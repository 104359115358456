<app-header></app-header>
<div class="page-wrapper">
<div class="content-wrapper user-listing">
  <h2><a routerLink="/dashboard" i18n="@@home">HOME</a> / <a routerLink="/mdm" i18n="@@masterDataManagement">Master Data Management</a> / <span i18n="@@product">Product</span></h2>
  <br />
  <hr class="border-bottom" />
  <input name="userFilter" type="text" placeholder="Search Users" class="form-control" style="display: inline; width: initial"  i18n-placeholder="@@searchUsers">
  <select class="form-control" style="display: inline;width: fit-content;margin-top: 14px;margin-left: 10px;">
    
    <option class = "option" value="10">10</option>
    <option class = "option" value="20">20</option>
    <option class = "option" value="50">50</option>
  </select>
  <div class="col-md-7 fltRgt form-group">
    <button type="button" name="button" class="btn btn-success fltRgt" (click)="openModal(addUserTemplate, false); $event.stopPropagation()">
      <img height="15px" alt="add product" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDkxLjg2IDQ5MS44NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDkxLjg2IDQ5MS44NjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIj48Zz48Zz4KCTxnPgoJCTxwYXRoIGQ9Ik00NjUuMTY3LDIxMS42MTRIMjgwLjI0NVYyNi42OTFjMC04LjQyNC0xMS40MzktMjYuNjktMzQuMzE2LTI2LjY5cy0zNC4zMTYsMTguMjY3LTM0LjMxNiwyNi42OXYxODQuOTI0SDI2LjY5ICAgIEMxOC4yNjcsMjExLjYxNCwwLDIyMy4wNTMsMCwyNDUuOTI5czE4LjI2NywzNC4zMTYsMjYuNjksMzQuMzE2aDE4NC45MjR2MTg0LjkyNGMwLDguNDIyLDExLjQzOCwyNi42OSwzNC4zMTYsMjYuNjkgICAgczM0LjMxNi0xOC4yNjgsMzQuMzE2LTI2LjY5VjI4MC4yNDVINDY1LjE3YzguNDIyLDAsMjYuNjktMTEuNDM4LDI2LjY5LTM0LjMxNlM0NzMuNTksMjExLjYxNCw0NjUuMTY3LDIxMS42MTR6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiNGRkZGRkYiIGRhdGEtb2xkX2NvbG9yPSIjZmZmZmZmIj48L3BhdGg+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+CjwvZz48L2c+IDwvc3ZnPg==" />
      Add Product
    </button>        
  </div>
  
  <div class="clearfix"></div>
  <br />
  <div style="overflow-x: auto">
    <table class="table table-sm table-hover table-one-line">
      <thead>
        <tr>
          <th i18n="@@productName">Product Name</th>
          <th i18n="@@typeOfProduct">Type of Product</th>
          <th i18n="@@status2">Status</th>
          <th i18n="@@productCategory">Product Category</th>
          <th i18n="@@actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let productListData of listOfProducts?.data;">
          <td>{{productListData?.productname}}</td>
          <td>{{productListData?.typeofproduct}}</td>
          <td>{{productListData?.status}}</td>
          <td>{{productListData?.productcategory}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #addUserTemplate>
    <form [formGroup]="productForm" (ngSubmit)="submitForm(productForm,$event)" autocomplete="off">
      <div class="modal-header">
        <button type="button" class="close" (click)="hideAddUserModal(); $event.stopPropagation()">
          
          <img height="15px" alt="" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjEyLjk4MiAyMTIuOTgyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMTIuOTgyIDIxMi45ODI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PGc+PGcgaWQ9IkNsb3NlIj4KCTxwYXRoIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDsiIGQ9Ik0xMzEuODA0LDEwNi40OTFsNzUuOTM2LTc1LjkzNmM2Ljk5LTYuOTksNi45OS0xOC4zMjMsMC0yNS4zMTIgICBjLTYuOTktNi45OS0xOC4zMjItNi45OS0yNS4zMTIsMGwtNzUuOTM3LDc1LjkzN0wzMC41NTQsNS4yNDJjLTYuOTktNi45OS0xOC4zMjItNi45OS0yNS4zMTIsMGMtNi45ODksNi45OS02Ljk4OSwxOC4zMjMsMCwyNS4zMTIgICBsNzUuOTM3LDc1LjkzNkw1LjI0MiwxODIuNDI3Yy02Ljk4OSw2Ljk5LTYuOTg5LDE4LjMyMywwLDI1LjMxMmM2Ljk5LDYuOTksMTguMzIyLDYuOTksMjUuMzEyLDBsNzUuOTM3LTc1LjkzN2w3NS45MzcsNzUuOTM3ICAgYzYuOTg5LDYuOTksMTguMzIyLDYuOTksMjUuMzEyLDBjNi45OS02Ljk5LDYuOTktMTguMzIyLDAtMjUuMzEyTDEzMS44MDQsMTA2LjQ5MXoiPjwvcGF0aD4KPC9nPjwvZz4gPC9zdmc+" />
        </button>
        <h4 class="modal-title" id="myModalLabel" i18n="@@addProduct">Add Product</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12" *ngFor="let productData of productsReqData?.data; let i = index;">
            <div [ngSwitch]="productData?.field_type">
              <div *ngSwitchCase="'text'">
                <div class="form-group">
                  <label class="control-label">
                    <span >{{productData?.field_name}}</span>
                    <span class="mandatory" *ngIf="productData?.mandatory === 'Y'"> *</span>
                  </label>
                  <input type="text" name="suppliername" class="form-control" (input)="inputValidator($event)" [formControlName]="productData?.field_id" placeholder="{{productData?.field_name}}">
                  <div *ngIf="productForm.controls[productData.field_id].invalid && (productForm.controls[productData.field_id].touched || f1Submitted)" class="alert alert-danger alert_pd">
                    <div *ngIf="productForm.controls[productData.field_id].errors.required">{{productData?.field_name}} is required</div>
                    <div *ngFor="let validation of productData?.validations">
                      <div *ngIf="productForm.controls[productData.field_id].errors.minlength && validation?.type === 'minlength'">{{productData?.field_name}} must be at least {{validation.value}} <span i18n="cherectersLong">characters long</span></div>
                      <div *ngIf="productForm.controls[productData.field_id].errors.maxlength && validation?.type === 'maxlength'">{{productData?.field_name}} can be max {{validation.value}} <span i18n="cherectersLong">characters long</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="'textarea'">
                <div class="form-group">
                  <label class="control-label">
                    <span >{{productData?.field_name}}</span>
                    <span class="mandatory" *ngIf="productData?.mandatory === 'Y'"> *</span>
                  </label>                  
                  <textarea id="textarea-result" class="form-control has-counter" #textareavalue (input)="inputValidator($event)" [formControlName]="productData?.field_id" placeholder="{{productData?.field_name}}"
                    maxlength="400"></textarea>
                  <div class="textarea-counter float-right">
                    <span class="value">{{textareavalue?.value?.length}}</span>/400
                  </div>
                  <div *ngIf="productForm.controls[productData.field_id].invalid && (productForm.controls[productData.field_id].touched || f1Submitted)" class="alert alert-danger alert_pd">
                    <div *ngIf="productForm.controls[productData.field_id].errors.required">{{productData?.field_name}} is required</div>
                    <div *ngFor="let validation of productData?.validations">
                      <div *ngIf="productForm.controls[productData.field_id].errors.minlength && validation?.type === 'minlength'">{{productData?.field_name}} must be at least {{validation.value}} <span i18n="cherectersLong">characters long</span></div>
                      <div *ngIf="productForm.controls[productData.field_id].errors.maxlength && validation?.type === 'maxlength'">{{productData?.field_name}} can be max {{validation.value}} <span i18n="cherectersLong">characters long</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="'dropdown'">
                <div class="form-group">
                  <label class="control-label">
                    <span>{{productData?.field_name}}</span>
                    <span class="mandatory" *ngIf="productData?.mandatory === 'Y'"> *</span>
                  </label>
                  <div *ngIf="productData?.value_options?.length == 0">
                    <select name="country" class="form-control" [name]="productData?.field_id" [formControlName]="productData?.field_id">
                      <option *ngFor="let item of typeOfProductData?.data" [ngValue]="item.id" selected="selected">
                        {{item.name}}
                      </option>                    
                    </select>
                  </div>
                  <div *ngIf="productData?.value_options?.length > 0">
                    <select name="status" class="form-control" [name]="productData?.field_id" [formControlName]="productData?.field_id">
                      <option *ngFor="let item of productData?.value_options" [ngValue]="item.id" [selected]="selected">
                        {{item.name}}
                      </option>
                    </select>
                  </div>                    
                  <div *ngIf="productForm.controls[productData.field_id].invalid && (productForm.controls[productData.field_id].touched || f1Submitted)" class="alert alert-danger alert_pd">
                    <div *ngIf="productForm?.controls[productData.field_id].errors.required">{{productData?.field_name}} is required</div>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="'date'">
                <div class="form-group">
                  <label class="control-label">
                    <span >{{productData?.field_name}}</span>
                    <span class="mandatory" *ngIf="productData?.mandatory === 'Y'"> *</span>
                  </label>
                  <div class="dateIcon" (click)="isOpen = !isOpen">
                      <input type="text" name="doe" bsDatepicker [bsConfig]="{ dateInputFormat: dateFormat, containerClass: 'theme-dark-blue' }" [isOpen]="isOpen" class="form-control" [formControlName]="productData?.field_id" placeholder="{{productData?.field_name}}" readonly>
                      <i class="utilicon utilicon-calendar-alt"></i>
                  </div>                  
                  <div *ngIf="productForm.controls[productData.field_id].invalid && (productForm.controls[productData.field_id].touched || f1Submitted)" class="alert alert-danger alert_pd">
                    <div *ngIf="productForm?.controls[productData.field_id].errors.required">{{productData?.field_name}} is required</div>
                  </div>
                </div>
              </div>

              <div *ngSwitchCase="'radio'">
                <div class="form-group">
                  <label class="control-label">
                    <span >{{productData?.field_name}}</span>
                    <span class="mandatory" *ngIf="productData?.mandatory === 'Y'"> *</span>
                  </label>`
                  <div class="form-check" *ngFor="let radioOption of productData?.value_options">
                    <input type="radio" class="form-check-input" id="{{productData?.field_id + radioOption?.value}}" [value]="radioOption?.value"
                      [formControlName]="surveyQuestionData.id">
                    <label class="form-check-label" for="{{productData?.field_id + radioOption?.value}}" tabindex="0">{{radioOption?.description}}</label>
                  </div>
                  <div *ngIf="productForm.controls[productData.field_id].invalid && (productForm.controls[productData.field_id].touched || f1Submitted)" class="alert alert-danger alert_pd">
                    <div *ngIf="productForm?.controls[productData.field_id].errors.required">{{productData?.field_name}} is required</div>
                  </div>
                </div>
              </div>

              <div *ngSwitchCase="'checkbox'">
                <div class="form-group">
                  <label class="control-label">
                    <span >{{productData?.field_name}}</span>
                    <span class="mandatory" *ngIf="productData?.mandatory === 'Y'"> *</span>
                  </label>`
                  <div class="form-check" *ngFor="let radioOption of productData?.value_options">
                    <input type="checkbox" class="form-check-input" id="{{productData?.field_id + radioOption?.value}}" [value]="radioOption?.value"
                      [formControlName]="surveyQuestionData.id">
                    <label class="form-check-label" for="{{productData?.field_id + radioOption?.value}}" tabindex="0">{{radioOption?.description}}</label>
                  </div>
                  <div *ngIf="productForm.controls[productData.field_id].invalid && (productForm.controls[productData.field_id].touched || f1Submitted)" class="alert alert-danger alert_pd">
                    <div *ngIf="productForm?.controls[productData.field_id].errors.required">{{productData?.field_name}} is required</div>
                  </div>
                </div>
              </div>

            </div>            
          </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="submit" id="usrcreatebtn" class="btn btn-primary" i18n="@@add">Add</button>
          <button type="button" (click)="hideAddUserModal(); $event.stopPropagation()" class="btn btn-secondary" data-dismiss="modal" i18n="@@cancel">Cancel</button>
      </div>
    </form>
  </ng-template>
</div>
</div>