import { Component,
  OnInit,
  OnDestroy,
  ViewChild, ElementRef, AfterViewInit, Renderer, ChangeDetectionStrategy, Renderer2, Inject, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Project, UserTypeServiceMapping } from '../../../models/project';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CommonService } from '../../../services/common.service';
import { chart } from 'highcharts';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
import { inherits } from 'util';
import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
var parentScope01;

@Component({
  selector: 'app-kpi-indicators',
  templateUrl: './kpi-indicators.component.html',
  styleUrls: ['./kpi-indicators.component.css']
})
export class KpiIndicatorsComponent implements OnInit {

  @ViewChild('charts') public chartElement01: ElementRef;
  @ViewChild('charts2') public chartElement02: ElementRef;
  projects: any = [];
  project: any;
  projectId = 0;
  dashboardId;
  dashboardData = [];
  reportIDs = [];
  predefinedReports = [];
  kpisListData = [];
  kpisObject ={
    dropDownVal:''
  }
  kpisReportsList= [];
  selectedKpiReportsIDs= [];
  selectedKPIsMultiSelect : any = [];
  dropdownSettings = {};
  sectionDropDown= [];
  subSectionDropdown = [];
  listsSectionsSubSection = [];
  public dashboardAddObject ={
    "name":'',
    "description":'',
    "section":0,
    "subsection":'',
    "reportNames":''
  };
  subsectionFlag = true;
  existingSelectedKPIs = [];
  countOfFarmers;
  listsKPISection = [];
  public kpiData:any = [];
  public kpiReports:any = [];
  public KPIAddObject ={
    "name":'',
    "description":'',
    "section":0,
    "reportNames":''
  };
  noNewReportsSelected;
  selectPlaceholder:string;
  selectAllText:string;
  unSelectAllText:string;
  searchText:string;

  constructor(
    private readonly route: ActivatedRoute,
    private projectService: ProjectService,
    private toastrService: ToastrService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private translate: TranslateService,
    private changeDetectionRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private commonService: CommonService,
    private hcRService:HighchartReportsService,
    private readonly spinner: Ng4LoadingSpinnerService,
  ) {
    
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.params['id'];
    this.hcRService.allCharts = [];
    this.projectService.getProjectBasicInfo(this.projectId).subscribe(response =>{
      this.project = response.data;
      let projectObject = this.project.filter(item => item.id == this.projectId)[0];
      this.project = projectObject;
    });

    let requestObject = {
      projectId : this.projectId,
      type : "KPI"
    };
    this.setTranslatedLabelObjects();
    // this.hcRService.getKPIsReports(requestObject).subscribe(response =>{
    this.hcRService.getSectionSubsectionReportsSelectionListAPI(requestObject).subscribe(response =>{  
      if(response.msg == 'success'){
        let listKPI = response.data;
        for (var item in listKPI.kpiSections) {
          let newObject = {
            "section": '',
            "reports": []
          };
          this.listsKPISection.push(item);
          console.log("listsKPISection", this.listsKPISection)
          newObject.section  = item;
          let reports = listKPI.kpiSections[item];
          for(let x in reports){
            newObject.reports.push(reports[x]);
          }
          this.kpiData.push(newObject);


        }

        if(listKPI.custom_reports && listKPI.custom_reports.length > 0){
          let customReports = listKPI.custom_reports;
          this.kpiReports.push(...customReports);
        }

      }
    });

     //multi-select drop down settings
     this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: this.selectAllText,
      unSelectAllText: this.unSelectAllText,
      searchPlaceholderText:this.searchText,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    if(this.projectId > 0){
      let requestObject = {
        "reportID":[50],
        "projectId":this.projectId
      }; 

      this.hcRService.getReports(requestObject).subscribe(response =>{
        if(response.msg == 'success'){
          this.countOfFarmers = response.data[0].series[0];
        }
      });

    }
  }

  //added by Kiran for translation
  setTranslatedLabelObjects(): void {
    this.translate.get('no_new_reports_selected').subscribe(result => {
      this.noNewReportsSelected = result;
    });
    this.translate.get('selectPlaceholder').subscribe(result => {
      this.selectPlaceholder = result;
    });
    this.translate.get('selectAllText').subscribe(result => {
      this.selectAllText = result;
    });
    this.translate.get('unSelectAllText').subscribe(result => {
      this.unSelectAllText = result;
    });
    this.translate.get('searchText').subscribe(result => {
      this.searchText = result;
    });
  }

  trackBySectionName(index, dataItem) {
    return dataItem.section;
  }

  selectKPIs(event, currentVal){
    //console.log("event ", event);
    //console.log("currentVal ",currentVal);
    // let newItem = {
    //   "kpiname":"Farmers count",
    //   "imageurl":"",
    //   "value":59
    // };
    // this.kpisReportsList.push(newItem);
    this.selectedKpiReportsIDs.push(currentVal.value);
    //console.log(" this.kpiReportsIDs ", this.selectedKpiReportsIDs);
  }

  selectKPISection(ev, selectedValue) {
    this.kpisListData = [];
    this.selectedKPIsMultiSelect = [];
    let section = this.kpiData.filter(item => item.section == this.KPIAddObject.section)[0];
    if(section != undefined || section  != null){
      this.kpisListData = section.reports;
      if(this.kpisListData.length == 0){

        this.subsectionFlag = true;
      }
      else{
        this.subsectionFlag = false;
      }
    }

  }

  selectSection(ev, selectedValue) {
    this.subsectionFlag = false;
  }

  trackByName(index, dataItem) {
    return dataItem.section;
  }

  genarateKPIs(){
    let selectedKPIIds;
    let selectedKpis = this.selectedKPIsMultiSelect.map(item => item.id);

    if(this.existingSelectedKPIs.length > 0){
      const result = selectedKpis.filter(d => this.existingSelectedKPIs.indexOf(d) < 0);
      selectedKPIIds = result;
      this.existingSelectedKPIs = this.existingSelectedKPIs.concat(result);
    }else{
      this.existingSelectedKPIs = this.existingSelectedKPIs.concat(selectedKpis);
      selectedKPIIds = selectedKpis;
    }
    let requestObject = {
      "reportID":selectedKPIIds,
      "projectId":this.projectId
    }

    if(selectedKPIIds.length > 0){
      this.spinner.show();
      this.hcRService.getReports(requestObject).subscribe(response =>{
        if(response.msg == 'success'){
           let reportsData= response.data;
           for(let i=0;i<reportsData.length;i++){
             if(reportsData[i].is_multi_kpi) {
              this.multiKPI(reportsData[i])
             }
             else if(reportsData[i].series.length > 0){
              for(let j=0;j<reportsData[i].series.length;j++){         
                for (var prop in reportsData[i].series[j]) {                    
                  if (reportsData[i].series[j].hasOwnProperty(prop)) {
                      let innerObj = {
                        name:'',
                        value:Number(''),
                        kpi_image:'',
                        cols:reportsData[i].dim_col
                      };
                      innerObj.name = reportsData[i].name;
                      let num = Number(reportsData[i].series[j][prop]).toFixed();
                      if(isNaN(parseInt(num))){
                        innerObj.value = reportsData[i].series[0][prop];
                      }else{
                        innerObj.value = parseInt(num);
                      }
                      innerObj.kpi_image = reportsData[i].kpi_image;
                      this.kpisReportsList.push(innerObj)
                  }
                }                  
              }
            } else {
              let innerObj = {
                name:'',
                value:'N/D',
                kpi_image:'',
                cols:reportsData[i].dim_col
              };
              innerObj.name = reportsData[i].name;
              innerObj.kpi_image = reportsData[i].kpi_image;
              this.kpisReportsList.push(innerObj);
            }
          }
          this.spinner.hide();
        }
      });
    }else{
      this.commonService.showToast('info', 'no_new_kpi_selected', {});
      //this.toastrService.info(this.noNewReportsSelected);
    }   
  }

  multiKPI(obj){
    let outerObj = {
      name:obj.name,
      is_multi:true,
      series:[],
      cols:obj.dim_col
    }
    if(obj.drilldown.series.length > 0){
      for(let j=0;j<obj.drilldown.series.length;j++){         
          let innerObj = {
            name:'',
            value:'',
            kpi_image:''
          };
          innerObj.name = obj.drilldown.series[j].kpi_key;
          if(obj.drilldown.series[j].data[0] != null){
            innerObj.value = obj.drilldown.series[j].data[0];
          } else {
            innerObj.value = 'N/D'
          }
          innerObj.kpi_image = obj.drilldown.series[j].kpi_image;
          outerObj.series.push(innerObj);
      }
    }
    this.kpisReportsList.push(outerObj)
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }
  goToReportingManagement(projectID){
    let url = 'charts/' + this.projectId;
    this.router.navigate([url]);
  }
  goToDashboards(){
    let url="charts/dashboards/"+ this.projectId;
    this.router.navigate([url]);
  }

  cancel(){
    let url="charts/"+ this.projectId;
    this.router.navigate([url]);
  }

  async printCharts(){
    //debugger;
    var mywindow = window.open('', 'PRINT', 'height=600,width=900');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById('KPIbox').innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  }

  clearAllCharts(){
    //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
    this.kpisReportsList = [];
    this.selectedKPIsMultiSelect = [];
    this.existingSelectedKPIs = [];
  }
  
  onSelectAllChart(ele){
    document.getElementById('KPI').click();
  }

}
