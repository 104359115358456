import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DynamicQuestionnaireService {

  constructor(private http: HttpClient) { }

  getQAnswersForFarmer(projectId, farmerId): Observable<any> {
    return this.http.get<any>(this.getUrl('/api/nq/getQAnswersForFarmer' + '/' + projectId + '/' + farmerId));
  }

  saveProfilingData(qAnswers, farmerId, timestamp, stats): Observable<any> {
    return this.http.post<any>(this.getUrl('/api/nq/saveProfilingData' + '/' + farmerId), {addedAt: timestamp, values: qAnswers, stats: stats});
  }

  updateQuestionnaireAssetLabel(qAssetId, languageId, label) {
    return this.http.post<any>(this.getUrl('/api/nq/updateQuestionnaireAssetLabel'), {id: qAssetId, languageId: languageId, label: label});
  }

  saveProfilingDataOffline(answerValueObject): Observable<any> {
    return this.http.post<any>(this.getUrl('/api/nq/saveProfilingDataOffline'), answerValueObject);
  }

  saveProfilingStatsDataOffline(statsValueObject): Observable<any> {
    return this.http.post<any>(this.getUrl('/api/nq/saveProfilingStatsDataOffline'), statsValueObject);
  }

  getQAnswersForRevision(projectId, farmerId, revisionId): Observable<any> {
    return this.http.get<any>(this.getUrl('/api/nq/getQAnswersForRevision' + '/' + projectId + '/' + farmerId + '/' + revisionId));
  }

  getAssetDetails(assetId): Observable<any> {
    return this.http.get<any>(this.getUrl('/api/nq/getAssetDetails' + '/' + assetId));
  }

  getQAnswersForProject(projectId, nextMinFarmerId): Observable<any> {
    return this.http.get<any>(this.getUrl('/api/nq/getQAnswersForProject' + '/' + projectId + '?minFarmerId=' + nextMinFarmerId));
  }

  getUrl(name): string {
    const url = environment.apiBaseUrl + name;
    //console.log('prjects' + url);
    return url;
  }

  makeRevision(surveyId): Observable<any> {
    return this.http.get<any>(this.getUrl('/api/nq/makeRevision' + '/' + surveyId));
  }

  getAnswerStatsForProject(projectId): Observable<any> {
    return this.http.get<any>(this.getUrl('/api/nq/getAnswerStatsForProject' + '/' + projectId));
  }

  getPaginationLimits(projectId): Observable<any> {
    return this.http.get<any>(this.getUrl('/api/nq/getPaginationLimits' + '/' + projectId));
  }

  getQAnswersForProjectPaginated(projectId, gte, lt): Observable<any> {
    return this.http.get<any>(this.getUrl('/api/nq/getQAnswersForProject' + '/' + projectId + '?gte=' + gte + '&lt=' + lt));
  }

  getAnswerStatsForCampaign(campaignId): Observable<any> {
    return this.http.get<any>(`${environment.apiBaseUrl}/api/nq/getAnswerStatsForCampaign/${campaignId}`);
  }

  getQAnswersForFarmerForCampaign(campaignId, farmerId): Observable<any> {
    return this.http.get<any>(this.getUrl(`/api/nq/getQAnswersForFarmerForCampaign/${campaignId}/${farmerId}`));
  }

  saveProfilingDataForCampaign(qAnswers, farmerId, campaignId, timestamp, stats): Observable<any> {
    return this.http.post<any>(this.getUrl(`/api/nq/saveProfilingDataForCampaign/${farmerId}/${campaignId}`), {addedAt: timestamp, values: qAnswers, stats: stats});
  }

  getQAnswersForCampaignPaginated(campaignId, gte, lt): Observable<any> {
    return this.http.get<any>(this.getUrl(`/api/nq/getQAnswersForCampaign/${campaignId}?gte=${gte}&lt=${lt}`));
  }

  //added by KRPT for performance issue
  getSurveyQAssetIdsDashboard(surveyId): Observable<any> {
    return this.http.get<any>(this.getUrl(`/api/nq/getSurveyQAssetIdsDashboard/${surveyId}`));
  } 

  getQAnswersForProjectPaginatedDashboard(projectId, gte, lt, surveyQIds): Observable<any> {
    let params = new HttpParams();
    params = params.append('surveyQIds', JSON.stringify(surveyQIds)); 
    return this.http.get<any>(this.getUrl('/api/nq/getQAnswersForProjectDashboard' + '/' + projectId + '/' + '?gte=' + gte + '&lt=' + lt), {params : params});
  }

}
