<div class="bg">
  <div class="login-p2f-logo">
    <img src="assets/images/path2farm-logo-hor-white.webp" alt="path2farm logo"/>
  </div>
  <div class="login-fir-logo login-p2froots-img">
    <img src="assets/images/fir-logo.webp" alt="firmenich logo"/>
  </div>
</div>

<div class="login-right">
    <div class="login-p2froots-logo text-right">
        <img class="login-p2froots-img" src="assets/images/p2f-roots-logo.webp" alt="p2f roots logo"/>
        <img class="login-p2froots-img-small" src="assets/images/p2f-roots-logo-white.webp" alt="p2f roots logo"/>
      </div>
      <div class="reset-screen forget" id="forgot" style="display:block;">
        <div class="login-info forgot-pwd">
          <h3 class="login-header text-center" i18n="@@forgotUserId">
            Forgot Your User ID
          </h3>
          <div class="forgot-body text-center">
            <form name="forgetForm" [formGroup]="resetUserIDForm" (ngSubmit)="sendEmail(resetUserIDForm)"  autocomplete="off">
              <span class="desc">
                <span i18n="@@willSendUserIdToRegEmailAddress">We will send your User ID(s) to your registered email address.</span>
              </span>
              <br /><br />
              <div class="form-group">
                <label for="emailid" i18n="@@enterRegisterEmailId">Enter Your Registered Email ID
                  <strong class="mandatory"> *</strong>
                </label>
               
                <input type="email" formControlName="emailID" autofocus appAutofocus class="form-control-login pl-2" maxlength="50" />
                  
                  <div *ngIf="resetUserIDForm.controls['emailID'].invalid && (resetUserIDForm.controls['emailID'].dirty)">
                    <div class="error" *ngIf="resetUserIDForm.controls['emailID'].hasError('required')">
                      <span i18n="@@emailIdIsRequired">Email ID is required</span></div>
                    <div class="error" *ngIf="resetUserIDForm.controls['emailID'].hasError('email')"  i18n="@@enterValidEmailId">Please enter the valid email
                    </div>
                  </div>

              </div>
              <button type="submit" title="Send" i18n-title="@@send" [disabled]="isDisabled || !resetUserIDForm.valid" class="btn-login-blue mr-3 inline-block" i18n="@@send">Send</button>
              <button class="button" title="Back" i18n-title="@@back" class="btn-login-blank inline-block" i18n="@@back" routerLink="/">Back</button>
            </form>
          </div>
        </div>
      </div>

  </div>
<div class="clearfix"></div>
