
import {Observable, forkJoin, throwError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { CommonService } from '../services/common.service' ;






interface IReturn {
  project: any[];
}

@Injectable()
export class QuestinnaireResolver implements Resolve<IReturn> {

  constructor(private commonService: CommonService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IReturn> {
 return  forkJoin ([
      this.commonService.getProjectKeyValue()
    ]).pipe(
    map(results => ({
      project: results[0]
    })),
   catchError(error => {
      return throwError(error);
    }),
  );
  }

}
