import * as tslib_1 from "tslib";
import { OnInit, ElementRef, AfterViewInit, ComponentFactoryResolver, Renderer2, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuppliersService } from '../../../services/suppliers.service';
import { ChartService } from '../../../services/chart.service';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
import { BsModalService } from 'ngx-bootstrap/modal';
var Highcharts = require('highcharts');
import { CommonService } from '../../../services/common.service';
import { DashboardAddService } from '../../../services/dashboard-add.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
var parentScope01;
var DashboardEditComponent = /** @class */ (function () {
    function DashboardEditComponent(route, projectService, toastrService, router, activeRoute, suppService, translate, changeDetectionRef, chartService, renderer, hcRService, modalService, commonService, DashboardAddService, spinner, componentFactoryResolver) {
        this.route = route;
        this.projectService = projectService;
        this.toastrService = toastrService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.suppService = suppService;
        this.translate = translate;
        this.changeDetectionRef = changeDetectionRef;
        this.chartService = chartService;
        this.renderer = renderer;
        this.hcRService = hcRService;
        this.modalService = modalService;
        this.commonService = commonService;
        this.DashboardAddService = DashboardAddService;
        this.spinner = spinner;
        this.componentFactoryResolver = componentFactoryResolver;
        this.preferredReportsType = 'predefined';
        this.setAsDefault = false;
        this.reportListType = [];
        this.reportTypes = 'predefined';
        this.projects = [];
        this.projectId = 0;
        this.typeOfAdministration = '';
        this.editChartReports = [];
        this.dashboardEditObject = {
            "name": '',
            "description": '',
            "section": 0,
            "subsection": '',
            "reportID": [],
            "newsection": '',
            "chartDataOptions": { "id": 0, "name": '', "type": '', "sortOrder": 0, "x": 0, "y": 0, "dim_col": 0, "dim_row": 0, "chartOptions": {} }
        };
        this.KPIAddObject = {
            "name": '',
            "description": '',
            "section": 0,
            "reportNames": ''
        };
        this.kpiDataObject = { "id": 0, "name": '', "type": '', "sortOrder": 0, "x": 0, "y": 0, "dim_col": 0, "dim_row": 0, "kpiImage": "" };
        this.subsectionFlag = true;
        this.reportsFlag = true;
        this.kpiFlag = true;
        this.selectedReportsMultiSelect = [];
        this.selectedKPIsMultiSelect = [];
        this.selectedReportsMultiSelectCustom = [];
        this.dropdownSettings = {};
        this.listsSectionsSubSection = [];
        this.chartsOptions = [];
        this.sectionDropDown = [];
        this.subSectionDropdown = [];
        this.listOfReport = [];
        this.exportBtns = false;
        this.reportAvailableFlag = false;
        this.existingSelectedReports = [];
        this.customReportsListData = [];
        this.checkChartsAvailable = [];
        this.profilingFlag = false;
        this.customFlag = false;
        this.listsKPISection = [];
        //added by Kiran for Gridster
        this.title = "Data Dashoard";
        this.currentAdIndex = -1;
        this.zoom = [5];
        this.height = 300;
        this.existingKPIs = [];
        this.kpisListData = [];
        this.kpiData = [];
        this.kpiReports = [];
        this.Highcharts = Highcharts;
        this.dashboardIDs = [];
        this.chartReports = [];
        this.disableKPIButton = false;
        this.disableChartsButton = false;
    }
    DashboardEditComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var gridsterWidth, requestObject, requestParams, requestObject2, requestObject_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                parentScope01 = this;
                this.dashboardId = this.route.snapshot.params['id2'];
                this.projectId = parseInt(this.route.snapshot.params['id']);
                this.setTranslatedLabelObjects();
                // if (navigator.onLine) {
                //   this.project = this.route.snapshot.data.project.project.data;
                // }else{
                //   const requests = await Promise.all([
                //     this.projectService.GetProjectsDataDashboard().toPromise()
                //   ]);
                //   this.project = requests[0].data.find(x => x.id === this.projectId);
                // }
                this.DashboardAddService.deleteAll();
                this.hcRService.allCharts = [];
                this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
                    _this.project = response.data;
                    var projectObject = _this.project.filter(function (item) { return item.id == _this.projectId; })[0];
                    _this.project = projectObject;
                });
                gridsterWidth = document.getElementById("gridsterBase").offsetWidth;
                this.chartWidth = (gridsterWidth / 3) - 15;
                requestObject = {
                    "dashboardId": this.dashboardId
                };
                this.spinner.show();
                this.hcRService.getDashboardDetailsById(requestObject).subscribe(function (response) {
                    // console.log("get Dashboard Data ", response);
                    if (response.msg == 'success') {
                        var dashboardData = response.data;
                        _this.dashboardEditObject.name = dashboardData[0].name;
                        _this.dashboardEditObject.description = dashboardData[0].description;
                        if (dashboardData[0].is_default == 'N') {
                            _this.setAsDefault = false;
                        }
                        else {
                            _this.setAsDefault = true;
                        }
                        var dashboardSelectedReports = [];
                        var reportArr = [];
                        for (var i = 0; i < dashboardData[0].hc_report_dashboard_dtl.length; i++) {
                            if (dashboardData[0].hc_report_dashboard_dtl[i].report_type == 'Chart') {
                                dashboardSelectedReports.push(dashboardData[0].hc_report_dashboard_dtl[i]);
                                _this.chartReports.push(dashboardData[0].hc_report_dashboard_dtl[i]);
                                //this.dashboardEditObject.section = dashboardData[0].hc_report_dashboard_dtl[0].section;
                            }
                            else {
                                _this.kpiDataObject =
                                    {
                                        "id": dashboardData[0].hc_report_dashboard_dtl[i].report_id,
                                        "name": dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.hc_report_name[0].name,
                                        "type": dashboardData[0].hc_report_dashboard_dtl[i].report_id.report_type,
                                        "sortOrder": dashboardData[0].hc_report_dashboard_dtl[i].report_id.sort_order,
                                        "x": dashboardData[0].hc_report_dashboard_dtl[i].x_axis,
                                        "y": dashboardData[0].hc_report_dashboard_dtl[i].y_axis,
                                        "dim_col": dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.dim_col,
                                        "dim_row": dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.dim_row,
                                        "kpiImage": dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.kpi_image
                                    };
                                //console.log("kpiDataObject",this.kpiDataObject);
                                var tempObj = {
                                    "id": dashboardData[0].hc_report_dashboard_dtl[i].report_id,
                                    "type": "view",
                                    "x": _this.kpiDataObject.x,
                                    "y": _this.kpiDataObject.y
                                };
                                reportArr.push(tempObj);
                                _this.existingKPIs.push(dashboardData[0].hc_report_dashboard_dtl[i].report_id);
                                //this.genarateKPIs(reportArr,"edit",this.kpiDataObject.x,this.kpiDataObject.y);
                            }
                        }
                        _this.genarateKPIs(reportArr, "edit");
                        if (dashboardSelectedReports && dashboardSelectedReports.length > 0) {
                            var reportCharts = [];
                            for (var i = 0; i < dashboardSelectedReports.length; i++) {
                                //hc_report_hdr
                                //let chartOptions = dashboardSelectedReports[i].hc_report_hdr.hc_report_type[0].conf;
                                //console.log(" chart options ", JSON.parse(chartOptions));
                                var reportId = [];
                                reportId.push(dashboardSelectedReports[i].report_id);
                                _this.dashboardEditObject.reportID = reportId;
                                // this.dashboardEditObject.chartDataOptions =
                                //                   {
                                //                     "id":dashboardSelectedReports[i].report_id,
                                //                     "name":dashboardSelectedReports[i].hc_report_hdr.hc_report_name[0].name,
                                //                     "type":dashboardSelectedReports[i].report_type,
                                //                     "sortOrder":dashboardSelectedReports[i].sort_order,
                                //                     "x":dashboardSelectedReports[i].x_axis,
                                //                     "y":dashboardSelectedReports[i].y_axis,
                                //                     "dim_col":dashboardSelectedReports[i].hc_report_hdr.dim_col,
                                //                     "dim_row":dashboardSelectedReports[i].hc_report_hdr.dim_row,
                                //                     "chartOptions":{}
                                //                   };
                                _this.dashboardEditObject.newsection = dashboardSelectedReports[i].hc_report_hdr.section;
                                reportCharts.push(reportId);
                            }
                            _this.editChartReports = reportCharts;
                            _this.genarateCharts(_this.reportTypes, 'init');
                        }
                    }
                });
                requestParams = {
                    projectId: this.projectId,
                    ecosystem_id: localStorage.getItem('ecosystem_id'),
                    type: "CHART"
                };
                this.hcRService.getSectionSubsectionReportsSelectionListAPI(requestParams).subscribe(function (response) {
                    var _a;
                    //console.log("response ", response);
                    if (response.msg == 'success') {
                        var sectionSubsectionReports = response.data;
                        if (sectionSubsectionReports.profiling_reports && Object.keys(sectionSubsectionReports.profiling_reports).length !== 0) {
                            for (var item in sectionSubsectionReports.profiling_reports) {
                                var newObject = {
                                    "section": '',
                                    "subsection": []
                                };
                                //console.log(" item ", item);
                                _this.sectionDropDown.push(item);
                                newObject.section = item;
                                var subSection = sectionSubsectionReports.profiling_reports[item];
                                //console.log(" subSection ", subSection);
                                for (var x in subSection) {
                                    //console.log(" subSection ", x)
                                    _this.subSectionDropdown.push(x);
                                    var reportsList = subSection[x];
                                    //this.listOfReport = reportsList.map(item => ({id:item.id, name:item.name}));
                                    //console.log("this.listOfReport ",this.listOfReport);
                                    var subObject = {
                                        "name": x,
                                        "reports": reportsList
                                    };
                                    newObject.subsection.push(subObject);
                                    //console.log(" newObject ", newObject);
                                }
                                //console.log(" newObject ", newObject);
                                _this.listsSectionsSubSection.push(newObject);
                                //console.log("this.listsSectionsSubSection ", this.listsSectionsSubSection);
                            }
                        }
                        if (sectionSubsectionReports.custom_reports && sectionSubsectionReports.custom_reports.length > 0) {
                            var customReports = sectionSubsectionReports.custom_reports;
                            (_a = _this.customReportsListData).push.apply(_a, customReports);
                        }
                        //console.log("this.listsSectionsSubSection ", this.listsSectionsSubSection);
                        var section = _this.listsSectionsSubSection.filter(function (item) { return item.section == _this.dashboardEditObject.section; })[0];
                        //console.log("section ", section);
                        //console.log(" subsection ", section.subsection);
                        //section != undefined || section  != null
                        if (section != undefined || section != null) {
                            _this.newSubSection = section.subsection;
                            var subsection = section.subsection.filter(function (item) { return item.name == _this.dashboardEditObject.subsection; })[0];
                            if (subsection && subsection.reports) {
                                _this.reportsListData = subsection.reports;
                            }
                            else {
                                _this.newSubSection = section.subsection;
                                //let reportsItems:any =[];
                                for (var i = 0; i < _this.newSubSection.length; i++) {
                                    var reports = _this.newSubSection[i].reports.filter(function (item) { return item.section == _this.dashboardEditObject.section; });
                                    //console.log("reports section select ", reports);
                                    _this.reportsListData = _this.reportsListData.concat(reports);
                                    //console.log(" reportsItems ", reportsItems);
                                }
                                //console.log("this.reportsListData ", this.reportsListData);
                                //this.reportsListData = [];
                                _this.selectedReportsMultiSelect = [];
                            }
                        }
                    }
                });
                this.dropdownSettings = {
                    singleSelection: false,
                    idField: 'id',
                    textField: 'name',
                    selectAllText: this.selectAllText,
                    unSelectAllText: this.unSelectAllText,
                    searchPlaceholderText: this.searchText1,
                    itemsShowLimit: 3,
                    allowSearchFilter: true
                };
                Highcharts.setOptions({
                    lang: {
                        noData: 'No data to display'
                    }
                });
                requestObject2 = {
                    projectId: this.projectId,
                    type: "KPI"
                };
                this.hcRService.getSectionSubsectionReportsSelectionListAPI(requestObject2).subscribe(function (response) {
                    var _a;
                    if (response.msg == 'success') {
                        var listKPI = response.data;
                        for (var item in listKPI.kpiSections) {
                            var newObject = {
                                "section": '',
                                "reports": []
                            };
                            _this.listsKPISection.push(item);
                            newObject.section = item;
                            var reports = listKPI.kpiSections[item];
                            for (var x in reports) {
                                newObject.reports.push(reports[x]);
                            }
                            _this.kpiData.push(newObject);
                        }
                        if (listKPI.custom_reports && listKPI.custom_reports.length > 0) {
                            var customReports = listKPI.custom_reports;
                            (_a = _this.kpiReports).push.apply(_a, customReports);
                        }
                    }
                });
                this.spinner.hide();
                if (this.projectId > 0) {
                    requestObject_1 = {
                        "reportID": [50],
                        "projectId": this.projectId
                    };
                    this.hcRService.getReports(requestObject_1).subscribe(function (response) {
                        if (response.msg == 'success') {
                            _this.countOfFarmers = response.data[0].series[0];
                        }
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    //added by Kiran for translation
    DashboardEditComponent.prototype.setTranslatedLabelObjects = function () {
        var _this = this;
        this.translate.get('selectPlaceholder').subscribe(function (result) {
            _this.selectPlaceholder = result;
        });
        this.translate.get('selectAllText').subscribe(function (result) {
            _this.selectAllText = result;
        });
        this.translate.get('unSelectAllText').subscribe(function (result) {
            _this.unSelectAllText = result;
        });
        this.translate.get('searchText').subscribe(function (result) {
            _this.searchText1 = result;
        });
    };
    DashboardEditComponent.prototype.loadDashboard = function (myForm) {
        var _this = this;
        this.disableKPIButton = true;
        var selectedKpis = this.selectedKPIsMultiSelect.map(function (item) { return item.id; });
        var selectedReportIds = [];
        if (this.layout.length > 0) {
            this.existingKPIs = this.layout.filter(function (item) { return item.componentName == "KPI"; }).map(function (item) { return item.id; });
            var result = selectedKpis.filter(function (d) { return _this.existingKPIs.indexOf(d) < 0; });
            selectedReportIds = result;
            this.existingKPIs = this.existingKPIs.concat(result);
        }
        else {
            this.existingKPIs = this.existingKPIs.concat(selectedKpis);
            selectedReportIds = selectedKpis;
        }
        // this.optionsModal.hide();
        if (selectedReportIds.length > 0) {
            var KPIs = this.selectedKPIsMultiSelect.map(function (item) { return item.id; });
            if (myForm.value.reportType == 'KPI') {
                var KPIobject = { "reportId": selectedReportIds, "type": myForm.value.reportType };
                this.dashboardIDs.push(KPIobject);
                this.genarateKPIs(selectedReportIds, "add");
            }
        }
        else {
            this.commonService.showToast('info', 'no_new_kpi_selected', {});
            this.disableKPIButton = false;
        }
    };
    DashboardEditComponent.prototype.genarateKPIs = function (reports, callType) {
        var _this = this;
        this.spinner.show();
        if (!reports || reports.length < 1) {
            return false;
        }
        var reportIds = [];
        if (callType == "add") {
            reportIds = reports;
        }
        else {
            for (var _i = 0, reports_1 = reports; _i < reports_1.length; _i++) {
                var report = reports_1[_i];
                reportIds.push(report.id);
            }
        }
        var requestObject = {
            "reportID": reportIds,
            "projectId": this.projectId
            //"projectId":25
        };
        var innerObj;
        this.hcRService.getReports(requestObject).subscribe(function (response) {
            //console.log("response ", response);
            if (response.msg == 'success') {
                var reportsData_1 = response.data;
                var _loop_1 = function (i) {
                    if (reportsData_1[i].is_multi_kpi) {
                        _this.multiKPI(reportsData_1[i], callType, reports);
                    }
                    else if (reportsData_1[i].series.length > 0) {
                        for (var j = 0; j < reportsData_1[i].series.length; j++) {
                            for (var prop in reportsData_1[i].series[j]) {
                                if (reportsData_1[i].series[j].hasOwnProperty(prop)) {
                                    innerObj = {
                                        id: reportsData_1[i].reportId,
                                        name: '',
                                        dim_col: reportsData_1[i].dim_col,
                                        dim_row: reportsData_1[i].dim_row,
                                        value: Number(''),
                                        kpi_image: ''
                                    };
                                    innerObj.name = reportsData_1[i].name;
                                    var num = Number(reportsData_1[i].series[j][prop]).toFixed();
                                    if (isNaN(parseInt(num))) {
                                        innerObj.value = reportsData_1[i].series[0][prop];
                                    }
                                    else {
                                        innerObj.value = parseInt(num);
                                    }
                                    innerObj.kpi_image = reportsData_1[i].kpi_image;
                                    if (callType == "edit") {
                                        var cordinates = reports.find(function (r) {
                                            return r.id == reportsData_1[i].reportId;
                                        });
                                        innerObj.x = (cordinates && cordinates.x) ? cordinates.x : 0;
                                        innerObj.y = (cordinates && cordinates.y) ? cordinates.y : 0;
                                        _this.DashboardAddService.addKPI(innerObj, "edit");
                                    }
                                    else {
                                        _this.existingKPIs.push(reportsData_1[i].reportId);
                                        _this.DashboardAddService.addKPI(innerObj, "add");
                                        //this.commonService.showToast('success', 'selected_kpis_added_successfully', {});
                                    }
                                }
                            }
                        }
                    }
                    else {
                        innerObj = {
                            id: reportsData_1[i].reportId,
                            name: '',
                            dim_col: reportsData_1[i].dim_col,
                            dim_row: reportsData_1[i].dim_row,
                            value: 'N/D',
                            kpi_image: ''
                        };
                        innerObj.name = reportsData_1[i].name;
                        innerObj.kpi_image = reportsData_1[i].kpi_image;
                        if (callType == "edit") {
                            var cordinates = reports.find(function (r) {
                                return r.id == reportsData_1[i].reportId;
                            });
                            innerObj.x = (cordinates && cordinates.x) ? cordinates.x : 0;
                            innerObj.y = (cordinates && cordinates.y) ? cordinates.y : 0;
                            _this.DashboardAddService.addKPI(innerObj, "edit");
                        }
                        else {
                            _this.existingKPIs.push(reportsData_1[i].reportId);
                            _this.DashboardAddService.addKPI(innerObj, "add");
                        }
                    }
                };
                //console.log("KPI ", reportsData);
                for (var i = 0; i < reportsData_1.length; i++) {
                    _loop_1(i);
                }
                _this.calcGridsterHeight();
                _this.spinner.hide();
                _this.disableKPIButton = false;
                if (callType != "edit") {
                    _this.commonService.showToast('success', 'selected_kpis_added_successfully', {});
                }
                //console.log("this.kpisReportsList ", this.kpisReportsList);
                //this.selectedKpiReportsIDs = [];
            }
        });
    };
    DashboardEditComponent.prototype.multiKPI = function (obj, callType, reports) {
        var outerObj = {
            id: obj.reportId,
            name: obj.name,
            is_multi: true,
            series: [],
            dim_col: obj.dim_col,
            dim_row: obj.dim_row,
            x: 0,
            y: 0
        };
        if (obj.drilldown.series.length > 0) {
            for (var j = 0; j < obj.drilldown.series.length; j++) {
                var innerObj = {
                    name: '',
                    value: '',
                    kpi_image: '',
                    cols: obj.dim_col
                };
                innerObj.name = obj.drilldown.series[j].kpi_key;
                if (obj.drilldown.series[j].data[0] != null) {
                    innerObj.value = obj.drilldown.series[j].data[0];
                }
                else {
                    innerObj.value = 'N/D';
                }
                innerObj.kpi_image = obj.drilldown.series[j].kpi_image;
                outerObj.series.push(innerObj);
            }
        }
        if (callType == "edit") {
            var cordinates = reports.find(function (r) {
                return r.id == obj.reportId;
            });
            outerObj.x = (cordinates && cordinates.x) ? cordinates.x : 0;
            outerObj.y = (cordinates && cordinates.y) ? cordinates.y : 0;
            this.DashboardAddService.addKPI(outerObj, "edit");
        }
        else {
            this.existingKPIs.push(obj.reportId);
            this.DashboardAddService.addKPI(outerObj, "add");
        }
    };
    DashboardEditComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.interval);
        window.clearInterval(this.timer);
    };
    Object.defineProperty(DashboardEditComponent.prototype, "options", {
        get: function () {
            return this.DashboardAddService.options;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardEditComponent.prototype, "layout", {
        get: function () {
            return this.DashboardAddService.layout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardEditComponent.prototype, "components", {
        get: function () {
            return this.DashboardAddService.components;
        },
        enumerable: true,
        configurable: true
    });
    DashboardEditComponent.prototype.removeFromGridster = function (chartId) {
        for (var i = 0; i < this.layout.length; i++) {
            if (this.layout[i].src.reportId == chartId) {
                this.DashboardAddService.removeItem(this.layout[i]);
            }
        }
    };
    DashboardEditComponent.prototype.calcGridsterHeight = function () {
        var kpiCount = 0;
        var chartCount = 0;
        for (var _i = 0, _a = this.layout; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item.componentName == "Chart") {
                chartCount = chartCount + item.cols;
            }
            else {
                kpiCount = kpiCount + item.cols;
            }
        }
        kpiCount = Math.ceil(kpiCount / 3);
        chartCount = Math.ceil(chartCount / 3);
        var totalKpiHeight = kpiCount * 160;
        var totalChartHeight = chartCount * 320;
        //const calcHeight = Math.ceil(this.layout.length / 3)*160;
        var calcHeight = totalChartHeight + totalKpiHeight;
        this.height = calcHeight;
    };
    DashboardEditComponent.prototype.showOptionPopup = function () {
        this.optionsModal = this.modalService.show(this.selectOptions, { backdrop: true, ignoreBackdropClick: true, class: 'modal-md-firmenich' });
        setTimeout(function () {
            document.getElementById('KPI').click();
        }, 100);
    };
    DashboardEditComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    DashboardEditComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    DashboardEditComponent.prototype.goToDashboards = function () {
        var url = "charts/dashboards/" + this.projectId;
        this.router.navigate([url]);
    };
    DashboardEditComponent.prototype.selectSection = function (ev, selectedValue) {
        var _this = this;
        this.reportsListData = [];
        this.selectedReportsMultiSelect = [];
        this.dashboardEditObject.subsection = "";
        //console.log("selectSection ",ev.target.value);
        //console.log("dashboardEditObject.sectionId ", this.dashboardEditObject.sectionId);
        var section = this.listsSectionsSubSection.filter(function (item) { return item.section == _this.dashboardEditObject.section; })[0];
        //console.log("section ", section);
        //console.log(" subsection ", section.subsection);
        if (section != undefined || section != null) {
            this.newSubSection = section.subsection;
            //let reportsItems:any =[];
            for (var i = 0; i < this.newSubSection.length; i++) {
                var reports = this.newSubSection[i].reports.filter(function (item) { return item.section == _this.dashboardEditObject.section; });
                //console.log("reports section select ", reports);
                this.reportsListData = this.reportsListData.concat(reports);
                //console.log(" reportsItems ", this.reportsListData);
            }
            //console.log("this.reportsListData ", this.reportsListData);
        }
        else {
            this.newSubSection = [];
        }
        this.subsectionFlag = false;
        this.reportsFlag = false;
    };
    DashboardEditComponent.prototype.selectKPISection = function (ev, selectedValue) {
        var _this = this;
        this.kpisListData = [];
        this.selectedKPIsMultiSelect = [];
        var section = this.kpiData.filter(function (item) { return item.section == _this.KPIAddObject.section; })[0];
        if (section != undefined || section != null) {
            this.kpisListData = section.reports;
            if (this.kpisListData.length == 0) {
                this.kpiFlag = true;
            }
            else {
                this.kpiFlag = false;
            }
        }
    };
    DashboardEditComponent.prototype.selectSubSection = function (ev) {
        var _this = this;
        this.reportsListData = [];
        //this.selectedValues.subsection = ev.target.value;
        //console.log("dashboardEditObject.subSectionId ", this.dashboardEditObject.subSectionId);
        var section = this.listsSectionsSubSection.filter(function (item) { return item.section == _this.dashboardEditObject.section; })[0];
        //this.reportsListData
        var subsection = section.subsection.filter(function (item) { return item.name == _this.dashboardEditObject.subsection; })[0];
        //console.log(" reports ", subsection);
        //this.reportsListData = subsection.reports;
        if (subsection && subsection.reports) {
            this.reportsListData = subsection.reports;
            this.selectedReportsMultiSelect = [];
        }
        else {
            this.newSubSection = section.subsection;
            for (var i = 0; i < this.newSubSection.length; i++) {
                var reports = this.newSubSection[i].reports.filter(function (item) { return item.section == _this.dashboardEditObject.section; });
                //console.log("reports section select ", reports);
                this.reportsListData = this.reportsListData.concat(reports);
                //console.log(" reportsItems ", reportsItems);
            }
            this.selectedReportsMultiSelect = [];
        }
        this.reportsFlag = false;
    };
    DashboardEditComponent.prototype.setAsDefaultDashboard = function () {
        //console.log(" setAsDefault ", this.setAsDefault);
    };
    DashboardEditComponent.prototype.preferredReportType = function (preferredReportType) {
    };
    DashboardEditComponent.prototype.genarateCharts = function (currentType, callType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, requestObject, selectedReportIds, selectedReports, result, chartsList;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                this.disableChartsButton = true;
                requestObject = {};
                if (callType == 'init') {
                    (_a = this.existingSelectedReports).push.apply(_a, this.dashboardEditObject.reportID);
                    selectedReportIds = this.dashboardEditObject.reportID;
                    requestObject = {
                        "reportID": this.editChartReports,
                        "projectId": this.projectId
                    };
                }
                else {
                    selectedReports = this.selectedReportsMultiSelect.map(function (item) { return item.id; });
                    if (this.layout.length > 0) {
                        this.existingSelectedReports = this.layout.filter(function (item) { return item.componentName == "Chart"; }).map(function (item) { return item.id; });
                        result = selectedReports.filter(function (d) { return _this.existingSelectedReports.indexOf(d) < 0; });
                        selectedReportIds = result;
                        this.existingSelectedReports = this.existingSelectedReports.concat(result);
                    }
                    else {
                        this.existingSelectedReports = this.existingSelectedReports.concat(selectedReports);
                        selectedReportIds = selectedReports;
                    }
                    requestObject = {
                        "reportID": selectedReportIds,
                        "projectId": this.projectId
                    };
                }
                chartsList = [];
                if (selectedReportIds.length > 0) {
                    this.spinner.show();
                    this.hcRService.getReports(requestObject).subscribe(function (response) {
                        if (response.msg == 'success') {
                            _this.reportAvailableFlag = true;
                            var reportsData = response.data;
                            if (reportsData && reportsData.length > 0) {
                                for (var i = 0; i < reportsData.length; i++) {
                                    var seriesArr = [];
                                    var contextMenuOptions = [];
                                    var chartOptions = reportsData[i].conf[0].conf;
                                    if (typeof reportsData[i].conf[0].conf !== 'object') {
                                        chartOptions = JSON.parse(reportsData[i].conf[0].conf);
                                    }
                                    //added by Kiran to change chart title to report name
                                    if (chartOptions.hasOwnProperty('title')) {
                                        chartOptions.title.text = reportsData[i].name;
                                    }
                                    chartOptions.dim_row = reportsData[i].dim_row;
                                    chartOptions.dim_col = reportsData[i].dim_col;
                                    //console.log("chartOptions ", chartOptions);
                                    if (reportsData[i].is_drilldown != 1) {
                                        //console.log(" chartOptions.chart.zoomType ", chartOptions.chart.zoomType);
                                        //console.log("reportsData[i].series ",reportsData[i].series.length);
                                        if (reportsData[i].series && reportsData[i].series.length > 1) {
                                            //console.log("more than 1 conf ");
                                            if (chartOptions.chart.zoomType == 'xy' && reportsData[i].conf[0].report_type == 'dual-axes') {
                                                seriesArr = [];
                                                //console.log("reportsData[i].conf[0].report_type ", reportsData[i].conf[0].report_type);
                                                var category = [];
                                                for (var x = 0; x < reportsData[i].series.length; x++) {
                                                    category.push(reportsData[i].series[x].key);
                                                    for (var y = 0; y < chartOptions.series.length; y++) {
                                                        if (chartOptions.series[y].hasOwnProperty('yAxis')) {
                                                            //chartOptions.series[y].name='';
                                                            chartOptions.series[y].data.push(Number(reportsData[i].series[x].avg_expense));
                                                        }
                                                        else {
                                                            //chartOptions.series[y].name='';
                                                            chartOptions.series[y].data.push(reportsData[i].series[x].value);
                                                        }
                                                    }
                                                }
                                                if (Array.isArray(chartOptions.xAxis)) {
                                                    chartOptions.xAxis[0].categories = category;
                                                }
                                                if (chartOptions.hasOwnProperty('yAxis')) {
                                                    var newString = chartOptions.yAxis[1].title.text + ' (' + reportsData[i].series[0].currency + ')';
                                                    chartOptions.yAxis[1].title.text = newString;
                                                }
                                                //chartOptions.legend.y=0;
                                                //chartOptions.legend.x=60;
                                                //console.log("chartOptions dual axes ", JSON.stringify(chartOptions));
                                            }
                                            else {
                                                var hcObject = {};
                                                var categories = [];
                                                var objectKeys = Object.keys(reportsData[i].series[0]);
                                                for (var j = 0; j < reportsData[i].series.length; j++) {
                                                    var elementItem = reportsData[i].series[j];
                                                    for (var k = 0; k < objectKeys.length; k++) {
                                                        if (objectKeys[k] == 'name') {
                                                            categories.push(reportsData[i].series[j][objectKeys[k]]);
                                                        }
                                                        else {
                                                            if (hcObject[objectKeys[k]]) {
                                                                hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                                                            }
                                                            else {
                                                                hcObject[objectKeys[k]] = new Array();
                                                                hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                                                            }
                                                        }
                                                    }
                                                }
                                                if (Object.keys(hcObject).length !== 0) {
                                                    for (var key in hcObject) {
                                                        var object_1 = {
                                                            name: '',
                                                            data: []
                                                        };
                                                        object_1.name = key;
                                                        object_1.data = hcObject[key];
                                                        seriesArr.push(object_1);
                                                    }
                                                }
                                                //console.log(" seriesArr time ", seriesArr);
                                                if (chartOptions.hasOwnProperty('xAxis')) {
                                                    chartOptions.xAxis.categories = categories;
                                                }
                                                if (chartOptions.hasOwnProperty('yAxis')) {
                                                    chartOptions.yAxis.min = 0;
                                                }
                                                //chartOptions.xAxis.categories = categories;
                                                //console.log("4 ", chartOptions);
                                                chartOptions.series = seriesArr;
                                                //console.log("chartOptions.series ",chartOptions.series);
                                            }
                                        }
                                        else {
                                            //console.log("reportsData[i].series ", reportsData[i].series);
                                            if (chartOptions.chart.zoomType == 'xy' && reportsData[i].conf[0].report_type == 'dual-axes') {
                                                seriesArr = [];
                                                //console.log("reportsData[i].conf[0].report_type ", reportsData[i].conf[0].report_type);
                                                var category = [];
                                                for (var x = 0; x < reportsData[i].series.length; x++) {
                                                    category.push(reportsData[i].series[x].key);
                                                    for (var y = 0; y < chartOptions.series.length; y++) {
                                                        if (chartOptions.series[y].hasOwnProperty('yAxis')) {
                                                            //chartOptions.series[y].name='';
                                                            chartOptions.series[y].data.push(Number(reportsData[i].series[x].avg_expense));
                                                        }
                                                        else {
                                                            //chartOptions.series[y].name='';
                                                            chartOptions.series[y].data.push(reportsData[i].series[x].value);
                                                        }
                                                    }
                                                }
                                                if (Array.isArray(chartOptions.xAxis)) {
                                                    chartOptions.xAxis[0].categories = category;
                                                }
                                                if (chartOptions.hasOwnProperty('yAxis')) {
                                                    if (reportsData[i].series.length > 0 && reportsData[i].series[0].hasOwnProperty('currency')) {
                                                        var newString = chartOptions.yAxis[1].title.text + ' (' + reportsData[i].series[0].currency + ')';
                                                        chartOptions.yAxis[1].title.text = newString;
                                                    }
                                                }
                                                //chartOptions.legend.y=0;
                                                //chartOptions.legend.x=60;
                                                //console.log("chartOptions dual axes ", JSON.stringify(chartOptions));
                                            }
                                            else if (chartOptions.chart.type != 'pyramid') {
                                                for (var j = 0; j < reportsData[i].series.length; j++) {
                                                    for (var prop in reportsData[i].series[j]) {
                                                        if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                                            if (Number(reportsData[i].series[0][prop]) > 0) {
                                                                var innerObj = {
                                                                    name: '',
                                                                    y: Number(''),
                                                                };
                                                                innerObj.name = prop;
                                                                innerObj.y = Number(reportsData[i].series[0][prop]);
                                                                seriesArr.push(innerObj);
                                                            }
                                                        }
                                                    }
                                                }
                                                // chartOptions.series = [
                                                //   {
                                                //     colorByPoint: true,
                                                //     data: seriesArr
                                                //   }
                                                // ];
                                                //console.log("3 ", chartOptions);
                                                if (chartOptions.hasOwnProperty('series')) {
                                                    if (chartOptions.series.length > 0) {
                                                        if (reportsData[i].conf[0].report_type == 'waterfall') {
                                                            if (chartOptions.hasOwnProperty('chart')) {
                                                                if (chartOptions.chart.hasOwnProperty('type')) {
                                                                    //console.log("waterfall")
                                                                    var totalObject = {
                                                                        name: 'Total',
                                                                        isIntermediateSum: true
                                                                    };
                                                                    seriesArr.push(totalObject);
                                                                    chartOptions.series[0].data = seriesArr;
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            chartOptions.series[0].data = seriesArr;
                                                        }
                                                    }
                                                }
                                                else {
                                                    if (reportsData[i].name === 'Accessibility to Basics') {
                                                        var categories = [];
                                                        seriesArr = [];
                                                        //console.log(reportsData[i]);
                                                        for (var j = 0; j < reportsData[i].series.length; j++) {
                                                            var object = {
                                                                name: 'Farmers',
                                                                data: []
                                                            };
                                                            for (var prop in reportsData[i].series[j]) {
                                                                if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                                                    categories.push(prop);
                                                                    // object.name = key;
                                                                    object.data.push(Number(reportsData[i].series[0][prop]));
                                                                }
                                                            }
                                                            seriesArr.push(object);
                                                        }
                                                        if (chartOptions.hasOwnProperty('xAxis')) {
                                                            chartOptions.xAxis.categories = categories;
                                                        }
                                                        if (chartOptions.hasOwnProperty('yAxis')) {
                                                            chartOptions.yAxis.min = 0;
                                                        }
                                                        chartOptions.series = seriesArr;
                                                    }
                                                    else {
                                                        chartOptions.series = [
                                                            {
                                                                colorByPoint: true,
                                                                data: seriesArr
                                                            }
                                                        ];
                                                    }
                                                }
                                            }
                                            else {
                                                for (var j = 0; j < reportsData[i].series.length; j++) {
                                                    for (var prop_1 in reportsData[i].series[j]) {
                                                        if (reportsData[i].series[0].hasOwnProperty(prop_1)) {
                                                            if (Number(reportsData[i].series[0][prop_1]) > 0) {
                                                                var newArray = [];
                                                                newArray[0] = prop_1;
                                                                newArray[1] = Number(reportsData[i].series[0][prop_1]);
                                                                seriesArr.push(newArray);
                                                            }
                                                        }
                                                    }
                                                }
                                                //console.log("seriesArr pyramid", seriesArr);
                                                seriesArr.sort(function (a, b) {
                                                    return a[1] < b[1] ? 1 : -1;
                                                });
                                                //console.log("seriesArr pyramid", seriesArr);
                                                // chartOptions.series = [
                                                //   {
                                                //     colorByPoint: true,
                                                //     data: seriesArr
                                                //   }
                                                // ];
                                                //console.log("2 ", chartOptions);
                                                if (chartOptions.hasOwnProperty('series')) {
                                                    chartOptions.series[0].data = seriesArr;
                                                }
                                                else {
                                                    chartOptions.series = [
                                                        {
                                                            colorByPoint: true,
                                                            data: seriesArr
                                                        }
                                                    ];
                                                }
                                            }
                                            //console.log("seriesArr ", seriesArr);
                                        }
                                    }
                                    else if (reportsData[i].is_drilldown == 1) {
                                        //console.log("Drill down ", chartOptions);
                                        //console.log("reportsData[i] ",reportsData[i].drilldown);
                                        for (var j = 0; j < reportsData[i].series.length; j++) {
                                            for (var prop_2 in reportsData[i].series[j]) {
                                                if (reportsData[i].series[0].hasOwnProperty(prop_2)) {
                                                    if (Number(reportsData[i].series[0][prop_2]) > 0) {
                                                        var innerObj_1 = {
                                                            name: '',
                                                            y: Number(''),
                                                            drilldown: ''
                                                        };
                                                        //let itemVal = Number(reportsData[i].series[0][prop]).toFixed(1);
                                                        //console.log("Number(reportsData[i].series[0][prop]) ",itemVal);
                                                        innerObj_1.name = prop_2;
                                                        innerObj_1.y = Number(reportsData[i].series[0][prop_2]);
                                                        var drilldownSeriesData = reportsData[i].drilldown.series;
                                                        for (var x = 0; x < drilldownSeriesData.length; x++) {
                                                            if (drilldownSeriesData[x].name == prop_2 && drilldownSeriesData[x].id == prop_2) {
                                                                innerObj_1.drilldown = prop_2;
                                                            }
                                                        }
                                                        seriesArr.push(innerObj_1);
                                                        //console.log("seriesArr ", seriesArr);
                                                    }
                                                }
                                            }
                                        }
                                        //console.log("1 ", chartOptions);
                                        if (reportsData[i].conf[0].report_type != 'combination') {
                                            if (chartOptions.hasOwnProperty('series')) {
                                                chartOptions.series[0].data = seriesArr;
                                            }
                                            else {
                                                chartOptions.series = [
                                                    {
                                                        colorByPoint: true,
                                                        data: seriesArr
                                                    }
                                                ];
                                            }
                                            var drilldownSeriesData = reportsData[i].drilldown.series;
                                            for (var x = 0; x < drilldownSeriesData.length; x++) {
                                                //let drillData = drilldownSeriesData[x].data;
                                                var drillOptionsData = [];
                                                if (drilldownSeriesData[x].has_child == 1) {
                                                    drilldownSeriesData[x]['keys'] = ['name', 'y', 'drilldown'];
                                                }
                                                if (drilldownSeriesData[x].hasOwnProperty('drill_report_type')) {
                                                    if (drilldownSeriesData[x].drill_report_type != null && drilldownSeriesData[x].drill_report_type != '') {
                                                        drilldownSeriesData[x].type = drilldownSeriesData[x].drill_report_type;
                                                        //Object.assign(chartOptions.drilldown.series[x], {type: "value"})
                                                        //console.log("chartOptions.drilldown.series[x]. ", chartOptions.drilldown.series[x]);
                                                        //chartOptions.drilldown.series[x].type = drilldownSeriesData[x].drill_report_type;
                                                    }
                                                }
                                            }
                                            if (reportsData[i].reportId == 102) {
                                                if (drilldownSeriesData[0].hasOwnProperty('data')) {
                                                    for (var x = 0; x < drilldownSeriesData[0].data.length; x++) {
                                                        if (drilldownSeriesData[0].data[x][2] == 'Above 15 years') {
                                                            drilldownSeriesData[0].data[x][2] = '';
                                                        }
                                                    }
                                                }
                                            }
                                            if (reportsData[i].reportId == 118) {
                                                if (drilldownSeriesData[1].hasOwnProperty('data')) {
                                                    for (var x = 0; x < drilldownSeriesData[1].data.length; x++) {
                                                        if (drilldownSeriesData[1].data[x][2] == 'No') {
                                                            drilldownSeriesData[1].data[x][2] = '';
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        else {
                                            if (chartOptions.hasOwnProperty('xAxis')) {
                                                chartOptions.xAxis.categories = reportsData[i].series.categories;
                                            }
                                            if (reportsData[i].series.categories.length > 0) {
                                                var seriesData = reportsData[i].series.series;
                                                //console.log("seriesData ", seriesData);
                                                for (var i_1 = 0; i_1 < seriesData.length; i_1++) {
                                                    if (seriesData[i_1].type == 'pie') {
                                                        seriesData[i_1].center = [20, 0];
                                                        seriesData[i_1].size = 50;
                                                        seriesData[i_1].showInLegend = false;
                                                        seriesData[i_1].dataLabels = {
                                                            enabled: false
                                                        };
                                                        seriesData[i_1].name = "Farmers";
                                                    }
                                                    else {
                                                        seriesData[i_1].name = "Farmers";
                                                    }
                                                }
                                                chartOptions.series = reportsData[i].series.series;
                                            }
                                            else {
                                                chartOptions.series = [];
                                                //chartOptions.labels.items[0].html = '';
                                                if (chartOptions.hasOwnProperty('labels')) {
                                                    chartOptions.labels.items[0].html = '';
                                                }
                                            }
                                        }
                                    }
                                    //console.log(" seriesArr ", seriesArr)
                                    //for(let j=0;j < reportsData[i].conf[0].length; j++){
                                    //console.log(" reportsData[i].conf ", reportsData[i].conf[0].conf);
                                    // chartOptions.chart.width = 320;
                                    //chartOptions.chart.height = "100%";
                                    //chartOptions.chart_type = chartOptions.chart.type;
                                    if (chartOptions.chart.hasOwnProperty('type')) {
                                        chartOptions.chart_type = chartOptions.chart.type;
                                    }
                                    else {
                                        if (chartOptions.chart.hasOwnProperty('charttype')) {
                                            chartOptions.chart_type = chartOptions.chart.charttype;
                                        }
                                    }
                                    chartOptions.title.style = {
                                        fontSize: "16px",
                                        fontWeight: '600'
                                    };
                                    chartOptions.reportId = reportsData[i].reportId;
                                    if (reportsData[i].is_drilldown == 1) {
                                        chartOptions.drilldown = reportsData[i].drilldown;
                                        //chartOptions.drilldown.drillUpButton = {"position":{"verticalAlign":"top"}};
                                        //chartOptions.drilldown.drillUpButton = {"position":{"x":-25,"y":-35}};
                                        //added by Kiran for drillup button
                                        var drillUp = {
                                            relativeTo: 'spacingBox',
                                            position: {
                                                align: 'left',
                                                y: -10,
                                                x: 0
                                            },
                                            theme: {
                                                fill: '#63775c',
                                                'stroke-width': 1,
                                                r: 25,
                                                states: {
                                                    hover: {
                                                        fill: '#63775c'
                                                    },
                                                    select: {
                                                        fill: '#63775c'
                                                    }
                                                }
                                            }
                                        };
                                        chartOptions.drilldown.drillUpButton = drillUp;
                                        if (chartOptions.hasOwnProperty('lang')) {
                                            chartOptions.lang.drillUpText = '⇦';
                                        }
                                        else {
                                            var drillText = {
                                                drillUpText: '⇦'
                                            };
                                            chartOptions.lang = drillText;
                                        }
                                        //var defaultTitleXAxis = "Percentage Range";
                                        //var defaultTitleYAxis = "Avg age of children";
                                        var defaultTitleYAxis = "";
                                        //var drilldownTitleXAxis = "School Name";
                                        //var drilldownTitleYAxis = "Count of children";
                                        var drilldownTitleYAxis = "";
                                        if (chartOptions.hasOwnProperty('yAxis')) {
                                            if (Array.isArray(chartOptions.yAxis)) {
                                                defaultTitleYAxis = chartOptions.yAxis[0].title.text;
                                            }
                                            else {
                                                if (chartOptions.yAxis.hasOwnProperty('title')) {
                                                    defaultTitleYAxis = chartOptions.yAxis.title.text;
                                                }
                                            }
                                        }
                                        // if(chartOptions.hasOwnProperty('yAxisDrilldownTitle')){
                                        //   drilldownTitleYAxis = chartOptions.yAxisDrilldownTitle.text;
                                        // }
                                        chartOptions.chart.events = {
                                            drilldown: function (e) {
                                                var curConfig = this.options;
                                                var curTitle = curConfig.title.text;
                                                if (curConfig.hasOwnProperty('drilltitle1')) {
                                                    if (curTitle == curConfig.drilltitle1.text) {
                                                        if (curConfig.hasOwnProperty('drilltitle2')) {
                                                            this.setTitle({ text: curConfig.drilltitle2.text }, { text: '' });
                                                        }
                                                    }
                                                    else {
                                                        this.oldTitle = curTitle;
                                                        this.oldSubtitle = curConfig.subtitle.text;
                                                        if (curConfig.hasOwnProperty('drilltitle2')) {
                                                            this.setTitle({ text: curConfig.drilltitle1.text }, { text: 'Drilldown for details' });
                                                        }
                                                        else {
                                                            this.setTitle({ text: curConfig.drilltitle1.text }, { text: '' });
                                                        }
                                                    }
                                                }
                                                var thisChart = this.yAxis[0].chart.options;
                                                if (thisChart.hasOwnProperty('yAxis')) {
                                                    if (Array.isArray(thisChart.yAxis)) {
                                                        if (thisChart.yAxis[0].hasOwnProperty('title')) {
                                                            this.oldYaxisTitle = thisChart.yAxis[0].title.text;
                                                        }
                                                    }
                                                    else {
                                                        if (thisChart.yAxis.hasOwnProperty('title')) {
                                                            this.oldYaxisTitle = thisChart.yAxis.title.text;
                                                        }
                                                    }
                                                }
                                                if (thisChart.hasOwnProperty('yAxisDrilldownTitle')) {
                                                    if (this.hasOwnProperty('yAxis')) {
                                                        if (Array.isArray(this.yAxis)) {
                                                            this.yAxis[0].setTitle({ text: thisChart.yAxisDrilldownTitle.text });
                                                        }
                                                        else {
                                                            if (this.yAxis.hasOwnProperty('title')) {
                                                                this.yAxis.setTitle({ text: thisChart.yAxisDrilldownTitle.text });
                                                            }
                                                        }
                                                    }
                                                }
                                            },
                                            drillup: function (e) {
                                                var curConfig = this.options;
                                                var curTitle = curConfig.title.text;
                                                if (curConfig.hasOwnProperty('drilltitle1')) {
                                                    if (curTitle == curConfig.drilltitle1.text) {
                                                        this.setTitle({ text: this.oldTitle }, { text: this.oldSubtitle });
                                                    }
                                                    else {
                                                        this.setTitle({ text: curConfig.drilltitle1.text }, { text: 'Drilldown for details' });
                                                    }
                                                }
                                                if (this.hasOwnProperty('yAxis')) {
                                                    if (Array.isArray(this.yAxis)) {
                                                        this.yAxis[0].setTitle({ text: this.oldYaxisTitle });
                                                    }
                                                    else {
                                                        if (this.yAxis.hasOwnProperty('title')) {
                                                            this.yAxis.setTitle({ text: this.oldYaxisTitle });
                                                        }
                                                    }
                                                }
                                            }
                                        };
                                    }
                                    if (chartOptions.chart.type == 'pie') {
                                        if (chartOptions.hasOwnProperty("plotOptions")) {
                                            if (chartOptions.plotOptions.hasOwnProperty("pie")) {
                                                chartOptions.plotOptions.pie.dataLabels = {
                                                    enabled: true,
                                                    format: '{point.percentage:.1f} %',
                                                    distance: -20,
                                                    color: '#fafafa'
                                                };
                                                chartOptions.plotOptions.pie.showInLegend = true;
                                            }
                                        }
                                        chartOptions.legend = {
                                            enabled: true
                                        };
                                    }
                                    if (chartOptions.chart.type == 'pyramid') {
                                        if (chartOptions.hasOwnProperty("plotOptions")) {
                                            if (chartOptions.plotOptions.hasOwnProperty("series")) {
                                                chartOptions.plotOptions.series.dataLabels = {
                                                    enabled: true,
                                                    format: '{point.y:,.0f}',
                                                    softConnector: true,
                                                    distance: -25,
                                                    color: '#fafafa'
                                                };
                                                chartOptions.plotOptions.series.width = '100%';
                                            }
                                        }
                                        chartOptions.title.x = 0;
                                    }
                                    if (chartOptions.chart.hasOwnProperty("charttype")) {
                                        //console.log("chartOptions.chart.charttype ", chartOptions.chart.charttype);
                                        if (chartOptions.chart.charttype == 'circle') {
                                            seriesArr = [];
                                            for (var j = 0; j < reportsData[i].series.length; j++) {
                                                for (var prop_3 in reportsData[i].series[j]) {
                                                    if (reportsData[i].series[0].hasOwnProperty(prop_3)) {
                                                        if (reportsData[i].series[0][prop_3] != null) {
                                                            //if(Number(reportsData[i].series[0][prop]) > 0){
                                                            var innerObj_2 = {
                                                                name: 'Farmers',
                                                                y: Number(''),
                                                                color: '#50C1BF'
                                                            };
                                                            //innerObj.name = prop;
                                                            //console.log("reportsData[i].series[0][prop] ", reportsData[i].series[0][prop]);
                                                            var x = reportsData[i].series[0][prop_3].split('/');
                                                            //console.log("x ", x);
                                                            innerObj_2.y = Number(x[0]);
                                                            seriesArr.push(innerObj_2);
                                                            // }
                                                        }
                                                    }
                                                }
                                            }
                                            chartOptions.series[0].data = seriesArr;
                                        }
                                    }
                                    if (reportsData[i].conf[0].report_type == 'column_placement') {
                                        seriesArr = [];
                                        //console.log("reportsData[i] ", reportsData[i]);
                                        delete chartOptions.xAxis.type;
                                        chartOptions.xAxis.categories = reportsData[i].series.categories;
                                        //console.log("reportsData[i].series ", reportsData[i].series);
                                        //console.log("reportsData[i].series ", reportsData[i].series.series);
                                        var seriesData = reportsData[i].series.series;
                                        chartOptions.series = [];
                                        var colorsDataObject = [
                                            {
                                                name: '',
                                                color: 'rgba(165,170,217,1)',
                                                data: [],
                                                pointPadding: 0.3,
                                                pointPlacement: -0.2
                                            }, {
                                                name: '',
                                                color: 'rgba(126,86,134,.9)',
                                                data: [],
                                                pointPadding: 0.4,
                                                pointPlacement: -0.2
                                            }, {
                                                name: '',
                                                color: 'rgba(248,161,63,1)',
                                                data: [],
                                                pointPadding: 0.3,
                                                pointPlacement: 0.2,
                                                yAxis: 1
                                            }, {
                                                name: '',
                                                color: 'rgba(186,60,61,.9)',
                                                data: [],
                                                pointPadding: 0.4,
                                                pointPlacement: 0.2,
                                                yAxis: 1
                                            }
                                        ];
                                        //console
                                        for (var k = 0; k < seriesData.length; k++) {
                                            //console.log("seriesData.name ", seriesData[k].name);
                                            //console.log("seriesData.name ", seriesData[k].data);
                                            // for(let j=0; j < chartOptions.series.length;j++){
                                            //chartOptions.series[k].name = seriesData[k].name;
                                            //chartOptions.series[k].data = seriesData[k].data;
                                            colorsDataObject[k].name = seriesData[k].name;
                                            colorsDataObject[k].data = seriesData[k].data;
                                            // }
                                        }
                                        chartOptions.series = colorsDataObject;
                                        //console.log(" column placement ", chartOptions);
                                    }
                                    if (reportsData[i].conf[0].report_type == 'column-stacked') {
                                        seriesArr = [];
                                        if (chartOptions.xAxis.hasOwnProperty('type')) {
                                            delete chartOptions.xAxis.type;
                                        }
                                        chartOptions.xAxis.categories = reportsData[i].series.categories;
                                        var seriesData = reportsData[i].series.series;
                                        for (var k = 0; k < seriesData.length; k++) {
                                            //     chartOptions.series[k].name = seriesData[k].name;
                                            //     chartOptions.series[k].data = seriesData[k].data;
                                            seriesData[k].data = seriesData[k].data.map(function (val, i) {
                                                return val === 0 ? null : val;
                                            });
                                            chartOptions.series.push(seriesData[k]);
                                        }
                                        //console.log(" column placement ", chartOptions);
                                    }
                                    chartOptions.reportTypeFrontend = currentType;
                                    chartOptions.chart.backgroundColor = '#fafafa';
                                    chartOptions.chart.spacingTop = 20;
                                    //chartOptions.chart.spacingBottom = 20;
                                    //added by Kiran for currency on zAxis
                                    if (reportsData[i].reportId == 93 || reportsData[i].reportId == 87 || reportsData[i].reportId == 135) {
                                        if (reportsData[i].hasOwnProperty('currency')) {
                                            chartOptions.yAxis[1].title.text = chartOptions.yAxis[1].title.text + ' (' + reportsData[i].currency + ')';
                                            if (reportsData[i].reportId == 87) {
                                                chartOptions.yAxis[0].title.text = chartOptions.yAxis[0].title.text + ' (' + reportsData[i].currency + ')';
                                            }
                                        }
                                    }
                                    if (reportsData[i].reportId == 49) {
                                        if (reportsData[i].hasOwnProperty('currency')) {
                                            chartOptions.yAxis.title.text = chartOptions.yAxis.title.text + ' (' + reportsData[i].currency + ')';
                                        }
                                    }
                                    //console.log("reportsData[i].conf ",reportsData[i].conf);
                                    if (reportsData[i].conf.length > 1) {
                                        contextMenuOptions = [
                                            'viewFullscreen',
                                            // {
                                            //   text: 'Change chart type ',
                                            //   onclick: function(this,event){
                                            //     let self = this;
                                            //     parentScope01.changeChartType(self);
                                            //   }
                                            //   },
                                            {
                                                textKey: 'downloadPDF',
                                                onclick: function () {
                                                    this.exportChart({
                                                        type: 'application/pdf'
                                                    });
                                                }
                                            },
                                            {
                                                textKey: 'downloadPNG',
                                                onclick: function () {
                                                    this.exportChart();
                                                }
                                            },
                                            {
                                                textKey: 'downloadXLS',
                                                onclick: function () {
                                                    this.downloadXLS();
                                                }
                                            },
                                            {
                                                text: 'Remove',
                                                onclick: function () {
                                                    var self = this;
                                                    parentScope01.removeFromGridster(self.userOptions.reportId); //reportsData[i].reportId);
                                                }
                                            }
                                            // {
                                            //   textKey: 'viewData',
                                            //   text: 'Toggle data table',
                                            //   onclick: function (this) {
                                            //     if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                                            //       this.dataTableDiv.style.display = 'none';
                                            //     } else {
                                            //       this.viewData();
                                            //       this.dataTableDiv.style.display = '';
                                            //     }
                                            //   }
                                            // }
                                        ];
                                    }
                                    else {
                                        if (!contextMenuOptions || !Array.isArray(contextMenuOptions)) {
                                            contextMenuOptions = [];
                                        }
                                        contextMenuOptions = [
                                            'viewFullscreen',
                                            {
                                                textKey: 'downloadPDF',
                                                onclick: function () {
                                                    this.exportChart({
                                                        type: 'application/pdf'
                                                    });
                                                }
                                            },
                                            {
                                                textKey: 'downloadPNG',
                                                onclick: function () {
                                                    this.exportChart();
                                                }
                                            },
                                            {
                                                textKey: 'downloadXLS',
                                                onclick: function () {
                                                    this.downloadXLS();
                                                }
                                            },
                                            {
                                                text: 'Remove',
                                                onclick: function () {
                                                    var self = this;
                                                    parentScope01.removeFromGridster(self.userOptions.reportId); //reportsData[i].reportId);
                                                }
                                            }
                                            // {
                                            //   textKey: 'viewData',
                                            //   text: 'Toggle data table',
                                            //   onclick: function (this) {
                                            //     if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                                            //       this.dataTableDiv.style.display = 'none';
                                            //     } else {
                                            //       this.viewData();
                                            //       this.dataTableDiv.style.display = '';
                                            //     }
                                            //   }
                                            // }
                                        ];
                                    }
                                    //console.log("contextMenuOptions ", contextMenuOptions);
                                    chartOptions.exporting.buttons.contextButton.menuItems = contextMenuOptions;
                                    chartOptions.exporting.filename = reportsData[i].name;
                                    //chartOptions.exporting.buttons.contextButton.className ='highcharts-button-normal';
                                    // chartOptions.legend = {
                                    //   enabled: falseS
                                    // };
                                    // chartOptions.exporting.menuItemDefinitions= {
                                    // 	viewFullscreen: {
                                    // 		onclick: function () {
                                    //       //this.fullscreen = new Highcharts.FullScreen(this.container);
                                    //       Highcharts.FullScreen.init(this.renderTo);
                                    //     	console.log('Test')
                                    // 		}
                                    // 	}
                                    // }
                                    //added bt KIran for export button position
                                    chartOptions.navigation = {
                                        buttonOptions: {
                                            verticalAlign: 'top',
                                            y: -5
                                        }
                                    };
                                    //console.log("chartOptions ", chartOptions);
                                    //console.log(" json sting ", JSON.stringify(chartOptions));
                                    //console.log("chartOptions.series ", chartOptions.series);
                                    //chartsList.push(chartOptions);
                                    var KPIobject = {};
                                    chartsList.push(chartOptions);
                                    if (callType == "init") {
                                        for (var j = 0; j < _this.chartReports.length; j++) {
                                            if (reportsData[i].reportId == _this.chartReports[j].report_id) {
                                                var newChartObj = {
                                                    "id": _this.chartReports[j].report_id,
                                                    "name": _this.chartReports[j].hc_report_hdr.hc_report_name[0].name,
                                                    "type": _this.chartReports[j].report_type,
                                                    "sortOrder": _this.chartReports[j].sort_order,
                                                    "x": _this.chartReports[j].x_axis,
                                                    "y": _this.chartReports[j].y_axis,
                                                    "dim_col": _this.chartReports[j].hc_report_hdr.dim_col,
                                                    "dim_row": _this.chartReports[j].hc_report_hdr.dim_row,
                                                    "chartOptions": chartOptions
                                                };
                                                var KPIobject_1 = { "reportId": reportsData[i].reportId, "col": reportsData[i].dim_col, "row": reportsData[i].dim_row, "type": 'Chart', "src": newChartObj };
                                                _this.DashboardAddService.addChart(KPIobject_1, "edit");
                                            }
                                        }
                                        // this.dashboardEditObject.chartDataOptions.chartOptions = chartOptions;
                                        // KPIobject = {"reportId":reportsData[i].reportId,"col":reportsData[i].dim_col,"row":reportsData[i].dim_row, "type":'Chart',"src":this.dashboardEditObject.chartDataOptions};
                                        // this.DashboardAddService.addChart(KPIobject,"edit");
                                    }
                                    else {
                                        KPIobject = { "reportId": reportsData[i].reportId, "dim_col": reportsData[i].dim_col, "dim_row": reportsData[i].dim_row, "type": 'Chart', "src": chartOptions };
                                        _this.DashboardAddService.addChart(chartOptions, "add");
                                    }
                                    _this.dashboardIDs.push(KPIobject);
                                    _this.calcGridsterHeight();
                                    //}
                                }
                            }
                            else {
                                if (_this.existingSelectedReports && _this.existingSelectedReports.length == 0) {
                                    _this.chartElement01.nativeElement.innerHTML = '';
                                    _this.hcRService.allCharts = [];
                                }
                            }
                            if (chartsList && chartsList.length > 0) {
                                //   //this.chartElement01.nativeElement.innerHTML = '';
                                //   //this.hcRService.allCharts = [];
                                //   for(let i=0;i<chartsList.length;i++){
                                //     //chartsList[i].exporting.buttons.contextButton.menuItems = contextMenuOptions;
                                //     let e = document.createElement("div");
                                //     let newElement = this.chartElement01.nativeElement.appendChild(e);
                                //     // this.renderer.addClass(newElement, 'chartbox');
                                //     // this.renderer.addClass(newElement, 'chartbox-add');
                                //     if(chartsList[i].reportId == 88){
                                //       this.renderer.addClass(newElement, 'col-md-4');
                                //       this.renderer.addClass(newElement, 'col-lg-8');
                                //     }else{
                                //       this.renderer.addClass(newElement, 'col-md-4');
                                //       this.renderer.addClass(newElement, 'col-lg-4');
                                //     }
                                //     this.renderer.addClass(newElement, 'pl-0');
                                //     this.renderer.addClass(newElement, 'pr-0');
                                //     let f = document.createElement("div");
                                //     let newElement1 = newElement.appendChild(f);
                                //     this.renderer.addClass(newElement1, 'chartBox-pdmr');
                                //     newElement1.setAttribute('id',+Math.random().toString().slice(2, 6));
                                //     this.hcRService.createChart(newElement1, chartsList[i]);
                                //     this.exportBtns = true;
                                //     //var el = document.getElementById('test');
                                //     //var newEl = document.createElement('button');
                                //     //el.insertAdjacentHTML('afterend', '<button id="two">two</button>');
                                //   }
                                //   if(currentType == 'predefined'){
                                //     this.profilingFlag = true;
                                //   }else{
                                //     //this.exportBtns = true;
                                //     this.customFlag = true;
                                //   }
                                // // }else{
                                // //   //this.chartElement02.nativeElement.innerHTML = '';
                                // //   //this.hcRService.allCharts = [];
                                // //   for(let i=0;i<chartsList.length;i++){
                                // //     let e = document.createElement("div");
                                // //     let newElement = this.chartElement02.nativeElement.appendChild(e);
                                // //     this.renderer.addClass(newElement, 'chartbox');
                                // //     this.renderer.addClass(newElement, 'chartbox-add');
                                // //     this.renderer.addClass(newElement, 'col-lg-6');
                                // //     newElement.setAttribute('id',+Math.random().toString().slice(2, 6));
                                // //     this.hcRService.createChart(newElement, chartsList[i]);
                                // //     this.exportBtns = true;
                                // //     this.customFlag = true;
                                // //   }
                                // // }
                                // this.checkChartsAvailable = this.hcRService.allCharts;
                                // //this.selectedReportsMultiSelect = [];
                                // //this.selectedReportsMultiSelectCustom = [];
                                // //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
                            }
                        }
                        else {
                            //this.existingSelectedReports = [];
                        }
                        _this.spinner.hide();
                        _this.disableChartsButton = false;
                        if (callType != "init") {
                            _this.commonService.showToast('success', 'selected_charts_added_successfully', {});
                        }
                    });
                }
                else {
                    this.commonService.showToast('info', 'no_new_report_selected', {});
                }
                return [2 /*return*/];
            });
        });
    };
    DashboardEditComponent.prototype.editDashboard = function (myForm) {
        var _this = this;
        //console.log("  myForm ", myForm.value);
        //console.log(" this.editDashboardForm ", this.editDashboardForm);
        if (myForm.valid) {
            // if(this.reportTypes == 'predefined'){
            var isValid = this.dashboardEditObject.section && this.selectedReportsMultiSelect.length > 0;
            // if(isValid){
            //console.log(" this.dashboardAddObject ", this.dashboardAddObject);
            //this.toastrService.info('API integration pending ');
            var chartsList = this.layout;
            //console.log("chartsList ", chartsList);
            if (chartsList.length > 0) {
                var selectedReports = chartsList.map(function (item, index) {
                    var container = { "id": 0, "type": '', "sortOrder": 0, "x": 0, "y": 0 };
                    container.id = item.id;
                    container.type = item.componentName;
                    container.sortOrder = index;
                    container.x = item.x;
                    container.y = item.y;
                    return container;
                });
                var requestParams = {
                    "dashboardId": this.dashboardId,
                    "projectId": this.projectId,
                    "dashboardType": 'self',
                    "reportId": selectedReports,
                    "name": this.dashboardEditObject.name,
                    "description": this.dashboardEditObject.description,
                    "isDefault": this.setAsDefault
                };
                //console.log("  requestParams ", requestParams);
                //return;
                this.hcRService.updateDashboard(requestParams).subscribe(function (response) {
                    //console.log(" response ", response);
                    if (response.msg == 'success') {
                        _this.commonService.showToast('success', 'dashboard_updated_success', {});
                        var url = "charts/dashboards/" + _this.projectId;
                        _this.router.navigate([url]);
                    }
                });
            }
            else {
                this.commonService.showToast('error', 'please_generate_one_report_update', {});
            }
            // }else{
            //   this.toastrService.error('Please fill the mandatory fields ');
            // }
            // }else{
            //   const isValid = this.selectedReportsMultiSelectCustom.length > 0;
            //   if(isValid){
            //     this.toastrService.info('Custom Reports are not available ');
            //     //console.log(" this.dashboardAddObject ", this.dashboardAddObject);
            //   }else{
            //     this.toastrService.error('Please fill the mandatory fields ');
            //   }
            // }
        }
        else {
            //console.log("invalid form ");
            this.commonService.showToast('error', 'please_fill_mandatory_fields', {});
        }
    };
    DashboardEditComponent.prototype.cancel = function (editDashboardForm) {
        // this.selectedReportsMultiSelectCustom = [];
        // this.dashboardEditObject.sectionId = 0;
        // this.dashboardEditObject.subSectionId = 0;
        // this.selectedReportsMultiSelect = [];
        // this.chartElement01.nativeElement.remove();
        // let url="charts/dashboards/"+ this.projectId;
        // this.router.navigate([url]);
        if (editDashboardForm.form.dirty) {
            this.confirmModal = this.modalService.show(this.confirmTemplate, { backdrop: true, ignoreBackdropClick: true });
        }
        else {
            var url = "charts/dashboards/" + this.projectId;
            this.router.navigate([url]);
        }
    };
    DashboardEditComponent.prototype.closeModal = function (isOkay) {
        this.confirmModal.hide();
        if (isOkay) {
            var url = "charts/dashboards/" + this.projectId;
            this.router.navigate([url]);
        }
    };
    DashboardEditComponent.prototype.trackBySectionName = function (index, dataItem) {
        return dataItem.section;
    };
    DashboardEditComponent.prototype.trackBySubsectionName = function (index, dataItem) {
        return dataItem.name;
    };
    DashboardEditComponent.prototype.ngAfterViewInit = function () {
        //console.log("after view init");
        //console.log("chartsOptions ", this.chartsOptions);
        //this.chartCreateFun();
    };
    // chartCreateFun(){
    //   for(let i=0;i<this.chartsOptions.length;i++){
    //     //console.log("create charts ");
    //     let e = document.createElement("div");
    //     let newElement = this.chartElement01.nativeElement.appendChild(e);
    //     this.renderer.addClass(newElement, 'chartbox');
    //     this.renderer.addClass(newElement, 'col-lg-6');
    //     //newElement.addClass('myClass');
    //     this.chartService.createChart(newElement, this.chartsOptions[i]);
    //   }
    // }
    DashboardEditComponent.prototype.reportTypeChange = function (typeOfReport) {
        //console.log(typeOfReport);
    };
    DashboardEditComponent.prototype.closePopUp = function () {
        var _this = this;
        //form.resetForm();
        this.modalService.hide(1);
        setTimeout(function () {
            _this.dashboardEditObject.section = 0;
            _this.dashboardEditObject.subsection = '';
            _this.KPIAddObject.section = 0;
            _this.subsectionFlag = true;
            _this.kpiFlag = true;
            _this.selectedKPIsMultiSelect = [];
            _this.selectedReportsMultiSelect = [];
        }, 500);
    };
    DashboardEditComponent.prototype.trackByName = function (index, dataItem) {
        return dataItem.section;
    };
    DashboardEditComponent.prototype.RemoveChart = function (itemScope) {
        //console.log(itemScope);
        parentScope01.removeFromList(itemScope.id, itemScope.userOptions.reportId, itemScope);
        itemScope.renderTo.remove();
        //self.renderTo.remove();
        //self.destory();
        //self.remove();
    };
    DashboardEditComponent.prototype.removeFromList = function (item, reportId, currentItem) {
        var charts = this.hcRService.allCharts;
        var profilingReports;
        var customReports;
        var newCharts = charts.filter(function (item1) { return item1.id != item; });
        this.hcRService.allCharts = newCharts;
        this.existingSelectedReports = this.existingSelectedReports.filter(function (item3) { return item3 != reportId; });
        profilingReports = newCharts;
        customReports = newCharts;
        if (this.reportTypes == 'predefined') {
            this.selectedReportsMultiSelect = this.selectedReportsMultiSelect.filter(function (item2) { return item2.id != reportId; });
            profilingReports = profilingReports.filter(function (item3) { return item3.reportTypeFrontend == currentItem.reportTypeFrontend; });
        }
        else {
            this.selectedReportsMultiSelectCustom = this.selectedReportsMultiSelectCustom.filter(function (item2) { return item2.id != reportId; });
            customReports = customReports.filter(function (item3) { return item3.reportTypeFrontend == currentItem.reportTypeFrontend; });
        }
        this.checkChartsAvailable = this.hcRService.allCharts;
        if (this.hcRService.allCharts.length == 0) {
            this.profilingFlag = false;
            this.customFlag = false;
        }
        if (profilingReports && profilingReports.length > 0) {
            this.profilingFlag = true;
        }
        else {
            this.profilingFlag = false;
        }
        if (customReports && customReports.length > 0) {
            this.customFlag = true;
        }
        else {
            this.customFlag = false;
        }
    };
    DashboardEditComponent.prototype.changeChartType = function (currentItemScope) {
        //debugger;
        //parentScope01.changeChartType
        //console.log("currentItemScope ", currentItemScope);
        //console.log("currentItemScope Before ", currentItemScope.userOptions.chart.type);
        if (currentItemScope.userOptions.chart.type == 'column') {
            currentItemScope.update({
                chart: {
                    type: 'pie'
                }
            });
        }
        else if (currentItemScope.userOptions.chart.type == 'pie') {
            currentItemScope.update({
                chart: {
                    type: 'column'
                }
            });
        }
        //console.log("currentItemScope After ", currentItemScope.userOptions.chart.type);
    };
    DashboardEditComponent.prototype.deleteDashboard = function () {
        var _this = this;
        var requestParams = {
            "dashboardId": Number(this.dashboardId),
            "projectId": this.projectId
        };
        //console.log("requestParams ", requestParams);
        this.hcRService.deleteDashboard(requestParams).subscribe(function (response) {
            //console.log("response ", response);
            if (response.msg == 'success') {
                _this.commonService.showToast('success', 'dashboard_deleted_success', {});
                var url = "charts/dashboards/" + _this.projectId;
                _this.router.navigate([url]);
            }
        });
    };
    DashboardEditComponent.prototype.clearAllCharts = function () {
        //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
        this.DashboardAddService.deleteAll();
    };
    DashboardEditComponent.prototype.onSelectAllKPI = function (ele) {
        document.getElementById('KPI').click();
    };
    DashboardEditComponent.prototype.onSelectAllChart = function (ele) {
        document.getElementById('Chart').click();
    };
    DashboardEditComponent.prototype.onResize = function (event) {
        var gridsterWidth = document.getElementById("gridsterBase").offsetWidth;
        this.chartWidth = (gridsterWidth / 3) - 15;
    };
    return DashboardEditComponent;
}());
export { DashboardEditComponent };
