// TODO: isDisplayed = true / false for questions in field data table
// TODO: Solve for case where table is not in currentSection
// TODO: Offline quick notes - various scenarios
// TODO: Copy over 0 / 2/ 4 decimal regex from old questionnaire to new questionnaire master
// TODO: Remove row from linked table on removal action
// TODO: UOM & Other question to be handled in getAllDisplayedQuestions - modify the backend logic accordngly
// TODO: which_crop_intercropping_surv_crop, crops_intercropping_with_surv_crop - handle via some displayFunction - DO MORE TESTING!!
// TODO: Handle comma-separated displayFunction key id updates when creating survey
// TODO: Handle migration of data for which_crop_intercropping_surv_crop, crops_intercropping_with_surv_crop

import { Component, OnInit, ViewChild, TemplateRef, LOCALE_ID, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// Services
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { FarmerService } from '../../services/farmer.service';
import { v4 as uuidv4 } from 'uuid';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
declare var idbApp: any;
declare var serviceWorkerVar;
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TranslateService } from '@ngx-translate/core';
import { Console } from 'console';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import Compressor from 'compressorjs';
//import {PdfViewerComponent} from 'ng2-pdf-viewer';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
@Component({
  selector: 'app-dynamic-questionnaire',
  templateUrl: './dynamic-questionnaire.component.html',
  styleUrls: ['./dynamic-questionnaire.component.css'],
  host: { '(window:partial-synced)': 'syncDone($event)' }
})
export class DynamicQuestionnaireComponent implements OnInit {
  @ViewChild('exitConfirmModalTemplate') exitConfirmModalTemplate: TemplateRef<any>;
  questionnaire; // Array of tabs
  currentTab; // Currently active tab
  currentTabIndex; // Index of currently active tab
  currentSection; // Currently active section
  currentSectionIndex; // Index of currently active section
  sectionForm: FormGroup; // Formgroup for currently active section
  showSectionForm = false; // Boolean to show / hide the section form
  qAnswers; // Array to contain all the answers in this questionnaire for this farmer
  checkedObject = {}; // Object that stores checked = true / false for checkbox questions (non-table)
  checkedObjectSecondaryKeys = {};  // Object that stores checked = true / false for checkbox questions (table)
  farmerId;  // Currently editing farmer's id
  secondary_asset_keys = {}; // Stores the secondary_asset_keys for each table. Format {tableId: [sak1, sak2], table2: [sak3, sak4]}
  secondary_asset_key__options = {}; // Stores the secondary_asset_key against the checkbox option that triggered creation of that sak. Format {optionId1: sak1, optionId2: sak2}
  project; // Project object for currently editing farmer
  notActiveSurvey = 2;
  notReleasedSurveyInLanguage;
  rawFarmerId;
  wizardShow;
  notes: any;
  currentUser = this.userService.getCurrentUser();
  projectId;
  farmer;
  revisionsInSurvey = [];
  selectedRevision = '';
  tableOtherQuestionsIsDisplayed = {};
  saveChangesConfirmModal;
  unsavedAnswerCount = 0;
  dynamicOptionsObject = {};
  private readonly subject = new Subject<boolean>();
  displayLogicKeyValues = [];
  showSurveyManualIcon = false;
  surveyHasReference = false;
  showReferenceTab = false;
  assetTypes = [
    {type: 'tab', subTypes: []},
    {type: 'section', subTypes: []},
    {type: 'subsection', subTypes: []},
    {type: 'table', subTypes: [{key: 'question_rows', label: 'Questions in rows'}, {key: 'question_cols', label: 'Questions in columns'}]},
    {type: 'question', subTypes: [{key: 'text', label: 'Text'}, {key: 'number', label: 'Number'}, {key: 'select', label: 'Select'}, {key: 'multiselect', label: 'Multiselect'}]},
    {type: 'option', subTypes: []},
    {type: 'uom_question', subTypes: [{key: '1', label: 'Length'},{key: '2', label: 'Volume'},{key: '3', label: 'Weight'},{key: '4', label: 'Area'}, {key: '5', label: 'Quantity'}]},
    {type: 'other_question', subTypes: []},
    {type: 'grand_total_question', subTypes: []},
  ];
  processedUOMs = {};
  defaultCropSak;
  askUserToConfirmExit = false;
  farmProfitabilitySummary = {income: {source: 'NA', amount: 'NA'}, expense: {source: 'NA', amount: 'NA'}, totalIncome: 'NA', totalExpense: 'NA', totalProfit: 'NA'};
  farmProfitabilitySummaryArray = [];
  usersServiceComponentMappings;
  ecosystemServicesComponentsMapping;
  canDoFarmerMgmt = false;
  thereNoActiveSnapshotForThisProject;
  showSummaryForCurrentTab;
  currencyCode;
  showHideBackButton = true;
  showHideNextButton = false;
  campaignId;
  breadcrumbTitle;
  totalSectionAnswered = true;
  tableAnswersSAKs = {};
  farmerUnavailableforProfiling;
  answerCanNotBeConfirmed;
  answerCanNotMarkNA;
  tableQuestionAnswered = true;
  newlyAddedKeys = [];
  answerCanNotBeValidate;
  cropNameQuestionAnswered = true;
  public dateFormat = 'DD-MM-YYYY';
  dateOpen = false;
  monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  mandatoryQuestionAnswered = true;
  surveysInfo = null;
  activeSnapshot = null;
  isDesktopDevice;
  uploadedFiles = [];
  docUrl;
  constructor(
    private readonly dynamicQuestionnaireService: DynamicQuestionnaireService,
    private readonly projectService: ProjectService,
    private readonly commonService: CommonService,
    private readonly route: ActivatedRoute,
    private readonly toastr: ToastrService,
    private readonly surveyService: SurveyService,
    private readonly userService: UserService,
    private readonly farmerService: FarmerService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly modalService: BsModalService,
    private localeService: BsLocaleService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly deviceService: DeviceDetectorService,
    @Inject(LOCALE_ID) private readonly locale: string,
    private http: HttpClient 
  ) {
    
    this.translate.get('farmer_unavailable_profiling').subscribe(result => {
      this.farmerUnavailableforProfiling = result;
    });
    this.translate.get('answer_can_not_be_confirmed').subscribe(result => {
      this.answerCanNotBeConfirmed = result;
    });
    this.translate.get('answer_can_not_be_mark_na').subscribe(result => {
      this.answerCanNotMarkNA = result;
    });
    this.translate.get('answer_can_not_be_validate').subscribe(result => {
      this.answerCanNotBeValidate = result;
    });
    this.localeService.use(this.locale);
  }
  canDeactivate(): Observable<boolean> | boolean {
    if (this.askUserToConfirmExit) {
      this.saveChangesConfirmModal = this.modalService.show(this.exitConfirmModalTemplate, { backdrop: true, ignoreBackdropClick: true, keyboard: false });      
      return this.subject.asObservable();
    }
    return true;
  }

  async ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('projectId');
      this.route.queryParamMap.subscribe(async queryParams => {
        // Get the 'raw' farmerid - this can be the farmer id (online registration) or a temporary offline id
        this.rawFarmerId = params.get('farmerId');
        // Get the farmer id (online registration), if it exists, or set this.farmerId to 0
        this.farmerId = +params.get('farmerId') || 0;
        // Get project info, questionnaire, & answers

        let request;
        let request2;

        if (queryParams.get('campaignId') && +queryParams.get('campaignId') !== 0 && !isNaN(+queryParams.get('campaignId'))) {
          this.campaignId = queryParams.get('campaignId');
          request = await this.campaignQuestionnaireFlow();
        } else {
          this.spinner.show();
          if (this.farmerId != 0) {
            if (navigator.onLine) {
              // If user is online, get the answers for farmer
              request = await Promise.all([
                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
                this.farmerService.getFarmerBasicDataById(this.farmerId).toPromise(),
                this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
              ]);
            } else {
              // If user is offline, get the pagination limits and farmer basic data for the project
              request = await Promise.all([
                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise(),
                this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
              ]);
              request2 = await Promise.all([
                this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
              ]);
            }
          } else {
            // If farmerId == 0, the farmer has been registered offline
            request = await Promise.all([
              this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
              this.projectService.getProjectProfilingData(this.projectId).toPromise(),
              this.projectService.getProjectDashboardData(this.projectId).toPromise(),
              this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise()
            ]);
          }
        }

        if (request[0]['msg'] !== 'success' || request[1]['msg'] !== 'success' || request[2]['msg'] !== 'success') {
          this.commonService.showToast('error', 'generic_error', {});
        } else {
          this.project = {...request[0].data[0], ...request[1].data[0], ...request[2].data[0]};
          this.canDoFarmerMgmtFn();
          this.setCurrencyCode();
          
          if (request[3]['message'] === 'notActive') {
            this.notActiveSurvey = 1;
            this.commonService.showToast('warning', 'No Active Survey Found', {});
          } else if (request[3]['message'] === 'notReleasedForLanguage') {
            this.notReleasedSurveyInLanguage = 1;
          } else {
            // Set the questionnaire object
            this.questionnaire = request[3]['message'];
            this.setQuestionnaireAttributes();
            this.notActiveSurvey = 0;
          }

          let result; let request3; 
          if(request[6]){
            this.surveysInfo = request[6].message;
          }
          if (this.farmerId != 0) {
            // If online, get answers from getQAnswersForFarmer (for this.farmerId)
            if (navigator.onLine) {
              result = request[4];
            } else {
              // If offline, get answers from getQAnswersForFarmer (for this.farmerId) - this is coming from request2
              result = {code: 'success'};
              let qAnswersForFarmer;
              if (request2 && request2[0] && request2[0]['message']) {
                // If request2[0] has a message attribute, getQAnswersForFarmer has been called for this farmer before
                qAnswersForFarmer = request2[0];
              } else {
                const paginationLimitsArray = request[4].message;
                // console.log('paginationLimitsArray', paginationLimitsArray);
                const paginationLimits = this.getPaginationLimits(paginationLimitsArray, this.farmerId)
                // console.log('paginationLimits', paginationLimits);

                if (this.campaignId) {
                  request3 = await Promise.all([
                    this.dynamicQuestionnaireService.getQAnswersForCampaignPaginated(this.campaignId, paginationLimits[0], paginationLimits[1]).toPromise()
                  ]);
                } else {
                  //added by KRPT for getting active survey ID
                  const surveyReq = await Promise.all([
                    this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                  ]);
                  let activeSurveyId;
                  if (surveyReq[0]['code'] === 'success' && surveyReq[0]['message'].length > 0) {
                    this.surveysInfo = surveyReq[0].message;
                    let activeSnapshot;
                    const snapshotSurveys = surveyReq[0]['message'].filter(item => item.survey_type == 'snapshot');
                    if (snapshotSurveys && snapshotSurveys.length > 0) {
                      activeSnapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
                      
                      if(activeSnapshot) {
                        activeSurveyId = activeSnapshot.id;
                      }
                    }
                  }
                  const getQIds = await Promise.all([
                    this.dynamicQuestionnaireService.getSurveyQAssetIdsDashboard(activeSurveyId).toPromise()
                  ])
                  if (getQIds[0]['code'] === 'success'){                      
                    request3 = await Promise.all([
                      this.dynamicQuestionnaireService.getQAnswersForProjectPaginatedDashboard(this.projectId, paginationLimits[0], paginationLimits[1], getQIds[0]['message']).toPromise()
                    ]);
                  }
                }

                // console.log('request3', request3);

                // If getQAnswersForFarmer has not been called for this farmer, get the answer values from getQAnswersForProject by filtering on farmerId
                result['message'] = request3[0]['msg'] !== 'blank' ? request3[0]['message'].filter(item => item.farmer_id === this.farmerId) : [];
              }
              // If getQAnswersForFarmer has been called for this farmer before, compare the timestamp of the two API calls (farmer specific & project-wide data fetch)
              // Set the results array as the latest API data fetch
              if (qAnswersForFarmer && request3) {
                    // getQAnswersForFarmer fetch      getQAnswersForProject fetch
                if (qAnswersForFarmer['ts'] > request3[0]['ts']) {
                  // Set as farmer-specific API fetch
                  result['message'] = qAnswersForFarmer['message'];
                } else {
                  // Set as project-wide fetch, filtered by farmerId
                  result['message'] = request3[0]['message'].filter(item => item.farmer_id === this.farmerId);
                }
              } else if (qAnswersForFarmer && !request3) {
                result['message'] = qAnswersForFarmer['message'];
              } else if(!qAnswersForFarmer && request3){
                result['message'] = request3[0]['message'].filter(item => item.farmer_id === this.farmerId);
              }
            }
          } else {
            result = [];
            if(!navigator.onLine){
              const surveyReq = await Promise.all([
                this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
              ]);
              if (surveyReq[0]['code'] === 'success' && surveyReq[0]['message'].length > 0) {
                this.surveysInfo = surveyReq[0].message;
              }
            }
          }
          // Set the farmer's basic data (name, quicknotes etc.)
          if (this.farmerId !== 0 && request[5]['msg'] === 'success') {
            if (navigator.onLine) {
              this.farmer = request[5]['data'];
            } else {
              this.farmer = request[5]['data'].find(item => item.id === this.farmerId);
            }
          } else {
            const farmertmpobj = await idbApp.getAllProjects('farmerstmp');
            this.farmer = farmertmpobj.find(x => x.tmp_id === this.rawFarmerId);
          }

          if (this.farmer.FarmerQuickNotes && this.farmer.FarmerQuickNotes.length > 0) {
            this.notes = this.farmer.FarmerQuickNotes[0];  
          } else {
            this.notes = { notes: '', farmer_id: this.farmerId };
          }

          // if(navigator.onLine && request[5].code === 'success') {
          //   this.revisionsInSurvey = request[5].message;
          // }
          // Set quick notes
          this.setFarmerQuickNotes();

          // Set the qAnswers array
          this.setQAnswersAndInitialize(result);
          this.preProcessProjectUOMs();
        }
        this.spinner.hide();
        this.setBreadcrumbText();
      })
    });
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  /**
   * Set the qAnswers array using the result array - answers returned by the API, and the indexedDB entries
   * @param result = answers array
   */
  async setQAnswersAndInitialize(result) {
    this.qAnswers = [];
    let indexedDBEntries = [];
    let farmerNA = false;
    // Find this rawFarmerId's indexedDB entries if any
    const farmertmpobj = await idbApp.getAllProjects('dq_profiling');
    if (farmertmpobj && farmertmpobj.length > 0) {
      let qAnswersForThisFarmerId = [];
      qAnswersForThisFarmerId = farmertmpobj.filter((item)=>{
        return (String(item.tmp_id) === String(this.rawFarmerId) && String(item.campaignId) === String(this.campaignId));
      });
      if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
        for (let i = 0; i < qAnswersForThisFarmerId.length; i++) {
          indexedDBEntries = indexedDBEntries.concat(JSON.parse(qAnswersForThisFarmerId[i].values));
        }
      }
    }
    // Cached values
    if (result.code === 'success' && result.message.length > 0) {
      for (let i = 0; i < result.message.length; i++) {
        if(!result.message[i].deleted){
          this.qAnswers.push({
            id: result.message[i].id.toString(), 
            key: result.message[i].questionnaire_asset_id.toString(), 
            sak_of_modified: result.message[i].sak_of_modified, 
            secondary_asset_key: result.message[i].secondary_asset_key, 
            value: result.message[i].answer.toString(),
            flex3: result.message[i].flex3
          });
          if(result.message[i].flex2 == 'NA'){
            farmerNA = true;
          }
        }
      }
    }

    // IndexedDB values
    if (indexedDBEntries.length > 0) {
      for (let i = 0; i < indexedDBEntries.length; i++) {
        // If the indexedDB entry has an id, find the same id from qAnswers and update the value, mark as touched
        if (indexedDBEntries[i].id) {
          const x = this.qAnswers.find(item => item.id == indexedDBEntries[i].id);
          if (x) {
            const xIndex = this.qAnswers.indexOf(x);
            if (this.validIndex(xIndex)) {
              this.qAnswers[xIndex]['value'] = indexedDBEntries[i]['value'];
              this.qAnswers[xIndex]['flex3'] = indexedDBEntries[i]['flex3'];
              this.qAnswers[xIndex]['touched'] = true;
              if (indexedDBEntries[i]['checked']) {this.qAnswers[xIndex]['checked'] = indexedDBEntries[i]['checked'];}
              if (indexedDBEntries[i]['unchecked']) {this.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['unchecked'];}
              if (indexedDBEntries[i]['deleted']) {this.qAnswers[xIndex]['deleted'] = indexedDBEntries[i]['deleted'];}
            }
          }
        } else {
          // Push into qAnswers
          const answerToInsert = {
            key: indexedDBEntries[i].key.toString(),
            sak_of_modified: indexedDBEntries[i].sak_of_modified,
            secondary_asset_key: indexedDBEntries[i].secondary_asset_key,
            value: indexedDBEntries[i].value.toString(),
            touched: true,
            checked: indexedDBEntries[i].checked,
            flex3: indexedDBEntries[i].flex3
          };

          if (indexedDBEntries[i]['checked']) {
            answerToInsert['checked'] = indexedDBEntries[i]['checked'];
          }
          if (indexedDBEntries[i]['unchecked']) {
            answerToInsert['unchecked'] = indexedDBEntries[i]['unchecked'];
          }
          if (indexedDBEntries[i]['deleted']) {
            answerToInsert['deleted'] = indexedDBEntries[i]['deleted'];
          }
          this.qAnswers.push(answerToInsert);
          if(indexedDBEntries[i].flex2 == 'NA'){
            farmerNA = true;
          }
        }
      }
      this.askUserToConfirmExit = false;
    }
    if (this.notActiveSurvey === 0) {
      // Initialize the questionnaire
      this.initialize();
      // Set the sak_of_modified values in this.secondary_asset_key__options object (which checkbox impacted which row / column uuid in a table)
      this.setSecondaryAssetKeyOptionValues();
      // Set dynamic options (which crop intercropping questions)
      this.setDynamicOptionsObject();
    }
    
    if(farmerNA && typeof this.campaignId === "undefined"){
      this.toastr.error(this.farmerUnavailableforProfiling);
    }
  }

  // Naviate to 1st section inside the clicked tab
  tabClicked(tabIndex) : void {

    if(tabIndex == -1){
      this.showReferenceTab = true;
      this.docUrl = `${environment.apiBaseUrl}/${this.locale.substr(0, 2)}/assets/uploads/surveyManual/${this.activeSnapshot.flex4}`;
      console.log('docUrl', this.docUrl);
      this.currentTabIndex = tabIndex;
      return;
    }
    if (this.currentTabIndex != tabIndex) {
      this.currentTab = this.questionnaire[tabIndex];
      this.currentTabIndex = tabIndex;
      this.showReferenceTab = false;
      // this.currentSectionIndex = 0;
      this.sectionClicked(0, true);
      setTimeout(() => {
        const x = document.querySelector('ul.sub-tab-fixed');
        if (x) {x.scrollLeft -= 1500;}
      }, 300);
    }
  }
  // To select last section on click of prev button
  tabClickedSectionLast(tabIndex) : void {
    if (this.currentTabIndex != tabIndex) {
      this.currentTab = this.questionnaire[tabIndex];
      this.currentTabIndex = tabIndex;
      this.currentSectionIndex = this.currentTab.sections.length - 1;
      this.sectionClicked(this.currentSectionIndex, true);
      setTimeout(() => {
        const x = document.querySelector('ul.sub-tab-fixed');
        if (x) {x.scrollLeft += 1500;}
      }, 300);
    }
  }

  // Navigate to the section clicked
  sectionClicked(sectionIndex, comingFromTabClicked?) : void {
    if (comingFromTabClicked || this.currentSectionIndex != sectionIndex) {
      window.scrollTo(0, 0);
      if (this.currentTab) {
        this.currentSection = undefined;
        if (this.currentTab.sections && this.currentTab.sections.length > 0) {
          this.currentSection = this.currentTab.sections[sectionIndex];
          this.currentSectionIndex = sectionIndex;
          // Set the Section Form
          this.setForm();
          this.showSummaryForCurrentTabFn();
          this.runTabSummaryCalculate();
        }
      }
      this.disableButton(sectionIndex);
      this.totalSectionAnswered = false;      
      this.tableQuestionAnswered = true;
      this.checkAllQuestionAnswered();
      this.checkMandatoryQuestions();
      // if (this.surveysInfo && this.surveysInfo.length > 0) {

      //   if(this.campaignId){
      //     this.surveysInfo = this.surveysInfo.filter((item) => item.name === "Campaign" && item.flex1 == this.campaignId);
      //   } else{
      //     this.surveysInfo = this.surveysInfo.filter((item) => item.name === "Snapshot");
      //   }

      //   this.activeSnapshot = this.surveysInfo[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? this.surveysInfo[0] : undefined;
      // }

      if (parseInt(this.currentSection.flex1) === 1) {
        this.showSurveyManualIcon = true;
      } else {
        this.showSurveyManualIcon = false;
      }
    }
  }

  // Initialize the questionnaire by navigating to the 1st tab
  initialize(): void {
    // this.currentTabIndex = 0;
    if (this.surveysInfo && this.surveysInfo.length > 0) {

      if(this.campaignId){
        this.surveysInfo = this.surveysInfo.filter((item) => item.name === "Campaign" && item.flex1 == this.campaignId);
      } else{
        this.surveysInfo = this.surveysInfo.filter((item) => item.name === "Snapshot");
      }

      this.activeSnapshot = this.surveysInfo[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? this.surveysInfo[0] : undefined;
    }
    if(this.activeSnapshot && this.activeSnapshot.flex4){
        this.surveyHasReference = true;
    }
    this.tabClicked(0);
    this.collectDisplayLogicKeyValues();
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      // rest of your code
    }
  }

  /**
   * Main function to create & set values in the section form
   */
  setForm(): void {
    this.showSectionForm = false;
    this.sectionForm = new FormGroup({});
    
    // For each question in the section
    if (this.currentSection.questions && this.currentSection.questions.length > 0) {
      for (let i = 0; i < this.currentSection.questions.length; i++) {
        // Find if the question has any answer(s) in the qAnswers array
        const existsInQAnswers = this.existsInQAnswers(this.currentSection.questions[i]);
        if (this.currentSection.questions[i].qa_subtype === 'multiselect' && existsInQAnswers && existsInQAnswers.length > 0) {
          // Set the object which determines whether a checkbox should appear as checked or unchecked (question.id + option.id combination is true / false)
          this.setCheckedObject(existsInQAnswers, this.currentSection.questions[i]);
          
          // Create a form control for first option
          this.sectionForm.addControl(this.currentSection.questions[i].id, new FormControl(existsInQAnswers[0].value));
        } else{
          // Create a form control for each question
          this.sectionForm.addControl(this.currentSection.questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
        }

        // Set the question's validations
        this.setQuestionValidations(this.currentSection.questions[i]);
        // Disable user input if required
        if (this.currentSection.questions[i].disable_input) {
          this.sectionForm.controls[this.currentSection.questions[i].id].disable();
        }
        // Set the question's UOM dropdown (value of dropdown select formcontrol + the option in the dropdown) if required
        if (this.currentSection.questions[i].include_uom_question) {
          this.setUOMQuestion(this.currentSection.questions[i], this.currentSection.questions[i].disable_input);
        }
        this.setOtherQuestion(this.currentSection.questions[i]);
        //set the file name if previously uploaded and saved
        if (this.currentSection.questions[i].qa_subtype == 'file' && existsInQAnswers) {
          const obj = {
            "id":existsInQAnswers.key,
            "image_file": '',
            "image_for" : existsInQAnswers.value,
            "sak": existsInQAnswers.secondary_asset_key
          }
          const f = this.uploadedFiles.find(item => item.id == obj.id && item.image_for == obj.image_for);
          if(!f){
            this.uploadedFiles.push(obj);
          }
        }
      }
    }

    // For each subsection in the section, do the same steps as above (done for each question within each subsection)
    if (this.currentSection.subsections && this.currentSection.subsections.length > 0) {
      for (let k = 0; k < this.currentSection.subsections.length; k++) {
        for (let i = 0; i < this.currentSection.subsections[k].questions.length; i++) {
          const existsInQAnswers = this.existsInQAnswers(this.currentSection.subsections[k].questions[i]);
          if (this.currentSection.subsections[k].questions[i].qa_subtype === 'multiselect' && existsInQAnswers && existsInQAnswers.length > 0) {
            this.setCheckedObject(existsInQAnswers, this.currentSection.subsections[k].questions[i]);
            
            // Create a form control for first option
            existsInQAnswers.forEach(item => {
              this.sectionForm.addControl(this.currentSection.subsections[k].questions[i].id, new FormControl(existsInQAnswers[0].value));
            });

          } else{
            this.sectionForm.addControl(this.currentSection.subsections[k].questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
          }
          this.setQuestionValidations(this.currentSection.subsections[k].questions[i]);
          if (this.currentSection.subsections[k].questions[i].disable_input) {
            this.sectionForm.controls[this.currentSection.subsections[k].questions[i].id].disable();
          }
          if (this.currentSection.subsections[k].questions[i].include_uom_question) {
            this.setUOMQuestion(this.currentSection.subsections[k].questions[i], this.currentSection.subsections[k].questions[i].disable_input);
          }
          this.setOtherQuestion(this.currentSection.subsections[k].questions[i]);
          //set the file name if previously uploaded and saved
          if (this.currentSection.subsections[k].questions[i].qa_subtype == 'file' && existsInQAnswers) {
            const obj = {
              "id":existsInQAnswers.key,
              "image_file": '',
              "image_for" : existsInQAnswers.value,
              "sak": existsInQAnswers.secondary_asset_key
            }
            const f = this.uploadedFiles.find(item => item.id == obj.id && item.image_for == obj.image_for);
            if(!f){
              this.uploadedFiles.push(obj);
            }
          }
        }
      }
    }

    // For each table within the section
    if (this.currentSection.tables && this.currentSection.tables.length > 0) {
      for (let k = 0; k < this.currentSection.tables.length; k++) {
        if (this.currentSection.tables[k].displayFunction && this.currentSection.tables[k].displayFunction.indexOf('addDefaultCropEntry') > -1) {
          this.addDefaultCropEntry(this.currentSection.tables[k]);
        }
        // For each question in the table
        for (let i = 0; i < this.currentSection.tables[k].questions.length; i++) {
          // Check if answers exist for this question
          const existsInQAnswersForTableQuestion = this.existsInQAnswersForTableQuestion(this.currentSection.tables[k].questions[i]);
          // Add secondary asset key of each unique row / column in the table to the secondary_asset_keys object
          this.secondary_asset_keys[this.currentSection.tables[k].id] = existsInQAnswersForTableQuestion.map(item => item.secondary_asset_key)
          .filter((value, index, self) => {return self.indexOf(value) === index;});
          // Either non-blank or "" answer should exist for each question in each row / column in the table
          if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
            // For each unique secondary asset key
            for (let p = 0; p < existsInQAnswersForTableQuestion.length; p++) {
              // For multiselect questions, set the checkedObjectSecondaryKeys value (to indicate whether the checkbox should appear checked or not)
              if (this.currentSection.tables[k].questions[i].qa_subtype === 'multiselect' 
              && existsInQAnswersForTableQuestion[p].value && existsInQAnswersForTableQuestion[p].value.length > 0) {
                this.setCheckedObjectSecondaryKeys(existsInQAnswersForTableQuestion[p], this.currentSection.tables[k].questions[i]);
              }
              // Add the form control (question.id + '_' + secondary_asset_key of the row / column)
              this.sectionForm.addControl(this.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key'] , new FormControl(existsInQAnswersForTableQuestion[p]['value']));
              // Set the question's validations, disable the input if required & add UOM questions if required
              this.setQuestionValidations(this.currentSection.tables[k].questions[i], existsInQAnswersForTableQuestion[p]['secondary_asset_key']);
              if (this.currentSection.tables[k].questions[i].disable_input) {
                this.sectionForm.controls[this.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key']].disable();
              }
              if (this.currentSection.tables[k].questions[i].include_uom_question) {
                this.setUOMQuestion(this.currentSection.tables[k].questions[i], this.currentSection.tables[k].questions[i].disable_input, existsInQAnswersForTableQuestion[p]);
              }
              this.setOtherQuestion(this.currentSection.tables[k].questions[i], existsInQAnswersForTableQuestion[p]['secondary_asset_key']);
              
              //set the file name if previously uploaded and saved
              if (this.currentSection.tables[k].questions[i].qa_subtype == 'file' && existsInQAnswersForTableQuestion[p].value) {
                const obj = {
                  "id": existsInQAnswersForTableQuestion[p].key,
                  "image_file": '',
                  "image_for" : existsInQAnswersForTableQuestion[p].value,
                  "sak": existsInQAnswersForTableQuestion[p].secondary_asset_key
                }
                const f = this.uploadedFiles.find(item => item.id == obj.id && item.image_for == obj.image_for && item.sak == obj.sak);
                if(!f){
                  this.uploadedFiles.push(obj);
                }
              }
            }
          }
        }

        for (let i = 0; i < this.currentSection.tables[k].grand_total_questions.length; i++) {
          const existsInQAnswers = this.existsInQAnswersForTableQuestion(this.currentSection.tables[k].grand_total_questions[i]);
          // console.log('324 existsInQAnswers', existsInQAnswers);
          this.sectionForm.addControl(this.currentSection.tables[k].grand_total_questions[i].id, existsInQAnswers && existsInQAnswers.length > 0 ? new FormControl(existsInQAnswers[0]['value']) : new FormControl(''));
          if (this.currentSection.tables[k].grand_total_questions[i].disable_input) {
            this.sectionForm.controls[this.currentSection.tables[k].grand_total_questions[i].id].disable();
          }
        }

        if (this.currentSection.tables[k].displayFunction && this.currentSection.tables[k].displayFunction.indexOf('addDefaultCropEntry') > -1) {
          this.sortDefaultCropToTop(this.currentSection.tables[k].id);
        }
      }
    }
    // Based on toggle logic if applicatble, set which elements in the form should be displayed (isDisplayed attribute = true/false)
    this.setSectionFormDisplay();
    this.showSectionForm = true;
  }

  //clear inputed date by calling following function
  clearCalendarDate(e, question){
    this.sectionForm.controls[question.id].setValue('');
    this.sectionFormChangeHandler(e, question);
  }

  /**
   * Handle input change events
   * Section & subsection (NON TABLE) questions (type = text, number, select)
   * @param args 
   * @param question 
   */
  sectionFormChangeHandler(args, question): void {
    if (this.questionHasNoErrors(question)) {
      if (!this.qAnswers) {this.qAnswers = [];}
      // if question subtype is date convert it to readable format
      if(question.qa_subtype == 'date'){
        if(this.sectionForm.value[question.id] == undefined){
          return;
        }
        let dateSelected;
        if(this.sectionForm.value[question.id] == ''){
          dateSelected = '';
        } else {
          let dateValue = this.sectionForm.value[question.id];
          let date;
          if(typeof dateValue == "string"){
            let dateParts = dateValue.split("-");
            date = new Date(+dateParts[2], parseInt(dateParts[1]) - 1, +dateParts[0]);
          } else {
            date = new Date(dateValue);
          }
          // const dateSelected = date.getDate() + '-' + this.monthShortNames[date.getMonth()] + '-' + date.getFullYear();
          dateSelected = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
        }
        this.sectionForm.value[question.id] = dateSelected;
      }
      // See if this object already exists in qAnswers
      const x = this.qAnswers.find(item => item.key == question.id);
      // If object exists, find the index     
      if (x) {
        const index = this.qAnswers.indexOf(x);
        // Set this object's touched parameter to true so that it is picked up to be sent to server
        this.qAnswers[index]['touched'] = true;
        // If this object has an id (previously value was set), set the form control's value
        if (this.qAnswers[index].id && this.qAnswers[index].id > 0) {
          this.qAnswers[index]['value'] = this.sectionForm.value[question.id] != null ? this.sectionForm.value[question.id] : '';
          if (this.qAnswers[index]['deleted']) {this.qAnswers[index]['deleted'] = false;}
        } else {
          // If this object does NOT have an id
          // If the form control has a not-null not-blank value, set it
          if (this.sectionForm.value[question.id] != null && this.sectionForm.value[question.id] !== '') {
            this.qAnswers[index]['value'] = this.sectionForm.value[question.id];
            if (this.qAnswers[index]['deleted']) {this.qAnswers[index]['deleted'] = false;}
          } else {
            // If the form control has a null / blank value, remove the object from this.qAnswers (it has been set and unset in the same session)
            this.qAnswers[index]['value'] = '';
            this.qAnswers[index]['deleted'] = true;
          }
        }
      } else {
        // If this object does not exist in qAnswers
        // If the form control has a not-null not-blank value, add the object to qAnswers with touched = true
        if (this.sectionForm.value[question.id] != null && this.sectionForm.value[question.id] !== '') {
          this.qAnswers.push({key: question.id.toString(), value: this.sectionForm.value[question.id].toString(), touched: true});
        }
        else {
          // console.log('211', question.id, this.sectionForm.value[question.id]);
        }
      }
      // Check if the question has a non-trivial toggle logic (NTTL) associated with it. If so, run the toggle logic functionality
      // if (question.table_to_modify != null && question.table_to_modify != '') {console.log('216');this.runDisplayLogic(question, this.sectionForm.value[question.id]);}
      // Based on toggle logic if applicatble, set which elements in the form should be displayed (isDisplayed attribute = true/false)
      this.setSectionFormDisplay();
      this.unsetOtherAnswerValue(question, null, null);
      if (question.uom_questions && question.uom_questions.length > 0) {
        this.setUOMValuesInQAnswers(question);
      }
      // If this question is modifying another through an auto calculate formula, run the auto calculate function
      if (question.modifies_qas) {
        this.runAutoCalculate(question, null, true);
      }
      this.unsetDependentQuestions(question);
      this.askUserToConfirmExit = true;

      if (this.showSummaryForCurrentTab) {
        this.runTabSummaryCalculate();
      }
      this.checkAllQuestionAnswered();
      this.checkMandatoryQuestions();

      // Added by SBJY
      let record = this.qAnswers.find(item => item.key == question.id);
      if (typeof record !== "undefined" && typeof record.value !== "undefined" && record.value) {
        this.confirmAnswer("", question, true)
      } else {
        this.confirmAnswer("", question, false)
      }
    } else if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) { 
      /* If question is mandatory then disable navigation buttons */
      for (let j = 0; j < question.survey_q_asset_validations.length; j++) {
        if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
          this.mandatoryQuestionAnswered = false;
          const record = this.qAnswers.find(item => item.key == question.id);
          const ind = this.qAnswers.indexOf(record);
          this.qAnswers[ind]['value'] = "";
        }
      }
    }
  }

  async sectionFormChangeHandlerFile(fileObj, question) {
    if (this.questionHasNoErrors(question)) {
      if (!this.qAnswers) {this.qAnswers = [];}
      // See if this object already exists in qAnswers
      let x;
      if(fileObj.sak == ""){
        x = this.qAnswers.find(item => item.key == question.id);
      } else {
        x = this.qAnswers.find(item => item.key == question.id && item.secondary_asset_key == fileObj.sak);
      }
      // If object exists, find the index
      if (x) {
        const index = this.qAnswers.indexOf(x);
        // Set this object's touched parameter to true so that it is picked up to be sent to server
        this.qAnswers[index]['touched'] = true;
        // If this object has an id (previously value was set), set the form control's value
        if (this.qAnswers[index].id && this.qAnswers[index].id > 0) {          
          this.qAnswers[index]['value'] = fileObj.image_for;
          this.qAnswers[index]['imgBase64'] = fileObj.image_file;
          if (this.qAnswers[index]['deleted']) {this.qAnswers[index]['deleted'] = false;}
        } else {
          // If this object does NOT have an id
          // If the form control has a not-null not-blank value, set it
          if (fileObj && fileObj.image_for != '') {
            this.qAnswers[index]['value'] = fileObj.image_for;
            this.qAnswers[index]['imgBase64'] = fileObj.image_file;
            if (this.qAnswers[index]['deleted']) {this.qAnswers[index]['deleted'] = false;}
          } else {
            // If the form control has a null / blank value, remove the object from this.qAnswers (it has been set and unset in the same session)
            this.qAnswers[index]['value'] = '';
            this.qAnswers[index]['deleted'] = true;
          }
        }
      } else {
        // If this object does not exist in qAnswers
        // If the form control has a not-null not-blank value, add the object to qAnswers with touched = true
        if (fileObj && fileObj.image_for != '') {
          if(fileObj.sak == ""){
            this.qAnswers.push({key: question.id.toString(), value: fileObj.image_for, touched: true, imgBase64:fileObj.image_file});
          } else {
            this.qAnswers.push({key: question.id.toString(), value: fileObj.image_for, touched: true, imgBase64:fileObj.image_file, secondary_asset_key: fileObj.sak});
          }
        }
        else {
          // console.log('211', question.id, this.sectionForm.value[question.id]);
        }
      }
      this.commonService.showToast('success', 'file_uploaded_successfully', {});


      // Check if the question has a non-trivial toggle logic (NTTL) associated with it. If so, run the toggle logic functionality
      // if (question.table_to_modify != null && question.table_to_modify != '') {console.log('216');this.runDisplayLogic(question, this.sectionForm.value[question.id]);}
      // Based on toggle logic if applicatble, set which elements in the form should be displayed (isDisplayed attribute = true/false)
      this.setSectionFormDisplay();
      // this.unsetOtherAnswerValue(question, null, null);
      // if (question.uom_questions && question.uom_questions.length > 0) {
      //   this.setUOMValuesInQAnswers(question);
      // }
      // // If this question is modifying another through an auto calculate formula, run the auto calculate function
      // if (question.modifies_qas) {
      //   this.runAutoCalculate(question, null, true);
      // }
      // this.unsetDependentQuestions(question);
      this.askUserToConfirmExit = true;

      // if (this.showSummaryForCurrentTab) {
      //   this.runTabSummaryCalculate();
      // }
      this.checkAllQuestionAnswered();
      this.checkMandatoryQuestions();

      // Added by SBJY
      if(fileObj.sak == ""){
        let record = this.qAnswers.find(item => item.key == question.id);
        if (typeof record !== "undefined" && typeof record.value !== "undefined" && record.value) {
          this.confirmAnswer("", question, true)
        } else {
          this.confirmAnswer("", question, false)
        }
      }
    } else if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) { 
      /* If question is mandatory then disable navigation buttons */
      for (let j = 0; j < question.survey_q_asset_validations.length; j++) {
        if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
          this.mandatoryQuestionAnswered = false;
        }
      }
    }
  }

   /* file upload */
   handleUploadFileInput(file: FileList, question, sak?) {
    // // debugger;
    if(file && file.length > 0){
      const fileItem = file.item(0);
      
      const msg = this.commonService.ValidateFile(fileItem);

      if (msg && msg.length > 0) {
        this.toastr.warning(msg);
        return;
      }
      new Compressor(fileItem, {
        quality: environment.imageCompress.quality,
        maxWidth:environment.imageCompress.maxWidth,
        maxHeight:environment.imageCompress.maxHeight,
        mimeType:environment.imageCompress.mimeType,
        convertSize:environment.imageCompress.convertSize,
        success(result) {
          setFarmerImageData(result);
        },
        error(err) {
          console.log(err.message);
        },
      });
      const setFarmerImageData = (result) => {
        this.commonService.blobToBase64(result).then((imageData)=>{
          const obj = {
            "id":question.id,
            "image_file": imageData,
            "image_for" : fileItem.name,
            "sak": sak ? sak : ''
          }
          if(sak){
            const x = this.uploadedFiles.find(item => item.id == question.id && item.sak == sak);
            if (x) {
              const index = this.uploadedFiles.indexOf(x);
              this.uploadedFiles[index]['image_file'] = imageData;
              this.uploadedFiles[index]['image_for'] = fileItem.name;
            } else {
              this.uploadedFiles.push(obj);
            }
          } else {
            // See if this object already exists in uploadedFiles
            const x = this.uploadedFiles.find(item => item.id == question.id);
            if (x) {
              const index = this.uploadedFiles.indexOf(x);
              this.uploadedFiles[index]['image_file'] = imageData;
              this.uploadedFiles[index]['image_for'] = fileItem.name;
            } else {
              this.uploadedFiles.push(obj);
            }
          }
          this.sectionFormChangeHandlerFile(obj, question);
        });
      };      
    }

  }

  /**
   * Handle input change events
   * Section & subsection (NON TABLE) questions (type = multiselect)
   * @param args 
   * @param option 
   * @param question 
   */
  sectionFormChangeHandlerCheckbox(args, option, question): void {
    if (this.questionHasNoErrors(question)) {
      this.updateCheckedObject(args, option.id, question.id, null);
      if (!this.qAnswers) {this.qAnswers = [];}
      // If the checkbox is checked
      if (args.target.checked) {
        const checkedItem = this.qAnswers.find(item => (item.key == question.id && item.value == option.id));
        const checkEmptyItem = this.qAnswers.find(item => (item.key == question.id && item.value == ''));
        if (checkedItem) {
          const checkedItemIndex = this.qAnswers.indexOf(checkedItem);
          if (checkedItemIndex != null && checkedItemIndex > -1) {
            this.qAnswers[checkedItemIndex]['unchecked'] = false;
            if (this.qAnswers[checkedItemIndex]['deleted']) {this.qAnswers[checkedItemIndex]['deleted'] = false;}
          }
        } else if(checkEmptyItem){
          const checkedItemIndex = this.qAnswers.indexOf(checkEmptyItem);
          if (checkedItemIndex != null && checkedItemIndex > -1) {
            this.qAnswers[checkedItemIndex]['value'] = option.id.toString();
            this.qAnswers[checkedItemIndex]['checked'] = true;
            this.qAnswers[checkedItemIndex]['unchecked'] = false;
            if (this.qAnswers[checkedItemIndex]['deleted']) {this.qAnswers[checkedItemIndex]['deleted'] = false;}
          }
        } else {
          // Add the answer to qAnswers array. Value should contain the option asset's id
          if(question.answerConfirmed || question.answerConfirmed == false){
            if(question.answerConfirmed == true){
              this.qAnswers.push({key: question.id.toString(), value: option.id.toString(), touched: true, checked: true, flex3: 'A'});
            } else {
              this.qAnswers.push({key: question.id.toString(), value: option.id.toString(), touched: true, checked: true, flex3: 'NA'});
            }
          } else {
            this.qAnswers.push({key: question.id.toString(), value: option.id.toString(), touched: true, checked: true});
          }
        }
        this.confirmAnswer("", question, true);
      } else {
        // If checkbox is unchecked, find the unchecked item in qAnswers
        const uncheckedItem = this.qAnswers.find(item => (item.key == question.id && item.value == option.id));
        if (uncheckedItem) {
          const uncheckedItemIndex = this.qAnswers.indexOf(uncheckedItem);
          if (uncheckedItemIndex != null && uncheckedItemIndex > -1) {
            // If the unchecked item has an id (meaning it was created & saved in DB previously), then set the unchecked attribute to true
            if (this.qAnswers[uncheckedItemIndex].id && this.qAnswers[uncheckedItemIndex].id > 0) {
              this.qAnswers[uncheckedItemIndex]['touched'] = true;
              this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
              this.qAnswers[uncheckedItemIndex]['deleted'] = true;
            } else {
              // If there is no id, this checkbox has been checked & unchecked in the same session. The item can be remvoed from qAnswers
              this.qAnswers = this.qAnswers.filter(item => !(item.key == question.id && item.value == option.id));
              // TODO: Remove this from IDB
              // this.qAnswers[uncheckedItemIndex]['touched'] = true;
              // this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
            }
            // this.qAnswers[uncheckedItemIndex]['touched'] = true;
            // this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
            //   // TODO: Remove this from IDB
          }
        }
        //check if not any option is selected
        const allQAnswers = this.qAnswers.filter(item => item.key == question.id);
        let allEmpty = true;
        for(const i of allQAnswers){
          if(!i.unchecked){
            allEmpty = false;
          }
        }
        if(allEmpty){
          //to show input required message check if question is mandaory;
          if(question.survey_q_asset_validations){
            const checkMandatory = question.survey_q_asset_validations.find(item => item.validation_key == "mandatory");
            if(checkMandatory){
              this.sectionForm.controls[question.id].setErrors({'required': true});         
            } else {              
              this.confirmAnswer("", question, false);          
            }
          }
        }
      }
      // Run display logic if required
      // if (question.table_to_modify != null && question.table_to_modify != '') {this.runDisplayLogicCheckBox(question, option.id, this.sectionForm.value[question.id]);}
      // Set which assets to display
      this.setSectionFormDisplay();
      this.unsetOtherAnswerValue(question, null, null);
      this.unsetDependentQuestions(question);
      this.askUserToConfirmExit = true;
      this.checkAllQuestionAnswered();
      this.checkMandatoryQuestions();
    } else if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) { 
      /* If question is mandatory then disable navigation buttons */
      for (let j = 0; j < question.survey_q_asset_validations.length; j++) {
        if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
          this.mandatoryQuestionAnswered = false;
        }
      }
    }
  }

  /**Confirmed that this question is answered */
  confirmAnswer(e, question, na, isAuto?, isClicked?){
    let flag = na ? 'A' : 'NA';
    let autoCalculateQnErrorFlag = false;
    if (this.questionHasNoErrors(question)) {
      let findQ;
      for(const j of this.currentSection.questions){
        if(j.id == question.id){
          findQ = j;
        }
      }
      for(const i of this.currentSection.subsections){
        for(const j of i.questions){
          if(j.id == question.id){
            findQ = j;
          }
        }
      }
      for(const i of this.currentSection.tables){
        for(const j of i.questions){
          if(j.id == question.id){
            findQ = j;
          }
        }
      }
      if (!this.qAnswers) {this.qAnswers = [];}
      // See if this object already exists in qAnswers      
      // if(question.qa_subtype == 'multiselect'){
      //   const x = this.qAnswers.filter(item => item.key == question.id);
      // }
      const x = this.qAnswers.filter(item => item.key == question.id);
      if (typeof question.survey_q_asset_autocalculate_formulas[0] !== "undefined") {
        const formula3 = question.survey_q_asset_autocalculate_formulas[0].formula;
        const formulaArray3 = formula3.split(",");
        const qArray2 = formulaArray3.filter(item => !isNaN(parseFloat(item)));
        let questionCount2 = 0;

        for (const qid of qArray2) {
          let findItem = this.qAnswers.find(item => item.key == qid);
          if (findItem && findItem.flex3) {
            questionCount2++;
          }
          //to check if current question type is grand total
          for (const t of this.currentSection.tables) {
            if (t.grand_total_questions && t.grand_total_questions.length > 0) {
              const gt = t.grand_total_questions.find(item => item.id == qid);
              let findA = this.qAnswers.find(item => item.key == qid);
              if (gt && !findA) {
                questionCount2++;
              }
            }
          }
        }
        if (questionCount2 !== qArray2.length) {
          this.toastr.error(this.answerCanNotBeValidate);
          autoCalculateQnErrorFlag = true;
        }
      }

      // If object exists, find the index
      if (!autoCalculateQnErrorFlag) {
      if (x && x.length > 0) {

        let allEmpty = true;
        let checkBoxFlag = false;
        let noErrors = true;
        for(const i of x){
            if(!i.unchecked && i.value !== ""){
              allEmpty = false;
            }
            if(typeof i.unchecked !== "undefined" || typeof i.checked !== "undefined"){
              checkBoxFlag = true;
            }
        }

        if(na && (isClicked || typeof isClicked === "undefined")){
          if(!checkBoxFlag && x[0].value === ''){
            this.toastr.error(this.answerCanNotBeConfirmed);
            noErrors = false;
          }

          if(checkBoxFlag && allEmpty){
            this.toastr.error(this.answerCanNotBeConfirmed);
            noErrors = false;
          }

        }

        else if(!na && (isClicked || typeof isClicked === "undefined")){

          if(!checkBoxFlag && x[0].value !== ''){
            this.toastr.error(this.answerCanNotMarkNA);
            noErrors = false;
          }

          if(checkBoxFlag && !allEmpty){
            this.toastr.error(this.answerCanNotMarkNA);
            noErrors = false;
          }

        }
        // if(!na && (x[0].value != '' || !allEmpty)){
          // if(!na && question.survey_q_asset_autocalculate_formulas.length === 0){
          //   if(!allEmpty){
          //     console.log("Answer cannot marke NA-->1");
          //     this.toastr.error(this.answerCanNotMarkNA);
          //   } else if(x[0].value != ''){
          //     console.log("Answer cannot marke NA-->2");
          //     this.toastr.error(this.answerCanNotMarkNA);
          //   }
            

          
          // this.toastr.error(this.answerCanNotMarkNA);
        // } else if (na && question.survey_q_asset_autocalculate_formulas.length === 0 && x[0].value == '' && allEmpty) {
          // }else if(na && question.survey_q_asset_autocalculate_formulas.length === 0){
          //   if(allEmpty){
          //     console.log("Answer cannot mark confirmed-->1");
          //     this.toastr.error(this.answerCanNotBeConfirmed);
          //   } else if(x[0].value === ''){
          //     console.log("Answer cannot mark confirmed-->2");
          //     this.toastr.error(this.answerCanNotBeConfirmed);
          //   }
          
          // console.log("Answer cannot mark confirmed");
          // this.toastr.error(this.answerCanNotBeConfirmed);
        // } 
        if (noErrors) {
          for(const y of x){
            const index = this.qAnswers.indexOf(y);
            // Set this object's touched parameter to true so that it is picked up to be sent to server
            this.qAnswers[index]['flex3'] = flag;
            this.qAnswers[index]['touched'] = true;
            if(findQ){
              findQ.answerConfirmed = na;
            };
          }
        }
      } else {
        // If this object does not exist in qAnswers
        // If the form control has a not-null not-blank value, add the object to qAnswers with touched = true
        if (this.sectionForm.value[question.id] != null && this.sectionForm.value[question.id] !== '' && na) {
          this.qAnswers.push({key: question.id.toString(), value: this.sectionForm.value[question.id].toString(), touched: true, flex3:'A'});
          if(findQ){
            findQ.answerConfirmed = na;
          };
        }
        else if(!na){
          this.qAnswers.push({key: question.id.toString(), value: '', touched: true, flex3:"NA"});
          if(findQ){
            findQ.answerConfirmed = na;
          };          
        } 
        else if((this.sectionForm.value[question.id] === '' || this.sectionForm.value[question.id] === null) && na) {
          this.toastr.error(this.answerCanNotBeConfirmed);
        }
      }
      this.checkAllQuestionAnswered();
      if(!isAuto){
        this.askUserToConfirmExit = true;

        // Fix for confirmeed metrics not showing in view page, compare answers and reports
        if (question.uom_questions && question.uom_questions.length > 0) {
          this.setUOMValuesInQAnswers(question, null, null, na);
        }
      } 

      if(question.modifies_qas){
        this.runAutoCalculate(question, null, true);
      }

      this.checkMandatoryQuestions();

      // if(question.modifies_qas){
      //   this.runAutoCalculate(question);
      // }
    }
    }
  }

  /* confirm answer table */
  confirmTableAnswer(e, tableId, secondaryAssetKey, na, clicked?){
    let flag = na ? 'A' : 'NA';
    const table = this.currentSection.tables.find(item => item.id == tableId);
    if(table){
      let count = 0; let TQcount = 0;
      for(const question of table.questions){
        const keyIsDisplayed = `${secondaryAssetKey}_isDisplayed`;
        if(question[keyIsDisplayed]){
          TQcount++;
        }
        if (!this.qAnswers) {this.qAnswers = [];}
        // See if this object already exists in qAnswers
        const x = this.qAnswers.find(item => item.key == question.id && item.secondary_asset_key == secondaryAssetKey);
        // If object exists, find the index
        const value = this.sectionForm.value[question.id + '_' + secondaryAssetKey];
        // if (this.notADuplicateValue(question, value)) { // Uncomment this function to apply constraint of unique answer value for given question
        if (x) {
          const index = this.qAnswers.indexOf(x);
          // Set this object's answered parameter to value
          this.qAnswers[index]['flex3'] = flag;
          this.qAnswers[index]['touched'] = true;
          if (x.value != null && x.value !== '') {
            count++;
          }
        } else {
          // Set the value of the formcontrol or set '' in case form control's value is null. This is required as each table question must have an entry in the answers table (even if '')
          if (value != null && value !== '') {
            this.qAnswers.push({key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: this.sectionForm.value[question.id + '_' + secondaryAssetKey].toString(), touched: true, flex3:'A'});
            count++;
          } else if(!na){
            this.qAnswers.push({key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: '', touched: true, flex3:'NA'});
            //this.tableAnswersSAKs[secondaryAssetKey] = na;
          }
        }
      }
      
      let errCount = 0;
      // The err msgs controlled by the btn behaviour
      if(na && count < 1 && clicked){
        this.toastr.error(this.answerCanNotBeConfirmed);
        errCount++;
      } 
      if(count >= 1 && !na && clicked){
       this.toastr.error(this.answerCanNotMarkNA);
       errCount++
      } 

      // If the any of the sub qn is answered in a row, we are marking the entire row is answered.
      if(count > 0 && !clicked) { 
        this.tableAnswersSAKs[secondaryAssetKey] = true;
      } else if(count < 0 && !clicked){
        this.tableAnswersSAKs[secondaryAssetKey] = false;
      }


      const findwithsaks = this.qAnswers.filter(item => item.secondary_asset_key == secondaryAssetKey);
      
      this.checkAllQuestionAnswered(clicked, na, secondaryAssetKey, errCount);
      this.askUserToConfirmExit = true;
    }
  }

  checkAllQuestionAnswered(clicked?,na?,secondaryAssetKey?, errCount?){
    let qCount = 0, aCount = 0; 
    let noQuestion = false, noSubsection = false, noTable = false;
    let allQnsMarkedNAInTableFlag = false;  
    let flex3Changed = false;
    if(this.currentSection.questions.length == 0){
      noQuestion = true;
    }
    for(const j of this.currentSection.questions){
      if(j.isDisplayed){qCount++;}
      if (j.qa_subtype !== "multiselect") {
        const findFlex3 = this.qAnswers.find(item => item.key == j.id);
        if (findFlex3 && findFlex3.flex3) {
          if (findFlex3.flex3 == 'A') {
            j.answerConfirmed = true;
          } else {
            j.answerConfirmed = false;
          }
        }
      } else {
        const findFlex3Arr = this.qAnswers.filter(item => item.key == j.id);
        if (findFlex3Arr && findFlex3Arr.length > 0) {
          let c = 0, nc = 0;
          findFlex3Arr.forEach((item) => {
            if (item.flex3 === "A") {
              c++;
            }
            if (item.flex3 === "NA") {
              nc++;
            }
          });
          if(c > 0){            
            j["answerConfirmed"] = true;
          } else if (nc > 0){
            j["answerConfirmed"] = false;
          }
        }
      }

      if(j.isDisplayed && (j.answerConfirmed || j.answerConfirmed == false)){
        aCount++;
      }
    }    
    if(this.currentSection.subsections.length == 0){
      noSubsection = true;
    }
    for(const j of this.currentSection.subsections){
      if(j.questions && j.questions.length > 0 && j.isDisplayed){
        for(const q of j.questions){
          if(q.isDisplayed){qCount++;}
          if (q.qa_subtype !== "multiselect") {
            const findFlex3 = this.qAnswers.find(item => item.key == q.id);
            if (findFlex3 && findFlex3.flex3) {
              if (findFlex3.flex3 == 'A') {
                q.answerConfirmed = true;
              } else {
                q.answerConfirmed = false;
              }
            }
          } else {
            const findFlex3Arr = this.qAnswers.filter(item => item.key == q.id);
            if (findFlex3Arr && findFlex3Arr.length > 0) {
              let c = 0, nc = 0;
              findFlex3Arr.forEach((item) => {
                if (item.flex3 === "A") {
                  c++;
                }
                if (item.flex3 === "NA") {
                  nc++;
                }
              });
              if(c > 0){            
                q["answerConfirmed"] = true;
              } else if (nc > 0){
                q["answerConfirmed"] = false;
              }
            }
          }

          if(q.answerConfirmed || q.answerConfirmed == false){
            aCount++;
          }
        }
      }
    }
    for(const j of this.currentSection.tables){
      //if(j.isDisplayed){qCount++;}
      const findSAKs = this.secondary_asset_keys[j.id];
      if(findSAKs.length == 0){
        noTable = true;
      }
      let totalNAQns = 0;
      if(findSAKs && findSAKs.length > 0){
        for(const sak of findSAKs){
          let TQcount = 0; let TAcount = 0;
          let flex3Flag;
          // if(j.questions && j.questions.length > 0 && j.isDisplayed){
          if(j.questions && j.questions.length > 0){
            for(const q of j.questions){
              let assetDisplayed = this.checkAssetDisplay(q);
              if(assetDisplayed){
                const findFlex3 = this.qAnswers.find(item => item.key == q.id && item.secondary_asset_key == sak);
                 if(findFlex3 && findFlex3.flex3 == 'A' && findFlex3.value != null && findFlex3.value != ''){
                  TAcount++;
                  flex3Flag = true;
                } 
                const keyIsDisplayed = `${sak}_isDisplayed`;              
                if(q[keyIsDisplayed]){
                  TQcount++;
                  if (findFlex3 && findFlex3.flex3 == 'NA' && findFlex3.value != null && findFlex3.value != '' && findFlex3.unchecked !== true){
                    TAcount++;
                    flex3Flag = false;
                  }
                }
  
                if(findFlex3 && (typeof findFlex3.flex3 === "undefined" || findFlex3.flex3 === null)){
                  if(findFlex3.value != null && findFlex3.value != ''){
                    TAcount++;
                  }
                }
              }
            }
          }
          if(TQcount != 0 && TAcount != 0 && TAcount > 0){
            qCount++;
            this.tableAnswersSAKs[sak] = flex3Flag;
          } else if(j.isDisplayed){
            qCount++;            
          }
          aCount++;
          if(TAcount > 0){
            this.tableAnswersSAKs[sak] = true;
            j.answerConfirmed = true; 
            this.qAnswers.forEach((item) => {
              if(item.secondary_asset_key === sak && (item.flex3 === null || typeof item.flex3 === "undefined" || item.flex3 === "NA")){
                item.flex3 = "A";
                item.touched = true;
                flex3Changed = true;
              }
            });     
          } else if(TAcount === 0 && !na){
            // this.tableAnswersSAKs[sak] = false;
            if(!errCount){
              this.tableAnswersSAKs[(secondaryAssetKey ? secondaryAssetKey : sak)] = false;
              this.qAnswers.forEach((item) => {
                if (item.secondary_asset_key === sak && (item.flex3 === null || typeof item.flex3 === "undefined" || item.flex3 === "A") ) {
                  item.flex3 = "NA";
                  item.touched = true;
                  flex3Changed = true;
                }
              });
            }
            j.answerConfirmed = false;
            totalNAQns++;
          }

        }
        if(totalNAQns === findSAKs.length){
          allQnsMarkedNAInTableFlag = true;
        }
      }
    }

    if(qCount != 0 && aCount != 0 && qCount == aCount){
      this.totalSectionAnswered = true;
    } else if (noQuestion && noSubsection && noTable){
      this.totalSectionAnswered = true;
    } else if(allQnsMarkedNAInTableFlag === true){
      this.totalSectionAnswered = true;
    } else {
      this.totalSectionAnswered = false;
    }

    if(flex3Changed){
      this.askUserToConfirmExit = true;
    }
  }
  /**
   * Unset the value of the 'other' question
   * @param question 
   * @param table 
   * @param secondaryAssetKey 
   */
  unsetOtherAnswerValue(question, table, secondaryAssetKey?): void {
    if (question.other_questions && question.other_questions.length > 0) {
      if (secondaryAssetKey) {
        if (!this.tableOtherQuestionsIsDisplayed[question.other_questions[0].id + '_' + secondaryAssetKey]) {
          const x = {};
          x[question.other_questions[0].id + '_' + secondaryAssetKey] = '';
          this.sectionForm.patchValue(x);
          this.sectionFormChangeHandlerForTable(null, table, question.other_questions[0], secondaryAssetKey);
        }
      } else {
        if (!question.other_questions[0].isDisplayed) {
          const x = {};
          x[question.other_questions[0].id] = null;
          this.sectionForm.patchValue(x);
          this.sectionFormChangeHandler(null, question.other_questions[0]);
        }
      }
    }
  }

  /**
   * Save questionnaire responses in the DB
   * @param alsoExit 
   */
  saveProfilingData(alsoExit?): void {
    this.spinner.show();
    // Find the items from qAnswers that have been touched in the current session
    let qAnswersToSubmit = this.qAnswers.filter(item => item.touched);
    qAnswersToSubmit = qAnswersToSubmit.filter(item => !(!item.id && (item.deleted || item.unchecked) && !(item.flex3 || (item.flex2 == 'A' && typeof item.flex3 === "undefined"))));

    // If any items need to be sent to the DB
    if (qAnswersToSubmit && qAnswersToSubmit.length > 0) {
      // For each such item, set deleted attribute to true if any of the following conditions are met. Items with deleted = true will be removed from the table
      // 1. value is null
      // 2. value is '' & secondary_asset_key is not present (i.e. non-table question with empty value)
      // 3. unchecked attribute is true (i.e. checkbox answer, which has now been unchecked) - for checkbox questions within a table, unchecked will not be set to false for the last option so that '' value is updated in DB
      // 4. secondary_asset_key is present, and the item is marked for deletion. I.e. table question, where in the entire row / column has been deleted
      for (let i = 0; i < qAnswersToSubmit.length; i++) {
        if (qAnswersToSubmit[i].value === null || qAnswersToSubmit[i].value === undefined || 
          (qAnswersToSubmit[i].value === '' && !qAnswersToSubmit[i].secondary_asset_key && qAnswersToSubmit[i].flex3 != 'NA') || 
          qAnswersToSubmit[i].unchecked ||
          (qAnswersToSubmit[i].secondary_asset_key && qAnswersToSubmit[i].deleted)
          ) {
          qAnswersToSubmit[i]['deleted'] = true;
          if(qAnswersToSubmit[i].unchecked && qAnswersToSubmit[i].flex3 == 'NA'){
            qAnswersToSubmit[i]['value'] = '';
            if(!qAnswersToSubmit[i].id){
              qAnswersToSubmit[i]['deleted'] = false;
            }
          }
        }
      }
      // For each key from this.secondary_asset_key__options, set the sak_of_modified attribute to the corresponding item
      // E.g. option = 498 caused secondary_asset_key = dskljhsfd09839823 to get created. The item with value = 498 should have sak_of_modified = dskljhsfd09839823
      // sak_of_modified is required so that we can map an option to the row / column that was created because of it
      Object.keys(this.secondary_asset_key__options).forEach(function (option) {
        const qAnswerToSubmitExists = qAnswersToSubmit.find(item => item.secondary_asset_key === option);
        if (qAnswerToSubmitExists) {
          const index = qAnswersToSubmit.indexOf(qAnswerToSubmitExists);
          if (index != null && index > -1) {
            qAnswersToSubmit[index]['sak_of_modified'] = this.secondary_asset_key__options[option];
          }
        }
      }.bind(this));
      // Make API call. Response is the updated values of qAnswers
      this.commonService.showToast('warning', 'saving_changes', {});
      const answerStats = this.getAnswerStatsLocal();
      for(const ans of qAnswersToSubmit){
        if(ans.deleted && ans.secondary_asset_key && !ans.id){          
          ans["not_for_save"] = true;
        }
      }
      qAnswersToSubmit = qAnswersToSubmit.filter(item => !item.not_for_save);
      if (navigator.onLine) {
        this.saveProfilingDataOnline(qAnswersToSubmit, alsoExit, answerStats);
        // this.updatePercentageCompletedValuesOffline();
      } else {
        this.saveProfilingDataOffline(qAnswersToSubmit, alsoExit, answerStats);
      }
    } else {
      this.commonService.showToast('success', 'changes_saved', {});
      this.askUserToConfirmExit = false;
      this.spinner.hide();
    }
  }

  /**
   * Update the qAnswers arary with answers saved in the DB
   * @param savedQAnswers 
   */
  updateQAnswers(savedQAnswers): void {
    if (!this.qAnswers) {this.qAnswers = [];}
    this.qAnswers = this.qAnswers.filter(item => !(item.unchecked || item.deleted));
    for (let i = 0; i < savedQAnswers.length; i++) {
      const x = this.qAnswers.find(item => 
        item.key == savedQAnswers[i].questionnaire_asset_id && item.value == savedQAnswers[i].answer && item.secondary_asset_key == savedQAnswers[i].secondary_asset_key
      );      
       if (x) {
        const index = this.qAnswers.indexOf(x);
         this.qAnswers[index] = {
           id: savedQAnswers[i].id.toString(),
           key: savedQAnswers[i].questionnaire_asset_id.toString(),
           value: savedQAnswers[i].answer.toString(),
           sak_of_modified: savedQAnswers[i].sak_of_modified ? savedQAnswers[i].sak_of_modified.toString() : null, 
           secondary_asset_key: savedQAnswers[i].secondary_asset_key ? savedQAnswers[i].secondary_asset_key.toString() : null,
           flex3: savedQAnswers[i].flex3
          }
       }
        else {
          this.qAnswers.push({
           id: savedQAnswers[i].id.toString(),
           key: savedQAnswers[i].questionnaire_asset_id.toString(),
           value: savedQAnswers[i].answer.toString(),
           sak_of_modified: savedQAnswers[i].sak_of_modified ? savedQAnswers[i].sak_of_modified.toString() : null, 
           secondary_asset_key: savedQAnswers[i].secondary_asset_key ? savedQAnswers[i].secondary_asset_key.toString() : null,
           flex3: savedQAnswers[i].flex3
          });
       }
    }
    // for (let i = 0; i < savedQAnswers.length; i++) {
    //   this.qAnswers.push({id: savedQAnswers[i].id.toString(), key: savedQAnswers[i].questionnaire_asset_id.toString(), sak_of_modified: savedQAnswers[i].sak_of_modified, secondary_asset_key: savedQAnswers[i].secondary_asset_key, value: savedQAnswers[i].answer.toString()});
    // }
    this.updateAllQAnswersTouchedValue(false);
  }

  /**
   * Check if a given question has answer(s) in the qAnswers array. This should be used for non-table questions only
   * @param question 
   */
  existsInQAnswers(question) {
    if (!question.is_table_question) {
      let x;
      if (question.qa_subtype === 'multiselect') {
        x = this.qAnswers.filter(item => item.key == question.id);
      } else {
        x = this.qAnswers.find(item => item.key == question.id);
      }
      return x;
    }
  }

  /**
   * Check if a given table question has answer(s) in the qAnswers array. This should be used for table questions only
   * Filter is used for all types of questions as same asset id may have multiple answers (one in each row / col)
   * @param question 
   */
  existsInQAnswersForTableQuestion(question) {
    return this.qAnswers.filter(item => item.key == question.id && !item.deleted && !item.unchecked);
  }

  /**
   * Set the checkedObject object attribute of question id + option id to true if it exists in qAnswers
   * This is used for checkbox questions that are NOT in a table
   * @param existsInQAnswers 
   * @param question 
   */
  setCheckedObject(existsInQAnswers, question) : void {
    for (let i = 0; i < existsInQAnswers.length; i++) {
      if (!existsInQAnswers[i].unchecked) {
        this.checkedObject[question.id + '_' + existsInQAnswers[i]['value']] = true;
      }
    }
  }

  /**
   * Set the checkedObject object attribute of question id + secondary_asset_key + option id to true if it exists in qAnswers for a given secondary asset key
   * This is used for checkbox questions that are in a table
   * @param existsInQAnswersForTableQuestionItem 
   * @param question 
   */
  setCheckedObjectSecondaryKeys(existsInQAnswersForTableQuestionItem, question) : void {
    if (!existsInQAnswersForTableQuestionItem.unchecked) {
      this.checkedObjectSecondaryKeys[question.id + '_' + existsInQAnswersForTableQuestionItem.secondary_asset_key + '_' + existsInQAnswersForTableQuestionItem['value']] = true;
    }
  }

  /**
   * Set isDisplayed = true / false for each child / grandchild asset in the section   
   */
  setSectionFormDisplay(): void {
    for (let i = 0; i < this.currentSection.questions.length; i++) {
      this.currentSection.questions[i].isDisplayed = this.checkAssetDisplay(this.currentSection.questions[i]);
      //if question answer is autocalucate
      if (this.currentSection.questions[i].isDisplayed && 
        (this.currentSection.questions[i].survey_q_asset_autocalculate_formulas && this.currentSection.questions[i].survey_q_asset_autocalculate_formulas.length > 0)) {
        this.runAutocalculateForNewlyDIsplayed(this.currentSection.questions[i])
      }
      if (this.currentSection.questions[i].other_questions && this.currentSection.questions[i].other_questions.length > 0) {
        this.currentSection.questions[i].other_questions[0].isDisplayed = this.checkOtherAnswerQuestionDisplay(this.currentSection.questions[i]);
      }
    }
    for (let k = 0; k < this.currentSection.subsections.length; k++) {
      this.currentSection.subsections[k].isDisplayed = this.checkAssetDisplay(this.currentSection.subsections[k]);
      for (let i = 0; i < this.currentSection.subsections[k].questions.length; i++) {
        this.currentSection.subsections[k].questions[i].isDisplayed = this.checkAssetDisplay(this.currentSection.subsections[k].questions[i]);
        if (this.currentSection.subsections[k].questions[i].isDisplayed && 
          (this.currentSection.subsections[k].questions[i].survey_q_asset_autocalculate_formulas && 
          this.currentSection.subsections[k].questions[i].survey_q_asset_autocalculate_formulas.length > 0)) {
            this.runAutocalculateForNewlyDIsplayed(this.currentSection.subsections[k].questions[i])
        }
        if (this.currentSection.subsections[k].questions[i].other_questions && this.currentSection.subsections[k].questions[i].other_questions.length > 0) {
          this.currentSection.subsections[k].questions[i].other_questions[0].isDisplayed = this.checkOtherAnswerQuestionDisplay(this.currentSection.subsections[k].questions[i]);
        }
      }
    }
    for (let k = 0; k < this.currentSection.tables.length; k++) {
      this.currentSection.tables[k].isDisplayed = this.checkAssetDisplay(this.currentSection.tables[k]);
      for (let i = 0; i < this.currentSection.tables[k].questions.length; i++) {
        if (this.currentSection.tables[k].questions[i].other_questions && this.currentSection.tables[k].questions[i].other_questions.length > 0) {
          if (!this.tableOtherQuestionsIsDisplayed) {
            this.tableOtherQuestionsIsDisplayed = {}
          }
          for (let p = 0; p < this.secondary_asset_keys[this.currentSection.tables[k].id].length; p++) {
            this.tableOtherQuestionsIsDisplayed[this.currentSection.tables[k].questions[i].other_questions[0].id + '_' + this.secondary_asset_keys[this.currentSection.tables[k].id][p]] 
            = this.checkOtherAnswerQuestionDisplay(this.currentSection.tables[k].questions[i], this.secondary_asset_keys[this.currentSection.tables[k].id][p]);
          }
        }
      }
      // TODO: Add logic for questions within the table
      for (let i = 0; i < this.currentSection.tables[k].questions.length; i++) {
        if (this.secondary_asset_keys[this.currentSection.tables[k].id] && this.secondary_asset_keys[this.currentSection.tables[k].id].length > 0) {
          for (let j = 0; j < this.secondary_asset_keys[this.currentSection.tables[k].id].length; j++) {
            this.currentSection.tables[k].questions[i][this.secondary_asset_keys[this.currentSection.tables[k].id][j] + '_isDisplayed'] = 
            this.checkAssetDisplay(this.currentSection.tables[k].questions[i], this.secondary_asset_keys[this.currentSection.tables[k].id][j]);
          }
        }
      }
    }
  }

  runAutocalculateForNewlyDIsplayed(question){
    const sections = this.currentTab.sections;
    if(sections && sections.length > 0){
      for(let k = 0; k < sections.length; k++){
        if(sections[k].questions && sections[k].questions.length > 0){
          for (const value of sections[k].questions) {
            if (value.modifies_qas != null && value.modifies_qas.includes(question.id)) {
              this.runAutoCalculate(value)
            }
          }
        }
        if(sections[k].subsections && sections[k].subsections.length > 0){
          for (let j = 0; j < sections[k].subsections.length; j++) {
            if(sections[k].subsections[j].questions && sections[k].subsections[j].questions.length > 0){
              for (const value of sections[k].subsections[j].questions) {
                if (value.modifies_qas != null && value.modifies_qas.includes(question.id)) {
                  this.runAutoCalculate(value)
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Check for simple toggle logic in deciding whether to display / hide an asset
   * By default, display the asset if there is no toggle logic defined for it
   * @param asset 
   * @param secondary_asset_key 
   */
  checkAssetDisplay(asset, secondary_asset_key?): boolean {
    // console.log('checkAssetDisplay secondary_asset_key', secondary_asset_key);
    // If the asset has any defined toggle logic
    if (asset.survey_q_asset_display_logic && asset.survey_q_asset_display_logic.length > 0) {
      // if (!secondary_asset_key) {
        // For any of the asset's display logic conditions
        for (let i = 0; i < asset.survey_q_asset_display_logic.length; i++) {
          // If the condition is of type "value_matches" - i.e. the asset on which this asset's display depends must have a value that exactly matches the condition
          if (asset.survey_q_asset_display_logic[i].logic_type === 'value_matches') {
            if (this.qAnswers.find(item => !item.unchecked && item.key == asset.survey_q_asset_display_logic[i].display_if_key && item.value == asset.survey_q_asset_display_logic[i].display_if_value && item.secondary_asset_key == secondary_asset_key)) {
              // Return true if such a value_matches condition is met
              // This exits the function immediately. If ANY condition for display is met, the asset is displayed
              return true;
            }
          }
          // If the condition is of type "value_matches" - i.e. the asset on which this asset's display depends must have any not-null, & not-blank, & non-zero value
          if (asset.survey_q_asset_display_logic[i].logic_type === 'value_exists') {
            if (this.qAnswers.find(item => !item.unchecked && item.key == asset.survey_q_asset_display_logic[i].display_if_key && item.value && item.value != '' && item.value != 0 && item.secondary_asset_key == secondary_asset_key)) {
              return true;
            }
          }
        }
        // If after looping through all display logic conditions the function has not returned true already, then return false
        return false;
      // } else {

      // }
    } else {
      // Return true in case no display logic is defined for this asset (visible by default)
      return true;
    }
  }

  /**
   * Disable the back / next buttons
   * @param newSectionIndex 
   */
  disableButton(newSectionIndex){
    if (newSectionIndex <= 0 && this.currentTabIndex <= 0){
      this.showHideBackButton = true;
    } else {
      this.showHideBackButton = false;
    }

    if(this.currentTab && this.currentTab.sections && this.questionnaire
      && newSectionIndex == this.currentTab.sections.length - 1 
      && this.currentTabIndex == this.questionnaire.length - 1 ){
      this.showHideNextButton = true;
    } else {
      this.showHideNextButton = false;
    }
  }

  /**
   * On click of Next / back button navigate to the section in a right (index+1) or left (index-1) direction
   */
  navigateSection(direction): void {
    // Find the section index to navigate to
    const newSectionIndex = this.currentSectionIndex + direction;
    const curActiveSection = <HTMLElement> document.querySelector('ul.sub-tab-fixed li a.active');
    this.disableButton(newSectionIndex);
    // If < 0, that indicates you need to move to the previous tab if the tab's index > 0
    if (newSectionIndex < 0) {
      const newTabIndex = this.currentTabIndex + direction;
      if (newTabIndex >= 0) {
        this.tabClickedSectionLast(newTabIndex);
      }
    }
    // If > 0 and less than the number of sections in the current tab, move to the clicked section's index
    else if (newSectionIndex >= 0 && newSectionIndex < this.currentTab.sections.length) {
      this.sectionClicked(newSectionIndex);
      let nextActiveSection;
      if (curActiveSection) {
        if(direction > 0) {
          nextActiveSection = <HTMLElement> curActiveSection.parentElement.nextElementSibling;
        } else {
          nextActiveSection = <HTMLElement> curActiveSection.parentElement.previousElementSibling;
        }
        const x = document.querySelector('ul.sub-tab-fixed');
        if (x) {x.scrollLeft = nextActiveSection.offsetLeft} 
      }
    }
    // In other conditions, navigate to the next tab (e.g. you click next in the last section of any tab, except the last tab)
    else {
      const newTabIndex = this.currentTabIndex + direction;
      if (newTabIndex < this.questionnaire.length && newTabIndex > 0) {
        this.tabClicked(newTabIndex);
      }
    }
  }

  //TODO: What happens on click of exit
  // exitClicked(template): void {
  //   let qAnswersToSubmit = this.qAnswers.filter(item => item.touched);
  //   if (qAnswersToSubmit && qAnswersToSubmit.length > 0) {
  //     this.unsavedAnswerCount = qAnswersToSubmit.length;
  //     this.saveChangesConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  //   } else {
  //     this.confirmExit();
  //   }
  // }
  /**
   * Handle click of exit button
   */
  exitClicked() {
    if (!navigator.onLine) {
      this.commonService.showToast('info', 'offline_sync_later', {});
    }
    if (this.campaignId) {
      this.router.navigate([`/campaign-profiling-farmers/${this.projectId}/${this.campaignId}`]);
    } else {
      this.router.navigate([`/profiling/${this.projectId}`]);
    }
  }

  closeModal(isOkay: boolean) {
    this.saveChangesConfirmModal.hide();
    this.subject.next(isOkay);
  }


  confirmExit(): void {
    if (this.saveChangesConfirmModal) {
      this.saveChangesConfirmModal.hide();
      this.unsavedAnswerCount = 0;
    }
    setTimeout(() => {this.router.navigate([`/profiling/${this.projectId}`]);}, 10);
  }


  closeSurveyTemplateModal() {
    this.saveChangesConfirmModal.hide();
  }

  backClicked(): void {
    if (this.campaignId) {
      this.router.navigate([`/campaign-profiling-farmers/${this.projectId}/${this.campaignId}`]);
    } else {
      this.router.navigate([`/profiling/${this.projectId}`]);
    }
  }

  // Run non-trivial toggle logic - around create / remove a row / column from a table
  // This function handles the scenarios where a change in input = number is supposed to modify a table
  // runDisplayLogic(question, answer) {
  //   // Check for question.toggleType value, e.g. 'generateMultipleChildren' - do not check for asset id
    
  //   // Get the table that this question is supposed to modify
  //   let tableToModify = this.currentSection.tables.find(item => item.id == question.table_to_modify);
  //   console.log('tableToModify', tableToModify, this.currentSection);
  //   // If it exists
  //   if (tableToModify) {
  //     // If current number of secondary asset keys is less than the newly answered value, new secondary asset key rows / cols must be added to the table
  //     // Initialize the this.secondary_asset_keys for this table
  //     if (!this.secondary_asset_keys[tableToModify.id]) {this.secondary_asset_keys[tableToModify.id] = []}
  //     let currentSecondarys = this.secondary_asset_keys[tableToModify.id].length;
  //     if (currentSecondarys < answer) {
  //       // Iterate as many times as the answer is GREATER than current number of secondary asset keys
  //       for (let k = 0; k < answer - currentSecondarys; k++) {
  //         // Generate a new secondary asset key
  //         let secondary_asset_key = uuidv4();
  //         // Add form controls (per question - key combination)
  //         for (let i = 0; i < tableToModify.questions.length; i++) {
  //           this.sectionForm.addControl(tableToModify.questions[i].id + '_' + secondary_asset_key, new FormControl(''));
  //           if (tableToModify.questions[i].disable_input) {this.sectionForm.controls[tableToModify.questions[i].id + '_' + secondary_asset_key].disable();}
  //           if (tableToModify.questions[i].include_uom_question) {this.setUOMQuestion(tableToModify.questions[i], tableToModify.questions[i].disable_input, {secondary_asset_key: secondary_asset_key, value: ''});}
  //         }
  //         // Add the newly generated key into the secondary_asset_keys object
  //         this.secondary_asset_keys[tableToModify.id].push(secondary_asset_key);
  //         // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
  //         this.addTableQuestionsIntoQAnswers(tableToModify, secondary_asset_key);
  //       }
  //     }
  //     // If current number of secondary asset keys is MORE than the newly answered value, old secondary asset key rows must be removed from the table
  //     else if (currentSecondarys > answer) {
  //       for (let k = 0; k < currentSecondarys - answer; k++) {
  //         // Find the secondary_asset_key to be removed (it is the last row / column in the table)
  //         let secondary_asset_key = this.secondary_asset_keys[tableToModify.id][(currentSecondarys - 1) - k];
  //         // Remove each question from the row / column from the forngroup
  //         for (let i = 0; i < tableToModify.questions.length; i++) {
  //           this.sectionForm.removeControl(tableToModify.questions[i].id + '_' + secondary_asset_key);
  //         }
  //         // Remove that row / col's secondary_asset_key from the this.secondary_asset_keys
  //         this.secondary_asset_keys[tableToModify.id] = this.secondary_asset_keys[tableToModify.id].filter(item => item != secondary_asset_key);

  //         // As the entire row / column is being removed, rark the entire table row / column questions for deletion
  //         for (let i = 0; i < this.qAnswers.length; i++) {
  //           if (this.qAnswers[i].secondary_asset_key == secondary_asset_key) {
  //             this.markDeleted(i);
  //             this.markTouched(i);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // Run non-trivial toggle logic - around create / remove a row / column from a table
  // This function handles the scenarios where a change in input = checkbox is supposed to modify a table
  // runDisplayLogicCheckBox(question, answer, checked) {
  //   // Get the table that this question is supposed to modify
  //   let tableToModify = this.currentSection.tables.find(item => item.id == question.table_to_modify);
  //   // If it exists
  //   if (tableToModify) {
  //     // Initialize this.secondary_asset_keys[tableToModify.id] for this table
  //     if (!this.secondary_asset_keys[tableToModify.id]) {this.secondary_asset_keys[tableToModify.id] = []}
  //     // Initialize this.secondary_asset_key__options = which option checked/unchecked has triggered addition of the secondary_asset_key
  //     if (!this.secondary_asset_key__options[answer]) {this.secondary_asset_key__options[answer] = []}
  //     // If the checkbox was marked as checked
  //     if (checked) {
  //       // Generate a new secondary_asset_key
  //       let secondary_asset_key = uuidv4();
  //       // For each question in the table, add a form control, & disable user input if required
  //       for (let i = 0; i < tableToModify.questions.length; i++) {
  //         this.sectionForm.addControl(tableToModify.questions[i].id + '_' + secondary_asset_key, new FormControl(''));
  //         if (tableToModify.questions[i].disable_input) {this.sectionForm.controls[tableToModify.questions[i].id + '_' + secondary_asset_key].disable();}
  //         if (tableToModify.questions[i].include_uom_question) {this.setUOMQuestion(tableToModify.questions[i], tableToModify.questions[i].disable_input, {secondary_asset_key: secondary_asset_key, value: ''});}
  //       }
  //       // Add the newly generated secondary_asset_key to this.secondary_asset_keys
  //       this.secondary_asset_keys[tableToModify.id].push(secondary_asset_key);
  //       // Add the newly generated secondary_asset_key as value of this.secondary_asset_key__options[answer]
  //       this.secondary_asset_key__options[answer] = secondary_asset_key;
  //       // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
  //       this.addTableQuestionsIntoQAnswers(tableToModify, secondary_asset_key);
  //     }
  //     // if unchecked
  //     else {
  //       // Find the secondary_asset_key to be removed
  //       let secondaryKeyToRemove = this.secondary_asset_key__options[answer];
  //       // Remove the corresponding form controls
  //       for (let i = 0; i < tableToModify.questions.length; i++) {
  //         this.sectionForm.removeControl(tableToModify.questions[i].id + '_' + secondaryKeyToRemove);
  //       }
  //       // Remove this secondary_asset_key from this.secondary_asset_keys
  //       this.secondary_asset_keys[tableToModify.id] = this.secondary_asset_keys[tableToModify.id].filter(item => item != secondaryKeyToRemove);
  //       // Delete this this.secondary_asset_key__options[answer]
  //       if (this.secondary_asset_key__options[answer]) {delete this.secondary_asset_key__options[answer];}

  //       // As the entire row / column is being removed, rark the entire table row / column questions for deletion
  //       for (let i = 0; i < this.qAnswers.length; i++) {
  //         if (this.qAnswers[i].secondary_asset_key == secondaryKeyToRemove) {
  //           this.qAnswers[i].deleted = true;
  //           this.qAnswers[i].touched = true;
  //         }
  //       }
  //     }
  //   }
  // }

  //clear calendar date for table
  clearTableCalendarDate(e, table, question, secondaryAssetKey){
    this.sectionForm.controls[question.id + '_' + secondaryAssetKey].setValue('');
    this.sectionFormChangeHandlerForTable(e, table, question, secondaryAssetKey);
  }

  /**
   * Handle input change events
   * Table questions (type = text, number, select)
   * @param args 
   * @param table 
   * @param question 
   * @param secondaryAssetKey 
   */
  sectionFormChangeHandlerForTable(args, table, question, secondaryAssetKey): void {
    if (this.questionHasNoErrors(question, secondaryAssetKey)) {
      if (!this.qAnswers) {this.qAnswers = [];}
      // if question subtype is date convert it to readable format
      if(question.qa_subtype == 'date'){          
        if(this.sectionForm.value[question.id + '_' + secondaryAssetKey] == undefined){
          return;
        }
        let dateSelected;     
        if(this.sectionForm.value[question.id + '_' + secondaryAssetKey] == ''){
          dateSelected = '';
        } else {
          let dateValue = this.sectionForm.value[question.id + '_' + secondaryAssetKey];
          let date;
          if(typeof dateValue == "string"){
            let dateParts = dateValue.split("-");
            date = new Date(+dateParts[2], parseInt(dateParts[1]) - 1, +dateParts[0]);
          } else {
            date = new Date(dateValue);
          }
          // const dateSelected = date.getDate() + '-' + this.monthShortNames[date.getMonth()] + '-' + date.getFullYear();
          dateSelected = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
          // console.log("dateSelected from sectionFormChangeHandlerForTable:",dateSelected);
        }
        this.sectionForm.value[question.id + '_' + secondaryAssetKey] = dateSelected;       
      }
    // See if this object already exists in qAnswers
    const x = this.qAnswers.find(item => item.key == question.id && item.secondary_asset_key == secondaryAssetKey);
      // If object exists, find the index
      const value = this.sectionForm.value[question.id + '_' + secondaryAssetKey];
      // if (this.notADuplicateValue(question, value)) { // Uncomment this function to apply constraint of unique answer value for given question
        if (x) {
          const index = this.qAnswers.indexOf(x);
          // Set this object's touched parameter to true
          this.qAnswers[index]['touched'] = true;
          // Set the value of the formcontrol or set '' in case form control's value is null. This is required as each table question must have an entry in the answers table (even if '')
          this.qAnswers[index]['value'] = (value != null && value !== '') ? value : '';
          // if (this.qAnswers[index]['value'] === '') {this.unsetOtherAnswerValue(question, table, secondaryAssetKey);}
          if (this.qAnswers[index]['deleted']) {this.qAnswers[index]['deleted'] = false;}
        } else {
          // Set the value of the formcontrol or set '' in case form control's value is null. This is required as each table question must have an entry in the answers table (even if '')
          if (value != null && value !== '') {
            this.qAnswers.push({key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: this.sectionForm.value[question.id + '_' + secondaryAssetKey].toString(), touched: true});
          } else {
            this.qAnswers.push({key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: '', touched: true});
            // this.unsetOtherAnswerValue(question, table, secondaryAssetKey);
          }
        }
        // Set the display of assets in this section
        this.setSectionFormDisplay();
        this.unsetOtherAnswerValue(question, table, secondaryAssetKey);
        // Run auto calculate logic if applicable
        if (question.modifies_qas) {
          this.runAutoCalculate(question, secondaryAssetKey, true);
        }
        // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
        this.addTableQuestionsIntoQAnswers(table, secondaryAssetKey);
        this.askUserToConfirmExit = true;
        this.modifyConnectedTable(table, secondaryAssetKey, 'update');
        this.modifyConnectedQuestion(table, secondaryAssetKey, 'update');
        if (question.uom_questions && question.uom_questions.length > 0) {
          this.setUOMValuesInQAnswers(question, secondaryAssetKey, table);
        }
        if (table && table.grand_total_questions && table.grand_total_questions.length > 0) {
          this.runGrandTotalCalculate(table.grand_total_questions[0]);
        } else {
          if (this.showSummaryForCurrentTab) {
            this.runTabSummaryCalculate();
          }
        }
        this.unsetDependentQuestions(question, secondaryAssetKey); 
      // } 
      // else {
      //   this.toastr.error('This is a duplicate value & cannot be selected');
      //   let x = {};
      //   x[question.id + '_' + secondaryAssetKey] = '';
      //   this.sectionForm.patchValue(x);
      // }
      this.checkAutoValidation(table, secondaryAssetKey);
      if (value != null && value !== '') {        
        this.searchKey(secondaryAssetKey);
        if(question.code == '8e4574af-dea9-4db9-a70e-8c2fec029f6f'){
          this.cropNameQuestionAnswered = true;
        }
      }
      if(value == '' || value == null){
        if(question.code == '8e4574af-dea9-4db9-a70e-8c2fec029f6f'){
          this.cropNameQuestionAnswered = false;
        }
        // this.checkAllAnswersRow(secondaryAssetKey);
        // this.checkAllAnswersTable(table);
      }
      this.checkAllAnswersTable(table);
      this.checkMandatoryQuestions();
    } else {
      if(question.code == '8e4574af-dea9-4db9-a70e-8c2fec029f6f'){
        this.cropNameQuestionAnswered = false;
        // this.checkAllAnswersRow(secondaryAssetKey);
        this.checkAllAnswersTable(table);
      }
      if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) { 
        /* If question is mandatory then disable navigation buttons */
        for (let j = 0; j < question.survey_q_asset_validations.length; j++) {
          if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
            this.mandatoryQuestionAnswered = false;
          }
        }
      }
    }
  }

  checkAllAnswersRow(sak){   
    const allTableSAKAnswers = this.qAnswers.filter(item => item.secondary_asset_key == sak);
    let emptyValue = 0;
    for(const i of allTableSAKAnswers){
      let question;
      for(const t of this.currentSection.tables){
        question = t.questions.find(item => item.id == i.key);
      }
      if (i.value != null && i.value !== '' && question) {        
        emptyValue++;
      }
    }
    if(emptyValue == 0){
      this.tableQuestionAnswered = false;
    }
  }

  checkAllAnswersTable(table) {
    this.tableQuestionAnswered = true;
    let emptyRowsPresentinTable = false;
    if (typeof this.secondary_asset_keys[table.id] !== "undefined") {
      for (let p = 0, emptyValue = 0; p < this.secondary_asset_keys[table.id].length; p++, emptyValue = 0) {
        let sak = this.secondary_asset_keys[table.id][p];
        const allTableSAKAnswers = this.qAnswers.filter(item => item.secondary_asset_key == sak);
        for (const i of allTableSAKAnswers) {
          let question;
          for (const t of this.currentSection.tables) {
            question = t.questions.find(item => item.id == i.key);
            if(question){
              break;
            }
          }
          if (i.value != null && i.value !== '' && question) {
            if (!(typeof i.unchecked !== "undefined" && i.unchecked == true)) {
              emptyValue++;
            }
          }
        }

        if (emptyValue == 0) {
          emptyRowsPresentinTable = true;
          break;
        }
      }
    }

    if (emptyRowsPresentinTable === true) {
      this.tableQuestionAnswered = false;
    }
  }

  checkAutoValidation(table, secondaryAssetKey){
    const allTableSAKAnswers = this.qAnswers.filter(item => item.secondary_asset_key == secondaryAssetKey);
    let allTableAnswered = true;
    const totalVisibleQuestions = []
    let TQcount = 0, count = 0;
    if(table.questions && table.questions.length > 0){
      for(const q of table.questions){
        const keyIsDisplayed = `${secondaryAssetKey}_isDisplayed`;
        if(q[keyIsDisplayed]){
          TQcount++;
          totalVisibleQuestions.push(q.id);
        }
      }
    }
    for(const i of allTableSAKAnswers){
      const findInVisibleQuestion = totalVisibleQuestions.find(item => item == i.key);
      if(findInVisibleQuestion){
        if(i.value == null || i.value == '' || i.unchecked === true){
          allTableAnswered = false; 
        }else{
          count++;
        }
      }
    }
    if(count > 0){
      allTableAnswered = true;
    } 
    if(allTableAnswered){
      this.confirmTableAnswer('', table.id, secondaryAssetKey, true);
    } else {      
      this.confirmTableAnswer('', table.id, secondaryAssetKey, false);
    }
  }

  /**
   * Called for checkbox type question - which has dynamic options (which crop intercropping with surveyed crop?)
   * @param args 
   * @param option 
   * @param question 
   */
  sectionFormChangeHandlerCheckboxDynamicOptions(args, option, question): void {
    if (this.questionHasNoErrors(question)) {
      this.updateCheckedObject(args, option.q.toString() + '__' + option.sak.toString(), question.id, null);
      if (!this.qAnswers) {this.qAnswers = [];}
      // If the checkbox is checked
      if (args.target.checked) {
        const checkedItem = this.qAnswers.find(item => (item.key == question.id && item.value == option.q + '__' + option.sak));
        if (checkedItem) {
          const checkedItemIndex = this.qAnswers.indexOf(checkedItem);
          if (checkedItemIndex != null && checkedItemIndex > -1) {
            this.qAnswers[checkedItemIndex]['unchecked'] = false;
            if (this.qAnswers[checkedItemIndex]['deleted']) {this.qAnswers[checkedItemIndex]['deleted'] = false;}
          }
        } else {
          // Add the answer to qAnswers array. Value should contain the option asset's id
          this.qAnswers.push({key: question.id.toString(), value: option.q.toString() + '__' + option.sak.toString(), touched: true, checked: true});
        }
      } else {
        // If checkbox is unchecked, find the unchecked item in qAnswers
        const uncheckedItem = this.qAnswers.find(item => (item.key == question.id && item.value == option.q + '__' + option.sak));
        if (uncheckedItem) {
          const uncheckedItemIndex = this.qAnswers.indexOf(uncheckedItem);
          if (uncheckedItemIndex != null && uncheckedItemIndex > -1) {
            // If the unchecked item has an id (meaning it was created & saved in DB previously), then set the unchecked attribute to true
            // if (this.qAnswers[uncheckedItemIndex].id && this.qAnswers[uncheckedItemIndex].id > 0) {
            //   this.qAnswers[uncheckedItemIndex]['touched'] = true;
            //   this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
            // } else {
            //   // If there is no id, this checkbox has been checked & unchecked in the same session. The item can be remvoed from qAnswers
            //   // this.qAnswers = this.qAnswers.filter(item => !(item.key == question.id && item.value == option.id));
            //   // TODO: Remove this from IDB
            //   this.qAnswers[uncheckedItemIndex]['touched'] = true;
            //   this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
            // }
            this.qAnswers[uncheckedItemIndex]['touched'] = true;
            this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
            //   // TODO: Remove this from IDB
            // this.unsetOtherAnswerValue(question, null, null);
          }
        }
      }
      // Run display logic if required
      // if (question.table_to_modify != null && question.table_to_modify != '') {this.runDisplayLogicCheckBox(question, option.id, this.sectionForm.value[question.id]);}
      // Set which assets to display
      this.setSectionFormDisplay();
      this.unsetOtherAnswerValue(question, null, null);
      
      //check if not any option is selected
      const allQAnswers = this.qAnswers.filter(item => item.key == question.id);
      let allEmpty = true;
      for(const i of allQAnswers){
        if(!i.unchecked && i.value !== ""){
          allEmpty = false;
        }
      }

      if(allEmpty){
        this.confirmAnswer("", question, false, "", false);
      } else{
        this.confirmAnswer("", question, true, "", false);
      }

      this.askUserToConfirmExit = true;
    }
  }

  /**
   * Called for checkbox type question in tables - which has dynamic options (which crop intercropping with surveyed crop?)
   * @param args 
   * @param table 
   * @param question 
   * @param option 
   * @param secondaryAssetKey 
   * @param calledFromUnsetQuestionValue 
   */
  sectionFormChangeHandlerForTableCheckboxDynamicOptions(args, table, question, option, secondaryAssetKey, calledFromUnsetQuestionValue?): void {
    if (this.questionHasNoErrors(question, secondaryAssetKey)) {
      this.updateCheckedObject(args, option.q.toString() + '__' + option.sak.toString(), question.id, secondaryAssetKey);
      if (!this.qAnswers) {this.qAnswers = [];}
        // Find all the qAnswers with this asset id and secondary asset key and where unchecked != true
        const checkboxOptions = this.qAnswers.filter(item => item.key == question.id && item.secondary_asset_key == secondaryAssetKey && !item.unchecked);
        // Find the qAnswer with the clicked option id or value = ''
        const changedItem = this.qAnswers.find(item => item.key == question.id && item.secondary_asset_key == secondaryAssetKey && (item.value === '' || item.value == option.q + '__' + option.sak));
        
        if (changedItem) {
          const index = this.qAnswers.indexOf(changedItem);
          if (index != null && index > -1) {
            // if checkbox is being checked
            if (args.target.checked) {
              // Set the value of the option id, set unchecked to false and touched to true
              this.qAnswers[index]['value'] = option.q.toString() + '__' + option.sak.toString();
              this.qAnswers[index]['unchecked'] = false;
              this.qAnswers[index]['touched'] = true;
              if (this.qAnswers[index]['deleted']) {this.qAnswers[index]['deleted'] = false;}
            } 
            // If the checkbox is being unchecked
            else {
              // Set touched to true
              this.qAnswers[index]['touched'] = true;
              // If there is at least one more item in qAnswers with key = question in this column (with this secondary asset key), then mark the unchecked item with unchecked = true
              // This will delete it from the db
              if (checkboxOptions.length > 1) {
                this.qAnswers[index]['unchecked'] = true;
              }
              // If the last checkbox in this row / column for this question is being unchecked, set it's value to '' & set unchecked to false
              // This will ensure it is NOT deleted in the db, just that the value is updated to ''
              else if (checkboxOptions.length === 1) {
                this.qAnswers[index]['value'] = '';
                this.qAnswers[index]['unchecked'] = false;
              }
            }
          }
        }
        // If this item does not yet exist in qAnswers, add it
        else {
          this.qAnswers.push({key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: option.q.toString() + '__' + option.sak.toString(), touched: true, checked: true});
        }
        // Set display of assets in this section
        this.setSectionFormDisplay();
        this.unsetOtherAnswerValue(question, table, secondaryAssetKey);
        // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
        this.addTableQuestionsIntoQAnswers(table, secondaryAssetKey);
        this.askUserToConfirmExit = true;
    }
  }

  /**
   * Handle input change events
   * Table questions (type = checkbox)
   * @param args 
   * @param table 
   * @param question 
   * @param option 
   * @param secondaryAssetKey 
   */
  sectionFormChangeHandlerForTableCheckbox(args, table, question, option, secondaryAssetKey): void {
    if (this.questionHasNoErrors(question, secondaryAssetKey)) {
      this.updateCheckedObject(args, option.id, question.id, secondaryAssetKey);
      if (!this.qAnswers) {this.qAnswers = [];}
        // Find all the qAnswers with this asset id and secondary asset key and where unchecked != true
        const checkboxOptions = this.qAnswers.filter(item => item.key == question.id && item.secondary_asset_key == secondaryAssetKey && !item.unchecked);
        // Find the qAnswer with the clicked option id or value = ''
        const changedItem = this.qAnswers.find(item => item.key == question.id && item.secondary_asset_key == secondaryAssetKey && (item.value === '' || item.value == option.id));
        
        if (changedItem) {
          const index = this.qAnswers.indexOf(changedItem);
          if (index != null && index > -1) {
            // if checkbox is being checked
            if (args.target.checked) {
              // Set the value of the option id, set unchecked to false and touched to true
              this.qAnswers[index]['value'] = option.id.toString();
              this.qAnswers[index]['unchecked'] = false;
              this.qAnswers[index]['touched'] = true;
              if (this.qAnswers[index]['deleted']) {this.qAnswers[index]['deleted'] = false;}
            } 
            // If the checkbox is being unchecked
            else {              
              // Set touched to true
              this.qAnswers[index]['touched'] = true;
              console.log('checkboxOptions', checkboxOptions);
              // If there is at least one more item in qAnswers with key = question in this column (with this secondary asset key), then mark the unchecked item with unchecked = true
              // This will delete it from the db
              if (checkboxOptions.length > 1) {
                this.qAnswers[index]['unchecked'] = true;
              }
              // If the last checkbox in this row / column for this question is being unchecked, set it's value to '' & set unchecked to false
              // This will ensure it is NOT deleted in the db, just that the value is updated to ''
              else if (checkboxOptions.length === 1) {
                this.qAnswers[index]['value'] = '';
                this.qAnswers[index]['unchecked'] = false;
              }
            }
          }
        }
        // If this item does not yet exist in qAnswers, add it
        else {
          this.qAnswers.push({key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: option.id.toString(), touched: true, checked: true});
        }

        //check if not any option is selected
        const allQAnswers = this.qAnswers.filter(item => item.key == question.id && item.secondary_asset_key == secondaryAssetKey);
        let allEmpty = true;
        for(const i of allQAnswers){
          if(i.value != '' && !i.unchecked){
            allEmpty = false;
          }
        }
        if(allEmpty){      
          //to show input required message check if question is mandaory;
          if(question.survey_q_asset_validations){
            const checkMandatory = question.survey_q_asset_validations.find(item => item.validation_key == "mandatory");
            if(checkMandatory){
              this.sectionForm.controls[question.id + '_' + secondaryAssetKey].setErrors({'required': true});
            }
          }
        }
        // Set display of assets in this section
        this.setSectionFormDisplay();
        this.unsetOtherAnswerValue(question, table, secondaryAssetKey);
        // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
        this.addTableQuestionsIntoQAnswers(table, secondaryAssetKey);
        this.unsetDependentQuestions(question, secondaryAssetKey);
        this.askUserToConfirmExit = true;

        
      this.checkAutoValidation(table, secondaryAssetKey);
      this.checkAllAnswersTable(table);
      this.checkMandatoryQuestions();
    } else if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) { 
      /* If question is mandatory then disable navigation buttons */
      for (let j = 0; j < question.survey_q_asset_validations.length; j++) {
        if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
          this.mandatoryQuestionAnswered = false;
        }
      }
    }
  }

  /**
   * Run an auto calculate formula calculation when question's answer is modified
   * @param question 
   * @param secondary_asset_key 
   */
  runAutoCalculate(question, secondary_asset_key?, fromUI?): void {
    // Find which questionnaire assets are modified by this asset (who's change event has triggered this function call)
    const qasToModify = question.modifies_qas.split(",");
    // For each such questionnaire asset to be modified
    for (let i = 0; i < qasToModify.length; i++) {
      // Try to find the question in the section's child questions
      const qaToModify =  this.getAssetNew(qasToModify[i]);
      // If any such question is found
      if (qaToModify) {
        if (this.checkAssetDisplay(qaToModify)) {
          // Define and set value of the target questions form control, depending on whether secondary_asset_key exists or not
          let fcName; let uomName;
          fcName = secondary_asset_key ? qaToModify.id + '_' + secondary_asset_key : qaToModify.id;
          fcName = fcName.toString();
          if (qaToModify.uom_questions && qaToModify.uom_questions.length > 0) {
            uomName = secondary_asset_key ? qaToModify.uom_questions[0].id + '_' + secondary_asset_key : qaToModify.uom_questions[0].id;
            uomName = uomName.toString();
          }
          // Get the formula to be evaluated (e.g. "135,*,161")
          if (qaToModify.survey_q_asset_autocalculate_formulas && qaToModify.survey_q_asset_autocalculate_formulas.length > 0) {
            const formula = qaToModify.survey_q_asset_autocalculate_formulas[0].formula;
            // Find the elements of the formula [135, *, 161]
            const formulaArray = formula.split(",");
            const formulaObjArray = [];
            for (let p = 0; p < formulaArray.length; p++) {
              // Ignore non-number elements of the formula
              if (isNaN(parseInt(formulaArray[p]))) {}
              else {
                // Find the value of the questionnaire asset id in the formula from the qAnswers array
                let answer; let x;
                if (!secondary_asset_key) {
                  // answer = this.qAnswers.find(item => item.key == formulaArray[p]) ? this.qAnswers.find(item => item.key == formulaArray[p]).value : null;
                  x = this.qAnswers.find(item => item.key == formulaArray[p]) ? this.qAnswers.find(item => item.key == formulaArray[p]) : null;
                } else {
                  // answer = this.qAnswers.find(item => item.key == formulaArray[p] && item.secondary_asset_key == secondary_asset_key) ? this.qAnswers.find(item => item.key == formulaArray[p] && item.secondary_asset_key == secondary_asset_key).value : null;
                  x = this.qAnswers.find(item => item.key == formulaArray[p] && item.secondary_asset_key == secondary_asset_key) ? this.qAnswers.find(item => item.key == formulaArray[p] && item.secondary_asset_key == secondary_asset_key) : null;
                }
                // If any of the answers is not a valid value, set the answer as 0
                if (x == null || (x.value && x.value == "")) {
                  x = {key: formulaArray[p], value: 0, secondary_asset_key: secondary_asset_key ? secondary_asset_key : null}
                }
                // formulaArray[p] = parseFloat(answer);
                formulaObjArray.push(x);
              }
            }
            // Evaluate the formula string with values
            const y = JSON.parse(JSON.stringify(formulaObjArray));
            const p = this.newAdjustFormulaArray(qaToModify, y, formulaArray, secondary_asset_key);
            if (!p) {return;}
            for (let k = 0; k < p['formula'].length; k++) {
              if (p['formula'][k] === '') {
                p['formula'][k] = 0;
              }
            }
            let answerToUpdate = this.calculateFormula(p['formula']);
            answerToUpdate = answerToUpdate ? answerToUpdate.toString() : '';
            // Create the patchValue object
            const x = {};
            x[fcName] = answerToUpdate;
            if (uomName) {x[uomName] = p['unit'];}
            // Patch the auto calculated value in the formgroup
            this.sectionForm.patchValue(x);
            // Add / Update the value in qAnswers
            let answerOfQaToModify; let answerOfQaToModifyUOM;
            if (secondary_asset_key) {
              answerOfQaToModify = this.qAnswers.find(item => item.key == qaToModify.id && item.secondary_asset_key == secondary_asset_key);
              if (uomName) {answerOfQaToModifyUOM = this.qAnswers.find(item => item.key == uomName && item.secondary_asset_key == secondary_asset_key);}
            } else {
              answerOfQaToModify = this.qAnswers.find(item => item.key == qaToModify.id);
              if (uomName) {answerOfQaToModifyUOM = this.qAnswers.find(item => item.key == uomName);}
            }
            if (answerOfQaToModify) {
              const index = this.qAnswers.indexOf(answerOfQaToModify);
              if (index != null && index > -1) {
                this.qAnswers[index]['value'] = answerToUpdate.toString();
                this.qAnswers[index]['touched'] = true;        
                //auto validation added for auto calculated answers
                
                let findQn = this.currentSection.questions.find(item => item.id == this.qAnswers[index].key);

                if(findQn) {
                  //checking if all questions are validated or not
                  const formula2 = findQn.survey_q_asset_autocalculate_formulas[0].formula;
                  const formulaArray2 = formula2.split(",");
                  const qArray = formulaArray2.filter(item => !isNaN(parseFloat(item)));
                  let questionCount = 0;
                  for(const qid of qArray){
                    let x;
                    if (!secondary_asset_key) {
                      x = this.qAnswers.find(item => item.key == qid);
                    } else {
                      x = this.qAnswers.find(item => item.key == qid && item.secondary_asset_key == secondary_asset_key); 
                    }
                    if (x && x.flex3){
                      questionCount++;
                    }
                    //to check if current question type is grand total
                    for(const t of this.currentSection.tables){
                      if(t.grand_total_questions && t.grand_total_questions.length > 0){
                        const gt = t.grand_total_questions.find(item => item.id == qid); 
                        let findA = this.qAnswers.find(item => item.key == qid);
                        if(gt && fromUI && !findA){
                          questionCount++;
                        }
                      }                      
                    }
                  }
                  if(qArray.length == questionCount) {
                    if (answerToUpdate !== "") {
                      this.confirmAnswer("", findQn, true, true)
                    } else {
                      this.confirmAnswer("", findQn, false, true)
                    }
                  }
                }

                let findSubSecQn = null;
                for (const j of this.currentSection.subsections) {
                  findSubSecQn = j.questions.find(item => item.id == this.qAnswers[index].key);
                }              
                
                if(findSubSecQn) {
                   //checking if all questions are validated or not
                    const formula3 = findSubSecQn.survey_q_asset_autocalculate_formulas[0].formula;
                    const formulaArray3 = formula3.split(",");
                    const qArray2 = formulaArray3.filter(item => !isNaN(parseFloat(item)));
                    let questionCount2 = 0;
                    for(const qid of qArray2){
                      let x;
                      if (!secondary_asset_key) {
                        x = this.qAnswers.find(item => item.key == qid);
                      } else {
                        x = this.qAnswers.find(item => item.key == qid && item.secondary_asset_key == secondary_asset_key); 
                      }
                      if (x && x.flex3){
                        questionCount2++;
                      }
                      //to check if current question type is grand total
                      for(const t of this.currentSection.tables){
                        if(t.grand_total_questions && t.grand_total_questions.length > 0){
                          const gt = t.grand_total_questions.find(item => item.id == qid);
                          let findA = this.qAnswers.find(item => item.key == qid);
                          if(gt && fromUI && !findA){
                            questionCount2++;
                          }
                        }                      
                      }
                    }
                    if(qArray2.length == questionCount2){
                      if (answerToUpdate !== "") {
                        this.confirmAnswer("", findSubSecQn, true, true)
                      } else {
                        this.confirmAnswer("", findSubSecQn, false, true)
                      }
                    }
                }
                
                let findQnTable; let tableid;
                for(const t of this.currentSection.tables){
                  findQnTable = t.questions.find(item => item.id == this.qAnswers[index].key);
                  tableid = t.id;
                }
                if(findQnTable){
                  if (answerToUpdate !== "") {
                    this.confirmTableAnswer("", tableid, secondary_asset_key, true)
                  } else {
                    this.confirmTableAnswer("", tableid, secondary_asset_key, false)
                  }
                }
              }
            }
            else {
              this.qAnswers.push({key: qaToModify.id.toString(), value: answerToUpdate.toString(), secondary_asset_key: secondary_asset_key, touched: true});
            }

            if (answerOfQaToModifyUOM) {
              const index = this.qAnswers.indexOf(answerOfQaToModifyUOM);
              if (index != null && index > -1) {
                this.qAnswers[index]['value'] = p['unit'].toString();
                this.qAnswers[index]['touched'] = true;
                this.qAnswers[index]['flex3'] = answerToUpdate ? "A" : "NA";
              }
            }
            else {
              if (p['unit']) {this.qAnswers.push({key: uomName.toString(), value: p['unit'].toString(), secondary_asset_key: secondary_asset_key, touched: true});}
            }
            
            // In case the auto calcualted field is part of another auto calculation (as an input), then run the next auto calculation as well
            if (qaToModify.modifies_qas) {
              this.runAutoCalculate(qaToModify, secondary_asset_key)
            }

            if (secondary_asset_key) {
              const parentTable = this.getParentAsset(question);
              // console.log('parentTable', parentTable);
              if (parentTable && parentTable.grand_total_questions && parentTable.grand_total_questions.length > 0) {
                this.runGrandTotalCalculate(parentTable.grand_total_questions[0]);
              } else if (parentTable && (!parentTable.grand_total_questions || parentTable.grand_total_questions.length == 0)) {
                if (this.showSummaryForCurrentTab) {
                  this.runTabSummaryCalculate();
                }
              }
            }
          }
        }
      }
    }
  }


  /**
   * Check if the uom_question has a value, & create the corresponding sectionForm control. Also add the options to be displayed in the dropdown
   * @param question 
   * @param disable 
   * @param existsInQAnswersForTableQuestionItem 
   */
  setUOMQuestion(question, disable, existsInQAnswersForTableQuestionItem?): void {
    if (!existsInQAnswersForTableQuestionItem) {
      // If the question has uom_questions children elements
      if (question.uom_questions && question.uom_questions.length > 0) {
        for (let i = 0; i < question.uom_questions.length; i++) {
          // For each uom_question child element, check if it has a value in qAnswers, & create the form control accordingly
          const existsInQAnswers = this.existsInQAnswers(question.uom_questions[i]);
          this.sectionForm.addControl(question.uom_questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
          if (disable) {this.sectionForm.controls[question.uom_questions[i].id].disable();}
          // Set the options dropdown for this uom_question
          question.uom_questions[i].options = this.project.ProjectUOM.filter(item => item.unit_type_id == question.uom_questions[i].qa_subtype);
          this.setUOMValuesInForm(question.uom_questions[i].id, question.uom_questions[i].options, null);
        }
      }
    } else {
      if (question.uom_questions && question.uom_questions.length > 0) {
        for (let i = 0; i < question.uom_questions.length; i++) {
          const valueObject = this.qAnswers.find(item => 
            item.key == question.uom_questions[i].id && item.secondary_asset_key === existsInQAnswersForTableQuestionItem['secondary_asset_key']
          );
          this.sectionForm.addControl(question.uom_questions[i].id + '_' + existsInQAnswersForTableQuestionItem['secondary_asset_key'], valueObject != null ? new FormControl(valueObject['value']) : new FormControl(''));
          if (disable) {this.sectionForm.controls[question.uom_questions[i].id + '_' + existsInQAnswersForTableQuestionItem['secondary_asset_key']].disable();}
          question.uom_questions[i].options = this.project.ProjectUOM.filter(item => item.unit_type_id == question.uom_questions[i].qa_subtype);
          this.setUOMValuesInForm(question.uom_questions[i].id, question.uom_questions[i].options, existsInQAnswersForTableQuestionItem['secondary_asset_key']);
        }
      }
    }
  }

  /**
   * Set the angular reactive form validators for the given question
   * @param question 
   * @param secondaryAssetKey 
   */
  setQuestionValidations(question, secondaryAssetKey?): void {
    if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) {
      let formControlName = question.id;
      if (secondaryAssetKey) {
        formControlName = formControlName + '_' + secondaryAssetKey;
      }
      const arrValidators = [];
      for (let j = 0; j < question.survey_q_asset_validations.length; j++) {
        if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
          arrValidators.push(Validators.required);
        }
        if (question.survey_q_asset_validations[j].validation_key == 'regex_pattern') {
          arrValidators.push(Validators.pattern(question.survey_q_asset_validations[j].validation_value));
        }
        if (question.survey_q_asset_validations[j].validation_key == 'min_value') {
          arrValidators.push(Validators.min(question.survey_q_asset_validations[j].validation_value));
        }
        if (question.survey_q_asset_validations[j].validation_key == 'max_value') {
          arrValidators.push(Validators.max(question.survey_q_asset_validations[j].validation_value));
        }
      }
      this.sectionForm.controls[formControlName].setValidators(arrValidators);
      this.sectionForm.controls[formControlName].updateValueAndValidity();
    }
  }

  /**
   * For table questions, ALL questions' answers must be entered in the answers table, even if the answer is ''
   * This is because the question asset id and secondary asset id must be present for every question in each unique column / row of the table
   * @param table 
   * @param sak 
   * @param setValueForKey 
   * @param valueToSet 
   */
  addTableQuestionsIntoQAnswers(table, sak, setValueForKey?, valueToSet?): void {
    if (table && table.questions) {
      for (let i = 0; i < table.questions.length; i++) {
        const x = this.qAnswers.find(item => item.key == table.questions[i].id && item.secondary_asset_key === sak);
        if (x) {
          // Do nothing, as the value for this question id and secondary asset key is already in qAnswers
        } else {
          // Add this question id and secondary asset key to qAnswers
          this.qAnswers.push({key: table.questions[i].id.toString(), secondary_asset_key: sak, value: setValueForKey == table.questions[i].id.toString() ? valueToSet : '', touched: true});
          // if (table.questions[i].uom_questions && table.questions[i].uom_questions.length > 0) {
          //   for (let j = 0; j < table.questions[i].uom_questions.length; j++) {
          //     this.qAnswers.push({key: table.questions[i].uom_questions[j].id.toString(), secondary_asset_key: sak, value: '', touched: true});
          //   }
          // }
        }
  
        if (table.questions[i].other_questions && table.questions[i].other_questions.length > 0) {
          const y = this.qAnswers.find(item => item.key == table.questions[i].other_questions[0].id && item.secondary_asset_key === sak);
          if (y) {
            // Do nothing, as the value for this question id and secondary asset key is already in qAnswers
          } else {
            // Add this question id and secondary asset key to qAnswers
            this.qAnswers.push({key: table.questions[i].other_questions[0].id.toString(), secondary_asset_key: sak, value: setValueForKey == table.questions[i].other_questions[0].id.toString() ? valueToSet : '', touched: true});
            if (table.questions[i].uom_questions && table.questions[i].uom_questions.length > 0) {
              for (let j = 0; j < table.questions[i].uom_questions.length; j++) {
                this.qAnswers.push({key: table.questions[i].uom_questions[j].id.toString(), secondary_asset_key: sak, value: '', touched: true});
              }
            }
          } 
        }
  
        if (table.questions[i].uom_questions && table.questions[i].uom_questions.length > 0) {
          for (let j = 0; j < table.questions[i].uom_questions.length; j++) {
            const z = this.qAnswers.find(item => item.key == table.questions[i].uom_questions[j].id && item.secondary_asset_key === sak);
            if (z) {
              // Do nothing, as the value for this question id and secondary asset key is already in qAnswers
            } else {
              // Add this question id and secondary asset key to qAnswers
              this.qAnswers.push({key: table.questions[i].uom_questions[j].id.toString(), secondary_asset_key: sak, value: setValueForKey === table.questions[i].uom_questions[j].id.toString() ? valueToSet : '', touched: true});
            } 
          }
        }
      }
    }
  }

  /**
   * Set the values in this.secondary_asset_key__options given a qAnswers array
   */
  setSecondaryAssetKeyOptionValues() {
    for (let i = 0; i < this.qAnswers.length; i++) {
      if (this.qAnswers[i].sak_of_modified != null) {
        this.secondary_asset_key__options[this.qAnswers[i].secondary_asset_key] = this.qAnswers[i].sak_of_modified;
      }
    }
  }

  /**
   * Add a row / column to the table (click on Add button)
   * @param table 
   */
  addToTable(table): void {
    const tableToModify = this.currentSection.tables.find(item => item.id == table.id);
    if (tableToModify) {
      // Initialize the this.secondary_asset_keys for this table
      if (!this.secondary_asset_keys[tableToModify.id]) {this.secondary_asset_keys[tableToModify.id] = []}
      const secondary_asset_key = uuidv4();
      // Add form controls (per question - key combination)
      for (let i = 0; i < tableToModify.questions.length; i++) {
        this.sectionForm.addControl(tableToModify.questions[i].id + '_' + secondary_asset_key, new FormControl(''));
        if (tableToModify.questions[i].disable_input) {this.sectionForm.controls[tableToModify.questions[i].id + '_' + secondary_asset_key].disable();}
        if (tableToModify.questions[i].include_uom_question) {this.setUOMQuestion(tableToModify.questions[i], tableToModify.questions[i].disable_input, {secondary_asset_key: secondary_asset_key, value: ''});}
        this.setQuestionValidations(tableToModify.questions[i], secondary_asset_key);
      }
      // Add the newly generated key into the secondary_asset_keys object
      this.secondary_asset_keys[tableToModify.id].push(secondary_asset_key);
      // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
      this.addTableQuestionsIntoQAnswers(tableToModify, secondary_asset_key);
      // Add the "other" questions if any to the table
      for (let i = 0; i < tableToModify.questions.length; i++) {
        this.setOtherQuestion(tableToModify.questions[i], secondary_asset_key);
      }
      this.modifyConnectedQuestion(tableToModify, secondary_asset_key, 'add');
      this.modifyConnectedTable(tableToModify, secondary_asset_key, 'add');
      this.setSectionFormDisplay();
      this.askUserToConfirmExit = true;
      this.totalSectionAnswered = false;
      //to disable add button
      this.tableQuestionAnswered = false;
      let obj = {
        sak : secondary_asset_key
      }
      this.newlyAddedKeys.push(obj);
      //disable navigations for crop name question
      if(table.code == '04c85617-7d54-4efd-b3b9-17db042ddb5d'){
        this.cropNameQuestionAnswered = false;
      }
      this.checkMandatoryQuestions();
    }
  }

  /**
   * When a table is modified, automatically modify its connected table (e.g. addotopma; crop details modifies the agri income table])
   * @param table 
   * @param secondary_asset_key 
   * @param action 
   */
  modifyConnectedQuestion(table, secondary_asset_key, action): void {
    if (table && table.displayFunction && table.displayFunction.indexOf("addOptionToQ") > -1) {
      const tableModifyDetails = JSON.parse(table.displayFunction);
      // Find the questions that need an option addition
      if(tableModifyDetails.addOptionToQ){
        const addOptionToQ = tableModifyDetails.addOptionToQ.split(",");
        const qToAddAsOption = tableModifyDetails.qToAddAsOption;
        if (action == 'add') {
          if (!this.dynamicOptionsObject) {this.dynamicOptionsObject = {};}
          for (let i = 0; i < addOptionToQ.length; i++) {
            if (!this.dynamicOptionsObject[addOptionToQ[i]]) {this.dynamicOptionsObject[addOptionToQ[i]] = [];}
            if (!this.dynamicOptionsObject[addOptionToQ[i]].find(item => item.sak == secondary_asset_key)) {
              this.dynamicOptionsObject[addOptionToQ[i]].push({q: qToAddAsOption, sak: secondary_asset_key, label: ''});
            }
          }
        } else if (action == 'update') {
          const qLabelToUpdate = this.qAnswers.find(item => item.key == qToAddAsOption && item.secondary_asset_key == secondary_asset_key);

          for (let i = 0; i < addOptionToQ.length; i++) {
            const p = this.dynamicOptionsObject[addOptionToQ[i]].find(item => item.sak == secondary_asset_key);
            const index = this.dynamicOptionsObject[addOptionToQ[i]].indexOf(p);
            if (this.validIndex) {
              this.dynamicOptionsObject[addOptionToQ[i]][index]['label'] = qLabelToUpdate.value;
            }
          }
        }
      }
      // console.log('this.dynamicOptionsObject', this.dynamicOptionsObject);
    }
  }

  /**
   * Remove a given row/column from a table
   * @param table 
   * @param secondary_asset_key 
   */
  removeFromTable(table, secondary_asset_key): void {
    // this is older version of tableToModify let tableToModify = this.currentSection.tables.find(item => item.id == table.id);
    const tableToModify = table;
    const dynamicOptionForSAKIsUsed = this.checkIfDynamicOptionIsUsed(table, secondary_asset_key);
    if (dynamicOptionForSAKIsUsed) {
      this.commonService.showToast('error', 'input_used_in_another_question', {});
      return;
    }
    if (tableToModify) {
      for (let i = 0; i < tableToModify.questions.length; i++) {
        // Remove the control from the sectionForm
        this.sectionForm.removeControl(tableToModify.questions[i].id + '_' + secondary_asset_key);
        // Remove the other question control from the sectionForm
        if (tableToModify.questions[i].other_questions && tableToModify.questions[i].other_questions.length > 0) {
          this.sectionForm.removeControl(tableToModify.questions[i].other_questions[0].id + '_' + secondary_asset_key);
        }
        // Remove the uom question controls from the sectionForm
        if (tableToModify.questions[i].uom_questions && tableToModify.questions[i].uom_questions.length > 0) {
          for (let j = 0; j < tableToModify.questions[i].uom_questions.length; j++) {
            this.sectionForm.removeControl(tableToModify.questions[i].uom_questions[j].id + '_' + secondary_asset_key);
          }
        }
      }
      // Remove that row / col's secondary_asset_key from the this.secondary_asset_keys
      if(this.secondary_asset_keys[tableToModify.id]){
        this.secondary_asset_keys[tableToModify.id] = this.secondary_asset_keys[tableToModify.id].filter(item => item != secondary_asset_key);
      }

      // As the entire row / column is being removed, rark the entire table row / column questions for deletion
      for (let i = 0; i < this.qAnswers.length; i++) {
        if (this.qAnswers[i].secondary_asset_key == secondary_asset_key) {
          this.markDeleted(i);
          this.markTouched(i);
        }
      }
      this.modifyConnectedTable(tableToModify, secondary_asset_key, 'remove');
      if (tableToModify != null && tableToModify.grand_total_questions != null && tableToModify.grand_total_questions.length > 0) {
        this.runGrandTotalCalculate(tableToModify.grand_total_questions[0]);
      } else if (tableToModify != null && (!tableToModify.grand_total_questions || tableToModify.grand_total_questions.length == 0)) {
        if (this.showSummaryForCurrentTab) {
          this.runTabSummaryCalculate();
        }
      }
      this.setDynamicOptionsObject();
      this.setSectionFormDisplay();
      this.checkAllQuestionAnswered();
      this.askUserToConfirmExit = true;
  
      //added for empty row issue
      this.searchKey(secondary_asset_key);
      this.checkAllAnswersTable(table);
      this.checkMandatoryQuestions();
      //enable navigations for crop name question
      if(table.code == '04c85617-7d54-4efd-b3b9-17db042ddb5d'){
        let emptyCrop = 0;
        const findSAKs = this.secondary_asset_keys[table.id];
        if(findSAKs.length == 0){
          this.cropNameQuestionAnswered = true;
        } else {
          for(const sak of findSAKs){
            for(const q of table.questions){
              if(q.code == '8e4574af-dea9-4db9-a70e-8c2fec029f6f'){
                const crop = this.qAnswers.find(item => item.key == q.id && item.secondary_asset_key == sak);
                if(crop.value == "" || crop.value == null){
                  emptyCrop++;
                }
              }              
            }
          }
        }
        if(emptyCrop == 0){
          this.cropNameQuestionAnswered = true;
        } else {          
          this.cropNameQuestionAnswered = false;
        }
      }
    }
  }

  //search key in newly added array and if found delete that object
  searchKey(key){
    const findKey = this.newlyAddedKeys.find(item => item.sak == key);
    if(findKey){
      this.tableQuestionAnswered = true;
    }
  }

  /**
   * If the table's cell value is used as a dynamic option in another question, check if an dynamic option answer with this table cell (q & secondary asset key) already exists
   * @param table 
   * @param secondary_asset_key 
   */
  checkIfDynamicOptionIsUsed(table, secondary_asset_key) {
    if (table.displayFunction && table.displayFunction.indexOf("addOptionToQ") > -1) {
      const tableModifyDetails = JSON.parse(table.displayFunction);
      // Find the questions that need an option addition
      const addOptionToQ = tableModifyDetails.addOptionToQ.split(",");

      for (let i = 0; i < addOptionToQ.length; i++) {
        const answerExists = this.qAnswers.find(item => item.key == addOptionToQ[i] && item.value.indexOf(secondary_asset_key) > -1 && !item.unchecked);
        if (answerExists) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Add 'other' question as a child asset of the given question
   * @param question 
   * @param secondaryAssetKey 
   */
  setOtherQuestion(question, secondaryAssetKey?): void {
    if (question.options && question.options.length > 0) {
      if (question.options.find(item => item.qa_subtype == 'other')) {
        if (secondaryAssetKey) {
          const existsInQAnswersForTableQuestion = this.existsInQAnswersForTableQuestion(question.other_questions[0]);
          // console.log('existsInQAnswersForTableQuestion', existsInQAnswersForTableQuestion, this.sectionForm);
          if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
            for (let i = 0; i < existsInQAnswersForTableQuestion.length; i++) {
              // console.log('existsInQAnswersForTableQuestion', existsInQAnswersForTableQuestion[i].value, existsInQAnswersForTableQuestion[i].secondary_asset_key, secondaryAssetKey);
              if (existsInQAnswersForTableQuestion[i].secondary_asset_key == secondaryAssetKey) {
                // Create a form control for each question
                this.sectionForm.addControl(question.other_questions[0].id + '_' + secondaryAssetKey, existsInQAnswersForTableQuestion[i].value ? new FormControl(existsInQAnswersForTableQuestion[i].value) : new FormControl(''));
                this.setQuestionValidations(question.other_questions[0], secondaryAssetKey);
              }
            }
          }
        } else {
          const existsInQAnswers = this.existsInQAnswers(question.other_questions[0]);
          // Create a form control for each question
          this.sectionForm.addControl(question.other_questions[0].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
          this.setQuestionValidations(question.other_questions[0]);
        }
      }
    }
  }

  /**
   * Check whether the "other" question should be disabled
   * @param question 
   * @param secondary_asset_key 
   */
  checkOtherAnswerQuestionDisplay(question, secondary_asset_key?) {
    if (question.other_questions && question.other_questions.length > 0 && question.options && question.options.length > 0) {
      const otherOption = question.options.find(item => item.qa_subtype == 'other');
      if (otherOption) {
        if (this.qAnswers.find(item => !item.unchecked && item.key == question.id && item.value == otherOption.id && item.secondary_asset_key == secondary_asset_key)) {
          return true;
        }
        return false;
      }
    }
  }

  /**
   * Modify the connected table's entry
   * @param sourceTable 
   * @param sak_of_source_table_element 
   * @param action 
   * @param sak_of_modified 
   */
  modifyConnectedTable(sourceTable, sak_of_source_table_element, action, sak_of_modified?): void {
    if (sourceTable && sourceTable.displayFunction && sourceTable.displayFunction.indexOf('modifyTable') > -1) {
      const tableModifyDetails = JSON.parse(sourceTable.displayFunction);
      if (action == 'add') {
        const assetToModify = this.getAsset(tableModifyDetails.modifyTable);
        if (assetToModify) {
          const secondary_asset_key = uuidv4();
          this.addTableQuestionsIntoQAnswers(assetToModify, secondary_asset_key);
          this.secondary_asset_key__options[sak_of_source_table_element] = secondary_asset_key;
          for (let i = 0; i < this.qAnswers.length; i++) {
            if (this.qAnswers[i].secondary_asset_key == sak_of_source_table_element) {
              this.qAnswers[i].sak_of_modified = secondary_asset_key;
            }
          }
        }
      } else if (action == 'update' && tableModifyDetails.sourceQId && tableModifyDetails.targetQId) {
        const assetToModify = this.getAsset(tableModifyDetails.modifyTable);
        if (assetToModify) {
          // Update the qAnswers
          const answerValueToSet = this.qAnswers.find(item => item.secondary_asset_key === sak_of_source_table_element && item.key == tableModifyDetails.sourceQId);
          // console.log('answerValueToSet', answerValueToSet);
          if (answerValueToSet) {
            const qAnswerToUpdate = this.qAnswers.find(item => item.secondary_asset_key === answerValueToSet.sak_of_modified);
            // console.log('qAnswerToUpdate', qAnswerToUpdate);
            if (qAnswerToUpdate) {
              const index = this.qAnswers.indexOf(qAnswerToUpdate);
              // console.log('index', index);
              if (this.validIndex(index)) {
                this.qAnswers[index].value = answerValueToSet != null ? answerValueToSet.value : '';
                this.markTouched(index);
              }
            }
            // Update the formcontrol's value if the target control is present in the sectionForm
            if (this.sectionForm.controls[tableModifyDetails.targetQId + '_' + answerValueToSet.sak_of_modified]) {
              const x = {};
              x[`${tableModifyDetails.targetQId}_${answerValueToSet.sak_of_modified}`] = answerValueToSet != null ? answerValueToSet.value : '';
              // Patch the auto calculated value in the formgroup
              this.sectionForm.patchValue(x);
            }
          }
        }
      }
      else if (action === 'remove' && tableModifyDetails.sourceQId && tableModifyDetails.targetQId) {
        const assetToModify = this.getAsset(tableModifyDetails.modifyTable);
        if (assetToModify) {
          const answerValueToSet = this.qAnswers.find(item => item.secondary_asset_key === sak_of_source_table_element && item.key === tableModifyDetails.sourceQId);
          this.removeFromTable(assetToModify, answerValueToSet.sak_of_modified);
        }
      }
      // console.log('this.qAnswers', this.qAnswers);
      // console.log('this.secondary_asset_key__options', this.secondary_asset_key__options);
    }
  }

  /**
   * Gets the table asset corresponding to assetId
   * @param assetId 
   */
  getAsset(assetId) {
    let asset;
    loop1:
    for (let i = 0; i < this.questionnaire.length; i++) {
      if (this.questionnaire[i].sections && this.questionnaire[i].sections.length > 0) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
            for (let k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
              if (assetId == this.questionnaire[i].sections[j].tables[k].id) {
                asset = this.questionnaire[i].sections[j].tables[k];
                break loop1;
              }
            }
          }
        }
      }
    }
    return asset;
  }


  /**
   * Given a child asset, get its parent asset
   * @param childAsset 
   */
  getParentAsset(childAsset) {
    let parentAsset;
    if (childAsset.qa_type == 'question' || childAsset.qa_type == 'grand_total_question') {
      loop1:
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
            parentAsset = this.questionnaire[i].sections[j];
            break loop1;
          } else {
            const parentSubsection = this.questionnaire[i].sections[j].subsections.find(item => item.code == childAsset.parent_code);
            if (parentSubsection) {
              parentAsset = parentSubsection;
              break loop1;
            }
            const parentTable = this.questionnaire[i].sections[j].tables.find(item => item.code == childAsset.parent_code);
            if (parentTable) {
              parentAsset = parentTable;
              break loop1;
            }
          }
        }
      }
    }

    if (childAsset.qa_type == 'subsection') {
      loop1:
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
            parentAsset = this.questionnaire[i].sections[j];
            break loop1;
          }
        }
      }
    }
    
    if (childAsset.qa_type == 'section') {
      for (let i = 0; i < this.questionnaire.length; i++) {
        parentAsset = this.questionnaire.find(item => item.code == childAsset.parent_code);
      }
    }

    if (childAsset.qa_type == 'option' || childAsset.qa_type == 'other_question' || childAsset.qa_type == 'uom_question') {
      loop1:
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          const parentQuestion = this.questionnaire[i].sections[j].questions.find(item => item.code == childAsset.parent_code);
          if (parentQuestion) {
            parentAsset = parentQuestion;
            break loop1;
          } else {
            if (this.questionnaire[i].sections[j].subsections && this.questionnaire[i].sections[j].subsections.length > 0) {
              for (let k = 0; k < this.questionnaire[i].sections[j].subsections.length; k++) {
                const parentQuestion = this.questionnaire[i].sections[j].subsections[k].questions.find(item => item.code == childAsset.parent_code);
                if (parentQuestion) {
                  parentAsset = parentQuestion;
                  break loop1;
                }
              }
            }

            if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
              for (let k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                const parentQuestion = this.questionnaire[i].sections[j].tables[k].questions.find(item => item.code == childAsset.parent_code);
                if (parentQuestion) {
                  parentAsset = parentQuestion;
                  break loop1;
                }
              }
            }
          }
        }
      }
    }

    if (childAsset.qa_type == 'table') {
      loop1:
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
            parentAsset = this.questionnaire[i].sections[j];
            break loop1;
          }
        }
      }
    }

    return parentAsset;
  }

  /**
   * Add the default crop entry to the table
   * @param table 
   */
  addDefaultCropEntry(table): void {
    const cropName = this.project.Product.name;
    const displayFunction = JSON.parse(table.displayFunction);
    const x = this.qAnswers.find(item => item.key == displayFunction.setQId && item.value == cropName);
    if (x) {
      // Do nothing
      this.defaultCropSak = x.secondary_asset_key;
    } else {
      this.defaultCropSak = uuidv4();
      this.addTableQuestionsIntoQAnswers(table, this.defaultCropSak, displayFunction.setQId, cropName);
    }
  }

  /**
   * Checks whether the given index is valid (not null, > -1)
   * @param index 
   */
  validIndex(index): boolean {
    if (index != null && index > -1) {
      return true;
    } return false;
  }

  /**
   * Mark the given array entry as 'touched', i.e. to be sent to the API
   * @param index 
   */
  markTouched(index): void {
    this.qAnswers[index].touched = true;
  }

  /**
   * Mark the given array entry as deleted
   * @param index 
   */
  markDeleted(index): void {
    this.qAnswers[index].deleted = true;
  }

  /**
   * Save the profiling answers data (ONLINE mode)
   * @param qAnswersToSubmit 
   * @param alsoExit 
   * @param stats 
   */
  saveProfilingDataOnline(qAnswersToSubmit, alsoExit, stats) {
    this.askUserToConfirmExit = false;
    if (this.campaignId) {
      this.dynamicQuestionnaireService.saveProfilingDataForCampaign(qAnswersToSubmit, this.rawFarmerId, this.campaignId, new Date().getTime(), stats)
      .subscribe(result => {
        this.spinner.hide();
        this.toastr.clear();
        this.commonService.showToast('success', 'changes_saved', {});
        this.updateQAnswers(result.message);
        if (alsoExit) {
          this.confirmExit();
        }
      })
    } else {
      this.dynamicQuestionnaireService.saveProfilingData(qAnswersToSubmit, this.rawFarmerId, new Date().getTime(), stats)
      .subscribe(result => {
        this.toastr.clear();
        this.spinner.hide();
        this.commonService.showToast('success', 'changes_saved', {});
        this.updateQAnswers(result.message);
        if (alsoExit) {
          this.confirmExit();
        }
      })
    }
  }

  /**
   * Save the profiling answers data to indexed DB (OFFLINE mode)
   * 
   * @param qAnswersToSubmit 
   * @param alsoExit 
   * @param answerStats 
   */
  async saveProfilingDataOffline(qAnswersToSubmit, alsoExit, answerStats) {
    if (qAnswersToSubmit && qAnswersToSubmit.length > 0) {
      const farmertmpobj = await idbApp.getAllProjects('dq_profiling');
      if (farmertmpobj && farmertmpobj.length > 0) {
        const qAnswersForThisFarmerId = farmertmpobj.filter((item) => {
         return  (String(item.tmp_id) === String(this.rawFarmerId) && String(item.campaignId) === String(this.campaignId));
        });

        if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
          for (let i = 0; i < qAnswersForThisFarmerId.length; i++) {
            const values = JSON.parse(qAnswersForThisFarmerId[i].values);
            let newAnswersArray = [];
            for (let j = 0; j < qAnswersToSubmit.length; j++) {
              let a = qAnswersToSubmit[j];
              if(values.length == 1 && values[0].value == '' && values[0].flex2 == 'A'){
                a['flex2'] = 'A';
                newAnswersArray.push(a);
              } 
              else if (a.secondary_asset_key) {
                if (a.value != null && !a.deleted && !a.unchecked) {
                  if (a.checked) {
                    newAnswersArray.push(a);  
                  } else {
                    let x;
                    if(a.id){
                      x = values.find(item => item.id == a.id && item.secondary_asset_key === a.secondary_asset_key);
                    } else {
                      x = values.find(item => item.key == a.key && item.secondary_asset_key === a.secondary_asset_key);
                    }                    
                    if (x) {
                      const xIndex = values.indexOf(x);
                      const y = values[xIndex];
                      y['value'] = a.value;
                      y['flex3'] = a.flex3;
                      if(a.imgBase64){
                        y['imgBase64'] = a.imgBase64;  
                      }
                      newAnswersArray.push(y);
                    } else {
                      newAnswersArray.push(a);
                    }
                  }
                } else if (a.value != null && a.id && a.unchecked) {
                  newAnswersArray.push(a);
                } else if (a.value === "" && a.touched && a.flex2 === "A" && !a.flex3){
                  newAnswersArray.push(a);
                } else if(a.value !== null && a.id && a.deleted && a.flex3){
                  newAnswersArray.push(a);
                }
              } else {
                // Non-checkbox
                if (a.value != null && !a.deleted && !a.unchecked) {
                  if (a.checked) {
                    newAnswersArray.push(a);
                  } else {
                    let x;
                    //check if answer is already having id, means answers is either suggested or saved
                    if(a.id){
                      x = values.find(item => item.id == a.id);
                    } else {
                      x = values.find(item => item.key == a.key);
                    }
                    if (x) {
                      const xIndex = values.indexOf(x);
                      const y = values[xIndex];
                      y['value'] = a.value;
                      y['flex3'] = a.flex3;
                      y['deleted'] = a.deleted;
                      newAnswersArray.push(y);
                    }  else {
                      a["flex2"] = "A";
                      newAnswersArray.push(a);
                    }
                  }
                } else if (a.value != null && a.id && a.unchecked) {
                  newAnswersArray.push(a);
                } else if (a.value === "" && a.flex3) {
                  newAnswersArray.push(a);
                } else if (a.value === "" && a.touched && typeof a.flex3 === "undefined"){
                  a["flex2"] = "A";
                  newAnswersArray.push(a);
                }
              }
            }
            // Remove the old entry for this unique_id and add a new entry in indexedDB with a new unique_id
            idbApp.removeDataFromStore('dq_profiling', qAnswersForThisFarmerId[i].unique_id);
            if (newAnswersArray && newAnswersArray.length > 0) {
              const uniqueId = uuidv4();
              const x = {unique_id: uniqueId, tmp_id: this.rawFarmerId, 
                updatedAt: new Date().getTime(), values: JSON.stringify(newAnswersArray), 
                stats: JSON.stringify(answerStats), campaignId: this.campaignId};
              idbApp.addStoreInfo([x], 'dq_profiling');
              this.askUserToConfirmExit = false;
            }
          }
        } else {
          this.addNewEntrytoNQIDB(qAnswersToSubmit, answerStats);
        }
      } else {
        this.addNewEntrytoNQIDB(qAnswersToSubmit, answerStats);
      }
    }
    if (alsoExit) {
      this.confirmExit();
    }
    this.spinner.hide();
  }

  /**
   * Add offline profiling answers to indexedDB (when answers do not already exist in indexedDB for this tmp_id)
   * @param qAnswersToSubmit 
   * @param stats 
   */
  addNewEntrytoNQIDB(qAnswersToSubmit, stats): void {
    const y1 = qAnswersToSubmit.filter(a => (a.checked || a.unchecked) && a.flex3 != 'NA');
    const y2 = qAnswersToSubmit.filter(a => (!a.secondary_asset_key && !a.checked && !a.unchecked && a.value != null && a.value != '' && !a.deleted) 
    || (a.secondary_asset_key && !a.checked && !a.unchecked && a.value != null && a.value != '' && !a.deleted));
    const na = qAnswersToSubmit.filter(a => a.value == '' && a.flex3 == 'NA' && !a.deleted);
    //added by KRPT for fixing offline delete answer issue
    //when user was removing numerical question answer or dropdown reset to select in offline mode, 
    // it was not getting saved and hence after syncing answer remained as it is.
    const deleted = qAnswersToSubmit.filter(a => (a.value == '' || a.value == null || a.secondary_asset_key) && a.deleted == true);
    let y11 = [];
    let y21 = [];
    if (y1 && y1.length > 0) y11 = y1;
    if (y2 && y2.length > 0) y21 = y2;
    let y = y11.concat(y21);
    y = y.concat(na);
    y = y.concat(deleted);
    if (y && y.length > 0) {
      const uniqueId = uuidv4();
      const x = {unique_id: uniqueId, tmp_id: this.rawFarmerId, 
        updatedAt: new Date().getTime(), values: JSON.stringify(y), 
        stats: JSON.stringify(stats), campaignId: this.campaignId};
      idbApp.addStoreInfo([x], 'dq_profiling');
      this.askUserToConfirmExit = false;
    }
  }

  /**
   * Not used. This can be deleted
   * @param uniqueId 
   */
  async postOfflineIDBDataToAPI(uniqueId) {
    const farmertmpobj = await idbApp.getAllProjects('dq_profiling');
    if (farmertmpobj && farmertmpobj.length > 0) {
      const uniqueIdData = farmertmpobj.filter(item => item.unique_id == uniqueId);
      // console.log('uniqueIdData', uniqueIdData);
      this.dynamicQuestionnaireService.saveProfilingDataOffline(uniqueIdData[0]).subscribe(result => {});
    }
  }

  /**
   * Mark all entries of qAnswers as touched so they are considered to be synced with DB
   * @param valueToSet 
   */
  updateAllQAnswersTouchedValue(valueToSet): void {
    if (this.qAnswers && this.qAnswers.length > 0) {
      for (let i = 0; i < this.qAnswers.length; i++) {
        this.qAnswers[i].touched = valueToSet;
      }
    }
  }

  registerSyncEvent() {
    serviceWorkerVar.sync.register('nqDataSync');
  }

  /**
   * Get all displayed assets (considering applied display logic and other answers)
   */
  getAllDisplayedQuestions() {
    const displayedQuestions = [];
    let otherquestions = [];
    let tableQns = [];

    for (let i = 0; i < this.questionnaire.length; i++) {
      for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
        const section = this.questionnaire[i].sections[j];
        
        for (let k = 0; k < section.questions.length; k++) {
          if (this.checkAssetDisplay(section.questions[k])) {
            displayedQuestions.push(section.questions[k]);
            if (section.questions[k].uom_questions && section.questions[k].uom_questions.length > 0) {
              for (let p = 0; p < section.questions[k].uom_questions.length; p++) {
                displayedQuestions.push(section.questions[k].uom_questions[p]);
              }
            }
          }
        }
        for (let k = 0; k < section.subsections.length; k++) {
          if (this.checkAssetDisplay(section.subsections[k])) {
            for (let m = 0; m < section.subsections[k].questions.length; m++) {
              if(this.checkAssetDisplay(section.subsections[k].questions[m])) {
                displayedQuestions.push(section.subsections[k].questions[m]);

                if (section.subsections[k].questions[m].uom_questions && section.subsections[k].questions[m].uom_questions.length > 0) {
                  for (let p = 0; p < section.subsections[k].questions[m].uom_questions.length; p++) {
                    displayedQuestions.push(section.subsections[k].questions[m].uom_questions[p]);
                  }
                }
                if (section.subsections[k].questions[m].other_questions && section.subsections[k].questions[m].other_questions.length > 0) {
                  if(this.checkOtherAnswerQuestionDisplay(section.subsections[k].questions[m])) {
                    displayedQuestions.push(section.subsections[k].questions[m].other_questions[0])
                  }
                }
              }
            }
          }
        }

        for (let k = 0; k < section.tables.length; k++) {
          // Added by SBJY
          let isDisplayed = this.checkAssetDisplay(section.tables[k]);
          
          if (isDisplayed) {
            for (let i = 0; i < section.tables[k].questions.length; i++) {

              let sskeys = this.existsInQAnswersForTableQuestion(section.tables[k].questions[i]);
              sskeys = sskeys.reduce((accumulator, current) => {
                if (!accumulator.some(item => item.key === current.key && item.secondary_asset_key === current.secondary_asset_key)) {
                  accumulator.push(current);
                }
                return accumulator;
              }, []);

              for (let j = 0; j < sskeys.length; j++) {
                if (sskeys[j].flex3) {
                  let displayed = this.checkAssetDisplay(section.tables[k].questions[i], sskeys[j].secondary_asset_key);
                  if (displayed) {
                    displayedQuestions.push(section.tables[k].questions[i]);
                    tableQns.push(section.tables[k].questions[i]);
                  }
                }
              }

              // Push 'other' questions in the multiselect option into different array. This is used later to remove other answers to count no. answers
              if (section.tables[k].questions[i].other_questions.length > 0) {
                for (let m = 0; m < section.tables[k].questions[i].other_questions.length; m++) {
                  otherquestions.push(section.tables[k].questions[i].other_questions[m]);
                }
              }
            }
          }

            // Commented by SBJY
            // To remove grand total question from total questions 
            // if (section.tables[k].grand_total_questions && section.tables[k].grand_total_questions.length > 0) {
            //   displayedQuestions.push(section.tables[k].grand_total_questions[0]);
            // }
            
          // }
          // TODO: Add logic for questions within the table
          // for (let i = 0; i < section.tables[k].questions.length; i++) {
          //   if (this.secondary_asset_keys[section.tables[k].id] && this.secondary_asset_keys[section.tables[k].id].length > 0) {
          //     for (let j = 0; j < this.secondary_asset_keys[section.tables[k].id].length; j++) {
          //       console.log('this.secondary_asset_keys[section.tables[k].id][j]', this.secondary_asset_keys[section.tables[k].id][j]);
          //       section.tables[k].questions[i][this.secondary_asset_keys[section.tables[k].id][j] + '_isDisplayed'] = this.checkAssetDisplay(section.tables[k].questions[i], this.secondary_asset_keys[section.tables[k].id][j]);
          //     }
          //   }
          // }
        }
      }
    }
    return {displayedQuestions, otherquestions, tableQns};
  }

;

  /**
   * Calculate the answer stats for this farmer
   */
   getAnswerStatsLocal() {

    let displayedQuestionsObj = this.getAllDisplayedQuestions()
    let answerExistsForTableNonBlankNew = [];
    let answerExistsForTableNonBlankNewV2 = [];
    let uomQnIds = [];

    const answerExistsForQuestionsNonTable = displayedQuestionsObj.displayedQuestions.filter(item => this.qAnswers.find(each => each.key == item.id && !each.deleted && !each.secondary_asset_key && each.flex3));

    const answerExistsForTable = this.qAnswers.filter(item => (item.secondary_asset_key && item.flex3 && !item.deleted));

    let answerExistsForTableNonBlank = answerExistsForTable.filter(item => item.value != '');
    // Get unique objects based on key and seconday asset key
    answerExistsForTableNonBlank = answerExistsForTableNonBlank.reduce((accumulator, current) => {
      if (!accumulator.some(item => item.key === current.key && item.secondary_asset_key === current.secondary_asset_key)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

     // Remove other answers from answers array with the help of displayedQuestionsObj.otherquestions array
     for (let i = 0; i < answerExistsForTableNonBlank.length; i++) {
       let findIndex = displayedQuestionsObj.otherquestions.findIndex((item) => parseInt(item.id) === parseInt(answerExistsForTableNonBlank[i].key));
       if (findIndex === -1) {
         answerExistsForTableNonBlankNew.push(answerExistsForTableNonBlank[i]);
       }
     }    


     //---- Remove metric answers from table answers array
     for (let i = 0; i < displayedQuestionsObj.tableQns.length; i++) {
       for (let j = 0; j < displayedQuestionsObj.tableQns[i].uom_questions.length; j++) {
         uomQnIds.push(displayedQuestionsObj.tableQns[i].uom_questions[j].id);
       }
     }
     uomQnIds = Array.from(new Set(uomQnIds));
     for (let i = 0; i < answerExistsForTableNonBlankNew.length; i++) {
       let findIndex = uomQnIds.findIndex((item) => parseInt(item) === parseInt(answerExistsForTableNonBlankNew[i].key));
       if (findIndex === -1) {
         answerExistsForTableNonBlankNewV2.push(answerExistsForTableNonBlankNew[i]);
       }
     }    

    //-------

    // console.log("uomQnIds:", uomQnIds);
    // console.log("displayedQuestions:", displayedQuestionsObj);
    // console.log("answerExistsForQuestionsNonTable:", answerExistsForQuestionsNonTable);
    // console.log("displayedQuestionsObj.otherquestions:", displayedQuestionsObj.otherquestions);
    // console.log("total qns: ", displayedQuestionsObj.displayedQuestions.length);
    // console.log("total ans: ", answerExistsForQuestionsNonTable.length + answerExistsForTableNonBlankNewV2.length);
    // console.log("answerExistsForQuestionsNonTableV2:", answerExistsForQuestionsNonTableV2);
    // console.log("answerExistsForTableNonBlankNewV2:", answerExistsForTableNonBlankNewV2);
    

    // ----------
    // The below code is only for debugging purpose.
    // let arr = [];

    //  displayedQuestionsObj.displayedQuestions.forEach((item1) => {
    //    let findIndex = answerExistsForQuestionsNonTable.findIndex((item) => item.id == item1.id);
    //    if (findIndex == -1) {
    //      let findIndex2 = answerExistsForTableNonBlankNewV2.findIndex((item) => item.key == item1.id);
    //      if (findIndex2 === -1) {
    //        console.log("Missed id: ", item1);
    //        arr.push(item1.id);
    //      }
    //    }
    // }) 
    // console.log("arr:", arr.join(","));
    // ---------- 

    return [displayedQuestionsObj.displayedQuestions[0].survey_id, displayedQuestionsObj.displayedQuestions.length, answerExistsForQuestionsNonTable.length + answerExistsForTableNonBlankNewV2.length];
  }

  /** Not used. This can be deleted */
  async removeKeyFromIDBSavedNQData(key) {
    const indexedDBEntries = [];
    // Find this rawFarmerId's indexedDB entries if any
    const farmertmpobj = await idbApp.getAllProjects('dq_profiling');
    // console.log('farmertmpobj', farmertmpobj);
    if (farmertmpobj && farmertmpobj.length > 0) {
      const qAnswersForThisFarmerId = farmertmpobj.filter(item => item.tmp_id === this.rawFarmerId);
      // console.log('qAnswersForThisFarmerId', qAnswersForThisFarmerId);
      if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
        for (let i = 0; i < qAnswersForThisFarmerId.length; i++) {
          let values = JSON.parse(qAnswersForThisFarmerId[i].values);
          values = values.filter(item => item.key != key);

          if (values.length > 0) {
            // Update the values string in the idb object
            
          } else {
            // Delete the idb object
            idbApp.removeDataFromStore('dq_profiling', qAnswersForThisFarmerId[i].unique_id);
          }
      }
    }
  }
}

/**
 * Toggle display of the wizard - quick notes
 */
wizardToggle() {
  this.wizardShow = !this.wizardShow;
}

/**
 * Save quick notes (ONLINE / OFFLINE mode)
 */
saveNotes() {
  this.wizardToggle();

  if (this.notes.id > 0) {
    this.notes.upd_user_id = this.currentUser.id;
    this.notes.upd_date = new Date();
  } else {
    this.notes.cr_user_id = this.currentUser.id;
  }

  this.notes.farmer_id = this.farmerId;

  if (navigator.onLine) {
    this.farmerService.SaveNotes(this.notes).subscribe(
      (res) => {
        if (res.msg === 'success') {

          if (!this.notes.id) {
            res.data.cr_date = new Date();
          }

          this.notes = res.data;

          // this.toastrService.success('');
        }
      }
    );
  } else {
    // console.log('this.notes', this.notes);
    const finalObj = {};
    const farmerObj = this.notes;
    Object.keys(farmerObj).forEach(function (key) {
      finalObj[key] = farmerObj[key];
    });
    const farmObj = new Array();
    const index = this.rawFarmerId.indexOf('_');
    const rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
    finalObj['tmp_id'] = this.farmerId > 0 ? `${this.farmerId}_QN` : `${rawFarmerIdEdited}_QN`;
    finalObj['savetab'] = 'QN';
    finalObj['randnum'] = Math.random().toString().slice(2, 12);
    finalObj['project_id'] = this.projectId;
    finalObj['upd_user_id'] = this.currentUser.id;
    farmObj[farmObj.length] = finalObj;
    idbApp.addStoreInfo(farmObj, 'farmerstmp');
  }
}

/** Not used. this can be deleted */
selectRevision(): void {
  if (this.selectedRevision != '') {
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { revisionId: this.selectedRevision },
        queryParamsHandling: 'merge'
      });
  } else {
    // console.log('1562');
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: {revisionId: ''},
        queryParamsHandling: 'merge'
      });
  }
}

/**
 * Given a qAnswer, if it has a uom question, convert the value into
 * project default unit and return (for grand total calculation)
 * @param qAnswer 
 */
getUOMConvertedValue(qAnswer) {
  const asset = this.getAssetNew(qAnswer.key);
  let valueToReturn;
  if (asset && asset.uom_questions && asset.uom_questions.length > 0) {
    const uomQuestion = asset.uom_questions[0];
    const uomAnswer = this.qAnswers.find(item => item.key == uomQuestion.id && item.secondary_asset_key === qAnswer.secondary_asset_key);
    if (!uomAnswer.value || uomAnswer.value == '') {
      return 0;
    } else {
      const conversionFactor = this.processedUOMs[uomQuestion['qa_subtype']]['units'].find(item => item.id == uomAnswer.value)['conversionFactorToProjectDefault'];
      valueToReturn = qAnswer.value * conversionFactor; 
    }
  } else {
    return qAnswer.value;
  }
  return valueToReturn;
}

/**
 * Run the grand total calculation for the given grand total question
 * @param grandTotalQuestion 
 */
runGrandTotalCalculate(grandTotalQuestion): void {
  const displayFunction = grandTotalQuestion.displayFunction;
  if (displayFunction && this.qAnswers && this.qAnswers.length > 0) {
    const displayFnJSON = JSON.parse(displayFunction);
    let grandTotal = 0;
    let numberOfDecimals = 0;
    for (let i = 0; i < this.qAnswers.length; i++) {
      if (this.qAnswers[i].key == displayFnJSON['grandTotalOf'] && !this.qAnswers[i].deleted && this.qAnswers[i].value && this.qAnswers[i].value != '') {
        // console.log('answer object', this.qAnswers[i]);
        // grandTotal = grandTotal + parseInt(this.qAnswers[i].value);
        const convertedValue = this.getUOMConvertedValue(this.qAnswers[i]);
        const p = this.getOutputDecimals([this.qAnswers[i]['value']]);
        if (p > numberOfDecimals) {
          numberOfDecimals = p;
        }
        if (convertedValue) {
          grandTotal = grandTotal + parseFloat(convertedValue);
        }
      }
    }
    const grandTotalValueToSet = grandTotal.toFixed(2);
    const x = this.qAnswers.find(item => item.key == grandTotalQuestion.id);
    if (x) {
      const xIndex = this.qAnswers.indexOf(x);
      if (this.validIndex(xIndex)) {
        this.qAnswers[xIndex].value = grandTotalValueToSet;
        this.qAnswers[xIndex].touched = true;
        this.qAnswers[xIndex].flex3 = 'A';
      }
    } else {
      this.qAnswers.push({
        key: grandTotalQuestion.id,
        value: grandTotalValueToSet,
        touched: true,
        flex3:'A'
      });
    }
    const y = {};
    y[grandTotalQuestion.id] = grandTotalValueToSet;
    // Patch the auto calculated value in the formgroup
    this.sectionForm.patchValue(y);

    if (grandTotalQuestion.modifies_qas) {
      this.runAutoCalculate(grandTotalQuestion, null, true);
    }
    if (this.showSummaryForCurrentTab) {
      this.runTabSummaryCalculate();
    }
  }
}

/**
 * Check that the given formcontrol has any errors or not
 * @param question 
 * @param secondaryAssetKey 
 */
questionHasNoErrors(question, secondaryAssetKey?) {
  if (secondaryAssetKey) {
    if (this.sectionForm.controls[question.id + '_' + secondaryAssetKey] && this.sectionForm.controls[question.id + '_' + secondaryAssetKey].errors) {
      return false;
    } else {
      return true;
    }
  } else {
    if (this.sectionForm.controls[question.id] && this.sectionForm.controls[question.id].errors) {
      return false;
    } else {
      return true;
    }
  }
}

/**
 * Create the set of dynamic options for the two questions
 * crop intercropping with surveyed crop
 * crop intercropping with surveyed crop (field level)
 */
setDynamicOptionsObject(): void {
  if (this.questionnaire && this.questionnaire !== 'noSurveys' && this.questionnaire.length > 0) {
    this.dynamicOptionsObject = {};
    for (let i = 0; i < this.questionnaire.length; i++) {
      for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
        if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
          for (let k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
            if (this.questionnaire[i].sections[j].tables[k].displayFunction && this.questionnaire[i].sections[j].tables[k].displayFunction.indexOf("addOptionToQ") > -1) {
              const tableModifyDetails = JSON.parse(this.questionnaire[i].sections[j].tables[k].displayFunction);
              if (tableModifyDetails && tableModifyDetails.addOptionToQ) {
                const addOptionToQ = tableModifyDetails.addOptionToQ.split(",");
                const qToAddAsOption = tableModifyDetails.qToAddAsOption;
                const existingAnswers = this.qAnswers.filter(item => item.key == qToAddAsOption);
                if (existingAnswers && existingAnswers.length > 0) {
                  for (let p = 0; p < addOptionToQ.length; p++) {
                    for (let q = 0; q < existingAnswers.length; q++) {
                      if (!existingAnswers[q].deleted) {
                        if (!this.dynamicOptionsObject[addOptionToQ[p]]) {this.dynamicOptionsObject[addOptionToQ[p]] = [];}
                        if (!this.dynamicOptionsObject[addOptionToQ[p]].find(item => item.sak == existingAnswers[q].secondary_asset_key)) {
                          this.dynamicOptionsObject[addOptionToQ[p]].push({q: qToAddAsOption, sak: existingAnswers[q].secondary_asset_key, label: existingAnswers[q].value});
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/**
 * Given array of formula elements, calculate the formula and return value
 * e.g. ["40","*","3"] would return 120
 * @param fnArray 
 */
calculateFormula(fnArray) {
  if (fnArray && fnArray.length > 0) {
    const numberOfDecimalsOfOutput = this.getOutputDecimals(fnArray);
    let answer;
    for (let i = 0; i < fnArray.length; i++) {
      if (!fnArray[i]) {fnArray[i] = 0;}
    }
    const fn = fnArray.join("")
    answer = new Function(`return ${fn}`)();
    if (answer) {
      answer = answer.toFixed(numberOfDecimalsOfOutput);
    }
    return answer;
  }
}

/**
 * Navigate to the project dashboard page for a given project
 * @param projectNameVal 
 * @param projectId 
 */
getBackProjectFilter(projectNameVal, projectId) {
  const url = "/dashboard";
  this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
}

/**
 * Set the farmer's quick notes attribute (from API / indexed DB)
 */
async setFarmerQuickNotes() {
  let rawFarmerIdQuickNotes;
  const x = this.rawFarmerId.substring(0,2);
  if (x == 'FA') {
    // the farmer was registered offline
    const whichTab = this.rawFarmerId.substring(this.rawFarmerId.length - 2);
    if (whichTab == '00') {
      rawFarmerIdQuickNotes = `${this.rawFarmerId.slice(0, -5)}QN`;
    } else {
      rawFarmerIdQuickNotes = `${this.rawFarmerId.slice(0, -4)}QN`;
    }
  } else {
    // // farmer was registered online
    // const p = 10 - this.rawFarmerId.length;
    // let m = '';
    // for (let i = 0; i < p; i++) {
    //   m = `${m}0`;
    // }
    rawFarmerIdQuickNotes = `${this.rawFarmerId}_QN`;
    // console.log('rawFarmerIdQuickNotes', rawFarmerIdQuickNotes);
  }
  // Find if the farmer has quick notes in it
  const quickNotesFromDB = this.farmer.FarmerQuickNotes;

  let quickNotesFromIndexedDB;
  if (!navigator.onLine) {
    const farmertmpobj = await idbApp.getAllProjects('farmerstmp');

    quickNotesFromIndexedDB = farmertmpobj.filter(x => x.tmp_id === rawFarmerIdQuickNotes);
  }

  if (!this.farmer.FarmerQuickNotes) {
    this.farmer.FarmerQuickNotes = [];
  }
  
  if (quickNotesFromDB) {
    this.farmer.FarmerQuickNotes = quickNotesFromDB;
  }

  if (quickNotesFromIndexedDB && quickNotesFromIndexedDB.length > 0) {
    this.farmer.FarmerQuickNotes = quickNotesFromIndexedDB;
  }

  if (this.farmer.FarmerQuickNotes && this.farmer.FarmerQuickNotes.length > 0) {
    this.notes = this.farmer.FarmerQuickNotes[0];
  }
}

/**
 * partial-sync event triggers this function call. It indicates that the offline-entered data has been synced with the DB
 * and that the local state should be refreshed by fetching the latest DB data and caching it
 * @param args 
 */
async syncDone(args) {
  if (navigator.onLine) {
    window.dispatchEvent(new Event('caching-now'));
    if (this.farmerId != 0) {
      await Promise.all([this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise()]);
    } else {
      await Promise.all([
        // this.dynamicQuestionnaireService.getQAnswersForProject(this.projectId, null).toPromise(),
        this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise()
      ]);

      await Promise.all([
        this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise()
      ])
      .then(result => {
        if (result[0]['code'] === "success") {
          for (let i = 0; i < result[0]['message'].length; i++) {
            this.loadFarmerDataIntoCache(this.projectId, result[0]['message'][i], result[0]['message'][i+1]);
          }
        }
      }).catch(e => {});
    }
    window.dispatchEvent(new Event('data-synced'));
  }
}


//Missing functions added by Kiran
leftScroll() {
  document.querySelector('ul.sub-tab-fixed').scrollLeft -= 500;
}

rightScroll() {
  document.querySelector('ul.sub-tab-fixed').scrollLeft += 500;
}

/**
 * Collect all the display_if_key values. This is used to unset dependent questions if the parent question value is removed
 */
collectDisplayLogicKeyValues(): void {
  this.displayLogicKeyValues = [];
  if (this.questionnaire && this.questionnaire.length > 0) {
    for (let i = 0; i < this.questionnaire.length; i++) {
      if (this.questionnaire[i].sections && this.questionnaire[i].sections.length > 0) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          for (let k = 0; k < this.questionnaire[i].sections[j].questions.length; k++) {
            if (this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic.length > 0) {
              for (let m = 0; m < this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic.length; m++) {
                const x = {
                  asset: this.questionnaire[i].sections[j].questions[k], key: this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_key.toString()
                };
                if (this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_value != null) {
                  x['value'] = this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_value.toString();
                }
                this.displayLogicKeyValues.push(x);
              }
            }
          }
          for (let p = 0; p < this.questionnaire[i].sections[j].subsections.length; p++) {
            if (this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic && this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic.length > 0) {
              for (let m = 0; m < this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic.length; m++) {
                const x = {
                  asset: this.questionnaire[i].sections[j].subsections[p], key: this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic[m].display_if_key.toString()
                };
                if (this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic[m].display_if_value != null) {
                  x['value'] = this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic[m].display_if_value.toString();
                }
                this.displayLogicKeyValues.push(x);
              }
            }
            for (let k = 0; k < this.questionnaire[i].sections[j].subsections[p].questions.length; k++) {
              if (this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic.length > 0) {
                for (let m = 0; m < this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic.length; m++) {
                  const x = {
                    asset: this.questionnaire[i].sections[j].subsections[p].questions[k], key: this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_key.toString()
                  };
                  if (this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_value != null) {
                    x['value'] = this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_value.toString();
                  }
                  this.displayLogicKeyValues.push(x);
                }
              }
            } 
          }
          for (let p = 0; p < this.questionnaire[i].sections[j].tables.length; p++) {
            if (this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic && this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic.length > 0) {
              for (let m = 0; m < this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic.length; m++) {
                const x = {
                  asset: this.questionnaire[i].sections[j].tables[p], key: this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic[m].display_if_key.toString()
                };
                if (this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic[m].display_if_value != null) {
                  x['value'] = this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic[m].display_if_value.toString();
                }
                this.displayLogicKeyValues.push(x);
              }
            }
            for (let k = 0; k < this.questionnaire[i].sections[j].tables[p].questions.length; k++) {
              if (this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic.length > 0) {
                for (let m = 0; m < this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic.length; m++) {
                  const x = {
                    asset: this.questionnaire[i].sections[j].tables[p].questions[k], key: this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_key.toString()
                  };
                  if (this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_value != null) {
                    x['value'] = this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_value.toString();
                  }
                  this.displayLogicKeyValues.push(x);
                }
              }
            }
          }
        }
      }
    }
  }
}

/**
 * If the parent question's value is unset / modified, then unset the dependent child question's value automatically
 * @param question - parent question whose value has been modified
 * @param secondaryAssetKey - optional
 */
unsetDependentQuestions(question, secondaryAssetKey?) : void {
  // Find if this question is a display logic key for another asset
  const assetsWithThisDisplayIfKey = this.displayLogicKeyValues.filter(item => item.key == question.id);
  if (assetsWithThisDisplayIfKey && assetsWithThisDisplayIfKey.length > 0) {
    for (let i = 0; i < assetsWithThisDisplayIfKey.length; i++) {
      if (!this.checkAssetDisplay(assetsWithThisDisplayIfKey[i].asset, secondaryAssetKey)) {
        if (assetsWithThisDisplayIfKey[i].asset.qa_type == 'question') {
          this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset, secondaryAssetKey);
          if (assetsWithThisDisplayIfKey[i].asset.uom_questions && assetsWithThisDisplayIfKey[i].asset.uom_questions.length > 0) {
            for (let k = 0; k < assetsWithThisDisplayIfKey[i].asset.uom_questions.length; k++) {
              this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.uom_questions[k], secondaryAssetKey);
            }
          }
          if (assetsWithThisDisplayIfKey[i].asset.other_questions && assetsWithThisDisplayIfKey[i].asset.other_questions.length > 0) {
            for (let k = 0; k < assetsWithThisDisplayIfKey[i].asset.other_questions.length; k++) {
              this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.other_questions[k], secondaryAssetKey);
            }
          }
        }
        if (assetsWithThisDisplayIfKey[i].asset.qa_type == 'subsection' || assetsWithThisDisplayIfKey[i].asset.qa_type == 'table') {
          if (assetsWithThisDisplayIfKey[i].asset.questions && assetsWithThisDisplayIfKey[i].asset.questions.length > 0) {
            for (let j = 0; j < assetsWithThisDisplayIfKey[i].asset.questions.length; j++) {
              this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.questions[j], secondaryAssetKey , secondaryAssetKey ? false : true);
              if (assetsWithThisDisplayIfKey[i].asset.questions[j].uom_questions && assetsWithThisDisplayIfKey[i].asset.questions[j].uom_questions.length > 0) {
                for (let k = 0; k < assetsWithThisDisplayIfKey[i].asset.questions[j].uom_questions.length; k++) {
                  this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.questions[j].uom_questions[k], secondaryAssetKey, secondaryAssetKey ? false : true);
                }
              }

              if (assetsWithThisDisplayIfKey[i].asset.questions[j].other_questions && assetsWithThisDisplayIfKey[i].asset.questions[j].other_questions.length > 0) {
                for (let k = 0; k < assetsWithThisDisplayIfKey[i].asset.questions[j].other_questions.length; k++) {
                  this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.questions[j].other_questions[k], secondaryAssetKey, secondaryAssetKey ? false : true);
                }
              }
            }
          }
          if (assetsWithThisDisplayIfKey[i].asset.grand_total_questions && assetsWithThisDisplayIfKey[i].asset.grand_total_questions.length > 0) {
            for (let j = 0; j < assetsWithThisDisplayIfKey[i].asset.grand_total_questions.length; j++) {
              this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.grand_total_questions[j], secondaryAssetKey, secondaryAssetKey ? false : true);
            }
          }
        }
      } else {
        // console.log('2350');
      }
    }
  }
}

/**
 * 
 * @param question - question whose value to be unset
 * @param secondaryAssetKey 
 * @param unsetAllInTable 
 */
unsetQuestionValue(question, secondaryAssetKey?, unsetAllInTable?) {
  let answersExistsInQAnswers;
  if (!unsetAllInTable) {
    answersExistsInQAnswers = this.qAnswers.filter(item => item.key == question.id && item.secondary_asset_key == secondaryAssetKey);
  } else {
    answersExistsInQAnswers = this.qAnswers.filter(item => item.key == question.id);
  }
  if (answersExistsInQAnswers && answersExistsInQAnswers.length > 0) {
    if (question.qa_subtype === 'multiselect') {
      for (let j = 0; j < answersExistsInQAnswers.length; j++) {
        const args = {target: {checked: false}};
        if (!answersExistsInQAnswers[j].secondary_asset_key) {
          this.sectionFormChangeHandlerCheckbox(args, {id:answersExistsInQAnswers[j].value}, question);
          if (this.checkedObject[question.id + '_' + answersExistsInQAnswers[j].value]) {delete this.checkedObject[question.id + '_' + answersExistsInQAnswers[j].value];}
        } else {
          if (question.has_dynamic_options) {
            const o1 = this.dynamicOptionsObject[question.id];
            if (o1) {
              const o2 = answersExistsInQAnswers[j].value.split('__');
              const o3 = o1.find(item => item.q == o2[0] && item.sak == o2[1]);
              if (o3) {
                this.sectionFormChangeHandlerForTableCheckboxDynamicOptions(args, this.getParentAsset(question), question, o3, answersExistsInQAnswers[j].secondary_asset_key, true);   
              }
            }
          } else {
            this.sectionFormChangeHandlerForTableCheckbox(args, this.getParentAsset(question), question, {id:answersExistsInQAnswers[j].value}, answersExistsInQAnswers[j].secondary_asset_key);
          }
          if (this.checkedObjectSecondaryKeys[question.id + '_' + answersExistsInQAnswers[j].secondary_asset_key + '_' + answersExistsInQAnswers[j]['value']]) {
            delete this.checkedObjectSecondaryKeys[question.id + '_' + answersExistsInQAnswers[j].secondary_asset_key + '_' + answersExistsInQAnswers[j]['value']];
          }
        }
      }
    } else {
      for (let j = 0; j < answersExistsInQAnswers.length; j++) {
        if (question.qa_type === 'uom_question') {
          // this.setUOMValuesInForm(question.id, question.options, secondaryAssetKey);
          this.patchSectionForm(question.id, answersExistsInQAnswers[j].secondary_asset_key, null);
        } else if (question.qa_subtype === 'select') {
          this.patchSectionForm(question.id, answersExistsInQAnswers[j].secondary_asset_key, "");
        } else {
          this.patchSectionForm(question.id, answersExistsInQAnswers[j].secondary_asset_key, null);
        }
        if (!answersExistsInQAnswers[j].secondary_asset_key) {
          this.sectionFormChangeHandler(null, question);
        } else {
          this.sectionFormChangeHandlerForTable(null, this.getParentAsset(question), question, answersExistsInQAnswers[j].secondary_asset_key);
        }
      }
    }
  }
}

/**
 * Patch the value in the specified formcontrol of the sectionform
 * @param key 
 * @param secondaryAssetKey 
 * @param value 
 */
patchSectionForm(key, secondaryAssetKey, value): void {
  if (secondaryAssetKey) {
    const x = {};
    x[key + '_' + secondaryAssetKey] = value == null ? '' : value;
    this.sectionForm.patchValue(x);
  } else {
    const x = {};
    x[key] = value;
    this.sectionForm.patchValue(x);
  }
}

/**
 * Given an assetId, find the asset in the questionnaire nested JSON and return it
 * @param assetId 
 */
getAssetNew(assetId) {
  const atp = this.assetTypes.map(item => item.type + 's'); // asset types plural
  // console.log('atp', atp);
  if (this.questionnaire && this.questionnaire.length > 0) {
    for (let o = 0; o < this.questionnaire.length; o++) {
      for (let i = 0; i < atp.length; i++) {
        // console.log('i', atp[i]);
        if (this.questionnaire[o][atp[i]] && this.questionnaire[o][atp[i]].length > 0) {
          for (let j = 0; j < this.questionnaire[o][atp[i]].length; j++) {
            if (assetId == this.questionnaire[o][atp[i]][j].id) {
              return this.questionnaire[o][atp[i]][j];
            }
            for (let k = 0; k < atp.length; k++) {
              // console.log('k', atp[k]);
              if (this.questionnaire[o][atp[i]][j][atp[k]] && this.questionnaire[o][atp[i]][j][atp[k]].length > 0) {
                for (let l = 0; l < this.questionnaire[o][atp[i]][j][atp[k]].length; l++) {
                  // console.log('this.questionnaire[o][atp[i]][j][atp[k]][l].id', this.questionnaire[o][atp[i]][j][atp[k]][l].id);
                  if (assetId == this.questionnaire[o][atp[i]][j][atp[k]][l].id) {
                    return this.questionnaire[o][atp[i]][j][atp[k]][l];
                  }
                  for (let m = 0; m < atp.length; m++) {
                    // console.log('m', atp[m]);
                    if (this.questionnaire[o][atp[i]][j][atp[k]][l][atp[m]] && this.questionnaire[o][atp[i]][j][atp[k]][l][atp[m]].length > 0) {
                      for (let n = 0; n < this.questionnaire[o][atp[i]][j][atp[k]][l][atp[m]].length; n++) {
                        if (assetId == this.questionnaire[o][atp[i]][j][atp[k]][l][atp[m]][n].id) {return this.questionnaire[o][atp[i]][j][atp[k]][l][atp[m]][n];
                        }
                      }
                    }      
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/**
 * If an asset in the formula array has a child uom_question, convert the asset's answer value to the project default unit
 * and return the converted value
 * the conversionFactorToProjectDefault value is used here
 * If an asset has TWO child uom questions, the 1st UOM conversion factor is multiplied, the 2nd one is used to divide. 
 * An example of the above is 10 Acre * 20 KG / Acre
 * @param qaToModify 
 * @param formulaObjArray 
 * @param formulaArray 
 * @param secondary_asset_key 
 */
newAdjustFormulaArray(qaToModify, formulaObjArray, formulaArray, secondary_asset_key) {
  for (let i = 0; i < formulaObjArray.length; i++) {
    if (!formulaObjArray[i]) {return;}
    formulaObjArray[i]['asset'] = this.getAssetNew(formulaObjArray[i].key);
  }

  // Get the unit-adjusted values of formula components
  for (let i = 0; i < formulaObjArray.length; i++) {
    if (formulaObjArray[i].asset.uom_questions && formulaObjArray[i].asset.uom_questions.length > 0) {
      formulaObjArray[i]['uomValues'] = [];
      for (let j = 0; j < formulaObjArray[i].asset.uom_questions.length; j++) {
        let x;
        let value;
        if (secondary_asset_key) {
          x = this.qAnswers.find(item => item.key == formulaObjArray[i].asset.uom_questions[j].id && item.secondary_asset_key == secondary_asset_key);
        } else {
          x = this.qAnswers.find(item => item.key == formulaObjArray[i].asset.uom_questions[j].id);
        }
        if (x) {value = x['value'];}
        formulaObjArray[i]['uomValues'].push({
          qa_subtype: formulaObjArray[i].asset.uom_questions[j].qa_subtype,
          value: value
        });
      }
    }
  }

  if (qaToModify.uom_questions && qaToModify.uom_questions.length > 0) {
    qaToModify['uomValues'] = [];
    for (let j = 0; j < qaToModify.uom_questions.length; j++) {
      let x;
      let value;
      if (secondary_asset_key) {
        x = this.qAnswers.find(item => item.key == qaToModify.uom_questions[j].id && item.secondary_asset_key == secondary_asset_key);
      } else {
        x = this.qAnswers.find(item => item.key == qaToModify.uom_questions[j].id);
      }
      if (x) {value = x['value'];}
      qaToModify['uomValues'].push({
        qa_subtype: qaToModify.uom_questions[j].qa_subtype,
        value: value
      });
    }
  }

  const newFormulaArray = [];
  for (let i = 0; i < formulaObjArray.length; i++) {
    if (!formulaObjArray[i]) {
      return;
    }
    if (formulaObjArray[i] && formulaObjArray[i].uomValues) {
      let modifiedValue;
      for (let j = 0; j < formulaObjArray[i].uomValues.length; j++) {
        if (!formulaObjArray[i].uomValues[j]['value']) {
          modifiedValue = 0;
        }
        const x = this.processedUOMs[formulaObjArray[i].uomValues[j]['qa_subtype']]['units'].find(item => item.id == formulaObjArray[i].uomValues[j].value);
        if (x) {
          // The first UOM is multiplicative. e.g. "Kg" in Kg / Hecture
          if (j === 0) {
            modifiedValue = formulaObjArray[i].value * x['conversionFactorToProjectDefault'];
          }
          // The second UOM is used in the denominator, e.g. "Hectare" in Kg / Hecture
          else if (j === 1) {
          modifiedValue = modifiedValue / x['conversionFactorToProjectDefault'];
        }
        }
        if (modifiedValue != null && modifiedValue != undefined) {
          const decimals = this.getOutputDecimals([formulaObjArray[i].value]);
          if (decimals != null && decimals != undefined) {
            modifiedValue = modifiedValue.toFixed(2);
          }
        }
      }
      newFormulaArray.push({key: formulaObjArray[i].key, value: modifiedValue});
    } else {
      newFormulaArray.push({key: formulaObjArray[i].key, value: formulaObjArray[i].value});
    }
  }

  // As the unit of qaToModify, set the project's default value
  let qaToModifyUOM;
  if (qaToModify['uomValues'] && qaToModify['uomValues'].length > 0) {
    qaToModifyUOM = this.processedUOMs[qaToModify['uomValues'][0].qa_subtype].projectDefaultUnit.id;
  }

  const formulaArrayCopy = JSON.parse(JSON.stringify(formulaArray));
  for (let i = 0; i < formulaArray.length; i++) {
    if (!isNaN(parseInt(formulaArrayCopy[i]))) {
      formulaArray[i] = newFormulaArray.find(item => item.key == formulaArrayCopy[i]).value;
    }
  }
  return {formula: formulaArray, unit: qaToModifyUOM};
}

/**
 * Convert the project's UOM array into a usable map, where the key is unit_type.id and value is an object which has
 * ... the units corresponding to that unit_type, along wiht the conversionFactorToProjectDefault
 * conversionFactorToProjectDefault converts each unit's international_value conversion factor (which is relative to international_unit such as hectare)...
 * ... into a conversion factor which is relative to the project's default unit (which may be different than the international unit)
 * This is done because autocalculate formulas' answer value is calculated in the project's default unit, if a unit for the calculated answer is not defined
 */
preProcessProjectUOMs() {
  this.processedUOMs = {};
  if (this.project && this.project.ProjectUOM && this.project.ProjectUOM.length > 0) {
    for (let i = 0; i < this.project.ProjectUOM.length; i++) {
      if (!this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]) {
        this.processedUOMs[this.project.ProjectUOM[i].unit_type_id] = {};
        this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['units'] = [];
      }
      const projectDefaultUnit = this.project.ProjectUOM.find(item => item.is_default && item.unit_type_id === this.project.ProjectUOM[i].unit_type_id);
      this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['projectDefaultUnit'] = projectDefaultUnit;
      this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['units'].push({
        id: this.project.ProjectUOM[i].id,
        international_unit: this.project.ProjectUOM[i].international_unit,
        international_value: this.project.ProjectUOM[i].international_value,
        uom: this.project.ProjectUOM[i].uom,
        conversionFactorToProjectDefault: this.project.ProjectUOM[i].international_value / projectDefaultUnit.international_value
      });
    }
  }
}

/**
 * Sort the default crop (the ecosystem crop) to the top of the agri income table
 * @param tableId - Number
 */
sortDefaultCropToTop(tableId) : void {
  if (this.secondary_asset_keys && this.secondary_asset_keys[tableId] && this.secondary_asset_keys[tableId].length > 0) {
    const x = this.secondary_asset_keys[tableId].indexOf(this.defaultCropSak);
    if (x) {
      if (x === 0) {
        // do nothing
        return;
      } else {
        const temp = JSON.parse(JSON.stringify(this.secondary_asset_keys[tableId]));
        const p = temp[0];
        temp[0] = this.defaultCropSak;
        temp[x] = p;
        this.secondary_asset_keys[tableId] = JSON.parse(JSON.stringify(temp));
      }
    }
  }
}

/**
 * Given the array of factors to be inserted into the formula, find the number of decimal places of the output
 * It is the max number of decimals of any of the input values
 * @param {*} fnArray 
 */
getOutputDecimals(fnArray) {
  let decimals = 0;
  const decimalPositionArray = [];
  fnArray.forEach(item => {
    if (item) {
      const indexOfDecimal = item.toString().indexOf('.');
      if (this.validIndex(indexOfDecimal)) {
        decimalPositionArray.push(item.toString().length - indexOfDecimal);
      }
    }
  });
  if (decimalPositionArray.length > 0) {
    decimals = Math.max.apply(Math, decimalPositionArray) - 1;
  }
  return decimals > 0 ? decimals : 0;
}

/**
 * Navigate to the farmer management component
 */
goToFarmerManagementEdit(): void {
  if(!this.campaignId){
    this.router.navigate(['farmerdetails/' + this.projectId + '/' + this.rawFarmerId]);
  } else {
    this.router.navigate(['farmerdetails/' + this.projectId + '/' + this.rawFarmerId], {queryParams: {campaignId: this.campaignId}});
  }
}

/**
 * Check whether the current user has access to the farmer management service or not. The button to navigate to farmer management
 * component is displayed only if the user has access
 */
canDoFarmerMgmtFn(): void {
  if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
    this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
  }

  if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
    this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
  }

  if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
    const canDoFarmerMgmt = this.usersServiceComponentMappings.filter(item => (item.services_id == 2 && item.user_type_id == this.currentUser.role.id));
    if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
      this.canDoFarmerMgmt = true;
    }
  }
  else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
    const canDoFarmerMgmt = this.ecosystemServicesComponentsMapping.filter(item => item.services_id == 2);
    if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
      this.canDoFarmerMgmt = true;
    }
  }
}

/**
 * Display a message in place of the questionnaire saying that there are no active snapshots in the project
 */
showNoActiveSnapshotMessage(): void {
  this.canDoFarmerMgmtFn();
  setTimeout(() => this.toastr.info(this.thereNoActiveSnapshotForThisProject), 0);
}

/**
 * Check whether the profitability summary needs to be displayed in the given tab
 */
showSummaryForCurrentTabFn(): void {
  if (this.currentTab && this.currentTab.displayFunction && this.currentTab.displayFunction.indexOf('showSummary') > -1) {
    this.showSummaryForCurrentTab = true;
  } else {
    this.showSummaryForCurrentTab = false;
  }
}

/**
 * Run the calculations used to display the profitability summary figures
 */
runTabSummaryCalculate(): void {
  if (this.showSummaryForCurrentTab) {
    this.createProfitabilitySummaryObject();
    if (this.currentSection && this.farmProfitabilitySummaryArray && this.farmProfitabilitySummaryArray.length > 0) {
      const x = this.farmProfitabilitySummaryArray.find(item => item.sectionId == this.currentSection.id);
      const indexOfCurrentSection = this.farmProfitabilitySummaryArray.indexOf(x);
      const sections = JSON.parse(JSON.stringify(this.farmProfitabilitySummaryArray));
      if (indexOfCurrentSection > 0) {
        sections.splice(indexOfCurrentSection);
        sections.reverse();
      }

      // Logic used to display the left and right side of the profitability summary tab
      for (let i = 0; i < this.farmProfitabilitySummaryArray.length; i++) {
        if (this.currentSection.id == this.farmProfitabilitySummaryArray[i].sectionId) {
          if (this.currentSection.isIncome) {
            this.farmProfitabilitySummary.income = {
              source: this.farmProfitabilitySummaryArray[i].label,
              amount: this.farmProfitabilitySummaryArray[i].amount
            }
            const expenseSection = sections.find(item => item.isExpense);
            if (expenseSection) {
              this.farmProfitabilitySummary.expense = {
                source: expenseSection.label,
                amount: expenseSection.amount
              }
            }
          } else if (this.currentSection.isExpense) {
            this.farmProfitabilitySummary.expense = {
              source: this.farmProfitabilitySummaryArray[i].label,
              amount: this.farmProfitabilitySummaryArray[i].amount
            }

            const incomeSection = sections.find(item => item.isIncome);
            if (incomeSection) {
              this.farmProfitabilitySummary.income = {
                source: incomeSection.label,
                amount: incomeSection.amount
              }
            }
          }
        }
      }

    }
    // console.log('this.farmProfitabilitySummary', this.farmProfitabilitySummary);
  }
}

/**
 * Create the profitability summary array that will be used to display the summary on top
 */
createProfitabilitySummaryObject(): void {
  this.farmProfitabilitySummaryArray = [];
  const sections = this.currentTab.sections;
  let totalIncome = 0;
  let totalExpense = 0;
  if (sections && sections.length > 0) {
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].isIncome || sections[i].isExpense) {
        const amount = this.getSummaryAmount(sections[i]);
        const amountToSet = amount ? amount : 0;
        // console.log(sections[i]['survey_q_asset_labels'][0].label, amount, amountToSet);
        const obj = {
          index: i,
          sectionId: sections[i].id,
          label: sections[i]['survey_q_asset_labels'][0].label,
          amount: amountToSet,
          isIncome: sections[i]['isIncome'],
          isExpense: sections[i]['isExpense']
        };
        this.farmProfitabilitySummaryArray.push(obj);
        if (obj.isIncome) {totalIncome = totalIncome + parseFloat(amountToSet);}
        if (obj.isExpense) {totalExpense = totalExpense + parseFloat(amountToSet);}
      }
      this.farmProfitabilitySummary.totalIncome = totalIncome.toString();
      this.farmProfitabilitySummary.totalExpense = totalExpense.toString();
      this.farmProfitabilitySummary.totalProfit = (totalIncome - totalExpense).toString();
    }
  }
}

/**
 * Reset the profitability summary array
 */
resetFarmProfitabilitySummary(): void {
  this.showSummaryForCurrentTab = false;
  this.farmProfitabilitySummary = {income: {source: 'NA', amount: 'NA'}, expense: {source: 'NA', amount: 'NA'}, totalIncome: 'NA', totalExpense: 'NA', totalProfit: 'NA'}; 
  this.farmProfitabilitySummaryArray = [];
}

/**
 * Given a section, get the summary amount - this is generally the grand total value of the section
 * @param section 
 */
getSummaryAmount(section) {
  let answerToReturn;
  if (section.displayFunction) {
    const x = JSON.parse(section.displayFunction);
    if (x) {
      if (x['answerQAsset']) {
        const y = this.qAnswers.find(item => item.key == x['answerQAsset'] && !item.deleted);
        if (y) {answerToReturn = y.value;}
      }

      // This is required for the workers expenditure section
      // Grand total of the daily workers expenses must be added to 12 * number of workers * avg monthly wate
      if (x['multiplyFollowing']) {
        if (x['key1'] && x['key2']) {
          const y1 = this.qAnswers.find(item => item.key == x['key1'] && !item.deleted);
          const y2 = this.qAnswers.find(item => item.key == x['key2'] && !item.deleted);
          if (y1 && y2) {
            const t = x['multiplyFollowing'] * y1.value * y2.value;
            answerToReturn = answerToReturn ? parseInt(answerToReturn) + t : t;
          }
        }
      }
    }
  }
  return answerToReturn;
}

/**
 * Set special attributes to the questionnaire sections
 */
setQuestionnaireAttributes(): void {
  if (this.questionnaire && this.questionnaire.length > 0) {
    for (let i = 0; i < this.questionnaire.length; i++) {
      if (this.questionnaire[i].sections && this.questionnaire[i].sections.length > 0) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (this.questionnaire[i].sections[j].displayFunction) {
            const x = JSON.parse(this.questionnaire[i].sections[j].displayFunction);
            if (x.type && x.type === 'income') {
              this.questionnaire[i].sections[j]['isIncome'] = true;
            } else if (x.type && x.type === 'expense') {
              this.questionnaire[i].sections[j]['isExpense'] = true;
            }
          }
        }
      }
    }
  }
}

/**
 * Set the currencycode value
 */
setCurrencyCode(): void {
  if(this.project.Currency && this.project.Currency.currency_code){
    this.currencyCode = this.project.Currency.currency_code;
  } else{
    this.currencyCode = "";
  }
}

// notADuplicateValue(question, value) {
  // if (question.displayFunction) {
  //   const displayFunctionJson = JSON.parse(question.displayFunction);
  //   if (displayFunctionJson && displayFunctionJson['type'] && displayFunctionJson['type'] === 'uniqueAnswers') {
  //     let answerValues = this.qAnswers.filter(item => item.key == question.id && !item.deleted);
  //     answerValues.push({key: question.id, value: value});
  //     if (answerValues && answerValues.length > 0) {
  //       if (this.hasObjectWithDuplicates(answerValues, 'value')) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     return true;
  //   }
  // } else {
  //   return true;
  // }
// }

// Checks if array has an object with duplicate values of attributeToCheck
// hasObjectWithDuplicates(array, attributeToCheck) {
//   let valueArr = [];
//   array.forEach(element => {
//     valueArr.push(element[attributeToCheck]);
//   });
//   let isDuplicate = valueArr.some(function(item, idx){ 
//     return valueArr.indexOf(item) != idx 
//   });
//   return isDuplicate;
// }

/** 
 * This function sets the uom values in the sectionForm so that they appear in the UOM dropdown by default
*/
 setUOMValuesInForm(uomQId, options, secondary_asset_key): void {
  const defaultOption = options.find(item => item.is_default);
  if (defaultOption) {
    if (this.sectionForm) {
      const x = {};
      if (secondary_asset_key) {
        if (this.sectionForm['value'][uomQId + '_' + secondary_asset_key] === '') {
          x[uomQId + '_' + secondary_asset_key] = defaultOption.id;
        }
      } else {
        if (this.sectionForm['value'][uomQId] === '') {
          x[uomQId] = defaultOption.id;
        }
      }
      this.sectionForm.patchValue(x);
    }
  }
}

/**
 * This function adds the valeus of the child UOM question to the qAnswers Array, it is called when the parent question is modified
 * @param question 
 * @param secondary_asset_key 
 * @param table 
 */
setUOMValuesInQAnswers(question, secondary_asset_key?, table?, na?): void {
  if (secondary_asset_key) {
    const x = this.qAnswers.find(item => item.key == question.id && item.secondary_asset_key === secondary_asset_key);
    if (x.value && x.touched) {
      for (const uomQ of question.uom_questions) {
        this.sectionFormChangeHandlerForTable(null, table, uomQ, secondary_asset_key);
      }
    }
  } else {
    const x = this.qAnswers.find(item => item.key == question.id);
    if ((x.value || (typeof na !== "undefined" && na == false)) && x.touched) {
      for (const uomQ of question.uom_questions) {
        this.sectionFormChangeHandler(null, uomQ);
      }
    }
  }
}

/**
 * Update the state of the checkedObject, which records which multiselect (checkbox) assets have been checked
 * @param args 
 * @param optionId 
 * @param questionId 
 * @param secondaryAssetKey 
 */
updateCheckedObject(args, optionId, questionId, secondaryAssetKey): void {
  if (secondaryAssetKey) {
    if (this.checkedObjectSecondaryKeys) {
      this.checkedObjectSecondaryKeys[questionId + '_' + secondaryAssetKey + '_' + optionId] = args.target.checked;
    }
  } else {
    if (this.checkedObject) {
      this.checkedObject[questionId + '_' + optionId] = args.target.checked;
    }
  }
}


/**
 * Find which limits range the given farmerId falls in
 * E.g. if limits = [130, 150, 170] and farmerId = 165
 * limitsToReturn = [150, 170] would be returned
 * @param limits 
 * @param farmerId 
 */
getPaginationLimits(limits, farmerId) {
  // console.log('limits', limits);
  // console.log('farmerId', farmerId);
  const limitsToReturn = [];

  for (let i = 0; i < limits.length; i++) {
    if (limits[i] <= farmerId && limits[i + 1] && limits[i + 1] > farmerId) {
      limitsToReturn[0] = limits[i];
      limitsToReturn[1] = limits[i+1];
      return limitsToReturn;
    } else if (!limits[i + 1]) {
      limitsToReturn[0] = limits[i];
      limitsToReturn[1] = undefined;
      return limitsToReturn;
    }
  }
}

/**
 * Load the profiling data into cache (after message received from API that the data has been synced)
 * @param projectId 
 * @param gte 
 * @param lt 
 */
async loadFarmerDataIntoCache(projectId, gte, lt) {
  if (projectId && projectId > 0) {  
    await Promise.all([
      this.dynamicQuestionnaireService.getQAnswersForProjectPaginated(projectId, gte, lt).toPromise()
    ])
    .then(result => {
      console.log('result', result);
    }).catch(e => {});
  }
}

async campaignQuestionnaireFlow() {
  let r;
  if (navigator.onLine) {
    r = await Promise.all([
      this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
      this.projectService.getProjectProfilingData(this.projectId).toPromise(),
      this.projectService.getProjectDashboardData(this.projectId).toPromise(),
      this.surveyService.getSurveyQuestionnaireForCampaign(this.campaignId).toPromise(),
      this.dynamicQuestionnaireService.getQAnswersForFarmerForCampaign(this.campaignId, this.farmerId).toPromise(),
      this.farmerService.getFarmerBasicDataById(this.farmerId).toPromise(),
      this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
    ]);
  } else {
    r = await Promise.all([
      this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
      this.projectService.getProjectProfilingData(this.projectId).toPromise(),
      this.projectService.getProjectDashboardData(this.projectId).toPromise(),
      this.surveyService.getSurveyQuestionnaireForCampaign(this.campaignId).toPromise(),
      this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise(),
      this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
      this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
    ]);
  }
  console.log('r', r);
  return r;
}

setBreadcrumbText(): void {
  this.translate.get('breadcrumbTitle').subscribe(result => {
    if (this.campaignId) {
      this.breadcrumbTitle = result['campaign'];
    }
    this.breadcrumbTitle = result['project'];
  });
}

goToProfilingTable(): void {
  if (this.campaignId) {
    this.router.navigate([`/campaign-profiling-farmers/${this.projectId}/${this.campaignId}`]);
  } else {
    this.router.navigate([`/profiling/${this.projectId}`]);
  }
}

checkMandatoryQuestions(){
    let qCount = 0; 
    for(const question of this.currentSection.questions){
      if(question.isDisplayed){
        for (let j = 0; j < question.survey_q_asset_validations.length; j++) {
          if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {            
            const answered = this.checkIfAnswered(question);
            if(!answered){
              qCount++;
            }
          }
        }
      }
    }
    for(const subsection of this.currentSection.subsections){
      if(subsection.questions && subsection.questions.length > 0 && subsection.isDisplayed){
        for(const question of subsection.questions){
          if(question.isDisplayed){
            for (let j = 0; j < question.survey_q_asset_validations.length; j++) {
              if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {            
                const answered = this.checkIfAnswered(question);
                if(!answered){
                  qCount++;
                }
              }
            }
          }
        }
      }
    }
    for(const table of this.currentSection.tables){
      const findSAKs = this.secondary_asset_keys[table.id];
      if(findSAKs && findSAKs.length > 0){
        for(const sak of findSAKs){
          // if(j.questions && j.questions.length > 0 && j.isDisplayed){
          if(table.questions && table.questions.length > 0 && table.isDisplayed){
            for(const question of table.questions){
              const keyIsDisplayed = `${sak}_isDisplayed`;  
              if(question[keyIsDisplayed]){
                for (let j = 0; j < question.survey_q_asset_validations.length; j++) {
                  if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {            
                    const answered = this.checkIfAnswered(question, sak);
                    if(!answered){
                      qCount++;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if(qCount > 0){
      this.mandatoryQuestionAnswered = false;
    } else {
      this.mandatoryQuestionAnswered = true;
    }
}

checkIfAnswered(question, sak?){
  const qid = question.id;
  let ans;
  let checkBoxQnFlag = false;
  
  if(sak){
    ans = this.qAnswers.find(item => item.key == qid && item.secondary_asset_key == sak);
  } else if (question.qa_subtype === "multiselect") {
    ans = this.qAnswers.filter(item => item.key == qid);
    checkBoxQnFlag = true;
  } else {
    ans = this.qAnswers.find(item => item.key == qid);
  }

  if (checkBoxQnFlag === true) {
    let qnAnswered = false;
    ans.map((item) => {
      if (!item.unchecked && item.flex3 === "A") {
        qnAnswered = true;
      }
    });
    if (qnAnswered) {
      return true;
    } else {
      return false;
    }
  } else if(ans && ans.value != '' && ans.value != null && ans.flex3 === "A"){
    return true;
  } else {
    return false;
  }
}

// BACKUP METHODS - MAY BE REQUIRED

// 16/7
// sectionFormChangeHandler(args): void {
//   if (!this.qAnswers) {this.qAnswers = [];}
//   Object.keys(this.sectionForm.value).forEach(function (k) {
//     if (this.sectionForm.value[k] && this.sectionForm.value[k] != '') {
//       let x = this.qAnswers.find(item => item.key == k);
//       if (x) {
//         let index = this.qAnswers.indexOf(x);
//         this.qAnswers[index]['value'] = this.sectionForm.value[k];
//       } else {
//         this.qAnswers.push({key: k, value: this.sectionForm.value[k]});
//       }
//     } else if (this.sectionForm.value[k] === '') {
//       let x = this.qAnswers.find(item => item.key == k);
//       if (x) {
//         let index = this.qAnswers.indexOf(x);
//         this.qAnswers[index]['value'] = this.sectionForm.value[k];
//       } else {
//         this.qAnswers.push({key: k, value: this.sectionForm.value[k]});
//       }
//     }
//   }.bind(this));
//   console.log('this.qAnswers', this.qAnswers);
// }



// for (let k = 0; k < this.currentSection.tables.length; k++) {
//   for (let i = 0; i < this.currentSection.tables[k].questions.length; i++) {
//     let existsInQAnswersForTableQuestion = this.existsInQAnswersForTableQuestion(this.currentSection.tables[k].questions[i]);
//     console.log('existsInQAnswersForTableQuestion', this.currentSection.tables[k].questions[i].id, existsInQAnswersForTableQuestion);
//     if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
//       for (let p = 0; p < existsInQAnswersForTableQuestion.length; p++) {
//         this.sectionForm.addControl(this.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key'], new FormControl(existsInQAnswersForTableQuestion[p]['value']));
//         if (this.currentSection.tables[k].questions[i].disable_input) {this.sectionForm.controls[this.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key']].disable();}
//         if (this.currentSection.tables[k].questions[i].include_uom_question) {this.setUOMQuestion(this.currentSection.tables[k].questions[i], existsInQAnswersForTableQuestion[p]['secondary_asset_key']);}
//       }
//       this.secondary_asset_keys[this.currentSection.tables[k].id] = existsInQAnswersForTableQuestion.map(item => item.secondary_asset_key);
//       let sak_of_modified_option = this.qAnswers.find(item => item.sak_of_modified == this.secondary_asset_keys[this.currentSection.tables[k].id]);
//       if (sak_of_modified_option) {
//         this.secondary_asset_key__options[sak_of_modified_option['value']] = this.secondary_asset_keys[this.currentSection.tables[k].id];
//       }
//     } else {
//       console.log('this.secondary_asset_keys[this.currentSection.tables[k].id]', this.secondary_asset_keys[this.currentSection.tables[k].id]);
//       let sak_of_modified_option = this.qAnswers.find(item => item.sak_of_modified != null && item.sak_of_modified == this.secondary_asset_keys[this.currentSection.tables[k].id]);
//       console.log('185', sak_of_modified_option, this.secondary_asset_keys[this.currentSection.tables[k].id]);
//       if (sak_of_modified_option) {
//         this.secondary_asset_key__options[sak_of_modified_option['value']] = this.secondary_asset_keys[this.currentSection.tables[k].id];
//       } else {

//       }
//       this.sectionForm.addControl(this.currentSection.tables[k].questions[i].id + '_' + this.secondary_asset_keys[this.currentSection.tables[k].id], new FormControl(''));
//       if (this.currentSection.tables[k].questions[i].disable_input) {this.sectionForm.controls[this.currentSection.tables[k].questions[i].id + '_' + this.secondary_asset_keys[this.currentSection.tables[k].id]].disable();}
//       if (this.currentSection.tables[k].questions[i].include_uom_question) {this.setUOMQuestion(this.currentSection.tables[k].questions[i], this.secondary_asset_keys[this.currentSection.tables[k].id]);}
//     }
//   }
// }
  openSurveyManual() {
    this.showReferenceTab = true;
    this.docUrl = `${environment.apiBaseUrl}/${this.locale.substr(0, 2)}/assets/uploads/surveyManual/${this.activeSnapshot.flex4}`;
    console.log('docUrl', this.docUrl)
    // const docExtension = docUrl.split(/[#?]/)[0].split('.').pop().trim();
    // this.http
    //   .get(docUrl, {
    //     responseType: 'blob',
    //   })
    //   .subscribe(response => {
    //     const blob = new Blob([response], { type: 'application/octet-stream' });
    //     const downloadUrl = window.URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = downloadUrl;
    //     console.log('downloadUrl', downloadUrl);
    //     link.style.display = 'none';
    //     link.download = `Survey_manual.${docExtension}`;
    //     link.click();
    //     window.URL.revokeObjectURL(downloadUrl);
    //     link.remove();
    //   });
  }

  openTextarea(e){
    e.target.nextElementSibling.style.display = "block";
    e.target.nextElementSibling.focus();
  }
  closeTextArea(e){
    e.target.style.display = "none";
    e.target.previousElementSibling.value = e.target.value;
  }

}