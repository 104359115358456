import { KeyValuePair } from './project';

export class Users {
    id: number;
    username: string;
    pwd: string;
    name: string;
    middle_name: string;
    last_name: string;
    alias: string;
    mobile: string;
    email: string;
    language: string;
    country_id: number;
    country_dial_in: string;
    deleted: number;
    user_type_id: number;
    is_read_write_alwd: string;
    forget_pwd_key: string;
    is_pwd_reset: number;
    pwd_reset_date: Date;
    cr_user_id: number;
    cr_date: Date;
    upd_user_id: number;
    upd_date: Date;
    // project_knowledge_yn: string;
    last_login_date: Date;
    UserProgramMapping: MultiValueDetailed[] = [];
  }

  export class MultiValueDetailed {
    id: number;
    users_id: number;
    user_type_id: number;
    access_type_id: number;
    project_id: number;
    cr_user_id: number;
  }

  export class MultiValuedModels {
    roles: KeyValuePair[];
  }
