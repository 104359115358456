import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, OnDestroy, 
  Inject, HostListener, ElementRef, Renderer } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { DOCUMENT } from '@angular/platform-browser';
import { Observable, fromEvent, merge, of, Subscription } from 'rxjs';
import { mapTo, switchMap, pairwise } from 'rxjs/operators';
import { ToastrService, ActiveToast} from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { UserService } from '../../services/user.service';
import { RevisionService } from '../../services/revision.service';
import { SurveyService } from '../../services/survey.service';
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { Project } from '../../models/project';
import { CampaignService } from '../../services/campaign.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataConsentService } from '../../services/data-consent.service';

import { $ } from 'protractor';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import Compressor from 'compressorjs';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConsReportService } from '../../services/consolidated-reports.service';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';

declare var idbApp: any;
declare var idbAppPhoto: any;

@Component({
  selector: 'app-managerpage',
  templateUrl: './managerpage.component.html',
  styles: [
    `.scroll-to-top {
      position: fixed;
      bottom: 15px;
      right: 15px;
      opacity: 0;
      transition: all .2s ease-in-out;
     }
     .show-scrollTop {
      opacity: 1;
      transition: all .2s ease-in-out;
     }
     .project-header nav.breadcrumb {
      width: calc(100% - 323px);
     }
     .posLeftCenter{
      position: absolute;
      top:1px;
      right:90px;
     }
     `
  ],
  host: { '(window:partial-synced)': 'syncDone($event)' },
})
export class ManagerpageComponent implements OnInit, AfterViewInit, OnDestroy {

  online$: Observable<boolean>;

  public registerFarmerModal;
  public registeringFarmerName;
  public registeringFarmerAge;
  public registeringFarmerSex;
  public registeringFarmerArea;
  public registeringFarmer = false;
  public snapshot;
  public master;
  public sex;
  public registerFarmerForm: FormGroup;
  public registerAnotherFarmer = false;
  fileToUpload: File = null;
  photoModel = '';
  projectIdRegisteringFarmer;
  public searchText: any;
  projects: any[] = [];
  isFieldAgent = true;
  isManager = false;
  isDeeproots = false;
  productType = [];
  managers = [];
  showDetailData1 = false;
  showingProjectTables:any = [];

  windowScrolled: boolean;

  projectFilter: any = { name: '' };
  public confirmModal = true;

  private readonly unsubscribe: Subject<void> = new Subject();

  order = 'project.name';
  projectNames: string[] = [];

  isAscending = true;
  sortLabel = '';
  // navArrowLeftIcons:boolean = false;
  // navArrowRightIcons:boolean = true;
  public noProjectsMsg: boolean = false;
  public farmerImageData: any;
  registerFarmerGender = 3;
  @ViewChild('registerFarmerModal') registerFarmerModalTemplate :any;
  @ViewChild('name') firstNameTextbox :ElementRef;
  // @ViewChild('namedsf', { read: ElementRef })firstNameTextbox: ElementRef;

  ageSliderOptions = {
      floor: 18,
      ceil: 99
  };

  farmerAgeSliderValue = 18;
  currentUser = this.userService.getCurrentUser();

  public clearValue: any;
  accessSub: Subscription;
  private readonly currentSearchClearStatus: Subscription;
  ecosystemScopeId;
  ecosystemScopeIdSubscription;
  uamData;
  public loadedProjects = false;
  uploadingFileName;
  noFileChosenDefault; // default text for input file component
  // flag set
  flagEn = false;
  flagEs = false;
  flagFr = false;
  flagZh = false;
  flagTa = false;
  flagMg = false;
  flagHi = false;
  flagId = false;
  notAvailableWhenOfflineTxt;
  deviceInfo;
  isMobile;
  isTablet;
  isDesktopDevice;
  titleValPosMale: number;
  titleValPosFemale: number;
  villageAreaList = [];
  pId;
  farmerMasterData:any;
  educationLevelData:any = [];
  showCacheMessage1; showCacheMessage2;
  //added by KRPT for consent sign 
  signValues;
  signEmpty = false;
  changeEvent = true;
  consentTemplateAvailable = false;;
  consentTemplateData;
  startTime;
  endTime;
  percCompletion = 0;
  randomNumForLog;
  deviceInfoObj;
  totalProjects = 0;
  projectsLoaded = 0;
  currentPerc = 0;
  ecosystemId = null;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly projectService: ProjectService,
    private commonService: CommonService,
    private readonly farmerService: FarmerService,
    private readonly userService: UserService,
    private readonly revisionService: RevisionService,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly formBuilder: FormBuilder,
    public modalService: BsModalService,
    private readonly activeRoute: ActivatedRoute,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly el: ElementRef,
    private readonly renderer: Renderer,
    private readonly translate: TranslateService,
    private readonly loc: Location,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly deviceService: DeviceDetectorService,
    private readonly surveyService: SurveyService,
    private readonly dynamicQuestionnaireService: DynamicQuestionnaireService,
    private readonly campaignService: CampaignService,
    private readonly dataConsentService:DataConsentService,
    private sanitizer: DomSanitizer,
    private readonly consReportService: ConsReportService,
    private http: HttpClient
  ) {

      this.online$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      );

     
      this.currentSearchClearStatus = this.commonService.currentSearchClearStatus.subscribe(message => {
       // console.log('message', message);
        if (message == 'blank') {
          this.removeSearchFilter();
        }
      });

      this.translate.get('file_upload_component_default_txt').subscribe(result => {
        this.noFileChosenDefault = result; // default text for the file input component
      });

      this.translate.get('not_available_when_offline').subscribe(result => {
        this.notAvailableWhenOfflineTxt = result; // default text for the file input component
      });

      this.translate.get('icon_title_male').subscribe(result => {
        this.titleValPosMale = result;

      });

      this.translate.get('icon_title_female').subscribe(result => {
        this.titleValPosFemale = result;
      });



      const localLang = this.loc.prepareExternalUrl('').replace(/\//g,'');

      if(localLang === 'en') {
        this.flagEn = true;
      } else if (localLang === 'es') {
          this.flagEs = true;
      } else if (localLang === 'fr') {
          this.flagFr = true;
      } else if (localLang === 'zh') {
        this.flagZh = true;
      } else if (localLang === 'ta') {
        this.flagTa = true;
      } else if (localLang === 'mg') {
        this.flagMg = true;
      } else if (localLang === 'hi') {
        this.flagHi = true;
      } else if (localLang === 'id') {
        this.flagId = true;
      } else {
        this.flagEn = true;
      }

      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.isMobile = this.deviceService.isMobile();
      this.isTablet = this.deviceService.isTablet();
      this.isDesktopDevice = this.deviceService.isDesktop();
    
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent,registerFarmerForm) {
    
      this.declineRegisterFarmerPrompt(this.registerFarmerForm);
    }


  ngOnInit() {

    this.activeRoute.queryParamMap.subscribe(queryParams => {
      let qpObject = {...queryParams.keys, ...queryParams};
      this.pId = qpObject['params']['pId'];
      let filterValue = qpObject['params']['filter'];
      if(filterValue && filterValue.length > 0){
        this.searchText = filterValue;
      }

      this.ecosystemScopeId = localStorage.getItem('ecosystem_id');

      const ecosystemId = qpObject['params']['ecosystemId'];
      const ecosystemName = qpObject['params']['ecosystemName'];
      if (ecosystemId && ecosystemId.length > 0) {
        this.ecosystemScopeId = ecosystemId;
        localStorage.setItem('ecosystem_id', ecosystemId);
        localStorage.setItem('ecosystem_name', ecosystemName);
      }
    })

    if (this.currentUser.role.id === 3){
      if (!localStorage.getItem('ecosystem_id') || localStorage.getItem('ecosystem_id') === '0') {
        this.commonService.showToast('warning', 'select_ecosystem', {});
        this.router.navigate(['/ecosystems']);
     }
    }
    if (this.currentUser.role.id === 2) {
      if (!localStorage.getItem('ecosystem_id')) {
        this.commonService.showToast('warning', 'select_ecosystem_or_all', {});
        this.router.navigate(['/ecosystems']);
     }
    }

    const code = this.commonService.checkRole();

      if (code == 'FAG') {
        this.isFieldAgent = true;
      }
      else if (code == 'PMG') {
        this.isManager = true;
      }
      else if (code == 'SYS1') {
        this.isDeeproots = true;
      }
      this.productType = this.route.snapshot.data.dashboard ? this.route.snapshot.data.dashboard.productType.data : undefined;
      
      this.managers = this.route.snapshot.data.dashboard ? this.route.snapshot.data.dashboard.managers.data : undefined;
      
      this.master = this.route.snapshot.data.project.master.data;
      this.sex = this.master.filter(x => x.item_tab === 'farmer_reg' && x.item_code === 'sex');
      
      const farmerQuestions:any=[];
      for(let i=0; i<this.master.length;i++){
        if(this.master[i].item_tab == 'farmer_reg'){
          farmerQuestions.push(this.master[i]);           
        }
      }
      for(let i=0;i<farmerQuestions.length;i++){
         if(farmerQuestions[i].item_code == 'education_level'){
          this.educationLevelData.push(farmerQuestions[i]);
        }
      }

      this.displayProjects();
      this.sortLabel = 'A-Z';

      this.deviceInfoObj = {
        browser: {
          name: this.deviceInfo.browser,
          version: this.deviceInfo.browser_version
        },
        os: {
          name: this.deviceInfo.os,
          version: this.deviceInfo.os_version
        }
      };
  }
  toggle1(id) {
    if(this.showingProjectTables.indexOf(id) > -1){
      this.showingProjectTables = this.showingProjectTables.filter(item => item != id);
      
    }else{
      this.showingProjectTables.push(id);
    }
  }
  filterChange(){
      this.router.navigate([], {
        relativeTo: this.activeRoute,
        queryParams:
        {
          filter: this.searchText.trim()
        },
        replaceUrl: true,
      });
  }

  removeSearchFilter(){
    this.searchText ='';
    this.pId = undefined;
    this.displayProjects();
  }

  getGridFilters() {
    const gridColums = ['name'];
    const finalObj = {};
    if (this.projects === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        
          finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  ngAfterViewInit() {
    let toast: ActiveToast<any>;
    if (navigator.onLine) {
      setTimeout(() => {
        toast = this.commonService.showToast('warning', 'caching_warning', {disableTimeOut: true});
      });
    
    this.commonService.updateCache().subscribe((val) => {
      // const snapshots = val[4];
      // if (snapshots.status == 'success') {
      //   this.commonService.allSnapshots = val[4].data;
      //   localStorage.setItem('snapshotsFetchedAt', new Date().getTime().toString());
      // }

      if (toast) {
        toast.toastRef.manualClose();
      } else {
        this.toastr.clear();
      }
      this.showCacheMessage2 = true;
      this.showDataCachedMessage(); 
    },
    error => {
      this.spinner.hide();
      if (toast) {
        toast.toastRef.manualClose();
      } else {
        this.toastr.clear();
      }
      setTimeout(() => this.commonService.showToast('error', 'generic_error', {}), 1000);
    });
    }
  }

  sort() {
     this.isAscending ? this.ascendic() : this.descendic();

  }

  ascendic() {
    this.isAscending = false;
    this.sortLabel = 'Z-A';
    this.projects.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
      }
      return 0;
    });
  }

  descendic() {
    this.isAscending = true;
    this.sortLabel = 'A-Z';
    this.projects.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
      }
      return 0;
    });
  }

  goTo(where: string) : void {
      if (navigator.onLine) {
        this.router.navigate([`/${where}`]);
      }
      else {
        if (where === 'setup') {
          this.commonService.showToast('warning', 'offline_project_warning', {});
        }
      }

  }


  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.currentSearchClearStatus.unsubscribe();
  }

  openRegisterFarmerModal(template: TemplateRef<any>, id) : void {
    this.projectIdRegisteringFarmer = id;
    this.registerFarmerModal = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
  }


  registerFarmer() : void {
    this.registeringFarmer = true;
    const name = this.registerFarmerForm['value'].name;
    const age = this.registerFarmerForm['value'].age;
    const sex = this.registerFarmerForm['value'].sex;
    const area = this.registerFarmerForm['value'].area;
    const telNumber = this.registerFarmerForm['value'].telNumber;
    const email = this.registerFarmerForm['value'].email;
    let levelOfEducation = this.registerFarmerForm['value'].levelOfEducation;
    const motherTongue = this.registerFarmerForm['value'].motherTongue;
    const fatherHusbandName = this.registerFarmerForm['value'].fatherHusbandName;

      if (!name || name.length == 0 || !age || age.length == 0 || !sex || sex.length == 0 || !area || area.length == 0 || !motherTongue || motherTongue.length == 0) {
        return;
      }
      if (levelOfEducation != null && levelOfEducation == 0) {
        levelOfEducation = null;
      }

      const farmerToRegister = {
        name: name,
        age: age,
        sex: sex,
        village_area_id: area,
        mother_tongue:motherTongue,
        education_level:levelOfEducation,
        tel_number: telNumber,
        email:email,
        photo_path: null,
        project_id: this.projectIdRegisteringFarmer,
        father_husband_name: fatherHusbandName
      };
      
      if (navigator.onLine) {
        if (this.fileToUpload) {
          const formData: any = new FormData();
          formData.append('type', 'farmerAvatar');
          formData.append('projectId', this.projectIdRegisteringFarmer);
          formData.append('avatar', this.fileToUpload, this.fileToUpload.name);

          this.farmerService.uploadFarmerImage(formData)
          .subscribe((obj) => {
            if (obj.msg === 'success') {
              farmerToRegister['photo_path'] = obj.data;
              this.farmerService.registerFarmer(farmerToRegister).subscribe(
                (result) => {
                  this.registeringFarmer = false;
                  if (result.msg === 'success') {
                    //If consent given by farmer (added by KRPT) 
                    if(this.signValues && this.signValues.agree && this.signValues.value != ""){
                      this.saveSignValue(this.projectIdRegisteringFarmer, result.data.id)
                    }

                    result.data.cr_date = new Date();
                    if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                      this.registerFarmerModal.hide();
                    }
                    this.registerFarmerForm.setValue({
                      name: '',
                      age: 18,
                      sex: 3,
                      area: 0,
                      motherTongue:'',
                      levelOfEducation:0,
                      telNumber: '',
                      email:'',
                      photo_path: '',
                      fatherHusbandName: ''
                    });
                    this.genderChanged(3);
                    this.registerFarmerForm.markAsUntouched();
                    this.registerFarmerForm.markAsPristine();
                    this.farmerAgeSliderValue = 18;
                    this.fileToUpload = undefined;
                    this.commonService.showToast('success', 'farmer_registration_successful', {});
                    this.renderer.selectRootElement('#nameVal').focus();
                    this.confirmModal = true;
                    //for signature to be reset
                    this.changeEvent = !this.changeEvent;
                  }
                  else {
                    this.confirmModal = true;
                    if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                      this.registerFarmerModal.hide();
                    }
                  }
                },
                (error) => {
                  this.confirmModal = true;
                  if (this.registerFarmerModal) {
                    this.registerFarmerModal.hide();
                  }
                }
              );
            }
          });
        }
        else {
          this.farmerService.registerFarmer(farmerToRegister).subscribe(
            (result) => {
              this.registeringFarmer = false;
              if (result.msg === 'success') {
                //If consent given by farmer (added by KRPT) 
                if(this.signValues && this.signValues.agree && this.signValues.value != ""){
                  this.saveSignValue(this.projectIdRegisteringFarmer, result.data.id)
                }
                result.data.cr_date = new Date();
                if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                  this.registerFarmerModal.hide();
                }
                this.registerFarmerForm.setValue({
                  name: '',
                  age: 18,
                  sex: 3,
                  area: 0,
                  motherTongue:'',
                  levelOfEducation:0,
                  telNumber: '',
                  email:'',
                  photo_path: '',
                  fatherHusbandName: ''
                });
                this.genderChanged(3);
                this.registerFarmerForm.markAsUntouched();
                this.registerFarmerForm.markAsPristine();
                this.farmerAgeSliderValue = 18;
                this.fileToUpload = undefined;
                this.confirmModal = true;
                this.renderer.selectRootElement('#nameVal').focus();                
                //for signature to be reset
                this.changeEvent = !this.changeEvent;
              }
              else {
                this.confirmModal = true;
                if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                  this.registerFarmerModal.hide();
                }
              }
            },
            (error) => {
              this.confirmModal = true;
              if (this.registerFarmerModal) {
                this.registerFarmerModal.hide();
              }
            }
          );
        }
      }
      else {
        const finalObj = {};
        const farmerObj = farmerToRegister;
        Object.keys(farmerObj).forEach(function (key) {
          if (typeof farmerObj[key] === 'object' && farmerObj[key] != null) {
            if (farmerObj[key].length && farmerObj[key].length > 0) {
              //finalObj[key] = {};
              finalObj[key] = farmerObj[key];
            } 
            // else {
            //   finalObj[key] = farmerObj[key];
            // }
          } else {
            finalObj[key] = farmerObj[key];
          }
        });
        const farmObj = new Array();
        finalObj['tmp_id'] = `FA${this.commonService.getRandomNum().toString().slice(0, 10)}_Tab0`;
        finalObj['savetab'] = 'Tab0';
        finalObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
        finalObj['cr_date'] = new Date().getTime();
        finalObj['upd_date'] = new Date().getTime();
        finalObj['id'] = 0;
        finalObj['total_questions'] = null;
        finalObj['mandatory_questions'] = null;
        finalObj['filled_questions'] = null;
        finalObj['filled_mandatory'] = null;
        // added for signature offline storage
        if(this.signValues && this.signValues.agree && this.signValues.value != ""){
          finalObj['consentPending'] = false;
          finalObj['valid_upto'] = 730;
        } else {
          finalObj['consentPending'] = true;
          finalObj['valid_upto'] = null;
        }
        farmObj[farmObj.length] = finalObj;

        idbApp.addStoreInfo(farmObj, 'farmerstmp');
        if (this.farmerImageData) {
          const farmPhotoObj = {};
          farmPhotoObj['tmp_id']  = finalObj['tmp_id'];
          farmPhotoObj['randnum'] = finalObj['randnum'];
          farmPhotoObj['farmerImageData'] = this.farmerImageData;
          farmPhotoObj['savetab'] = 'Tab0';
          farmPhotoObj['cr_date'] = Date.now();
          farmPhotoObj['farmer_id'] = 0;
          idbAppPhoto.addStoreInfo([farmPhotoObj], 'farmersPhototmp');
        }

        // added for signature offline storage
        if(this.signValues && this.signValues.agree && this.signValues.value != ""){
          const farmSignatureObj = {};
          const curProject = this.projects.find(item => item.id == this.projectIdRegisteringFarmer);
          
          const err_msg = {
            "farmer_consent_template_id":curProject.consentTemplateId,
            "project_id":this.projectIdRegisteringFarmer,
            "user_id":this.currentUser.id
          }
          farmSignatureObj['tmp_id']  = finalObj['tmp_id'];
          farmSignatureObj['randnum'] = finalObj['randnum'];
          farmSignatureObj['farmerImageData'] = this.signValues.value;
          farmSignatureObj['savetab'] = 'Tab0';
          farmSignatureObj['cr_date'] = Date.now();
          farmSignatureObj['farmer_id'] = 0;
          farmSignatureObj['photo_type'] = "signature";
          farmSignatureObj['error_msg'] = err_msg;
          idbAppPhoto.addStoreInfo([farmSignatureObj], 'farmerSignaturetmp');
        }
        this.commonService.showToast('info', 'offline_sync_later', {}); /* this.toastrService.info('You are offline. Data will be synced once you are back online.'); */
        this.signEmpty = false;
        if(this.signValues){
          this.signValues.agree = false;
          this.signValues.value = "";
        }
        //for signature to be reset
        this.changeEvent = !this.changeEvent;
        this.genderChanged(3);
        this.registerFarmerForm.markAsPristine();
        this.farmerAgeSliderValue = 18;
        this.farmerImageData = undefined;
        this.registerSyncEvent();

        farmerToRegister['cr_date'] = new Date();
        farmerToRegister['tmp_id'] = finalObj['tmp_id'];
        if (!this.registerAnotherFarmer && this.registerFarmerModal) {
          this.registerFarmerModal.hide();
        }
        this.registerFarmerForm.setValue({
          name: '',
          age: 18,
          sex: 3,
          area: 0,
          motherTongue:'',
          levelOfEducation:0,
          telNumber: '',
          email:'',
          photo_path: '',
          fatherHusbandName: ''

        });
        this.registerFarmerForm.markAsUntouched();
        this.registerFarmerForm.markAsPristine();
        this.renderer.selectRootElement('#nameVal').focus();
        this.farmerAgeSliderValue = 18;
        this.registeringFarmer = false;
        this.confirmModal = true;
      }
      this.uploadingFileName = undefined;

  }

  saveSignValue(projectId, farmerId){
    this.spinner.show();
    const data = {
      "consent_sign":this.signValues.value
    }
    this.dataConsentService.saveFarmerConsent(projectId, farmerId, data).subscribe(
      (result) => {
        this.spinner.hide();
        this.signEmpty = false;
        this.signValues.agree = false;
        this.signValues.value = "";
      },
      (error) => {
        this.spinner.hide();
        this.signEmpty = false;
        this.signValues.agree = false;
        this.signValues.value = "";
      }
    );
  }

  registerSyncEvent() {
    navigator.serviceWorker.register('/service-worker.js', {
      scope: './'
    })
    .then(function (reg) {
      reg.sync.register('oneTimeSync');
    })
    .catch(function (error) {
    });
  }

  declineRegisterFarmerPrompt(form) : void {
    if (form.dirty) {
      this.confirmModal = false;
    } else {
      this.declineRegisterFarmer();
    }
  }

  declineRegisterFarmer() : void {
    if (this.registerFarmerModal) {
      this.registerFarmerModal.hide();
    }
    this.registerFarmerForm.setValue({
      name: '',
      age: 18,
      sex: 3,
      area: 0,
      motherTongue:'',
      levelOfEducation:0,
      telNumber: '',
      email:'',
      photo_path: '',
      fatherHusbandName: ''
    });
    this.registerFarmerForm.markAsUntouched();
    this.registerFarmerForm.markAsPristine();
    this.farmerAgeSliderValue = 18;
    this.uploadingFileName = undefined;
    setTimeout(() => this.confirmModal = true, 1000);
  }

  handleFileInput(file: FileList) {

    const fileItem = file.item(0);

    this.uploadingFileName = fileItem.name;

    const msg = this.commonService.ValidateFile(fileItem);

    if (msg && msg.length > 0) {
      this.commonService.showToast('warning', msg, {});
      return;
    }

    // this.fileToUpload = fileItem;
    // this.farmer.photo_path = fileItem.name;

    // const reader = new FileReader();
    // reader.onload = (event: any) => {
    //   // this.imageUrl = event.target.result;
    //   this.farmerImageData = null;
    //   let imageData = event.target.result;
    //   imageData = new Uint8Array(imageData);
    //   imageData = new Blob( [ imageData ], { type: "image/jpeg" } );

    //   let setFarmerImageData = (result) => { 
    //     this.commonService.blobToBase64(result).then((imageData)=>{
    //       this.farmerImageData = imageData;
    //     });
    //   };

    //   new Compressor(imageData, {
    //     quality: 0.6,
    //     success(result) {
    //       setFarmerImageData(result);
    //     },
    //     error(err) {
    //       console.log(err.message);
    //     },
    //   });
    // };
    // // reader.readAsDataURL(this.fileToUpload);
    // reader.readAsArrayBuffer(this.fileToUpload);

    new Compressor(fileItem, {
          quality: environment.imageCompress.quality,
          maxWidth:environment.imageCompress.maxWidth,
          maxHeight:environment.imageCompress.maxHeight,
          mimeType:environment.imageCompress.mimeType,
          convertSize:environment.imageCompress.convertSize,
          success(result) {
            setFarmerImageData(result);
          },
          error(err) {
            console.log(err.message);
          },
        });
    
        let setFarmerImageData = (result) => {
          this.fileToUpload = result;
          this.commonService.blobToBase64(result).then((imageData)=>{
            this.farmerImageData = imageData;
          });
      };
    
  }

  genderChanged(gender) : void {
    this.registerFarmerForm.patchValue({
      sex: gender
    });
    this.registerFarmerGender = gender;
  }

  handleServiceClick(service, project) : void {

    if (service.click_action == 'modal') {
      this.setVillageAreaList(project);
      this.openRegisterFarmerModal(this[`${service.click_action_value}Template`], project.id);
    }
    else if (service.click_action == 'router') {
      if(navigator.onLine){
        const url = `/${service.click_action_value}/${project.id}`;
        this.router.navigate([url]);
      }else{
        if(service.id === 5 || service.id === 4 || service.id === 7 || service.id === 8){
          this.toastr.warning(this.notAvailableWhenOfflineTxt);
        }else{
          const url = `/${service.click_action_value}/${project.id}`;
          this.router.navigate([url]);
        }
      }
    }

    

  }

  confirmModalFn(what) : void {
    if (what == 'Yes') {
      this.declineRegisterFarmer();
    }
    else {
      this.confirmModal = true;
    }
  }

  // When the slide is moved, patch the selected value in the form
  setAgeValue(event) : void {
    this.registerFarmerForm.patchValue({
      age: this.farmerAgeSliderValue
    });
    this.registerFarmerForm.markAsDirty();
  }

  scrollRight(id) : void {
    const x = document.getElementById(id);
    x.scrollTo({ left: (x.scrollLeft + 150), behavior: 'smooth'});
  }

  scrollLeft(id) : void {
    const x = document.getElementById(id);
    x.scrollTo({ left: (x.scrollLeft - 150), behavior: 'smooth' });
  }

  setVillageAreaList(project): void {
    if (project.ecosystem_region_master) {
      this.villageAreaList = project.ecosystem_region_master.region_villages;
    }
  }

async loadDetailedProjectData() {
  this.startTime = performance.now();
  if (this.projects && this.projects.length > 0) {
    for (let i = 0; i < this.projects.length; i++) {
      try {
        const request = await Promise.all([
          this.projectService.getProjectBasicInfo(this.projects[i].id).toPromise(),
          this.projectService.getProjectDashboardData(this.projects[i].id).toPromise(),
          this.projectService.getProjectProfilingData(this.projects[i].id).toPromise(),
          this.farmerService.getFarmCoordinates(this.projects[i].id).toPromise(),
          this.farmerService.getEstimatedPotentialValue(this.projects[i].id).toPromise(),
          //this.farmerService.getFarmersByProjectBasicData(this.projects[i].id).toPromise(),
          //this.dataConsentService.getFarmerConsents(this.projects[i].id).toPromise(),
          !this.consentTemplateAvailable ? this.dataConsentService.getConsentTemplate(this.projects[i].id).toPromise() : ''
        ]);
  
        if (request[0]['msg'] != 'success' 
        || request[1]['msg'] != 'success' 
        || request[2]['msg'] != 'success' 
        || request[3]['msg'] != 'success' 
        || request[4]['msg'] != 'success'
        ) {
          this.commonService.showToast('error', 'generic_error', {});
        } else {
          const projectBasicInfo = request[0].data[0];
          const projectDashboardData = request[1].data[0];
          const projectProfilingData = request[2].data[0];
          const projectConsentTemplateData = request[5].data;
          
          if (projectBasicInfo) {
            this.projects[i]['description'] = projectBasicInfo['description'];
            this.projects[i]['start_date'] = projectBasicInfo['start_date'];
            this.projects[i]['Product'] = projectBasicInfo['Product'];
            this.projects[i]['Supplier'] = projectBasicInfo['Supplier'];
            this.projects[i]['MultiValueMaster'] = projectBasicInfo['MultiValueMaster'];
            this.projects[i]['ProjectStatus'] = projectBasicInfo['ProjectStatus'];
            this.projects[i]['Country'] = projectBasicInfo['Country'];
            this.projects[i]['ecosystem'] = projectBasicInfo['ecosystem'];
          }
          
          if (projectDashboardData) {
            this.projects[i]['ecosystem_services_components_mapping'] = projectDashboardData['ecosystem_services_components_mapping'];
            this.projects[i]['ecosystem_region_master'] = projectDashboardData['ecosystem_region_master'];
            this.projects[i]['farmerCount'] = projectDashboardData['farmerCount'];
            this.projects[i]['ProjectMapping'] = projectDashboardData['ProjectMapping'];
            this.projects[i]['supplier_project_services_components_mapping'] = projectDashboardData['supplier_project_services_components_mapping'];
          }
  
          if (projectProfilingData) {
            this.projects[i]['QuesProjectSnapshotMapping'] = projectProfilingData['QuesProjectSnapshotMapping'];
            this.projects[i]['ProjectUOM'] = projectProfilingData['ProjectUOM'];
          }

          if(projectConsentTemplateData){
            this.consentTemplateAvailable = true;
            this.consentTemplateData = projectConsentTemplateData;
          }          
          this.projects[i]['consentTemplateId'] = this.consentTemplateData.id;
          this.projects[i]['consentTemplate'] = this.sanitizer.bypassSecurityTrustHtml(this.consentTemplateData.template_content);
        }
        this.setProjectServices(i);
      } catch (err) {
        // console.log('858', err);
        this.setProjectServices(i);
      }
      //check if campaign service is assigned to this user
      // let isCampaignService = false;
      // if(this.projects[i].displayedServices){
      //   for(const service of this.projects[i].displayedServices){
      //     if(service.id == 6){
      //       isCampaignService = true;
      //     }
      //   }
      // }
      // const request2 = await Promise.all([
      //   this.surveyService.getSurveyQuestionnaireForProject(this.projects[i].id).toPromise(),
      //   this.surveyService.getAllSurveysForProject(this.projects[i].id).toPromise(),
      //   this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projects[i].id).toPromise(),
      //   isCampaignService ? this.campaignService.getCampaignsForProject(this.projects[i].id).toPromise() : ''
      // ])
      // .then(async x => {
      //   //added by KRPT for getting active survey ID
      //   let activeSurveyId;
      //   if (x[1]['code'] === 'success' && x[1]['message'].length > 0) {
      //     let activeSnapshot;
      //     const snapshotSurveys = x[1]['message'].filter(item => item.survey_type == 'snapshot');
      //     if (snapshotSurveys && snapshotSurveys.length > 0) {
      //       activeSnapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
            
      //       if(activeSnapshot) {
      //         activeSurveyId = activeSnapshot.id;
      //       }
      //     }
      //   }
      //   if (x[3]['code'] === 'success') {
      //     let request3;
      //     for (let k = 0; k < x[3]['message'].length; k++) {
      //       if(x[3]['message'][k].closed_date == null){
      //         request3 = await Promise.all([
      //           this.surveyService.getSurveyQuestionnaireForCampaign(x[3]['message'][k].id).toPromise(),
      //           this.campaignService.getFarmersMappedToCampaign(x[3]['message'][k].id).toPromise(),
      //           this.dynamicQuestionnaireService.getAnswerStatsForCampaign(x[3]['message'][k].id).toPromise()
      //         ]);
      //       } else {
      //         continue;
      //       }
      //     }
      //     await this.loadPaginationLimits(this.projects[i].id, x[3]['message'], activeSurveyId);
      //   } else {
      //     await this.loadPaginationLimits(this.projects[i].id, null, activeSurveyId);
      //   }
      //   this.projects[i]['loadedDisplayedServices'] = true;
      // })
      // .catch(err => {
      //   this.loadPaginationLimits(this.projects[i].id, null);
      // });
      this.projects[i]['loadedDisplayedServices'] = true;
    }
      // this.showCacheMessage1 = true;
      // setTimeout(() => this.showDataCachedMessage(), 100); //5000
      // this.setDashboardData();
    } 
    // else {
    //   this.showCacheMessage1 = true;
    //   this.showCacheMessage2 = true;
    //   this.showDataCachedMessage();
    //   this.setDashboardData();
    // }
  }

  setDashboardData(): void {
    this.commonService.setProjectList(this.projects);

    this.registerFarmerForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required,Validators.maxLength(50)]),
      age: new FormControl(18, [Validators.required, Validators.min(18), Validators.max(99)]),
      sex: new FormControl(3, [Validators.required]),
      area: new FormControl(0, [Validators.required,Validators.min(1)]),
      motherTongue: new FormControl('', [Validators.required,Validators.maxLength(100)]),
      levelOfEducation: new FormControl(0),
      email: new FormControl('', [Validators.email]),
      telNumber: new FormControl('', [Validators.pattern("^[0-9]{5,13}$"),
      Validators.minLength(5),Validators.maxLength(13)]),
      photo_path: new FormControl(''),
      fatherHusbandName: new FormControl('', [Validators.maxLength(50)])
    });

    if (this.projects && this.projects.length == 0) {
      this.noProjectsMsg = true;
    }

    // make the non-critical API calls to fetch and cache this data (moved these out of dashboard.resolver.ts)
    if (this.currentUser.role.id < 4){
      this.commonService.getProjectManager().subscribe(result => {/*console.log(result)*/});
    }

  }
  
  setProjectServices(i): void {
    // console.log('setProjectServices called for', this.projects[i]);
    this.loadedProjects  = true;
    let productItem;
    let managerItem
    productItem = this.productType ? this.productType.filter(item => item.id == this.projects[i].product_type)[0] : undefined;
      if (productItem) {
        this.projects[i]['product_type_name'] = productItem.name;
      }
      else {
        this.projects[i]['product_type_name'] = "NA";
      }

      managerItem = this.managers ? this.managers.filter(item => item.id == this.projects[i].project_manager)[0] : undefined;
      if(managerItem) {
        this.projects[i]['manager_name'] = managerItem.name;
      }
      else {
        if(this.projects[i]['MultiValueMaster'] != null || this.projects[i]['MultiValueMaster'] != undefined){
          this.projects[i]['manager_name'] = this.projects[i]['MultiValueMaster']['name'];
        }else{
          this.projects[i]['manager_name'] = "NA";
        }
        
      }
      // For non-admin roles, define the services displayed array based on the services mapped to this project
      if ((this.currentUser.role.id > 3) && this.projects[i]['supplier_project_services_components_mapping'] && this.projects[i]['supplier_project_services_components_mapping'].length > 0) {
        const displayedServicesTemp = [];
        for (let j = 0; j < this.projects[i]['supplier_project_services_components_mapping'].length;j++) {
          displayedServicesTemp.push(this.projects[i]['supplier_project_services_components_mapping'][j]['services']);
          if (this.projects[i]['supplier_project_services_components_mapping'][j]['services_components_mapping']){
            displayedServicesTemp.push(this.projects[i]['supplier_project_services_components_mapping'][j]['services_components_mapping']['services']);
          }
        }

        const displayedServicesUnique = displayedServicesTemp.reduce((unique, o) => {
          if(!unique.some(obj => obj.code === o.code)) {
              unique.push(o);
            }
            return unique;
        },[]);

        displayedServicesUnique.sort((a, b) => {
          if (a.sequence < b.sequence) {
            return -1;
          }
          if (a.sequence > b.sequence) {
            return 1;
          }
          return 0;
        })
        // console.log('displayedServicesUnique', displayedServicesUnique);
        //this.projects[i]['loadedDisplayedServices'] = true;
        this.projects[i]['displayedServices'] = displayedServicesUnique;
      }

      // For admin roles, define the services displayed array based on the services mapped to the ecosystem - whether or not mapped to the project
      else if ((this.currentUser.role.id < 4) && this.projects[i]['ecosystem_services_components_mapping'] && this.projects[i]['ecosystem_services_components_mapping'].length > 0) {
        const displayedServicesTemp = [];
        for (let j = 0; j < this.projects[i]['ecosystem_services_components_mapping'].length;j++) {
          displayedServicesTemp.push(this.projects[i]['ecosystem_services_components_mapping'][j]['services']);
          if (this.projects[i]['ecosystem_services_components_mapping'][j]['services_components_mapping']){
            displayedServicesTemp.push(this.projects[i]['ecosystem_services_components_mapping'][j]['services_components_mapping']['services']);
          }
        }

        const displayedServicesUnique = displayedServicesTemp.reduce((unique, o) => {
          if(!unique.some(obj => obj.code === o.code)) {
              unique.push(o);
            }
            return unique;
        },[]);

        displayedServicesUnique.sort((a, b) => {
          if (a.sequence < b.sequence) {
            return -1;
          }
          if (a.sequence > b.sequence) {
            return 1;
          }
          return 0;
        });
        this.projects[i]['displayedServices'] = displayedServicesUnique;
        //this.projects[i]['loadedDisplayedServices'] = true;
      } else if ((this.currentUser.role.id > 3) && (!this.projects[i]['supplier_project_services_components_mapping'] || this.projects[i]['supplier_project_services_components_mapping'].length == 0)) {
        this.projects[i]['displayedServices'] = null;
        //this.projects[i]['loadedDisplayedServices'] = true;
      }
  }

  showDataCachedMessage(): void {
    if (this.showCacheMessage1 && this.showCacheMessage2) {
      this.endTime = performance.now();
      this.toastr.clear();
      setTimeout(() => this.commonService.showToast('success', 'data_cached_successfully', {preventDuplicates: true}), 100);
      this.spinner.hide();
    }
  }

  async syncDone(args) {
    if (navigator.onLine) {
      window.dispatchEvent(new Event('caching-now'));
      if (this.projects && this.projects.length > 0) {
        for (let i = 0; i < this.projects.length; i++) {
          const request = await Promise.all([
            this.farmerService.getFarmersByProjectBasicData(this.projects[i].id).toPromise(),
            // this.dynamicQuestionnaireService.getQAnswersForProject(this.projects[i].id, null).toPromise(),
            this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projects[i].id).toPromise()
          ]);
          
          await Promise.all([
            this.dynamicQuestionnaireService.getPaginationLimits(this.projects[i].id).toPromise()
          ])
          .then(result => {
            if (result[0]['code'] === "success") {
              for (let i = 0; i < result[0]['message'].length; i++) {
                this.loadFarmerDataIntoCache(this.projects[i].id, result[0]['message'][i], result[0]['message'][i+1]);
              }
            }
          }).catch(e => {});
        } 
        window.dispatchEvent(new Event('data-synced'));
      }
    }
  }

  async displayProjects() {
    //if (navigator.onLine) this.spinner.show();
    if (!this.pId) {
      this.projectService.getUsersProjectIds().pipe(takeUntil(this.unsubscribe))
      .subscribe(
        async (project) => {
          if (project.msg === 'success') {
            // the following userType based filters are required for offline mode in case multiple ecosystem projects are stored in the cache
            // if system admin, all projects to be displayed
            if (this.currentUser.role.id == 1) {
              this.projects = project.data;
            }
            // if P2F-Roots admin, all projects to be displayed in View All mode, otherwise only show projects in selected ecosystem
            else if (this.currentUser.role.id == 2) {
              if (this.ecosystemScopeId == 0) this.projects = project.data;
              else this.projects = project.data.filter(item => item.ecosystem_id == this.ecosystemScopeId);
            }
            // if project admin, show projects in the selected ecosystem only
            else if (this.currentUser.role.id == 3) {
              this.projects = project.data.filter(item => item.ecosystem_id == this.ecosystemScopeId);
            }
            // in all other cases, show all projects
            else {
              this.projects = project.data;
            }
            await this.loadDetailedProjectData();
            this.loadedProjects  = true;
            this.cacheProjectsData();
              //descendic()
          } else if (project.success == false) {
            this.commonService.showToast('error', 'relogin', {});
            setTimeout(() => {this.toastr.clear()}, 5000)
          }
        },
        (error) => {
          console.error(error);
        });
    }
      else {
        this.projects = [{id: this.pId, name: this.searchText, ecosystem_id: this.ecosystemScopeId}];
        await this.loadDetailedProjectData();
        this.loadedProjects  = true;
        this.cacheProjectsData();
      }
  }
  async cacheProjectsData(){
    let count = 0;
    this.totalProjects = this.projects.length;
    if (this.projects && this.projects.length > 0) {
      this.projectsLoaded = 0;
      this.randomNumForLog = Math.floor(100000 + Math.random() * 900000);
      for(let item of this.projects) {
        //check if campaign service is assigned to this user
        count++;
        let isCampaignService = false;
        if(item.displayedServices){
          for(const service of item.displayedServices){
            if(service.id == 6){
              isCampaignService = true;
            }
          }
        }
        const request = await Promise.all([
          this.farmerService.getFarmersByProjectBasicData(item.id).toPromise(),
          this.dataConsentService.getFarmerConsents(item.id).toPromise(),
          this.surveyService.getSurveyQuestionnaireForProject(item.id).toPromise(),
          this.surveyService.getAllSurveysForProject(item.id).toPromise(),
          this.dynamicQuestionnaireService.getAnswerStatsForProject(item.id).toPromise(),
          isCampaignService ? this.campaignService.getCampaignsForProject(item.id).toPromise() : ''
        ])
        .then(async x => {
          //added by KRPT for getting active survey ID
          let activeSurveyId;
          if (x[3]['code'] === 'success' && x[3]['message'].length > 0) {
            let activeSnapshot;
            const snapshotSurveys = x[3]['message'].filter(item => item.survey_type == 'snapshot');
            if (snapshotSurveys && snapshotSurveys.length > 0) {
              activeSnapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
              
              if(activeSnapshot) {
                activeSurveyId = activeSnapshot.id;
                
                if(activeSnapshot.flex4){
                  const docUrl = `${environment.apiBaseUrl}/${this.loc.prepareExternalUrl('').replace(/\//g, '').substr(0, 2)}/assets/uploads/surveyManual/${activeSnapshot.flex4}`;
                  console.log("docUrl:", docUrl);
                  this.http
                    .get(docUrl, {
                      responseType: 'blob',
                    })
                    .subscribe(response => { });
                }
              }
            }

            const campaignSurveys = x[3]['message'].filter(item => item.survey_type == 'campaign');
            if (campaignSurveys && campaignSurveys.length > 0) {
              for (let i = 0, activeCampaign = null; i < campaignSurveys.length; i++, activeCampaign = null) {
                activeCampaign = campaignSurveys[i]['survey_stage_changelogs'][0]['change_type'] == 'active' ? campaignSurveys[i] : undefined;
                if (activeCampaign) {
                  if (activeCampaign.flex4) {
                    const docUrl = `${environment.apiBaseUrl}/${this.loc.prepareExternalUrl('').replace(/\//g, '').substr(0, 2)}/assets/uploads/surveyManual/${activeCampaign.flex4}`;
                    this.http
                      .get(docUrl, {
                        responseType: 'blob',
                      })
                      .subscribe(response => { });
                  }
                }
              }
            }
          }
          if (x[5]['code'] === 'success') {
            let request3;
            for (let k = 0; k < x[5]['message'].length; k++) {
              if(x[5]['message'][k].closed_date == null){
                request3 = await Promise.all([
                  this.surveyService.getSurveyQuestionnaireForCampaign(x[5]['message'][k].id).toPromise(),
                  this.campaignService.getFarmersMappedToCampaign(x[5]['message'][k].id).toPromise(),
                  this.dynamicQuestionnaireService.getAnswerStatsForCampaign(x[5]['message'][k].id).toPromise()
                ]);
              } else {
                continue;
              }
              const campaignSurveys = x[3]['message'].filter(item => item.survey_type == 'campaign');
              const thisCampaignSurveys = campaignSurveys.filter(item => item.flex1 == x[5]['message'][k].id);
              if(thisCampaignSurveys && thisCampaignSurveys.length > 0){
                const activeSurvey = thisCampaignSurveys.find(item => item.survey_stage_changelogs[0].change_type == 'active');
                if(activeSurvey){
                  x[5]['message'][k]['activeSurvey'] = true;
                }
              }
            }
            await this.loadPaginationLimits(item.id, x[5]['message'], activeSurveyId);
          } else {
            await this.loadPaginationLimits(item.id, null, activeSurveyId);
          }
          
          this.ecosystemId = localStorage.getItem('ecosystem_id') ? localStorage.getItem('ecosystem_id') : "NA";
          this.projectsLoaded = this.projectsLoaded + 1;
          this.currentPerc = parseFloat(((this.projectsLoaded/this.totalProjects)*100).toFixed(2));
          
          // Push user log info to backend. The params such as device info, user info and time taken to cache data in secs are passing to backend
          this.commonService.updateUserLog(this.deviceInfoObj, this.currentUser.id, this.randomNumForLog, this.totalProjects, this.projectsLoaded, this.currentPerc, this.ecosystemId, parseInt(item.id)).subscribe((message)=>{
            console.table(message);
          });


          item['readyForOffline'] = true;
          //added by KRPT to calculated percentage completion
          this.percCompletion =  (count * 100) / this.projects.length;
          if(!Number.isInteger(this.percCompletion)){
            this.percCompletion = parseFloat(this.percCompletion.toFixed(1));
          }
        })
        .catch(err => {
          this.loadPaginationLimits(item.id, null);
        });    
      }
      this.showCacheMessage1 = true;
      setTimeout(() => this.showDataCachedMessage(), 100); //5000
      this.setDashboardData();
    } else {
      this.showCacheMessage1 = true;
      this.showCacheMessage2 = true;
      this.showDataCachedMessage();
      this.setDashboardData();
    }
  }

  async loadFarmerDataIntoCache(projectId, gte, lt) {
    if (projectId && projectId > 0) {  
      await Promise.all([
        this.dynamicQuestionnaireService.getQAnswersForProjectPaginated(projectId, gte, lt).toPromise()
      ])
      .then(result => {
        // console.log('result', result);
      }).catch(e => {});
    }
  }

  async loadFarmerDataIntoCacheDashboard(projectId, gte, lt, surveyQIds) {
    if (projectId && projectId > 0) {  
      await Promise.all([
        this.dynamicQuestionnaireService.getQAnswersForProjectPaginatedDashboard(projectId, gte, lt, surveyQIds).toPromise()
      ])
      .then(result => {
        // console.log('result', result);
      }).catch(e => {});
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      // rest of your code
    }
  }

  async loadPaginationLimits(projectId, campaigns, activeSurveyId?) {
    if (projectId && projectId > 0) {
      await Promise.all([
        this.dynamicQuestionnaireService.getPaginationLimits(projectId).toPromise(),
        activeSurveyId ? this.dynamicQuestionnaireService.getSurveyQAssetIdsDashboard(activeSurveyId).toPromise() : '',
        this.farmerService.getFarmerAvailibility(projectId).toPromise()
      ])
      .then(result => {
        let surveyQIds = [];
        if (result[1]['code'] === 'success'){
          surveyQIds = result[1]['message'];
        }
        if (result[0]['code'] === "success") {
          for (let i = 0; i < result[0]['message'].length; i++) {
            this.loadFarmerDataIntoCacheDashboard(projectId, result[0]['message'][i], result[0]['message'][i+1], surveyQIds);
            //this.loadFarmerDataIntoCache(projectId, result[0]['message'][i], result[0]['message'][i+1]);
          }

          if (campaigns && campaigns.length > 0) {
            for (let i = 0; i < campaigns.length; i++) {
             // this.loadFarmerCampaignDataIntoCache(campaigns[i].id, result[0]['message'][i], result[0]['message'][i+1]);
             for (let ij = 0; ij < result[0]['message'].length; ij++) {
              if((campaigns[i].closed_date === null || campaigns[i].closed_date === "" || !campaigns[i].closed_date) && campaigns[i].activeSurvey){
                this.loadFarmerCampaignDataIntoCache(campaigns[i].id, result[0]['message'][ij], result[0]['message'][ij+1]);
              }
             }
            }
          }

        }
      }).catch(e => {});
    }    
  }


  async loadFarmerCampaignDataIntoCache(campaignId, gte, lt) {
    if (campaignId && campaignId > 0) {
      await Promise.all([
        this.dynamicQuestionnaireService.getQAnswersForCampaignPaginated(campaignId, gte, lt).toPromise()
      ])
      .then(result => {
      }).catch(e => {});
    }
  }  

  getConsentData(data) {
    if(data.agree && data.value != ""){
      this.signValues = data;
      this.signEmpty = false;
    }
    if((data.agree && data.value == "") || (!data.agree && data.value != "")){
      this.signEmpty = true;
    }
    if(!data.agree && data.value == ""){
      this.signEmpty = false;
    }
  }

  //for ecosystem consolidation navigation
  async navigateEcosytemConsolidation(){
    // const url = `/consolidatedreportecosystem/${this.ecosystemScopeId}`;
    // this.router.navigate([url]);
    this.spinner.show();
    if (this.projects && this.projects.length > 0) {
      let allecoData;
      const request = await Promise.all([
        this.consReportService.getEcosystemConsolidationTypeData(this.ecosystemScopeId).toPromise()
      ])
      if(request[0].code == "success"){
        allecoData = request[0].message;
      }
      var wb = XLSX.utils.book_new();
      //console.log('this.projects', this.projects[0]);
      for (let i = 0; i < this.projects.length; i++) {
        const projData = allecoData.filter(item => item.project_id == this.projects[i].id);
        if (projData && projData.length > 0) {
          const typeSelectedQuestions = [];
          for(const item of projData){
            let obj = {
              'qid': item.questionnaire_asset_id,
              'type': item.cons_type,
              'not_required': item.not_required
            }
            typeSelectedQuestions.push(obj);
          }
          if(typeSelectedQuestions.length > 0){
            const newRequest = {
              "questions":typeSelectedQuestions
            };
            const requests = await Promise.all([
              this.consReportService.getProjectConsolidatedReport(this.projects[i].id, newRequest).toPromise()
            ])  
            const finalReportArr = [];
            for(let item2 of requests[0].message){
              let obj = Object.assign({ "Project Name": this.projects[i].name }, item2);
              //console.log('obj', obj);
              finalReportArr.push(obj)
            }
            //console.log('requests[0].message', requests[0].message);
            var wsBooks = XLSX.utils.json_to_sheet(finalReportArr);
            var wscols = [
              {wpx: 150},
              {wpx: 80}, // "pixels
              {wpx: 120},
              {wpx: 120},
              {wpx: 300},
              {wpx: 120},
              {wpx: 120},
              {wpx: 150},
              {wpx: 200},
              {wpx: 200},
              {wpx: 120}
            ];      
            wsBooks['!cols'] = wscols;
            const sheetName = `Project_${this.projects[i].id}`;
            XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);             
            
          }
        } else {
          var wsBooks = XLSX.utils.json_to_sheet([]);
          const sheetName = `Project_${this.projects[i].id}`;
          XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);        
        }
      }
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      let linkdownload = document.createElement('a');
      linkdownload.href = URL.createObjectURL(blob);
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      const today1 = dd + '-' + mm + '-' + yyyy;
      linkdownload.download = 'P2F-Roots_Consolidated_Report_Ecosystem_' + this.ecosystemScopeId + '_'  + today1 + '.xlsx';
      //linkdownload.download = `P2F-Roots_Consolidated_Report_${this.projects[0].Supplier.name}-${this.projects[0].Product.name}.xlsx`;
      linkdownload.style.display = 'none';
      document.body.appendChild(linkdownload);
      linkdownload.click();
      linkdownload.remove();
      this.toastr.success("Excel Downloaded");
      this.spinner.hide();
    } else {
      this.toastr.success("Project not found");
      this.spinner.hide();
    }
  }

  //check if farmername contains spaces only
  checkEmptyness(e){    
    const name = this.registerFarmerForm['value'].name;
    if (!name.replace(/\s/g, '').length) {
      this.registeringFarmer = true;
    } else {
      this.registeringFarmer = false;
    }
  }

  showOverview(e){
    document.querySelector('.peoject-img-list').classList.add('show');
  }

}
