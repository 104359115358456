<app-header></app-header>
<div class="project-header clearfix">

  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" i18n="@@projectHome">Project Home</a>
      </li>
      <li class="breadcrumb-item active cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
          <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item active cursorPointer" routerLink="/profiling/{{project?.id}}" aria-current="page">
        <span i18n-title="@@projectProfiling" title="PROJECT PROFILING">...</span>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        <span i18n="@@farmerProfileDatasheet">Farmer Profile Datasheet</span>
      </li>
    </ol>
  </nav>
  <ol class="breadcrumb posTopLeft" style="float:right; margin-top: 4px; margin-right:10px;">
    <li class="cursorPointer" aria-current="page">
      <span class="btn-back ml-3" title="Back" routerLink="/profiling/{{project?.id}}" aria-current="page">
        <i class="utilicon utilicon-arrow-alt-left-light"></i>
      </span>
    </li>
  </ol>
  <div class="col-md-3 fltRgt print-projectinfo datatable-header">
  </div>
</div>
<!-- <pre>
  {{qAnswers|json}}
</pre> -->
<div class="content-wrap content-wrapper view-profile">
  <div class="form-design">
    <div class="form-box">
      <div class="farmer-profile-div clearfix">
        <div class="tab-control-div" id="mainDiv">
          <div class="farmer-tab-content">
            <ul *ngIf="questionnaire && questionnaire != 'noSurveys'" class="nav nav-pills fixed-tabs" id="pills-tab2" role="tablist">
              <li class="nav-item">
                  <a class="nav-link" data-toggle="pill" [href]="'#' + farmerDetailsTabIndex" id="pills-fp-tab"  [ngClass]="{'active': currentTabIndex == farmerDetailsTabIndex}"
                  (click)="tabClicked(farmerDetailsTabIndex,true)"  role="tab" aria-selected="true">Farmer Details</a>
              </li>
              <li class="nav-item" *ngFor="let tab of questionnaire; let i = index">
                <a class="nav-link" [ngClass]="{'active': currentTab?.id ==tab?.id && currentTabIndex != farmerDetailsTabIndex}" id="pills-fp-tab" data-toggle="pill"
                  [href]="'#' + tab?.id" role="tab" aria-selected="true" (click)="tabClicked(i,false)">
                  {{tab?.survey_q_asset_labels[0]?.label}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div class="tab-pane" [id]="farmerDetailsTabIndex" [ngClass]="{'active': currentTabIndex == farmerDetailsTabIndex}" *ngIf="currentTabIndex == farmerDetailsTabIndex"> 
            <div class="view-profile-data mt-3">
                <h3 _ngcontent-c5="" class="view-profile-title">Personal</h3>
              <ul class="clearfix">
                <li *ngFor="let question of farmerRegQuestions">
                  <div *ngIf="question.code !='photo_path'" class="repeater">
                    <span class="question-area">{{question.ques_text}}</span>
                    <span class="asnwer-area" *ngIf="question.ques_type!='select'">{{farmer[question.code]}}&nbsp;</span>
                    <span class="asnwer-area" *ngIf="question.ques_type=='select' && question.code!='village_area_id'">{{getMasterData(question.code,farmer[question.code])}}&nbsp;</span>
                    <span class="asnwer-area" *ngIf="question.code=='village_area_id'">{{farmer?.region_village?.village_name}}&nbsp;</span>
                  </div>
                  <div *ngIf="question.code =='photo_path'" class="repeater">
                    <span class="question-area">{{question.ques_text}}</span>
                    <span class="asnwer-area" *ngIf="farmer[question.code]">Present &nbsp;</span>
                    <span class="asnwer-area" *ngIf="!farmer[question.code]">Not Present &nbsp;</span>
                  </div>
                </li>
              </ul>
            </div>
        </div>
        <div class="tab-pane active" [id]="currentTab?.id" *ngIf="currentTabIndex != farmerDetailsTabIndex">            
          <div class="row">
            <div class="col-sm-12" *ngFor="let section of currentTab?.sections; let sectionIndex = index;" [ngClass]="{'hide': section.removed_from_snapshot }">
              <div class="view-profile-data">
                <h3 class="view-profile-title">{{section?.survey_q_asset_labels[0]?.label}}</h3>
                <ul class="clearfix">
                  <ng-container *ngFor="let question of section?.questions">
                    <li>
                        <span class="question-area">{{question?.survey_q_asset_labels[0]?.label}}</span>
                        <span class="asnwer-area">{{question?.answerText}}&nbsp;
                          <span *ngIf="question.include_uom_question">
                            <span *ngFor="let uom of question.uom_questions; let i = index">
                              <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                              {{uom?.answerText}}
                            </span>
                          </span>
                          <span *ngIf="question.other_questions && question.other_questions.length > 0">{{question.otherAnsText}}</span>
                        </span>
                    </li>
                  </ng-container>
                  <ng-container *ngFor="let subsection of section?.subsections">
                    <li>
                      <ul class="clearfix" style="width:100%">
                        <h3 class="view-profile-title mt-0">{{subsection?.survey_q_asset_labels[0]?.label}}</h3>
                        <ng-container *ngFor="let question of subsection?.questions">
                          <li>
                            <span class="question-area">{{question?.survey_q_asset_labels[0]?.label}}</span>
                            <span class="asnwer-area">{{question?.answerText}}&nbsp;
                              <span *ngIf="question.include_uom_question">
                                <span *ngFor="let uom of question.uom_questions; let i = index">
                                  <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                  {{uom?.answerText}}
                                </span>
                              </span>
                              <span *ngIf="question.other_questions && question.other_questions.length > 0">{{question.otherAnsText}}</span>
                            </span>
                          </li>
                        </ng-container>
                      </ul>
                    </li>
                  </ng-container>
                  <ng-container *ngFor="let table of section?.tables">
                    <!-- <li>
                      <ul class="clearfix" style="width:100%">
                        <h3 class="view-profile-title mt-0">{{table?.survey_q_asset_labels[0]?.label}}</h3>
                        <ng-container *ngFor="let question of table?.questions">
                          <li>
                            <span class="question-area">{{question?.survey_q_asset_labels[0]?.label}}</span>
                            <span class="asnwer-area">&nbsp;{{question.id}}</span>
                          </li>
                        </ng-container>
                      </ul>
                    </li> -->
                    <table class="table">
                      <thead class="no-box-shaow">
                        <tr>
                          <th *ngFor="let question of table.questions">
                            <span class="question-area">{{question?.survey_q_asset_labels[0]?.label}}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let key of secondary_asset_keys[table.id]" [id] = "key">
                              <td *ngFor="let question of table.questions" [id] = "question.id"><span class="asnwer-area">
                                  
                                <!-- {{table.id}}--{{question.id}}-- {{key}} -->
                              
                               {{tableQuestionsAnswer[table.id+question.id+key].value}}
                              </span></td>
                          </tr>
                      </tbody>
                    </table>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
