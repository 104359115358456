import * as tslib_1 from "tslib";
import { OnInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
var CommunityLivelihoodComponent = /** @class */ (function () {
    function CommunityLivelihoodComponent(route, projectService, toastrService, router, activeRoute, translate, changeDetectionRef, renderer, hcRService, spinner) {
        this.route = route;
        this.projectService = projectService;
        this.toastrService = toastrService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.translate = translate;
        this.changeDetectionRef = changeDetectionRef;
        this.renderer = renderer;
        this.hcRService = hcRService;
        this.spinner = spinner;
        //@ViewChild('charts3') public chartElement03: ElementRef;
        //@ViewChild('charts4') public chartElement04: ElementRef;
        this.projects = [];
        this.projectId = 0;
        this.dashboardData = [];
        this.reportIDs = [];
        this.predefinedReports = [];
        this.reportWaterAccess = [];
        this.reportCookingSource = [];
        this.reportWasteManagement = [];
        this.reportHousing = [];
        this.reportHealthcare = [];
        this.reportElectricity = [];
    }
    CommunityLivelihoodComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dashboardId = this.route.snapshot.params['id2'];
        this.projectId = this.route.snapshot.params['id'];
        this.hcRService.allCharts = [];
        this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
            _this.project = response.data;
            var projectObject = _this.project.filter(function (item) { return item.id == _this.projectId; })[0];
            _this.project = projectObject;
        });
        var requestObject = {
            //"dashboardId":this.dashboardId
            "dashboardId": this.dashboardId
        };
        this.spinner.show();
        this.hcRService.getDashboardDetailsById(requestObject).subscribe(function (response) {
            //console.log("get Dashboard Data ", response);
            if (response.msg == 'success') {
                _this.dashboardData = response.data;
                if (_this.dashboardData.length > 0) {
                    //console.log("this.dashboardData ", this.dashboardData);
                    var dashboardSelectedReports = [];
                    for (var i = 0; i < _this.dashboardData[0].hc_report_dashboard_dtl.length; i++) {
                        dashboardSelectedReports.push(_this.dashboardData[0].hc_report_dashboard_dtl[i]);
                        //this.dashboardEditObject.section = dashboardData[0].hc_report_dashboard_dtl[0].section;
                    }
                    //console.log("dashboardSelectedReports ", dashboardSelectedReports);
                    if (dashboardSelectedReports && dashboardSelectedReports.length > 0) {
                        for (var i = 0; i < dashboardSelectedReports.length; i++) {
                            _this.reportIDs.push(dashboardSelectedReports[i].report_id);
                        }
                        //console.log(" this.reportIDs ", this.reportIDs);
                        if (_this.reportIDs && _this.reportIDs.length > 0) {
                            _this.genarateCharts(_this.reportIDs);
                        }
                    }
                }
            }
        });
    };
    CommunityLivelihoodComponent.prototype.genarateCharts = function (reportIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requestObject, chartsList;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.spinner.show();
                requestObject = {};
                requestObject = {
                    "reportID": reportIds,
                    "projectId": this.projectId
                };
                chartsList = [];
                this.hcRService.getReports(requestObject).subscribe(function (response) {
                    //console.log("response ", response);
                    if (response.msg == 'success') {
                        var reportsData = response.data;
                        _this.predefinedReports = response.data;
                        //console.log("reportsData ", this.predefinedReports);
                        if (reportsData && reportsData.length > 0) {
                            for (var i = 0; i < reportsData.length; i++) {
                                var seriesArr = [];
                                if (reportsData[i].reportId === 25) {
                                    for (var j = 0; j < reportsData[i].series.length; j++) {
                                        for (var prop in reportsData[i].series[j]) {
                                            if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                                if (Number(reportsData[i].series[0][prop]) > 0) {
                                                    var innerObj = {
                                                        name: '',
                                                        value: Number(''),
                                                    };
                                                    innerObj.name = prop;
                                                    innerObj.value = Number(reportsData[i].series[0][prop]);
                                                    _this.reportWaterAccess.push(innerObj);
                                                }
                                            }
                                        }
                                    }
                                    //console.log("reportWaterAccess ", this.reportWaterAccess);
                                }
                                if (reportsData[i].reportId === 29) {
                                    for (var j = 0; j < reportsData[i].series.length; j++) {
                                        for (var prop in reportsData[i].series[j]) {
                                            if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                                if (Number(reportsData[i].series[0][prop]) > 0) {
                                                    var innerObj = {
                                                        name: '',
                                                        value: Number(''),
                                                    };
                                                    innerObj.name = prop;
                                                    innerObj.value = Number(reportsData[i].series[0][prop]);
                                                    _this.reportCookingSource.push(innerObj);
                                                }
                                            }
                                        }
                                    }
                                    //console.log("reportCookingSource ", this.reportCookingSource);
                                }
                                if (reportsData[i].reportId === 26) {
                                    for (var j = 0; j < reportsData[i].series.length; j++) {
                                        for (var prop in reportsData[i].series[j]) {
                                            if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                                if (Number(reportsData[i].series[0][prop]) > 0) {
                                                    var innerObj = {
                                                        name: '',
                                                        value: Number(''),
                                                    };
                                                    innerObj.name = prop;
                                                    innerObj.value = Number(reportsData[i].series[0][prop]);
                                                    _this.reportHousing.push(innerObj);
                                                }
                                            }
                                        }
                                    }
                                    //console.log("reportHousing ", this.reportHousing);
                                }
                                if (reportsData[i].reportId === 141) {
                                    for (var j = 0; j < reportsData[i].series.length; j++) {
                                        for (var prop in reportsData[i].series[j]) {
                                            if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                                if (Number(reportsData[i].series[0][prop]) > 0) {
                                                    var innerObj = {
                                                        name: '',
                                                        value: Number(''),
                                                    };
                                                    innerObj.name = prop;
                                                    innerObj.value = Number(reportsData[i].series[0][prop]);
                                                    _this.reportElectricity.push(innerObj);
                                                }
                                            }
                                        }
                                    }
                                    //console.log("reportElectricity ", this.reportElectricity);
                                }
                                // if(reportsData[i].reportId === 100){
                                //     for(let j=0;j<reportsData[i].series.length;j++){
                                //         console.log(reportsData[i].series[j])        
                                //         let innerObj = {
                                //         name:'',
                                //         value:Number(''),
                                //         };
                                //         innerObj.name = reportsData[i].series[j].key;
                                //         innerObj.value = Number(reportsData[i].series[j].value);
                                //         this.reportHealthcare.push(innerObj)               
                                //     }
                                //     console.log("reportHealthcare ", this.reportHealthcare);
                                // }
                                if (reportsData[i].reportId === 69 && reportsData[i].name == 'Waste Management Methods') {
                                    for (var j = 0; j < reportsData[i].series.length; j++) {
                                        for (var prop in reportsData[i].series[j]) {
                                            if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                                if (Number(reportsData[i].series[0][prop]) > 0) {
                                                    var innerObj = {
                                                        name: '',
                                                        value: Number(''),
                                                    };
                                                    innerObj.name = prop;
                                                    innerObj.value = Number(reportsData[i].series[0][prop]);
                                                    _this.reportWasteManagement.push(innerObj);
                                                }
                                            }
                                        }
                                    }
                                    //console.log("reportCookingSource ", this.reportWasteManagement);
                                }
                                if (reportsData[i].conf && reportsData[i].conf.length > 0) {
                                    var chartOptions = reportsData[i].conf[0].conf;
                                    if (typeof reportsData[i].conf[0].conf !== 'object') {
                                        chartOptions = JSON.parse(reportsData[i].conf[0].conf);
                                    }
                                    var healthInsurence = [];
                                    // chartOptions.exporting.buttons.contextButton.menuItems = contextMenuOptions;
                                    // // chartOptions.chart.width = 320;
                                    // chartOptions.chart.height = '50%';
                                    // chartOptions.chart_type = chartOptions.chart.type;
                                    // chartOptions.title.style= {
                                    // fontSize: "16px",
                                    // fontWeight: '600'
                                    // };       
                                    // chartOptions.reportId = reportsData[i].reportId; 
                                    // if(reportsData[i].is_drilldown == 1){
                                    // chartOptions.drilldown = reportsData[i].drilldown;
                                    // }
                                    // //chartOptions.reportTypeFrontend = currentType;
                                    // chartOptions.chart.backgroundColor= '#fafafa';
                                    // chartOptions.chart.spacingTop = 20;
                                    // chartOptions.chart.spacingBottom = 20;
                                    // chartOptions.reportName = reportsData[i].name;
                                    // //console.log("chartOptions ", chartOptions);
                                    // chartsList.push(chartOptions);
                                    if (reportsData[i].name === 'Health Insurance Type') {
                                        for (var j = 0; j < reportsData[i].series.length; j++) {
                                            //if(reportsData[i].series && reportsData[i].series.length > 1){
                                            for (var prop_1 in reportsData[i].series[0]) {
                                                //console.log("prop ", prop);  
                                                //console.log("reportsData[i].series[0][prop] ", reportsData[i].series[j][prop]);  
                                                //console.log("reportsData[i].series[0].key", reportsData[i].series[j].key);                
                                                if (reportsData[i].series[0].hasOwnProperty(prop_1)) {
                                                    if (Number(reportsData[i].series[0][prop_1]) > 0) {
                                                        var newArray = [];
                                                        newArray[0] = prop_1;
                                                        newArray[1] = Number(reportsData[i].series[0][prop_1]);
                                                        healthInsurence.push(newArray);
                                                    }
                                                }
                                            }
                                            //}
                                        }
                                        var options = {
                                            chart: {
                                                type: 'column',
                                                height: '200px'
                                            },
                                            title: {
                                                text: 'Insurance Type:',
                                                style: {
                                                    fontSize: '18px'
                                                },
                                                align: 'left',
                                                x: 0,
                                                y: 5
                                            },
                                            subtitle: {
                                                text: ''
                                            },
                                            xAxis: {
                                                type: 'category'
                                            },
                                            yAxis: {
                                                min: 0,
                                                title: {
                                                    text: ''
                                                }
                                            },
                                            legend: {
                                                enabled: false
                                            },
                                            credits: {
                                                enabled: false
                                            },
                                            tooltip: {
                                                pointFormat: ''
                                            },
                                            exporting: {
                                                enabled: false
                                            },
                                            series: [{
                                                    name: 'Health Insurance Type',
                                                    color: '#50C1BF',
                                                    data: [],
                                                    dataLabels: {
                                                        enabled: true,
                                                        format: '{point.y}',
                                                        style: {
                                                            fontWeight: 'normal'
                                                        }
                                                    }
                                                }]
                                        };
                                        options.series[0].data = healthInsurence;
                                        //   let h = document.createElement("div");
                                        //     // let newElement = this.chartElement01.nativeElement.appendChild(e); 
                                        //     // let newElement2 = this.chartElement02.nativeElement.appendChild(f); 
                                        //     let newElement4 = this.chartElement04.nativeElement.appendChild(h);
                                        //     this.hcRService.createChart(newElement4, options);
                                    }
                                }
                                else {
                                    //console.log("conf 0 ");
                                    var healthCareProviders = [];
                                    if (reportsData[i].name === 'Health Care Providers') {
                                        var options = {
                                            chart: {
                                                type: 'column',
                                                height: '200px'
                                            },
                                            title: {
                                                text: 'Healthcare Provider:',
                                                style: {
                                                    fontSize: '18px'
                                                },
                                                align: 'left',
                                                x: 0,
                                                y: 5
                                            },
                                            subtitle: {
                                                text: ''
                                            },
                                            xAxis: {
                                                type: 'category'
                                            },
                                            yAxis: {
                                                min: 0,
                                                title: {
                                                    text: ''
                                                }
                                            },
                                            legend: {
                                                enabled: false
                                            },
                                            credits: {
                                                enabled: false
                                            },
                                            tooltip: {
                                                pointFormat: ''
                                            },
                                            exporting: {
                                                enabled: false
                                            },
                                            series: [{
                                                    name: 'Healthcare Providers',
                                                    color: '#50C1BF',
                                                    data: [],
                                                    dataLabels: {
                                                        enabled: true,
                                                        format: '{point.y}',
                                                        style: {
                                                            fontWeight: 'normal'
                                                        }
                                                    }
                                                }]
                                        };
                                        for (var j = 0; j < reportsData[i].series.length; j++) {
                                            //if(reportsData[i].series && reportsData[i].series.length > 1){
                                            for (var prop_2 in reportsData[i].series[j]) {
                                                //console.log("prop ", prop);  
                                                //console.log("reportsData[i].series[0][prop] ", reportsData[i].series[j][prop]);  
                                                //console.log("reportsData[i].series[0].key", reportsData[i].series[j].key);                
                                                if (reportsData[i].series[j].hasOwnProperty(prop_2)) {
                                                    if (Number(reportsData[i].series[j][prop_2]) > 0) {
                                                        var newArray_1 = [];
                                                        newArray_1[0] = reportsData[i].series[j].key;
                                                        newArray_1[1] = Number(reportsData[i].series[j][prop_2]);
                                                        healthCareProviders.push(newArray_1);
                                                    }
                                                }
                                            }
                                            //}
                                        }
                                        //console.log("healthCareProviders ", healthCareProviders);
                                        options.series[0].data = healthCareProviders;
                                        // let g = document.createElement("div");
                                        // //let h = document.createElement("div");
                                        // // let newElement = this.chartElement01.nativeElement.appendChild(e); 
                                        // // let newElement2 = this.chartElement02.nativeElement.appendChild(f); 
                                        // let newElement3 = this.chartElement03.nativeElement.appendChild(g); 
                                        // //let newElement4 = this.chartElement04.nativeElement.appendChild(h);
                                        // this.hcRService.createChart(newElement3, options);
                                    }
                                }
                            }
                        }
                        else {
                            //this.chartElement01.nativeElement.innerHTML = '';
                            _this.hcRService.allCharts = [];
                        }
                        //console.log("chartsList ", chartsList);
                        _this.spinner.hide();
                    }
                    else {
                        _this.spinner.hide();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    CommunityLivelihoodComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    CommunityLivelihoodComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    CommunityLivelihoodComponent.prototype.goToDashboards = function () {
        var url = "charts/dashboards/" + this.projectId;
        this.router.navigate([url]);
    };
    return CommunityLivelihoodComponent;
}());
export { CommunityLivelihoodComponent };
