import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { mapTo } from 'rxjs/operators';
import { Observable, fromEvent, merge, of} from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { FarmerService } from '../../services/farmer.service';
import { SuppliersService } from '../../services/suppliers.service'

import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var idbApp: any;

declare var $: any;

declare var serviceWorkerVar;

@Component({
  selector: 'app-ecosystemsettings',
  templateUrl: './ecosystemsettings.component.html',
  styleUrls: ['./ecosystemsettings.component.css']
})
export class EcosystemsettingsComponent implements OnInit {
  isOnline: Observable<boolean>;
  projectId = 0;
  project: any;
  projects: any;
  farmers = [];
  productType = [];
  managers = [];

  productTypeName: string;
  managerName: string;

  isAscending = true;
  isAscendingId = true;
  isAscendingName = true;
  isAscendingTel = true;
  isAscendingDate = true;
  sortLabel = '';

  public showSpinner = false;

  isFieldAgent = false;
  isManager = false;
  isDeeproots = false;

  farmerFilterString: any = { name: '' };

  page = 1;
  itemsPerPage = 10;

  public previousPaginationLabel;
  public nextPaginationLabel;
  public isInIDBInThisProject = [];
  public showSyncSuccessAlert = false;
  public closeSnapshotModal: BsModalRef;
  public modifySnapshotModal: BsModalRef;

  public allSnapshots;
  public snapshot;
  public olderSnapshots = [];

  public snapshotNameTemp;
  public bsDateRange;
  public snapshotModifying;
  public oldSnapshotInfo;

  public minDate = new Date();
  public projectUndefined;

  public getSnapshotInterval;
  public noSnapshotsInProject = false;
  public fetchingSnapshotInfo = true;

  public disableCreateSnapshot = false;
  public disableCloseSnapshot = false;

  public registerFarmerModal;
  public updateFarmerModal;
  public registeringFarmerName;
  public registeringFarmerAge;
  public registeringFarmerSex;
  public registeringFarmerArea;
  public registeringFarmer = false;

  public master;
  public sex;
  public registerFarmerForm: FormGroup;
  public updateFarmerForm: FormGroup;
  public registerAnotherFarmer = false;
  fileToUpload: File = null;
  photoModel = '';

  oldSnapshotId = 0;
  arrSnapshotRevisions = [];
  snapshotRevision = [];
  public id:any;
  public projectData:any;

  farmerUpdating;
  public loadedEcosystems = false;
  searchText = '';

  currentUser = this.userService.getCurrentUser();

  fromNavId;
  public typeOfAdministrationArr: any[]; 
  typeOfAdministration = '';
  public ecoSystemSettingsData:any;
  public ecosystemId:any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly commonService: CommonService,
    private readonly router: Router,
    private readonly farmerService: FarmerService,
    private readonly projectService: ProjectService,
    private readonly translate: TranslateService,
    public toastrService: ToastrService,
    public modalService: BsModalService,
    private readonly localeService: BsLocaleService,
    private readonly formBuilder: FormBuilder,
    private readonly revisionService: RevisionService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly userService: UserService,
    private readonly mdmService: SuppliersService,
  ) {

      this.route.paramMap.subscribe(params => {
        this.id = params.get('id');
      });

      this.route.queryParamMap.subscribe(queryParams => {
        const qpObject = { ...queryParams.keys, ...queryParams };

        this.fromNavId = qpObject['params']['from'];

        this.translate.get('typeAdminTextArr').subscribe((val) => {
          this.typeOfAdministrationArr = val;
        }, err => console.log('err', err));

        if (this.fromNavId == 2) {
          this.typeOfAdministration = this.typeOfAdministrationArr[0];
        }
        else if (this.fromNavId == 3) {
          this.typeOfAdministration = this.typeOfAdministrationArr[1];
        }

      })

    this.isOnline = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });

    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });
  }
  snapshotChanged() {
    this.snapshotRevision = this.arrSnapshotRevisions.filter(x => x.snapshot_id_ref === this.oldSnapshotId);
  }

  async ngOnInit() {
    this.scrollToTop();
    const mdmCode="ECOSYSTEM_MDM";
    
    this.ecosystemId = localStorage.getItem('ecosystem_id');
    this.projectId = +this.route.snapshot.params['id'];
    const code = this.commonService.checkRole();

    if (code == 'FAG') {
      this.isFieldAgent = true;
    }
    else if (code === 'PMG') {
      this.isManager = true;
    }
    else if (code === 'SYS1') {
      this.isDeeproots = true;
    }

    if (navigator.onLine) {
      this.spinner.show();
      this.mdmService.getEcoSystemSettingsData(mdmCode, this.ecosystemId).subscribe(response =>{
        if(response.status == 'success'){
          this.ecoSystemSettingsData = response.data;
          this.loadedEcosystems = true;
          this.spinner.hide();
        }else{
          this.ecoSystemSettingsData = [];
          this.spinner.hide();
        }
        
      });
    } else {

      const requests = await Promise.all([
        this.projectService.GetProjectsDataDashboard().toPromise()
     ]);
      this.project =  requests[0].data.find(x => x.id === this.projectId);

    }
  }

  getProjectData(){
    this.projectService.GetProject(this.id).subscribe(response => {
      this.projectData = response.data;
    });
  }


  goToDashboard(): void {
    this.router.navigate(['dashboard']);
  }


  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  ngOnDestroy() {
    this.showSpinner = false;
    clearInterval(this.getSnapshotInterval);
  }

  goToEditProject() {
    if (navigator.onLine) {
      const url = `setup/${this.project.id}`;
      this.router.navigate([url]);
    } else {
      this.toastrService.warning('You cannot edit a project while offline');
    }
  }

  sortBy(sortOn: string) {

    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);

  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    if (sortBy === 'ecosystem_name') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.ecoSystemSettingsData.sort((a, b) => {
        if (a.projectname.toLowerCase() < b.projectname.toLowerCase()) {
          return 1;
        }
        if (a.projectname.toLowerCase() > b.projectname.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'marker_description') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.ecoSystemSettingsData.sort((a, b) => {
        if (a.description.toLowerCase() < b.description.toLowerCase()) {
          return 1;
        }
        if (a.description.toLowerCase() > b.description.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'status') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.ecoSystemSettingsData.sort((a, b) => {
        if (a.projectstatus.toLowerCase() < b.projectstatus.toLowerCase()) {
          return 1;
        }
        if (a.projectstatus.toLowerCase() > b.projectstatus.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'upd_date') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.ecoSystemSettingsData.sort((a, b) => {
        if (a.suppliername.toLowerCase() < b.suppliername.toLowerCase()) {
          return 1;
        }
        if (a.suppliername.toLowerCase() > b.suppliername.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }

  descendic(sortBy: string) {
    this.isAscending = true;
    if (sortBy === 'ecosystem_name') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.ecoSystemSettingsData.sort((a, b) => {
        if (a.projectname.toLowerCase() > b.projectname.toLowerCase()) {
          return 1;
        }
        if (a.projectname.toLowerCase() < b.projectname.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'marker_description') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.ecoSystemSettingsData.sort((a, b) => {
        if (a.description.toLowerCase() > b.description.toLowerCase()) {
          return 1;
        }
        if (a.description.toLowerCase() < b.description.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'status') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.ecoSystemSettingsData.sort((a, b) => {
        if (a.projectstatus.toLowerCase() > b.projectstatus.toLowerCase()) {
          return 1;
        }
        if (a.projectstatus.toLowerCase() < b.projectstatus.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'upd_date') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.ecoSystemSettingsData.sort((a, b) => {
        if (a.suppliername.toLowerCase() > b.suppliername.toLowerCase()) {
          return 1;
        }
        if (a.suppliername.toLowerCase() < b.suppliername.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }


  getGridFilters() {
    const gridColums = ['ecosystem_name','marker_description','status', 'upd_date'];
    const finalObj = {};
    if (this.ecoSystemSettingsData === undefined) {
    return {};
    } else {
    gridColums.forEach(element => {
    finalObj[element] = this.searchText;
    });
    }
    return finalObj;
  }

  goToEditEcosystemSettings(id){
    const url =`/ecosystem-settings/edit/${id}`;
    this.router.navigate([url],{ queryParams: { from: this.fromNavId } });
  }

  exportToExcel() : void {
    this.mdmService.exportData('ECOSYSTEM_SETTINGS_MDM', this.ecoSystemSettingsData.length == 1 ? this.ecoSystemSettingsData[0].id : undefined).subscribe(result => {
      console.log('result', result);
      var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
      const blob = new Blob([result], {type: contentType});
      const linkdownload = document.createElement('a');
      linkdownload.href = URL.createObjectURL(blob);
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();
      var today1 = dd + '-' + mm + '-' + yyyy;
      this.translate.get('ecosystem_settings').subscribe((val) => {
        linkdownload.download = 'P2F-Roots_' + val + '_ExcelExport_' + today1 + '.xlsx';
        linkdownload.style.display = 'none';
        document.body.appendChild(linkdownload);
        linkdownload.click();
      }, err => console.log('err', err));
    }, err=> console.log('err', err))
  }

}
