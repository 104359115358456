import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services/project.service';
import { SurveyService } from '../../../services/survey.service';
import { TranslateService } from '@ngx-translate/core';
import { CompareDataService } from '../../../services/compare-data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FarmerService } from '../../../services/farmer.service';
import $ from 'jquery';
var AnswerComparisonComponent = /** @class */ (function () {
    function AnswerComparisonComponent(router, route, projectService, surveyService, compareDataService, spinner, farmerService, translate) {
        this.router = router;
        this.route = route;
        this.projectService = projectService;
        this.surveyService = surveyService;
        this.compareDataService = compareDataService;
        this.spinner = spinner;
        this.farmerService = farmerService;
        this.translate = translate;
        this.projectId = 0;
        this.requestParams = {};
        this.farmerDetailsTabIndex = 0;
        this.sourceAnswer = [];
        this.targetAnswer = [];
        this.revisionComparisonData = [];
        this.revTextParams = {};
        this.secondary_asset_keys = {};
        this.selectedFarmerId = [];
        this.currentTabExist = true;
        this.dropdownSettings = {};
        this.dropdownList = [];
        this.farmerDiscontinued = false;
        var requestVal = localStorage.getItem('compareSnapshotsRevisionRequestObject');
        this.requestParams = JSON.parse(requestVal);
        this.requestParams["showDetails"] = 1;
        var requestText = localStorage.getItem('compareSnapshotsRevisionTextObject');
        this.revTextParams = JSON.parse(requestText);
    }
    AnswerComparisonComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, revisionId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        //this.selectedFarmerId = this.requestParams['farmerId'];
                        this.projectId = this.route.snapshot.params['id'];
                        this.farmerId = this.requestParams['farmerId'];
                        return [4 /*yield*/, Promise.all([
                                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                this.projectService.getProjectProfilingData(this.projectId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        this.project = tslib_1.__assign({}, request[0].data[0], request[1].data[0]);
                        revisionId = this.requestParams['targetRevisionId'];
                        this.compareDataService.getFarmerList(this.projectId, revisionId).subscribe(function (response) {
                            _this.farmers = response.farmers;
                            for (var _i = 0, _a = _this.farmers; _i < _a.length; _i++) {
                                var x = _a[_i];
                                var idName = void 0;
                                if (x.supplier_id) {
                                    idName = x.supplier_id + " - " + x.name;
                                }
                                else {
                                    idName = x.name;
                                }
                                x.name = idName;
                                if (x.id == _this.farmerId) {
                                    _this.selectedFarmerId = [{ 'id': x.id, 'name': idName }];
                                }
                                //this.dropdownList.push({'id':x.id, 'name':idName});
                            }
                        });
                        this.setTranslatedLabelObjects();
                        this.getAllFarmerData(this.farmerId);
                        this.dropdownSettings = {
                            singleSelection: true,
                            idField: 'id',
                            textField: 'name',
                            selectAllText: this.selectAllText,
                            unSelectAllText: this.unSelectAllText,
                            searchPlaceholderText: this.searchText1,
                            itemsShowLimit: 3,
                            allowSearchFilter: true,
                            closeDropDownOnSelection: true
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    //added by Kiran for translation
    AnswerComparisonComponent.prototype.setTranslatedLabelObjects = function () {
        var _this = this;
        this.translate.get('selectPlaceholder').subscribe(function (result) {
            _this.selectPlaceholder = result;
        });
        this.translate.get('selectAllText').subscribe(function (result) {
            _this.selectAllText = result;
        });
        this.translate.get('unSelectAllText').subscribe(function (result) {
            _this.unSelectAllText = result;
        });
        this.translate.get('searchText').subscribe(function (result) {
            _this.searchText1 = result;
        });
    };
    AnswerComparisonComponent.prototype.setFarmer = function (eve) {
        this.requestParams['farmerId'] = eve.id;
        this.getAllFarmerData(eve.id);
    };
    AnswerComparisonComponent.prototype.getAllFarmerData = function (farmer) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, masterData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getFarmRegQuestions().toPromise(),
                                this.farmerService.getFarmerBasicDataById(farmer).toPromise(),
                                this.farmerService.GetFarmerMaster().toPromise(),
                                this.surveyService.getSurveyQForProfilingService(this.requestParams['targetSnapshotId']).toPromise(),
                                this.compareDataService.getRevisionComparisonReport(this.projectId, this.requestParams).toPromise(),
                                this.surveyService.getSurveyQForProfilingService(this.requestParams['sourceSnapshotId']).toPromise()
                            ])];
                    case 1:
                        requests = _a.sent();
                        this.farmerRegQuestions = requests[0].data;
                        this.farmer = requests[1].data;
                        masterData = requests[2].data;
                        this.masterDataFarmer = masterData.filter(function (t) {
                            return t.item_tab === "farmer_reg";
                        });
                        if (requests[3]['message'] !== 'notReleasedForLanguage') {
                            this.questionnaire = requests[3]['message'];
                        }
                        else {
                            this.questionnaire = [];
                        }
                        if (requests[5]['message'] !== 'notReleasedForLanguage') {
                            this.sourceQuestionnaire = requests[5]['message'];
                        }
                        else {
                            this.sourceQuestionnaire = [];
                        }
                        this.sourceAnswer = requests[4].source;
                        this.targetAnswer = requests[4].target;
                        this.farmerDiscontinued = requests[4].discontinedFarmersFlag;
                        this.setQuestionAnswers();
                        this.initialize();
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    AnswerComparisonComponent.prototype.setQuestionAnswers = function () {
        var targetDiffArr = [];
        if (this.questionnaire && this.questionnaire.length > 0) {
            for (var _i = 0, _a = this.questionnaire; _i < _a.length; _i++) {
                var tab = _a[_i];
                var sectionArr = [];
                var changesCount = 0;
                if (tab.sections && tab.sections.length > 0) {
                    for (var _b = 0, _c = tab.sections; _b < _c.length; _b++) {
                        var section = _c[_b];
                        var questionArr = [];
                        var subsectionArr = [];
                        var tableArr = [];
                        if (section.questions && section.questions.length > 0) {
                            for (var _d = 0, _e = section.questions; _d < _e.length; _d++) {
                                var question = _e[_d];
                                question = this.setAnswerForQuestionNonTableTarget(question);
                                question = this.setAnswerForQuestionNonTableSource(question);
                                var questionAnswers = this.getAnswersForQuestion(question);
                                if (questionAnswers) {
                                    questionArr.push(questionAnswers);
                                }
                            }
                        }
                        if (section.subsections && section.subsections.length > 0) {
                            for (var _f = 0, _g = section.subsections; _f < _g.length; _f++) {
                                var subsection = _g[_f];
                                var subsectionQuestionArr = [];
                                if (subsection.questions && subsection.questions.length > 0) {
                                    for (var _h = 0, _j = subsection.questions; _h < _j.length; _h++) {
                                        var question = _j[_h];
                                        question = this.setAnswerForQuestionNonTableTarget(question);
                                        question = this.setAnswerForQuestionNonTableSource(question);
                                        var questionAnswers = this.getAnswersForQuestion(question);
                                        if (questionAnswers) {
                                            subsectionQuestionArr.push(questionAnswers);
                                        }
                                    }
                                }
                                if (subsectionQuestionArr.length > 0) {
                                    var subsectionObj = {
                                        "subsection_name": subsection.survey_q_asset_labels[0].label,
                                        "questions": subsectionQuestionArr
                                    };
                                    subsectionArr.push(subsectionObj);
                                }
                            }
                        }
                        if (section.tables && section.tables.length > 0) {
                            for (var _k = 0, _l = section.tables; _k < _l.length; _k++) {
                                var table = _l[_k];
                                var tableQuestionArr = [];
                                this.secondary_asset_keys[table.id] = this.getSecondaryAssetKeysForTable(table);
                                if (table.questions && table.questions.length > 0) {
                                    for (var _m = 0, _o = table.questions; _m < _o.length; _m++) {
                                        var question = _o[_m];
                                        question = this.setAnswerForQuestionTableTarget(question, this.secondary_asset_keys[table.id]);
                                        question = this.setAnswerForQuestionTableSource(question, this.secondary_asset_keys[table.id], table.code, table.id);
                                        var questionAnswers = this.getAnswersForQuestion(question);
                                        if (questionAnswers) {
                                            tableQuestionArr.push(questionAnswers);
                                        }
                                    }
                                }
                                if (tableQuestionArr.length > 0) {
                                    var tableObj = {
                                        "table_name": table.survey_q_asset_labels[0].label,
                                        "questions": tableQuestionArr,
                                        "id": table.id
                                    };
                                    tableArr.push(tableObj);
                                }
                                if (table.displayFunction && table.displayFunction.indexOf('addDefaultCropEntry') > -1) {
                                    this.sortDefaultCropToTop(table);
                                }
                            }
                        }
                        if (questionArr.length > 0 || subsectionArr.length > 0 || tableArr.length > 0) {
                            var sectionObj = {
                                "section_name": section.survey_q_asset_labels[0].label,
                                "questions": questionArr,
                                "subsections": subsectionArr,
                                "tables": tableArr
                            };
                            sectionArr.push(sectionObj);
                        }
                    }
                }
                if (sectionArr.length > 0) {
                    var tabObj = {
                        "tab_name": tab.survey_q_asset_labels[0].label,
                        "sections": sectionArr,
                        "total_changes": changesCount,
                        "id": tab.id
                    };
                    targetDiffArr.push(tabObj);
                }
            }
        }
        this.revisionComparisonData = targetDiffArr;
        console.log('targetDiffArr', targetDiffArr);
    };
    AnswerComparisonComponent.prototype.setAnswerForQuestionNonTableTarget = function (question) {
        var temp;
        var answer;
        var otherAnswer;
        var uomAnswer;
        if (question.qa_subtype === 'text') {
            temp = this.targetAnswer.find(function (item) { return item['survey_q_asset.code'] == question.code; });
            if (temp) {
                answer = temp.answer;
            }
        }
        else if (question.qa_subtype === 'number') {
            temp = this.targetAnswer.find(function (item) { return item['survey_q_asset.code'] == question.code; });
            if (temp) {
                answer = temp.answer;
            }
        }
        else if (question.qa_subtype === 'select') {
            var x = this.targetAnswer.find(function (item) { return item['survey_q_asset.code'] == question.code; });
            if (x) {
                answer = x.answer;
            }
        }
        else { // multiselect
            var x = this.targetAnswer.filter(function (item) { return item['survey_q_asset.code'] == question.code; });
            if (x && x.length > 0) {
                if (!question.has_dynamic_options) {
                    var z = x.map(function (item) { return item.answer; });
                    answer = z.join(", ");
                }
                // else {
                //   const doo = this.dynamicOptionsObject[question.id];
                //   if (doo) {
                //     const y = doo.filter(item => x.find(each => each.value == item.q + '__' + item.sak));
                //     if (y && y.length > 0) {
                //       const z = y.map(item => item.label);
                //       answer = z.join(", ");
                //     } 
                //   }
                // }
            }
        }
        if (answer) {
            question.targetAnswer = answer;
        }
        if (question.other_questions && question.other_questions.length > 0) {
            otherAnswer = this.targetAnswer.find(function (item) { return item['survey_q_asset.code'] == question.other_questions[0].code; });
            if (otherAnswer) {
                question.otherAnswer = otherAnswer.answer;
            }
        }
        if (question.uom_questions && question.uom_questions.length > 0) {
            var _loop_1 = function (uomQ) {
                temp = this_1.targetAnswer.find(function (item) { return item['survey_q_asset.code'] == uomQ.code; });
                if (temp) {
                    var y = this_1.project.ProjectUOM.find(function (item) { return item.id == temp.answer; });
                    if (y) {
                        uomQ.answer = y.uom;
                    }
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = question.uom_questions; _i < _a.length; _i++) {
                var uomQ = _a[_i];
                _loop_1(uomQ);
            }
        }
        return question;
    };
    AnswerComparisonComponent.prototype.setAnswerForQuestionTableTarget = function (question, secondaryAssetKeys) {
        var answer = {};
        var uomQAnswer = {};
        var otherQAnswer = {};
        var _loop_2 = function (sak) {
            var temp;
            var otherAnswer = void 0;
            if (question.qa_subtype === 'text') {
                temp = this_2.targetAnswer.find(function (item) { return item['survey_q_asset.code'] == question.code && item.secondary_asset_key == sak; });
                if (temp) {
                    answer[sak] = temp.answer;
                }
            }
            else if (question.qa_subtype === 'number') {
                temp = this_2.targetAnswer.find(function (item) { return item['survey_q_asset.code'] == question.code && item.secondary_asset_key == sak; });
                if (temp) {
                    answer[sak] = temp.answer;
                }
            }
            else if (question.qa_subtype === 'select') {
                var x = this_2.targetAnswer.find(function (item) { return item['survey_q_asset.code'] == question.code && item.secondary_asset_key == sak; });
                if (x) {
                    answer[sak] = x.answer;
                }
            }
            else { // multiselect
                var x = this_2.targetAnswer.filter(function (item) { return item['survey_q_asset.code'] == question.code && item.secondary_asset_key == sak; });
                if (x && x.length > 0) {
                    if (!question.has_dynamic_options) {
                        var z = x.map(function (item) { return item.answer; });
                        answer[sak] = z.join(", ");
                    }
                    // else {
                    //   const doo = this.dynamicOptionsObject[question.id];
                    //   if (doo) {
                    //     const y = doo.filter(item => x.find(each => each.value == item.q + '__' + item.sak));
                    //     if (y && y.length > 0) {
                    //       const z = y.map(item => item.label);
                    //       answer[sak] = z.join(", ");
                    //     } 
                    //   }
                    // }
                }
            }
            if (question.other_questions && question.other_questions.length > 0) {
                otherAnswer = this_2.targetAnswer.find(function (item) { return item['survey_q_asset.code'] == question.other_questions[0].code && item.secondary_asset_key == sak; });
                if (otherAnswer) {
                    otherQAnswer[sak] = otherAnswer.answer;
                    answer[sak] = answer[sak] + "-" + otherAnswer.answer;
                    if (answer[sak]) {
                        if (answer[sak] === "-") {
                            answer[sak] = "";
                        }
                        else if (answer[sak].length > 1 && answer[sak][answer[sak].length - 1] === "-") {
                            answer[sak] = answer[sak].substring(0, answer[sak].length - 1);
                        }
                    }
                }
            }
            if (question.uom_questions && question.uom_questions.length > 0) {
                var _loop_3 = function (uomQ) {
                    temp = this_2.targetAnswer.find(function (item) { return item['survey_q_asset.code'] == uomQ.code && item.secondary_asset_key == sak; });
                    if (temp) {
                        var y = this_2.project.ProjectUOM.find(function (item) { return item.id == temp.answer; });
                        if (y) {
                            uomQAnswer[sak] = y.uom;
                        }
                    }
                    uomQ.answer = uomQAnswer;
                };
                for (var _i = 0, _a = question.uom_questions; _i < _a.length; _i++) {
                    var uomQ = _a[_i];
                    _loop_3(uomQ);
                }
            }
        };
        var this_2 = this;
        for (var _i = 0, secondaryAssetKeys_1 = secondaryAssetKeys; _i < secondaryAssetKeys_1.length; _i++) {
            var sak = secondaryAssetKeys_1[_i];
            _loop_2(sak);
        }
        question.targetAnswer = answer;
        return question;
    };
    AnswerComparisonComponent.prototype.setAnswerForQuestionNonTableSource = function (question) {
        var sourceQuestion = this.getSourceQuestion(question.code);
        if (!sourceQuestion) {
            sourceQuestion = {};
        }
        var temp;
        var answer;
        var otherAnswer;
        var uomAnswer;
        if (sourceQuestion.qa_subtype === 'text') {
            temp = this.sourceAnswer.find(function (item) { return item['survey_q_asset.code'] == sourceQuestion.code; });
            if (temp) {
                answer = temp.answer;
            }
        }
        else if (sourceQuestion.qa_subtype === 'number') {
            temp = this.sourceAnswer.find(function (item) { return item['survey_q_asset.code'] == sourceQuestion.code; });
            if (temp) {
                answer = temp.answer;
            }
        }
        else if (sourceQuestion.qa_subtype === 'select') {
            var x = this.sourceAnswer.find(function (item) { return item['survey_q_asset.code'] == sourceQuestion.code; });
            if (x) {
                answer = x.answer;
            }
        }
        else { // multiselect
            var x = this.sourceAnswer.filter(function (item) { return item['survey_q_asset.code'] == sourceQuestion.code; });
            if (x && x.length > 0) {
                if (!sourceQuestion.has_dynamic_options) {
                    var z = x.map(function (item) { return item.answer; });
                    answer = z.join(", ");
                }
                // else {
                //   const doo = this.dynamicOptionsObject[question.id];
                //   if (doo) {
                //     const y = doo.filter(item => x.find(each => each.value == item.q + '__' + item.sak));
                //     if (y && y.length > 0) {
                //       const z = y.map(item => item.label);
                //       answer = z.join(", ");
                //     } 
                //   }
                // }
            }
        }
        if (answer) {
            question.sourceAnswer = answer;
        }
        if (sourceQuestion.other_questions && sourceQuestion.other_questions.length > 0) {
            otherAnswer = this.sourceAnswer.find(function (item) { return item['survey_q_asset.code'] == sourceQuestion.other_questions[0].code; });
            if (otherAnswer) {
                question.otherAnswerSource = otherAnswer.answer;
            }
        }
        if (sourceQuestion.uom_questions && sourceQuestion.uom_questions.length > 0) {
            var _loop_4 = function (uomQ) {
                temp = this_3.sourceAnswer.find(function (item) { return item['survey_q_asset.code'] == uomQ.code; });
                if (temp) {
                    var y = this_3.project.ProjectUOM.find(function (item) { return item.id == temp.answer; });
                    if (y) {
                        var checkQ = question.uom_questions.find(function (item) { return item.code == uomQ.code; });
                        if (checkQ) {
                            checkQ.answerSource = y.uom;
                        }
                    }
                }
            };
            var this_3 = this;
            for (var _i = 0, _a = sourceQuestion.uom_questions; _i < _a.length; _i++) {
                var uomQ = _a[_i];
                _loop_4(uomQ);
            }
        }
        return question;
    };
    AnswerComparisonComponent.prototype.setAnswerForQuestionTableSource = function (question, secondaryAssetKeys, tableCode, tableId) {
        var sourceQuestion = this.getSourceQuestion(question.code);
        if (!sourceQuestion) {
            sourceQuestion = {};
        }
        var sourceTable = this.getSourceTable(tableCode);
        if (!sourceTable) {
            sourceTable = {};
        }
        var sakSource = {};
        sakSource[sourceTable.id] = this.getSecondaryAssetKeysForTableSource(sourceTable);
        var sakSourceArr = sakSource[sourceTable.id];
        var answer = {};
        var uomQAnswer = {};
        var otherQAnswer = {};
        var unionArr = secondaryAssetKeys.concat(sakSourceArr.filter(function (item) { return secondaryAssetKeys.indexOf(item) < 0; }));
        // if(secondaryAssetKeys.length == 0 && sakSourceArr.length > 0){
        //   secondaryAssetKeys = sakSourceArr;
        //   this.secondary_asset_keys[tableId] = sakSourceArr;
        // }
        secondaryAssetKeys = unionArr;
        this.secondary_asset_keys[tableId] = unionArr;
        var _loop_5 = function (i) {
            var temp;
            var otherAnswer = void 0;
            if (sourceQuestion.qa_subtype === 'text') {
                temp = this_4.sourceAnswer.find(function (item) { return item['survey_q_asset.code'] == sourceQuestion.code && item.secondary_asset_key == secondaryAssetKeys[i]; });
                if (temp) {
                    answer[secondaryAssetKeys[i]] = temp.answer;
                }
            }
            else if (sourceQuestion.qa_subtype === 'number') {
                temp = this_4.sourceAnswer.find(function (item) { return item['survey_q_asset.code'] == sourceQuestion.code && item.secondary_asset_key == secondaryAssetKeys[i]; });
                if (temp) {
                    answer[secondaryAssetKeys[i]] = temp.answer;
                }
            }
            else if (sourceQuestion.qa_subtype === 'select') {
                var x = this_4.sourceAnswer.find(function (item) { return item['survey_q_asset.code'] == sourceQuestion.code && item.secondary_asset_key == secondaryAssetKeys[i]; });
                if (x) {
                    answer[secondaryAssetKeys[i]] = x.answer;
                }
            }
            else { // multiselect
                var x = this_4.sourceAnswer.filter(function (item) { return item['survey_q_asset.code'] == sourceQuestion.code && item.secondary_asset_key == secondaryAssetKeys[i]; });
                if (x && x.length > 0) {
                    if (!sourceQuestion.has_dynamic_options) {
                        var z = x.map(function (item) { return item.answer; });
                        answer[secondaryAssetKeys[i]] = z.join(", ");
                    }
                    // else {
                    //   const doo = this.dynamicOptionsObject[question.id];
                    //   if (doo) {
                    //     const y = doo.filter(item => x.find(each => each.value == item.q + '__' + item.sak));
                    //     if (y && y.length > 0) {
                    //       const z = y.map(item => item.label);
                    //       answer[sak] = z.join(", ");
                    //     } 
                    //   }
                    // }
                }
            }
            if (sourceQuestion.other_questions && sourceQuestion.other_questions.length > 0) {
                otherAnswer = this_4.sourceAnswer.find(function (item) { return item['survey_q_asset.code'] == sourceQuestion.other_questions[0].code && item.secondary_asset_key == secondaryAssetKeys[i]; });
                if (otherAnswer) {
                    otherQAnswer[secondaryAssetKeys[i]] = otherAnswer.answer;
                    answer[secondaryAssetKeys[i]] = answer[secondaryAssetKeys[i]] + "-" + otherAnswer.answer;
                }
            }
            if (sourceQuestion.uom_questions && sourceQuestion.uom_questions.length > 0) {
                var _loop_6 = function (uomQ) {
                    temp = this_4.sourceAnswer.find(function (item) { return item['survey_q_asset.code'] == uomQ.code && item.secondary_asset_key == secondaryAssetKeys[i]; });
                    if (temp) {
                        var y = this_4.project.ProjectUOM.find(function (item) { return item.id == temp.answer; });
                        if (y) {
                            uomQAnswer[secondaryAssetKeys[i]] = y.uom;
                        }
                    }
                    uomQ.answerSource = uomQAnswer;
                };
                for (var _i = 0, _a = sourceQuestion.uom_questions; _i < _a.length; _i++) {
                    var uomQ = _a[_i];
                    _loop_6(uomQ);
                }
            }
        };
        var this_4 = this;
        for (var i = 0; i < secondaryAssetKeys.length; i++) {
            _loop_5(i);
        }
        question.sourceAnswer = answer;
        return question;
    };
    AnswerComparisonComponent.prototype.getAnswersForQuestion = function (question) {
        var isUpdate = false;
        for (var _i = 0, _a = this.sourceAnswer; _i < _a.length; _i++) {
            var SA = _a[_i];
            if (question.code === SA["survey_q_asset.code"]) {
                if (SA.marker === "DELETED") {
                    isUpdate = true;
                    question.marker = SA.marker;
                }
            }
        }
        for (var _b = 0, _c = this.targetAnswer; _b < _c.length; _b++) {
            var TA = _c[_b];
            if (question.code === TA["survey_q_asset.code"]) {
                if (TA.marker !== "NO_CHANGE") {
                    isUpdate = true;
                    question.marker = TA.marker;
                }
            }
        }
        if (question.targetAnswer === question.sourceAnswer && question.otherAnswer === question.otherAnswerSource) {
            isUpdate = false;
        }
        if (isUpdate) {
            return question;
        }
    };
    /**
   * Sort the default crop (the ecosystem crop) to the top of the agri income table
   * @param tableId - Number
  */
    AnswerComparisonComponent.prototype.sortDefaultCropToTop = function (table) {
        var tableId = table.id;
        var cropName = this.project.Product.name;
        var displayFunction = JSON.parse(table.displayFunction);
        var x = this.targetAnswer.find(function (item) { return item.questionnaire_asset_id == displayFunction.setQId && item.answer == cropName; });
        if (x) {
            // Set the value of this.defaultCropSak;
            this.defaultCropSak = x.secondary_asset_key;
            if (this.secondary_asset_keys && this.secondary_asset_keys[tableId] && this.secondary_asset_keys[tableId].length > 0) {
                var x_1 = this.secondary_asset_keys[tableId].indexOf(this.defaultCropSak);
                if (x_1) {
                    if (x_1 === 0) {
                        // do nothing
                        return;
                    }
                    else {
                        var temp = JSON.parse(JSON.stringify(this.secondary_asset_keys[tableId]));
                        var p = temp[0];
                        temp[0] = this.defaultCropSak;
                        temp[x_1] = p;
                        this.secondary_asset_keys[tableId] = JSON.parse(JSON.stringify(temp));
                    }
                }
            }
        }
    };
    AnswerComparisonComponent.prototype.getSourceQuestion = function (code) {
        var sourceQ;
        if (this.sourceQuestionnaire && this.sourceQuestionnaire.length > 0) {
            for (var _i = 0, _a = this.sourceQuestionnaire; _i < _a.length; _i++) {
                var tab = _a[_i];
                if (tab.sections && tab.sections.length > 0) {
                    for (var _b = 0, _c = tab.sections; _b < _c.length; _b++) {
                        var section = _c[_b];
                        if (section.questions && section.questions.length > 0) {
                            for (var _d = 0, _e = section.questions; _d < _e.length; _d++) {
                                var question = _e[_d];
                                if (question.code === code) {
                                    sourceQ = question;
                                }
                            }
                        }
                        if (section.subsections && section.subsections.length > 0) {
                            for (var _f = 0, _g = section.subsections; _f < _g.length; _f++) {
                                var subsection = _g[_f];
                                if (subsection.questions && subsection.questions.length > 0) {
                                    for (var _h = 0, _j = subsection.questions; _h < _j.length; _h++) {
                                        var question = _j[_h];
                                        if (question.code === code) {
                                            sourceQ = question;
                                        }
                                    }
                                }
                            }
                        }
                        if (section.tables && section.tables.length > 0) {
                            for (var _k = 0, _l = section.tables; _k < _l.length; _k++) {
                                var table = _l[_k];
                                if (table.questions && table.questions.length > 0) {
                                    for (var _m = 0, _o = table.questions; _m < _o.length; _m++) {
                                        var question = _o[_m];
                                        if (question.code === code) {
                                            sourceQ = question;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return sourceQ;
    };
    AnswerComparisonComponent.prototype.getSourceTable = function (code) {
        var sourceT;
        if (this.sourceQuestionnaire && this.sourceQuestionnaire.length > 0) {
            for (var _i = 0, _a = this.sourceQuestionnaire; _i < _a.length; _i++) {
                var tab = _a[_i];
                if (tab.sections && tab.sections.length > 0) {
                    for (var _b = 0, _c = tab.sections; _b < _c.length; _b++) {
                        var section = _c[_b];
                        if (section.tables && section.tables.length > 0) {
                            for (var _d = 0, _e = section.tables; _d < _e.length; _d++) {
                                var table = _e[_d];
                                if (table.code === code) {
                                    sourceT = table;
                                }
                            }
                        }
                    }
                }
            }
        }
        return sourceT;
    };
    AnswerComparisonComponent.prototype.getSecondaryAssetKeysForTable = function (table) {
        var sakArray = [];
        if (table.questions && table.questions.length > 0) {
            var qIds_1 = table.questions.map(function (item) { return item.id.toString(); });
            var answersForQIds = this.targetAnswer.filter(function (item) { return qIds_1.indexOf(item["questionnaire_asset_id"].toString()) > -1; });
            if (answersForQIds && answersForQIds.length > 0) {
                var answersForQIdsSaks = answersForQIds.map(function (item) { return item.secondary_asset_key; });
                if (answersForQIdsSaks && answersForQIdsSaks.length > 0) {
                    sakArray = this.getUniqueValues(answersForQIdsSaks);
                }
            }
        }
        return sakArray;
    };
    AnswerComparisonComponent.prototype.getSecondaryAssetKeysForTableSource = function (table) {
        var sakArray = [];
        if (table.questions && table.questions.length > 0) {
            var qIds_2 = table.questions.map(function (item) { return item.id.toString(); });
            var answersForQIds = this.sourceAnswer.filter(function (item) { return qIds_2.indexOf(item["questionnaire_asset_id"].toString()) > -1; });
            if (answersForQIds && answersForQIds.length > 0) {
                var answersForQIdsSaks = answersForQIds.map(function (item) { return item.secondary_asset_key; });
                if (answersForQIdsSaks && answersForQIdsSaks.length > 0) {
                    sakArray = this.getUniqueValues(answersForQIdsSaks);
                }
            }
        }
        return sakArray;
    };
    AnswerComparisonComponent.prototype.getUniqueValues = function (array) {
        var uniqueArray = [];
        for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
            var item = array_1[_i];
            if (uniqueArray.indexOf(item) < 0) {
                uniqueArray.push(item);
            }
        }
        return uniqueArray;
    };
    // Initialize the questionnaire by navigating to the 1st tab
    AnswerComparisonComponent.prototype.initialize = function () {
        this.farmerDetailsTabIndex = this.questionnaire.length + 1;
        this.currentTabIndex = this.farmerDetailsTabIndex;
        this.tabClicked(0, false);
    };
    AnswerComparisonComponent.prototype.tabClicked = function (tabIndex, isFarmerTab) {
        var _this = this;
        if (!isFarmerTab) {
            this.currentTab = this.questionnaire[tabIndex];
            var checkTabExist = this.revisionComparisonData.find(function (item) { return item.id === _this.currentTab.id; });
            if (checkTabExist) {
                this.currentTabExist = true;
            }
            else {
                this.currentTabExist = false;
            }
        }
        this.currentTabIndex = tabIndex;
        this.scrollToTop();
    };
    AnswerComparisonComponent.prototype.getMasterData = function (code, value) {
        var answer = this.masterDataFarmer.filter(function (v) {
            return v.master_id === value && v.item_code === code;
        });
        if (answer.length > 0) {
            return answer[0].name;
        }
        else {
            return null;
        }
    };
    AnswerComparisonComponent.prototype.scrollToTop = function () {
        var toTop = $('#mainDiv').scrollTop = 0;
        $('html, body').animate({
            scrollTop: toTop
        }, 'slow');
    };
    AnswerComparisonComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    AnswerComparisonComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    return AnswerComparisonComponent;
}());
export { AnswerComparisonComponent };
