import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
var DataConsentService = /** @class */ (function () {
    function DataConsentService(http, appConfigService) {
        this.http = http;
        this.appConfigService = appConfigService;
        this.dataConsentURL = this.appConfigService.getUrl(this.appConfigService.DATACONSENT);
    }
    DataConsentService.prototype.getFarmerConsents = function (projectID) {
        return this.http.get(this.dataConsentURL + "/farmer_consents/" + projectID);
    };
    DataConsentService.prototype.getConsentTemplate = function (projectID, templateId) {
        return templateId ? this.http.get(this.dataConsentURL + "/consents_template/" + projectID + "/" + templateId) :
            this.http.get(this.dataConsentURL + "/consents_template/" + projectID);
    };
    DataConsentService.prototype.getFarmerConsentHistory = function (projectID, farmerID) {
        return farmerID ? this.http.get(this.dataConsentURL + "/farmer_consents_history/" + projectID + "/" + farmerID) :
            this.http.get(this.dataConsentURL + "/farmer_consents_history/" + projectID);
    };
    DataConsentService.prototype.saveFarmerConsent = function (projectId, farmerId, data) {
        return this.http.post(this.dataConsentURL + "/farmer_consents/" + projectId + "/" + farmerId, data);
    };
    DataConsentService.prototype.getFarmerConsentForFarmers = function (projectId, data) {
        return this.http.post(this.dataConsentURL + "/farmer_consent_signature/" + projectId, data);
    };
    DataConsentService.ngInjectableDef = i0.defineInjectable({ factory: function DataConsentService_Factory() { return new DataConsentService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: DataConsentService, providedIn: "root" });
    return DataConsentService;
}());
export { DataConsentService };
