import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], key: string, searchText: string): any[] {

    if (!items) { 
      return []; 
    }
    if (!searchText) { 
      return items; 
    }
    searchText = searchText.toLowerCase();
    return items.filter( it => {
          return it[key].toLowerCase() === searchText;
        });
      }
}


@Pipe({ name: 'dateSuffix' })
export class DateSuffix implements PipeTransform {
    transform(value: string): string {

    let suffix = 'th',
        day = value;

        if (day == '1' || day == '21' || day == '31') {
            suffix = 'st'
        } else if (day == '2' || day == '22') {
            suffix = 'nd';
        } else if (day == '3' || day == '23') {
           suffix = 'rd';
        }

        return (day+suffix);

    }
}


@Pipe({
  name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {

  transform(val: number): string {
    if (val !== undefined && val !== null) {
      // here we just remove the commas from value
      return val.toString().replace(/,/g, "");
    } else {
      return;
    }
  }
}
