import {
 HttpEvent,
 HttpInterceptor,
 HttpHandler,
 HttpRequest,
 HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService} from 'ngx-toastr';
import { Router } from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import { StorageService } from '../services/storage.service';
import { CommonService } from '../services/common.service';

import { BsModalService } from 'ngx-bootstrap/modal';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private readonly toastr: ToastrService,
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly storageService: StorageService,
        private readonly commonService: CommonService,
        private readonly modalService: BsModalService,
        private readonly spinner: Ng4LoadingSpinnerService) {
    }

 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   return next.handle(request)
     .pipe(
       //retry(1),
       catchError((error: HttpErrorResponse) => {
         this.spinner.hide();
         let errorMessage = '';
         if (error.error instanceof ErrorEvent) {
           // client-side error
           errorMessage = `Error: ${error.error.message}`;
         } else {
           // server-side error
          //  console.log('error', error);
           errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
           if (error.status == 401 || error.status == 403) {
            // this.commonService.showToast('error', 'unauthorized', {});
           
           }
           else if (error.status == 404) {
            this.commonService.showToast('error', 'not_found', {});
           }
           else if (error.status == 500) {
            if (error.error.code == 'SequelizeForeignKeyConstraintError') {
              this.commonService.showToast('error', 'SequelizeForeignKeyConstraintError', {});
            }
            else {
              this.commonService.showToast('error', error.error.code, {});
            }
           }
           else if (error.status == 400) {
              this.commonService.showToast('error', error.error.code, {});
              
           }
           else if (error.status == 429) {
            this.commonService.showToast('error', 'too_many_requests', {});
           }
           else if (error.status == 0) {
             // Errors from workbox of type no-response: no-response :: [{"url":"http://localhost:3000/api/farmer/getFarmerById/"}]
             // are caught here
             // Do nothing when such an error is caught, ie. do not show any message.
             // When such an error is thrown, it means data is served by workbox from the cache
           }
           else {
            this.commonService.showToast('error', 'generic_error', {});
           }
         }
         return throwError(errorMessage);
       })
     );
    }

    private closeAllModals() {
      for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
        this.modalService.hide(i);
      }
    }

    logout() {
        this.closeAllModals();
        this.authenticationService.removeToken();
        this.authenticationService.removeTokenHeader();
        this.storageService.setLocalStorage('lg', '1');
        this.router.navigate(['/']);
    }
}
