import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil, mapTo } from 'rxjs/operators';
import { Subject, fromEvent, merge, of } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
var RegisterFarmerComponent = /** @class */ (function () {
    function RegisterFarmerComponent(route, commonService, router, farmerService, projectService, translate, toastrService, modalService, localeService, formBuilder) {
        this.route = route;
        this.commonService = commonService;
        this.router = router;
        this.farmerService = farmerService;
        this.projectService = projectService;
        this.translate = translate;
        this.toastrService = toastrService;
        this.modalService = modalService;
        this.localeService = localeService;
        this.formBuilder = formBuilder;
        this.registeringFarmer = false;
        this.registerAnotherFarmer = false;
        this.fileToUpload = null;
        this.photoModel = '';
        this.unsubscribe = new Subject();
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
    }
    RegisterFarmerComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var projects, master;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scrollToTop();
                        if (!navigator.onLine) return [3 /*break*/, 1];
                        this.master = this.route.snapshot.data.project.master.data;
                        this.sex = this.master.filter(function (x) { return x.item_tab === 'farmer_reg' && x.item_code === 'sex'; });
                        this.projectService.GetProjects().pipe(takeUntil(this.unsubscribe))
                            .subscribe(function (project) {
                            if (project.msg === 'success') {
                                _this.projects = project.data;
                                _this.registerFarmerForm = _this.formBuilder.group({
                                    name: new FormControl('', [Validators.required]),
                                    age: new FormControl('', [Validators.required, Validators.min(18), Validators.max(99)]),
                                    sex: new FormControl('', [Validators.required]),
                                    area: new FormControl('', [Validators.required]),
                                    telNumber: new FormControl(''),
                                    photo_path: new FormControl('')
                                });
                            }
                        }, function (error) { return console.error(error); });
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, this.projectService.GetProjects().toPromise()];
                    case 2:
                        projects = _a.sent();
                        if (projects) {
                            this.projects = projects.data;
                        }
                        this.registerFarmerForm = this.formBuilder.group({
                            name: new FormControl('', [Validators.required]),
                            age: new FormControl('', [Validators.required, Validators.min(18), Validators.max(99)]),
                            sex: new FormControl('', [Validators.required]),
                            area: new FormControl('', [Validators.required]),
                            telNumber: new FormControl('', [Validators.min(1000000000), Validators.max(999999999999)]),
                            photo_path: new FormControl('')
                        });
                        return [4 /*yield*/, this.farmerService.GetFarmerMaster().toPromise()];
                    case 3:
                        master = _a.sent();
                        this.master = master.data;
                        this.sex = this.master.filter(function (x) { return x.item_tab === 'farmer_reg' && x.item_code === 'sex'; });
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    RegisterFarmerComponent.prototype.openRegisterFarmerModal = function (template) {
        this.registerFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    RegisterFarmerComponent.prototype.registerFarmer = function () {
        var _this = this;
        this.registeringFarmer = true;
        var name = this.registerFarmerForm['value'].name;
        var age = this.registerFarmerForm['value'].age;
        var sex = this.registerFarmerForm['value'].sex;
        var area = this.registerFarmerForm['value'].area;
        var telNumber = this.registerFarmerForm['value'].telNumber;
        if (!name || name.length == 0 || !age || age.length == 0 || !sex || sex.length == 0 || !area || area.length == 0) {
            return;
        }
        var farmerToRegister = {
            name: name,
            age: age,
            sex: sex,
            village_area: area,
            tel_number: telNumber,
            photo_path: null,
            project_id: this.projectId
        };
        if (navigator.onLine) {
            if (this.fileToUpload) {
                var formData = new FormData();
                formData.append('type', 'farmerAvatar');
                formData.append('projectId', this.projectId);
                formData.append('avatar', this.fileToUpload, this.fileToUpload.name);
                this.projectService.Upload(formData)
                    .subscribe(function (obj) {
                    if (obj.msg === 'success') {
                        farmerToRegister['photo_path'] = obj.data;
                        _this.farmerService.registerFarmer(farmerToRegister).subscribe(function (result) {
                            _this.registeringFarmer = false;
                            if (result.msg === 'success') {
                                result.data.cr_date = new Date();
                                if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                                    _this.registerFarmerModal.hide();
                                }
                                _this.registerFarmerForm.setValue({
                                    name: '',
                                    age: '',
                                    sex: '',
                                    area: '',
                                    telNumber: '',
                                    photo_path: ''
                                });
                                _this.registerFarmerForm.markAsUntouched();
                                _this.registerFarmerForm.markAsPristine();
                                _this.fileToUpload = undefined;
                                _this.commonService.showToast('success', 'farmer_registration_successful', {});
                            }
                            else {
                                if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                                    _this.registerFarmerModal.hide();
                                }
                            }
                        }, function (error) {
                            if (_this.registerFarmerModal) {
                                _this.registerFarmerModal.hide();
                            }
                        });
                    }
                });
            }
            else {
                this.farmerService.registerFarmer(farmerToRegister).subscribe(function (result) {
                    _this.registeringFarmer = false;
                    if (result.msg === 'success') {
                        result.data.cr_date = new Date();
                        if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                            _this.registerFarmerModal.hide();
                        }
                        _this.registerFarmerForm.setValue({
                            name: '',
                            age: '',
                            sex: '',
                            area: '',
                            telNumber: '',
                            photo_path: ''
                        });
                        _this.registerFarmerForm.markAsUntouched();
                        _this.registerFarmerForm.markAsPristine();
                        _this.fileToUpload = undefined;
                    }
                    else {
                        if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                            _this.registerFarmerModal.hide();
                        }
                    }
                }, function (error) {
                    if (_this.registerFarmerModal) {
                        _this.registerFarmerModal.hide();
                    }
                });
            }
        }
        else {
            var finalObj_1 = {};
            var farmerObj_1 = farmerToRegister;
            Object.keys(farmerObj_1).forEach(function (key) {
                if (typeof farmerObj_1[key] === 'object' && farmerObj_1[key] != null) {
                    if (farmerObj_1[key].length && farmerObj_1[key].length > 0) {
                        //finalObj[key] = {};
                        finalObj_1[key] = farmerObj_1[key];
                    }
                    // else {
                    //   finalObj[key] = farmerObj[key];
                    // }
                }
                else {
                    finalObj_1[key] = farmerObj_1[key];
                }
            });
            var farmObj = new Array();
            finalObj_1['tmp_id'] = "FA" + this.commonService.getRandomNum().toString().slice(0, 10) + "_Tab0";
            finalObj_1['savetab'] = 'Tab0';
            finalObj_1['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
            finalObj_1['cr_date'] = new Date().getTime();
            finalObj_1['id'] = 0;
            farmObj[farmObj.length] = finalObj_1;
            idbApp.addStoreInfo(farmObj, 'farmerstmp');
            this.commonService.showToast('info', 'offline_sync_later', {});
            this.registerFarmerForm.markAsPristine();
            this.registerSyncEvent();
            farmerToRegister['cr_date'] = new Date();
            farmerToRegister['tmp_id'] = finalObj_1['tmp_id'];
            if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                this.registerFarmerModal.hide();
            }
            this.registerFarmerForm.setValue({
                name: '',
                age: '',
                sex: '',
                area: '',
                telNumber: '',
                photo_path: ''
            });
            this.registerFarmerForm.markAsUntouched();
            this.registerFarmerForm.markAsPristine();
            this.registeringFarmer = false;
        }
    };
    RegisterFarmerComponent.prototype.registerSyncEvent = function () {
        navigator.serviceWorker.register('/service-worker.js', {
            scope: './'
        })
            .then(function (reg) {
            reg.sync.register('oneTimeSync');
        })
            .catch(function (error) {
        });
    };
    RegisterFarmerComponent.prototype.declineRegisterFarmer = function () {
        if (this.registerFarmerModal) {
            this.registerFarmerModal.hide();
        }
        this.registerFarmerForm.setValue({
            name: '',
            age: '',
            sex: '',
            area: '',
            telNumber: '',
            photo_path: ''
        });
        this.registerFarmerForm.markAsUntouched();
        this.registerFarmerForm.markAsPristine();
    };
    RegisterFarmerComponent.prototype.handleFileInput = function (file) {
        var fileItem = file.item(0);
        var msg = this.commonService.ValidateFile(fileItem);
        if (msg.length > 0) {
            this.commonService.showToast('warning', 'generic_error', {});
            return;
        }
        this.fileToUpload = fileItem;
        var reader = new FileReader();
        reader.onload = function (event) {
        };
        reader.readAsDataURL(this.fileToUpload);
    };
    RegisterFarmerComponent.prototype.scrollToTop = function () {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    return RegisterFarmerComponent;
}());
export { RegisterFarmerComponent };
