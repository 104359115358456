import { Component, OnInit, TemplateRef, HostListener, LOCALE_ID, Inject} from '@angular/core';

import { FormBuilder, FormGroup} from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { mapTo } from 'rxjs/operators';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { UserService } from '../../services/user.service';
import { CampaignService } from '../../services/campaign.service';

import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as XLSX from 'xlsx';
import { ConsReportService } from '../../services/consolidated-reports.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';


declare var idbApp: any;

declare var $: any;

declare var serviceWorkerVar;

@Component({
  selector: 'app-campaign-profiling-farmers',
  templateUrl: './campaign-profiling-farmers.component.html',
  styleUrls: ['./campaign-profiling-farmers.component.css'],
  host: { '(window:partial-synced)': 'syncDone($event)' }
})
export class CampaignProfilingFarmersComponent implements OnInit {

  isOnline: Observable<boolean>;
  projectId = 0;
  project: any;
  farmers = [];
  productType = [];
  managers = [];

  productTypeName: string;
  managerName: string;

  isAscending = true;
  isAscendingId = true;
  isAscendingName = true;
  isAscendingTel = true;
  isAscendingDate = true;
  sortLabel = '';

  public showSpinner = false;

  isFieldAgent = false;
  isManager = false;
  isDeeproots = false;

  farmerFilterString: any = { name: '' };

  page = 1;
  itemsPerPage = 10;
  searchText = '';

  public previousPaginationLabel;
  public nextPaginationLabel;
  public isInIDBInThisProject = [];
  public showSyncSuccessAlert = false;
  public closeSnapshotModal: BsModalRef;
  public modifySnapshotModal: BsModalRef;

  public allSnapshots;
  public snapshot;
  public olderSnapshots = [];
  public allSurveys;
  public snapshotNameTemp;
  public bsDateRange;
  public snapshotModifying;
  public oldSnapshotInfo;

  public minDate = new Date();
  public projectUndefined;

  public getSnapshotInterval;
  public noSnapshotsInProject = false;
  public fetchingSnapshotInfo = true;

  public disableCreateSnapshot = false;
  public disableCloseSnapshot = false;

  public registerFarmerModal;
  public registeringFarmerName;
  public registeringFarmerAge;
  public registeringFarmerSex;
  public registeringFarmerArea;
  public registeringFarmer = false;

  public quickNoteModal;
  public quickNoteData:any;
  
  public master;
  public sex;
  public registerFarmerForm: FormGroup;
  public registerAnotherFarmer = false;
  fileToUpload: File = null;
  photoModel = '';

  oldSnapshotId = 0;
  arrSnapshotRevisions = [];
  snapshotRevision = [];
  public id: any;
  public projectData: any;
  public UPM;
  usersServiceComponentMappings;
  ecosystemServicesComponentsMapping;
  canExportToExcel = false;
  canMakeRevision = false
  currentUser;

  selectedFarmers = [];

  // tranlsate service
  thereNoActiveSnapshotForThisProject;
  revisionTxt;
  createSuccessTxt;
  surveyStats;
  tooltipText;
  tooltipText2;
  selectDeselectLabels;
  campaignId;
  selectedTabCode;
  allTabNames = [];
  public selectTabName;
  entireSurvey;
  confirmTEDModal = true;
  campaignProfiling;
  showSurveyManualIcon = false;
  noChangeRevisionTxt;
  filteredfarmers = [];
  constructor(
    private readonly route: ActivatedRoute,
    private readonly commonService: CommonService,
    private readonly router: Router,
    private readonly farmerService: FarmerService,
    private readonly projectService: ProjectService,
    private readonly userService: UserService,
    private readonly translate: TranslateService,
    public toastrService: ToastrService,
    public modalService: BsModalService,
    private readonly localeService: BsLocaleService,
    private readonly formBuilder: FormBuilder,
    private readonly revisionService: RevisionService,
    private readonly dynamicQuestionnaireService: DynamicQuestionnaireService,
    private readonly surveyService: SurveyService,
    private readonly campaignService: CampaignService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly consReportService: ConsReportService,
    @Inject(LOCALE_ID) private readonly locale: string,
    private http: HttpClient) {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.isOnline = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });

    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });

    this.translate.get('there_no_active_snapshot_for_this_project').subscribe(result => {
      this.thereNoActiveSnapshotForThisProject = result;
    });

    this.translate.get('revision').subscribe(result => {
      this.revisionTxt = result;
    });
    this.translate.get('created_successfully').subscribe(result => {
      this.createSuccessTxt = result;
    });
    this.translate.get('select_deselect_object').subscribe(result => {
      this.selectDeselectLabels = result;
    });
    this.translate.get('data_will_be_synced').subscribe(result => {
      this.tooltipText2 = result;
    });
    this.translate.get('entire_survey').subscribe(result => {
      this.entireSurvey = result;
    });
    this.translate.get('campaign_profiling').subscribe(result => {
      this.campaignProfiling = result;
    });
    this.localeService.use(this.locale);
    this.translate.get('no_revision_txt').subscribe(result => {
      this.noChangeRevisionTxt = result;
    });
  }

  async ngOnInit() {
    this.scrollToTop();
    this.projectId = +this.route.snapshot.params['id'];
    this.campaignId = +this.route.snapshot.params['campaignId'];
    this.currentUser = this.userService.getCurrentUser();

      this.project = this.route.snapshot.data.project.project;
      if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
        this.UPM = this.project.ProjectMapping.find(item => item.users_id === this.currentUser.id);
      }

      if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
        this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
      }

      if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
        this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
      }

      if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
        const canExportToExcel = this.usersServiceComponentMappings.filter(item => (
          item.services_components_mapping && item.services_components_mapping.id === 10 && item.user_type_id === this.currentUser.role.id)
        );
        if (canExportToExcel && canExportToExcel.length > 0) {
          this.canExportToExcel = true;
        }

        const canMakeRevision = this.usersServiceComponentMappings.filter(item => (
          item.services_components_mapping && item.services_components_mapping.id === 11 && item.user_type_id === this.currentUser.role.id)
        );
        if (canMakeRevision && canMakeRevision.length > 0) {
          this.canMakeRevision = true;
        }
      }
      else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
        const canExportToExcel = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id === 10));
        if (canExportToExcel && canExportToExcel.length > 0) {
          this.canExportToExcel = true;
        }

        const canMakeRevision = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id === 11));
        if (canMakeRevision && canMakeRevision.length > 0) {
          this.canMakeRevision = true;
        }
      }

      const requests = await Promise.all([
        this.campaignService.getFarmersMappedToCampaign(this.campaignId).toPromise(),
        this.surveyService.getAllSurveysForProject(this.projectId).toPromise(),
        this.dynamicQuestionnaireService.getAnswerStatsForCampaign(this.campaignId).toPromise()
      ]);
      this.farmers = requests[0].data.filter(item => !item.is_discontinued);
      this.allSurveys = requests[1].message;      
      this.filteredfarmers = this.farmers;
      this.setSurveyStats(requests[2].message);

      this.setSurveys();
  }


  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }


  async setSurveyStats(stats) {
    if (stats !== 'survey_not_active') {
      const surveyStatsOnline = stats;
      const surveyStatsFromIDB = await idbApp.getAllProjects('dq_profiling');
      for (let i = 0; i < this.farmers.length; i++) {
        if (this.farmers[i].id && this.farmers[i].id !== 0) {
          const statsForFarmer = surveyStatsOnline.find(item => item.farmer_id === this.farmers[i].id);
          if (!statsForFarmer) {
            this.farmers[i]['percentageCompleted'] = 0;
            this.farmers[i]['lastProfiledAt'] = null;
          } else {
            this.farmers[i]['percentageCompleted'] = Math.round(parseInt(statsForFarmer.answered_questions) / parseInt(statsForFarmer.total_questions)*100);
            this.farmers[i]['lastProfiledAt'] = statsForFarmer.upd_date ? statsForFarmer.upd_date : statsForFarmer.cr_date;
          }
          const statsForFarmerFromIDB = surveyStatsFromIDB.find(item => parseInt(item.tmp_id) === parseInt(this.farmers[i].id) && parseInt(item.campaignId) === parseInt(this.campaignId));
          if (statsForFarmerFromIDB) {
            const x = JSON.parse(statsForFarmerFromIDB.stats);
            if (x) {
              this.farmers[i]['percentageCompleted'] = Math.round(parseInt(x[2]) / parseInt(x[1])*100);
              this.farmers[i]['lastProfiledAt'] = new Date(statsForFarmerFromIDB.updatedAt);
            }
          }
        } else {
          let tmp_id = this.farmers[i].tmp_id;
          if (tmp_id.indexOf('_Tab00') > -1) {
            tmp_id = tmp_id.substring(0, tmp_id.length - 1);
          }
          const statsForFarmerFromIDB = surveyStatsFromIDB.find(item => parseInt(item.tmp_id) === parseInt(tmp_id) && parseInt(item.campaignId) === parseInt(this.campaignId));
          if (statsForFarmerFromIDB) {
            const x = JSON.parse(statsForFarmerFromIDB.stats);
            if (x) {
              this.farmers[i]['percentageCompleted'] = Math.round(parseInt(x[2]) / parseInt(x[1])*100);
              this.farmers[i]['lastProfiledAt'] = new Date(statsForFarmerFromIDB.updatedAt);
            }
          }
        }
      }
    }
  }

  setSurveys(): void {
    if (this.allSurveys && this.allSurveys.length > 0) {
      const campaignSurveys = this.allSurveys.filter(item => item.survey_type === 'campaign' && parseInt(item.flex1) === parseInt(this.campaignId));
      if (campaignSurveys && campaignSurveys.length > 0) {
        this.snapshot = campaignSurveys[0]['survey_stage_changelogs'][0]['change_type'] === 'active' ? campaignSurveys[0] : undefined;
      } else {
        this.snapshot = undefined;  
      }
    } else {
      this.snapshot = undefined;
    }

    if(this.snapshot){
      if(this.snapshot.flex4){
        this.showSurveyManualIcon = true;
      } 
    }
  }

  getGridFilters() {
    const gridColums = ['farmer_code', 'name', 'lastProfiledAt'];
    const finalObj = {};
    if (this.farmers === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  goToFarmer(farmer): void {
    let url;
    if (this.snapshot) {
      if (farmer.id && farmer.id > 0) {
        url = `/questionnaire/respond/${farmer.project_id}/${farmer.id}`;
      } else if (farmer.tmp_id && !navigator.onLine) {
        if (farmer.tmp_id.indexOf('Tab00') > -1) {
          url = `/questionnaire/respond/${farmer.project_id}/${farmer.tmp_id.substring(0, farmer.tmp_id.length - 1)}`;
        } else {
          url = `/questionnaire/respond/${farmer.project_id}/${farmer.tmp_id}`;
        }
      } else if (farmer.tmp_id && navigator.onLine) {
        this.commonService.showToast('warning', 'please_wait_data_sync', {preventDuplicates: true});
        return;
      } else {
        url = '/error';
      }
      this.router.navigate([url], {queryParams: {campaignId: this.campaignId}});
    } else {
      this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
    }
  }

  goToViewFarmerDQ(farmer): void {
    let url2;
    if (this.snapshot) {
      if (farmer.id && farmer.id > 0) {
        url2 = `/questionnaire/view/${farmer.project_id}/${farmer.id}`;
      } else if (farmer.tmp_id && !navigator.onLine) {
        if (farmer.tmp_id.indexOf('Tab00') > -1) {
          url2 = `/questionnaire/view/${farmer.project_id}/${farmer.tmp_id.substring(0, farmer.tmp_id.length - 1)}`;
        } else {
          url2 = `/questionnaire/view/${farmer.project_id}/${farmer.tmp_id}`;
        }
      } else if (farmer.tmp_id && navigator.onLine) {
        this.commonService.showToast('warning', 'please_wait_data_sync', {preventDuplicates: true});
        return;
      } else {
        url2 = '/error';
      }
      this.router.navigate([url2], {queryParams: {campaignId: this.campaignId}});
    } else {
      this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
    }
  }

  makeRevisionDynamicQuestionnaire(): void {
    if (this.snapshot) {
      this.dynamicQuestionnaireService.makeRevision(this.snapshot.id)
      .subscribe(result => {
        if (result.code == 'success') {
          if (result.message == "no_change") {
            this.toastrService.warning(this.noChangeRevisionTxt);
          } else {
            this.toastrService.success(`${this.revisionTxt} ${result.message.revision_number} ${this.createSuccessTxt}`);
          }
        }
      })
    }
  }

  quickNoteView(currentItem, template: TemplateRef<any>){
    this.quickNoteData = currentItem;
    //console.log("quickNoteData ",this.quickNoteData[0].notes);
    this.quickNoteModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }
  closePrompt():void{
    if (this.quickNoteModal) this.quickNoteModal.hide();
  }

  //added by Kiran for escape button click event
  @HostListener('window:keyup', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if (this.quickNoteModal) {
        this.quickNoteModal.hide();
      }
    }
  }

  async syncDone(args) {
    if (navigator.onLine) {
      window.dispatchEvent(new Event('caching-now'));
      const requests = await Promise.all([
        // this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
        // this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise()
        this.campaignService.getFarmersMappedToCampaign(this.campaignId).toPromise(),
        this.dynamicQuestionnaireService.getAnswerStatsForCampaign(this.campaignId).toPromise()
      ]);
      this.farmers = requests[0].data;
      //this.sortFarmers();
      this.setSurveyStats(requests[1].message);

      await Promise.all([
        this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise()
      ])
      .then(result => {
        if (result[0]['code'] === "success") {
          for (let i = 0; i < result[0]['message'].length; i++) {
            //this.loadFarmerDataIntoCache(this.projectId, result[0]['message'][i], result[0]['message'][i+1]);
            this.loadFarmerCampaignDataIntoCache(this.campaignId, result[0]['message'][i], result[0]['message'][i+1]);
          }
        }
      }).catch(e => {});


      window.dispatchEvent(new Event('data-synced'));
    }
  }

  sortFarmers(): void {
    this.farmers.sort((a, b) => {
      if (a.cr_date < b.cr_date) {
        return 1;
      }
      if (a.cr_date > b.cr_date) {
        return -1;
      }
      return 0;
    });
  }

  async loadFarmerDataIntoCache(projectId, gte, lt) {
    if (projectId && projectId > 0) {  
      await Promise.all([
        this.dynamicQuestionnaireService.getQAnswersForProjectPaginated(projectId, gte, lt).toPromise()
      ])
      .then(result => {
        console.log('result', result);
      }).catch(e => {});
    }
  }

  async loadFarmerCampaignDataIntoCache(campaignId, gte, lt) {
    if (campaignId && campaignId > 0) {
      await Promise.all([
        this.dynamicQuestionnaireService.getQAnswersForCampaignPaginated(campaignId, gte, lt).toPromise()
      ])
      .then(result => {
      }).catch(e => {});
    }
  }

  // Toggle a particular farmer's selection status
  toggleThisFarmerSelect(id): void {
    if (this.selectedFarmers.indexOf(id) > -1) {
      this.selectedFarmers = this.selectedFarmers.filter(item => item !== id);
    }
    else {
      this.selectedFarmers.push(id);
    }
    // console.log("single", this.selectedFarmers)
    this.setTooltipText();
  }

  // Select / deselect all farmers in the array
  toggleAllFarmers(): void {
    // If all farmers are already selected, then deselect all farmers
    if (this.selectedFarmers.length === this.farmers.length) {
      this.selectedFarmers = [];
    }
    // If all farmers are NOT already selected, select all the farmers
    else {
      this.selectedFarmers = [];
      for (const val of this.farmers) {
        this.selectedFarmers.push(val.id);
      }
    }
    this.setTooltipText();
  }

  setTooltipText(): void {
    if (this.farmers && this.selectedFarmers) {
      if (this.selectedFarmers.length === this.farmers.length) {
        // Deselect farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.deselect} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.deselect} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      } else {
        // Select farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.select} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.select} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      }
    }
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  sortBy(sortOn: string) {
    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    if (sortBy === 'name') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.farmers.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.farmers.sort((a, b) => {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'tel') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.farmers.sort((a, b) => {
        if (a.tel_number < b.tel_number) {
          return 1;
        }
        if (a.tel_number > b.tel_number) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'date') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.farmers.sort((a, b) => {
        if (a.lastProfiledAt === b.lastProfiledAt) {
            return 0;
        }
    
        // nulls sort after anything else
        if (a.lastProfiledAt === null) {
            return 1;
        }
        if (b.lastProfiledAt === null) {
            return -1;
        }
        if (a.lastProfiledAt < b.lastProfiledAt) {
          return 1;
        }
        if (a.lastProfiledAt > b.lastProfiledAt) {
          return -1;
        }
        return 0;
      });
    }

  }

  descendic(sortBy: string) {
    this.isAscending = true;
    if (sortBy === 'name') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.farmers.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.farmers.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'tel') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.farmers.sort((a, b) => {
        if (a.tel_number > b.tel_number) {
          return 1;
        }
        if (a.tel_number < b.tel_number) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'date') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.farmers.sort((a, b) => {
        if (a.lastProfiledAt === b.lastProfiledAt) {
          return 0;
      }
  
      // nulls sort after anything else
      if (a.lastProfiledAt === null) {
          return -1;
      }
      if (b.lastProfiledAt === null) {
          return 1;
      }
        if (a.lastProfiledAt > b.lastProfiledAt) {
          return 1;
        }
        if (a.lastProfiledAt < b.lastProfiledAt) {
          return -1;
        }
        return 0;
      });
    }
  }


  exportFarmers(template: TemplateRef<any>) {
    if (this.snapshot) {
      this.spinner.show();
      this.selectedTabCode = undefined;
      if (this.selectedFarmers.length) {
        if (this.projectId != null && this.projectId != 0) {
          this.submitTabName()
        }
      } else {
        this.toastrService.info('Information: ' + "Select Farmers first");
      }
    } else {
      this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
    }
  }

  //download excel for campaign profiling
  async submitTabName() {
    this.spinner.show();
    let selTemp = this.farmers.filter(f => this.selectedFarmers.includes(f.id) && f.percentageCompleted);
    selTemp = selTemp.map((f) => {
      return f.id
    });
    const exportExcelRequest = {
      "tabCode": this.selectedTabCode,
      "farmers": selTemp
    };
    // this code is with XLSX
    let chunk;
    let splittedArrayLength = 500; //1000
    let splittedArray = [];
    while (selTemp.length > 0) {
      chunk = selTemp.splice(0, splittedArrayLength);
      splittedArray.push(chunk);
    }
    var wb = XLSX.utils.book_new();
    for (let i = 0; i < splittedArray.length; i++) {
      const newRequest = {
        "tabCode": this.selectedTabCode,
        "farmers": splittedArray[i]
      };
      const requests = await Promise.all([
        this.consReportService.getCampaignReport(this.projectId, this.campaignId, newRequest).toPromise()
      ])

      var wsBooks = XLSX.utils.json_to_sheet(requests[0].message);
      var wscols = [
        { wpx: 80 }, // "pixels
        { wpx: 80 },
        { wpx: 120 },
        { wpx: 300 },
        { wpx: 100 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 }
      ];

      wsBooks['!cols'] = wscols;
      const sheetName = `Farmer Sheet ${i + 1}`
      XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);
    }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: fileType });
    let linkdownload = document.createElement('a');
    linkdownload.href = URL.createObjectURL(blob);
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const today1 = dd + '-' + mm + '-' + yyyy;
    linkdownload.download = 'P2F-Roots_' + this.campaignProfiling + '_' + this.entireSurvey + '_Project_' + this.projectId + '_' + today1 + '.xlsx';
    linkdownload.style.display = 'none';
    document.body.appendChild(linkdownload);
    linkdownload.click();
    linkdownload.remove();
    this.selectedFarmers = [];
    this.toastrService.success("Excel Downloaded");
    this.spinner.hide();
  }

  openSurveyManual() {
    const docUrl = `${environment.apiBaseUrl}/${this.locale.substr(0, 2)}/assets/uploads/surveyManual/${this.snapshot.flex4}`;
    const docExtension = docUrl.split(/[#?]/)[0].split('.').pop().trim();
    this.http
      .get(docUrl, {
        responseType: 'blob',
      })
      .subscribe(response => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.style.display = 'none';
        link.download = `Survey_manual.${docExtension}`;
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
        link.remove();
      });
  }
  //filter farmers array based on search text
  filterFarmers(){ 
    this.farmers = this.filteredfarmers;
    if(this.searchText.length > 0){
      const filterArr = this.farmers.filter(item => {
        if((item.farmer_code && item.farmer_code.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
        (item.name && item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
        (item.lastProfiledAt && item.lastProfiledAt.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
        (item.official_id && item.official_id.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)){
          return item; 
        }
      })
      this.farmers = filterArr;
    }
  }
}
