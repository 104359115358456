import { Component, OnInit, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-resetusernameandpassword',
  templateUrl: './resetusernameandpassword.component.html',
  styleUrls: ['./resetusernameandpassword.component.css']
})
export class ResetusernameandpasswordComponent implements OnInit {

  private readonly unsubscribe: Subject<void> = new Subject();
  currentUser = this.userService.getCurrentUser();
  isFirstTimeLogin = false;
  currentUserID: any;
  newUserID: any;
  oldpassword: any;
  newpassword: any;
  cfmpassword: any;

  public barLabel: string = 'Password strength:';
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];

  public capslockOn;

  // flag set in two components
  flagEn = false;
  flagEs = false;
  flagFr = false;
  flagZh = false;
  flagMg = false;
  flagTa = false;

  passChar = false;
  showPass = false;

  constructor(
    public userService: UserService,
    public toastrService: ToastrService,
    public router: Router,
    private readonly commonService: CommonService,
    private readonly loc: Location) {

    const localLang = this.loc.prepareExternalUrl('').replace(/\//g,'');

      if (localLang === 'en') {
        this.flagEn = true;
      }
      if (localLang === 'es') {
          this.flagEs = true;
      }
      if (localLang === 'fr') {
          this.flagFr = true;
      }
      if (localLang === 'zh') {
        this.flagZh = true;
      }
      if (localLang === 'ta') {
        this.flagTa = true;
      }
      if (localLang === 'mg') {
        this.flagMg = true;
      }
      if (localLang === 'en') {
        this.flagEn = true;
      }


    }

  @HostListener('window:click', ['$event']) onClick(event){
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      this.capslockOn = true;
     } else {
      this.capslockOn = false;
     }
    }

   @HostListener('window:keydown', ['$event'])
   onKeyDown(event){
   if (event.getModifierState && event.getModifierState('CapsLock')) {
     this.capslockOn = true;
     } else {
      this.capslockOn = false;
     }
   }

   @HostListener('window:keyup', ['$event'])
    onKeyUp(event){
    if (event.getModifierState && event.getModifierState('CapsLock')) {
     this.capslockOn = true;
    } else {
     this.capslockOn = false;
    }
   }

  ngOnInit() {
  }

  /*Reset password, as user first-time login*/
  resetUsernamePassword() {

    const currentUserID = this.currentUserID;
    const newUserID = this.newUserID;
    const oldPass = this.oldpassword;
    const newPass = this.newpassword;
    const confirmPass = this.cfmpassword;
    /*Check if user's newPass=confirmPass while reset*/

    if (!currentUserID) {
      this.commonService.showToast('error', 'enter_old_userid', {});
    }

    if (!newUserID) {
      this.commonService.showToast('error', 'enter_new_userid', {});
    }

    if (!oldPass) {
      this.commonService.showToast('error', 'enter_old_pw', {});
    }

    if (!newPass) {
      this.commonService.showToast('error', 'enter_new_pw', {});
    }

    if (!confirmPass) {
      this.commonService.showToast('error', 'confirm_pw_empty', {});
    }

    if ((newPass && confirmPass) && (newPass !== confirmPass)) {
      this.commonService.showToast('error', 'old_new_pw_error', {});
    } else {

      const Object ={
        "username": currentUserID,
        "newUsername": newUserID,
        "password":oldPass,
        "newPassword":newPass,
        "confirmPass": confirmPass
      }
      /*Check if user's old-pass(user entered)=currentpass(in db) @loggin*/
              this.userService.resetUsernamePassword(Object).pipe(takeUntil(this.unsubscribe))
                .subscribe(
                  (validauthdata) => {
                    if (validauthdata['msg'] === 'success') {
                              this.commonService.showToast('success', validauthdata['code'], {});
                              
                              this.router.navigate(['']);
                            
                    } else if (validauthdata['msg'] === 'fail') {
                      this.commonService.showToast('error', validauthdata['code'], {});
                      // if(validauthdata['code'] === 'reset_array_errors') {
                      //   this.commonService.showToast('error', validauthdata['code'], {});
                      // } else {
                      //   this.commonService.showToast('error', validauthdata['code'], {});
                      // }
                    }else{
                      this.toastrService.error(validauthdata['msgText']);
                    }
                  }, (error) => {
                    this.commonService.showToast('error', 'error_updating_pw', {});
                  });
    }
  }

}
