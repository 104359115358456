import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare var idbApp;

if (environment.production) {
  enableProdMode();

}
localStorage.setItem('apiBaseUrl', environment.apiBaseUrl );
localStorage.setItem('port', environment.port);
// console.log('now setting apiBaseUrl to idb', environment.apiBaseUrl);
let obj = {
  url: environment.apiBaseUrl
};
obj['apiBaseUrl_key'] = 'apiBaseUrl_key';
idbApp.addStoreInfo([obj], 'apiBaseUrl');

platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => console.log(err));
