import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocaleService {
  constructor(
    private readonly translate: TranslateService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {
      translate.addLangs(['en', 'es']);
      translate.setDefaultLang('en');
      translate.use(this.locale);
  }
}
