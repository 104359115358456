import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { CampaignService } from '../../services/campaign.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
var CampaignSetupComponent = /** @class */ (function () {
    function CampaignSetupComponent(projectService, commonService, router, userService, campaignService, loc, _route, spinner, locale, translate, formBuilder, modalService, localeService) {
        var _this = this;
        this.projectService = projectService;
        this.commonService = commonService;
        this.router = router;
        this.userService = userService;
        this.campaignService = campaignService;
        this.loc = loc;
        this._route = _route;
        this.spinner = spinner;
        this.locale = locale;
        this.translate = translate;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.localeService = localeService;
        this.isOpen = false;
        this.isOpenEndDate = false;
        this.dateFormat = 'DD-MMM-YYYY';
        this.savingCampaign = false;
        this.projectId = 0;
        this.campaignId = 0;
        this.currentUser = this.userService.getCurrentUser();
        this.typeOfAdministration = '';
        this.projects = [];
        this.campaignStatuses = [];
        this._route.queryParamMap.subscribe(function (queryParams) {
            var qpObject = tslib_1.__assign({}, queryParams.keys, queryParams);
            _this.fromNavId = qpObject['params']['from'];
            //navigation changes added
            _this.translate.get('typeAdminTextArr').subscribe(function (val) {
                _this.typeOfAdministrationArr = val;
                if (_this.fromNavId === '2') {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[0];
                }
                else if (_this.fromNavId === '3') {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[1];
                }
            }, function (err) { return console.log('err', err); });
        });
        this.localeService.use(this.locale);
    }
    CampaignSetupComponent.prototype.ngOnInit = function () {
        var a = this._route.snapshot.params['id'];
        this.campaignId = a ? +a : 0;
        this.getProjects();
        this.getCampaignStatuses();
        this.createCampaignForm();
        if (this.campaignId && this.campaignId !== 0) {
            this.editCampaign();
        }
    };
    CampaignSetupComponent.prototype.createCampaignForm = function () {
        this.campaignForm = this.formBuilder.group({
            projectId: new FormControl(0, [Validators.required, Validators.min(1)]),
            name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
            objectives: new FormControl('', [Validators.maxLength(1000)]),
            description: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
            startDate: new FormControl(null, [Validators.required]),
            endDate: new FormControl(''),
            status: new FormControl('0'),
            results: new FormControl('', [Validators.maxLength(1000)]),
            closingRemarks: new FormControl('', [Validators.maxLength(1000)]),
            campaignType: new FormControl(false)
        });
    };
    CampaignSetupComponent.prototype.editCampaign = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, obj;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([
                                this.campaignService.getCampaignById(this.campaignId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['code'] === 'success') {
                            this.campaign = request[0]['message'];
                        }
                        obj = {
                            projectId: this.campaign.project_id,
                            name: this.campaign.name,
                            description: this.campaign.description,
                            objectives: this.campaign.objectives,
                            startDate: new Date(this.campaign.start_date),
                            endDate: this.campaign.end_date ? new Date(this.campaign.end_date) : '',
                            status: this.campaign.status ? +this.campaign.status : '0',
                            results: this.campaign.results,
                            closingRemarks: this.campaign.closing_remarks,
                            campaignType: this.campaign.campaign_type
                        };
                        this.campaignForm.patchValue(obj);
                        if (this.campaign.closed_date) {
                            this.campaignForm.disable();
                        }
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    CampaignSetupComponent.prototype.getProjects = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.projectService.GetProjectsBasicInfo().toPromise()
                        ])];
                    case 1:
                        request = _a.sent();
                        if (request && request[0]['msg'] === 'success') {
                            this.projects = request[0].data;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    //Redirect to campaign management on click of breadcrumb
    CampaignSetupComponent.prototype.redirectToCampaignManagement = function () {
        var url = "/campaignmanagement";
        this.router.navigate([url], { queryParams: { from: this.fromNavId } });
    };
    CampaignSetupComponent.prototype.saveUpdateCampaign = function () {
        var _this = this;
        this.savingCampaign = true;
        var name = this.campaignForm['value'].name;
        var projectId = this.campaignForm['value'].projectId;
        var objectives = this.campaignForm['value'].objectives;
        var description = this.campaignForm['value'].description;
        var startDate = this.campaignForm['value'].startDate;
        var endDate = this.campaignForm['value'].endDate;
        var status = this.campaignForm['value'].status;
        var results = this.campaignForm['value'].results;
        var campaignType = this.campaignForm['value'].campaignType;
        if (!projectId || projectId === 0 || !name || name.length === 0 ||
            !description || description.length === 0 || !startDate || startDate.length === 0) {
            this.savingCampaign = false;
            return;
        }
        if (endDate && endDate < startDate) {
            this.commonService.showToast('error', 'invalid_end_date', {});
            this.savingCampaign = false;
            return;
        }
        var campaignToSaveOrUpdate = {
            projectId: projectId,
            name: name,
            objectives: objectives,
            description: description,
            startDate: startDate,
            endDate: endDate,
            status: status !== 0 ? status : null,
            results: results,
            campaignType: campaignType
        };
        this.campaignService.saveOrUpdateCampaign(this.campaignId, campaignToSaveOrUpdate)
            .subscribe(function (result) {
            console.log('result', result);
            _this.savingCampaign = false;
            if (result.code === 'success') {
                if (_this.campaignId > 0) {
                    _this.commonService.showToast('success', 'campaign_updated_success', {});
                }
                else {
                    _this.commonService.showToast('success', 'campaign_saved_success', {});
                }
                _this.router.navigate(['/campaignmanagement'], { queryParams: { from: _this.fromNavId } });
            }
        });
    };
    CampaignSetupComponent.prototype.cancel = function (template) {
        if (this.campaignForm.dirty) {
            this.confirmCancelModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        }
        else {
            this.confirmCancel();
        }
    };
    CampaignSetupComponent.prototype.confirmModal = function (what) {
        if (what === 'yes') {
            if (this.confirmCancelModal) {
                this.confirmCancelModal.hide();
            }
            this.confirmCancel();
        }
        else {
            if (this.confirmCancelModal) {
                this.confirmCancelModal.hide();
            }
        }
    };
    CampaignSetupComponent.prototype.confirmCancel = function () {
        var url = "/campaignmanagement";
        this.router.navigate([url], { queryParams: { from: this.fromNavId } });
    };
    // Implemented export to excel for campaigns
    CampaignSetupComponent.prototype.getCampaignStatuses = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.campaignService.getCampaignStatuses().toPromise()
                        ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['msg'] === 'success') {
                            this.campaignStatuses = request[0]['data'];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CampaignSetupComponent.prototype.checkCampaignType = function (e, template) {
        if (this.campaignId && this.campaignId !== 0 && e.target.checked == false) {
            this.campaignTypeModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        }
    };
    CampaignSetupComponent.prototype.confirmTypeModal = function () {
        if (this.campaignTypeModal) {
            this.campaignTypeModal.hide();
        }
    };
    return CampaignSetupComponent;
}());
export { CampaignSetupComponent };
