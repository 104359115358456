import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/login.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SuppliersService } from '../../services/suppliers.service';
import { UserService } from '../../services/user.service';
import { Location } from '@angular/common';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DualListComponent } from 'angular-dual-listbox';
var SupplierComponent = /** @class */ (function () {
    function SupplierComponent(commonService, router, _route, spinner, toastrService, loginService, fb, modalService, translate, suppService, userService, activeRoute, document, location, localeService, locale) {
        var _this = this;
        this.commonService = commonService;
        this.router = router;
        this._route = _route;
        this.spinner = spinner;
        this.toastrService = toastrService;
        this.loginService = loginService;
        this.fb = fb;
        this.modalService = modalService;
        this.translate = translate;
        this.suppService = suppService;
        this.userService = userService;
        this.activeRoute = activeRoute;
        this.document = document;
        this.location = location;
        this.localeService = localeService;
        this.locale = locale;
        this.isOpen = false;
        this.f1Submitted = false;
        this.countryData = {};
        this.autoSelectData = {};
        this.multiSelectData = {};
        this.tableFinalData = [];
        this.services = [];
        this.userTypes = [];
        this.serviceComponents = [];
        this.masterServiceComponentArray = [];
        this.serviceComponentsMapping = [];
        this.rowsToShow = [];
        this.servicesArray = [];
        this.componentsArray = [];
        // userFilter: any = { name: '' };
        this.userFilter = {};
        this.isDeepRoots = false;
        this.currentUser = this.userService.getCurrentUser();
        this.page = 1;
        this.itemsPerPage = 10;
        this.confirmModal = true;
        this.confirmUpdateModal = true;
        this.customdata = [];
        this.customdataModified = [];
        this.userTypeAddArr = [];
        this.userTypeCheckedForAdd = [];
        this.psmCheckedArray = [];
        this.addForm = false;
        this.editForm = false;
        this.showExportButtonHere = false;
        this.typeOfAdministration = '';
        this.currentLocale = '';
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'Un-Select All',
            allowSearchFilter: true
        };
        //angular dual list
        this.keepSorted = true;
        this.filter = false;
        this.userAdd = '';
        this.disabled = false;
        this.sourceLeft = true;
        this.format = DualListComponent.DEFAULT_FORMAT;
        this.farmers = [];
        this.farmersInProject = [];
        this.activeRoute.paramMap.subscribe(function (params) {
            _this.activeRoute.queryParamMap.subscribe(function (queryParams) {
                var qpObject = tslib_1.__assign({}, queryParams.keys, queryParams);
                _this.origin = qpObject['params']['origin'];
                _this.fromNavId = qpObject['params']['from'];
                _this.translate.get('typeAdminTextArr').subscribe(function (val) {
                    _this.typeOfAdministrationArr = val;
                }, function (err) { return console.log('err', err); });
                if (_this.fromNavId == 2) {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[0];
                }
                else if (_this.fromNavId == 3) {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[1];
                }
                else if (_this.fromNavId == 6) {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[2];
                }
            });
        });
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
        this.translate.get('selectAllText').subscribe(function (result) {
            _this.dropdownSettings.selectAllText = result;
        });
        this.translate.get('unSelectAllText').subscribe(function (result) {
            _this.dropdownSettings.unSelectAllText = result;
        });
        this.localeService.use(this.locale);
    }
    SupplierComponent.prototype.scrollToTop = function () {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    SupplierComponent.prototype.onKeydownHandler = function (event) {
        if (this.addForm) {
            this.hideAddUserModal(this.supplierForm);
        }
        else if (this.editForm) {
            this.hideUpdateModal(this.editSupplierForm);
        }
    };
    SupplierComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentLocale = "field_label_" + this.locale.replace('-US', '');
        this.scrollToTop();
        this.supplierForm = this.fb.group({});
        this.editSupplierForm = this.fb.group({});
        this.ecosystem_id = localStorage.getItem('ecosystem_id');
        this.getMasterData();
        this.services = this._route.snapshot.data.commonData.services.data;
        this.userTypes = this._route.snapshot.data.commonData.rolesToMapToEcosystem.data;
        this.serviceComponents = this._route.snapshot.data.commonData.serviceComponents.data;
        for (var i = 0; i < this.userTypes.length; i++) {
            this.userTypes[i].uniqueid = this.userTypes[i]['id'] + this.userTypes[i]['code'];
        }
        for (var i = 0; i < this.serviceComponents.length; i++) {
            if (this.serviceComponentsMapping[this.serviceComponents[i]['services_id']]) {
                this.serviceComponentsMapping[this.serviceComponents[i]['services_id']]++;
            }
            else {
                this.serviceComponentsMapping[this.serviceComponents[i]['services_id']] = 0;
            }
        }
        var _loop_1 = function (i) {
            var componentsInService = void 0;
            this_1.masterServiceComponentArray.push(this_1.services[i]);
            componentsInService = this_1.serviceComponents.filter(function (item) { return item.services_id == _this.services[i].id; });
            if (componentsInService && componentsInService.length > 0) {
                var componentsInService2 = this_1.masterServiceComponentArray.concat(componentsInService);
                this_1.masterServiceComponentArray = componentsInService2;
            }
            else {
                this_1.services[i]['noComponents'] = true;
            }
        };
        var this_1 = this;
        for (var i = 0; i < this.services.length; i++) {
            _loop_1(i);
        }
        var _loop_2 = function (i) {
            this_2.masterServiceComponentArray[i]['opened'] = true;
            if (this_2.masterServiceComponentArray[i].hasOwnProperty('component_service_level')) {
                this_2.masterServiceComponentArray[i]['displaycheck'] = true;
            }
            else {
                if (this_2.masterServiceComponentArray.find(function (item) { return item.services_id
                    === _this.masterServiceComponentArray[i]['id']; }) === undefined) {
                    this_2.masterServiceComponentArray[i]['displaycheck'] = true;
                }
                else {
                    this_2.masterServiceComponentArray[i]['displaycheck'] = false;
                }
            }
            if (this_2.masterServiceComponentArray[i].services_id) {
                this_2.rowsToShow.push(this_2.masterServiceComponentArray[i].id);
            }
            this_2.masterServiceComponentArray[i]['uniqueid'] = this_2.masterServiceComponentArray[i]['id'] + this_2.masterServiceComponentArray[i]['code'];
            if (this_2.masterServiceComponentArray[i]['services_id']) {
                this_2.masterServiceComponentArray[i]['uniqueid'] = this_2.masterServiceComponentArray[i]['uniqueid'] + "C";
            }
            else {
                this_2.masterServiceComponentArray[i]['uniqueid'] = this_2.masterServiceComponentArray[i]['uniqueid'] + "S";
            }
        };
        var this_2 = this;
        for (var i = 0; i < this.masterServiceComponentArray.length; i++) {
            _loop_2(i);
        }
    };
    SupplierComponent.prototype.psmChecked = function (event, item, parentServiceEvent) {
        if (parentServiceEvent) {
            // if the parent service checkbox is checked, add the component to customData
            if (parentServiceEvent.target.checked) {
                try {
                    this.supplierForm.removeControl(item.uniqueid);
                }
                catch (_a) { }
                this.supplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
                if (item.services_id) {
                    this.customdata = this.customdata.filter(function (one) { return one.components_id !== item.id; });
                    this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id });
                }
                else {
                    this.customdata = this.customdata.filter(function (one) { return one.services_id !== item.id; });
                    this.customdata.push({ 'name': item.name, 'services_id': item.id });
                }
                this.psmCheckedArray.push(item.uniqueid);
            }
            // if parent service checkbox is unchecked, remove all child components from customData
            else {
                try {
                    this.supplierForm.removeControl(item.uniqueid);
                }
                catch (_b) { }
                this.supplierForm.addControl((item.uniqueid), new FormControl());
                if (item.services_id) {
                    this.customdata = this.customdata.filter(function (one) { return one.components_id !== item.id; });
                }
                // this is a noComponents service (service without child components)
                else {
                    this.customdata = this.customdata.filter(function (one) { return one.services_id !== item.id; });
                }
                this.psmCheckedArray = this.psmCheckedArray.filter(function (one) { return one !== item.uniqueid; });
            }
        }
        else {
            if (event.target.checked) {
                if (item.hasOwnProperty('component_service_level')) {
                    this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id });
                }
                else {
                    this.customdata.push({ 'name': item.name, 'services_id': item.id });
                }
            }
            else {
                if (item.services_id) {
                    this.customdata = this.customdata.filter(function (one) { return one.components_id !== item.id; });
                }
                // this is a noComponents service (service without child components)
                else {
                    this.customdata = this.customdata.filter(function (one) { return one.services_id !== item.id; });
                }
            }
        }
    };
    SupplierComponent.prototype.psmCheckedEdit = function (event, item, parentServiceEvent) {
        if (parentServiceEvent) {
            // if the parent service checkbox is checked, add the component to customData
            if (parentServiceEvent.target.checked) {
                try {
                    this.editSupplierForm.removeControl(item.uniqueid);
                }
                catch (_a) { }
                this.editSupplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
                if (item.services_id) {
                    this.customdata = this.customdata.filter(function (one) { return one.components_id !== item.id; });
                    this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id });
                }
                else {
                    if (this.customdata[0]['services'] && this.customdata[0]['services']['name']) {
                        this.customdata = this.customdata.filter(function (one) { return one.services_id !== item.id; });
                    }
                    else {
                        console.log('');
                        this.customdata = this.customdata.filter(function (one) { return one.services_id !== item.id; });
                    }
                    this.customdata.push({ 'name': item.name, 'services_id': item.id });
                }
            }
            // if parent service checkbox is unchecked, remove all child components from customData
            else {
                try {
                    this.editSupplierForm.removeControl(item.uniqueid);
                }
                catch (_b) { }
                this.editSupplierForm.addControl((item.uniqueid), new FormControl());
                if (item.services_id) {
                    this.customdata = this.customdata.filter(function (one) { return one.components_id !== item.id; });
                }
                else {
                    if (this.customdata[0]['services'] && this.customdata[0]['services'['name']]) {
                        this.customdata = this.customdata.filter(function (one) { return one.services_id !== item.id; });
                    }
                    else {
                        console.log('');
                        this.customdata = this.customdata.filter(function (one) { return one.services_id !== item.id; });
                    }
                }
            }
        }
        else {
            if (event.target.checked) {
                if (item.hasOwnProperty('component_service_level')) {
                    this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id });
                }
                else {
                    this.customdata.push({ 'name': item.name, 'services_id': item.id });
                }
            }
            else {
                // this is a component
                if (item.services_id) {
                    this.customdata = this.customdata.filter(function (one) { return one.components_id !== item.id; });
                }
                // this is a noComponents service (service without child components)
                else {
                    this.customdata = this.customdata.filter(function (one) { return one.services_id !== item.id; });
                }
            }
        }
    };
    SupplierComponent.prototype.psmCheckedParentService = function (event, item) {
        var componentsToToggle = this.masterServiceComponentArray.filter(function (one) { return one.services_id == item.id; });
        if (componentsToToggle && componentsToToggle.length > 0) {
            this.psmChecked(null, item, event);
            for (var i = 0; i < componentsToToggle.length; i++) {
                // if the parent service checkbox was checked, then check all the component checkboxes
                if (event.target.checked) {
                    this.psmChecked(null, componentsToToggle[i], event);
                }
                // if the box was unchecked then uncheck all component checkboxes
                else {
                    console.log('');
                    this.psmChecked(null, componentsToToggle[i], event);
                }
            }
        }
    };
    SupplierComponent.prototype.psmCheckedEditParentService = function (event, item) {
        var componentsToToggle = this.masterServiceComponentArray.filter(function (one) { return one.services_id == item.id; });
        if (componentsToToggle && componentsToToggle.length > 0) {
            for (var i = 0; i < componentsToToggle.length; i++) {
                if (event.target.checked) {
                    this.psmCheckedEdit(null, componentsToToggle[i], event);
                }
                // if the box was unchecked then uncheck all component checkboxes
                else {
                    console.log('');
                    this.psmCheckedEdit(null, componentsToToggle[i], event);
                }
            }
        }
    };
    SupplierComponent.prototype.psmCheckedAll = function (event) {
        if (this.masterServiceComponentArray && this.masterServiceComponentArray.length > 0) {
            this.customdata = [];
            this.psmCheckedArray = [];
            var _loop_3 = function (i) {
                // if the parent service checkbox was checked, then check all the component checkboxes
                var item = this_3.masterServiceComponentArray[i];
                if (event.target.checked) {
                    try {
                        this_3.supplierForm.removeControl(item.uniqueid);
                    }
                    catch (_a) { }
                    this_3.supplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
                    if (item.services_id) {
                        this_3.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id });
                    }
                    else {
                        this_3.customdata.push({ 'name': item.name, 'services_id': item.id });
                    }
                    this_3.psmCheckedArray.push(item.uniqueid);
                    this_3.supplierForm.markAsDirty();
                }
                else if (!event.target.checked && item.is_modifiable == 'N' && item.is_default == 'Y') {
                    try {
                        this_3.supplierForm.removeControl(item.uniqueid);
                    }
                    catch (_b) {
                    }
                    this_3.supplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
                    if (item.services_id) {
                        this_3.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id });
                    }
                    else {
                        this_3.customdata.push({ 'name': item.name, 'services_id': item.id });
                    }
                    this_3.psmCheckedArray.push(item.uniqueid);
                    this_3.supplierForm.markAsDirty();
                }
                // if the box was unchecked then uncheck all component checkboxes
                else {
                    try {
                        this_3.supplierForm.removeControl(item.uniqueid);
                    }
                    catch (_c) { }
                    this_3.supplierForm.addControl((item.uniqueid), new FormControl());
                    this_3.psmCheckedArray = this_3.psmCheckedArray.filter(function (one) { return one !== item.uniqueid; });
                    if (item.services_id) {
                        this_3.customdata = this_3.customdata.filter(function (one) { return one.components_id !== item.id; });
                    }
                    else {
                        this_3.customdata = this_3.customdata.filter(function (one) { return one.services_id !== item.services_id; });
                    }
                }
            };
            var this_3 = this;
            for (var i = 0; i < this.masterServiceComponentArray.length; i++) {
                _loop_3(i);
            }
        }
    };
    SupplierComponent.prototype.psmCheckedEditAll = function (event) {
        if (this.masterServiceComponentArray && this.masterServiceComponentArray.length > 0) {
            this.customdata = [];
            for (var i = 0; i < this.masterServiceComponentArray.length; i++) {
                // if the parent service checkbox was checked, then check all the component checkboxes
                var item = this.masterServiceComponentArray[i];
                if (event.target.checked) {
                    try {
                        this.editSupplierForm.removeControl(item.uniqueid);
                    }
                    catch (_a) { }
                    this.editSupplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
                    if (item.services_id) {
                        this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id });
                    }
                    else {
                        this.customdata.push({ 'name': item.name, 'services_id': item.id });
                    }
                }
                else if (!event.target.checked && item.is_modifiable == 'N' && item.is_default == 'Y') {
                    try {
                        this.editSupplierForm.removeControl(item.uniqueid);
                    }
                    catch (_b) {
                    }
                    this.editSupplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
                    if (item.services_id) {
                        this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id });
                    }
                    else {
                        this.customdata.push({ 'name': item.name, 'services_id': item.id });
                    }
                }
                // if the box was unchecked then uncheck all component checkboxes
                else {
                    try {
                        this.editSupplierForm.removeControl(item.uniqueid);
                    }
                    catch (_c) { }
                    this.editSupplierForm.addControl((item.uniqueid), new FormControl());
                    this.customdata = [];
                }
            }
            this.editSupplierForm.markAsDirty();
        }
    };
    SupplierComponent.prototype.userTypeCheckedEditAll = function (event) {
        if (this.userTypes && this.userTypes.length > 0) {
            this.customdata = [];
            for (var i = 0; i < this.userTypes.length; i++) {
                // if the parent service checkbox was checked, then check all the component checkboxes
                var item = this.userTypes[i];
                if (event.target.checked) {
                    try {
                        this.editSupplierForm.removeControl(item.uniqueid);
                    }
                    catch (_a) { }
                    this.editSupplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
                    this.customdata.push({ 'name': item.name, 'user_type_id': item.id });
                }
                // if the box was unchecked then uncheck all component checkboxes
                else {
                    try {
                        this.editSupplierForm.removeControl(item.uniqueid);
                    }
                    catch (_b) { }
                    this.editSupplierForm.addControl((item.uniqueid), new FormControl());
                    this.customdata = [];
                }
            }
            this.editSupplierForm.markAsDirty();
        }
    };
    SupplierComponent.prototype.userTypeCheckedAll = function (event) {
        if (this.userTypes && this.userTypes.length > 0) {
            this.customdata = [];
            for (var i = 0; i < this.userTypes.length; i++) {
                // if the parent service checkbox was checked, then check all the component checkboxes
                var item = this.userTypes[i];
                if (event.target.checked) {
                    try {
                        this.supplierForm.removeControl(item.uniqueid);
                    }
                    catch (_a) { }
                    this.supplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
                    this.customdata.push({ 'name': item.name, 'user_type_id': item.id });
                    this.userTypeCheckedForAdd.push(item.uniqueid);
                }
                // if the box was unchecked then uncheck all component checkboxes
                else {
                    try {
                        this.supplierForm.removeControl(item.uniqueid);
                    }
                    catch (_b) { }
                    this.supplierForm.addControl((item.uniqueid), new FormControl());
                    this.customdata = [];
                    this.userTypeCheckedForAdd = [];
                }
            }
            this.supplierForm.markAsDirty();
        }
    };
    SupplierComponent.prototype.userTypeChecked = function (event, item) {
        if (event.target.checked) {
            this.customdata.push({ 'name': item.name, 'user_type_id': item.id });
            this.userTypeCheckedForAdd.push(item.uniqueid);
        }
        else {
            this.customdata.splice(this.customdata.indexOf(this.customdata.find(function (data) { return data.user_type_id === item.id; })), 1);
            this.userTypeCheckedForAdd = this.userTypeCheckedForAdd.filter(function (one) { return one !== item.uniqueid; });
        }
    };
    SupplierComponent.prototype.inputValidator = function (event) {
        var pattern = RegExp(/^[a-zA-Z0-9 \r\n]+$/);
        if (!pattern.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^a-zA-Z0-9 (,)/=<>_*"&\n!?.-]/g, '');
            // invalid character, prevent input
        }
    };
    SupplierComponent.prototype.mdmFilterData = function (data) {
        return data;
    };
    SupplierComponent.prototype.serviceOpenCloseToggle = function (item) {
        var clickedItem = this.masterServiceComponentArray.filter(function (one) { return one.id === item.id; });
        var clickedItemObj = clickedItem[0];
        var index = this.masterServiceComponentArray.indexOf(clickedItemObj);
        this.masterServiceComponentArray[index]['opened'] = !this.masterServiceComponentArray[index]['opened'];
        if (!this.masterServiceComponentArray[index]['opened']) {
            var childItems_1 = this.masterServiceComponentArray.filter(function (one) { return one.services_id === item.id; });
            if (childItems_1 && childItems_1.length > 0) {
                var _loop_4 = function (i) {
                    var tempRowsToShow = this_4.rowsToShow.filter(function (item) { return item !== childItems_1[i].id; });
                    this_4.rowsToShow = tempRowsToShow;
                };
                var this_4 = this;
                for (var i = 0; i < childItems_1.length; i++) {
                    _loop_4(i);
                }
            }
        }
        else {
            var childItems = this.masterServiceComponentArray.filter(function (one) { return one.services_id == item.id; });
            if (childItems && childItems.length > 0) {
                for (var i = 0; i < childItems.length; i++) {
                    this.rowsToShow.push(childItems[i].id);
                }
            }
        }
    };
    SupplierComponent.prototype.getMDMDataValues = function () {
        var _this = this;
        this.suppService.getMDMDataValues(this.origin).subscribe(function (response) {
            _this.tableData = response;
            _this.tableFinalData = [];
            var finalObj = _this.tableData;
            if (_this.origin == 'VC_FARMER_MAPPING_MDM') {
                _this.vcFarmerMappingData = Object.assign([], response.data);
                for (var i = 0; i < _this.tableData.data.length; i++) {
                    _this.tableData.data[i]['users_id'] = _this.tableData.data[i]['users']['name'];
                    _this.tableData.data[i]['project_id'] = _this.tableData.data[i]['Project']['name'];
                    _this.tableData.data[i]['village_name'] = _this.tableData.data[i]['farmer_reg']['region_village']['village_name'];
                    _this.tableData.data[i]['user_category'] = {};
                    _this.tableData.data[i]['user_category']['name'] = _this.tableData.data[i]['users']['user_category']['name'];
                    _this.tableData.data[i]['user_category_id'] = _this.tableData.data[i]['users']['user_category']['name'];
                }
            }
            _this.tableData.data.forEach(function (element) {
                element.isAscending = false;
            });
            var newRecord = _this.supplierQuesData.data.filter(function (item) { return item.field_type === 'dropdown' && item.value_options != null; });
            var newObject = {};
            var _loop_5 = function (valueItem) {
                _this.tableData.data.forEach(function (element) {
                    var filteredobj = (valueItem.valueOptions.filter(function (ele) {
                        return ele.id === element[valueItem.mapping_table_field];
                    }));
                    if (filteredobj && filteredobj.length > 0) {
                        element[valueItem.mapping_table_field] = filteredobj[0].name;
                    }
                });
            };
            for (var _i = 0, newRecord_1 = newRecord; _i < newRecord_1.length; _i++) {
                var valueItem = newRecord_1[_i];
                _loop_5(valueItem);
            }
            if (_this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM' || _this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
                for (var _a = 0, _b = finalObj.data; _a < _b.length; _a++) {
                    var finalObjCurr = _b[_a];
                    _this.tableFinalData.push(finalObjCurr);
                }
                _this.tableFinalData.forEach(function (snap) {
                    snap.customAssociates = snap.customAssociates.split(',');
                });
                if (_this.tableFinalData && _this.origin == 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM' && _this.countryData['77']['data']) {
                    var ecosystemsAlreadyMapped = _this.tableFinalData.map(function (item) { return item.ecosystem_id; });
                }
                else if (_this.tableFinalData && _this.origin == 'ECOSYSTEM_USER_TYPE_MAPPING_MDM' && _this.countryData['78']['data']) {
                    var ecosystemsAlreadyMapped = _this.tableFinalData.map(function (item) { return item.ecosystem_id; });
                }
            }
            else if (_this.origin === 'ECOSYSTEM_USERS_MAPPING_MDM') {
                for (var _c = 0, _d = finalObj.data; _c < _d.length; _c++) {
                    var finalObjCurr = _d[_c];
                    var tmpObj = [];
                    for (var _e = 0, _f = _this.supplierQuesData.data; _e < _f.length; _e++) {
                        var supplierQuesData = _f[_e];
                        tmpObj.push(finalObjCurr[supplierQuesData.mapping_table_field]);
                    }
                    _this.tableFinalData.push(tmpObj);
                }
                if (_this.countryData['70'].data) {
                    var x = _this.countryData['70'].data.filter(function (item) { return item.user_type_id < 4; });
                    _this.countryData['70'].data = x;
                }
            }
            else if (_this.origin === 'USERPROGRAMMAPPING_MDM' && _this.countryData['24'].data) {
                var x = _this.countryData['24'].data.filter(function (item) { return item.user_type_id > 3; });
                _this.countryData['24'].data = x;
            }
            else if (_this.origin == 'VC_FARMER_MAPPING_MDM') {
                var newTableData = [];
                var _loop_6 = function (i) {
                    var x = newTableData.find(function (each) { return each.farmer_reg.region_village.village_name === _this.tableData.data[i].farmer_reg.region_village.village_name && each.users.name == _this.tableData.data[i].users.name && each.farmer_reg.project_id == _this.tableData.data[i].farmer_reg.project_id; });
                    if (!x) {
                        newTableData.push(_this.tableData.data[i]);
                    }
                };
                for (var i = 0; i < _this.tableData.data.length; i++) {
                    _loop_6(i);
                }
                _this.tableData.data = newTableData;
            }
            else {
                for (var _g = 0, _h = finalObj.data; _g < _h.length; _g++) {
                    var finalObjCurr = _h[_g];
                    var tmpObj = [];
                    for (var _j = 0, _k = _this.supplierQuesData.data; _j < _k.length; _j++) {
                        var supplierQuesData = _k[_j];
                        if (supplierQuesData.is_ref_modal === 'Y') {
                            _this.origin == 'VC_FARMER_MAPPING_MDM' ? tmpObj.push(finalObjCurr['Project']['name']) : tmpObj.push(finalObjCurr[supplierQuesData.ref_modal_name]['name']);
                            if (_this.origin === 'USERPROGRAMMAPPING_MDM' && supplierQuesData.ref_modal_name === 'users') {
                                tmpObj.push(finalObjCurr[supplierQuesData.mapping_table_field]);
                            }
                        }
                        else {
                            tmpObj.push(finalObjCurr[supplierQuesData.mapping_table_field]);
                        }
                    }
                    _this.tableFinalData.push(tmpObj);
                }
                if (_this.origin === 'UNITS_MDM' && _this.tableData.data && _this.tableData.data.length > 0 && _this.countryData[50]) {
                    var _loop_7 = function (i) {
                        var intValue = _this.countryData[50].data.filter(function (item) { return item.international_unit_to_save == _this.tableData.data[i]['international_unit']; });
                        if (intValue && intValue.length > 0) {
                            _this.tableData.data[i]['international_unit'] = intValue[0]['international_unit'];
                        }
                    };
                    for (var i = 0; i < _this.tableData.data.length; i++) {
                        _loop_7(i);
                    }
                }
            }
        });
    };
    SupplierComponent.prototype.getGridFilters = function () {
        var _this = this;
        var finalObj = {};
        if (this.supplierQuesData === undefined) {
            return {};
        }
        if (this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM' || this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
            finalObj['name'] = this.searchText;
        }
        else if (this.origin === 'ECOSYSTEM_USERS_MAPPING_MDM') {
            this.supplierQuesData.data.forEach(function (element) {
                finalObj[element.mapping_table_field] = _this.searchText;
            });
        }
        else {
            this.supplierQuesData.data.forEach(function (element) {
                if (element.is_ref_modal === 'Y') {
                    finalObj[element.ref_modal_name] = _this.searchText;
                }
                else {
                    finalObj[element.mapping_table_field] = _this.searchText;
                }
            });
        }
        return finalObj;
    };
    SupplierComponent.prototype.getMasterData = function () {
        var _this = this;
        this.suppService.getMaster(this.origin).subscribe(function (response) {
            _this.supplierQuesData = response;
            var validationsArray;
            var _loop_8 = function (i) {
                _this.supplierQuesData.data[i].validations = {};
                var validatorArr = [];
                if (_this.supplierQuesData.data[i].mandatory === 'Y') {
                    validatorArr.push(Validators.required);
                }
                if (_this.supplierQuesData.data[i].MDMValidation != null) {
                    validationsArray = JSON.parse(_this.supplierQuesData.data[i].MDMValidation.validation_obj);
                    for (var j = 0; j < validationsArray.length; j++) {
                        if (validationsArray[j].type === 'min') {
                            _this.supplierQuesData.data[i].validations['minlength'] = validationsArray[j].value;
                            validatorArr.push(Validators.minLength(validationsArray[j].value));
                        }
                        if (validationsArray[j].type === 'max') {
                            _this.supplierQuesData.data[i].validations['maxlength'] = validationsArray[j].value;
                            validatorArr.push(Validators.maxLength(validationsArray[j].value));
                        }
                        if (validationsArray[j].type === 'email') {
                            validatorArr.push(Validators.email);
                        }
                        if (validationsArray[j].type === 'number') {
                            validatorArr.push(Validators.pattern('^[0-9]{5,13}$'));
                        }
                    }
                }
                if (_this.supplierQuesData.data[i].field_type === 'dropdown') {
                    if (_this.supplierQuesData.data[i].value_options === null && _this.supplierQuesData.data[i].api_url) {
                        _this.countryData[_this.supplierQuesData.data[i].id] = {};
                        _this.suppService.getAPIURLMethod(_this.supplierQuesData.data[i].api_url).subscribe(function (response) {
                            _this.countryData[_this.supplierQuesData.data[i].id] = response;
                        });
                        _this.supplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({
                            disabled: _this.supplierQuesData.data[i].readonly === 'Y' ? true : false
                        }, validatorArr));
                    }
                    else if (_this.supplierQuesData.data[i].value_options) {
                        _this.valueOptions = JSON.parse(_this.supplierQuesData.data[i].value_options);
                        _this.supplierQuesData.data[i]['valueOptions'] = _this.valueOptions;
                        _this.supplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({
                            value: _this.valueOptions[0].id,
                            disabled: _this.supplierQuesData.data[i].readonly === 'Y' ? true : false
                        }, validatorArr));
                    }
                    else {
                        _this.supplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({
                            disabled: _this.supplierQuesData.data[i].readonly === 'Y' ? true : false
                        }, validatorArr));
                    }
                }
                else if (_this.supplierQuesData.data[i].field_type === 'autoselect') {
                    if (_this.supplierQuesData.data[i].value_options === null) {
                        _this.autoSelectData[_this.supplierQuesData.data[i].id] = {};
                        _this.suppService.getAPIURLMethod(_this.supplierQuesData.data[i].api_url).subscribe(function (response) {
                            _this.autoSelectData[_this.supplierQuesData.data[i].id] = response;
                        });
                        _this.supplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({
                            disabled: _this.supplierQuesData.data[i].readonly === 'Y' ? true : false
                        }, validatorArr));
                    }
                }
                else if (_this.supplierQuesData.data[i].field_type === 'multiselect') {
                    if (_this.supplierQuesData.data[i].value_options === null) {
                        _this.multiSelectData[_this.supplierQuesData.data[i].id] = {};
                        _this.suppService.getAPIURLMethod(_this.supplierQuesData.data[i].api_url).subscribe(function (response) {
                            _this.multiSelectData[_this.supplierQuesData.data[i].id] = response;
                        });
                        _this.supplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({
                            disabled: _this.supplierQuesData.data[i].readonly === 'Y' ? true : false
                        }, validatorArr));
                    }
                }
                else if (_this.supplierQuesData.data[i].field_type === 'date') {
                    _this.supplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({
                        value: null,
                        disabled: _this.supplierQuesData.data[i].readonly === 'Y' ? true : false
                    }, validatorArr));
                    _this.dateFormat = 'DD-MMM-YYYY';
                }
                else if (_this.supplierQuesData.data[i].field_type === 'duallist') {
                    if (_this.supplierQuesData.data[i].value_options === null) {
                        _this.countryData[_this.supplierQuesData.data[i].id] = {};
                    }
                }
                else {
                    _this.supplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({
                        value: null,
                        disabled: _this.supplierQuesData.data[i].readonly === 'Y' ? true : false
                    }, validatorArr));
                }
            };
            for (var i = 0; i < _this.supplierQuesData.data.length; i++) {
                _loop_8(i);
            }
            _this.getMDMDataValues();
        });
    };
    Object.defineProperty(SupplierComponent.prototype, "f1", {
        // convenience getter for easy access to form fields
        get: function () {
            return this.supplierForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    SupplierComponent.prototype.openModal = function (template, importUserModalBool) {
        if (importUserModalBool) {
            this.importUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        }
        else {
            this.confirmModal = true;
            this.addUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
            this.addForm = true;
            this.supplierForm.reset();
        }
        if (this.origin == 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
            this.userTypeCheckedForAdd = [];
        }
        this.customdata = [];
        if (this.origin == 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM') {
            this.customdata = [];
            this.psmCheckedArray = [];
            for (var i = 0; i < this.masterServiceComponentArray.length; i++) {
                if (this.masterServiceComponentArray[i]['is_default'] == 'Y') {
                    if (this.masterServiceComponentArray[i].hasOwnProperty('component_service_level')) {
                        this.customdata.push({ 'name': this.masterServiceComponentArray[i].name, 'services_id': this.masterServiceComponentArray[i].services_id, 'components_id': this.masterServiceComponentArray[i].id });
                    }
                    else {
                        this.customdata.push({ 'name': this.masterServiceComponentArray[i].name, 'services_id': this.masterServiceComponentArray[i].id });
                    }
                    this.psmCheckedArray.push(this.masterServiceComponentArray[i]['uniqueid']);
                }
            }
        }
        if (this.origin == 'VC_FARMER_MAPPING_MDM') {
            this.farmers = [];
            this.source = [];
            this.confirmed = [];
        }
    };
    SupplierComponent.prototype.randomString = function (length) {
        return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
    };
    SupplierComponent.prototype.onSelect = function (event) {
        this.selectedOption = event.item;
    };
    SupplierComponent.prototype.onMultiSelect = function (item, key, yesNo) {
        this.addItem([item], key, yesNo);
    };
    SupplierComponent.prototype.onSelectAll = function (items, key, yesNo) {
        this.addItem(items, key, yesNo);
    };
    SupplierComponent.prototype.onDeSelect = function (item, key, yesNo) {
        this.removeItem([item], key, yesNo);
    };
    SupplierComponent.prototype.onDeSelectAll = function (items, key, yesNo) {
        this.removeItem(items, key, yesNo);
    };
    SupplierComponent.prototype.addItem = function (items, key, yesNo) {
    };
    SupplierComponent.prototype.removeItem = function (items, key, yesNo) {
    };
    SupplierComponent.prototype.submitForm = function (form, event) {
        var _this = this;
        this.f1Submitted = true;
        if (form.valid) {
            var newRequestArray = [];
            var formCode = {
                'form_code': this.origin,
                'cr_user_id': this.currentUser.id,
                'upd_user_id': this.currentUser.id,
                'data': []
            };
            var newRequest = [];
            var _loop_9 = function (x) {
                var result = this_5.supplierQuesData.data.filter(function (item) { return item.id === +x; });
                var selectId = void 0;
                if (this_5.selectedOption) {
                    selectId = this_5.selectedOption.id;
                }
                if (result.length > 0)
                    if (form.getRawValue()[x] === null || form.getRawValue()[x] === '') {
                    }
                    else {
                        if (this_5.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM')
                            formCode['ecosystem_id'] = form.getRawValue()[x];
                        if (this_5.selectedOption && this_5.selectedOption.name === form.getRawValue()[x]) {
                            newRequest = {
                                "id": x, "value": selectId, "mapping_table": result[0].mapping_table,
                                "mapping_table_field": result[0].mapping_table_field
                            };
                        }
                        else {
                            newRequest = {
                                "id": x, "value": form.getRawValue()[x], "mapping_table": result[0].mapping_table,
                                "mapping_table_field": result[0].mapping_table_field
                            };
                        }
                    }
                formCode.data.push(newRequest);
            };
            var this_5 = this;
            for (var x in form.getRawValue()) {
                _loop_9(x);
            }
            if (this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM') {
                this.customdataModified = this.customdata;
                var servicesArray_1 = [];
                for (var i = 0; i < this.customdataModified.length; i++) {
                    if (!this.customdataModified[i]['components_id']) {
                        servicesArray_1.push(this.customdataModified[i]);
                    }
                }
                var itemsToRemove_1 = [];
                var _loop_10 = function (i) {
                    var isComponentSelected = this_6.customdataModified.filter(function (item) { return item.components_id != null && item.services_id === servicesArray_1[i]['services_id']; });
                    if (isComponentSelected && isComponentSelected.length > 0) {
                        // in customdataModified, keep the array items that have a component id & service id is equal to servicesArray[i]['id']
                        // keep the items that do not have a component id and services id is not equal to servicesArray[i]['id']
                        itemsToRemove_1 = itemsToRemove_1.concat(this_6.customdataModified.filter(function (item) { return item.components_id == null && item.services_id == servicesArray_1[i]['services_id']; }));
                    }
                };
                var this_6 = this;
                for (var i = 0; i < servicesArray_1.length; i++) {
                    _loop_10(i);
                }
                var _loop_11 = function (i) {
                    // in customdataModified, keep the array items that have a component id or that do not have a component id and the services_id is not equal to itemsToRemove[i]['id']
                    this_7.customdataModified = this_7.customdataModified.filter(function (item) { return item.components_id != null || (item.components_id == null && item.services_id !== itemsToRemove_1[i]['services_id']); });
                };
                var this_7 = this;
                for (var i = 0; i < itemsToRemove_1.length; i++) {
                    _loop_11(i);
                }
                formCode['customobj'] = this.customdataModified;
            }
            if (this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
                formCode['ecosystem_id'] = formCode.data[0].value;
                formCode['customobj'] = this.customdata;
                delete formCode.data;
            }
            if (this.origin === 'ECOSYSTEM_USERS_MAPPING_MDM') {
                var ecoid = formCode.data.find(function (ucval) { return ucval.mapping_table_field === 'ecosystem_id'; });
                var ucid = formCode.data.find(function (ucval) { return ucval.mapping_table_field === 'user_category_id'; });
                if (ecoid.value == '-1' && ucid.value != 1) {
                    this.commonService.showToast('error', 'Only users with Firmenich category can map to All ecosystems');
                    return false;
                }
            }
            if (this.origin === 'VC_FARMER_MAPPING_MDM') {
                var farmerObject = {};
                for (var i = 0; i < this.supplierQuesData.data.length; i++) {
                    if (this.supplierQuesData.data[i].id == 83) {
                        farmerObject = {
                            'id': this.supplierQuesData.data[i].id.toString(),
                            'mapping_table': this.supplierQuesData.data[i].mapping_table,
                            'mapping_table_field': this.supplierQuesData.data[i].mapping_table_field,
                            'value': this.confirmed
                        };
                        formCode.data.push(farmerObject);
                    }
                }
            }
            this.suppService.saveMDM(formCode).subscribe(function (response) {
                var responseData = response;
                if (responseData.status === 'success') {
                    if (_this.addUserModal) {
                        _this.addUserModal.hide();
                        _this.userTypeCheckedForAdd = [];
                    }
                    _this.commonService.showToast('success', responseData.code, {});
                    // If a unit is being added, it needs time to be mapped to the ecosystem
                    // Refresh the table with a delay
                    if (_this.origin == 'UNITS_MDM') {
                        setTimeout(function () {
                            _this.getMDMDataValues();
                        }, 3000);
                        _this.commonService.showToast('warning', 'update_ecosystem_settings', {});
                    }
                    // For non-Units MDMs, refresh the table immediately
                    else {
                        _this.getMDMDataValues();
                    }
                    _this.f1Submitted = false;
                    _this.supplierForm.reset();
                    _this.getMasterData();
                    _this.customdata = [];
                    _this.psmCheckedArray = [];
                }
                else {
                    _this.commonService.showToast('error', responseData.code, {});
                }
            });
        }
    };
    SupplierComponent.prototype.editThisUser = function (itemValue, template) {
        var _this = this;
        this.confirmUpdateModal = true;
        var newObject = {
            'id': itemValue,
            'form_code': this.origin,
        };
        if (this.origin == 'VC_FARMER_MAPPING_MDM') {
            this.farmers = [];
            this.source = [];
            this.confirmed = [];
        }
        this.suppService.getMDMDataValues(this.origin).subscribe(function (response) {
            var responseData = response;
            if (responseData.status === 'success') {
                if (_this.origin === 'VC_FARMER_MAPPING_MDM') {
                    _this.editSupplierDataFarmers = response.data.filter(function (item) { return item.project_id === response.data.find(function (each) { return each.id === itemValue; })['project_id']; });
                    for (var i = 0; i < _this.editSupplierDataFarmers.length; i++) {
                        _this.editSupplierDataFarmers[i]['user_category_id'] = _this.editSupplierDataFarmers[0]['users']['user_category']['id'];
                    }
                }
                _this.editSupplierData = response.data.filter(function (item) { return item.id === itemValue; })[0];
                var drpdownpatcharr_1 = {};
                var validationsArray = void 0;
                var _loop_12 = function (i) {
                    if (_this.autoSelectData[_this.supplierQuesData.data[i].id] && _this.supplierQuesData.data[i].mapping_table_field === 'country_id' && _this.editSupplierData.hasOwnProperty('country_id')) {
                        var countryName = _this.autoSelectData[_this.supplierQuesData.data[i].id].data.filter(function (item) { return item.id === _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field]; })[0];
                        drpdownpatcharr_1[_this.supplierQuesData.data[i].id] =
                            (_this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === null ||
                                _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === undefined) ? '' :
                                countryName.name;
                        //this.multiSelectData[this.supplierQuesData.data[i].id];
                    }
                    else if (_this.multiSelectData[_this.supplierQuesData.data[i].id] && _this.supplierQuesData.data[i].mapping_table_field === 'project_id' && _this.editSupplierData.hasOwnProperty('project_id')) {
                        var multiValItem = _this.multiSelectData[_this.supplierQuesData.data[i].id].data.filter(function (item) { return item.id === _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field]; })[0];
                        drpdownpatcharr_1[_this.supplierQuesData.data[i].id] =
                            (_this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === null ||
                                _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === undefined) ? '' :
                                [{ 'id': multiValItem.id, 'name': multiValItem.name }];
                    }
                    else if (_this.supplierQuesData.data[i].field_type === 'date') {
                        var dateOfEnroll = new Date(_this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field]);
                        drpdownpatcharr_1[_this.supplierQuesData.data[i].id] =
                            (_this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === null ||
                                _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === undefined) ? '' :
                                dateOfEnroll;
                    }
                    else {
                        if (_this.supplierQuesData.data[i].form_code == 'ECOSYSTEM_USERS_MAPPING_MDM') {
                            drpdownpatcharr_1[_this.supplierQuesData.data[i].id] =
                                (_this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === null ||
                                    _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === undefined) ? '' :
                                    (_this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field].id ? _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field].id.toString() : _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field].toString());
                        }
                        else {
                            drpdownpatcharr_1[_this.supplierQuesData.data[i].id] =
                                (_this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === null ||
                                    _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === undefined) ? '' :
                                    _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field].toString();
                        }
                    }
                    var validatorArr = [];
                    if (_this.supplierQuesData.data[i].mandatory === 'Y') {
                        validatorArr.push(Validators.required);
                    }
                    if (_this.supplierQuesData.data[i].MDMValidation != null) {
                        validationsArray = JSON.parse(_this.supplierQuesData.data[i].MDMValidation.validation_obj);
                        for (var j = 0; j < validationsArray.length; j++) {
                            if (validationsArray[j].type === 'min') {
                                validatorArr.push(Validators.minLength(validationsArray[j].value));
                            }
                            if (validationsArray[j].type === 'max') {
                                validatorArr.push(Validators.maxLength(validationsArray[j].value));
                            }
                            if (validationsArray[j].type === 'email') {
                                validatorArr.push(Validators.email);
                            }
                            if (validationsArray[j].type === 'number') {
                                validatorArr.push(Validators.pattern('^[0-9]*$'));
                            }
                        }
                    }
                    if (_this.supplierQuesData.data[i].field_type === 'autoselect') {
                        if (_this.supplierQuesData.data[i].value_options === null) {
                            _this.editSupplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({
                                disabled: _this.supplierQuesData.data[i].readonly === 'Y' ? true : false
                            }, validatorArr));
                        }
                    }
                    if (_this.supplierQuesData.data[i].field_type === 'dropdown') {
                        if (_this.supplierQuesData.data[i].value_options === null && _this.supplierQuesData.data[i].api_url) {
                            _this.countryData[_this.supplierQuesData.data[i].id] = {};
                            _this.suppService.getAPIURLMethod(_this.supplierQuesData.data[i].api_url).subscribe(function (response) {
                                _this.countryData[_this.supplierQuesData.data[i].id] = response;
                                if (_this.origin == 'UNITS_MDM') {
                                    var intValue = _this.countryData[50].data.filter(function (item) { return item.international_unit_to_save == drpdownpatcharr_1['52']; });
                                    if (intValue && intValue.length > 0) {
                                        drpdownpatcharr_1['52'] = intValue[0]['international_unit'];
                                    }
                                    _this.editSupplierForm.patchValue(drpdownpatcharr_1);
                                }
                            });
                            _this.editSupplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({ disabled: _this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true }, validatorArr));
                        }
                        else if (_this.supplierQuesData.data[i].value_options) {
                            _this.valueOptions = JSON.parse(_this.supplierQuesData.data[i].value_options);
                            _this.editSupplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({ disabled: _this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true }, validatorArr));
                        }
                        else {
                            _this.editSupplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({
                                disabled: true,
                            }, validatorArr));
                        }
                    }
                    else if (_this.supplierQuesData.data[i].field_type === 'multiselect') {
                        if (_this.supplierQuesData.data[i].value_options === null) {
                            _this.multiSelectData[_this.supplierQuesData.data[i].id] = {};
                            _this.suppService.getAPIURLMethod(_this.supplierQuesData.data[i].api_url).subscribe(function (response) {
                                _this.multiSelectData[_this.supplierQuesData.data[i].id] = response;
                            });
                            _this.editSupplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({
                                disabled: _this.supplierQuesData.data[i].readonly === 'Y' ? true : false
                            }, validatorArr));
                        }
                    }
                    else if (_this.supplierQuesData.data[i].field_type === 'duallist') {
                        var url = void 0;
                        if (_this.editSupplierData.farmer_reg.project_id) {
                            url = _this.supplierQuesData.data[i].api_url + _this.editSupplierData.farmer_reg.project_id;
                        }
                        else {
                            url = _this.supplierQuesData.data[i].api_url + _this.editSupplierData.farmer_reg[0].project_id;
                        }
                        _this.suppService.getAPIURLMethod(url).subscribe(function (response) {
                            var x = response.data.map(function (item) { return item.region_village; }).filter(function (x) { return x != null; }) || [];
                            var villagesArray = [];
                            if (x.length > 0) {
                                var _loop_13 = function (i_1) {
                                    if (!villagesArray.find(function (item) { return item.id === x[i_1].id; })) {
                                        villagesArray.push({ id: x[i_1].id, name: x[i_1].village_name });
                                    }
                                };
                                for (var i_1 = 0; i_1 < x.length; i_1++) {
                                    _loop_13(i_1);
                                }
                                villagesArray.sort(function (a, b) {
                                    if (a.name < b.name) {
                                        return -1;
                                    }
                                    if (a.name > b.name) {
                                        return 1;
                                    }
                                    return 0;
                                });
                            }
                            _this.countryData['84'] = {};
                            _this.countryData['84'].data = villagesArray;
                            _this.countryData[_this.supplierQuesData.data[i].id] = response.data;
                            _this.farmers = response.data;
                            drpdownpatcharr_1['84'] = _this.tableData.data.find(function (item) { return item.id == itemValue; }).farmer_reg.region_village.id.toString();
                            _this.editSupplierForm.patchValue(drpdownpatcharr_1);
                            _this.filterFarmersBySelectedVillage(true);
                            _this.doReset('edit', drpdownpatcharr_1[79]);
                        });
                    }
                    else if (_this.supplierQuesData.data[i].field_type === 'date') {
                        _this.editSupplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({ disabled: _this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true }, validatorArr));
                        _this.dateFormat = 'DD-MMM-YYYY';
                    }
                    else {
                        _this.editSupplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({ disabled: _this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true }, validatorArr));
                    }
                };
                for (var i = 0; i < _this.supplierQuesData.data.length; i++) {
                    _loop_12(i);
                }
                _this.updateUserModal = _this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
                _this.editForm = true;
                _this.editSupplierForm.patchValue(drpdownpatcharr_1);
            }
        });
    };
    SupplierComponent.prototype.editSuppComponents = function (itemValue, template) {
        var _this = this;
        this.confirmUpdateModal = true;
        var updatingSupplier = {
            'ecosystem_id': itemValue.ecosystem_id,
            'form_code': this.origin
        };
        this.suppService.getSingleMDMComponents(updatingSupplier).subscribe(function (response) {
            if (response.status === 'success') {
                _this.editSupplierData = response.data;
                var drpdownpatcharr = {};
                _this.customdata = response.customdata;
                _this.updatingEcosystemId = response.data.ecosystem_id;
                var validationsArray = void 0;
                var _loop_14 = function (i) {
                    drpdownpatcharr[_this.supplierQuesData.data[i].id]
                        = (_this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === null ||
                            _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field] === undefined)
                            ? '' : _this.editSupplierData[_this.supplierQuesData.data[i].mapping_table_field].toString();
                    var validatorArr = [];
                    if (_this.supplierQuesData.data[i].mandatory === 'Y') {
                        validatorArr.push(Validators.required);
                    }
                    if (_this.supplierQuesData.data[i].MDMValidation != null) {
                        validationsArray = JSON.parse(_this.supplierQuesData.data[i].MDMValidation.validation_obj);
                        for (var j = 0; j < validationsArray.length; j++) {
                            if (validationsArray[j].type === 'min') {
                                validatorArr.push(Validators.minLength(validationsArray[j].value));
                            }
                            if (validationsArray[j].type === 'max') {
                                validatorArr.push(Validators.maxLength(validationsArray[j].value));
                            }
                            if (validationsArray[j].type === 'email') {
                                validatorArr.push(Validators.email);
                            }
                            if (validationsArray[j].type === 'number') {
                                validatorArr.push(Validators.pattern('^[0-9]*$'));
                            }
                        }
                    }
                    if (_this.supplierQuesData.data[i].field_type === 'dropdown') {
                        if (_this.supplierQuesData.data[i].value_options === null) {
                            _this.countryData[_this.supplierQuesData.data[i].id] = {};
                            _this.suppService.getAPIURLMethod(_this.supplierQuesData.data[i].api_url).subscribe(function (response) {
                                _this.countryData[_this.supplierQuesData.data[i].id] = response;
                            });
                            _this.editSupplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({ disabled: _this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true }, validatorArr));
                        }
                        else {
                            _this.valueOptions = JSON.parse(_this.supplierQuesData.data[i].value_options);
                            _this.editSupplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({ disabled: _this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true }, validatorArr));
                        }
                    }
                    else if (_this.supplierQuesData.data[i].field_type === 'date') {
                        _this.editSupplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({ disabled: _this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true }, validatorArr));
                        _this.dateFormat = 'DD-MMM-YYYY';
                    }
                    else {
                        _this.editSupplierForm.addControl(_this.supplierQuesData.data[i].id, new FormControl({ disabled: _this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true }, validatorArr));
                    }
                };
                for (var i = 0; i < _this.supplierQuesData.data.length; i++) {
                    _loop_14(i);
                }
                if (_this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM') {
                    var _loop_15 = function (mscarr) {
                        var tempi = _this.customdata.find(function (melement) {
                            if (!mscarr.services_id && mscarr.id === melement.services_id) {
                                return melement;
                            }
                            if (melement.services_components_mapping && mscarr.id == melement.services_components_mapping.id && mscarr.services_id == melement.services_id) {
                                return melement;
                            }
                        });
                        if (tempi != null) {
                            try {
                                _this.editSupplierForm.removeControl(mscarr.uniqueid);
                            }
                            catch (_a) { }
                            _this.editSupplierForm.addControl((mscarr.uniqueid), new FormControl({ checked: true }));
                        }
                        else {
                            try {
                                _this.editSupplierForm.removeControl(mscarr.uniqueid);
                            }
                            catch (_b) { }
                            _this.editSupplierForm.addControl((mscarr.uniqueid), new FormControl());
                        }
                    };
                    for (var _i = 0, _a = _this.masterServiceComponentArray; _i < _a.length; _i++) {
                        var mscarr = _a[_i];
                        _loop_15(mscarr);
                    }
                }
                if (_this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
                    var _loop_16 = function (mscarr) {
                        var tempi = _this.customdata.find(function (melement) {
                            var fid = melement.user_type.id + melement.user_type.code;
                            if (mscarr.uniqueid === fid) {
                                return melement;
                            }
                        });
                        if (tempi != null) {
                            try {
                                _this.editSupplierForm.removeControl(mscarr.uniqueid);
                            }
                            catch (_a) { }
                            _this.editSupplierForm.addControl((mscarr.uniqueid), new FormControl({ checked: true }));
                        }
                        else {
                            try {
                                _this.editSupplierForm.removeControl(mscarr.uniqueid);
                            }
                            catch (_b) { }
                            _this.editSupplierForm.addControl((mscarr.uniqueid), new FormControl());
                        }
                    };
                    for (var _b = 0, _c = _this.userTypes; _b < _c.length; _b++) {
                        var mscarr = _c[_b];
                        _loop_16(mscarr);
                    }
                }
                _this.updateUserModal = _this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
                _this.editForm = true;
                _this.editSupplierForm.patchValue(drpdownpatcharr);
            }
        });
    };
    SupplierComponent.prototype.trackById = function (index, dataItem) {
        return dataItem.id;
    };
    SupplierComponent.prototype.setUserType = function (form_code, mapping_table_field) {
        if (form_code === 'USERPROGRAMMAPPING_MDM' && mapping_table_field === 'users_id') {
            var mid = this.supplierQuesData.data.find(function (sdata) { return sdata.mapping_table_field === mapping_table_field; });
            var midval_1 = this.supplierForm.get(('' + mid.id)).value;
            var seldata = this.countryData[mid.id].data.find(function (sdata) { return sdata.id === +midval_1; });
            var fmid = this.supplierQuesData.data.find(function (sdata) { return sdata.mapping_table_field === 'user_type_id'; });
            fmid = '' + fmid.id;
            this.supplierForm.controls[fmid].setValue(seldata.user_type_id);
        }
        if (form_code === 'UNITS_MDM' && mapping_table_field === 'unit_type_id') {
            var mid = this.supplierQuesData.data.find(function (sdata) { return sdata.mapping_table_field === mapping_table_field; });
            var midval_2 = this.supplierForm.get(('' + mid.id)).value;
            var seldata = this.countryData[mid.id].data.find(function (sdata) { return sdata.id === +midval_2; });
            var fmid = this.supplierQuesData.data.find(function (sdata) { return sdata.mapping_table_field === 'international_unit'; });
            fmid = '' + fmid.id;
            this.supplierForm.controls[fmid].setValue(seldata.international_unit);
        }
        if (form_code === 'ECOSYSTEM_USERS_MAPPING_MDM' && mapping_table_field === 'users_id') {
            var mid = this.supplierQuesData.data.find(function (sdata) { return sdata.mapping_table_field === mapping_table_field; });
            var midval_3 = this.supplierForm.get(('' + mid.id)).value;
            var seldata = this.countryData[mid.id].data.find(function (sdata) { return sdata.id === +midval_3; });
            var utid = this.supplierQuesData.data.find(function (sdata) { return sdata.mapping_table_field === 'user_type_id'; });
            utid = '' + utid.id;
            var ucid = this.supplierQuesData.data.find(function (sdata) { return sdata.mapping_table_field === 'user_category_id'; });
            ucid = '' + ucid.id;
            var atid = this.supplierQuesData.data.find(function (sdata) { return sdata.mapping_table_field === 'access_type_id'; });
            atid = '' + atid.id;
            this.supplierForm.controls[utid].setValue(seldata.user_type_id);
            this.supplierForm.controls[ucid].setValue(seldata.user_category_id);
            var atdata = this.countryData[atid].data.find(function (sdata) { return sdata.code === 'FUL3'; });
            this.supplierForm.controls[atid].setValue(atdata.id);
        }
    };
    //Village  Collector Mapping 
    SupplierComponent.prototype.getVCMappingData = function (form_code, mapping_table_field, event) {
        var _this = this;
        if (form_code === 'VC_FARMER_MAPPING_MDM' && mapping_table_field === 'project_id') {
            var _loop_17 = function (i) {
                if (this_8.supplierQuesData.data[i].field_type === 'duallist') {
                    this_8.confirmedFarmers = new Array();
                    this_8.countryData[this_8.supplierQuesData.data[i].id] = {};
                    var url = this_8.supplierQuesData.data[i].api_url + event.target.value;
                    this_8.suppService.getAPIURLMethod(url).subscribe(function (response) {
                        _this.countryData[_this.supplierQuesData.data[i].id] = response.data;
                        var x = response.data.map(function (item) { return item.region_village; }).filter(function (x) { return x != null; }) || [];
                        var villagesArray = [];
                        if (x.length > 0) {
                            var _loop_18 = function (i_2) {
                                var exists = villagesArray.find(function (item) { return item.id === x[i_2].id; });
                                var mappingsInThisProject = [];
                                mappingsInThisProject = _this.tableData.data.filter(function (item) { return item.farmer_reg.project_id == event.target.value; });
                                var villageAlreadyInMappingsInThisProject = mappingsInThisProject.length > 0 ? mappingsInThisProject.find((function (item) { return item.farmer_reg.region_village.id == x[i_2].id; })) : undefined;
                                if (!exists) {
                                    villagesArray.push({ id: x[i_2].id, name: x[i_2].village_name });
                                }
                            };
                            for (var i_2 = 0; i_2 < x.length; i_2++) {
                                _loop_18(i_2);
                            }
                            villagesArray.sort(function (a, b) {
                                if (a.name < b.name) {
                                    return -1;
                                }
                                if (a.name > b.name) {
                                    return 1;
                                }
                                return 0;
                            });
                        }
                        _this.supplierForm.controls[84].setValue('0');
                        _this.countryData['84'] = {};
                        _this.countryData['84'].data = villagesArray;
                        _this.countryData['84'].data.unshift({ id: '0', name: 'Select' });
                        //let farmersAlreadyMappedToAVC = this.vcFarmerMappingData.map(item => item.farmer_reg);
                        var farmersAlreadyMappedToAVC = [{ "id": 0 }];
                        //console.log('farmersAlreadyMappedToAVC', farmersAlreadyMappedToAVC);
                        _this.farmersInProject = villagesArray.length > 1 ? response.data.filter(function (item) { return !farmersAlreadyMappedToAVC.find(function (each) { return each.id == item.id; }); }) : [];
                        //console.log('this.farmersInProject', this.farmersInProject);
                        // if (villagesArray.length == 1) {this.commonService.showToast('warning', 'no_add_only_edit');}
                        _this.filterFarmersBySelectedVillage();
                    });
                }
            };
            var this_8 = this;
            for (var i = 0; i < this.supplierQuesData.data.length; i++) {
                _loop_17(i);
            }
        }
        else if (form_code === 'VC_FARMER_MAPPING_MDM' && mapping_table_field === 'village_name') {
            this.filterFarmersBySelectedVillage();
        }
        else if (form_code === 'VC_FARMER_MAPPING_MDM' && mapping_table_field === 'users_id') {
            this.filterFarmersBySelectedVillage();
        }
    };
    //Angular DUAL List 
    SupplierComponent.prototype.farmerLabel = function (item) {
        return item.name;
    };
    SupplierComponent.prototype.useFarmers = function () {
        this.dualListKey = 'id';
        this.display = this.farmerLabel;
        this.keepSorted = true;
        this.source = this.sourceFarmers;
        this.confirmed = this.confirmedFarmers;
    };
    SupplierComponent.prototype.doReset = function (itemType, userId) {
        var _this = this;
        if (itemType == 'edit') {
            this.confirmedFarmers = new Array();
            if (this.editSupplierDataFarmers.id) {
                var selectedFarmer = this.farmers.filter(function (item) { return item.id == _this.editSupplierDataFarmers.farmer_reg.id && _this.editSupplierDataFarmers.status == 'Active'; });
                this.confirmedFarmers.push(selectedFarmer);
            }
            else {
                var p = this.editSupplierDataFarmers.filter(function (item) { return item.users_id == userId; });
                var _loop_19 = function (x) {
                    var selectedFarmer = this_9.farmers.filter(function (item) { return item.id == x.farmer_reg.id && x.status == 'Active'; })[0];
                    if (selectedFarmer) {
                        this_9.confirmedFarmers.push(selectedFarmer);
                    }
                };
                var this_9 = this;
                for (var _i = 0, p_1 = p; _i < p_1.length; _i++) {
                    var x = p_1[_i];
                    _loop_19(x);
                }
                this.allFarmersMappedToVCNotInConfirmed = this.editSupplierDataFarmersAllVillages.filter(function (item) { return item.status == 'Active' && item.users_id == userId && !_this.confirmedFarmers.find(function (each) { return each.id == item.farmer_reg.id; }); });
            }
        }
        this.sourceFarmers = JSON.parse(JSON.stringify(this.farmers));
        this.useFarmers();
    };
    SupplierComponent.prototype.doAdd = function () {
        var _loop_20 = function (i, len) {
            var o = this_10.source[i];
            var found = this_10.confirmed.find(function (e) { return e === o; });
            if (!found) {
                this_10.confirmed.push(o);
                return "break";
            }
        };
        var this_10 = this;
        for (var i = 0, len = this.source.length; i < len; i += 1) {
            var state_1 = _loop_20(i, len);
            if (state_1 === "break")
                break;
        }
    };
    SupplierComponent.prototype.doRemove = function () {
        if (this.confirmed.length > 0) {
            this.confirmed.splice(0, 1);
        }
    };
    SupplierComponent.prototype.doDelete = function () {
        if (this.source.length > 0) {
            this.source.splice(0, 1);
        }
    };
    SupplierComponent.prototype.onSelectUpdate = function (event) {
        this.updateSelectedOption = event.item;
    };
    SupplierComponent.prototype.printHeader = function (header) {
    };
    SupplierComponent.prototype.updateMDMDate = function (form, id) {
        var _this = this;
        this.f1Submitted = true;
        if (this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM' || this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM') {
            var temp = {};
            this.customdataModified = this.customdata;
            var servicesArray_2 = [];
            for (var i = 0; i < this.customdataModified.length; i++) {
                if (!this.customdataModified[i]['components_id']) {
                    servicesArray_2.push(this.customdataModified[i]);
                }
            }
            var itemsToRemove_2 = [];
            var _loop_21 = function (i) {
                var isComponentSelected = this_11.customdataModified.filter(function (item) { return item.components_id != null && item.services_id == servicesArray_2[i]['services_id']; });
                if (isComponentSelected && isComponentSelected.length > 0) {
                    // in customdataModified, keep the array items that have a component id & service id is equal to servicesArray[i]['id']
                    // keep the items that do not have a component id and services id is not equal to servicesArray[i]['id']
                    itemsToRemove_2 = itemsToRemove_2.concat(this_11.customdataModified.filter(function (item) { return item.components_id == null && item.services_id == servicesArray_2[i]['services_id']; }));
                }
            };
            var this_11 = this;
            for (var i = 0; i < servicesArray_2.length; i++) {
                _loop_21(i);
            }
            var _loop_22 = function (i) {
                // in customdataModified, keep the array items that have a component id or that do not have a component id and the services_id is not equal to itemsToRemove[i]['id']
                this_12.customdataModified = this_12.customdataModified.filter(function (item) { return item.components_id != null || (item.components_id == null && item.services_id !== itemsToRemove_2[i]['services_id']); });
            };
            var this_12 = this;
            for (var i = 0; i < itemsToRemove_2.length; i++) {
                _loop_22(i);
            }
            this.customdata = this.customdataModified;
            this.customdata.forEach(function (snap) {
                snap.upd_user_id = _this.currentUser.id;
            });
            temp.ecosystem_id = this.updatingEcosystemId;
            temp.data = this.customdata;
            temp.form_code = this.origin;
            temp.cr_user_id = this.currentUser.id;
            temp.upd_user_id = this.currentUser.id;
            this.suppService.updateMDMData(temp).subscribe(function (response) {
                var responseData = response;
                if (responseData.status === 'success') {
                    if (_this.updateUserModal) {
                        _this.updateUserModal.hide();
                    }
                    _this.commonService.showToast('success', responseData.code, {});
                    _this.getMDMDataValues();
                    _this.editSupplierForm.reset();
                    _this.getMasterData();
                    _this.customdata = [];
                }
                else {
                    _this.commonService.showToast('error', responseData.code, {});
                }
            });
        }
        else {
            if (form.valid) {
                var newRequestArray = [];
                var formCode = {
                    'form_code': this.origin,
                    'id': id,
                    'upd_user_id': this.currentUser.id,
                    'data': []
                };
                var newRequest = [];
                for (var x in form.getRawValue()) {
                    var result = this.supplierQuesData.data.filter(function (item) { return item.id === +x; });
                    var selectId = void 0;
                    if (this.updateSelectedOption) {
                        selectId = this.updateSelectedOption.id;
                    }
                    if (result.length > 0) {
                        if (form.getRawValue()[x] === null || form.getRawValue()[x] === '') {
                            newRequest = {
                                'id': x, 'value': null, 'mapping_table': result[0].mapping_table,
                                'mapping_table_field': result[0].mapping_table_field
                            };
                        }
                        else if (this.updateSelectedOption && this.updateSelectedOption.name === form.getRawValue()[x]) {
                            newRequest = {
                                "id": x, "value": selectId, "mapping_table": result[0].mapping_table,
                                "mapping_table_field": result[0].mapping_table_field
                            };
                        }
                        else {
                            newRequest = {
                                'id': x, 'value': form.getRawValue()[x], 'mapping_table': result[0].mapping_table,
                                'mapping_table_field': result[0].mapping_table_field
                            };
                        }
                    }
                    formCode.data.push(newRequest);
                }
                if (this.origin == 'VC_FARMER_MAPPING_MDM') {
                    var x_1 = Object.assign([], this.confirmed);
                    var _loop_23 = function (i) {
                        var item = this_13.editSupplierDataFarmers.find(function (each) { return each.farmer_id == x_1[i]['id'] && each.users_id == _this.editSupplierData['users_id']; });
                        if (item) {
                            var dbId = item['id'];
                            x_1[i]['dbId'] = dbId;
                        }
                    };
                    var this_13 = this;
                    for (var i = 0; i < x_1.length; i++) {
                        _loop_23(i);
                    }
                    for (var i = 0; i < this.allFarmersMappedToVCNotInConfirmed.length; i++) {
                        var y = this.allFarmersMappedToVCNotInConfirmed[i];
                        y['dbId'] = y['id'];
                        x_1.push(y);
                    }
                    formCode.data.push({
                        id: 83,
                        mapping_table: "vc_farmer_mapping",
                        mapping_table_field: "farmer_id",
                        value: x_1
                    });
                }
                this.suppService.updateMDMData(formCode).subscribe(function (response) {
                    var responseData = response;
                    if (responseData.status === 'success') {
                        if (_this.updateUserModal) {
                            _this.updateUserModal.hide();
                        }
                        _this.supplierForm.reset();
                        _this.editSupplierForm.reset();
                        _this.f1Submitted = false;
                        _this.commonService.showToast('success', responseData.code, {});
                        _this.getMDMDataValues();
                        _this.getMasterData();
                        if (_this.origin == 'UNITS_MDM') {
                            _this.commonService.showToast('warning', 'update_ecosystem_settings', {});
                        }
                    }
                    else {
                        _this.commonService.showToast('error', responseData.code, {});
                    }
                });
            }
        }
    };
    SupplierComponent.prototype.openModalConfirmRemoval = function (template) {
        this.removeUsersConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    };
    SupplierComponent.prototype.openModalConfirmDeac = function (template) {
        this.deacUserConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    };
    SupplierComponent.prototype.deactivateThisUser = function (id) {
        var _this = this;
        var formCode = {
            'form_code': this.origin,
            'id': id,
            'upd_user_id': this.currentUser.id
        };
        this.suppService.deleteMDMData(formCode).subscribe(function (response) {
            var responseData = response;
            if (responseData.status === 'success') {
                _this.commonService.showToast('success', responseData.code, {});
                _this.getMDMDataValues();
                _this.getMasterData();
            }
            else {
                _this.commonService.showToast('error', responseData.code, {});
            }
            if (_this.deacUserConfirmModal) {
                _this.deacUserConfirmModal.hide();
            }
        });
    };
    SupplierComponent.prototype.openModalConfirmDeacSuppComponentMDM = function (ecosystem, template) {
        this.deletingEcosystemId = ecosystem;
        this.deacUserConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    };
    SupplierComponent.prototype.delSuppComponentMDM = function () {
        var _this = this;
        var formCode = {
            'form_code': this.origin,
            'ecosystem_id': this.deletingEcosystemId.ecosystem_id,
            'upd_user_id': this.currentUser.id
        };
        this.suppService.deleteMDMData(formCode).subscribe(function (response) {
            var responseData = response;
            if (responseData.status === 'success') {
                _this.commonService.showToast('success', responseData.code, {});
                _this.getMDMDataValues();
                _this.getMasterData();
            }
            else {
                _this.commonService.showToast('error', responseData.code, {});
            }
            if (_this.deacUserConfirmModal) {
                _this.deacUserConfirmModal.hide();
            }
        });
    };
    SupplierComponent.prototype.declineDeactivation = function () {
        if (this.deacUserConfirmModal) {
            this.deacUserConfirmModal.hide();
        }
    };
    SupplierComponent.prototype.hideUpdateModal = function (form) {
        if (form.dirty) {
            this.confirmUpdateModal = false;
        }
        else {
            if (this.updateUserModal) {
                this.updateUserModal.hide();
                this.editForm = false;
            }
            this.editSupplierForm.reset();
        }
        this.f1Submitted = false;
    };
    SupplierComponent.prototype.hideAddUserModal = function (form) {
        if (form.dirty) {
            this.confirmModal = false;
        }
        else {
            if (this.addUserModal) {
                this.addUserModal.hide();
                this.addForm = false;
            }
            this.supplierForm.reset();
        }
        this.f1Submitted = false;
    };
    SupplierComponent.prototype.closeModal = function (val) {
        if (val === 'add') {
            if (this.addUserModal) {
                this.addUserModal.hide();
            }
            this.supplierForm.reset();
            this.f1Submitted = false;
        }
        else {
            this.editSupplierForm.reset();
            if (this.updateUserModal) {
                this.updateUserModal.hide();
            }
            this.f1Submitted = false;
        }
    };
    SupplierComponent.prototype.confirmModalYes = function (valItem) {
        if (valItem === 'add') {
            if (this.addUserModal) {
                this.addUserModal.hide();
            }
            this.supplierForm.reset();
            this.customdata = [];
            this.f1Submitted = false;
            this.userTypeCheckedForAdd = [];
        }
        else {
            if (this.updateUserModal) {
                this.updateUserModal.hide();
            }
            this.customdata = [];
            this.editSupplierForm.reset();
            this.f1Submitted = false;
        }
    };
    SupplierComponent.prototype.confirmModalNo = function (valItem) {
        if (valItem === 'add') {
            this.confirmModal = true;
        }
        else {
            this.confirmUpdateModal = true;
        }
    };
    SupplierComponent.prototype.exportMDMData = function () {
        var _this = this;
        this.suppService.exportData(this.origin)
            .subscribe(function (result) {
            console.log('result', result);
            var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
            var blob = new Blob([result], { type: contentType });
            var linkdownload = document.createElement('a');
            linkdownload.href = URL.createObjectURL(blob);
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            var today1 = dd + '-' + mm + '-' + yyyy;
            //var file_name = 'P2F-Roots_' + this.origin + '_ExcelExport_' + today + '.xlsx';
            linkdownload.download = 'P2F-Roots_' + _this.tableData.mastername + '_ExcelExport_' + today1 + '.xlsx';
            linkdownload.style.display = 'none';
            document.body.appendChild(linkdownload);
            linkdownload.click();
        }, function (err) { return console.log('err', err); });
    };
    SupplierComponent.prototype.soryBy = function (item) {
        if (item.isAscending) {
            this.asc(item);
        }
        else {
            this.desc(item);
        }
        this.tableData.data.forEach(function (element) {
            element.isAscending = true;
        });
        item.isAscending = !item.isAscending;
    };
    SupplierComponent.prototype.asc = function (item) {
        if (this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM' || this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
            this.tableFinalData.sort(function (a, b) {
                var finala = '';
                var finalb = '';
                finala = a['name'];
                finalb = b['name'];
                if (finala === null) {
                    finala = '';
                }
                if (finalb === null) {
                    finalb = '';
                }
                if (finala.toLowerCase() > finalb.toLowerCase()) {
                    return 1;
                }
                if (finala.toLowerCase() < finalb.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (this.origin === 'ECOSYSTEM_USERS_MAPPING_MDM') {
            this.tableData.data.sort(function (a, b) {
                var finala = '';
                var finalb = '';
                finala = a[item.mapping_table_field];
                finalb = b[item.mapping_table_field];
                if (finala === null) {
                    finala = '';
                }
                if (finalb === null) {
                    finalb = '';
                }
                if (finala.toLowerCase() > finalb.toLowerCase()) {
                    return 1;
                }
                if (finala.toLowerCase() < finalb.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else {
            this.tableData.data.sort(function (a, b) {
                var finala = '';
                var finalb = '';
                if (item.field_type === 'dropdown' && item.api_url !== null) {
                    finala = a[item.ref_modal_name]['name'];
                    finalb = b[item.ref_modal_name]['name'];
                }
                else {
                    finala = a[item.mapping_table_field];
                    finalb = b[item.mapping_table_field];
                }
                if (finala === null) {
                    finala = '';
                }
                if (finalb === null) {
                    finalb = '';
                }
                if (finala.toLowerCase() > finalb.toLowerCase()) {
                    return 1;
                }
                if (finala.toLowerCase() < finalb.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    SupplierComponent.prototype.desc = function (item) {
        if (this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM' || this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
            this.tableFinalData.sort(function (a, b) {
                var finala = '';
                var finalb = '';
                finala = a['name'];
                finalb = b['name'];
                if (finala === null) {
                    finala = '';
                }
                if (finalb === null) {
                    finalb = '';
                }
                if (finala.toLowerCase() < finalb.toLowerCase()) {
                    return 1;
                }
                if (finala.toLowerCase() > finalb.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (this.origin === 'ECOSYSTEM_USERS_MAPPING_MDM') {
            this.tableData.data.sort(function (a, b) {
                var finala = '';
                var finalb = '';
                finala = a[item.mapping_table_field];
                finalb = b[item.mapping_table_field];
                if (finala === null) {
                    finala = '';
                }
                if (finalb === null) {
                    finalb = '';
                }
                if (finala.toLowerCase() < finalb.toLowerCase()) {
                    return 1;
                }
                if (finala.toLowerCase() > finalb.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else {
            this.tableData.data.sort(function (a, b) {
                var finala = '';
                var finalb = '';
                if (item.field_type === 'dropdown' && item.api_url !== null) {
                    finala = a[item.ref_modal_name]['name'];
                    finalb = b[item.ref_modal_name]['name'];
                }
                else {
                    finala = a[item.mapping_table_field];
                    finalb = b[item.mapping_table_field];
                }
                if (finala === null) {
                    finala = '';
                }
                if (finalb === null) {
                    finalb = '';
                }
                if (finala.toLowerCase() < finalb.toLowerCase()) {
                    return 1;
                }
                if (finala.toLowerCase() > finalb.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    SupplierComponent.prototype.goBackToAdmin = function () {
        this.location.back();
    };
    SupplierComponent.prototype.filterFarmersBySelectedVillage = function (isEdit) {
        if (!isEdit) {
            var selectedVillage_1 = this.supplierForm.controls[84].value;
            var selectedVC = this.supplierForm.controls[79].value;
            if (selectedVillage_1 && parseInt(selectedVillage_1) > 1) {
                if (selectedVC) {
                    this.filterByVCMapping(selectedVC, selectedVillage_1);
                }
                else {
                    this.farmers = this.farmersInProject.filter(function (item) { return item.region_village.id == selectedVillage_1; });
                }
            }
            else if (selectedVC) {
                this.filterByVCMapping(selectedVC);
            }
            else {
                this.farmers = this.farmersInProject;
            }
            this.doReset('add');
        }
        else {
            var selectedVillage_2 = this.editSupplierForm.controls[84].value;
            if (selectedVillage_2 && parseInt(selectedVillage_2) > 1) {
                this.farmers = this.farmers.filter(function (item) { return item.region_village.id == selectedVillage_2; });
                this.editSupplierDataFarmersAllVillages = Object.assign([], JSON.parse(JSON.stringify(this.editSupplierDataFarmers)));
                this.editSupplierDataFarmers = this.editSupplierDataFarmers.filter(function (item) { return item.farmer_reg.region_village.id == selectedVillage_2; });
            }
            else {
                this.farmers = this.farmersInProject;
            }
        }
    };
    // filter farmers by VC mapping
    SupplierComponent.prototype.filterByVCMapping = function (isVC, selectedVillage) {
        this.confirmedFarmers = new Array();
        this.farmers = [];
        if (!selectedVillage) {
            for (var _i = 0, _a = this.farmersInProject; _i < _a.length; _i++) {
                var farmer = _a[_i];
                var VCMappedFarmers = farmer.vc_farmer_mappings.find(function (item) { return item.users_id == isVC; });
                if (VCMappedFarmers) {
                    this.confirmedFarmers.push(farmer);
                }
                else {
                    this.farmers.push(farmer);
                }
            }
        }
        else {
            var filterByVillage = this.farmersInProject.filter(function (item) { return item.region_village.id == selectedVillage; });
            for (var _b = 0, filterByVillage_1 = filterByVillage; _b < filterByVillage_1.length; _b++) {
                var farmer = filterByVillage_1[_b];
                var VCMappedFarmers = farmer.vc_farmer_mappings.find(function (item) { return item.users_id == isVC; });
                if (VCMappedFarmers) {
                    this.confirmedFarmers.push(farmer);
                }
                else {
                    this.farmers.push(farmer);
                }
            }
        }
        if (this.farmers.length == 0 && this.farmersInProject.length > 0) {
            this.toastrService.warning('No farmer available for mapping');
        }
    };
    return SupplierComponent;
}());
export { SupplierComponent };
