import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '../../services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { TrainingService } from '../../services/training.service';
var ProjectTrainingMappingComponent = /** @class */ (function () {
    function ProjectTrainingMappingComponent(route, router, translate, userService, spinner, trainingService, commonService, projectService, modalService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.translate = translate;
        this.userService = userService;
        this.spinner = spinner;
        this.trainingService = trainingService;
        this.commonService = commonService;
        this.projectService = projectService;
        this.modalService = modalService;
        this.typeOfAdministration = '';
        this.currentUser = this.userService.getCurrentUser();
        this.searchText = '';
        this.loadingFarmers = false;
        this.farmers = [];
        this.page = 1;
        this.itemsPerPage = 10;
        this.farmersListPage = 1;
        this.isAscending = true;
        this.isAscendingProject = true;
        this.isAscendingType = true;
        this.isAscendingName = true;
        this.mappingAddObject = {
            "topic": 0,
            "description": '',
            "project": 0,
            'mandatory': '',
            'duedate': null,
            'frequency': 0,
            "certificate": 0,
            "certifiedby": 0
        };
        this.unitTrustsPnl = 0;
        this.isCertificate = false;
        this.disableAddButton = true;
        this.formIsEdited = false;
        this.isTrainingOpen = false;
        this.dateFormat = 'DD-MMM-YYYY';
        this.isMandatory = true;
        this.isEdit = false;
        this.route.paramMap.subscribe(function (params) {
            _this.id = params.get('id');
        });
        this.route.queryParamMap.subscribe(function (queryParams) {
            var qpObject = tslib_1.__assign({}, queryParams.keys, queryParams);
            _this.fromNavId = qpObject['params']['from'];
            _this.translate.get('typeAdminTextArr').subscribe(function (val) {
                _this.typeOfAdministrationArr = val;
                if (_this.fromNavId === '2') {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[0];
                }
                else if (_this.fromNavId === '3') {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[1];
                }
            }, function (err) { return console.log('err', err); });
        });
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
    }
    ProjectTrainingMappingComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.onLine) return [3 /*break*/, 2];
                        this.spinner.show();
                        this.ecosystem_id = localStorage.getItem('ecosystem_id');
                        this.getTrainingMapping();
                        return [4 /*yield*/, Promise.all([
                                this.projectService.GetProjectsBasicInfo().toPromise(),
                                this.commonService.getTrainingCertifications().toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['msg'] === 'success') {
                            this.projects = request[0]['data'];
                        }
                        if (request[1]['msg'] === 'success') {
                            this.trainingCertifications = request[1]['data'];
                        }
                        this.trainings = this.trainingCertifications.filter(function (item) { return item.training_certification_type == 'Training' && item.status == 'Active'; });
                        this.certifications = this.trainingCertifications.filter(function (item) { return item.training_certification_type == 'Certification' && item.status == 'Active'; });
                        this.spinner.hide();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ProjectTrainingMappingComponent.prototype.getTrainingMapping = function () {
        var _this = this;
        this.trainingService.getProjectTrainingMapping().subscribe(function (result) {
            _this.projectTrainings = result['data'];
        });
    };
    ProjectTrainingMappingComponent.prototype.saveProjectTrainingMapping = function () {
        var _this = this;
        this.spinner.show();
        var requestParams;
        var finalDueDate;
        if (this.mappingAddObject.frequency != 0) {
            var dueDate = new Date();
            dueDate.setMonth(dueDate.getMonth() + this.mappingAddObject.frequency);
            finalDueDate = dueDate.getFullYear() + '-'
                + ('0' + (dueDate.getMonth() + 1)).slice(-2) + '-'
                + ('0' + dueDate.getDate()).slice(-2);
        }
        if (!this.isCertificate) {
            requestParams = {
                "training_certification_id": this.mappingAddObject.topic,
                "project_id": this.mappingAddObject.project,
                "description": this.mappingAddObject.description,
                "is_training": 1,
                "mandatory": this.mappingAddObject.mandatory ? 1 : 0,
                "due_date": finalDueDate,
                "frequency": this.mappingAddObject.frequency != 0 ? this.mappingAddObject.frequency : null
            };
        }
        else {
            requestParams = {
                "training_certification_id": this.mappingAddObject.certificate,
                "project_id": this.mappingAddObject.project,
                "description": this.mappingAddObject.description,
                "is_training": 0,
                "certified_by": this.mappingAddObject.certifiedby
            };
        }
        console.log('requestParams', requestParams);
        this.trainingService.saveProjectTCMapping(requestParams, this.isEdit, this.currentMappingID).subscribe(function (result) {
            if (result['msg'] === 'MAPPING_CREATED' || result['msg'] === 'MAPPING_UPDATED') {
                if (result['msg'] === 'MAPPING_CREATED') {
                    _this.commonService.showToast('success', 'success_master_data_created', {});
                }
                else {
                    _this.commonService.showToast('success', 'success_master_data_updated', {});
                }
                _this.closePopUpYes();
                _this.getTrainingMapping();
                _this.spinner.hide();
            }
        });
    };
    ProjectTrainingMappingComponent.prototype.editProjectTrainingMapping = function (id, template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, curMapping;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.isEdit = true;
                        this.currentMappingID = id;
                        return [4 /*yield*/, Promise.all([
                                this.trainingService.getProjectTrainingMapping(id).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        curMapping = request[0]['data'];
                        if (curMapping.is_training == 0) {
                            this.isCertificate = true;
                            this.unitTrustsPnl = 1;
                        }
                        else {
                            this.isCertificate = false;
                            this.unitTrustsPnl = 0;
                        }
                        if (curMapping.mandatory == 1) {
                            this.isMandatory = false;
                        }
                        if (curMapping && !this.isCertificate) {
                            this.mappingAddObject.topic = curMapping.training_certification_master.id;
                            this.mappingAddObject.project = curMapping.project_id;
                            this.mappingAddObject.description = curMapping.description;
                            this.mappingAddObject.mandatory = curMapping.mandatory;
                            this.mappingAddObject.frequency = curMapping.frequency;
                        }
                        if (curMapping && this.isCertificate) {
                            this.mappingAddObject.certificate = curMapping.training_certification_master.id;
                            this.mappingAddObject.project = curMapping.project_id;
                            this.mappingAddObject.description = curMapping.description;
                            this.mappingAddObject.certifiedby = curMapping.certified_by;
                        }
                        this.addMappingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectTrainingMappingComponent.prototype.addProjectGroupMapping = function (template) {
        this.isCertificate = false;
        this.addMappingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    ProjectTrainingMappingComponent.prototype.closePopUp = function (form) {
        if (form.dirty) {
            this.formIsEdited = true;
        }
        else {
            this.resetMappingObject();
            this.addMappingModal.hide();
        }
    };
    ProjectTrainingMappingComponent.prototype.closePopUpYes = function () {
        this.resetMappingObject();
        this.addMappingModal.hide();
        this.formIsEdited = false;
    };
    ProjectTrainingMappingComponent.prototype.closePopUpNo = function () {
        this.formIsEdited = false;
    };
    ProjectTrainingMappingComponent.prototype.resetMappingObject = function () {
        this.mappingAddObject.topic = 0;
        this.mappingAddObject.project = 0;
        this.mappingAddObject.description = '';
        this.mappingAddObject.mandatory = '';
        this.mappingAddObject.frequency = 0;
        this.mappingAddObject.certificate = 0;
        this.mappingAddObject.certifiedby = 0;
        this.disableAddButton = true;
        this.unitTrustsPnl = 0;
        this.isEdit = false;
        this.isMandatory = true;
    };
    ProjectTrainingMappingComponent.prototype.showAddButton = function () {
        if (!this.isCertificate) {
            if (this.mappingAddObject.topic != 0 && this.mappingAddObject.project != 0) {
                if (!this.isMandatory) {
                    if (this.mappingAddObject.frequency == 0) {
                        this.disableAddButton = true;
                    }
                    else {
                        this.disableAddButton = false;
                    }
                }
                else {
                    this.disableAddButton = false;
                }
            }
            else {
                this.disableAddButton = true;
            }
        }
        else {
            if (this.mappingAddObject.certificate != 0 && this.mappingAddObject.project != 0) {
                this.disableAddButton = false;
            }
            else {
                this.disableAddButton = true;
            }
        }
    };
    ProjectTrainingMappingComponent.prototype.toggleDueDate = function (eve) {
        if (eve.target.checked) {
            this.isMandatory = false;
            this.showAddButton();
        }
        else {
            this.isMandatory = true;
            this.mappingAddObject.frequency = 0;
            this.showAddButton();
        }
    };
    ProjectTrainingMappingComponent.prototype.typeChanged = function (type) {
        if (type == 'certificate') {
            this.isCertificate = true;
        }
        else {
            this.isCertificate = false;
        }
        this.resetMappingObject();
        this.showAddButton();
    };
    ProjectTrainingMappingComponent.prototype.exportToExcel = function () {
        var _this = this;
        this.trainingService.exportToExcel().subscribe(function (result) {
            console.log('result', result);
            var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
            var blob = new Blob([result], { type: contentType });
            var linkdownload = document.createElement('a');
            linkdownload.href = URL.createObjectURL(blob);
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            var today1 = dd + "-" + mm + "-" + yyyy;
            _this.translate.get('training_mapping').subscribe(function (val) {
                linkdownload.download = "P2F-Roots_" + val + "_ExcelExport_" + today1 + ".xlsx";
                linkdownload.style.display = 'none';
                document.body.appendChild(linkdownload);
                linkdownload.click();
            }, function (err) { return console.log('err', err); });
        }, function (err) { return console.log('err', err); });
    };
    ProjectTrainingMappingComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    ProjectTrainingMappingComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        this.isAscendingType = sortBy !== 'master_type';
        this.isAscendingName = sortBy !== 'master_name';
        this.isAscendingProject = sortBy !== 'project_name';
        // Sort of new fields added
        if (sortBy === 'master_type' || sortBy === 'master_name' || sortBy === 'project_name') {
            this.projectTrainings.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
    };
    ProjectTrainingMappingComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        this.isAscendingType = sortBy === 'master_type';
        this.isAscendingName = sortBy === 'master_name';
        this.isAscendingProject = sortBy === 'project_name';
        // Sort of new fields added
        if (sortBy === 'master_type' || sortBy === 'master_name' || sortBy === 'project_name') {
            this.projectTrainings.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
    };
    ProjectTrainingMappingComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['master_type', 'master_name', 'project_name'];
        var finalObj = {};
        if (this.projectTrainings === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    ProjectTrainingMappingComponent.prototype.trackByGroupName = function (index, dataItem) {
        return dataItem.groupName;
    };
    ProjectTrainingMappingComponent.prototype.trackByProjectName = function (index, dataItem) {
        return dataItem.groupName;
    };
    ProjectTrainingMappingComponent.prototype.trackByTypeName = function (index, dataItem) {
        return dataItem.groupName;
    };
    ProjectTrainingMappingComponent.prototype.trackByStatusName = function (index, dataItem) {
        return dataItem.groupName;
    };
    return ProjectTrainingMappingComponent;
}());
export { ProjectTrainingMappingComponent };
