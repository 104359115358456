import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable()
export class ForgetpasswordService {

  forgetpassURL = this.appConfigService.getUrl(this.appConfigService.FORGETPASSWORD);  
  private isUserLoggedIn;
  
  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) {
    this.isUserLoggedIn = false;
  }

  isUserValid(username: string): Observable<any> {
    return this.http.get<any>(`${this.forgetpassURL}/isUserValid/${username}`);
  }
  sendForgetPassEmail(username: string): Observable<any> {
    return this.http.post(`${this.forgetpassURL}/sendForgetPassEmail`, { username: username });
  }
  sendResetUserIDEmail(item): Observable<any> {
     return this.http.post(`${this.forgetpassURL}/sendResetUserIDEmail`, item);
   }
}