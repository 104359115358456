import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// Services
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { FarmerService } from '../../services/farmer.service';
import { v4 as uuidv4 } from 'uuid';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
declare var idbApp: any;
declare var serviceWorkerVar;
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { join } from 'path';

@Component({
  selector: 'app-view-profiling-datasheet',
  templateUrl: './view-profiling-datasheet.component.html',
  styleUrls: ['./view-profiling-datasheet.component.css']
})
export class ViewProfilingDatasheetComponent implements OnInit {
  questionnaire; // Array of tabs
  currentTab; // Currently active tab
  currentTabIndex; // Index of currently active tab
  currentSection; // Currently active section
  currentSectionIndex; // Index of currently active section
  sectionForm: FormGroup; // Formgroup for currently active section
  showSectionForm = false; // Boolean to show / hide the section form
  qAnswers; // Array to contain all the answers in this questionnaire for this farmer
  checkedObject = {}; // Object that stores checked = true / false for checkbox questions (non-table)
  checkedObjectSecondaryKeys = {};  // Object that stores checked = true / false for checkbox questions (table)
  farmerId;  // Currently editing farmer's id
  secondary_asset_keys = {}; // Stores the secondary_asset_keys for each table. Format {tableId: [sak1, sak2], table2: [sak3, sak4]}
  secondary_asset_key__options = {}; // Stores the secondary_asset_key against the checkbox option that triggered creation of that sak. Format {optionId1: sak1, optionId2: sak2}
  project; // Project object for currently editing farmer
  notActiveSurvey = 2;
  notReleasedSurveyInLanguage;
  rawFarmerId;
  wizardShow;
  notes: any;
  currentUser = this.userService.getCurrentUser();
  projectId;
  farmer;
  revisionsInSurvey = [];
  selectedRevision = '';
  tableOtherQuestionsIsDisplayed = {};
  saveChangesConfirmModal;
  unsavedAnswerCount = 0;
  dynamicOptionsObject = {};
  private readonly subject = new Subject<boolean>();
  displayLogicKeyValues = [];
  tableQuestionsAnswer = {};
  assetTypes = [
    {type: 'tab', subTypes: []},
    {type: 'section', subTypes: []},
    {type: 'subsection', subTypes: []},
    {type: 'table', subTypes: [{key: 'question_rows', label: 'Questions in rows'}, {key: 'question_cols', label: 'Questions in columns'}]},
    {type: 'question', subTypes: [{key: 'text', label: 'Text'}, {key: 'number', label: 'Number'}, {key: 'select', label: 'Select'}, {key: 'multiselect', label: 'Multiselect'}]},
    {type: 'option', subTypes: []},
    {type: 'uom_question', subTypes: [{key: '1', label: 'Length'},{key: '2', label: 'Volume'},{key: '3', label: 'Weight'},{key: '4', label: 'Area'}, {key: '5', label: 'Quantity'}]},
    {type: 'other_question', subTypes: []},
    {type: 'grand_total_question', subTypes: []},
  ];
  processedUOMs = {};
  defaultCropSak;
  askUserToConfirmExit = false;
  farmProfitabilitySummary = {income: {source: 'NA', amount: 'NA'}, expense: {source: 'NA', amount: 'NA'}, totalIncome: 'NA', totalExpense: 'NA', totalProfit: 'NA'};
  farmProfitabilitySummaryArray = [];
  usersServiceComponentMappings;
  ecosystemServicesComponentsMapping;
  canDoFarmerMgmt = false;
  thereNoActiveSnapshotForThisProject;
  showSummaryForCurrentTab;
  currencyCode;
  showHideBackButton = true;
  showHideNextButton = false;
  farmerDetailsTabIndex = 0;
  // objFarmer : any;
  farmerRegQuestions = {};
  masterDataFarmer :any;
  constructor(
    private readonly _route: ActivatedRoute,
    private readonly dynamicQuestionnaireService: DynamicQuestionnaireService,
    private readonly projectService: ProjectService,
    private readonly commonService: CommonService,
    private readonly route: ActivatedRoute,
    private readonly toastr: ToastrService,
    private readonly surveyService: SurveyService,
    private readonly userService: UserService,
    private readonly farmerService: FarmerService,
    private readonly router: Router,
    private readonly modalService: BsModalService,
    private readonly spinner: Ng4LoadingSpinnerService
  ) { }

  async ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('projectId');
      this.route.queryParamMap.subscribe(async queryParams => {
        // Get the 'raw' farmerid - this can be the farmer id (online registration) or a temporary offline id
        this.rawFarmerId = params.get('id');
        // Get the farmer id (online registration), if it exists, or set this.farmerId to 0
        this.farmerId = +params.get('id') || 0;
        // Get project info, questionnaire, & answers
        let request;
        let request2;
        this.spinner.show();
        if (this.farmerId != 0) {
          if (navigator.onLine) {
            request = await Promise.all([
              this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
              this.projectService.getProjectProfilingData(this.projectId).toPromise(),
              this.projectService.getProjectDashboardData(this.projectId).toPromise(),
              this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
              this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
              this.farmerService.getFarmerBasicDataById(this.farmerId).toPromise()
            ]);
          } else {
            request = await Promise.all([
              this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
              this.projectService.getProjectProfilingData(this.projectId).toPromise(),
              this.projectService.getProjectDashboardData(this.projectId).toPromise(),
              this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
              this.dynamicQuestionnaireService.getQAnswersForProject(this.projectId, null).toPromise(),
              this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
            ]);
            request2 = await Promise.all([
              this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
            ]);
          }
        } else {
          request = await Promise.all([
            this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
            this.projectService.getProjectProfilingData(this.projectId).toPromise(),
            this.projectService.getProjectDashboardData(this.projectId).toPromise(),
            this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise()
          ]);
        }
        /**
         * Farmer Registration Data
         */
        const farmer_requests = await Promise.all([
          this.farmerService.getFarmRegQuestions().toPromise(),
          this.farmerService.GetFarmerMaster().toPromise()
        ]);
        this.farmerRegQuestions = farmer_requests[0].data;
        const masterData = farmer_requests[1].data;
        this.masterDataFarmer = masterData.filter((t)=>{return t.item_tab=="farmer_reg";});

        if (request[0]['msg'] !== 'success' || request[1]['msg'] !== 'success' || request[2]['msg'] !== 'success') {
          this.commonService.showToast('error', 'generic_error', {});
        } else {
          this.project = {...request[0].data[0], ...request[1].data[0], ...request[2].data[0]};
          this.canDoFarmerMgmtFn();
          this.setCurrencyCode();
          
          if (request[3]['message'] === 'notActive') {
            this.notActiveSurvey = 1;
            this.commonService.showToast('warning', 'No Active Survey Found', {});
          } else if (request[3]['message'] === 'notReleasedForLanguage') {
            this.notReleasedSurveyInLanguage = 1;
          } else {
            this.questionnaire = request[3]['message'];
            this.setQuestionnaireAttributes();
            this.notActiveSurvey = 0;
          }
          
          let result;
          if (this.farmerId != 0) {
            // If online, get answers from getQAnswersForFarmer (for this.farmerId)
            if (navigator.onLine) {
              result = request[4];
            } else {
              // If offline, get answers from getQAnswersForFarmer (for this.farmerId) - this is coming from request2
              result = {code: 'success'};
              let qAnswersForFarmer;
              if (request2[0] && request2[0]['message']) {
                // If request2[0] has a message attribute, getQAnswersForFarmer has been called for this farmer before
                qAnswersForFarmer = request2[0];
              } else {
                // If getQAnswersForFarmer has not been called for this farmer, get the answer values from getQAnswersForProject by filtering on farmerId
                result['message'] = request[4]['message'].filter(item => item.farmer_id === this.farmerId);
              }
              // If getQAnswersForFarmer has been called for this farmer before, compare the timestamp of the two API calls (farmer specific & project-wide data fetch)
              // Set the results array as the latest API data fetch
              if (qAnswersForFarmer) {
                    // getQAnswersForFarmer fetch      getQAnswersForProject fetch
                if (qAnswersForFarmer['ts'] > request[4]['ts']) {
                  // Set as farmer-specific API fetch
                  result['message'] = qAnswersForFarmer['message'];
                } else {
                  // Set as project-wide fetch, filtered by farmerId
                  result['message'] = request[4]['message'].filter(item => item.farmer_id === this.farmerId);
                }
              }
            }
          } else {
            result = [];
          }
          
          if (this.farmerId !== 0 && request[5]['msg'] === 'success') {
            if (navigator.onLine) {
              this.farmer = request[5]['data'];
            } else {
              this.farmer = request[5]['data'].find(item => item.id === this.farmerId);
            }
            this.setVCMapping();
          } else {
            let farmertmpobj = await idbApp.getAllProjects('farmerstmp');
            this.farmer = farmertmpobj.find(x => x.tmp_id === this.rawFarmerId);
          }

          if (this.farmer && this.farmer.FarmerQuickNotes && this.farmer.FarmerQuickNotes.length > 0) {
            this.notes = this.farmer.FarmerQuickNotes[0];  
          } else {
            this.notes = { notes: '', farmer_id: this.farmerId };
          }

          // if(navigator.onLine && request[5].code === 'success') {
          //   this.revisionsInSurvey = request[5].message;
          // }
          // Set quick notes
          //this.setFarmerQuickNotes();

          // Set the qAnswers array
          await this.setQAnswersAndInitialize(result);
          this.preProcessProjectUOMs();
          this.setAnswerToQuestions();
        }
        this.spinner.hide();
      })
    });
  }

  async setQAnswersAndInitialize(result) {
    this.qAnswers = [];
    let indexedDBEntries = [];
    // Find this rawFarmerId's indexedDB entries if any
    const farmertmpobj = await idbApp.getAllProjects('dq_profiling');
    if (farmertmpobj && farmertmpobj.length > 0) {
      let qAnswersForThisFarmerId = farmertmpobj.filter(item => item.tmp_id === this.rawFarmerId);
      if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
        for (let i = 0; i < qAnswersForThisFarmerId.length; i++) {
          indexedDBEntries = indexedDBEntries.concat(JSON.parse(qAnswersForThisFarmerId[i].values));
        }
      }
    }
    // Cached values
    if (result.code === 'success' && result.message.length > 0) {
      for (let i = 0; i < result.message.length; i++) {
        this.qAnswers.push({
          id: result.message[i].id.toString(), 
          key: result.message[i].questionnaire_asset_id.toString(), 
          sak_of_modified: result.message[i].sak_of_modified, 
          secondary_asset_key: result.message[i].secondary_asset_key, 
          value: result.message[i].answer.toString()
        });
      }
    }

    // IndexedDB values
    if (indexedDBEntries.length > 0) {
      for (let i = 0; i < indexedDBEntries.length; i++) {
        // If the indexedDB entry has an id, find the same id from qAnswers and update the value, mark as touched
        if (indexedDBEntries[i].id) {
          const x = this.qAnswers.find(item => item.id == indexedDBEntries[i].id);
          if (x) {
            const xIndex = this.qAnswers.indexOf(x);
            if (this.validIndex(xIndex)) {
              this.qAnswers[xIndex]['value'] = indexedDBEntries[i]['value'];
              this.qAnswers[xIndex]['touched'] = true;
              if (indexedDBEntries[i]['checked']) {this.qAnswers[xIndex]['checked'] = indexedDBEntries[i]['checked'];}
              if (indexedDBEntries[i]['unchecked']) {this.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['unchecked'];}
              if (indexedDBEntries[i]['deleted']) {this.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['deleted'];}
            }
          }
        } else {
          // Push into qAnswers
          const answerToInsert = {
            key: indexedDBEntries[i].key.toString(),
            sak_of_modified: indexedDBEntries[i].sak_of_modified,
            secondary_asset_key: indexedDBEntries[i].secondary_asset_key,
            value: indexedDBEntries[i].value.toString(),
            touched: true,
            checked: indexedDBEntries[i].checked
          };

          if (indexedDBEntries[i]['checked']) {
            answerToInsert['checked'] = indexedDBEntries[i]['checked'];
          }
          if (indexedDBEntries[i]['unchecked']) {
            answerToInsert['unchecked'] = indexedDBEntries[i]['unchecked'];
          }
          if (indexedDBEntries[i]['deleted']) {
            answerToInsert['unchecked'] = indexedDBEntries[i]['deleted'];
          }
          this.qAnswers.push(answerToInsert);
        }
      }
      this.askUserToConfirmExit = false;
    }
    if (this.notActiveSurvey === 0) {
      // Initialize the questionnaire
      this.initialize();
      // Set the sak_of_modified values in this.secondary_asset_key__options object (which checkbox impacted which row / column uuid in a table)
      this.setSecondaryAssetKeyOptionValues();
      // Set dynamic options (which crop intercropping questions)
      this.setDynamicOptionsObject();
    }
  }

   // Initialize the questionnaire by navigating to the 1st tab
   initialize(): void {
    this.farmerDetailsTabIndex = this.questionnaire.length+1;
    this.currentTabIndex = this.farmerDetailsTabIndex;
    this.tabClicked(this.currentTabIndex,true);
    //this.collectDisplayLogicKeyValues();
    
  }

  tabClicked(tabIndex,isFarmerTab) : void {
    if(!isFarmerTab){
      this.currentTab = this.questionnaire[tabIndex];
    }
    this.currentTabIndex = tabIndex;
  }

  setSecondaryAssetKeyOptionValues() {
    for (let i = 0; i < this.qAnswers.length; i++) {
      if (this.qAnswers[i].sak_of_modified != null) {
        this.secondary_asset_key__options[this.qAnswers[i].secondary_asset_key] = this.qAnswers[i].sak_of_modified;
      }
    }
  }

  setDynamicOptionsObject(): void {
    if (this.questionnaire && this.questionnaire !== 'noSurveys' && this.questionnaire.length > 0) {
      this.dynamicOptionsObject = {};
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
            for (let k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
              if (this.questionnaire[i].sections[j].tables[k].displayFunction && this.questionnaire[i].sections[j].tables[k].displayFunction.indexOf("addOptionToQ") > -1) {
                const tableModifyDetails = JSON.parse(this.questionnaire[i].sections[j].tables[k].displayFunction);
                if (tableModifyDetails && tableModifyDetails.addOptionToQ) {
                  const addOptionToQ = tableModifyDetails.addOptionToQ.split(",");
                  const qToAddAsOption = tableModifyDetails.qToAddAsOption;
                  let existingAnswers = this.qAnswers.filter(item => item.key == qToAddAsOption);
                  if (existingAnswers && existingAnswers.length > 0) {
                    for (let p = 0; p < addOptionToQ.length; p++) {
                      for (let q = 0; q < existingAnswers.length; q++) {
                        if (!existingAnswers[q].deleted) {
                          if (!this.dynamicOptionsObject[addOptionToQ[p]]) {this.dynamicOptionsObject[addOptionToQ[p]] = [];}
                          if (!this.dynamicOptionsObject[addOptionToQ[p]].find(item => item.sak == existingAnswers[q].secondary_asset_key)) {
                            this.dynamicOptionsObject[addOptionToQ[p]].push({q: qToAddAsOption, sak: existingAnswers[q].secondary_asset_key, label: existingAnswers[q].value});
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  preProcessProjectUOMs() {
    this.processedUOMs = {};
    if (this.project && this.project.ProjectUOM && this.project.ProjectUOM.length > 0) {
      for (let i = 0; i < this.project.ProjectUOM.length; i++) {
        if (!this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]) {
          this.processedUOMs[this.project.ProjectUOM[i].unit_type_id] = {};
          this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['units'] = [];
        }
        const projectDefaultUnit = this.project.ProjectUOM.find(item => item.is_default && item.unit_type_id === this.project.ProjectUOM[i].unit_type_id);
        this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['projectDefaultUnit'] = projectDefaultUnit;
        this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['units'].push({
          id: this.project.ProjectUOM[i].id,
          international_unit: this.project.ProjectUOM[i].international_unit,
          international_value: this.project.ProjectUOM[i].international_value,
          uom: this.project.ProjectUOM[i].uom,
          conversionFactorToProjectDefault: this.project.ProjectUOM[i].international_value / projectDefaultUnit.international_value
        });
      }
    }
  }

  canDoFarmerMgmtFn(): void {
    if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
      this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
    }
  
    if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
      this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
    }
  
    if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
      let canDoFarmerMgmt = this.usersServiceComponentMappings.filter(item => (item.services_id == 2 && item.user_type_id == this.currentUser.role.id));
      if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
        this.canDoFarmerMgmt = true;
      }
    }
    else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
      let canDoFarmerMgmt = this.ecosystemServicesComponentsMapping.filter(item => item.services_id == 2);
      if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
        this.canDoFarmerMgmt = true;
      }
    }
  }

  setCurrencyCode(): void {
    if(this.project.Currency && this.project.Currency.currency_code){
      this.currencyCode = this.project.Currency.currency_code;
    } else{
      this.currencyCode = "";
    }
  }
  setQuestionnaireAttributes(): void {
    if (this.questionnaire && this.questionnaire.length > 0) {
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (this.questionnaire[i].sections[j].displayFunction) {
            const x = JSON.parse(this.questionnaire[i].sections[j].displayFunction);
            if (x.type && x.type === 'income') {
              this.questionnaire[i].sections[j]['isIncome'] = true;
            } else if (x.type && x.type === 'expense') {
              this.questionnaire[i].sections[j]['isExpense'] = true;
            }
          }
        }
      }
    }
  }
  validIndex(index): boolean {
    if (index != null && index > -1) {
      return true;
    } return false;
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  existsInQAnswersForTableQuestion(question) {
    return this.qAnswers.filter(item => item.key == question.id && !item.deleted && !item.unchecked);
  }

    // Check if a given question has answer(s) in the qAnswers array. This should be used for non-table questions only
    existsInQAnswers(question) {
      if (!question.is_table_question) {
        let x;
        if (question.qa_subtype === 'multiselect') {
          x = this.qAnswers.filter(item => item.key == question.id);
        } else {
          x = this.qAnswers.find(item => item.key == question.id);
        }
        return x;
      }
    }
    setOtherQuestion(question, secondaryAssetKey?) {
      if (question.options && question.options.length > 0) {
        if (question.options.find(item => item.qa_subtype == 'other')) {
          if (secondaryAssetKey) {
            let existsInQAnswersForTableQuestion = this.existsInQAnswersForTableQuestion(question.other_questions[0]);
            if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
              for (let i = 0; i < existsInQAnswersForTableQuestion.length; i++) {
                if (existsInQAnswersForTableQuestion[i].secondary_asset_key == secondaryAssetKey) {
                  question.otherAnsText = existsInQAnswersForTableQuestion?existsInQAnswersForTableQuestion[i].value:null;
                }
              }
            }
          } else {
            let existsInQAnswers = this.existsInQAnswers(question.other_questions[0]);
            question.otherAnsText = existsInQAnswers?existsInQAnswers['value']:null;
          }
        }
      }
      return question;
    }

  setAnswerForQuestion(question){
    question.answerText = null;
          let answerDetails  = this.qAnswers.filter((a)=>{return a.key == question.id;});
          if(question.qa_subtype == "text" || question.qa_subtype == "number"){
            if(answerDetails && answerDetails.length>0){
              question.answerText = answerDetails[0].value;
            }
          }else if(question.qa_subtype == "select"){
            if(answerDetails && answerDetails.length>0){
              let optionsValue = question.options.filter((op)=>{return op.id ==answerDetails[0].value});
              if(optionsValue && optionsValue.length>0){
                question.answerText = optionsValue[0].survey_q_asset_labels[0].label;
              }
            }
          }else if(question.qa_subtype == "multiselect"){
            question.answerText = null;
            if(answerDetails && answerDetails.length>0){
              
              let optionsIds = [];
              let multiAns = []
              for(let ans of answerDetails){
                optionsIds.push(ans.value);
              }
              for(let op of question.options){
                if(optionsIds.indexOf(op.id + "")>-1 && op.qa_subtype != "other"){
                  multiAns.push(op.survey_q_asset_labels[0].label);
                }else if(optionsIds.indexOf(op.id + "")>-1 && op.qa_subtype == "other"){
                  multiAns.push(question.other_questions[0].survey_q_asset_labels[0].label);
                }
              }
              question.answerText = multiAns.join(" , ");
            }
          }
      question = this.setOtherQuestion(question);
      return question;
  }

  setAnswerForUOMQuestion(uomQuestion){
    uomQuestion.answerText = null;
    let answerDetails  = this.qAnswers.filter((a)=>{return a.key == uomQuestion.id;});
    uomQuestion.options = this.project.ProjectUOM.filter(item => item.unit_type_id == uomQuestion.qa_subtype);
    
    if(answerDetails && answerDetails.length>0){
      let optionsValue = uomQuestion.options.filter((op)=>{return op.id == answerDetails[0].value});
      if(optionsValue && optionsValue.length>0){
        uomQuestion.answerText = optionsValue[0].international_unit;
      }
    }
    return uomQuestion;
  }

  setAnswerToQuestions() : void{
    // this.questionnaire
    // this.qAnswers
    for(let currentTab of this.questionnaire){ // tabs
      for(let section of currentTab.sections){

        for(let question of section.questions){
          question = this.setAnswerForQuestion(question);
          if(question.include_uom_question){
            for (let uomQ of question.uom_questions) {
              uomQ = this.setAnswerForUOMQuestion(uomQ);
            }
          }
        }


        for(let subsections of section.subsections){
          for(let question of subsections.questions){
            question = this.setAnswerForQuestion(question);
            if(question.include_uom_question){
              for (let uomQ of question.uom_questions) {
                uomQ = this.setAnswerForUOMQuestion(uomQ);
              }
            }
          }
        }

        for(let tables of section.tables){
          for (let i = 0; i < tables.questions.length; i++) {
            let existsInQAnswersForTableQuestion = this.existsInQAnswersForTableQuestion(tables.questions[i]);
            this.secondary_asset_keys[tables.id] = existsInQAnswersForTableQuestion.map(item => item.secondary_asset_key)
            .filter((value, index, self) => {return self.indexOf(value) === index;});
            if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
              if(tables.questions[i].qa_subtype == "multiselect"){
                // console.log("existsInQAnswersForTableQuestion",existsInQAnswersForTableQuestion);
              }
              for (let p = 0; p < existsInQAnswersForTableQuestion.length; p++) {
                let tableKey = tables.id+tables.questions[i].id+existsInQAnswersForTableQuestion[p].secondary_asset_key;
                this.tableQuestionsAnswer[tableKey] = 
                {
                  "value" : null,
                  "otherValue" : null,
                  "uomAnswer" : null
                };

                if(tables.questions[i].qa_subtype == "text" || tables.questions[i].qa_subtype == "number"){
                  this.tableQuestionsAnswer[tableKey].value = existsInQAnswersForTableQuestion[p].value;
                }else if(tables.questions[i].qa_subtype == "select"){
                  if(existsInQAnswersForTableQuestion[p]){
                    let optionsValue = tables.questions[i].options.filter((op)=>{return op.id == existsInQAnswersForTableQuestion[p].value});
                    if(optionsValue && optionsValue.length>0){
                      this.tableQuestionsAnswer[tableKey].value = optionsValue[0].survey_q_asset_labels[0].label;
                    }
                  }
                }
                else if(tables.questions[i].qa_subtype == "multiselect"){
                  // console.log("existsInQAnswersForTableQuestion",existsInQAnswersForTableQuestion);
                //   if(answerDetails && answerDetails.length>0){
                    
                //     let optionsIds = [];
                //     let multiAns = []
                //     for(let ans of answerDetails){
                //       optionsIds.push(ans.value);
                //     }
                //     for(let op of question.options){
                //       if(optionsIds.indexOf(op.id + "")>-1 && op.qa_subtype != "other"){
                //         multiAns.push(op.survey_q_asset_labels[0].label);
                //       }else if(optionsIds.indexOf(op.id + "")>-1 && op.qa_subtype == "other"){
                //         multiAns.push(question.other_questions[0].survey_q_asset_labels[0].label);
                //       }
                //     }
                //     question.answerText = multiAns.join(" , ");
                //   }
                }
              }
            }


          }

          
        }


      }
    }



  }

  setVCMapping(): void {
    if (this.farmer) {
      if (this.farmer.vc_farmer_mappings && this.farmer.vc_farmer_mappings.length > 0) {
        if (this.farmer.vc_farmer_mappings[0].status == 'Active') {
          this.farmer.village_collector = this.farmer.vc_farmer_mappings[0].users.name;
        }
      }
    }
  }

  getMasterData(code,value){
    let answer = this.masterDataFarmer.filter((v)=>{
      return v.master_id==value && v.item_code==code;
    });
    if(answer.length>0){
      return answer[0].name;
    }else{
      return null;
    }

  }


}


