import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ConsReportService {

  CONSREPORT = this.appConfigService.getUrl(this.appConfigService.CONSREPORT);
  
  constructor(private http: HttpClient, private appConfigService: AppConfigService) { }


  exportAllSurveyData(projectId, request): Observable<any> {
    return this.http.post<any>(`${this.CONSREPORT}/exportAllSurveyData/${projectId}`, request);
  }

  getConsolidationTypeData(projectId, request): Observable<any> {
    return this.http.post<any>(`${this.CONSREPORT}/getConsolidationTypeData/${projectId}`, request);
  }

  getProjectConsolidatedReport(projectId, request): Observable<any> {
    return this.http.post<any>(`${this.CONSREPORT}/getProjectConsolidatedReport/${projectId}`, request);
  }

  saveConsolidationTypeData(projectId, request): Observable<any> {
    return this.http.post<any>(`${this.CONSREPORT}/saveConsolidationTypeData/${projectId}`, request);
  }
  
  resetDefaultConsolidationType(projectId, SurveyId): Observable<any> {
    return this.http.get<any>(`${this.CONSREPORT}/resetDefaultConsolidationType/${projectId}/${SurveyId}`);
  }
  
  getAllConsolidationTypes(projectId, request): Observable<any> {
    return this.http.post<any>(`${this.CONSREPORT}/getAllConsolidationTypes/${projectId}`, request);
  }

  getEcosystemConsolidationTypeData(ecosystemId): Observable<any> {
    return this.http.get<any>(`${this.CONSREPORT}/getEcosystemConsolidationTypeData/${ecosystemId}`);
  }
  getProjectQAsubtype(ecosystemId): Observable<any> {
    return this.http.get<any>(`${this.CONSREPORT}/getProjectQAsubtype/${ecosystemId}`);
  }
  
  getCampaignReport(projectId, campaignId, request): Observable<any> {
    return this.http.post<any>(`${this.CONSREPORT}/getCampaignReport/${projectId}/${campaignId}`, request);
  }

}