import { Component, OnInit } from '@angular/core';
import {UserService} from '../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-route-user',
  templateUrl: './route-user.component.html',
  styleUrls: ['./route-user.component.css']
})
export class RouteUserComponent implements OnInit {

  html = '<p>Hello</p>';

  constructor(
    private readonly userService: UserService,
    private readonly router: Router
  ) { }
  

  async ngOnInit() {
    const requests = await Promise.all([
      this.userService.getNavItemsForUserType().toPromise(),
    ]);
    const navItemsData =  requests[0].data;
    console.log('navItemsData', navItemsData);
    const defaultNavItem = navItemsData.filter(item => item.is_default == true)[0];
    console.log('defaultNavItem', defaultNavItem);
    if (defaultNavItem) {this.goToNew(defaultNavItem);}
  }

  goToNew(navItem) : void {
    if (navItem.user_type_id == 2 || navItem.user_type_id == 3) {
      this.router.navigate(['/ecosystems']);
    }
    else {
      const nav = navItem['NavigationControl'];
      if (nav.click_action == 'router') {
        if (nav.angular_component === "/dashboard") {
          this.router.navigate([nav.angular_component]);
        }
        else {
          if (!nav.populate_dynamic_content) {
            const url = nav.angular_component;
            this.router.navigate([url]);
          }
          else {
            const url = '/goto/' + nav.id;
            this.router.navigate([url]);
          }
        }
      }
    }
  }

}
