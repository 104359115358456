import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, TemplateRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { mapTo } from 'rxjs/operators';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { environment } from '../../../environments/environment';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataConsentService } from '../../services/data-consent.service';
import { ConsReportService } from '../../services/consolidated-reports.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
var ProfilingComponent = /** @class */ (function () {
    function ProfilingComponent(route, commonService, router, farmerService, projectService, userService, translate, toastrService, modalService, localeService, formBuilder, revisionService, dynamicQuestionnaireService, surveyService, consReportService, spinner, dataConsentService, sanitizer, locale, http) {
        var _this = this;
        this.route = route;
        this.commonService = commonService;
        this.router = router;
        this.farmerService = farmerService;
        this.projectService = projectService;
        this.userService = userService;
        this.translate = translate;
        this.toastrService = toastrService;
        this.modalService = modalService;
        this.localeService = localeService;
        this.formBuilder = formBuilder;
        this.revisionService = revisionService;
        this.dynamicQuestionnaireService = dynamicQuestionnaireService;
        this.surveyService = surveyService;
        this.consReportService = consReportService;
        this.spinner = spinner;
        this.dataConsentService = dataConsentService;
        this.sanitizer = sanitizer;
        this.locale = locale;
        this.http = http;
        this.projectId = 0;
        this.farmers = [];
        this.productType = [];
        this.managers = [];
        this.isAscending = true;
        this.isAscendingId = true;
        this.isAscendingName = true;
        this.isAscendingTel = true;
        this.isAscendingDate = true;
        this.isAscendingFarmerNo = true; //Official farmer id added 
        this.sortLabel = '';
        this.showSpinner = false;
        this.isFieldAgent = false;
        this.isManager = false;
        this.isDeeproots = false;
        this.farmerFilterString = { name: '' };
        this.page = 1;
        this.itemsPerPage = 10;
        this.searchText = '';
        this.isInIDBInThisProject = [];
        this.showSyncSuccessAlert = false;
        this.olderSnapshots = [];
        this.minDate = new Date();
        this.noSnapshotsInProject = false;
        this.fetchingSnapshotInfo = true;
        this.disableCreateSnapshot = false;
        this.disableCloseSnapshot = false;
        this.registeringFarmer = false;
        this.registerAnotherFarmer = false;
        this.fileToUpload = null;
        this.photoModel = '';
        this.oldSnapshotId = 0;
        this.arrSnapshotRevisions = [];
        this.snapshotRevision = [];
        this.canExportToExcel = false;
        this.canMakeRevision = false;
        this.selectedFarmers = [];
        this.allTabNames = [];
        this.confirmTEDModal = true;
        this.confirmConsentModal = true;
        this.selectedConsentCode = true;
        this.showSurveyManualIcon = false;
        this.filteredfarmers = [];
        this.route.paramMap.subscribe(function (params) {
            _this.id = params.get('id');
        });
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
        this.translate.get('there_no_active_snapshot_for_this_project').subscribe(function (result) {
            _this.thereNoActiveSnapshotForThisProject = result;
        });
        this.translate.get('revision').subscribe(function (result) {
            _this.revisionTxt = result;
        });
        this.translate.get('created_successfully').subscribe(function (result) {
            _this.createSuccessTxt = result;
        });
        this.translate.get('select_deselect_object').subscribe(function (result) {
            _this.selectDeselectLabels = result;
        });
        this.translate.get('data_will_be_synced').subscribe(function (result) {
            _this.tooltipText2 = result;
        });
        this.translate.get('farmer_profiling').subscribe(function (result) {
            _this.farmerProfiling = result;
        });
        this.translate.get('consent_template_not_available').subscribe(function (result) {
            _this.consentTemplateNotAvailable = result;
        });
        this.translate.get('consent_added_succssfully').subscribe(function (result) {
            _this.consentAddedSuccssfully = result;
        });
        this.translate.get('entire_survey').subscribe(function (result) {
            _this.entireSurvey = result;
        });
        this.translate.get('marked_unavailable').subscribe(function (result) {
            _this.farmerMarkedUnavailableSuccessfully = result;
        });
        this.translate.get('marked_available').subscribe(function (result) {
            _this.farmerMarkedAvailableSuccessfully = result;
        });
        this.localeService.use(this.locale);
        this.translate.get('no_revision_txt').subscribe(function (result) {
            _this.noChangeRevisionTxt = result;
        });
    }
    ProfilingComponent.prototype.snapshotChanged = function () {
        var _this = this;
        this.snapshotRevision = this.arrSnapshotRevisions.filter(function (x) { return x.snapshot_id_ref === _this.oldSnapshotId; });
    };
    ProfilingComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var code, canExportToExcel, canMakeRevision, canRecordConsent, canExportToExcel, canMakeRevision, canRecordConsent, requests, requests, canExportToExcel, canMakeRevision, canRecordConsent, canExportToExcel, canMakeRevision, canRecordConsent;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scrollToTop();
                        this.projectId = +this.route.snapshot.params['id'];
                        code = this.commonService.checkRole();
                        this.currentUser = this.userService.getCurrentUser();
                        if (code == 'FAG') {
                            this.isFieldAgent = true;
                        }
                        else if (code === 'PMG')
                            this.isManager = true;
                        else if (code === 'SYS1') {
                            this.isDeeproots = true;
                        }
                        if (!navigator.onLine) return [3 /*break*/, 2];
                        this.project = this.route.snapshot.data.project.project;
                        // console.log('this.project', this.project);
                        if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
                            this.UPM = this.project.ProjectMapping.find(function (item) { return item.users_id == _this.currentUser.id; });
                            // this.UPM.access_type_id = 3;
                        }
                        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
                            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
                        }
                        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
                            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
                        }
                        // console.log('this.project', this.project);
                        if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
                            canExportToExcel = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 4 && item.user_type_id == _this.currentUser.role.id); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                            canMakeRevision = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 5 && item.user_type_id == _this.currentUser.role.id); });
                            if (canMakeRevision && canMakeRevision.length > 0) {
                                this.canMakeRevision = true;
                            }
                            canRecordConsent = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "FR_CON_REC" && item.user_type_id == _this.currentUser.role.id); });
                            if (canRecordConsent && canRecordConsent.length > 0) {
                                this.canRecordConsent = true;
                            }
                        }
                        else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
                            canExportToExcel = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 4); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                            canMakeRevision = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 5); });
                            if (canMakeRevision && canMakeRevision.length > 0) {
                                this.canMakeRevision = true;
                            }
                            canRecordConsent = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "FR_CON_REC"); });
                            if (canRecordConsent && canRecordConsent.length > 0) {
                                this.canRecordConsent = true;
                            }
                        }
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                                this.surveyService.getAllSurveysForProject(this.projectId).toPromise(),
                                this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise(),
                                this.farmerService.getFarmerAvailibility(this.projectId).toPromise()
                            ])];
                    case 1:
                        requests = _a.sent();
                        this.farmers = requests[0].data;
                        this.allSurveys = requests[1].message;
                        // this.surveyStats = requests[2].message;
                        // console.log('this.surveyStats', this.surveyStats);
                        // Get any farmers saved in indexed db for this project
                        this.getFarmerConsents();
                        this.setOfflineData();
                        this.setSurveyStats(requests[2].message);
                        // this.master = this.route.snapshot.data.project.master.data;
                        // this.allSnapshots = this.project.QuesProjectSnapshotMappings;
                        this.setSurveys();
                        if (requests[3].msg == "success") {
                            this.farmerNA = requests[3].code;
                        }
                        this.setFarmerNA();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, Promise.all([
                            this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                            this.surveyService.getAllSurveysForProject(this.projectId).toPromise(),
                            this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise(),
                            this.farmerService.getFarmerAvailibility(this.projectId).toPromise()
                        ])];
                    case 3:
                        requests = _a.sent();
                        // this.project = requests[0].data.find(x => x.id == this.projectId);
                        this.project = this.route.snapshot.data.project.project;
                        if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
                            this.UPM = this.project.ProjectMapping.find(function (item) { return item.users_id == _this.currentUser.id; });
                            // this.UPM.access_type_id = 3;
                        }
                        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
                            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
                        }
                        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
                            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
                        }
                        if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
                            canExportToExcel = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 4 && item.user_type_id == _this.currentUser.role.id); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                            canMakeRevision = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 5 && item.user_type_id == _this.currentUser.role.id); });
                            if (canMakeRevision && canMakeRevision.length > 0) {
                                this.canMakeRevision = true;
                            }
                            canRecordConsent = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "FR_CON_REC" && item.user_type_id == _this.currentUser.role.id); });
                            if (canRecordConsent && canRecordConsent.length > 0) {
                                this.canRecordConsent = true;
                            }
                        }
                        else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
                            canExportToExcel = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 4); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                            canMakeRevision = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 5); });
                            if (canMakeRevision && canMakeRevision.length > 0) {
                                this.canMakeRevision = true;
                            }
                            canRecordConsent = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "FR_CON_REC"); });
                            if (canRecordConsent && canRecordConsent.length > 0) {
                                this.canRecordConsent = true;
                            }
                        }
                        this.farmers = requests[0].data;
                        this.allSurveys = requests[1].message;
                        this.setFarmerRegData();
                        // const snapshotData = requests[1].data;
                        // let projectSnapshots = snapshotData.filter(x => x.project == this.projectId);
                        // if (projectSnapshots && projectSnapshots.length > 0) {
                        //   this.allSnapshots = projectSnapshots[0].snapshots;
                        // } else {
                        //   // console.log('projectSnapshots is null or length 0');
                        //   this.allSnapshots = [];
                        // }
                        // debugger;;
                        // Get any farmers saved in indexed db for this project
                        this.getFarmerConsents();
                        this.setOfflineData();
                        this.setSurveyStats(requests[2].message);
                        this.setSurveys();
                        if (requests[3].msg == "success") {
                            this.farmerNA = requests[3].code;
                        }
                        this.setFarmerNA();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.setOfflineData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmertmpobj, idbAppFarmers, idbAppFarmersUpdatedOffline, _loop_1, this_1, i;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, idbApp.getAllProjects('farmerstmp')];
                    case 1:
                        farmertmpobj = _a.sent();
                        idbAppFarmers = farmertmpobj.filter(function (x) { return x.project_id == _this.projectId && x.savetab == 'Tab0'; });
                        if (idbAppFarmers && idbAppFarmers.length > 0) {
                            idbAppFarmers.sort(function (a, b) {
                                var aDate = new Date(a.cr_date);
                                var bDate = new Date(b.cr_date);
                                return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
                            });
                            this.farmers = idbAppFarmers.concat(this.farmers);
                        }
                        idbAppFarmersUpdatedOffline = farmertmpobj.filter(function (x) { return x.project_id == _this.projectId && x.savetab == 'Tab00'; });
                        if (idbAppFarmersUpdatedOffline && idbAppFarmersUpdatedOffline.length > 0) {
                            _loop_1 = function (i) {
                                var farmerUpdated = void 0;
                                if (idbAppFarmersUpdatedOffline[i].id > 0) {
                                    // console.log('id is greater than 0');
                                    farmerUpdated = this_1.farmers.filter(function (item) { return item.id == idbAppFarmersUpdatedOffline[i].id; });
                                }
                                else {
                                    // console.log('id is equal to 0');
                                    farmerUpdated = this_1.farmers.filter(function (item) { return item.tmp_id == idbAppFarmersUpdatedOffline[i].tmp_id.slice(0, -1); });
                                }
                                if (farmerUpdated && farmerUpdated.length > 0) {
                                    var index = this_1.farmers.indexOf(farmerUpdated[0]);
                                    if (index != null && index > -1) {
                                        this_1.farmers[index] = idbAppFarmersUpdatedOffline[i];
                                    }
                                }
                            };
                            this_1 = this;
                            for (i = 0; i < idbAppFarmersUpdatedOffline.length; i++) {
                                _loop_1(i);
                            }
                        }
                        // this.setFilledPercentageForOfflineProfiledFarmers();
                        this.filteredfarmers = this.farmers;
                        this.sortFarmers();
                        this.setQuickNotesData();
                        this.setOfflineSignData();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.setOfflineSignData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmersignobj, faremerWithTemId, _loop_2, _i, faremerWithTemId_1, item;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, idbAppPhoto.getAllProjects('farmerSignaturetmp')];
                    case 1:
                        farmersignobj = _a.sent();
                        faremerWithTemId = this.farmers.filter(function (x) { return x.tmp_id; });
                        _loop_2 = function (item) {
                            var findSignature = farmersignobj.find(function (x) { return x.tmp_id == item.tmp_id; });
                            if (findSignature) {
                                item.consentPending = false;
                                item.valid_upto = 730;
                            }
                        };
                        for (_i = 0, faremerWithTemId_1 = faremerWithTemId; _i < faremerWithTemId_1.length; _i++) {
                            item = faremerWithTemId_1[_i];
                            _loop_2(item);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.goToDashboard = function () {
        this.router.navigate(['dashboard']);
    };
    ProfilingComponent.prototype.goToFarmer = function (farmer) {
        var url;
        if (this.snapshot) {
            if (farmer.id && farmer.id > 0) {
                url = '/questionnaire/respond/' + farmer.project_id + '/' + farmer.id;
            }
            else if (farmer.tmp_id && !navigator.onLine) {
                if (farmer.tmp_id.indexOf('Tab00') > -1) {
                    url = '/questionnaire/respond/' + farmer.project_id + '/' + farmer.tmp_id.substring(0, farmer.tmp_id.length - 1);
                }
                else {
                    url = '/questionnaire/respond/' + farmer.project_id + '/' + farmer.tmp_id;
                }
            }
            else if (farmer.tmp_id && navigator.onLine) {
                this.commonService.showToast('warning', 'please_wait_data_sync', { preventDuplicates: true });
                return;
            }
            else {
                url = '/error';
            }
            this.router.navigate([url]);
        }
        else {
            this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
        }
    };
    ProfilingComponent.prototype.goToViewFarmer = function (farmer) {
        var url;
        // if (this.snapshot) {
        if (farmer.id && farmer.id > 0) {
            url = '/viewprofile/' + farmer.project_id + '/' + farmer.id;
        }
        else if (farmer.tmp_id) {
            url = '/viewprofile/' + farmer.project_id + '/' + farmer.tmp_id;
        }
        else {
            url = '/error';
        }
        this.router.navigate([url]);
        // } else {
        //   this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
        // }
    };
    ProfilingComponent.prototype.scrollToTop = function () {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    };
    ProfilingComponent.prototype.ngAfterViewInit = function () {
        if (this.project && this.project.QuesProjectSnapshotMappings && this.project.QuesProjectSnapshotMappings.length > 0) {
            // If the active snapshot is in draft stage, fetch complete QuesProjectSnapshotMappings object from server
            // If the active snapshot is not in draft stage, fetch the complete QuesProjectSnapshotMappings object from the cache...
            // ... have to wait for the managerpage ngAfterViewInit function to finish getting the response for this to work
        }
    };
    ProfilingComponent.prototype.ngOnDestroy = function () {
        this.showSpinner = false;
        clearInterval(this.getSnapshotInterval);
    };
    ProfilingComponent.prototype.sortBy = function (sortOn) {
        // console.log(sortOn);
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    ProfilingComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        if (sortBy === 'name') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.isAscendingFarmerNo = true;
            this.farmers.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.isAscendingFarmerNo = true;
            this.farmers.sort(function (a, b) {
                if (a.id < b.id) {
                    return 1;
                }
                if (a.id > b.id) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'tel') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.isAscendingFarmerNo = true;
            this.farmers.sort(function (a, b) {
                if (a.tel_number < b.tel_number) {
                    return 1;
                }
                if (a.tel_number > b.tel_number) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'date') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.isAscendingFarmerNo = true;
            this.farmers.sort(function (a, b) {
                if (a.lastProfiledAt === b.lastProfiledAt) {
                    return 0;
                }
                // nulls sort after anything else
                if (a.lastProfiledAt === null) {
                    return 1;
                }
                if (b.lastProfiledAt === null) {
                    return -1;
                }
                if (a.lastProfiledAt < b.lastProfiledAt) {
                    return 1;
                }
                if (a.lastProfiledAt > b.lastProfiledAt) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'official_id') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.isAscendingFarmerNo = false;
            this.farmers.sort(function (a, b) {
                if (a.official_id === null) {
                    return 1;
                }
                if (b.official_id === null) {
                    return -1;
                }
                if (a && a.official_id != null && b && b.official_id != null) {
                    if (a.official_id < b.official_id) {
                        return 1;
                    }
                    if (a.official_id > b.official_id) {
                        return -1;
                    }
                    return 0;
                }
            });
        }
    };
    ProfilingComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        if (sortBy === 'name') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.isAscendingFarmerNo = false;
            this.farmers.sort(function (a, b) {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.isAscendingFarmerNo = false;
            this.farmers.sort(function (a, b) {
                if (a.id > b.id) {
                    return 1;
                }
                if (a.id < b.id) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'tel') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.isAscendingFarmerNo = false;
            this.farmers.sort(function (a, b) {
                if (a.tel_number > b.tel_number) {
                    return 1;
                }
                if (a.tel_number < b.tel_number) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'date') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.isAscendingFarmerNo = false;
            this.farmers.sort(function (a, b) {
                if (a.lastProfiledAt === b.lastProfiledAt) {
                    return 0;
                }
                // nulls sort after anything else
                if (a.lastProfiledAt === null) {
                    return -1;
                }
                if (b.lastProfiledAt === null) {
                    return 1;
                }
                if (a.lastProfiledAt > b.lastProfiledAt) {
                    return 1;
                }
                if (a.lastProfiledAt < b.lastProfiledAt) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'official_id') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.isAscendingFarmerNo = true;
            this.farmers.sort(function (a, b) {
                if (a.official_id === null) {
                    return 1;
                }
                if (b.official_id === null) {
                    return -1;
                }
                if (a && a.official_id != null && b && b.official_id != null) {
                    if (a.official_id > b.official_id) {
                        return 1;
                    }
                    if (a.official_id < b.official_id) {
                        return -1;
                    }
                    return 0;
                }
            });
        }
    };
    ProfilingComponent.prototype.updateSyncStatusShown = function (args) {
        var _this = this;
        // console.log('args', args);
        for (var i = 0; i < this.farmers.length; i++) {
            // if there is a non-zero farmer_id in the allData element, it is an update object
            this.farmers[i]['isInIDB'] = false;
        }
        var numberOfUnsyncedFarmers = this.isInIDBInThisProject.length;
        this.isInIDBInThisProject = [];
        if (numberOfUnsyncedFarmers && numberOfUnsyncedFarmers > 0) {
            numberOfUnsyncedFarmers = 0;
            this.showSyncSuccessAlert = true;
            setTimeout(function () { _this.showSyncSuccessAlert = false; }, 5000);
        }
    };
    ProfilingComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['farmer_code', 'name', 'official_id', 'lastProfiledAt'];
        var finalObj = {};
        if (this.farmers === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    ProfilingComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    ProfilingComponent.prototype.setSnapshots = function () {
        //debugger;
        var currentSnapshotArray = this.allSnapshots.filter(function (item) { return item.status === 'active'; });
        if (currentSnapshotArray && currentSnapshotArray.length > 0) {
            this.snapshot = currentSnapshotArray[0];
        }
        else {
            this.snapshot = undefined;
        }
    };
    ProfilingComponent.prototype.setSurveys = function () {
        if (this.allSurveys && this.allSurveys.length > 0) {
            var snapshotSurveys = this.allSurveys.filter(function (item) { return item.survey_type == 'snapshot'; });
            if (snapshotSurveys && snapshotSurveys.length > 0) {
                this.snapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
            }
            else {
                this.snapshot = undefined;
            }
        }
        else {
            this.snapshot = undefined;
        }
        if (this.snapshot) {
            if (this.snapshot.flex4) {
                this.showSurveyManualIcon = true;
            }
        }
    };
    ProfilingComponent.prototype.syncDone = function (args) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.onLine) return [3 /*break*/, 3];
                        window.dispatchEvent(new Event('caching-now'));
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                                // this.dynamicQuestionnaireService.getQAnswersForProject(this.projectId, null).toPromise(),
                                this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise(),
                                this.farmerService.getFarmerAvailibility(this.projectId).toPromise()
                            ])];
                    case 1:
                        requests = _a.sent();
                        this.farmers = requests[0].data;
                        this.sortFarmers();
                        this.setSurveyStats(requests[1].message);
                        if (requests[2].msg == "success") {
                            this.farmerNA = requests[2].code;
                        }
                        this.setFarmerNA();
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise()
                            ])
                                .then(function (result) {
                                if (result[0]['code'] === "success") {
                                    for (var i = 0; i < result[0]['message'].length; i++) {
                                        _this.loadFarmerDataIntoCache(_this.projectId, result[0]['message'][i], result[0]['message'][i + 1]);
                                    }
                                }
                            }).catch(function (e) { })];
                    case 2:
                        _a.sent();
                        this.getFarmerConsents();
                        window.dispatchEvent(new Event('data-synced'));
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.exportFarmers = function (template) {
        var _this = this;
        if (this.snapshot) {
            this.spinner.show();
            this.selectedTabCode = undefined;
            if (this.selectedFarmers.length) {
                if (this.projectId != null && this.projectId != 0) {
                    this.surveyService.getProfilingTabKeys(this.projectId, {})
                        .subscribe(function (result) {
                        if (result.code == 'success') {
                            _this.allTabNames = result.message;
                            _this.selectTabName = _this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
                            _this.spinner.hide();
                        }
                        else {
                            _this.toastrService.info('Information: ' + result.msgText);
                            _this.spinner.hide();
                        }
                    });
                }
            }
            else {
                this.toastrService.info('Information: ' + "Select Farmers first");
            }
        }
        else {
            this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
        }
    };
    //download excel for selected tab and farmers
    ProfilingComponent.prototype.submitTabName = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var selTemp, exportExcelRequest, selectedTabName_1, chunk, splittedArrayLength, splittedArray, wb, i, newRequest, requests, wsBooks, wscols, sheetName, fileType, excelBuffer, blob, linkdownload, today, dd, mm, yyyy, today1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.closeTabNamePopup();
                        this.spinner.show();
                        selTemp = this.farmers.filter(function (f) { return _this.selectedFarmers.includes(f.id) && (f.percentageCompleted || f.is_discontinued); });
                        selTemp = selTemp.map(function (f) {
                            return f.id;
                        });
                        exportExcelRequest = {
                            "tabCode": this.selectedTabCode,
                            "farmers": selTemp
                        };
                        if (!(this.selectedTabCode != 'entireSurvey')) return [3 /*break*/, 1];
                        selectedTabName_1 = this.allTabNames.find(function (item) { return item.code == _this.selectedTabCode; });
                        this.surveyService.exportFarmerProfilingData(this.projectId, exportExcelRequest)
                            .subscribe(function (exportResult) {
                            var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
                            var blob = new Blob([exportResult], { type: contentType });
                            var linkdownload = document.createElement('a');
                            linkdownload.href = URL.createObjectURL(blob);
                            var today = new Date();
                            var dd = String(today.getDate()).padStart(2, '0');
                            var mm = String(today.getMonth() + 1).padStart(2, '0');
                            var yyyy = today.getFullYear();
                            var today1 = dd + '-' + mm + '-' + yyyy;
                            linkdownload.download = 'P2F-Roots_' + _this.farmerProfiling + '_' + selectedTabName_1.survey_q_asset_labels[0].label + '_' + today1 + '.xlsx';
                            linkdownload.style.display = 'none';
                            document.body.appendChild(linkdownload);
                            linkdownload.click();
                            linkdownload.remove();
                            _this.selectedFarmers = [];
                            _this.toastrService.success("Excel Downloaded");
                            _this.spinner.hide();
                        });
                        return [3 /*break*/, 6];
                    case 1:
                        chunk = void 0;
                        splittedArrayLength = 500;
                        splittedArray = [];
                        while (selTemp.length > 0) {
                            chunk = selTemp.splice(0, splittedArrayLength);
                            splittedArray.push(chunk);
                        }
                        wb = XLSX.utils.book_new();
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < splittedArray.length)) return [3 /*break*/, 5];
                        newRequest = {
                            "tabCode": this.selectedTabCode,
                            "farmers": splittedArray[i]
                        };
                        return [4 /*yield*/, Promise.all([
                                this.consReportService.exportAllSurveyData(this.projectId, newRequest).toPromise()
                            ])];
                    case 3:
                        requests = _a.sent();
                        wsBooks = XLSX.utils.json_to_sheet(requests[0].message);
                        wscols = [
                            { wpx: 80 },
                            { wpx: 80 },
                            { wpx: 120 },
                            { wpx: 300 },
                            { wpx: 100 },
                            { wpx: 80 },
                            { wpx: 80 },
                            { wpx: 200 },
                            { wpx: 200 },
                            { wpx: 200 }
                        ];
                        wsBooks['!cols'] = wscols;
                        sheetName = "Farmer Sheet " + (i + 1);
                        XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5:
                        fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                        excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        blob = new Blob([excelBuffer], { type: fileType });
                        linkdownload = document.createElement('a');
                        linkdownload.href = URL.createObjectURL(blob);
                        today = new Date();
                        dd = String(today.getDate()).padStart(2, '0');
                        mm = String(today.getMonth() + 1).padStart(2, '0');
                        yyyy = today.getFullYear();
                        today1 = dd + '-' + mm + '-' + yyyy;
                        linkdownload.download = 'P2F-Roots_' + this.farmerProfiling + '_' + this.entireSurvey + '_Project_' + this.projectId + '_' + today1 + '.xlsx';
                        linkdownload.style.display = 'none';
                        document.body.appendChild(linkdownload);
                        linkdownload.click();
                        linkdownload.remove();
                        this.selectedFarmers = [];
                        this.toastrService.success("Excel Downloaded");
                        this.spinner.hide();
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    // exportExcel(excelData) {
    //   //Title, Header & Data
    //   const today = new Date();
    //   const dd = String(today.getDate()).padStart(2, '0');
    //   const mm = String(today.getMonth() + 1).padStart(2, '0');
    //   const yyyy = today.getFullYear();
    //   const today1 = dd + '-' + mm + '-' + yyyy;
    //   const title = 'P2F-Roots_' + this.farmerProfiling + '_Entire_Survey_' + today1 + '.xlsx';
    //   const header = Object.keys(excelData[0])
    //   const data = excelData;
    //   console.log('data', data)
    //   //Create a workbook with a worksheet
    //   let workbook: ExcelProper.Workbook = new Excel.Workbook();
    //   let worksheet = workbook.addWorksheet('Farmer Data');
    //   worksheet.columns = [
    //     { header: 'Question Id', key: 'Question Id', width : 16 },
    //     { header: 'Tab Name', key: 'Tab Name', width : 16 },
    //     { header: 'Section', key: 'Section', width : 24 },
    //     { header: 'Question Label', key: 'Question Label', width : 60 },
    //     { header: 'Farmer Name', key: 'Farmer Name', width : 20 },
    //     { header: 'Farmer Code', key: 'Farmer Code', width : 16 },
    //     { header: 'Official Id', key: 'Official Id', width : 16 },
    //     { header: 'Survey 1 (14-Dec-2021)', key: 'Survey 1 (14-Dec-2021)', width : 40 },
    //     { header: 'Survey 2 (2-Nov-2021 to 14-Dec-2021)', key: 'Survey 2 (2-Nov-2021 to 14-Dec-2021)', width : 40 },
    //     { header: 'Survey 3 (12-Jun-2021 to 2-Nov-2021)', key: 'Survey 3 (12-Jun-2021 to 2-Nov-2021)', width : 40 }
    //   ];
    //   //Blank Row 
    //   for (const row of data){
    //     worksheet.addRow(row);
    //   }
    //   worksheet.getRow(1).font = { bold: true, color: {argb: "ffffff"} };
    //   worksheet.getRow(1).fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: '0000FF' },
    //   };
    //   worksheet.views = [
    //     { state: 'frozen', xSplit: 0, ySplit: 1, activeCell: 'B2' }
    //   ];  
    //   //Generate & Save Excel File
    //   workbook.xlsx.writeBuffer().then((data) => {
    //     let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //     fs.saveAs(blob, title + '.xlsx');
    //   })
    // }
    // Toggle a particular farmer's selection status
    ProfilingComponent.prototype.toggleThisFarmerSelect = function (id) {
        if (this.selectedFarmers.indexOf(id) > -1) {
            this.selectedFarmers = this.selectedFarmers.filter(function (item) { return item != id; });
        }
        else
            this.selectedFarmers.push(id);
        // console.log("single", this.selectedFarmers)
        this.setTooltipText();
    };
    // Select / deselect all farmers in the array
    ProfilingComponent.prototype.toggleAllFarmers = function () {
        // If all farmers are already selected, then deselect all farmers
        if (this.selectedFarmers.length == this.farmers.length) {
            this.selectedFarmers = [];
        }
        // If all farmers are NOT already selected, select all the farmers
        else {
            this.selectedFarmers = [];
            for (var i = 0; i < this.farmers.length; i++) {
                this.selectedFarmers.push(this.farmers[i].id);
            }
        }
        this.setTooltipText();
    };
    // Set the profiling % for farmers that were profiled in offline mode
    ProfilingComponent.prototype.setFilledPercentageForOfflineProfiledFarmers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmertmpobj, idbAppFarmersUpdatedOffline, _loop_3, this_2, i;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, idbApp.getAllProjects('farmerstmp')];
                    case 1:
                        farmertmpobj = _a.sent();
                        idbAppFarmersUpdatedOffline = farmertmpobj.filter(function (x) { return x.project_id == _this.projectId && x.savetab != 'Tab0' && x.savetab != 'Tab00'; });
                        if (idbAppFarmersUpdatedOffline && idbAppFarmersUpdatedOffline.length > 0) {
                            _loop_3 = function (i) {
                                var farmerUpdated = void 0;
                                if (idbAppFarmersUpdatedOffline[i].id && idbAppFarmersUpdatedOffline[i].id > 0) {
                                    farmerUpdated = this_2.farmers.filter(function (item) { return item.id == idbAppFarmersUpdatedOffline[i].id; });
                                }
                                else {
                                    var tempId_1 = idbAppFarmersUpdatedOffline[i].tmp_id.slice(0, -1);
                                    tempId_1 = tempId_1 + '0';
                                    var tempId00_1 = tempId_1 + '0';
                                    farmerUpdated = this_2.farmers.filter(function (item) { return item.tmp_id == tempId_1 || item.tmp_id == tempId00_1; });
                                }
                                if (farmerUpdated && farmerUpdated.length > 0) {
                                    var index = this_2.farmers.indexOf(farmerUpdated[0]);
                                    if (index != null && index > -1) {
                                        this_2.farmers[index]['filled_questions'] = idbAppFarmersUpdatedOffline[i]['filled_questions'];
                                        this_2.farmers[index]['total_questions'] = idbAppFarmersUpdatedOffline[i]['total_questions'];
                                    }
                                }
                            };
                            this_2 = this;
                            // For each of these entries, find the entry updated in this.farmers & update the value of filled_questions & total _questions
                            // As looping through idb in sequence, the last entry for any particular farmer will supply the final value displayed on screen
                            for (i = 0; i < idbAppFarmersUpdatedOffline.length; i++) {
                                _loop_3(i);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.setFarmerRegData = function () {
        if (this.farmers && this.farmers.length > 0) {
            for (var i = 0; i < this.farmers.length; i++) {
                if (this.farmers[i].farmer_reg) {
                    this.farmers[i] = tslib_1.__assign({}, this.farmers[i], this.farmers[i]['farmer_reg']);
                }
            }
        }
    };
    ProfilingComponent.prototype.sortFarmers = function () {
        this.farmers.sort(function (a, b) {
            if (a.cr_date < b.cr_date) {
                return 1;
            }
            if (a.cr_date > b.cr_date) {
                return -1;
            }
            return 0;
        });
    };
    ProfilingComponent.prototype.profileCompletionCalculation = function () {
    };
    ProfilingComponent.prototype.quickNoteView = function (currentItem, template) {
        this.quickNoteData = currentItem;
        //console.log("quickNoteData ",this.quickNoteData[0].notes);
        this.quickNoteModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    ProfilingComponent.prototype.closePrompt = function () {
        if (this.quickNoteModal) {
            this.quickNoteModal.hide();
        }
    };
    ProfilingComponent.prototype.closeTabNamePopup = function (fromUI) {
        if (fromUI) {
            if (this.selectedTabCode) {
                this.confirmTEDModal = false;
            }
            else if (this.selectTabName) {
                this.selectTabName.hide();
                this.confirmTEDModal = true;
            }
        }
        else if (this.selectTabName) {
            this.confirmTEDModal = true;
            this.selectTabName.hide();
        }
    };
    ProfilingComponent.prototype.confirmModalFn = function (decision) {
        if (decision == "Yes") {
            this.selectTabName.hide();
            this.confirmTEDModal = true;
        }
        else {
            this.confirmTEDModal = true;
        }
    };
    //added by Kiran for escape button click event
    ProfilingComponent.prototype.onKeydownHandler = function (event) {
        if (event.key === "Escape") {
            if (this.quickNoteModal) {
                this.quickNoteModal.hide();
            }
            this.closeTabNamePopup(true);
            this.closeConsentPopup();
        }
    };
    ProfilingComponent.prototype.makeRevisionDynamicQuestionnaire = function () {
        var _this = this;
        if (this.snapshot) {
            this.dynamicQuestionnaireService.makeRevision(this.snapshot.id)
                .subscribe(function (result) {
                console.log('result', result);
                if (result.code == 'success') {
                    if (result.message == "no_change") {
                        _this.toastrService.warning(_this.noChangeRevisionTxt);
                    }
                    else {
                        _this.toastrService.success(_this.revisionTxt + " " + result.message.revision_number + " " + _this.createSuccessTxt);
                    }
                }
            });
        }
    };
    ProfilingComponent.prototype.setSurveyStats = function (stats) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var surveyStatsOnline, surveyStatsFromIDB, _loop_4, this_3, i;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(stats != 'survey_not_active')) return [3 /*break*/, 2];
                        surveyStatsOnline = stats;
                        this.surveyStats = stats;
                        return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 1:
                        surveyStatsFromIDB = _a.sent();
                        _loop_4 = function (i) {
                            if (this_3.farmers[i].id && this_3.farmers[i].id != 0) {
                                var statsForFarmer = surveyStatsOnline.find(function (item) { return item.farmer_id == _this.farmers[i].id; });
                                if (!statsForFarmer) {
                                    this_3.farmers[i]['percentageCompleted'] = 0;
                                    this_3.farmers[i]['lastProfiledAt'] = null;
                                }
                                else {
                                    this_3.farmers[i]['percentageCompleted'] = Math.round(parseInt(statsForFarmer.answered_questions) / parseInt(statsForFarmer.total_questions) * 100);
                                    this_3.farmers[i]['lastProfiledAt'] = statsForFarmer.upd_date ? statsForFarmer.upd_date : statsForFarmer.cr_date;
                                }
                                var statsForFarmerFromIDB = surveyStatsFromIDB.find(function (item) { return item.tmp_id == _this.farmers[i].id && !item.campaignId; });
                                if (statsForFarmerFromIDB) {
                                    var x = JSON.parse(statsForFarmerFromIDB.stats);
                                    if (x) {
                                        this_3.farmers[i]['percentageCompleted'] = Math.round(parseInt(x[2]) / parseInt(x[1]) * 100);
                                        this_3.farmers[i]['lastProfiledAt'] = new Date(statsForFarmerFromIDB.updatedAt);
                                    }
                                }
                            }
                            else {
                                var tmp_id_1 = this_3.farmers[i].tmp_id;
                                if (tmp_id_1.indexOf('_Tab00') > -1) {
                                    tmp_id_1 = tmp_id_1.substring(0, tmp_id_1.length - 1);
                                }
                                var statsForFarmerFromIDB = surveyStatsFromIDB.find(function (item) { return item.tmp_id == tmp_id_1 && !item.campaignId; });
                                if (statsForFarmerFromIDB) {
                                    var x = JSON.parse(statsForFarmerFromIDB.stats);
                                    if (x) {
                                        this_3.farmers[i]['percentageCompleted'] = Math.round(parseInt(x[2]) / parseInt(x[1]) * 100);
                                        this_3.farmers[i]['lastProfiledAt'] = new Date(statsForFarmerFromIDB.updatedAt);
                                    }
                                }
                            }
                        };
                        this_3 = this;
                        for (i = 0; i < this.farmers.length; i++) {
                            _loop_4(i);
                        }
                        return [3 /*break*/, 2];
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.setQuickNotesData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, f, farmertmpobj, idbAppFarmersQuickNotesOffline_1, _loop_5, this_4, i;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.farmers && this.farmers.length > 0)) return [3 /*break*/, 2];
                        for (_i = 0, _a = this.farmers; _i < _a.length; _i++) {
                            f = _a[_i];
                            if (!f['FarmerQuickNotes']) {
                                f['FarmerQuickNotes'] = [];
                            }
                        }
                        return [4 /*yield*/, idbApp.getAllProjects('farmerstmp')];
                    case 1:
                        farmertmpobj = _b.sent();
                        idbAppFarmersQuickNotesOffline_1 = farmertmpobj.filter(function (x) { return x.project_id == _this.projectId && x.savetab === 'QN'; });
                        if (idbAppFarmersQuickNotesOffline_1 && idbAppFarmersQuickNotesOffline_1.length > 0) {
                            _loop_5 = function (i) {
                                var farmerUpdated = void 0;
                                if (idbAppFarmersQuickNotesOffline_1[i].farmer_id > 0) {
                                    farmerUpdated = this_4.farmers.filter(function (item) { return item.id == idbAppFarmersQuickNotesOffline_1[i].farmer_id; });
                                }
                                else {
                                    var p_1 = idbAppFarmersQuickNotesOffline_1[i].tmp_id.slice(0, -2);
                                    farmerUpdated = this_4.farmers.filter(function (item) { return item.tmp_id === p_1 + "Tab0"; });
                                }
                                if (farmerUpdated && farmerUpdated.length > 0) {
                                    var index = this_4.farmers.indexOf(farmerUpdated[0]);
                                    if (index != null && index > -1) {
                                        this_4.farmers[index]['FarmerQuickNotes'] = [idbAppFarmersQuickNotesOffline_1[i]];
                                    }
                                }
                            };
                            this_4 = this;
                            for (i = 0; i < idbAppFarmersQuickNotesOffline_1.length; i++) {
                                _loop_5(i);
                            }
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.goToViewFarmerDQ = function (farmer) {
        var url2;
        if (this.snapshot) {
            if (farmer.id && farmer.id > 0) {
                url2 = '/questionnaire/view/' + farmer.project_id + '/' + farmer.id;
            }
            else if (farmer.tmp_id && !navigator.onLine) {
                if (farmer.tmp_id.indexOf('Tab00') > -1) {
                    url2 = '/questionnaire/view/' + farmer.project_id + '/' + farmer.tmp_id.substring(0, farmer.tmp_id.length - 1);
                }
                else {
                    url2 = '/questionnaire/view/' + farmer.project_id + '/' + farmer.tmp_id;
                }
            }
            else if (farmer.tmp_id && navigator.onLine) {
                this.commonService.showToast('warning', 'please_wait_data_sync', { preventDuplicates: true });
                return;
            }
            else {
                url2 = '/error';
            }
            this.router.navigate([url2]);
        }
        else {
            this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
        }
    };
    ProfilingComponent.prototype.loadFarmerDataIntoCache = function (projectId, gte, lt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(projectId && projectId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getQAnswersForProjectPaginated(projectId, gte, lt).toPromise()
                            ])
                                .then(function (result) {
                                console.log('result', result);
                            }).catch(function (e) { })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.setTooltipText = function () {
        if (this.farmers && this.selectedFarmers) {
            if (this.selectedFarmers.length == this.farmers.length) {
                // Deselect farmers.length farmers
                if (this.farmers.length == 1) {
                    this.tooltipText = this.selectDeselectLabels.deselect + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.deselect + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
            else {
                // Select farmers.length farmers
                if (this.farmers.length == 1) {
                    this.tooltipText = this.selectDeselectLabels.select + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.select + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
        }
    };
    //added by Kiran for famer consent
    ProfilingComponent.prototype.getFarmerConsents = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, _loop_6, this_5, _i, _a, _b, key, value;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.dataConsentService.getFarmerConsents(this.projectId).toPromise(),
                            this.dataConsentService.getConsentTemplate(this.projectId).toPromise()
                        ])];
                    case 1:
                        requests = _c.sent();
                        if (requests[0].msg == 'success') {
                            this.farmerConsent = requests[0].data;
                        }
                        if (requests[1].msg == 'success' && requests[1].data) {
                            this.consentTemplateId = requests[1].data.id;
                            this.consentTemplate = this.sanitizer.bypassSecurityTrustHtml(requests[1].data.template_content);
                        }
                        _loop_6 = function (key, value) {
                            var findFarmer = this_5.farmers.find(function (item) { return item.id == key; });
                            if (findFarmer) {
                                findFarmer.consentPending = !value["is_consent_valid"];
                                findFarmer.valid_upto = value["valid_upto"];
                            }
                        };
                        this_5 = this;
                        for (_i = 0, _a = Object.entries(this.farmerConsent); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], value = _b[1];
                            _loop_6(key, value);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.openConsentPopup = function (farmer, template) {
        if (this.consentTemplate) {
            this.consentFarmerName = farmer.name;
            this.farmerId = farmer.id;
            this.consentFarmerNo = farmer.official_id;
            this.farmer = farmer;
            this.addConsentPopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            this.commonService.showToast('warning', 'consent_template_not_available', { preventDuplicates: true });
        }
    };
    ProfilingComponent.prototype.closeConsentPopup = function () {
        if (!this.selectedConsentCode) {
            this.confirmConsentModal = false;
        }
        else if (this.addConsentPopup) {
            this.addConsentPopup.hide();
            this.confirmConsentModal = true;
        }
    };
    ProfilingComponent.prototype.confirmConsentModalFn = function (decision) {
        if (decision == "Yes") {
            this.addConsentPopup.hide();
            this.confirmConsentModal = true;
        }
        else {
            this.confirmConsentModal = true;
            this.selectedConsentCode = true;
        }
    };
    ProfilingComponent.prototype.getConsentData = function (data) {
        if (data.agree && data.value != "") {
            this.signValues = data;
            this.selectedConsentCode = false;
        }
        else {
            this.selectedConsentCode = true;
        }
    };
    ProfilingComponent.prototype.submitConsent = function () {
        var _this = this;
        if (navigator.onLine) {
            this.spinner.show();
            var data = {
                "consent_sign": this.signValues.value
            };
            this.dataConsentService.saveFarmerConsent(this.projectId, this.farmerId, data).subscribe(function (result) {
                _this.selectedConsentCode = true;
                _this.closeConsentPopup();
                _this.commonService.showToast('success', 'consent_added_succssfully', { preventDuplicates: true });
                var findFarmer = _this.farmers.find(function (item) { return item.id == _this.farmerId; });
                if (findFarmer) {
                    findFarmer.consentPending = false;
                    findFarmer.valid_upto = 365;
                }
                _this.spinner.hide();
            }, function (error) {
                _this.spinner.hide();
            });
        }
        else {
            // added for signature offline storage
            if (this.signValues.agree && this.signValues.value != "") {
                //add farmer in farmerstmp
                var finalObj_1 = {};
                var farmerObj_1 = this.farmer;
                Object.keys(farmerObj_1).forEach(function (key) {
                    if (typeof farmerObj_1[key] === 'object' && farmerObj_1[key] != null) {
                        if (farmerObj_1[key].length && farmerObj_1[key].length > 0) {
                            finalObj_1[key] = farmerObj_1[key];
                        }
                    }
                    else {
                        finalObj_1[key] = farmerObj_1[key];
                    }
                });
                var farmObj = new Array();
                finalObj_1['tmp_id'] = this.farmer.id > 0 ? this.farmer.farmer_code + "_Tab00" : "" + this.farmer.tmp_id;
                finalObj_1['savetab'] = this.farmer.id > 0 ? 'Tab00' : "Tab0";
                finalObj_1['randnum'] = this.farmer.id > 0 ? this.commonService.getRandomNum().toString().slice(0, 10) : this.farmer.randnum;
                finalObj_1['project_id'] = this.projectId;
                finalObj_1['upd_user_id'] = this.currentUser.id;
                finalObj_1['consentPending'] = false;
                finalObj_1['valid_upto'] = 730;
                farmObj[farmObj.length] = finalObj_1;
                idbApp.addStoreInfo(farmObj, 'farmerstmp');
                var farmSignatureObj = {};
                var err_msg = {
                    "farmer_consent_template_id": this.consentTemplateId,
                    "project_id": this.projectId,
                    "user_id": this.currentUser.id
                };
                if (this.farmerId == 0) {
                    farmSignatureObj['tmp_id'] = this.farmer.tmp_id;
                    farmSignatureObj['randnum'] = this.farmer.randnum;
                    farmSignatureObj['farmerImageData'] = this.signValues.value;
                    farmSignatureObj['savetab'] = 'Tab0';
                    farmSignatureObj['cr_date'] = Date.now();
                    farmSignatureObj['farmer_id'] = 0;
                    farmSignatureObj['photo_type'] = "signature";
                }
                else {
                    farmSignatureObj['tmp_id'] = this.farmer.farmer_code + "_Tab00";
                    farmSignatureObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
                    farmSignatureObj['farmerImageData'] = this.signValues.value;
                    farmSignatureObj['savetab'] = 'Tab00';
                    farmSignatureObj['cr_date'] = Date.now();
                    farmSignatureObj['farmer_id'] = this.farmerId;
                    farmSignatureObj['photo_type'] = "signature";
                }
                farmSignatureObj['error_msg'] = err_msg;
                idbAppPhoto.addStoreInfo([farmSignatureObj], 'farmerSignaturetmp');
            }
            this.selectedConsentCode = true;
            this.closeConsentPopup();
            this.commonService.showToast('info', 'offline_sync_later', {});
            var findFarmer = this.farmers.find(function (item) { return item.id == _this.farmerId; });
            if (findFarmer) {
                findFarmer.consentPending = false;
                findFarmer.valid_upto = 365;
            }
        }
    };
    // remove farmer btn click function
    ProfilingComponent.prototype.removeFarmer = function (template, farmer) {
        if (this.snapshot) {
            this.removingFarmer = farmer;
            this.removeFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
        }
    };
    ProfilingComponent.prototype.confirmModalDeleteFarmer = function (what) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmertmpobj, farmerInIndexDB, _i, farmertmpobj_1, obj, fid, answerStats, val, _a, val_1, i, uniqueId, x, findFarmer;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(what === 'yes')) return [3 /*break*/, 5];
                        if (!navigator.onLine) return [3 /*break*/, 1];
                        this.confirmedFarmerNotAvailable(this.removingFarmer);
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 2:
                        farmertmpobj = _b.sent();
                        farmerInIndexDB = void 0;
                        if (farmertmpobj && farmertmpobj.length > 0) {
                            for (_i = 0, farmertmpobj_1 = farmertmpobj; _i < farmertmpobj_1.length; _i++) {
                                obj = farmertmpobj_1[_i];
                                fid = this.removingFarmer.tmp_id ? this.removingFarmer.tmp_id : this.removingFarmer.id;
                                if (obj.tmp_id == fid) {
                                    farmerInIndexDB = true;
                                    answerStats = obj.stats;
                                    val = JSON.parse(obj.values);
                                    //val = val.slice(1, -1);
                                    if (val && val.length > 0) {
                                        for (_a = 0, val_1 = val; _a < val_1.length; _a++) {
                                            i = val_1[_a];
                                            i['flex2'] = 'NA';
                                        }
                                    }
                                    idbApp.removeDataFromStore('dq_profiling', obj.unique_id);
                                    uniqueId = uuidv4();
                                    x = { unique_id: uniqueId, tmp_id: fid,
                                        updatedAt: new Date().getTime(), values: JSON.stringify(val),
                                        stats: answerStats, campaignId: undefined };
                                    idbApp.addStoreInfo([x], 'dq_profiling');
                                    findFarmer = void 0;
                                    if (this.removingFarmer.tmp_id) {
                                        findFarmer = this.farmers.find(function (item) { return item.tmp_id == _this.removingFarmer.tmp_id; });
                                    }
                                    else {
                                        findFarmer = this.farmers.find(function (item) { return item.id == _this.removingFarmer.id; });
                                    }
                                    if (findFarmer) {
                                        findFarmer["notAvailable"] = true;
                                    }
                                    this.removingFarmer = undefined;
                                    if (this.removeFarmerModal) {
                                        this.removeFarmerModal.hide();
                                    }
                                    this.toastrService.success(this.farmerMarkedUnavailableSuccessfully);
                                }
                            }
                        }
                        if (!!farmerInIndexDB) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.addNewEntrytoNQIDB("NA")];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.removingFarmer = undefined;
                        if (this.removeFarmerModal) {
                            this.removeFarmerModal.hide();
                        }
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.addNewEntrytoNQIDB = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, request2, result, request3, qids, qAnswersForFarmer, paginationLimitsArray, paginationLimits, surveyReq, activeSurveyId, activeSnapshot, snapshotSurveys, getQIds;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise()
                        ])];
                    case 1:
                        request = _a.sent();
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.removingFarmer.id).toPromise(),
                            ])];
                    case 2:
                        request2 = _a.sent();
                        // If offline, get answers from getQAnswersForFarmer (for this.farmerId) - this is coming from request2
                        result = { code: 'success' };
                        if (request2 && request2[0] && request2[0]['message']) {
                            // If request2[0] has a message attribute, getQAnswersForFarmer has been called for this farmer before
                            qAnswersForFarmer = request2[0];
                        }
                        paginationLimitsArray = request[0].message;
                        paginationLimits = this.getPaginationLimits(paginationLimitsArray, this.removingFarmer.id);
                        return [4 /*yield*/, Promise.all([
                                this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                            ])];
                    case 3:
                        surveyReq = _a.sent();
                        if (surveyReq[0]['code'] === 'success' && surveyReq[0]['message'].length > 0) {
                            activeSnapshot = void 0;
                            snapshotSurveys = surveyReq[0]['message'].filter(function (item) { return item.survey_type == 'snapshot'; });
                            if (snapshotSurveys && snapshotSurveys.length > 0) {
                                activeSnapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
                                if (activeSnapshot) {
                                    activeSurveyId = activeSnapshot.id;
                                    this.currentSurveyId = activeSnapshot.id;
                                }
                            }
                        }
                        if (!activeSurveyId) {
                            this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
                            this.removingFarmer = undefined;
                            if (this.removeFarmerModal) {
                                this.removeFarmerModal.hide();
                            }
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getSurveyQAssetIdsDashboard(activeSurveyId).toPromise()
                            ])];
                    case 4:
                        getQIds = _a.sent();
                        if (!(getQIds[0]['code'] === 'success')) return [3 /*break*/, 6];
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getQAnswersForProjectPaginatedDashboard(this.projectId, paginationLimits[0], paginationLimits[1], getQIds[0]['message']).toPromise()
                            ])];
                    case 5:
                        request3 = _a.sent();
                        qids = getQIds[0]['message'];
                        _a.label = 6;
                    case 6:
                        // If getQAnswersForFarmer has not been called for this farmer, get the answer values from getQAnswersForProject by filtering on farmerId
                        result['message'] = request3[0]['msg'] !== 'blank' ? request3[0]['message'].filter(function (item) { return item.farmer_id === _this.removingFarmer.id; }) : [];
                        // If getQAnswersForFarmer has been called for this farmer before, compare the timestamp of the two API calls (farmer specific & project-wide data fetch)
                        // Set the results array as the latest API data fetch
                        if (qAnswersForFarmer) {
                            // getQAnswersForFarmer fetch      getQAnswersForProject fetch
                            if (request3) {
                                if (qAnswersForFarmer['ts'] > request3[0]['ts']) {
                                    // Set as farmer-specific API fetch
                                    result['message'] = qAnswersForFarmer['message'];
                                }
                                else {
                                    // Set as project-wide fetch, filtered by farmerId
                                    result['message'] = request3[0]['message'].filter(function (item) { return item.farmer_id === _this.removingFarmer.id; });
                                }
                            }
                            else {
                                result['message'] = qAnswersForFarmer['message'];
                            }
                        }
                        // Set the qAnswers array
                        this.setQAnswersAndInitialize(result, qids, status);
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
       * Set the qAnswers array using the result array - answers returned by the API, and the indexedDB entries
       * @param result = answers array
       */
    ProfilingComponent.prototype.setQAnswersAndInitialize = function (result, qids, status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var indexedDBEntries, farmertmpobj, qAnswersForThisFarmerId, i, i, _loop_7, this_6, i, qAnswersToSubmit;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.qAnswers = [];
                        indexedDBEntries = [];
                        return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 1:
                        farmertmpobj = _a.sent();
                        if (farmertmpobj && farmertmpobj.length > 0) {
                            qAnswersForThisFarmerId = [];
                            qAnswersForThisFarmerId = farmertmpobj.filter(function (item) {
                                return (String(item.tmp_id) === String(_this.removingFarmer.id) && item.campaignId == undefined);
                            });
                            if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
                                for (i = 0; i < qAnswersForThisFarmerId.length; i++) {
                                    indexedDBEntries = indexedDBEntries.concat(JSON.parse(qAnswersForThisFarmerId[i].values));
                                }
                            }
                        }
                        // Cached values
                        if (result.code === 'success' && result.message.length > 0) {
                            for (i = 0; i < result.message.length; i++) {
                                this.qAnswers.push({
                                    id: result.message[i].id.toString(),
                                    key: result.message[i].questionnaire_asset_id.toString(),
                                    sak_of_modified: result.message[i].sak_of_modified,
                                    secondary_asset_key: result.message[i].secondary_asset_key,
                                    value: result.message[i].answer.toString(),
                                    touched: true,
                                    flex2: status
                                });
                            }
                        }
                        // IndexedDB values
                        if (indexedDBEntries.length > 0) {
                            _loop_7 = function (i) {
                                // If the indexedDB entry has an id, find the same id from qAnswers and update the value, mark as touched
                                if (indexedDBEntries[i].id) {
                                    var x = this_6.qAnswers.find(function (item) { return item.id == indexedDBEntries[i].id; });
                                    if (x) {
                                        var xIndex = this_6.qAnswers.indexOf(x);
                                        if (this_6.validIndex(xIndex)) {
                                            this_6.qAnswers[xIndex]['value'] = indexedDBEntries[i]['value'];
                                            this_6.qAnswers[xIndex]['touched'] = true;
                                            this_6.qAnswers[xIndex]['flex2'] = status;
                                            if (indexedDBEntries[i]['checked']) {
                                                this_6.qAnswers[xIndex]['checked'] = indexedDBEntries[i]['checked'];
                                            }
                                            if (indexedDBEntries[i]['unchecked']) {
                                                this_6.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['unchecked'];
                                            }
                                            if (indexedDBEntries[i]['deleted']) {
                                                this_6.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['deleted'];
                                            }
                                        }
                                    }
                                }
                                else {
                                    // Push into qAnswers
                                    var answerToInsert = {
                                        key: indexedDBEntries[i].key.toString(),
                                        sak_of_modified: indexedDBEntries[i].sak_of_modified,
                                        secondary_asset_key: indexedDBEntries[i].secondary_asset_key,
                                        value: indexedDBEntries[i].value.toString(),
                                        touched: true,
                                        checked: indexedDBEntries[i].checked,
                                        flex2: status
                                    };
                                    if (indexedDBEntries[i]['checked']) {
                                        answerToInsert['checked'] = indexedDBEntries[i]['checked'];
                                    }
                                    if (indexedDBEntries[i]['unchecked']) {
                                        answerToInsert['unchecked'] = indexedDBEntries[i]['unchecked'];
                                    }
                                    if (indexedDBEntries[i]['deleted']) {
                                        answerToInsert['unchecked'] = indexedDBEntries[i]['deleted'];
                                    }
                                    this_6.qAnswers.push(answerToInsert);
                                }
                            };
                            this_6 = this;
                            for (i = 0; i < indexedDBEntries.length; i++) {
                                _loop_7(i);
                            }
                            //this.askUserToConfirmExit = false;
                        }
                        if (this.qAnswers.length == 0) {
                            this.qAnswers.push({
                                key: qids[0],
                                value: '',
                                touched: true,
                                flex2: status
                            });
                        }
                        qAnswersToSubmit = this.qAnswers.filter(function (item) { return item.touched; });
                        this.addEntyToIndexDB(qAnswersToSubmit, status);
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.addEntyToIndexDB = function (qAnswersToSubmit, status) {
        var _this = this;
        var y1 = qAnswersToSubmit.filter(function (a) { return a.checked || a.unchecked; });
        var y2 = qAnswersToSubmit.filter(function (a) { return (!a.secondary_asset_key && !a.checked && !a.unchecked && a.value != null && a.value != '' && !a.deleted)
            || (a.secondary_asset_key && !a.checked && !a.unchecked && a.value != null && !a.deleted); });
        var na = qAnswersToSubmit.filter(function (a) { return a.value == '' && (a.flex2 == 'NA' || a.flex2 == 'A'); });
        var y11 = [];
        var y21 = [];
        if (y1 && y1.length > 0)
            y11 = y1;
        if (y2 && y2.length > 0)
            y21 = y2;
        var y = y11.concat(y21);
        y = y.concat(na);
        var statsForFarmer = this.surveyStats.find(function (item) { return item.farmer_id == _this.removingFarmer.id; });
        var stats;
        if (!statsForFarmer) {
            stats = [this.currentSurveyId, 0, 0];
        }
        else {
            stats = [this.currentSurveyId, parseInt(statsForFarmer.total_questions), parseInt(statsForFarmer.answered_questions)];
        }
        if (y && y.length > 0) {
            var uniqueId = uuidv4();
            var x = { unique_id: uniqueId, tmp_id: this.removingFarmer.tmp_id ? this.removingFarmer.tmp_id : this.removingFarmer.id,
                updatedAt: new Date().getTime(), values: JSON.stringify(y),
                stats: JSON.stringify(stats), campaignId: undefined };
            idbApp.addStoreInfo([x], 'dq_profiling');
        }
        var findFarmer;
        if (this.removingFarmer.tmp_id) {
            findFarmer = this.farmers.find(function (item) { return item.tmp_id == _this.removingFarmer.tmp_id; });
        }
        else {
            findFarmer = this.farmers.find(function (item) { return item.id == _this.removingFarmer.id; });
        }
        if (findFarmer) {
            findFarmer["notAvailable"] = status == "NA" ? true : false;
        }
        this.removingFarmer = undefined;
        if (this.removeFarmerModal) {
            this.removeFarmerModal.hide();
        }
        var toastMessage = status == "NA" ? this.farmerMarkedUnavailableSuccessfully : this.farmerMarkedAvailableSuccessfully;
        //this.commonService.showToast('success', toastMessage, {});
        this.toastrService.success(toastMessage);
    };
    /**
       * Checks whether the given index is valid (not null, > -1)
       * @param index
       */
    ProfilingComponent.prototype.validIndex = function (index) {
        if (index != null && index > -1) {
            return true;
        }
        return false;
    };
    /**
     * Find which limits range the given farmerId falls in
     * E.g. if limits = [130, 150, 170] and farmerId = 165
     * limitsToReturn = [150, 170] would be returned
     * @param limits
     * @param farmerId
     */
    ProfilingComponent.prototype.getPaginationLimits = function (limits, farmerId) {
        var limitsToReturn = [];
        for (var i = 0; i < limits.length; i++) {
            if (limits[i] <= farmerId && limits[i + 1] && limits[i + 1] > farmerId) {
                limitsToReturn[0] = limits[i];
                limitsToReturn[1] = limits[i + 1];
                return limitsToReturn;
            }
            else if (!limits[i + 1]) {
                limitsToReturn[0] = limits[i];
                limitsToReturn[1] = undefined;
                return limitsToReturn;
            }
        }
    };
    // add farmer btn click function
    ProfilingComponent.prototype.addFarmer = function (template, farmer) {
        if (this.snapshot) {
            this.removingFarmer = farmer;
            this.removeFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
        }
    };
    ProfilingComponent.prototype.confirmModalAddFarmer = function (what) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmertmpobj, farmerInIndexDB, _i, farmertmpobj_2, obj, fid, answerStats, val, _a, val_2, i, uniqueId, x, findFarmer;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(what === 'yes')) return [3 /*break*/, 5];
                        if (!navigator.onLine) return [3 /*break*/, 1];
                        this.confirmedFarmerAvailable(this.removingFarmer);
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 2:
                        farmertmpobj = _b.sent();
                        farmerInIndexDB = void 0;
                        if (farmertmpobj && farmertmpobj.length > 0) {
                            for (_i = 0, farmertmpobj_2 = farmertmpobj; _i < farmertmpobj_2.length; _i++) {
                                obj = farmertmpobj_2[_i];
                                fid = this.removingFarmer.tmp_id ? this.removingFarmer.tmp_id : this.removingFarmer.id;
                                console.log('fid', fid);
                                if (obj.tmp_id == fid) {
                                    farmerInIndexDB = true;
                                    answerStats = obj.stats;
                                    val = JSON.parse(obj.values);
                                    if (val && val.length > 0) {
                                        for (_a = 0, val_2 = val; _a < val_2.length; _a++) {
                                            i = val_2[_a];
                                            i['flex2'] = 'A';
                                        }
                                        idbApp.removeDataFromStore('dq_profiling', obj.unique_id);
                                        uniqueId = uuidv4();
                                        x = { unique_id: uniqueId, tmp_id: fid,
                                            updatedAt: new Date().getTime(), values: JSON.stringify(val),
                                            stats: answerStats, campaignId: undefined };
                                        idbApp.addStoreInfo([x], 'dq_profiling');
                                        findFarmer = void 0;
                                        if (this.removingFarmer.tmp_id) {
                                            findFarmer = this.farmers.find(function (item) { return item.tmp_id == _this.removingFarmer.tmp_id; });
                                        }
                                        else {
                                            findFarmer = this.farmers.find(function (item) { return item.id == _this.removingFarmer.id; });
                                        }
                                        if (findFarmer) {
                                            findFarmer["notAvailable"] = false;
                                        }
                                        this.removingFarmer = undefined;
                                        if (this.removeFarmerModal) {
                                            this.removeFarmerModal.hide();
                                        }
                                        this.toastrService.success(this.farmerMarkedAvailableSuccessfully);
                                    }
                                }
                            }
                        }
                        if (!!farmerInIndexDB) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.addNewEntrytoNQIDB("A")];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.removingFarmer = undefined;
                        if (this.removeFarmerModal) {
                            this.removeFarmerModal.hide();
                        }
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.confirmedFarmerNotAvailable = function (farmer) {
        var _this = this;
        if (this.removeFarmerModal) {
            this.removeFarmerModal.hide();
        }
        this.spinner.show();
        this.farmerService.farmerAvailableNotAvailable(farmer.id, false, this.projectId)
            .subscribe(function (result) {
            if (result.msg === 'success') {
                _this.spinner.hide();
                //this.commonService.showToast('success', 'farmerMarkedUnavailableSuccessfully', {});
                _this.toastrService.success(_this.farmerMarkedUnavailableSuccessfully);
                var findFarmer = _this.farmers.find(function (item) { return item.id == _this.removingFarmer.id; });
                if (findFarmer) {
                    findFarmer["notAvailable"] = true;
                }
            }
            else {
                _this.commonService.showToast('error', 'error_farmer_deleted', {});
            }
            _this.removingFarmer = undefined;
        });
    };
    ProfilingComponent.prototype.confirmedFarmerAvailable = function (farmer) {
        var _this = this;
        if (this.removeFarmerModal) {
            this.removeFarmerModal.hide();
        }
        this.spinner.show();
        this.farmerService.farmerAvailableNotAvailable(farmer.id, true, this.projectId)
            .subscribe(function (result) {
            if (result.msg === 'success') {
                _this.spinner.hide();
                _this.toastrService.success(_this.farmerMarkedAvailableSuccessfully);
                var findFarmer = _this.farmers.find(function (item) { return item.id == _this.removingFarmer.id; });
                if (findFarmer) {
                    findFarmer["notAvailable"] = undefined;
                }
            }
            else {
                _this.commonService.showToast('error', 'error_farmer_deleted', {});
            }
            _this.removingFarmer = undefined;
        });
    };
    //for making farmer unavailable if 
    ProfilingComponent.prototype.setFarmerNA = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _loop_8, this_7, _i, _a, fid, farmertmpobj, farmerNAarr, _b, farmertmpobj_3, obj, val, _c, val_3, i, _loop_9, this_8, _d, farmerNAarr_1, farmer;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _loop_8 = function (fid) {
                            var findFarmer = this_7.farmers.find(function (item) { return item.id == fid; });
                            if (findFarmer) {
                                findFarmer.notAvailable = true;
                            }
                        };
                        this_7 = this;
                        for (_i = 0, _a = this.farmerNA; _i < _a.length; _i++) {
                            fid = _a[_i];
                            _loop_8(fid);
                        }
                        if (!!navigator.onLine) return [3 /*break*/, 2];
                        return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 1:
                        farmertmpobj = _e.sent();
                        farmerNAarr = [];
                        if (farmertmpobj && farmertmpobj.length > 0) {
                            for (_b = 0, farmertmpobj_3 = farmertmpobj; _b < farmertmpobj_3.length; _b++) {
                                obj = farmertmpobj_3[_b];
                                val = JSON.parse(obj.values);
                                if (val && val.length > 0) {
                                    for (_c = 0, val_3 = val; _c < val_3.length; _c++) {
                                        i = val_3[_c];
                                        if (i.flex2 == 'NA' || i.flex2 == 'A') {
                                            farmerNAarr.push({
                                                farmer_id: obj.tmp_id,
                                                availability: i.flex2
                                            });
                                        }
                                    }
                                }
                            }
                        }
                        if (farmerNAarr.length > 0) {
                            _loop_9 = function (farmer) {
                                var findFarmer = this_8.farmers.find(function (item) { return item.id == farmer.farmer_id; });
                                if (findFarmer) {
                                    findFarmer.notAvailable = farmer.availability == "NA" ? true : false;
                                }
                                else {
                                    var findTmpFarmer = this_8.farmers.find(function (item) { return item.tmp_id == farmer.farmer_id; });
                                    if (findTmpFarmer) {
                                        findTmpFarmer.notAvailable = farmer.availability == "NA" ? true : false;
                                    }
                                }
                            };
                            this_8 = this;
                            for (_d = 0, farmerNAarr_1 = farmerNAarr; _d < farmerNAarr_1.length; _d++) {
                                farmer = farmerNAarr_1[_d];
                                _loop_9(farmer);
                            }
                        }
                        _e.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ProfilingComponent.prototype.openSurveyManual = function () {
        var docUrl = environment.apiBaseUrl + "/" + this.locale.substr(0, 2) + "/assets/uploads/surveyManual/" + this.snapshot.flex4;
        var docExtension = docUrl.split(/[#?]/)[0].split('.').pop().trim();
        this.http
            .get(docUrl, {
            responseType: 'blob',
        })
            .subscribe(function (response) {
            var blob = new Blob([response], { type: 'application/octet-stream' });
            var downloadUrl = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadUrl;
            link.style.display = 'none';
            link.download = "Survey_manual." + docExtension;
            link.click();
            window.URL.revokeObjectURL(downloadUrl);
            link.remove();
        });
    };
    //filter farmers array based on search text
    ProfilingComponent.prototype.filterFarmers = function () {
        var _this = this;
        this.farmers = this.filteredfarmers;
        if (this.searchText.length > 0) {
            var filterArr = this.farmers.filter(function (item) {
                if ((item.farmer_code && item.farmer_code.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1) ||
                    (item.name && item.name.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1) ||
                    (item.lastProfiledAt && item.lastProfiledAt.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1) ||
                    (item.official_id && item.official_id.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1)) {
                    return item;
                }
            });
            this.farmers = filterArr;
        }
    };
    return ProfilingComponent;
}());
export { ProfilingComponent };
