<app-header></app-header>
<div class="project-header clearfix">

    <nav aria-label="breadcrumb" class="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-icon">
                <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{typeOfAdministration}}</li>
        </ol>
    </nav>
</div>
<div class="page-wrapper">
    <div class="content-wrapper background">

        <div class="myprojects">
            <h3 class="project-head">{{nav?.menu_name}}</h3>

            <div class="clearfix"></div>

            <div class="list-project" id="myList">
                <ul class="mdm-box clearfix">
                    <li class="col-lg-2 col-md-2 col-sm-6 col-xs-12" *ngFor="let mdminfo of mdmData" (click) ="navaigationMenu(mdminfo?.MDM.master_code)">
                        <span class="box box_mh" >
                            <img [src]="mdminfo?.MDM.photo_path" alt="photo">
                            <h3 style="max-height: 78px;">{{mdminfo.MDM.name}}</h3>
                            <p>{{mdminfo.MDM.description}}</p>
                        </span>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</div>

