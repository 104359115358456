<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToDashboards()" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)"><span i18n-title="@@projectReporting" title="Project Reporting">...</span></li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToDashboards()"><span title="Dashboards">...</span></li>
      <li class="breadcrumb-item active" aria-current="page"><span>Community Livelihood</span></li>
    </ol>
  </nav>
</div>
<div class="content-wrap content-wrapper background margin-btm-20" >
  <div class="form-design form-box">
    <div class="row">
      <div class="col-md-12 col-lg-9">
        <label class="group-title inline-block mrbtm-two">
          <span>Community Livelihood</span>
        </label>
      </div>
    </div>
    <div class="form-design form-box form-box-gray" >
      <div class="row mt-3">
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <!-- <div class="pb-1 border-bottom value-box-farmer">
              <strong>Total Farmers</strong>
            </div>
            <div class="value-box text-center">
              1,010
            </div> -->
            <div class="row no-gutters">
              <div class="col-md-12 col-lg-4">
                <img  alt="Farmers" class="" src="assets/online_images/farmer3.webp" />
              </div>
              <div  class="col-md-12 col-lg-8 pl-3 pt-1">
                <ng-container  *ngFor="let report of predefinedReports" >
                <div class="font-big pt-2 pb-2 height100 flex-center content-center" *ngIf="report.reportId == 50">
                  <span *ngIf="report?.series[0]?.total_farmers != null">{{report?.series[0]?.total_farmers | number:'1.0':'en-US'}}</span>
                  <span *ngIf="report?.series[0]?.total_farmers == null">0</span>
                </div>
                <!-- <div class="font-big pt-2 pb-2" *ngIf="predefinedReports[0]?.series[0]?.total_farmers == null">0</div> -->
                </ng-container>
              </div>
            </div>
            <div class="mt-3 mb-2 align-center font-20 flex-center content-center semi-bold">Total Farmers</div>
            <!-- <div class="question-images-small">
              <img src="assets/images/distance.webp" alt="Hospital" />
            </div>
            <div class="question-box-small">
                <div class="blue-big pt-1 pb-1">1,010</div>
                <div class="">Total Farmers</div>
            </div> -->
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row no-gutters">
              <div class="col-md-12 col-lg-4">
                <img  alt="Farmers" class="" src="assets/online_images/family-count.webp" />
              </div>
              <div  class="col-md-12 col-lg-8 pl-3 pt-1">
                <ng-container  *ngFor="let report of predefinedReports" >
                  <div class="font-big pt-2 pb-2 height100 flex-center content-center" *ngIf="report.reportId == 97">
                    <span *ngIf="report?.series[0]?.Total_family_members != null">{{report?.series[0]?.Total_family_members | number:'1.0':'en-US'}}</span>
                    <span *ngIf="report?.series[0]?.Total_family_members == null">0</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="mt-3 mb-2 align-center font-20 flex-center content-center semi-bold">Family Count</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box">          
            <div class="row no-gutters">
              <div class="col-md-12 col-lg-4">
                  <img  alt="Farmers" class="" src="assets/images/literacy.webp" />
              </div>
              <div  class="col-md-12 col-lg-8 pl-3 pt-1">
                <ng-container *ngFor="let report of predefinedReports" >
                  <div class="pt-2 pb-2 height100 flex-center content-center" *ngIf="report.reportId == 98">
                    <span class="font-big" *ngIf="report?.series[0]?.Literate != null">{{report?.series[0]?.Literate}}</span>
                    <span class="font-big" *ngIf="report?.series[0]?.Literate == null">0</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="mt-3 mb-2 align-center font-20 flex-center content-center semi-bold">Literacy Rate</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box height100">
            <div class="row no-gutters">
              <!-- <div class="col-md-12 col-lg-3">
                  <img  alt="Farmers" class="image-rounded" src="assets/images/male-farmer.svg" />
              </div>
              <div  class="col-md-12 col-lg-3 pl-3 pt-1">
                <ng-container  *ngFor="let report of predefinedReports" >
                  <div class="font-big pt-2 pb-2 height100 flex-center" *ngIf="report.reportId == 46">
                    <span *ngIf="report?.series[0]?.Male != null">{{report?.series[0]?.Male}}%</span>
                    <span *ngIf="report?.series[0]?.Male == null">0%</span>
                  </div>
                </ng-container>
              </div>
              <div class="col-md-12 col-lg-3">
                  <img  alt="Farmers" class="image-rounded" src="assets/images/female-farmer.svg" />
              </div>
              <div  class="col-md-12 col-lg-3 pl-3 pt-1">
                <ng-container  *ngFor="let report of predefinedReports" >
                  <div class="font-big pt-2 pb-2 height100 flex-center" *ngIf="report.reportId == 46">
                    <span *ngIf="report?.series[0]?.Female != null">{{report?.series[0]?.Female}}%</span>
                    <span *ngIf="report?.series[0]?.Female == null">0%</span>
                  </div>
                </ng-container>
              </div> -->
              <div class="col-md-12 col-lg-6">
                <ng-container *ngFor="let report of predefinedReports" >
                  <div class="male-farmer-small" *ngIf="report.reportId == 203">
                    <span class="font-big" *ngIf="report?.series[0]?.Male != null">{{report?.series[0]?.Male}}</span>
                    <span class="font-big" *ngIf="report?.series[0]?.Male == null">0</span>
                    <span class="small">%</span>
                  </div>
                </ng-container>
              </div>
              <div class="col-md-12 col-lg-6 pdleft-two">
                <ng-container *ngFor="let report of predefinedReports" >
                  <div class="female-farmer-small" *ngIf="report.reportId == 203">
                    <span class="font-big" *ngIf="report?.series[0]?.Female != null">{{report?.series[0]?.Female }}</span>
                    <span class="font-big" *ngIf="report?.series[0]?.Female == null">0</span>
                    <span class="small">%</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="mb-2 align-center font-20 flex-center content-center semi-bold" style="margin-top:20px">Farming as Profession</div>
          </div>
        </div>
      </div>    
      <div class="row mt-4">
        <div class="col-md-12 col-lg-3">
          <div class="white-box height100">
              <div class="font-20 semi-bold">Electricity Source:</div>
              <div class="gf-kpi-box-1 flex-center">
                  <!-- <ng-container *ngFor="let report of predefinedReports" > -->
                      <!-- *ngIf="report.reportId == 141" -->
                    <div class="padding0-20" >
                      <div class="padding5-10">
                        <span><img src="assets/online_images/no-electricity.webp" alt="no-electricity" title="no-electricity" class="icon-20" /></span>
                        <span class="ml-2">No Electricity</span>
                        <ng-container *ngFor="let report of predefinedReports" >
                          <ng-container *ngIf="report.reportId == 141"> 
                              <ng-container *ngFor="let item of report?.series[0] | keyvalue">
                                <span *ngIf="item.key == 'No Electricity'" class="blue-medium ml-2">- {{item.value}}%</span>
                              </ng-container>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="padding5-10">
                        <span><img src="assets/online_images/tower.webp" alt="public" title="public" class="icon-20" /></span>
                        <span class="ml-2">Public</span>
                        <ng-container *ngFor="let report of predefinedReports" >
                          <ng-container *ngIf="report.reportId == 141"> 
                              <ng-container *ngFor="let item of report?.series[0] | keyvalue">
                                <span *ngIf="item.key == 'Public'" class="blue-medium ml-2">- {{item.value}}%</span>
                              </ng-container>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="padding5-10">
                        <span><img src="assets/online_images/generator.webp" alt="generator" title="generator" class="icon-20" /></span>
                        <span class="ml-2">Generator</span>
                        <ng-container *ngFor="let report of predefinedReports" >
                          <ng-container *ngIf="report.reportId == 141"> 
                              <ng-container *ngFor="let item of report?.series[0] | keyvalue">
                                <span *ngIf="item.key == 'Generator'" class="blue-medium ml-2">- {{item.value}}%</span>
                              </ng-container>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="padding5-10">
                        <span><img src="assets/online_images/solar-panel.webp" alt="solar-panel" title="solar-panel" class="icon-20" /></span>
                        <span class="ml-2">Solar</span>
                        <ng-container *ngFor="let report of predefinedReports" >
                          <ng-container *ngIf="report.reportId == 141"> 
                              <ng-container *ngFor="let item of report?.series[0] | keyvalue">
                                <span *ngIf="item.key == 'Solar'" class="blue-medium ml-2">- {{item.value}}%</span>
                              </ng-container>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="padding5-10">
                        <span><img src="assets/online_images/windmill.webp" alt="windmill" title="windmill" class="icon-20" /></span>
                        <span class="ml-2">Wind</span>
                        <ng-container *ngFor="let report of predefinedReports" >
                          <ng-container *ngIf="report.reportId == 141"> 
                              <ng-container *ngFor="let item of report?.series[0] | keyvalue">
                                <span *ngIf="item.key == 'Wind'" class="blue-medium ml-2">- {{item.value}}%</span>
                              </ng-container>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="padding5-10">
                        <span><img src="assets/online_images/other.webp" alt="other" title="other" class="icon-20" /></span>
                        <span class="ml-2">Other</span>
                        <ng-container *ngFor="let report of predefinedReports" >
                          <ng-container *ngIf="report.reportId == 141"> 
                              <ng-container *ngFor="let item of report?.series[0] | keyvalue">
                                <span *ngIf="item.key == 'Other'" class="blue-medium ml-2">- {{item.value}}%</span>
                              </ng-container>
                          </ng-container>
                        </ng-container>
                      </div>

                      <!-- <div class="padding5-10" *ngFor="let report of report?.series[0] | keyvalue">
                        <span *ngIf="report.key == 'No Electricity'"><img src="assets/images/no-electricity.webp" alt="No Electricity" title="electricity" class="icon-20" /></span>
                        <span *ngIf="report.key == 'Public'"><img src="assets/images/towery.webp" alt="Public" title="Public" class="icon-20" /></span>
                        <span *ngIf="report.key == 'Generator'"><img src="assets/images/generator.webp" alt="Generator" title="Generator" class="icon-20" /></span>
                        <span *ngIf="report.key == 'Solar'"><img src="assets/images/solar-panel.webp" alt="Solar" title="Solar" class="icon-20" /></span>
                        <span *ngIf="report.key == 'Wind'"><img src="assets/images/windmill.webp" alt="windmill" title="windmill" class="icon-20" /></span>
                        <span *ngIf="report.key == 'Other'"><img src="assets/images/other.webp" alt="other" title="other" class="icon-20" /></span>
                        <span class="ml-2">{{report.key}}</span>
                        <span class="blue-medium ml-2">- {{report.value}}%</span>
                      </div>
                    </div> -->
                  <!-- </ng-container> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-9"> 
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="white-box height100 flex-center">
                <div class="row no-gutters height100">
                  <div class="col-md-12 col-lg-2">
                    <div class="height100 flex-center">
                      <img src="assets/online_images/children.webp" class="" alt="children" />
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-10">
                    <ng-container  *ngFor="let report of predefinedReports" >
                      <div class="" *ngIf="report.reportId == 202">
                        <div class="font-20 semi-bold padding0-20">Children:</div>
                        <div class="padding5-20 mt-1"><span>There are </span><span class="blue-medium" *ngIf="report?.series[0]?.value != null">{{report?.series[0]?.value | number:'1.0':'en-US'}}</span><span class="blue-medium" *ngIf="report.series[0]?.value == null">0</span><span> Children under the age of 15 years</span></div>
                        <div class="padding5-20"><span class="blue-medium" *ngIf="report?.series[1]?.value != null && report?.series[0]?.value != null">{{((report?.series[1]?.value /report?.series[0]?.value) * 100) | number:'1.0-0' }}% </span> <span class="blue-medium" *ngIf="report?.series[1]?.value == null || report?.series[0]?.value == null">0% </span> <span *ngIf="report?.series[1]?.value != null">({{report?.series[1]?.value | number:'1.0':'en-US'}} children)</span><span *ngIf="report?.series[1]?.value == null">(0 children)</span><span> of them are attending school.</span></div>
                        <div class="padding5-20"><span class="blue-medium" *ngIf="report?.series[2]?.value != null && report?.series[0]?.value != null">{{((report?.series[2]?.value /report?.series[0]?.value) * 100) | number:'1.0-0' }}% </span><span class="blue-medium" *ngIf="report?.series[2]?.value == null || report?.series[0]?.value == null">0% </span>&nbsp;<span *ngIf="report?.series[2]?.value != null">({{report?.series[2]?.value | number:'1.0':'en-US'}} children)</span><span *ngIf="report?.series[2]?.value == null">(0 children)</span><span> of them help their families in the farms outside of school hours.</span></div>                
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <div class="white-box height100">
                  <div class="row flex-center pt-4 no-gutters">
                    <div class="col-md-12 col-lg-5 text-center">
                      <img src="assets/online_images/time-to-school.webp" alt="time-to-school" class="images-rounded" style="height:55px" />
                    </div>
                    <ng-container *ngFor="let report of predefinedReports" >
                      <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 193">
                        <span class="font-24" *ngIf="report?.series[0]?.key != null">{{report?.series[0]?.key == 'Less than 30 minutes' ? '	&lt; 30 minutes' : report?.series[0]?.key == 'More than 1 hour' ? '&gt; 1 hour' : report?.series[0]?.key}}</span>
                        <span class="font-24" *ngIf="report?.series[0]?.key == null">0</span>
                      </div>
                    </ng-container>
                  </div>
                  <div class="mt-3 mb-2 text-center font-20 semi-bold height-48 flex-center content-center">Average Distance to School</div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12 col-lg-8">
              <div class="white-box height100 flex-center">
                <div class="row no-gutters height100">
                  <div class="col-md-12 col-lg-2">
                    <div class="height100 flex-center">
                      <img src="assets/online_images/hospital.webp" class="" alt="hospital" />
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-10">
                    <div class="padding5-20 pr-0">
                      <span class="font-20 semi-bold">Healthcare Access: </span>
                      <ng-container *ngFor="let report of predefinedReports" >
                        <span  *ngIf="report.reportId == 71">
                          <span class="blue-medium" *ngIf="report?.series[0]?.Yes != null">{{report?.series[0]?.Yes}}% </span>
                          <span class="blue-medium" *ngIf="report?.series[0]?.Yes == null">0% </span>
                        </span>
                      </ng-container>
                      <span>have access to healthcare</span>
                    </div>
                    <!-- *ngIf="report.reportId == 100" -->
                      <div class="question-icons padding0-20 flex mt-2">
                        <section class="text-center">
                          <div><img src="assets/online_images/government.webp" alt="government" title="government" /></div>
                          <div>Government</div>
                          <ng-container *ngFor="let report of predefinedReports" >
                            <ng-container *ngIf="report.reportId == 100"> 
                              <ng-container *ngFor="let item of report?.series">
                                <div *ngIf="item.key == 'Government hospital or dispensary'" class="pt-1 blue-medium">{{item.value}}%</div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </section>
                        <section class="text-center">
                          <div><img src="assets/online_images/private.webp" alt="private" title="private" /></div>
                          <div>Private</div>
                          <ng-container *ngFor="let report of predefinedReports" >
                            <ng-container *ngIf="report.reportId == 100"> 
                              <ng-container *ngFor="let item of report?.series">
                                <div *ngIf="item.key == 'Private hospital or dispensary'" class="pt-1 blue-medium">{{item.value}}%</div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </section>
                        <section class="text-center">
                          <div><img src="assets/online_images/mobile-healthcare.webp" alt="mobile-healthcare" title="mobile-healthcare" /></div>
                          <div>Mobile</div>
                          <ng-container *ngFor="let report of predefinedReports" >
                            <ng-container *ngIf="report.reportId == 100"> 
                              <ng-container *ngFor="let item of report?.series">
                                <div *ngIf="item.key == 'Mobile hospital'" class="pt-1 blue-medium">{{item.value}}%</div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </section>
                        <section class="text-center">
                          <div><img src="assets/online_images/ngo.webp" alt="ngo" title="ngo" /></div>
                          <div>NGO</div>
                          <ng-container *ngFor="let report of predefinedReports" >
                            <ng-container *ngIf="report.reportId == 100"> 
                              <ng-container *ngFor="let item of report?.series">
                                <div *ngIf="item.key == 'NGO health center'" class="pt-1 blue-medium">{{item.value}}%</div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </section>
                      </div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-md-12 col-lg-6 pl-0 pr-0">
                    <div #charts3 class="highcharts-no-border"></div>
                  </div>
                  <div class="col-md-12 col-lg-6 pl-0 pr-0">
                    <div #charts4 class="highcharts-no-border"></div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <div class="white-box height100">
                  <div class="row flex-center pt-2 no-gutters">
                    <div class="col-md-12 col-lg-5 text-center">
                      <img src="assets/online_images/ambulance.webp" alt="ambulance" style="height:55px" />
                    </div>
                    <ng-container *ngFor="let report of predefinedReports" >
                      <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 101">
                        <span class="font-24" *ngIf="report?.series[0]?.key != null">{{report?.series[0]?.key == 'Less than 30 minutes' ? '	&lt; 30 minutes' : report?.series[0]?.key == 'More than 1 hour' ? '&gt; 1 hour' : report?.series[0]?.key}}</span>
                        <span class="font-24" *ngIf="report?.series[0]?.key == null">0</span>
                      </div>
                    </ng-container>
                  </div>
                  <div class="mt-3 mb-2 text-center font-20 semi-bold height-48 flex-center content-center">Average Time to Hospital</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 col-lg-9">
          <div class="white-box height100">
            <div class="row no-gutters height100">
              <div class="col-md-12 col-lg-2">
                <div class="height100 flex-center content-center">
                  <img src="assets/online_images/water-source.webp" class="" alt="water source" class="height-83" />
                </div>
              </div>
              <div class="col-md-12 col-lg-10">
                <ng-container *ngFor="let report of predefinedReports" >
                  <div class="padding5-20" *ngIf="report.reportId == 24"><span class="font-20 semi-bold">Water Source: </span><span class="blue-medium" *ngIf="report?.series[0]?.Yes != null">{{report?.series[0]?.Yes}}% </span><span class="blue-medium" *ngIf="report?.series[0]?.Yes == null">0% </span> have access all year long</div>
                  <!-- <span class="font-20">Water Source: </span> -->
                </ng-container>  
                <div class="question-icons mt-2 padding0-20 flex">
                  <section class="text-center">
                    <div><img src="assets/online_images/lake.webp" alt="lake" title="pond / lake / river" /></div>
                    <div>Lake</div>
                    <ng-container *ngFor="let waterAccess of reportWaterAccess">
                      <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Pond / Lake / River'">{{waterAccess.value}}%</div>
                    </ng-container>
                  </section>
                  <section class="text-center">
                    <div><img src="assets/online_images/tap.webp" alt="tap" title="tap" /></div>
                    <div>Public Tap</div>
                    <ng-container *ngFor="let waterAccess of reportWaterAccess">
                      <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Public tap'">{{waterAccess.value}}%</div>
                    </ng-container>
                  </section>
                  <section class="text-center">
                    <div><img src="assets/online_images/well.webp" alt="well" title="well" /></div>
                    <div>Well</div>
                    <ng-container *ngFor="let waterAccess of reportWaterAccess">
                      <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Well'">{{waterAccess.value}}%</div>
                    </ng-container>
                  </section>
                  <section class="text-center">
                    <div><img src="assets/online_images/spring-tap.webp" alt="spring tap" title="spring tap" /></div>
                    <div>Spring Tap</div>
                    <ng-container *ngFor="let waterAccess of reportWaterAccess">
                      <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Spring tap'">{{waterAccess.value}}%</div>
                    </ng-container>
                  </section>
                  <section class="text-center">
                    <div><img src="assets/online_images/rain.webp" alt="rain" title="rain" /></div>
                    <div>Rain</div>
                    <ng-container *ngFor="let waterAccess of reportWaterAccess">
                      <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Rain'">{{waterAccess.value}}%</div>
                    </ng-container>
                  </section>
                  <section class="text-center">
                    <div><img src="assets/online_images/other.webp" alt="other" title="other" /></div>
                    <div>Other</div>
                    <ng-container *ngFor="let waterAccess of reportWaterAccess">
                        <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Other'">{{waterAccess.value}}%</div>
                    </ng-container>
                  </section>
                </div>
                <!-- <div class="question-icons mt-2 padding0-20" *ngIf="reportWaterAccess.length > 0">
                  <section class="text-center" *ngFor="let waterAccess of reportWaterAccess">
                    <div *ngIf="waterAccess.name == 'Pond / Lake / River'"><img src="assets/images/lake.webp" alt="lake" title="pond / lake / river" /></div>
                    <div *ngIf="waterAccess.name == 'Pond / Lake / River'">Lake</div>
                    <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Pond / Lake / River'">{{waterAccess.value}}%</div>
                    <div *ngIf="waterAccess.name == 'Public tap'"><img src="assets/images/tap.webp" alt="public tap" title="Public tap" /></div>
                    <div *ngIf="waterAccess.name == 'Public tap'">{{waterAccess.name}}</div>
                    <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Public tap'">{{waterAccess.value}}%</div>
                    <div *ngIf="waterAccess.name == 'Well'"><img src="assets/images/well.webp" alt="well" title="well" /></div>
                    <div *ngIf="waterAccess.name == 'Well'">{{waterAccess.name}}</div>
                    <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Well'">{{waterAccess.value}}%</div>
                    <div *ngIf="waterAccess.name == 'Spring tap'"><img src="assets/images/spring-tap.webp" alt="spring tap" title="spring tap" /></div>
                    <div *ngIf="waterAccess.name == 'Spring tap'">{{waterAccess.name}}</div>
                    <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Spring tap'">{{waterAccess.value}}%</div>
                    <div *ngIf="waterAccess.name == 'Rain'"><img src="assets/images/rain.webp" alt="rain" title="Rain" /></div>
                    <div *ngIf="waterAccess.name == 'Rain'">{{waterAccess.name}}</div>
                    <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Rain'">{{waterAccess.value}}%</div>
                    <div *ngIf="waterAccess.name == 'Other'"><img src="assets/images/other.webp" alt="other" title="other" /></div>
                    <div *ngIf="waterAccess.name == 'Other'">{{waterAccess.name}}</div>
                    <div class="pt-1 blue-medium" *ngIf="waterAccess.name == 'Other'">{{waterAccess.value}}%</div>
                  </section>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box height100">
              <div class="row flex-center pt-2 no-gutters">
                <div class="col-md-12 col-lg-5 text-center">
                  <img src="assets/online_images/time-to-water.webp" alt="time-to-water" style="height:55px" />
                </div>
                <ng-container *ngFor="let report of predefinedReports" >
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 99">
                    <span class="font-24" *ngIf="report?.series[0]?.key != null">{{report?.series[0]?.key == 'Less than 30 minutes' ? '	&lt; 30 minutes' : report?.series[0]?.key == 'More than 1 hour' ? '&gt; 1 hour' : report?.series[0]?.key}}</span>
                    <span class="font-24" *ngIf="report?.series[0]?.key == null">0</span>
                  </div>
                </ng-container>
              </div>
              <div class="mt-3 mb-2 text-center font-20 semi-bold height-48 flex-center content-center">Average Time to Reach Water Source</div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 col-lg-6">
          <div class="white-box height100">
              <div class="font-20 semi-bold">Cooking Source:</div>
              <div class="question-icons mt-3 flex">
                <section class="text-center">
                  <div><img src="assets/online_images/firewood.webp" alt="firewood" title="firewood" /></div>
                  <div>Fire wood</div>
                  <ng-container *ngFor="let cookingSource of reportCookingSource">
                    <div *ngIf="cookingSource.name == 'Fire wood'" class="pt-1 blue-medium">{{cookingSource.value}}%</div>
                  </ng-container>
                </section>
                <section class="text-center">
                  <div><img src="assets/online_images/gas-bottle.webp" alt="gas-bottle" title="gas" /></div>
                  <div>Gas</div>
                  <ng-container *ngFor="let cookingSource of reportCookingSource">
                    <div *ngIf="cookingSource.name == 'Gas'" class="pt-1 blue-medium">{{cookingSource.value}}%</div>
                  </ng-container>
                </section>
                <section class="text-center">
                  <div><img src="assets/online_images/coal.webp" alt="coal" title="coal" /></div>
                  <div>Coal</div>
                  <ng-container *ngFor="let cookingSource of reportCookingSource">
                    <div *ngIf="cookingSource.name == 'Coal'" class="pt-1 blue-medium">{{cookingSource.value}}%</div>
                  </ng-container>
                </section>
                <section class="text-center">
                  <div><img src="assets/online_images/other.webp" alt="other" title="other" /></div>
                  <div>Other</div>
                  <ng-container *ngFor="let cookingSource of reportCookingSource">
                    <div *ngIf="cookingSource.name == 'Other'" class="pt-1 blue-medium">{{cookingSource.value}}%</div>
                  </ng-container>
                </section>
              </div>
              <!-- <div class="question-icons mt-3" *ngIf="reportCookingSource?.length > 0">
                <section class="text-center" *ngFor="let cookingSource of reportCookingSource">
                  <div *ngIf="cookingSource.name == 'Fire wood'"><img src="assets/images/firewood.webp" alt="firewood" title="firewood" /></div>
                  <div *ngIf="cookingSource.name == 'Fire wood'">{{cookingSource.name}}</div>
                  <div *ngIf="cookingSource.name == 'Fire wood'" class="pt-1 blue-medium">{{cookingSource.value}}%</div>
                  <div *ngIf="cookingSource.name == 'Gas'"><img src="assets/images/gas-bottle.webp" alt="gas" title="gas" /></div>
                  <div *ngIf="cookingSource.name == 'Gas'">{{cookingSource.name}}</div>
                  <div *ngIf="cookingSource.name == 'Gas'" class="pt-1 blue-medium">{{cookingSource.value}}%</div>
                  <div *ngIf="cookingSource.name == 'Coal'"><img src="assets/images/coal.webp" alt="coal" title="coal" /></div>
                  <div *ngIf="cookingSource.name == 'Coal'">{{cookingSource.name}}</div>
                  <div *ngIf="cookingSource.name == 'Coal'" class="pt-1 blue-medium">{{cookingSource.value}}%</div>
                  <div *ngIf="cookingSource.name == 'Other'"><img src="assets/images/other.webp" alt="other" title="other" /></div>
                  <div *ngIf="cookingSource.name == 'Other'">{{cookingSource.name}}</div>
                  <div *ngIf="cookingSource.name == 'Other'" class="pt-1 blue-medium">{{cookingSource.value}}%</div>
                </section>
              </div> -->
          </div>
        </div>
        <div class="col-md-12 col-lg-6">
          <div class="white-box height100">
            <div class="font-20 semi-bold">Type of Housing :</div>
            <div class="question-icons mt-3 flex">
              <section class="text-center">
                <div><img src="assets/online_images/hut.webp" alt="hut" title="hut" /></div>
                <div>Concrete</div>
                <ng-container *ngFor="let housing of reportHousing">
                  <div *ngIf="housing.name == 'Concrete'" class="pt-1 blue-medium">{{housing.value}}%</div>
                </ng-container>
              </section>
              <section class="text-center">
                <div><img src="assets/online_images/hut.webp" alt="hut" title="hut" /></div>
                <div>Wooden</div>
                <ng-container *ngFor="let housing of reportHousing">
                  <div *ngIf="housing.name == 'Wooden'" class="pt-1 blue-medium">{{housing.value}}%</div>
                </ng-container>
              </section>
              <section class="text-center">
                <div><img src="assets/online_images/hut.webp" alt="hut" title="hut" /></div>
                <div>Thatched Hut</div>
                <ng-container *ngFor="let housing of reportHousing">
                  <div *ngIf="housing.name == 'Thatched hut'" class="pt-1 blue-medium">{{housing.value}}%</div>
                </ng-container>
              </section>
              <section class="text-center">
                <div><img src="assets/online_images/hut.webp" alt="hut" title="hut" /></div>
                <div>Tinned Housing</div>
                <ng-container *ngFor="let housing of reportHousing">
                  <div *ngIf="housing.name == 'Tinned housing'" class="pt-1 blue-medium">{{housing.value}}%</div>
                </ng-container>
              </section>
            </div>
            <!-- <ng-container *ngFor="let report of predefinedReports" >
              <div class="question-icons mt-3" *ngIf="report.reportId == 26">
                <section class="text-center" *ngFor="let report of report?.series[0] | keyvalue">
                  <div><img src="assets/images/hut.webp" alt="hut" title="hut" /></div>
                  <div>{{report.key}}</div>
                  <div class="pt-1 blue-medium">{{report.value}}%</div>
                </section>
              </div>
            </ng-container> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
