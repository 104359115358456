<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToReportingManagement(project?.id)" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)">
        <span i18n-title="@@projectReporting" title="Project Reporting">...</span>
      </li>      
      <li class="breadcrumb-item active" aria-current="page"><span i18n="@@kpis">KPIS</span></li>
    </ol>
  </nav>
</div>
<div class="content-wrap content-wrapper background margin-btm-20 min-height-calc" style="overflow:hidden">
  <div class="form-design form-box pb-0">
    <div class="row">
      <div class="col-md-12 col-lg-9">
        <label class="group-title inline-block mrbtm-two">
          <span id="KPI" i18n="@@runReport">Run Report</span>
        </label>
      </div>
    </div>
    <div class="row mrbottomten">
      <div class="col-md-12 col-lg-3 col-xs-12">
        <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': section_name.value == 0 && (section_name.dirty || section_name.touched) }">
          <label><span i18n="@@sectionName">Section Name </span><span class="mandatory"> *</span></label>
          <select class="form-control" (change)="selectKPISection($event,section_name)" [(ngModel)]="KPIAddObject.section" name="section_name"  #section_name="ngModel" [ngClass]="(KPIAddObject.section == 0 ? 'ng-invalid':'ng-valid')">
            <option value="0" class="option" i18n="@@select">Select</option>
            <option *ngFor="let list of listsKPISection; trackBy: trackBySectionName" [ngValue]="list">{{list}}</option>
          </select>
          <div class="error" *ngIf="section_name.value == 0 && (section_name.dirty || section_name.touched)">
            <span i18n="@@enterValidInput">Enter valid input.</span>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-3 col-xs-12">
        <div class="form-group no-margin-hr">
          <label style="color:#c1c1c1" i18n="@@subSectionName">Sub Section Name </label>
          <select class="form-control" disabled >
            <option value="" class="option" i18n="@@select">Select</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 col-lg-3 col-xs-12">
        <div class="form-group no-margin-hr reportsdropdown" >
          <label><span i18n="@@kpiName">KPI Name </span><span class="mandatory"> *</span></label>
          <!-- <select class="form-control" (change)="selectKPIs($event,kpis_name)" [(ngModel)]="kpisObject.dropDownVal" name="kpis_name"  #kpis_name="ngModel">
            <option value="" class="option" i18n="@@select">Select</option>
            <option *ngFor="let list of kpisListData; trackBy: trackByName" [ngValue]="list.id">{{list.name}}</option>
          </select> -->
          <ng-multiselect-dropdown
            [placeholder]="selectPlaceholder"
            [data]="kpisListData"
            [(ngModel)]="selectedKPIsMultiSelect"
            [settings]="dropdownSettings"
            [ngModelOptions]="{standalone: true}"
            (onSelectAll)="onSelectAllChart($event)"
            #reportNames="ngModel" name="reportNames" onchange="this.setAttribute('required','')" [ngClass]="(selectedKPIsMultiSelect.length <= 0 ? 'ng-invalid':'ng-valid')" required [disabled]="subsectionFlag"
            >
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="col-md-12 col-lg-3 col-xs-12 fltRgt datatable-header slidedownsearch">
        <label></label>
        <div class="form-group runreport-btns">
          <button type="button" name="button" class="runBtn btn btn-primary btn-lg mr-3" (click)="genarateKPIs()" [disabled]="selectedKPIsMultiSelect.length <= 0">
            <span i18n="@@run">Run</span>
          </button>
          <button type="button" name="button" class="btn btn-default-gray btn-lg"  (click)="cancel()">
            <span i18n="@@cancel">Cancel</span>
          </button>
        </div>
      </div>
    </div>
    <div class="form-design form-box pb-0 mb-0" *ngIf="kpisReportsList?.length > 0">
        <!-- <div class="row">
          <div class="col-md-12 col-lg-4">
            <div>
              <div class="form-group no-margin-hr">
                <label> </label>
                <select class="form-control" (change)="selectKPIs($event,kpis_name)" [(ngModel)]="kpisObject.dropDownVal" name="kpis_name"  #kpis_name="ngModel">
                  <option value="" class="option" i18n="@@select">Select</option>
                  <option *ngFor="let list of kpisListData; trackBy: trackByName" [ngValue]="list.id">{{list.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-3 col-xs-12 text-left datatable-header slidedownsearch">
            <div class="form-group">
              <button type="button" name="button" class="btn btn-primary mr-3" (click)="genarateKPIs()">
                <span>Add</span>
              </button>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-lg-12 form-group fltRgt datatable-header" style="padding-right:10px">
            <div class="countOfFarmers">
              <span *ngIf="countOfFarmers?.total_farmers != null">({{countOfFarmers?.total_farmers}} <span> <span i18n="@@farmers">Farmers</span>)</span></span>
              <span *ngIf="countOfFarmers?.total_farmers == null">(0 <span i18n="@@farmers">Farmers</span>)</span>
            </div>
            <!-- ngx print --><!--printSectionId="test1" ngxPrint-->
            <!-- <button type="button" name="button" id="printReportsBtn" class="btn btn-noBg btn-success btn-lg" (click)="printCharts()"> 
              <span i18n="@@print">Print</span>
            </button> -->
            <button type="button" name="button" class="btn btn-success btn-lg" (click)="clearAllCharts()">
              <span i18n="@@clearAll">Clear All</span>
            </button>
          </div>
        </div>
        <div class="row" id="KPIbox">
          <div [ngClass]="item.cols == 2 ? 'col-md-8 col-lg-8 pb-4' : item.cols == 3 ? 'col-md-12 col-lg-12 pb-4' : 'col-md-4 col-lg-4 pb-4'" *ngFor="let item of kpisReportsList;">
            <div *ngIf="item.is_multi else singleKPI">
              <div class="highcharts-container">
                <div class="row no-gutters">
                  <div class="col pos-r" *ngFor="let item2 of item.series; let i = index">
                    <span *ngIf="i < item.series.length - 1" class="right-separator"></span>
                    <div class="align-center content-center" style="padding-top:13px">
                      <div class="inline-block text-center">
                        <img [src]="item2?.kpi_image != '' && item2?.kpi_image != null ? item2?.kpi_image : 'assets/online_images/default.svg'" alt="{{item2?.name}}"  style="max-height:48px; float:left"/>
                      </div>
                      <div class="inline-block text-center pl-3">
                        <span class="fnt-size-common-number">{{item2?.value == 'Less than 30 minutes' ? '	&lt; 30 minutes' : item2?.value == 'More than 1 hour' ? '&gt; 1 hour' : item2?.value}}</span><br/>
                        <span class="weight-400">{{item2?.name}}</span>
                      </div>
                    </div>
                    <!-- <div class="row flex-center no-gutters">
                      <div class="col-md-12 col-lg-4 text-right pt-2 content-center">
                        <img [src]="item2?.kpi_image != '' && item2?.kpi_image != null ? item2?.kpi_image : 'assets/icons/default.svg'" alt="{{item2?.name}}"  style="max-height:48px"/>
                      </div>
                      <div class="col-md-12 col-lg-8">
                        <div class="row no-gutters">
                          <div class="col-md-12 col-lg-12 flex-center content-center pb-1 pt-3">
                            <span class="fnt-size-common-number">{{item2?.value == 'Less than 30 minutes' ? '	&lt; 30 minutes' : item2?.value == 'More than 1 hour' ? '&gt; 1 hour' : item2?.value}}</span>
                          </div>
                        </div>                  
                        <div class="row no-gutters">
                          <div class="col-md-12 col-lg-12 flex-center content-center">
                            <div class="align-center flex-center weight-400">{{item2?.name}}</div>
                          </div>
                        </div>
                      </div>
                    </div>  -->
                  </div>
                </div>
                <div class="align-center font-20 pb-2 pt-1 flex-center content-center">{{item?.name}}</div>
              </div>
            </div>
            <ng-template #singleKPI>
              <div class="highcharts-container" >
                <div class="row flex-center no-gutters">
                  <div class="col-md-12 col-lg-4 align-center content-center">
                    <img [src]="item?.kpi_image != '' && item?.kpi_image != null ? item?.kpi_image : 'assets/online_images/default.svg'" alt="{{item?.name}}"  style="max-height:67px"/>         
                  </div>
                  <div class="col-md-12 col-lg-8">
                    <div class="row no-gutters">
                      <div class="col-md-12 col-lg-12 flex-center content-center pb-3 pt-3">
                        <span class="fnt-size-common-number">{{item?.value == 'Less than 30 minutes' ? '	&lt; 30 minutes' : item?.value == 'More than 1 hour' ? '&gt; 1 hour' : item?.value}}</span>
                        <!-- <span *ngIf="item?.name == 'Avg Farmer Literacy Rate'" class="font-20 ml-2">%</span> -->
                      </div>
                    </div>                  
                    <div class="row no-gutters">
                      <div class="col-md-12 col-lg-12 flex-center content-center pr-2 pl-2">
                        <div class="align-center font-20 flex-center height-48">{{item?.name}}</div>
                      </div>
                    </div>
                  </div>
                </div>              
              </div>
            </ng-template>
          </div>
        </div>
    </div>
    <div *ngIf="kpisReportsList.length > 0" class="pb-2">
      <span class="gray weight-400" style="font-size: 0.7rem;">* N/D = No Data</span>
    </div>
  </div>
</div>