import * as tslib_1 from "tslib";
// TODO: Done: Build questionnaire from blank slate
// TODO: Done: Bug in option reordering
import { OnInit, TemplateRef, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// Services
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import { ChangeDetectorRef } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { ConsReportService } from '../../services/consolidated-reports.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { environment } from '../../../environments/environment';
import Compressor from 'compressorjs';
var Edq2Component = /** @class */ (function () {
    function Edq2Component(dynamicQuestionnaireService, route, surveyService, projectService, commonService, toastr, modalService, ref, router, locale, spinner, translate, location, consReportService) {
        var _this = this;
        this.dynamicQuestionnaireService = dynamicQuestionnaireService;
        this.route = route;
        this.surveyService = surveyService;
        this.projectService = projectService;
        this.commonService = commonService;
        this.toastr = toastr;
        this.modalService = modalService;
        this.ref = ref;
        this.router = router;
        this.locale = locale;
        this.spinner = spinner;
        this.translate = translate;
        this.location = location;
        this.consReportService = consReportService;
        this.showSectionForm = false;
        this.reloadingQuestionnaire = true;
        this.checkedObject = {};
        this.farmerId = 1;
        this.obj = { id: "8", key: "116", value: "1" };
        this.assetTypes = [
            { type: 'tab', label: 'Tab', subTypes: [] },
            { type: 'section', label: 'Section', subTypes: [] },
            { type: 'subsection', label: 'Subsection', subTypes: [] },
            { type: 'table', label: 'Table', subTypes: [{ key: 'question_rows', label: 'Questions in rows' }, { key: 'question_cols', label: 'Questions in columns' }] },
            { type: 'question', label: 'Question', subTypes: [{ key: 'text', label: 'Text' }, { key: 'number', label: 'Number' }, { key: 'select', label: 'Select' }, { key: 'multiselect', label: 'Multiselect' }, { key: 'date', label: 'Date' }, { key: 'file', label: 'File' }] },
            { type: 'option', label: 'Option', subTypes: [] },
            { type: 'uom_question', label: 'UOM Question', subTypes: [{ key: '1', label: 'Length' }, { key: '2', label: 'Volume' }, { key: '3', label: 'Weight' }, { key: '4', label: 'Area' }, { key: '5', label: 'Quantity' }] },
            { type: 'other_question', label: 'Other Question', subTypes: [] },
            { type: 'grand_total_question', label: 'Grand Total Question', subTypes: [] },
        ];
        this.assetSubtypes = [];
        this.changedAssets = [];
        this.validationTypes = ['max_value', 'min_value', 'regex_pattern'];
        this.newValidationTypes = [];
        this.mtaIssues = [];
        this.questionnaireChanges = {};
        this.displayLogicTypes = [{ type_key: 'value_matches', type_label: 'Must have specified answer' }, { type_key: 'value_exists', type_label: 'Can have any answer' }];
        this.displayLogicTypeSelected = { type_key: 'value_matches', type_label: 'Must have specified answer' };
        this.uomSubtypes = [];
        this.mtaHasOtherOption = false;
        this.autocalculateFormulaArray = [];
        this.formulaOperations = [
            { name: '+', symbol: '+' },
            { name: '-', symbol: '-' },
            { name: 'x', symbol: '*' },
            { name: '/', symbol: '/' }
        ];
        this.saveTemplateSubmitted = false;
        this.remainingTemplateDescriptionLength = 300;
        this.savingTemplate = false;
        this.displayLogicKeys = [];
        this.displayLogicValues = [];
        this.userLanguages = [];
        this.partOfDisplayLogic = false;
        this.recursiveRemoveOngoing = false;
        this.updateableAttributes = ['id', 'qa_type', 'qa_subtype', 'parent_id', 'parent_code', 'is_table_question',
            'include_uom_question', 'table_to_modify', 'disable_input', 'qa_sequence', 'removed_from_questionnaire',
            'modifies_qas', 'upd_date', 'upd_user_id', 'deleted'
        ];
        this.wizardFlowOngoing = false;
        this.wizardDisplayedPanel = 1;
        this.wizardPanel1Updated = false;
        this.labelVariable = 'survey_q_asset_labels';
        this.validationVariable = 'survey_q_asset_validations';
        this.displayLogicVariable = 'survey_q_asset_display_logic';
        this.autocalculateFormulaVariable = 'survey_q_asset_autocalculate_formulas';
        this.typeOfAdministration = '';
        this.p2fTemplate = false;
        this.autocalculateFormulaAssetIds = [];
        this.showUpArrow = false;
        this.previousDisplayLogics = [];
        this.ecosystemTemplates = [];
        this.loadingTextValue = 'Please wait';
        this.assetTypeLabels = {};
        this.isCampaign = false;
        this.consQuestions = [];
        this.page = 1;
        this.itemsPerPage = 10;
        this.searchText = '';
        this.additionalTypeSelected = [];
        this.isAscending = true;
        this.isAscendingName = true;
        this.questionnaireText = "Questionnaire";
        this.GTWizardDisplayedPanel = 1;
        this.gtaIssues = false;
        this.surveyManualFileToUpload = null;
        this.fileListObj = null;
        this.successUploadSurveyManualmsg = null;
        this.currentSec = null;
        this.enabledSections = [];
        this.toEnable = 0;
        this.enableTxt = "+ ";
        this.disableTxt = '\u2714 ';
        this.showSurveyManualTextRefText = "";
        this.currentSecFlex1isTrue = 0;
        this.surveyManualUploadedFlag = false;
        this.selectFileMsg = "";
        this.enableTextTranslatedValue = "";
        this.disableTextTranslatedValue = "";
        if (this.locale && this.locale.length > 2) {
            this.locale = this.locale.substr(0, 2);
        }
        this.route.paramMap.subscribe(function (params) {
            _this.route.queryParamMap.subscribe(function (queryParams) {
                var qpObject = tslib_1.__assign({}, queryParams.keys, queryParams);
                var fromNavId = qpObject['params']['from'];
                _this.translate.get('typeAdminTextArr').subscribe(function (val) {
                    _this.typeOfAdministrationArr = val;
                    if (fromNavId == 2) {
                        _this.typeOfAdministration = _this.typeOfAdministrationArr[0];
                    }
                    else if (fromNavId == 3) {
                        _this.typeOfAdministration = _this.typeOfAdministrationArr[1];
                    }
                    else if (fromNavId == 6) {
                        _this.typeOfAdministration = _this.typeOfAdministrationArr[2];
                    }
                }, function (err) { return console.log('err', err); });
                _this.translate.get('please_wait').subscribe(function (result) {
                    _this.loadingTextValue = result;
                });
                _this.translate.get('asset_labels').subscribe(function (result) {
                    _this.updateAssetLabels(result);
                });
                _this.translate.get('questionnaire').subscribe(function (result) {
                    _this.questionnaireText = result;
                });
                _this.translate.get('survey_manual_doc_upload_successfully').subscribe(function (result) {
                    _this.successUploadSurveyManualmsg = result;
                });
                _this.translate.get('please_select_file').subscribe(function (result) {
                    _this.selectFileMsg = result;
                });
                _this.translate.get('enable_text').subscribe(function (result) {
                    _this.enableTextTranslatedValue = result;
                });
                _this.translate.get('disable_text').subscribe(function (result) {
                    _this.disableTextTranslatedValue = result;
                });
            });
        });
    }
    // Get the project, surveys, questionnaire info etc.
    Edq2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.spinner.show();
        this.route.paramMap.subscribe(function (params) {
            _this.route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var request, x, request, i, j, x;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(queryParams.get('p2f') && queryParams.get('p2f') == '1')) return [3 /*break*/, 2];
                            this.p2fTemplate = true;
                            this.updateQVariables();
                            return [4 /*yield*/, Promise.all([
                                    this.commonService.getLanguages().toPromise(),
                                    this.surveyService.getP2FTemplate().toPromise()
                                ])];
                        case 1:
                            request = _a.sent();
                            if (request[0]['data']) {
                                this.userLanguages = request[0]['data'];
                            }
                            if (request[1]['message']) {
                                this.setTranslatedLabelObjects();
                                this.questionnaire = request[1]['message'];
                                this.initialize(0, 0);
                                this.reloadingQuestionnaire = false;
                                x = this.questionnaire[0][this.labelVariable].find(function (item) { return item.language_id == _this.getLanguageId(); });
                                // currentLocaleLanguageIdIndex stores the index of the current locale's language ID in the questionnaire's asset's survey_q_asst_labels array
                                // currentLocaleLanguageIdIndex is used in the html file to show / add the survey_q_asset_label for the current locale
                                this.currentLocaleLanguageIdIndex = this.questionnaire[0][this.labelVariable].indexOf(x);
                                this.setNotReleasedQuestionnaire();
                                this.spinner.hide();
                            }
                            return [3 /*break*/, 4];
                        case 2:
                            this.surveyId = params.get('surveyId');
                            this.projectId = params.get('projectId');
                            this.updateUpdateableAttributes();
                            return [4 /*yield*/, Promise.all([
                                    this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                    this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                    this.surveyService.getSurvey(this.surveyId).toPromise(),
                                    this.surveyService.getSurveyQuestionnaire(this.surveyId).toPromise(),
                                    this.surveyService.getAssociatedTemplates(this.surveyId).toPromise(),
                                    this.surveyService.getSurveyLanguages().toPromise()
                                ])];
                        case 3:
                            request = _a.sent();
                            if (request[0]['msg'] != 'success' || request[1]['msg'] != 'success') {
                                this.commonService.showToast('error', 'generic_error', {});
                            }
                            else {
                                this.project = tslib_1.__assign({}, request[0].data[0], request[1].data[0]);
                            }
                            if (request[2]['message']) {
                                this.survey = request[2]['message'];
                                if (this.survey.survey_type == "campaign") {
                                    this.isCampaign = true;
                                }
                                if (this.survey.flex4) {
                                    this.surveyManualUploadedFlag = true;
                                }
                            }
                            if (request[5]['message']) {
                                this.userLanguages = request[5]['message'];
                            }
                            if (request[3]['message']) {
                                this.setTranslatedLabelObjects();
                                if (request[3]['message'].length > 0) {
                                    this.questionnaire = request[3]['message'];
                                    for (i = 0; i < request[3]['message'].length; i++) {
                                        if (request[3]['message'][i].sections.length > 0) {
                                            for (j = 0; j < request[3]['message'][i].sections.length; j++) {
                                                if (parseInt(request[3]['message'][i].sections[j].flex1) === 1) {
                                                    this.enabledSections.push(request[3]['message'][i].sections[j].id);
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    this.initializeBlankQuestionnaire();
                                }
                                this.initialize(0, 0, true);
                                this.reloadingQuestionnaire = false;
                                x = this.questionnaire[0][this.labelVariable].find(function (item) { return item.language_id == _this.getLanguageId(); });
                                // currentLocaleLanguageIdIndex stores the index of the current locale's language ID in the questionnaire's asset's survey_q_asst_labels array
                                // currentLocaleLanguageIdIndex is used in the html file to show / add the survey_q_asset_label for the current locale
                                this.currentLocaleLanguageIdIndex = this.questionnaire[0][this.labelVariable].indexOf(x);
                                this.setNotReleasedQuestionnaire();
                                this.spinner.hide();
                            }
                            _a.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
        });
        //set consolidation types data
        this.reportsTypeData = [{
                "name": "Percent",
                "id": 1,
                "q_type": "multiselect, select, text"
            }, {
                "name": "Average",
                "id": 2,
                "q_type": "number"
            }, {
                "name": "Sum",
                "id": 3,
                "q_type": "number"
            }, {
                "name": "Min",
                "id": 4,
                "q_type": "number"
            }, {
                "name": "Max",
                "id": 5,
                "q_type": "number"
            }, {
                "name": "Median",
                "id": 6,
                "q_type": "number"
            }, {
                "name": "Count",
                "id": 7,
                "q_type": "multiselect, select, text"
            }, {
                "name": "Total Interviewees",
                "id": 8,
                "q_type": "multiselect, select, text"
            }, {
                "name": "Total Answers",
                "id": 9,
                "q_type": "multiselect, select, text, number"
            },
        ];
    };
    // Initialize the q to the given tab index & given section index
    Edq2Component.prototype.initialize = function (tabIndex, sectionIndex, selectFirstQuestion) {
        this.currentTabIndex = tabIndex;
        this.tabClicked(this.currentTabIndex, sectionIndex, selectFirstQuestion);
        this.collectDisplayLogicKeyValues();
        this.collectAutocalculateFormulaAssetIds();
    };
    // Initilize the q as a blank questionnaire (called with questionnaire returned as [])
    // Add the 1st tab & launch wizard
    Edq2Component.prototype.initializeBlankQuestionnaire = function () {
        var _a;
        var assetToAdd = (_a = {
                survey_id: this.surveyId,
                code: uuidv4(),
                qa_type: 'tab',
                parent_id: null,
                parent_code: null,
                qa_sequence: 1,
                qa_subtype: null
            },
            _a[this.labelVariable] = this.initializeSurveyAssetLabels('New tab'),
            _a[this.validationVariable] = [],
            _a[this.displayLogicVariable] = [],
            _a[this.autocalculateFormulaVariable] = [],
            _a.is_table_question = false,
            _a);
        this.questionnaire = [];
        this.questionnaire.push(assetToAdd);
        this.disableCancelOfModifyingAssetModal = true;
        this.isBlankQuestionnaire = true;
        this.modifyThisAsset(this.questionnaire[0], this.modifyAssetModalTemp, 'tab');
    };
    // Go to the tab = tabIndex & section = sectionIndex
    Edq2Component.prototype.tabClicked = function (tabIndex, sectionIndex, selectFirstQuestion) {
        delete this.currentTab;
        this.currentTab = JSON.parse(JSON.stringify(this.questionnaire[tabIndex]));
        this.currentTabIndex = tabIndex;
        this.currentSectionIndex = sectionIndex;
        this.sectionClicked(this.currentSectionIndex, selectFirstQuestion);
    };
    // Go to section = sectionIndex
    Edq2Component.prototype.sectionClicked = function (sectionIndex, selectFirstQuestion) {
        var _this = this;
        this.rightPanelScroll();
        if (this.currentTab) {
            delete this.currentSection;
            if (this.currentTab.sections && this.currentTab.sections.length > 0) {
                this.currentSection = JSON.parse(JSON.stringify(this.currentTab.sections[sectionIndex]));
                this.currentSectionIndex = sectionIndex;
                if (!isNaN(this.displayLogicIndex) || this.modifyAutocalculateFormulaForAsset || this.autocalculateFormulaForAssetExists) {
                    selectFirstQuestion = false;
                }
                if (selectFirstQuestion && this.currentSection.questions && this.currentSection.questions.length > 0) {
                    this.clickOnQuestionDiv(this.currentSection.questions[0], undefined);
                }
                this.ref.detectChanges();
                this.reloadingQuestionnaire = false;
                this.allSectionsInCurrentTabDeleted = !this.currentTab.sections.find(function (item) { return !item.deleted; }) ? true : false;
            }
            else {
                this.ref.detectChanges();
                this.reloadingQuestionnaire = false;
            }
        }
        else {
            this.ref.detectChanges();
            this.reloadingQuestionnaire = false;
        }
        this.checkTableForGrandTotal();
        var findItem = this.enabledSections.findIndex(function (item) { return item === _this.currentSection.id; });
        if (findItem !== -1) {
            this.currentSecFlex1isTrue = 1;
            this.showSurveyManualTextRefText = this.disableTxt + this.disableTextTranslatedValue;
        }
        else {
            this.currentSecFlex1isTrue = 0;
            this.showSurveyManualTextRefText = this.enableTxt + this.enableTextTranslatedValue;
        }
        // this.updateMTAAttribbutes();
    };
    Edq2Component.prototype.checkTableForGrandTotal = function () {
        if (this.currentSection && this.currentSection.tables && this.currentSection.tables.length > 0) {
            for (var _i = 0, _a = this.currentSection.tables; _i < _a.length; _i++) {
                var table = _a[_i];
                if (!table.grand_total_questions) {
                    table["grand_total_questions"] = [];
                }
            }
        }
    };
    // Scroll the right panel to the focused item
    Edq2Component.prototype.rightPanelScroll = function (el) {
        var parentScroll = document.getElementById('rightPanel');
        if (parentScroll) {
            parentScroll.scroll({ top: el ? (el.offsetTop + el.offsetParent.offsetTop) : 0, behavior: "smooth" });
        }
    };
    // Set the mta (modifyThisAsset) variable - this is the variable being modiified in the wizard
    Edq2Component.prototype.modifyThisAsset = function (asset, template, assetType) {
        var _this = this;
        this.questionAddEdit = this.addEditModeObj['edit_mode'];
        this.assetSubtypes = this.assetTypes.find(function (item) { return item.type == assetType; })['subTypes']; // Find the asset's subtypes - they are shown in the wizard
        this.mta = Object.assign({}, JSON.parse(JSON.stringify(asset))); // Set the mta variable
        console.log('mta', this.mta);
        this.uomSubtypes = this.assetTypes.find(function (item) { return item.type == 'uom_question'; })['subTypes']; // Find the types of UOM available
        this.mta.isMandatory = this.mta[this.validationVariable].find(function (each) { return each.validation_key == 'mandatory'; }) ? true : false; // Set the isMandatory attribute - used to check the checkboz
        var isMin = this.mta[this.validationVariable].find(function (each) { return each.validation_key == 'min_value'; });
        if (isMin) {
            this.mta.minValue = isMin.validation_value;
        } // Set the min attribute - used to check the checkboz
        var isMax = this.mta[this.validationVariable].find(function (each) { return each.validation_key == 'max_value'; });
        if (isMax) {
            this.mta.maxValue = isMax.validation_value;
        } // Set the max attribute - used to check the checkboz
        //this.mta.maxVal = this.mta[this.validationVariable].find(each => each.validation_key == 'mandatory') ? true : false; // Set the max attribute - used to check the checkboz
        if (asset.qa_type == 'question' || asset.qa_type == 'subsection' || asset.qa_type == 'table') {
            this.mta.childOfCurrentSection = this.mta.parent_code === this.currentSection.code;
        }
        this.setMtaHasOtherOption(); // Check if the mta already has an "other" option
        this.mtaIssues = []; // Initialize the mtaIssues array to blank (list of validation issues with the mta, such as missing English label)
        this.setPrimaryActionButtonLabel(); // Set the label of the primary action button - it depends on the type of MTA
        if (!this.wizardFlowOngoing) { // If the wizard flow is not already ongoing, launch the wizard modal
            this.manageUnits = false;
            this.modifyingAssetModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
            setTimeout(function () {
                var a = document.querySelector('.wizard-input-box');
                if (a) {
                    a.focus(); // Focus ont he 1st input box in the wizard
                    if (_this.isBlankQuestionnaire) {
                        a.select();
                    }
                }
            }, 1000);
        }
    };
    // Depending on thet type & subtype of the mta, set the primary action button label
    Edq2Component.prototype.setPrimaryActionButtonLabel = function () {
        if (this.mta.qa_type === 'tab') {
            this.primaryActionButtonLabel = this.primaryActionButtonLabelObj.save_add_section;
        }
        if (this.mta.qa_type === 'section') {
            this.primaryActionButtonLabel = this.primaryActionButtonLabelObj.save_next;
        }
        if (this.mta.qa_type === 'subsection' || this.mta.qa_type === 'table') {
            this.primaryActionButtonLabel = this.primaryActionButtonLabelObj.save_add_question;
        }
        if (this.mta.qa_type === 'question') {
            if (this.mta.qa_subtype === 'select' || this.mta.qa_subtype === 'multiselect') {
                this.primaryActionButtonLabel = this.primaryActionButtonLabelObj.save_manage_options;
            }
            else {
                if (this.manageUnits) {
                    this.primaryActionButtonLabel = this.primaryActionButtonLabelObj.save_manage_units;
                }
                else {
                    this.primaryActionButtonLabel = this.primaryActionButtonLabelObj.save_add_another;
                }
            }
        }
    };
    // TODO: Remove this function
    Edq2Component.prototype.takePrimaryActionInWizard = function () {
        this.wizardFlowOngoing = true;
        if (this.mta.qa_type === 'tab') {
            this.updateChangedAssets();
        }
        if (this.mta.qa_type === 'section') {
            if (this.validateMTA()) {
                this.updateChangedAssets();
                this.updateCurrentTab();
            }
        }
    };
    // Called if the asset's subtype is changed. E.g. question is changed from subtype = number to subtype = select
    Edq2Component.prototype.subtypeChanged = function (event) {
        this.setPrimaryActionButtonLabel();
    };
    // Check if the mta already has an "other" option
    Edq2Component.prototype.setMtaHasOtherOption = function () {
        if (this.mta.qa_type == 'question') {
            if (this.mta.options && this.mta.options.length > 0) {
                var x = this.mta.options.find(function (item) { return item.qa_subtype == 'other'; });
                // console.log('x', x);
                this.mta.mtaHasOtherOption = x ? true : false;
            }
        }
    };
    // Create arrays of ALL displayLogicKeys & displayLogicValues in the questionnaire
    // This is needed to check at the time of removal whether a given question / option can be safely removed
    Edq2Component.prototype.collectDisplayLogicKeyValues = function () {
        this.displayLogicKeys = [];
        this.displayLogicValues = [];
        if (this.questionnaire && this.questionnaire.length > 0) {
            for (var i = 0; i < this.questionnaire.length; i++) {
                if (this.questionnaire[i].sections && this.questionnaire[i].sections.length > 0) {
                    for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                        if (this.questionnaire[i].sections[j].questions && this.questionnaire[i].sections[j].questions.length > 0) {
                            for (var k = 0; k < this.questionnaire[i].sections[j].questions.length; k++) {
                                if (this.questionnaire[i].sections[j].questions[k][this.displayLogicVariable] && this.questionnaire[i].sections[j].questions[k][this.displayLogicVariable].length > 0) {
                                    for (var m = 0; m < this.questionnaire[i].sections[j].questions[k][this.displayLogicVariable].length; m++) {
                                        this.displayLogicKeys.push(this.questionnaire[i].sections[j].questions[k][this.displayLogicVariable][m].display_if_key.toString());
                                        if (this.questionnaire[i].sections[j].questions[k][this.displayLogicVariable][m].display_if_value != null) {
                                            this.displayLogicValues.push(this.questionnaire[i].sections[j].questions[k][this.displayLogicVariable][m].display_if_value.toString());
                                        }
                                    }
                                }
                            }
                        }
                        if (this.questionnaire[i].sections[j].subsections && this.questionnaire[i].sections[j].subsections.length > 0) {
                            for (var p = 0; p < this.questionnaire[i].sections[j].subsections.length; p++) {
                                if (this.questionnaire[i].sections[j].subsections[p].questions && this.questionnaire[i].sections[j].subsections[p].questions.length > 0) {
                                    for (var k = 0; k < this.questionnaire[i].sections[j].subsections[p].questions.length; k++) {
                                        if (this.questionnaire[i].sections[j].subsections[p].questions[k][this.displayLogicVariable] && this.questionnaire[i].sections[j].subsections[p].questions[k][this.displayLogicVariable].length > 0) {
                                            for (var m = 0; m < this.questionnaire[i].sections[j].subsections[p].questions[k][this.displayLogicVariable].length; m++) {
                                                this.displayLogicKeys.push(this.questionnaire[i].sections[j].subsections[p].questions[k][this.displayLogicVariable][m].display_if_key.toString());
                                                if (this.questionnaire[i].sections[j].subsections[p].questions[k][this.displayLogicVariable][m].display_if_value != null) {
                                                    this.displayLogicValues.push(this.questionnaire[i].sections[j].subsections[p].questions[k][this.displayLogicVariable][m].display_if_value.toString());
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                            for (var p = 0; p < this.questionnaire[i].sections[j].tables.length; p++) {
                                if (this.questionnaire[i].sections[j].tables[p].questions && this.questionnaire[i].sections[j].tables[p].questions.length > 0) {
                                    for (var k = 0; k < this.questionnaire[i].sections[j].tables[p].questions.length; k++) {
                                        if (this.questionnaire[i].sections[j].tables[p].questions[k][this.displayLogicVariable] && this.questionnaire[i].sections[j].tables[p].questions[k][this.displayLogicVariable].length > 0) {
                                            for (var m = 0; m < this.questionnaire[i].sections[j].tables[p].questions[k][this.displayLogicVariable].length; m++) {
                                                this.displayLogicKeys.push(this.questionnaire[i].sections[j].tables[p].questions[k][this.displayLogicVariable][m].display_if_key.toString());
                                                if (this.questionnaire[i].sections[j].tables[p].questions[k][this.displayLogicVariable][m].display_if_value != null) {
                                                    this.displayLogicValues.push(this.questionnaire[i].sections[j].tables[p].questions[k][this.displayLogicVariable][m].display_if_value.toString());
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };
    // Get the languageId of the current locale
    Edq2Component.prototype.getLanguageId = function () {
        var _this = this;
        if (this.userLanguages && this.userLanguages.length > 0) {
            var p = this.userLanguages.find(function (item) { return item.locale == _this.locale; });
            if (p) {
                return p['id'];
            }
            else {
                return undefined;
            }
        }
        else {
            return undefined;
        }
    };
    // Function called with a particular question's div (right panel) is clicked
    // Either display the question control buttons, or use the clicked question in a display logic / auto calculate formula
    Edq2Component.prototype.clickOnQuestionDiv = function (question, el) {
        this.partOfDisplayLogic = false;
        // If autocalculate formula change is ongoing
        if (question && (this.modifyAutocalculateFormulaForAsset || this.autocalculateFormulaForAssetExists)) {
            // If clicked question is NOT of type number, show error
            if (question.qa_subtype != 'number') {
                this.commonService.showToast('error', 'question_of_type_number', {});
                return;
            }
            if (this.isInvalidTableQuestion(question)) {
                this.commonService.showToast('error', 'cannot_select_question_from_table', {});
                return;
            }
            // If clicked question is not the same question for which formula's LHS is being set, then proceed to add it to the formula
            if ((this.modifyAutocalculateFormulaForAsset && this.modifyAutocalculateFormulaForAsset.code != question.code) || (this.autocalculateFormulaForAssetExists && this.autocalculateFormulaForAssetExists.code != question.code)) {
                this.setQuestionInModifyingAutocalcualteFormula(question);
            }
        }
        else if (this.modifyDisplayLogicForAsset) { // If display logic change is ongoing
            // If displayLogicDisplayIfKey is not already set, and the clicked question can be set as the key, then set it as the key
            if (question && !this.displayLogicDisplayIfKey && this.canSelectQuestionInDisplayLogic(question)) {
                this.displayLogicDisplayIfKey = question;
                this.displayLogicDisplayIfValue = undefined;
                this.addDisplayLogic(question.id, undefined); // Add the displayIfKey, displayIfvalue to the question's this.displayLogicVariable
                if (question.qa_type === 'text' || question.qa_type === 'number') {
                    this.displayLogicDisplayIfValueExists = true;
                }
            }
        }
        else { // If neither autocalculate formula nor display logic change is ongoing, then show the question icons for the clicked question
            this.showAddButtonAfterThisQuestion = question;
            if (el != undefined) { // Scroll to the clicked question
                this.rightPanelScroll(el);
            }
        }
    };
    // Add the question to an autocalculate formula
    Edq2Component.prototype.setQuestionInModifyingAutocalcualteFormula = function (question) {
        var x = this.autocalculateFormulaArray.find(function (item) { return item.selectedForSetting; });
        if (x) {
            var indexToSetQuestionAt = this.autocalculateFormulaArray.indexOf(x);
            if (this.validIndex(indexToSetQuestionAt)) {
                this.autocalculateFormulaArray[indexToSetQuestionAt] = { part: question, isAsset: true };
            }
        }
    };
    // Utility function to check if the index of something in in array / string etc. is valid (ie exists & > -1)
    Edq2Component.prototype.validIndex = function (index) {
        if (!(index == null || index == undefined) && index > -1) {
            return true;
        }
        return false;
    };
    // Add the clicked question (ifKey) & clicked option if any (ifValue) to the ongoing display logic operation
    Edq2Component.prototype.addDisplayLogic = function (ifKey, ifValue) {
        var displayLogicToAdd = {
            questionnaire_asset_id: this.modifyDisplayLogicForAsset.id,
            display_if_key: ifKey,
            display_if_value: ifValue,
            logic_type: ifValue ? 'value_matches' : 'value_exists'
        };
        if (!this.modifyDisplayLogicForAsset[this.displayLogicVariable] || this.modifyDisplayLogicForAsset[this.displayLogicVariable].length == 0) {
            this.modifyDisplayLogicForAsset[this.displayLogicVariable] = [];
        }
        this.modifyDisplayLogicForAsset[this.displayLogicVariable] = [displayLogicToAdd];
    };
    // Check if the clicked question can be selected as part of the ongoing display logic operation
    Edq2Component.prototype.canSelectQuestionInDisplayLogic = function (clickedQuestion) {
        // Dont allow selection of the question in its own display logic
        if (this.modifyDisplayLogicForAsset.code == clickedQuestion.code) {
            this.commonService.showToast('error', 'cannot_select_same_question', {});
            return false;
        }
        // Dont allow selection of a question in its parent's display logic
        if (this.modifyDisplayLogicForAsset.code == clickedQuestion.parent_code) {
            this.commonService.showToast('error', 'cannot_select_child_question', {});
            return false;
        }
        // Dont allow selection of a question that has been removed from the questionnaire
        if (clickedQuestion.removed_from_questionnaire) {
            this.commonService.showToast('error', 'question_not_remvoved_from_questionnaire', {});
            return false;
        }
        return true;
    };
    // Clicked to initiate a display logic operation for the selected asset (question, subsection, table)
    Edq2Component.prototype.modifyDisplayLogic = function (asset, template, parentSubsection, parentSection, direction) {
        var _this = this;
        if (!direction) {
            this.displayLogicIndex = 0;
            this.modifyingDisplayLogicInTab = this.currentTabIndex;
            this.modifyingDisplayLogicInSection = this.currentSectionIndex;
        }
        else if (direction === 1) {
            this.displayLogicIndex++;
        }
        else if (direction === -1) {
            this.displayLogicIndex--;
        }
        if (direction === 1 || direction === -1) {
            if (this.displayLogicForAssetExists) {
                asset = this.displayLogicForAssetExists;
            }
            else {
                asset = this.modifyDisplayLogicForAsset;
            }
        }
        // If display logic already exists, set the displayLogicForAssetExists variable
        if (asset[this.displayLogicVariable] && asset[this.displayLogicVariable].length > 0) {
            this.displayLogicForAssetExists = asset;
            if (this.displayLogicIndex < this.displayLogicForAssetExists[this.displayLogicVariable].length) {
                // Loop through the questionnaire till you find the displayLogicDisplayIfKey. It should match one of the question's ids
                loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                    for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                        this.displayLogicDisplayIfKey = this.questionnaire[i].sections[j].questions.find(function (item) { return item.id == _this.displayLogicForAssetExists[_this.displayLogicVariable][_this.displayLogicIndex].display_if_key; });
                        if (this.displayLogicDisplayIfKey) {
                            break loop1;
                        }
                        if (!this.displayLogicDisplayIfKey && this.questionnaire[i].sections[j].subsections && this.questionnaire[i].sections[j].subsections.length > 0) {
                            for (var k = 0; k < this.questionnaire[i].sections[j].subsections.length; k++) {
                                this.displayLogicDisplayIfKey = this.questionnaire[i].sections[j].subsections[k].questions.find(function (item) { return item.id == _this.displayLogicForAssetExists[_this.displayLogicVariable][_this.displayLogicIndex].display_if_key; });
                                if (this.displayLogicDisplayIfKey) {
                                    break loop1;
                                }
                            }
                        }
                        if (!this.displayLogicDisplayIfKey && this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                            for (var k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                                this.displayLogicDisplayIfKey = this.questionnaire[i].sections[j].tables[k].questions.find(function (item) { return item.id == _this.displayLogicForAssetExists[_this.displayLogicVariable][_this.displayLogicIndex].display_if_key; });
                                if (this.displayLogicDisplayIfKey) {
                                    break loop1;
                                }
                            }
                        }
                    }
                }
                // If the display logic type is 'value_matches', then set the value of displayLogicDisplayIfValue variable
                if (asset[this.displayLogicVariable][this.displayLogicIndex].logic_type == 'value_matches') {
                    this.displayLogicDisplayIfValue = this.displayLogicDisplayIfKey.options.find(function (item) { return item.id == _this.displayLogicForAssetExists[_this.displayLogicVariable][_this.displayLogicIndex].display_if_value; });
                    this.displayLogicDisplayIfValueExists = false;
                }
                else {
                    this.displayLogicDisplayIfValueExists = true;
                }
            }
            else {
                this.previousDisplayLogics = asset[this.displayLogicVariable];
                this.resetDisplayLogic(true);
                this.modifyDisplayLogicForAsset = asset;
            }
        }
        else { // If display logic does not already exist, set the modifyDisplayLogicForAsset variable
            this.modifyDisplayLogicForAsset = asset;
        }
    };
    // Clicked to initiate a autocalculate formula operation for the selected asset (question)
    Edq2Component.prototype.modifyAutocalculateFormula = function (asset, parentSubsection, parentSection) {
        this.modifyingAutoCalcFormulaInTab = this.currentTabIndex;
        this.modifyingAutoCalcFormulaInSection = this.currentSectionIndex;
        // If a formula already exists for the asset
        if (asset[this.autocalculateFormulaVariable] && asset[this.autocalculateFormulaVariable].length > 0) {
            var formula = asset[this.autocalculateFormulaVariable][0].formula;
            var formulaArray_1 = formula.split(",");
            // Find the asset ids that make up the formula RHS - ignore the operator values such as "+" etc
            var formulaQAssetIdArray_1 = [];
            for (var p = 0; p < formulaArray_1.length; p++) {
                // Ignore non-number elements of the formula
                if (isNaN(parseInt(formulaArray_1[p]))) { }
                else {
                    formulaQAssetIdArray_1.push(parseInt(formulaArray_1[p]));
                }
            }
            // Find the assets that make up the formula RHS (this is needed to display in the green box)
            var formulaQAssetArray = [];
            for (var i = 0; i < this.questionnaire.length; i++) {
                if (this.questionnaire[i].sections && this.questionnaire[i].sections.length > 0) {
                    for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                        if (this.questionnaire[i].sections[j].questions && this.questionnaire[i].sections[j].questions.length > 0) {
                            var x = this.questionnaire[i].sections[j].questions.filter(function (item) { return formulaQAssetIdArray_1.indexOf(item.id) > -1; });
                            formulaQAssetArray = formulaQAssetArray.concat(x);
                        }
                        if (this.questionnaire[i].sections[j].subsections && this.questionnaire[i].sections[j].subsections.length > 0) {
                            for (var k = 0; k < this.questionnaire[i].sections[j].subsections.length; k++) {
                                var y = this.questionnaire[i].sections[j].subsections[k].questions.filter(function (item) { return formulaQAssetIdArray_1.indexOf(item.id) > -1; });
                                formulaQAssetArray = formulaQAssetArray.concat(y);
                            }
                        }
                        if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                            for (var k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                                var z = this.questionnaire[i].sections[j].tables[k].questions.filter(function (item) { return formulaQAssetIdArray_1.indexOf(item.id) > -1; });
                                formulaQAssetArray = formulaQAssetArray.concat(z);
                            }
                            for (var k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                                if (this.questionnaire[i].sections[j].tables[k].grand_total_questions && this.questionnaire[i].sections[j].tables[k].grand_total_questions.length > 0) {
                                    var z = this.questionnaire[i].sections[j].tables[k].grand_total_questions.filter(function (item) { return formulaQAssetIdArray_1.indexOf(item.id) > -1; });
                                    formulaQAssetArray = formulaQAssetArray.concat(z);
                                }
                            }
                        }
                    }
                }
            }
            // Set the autocalculateFormulaArray array, consisting of q assets & operators both
            this.autocalculateFormulaArray = [];
            var _loop_1 = function (i) {
                if (isNaN(parseInt(formulaArray_1[i]))) {
                    this_1.autocalculateFormulaArray.push({ part: formulaArray_1[i], isAsset: false });
                }
                else {
                    var x = formulaQAssetArray.find(function (item) { return item.id == formulaArray_1[i]; });
                    if (x) {
                        this_1.autocalculateFormulaArray.push({ part: x, isAsset: true });
                    }
                    else {
                        // TODO: Handle error
                    }
                }
            };
            var this_1 = this;
            for (var i = 0; i < formulaArray_1.length; i++) {
                _loop_1(i);
            }
            this.autocalculateFormulaForAssetExists = asset;
        }
        else { // If the autocalculate formula does not already exist for this asset, then initialize with a blank formula array with * operator
            this.modifyAutocalculateFormulaForAsset = asset;
            this.autocalculateFormulaArray = [];
            this.autocalculateFormulaArray.push({ isAsset: true, editing: true });
            this.autocalculateFormulaArray.push({ isAsset: false, part: "+" });
            this.autocalculateFormulaArray.push({ isAsset: true, editing: true });
        }
    };
    // Clicked to remove display logic (either pre-existing or from ongoing operation)
    Edq2Component.prototype.removeDisplayLogic = function () {
        if ((this.modifyDisplayLogicForAsset && this.modifyDisplayLogicForAsset.qa_type === 'question') || (this.displayLogicForAssetExists && this.displayLogicForAssetExists.qa_type === 'question')) {
            var el = document.querySelector(".active-question");
            this.rightPanelScroll(el);
        }
        // Store the existing value in a local variable & update the mta
        var originalDisplayLogicArray;
        if (this.modifyDisplayLogicForAsset) {
            originalDisplayLogicArray = JSON.parse(JSON.stringify(this.modifyDisplayLogicForAsset[this.displayLogicVariable]));
            this.modifyDisplayLogicForAsset[this.displayLogicVariable] = this.modifyDisplayLogicForAsset[this.displayLogicVariable].filter(function (item) { return item.id; });
            this.mta = this.modifyDisplayLogicForAsset;
        }
        else if (this.displayLogicForAssetExists) {
            originalDisplayLogicArray = JSON.parse(JSON.stringify(this.displayLogicForAssetExists[this.displayLogicVariable]));
            this.displayLogicForAssetExists[this.displayLogicVariable].splice(this.displayLogicIndex, 1);
            this.mta = this.displayLogicForAssetExists;
        }
        // If the existing value was not just an empty array, then update the changedAssets variable & the displayed tab
        if (originalDisplayLogicArray && originalDisplayLogicArray.length > 0 && originalDisplayLogicArray.find(function (item) { return item.id; })) {
            this.currentTabIndex = this.modifyingDisplayLogicInTab;
            this.currentSectionIndex = this.modifyingDisplayLogicInSection;
            this.spinner.show();
            this.updateChangedAssets();
            this.updateCurrentTab();
        }
        this.showAddButtonAfterThisQuestion = this.mta;
        // Reset the display logic variables
        this.resetDisplayLogic();
    };
    // "Confirm" & close the ongoing display logic operation, commit to the local variable for DB save
    Edq2Component.prototype.saveDisplayLogic = function () {
        if ((this.modifyDisplayLogicForAsset && this.modifyDisplayLogicForAsset.qa_type === 'question') || (this.displayLogicForAssetExists && this.displayLogicForAssetExists.qa_type === 'question')) {
            var el = document.querySelector(".active-question");
            this.rightPanelScroll(el);
        }
        this.mta = this.modifyDisplayLogicForAsset;
        this.mta[this.displayLogicVariable] = this.mta[this.displayLogicVariable].concat(this.previousDisplayLogics);
        this.spinner.show();
        this.currentTabIndex = this.modifyingDisplayLogicInTab;
        this.currentSectionIndex = this.modifyingDisplayLogicInSection;
        this.updateChangedAssets();
        this.updateCurrentTab();
        this.resetDisplayLogic();
    };
    // Cancel ongoing display logic operation
    Edq2Component.prototype.cancelDisplayLogicChanges = function () {
        if ((this.modifyDisplayLogicForAsset && this.modifyDisplayLogicForAsset.qa_type === 'question') || (this.displayLogicForAssetExists && this.displayLogicForAssetExists.qa_type === 'question')) {
            var el = document.querySelector(".active-question");
            this.rightPanelScroll(el);
        }
        this.resetDisplayLogic();
    };
    // Reset variables in the ongoing display logic operation
    Edq2Component.prototype.resetDisplayLogic = function (retainCurrentTabSectionValues) {
        this.modifyDisplayLogicForAsset = undefined;
        this.displayLogicForAssetExists = undefined;
        this.displayLogicDisplayIfKey = undefined;
        this.displayLogicDisplayIfValue = undefined;
        this.displayLogicDisplayIfValueExists = undefined;
        this.displayLogicIndex = undefined;
        if (!retainCurrentTabSectionValues) {
            this.modifyingDisplayLogicInTab = undefined;
            this.modifyingDisplayLogicInSection = undefined;
        }
    };
    // Update the local variable that stores all "changed" assets, ie. assets that need to be sent to the API & saved to DB
    Edq2Component.prototype.updateChangedAssets = function (initiateWizardFlow) {
        var x = JSON.parse(JSON.stringify(this.mta));
        var mtaBeingModified = this.changedAssets.find(function (item) { return item.code == x.code; });
        if (mtaBeingModified) {
            var index = this.changedAssets.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
                this.changedAssets[index] = x;
            }
        }
        else {
            this.changedAssets.push(x);
        }
        this.checkAndSaveChangedAssets(initiateWizardFlow);
    };
    // Commit changed assets to the DB
    Edq2Component.prototype.checkAndSaveChangedAssets = function (initiateWizardFlow) {
        var _this = this;
        if (!this.saveChangedAssetsInterval) {
            // Set up a 1 second frequency interval to check if the assets should be committed to DB now
            this.saveChangedAssetsInterval = setInterval(function () {
                // Assts should be cmmmitted to DB only if there are any such assts to be committed & also a recursive removal is NOT going on
                // A recursive removal is one where e.g. - a section is removed, all questions (and all options in the question etc) are also marked for removal
                // if a recursive removal is going on, then make the API call only after the entire recursive removal is completed
                if (_this.changedAssets && _this.changedAssets.length > 0) {
                    // this.spinner.show();
                    if (!_this.recursiveRemoveOngoing) {
                        _this.saveQuestionnaireChanges(initiateWizardFlow);
                    }
                }
            }, 100);
        }
    };
    // Update the asset which is being modified, with all the changes that are part of the 'mta' variable
    Edq2Component.prototype.updateCurrentTab = function () {
        var x = JSON.parse(JSON.stringify(this.mta));
        if (x.qa_type == 'question') {
            this.updateCurrentTabQuestion();
        }
        else if (x.qa_type == 'section') {
            this.updateCurrentTabSection();
        }
        else if (x.qa_type == 'tab') {
            this.updateCurrentTabEntireTab();
        }
        else if (x.qa_type == 'subsection') {
            this.updateCurrentTabSubsection();
        }
        else if (x.qa_type == 'table') {
            this.updateCurrentTabTable();
        }
    };
    // Update asset of type = question in the questionnaire object
    Edq2Component.prototype.updateCurrentTabQuestion = function () {
        var _this = this;
        var parentAsset = this.getParentAsset(this.mta);
        if (parentAsset.qa_type == 'section') {
            if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions) {
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions = [];
            }
            var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.find(function (item) { return item.code == _this.mta.code; });
            if (mtaBeingModified) {
                var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.indexOf(mtaBeingModified);
                if (this.validIndex(index)) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[index] = this.mta;
                }
            }
            else {
                if (this.relativeToAssetIndex != undefined) {
                    if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions) {
                        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions = [];
                    }
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.splice(this.relativeToAssetIndex + 1, 0, this.mta);
                }
                else {
                    if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions) {
                        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions = [];
                    }
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.splice(this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.length, 0, this.mta);
                }
            }
        }
        else if (parentAsset.qa_type == 'subsection') {
            if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections) {
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections = [];
            }
            var subsectionBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.find(function (item) { return item.code == _this.mta.parent_code; });
            if (subsectionBeingModified) {
                var subsectionIndex = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.indexOf(subsectionBeingModified);
                if (this.validIndex(subsectionIndex)) {
                    if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions) {
                        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions = [];
                    }
                    var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions.find(function (item) {
                        return item.code == _this.mta.code;
                    });
                    if (mtaBeingModified) {
                        var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions.indexOf(mtaBeingModified);
                        if (this.validIndex(index)) {
                            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions[index] = this.mta;
                        }
                    }
                    else {
                        if (this.relativeToAssetIndex != undefined) {
                            if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions) {
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions = [];
                            }
                            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions.splice(this.relativeToAssetIndex + 1, 0, this.mta);
                        }
                    }
                }
            }
        }
        else if (parentAsset.qa_type == 'table') {
            if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables) {
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables = [];
            }
            var tableBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.find(function (item) { return item.code == _this.mta.parent_code; });
            if (tableBeingModified) {
                var tableIndex = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.indexOf(tableBeingModified);
                if (this.validIndex(tableIndex)) {
                    if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions) {
                        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions = [];
                    }
                    var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions.find(function (item) {
                        return item.code == _this.mta.code;
                    });
                    if (mtaBeingModified) {
                        var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions.indexOf(mtaBeingModified);
                        if (this.validIndex(index)) {
                            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions[index] = this.mta;
                        }
                    }
                    else {
                        if (this.relativeToAssetIndex != undefined) {
                            if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions) {
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions = [];
                            }
                            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions.splice(this.relativeToAssetIndex + 1, 0, this.mta);
                        }
                    }
                }
            }
        }
        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined, undefined);
    };
    // Update asset of type = section in the questionnaire object
    Edq2Component.prototype.updateCurrentTabSection = function () {
        var _this = this;
        if (!this.questionnaire[this.currentTabIndex].sections) {
            this.questionnaire[this.currentTabIndex].sections = [];
        }
        var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections.find(function (item) { return item.code == _this.mta.code; });
        if (mtaBeingModified) {
            var index = this.questionnaire[this.currentTabIndex].sections.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
                this.questionnaire[this.currentTabIndex].sections[index] = this.mta;
            }
        }
        else {
            if (this.relativeToAssetIndex != undefined) {
                if (!this.questionnaire[this.currentTabIndex].sections) {
                    this.questionnaire[this.currentTabIndex].sections = [];
                }
                this.questionnaire[this.currentTabIndex].sections.splice(this.relativeToAssetIndex + 1, 0, this.mta);
            }
            else {
                if (!this.questionnaire[this.currentTabIndex].sections) {
                    this.questionnaire[this.currentTabIndex].sections = [];
                }
                this.questionnaire[this.currentTabIndex].sections.splice(this.questionnaire[this.currentTabIndex].sections.length, 0, this.mta);
            }
        }
        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined, undefined);
    };
    // Update asset of type = subsection in the questionnaire object
    Edq2Component.prototype.updateCurrentTabSubsection = function () {
        var _this = this;
        if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections) {
            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections = [];
        }
        var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.find(function (item) { return item.code == _this.mta.code; });
        if (mtaBeingModified) {
            var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[index] = this.mta;
            }
        }
        else {
            if (this.relativeToAssetIndex != undefined) {
                if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections = [];
                }
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.splice(this.relativeToAssetIndex + 1, 0, this.mta);
            }
            else {
                if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections = [];
                }
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.splice(this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.length, 0, this.mta);
            }
        }
        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined, undefined);
    };
    // Update asset of type = table in the questionnaire object
    Edq2Component.prototype.updateCurrentTabTable = function () {
        var _this = this;
        if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables) {
            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables = [];
        }
        var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.find(function (item) { return item.code == _this.mta.code; });
        if (mtaBeingModified) {
            var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[index] = this.mta;
            }
        }
        else {
            if (this.relativeToAssetIndex != undefined) {
                if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables = [];
                }
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.splice(this.relativeToAssetIndex + 1, 0, this.mta);
            }
            else {
                if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables = [];
                }
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.splice(this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.length, 0, this.mta);
            }
        }
        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined, undefined);
    };
    // // Update asset of type = tab in the questionnaire object
    Edq2Component.prototype.updateCurrentTabEntireTab = function () {
        var _this = this;
        if (!this.questionnaire) {
            this.questionnaire = [];
        }
        var mtaBeingModified = this.questionnaire.find(function (item) { return item.code == _this.mta.code; });
        if (mtaBeingModified) {
            var index = this.questionnaire.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
                this.questionnaire[index] = this.mta;
            }
        }
        else {
            if (this.relativeToAssetIndex != undefined) {
                if (!this.questionnaire) {
                    this.questionnaire = [];
                }
                this.questionnaire.splice(this.relativeToAssetIndex + 1, 0, this.mta);
            }
        }
        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined, undefined);
    };
    // Given an asset, get its parent asset (e.g. for a section, this function will return the parent tab)
    Edq2Component.prototype.getParentAsset = function (childAsset) {
        var parentAsset;
        if (!childAsset.parent_code) {
            return null;
        }
        if (childAsset.qa_type == 'question') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
                        parentAsset = this.questionnaire[i].sections[j];
                        break loop1;
                    }
                    else {
                        if (this.questionnaire[i].sections[j].subsections && this.questionnaire[i].sections[j].subsections.length > 0) {
                            var parentSubsection = this.questionnaire[i].sections[j].subsections.find(function (item) { return item.code == childAsset.parent_code; });
                            if (parentSubsection) {
                                parentAsset = parentSubsection;
                                break loop1;
                            }
                        }
                        if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                            var parentTable = this.questionnaire[i].sections[j].tables.find(function (item) { return item.code == childAsset.parent_code; });
                            if (parentTable) {
                                parentAsset = parentTable;
                                break loop1;
                            }
                        }
                    }
                }
            }
        }
        if (childAsset.qa_type == 'subsection') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
                        parentAsset = this.questionnaire[i].sections[j];
                        break loop1;
                    }
                }
            }
        }
        if (childAsset.qa_type == 'section') {
            for (var i = 0; i < this.questionnaire.length; i++) {
                parentAsset = this.questionnaire.find(function (item) { return item.code == childAsset.parent_code; });
            }
        }
        if (childAsset.qa_type == 'option' || childAsset.qa_type == 'other_question' || childAsset.qa_type == 'uom_question') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    var parentQuestion = void 0;
                    if (this.questionnaire[i].sections[j].questions && this.questionnaire[i].sections[j].questions.length > 0) {
                        parentQuestion = this.questionnaire[i].sections[j].questions.find(function (item) { return item.code == childAsset.parent_code; });
                    }
                    if (parentQuestion) {
                        parentAsset = parentQuestion;
                        break loop1;
                    }
                    else {
                        if (this.questionnaire[i].sections[j].subsections && this.questionnaire[i].sections[j].subsections.length > 0) {
                            for (var k = 0; k < this.questionnaire[i].sections[j].subsections.length; k++) {
                                var parentQuestion_1 = this.questionnaire[i].sections[j].subsections[k].questions.find(function (item) { return item.code == childAsset.parent_code; });
                                if (parentQuestion_1) {
                                    parentAsset = parentQuestion_1;
                                    break loop1;
                                }
                            }
                        }
                        if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                            for (var k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                                var parentQuestion_2 = this.questionnaire[i].sections[j].tables[k].questions.find(function (item) { return item.code == childAsset.parent_code; });
                                if (parentQuestion_2) {
                                    parentAsset = parentQuestion_2;
                                    break loop1;
                                }
                            }
                        }
                    }
                }
            }
        }
        if (childAsset.qa_type == 'table') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
                        parentAsset = this.questionnaire[i].sections[j];
                        break loop1;
                    }
                }
            }
        }
        return parentAsset;
    };
    // Cancel ongoing auto calculate formula changes
    Edq2Component.prototype.cancelAutocalculateFormulaChanges = function () {
        this.resetAutocalculateFormula();
        var el = document.querySelector(".active-question");
        this.rightPanelScroll(el);
    };
    // Remove the selected asset's autocalculate formula changes
    Edq2Component.prototype.removeAutocalculateFormula = function () {
        var el = document.querySelector(".active-question");
        this.rightPanelScroll(el);
        if (this.modifyAutocalculateFormulaForAsset) {
            this.modifyAutocalculateFormulaForAsset[this.autocalculateFormulaVariable] = [];
            this.mta = this.modifyAutocalculateFormulaForAsset;
        }
        else if (this.autocalculateFormulaForAssetExists) {
            this.autocalculateFormulaForAssetExists[this.autocalculateFormulaVariable] = [];
            this.mta = this.autocalculateFormulaForAssetExists;
        }
        this.currentTabIndex = this.modifyingAutoCalcFormulaInTab;
        this.currentSectionIndex = this.modifyingAutoCalcFormulaInSection;
        this.spinner.show();
        this.recursiveRemoveOngoing = true;
        this.updateChangedAssets();
        this.updateCurrentTab();
        this.updateModifiesQasOfFormulaParts(null);
        this.showAddButtonAfterThisQuestion = this.autocalculateFormulaForAssetExists;
        this.resetAutocalculateFormula();
    };
    // Reset auto calculate formula variables
    Edq2Component.prototype.resetAutocalculateFormula = function () {
        this.modifyAutocalculateFormulaForAsset = undefined;
        this.autocalculateFormulaForAssetExists = undefined;
        this.modifyingAutoCalcFormulaInTab = undefined;
        this.modifyingAutoCalcFormulaInSection = undefined;
        this.autocalculateFormulaArray = [];
    };
    // Remove one of the autocalculate formula parts
    Edq2Component.prototype.removeThisFormulaPart = function (index) {
        // At this index in autocalculateFormulaArray set the part with attribute editing: true
        this.autocalculateFormulaArray[index] = { editing: true, isAsset: true };
        this.selectAutocalculatePartAtThisIndex(index);
    };
    // Mark one of the autocalculate formula parts for selection (i.e. setting the question value at the selected parts)
    Edq2Component.prototype.selectAutocalculatePartAtThisIndex = function (index) {
        this.autocalculateFormulaArray[index].selectedForSetting = true;
    };
    // Add a new operator to the end of the autocalculate formula
    Edq2Component.prototype.appendNewOperator = function () {
        this.autocalculateFormulaArray.push({ isAsset: false, part: "+" });
        this.autocalculateFormulaArray.push({ isAsset: true, editing: true });
    };
    // Set the modifies_qas value of each autocalculate formula part - modifies_qas is the asset ID of the formula's LHS
    Edq2Component.prototype.updateModifiesQasOfFormulaParts = function (qaBeingModifiedId) {
        for (var i = 0; i < this.autocalculateFormulaArray.length; i++) {
            if (this.autocalculateFormulaArray[i].isAsset) {
                var p = this.autocalculateFormulaArray[i].part;
                if (p.uom_questions && p.uom_questions.length > 0) {
                    for (var j = 0; j < p.uom_questions.length; j++) {
                        this.mta = p.uom_questions[j];
                        this.mta.modifies_qas = qaBeingModifiedId;
                        this.updateChangedAssets();
                        this.updateCurrentTab();
                    }
                    this.mta = p;
                    this.mta.modifies_qas = qaBeingModifiedId;
                    if (i === this.autocalculateFormulaArray.length - 1) {
                        this.recursiveRemoveOngoing = false;
                    }
                    this.updateChangedAssets();
                    this.updateCurrentTab();
                }
                else {
                    this.mta = p;
                    this.mta.modifies_qas = qaBeingModifiedId;
                    if (i === this.autocalculateFormulaArray.length - 1) {
                        this.recursiveRemoveOngoing = false;
                    }
                    this.updateChangedAssets();
                    this.updateCurrentTab();
                }
            }
        }
    };
    // Called on click of "Save" in autocalculate formula box
    Edq2Component.prototype.saveAutocalculateFormulaForAsset = function () {
        if (!this.validateAutocalculateFormulaArray()) {
            this.commonService.showToast('error', 'enter_valid_formula', {});
            return;
        }
        var el = document.querySelector(".active-question");
        this.rightPanelScroll(el);
        var newFormulaArray = [];
        for (var i = 0; i < this.autocalculateFormulaArray.length; i++) {
            if (this.autocalculateFormulaArray[i].isAsset) {
                newFormulaArray.push(this.autocalculateFormulaArray[i].part.id);
            }
            else {
                newFormulaArray.push(this.autocalculateFormulaArray[i].part);
            }
        }
        var newFormulaString = newFormulaArray.join();
        if (this.autocalculateFormulaForAssetExists) {
            this.autocalculateFormulaForAssetExists[this.autocalculateFormulaVariable][0].formula = newFormulaString;
            this.mta = this.autocalculateFormulaForAssetExists;
        }
        else if (this.modifyAutocalculateFormulaForAsset) {
            if (!this.modifyAutocalculateFormulaForAsset[this.autocalculateFormulaVariable] || this.modifyAutocalculateFormulaForAsset[this.autocalculateFormulaVariable].length == 0) {
                this.modifyAutocalculateFormulaForAsset[this.autocalculateFormulaVariable] = [{}];
            }
            this.modifyAutocalculateFormulaForAsset[this.autocalculateFormulaVariable][0]['questionnaire_asset_id'] = this.modifyAutocalculateFormulaForAsset.id;
            this.modifyAutocalculateFormulaForAsset[this.autocalculateFormulaVariable][0]['formula'] = newFormulaString;
            this.mta = this.modifyAutocalculateFormulaForAsset;
        }
        var x = JSON.stringify(this.mta);
        var qaBeingModifiedId = this.mta.id;
        this.currentTabIndex = this.modifyingAutoCalcFormulaInTab;
        this.currentSectionIndex = this.modifyingAutoCalcFormulaInSection;
        this.mta = JSON.parse(x);
        this.spinner.show();
        this.recursiveRemoveOngoing = true;
        this.updateCurrentTab();
        this.updateChangedAssets();
        this.updateModifiesQasOfFormulaParts(qaBeingModifiedId);
        this.resetAutocalculateFormula();
        this.showAddButtonAfterThisQuestion = this.mta;
    };
    // Validate the autocalculate formula array. Each element should have an attribute of "part"
    Edq2Component.prototype.validateAutocalculateFormulaArray = function () {
        if (!this.autocalculateFormulaArray || this.autocalculateFormulaArray.length == 0) {
            return false;
        }
        else {
            for (var i = 0; i < this.autocalculateFormulaArray.length; i++) {
                if (!this.autocalculateFormulaArray[i].part) {
                    return false;
                }
            }
            return true;
        }
    };
    // Change sequence of the asset in the specified direction
    Edq2Component.prototype.changeAssetSequence = function (asset, direction) {
        if (asset.qa_type == 'question') {
            this.changeQuestionSequence(asset, direction);
        }
        if (asset.qa_type == 'section') {
            this.changeSectionSequence(asset, direction);
        }
        if (asset.qa_type == 'tab') {
            this.changeTabSequence(asset, direction);
        }
        if (asset.qa_type == 'subsection') {
            this.changeSubsectionSequence(asset, direction);
        }
        if (asset.qa_type == 'table') {
            this.changeTableSequence(asset, direction);
        }
        if (asset.qa_type == 'option') {
            this.changeOptionSequence(asset, direction);
        }
    };
    Edq2Component.prototype.changeTabSequence = function (tab, direction) {
        var allChildrenAssets = this.questionnaire;
        var x = allChildrenAssets.find(function (item) { return item.code == tab.code; });
        if (x) {
            var indexOfTab = allChildrenAssets.indexOf(x);
            if (this.validIndex(indexOfTab)) {
                var moveTabToIndex = indexOfTab + direction;
                var destinationAsset = allChildrenAssets[moveTabToIndex];
                var newSequenceOfDestinationAsset = tab.qa_sequence;
                tab.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfTab) && this.validIndex(moveTabToIndex)) {
                    this.questionnaire[indexOfTab] = destinationAsset;
                    this.questionnaire[moveTabToIndex] = tab;
                }
                this.mta = tab;
                this.updateChangedAssets();
                this.mta = destinationAsset;
                this.updateChangedAssets();
                this.initialize(moveTabToIndex, this.currentSectionIndex);
                this.clickOnQuestionDiv(undefined, undefined);
            }
        }
    };
    Edq2Component.prototype.changeSectionSequence = function (section, direction) {
        var parentAsset = this.getParentAsset(section);
        var allChildrenAssets = parentAsset.sections;
        var x = allChildrenAssets.find(function (item) { return item.code == section.code; });
        if (x) {
            var indexOfSection = allChildrenAssets.indexOf(x);
            if (this.validIndex(indexOfSection)) {
                var moveQuestionToIndex = indexOfSection + direction;
                var destinationAsset = parentAsset.sections[moveQuestionToIndex];
                var newSequenceOfDestinationAsset = section.qa_sequence;
                section.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfSection) && this.validIndex(moveQuestionToIndex)) {
                    this.questionnaire[this.currentTabIndex].sections[indexOfSection] = destinationAsset;
                    this.questionnaire[this.currentTabIndex].sections[moveQuestionToIndex] = section;
                }
                this.mta = section;
                this.updateChangedAssets();
                this.mta = destinationAsset;
                this.updateChangedAssets();
                this.initialize(this.currentTabIndex, moveQuestionToIndex);
                this.clickOnQuestionDiv(undefined, undefined);
            }
        }
    };
    Edq2Component.prototype.changeQuestionSequence = function (question, direction) {
        var parentAsset = this.getParentAsset(question);
        var allChildrenAssets = parentAsset.questions;
        var questionBeingModified = allChildrenAssets.find(function (item) { return item.code == question.code; });
        if (questionBeingModified) {
            var indexOfQuestion = allChildrenAssets.indexOf(questionBeingModified);
            if (this.validIndex(indexOfQuestion)) {
                var moveQuestionToIndex = indexOfQuestion + direction;
                var destinationAsset = parentAsset.questions[moveQuestionToIndex];
                var newSequenceOfDestinationAsset = question.qa_sequence;
                question.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfQuestion) && this.validIndex(moveQuestionToIndex)) {
                    if (parentAsset.qa_type == 'section') {
                        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[indexOfQuestion] = destinationAsset;
                        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[moveQuestionToIndex] = question;
                    }
                    else if (parentAsset.qa_type == 'subsection') {
                        var parentSubsection = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.find(function (item) { return item.code == parentAsset.code; });
                        if (parentSubsection) {
                            var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.indexOf(parentSubsection);
                            if (this.validIndex(index)) {
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[index].questions[indexOfQuestion] = destinationAsset;
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[index].questions[moveQuestionToIndex] = question;
                            }
                        }
                    }
                    else if (parentAsset.qa_type == 'table') {
                        var parentSubsection = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.find(function (item) { return item.code == parentAsset.code; });
                        if (parentSubsection) {
                            var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.indexOf(parentSubsection);
                            if (this.validIndex(index)) {
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[index].questions[indexOfQuestion] = destinationAsset;
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[index].questions[moveQuestionToIndex] = question;
                            }
                        }
                    }
                }
                this.mta = question;
                this.updateChangedAssets();
                this.mta = destinationAsset;
                this.updateChangedAssets();
                this.initialize(this.currentTabIndex, this.currentSectionIndex);
                this.clickOnQuestionDiv(undefined, undefined);
                var el = document.querySelector(".active-question");
                this.rightPanelScroll(el);
                this.showAddButtonAfterThisQuestion = question;
            }
        }
    };
    Edq2Component.prototype.changeOptionSequence = function (option, direction) {
        var x = JSON.parse(JSON.stringify(this.mta.options));
        var allChildrenAssets = x;
        var optionBeingModified = allChildrenAssets.find(function (item) { return item.code == option.code; });
        if (optionBeingModified) {
            var indexOfOption = allChildrenAssets.indexOf(optionBeingModified);
            if (this.validIndex(indexOfOption)) {
                var moveOptionToIndex = indexOfOption + direction;
                var destinationAsset = x[moveOptionToIndex];
                var newSequenceOfDestinationAsset = option.qa_sequence;
                option.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfOption) && this.validIndex(moveOptionToIndex)) {
                    x[indexOfOption] = destinationAsset;
                    x[moveOptionToIndex] = option;
                    this.mta.options = JSON.parse(JSON.stringify(x));
                    this.initialize(this.currentTabIndex, this.currentSectionIndex);
                }
            }
        }
    };
    Edq2Component.prototype.changeSubsectionSequence = function (subsection, direction) {
        var parentAsset = this.getParentAsset(subsection);
        var allChildrenAssets = parentAsset.subsections;
        var subsectionBeingModified = allChildrenAssets.find(function (item) { return item.code == subsection.code; });
        if (subsectionBeingModified) {
            var indexOfSubsection = allChildrenAssets.indexOf(subsectionBeingModified);
            if (this.validIndex(indexOfSubsection)) {
                var moveSubsectionToIndex = indexOfSubsection + direction;
                var destinationAsset = parentAsset.subsections[moveSubsectionToIndex];
                var newSequenceOfDestinationAsset = subsection.qa_sequence;
                subsection.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfSubsection) && this.validIndex(moveSubsectionToIndex)) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[indexOfSubsection] = destinationAsset;
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[moveSubsectionToIndex] = subsection;
                }
                this.mta = subsection;
                this.updateChangedAssets();
                this.mta = destinationAsset;
                this.updateChangedAssets();
                this.initialize(this.currentTabIndex, this.currentSectionIndex);
                this.clickOnQuestionDiv(undefined, undefined);
            }
        }
    };
    Edq2Component.prototype.changeTableSequence = function (table, direction) {
        var parentAsset = this.getParentAsset(table);
        var allChildrenAssets = parentAsset.tables;
        var tableBeingModified = allChildrenAssets.find(function (item) { return item.code == table.code; });
        if (tableBeingModified) {
            var indexOfTable = allChildrenAssets.indexOf(tableBeingModified);
            // console.log('indexOfTable', indexOfTable);
            if (this.validIndex(indexOfTable)) {
                var moveTableToIndex = indexOfTable + direction;
                var destinationAsset = parentAsset.tables[moveTableToIndex];
                var newSequenceOfDestinationAsset = table.qa_sequence;
                table.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfTable) && this.validIndex(moveTableToIndex)) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[indexOfTable] = destinationAsset;
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[moveTableToIndex] = table;
                }
                this.mta = table;
                this.updateChangedAssets();
                this.mta = destinationAsset;
                this.updateChangedAssets();
                this.initialize(this.currentTabIndex, this.currentSectionIndex);
                this.clickOnQuestionDiv(undefined, undefined);
            }
        }
    };
    // Add asset of specified type at the given index
    Edq2Component.prototype.addAsset = function (assetType, relativeToAsset, relativeToAssetIndex, direction, template, isChildOfTable, comingFromSectionWizardPanel2) {
        var _a;
        if (assetType != 'tab' && (!relativeToAsset.parent_id || relativeToAsset.parent_id == 0)) {
            return;
        }
        // If the addAsset is being called from Wizard panel 2 (i.e. immediately after adding / editing a section), then the relativeToAsset should be the
        // last asset of that type in that section - so reset the value of relativeToAsset, relativeToAssetIndex accordingly
        if (comingFromSectionWizardPanel2) {
            if (assetType == 'question' && this.currentSection && this.currentSection.questions && this.currentSection.questions.length > 0) {
                relativeToAsset = this.currentSection.questions[this.currentSection.questions.length - 1];
                relativeToAssetIndex = this.currentSection.questions.length - 1;
            }
            if (assetType == 'subsection' && this.currentSection && this.currentSection.subsections && this.currentSection.subsections.length > 0) {
                relativeToAsset = this.currentSection.subsections[this.currentSection.subsections.length - 1];
                relativeToAssetIndex = this.currentSection.subsections.length - 1;
            }
            if (assetType == 'table' && this.currentSection && this.currentSection.tables && this.currentSection.tables.length > 0) {
                relativeToAsset = this.currentSection.tables[this.currentSection.tables.length - 1];
                relativeToAssetIndex = this.currentSection.tables.length - 1;
            }
        }
        var assetToAdd = (_a = {
                survey_id: this.surveyId,
                code: uuidv4(),
                qa_type: assetType,
                parent_id: relativeToAsset.parent_id,
                parent_code: relativeToAsset.parent_code,
                qa_sequence: this.getRelativeSequenceNew(relativeToAsset, direction),
                qa_subtype: this.getDefaultSubtype(assetType)
            },
            _a[this.labelVariable] = this.initializeSurveyAssetLabels(),
            _a[this.validationVariable] = [],
            _a[this.displayLogicVariable] = [],
            _a[this.autocalculateFormulaVariable] = [],
            _a.is_table_question = isChildOfTable ? true : false,
            _a);
        this.modifyThisAsset(assetToAdd, template, assetType); // Launch the wizard
        this.relativeToAssetIndex = relativeToAssetIndex;
        this.questionAddEdit = this.addEditModeObj['add_mode'];
    };
    // Find the sibling assets to relativeToAsset
    Edq2Component.prototype.getRelativeSequence = function (relativeToAsset, direction) {
        // Check if an asset with qa_sequence = relativeToAsset + direction exists
        // If it does not, return relativeToAsset + direction
        // If it does, check relativeToAsset + direction + direction.. until you find an unclaimed qa_sequence number.
        if (relativeToAsset.qa_sequence == null) {
            return 0;
        }
        else {
            var siblingAssets = [];
            if (relativeToAsset.qa_type == 'section') {
                siblingAssets = this.currentTab.sections.filter(function (item) { return item.id != relativeToAsset.id; });
            }
            if (relativeToAsset.qa_type == 'question') {
                var parentAsset = this.getParentAsset(relativeToAsset);
                siblingAssets = parentAsset.questions;
            }
            if (relativeToAsset.qa_type == 'subsection') {
                var parentAsset = this.getParentAsset(relativeToAsset);
                siblingAssets = parentAsset.subsections;
            }
            if (relativeToAsset.qa_type == 'table') {
                var parentAsset = this.getParentAsset(relativeToAsset);
                siblingAssets = parentAsset.tables;
            }
            var sequenceToCheck_1 = relativeToAsset.qa_sequence + direction;
            var sequenceIsAlreadyClaimed = siblingAssets.find(function (item) { return sequenceToCheck_1 != relativeToAsset.qa_sequence && item.qa_sequence == sequenceToCheck_1; });
            if (!sequenceIsAlreadyClaimed) {
                return relativeToAsset.qa_sequence + direction;
            }
            else {
                return sequenceIsAlreadyClaimed.qa_sequence;
            }
        }
    };
    // Get the default subtype for given asset type. It is the 1st element of the substypes array
    Edq2Component.prototype.getDefaultSubtype = function (assetType) {
        var subtypes = this.assetTypes.find(function (item) { return item.type == assetType; })['subTypes'];
        if (subtypes && subtypes.length > 0) {
            return subtypes[0].key;
        }
        else {
            return null;
        }
    };
    // Add / remove the mta asset
    Edq2Component.prototype.changeAsset = function (action, calledFromMarkChildrenRecursively, calledFromChangeTabAsset) {
        var _this = this;
        var firstCall = false; // firstCall is the variable which stores whether this is the 1st call of this function
        // If it is the 1st call, then start the spinner & introduce a delay for the recursive call
        if (!calledFromMarkChildrenRecursively && !calledFromChangeTabAsset) {
            firstCall = true;
        }
        if (firstCall) {
            if (this.mta && !this.removalConfirmed) {
                if (this.mta.qa_type === 'tab' || this.mta.qa_type === 'section' || this.mta.qa_type === 'subsection' || this.mta.qa_type === 'table') {
                    if (action === 'remove') {
                        this.mtaTopLevelParentToRemoveString = JSON.stringify(this.mta);
                        var x = this.checkIfMTAChildCannotBeRemoved();
                        // console.log('this tab/section/subsection/table removal status', x);
                        if (x) {
                            // Display the confirmation modal
                            this.promptDeletionOfChildAssetsModal = this.modalService.show(this.promptDeletionOfChildAssetsTemp, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
                            return;
                        }
                    }
                }
                else if (this.mta.qa_type === 'question') {
                    if (action === 'delete' || action === 'remove') {
                        var y = this.checkIfQuestionMTACannotBeRemoved(action);
                        if (y) {
                            return;
                        }
                    }
                }
            }
            this.spinner.show();
            setTimeout(function () {
                _this.changeAssetFn(action, calledFromMarkChildrenRecursively, calledFromChangeTabAsset);
            }, 1000);
        }
        else {
            this.changeAssetFn(action, calledFromMarkChildrenRecursively, calledFromChangeTabAsset);
        }
    };
    // Common function to handle add/remove actions on a given asset
    Edq2Component.prototype.changeAssetFn = function (action, calledFromMarkChildrenRecursively, calledFromChangeTabAsset) {
        if (this.mta.qa_type === 'question') {
            if (this.removalConfirmed) {
                this.mta[this.displayLogicVariable] = [];
                this.mta[this.autocalculateFormulaVariable] = [];
            }
        }
        if (this.mta.qa_type === 'tab' && !calledFromChangeTabAsset) {
            this.changeTabAsset(action, calledFromMarkChildrenRecursively);
            return;
        }
        if (action === 'remove') {
            this.mta.removed_from_questionnaire = true;
        }
        else if (action === 'delete') {
            this.mta.deleted = true;
        }
        else { // action == added
            this.mta.removed_from_questionnaire = false;
        }
        if (this.mta.qa_type === 'question' && this.mta.options && this.mta.options.length > 0) {
            this.markChildOptions(action);
        }
        // this.mta.removed_from_questionnaire = action === 'remove' ? true : false;
        this.updateChangedAssets();
        this.updateCurrentTab();
        // If the action is removal, start the recursive removal if this function is not already being called from the recursive removal function
        if (this.mta.qa_type !== 'tab' && !calledFromMarkChildrenRecursively) {
            // this.spinner.show();
            this.recursiveRemoveOngoing = true; // Set this variable so API call to save asset changes is blocked until recursive removal completes
            this.markChildrenRecursively(this.mta, action);
        }
    };
    // Recursive removal function
    // If the mta has any children of type as defined in assets array, remvoe them recursively
    Edq2Component.prototype.markChildrenRecursively = function (asset, action) {
        for (var i = 0; i < this.assetTypes.length; i++) {
            var key = this.assetTypes[i].type + 's';
            if (key !== 'options') { // we do not want to mark options as deleted / removed recursively. There is a separate changeOptions function which removes option assets
                if (asset[key]) {
                    for (var j = 0; j < asset[key].length; j++) {
                        this.mta = asset[key][j];
                        this.changeAsset(action, true);
                        this.markChildrenRecursively(asset[key][j], action);
                    }
                }
                else {
                    // Once the mta has no further children of any type as defined in assets array, stop the recursive removal
                    this.recursiveRemoveOngoing = false;
                    // this.spinner.hide();
                }
            }
        }
        this.mta = undefined;
    };
    // Close the wizard
    Edq2Component.prototype.closeModifyingAssetTemplateModal = function () {
        if (this.okayToCancelMTAModal()) {
            this.mta = undefined;
            this.resetWizardDisplayedPanel();
            this.resetwizardFlowOngoing();
            this.manageUnits = false;
            if (this.modifyingAssetModal) {
                this.modifyingAssetModal.hide();
            }
        }
    };
    // TODO: Remove this after testing phase
    Edq2Component.prototype.cancelModifyingQuestion = function () {
        this.modifyingThisQuestion = undefined;
    };
    // Save changes to DB
    Edq2Component.prototype.saveQuestionnaireChanges = function (initiateWizardFlow) {
        var _this = this;
        if (this.promptDeletionOfChildAssetsModal) {
            this.promptDeletionOfChildAssetsModal.hide();
        }
        var x = JSON.parse(JSON.stringify(this.changedAssets));
        // console.log('saveQuestionnaireChanges called for', JSON.stringify(x));
        this.changedAssets = [];
        // this.spinner.show();
        if (this.p2fTemplate) {
            // Send the changed assets to the API
            this.surveyService.saveP2FTemplateChanges(x)
                .subscribe(function (result) {
                // Unset the clearinterval variable, so another call can now be made 
                window.clearInterval(_this.saveChangedAssetsInterval);
                _this.saveChangedAssetsInterval = undefined;
                // If wizard flow is not ongoing, set the displayed panel to 1 (wizard has 2 panels - e.g. add question (panel 1), add options in question (panel 2))
                if (!_this.wizardFlowOngoing) {
                    _this.resetWizardDisplayedPanel();
                }
                ;
                _this.spinner.hide();
                _this.declineDeletion();
                _this.removalConfirmed = false;
                if (result.code == 'success') {
                    // If the changed assts were saved to DB successfully, swap the newly saved assets into the questinnaire object
                    _this.newerSwapInNewAssetIntoQuestionnaire(result['message'], x);
                    // If the wizard flow has been initiated, then take the wizard flow action
                    if (initiateWizardFlow) {
                        _this.wizardFlowAction(result['message'][0]);
                    }
                }
                if (result.code == 'fail') {
                    // TODO: Done: Caught in error handler. Let user know about errors
                    _this.commonService.showToast('error', 'generic_error', {});
                }
            });
        }
        else {
            // Send the changed assets to the API
            this.surveyService.saveQuestionnaireChanges(this.surveyId, x)
                .subscribe(function (result) {
                // Unset the clearinterval variable, so another call can now be made 
                window.clearInterval(_this.saveChangedAssetsInterval);
                _this.saveChangedAssetsInterval = undefined;
                // If wizard flow is not ongoing, set the displayed panel to 1 (wizard has 2 panels - e.g. add question (panel 1), add options in question (panel 2))
                if (!_this.wizardFlowOngoing) {
                    _this.resetWizardDisplayedPanel();
                }
                ;
                _this.spinner.hide();
                _this.declineDeletion();
                _this.removalConfirmed = false;
                if (result.code == 'success') {
                    _this.showSuccessMessage(result['message']);
                    // If the changed assts were saved to DB successfully, swap the newly saved assets into the questinnaire object
                    _this.newerSwapInNewAssetIntoQuestionnaire(result['message'], x);
                    //if uom_question then set it as first element in array
                    result['message'].forEach(function (item, i) {
                        if (item.qa_type === "uom_question") {
                            result['message'].splice(i, 1);
                            result['message'].unshift(item);
                        }
                    });
                    // If the wizard flow has been initiated, then take the wizard flow action
                    if (initiateWizardFlow) {
                        _this.wizardFlowAction(result['message'][0]);
                    }
                }
                if (result.code == 'fail') {
                    // TODO: Done: Caught in error handler. Let user know about errors
                    _this.commonService.showToast('error', 'generic_error', {});
                }
            });
        }
    };
    // After the questionnaire changes are saved get the latest version of the questionnaire & re-render on UI
    // TODO: This is no longer being used. Remove after the testing phase
    Edq2Component.prototype.updateQuestionnaireAfterSaving = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([
                                this.surveyService.getSurveyQuestionnaire(this.surveyId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['message']) {
                            // delete this.questionnaire;
                            this.reloadingQuestionnaire = true;
                            this.questionnaire = undefined;
                            this.ref.detectChanges();
                            setTimeout(function () {
                                _this.questionnaire = Object.assign([], JSON.parse(JSON.stringify(request[0]['message'])));
                                if (request[0]['message'] && request[0]['message'].length > 0) {
                                    _this.isBlankQuestionnaire = false;
                                    _this.ref.detectChanges();
                                }
                                _this.initialize(_this.currentTabIndex, _this.currentSectionIndex);
                            }, 500);
                            // this.ref.detectChanges();
                            this.changedAssets = [];
                            this.spinner.hide();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // When save button is clicked on asset modification modal
    Edq2Component.prototype.saveAssetChanges = function (initiateWizardFlow) {
        // Set variable for whether primary action flow is ongoing
        this.wizardFlowOngoing = initiateWizardFlow;
        // Wizard flow is initiated but no changes made in Panel 1. So just display Panel 2
        if (this.wizardFlowOngoing && !this.wizardPanel1Updated && this.wizardDisplayedPanel == 1 && this.mta.qa_type == 'question') {
            if (this.mta.qa_subtype === 'select' || this.mta.qa_subtype === 'multiselect' ||
                (this.mta.qa_subtype === 'number' && this.manageUnits)) {
                this.wizardDisplayedPanel = 2;
                return;
            }
        }
        // Preprocess the MTA depending on question
        this.preProcessMTA();
        // Validate the MTA
        if (this.validateMTA()) {
            this.spinner.show();
            // Update the MTA asset
            this.updateChangedAssets(initiateWizardFlow);
            this.updateCurrentTab();
            // Close the modal if wizard flow is NOT ongoing
            if (!initiateWizardFlow) {
                this.closeModifyingAssetTemplateModal();
            }
            else {
                setTimeout(function () {
                    var a = document.querySelector('.wizard-input-box');
                    if (a) {
                        a.focus();
                    }
                }, 1000);
            }
            // Un-disable the cancellation of the modal
            if (this.disableCancelOfModifyingAssetModal) {
                this.disableCancelOfModifyingAssetModal = false;
            }
        }
        else {
            var parentScroll = document.getElementById('popupFormBox');
            if (parentScroll) {
                parentScroll.scroll({ top: 0, behavior: "smooth" });
            }
        }
    };
    // Add the issue to mtaIsuses array
    Edq2Component.prototype.addToMTAIssues = function (issue) {
        if (!this.mtaIssues.find(function (item) { return item.code == issue.code; })) {
            this.mtaIssues.push(issue);
        }
        if (this.mtaIssues && this.mtaIssues.length > 0) {
            this.translateMTAIssues();
        }
    };
    // TODO: Should the return be at the end of this function after checking all validity conditions
    // Valiate the MTA before committing the changes
    Edq2Component.prototype.validateMTA = function () {
        this.mtaIssues = [];
        // If qa_subtype is select or multiselect, it must have at least 1 option
        // if (this.mta.id && (this.mta.qa_subtype == 'select' || this.mta.qa_subtype == 'multiselect') && (!this.mta.options || this.mta.options.length == 0 || !this.mta.options.find(item => !item.removed_from_questionnaire))) {
        //   this.addToMTAIssues({code: 1, text: 'Must have at least 1 option'});
        //   return false;
        // }  
        // If qa_subtype is text or number, it must have 0 options
        if (this.mta.id && (this.mta.qa_subtype == 'text' || this.mta.qa_subtype == 'number') && (this.mta.options && this.mta.options.length > 0 && this.mta.options.find(function (item) { return !item.removed_from_questionnaire; }))) {
            this.addToMTAIssues({ code: 2, text: 'Please remove all options' });
        }
        // If mta has uom_question the mta must have a valid qa_subtype
        if (this.mta.id && !(this.mta.qa_subtype == 'text' || this.mta.qa_subtype == 'number') && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
            this.addToMTAIssues({ code: 3, text: 'Question must be of type Number or Text' });
        }
        // Must not have duplicated validators
        if (this.mta.id && this.mta[this.validationVariable] && this.mta[this.validationVariable].length > 0) {
            if (this.hasObjectWithDuplicates(this.mta[this.validationVariable], 'validation_key')) {
                this.addToMTAIssues({ code: 4, text: 'Only one validator of each type is permitted' });
            }
        }
        // Must not have duplicated uom_questions (two uom_questions of the same qa_subtype)
        if (this.mta.id && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
            if (this.hasObjectWithDuplicates(this.mta.uom_questions, 'qa_subtype')) {
                this.addToMTAIssues({ code: 5, text: 'Only one unit of measurement of each type is permitted' });
            }
        }
        // Each validator must have a not-null not-blank value
        if (this.mta.id && this.mta[this.validationVariable] && this.mta[this.validationVariable].length > 0) {
            if (this.hasObjectWithBlankValue(this.mta[this.validationVariable], 'validation_value')) {
                this.addToMTAIssues({ code: 6, text: 'Each validator must have a value' });
            }
            if (this.hasObjectWithBlankValue(this.mta[this.validationVariable], 'validation_key')) {
                this.addToMTAIssues({ code: 7, text: 'Each validator must have a key' });
            }
        }
        // If the unit qa_subtype or label is blank, throw an error
        if (this.mta.id && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
            var x = [];
            for (var i = 0; i < this.mta.uom_questions.length; i++) {
                x = x.concat(this.mta.uom_questions[i][this.labelVariable]);
            }
            if (this.hasObjectWithBlankValue(x, 'label')) {
                this.addToMTAIssues({ code: 8, text: 'Each unit must have a label. Set default "Unit"' });
            }
            if (this.hasObjectWithBlankValue(this.mta.uom_questions, 'qa_subtype')) {
                this.addToMTAIssues({ code: 9, text: 'Please select a unit type for each unit of measurement' });
            }
        }
        // Units are permitted only in text & number type questions
        if (this.mta.id && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
            if (this.mta.qa_subtype != 'text' && this.mta.qa_subtype != 'number') {
                this.addToMTAIssues({ code: 10, text: 'Units are permitted for questions of type "Text" and "Number"' });
            }
        }
        // Validate that each of the labels have non-empty string as label
        if (this.hasObjectWithBlankValue(this.mta[this.labelVariable].filter(function (item) { return item.language_id == 1; }), 'label')) {
            this.addToMTAIssues({ code: 11, text: 'Please enter a label' });
        }
        // Validate that each of the options has a label
        // let x = [];
        // if (this.mta.options && this.mta.options.length > 0) {
        //   for (let i = 0; i < this.mta.options.length; i++) {
        //     if (this.mta.options[i][this.labelVariable] && this.mta.options[i][this.labelVariable].length > 0) {
        //       for (let j = 0; j < this.mta.options[i][this.labelVariable].length; j++) {
        //         x.push(this.mta.options[i][this.labelVariable][j]);
        //       }
        //     }
        //   }
        // }
        // if (this.hasObjectWithBlankValue(x, 'label')) {
        //   this.addToMTAIssues({code: 12, text: 'Please enter a label for each option'});
        //     return false;
        // }
        // Validate that both disabled & mandatory options are not selected for the question
        if (this.mta.disable_input && this.mta.isMandatory) {
            this.addToMTAIssues({ code: 13, text: 'Cannot select both "Required" & "Disable input" options at once' });
        }
        // Validate that MTA has valid language_id in labels
        for (var i = 0; i < this.mta[this.labelVariable].length; i++) {
            if (!this.mta[this.labelVariable][i].language_id) {
                this.addToMTAIssues({ code: 14, text: 'Please cancel and try making the change again.' });
            }
        }
        // Validate that MTA options if any have valid language_id in labels
        if (this.mta.options && this.mta.options.length > 0) {
            for (var j = 0; j < this.mta.options.length; j++) {
                for (var i = 0; i < this.mta.options[j][this.labelVariable].length; i++) {
                    if (!this.mta.options[j][this.labelVariable][i].language_id) {
                        this.addToMTAIssues({ code: 22, text: 'Please cancel and try making the change again.' });
                    }
                }
            }
        }
        // Validate that the label is 300 characters or less
        for (var i = 0; i < this.mta[this.labelVariable].length; i++) {
            if (this.mta[this.labelVariable][i].label && this.mta[this.labelVariable][i].label.length > 300) {
                this.addToMTAIssues({ code: 15, text: 'One or more label length exceeds limit: 300.' });
            }
        }
        // Validate that the MTA Option label is 300 characters or less
        if (this.mta.options && this.mta.options.length > 0) {
            for (var j = 0; j < this.mta.options.length; j++) {
                for (var i = 0; i < this.mta.options[j][this.labelVariable].length; i++) {
                    if (this.mta.options[j][this.labelVariable][i].label && this.mta.options[j][this.labelVariable][i].label.length > 300) {
                        this.addToMTAIssues({ code: 16, text: 'One or more label length exceeds limit: 300' });
                    }
                }
            }
        }
        if (this.mta.qa_subtype && typeof (this.mta.qa_subtype) != 'string') {
            this.addToMTAIssues({ code: 17, text: 'Please select a valid value for "Type"' });
        }
        // Type of question is mandatory
        if (this.mta.qa_type === 'question' && !this.mta.qa_subtype) {
            this.addToMTAIssues({ code: 18, text: 'Please select type of question' });
        }
        if ((this.mta.qa_subtype === 'select' || this.mta.qa_subtype === 'multiselect') &&
            (!this.mta.options || this.mta.options.length == 0) && !this.mta.has_dynamic_options &&
            (!this.wizardFlowOngoing || this.wizardDisplayedPanel === 2)) {
            this.addToMTAIssues({ code: 19, text: 'Please add options for this question by clicking on "Save & Manage Options"' });
        }
        if ((this.mta.qa_subtype === 'select' || this.mta.qa_subtype === 'multiselect') && this.mta.has_dynamic_options && this.mta.options && this.mta.options.length > 0) {
            this.addToMTAIssues({ code: 20, text: 'Options cannot be added for this question as it has dynamically generated options' });
        }
        if ((this.mta.qa_subtype === 'select' || this.mta.qa_subtype === 'multiselect') && this.mta.options && this.mta.options.length > 0) {
            // Validate that each of the option labels have non-empty string as label
            for (var i = 0; i < this.mta.options.length; i++) {
                if (this.hasObjectWithBlankValue(this.mta.options[i][this.labelVariable].filter(function (item) { return item.language_id == 1; }), 'label')) {
                    this.addToMTAIssues({ code: 21, text: 'Please enter a label for option' });
                }
            }
        }
        if (this.mtaIssues.length > 0) {
            return false;
        }
        else {
            return true;
        }
    };
    // Checks if array has an object with duplicate values of attributeToCheck
    Edq2Component.prototype.hasObjectWithDuplicates = function (array, attributeToCheck) {
        var valueArr = [];
        array.forEach(function (element) {
            if (!element.removed_from_questionnaire) {
                valueArr.push(element[attributeToCheck]);
            }
        });
        var isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx;
        });
        return isDuplicate;
    };
    // Checks if array has an object with blank value of attributeToCheck
    Edq2Component.prototype.hasObjectWithBlankValue = function (array, attributeToCheck) {
        for (var i = 0; i < array.length; i++) {
            if (!array[i][attributeToCheck] || array[i][attributeToCheck] == '') {
                return true;
            }
        }
        return false;
    };
    // Change whether the question is mandatory or not
    Edq2Component.prototype.changeMandatory = function (event) {
        if (event.target.checked) {
            if (this.mta[this.validationVariable].find(function (item) { return item.validation_key == 'mandatory'; })) {
            }
            else {
                var validationToAdd = {
                    questionnaire_asset_id: this.mta.id,
                    validation_key: 'mandatory',
                    validation_value: 'true'
                };
                this.mta[this.validationVariable].push(validationToAdd);
            }
        }
        else {
            if (this.mta[this.validationVariable].find(function (item) { return item.validation_key == 'mandatory'; })) {
                this.mta[this.validationVariable] = this.mta[this.validationVariable].filter(function (item) { return item.validation_key != 'mandatory'; });
            }
        }
    };
    //change min and max value
    Edq2Component.prototype.changeMin = function (event) {
        if (event.target.value) {
            var isMin = this.mta[this.validationVariable].find(function (item) { return item.validation_key == 'min_value'; });
            if (isMin) {
                isMin.validation_value = event.target.value;
            }
            else {
                var validationToAdd = {
                    questionnaire_asset_id: this.mta.id,
                    validation_key: 'min_value',
                    validation_value: event.target.value
                };
                this.mta[this.validationVariable].push(validationToAdd);
            }
        }
        else {
            if (this.mta[this.validationVariable].find(function (item) { return item.validation_key == 'min_value'; })) {
                this.mta[this.validationVariable] = this.mta[this.validationVariable].filter(function (item) { return item.validation_key != 'min_value'; });
            }
        }
    };
    Edq2Component.prototype.changeMax = function (event) {
        if (event.target.value) {
            var isMax = this.mta[this.validationVariable].find(function (item) { return item.validation_key == 'max_value'; });
            if (isMax) {
                isMax.validation_value = event.target.value;
            }
            else {
                var validationToAdd = {
                    questionnaire_asset_id: this.mta.id,
                    validation_key: 'max_value',
                    validation_value: event.target.value
                };
                this.mta[this.validationVariable].push(validationToAdd);
            }
        }
        else {
            if (this.mta[this.validationVariable].find(function (item) { return item.validation_key == 'max_value'; })) {
                this.mta[this.validationVariable] = this.mta[this.validationVariable].filter(function (item) { return item.validation_key != 'max_value'; });
            }
        }
    };
    // Swap each item in assets array into the questionnaire array, i.e replace the original with the item in assets array (which now has id)
    Edq2Component.prototype.newerSwapInNewAssetIntoQuestionnaire = function (assets, allChangedAssetsSaved) {
        for (var i = 0; i < allChangedAssetsSaved.length; i++) {
            if (allChangedAssetsSaved[i].deleted) {
                assets.push(allChangedAssetsSaved[i]);
            }
        }
        // console.log('assets to swap', assets);
        for (var i = 0; i < assets.length; i++) {
            this.getNodeIndicesAndSwap(assets[i], {}, true);
        }
    };
    /**
     * Swap the asset recursively in the asset tree
     */
    Edq2Component.prototype.getNodeIndicesAndSwap = function (asset, indicesObject, isNonRecursiveCaller) {
        // Set the original asset value if this is being called non recursively
        if (isNonRecursiveCaller) {
            this.originalAsset = JSON.parse(JSON.stringify(asset));
        }
        // Start with key = asset's qa_type. E.g. if swapping in question, start with key = 'questions'
        var key = asset.qa_type + 's';
        // Get the asset's parent
        var parent = this.getParentAsset(asset);
        // If the parent has no children of type key & key != tabs , then add asset to the children array
        if (parent && (!parent[key] || parent[key].length == 0) && key != 'tabs') {
            parent[key] = [];
            parent[key].push(asset);
        }
        // If the parent does have children of type key, then find the index of asset & add it to indicesObject
        if (parent && parent[key]) {
            var assetToSwapOut = parent[key].find(function (item) { return item.code == asset.code; });
            if (assetToSwapOut) {
                var index = parent[key].indexOf(assetToSwapOut);
                if (this.validIndex(index)) {
                    indicesObject[key] = index;
                }
            }
            // Run the function for the parent asset now (this is the recursive call)
            this.getNodeIndicesAndSwap(parent, indicesObject);
        }
        else {
            // If parent has no more children of type == key
            // If the key = tabs, you have reached the end of the recursive calls. Add the tab's index to indicesObject
            if (key == 'tabs') {
                var assetToSwapOut = this.questionnaire.find(function (item) { return item.code == asset.code; });
                if (assetToSwapOut) {
                    var indexOfTab = this.questionnaire.indexOf(assetToSwapOut);
                    if (this.validIndex(indexOfTab)) {
                        indicesObject[key] = indexOfTab;
                    }
                }
            }
            // Once the indicesObject is ready, pass it to swapFunction to perform the actual swap
            // indicesObject should look like {tabs: 0, sections: 2, questions: 3, options: 2} etc. - this will make the swap for ...
            // option at index 2 in question of index 3 in section of index 2 in tab of index 0
            if (indicesObject && Object.keys(indicesObject) && Object.keys(indicesObject).length > 0) {
                this.swapFunction(indicesObject);
            }
        }
    };
    // Get an indicesObject & swap in the asset into the correct index, depending on the type of asset
    // "Swap in" means set the updateableAttributes in questionnaire array based on the originalAsset. Not actually replace the object with originalAsset
    Edq2Component.prototype.swapFunction = function (indicesObject) {
        var _this = this;
        var tabIndex = indicesObject['tabs'];
        var sectionIndex = indicesObject['sections'];
        var subsectionIndex = indicesObject['subsections'];
        var tableIndex = indicesObject['tables'];
        var questionIndex = indicesObject['questions'];
        var optionIndex = indicesObject['options'];
        var uomQuestionIndex = indicesObject['uom_questions'];
        var otherQuestionIndex = indicesObject['other_questions'];
        var asset = JSON.parse(JSON.stringify(this.originalAsset));
        this.originalAsset = undefined;
        if (asset.qa_type === 'tab') {
            if (!this.questionnaire[tabIndex]) {
                this.questionnaire[tabIndex] = asset;
            }
            else {
                this.updateableAttributes.forEach(function (attribute) {
                    _this.questionnaire[tabIndex][attribute] = asset[attribute];
                });
            }
        }
        if (asset.qa_type === 'section') {
            if (!this.questionnaire[tabIndex].sections[sectionIndex]) {
                this.questionnaire[tabIndex].sections[sectionIndex] = asset;
            }
            else {
                this.updateableAttributes.forEach(function (attribute) {
                    _this.questionnaire[tabIndex].sections[sectionIndex][attribute] = asset[attribute];
                });
            }
        }
        if (asset.qa_type === 'subsection') {
            if (!this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex]) {
                this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex] = asset;
            }
            else {
                this.updateableAttributes.forEach(function (attribute) {
                    _this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex][attribute] = asset[attribute];
                });
            }
        }
        if (asset.qa_type === 'table') {
            if (!this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex]) {
                this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex] = asset;
            }
            else {
                this.updateableAttributes.forEach(function (attribute) {
                    _this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex][attribute] = asset[attribute];
                });
            }
        }
        if (asset.qa_type === 'question') {
            if (subsectionIndex != null) {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex][attribute] = asset[attribute];
                    });
                }
            }
            else if (tableIndex != null) {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex][attribute] = asset[attribute];
                    });
                }
            }
            else {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex][attribute] = asset[attribute];
                    });
                }
            }
        }
        if (asset.qa_type === 'option') {
            if (subsectionIndex != null) {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex].options[optionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex].options[optionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex].options[optionIndex][attribute] = asset[attribute];
                    });
                }
            }
            else if (tableIndex != null) {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex].options[optionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex].options[optionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex].options[optionIndex][attribute] = asset[attribute];
                    });
                }
            }
            else {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex].options[optionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex].options[optionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex].options[optionIndex][attribute] = asset[attribute];
                    });
                }
            }
        }
        if (asset.qa_type === 'uom_question') {
            if (subsectionIndex != null) {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex].uom_questions[uomQuestionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex].uom_questions[uomQuestionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex].uom_questions[uomQuestionIndex][attribute] = asset[attribute];
                    });
                }
            }
            else if (tableIndex != null) {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex].uom_questions[uomQuestionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex].uom_questions[uomQuestionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex].uom_questions[uomQuestionIndex][attribute] = asset[attribute];
                    });
                }
            }
            else {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex].uom_questions[uomQuestionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex].uom_questions[uomQuestionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex].uom_questions[uomQuestionIndex][attribute] = asset[attribute];
                    });
                }
            }
        }
        if (asset.qa_type === 'other_question') {
            if (subsectionIndex != null) {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex].other_questions[otherQuestionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex].other_questions[otherQuestionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].subsections[subsectionIndex].questions[questionIndex].other_questions[otherQuestionIndex][attribute] = asset[attribute];
                    });
                }
            }
            else if (tableIndex != null) {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex].other_questions[otherQuestionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex].other_questions[otherQuestionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].tables[tableIndex].questions[questionIndex].other_questions[otherQuestionIndex][attribute] = asset[attribute];
                    });
                }
            }
            else {
                if (!this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex].other_questions[otherQuestionIndex]) {
                    this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex].other_questions[otherQuestionIndex] = asset;
                }
                else {
                    this.updateableAttributes.forEach(function (attribute) {
                        _this.questionnaire[tabIndex].sections[sectionIndex].questions[questionIndex].other_questions[otherQuestionIndex][attribute] = asset[attribute];
                    });
                }
            }
        }
        this.ref.detectChanges();
        // After maing the swap, call initialize again & set the proper values of currentTab, currentSection
        var initializeTabAt = this.currentTabIndex;
        var initializeSectionAt = this.currentSectionIndex;
        var initializingBlankQuestionnaire = false;
        if (asset.qa_type === 'tab' && this.questionnaire[this.currentTabIndex].deleted) {
            var nonDeletedTabs = this.questionnaire.filter(function (item) { return !item.deleted; });
            if (nonDeletedTabs) {
                // All the tabs are deleted - so initiate a blank questionnaire
                if (nonDeletedTabs.length === 0) {
                    initializingBlankQuestionnaire = true;
                }
                else {
                    // Some tabs are not deleted
                    if (initializeTabAt === nonDeletedTabs.length) {
                        // Scenario 1: profitability deleted. Livelihood, agriculture are still there => Move to agriculture (previous) tab
                        // initializeTabAt = 2. nonDeletedTabs.length = 2
                        initializeTabAt--;
                    }
                    else if (initializeTabAt < nonDeletedTabs.length) {
                        // Scenario 2: agriculture deleted. Livelihood, profitability are still there => Move to profitability (next) tab
                        // initializeTabAt = 1. nonDeletedTabs.length = 2
                        initializeTabAt++;
                    }
                }
            }
        }
        if (asset.qa_type === 'section' && this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].deleted) {
            initializeSectionAt++;
            if (initializeSectionAt >= this.questionnaire[this.currentTabIndex].sections.length) {
                if (initializeTabAt < this.questionnaire.length - 1) {
                    initializeTabAt++;
                }
                initializeSectionAt = 0;
            }
        }
        if (initializingBlankQuestionnaire) {
            this.initializeBlankQuestionnaire();
        }
        else {
            this.initialize(initializeTabAt, initializeSectionAt);
        }
    };
    // Create the this.labelVariable array using each of the userLanguages
    Edq2Component.prototype.initializeSurveyAssetLabels = function (defaultLabel) {
        var surveyAssetLabelsArray = [];
        for (var i = 0; i < this.userLanguages.length; i++) {
            surveyAssetLabelsArray.push({ language_id: this.userLanguages[i].id, label: defaultLabel ? defaultLabel : '' });
        }
        return surveyAssetLabelsArray;
    };
    // If wizard flow is initiated (e.g. "save and add question"), then take the appropriate action
    Edq2Component.prototype.wizardFlowAction = function (rootAsset) {
        this.mta = rootAsset;
        // If the rootasset = tab, ie a tab was just created, now add a section
        if (rootAsset.qa_type === 'tab') {
            var currentTab = this.questionnaire.find(function (item) { return item.code == rootAsset.code; });
            var currentTabIndex = this.questionnaire.indexOf(currentTab);
            // console.log('currentTabIndex', currentTabIndex);
            this.initialize(currentTabIndex, 0);
            var relativeDataSection = this.getRelativeToAssetData(currentTab);
            this.addAsset('section', relativeDataSection[0], relativeDataSection[1], 1, this.modifyAssetModalTemp);
        }
        // If the rootasset = section, go to wizardDisplayedPanel = 2 (3 buttons to decide what to add inside this section)
        if (rootAsset.qa_type === 'section') {
            var currentTabIndex = this.questionnaire.indexOf(this.getParentAsset(rootAsset));
            var currentSection = this.questionnaire[currentTabIndex].sections.find(function (item) { return item.code == rootAsset.code; });
            var currentSectionIndex = this.questionnaire[currentTabIndex].sections.indexOf(currentSection);
            this.initialize(currentTabIndex, currentSectionIndex);
            if (this.wizardDisplayedPanel == 1) {
                this.wizardDisplayedPanel = 2;
            }
        }
        // If the rootasset = subsection or table, add a question in the rootasset
        if (rootAsset.qa_type === 'subsection' || rootAsset.qa_type === 'table') {
            var relativeDataQuestion = this.getRelativeToAssetData(rootAsset);
            this.addAsset('question', relativeDataQuestion[0], relativeDataQuestion[1], 1, this.modifyAssetModalTemp, rootAsset.qa_type === 'table' ? true : false);
        }
        // If the rootasset = question
        if (rootAsset.qa_type === 'question') {
            // If the subtype = select / multiselect
            if (rootAsset.qa_subtype === 'select' || rootAsset.qa_subtype === 'multiselect') {
                // If currently in panel 1, go to panel 2 to add options
                if (this.wizardDisplayedPanel == 1) {
                    this.wizardDisplayedPanel = 2;
                    this.modifyThisAsset(rootAsset, this.modifyAssetModalTemp, 'question');
                }
                else {
                    // If already in panel 2, go to panel 1 to add another question
                    this.wizardDisplayedPanel = 1;
                    this.addAnotherQuestion(rootAsset);
                }
            }
            else {
                // If the subtype = text / number, add another question after rootAsset if manageUnits is false. Else go to Manage Units in Panel 2
                if (this.manageUnits) {
                    if (this.wizardDisplayedPanel == 1) {
                        this.wizardDisplayedPanel = 2;
                        this.modifyThisAsset(rootAsset, this.modifyAssetModalTemp, 'question');
                    }
                    else {
                        // If already in panel 2, go to panel 1 to add another question
                        this.wizardDisplayedPanel = 1;
                        this.addAnotherQuestion(rootAsset);
                    }
                }
                else {
                    this.addAnotherQuestion(rootAsset);
                }
            }
        }
        if (rootAsset.qa_type === 'option' || rootAsset.qa_type === 'other_question' || rootAsset.qa_type === 'uom_question') {
            var parentQuestion = this.getParentAsset(rootAsset);
            if (this.wizardDisplayedPanel == 2) {
                this.wizardDisplayedPanel = 1;
                this.addAnotherQuestion(parentQuestion);
            }
            else {
                this.mta = parentQuestion;
                this.wizardDisplayedPanel = 2;
            }
        }
    };
    // Add another question relative to rootAsset
    Edq2Component.prototype.addAnotherQuestion = function (rootAsset) {
        var parentAsset = this.getParentAsset(rootAsset);
        if (parentAsset && parentAsset.questions) {
            var rootAssetInParent = parentAsset.questions.find(function (item) { return item.code == rootAsset.code; });
            if (rootAssetInParent) {
                var relativeToAssetIndex = parentAsset.questions.indexOf(rootAssetInParent);
                if (this.validIndex(relativeToAssetIndex)) {
                    this.manageUnits = false;
                    this.addAsset('question', { qa_type: 'question', parent_id: rootAsset.parent_id, code: rootAsset.code, parent_code: rootAsset.parent_code, qa_sequence: rootAsset.qa_sequence }, relativeToAssetIndex, 1, this.modifyAssetModalTemp, parentAsset.qa_type === 'table' ? true : false);
                }
            }
        }
    };
    // Add option to the mta
    Edq2Component.prototype.addOption = function (isOtherOption) {
        var _a;
        // isOtherOption = true would indicate that this is an option of qa_subtype 'Other' - a text field is to be displayed if this option is selected
        if (!this.mta.id) {
            return;
        }
        var optionToAdd = (_a = {
                survey_id: this.surveyId,
                parent_id: this.mta.id,
                parent_code: this.mta.code,
                qa_sequence: this.getSequence('option'),
                code: uuidv4(),
                qa_type: 'option',
                qa_subtype: isOtherOption ? 'other' : null
            },
            _a[this.labelVariable] = isOtherOption ? this.initializeSurveyAssetLabels('Other') : this.initializeSurveyAssetLabels(),
            _a);
        if (!this.mta.options) {
            this.mta.options = [];
        }
        this.mta.options.push(optionToAdd);
        this.addOrRemoveAsset(optionToAdd, 'add');
        this.setMtaHasOtherOption();
        if (isOtherOption) {
            this.addIsOtherOptionQuestionAsset();
        }
    };
    // Handle the display & changedAssets after option / question is removed / added
    Edq2Component.prototype.addOrRemoveAsset = function (asset, action) {
        this.handleDisplay(asset, action);
        this.handleChangedAssets(asset, action);
    };
    /**
     * Set the removed_from_questionnaire value depending on type of action
     * @param asset
     * @param action
     */
    Edq2Component.prototype.handleDisplay = function (asset, action) {
        if (asset.qa_type == 'question') {
            this.mta.removed_from_questionnaire = (action == 'remove' ? true : false);
        }
        if (asset.qa_type == 'option') {
            if (this.displayLogicValues.indexOf(asset.id) > -1) {
                this.commonService.showToast('warning', 'option_part_of_display_logic', {});
                return;
            }
            var optionToRemove = this.mta.options.find(function (item) { return item.code == asset.code; });
            if (optionToRemove) {
                var index = this.mta.options.indexOf(optionToRemove);
                if (this.validIndex(index)) {
                    this.mta.options[index].removed_from_questionnaire = (action == 'remove' ? true : false);
                }
            }
        }
    };
    /**
     * In a local variable, store all the asset additions / removals - to identify if any redundant changes are made
     * @param asset
     * @param action
     */
    Edq2Component.prototype.handleChangedAssets = function (asset, action) {
        var nowUTCString = new Date().toUTCString();
        if (!this.questionnaireChanges['assets']) {
            this.questionnaireChanges['assets'] = {};
        }
        if (action == 'remove') {
            if (this.isRedundant(asset, action)) {
                this.questionnaireChanges['assets']['add'] = this.questionnaireChanges['assets']['add'].filter(function (item) { return !(item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code)); });
            }
            else {
                if (!this.questionnaireChanges['assets']['remove']) {
                    this.questionnaireChanges['assets']['remove'] = [];
                }
                var element = asset.id ? { qa_type: asset.qa_type, identifier: 'id', value: asset.id, at: nowUTCString } : { qa_type: asset.qa_type, identifier: 'code', value: asset.code, at: nowUTCString };
                this.questionnaireChanges['assets']['remove'].push(element);
            }
        }
        else {
            if (this.isRedundant(asset, action)) {
                this.questionnaireChanges['assets']['remove'] = this.questionnaireChanges['assets']['remove'].filter(function (item) { return !(item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code)); });
            }
            else {
                if (!this.questionnaireChanges['assets']['add']) {
                    this.questionnaireChanges['assets']['add'] = [];
                }
                var element = asset.id ? { qa_type: asset.qa_type, identifier: 'id', value: asset.id, at: nowUTCString } : { qa_type: asset.qa_type, identifier: 'code', value: asset.code, at: nowUTCString };
                this.questionnaireChanges['assets']['add'].push(element);
            }
        }
    };
    // Get sequence of the option to be added (max value of sequence of existing options + 1)
    Edq2Component.prototype.getSequence = function (assetType) {
        var x;
        if (assetType == 'option' && this.mta.options) {
            x = Object.assign([], JSON.parse(JSON.stringify(this.mta.options)));
        }
        else if (assetType == 'question' && this.currentSection.questions) {
            x = Object.assign([], JSON.parse(JSON.stringify(this.currentSection.questions)));
        }
        else if (assetType == 'subsection' && this.currentSection.subsections) {
            x = Object.assign([], JSON.parse(JSON.stringify(this.currentSection.subsections)));
        }
        else if (assetType == 'table' && this.currentSection.tables) {
            x = Object.assign([], JSON.parse(JSON.stringify(this.currentSection.tables)));
        }
        else if (assetType == 'section' && this.currentTab.sections) {
            x = Object.assign([], JSON.parse(JSON.stringify(this.currentTab.sections)));
        }
        else if (assetType == 'uom_question' && this.mta.uom_questions) {
            x = Object.assign([], JSON.parse(JSON.stringify(this.mta.uom_questions)));
        }
        // If the question has options, sort the options already in this question. If no options, return 1
        // Similar logic for other asset types
        if (x && x.length > 0) {
            x.sort(function (a, b) {
                if (a.qa_sequence > b.qa_sequence) {
                    return 1;
                }
                else if (a.qa_sequence < b.qa_sequence) {
                    return -1;
                }
                else {
                    return 0;
                }
            });
            // Get the max qa_sequence value in current set of options
            var maxSequence = x[x.length - 1].qa_sequence;
            return maxSequence + 1;
        }
        else {
            return 1;
        }
    };
    // Add "other question" child asset to question (the textbox to enter value if "other" option is selected)
    Edq2Component.prototype.addIsOtherOptionQuestionAsset = function () {
        var _a;
        if (!this.mta.id || this.mta.id == 0) {
            return;
        }
        if (!this.mta.other_questions) {
            this.mta.other_questions = [];
        }
        var otherQuestionToAdd = (_a = {
                code: uuidv4(),
                qa_type: 'other_question',
                survey_id: this.surveyId,
                parent_id: this.mta.id,
                parent_code: this.mta.code
            },
            _a[this.labelVariable] = this.initializeSurveyAssetLabels('Other'),
            _a.is_table_question = this.mta.is_table_question,
            _a);
        this.mta.other_questions.push(otherQuestionToAdd);
    };
    Edq2Component.prototype.isRedundant = function (asset, action) {
        if (action == 'remove') {
            if (this.questionnaireChanges['assets']['add']) {
                if (this.questionnaireChanges['assets']['add'].find(function (item) { return item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code); })) {
                    return true;
                }
            }
        }
        else {
            if (this.questionnaireChanges['assets']['remove']) {
                if (this.questionnaireChanges['assets']['remove'].find(function (item) { return item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code); })) {
                    return true;
                }
            }
        }
        return false;
    };
    // Set wizard displayed panel = 1
    Edq2Component.prototype.resetWizardDisplayedPanel = function () {
        this.wizardDisplayedPanel = 1;
        this.wizardPanel1Updated = false;
    };
    // Set wizardFlowOngoing = false
    Edq2Component.prototype.resetwizardFlowOngoing = function () {
        this.wizardFlowOngoing = false;
    };
    // Set the clicked option as part of the ongoing display logic change
    Edq2Component.prototype.setOptionAsDisplayLogic = function (event, option, question) {
        if (!this.modifyDisplayLogicForAsset) {
            return;
        }
        if (!this.modifyDisplayLogicForAsset.id) {
            return;
        }
        if (this.canAddOptionToDisplayLogic(option)) { // Only the options in the parent question can be selected. Others are disabled anyway
            // Unset all radio & checkbox inputs 'checked' values - the selected option will just have a blur border around it. Not be visibly checked
            var elArray = void 0;
            if (question.qa_subtype == 'select') {
                elArray = document.getElementsByName(question.code);
            }
            else {
                elArray = document.getElementsByName(option.code);
            }
            if (elArray) {
                for (var _i = 0, elArray_1 = elArray; _i < elArray_1.length; _i++) {
                    var el = elArray_1[_i];
                    el.checked = false;
                }
            }
            this.displayLogicDisplayIfKey = undefined;
            this.displayLogicDisplayIfValue = undefined;
            delete this.displayLogicDisplayIfKey;
            delete this.displayLogicDisplayIfValue;
            this.ref.detectChanges();
            this.displayLogicDisplayIfKey = question;
            this.displayLogicDisplayIfValue = option;
            this.addDisplayLogic(question.id, option.id);
            this.ref.detectChanges();
        }
    };
    Edq2Component.prototype.canAddOptionToDisplayLogic = function (option) {
        return true;
    };
    // Add or remove option
    Edq2Component.prototype.changeOption = function (option, action) {
        var optionToRemove = this.mta.options.find(function (item) { return item.code == option.code; });
        if (optionToRemove) {
            var index = this.mta.options.indexOf(optionToRemove);
            if (this.validIndex(index)) {
                if (this.mta.options[index].id) {
                    if (this.displayLogicValues.indexOf(this.mta.options[index].id.toString()) > -1) {
                        this.commonService.showToast('error', 'option_part_of_display_logic_modify_display_logic', {});
                        return;
                    }
                    this.mta.options[index].removed_from_questionnaire = (action == 'remove' ? true : false);
                }
                else {
                    this.mta.options.splice(index, 1);
                }
                this.setMtaHasOtherOption();
                if (option.qa_subtype === 'other') {
                    this.changeOtherQuestion(option, action);
                }
            }
        }
    };
    // Preprocess MTA before saving changes
    Edq2Component.prototype.preProcessMTA = function () {
        if (this.mta.qa_type === 'question' && this.mta.qa_subtype !== 'select' && this.mta.qa_subtype !== 'multiselect' && this.mta.options && this.mta.options.length > 0) {
            for (var i = 0; i < this.mta.options.length; i++) {
                this.mta.options[i].removed_from_questionnaire = true;
            }
            if (this.mta.other_questions && this.mta.other_questions.length > 0) {
                this.mta.other_questions[0].removed_from_questionnaire = true;
            }
        }
    };
    // // TODO: Remvoe this after testing phase. It was required to make the tab/sections clickable in the wizard. No longer needed
    // updateMTAAttribbutes(): void {
    //   if (this.mta && this.mta.qa_type) {
    //     let relativeToAsset;
    //     if (this.mta.qa_type === 'tab') {
    //       relativeToAsset = this.currentTab;
    //     } else if (this.mta.qa_type == 'section' || this.mta.qa_type == 'subsection' || this.mta.qa_type == 'table') {
    //       relativeToAsset = this.currentSection;
    //     } else if (this.mta.qa_type == 'question') {
    //       relativeToAsset = this.getParentAsset(this.mta);
    //     }
    //     // console.log('relativeToAsset', relativeToAsset);
    //     if (relativeToAsset) {
    //       // Update the MTA's sequence
    //       if (relativeToAsset.qa_sequence != undefined) {
    //         console.log('2440');
    //         this.mta.qa_sequence = this.getRelativeSequenceNew(relativeToAsset, 1);
    //       }
    //       // Update the MTA's parent_id, parent_code
    //       if (this.mta.qa_type == 'tab') {
    //         this.mta.parent_id = null;
    //         this.mta.parent_code = null;
    //       }
    //       if (this.mta.qa_type == 'section') {
    //         if (relativeToAsset.parent_id != undefined) {
    //           this.mta.parent_id = relativeToAsset.parent_id;
    //         }
    //         if (relativeToAsset.parent_code != undefined) {
    //           this.mta.parent_code = relativeToAsset.parent_code;
    //         }
    //       }
    //       if (this.mta.qa_type == 'question' || this.mta.qa_type == 'subsection' || this.mta.qa_type == 'table') {
    //         if (relativeToAsset.parent_id != undefined) {
    //           this.mta.parent_id = relativeToAsset.id;
    //         }
    //         if (relativeToAsset.parent_code != undefined) {
    //           this.mta.parent_code = relativeToAsset.code;
    //         }
    //       }
    //       this.updateRelativeToAssetIndex(relativeToAsset);
    //     }
    //   }
    // }
    /**
     * Not used. This can be deleted
     * @param relativeToAsset
     */
    Edq2Component.prototype.updateRelativeToAssetIndex = function (relativeToAsset) {
        if (relativeToAsset) {
            var index = this.getAssetIndex(relativeToAsset);
            if (this.validIndex(index)) {
                this.relativeToAssetIndex = index;
            }
        }
    };
    /**
   * Not used. This can be deleted
   * @param asset
   */
    Edq2Component.prototype.getAssetIndex = function (asset) {
        if (asset.qa_type == 'tab' && this.currentTabIndex) {
            return this.currentTabIndex;
        }
        if (asset.qa_type == 'section' && this.currentSectionIndex) {
            return this.currentSectionIndex;
        }
        if (asset.qa_type == 'subsection') {
            if (this.currentSection && this.currentSection.subsections) {
                var assetInQ = this.currentSection.subsections.find(function (item) { return item.code == asset.code; });
                if (assetInQ) {
                    return this.currentSection.subsections.indexOf(assetInQ);
                }
            }
        }
        if (asset.qa_type == 'table') {
            if (this.currentSection && this.currentSection.tables) {
                var assetInQ = this.currentSection.tables.find(function (item) { return item.code == asset.code; });
                if (assetInQ) {
                    return this.currentSection.tables.indexOf(assetInQ);
                }
            }
        }
        if (asset.qa_type == 'question') {
            var parentAsset = this.getParentAsset(asset);
            if (parentAsset.qa_type == 'section') {
                return this.currentSectionIndex;
            }
            if (parentAsset.qa_type == 'subsection') {
                if (this.currentSection && this.currentSection.subsections) {
                    var assetInQ = this.currentSection.subsections.find(function (item) { return item.code == asset.code; });
                    if (assetInQ) {
                        return this.currentSection.subsections.indexOf(assetInQ);
                    }
                }
            }
            if (parentAsset.qa_type == 'table') {
                if (this.currentSection && this.currentSection.tables) {
                    var assetInQ = this.currentSection.tables.find(function (item) { return item.code == asset.code; });
                    if (assetInQ) {
                        return this.currentSection.tables.indexOf(assetInQ);
                    }
                }
            }
        }
    };
    /**
     * Preview survey function
     * @param action
     */
    Edq2Component.prototype.printSurvey = function (action) {
        if (this.questionnaire && this.questionnaire.length > 0) {
            for (var _i = 0, _a = this.questionnaire; _i < _a.length; _i++) {
                var tab = _a[_i];
                this.checkMissingLabel(tab);
                if (tab.sections && tab.sections.length > 0) {
                    for (var _b = 0, _c = tab.sections; _b < _c.length; _b++) {
                        var section = _c[_b];
                        this.checkMissingLabel(section);
                        var totalQuestions = 0;
                        if (section.questions && section.questions.length > 0) {
                            totalQuestions = section.questions.length;
                            for (var _d = 0, _e = section.questions; _d < _e.length; _d++) {
                                var question = _e[_d];
                                this.checkMissingLabel(question);
                                var optionTotalLength = 0;
                                if (question.options && question.options.length > 0) {
                                    for (var _f = 0, _g = question.options; _f < _g.length; _f++) {
                                        var option = _g[_f];
                                        optionTotalLength = optionTotalLength + option.survey_q_asset_labels[0].label.length;
                                        if (optionTotalLength > 150) {
                                            totalQuestions = totalQuestions + 1;
                                            optionTotalLength = 0;
                                        }
                                    }
                                }
                            }
                        }
                        if (section.subsections && section.subsections.length > 0) {
                            for (var _h = 0, _j = section.subsections; _h < _j.length; _h++) {
                                var subsection = _j[_h];
                                this.checkMissingLabel(subsection);
                                if (subsection.questions && subsection.questions.length > 0) {
                                    totalQuestions = totalQuestions + (subsection.questions.length + 1);
                                    for (var _k = 0, _l = subsection.questions; _k < _l.length; _k++) {
                                        var question = _l[_k];
                                        this.checkMissingLabel(question);
                                        var optionTotalLength = 0;
                                        if (question.options && question.options.length > 0) {
                                            for (var _m = 0, _o = question.options; _m < _o.length; _m++) {
                                                var option = _o[_m];
                                                optionTotalLength = optionTotalLength + option.survey_q_asset_labels[0].label.length;
                                                if (optionTotalLength > 150) {
                                                    totalQuestions = totalQuestions + 1;
                                                    optionTotalLength = 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (section.tables && section.tables.length > 0) {
                            for (var _p = 0, _q = section.tables; _p < _q.length; _p++) {
                                var table = _q[_p];
                                this.checkMissingLabel(table);
                                if (table.questions && table.questions.length > 0) {
                                    totalQuestions = totalQuestions + (table.questions.length + 1);
                                    for (var _r = 0, _s = table.questions; _r < _s.length; _r++) {
                                        var question = _s[_r];
                                        this.checkMissingLabel(question);
                                        var optionTotalLength = 0;
                                        if (question.options && question.options.length > 0) {
                                            for (var _t = 0, _u = question.options; _t < _u.length; _t++) {
                                                var option = _u[_t];
                                                optionTotalLength = optionTotalLength + option.survey_q_asset_labels[0].label.length;
                                                if (optionTotalLength > 150) {
                                                    totalQuestions = totalQuestions + 1;
                                                    optionTotalLength = 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        var totalQuestionsHeight = totalQuestions > 55 ? '137.8cm' : totalQuestions > 44 ? '136.5cm' : totalQuestions > 33 ? '109.2cm' : totalQuestions > 22 ? '81.9cm' : totalQuestions > 11 ? '54.6cm' : '27.3cm';
                        //console.log('totalQuestionsHeight', totalQuestionsHeight)
                        section.totalQuestionsHeight = totalQuestionsHeight;
                    }
                }
            }
        }
        var surveyQuestionnaire = document.getElementById('surveyQuestionnaire');
        if (action === 'close') {
            surveyQuestionnaire.classList.remove('d-block');
            surveyQuestionnaire.classList.add('d-none');
            document.documentElement.style.overflowY = 'scroll';
        }
        else {
            surveyQuestionnaire.classList.remove('d-none');
            surveyQuestionnaire.classList.add('d-block');
            document.documentElement.style.overflowY = 'hidden';
        }
        //add total questions in section object
        for (var _v = 0, _w = this.questionnaire; _v < _w.length; _v++) {
            var tab = _w[_v];
            for (var _x = 0, _y = tab.sections; _x < _y.length; _x++) {
                var section = _y[_x];
                var totalQuestions = 0;
                if (section.questions.length > 0) {
                    totalQuestions = section.questions.length;
                }
                for (var _z = 0, _0 = section.subsections; _z < _0.length; _z++) {
                    var subsection = _0[_z];
                    if (subsection.questions.length > 0) {
                        totalQuestions = totalQuestions + (subsection.questions.length + 1);
                    }
                }
                for (var _1 = 0, _2 = section.tables; _1 < _2.length; _1++) {
                    var table = _2[_1];
                    if (table.questions.length > 0) {
                        totalQuestions = totalQuestions + (table.questions.length + 1);
                    }
                }
                section.totalQuestions = totalQuestions;
            }
        }
    };
    /**
     * Check whether the item has a missing label, Assign an attribute if so
     * @param item
     */
    Edq2Component.prototype.checkMissingLabel = function (item) {
        if (item.survey_q_asset_labels && item.survey_q_asset_labels.length > 0) {
            var name_1 = item[this.labelVariable][this.currentLocaleLanguageIdIndex];
            if (item.hasOwnProperty('missing_label')) {
                item.missing_label = false;
            }
            if (name_1.label == "" || name_1.label == null) {
                if (item.hasOwnProperty('missing_label')) {
                    item.missing_label = true;
                }
                else {
                    item["missing_label"] = true;
                }
            }
        }
        if (item.qa_type == 'question' && (item.qa_subtype == 'select' || item.qa_subtype == 'multiselect')) {
            if (item.hasOwnProperty('missing_option')) {
                item.missing_option = false;
            }
            for (var _i = 0, _a = item.options; _i < _a.length; _i++) {
                var option = _a[_i];
                var name_2 = option[this.labelVariable][this.currentLocaleLanguageIdIndex];
                if (name_2.label == "" || name_2.label == null) {
                    if (item.hasOwnProperty('missing_option')) {
                        item.missing_option = true;
                    }
                    else {
                        item["missing_option"] = true;
                    }
                }
            }
        }
    };
    Edq2Component.prototype.scrollToTop = function () {
        document.getElementById('surveyQuestionnaire').scrollTop = 0; // For Safari
        //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    Edq2Component.prototype.onScroll = function (item) {
        if (document.getElementById('surveyQuestionnaire').scrollTop > 100) {
            this.showUpArrow = true;
        }
        else {
            this.showUpArrow = false;
        }
    };
    // Handle click on project name in breadcrumb
    Edq2Component.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    // Launch modal to save as template
    // TODO: Remove after testing phase. Save as template moved to surveys page
    Edq2Component.prototype.launchSaveAsTemplateModal = function (template, template2) {
        this.savingTemplate = false;
        this.templateNameInput = undefined;
        this.templateDescriptionInput = undefined;
        this.saveTemplateSubmitted = false;
        if (this.changedAssets.length > 0) {
            this.confirmForChanges = this.modalService.show(template2, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            this.saveTemplateModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
    };
    // TODO: Remove after testing phase. Activate snapshot moved to surveys page
    Edq2Component.prototype.activateSnapshot = function (template, template2) {
        var _this = this;
        this.surveyService.checkLanguageOKForRelease(this.surveyId)
            .subscribe(function (result) {
            if (result.code == 'success') {
                _this.languagesReadyForRelease = result['message']['ok'];
                _this.languagesNotReadyForRelease = result['message']['notOK'];
                if (_this.changedAssets.length > 0) {
                    _this.confirmForChanges = _this.modalService.show(template2, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
                }
                else {
                    _this.closeSnapshotModal = _this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
                }
            }
        });
    };
    Edq2Component.prototype.declineSaveChangesActivate = function () {
        this.confirmForChanges.hide();
    };
    Edq2Component.prototype.moveWithoutSaveChangesActivate = function () {
        this.confirmForChanges.hide();
        this.activateSurvey();
    };
    Edq2Component.prototype.confirmActivation = function () {
        if (this.closeSnapshotModal) {
            this.closeSnapshotModal.hide();
        }
        this.activateSurvey();
    };
    Edq2Component.prototype.declineCloseSnapshot = function () {
        if (this.closeSnapshotModal) {
            this.closeSnapshotModal.hide();
        }
    };
    /**
     * Not used. This can be deleted
    */
    Edq2Component.prototype.activateSurvey = function () {
        var _this = this;
        if (this.disableButtons || this.savingTemplate) {
            return;
        }
        else {
            this.spinner.show();
            this.disableButtons = true;
            this.ref.detectChanges();
            this.surveyService.activateSurvey(this.surveyId)
                .subscribe(function (result) {
                if (result.code == 'success') {
                    _this.commonService.showToast('success', 'activated_survey', {});
                    _this.disableButtons = false;
                    _this.ref.detectChanges();
                    _this.router.navigate(['questionnaires/' + _this.projectId]);
                    _this.spinner.hide();
                }
            }, function (error) {
                _this.spinner.hide();
                _this.disableButtons = false;
                _this.ref.detectChanges();
            });
        }
    };
    //added by Kiran for escape button click event
    Edq2Component.prototype.onKeydownHandler = function (event) {
        if (event.keyCode === 27) {
            if (this.modifyingAssetModal) {
                if (this.disableCancelOfModifyingAssetModal) {
                    return;
                }
                else {
                    this.closeModifyingAssetTemplateModal();
                }
            }
            if (this.deleteAssetsModal) {
                this.declineDeletion();
            }
            this.printSurvey('close');
        }
    };
    // Remove the trailing operator in the autocalculate formula array (the empty question after the last operator AND the operator itself)
    Edq2Component.prototype.removeTrailingOperator = function (i) {
        this.autocalculateFormulaArray.pop();
        this.autocalculateFormulaArray.pop();
    };
    //added by Kiran for navigation to preview page
    Edq2Component.prototype.previewSurvey = function () {
        this.router.navigate(["questionnaire/template/" + this.projectId + "/" + this.surveyId + "/-1"]);
    };
    /**
     * Called if the P2F template is being modified. Sets the labelVariable etc values
     */
    Edq2Component.prototype.updateQVariables = function () {
        this.labelVariable = 'questionnaire_asset_labels';
        this.validationVariable = 'questionnaire_asset_validations';
        this.displayLogicVariable = 'questionnaire_asset_display_logic';
        this.autocalculateFormulaVariable = 'questionnaire_asset_autocalculate_formulas';
        this.updateUpdateableAttributes();
    };
    /**
     * Add the labelVariable etc to the updateableAttributes
     */
    Edq2Component.prototype.updateUpdateableAttributes = function () {
        this.updateableAttributes.push(this.labelVariable);
        this.updateableAttributes.push(this.validationVariable);
        this.updateableAttributes.push(this.displayLogicVariable);
        this.updateableAttributes.push(this.autocalculateFormulaVariable);
    };
    Edq2Component.prototype.goBackToAdmin = function () {
        this.location.back();
    };
    Edq2Component.prototype.promptBeforeDeleting = function (template) {
        this.deleteAssetsModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    Edq2Component.prototype.declineDeletion = function () {
        if (this.deleteAssetsModal) {
            this.deleteAssetsModal.hide();
        }
    };
    /**
     * Add UOM to the current MTA
     */
    Edq2Component.prototype.addUOM = function () {
        var _a;
        if (!this.mta.id || this.mta.id == 0) {
            return;
        }
        if (!this.mta.uom_questions) {
            this.mta.uom_questions = [];
        }
        if (this.mta.uom_questions.length === 2) {
            this.commonService.showToast('warning', 'can_add_upto_2_Units', {});
            return;
        }
        var uomToAdd = (_a = {
                code: uuidv4(),
                qa_type: 'uom_question',
                qa_subtype: "",
                survey_id: this.surveyId,
                parent_id: this.mta.id,
                parent_code: this.mta.code
            },
            _a[this.labelVariable] = this.initializeSurveyAssetLabels('Unit'),
            _a.is_table_question = this.mta.is_table_question,
            _a.qa_sequence = this.getSequence('uom_question'),
            _a);
        this.mta.include_uom_question = true;
        this.mta.uom_questions.push(uomToAdd);
    };
    /**
     * Remove UOM from the current MTA
     * @param uom
     * @param action
     */
    Edq2Component.prototype.changeUOM = function (uom, action) {
        var uomToRemove = this.mta.uom_questions.find(function (item) { return item.code == uom.code; });
        if (uomToRemove) {
            var index = this.mta.uom_questions.indexOf(uomToRemove);
            if (this.validIndex(index)) {
                if (this.mta.uom_questions[index].id) {
                    this.mta.uom_questions[index].removed_from_questionnaire = (action == 'remove' ? true : false);
                    this.mta.include_uom_question = (action == 'remove' ? false : true);
                }
                else {
                    this.mta.uom_questions.splice(index, 1);
                }
            }
        }
    };
    Edq2Component.prototype.getRelativeSequenceNew = function (relativeToAsset, direction) {
        // TODO: Include tab logic in this!@
        // Find the sibling assets to relativeToAsset
        // Check if an asset with qa_sequence = relativeToAsset + direction exists
        // If it does not, return relativeToAsset + direction
        // If it does, check relativeToAsset + direction + direction.. until you find an unclaimed qa_sequence number.
        if (relativeToAsset.qa_sequence == null) {
            return 1;
        }
        else {
            var siblingAssets = [];
            if (relativeToAsset.qa_type == 'tab') {
                siblingAssets = this.questionnaire;
            }
            if (relativeToAsset.qa_type == 'section') {
                siblingAssets = this.currentTab.sections;
            }
            if (relativeToAsset.qa_type == 'question') {
                var parentAsset = this.getParentAsset(relativeToAsset);
                siblingAssets = parentAsset.questions;
            }
            if (relativeToAsset.qa_type == 'subsection') {
                var parentAsset = this.getParentAsset(relativeToAsset);
                siblingAssets = parentAsset.subsections;
            }
            if (relativeToAsset.qa_type == 'table') {
                var parentAsset = this.getParentAsset(relativeToAsset);
                siblingAssets = parentAsset.tables;
            }
            var indexOfAsset = void 0;
            var asset = siblingAssets.find(function (item) { return item.code === relativeToAsset.code; });
            indexOfAsset = siblingAssets.indexOf(asset);
            var indexOfNextAsset = indexOfAsset + 1;
            var qaSequenceToReturn = void 0;
            if (siblingAssets[indexOfNextAsset]) {
                qaSequenceToReturn = (siblingAssets[indexOfAsset].qa_sequence + siblingAssets[indexOfNextAsset].qa_sequence) / 2;
            }
            else {
                qaSequenceToReturn = siblingAssets[indexOfAsset].qa_sequence + 1;
            }
            return qaSequenceToReturn;
        }
    };
    /**
     * Set the removed_from_questionnaire of the other_question depending on the action
     * @param option
     * @param action
     */
    Edq2Component.prototype.changeOtherQuestion = function (option, action) {
        if (this.mta.other_questions && this.mta.other_questions.length > 0) {
            if (action === 'remove') {
                if (option.id) {
                    this.mta.other_questions[0]['removed_from_questionnaire'] = true;
                }
                else {
                    this.mta.other_questions = [];
                }
            }
            else {
                this.mta.other_questions[0]['removed_from_questionnaire'] = false;
            }
        }
    };
    Edq2Component.prototype.showSuccessMessage = function (savedAssets) {
        if (savedAssets && savedAssets.length > 0) {
            this.commonService.showToast('success', 'changes_saved', {});
        }
    };
    // If a tab asset is being soft deleted / marked as deleted / added back, the updateCurrentTab moves all sections' questions to the currentSection
    // This is the fix - within the tab, remove each section 1 by 1 and then remove the tab asset itself
    // changeAsset() modified to pass the first tab removal to this function, & when the tab is passed back to changeAsset() after child...
    // sections are removed, the recursive handler is bypassed (as all children of the tab are already marked for removal / add back)
    Edq2Component.prototype.changeTabAsset = function (action, calledFromMarkChildrenRecursively) {
        var tab = JSON.parse(JSON.stringify(this.mta));
        if (!calledFromMarkChildrenRecursively && tab) {
            if (tab.sections && tab.sections.length > 0) {
                var sections = JSON.parse(JSON.stringify(this.mta.sections));
                for (var i = 0; i < sections.length; i++) {
                    this.mta = sections[i];
                    this.changeAsset(action, calledFromMarkChildrenRecursively, true);
                }
            }
            this.mta = tab;
            this.changeAsset(action, calledFromMarkChildrenRecursively, true);
        }
        else {
            this.spinner.hide();
        }
    };
    Edq2Component.prototype.okayToCancelMTAModal = function () {
        if (this.mta) {
            if ((this.mta.qa_subtype === 'select' || this.mta.qa_subtype === 'multiselect') && this.mta.id &&
                (!this.mta.options || this.mta.options.length == 0) && !this.mta.has_dynamic_options &&
                (!this.wizardFlowOngoing || this.wizardDisplayedPanel === 2)) {
                this.addToMTAIssues({ code: 19, text: 'Please add options for this question by clicking on "Save & Manage Options"' });
                return false;
            }
        }
        this.mtaIssues = [];
        return true;
    };
    // If the rootAsset is tab or subsection or table, then the relativeToAsset should be the last child element of the
    // rootAsset, if it exists. If it doesn't exist
    Edq2Component.prototype.getRelativeToAssetData = function (rootAsset) {
        if (rootAsset) {
            var relativeToAsset = { parent_id: rootAsset.id, parent_code: rootAsset.code };
            var relativeToAssetIndex = 0;
            if (rootAsset.qa_type === 'tab') {
                relativeToAsset['qa_type'] = 'section';
                if (rootAsset.sections && rootAsset.sections.length > 0) {
                    relativeToAsset = rootAsset.sections[rootAsset.sections.length - 1];
                    relativeToAssetIndex = rootAsset.sections.length - 1;
                    return [relativeToAsset, relativeToAssetIndex];
                }
                else {
                    return [relativeToAsset, 0];
                }
            }
            else if (rootAsset.qa_type === 'table' || rootAsset.qa_type === 'subsection') {
                var asset = this.currentSection[rootAsset.qa_type + "s"].find(function (item) { return item.code === rootAsset.code; });
                if (asset) {
                    relativeToAsset['qa_type'] = 'question';
                    if (asset.questions && asset.questions.length > 0) {
                        relativeToAsset = asset.questions[asset.questions.length - 1];
                        relativeToAssetIndex = asset.questions.length - 1;
                        return [relativeToAsset, relativeToAssetIndex];
                    }
                    else {
                        return [relativeToAsset, 0];
                    }
                }
            }
            else {
                return [relativeToAsset, 0];
            }
        }
        else {
            return [null, null];
        }
    };
    /**
     * Collect all the asset ids used in the autocalculate formulas into an array
     * this is used to check whether any of the assets involved can be removed
     */
    Edq2Component.prototype.collectAutocalculateFormulaAssetIds = function () {
        this.autocalculateFormulaAssetIds = [];
        var x = [];
        if (this.questionnaire && this.questionnaire.length > 0) {
            for (var _i = 0, _a = this.questionnaire; _i < _a.length; _i++) {
                var tab = _a[_i];
                if (tab.sections && tab.sections.length > 0) {
                    for (var _b = 0, _c = tab.sections; _b < _c.length; _b++) {
                        var section = _c[_b];
                        if (section.questions && section.questions.length > 0) {
                            for (var _d = 0, _e = section.questions; _d < _e.length; _d++) {
                                var question = _e[_d];
                                if (question[this.autocalculateFormulaVariable] &&
                                    question[this.autocalculateFormulaVariable].length > 0) {
                                    for (var _f = 0, _g = question[this.autocalculateFormulaVariable]; _f < _g.length; _f++) {
                                        var f = _g[_f];
                                        x.push(f.questionnaire_asset_id.toString());
                                        x.push(f.formula.toString());
                                    }
                                }
                            }
                        }
                        if (section.subsections && section.subsections.length > 0) {
                            for (var _h = 0, _j = section.subsections; _h < _j.length; _h++) {
                                var subsection = _j[_h];
                                if (subsection.questions && subsection.questions.length > 0) {
                                    for (var _k = 0, _l = subsection.questions; _k < _l.length; _k++) {
                                        var question = _l[_k];
                                        if (question[this.autocalculateFormulaVariable]
                                            && question[this.autocalculateFormulaVariable].length > 0) {
                                            for (var _m = 0, _o = question[this.autocalculateFormulaVariable]; _m < _o.length; _m++) {
                                                var f = _o[_m];
                                                x.push(f.questionnaire_asset_id.toString());
                                                x.push(f.formula.toString());
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (section.tables && section.tables.length > 0) {
                            for (var _p = 0, _q = section.tables; _p < _q.length; _p++) {
                                var table = _q[_p];
                                if (table.questions && table.questions.length > 0) {
                                    for (var _r = 0, _s = table.questions; _r < _s.length; _r++) {
                                        var question = _s[_r];
                                        if (question[this.autocalculateFormulaVariable]
                                            && question[this.autocalculateFormulaVariable].length > 0) {
                                            for (var _t = 0, _u = question[this.autocalculateFormulaVariable]; _t < _u.length; _t++) {
                                                var f = _u[_t];
                                                x.push(f.questionnaire_asset_id.toString());
                                                x.push(f.formula.toString());
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (x.length > 0) {
            for (var _v = 0, x_1 = x; _v < x_1.length; _v++) {
                var y = x_1[_v];
                if (y.indexOf(',') < 0) {
                    this.autocalculateFormulaAssetIds.push(y);
                }
                else {
                    var p = y.split(",");
                    if (p && p.length > 0) {
                        for (var _w = 0, p_1 = p; _w < p_1.length; _w++) {
                            var q = p_1[_w];
                            if (!isNaN(parseInt(q))) {
                                this.autocalculateFormulaAssetIds.push(q);
                            }
                        }
                    }
                }
            }
        }
    };
    /**
     * Check if any of the MTA's child assets cannot be removed
     */
    Edq2Component.prototype.checkIfMTAChildCannotBeRemoved = function () {
        if (this.mta) {
            if (this.mta.qa_type === 'tab') {
                if (this.mta.sections && this.mta.sections.length > 0) {
                    for (var _i = 0, _a = this.mta.sections; _i < _a.length; _i++) {
                        var section = _a[_i];
                        if (this.checkIfSectionChildCannotBeRemoved(section)) {
                            return true;
                        }
                    }
                }
                return false;
            }
            else if (this.mta.qa_type === 'section') {
                return this.checkIfSectionChildCannotBeRemoved(this.mta);
            }
            else {
                return this.checkIfSubsectionTableChildCannotBeRemoved(this.mta);
            }
        }
    };
    /**
     * Check if any child asset of the section cannot be removed
     * @param section
     */
    Edq2Component.prototype.checkIfSectionChildCannotBeRemoved = function (section) {
        if (!section) {
            return false;
        }
        if (section.questions && section.questions.length > 0) {
            for (var _i = 0, _a = section.questions; _i < _a.length; _i++) {
                var question = _a[_i];
                if (this.displayLogicKeys.indexOf(question.id.toString()) > -1 || this.autocalculateFormulaAssetIds.indexOf(question.id.toString()) > -1) {
                    return true;
                }
            }
        }
        if (section.subsections && section.subsections.length > 0) {
            for (var _b = 0, _c = section.subsections; _b < _c.length; _b++) {
                var subsection = _c[_b];
                if (this.checkIfSubsectionTableChildCannotBeRemoved(subsection)) {
                    return true;
                }
            }
        }
        if (section.tables && section.tables.length > 0) {
            for (var _d = 0, _e = section.tables; _d < _e.length; _d++) {
                var table = _e[_d];
                if (this.checkIfSubsectionTableChildCannotBeRemoved(table)) {
                    return true;
                }
            }
        }
        return false;
    };
    /**
   * Check if any child asset of the subsection cannot be removed
   * @param subsection
   */
    Edq2Component.prototype.checkIfSubsectionTableChildCannotBeRemoved = function (parent) {
        if (!parent) {
            return false;
        }
        if (parent.questions && parent.questions.length > 0) {
            for (var _i = 0, _a = parent.questions; _i < _a.length; _i++) {
                var question = _a[_i];
                if (this.displayLogicKeys.indexOf(question.id.toString()) > -1 || this.autocalculateFormulaAssetIds.indexOf(question.id.toString()) > -1) {
                    return true;
                }
            }
        }
        return false;
    };
    Edq2Component.prototype.declineRemoval = function () {
        this.removalConfirmed = false;
        if (this.promptDeletionOfChildAssetsModal) {
            this.promptDeletionOfChildAssetsModal.hide();
        }
    };
    Edq2Component.prototype.confirmRemoval = function () {
        this.removalConfirmed = true;
        this.mta = JSON.parse(this.mtaTopLevelParentToRemoveString);
        this.changeAsset('remove', false, false);
    };
    /**
     * Mark child option assets removed_from_questionnaire = true / false depending on the action
     * @param action
     */
    Edq2Component.prototype.markChildOptions = function (action) {
        for (var index = 0; index < this.mta.options.length; index++) {
            if (this.mta.options[index].id) {
                if (action === 'remove') {
                    this.mta.options[index].removed_from_questionnaire = true;
                }
                else if (action === 'delete') {
                    this.mta.options[index].deleted = true;
                }
                else {
                    this.mta.options[index].removed_from_questionnaire = false;
                }
            }
            else {
                this.mta.options.splice(index, 1);
            }
            this.setMtaHasOtherOption();
        }
    };
    /**
     * Check whether the given MTA can be removed or not
     * If it is part of displayLogicKeys or autocalculate formulas it cannot be removed
     * @param action
     */
    Edq2Component.prototype.checkIfQuestionMTACannotBeRemoved = function (action) {
        if ((action === 'remove' || action === 'delete') && this.mta.qa_type === 'question' && this.displayLogicKeys.indexOf(this.mta.id.toString()) > -1) {
            this.commonService.showToast('error', 'question_part_of_display_logic_cannot_removed', {});
            return true;
        }
        if ((action === 'remove' || action === 'delete') && this.autocalculateFormulaAssetIds.indexOf(this.mta.id.toString()) > -1) {
            this.commonService.showToast('error', 'question_part_of_autocalculate_formula_cannot_removed', {});
            return true;
        }
        return false;
    };
    /**
     * If question is part of a table & the current formula being modified is from a differnet table,
     * then this question cannot be selected
     * @param question
     */
    Edq2Component.prototype.isInvalidTableQuestion = function (question) {
        if (question && question.is_table_question) {
            if (this.modifyAutocalculateFormulaForAsset) {
                if (this.modifyAutocalculateFormulaForAsset.parent_code !== question.parent_code) {
                    return true;
                }
            }
            else if (this.autocalculateFormulaForAssetExists) {
                if (this.autocalculateFormulaForAssetExists.parent_code !== question.parent_code) {
                    return true;
                }
            }
            else {
                return true;
            }
        }
        return false;
    };
    //added by Kiran for translation
    Edq2Component.prototype.setTranslatedLabelObjects = function () {
        var _this = this;
        this.translate.get('primary_action_btn_label').subscribe(function (result) {
            _this.primaryActionButtonLabelObj = result;
        });
        this.translate.get('mta_issues').subscribe(function (result) {
            _this.mtaIssuesObj = result;
        });
        this.translate.get('add_edit_mode').subscribe(function (result) {
            _this.addEditModeObj = result;
        });
    };
    Edq2Component.prototype.translateMTAIssues = function () {
        for (var _i = 0, _a = this.mtaIssues; _i < _a.length; _i++) {
            var issue = _a[_i];
            issue['translatedText'] = this.mtaIssuesObj[issue.code];
        }
    };
    Edq2Component.prototype.setNotReleasedQuestionnaire = function () {
        if (this.currentLocaleLanguageIdIndex == undefined || this.currentLocaleLanguageIdIndex == null || this.currentLocaleLanguageIdIndex < 0) {
            this.notReleasedSurveyInLanguage = 1;
        }
    };
    /**
     * Initiate change template function for this survey
     * @param template
     */
    Edq2Component.prototype.changeTemplate = function (template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var templates, x;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([this.surveyService.getEcosystemTemplates().toPromise()])];
                    case 1:
                        templates = _a.sent();
                        this.spinner.hide();
                        x = templates[0].message;
                        if (x && x.length > 0) {
                            if (this.isCampaign) {
                                this.ecosystemTemplates = x.filter(function (item) { return item.template_type === "campaign"; });
                            }
                            else {
                                this.ecosystemTemplates = x.filter(function (item) { return item.template_type === "snapshot"; });
                            }
                        }
                        //this.ecosystemTemplates = templates[0].message;
                        this.surveyTemplatesModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-md-firmenich' });
                        return [2 /*return*/];
                }
            });
        });
    };
    Edq2Component.prototype.goToSurveyManagement = function (projectID) {
        console.log('question', this.consQuestions.length);
        var size = Object.keys(this.checkedObject).length;
        console.log('checked object', size);
        var isAllConsSelected = false;
        if (size > 0 && this.consQuestions.length > 0) {
            if (size == this.consQuestions.length) {
                isAllConsSelected = true;
            }
        }
        var url = '/questionnaires/' + this.projectId;
        if (this.isCampaign) {
            this.router.navigate([url], { queryParams: { fromCampaign: true } });
        }
        else {
            this.router.navigate([url], { queryParams: { fromProject: true, isAllConsSelected: isAllConsSelected } });
        }
    };
    Edq2Component.prototype.closeSurveyTemplateModal = function (myForm) {
        // Hide the modal
        if (this.surveyTemplatesModal) {
            this.surveyTemplatesModal.hide();
        }
        myForm.reset();
        this.templateId = undefined;
    };
    // Take required action (use / preview) for the clicked template
    // If templateId == 0, it indicates that the global P2F-Roots template is clicked
    Edq2Component.prototype.templateAction = function (actionType, templateId, myForm) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(actionType == 'use')) return [3 /*break*/, 2];
                        // if (templateId != -1) {
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([this.surveyService.changeQuestionnaireForSurvey(this.surveyId, templateId).toPromise()])];
                    case 1:
                        requests = _a.sent();
                        if (requests[0].code == 'success') {
                            this.closeSurveyTemplateModal(myForm);
                            if (templateId != -1) {
                                this.commonService.showToast('success', 'success_questionnaire_created', {}, null, { "languages": requests[0].message });
                            }
                            this.ngOnInit();
                        }
                        else {
                            this.spinner.hide();
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    //added by Kiran for template selection
    Edq2Component.prototype.selectTemplate = function (el) {
        var radioBut = el.getElementsByTagName('input')[0];
        radioBut.click();
        if (radioBut.checked) {
            var allRadioButton = document.querySelectorAll('.template-card');
            for (var i = 0; i < allRadioButton.length; i++) {
                allRadioButton[i].classList.remove('template-card-selected');
            }
            el.classList.add('template-card-selected');
        }
        else {
            el.classList.remove('template-card-selected');
        }
    };
    /**
     * Update the 'label' attribute of the assetTypes objects depending on the locale
     * @param assetLabels
     */
    Edq2Component.prototype.updateAssetLabels = function (assetLabels) {
        this.assetTypeLabels = assetLabels;
        for (var i = 0; i < this.assetTypes.length; i++) {
            var assetType = this.assetTypes[i].type;
            if (assetType) {
                this.assetTypes[i]['label'] = assetLabels[assetType];
            }
            if (this.assetTypes[i].subTypes && this.assetTypes[i].subTypes.length > 0) {
                for (var j = 0; j < this.assetTypes[i].subTypes.length; j++) {
                    var assetSubType = this.assetTypes[i].subTypes[j].key;
                    if (assetSubType) {
                        this.assetTypes[i].subTypes[j]['label'] = assetLabels[assetSubType];
                    }
                }
            }
        }
    };
    //added for consolidation type selection
    Edq2Component.prototype.openConsolidationPopup = function (template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var questionArr, _i, _a, tab, _b, _c, section, _d, _e, q, obj, _f, _g, subsection, _h, _j, q, obj, _k, _l, table, _m, _o, q, obj, qarr, newRequest, request, _p, _q, q;
            return tslib_1.__generator(this, function (_r) {
                switch (_r.label) {
                    case 0:
                        this.spinner.show();
                        questionArr = [];
                        for (_i = 0, _a = this.questionnaire; _i < _a.length; _i++) {
                            tab = _a[_i];
                            for (_b = 0, _c = tab.sections; _b < _c.length; _b++) {
                                section = _c[_b];
                                for (_d = 0, _e = section.questions; _d < _e.length; _d++) {
                                    q = _e[_d];
                                    obj = {
                                        "id": q.id,
                                        "name": q.survey_q_asset_labels[0].label,
                                        "original_questionnaire_item_code": q.code,
                                        "qa_subtype": q.qa_subtype,
                                        "survey_id": q.survey_id
                                    };
                                    questionArr.push(obj);
                                }
                                for (_f = 0, _g = section.subsections; _f < _g.length; _f++) {
                                    subsection = _g[_f];
                                    for (_h = 0, _j = subsection.questions; _h < _j.length; _h++) {
                                        q = _j[_h];
                                        obj = {
                                            "id": q.id,
                                            "name": q.survey_q_asset_labels[0].label,
                                            "original_questionnaire_item_code": q.code,
                                            "qa_subtype": q.qa_subtype,
                                            "survey_id": q.survey_id
                                        };
                                        questionArr.push(obj);
                                    }
                                }
                                for (_k = 0, _l = section.tables; _k < _l.length; _k++) {
                                    table = _l[_k];
                                    for (_m = 0, _o = table.questions; _m < _o.length; _m++) {
                                        q = _o[_m];
                                        obj = {
                                            "id": q.id,
                                            "name": q.survey_q_asset_labels[0].label,
                                            "original_questionnaire_item_code": q.code,
                                            "qa_subtype": q.qa_subtype,
                                            "survey_id": q.survey_id
                                        };
                                        questionArr.push(obj);
                                    }
                                }
                            }
                        }
                        this.consQuestions = questionArr;
                        qarr = questionArr.map(function (item) { return item.id; });
                        newRequest = {
                            "questions": qarr
                        };
                        return [4 /*yield*/, Promise.all([
                                this.consReportService.getAllConsolidationTypes(this.projectId, newRequest).toPromise()
                            ])];
                    case 1:
                        request = _r.sent();
                        if (request[0].code == "success") {
                            this.checkedObject = {};
                            this.additionalTypeSelected = [];
                            this.page = 1;
                            for (_p = 0, _q = request[0].message; _p < _q.length; _p++) {
                                q = _q[_p];
                                if (q.cons_type && q.deleted === 0 && q.cons_type != 'Not required') {
                                    this.checkedObject[q.questionnaire_asset_id] = (typeof this.checkedObject[q.questionnaire_asset_id] === "undefined" ? "" : this.checkedObject[q.questionnaire_asset_id]) + q.cons_type + ", ";
                                }
                                if (!this.checkedObject[q.questionnaire_asset_id] && q.not_required === 1) {
                                    this.checkedObject[q.questionnaire_asset_id] = "Not required, ";
                                }
                                if (this.checkedObject[q.questionnaire_asset_id] && this.checkedObject[q.questionnaire_asset_id].indexOf("Not required") === -1 && q.not_required === 1) {
                                    this.checkedObject[q.questionnaire_asset_id] = (typeof this.checkedObject[q.questionnaire_asset_id] === "undefined" ? "" : this.checkedObject[q.questionnaire_asset_id]) + "Not required, ";
                                }
                            }
                        }
                        this.consQuestionPopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    Edq2Component.prototype.typeChanged = function (ele, val, qid) {
        var additonalTypeObject = {};
        if (val === "Not required") {
            var findType = void 0;
            for (var j = 0; j < this.additionalTypeSelected.length; j++) {
                if (this.additionalTypeSelected[j].qid == qid && this.additionalTypeSelected[j].type == val) {
                    findType = this.additionalTypeSelected[j];
                    if (ele.target.checked) {
                        this.additionalTypeSelected[j].notRequired = 1;
                    }
                    else {
                        this.additionalTypeSelected[j].notRequired = 0;
                    }
                }
            }
            if (!findType) {
                additonalTypeObject = {
                    "qid": qid,
                    "type": val,
                    "notRequired": (ele.target.checked) ? 1 : 0
                };
                this.additionalTypeSelected.push(additonalTypeObject);
            }
            if (ele.target.checked) {
                // document.getElementById(ele.path[4].id).classList.add('disabled');
                document.getElementById(ele.composedPath()[4].id).classList.add('disabled');
                ele.target.style.pointerEvents = "visible";
                var isQid = false;
                for (var i in this.checkedObject) {
                    if (i == qid) {
                        isQid = true;
                        var typeArr = this.checkedObject[i].split(', ');
                        typeArr.push(val);
                        typeArr = typeArr.filter(function (el) {
                            return el != "";
                        });
                        this.checkedObject[i] = typeArr.join(', ');
                    }
                }
                if (!isQid) {
                    this.checkedObject[qid] = val;
                }
            }
            else {
                // document.getElementById(ele.path[4].id).classList.remove('disabled');   
                document.getElementById(ele.composedPath()[4].id).classList.remove('disabled');
                for (var i in this.checkedObject) {
                    if (i == qid) {
                        var typeArr = this.checkedObject[i].split(', ');
                        for (var j = 0; j < typeArr.length; j++) {
                            if (val == typeArr[j]) {
                                typeArr.splice(j, 1);
                            }
                        }
                        typeArr = typeArr.filter(function (el) {
                            return el != "";
                        });
                        this.checkedObject[i] = typeArr.join(', ');
                    }
                }
            }
        }
        else {
            if (ele.target.checked) {
                var isQid = false;
                for (var i in this.checkedObject) {
                    if (i == qid) {
                        isQid = true;
                        var typeArr = this.checkedObject[i].split(', ');
                        typeArr.push(val);
                        typeArr = typeArr.filter(function (el) {
                            return el != "";
                        });
                        this.checkedObject[i] = typeArr.join(', ');
                    }
                }
                if (!isQid) {
                    this.checkedObject[qid] = val;
                }
                additonalTypeObject = {
                    "qid": qid,
                    "type": val,
                    "deleted": 0,
                    "notRequired": 0
                };
                this.additionalTypeSelected.push(additonalTypeObject);
            }
            else {
                for (var i in this.checkedObject) {
                    if (i == qid) {
                        var typeArr = this.checkedObject[i].split(', ');
                        for (var j = 0; j < typeArr.length; j++) {
                            if (val == typeArr[j]) {
                                typeArr.splice(j, 1);
                            }
                        }
                        typeArr = typeArr.filter(function (el) {
                            return el != "";
                        });
                        this.checkedObject[i] = typeArr.join(', ');
                    }
                }
                var findType = void 0;
                for (var j = 0; j < this.additionalTypeSelected.length; j++) {
                    if (this.additionalTypeSelected[j].qid == qid && this.additionalTypeSelected[j].type == val) {
                        findType = this.additionalTypeSelected[j];
                        this.additionalTypeSelected.splice(j, 1);
                    }
                }
                if (!findType) {
                    var additonalTypeObject_1 = {
                        "qid": qid,
                        "type": val,
                        "deleted": 1,
                        "notRequired": 0
                    };
                    this.additionalTypeSelected.push(additonalTypeObject_1);
                }
            }
        }
    };
    Edq2Component.prototype.saveConsTypeData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var typesToSave, _loop_2, this_2, _i, _a, item, typeRequest, requests;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinner.show();
                        typesToSave = [];
                        _loop_2 = function (item) {
                            var findItem = this_2.additionalTypeSelected.filter(function (item2) { return item2.qid == item.id; });
                            if (findItem && findItem.length > 0) {
                                for (var _i = 0, findItem_1 = findItem; _i < findItem_1.length; _i++) {
                                    var i = findItem_1[_i];
                                    var newObj = {
                                        "project_id": parseInt(this_2.projectId),
                                        "questionnaire_asset_id": i.qid,
                                        "survey_id": item.survey_id,
                                        "code": item.original_questionnaire_item_code,
                                        "cons_type": i.type,
                                        "label": item.name,
                                        "deleted": i.deleted,
                                        "is_def_cons": 1,
                                        "notRequired": i.notRequired
                                    };
                                    typesToSave.push(newObj);
                                }
                            }
                            else {
                                var newObj = {
                                    "project_id": parseInt(this_2.projectId),
                                    "questionnaire_asset_id": item.id,
                                    "survey_id": item.survey_id,
                                    "code": item.original_questionnaire_item_code,
                                    "cons_type": '',
                                    "label": item.name,
                                    "deleted": 0,
                                    "is_def_cons": 1,
                                    "notRequired": 0
                                };
                                typesToSave.push(newObj);
                            }
                        };
                        this_2 = this;
                        for (_i = 0, _a = this.consQuestions; _i < _a.length; _i++) {
                            item = _a[_i];
                            _loop_2(item);
                        }
                        typeRequest = {
                            "types": typesToSave
                        };
                        return [4 /*yield*/, Promise.all([
                                this.consReportService.saveConsolidationTypeData(this.projectId, typeRequest).toPromise()
                            ])];
                    case 1:
                        requests = _b.sent();
                        if (requests[0].code == "success") {
                            this.spinner.hide();
                            this.commonService.showToast('success', 'Consolidation type saved successfully!', { preventDuplicates: true });
                        }
                        this.closeConsModal();
                        return [2 /*return*/];
                }
            });
        });
    };
    Edq2Component.prototype.closeConsModal = function () {
        this.consQuestionPopup.hide();
    };
    Edq2Component.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['name'];
        var finalObj = {};
        if (this.consQuestionPopup === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    Edq2Component.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    Edq2Component.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        if (sortBy === 'name') {
            this.isAscendingName = false;
            this.consQuestions.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    Edq2Component.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        if (sortBy === 'name') {
            this.isAscendingName = true;
            this.consQuestions.sort(function (a, b) {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    //for download pdf of preview questionnaire
    Edq2Component.prototype.downloadQuestionnaireAsPDF = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pages, pdf, _loop_3, i, fileName;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        pages = document.querySelectorAll('.tabwise');
                        _loop_3 = function (i) {
                            var page;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        page = pages[i];
                                        return [4 /*yield*/, html2canvas(page).then(function (canvas) {
                                                var imgData = canvas.toDataURL("image/jpeg", 0.8);
                                                if (i == 0) {
                                                    pdf = new jsPDF({
                                                        unit: 'px',
                                                        format: [parseInt(canvas.style.width) + 175, parseInt(canvas.style.height) + 1000]
                                                    });
                                                    pdf.addImage(imgData, 'JPEG', 10, 10);
                                                }
                                                else {
                                                    pdf.addPage([parseInt(canvas.style.width) + 175, parseInt(canvas.style.height) + 1000], "p");
                                                    pdf.addImage(imgData, 'JPEG', 10, 10);
                                                }
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < pages.length)) return [3 /*break*/, 4];
                        return [5 /*yield**/, _loop_3(i)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        fileName = "P2F-Roots_" + this.project.name + "_" + this.questionnaireText;
                        pdf.save(fileName);
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    //To add a Grand Total question to a table
    Edq2Component.prototype.addGrandTotal = function (table, template) {
        var _a;
        if (table.questions && table.questions.length > 0) {
            var numericQuestions = table.questions.filter(function (item) { return item.qa_subtype == "number"; });
            if (numericQuestions.length > 0) {
                this.tableToModify = table;
                var langArray = this.initializeSurveyAssetLabels();
                for (var _i = 0, langArray_1 = langArray; _i < langArray_1.length; _i++) {
                    var item = langArray_1[_i];
                    if (item.language_id == 1) {
                        item.label = "Grand Total";
                    }
                }
                this.questionAddEdit = this.addEditModeObj['add_mode'];
                var assetToAdd = (_a = {
                        survey_id: this.surveyId,
                        code: uuidv4(),
                        parent_id: this.tableToModify.id,
                        parent_code: this.tableToModify.parent_code,
                        grand_total_qn_id: this.questionSelectedForGT,
                        qa_subtype: 'number'
                    },
                    _a[this.labelVariable] = langArray,
                    // [this.validationVariable]: [],
                    // [this.displayLogicVariable]: [],
                    // [this.autocalculateFormulaVariable]: [],
                    _a.is_table_question = true,
                    _a);
                this.gta = Object.assign({}, JSON.parse(JSON.stringify(assetToAdd))); // Set the gta (grand total) variable
                this.grandTotalModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
            }
            else {
                this.commonService.showToast('error', 'no_numeric_question_found', {});
            }
        }
        else {
            this.commonService.showToast('error', 'no_numeric_question_found', {});
        }
    };
    Edq2Component.prototype.saveGrandTotalQuestion = function () {
        var _this = this;
        if (!this.questionSelectedForGT) {
            this.gtaIssues = true;
        }
        else {
            this.spinner.show();
            this.gtaIssues = false;
            this.gta.grand_total_qn_id = this.questionSelectedForGT;
            // Send the changed assets to the API
            this.surveyService.saveGrandTotalQuestion(this.surveyId, this.gta)
                .subscribe(function (result) {
                if (result.code == 'success') {
                    _this.commonService.showToast('success', 'grand_total_question_saved', {});
                }
                if (result.code == 'fail') {
                    // TODO: Done: Caught in error handler. Let user know about errors
                    _this.commonService.showToast('error', 'generic_error', {});
                }
                _this.spinner.hide();
                _this.confirmGTExit();
            });
        }
    };
    Edq2Component.prototype.confirmGTExit = function () {
        if (this.grandTotalModal) {
            this.grandTotalModal.hide();
        }
        this.tableToModify = {};
        this.questionSelectedForGT = undefined;
        this.GTWizardDisplayedPanel = 1;
        this.gtaIssues = false;
    };
    Edq2Component.prototype.openUploadSurveyManualDocTemplate = function (template) {
        this.uploadSurveyManualModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-sm-firmenich' });
    };
    Edq2Component.prototype.closeUploadSurveyManualDoc = function () {
        this.uploadSurveyManualModal.hide();
    };
    //Check valid extension for uploaded file
    Edq2Component.prototype.ValidateSurveyManualFile = function (file) {
        var allowedExtensions = ['pdf'];
        var fileExtension = file.name.split('.').pop();
        var msg = null;
        if (fileExtension) {
            fileExtension = fileExtension.toString().toLowerCase();
        }
        else {
            msg = 'invalid_extension';
        }
        if (!msg && allowedExtensions.indexOf(fileExtension.toLowerCase()) === -1) {
            msg = 'invalid_extension';
        }
        if (!msg && file.size > 10485760) {
            msg = 'exceeds_size_limit';
        }
        return msg;
    };
    Edq2Component.prototype.convertFileIntoBlob = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        var reader = new FileReader();
                        reader.onload = function (e) {
                            var arrayBuffer = reader.result;
                            var blob = new Blob([arrayBuffer], { type: file.type });
                            resolve(blob);
                        };
                        reader.readAsArrayBuffer(file);
                    })];
            });
        });
    };
    /* file upload */
    Edq2Component.prototype.handleUploadFileDocFileInput = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileItem, msg, setFarmerImageData, fileBlob, fileNameElement;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fileItem = file.item(0);
                        msg = this.ValidateSurveyManualFile(fileItem);
                        setFarmerImageData = function (result, fileItem) {
                            _this.uploadedSurveyManualFile = result;
                            _this.uploadedSurveyManualFile.name = fileItem.name;
                        };
                        if (msg && msg.length > 0) {
                            this.toastr.warning(msg);
                            return [2 /*return*/];
                        }
                        if (!!fileItem.type.includes("application")) return [3 /*break*/, 1];
                        new Compressor(fileItem, {
                            // quality: environment.imageCompress.quality,
                            quality: 1,
                            maxWidth: environment.imageCompress.maxWidth,
                            maxHeight: environment.imageCompress.maxHeight,
                            mimeType: environment.imageCompress.mimeType,
                            convertSize: environment.imageCompress.convertSize,
                            success: function (result) {
                                setFarmerImageData(result, fileItem);
                            },
                            error: function (err) {
                                console.log(err.message);
                            },
                        });
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.convertFileIntoBlob(fileItem)];
                    case 2:
                        fileBlob = _a.sent();
                        setFarmerImageData(fileBlob, fileItem);
                        _a.label = 3;
                    case 3:
                        fileNameElement = document.getElementById("fileName");
                        fileNameElement.innerText = fileItem.name;
                        return [2 /*return*/];
                }
            });
        });
    };
    Edq2Component.prototype.saveSurveyManualDoc = function () {
        var _this = this;
        if (this.uploadedSurveyManualFile) {
            this.spinner.show();
            var formData = new FormData();
            formData.append('surveyManualDoc', this.uploadedSurveyManualFile, this.uploadedSurveyManualFile.name);
            this.surveyService.saveSurveyManualDoc(this.surveyId, formData).subscribe(function (result) {
                if (result.msg === "success") {
                    _this.spinner.hide();
                    _this.uploadSurveyManualModal.hide();
                    _this.commonService.showToast('success', 'survey_manual_doc_upload_successfully', {});
                    _this.uploadedSurveyManualFile = [];
                    _this.surveyManualUploadedFlag = true;
                }
                else {
                    _this.commonService.showToast('error', 'generic_error', {});
                    _this.spinner.hide();
                    _this.surveyManualUploadedFlag = false;
                }
            }, function (error) {
                _this.spinner.hide();
                _this.surveyManualUploadedFlag = false;
            });
        }
        else {
            this.commonService.showToast('warning', 'please_select_file', {});
        }
    };
    Edq2Component.prototype.updateSurveyManualRef = function (template, currentSection) {
        this.currentSec = currentSection;
        var findItem = this.enabledSections.findIndex(function (item) { return item === currentSection.id; });
        if (findItem === -1) {
            this.toEnable = 1;
        }
        else {
            this.toEnable = 0;
        }
        this.confirmSurveyManualModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-sm-firmenich' });
    };
    Edq2Component.prototype.confirmSurveyManualModalAction = function (selection) {
        var _this = this;
        if (selection === "Yes") {
            var payload = {
                sectionId: this.currentSec.id,
                toEnable: this.toEnable,
            };
            this.surveyService.updateSurveyManualRefWithSection(this.surveyId, payload).subscribe(function (result) {
                if (_this.toEnable === 1) {
                    _this.enabledSections.push(_this.currentSec.id);
                    _this.showSurveyManualTextRefText = _this.disableTxt + _this.disableTextTranslatedValue;
                    _this.currentSecFlex1isTrue = 1;
                }
                else {
                    var findIndex = _this.enabledSections.findIndex(function (item) { return item === _this.currentSec.id; });
                    _this.enabledSections.splice(findIndex, 1);
                    _this.showSurveyManualTextRefText = _this.enableTxt + _this.enableTextTranslatedValue;
                    _this.currentSecFlex1isTrue = 0;
                }
                _this.currentSec = "";
                _this.uploadedSurveyManualFile = [];
                _this.spinner.hide();
                _this.confirmSurveyManualModal.hide();
                _this.commonService.showToast('success', 'reference_updated_successfully', {});
                _this.currentSection.flex1 = _this.toEnable;
                var el = document.querySelector(".active-question");
                _this.rightPanelScroll(el);
            }, function (error) {
                _this.spinner.hide();
            });
        }
        else {
            this.closeConfirmSurveyManualModalAction();
        }
    };
    Edq2Component.prototype.closeConfirmSurveyManualModalAction = function () {
        this.confirmSurveyManualModal.hide();
    };
    return Edq2Component;
}());
export { Edq2Component };
