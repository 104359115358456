<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToDashboards()" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)"><span i18n-title="@@projectReporting" title="Project Reporting">...</span></li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToDashboards()"><span title="Dashboards">...</span></li>
      <li class="breadcrumb-item active" aria-current="page"><span>Collection Network</span></li>
    </ol>
  </nav>
</div>

<div class="content-wrap content-wrapper background margin-btm-20">
  <div class="form-design form-box">
    <div class="row">
      <div class="col-md-12 col-lg-9">
        <label class="group-title inline-block mrbtm-two">
          <span>Collection Network</span>
        </label>
      </div>
    </div>
    <div class="form-design form-box form-box-gray" >
      <div class="row mt-3">
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row height-83 flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                <img src="assets/online_images/village.webp" alt="Villages" />
              </div>
              <ng-container  *ngFor="let report of predefinedReports" >
                <div class="col-md-12 col-lg-4 font-big flex-center" *ngIf="report.reportId == 143">
                  <span class="" *ngIf="report?.series[0]['total_villages'] != null">{{report?.series[0]['total_villages'] | number:'1.0':'en-US'}}</span>
                  <span class="" *ngIf="report?.series[0]['total_villages'] == null">0</span>
                </div>
              </ng-container>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">Total Villages Covered</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row height-83 flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                <img src="assets/online_images/farmer2.webp" alt="farmer" />
              </div>
              <ng-container  *ngFor="let report of predefinedReports" >
                <div class="col-md-12 col-lg-4 font-big flex-center" *ngIf="report.reportId == 50">
                  <span class="" *ngIf="report?.series[0]['total_farmers'] != null">{{report?.series[0]['total_farmers'] | number:'1.0':'en-US'}}</span>
                  <span class="" *ngIf="report?.series[0]['total_farmers'] == null">0</span>
                </div>
              </ng-container>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">Total Farmers</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row height-83 flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                <img src="assets/online_images/crop.svg" alt="Area Crop" />
              </div>              
              <ng-container  *ngFor="let report of predefinedReports" >
                <div class="col-md-12 col-lg-4 font-big flex-center" *ngIf="report.reportId == 44">
                  <span class="" *ngIf="report?.series[0]['Surveyed Crop as Main Crop'] != null">{{report?.series[0]['Surveyed Crop as Main Crop'] | number: '1.0-0'}}</span>
                  <span class="" *ngIf="report?.series[0]['Surveyed Crop as Main Crop'] == null">0</span>
                  <span class="small"> %</span>
                </div>
              </ng-container>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">Vanilla as Main Crop</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row height-83 flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                <img src="assets/online_images/vine5.webp" alt="vine" />
              </div>
              <ng-container  *ngFor="let report of predefinedReports" >
                <div class="col-md-12 col-lg-4 font-big flex-center" *ngIf="report.reportId == 45">
                  <span class="" *ngIf="report?.series[0]['With Surveyed Crop for more than 3 years'] != null">{{report?.series[0]['With Surveyed Crop for more than 3 years'] | number: '1.0-0'}}</span>
                  <span class="" *ngIf="report?.series[0]['With Surveyed Crop for more than 3 years'] == null">0.0</span>
                  <span class="small"> %</span>
                </div>
              </ng-container>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">With Vanilla for more than 3 Years</div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row height-83 flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                <img src="assets/online_images/fields-no.webp" alt="fields-no" />
              </div>
              <ng-container  *ngFor="let report of predefinedReports" >
                <div class="col-md-12 col-lg-4 font-big flex-center" *ngIf="report.reportId == 144">
                  <span class="" *ngIf="report?.series[0]['avg_no_of_fields'] != null">{{report?.series[0]['avg_no_of_fields'] | number:'1.0':'en-US'}}</span>
                  <span class="" *ngIf="report?.series[0]['avg_no_of_fields'] == null">0</span>
                </div>
              </ng-container>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">Average No of Fields</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row height-83 flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                <img src="assets/online_images/size-of-field.webp" alt="size-of-field" />
              </div>
              <ng-container  *ngFor="let report of predefinedReports" >
                <div class="col-md-12 col-lg-4 font-big flex-center" *ngIf="report.reportId == 145">
                  <span class="" *ngIf="report?.series[0]['avg_size_of_fields'] != null">{{report?.series[0]['avg_size_of_fields']}}</span>
                  <span class="" *ngIf="report?.series[0]['avg_size_of_fields'] == null">0</span>
                  <!-- <span class="small ml-1" *ngIf="report?.series[0]['international_unit'] != null">{{report?.series[0]['international_unit'] == 'Hectare' ? ' ha' : null}}</span> -->
                </div>
              </ng-container>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">Average Size of Fields</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row height-83 flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                <img src="assets/online_images/age-of-field.webp" alt="age-of-field" />
              </div>              
              <ng-container  *ngFor="let report of predefinedReports" >
                <div class="col-md-12 col-lg-4 font-big flex-center" *ngIf="report.reportId == 146">
                  <span class="" *ngIf="report?.series[0]['Average_age_Fields'] != null">{{report?.series[0]['Average_age_Fields'] }} </span>
                  <span class="" *ngIf="report?.series[0]['Average_age_Fields'] == null">0</span>
                  <span class="small ml-1"> Years</span>
                </div>
              </ng-container>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">Average Age of Fields</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row height-83 flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                <img src="assets/online_images/vine3.webp" alt="# of vines" />
              </div>
              <ng-container  *ngFor="let report of predefinedReports" >
                <div class="col-md-12 col-lg-4 font-big flex-center" *ngIf="report.reportId == 147">
                  <span class="" *ngIf="report?.series[0]['avg_no_of_vines'] != null">{{report?.series[0]['avg_no_of_vines'] | number:'1.0':'en-US'}}</span>
                  <span class="" *ngIf="report?.series[0]['avg_no_of_vines'] == null">0</span>
                </div>
              </ng-container>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">Average No of Vines</div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 col-lg-12">
          <div #charts class="highcharts-no-border" style="height:250px"></div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row no-gutters flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                <img src="assets/online_images/forecasted.webp" class="height-83" alt="forecasted" />
              </div>
              <ng-container *ngFor="let report of predefinedReports" >
                <div class="col-md-12 col-lg-6 font-big text-center" *ngIf="report.reportId == 148">
                  <div class="mt-2 font-big">
                    <span class="" *ngIf="report?.series[0]['total_forecasted_production'] != null">{{report?.series[0]['total_forecasted_production']}}</span>
                    <span class="" *ngIf="report?.series[0]['total_forecasted_production'] == null">0</span>
                  </div>
                  <!-- <div class="mt-2">
                    <span class="small">Metric Ton</span>
                  </div> -->
                </div>
              </ng-container>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">Last Season Delivered Production</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row no-gutters flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                  <img src="assets/online_images/actual.webp" class="height-83" alt="actual production" />
              </div>
              <div class="col-md-12 col-lg-6 font-30 flex-center">
                <!-- <span class="">0</span> -->
                <!-- <span class="" *ngIf="report?.series[0]['total_forecasted_production'] == null">0</span> -->
              </div>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">Last Season Actual Production</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row no-gutters flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                <img src="assets/online_images/forecasted.webp" class="height-83" alt="production" />
              </div>
              <ng-container  *ngFor="let report of predefinedReports" >
                <div class="col-md-12 col-lg-6 font-big text-center" *ngIf="report.reportId == 149">
                  <div class="mt-2 font-big">
                    <span class="" *ngIf="report?.series[0]['total_forecasted_production'] != null">{{report?.series[0]['total_forecasted_production']}}</span>
                    <span class="" *ngIf="report?.series[0]['total_forecasted_production'] == null">0</span>
                  </div>
                  <!-- <div class="mt-2">
                    <span class="small">Metric Ton</span>
                  </div> -->
                </div>
              </ng-container>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">Current Season Forecasted Production</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="white-box">
            <div class="row no-gutters flex-center">
              <div class="col-md-12 col-lg-6 align-center">
                <img src="assets/online_images/actual.webp" class="height-83" alt="actual production" />
              </div>
              <div class="col-md-12 col-lg-4 font-big flex-center">
                <!-- <span class="">0</span> -->
                <!-- <span class="" *ngIf="report?.series[0]['total_forecasted_production'] == null">0</span> -->
              </div>
            </div>
            <div class="mt-3 mb-2 align-center font-20 semi-bold height-48 flex-center content-center">Current Season Actual Production</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
