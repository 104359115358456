import { Component,
  OnInit,
  OnDestroy,
  ViewChild, ElementRef, AfterViewInit, Renderer, ChangeDetectionStrategy, Renderer2, Inject, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Project, UserTypeServiceMapping } from '../../../models/project';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { chart } from 'highcharts';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
import { inherits } from 'util';
import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

@Component({
  selector: 'app-community-livelihood',
  templateUrl: './community-livelihood.component.html',
  styleUrls: ['./community-livelihood.component.css']
})
export class CommunityLivelihoodComponent implements OnInit {
  //@ViewChild('charts3') public chartElement03: ElementRef;
  //@ViewChild('charts4') public chartElement04: ElementRef;
  projects: any = [];
  project: any;
  projectId = 0;
  dashboardId;
  dashboardData = [];
  reportIDs = [];
  predefinedReports = [];
  reportWaterAccess = [];
  reportCookingSource = [];
  reportWasteManagement = [];
  reportHousing = [];
  reportHealthcare = [];
  reportElectricity = [];
  constructor(
    private readonly route: ActivatedRoute,
    private projectService: ProjectService,
    private toastrService: ToastrService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private translate: TranslateService,
    private changeDetectionRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private hcRService:HighchartReportsService,
    private readonly spinner: Ng4LoadingSpinnerService,
  ) {
    
  }

  ngOnInit() {
    this.dashboardId = this.route.snapshot.params['id2'];
    this.projectId = this.route.snapshot.params['id'];
    this.hcRService.allCharts = [];

    this.projectService.getProjectBasicInfo(this.projectId).subscribe(response =>{
      this.project = response.data;
      const projectObject = this.project.filter(item => item.id == this.projectId)[0];
      this.project = projectObject;
    });

    const requestObject = {
      //"dashboardId":this.dashboardId
      "dashboardId":this.dashboardId
    }
  this.spinner.show();
  this.hcRService.getDashboardDetailsById(requestObject).subscribe(response =>{
      //console.log("get Dashboard Data ", response);
      if(response.msg == 'success'){
          this.dashboardData = response.data;
        if(this.dashboardData.length > 0){
            //console.log("this.dashboardData ", this.dashboardData);
          const dashboardSelectedReports = [];
          for(let i=0;i< this.dashboardData[0].hc_report_dashboard_dtl.length;i++){
              dashboardSelectedReports.push(this.dashboardData[0].hc_report_dashboard_dtl[i]);
          //this.dashboardEditObject.section = dashboardData[0].hc_report_dashboard_dtl[0].section;
          }
          //console.log("dashboardSelectedReports ", dashboardSelectedReports);
          if(dashboardSelectedReports && dashboardSelectedReports.length > 0){
            for(let i=0;i<dashboardSelectedReports.length;i++){
              this.reportIDs.push(dashboardSelectedReports[i].report_id);      
            }
            //console.log(" this.reportIDs ", this.reportIDs);
            if(this.reportIDs && this.reportIDs.length > 0){
                this.genarateCharts(this.reportIDs);
            }
          }
        }         
      }
    });

  }

  async genarateCharts(reportIds){
    this.spinner.show();
    let requestObject ={};
    requestObject = {
        "reportID":reportIds,        
        "projectId":this.projectId
    };
    const chartsList = [];    

    this.hcRService.getReports(requestObject).subscribe(response =>{
        //console.log("response ", response);
        if(response.msg == 'success'){
          const reportsData = response.data;
            this.predefinedReports = response.data;
            //console.log("reportsData ", this.predefinedReports);
            if(reportsData && reportsData.length > 0){
                for(let i=0;i<reportsData.length;i++){
                    var seriesArr = [];
                    
                    if(reportsData[i].reportId === 25){
                        for(let j=0;j<reportsData[i].series.length;j++){                  
                            for (var prop in reportsData[i].series[j]) {                    
                              if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                if(Number(reportsData[i].series[0][prop]) > 0){
                                  const innerObj = {
                                    name:'',
                                    value:Number(''),
                                  };
                                  innerObj.name = prop;
                                  innerObj.value = Number(reportsData[i].series[0][prop]);
                                  this.reportWaterAccess.push(innerObj)
                                }                          
                              }
                            }                  
                          }
                        //console.log("reportWaterAccess ", this.reportWaterAccess);
                    }

                    if(reportsData[i].reportId === 29){
                        for(let j=0;j<reportsData[i].series.length;j++){                  
                            for (var prop in reportsData[i].series[j]) {                    
                              if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                if(Number(reportsData[i].series[0][prop]) > 0){
                                  const innerObj = {
                                    name:'',
                                    value:Number(''),
                                  };
                                  innerObj.name = prop;
                                  innerObj.value = Number(reportsData[i].series[0][prop]);
                                  this.reportCookingSource.push(innerObj)
                                }                          
                              }
                            }                  
                          }
                        //console.log("reportCookingSource ", this.reportCookingSource);
                    }

                    if(reportsData[i].reportId === 26){
                        for(let j=0;j<reportsData[i].series.length;j++){                  
                            for (var prop in reportsData[i].series[j]) {                    
                              if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                if(Number(reportsData[i].series[0][prop]) > 0){
                                  const innerObj = {
                                    name:'',
                                    value:Number(''),
                                  };
                                  innerObj.name = prop;
                                  innerObj.value = Number(reportsData[i].series[0][prop]);
                                  this.reportHousing.push(innerObj)
                                }                          
                              }
                            }                  
                          }
                        //console.log("reportHousing ", this.reportHousing);
                    }

                    if(reportsData[i].reportId === 141){
                        for(let j=0;j<reportsData[i].series.length;j++){                  
                            for (var prop in reportsData[i].series[j]) {                    
                              if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                if(Number(reportsData[i].series[0][prop]) > 0){
                                  const innerObj = {
                                    name:'',
                                    value:Number(''),
                                  };
                                  innerObj.name = prop;
                                  innerObj.value = Number(reportsData[i].series[0][prop]);
                                  this.reportElectricity.push(innerObj)
                                }                          
                              }
                            }                  
                          }
                        //console.log("reportElectricity ", this.reportElectricity);
                    }

                    // if(reportsData[i].reportId === 100){
                    //     for(let j=0;j<reportsData[i].series.length;j++){
                    //         console.log(reportsData[i].series[j])        
                    //         let innerObj = {
                    //         name:'',
                    //         value:Number(''),
                    //         };
                    //         innerObj.name = reportsData[i].series[j].key;
                    //         innerObj.value = Number(reportsData[i].series[j].value);
                    //         this.reportHealthcare.push(innerObj)               
                    //     }
                    //     console.log("reportHealthcare ", this.reportHealthcare);
                    // }

                    if(reportsData[i].reportId === 69 && reportsData[i].name == 'Waste Management Methods'){
                        for(let j=0;j<reportsData[i].series.length;j++){                  
                            for (var prop in reportsData[i].series[j]) {                    
                              if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                if(Number(reportsData[i].series[0][prop]) > 0){
                                  const innerObj = {
                                    name:'',
                                    value:Number(''),
                                  };
                                  innerObj.name = prop;
                                  innerObj.value = Number(reportsData[i].series[0][prop]);
                                  this.reportWasteManagement.push(innerObj)
                                }                          
                              }
                            }                  
                          }
                        //console.log("reportCookingSource ", this.reportWasteManagement);
                    }

                    if(reportsData[i].conf && reportsData[i].conf.length > 0){
                        let chartOptions = reportsData[i].conf[0].conf;
                        if(typeof reportsData[i].conf[0].conf !== 'object'){
                            chartOptions = JSON.parse(reportsData[i].conf[0].conf);                            
                        }

                        const healthInsurence  = [];

                                              
                        // chartOptions.exporting.buttons.contextButton.menuItems = contextMenuOptions;
                        // // chartOptions.chart.width = 320;
                        // chartOptions.chart.height = '50%';
                        // chartOptions.chart_type = chartOptions.chart.type;
                        // chartOptions.title.style= {
                        // fontSize: "16px",
                        // fontWeight: '600'
                        // };       
                        // chartOptions.reportId = reportsData[i].reportId; 
                        // if(reportsData[i].is_drilldown == 1){
                        // chartOptions.drilldown = reportsData[i].drilldown;
                        // }
                        // //chartOptions.reportTypeFrontend = currentType;
                        // chartOptions.chart.backgroundColor= '#fafafa';
                        // chartOptions.chart.spacingTop = 20;
                        // chartOptions.chart.spacingBottom = 20;
                        // chartOptions.reportName = reportsData[i].name;

                        // //console.log("chartOptions ", chartOptions);
                        // chartsList.push(chartOptions);
                        if(reportsData[i].name === 'Health Insurance Type'){
                            for(let j=0;j<reportsData[i].series.length;j++){  
                                //if(reportsData[i].series && reportsData[i].series.length > 1){
                                for (const prop in reportsData[i].series[0]) { 
                                    //console.log("prop ", prop);  
                                    //console.log("reportsData[i].series[0][prop] ", reportsData[i].series[j][prop]);  
                                    //console.log("reportsData[i].series[0].key", reportsData[i].series[j].key);                
                                    if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                        if(Number(reportsData[i].series[0][prop]) > 0){
                                            var newArray = [];
                                            newArray[0] = prop;
                                            newArray[1] = Number(reportsData[i].series[0][prop]);
                                            healthInsurence.push(newArray)
                                        }                          
                                    }
                                }                                
                                //}
                            }
                            const options = {
                                chart: {
                                  type: 'column',
                                  height:'200px'
                                },
                                title: {
                                    text: 'Insurance Type:',
                                    style: {
                                        fontSize: '18px'
                                    },
                                    align:'left',
                                    x:0,
                                    y:5
                                },
                                subtitle: {
                                    text: ''
                                },
                                xAxis: {
                                    type: 'category'
                                },
                                yAxis: {
                                    min: 0,
                                    title: {
                                        text: ''
                                    }
                                },
                                legend: {
                                    enabled: false
                                },
                                credits: {
                                    enabled: false
                                },
                                tooltip: {
                                    pointFormat: ''
                                },
                                exporting: {
                                  enabled: false
                                },
                                series: [{
                                    name: 'Health Insurance Type',
                                    color:'#50C1BF',
                                    data: [],
                                    dataLabels: {
                                        enabled: true,
                                        format: '{point.y}', // one decimal
                                        style: {
                                            fontWeight:'normal'
                                        }
                                    }
                                }]
                              };
    
                              options.series[0].data = healthInsurence;
    
                            //   let h = document.createElement("div");
                            //     // let newElement = this.chartElement01.nativeElement.appendChild(e); 
                            //     // let newElement2 = this.chartElement02.nativeElement.appendChild(f); 
                            //     let newElement4 = this.chartElement04.nativeElement.appendChild(h);
    
                            //     this.hcRService.createChart(newElement4, options);

                        }
                        

                    }else{
                        //console.log("conf 0 ");
                        const healthCareProviders = [];
                        
                        if(reportsData[i].name === 'Health Care Providers'){
                          const options ={
                                chart: {
                                  type: 'column',
                                  height:'200px'
                                },
                                title: {
                                    text: 'Healthcare Provider:',
                                    style: {
                                        fontSize: '18px'
                                    },
                                    align:'left',
                                    x:0,
                                    y:5
                                },
                                subtitle: {
                                    text: ''
                                },
                                xAxis: {
                                    type: 'category'
                                },
                                yAxis: {
                                    min: 0,
                                    title: {
                                        text: ''
                                    }
                                },
                                legend: {
                                    enabled: false
                                },
                                credits: {
                                    enabled: false
                                },
                                tooltip: {
                                    pointFormat: ''
                                },
                                exporting: {
                                  enabled: false
                                },
                                series: [{
                                    name: 'Healthcare Providers',
                                    color:'#50C1BF',
                                    data: [             
                                    ],
                                    dataLabels: {
                                        enabled: true,
                                        format: '{point.y}', // one decimal
                                        style: {
                                          fontWeight:'normal'
                                        }
                                    }
                                }]
                              };
                            
                            for(let j=0;j<reportsData[i].series.length;j++){  
                                //if(reportsData[i].series && reportsData[i].series.length > 1){
                                for (const prop in reportsData[i].series[j]) { 
                                    //console.log("prop ", prop);  
                                    //console.log("reportsData[i].series[0][prop] ", reportsData[i].series[j][prop]);  
                                    //console.log("reportsData[i].series[0].key", reportsData[i].series[j].key);                
                                    if (reportsData[i].series[j].hasOwnProperty(prop)) {
                                        if(Number(reportsData[i].series[j][prop]) > 0){
                                          const newArray = [];
                                            newArray[0] = reportsData[i].series[j].key;
                                            newArray[1] = Number(reportsData[i].series[j][prop]);
                                            healthCareProviders.push(newArray)
                                        }                          
                                    }
                                } 
                                
                                //}
                            }

                            //console.log("healthCareProviders ", healthCareProviders);
                            options.series[0].data = healthCareProviders;

                            // let g = document.createElement("div");
                            // //let h = document.createElement("div");
                            // // let newElement = this.chartElement01.nativeElement.appendChild(e); 
                            // // let newElement2 = this.chartElement02.nativeElement.appendChild(f); 
                            // let newElement3 = this.chartElement03.nativeElement.appendChild(g); 
                            // //let newElement4 = this.chartElement04.nativeElement.appendChild(h);

                            // this.hcRService.createChart(newElement3, options);
                            
                        }
                    }                   

                }
            }else{
                //this.chartElement01.nativeElement.innerHTML = '';
                this.hcRService.allCharts = [];
            }
            //console.log("chartsList ", chartsList);
            this.spinner.hide();
        } else {
          this.spinner.hide();
        }
    });
  }


  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }
  goToReportingManagement(projectID){
    const url = 'charts/' + this.projectId;
    this.router.navigate([url]);
  }
  goToDashboards(){
    const url="charts/dashboards/"+ this.projectId;
    this.router.navigate([url]);
  }

}
