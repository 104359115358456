<app-header></app-header>
<div class="content-wrapper">
  <h2> <span i18n="@@home1">HOME</span> / <span i18n="@@historicalData">Historical Data</span></h2>
  <div class="project_info">
    <div class="row">
      <div class="col-lg-3">
        <div class="panel panel-info">
          <div class="panel-heading">
              <span i18n="@@projectName">Project Name</span>
          </div>
          <div class="panel-body">
            <p>Mint</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="panel panel-warning">
          <div class="panel-heading" i18n="@@farmerName">
            Farmer Name
          </div>
          <div class="panel-body">
            <p>Mandeep Singh</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="panel panel-danger">
          <div class="panel-heading" i18n="@@lastDataCollectedDate">
            Last Data Collected Date
          </div>
          <div class="panel-body">
            <p>25th Feb 2018</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="panel panel-info">
          <div class="panel-heading" i18n="historicalData">
            History of Data
          </div>
          <div class="panel-body">
            <p>5</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="historyData row">


    <div class="col-lg-3" routerLink="">
      <img src="/assets/uploads/ProjectImg.webp" title="" alt="" />
      <h3>Dated : 25/02/2018</h3>
    </div>
    <div class="col-lg-3" routerLink="">
      <img src="/assets/uploads/ProjectImg.webp" title="" alt="" />
      <h3>Dated : 20/01/2018</h3>

    </div>
    <div class="col-lg-3" routerLink="">
      <img src="/assets/uploads/ProjectImg.webp" title="" alt="" />
      <h3>Dated : 05/12/2017</h3>

    </div>
    <div class="col-lg-3" routerLink="">
      <img src="/assets/uploads/ProjectImg.webp" title="" alt="" />
      <h3>Dated : 15/11/2018</h3>

    </div>
  </div>
  <div class="fltRgt" id="">
    <ul class="pagination">
      <li class="paginate_button previous disabled" aria-controls="dataTables-farmer" tabindex="0" id="dataTables-farmer_previous">
        <a i18n="@@previous" i18n-title="@@previous" href="#">Previous</a></li>
      <li class="paginate_button active" aria-controls="dataTables-farmer" tabindex="0"><a href="#">1</a></li>
      <li class="paginate_button next disabled" aria-controls="dataTables-farmer" tabindex="0" id="dataTables-farmer_next"><a
          href="#" i18n="@@next" i18n-title="@@next">Next</a></li>
    </ul>
  </div>
</div>