import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable } from 'rxjs';




@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private readonly count = 0;

  constructor(private readonly spinner: Ng4LoadingSpinnerService,
  @Inject(PLATFORM_ID) private readonly platformId: Object,
  @Inject(LOCALE_ID) private locale: string) {
      if (this.locale && this.locale.length > 2) {
          this.locale = this.locale.substr(0,2);
      }
  }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const fp = localStorage.getItem('fp');
        if (currentUser && currentUser.token) {
            if (this.locale == 'en-US'){
                this.locale = 'en';
            }
            request = request.clone({
                setHeaders: {
                   Authorization: `${currentUser.token}`,
                   'fp' : `${fp}`,
                   'locale': this.locale
                }
            });
        }
        if (currentUser && currentUser.token && (currentUser.role.id == 2 || currentUser.role.id == 3)) {
            request = request.clone({
                setHeaders: {
                   Authorization: `${currentUser.token}`,
                   'fp' : `${fp}`,
                   'locale': this.locale,
                   'ecosystem_id': (localStorage.getItem('ecosystem_id') && localStorage.getItem('ecosystem_id') != '0') ? localStorage.getItem('ecosystem_id') : '0'
                }
            });
        }
        return next.handle(request);
    }
}
