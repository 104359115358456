import { Component, OnInit, HostListener } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '../../services/user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FarmerService } from '../../services/farmer.service';
import { CommonService } from '../../services/common.service';
import { TrainingService } from '../../services/training.service';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'app-project-group-mapping',
  templateUrl: './project-group-mapping.component.html',
  styleUrls: ['./project-group-mapping.component.css']
})
export class ProjectGroupMappingComponent implements OnInit {
  isOnline: Observable<boolean>;
  projectGroups:any;
  projects;
  public id:any;
  public previousPaginationLabel;
  public nextPaginationLabel;
  fromNavId;
  public typeOfAdministrationArr: any[]; 
  typeOfAdministration = '';
  currentUser = this.userService.getCurrentUser();
  searchText = '';
  searchTextFarmers;
  confirmFarmerModal;
  loadingFarmers = false;
  farmers = [];  
  selectedFarmers = [];
  selectedFarmersCopy = [];
  tooltipText;
  selectDeselectLabels;
  managingFarmersForGroup;
  page = 1;
  itemsPerPage = 10;
  farmersListPage = 1;
  isAscending = true;
  isAscendingGroup = true;
  isAscendingProject = true;
  isAscendingType = true;
  isAscendingStatus = true;

  isAscendingFarmer = true;
  isAscendingIdFarmer = true;
  isAscendingNameFarmer = true;
  isAscendingTelFarmer = true;
  isAscendingDateFarmer = true;
  isAscendingAgeFarmer = true;
  isAscendingSexFarmer = true;
  isAscendingVillageFarmer = true;
  isAscendingVCFarmer = true;
  manageFarmersModal: BsModalRef;
  cancelFarmersModal: BsModalRef;
  addMappingModal: BsModalRef;
  public mappingAddObject ={
    "group":0,
    "type":0,
    "project":0,
    "status":0
  }
  disableAddButton = true;
  formIsEdited = false;
  uniqueGroupTypes = [];
  allGroups;
  isEdit = false;
  currentMappingID;
  ecosystem_id;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly userService: UserService, 
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly farmerService: FarmerService,
    private readonly commonService: CommonService,
    public modalService: BsModalService,
    private readonly trainingService: TrainingService,
    private readonly projectService: ProjectService,
  ) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.route.queryParamMap.subscribe(queryParams => {
      const qpObject = { ...queryParams.keys, ...queryParams };

      this.fromNavId = qpObject['params']['from'];

      this.translate.get('typeAdminTextArr').subscribe((val) => {
        this.typeOfAdministrationArr = val;
        if (this.fromNavId === '2') {
          this.typeOfAdministration = this.typeOfAdministrationArr[0];
        }
        else if (this.fromNavId === '3') {
          this.typeOfAdministration = this.typeOfAdministrationArr[1];
        }
      }, err => console.log('err', err));

    })

  this.isOnline = merge(
    of(navigator.onLine),
    fromEvent(window, 'online').pipe(mapTo(true)),
    fromEvent(window, 'offline').pipe(mapTo(false))
  );

  this.translate.get('previousPaginationLabel').subscribe(result => {
    this.previousPaginationLabel = result;
  });

  this.translate.get('nextPaginationLabel').subscribe(result => {
    this.nextPaginationLabel = result;
  });
  this.translate.get('select_deselect_object').subscribe(result => {
    this.selectDeselectLabels = result;
  });
   }

  async ngOnInit() {
    if (navigator.onLine) {
      this.spinner.show();
      this.ecosystem_id = localStorage.getItem('ecosystem_id');
      this.getMasterData();
      const request = await Promise.all([
        this.projectService.GetProjectsBasicInfo().toPromise(),
        this.trainingService.getAllGroups().toPromise()
      ]);
      if (request[0]['msg'] === 'success'){
        this.projects = request[0]['data'];
      }
      if (request[1]['msg'] === 'success'){
        this.allGroups = request[1]['data'];
      }
      this.spinner.hide();
    }
  }
  getMasterData(){
    this.trainingService.getProjectGroupMapping().subscribe(result => {    
      if (result['msg'] === 'success'){  
        this.projectGroups = result['data'];
        for(const item of this.projectGroups){
          item.groupName = item.training_certification_group.name;
          item.associatedProject = item.project.name;
          item.groupType = item.training_certification_group.multivalue_master.name;
          item.mappingStatus = item.status;
        }
      }
    })
  }

  editProjectGroupMapping(id, template){
    this.spinner.show();
    this.isEdit = true;
    this.currentMappingID = id;
    const curMapping = this.projectGroups.find(item => item.id === id);
    console.log('curMapping', curMapping)
    if(curMapping){
      this.mappingAddObject.group = curMapping.training_certification_group.id;
      this.mappingAddObject.project = curMapping.project.id;
      this.mappingAddObject.type = curMapping.training_certification_group.multivalue_master.id;
      this.mappingAddObject.status = curMapping.mappingStatus;
    }
    this.addMappingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    this.spinner.hide();
  }

  addProjectGroupMapping(template){
    this.addMappingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  saveProjectGroupMapping(){    
    this.spinner.show();
    const requestParams = {
        "group_id": this.mappingAddObject.group,
        "project_id": this.mappingAddObject.project,
        "status": this.mappingAddObject.status
      }
      console.log('requestParams', requestParams)
    
    this.trainingService.saveProjectGroupMapping(requestParams, this.isEdit, this.currentMappingID).subscribe(result => {
      if (result['msg'] === 'MAPPING_CREATED' || result['msg'] === 'MAPPING_UPDATED') {
        if(result['msg'] === 'MAPPING_CREATED'){
          this.commonService.showToast('success', 'success_master_data_created', {});
        } else {
          this.commonService.showToast('success', 'success_master_data_updated', {});
        }
        this.closePopUpYes();
        this.getMasterData();          
        this.spinner.hide();
      }
    })
  }
  closePopUp(form?: any){
    if(!this.isEdit){
      if(this.mappingAddObject.group != 0 || this.mappingAddObject.project != 0 || this.mappingAddObject.status != 0 || this.mappingAddObject.type != 0){
        this.formIsEdited = true;
      } else {
        this.resetMappingObject()
        this.addMappingModal.hide();
      }
    } else {
      if (form.dirty) {
        this.formIsEdited = true;
      } else {
        this.resetMappingObject()
        this.addMappingModal.hide();
      }
    }
  }
  closePopUpYes(){
    this.resetMappingObject()
    this.addMappingModal.hide();
    this.formIsEdited = false;
  }
  closePopUpNo(){
    this.formIsEdited = false;
  }

  resetMappingObject(){    
    this.mappingAddObject.group = 0;
    this.mappingAddObject.project = 0;
    this.mappingAddObject.type = 0;
    this.mappingAddObject.status = 0;
    this.disableAddButton = true; 
    this.isEdit = false;
  }

  async manageFarmers(project, template) {
    this.searchTextFarmers = ""
    this.confirmFarmerModal = true;
    if (!this.loadingFarmers) {
      this.spinner.show();
      this.loadingFarmers = true;
      this.managingFarmersForGroup = project;
      
      const requests = await Promise.all([
        this.farmerService.getFarmersByProjectBasicData(project.project_id).toPromise(),
        this.trainingService.getFarmerIdsMappedToGroup(project.id).toPromise(),
      ]);
      
      this.loadingFarmers = false;
      this.spinner.hide();
      
      if (requests[0]['msg'] === 'success') {
        this.farmers = requests[0].data.filter(item => !item.is_discontinued);
        for(const item of this.farmers){
          item.village_name = item.region_village.village_name
        }
        this.sortFarmers();
      }
      if (requests[1]['code'] === 'success') {
        this.selectedFarmers = requests[1].message;
        this.selectedFarmersCopy = JSON.parse(JSON.stringify(this.selectedFarmers));
      }
  
      if (this.farmers.length === 0) {
        this.commonService.showToast('info', 'no_farmers_regd', {});
      } else {
        this.farmersListPage = 1;
        this.tooltipText = undefined;
        this.manageFarmersModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
      }
    }
  }

  cancelManageFarmers(){
    let isEqual = false;
    for(const val of this.selectedFarmers){
      if(this.selectedFarmersCopy.indexOf(val) < 0){
        isEqual = true;
      }
    }
    if(isEqual){
      this.confirmFarmerModal = false;
    } else {
      this.declineManageFarmers();
    }
  }

  declineManageFarmers(): void {
    this.selectedFarmers = [];
    this.farmers = [];
    this.managingFarmersForGroup = undefined;
    if (this.manageFarmersModal) {
      this.manageFarmersModal.hide();
    }
  }

  confirmManageFarmers(): void {
    this.trainingService.mapFarmersToGroup(this.managingFarmersForGroup.id, this.selectedFarmers)
    .subscribe(result => {
      console.log('result', result);
      if (result['code'] === 'success') {
        this.commonService.showToast('success', 'changes_saved', {});
        this.declineManageFarmers();
      }
    })
  }

  sortFarmers(): void {
    this.farmers.sort((a, b) => {
      if (a.cr_date < b.cr_date) {
        return 1;
      }
      if (a.cr_date > b.cr_date) {
        return -1;
      }
      return 0;
    });
  }

  showAddButton(isGroup?){
    if(isGroup && this.mappingAddObject.group != 0){
      const getGroupType = this.allGroups.find(item => item.id == this.mappingAddObject.group);
      this.mappingAddObject.type = getGroupType.multivalue_master.id
    }
    if(this.mappingAddObject.group != 0 && this.mappingAddObject.project != 0 && this.mappingAddObject.status != 0){
      this.disableAddButton = false;  
    } else {
      this.disableAddButton = true; 
    }
  }

  exportToExcel() : void {
    this.trainingService.exportToExcelPGM().subscribe(result => {
      console.log('result', result);
      var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
      const blob = new Blob([result], {type: contentType});
      const linkdownload = document.createElement('a');
      linkdownload.href = URL.createObjectURL(blob);
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();
      var today1 = `${dd}-${mm}-${yyyy}`;
      this.translate.get('group_mapping').subscribe((val) => {
        linkdownload.download =`P2F-Roots_${val}_ExcelExport_${today1}.xlsx`;
        linkdownload.style.display = 'none';
        document.body.appendChild(linkdownload);
        linkdownload.click();
      }, err => console.log('err', err));
    }, err=> console.log('err', err))
  }

  // Select / deselect all farmers in the array
  toggleAllFarmers(): void {
    // If all farmers are already selected, then deselect all farmers
    if (this.selectedFarmers.length === this.farmers.length) {
      this.selectedFarmers = [];
    }
    // If all farmers are NOT already selected, select all the farmers
    else {
      this.selectedFarmers = [];
      for (const val of this.farmers) {
        this.selectedFarmers.push(val.id);
      }
    }
    this.setTooltipText();
  }

  setTooltipText(): void {
    if (this.farmers && this.selectedFarmers) {
      if (this.selectedFarmers.length === this.farmers.length) {
        // Deselect farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.deselect} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.deselect} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      } else {
        // Select farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.select} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.select} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      }
    }
  }

   // Toggle a particular farmer's selection status
   toggleThisFarmerSelect(id): void {
    if (this.selectedFarmers.indexOf(id) > -1) {
      this.selectedFarmers = this.selectedFarmers.filter(item => item !== id);
    }
    else {
      this.selectedFarmers.push(id);
    }
    this.setTooltipText();
  }

  confirmCloseFarmerNo(){
    this.confirmFarmerModal = true;
  }

  @HostListener('window:keyup', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if(this.confirmFarmerModal){
        this.cancelManageFarmers();
      } else {
        this.confirmCloseFarmerNo();
      }
      if(this.addMappingModal){
        this.closePopUp()
      }
    }
  }

  sortBy(sortOn: string) {

    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);

  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    if (sortBy === 'groupname') {
      this.isAscendingGroup = false;
      this.isAscendingProject = true;
      this.isAscendingType = true;
      this.isAscendingStatus = true;
      this.projectGroups.sort((a, b) => {
        if (a.groupName.toLowerCase() < b.groupName.toLowerCase()) {
          return 1;
        }
        if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'associatedproject') {
      this.isAscendingGroup = true;
      this.isAscendingProject = false;
      this.isAscendingType = true;
      this.isAscendingStatus = true;
      this.projectGroups.sort((a, b) => {
        if (a.associatedProject.toLowerCase() < b.associatedProject.toLowerCase()) {
          return 1;
        }
        if (a.associatedProject.toLowerCase() > b.associatedProject.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'grouptype') {
      this.isAscendingGroup = true;
      this.isAscendingProject = true;
      this.isAscendingType = false;
      this.isAscendingStatus = true;
      this.projectGroups.sort((a, b) => {
        if (a.groupType.toLowerCase() < b.groupType.toLowerCase()) {
          return 1;
        }
        if (a.groupType.toLowerCase() > b.groupType.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'mappingstatus') {
      this.isAscendingGroup = true;
      this.isAscendingProject = true;
      this.isAscendingType = true;
      this.isAscendingStatus = false;
      this.projectGroups.sort((a, b) => {
        if (a.mappingStatus.toLowerCase() < b.mappingStatus.toLowerCase()) {
          return 1;
        }
        if (a.mappingStatus.toLowerCase() > b.mappingStatus.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }

  descendic(sortBy: string) {
    this.isAscending = true;
    if (sortBy === 'groupname') {
      this.isAscendingGroup = true;
      this.isAscendingProject = false;
      this.isAscendingType = false;
      this.isAscendingStatus = false;
      this.projectGroups.sort((a, b) => {
        if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) {
          return 1;
        }
        if (a.groupName.toLowerCase() < b.groupName.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'associatedproject') {
      this.isAscendingGroup = false;
      this.isAscendingProject = true;
      this.isAscendingType = false;
      this.isAscendingStatus = false;
      this.projectGroups.sort((a, b) => {
        if (a.associatedProject.toLowerCase() > b.associatedProject.toLowerCase()) {
          return 1;
        }
        if (a.associatedProject.toLowerCase() < b.associatedProject.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'grouptype') {
      this.isAscendingGroup = false;
      this.isAscendingProject = false;
      this.isAscendingType = true;
      this.isAscendingStatus = false;
      this.projectGroups.sort((a, b) => {
        if (a.groupType.toLowerCase() > b.groupType.toLowerCase()) {
          return 1;
        }
        if (a.groupType.toLowerCase() < b.groupType.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'mappingstatus') {
      this.isAscendingGroup = false;
      this.isAscendingProject = false;
      this.isAscendingType = false;
      this.isAscendingStatus = true;
      this.projectGroups.sort((a, b) => {
        if (a.mappingStatus.toLowerCase() > b.mappingStatus.toLowerCase()) {
          return 1;
        }
        if (a.mappingStatus.toLowerCase() < b.mappingStatus.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }

  getGridFilters() {
    const gridColums = ['groupName','associatedProject','groupType', 'mappingStatus'];
    
    const finalObj = {};
    if (this.projectGroups === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  farmerSortBy(sortOn: string) {
    this.isAscendingFarmer ? this.ascendicFarmer(sortOn) : this.descendicFarmer(sortOn);
  }

  ascendicFarmer(sortBy: string) {
    this.isAscendingFarmer = false;

    this.isAscendingIdFarmer = sortBy !== 'id';
    this.isAscendingNameFarmer = sortBy !== 'name';
    this.isAscendingTelFarmer = sortBy !== 'official_id';
    this.isAscendingDateFarmer = sortBy !== 'cr_date';
    this.isAscendingAgeFarmer = sortBy !== 'age';
    this.isAscendingSexFarmer = sortBy !== 'sex';
    // Sort of new fields added
    this.isAscendingVillageFarmer = sortBy !== 'village_name';
    this.isAscendingVCFarmer = sortBy === 'village_collectors'
    // Sort of new fields added
    if (sortBy === 'name') {
      this.farmers.sort((a, b) => {
        if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
          return 1;
        }
        if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
      this.farmers.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'village_name') {
      this.farmers.sort((a, b) => {
        if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
          return 1;
        }
        if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }

  descendicFarmer(sortBy: string) {
    this.isAscendingFarmer = true;

    this.isAscendingIdFarmer = sortBy === 'id';
    this.isAscendingNameFarmer = sortBy === 'name';
    this.isAscendingTelFarmer = sortBy === 'official_id';
    this.isAscendingDateFarmer = sortBy === 'cr_date';
    this.isAscendingAgeFarmer = sortBy === 'age';
    this.isAscendingSexFarmer = sortBy === 'sex';
    // Sort of new fields added
    this.isAscendingVillageFarmer = sortBy === 'village_name';
    this.isAscendingVCFarmer = sortBy === 'village_collectors'
    // Sort of new fields added
    if (sortBy === 'name') {
      this.farmers.sort((a, b) => {
        if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
          return 1;
        }
        if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
      this.farmers.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'village_name') {
      this.farmers.sort((a, b) => {
        if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
          return 1;
        }
        if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }

  getGridFiltersFarmers() {
    const gridColums = ['farmer_code', 'name', 'official_id', 'age', 'village_name','village_collectors', 'cr_date'];
    const finalObj = {};
    if (this.farmers === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchTextFarmers;
      });
    }
    return finalObj;
  }

  trackByGroupName(index, dataItem) {
    return dataItem.groupName;
  }
  trackByProjectName(index, dataItem) {
    return dataItem.groupName;
  }
  trackByTypeName(index, dataItem) {
    return dataItem.groupName;
  }
  trackByStatusName(index, dataItem) {
    return dataItem.groupName;
  }

}
