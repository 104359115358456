<app-header></app-header>
  <div class="project-header clearfix">

    <nav aria-label="breadcrumb" class="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-icon">
          <span class="btn-back" title="Back" i18n-title="@@back"  (click)="redirectToProjectManagement()" aria-current="page">
            <i class="utilicon utilicon-arrow-alt-left"></i>
          </span>
        </li>
        <li class="breadcrumb-icon">
          <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
        </li>
        <!-- Breadcrumb updated-->
        <li class="breadcrumb-item"><a href="#" routerLink="/goto/{{fromNavId}}" [attr.title]="typeOfAdministration">{{typeOfAdministration | slice:0:15}}...</a></li>
        <li class="breadcrumb-item"><a (click)="redirectToProjectManagement()" i18n-title="@@projectManagement" title="Project Management">...</a></li>
        <li *ngIf="project?.id > 0; else addProject" class="breadcrumb-item active" aria-current="page">{{project.name}}</li>
        <ng-template #addProject><li *ngIf="projectId == 0" i18n="@@addProject" class="breadcrumb-item active" aria-current="page">Add Project</li></ng-template>
      </ol>
    </nav>
  </div>


  <div class="clearfix"></div>
  <div class="content-wrap content-wrapper">
    <form name="projectForm" #projectForm="ngForm" class="ng-untouched ng-pristine ng-invalid form-design" novalidate (ngSubmit)="projectForm.form.valid && (project.product_type>0 && project.country_id>0) && submit()"
      enctype="multipart/form-data">

      <div class="form-box">

        <label i18n="@@projectInfo" class="group-title">Project Information</label>

        <div class="row">

          <div class="col-lg-6 col-md-12">
            <div class="form-group" [ngClass]="{ 'has-error': Project_Name.invalid && (Project_Name.dirty || Project_Name.touched || projectForm.submitted)}">

              <label for="text-result"><span i18n="@@projectNameOrCode">Project Name or Code</span><span class="mandatory">*</span></label>

              <input type="text" name="Project_Name" autofocus appAutofocus placeholder="Enter value" i18n-placeholder="@@enterValue" class="form-control" [(ngModel)]="project.name"
                #Project_Name="ngModel" maxlength="50" required>

              <div class="error" *ngIf=" Project_Name.invalid && (Project_Name.dirty || Project_Name.touched || projectForm.submitted)">
                <span i18n="@@enterValidInput">Enter valid input.</span>
              </div>

            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': Description.invalid && (Description.dirty || Description.touched || projectForm.submitted) }">
              <label for="text-result"><span i18n="@@description">Description</span> <span class="mandatory">*</span></label>

              <textarea class="form-control has-counter" #textareavalue
              name="Description" [(ngModel)]="project.description" #Description="ngModel" i18n="@@enterText" placeholder="Enter text" i18n-placeholder="@@enterText" [attr.minLength]="10" [attr.maxLength]="1000" required></textarea>
              <div class="textarea-counter float-right">
                <span class="value">{{textareavalue?.value?.length}}</span>/1000
              </div>
              <div class="error" *ngIf="Description.invalid && (Description.dirty || Description.touched || projectForm.submitted)">
                <span i18n="@@enterValidInput">Enter valid input.</span>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12" *ngIf="currentUser?.role?.id > 2;else sysAdminEAdminProduct">
            <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': Product.invalid && (Product.dirty || Product.touched || projectForm.submitted)}">
              <label for="text-result"><span i18n="@@product">Product</span><span class="mandatory">*</span></label>
                <select [disabled]="true" required class="form-control" [(ngModel)]="project.product_id" name="Product" #Product
                [ngClass]="(project.product_id==0? 'ng-invalid':'ng-valid')"
                  onchange="this.setAttribute('required','')" (change)="setProductType($event); getSupplierMappings(null, null)">
                  <option value="0" class="option" selected i18n="@@select">Select Product</option>
                  <option *ngFor="let item of products; trackBy: trackById" [ngValue]="item.id">{{item.name}}</option>
                </select>
                <div class="error" *ngIf="Product.value==0 && (Product.dirty || Product.touched || projectForm.submitted)">Select valid input.</div>
            </div>
          </div>
          <ng-template #sysAdminEAdminProduct>
            <div class="col-lg-6 col-md-12">
              <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': Product.invalid && (Product.dirty || Product.touched || projectForm.submitted)}">
                <label for="text-result"><span i18n="@@product">Product</span><span class="mandatory">*</span></label>
                  <select [disabled]="projectId > 0 && project.product_id > 0" required class="form-control" [(ngModel)]="project.product_id" name="Product" #Product
                  [ngClass]="(project.product_id==0? 'ng-invalid':'ng-valid')"
                    onchange="this.setAttribute('required','')" (change)="setProductType($event); getSupplierMappings(null, null)">
                    <option value="0" class="option" selected i18n="@@select">Select Product</option>
                    <option *ngFor="let item of products; trackBy: trackById" [ngValue]="item.id">{{item.name}}</option>
                  </select>
                  <div class="error" *ngIf="Product.value==0 && (Product.dirty || Product.touched || projectForm.submitted)">Select valid input.</div>
              </div>
            </div>
          </ng-template>

          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': productType.value==0 && (productType.dirty || productType.touched || projectForm.submitted)}">

              <label for="text-result"><span i18n="@@typeOfProduct">Type of Product</span></label>

              <select disabled required id="select-normal" class="form-control select2" [(ngModel)]="project.product_type" name="productType" #productType
                [ngClass]="(project.product_type==0? 'ng-invalid':'ng-valid')" onchange="this.setAttribute('required','')">
                <option value="0" class="option" selected i18n="@@select">Select</option>
                <option *ngFor="let item of productTypes; trackBy: trackById" [ngValue]="item.id">{{item.name}}</option>
              </select>
              <div class="error" *ngIf="productType.value==0 && (productType.dirty || productType.touched || projectForm.submitted)">
                <span i18n="@@selectValidInput">Select valid input.</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12" *ngIf="currentUser?.role?.id > 2;else sysAdminEAdminSupplier">
            <div class="form-group no-margin-hr">
              <label><span i18n="@@supplier">Supplier</span><span class="mandatory">*</span></label>
              <select [disabled]="true" required class="form-control" [(ngModel)]="project.supplier_id" name="Supplier" #Supplier
              [ngClass]="(project.supplier_id==0? 'ng-invalid':'ng-valid')"
                onchange="this.setAttribute('required','')" (change)="getSupplierMappings(null, null)">
                <option value="0" class="option" selected i18n="@@select">Select Supplier</option>
                <option *ngFor="let sup of suppliers; trackBy: trackById" [ngValue]="sup.id">{{sup.name}}</option>
              </select>
              <div class="error" *ngIf="Supplier.value==0 && (Supplier.dirty || Supplier.touched || projectForm.submitted)" i18n="@@selectValidInput">Select valid input.</div>
            </div>
          </div>
          <ng-template #sysAdminEAdminSupplier>
            <div class="col-lg-6 col-md-12">
              <div class="form-group no-margin-hr">
                <label><span i18n="@@supplier">Supplier</span><span class="mandatory">*</span></label>
                <select [disabled]="projectId > 0 && project.supplier_id > 0" required class="form-control" [(ngModel)]="project.supplier_id" name="Supplier" #Supplier
                [ngClass]="(project.supplier_id==0? 'ng-invalid':'ng-valid')"
                  onchange="this.setAttribute('required','')" (change)="getSupplierMappings(null, null)">
                  <option value="0" class="option" selected i18n="@@select">Select Supplier</option>
                  <option *ngFor="let sup of suppliers; trackBy: trackById" [ngValue]="sup.id">{{sup.name}}</option>
                </select>
                <div class="error" *ngIf="Supplier.value==0 && (Supplier.dirty || Supplier.touched || projectForm.submitted)" i18n="@@selectValidInput">Select valid input.</div>
              </div>
            </div>
          </ng-template>
          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': region.invalid && (region.dirty || region.touched || projectForm.submitted)}">
              <label for="text-result"><span i18n="@@area">Area</span><span class="mandatory">*</span></label>

                <select class="form-control" [(ngModel)]="project.region_id" name="region" #region [ngClass]="(project.region_id==0? 'ng-invalid':'ng-valid')"
                onchange="this.setAttribute('required','');" (change)="setCurrency()" #region="ngModel" required>
                  <option value="0" class="option" selected i18n="@@select">Select</option>
                  <option *ngFor="let item of ecosystemRegionData; trackBy: trackById" [ngValue]="item.id">{{item.region_name}}</option>
                </select>
              <div class="error" *ngIf="region.value == '' && (region.dirty || region.touched || projectForm.submitted)">
                <span i18n="@@enterValidInput">Enter valid input.</span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="form-box">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr">
              <label for="text-result"><span i18n="@@region">Region</span></label>
              <input type="text" name="regionState" placeholder="Enter value" i18n-placeholder="@@enterValue" class="form-control" [(ngModel)]="project.region_state"
                #regionState="ngModel" maxlength="100">
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': currency.invalid && (currency.dirty || currency.touched || projectForm.submitted)}">
              <label for="text-result"><span i18n="@@projectCurrency">Project Currency</span><span class="mandatory">*</span></label>
              <select required id="select-normal" disabled class="form-control select2" [(ngModel)]="project.currency_code" name="Currency" #currency="ngModel"
              [ngClass]="(project.currency_code==''? 'ng-invalid':'ng-valid')" onchange="this.setAttribute('required','');" #currency="ngModel">
                <option value="" class="option" selected i18n="@@select">Select</option>
                <option *ngFor="let item of currencyArr; trackBy: trackById" [ngValue]="item.currency_code">{{item.currency_code}} / {{item.currency_name}}</option>
              </select>
              <div class="error" *ngIf="currency.invalid && (currency.dirty || currency.touched || projectForm.submitted)">
                <span i18n="@@enterValidInput">Enter valid input.</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr">
              <label for="text-result" i18n="@@projectStartDate">Project Start Date</label>
                <div class="form-group">
                  <div class="dateIcon" (click)="isOpen = !isOpen">
                    <input bsDatepicker placement="top" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue' }" [isOpen]="isOpen" class="form-control"
                    [(ngModel)]="project.start_date" [ngModelOptions]="{standalone: true}" placeholder="DD-MM-YYYY" readonly>
                    <i class="utilicon utilicon-calendar-alt"></i>
                  </div>
                </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr">
              <label for="text-result" i18n="@@projectStatus">Project Status</label>
              <select class="form-control" [(ngModel)]="project.status_id" name="status" #status="ngModel">
                <option value="0" class="option" selected i18n="@@select">Select</option>
                <option *ngFor="let item of projectStatus; trackBy: trackById" [ngValue]="item.id">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-md-12" *ngIf="isTablet">
            <div class="form-group no-margin-hr text-left">
                <label class="control-label"><span i18n="@@photo">Photo</span>
                  <span class="desc"><span i18n="@@imgMaxSize" class="mr-1"></span>
                    <span *ngIf="online$ | async; else offlineMsg"></span>
                    <ng-template #offlineMsg>
                      <span class="text-red" i18n="@@imageAvailableOnline">Available when online</span>
                    </ng-template>
                  </span>
                </label>
                <!--custom upload button-->
                <div class="upload-btn-wrapper" *ngIf="online$ | async; else disabledInput" #fileInput >
                  <div *ngIf="isDesktopDevice;else tabletDevice">
                    <button class="btnr" i18n-title="@@noFileChosen" (click)="fileChangeEvent($event)">
                      <span i18n="@@chooseFiles">Choose Files</span>
                    </button>
                    <span *ngIf="uploadingFileName; else nofiles">{{uploadingFileName}}</span>
                    <ng-template #nofiles>
                        <span i18n="@@noFileChosen">No file Chosen</span>
                    </ng-template>
                    <input type="file" class="absolute-input" id="fileInput" (change)="fileChangeEvent($event)"
                    accept="image/*" data-max-size="10485760" multiple="false" title="{{noFileChosenDefault}}" i18n-title="@@noFileChosen" />
                  </div>
                  <ng-template #tabletDevice>
                    <label for="fileInput">
                      <span><i class="utilicon utilicon-camera-light" style="font-size: 2.2em;"></i></span>
                      <span class="upload_file" *ngIf="uploadingFileName">{{uploadingFileName}}</span>
                      
                      <input type="file" class="" id="fileInput" (change)="fileChangeEvent($event)"
                      accept="image/*" capture="camera" style="display:none" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}" />
                    </label>
                  </ng-template>
                </div>
                <ng-template #disabledInput>
                  <!--custom upload button-->
                  <div class="upload-btn-wrapper" #fileInput >
                      <button class="btnr" i18n-title="@@noFileChosen" (click)="fileChangeEvent($event)">
                        <span i18n="@@chooseFiles">Choose Files</span>
                      </button>
                      <span *ngIf="uploadingFileName; else nofiles">{{uploadingFileName}}</span>
                      <ng-template #nofiles>
                          <span i18n="@@noFileChosen">No file Chosen</span>
                      </ng-template>
                      <input disabled type="file" class="absolute-input" id="fileInput"
                      accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}" />
                  </div>
                </ng-template>
              </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': projectestimatedyield.invalid && (projectestimatedyield.dirty || projectestimatedyield.touched || projectForm.submitted) }">
              <label for="text-result"><span i18n="@@projectEstimatedYield">Project Estimated Yield</span></label>

              <input type="number" name="projectestimatedyield" placeholder="Enter value" i18n-placeholder="@@enterValue" class="form-control projectEstimatedYield" [(ngModel)]="project.estimated_yield"
                #projectestimatedyield="ngModel" maxlength="30" style="width:24%;" pattern="^[0-9]+(\.[0-9]{1,2})?$">
              
                <select disabled class="form-control select2 projectEstimatedYield project_est_weight_area" [(ngModel)]="project.estimated_yield_weight_uom" name="weight" #weight="ngModel"
                [ngClass]="(project.estimated_yield_weight_uom==0? 'ng-invalid':'ng-valid')" onchange="this.setAttribute('required','');">
                  <option value="0" class="option" selected i18n="@@select">Select</option>
                  <option *ngFor="let item of projectYieldWeightUnitsArray; trackBy: trackByTmpId" [ngValue]="item.tmp_id">{{item.name}}</option>
                </select>
                <span style="font-size: 40px;vertical-align: middle;padding: 0 6px;color: #b9bcc0;">/</span>
                <select disabled class="form-control select2 projectEstimatedYield project_est_weight_area" [(ngModel)]="project.estimated_yield_area_uom" name="areadropdown"
              [ngClass]="(project.estimated_yield_area_uom==0? 'ng-invalid':'ng-valid')" onchange="this.setAttribute('required','');" #areadropdown="ngModel">
                <option value="0" class="option" selected i18n="@@select">Select</option>
                <option *ngFor="let item of projectYieldAreaUnitsArray; trackBy: trackByTmpId" [ngValue]="item.tmp_id">{{item.name}}</option>
              </select>
              
              <div class="error" *ngIf="projectestimatedyield.invalid && (projectestimatedyield.dirty || projectestimatedyield.touched || projectForm.submitted)">
                <span i18n="@@enterValidInput">Enter valid input.</span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="unitsMasterArray && unitsMasterArray.length > 0">
        <div *ngFor="let item of unitsMasterArray" class="form-box">
          <label class="group-title" style="display: inline" *ngIf="flagEn">{{item.unitType.name}} Units*</label>
          <label class="group-title" style="display: inline" *ngIf="flagEs">Unidades de {{item.unitType.name}}*</label>
          <label class="group-title" style="display: inline" *ngIf="flagFr">Unités de {{item.unitType.name}}*</label>
          <label class="group-title" style="display: inline" *ngIf="flagZh">{{item.unitType.name}} 单位*</label>
          <label class="group-title" style="display: inline" *ngIf="flagTa">{{item.unitType.name}} அலகுகள்*</label>
          <label class="group-title" style="display: inline" *ngIf="flagMg">Units* {{item.unitType.name}}</label>
          <button [disabled]="item?.unitOptions.length - item?.unitsInProject.length == 0" class="btn btn-secondary btn-sm fltRgt addBtn" type="button" value="Add" (click)="addUnitDynamic(item.unitType); $event.stopPropagation()"
           ><span i18n="@@add2">Add </span><i class="utilicon utilicon-plus"></i>
          </button>

          <div class="clearfix"></div>
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="table-wrapper">
                  <table class="table table-one-line form-table">
                    <tbody>
                      <tr *ngFor="let projectUOM of item.unitsInProject; let i=index; trackBy:trackByIndex">
                        <td style="width:30%">
                          <div class="form-group" style="padding-bottom: 16px;">
                            <label for="text-result" i18n="@@unitName">Unit Name</label>
                            <input type="text" disabled [id]="projectUOM['code']" [name]="projectUOM['code']" required placeholder="Enter value" i18n-placeholder="@@enterValue"
                            [(ngModel)]="projectUOM['name']" class="form-control width-measure">
                          </div>
                        </td>
                        <td style="width:30%">
                          <div class="form-group" [ngClass]="{ 'has-error': !projectUOM.international_value || projectUOM.international_value == '' && projectForm.submitted ||
                          (projectUOM.international_value && projectUOM.international_value.toString().indexOf('.') > -1 &&
                          projectUOM.international_value.toString().substring(projectUOM.international_value.toString().indexOf('.')).length > 5) }"
                          style="padding-bottom: 10px;">
                            <label for="text-result" i18n="@@internationalConversionFactor">International Conversion Factor</label>
                            <div class="input-group">
                                <input [id]="projectUOM.tmp_id" type="text" disabled [name]="projectUOM.tmp_id" required placeholder="Enter value" i18n-placeholder="@@enterValue"
                                [(ngModel)]="projectUOM.international_value" class="form-control width-measure" pattern="^\d+(\.\d{1,4})?$"
                                customMin="0" step="1" customMax="999999" (change)="updateUnitsMasterArrayCopy($event)">
                              <div class="input-group-append">
                                <span class="input-group-text" id="basic-addon2">{{projectUOM.international_unit}}</span>
                              </div>
                            </div>
                            <div class="error" *ngIf="!projectUOM.international_value || projectUOM.international_value == '' && (projectForm.submitted)">
                              <span i18n="@@enterValidInput">Enter valid input.</span>
                            </div>
                            <div class="error" *ngIf="projectUOM.international_value && projectUOM.international_value.toString().indexOf('.') > -1 &&
                                projectUOM.international_value.toString().substring(projectUOM.international_value.toString().indexOf('.')).length > 5">
                              <span i18n="@@upToFourDecimalsOnly">Up to 4 decimal places permitted</span>
                            </div>
                          </div>
                        </td>
                        <td style="width:20%; text-align: center">
                          <div class="form-group" style="padding-bottom: 10px;">
                            <label for="radio_results" i18n="@@setAsProjectDefaultDefault">Set as Project Default</label>
                            
                            <input type="radio" [name]="projectUOM?.code" [checked]="projectUOM.is_default == true" (change)="changeDefaultProjectUOM(projectUOM)"
                              class="px">

                          </div>
                        </td>
                        <td style="width:20%; text-align: center">
                          <div class="form-group">
                            <label for="radio_results" i18n="@@actions">Actions</label>
                            <button class="utilicon utilicon-trash-alt" title="Delete" i18n-title="@@delete" [disabled]="item.unitsInProject.length < 2" (click)="deleteUnit2(projectUOM); $event.stopPropagation()"></button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>


        </div>
      </div>



      <div *ngIf="!hideSupplierMappingGrid">
      <div class="clearfix"></div>
      <hr class="breakline">

      <div class="form-box">
            <label class="group-title" i18n="@@userTypeNServiceMapping">User Type &amp; Service Mapping</label>
            <div class="row">
                <div class="col-lg-12 col-md-12">

                    <div class="partner-services clearfix">
                        <div class="table-wrapper ng-star-inserted">

                            <table class="table table-hover table-one-line">
                              <thead>
                                  <tr>
                                      <th style="width:24%" scope="col"><button class="oc-all" type="button" (click)="serviceOpenCloseToggleAll()">
                                        <i *ngIf="allClosed; else showMinusIcon" class="utilicon utilicon-plus-light"></i>
                                        <ng-template #showMinusIcon><i class="fa fa-minus"></i></ng-template>
                                      </button><span i18n="@@services">Services</span></th>
                                      <th *ngFor="let partner of supplierRoles" scope="col" style="width:19%">
                                        <span class="form-check" style="display: inline;">
                                          <input type="checkbox" class="form-check-input" [checked]="allCheckedUserTypes.indexOf(partner.id) > -1" (change)="toggleAllServicesForUserType(partner.id)" container="body" placement="right">
                                          <label class="form-check-label"> &nbsp;</label>
                                        </span>
                                        {{partner.name}}
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of masterServiceComponentArray" [class.noComponents]="item.noComponents" [class.comp-open]="!item.services_id && item.opened" [class.comp-closed]="!item.services_id && !item.opened">
                                  <td *ngIf="!item.services_id || rowsToShow.indexOf(item.id) > -1">
                                    <strong (click)="serviceOpenCloseToggle(item)" [class.parent-mapping]="!item.services_id" [class.child-mapping]="item.services_id">
                                      <i class="utilicon"></i>
                                      {{item.name}}
                                    </strong>
                                  </td>
                                  <td *ngFor="let partner of supplierRoles">
                                    <span class="form-check" *ngIf="!item.services_id || rowsToShow.indexOf(item.id) > -1">
                                      <span *ngIf="item.services_id || item.noComponents; else toggleComponentCheckboxes">
                                        <span *ngIf="item.noComponents; else indentThis">
                                          <input type="checkbox" [disabled]="item.is_modifiable == 'N'" class="form-check-input" [checked]="(item.services_id && checkedObj['C_' + item.id + '_' + partner.id]) || (!item.services_id && checkedObj['S_' + item.id + '_' + partner.id])" (change)="psmChecked(partner.id, item.id, item.services_id ? item.services_id : null)" container="body" placement="right">
                                          <label class="form-check-label"> &nbsp;</label>
                                        </span>
                                        <ng-template #indentThis>
                                            <span class="hline"></span>
                                          <input  type="checkbox" [disabled]="item.is_modifiable == 'N'" class="form-check-input child-table-input" [checked]="(item.services_id && checkedObj['C_' + item.id + '_' + partner.id]) || (!item.services_id && checkedObj['S_' + item.id + '_' + partner.id])" (change)="psmChecked(partner.id, item.id, item.services_id ? item.services_id : null)" container="body" placement="right">
                                          <label class="form-check-label child-table-check"> &nbsp;</label>
                                        </ng-template>
                                      </span>
                                      <ng-template #toggleComponentCheckboxes>
                                        <span>
                                          <input type="checkbox" [disabled]="item.is_modifiable == 'N'" class="form-check-input" [checked]="(item.services_id && checkedObj['C_' + item.id + '_' + partner.id]) || (!item.services_id && checkedObj['S_' + item.id + '_' + partner.id]) || allCheckedUserTypesServices.indexOf('S' + partner.id + item.id) > -1" (change)="toggleServiceComponentsForUserType2(partner.id, item.id)" container="body" placement="right">
                                          <label class="form-check-label"> &nbsp;</label>
                                        </span>
                                      </ng-template>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                          </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
      </div>
      <hr class="breakline">
      <div class="form-box actions">

        <button [disabled]="savingProject || !projectForm.valid || supplier_project_services_components_mapping?.length < 1 || project.region_id < 1 || disableProjectButton" *ngIf="project.id > 0; else saveProjectButton" class="btn btn-primary" title="Update Project" i18n-title="@@updateProject" type="submit" i18n="@@updateProject"> Update Project </button>
        <ng-template #saveProjectButton>
          <button [disabled]="savingProject || !projectForm.valid || supplier_project_services_components_mapping?.length < 1 || project.region_id < 1 || disableProjectButton" class="btn btn-primary"  title="Save Project" i18n-title="@@saveProject" type="submit" i18n="@@saveProject"> Save Project </button>
        </ng-template>
        <button class="btn btn-secondary"  title="Cancel" i18n-title="@@cancel" i18n="@@cancel"  type="button" (click)="cancel(confirmCancelTemplate)">
          Cancel </button>
      </div>

    </form>
    <ng-template #confirmCancelTemplate>
      <div class="modal-body text-center">
        <h3 i18n="@@discardUnsavedChanges">
          Discard unsaved changes?
        </h3>
        <button type="button" title="Yes" i18n-title="@@yes" class="btn btn-secondary mr-1" (click)="confirmModal('yes')" i18n="@@yes">Yes</button>
        <button type="button" title="No" i18n-title="@@no" class="btn btn-primary cancel-focus" autofocus appAutofocus  (click)="confirmModal('no')" i18n="@@no">No</button>
      </div>
    </ng-template>
  </div>


  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="z-index: 9999">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          
          <h4 class="modal-title" id="myModalLabel" i18n="@@error">Error</h4>
        </div>
        <div class="modal-body">
          <ul>
            <li class="red" i18n="@@projectNameMissing">Project Name is missing</li>
            <li class="red" i18n="@@projectManagerMissing">Project Manager is missing</li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" i18n="@@ok"  i18n-title="@@ok">Ok</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
