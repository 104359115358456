
import { Subject, Subscription, merge, fromEvent } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { Component,
  OnInit,
  OnDestroy,
  ViewChild, ElementRef, AfterViewInit, Renderer2, Inject, TemplateRef, LOCALE_ID } from '@angular/core';

import { mapTo } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import {Project, ProjectUOM} from '../../models/project';

import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService} from '../../services/user.service';

import { ToastrService, ActiveToast } from 'ngx-toastr';
import { SuppliersService } from '../../services/suppliers.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})

export class SetupComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild ('Country') country;
  @ViewChild ('Supplier') supplier ;
  // @ViewChild('fileInput') fileInputVariable: any;
  @ViewChild('projectForm') projectForm;
  @ViewChild('productType') product_type;
  @ViewChild('f_project_manager') projectManager;

  private readonly subject = new Subject<boolean>();
  online$: Observable<boolean>;

  public isOpen = false;
  public dateFormat = 'DD-MMM-YYYY';
  public savingProject = false

  projectId = 0;
  project = new Project();
  products = [];
  suppliers = [];
  projectStatus = [];
  countries = [];
  currencyArr = [];
  services = [];
  serviceComponents = [];
  roles = [];
  supplierRoles = [];
  productTypes = [];
  project_manager = [];
  partners = [];
  masterServiceComponentArray = [];
  rowsToShow = [];
  units = [];
  unitsDuplicate;
  unittypes = [];

  Weight: ProjectUOM[] = [];
  Length: ProjectUOM[] = [];
  Volume: ProjectUOM[] = [];
  Area: ProjectUOM[] = [];
  partnerServiceMapping: any = [];
  user_type_services_components_mapping: any = [];
  supplier_project_services_components_mapping = [];

  currentUser = this.userService.getCurrentUser();
  filesToUpload: Array<File> = [];

  uploadingFileName;

  subscription: Subscription;
  error = '';
  modalDisplay = 'none' ;

  faServices = ['e-Pay', 'Farmer Profiling', 'Geo Mapping', 'Scan ID Card', 'Sourcing & Collection', 'Traceability'];
  pmServices = ['Farmer Profiling', 'Print ID Cards', 'Reporting & Analytics', 'Scan ID Card'];

  faServicesCode = ['EPAY', 'FMPR', 'GEOM', 'SCID', 'SRCL', 'TRCY'];
  pmServicesCode = ['FMPR', 'PRID', 'RPAN', 'SCID'];

  product_name: string;

  public showMainSpinner = false;

  public intUnitValue;

  private readonly unsubscribe: Subject<void> = new Subject();

  public partnersArray = [];
  public servicesArray = [];
  public componentsArray = [];

  public checkedObj = {};
  public allCheckedUserTypes = [];
  public allCheckedUserTypesServices = [];

  public allComponentsOfThisServiceSelected = [];
  public serviceComponentsMapping = [];

  public unitsMasterArray = [];
  unitsMasterArrayCopy;
  public projectUnitsMaster = [];
  public projectUOMChanged;
  public projectUOMObj = {};

  public mappingsForSupplier = {};
  public hideSupplierMappingGrid = true;
  public userTypeMappingsCount;
  public servicesWithoutComponents;
  public ecosystemSettingsData;
  public projectYieldWeightUnitsArray;
  public projectYieldAreaUnitsArray;
  public ecosystemRegionData:any = [];
  public ecosystem_id;

  public confirmCancelModal : BsModalRef;
  public allClosed = false;

  noFileChosenDefault; // default text for input file component

  flagEn = false;
  flagEs = false;
  flagFr = false;
  flagZh = false;
  flagTa = false;
  flagMg = false;

  fromNavId;
  deviceInfo;
  isMobile;
  isTablet;
  isDesktopDevice;
  //Breadcrumb changes added
  public typeOfAdministrationArr: any[]; 
  typeOfAdministration = '';

  constructor(private readonly projectService: ProjectService, private readonly commonService:  CommonService,
  private readonly router: Router, private readonly elementRef: ElementRef,
  private readonly renderer: Renderer2, private readonly userService: UserService, private readonly loc: Location,
  private readonly _route: ActivatedRoute, private readonly spinner: Ng4LoadingSpinnerService,
  private readonly toastrService: ToastrService, @Inject(LOCALE_ID) private readonly locale: string, private readonly translate: TranslateService,
  private readonly suppliersService: SuppliersService, private readonly modalService: BsModalService, private readonly localeService: BsLocaleService,
  private deviceService: DeviceDetectorService
  ) {

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.translate.get('file_upload_component_default_txt').subscribe(result => {
      this.noFileChosenDefault = result; // default text for the file input component
    });

    this.localeService.use(this.locale);

    const localLang = this.loc.prepareExternalUrl('').replace(/\//g,'');

    if(localLang === 'en') {
      this.flagEn = true;
    } else if (localLang === 'es') {
        this.flagEs = true;
    } else if (localLang === 'fr') {
        this.flagFr = true;
    } else if (localLang === 'zh') {
      this.flagZh = true;
    } else if (localLang === 'ta') {
      this.flagTa = true;
    } else if (localLang === 'mg') {
      this.flagMg = true;
    } else {
      this.flagEn = true;
    }

    this._route.queryParamMap.subscribe(queryParams => {
      const qpObject = { ...queryParams.keys, ...queryParams };

      this.fromNavId = qpObject['params']['from'];
      //console.log('this.fromNavId', this.fromNavId);
      //navigation changes added
      this.translate.get('typeAdminTextArr').subscribe((val) => {
        this.typeOfAdministrationArr = val;
      }, err => console.log('err', err));

      if (this.fromNavId == 2) {
        this.typeOfAdministration = this.typeOfAdministrationArr[0];
      }
      else if (this.fromNavId == 3) {
        this.typeOfAdministration = this.typeOfAdministrationArr[1];
      }
    });

    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();

}

  async ngOnInit() {

    this.scrollToTop();

    if (!navigator.onLine) {
      setTimeout(() => { 
        this.commonService.showToast('warning', 'offline_project_warning', {}); 
      });
      this.router.navigate(['/dashboard']);
      return;
    }
    setTimeout(() => this.spinner.show(), 0);
    const a = this._route.snapshot.params['id'];
    this.projectId = a ? +a : 0;

    if (this.projectId > 0) {
      const request = await Promise.all([
        this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
        this.projectService.getProjectDashboardData(this.projectId).toPromise(),
        this.projectService.getProjectProfilingData(this.projectId).toPromise(),
        this.projectService.getProjectServicesMappings(this.projectId).toPromise()
      ]);
      if (request[0]['msg'] !== 'success' || request[1]['msg'] !== 'success'|| request[2]['msg'] !== 'success'|| request[3]['msg'] !== 'success') {
        this.commonService.showToast('error', 'generic_error', {});
      } else {
        this.project = {...request[0].data[0], ...request[1].data[0], ...request[2].data[0], ...request[3].data[0]};

          if (this.project.ProjectUOM) {
            for (let i = 0; i < this.project.ProjectUOM.length; i++) {
              let international_value_string = this.project.ProjectUOM[i]['international_value'].toString();
              international_value_string = international_value_string.replace(/(\.[0-9]*?)0+$/, "$1");
              this.project.ProjectUOM[i]['international_value'] = +international_value_string;
              this.project.ProjectUOM[i]['name'] = this.project.ProjectUOM[i]['uom'];
            }
          }
          if (this.project['start_date']) {
            this.project['start_date'] = new Date(this.project['start_date']);
          }
          if (this.project['end_date']) {
            this.project['end_date'] = new Date(this.project['end_date']);
          }
          this.project.status_id = this.project.status_id > 0 ? this.project.status_id : 0;
          this.init();
        }
    } 
    else {
      this.init();
    }



  }

  ngAfterViewInit(): void {
    this.spinner.hide();

    const elements: any[] = this.elementRef.nativeElement.querySelectorAll('select[required]');

    setTimeout(() => {
      elements.forEach(e => {
        if (e.value === '0') {
          this.renderer.addClass(e, 'ng-invalid');
        }
      });
    }, 1000);

  }

  init() {
    if (this.project.id > 0) {
      this.services = this._route.snapshot.data.commonData.services.data;
      this.products = this._route.snapshot.data.commonData.products.data;
      this.countries = this._route.snapshot.data.commonData.countries.data;
      this.projectStatus = this._route.snapshot.data.commonData.status.data;
      this.suppliers = this._route.snapshot.data.commonData.suppliers.data;
      this.productTypes = this._route.snapshot.data.commonData.productType.data;
      this.currencyArr = this._route.snapshot.data.commonData.currency.data;
      this.project_manager = this._route.snapshot.data.commonData.projectmanager.data;
      this.serviceComponents = this._route.snapshot.data.commonData.serviceComponents.data;

      if(this.currentUser.role.id > 2){
        //Product and supplier autopopulated
        if(this.products[0].id > 0){
          this.project.product_id = this.products[0].id;
        }else{
          this.project.product_id = 0;
        }
        if(this.suppliers[0].id > 0){
          this.project.supplier_id = this.suppliers[0].id;
        }else{
          this.project.supplier_id = 0;
        }
      }

      //product type auto populated
      if (this.project.product_id) {
        if (this.project.product_id > 0) {
          const product = this.products.filter(item => item.id == this.project.product_id);
          if (product && product.length > 0) {
            const productItem = product[0];
            if (productItem) {
              this.project.product_type = productItem.product_type_id;
            }
          }
        }
        else {
          this.project.product_type = 0;
        }
      }
      
      // Get project data
      if (this.project.id > 0) {
        for (let i = 0; i < this.unitsMasterArray.length; i++) {
          this.unitsMasterArray[i].unitsInProject = this.project.ProjectUOM.filter(x => x.unit_type_id == this.unitsMasterArray[i]['unitType'].id);
          for (let j = 0; j < this.unitsMasterArray[i].unitsInProject.length; j++) {
            this.unitsMasterArray[i].unitsInProject[j]['tmp_id']  = +this.commonService.getRandomNum().toString().slice(2, 6);
          }
        }
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
        this.setInternationalUnits();

        this.project.product_name = this.products.find(x => x.id === this.project.product_id).name;

        this.getSupplierMappings(this.project.supplier_id, this.project.product_id);
        const servicesWithoutComponentsTemp = this.supplier_project_services_components_mapping.filter(item => item.components_id == null);

        this.servicesWithoutComponents = servicesWithoutComponentsTemp.reduce((unique, o) => {
          if(!unique.some(obj => obj.user_type_id === o.user_type_id || obj.services_id === o.services_id)) {
            unique.push(o);
          }
          return unique;
        },[]);
      } else {
        this.servicesWithoutComponents = [];
        this.user_type_services_components_mapping = [];
        this.addUnitNew();
      }
    }
    else {
      this.commonService.getSuppliersProductsProjectSetup()
      .subscribe(result => {
        this.suppliers = result.data.suppliers;
        this.products = result.data.products;
        //Product and supplier autopopulated
        if(this.currentUser.role.id > 2){
          if(this.products[0].id > 0){
            this.project.product_id = this.products[0].id;
          }else{
            this.project.product_id = 0;
          }
          if(this.suppliers[0].id > 0){
            this.project.supplier_id = this.suppliers[0].id;
          }else{
            this.project.supplier_id = 0;
          }
        }        
        
        this.services = this._route.snapshot.data.commonData.services.data;
        this.countries = this._route.snapshot.data.commonData.countries.data;
        this.projectStatus = this._route.snapshot.data.commonData.status.data;
        this.productTypes = this._route.snapshot.data.commonData.productType.data;
        this.currencyArr = this._route.snapshot.data.commonData.currency.data;
        this.project_manager = this._route.snapshot.data.commonData.projectmanager.data;
        this.serviceComponents = this._route.snapshot.data.commonData.serviceComponents.data;
        //product type autopopulated
        if (this.project.product_id) {
          if (this.project.product_id > 0) {
            const product = this.products.filter(item => item.id == this.project.product_id);
            if (product && product.length > 0) {
              const productItem = product[0];
              if (productItem) {
                this.project.product_type = productItem.product_type_id;
              }
            }
          }
          else {
            this.project.product_type = 0;
          }
        }
        this.project['region_id']=0;
        this.project['estimated_yield_weight_uom']=0;
        this.project['estimated_yield_area_uom']=0;
        // Get project data
        if (this.project.id > 0) {
          for (let i = 0; i < this.unitsMasterArray.length; i++) {
            this.unitsMasterArray[i].unitsInProject = this.project.ProjectUOM.filter(x => x.unit_type_id == this.unitsMasterArray[i]['unitType'].id);
            for (let j = 0; j < this.unitsMasterArray[i].unitsInProject.length; j++) {
              this.unitsMasterArray[i].unitsInProject[j]['tmp_id']  = +this.commonService.getRandomNum().toString().slice(2, 6);
            }
          }
          this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);


          this.project.product_name = this.products.find(x => x.id === this.project.product_id).name;

          this.getSupplierMappings(this.project.supplier_id, this.project.product_id);
          const servicesWithoutComponentsTemp = this.supplier_project_services_components_mapping.filter(item => item.components_id == null);

          this.servicesWithoutComponents = servicesWithoutComponentsTemp.reduce((unique, o) => {
            if(!unique.some(obj => obj.user_type_id === o.user_type_id || obj.services_id === o.services_id)) {
              unique.push(o);
            }
            return unique;
          },[]);
          this.setInternationalUnits();
        } else {
          this.servicesWithoutComponents = [];
          this.user_type_services_components_mapping = [];
          this.addUnitNew();
        }
        // Auto load the data of project
        if(this.currentUser.role.id > 2){
          this.getSupplierMappings(this.project.supplier_id, this.project.product_id);
        }
        

      })
    }


  }

  checkIfDuplicateExists(w){
    for (let i = 0; i < w.length; i++) {
      const filteredArray = w.filter(item => item.code == w[i].code);
      if (filteredArray && filteredArray.length > 1) {
        return true;
      }
    }
  }

  ecosystemUnitsInitialization(unitsData){
    // Set up units
    this.unitsMasterArray = [];
    if(unitsData.length > 0){
      this.units = unitsData;
      this.unitsDuplicate = JSON.stringify(this.units);
      this.unittypes = this._route.snapshot.data.commonData.unittypes.data;
      for (let i = 0; i < this.unittypes.length; i++) {
        this.unitsMasterArray.push({
          unitType: this.unittypes[i],
          unitOptions: this.ecosystemSettingsData.EcosystemUOM.filter(item => item.unit_type_id == this.unittypes[i].id),
        });
      }
      this.addUnitNew();
    }else{
      this.commonService.showToast('error', 'need_update_in_ecosystem_settings', {});
    }
    
  }

  submit() {
    this.spinner.show();
    this.savingProject = true;

    const servicesWithComponents = [];
    for (let i = 0; i < this.supplier_project_services_components_mapping.length; i++) {
      if (this.supplier_project_services_components_mapping[i]['components_id']) {
        servicesWithComponents.push(this.supplier_project_services_components_mapping[i]['services_id']);
      }
    }

    for (let i = 0; i < servicesWithComponents.length; i++) {
      this.supplier_project_services_components_mapping = this.supplier_project_services_components_mapping.filter(item => item.services_id !== servicesWithComponents[i] || item.components_id);
    }

    this.showMainSpinner = true;
    let uomArray = []; 
    const uomArray2 = [];
    for (let i = 0; i < this.unitsMasterArray.length; i++) {
      uomArray = uomArray.concat(this.unitsMasterArray[i]['unitsInProject']);
    }

    if (this.checkIfDuplicateExists(uomArray)) {
      this.commonService.showToast('error', 'duplicate_units', {});
      this.spinner.hide();
      this.savingProject = false;
      return;
    }

    for (let i = 0; i < uomArray.length; i++) {
      if (!uomArray[i]['international_value'] || uomArray[i]['international_value'] == '' || uomArray[i]['international_value'] == 0) {
        this.commonService.showToast('error', 'int_unit_value_missing', {});
        this.spinner.hide();
        this.savingProject = false;
        return;
      }

      const uom = {
        id : uomArray[i]['id'],
        project_id : this.project.id > 0 ? this.project.id : 0,
        unit_id: uomArray[i]['unit_id'],
        unit_type_id: uomArray[i]['unit_type_id'],
        code: uomArray[i]['code'],
        uom: uomArray[i]['uom'] ? uomArray[i]['uom'] : uomArray[i]['name'],
        international_value: uomArray[i]['international_value'],
        international_unit: uomArray[i]['international_unit'],
        is_default: uomArray[i]['is_default'],
        deleted: uomArray[i]['deleted'] ? (uomArray[i]['deleted'] == true ? true: false) : false
      };
      uomArray2.push(uom);
    }

    this.project.ProjectUOM = uomArray2;

    if (!this.project.ProjectUOM || this.project.ProjectUOM.length == 0) {
      this.commonService.showToast('error', 'add_units', {});
      this.savingProject = false;
      return;
    }

    for (let i = 0; i < this.unitsMasterArray.length; i++) {
      const unitX = this.project.ProjectUOM.filter(item => item.unit_type_id == this.unitsMasterArray[i]['unitType']['id']);
      if (!unitX || unitX.length == 0) {
        this.commonService.showToast('error', 'add_units', {});
        this.savingProject = false;
        return;
      }
    }

    const isValid = this.supplier_project_services_components_mapping.length > 0 && this.project.currency_code && this.project.currency_code.length > 0 && this.unitsMasterArray.length > 0;

    if (isValid) {

      if (this.project['estimated_yield'] == '') {
        this.project['estimated_yield'] = null
      }

        this.project['supplier_project_services_components_mapping'] = this.supplier_project_services_components_mapping;
     
      for (let i = 0; i < this.project['supplier_project_services_components_mapping'].length; i++) {
        this.project['supplier_project_services_components_mapping'][i]['project_id'] = this.project.id > 0 ? this.project.id : 0;
        this.project['supplier_project_services_components_mapping'][i]['cr_user_id'] = this.currentUser.id;
      }

      const initials = this.GetInitials(this.project.name);
      const productName = this.project.product_name;
      
      const supplierName = this.project.supplier_name;

      this.project.program_code = initials + productName + supplierName;
      this.project.cr_user_id = this.currentUser.id;

      if (this.filesToUpload.length > 0) {
        const formData: any = new FormData();
        const files: Array<File> = this.filesToUpload;

        formData.append('type', 'projectAvatar');
        formData.append('farmerId', this.project.id);
        formData.append('avatar', files[0], files[0].name);
        this.projectService.Upload(formData)
          .subscribe((obj) => {
            if (obj.msg === 'success') {
              this.project.photo_path = obj.data;
              this.saveProject();
            } else {
              this.commonService.showToast('error', 'generic_error', {});
              this.spinner.hide();
            }
          },
        (err) => {
          this.spinner.hide();
        });
      } else {
        this.saveProject();
      }

    } else {
      this.commonService.showToast('error', 'select_psm', {});
      this.savingProject = false;
      this.spinner.hide();
    }
  }

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  saveProject() {
    this.project.ProjectMapping = [{
      users_id: this.currentUser.id,
      access_type_id: 3,
      user_type_id: this.currentUser.role.id,
      cr_user_id: this.currentUser.id,
      project_id: this.project.id > 0 ? this.project.id : 0
    }];

    this.project.status_id = this.project.status_id > 0 ? this.project.status_id : null;
    this.project.description =  this.project.description.substr(0, 999);
        
    this.projectService.SaveProject(this.project).subscribe(
        (result) => {
          this.showMainSpinner = false;
          this.savingProject = false;
          if (result.msg === 'success') {
            if (this.project.id > 0) {
              this.commonService.showToast('success', 'project_updated_success', {});
            }
            else {
              this.commonService.showToast('success', 'project_saved_success', {});
            }

            let toast: ActiveToast<any>;
              setTimeout(() => {
              toast = this.commonService.showToast('warning', 'caching_warning', {disableTimeOut: true});
              });


            this.commonService.updateCache().subscribe( (val) => {

              if (toast) {
                toast.toastRef.manualClose();
              } else {
                this.toastrService.clear();
              }
              setTimeout(() => this.commonService.showToast('success', 'data_cached_successfully', {}), 1000);
            });

           setTimeout(() => {
            this.spinner.hide();
             if (result.data.id) {
              const url="/projectmanagement";
              this.router.navigate([url],{ queryParams: { from: this.fromNavId } });
             }
             else if (result.data.project.id) {
              const url="/projectmanagement";
              this.router.navigate([url],{ queryParams: { from: this.fromNavId } });
             }
            }, 1000);
          }
          this.spinner.hide();
        },
        (error) => {this.spinner.hide();
          this.savingProject = false;
        },
        () => {/*console.log('')*/}
      );
  }

  // Add a unit to the gieve unit type
  addUnitDynamic(unitTypeToAddClicked: any) {
    const oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
    let unitToAdd;
    let unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
    // Find the unit type that is to be added
    const unitTypeToAdd = oldUnitsMasterArray.filter(one => one.unitType.id == unitTypeToAddClicked.id);
    if (unitTypeToAdd && unitTypeToAdd.length > 0) {
      const index = oldUnitsMasterArray.indexOf(unitTypeToAdd[0]);
      if (index != null && index > -1) {
        // From the overall units array, remove the units in this unittype that have already been added
        for (let i = 0; i < oldUnitsMasterArray[index].unitsInProject.length; i++) {
          unitsDuplicateArray = unitsDuplicateArray.filter(item => item.unit_id !== oldUnitsMasterArray[index].unitsInProject[i].unit_id);
        }
        // From the remaining set of units, find the first unit of the type that is to be added
        const unitChanged = unitsDuplicateArray.filter(one => one.unit_type_id == unitTypeToAddClicked.id);
        
        if (unitChanged && unitChanged.length > 0) {
          unitToAdd = unitChanged[0];
          unitToAdd.tmp_id = +this.commonService.getRandomNum().toString().slice(2, 6);
          unitToAdd.is_default = false;
          oldUnitsMasterArray[index]['unitsInProject'].push(unitToAdd);
        }
      }
    }
    // Configure the unitsMasterArray & copy
    this.unitsMasterArray = oldUnitsMasterArray;
    this.unitsMasterArray = [].concat(this.unitsMasterArray);
    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
    this.setInternationalUnits();
  }

  addUnitNew() {
    if (this.project.id > 0) {
      for (let i = 0; i < this.unitsMasterArray.length; i++) {
        this.unitsMasterArray[i].unitsInProject = this.project.ProjectUOM.filter(x => x.unit_type_id == this.unitsMasterArray[i]['unitType'].id);
        for (let j = 0; j < this.unitsMasterArray[i].unitsInProject.length; j++) {
          this.unitsMasterArray[i].unitsInProject[j]['tmp_id']  = +this.commonService.getRandomNum().toString().slice(2, 6);
        }
      }
      this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
      this.setInternationalUnits();
    } else {
      for (let i = 0; i < this.unitsMasterArray.length; i++) {
        this.unitsMasterArray[i]['unitsInProject'] = [];
        const x = this.unitsMasterArray[i].unitOptions.filter(item => item.is_default == 1)[0];
        this.unitsMasterArray[i]['unitsInProject'].push(x);
        this.unitsMasterArray[i]['unitsInProject'][0]['is_default'] = true;
        this.unitsMasterArray[i]['unitsInProject'][0]['tmp_id'] = +this.commonService.getRandomNum().toString().slice(2, 6);
      }    
      this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray); 
      this.setInternationalUnits();
    }
    if (this.project.id > 0) {
      this.projectEstYieldFieldsUpdate(this.unitsMasterArray, 'unitsInProject');
    }
    else {this.projectEstYieldFieldsUpdate(this.unitsMasterArray, 'unitOptions');}
  }

  projectEstYieldFieldsUpdate(unitsMasterArray, filterFrom){

    for(let i=0;i<unitsMasterArray.length;i++){
      if(unitsMasterArray[i].unitType.id == 4){
        this.projectYieldAreaUnitsArray = [];
        this.projectYieldAreaUnitsArray = unitsMasterArray[i][filterFrom].filter(item => item.unit_type_id === 4);
      }
      if(this.unitsMasterArray[i].unitType.id == 3){
        this.projectYieldWeightUnitsArray = [];
        this.projectYieldWeightUnitsArray = unitsMasterArray[i][filterFrom].filter(item => item.unit_type_id == 3);
      }
    }    
    let weightUnitDefault;
    let areaUnitDefault;
    if(this.projectYieldWeightUnitsArray != undefined || this.projectYieldWeightUnitsArray != null){
      weightUnitDefault = this.projectYieldWeightUnitsArray.filter(item => item.unit_type_id == 3 && item.is_default == true)[0];
      this.project.estimated_yield_weight_uom = weightUnitDefault.tmp_id;
    }
    if(this.projectYieldAreaUnitsArray != undefined || this.projectYieldAreaUnitsArray != null){
      areaUnitDefault = this.projectYieldAreaUnitsArray.filter(item => item.unit_type_id == 4 && item.is_default == true)[0];
      this.project.estimated_yield_area_uom = areaUnitDefault.tmp_id;
    }    
    
  }

  projectEstYieldFieldsUpdate2(unitsMasterArray,unit){    
    
    for(let i=0;i<this.unitsMasterArray.length;i++){
      if(this.unitsMasterArray[i].unitType.id == 4){
        this.projectYieldAreaUnitsArray = [];
        if(unit.unit_type_id == 4 && unit.is_default == false){
          this.projectYieldAreaUnitsArray = this.unitsMasterArray[i].unitsInProject.filter(item => item.unit_type_id == 4);
        }else{
          this.projectYieldAreaUnitsArray = this.unitsMasterArray[i].unitOptions.filter(item => item.unit_type_id == 4);
        }        
      }
      if(this.unitsMasterArray[i].unitType.id == 3){
        this.projectYieldWeightUnitsArray = [];
        if(unit.unit_type_id === 3 && unit.is_default === false){
          this.projectYieldWeightUnitsArray = this.unitsMasterArray[i].unitsInProject.filter(item => item.unit_type_id == 3);
        }else{
          this.projectYieldWeightUnitsArray = this.unitsMasterArray[i].unitOptions.filter(item => item.unit_type_id == 3);
        }
      }
    }
    
    let weightUnitDefault;
    let areaUnitDefault;
    if(this.projectYieldWeightUnitsArray != undefined || this.projectYieldWeightUnitsArray != null){
      weightUnitDefault = this.projectYieldWeightUnitsArray.filter(item => item.unit_type_id == 3 && item.is_default == true)[0];
      
      this.project.estimated_yield_weight_uom = weightUnitDefault.tmp_id;
    }
    if(this.projectYieldAreaUnitsArray != undefined || this.projectYieldAreaUnitsArray != null){
      areaUnitDefault = this.projectYieldAreaUnitsArray.filter(item => item.unit_type_id == 4 && item.is_default == true)[0];
      this.project.estimated_yield_area_uom = areaUnitDefault.tmp_id;
    }
  }
  projectEstYieldFieldsUpdate3(unitsMasterArray,unit){    
    
    for(let i=0;i<this.unitsMasterArray.length;i++){
      if(this.unitsMasterArray[i].unitType.id == 4){
        if(unit.unit_type_id == 4){
          this.projectYieldAreaUnitsArray = [];
          this.projectYieldAreaUnitsArray = this.unitsMasterArray[i].unitsInProject.filter(item => item.unit_type_id == 4);
        }        
      }
      if(this.unitsMasterArray[i].unitType.id == 3){
        if(unit.unit_type_id == 3){          
          this.projectYieldWeightUnitsArray = [];
          this.projectYieldWeightUnitsArray = this.unitsMasterArray[i].unitsInProject.filter(item => item.unit_type_id == 3);
        }
      }
    }
    let weightUnitDefault;
    let areaUnitDefault;

    if(this.projectYieldWeightUnitsArray != undefined || this.projectYieldWeightUnitsArray != null){
      weightUnitDefault = this.projectYieldWeightUnitsArray.filter(item => item.unit_type_id == 3 && item.is_default == true)[0];
      this.project.estimated_yield_weight_uom = weightUnitDefault.tmp_id;
    }
    if(this.projectYieldAreaUnitsArray != undefined || this.projectYieldAreaUnitsArray != null){
      areaUnitDefault = this.projectYieldAreaUnitsArray.filter(item => item.unit_type_id == 4 && item.is_default == true)[0];
      this.project.estimated_yield_area_uom = areaUnitDefault.tmp_id;
    }
    
  }

  addUnit(key: string) {
    const uom = new ProjectUOM();
    uom.temp_id = +this.commonService.getRandomNum().toString().slice(2, 6);

    uom.uom_type = key;
    if (this.locale == 'es') {
      uom.international_uom = key === 'Length' ? 'Metro' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litro' : 'Hectárea'));
    } else if (this.locale == 'en') {
      uom.international_uom = key === 'Length' ? 'Meter' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Ltr' : 'Hectare'));
    } else if (this.locale == 'fr') {
      uom.international_uom = key === 'Length' ? 'Mètre' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litre' : 'Hectare'));
    } else if (this.locale == 'zh') {
      uom.international_uom = key === 'Length' ? '仪表' : (key === 'Weight' ? '公斤' : (key === 'Volume' ? '升' : '公顷'));
    } else {
      uom.international_uom = key === 'Length' ? 'மீட்டர்' : (key === 'Weight' ? 'கிலோ' : (key === 'Volume' ? 'லிட்டர்' : 'ஹெக்டேர்'));
    }
    uom.is_default = this[key].length === 0;

    if (this[key].length < 1) {
      if (this.locale == 'es') {
        uom.uom = (key === 'Length' ? 'Metro' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litro' : 'Hectárea')));
      } else if (this.locale == 'en') {
        uom.uom = (key === 'Length' ? 'Meter' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Ltr' : 'Hectare')));
      } else if (this.locale == 'fr') {
        uom.uom = (key === 'Length' ? 'Mètre' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litre' : 'Hectare')));
      } else if (this.locale == 'zh') {
        uom.uom = (key === 'Length' ? '仪表' : (key === 'Weight' ? '公斤' : (key === 'Volume' ? '升' : '公顷')));
      } else {
        uom.uom = (key === 'Length' ? 'மீட்டர்' : (key === 'Weight' ? 'கிலோ' : (key === 'Volume' ? 'லிட்டர்' : 'ஹெக்டேர்')));
      }
      uom.international_value = 1;
    }
    uom.project_id = this.project.id > 0 ? this.project.id : 0;
    this[key].push(uom);
  }
  deleteUnit(key: string, unit: ProjectUOM) {

    if (unit.id > 0) {
      this[key] = this[key].filter(x => x.id !== unit.id);
    } else {
      this[key] = this[key].filter(x => x.temp_id !== unit.temp_id);
    }


    if (unit.is_default) {
      this[key][0].is_default = true;
    }

  }

  deleteUnit2(unit: any) {
    const oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
    const unitTypeToDelete = oldUnitsMasterArray.filter(one => one.unitType.id === unit.unit_type_id);
    if (unitTypeToDelete && unitTypeToDelete.length > 0) {
      const index = oldUnitsMasterArray.indexOf(unitTypeToDelete[0]);
      if (index != null && index > -1) {
        oldUnitsMasterArray[index]['unitsInProject'] = oldUnitsMasterArray[index]['unitsInProject'].filter(item => item.tmp_id !== unit.tmp_id);

        if (oldUnitsMasterArray[index]['unitsInProject'].length > 0) {
          oldUnitsMasterArray[index]['unitsInProject'][0]['is_default'] = true;
        }
      }
    }
    this.unitsMasterArray = oldUnitsMasterArray;
    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
    this.projectEstYieldFieldsUpdate3(this.unitsMasterArray,unit);
    this.setInternationalUnits();
  }

  changeDefaultProjectUOM(unit: any) {
    this.projectYieldAreaUnitsArray = [];
    this.projectYieldWeightUnitsArray =[];    
    const oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
    const unitTypeChanged = oldUnitsMasterArray.filter(item => item.unitType.id == unit.unit_type_id);
    if (unitTypeChanged && unitTypeChanged.length > 0) {
      const index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
      if (index != null && index > -1) {
        const unitsInProject = oldUnitsMasterArray[index]['unitsInProject'];
        for (let i = 0; i < unitsInProject.length; i++) {
          unitsInProject[i]['is_default'] = false;
        }
        const unitChanged = unitsInProject.filter(item => item.tmp_id == unit.tmp_id);
        if (unitChanged && unitChanged.length > 0) {
          const index2 = unitsInProject.indexOf(unitChanged[0]);
          unitsInProject[index2]['is_default'] = true;
        }
        oldUnitsMasterArray['unitsInProject'] = unitsInProject;
      }
    }
    this.unitsMasterArray = oldUnitsMasterArray;
    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);

    this.projectEstYieldFieldsUpdateOnChange();

    
    this.setInternationalUnits();
  }

  checkChange(key: string, entry: ProjectUOM) {

    this[key] = this[key].map(x => {
      if (x.temp_id === entry.temp_id) {
        x.is_default = true;
      } else {
        x.is_default = false;
      }
      return x;
    });
  }

  checkUnit(key: string, entry: ProjectUOM) {

    this[key].map(x => {
      if (entry.uom && entry.uom !== '' && x.uom === entry.uom && x.temp_id !== entry.temp_id) {
        entry.uom = '';
        this.commonService.showToast('warning', 'unit_conversion_exists', {});
        return;
      }
    });
  }

  fileChangeEvent(fileInput: any) {
    const fileItem = fileInput.target.files.item(0);
    this.uploadingFileName = fileItem.name;

    const file = fileInput.target.files[0];
    this.uploadingFileName = fileItem.name;
    this.project.photo_path = '';

    const msg = this.commonService.ValidateFile(file);

    if (msg.length > 0) {
      this.commonService.showToast('warning', 'generic_error', {});
      return;
    }

    this.filesToUpload = <Array<File>>fileInput.target.files;
    this.project.photo_path = file.name;
  }

  cancel(template: TemplateRef<any>) {
    if (this.projectForm.dirty) {
      this.confirmCancelModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    }
    else {
      this.confirmCancel();
    }
  }

  confirmModal(what: string) {
    if (what == 'yes') {
      if (this.confirmCancelModal) {
        this.confirmCancelModal.hide();
      }
      this.confirmCancel();
    }
    else {
      if (this.confirmCancelModal) {
        this.confirmCancelModal.hide();
      }
    }
  }

  confirmCancel() : void {
    const url="/projectmanagement";
    this.router.navigate([url],{ queryParams: { from: this.fromNavId } });
  }

  GetInitials(string) {
    const names = string.split(' ');
    let  initials = '';

    if (names.length === 1) {
      return names[0].substring(0, 1).toUpperCase();
    }

    names.forEach((name) => {
      initials += name.substring(0, 1).toUpperCase();
    });
    return initials;
  }

  productChanged() {

    if (this.project.product_name) {
      const obj = this.products.find(x => x.name.toLowerCase() === this.project.product_name.toLowerCase());
      if (obj) {
        this.project.product_id = obj.id;
      } else {
        this.project.product_id = 0;
      }
    }
  }

  trackByUnitTypeId(index, dataItem) {
    return dataItem.unit_type_id;
  }
  trackByRegionId(index, dataItem){
    return dataItem.region_id;
  }

  trackById(index, dataItem) {
    return dataItem.id;
  }
  trackByCode(index, dataItem) {
    return dataItem.code;
  }
  trackByTempId(index, dataItem) {

     return dataItem.temp_id;
  }
  trackByTmpId(index, dataItem){
    return dataItem.tmp_id;
  }
  goToProject(): void {
    const url = `projectinfo/${this.projectId}`;
    this.router.navigate([url]);
  }

  goToDashboard(): void {
    if (confirm('Are you sure you want to exit?')) {
      this.router.navigate(['/dashboard']);
    }
  }

  trackByIndex(index, dataItem) {

    return index;
 }

 closeModal(isOkay: boolean) {

    this.modalDisplay = 'none';
    this.subject.next(isOkay);

 }

  ngOnDestroy() {

    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.subject.complete();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setCurrency() : void {
      /* This functionality has been disabled on 18/7/19
    // console.log('selected country', this.project.country_id);
    let selectedCountry = this.countries.filter(item => item.id == this.project.country_id);
    if (selectedCountry && selectedCountry.length > 0) {
      const selectedCurrency = this.currencyArr.filter(item => item.currency_code == selectedCountry[0].currency_code);
      if (selectedCurrency && selectedCurrency.length > 0) {
        this.project.currency_code = selectedCurrency[0].currency_code;
      }
      else {
        this.project.currency_code = "";
      }
    } else {
      this.project.currency_code = '';
    }
    */
  }

  setProductType(args) : void {
    if (this.project.product_id) {
      if (this.project.product_id > 0) {
        const product = this.products.filter(item => item.id == this.project.product_id);
        if (product && product.length > 0) {
          const productItem = product[0];
          if (productItem) {
            this.project.product_type = productItem.product_type_id;
          }
        }
      }
      else {
        this.project.product_type = 0;
      }
    }
  }

  serviceOpenCloseToggleAll() : void {
    for (let i = 0; i < this.masterServiceComponentArray.length; i++) {
      if (!this.masterServiceComponentArray[i]['services_id'] && this.masterServiceComponentArray[i]['noComponents'] == false) {
        this.serviceOpenCloseToggle(this.masterServiceComponentArray[i]);
      }
    }
    if (this.rowsToShow && this.rowsToShow.length === 0) {
      this.allClosed = true;
    }
    else if (!this.rowsToShow) {
      this.allClosed = true;
    }
    else {
      this.allClosed = false;
    }
  }

  serviceOpenCloseToggle(item) : void {
    const clickedItem = this.masterServiceComponentArray.filter(one => one.id == item.id);
    const clickedItemObj = clickedItem[0];
    const index = this.masterServiceComponentArray.indexOf(clickedItemObj);
    this.masterServiceComponentArray[index]['opened'] = !this.masterServiceComponentArray[index]['opened'];
    

    if (!this.masterServiceComponentArray[index]['opened']) {
      const childItems = this.masterServiceComponentArray.filter(one => one.services_id == item.id);
      
      if (childItems && childItems.length > 0) {
        for (let i = 0; i < childItems.length; i++) {
          const tempRowsToShow = this.rowsToShow.filter(item => item !== childItems[i].id);
          this.rowsToShow = tempRowsToShow;
        }
      }
    }
    else {
      const childItems = this.masterServiceComponentArray.filter(one => one.services_id == item.id);
      
      if (childItems && childItems.length > 0) {
        for (let i = 0; i < childItems.length; i++) {
          this.rowsToShow.push(childItems[i].id);
        }
      }
    }
    if (this.rowsToShow && this.rowsToShow.length === 0) {
      this.allClosed = true;
    }
    else if (!this.rowsToShow) {
      this.allClosed = true;
    }
    else {
      this.allClosed = false;
    }
  }


  psmChecked(partnerId, itemId, parentServiceId, isToggleAllCall?) : void {
    if (parentServiceId) { // this is a component being toggled
      const isPresent = this.supplier_project_services_components_mapping.filter(one => one.components_id == itemId && one.user_type_id == partnerId);
      
      if (isPresent && isPresent.length > 0) {
        // If it is a select all call, don't remove, otherwise remove
        if (!isToggleAllCall) {
          const itemsToRemove = this.supplier_project_services_components_mapping.filter(one => one.components_id == itemId && one.user_type_id == partnerId);
          const tempArray = this.supplier_project_services_components_mapping.filter(x => !itemsToRemove.includes(x));
          this.supplier_project_services_components_mapping = tempArray;
        }
      }
       // not present so add this component
      else {
        this.supplier_project_services_components_mapping.push({supplier_id: this.project.supplier_id, user_type_id: partnerId, components_id: itemId, services_id: parentServiceId});
      }
    }
    else { // this is a parent service
      const isPresent = this.supplier_project_services_components_mapping.filter(one => one.services_id == itemId && one.user_type_id == partnerId);
      if (isPresent && isPresent.length > 0) {
        if (!isToggleAllCall) {
          const itemsToRemove = this.supplier_project_services_components_mapping.filter(one => one.services_id == itemId && one.user_type_id == partnerId);
          const tempArray = this.supplier_project_services_components_mapping.filter(x => !itemsToRemove.includes(x));
          this.supplier_project_services_components_mapping = tempArray;
        }
      }
      else {
        this.supplier_project_services_components_mapping.push({supplier_id: this.project.supplier_id, user_type_id: partnerId, services_id: itemId});
      }
    }

    this.checkedObj = {};
    for (let i = 0; i < this.supplier_project_services_components_mapping.length; i++) {
      if (this.supplier_project_services_components_mapping[i]['components_id']) {
        this.checkedObj['C_' + this.supplier_project_services_components_mapping[i]['components_id'] + '_' + this.supplier_project_services_components_mapping[i]['user_type_id']] = true;
      }
      else {
        this.checkedObj['S_' + this.supplier_project_services_components_mapping[i]['services_id'] + '_' + this.supplier_project_services_components_mapping[i]['user_type_id']] = true;
      }
    }

    this.setServicesWithComponentsAllChecked();
  }

  toggleComponentCheckboxesFn(partner, service) : void {
    const childComponents = this.masterServiceComponentArray.filter(item => item.services_id == service.id);
    
    if (this.allComponentsOfThisServiceSelected.find(item => item == service.id + partner.id)) {
      this.allComponentsOfThisServiceSelected = this.allComponentsOfThisServiceSelected.filter(item => item !== service.id + partner.id);
      for (let i = 0; i < childComponents.length; i++) {
        const itemsToRemove = this.supplier_project_services_components_mapping.filter(one => one.services_id == childComponents[i].id && one.user_type_id == partner.id);
        const tempArray = this.supplier_project_services_components_mapping.filter(x => !itemsToRemove.includes(x) );
        this.supplier_project_services_components_mapping = tempArray;

        const index = `C_${childComponents[i]['id']}_${partner.id}`;
        this.checkedObj[index] = false;
      }
    }
    else {
      this.allComponentsOfThisServiceSelected.push(service.id + partner.id);
      for (let i = 0; i < childComponents.length; i++) {
        this.supplier_project_services_components_mapping.push({user_type_id: partner.id, components_id: childComponents[i].id, services_id: childComponents[i].services_id});

        const index = `C_${childComponents[i]['id']}_${partner.id}`;
        this.checkedObj[index] = true;
      }
    }
  }

  internationalUnitChanged(projectUOM) : void {
    const unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
    const unitChanged = unitsDuplicateArray.filter(one => one.code == projectUOM.code);
    if (unitChanged && unitChanged.length > 0) {
      const index = unitsDuplicateArray.indexOf(unitChanged[0]);
      if (index != null  && index > -1) {
        unitsDuplicateArray[index]['international_value'] = projectUOM['international_value'];
        this.unitsDuplicate = JSON.stringify(unitsDuplicateArray);
      }
    }
  }

  selectUnit(projectUOM, projectUOMIndex) : void {

    const unitTypeChanged = this.unitsMasterArray.filter(one => one.unitType.id == projectUOM.unit_type_id);
    
    if (unitTypeChanged && unitTypeChanged.length > 0) {
      const index = this.unitsMasterArray.indexOf(unitTypeChanged[0]);
      
      if (index != null && index > -1) {
        const projectUOMChanged = this.unitsMasterArray[index]['unitsInProject'].filter(one => one.code == projectUOM.code);
        
        if (projectUOMChanged && projectUOMChanged.length > 0) {
          const index2 = this.unitsMasterArray[index]['unitsInProject'].indexOf(projectUOMChanged[0]);
          if (index2 != null  && index2 > -1){
            const unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
            const unitSelected = unitsDuplicateArray.filter(one => one.code == projectUOM.code);
            if (unitSelected && unitSelected.length > 0) {
              const index3 = unitsDuplicateArray.indexOf(unitSelected[0]);
              const unitToSet = unitsDuplicateArray[index3];

              for (let i = 0; i < this.unitsMasterArray[index]['unitsInProject'].length; i++) {
                this.unitsMasterArray[index]['unitsInProject'][i]['is_default'] = false;
              }
              this.unitsMasterArray[index]['unitsInProject'][index2] = unitsDuplicateArray[index3];
              this.unitsMasterArray[index]['unitsInProject'][index2]['is_default'] = true;
            }
          }
        }

        const unitChanged = this.unitsMasterArray[index]['unitOptions'].filter(one => one.id == projectUOM.id);
        
        if (unitChanged && unitChanged.length > 0) {
          const index4 = this.unitsMasterArray[index]['unitOptions'].indexOf(unitChanged[0]);
          if (index4 != null  && index4 > -1){
            const unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
            const unitSelected = unitsDuplicateArray.filter(one => one.id == projectUOM.id);
            if (unitSelected && unitSelected.length > 0) {
              const index5 = unitsDuplicateArray.indexOf(unitSelected[0]);
              this.unitsMasterArray[index]['unitOptions'][index4] = unitsDuplicateArray[index5];
            }
          }
        }
      }
    }
    this.unitsMasterArray = Object.assign([], this.unitsMasterArray);
    this.setInternationalUnits();
  }

  selectUnit2(projectUOM, projectUOMIndex): void {
    const oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
    const unitTypeChanged = oldUnitsMasterArray.filter(one => one.unitType.id == projectUOM.unit_type_id);
    if (unitTypeChanged && unitTypeChanged.length > 0) {
      const index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
      if (index != null && index > -1) {
        const projectUOMChanged = oldUnitsMasterArray[index]['unitsInProject'].filter(one => one.tmp_id == projectUOM.tmp_id); // code changed to tmp_id
        
        if (projectUOMChanged && projectUOMChanged.length > 0) {
          const index2 = oldUnitsMasterArray[index]['unitsInProject'].indexOf(projectUOMChanged[0]);
          if (index2 != null  && index2 > -1){
            const unitsDuplicateArray = JSON.parse(this.unitsDuplicate);

            // this is the new unit selected
            const unitSelected = unitsDuplicateArray.filter(one => one.code == projectUOM.code);
            
            if (unitSelected && unitSelected.length > 0) {
              const index3 = unitsDuplicateArray.indexOf(unitSelected[0]);
              unitsDuplicateArray[index3]['tmp_id'] = projectUOM.tmp_id;

              for (let i = 0; i < oldUnitsMasterArray[index]['unitsInProject'].length; i++) {
                oldUnitsMasterArray[index]['unitsInProject'][i]['is_default'] = false;
              }
              oldUnitsMasterArray[index]['unitsInProject'][index2] = unitsDuplicateArray[index3];
              oldUnitsMasterArray[index]['unitsInProject'][index2]['is_default'] = true;
            }
          }
        }

      }
    }
    this.unitsMasterArray = oldUnitsMasterArray;
    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
    this.projectEstYieldFieldsUpdate3(this.unitsMasterArray,projectUOM);
    this.setInternationalUnits();


  }

  getSupplierMappings(supplierIdProvided, productIdProvided) : void {
    
    if (this.project.supplier_id !== 0 && this.project.product_id !== 0) {
      this.suppliersService.getMappingsForSupplier(supplierIdProvided ? supplierIdProvided : this.project.supplier_id, productIdProvided ? productIdProvided : this.project.product_id, this.project.id > 0 ? this.project.id : 0)
      .subscribe(result => {

        if (!result.supplier_services_components_mapping || !result.supplier_user_type_mapping) {
          this.hideSupplierMappingGrid = true;
          this.commonService.showToast('error', 'ecosystem_mappings_error', {});
          
          this.services = [];
          this.serviceComponents = [];
          this.masterServiceComponentArray = [];
          this.rowsToShow = [];
          this.supplierRoles = [];
          this.hideSupplierMappingGrid = true;
          this.supplier_project_services_components_mapping = [];
        }
        else if (result.supplier_services_components_mapping.length == 0 || result.supplier_user_type_mapping.length == 0){
          this.commonService.showToast('error', 'ecosystem_mappings_invalid', {});
          this.services = [];
          this.serviceComponents = [];
          this.masterServiceComponentArray = [];
          this.rowsToShow = [];
          this.supplierRoles = [];
          this.hideSupplierMappingGrid = true;
          this.supplier_project_services_components_mapping = [];
        }
        else {
          this.hideSupplierMappingGrid = false;
          this.mappingsForSupplier = result;
          this.createServiceMappingGrid();
          //Getting ecosystem related data
          this.ecosystem_id = result.supplier_services_components_mapping[0].ecosystem_id;
          this.suppliersService.getEcoSystemSettingsRecord(result.supplier_services_components_mapping[0].ecosystem_id).subscribe(response =>{
            this.ecosystemSettingsData = response.data;
            this.spinner.hide();

            for (let i = 0; i < this.ecosystemSettingsData.EcosystemUOM.length; i++) {
              this.ecosystemSettingsData.EcosystemUOM[i]['id'] = 0;
              this.ecosystemSettingsData.EcosystemUOM[i]['name'] = this.ecosystemSettingsData.EcosystemUOM[i]['uom'];
            }

            if(response.status === 'success'){
              if(this.ecosystemSettingsData.currency_id == null || this.ecosystemSettingsData.currency_id === 'null'){
                this.ecosystemSettingsData.currency_id = 0;           
              }else{
                const populatedCurrency = this.currencyArr.filter(item => item.id === this.ecosystemSettingsData.currency_id)[0];
                this.project.currency_code = populatedCurrency.currency_code;
              }
              if(this.ecosystemSettingsData.country_id == null || this.ecosystemSettingsData.country_id == 'null'){
                this.ecosystemSettingsData.country_id = 0;            
              }else{
                const populatedCountry = this.countries.filter(item => item.id == this.ecosystemSettingsData.country_id)[0];
                
                this.project.country_id = populatedCountry.id;
              }

              if(this.ecosystemSettingsData.ecosystem_region_master.length > 0 || this.ecosystemSettingsData.ecosystem_region_master != null){
                for(let region of this.ecosystemSettingsData.ecosystem_region_master){
                  const regionObject ={
                    "id":region.id,
                    "region_name":region.region_name
                  };
                  //finding the duplicate regions and not pusing into array
                  const x = this.ecosystemRegionData.find(item => item.id == regionObject.id);
                  if (!x) {
                    this.ecosystemRegionData.push(regionObject);
                  }
                } 
              }
              if(this.ecosystemSettingsData.EcosystemUOM.length > 0 || this.ecosystemSettingsData.EcosystemUOM != null){
                
                const newArrayCopy = JSON.stringify(this.ecosystemSettingsData.EcosystemUOM);
                  
                  let ecoSystemUOM =[];
                  ecoSystemUOM = JSON.parse(newArrayCopy);
                  for(let y of ecoSystemUOM){
                    y.id = 0;
                  }              
                  this.ecosystemUnitsInitialization(ecoSystemUOM);               

              }             

            }

          });
        }
      })
    }
    else {
      this.hideSupplierMappingGrid = true;
      this.supplier_project_services_components_mapping = [];
      this.masterServiceComponentArray = [];
      this.services = [];
      this.serviceComponents = [];
      this.rowsToShow = [];
      this.supplierRoles = [];
    }
  }

  createServiceMappingGrid() : void {
    const servicesTemp1 = []; 
    const servicesTemp2 = [];
    this.services = [];
    this.serviceComponents = [];
    this.masterServiceComponentArray = [];
    this.rowsToShow = [];
    this.supplierRoles = [];
    for (let i = 0; i < this.mappingsForSupplier['supplier_user_type_mapping'].length; i++) {
      this.supplierRoles.push(this.mappingsForSupplier['supplier_user_type_mapping'][i]['user_type']);
    }

    for (let i = 0; i < this.mappingsForSupplier['supplier_services_components_mapping'].length; i++) {
      if (this.mappingsForSupplier['supplier_services_components_mapping'][i]['components_id']) {
        this.serviceComponents.push(this.mappingsForSupplier['supplier_services_components_mapping'][i]['services_components_mapping']);
      }
      servicesTemp1.push(this.mappingsForSupplier['supplier_services_components_mapping'][i]['services']);
    }

    this.services = servicesTemp1.reduce((unique, o) => {
        if(!unique.some(obj => obj.code === o.code)) {
          unique.push(o);
        }
        return unique;
    },[]);

    for (let i = 0; i < this.serviceComponents.length; i++) {
      if (this.serviceComponentsMapping[this.serviceComponents[i]['services_id']]) {
        this.serviceComponentsMapping[this.serviceComponents[i]['services_id']]++;
      }
      else {
        this.serviceComponentsMapping[this.serviceComponents[i]['services_id']] = 0;
      }
    }

    for (let i = 0; i < this.services.length; i++) {
      let componentsInService;
      this.masterServiceComponentArray.push(this.services[i]);
      componentsInService = this.serviceComponents.filter(item => item.services_id == this.services[i].id);
      if (componentsInService && componentsInService.length > 0) {
        const componentsInService2 = this.masterServiceComponentArray.concat(componentsInService);
        this.masterServiceComponentArray = componentsInService2;
        this.services[i]['noComponents'] = false;
      }
      else {
        this.services[i]['noComponents'] = true;
      }
    }

    for (let i = 0; i < this.masterServiceComponentArray.length; i++) {
      this.masterServiceComponentArray[i]['opened'] = true;
      if (this.masterServiceComponentArray[i].services_id) {
        this.rowsToShow.push(this.masterServiceComponentArray[i].id);
      }
    }
    this.addCheckedToSPSCM();
  }

  addCheckedToSPSCM() : void {
    // if this is a project being edited, add the already mapped itesm to checkedObj
    if (this.project.id > 0) {
      for (let i = 0; i < this.project['supplier_project_services_components_mapping'].length; i++) {
        if (this.project['supplier_project_services_components_mapping'][i]['components_id'] != null) {
          
          this.psmChecked(this.project['supplier_project_services_components_mapping'][i]['user_type_id'], this.project['supplier_project_services_components_mapping'][i]['components_id'],this.project['supplier_project_services_components_mapping'][i]['services_id']);
        }
        else {
          this.psmChecked(this.project['supplier_project_services_components_mapping'][i]['user_type_id'], this.project['supplier_project_services_components_mapping'][i]['services_id'], null);
        }
      }

      // Additionally if there are NEW user types or services / components mapped to this ecosystem (mapped AFTER project was created)
      // they will not be present in the supplier_project_services_components_mapping but will be present in the services & serviceCOmponents
      // Their is_default = 'Y' must be handled
      for (let i = 0; i < this.serviceComponents.length; i++) {
        for (let j = 0; j < this.supplierRoles.length; j++) {
          if (!this.checkedObj['C_' + this.serviceComponents[i]['id'] + '_' + this.supplierRoles[j]['id']] && this.serviceComponents[i]['is_default'] == 'Y') {
              this.psmChecked(this.supplierRoles[j].id, this.serviceComponents[i].id, this.serviceComponents[i].services_id);
          }
        }
      }
      for (let i = 0; i < this.services.length; i++) {
        for (let j = 0; j < this.supplierRoles.length; j++) {
          if (!this.checkedObj['S_' + this.services[i]['id'] + '_' + this.supplierRoles[j]['id']] && this.services[i]['is_default'] == 'Y') {
            this.psmChecked(this.supplierRoles[j].id, this.services[i].id, null);
          }
        }
      }
    }
    else {
      for (let i = 0; i < this.serviceComponents.length; i++) {
        for (let j = 0; j < this.supplierRoles.length; j++) {
          if (this.serviceComponents[i]['is_default'] == 'Y') {
              this.psmChecked(this.supplierRoles[j].id, this.serviceComponents[i].id, this.serviceComponents[i].services_id);
          }
        }
      }

      for (let i = 0; i < this.services.length; i++) {
        for (let j = 0; j < this.supplierRoles.length; j++) {
          if (this.services[i]['is_default'] == 'Y') {
            this.psmChecked(this.supplierRoles[j].id, this.services[i].id, null);
          }
        }
      }
    }
  }

  setServicesWithComponentsAllChecked() : void {
    // Get all services with components in them
    const servicesWithComponents = this.services.filter(x => x.noComponents == false);
    // For each such service
    for (let i = 0; i < servicesWithComponents.length; i++) {
      for (let p = 0; p < this.supplierRoles.length; p++) {
        const componentsInService = this.serviceComponents.filter(item => item.services_id == servicesWithComponents[i].id);
        let countOfComponentsInServiceForUserType = 0;
        for (let j = 0; j < componentsInService.length; j++) {
          const index = `C_${componentsInService[j]['id']}_${this.supplierRoles[p]['id']}`;
          if (this.checkedObj[index]) {
            countOfComponentsInServiceForUserType++;
          }
        }
        
        if (countOfComponentsInServiceForUserType === componentsInService.length) {
          this.allCheckedUserTypesServices.push(`S${this.supplierRoles[p]['id']}${servicesWithComponents[i]['id']}`);
        }
        else {
          this.allCheckedUserTypesServices = this.allCheckedUserTypesServices.filter(item => item !== ('S' + this.supplierRoles[p]['id'] + servicesWithComponents[i]['id']))
        }
      }
    }
    this.setUserTypesWithComponentsAllChecked();
  }

  setUserTypesWithComponentsAllChecked() : void {
    const componentsInUserType = this.serviceComponents;
    const servicesWithoutComponents = this.services.filter(item => item.noComponents == true);
    const total = componentsInUserType.length + servicesWithoutComponents.length;

    for (let i = 0; i < this.supplierRoles.length; i++) {
      let count1 = 0; 
      let count2 = 0;
      for (let j = 0; j < servicesWithoutComponents.length; j++) {
        if (this.checkedObj[`S_${servicesWithoutComponents[j]['id']}_${this.supplierRoles[i]['id']}`]) {
          count1++;
        }
      }

      for (let j = 0; j < componentsInUserType.length; j++) {
        if (this.checkedObj[`C_${componentsInUserType[j]['id']}_${this.supplierRoles[i]['id']}`]) {
          count2++;
        }
      }
      
      if (total == count1 + count2) {
        this.allCheckedUserTypes.push(this.supplierRoles[i]['id']);
      }
      else {
        this.allCheckedUserTypes = this.allCheckedUserTypes.filter(item => item !== this.supplierRoles[i]['id']);
      }
    }
  }

  toggleAllServicesForUserType(partnerId) : void {
    // Check if this user type already has all items selected
    const isPresent = this.allCheckedUserTypes.indexOf(partnerId);
    if (isPresent != null && isPresent > -1) {
      // If all items already selected, must remove all items
      for (let i = 0; i < this.masterServiceComponentArray.length; i++) {
        if (this.masterServiceComponentArray[i].services_id && this.masterServiceComponentArray[i].is_modifiable != 'N') {
          this.psmChecked(partnerId, this.masterServiceComponentArray[i].id, this.masterServiceComponentArray[i].services_id);
        }
        else {
          if (this.masterServiceComponentArray[i].noComponents == true  && this.masterServiceComponentArray[i].is_modifiable != 'N') {
            this.psmChecked(partnerId, this.masterServiceComponentArray[i].id, null);
          }
          else if (this.masterServiceComponentArray[i].noComponents == false  && this.masterServiceComponentArray[i].is_modifiable != 'N') {
            
          }
        }
      }
      this.allCheckedUserTypes = this.allCheckedUserTypes.filter(item => item != partnerId);
    }
    else {
      // If all items NOT already selected, must select all items
      this.allCheckedUserTypes.push(partnerId);
      for (let i = 0; i < this.masterServiceComponentArray.length; i++) {
        if (this.masterServiceComponentArray[i].services_id && this.masterServiceComponentArray[i].is_modifiable != 'N') {
          this.psmChecked(partnerId, this.masterServiceComponentArray[i].id, this.masterServiceComponentArray[i].services_id, true);
        }
        else {
          if (this.masterServiceComponentArray[i].noComponents == true  && this.masterServiceComponentArray[i].is_modifiable != 'N') {
            this.psmChecked(partnerId, this.masterServiceComponentArray[i].id, null, true);
          }
          else if (this.masterServiceComponentArray[i].noComponents == false  && this.masterServiceComponentArray[i].is_modifiable != 'N') {
            
          }
        }
      }
    }
  }

  toggleServiceComponentsForUserType2(partnerId, servicesId) : void {
    const isPresent = this.allCheckedUserTypesServices.indexOf(`S${partnerId}${servicesId}`);
    if (isPresent != null && isPresent > -1) {
      this.allCheckedUserTypesServices = this.allCheckedUserTypesServices.filter(item => item != `S${partnerId}${servicesId}`);
      const componentsToToggle = this.masterServiceComponentArray.filter(item => item.id == servicesId && item.services_id == null || item.services_id == servicesId);
      for (let i = 0; i < componentsToToggle.length; i++) {
        if (componentsToToggle[i].services_id && componentsToToggle[i].is_modifiable != 'N') {
          this.psmChecked(partnerId, componentsToToggle[i].id, componentsToToggle[i].services_id);
        }
        else {
          if (componentsToToggle[i].noComponents == true  && componentsToToggle[i].is_modifiable != 'N') {
            this.psmChecked(partnerId, componentsToToggle[i].id, null, true);
          }
          else if (componentsToToggle[i].noComponents == false  && componentsToToggle[i].is_modifiable != 'N') {

          }
        }
      }
    }
    else {
      this.allCheckedUserTypesServices.push(`S${partnerId}${servicesId}`);
      const componentsToToggle = this.masterServiceComponentArray.filter(item => item.id == servicesId && item.services_id == null || item.services_id == servicesId);
      for (let i = 0; i < componentsToToggle.length; i++) {
        if (componentsToToggle[i].services_id && componentsToToggle[i].is_modifiable != 'N') {
          this.psmChecked(partnerId, componentsToToggle[i].id, componentsToToggle[i].services_id, true);
        }
        else {
          if (componentsToToggle[i].noComponents == true  && componentsToToggle[i].is_modifiable != 'N') {
            this.psmChecked(partnerId, componentsToToggle[i].id, null, true);
          }
          else if (componentsToToggle[i].noComponents == false  && componentsToToggle[i].is_modifiable != 'N') {

          }
        }
      }
    }
  }

  internationalUnitValueChanged(unit) : void {
    
    const oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
    const unitTypeChanged = oldUnitsMasterArray.filter(item => item.unitType.id == unit.unit_type_id);
    if (unitTypeChanged && unitTypeChanged.length > 0) {
      const index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
      if (index != null && index > -1) {
        const unitsInProject = oldUnitsMasterArray[index]['unitsInProject'];

        const unitChanged = unitsInProject.filter(item => item.tmp_id == unit.tmp_id);
        if (unitChanged && unitChanged.length > 0) {
          const index1 = unitsInProject.indexOf(unitChanged[0]);
          
          unitsInProject[index1]['international_value'] = unit['international_value'];

          oldUnitsMasterArray['unitsInProject'] = unitsInProject;
          this.unitsMasterArray = Object.assign([], oldUnitsMasterArray);
          this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
          this.setInternationalUnits();
        }
      }
    }
  }

  updateUnitsMasterArrayCopy(args): void {
    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
  }

  setInternationalUnits(): void {
    for (let i = 0; i < this.unitsMasterArray.length; i++) {
      for (let j = 0; j < this.unitsMasterArray[i]['unitsInProject'].length; j++) {
        const intValue = this.unittypes.filter(item => item.international_unit_to_save == this.unitsMasterArray[i]['unitsInProject'][j]['international_unit']);
        
        if (intValue && intValue.length > 0) {
          this.unitsMasterArray[i]['unitsInProject'][j]['international_unit'] = intValue[0]['international_unit'];
        }
      }
    }
  }

  projectEstYieldFieldsUpdateOnChange(): void {
    this.projectYieldWeightUnitsArray = this.unitsMasterArray.find(item => item.unitType.id == 3).unitsInProject;
    this.projectYieldAreaUnitsArray = this.unitsMasterArray.find(item => item.unitType.id == 4).unitsInProject;
    this.project.estimated_yield_weight_uom = this.projectYieldWeightUnitsArray.find(item => item.is_default == true).tmp_id;
    this.project.estimated_yield_area_uom = this.projectYieldAreaUnitsArray.find(item => item.is_default == true).tmp_id;
  }
  //Redirect to project management on click of breadcrumb
  redirectToProjectManagement(){
    const url="/projectmanagement";
    this.router.navigate([url],{ queryParams: { from: this.fromNavId } });
  }

}



