import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiManagementService {

  API_MANAGEMENT_URL = this.appConfigService.getUrl(this.appConfigService.API_MANAGEMENT);
  API_URL = '/api';
  dynamicAPIUrl = this.appConfigService.getUrl('');

  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) { }

  getAPIInfo() {
    return this.http.get<any>( `${this.API_MANAGEMENT_URL}/getAPIInfo`);
  }

  getAPIErrors() {
    return this.http.get<any>( `${this.API_MANAGEMENT_URL}/getAPIErrors`);
  }

  triggerAPI(api) {
    return this.http.post<any>( `${this.API_MANAGEMENT_URL}/triggerAPI`, api);
  }

  retriggerAPIForError(errorId) {
    return this.http.get<any>( `${this.API_MANAGEMENT_URL}/retriggerAPIForError/${errorId}`);
  }

  getAPIConfigData(urls) {
    let responseArray = [];
    for (let i = 0; i < urls.length; i++) {
      responseArray[i] = this.http.get<any>( `${this.dynamicAPIUrl}/api/${urls[i]}`);
    }

    return forkJoin(responseArray);
  }

  saveTraceabilityEcosystems(data) {
    return this.http.post<any>(`${this.API_MANAGEMENT_URL}/saveTraceabilityEcosystems`, data);
  }
}
