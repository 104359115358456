import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  
  private readonly campaignURL = this.appConfigService.getUrl(this.appConfigService.CAMPAIGN);

  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) { }

  getCampaigns(): Observable<any> {
    return this.http.get<any>(`${this.campaignURL}/getCampaigns`);
  }

  getCampaignById(campaignId): Observable<any> {
    return this.http.get<any>(`${this.campaignURL}/getCampaignById/${campaignId}`);
  }

  saveOrUpdateCampaign(campaignId, data): Observable<any> {
    return campaignId && campaignId > 0 ? this.http.put(`${this.campaignURL}/updateCampaign/${campaignId}`, data) 
    : this.http.post(this.campaignURL + '/saveCampaign', data); 
  }

  closeCampaign(campaignId, data): Observable<any> {
    return this.http.put(`${this.campaignURL}/closeCampaign/${campaignId}`, data);
  }

  getFarmerIdsMappedToCampaign(campaignId): Observable<any> {
    return this.http.get<any>(`${this.campaignURL}/getFarmerIdsMappedToCampaign/${campaignId}`);
  }

  mapFarmersToCampaign(campaignId, farmerIdsArray): Observable<any> {
    return this.http.post(`${this.campaignURL}/mapFarmersToCampaign/${campaignId}`, farmerIdsArray); 
  }

  getCampaignStatuses(): Observable<any> {
    return this.http.get<any>(`${this.campaignURL}/getCampaignStatuses`);
  }

  getCampaignsForProject(projectId): Observable<any> {
    return this.http.get<any>(`${this.campaignURL}/getCampaignsByProject/${projectId}`);
  }

  getFarmersMappedToCampaign(campaignId): Observable<any> {
    return this.http.get<any>(`${this.campaignURL}/getFarmersMappedToCampaign/${campaignId}`);
  }
  
}
