import { Component, OnInit, TemplateRef, HostListener } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../services/project.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FarmerService } from '../../services/farmer.service';
import { TrainingService } from '../../services/training.service';
import Compressor from 'compressorjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-edit-certification',
  templateUrl: './edit-certification.component.html',
  styleUrls: ['./edit-certification.component.css']
})
export class EditCertificationComponent implements OnInit {
  isOnline: Observable<boolean>;
  project:any;
  projectId;
  type;
  groundForm:FormGroup;
  public isTrainingOpen = false;
  isCertificateOpen = false;
  isExpiryOpen = false;
  public dateFormat = 'DD-MMM-YYYY';
  isDesktopDevice;
  uploadedTrainingFiles = [];
  uploadedTrainingFilesOld = [];
  trainers = [];
  trainingCertifications;
  trainings;
  certifications;  
  searchTextFarmers;
  confirmFarmerModal;
  loadingFarmers = false;
  managingFarmersForGroup;
  selectedFarmersCopy = [];
  tooltipText;
  farmers = [];
  selectedFarmers = [];
  selectedTrainings = [];
  selectedSpouse = [];
  selectedChild = [];
  globalTrainings = [];
  globalSpouse = [];
  globalChild = [];
  selectDeselectLabels;
  public confirmModalPopup = true;
  public confirmPopup:BsModalRef;
  manageFarmersModal: BsModalRef;
  addGroupModal: BsModalRef;
  page = 1;
  itemsPerPage = 10;
  farmersListPage = 1;
  public searchText: any;
  public previousPaginationLabel;
  public nextPaginationLabel;
  groupFarmers = [];
  allGroups;
  disableAddButton = true;
  totalSelect = ['group1'];
  maxDate = new Date();
  minDate = new Date();
  hdrID;
  typeDetails;
  currentTab = [];
  currentGroup = {
    "groupName" : "",
    "details" : [],
    "id": ""
  };
  currentGroupFarmers = [];
  currentGroupIndex;
  allProjectFarmers = [];
  updatedFarmers = [];
  confirmGroupModal = true;
  newGroup = 0;
  allGroupIDs = [];
  isAscending = true;
  isAscendingCode = true;
  isAscendingName = true;
  isAscendingAge = true;
  isAscendingSex = true;
  isAscendingOfficialID = true;

  isAscendingFarmer = true;
  isAscendingIdFarmer = true;
  isAscendingNameFarmer = true;
  isAscendingTelFarmer = true;
  isAscendingDateFarmer = true;
  isAscendingAgeFarmer = true;
  isAscendingSexFarmer = true;
  isAscendingVillageFarmer = true;
  isAscendingVCFarmer = true;
  
  viewModel = [];
  isView = false;
  additionalFarmer;
  constructor(private readonly _route: ActivatedRoute, 
    private readonly router: Router,
    private readonly projectService: ProjectService,
    private readonly deviceService: DeviceDetectorService,
    private readonly formBuilder: FormBuilder,
    private readonly commonService: CommonService,
    public toastrService: ToastrService, 
    private readonly modalService: BsModalService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly trainingService: TrainingService,
    private readonly farmerService: FarmerService,
    private readonly translate: TranslateService,
  ) { 
    this.isOnline = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
  
    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });
  
    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });
    this.translate.get('select_deselect_object').subscribe(result => {
      this.selectDeselectLabels = result;
    });
    this.translate.get('additional_farmer').subscribe(result => {
      this.additionalFarmer = result;
    });
  }

  ngOnInit() {
    this.projectId = +this._route.snapshot.params['id'];
    this.type = "Certification";
    this.hdrID = this._route.snapshot.params['typeid'];
    this._route.queryParamMap.subscribe(async queryParams => {
      if (queryParams.get('isView') && queryParams.get('isView') === 'true') {
        this.isView = true;
      }
    })
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.getTrainingCertifcationList();
    this.createTrainingForm();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  async getTrainingCertifcationList(){    
    this.spinner.show();
    const request = await Promise.all([
      this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
      this.trainingService.get_all_training_certification(this.projectId).toPromise(),
      this.trainingService.get_all_trainer(this.projectId).toPromise(),
      this.trainingService.getAllGroups(this.projectId).toPromise()
    ]);
    if (request[0]['msg'] === 'success') {
      this.project = request[0]['data'][0];
    }
    if (request[1]['msg'] === 'success'){
      this.trainingCertifications = request[1]['data'];
    }
    if (request[2]['msg'] === 'success'){
      this.trainers = request[2]['data'];
    }
    if (request[3]['msg'] === 'success'){
      this.allGroups = request[3]['data'];
    }
    this.trainings = this.trainingCertifications.filter(item => 
      item.training_certification_master.training_certification_type == 'Training' && item.training_certification_master.status == 'Active'
    );
    this.certifications = this.trainingCertifications.filter(item => 
      item.training_certification_master.training_certification_type == 'Certification' && item.training_certification_master.status == 'Active'
    );
    this.editTraining();
    this.spinner.hide();
  }  

  createTrainingForm(){
    this.groundForm = this.formBuilder.group({
      trainingName: new FormControl('0', [Validators.required]),
      trainerName: new FormControl('0', [Validators.required]),
      description: new FormControl('', [Validators.maxLength(1000)]),
      trainingDate: new FormControl(null, [Validators.required]),
      group1: new FormControl('0', [Validators.required]),
      certificateName : new FormControl('0', [Validators.required]),
      certificationDate : new FormControl(null),
      expiryDate : new FormControl(null)
    });
  }

  async editTraining() {
    this.spinner.show();
    const request = await Promise.all([
      this.trainingService.getCertificationDetails(this.hdrID).toPromise()
    ]);
    
    if (request[0]['msg'] === 'success'){
      this.typeDetails = request[0]['data'];
    }
    this.uploadedTrainingFilesOld = this.typeDetails.training_documents_dtl
    this.currentTab = [];
    const obj = {
      certificateName: this.typeDetails.project_training_certification_mapping_id,
      certificationDate: new Date(this.typeDetails.certification_date),
      description: this.typeDetails.description,
      expiryDate: this.typeDetails.expiry_date ? new Date(this.typeDetails.expiry_date) : null
    };

    const certificationName = this.certifications.find(item => item.project_training_certification_mapping_id == obj.certificateName);
    const viewObj = {
      certificateName: certificationName.training_certification_master.name,
      certificationDate: new Date(this.typeDetails.certification_date),
      description: this.typeDetails.description,
      expiryDate: this.typeDetails.expiry_date ? new Date(this.typeDetails.expiry_date) : null
    };

    this.viewModel.push(viewObj)

    const details  = this.typeDetails.certification_recording_dtl;
    for (const [key, value] of Object.entries(details)) {
      let obj = {};
      if(key != "EXTERNAL"){
        const groupName = this.allGroups.find(item => item.project_group_mapping_id == key);
        if(groupName) {
          obj = {
            "groupName" : groupName.training_certification_group.name,
            "details" : value,
            "id": key
          }
        }
        this.allGroupIDs.push(parseInt(key))
      } else {
        obj = {
          "groupName" : this.additionalFarmer,
          "details" : value,
          "id": key
        }
      }
      this.currentTab.push(obj);
    }
    this.initialize(0)

    this.groundForm.patchValue(obj);
    this.groundForm.markAsPristine();
    this.spinner.hide();
  }

  initialize(index){    
    for(const item of this.currentTab){
      for(const i of item.details){
        const farmerDetails = i.farmer_reg;
        farmerDetails.project_group_actor_mapping_id = i.project_group_actor_mapping_id;
        farmerDetails.id = i.id;
        this.groupFarmers.push(farmerDetails);
      }
    }
    this.groupClicked(index);
  }

  groupClicked(index, newGroup?){    
    this.spinner.show();
    this.currentGroup = this.currentTab[index];
    this.currentGroupIndex = index;
    if(this.currentGroup.id != "EXTERNAL"){
      this.trainingService.getGroupFarmers(this.currentGroup.id).subscribe(result => {
        if (result['msg'] === 'success') {
          this.currentGroupFarmers = [];
          for(const item of result['data']){
            const farmerExistInOtherGroup = this.checkFarmerInOtherGroup(item);
            if(!farmerExistInOtherGroup){
              this.currentGroupFarmers.push(item);
            }
          }
          if(newGroup && this.currentGroupFarmers.length == 0){
            this.currentTab.splice(index, 1);
            this.commonService.showToast('warning', 'no_unique_farmer_found_this_group', {});
            this.groupClicked(0);
          } else if(newGroup){
            this.commonService.showToast('success', 'unique_farmers_added_successfully', {});
          }
          for(const id of this.currentGroupFarmers){
            if(this.allProjectFarmers.indexOf(id) < 0){
              this.allProjectFarmers.push(id);
            }
            if(newGroup){
              this.updatedFarmers.push(id);
            }
          }
          this.spinner.hide();
        }
      });
    } else {
      this.currentGroupFarmers = [];
      for(const item of this.currentGroup.details){
        const farmerExistInOtherGroup = this.checkFarmerInOtherGroup(item.farmer_reg);
        if(!farmerExistInOtherGroup){
          const exists = this.currentGroupFarmers.find(e => e.farmer_id === item.farmer_reg.farmer_id);
          if (!exists) {
            this.currentGroupFarmers.push(item.farmer_reg);
          }
        }
      }
      if(newGroup && this.currentGroupFarmers.length == 0){
        this.currentTab.splice(index, 1);
        this.commonService.showToast('warning', 'no_unique_farmer_found_this_selection', {});
        this.groupClicked(0);
      } else if(newGroup){
        this.commonService.showToast('success', 'unique_farmers_added_successfully', {});
      }
      for(const id of this.currentGroupFarmers){
        if(this.allProjectFarmers.indexOf(id) < 0){
          this.allProjectFarmers.push(id);
          if(newGroup){
            this.updatedFarmers.push(id);
          }
        }
      }
      this.spinner.hide();
    }
    this.selectedTrainings = [];
    for(const item of this.currentGroup.details){
      if(item.certified == 1){
        if(this.selectedTrainings.indexOf(item.farmer_id) < 0){
          this.selectedTrainings.push(item.farmer_id);
        }
        if(this.globalTrainings.indexOf(item.farmer_id) < 0){
          this.globalTrainings.push(item.farmer_id);
        }
      }
    }
  }

  checkFarmerInOtherGroup(farmer){
    let farmerExists = false;
    for(const item of this.currentTab){
      if(item.id != this.currentGroup.id){
        for(const i of item.details){
          if(farmer.farmer_id == i.farmer_reg.farmer_id){
            farmerExists = true;
          }
        }
      }
    }
    return farmerExists;
  }

  groupSelected(e){
    this.spinner.show();
    this.showAddButton();
    if(e.target.value !=  0){
      this.trainingService.getGroupFarmers(e.target.value).subscribe(result => {
        if (result['msg'] === 'success') {
          const data = result['data'];
          this.groupFarmers = this.groupFarmers.concat(data);
          this.groupFarmers = this.groupFarmers.filter((v,i,a)=>a.findIndex(t=>(t.farmer_id===v.farmer_id))===i)
         
          this.spinner.hide();
        }
      });
    }
  }

  addNewGroupDD(){
    const groupNo = this.totalSelect.length + 1;
    this.groundForm.addControl(`group${groupNo}`, new FormControl('0', Validators.required));
    this.totalSelect.push(`group${groupNo}`)
  }

  removeNewGroupDD() {
    const groupNo = this.totalSelect.length;
    this.groundForm.removeControl(`group${groupNo}`);
    this.totalSelect.pop()
  }

  saveUpdateTraining(){
    this.spinner.show();
    const certificateName = this.groundForm['value'].certificateName;
    const certificationDate = this.groundForm['value'].certificationDate;
    const expiryDate = this.groundForm['value'].expiryDate;
    const description = this.groundForm['value'].description;
    const farmerData = [];
    for(const item of this.updatedFarmers){
      const farmer = this.groupFarmers.find(item2 => item2.farmer_id == item.farmer_id);
      let obj = {};
      if(farmer){
        obj = {
          "id": farmer.id,
          "certification_recording_hdr_id":  this.hdrID,
          "farmer_id": farmer.farmer_id,
          "project_group_actor_mapping_id": farmer.project_group_actor_mapping_id,
          "certified": item.hasOwnProperty('certified') ? item.certified : this.globalTrainings.indexOf(item.farmer_id) > -1 ? 1 : 0
        }
      } else {
        obj = {
          "farmer_id": item.farmer_id,
          "certification_recording_hdr_id":  this.hdrID,
          "project_group_actor_mapping_id": item.project_group_actor_mapping_id,
          "certified": item.hasOwnProperty('certified') ? item.certified : this.globalTrainings.indexOf(item.farmer_id) > -1 ? 1 : 0
        }
      }
      farmerData.push(obj);
    }
    const certificateToAdd = {
      "id": this.hdrID,
      "project_training_certification_mapping_id": certificateName,
      "certification_date": certificationDate,
      "expiry_date": expiryDate,
      "description": description,
      "for_individual": 0,
      "certification_recording_dtl": farmerData
    }
    this.trainingService.updateCertification(certificateToAdd).subscribe(
      (result) => {
        this.commonService.showToast('success', 'certification_updated_successfully', {});
        this.editTraining();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  navigateSection(direction){
    const index = this.currentGroupIndex + direction;
    this.groupClicked(index)
  }

  async manageFarmers(project, template) {
    this.searchTextFarmers = ""
    this.confirmFarmerModal = true;
    if (!this.loadingFarmers) {
      this.spinner.show();
      this.loadingFarmers = true;
      this.managingFarmersForGroup = project;
      
      const requests = await Promise.all([
        this.farmerService.getFarmersByProjectBasicData(project.id).toPromise()
      ]);
      
      this.loadingFarmers = false;
      this.spinner.hide();
      
      if (requests[0]['msg'] === 'success') {
        this.farmers = requests[0].data;
        for(const item of this.farmers){
          item.village_name = item.region_village.village_name
        }
        this.sortFarmers();
      }
      for(const item of this.currentTab){
        for(const i of item.details){
          this.selectedFarmersCopy.push(i.farmer_reg.farmer_id);
        }
      }
  
      if (this.farmers.length === 0) {
        this.commonService.showToast('info', 'no_farmers_regd', {});
      } else {
        this.farmersListPage = 1;
        this.tooltipText = undefined;
        this.manageFarmersModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
      }
    }
  }

  confirmManageFarmers(){
    const toAddInExternal = [];
    for(const item of this.selectedFarmers){
      const checkFarmerExist = this.allProjectFarmers.find(item2 => item2.farmer_id == item);
      if(!checkFarmerExist){
        const farmer = this.farmers.find(item2 => item2.id == item);
        const obj = {
          "age": farmer.age,
          "farmer_code": farmer.farmer_code,
          "farmer_id": farmer.id,
          "name": farmer.name,
          "official_id": farmer.official_id,
          "project_group_actor_mapping_id": null,
          "sex": farmer.sex
        }
        toAddInExternal.push({"farmer_reg":obj});
      }
    }
    const findExternal = this.currentTab.find(item => item.id == "EXTERNAL");
    if(findExternal){
      for(const item of toAddInExternal){
        findExternal.details.push(item);
      }
      this.groupClicked(this.currentTab.indexOf(findExternal), true);
    } else {
      const obj = {
        "groupName" : this.additionalFarmer,
        "details" : toAddInExternal,
        "id": "EXTERNAL"
      }
      this.currentTab.push(obj);
      this.groupClicked(this.currentTab.length - 1, true);
    }
    this.showAddButton();
    this.commonService.showToast('success', 'unique_farmers_added_successfully', {});
    this.declineManageFarmers();
  }

  cancelManageFarmers(){
    let isEqual = false;
    for(const val of this.selectedFarmers){
      if(this.selectedFarmersCopy.indexOf(val) < 0){
        isEqual = true;
      }
    }
    if(isEqual){
      this.confirmFarmerModal = false;
    } else {
      this.declineManageFarmers();
    }
  }

  declineManageFarmers(): void {
    this.selectedFarmers = [];
    this.farmers = [];
    this.managingFarmersForGroup = undefined;
    if (this.manageFarmersModal) {
      this.manageFarmersModal.hide();
    }
  }

  
  confirmCloseFarmerNo(){
    this.confirmFarmerModal = true;
  }

  // Toggle a particular farmer's selection status
  toggleThisFarmerSelect(id): void {
    if (this.selectedFarmers.indexOf(id) > -1) {
      this.selectedFarmers = this.selectedFarmers.filter(item => item !== id);
    }
    else {
      this.selectedFarmers.push(id);
    }
    this.setTooltipText();
  }

  toggleThisTrainingSelect(id): void {
    if (this.selectedTrainings.indexOf(id) > -1) {
      this.selectedTrainings = this.selectedTrainings.filter(item => item !== id);
      this.globalTrainings = this.globalTrainings.filter(item => item !== id);      
      this.updateFarmer(id, "certified", 0)
    }
    else {
      this.selectedTrainings.push(id);
      this.globalTrainings.push(id);
      this.updateFarmer(id, "certified", 1);
    }
  }

  toggleThisSpouseSelect(id): void {
    if (this.selectedSpouse.indexOf(id) > -1) {
      this.selectedSpouse = this.selectedSpouse.filter(item => item !== id);
      this.globalSpouse = this.globalSpouse.filter(item => item !== id);
      this.updateFarmer(id, "spouse_attended", 0);
    }
    else {
      this.selectedSpouse.push(id);
      this.globalSpouse.push(id);
      this.updateFarmer(id, "spouse_attended", 1);
    }
  }
  toggleThisChildSelect(id): void {
    if (this.selectedChild.indexOf(id) > -1) {
      this.selectedChild = this.selectedChild.filter(item => item !== id);
      this.globalChild = this.globalChild.filter(item => item !== id);
      this.updateFarmer(id, "child_attended", 0);
    }
    else {
      this.selectedChild.push(id);
      this.globalChild.push(id)
      this.updateFarmer(id, "child_attended", 1);
    }
  }

  updateFarmer(id, field, val){
    this.showAddButton();
    const farmer = this.allProjectFarmers.find(item => item.farmer_id == id);
    if(farmer){
      const exist = this.updatedFarmers.find(item => item.farmer_id == id);
      if(exist){
        exist[field] = val;
      } else {
        farmer[field] = val;
        this.updatedFarmers.push(farmer);
      }
    }    
  }
  // Select / deselect all farmers in the array
  toggleAllFarmers(): void {
    // If all farmers are already selected, then deselect all farmers
    if (this.selectedFarmers.length === this.farmers.length) {
      this.selectedFarmers = [];
    }
    // If all farmers are NOT already selected, select all the farmers
    else {
      this.selectedFarmers = [];
      for (const val of this.farmers) {
        this.selectedFarmers.push(val.id);
      }
    }
    this.setTooltipText();
  }

  toggleAllFarmersTrainings(): void {
    if (this.selectedTrainings.length === this.currentGroupFarmers.length) {
      this.selectedTrainings = [];
      for (const val of this.currentGroupFarmers) {
        this.updateFarmer(val.farmer_id, "certified", 0);
        this.globalTrainings = this.globalTrainings.filter(item => item !== val.farmer_id)
      }
    }
    else {
      this.selectedTrainings = [];
      for (const val of this.currentGroupFarmers) {
        if(!val.is_discontinued){
          this.selectedTrainings.push(val.farmer_id);
          this.updateFarmer(val.farmer_id, "certified", 1);
          if(this.globalTrainings.indexOf(val.farmer_id) < 0 ){
            this.globalTrainings.push(val.farmer_id);
          }
        }
      }
    }
  }

  toggleAllSpouses(): void {
    if (this.selectedSpouse.length === this.currentGroupFarmers.length) {
      this.selectedSpouse = [];
      for (const val of this.currentGroupFarmers) {
        this.updateFarmer(val.farmer_id, "spouse_attended", 0);
        this.globalSpouse = this.globalSpouse.filter(item => item !== val.farmer_id)
      }
    }
    else {
      this.selectedSpouse = [];
      for (const val of this.currentGroupFarmers) {
        this.selectedSpouse.push(val.farmer_id);
        this.updateFarmer(val.farmer_id, "spouse_attended", 1);
        if(this.globalSpouse.indexOf(val.farmer_id) < 0 ){
          this.globalSpouse.push(val.farmer_id);
        }
      }
    }
    //this.setTooltipText();
  }
  toggleAllChildren(): void {
    if (this.selectedChild.length === this.currentGroupFarmers.length) {
      this.selectedChild = [];
      for (const val of this.currentGroupFarmers) {
        this.updateFarmer(val.farmer_id, "child_attended", 0);
        this.globalChild = this.globalChild.filter(item => item !== val.farmer_id)
      }
    }
    else {
      this.selectedChild = [];
      for (const val of this.currentGroupFarmers) {
        this.selectedChild.push(val.farmer_id);
        this.updateFarmer(val.farmer_id, "child_attended", 1);
        if(this.globalChild.indexOf(val.farmer_id) < 0 ){
          this.globalChild.push(val.farmer_id);
        }
      }
    }
  }

  setTooltipText(): void {
    if (this.farmers && this.selectedFarmers) {
      if (this.selectedFarmers.length === this.farmers.length) {
        // Deselect farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.deselect} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.deselect} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      } else {
        // Select farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.select} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.select} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      }
    }
  }

  addGroup(template){
    this.addGroupModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  submitNewGroup(){
    const groupName = this.allGroups.find(item => item.project_group_mapping_id == this.newGroup);
    if(groupName) {
      const obj = {
        "groupName" : groupName.training_certification_group.name,
        "details" : [],
        "id": this.newGroup
      }
      const findExternal = this.currentTab.find(item => item.id == "EXTERNAL");
      if(findExternal){
        this.currentTab.splice(-1, 0, obj);
      } else {
        this.currentTab.push(obj);
      }
      const getIndex = this.currentTab.find(item => item.id == this.newGroup);      
      this.groupClicked(this.currentTab.indexOf(getIndex), true);
      this.allGroupIDs.push(this.newGroup);
      this.showAddButton();
      this.closeGroupPopup();
    }
  }

  closeTypePopup(){
    if(this.newGroup == 0){
      this.closeGroupPopup()
    } else {
      this.confirmGroupModal = false;
    }
  }
  closeGroupPopup() {
    if(this.addGroupModal){
      this.addGroupModal.hide();
      this.newGroup = 0;
    }
  }
  confirmModalFn(decision){
    if(decision == "Yes"){
      this.closeGroupPopup();
      this.confirmGroupModal = true;
    } else {
      this.confirmGroupModal = true;
    }
  }

  sortFarmers(): void {
    this.farmers.sort((a, b) => {
      if (a.cr_date < b.cr_date) {
        return 1;
      }
      if (a.cr_date > b.cr_date) {
        return -1;
      }
      return 0;
    });
  }

  getGridFiltersFarmers() {
    const gridColums = ['farmer_code', 'name', 'official_id', 'age', 'village_name','village_collectors', 'cr_date'];
    const finalObj = {};
    if (this.farmers === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchTextFarmers;
      });
    }
    return finalObj;
  }

  /* file upload */
  handleTrainingFileInput(file: FileList) {
    // // debugger;
    const fileItem = file.item(0);
    
    const msg = this.commonService.ValidateFile(fileItem);

    if (msg && msg.length > 0) {
      this.toastrService.warning(msg);
      return;
    }
    new Compressor(fileItem, {
      quality: environment.imageCompress.quality,
      maxWidth:environment.imageCompress.maxWidth,
      maxHeight:environment.imageCompress.maxHeight,
      mimeType:environment.imageCompress.mimeType,
      convertSize:environment.imageCompress.convertSize,
      success(result) {
        setFarmerImageData(result);
      },
      error(err) {
        console.log(err.message);
      },
    });

    const setFarmerImageData = (result) => {
      this.commonService.blobToBase64(result).then((imageData)=>{
        const obj = {
          "image_file": imageData,
          "image_for" : fileItem.name
        }
        this.uploadedTrainingFiles.push(obj)
      });
    };


  }

  showAddButton(){
    if(this.groundForm['value'].certificationDate != null ){
      this.disableAddButton = false;
    } else {
      this.disableAddButton = true;
    }
  }
  removeTrainingFile(fileName){
    const x = this.uploadedTrainingFiles.find(item => item.image_for == fileName);
    const index = this.uploadedTrainingFiles.indexOf(x);
    if (index !== -1) {
      this.uploadedTrainingFiles.splice(index, 1);    
    }
  }

  removeTrainingFileOld(id){
    this.trainingService.deleteTrainingDocument(id, {}).subscribe(
      (result) => {
        this.commonService.showToast('success', 'file_deleted_successfully', {});
        this.uploadedTrainingFilesOld = this.uploadedTrainingFilesOld.filter(item => item.id !== id);
      },
      (error) => {        
        this.commonService.showToast('error', error, {});
      }
    );
  }

  exitClicked(myForm: FormGroup, template: TemplateRef<any>){
    if(myForm.dirty){
      this.confirmModalPopup = false;
      this.confirmPopup = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
    }else{
      const url = 'training-management/' + this.projectId;
      this.router.navigate([url]);
    }
  } 

  confirmExitModal(item){
    if(item === 'Yes'){
        this.confirmModalPopup = true;
        if (this.confirmPopup) {
          this.confirmPopup.hide();
        }
        const url = 'training-management/' + this.projectId;
        this.router.navigate([url]);
    }else if(item === 'No'){
      this.confirmModalPopup = true;
      if (this.confirmPopup) {
        this.confirmPopup.hide();
      }
    }
  }

  getGridFilters() {
    //new fields added for search village_name and vc_name
    const gridColums = ['farmer_code', 'name', 'age', 'sex','official_id'];
    const finalObj = {};
    if (this.farmers === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  sortBy(sortOn: string) {
    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    this.isAscendingCode = sortBy !== 'farmer_code';
    this.isAscendingName = sortBy !== 'name';
    this.isAscendingAge = sortBy !== 'age';
    this.isAscendingSex = sortBy !== 'sex';
    this.isAscendingOfficialID = sortBy !== 'official_id';
    // Sort of new fields added
    if (sortBy === 'farmer_code' || sortBy === 'official_id' || sortBy === 'name' || sortBy === 'age' || sortBy === 'sex') {
      this.currentGroupFarmers.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        } 
        return 0;
      });
    }

  }

  descendic(sortBy: string) {
    this.isAscending = true;

    this.isAscendingCode = sortBy === 'farmer_code';
    this.isAscendingName = sortBy === 'name';
    this.isAscendingAge = sortBy === 'age';
    this.isAscendingSex = sortBy === 'sex';
    this.isAscendingOfficialID = sortBy === 'official_id';
    // Sort of new fields added
    if (sortBy === 'farmer_code' || sortBy === 'official_id' || sortBy === 'name' || sortBy === 'age' || sortBy === 'sex') {
      this.currentGroupFarmers.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    }
  }

  farmerSortBy(sortOn: string) {
    this.isAscendingFarmer ? this.ascendicFarmer(sortOn) : this.descendicFarmer(sortOn);
  }

  ascendicFarmer(sortBy: string) {
    this.isAscendingFarmer = false;

    this.isAscendingIdFarmer = sortBy !== 'id';
    this.isAscendingNameFarmer = sortBy !== 'name';
    this.isAscendingTelFarmer = sortBy !== 'tel_number';
    this.isAscendingDateFarmer = sortBy !== 'cr_date';
    this.isAscendingAgeFarmer = sortBy !== 'age';
    this.isAscendingSexFarmer = sortBy !== 'sex';
    // Sort of new fields added
    this.isAscendingVillageFarmer = sortBy !== 'village_name';
    this.isAscendingVCFarmer = sortBy !== 'village_collectors';   
    this.isAscendingOfficialID = sortBy !== 'official_id';
    // Sort of new fields added
    if (sortBy === 'name') {
      this.farmers.sort((a, b) => {
        if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
          return 1;
        }
        if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
      this.farmers.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'village_name') {
      this.farmers.sort((a, b) => {
        if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
          return 1;
        }
        if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }

  descendicFarmer(sortBy: string) {
    this.isAscendingFarmer = true;

    this.isAscendingIdFarmer = sortBy === 'id';
    this.isAscendingNameFarmer = sortBy === 'name';
    this.isAscendingTelFarmer = sortBy === 'tel_number';
    this.isAscendingDateFarmer = sortBy === 'cr_date';
    this.isAscendingAgeFarmer = sortBy === 'age';
    this.isAscendingSexFarmer = sortBy === 'sex';
    // Sort of new fields added
    this.isAscendingVillageFarmer = sortBy === 'village_name';
    this.isAscendingVCFarmer = sortBy === 'village_collectors';   
    this.isAscendingOfficialID = sortBy === 'official_id';
    // Sort of new fields added
    if (sortBy === 'name') {
      this.farmers.sort((a, b) => {
        if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
          return 1;
        }
        if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
      this.farmers.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'village_name') {
      this.farmers.sort((a, b) => {
        if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
          return 1;
        }
        if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  trackBytraining(index, dataItem) {
    return dataItem.id;
  }
  trackBytrainer(index, dataItem) {
    return dataItem.id;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeTypePopup();    
  }
}
