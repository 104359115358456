import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  public version: string;
  public windowScrolled: boolean;
  deferredPrompt;
  showInstallPromotion = true;
  constructor(
    public loginService: LoginService,
    @Inject(DOCUMENT) private readonly document: Document
  ) { }

  ngOnInit() {
    this.scrollToTop();
    this.loginService.getVersion()
    .subscribe(response => {
      if (response['msg'] == 'success'){
        this.version = response['msgText'];
      }
      else {
        this.version = 'Unknown';
      }
    })
  }

  public wizardShow = false;
  public wizardBtn:any = 'Show';

  wizardToggle() {
    this.wizardShow = !this.wizardShow;
  }

  @HostListener("window:scroll", [])
    onWindowScroll() {
        if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
            this.windowScrolled = true;
        }
        else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
            this.windowScrolled = false;
        }
    }
    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }


    showInstallPrompt() : void {
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice
        .then((choiceResult) => {
        this.deferredPrompt = null;
        });
    }



}
