import { Component, OnInit, TemplateRef, HostListener, Inject, LOCALE_ID } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { mapTo } from 'rxjs/operators';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';

import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SuppliersService } from '../../services/suppliers.service';

declare var idbApp: any;

declare var $: any;

declare var serviceWorkerVar;

@Component({
  selector: 'app-ecosystemmanagement',
  templateUrl: './ecosystemmanagement.component.html',
  styleUrls: ['./ecosystemmanagement.component.css']
})
export class EcosystemmanagementComponent implements OnInit {
  isOnline: Observable<boolean>;
  projectId = 0;
  project: any;
  projects: any;
  ecosystems: any;
  farmers = [];
  productType = [];
  managers = [];
  public ecoSystemUserForm: FormGroup;
  productTypeName: string;
  managerName: string;
  addUserModal: BsModalRef;
  importUserModal: BsModalRef;
  public confirmModal = true;
  isAscending = true;
  isAscendingId = true;
  isAscendingName = true;
  isAscendingTel = true;
  isAscendingDate = true;
  sortLabel = '';
  public ecoSystemData:any;
  public valueOptions: any;
  public showSpinner = false;

  isFieldAgent = false;
  isManager = false;
  isDeeproots = false;

  farmerFilterString: any = { name: '' };

  page = 1;
  itemsPerPage = 10;

  public previousPaginationLabel;
  public nextPaginationLabel;
  public isInIDBInThisProject = [];
  public showSyncSuccessAlert = false;
  public closeSnapshotModal: BsModalRef;
  public modifySnapshotModal: BsModalRef;

  public allSnapshots;
  public snapshot;
  public olderSnapshots = [];

  public snapshotNameTemp;
  public bsDateRange;
  public snapshotModifying;
  public oldSnapshotInfo;

  public minDate = new Date();
  public projectUndefined;

  public getSnapshotInterval;
  public noSnapshotsInProject = false;
  public fetchingSnapshotInfo = true;

  public disableCreateSnapshot = false;
  public disableCloseSnapshot = false;

  public registerFarmerModal;
  public updateFarmerModal;
  public registeringFarmerName;
  public registeringFarmerAge;
  public registeringFarmerSex;
  public registeringFarmerArea;
  public registeringFarmer = false;

  public master;
  public sex;
  public registerFarmerForm: FormGroup;
  public updateFarmerForm: FormGroup;
  public registerAnotherFarmer = false;
  fileToUpload: File = null;
  photoModel = '';

  oldSnapshotId = 0;
  arrSnapshotRevisions = [];
  snapshotRevision = [];
  public id:any;
  public projectData:any;

  farmerUpdating;

  searchText = '';
  public addingUserToEcosystemId;
  public countryData: any = {};
  public autoSelectData: any = {};
  public multiSelectData: any = {};
  public dateFormat: any;
  public addForm = false;
  public f1Submitted = false;
  public confirmUpdateModal = true;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly commonService: CommonService,
    private readonly router: Router,
    private readonly farmerService: FarmerService,
    private readonly projectService: ProjectService,
    private readonly translate: TranslateService,
    public toastrService: ToastrService,
    public modalService: BsModalService,
    private readonly localeService: BsLocaleService,
    private readonly formBuilder: FormBuilder,
    private readonly revisionService: RevisionService,
    private readonly suppService: SuppliersService,
    private readonly spinner: Ng4LoadingSpinnerService,
    @Inject(LOCALE_ID) private readonly locale: string,) {

      this.route.paramMap.subscribe(params => {
        this.id = params.get('id');
      });

    this.isOnline = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });

    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });
    this.localeService.use(this.locale);
  }
  snapshotChanged() {
    this.snapshotRevision = this.arrSnapshotRevisions.filter(x => x.snapshot_id_ref === this.oldSnapshotId);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.addForm){
      this.hideAddUserModal(this.ecoSystemUserForm);
    }    
  }

  async ngOnInit() {
    this.scrollToTop();
    this.ecoSystemUserForm = this.formBuilder.group({
    });
    this.projectId = +this.route.snapshot.params['id'];
    const code = this.commonService.checkRole();

    if (code == 'FAG') {
      this.isFieldAgent = true;
    }
    else if (code === 'PMG') {
      this.isManager = true;
    }
    else if (code === 'DRO') {
      this.isDeeproots = true;
    }

    if (navigator.onLine) {
      this.spinner.show();
    this.projectService.getEcosystems()
    .subscribe(
      (ecosystems) => {
        if (ecosystems.msg === 'success') {
            const finalObj = [];
            for(let i = 0 ; i < ecosystems.data.length; i++) {
              const newObject = {};
              newObject[i] = {
                'suppliername':ecosystems.data[i].ecosystem.supplier.name,
                'productname':ecosystems.data[i].ecosystem.product.name, 
                'xcoordinate':ecosystems.data[i].ecosystem.x_coordinate, 
                'ycoordinate':ecosystems.data[i].ecosystem.y_coordinate ,
                'marker_description': ecosystems.data[i].ecosystem.marker_description,
                'status':ecosystems.data[i].ecosystem.status
              };
              finalObj.push(newObject[i])
            }
            this.ecosystems = finalObj;
            this.spinner.hide();
            this.getAddUserData();  
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );

    } else {

      const requests = await Promise.all([
        this.projectService.GetProjectsDataDashboard().toPromise()
     ]);
      this.project =  requests[0].data.find(x => x.id === this.projectId);

    }
  }

  getProjectData(){
    this.projectService.GetProject(this.id).subscribe(response => {
      this.projectData = response.data;
    });
  }


  goToDashboard(): void {
    this.router.navigate(['dashboard']);
  }


  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  ngOnDestroy() {
    this.showSpinner = false;
    clearInterval(this.getSnapshotInterval);
  }

  goToEditProject() {
    if (navigator.onLine) {
      const url = `setup/${this.project.id}`;
      this.router.navigate([url]);
    } else {
      this.toastrService.warning('You cannot edit a project while offline');
    }
  }

  sortBy(sortOn: string) {


    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);

  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    if (sortBy === 'suppliername') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.ecosystems.sort((a, b) => {
        if (a.suppliername.toLowerCase() < b.suppliername.toLowerCase()) {
          return 1;
        }
        if (a.suppliername.toLowerCase() > b.suppliername.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'productname') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.ecosystems.sort((a, b) => {
        if (a.productname.toLowerCase() < b.productname.toLowerCase()) {
          return 1;
        }
        if (a.productname.toLowerCase() > b.productname.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'xcoordinate') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.ecosystems.sort((a, b) => {
        if (a.xcoordinate < b.xcoordinate) {
          return 1;
        }
        if (a.xcoordinate > b.xcoordinate) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'ycoordinate') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.ecosystems.sort((a, b) => {
        if (a.ycoordinate < b.ycoordinate) {
          return 1;
        }
        if (a.ycoordinate > b.ycoordinate) {
          return -1;
        }
        return 0;
      });
    }else if (sortBy === 'marker_description') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.ecosystems.sort((a, b) => {
        if (a.marker_description.toLowerCase() < b.marker_description.toLowerCase()) {
          return 1;
        }
        if (a.marker_description.toLowerCase() > b.marker_description.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }else if (sortBy === 'status') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.ecosystems.sort((a, b) => {
        if (a.status.toLowerCase() < b.status.toLowerCase()) {
          return 1;
        }
        if (a.status.toLowerCase() > b.status.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }

  }

  descendic(sortBy: string) {
    this.isAscending = true;
    if (sortBy === 'suppliername') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.ecosystems.sort((a, b) => {
        if (a.suppliername.toLowerCase() > b.suppliername.toLowerCase()) {
          return 1;
        }
        if (a.suppliername.toLowerCase() < b.suppliername.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'productname') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.ecosystems.sort((a, b) => {
        if (a.productname.toLowerCase() > b.productname.toLowerCase()) {
          return 1;
        }
        if (a.productname.toLowerCase() < b.productname.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'xcoordinate') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.ecosystems.sort((a, b) => {
        if (a.xcoordinate > b.xcoordinate) {
          return 1;
        }
        if (a.xcoordinate < b.xcoordinate) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'ycoordinate') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.ecosystems.sort((a, b) => {
        if (a.ycoordinate > b.ycoordinate) {
          return 1;
        }
        if (a.ycoordinate < b.ycoordinate) {
          return -1;
        }
        return 0;
      });
    }else if (sortBy === 'marker_description') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.ecosystems.sort((a, b) => {
        if (a.marker_description.toLowerCase() > b.marker_description.toLowerCase()) {
          return 1;
        }
        if (a.marker_description.toLowerCase() < b.marker_description.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }else if (sortBy === 'status') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.ecosystems.sort((a, b) => {
        if (a.status.toLowerCase() > b.status.toLowerCase()) {
          return 1;
        }
        if (a.status.toLowerCase() < b.status.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }


  getGridFilters() {
    const gridColums = [ 'suppliername', 'productname', 'xcoordinate','ycoordinate','marker_description', 'status'];
    
    const finalObj = {};
    if (this.ecosystems === undefined) {
    return {};
    } else {
    gridColums.forEach(element => {
    finalObj[element] = this.searchText;
    });
    }
    return finalObj;
  }

  sortByItem(item) {

    if (item.isAscending) {
      this.asc(item)
    } else {
      this.desc(item)
    }

    this.ecosystems.forEach(element => {
      element.isAscending = true;
    });
    item.isAscending = !item.isAscending;

  }
  asc(item) {
      this.ecosystems.sort((a, b) => {
        let finala = '';
        let finalb = '';
        if (item.field_type === 'dropdown' && item.api_url !== null) {
          finala = a[item.ref_modal_name]['name'];
          finalb = b[item.ref_modal_name]['name'];
        } else {
          finala = a[item.mapping_table_field];
          finalb = b[item.mapping_table_field];
        }

        if (finala === null) {
          finala = '';
        }

        if (finalb === null) {
          finalb = '';
        }

        if (finala.toLowerCase() > finalb.toLowerCase()) {
          return 1;
        }
        if (finala.toLowerCase() < finalb.toLowerCase()) {
          return -1;
        }

        return 0;
      });
    }
  

  desc(item) {
      this.ecosystems.sort((a, b) => {
        let finala = '';
        let finalb = '';
        if (item.field_type === 'dropdown' && item.api_url !== null) {
          finala = a[item.ref_modal_name]['name'];
          finalb = b[item.ref_modal_name]['name'];
        } else {
          finala = a[item.mapping_table_field];
          finalb = b[item.mapping_table_field];
        }

        if (finala === null) {
          finala = '';
        }

        if (finalb === null) {
          finalb = '';
        }

        if (finala.toLowerCase() < finalb.toLowerCase()) {
          return 1;
        }
        if (finala.toLowerCase() > finalb.toLowerCase()) {
          return -1;
        }

        return 0;
      });
    }
  


  addUserToEcosystemSubmit(form:any): void {
    const newObject = {
      ecosystem_id:this.addingUserToEcosystemId,
      data:form.value,
    }
  }

  addEcoUserModal(template: TemplateRef<any>,currentItem, importUserModalBool: boolean) {
    this.addingUserToEcosystemId = currentItem;
    if (importUserModalBool) {
      this.importUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    } else {
      this.confirmModal = true;
      this.addUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
      this.addForm = true;
    }
  }

  confirmModalYes(valItem: any) {
    if (valItem === 'add') {
      if (this.addUserModal) {
        this.addUserModal.hide();
      }
      this.ecoSystemUserForm.reset();
      this.f1Submitted = false;
    } 
  }

  confirmModalNo(valItem: any) {
    if (valItem === 'add') {
      this.confirmModal = true;
    }

  }

  getAddUserData() {
    this.suppService.getMaster('USERS_MDM').subscribe(response => {
      this.ecoSystemData = response;


      let validationsArray;
      for (let i = 0; i < this.ecoSystemData.data.length; i++) {
        this.ecoSystemData.data[i].validations = {};
        const validatorArr = [];
        if (this.ecoSystemData.data[i].mandatory === 'Y') {
          validatorArr.push(Validators.required);
        }

        if (this.ecoSystemData.data[i].MDMValidation != null) {
          validationsArray = JSON.parse(this.ecoSystemData.data[i].MDMValidation.validation_obj);
          for (let j = 0; j < validationsArray.length; j++) {
            if (validationsArray[j].type === 'min') {
              this.ecoSystemData.data[i].validations['minlength'] = validationsArray[j].value;
              validatorArr.push(Validators.minLength(validationsArray[j].value));
            }

            if (validationsArray[j].type === 'max') {
              this.ecoSystemData.data[i].validations['maxlength'] = validationsArray[j].value;
              validatorArr.push(Validators.maxLength(validationsArray[j].value));
            }

            if (validationsArray[j].type === 'email') {
              validatorArr.push(Validators.email);
            }

            if (validationsArray[j].type === 'number') {
              validatorArr.push(Validators.pattern('^[0-9]{5,13}$'));
            }
          }
        }

        if (this.ecoSystemData.data[i].field_type === 'dropdown') {
          if (this.ecoSystemData.data[i].value_options === null) {
            this.countryData[this.ecoSystemData.data[i].id] = {};
            this.suppService.getAPIURLMethod(this.ecoSystemData.data[i].api_url).subscribe(response => {
              this.countryData[this.ecoSystemData.data[i].id] = response;
            });

            this.ecoSystemUserForm.addControl(this.ecoSystemData.data[i].id, new FormControl({
              disabled: this.ecoSystemData.data[i].readonly === 'Y' ? true : false
            }, validatorArr));
          } else {

            this.valueOptions = JSON.parse(this.ecoSystemData.data[i].value_options);
            this.ecoSystemData.data[i]['valueOptions'] = this.valueOptions;
            this.ecoSystemUserForm.addControl(this.ecoSystemData.data[i].id, new FormControl({
              value: this.valueOptions[0].id,
              disabled: this.ecoSystemData.data[i].readonly === 'Y' ? true : false
            }, validatorArr));
          }

        } else if (this.ecoSystemData.data[i].field_type === 'autoselect') {

          if (this.ecoSystemData.data[i].value_options === null) {
            this.autoSelectData[this.ecoSystemData.data[i].id] = {};
            this.suppService.getAPIURLMethod(this.ecoSystemData.data[i].api_url).subscribe(response => {
              this.autoSelectData[this.ecoSystemData.data[i].id] = response;
            });

            this.ecoSystemUserForm.addControl(this.ecoSystemData.data[i].id, new FormControl({
              disabled: this.ecoSystemData.data[i].readonly === 'Y' ? true : false
            }, validatorArr));
          }

        } else if (this.ecoSystemData.data[i].field_type === 'multiselect') {

          if (this.ecoSystemData.data[i].value_options === null) {
            this.multiSelectData[this.ecoSystemData.data[i].id] = {};
            this.suppService.getAPIURLMethod(this.ecoSystemData.data[i].api_url).subscribe(response => {
              this.multiSelectData[this.ecoSystemData.data[i].id] = response;
            });

            this.ecoSystemUserForm.addControl(this.ecoSystemData.data[i].id, new FormControl({
              disabled: this.ecoSystemData.data[i].readonly === 'Y' ? true : false
            }, validatorArr));
          }

        } else if (this.ecoSystemData.data[i].field_type === 'date') {
          this.ecoSystemUserForm.addControl(this.ecoSystemData.data[i].id, new FormControl({
            value: null,
            disabled: this.ecoSystemData.data[i].readonly === 'Y' ? true : false
          }, validatorArr));
          this.dateFormat = 'DD-MMM-YYYY';
        } else {
          this.ecoSystemUserForm.addControl(this.ecoSystemData.data[i].id, new FormControl({
            value: null,
            disabled: this.ecoSystemData.data[i].readonly === 'Y' ? true : false
          }, validatorArr));
        }
      }
    });
  }

  setUserType(form_code, mapping_table_field) {
    if (form_code === 'USERPROGRAMMAPPING_MDM' && mapping_table_field === 'users_id') {
      const mid = this.ecoSystemData.data.find(sdata => sdata.mapping_table_field === mapping_table_field);
      const midval = this.ecoSystemUserForm.get(('' + mid.id)).value;
      const seldata = this.countryData[mid.id].data.find(sdata => sdata.id === +midval);
      let fmid = this.ecoSystemData.data.find(sdata => sdata.mapping_table_field === 'user_type_id');
      fmid = '' + fmid.id;
      this.ecoSystemUserForm.controls[fmid].setValue(seldata.user_type_id);
    }

    if (form_code === 'UNITS_MDM' && mapping_table_field === 'unit_type_id') {
      const mid = this.ecoSystemData.data.find(sdata => sdata.mapping_table_field === mapping_table_field);
      
      const midval = this.ecoSystemUserForm.get(('' + mid.id)).value;
      
      const seldata = this.countryData[mid.id].data.find(sdata => sdata.id === +midval);
      let fmid = this.ecoSystemData.data.find(sdata => sdata.mapping_table_field === 'international_unit');
      fmid = '' + fmid.id;
      this.ecoSystemUserForm.controls[fmid].setValue(seldata.international_unit);
    }
    if (form_code === 'ECOSYSTEM_USERS_MAPPING_MDM' && mapping_table_field === 'users_id') {
      const mid = this.ecoSystemData.data.find(sdata => sdata.mapping_table_field === mapping_table_field);
      const midval = this.ecoSystemUserForm.get(('' + mid.id)).value;
      const seldata = this.countryData[mid.id].data.find(sdata => sdata.id === +midval);
      let utid = this.ecoSystemData.data.find(sdata => sdata.mapping_table_field === 'user_type_id');
      utid = '' + utid.id;
      let ucid = this.ecoSystemData.data.find(sdata => sdata.mapping_table_field === 'user_category_id');
      ucid = '' + ucid.id;
      let atid = this.ecoSystemData.data.find(sdata => sdata.mapping_table_field === 'access_type_id');
      atid = '' + atid.id;
      this.ecoSystemUserForm.controls[utid].setValue(seldata.user_type_id);
      this.ecoSystemUserForm.controls[ucid].setValue(seldata.user_category_id);
      const atdata = this.countryData[atid].data.find(sdata => sdata.name === 'Full');
      this.ecoSystemUserForm.controls[atid].setValue(atdata.id);
    }
  }
  hideAddUserModal(form: any): void {
    if (form.dirty) {
      this.confirmModal = false;
    } else {
      if (this.addUserModal) {
        this.addUserModal.hide();
        this.addForm = false;
      }
      this.ecoSystemUserForm.reset();
    }
    this.f1Submitted = false;
  }

}
