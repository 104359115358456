<app-header></app-header>
<div class="project-header clearfix">

  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="getBackProjectFilter(project?.name, project?.id)" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item active cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item active" aria-current="page" i18n="@@projectDatasheet">Project Datasheet</li>
    </ol>
  </nav>
  <div class="fltRgt posTopLeft" style="top: 5px;">
    <span *ngIf="isOnline | async; else offlineBtn">
        <button class="small-button" (click)="printProjectDatasheet();$event.stopPropagation()">
            <span i18n="@@downloadDatasheet" class="mr-2">Download Datasheet</span>
            <svg width="17px" height="13px" viewBox="0 0 17 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
              <title i18n="@@projectDatasheet">Project Datasheet</title>
              <defs></defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Project-Detail" transform="translate(-722.000000, -235.000000)" fill-rule="nonzero">
                  <g id="printer" transform="translate(722.000000, 235.000000)">
                    <path d="M17,12.2682972 L17,5.80320153 C17,4.99103954 16.3360777,4.32914541 15.5214271,4.32914541 L13.7413975,4.32914541 L13.7413975,0.948118622 C13.7413975,0.425758929 13.314334,0 12.7903737,0 L4.20962635,0 C3.68566603,0 3.25860249,0.425758929 3.25860249,0.948118622 L3.25860249,4.32914541 L1.47857294,4.32914541 C0.663922314,4.32914541 0,4.99103954 0,5.80320153 L0,12.2682972 C0,13.0804592 0.663922314,13.7423533 1.47857294,13.7423533 L3.25860249,13.7423533 L3.25860249,16.5831314 C3.25860249,17.1054911 3.68566603,17.53125 4.20962635,17.53125 L12.7903737,17.53125 C13.314334,17.53125 13.7413975,17.1054911 13.7413975,16.5831314 L13.7413975,13.7387755 L15.5214271,13.7387755 C16.3396665,13.7387755 17,13.0804592 17,12.2682972 Z M3.96917881,0.948118622 C3.96917881,0.815739796 4.07684188,0.708405612 4.20962635,0.708405612 L12.7903737,0.708405612 C12.9231581,0.708405612 13.0308212,0.815739796 13.0308212,0.948118622 L13.0308212,4.32914541 L3.96917881,4.32914541 L3.96917881,0.948118622 Z M13.03441,16.5831314 C13.03441,16.7155102 12.9267469,16.8228444 12.7939624,16.8228444 L4.20962635,16.8228444 C4.07684188,16.8228444 3.96917881,16.7155102 3.96917881,16.5831314 L3.96917881,9.39174107 L13.0308212,9.39174107 L13.0308212,16.5831314 L13.03441,16.5831314 Z M16.2894237,12.2682972 C16.2894237,12.6904783 15.9449018,13.0339477 15.5214271,13.0339477 L13.7413975,13.0339477 L13.7413975,9.03753827 C13.7413975,8.84075893 13.5834917,8.68333546 13.3861094,8.68333546 L3.61389065,8.68333546 C3.41650834,8.68333546 3.25860249,8.84075893 3.25860249,9.03753827 L3.25860249,13.0339477 L1.47857294,13.0339477 C1.05509816,13.0339477 0.710576314,12.6904783 0.710576314,12.2682972 L0.710576314,5.80320153 C0.710576314,5.38102041 1.05509816,5.03755102 1.47857294,5.03755102 L15.5214271,5.03755102 C15.9449018,5.03755102 16.2894237,5.38102041 16.2894237,5.80320153 L16.2894237,12.2682972 Z"
                      id="Shape" fill="#2C2F33"></path>
                    <path d="M2.390625,6.375 C2.3208649,6.375 2.25378788,6.40451389 2.20280934,6.45280934 C2.15451389,6.50378788 2.125,6.5708649 2.125,6.640625 C2.125,6.7103851 2.15451389,6.77746212 2.20280934,6.82844066 C2.25378788,6.87673611 2.3208649,6.90625 2.390625,6.90625 C2.4603851,6.90625 2.52746212,6.87673611 2.57844066,6.82844066 C2.62673611,6.7801452 2.65625,6.7103851 2.65625,6.640625 C2.65625,6.5708649 2.62673611,6.50378788 2.57844066,6.45280934 C2.5301452,6.40451389 2.4603851,6.375 2.390625,6.375 Z"
                      id="Shape" fill="#2C2F33"></path>
                    <path d="M6.20510618,13.28125 L11.3261438,13.28125 C11.5268973,13.28125 11.6875,13.1631944 11.6875,13.015625 C11.6875,12.8680556 11.5268973,12.75 11.3261438,12.75 L6.20510618,12.75 C6.00435275,12.75 5.84375,12.8680556 5.84375,13.015625 C5.84375,13.1631944 6.00435275,13.28125 6.20510618,13.28125 Z"
                      id="Shape" fill="#3C92CA"></path>
                    <path d="M6.20510618,15.40625 L11.3261438,15.40625 C11.5268973,15.40625 11.6875,15.2881944 11.6875,15.140625 C11.6875,14.9930556 11.5268973,14.875 11.3261438,14.875 L6.20510618,14.875 C6.00435275,14.875 5.84375,14.9930556 5.84375,15.140625 C5.84375,15.2881944 6.00435275,15.40625 6.20510618,15.40625 Z"
                      id="Shape" fill="#3C92CA"></path>
                    <path d="M6.20510618,11.15625 L11.3261438,11.15625 C11.5268973,11.15625 11.6875,11.0381944 11.6875,10.890625 C11.6875,10.7430556 11.5268973,10.625 11.3261438,10.625 L6.20510618,10.625 C6.00435275,10.625 5.84375,10.7430556 5.84375,10.890625 C5.84375,11.0381944 6.00435275,11.15625 6.20510618,11.15625 Z"
                      id="Shape" fill="#3C92CA"></path>
                  </g>
                </g>
              </g>
            </svg>
          </button>
    </span>
    <ng-template #offlineBtn>
      <button [disabled]="true" class="small-button">
          <span i18n="@@downloadDatasheet" class="mr-2">Download Datasheet</span>
          <svg width="17px" height="13px" viewBox="0 0 17 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
            <title i18n="@@projectDatasheet">Project Datasheet</title>
            <defs></defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Project-Detail" transform="translate(-722.000000, -235.000000)" fill-rule="nonzero">
                <g id="printer" transform="translate(722.000000, 235.000000)">
                  <path d="M17,12.2682972 L17,5.80320153 C17,4.99103954 16.3360777,4.32914541 15.5214271,4.32914541 L13.7413975,4.32914541 L13.7413975,0.948118622 C13.7413975,0.425758929 13.314334,0 12.7903737,0 L4.20962635,0 C3.68566603,0 3.25860249,0.425758929 3.25860249,0.948118622 L3.25860249,4.32914541 L1.47857294,4.32914541 C0.663922314,4.32914541 0,4.99103954 0,5.80320153 L0,12.2682972 C0,13.0804592 0.663922314,13.7423533 1.47857294,13.7423533 L3.25860249,13.7423533 L3.25860249,16.5831314 C3.25860249,17.1054911 3.68566603,17.53125 4.20962635,17.53125 L12.7903737,17.53125 C13.314334,17.53125 13.7413975,17.1054911 13.7413975,16.5831314 L13.7413975,13.7387755 L15.5214271,13.7387755 C16.3396665,13.7387755 17,13.0804592 17,12.2682972 Z M3.96917881,0.948118622 C3.96917881,0.815739796 4.07684188,0.708405612 4.20962635,0.708405612 L12.7903737,0.708405612 C12.9231581,0.708405612 13.0308212,0.815739796 13.0308212,0.948118622 L13.0308212,4.32914541 L3.96917881,4.32914541 L3.96917881,0.948118622 Z M13.03441,16.5831314 C13.03441,16.7155102 12.9267469,16.8228444 12.7939624,16.8228444 L4.20962635,16.8228444 C4.07684188,16.8228444 3.96917881,16.7155102 3.96917881,16.5831314 L3.96917881,9.39174107 L13.0308212,9.39174107 L13.0308212,16.5831314 L13.03441,16.5831314 Z M16.2894237,12.2682972 C16.2894237,12.6904783 15.9449018,13.0339477 15.5214271,13.0339477 L13.7413975,13.0339477 L13.7413975,9.03753827 C13.7413975,8.84075893 13.5834917,8.68333546 13.3861094,8.68333546 L3.61389065,8.68333546 C3.41650834,8.68333546 3.25860249,8.84075893 3.25860249,9.03753827 L3.25860249,13.0339477 L1.47857294,13.0339477 C1.05509816,13.0339477 0.710576314,12.6904783 0.710576314,12.2682972 L0.710576314,5.80320153 C0.710576314,5.38102041 1.05509816,5.03755102 1.47857294,5.03755102 L15.5214271,5.03755102 C15.9449018,5.03755102 16.2894237,5.38102041 16.2894237,5.80320153 L16.2894237,12.2682972 Z"
                    id="Shape" fill="#2C2F33"></path>
                  <path d="M2.390625,6.375 C2.3208649,6.375 2.25378788,6.40451389 2.20280934,6.45280934 C2.15451389,6.50378788 2.125,6.5708649 2.125,6.640625 C2.125,6.7103851 2.15451389,6.77746212 2.20280934,6.82844066 C2.25378788,6.87673611 2.3208649,6.90625 2.390625,6.90625 C2.4603851,6.90625 2.52746212,6.87673611 2.57844066,6.82844066 C2.62673611,6.7801452 2.65625,6.7103851 2.65625,6.640625 C2.65625,6.5708649 2.62673611,6.50378788 2.57844066,6.45280934 C2.5301452,6.40451389 2.4603851,6.375 2.390625,6.375 Z"
                    id="Shape" fill="#2C2F33"></path>
                  <path d="M6.20510618,13.28125 L11.3261438,13.28125 C11.5268973,13.28125 11.6875,13.1631944 11.6875,13.015625 C11.6875,12.8680556 11.5268973,12.75 11.3261438,12.75 L6.20510618,12.75 C6.00435275,12.75 5.84375,12.8680556 5.84375,13.015625 C5.84375,13.1631944 6.00435275,13.28125 6.20510618,13.28125 Z"
                    id="Shape" fill="#3C92CA"></path>
                  <path d="M6.20510618,15.40625 L11.3261438,15.40625 C11.5268973,15.40625 11.6875,15.2881944 11.6875,15.140625 C11.6875,14.9930556 11.5268973,14.875 11.3261438,14.875 L6.20510618,14.875 C6.00435275,14.875 5.84375,14.9930556 5.84375,15.140625 C5.84375,15.2881944 6.00435275,15.40625 6.20510618,15.40625 Z"
                    id="Shape" fill="#3C92CA"></path>
                  <path d="M6.20510618,11.15625 L11.3261438,11.15625 C11.5268973,11.15625 11.6875,11.0381944 11.6875,10.890625 C11.6875,10.7430556 11.5268973,10.625 11.3261438,10.625 L6.20510618,10.625 C6.00435275,10.625 5.84375,10.7430556 5.84375,10.890625 C5.84375,11.0381944 6.00435275,11.15625 6.20510618,11.15625 Z"
                    id="Shape" fill="#3C92CA"></path>
                </g>
              </g>
            </g>
          </svg>
        </button>
    </ng-template>
  </div>
</div>
<div class="page-wrapper">
  <div *ngIf="project; else projectUndefined">
    <div class="content-wrap no-search content-wrapper background">
      <div class="form-design form-box padB0 clearfix">

        <div class="accordion" id="accordionExample">
          <div class="clearfix">
            <div data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" id="headingOne">
              <i class="utilicon utilicon-chevron-down"></i>
              <label class="group-title inline-block" i18n="@@projectInfo">Project Information
              </label>
            </div>

            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div class="card-body">
                <div class="clearfix"></div>
                <div class="project-info-box clearfix">
                  <div class="row">
                    <div class="col-sm-12 col-lg-6">
                      <table class="table table-project-info" id="project-information-1">
                        <tbody>
                          <tr>
                            <td i18n="@@projectName" class="project-info-label">Project Name</td>
                            <td>{{project?.name}}</td>
                          </tr>
                          <tr>
                            <td i18n="@@projectDescription" class="project-info-label">Project Description</td>
                            <td>{{project?.description}}</td>
                          </tr>
                          <tr>
                            <td i18n="@@deepRootsManager" class="project-info-label">Deep Roots Manager</td>
                            <td>{{project?.ecosystem?.dr_manager?.name}}</td>
                          </tr>
                          <tr>
                            <td class="project-info-label" i18n="@@projectAdmin">Project Admin</td>
                            <td>{{projectAdmin.length > 0 ? projectAdmin : project?.projectAdmins}}</td>
                          </tr>
                          <tr>
                            <td i18n="@@countRegisteredFarmers" class="project-info-label">No of Registered Farmers</td>
                            <td>{{farmerCount}}</td>
                          </tr>
                          <tr>
                            <td i18n="@@projectCreatedDate" class="project-info-label">Project Created on</td>
                            <td>{{project.cr_date | date: 'dd-MMM-yyyy'}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-sm-12 col-lg-6">
                      <table class="table  table-project-info" id="project-information-2">
                        <tbody>
                          <tr>
                            <td i18n="@@product" class="project-info-label">Product</td>
                            <td> {{project?.Product?.name}}</td>
                          </tr>
                          <tr>
                            <td i18n="@@typeOfProducts" class="project-info-label">Type of Product</td>
                            <td>{{project?.Product?.product_type?.name}}</td>
                          </tr>
                          <tr>
                            <td i18n="@@supplier" class="project-info-label">Supplier</td>
                            <td> {{project?.Supplier?.name}}</td>
                          </tr>
                          <tr>
                            <td i18n="@@country" class="project-info-label">Country</td>
                            <td> {{project?.Country?.name}}</td>
                          </tr>
                          <tr>
                            <td class="project-info-label" i18n="@@area" >Area</td>
                            <td> {{project?.ecosystem_region_master?.region_name}}</td>
                          </tr>
                          <tr>
                            <td i18n="@@region" class="project-info-label">Region</td>
                            <td> {{project?.region_state}}</td>
                          </tr>
                          <tr>
                            <td i18n="@@startDate" class="project-info-label">Start Date</td>
                            <td> {{project.start_date| date: 'dd-MMM-yyyy'}}</td>
                          </tr>
                          <tr>
                            <td i18n="@@status" class="project-info-label">Status</td>
                            <td> {{project?.ProjectStatus?.name}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="form-design form-box padB0 clearfix">
          <div class="accordion" id="accordionExample2">
            <div class="clearfix">
              <div data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" id="headingTwo">
                <i class="utilicon utilicon-chevron-down"></i>
                <label class="group-title inline-block" i18n="@@snapshotAndRevisionInformation">
                    Snapshot &amp; Revision Information</label>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample2">
                <div class="card mt-3">
                  <div i18n="@@snapshotInformation" class="inner-card-header">
                    Snapshot
                  </div>
                  <div class="card-body snapshot-status pb-3 pt-3" style="height:auto">
                    <div class="row no-gutters">
                      <div class="col-sm-12 col-lg-4">
                        <div class="inner-card">
                          <div class="inner-card-header" i18n="@@snapshotInformation1">Snapshot Information</div>
                          <div class="inner-card-body">
                            <div *ngIf="loadingSurveyData">
                              <p>Loading...</p>
                            </div>
                              <div *ngIf="!loadingSurveyData && snapshotSurveys?.length == 0;">
                                <p i18n="@@noSnapshotCreated">No snapshot created</p>
                              </div>
                              <div class="" *ngFor="let survey of snapshotSurveys; let i = index">
                                <div *ngIf="i == 0">
                                  <div class="">
                                    <p class="card-title"><strong i18n="@@activeSnapshot">Active Snapshot:</strong>
                                      <span i18n="@@snapshotInformation" class="pl-1">Snapshot</span><span>{{survey.survey_type_sequence ? ' ' + survey.survey_type_sequence : ''}}</span>
                                    </p>
                                    <p class="card-title"><strong i18n="@@created">Created:</strong> {{survey.cr_date | date: 'dd-MMM-yyyy hh:mm a'}}</p>
                                    <p class="card-title" *ngIf="survey?.survey_stage_changelogs[0].change_type == 'draft'"><strong i18n="@@stageDraft">Stage:
                                      </strong>
                                      <span class="badge badge-info" i18n="@@draft">Draft</span></p>
                                    <p class="card-title" *ngIf="survey?.survey_stage_changelogs[0].change_type == 'active'"><strong i18n="@@stageActive">Stage:
                                      </strong>
                                      <span class="badge badge-success" i18n="@@active">Active</span>
                                    </p>
                                    <p class="card-title" *ngIf="survey?.survey_stage_changelogs[0].change_type == 'closed'">
                                      <strong i18n="@@stageClosed">Stage: </strong>
                                      <span class="badge badge-danger" i18n="@@notActive"> Closed</span></p>
                                    <p *ngIf="survey.lastModifiedDate" class="card-title"><strong>Questionnaire updated on:</strong> {{survey.lastModifiedDate  | date: 'dd-MMM-yyyy hh:mm a'}}</p>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mt-3">
                  <div class="inner-card-header" i18n="@@revision">
                    Revision
                  </div>
                  <div class="card-body snapshot-status pb-3 pt-3" style="height:auto">
                    <div class="row no-gutters">
                      <div class="col-sm-12 col-lg-6">
                        <div class="inner-card">
                          <div class="row no-gutters">
                            <div class="col-lg-6 col-sm-6 col-md-6">
                              <div class="inner-card-header" i18n="@@revisionInformation">Revision Information</div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-md-6">
                              <div class="inner-card-header text-right" i18n="@@revisionSchedule">Revision Schedule</div>
                            </div>
                          </div>
                          <div class="inner-card-body">
                            <div *ngIf="loadingSurveyData">
                              <p>Loading...</p>
                            </div>
                            <!-- <div *ngIf="!snapshotSurveys[0].latestRevision">No Revision Found</div> -->
                            <div class="row no-gutters">
                              <div class="col-lg-6 col-sm-6 col-md-6 border-right-light-blue">
                                <div class="" *ngFor="let survey of snapshotSurveys; let i = index">
                                  <div *ngIf="i == 0">
                                    <div class="">
                                      <p class="card-title">
                                        <strong i18n="@@revisionNumber">Revision Number: </strong>
                                        <span *ngIf="snapshotSurveys[0].latestRevision && snapshotSurveys[0]?.survey_stage_changelogs[0].change_type == 'active'">{{survey?.latestRevision.revision_number}}</span>
                                        <span *ngIf="!snapshotSurveys[0].latestRevision || snapshotSurveys[0]?.survey_stage_changelogs[0].change_type != 'active'" i18n="@@none">None</span>
                                      </p>
                                      <p class="card-title">
                                        <strong i18n="@@updated">Updated: </strong> 
                                        <span *ngIf="snapshotSurveys[0].latestRevision && snapshotSurveys[0]?.survey_stage_changelogs[0].change_type == 'active'">{{survey?.latestRevision.cr_date | date: 'dd-MMM-yyyy hh:mm a'}}</span>
                                        <span *ngIf="!snapshotSurveys[0].latestRevision || snapshotSurveys[0]?.survey_stage_changelogs[0].change_type != 'active'" i18n="@@none">None</span>
                                      </p>
                                      <p class="card-title">
                                        <strong i18n="@@stageActive">Stage: </strong>
                                        <span *ngIf="snapshotSurveys[0].latestRevision && snapshotSurveys[0]?.survey_stage_changelogs[0].change_type == 'active'" class="badge badge-success" i18n="@@active">Active</span>                                    
                                        <span *ngIf="!snapshotSurveys[0].latestRevision || snapshotSurveys[0]?.survey_stage_changelogs[0].change_type != 'active'" class="badge badge-danger" i18n="@@notActive">Not Active</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6 col-sm-6 col-md-6">
                                  <div class="text-right" *ngIf="snapshotSurveys?.length > 0 && schedule?.id > 0">
                                    <p class="card-title">
                                      <strong i18n="@@frequency">Frequency: </strong>
                                      <span *ngIf="schedule && schedule?.id > 0">{{schedule.frequencyName}}</span>
                                      <span *ngIf="!schedule || schedule?.id == 0">--</span>
                                    </p>
                                    <p class="card-title">
                                      <strong i18n="@@scheduleAt">Schedule At: </strong> 
                                      <span *ngIf="schedule && schedule?.id > 0">{{ scheduleText.scheduling_frequency==2?(scheduleText.scheduled_datetime|date:'EEEE') :(scheduleText.scheduling_frequency==4?(scheduleText.day | dateSuffix):'')}}&nbsp;&nbsp;{{(scheduleText.hrs==0?'00 Hrs UTC':'12 Hrs UTC')}}</span>
                                      <span *ngIf="!schedule || schedule?.id == 0">--</span>
                                    </p>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      <div class="form-design form-box padB0 clearfix">
        <div class="accordion" id="accordionExample3">
          <div class="clearfix">
            <div data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" id="headingThree">
              <i class="utilicon utilicon-chevron-down"></i>
              <label class="group-title inline-block" i18n="@@mappedService">
                Mapped Services</label>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample3">
              <div class="card-body">
                <div class="project-info mapping-for-print">
                  <div class="row">
                    <div class="col-sm-12" *ngIf="project?.displayedServices?.length>0; else noServices">
                      <div class="service-image" *ngFor="let service of project?.displayedServices">
                        <img [src]="service.photo_path ? service.photo_path : null" alt="photo" />
                        <span class="service-name">{{service?.name}}</span>
                      </div>

                    </div>
                    <ng-template #noServices>
                      <p i18n="@@serviceMappingNotExistForProject">Service mapping does not exist for this project.</p>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="form-design form-box padB0 clearfix">
        <div class="accordion" id="accordionExample4">
          <div class="clearfix">
            <div data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" id="headingFour">
              <i class="utilicon utilicon-chevron-down"></i>
              <label class="group-title inline-block" i18n="@@campaignInformation">
                Campaign Information</label>
            </div>
            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample4">
              <div class="card-body">
                <p i18n="@@campaignInformationCommingSoon">Campaign Information Coming Soon</p>
              </div>
            </div>
          </div>

        </div>

      </div>
      <ng-template #projectUndefined>
        <p i18n="@@projectNotExists">This project does not exist</p>
      </ng-template>
    </div>
  </div>

</div>
