
<div class="footer-links">
    <p class="text-center">{{version}}</p>
<a target="_blank" rel="noopener" href="https://www.dsm-firmenich.com/corporate/home.html">dsm-firmenich.com</a>

<a target="_blank" rel="noopener" i18n="@@termAndConditions" i18n-title="@@termAndConditions" href="https://p2froots.firmenich.com/en/assets/media/Firmenich_p2froots_portal_pwa_internet_disclaimer_and_legal_statement.pdf">Terms &amp; Conditions</a>


<a target="_blank" rel="noopener" i18n="@@privacyPolicy" i18n-title="@@privacyPolicy" i18n-title="@@privacyPolicy" href="https://p2froots.firmenich.com/en/assets/media/Firmenich_Privacy_Policy_p2froots.firmenich.com.pdf">Privacy Policy</a>


<a target="_blank" rel="noopener" i18n="@@cookiePolicy" i18n-title="@@cookiePolicy" href="https://p2froots.firmenich.com/en/assets/media/Firmenich_Cookies_Policy_p2froots.firmenich.com.pdf">Cookie Policy</a>


</div>

<div class="clearfix"></div>
<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
    <button (click)="scrollToTop()" class="btn btn-primary btn-icon btn-circle" id="go-to-top-button" aria-label="Go to top">
        
        <img alt="Go to top" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSwxOTcuMjg3TDE1OS4wMjgsNjkuMzgxYy0zLjYxMy0zLjYxNy03Ljg5NS01LjQyNC0xMi44NDctNS40MjRzLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0TDUuNDI0LDE5Ny4yODcgICBDMS44MDcsMjAwLjkwNCwwLDIwNS4xODYsMCwyMTAuMTM0czEuODA3LDkuMjMzLDUuNDI0LDEyLjg0N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyNSwxMi44NSw1LjQyNWgyNTUuODEzICAgYzQuOTQ5LDAsOS4yMzMtMS44MDgsMTIuODQ4LTUuNDI1YzMuNjEzLTMuNjEzLDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N1MyOTAuNTQ4LDIwMC45MDQsMjg2LjkzNSwxOTcuMjg3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iI2ZmZmZmZiI+PC9wYXRoPgo8L2c+PC9nPiA8L3N2Zz4=" />
    </button>
</div>
