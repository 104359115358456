import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../services/project.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FarmerService } from '../../services/farmer.service';
import { TrainingService } from '../../services/training.service';
import Compressor from 'compressorjs';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';
var AddTrainingComponent = /** @class */ (function () {
    function AddTrainingComponent(_route, router, projectService, deviceService, formBuilder, commonService, toastrService, modalService, spinner, trainingService, farmerService, translate, userService) {
        var _this = this;
        this._route = _route;
        this.router = router;
        this.projectService = projectService;
        this.deviceService = deviceService;
        this.formBuilder = formBuilder;
        this.commonService = commonService;
        this.toastrService = toastrService;
        this.modalService = modalService;
        this.spinner = spinner;
        this.trainingService = trainingService;
        this.farmerService = farmerService;
        this.translate = translate;
        this.userService = userService;
        this.isCertificate = false;
        this.isTrainingOpen = false;
        this.isCertificateOpen = false;
        this.isExpiryOpen = false;
        this.dateFormat = 'DD-MMM-YYYY';
        this.uploadedTrainingFiles = [];
        this.trainers = [];
        this.loadingFarmers = false;
        this.selectedFarmersCopy = [];
        this.farmers = [];
        this.selectedFarmers = [];
        this.selectedTrainings = [];
        this.selectedSpouse = [];
        this.selectedChild = [];
        this.confirmModalPopup = true;
        this.page = 1;
        this.itemsPerPage = 10;
        this.farmersListPage = 1;
        this.groupFarmers = [];
        this.disableAddButton = true;
        this.disableCertificateAddButton = true;
        this.totalSelect = [];
        this.maxDate = new Date();
        this.minDate = new Date();
        this.globalFarmerIDs = [];
        this.isAscending = true;
        this.isAscendingCode = true;
        this.isAscendingName = true;
        this.isAscendingAge = true;
        this.isAscendingSex = true;
        this.isAscendingOfficialID = true;
        this.isAscendingFarmer = true;
        this.isAscendingIdFarmer = true;
        this.isAscendingNameFarmer = true;
        this.isAscendingTelFarmer = true;
        this.isAscendingDateFarmer = true;
        this.isAscendingAgeFarmer = true;
        this.isAscendingSexFarmer = true;
        this.isAscendingVillageFarmer = true;
        this.isAscendingVCFarmer = true;
        this.currentUser = this.userService.getCurrentUser();
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
        this.translate.get('select_deselect_object').subscribe(function (result) {
            _this.selectDeselectLabels = result;
        });
    }
    AddTrainingComponent.prototype.ngOnInit = function () {
        this.projectId = +this._route.snapshot.params['id'];
        this.type = this._route.snapshot.params['type'];
        if (this.type == "certification") {
            this.isCertificate = true;
        }
        this.minDate.setDate(this.minDate.getDate() + 1);
        this.getTrainingCertifcationList();
        this.createTrainingForm();
        this.isDesktopDevice = this.deviceService.isDesktop();
    };
    AddTrainingComponent.prototype.getTrainingCertifcationList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, userObj;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([
                                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                this.trainingService.get_all_training_certification(this.projectId).toPromise(),
                                this.trainingService.get_all_trainer(this.projectId).toPromise(),
                                this.trainingService.getAllGroups(this.projectId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['msg'] === 'success') {
                            this.project = request[0]['data'][0];
                        }
                        if (request[1]['msg'] === 'success') {
                            this.trainingCertifications = request[1]['data'];
                        }
                        if (this.currentUser.role.id <= 3) {
                            if (request[2]['msg'] === 'success') {
                                this.trainers = request[2]['data'];
                            }
                        }
                        else {
                            userObj = {
                                users_id: this.currentUser.id,
                                users: {
                                    name: this.currentUser.name
                                }
                            };
                            this.trainers.push(userObj);
                        }
                        if (request[3]['msg'] === 'success') {
                            this.allGroups = request[3]['data'];
                        }
                        this.trainings = this.trainingCertifications.filter(function (item) {
                            return item.training_certification_master.training_certification_type == 'Training' && item.training_certification_master.status == 'Active';
                        });
                        this.certifications = this.trainingCertifications.filter(function (item) {
                            return item.training_certification_master.training_certification_type == 'Certification' && item.training_certification_master.status == 'Active';
                        });
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddTrainingComponent.prototype.createTrainingForm = function () {
        this.groundForm = this.formBuilder.group({
            trainingName: new FormControl('0', [Validators.required]),
            trainerName: new FormControl('0', [Validators.required]),
            description: new FormControl('', [Validators.maxLength(1000)]),
            trainingDate: new FormControl(null, [Validators.required]),
            group: new FormControl('0', [Validators.required]),
            certificateName: new FormControl('0', [Validators.required]),
            certificationDate: new FormControl(null),
            expiryDate: new FormControl(null)
        });
    };
    AddTrainingComponent.prototype.groupSelected = function (e) {
        var _this = this;
        var groupID = this.groundForm['value'].group;
        var exists = this.totalSelect.find(function (item) { return item.id == groupID; });
        if (exists) {
            this.toastrService.warning("Group already selected!");
            return;
        }
        if (groupID != 0) {
            this.spinner.show();
            this.trainingService.getGroupFarmers(groupID).subscribe(function (result) {
                if (result['msg'] === 'success') {
                    var data = result['data'];
                    if (data.length == 0) {
                        _this.spinner.hide();
                        _this.commonService.showToast('warning', 'no_farmer_found_this_group');
                        return;
                    }
                    data = data.filter(function (item) { return !item.is_discontinued; });
                    //set unique farmer data
                    _this.groupFarmers = _this.groupFarmers.concat(data);
                    _this.groupFarmers = _this.groupFarmers.filter(function (v, i, a) { return a.findIndex(function (t) { return (t.farmer_id === v.farmer_id); }) === i; });
                    //create object of groupid and all farmer ids in thar group          
                    var allFarmerIDs = [];
                    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                        var item = data_1[_i];
                        allFarmerIDs.push(item.farmer_id);
                    }
                    var obj = {
                        id: groupID,
                        farmerIDs: allFarmerIDs
                    };
                    _this.globalFarmerIDs.push(obj);
                    //add in list of selected groups
                    var groupDetails = _this.allGroups.find(function (item) { return item.project_group_mapping_id == groupID; });
                    _this.totalSelect.push({ name: groupDetails.training_certification_group.name, id: groupID });
                    _this.commonService.showToast('success', 'unique_farmers_added_successfully', {});
                    _this.groundForm.controls['group'].setValue(0);
                    if (_this.isCertificate) {
                        _this.showCertificateAddButton();
                    }
                    else {
                        _this.showAddButton();
                    }
                    _this.spinner.hide();
                }
            });
        }
    };
    AddTrainingComponent.prototype.removeNewGroup = function (id) {
        var curGroupIDs = this.globalFarmerIDs.find(function (item) { return item.id == id; }).farmerIDs;
        console.log('curGroupIDs', curGroupIDs);
        console.log('globalFarmerIDs', this.globalFarmerIDs);
        var _loop_1 = function (item) {
            for (var _i = 0, _a = this_1.globalFarmerIDs; _i < _a.length; _i++) {
                var group = _a[_i];
                if (group.id != id) {
                    if (group.farmerIDs.indexOf(item) > -1) {
                        curGroupIDs = curGroupIDs.filter(function (item2) { return item2 !== item; });
                    }
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, curGroupIDs_1 = curGroupIDs; _i < curGroupIDs_1.length; _i++) {
            var item = curGroupIDs_1[_i];
            _loop_1(item);
        }
        var _loop_2 = function (id_1) {
            this_2.groupFarmers = this_2.groupFarmers.filter(function (item) { return item.farmer_id != id_1; });
            this_2.selectedTrainings = this_2.selectedTrainings.filter(function (item) { return item != id_1; });
        };
        var this_2 = this;
        for (var _a = 0, curGroupIDs_2 = curGroupIDs; _a < curGroupIDs_2.length; _a++) {
            var id_1 = curGroupIDs_2[_a];
            _loop_2(id_1);
        }
        this.totalSelect = this.totalSelect.filter(function (item) { return item.id != id; });
        this.globalFarmerIDs = this.globalFarmerIDs.filter(function (item) { return item.id != id; });
        this.commonService.showToast('success', 'group_farmers_removed_successfully');
    };
    AddTrainingComponent.prototype.saveUpdateTraining = function () {
        var _this = this;
        this.spinner.show();
        var trainingName = this.groundForm['value'].trainingName;
        var trainerName = this.groundForm['value'].trainerName;
        var date = new Date(this.groundForm['value'].trainingDate);
        var trainingDate = date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2);
        var description = this.groundForm['value'].description;
        var farmerData = [];
        for (var _i = 0, _a = this.groupFarmers; _i < _a.length; _i++) {
            var item = _a[_i];
            var obj = {
                "farmer_id": item.farmer_id,
                "project_group_actor_mapping_id": item.project_group_actor_mapping_id,
                "training_attended": this.selectedTrainings.indexOf(item.farmer_id) > -1 ? 1 : 0,
                "spouse_attended": this.selectedSpouse.indexOf(item.farmer_id) > -1 ? 1 : 0,
                "children_attended_above_16": this.selectedChild.indexOf(item.farmer_id) > -1 ? 1 : 0
            };
            farmerData.push(obj);
        }
        var documentData = this.uploadedTrainingFiles;
        var trainingToAdd = {
            "project_training_certification_mapping_id": trainingName,
            "trainer_id": trainerName,
            "training_date": trainingDate,
            "description": description,
            "status": "Active",
            "training_recording_dtl": farmerData,
            "training_documents_dtl": documentData
        };
        this.trainingService.saveTrainingDetails(trainingToAdd).subscribe(function (result) {
            _this.commonService.showToast('success', 'training_created_successfully', {});
            var url = "training-management/" + _this.projectId;
            _this.router.navigate([url]);
            _this.spinner.hide();
        }, function (error) {
            _this.spinner.hide();
        });
    };
    AddTrainingComponent.prototype.saveUpdateCertificate = function () {
        var _this = this;
        this.spinner.show();
        var certificateName = this.groundForm['value'].certificateName;
        var date = new Date(this.groundForm['value'].certificationDate);
        var certificationDate = date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2);
        var expiryDate = this.groundForm['value'].expiryDate;
        var description = this.groundForm['value'].description;
        var farmerData = [];
        for (var _i = 0, _a = this.groupFarmers; _i < _a.length; _i++) {
            var item = _a[_i];
            var obj = {
                "farmer_id": item.farmer_id,
                "project_group_actor_mapping_id": item.project_group_actor_mapping_id,
                "certified": this.selectedTrainings.indexOf(item.farmer_id) > -1 ? 1 : 0
            };
            farmerData.push(obj);
        }
        var certificationToAdd = {
            "project_training_certification_mapping_id": certificateName,
            "certification_date": certificationDate,
            "expiry_date": expiryDate,
            "description": description,
            "certification_recording_dtl": farmerData
        };
        this.trainingService.saveCertificationDetails(certificationToAdd).subscribe(function (result) {
            _this.commonService.showToast('success', 'certification_created_successfully', {});
            var url = "training-management/" + _this.projectId;
            _this.router.navigate([url]);
            _this.spinner.hide();
        }, function (error) {
            _this.spinner.hide();
        });
    };
    AddTrainingComponent.prototype.manageFarmers = function (project, template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, _i, _a, item, _b, _c, id;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.searchTextFarmers = "";
                        this.confirmFarmerModal = true;
                        if (!!this.loadingFarmers) return [3 /*break*/, 2];
                        this.spinner.show();
                        this.loadingFarmers = true;
                        this.managingFarmersForGroup = project;
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getFarmersByProjectBasicData(project.id).toPromise(),
                            ])];
                    case 1:
                        requests = _d.sent();
                        this.loadingFarmers = false;
                        this.spinner.hide();
                        if (requests[0]['msg'] === 'success') {
                            this.farmers = requests[0].data.filter(function (item) { return !item.is_discontinued; });
                            for (_i = 0, _a = this.farmers; _i < _a.length; _i++) {
                                item = _a[_i];
                                item.village_name = item.region_village.village_name;
                            }
                            this.sortFarmers();
                        }
                        for (_b = 0, _c = this.groupFarmers; _b < _c.length; _b++) {
                            id = _c[_b];
                            this.selectedFarmersCopy.push(id.farmer_id);
                        }
                        if (this.farmers.length === 0) {
                            this.commonService.showToast('info', 'no_farmers_regd', {});
                        }
                        else {
                            this.farmersListPage = 1;
                            this.tooltipText = undefined;
                            this.manageFarmersModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
                        }
                        _d.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    AddTrainingComponent.prototype.confirmManageFarmers = function () {
        var _loop_3 = function (item) {
            var checkFarmerExist = this_3.groupFarmers.find(function (item2) { return item2.farmer_id == item; });
            if (!checkFarmerExist) {
                var farmer = this_3.farmers.find(function (item2) { return item2.id == item; });
                var obj = {
                    "age": farmer.age,
                    "farmer_code": farmer.farmer_code,
                    "farmer_id": farmer.id,
                    "name": farmer.name,
                    "official_id": farmer.official_id,
                    "project_group_actor_mapping_id": null,
                    "sex": farmer.sex
                };
                this_3.groupFarmers.push(obj);
            }
        };
        var this_3 = this;
        for (var _i = 0, _a = this.selectedFarmers; _i < _a.length; _i++) {
            var item = _a[_i];
            _loop_3(item);
        }
        this.commonService.showToast('success', 'unique_farmers_added_successfully', {});
        this.declineManageFarmers();
    };
    AddTrainingComponent.prototype.cancelManageFarmers = function () {
        var isEqual = false;
        for (var _i = 0, _a = this.selectedFarmers; _i < _a.length; _i++) {
            var val = _a[_i];
            if (this.selectedFarmersCopy.indexOf(val) < 0) {
                isEqual = true;
            }
        }
        if (isEqual) {
            this.confirmFarmerModal = false;
        }
        else {
            this.declineManageFarmers();
        }
    };
    AddTrainingComponent.prototype.declineManageFarmers = function () {
        this.selectedFarmers = [];
        this.farmers = [];
        this.managingFarmersForGroup = undefined;
        if (this.manageFarmersModal) {
            this.manageFarmersModal.hide();
        }
    };
    AddTrainingComponent.prototype.confirmCloseFarmerNo = function () {
        this.confirmFarmerModal = true;
    };
    // Toggle a particular farmer's selection status
    AddTrainingComponent.prototype.toggleThisFarmerSelect = function (id) {
        if (this.selectedFarmers.indexOf(id) > -1) {
            this.selectedFarmers = this.selectedFarmers.filter(function (item) { return item !== id; });
        }
        else {
            this.selectedFarmers.push(id);
        }
        this.setTooltipText();
    };
    AddTrainingComponent.prototype.toggleThisTrainingSelect = function (id) {
        if (this.selectedTrainings.indexOf(id) > -1) {
            this.selectedTrainings = this.selectedTrainings.filter(function (item) { return item !== id; });
        }
        else {
            this.selectedTrainings.push(id);
        }
    };
    AddTrainingComponent.prototype.toggleThisSpouseSelect = function (id) {
        if (this.selectedSpouse.indexOf(id) > -1) {
            this.selectedSpouse = this.selectedSpouse.filter(function (item) { return item !== id; });
        }
        else {
            this.selectedSpouse.push(id);
            this.selectedTrainings.push(id);
        }
    };
    AddTrainingComponent.prototype.toggleThisChildSelect = function (id) {
        if (this.selectedChild.indexOf(id) > -1) {
            this.selectedChild = this.selectedChild.filter(function (item) { return item !== id; });
        }
        else {
            this.selectedChild.push(id);
            this.selectedTrainings.push(id);
        }
    };
    // Select / deselect all farmers in the array
    AddTrainingComponent.prototype.toggleAllFarmers = function () {
        // If all farmers are already selected, then deselect all farmers
        if (this.selectedFarmers.length === this.farmers.length) {
            this.selectedFarmers = [];
        }
        // If all farmers are NOT already selected, select all the farmers
        else {
            this.selectedFarmers = [];
            for (var _i = 0, _a = this.farmers; _i < _a.length; _i++) {
                var val = _a[_i];
                this.selectedFarmers.push(val.id);
            }
        }
        this.setTooltipText();
    };
    AddTrainingComponent.prototype.toggleAllFarmersTrainings = function () {
        // If all farmers are already selected, then deselect all farmers
        if (this.selectedTrainings.length === this.groupFarmers.length) {
            this.selectedTrainings = [];
        }
        // If all farmers are NOT already selected, select all the farmers
        else {
            this.selectedTrainings = [];
            for (var _i = 0, _a = this.groupFarmers; _i < _a.length; _i++) {
                var val = _a[_i];
                this.selectedTrainings.push(val.farmer_id);
            }
        }
    };
    AddTrainingComponent.prototype.toggleAllSpouses = function () {
        // If all farmers are already selected, then deselect all farmers
        if (this.selectedSpouse.length === this.groupFarmers.length) {
            this.selectedSpouse = [];
        }
        // If all farmers are NOT already selected, select all the farmers
        else {
            this.selectedSpouse = [];
            for (var _i = 0, _a = this.groupFarmers; _i < _a.length; _i++) {
                var val = _a[_i];
                this.selectedSpouse.push(val.farmer_id);
                this.selectedTrainings.push(val.farmer_id);
            }
        }
    };
    AddTrainingComponent.prototype.toggleAllChildren = function () {
        // If all farmers are already selected, then deselect all farmers
        if (this.selectedChild.length === this.groupFarmers.length) {
            this.selectedChild = [];
        }
        // If all farmers are NOT already selected, select all the farmers
        else {
            this.selectedChild = [];
            for (var _i = 0, _a = this.groupFarmers; _i < _a.length; _i++) {
                var val = _a[_i];
                this.selectedChild.push(val.farmer_id);
                this.selectedTrainings.push(val.farmer_id);
            }
        }
    };
    AddTrainingComponent.prototype.setTooltipText = function () {
        if (this.farmers && this.selectedFarmers) {
            if (this.selectedFarmers.length === this.farmers.length) {
                // Deselect farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.deselect + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.deselect + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
            else {
                // Select farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.select + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.select + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
        }
    };
    AddTrainingComponent.prototype.sortFarmers = function () {
        this.farmers.sort(function (a, b) {
            if (a.cr_date < b.cr_date) {
                return 1;
            }
            if (a.cr_date > b.cr_date) {
                return -1;
            }
            return 0;
        });
    };
    AddTrainingComponent.prototype.getGridFiltersFarmers = function () {
        var _this = this;
        var gridColums = ['farmer_code', 'name', 'tel_number', 'official_id', 'age', 'village_name', 'village_collectors', 'cr_date'];
        var finalObj = {};
        if (this.farmers === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchTextFarmers;
            });
        }
        return finalObj;
    };
    /* file upload */
    AddTrainingComponent.prototype.handleTrainingFileInput = function (file) {
        var _this = this;
        // // debugger;
        var fileItem = file.item(0);
        var msg = this.commonService.ValidateFile(fileItem);
        if (msg && msg.length > 0) {
            // this.toastrService.warning(msg);
            this.commonService.showToast('warning', msg, {});
            return;
        }
        new Compressor(fileItem, {
            quality: environment.imageCompress.quality,
            maxWidth: environment.imageCompress.maxWidth,
            maxHeight: environment.imageCompress.maxHeight,
            mimeType: environment.imageCompress.mimeType,
            convertSize: environment.imageCompress.convertSize,
            success: function (result) {
                setFarmerImageData(result);
            },
            error: function (err) {
                console.log(err.message);
            },
        });
        var setFarmerImageData = function (result) {
            _this.commonService.blobToBase64(result).then(function (imageData) {
                var obj = {
                    "image_file": imageData,
                    "image_for": fileItem.name
                };
                _this.uploadedTrainingFiles.push(obj);
            });
        };
    };
    AddTrainingComponent.prototype.showAddButton = function () {
        if (this.groundForm['value'].trainingName != 0 && this.groundForm['value'].trainerName != 0 && this.groundForm['value'].trainingDate != null && this.totalSelect.length > 0) {
            this.disableAddButton = false;
        }
        else {
            this.disableAddButton = true;
        }
    };
    AddTrainingComponent.prototype.showCertificateAddButton = function () {
        if (this.groundForm['value'].certificateName != 0 && this.groundForm['value'].certificationDate != null && this.totalSelect.length > 0) {
            this.disableCertificateAddButton = false;
        }
        else {
            this.disableCertificateAddButton = true;
        }
    };
    AddTrainingComponent.prototype.removeTrainingFile = function (fileName, template) {
        var file = {
            isOld: false,
            file: fileName
        };
        this.fileToDelete = file;
        this.fileDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    AddTrainingComponent.prototype.confirmFileDelete = function (decision) {
        var _this = this;
        if (decision == "Yes") {
            var x = this.uploadedTrainingFiles.find(function (item) { return item.image_for == _this.fileToDelete.file; });
            var index = this.uploadedTrainingFiles.indexOf(x);
            if (index !== -1) {
                this.uploadedTrainingFiles.splice(index, 1);
                this.fileDeleteReset();
            }
        }
        else {
            this.fileDeleteReset();
        }
    };
    AddTrainingComponent.prototype.fileDeleteReset = function () {
        this.fileDeletePopup.hide();
        this.fileToDelete = null;
    };
    AddTrainingComponent.prototype.exitClicked = function (myForm, template) {
        if (myForm.dirty) {
            this.confirmModalPopup = false;
            this.confirmPopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            var url = 'training-management/' + this.projectId;
            this.router.navigate([url]);
        }
    };
    AddTrainingComponent.prototype.confirmExitModal = function (item) {
        if (item === 'Yes') {
            this.confirmModalPopup = true;
            if (this.confirmPopup) {
                this.confirmPopup.hide();
            }
            var url = 'training-management/' + this.projectId;
            this.router.navigate([url]);
        }
        else if (item === 'No') {
            this.confirmModalPopup = true;
            if (this.confirmPopup) {
                this.confirmPopup.hide();
            }
        }
    };
    AddTrainingComponent.prototype.getGridFilters = function () {
        var _this = this;
        //new fields added for search village_name and vc_name
        var gridColums = ['farmer_code', 'name', 'age', 'sex', 'official_id'];
        var finalObj = {};
        if (this.farmers === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    AddTrainingComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    AddTrainingComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        this.isAscendingCode = sortBy !== 'farmer_code';
        this.isAscendingName = sortBy !== 'name';
        this.isAscendingAge = sortBy !== 'age';
        this.isAscendingSex = sortBy !== 'sex';
        this.isAscendingOfficialID = sortBy !== 'official_id';
        // Sort of new fields added
        if (sortBy === 'farmer_code' || sortBy === 'official_id' || sortBy === 'name' || sortBy === 'age' || sortBy === 'sex') {
            this.groupFarmers.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
    };
    AddTrainingComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        this.isAscendingCode = sortBy === 'farmer_code';
        this.isAscendingName = sortBy === 'name';
        this.isAscendingAge = sortBy === 'age';
        this.isAscendingSex = sortBy === 'sex';
        this.isAscendingOfficialID = sortBy === 'official_id';
        // Sort of new fields added
        if (sortBy === 'farmer_code' || sortBy === 'official_id' || sortBy === 'name' || sortBy === 'age' || sortBy === 'sex') {
            this.groupFarmers.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
    };
    AddTrainingComponent.prototype.farmerSortBy = function (sortOn) {
        this.isAscendingFarmer ? this.ascendicFarmer(sortOn) : this.descendicFarmer(sortOn);
    };
    AddTrainingComponent.prototype.ascendicFarmer = function (sortBy) {
        this.isAscendingFarmer = false;
        this.isAscendingIdFarmer = sortBy !== 'id';
        this.isAscendingNameFarmer = sortBy !== 'name';
        this.isAscendingTelFarmer = sortBy !== 'tel_number';
        this.isAscendingDateFarmer = sortBy !== 'cr_date';
        this.isAscendingAgeFarmer = sortBy !== 'age';
        this.isAscendingSexFarmer = sortBy !== 'sex';
        // Sort of new fields added
        this.isAscendingVillageFarmer = sortBy !== 'village_name';
        this.isAscendingVCFarmer = sortBy !== 'village_collectors';
        this.isAscendingOfficialID = sortBy !== 'official_id';
        // Sort of new fields added
        if (sortBy === 'name') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                    return 1;
                }
                if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'village_name') {
            this.farmers.sort(function (a, b) {
                if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
                    return 1;
                }
                if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    AddTrainingComponent.prototype.descendicFarmer = function (sortBy) {
        this.isAscendingFarmer = true;
        this.isAscendingIdFarmer = sortBy === 'id';
        this.isAscendingNameFarmer = sortBy === 'name';
        this.isAscendingTelFarmer = sortBy === 'tel_number';
        this.isAscendingDateFarmer = sortBy === 'cr_date';
        this.isAscendingAgeFarmer = sortBy === 'age';
        this.isAscendingSexFarmer = sortBy === 'sex';
        // Sort of new fields added
        this.isAscendingVillageFarmer = sortBy === 'village_name';
        this.isAscendingVCFarmer = sortBy === 'village_collectors';
        this.isAscendingOfficialID = sortBy === 'official_id';
        // Sort of new fields added
        if (sortBy === 'name') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                    return 1;
                }
                if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'village_name') {
            this.farmers.sort(function (a, b) {
                if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
                    return 1;
                }
                if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    AddTrainingComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    AddTrainingComponent.prototype.trackBytraining = function (index, dataItem) {
        return dataItem.id;
    };
    AddTrainingComponent.prototype.trackBytrainer = function (index, dataItem) {
        return dataItem.id;
    };
    return AddTrainingComponent;
}());
export { AddTrainingComponent };
