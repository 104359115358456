<app-header></app-header>
<div class="project-header clearfix">

    <nav aria-label="breadcrumb" class="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">All Projects</a></li>
            <li class="breadcrumb-item"><a href="#">Jasmine</a></li>
            <li class="breadcrumb-item active" aria-current="page">Kshiteej Jain</li>
        </ol>
    </nav>
</div>
<div class="clearfix"></div>
<div class="content-wrap content-wrapper">
    <div enctype="multipart/form-data" name="projectForm" novalidate="" class="form-design">
        <div class="form-box">

            <div class="farmer-profile-div clearfix">

                <div class="farmer-tab-content" id="pills-tabContent2">
                    <div class="tab-pane fade in active" id="pills-fp" role="tabpanel" aria-labelledby="pills-fp-tab">
                        <label class="group-title">Farmer Profile</label>
                        <div class="clearfix"></div>
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item visited">
                                <a class="nav-link" id="pills-personal-tab" data-toggle="pill" href="#pills-personal"
                                    role="tab" aria-controls="pills-personal" aria-selected="true"> <span class="progress-circle">
                                        <span class="utilicon"></span>
                                    </span> <br> Personal Data</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active" id="pills-agriculture-tab" data-toggle="pill" href="#pills-agriculture"
                                    role="tab" aria-controls="pills-agriculture" aria-selected="false"> <span class="progress-circle">
                                        <span class="utilicon"></span>
                                    </span> <br> Agriculture</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="pills-production-tab" data-toggle="pill" href="#pills-production"
                                    role="tab" aria-controls="pills-production" aria-selected="false"><span class="progress-circle">
                                        <span class="utilicon"></span>
                                    </span> <br> Production</a>
                            </li>
                        </ul>
                        <div class="farmer-tab-content" id="pills-tabContent">
                            <div class="tab-pane fade" id="pills-personal" role="tabpanel" aria-labelledby="pills-personal-tab">
                                <form>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="farmer-iamge">
                                                <img src="assets/images/farmer.webp" alt="farmer">
                                            </div>

                                            <div class="farmer-qrcode">
                                                <img src="assets/images/qr-code.svg" alt="qr code">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Name <span class="mandatory">*</span></label>
                                                <input type="text" name="Project_Name" i18n-placeholder="@@enterValue"
                                                    class="form-control" maxlength="50" required>
                                                <div class="error"><span i18n="@@enterValidInput">Enter valid input</span></div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result" i18n="@@photo">Photo(equal ratio are best fit, max size 10mb)</label>
                                                <div class="input-group file-upload">
                                                    <input type="text" class="form-control" readonly="">
                                                    <label class="input-group-btn">
                                                        <span class="btn btn-primary btn-file">
                                                            Browse… <input type="file" style="display: none;" multiple="">
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Village / Area <span class="mandatory">*</span></label>
                                                <input type="text" name="Project_Name" i18n-placeholder="@@enterValue"
                                                    class="form-control" maxlength="50" required>
                                                <div class="error"><span i18n="@@enterValidInput">Enter valid input</span></div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Official ID Number</label>
                                                <select id="select-normal" class="form-control select2"
                                                    data-placeholder="Select a topic">
                                                    <option value="1">Product 1</option>
                                                    <option value="2">Product 2</option>
                                                    <option value="3">Product 3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result" i18n="@@age">Age <span class="mandatory">*</span></label>
                                                <input type="text" name="age" i18n-placeholder="@@enterValue" class="form-control"
                                                    maxlength="50" required>
                                                <div class="error"><span i18n="@@enterValidInput">Enter valid input</span></div>

                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Gender</label>
                                                <select id="select-normal" class="form-control select2"
                                                    data-placeholder="Select a topic">
                                                    <option value="1" selected>Male</option>
                                                    <option value="2">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Phone</label>
                                                <input type="text" name="phone" i18n-placeholder="@@enterValue" class="form-control"
                                                    maxlength="50" required>

                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Mother Tongue</label>
                                                <input type="text" name="mother-tongue" i18n-placeholder="@@enterValue"
                                                    class="form-control" maxlength="50" required>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Languages Known</label>
                                                <input type="text" name="mother-tongue" i18n-placeholder="@@enterValue"
                                                    class="form-control" maxlength="50" required>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Level of Education</label>
                                                <select id="select-normal" class="form-control select2"
                                                    data-placeholder="Select a topic">
                                                    <option value="1" selected>BSC</option>
                                                    <option value="2">BA</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Remarks & Social Status</label>
                                                <textarea class="form-control has-counter" rows="4" maxlength="300"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="alert alert-danger">There are errors</div>
                                        </div>
                                    </div>
                                    <hr class="breakline">
                                    <div _ngcontent-c7="" class="fixed-actions form-box actions">
                                        <button _ngcontent-c7="" class="btn btn-secondary" i18n="@@back" i18n-title="@@back" type="submit"> Back</button>
                                        <button _ngcontent-c7="" class="btn btn-primary" i18n="@@submit" i18n-title="@@submit" type="submit"> Next </button>
                                    </div>
                                </form>
                            </div>
                            <div class="tab-pane fade in active" id="pills-agriculture" role="tabpanel" aria-labelledby="pills-agriculture-tab">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Type of Agriculture Production <span class="mandatory">*</span></label>
                                                <select id="select-normal" class="form-control select2"
                                                    data-placeholder="Select a topic">
                                                    <option value="1">Faring/Cultivation</option>
                                                    <option value="2">Wild Collection</option>
                                                    <option value="3">Both</option>
                                                </select>
                                                <div class="error"><span i18n="@@enterValidInput">Enter valid input</span></div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">How long have you been involved in
                                                    farming/collecting of the surveyed crop?</label>
                                                <div class="input-group">
                                                    <select class="form-control col50" data-placeholder="Select Year">
                                                        <option value="1">Select Year</option>
                                                        <option value="2">1</option>
                                                        <option value="3">2</option>
                                                    </select>
                                                    <select class="form-control col50" data-placeholder="Select Month">
                                                        <option value="1">Select Month</option>
                                                        <option value="2">Jan</option>
                                                        <option value="3">Feb</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Total area planted with the surveyed crop?
                                                    <span class="mandatory">*</span></label>
                                                <div class="input-group">
                                                    <input type="number" name="" i18n-placeholder="@@enterValue" class="form-control col50"
                                                        value="1" required>
                                                    <select class="form-control col50 fltRgt" data-placeholder="Select Unit">
                                                        <option value="1">Hectare</option>
                                                        <option value="2">Jan</option>
                                                        <option value="3">Feb</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">How many fields do you have of the surveyed
                                                    crop?<span class="mandatory">*</span></label>
                                                <div class="input-group">
                                                    <input type="number" name="" i18n-placeholder="@@enterValue" class="form-control"
                                                        value="1" required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-sub-section">
                                                <div class="form-group">
                                                    <label for="text-result">Number of trees, vines or seedlings per
                                                        field<span class="mandatory">*</span></label>
                                                    <div class="input-group">
                                                        <input type="name" name="" i18n-placeholder="@@enterValue"
                                                            class="form-control col50" required>
                                                        <input type="number" name="" i18n-placeholder="@@enterValue"
                                                            class="form-control col50" value="1" required>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                    <hr class="breakline">
        <div _ngcontent-c7="" class="fixed-actions form-box actions">
            <button _ngcontent-c7="" class="btn btn-secondary" type="submit"> Back</button>
            <button _ngcontent-c7="" class="btn btn-primary" type="submit"> Next </button>
        </div>
                                </form>
                            </div>
                            <div class="tab-pane fade" id="pills-production" role="tabpanel" aria-labelledby="pills-production-tab">
                                <h3>Production</h3>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="pills-livelihood" role="tabpanel" aria-labelledby="pills-livelihood-tab">
                        <h3>2</h3>
                    </div>
                    <div class="tab-pane fade" id="pills-profitability" role="tabpanel" aria-labelledby="pills-profitability-tab">
                        <h3>3</h3>
                    </div>
                    <div class="tab-pane fade" id="pills-husbandry" role="tabpanel" aria-labelledby="pills-husbandry-tab">
                        <label class="group-title">Husbandry</label>
                        <div class="clearfix"></div>
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item visited">
                                <a class="nav-link" id="pills-poultry-tab" data-toggle="pill" href="#pills-poultry"
                                    role="tab" aria-controls="pills-poultry" aria-selected="true"> <span class="progress-circle">
                                        <span class="utilicon"></span>
                                    </span> <br> Breeding - Poultry</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active" id="pills-biggerAnimal-tab" data-toggle="pill" href="#pills-biggerAnimal"
                                    role="tab" aria-controls="pills-biggerAnimal" aria-selected="false"> <span class="progress-circle">
                                        <span class="utilicon"></span>
                                    </span> <br> Breeding - Bigger Animals</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="pills-aquaculture-tab" data-toggle="pill" href="#pills-aquaculture"
                                    role="tab" aria-controls="pills-aquaculture" aria-selected="false"><span class="progress-circle">
                                        <span class="utilicon"></span>
                                    </span> <br> Breeding - Aquaculture</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="pills-livestock-tab" data-toggle="pill" href="#pills-livestock"
                                    role="tab" aria-controls="pills-livestock" aria-selected="false"><span class="progress-circle">
                                        <span class="utilicon"></span>
                                    </span> <br> Other Livestock Revenues</a>
                            </li>
                        </ul>
                        <div class="farmer-tab-content" id="pills-tabContent">
                            <div class="tab-pane fade" id="pills-poultry" role="tabpanel" aria-labelledby="pills-poultry-tab">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Do you have any poultry? <span class="mandatory">*</span></label>
                                                <select id="select-normal" class="form-control select2"
                                                    data-placeholder="Select">
                                                    <option value="1">Yes</option>
                                                    <option value="2">Yes</option>
                                                </select>
                                                <div class="error"><span i18n="@@enterValidInput">Enter valid input</span></div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">What kind of poultry? <span class="mandatory">*</span></label>
                                                <div class="input-group">
                                                    <input type="text" name="Project_Name" i18n-placeholder="@@enterValue"
                                                        class="form-control" maxlength="50" required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Do you have any poultry? <span class="mandatory">*</span></label>
                                                <select id="select-normal" class="form-control select2"
                                                    data-placeholder="Select">
                                                    <option value="1">Yes</option>
                                                    <option value="2">Yes</option>
                                                </select>
                                                <div class="error"><span i18n="@@enterValidInput">Enter valid input</span></div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">What kind of poultry?</label>
                                                <div class="input-group">
                                                    <input type="text" name="Project_Name" i18n-placeholder="@@enterValue"
                                                        class="form-control" maxlength="50" required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-sub-section">
                                                <h3>Chicken</h3>
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="form-group has-error">
                                                            <label for="text-result">How many chickens per year?<span
                                                                    class="mandatory">*</span></label>
                                                            <div class="input-group">
                                                                <input type="name" name="" i18n-placeholder="@@enterValue"
                                                                    class="form-control" required>
                                                            </div>
                                                            <div class="error"><span _ngcontent-c5="">Enter valid input</span></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label for="text-result">Percentage self consumed in the
                                                                family<span class="mandatory">*</span></label>
                                                            <div class="input-group">
                                                                <input type="name" name="" i18n-placeholder="@@enterValue"
                                                                    class="form-control" required>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label for="text-result">Percentage self consumed in the
                                                                family<span class="mandatory">*</span></label>
                                                            <div class="input-group">
                                                                <input type="name" name="" i18n-placeholder="@@enterValue"
                                                                    class="form-control" required>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label for="text-result">Percentage self consumed in the
                                                                family<span class="mandatory">*</span></label>
                                                            <div class="input-group">
                                                                <input type="name" name="" i18n-placeholder="@@enterValue"
                                                                    class="form-control" required>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label for="text-result">Percentage self consumed in the
                                                                family<span class="mandatory">*</span></label>
                                                            <div class="input-group">
                                                                <input type="name" name="" i18n-placeholder="@@enterValue"
                                                                    class="form-control" required>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-sub-section">
                                                <h3>Turkey</h3>
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label for="text-result">How many chickens per year?<span
                                                                    class="mandatory">*</span></label>
                                                            <div class="input-group">
                                                                <input type="name" name="" i18n-placeholder="@@enterValue"
                                                                    class="form-control" required>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label for="text-result">Percentage self consumed in the
                                                                family<span class="mandatory">*</span></label>
                                                            <div class="input-group">
                                                                <input type="name" name="" i18n-placeholder="@@enterValue"
                                                                    class="form-control" required>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="breakline">
        <div _ngcontent-c7="" class="fixed-actions form-box actions">
            <button _ngcontent-c7="" class="btn btn-secondary" type="submit"> Back</button>
            <button _ngcontent-c7="" class="btn btn-primary" type="submit"> Next </button>
        </div>
                                </form>
                            </div>
                            <div class="tab-pane fade in active" id="pills-biggerAnimal" role="tabpanel"
                                aria-labelledby="pills-biggerAnimal-tab">
                                <form>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Type of Agriculture Production <span class="mandatory">*</span></label>
                                                <select id="select-normal" class="form-control select2"
                                                    data-placeholder="Select a topic">
                                                    <option value="1">Faring/Cultivation</option>
                                                    <option value="2">Wild Collection</option>
                                                    <option value="3">Both</option>
                                                </select>
                                                <div class="error"><span i18n="@@enterValidInput">Enter valid input</span></div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">How long have you been involved in
                                                    farming/collecting of the surveyed crop?</label>
                                                <div class="input-group">
                                                    <select class="form-control col50" data-placeholder="Select Year">
                                                        <option value="1">Select Year</option>
                                                        <option value="2">1</option>
                                                        <option value="3">2</option>
                                                    </select>
                                                    <select class="form-control col50" data-placeholder="Select Month">
                                                        <option value="1">Select Month</option>
                                                        <option value="2">Jan</option>
                                                        <option value="3">Feb</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">Total area planted with the surveyed crop?
                                                    <span class="mandatory">*</span></label>
                                                <div class="input-group">
                                                    <input type="number" name="" i18n-placeholder="@@enterValue" class="form-control col50"
                                                        value="1" required>
                                                    <select class="form-control col50 fltRgt" data-placeholder="Select Unit">
                                                        <option value="1">Hectare</option>
                                                        <option value="2">Jan</option>
                                                        <option value="3">Feb</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label for="text-result">How many fields do you have of the surveyed
                                                    crop?<span class="mandatory">*</span></label>
                                                <div class="input-group">
                                                    <input type="number" name="" i18n-placeholder="@@enterValue" class="form-control"
                                                        value="1" required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-sub-section">
                                                <div class="form-group">
                                                    <label for="text-result">Number of trees, vines or seedlings per
                                                        field<span class="mandatory">*</span></label>
                                                    <div class="input-group">
                                                        <input type="name" name="" i18n-placeholder="@@enterValue"
                                                            class="form-control col50" required>
                                                        <input type="number" name="" i18n-placeholder="@@enterValue"
                                                            class="form-control col50" value="1" required>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                    <hr class="breakline">
        <div _ngcontent-c7="" class="fixed-actions form-box actions">
            <button _ngcontent-c7="" class="btn btn-secondary" i18n="@@back" i18n-title="@@back" type="submit"> Back</button>
            <button _ngcontent-c7="" class="btn btn-primary" i18n="@@submit" i18n-title="@@submit" type="submit"> Next </button>
        </div>
                                </form>
                            </div>
                            <div class="tab-pane fade" id="pills-aquaculture" role="tabpanel" aria-labelledby="pills-aquaculture-tab">
                                <h3>Aquaculture</h3>
                            </div>
                            <div class="tab-pane fade" id="pills-livestock" role="tabpanel" aria-labelledby="pills-livestock-tab">
                                <h3>Other Livestock Revenues</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="nav nav-pills fixed-tabs" id="pills-tab2" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="pills-fp-tab" data-toggle="pill" href="#pills-fp" role="tab"
                            aria-controls="pills-fp" aria-selected="true">
                            Farmer Profile <span class="utilicon utilicon-check"></span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-livelihood-tab" data-toggle="pill" href="#pills-livelihood" role="tab"
                            aria-controls="pills-livelihood" aria-selected="false">
                            Livelihoods <span class="utilicon utilicon-check"></span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-profitability-tab" data-toggle="pill" href="#pills-profitability"
                            role="tab" aria-controls="pills-profitability" aria-selected="false">
                            Farm Profitability <span class="utilicon utilicon-check"></span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-husbandry-tab" data-toggle="pill" href="#pills-husbandry" role="tab"
                            aria-controls="pills-husbandry" aria-selected="false">
                            Husbandry <span class="utilicon utilicon-check"></span> </a>
                    </li>
                </ul>


            </div>
            <div class="clearfix"></div>

        </div>


    </div>
</div>
