
import { Observable, forkJoin, of } from 'rxjs';


import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';

import { ProjectService } from '../services/project.service';
import { FarmerService } from '../services/farmer.service';
import { CommonService } from '../services/common.service';
import { RevisionService } from '../services/revision.service';
import { StorageService } from '../services/storage.service';

interface IReturn {
  project: any[];
  farmers: any[];
  productType: any[];
  managers: any[];
}

@Injectable()
export class ProjectResolver implements Resolve<IReturn> {

  userValue;

  constructor(private readonly projectService: ProjectService, private readonly farmerService: FarmerService,
    private readonly commonService: CommonService, private readonly revisionService: RevisionService, private readonly storageService: StorageService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IReturn> {
    const projectId = route.params['id'];
    let services: Observable<any>[];
    if (navigator.onLine) {
     services = [this.projectService.GetProject(projectId),
     this.farmerService.GetFarmersByProjectIdAllData(projectId)];
    } else {
      services = [of([]), of([])];
    }

    if (this.checkRole() < 4) {
      return forkJoin([
        ...services,
        this.commonService.getProductType().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getProjectManager().pipe(map((results) => results), catchError(e => of([]))),
        this.farmerService.GetFarmerMaster().pipe(map((results) => results), catchError(e => of([])))
      ]).pipe(
        map(results => ({
          project: results[0],
          farmers: results[1],
          productType: results[2],
          managers: results[3],
          master: results[4]
        }))
      );
    } else {
      return forkJoin([
        ...services,
        this.farmerService.GetFarmerMaster().pipe(map((results) => results), catchError(e => of([])))
      ]).pipe(
        map(results => ({
          project: results[0],
          farmers: results[1],
          productType: [],
          managers: [],
          master: results[2]
        }))
      );
    }
  }

  checkRole(): any {
    const localTokenInfo = this.storageService.getLocalTokenInfo();
    if (localTokenInfo) {
      this.userValue = JSON.parse(localTokenInfo);
      return this.userValue.role.id;
    }
  }

}
