import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectService } from '../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SurveyService } from '../../services/survey.service';
import { CommonService } from '../../services/common.service';
import { ConsReportService } from '../../services/consolidated-reports.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { BsModalService } from 'ngx-bootstrap/modal';
var ConsolidatedReportComponent = /** @class */ (function () {
    function ConsolidatedReportComponent(route, projectService, toastrService, surveyService, commonService, consReportService, translate, spinner, modalService, router) {
        var _this = this;
        this.route = route;
        this.projectService = projectService;
        this.toastrService = toastrService;
        this.surveyService = surveyService;
        this.commonService = commonService;
        this.consReportService = consReportService;
        this.translate = translate;
        this.spinner = spinner;
        this.modalService = modalService;
        this.router = router;
        this.projectId = 0;
        this.listsSectionsSubSection = [];
        this.notActiveSurvey = 2;
        this.dashboardAddObject = {
            "section": [],
            "subsection": []
        };
        this.selectedTab = false;
        this.newSubSection = [];
        this.finalQuestionsArray = [];
        this.questionAndType = [];
        this.page = 1;
        this.itemsPerPage = 10;
        this.searchText = '';
        this.selectedQuestions = [];
        this.isAscending = true;
        this.isAscendingName = true;
        this.selectedReportsMultiSelect = [];
        this.dropdownSettings = {};
        this.dropdownSettings1 = {};
        this.checkedObject = {};
        this.additionalTypeSelected = [];
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('select_deselect_object').subscribe(function (result) {
            _this.selectDeselectLabels = result;
        });
        this.translate.get('selectPlaceholder').subscribe(function (result) {
            _this.selectPlaceholder = result;
        });
    }
    ConsolidatedReportComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, projectObject;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.projectId = parseInt(this.route.snapshot.params['id']);
                        return [4 /*yield*/, Promise.all([
                                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        this.project = request[0].data;
                        projectObject = this.project.filter(function (item) { return item.id == _this.projectId; })[0];
                        this.project = projectObject;
                        if (request[1]['message'] === 'notActive') {
                            this.notActiveSurvey = 1;
                            this.commonService.showToast('warning', 'No Active Survey Found', {});
                        }
                        else if (request[1]['message'] === 'notReleasedForLanguage' || request[1]['message'].length == 0) {
                            this.notReleasedSurveyInLanguage = 1;
                        }
                        else {
                            // Set the questionnaire object
                            this.questionnaire = request[1]['message'];
                            this.notActiveSurvey = 0;
                            this.notReleasedSurveyInLanguage = 0;
                            this.surveyId = request[1]['message'][0].survey_id;
                        }
                        this.setSectionSubsection();
                        this.reportsTypeData = [{
                                "name": "Percent",
                                "id": 1,
                                "q_type": "multiselect, select, text"
                            }, {
                                "name": "Average",
                                "id": 2,
                                "q_type": "number"
                            }, {
                                "name": "Sum",
                                "id": 3,
                                "q_type": "number"
                            }, {
                                "name": "Min",
                                "id": 4,
                                "q_type": "number"
                            }, {
                                "name": "Max",
                                "id": 5,
                                "q_type": "number"
                            }, {
                                "name": "Median",
                                "id": 6,
                                "q_type": "number"
                            }, {
                                "name": "Count",
                                "id": 7,
                                "q_type": "multiselect, select, text"
                            }, {
                                "name": "Total Interviewees",
                                "id": 8,
                                "q_type": "multiselect, select, text"
                            }, {
                                "name": "Total Answers",
                                "id": 9,
                                "q_type": "multiselect, select, text, number"
                            }];
                        this.dropdownSettings = {
                            singleSelection: false,
                            idField: 'sectionId',
                            textField: 'section',
                            allowSearchFilter: false,
                            enableCheckAll: false,
                            itemsShowLimit: 3
                        };
                        this.dropdownSettings1 = {
                            singleSelection: false,
                            idField: 'id',
                            textField: 'name',
                            allowSearchFilter: true,
                            enableCheckAll: true,
                            itemsShowLimit: 3
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    ConsolidatedReportComponent.prototype.setSectionSubsection = function () {
        for (var _i = 0, _a = this.questionnaire; _i < _a.length; _i++) {
            var item = _a[_i];
            var newObject = {
                "section": '',
                "sectionId": '',
                "subsection": []
            };
            newObject.section = item.survey_q_asset_labels[0].label;
            newObject.sectionId = item.id;
            var subSection = item.sections;
            for (var _b = 0, subSection_1 = subSection; _b < subSection_1.length; _b++) {
                var x = subSection_1[_b];
                var subObject = {
                    "name": x.survey_q_asset_labels[0].label,
                    "id": x.id
                };
                newObject.subsection.push(subObject);
            }
            this.listsSectionsSubSection.push(newObject);
        }
    };
    ConsolidatedReportComponent.prototype.selectSection = function (item) {
        if (this.dashboardAddObject.section.length > 0) {
            this.selectedTab = true;
        }
        else {
            this.selectedTab = false;
        }
        this.dashboardAddObject.subsection = [];
        this.newSubSection = [];
        var _loop_1 = function (sec) {
            var _a;
            var section = this_1.listsSectionsSubSection.filter(function (item) { return item.sectionId == sec.sectionId; })[0];
            if (section != undefined || section != null) {
                (_a = this_1.newSubSection).push.apply(_a, section.subsection);
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = this.dashboardAddObject.section; _i < _a.length; _i++) {
            var sec = _a[_i];
            _loop_1(sec);
        }
    };
    ConsolidatedReportComponent.prototype.genarateReports = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var questionnaireObject, _loop_2, this_2, _i, _a, sec, questionArray, subsectionArr, _loop_3, _b, _c, SS, _d, subsectionArr_1, subSec, subsection, _e, subsection_1, item, questions, _f, questions_1, q, obj, table, _g, table_1, item, questions, _h, questions_2, q, obj, secQuestions, _j, secQuestions_1, q, obj, _k, questionnaireObject_1, i, sections, _l, sections_1, section, subsection, _m, subsection_2, item, questions, _o, questions_3, q, obj, table, _p, table_2, item, questions, _q, questions_4, q, obj, secQuestions, _r, secQuestions_2, q, obj, newRequest, request;
            return tslib_1.__generator(this, function (_s) {
                switch (_s.label) {
                    case 0:
                        this.spinner.show();
                        questionnaireObject = [];
                        _loop_2 = function (sec) {
                            var qObject = this_2.questionnaire.filter(function (item) { return item.id == sec.sectionId; });
                            if (qObject) {
                                questionnaireObject.push(qObject[0]);
                            }
                        };
                        this_2 = this;
                        for (_i = 0, _a = this.dashboardAddObject.section; _i < _a.length; _i++) {
                            sec = _a[_i];
                            _loop_2(sec);
                        }
                        questionArray = [];
                        if (this.dashboardAddObject.subsection.length > 0) {
                            subsectionArr = [];
                            _loop_3 = function (SS) {
                                for (var _i = 0, questionnaireObject_2 = questionnaireObject; _i < questionnaireObject_2.length; _i++) {
                                    var sec = questionnaireObject_2[_i];
                                    var qObject = sec.sections.filter(function (item) { return item.id == SS.id; });
                                    if (qObject && qObject.length > 0) {
                                        subsectionArr.push(qObject[0]);
                                    }
                                }
                            };
                            for (_b = 0, _c = this.dashboardAddObject.subsection; _b < _c.length; _b++) {
                                SS = _c[_b];
                                _loop_3(SS);
                            }
                            for (_d = 0, subsectionArr_1 = subsectionArr; _d < subsectionArr_1.length; _d++) {
                                subSec = subsectionArr_1[_d];
                                subsection = subSec.subsections;
                                if (subsection && subsection.length > 0) {
                                    for (_e = 0, subsection_1 = subsection; _e < subsection_1.length; _e++) {
                                        item = subsection_1[_e];
                                        questions = item.questions;
                                        for (_f = 0, questions_1 = questions; _f < questions_1.length; _f++) {
                                            q = questions_1[_f];
                                            obj = {
                                                "id": q.id,
                                                "name": q.survey_q_asset_labels[0].label,
                                                "original_questionnaire_item_code": q.original_questionnaire_item_code,
                                                "qa_subtype": q.qa_subtype
                                            };
                                            this.finalQuestionsArray.push(obj);
                                            questionArray.push(q.id);
                                        }
                                    }
                                }
                                table = subSec.tables;
                                if (table && table.length > 0) {
                                    for (_g = 0, table_1 = table; _g < table_1.length; _g++) {
                                        item = table_1[_g];
                                        questions = item.questions;
                                        for (_h = 0, questions_2 = questions; _h < questions_2.length; _h++) {
                                            q = questions_2[_h];
                                            obj = {
                                                "id": q.id,
                                                "name": q.survey_q_asset_labels[0].label,
                                                "original_questionnaire_item_code": q.original_questionnaire_item_code,
                                                "qa_subtype": q.qa_subtype
                                            };
                                            this.finalQuestionsArray.push(obj);
                                            questionArray.push(q.id);
                                        }
                                    }
                                }
                                secQuestions = subSec.questions;
                                for (_j = 0, secQuestions_1 = secQuestions; _j < secQuestions_1.length; _j++) {
                                    q = secQuestions_1[_j];
                                    obj = {
                                        "id": q.id,
                                        "name": q.survey_q_asset_labels[0].label,
                                        "original_questionnaire_item_code": q.original_questionnaire_item_code,
                                        "qa_subtype": q.qa_subtype
                                    };
                                    this.finalQuestionsArray.push(obj);
                                    questionArray.push(q.id);
                                }
                            }
                        }
                        else {
                            for (_k = 0, questionnaireObject_1 = questionnaireObject; _k < questionnaireObject_1.length; _k++) {
                                i = questionnaireObject_1[_k];
                                sections = i.sections;
                                for (_l = 0, sections_1 = sections; _l < sections_1.length; _l++) {
                                    section = sections_1[_l];
                                    subsection = section.subsections;
                                    if (subsection && subsection.length > 0) {
                                        for (_m = 0, subsection_2 = subsection; _m < subsection_2.length; _m++) {
                                            item = subsection_2[_m];
                                            questions = item.questions;
                                            for (_o = 0, questions_3 = questions; _o < questions_3.length; _o++) {
                                                q = questions_3[_o];
                                                obj = {
                                                    "id": q.id,
                                                    "name": q.survey_q_asset_labels[0].label,
                                                    "original_questionnaire_item_code": q.original_questionnaire_item_code,
                                                    "qa_subtype": q.qa_subtype
                                                };
                                                this.finalQuestionsArray.push(obj);
                                                questionArray.push(q.id);
                                            }
                                        }
                                    }
                                    table = section.tables;
                                    if (table && table.length > 0) {
                                        for (_p = 0, table_2 = table; _p < table_2.length; _p++) {
                                            item = table_2[_p];
                                            questions = item.questions;
                                            for (_q = 0, questions_4 = questions; _q < questions_4.length; _q++) {
                                                q = questions_4[_q];
                                                obj = {
                                                    "id": q.id,
                                                    "name": q.survey_q_asset_labels[0].label,
                                                    "original_questionnaire_item_code": q.original_questionnaire_item_code,
                                                    "qa_subtype": q.qa_subtype
                                                };
                                                this.finalQuestionsArray.push(obj);
                                                questionArray.push(q.id);
                                            }
                                        }
                                    }
                                    secQuestions = section.questions;
                                    for (_r = 0, secQuestions_2 = secQuestions; _r < secQuestions_2.length; _r++) {
                                        q = secQuestions_2[_r];
                                        obj = {
                                            "id": q.id,
                                            "name": q.survey_q_asset_labels[0].label,
                                            "original_questionnaire_item_code": q.original_questionnaire_item_code,
                                            "qa_subtype": q.qa_subtype
                                        };
                                        this.finalQuestionsArray.push(obj);
                                        questionArray.push(q.id);
                                    }
                                }
                            }
                        }
                        newRequest = {
                            "questions": questionArray
                        };
                        return [4 /*yield*/, Promise.all([
                                this.consReportService.getConsolidationTypeData(this.projectId, newRequest).toPromise()
                            ])];
                    case 1:
                        request = _s.sent();
                        if (request[0].code == "success") {
                            // let isNew = false;
                            // for(const q of request[0].message){
                            //   const findQ = this.questionAndType.find(item => item.questionnaire_asset_id == q.questionnaire_asset_id);
                            //   if(!findQ){
                            //     isNew = true;
                            //   }
                            // }
                            // if(isNew){
                            this.questionAndType = request[0].message;
                            this.toastrService.success("Unique questions loaded successfully, click on any question to extract it into excel");
                            // } else {
                            //   this.toastrService.warning("No unique questions found to be loaded, click on any question to extract it into excel");
                            // }
                            this.setForm();
                        }
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    ConsolidatedReportComponent.prototype.setForm = function () {
        this.checkedObject = {};
        var _loop_4 = function (item) {
            this_3.checkedObject[item.questionnaire_asset_id] = item.cons_type;
            var findQ = this_3.finalQuestionsArray.find(function (item1) { return item1.id == item.questionnaire_asset_id; });
            if (findQ) {
                item.qa_subtype = findQ.qa_subtype;
            }
        };
        var this_3 = this;
        for (var _i = 0, _a = this.questionAndType; _i < _a.length; _i++) {
            var item = _a[_i];
            _loop_4(item);
        }
    };
    ConsolidatedReportComponent.prototype.exportConsolidatedReport = function (template) {
        if (this.additionalTypeSelected.length > 0) {
            this.confirmTypeSave = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            this.confirmModalFn('No');
        }
    };
    ConsolidatedReportComponent.prototype.confirmModalFn = function (decision) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var typesToSave, _loop_5, this_4, _i, _a, item, typeSelectedQuestions, _loop_6, this_5, _b, _c, qid, newRequest, typeRequest, requests, wb, wsBooks, wscols, sheetName, fileType, excelBuffer, blob, linkdownload, today, dd, mm, yyyy, today1;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.spinner.show();
                        typesToSave = [];
                        if (decision == "Yes") {
                            _loop_5 = function (item) {
                                var findQuestion = this_4.selectedQuestions.find(function (item1) { return item1 == item.qid; });
                                if (findQuestion) {
                                    var findItem = this_4.questionAndType.find(function (item2) { return item2.questionnaire_asset_id == item.qid; });
                                    if (findItem) {
                                        var newObj = {
                                            "project_id": this_4.projectId,
                                            "questionnaire_asset_id": item.qid,
                                            "survey_id": findItem.survey_id,
                                            "code": findItem.code,
                                            "cons_type": item.type,
                                            "label": findItem.label,
                                            "deleted": item.deleted,
                                            "is_def_cons": 0
                                        };
                                        typesToSave.push(newObj);
                                    }
                                }
                            };
                            this_4 = this;
                            for (_i = 0, _a = this.additionalTypeSelected; _i < _a.length; _i++) {
                                item = _a[_i];
                                _loop_5(item);
                            }
                        }
                        typeSelectedQuestions = [];
                        _loop_6 = function (qid) {
                            var findNR = this_5.questionAndType.find(function (item) { return item.questionnaire_asset_id == qid && item.not_required == 1; });
                            for (var i in this_5.checkedObject) {
                                if (i == qid) {
                                    if (this_5.checkedObject[i].length > 0) {
                                        var obj = {
                                            'qid': qid,
                                            'type': this_5.checkedObject[i],
                                            'not_required': findNR ? 1 : 0
                                        };
                                        typeSelectedQuestions.push(obj);
                                    }
                                }
                            }
                        };
                        this_5 = this;
                        for (_b = 0, _c = this.selectedQuestions; _b < _c.length; _b++) {
                            qid = _c[_b];
                            _loop_6(qid);
                        }
                        if (!(typeSelectedQuestions.length > 0)) return [3 /*break*/, 2];
                        newRequest = {
                            "questions": typeSelectedQuestions
                        };
                        typeRequest = {
                            "types": typesToSave
                        };
                        return [4 /*yield*/, Promise.all([
                                this.consReportService.getProjectConsolidatedReport(this.projectId, newRequest).toPromise(),
                                decision == 'Yes' ? this.consReportService.saveConsolidationTypeData(this.projectId, typeRequest).toPromise() : ''
                            ])
                            // for(const i of requests[0].message){
                            //   const survey3 = i[Object.keys(i)[7]];
                            //   const survey2 = i[Object.keys(i)[6]];
                            //   const survey1 = i[Object.keys(i)[5]];
                            //   const variance1 = survey1 - survey2;
                            //   i["Variance Survey 1 to 2"] = variance1.toFixed(1);
                            //   const variance2 = survey2 - survey3;
                            //   i["Variance Survey 2 to 3"] = variance2.toFixed(1);
                            // }
                        ];
                    case 1:
                        requests = _d.sent();
                        wb = XLSX.utils.book_new();
                        wsBooks = XLSX.utils.json_to_sheet(requests[0].message);
                        wscols = [
                            { wpx: 80 },
                            { wpx: 120 },
                            { wpx: 120 },
                            { wpx: 300 },
                            { wpx: 120 },
                            { wpx: 120 },
                            { wpx: 150 },
                            { wpx: 200 },
                            { wpx: 200 },
                            { wpx: 120 }
                        ];
                        wsBooks['!cols'] = wscols;
                        sheetName = "Consolidation Data";
                        XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);
                        fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                        excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        blob = new Blob([excelBuffer], { type: fileType });
                        linkdownload = document.createElement('a');
                        linkdownload.href = URL.createObjectURL(blob);
                        today = new Date();
                        dd = String(today.getDate()).padStart(2, '0');
                        mm = String(today.getMonth() + 1).padStart(2, '0');
                        yyyy = today.getFullYear();
                        today1 = dd + '-' + mm + '-' + yyyy;
                        linkdownload.download = "P2F-Roots_Consolidated_Report_Project_" + this.projectId + "_" + today1 + ".xlsx";
                        linkdownload.style.display = 'none';
                        document.body.appendChild(linkdownload);
                        linkdownload.click();
                        linkdownload.remove();
                        this.selectedQuestions = [];
                        this.additionalTypeSelected = [];
                        this.toastrService.success("Excel Downloaded");
                        this.resetQuestionnaire();
                        this.spinner.hide();
                        return [3 /*break*/, 3];
                    case 2:
                        this.selectedQuestions = [];
                        this.commonService.showToast('warning', 'Please select consolidation type for at least one question.', { preventDuplicates: true });
                        this.spinner.hide();
                        _d.label = 3;
                    case 3:
                        if (this.confirmTypeSave) {
                            this.closeTypePopup();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ConsolidatedReportComponent.prototype.typeChanged = function (ele, val, qid) {
        if (ele.target.checked) {
            for (var i in this.checkedObject) {
                if (i == qid) {
                    var typeArr = this.checkedObject[i].split(', ');
                    typeArr.push(val);
                    typeArr = typeArr.filter(function (el) {
                        return el != "";
                    });
                    this.checkedObject[i] = typeArr.join(', ');
                }
            }
            var additonalTypeObject = {
                "qid": qid,
                "type": val,
                "deleted": 0
            };
            this.additionalTypeSelected.push(additonalTypeObject);
        }
        else {
            for (var i in this.checkedObject) {
                if (i == qid) {
                    var typeArr = this.checkedObject[i].split(', ');
                    for (var j = 0; j < typeArr.length; j++) {
                        if (val == typeArr[j]) {
                            typeArr.splice(j, 1);
                        }
                    }
                    typeArr = typeArr.filter(function (el) {
                        return el != "";
                    });
                    this.checkedObject[i] = typeArr.join(', ');
                }
            }
            var findType = void 0;
            for (var j = 0; j < this.additionalTypeSelected.length; j++) {
                if (this.additionalTypeSelected[j].qid == qid && this.additionalTypeSelected[j].type == val) {
                    findType = this.additionalTypeSelected[j];
                    this.additionalTypeSelected.splice(j, 1);
                }
            }
            if (!findType) {
                var additonalTypeObject = {
                    "qid": qid,
                    "type": val,
                    "deleted": 1
                };
                this.additionalTypeSelected.push(additonalTypeObject);
            }
        }
    };
    ConsolidatedReportComponent.prototype.resetToDefault = function (template) {
        //resetDefaultConsolidationType
        this.confirmDefaultReset = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    ConsolidatedReportComponent.prototype.clearAll = function () {
        this.dashboardAddObject.section = [];
        this.dashboardAddObject.subsection = [];
        this.questionAndType = [];
        this.selectedQuestions = [];
        this.additionalTypeSelected = [];
        this.newSubSection = [];
    };
    ConsolidatedReportComponent.prototype.selectAllConsTypes = function () {
        for (var _i = 0, _a = this.questionAndType; _i < _a.length; _i++) {
            var qid = _a[_i];
            for (var _b = 0, _c = this.reportsTypeData; _b < _c.length; _b++) {
                var type = _c[_b];
                if (type.q_type.indexOf(qid.qa_subtype) > -1) {
                    for (var i in this.checkedObject) {
                        if (i == qid.questionnaire_asset_id) {
                            var typeArr = this.checkedObject[i].split(', ');
                            typeArr.push(type.name);
                            typeArr = typeArr.filter(function (el) {
                                return el != "";
                            });
                            this.checkedObject[i] = typeArr.join(', ');
                        }
                    }
                    var additonalTypeObject = {
                        "qid": qid.questionnaire_asset_id,
                        "type": type.name,
                        "deleted": 0
                    };
                    this.additionalTypeSelected.push(additonalTypeObject);
                }
            }
        }
    };
    ConsolidatedReportComponent.prototype.confirmDefaultFn = function () {
        var _this = this;
        this.spinner.show();
        this.consReportService.resetDefaultConsolidationType(this.projectId, this.surveyId)
            .subscribe(function (result) {
            if (result.code == 'success') {
                _this.toastrService.success("Consolidation types set to default successfully!");
            }
            _this.resetQuestionnaire();
            _this.closeDefaultFn();
            _this.spinner.hide();
        });
    };
    ConsolidatedReportComponent.prototype.resetQuestionnaire = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var qids, newRequest, request, _loop_7, this_6, _i, _a, q;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        qids = this.questionAndType.map(function (item) { return item.questionnaire_asset_id; });
                        newRequest = {
                            "questions": qids
                        };
                        return [4 /*yield*/, Promise.all([
                                this.consReportService.getConsolidationTypeData(this.projectId, newRequest).toPromise()
                            ])];
                    case 1:
                        request = _b.sent();
                        if (request[0].code == "success") {
                            this.questionAndType = [];
                            _loop_7 = function (q) {
                                var findQ = this_6.questionAndType.find(function (item) { return item.questionnaire_asset_id == q.questionnaire_asset_id; });
                                if (!findQ) {
                                    this_6.questionAndType.push(q);
                                }
                            };
                            this_6 = this;
                            for (_i = 0, _a = request[0].message; _i < _a.length; _i++) {
                                q = _a[_i];
                                _loop_7(q);
                            }
                            this.setForm();
                            this.additionalTypeSelected = [];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ConsolidatedReportComponent.prototype.closeDefaultFn = function () {
        this.confirmDefaultReset.hide();
    };
    ConsolidatedReportComponent.prototype.closeTypePopup = function () {
        this.confirmTypeSave.hide();
    };
    ConsolidatedReportComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['label'];
        var finalObj = {};
        if (this.questionAndType === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    // Select / deselect all questions in the array
    ConsolidatedReportComponent.prototype.toggleAllFarmers = function () {
        // If all questions are already selected, then deselect all farmers
        if (this.selectedQuestions.length == this.questionAndType.length) {
            this.selectedQuestions = [];
        }
        // If all questions are NOT already selected, select all the questions
        else {
            this.selectedQuestions = [];
            for (var i = 0; i < this.questionAndType.length; i++) {
                this.selectedQuestions.push(this.questionAndType[i].questionnaire_asset_id);
            }
        }
        this.setTooltipText();
    };
    // Toggle a particular question's selection status
    ConsolidatedReportComponent.prototype.toggleThisFarmerSelect = function (id) {
        if (this.selectedQuestions.indexOf(id) > -1) {
            this.selectedQuestions = this.selectedQuestions.filter(function (item) { return item != id; });
        }
        else
            this.selectedQuestions.push(id);
        this.setTooltipText();
    };
    ConsolidatedReportComponent.prototype.setTooltipText = function () {
        if (this.questionAndType && this.selectedQuestions) {
            if (this.selectedQuestions.length == this.questionAndType.length) {
                // Deselect farmers.length farmers
                if (this.questionAndType.length == 1) {
                    this.tooltipText = this.selectDeselectLabels.deselect + " 1 question";
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.deselect + " " + this.questionAndType.length + " question";
                }
            }
            else {
                // Select farmers.length farmers
                if (this.questionAndType.length == 1) {
                    this.tooltipText = this.selectDeselectLabels.select + " 1 question";
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.select + " " + this.questionAndType.length + " question";
                }
            }
        }
    };
    ConsolidatedReportComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    ConsolidatedReportComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        if (sortBy === 'label') {
            this.isAscendingName = false;
            this.questionAndType.sort(function (a, b) {
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return 1;
                }
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    ConsolidatedReportComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        if (sortBy === 'label') {
            this.isAscendingName = true;
            this.questionAndType.sort(function (a, b) {
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    ConsolidatedReportComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    ConsolidatedReportComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    ConsolidatedReportComponent.prototype.goToDashboards = function () {
        var url = "charts/dashboards/" + this.projectId;
        this.router.navigate([url]);
    };
    ConsolidatedReportComponent.prototype.trackBySectionName = function (index, dataItem) {
        return dataItem.section;
    };
    ConsolidatedReportComponent.prototype.trackBySubsectionName = function (index, dataItem) {
        return dataItem.name;
    };
    ConsolidatedReportComponent.prototype.keyDownFunction = function (event) {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    };
    ConsolidatedReportComponent.prototype.cancel = function () {
        var url = "charts/" + this.projectId;
        this.router.navigate([url]);
    };
    return ConsolidatedReportComponent;
}());
export { ConsolidatedReportComponent };
