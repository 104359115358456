import * as tslib_1 from "tslib";
import { OnInit, ElementRef, ComponentFactoryResolver, Renderer2, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuppliersService } from '../../../services/suppliers.service';
import { ChartService } from '../../../services/chart.service';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
import { DashboardAddService } from '../../../services/dashboard-add.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
var parentScope01;
var DashboardViewReportsComponent = /** @class */ (function () {
    function DashboardViewReportsComponent(route, projectService, toastrService, router, activeRoute, suppService, translate, changeDetectionRef, chartService, renderer, hcRService, 
    //added by Kiran for Gridster
    spinner, DashboardAddService, componentFactoryResolver) {
        this.route = route;
        this.projectService = projectService;
        this.toastrService = toastrService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.suppService = suppService;
        this.translate = translate;
        this.changeDetectionRef = changeDetectionRef;
        this.chartService = chartService;
        this.renderer = renderer;
        this.hcRService = hcRService;
        this.spinner = spinner;
        this.DashboardAddService = DashboardAddService;
        this.componentFactoryResolver = componentFactoryResolver;
        this.preferredReportsType = 'predefined';
        this.setAsDefault = false;
        this.reportListType = [];
        this.reportTypes = 'predefined';
        this.projects = [];
        this.projectId = 0;
        this.dashboardEditObject = {
            "name": '',
            "description": '',
            "section": 0,
            "subsection": '',
            "reportID": [],
            "newsection": '',
            "chartDataOptions": { "id": 0, "name": '', "type": '', "sortOrder": 0, "x": 0, "y": 0, "dim_col": 0, "dim_row": 0, "chartOptions": {} }
        };
        this.subsectionFlag = true;
        this.reportsFlag = true;
        this.selectedReportsMultiSelect = [];
        this.selectedReportsMultiSelectCustom = [];
        this.dropdownSettings = {};
        this.listsSectionsSubSection = [];
        this.chartsOptions = [];
        this.sectionDropDown = [];
        this.subSectionDropdown = [];
        this.listOfReport = [];
        this.exportBtns = false;
        this.reportAvailableFlag = false;
        this.dashboardData = [];
        this.existingSelectedReports = [];
        this.customReportsListData = [];
        this.checkChartsAvailable = [];
        this.profilingFlag = false;
        this.customFlag = false;
        this.localChartsList = [];
        //added by Kiran for Gridster
        this.title = "Data Dashoard";
        this.currentAdIndex = -1;
        this.zoom = [5];
        this.height = 300;
        this.kpisListData = [];
        this.Highcharts = Highcharts;
        this.dashboardIDs = [];
        this.kpiDataObject = { "id": 0, "name": '', "type": '', "sortOrder": 0, "x": 0, "y": 0, "dim_col": 0, "dim_row": 0, "kpiImage": "" };
        this.allReportsArr = [];
        this.chartReports = [];
    }
    DashboardViewReportsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var gridsterWidth, requestObject;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                parentScope01 = this;
                //console.log("this.route.snapshot.params ", this.route.snapshot.params);
                this.dashboardId = this.route.snapshot.params['id2'];
                this.projectId = this.route.snapshot.params['id'];
                this.hcRService.allCharts = [];
                this.DashboardAddService.deleteAll();
                gridsterWidth = document.getElementById("gridsterBase").offsetWidth;
                this.chartWidth = (gridsterWidth / 3) - 15;
                this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
                    _this.project = response.data;
                    var projectObject = _this.project.filter(function (item) { return item.id == _this.projectId; })[0];
                    _this.project = projectObject;
                });
                Highcharts.getSVG = function (charts) {
                    var svgArr = [], top = 0, width = 0, endWidth = 0;
                    Highcharts.each(charts, function (chart) {
                        var svg = chart.getSVG(), 
                        // Get width/height of SVG for export
                        svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/)[1], svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/)[1];
                        svg = svg.replace('<svg', '<g transform="translate(' + width + ', ' + top + ')" ');
                        svg = svg.replace('</svg>', '</g>');
                        width += svgWidth;
                        endWidth = Math.max(endWidth, width);
                        if (width === 2 * svgWidth) {
                            width = 0;
                            top += svgHeight;
                        }
                        svgArr.push(svg);
                    });
                    top += 400;
                    return '<svg height="' + top + '" width="' + endWidth +
                        '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
                        svgArr.join('') + '</svg>';
                };
                Highcharts.exportCharts = function (charts, options) {
                    // Merge the options
                    options = Highcharts.merge(Highcharts.getOptions().exporting, options);
                    // Post to export server
                    Highcharts.post(options.url, {
                        filename: options.filename || 'chart',
                        type: options.type,
                        width: options.width,
                        svg: Highcharts.getSVG(charts)
                    });
                };
                Highcharts.setOptions({
                    lang: {
                        noData: 'No data to display'
                    }
                });
                requestObject = {
                    "dashboardId": this.dashboardId
                };
                this.spinner.show();
                this.hcRService.getDashboardDetailsById(requestObject).subscribe(function (response) {
                    //console.log("get Dashboard Data ", response);
                    if (response.msg == 'success') {
                        _this.dashboardData = response.data;
                        _this.dashboardEditObject.name = _this.dashboardData[0].name;
                        _this.dashboardEditObject.description = _this.dashboardData[0].description;
                        if (_this.dashboardData[0].is_default == 'N') {
                            _this.setAsDefault = false;
                            _this.dashboardData[0].is_default = false;
                        }
                        else {
                            _this.setAsDefault = true;
                            _this.dashboardData[0].is_default = true;
                        }
                        var dashboardSelectedReports = [];
                        var reportArr = [];
                        for (var i = 0; i < _this.dashboardData[0].hc_report_dashboard_dtl.length; i++) {
                            // const reportArr = [];
                            if (_this.dashboardData[0].hc_report_dashboard_dtl[i].report_type == 'Chart') {
                                dashboardSelectedReports.push(_this.dashboardData[0].hc_report_dashboard_dtl[i]);
                                _this.chartReports.push(_this.dashboardData[0].hc_report_dashboard_dtl[i]);
                                //this.dashboardEditObject.section = dashboardData[0].hc_report_dashboard_dtl[0].section;
                            }
                            else {
                                _this.kpiDataObject =
                                    {
                                        "id": _this.dashboardData[0].hc_report_dashboard_dtl[i].report_id,
                                        "name": _this.dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.hc_report_name.length > 0 ? _this.dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.hc_report_name[0].name : '',
                                        "type": _this.dashboardData[0].hc_report_dashboard_dtl[i].report_id.report_type,
                                        "sortOrder": _this.dashboardData[0].hc_report_dashboard_dtl[i].report_id.sort_order,
                                        "x": _this.dashboardData[0].hc_report_dashboard_dtl[i].x_axis,
                                        "y": _this.dashboardData[0].hc_report_dashboard_dtl[i].y_axis,
                                        "dim_col": _this.dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.dim_col,
                                        "dim_row": _this.dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.dim_row,
                                        "kpiImage": _this.dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.kpi_image
                                    };
                                var tempObj = {
                                    "id": _this.dashboardData[0].hc_report_dashboard_dtl[i].report_id,
                                    "type": "view",
                                    "x": _this.kpiDataObject.x,
                                    "y": _this.kpiDataObject.y
                                };
                                reportArr.push(tempObj);
                            }
                        }
                        _this.genarateKPIs(reportArr);
                        //console.log("newSelectOptions ", dashboardSelectedReports)
                        if (dashboardSelectedReports && dashboardSelectedReports.length > 0) {
                            var reportsIds = [];
                            for (var i = 0; i < dashboardSelectedReports.length; i++) {
                                //hc_report_hdr
                                //let chartOptions = dashboardSelectedReports[i].hc_report_hdr.hc_report_type[0].conf;
                                //console.log(" chart options ", JSON.parse(chartOptions));
                                var reportId = [];
                                reportId.push(dashboardSelectedReports[i].report_id);
                                _this.dashboardEditObject.reportID = reportId;
                                _this.dashboardEditObject.newsection = dashboardSelectedReports[i].hc_report_hdr.section;
                                // this.dashboardEditObject.chartDataOptions =
                                //                   {
                                //                     "id":dashboardSelectedReports[i].report_id,
                                //                     "name":dashboardSelectedReports[i].hc_report_hdr.hc_report_name[0].name?dashboardSelectedReports[i].hc_report_hdr.hc_report_name[0].name:"",
                                //                     "type":dashboardSelectedReports[i].report_type,
                                //                     "sortOrder":dashboardSelectedReports[i].sort_order,
                                //                     "x":dashboardSelectedReports[i].x_axis,
                                //                     "y":dashboardSelectedReports[i].y_axis,
                                //                     "dim_col":dashboardSelectedReports[i].hc_report_hdr.dim_col,
                                //                     "dim_row":dashboardSelectedReports[i].hc_report_hdr.dim_row,
                                //                     "chartOptions":{}
                                //                   };
                                //console.log(" i --> ",i);
                                // this.genarateCharts(this.reportTypes,'init');
                                var tempObj = {
                                    "id": dashboardSelectedReports[i].report_id,
                                    "type": "init",
                                    "x": dashboardSelectedReports[i].x_axis,
                                    "y": dashboardSelectedReports[i].y_axis,
                                };
                                reportsIds.push(tempObj);
                            }
                            _this.genarateCharts(reportsIds);
                        }
                    }
                });
                this.spinner.hide();
                return [2 /*return*/];
            });
        });
    };
    DashboardViewReportsComponent.prototype.genarateKPIs = function (reports) {
        var _this = this;
        this.spinner.show();
        if (!reports || reports.length < 1) {
            return false;
        }
        var reportIds = [];
        for (var _i = 0, reports_1 = reports; _i < reports_1.length; _i++) {
            var report = reports_1[_i];
            reportIds.push(report.id);
        }
        var requestObject = {
            "reportID": reportIds,
            "projectId": this.projectId
            //"projectId":25
        };
        var innerObj;
        this.hcRService.getReports(requestObject).subscribe(function (response) {
            if (response.msg == 'success') {
                _this.reportAvailableFlag = true;
                var reportsData_1 = response.data;
                var _loop_1 = function (i) {
                    if (reportsData_1[i].is_multi_kpi) {
                        _this.multiKPI(reportsData_1[i], reports);
                    }
                    else if (reportsData_1[i].series.length > 0) {
                        for (var j = 0; j < reportsData_1[i].series.length; j++) {
                            for (var prop in reportsData_1[i].series[j]) {
                                if (reportsData_1[i].series[j].hasOwnProperty(prop)) {
                                    //if(Number(reportsData[i].series[j][prop]) > 0){
                                    innerObj = {
                                        id: reportsData_1[i].reportId,
                                        name: '',
                                        dim_col: reportsData_1[i].dim_col,
                                        dim_row: reportsData_1[i].dim_row,
                                        value: Number(''),
                                        kpi_image: ''
                                    };
                                    innerObj.name = reportsData_1[i].name;
                                    var num = Number(reportsData_1[i].series[j][prop]).toFixed();
                                    if (isNaN(parseInt(num))) {
                                        innerObj.value = reportsData_1[i].series[0][prop];
                                    }
                                    else {
                                        innerObj.value = parseInt(num);
                                    }
                                    innerObj.kpi_image = reportsData_1[i].kpi_image;
                                    var cordinates = reports.find(function (r) { return r.id == reportsData_1[i].reportId; });
                                    innerObj.x = (cordinates && cordinates.x) ? cordinates.x : 0;
                                    innerObj.y = (cordinates && cordinates.y) ? cordinates.y : 0;
                                    _this.DashboardAddService.addKPI(innerObj, "view");
                                }
                            }
                        }
                    }
                    else {
                        innerObj = {
                            id: reportsData_1[i].reportId,
                            name: '',
                            dim_col: reportsData_1[i].dim_col,
                            dim_row: reportsData_1[i].dim_row,
                            value: 'N/D',
                            kpi_image: ''
                        };
                        innerObj.name = reportsData_1[i].name;
                        innerObj.kpi_image = reportsData_1[i].kpi_image;
                        var cordinates = reports.find(function (r) { return r.id == reportsData_1[i].reportId; });
                        innerObj.x = (cordinates && cordinates.x) ? cordinates.x : 0;
                        innerObj.y = (cordinates && cordinates.y) ? cordinates.y : 0;
                        _this.DashboardAddService.addKPI(innerObj, "view");
                    }
                };
                for (var i = 0; i < reportsData_1.length; i++) {
                    _loop_1(i);
                }
                _this.calcGridsterHeight();
                _this.spinner.hide();
                //console.log("this.kpisReportsList ", this.kpisReportsList);
                //this.selectedKpiReportsIDs = [];
            }
        });
    };
    DashboardViewReportsComponent.prototype.multiKPI = function (obj, reports) {
        var outerObj = {
            id: obj.reportId,
            name: obj.name,
            is_multi: true,
            series: [],
            dim_col: obj.dim_col,
            dim_row: obj.dim_row,
            x: 0,
            y: 0
        };
        if (obj.drilldown.series.length > 0) {
            for (var j = 0; j < obj.drilldown.series.length; j++) {
                var innerObj = {
                    name: '',
                    value: '',
                    kpi_image: '',
                    cols: obj.dim_col
                };
                innerObj.name = obj.drilldown.series[j].kpi_key;
                if (obj.drilldown.series[j].data[0] != null) {
                    innerObj.value = obj.drilldown.series[j].data[0];
                }
                else {
                    innerObj.value = 'N/D';
                }
                innerObj.kpi_image = obj.drilldown.series[j].kpi_image;
                outerObj.series.push(innerObj);
            }
        }
        var cordinates = reports.find(function (r) { return r.id == obj.reportId; });
        outerObj.x = (cordinates && cordinates.x) ? cordinates.x : 0;
        outerObj.y = (cordinates && cordinates.y) ? cordinates.y : 0;
        this.DashboardAddService.addKPI(outerObj, "view");
    };
    DashboardViewReportsComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.interval);
        window.clearInterval(this.timer);
    };
    Object.defineProperty(DashboardViewReportsComponent.prototype, "options", {
        get: function () {
            return this.DashboardAddService.options;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardViewReportsComponent.prototype, "layout", {
        get: function () {
            return this.DashboardAddService.layout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardViewReportsComponent.prototype, "components", {
        get: function () {
            return this.DashboardAddService.components;
        },
        enumerable: true,
        configurable: true
    });
    DashboardViewReportsComponent.prototype.calcGridsterHeight = function () {
        var kpiCount = 0;
        var chartCount = 0;
        for (var _i = 0, _a = this.layout; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item.componentName == "Chart") {
                chartCount = chartCount + item.cols;
            }
            else {
                kpiCount = kpiCount + item.cols;
            }
        }
        kpiCount = Math.ceil(kpiCount / 3);
        chartCount = Math.ceil(chartCount / 3);
        var totalKpiHeight = kpiCount * 160;
        var totalChartHeight = chartCount * 320;
        //const calcHeight = Math.ceil(this.layout.length / 3)*160;
        var calcHeight = totalChartHeight + totalKpiHeight;
        this.height = calcHeight;
    };
    DashboardViewReportsComponent.prototype.genarateCharts = function (reports) {
        var _this = this;
        var requestObject = {};
        // if(callType == 'init'){
        // //console.log("selectedReports ", selectedReports);
        //   requestObject = {
        //     "reportID":this.dashboardEditObject.reportID,
        //     //"reportID":this.chartReportsIds,
        //     "projectId":this.projectId
        //   };
        // }
        var reportIds = [];
        for (var _i = 0, reports_2 = reports; _i < reports_2.length; _i++) {
            var report = reports_2[_i];
            reportIds.push(report.id);
        }
        requestObject = {
            "reportID": reportIds,
            "projectId": this.projectId
        };
        //console.log("request params Object ", requestObject);
        var chartsList = [];
        this.spinner.show();
        this.hcRService.getReports(requestObject).subscribe(function (response) {
            //console.log("response ", response);
            if (response.msg == 'success') {
                _this.reportAvailableFlag = true;
                var reportsData = response.data;
                if (reportsData && reportsData.length > 0) {
                    for (var i = 0; i < reportsData.length; i++) {
                        var seriesArr = [];
                        var contextMenuOptions = [];
                        var chartOptions = reportsData[i].conf[0].conf;
                        if (typeof reportsData[i].conf[0].conf !== 'object') {
                            chartOptions = JSON.parse(reportsData[i].conf[0].conf);
                        }
                        //added by Kiran to change chart title to report name
                        if (chartOptions.hasOwnProperty('title')) {
                            chartOptions.title.text = reportsData[i].name;
                        }
                        chartOptions.dim_row = reportsData[i].dim_row;
                        chartOptions.dim_col = reportsData[i].dim_col;
                        //console.log("chartOptions ", chartOptions);
                        if (reportsData[i].is_drilldown != 1) {
                            //console.log(" chartOptions.chart.zoomType ", chartOptions.chart.zoomType);
                            //console.log("reportsData[i].series ",reportsData[i].series.length);
                            if (reportsData[i].series && reportsData[i].series.length > 1) {
                                //console.log("more than 1 conf ");
                                if (chartOptions.chart.zoomType == 'xy' && reportsData[i].conf[0].report_type == 'dual-axes') {
                                    seriesArr = [];
                                    //console.log("reportsData[i].conf[0].report_type ", reportsData[i].conf[0].report_type);
                                    var category = [];
                                    for (var x = 0; x < reportsData[i].series.length; x++) {
                                        category.push(reportsData[i].series[x].key);
                                        for (var y = 0; y < chartOptions.series.length; y++) {
                                            if (chartOptions.series[y].hasOwnProperty('yAxis')) {
                                                //chartOptions.series[y].name='';
                                                chartOptions.series[y].data.push(Number(reportsData[i].series[x].avg_expense));
                                            }
                                            else {
                                                //chartOptions.series[y].name='';
                                                chartOptions.series[y].data.push(reportsData[i].series[x].value);
                                            }
                                        }
                                    }
                                    if (Array.isArray(chartOptions.xAxis)) {
                                        chartOptions.xAxis[0].categories = category;
                                    }
                                    if (chartOptions.hasOwnProperty('yAxis')) {
                                        var newString = chartOptions.yAxis[1].title.text + ' (' + reportsData[i].series[0].currency + ')';
                                        chartOptions.yAxis[1].title.text = newString;
                                    }
                                    //chartOptions.legend.y=0;
                                    //chartOptions.legend.x=60;
                                    //console.log("chartOptions dual axes ", JSON.stringify(chartOptions));
                                }
                                else {
                                    var hcObject = {};
                                    var categories = [];
                                    var objectKeys = Object.keys(reportsData[i].series[0]);
                                    for (var j = 0; j < reportsData[i].series.length; j++) {
                                        var elementItem = reportsData[i].series[j];
                                        for (var k = 0; k < objectKeys.length; k++) {
                                            if (objectKeys[k] == 'name') {
                                                categories.push(reportsData[i].series[j][objectKeys[k]]);
                                            }
                                            else {
                                                if (hcObject[objectKeys[k]]) {
                                                    hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                                                }
                                                else {
                                                    hcObject[objectKeys[k]] = new Array();
                                                    hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                                                }
                                            }
                                        }
                                    }
                                    if (Object.keys(hcObject).length !== 0) {
                                        for (var key in hcObject) {
                                            var object_1 = {
                                                name: '',
                                                data: []
                                            };
                                            object_1.name = key;
                                            object_1.data = hcObject[key];
                                            seriesArr.push(object_1);
                                        }
                                    }
                                    //console.log(" seriesArr time ", seriesArr);
                                    if (chartOptions.hasOwnProperty('xAxis')) {
                                        chartOptions.xAxis.categories = categories;
                                    }
                                    if (chartOptions.hasOwnProperty('yAxis')) {
                                        chartOptions.yAxis.min = 0;
                                    }
                                    //chartOptions.xAxis.categories = categories;
                                    //console.log("4 ", chartOptions);
                                    chartOptions.series = seriesArr;
                                    //console.log("chartOptions.series ",chartOptions.series);
                                }
                            }
                            else {
                                //console.log("reportsData[i].series ", reportsData[i].series);
                                if (chartOptions.chart.zoomType == 'xy' && reportsData[i].conf[0].report_type == 'dual-axes') {
                                    seriesArr = [];
                                    //console.log("reportsData[i].conf[0].report_type ", reportsData[i].conf[0].report_type);
                                    var category = [];
                                    for (var x = 0; x < reportsData[i].series.length; x++) {
                                        category.push(reportsData[i].series[x].key);
                                        for (var y = 0; y < chartOptions.series.length; y++) {
                                            if (chartOptions.series[y].hasOwnProperty('yAxis')) {
                                                //chartOptions.series[y].name='';
                                                chartOptions.series[y].data.push(Number(reportsData[i].series[x].avg_expense));
                                            }
                                            else {
                                                //chartOptions.series[y].name='';
                                                chartOptions.series[y].data.push(reportsData[i].series[x].value);
                                            }
                                        }
                                    }
                                    if (Array.isArray(chartOptions.xAxis)) {
                                        chartOptions.xAxis[0].categories = category;
                                    }
                                    if (chartOptions.hasOwnProperty('yAxis')) {
                                        if (reportsData[i].series.length > 0 && reportsData[i].series[0].hasOwnProperty('currency')) {
                                            var newString = chartOptions.yAxis[1].title.text + ' (' + reportsData[i].series[0].currency + ')';
                                            chartOptions.yAxis[1].title.text = newString;
                                        }
                                    }
                                    //chartOptions.legend.y=0;
                                    //chartOptions.legend.x=60;
                                    //console.log("chartOptions dual axes ", JSON.stringify(chartOptions));
                                }
                                else if (chartOptions.chart.type != 'pyramid') {
                                    for (var j = 0; j < reportsData[i].series.length; j++) {
                                        for (var prop in reportsData[i].series[j]) {
                                            if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                                if (Number(reportsData[i].series[0][prop]) > 0) {
                                                    var innerObj = {
                                                        name: '',
                                                        y: Number(''),
                                                    };
                                                    innerObj.name = prop;
                                                    innerObj.y = Number(reportsData[i].series[0][prop]);
                                                    seriesArr.push(innerObj);
                                                }
                                            }
                                        }
                                    }
                                    // chartOptions.series = [
                                    //   {
                                    //     colorByPoint: true,
                                    //     data: seriesArr
                                    //   }
                                    // ];
                                    //console.log("3 ", chartOptions);
                                    if (chartOptions.hasOwnProperty('series')) {
                                        if (chartOptions.series.length > 0) {
                                            if (reportsData[i].conf[0].report_type == 'waterfall') {
                                                if (chartOptions.hasOwnProperty('chart')) {
                                                    if (chartOptions.chart.hasOwnProperty('type')) {
                                                        //console.log("waterfall")
                                                        var totalObject = {
                                                            name: 'Total',
                                                            isIntermediateSum: true
                                                        };
                                                        seriesArr.push(totalObject);
                                                        chartOptions.series[0].data = seriesArr;
                                                    }
                                                }
                                            }
                                            else {
                                                chartOptions.series[0].data = seriesArr;
                                            }
                                        }
                                    }
                                    else {
                                        if (reportsData[i].name === 'Accessibility to Basics') {
                                            var categories = [];
                                            seriesArr = [];
                                            //console.log(reportsData[i]);
                                            for (var j = 0; j < reportsData[i].series.length; j++) {
                                                var object = {
                                                    name: 'Farmers',
                                                    data: []
                                                };
                                                for (var prop in reportsData[i].series[j]) {
                                                    if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                                        categories.push(prop);
                                                        // object.name = key;
                                                        object.data.push(Number(reportsData[i].series[0][prop]));
                                                    }
                                                }
                                                seriesArr.push(object);
                                            }
                                            if (chartOptions.hasOwnProperty('xAxis')) {
                                                chartOptions.xAxis.categories = categories;
                                            }
                                            if (chartOptions.hasOwnProperty('yAxis')) {
                                                chartOptions.yAxis.min = 0;
                                            }
                                            chartOptions.series = seriesArr;
                                        }
                                        else {
                                            chartOptions.series = [
                                                {
                                                    colorByPoint: true,
                                                    data: seriesArr
                                                }
                                            ];
                                        }
                                    }
                                }
                                else {
                                    for (var j = 0; j < reportsData[i].series.length; j++) {
                                        for (var prop_1 in reportsData[i].series[j]) {
                                            if (reportsData[i].series[0].hasOwnProperty(prop_1)) {
                                                if (Number(reportsData[i].series[0][prop_1]) > 0) {
                                                    var newArray = [];
                                                    newArray[0] = prop_1;
                                                    newArray[1] = Number(reportsData[i].series[0][prop_1]);
                                                    seriesArr.push(newArray);
                                                }
                                            }
                                        }
                                    }
                                    //console.log("seriesArr pyramid", seriesArr);
                                    seriesArr.sort(function (a, b) {
                                        return a[1] < b[1] ? 1 : -1;
                                    });
                                    //console.log("seriesArr pyramid", seriesArr);
                                    // chartOptions.series = [
                                    //   {
                                    //     colorByPoint: true,
                                    //     data: seriesArr
                                    //   }
                                    // ];
                                    //console.log("2 ", chartOptions);
                                    if (chartOptions.hasOwnProperty('series')) {
                                        chartOptions.series[0].data = seriesArr;
                                    }
                                    else {
                                        chartOptions.series = [
                                            {
                                                colorByPoint: true,
                                                data: seriesArr
                                            }
                                        ];
                                    }
                                }
                                //console.log("seriesArr ", seriesArr);
                            }
                        }
                        else if (reportsData[i].is_drilldown == 1) {
                            //console.log("Drill down ", chartOptions);
                            //console.log("reportsData[i] ",reportsData[i].drilldown);
                            for (var j = 0; j < reportsData[i].series.length; j++) {
                                for (var prop_2 in reportsData[i].series[j]) {
                                    if (reportsData[i].series[0].hasOwnProperty(prop_2)) {
                                        if (Number(reportsData[i].series[0][prop_2]) > 0) {
                                            var innerObj_1 = {
                                                name: '',
                                                y: Number(''),
                                                drilldown: ''
                                            };
                                            //let itemVal = Number(reportsData[i].series[0][prop]).toFixed(1);
                                            //console.log("Number(reportsData[i].series[0][prop]) ",itemVal);
                                            innerObj_1.name = prop_2;
                                            innerObj_1.y = Number(reportsData[i].series[0][prop_2]);
                                            var drilldownSeriesData = reportsData[i].drilldown.series;
                                            for (var x = 0; x < drilldownSeriesData.length; x++) {
                                                if (drilldownSeriesData[x].name == prop_2 && drilldownSeriesData[x].id == prop_2) {
                                                    innerObj_1.drilldown = prop_2;
                                                }
                                            }
                                            seriesArr.push(innerObj_1);
                                            //console.log("seriesArr ", seriesArr);
                                        }
                                    }
                                }
                            }
                            //console.log("1 ", chartOptions);
                            if (reportsData[i].conf[0].report_type != 'combination') {
                                if (chartOptions.hasOwnProperty('series')) {
                                    chartOptions.series[0].data = seriesArr;
                                }
                                else {
                                    chartOptions.series = [
                                        {
                                            colorByPoint: true,
                                            data: seriesArr
                                        }
                                    ];
                                }
                                var drilldownSeriesData = reportsData[i].drilldown.series;
                                for (var x = 0; x < drilldownSeriesData.length; x++) {
                                    //let drillData = drilldownSeriesData[x].data;
                                    var drillOptionsData = [];
                                    if (drilldownSeriesData[x].has_child == 1) {
                                        drilldownSeriesData[x]['keys'] = ['name', 'y', 'drilldown'];
                                    }
                                    if (drilldownSeriesData[x].hasOwnProperty('drill_report_type')) {
                                        if (drilldownSeriesData[x].drill_report_type != null && drilldownSeriesData[x].drill_report_type != '') {
                                            drilldownSeriesData[x].type = drilldownSeriesData[x].drill_report_type;
                                            //Object.assign(chartOptions.drilldown.series[x], {type: "value"})
                                            //console.log("chartOptions.drilldown.series[x]. ", chartOptions.drilldown.series[x]);
                                            //chartOptions.drilldown.series[x].type = drilldownSeriesData[x].drill_report_type;
                                        }
                                    }
                                }
                                if (reportsData[i].reportId == 102) {
                                    if (drilldownSeriesData[0].hasOwnProperty('data')) {
                                        for (var x = 0; x < drilldownSeriesData[0].data.length; x++) {
                                            if (drilldownSeriesData[0].data[x][2] == 'Above 15 years') {
                                                drilldownSeriesData[0].data[x][2] = '';
                                            }
                                        }
                                    }
                                }
                                if (reportsData[i].reportId == 118) {
                                    if (drilldownSeriesData[1].hasOwnProperty('data')) {
                                        for (var x = 0; x < drilldownSeriesData[1].data.length; x++) {
                                            if (drilldownSeriesData[1].data[x][2] == 'No') {
                                                drilldownSeriesData[1].data[x][2] = '';
                                            }
                                        }
                                    }
                                }
                            }
                            else {
                                if (chartOptions.hasOwnProperty('xAxis')) {
                                    chartOptions.xAxis.categories = reportsData[i].series.categories;
                                }
                                if (reportsData[i].series.categories.length > 0) {
                                    var seriesData = reportsData[i].series.series;
                                    //console.log("seriesData ", seriesData);
                                    for (var i_1 = 0; i_1 < seriesData.length; i_1++) {
                                        if (seriesData[i_1].type == 'pie') {
                                            seriesData[i_1].center = [20, 0];
                                            seriesData[i_1].size = 50;
                                            seriesData[i_1].showInLegend = false;
                                            seriesData[i_1].dataLabels = {
                                                enabled: false
                                            };
                                            seriesData[i_1].name = "Farmers";
                                        }
                                        else {
                                            seriesData[i_1].name = "Farmers";
                                        }
                                    }
                                    chartOptions.series = reportsData[i].series.series;
                                }
                                else {
                                    chartOptions.series = [];
                                    //chartOptions.labels.items[0].html = '';
                                    if (chartOptions.hasOwnProperty('labels')) {
                                        chartOptions.labels.items[0].html = '';
                                    }
                                }
                            }
                        }
                        //console.log(" seriesArr ", seriesArr)
                        //for(let j=0;j < reportsData[i].conf[0].length; j++){
                        //console.log(" reportsData[i].conf ", reportsData[i].conf[0].conf);
                        // chartOptions.chart.width = 320;
                        //chartOptions.chart.height = "100%";
                        //chartOptions.chart_type = chartOptions.chart.type;
                        if (chartOptions.chart.hasOwnProperty('type')) {
                            chartOptions.chart_type = chartOptions.chart.type;
                        }
                        else {
                            if (chartOptions.chart.hasOwnProperty('charttype')) {
                                chartOptions.chart_type = chartOptions.chart.charttype;
                            }
                        }
                        chartOptions.title.style = {
                            fontSize: "16px",
                            fontWeight: '600'
                        };
                        chartOptions.reportId = reportsData[i].reportId;
                        if (reportsData[i].is_drilldown == 1) {
                            chartOptions.drilldown = reportsData[i].drilldown;
                            //chartOptions.drilldown.drillUpButton = {"position":{"verticalAlign":"top"}};
                            //chartOptions.drilldown.drillUpButton = {"position":{"x":-25,"y":-35}};
                            //added by Kiran for drillup button
                            var drillUp = {
                                relativeTo: 'spacingBox',
                                position: {
                                    align: 'left',
                                    y: -10,
                                    x: 0
                                },
                                theme: {
                                    fill: '#63775c',
                                    'stroke-width': 1,
                                    r: 25,
                                    states: {
                                        hover: {
                                            fill: '#63775c'
                                        },
                                        select: {
                                            fill: '#63775c'
                                        }
                                    }
                                }
                            };
                            chartOptions.drilldown.drillUpButton = drillUp;
                            if (chartOptions.hasOwnProperty('lang')) {
                                chartOptions.lang.drillUpText = '⇦';
                            }
                            else {
                                var drillText = {
                                    drillUpText: '⇦'
                                };
                                chartOptions.lang = drillText;
                            }
                            //var defaultTitleXAxis = "Percentage Range";
                            //var defaultTitleYAxis = "Avg age of children";
                            var defaultTitleYAxis = "";
                            //var drilldownTitleXAxis = "School Name";
                            //var drilldownTitleYAxis = "Count of children";
                            var drilldownTitleYAxis = "";
                            if (chartOptions.hasOwnProperty('yAxis')) {
                                if (Array.isArray(chartOptions.yAxis)) {
                                    defaultTitleYAxis = chartOptions.yAxis[0].title.text;
                                }
                                else {
                                    if (chartOptions.yAxis.hasOwnProperty('title')) {
                                        defaultTitleYAxis = chartOptions.yAxis.title.text;
                                    }
                                }
                            }
                            // if(chartOptions.hasOwnProperty('yAxisDrilldownTitle')){
                            //   drilldownTitleYAxis = chartOptions.yAxisDrilldownTitle.text;
                            // }
                            chartOptions.chart.events = {
                                drilldown: function (e) {
                                    var curConfig = this.options;
                                    var curTitle = curConfig.title.text;
                                    if (curConfig.hasOwnProperty('drilltitle1')) {
                                        if (curTitle == curConfig.drilltitle1.text) {
                                            if (curConfig.hasOwnProperty('drilltitle2')) {
                                                this.setTitle({ text: curConfig.drilltitle2.text }, { text: '' });
                                            }
                                        }
                                        else {
                                            this.oldTitle = curTitle;
                                            this.oldSubtitle = curConfig.subtitle.text;
                                            if (curConfig.hasOwnProperty('drilltitle2')) {
                                                this.setTitle({ text: curConfig.drilltitle1.text }, { text: 'Drilldown for details' });
                                            }
                                            else {
                                                this.setTitle({ text: curConfig.drilltitle1.text }, { text: '' });
                                            }
                                        }
                                    }
                                    var thisChart = this.yAxis[0].chart.options;
                                    if (thisChart.hasOwnProperty('yAxis')) {
                                        if (Array.isArray(thisChart.yAxis)) {
                                            if (thisChart.yAxis[0].hasOwnProperty('title')) {
                                                this.oldYaxisTitle = thisChart.yAxis[0].title.text;
                                            }
                                        }
                                        else {
                                            if (thisChart.yAxis.hasOwnProperty('title')) {
                                                this.oldYaxisTitle = thisChart.yAxis.title.text;
                                            }
                                        }
                                    }
                                    if (thisChart.hasOwnProperty('yAxisDrilldownTitle')) {
                                        if (this.hasOwnProperty('yAxis')) {
                                            if (Array.isArray(this.yAxis)) {
                                                this.yAxis[0].setTitle({ text: thisChart.yAxisDrilldownTitle.text });
                                            }
                                            else {
                                                if (this.yAxis.hasOwnProperty('title')) {
                                                    this.yAxis.setTitle({ text: thisChart.yAxisDrilldownTitle.text });
                                                }
                                            }
                                        }
                                    }
                                },
                                drillup: function (e) {
                                    var curConfig = this.options;
                                    var curTitle = curConfig.title.text;
                                    if (curConfig.hasOwnProperty('drilltitle1')) {
                                        if (curTitle == curConfig.drilltitle1.text) {
                                            this.setTitle({ text: this.oldTitle }, { text: this.oldSubtitle });
                                        }
                                        else {
                                            this.setTitle({ text: curConfig.drilltitle1.text }, { text: 'Drilldown for details' });
                                        }
                                    }
                                    if (this.hasOwnProperty('yAxis')) {
                                        if (Array.isArray(this.yAxis)) {
                                            this.yAxis[0].setTitle({ text: this.oldYaxisTitle });
                                        }
                                        else {
                                            if (this.yAxis.hasOwnProperty('title')) {
                                                this.yAxis.setTitle({ text: this.oldYaxisTitle });
                                            }
                                        }
                                    }
                                }
                            };
                        }
                        if (chartOptions.chart.type == 'pie') {
                            if (chartOptions.hasOwnProperty("plotOptions")) {
                                if (chartOptions.plotOptions.hasOwnProperty("pie")) {
                                    chartOptions.plotOptions.pie.dataLabels = {
                                        enabled: true,
                                        format: '{point.percentage:.1f} %',
                                        distance: -20,
                                        color: '#fafafa'
                                    };
                                    chartOptions.plotOptions.pie.showInLegend = true;
                                }
                            }
                            chartOptions.legend = {
                                enabled: true
                            };
                        }
                        if (chartOptions.chart.type == 'pyramid') {
                            if (chartOptions.hasOwnProperty("plotOptions")) {
                                if (chartOptions.plotOptions.hasOwnProperty("series")) {
                                    chartOptions.plotOptions.series.dataLabels = {
                                        enabled: true,
                                        format: '{point.y:,.0f}',
                                        softConnector: true,
                                        distance: -25,
                                        color: '#fafafa'
                                    };
                                    chartOptions.plotOptions.series.width = '100%';
                                }
                            }
                            chartOptions.title.x = 0;
                        }
                        if (chartOptions.chart.hasOwnProperty("charttype")) {
                            //console.log("chartOptions.chart.charttype ", chartOptions.chart.charttype);
                            if (chartOptions.chart.charttype == 'circle') {
                                seriesArr = [];
                                for (var j = 0; j < reportsData[i].series.length; j++) {
                                    for (var prop_3 in reportsData[i].series[j]) {
                                        if (reportsData[i].series[0].hasOwnProperty(prop_3)) {
                                            if (reportsData[i].series[0][prop_3] != null) {
                                                //if(Number(reportsData[i].series[0][prop]) > 0){
                                                var innerObj_2 = {
                                                    name: 'Farmers',
                                                    y: Number(''),
                                                    color: '#50C1BF'
                                                };
                                                //innerObj.name = prop;
                                                //console.log("reportsData[i].series[0][prop] ", reportsData[i].series[0][prop]);
                                                var x = reportsData[i].series[0][prop_3].split('/');
                                                //console.log("x ", x);
                                                innerObj_2.y = Number(x[0]);
                                                seriesArr.push(innerObj_2);
                                                // }
                                            }
                                        }
                                    }
                                }
                                chartOptions.series[0].data = seriesArr;
                            }
                        }
                        if (reportsData[i].conf[0].report_type == 'column_placement') {
                            seriesArr = [];
                            //console.log("reportsData[i] ", reportsData[i]);
                            delete chartOptions.xAxis.type;
                            chartOptions.xAxis.categories = reportsData[i].series.categories;
                            //console.log("reportsData[i].series ", reportsData[i].series);
                            //console.log("reportsData[i].series ", reportsData[i].series.series);
                            var seriesData = reportsData[i].series.series;
                            chartOptions.series = [];
                            //console
                            var colorsDataObject = [
                                {
                                    name: '',
                                    color: 'rgba(165,170,217,1)',
                                    data: [],
                                    pointPadding: 0.3,
                                    pointPlacement: -0.2
                                }, {
                                    name: '',
                                    color: 'rgba(126,86,134,.9)',
                                    data: [],
                                    pointPadding: 0.4,
                                    pointPlacement: -0.2
                                }, {
                                    name: '',
                                    color: 'rgba(248,161,63,1)',
                                    data: [],
                                    pointPadding: 0.3,
                                    pointPlacement: 0.2,
                                    yAxis: 1
                                }, {
                                    name: '',
                                    color: 'rgba(186,60,61,.9)',
                                    data: [],
                                    pointPadding: 0.4,
                                    pointPlacement: 0.2,
                                    yAxis: 1
                                }
                            ];
                            //console
                            for (var k = 0; k < seriesData.length; k++) {
                                //console.log("seriesData.name ", seriesData[k].name);
                                //console.log("seriesData.name ", seriesData[k].data);
                                // for(let j=0; j < chartOptions.series.length;j++){
                                //chartOptions.series[k].name = seriesData[k].name;
                                //chartOptions.series[k].data = seriesData[k].data;
                                colorsDataObject[k].name = seriesData[k].name;
                                colorsDataObject[k].data = seriesData[k].data;
                                // }
                            }
                            chartOptions.series = colorsDataObject;
                            //console.log(" column placement ", chartOptions);
                        }
                        if (reportsData[i].conf[0].report_type == 'column-stacked') {
                            seriesArr = [];
                            if (chartOptions.xAxis.hasOwnProperty('type')) {
                                delete chartOptions.xAxis.type;
                            }
                            chartOptions.xAxis.categories = reportsData[i].series.categories;
                            var seriesData = reportsData[i].series.series;
                            for (var k = 0; k < seriesData.length; k++) {
                                //     chartOptions.series[k].name = seriesData[k].name;
                                //     chartOptions.series[k].data = seriesData[k].data;
                                seriesData[k].data = seriesData[k].data.map(function (val, i) {
                                    return val === 0 ? null : val;
                                });
                                chartOptions.series.push(seriesData[k]);
                            }
                            //console.log(" column placement ", chartOptions);
                        }
                        chartOptions.reportTypeFrontend = 'init'; //currentType;
                        chartOptions.chart.backgroundColor = '#fafafa';
                        chartOptions.chart.spacingTop = 20;
                        //chartOptions.chart.spacingBottom = 20;
                        //added by Kiran for currency on zAxis
                        if (reportsData[i].reportId == 93 || reportsData[i].reportId == 87 || reportsData[i].reportId == 135) {
                            if (reportsData[i].hasOwnProperty('currency')) {
                                chartOptions.yAxis[1].title.text = chartOptions.yAxis[1].title.text + ' (' + reportsData[i].currency + ')';
                                if (reportsData[i].reportId == 87) {
                                    chartOptions.yAxis[0].title.text = chartOptions.yAxis[0].title.text + ' (' + reportsData[i].currency + ')';
                                }
                            }
                        }
                        if (reportsData[i].reportId == 49) {
                            if (reportsData[i].hasOwnProperty('currency')) {
                                chartOptions.yAxis.title.text = chartOptions.yAxis.title.text + ' (' + reportsData[i].currency + ')';
                            }
                        }
                        //console.log("reportsData[i].conf ",reportsData[i].conf);
                        if (reportsData[i].conf.length > 1) {
                            contextMenuOptions = [
                                'viewFullscreen',
                                // {
                                //   text: 'Change chart type ',
                                //   onclick: function(this,event){
                                //     let self = this;
                                //     parentScope01.changeChartType(self);
                                //   }
                                //   },
                                {
                                    textKey: 'downloadPDF',
                                    onclick: function () {
                                        this.exportChart({
                                            type: 'application/pdf'
                                        });
                                    }
                                },
                                {
                                    textKey: 'downloadPNG',
                                    onclick: function () {
                                        this.exportChart();
                                    }
                                },
                                {
                                    textKey: 'downloadXLS',
                                    onclick: function () {
                                        this.downloadXLS();
                                    }
                                }
                                // {
                                //   text: 'Remove',
                                //   onclick: function(this){
                                //   let self = this;
                                //   parentScope01.RemoveChart(self);
                                //   }
                                // }
                                // {
                                //   textKey: 'viewData',
                                //   text: 'Toggle data table',
                                //   onclick: function (this) {
                                //     if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                                //       this.dataTableDiv.style.display = 'none';
                                //     } else {
                                //       this.viewData();
                                //       this.dataTableDiv.style.display = '';
                                //     }
                                //   }
                                // }
                            ];
                        }
                        else {
                            if (!contextMenuOptions || !Array.isArray(contextMenuOptions)) {
                                contextMenuOptions = [];
                            }
                            contextMenuOptions = [
                                'viewFullscreen',
                                {
                                    textKey: 'downloadPDF',
                                    onclick: function () {
                                        this.exportChart({
                                            type: 'application/pdf'
                                        });
                                    }
                                },
                                {
                                    textKey: 'downloadPNG',
                                    onclick: function () {
                                        this.exportChart();
                                    }
                                },
                                {
                                    textKey: 'downloadXLS',
                                    onclick: function () {
                                        this.downloadXLS();
                                    }
                                }
                                // {
                                //   text: 'Remove',
                                //   onclick: function(this){
                                //   let self = this;
                                //   parentScope01.RemoveChart(self);
                                //   }
                                // }
                                // {
                                //   textKey: 'viewData',
                                //   text: 'Toggle data table',
                                //   onclick: function (this) {
                                //     if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                                //       this.dataTableDiv.style.display = 'none';
                                //     } else {
                                //       this.viewData();
                                //       this.dataTableDiv.style.display = '';
                                //     }
                                //   }
                                // }
                            ];
                        }
                        //console.log("contextMenuOptions ", contextMenuOptions);
                        chartOptions.exporting.buttons.contextButton.menuItems = contextMenuOptions;
                        chartOptions.exporting.filename = reportsData[i].name;
                        //chartOptions.exporting.buttons.contextButton.className ='highcharts-button-normal';
                        // chartOptions.legend = {
                        //   enabled: falseS
                        // };
                        // chartOptions.exporting.menuItemDefinitions= {
                        // 	viewFullscreen: {
                        // 		onclick: function () {
                        //       //this.fullscreen = new Highcharts.FullScreen(this.container);
                        //       Highcharts.FullScreen.init(this.renderTo);
                        //     	console.log('Test')
                        // 		}
                        // 	}
                        // }
                        //added bt KIran for export button position
                        chartOptions.navigation = {
                            buttonOptions: {
                                verticalAlign: 'top',
                                y: -5
                            }
                        };
                        //console.log("chartOptions ", chartOptions);
                        //console.log(" json sting ", JSON.stringify(chartOptions));
                        //console.log("chartOptions.series ", chartOptions.series);
                        //chartsList.push(chartOptions);
                        for (var j = 0; j < _this.chartReports.length; j++) {
                            if (reportsData[i].reportId == _this.chartReports[j].report_id) {
                                var newChartObj = {
                                    "id": _this.chartReports[j].report_id,
                                    "name": _this.chartReports[j].hc_report_hdr.hc_report_name[0].name,
                                    "type": _this.chartReports[j].report_type,
                                    "sortOrder": _this.chartReports[j].sort_order,
                                    "x": _this.chartReports[j].x_axis,
                                    "y": _this.chartReports[j].y_axis,
                                    "dim_col": _this.chartReports[j].hc_report_hdr.dim_col,
                                    "dim_row": _this.chartReports[j].hc_report_hdr.dim_row,
                                    "chartOptions": chartOptions
                                };
                                var KPIobject = { "reportId": reportsData[i].reportId, "col": reportsData[i].dim_col, "row": reportsData[i].dim_row, "type": 'Chart', "src": newChartObj };
                                _this.DashboardAddService.addChart(KPIobject, "view");
                            }
                        }
                        //this.dashboardEditObject.chartDataOptions.chartOptions = chartOptions;
                        _this.calcGridsterHeight();
                        //}
                    }
                }
                else {
                    if (_this.existingSelectedReports && _this.existingSelectedReports.length == 0) {
                        _this.chartElement01.nativeElement.innerHTML = '';
                        _this.hcRService.allCharts = [];
                    }
                }
                if (chartsList && chartsList.length > 0) {
                    //this.chartElement01.nativeElement.innerHTML = '';
                    //this.hcRService.allCharts = [];
                    for (var i = 0; i < chartsList.length; i++) {
                        //chartsList[i].exporting.buttons.contextButton.menuItems = contextMenuOptions;
                        var e = document.createElement("div");
                        var newElement = _this.chartElement01.nativeElement.appendChild(e);
                        // this.renderer.addClass(newElement, 'chartbox');
                        // this.renderer.addClass(newElement, 'chartbox-add');
                        if (chartsList[i].reportId == 88) {
                            _this.renderer.addClass(newElement, 'col-md-4');
                            _this.renderer.addClass(newElement, 'col-lg-8');
                        }
                        else {
                            _this.renderer.addClass(newElement, 'col-md-4');
                            _this.renderer.addClass(newElement, 'col-lg-4');
                        }
                        _this.renderer.addClass(newElement, 'pl-0');
                        _this.renderer.addClass(newElement, 'pr-0');
                        var f = document.createElement("div");
                        var newElement1 = newElement.appendChild(f);
                        _this.renderer.addClass(newElement1, 'chartBox-pdmr');
                        //Added by Kiran to replace math.random to windows.crypto
                        var array = new Uint32Array(1);
                        window.crypto.getRandomValues(array);
                        newElement1.setAttribute('id', +array[0].toString().slice(2, 6));
                        _this.hcRService.createChart(newElement1, chartsList[i]);
                        _this.exportBtns = true;
                        //var el = document.getElementById('test');
                        //var newEl = document.createElement('button');
                        //el.insertAdjacentHTML('afterend', '<button id="two">two</button>');
                    }
                    for (var i = 0; i < chartsList.length; i++) {
                        var e = document.createElement("div");
                        var newElement = _this.chartElement02.nativeElement.appendChild(e);
                        _this.renderer.setStyle(newElement, 'width', '48%');
                        _this.renderer.setStyle(newElement, 'display', 'inline-block');
                        _this.renderer.setStyle(newElement, 'padding', '1%');
                        _this.renderer.addClass(newElement, 'chartBox-pdmr');
                        //Added by Kiran to replace math.random to windows.crypto
                        var array = new Uint32Array(1);
                        window.crypto.getRandomValues(array);
                        newElement.setAttribute('id', +array[0].toString().slice(2, 6));
                        var newVal = Highcharts.chart(newElement, chartsList[i]);
                        _this.localChartsList.push(newVal);
                    }
                    // if(currentType == 'predefined'){
                    //   this.profilingFlag = true;
                    // }else{
                    //   //this.exportBtns = true;
                    //   this.customFlag = true;
                    // }
                    // }else{
                    //   //this.chartElement02.nativeElement.innerHTML = '';
                    //   //this.hcRService.allCharts = [];
                    //   for(let i=0;i<chartsList.length;i++){
                    //     let e = document.createElement("div");
                    //     let newElement = this.chartElement02.nativeElement.appendChild(e);
                    //     this.renderer.addClass(newElement, 'chartbox');
                    //     this.renderer.addClass(newElement, 'chartbox-add');
                    //     this.renderer.addClass(newElement, 'col-lg-6');
                    //     newElement.setAttribute('id',+Math.random().toString().slice(2, 6));
                    //     this.hcRService.createChart(newElement, chartsList[i]);
                    //     this.exportBtns = true;
                    //     this.customFlag = true;
                    //   }
                    // }
                    _this.checkChartsAvailable = _this.hcRService.allCharts;
                    //this.selectedReportsMultiSelect = [];
                    //this.selectedReportsMultiSelectCustom = [];
                    //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
                }
            }
            else {
                //this.existingSelectedReports = [];
            }
            _this.spinner.hide();
        });
    };
    DashboardViewReportsComponent.prototype.downloadAsPDF = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var charts;
            return tslib_1.__generator(this, function (_a) {
                charts = this.localChartsList;
                //let charts = this.layout;
                Highcharts.exportCharts(charts, {
                    type: 'application/pdf'
                });
                return [2 /*return*/];
            });
        });
    };
    DashboardViewReportsComponent.prototype.RemoveChart = function (itemScope) {
        parentScope01.removeFromList(itemScope.id);
        itemScope.renderTo.remove();
        //self.renderTo.remove();
        //self.destory();
        //self.remove();
    };
    DashboardViewReportsComponent.prototype.removeFromList = function (item) {
        var charts = this.hcRService.allCharts;
        var newCharts = charts.filter(function (item1) { return item1.id != item; });
        this.hcRService.allCharts = newCharts;
        this.localChartsList = newCharts;
    };
    DashboardViewReportsComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    DashboardViewReportsComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    DashboardViewReportsComponent.prototype.goToDashboards = function () {
        var url = "charts/dashboards/" + this.projectId;
        this.router.navigate([url]);
    };
    DashboardViewReportsComponent.prototype.addNewDashboard = function () {
        var url = "charts/dashboards/add/" + this.projectId;
        this.router.navigate([url]);
    };
    DashboardViewReportsComponent.prototype.editDashboard = function (currentItem) {
        var url = "charts/dashboards/edit/" + currentItem.id + '/' + this.projectId;
        this.router.navigate([url]);
    };
    DashboardViewReportsComponent.prototype.onResize = function (event) {
        var gridsterWidth = document.getElementById("gridsterBase").offsetWidth;
        this.chartWidth = (gridsterWidth / 3) - 15;
    };
    return DashboardViewReportsComponent;
}());
export { DashboardViewReportsComponent };
