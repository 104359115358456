import { Component, OnInit, TemplateRef } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../services/project.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FarmerService } from '../../services/farmer.service';
import { TrainingService } from '../../services/training.service';
import Compressor from 'compressorjs';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-add-training',
  templateUrl: './add-training.component.html',
  styleUrls: ['./add-training.component.css']
})
export class AddTrainingComponent implements OnInit {
  isOnline: Observable<boolean>;
  project:any;
  projectId;
  type;
  isCertificate = false;
  groundForm:FormGroup;
  public isTrainingOpen = false;
  isCertificateOpen = false;
  isExpiryOpen = false;
  public dateFormat = 'DD-MMM-YYYY';
  isDesktopDevice;
  uploadedTrainingFiles = [];
  trainers = [];
  trainingCertifications;
  trainings;
  certifications;  
  searchTextFarmers;
  confirmFarmerModal;
  loadingFarmers = false;
  managingFarmersForGroup;
  selectedFarmersCopy = [];
  tooltipText;
  farmers = [];
  selectedFarmers = [];
  selectedTrainings = [];
  selectedSpouse = [];
  selectedChild = [];
  selectDeselectLabels;
  public confirmModalPopup = true;
  public confirmPopup:BsModalRef;
  manageFarmersModal: BsModalRef;
  page = 1;
  itemsPerPage = 10;
  farmersListPage = 1;
  public searchText: any;
  public previousPaginationLabel;
  public nextPaginationLabel;
  groupFarmers = [];
  allGroups;
  disableAddButton = true;
  disableCertificateAddButton = true;
  totalSelect = [];
  maxDate = new Date();
  minDate = new Date();
  globalFarmerIDs = [];
  isAscending = true;
  isAscendingCode = true;
  isAscendingName = true;
  isAscendingAge = true;
  isAscendingSex = true;
  isAscendingOfficialID = true;
  isAscendingFarmer = true;
  isAscendingIdFarmer = true;
  isAscendingNameFarmer = true;
  isAscendingTelFarmer = true;
  isAscendingDateFarmer = true;
  isAscendingAgeFarmer = true;
  isAscendingSexFarmer = true;
  isAscendingVillageFarmer = true;
  isAscendingVCFarmer = true;
  currentUser = this.userService.getCurrentUser();
  fileToDelete;
  public fileDeletePopup: BsModalRef;
  constructor(private readonly _route: ActivatedRoute, 
    private readonly router: Router,
    private readonly projectService: ProjectService,
    private readonly deviceService: DeviceDetectorService,
    private readonly formBuilder: FormBuilder,
    private readonly commonService: CommonService,
    public toastrService: ToastrService, 
    private readonly modalService: BsModalService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly trainingService: TrainingService,
    private readonly farmerService: FarmerService,
    private readonly translate: TranslateService,
    private readonly userService: UserService,
  ) { 
    this.isOnline = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
  
    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });
  
    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });
    this.translate.get('select_deselect_object').subscribe(result => {
      this.selectDeselectLabels = result;
    });
  }

  ngOnInit() {
    this.projectId = +this._route.snapshot.params['id'];
    this.type = this._route.snapshot.params['type'];
    if(this.type == "certification"){
      this.isCertificate = true;
    }
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.getTrainingCertifcationList();
    this.createTrainingForm();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  async getTrainingCertifcationList(){    
    this.spinner.show();
    const request = await Promise.all([
      this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
      this.trainingService.get_all_training_certification(this.projectId).toPromise(),
      this.trainingService.get_all_trainer(this.projectId).toPromise(),
      this.trainingService.getAllGroups(this.projectId).toPromise()
    ]);
    if (request[0]['msg'] === 'success') {
      this.project = request[0]['data'][0];
    }
    if (request[1]['msg'] === 'success'){
      this.trainingCertifications = request[1]['data'];
    }
    if(this.currentUser.role.id <= 3){
      if (request[2]['msg'] === 'success'){
        this.trainers = request[2]['data'];
      }
    } else {
      const userObj = {
        users_id: this.currentUser.id,
        users : {
          name: this.currentUser.name
        }
      }
      this.trainers.push(userObj);
    }
    if (request[3]['msg'] === 'success'){
      this.allGroups = request[3]['data'];
    }
    this.trainings = this.trainingCertifications.filter(item => 
      item.training_certification_master.training_certification_type == 'Training' && item.training_certification_master.status == 'Active');
    this.certifications = this.trainingCertifications.filter(item => 
      item.training_certification_master.training_certification_type == 'Certification' && item.training_certification_master.status == 'Active');
    this.spinner.hide();
  }

  

  createTrainingForm(){
    this.groundForm = this.formBuilder.group({
      trainingName: new FormControl('0', [Validators.required]),
      trainerName: new FormControl('0', [Validators.required]),
      description: new FormControl('', [Validators.maxLength(1000)]),
      trainingDate: new FormControl(null, [Validators.required]),
      group: new FormControl('0', [Validators.required]),
      certificateName : new FormControl('0', [Validators.required]),
      certificationDate : new FormControl(null),
      expiryDate : new FormControl(null)
    });
  }

  groupSelected(e){
    const groupID = this.groundForm['value'].group;
    const exists = this.totalSelect.find(item => item.id == groupID);
    if(exists){
      this.toastrService.warning("Group already selected!");
      return
    }
    if(groupID !=  0){
      this.spinner.show();
      this.trainingService.getGroupFarmers(groupID).subscribe(result => {
        if (result['msg'] === 'success') {
          let data = result['data'];
          if(data.length == 0){
            this.spinner.hide();
            this.commonService.showToast('warning', 'no_farmer_found_this_group');
            return;
          }
          data = data.filter(item => !item.is_discontinued);
          
          //set unique farmer data
          this.groupFarmers = this.groupFarmers.concat(data);
          this.groupFarmers = this.groupFarmers.filter((v,i,a)=>a.findIndex(t=>(t.farmer_id===v.farmer_id))===i);
          
          //create object of groupid and all farmer ids in thar group          
          const allFarmerIDs = [];
          for(const item of data){
            allFarmerIDs.push(item.farmer_id);
          }
          const obj = {
            id : groupID,
            farmerIDs : allFarmerIDs
          }
          this.globalFarmerIDs.push(obj);
          //add in list of selected groups
          const groupDetails = this.allGroups.find(item => item.project_group_mapping_id == groupID);
          this.totalSelect.push({name: groupDetails.training_certification_group.name, id: groupID});
          this.commonService.showToast('success', 'unique_farmers_added_successfully', {});
          this.groundForm.controls['group'].setValue(0);          
          if(this.isCertificate){
            this.showCertificateAddButton();
          } else {
            this.showAddButton();
          }
          this.spinner.hide();
        }
      });
    }
  }

  removeNewGroup(id) {
    let curGroupIDs = this.globalFarmerIDs.find(item => item.id == id).farmerIDs;
    console.log('curGroupIDs', curGroupIDs);
    console.log('globalFarmerIDs', this.globalFarmerIDs);
    for(const item of curGroupIDs){
      for(const group of this.globalFarmerIDs){
        if(group.id != id){
          if(group.farmerIDs.indexOf(item) > -1){
            curGroupIDs = curGroupIDs.filter(item2 => item2 !== item)
          }
        }
      }
    }
    for(const id of curGroupIDs){
      this.groupFarmers = this.groupFarmers.filter(item => item.farmer_id != id);
      this.selectedTrainings = this.selectedTrainings.filter(item => item != id);
    }
    this.totalSelect = this.totalSelect.filter(item => item.id != id);
    this.globalFarmerIDs = this.globalFarmerIDs.filter(item => item.id != id);
    this.commonService.showToast('success', 'group_farmers_removed_successfully');
  }

  saveUpdateTraining(){
    this.spinner.show();
    const trainingName = this.groundForm['value'].trainingName;
    const trainerName = this.groundForm['value'].trainerName;
    const date = new Date(this.groundForm['value'].trainingDate);
    const trainingDate = `${date.getFullYear()}-${('0' + (date.getMonth()+1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    const description = this.groundForm['value'].description;
    const farmerData = [];
    for(const item of this.groupFarmers){
      const obj = {
        "farmer_id": item.farmer_id,
        "project_group_actor_mapping_id": item.project_group_actor_mapping_id,
        "training_attended": this.selectedTrainings.indexOf(item.farmer_id) > -1 ? 1 : 0,
        "spouse_attended": this.selectedSpouse.indexOf(item.farmer_id) > -1 ? 1 : 0,
        "children_attended_above_16": this.selectedChild.indexOf(item.farmer_id) > -1 ? 1 : 0
      }
      farmerData.push(obj);
    }
    const documentData = this.uploadedTrainingFiles;
    const trainingToAdd = {
      "project_training_certification_mapping_id": trainingName,
      "trainer_id": trainerName,
      "training_date": trainingDate,
      "description": description,
      "status": "Active",
      "training_recording_dtl": farmerData,
      "training_documents_dtl": documentData
    }
    this.trainingService.saveTrainingDetails(trainingToAdd).subscribe(
      (result) => {    
        this.commonService.showToast('success', 'training_created_successfully', {});
        const url = `training-management/${this.projectId}`;
        this.router.navigate([url]);        
        this.spinner.hide();
      },
      (error) => {   
        this.spinner.hide();
      }
    );
  }

  saveUpdateCertificate(){
    this.spinner.show();
    const certificateName = this.groundForm['value'].certificateName;
    const date = new Date(this.groundForm['value'].certificationDate);
    const certificationDate = `${date.getFullYear()}-${('0' + (date.getMonth()+1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    const expiryDate = this.groundForm['value'].expiryDate;
    const description = this.groundForm['value'].description;
    const farmerData = [];
    for(const item of this.groupFarmers){
      const obj = {
        "farmer_id": item.farmer_id,
        "project_group_actor_mapping_id": item.project_group_actor_mapping_id,
        "certified": this.selectedTrainings.indexOf(item.farmer_id) > -1 ? 1 : 0
      }
      farmerData.push(obj);
    }
    const certificationToAdd = {
      "project_training_certification_mapping_id": certificateName,
      "certification_date": certificationDate,
      "expiry_date": expiryDate,
      "description": description,
      "certification_recording_dtl": farmerData
    }
    this.trainingService.saveCertificationDetails(certificationToAdd).subscribe(
      (result) => {        
        this.commonService.showToast('success', 'certification_created_successfully', {});
        const url = `training-management/${this.projectId}`;
        this.router.navigate([url]);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  async manageFarmers(project, template) {
    this.searchTextFarmers = ""
    this.confirmFarmerModal = true;
    if (!this.loadingFarmers) {
      this.spinner.show();
      this.loadingFarmers = true;
      this.managingFarmersForGroup = project;
      
      const requests = await Promise.all([
        this.farmerService.getFarmersByProjectBasicData(project.id).toPromise(),
      ]);
      
      this.loadingFarmers = false;
      this.spinner.hide();
      
      if (requests[0]['msg'] === 'success') {
        this.farmers = requests[0].data.filter(item => !item.is_discontinued);
        for(const item of this.farmers){
          item.village_name = item.region_village.village_name
        }
        this.sortFarmers();
      }
      for(const id of this.groupFarmers){
        this.selectedFarmersCopy.push(id.farmer_id);
      }  
      if (this.farmers.length === 0) {
        this.commonService.showToast('info', 'no_farmers_regd', {});
      } else {
        this.farmersListPage = 1;
        this.tooltipText = undefined;
        this.manageFarmersModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
      }
    }
  }

  confirmManageFarmers(){
    for(const item of this.selectedFarmers){
      const checkFarmerExist = this.groupFarmers.find(item2 => item2.farmer_id == item);
      if(!checkFarmerExist){
        const farmer = this.farmers.find(item2 => item2.id == item);
        const obj = {
          "age": farmer.age,
          "farmer_code": farmer.farmer_code,
          "farmer_id": farmer.id,
          "name": farmer.name,
          "official_id": farmer.official_id,
          "project_group_actor_mapping_id": null,
          "sex": farmer.sex
        }          
        this.groupFarmers.push(obj);
      }
    }
    this.commonService.showToast('success', 'unique_farmers_added_successfully', {});
    this.declineManageFarmers();
  }

  cancelManageFarmers(){
    let isEqual = false;
    for(const val of this.selectedFarmers){
      if(this.selectedFarmersCopy.indexOf(val) < 0){
        isEqual = true;
      }
    }
    if(isEqual){
      this.confirmFarmerModal = false;
    } else {
      this.declineManageFarmers();
    }
  }

  declineManageFarmers(): void {
    this.selectedFarmers = [];
    this.farmers = [];
    this.managingFarmersForGroup = undefined;
    if (this.manageFarmersModal) {
      this.manageFarmersModal.hide();
    }
  }

  confirmCloseFarmerNo(){
    this.confirmFarmerModal = true;
  }

  // Toggle a particular farmer's selection status
  toggleThisFarmerSelect(id): void {
    if (this.selectedFarmers.indexOf(id) > -1) {
      this.selectedFarmers = this.selectedFarmers.filter(item => item !== id);
    }
    else {
      this.selectedFarmers.push(id);
    }
    this.setTooltipText();
  }

  toggleThisTrainingSelect(id): void {
    if (this.selectedTrainings.indexOf(id) > -1) {
      this.selectedTrainings = this.selectedTrainings.filter(item => item !== id);
    }
    else {
      this.selectedTrainings.push(id);
    }
  }

  toggleThisSpouseSelect(id): void {
    if (this.selectedSpouse.indexOf(id) > -1) {
      this.selectedSpouse = this.selectedSpouse.filter(item => item !== id);
    }
    else {
      this.selectedSpouse.push(id);
      this.selectedTrainings.push(id);
    }
  }
  toggleThisChildSelect(id): void {
    if (this.selectedChild.indexOf(id) > -1) {
      this.selectedChild = this.selectedChild.filter(item => item !== id);
    }
    else {
      this.selectedChild.push(id);
      this.selectedTrainings.push(id);
    }
  }
  // Select / deselect all farmers in the array
  toggleAllFarmers(): void {
    // If all farmers are already selected, then deselect all farmers
    if (this.selectedFarmers.length === this.farmers.length) {
      this.selectedFarmers = [];
    }
    // If all farmers are NOT already selected, select all the farmers
    else {
      this.selectedFarmers = [];
      for (const val of this.farmers) {
        this.selectedFarmers.push(val.id);
      }
    }
    this.setTooltipText();
  }

  toggleAllFarmersTrainings(): void {
    // If all farmers are already selected, then deselect all farmers
    if (this.selectedTrainings.length === this.groupFarmers.length) {
      this.selectedTrainings = [];
    }
    // If all farmers are NOT already selected, select all the farmers
    else {
      this.selectedTrainings = [];
      for (const val of this.groupFarmers) {
        this.selectedTrainings.push(val.farmer_id);
      }
    }
  }

  toggleAllSpouses(): void {
    // If all farmers are already selected, then deselect all farmers
    if (this.selectedSpouse.length === this.groupFarmers.length) {
      this.selectedSpouse = [];
    }
    // If all farmers are NOT already selected, select all the farmers
    else {
      this.selectedSpouse = [];
      for (const val of this.groupFarmers) {
        this.selectedSpouse.push(val.farmer_id);
        this.selectedTrainings.push(val.farmer_id);
      }
    }
  }
  toggleAllChildren(): void {
    // If all farmers are already selected, then deselect all farmers
    if (this.selectedChild.length === this.groupFarmers.length) {
      this.selectedChild = [];
    }
    // If all farmers are NOT already selected, select all the farmers
    else {
      this.selectedChild = [];
      for (const val of this.groupFarmers) {
        this.selectedChild.push(val.farmer_id);
        this.selectedTrainings.push(val.farmer_id);
      }
    }
  }

  setTooltipText(): void {
    if (this.farmers && this.selectedFarmers) {
      if (this.selectedFarmers.length === this.farmers.length) {
        // Deselect farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.deselect} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.deselect} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      } else {
        // Select farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.select} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.select} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      }
    }
  }

  sortFarmers(): void {
    this.farmers.sort((a, b) => {
      if (a.cr_date < b.cr_date) {
        return 1;
      }
      if (a.cr_date > b.cr_date) {
        return -1;
      }
      return 0;
    });
  }

  getGridFiltersFarmers() {
    const gridColums = ['farmer_code', 'name', 'tel_number', 'official_id', 'age', 'village_name','village_collectors', 'cr_date'];
    const finalObj = {};
    if (this.farmers === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchTextFarmers;
      });
    }
    return finalObj;
  }

  /* file upload */
  handleTrainingFileInput(file: FileList) {
    // // debugger;
    const fileItem = file.item(0);
    
    const msg = this.commonService.ValidateFile(fileItem);

    if (msg && msg.length > 0) {
      // this.toastrService.warning(msg);
      this.commonService.showToast('warning', msg, {});
      return;
    }
    new Compressor(fileItem, {
      quality: environment.imageCompress.quality,
      maxWidth:environment.imageCompress.maxWidth,
      maxHeight:environment.imageCompress.maxHeight,
      mimeType:environment.imageCompress.mimeType,
      convertSize:environment.imageCompress.convertSize,
      success(result) {
        setFarmerImageData(result);
      },
      error(err) {
        console.log(err.message);
      },
    });

    const setFarmerImageData = (result) => {
      this.commonService.blobToBase64(result).then((imageData)=>{
        const obj = {
          "image_file": imageData,
          "image_for" : fileItem.name
        }
        this.uploadedTrainingFiles.push(obj)
      });
    };


  }

  showAddButton(){
    if(this.groundForm['value'].trainingName != 0 && this.groundForm['value'].trainerName != 0 && this.groundForm['value'].trainingDate != null && this.totalSelect.length > 0){
      this.disableAddButton = false;
    } else {
      this.disableAddButton = true;
    }
  }
  showCertificateAddButton(){
    if(this.groundForm['value'].certificateName != 0 && this.groundForm['value'].certificationDate != null && this.totalSelect.length > 0 ){
      this.disableCertificateAddButton = false;
    } else {
      this.disableCertificateAddButton = true;
    }
  }
  removeTrainingFile(fileName, template){
    const file ={ 
      isOld : false,
      file : fileName
    }
    this.fileToDelete = file;
    this.fileDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false});
  }
  confirmFileDelete(decision){
    if(decision == "Yes"){
        const x = this.uploadedTrainingFiles.find(item => item.image_for == this.fileToDelete.file);
        const index = this.uploadedTrainingFiles.indexOf(x);
        if (index !== -1) {
          this.uploadedTrainingFiles.splice(index, 1);
          this.fileDeleteReset();
        }
    } else {
      this.fileDeleteReset();
    }
  }
  fileDeleteReset(){
    this.fileDeletePopup.hide();
    this.fileToDelete = null;
  }

  exitClicked(myForm: FormGroup, template: TemplateRef<any>){
    if(myForm.dirty){
      this.confirmModalPopup = false;
      this.confirmPopup = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
    }else{
      const url = 'training-management/' + this.projectId;
      this.router.navigate([url]);
    }
  } 

  confirmExitModal(item){
    if(item === 'Yes'){
        this.confirmModalPopup = true;
        if (this.confirmPopup) {
          this.confirmPopup.hide();
        }
        const url = 'training-management/' + this.projectId;
        this.router.navigate([url]);
    }else if(item === 'No'){
      this.confirmModalPopup = true;
      if (this.confirmPopup) {
        this.confirmPopup.hide();
      }
    }
  }

  getGridFilters() {
    //new fields added for search village_name and vc_name
    const gridColums = ['farmer_code', 'name', 'age', 'sex','official_id'];
    const finalObj = {};
    if (this.farmers === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  sortBy(sortOn: string) {
    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    this.isAscendingCode = sortBy !== 'farmer_code';
    this.isAscendingName = sortBy !== 'name';
    this.isAscendingAge = sortBy !== 'age';
    this.isAscendingSex = sortBy !== 'sex';
    this.isAscendingOfficialID = sortBy !== 'official_id';
    // Sort of new fields added
    if (sortBy === 'farmer_code' || sortBy === 'official_id' || sortBy === 'name' || sortBy === 'age' || sortBy === 'sex') {
      this.groupFarmers.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    }

  }

  descendic(sortBy: string) {
    this.isAscending = true;

    this.isAscendingCode = sortBy === 'farmer_code';
    this.isAscendingName = sortBy === 'name';
    this.isAscendingAge = sortBy === 'age';
    this.isAscendingSex = sortBy === 'sex';
    this.isAscendingOfficialID = sortBy === 'official_id';
    // Sort of new fields added
    if (sortBy === 'farmer_code' || sortBy === 'official_id' || sortBy === 'name' || sortBy === 'age' || sortBy === 'sex') {
      this.groupFarmers.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    }
  }

  farmerSortBy(sortOn: string) {
    this.isAscendingFarmer ? this.ascendicFarmer(sortOn) : this.descendicFarmer(sortOn);
  }

  ascendicFarmer(sortBy: string) {
    this.isAscendingFarmer = false;

    this.isAscendingIdFarmer = sortBy !== 'id';
    this.isAscendingNameFarmer = sortBy !== 'name';
    this.isAscendingTelFarmer = sortBy !== 'tel_number';
    this.isAscendingDateFarmer = sortBy !== 'cr_date';
    this.isAscendingAgeFarmer = sortBy !== 'age';
    this.isAscendingSexFarmer = sortBy !== 'sex';
    // Sort of new fields added
    this.isAscendingVillageFarmer = sortBy !== 'village_name';
    this.isAscendingVCFarmer = sortBy !== 'village_collectors';
    this.isAscendingOfficialID = sortBy !== 'official_id';
    // Sort of new fields added
    if (sortBy === 'name') {
      this.farmers.sort((a, b) => {
        if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
          return 1;
        }
        if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
      this.farmers.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'village_name') {
      this.farmers.sort((a, b) => {
        if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
          return 1;
        }
        if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }

  descendicFarmer(sortBy: string) {
    this.isAscendingFarmer = true;

    this.isAscendingIdFarmer = sortBy === 'id';
    this.isAscendingNameFarmer = sortBy === 'name';
    this.isAscendingTelFarmer = sortBy === 'tel_number';
    this.isAscendingDateFarmer = sortBy === 'cr_date';
    this.isAscendingAgeFarmer = sortBy === 'age';
    this.isAscendingSexFarmer = sortBy === 'sex';
    // Sort of new fields added
    this.isAscendingVillageFarmer = sortBy === 'village_name';
    this.isAscendingVCFarmer = sortBy === 'village_collectors';
    this.isAscendingOfficialID = sortBy === 'official_id';
    // Sort of new fields added
    if (sortBy === 'name') {
      this.farmers.sort((a, b) => {
        if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
          return 1;
        }
        if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
      this.farmers.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'village_name') {
      this.farmers.sort((a, b) => {
        if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
          return 1;
        }
        if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  trackBytraining(index, dataItem) {
    return dataItem.id;
  }
  trackBytrainer(index, dataItem) {
    return dataItem.id;
  }

}
