import { Component, OnInit } from '@angular/core';
import { ForgetpasswordService } from '../../services/forgetpassword.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-resetuserid',
  templateUrl: './resetuserid.component.html',
  styleUrls: ['./resetuserid.component.css']
})
export class ResetuseridComponent implements OnInit {
  emailid:any;
  isDisabled = false;
  public resetUserIDForm: FormGroup;

  // flag set in two components
  flagEn = false;
  flagEs = false;
  flagFr = false;
  flagZh = false;
  flagMg = false;
  flagTa = false;

  constructor(
    private readonly router: Router,
    private readonly forgetpasswordService: ForgetpasswordService,
    public toastrService: ToastrService,
    private readonly formBuilder: FormBuilder,
    private readonly commonService: CommonService,
    private readonly loc: Location) {

    const localLang = this.loc.prepareExternalUrl('').replace(/\//g,'');

      if (localLang === 'en') {
        this.flagEn = true;
      }
      if (localLang === 'es') {
          this.flagEs = true;
      }
      if (localLang === 'fr') {
          this.flagFr = true;
      }
      if (localLang === 'zh') {
        this.flagZh = true;
      }
      if (localLang === 'ta') {
        this.flagTa = true;
      }
      if (localLang === 'mg') {
        this.flagMg = true;
      }
      if (localLang === 'en') {
        this.flagEn = true;
      }

    }

  ngOnInit() {
    this.resetUserIDForm = this.formBuilder.group({
      emailID: new FormControl('', [Validators.required, Validators.email])
    });
  }

  sendEmail(form:any) {
    this.isDisabled = true;
    if (form.valid) {
      this.forgetpasswordService.sendResetUserIDEmail(form.value).subscribe(forgetpwddata => {
          this.commonService.showToast('warning', forgetpwddata.code, {});
          this.isDisabled = false;
          this.router.navigate(['']);
          this.resetUserIDForm.setValue({
            emailID:''
          });
      }, (err) => {
      });
    } else {
      this.isDisabled = false;
    }
  }

}
