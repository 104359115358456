import { Component, OnInit, AfterViewChecked, ViewChild, TemplateRef, Inject, ChangeDetectorRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { Observable } from 'rxjs/Observable';
import { merge, of, fromEvent, Subject } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CommonService } from '../../services/common.service';
import { LOCALE_ID } from '@angular/core';

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ProjectService } from '../../services/project.service';
import { validateConfig } from '@angular/router/src/config';
import { UserService } from '../../services/user.service';

import { environment } from '../../../environments/environment';
import { FarmerService } from '../../services/farmer.service';
import { SurveyService } from '../../services/survey.service';
declare var idbApp: any;
declare var idbAppPhoto: any;
import $ from 'jquery';
import { element } from 'protractor';
import { FarmerCropDetail } from '../../models/farmerReg';
import { ThrowStmt } from '@angular/compiler';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import Compressor from 'compressorjs';
import { DataConsentService } from '../../services/data-consent.service';
// import {MapsAPILoader} from '@agm/core';
import {} from 'googlemaps';
declare var serviceWorkerVar;
declare var google: any;

@Component({
  selector: 'app-farmerdetails',
  templateUrl: './farmerdetails.component.html',
  styleUrls: ['./farmerdetails.component.css']
})
export class FarmerdetailsComponent implements OnInit {
  public tabsList:any;
  public selectedTab;
  online$: Observable<boolean>;
  isOnline: boolean = navigator.onLine;
  public farmerRegQuestions;
  public farmer;
  public enterValueObject:any;
  myForm: FormGroup;
  master;
  projectId;
  genderData:any = [];
  educationLevelData:any = [];
  schoolLevelData:any = [];
  project:any;
  public isNextClicked:boolean = true;
  currentTabIndex = 0;
  public uploadingFileName;
  public fileUploadMsg;
  public myFormStatus:boolean= false;
  public uploadingFileNameFlag:boolean = false;
  currentUser = this.userService.getCurrentUser();
  public rawFarmerId;
  farmerMainObject;
  public showSpinner = false;
  deviceInfo;
  isMobile;
  isTablet;
  isDesktopDevice;
  farmerPhotoPath;
  private subject = new Subject<boolean>();
  @ViewChild('confirmTemplate') confirmTemplate: TemplateRef<any>;
  confirmModal: BsModalRef;
  imageUrl = 'assets/images/photo.webp';
  fileToUpload: File = null;
  noFileChosenDefault;
  public confirmModalPopup: boolean = true;
  public confirmPopup:BsModalRef;
  villageAreaList = [];
  public f1Submitted: boolean = false;
  usersServiceComponentMappings;
  ecosystemServicesComponentsMapping;
  canDoProfiling = false;
  farmerUpdatedOffline = false;
  public farmerImageData: any;
  unitTrustsPnl = 0;
  isCertificate = false;
  trainingMissed = false;
  canAddTraining = false;
  trainingDetailsTabIndex = 1;
  farmDetails = 2;
  farmerConsent;
  //for google map
  @ViewChild('map') mapElement: any;
  map: any;
  mapDisplay = false;
  drawingManager: any;
  //pointList: { lat: number; lng: number }[] = [];
  //selectedArea = 0;
  loc_coordinates;
  farms = [];
  originalFarms = [];
  selectedFarm;
  updatedFarmName;
  noNewFarm = false;
  addRecordingModal;
  farmRecording = {
    start: '',
    intermediate: [],
    end: ''
  };
  drawAreaOnMap;
  walkingOnTheEdge;
  //below vars are for agm core
  lat = 51.678418;
  lng = 7.809007;
  markerlat;
  markerlng;
  potential;
  farmDeletePopup;
  farmToDelete;
  farmImageData;
  public uploadingFarmFileName;
  canDoGeoplotting = false;
  validFarmerName;
  farmFileToUpload: File = null;
  uploadedFarmImage;
  farmTypeSelected = 0;
  currentFarmerOriginalPotential;
  farmTypeNotSelected;
  farmSavedSuccessfully;
  farmDeletedSuccessfully;
  annualForecastUpdatedSuccessfully;
  campaignId;
  @HostListener('window:online', ['$event'])
   onOnline(event: Event){
    this.isOnline = true;
   }
  @HostListener('window:offline', ['$event'])
   onOffline(event: Event){
    this.isOnline = false;
   }
  constructor(
    private readonly _route: ActivatedRoute, public toastrService: ToastrService,
    private readonly commonService: CommonService, private formBuilder: FormBuilder,
    private readonly userService: UserService, private readonly router: Router,
    private translate: TranslateService,
    private readonly farmerService: FarmerService, private modalService: BsModalService,
    private readonly spinner: Ng4LoadingSpinnerService, private readonly projectService: ProjectService,
    private localeService: BsLocaleService, @Inject(LOCALE_ID) private locale: string, private cd: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private surveyService: SurveyService,
    private readonly dataConsentService:DataConsentService
    // private mapsAPILoader: MapsAPILoader
  ) { 
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
    
    this.localeService.use(this.locale);
    this.translate.get('enter_value_object').subscribe(result => {
      this.enterValueObject = result;
      // console.log('this.enterValueObject => ', this.enterValueObject);
    });
    this.translate.get('file_upload_component_default_txt').subscribe(result => {
      this.noFileChosenDefault = result; // default text for the file input component
    });
    this.translate.get('farm_type_not_selected').subscribe(result => {
      this.farmTypeNotSelected = result;
    });
    this.translate.get('Farm_saved_successfully').subscribe(result => {
      this.farmSavedSuccessfully = result;
    });
    this.translate.get('farm_deleted_successfully').subscribe(result => {
      this.farmDeletedSuccessfully = result;
    });
    this.translate.get('annual_forecast_updated_successfully').subscribe(result => {
      this.annualForecastUpdatedSuccessfully = result;
    });
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  async ngOnInit() {
    const farmerId = +this._route.snapshot.params['id'] || 0;
    this.projectId = +this._route.snapshot.params['projectId'];
    this.rawFarmerId = this._route.snapshot.params['id'];
    this.uploadingFileName = '';
    this.farmerMainObject = {};
    this.showSpinner = true; 
    this._route.queryParamMap.subscribe(async queryParams => {
      if (queryParams.get('campaignId') && +queryParams.get('campaignId') !== 0 && !isNaN(+queryParams.get('campaignId'))) {
        this.campaignId = queryParams.get('campaignId');
      }
    })
    //console.log("this.uploadingFileNameFlag ",this.uploadingFileNameFlag);
    if (navigator.onLine) {
      const requests = await Promise.all([this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),]);
      this.farmer = requests[0].data.find(x => x.id == farmerId);
      this.farmerPhotoPath = this.farmer.photo_path;
      this.master = this._route.snapshot.data.farmerData.master.data;
      this.project = this._route.snapshot.data.farmerData.project;

      this._route.queryParamMap.subscribe(async queryParams => {
        if (queryParams.get('trainingMissed') && queryParams.get('trainingMissed') === 'true') {
          this.trainingMissed = true;
        }
      })

      this.farmerService.getFarmerManagementTabs().subscribe(response =>{
        this.tabsList = response.data;
        //console.log("this.tabsList  ", this.tabsList);
        this.selectedTab = this.tabsList[0];
  
        const farmerRegData:any = [];
        for(let i=0; i<this.master.length;i++){
          if(this.master[i].item_tab == this.tabsList[0].tab_key){
              farmerRegData.push(this.master[i]);           
          }
        }
        
        //console.log("farmerRegData ", farmerRegData);
        
        for(let i=0;i<farmerRegData.length;i++){
          if(farmerRegData[i].item_code == 'sex'){
            this.genderData.push(farmerRegData[i]);
          }
          else if(farmerRegData[i].item_code == 'education_level'){
            this.educationLevelData.push(farmerRegData[i]);
          }
          else if(farmerRegData[i].item_code == 'school_level'){
            this.schoolLevelData.push(farmerRegData[i]);
          }
        }
  
        //console.log("this.genderData ", this.genderData);
        //console.log("this.educationLevelData ", this.educationLevelData);
  
        this.getSelectedTabData();
      });
      this.setVillageAreaList();
    } else {

      const farmertmpobj = await idbApp.getAllProjects('farmerstmp');
      // console.log('farmertmpobj', farmertmpobj);

      let farmerFromIDB;
      if (farmertmpobj && farmerId && farmerId > 0) {
        farmerFromIDB = farmertmpobj.find(x => x.id == farmerId);
      } else {
        farmerFromIDB = farmertmpobj.find(x => x.tmp_id == this.rawFarmerId + '0');
        if (!farmerFromIDB) {
          farmerFromIDB = farmertmpobj.find(x => x.tmp_id == this.rawFarmerId);
        }
      }
      const requests = await Promise.all([
        this.farmerService.GetFarmerMaster().toPromise(),
        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
        this.farmerService.getFarmerManagementTabs().toPromise(),
        this.dataConsentService.getFarmerConsents(this.projectId).toPromise()    
        // farmer reg questions
      ]);
      console.log('requests', requests);
      this.master = requests[0].data;
      this.project = this._route.snapshot.data.farmerData.project;
      this.farmer = farmerFromIDB ? farmerFromIDB : requests[1].data.find(x => x.id == farmerId);
      this.farmerPhotoPath = this.farmer.photo_path;
      this.tabsList = requests[2].data;
      this.selectedTab = this.tabsList[0];
  
      const farmerRegData:any = [];
      for(let i=0; i<this.master.length;i++){
        if(this.master[i].item_tab == this.tabsList[0].tab_key){
            farmerRegData.push(this.master[i]);           
        }
      }
        
      for(let i=0;i<farmerRegData.length;i++){
        if(farmerRegData[i].item_code == 'sex'){
          this.genderData.push(farmerRegData[i]);
        }else if(farmerRegData[i].item_code == 'education_level'){
          this.educationLevelData.push(farmerRegData[i]);
        }else if(farmerRegData[i].item_code == 'school_level'){
          this.schoolLevelData.push(farmerRegData[i]);
        }
      }

      //added by KRPT for adding farmer consent

      if(requests[0].msg == 'success'){
        this.farmerConsent = requests[3].data;
      }
      for(const [key, value] of Object.entries(this.farmerConsent)){
        if(parseInt(key) == farmerId){
          this.farmer.consentPending = !value["is_consent_valid"];
          this.farmer.valid_upto = value["valid_upto"];
        }
      }
          
      this.getSelectedTabData();
      this.setVillageAreaList();
    }
    this.updatedFarmName = `${this.farmer.name} Farm 1`;
    this.canDoProfilingFn();
    //geo-plotting service component check
    this.canDoGeoplottingFn();
    //for getting farm details 
    
    let request = await Promise.all([
      this.farmerService.getEstimatedPotentialValue(this.projectId).toPromise()
    ]);
    
    if (request[0]['msg'] === 'success') {
      const currentFarmer = request[0]['data'].filter(item => item.farmer_id == farmerId);
      if(currentFarmer && currentFarmer.length > 0){
        this.potential = currentFarmer[0].answer;
        this.currentFarmerOriginalPotential = currentFarmer[0].answer;
      }
    } 

  }

  canDoGeoplottingFn(){
    if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
      this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
    }

    if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
      this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
    }

    if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
      for(const item of this.usersServiceComponentMappings){
        if(item.services_components_mapping){
          if(item.services_components_mapping.code == "FAR21" && item.user_type_id == this.currentUser.role.id){
            this.canDoGeoplotting = true;
          }
        }
      }
    }
    else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
      for(const item of this.ecosystemServicesComponentsMapping){
        if(item.services_components_mapping){
          if(item.services_components_mapping.code == "FAR21"){
            this.canDoGeoplotting = true;
          }
        }
      }
    }
  }
  

  getSelectedTabData(): void {
    if (this.selectedTab['tab_key'] == 'farmer_reg') {
      if (!this.farmerRegQuestions || this.farmerRegQuestions.length == 0) {
        this.getFarmRegQuestions();
      }
    } else if (this.selectedTab['tab_key'] == 'farms'){
      // function to get farms related data from mongodb
    }
    // other else if conditions
  }

  async getFarmRegQuestions() {
    this.myForm = this.formBuilder.group({
    });
    if (navigator.onLine) {
      this.farmerService.getFarmRegQuestions()
      .subscribe(response => {
        //console.log('response', response);
        this.farmerRegQuestions = response.data;
        // console.log("this.farmerRegQuestions all ", this.farmerRegQuestions);
        // for (let i = 0; i < this.farmerRegQuestions.length; i++) {
      //   this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl('',
        //     [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));                  
        // }

        // Farmer questions sort the order based on the ques_seq
        const orderedQuestions = this.farmerRegQuestions.sort((a,b) =>  Number(a.ques_seq) - Number(b.ques_seq))
        this.farmerRegQuestions = orderedQuestions;

        this.setVCMapping();
        this.myFormStatus = true;
        this.setFarmerForm(); 
        this.showSpinner = false;
      })
    } else {
      const requests = await Promise.all([
        this.farmerService.getFarmRegQuestions().toPromise(),
      ]);
      this.farmerRegQuestions = requests[0].data;
      
      //console.log("offline this.farmerRegQuestions ", this.farmerRegQuestions);
      this.setVCMapping();
      this.setFarmerForm();
      this.showSpinner = false;
    }
  }

  scrollToTop() {
    // $('html, body').animate({
    //   scrollTop: $('#mainDiv').scrollTop = 0
    // }, 'slow');
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  clickBack(){
    this.currentTabIndex = 0;
  }

  tabClicked(tab, index){
    this.currentTabIndex = index;
    if(index == 2){
      this.mapDisplay = true;
      this.initializeMap();
    } else {
      this.mapDisplay = false;
    }
  }
  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  /* file upload */
  handleFileInput(file: FileList, tab: string) {
    // // debugger;
    const fileItem = file.item(0);
    if(fileItem.name == null || fileItem.name == undefined){
      this.uploadingFileName = '';
    }else{
      this.uploadingFileName = fileItem.name;
    }
    this.uploadingFileName = fileItem.name;
    if(this.uploadingFileName){
      this.uploadingFileNameFlag= true;
    }else{
      this.uploadingFileNameFlag= false;
    }
    this.fileUploadMsg = '';
    
    const msg = this.commonService.ValidateFile(fileItem);

    if (msg && msg.length > 0) {
      // this.commonService.showToast('warning', 'generic_error', {});
      this.commonService.showToast('warning', msg, {});
      this.fileUploadMsg = msg;
      return;
    }
    //console.log(" fileItem  ",fileItem);
    // this.fileToUpload = fileItem;
    //this.farmer['photo_path'] = fileItem.name;

    // const reader = new FileReader();
    // reader.onload = (event: any) => {
    //   // this.imageUrl = event.target.result;
    //   this.farmerImageData = null;
    //   let imageData = event.target.result;
    //   imageData = new Uint8Array( imageData );
    //   imageData = new Blob( [ imageData ], { type: "image/jpeg" } );

    //   let setFarmerImageData = (result) => { 
    //     this.commonService.blobToArrayBuffer(result).then((imageData)=>{
    //       this.farmerImageData = imageData;
    //     });
    //   };

    //   new Compressor(imageData, {
    //     quality: 0.6,
    //     success(result) {
    //       setFarmerImageData(result);
    //     },
    //     error(err) {
    //       console.log(err.message);
    //     },
    //   });
    // };
    // reader.readAsArrayBuffer(this.fileToUpload);

    new Compressor(fileItem, {
      quality: environment.imageCompress.quality,
      maxWidth:environment.imageCompress.maxWidth,
      maxHeight:environment.imageCompress.maxHeight,
      mimeType:environment.imageCompress.mimeType,
      convertSize:environment.imageCompress.convertSize,
      success(result) {
        setFarmerImageData(result);
      },
      error(err) {
        console.log(err.message);
      },
    });

    const setFarmerImageData = (result) => {
      this.fileToUpload = result;
      this.commonService.blobToBase64(result).then((imageData)=>{
        this.farmerImageData = imageData;
        this.imageUrl = imageData.toString();
      });
  };


  }


  submitForm(tab: string, myForm: FormGroup, index: number){
    //console.log("myForm ",myForm.value);    
    this.f1Submitted = true;
    if (myForm.valid) {
      this.farmerMainObject[tab] = this.farmer;
      //console.log("myForm.valid");
      if(this.farmer && (this.farmer != null || this.farmer != undefined)){
        // Object.keys(this.farmer).forEach((key1) =>{                  
          Object.keys(myForm.value).forEach((key2) =>{
            // if(key1 == key2){
              this.farmer[key2] = myForm.controls[key2].value; 
            // }
          // });         
          
        });
        this.farmer.photo_path = this.farmerPhotoPath ? this.farmerPhotoPath : null;
      }
      if (this.farmer.education_level != null && this.farmer.education_level == '') {this.farmer.education_level = null;}
      this.setSchoolLevelToNull();
      if (navigator.onLine) {
        //console.log("this.farmerMainObject[tab] online", this.farmerMainObject[tab]);
        //console.log("this.farmerMainObject online",this.farmerMainObject);
        //console.log("this.farmer online",this.farmer);
        if (this.fileToUpload) {
          //console.log("this.fileToUpload ", this.fileToUpload);
          const formData: any = new FormData();
          formData.append('type', 'farmerAvatar');
          formData.append('projectId', this.projectId);
          formData.append('avatar', this.fileToUpload, this.fileToUpload.name);

          this.farmerService.uploadFarmerImage(formData)
            .subscribe((obj) => {
              if (obj.msg === 'success') {
                // console.log('obj', obj);
              this.farmer['photo_path'] = obj.data;
              this.farmerPhotoPath = this.farmer.photo_path;
              this.farmerMainObject[tab] = this.farmer;
              this.farmerService.SaveFarmer(this.farmerMainObject[tab])
                .subscribe(result => {
                  // console.log('result after updating farmer', result);
                  if (result.msg == 'success') {
                    this.f1Submitted = false;                    
                    this.commonService.showToast('success', 'success_farmer_updated', {});
                    const url = 'farmers/' + this.projectId;
                    // this.router.navigate([url]);
                  }
                  else {
                    this.commonService.showToast('error', 'error_farmer_updated', {});
                  }
                  this.myForm.markAsPristine();
                })
              }
            });
        } else{
          this.farmerMainObject[tab] = this.farmer;
          this.farmerService.SaveFarmer(this.farmerMainObject[tab])
            .subscribe(result => {
              // console.log('result after updating farmer', result);
              if (result.msg == 'success') {     
                this.f1Submitted = false;           
                this.commonService.showToast('success', 'success_farmer_updated', {}); 
                const url = 'farmers/' + this.projectId;
                // this.router.navigate([url]);             
              }
              else {
                this.commonService.showToast('error', 'error_farmer_updated', {});              
              }
              this.myForm.markAsPristine();
          })
        }
      }else{
        //offline
        this.farmerMainObject[tab] = this.farmer;
        const finalObj = {};
        const farmerObj = this.farmerMainObject[tab];
        //console.log("this.farmerMainObject ",this.farmerMainObject);
        //console.log("farmerObj ",farmerObj);
        // console.log('farmerObj initially', JSON.stringify(farmerObj));
        Object.keys(farmerObj).forEach(function (key) {
          // console.log('now doing key', key);
          // console.log('now doing farmerObj[key]', farmerObj[key]);
          if (typeof farmerObj[key] === 'object' && farmerObj[key] != null) {
            if (farmerObj[key].length && farmerObj[key].length > 0) {
             // finalObj[key] = {};
              finalObj[key] = farmerObj[key];
            }
            //  else {
            //   finalObj[key] = farmerObj[key];
            // }
          } else {
            finalObj[key] = farmerObj[key];
          }
        });
        const farmObj = new Array();
        // tslint:disable-next-line:max-line-length
        // finalObj['tmp_id'] = this.farmer[tab].id > 0 ? `${this.farmer[tab].farmer_code}_Tab0` : `FA${Math.random().toString().slice(2, 12)}_Tab0`;
        const index = this.rawFarmerId.indexOf('_');
        const rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
        // console.log('rawFarmerIdEdited', rawFarmerIdEdited);
        finalObj['tmp_id'] = this.farmer.id > 0 ? `${this.farmerMainObject.farmer_reg.farmer_code}_Tab00` : `${rawFarmerIdEdited}_Tab00`;
        finalObj['savetab'] = 'Tab00';
        finalObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
        finalObj['project_id'] = this.projectId;
        finalObj['upd_user_id'] = this.currentUser.id;
        finalObj['consentPending'] = this.farmer.consentPending;
        finalObj['valid_upto'] = this.farmer.valid_upto;
        delete finalObj['photo_path'];
        farmObj[farmObj.length] = finalObj;
        // finalObj['cr_date'] = Date.now();
        idbApp.addStoreInfo(farmObj, 'farmerstmp');
        // console.log(this.farmerImageData);
        if (this.farmerImageData) {
          const farmPhotoObj = {};
          farmPhotoObj['tmp_id'] = this.farmer.id > 0 ? `${this.farmerMainObject.farmer_reg.farmer_code}_Tab0` : `${rawFarmerIdEdited}_Tab0`;
          farmPhotoObj['randnum'] = finalObj['randnum'];
          farmPhotoObj['farmerImageData'] = this.farmerImageData;
          farmPhotoObj['savetab'] = 'Tab00';
          // farmPhotoObj['cr_date'] = Date.now();
          farmPhotoObj['farmer_id'] = (this.farmer.id > 0) ? this.farmer.id : 0;
          idbAppPhoto.addStoreInfo([farmPhotoObj], 'farmersPhototmp');
        }

        //console.log("after idbAPP stat ", farmObj);
        // this.commonService.showToast('info', 'offline_sync_later', {});
        // this.saveButtonClicked['farmer_reg'] = false;

        this.showSpinner = false;
        this.myForm.markAsPristine();
        this.farmerImageData = undefined;
        // this.registerSyncEvent();

        // code to update the cache


        this.updateCache();
        //console.log("after  update log");
        this.f1Submitted = false;
        const url = 'farmers/' + this.projectId;
        this.farmerUpdatedOffline = true;
        this.commonService.showToast('info', 'offline_sync_later', {});
      }  
      
    }else{
      //console.log("myForm.Invalid");
    }
  }

  registerSyncEvent() {
    serviceWorkerVar.sync.register('oneTimeSync');
    //console.log("serviceWorkerVar register");
    // navigator.serviceWorker.register('/service-worker.js', {
    //   scope: './'
    // })
    //   .then(function (reg) {
    //     // console.log('ServiceWorker registered2', reg);
    //     reg.sync.register('oneTimeSync');
    //   })
    //   .catch(function (error) {
    //     // console.log('Failed to register ServiceWorker', error);
    //   });
  }

  async updateCache() {
    this.updateFarmerCache();

    const url = `${environment.apiBaseUrl}/api/farmer/get/allFarmers`;

    if (this.farmer.id > 0) {
      const farmerId = this.farmer.id;
      const farmer = this.farmer;

      const keys = await caches.keys();

      keys.forEach(async (key) => {
        const cache = await caches.open(key);
        const resp = await cache.match(url);

        if (resp) {
          const init = {
            status: resp.status,
            statusText: resp.statusText,
            headers: {}
          };
          resp.headers.forEach(function (v, k) {
            init.headers[k] = v;
          });

          const body = await resp.json();
          const respBody = body;
          const index = respBody.data.findIndex(x => x.id == farmerId);
          respBody.data[index] = farmer;
          const newResp = new Response(JSON.stringify(respBody), init);

          const currentUser = JSON.parse(localStorage.getItem('currentUser'));
          const fp = localStorage.getItem('fp');

          const request = new Request(url);

          request.headers.append('Authorization', currentUser.token);
          request.headers.append('fp', fp);

          cache.put(url, newResp.clone());
        }
      });
    }
  }

  async updateFarmerCache(){
    const farmerId = this.farmerMainObject.farmer_reg.id;
    const farmer = this.farmerMainObject.farmer_reg;
    // caches.open('deeproots-0.0.01').then(async cache => {
    const url = `${environment.apiBaseUrl}/api/farmer/getall/byproject`;

    const keys = await caches.keys();

    keys.forEach(async (key) => {
      const cache = await caches.open(key);
      const resp = await cache.match(url);

      if (resp) {
        const init = {
          status: resp.status,
          statusText: resp.statusText,
          headers: {}
        };
        resp.headers.forEach(function (v, k) {
          init.headers[k] = v;
        });

        const body = await resp.json();
        if (body) {
          const respBody = body;
          const index = respBody.data.findIndex(x => x.id == farmerId);
          const newRecord = respBody.data[index];
          // this will only update name and tel number as it is required on projectinfo page
          if (newRecord) {
            newRecord.name = farmer.name;
            newRecord.tel_number = farmer.tel_number;
            newRecord.upd_date = farmer.upd_date;

            newRecord.filled_questions =  farmer.filled_questions;
            newRecord.filled_mandatory = farmer.filled_mandatory;
            newRecord.total_questions = farmer.total_questions;
            newRecord.mandatory_questions =farmer.mandatory_questions;



            respBody.data[index] = newRecord;
            const newResp = new Response(JSON.stringify(respBody), init);

            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            const fp = localStorage.getItem('fp');

            const request = new Request(url);

            request.headers.append('Authorization', currentUser.token);
            request.headers.append('fp', fp);

            cache.put(request, newResp.clone());
          }
        }
      }
    });
}

  exitClicked(myForm: FormGroup, template: TemplateRef<any>){
    if(myForm.dirty){
      //console.log("if");
      this.confirmModalPopup = false;
      this.confirmPopup = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
    }else{
      const url = 'farmers/' + this.projectId;
      this.router.navigate([url]);
    }
  } 

  confirmExitModal(item){
    if(item === 'Yes'){
      //this.cancelQuestionEdit();
      //setTimeout(()=>{
        this.confirmModalPopup = true;
        if (this.confirmPopup) this.confirmPopup.hide();
      //},1000);
        if (!navigator.onLine) {
          this.commonService.showToast('info', 'offline_sync_later', {});
        }
        const url = 'farmers/' + this.projectId;
        this.router.navigate([url]);
    }else if(item === 'No'){
      this.confirmModalPopup = true;
      if (this.confirmPopup) this.confirmPopup.hide();
    }
  }

  getBackFarmerManagment(id){
    const url = 'farmers/' + this.projectId;
    this.router.navigate([url]);
  }

  setVillageAreaList(): void {
    if (this.project.ecosystem_region_master) {
      this.villageAreaList = this.project.ecosystem_region_master.region_villages;
    }
  }

  setVCMapping(): void {
    if (this.farmer) {
      if (this.farmer.vc_farmer_mappings && this.farmer.vc_farmer_mappings.length > 0) {
        let vcNames = '';
        for (let i = 0; i < this.farmer.vc_farmer_mappings.length; i++) {
          if (this.farmer.vc_farmer_mappings[i].status == 'Active') {
            vcNames = this.farmer.vc_farmer_mappings[i].users.name  + ', ' + vcNames;
          }
        }
        vcNames = vcNames.substring(0, vcNames.length - 2);
        this.farmer.village_collector = vcNames;
      }
    }
  }

  // Create and set values for the farmer details edit form based on reg questions the farmer object
  setFarmerForm() {
    // console.log('this.farmer', this.farmer);
    for (let i = 0; i < this.farmerRegQuestions.length; i++) {
      if(this.farmer && (this.farmer != null || this.farmer != undefined)){
        const key = this.farmerRegQuestions[i].code;
          // console.log('key', key);
            if(this.farmerRegQuestions[i].code === 'photo_path'){
              this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl('',
                [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));
            }
            else if (this.farmerRegQuestions[i].code === 'village_collector' || this.farmerRegQuestions[i].code === 'official_id_card_no') {
              if (this.farmer[key]) {
                this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl({value: this.farmer[key], disabled: true},
                  [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));
              } else {
                this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl({value: '', disabled: true},
                  [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));
              }
            }
            else{
              if (this.farmer[key]) {
                this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl(this.farmer[key],
                  [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));
              } else {
                this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl('',
                  [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));
              }
            }
          }
          if (this.farmerRegQuestions[i].regex_pattern && this.farmerRegQuestions[i].regex_pattern !== '') {
            this.myForm.controls[this.farmerRegQuestions[i].code].setValidators(Validators.pattern(this.farmerRegQuestions[i].regex_pattern))
          }
      }
  }

  async goToFarmerProfiling() {
    let url;
    
    // Check if the project has an active survey of type snapshot, which is released in the current language
    // If no active survey / not released in the current language, show toast message & return from function
    const request = await Promise.all([
      this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
    ]);
    if (request[0]['message'] === 'notActive' || request[0]['message'] === 'notReleasedForLanguage') {
      this.commonService.showToast('info', 'there_no_active_snapshot_for_this_project', {});
      return;
    }

    if (this.farmer.id && this.farmer.id > 0) {
      url = '/questionnaire/respond/' + this.farmer.project_id + '/' + this.farmer.id;
    } else if (this.farmer.tmp_id) {
      if (this.farmer.tmp_id.indexOf('Tab00') > -1) {
        url = '/questionnaire/respond/' + this.farmer.project_id + '/' + this.farmer.tmp_id.substring(0, this.farmer.tmp_id.length - 1);
      } else {
        url = '/questionnaire/respond/' + this.farmer.project_id + '/' + this.farmer.tmp_id;
      }
    } else {
      url = '/error';
    }
    if(!this.campaignId){
      this.router.navigate([url]);
    } else {
      this.router.navigate([url], {queryParams: {campaignId: this.campaignId}});
    }
  }

  canDoProfilingFn(): void {
    if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
      this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
    }

    if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
      this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
    }

    if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
      const canDoProfiling = this.usersServiceComponentMappings.filter(item => (item.services_id == 3 && item.user_type_id == this.currentUser.role.id));
      if (canDoProfiling && canDoProfiling.length > 0) {
        this.canDoProfiling = true;
      }
      const canAddTraining = this.usersServiceComponentMappings.filter(item => (
        item.services_components_mapping && item.services_components_mapping.code === "FR_TRA_CER" && item.user_type_id === this.currentUser.role.id)
      );
      if (canAddTraining && canAddTraining.length > 0) {
        this.canAddTraining = true;
      }
    }
    else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
      const canDoProfiling = this.ecosystemServicesComponentsMapping.filter(item => item.services_id == 3);
      if (canDoProfiling && canDoProfiling.length > 0) {
        this.canDoProfiling = true;
      }
      const canAddTraining = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.code === "FR_TRA_CER"));
      if (canAddTraining && canAddTraining.length > 0) {
        this.canAddTraining = true;
      }
    }
  }

  setSchoolLevelToNull(): void {
    if (this.farmer['school_level'] == '') {
      this.farmer['school_level'] = null;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {    
    if (this.myForm && this.myForm.dirty) {
      this.confirmModal = this.modalService.show(this.confirmTemplate, { backdrop: true, ignoreBackdropClick: true });
      this.spinner.hide();
      return this.subject.asObservable();
    }
    return true;
  }

  closeModal(isOkay: boolean) {
    this.confirmModal.hide();
    this.subject.next(isOkay);
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      // rest of your code
    }
  } 
  typeChanged(type){
    if(type == 'certificate'){
      this.isCertificate = true;
    } else {
      this.isCertificate = false;
    }
  } 

  //check if farmername contains spaces only
  checkEmptyness() {
    const name = this.myForm['value'].name;
    if (!name.replace(/\s/g, '').length) {
      this.validFarmerName = true
    } else {
      this.validFarmerName = false
    }
  }

 //Geo-plotting - for getting farm cordinates
 
 getGeoLocation() {
  this.getPosition().then(pos=>
    {      
      const myLatLng = new google.maps.LatLng(pos.lat, pos.lng);
      const newMapProperties = {
        center: myLatLng,
        zoom: 19,
        mapTypeControl: false,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.HYBRID
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, newMapProperties);
      let marker = new google.maps.Marker({
        position: myLatLng,
        draggable:false,
      });
      // To add the marker to the map, call setMap();
      marker.setMap(this.map);
      this.loc_coordinates = `${pos.lat},${pos.lng}`;
    });
  }

  getPosition(): Promise<any> {
    const opts = { enableHighAccuracy: true, maximumAge: 60000, timeout: 5000 };
    return new Promise((resolve, reject) => {
      navigator.geolocation.watchPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }, opts
      );
    });
  }

  //initialize google map on click of tab
  async initializeMap(saved?){
    // console.log("this.isOnline:", this.isOnline);
    let request = await Promise.all([
      this.farmerService.getFarmCoordinates(this.projectId).toPromise()
    ]);
    
    if (request[0]['msg'] === 'success') {
      this.farms = request[0]['data'].filter(item => item.farmer_code == this.farmer.farmer_code);
    }  
    if (!navigator.onLine) {
      const farmtmpobj = await idbAppPhoto.getAllProjects('farmCoordinatestmp');
      
      if(farmtmpobj && farmtmpobj.length > 0){        
        const index = this.rawFarmerId.indexOf('_');
        const rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
        const farmerCode = this.farmer.id > 0 ? `${this.farmer.farmer_code}`  : `${rawFarmerIdEdited}`;
        // console.log("farmerCode:", farmerCode);
        let farmerFarms = [];
        farmtmpobj.forEach((item =>{
          const tmpIds = item.tmp_id.split("_");
          // console.log("tmpIds:", tmpIds);
          if(tmpIds[0] == farmerCode){
            farmerFarms.push(item);
          }
        }));
        // console.log("farmerFarms:", farmerFarms);
        // farmerFarms = farmtmpobj.filter(item => item.farmData.farmer_code == farmerCode);
        
        if(farmerFarms && farmerFarms.length > 0){
          for(const farm of farmerFarms){        
            //this.originalFarms.push(farm.farmData);    
            //check if deleted 
            if(!farm.farmData.deleted){
              farm.farmData['farmname'] = farm.farmData.farm_name;
              farm.farmData['tmp_id'] = farm.tmp_id;
              farm.farmData['id'] = farm.randnum;
              delete farm.farmData.farm_name;
              this.farms.push(farm.farmData);
            } else {
              if(farm.farmData.id){
                this.farms = this.farms.filter(item => item.id != farm.farmData.id);
              }
            }
          }
        }
      }
    }
    //console.log('this.farms', this.farms);
    this.originalFarms = this.farms;
    if(this.farms.length > 0){
      const farmIndex = saved ? this.farms.length - 1 : 0;
      const farmLatLng = this.farms[farmIndex].plot;
      if(this.farms[farmIndex].area == null){
        const splitLatLng = farmLatLng.split(',');
        const myLatLng = new google.maps.LatLng(parseFloat(splitLatLng[0]), parseFloat(splitLatLng[1]));
        this.setMarkerOnMap(myLatLng); 
      } else {
        const area = await this.createPolygonOnMap(farmLatLng, this.farms[farmIndex]);
        //console.log('area', area);
      }
      
      this.selectedFarm = this.farms[farmIndex];
      this.updatedFarmName = this.farms[farmIndex].farmname;
      this.farmTypeSelected = this.farms[farmIndex].farm_type;
      if(this.farms[farmIndex].fcproj != null){
        this.potential = this.farms[farmIndex].fcproj;
      }
      if(this.farms[farmIndex].farmimage != null){
        this.uploadedFarmImage = this.farms[farmIndex].farmimage;
      }

      this.noNewFarm = false;
    } else {      
      this.updatedFarmName = `${this.farmer.name} Farm 1`;
      this.potential = this.currentFarmerOriginalPotential;
      this.farmTypeSelected = 0;
      this.setAreaMap();
    }
  }

  setMarkerOnMap(coordinates){
    const newMapProperties = {
      center: coordinates,
      zoom: 19,
      mapTypeControl: false,
      streetViewControl: false,
      mapTypeId: google.maps.MapTypeId.HYBRID
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, newMapProperties);
    let marker = new google.maps.Marker({
      position: coordinates,
      //title: "Field Location!",
      draggable:false,
    });
    // To add the marker to the map, call setMap();
    marker.setMap(this.map);
  }

  setAreaMap(){
    this.getPosition().then(pos=>
    {
      const myLatLng = new google.maps.LatLng(pos.lat, pos.lng);
      const newMapProperties = {
        center: myLatLng,
        zoom: 16,
        mapTypeControl: false,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.HYBRID
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, newMapProperties);
      let marker = new google.maps.Marker({
        position: myLatLng,
        draggable:true,
      });
      // To add the marker to the map, call setMap();
      marker.setMap(null);
    });
  }

  farmSectionClicked(findex){
    this.selectedFarm = this.farms[findex];
    if(this.selectedFarm.id != 0){
      const farmLatLng = this.selectedFarm.plot;
      if(this.selectedFarm.area == null){
        const splitLatLng = farmLatLng.split(',');
        const myLatLng = new google.maps.LatLng(parseFloat(splitLatLng[0]), parseFloat(splitLatLng[1]));
        this.setMarkerOnMap(myLatLng);
      } else {
        const area = this.createPolygonOnMap(farmLatLng, this.selectedFarm);
      }
    } else if(this.selectedFarm.farm_type == 3){
      this.drawPolygon();
    } else if(this.selectedFarm.farm_type == 1){
      this.getGeoLocation();
    } else {
      this.setAreaMap();
    }
    this.updatedFarmName = this.selectedFarm.farmname;
    this.potential = this.selectedFarm.fcproj;    
    this.uploadedFarmImage = this.selectedFarm.farmimage;
    this.farmTypeSelected = this.selectedFarm.farm_type;
    if(this.farmTypeSelected == 2 && this.selectedFarm.id == 0){
      this.walkingOnTheEdge = true;
    } else {
      this.walkingOnTheEdge = false;
    }
  }
  
  async saveCoordinates(e){
    //this.spinner.show();
    if(this.farmTypeSelected == 0){
      this.toastrService.warning(this.farmTypeNotSelected);
      return;
    }
    const index = this.rawFarmerId.indexOf('_');
    const rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
    let area;
    if(this.drawAreaOnMap || this.walkingOnTheEdge){
      area = await this.createPolygonOnMap(this.loc_coordinates);
    }
    const obj = {
      ecosystem_id : this.project.ecosystem_id,
      project_id : this.projectId,
      farmer_code : this.farmer.id > 0 ? `${this.farmer.farmer_code}`  : null,
      farm_name : this.updatedFarmName,
      farm_type : this.farmTypeSelected,
      plot : this.loc_coordinates,
      fcproj: this.potential && this.potential != "" ? this.potential : null,
      fcproj_unit: this.potential ? 'kilogram' : null,
      area: area ? area : null
    }
    //console.log('obj', obj);
    if (navigator.onLine) {
      if (this.farmFileToUpload) {
        //console.log("this.fileToUpload ", this.fileToUpload);
        const formData: any = new FormData();
        formData.append('farmImage', this.farmFileToUpload, this.farmFileToUpload.name);

        this.farmerService.uploadFarmImage(formData)
          .subscribe((res) => {
            if (res.msg === 'success') {
            const farmImage = res.data;  
            obj['farm_image'] = farmImage;
            this.farmerService.saveFarmCoordinates(obj)
              .subscribe(result => {
                this.toastrService.success(this.farmSavedSuccessfully);
                this.spinner.hide();
                this.potential = undefined;
                this.drawAreaOnMap = false;
                this.walkingOnTheEdge = false;
                this.farmFileToUpload = null;
                this.uploadingFarmFileName = '';
                this.farmImageData = undefined;
                this.farmTypeSelected = 0;
                this.initializeMap(true);
            })
            }
          });
      } else {
        this.farmerService.saveFarmCoordinates(obj)
        .subscribe(result => {
          this.toastrService.success(this.farmSavedSuccessfully);
          this.spinner.hide();
          this.potential = undefined;
          this.drawAreaOnMap = false;
          this.walkingOnTheEdge = false;
          this.farmTypeSelected = 0;
          this.initializeMap(true);
        })
      }
    } else {
      //offline save coordinates      
      obj['user_id'] = this.currentUser.id;
      obj['farmImage'] = this.farmImageData;
      // get farm # if already saved any in offline
      let tempId;
      const index = this.rawFarmerId.indexOf('_');
      const rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
      const farmerCode = this.farmer.id > 0 ? `${this.farmer.farmer_code}`  : `${rawFarmerIdEdited}`;
      
      const farmtmpobj = await idbAppPhoto.getAllProjects('farmCoordinatestmp');
      if(farmtmpobj && farmtmpobj.length > 0){
        let farmerFarms = [];
        farmtmpobj.forEach((item =>{
          const tmpIds = item.tmp_id.split("_");
          if(tmpIds[0] == farmerCode){
            farmerFarms.push(tmpIds[1]);
          }
        }));
        if(farmerFarms && farmerFarms.length > 0){
          const lastFarm = farmerFarms[farmerFarms.length -1];
          let lastChar = parseInt(lastFarm.substr(lastFarm.length - 1)) + 1;
          tempId = `${farmerCode}_Tab0${lastChar}`;
        } else {
          tempId = `${farmerCode}_Tab01`;
        }
      } else {
        tempId = `${farmerCode}_Tab01`;
      }
      //console.log('tempId', tempId);
      const farmObj = {};
      farmObj['tmp_id'] = tempId;
      //farmObj['tmp_id'] = this.farmer.id > 0 ? `${this.farmer.farmer_code}_Tab0${this.originalFarms.length + 1}` : `${rawFarmerIdEdited}_Tab0${this.originalFarms.length + 1}`;
      farmObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
      farmObj['farmData'] = obj;
      farmObj['savetab'] = 'Tab00';
      idbAppPhoto.addStoreInfo([farmObj], 'farmCoordinatestmp');
      this.commonService.showToast('info', 'offline_sync_later', {});
      
      this.spinner.hide();
      this.potential = undefined;
      this.drawAreaOnMap = false;
      this.walkingOnTheEdge = false;
      this.farmFileToUpload = null;
      this.uploadingFarmFileName = '';
      this.farmImageData = undefined;
      this.farmTypeSelected = 0;
      this.initializeMap(true);
    }
    this.loc_coordinates = undefined;
    this.resetFarmCordinates();
  }

  updateCoordinates(farm){this.spinner.show();    
    const index = this.rawFarmerId.indexOf('_');
    const rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
    const obj = {
      id : farm.id,
      ecosystem_id : farm.ecosystem_id,
      project_id : this.projectId,
      farmer_code : this.farmer.id > 0 ? `${this.farmer.farmer_code}` : `${rawFarmerIdEdited}`,
      farm_name : this.updatedFarmName,
      plot : this.loc_coordinates,
      fcproj: this.potential,
      fcproj_unit: this.potential ? 'kilogram' : null,
      farmImage: this.farmImageData
    };
    if (navigator.onLine) {
      this.farmerService.saveFarmCoordinates(obj)
      .subscribe(result => {
        this.potential = undefined;
        this.initializeMap();
        this.toastrService.success('Farm updated successfully!');
        const curIndex = this.farms.indexOf(farm);
        this.farmSectionClicked(curIndex);
        this.spinner.hide();
      })
    } else {
      //offline save coordinates
      obj['user_id'] = this.currentUser.id;
      const farmObj = {};
      farmObj['tmp_id'] = this.farmer.id > 0 ? `${this.farmer.farmer_code}_Tab0${this.originalFarms.length + 1}` : `${rawFarmerIdEdited}_Tab0${this.originalFarms.length + 1}`;
      farmObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
      farmObj['farmData'] = obj;
      farmObj['savetab'] = 'Tab00';
      if(farm.tmp_id){
        idbAppPhoto.removeDataFromStore('farmCoordinatestmp', farm.tmp_id);
      }
      idbAppPhoto.addStoreInfo([farmObj], 'farmCoordinatestmp');
      this.commonService.showToast('info', 'offline_sync_later', {});      
      // obj['id'] = 0;
      // this.farms.push(obj);
      // this.farmSectionClicked(this.farms.length - 1);
      this.spinner.hide();
    }
    this.loc_coordinates = undefined;
  }

  async saveFarmCoordinates(){
    this.spinner.show();
    let farmCoordinates = this.farmRecording.start;
    for(const item of this.farmRecording.intermediate){
      farmCoordinates = `${farmCoordinates} ${item}`;
    }
    farmCoordinates = `${farmCoordinates} ${this.farmRecording.end}`;
    const area = await this.createPolygonOnMap(farmCoordinates);

    const index = this.rawFarmerId.indexOf('_');
    const rawFarmerIdEdited = this.rawFarmerId.substr(0, index);

    const obj = {
      ecosystem_id : this.project.ecosystem_id,
      project_id : this.projectId,
      farmer_code : this.farmer.id > 0 ? `${this.farmer.farmer_code}` : `${rawFarmerIdEdited}`,
      farm_name : this.updatedFarmName,
      plot : farmCoordinates,
      area : area,
      area_unit : 'hectare',
      fcproj: this.potential,
      fcproj_unit: this.potential ? 'kilogram' : null,
      farmImage: this.farmImageData
    }
    if (navigator.onLine) {
      this.farmerService.saveFarmCoordinates(obj)
      .subscribe(result => {
        this.toastrService.success(this.farmSavedSuccessfully);
        this.spinner.hide();
        this.potential = undefined;
        this.initializeMap();
      })
    } else {
      //offline save coordinates
      obj['user_id'] = this.currentUser.id;
      const farmObj = {};
      farmObj['tmp_id'] = this.farmer.id > 0 ? `${this.farmer.farmer_code}_Tab0${this.originalFarms.length + 1}` : `${rawFarmerIdEdited}_Tab0${this.originalFarms.length + 1}`;
      farmObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
      farmObj['farmData'] = obj;
      farmObj['savetab'] = 'Tab00';
      // farmPhotoObj['cr_date'] = Date.now();
      // farmObj['farmer_id'] = (this.farmer.id > 0) ? this.farmer.id : 0;
      idbAppPhoto.addStoreInfo([farmObj], 'farmCoordinatestmp');
      this.commonService.showToast('info', 'offline_sync_later', {});
      // obj['id'] = 0;
      // this.farms.push(obj);
      // this.farmSectionClicked(this.farms.length - 1);
      this.spinner.hide();
      this.potential = undefined;
      this.initializeMap();
    }
    this.closeRecodingPopup();
    this.walkingOnTheEdge = false;
  }
  
  deleteFarm(farm, template){
    this.farmToDelete = farm;
    this.farmDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });    
  }

  async confirmFarmDelete(decision){
    if(decision == "Yes"){
      this.spinner.show();
      const farmId = this.farmToDelete.id;
      if(navigator.onLine){
        this.farmerService.deleteFarm(farmId)
        .subscribe(result => {
          this.toastrService.success(this.farmDeletedSuccessfully);
          this.uploadedFarmImage = undefined;
          this.spinner.hide();
          this.initializeMap();
          this.farmDeleteReset();
        })
      } else {
        //offline farm delete         
        // const farmtmpobj = await idbAppPhoto.getAllProjects('farmCoordinatestmp');
        // console.log('farmtmpobj', farmtmpobj);
        if(farmId && !this.farmToDelete.user_id){
          const obj = {
            id : farmId,
            ecosystem_id : this.farmToDelete.ecosystem_id,
            project_id : this.projectId,
            farmer_code : this.farmToDelete.farmer_code,
            farm_name : this.farmToDelete.farmname,
            plot : this.farmToDelete.plot,
            user_id : this.currentUser.id,
            deleted: true
          };
          const farmObj = {};
          const index = this.rawFarmerId.indexOf('_');
          const rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
          farmObj['tmp_id'] = this.farmer.id > 0 ? `${this.farmer.farmer_code}_Tab0${this.farms.length + 1}` : `${rawFarmerIdEdited}_Tab0${this.farms.length + 1}`;
          farmObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
          farmObj['farmData'] = obj;
          farmObj['savetab'] = 'Tab00';
          idbAppPhoto.addStoreInfo([farmObj], 'farmCoordinatestmp');
        }
        console.log('farm.tmp_id', this.farmToDelete.tmp_id);
        if(this.farmToDelete.tmp_id){
          idbAppPhoto.removeDataFromStore('farmCoordinatestmp', this.farmToDelete.tmp_id);
        }
        this.commonService.showToast('info', 'offline_sync_later', {});
        this.initializeMap();
        this.spinner.hide();
        this.farmDeleteReset();
      }
    } else {
      this.farmDeleteReset();
    }
  }
  farmDeleteReset(){
    this.farmDeletePopup.hide();
    this.farmToDelete = null;
  }

  //open popup for farm recordings
  getFarmCoordinates(){
    this.walkingOnTheEdge = true;
    this.setAreaMap();
    //this.addRecordingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  closeRecodingPopup(){
    if(this.addRecordingModal){
      this.addRecordingModal.hide();
      this.resetFarmCordinates();
    }
  }
  
  addFarm(e){
    const obj = {
      id : 0,
      ecosystem_id : this.project.ecosystem_id,
      farmer_code : this.farmer.farmer_code,
      farmname : `${this.farmer.name} Farm ${this.originalFarms.length + 1}`,
      plot : '',
      fcproj: this.farms[0].fcproj,
      farm_type: 0
    }
    this.farms.push(obj);
    this.updatedFarmName = `${this.farmer.name} Farm ${this.originalFarms.length}`;
    this.farmSectionClicked(this.farms.length - 1);
    this.noNewFarm = true;
    this.setAreaMap();
  }

  //geo plotting type dropdown selection
  plotTypeSelection(e, farm?){
    this.farmTypeSelected = e.target.value;
    if(farm){
      const index = this.farms.indexOf(farm);
      if(e.target.value != 0){
        this.farms[index].farm_type = e.target.value;
      }
    }
    this.drawAreaOnMap = false;
    this.walkingOnTheEdge = false;
    this.resetFarmCordinates();    
    this.loc_coordinates = undefined;
    if(e.target.value == 1){
      this.getGeoLocation();
    } else if(e.target.value == 2){
      this.getFarmCoordinates();
    } else if(e.target.value == 3){
      this.drawPolygon();
    } else {
      this.loc_coordinates = undefined;
      this.setAreaMap();
    }
  }

   /*farm file upload */
  handleFarmFileInput(file: FileList) {
    // // debugger;
    const fileItem = file.item(0);
    if(fileItem.name == null || fileItem.name == undefined){
      this.uploadingFarmFileName = '';
    }else{
      this.uploadingFarmFileName = fileItem.name;
    }
    this.uploadingFarmFileName = fileItem.name;
    // if(this.uploadingFarmFileName){
    //   this.uploadingFileNameFlag= true;
    // }else{
    //   this.uploadingFileNameFlag= false;
    // }
    //this.fileUploadMsg = '';
    
    const msg = this.commonService.ValidateFile(fileItem);

    if (msg && msg.length > 0) {
      this.commonService.showToast('warning', msg, {});
      //this.fileUploadMsg = msg;
      return;
    }

    new Compressor(fileItem, {
      quality: environment.imageCompress.quality,
      maxWidth:environment.imageCompress.maxWidth,
      maxHeight:environment.imageCompress.maxHeight,
      mimeType:environment.imageCompress.mimeType,
      convertSize:environment.imageCompress.convertSize,
      success(result) {
        setFarmImageData(result);
      },
      error(err) {
        console.log(err.message);
      },
  });

  const setFarmImageData = (result) => {
      this.farmFileToUpload = result;
      this.commonService.blobToBase64(result).then((imageData)=>{
        this.farmImageData = imageData;
      });
  };


  }

  // //for agm
  // changeTilt(map) {
  // }  
  
  resetFarmCordinates(){
    this.loc_coordinates = undefined;
    this.farmRecording = {
      start: '',
      intermediate: [],
      end: ''
    };
  }
  //record farm coordinates
  recordFarmCoordinates(type){
    this.getPosition().then(pos=>
    {
      if(type == 'start'){
        this.farmRecording.start = `${pos.lat},${pos.lng}`;
      }
      if(type == 'intermediate'){
        this.farmRecording.intermediate.push(`${pos.lat},${pos.lng}`);
      }
      if(type == 'end'){
        this.farmRecording.end = `${pos.lat},${pos.lng}`;
        let farmCoordinates = this.farmRecording.start;
        for(const item of this.farmRecording.intermediate){
          farmCoordinates = `${farmCoordinates} ${item}`;
        }
        farmCoordinates = `${farmCoordinates} ${this.farmRecording.end}`;
        this.loc_coordinates = farmCoordinates;
        const area = this.createPolygonOnMap(farmCoordinates);
      }
    })

  }

  createPolygonOnMap(farmCoordinates, farm?){
    const coordinates = farmCoordinates.split(' ');
    let polyCoordinates = [];
    for(const item of coordinates){
      const i = item.split(',');
      let obj = {
        lat: parseFloat(i[0]), lng : parseFloat(i[1])
      }
      polyCoordinates.push(obj)
    }

    const myLatLng = new google.maps.LatLng(polyCoordinates[0].lat, polyCoordinates[0].lng);
    const newMapProperties = {
      center: myLatLng,
      zoom: 18,
      mapTypeControl: false,
      streetViewControl: false,
      mapTypeId: google.maps.MapTypeId.HYBRID
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, newMapProperties);

    // Construct the polygon.
    const bermudaTriangle = new google.maps.Polygon({
      paths: polyCoordinates,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      editable: true
    });
    this.attachPolygonInfoWindow(bermudaTriangle, farm);

    bermudaTriangle.setMap(this.map);
    
    let area = google.maps.geometry.spherical.computeArea(bermudaTriangle.getPath());  
    const areaText = `Area : ${(area/10000).toFixed(2)} Hectare`;
    if(!farm) {window.alert(areaText);}
    return((area/10000).toFixed(2));
  }

  attachPolygonInfoWindow(polygon, farm) {
    let infoWindow = new google.maps.InfoWindow();
    let contentString 
    if(farm){
      contentString = '<div">'+ farm.farmname + 
        '</div>'+
        '<div>'+ 'Area: ' + farm.area + ' Hectare' + '</div>';
    }
    google.maps.event.addListener(polygon, 'mouseover', function (e) {
        infoWindow.setContent(contentString);
        var latLng = e.latLng;
        infoWindow.setPosition(latLng);
        infoWindow.open(this.map);
    });
    infoWindow.open(this.map);
}

  //draw polygon
  drawPolygon() {
    const self = this;
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['polygon']
      }
    });
    this.getPosition().then(pos=>
    {      
      const myLatLng = new google.maps.LatLng(pos.lat, pos.lng);
      const newMapProperties = {
        center: myLatLng,
        zoom: 19,
        mapTypeControl: false,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.HYBRID
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, newMapProperties);
      this.drawingManager.setMap(this.map);
    });
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      // Polygon drawn
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        //this is the coordinate, you can assign it to a variable or pass into another function.
        //console.log(`polygon cordinates : ${event.overlay.getPath().getArray()}`);
        const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(
              paths.getAt(p),
              'set_at',
              () => {
                if (!event.overlay.drag) {
                  self.updatePointList(event.overlay.getPath());
                }
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'insert_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
            google.maps.event.addListener(
              paths.getAt(p),
              'remove_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
          }
          self.updatePointList(event.overlay.getPath());
        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          self.drawingManager.setDrawingMode(null);
          // To hide:
          self.drawingManager.setOptions({
            drawingControl: false,
          });
        }
    });
  }

  updatePointList(path) {
    //this.pointList = [];
    const len = path.getLength();
    let pathCordinates;
    for (let i = 0; i < len; i++) {
      // this.pointList.push(
      //   path.getAt(i).toJSON()
      // );
      if(i == 0){
        pathCordinates = `${path.getAt(i).toJSON().lat},${path.getAt(i).toJSON().lng}`;
      } else {
        pathCordinates = `${pathCordinates} ${path.getAt(i).toJSON().lat},${path.getAt(i).toJSON().lng}`;
      }
    }
    this.loc_coordinates = pathCordinates;
    this.drawAreaOnMap = true;
  }

  updateForecast(farm) {
    if(this.potential && farm.fcproj != this.potential){
      const obj = {
        id: farm.id,
        ecosystem_id : this.project.ecosystem_id,
        project_id : this.projectId,
        farmer_code : farm.farmer_code,
        farm_name : farm.farmname,
        farm_type : farm.farm_type,
        plot : farm.plot,
        fcproj: this.potential,
        fcproj_unit: this.potential ? 'kilogram' : null,
        area: farm.area
      }
      if (navigator.onLine) {
        this.spinner.show();
        this.farmerService.saveFarmCoordinates(obj)
        .subscribe(result => {
          this.toastrService.success(this.annualForecastUpdatedSuccessfully);
          this.spinner.hide();
          farm.fcproj = this.potential;
          // this.drawAreaOnMap = false;
          // this.walkingOnTheEdge = false;
          // this.farmTypeSelected = 0;
          // this.initializeMap();
        })
      } else {
        //offline save coordinates      
        obj['user_id'] = this.currentUser.id;
        //obj['farmImage'] = this.farmImageData;
        const farmObj = {};
        farmObj['tmp_id'] = farm.farmer_code;
        farmObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
        farmObj['farmData'] = obj;
        farmObj['savetab'] = 'Tab00';
        // farmObj['farmer_id'] = (this.farmer.id > 0) ? this.farmer.id : 0;
        idbAppPhoto.addStoreInfo([farmObj], 'farmCoordinatestmp');
        this.commonService.showToast('info', 'offline_sync_later', {});
        farm.fcproj = this.potential;
      
      }
    }
  }
}
