    <div class="posRel">
      <div class="farmer-dropdown">
        <!-- <select class="small-button" style="padding:4px 5px" (change)="setFarmer($event)" [(ngModel)]="selectedFarmerId" name="select_farmer"  #select_farmer="ngModel">
          <option *ngFor="let farmer of farmers; let i = index" [value]="farmer.id">
            <span *ngIf="farmer.supplier_id">{{farmer.supplier_id}} - </span>
            <span>{{farmer.name}}</span>
          </option>
        </select> -->
        <ng-multiselect-dropdown
          [placeholder]="selectPlaceholder"
          [settings]="dropdownSettings"
          [data]="farmers"
          [(ngModel)]="selectedFarmerId"
          [ngModelOptions]="{standalone: true}"
          (onSelect)="setFarmer($event)"
          #reportIDs="ngModel" name="reportIDs"
        ></ng-multiselect-dropdown>
      </div>
      <div class="answer-compare-div clearfix">
        <div class="" id="mainDiv">
          <div class="farmer-tab-content farmer-profile-div">  
            <ul class="nav nav-pills fixed-tabs" style="overflow: auto">
              <!-- <li class="nav-item">
                <a class="nav-link" data-toggle="pill" [href]="'#' + farmerDetailsTabIndex" id="pills-fp-tab"  [ngClass]="{'active': currentTabIndex == farmerDetailsTabIndex}"
                (click)="tabClicked(farmerDetailsTabIndex, true)"  role="tab" aria-selected="true" i18n="@@farmerDetails">Farmer Details</a>
              </li> -->
              <li class="nav-item" *ngFor="let tab of questionnaire; let i = index">
                <a class="nav-link" [ngClass]="{'active': currentTab?.id == tab?.id && currentTabIndex != farmerDetailsTabIndex}" id="pills-fp-tab" data-toggle="pill"
                  [href]="'#' + tab?.id" role="tab" aria-selected="true" (click)="tabClicked(i, false)">
                  {{tab?.survey_q_asset_labels[0]?.label}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-0">
        <!-- <div class="tab-pane" [id]="farmerDetailsTabIndex" [ngClass]="{'active': currentTabIndex == farmerDetailsTabIndex}" *ngIf="currentTabIndex == farmerDetailsTabIndex"> 
          <div class="view-profile-data mt-3">
            <h3 class="view-profile-title" i18n="@@personal">Personal</h3>
            <ul class="clearfix">
              <li *ngFor="let question of farmerRegQuestions">
                <div *ngIf="question.code !='photo_path'" class="repeater">
                  <span class="question-area">{{question.ques_text}}</span>
                  <span class="asnwer-area" *ngIf="question.ques_type!='select'">{{farmer[question.code]}}&nbsp;</span>
                  <span class="asnwer-area" *ngIf="question.ques_type=='select' && question.code!='village_area_id'">{{getMasterData(question.code,farmer[question.code])}}&nbsp;</span>
                  <span class="asnwer-area" *ngIf="question.code=='village_area_id'">{{villageName}}&nbsp;</span>
                </div>
                <div *ngIf="question.code =='photo_path'" class="repeater">
                  <span class="question-area">{{question.ques_text}}</span>
                  <span class="asnwer-area" *ngIf="farmer[question.code]" i18n="@@present">Present &nbsp;</span>
                  <span class="asnwer-area" *ngIf="!farmer[question.code]" i18n="@@notPresent">Not Present &nbsp;</span>
                </div>
              </li>
            </ul>
          </div>
        </div> -->
        <div class="tab-pane active" [id]="currentTab?.id" *ngIf="currentTabIndex != farmerDetailsTabIndex"> 
          <div *ngIf="farmerDiscontinued" class="text-center blue-medium pt-4 pb-4">Since the farmer is discontinued in either source or destination survey, we are unable to compare the answers. </div>
          
          <div *ngIf="!farmerDiscontinued">
            <div *ngIf="!currentTabExist" class="text-center blue-medium pt-4 pb-4" i18n="@@bothRevisionsIdentical">Both revisions are identical!</div> 
            <ng-container *ngFor="let tab of revisionComparisonData">            
              <div *ngIf="tab.id == currentTab?.id">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="indices pt-2" style="width:40%; display: inline-block;">
                      <span class="small-indices" i18n="@@edited">Edited</span>
                      <span class="small-indices" i18n="@@added">Added</span>
                      <span class="small-indices" i18n="@@deleted">Deleted</span>
                    </div>
                    <span class="source-answer-area pb-0 font-1-1 weight-400">{{revTextParams?.sourceText}}&nbsp;(<span i18n="@@source">Source</span>)</span>
                    <span class="target-answer-area pb-0 font-1-1 weight-400">{{revTextParams?.targetText}}&nbsp;(<span i18n="@@target">Target</span>)</span>
                  </div>
                </div>
                <div class="row scrollbar-1">
                  <div class="col-sm-12" *ngFor="let section of tab?.sections; let sectionIndex = index;" [ngClass]="{'hide': section.removed_from_snapshot }">
                    <div class="view-profile-data">
                      <h3 class="view-profile-title">{{section?.section_name}}</h3>
                      <ul class="clearfix">
                        <ng-container *ngFor="let question of section?.questions">
                          <li class="bg-none">
                            <span class="question-area bg-none">{{question?.survey_q_asset_labels[0].label}}</span>
                            <span class="target-answer-area" [ngClass]="question.marker == 'ADDED' ? 'added' : question.marker == 'UPDATED' ? 'edited' : 'deleted-q'">{{question?.targetAnswer}}&nbsp;
                              <span *ngIf="question.include_uom_question">
                                <span *ngFor="let uom of question.uom_questions; let i = index">
                                  <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                  {{uom?.answer}}
                                </span>
                              </span>
                              <span *ngIf="question.other_questions && question.other_questions.length > 0">
                              <span *ngIf="question.otherAnswer">-&nbsp;</span>
                                {{question.otherAnswer}}
                              </span>
                            </span>
                            <span class="source-answer-area">{{question?.sourceAnswer}}&nbsp;
                              <span *ngIf="question.otherAnswer">-&nbsp;{{question.otherAnswerSource}}</span> 
                              <span *ngIf="question.include_uom_question">
                                <span *ngFor="let uom of question.uom_questions; let i = index">
                                  <span *ngIf="i > 0 && uom?.answerSource" class="mr-1 ml-1">/</span>
                                  {{uom?.answerSource}}
                                </span>
                              </span>                           
                            </span>
                          </li>
                        </ng-container>
                        <ng-container *ngFor="let subsection of section?.subsections">
                          <li>
                            <ul class="clearfix" style="width:100%">
                              <h3 class="view-profile-title mt-0">{{subsection?.subsection_name}}</h3>
                              <ng-container *ngFor="let question of subsection?.questions">
                                <li class="bg-none">
                                  <span class="question-area bg-none">{{question?.survey_q_asset_labels[0].label}}</span>
                                  <span class="target-answer-area" [ngClass]="question.marker == 'ADDED' ? 'added' : question.marker == 'UPDATED' ? 'edited' : 'deleted-q'">{{question?.targetAnswer}}&nbsp;
                                    <span *ngIf="question.include_uom_question">
                                      <span *ngFor="let uom of question.uom_questions; let i = index">
                                        <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                        {{uom?.answer}}
                                      </span>
                                    </span>
                                    <span *ngIf="question.other_questions && question.other_questions.length > 0">
                                      <span *ngIf="question.otherAnswer">-&nbsp;</span>
                                      {{question.otherAnswer}}
                                    </span>
                                  </span>
                                  <span class="source-answer-area">{{question?.sourceAnswer}}&nbsp;
                                    <span *ngIf="question.otherAnswer">-&nbsp;{{question.otherAnswerSource}}</span>
                                    <span *ngIf="question.include_uom_question">
                                      <span *ngFor="let uom of question.uom_questions; let i = index">
                                        <span *ngIf="i > 0 && uom?.answerSource" class="mr-1 ml-1">/</span>
                                        {{uom?.answerSource}}
                                      </span>
                                    </span> 
                                  </span>
                                </li>
                              </ng-container>
                            </ul>
                          </li>
                        </ng-container>
                        <ng-container *ngFor="let table of section?.tables">
                          <li>
                            <ul class="clearfix" style="width:100%">
                              <h3 class="view-profile-title mt-0">{{table?.table_name}}</h3>
                              <div *ngFor="let key of secondary_asset_keys[table.id]" [id] = "key">
                                <ng-container *ngFor="let question of table?.questions">

                                  <span *ngIf="(question?.sourceAnswer[key] === undefined || question?.sourceAnswer[key] === null) ? question?.sourceAnswer[key] = '' : question?.sourceAnswer[key] = question?.sourceAnswer[key]"> </span>
                                  <span *ngIf="(question?.targetAnswer[key] === undefined || question?.targetAnswer[key] === null) ? question?.targetAnswer[key] = '' : question?.targetAnswer[key] = question?.targetAnswer[key]"> </span>
                                  
                                  <li class="bg-none" *ngIf="question?.targetAnswer[key] != question?.sourceAnswer[key]">
                                    <span class="question-area bg-none">{{question?.survey_q_asset_labels[0].label}}</span>
                                    <span class="target-answer-area" [ngClass]="question?.targetAnswer[key] && !question?.sourceAnswer[key] ? 'added' : !question?.targetAnswer[key] && question?.sourceAnswer[key] ? 'deleted-q' : 'edited'">{{question?.targetAnswer[key]}}&nbsp;
                                      <span *ngIf="question?.include_uom_question">
                                        <span *ngFor="let uom of question.uom_questions; let i = index">
                                          <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                          {{uom?.answer[key]}}
                                        </span>
                                      </span>
                                    </span>
                                    <span class="source-answer-area">{{question?.sourceAnswer[key]}}&nbsp;
                                      <span *ngIf="question?.include_uom_question && question?.sourceAnswer[key]">
                                        <span *ngFor="let uom of question.uom_questions; let i = index">
                                          <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                          {{uom?.answer[key]}}
                                        </span>
                                      </span>
                                    </span>
                                  </li>
                                </ng-container>
                              </div>
                            </ul>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
