import { Component, OnInit, TemplateRef, HostListener, Inject, LOCALE_ID } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/login.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SuppliersService } from '../../services/suppliers.service'
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/platform-browser';
import {Location} from '@angular/common';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styles: [
    `.alert_pd{
      padding-bottom: 0.6em;
      padding-top: 0.3em;
    }
    .scroll-to-top {
      position: fixed;
      bottom: 15px;
      right: 15px;
      opacity: 0;
      transition: all .2s ease-in-out;
     }
     .show-scrollTop {
      opacity: 1;
      transition: all .2s ease-in-out;
     }
    `
  ]
})
export class SupplierComponent implements OnInit {
  public dateFormat: any;
  public isOpen = false;
  public supplierForm: FormGroup;
  public editSupplierForm: FormGroup;
  addUserModal: BsModalRef;
  importUserModal: BsModalRef;
  updateUserModal: BsModalRef;
  removeUsersConfirmModal: BsModalRef;
  deacUserConfirmModal: BsModalRef;
  public f1Submitted = false;
  public supplierQuesData: any;
  public countryData: any = {};
  public autoSelectData: any = {};
  public multiSelectData: any = {};
  public tableData: any;
  public tableFinalData = [];
  public searchText: string;
  public valueOptions: any;
  public selectedOption: any;
  public updateSelectedOption: any;
  services = [];
  userTypes = [];
  serviceComponents = [];
  masterServiceComponentArray = [];
  public serviceComponentsMapping = [];
  rowsToShow = [];
  public servicesArray = [];
  public componentsArray = [];
  // userFilter: any = { name: '' };
  userFilter: any = {};
  public editSupplierData: any;
  editSupplierDataFarmers;
  allFarmersMappedToVCNotInConfirmed;
  editSupplierDataFarmersAllVillages;
  public isDeepRoots: boolean = false;
  public currentUser = this.userService.getCurrentUser();
  public origin: any;
  public fromNavId;
  public menu_type: any;
  public page = 1;
  public itemsPerPage = 10;

  public previousPaginationLabel;
  public nextPaginationLabel;
  public confirmModal = true;
  public confirmUpdateModal = true;
  public clickLink;
  public windowScrolled: boolean;
  public customdata = [];
  public customdataModified = [];
  public updatingEcosystemId;
  public deletingEcosystemId;
  public userTypeAddArr: Array<any> = [];
  public userTypeCheckedForAdd = [];
  public psmCheckedArray = [];
  public addForm = false;
  public editForm = false;
  showExportButtonHere = false;
  public typeOfAdministrationArr: any[];
  typeOfAdministration = '';
  currentLocale = '';
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Un-Select All',
    allowSearchFilter: true
  };

  //angular dual list
  keepSorted = true;
  key: string;
  dualListKey:string;
	display: any;
	filter = false;
	source: Array<any>;
	confirmed: Array<any>;
	userAdd = '';
	disabled = false;

	sourceLeft = true;
  format: any = DualListComponent.DEFAULT_FORMAT;
  private sourceFarmers: Array<any>;
  public farmers:Array<any> = [];
  private confirmedFarmers: Array<any>;
  farmersInProject = [];
  vcFarmerMappingData;
  ecosystem_id;

  constructor(
    private readonly commonService: CommonService,
    private readonly router: Router,
    private readonly _route: ActivatedRoute,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly toastrService: ToastrService,
    private readonly loginService: LoginService,
    private readonly fb: FormBuilder,
    private readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly suppService: SuppliersService,
    private readonly userService: UserService,
    private readonly activeRoute: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly location: Location,
    private readonly localeService: BsLocaleService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {

    this.activeRoute.paramMap.subscribe(params => {
      this.activeRoute.queryParamMap.subscribe(queryParams => {
        const qpObject = { ...queryParams.keys, ...queryParams };
        this.origin = qpObject['params']['origin'];
        this.fromNavId = qpObject['params']['from'];

        this.translate.get('typeAdminTextArr').subscribe((val) => {
          this.typeOfAdministrationArr = val;
        }, err => console.log('err', err));

        if (this.fromNavId == 2) {
          this.typeOfAdministration = this.typeOfAdministrationArr[0];
        }
        else if (this.fromNavId == 3) {
          this.typeOfAdministration = this.typeOfAdministrationArr[1];
        }
        else if (this.fromNavId == 6) {
          this.typeOfAdministration = this.typeOfAdministrationArr[2];
        }

        
      })
    });

    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });

    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });

    this.translate.get('selectAllText').subscribe(result => {
      this.dropdownSettings.selectAllText = result;
    });

    this.translate.get('unSelectAllText').subscribe(result => {
      this.dropdownSettings.unSelectAllText = result;
    });

    this.localeService.use(this.locale);

  }

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
  
    if(this.addForm){
   
      this.hideAddUserModal(this.supplierForm);
    }else if(this.editForm){
   
      this.hideUpdateModal(this.editSupplierForm);
    }

  }

  ngOnInit() {
    this.currentLocale = `field_label_${this.locale.replace('-US', '')}`;
    this.scrollToTop();
    this.supplierForm = this.fb.group({
    });
    this.editSupplierForm = this.fb.group({
    });
    this.ecosystem_id = localStorage.getItem('ecosystem_id');

    this.getMasterData();
    this.services = this._route.snapshot.data.commonData.services.data;
    this.userTypes = this._route.snapshot.data.commonData.rolesToMapToEcosystem.data;
    this.serviceComponents = this._route.snapshot.data.commonData.serviceComponents.data;
    for (let i = 0; i < this.userTypes.length; i++) {
      this.userTypes[i].uniqueid = this.userTypes[i]['id'] + this.userTypes[i]['code'];
    }
    for (let i = 0; i < this.serviceComponents.length; i++) {
      if (this.serviceComponentsMapping[this.serviceComponents[i]['services_id']]) {
        this.serviceComponentsMapping[this.serviceComponents[i]['services_id']]++;
      } else {
        this.serviceComponentsMapping[this.serviceComponents[i]['services_id']] = 0;
      }
    }

    for (let i = 0; i < this.services.length; i++) {
      let componentsInService;
      this.masterServiceComponentArray.push(this.services[i]);
      componentsInService = this.serviceComponents.filter(item => item.services_id == this.services[i].id);
      if (componentsInService && componentsInService.length > 0) {
        const componentsInService2 = this.masterServiceComponentArray.concat(componentsInService);
        this.masterServiceComponentArray = componentsInService2;
      } else {
        this.services[i]['noComponents'] = true;
      }
    }
    
    for (let i = 0; i < this.masterServiceComponentArray.length; i++) {
      this.masterServiceComponentArray[i]['opened'] = true;
      if (this.masterServiceComponentArray[i].hasOwnProperty('component_service_level')) {
        this.masterServiceComponentArray[i]['displaycheck'] = true;
      } else {
        if (this.masterServiceComponentArray.find(item => item.services_id
          === this.masterServiceComponentArray[i]['id']) === undefined) {
          this.masterServiceComponentArray[i]['displaycheck'] = true;
        } else {
          this.masterServiceComponentArray[i]['displaycheck'] = false;
        }
      }
      if (this.masterServiceComponentArray[i].services_id) {
        this.rowsToShow.push(this.masterServiceComponentArray[i].id);
      }
      this.masterServiceComponentArray[i]['uniqueid'] = this.masterServiceComponentArray[i]['id'] + this.masterServiceComponentArray[i]['code'];

      if (this.masterServiceComponentArray[i]['services_id']) {
        this.masterServiceComponentArray[i]['uniqueid'] = `${this.masterServiceComponentArray[i]['uniqueid']}C`;
      }
      else {
        this.masterServiceComponentArray[i]['uniqueid'] = `${this.masterServiceComponentArray[i]['uniqueid']}S`;
      }
    }
  }

  psmChecked(event: any, item, parentServiceEvent?) {
    if (parentServiceEvent) {
      // if the parent service checkbox is checked, add the component to customData
      if (parentServiceEvent.target.checked) {
        try {
          this.supplierForm.removeControl(item.uniqueid);
        } catch { }
        this.supplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
        
        if (item.services_id) {
          this.customdata = this.customdata.filter(one => one.components_id !== item.id);
          this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id })
        }
        else {
          this.customdata = this.customdata.filter(one => one.services_id !== item.id);
          this.customdata.push({ 'name': item.name, 'services_id': item.id })
        }
        this.psmCheckedArray.push(item.uniqueid);
      }
      // if parent service checkbox is unchecked, remove all child components from customData
      else {
        try {
          this.supplierForm.removeControl(item.uniqueid);
        } catch { }
        this.supplierForm.addControl((item.uniqueid), new FormControl());
        if (item.services_id) {
          this.customdata = this.customdata.filter(one => one.components_id !== item.id);
        }
        // this is a noComponents service (service without child components)
        else {
          this.customdata = this.customdata.filter(one => one.services_id !== item.id);
        }
        this.psmCheckedArray = this.psmCheckedArray.filter(one => one !== item.uniqueid);
      }
    }
    else {
      if (event.target.checked) {
        if (item.hasOwnProperty('component_service_level')) {
          this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id })
        } else {
          this.customdata.push({ 'name': item.name, 'services_id': item.id });
        }
      } else {
        if (item.services_id) {
          this.customdata = this.customdata.filter(one => one.components_id !== item.id);
        }
        // this is a noComponents service (service without child components)
        else {
          this.customdata = this.customdata.filter(one => one.services_id !== item.id);
        }
      }
    }
  }

  psmCheckedEdit(event: any, item, parentServiceEvent?) {

    if (parentServiceEvent) {
      // if the parent service checkbox is checked, add the component to customData
      if (parentServiceEvent.target.checked) {
        try {
          this.editSupplierForm.removeControl(item.uniqueid);
        } catch { }
        this.editSupplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));

        if (item.services_id) {
          this.customdata = this.customdata.filter(one => one.components_id !== item.id);
          
          this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id })
        }
        else {
          if (this.customdata[0]['services'] && this.customdata[0]['services']['name']) {
            this.customdata = this.customdata.filter(one => one.services_id !== item.id);
          }
          else {
            console.log('');
            this.customdata = this.customdata.filter(one => one.services_id !== item.id);
          }
          this.customdata.push({ 'name': item.name, 'services_id': item.id })
        }
      }
      // if parent service checkbox is unchecked, remove all child components from customData
      else {
        try {
          this.editSupplierForm.removeControl(item.uniqueid);
        } catch { }
        this.editSupplierForm.addControl((item.uniqueid), new FormControl());
        if (item.services_id) {
          this.customdata = this.customdata.filter(one => one.components_id !== item.id);
          
        }
        else {
          if (this.customdata[0]['services'] && this.customdata[0]['services'['name']]) {
            this.customdata = this.customdata.filter(one => one.services_id !== item.id);
            
          }
          else {
            console.log('');
            this.customdata = this.customdata.filter(one => one.services_id !== item.id);
            
          }
        }
      }
    }
    else {
      if (event.target.checked) {
        if (item.hasOwnProperty('component_service_level')) {
          this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id })
        } else {
          this.customdata.push({ 'name': item.name, 'services_id': item.id });
        }
      } else {

        // this is a component
        if (item.services_id) {
          this.customdata = this.customdata.filter(one => one.components_id !== item.id);
        }
        // this is a noComponents service (service without child components)
        else {
          this.customdata = this.customdata.filter(one => one.services_id !== item.id);
        }

        
      }
    }
  }

  psmCheckedParentService(event, item) {

    const componentsToToggle = this.masterServiceComponentArray.filter(one => one.services_id == item.id);
    

    if (componentsToToggle && componentsToToggle.length > 0) {
      this.psmChecked(null, item, event);
      for (let i = 0; i < componentsToToggle.length; i++) {
        // if the parent service checkbox was checked, then check all the component checkboxes
        if (event.target.checked) {
          this.psmChecked(null, componentsToToggle[i], event);
        }
        // if the box was unchecked then uncheck all component checkboxes
        else {
          console.log('');
          this.psmChecked(null, componentsToToggle[i], event);
        }
      }
    }
  }

  psmCheckedEditParentService(event, item) {
 

    const componentsToToggle = this.masterServiceComponentArray.filter(one => one.services_id == item.id);


    if (componentsToToggle && componentsToToggle.length > 0) {
      for (let i = 0; i < componentsToToggle.length; i++) {
        if (event.target.checked) {
          this.psmCheckedEdit(null, componentsToToggle[i], event);
        }
        // if the box was unchecked then uncheck all component checkboxes
        else {
          console.log('');
          this.psmCheckedEdit(null, componentsToToggle[i], event);
        }
      }
    }
  }

  psmCheckedAll(event) {
    
    if (this.masterServiceComponentArray && this.masterServiceComponentArray.length > 0) {
      this.customdata = []; 
      this.psmCheckedArray = [];
      for (let i = 0; i < this.masterServiceComponentArray.length; i++) {
        // if the parent service checkbox was checked, then check all the component checkboxes
        const item = this.masterServiceComponentArray[i];
        if (event.target.checked) {
          try {
            this.supplierForm.removeControl(item.uniqueid);
          } catch { }
          this.supplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
          if (item.services_id) {
            this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id })
          }
          else {
            this.customdata.push({ 'name': item.name, 'services_id': item.id })
          }
          this.psmCheckedArray.push(item.uniqueid);
          this.supplierForm.markAsDirty();
        }
        else if (!event.target.checked && item.is_modifiable == 'N' && item.is_default == 'Y') {
          
          try {
            this.supplierForm.removeControl(item.uniqueid);
          } catch {
          }
          this.supplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
          if (item.services_id) {
            this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id })
          }
          else {
            this.customdata.push({ 'name': item.name, 'services_id': item.id })
          }
          this.psmCheckedArray.push(item.uniqueid);
          this.supplierForm.markAsDirty();
        }
        // if the box was unchecked then uncheck all component checkboxes
        else {
          try {
            this.supplierForm.removeControl(item.uniqueid);
          } catch { }
          this.supplierForm.addControl((item.uniqueid), new FormControl());
          this.psmCheckedArray = this.psmCheckedArray.filter(one => one !== item.uniqueid);
          if (item.services_id) {
            this.customdata = this.customdata.filter(one => one.components_id !== item.id);
          }
          else {
            this.customdata = this.customdata.filter(one => one.services_id !== item.services_id);
          }
        }
      }
    }
  }

  psmCheckedEditAll(event) {

    if (this.masterServiceComponentArray && this.masterServiceComponentArray.length > 0) {
      this.customdata = [];
      for (let i = 0; i < this.masterServiceComponentArray.length; i++) {
        // if the parent service checkbox was checked, then check all the component checkboxes
        const item = this.masterServiceComponentArray[i];
        if (event.target.checked) {
          try {
            this.editSupplierForm.removeControl(item.uniqueid);
          } catch { }
          this.editSupplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
          if (item.services_id) {
            this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id })
          }
          else {
            this.customdata.push({ 'name': item.name, 'services_id': item.id })
          }
        }
        else if (!event.target.checked && item.is_modifiable == 'N' && item.is_default == 'Y') {
          
          try {
            this.editSupplierForm.removeControl(item.uniqueid);
          } catch {
          }
          this.editSupplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
          if (item.services_id) {
            this.customdata.push({ 'name': item.name, 'services_id': item.services_id, 'components_id': item.id })
          }
          else {
            this.customdata.push({ 'name': item.name, 'services_id': item.id })
          }
        }
        // if the box was unchecked then uncheck all component checkboxes
        else {
          try {
            this.editSupplierForm.removeControl(item.uniqueid);
          } catch { }
          this.editSupplierForm.addControl((item.uniqueid), new FormControl());
          this.customdata = [];
        }
      }
      this.editSupplierForm.markAsDirty();
    }
  }

  userTypeCheckedEditAll(event) {

    if (this.userTypes && this.userTypes.length > 0) {
      this.customdata = [];
      for (let i = 0; i < this.userTypes.length; i++) {
        // if the parent service checkbox was checked, then check all the component checkboxes
        const item = this.userTypes[i];
        if (event.target.checked) {
          try {
            this.editSupplierForm.removeControl(item.uniqueid);
          } catch { }
          this.editSupplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
          this.customdata.push({ 'name': item.name, 'user_type_id': item.id })
        }
        // if the box was unchecked then uncheck all component checkboxes
        else {
          try {
            this.editSupplierForm.removeControl(item.uniqueid);
          } catch { }
          this.editSupplierForm.addControl((item.uniqueid), new FormControl());
          this.customdata = [];
        }
      }
      this.editSupplierForm.markAsDirty();
    }
  }

  userTypeCheckedAll(event) {

    if (this.userTypes && this.userTypes.length > 0) {
      this.customdata = [];
      for (let i = 0; i < this.userTypes.length; i++) {
        // if the parent service checkbox was checked, then check all the component checkboxes
        const item = this.userTypes[i];
        if (event.target.checked) {
          try {
            this.supplierForm.removeControl(item.uniqueid);
          } catch { }
          this.supplierForm.addControl((item.uniqueid), new FormControl({ checked: true }));
          this.customdata.push({ 'name': item.name, 'user_type_id': item.id })
          this.userTypeCheckedForAdd.push(item.uniqueid);
        }
        // if the box was unchecked then uncheck all component checkboxes
        else {
          try {
            this.supplierForm.removeControl(item.uniqueid);
          } catch { }
          this.supplierForm.addControl((item.uniqueid), new FormControl());
          this.customdata = [];
          this.userTypeCheckedForAdd = [];
        }
      }
      this.supplierForm.markAsDirty();
    }
  }

  userTypeChecked(event: any, item) {
    if (event.target.checked) {
      this.customdata.push({ 'name': item.name, 'user_type_id': item.id })
      this.userTypeCheckedForAdd.push(item.uniqueid);
    } else {
      this.customdata.splice(this.customdata.indexOf(this.customdata.find(data => data.user_type_id === item.id)), 1);
      this.userTypeCheckedForAdd = this.userTypeCheckedForAdd.filter(one => one !== item.uniqueid);
    }
  }

  public inputValidator(event: any) {
    const pattern = RegExp(/^[a-zA-Z0-9 \r\n]+$/);
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9 (,)/=<>_*"&\n!?.-]/g, '');
      // invalid character, prevent input
    }
  }

  mdmFilterData(data) {
    return data;
  }

  serviceOpenCloseToggle(item): void {
    const clickedItem = this.masterServiceComponentArray.filter(one => one.id === item.id);
    const clickedItemObj = clickedItem[0];
    const index = this.masterServiceComponentArray.indexOf(clickedItemObj);
    this.masterServiceComponentArray[index]['opened'] = !this.masterServiceComponentArray[index]['opened'];

    if (!this.masterServiceComponentArray[index]['opened']) {
      const childItems = this.masterServiceComponentArray.filter(one => one.services_id === item.id);
      if (childItems && childItems.length > 0) {
        for (let i = 0; i < childItems.length; i++) {
          const tempRowsToShow = this.rowsToShow.filter(item => item !== childItems[i].id);
          this.rowsToShow = tempRowsToShow;
        }
      }
    } else {
      const childItems = this.masterServiceComponentArray.filter(one => one.services_id == item.id);
      if (childItems && childItems.length > 0) {
        for (let i = 0; i < childItems.length; i++) {
          this.rowsToShow.push(childItems[i].id);
        }
      }
    }
  }

  getMDMDataValues() {
    this.suppService.getMDMDataValues(this.origin).subscribe(response => {
      this.tableData = response;
      this.tableFinalData = [];
      const finalObj = this.tableData;
      if (this.origin == 'VC_FARMER_MAPPING_MDM') {
        this.vcFarmerMappingData = Object.assign([], response.data);
        for (let i = 0; i < this.tableData.data.length; i++) {
          this.tableData.data[i]['users_id'] = this.tableData.data[i]['users']['name'];
          this.tableData.data[i]['project_id'] = this.tableData.data[i]['Project']['name'];
          this.tableData.data[i]['village_name'] = this.tableData.data[i]['farmer_reg']['region_village']['village_name'];
          this.tableData.data[i]['user_category'] = {};
          this.tableData.data[i]['user_category']['name'] = this.tableData.data[i]['users']['user_category']['name'];
          this.tableData.data[i]['user_category_id'] = this.tableData.data[i]['users']['user_category']['name'];
        }
      }
      this.tableData.data.forEach(element => {
        element.isAscending = false;
      });

      const newRecord = this.supplierQuesData.data.filter(item => item.field_type === 'dropdown' && item.value_options != null);
      
      const newObject = {};
      for (let valueItem of newRecord) {
        this.tableData.data.forEach(element => {
          const filteredobj = (valueItem.valueOptions.filter(ele => {
            return ele.id === element[valueItem.mapping_table_field]
          }));
          if (filteredobj && filteredobj.length > 0) {
            element[valueItem.mapping_table_field] = filteredobj[0].name;
          }

        });
      }

      if (this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM' || this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
        for (const finalObjCurr of finalObj.data) {
          this.tableFinalData.push(finalObjCurr);
        }
        this.tableFinalData.forEach(snap => {
          snap.customAssociates = snap.customAssociates.split(',');
        });
        if (this.tableFinalData && this.origin == 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM' && this.countryData['77']['data']) {
          const ecosystemsAlreadyMapped = this.tableFinalData.map(item => item.ecosystem_id);
          
        }
        else if (this.tableFinalData && this.origin == 'ECOSYSTEM_USER_TYPE_MAPPING_MDM' && this.countryData['78']['data']) {
          const ecosystemsAlreadyMapped = this.tableFinalData.map(item => item.ecosystem_id);
          
        }

      } else if (this.origin === 'ECOSYSTEM_USERS_MAPPING_MDM') {
        for (const finalObjCurr of finalObj.data) {
          const tmpObj = [];
          for (const supplierQuesData of this.supplierQuesData.data) {
              tmpObj.push(finalObjCurr[supplierQuesData.mapping_table_field]);
          }
          this.tableFinalData.push(tmpObj);
        }
        if (this.countryData['70'].data) {
          const x = this.countryData['70'].data.filter(item => item.user_type_id < 4);
        this.countryData['70'].data = x;}
      } else if (this.origin === 'USERPROGRAMMAPPING_MDM' && this.countryData['24'].data) {
        const x = this.countryData['24'].data.filter(item => item.user_type_id > 3);
        this.countryData['24'].data = x;
      } else if (this.origin == 'VC_FARMER_MAPPING_MDM') {
        const newTableData = [];
        for (let i = 0; i < this.tableData.data.length; i++) {
          const x = newTableData.find(each => each.farmer_reg.region_village.village_name === this.tableData.data[i].farmer_reg.region_village.village_name && each.users.name == this.tableData.data[i].users.name && each.farmer_reg.project_id == this.tableData.data[i].farmer_reg.project_id);
          
          if (!x) {
            newTableData.push(this.tableData.data[i]);
          }
        }
        this.tableData.data = newTableData;
      }
      
      else {
        for (const finalObjCurr of finalObj.data) {
          const tmpObj = [];
          for (const supplierQuesData of this.supplierQuesData.data) {
            if (supplierQuesData.is_ref_modal === 'Y') {
              this.origin == 'VC_FARMER_MAPPING_MDM' ? tmpObj.push(finalObjCurr['Project']['name']) : tmpObj.push(finalObjCurr[supplierQuesData.ref_modal_name]['name']);
              if (this.origin === 'USERPROGRAMMAPPING_MDM' && supplierQuesData.ref_modal_name === 'users') {
                tmpObj.push(finalObjCurr[supplierQuesData.mapping_table_field]);
              }
            } else {
              tmpObj.push(finalObjCurr[supplierQuesData.mapping_table_field]);
            }
          }
          this.tableFinalData.push(tmpObj);
        }
        if (this.origin === 'UNITS_MDM' && this.tableData.data && this.tableData.data.length > 0 && this.countryData[50]) {
          for (let i = 0; i < this.tableData.data.length; i++) {
            const intValue = this.countryData[50].data.filter(item => item.international_unit_to_save == this.tableData.data[i]['international_unit']);
            
            if (intValue && intValue.length > 0) {
              this.tableData.data[i]['international_unit'] = intValue[0]['international_unit'];
            }
          }
        }
      }
    });
  }

  getGridFilters() {
    const finalObj = {};
    if (this.supplierQuesData === undefined) {
      return {};
    }
    if (this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM' || this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
      finalObj['name'] = this.searchText;
    } else if (this.origin === 'ECOSYSTEM_USERS_MAPPING_MDM') {
      this.supplierQuesData.data.forEach(element => {
          finalObj[element.mapping_table_field] = this.searchText;
      });
    } else {
      this.supplierQuesData.data.forEach(element => {
        if (element.is_ref_modal === 'Y') {
          finalObj[element.ref_modal_name] = this.searchText;
        } else {
          finalObj[element.mapping_table_field] = this.searchText;
        }
      });
    }
    return finalObj;
  }

  getMasterData() {
    this.suppService.getMaster(this.origin).subscribe(response => {
      this.supplierQuesData = response;


      let validationsArray;
      for (let i = 0; i < this.supplierQuesData.data.length; i++) {
        this.supplierQuesData.data[i].validations = {};
        const validatorArr = [];
        if (this.supplierQuesData.data[i].mandatory === 'Y') {
          validatorArr.push(Validators.required);
        }

        if (this.supplierQuesData.data[i].MDMValidation != null) {
          validationsArray = JSON.parse(this.supplierQuesData.data[i].MDMValidation.validation_obj);
          for (let j = 0; j < validationsArray.length; j++) {
            if (validationsArray[j].type === 'min') {
              this.supplierQuesData.data[i].validations['minlength'] = validationsArray[j].value;
              validatorArr.push(Validators.minLength(validationsArray[j].value));
            }

            if (validationsArray[j].type === 'max') {
              this.supplierQuesData.data[i].validations['maxlength'] = validationsArray[j].value;
              validatorArr.push(Validators.maxLength(validationsArray[j].value));
            }

            if (validationsArray[j].type === 'email') {
              validatorArr.push(Validators.email);
            }

            if (validationsArray[j].type === 'number') {
              validatorArr.push(Validators.pattern('^[0-9]{5,13}$'));
            }
          }
        }

        if (this.supplierQuesData.data[i].field_type === 'dropdown' ) {
          if (this.supplierQuesData.data[i].value_options === null && this.supplierQuesData.data[i].api_url) {
            this.countryData[this.supplierQuesData.data[i].id] = {};
            this.suppService.getAPIURLMethod(this.supplierQuesData.data[i].api_url).subscribe(response => {
              
              this.countryData[this.supplierQuesData.data[i].id] = response;
             
            });            

            this.supplierForm.addControl(this.supplierQuesData.data[i].id, new FormControl({
              disabled: this.supplierQuesData.data[i].readonly === 'Y' ? true : false
            }, validatorArr));
          } else if (this.supplierQuesData.data[i].value_options){

            this.valueOptions = JSON.parse(this.supplierQuesData.data[i].value_options);
            this.supplierQuesData.data[i]['valueOptions'] = this.valueOptions;
            this.supplierForm.addControl(this.supplierQuesData.data[i].id, new FormControl({
              value: this.valueOptions[0].id,
              disabled: this.supplierQuesData.data[i].readonly === 'Y' ? true : false
            }, validatorArr));
          } else {
            this.supplierForm.addControl(this.supplierQuesData.data[i].id, new FormControl({
              disabled: this.supplierQuesData.data[i].readonly === 'Y' ? true : false
            }, validatorArr));
          }

        } else if (this.supplierQuesData.data[i].field_type === 'autoselect') {

          if (this.supplierQuesData.data[i].value_options === null) {
            this.autoSelectData[this.supplierQuesData.data[i].id] = {};
            this.suppService.getAPIURLMethod(this.supplierQuesData.data[i].api_url).subscribe(response => {
              this.autoSelectData[this.supplierQuesData.data[i].id] = response;
            });

            this.supplierForm.addControl(this.supplierQuesData.data[i].id, new FormControl({
              disabled: this.supplierQuesData.data[i].readonly === 'Y' ? true : false
            }, validatorArr));
          }

        } else if (this.supplierQuesData.data[i].field_type === 'multiselect') {

          if (this.supplierQuesData.data[i].value_options === null) {
            this.multiSelectData[this.supplierQuesData.data[i].id] = {};
            this.suppService.getAPIURLMethod(this.supplierQuesData.data[i].api_url).subscribe(response => {
              this.multiSelectData[this.supplierQuesData.data[i].id] = response;
            });

            this.supplierForm.addControl(this.supplierQuesData.data[i].id, new FormControl({
              disabled: this.supplierQuesData.data[i].readonly === 'Y' ? true : false
            }, validatorArr));
          }

        } else if (this.supplierQuesData.data[i].field_type === 'date') {
          this.supplierForm.addControl(this.supplierQuesData.data[i].id, new FormControl({
            value: null,
            disabled: this.supplierQuesData.data[i].readonly === 'Y' ? true : false
          }, validatorArr));
          this.dateFormat = 'DD-MMM-YYYY';
        } else if (this.supplierQuesData.data[i].field_type === 'duallist') {
          if (this.supplierQuesData.data[i].value_options === null) {
            
            this.countryData[this.supplierQuesData.data[i].id] = {};
           
          } 
        }else {
          this.supplierForm.addControl(this.supplierQuesData.data[i].id, new FormControl({
            value: null,
            disabled: this.supplierQuesData.data[i].readonly === 'Y' ? true : false
          }, validatorArr));
        }
      }
      this.getMDMDataValues();
    });
  }

  // convenience getter for easy access to form fields
  get f1() { 
    return this.supplierForm.controls; 
  }

  openModal(template: TemplateRef<any>, importUserModalBool: boolean) {

    if (importUserModalBool) {
      this.importUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    } else {
      
      this.confirmModal = true;
      this.addUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
      this.addForm = true;
      this.supplierForm.reset();
    }
    if (this.origin == 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
      this.userTypeCheckedForAdd = [];
    }
    this.customdata = [];

    if (this.origin == 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM') {
      this.customdata = [];
      this.psmCheckedArray = [];
      for (let i = 0; i < this.masterServiceComponentArray.length; i++) {
        if (this.masterServiceComponentArray[i]['is_default'] == 'Y') {
          if (this.masterServiceComponentArray[i].hasOwnProperty('component_service_level')) {
            this.customdata.push({ 'name': this.masterServiceComponentArray[i].name, 'services_id': this.masterServiceComponentArray[i].services_id, 'components_id': this.masterServiceComponentArray[i].id })
          } else {
            this.customdata.push({ 'name': this.masterServiceComponentArray[i].name, 'services_id': this.masterServiceComponentArray[i].id });
          }
          this.psmCheckedArray.push(this.masterServiceComponentArray[i]['uniqueid']);
        }
      }
    }

    if (this.origin == 'VC_FARMER_MAPPING_MDM') {
      this.farmers = [];
      this.source = [];
      this.confirmed = [];

    }
  }

  randomString(length) {
    return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedOption = event.item;
  }


  onMultiSelect(item: any, key: string, yesNo?: boolean) {
    
    this.addItem([item], key, yesNo);
  }

  onSelectAll(items: any, key: string, yesNo?: boolean) {

    this.addItem(items, key, yesNo);
  }

  onDeSelect(item: any, key: string, yesNo?: boolean) {
    this.removeItem([item], key, yesNo);
  }

  onDeSelectAll(items: any, key: string, yesNo?: boolean) {
    this.removeItem(items, key, yesNo);
  }

  addItem(items: any, key: string, yesNo: boolean) {
    
  }

  removeItem(items: any, key: string, yesNo: boolean) {

  }

  submitForm(form: any, event: any) {    
    this.f1Submitted = true;
    if (form.valid) {
      const newRequestArray = [];
      const formCode = {
        'form_code': this.origin,
        'cr_user_id': this.currentUser.id,
        'upd_user_id': this.currentUser.id,
        'data': []
      }
      let newRequest: any = [];
      for (let x in form.getRawValue()) {
        const result = this.supplierQuesData.data.filter(item => item.id === +x);
        let selectId;
        if (this.selectedOption) {
          selectId = this.selectedOption.id;
        }
        if (result.length > 0)
          if (form.getRawValue()[x] === null || form.getRawValue()[x] === '') {
           
          } else {
            if (this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM')
              formCode['ecosystem_id'] = form.getRawValue()[x];

            if (this.selectedOption && this.selectedOption.name === form.getRawValue()[x]) {
              newRequest = {
                "id": x, "value": selectId, "mapping_table": result[0].mapping_table,
                "mapping_table_field": result[0].mapping_table_field
              };
            } else {
              newRequest = {
                "id": x, "value": form.getRawValue()[x], "mapping_table": result[0].mapping_table,
                "mapping_table_field": result[0].mapping_table_field
              };
            }

          }
        formCode.data.push(newRequest);
      }
      if (this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM') {
        
        this.customdataModified = this.customdata;
        
        const servicesArray = [];
        for (let i = 0; i < this.customdataModified.length; i++) {
          if (!this.customdataModified[i]['components_id']) {
            servicesArray.push(this.customdataModified[i]);
          }
        }
        let itemsToRemove = [];
        for (let i = 0; i < servicesArray.length; i++) {
          const isComponentSelected = this.customdataModified.filter(item => item.components_id != null && item.services_id === servicesArray[i]['services_id']);
          
          if (isComponentSelected && isComponentSelected.length > 0) {

            // in customdataModified, keep the array items that have a component id & service id is equal to servicesArray[i]['id']
            // keep the items that do not have a component id and services id is not equal to servicesArray[i]['id']
            itemsToRemove = itemsToRemove.concat(this.customdataModified.filter(item => item.components_id == null && item.services_id == servicesArray[i]['services_id']));
            
          }
        }
        for (let i = 0; i < itemsToRemove.length; i++) {
          // in customdataModified, keep the array items that have a component id or that do not have a component id and the services_id is not equal to itemsToRemove[i]['id']
          this.customdataModified = this.customdataModified.filter(item => item.components_id != null || (item.components_id == null && item.services_id !== itemsToRemove[i]['services_id']));
        }

        formCode['customobj'] = this.customdataModified;
      
      }

      if (this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
        formCode['ecosystem_id'] = formCode.data[0].value;
        formCode['customobj'] = this.customdata;
        delete formCode.data;
      }


      if (this.origin === 'ECOSYSTEM_USERS_MAPPING_MDM') {
        const ecoid = formCode.data.find(ucval => ucval.mapping_table_field === 'ecosystem_id');
        const ucid = formCode.data.find(ucval => ucval.mapping_table_field === 'user_category_id');
   
        if (ecoid.value == '-1' && ucid.value != 1) {
          this.commonService.showToast('error', 'Only users with Firmenich category can map to All ecosystems');
          return false;
        }
      }

      if(this.origin === 'VC_FARMER_MAPPING_MDM'){
        let farmerObject={};
        for (let i = 0; i < this.supplierQuesData.data.length; i++) {
          if(this.supplierQuesData.data[i].id == 83){
            farmerObject ={
              'id':this.supplierQuesData.data[i].id.toString(),
              'mapping_table':this.supplierQuesData.data[i].mapping_table,
              'mapping_table_field':this.supplierQuesData.data[i].mapping_table_field,
              'value':this.confirmed
            }
            formCode.data.push(farmerObject);
          }
        }
      }
      this.suppService.saveMDM(formCode).subscribe(response => {
        let responseData = response;
        if (responseData.status === 'success') {
          if (this.addUserModal) {
            this.addUserModal.hide();
            this.userTypeCheckedForAdd = [];
          }
          this.commonService.showToast('success', responseData.code, {});
          // If a unit is being added, it needs time to be mapped to the ecosystem
          // Refresh the table with a delay
          if (this.origin == 'UNITS_MDM') {
            setTimeout(() => {
              this.getMDMDataValues();
            }, 3000); 
            this.commonService.showToast('warning', 'update_ecosystem_settings', {});
          } 
          // For non-Units MDMs, refresh the table immediately
          else {
            this.getMDMDataValues();
          }
          this.f1Submitted = false;
          this.supplierForm.reset();
          this.getMasterData();
          this.customdata = [];
          this.psmCheckedArray = [];
        } else {
          this.commonService.showToast('error', responseData.code, {});
        }
      });

    }
  }

  editThisUser(itemValue: any, template: TemplateRef<any>) {
    
    this.confirmUpdateModal = true;
    const newObject = {
      'id': itemValue,
      'form_code': this.origin,
    };

    if (this.origin == 'VC_FARMER_MAPPING_MDM') {
      this.farmers = [];
      this.source = [];
      this.confirmed = [];
    }

    this.suppService.getMDMDataValues(this.origin).subscribe(response => {
      const responseData = response;
      if (responseData.status === 'success') {
        if (this.origin === 'VC_FARMER_MAPPING_MDM') {
          this.editSupplierDataFarmers = response.data.filter(item => item.project_id === response.data.find(each => each.id === itemValue)['project_id']);
          for (let i = 0; i < this.editSupplierDataFarmers.length; i++) {
            this.editSupplierDataFarmers[i]['user_category_id'] = this.editSupplierDataFarmers[0]['users']['user_category']['id'];  
          }
        }
        this.editSupplierData = response.data.filter(item => item.id === itemValue)[0];
        

        const drpdownpatcharr = {};
        let validationsArray;
        for (let i = 0; i < this.supplierQuesData.data.length; i++) {
          if (this.autoSelectData[this.supplierQuesData.data[i].id] && this.supplierQuesData.data[i].mapping_table_field === 'country_id' && this.editSupplierData.hasOwnProperty('country_id')) {
            const countryName = this.autoSelectData[this.supplierQuesData.data[i].id].data.filter(item => item.id === this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field])[0];
            
            drpdownpatcharr[this.supplierQuesData.data[i].id] =
              (this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === null ||
                this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === undefined) ? '' :
                countryName.name; 
                //this.multiSelectData[this.supplierQuesData.data[i].id];
          } else if (this.multiSelectData[this.supplierQuesData.data[i].id] && this.supplierQuesData.data[i].mapping_table_field === 'project_id' && this.editSupplierData.hasOwnProperty('project_id')) {
            const multiValItem = this.multiSelectData[this.supplierQuesData.data[i].id].data.filter(item => item.id === this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field])[0];
            
            drpdownpatcharr[this.supplierQuesData.data[i].id] =
              (this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === null ||
                this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === undefined) ? '' :
                [{ 'id': multiValItem.id, 'name': multiValItem.name }];
            
          } else if (this.supplierQuesData.data[i].field_type === 'date') {
            const dateOfEnroll = new Date(this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field]);
            drpdownpatcharr[this.supplierQuesData.data[i].id] =
              (this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === null ||
                this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === undefined) ? '' :
                dateOfEnroll;
          } else {
            if (this.supplierQuesData.data[i].form_code == 'ECOSYSTEM_USERS_MAPPING_MDM') {
              drpdownpatcharr[this.supplierQuesData.data[i].id] =
              (this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === null ||
                this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === undefined) ? '' :
                (this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field].id ? this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field].id.toString() : this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field].toString());
            } else {
              drpdownpatcharr[this.supplierQuesData.data[i].id] =
              (this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === null ||
                this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === undefined) ? '' :
                this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field].toString();
                
            }
            
          }
          const validatorArr = [];
          if (this.supplierQuesData.data[i].mandatory === 'Y') {
            validatorArr.push(Validators.required);
          }
          if (this.supplierQuesData.data[i].MDMValidation != null) {
            validationsArray = JSON.parse(this.supplierQuesData.data[i].MDMValidation.validation_obj);
            for (let j = 0; j < validationsArray.length; j++) {
              if (validationsArray[j].type === 'min') {
                validatorArr.push(Validators.minLength(validationsArray[j].value));
              }
              if (validationsArray[j].type === 'max') {
                validatorArr.push(Validators.maxLength(validationsArray[j].value));
              }
              if (validationsArray[j].type === 'email') {
                validatorArr.push(Validators.email);
              }
              if (validationsArray[j].type === 'number') {
                validatorArr.push(Validators.pattern('^[0-9]*$'));
              }
            }
          }
          if (this.supplierQuesData.data[i].field_type === 'autoselect') {
            if (this.supplierQuesData.data[i].value_options === null) {
              

              this.editSupplierForm.addControl(this.supplierQuesData.data[i].id, new FormControl({
                disabled: this.supplierQuesData.data[i].readonly === 'Y' ? true : false
              }, validatorArr));
            }
            
          }

          if (this.supplierQuesData.data[i].field_type === 'dropdown') {

            if (this.supplierQuesData.data[i].value_options === null && this.supplierQuesData.data[i].api_url) {
              
              this.countryData[this.supplierQuesData.data[i].id] = {};
              this.suppService.getAPIURLMethod(this.supplierQuesData.data[i].api_url).subscribe(response => {
                this.countryData[this.supplierQuesData.data[i].id] = response;
                

                if (this.origin == 'UNITS_MDM') {
                 
                  const intValue = this.countryData[50].data.filter(item => item.international_unit_to_save == drpdownpatcharr['52']);
                  
                  if (intValue && intValue.length > 0) {
                    drpdownpatcharr['52'] = intValue[0]['international_unit'];
                  }
                  this.editSupplierForm.patchValue(drpdownpatcharr);
                }
                
              });
              this.editSupplierForm.addControl(this.supplierQuesData.data[i].id,
                new FormControl({ disabled: this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true },
                  validatorArr));
            } else if (this.supplierQuesData.data[i].value_options) {
              
              this.valueOptions = JSON.parse(this.supplierQuesData.data[i].value_options);
              this.editSupplierForm.addControl(this.supplierQuesData.data[i].id,
                new FormControl({ disabled: this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true },
                  validatorArr));
            } else {
              this.editSupplierForm.addControl(this.supplierQuesData.data[i].id, new FormControl({
                disabled: true,
              }, validatorArr));
            }

          }
          else if (this.supplierQuesData.data[i].field_type === 'multiselect') {

            if (this.supplierQuesData.data[i].value_options === null) {
              this.multiSelectData[this.supplierQuesData.data[i].id] = {};
              this.suppService.getAPIURLMethod(this.supplierQuesData.data[i].api_url).subscribe(response => {
                this.multiSelectData[this.supplierQuesData.data[i].id] = response;
              });

              this.editSupplierForm.addControl(this.supplierQuesData.data[i].id, new FormControl({
                disabled: this.supplierQuesData.data[i].readonly === 'Y' ? true : false
              }, validatorArr));
            }

          }else if (this.supplierQuesData.data[i].field_type === 'duallist') {
            let url;
            if (this.editSupplierData.farmer_reg.project_id) {
              url = this.supplierQuesData.data[i].api_url + this.editSupplierData.farmer_reg.project_id   
            } else {
              url = this.supplierQuesData.data[i].api_url + this.editSupplierData.farmer_reg[0].project_id
            }
            this.suppService.getAPIURLMethod(url).subscribe(response => {
              const x = response.data.map(item => item.region_village).filter(x => x != null) || [];
              const villagesArray = [];
              if (x.length > 0) {
                for (let i = 0; i < x.length; i++) {
                  if (!villagesArray.find(item => item.id === x[i].id)) {
                    villagesArray.push({id: x[i].id, name: x[i].village_name});
                  }
                }
                villagesArray.sort((a, b) => {
                  if(a.name < b.name) { 
                    return -1; 
                  } 
                  if(a.name > b.name) { 
                    return 1; 
                  } 
                  return 0;
                });
              }
              this.countryData['84'] = {};
              this.countryData['84'].data = villagesArray;
              this.countryData[this.supplierQuesData.data[i].id] = response.data;
              this.farmers = response.data;
              drpdownpatcharr['84'] = this.tableData.data.find(item => item.id == itemValue).farmer_reg.region_village.id.toString();
              this.editSupplierForm.patchValue(drpdownpatcharr);
              this.filterFarmersBySelectedVillage(true);
              this.doReset('edit', drpdownpatcharr[79]);
            });
          }
          else if (this.supplierQuesData.data[i].field_type === 'date') {
            this.editSupplierForm.addControl(this.supplierQuesData.data[i].id,
              new FormControl({ disabled: this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true },
                validatorArr));
            this.dateFormat = 'DD-MMM-YYYY';
          } else {
            this.editSupplierForm.addControl(this.supplierQuesData.data[i].id,
              new FormControl({ disabled: this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true },
                validatorArr));
          }

        }
        this.updateUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard:false });
        this.editForm = true;

        this.editSupplierForm.patchValue(drpdownpatcharr);

      }
    });


  }
  editSuppComponents(itemValue: any, template: TemplateRef<any>) {

    this.confirmUpdateModal = true;
    const updatingSupplier = {
      'ecosystem_id': itemValue.ecosystem_id,
      'form_code': this.origin
    };

    this.suppService.getSingleMDMComponents(updatingSupplier).subscribe(response => {
      if (response.status === 'success') {

        this.editSupplierData = response.data;
        
        const drpdownpatcharr = {};
        this.customdata = response.customdata;
        this.updatingEcosystemId = response.data.ecosystem_id;
        
        let validationsArray;
        for (let i = 0; i < this.supplierQuesData.data.length; i++) {
          drpdownpatcharr[this.supplierQuesData.data[i].id]
            = (this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === null ||
              this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field] === undefined)
              ? '' : this.editSupplierData[this.supplierQuesData.data[i].mapping_table_field].toString();
            const validatorArr = [];
          if (this.supplierQuesData.data[i].mandatory === 'Y') {
            validatorArr.push(Validators.required);
          }
          if (this.supplierQuesData.data[i].MDMValidation != null) {
            validationsArray = JSON.parse(this.supplierQuesData.data[i].MDMValidation.validation_obj);
            for (let j = 0; j < validationsArray.length; j++) {
              if (validationsArray[j].type === 'min') {
                validatorArr.push(Validators.minLength(validationsArray[j].value));
              }

              if (validationsArray[j].type === 'max') {
                validatorArr.push(Validators.maxLength(validationsArray[j].value));
              }

              if (validationsArray[j].type === 'email') {
                validatorArr.push(Validators.email);
              }

              if (validationsArray[j].type === 'number') {
                validatorArr.push(Validators.pattern('^[0-9]*$'));
              }
            }
          }

          if (this.supplierQuesData.data[i].field_type === 'dropdown') {

            if (this.supplierQuesData.data[i].value_options === null) {

              this.countryData[this.supplierQuesData.data[i].id] = {};
              this.suppService.getAPIURLMethod(this.supplierQuesData.data[i].api_url).subscribe(response => {
                this.countryData[this.supplierQuesData.data[i].id] = response;
              });
              this.editSupplierForm.addControl(this.supplierQuesData.data[i].id,
                new FormControl({ disabled: this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true },
                  validatorArr));
            } else {
              this.valueOptions = JSON.parse(this.supplierQuesData.data[i].value_options);
              this.editSupplierForm.addControl(this.supplierQuesData.data[i].id,
                new FormControl({ disabled: this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true },
                  validatorArr));
            }

          } else if (this.supplierQuesData.data[i].field_type === 'date') {
            this.editSupplierForm.addControl(this.supplierQuesData.data[i].id,
              new FormControl({ disabled: this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true },
                validatorArr));
            this.dateFormat = 'DD-MMM-YYYY';
          } else {
            this.editSupplierForm.addControl(this.supplierQuesData.data[i].id,
              new FormControl({ disabled: this.supplierQuesData.data[i].is_modifiable === 'Y' ? false : true },
                validatorArr));
          }
        }
        if (this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM') {
         
          for (const mscarr of this.masterServiceComponentArray) {
            
            const tempi = this.customdata.find(melement => {
              
              if (!mscarr.services_id && mscarr.id === melement.services_id) {
                
                return melement;
              }
              if (melement.services_components_mapping && mscarr.id == melement.services_components_mapping.id && mscarr.services_id == melement.services_id) {
                
                return melement;
              }
            });
            if (tempi != null) {
              try {
                this.editSupplierForm.removeControl(mscarr.uniqueid);
              } catch { }
              this.editSupplierForm.addControl((mscarr.uniqueid), new FormControl({ checked: true }));
            } else {
              try {
                this.editSupplierForm.removeControl(mscarr.uniqueid);
              } catch { }
              this.editSupplierForm.addControl((mscarr.uniqueid), new FormControl());
            }
          }
        }

        if (this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
          
          for (const mscarr of this.userTypes) {
            const tempi = this.customdata.find(melement => {
              const fid = melement.user_type.id + melement.user_type.code;
              if (mscarr.uniqueid === fid) {
                return melement;
              }
            });
            if (tempi != null) {
              try {
                this.editSupplierForm.removeControl(mscarr.uniqueid);
              } catch { }
              this.editSupplierForm.addControl((mscarr.uniqueid), new FormControl({ checked: true }));
            } else {
              try {
                this.editSupplierForm.removeControl(mscarr.uniqueid);
              } catch { }
              this.editSupplierForm.addControl((mscarr.uniqueid), new FormControl());
            }
          }
        }

        this.updateUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        this.editForm = true;
        this.editSupplierForm.patchValue(drpdownpatcharr);
      }
    });
  }

  trackById(index, dataItem) {
    return dataItem.id;
  }

  setUserType(form_code, mapping_table_field) {
    if (form_code === 'USERPROGRAMMAPPING_MDM' && mapping_table_field === 'users_id') {
      const mid = this.supplierQuesData.data.find(sdata => sdata.mapping_table_field === mapping_table_field);
      const midval = this.supplierForm.get(('' + mid.id)).value;
      const seldata = this.countryData[mid.id].data.find(sdata => sdata.id === +midval);
      let fmid = this.supplierQuesData.data.find(sdata => sdata.mapping_table_field === 'user_type_id');
      fmid = '' + fmid.id;
      this.supplierForm.controls[fmid].setValue(seldata.user_type_id);
    }

    if (form_code === 'UNITS_MDM' && mapping_table_field === 'unit_type_id') {
      
      const mid = this.supplierQuesData.data.find(sdata => sdata.mapping_table_field === mapping_table_field);
      
      const midval = this.supplierForm.get(('' + mid.id)).value;
      
      const seldata = this.countryData[mid.id].data.find(sdata => sdata.id === +midval);
      
      let fmid = this.supplierQuesData.data.find(sdata => sdata.mapping_table_field === 'international_unit');
      fmid = '' + fmid.id;
      this.supplierForm.controls[fmid].setValue(seldata.international_unit);
    }
    if (form_code === 'ECOSYSTEM_USERS_MAPPING_MDM' && mapping_table_field === 'users_id') {
      const mid = this.supplierQuesData.data.find(sdata => sdata.mapping_table_field === mapping_table_field);
      const midval = this.supplierForm.get(('' + mid.id)).value;
      const seldata = this.countryData[mid.id].data.find(sdata => sdata.id === +midval);
      let utid = this.supplierQuesData.data.find(sdata => sdata.mapping_table_field === 'user_type_id');
      utid = '' + utid.id;
      let ucid = this.supplierQuesData.data.find(sdata => sdata.mapping_table_field === 'user_category_id');
      ucid = '' + ucid.id;
      let atid = this.supplierQuesData.data.find(sdata => sdata.mapping_table_field === 'access_type_id');
      atid = '' + atid.id;
      this.supplierForm.controls[utid].setValue(seldata.user_type_id);
      this.supplierForm.controls[ucid].setValue(seldata.user_category_id);
      
      const atdata = this.countryData[atid].data.find(sdata => sdata.code === 'FUL3');
      this.supplierForm.controls[atid].setValue(atdata.id);
    }
    
  }
  //Village  Collector Mapping 
  getVCMappingData(form_code,mapping_table_field,event){
    
    if (form_code === 'VC_FARMER_MAPPING_MDM' && mapping_table_field === 'project_id') {
      for (let i = 0; i < this.supplierQuesData.data.length; i++) {
        if(this.supplierQuesData.data[i].field_type === 'duallist'){
          this.confirmedFarmers = new Array<any>();
          this.countryData[this.supplierQuesData.data[i].id] = {};            
          const url =this.supplierQuesData.data[i].api_url+event.target.value;
          this.suppService.getAPIURLMethod(url).subscribe(response => {
            this.countryData[this.supplierQuesData.data[i].id] = response.data;
            const x = response.data.map(item => item.region_village).filter(x => x != null) || [];
            const villagesArray = [];
            if (x.length > 0) {
              for (let i = 0; i < x.length; i++) {
                const exists = villagesArray.find(item => item.id === x[i].id);
                let mappingsInThisProject = [];
                mappingsInThisProject = this.tableData.data.filter(item => item.farmer_reg.project_id == event.target.value);
                let villageAlreadyInMappingsInThisProject = mappingsInThisProject.length > 0 ? mappingsInThisProject.find((item => item.farmer_reg.region_village.id == x[i].id)) : undefined;
                if (!exists) {
                  villagesArray.push({id: x[i].id, name: x[i].village_name});
                }
              }
              villagesArray.sort((a, b) => {
                if(a.name < b.name) { 
                  return -1; 
                } 
                if(a.name > b.name) { 
                  return 1; 
                } 
                return 0;
              });
            }
            this.supplierForm.controls[84].setValue('0');
            this.countryData['84'] = {};
            this.countryData['84'].data = villagesArray;
            this.countryData['84'].data.unshift({id: '0', name: 'Select'});
            //let farmersAlreadyMappedToAVC = this.vcFarmerMappingData.map(item => item.farmer_reg);
            let farmersAlreadyMappedToAVC = [{"id":0}];
            //console.log('farmersAlreadyMappedToAVC', farmersAlreadyMappedToAVC);
            this.farmersInProject = villagesArray.length > 1 ? response.data.filter(item => !farmersAlreadyMappedToAVC.find(each => each.id == item.id)) : [];
            //console.log('this.farmersInProject', this.farmersInProject);
            // if (villagesArray.length == 1) {this.commonService.showToast('warning', 'no_add_only_edit');}
            this.filterFarmersBySelectedVillage();
          });
        }
      }
    } else if (form_code === 'VC_FARMER_MAPPING_MDM' && mapping_table_field === 'village_name') {
      
      this.filterFarmersBySelectedVillage();
    } else if (form_code === 'VC_FARMER_MAPPING_MDM' && mapping_table_field === 'users_id') {
      
      this.filterFarmersBySelectedVillage();
    }
  }

 //Angular DUAL List 
  private farmerLabel(item: any) {
    return item.name;
  }

  private useFarmers() {
    this.dualListKey = 'id';
    this.display = this.farmerLabel;
    this.keepSorted = true;
    this.source = this.sourceFarmers;
    this.confirmed = this.confirmedFarmers;
  }

  doReset(itemType, userId?) {
    if(itemType == 'edit'){
      this.confirmedFarmers = new Array<any>();
      if (this.editSupplierDataFarmers.id) {
        const selectedFarmer = this.farmers.filter(item => item.id == this.editSupplierDataFarmers.farmer_reg.id && this.editSupplierDataFarmers.status == 'Active');
        this.confirmedFarmers.push(selectedFarmer);
      } else {
        let p = this.editSupplierDataFarmers.filter(item => item.users_id == userId);
        for(let x of p){
          let selectedFarmer = this.farmers.filter(item => item.id == x.farmer_reg.id && x.status == 'Active')[0];
          if (selectedFarmer) {this.confirmedFarmers.push(selectedFarmer);}
        }
        this.allFarmersMappedToVCNotInConfirmed = this.editSupplierDataFarmersAllVillages.filter(item => item.status == 'Active' && item.users_id == userId && !this.confirmedFarmers.find(each => each.id == item.farmer_reg.id));
      }
    }
    this.sourceFarmers = JSON.parse(JSON.stringify(this.farmers));
    this.useFarmers();
  }

  doAdd() {
    for (let i = 0, len = this.source.length; i < len; i += 1) {
      const o = this.source[i];
      const found = this.confirmed.find( (e: any) => e === o );
      if (!found) {
        this.confirmed.push(o);
        break;
      }
    }
  }

  doRemove() {
    if (this.confirmed.length > 0) {
      this.confirmed.splice(0, 1);
    }
  }

  doDelete() {
    if (this.source.length > 0) {
      this.source.splice(0, 1);
    }
  }

  onSelectUpdate(event: TypeaheadMatch): void {
    this.updateSelectedOption = event.item;
  }

  printHeader(header) {
  }

  updateMDMDate(form: any, id) {
    this.f1Submitted = true;
    if (this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM' || this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM') {
      const temp: any = {};
        this.customdataModified = this.customdata;
        const servicesArray = [];
        for (let i = 0; i < this.customdataModified.length; i++) {
          if (!this.customdataModified[i]['components_id']) {
            servicesArray.push(this.customdataModified[i]);
          }
        }
        let itemsToRemove = [];
        for (let i = 0; i < servicesArray.length; i++) {
          const isComponentSelected = this.customdataModified.filter(item => item.components_id != null && item.services_id == servicesArray[i]['services_id']);
          
          if (isComponentSelected && isComponentSelected.length > 0) {

            // in customdataModified, keep the array items that have a component id & service id is equal to servicesArray[i]['id']
            // keep the items that do not have a component id and services id is not equal to servicesArray[i]['id']
            itemsToRemove = itemsToRemove.concat(this.customdataModified.filter(item => item.components_id == null && item.services_id == servicesArray[i]['services_id']));
            
          }
        }
        for (let i = 0; i < itemsToRemove.length; i++) {
          // in customdataModified, keep the array items that have a component id or that do not have a component id and the services_id is not equal to itemsToRemove[i]['id']
          this.customdataModified = this.customdataModified.filter(item => item.components_id != null || (item.components_id == null && item.services_id !== itemsToRemove[i]['services_id']));
        }
        this.customdata = this.customdataModified;
      this.customdata.forEach(snap => {
        snap.upd_user_id = this.currentUser.id;
      })
      temp.ecosystem_id = this.updatingEcosystemId;
      temp.data = this.customdata;
      temp.form_code = this.origin;
      temp.cr_user_id = this.currentUser.id;
      temp.upd_user_id = this.currentUser.id;


      this.suppService.updateMDMData(temp).subscribe(response => {
        const responseData = response;
        if (responseData.status === 'success') {
          if (this.updateUserModal) {
            this.updateUserModal.hide();
          }
          this.commonService.showToast('success', responseData.code, {});
          this.getMDMDataValues();
          this.editSupplierForm.reset();
          this.getMasterData();
          this.customdata = [];
        } else {
          this.commonService.showToast('error', responseData.code, {});
        }
      });
    } else {
      if (form.valid) {
        const newRequestArray = [];
        const formCode = {
          'form_code': this.origin,
          'id': id,
          'upd_user_id': this.currentUser.id,
          'data': []
        };
        let newRequest: any = [];
        for (var x in form.getRawValue()) {
          const result = this.supplierQuesData.data.filter(item => item.id === +x);
          let selectId;
          if (this.updateSelectedOption) {
            selectId = this.updateSelectedOption.id;
          }

          if (result.length > 0) {
            if (form.getRawValue()[x] === null || form.getRawValue()[x] === '') {
              newRequest = {
                'id': x, 'value': null, 'mapping_table': result[0].mapping_table,
                'mapping_table_field': result[0].mapping_table_field
              };
            } else if (this.updateSelectedOption && this.updateSelectedOption.name === form.getRawValue()[x]) {
              newRequest = {
                "id": x, "value": selectId, "mapping_table": result[0].mapping_table,
                "mapping_table_field": result[0].mapping_table_field
              };
            } else {
              newRequest = {
                'id': x, 'value': form.getRawValue()[x], 'mapping_table': result[0].mapping_table,
                'mapping_table_field': result[0].mapping_table_field
              };
            }
          }
          formCode.data.push(newRequest);
        }

        if (this.origin == 'VC_FARMER_MAPPING_MDM') {
          let x = Object.assign([], this.confirmed);
          for (let i = 0; i < x.length; i++) {
            let item =   this.editSupplierDataFarmers.find(each => each.farmer_id == x[i]['id'] && each.users_id == this.editSupplierData['users_id']);
            if (item) {
              const dbId = item['id'];
              x[i]['dbId'] = dbId;
            }
          }

          for (let i = 0; i < this.allFarmersMappedToVCNotInConfirmed.length; i++) {
            let y = this.allFarmersMappedToVCNotInConfirmed[i];
            y['dbId'] = y['id'];
            x.push(y);
          }

          formCode.data.push({
            id: 83,
            mapping_table: "vc_farmer_mapping",
            mapping_table_field: "farmer_id",
            value: x
          })
        }

        

        this.suppService.updateMDMData(formCode).subscribe(response => {
          const responseData = response;
          if (responseData.status === 'success') {
            if (this.updateUserModal) {
              this.updateUserModal.hide();
            }
            this.supplierForm.reset();
            this.editSupplierForm.reset();
            this.f1Submitted = false;
            this.commonService.showToast('success', responseData.code, {});
            this.getMDMDataValues();
            this.getMasterData();
            if (this.origin == 'UNITS_MDM') {
              this.commonService.showToast('warning', 'update_ecosystem_settings', {});
            }
          } else {
            this.commonService.showToast('error', responseData.code, {});
          }
        });
      }
    }
  }

  openModalConfirmRemoval(template: TemplateRef<any>) {
    this.removeUsersConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
  }

  openModalConfirmDeac(template: TemplateRef<any>) {
    this.deacUserConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
  }

  deactivateThisUser(id) {
    const formCode = {
      'form_code': this.origin,
      'id': id,
      'upd_user_id': this.currentUser.id
    };

    this.suppService.deleteMDMData(formCode).subscribe(response => {
      const responseData = response;
      if (responseData.status === 'success') {
        this.commonService.showToast('success', responseData.code, {});
        this.getMDMDataValues();
        this.getMasterData();
      } else {
        this.commonService.showToast('error', responseData.code, {});
      }
      if (this.deacUserConfirmModal) {
        this.deacUserConfirmModal.hide();
      }
    });
  }

  openModalConfirmDeacSuppComponentMDM(ecosystem, template: TemplateRef<any>) {
    this.deletingEcosystemId = ecosystem;
    this.deacUserConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
  }

  delSuppComponentMDM() {
    
    const formCode = {
      'form_code': this.origin,
      'ecosystem_id': this.deletingEcosystemId.ecosystem_id,
      'upd_user_id': this.currentUser.id
    };

    this.suppService.deleteMDMData(formCode).subscribe(response => {
      const responseData = response;
      if (responseData.status === 'success') {
        this.commonService.showToast('success', responseData.code, {});
        this.getMDMDataValues();
        this.getMasterData();
      }
      else {
        this.commonService.showToast('error', responseData.code, {});
      }
      if (this.deacUserConfirmModal) {
        this.deacUserConfirmModal.hide();
      }
    });
  }

  declineDeactivation(): void {
    if (this.deacUserConfirmModal) {
      this.deacUserConfirmModal.hide();
    }
  }

  hideUpdateModal(form: any): void {
    if (form.dirty) {
      this.confirmUpdateModal = false;
    } else {
      if (this.updateUserModal) {
        this.updateUserModal.hide();
        this.editForm = false;
      }
      this.editSupplierForm.reset();
    }

    this.f1Submitted = false;
  }

  hideAddUserModal(form: any): void {
    if (form.dirty) {
      this.confirmModal = false;
    } else {
      if (this.addUserModal) {
        this.addUserModal.hide();
        this.addForm = false;
      }
      this.supplierForm.reset();
    }
    this.f1Submitted = false;
  }

  closeModal(val: any) {
    if (val === 'add') {
      if (this.addUserModal) {
        this.addUserModal.hide();
      }
      this.supplierForm.reset();
      this.f1Submitted = false;
    } else {
      this.editSupplierForm.reset();
      if (this.updateUserModal) {
        this.updateUserModal.hide();
      }
      this.f1Submitted = false;
    }
  }

  confirmModalYes(valItem: any) {
    if (valItem === 'add') {
      if (this.addUserModal) {
        this.addUserModal.hide();
      }
      this.supplierForm.reset();
      this.customdata = [];
      this.f1Submitted = false;
      this.userTypeCheckedForAdd = [];
    } else {
      if (this.updateUserModal) {
        this.updateUserModal.hide();
      }
      this.customdata = [];
      this.editSupplierForm.reset();
      this.f1Submitted = false;
    }
  }

  confirmModalNo(valItem: any) {
    if (valItem === 'add') {
      this.confirmModal = true;
    } else {
      this.confirmUpdateModal = true;
    }

  }

  exportMDMData(): void {
    this.suppService.exportData(this.origin)
      .subscribe(result => {
        console.log('result', result);
		var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
		const blob = new Blob([result], {type: contentType});
		let linkdownload = document.createElement('a');
		linkdownload.href = URL.createObjectURL(blob);
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0');
		var yyyy = today.getFullYear();
		var today1 = dd + '-' + mm + '-' + yyyy;
		//var file_name = 'P2F-Roots_' + this.origin + '_ExcelExport_' + today + '.xlsx';
		linkdownload.download = 'P2F-Roots_' + this.tableData.mastername + '_ExcelExport_' + today1 + '.xlsx';
		linkdownload.style.display = 'none';
		document.body.appendChild(linkdownload);
		linkdownload.click();
      }, err=> console.log('err', err))
  }

  soryBy(item) {

    if (item.isAscending) {
      this.asc(item)
    } else {
      this.desc(item)
    }

    this.tableData.data.forEach(element => {
      element.isAscending = true;
    });
    item.isAscending = !item.isAscending;

  }
  asc(item) {
    if (this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM' || this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
      this.tableFinalData.sort((a, b) => {
        let finala = '';
        let finalb = '';
        finala = a['name'];
        finalb = b['name'];

        if (finala === null) {
          finala = '';
        }

        if (finalb === null) {
          finalb = '';
        }

        if (finala.toLowerCase() > finalb.toLowerCase()) {
          return 1;
        }
        if (finala.toLowerCase() < finalb.toLowerCase()) {
          return -1;
        }

        return 0;
      });
    } else if (this.origin === 'ECOSYSTEM_USERS_MAPPING_MDM') {
      this.tableData.data.sort((a, b) => {
        let finala = '';
        let finalb = '';

        finala = a[item.mapping_table_field];
        finalb = b[item.mapping_table_field];

        if (finala === null) {
          finala = '';
        }

        if (finalb === null) {
          finalb = '';
        }

        if (finala.toLowerCase() > finalb.toLowerCase()) {
          return 1;
        }
        if (finala.toLowerCase() < finalb.toLowerCase()) {
          return -1;
        }

        return 0;
      });
    } else {
      this.tableData.data.sort((a, b) => {
        let finala = '';
        let finalb = '';
        if (item.field_type === 'dropdown' && item.api_url !== null) {
          finala = a[item.ref_modal_name]['name'];
          finalb = b[item.ref_modal_name]['name'];
        } else {
          finala = a[item.mapping_table_field];
          finalb = b[item.mapping_table_field];
        }

        if (finala === null) {
          finala = '';
        }

        if (finalb === null) {
          finalb = '';
        }

        if (finala.toLowerCase() > finalb.toLowerCase()) {
          return 1;
        }
        if (finala.toLowerCase() < finalb.toLowerCase()) {
          return -1;
        }

        return 0;
      });
    }
  }

  desc(item) {
    if (this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM' || this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM') {
      this.tableFinalData.sort((a, b) => {
        let finala = '';
        let finalb = '';
        finala = a['name'];
        finalb = b['name'];

        if (finala === null) {
          finala = '';
        }

        if (finalb === null) {
          finalb = '';
        }

        if (finala.toLowerCase() < finalb.toLowerCase()) {
          return 1;
        }
        if (finala.toLowerCase() > finalb.toLowerCase()) {
          return -1;
        }

        return 0;
      });
    } else if (this.origin === 'ECOSYSTEM_USERS_MAPPING_MDM') {
      this.tableData.data.sort((a, b) => {
        let finala = '';
        let finalb = '';

        finala = a[item.mapping_table_field];
        finalb = b[item.mapping_table_field];

        if (finala === null) {
          finala = '';
        }

        if (finalb === null) {
          finalb = '';
        }

        if (finala.toLowerCase() < finalb.toLowerCase()) {
          return 1;
        }
        if (finala.toLowerCase() > finalb.toLowerCase()) {
          return -1;
        }

        return 0;
      });
    } else {
      this.tableData.data.sort((a, b) => {
        let finala = '';
        let finalb = '';
        if (item.field_type === 'dropdown' && item.api_url !== null) {
          finala = a[item.ref_modal_name]['name'];
          finalb = b[item.ref_modal_name]['name'];
        } else {
          finala = a[item.mapping_table_field];
          finalb = b[item.mapping_table_field];
        }

        if (finala === null) {
          finala = '';
        }

        if (finalb === null) {
          finalb = '';
        }

        if (finala.toLowerCase() < finalb.toLowerCase()) {
          return 1;
        }
        if (finala.toLowerCase() > finalb.toLowerCase()) {
          return -1;
        }

        return 0;
      });
    }
  }

  goBackToAdmin() : void {
    this.location.back();
  }

  filterFarmersBySelectedVillage(isEdit?): void {
    if (!isEdit) {
      const selectedVillage = this.supplierForm.controls[84].value;
      const selectedVC = this.supplierForm.controls[79].value;
      
      if (selectedVillage && parseInt(selectedVillage) > 1) {
        if (selectedVC) {
          this.filterByVCMapping(selectedVC, selectedVillage);
        } else {
          this.farmers = this.farmersInProject.filter(item => item.region_village.id == selectedVillage);
        }
      } else if (selectedVC) {  
        this.filterByVCMapping(selectedVC);
      } else {
        this.farmers = this.farmersInProject;
      }
      this.doReset('add');
    } else {
      const selectedVillage = this.editSupplierForm.controls[84].value;
      if (selectedVillage && parseInt(selectedVillage) > 1) {
        this.farmers = this.farmers.filter(item => item.region_village.id == selectedVillage);
        this.editSupplierDataFarmersAllVillages = Object.assign([], JSON.parse(JSON.stringify(this.editSupplierDataFarmers)));
        this.editSupplierDataFarmers = this.editSupplierDataFarmers.filter(item => item.farmer_reg.region_village.id == selectedVillage);
      }
      else {
        this.farmers = this.farmersInProject;
      }
    }
  }

  // filter farmers by VC mapping
  filterByVCMapping(isVC, selectedVillage?){  
    this.confirmedFarmers = new Array<any>();
    this.farmers = [];
    if(!selectedVillage){        
      for(const farmer of this.farmersInProject){
        const VCMappedFarmers = farmer.vc_farmer_mappings.find(item => item.users_id == isVC);
        if(VCMappedFarmers){
          this.confirmedFarmers.push(farmer);
        } else {
          this.farmers.push(farmer);
        }
      }
    } else {
      const filterByVillage = this.farmersInProject.filter(item => item.region_village.id == selectedVillage);
      for(const farmer of filterByVillage){
        const VCMappedFarmers = farmer.vc_farmer_mappings.find(item => item.users_id == isVC);
        if(VCMappedFarmers){
          this.confirmedFarmers.push(farmer);
        } else {
          this.farmers.push(farmer);
        }
      }
    }
    if(this.farmers.length == 0 && this.farmersInProject.length > 0){
      this.toastrService.warning('No farmer available for mapping');
    }
  }

}
