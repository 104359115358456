<div style="width:100%;text-align:center;">
	</div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="error-template clearfix">
                    <div class="error-img">
                        <img src="assets/images/error-img.svg" alt="error">
                    </div>
                    <div class="error-msg">
                    <h2 class="error-title">404</h2>
                    <p><span i18n="@@pageNotFoundMsg">There seems to be some issue with the page you are trying to access.</span><br> 
                        <span i18n="@@pageNotFoundContactSupport">Please contact Support if you would like to report this issue.</span></p>
                        <p class="small-text"><span i18n="@@youCanAlso">You can also go </span>  <a routerLink="/dashboard" i18n="@@backToHome">Back to Home Page</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
