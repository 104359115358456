import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';



@Injectable()
export class TwofactorauthService {

  twofactorauthURL = this.appConfigService.getUrl(this.appConfigService.TWOFACTORAUTH);

  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) { }

  Setup(farmerId: number): Observable<any> {
    return this.http.get<any>(`${this.twofactorauthURL}/setup/${farmerId}`);
  }
  Verify(id: number, otp: string, userSecret2fa: string): Observable<any> {
    return this.http.get<any>(`${this.twofactorauthURL}/verify/${id}/${otp}/${userSecret2fa}`);
  }
  Disable(farmerId: number): Observable<any> {
    return this.http.get<any>(`${this.twofactorauthURL}/disable/${farmerId}`);
  }


}
