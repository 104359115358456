import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable()
export class SuppliersService {
  supplierURL = this.appConfigService.getUrl(this.appConfigService.Supplier);
  mdmDataURL = this.appConfigService.getUrl(this.appConfigService.MDMDATA);
  mdmMasterUrl = this.appConfigService.getUrl(this.appConfigService.MDMMASTER);
  mdmEcoSystemSettingsURL = this.appConfigService.getUrl(this.appConfigService.ECOSYSTEMSETTINGS);
  mdmEcoSystemSettingsRecordURL = this.appConfigService.getUrl(this.appConfigService.ECOSYSTEMRECORD);
  suppleirComponentMappingURL = this.appConfigService.getUrl(this.appConfigService.Supplier);
  dynamicAPIUrl = this.appConfigService.getUrl('');
  private masterData: any;
  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) { }


  getMaster(item) {
    return this.http.post<any>(`${this.mdmMasterUrl}`, { form_code: item });
  }


  getMDMDataValues(item) {
    return this.http.post<any>(`${this.mdmDataURL}/all`, { form_code: item });
  }

  saveMDM(mdmData: any): Observable<any> {
    return this.http.post<any>(`${this.mdmDataURL}/addnew`, mdmData);
  }

  getAPIURLMethod(itemVal) {
    return this.http.get<any>(`${this.dynamicAPIUrl}${itemVal}`);
  }

  getSingleMDMData(item: any) {
    return this.http.post<any>(`${this.mdmDataURL}/singleMDM`, item);
  }
  getMDMMaster() {
    return this.http.get<any>(`${this.mdmMasterUrl}/getall`);
  }

  getMDMMaster2(navId) {
    return this.http.get<any>(`${this.mdmMasterUrl}/getallNavServices/${navId}`);
  }

  updateMDMData(mdmData: any): Observable<any> {
    return this.http.post<any>(`${this.mdmDataURL}/update`, mdmData);
  }

  deleteMDMData(mdmData: any): Observable<any> {
    return this.http.post<any>(`${this.mdmDataURL}/delete`, mdmData);
  }

  setMDMData(name: any, master_code: any) {
    const newObject = {
      'name': name,
      'master_code': master_code
    }
    this.masterData = newObject
  }

  getMDMData() {
    return this.masterData;

  }

  exportData(what: string, ecosystemId?) {
    return this.http.post<Blob>(`${this.mdmDataURL}/export`, { form_code: what, ecosystem_id: ecosystemId },{responseType:'blob' as 'json'});
    }

  getMappingsForSupplier(supplierId, productId, projectId) {
    return this.http.get<any>(`${this.mdmDataURL}/getSupplierMappings/${supplierId}/${productId}/${projectId}`);
  }

  getSingleMDMComponents(supplierObject) {
    return this.http.post<any>(`${this.mdmDataURL}/getAllComponents`, supplierObject);
  }
  getEcoSystemSettingsData(item,ecosystemID) {
    return this.http.post<any>(`${this.mdmEcoSystemSettingsURL}/all`, { form_code: item,  ecosystem_id:ecosystemID});
  }
  getEcoSystemSettingsRecord(itemID) {
    return this.http.post<any>(`${this.mdmEcoSystemSettingsRecordURL}`, { ecosystem_id: itemID });
  }

  updateEcosystemSettings(data): Observable<any> {
    return this.http.put(`${this.mdmDataURL}/updateEcosystemSettings`, data);
  }
}
