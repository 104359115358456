/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ngx-progressbar/index.ngfactory";
import * as i3 from "ngx-progressbar";
import * as i4 from "@angular/router";
import * as i5 from "../../node_modules/ngx-tour-ngx-bootstrap/ngx-tour-ngx-bootstrap.ngfactory";
import * as i6 from "ngx-tour-ngx-bootstrap";
import * as i7 from "../../node_modules/ng4-loading-spinner/ng4-loading-spinner.ngfactory";
import * as i8 from "ng4-loading-spinner";
import * as i9 from "./components/footer/footer.component.ngfactory";
import * as i10 from "./components/footer/footer.component";
import * as i11 from "./services/login.service";
import * as i12 from "@angular/platform-browser";
import * as i13 from "./app.component";
import * as i14 from "./services/user.service";
import * as i15 from "./services/authentication.service";
import * as i16 from "./services/update.service";
import * as i17 from "ngx-toastr";
import * as i18 from "./services/common.service";
import * as i19 from "@angular/common";
import * as i20 from "./services/locale.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["id", "fullPage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ng-progress", [], null, null, null, i2.View_ɵa_0, i2.RenderType_ɵa)), i1.ɵdid(2, 573440, null, 0, i3.ɵa, [i3.NgProgress], { ease: [0, "ease"], positionUsing: [1, "positionUsing"], showSpinner: [2, "showSpinner"], color: [3, "color"], thick: [4, "thick"], maximum: [5, "maximum"], minimum: [6, "minimum"], speed: [7, "speed"], trickleSpeed: [8, "trickleSpeed"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "tour-step-template", [], null, [["window", "keydown.Escape"], ["window", "keydown.ArrowRight"], ["window", "keydown.ArrowLeft"]], function (_v, en, $event) { var ad = true; if (("window:keydown.Escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onEscapeKey() !== false);
        ad = (pd_0 && ad);
    } if (("window:keydown.ArrowRight" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onArrowRightKey() !== false);
        ad = (pd_1 && ad);
    } if (("window:keydown.ArrowLeft" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).onArrowLeftKey() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_TourStepTemplateComponent_0, i5.RenderType_TourStepTemplateComponent)), i1.ɵdid(6, 1097728, null, 1, i6.TourStepTemplateComponent, [i6.ɵc, i6.ɵa], null, null), i1.ɵqud(335544320, 1, { stepTemplate: 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "ng4-loading-spinner", [], null, null, null, i7.View_Ng4LoadingSpinnerComponent_0, i7.RenderType_Ng4LoadingSpinnerComponent)), i1.ɵdid(9, 180224, null, 0, i8.Ng4LoadingSpinnerComponent, [i8.Ng4LoadingSpinnerService], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-footer", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_FooterComponent_0, i9.RenderType_FooterComponent)), i1.ɵdid(11, 114688, null, 0, i10.FooterComponent, [i11.LoginService, i12.DOCUMENT], null, null)], function (_ck, _v) { var currVal_0 = "linear"; var currVal_1 = "marginLeft"; var currVal_2 = false; var currVal_3 = "#EA5A0B"; var currVal_4 = true; var currVal_5 = 1; var currVal_6 = 0.15; var currVal_7 = 200; var currVal_8 = 250; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); _ck(_v, 4, 0); _ck(_v, 11, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "data-synced"], ["window", "error-caching"], ["window", "caching-now"], ["window", "need-reload"]], function (_v, en, $event) { var ad = true; if (("window:data-synced" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).showDataSyncedToast($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:error-caching" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).showErrorCachingToast($event) !== false);
        ad = (pd_1 && ad);
    } if (("window:caching-now" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).showCachingToast($event) !== false);
        ad = (pd_2 && ad);
    } if (("window:need-reload" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).needReloadFn($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i13.AppComponent, [i4.Router, i8.Ng4LoadingSpinnerService, i14.UserService, i15.AuthenticationService, i16.UpdateService, i17.ToastrService, i18.CommonService, i19.Location, i12.DOCUMENT, i20.LocaleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
