import { Component, OnInit, Inject, LOCALE_ID, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService} from '../../services/user.service';
import { CampaignService } from '../../services/campaign.service';
import { TranslateService } from '@ngx-translate/core';

import { Location } from '@angular/common';


@Component({
  selector: 'app-campaign-setup',
  templateUrl: './campaign-setup.component.html',
  styleUrls: ['./campaign-setup.component.css']
})
export class CampaignSetupComponent implements OnInit {
  
  public isOpen = false;
  public isOpenEndDate = false;
  public dateFormat = 'DD-MMM-YYYY';
  public savingCampaign = false;
  projectId = 0;
  campaignId = 0;
  currentUser = this.userService.getCurrentUser();
  fromNavId;
  public typeOfAdministrationArr: any[]; 
  typeOfAdministration = '';
  campaignForm: FormGroup;
  projects = [];
  campaign;
  confirmCancelModal;
  campaignStatuses = [];
  campaignTypeModal;

  constructor(
    private readonly projectService: ProjectService, 
    private readonly commonService:  CommonService,
    private readonly router: Router, 
    private readonly userService: UserService,
    private readonly campaignService: CampaignService, 
    private readonly loc: Location,
    private readonly _route: ActivatedRoute, 
    private readonly spinner: Ng4LoadingSpinnerService,
    @Inject(LOCALE_ID) private readonly locale: string, 
    private readonly translate: TranslateService,
    private readonly formBuilder: FormBuilder,
    private readonly modalService: BsModalService,
    private readonly localeService: BsLocaleService) {
      this._route.queryParamMap.subscribe(queryParams => {
        const qpObject = { ...queryParams.keys, ...queryParams };
  
        this.fromNavId = qpObject['params']['from'];
        //navigation changes added
        this.translate.get('typeAdminTextArr').subscribe((val) => {
          this.typeOfAdministrationArr = val;
          if (this.fromNavId === '2') {
            this.typeOfAdministration = this.typeOfAdministrationArr[0];
          }
          else if (this.fromNavId === '3') {
            this.typeOfAdministration = this.typeOfAdministrationArr[1];
          }
        }, err => console.log('err', err));
      });
      this.localeService.use(this.locale);
    }

  ngOnInit() {
    const a = this._route.snapshot.params['id'];
    this.campaignId = a ? +a : 0;
    this.getProjects();
    this.getCampaignStatuses();
    this.createCampaignForm();
    
    if (this.campaignId && this.campaignId !== 0) {
      this.editCampaign();
    }
  }

  createCampaignForm(): void {
    this.campaignForm = this.formBuilder.group({
      projectId: new FormControl(0, [Validators.required, Validators.min(1)]),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      objectives: new FormControl('', [Validators.maxLength(1000)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      startDate: new FormControl(null, [Validators.required]),
      endDate: new FormControl(''),
      status: new FormControl('0'),
      results: new FormControl('', [Validators.maxLength(1000)]),
      closingRemarks: new FormControl('', [Validators.maxLength(1000)]),
      campaignType: new FormControl(false)
    });
  }

  async editCampaign() {
    this.spinner.show();
    const request = await Promise.all([
      this.campaignService.getCampaignById(this.campaignId).toPromise()
    ]);

    if (request[0]['code'] === 'success') {
      this.campaign = request[0]['message'];
    }

    const obj = {
      projectId: this.campaign.project_id,
      name: this.campaign.name,
      description: this.campaign.description,
      objectives: this.campaign.objectives,
      startDate: new Date(this.campaign.start_date),
      endDate: this.campaign.end_date ? new Date(this.campaign.end_date) : '',
      status: this.campaign.status ? +this.campaign.status : '0',
      results: this.campaign.results,
      closingRemarks: this.campaign.closing_remarks,
      campaignType: this.campaign.campaign_type
    };

    this.campaignForm.patchValue(obj);

    if (this.campaign.closed_date) {
      this.campaignForm.disable();
    }
    this.spinner.hide();
  }

  async getProjects() {
    const request = await Promise.all([
      this.projectService.GetProjectsBasicInfo().toPromise()
    ]);

    if (request && request[0]['msg'] === 'success') {
      this.projects = request[0].data;
    }

  }


  //Redirect to campaign management on click of breadcrumb
  redirectToCampaignManagement(){
    const url="/campaignmanagement";
    this.router.navigate([url],{ queryParams: { from: this.fromNavId } });
  }

  saveUpdateCampaign(): void {
    this.savingCampaign = true;
    const name = this.campaignForm['value'].name;
    const projectId = this.campaignForm['value'].projectId;
    const objectives = this.campaignForm['value'].objectives;
    const description = this.campaignForm['value'].description;
    const startDate = this.campaignForm['value'].startDate;
    const endDate = this.campaignForm['value'].endDate;
    const status = this.campaignForm['value'].status;
    const results = this.campaignForm['value'].results;
    const campaignType = this.campaignForm['value'].campaignType;

    if (!projectId || projectId === 0 || !name || name.length === 0 || 
      !description || description.length === 0 || !startDate || startDate.length === 0) {
        this.savingCampaign = false;
        return;
    }

    if (endDate && endDate < startDate) {
      this.commonService.showToast('error', 'invalid_end_date', {});
      this.savingCampaign = false;
      return;
    }

    const campaignToSaveOrUpdate = {
      projectId: projectId,
      name: name,
      objectives: objectives,
      description: description,
      startDate: startDate,
      endDate: endDate,
      status: status !== 0 ? status : null,
      results: results,
      campaignType: campaignType
    };

    this.campaignService.saveOrUpdateCampaign(this.campaignId, campaignToSaveOrUpdate)
    .subscribe(result => {
      console.log('result', result);
      this.savingCampaign = false;
      if (result.code === 'success') {
        if (this.campaignId > 0) {
          this.commonService.showToast('success', 'campaign_updated_success', {});
        }
        else {
          this.commonService.showToast('success', 'campaign_saved_success', {});
        }

        this.router.navigate(['/campaignmanagement'], { queryParams: { from: this.fromNavId } })
      }
    })
    

  }

  cancel(template: TemplateRef<any>) {
    if (this.campaignForm.dirty) {
      this.confirmCancelModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    }
    else {
      this.confirmCancel();
    }
  }

  confirmModal(what: string) {
    if (what === 'yes') {
      if (this.confirmCancelModal) {
        this.confirmCancelModal.hide();
      }
      this.confirmCancel();
    }
    else {
      if (this.confirmCancelModal) {
        this.confirmCancelModal.hide();
      }
    }
  }

  confirmCancel() : void {
    const url="/campaignmanagement";
    this.router.navigate([url],{ queryParams: { from: this.fromNavId } });
  }


  // Implemented export to excel for campaigns

  async getCampaignStatuses() {
    const request = await Promise.all([
      this.campaignService.getCampaignStatuses().toPromise()
    ]);

    if (request[0]['msg'] === 'success') {
      this.campaignStatuses = request[0]['data'];
    }
  }

  checkCampaignType(e, template){
    if (this.campaignId && this.campaignId !== 0 && e.target.checked == false) {
      this.campaignTypeModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    }
  }

  confirmTypeModal(){
    if(this.campaignTypeModal){
      this.campaignTypeModal.hide();
    }
  }

}
