import { Component, OnInit, Inject } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { UserService } from './services/user.service';
import { AuthenticationService } from './services/authentication.service';

import { CommonService } from './services/common.service';
import { UpdateService } from './services/update.service';
import { LocaleService } from './services/locale.service';
import { ToastrService } from 'ngx-toastr';

import {Location} from '@angular/common';

import $ from 'jquery';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  host: { '(window:data-synced)': 'showDataSyncedToast($event)', '(window:error-caching)': 'showErrorCachingToast($event)', '(window:caching-now)': 'showCachingToast($event)', '(window:need-reload)': 'needReloadFn($event)' }
})

export class AppComponent implements OnInit {

  localStyle = this.doc.getElementById('localeStyleSheet');
  tempPath = './assets/css/';
  // tslint:disable-next-line:max-line-length
  constructor(private readonly router: Router, private readonly ng4LoadingSpinnerService: Ng4LoadingSpinnerService,
    private readonly userService: UserService, private readonly authenticationService: AuthenticationService,
    private update: UpdateService, public toastrService: ToastrService, private commonService: CommonService,
    private location: Location,
    @Inject(DOCUMENT) private doc, private localeService: LocaleService) {

    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    if (window['isUpdateAvailable']) {
      window['isUpdateAvailable']
        .then(isAvailable => {
          if (isAvailable) {
            // this.toastrService.info('An updated version of the application is available');
            this.commonService.showToast('info', 'update_available', {});
            // window['isUpdateAvailable'] = false;
          }
        });
    }

    const localLang = this.location.prepareExternalUrl('').replace(/\//g,'');

    if (localLang === 'zh') {
      this.localStyle.removeAttribute('href');
      this.localStyle.setAttribute('href', this.tempPath + 'style-zh.css');
    } else if (localLang === 'en') {
      this.localStyle.removeAttribute('href');
      this.localStyle.setAttribute('href', this.tempPath + 'style-en.css');
    } else if (localLang === 'es') {
      this.localStyle.removeAttribute('href');
      this.localStyle.setAttribute('href', this.tempPath + 'style-es.css');
    } else if (localLang === 'fr') {
      this.localStyle.removeAttribute('href');
      this.localStyle.setAttribute('href', this.tempPath + 'style-fr.css');
    } else if (localLang === 'ta') {
      this.localStyle.removeAttribute('href');
      this.localStyle.setAttribute('href', this.tempPath + 'style-ta.css');
    } else if (localLang === 'mg') {
      this.localStyle.removeAttribute('href');
      this.localStyle.setAttribute('href', this.tempPath + 'style-mg.css');
    } else {
      this.localStyle.removeAttribute('href');
    }
  }

  ngOnInit() {
    this.scrollToTop();
    /* Scroll to top route */
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // window.scrollTo(0, 0);
    });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {

    // if (event instanceof NavigationStart) {
    //   this.ng4LoadingSpinnerService.show();
    // }

    // if (event instanceof NavigationEnd) {
    //   setTimeout(function () { this.ng4LoadingSpinnerService.hide(); }.bind(this), 1000);
    // }

    // // Set loading state to false in both of the below events to hide the spinner in case a request fails
    // if (event instanceof NavigationCancel) {
    //   setTimeout(function () { this.ng4LoadingSpinnerService.hide(); }.bind(this), 1000);
    // }

    // if (event instanceof NavigationError) {
    //   setTimeout(function () { this.ng4LoadingSpinnerService.hide(); }.bind(this), 1000);
    // }
  }

  showDataSyncedToast(args: any): void {
    this.toastrService.clear();
    // this.toastrService.success('All data synced successfully');
    this.commonService.showToast('success', 'data_synced_successfully', {});
  }

  // there was an error caching so show the error
  showErrorCachingToast(args: any): void {
    this.commonService.showToast('error', 'error_caching', {});
  }

  // there was an error caching so show the error
  showCachingToast(args: any): void {
    this.commonService.showToast('warning', 'caching_warning', { disableTimeOut: true });
  }

  needReloadFn(args: any): void {
    // console.log('needReloadFn called');
    this.commonService.showToast('warning', 'need_reload', { disableTimeOut: true }, true);
  }

  scrollToTop() {
    $('html, body').animate({
      scrollTop: $('#fullPage').scrollTop = 0
    }, 'slow');
  }
}
