import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';



@Injectable()
export class AuthguardGuard implements CanActivate {

  constructor(private readonly authenticationService: AuthenticationService, private readonly router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.authenticationService.isAuthenticated()) {

      
      return true;
    }

     this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });

     return false;

   }
}
