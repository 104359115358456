<div id="fullPage">
	<!-- <app-header></app-header> -->
	<ng-progress [positionUsing]="'marginLeft'" [minimum]="0.15" [maximum]="1"
	[speed]="200" [showSpinner]="false" [color]="'#EA5A0B'" [trickleSpeed]="250" [thick]="true" [ease]="'linear'"></ng-progress>

	<router-outlet></router-outlet>
	<tour-step-template></tour-step-template>
  <ng4-loading-spinner></ng4-loading-spinner>
	<app-footer></app-footer>
</div>
