<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToReportingManagement(project?.id)" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
          <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)">
        <span i18n-title="@@projectReporting" title="Project Reporting">...</span>
      </li>
      <li class="breadcrumb-item active" aria-current="page"><span i18n="@@dashboards">Dashboards</span></li>
    </ol>
  </nav>
</div>

<div class="content-wrap content-wrapper background margin-btm-20">
  <div class="form-design form-box">
    <div class="row">
      <div class="col-md-12 col-lg-5"><label class="group-title inline-block"><span i18n="@@dashboards">Dashboards</span></label></div>
      <div class="col-md-12 col-lg-7 form-group fltRgt datatable-header slidedownsearch">
        <button type="button" name="button" class="btn btn-success" (click)="addNewDashboard()">
          <span i18n="@@add">Add</span><span></span>
        </button>

        <input name="userFilter" type="text" [(ngModel)]="searchText" placeholder="Search Dashboard"
          class="form-control" style="display: inline;width:initial" i18n-placeholder="@@searchDashboard">
      </div>
    </div>
    <!-- CARD VIEW -->
    <div class="" *ngIf="listViewDashboardStyle && dashboardLists.length > 0">
      <div class="row" style="margin-top: 1%;">
        <div class="col-lg-3" *ngFor="let list of dashboardLists | gridFilter: getGridFilters()">
          <div class="dashboard-card-wrapper" [ngClass]="list.dashboard_type == 'predefined'? 'dashboard-predefined': 'dashboard-self'" (click)="dashboardViewReports(list)">
            <div class="text-center dashboard-card-image">
              <img *ngIf="list.dashboard_type == 'predefined'" style="width: 40%;" src="assets/images/system.svg" alt="System" />
              <img *ngIf="list.dashboard_type == 'self'" style="width: 40%;" src="assets/images/self.svg" alt="Self" />
            </div>
            <div class="dashboard-card-text">
              {{list.name}}
            </div>
            <div class="dashboard-card-buttons text-right">
              <span *ngIf="list.dashboard_type != 'predefined'">
                <button class="edit-btn inline-flex" title="edit" i18n-title="@@edit" name="button" type="button " (click)="editDashboard(list)">
                  <img height="15px" alt="" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDY5LjMzMSA0NjkuMzMxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NjkuMzMxIDQ2OS4zMzE7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PGc+PGc+Cgk8cGF0aCBkPSJNNDM4LjkzMSwzMC40MDNjLTQwLjQtNDAuNS0xMDYuMS00MC41LTE0Ni41LDBsLTI2OC42LDI2OC41Yy0yLjEsMi4xLTMuNCw0LjgtMy44LDcuN2wtMTkuOSwxNDcuNCAgIGMtMC42LDQuMiwwLjksOC40LDMuOCwxMS4zYzIuNSwyLjUsNiw0LDkuNSw0YzAuNiwwLDEuMiwwLDEuOC0wLjFsODguOC0xMmM3LjQtMSwxMi42LTcuOCwxMS42LTE1LjJjLTEtNy40LTcuOC0xMi42LTE1LjItMTEuNiAgIGwtNzEuMiw5LjZsMTMuOS0xMDIuOGwxMDguMiwxMDguMmMyLjUsMi41LDYsNCw5LjUsNHM3LTEuNCw5LjUtNGwyNjguNi0yNjguNWMxOS42LTE5LjYsMzAuNC00NS42LDMwLjQtNzMuMyAgIFM0NTguNTMxLDQ5LjkwMyw0MzguOTMxLDMwLjQwM3ogTTI5Ny42MzEsNjMuNDAzbDQ1LjEsNDUuMWwtMjQ1LjEsMjQ1LjFsLTQ1LjEtNDUuMUwyOTcuNjMxLDYzLjQwM3ogTTE2MC45MzEsNDE2LjgwM2wtNDQuMS00NC4xICAgbDI0NS4xLTI0NS4xbDQ0LjEsNDQuMUwxNjAuOTMxLDQxNi44MDN6IE00MjQuODMxLDE1Mi40MDNsLTEwNy45LTEwNy45YzEzLjctMTEuMywzMC44LTE3LjUsNDguOC0xNy41YzIwLjUsMCwzOS43LDgsNTQuMiwyMi40ICAgczIyLjQsMzMuNywyMi40LDU0LjJDNDQyLjMzMSwxMjEuNzAzLDQzNi4xMzEsMTM4LjcwMyw0MjQuODMxLDE1Mi40MDN6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiNGRkZGRkYiIGRhdGEtb2xkX2NvbG9yPSIjZmZmZmZmIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==">
                </button>
              </span>
              <span *ngIf="!list.is_default && list.dashboard_type != 'predefined'" >
                <button class="edit-btn inline-flex white ml-2" title="set as default" i18n-title="@@setAsDefault" (click)="setADasboardAsDeafult(list?.id)">
                  <span class="utilicon utilicon-thumbtack-light" style="height: 15px;"></span> 
                </button>
              </span>
            </div>
            <div class="dashboard-card-label">
              <img *ngIf="list.dashboard_type == 'predefined'" src="assets/images/system-text.svg" alt="System" />
              <img *ngIf="list.dashboard_type == 'self'" src="assets/images/self-text.svg" alt="Self" />
            </div>
            <div class="dashboard-card-created text-center"><span i18n="@@lastUpdated">Last updated: </span>{{list.upd_date | date: 'dd-MMM-yyyy'}}</div>
          </div>
        </div>
        <!--Added by Kiran to show Vanilla Dashboard-->
        <!-- <div class="col-lg-3">
          <div class="dashboard-card-wrapper dashboard-predefined" (click)="dashboardVanilla()">
            <div class="text-center dashboard-card-image">
              <img style="width: 40%;" src="assets/images/system.svg" alt="System" />
            </div>
            <div class="dashboard-card-text">
              Community Livelihood
            </div>
            <div class="dashboard-card-label">
              <img src="assets/images/system-text.svg" alt="System" />
            </div>
            <div class="dashboard-card-created text-center">Last updated: 7-Sep-2020</div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="dashboard-card-wrapper dashboard-predefined" (click)="farmsProfile()">
            <div class="text-center dashboard-card-image">
              <img style="width: 40%;" src="assets/images/system.svg" alt="System" />
            </div>
            <div class="dashboard-card-text">
              Farms Profile
            </div>
            <div class="dashboard-card-label">
              <img src="assets/images/system-text.svg" alt="System" />
            </div>
            <div class="dashboard-card-created text-center">Last updated: 15-Sep-2020</div>
          </div>
        </div>
        <!-- vanilla dashboard ends here-->
      </div>
      <!-- <div class="highchart-wrapper-box"  *ngFor="let list of dashboardLists | gridFilter: getGridFilters()">
        <div class="highchart-card" [ngClass]="list.is_default? 'active': 'no'">
          <span style="display: inline-block;width: 80%;cursor: pointer;" (click)="dashboardViewReports(list)">
            <div class="title">{{list.name}}</div>
            <p class="marker">Type</p>
            <p>{{list.dashboard_type}}</p>
            <p class="marker">Last Updated</p>
            <p>{{list.upd_date | date: 'dd-MMM-yyyy'}}</p>
          </span>
          <span *ngIf="list.dashboard_type != 'predefined'">
            <button class="btn btn-primary" name="button " style="float: right;margin: 0;" type="button " (click)="editDashboard(list)">
              <img height="20px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDY5LjMzMSA0NjkuMzMxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NjkuMzMxIDQ2OS4zMzE7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PGc+PGc+Cgk8cGF0aCBkPSJNNDM4LjkzMSwzMC40MDNjLTQwLjQtNDAuNS0xMDYuMS00MC41LTE0Ni41LDBsLTI2OC42LDI2OC41Yy0yLjEsMi4xLTMuNCw0LjgtMy44LDcuN2wtMTkuOSwxNDcuNCAgIGMtMC42LDQuMiwwLjksOC40LDMuOCwxMS4zYzIuNSwyLjUsNiw0LDkuNSw0YzAuNiwwLDEuMiwwLDEuOC0wLjFsODguOC0xMmM3LjQtMSwxMi42LTcuOCwxMS42LTE1LjJjLTEtNy40LTcuOC0xMi42LTE1LjItMTEuNiAgIGwtNzEuMiw5LjZsMTMuOS0xMDIuOGwxMDguMiwxMDguMmMyLjUsMi41LDYsNCw5LjUsNHM3LTEuNCw5LjUtNGwyNjguNi0yNjguNWMxOS42LTE5LjYsMzAuNC00NS42LDMwLjQtNzMuMyAgIFM0NTguNTMxLDQ5LjkwMyw0MzguOTMxLDMwLjQwM3ogTTI5Ny42MzEsNjMuNDAzbDQ1LjEsNDUuMWwtMjQ1LjEsMjQ1LjFsLTQ1LjEtNDUuMUwyOTcuNjMxLDYzLjQwM3ogTTE2MC45MzEsNDE2LjgwM2wtNDQuMS00NC4xICAgbDI0NS4xLTI0NS4xbDQ0LjEsNDQuMUwxNjAuOTMxLDQxNi44MDN6IE00MjQuODMxLDE1Mi40MDNsLTEwNy45LTEwNy45YzEzLjctMTEuMywzMC44LTE3LjUsNDguOC0xNy41YzIwLjUsMCwzOS43LDgsNTQuMiwyMi40ICAgczIyLjQsMzMuNywyMi40LDU0LjJDNDQyLjMzMSwxMjEuNzAzLDQzNi4xMzEsMTM4LjcwMyw0MjQuODMxLDE1Mi40MDN6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiNGRkZGRkYiIGRhdGEtb2xkX2NvbG9yPSIjZmZmZmZmIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==">
            </button>
          </span>
          <div class="footer">
            <!-- <button class="btn btn-primary" name="button " style="display: inline-flex" type="button " (click)="editDashboard(list)">
              <img height="20px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDY5LjMzMSA0NjkuMzMxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NjkuMzMxIDQ2OS4zMzE7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PGc+PGc+Cgk8cGF0aCBkPSJNNDM4LjkzMSwzMC40MDNjLTQwLjQtNDAuNS0xMDYuMS00MC41LTE0Ni41LDBsLTI2OC42LDI2OC41Yy0yLjEsMi4xLTMuNCw0LjgtMy44LDcuN2wtMTkuOSwxNDcuNCAgIGMtMC42LDQuMiwwLjksOC40LDMuOCwxMS4zYzIuNSwyLjUsNiw0LDkuNSw0YzAuNiwwLDEuMiwwLDEuOC0wLjFsODguOC0xMmM3LjQtMSwxMi42LTcuOCwxMS42LTE1LjJjLTEtNy40LTcuOC0xMi42LTE1LjItMTEuNiAgIGwtNzEuMiw5LjZsMTMuOS0xMDIuOGwxMDguMiwxMDguMmMyLjUsMi41LDYsNCw5LjUsNHM3LTEuNCw5LjUtNGwyNjguNi0yNjguNWMxOS42LTE5LjYsMzAuNC00NS42LDMwLjQtNzMuMyAgIFM0NTguNTMxLDQ5LjkwMyw0MzguOTMxLDMwLjQwM3ogTTI5Ny42MzEsNjMuNDAzbDQ1LjEsNDUuMWwtMjQ1LjEsMjQ1LjFsLTQ1LjEtNDUuMUwyOTcuNjMxLDYzLjQwM3ogTTE2MC45MzEsNDE2LjgwM2wtNDQuMS00NC4xICAgbDI0NS4xLTI0NS4xbDQ0LjEsNDQuMUwxNjAuOTMxLDQxNi44MDN6IE00MjQuODMxLDE1Mi40MDNsLTEwNy45LTEwNy45YzEzLjctMTEuMywzMC44LTE3LjUsNDguOC0xNy41YzIwLjUsMCwzOS43LDgsNTQuMiwyMi40ICAgczIyLjQsMzMuNywyMi40LDU0LjJDNDQyLjMzMSwxMjEuNzAzLDQzNi4xMzEsMTM4LjcwMyw0MjQuODMxLDE1Mi40MDN6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiNGRkZGRkYiIGRhdGEtb2xkX2NvbG9yPSIjZmZmZmZmIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==">
            </button>
            <button class="btn btn-primary-blue" *ngIf="!list.is_default && list.dashboard_type != 'predefined'" (click)="setADasboardAsDeafult(list?.id)">Set as default</button>
          </div>
        </div>
      </div> -->
      <div *ngIf="dashboardLists.length <= 0">
        No dashboards available
      </div>
    </div>
  </div>
</div>
