<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="getBackProjectFilter(project?.name, project?.id)" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item active cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item active" aria-current="page"><span i18n="@@projectReporting">Project Reporting</span></li>
    </ol>
  </nav>
</div>

<div class="content-wrap content-wrapper background margin-btm-20">

     <div class="myprojects">
        <div class="list-project" id="myList">
        <ul class="mdm-box row" *ngIf="online$ | async; else offlineMsg">
          <li class="col-lg-2 col-md-2 col-sm-6 col-xs-12" *ngFor="let list of projectReporting">
              <span class="box box_mh text-center" (click)="detailProjectReport(list)">
                <img alt="" [src]="list.image">
                <h3>{{list.title}}</h3>
                <p>{{list.subtitle}}</p>
              </span>
          </li>
        </ul>
        <ng-template #offlineMsg>
          <ul class="mdm-box row">
            <span class="text-red" i18n="@@reportsNotAvailableOffline">Reports not available when offline</span>
          </ul>
        </ng-template>
      </div>
    </div>

</div>
