// TODO: Done: Delete template selection component if not required
// TODO: Done: Select option to create questionnaire from blank slate



import { Component, OnInit, TemplateRef, HostListener, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { takeUntil, map, mapTo } from 'rxjs/operators';
import { Subject, Observable, fromEvent, merge, of, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { SurveyService } from '../../services/survey.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { registerLocaleData } from '@angular/common';
import { RevisionService } from '../../services/revision.service';
import { UserService } from '../../services/user.service';
import {ChangeDetectorRef} from '@angular/core';
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent implements OnInit {

  projectId;
  project;
  allSurveys = [];
  snapshotSurveys = [];
  campaignSurveys = [];
  snapshotSurveyAction;
  campaignSurveyAction;
  surveyTemplatesModal: BsModalRef;
  surveyIdClicked;
  showSnapshotSurveyCreateButton;
  showCampaignSurveyCreateButton;
  ecosystemTemplates = [];
  templateId;
  loadingSurveyData;
  public closeSnapshotModal: BsModalRef;
  canCreateSnapshot = false;
  canScheduleRevision = false;
  currentUser = this.userService.getCurrentUser();
  schedule;
  scheduleRevisionModal: BsModalRef;
  scheduleText;
  showPrompt = true;
  scheduleSavedSuccessfully: string;
  scheduleStopSuccessfully: string;
  public revisionForm: FormGroup;
  frequency = [];
  languagesReadyForRelease;
  languagesNotReadyForRelease;
  saveTemplateModal;
  templateNameInput;
  templateDescriptionInput;
  saveTemplateSubmitted = false;
  savingTemplate = false;
  disableButtons;
  saveTemplateAfterActivation;
  questionnaire = []; // Array of tabs
  labelVariable = 'survey_q_asset_labels';
  displayLogicVariable = 'survey_q_asset_display_logic';
  autocalculateFormulaVariable = 'survey_q_asset_autocalculate_formulas';
  currentLocaleLanguageIdIndex;
  userLanguages = [];
  showUpArrow = false;
  campaigns = [];
  fromCampaign = false;
  fromProject = false;
  isAllConsSelected = false;
  isCampaignActivation = false;
  surveyName;
  emptySurveyName = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private surveyService: SurveyService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly userService: UserService,
    private translate: TranslateService,
    private revisionService: RevisionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private ref: ChangeDetectorRef,
    private readonly campaignService: CampaignService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {
    if (this.locale && this.locale.length > 2) {
      this.locale = this.locale.substr(0, 2);
    }
    this.translate.get('schedule_saved_successfully').subscribe(result => {
      this.scheduleSavedSuccessfully = result;
    });
    this.translate.get('schedule_stopped_successfully').subscribe(result => {
      this.scheduleStopSuccessfully = result;
    });
  }

  @ViewChild('quickConnectModal') scheduleRevisionModalTemplate: any;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.cancelModal(this.revisionForm);
    this.declineCloseSnapshot();
    if (this.surveyTemplatesModal) {
      this.surveyTemplatesModal.hide();
    }
    this.closeSaveTemplateModal();    
    this.printSurvey('close');
  }

  async ngOnInit() {
    this.projectId = +this.route.snapshot.params['id'];
    this.project = this.route.snapshot.data.project.project;
    this.loadingSurveyData = true;   
    this.route.queryParamMap.subscribe(async queryParams => {
      if (queryParams.get('fromCampaign') && queryParams.get('fromCampaign') === 'true') {
        this.fromCampaign = true;
      }
      if (queryParams.get('fromProject') && queryParams.get('fromProject') === 'true') {
        this.fromProject = true;
      }    
      if (queryParams.get('isAllConsSelected') && queryParams.get('isAllConsSelected') === 'true') {
        this.isAllConsSelected = true;
      }    
        
    })
    if(this.fromCampaign){
      document.getElementById('collapseOne').classList.remove('show');
      document.getElementById('collapseTwo').classList.add('show');
    }
    if(this.fromProject){
      document.getElementById('collapseTwo').classList.remove('show');
      document.getElementById('collapseOne').classList.add('show');
    }
    this.setSurveys();
    this.setSnapshotPermissions();
    this.setRevisionData();
  }
  // Get the languageId of the current locale
  getLanguageId() {
    if (this.userLanguages && this.userLanguages.length > 0) {
      const p = this.userLanguages.find(item => item.locale == this.locale);
      if (p) {
        return p['id'];
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  // Get all surveys for the given project & filter them by type
  async setSurveys() {
    const requests = await Promise.all([this.surveyService.getAllSurveysForProject(this.projectId).toPromise()]);
    this.allSurveys = requests[0].message;

    this.filterSurveys();
    this.getCampaigns();
    this.loadingSurveyData = false;
  }

  // Create survey for the given project, of the specified type (snapshot / campaign)
  async createSurvey(type, template) {
    this.spinner.show();
    const requests = await Promise.all([this.surveyService.createSurvey(this.projectId, type).toPromise()]);
   
    if (requests[0].code == 'success') {
      if (requests[0].message['cr_date']['val'] === 'CURRENT_TIMESTAMP') {
        requests[0].message['cr_date'] = new Date();
      }
      this.allSurveys.unshift(requests[0].message);
      this.filterSurveys();
      this.spinner.hide();
      this.surveyActionPrimary(requests[0].message, template);
    } else {
      this.spinner.hide();
    }
  }

  filterSurveys(): void {
    this.campaignSurveys = this.allSurveys.filter(item => item.survey_type == 'campaign');
    this.snapshotSurveys = this.allSurveys.filter(item => item.survey_type == 'snapshot');
    this.showCreateButtons();
  }

  // Take the primary button's action = here it is calling manageQuestionnaire function
  surveyActionPrimary(survey, template): void {
    this.surveyIdClicked = survey.id;
    if (survey.survey_stage_changelogs[0].change_type == 'draft') {
      this.manageQuestionnaire(survey, template);
    }
  }

  // Take the secondary button's action = here it is activate / close survey
  surveyActionSecondary(survey, template, template1: TemplateRef<any>): void {
    if(survey.survey_type == 'campaign'){
      this.isCampaignActivation = true;
    } else {
      this.isCampaignActivation = false;
    }
    this.surveyIdClicked = survey.id;
    if (survey.survey_stage_changelogs[0].change_type == 'draft') {
      this.spinner.show();
      // Check which languages are OK to be released - a language is OK if there are no invalid and no missing labels for any asset
      this.surveyService.checkLanguageOKForRelease(this.surveyIdClicked)
      .subscribe(result => {
        this.spinner.hide();
        if (result.code == 'success') {
          this.languagesReadyForRelease = result['message']['ok'];
          this.languagesNotReadyForRelease = result['message']['notOK'];
          this.saveTemplateAfterActivation = false;
          this.closeSnapshotModal = this.modalService.show(template1, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
      })
    }
    if (survey.survey_stage_changelogs[0].change_type == 'active') {
      //this.closeSurvey(this.surveyIdClicked);
      this.closeSnapshotModal = this.modalService.show(template1, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    }
  }

  /**
   * Check whether the template details entered by user are valid. If valid, then call activateSurvey function
   * @param template 
   */
  confirmActivation(template) : void {
    if (this.saveTemplateAfterActivation) {
      if (this.savingTemplate || this.disableButtons) {
        return;
      }
      this.saveTemplateSubmitted = true;
      if (!this.templateNameInput || this.templateNameInput.length == 0 || !this.templateDescriptionInput || this.templateDescriptionInput.length == 0) {
        return;
      }
    }
    if (this.closeSnapshotModal) { this.closeSnapshotModal.hide(); }
    this.activateSurvey(this.surveyIdClicked, template);
  }

  confirmCloseSnapshot() : void {
    if (this.closeSnapshotModal) { this.closeSnapshotModal.hide(); }
    this.closeSurvey(this.surveyIdClicked);
  }

  declineCloseSnapshot(): void {
    if (this.closeSnapshotModal) { this.closeSnapshotModal.hide(); }
    this.closeSaveTemplateModal();
  }

  // Move survey to stage = active. Allow responses to the survey
  async activateSurvey(surveyId, template) {
    this.spinner.show();
    const requests = await Promise.all([this.surveyService.activateSurvey(surveyId).toPromise()]);
    if (requests[0].code == 'success') {
      if (this.saveTemplateAfterActivation) {
        this.saveTemplate();
      }
      this.commonService.showToast('success', 'survey_activated', {});
      this.setSurveys();
      if (!this.saveTemplateAfterActivation) {this.spinner.hide();}
    } else {
      this.spinner.hide();
    }
  }

  // Move survey to stage = closed. Do not allow further responses to survey
  async closeSurvey(surveyId) {
    this.spinner.show();
    const requests = await Promise.all([this.surveyService.closeSurvey(surveyId).toPromise()]);
    if (requests[0].code == 'success') {
      this.commonService.showToast('success', 'survey_closed', {});
      this.setSurveys();
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }

  // Called on clicking Manage questionnaire button. If questionnaire exists, redirect to the questionnaire mgmt page
  // Else open the modal with templates list in it for selection
  async manageQuestionnaire(survey, template) {
    console.log('survey', survey);
    if (survey.questionnaireExists) {
      this.router.navigate(['questionnaire/edit/' + this.projectId + '/' + survey.id]);
    } else {
      this.spinner.show();
      const requests = await Promise.all([this.surveyService.checkSurveyQuestionnaireExists(survey.id).toPromise()]);
      if (requests[0].code == 'success') {
        if (requests[0].message == 'noQuestionnaire') {
          const templates = await Promise.all([this.surveyService.getEcosystemTemplates().toPromise()]);
          this.spinner.hide();
          const x = templates[0].message;
          if (x && x.length > 0) {
            this.ecosystemTemplates = x.filter(item => item.template_type === survey.survey_type);
          }
          this.surveyTemplatesModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class:'modal-md-firmenich' });
        } else {
          this.spinner.hide();
          this.router.navigate(['questionnaire/edit/' + this.projectId + '/' + survey.id]);
        }
      }
    }
  }

  closeSurveyTemplateModal(myForm: NgForm): void {
    // Hide the modal
    if (this.surveyTemplatesModal) {this.surveyTemplatesModal.hide();}
    myForm.reset();
    this.templateId = undefined;
  }

  // Take required action (use / preview) for the clicked template
  // If templateId == 0, it indicates that the global P2F-Roots template is clicked
  async templateAction(actionType, templateId, myForm) {
    if (actionType == 'use') {
      const obj = {
        surveyName: this.surveyName
      }
      if (templateId != -1) {
        this.spinner.show();
        const requests = await Promise.all([this.surveyService.createQuestionnaireForSurvey(this.surveyIdClicked, templateId, obj).toPromise()]);
        if (requests[0].code == 'success') {
          this.closeSurveyTemplateModal(myForm);
          this.commonService.showToast('success', 'success_questionnaire_created', {}, null, {"languages": requests[0].message});
          this.setSurveys();
          this.spinner.hide();
          
        } else {
          this.spinner.hide();
        }
      } else {
        const requests = await Promise.all([this.surveyService.updateSurveyNameBySurveyId(this.surveyIdClicked, obj).toPromise()]);
        if (requests[0].code == 'success') {
          this.closeSurveyTemplateModal(myForm);
          this.router.navigate(['questionnaire/edit/' + this.projectId + '/' + this.surveyIdClicked]);
        }
      }
    } else if (actionType == 'preview') {
      if (templateId != -1) {
        this.closeSurveyTemplateModal(myForm);
        this.router.navigate(['questionnaire/template/' + this.projectId + '/'+ this.surveyIdClicked + '/' + templateId]);
      } else {
        this.commonService.showToast('success', 'blank_template_cannot_previewed', {});
      }
    }
  }

  // Show the buttons to create the survey if either no survey exists, or the stage of the latest survey = closed
  showCreateButtons(): void {
    if (this.snapshotSurveys.length == 0 || this.snapshotSurveys[0].survey_stage_changelogs[0].change_type == 'closed') {
      this.showSnapshotSurveyCreateButton = true;
    } else {
      this.showSnapshotSurveyCreateButton = false;
    }

    if (this.campaignSurveys.length == 0 || this.campaignSurveys[0].survey_stage_changelogs[0].change_type == 'closed') {
      this.showCampaignSurveyCreateButton = true;
    } else {
      this.showCampaignSurveyCreateButton = false;
    }
  }

  // Depdnding on the user's service component mappings, show / hide buttons to create snapshot & schedule revision
  setSnapshotPermissions(): void {
    if (this.currentUser.role.id > 3) {
      // For all other types of users, check whether they have been given access to that component during the project setup
      if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
        let canCreateSnapshot = this.project.supplier_project_services_components_mapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 6 && item.user_type_id == this.currentUser.role.id));
        if (canCreateSnapshot && canCreateSnapshot.length > 0) {
          this.canCreateSnapshot = true;
        }

        // For all other types of users, check whether they have been given access to that component during the project setup
        let canScheduleRevision = this.project.supplier_project_services_components_mapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 7 && item.user_type_id == this.currentUser.role.id));
        if (canScheduleRevision && canScheduleRevision.length > 0) {
          this.canScheduleRevision = true;
        }
      }
    }
    else {
      if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
        let canCreateSnapshot = this.project.ecosystem_services_components_mapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 6));
        if (canCreateSnapshot && canCreateSnapshot.length > 0) {
          this.canCreateSnapshot = true;
        }

        let canScheduleRevision = this.project.ecosystem_services_components_mapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 7));
        if (canScheduleRevision && canScheduleRevision.length > 0) {
          this.canScheduleRevision = true;
        }
      }
    }
  }

  // Show schedule revision modal
  scheduleRevisionModalFn(template: TemplateRef<any>): void {
    this.schedule = Object.assign({}, this.scheduleText);
    this.scheduleRevisionModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  declineModal(form): void {
    if (form.dirty) {
      this.showPrompt = false;
    }
    else {
      if (this.scheduleRevisionModal) {
        this.schedule = Object.assign({}, this.scheduleText);
        //this.schedule = this.scheduleText;
        this.scheduleRevisionModal.hide();
      }
    }
  }

  cancelModal(form):void{
    //console.log("form ", form);
    if (form.dirty) {
      //console.log("some thing chnages");
      this.showPrompt = false;
    } else {
      this.confirmModalFn('Yes');
    }
  }

  confirmModalFn(what): void {
    if (what === 'Yes') {
      if (this.scheduleRevisionModal){
        this.schedule = Object.assign({}, this.scheduleText);
        //console.log("this.schedule ", this.schedule);
        //this.schedule = this.scheduleText;
        this.scheduleRevisionModal.hide();
        setTimeout(() => this.showPrompt = true, 1000);
        this.revisionForm.markAsPristine();
      }
    }
    else {
      setTimeout(() => this.showPrompt = true, 1000);
      //this.showPrompt = true;
    }
  }

  /**
   * Save the schedule revision as per user inputs
   * @param form 
   */
  saveSchedule(form:any) {
    //  debugger;
    //console.log("submit form ::: ", form.value);
    //console.log("this.schedule ::: ", this.schedule);
    if (this.schedule.id > 0) {
      this.schedule.upd_user_id = this.currentUser.id;
      this.schedule.upd_date = Date.now();
      this.schedule.revision_status = 'Not Started';
    } else {
      this.schedule.cr_user_id = this.currentUser.id;
      this.schedule.cr_date = Date.now();
    }

    const dt = new Date();
    //const hrs = dt.getHours();

    dt.setHours(this.schedule.hrs, 0, 0);

    this.schedule.scheduled_datetime = dt;


    if (this.schedule.scheduling_frequency == 2) {
      dt.setDate(dt.getDate() + ((7 - dt.getDay()) % 7) +  (+this.schedule.weekday) );
      this.schedule.scheduled_datetime = dt;
    }
    if (this.schedule.scheduling_frequency == 4) {
      dt.setDate(this.schedule.day);
      this.schedule.scheduled_datetime = dt;
    }
    this.schedule['scheduled_datetime_utc'] = this.schedule.scheduled_datetime.getTime();
    const year = this.schedule.scheduled_datetime.getFullYear();
    const month = this.schedule.scheduled_datetime.getMonth();
    const date = this.schedule.scheduled_datetime.getDate();
    this.schedule['year'] = year;
    this.schedule['month'] = month;
    this.schedule['date'] = date;

    this.revisionService.SaveRevisionSchedule(this.schedule).subscribe(res => {
      if (res.msg == 'success') {
        if (res.data.deleted && res.data.deleted !== '0') {
          this.schedule = { id: 0, project_id: this.projectId, scheduling_frequency: 1, scheduled_datetime: new Date(), weekday: 1, day:1, hrs:0 };
          this.scheduleText = Object.assign({}, this.schedule);
          this.toastr.success(this.scheduleStopSuccessfully);
        } else {
          this.schedule = res.data;
          this.schedule.frequencyName = this.frequency.find(x => x.id == this.schedule.scheduling_frequency).name;
          let dt = new Date(this.schedule.scheduled_datetime);
          this.schedule.weekday = dt.getDay();
          this.schedule.day = dt.getDate();
          this.schedule.hrs = dt.getHours();
          this.toastr.success(this.scheduleSavedSuccessfully);
          this.scheduleRevisionModal.hide();
          this.scheduleText = Object.assign({}, this.schedule);
        }
      }
    });
  }

  /**
   * Set the schedule frequency configs
   * If a schedule is already created for a project, set the value
   */
  async setRevisionData() {
    this.schedule = { id: 0, project_id: this.projectId, scheduling_frequency: 1, scheduled_datetime: new Date(), weekday: 1, day:1, hrs:0 };
    this.scheduleText = Object.assign({}, this.schedule);
    this.revisionForm = this.formBuilder.group({
      scheduling_frequency:new FormControl(this.schedule.scheduling_frequency, [Validators.required]),
      day:new FormControl(this.schedule.day, [Validators.required]),
      weekday:new FormControl(this.schedule.weekday, [Validators.required]),
      hrs:new FormControl(this.schedule.hrs, [Validators.required])
    });
    const requests = await Promise.all([
      this.commonService.getScheduleFrequency().toPromise(),
      this.revisionService.GetRevisionSchedule(this.projectId).toPromise()
    ]);

    let frequencyData = requests[0];
    const shceduleData = requests[1];
    if (frequencyData.msg == 'success') {
      this.frequency = frequencyData.data;
    }
    if (shceduleData.msg == 'success' && shceduleData.data) {
      this.schedule = shceduleData.data;
      let SchDate = new Date(this.schedule.scheduled_datetime);
      this.schedule.weekday = SchDate.getDay();
      this.schedule.day = SchDate.getDate();
      this.schedule.hrs = SchDate.getHours();
      this.schedule.frequencyName = this.frequency.find(x => x.id == this.schedule.scheduling_frequency).name;
      this.scheduleText = Object.assign({}, this.schedule);
      // console.log('schedule', this.schedule)
    }
  }

  closeSaveTemplateModal(): void {
    if (this.saveTemplateModal) {
      this.saveTemplateModal.hide()
    }
    this.saveTemplateAfterActivation = false;
    this.templateNameInput = undefined;
    this.templateDescriptionInput = undefined;
    this.saveTemplateSubmitted = false;
    this.savingTemplate = false;
    this.ref.detectChanges();
  }

  // Function to save template from the given survey ID, with the added templateDetails
  saveTemplate(): void {
    this.commonService.showToast('warning', 'saving_template', {});
    const templateDetails = {
      name: this.templateNameInput,
      description: this.templateDescriptionInput
    };

    this.savingTemplate = true;
    this.ref.detectChanges();
    this.surveyService.saveAsTemplate(this.surveyIdClicked, templateDetails)
    .subscribe(result => {
      if (result['code'] == 'success') {
        this.commonService.showToast('success', 'template_created', {});
      }
      this.spinner.hide();
      this.closeSaveTemplateModal();
    }, error => {
      this.spinner.hide();
      this.closeSaveTemplateModal();
    })
  }

  //added by Kiran for template selection
  selectTemplate(el){
    const radioBut = el.getElementsByTagName('input')[0];
    radioBut.click();
    if(radioBut.checked){
      let allRadioButton = document.querySelectorAll('.template-card');
      for(let i = 0; i < allRadioButton.length; i++){
        allRadioButton[i].classList.remove('template-card-selected')
      }
      el.classList.add('template-card-selected')
    } else {
      el.classList.remove('template-card-selected');
    }
  }

  async stopScheduleRevision() {
    this.schedule.deleted = true;
    this.saveSchedule(null);
  }

  async getSurveyDetails(id, campaignId){
    if(campaignId){
      const url = `viewquestionnaire/${this.projectId}/${id}`;
      this.router.navigate([url], { queryParams: { campaignId: campaignId}});
    } else {
      const url = `viewquestionnaire/${this.projectId}/${id}`;
      this.router.navigate([url]);
    }
  }

  /**
   * Preview survey function
   * @param action 
   */
  printSurvey(action){    
    const surveyQuestionnaire = document.getElementById('surveyQuestionnaire');
    if(action === 'close'){
      surveyQuestionnaire.classList.remove('d-block');
      surveyQuestionnaire.classList.add('d-none');
      document.documentElement.style.overflowY = 'scroll';    
    } else {
      surveyQuestionnaire.classList.remove('d-none');
      surveyQuestionnaire.classList.add('d-block');
      document.documentElement.style.overflowY = 'hidden';
    } 
  }

  onScroll(item){
    if(document.getElementById('surveyQuestionnaire').scrollTop > 100){
      this.showUpArrow = true;
    } else {
      this.showUpArrow = false;
    }
  }
  scrollToTop() {
    document.getElementById('surveyQuestionnaire').scrollTop = 0; // For Safari
    //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  async getCampaigns() {
    const request = await Promise.all([
      this.campaignService.getCampaignsForProject(this.projectId).toPromise()
    ]);

    console.log('request', request);

    if (request[0]['code'] === 'success' && request[0]['message']) {
      const campaignArr = Array.isArray(request[0]['message']) ? request[0]['message'] : [request[0]['message']];
      if (campaignArr.length > 0) {
        for (const val of campaignArr) {
          val.survey = this.campaignSurveys.find(item => parseInt(item.flex1) === val.id);
        }
      }
      const activeCampaigns = campaignArr.filter(item => item.closed_date == null);
      const closedCampaigns = campaignArr.filter(item => item.closed_date != null);
      const withSurvey = activeCampaigns.filter(item => item.survey);
      const withoutSurvey = activeCampaigns.filter(item => !item.survey);
      const status = {
        'draft': 1,
        'active': 2,
        'closed': 3
      };
      //withSurvey.sort((a, b) => status[a.survey.survey_stage_changelogs[0].change_type] - status[b.survey.survey_stage_changelogs[0].change_type]);        
      withSurvey.sort(
      function(a, b) {      
         if (a.survey.survey_stage_changelogs[0].change_type === b.survey.survey_stage_changelogs[0].change_type) {
            // Price is only important when cities are the same
            if (a.survey.survey_stage_changelogs[0].change_at < b.survey.survey_stage_changelogs[0].change_at) {
              return 1;
            }
            if (a.survey.survey_stage_changelogs[0].change_at > b.survey.survey_stage_changelogs[0].change_at) {
              return -1;
            }
            return 0;
         }
         return status[a.survey.survey_stage_changelogs[0].change_type] - status[b.survey.survey_stage_changelogs[0].change_type];
        
      });
      withoutSurvey.sort((a,b) => {
        if (a.cr_date < b.cr_date) {
          return 1;
        }
        if (a.cr_date > b.cr_date) {
          return -1;
        }
      })
      const allSurveys = withoutSurvey.concat(withSurvey);
      closedCampaigns.sort((a,b) => {
        if (a.closed_date < b.closed_date) {
          return 1;
        }
        if (a.closed_date > b.closed_date) {
          return -1;
        }
      })
      this.campaigns = allSurveys.concat(closedCampaigns);
      // this.campaigns = Array.isArray(request[0]['message']) ? request[0]['message'] : [request[0]['message']];
      // this.campaigns.sort((a, b) => {
      //   if (a.closed_date < b.closed_date) {
      //     return 1;
      //   }
      //   if (a.closed_date > b.closed_date) {
      //     return -1;
      //   }
      //   return 0;
      // });
      // this.campaigns = this.campaigns.reverse();
    }

    //this.mapCampaignSurveysToCampaigns();

  }

  mapCampaignSurveysToCampaigns(): void {
    if (this.campaigns.length > 0) {
      for (const val of this.campaigns) {
        val.survey = this.campaignSurveys.find(item => parseInt(item.flex1) === val.id);
      }
    }
  }

  // Create campaign survey for the given project, of the specified type (snapshot / campaign)
  async createCampaignSurvey(template, c) {
    this.spinner.show();
    const requests = await Promise.all([this.surveyService.createCampaignSurvey(c.id).toPromise()]);
   
    if (requests[0].code === 'success') {
      if (requests[0].message['cr_date']['val'] === 'CURRENT_TIMESTAMP') {
        requests[0].message['cr_date'] = new Date();
      }
      this.allSurveys.unshift(requests[0].message);
      this.filterSurveys();
      this.getCampaigns();
      this.spinner.hide();
      this.surveyActionPrimary(requests[0].message, template);
    } else {
      this.spinner.hide();
    }
  }

  //check if farmername contains spaces only
  checkEmptyness(e){    
    const name = e.target.value;
    if (!name.replace(/\s/g, '').length) {
      this.emptySurveyName = true;
    } else {
      this.emptySurveyName = false;
    }
  }


}
