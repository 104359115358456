import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';




/* @Injectable()
export class NavigationGuard implements CanDeactivate<FarmersetupComponent> {
  constructor(private userService: UserService) {}
  canDeactivate(component: FarmersetupComponent) {
    // will prevent user from going back
    if (this.userService.getBackClicked()) {
      this.userService.setBackClicked(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    }
    return true;
  }
}
 */

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class NavigationGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: CanComponentDeactivate,
           route: ActivatedRouteSnapshot,
           state: RouterStateSnapshot) {
     const url : string = state.url;

     return component.canDeactivate ? component.canDeactivate() : true;
  }
}

