import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Project, UserTypeServiceMapping } from '../../../models/project';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuppliersService } from '../../../services/suppliers.service';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
import { CommonService } from '../../../services/common.service';
@Component({
  selector: 'app-dashboards-list',
  templateUrl: './dashboards-list.component.html',
  styleUrls: ['./dashboards-list.component.css']
})
export class DashboardsListComponent implements OnInit {
  listViewDashboardStyle = true;
  projects: any = [];
  project: any;
  projectId = 0;
  public tableData: any;
  public origin: any;
  tableFinalData;
  public fromNavId;
  public typeOfAdministrationArr: any[];
  typeOfAdministration = '';
  public searchText: string;

  public previousPaginationLabel;
  public nextPaginationLabel;
  public dashboardLists:any = [];

  constructor(
    private readonly route: ActivatedRoute,
    private projectService: ProjectService,
    private toastrService: ToastrService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private readonly suppService: SuppliersService,
    private translate: TranslateService,
    private hcRService:HighchartReportsService,
    private commonService: CommonService
  ) {
    translate.setDefaultLang('en');
    // this.activeRoute.paramMap.subscribe(params => {
    //   this.activeRoute.queryParamMap.subscribe(queryParams => {
    //     const qpObject = { ...queryParams.keys, ...queryParams };
    //     // console.log('qpObject', qpObject);
    //     this.origin = qpObject['params']['origin'];
    //     this.fromNavId = qpObject['params']['from'];

    //     this.translate.get('typeAdminTextArr').subscribe((val) => {
    //       this.typeOfAdministrationArr = val;
    //     }, err => console.log('err', err));

    //     if (this.fromNavId === 2) {
    //       this.typeOfAdministration = this.typeOfAdministrationArr[0];
    //     } else if (this.fromNavId === 3) {
    //       this.typeOfAdministration = this.typeOfAdministrationArr[1];
    //     } else if (this.fromNavId === 6) {
    //       this.typeOfAdministration = this.typeOfAdministrationArr[2];
    //     }

    //   });

    // });

    // this.dashboardLists = [
    //   {
    //     id: 1,
    //     name: 'Dashboard new title ABC',
    //     description: 'demo text available',
    //     type: 'predefined',
    //     isdefault: true,
    //     last_updated: '20/05/2020'
    //   }, {
    //     id: 2,
    //     name: 'Dashboard XTR title Dre',
    //     description: 'demo text available line 2',
    //     type: 'self',
    //     isdefault: false,
    //     last_updated: '20/05/2020'
    //   }, {
    //     id: 3,
    //     name: 'Accessibility',
    //     description: 'demo text available as accessibility',
    //     type: 'self',
    //     isdefault: false,
    //     last_updated: '20/05/2020'
    //   }
    // ];

    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });

    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });

   }

  async ngOnInit() {
    this.projectId = this.route.snapshot.params['id'];
    // if (navigator.onLine) {
    //   this.project = this.route.snapshot.data.project.project.data;
    // }else{
    //   const requests = await Promise.all([
    //     this.projectService.GetProjectsDataDashboard().toPromise()
    //   ]);
    //   this.project = requests[0].data.find(x => x.id === this.projectId);
    // }

    this.projectService.getProjectBasicInfo(this.projectId).subscribe(response =>{
      this.project = response.data;
      const projectObject = this.project.filter(item => item.id == this.projectId)[0];
      this.project = projectObject;
    });

    const requestParams ={
      "projectId":this.projectId
    }

    this.hcRService.getDashboardsList(requestParams).subscribe(response =>{
      if(response.msg == 'success'){
        this.dashboardLists = response.data;
        for(let i=0;i<this.dashboardLists.length;i++){
          if(this.dashboardLists[i].is_default == 'Y'){
            this.dashboardLists[i].is_default = true;
          }else if(this.dashboardLists[i].is_default == 'N'){
            this.dashboardLists[i].is_default = false;
          }
        }

        //console.log("this.dashboardLists ", this.dashboardLists);
      }
      
    });

  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }
  getGridFilters() {
    //new fields added for search village_name and vc_name
    const gridColums = ['id', 'name', 'description', 'dashboard_type', 'upd_date'];
    const finalObj = {};
    if (this.dashboardLists === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  setADasboardAsDeafult(itemID){
    const requestParams ={
      "dashboardId":itemID,
      "projectId":this.projectId,
    }

    this.hcRService.setDashboardAsDefault(requestParams).subscribe(response =>{
      if(response.msg == 'success'){
        this.commonService.showToast('success', 'dashboard_set_default', {});
        const requestParams ={
          "projectId":this.projectId
        }    
        this.hcRService.getDashboardsList(requestParams).subscribe(response =>{
          //console.log("response ==> ", response);
          if(response.msg == 'success'){
            this.dashboardLists = response.data;
            for(let i=0;i<this.dashboardLists.length;i++){
              if(this.dashboardLists[i].is_default == 'Y'){
                this.dashboardLists[i].is_default = true;
              }else if(this.dashboardLists[i].is_default == 'N'){
                this.dashboardLists[i].is_default = false;
              }
            }
          }
          
        });
      }
    });
  }

  // listView() {
  //   this.listViewDashboardStyle = true;
  // }

  // cardView() {
  //   this.listViewDashboardStyle = false;
  // }

  goToReportingManagement(projectID){
    const url = 'charts/' + this.projectId;
    this.router.navigate([url]);
  }

  addNewDashboard(){
    const url="charts/dashboards/add/"+ this.projectId;
    this.router.navigate([url]);
  }

  editDashboard(currentItem){
    const url="charts/dashboards/edit/"+ currentItem.id +'/'+this.projectId;
    this.router.navigate([url]);
  }

  dashboardViewReports(currentItem){
    if(currentItem.dashboard_type == 'predefined'){
      const url="charts/dashboards/"+ currentItem.route +'/'+ currentItem.id +'/'+this.projectId;
      this.router.navigate([url]);
    }else if(currentItem.dashboard_type == 'self'){
      const url="charts/dashboards/viewreports/"+ currentItem.id +'/'+this.projectId;
      this.router.navigate([url]);
    }
    
  }

}
