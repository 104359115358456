<div class="bg">
    <div class="login-p2f-logo">
      <img src="assets/images/path2farm-logo-hor-white.webp" alt="path2farm logo"/>
    </div>
    <div class="login-fir-logo login-p2froots-img">
      <img src="assets/images/fir-logo.webp" alt="firmenich logo"/>
    </div>
</div>


<div class="login-right">
    <div class="login-p2froots-logo text-right" style="margin-bottom: 20px">
      <img class="login-p2froots-img" src="assets/images/p2f-roots-logo.webp" alt="p2f roots logo"/>
      <img class="login-p2froots-img-small" src="assets/images/p2f-roots-logo-white.webp" alt="p2f roots logo"/>
    </div>
    <div class="reset-screen login mt-0 pt-0" id="login">
      <div class="login-info">
        <div class="login-info reset-pwd text-center" ng-model="RESET">
          <h3 class="login-header" i18n="@@resetUserIDPassword">
            Reset User ID and Password
          </h3>
          <div class="reset-body text-center">
                <form name="resetForm" (ngSubmit)="resetUsernamePassword()" #resetform="ngForm" novalidate autocomplete="off">
                  <div class="row">
                    <div class="form-group col-lg-12 col-md-12">
                        <label for="username" class="mb-0">
                          <span  i18n="@@userId">Current User ID</span>
                          <strong class="mandatory"> *</strong>
                        </label>
                      <input class="form-control-login pl-2 pr-2" type="text" value="" name="olduserid" required
                      minlength="6" maxlength="10" [(ngModel)]="currentUserID" #olduserid="ngModel" [value]="olduserid.value?.toUpperCase()" autofocus appAutofocus>
                      <div *ngIf="olduserid.invalid && (olduserid.dirty)">
                          <div class="error" *ngIf="olduserid.hasError('required')" i18n="@@required">Required</div>
                          <div class="error" *ngIf="olduserid.hasError('minlength')" i18n="@@enterAtleast6Characters">Enter 6 to 10 characters</div>
                        </div>
                      <span class="separator"></span>
                    </div>
                    <div class="form-group col-lg-12 col-md-12">
                        <label for="username" class="mb-0"><span i18n="@@newUserID">New User ID</span>
                            <strong class="mandatory"> *</strong>
                        </label>
                      <input class="form-control-login pl-2 pr-2" type="text" value="" name="newuserid" required
                        minlength="6" maxlength="10" [(ngModel)]="newUserID" #newuserid="ngModel" [value]="newuserid.value?.toUpperCase()">
                        <div *ngIf="newuserid.invalid && (newuserid.dirty)">
                            <div class="error" *ngIf="newuserid.hasError('required')"><span i18n="@@required">Required</span></div>
                            <div class="error" *ngIf="newuserid.hasError('minlength')"><span i18n="@@enterAtleast6Characters">Enter 6 to 10 character</span></div>
                          </div>
                      <span class="separator"></span>
                    </div>
                  </div>

              <!-- <hr class="clearfix"> -->

              <div class="row">
                <div class="form-group col-lg-12 col-md-12">
                    <label for="username" class="mb-0">
                      <span i18n="@@currentPassword">Current Password</span>
                        <strong class="mandatory"> *</strong>
                    </label>
                <input class="form-control-login pl-2 pr-2" i18n-placeholder="@@oldPassword" type="password" value="" name="oldpwd" required
                  maxlength="20" [(ngModel)]="oldpassword" #oldpwd="ngModel">
                  <div *ngIf="oldpwd.invalid && (oldpwd.dirty)">
                      <div class="error" *ngIf="oldpwd.hasError('required')"><span i18n="@@required">Required</span></div>
                    </div>
                <span class="separator"></span>
                </div>
                <div class="form-group col-lg-12 col-md-12 clearfix posRel">
                  <label for="username" class="mb-0"><span i18n="@@newPassword">New Password</span>
                      <strong class="mandatory"> *</strong>
                  </label>
                  <div class="pos-r">
                    <input class="form-control-login pl-2 pr-2" i18n-placeholder="@@newPassword" [type]="showPass ? 'text' : 'password'" value="" name="newpwd" required
                      minlength="12" maxlength="20" [(ngModel)]="newpassword" #newpwd="ngModel" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$" 
                      (focus)="passChar = !passChar" (blur)="passChar = !passChar">
                    <span class="separator"></span>
                    <i class="utilicon utilicon-eye-light show-pass" title="Show Password" i18n-title="@@showPassword" *ngIf="!showPass" (click)="showPass = !showPass"></i>
                    <i class="utilicon utilicon-eye-slash-light show-pass" title="Hide Password" i18n-title="@@hidePassword" *ngIf="showPass" (click)="showPass = !showPass"></i>
                  </div>
                  <div class="password-strength-div" *ngIf="newpassword">
                    <div><label style="color: #004281; float: left; max-width: 175px;" *ngIf="newpassword" i18n="@@passwordStrength">Password Strength</label></div>
                    <div>
                      <ng2-password-strength-bar *ngIf="newpassword" class="fltRgt"
                        [passwordToCheck]="newpassword"
                        [barColors]="myColors">
                      </ng2-password-strength-bar>
                    </div>
                  </div>                    
                  <div *ngIf="newpwd.invalid && (newpwd.dirty)">
                    <div class="error" *ngIf="newpwd.hasError('required')"><span i18n="@@required">Required</span></div>
                    <!-- <div class="error" *ngIf="newpwd.hasError('minlength')"><span i18n="@@enter12to20characters" >Enter 12 to 20 characters</span></div> -->
                  </div>
                  <div class="password-popup" *ngIf="passChar">
                    <div i18n="@@passwordMust">Password must :</div>
                    <ul>
                      <li i18n="@@betweenCharacter">be between 12 and 20 characters</li>
                      <li i18n="@@includeUppercaseCharacter">include an uppercase character</li>
                      <li i18n="@@includeLowercaseCharacter">include a lowercase character</li>
                      <li i18n="@@includeSpecialCharacter">include a special character from @$!%*?&</li>
                      <li i18n="@@includeNumber">include a number</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-12 col-md-12">
                  <label for="username" class="mb-0">
                    <span i18n="@@confirmPassword">Confirm Password</span>
                    <strong class="mandatory"> *</strong>
                  </label>
                  <input class="form-control-login pl-2 pr-2" i18n-placeholder="@@confirmPassword" type="password" value="" name="cfmpwd"
                    required [(ngModel)]="cfmpassword" maxlength="20" #cfmpwd="ngModel">
                    <div *ngIf="cfmpwd.invalid && (cfmpwd.dirty)">
                        <div class="error" *ngIf="cfmpwd.hasError('required')" i18n="@@required">Required</div>
                      </div>
                    <span class="separator"></span>
                </div>
              </div>  
              <div class="clearfix mb-3">
                <button type="submit" class="btn-login-blue mr-3 mt-3 inline-block" id="submit" title="Reset" i18n-title="@@reset" [disabled]="resetform.form.invalid" [ngClass]="{ 'disabled': resetform.form.invalid }" i18n="@@reset">Reset</button>
                <button class="button" class="btn-login-blank mt-3 inline-block" title="Back" i18n-title="@@back" routerLink="/" i18n="@@back">Back</button>
              </div>
            </form>
          </div>
          <div class="reset-msg hide">
            <span class="desc" i18n="@@passwordChangedSuccessfully">
              Your password has been changed successfully.
            </span>
            <a routerLink="./" class="btn btn-lg-btn-success btn-block login-btn" i18n="@@login">Login</a>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="clearfix"></div>
