
export class Project {
  id = 0;
  name: string;
  product_name: string;
  product_id = 0;
  supplier_id = 0;
  supplier_name: string; // change supplier field to text
  program_code: string;
  country_id = 0;
  product_type = 0;
  description: string;
  photo_path = '';
  region: string;
  start_date: Date;
  currency_code = '';
  cr_user_id: number;
  project_manager = 0;
  UserTypeServiceMappings: UserTypeServiceMapping[];
  status_id = 0;
  ProjectMapping: UserProgramMapping[];
  Product: KeyValuePair;
  Supplier: KeyValuePair;
  Country: KeyValuePair;
  ProjectStatus: KeyValuePair;
  ProjectUOM: ProjectUOM[] = [];
  region_id=0;
  estimated_yield='';
  estimated_yield_weight_uom=0;
  estimated_yield_area_uom=0;
  region_state:string;
}

export class UserTypeServiceMapping {
  user_type_id: number;
  service_id: number;
  cr_user_id: number;
  project_id: number;
}

export class KeyValuePair {
  id: number;
  name: string;
}

export class UserProgramMapping {
  users_id: number;
  user_type_id: number;
  access_type_id: number;
  project_id: number;
  cr_user_id: number;
}

export class ProjectUOM {
  id: number;
  temp_id: number;
  project_id: number;
  uom: string;
  uom_type: string;
  // name: string;
  international_value: number;
  international_uom: string;
  is_default = false;
  unit_type_id: number;
  unit_id: number;
}
