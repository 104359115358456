import { Component, OnInit, Inject, ViewChild, HostListener, ElementRef, Renderer, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { CommonService } from '../../services/common.service';

import { environment } from '../../../environments/environment.prod';
import { LoginService } from '../../services/login.service';
import { StorageService } from '../../services/storage.service';
import { AuthenticationService } from '../../services/authentication.service';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

import { isPlatformBrowser, Location } from '@angular/common';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styles:[`
    .show-pass {
      position: absolute;
      top: 13px;
      right: 0px;
      color: #0078d1;
      font-size: 1rem;
      cursor: pointer;
    }
  `]
})
export class LoginFormComponent implements OnInit {
  model: any = { role: '' };
  isValid = true;
  returnUrl = 'route';
  // languages: any[] = [{ 'code': 'en', 'name': 'English' }, { 'code': 'es', 'name': 'Español' }];
  public loginErrorMsg: any = '';
  resetPassword = false;
  private readonly unsubscribe: Subject<void> = new Subject();

  roles: any = [];

  private loggedInUser: any;
  public loginSpinner = false;
  public capslockOn;

  public locale;
  public selectedLocale;
  public errorMessage: any;

  // flag set in two components
  flagEn = false;
  flagEs = false;
  flagFr = false;
  flagZh = false;
  flagMg = false;
  flagTa = false;
  flagHi = false;
  flagId = false;
  checkConsent;

  SWInstallation;
  loadingTextValue = 'Please wait';

  availableLocales = [
    { code: 'en', label: 'English'},
    { code: 'es', label: 'Español'},
    { code: 'fr', label: 'Français'},
    { code: 'ta', label: 'தமிழ்'},
    { code: 'mg', label: 'Malagasy'},
    { code: 'zh', label: '中文'},
    { code: 'hi', label: 'हिन्दी'},
    { code: 'id', label: 'Bahasa Indonesia'}
  ];

  flagClass;
  showPass = false;
  
  @ViewChild('username') firstNameTextbox: ElementRef;

  constructor(private readonly router: Router, private readonly route: ActivatedRoute,
    private readonly user: UserService, private readonly storageService: StorageService,
    private readonly loginService: LoginService, public readonly translate: TranslateService,
    private readonly authenticationService: AuthenticationService,
    private readonly el: ElementRef, private readonly renderer: Renderer,
    private readonly toastrService: ToastrService,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly commonService: CommonService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly loc: Location) {

      this.locale = this.commonService.getLocale();
      this.selectedLocale = this.locale;

      translate.addLangs(['en', 'es', 'fr', 'ta', 'mg', 'zh', 'hi', 'id']);
      translate.setDefaultLang('en');
      translate.use(this.locale);

      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'route';

      const localLang = this.loc.prepareExternalUrl('').replace(/\//g,'');

      this.flagClass = localLang;

    	if (localLang === 'en') {
        this.flagEn = true;
      }
      if (localLang === 'es') {
          this.flagEs = true;
      }
      if (localLang === 'fr') {
          this.flagFr = true;
      }
      if (localLang === 'zh') {
        this.flagZh = true;
      }
      if (localLang === 'ta') {
        this.flagTa = true;
      }
      if (localLang === 'mg') {
        this.flagMg = true;
      }
      if (localLang === 'en') {
        this.flagEn = true;
      }
      if (localLang === 'hi') {
        this.flagHi = true;
      }
      if (localLang === 'id') {
        this.flagId = true;
      }
  }

  @HostListener('window:click', ['$event']) onClick(event){
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      this.capslockOn = true;
     } else {
      this.capslockOn = false;
     }
    }

   @HostListener('window:keydown', ['$event'])
   onKeyDown(event){
   if (event.getModifierState && event.getModifierState('CapsLock')) {
    this.capslockOn = true;
     } else {
    this.capslockOn = false;
     }
   }

   @HostListener('window:keyup', ['$event'])
    onKeyUp(event){
    if (event.getModifierState && event.getModifierState('CapsLock')) {
     this.capslockOn = true;
    } else {
     this.capslockOn = false;
    }
   }

  ngOnInit() {
    const consentGiven = localStorage.getItem('consentGiven');
    const serviceWorkerInstallation = localStorage.getItem('SWstate');
    this.SWInstallation = serviceWorkerInstallation;
    if(consentGiven == 'true' && consentGiven != null){
      this.checkConsent = false;
    } else {
      this.checkConsent = true;
    }
    // for the service worker to cache resources properly 1-time hard refresh of the page is required
    // this is the key that denotes whether the page has been refreshed or not
    const pageRefreshed = sessionStorage.getItem('pr');
    if (pageRefreshed != null && pageRefreshed == 'true') {
      console.log(pageRefreshed)
    } else {
      sessionStorage.setItem('pr', 'true');
    }

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'route';
    console.log('returnUrl', this.returnUrl)

    // Get the user's locally stored token object
    const tokenObj = this.storageService.getLocalTokenInfo();

    // On logout, tokenObj is destroyed only if the user is online and the user is online AND the user is a manager

    if (tokenObj) {

      const user = JSON.parse(tokenObj);

      if (user) {
        // This returns true if the token is not *expired*
        const valid = this.authenticationService.isAuthenticated();
        // If the token is not yet expired... (validity set to 7 days on 10/4/2019)
        if (valid) {
          // Get the value of lg. This is set to 1 when the user logs out manually
          const logout = this.storageService.getLocalStorage('lg');

/*

 If the user ticked the Remember me box AND they did not log out manually,
 then redirect them to the returnUrl - even if they are currently online

*/


          if (isPlatformBrowser(this.platformId)) {
            if (!navigator.onLine) {
              // If the user is offline then redirect them to the returnUrl
              this.router.navigate([this.returnUrl]);
            }
            // If online, do nothing - make them log in again and generate a fresh 7 day valid token
            else {}
          }
        }
        // If the token is expired, do nothing - even if the user is offline, do not let them enter the app
        else {}
      }
    }
  }

  ngAfterViewInit() {
    this.renderer.selectRootElement('#username').focus();
  }

  switchLocale() : void {
    // Don't swtich locale if current locale is already es (Spanish)
    if (this.locale !== 'es') {
      const d = new Date();
      const offset = d.getTimezoneOffset();
      // 360 is the timezone offset in Guatemala (UTC - 6h)
      if (offset == 360) {
        window.open(`${environment.apiBaseUrl}/es`, '_self');
      }
    }
  }


  forgetPassword() {
    this.router.navigate(['forgetpassword']);
  }

  doLogin() {    
    localStorage.setItem('consentGiven', 'true');
    this.loginSpinner = true;
    const username = this.model.username;
    const password = this.model.password;
    const lrole = 12;
    const fp = this.storageService.getLocalStorage('fp');

    if (isPlatformBrowser(this.platformId)) {
    if (navigator.onLine === false) {

      const user = JSON.parse(this.storageService.getLocalTokenInfo());

      if (user && user.username === this.model.username) {
        this.isValid = true;
        this.router.navigate([this.returnUrl]);
      } else {
        this.isValid = false;
      }
    } else {

      this.loginService.login(username.toLocaleLowerCase(), password, lrole, fp).subscribe(data => {
        
        if (data.msg === 'success') {
          this.loginErrorMsg = '';
          this.loggedInUser = data.user;
          data.user.rememberme = this.model.rememberme;
          data.user.rcode = data.user.role['code'];
          data.user.rolename = data.user.role['name'];
          this.isValid = true;
          this.authenticationService.setTokenInfo(data.user);

          this.loginSpinner = false;
          if (data.user.resetUsername === 'Y') {
            this.router.navigate(['resetusernamepassword']);
          } else if (this.loggedInUser.is_pwd_reset === 0) {
            this.router.navigate(['resetpassword']);
          } else {
            this.router.navigate([this.returnUrl]);
          }
        } else {
          if (data.code === 'login_invalid_credentials') {
            this.commonService.showToast('error', 'login_invalid_credentials', {});
        }
      }

      }, (err) => {
        console.log(err);
      });
    }
  }

  }

  updateLocale(): void {
    if (this.selectedLocale == 'es') {
      window.open(`${environment.apiBaseUrl}/es`, '_self');
    } else if (this.selectedLocale == 'fr') {
      window.open(`${environment.apiBaseUrl}/fr`, '_self');
    } else if (this.selectedLocale == 'ta') {
      window.open(`${environment.apiBaseUrl}/ta`, '_self');
    } else if (this.selectedLocale == 'mg') {
      window.open(`${environment.apiBaseUrl}/mg`, '_self');
    } else if (this.selectedLocale == 'zh') {
      window.open(`${environment.apiBaseUrl}/zh`, '_self');
    } else if (this.selectedLocale == 'hi') {
      window.open(`${environment.apiBaseUrl}/hi`, '_self');
    } else if (this.selectedLocale == 'id') {
      window.open(`${environment.apiBaseUrl}/id`, '_self');
    } else {
      window.open(`${environment.apiBaseUrl}/en`, '_self');
    }
  }

  onFocusCountry(ele) {
    ele.target.nextElementSibling.classList.add('login-blue');
  }
  onBlurCountry(ele){
    ele.target.nextElementSibling.classList.remove('login-blue');
  }

  setLanguage(lang){
    localStorage.setItem('consentGiven', 'true');
    if(lang == this.flagClass){
      return;
    } else {
      this.selectedLocale = lang;
      this.updateLocale()
    }
  }
  dismiss(){
    document.getElementById('cookieBanner').style.display='none';
  };
  setCookies(){
    localStorage.setItem('consentGiven', 'true');
  }
}
