import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// Services
import { CommonService } from '../../services/common.service';
import { ProjectService } from '../../services/project.service';
import { UserService } from '../../services/user.service';
import { CampaignService } from '../../services/campaign.service';
import { TranslateService } from '@ngx-translate/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FarmerService } from '../../services/farmer.service';
import { SuppliersService } from '../../services/suppliers.service';
var CampaignManagementComponent = /** @class */ (function () {
    function CampaignManagementComponent(route, commonService, campaignService, userService, projectService, router, translate, spinner, farmerService, modalService, supplierService) {
        var _this = this;
        this.route = route;
        this.commonService = commonService;
        this.campaignService = campaignService;
        this.userService = userService;
        this.projectService = projectService;
        this.router = router;
        this.translate = translate;
        this.spinner = spinner;
        this.farmerService = farmerService;
        this.modalService = modalService;
        this.supplierService = supplierService;
        this.campaigns = [];
        this.currentUser = this.userService.getCurrentUser();
        this.typeOfAdministration = '';
        this.loadedCampaigns = false;
        this.closingCampaign = false;
        this.farmers = [];
        this.selectedFarmers = [];
        this.selectedFarmersCopy = [];
        this.loadingFarmers = false;
        this.isAscending = true;
        this.isAscendingId = true;
        this.isAscendingName = true;
        this.isAscendingTel = true;
        this.isAscendingDate = true;
        this.isAscendingFarmer = true;
        this.isAscendingIdFarmer = true;
        this.isAscendingNameFarmer = true;
        this.isAscendingTelFarmer = true;
        this.isAscendingDateFarmer = true;
        this.isAscendingAgeFarmer = true;
        this.isAscendingSexFarmer = true;
        this.isAscendingVillageFarmer = true;
        this.isAscendingVCFarmer = true;
        this.sortLabel = '';
        this.page = 1;
        this.itemsPerPage = 10;
        this.farmersListPage = 1;
        this.route.queryParamMap.subscribe(function (queryParams) {
            var qpObject = tslib_1.__assign({}, queryParams.keys, queryParams);
            _this.fromNavId = qpObject['params']['from'];
            _this.translate.get('typeAdminTextArr').subscribe(function (val) {
                _this.typeOfAdministrationArr = val;
                if (_this.fromNavId === '2') {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[0];
                }
                else if (_this.fromNavId === '3') {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[1];
                }
            }, function (err) { return console.log('err', err); });
        });
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
        this.translate.get('select_deselect_object').subscribe(function (result) {
            _this.selectDeselectLabels = result;
        });
    }
    CampaignManagementComponent.prototype.ngOnInit = function () {
        this.getCampaigns();
    };
    CampaignManagementComponent.prototype.getCampaigns = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, campaigns_1, projects, campaignStatuses, _loop_1, i;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.campaignService.getCampaigns().toPromise(),
                            this.projectService.GetProjectsBasicInfo().toPromise(),
                            this.campaignService.getCampaignStatuses().toPromise()
                        ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['code'] === 'success' && request[1]['msg'] === 'success' && request[2]['msg'] === 'success') {
                            campaigns_1 = request[0]['message'];
                            projects = request[1]['data'];
                            campaignStatuses = request[2]['data'];
                            _loop_1 = function (i) {
                                campaigns_1[i].project = projects.find(function (item) { return item.id === campaigns_1[i].project_id; });
                                campaigns_1[i].projectname = campaigns_1[i].project.name;
                                campaigns_1[i].ecosystem = campaigns_1[i].project.Supplier.name + " - " + campaigns_1[i].project.Product.name;
                                campaigns_1[i].producttype = campaigns_1[i].project.Product.product_type.name;
                                campaigns_1[i].status = campaigns_1[i].status && campaigns_1[i].status !== '0' ? campaignStatuses.find(function (item) { return item.id === parseInt(campaigns_1[i].status); })['name'] : '';
                            };
                            for (i = 0; i < campaigns_1.length; i++) {
                                _loop_1(i);
                            }
                            this.campaigns = campaigns_1;
                            this.loadedCampaigns = true;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CampaignManagementComponent.prototype.goToAddCampaign = function () {
        if (navigator.onLine) {
            var url = 'campaign-setup';
            this.router.navigate([url], { queryParams: { from: this.fromNavId } });
        }
    };
    CampaignManagementComponent.prototype.goToEditCampaign = function (campaignId) {
        if (navigator.onLine) {
            var url = "campaign-setup/" + campaignId;
            this.router.navigate([url], { queryParams: { from: this.fromNavId } });
        }
    };
    CampaignManagementComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['name', 'description', 'campaignstatus', 'producttype'];
        var finalObj = {};
        if (this.campaigns === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    CampaignManagementComponent.prototype.closeCampaign = function (campaign, template) {
        this.confirmUpdateModal = true;
        this.campaignClosingRemarks = "";
        this.closeThisCampaign = campaign;
        this.closeCampaignModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    CampaignManagementComponent.prototype.cancelCloseCampaign = function (val) {
        if (val.length > 0) {
            this.confirmUpdateModal = false;
        }
        else {
            this.declineCloseCampaign();
        }
    };
    CampaignManagementComponent.prototype.declineCloseCampaign = function () {
        this.closeThisCampaign = undefined;
        if (this.closeCampaignModal) {
            this.closeCampaignModal.hide();
        }
    };
    CampaignManagementComponent.prototype.confirmCloseCampaignNo = function () {
        this.confirmUpdateModal = true;
    };
    CampaignManagementComponent.prototype.confirmCloseCampaign = function () {
        var _this = this;
        this.closeCampaignSubmitted = true;
        if (!this.campaignClosingRemarks || this.campaignClosingRemarks.length === 0) {
            return;
        }
        this.closingCampaign = true;
        this.campaignService.closeCampaign(this.closeThisCampaign.id, { closingRemarks: this.campaignClosingRemarks })
            .subscribe(function (result) {
            if (result['code'] === 'success') {
                _this.closingCampaign = false;
                _this.declineCloseCampaign();
                _this.getCampaigns();
            }
        });
    };
    CampaignManagementComponent.prototype.manageFarmers = function (campaign, template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, _i, _a, item;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.searchTextFarmers = "";
                        this.confirmFarmerModal = true;
                        if (!!this.loadingFarmers) return [3 /*break*/, 2];
                        this.spinner.show();
                        this.loadingFarmers = true;
                        this.managingFarmersForCampaign = campaign;
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getFarmersByProjectBasicData(campaign.project_id).toPromise(),
                                this.campaignService.getFarmerIdsMappedToCampaign(campaign.id).toPromise(),
                            ])];
                    case 1:
                        requests = _b.sent();
                        this.loadingFarmers = false;
                        this.spinner.hide();
                        if (requests[0]['msg'] === 'success') {
                            this.farmers = requests[0].data.filter(function (item) { return !item.is_discontinued; });
                            for (_i = 0, _a = this.farmers; _i < _a.length; _i++) {
                                item = _a[_i];
                                item.village_name = item.region_village.village_name;
                            }
                            this.sortFarmers();
                        }
                        if (requests[1]['code'] === 'success') {
                            this.selectedFarmers = requests[1].message;
                            this.selectedFarmersCopy = JSON.parse(JSON.stringify(this.selectedFarmers));
                        }
                        if (this.farmers.length === 0) {
                            this.commonService.showToast('info', 'no_farmers_regd', {});
                        }
                        else {
                            this.farmersListPage = 1;
                            this.tooltipText = undefined;
                            this.manageFarmersModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    CampaignManagementComponent.prototype.getGridFiltersFarmers = function () {
        var _this = this;
        var gridColums = ['farmer_code', 'name', 'official_id', 'age', 'village_name', 'village_collectors', 'cr_date'];
        var finalObj = {};
        if (this.farmers === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchTextFarmers;
            });
        }
        return finalObj;
    };
    // Toggle a particular farmer's selection status
    CampaignManagementComponent.prototype.toggleThisFarmerSelect = function (id) {
        if (this.selectedFarmers.indexOf(id) > -1) {
            this.selectedFarmers = this.selectedFarmers.filter(function (item) { return item !== id; });
        }
        else {
            this.selectedFarmers.push(id);
        }
        this.setTooltipText();
    };
    CampaignManagementComponent.prototype.setTooltipText = function () {
        if (this.farmers && this.selectedFarmers) {
            if (this.selectedFarmers.length === this.farmers.length) {
                // Deselect farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.deselect + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.deselect + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
            else {
                // Select farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.select + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.select + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
        }
    };
    CampaignManagementComponent.prototype.cancelManageFarmers = function () {
        var isEqual = false;
        for (var _i = 0, _a = this.selectedFarmers; _i < _a.length; _i++) {
            var val = _a[_i];
            if (this.selectedFarmersCopy.indexOf(val) < 0) {
                isEqual = true;
            }
        }
        if (isEqual) {
            this.confirmFarmerModal = false;
        }
        else {
            this.declineManageFarmers();
        }
    };
    CampaignManagementComponent.prototype.declineManageFarmers = function () {
        this.selectedFarmers = [];
        this.farmers = [];
        this.managingFarmersForCampaign = undefined;
        if (this.manageFarmersModal) {
            this.manageFarmersModal.hide();
        }
    };
    CampaignManagementComponent.prototype.confirmCloseFarmerNo = function () {
        this.confirmFarmerModal = true;
    };
    CampaignManagementComponent.prototype.confirmManageFarmers = function () {
        var _this = this;
        this.campaignService.mapFarmersToCampaign(this.managingFarmersForCampaign.id, this.selectedFarmers)
            .subscribe(function (result) {
            console.log('result', result);
            if (result['code'] === 'success') {
                _this.commonService.showToast('success', 'changes_saved', {});
                _this.declineManageFarmers();
            }
        });
    };
    CampaignManagementComponent.prototype.closeFarmerCancel = function () {
        if (this.cancelFarmersModal) {
            this.cancelFarmersModal.hide();
        }
    };
    CampaignManagementComponent.prototype.confirmCancel = function () {
        if (this.cancelFarmersModal) {
            this.cancelFarmersModal.hide();
        }
        if (this.manageFarmersModal) {
            this.manageFarmersModal.hide();
        }
    };
    // Select / deselect all farmers in the array
    CampaignManagementComponent.prototype.toggleAllFarmers = function () {
        // If all farmers are already selected, then deselect all farmers
        if (this.selectedFarmers.length === this.farmers.length) {
            this.selectedFarmers = [];
        }
        // If all farmers are NOT already selected, select all the farmers
        else {
            this.selectedFarmers = [];
            for (var _i = 0, _a = this.farmers; _i < _a.length; _i++) {
                var val = _a[_i];
                this.selectedFarmers.push(val.id);
            }
        }
        this.setTooltipText();
    };
    CampaignManagementComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    CampaignManagementComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        if (sortBy === 'projectname') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.campaigns.sort(function (a, b) {
                if (a.projectname.toLowerCase() < b.projectname.toLowerCase()) {
                    return 1;
                }
                if (a.projectname.toLowerCase() > b.projectname.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'ecosystem') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.campaigns.sort(function (a, b) {
                if (a.ecosystem.toLowerCase() < b.ecosystem.toLowerCase()) {
                    return 1;
                }
                if (a.ecosystem.toLowerCase() > b.ecosystem.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'campaignname') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.campaigns.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'campaigndescription') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.campaigns.sort(function (a, b) {
                if (a.description.toLowerCase() < b.description.toLowerCase()) {
                    return 1;
                }
                if (a.description.toLowerCase() > b.description.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'status') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.campaigns.sort(function (a, b) {
                if (a.status.toLowerCase() < b.status.toLowerCase()) {
                    return 1;
                }
                if (a.status.toLowerCase() > b.status.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'producttype') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.campaigns.sort(function (a, b) {
                if (a.producttype.toLowerCase() < b.producttype.toLowerCase()) {
                    return 1;
                }
                if (a.producttype.toLowerCase() > b.producttype.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'startdate') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.campaigns.sort(function (a, b) {
                if (a.start_date.toLowerCase() < b.start_date.toLowerCase()) {
                    return 1;
                }
                if (a.start_date.toLowerCase() > b.start_date.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'enddate') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.campaigns.sort(function (a, b) {
                if (a.end_date && b.end_date) {
                    if (a.end_date.toLowerCase() < b.end_date.toLowerCase()) {
                        return 1;
                    }
                    if (a.end_date.toLowerCase() > b.end_date.toLowerCase()) {
                        return -1;
                    }
                }
                return 0;
            });
        }
    };
    CampaignManagementComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        if (sortBy === 'projectname') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.campaigns.sort(function (a, b) {
                if (a.projectname.toLowerCase() > b.projectname.toLowerCase()) {
                    return 1;
                }
                if (a.projectname.toLowerCase() < b.projectname.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'ecosystem') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.campaigns.sort(function (a, b) {
                if (a.ecosystem.toLowerCase() > b.ecosystem.toLowerCase()) {
                    return 1;
                }
                if (a.ecosystem.toLowerCase() < b.ecosystem.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'campaignname') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.campaigns.sort(function (a, b) {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'campaigndescription') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.campaigns.sort(function (a, b) {
                if (a.description.toLowerCase() > b.description.toLowerCase()) {
                    return 1;
                }
                if (a.description.toLowerCase() < b.description.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'status') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.campaigns.sort(function (a, b) {
                if (a.status.toLowerCase() > b.status.toLowerCase()) {
                    return 1;
                }
                if (a.status.toLowerCase() < b.status.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'producttype') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.campaigns.sort(function (a, b) {
                if (a.producttype.toLowerCase() > b.producttype.toLowerCase()) {
                    return 1;
                }
                if (a.producttype.toLowerCase() < b.producttype.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'startdate') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.campaigns.sort(function (a, b) {
                if (a.start_date.toLowerCase() > b.start_date.toLowerCase()) {
                    return 1;
                }
                if (a.start_date.toLowerCase() < b.start_date.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'enddate') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.campaigns.sort(function (a, b) {
                if (a.end_date && b.end_date) {
                    if (a.end_date.toLowerCase() > b.end_date.toLowerCase()) {
                        return 1;
                    }
                    if (a.end_date.toLowerCase() < b.end_date.toLowerCase()) {
                        return -1;
                    }
                }
                return 0;
            });
        }
    };
    CampaignManagementComponent.prototype.onKeydownHandler = function (event) {
        if (event.keyCode === 27) {
            if (this.confirmFarmerModal) {
                this.cancelManageFarmers();
            }
            else {
                this.confirmCloseFarmerNo();
            }
            if (this.confirmUpdateModal) {
                this.cancelCloseCampaign(this.campaignClosingRemarks);
            }
            else {
                this.confirmCloseCampaignNo();
            }
        }
    };
    CampaignManagementComponent.prototype.exportToExcel = function () {
        var _this = this;
        this.supplierService.exportData('CAMPAIGNMGMT_MDM').subscribe(function (result) {
            console.log('result', result);
            var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
            var blob = new Blob([result], { type: contentType });
            var linkdownload = document.createElement('a');
            linkdownload.href = URL.createObjectURL(blob);
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            var today1 = dd + "-" + mm + "-" + yyyy;
            _this.translate.get('campaign_management').subscribe(function (val) {
                linkdownload.download = "P2F-Roots_" + val + "_ExcelExport_" + today1 + ".xlsx";
                linkdownload.style.display = 'none';
                document.body.appendChild(linkdownload);
                linkdownload.click();
            }, function (err) { return console.log('err', err); });
        }, function (err) { return console.log('err', err); });
    };
    CampaignManagementComponent.prototype.farmerSortBy = function (sortOn) {
        this.isAscendingFarmer ? this.ascendicFarmer(sortOn) : this.descendicFarmer(sortOn);
    };
    CampaignManagementComponent.prototype.ascendicFarmer = function (sortBy) {
        this.isAscendingFarmer = false;
        this.isAscendingIdFarmer = sortBy !== 'id';
        this.isAscendingNameFarmer = sortBy !== 'name';
        this.isAscendingTelFarmer = sortBy !== 'official_id';
        this.isAscendingDateFarmer = sortBy !== 'cr_date';
        this.isAscendingAgeFarmer = sortBy !== 'age';
        this.isAscendingSexFarmer = sortBy !== 'sex';
        // Sort of new fields added
        this.isAscendingVillageFarmer = sortBy !== 'village_name';
        this.isAscendingVCFarmer = sortBy === 'village_collectors';
        // Sort of new fields added
        if (sortBy === 'name') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                    return 1;
                }
                if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'village_name') {
            this.farmers.sort(function (a, b) {
                if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
                    return 1;
                }
                if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    CampaignManagementComponent.prototype.descendicFarmer = function (sortBy) {
        this.isAscendingFarmer = true;
        this.isAscendingIdFarmer = sortBy === 'id';
        this.isAscendingNameFarmer = sortBy === 'name';
        this.isAscendingTelFarmer = sortBy === 'official_id';
        this.isAscendingDateFarmer = sortBy === 'cr_date';
        this.isAscendingAgeFarmer = sortBy === 'age';
        this.isAscendingSexFarmer = sortBy === 'sex';
        // Sort of new fields added
        this.isAscendingVillageFarmer = sortBy === 'village_name';
        this.isAscendingVCFarmer = sortBy === 'village_collectors';
        // Sort of new fields added
        if (sortBy === 'name') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                    return 1;
                }
                if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'village_name') {
            this.farmers.sort(function (a, b) {
                if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
                    return 1;
                }
                if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    CampaignManagementComponent.prototype.sortFarmers = function () {
        this.farmers.sort(function (a, b) {
            if (a.cr_date < b.cr_date) {
                return 1;
            }
            if (a.cr_date > b.cr_date) {
                return -1;
            }
            return 0;
        });
    };
    return CampaignManagementComponent;
}());
export { CampaignManagementComponent };
