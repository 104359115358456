/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./signature.component";
import * as i5 from "ngx-bootstrap/modal/bs-modal.service";
import * as i6 from "../../services/data-consent.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/router";
var styles_SignatureComponent = [i0.styles];
var RenderType_SignatureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignatureComponent, data: {} });
export { RenderType_SignatureComponent as RenderType_SignatureComponent };
function View_SignatureComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "font-small text-warning font-weight-normal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Consent template is not available for this project"]))], null, null); }
function View_SignatureComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"], ["style", "display: flow-root;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePrivacyPolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "utilicon utilicon-times-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["style", "overflow-x: auto"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePrivacyPolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.consentTemplate; _ck(_v, 4, 0, currVal_0); }); }
export function View_SignatureComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { canvas: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "row"]], [[2, "hide", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "col-sm-12 text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "span", [["class", "form-check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "input", [["class", "form-check-input"], ["container", "body"], ["placement", "right"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.agree = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.getSignValues() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(7, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "label", [["class", "form-check-label mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["I agree to the"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "label", [["class", "cursorPointer pl-1 mb-0"], ["style", "color: #007bff;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewPrivacyPolicy(i1.ɵnov(_v, 21)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Privacy Policy"])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "col-sm-12 text-left posRel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, [[1, 0], ["canvas", 1]], null, 0, "canvas", [["id", "canvas"], ["name", "signature"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "pos-to-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearCanvas() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "utilicon utilicon-times-circle-light"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignatureComponent_1)), i1.ɵdid(20, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["privacyPolicy", 2]], null, 0, null, View_SignatureComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.agree; _ck(_v, 7, 0, currVal_8); var currVal_9 = !_co.consentTemplate; _ck(_v, 20, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.consentTemplate; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_SignatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signature", [], null, null, null, View_SignatureComponent_0, RenderType_SignatureComponent)), i1.ɵdid(1, 4767744, null, 0, i4.SignatureComponent, [i5.BsModalService, i6.DataConsentService, i7.DomSanitizer, i8.ActivatedRoute], null, null)], null, null); }
var SignatureComponentNgFactory = i1.ɵccf("app-signature", i4.SignatureComponent, View_SignatureComponent_Host_0, { width: "width", height: "height", consentTemplate: "consentTemplate", projectId: "projectId", changeEvent: "changeEvent" }, { newItemEvent: "newItemEvent" }, []);
export { SignatureComponentNgFactory as SignatureComponentNgFactory };
