import { Component, OnInit, Inject } from '@angular/core';

import { DOCUMENT } from "@angular/platform-browser";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute} from '@angular/router';
import { CommonService } from '../../services/common.service';
import { SuppliersService } from '../../services/suppliers.service'
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-mdm',
    templateUrl: './mdm.component.html',
    styles: [
        `.scroll-to-top {
      position: fixed;
      bottom: 15px;
      right: 15px;
      opacity: 0;
      transition: all .2s ease-in-out;
     }
     .show-scrollTop {
      opacity: 1;
      transition: all .2s ease-in-out;
     }
     .box_mh{
       min-height:100px;
     }`
    ]
})
export class MdmComponent implements OnInit {
    windowScrolled: boolean;
    public listOfMenus: any;
    public mdmData:any;
    public nav;
    public typeOfAdministrationArr: any[];    
    typeOfAdministration = '';
    constructor(
        private readonly route: ActivatedRoute,
        private readonly commonService: CommonService,
        private readonly router: Router,
        private readonly toastr: ToastrService,
        private readonly translate: TranslateService,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly suppService: SuppliersService,
    ) { }

    ngOnInit() {
        this.scrollToTop();
        this.translate.get('typeAdminTextArr').subscribe((val) => {
            this.typeOfAdministrationArr = val;
          }, err => console.log('err', err));

        this.route.params.subscribe(params => {
            if (params['navId'] == 2) {
                this.typeOfAdministration = this.typeOfAdministrationArr[0];
            }
            else if (params['navId'] == 3) {
                this.typeOfAdministration =  this.typeOfAdministrationArr[1];
            }
            else if (params['navId'] == 6) {
                this.typeOfAdministration =  this.typeOfAdministrationArr[2];
            }
            this.getAllData2(params['navId']);
        });
    }

    getAllData(){
        this.suppService.getMDMMaster().subscribe(response=> {
            if(response.status === 'success'){
                this.mdmData= response.data;
            }                    
          });
    }

    getAllData2(navId){
        this.suppService.getMDMMaster2(navId).subscribe(response=> {
            if(response.status === 'success'){
                this.nav = response.data.nav;
                this.mdmData= response.data.items;
            }                      
          });
    }

        scrollToTop() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }

    navaigationMenu(valType){
        sessionStorage.setItem("menuType", valType);
        if (valType == 'PROJECTMGMT_MDM') {
            this.router.navigate(['/projectmanagement'], { queryParams: { from: this.nav.id } });
        }
        else if (valType == 'ECOSYSTEM_SETTINGS_MDM') {
            this.router.navigate(['/ecosystem-settings'], { queryParams: { from: this.nav.id } });
        }
        else if (valType == 'API_MANAGEMENT_MDM') {
            this.router.navigate(['/api-management'], { queryParams: { from: this.nav.id } });
        }
        else if (valType == 'P2F_TEMPLATE_MDM') {
            this.router.navigate(['questionnaire/edit/0/0'], { queryParams: { from: this.nav.id, p2f: '1' } });
        }
        else if (valType == 'CAMPAIGNMGMT_MDM') {
            this.router.navigate(['/campaignmanagement'], { queryParams: { from: this.nav.id} });
        }
        else if (valType == 'TRAINING_AND_CERTIFICATION_MDM') {
            this.router.navigate(['/projecttrainingmapping'], { queryParams: { from: this.nav.id} });
        }
        else if (valType == 'PROJECT_GROUP_MAPPING_MDM') {
            this.router.navigate(['/projectgroupmapping'], { queryParams: { from: this.nav.id} });
        }        
        else {
            const url="/mdminfo";
            this.router.navigate([url], { queryParams: { origin: valType, from: this.nav.id } });
        }
    }
}
