import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// Services
import { CommonService } from '../../services/common.service';
import { ProjectService } from '../../services/project.service';
import { UserService } from '../../services/user.service';
import { CampaignService } from '../../services/campaign.service';
import { TranslateService } from '@ngx-translate/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FarmerService } from '../../services/farmer.service';
var CampaignProfilingComponent = /** @class */ (function () {
    function CampaignProfilingComponent(route, commonService, campaignService, userService, projectService, router, translate, spinner, farmerService, modalService) {
        var _this = this;
        this.route = route;
        this.commonService = commonService;
        this.campaignService = campaignService;
        this.userService = userService;
        this.projectService = projectService;
        this.router = router;
        this.translate = translate;
        this.spinner = spinner;
        this.farmerService = farmerService;
        this.modalService = modalService;
        this.campaigns = [];
        this.currentUser = this.userService.getCurrentUser();
        this.typeOfAdministration = '';
        this.loadedCampaigns = false;
        this.closingCampaign = false;
        this.farmers = [];
        this.selectedFarmers = [];
        this.selectedFarmersCopy = [];
        this.loadingFarmers = false;
        this.isAscending = true;
        this.isAscendingId = true;
        this.isAscendingName = true;
        this.isAscendingTel = true;
        this.isAscendingDate = true;
        this.sortLabel = '';
        this.page = 1;
        this.itemsPerPage = 10;
        this.farmersListPage = 1;
        this.route.queryParamMap.subscribe(function (queryParams) {
            var qpObject = tslib_1.__assign({}, queryParams.keys, queryParams);
            _this.fromNavId = qpObject['params']['from'];
            _this.translate.get('typeAdminTextArr').subscribe(function (val) {
                _this.typeOfAdministrationArr = val;
                if (_this.fromNavId === 2) {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[0];
                }
                else if (_this.fromNavId === 3) {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[1];
                }
            }, function (err) { return console.log('err', err); });
        });
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
        this.translate.get('select_deselect_object').subscribe(function (result) {
            _this.selectDeselectLabels = result;
        });
    }
    CampaignProfilingComponent.prototype.ngOnInit = function () {
        this.projectId = +this.route.snapshot.params['id'];
        this.getCampaigns();
    };
    CampaignProfilingComponent.prototype.getCampaigns = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.campaignService.getCampaignsForProject(this.projectId).toPromise(),
                            this.projectService.getProjectBasicInfo(this.projectId).toPromise()
                        ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['code'] === 'success' && request[0]['message']) {
                            this.campaigns = Array.isArray(request[0]['message']) ? request[0]['message'] : [request[0]['message']];
                        }
                        if (navigator.onLine) {
                            console.log('online');
                        }
                        else {
                            this.campaigns = this.campaigns.filter(function (item) { return item.closed_date == null; });
                        }
                        if (request[1]['msg'] === 'success') {
                            this.project = request[1]['data'][0];
                        }
                        this.loadedCampaigns = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    CampaignProfilingComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['name', 'description'];
        var finalObj = {};
        if (this.campaigns === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    CampaignProfilingComponent.prototype.goToCampaignProfiling = function (campaignId) {
        this.router.navigate(["/campaign-profiling-farmers/" + this.projectId + "/" + campaignId]);
    };
    CampaignProfilingComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    return CampaignProfilingComponent;
}());
export { CampaignProfilingComponent };
