import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuppliersService } from '../../../services/suppliers.service';
import { ChartService } from '../../../services/chart.service';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
require('highcharts/modules/full-screen')(Highcharts);
var parentScope01;
var RunReportComponent = /** @class */ (function () {
    function RunReportComponent(route, projectService, toastrService, router, activeRoute, suppService, translate, changeDetectionRef, chartService, renderer, hcRService) {
        this.route = route;
        this.projectService = projectService;
        this.toastrService = toastrService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.suppService = suppService;
        this.translate = translate;
        this.changeDetectionRef = changeDetectionRef;
        this.chartService = chartService;
        this.renderer = renderer;
        this.hcRService = hcRService;
        this.preferredReportsType = 'profiling';
        this.setAsDefault = false;
        this.reportListType = [];
        this.customReportsListData = [];
        this.reportTypes = 'predefined';
        this.projects = [];
        this.projectId = 0;
        this.typeOfAdministration = '';
        this.dashboardAddObject = {
            "section": 0,
            "subsection": '',
            "reportID": []
        };
        this.subsectionFlag = true;
        this.reportsFlag = true;
        this.selectedReportsMultiSelect = [];
        this.selectedReportsMultiSelectCustom = [];
        this.dropdownSettings = {};
        this.listsSectionsSubSection = [];
        this.chartsOptions = [];
        this.exportBtns = false;
        this.reportAvailableFlag = false;
        this.sectionDropDown = [];
        this.subSectionDropdown = [];
        this.listOfReport = [];
        this.existingSelectedReports = [];
        this.checkChartsAvailable = [];
        this.profilingFlag = false;
        this.customFlag = false;
        this.localChartsList = [];
    }
    RunReportComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requestParams, requestObject;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                //debugger;
                parentScope01 = this;
                this.projectId = parseInt(this.route.snapshot.params['id']);
                // if (navigator.onLine) {
                //   this.project = this.route.snapshot.data.project.project.data;
                // }else{
                //   const requests = await Promise.all([
                //     this.projectService.GetProjectsDataDashboard().toPromise()
                //   ]);
                //   this.project = requests[0].data.find(x => x.id === this.projectId);
                // }
                this.hcRService.allCharts = [];
                this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
                    _this.project = response.data;
                    var projectObject = _this.project.filter(function (item) { return item.id == _this.projectId; })[0];
                    _this.project = projectObject;
                });
                requestParams = {
                    projectId: this.projectId,
                    type: "CHART"
                };
                this.setTranslatedLabelObjects();
                this.hcRService.getSectionSubsectionReportsSelectionListAPI(requestParams).subscribe(function (response) {
                    var _a;
                    //console.log("response ", response);
                    if (response.msg == 'success') {
                        var sectionSubsectionReports = response.data;
                        //console.log("sectionSubsectionReports ",sectionSubsectionReports);
                        if (sectionSubsectionReports.profiling_reports && Object.keys(sectionSubsectionReports.profiling_reports).length !== 0) {
                            for (var item in sectionSubsectionReports.profiling_reports) {
                                var newObject = {
                                    "section": '',
                                    "subsection": []
                                };
                                //console.log(" item ", item);
                                _this.sectionDropDown.push(item);
                                newObject.section = item;
                                var subSection = sectionSubsectionReports.profiling_reports[item];
                                //console.log(" subSection ", subSection);
                                for (var x in subSection) {
                                    //console.log(" subSection ", x)
                                    _this.subSectionDropdown.push(x);
                                    var reportsList = subSection[x];
                                    //this.listOfReport = reportsList.map(item => ({id:item.id, name:item.name}));
                                    //console.log("this.listOfReport ",this.listOfReport);
                                    var subObject = {
                                        "name": x,
                                        "reports": reportsList
                                    };
                                    newObject.subsection.push(subObject);
                                    //console.log(" newObject ", newObject);
                                }
                                //console.log(" newObject ", newObject);
                                _this.listsSectionsSubSection.push(newObject);
                                //console.log("this.listsSectionsSubSection ", this.listsSectionsSubSection);
                            }
                        }
                        if (sectionSubsectionReports.custom_reports && sectionSubsectionReports.custom_reports.length > 0) {
                            var customReports = sectionSubsectionReports.custom_reports;
                            (_a = _this.customReportsListData).push.apply(_a, customReports);
                        }
                    }
                });
                this.dropdownSettings = {
                    singleSelection: false,
                    idField: 'id',
                    textField: 'name',
                    selectAllText: this.selectAllText,
                    unSelectAllText: this.unSelectAllText,
                    searchPlaceholderText: this.searchText1,
                    itemsShowLimit: 3,
                    allowSearchFilter: true
                };
                Highcharts.getSVG = function (charts) {
                    var svgArr = [], top = 0, width = 0, endWidth = 0;
                    Highcharts.each(charts, function (chart) {
                        var svg = chart.getSVG(), 
                        // Get width/height of SVG for export
                        svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/)[1], svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/)[1];
                        svg = svg.replace('<svg', '<g transform="translate(' + width + ', ' + top + ')" ');
                        svg = svg.replace('</svg>', '</g>');
                        //console.log(" svgWidth ", svgWidth);
                        //console.log(" width before ", width);
                        width += svgWidth;
                        //console.log(" width After ", width);
                        endWidth = Math.max(endWidth, width);
                        //console.log("endWidth ", endWidth);
                        if (width === 2 * svgWidth) {
                            width = 0;
                            top += svgHeight;
                        }
                        //console.log("top ", top);
                        svgArr.push(svg);
                    });
                    top += 400;
                    return '<svg height="' + top + '" width="' + endWidth +
                        '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
                        svgArr.join('') + '</svg>';
                };
                Highcharts.exportCharts = function (charts, options) {
                    // Merge the options
                    options = Highcharts.merge(Highcharts.getOptions().exporting, options);
                    // Post to export server
                    Highcharts.post(options.url, {
                        filename: options.filename || 'chart',
                        type: options.type,
                        width: options.width,
                        svg: Highcharts.getSVG(charts)
                    });
                };
                Highcharts.setOptions({
                    lang: {
                        noData: 'No data to display',
                    }
                });
                Highcharts.setOptions({
                    lang: {
                        drillUpText: '<<'
                    }
                });
                if (this.projectId > 0) {
                    requestObject = {
                        "reportID": [50],
                        "projectId": this.projectId
                    };
                    this.hcRService.getReports(requestObject).subscribe(function (response) {
                        //console.log("response ", response);
                        if (response.msg == 'success') {
                            //console.log(" response ", response);
                            _this.countOfFarmers = response.data[0].series[0];
                        }
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    //added by Kiran for translation
    RunReportComponent.prototype.setTranslatedLabelObjects = function () {
        var _this = this;
        this.translate.get('no_new_reports_selected').subscribe(function (result) {
            _this.noNewReportsSelected = result;
        });
        this.translate.get('selectPlaceholder').subscribe(function (result) {
            _this.selectPlaceholder = result;
        });
        this.translate.get('selectAllText').subscribe(function (result) {
            _this.selectAllText = result;
        });
        this.translate.get('unSelectAllText').subscribe(function (result) {
            _this.unSelectAllText = result;
        });
        this.translate.get('searchText').subscribe(function (result) {
            _this.searchText1 = result;
        });
    };
    RunReportComponent.prototype.changeChartType = function (currentItemScope) {
        //debugger;
        //parentScope01.changeChartType
        //console.log("currentItemScope ", currentItemScope);
        //console.log("currentItemScope Before ", currentItemScope.userOptions.chart.type);
        if (currentItemScope.userOptions.chart.type == 'column') {
            currentItemScope.update({
                chart: {
                    type: 'pie'
                }
            });
        }
        else if (currentItemScope.userOptions.chart.type == 'pie') {
            currentItemScope.update({
                chart: {
                    type: 'column'
                }
            });
        }
    };
    RunReportComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    RunReportComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    RunReportComponent.prototype.goToDashboards = function () {
        var url = "charts/dashboards/" + this.projectId;
        this.router.navigate([url]);
    };
    RunReportComponent.prototype.selectSection = function (ev, selectedValue) {
        var _this = this;
        this.reportsListData = [];
        this.selectedReportsMultiSelect = [];
        this.dashboardAddObject.subsection = '';
        //console.log("selectSection ",ev.target.value);
        //console.log("dashboardAddObject.sectionId ", this.dashboardAddObject.sectionId);
        var section = this.listsSectionsSubSection.filter(function (item) { return item.section == _this.dashboardAddObject.section; })[0];
        //console.log("section ", section);
        if (section != undefined || section != null) {
            this.newSubSection = section.subsection;
            for (var i = 0; i < this.newSubSection.length; i++) {
                var reports = this.newSubSection[i].reports.filter(function (item) { return item.section == _this.dashboardAddObject.section; });
                //console.log("reports section select ", reports);
                this.reportsListData = this.reportsListData.concat(reports);
                //console.log(" reportsItems ", this.reportsListData);
            }
        }
        else {
            this.newSubSection = [];
        }
        //console.log(" subsection ", section.subsection);
        this.subsectionFlag = false;
    };
    RunReportComponent.prototype.selectSubSection = function (ev) {
        var _this = this;
        this.reportsListData = [];
        //this.selectedValues.subsection = ev.target.value;
        //console.log("dashboardAddObject.subSectionId ", this.dashboardAddObject.subSectionId);
        var section = this.listsSectionsSubSection.filter(function (item) { return item.section == _this.dashboardAddObject.section; })[0];
        //this.reportsListData
        var subsection;
        if (section != undefined || section != null) {
            subsection = section.subsection.filter(function (item) { return item.name == _this.dashboardAddObject.subsection; })[0];
        }
        //let subsection = section.subsection.filter(item =>  item.name == this.dashboardAddObject.subsection)[0];
        //console.log(" reports ", subsection);
        //this.reportsListData = subsection.reports;
        if (subsection && subsection.reports) {
            this.reportsListData = subsection.reports;
            this.selectedReportsMultiSelect = [];
        }
        else {
            this.newSubSection = section.subsection;
            //let reportsItems:any =[];
            for (var i = 0; i < this.newSubSection.length; i++) {
                var reports = this.newSubSection[i].reports.filter(function (item) { return item.section == _this.dashboardAddObject.section; });
                //console.log("reports section select ", reports);
                this.reportsListData = this.reportsListData.concat(reports);
                //console.log(" reportsItems ", reportsItems);
            }
            this.selectedReportsMultiSelect = [];
        }
        this.reportsFlag = false;
    };
    RunReportComponent.prototype.setAsDefaultDashboard = function () {
        //console.log(" setAsDefault ", this.setAsDefault);
    };
    RunReportComponent.prototype.chartType = function (selectedChartType) {
        //console.log(" selectedChartType ", selectedChartType);
    };
    RunReportComponent.prototype.genarateReports = function (currentType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var selectedReportIds, requestObject, selectedReports, result, chartsList, colList;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                requestObject = {};
                if (currentType == 'predefined') {
                    selectedReports = this.selectedReportsMultiSelect.map(function (item) { return item.id; });
                    //console.log("selectedReports ", selectedReports);
                }
                else {
                    selectedReports = this.selectedReportsMultiSelectCustom.map(function (item) { return item.id; });
                    //console.log("selectedReports ", selectedReports);     
                }
                if (this.existingSelectedReports.length > 0) {
                    result = selectedReports.filter(function (d) { return _this.existingSelectedReports.indexOf(d) < 0; });
                    //console.log(result);
                    selectedReportIds = result;
                    this.existingSelectedReports = this.existingSelectedReports.concat(result);
                }
                else {
                    this.existingSelectedReports = this.existingSelectedReports.concat(selectedReports);
                    selectedReportIds = selectedReports;
                }
                requestObject = {
                    //"reportID":selectedReportIds,
                    "reportID": selectedReportIds,
                    "projectId": this.projectId
                    //"projectId":25
                };
                chartsList = [];
                colList = [];
                if (selectedReportIds.length > 0) {
                    this.hcRService.getReports(requestObject).subscribe(function (response) {
                        //console.log("response ", response);
                        if (response.msg == 'success') {
                            _this.reportAvailableFlag = true;
                            var reportsData_1 = response.data;
                            if (reportsData_1 && reportsData_1.length > 0) {
                                var _loop_1 = function (i) {
                                    seriesArr = [];
                                    var contextMenuOptions = [];
                                    colList.push(reportsData_1[i].dim_col);
                                    var chartOptions = reportsData_1[i].conf[0].conf;
                                    if (typeof reportsData_1[i].conf[0].conf !== 'object') {
                                        chartOptions = JSON.parse(reportsData_1[i].conf[0].conf);
                                    }
                                    //added by Kiran to change chart title to report name
                                    if (chartOptions.hasOwnProperty('title')) {
                                        chartOptions.title.text = reportsData_1[i].name;
                                    }
                                    //console.log("chartOptions ", chartOptions);
                                    if (reportsData_1[i].is_drilldown != 1) {
                                        //console.log(" chartOptions.chart.zoomType ", chartOptions.chart.zoomType);
                                        //console.log("reportsData[i].series ",reportsData[i].series.length);
                                        if (reportsData_1[i].series && reportsData_1[i].series.length > 1) {
                                            //console.log("more than 1 conf ");
                                            if (chartOptions.chart.zoomType == 'xy' && reportsData_1[i].conf[0].report_type == 'dual-axes') {
                                                seriesArr = [];
                                                //console.log("reportsData[i].conf[0].report_type ", reportsData[i].conf[0].report_type);
                                                var category = [];
                                                for (var x = 0; x < reportsData_1[i].series.length; x++) {
                                                    category.push(reportsData_1[i].series[x].key);
                                                    for (var y = 0; y < chartOptions.series.length; y++) {
                                                        if (chartOptions.series[y].hasOwnProperty('yAxis')) {
                                                            //chartOptions.series[y].name='';
                                                            chartOptions.series[y].data.push(Number(reportsData_1[i].series[x].avg_expense));
                                                        }
                                                        else {
                                                            //chartOptions.series[y].name='';
                                                            chartOptions.series[y].data.push(reportsData_1[i].series[x].value);
                                                        }
                                                    }
                                                }
                                                if (Array.isArray(chartOptions.xAxis)) {
                                                    chartOptions.xAxis[0].categories = category;
                                                }
                                                if (chartOptions.hasOwnProperty('yAxis')) {
                                                    var newString = chartOptions.yAxis[1].title.text + ' (' + reportsData_1[i].series[0].currency + ')';
                                                    chartOptions.yAxis[1].title.text = newString;
                                                }
                                                //chartOptions.legend.y=0;
                                                //chartOptions.legend.x=60;
                                                //console.log("chartOptions dual axes ", JSON.stringify(chartOptions));
                                            }
                                            else {
                                                var hcObject = {};
                                                var categories = [];
                                                var objectKeys = Object.keys(reportsData_1[i].series[0]);
                                                for (var j = 0; j < reportsData_1[i].series.length; j++) {
                                                    elementItem = reportsData_1[i].series[j];
                                                    for (var k = 0; k < objectKeys.length; k++) {
                                                        if (objectKeys[k] == 'name') {
                                                            categories.push(reportsData_1[i].series[j][objectKeys[k]]);
                                                        }
                                                        else {
                                                            if (hcObject[objectKeys[k]]) {
                                                                hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                                                            }
                                                            else {
                                                                hcObject[objectKeys[k]] = new Array();
                                                                hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                                                            }
                                                        }
                                                    }
                                                }
                                                if (Object.keys(hcObject).length !== 0) {
                                                    for (var key in hcObject) {
                                                        var object_1 = {
                                                            name: '',
                                                            data: []
                                                        };
                                                        object_1.name = key;
                                                        object_1.data = hcObject[key];
                                                        seriesArr.push(object_1);
                                                    }
                                                }
                                                //console.log(" seriesArr time ", seriesArr);
                                                if (chartOptions.hasOwnProperty('xAxis')) {
                                                    chartOptions.xAxis.categories = categories;
                                                }
                                                if (chartOptions.hasOwnProperty('yAxis')) {
                                                    chartOptions.yAxis.min = 0;
                                                }
                                                //chartOptions.xAxis.categories = categories;
                                                //console.log("4 ", chartOptions);
                                                chartOptions.series = seriesArr;
                                                //console.log("chartOptions.series ",chartOptions.series);
                                            }
                                        }
                                        else {
                                            //console.log("reportsData[i].series ", reportsData[i].series);
                                            if (chartOptions.chart.zoomType == 'xy' && reportsData_1[i].conf[0].report_type == 'dual-axes') {
                                                seriesArr = [];
                                                //console.log("reportsData[i].conf[0].report_type ", reportsData[i].conf[0].report_type);
                                                var category = [];
                                                for (var x = 0; x < reportsData_1[i].series.length; x++) {
                                                    category.push(reportsData_1[i].series[x].key);
                                                    for (var y = 0; y < chartOptions.series.length; y++) {
                                                        if (chartOptions.series[y].hasOwnProperty('yAxis')) {
                                                            //chartOptions.series[y].name='';
                                                            chartOptions.series[y].data.push(Number(reportsData_1[i].series[x].avg_expense));
                                                        }
                                                        else {
                                                            //chartOptions.series[y].name='';
                                                            chartOptions.series[y].data.push(reportsData_1[i].series[x].value);
                                                        }
                                                    }
                                                }
                                                if (Array.isArray(chartOptions.xAxis)) {
                                                    chartOptions.xAxis[0].categories = category;
                                                }
                                                if (chartOptions.hasOwnProperty('yAxis')) {
                                                    if (reportsData_1[i].series.length > 0 && reportsData_1[i].series[0].hasOwnProperty('currency')) {
                                                        var newString = chartOptions.yAxis[1].title.text + ' (' + reportsData_1[i].series[0].currency + ')';
                                                        chartOptions.yAxis[1].title.text = newString;
                                                    }
                                                }
                                                //chartOptions.legend.y=0;
                                                //chartOptions.legend.x=60;
                                                //console.log("chartOptions dual axes ", JSON.stringify(chartOptions));
                                            }
                                            else if (chartOptions.chart.type != 'pyramid') {
                                                for (var j = 0; j < reportsData_1[i].series.length; j++) {
                                                    for (var prop in reportsData_1[i].series[j]) {
                                                        if (reportsData_1[i].series[0].hasOwnProperty(prop)) {
                                                            if (Number(reportsData_1[i].series[0][prop]) > 0) {
                                                                innerObj = {
                                                                    name: '',
                                                                    y: Number(''),
                                                                };
                                                                innerObj.name = prop;
                                                                innerObj.y = Number(reportsData_1[i].series[0][prop]);
                                                                seriesArr.push(innerObj);
                                                            }
                                                        }
                                                    }
                                                }
                                                // chartOptions.series = [
                                                //   {
                                                //     colorByPoint: true,
                                                //     data: seriesArr
                                                //   }
                                                // ];
                                                //console.log("3 ", chartOptions);
                                                if (chartOptions.hasOwnProperty('series')) {
                                                    //console.log(" seriesArr ", seriesArr);
                                                    if (chartOptions.series.length > 0) {
                                                        if (reportsData_1[i].conf[0].report_type == 'waterfall') {
                                                            if (chartOptions.hasOwnProperty('chart')) {
                                                                if (chartOptions.chart.hasOwnProperty('type')) {
                                                                    //console.log("waterfall")
                                                                    var totalObject = {
                                                                        name: 'Total',
                                                                        isIntermediateSum: true
                                                                    };
                                                                    seriesArr.push(totalObject);
                                                                    chartOptions.series[0].data = seriesArr;
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            chartOptions.series[0].data = seriesArr;
                                                        }
                                                    }
                                                }
                                                else {
                                                    if (reportsData_1[i].name === 'Accessibility to Basics') {
                                                        var categories = [];
                                                        seriesArr = [];
                                                        //console.log(reportsData[i]);
                                                        for (var j = 0; j < reportsData_1[i].series.length; j++) {
                                                            object = {
                                                                name: 'Farmers',
                                                                data: []
                                                            };
                                                            for (var prop in reportsData_1[i].series[j]) {
                                                                if (reportsData_1[i].series[0].hasOwnProperty(prop)) {
                                                                    categories.push(prop);
                                                                    // object.name = key;
                                                                    object.data.push(Number(reportsData_1[i].series[0][prop]));
                                                                }
                                                            }
                                                            seriesArr.push(object);
                                                        }
                                                        if (chartOptions.hasOwnProperty('xAxis')) {
                                                            chartOptions.xAxis.categories = categories;
                                                        }
                                                        if (chartOptions.hasOwnProperty('yAxis')) {
                                                            chartOptions.yAxis.min = 0;
                                                        }
                                                        chartOptions.series = seriesArr;
                                                    }
                                                    else {
                                                        chartOptions.series = [
                                                            {
                                                                colorByPoint: true,
                                                                data: seriesArr
                                                            }
                                                        ];
                                                    }
                                                }
                                            }
                                            else {
                                                for (var j = 0; j < reportsData_1[i].series.length; j++) {
                                                    for (var prop_1 in reportsData_1[i].series[j]) {
                                                        if (reportsData_1[i].series[0].hasOwnProperty(prop_1)) {
                                                            if (Number(reportsData_1[i].series[0][prop_1]) > 0) {
                                                                newArray = [];
                                                                newArray[0] = prop_1;
                                                                newArray[1] = Number(reportsData_1[i].series[0][prop_1]);
                                                                seriesArr.push(newArray);
                                                            }
                                                        }
                                                    }
                                                }
                                                //console.log("seriesArr pyramid", seriesArr);
                                                seriesArr.sort(function (a, b) {
                                                    return a[1] < b[1] ? 1 : -1;
                                                });
                                                //console.log("seriesArr pyramid", seriesArr);
                                                // chartOptions.series = [
                                                //   {
                                                //     colorByPoint: true,
                                                //     data: seriesArr
                                                //   }
                                                // ];
                                                //console.log("2 ", chartOptions);
                                                if (chartOptions.hasOwnProperty('series')) {
                                                    chartOptions.series[0].data = seriesArr;
                                                }
                                                else {
                                                    chartOptions.series = [
                                                        {
                                                            colorByPoint: true,
                                                            data: seriesArr
                                                        }
                                                    ];
                                                }
                                            }
                                            //console.log("seriesArr ", seriesArr);                  
                                        }
                                    }
                                    else if (reportsData_1[i].is_drilldown == 1) {
                                        //console.log("Drill down ", chartOptions);
                                        //console.log("reportsData[i] ",reportsData[i].drilldown);
                                        for (var j = 0; j < reportsData_1[i].series.length; j++) {
                                            for (var prop_2 in reportsData_1[i].series[j]) {
                                                if (reportsData_1[i].series[0].hasOwnProperty(prop_2)) {
                                                    if (Number(reportsData_1[i].series[0][prop_2]) > 0) {
                                                        var innerObj_1 = {
                                                            name: '',
                                                            y: Number(''),
                                                            drilldown: ''
                                                        };
                                                        //let itemVal = Number(reportsData[i].series[0][prop]).toFixed(1);
                                                        //console.log("Number(reportsData[i].series[0][prop]) ",itemVal);
                                                        innerObj_1.name = prop_2;
                                                        innerObj_1.y = Number(reportsData_1[i].series[0][prop_2]);
                                                        var drilldownSeriesData = reportsData_1[i].drilldown.series;
                                                        for (var x = 0; x < drilldownSeriesData.length; x++) {
                                                            if (drilldownSeriesData[x].name == prop_2 && drilldownSeriesData[x].id == prop_2) {
                                                                innerObj_1.drilldown = prop_2;
                                                            }
                                                        }
                                                        seriesArr.push(innerObj_1);
                                                        //console.log("seriesArr ", seriesArr);
                                                    }
                                                }
                                            }
                                        }
                                        //console.log("1 ", chartOptions);
                                        if (reportsData_1[i].conf[0].report_type != 'combination') {
                                            if (chartOptions.hasOwnProperty('series')) {
                                                chartOptions.series[0].data = seriesArr;
                                            }
                                            else {
                                                chartOptions.series = [
                                                    {
                                                        colorByPoint: true,
                                                        data: seriesArr
                                                    }
                                                ];
                                            }
                                            var drilldownSeriesData = reportsData_1[i].drilldown.series;
                                            for (var x = 0; x < drilldownSeriesData.length; x++) {
                                                //let drillData = drilldownSeriesData[x].data;
                                                var drillOptionsData = [];
                                                //console.log("drilldownSeriesData[x] ", drilldownSeriesData[x]);
                                                if (drilldownSeriesData[x].has_child == 1) {
                                                    drilldownSeriesData[x]['keys'] = ['name', 'y', 'drilldown'];
                                                }
                                                if (drilldownSeriesData[x].hasOwnProperty('drill_report_type')) {
                                                    if (drilldownSeriesData[x].drill_report_type != null && drilldownSeriesData[x].drill_report_type != '') {
                                                        drilldownSeriesData[x].type = drilldownSeriesData[x].drill_report_type;
                                                        //Object.assign(chartOptions.drilldown.series[x], {type: "value"})
                                                        //console.log("chartOptions.drilldown.series[x]. ", chartOptions.drilldown.series[x]);
                                                        //chartOptions.drilldown.series[x].type = drilldownSeriesData[x].drill_report_type;
                                                    }
                                                }
                                            }
                                            if (reportsData_1[i].reportId == 102) {
                                                if (drilldownSeriesData[0].hasOwnProperty('data')) {
                                                    for (var x = 0; x < drilldownSeriesData[0].data.length; x++) {
                                                        if (drilldownSeriesData[0].data[x][2] == 'Above 15 years') {
                                                            drilldownSeriesData[0].data[x][2] = '';
                                                        }
                                                    }
                                                }
                                            }
                                            if (reportsData_1[i].reportId == 118) {
                                                if (drilldownSeriesData[1].hasOwnProperty('data')) {
                                                    for (var x = 0; x < drilldownSeriesData[1].data.length; x++) {
                                                        if (drilldownSeriesData[1].data[x][2] == 'No') {
                                                            drilldownSeriesData[1].data[x][2] = '';
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        else {
                                            if (chartOptions.hasOwnProperty('xAxis')) {
                                                chartOptions.xAxis.categories = reportsData_1[i].series.categories;
                                            }
                                            if (reportsData_1[i].series.categories.length > 0) {
                                                var seriesData = reportsData_1[i].series.series;
                                                //console.log("seriesData ", seriesData);
                                                for (var i_1 = 0; i_1 < seriesData.length; i_1++) {
                                                    if (seriesData[i_1].type == 'pie') {
                                                        seriesData[i_1].center = [20, 0];
                                                        seriesData[i_1].size = 50;
                                                        seriesData[i_1].showInLegend = false;
                                                        seriesData[i_1].dataLabels = {
                                                            enabled: false
                                                        };
                                                        seriesData[i_1].name = "Farmers";
                                                    }
                                                    else {
                                                        seriesData[i_1].name = "Farmers";
                                                    }
                                                }
                                                chartOptions.series = reportsData_1[i].series.series;
                                            }
                                            else {
                                                chartOptions.series = [];
                                                //chartOptions.labels.items[0].html = '';
                                                if (chartOptions.hasOwnProperty('labels')) {
                                                    chartOptions.labels.items[0].html = '';
                                                }
                                            }
                                        }
                                    }
                                    //console.log(" seriesArr ", seriesArr)
                                    //for(let j=0;j < reportsData[i].conf[0].length; j++){
                                    //console.log(" reportsData[i].conf ", reportsData[i].conf[0].conf);              
                                    // chartOptions.chart.width = 320;
                                    //chartOptions.chart.height = "100%";
                                    //chartOptions.chart_type = chartOptions.chart.type;
                                    if (chartOptions.chart.hasOwnProperty('type')) {
                                        chartOptions.chart_type = chartOptions.chart.type;
                                    }
                                    else {
                                        if (chartOptions.chart.hasOwnProperty('charttype')) {
                                            chartOptions.chart_type = chartOptions.chart.charttype;
                                        }
                                    }
                                    chartOptions.title.style = {
                                        fontSize: "16px",
                                        fontWeight: '600'
                                    };
                                    chartOptions.reportId = reportsData_1[i].reportId;
                                    if (reportsData_1[i].is_drilldown == 1) {
                                        chartOptions.drilldown = reportsData_1[i].drilldown;
                                        //chartOptions.drilldown.drillUpButton = {"position":{"verticalAlign":"top"}};
                                        //added by Kiran for drillup button
                                        var drillUp = {
                                            relativeTo: 'spacingBox',
                                            position: {
                                                align: 'left',
                                                y: -10,
                                                x: 0
                                            },
                                            theme: {
                                                fill: '#63775c',
                                                'stroke-width': 1,
                                                r: 25,
                                                states: {
                                                    hover: {
                                                        fill: '#63775c'
                                                    },
                                                    select: {
                                                        fill: '#63775c'
                                                    }
                                                }
                                            }
                                        };
                                        chartOptions.drilldown.drillUpButton = drillUp;
                                        if (chartOptions.hasOwnProperty('lang')) {
                                            chartOptions.lang.drillUpText = '⇦';
                                        }
                                        else {
                                            var drillText = {
                                                drillUpText: '⇦'
                                            };
                                            chartOptions.lang = drillText;
                                        }
                                        //var defaultTitleXAxis = "Percentage Range";
                                        //var defaultTitleYAxis = "Avg age of children";
                                        defaultTitleYAxis = "";
                                        //var drilldownTitleXAxis = "School Name";
                                        //var drilldownTitleYAxis = "Count of children";
                                        drilldownTitleYAxis = "";
                                        //var drillDownTitle = "Hello";
                                        //var defaultTitle = "";
                                        if (chartOptions.hasOwnProperty('yAxis')) {
                                            if (Array.isArray(chartOptions.yAxis)) {
                                                defaultTitleYAxis = chartOptions.yAxis[0].title.text;
                                                //console.log("defaultTitleYAxis ", defaultTitleYAxis);
                                            }
                                            else {
                                                if (chartOptions.yAxis.hasOwnProperty('title')) {
                                                    defaultTitleYAxis = chartOptions.yAxis.title.text;
                                                    //console.log("defaultTitleYAxis ", defaultTitleYAxis);
                                                }
                                            }
                                        }
                                        // if(chartOptions.hasOwnProperty('title')){
                                        //   defaultTitle = chartOptions.title.text;
                                        // }
                                        // if(chartOptions.hasOwnProperty('yAxisDrilldownTitle')){
                                        //   drilldownTitleYAxis = chartOptions.yAxisDrilldownTitle.text;
                                        // }
                                        // console.log('drilldownTitleYAxis', drilldownTitleYAxis)
                                        chartOptions.chart.events = {
                                            drilldown: function (e) {
                                                // this.setTitle({text: e.seriesOptions.name}, {text: ''});
                                                // console.log(e.seriesOptions.id);
                                                var thisChart = this.yAxis[0].chart.options;
                                                if (thisChart.hasOwnProperty('yAxis')) {
                                                    if (Array.isArray(thisChart.yAxis)) {
                                                        if (thisChart.yAxis[0].hasOwnProperty('title')) {
                                                            this.oldYaxisTitle = thisChart.yAxis[0].title.text;
                                                        }
                                                    }
                                                    else {
                                                        if (thisChart.yAxis.hasOwnProperty('title')) {
                                                            this.oldYaxisTitle = thisChart.yAxis.title.text;
                                                        }
                                                    }
                                                }
                                                if (thisChart.hasOwnProperty('yAxisDrilldownTitle')) {
                                                    if (this.hasOwnProperty('yAxis')) {
                                                        if (Array.isArray(this.yAxis)) {
                                                            this.yAxis[0].setTitle({ text: thisChart.yAxisDrilldownTitle.text });
                                                        }
                                                        else {
                                                            if (this.yAxis.hasOwnProperty('title')) {
                                                                this.yAxis.setTitle({ text: thisChart.yAxisDrilldownTitle.text });
                                                            }
                                                        }
                                                    }
                                                }
                                                var curConfig = this.options;
                                                var curTitle = curConfig.title.text;
                                                if (curConfig.hasOwnProperty('drilltitle1')) {
                                                    if (curTitle == curConfig.drilltitle1.text) {
                                                        if (curConfig.hasOwnProperty('drilltitle2')) {
                                                            this.setTitle({ text: curConfig.drilltitle2.text }, { text: '' });
                                                            if (reportsData_1[i].reportId == 116) {
                                                                this.yAxis[0].setTitle({ text: "No. of responses" });
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        this.oldTitle = curTitle;
                                                        this.oldSubtitle = curConfig.subtitle.text;
                                                        if (curConfig.hasOwnProperty('drilltitle2')) {
                                                            this.setTitle({ text: curConfig.drilltitle1.text }, { text: 'Drilldown for details' });
                                                        }
                                                        else {
                                                            this.setTitle({ text: curConfig.drilltitle1.text }, { text: '' });
                                                        }
                                                        if (reportsData_1[i].reportId == 116) {
                                                            this.yAxis[0].setTitle({ text: "Average age" });
                                                        }
                                                    }
                                                }
                                                //this.xAxis[0].setTitle({ text: drilldownTitleXAxis });
                                                // this.yAxis[0].setTitle({ text: drilldownTitleYAxis });
                                                // console.log("drilldownTitleYAxis ", drilldownTitleYAxis);
                                                //this.setTitle({ text: drillDownTitle });
                                            },
                                            drillup: function (e) {
                                                //console.log(this);
                                                if (this.hasOwnProperty('yAxis')) {
                                                    if (Array.isArray(this.yAxis)) {
                                                        this.yAxis[0].setTitle({ text: this.oldYaxisTitle });
                                                    }
                                                    else {
                                                        if (this.yAxis.hasOwnProperty('title')) {
                                                            this.yAxis.setTitle({ text: this.oldYaxisTitle });
                                                        }
                                                    }
                                                }
                                                var curConfig = this.options;
                                                var curTitle = curConfig.title.text;
                                                if (curConfig.hasOwnProperty('drilltitle1')) {
                                                    if (curTitle == curConfig.drilltitle1.text) {
                                                        this.setTitle({ text: this.oldTitle }, { text: this.oldSubtitle });
                                                        if (reportsData_1[i].reportId == 116) {
                                                            this.yAxis[0].setTitle({ text: "No. of children" });
                                                        }
                                                    }
                                                    else {
                                                        this.setTitle({ text: curConfig.drilltitle1.text }, { text: 'Drilldown for details' });
                                                        if (reportsData_1[i].reportId == 116) {
                                                            this.yAxis[0].setTitle({ text: "Average age" });
                                                        }
                                                    }
                                                }
                                                //this.xAxis[0].setTitle({ text: defaultTitleXAxis });
                                                //this.yAxis[0].setTitle({ text: defaultTitleYAxis });
                                                //console.log("defaultTitleYAxis ", defaultTitleYAxis);
                                                //this.setTitle({ text: defaultTitle });
                                            }
                                        };
                                    }
                                    //console.log('chartOptions, ', chartOptions)
                                    if (chartOptions.chart.type == 'pie') {
                                        if (chartOptions.hasOwnProperty("plotOptions")) {
                                            if (chartOptions.plotOptions.hasOwnProperty("pie")) {
                                                chartOptions.plotOptions.pie.dataLabels = {
                                                    enabled: true,
                                                    format: '{point.percentage:.1f} %',
                                                    distance: -20,
                                                    color: '#fafafa'
                                                };
                                                chartOptions.plotOptions.pie.showInLegend = true;
                                            }
                                        }
                                        chartOptions.legend = {
                                            enabled: true
                                        };
                                    }
                                    if (chartOptions.chart.type == 'pyramid') {
                                        if (chartOptions.hasOwnProperty("plotOptions")) {
                                            if (chartOptions.plotOptions.hasOwnProperty("series")) {
                                                chartOptions.plotOptions.series.dataLabels = {
                                                    enabled: true,
                                                    format: '{point.y:,.0f}',
                                                    softConnector: true,
                                                    distance: -25,
                                                    color: '#fafafa'
                                                };
                                                chartOptions.plotOptions.series.width = '100%';
                                            }
                                        }
                                        chartOptions.title.x = 0;
                                    }
                                    if (chartOptions.chart.hasOwnProperty("charttype")) {
                                        //console.log("chartOptions.chart.charttype ", chartOptions.chart.charttype);
                                        if (chartOptions.chart.charttype == 'circle') {
                                            seriesArr = [];
                                            for (var j = 0; j < reportsData_1[i].series.length; j++) {
                                                for (var prop_3 in reportsData_1[i].series[j]) {
                                                    if (reportsData_1[i].series[0].hasOwnProperty(prop_3)) {
                                                        if (reportsData_1[i].series[0][prop_3] != null) {
                                                            //if(Number(reportsData[i].series[0][prop]) > 0){
                                                            var innerObj_2 = {
                                                                name: 'Farmers',
                                                                y: Number(''),
                                                                color: '#50C1BF'
                                                            };
                                                            //innerObj.name = prop;
                                                            //console.log("reportsData[i].series[0][prop] ", reportsData[i].series[0][prop]);
                                                            var x = reportsData_1[i].series[0][prop_3].split('/');
                                                            //console.log("x ", x);
                                                            innerObj_2.y = Number(x[0]);
                                                            seriesArr.push(innerObj_2);
                                                            // } 
                                                        }
                                                    }
                                                }
                                            }
                                            chartOptions.series[0].data = seriesArr;
                                        }
                                    }
                                    if (reportsData_1[i].conf[0].report_type == 'column_placement') {
                                        seriesArr = [];
                                        //console.log("reportsData[i] ", reportsData[i]);
                                        delete chartOptions.xAxis.type;
                                        chartOptions.xAxis.categories = reportsData_1[i].series.categories;
                                        //console.log("reportsData[i].series ", reportsData[i].series);
                                        //console.log("reportsData[i].series ", reportsData[i].series.series);
                                        var seriesData = reportsData_1[i].series.series;
                                        chartOptions.series = [];
                                        //console
                                        var colorsDataObject = [
                                            {
                                                name: '',
                                                color: 'rgba(165,170,217,1)',
                                                data: [],
                                                pointPadding: 0.3,
                                                pointPlacement: -0.2
                                            }, {
                                                name: '',
                                                color: 'rgba(126,86,134,.9)',
                                                data: [],
                                                pointPadding: 0.4,
                                                pointPlacement: -0.2
                                            }, {
                                                name: '',
                                                color: 'rgba(248,161,63,1)',
                                                data: [],
                                                pointPadding: 0.3,
                                                pointPlacement: 0.2,
                                                yAxis: 1
                                            }, {
                                                name: '',
                                                color: 'rgba(186,60,61,.9)',
                                                data: [],
                                                pointPadding: 0.4,
                                                pointPlacement: 0.2,
                                                yAxis: 1
                                            }
                                        ];
                                        for (var k = 0; k < seriesData.length; k++) {
                                            //console.log("seriesData.name ", seriesData[k].name);
                                            //console.log("seriesData.name ", seriesData[k].data);
                                            // for(let j=0; j < chartOptions.series.length;j++){
                                            //for(let x=0;x<colorsDataObject.length;x++){
                                            colorsDataObject[k].name = seriesData[k].name;
                                            colorsDataObject[k].data = seriesData[k].data;
                                            //}
                                            //old code 
                                            //chartOptions.series[k].name = seriesData[k].name;
                                            //chartOptions.series[k].data = seriesData[k].data;
                                            // }
                                        }
                                        //console.log("colorsDataObject ", colorsDataObject);
                                        chartOptions.series = colorsDataObject;
                                        //console.log(" column placement ", chartOptions);
                                    }
                                    if (reportsData_1[i].conf[0].report_type == 'column-stacked') {
                                        seriesArr = [];
                                        if (chartOptions.xAxis.hasOwnProperty('type')) {
                                            delete chartOptions.xAxis.type;
                                        }
                                        chartOptions.xAxis.categories = reportsData_1[i].series.categories;
                                        var seriesData = reportsData_1[i].series.series;
                                        for (var k = 0; k < seriesData.length; k++) {
                                            //     chartOptions.series[k].name = seriesData[k].name;
                                            //     chartOptions.series[k].data = seriesData[k].data;  
                                            seriesData[k].data = seriesData[k].data.map(function (val, i) {
                                                return val === 0 ? null : val;
                                            });
                                            chartOptions.series.push(seriesData[k]);
                                        }
                                        //console.log(" column placement ", chartOptions);
                                    }
                                    chartOptions.reportTypeFrontend = currentType;
                                    chartOptions.chart.backgroundColor = '#fafafa';
                                    chartOptions.chart.spacingTop = 20;
                                    //chartOptions.chart.spacingBottom = 20;
                                    //added by Kiran for currency on zAxis
                                    if (reportsData_1[i].reportId == 93 || reportsData_1[i].reportId == 87 || reportsData_1[i].reportId == 135) {
                                        if (reportsData_1[i].hasOwnProperty('currency')) {
                                            chartOptions.yAxis[1].title.text = chartOptions.yAxis[1].title.text + ' (' + reportsData_1[i].currency + ')';
                                            if (reportsData_1[i].reportId == 87) {
                                                chartOptions.yAxis[0].title.text = chartOptions.yAxis[0].title.text + ' (' + reportsData_1[i].currency + ')';
                                            }
                                        }
                                    }
                                    if (reportsData_1[i].reportId == 49) {
                                        if (reportsData_1[i].hasOwnProperty('currency')) {
                                            chartOptions.yAxis.title.text = chartOptions.yAxis.title.text + ' (' + reportsData_1[i].currency + ')';
                                        }
                                    }
                                    // if(reportsData[i].reportId == 102){
                                    //   var UNDF;
                                    //   if(chartOptions.hasOwnProperty('tooltip')){
                                    //     chartOptions.tooltip = {
                                    //       headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                                    //       pointFormatter: function () {
                                    //         if (this.series.chart.drilldownLevels !== UNDF && this.series.chart.drilldownLevels.length > 1) {
                                    //             return '<span style="color:' + this.color + '">' + this.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                                    //         } else {
                                    //             return '<span style="color:' + this.color + '">' + this.name + '</span>: <b>' + this.y.toFixed(1) + '%</b><br/>';
                                    //         }
                                    //       }
                                    //     }
                                    //   }
                                    // }
                                    //console.log("reportsData[i].conf ",reportsData[i].conf);
                                    if (reportsData_1[i].conf.length > 1) {
                                        contextMenuOptions = [
                                            'viewFullscreen',
                                            // {
                                            //   text: 'Change chart type ',
                                            //   onclick: function(this,event){
                                            //     // event.preventDefault();
                                            //     //console.log("this ",this);
                                            //     // console.log(this.userOptions.exporting.buttons.contextButton.menuItems[0].name);
                                            //     // let item = this.userOptions.exporting.buttons.contextButton.menuItems[0];
                                            //     // console.log(item);
                                            //     // console.log("selectedChartType ", this.selectedChartType);
                                            //     // event.stopPropagation();
                                            // //     let el = `<div class="dropdown-content">
                                            // //     <a href="#">Link 1</a>
                                            // //     <a href="#">Link 2</a>  
                                            // // </div> `;
                                            // //     let newElement01 = document.querySelector('.highcharts-menu div:first-child');
                                            // //     let elementOne = document.createElement('div');
                                            // //     elementOne.innerHTML =`<div class="dropdown-content">
                                            // //     <a href="#">Link 1</a>
                                            // //     <a href="#">Link 2</a>  
                                            // // </div>`; 
                                            // //     //this.renderer.appendChild(el);
                                            // //     newElement01.appendChild(elementOne);
                                            //     let self = this;
                                            //     parentScope01.changeChartType(self);
                                            //   }
                                            //   },
                                            {
                                                textKey: 'downloadPDF',
                                                onclick: function () {
                                                    this.exportChart({
                                                        type: 'application/pdf',
                                                        filename: reportsData_1[i].name
                                                    });
                                                }
                                            },
                                            {
                                                textKey: 'downloadPNG',
                                                onclick: function () {
                                                    this.exportChart();
                                                }
                                            },
                                            {
                                                textKey: 'downloadXLS',
                                                onclick: function () {
                                                    this.downloadXLS();
                                                }
                                            },
                                            {
                                                text: 'Remove',
                                                onclick: function () {
                                                    var self = this;
                                                    parentScope01.RemoveChart(self);
                                                }
                                            },
                                        ];
                                    }
                                    else {
                                        contextMenuOptions = [
                                            'viewFullscreen',
                                            {
                                                textKey: 'downloadPDF',
                                                onclick: function () {
                                                    this.exportChart({
                                                        type: 'application/pdf',
                                                        filename: reportsData_1[i].name
                                                    });
                                                }
                                            },
                                            {
                                                textKey: 'downloadPNG',
                                                onclick: function () {
                                                    this.exportChart();
                                                }
                                            },
                                            {
                                                textKey: 'downloadXLS',
                                                onclick: function () {
                                                    this.downloadXLS();
                                                }
                                            },
                                            {
                                                text: 'Remove',
                                                onclick: function () {
                                                    var self = this;
                                                    parentScope01.RemoveChart(self);
                                                }
                                            }
                                            // {
                                            //   textKey: 'viewData',
                                            //   text: 'Toggle data table',
                                            //   onclick: function (this) {
                                            //     if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                                            //       this.dataTableDiv.style.display = 'none';
                                            //     } else {
                                            //       this.viewData();
                                            //       this.dataTableDiv.style.display = '';
                                            //     }
                                            //   }                  
                                            // }
                                        ];
                                    }
                                    //console.log("contextMenuOptions ", contextMenuOptions);
                                    chartOptions.exporting.buttons.contextButton.menuItems = contextMenuOptions;
                                    chartOptions.exporting.filename = reportsData_1[i].name;
                                    //chartOptions.exporting.buttons.contextButton.className ='highcharts-button-normal';
                                    // chartOptions.legend = {
                                    //   enabled: falseS
                                    // };
                                    // chartOptions.exporting.menuItemDefinitions= {
                                    // 	viewFullscreen: {
                                    // 		onclick: function () {
                                    //       //this.fullscreen = new Highcharts.FullScreen(this.container);
                                    //       Highcharts.FullScreen.init(this.renderTo);
                                    //     	console.log('Test')
                                    // 		}
                                    // 	}
                                    // }
                                    //added bt KIran for export button position 
                                    chartOptions.navigation = {
                                        buttonOptions: {
                                            verticalAlign: 'top',
                                            y: -5
                                        }
                                    };
                                    //console.log("chartOptions ", chartOptions);
                                    //console.log(" json sting ", JSON.stringify(chartOptions));
                                    //console.log("chartOptions.series ", chartOptions.series);
                                    chartsList.push(chartOptions);
                                };
                                var seriesArr, elementItem, innerObj, object, newArray, defaultTitleYAxis, drilldownTitleYAxis;
                                for (var i = 0; i < reportsData_1.length; i++) {
                                    _loop_1(i);
                                }
                            }
                            else {
                                if (_this.existingSelectedReports && _this.existingSelectedReports.length == 0) {
                                    _this.chartElement01.nativeElement.innerHTML = '';
                                    _this.hcRService.allCharts = [];
                                }
                            }
                            if (chartsList && chartsList.length > 0) {
                                for (var i = 0; i < chartsList.length; i++) {
                                    var e = document.createElement("div");
                                    var newElement = _this.chartElement01.nativeElement.appendChild(e);
                                    if (colList[i] == 2) {
                                        _this.renderer.addClass(newElement, 'col-md-8');
                                        _this.renderer.addClass(newElement, 'col-lg-8');
                                    }
                                    else {
                                        _this.renderer.addClass(newElement, 'col-md-4');
                                        _this.renderer.addClass(newElement, 'col-lg-4');
                                    }
                                    _this.renderer.addClass(newElement, 'pl-0');
                                    _this.renderer.addClass(newElement, 'pr-0');
                                    var f = document.createElement("div");
                                    var newElement1 = newElement.appendChild(f);
                                    _this.renderer.addClass(newElement1, 'chartBox-pdmr');
                                    //Added by Kiran to replace math.random to windows.crypto
                                    var array = new Uint32Array(1);
                                    window.crypto.getRandomValues(array);
                                    newElement1.setAttribute('id', +array[0].toString().slice(2, 6));
                                    _this.hcRService.createChart(newElement1, chartsList[i]);
                                    _this.exportBtns = true;
                                    //var el = document.getElementById('test');
                                    //var newEl = document.createElement('button');
                                    //el.insertAdjacentHTML('afterend', '<button id="two">two</button>');
                                }
                                for (var i = 0; i < chartsList.length; i++) {
                                    //chartsList[i].exporting.buttons.contextButton.menuItems = contextMenuOptions; 
                                    var e = document.createElement("div");
                                    var newElement = _this.chartElement02.nativeElement.appendChild(e);
                                    // this.renderer.addClass(newElement, 'chartbox');
                                    // this.renderer.addClass(newElement, 'chartbox-add');
                                    _this.renderer.setStyle(newElement, 'width', '48%');
                                    _this.renderer.setStyle(newElement, 'display', 'inline-block');
                                    _this.renderer.setStyle(newElement, 'padding', '1%');
                                    //this.renderer.setStyle(newElement, 'margin','0.6%');
                                    //this.renderer.addClass(newElement, 'col-lg-4');
                                    _this.renderer.addClass(newElement, 'chartBox-pdmr');
                                    //Added by Kiran to replace math.random to windows.crypto
                                    var array = new Uint32Array(1);
                                    window.crypto.getRandomValues(array);
                                    newElement.setAttribute('id', +array[0].toString().slice(2, 6));
                                    //this.hcRService.createChart(newElement, chartsList[i]);
                                    var newVal = Highcharts.chart(newElement, chartsList[i]);
                                    _this.localChartsList.push(newVal);
                                    //this.exportBtns = true;
                                    //var el = document.getElementById('test');
                                    //var newEl = document.createElement('button');
                                    //el.insertAdjacentHTML('afterend', '<button id="two">two</button>');
                                }
                                if (currentType == 'predefined') {
                                    _this.profilingFlag = true;
                                }
                                else {
                                    //this.exportBtns = true;
                                    _this.customFlag = true;
                                }
                                // }else{
                                //   //this.chartElement02.nativeElement.innerHTML = '';
                                //   //this.hcRService.allCharts = [];
                                //   for(let i=0;i<chartsList.length;i++){
                                //     let e = document.createElement("div");
                                //     let newElement = this.chartElement02.nativeElement.appendChild(e);
                                //     this.renderer.addClass(newElement, 'chartbox');
                                //     this.renderer.addClass(newElement, 'chartbox-add');
                                //     this.renderer.addClass(newElement, 'col-lg-6');
                                //     newElement.setAttribute('id',+Math.random().toString().slice(2, 6));       
                                //     this.hcRService.createChart(newElement, chartsList[i]);
                                //     this.exportBtns = true;
                                //     this.customFlag = true;
                                //   }
                                // }
                                _this.checkChartsAvailable = _this.hcRService.allCharts;
                                //this.selectedReportsMultiSelect = [];
                                //this.selectedReportsMultiSelectCustom = [];
                                //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
                            }
                        }
                        else {
                            //this.existingSelectedReports = [];
                        }
                    });
                }
                else {
                    this.toastrService.info(this.noNewReportsSelected);
                }
                return [2 /*return*/];
            });
        });
    };
    RunReportComponent.prototype.reportTypeChange = function (typeOfReport) {
        //console.log(typeOfReport);     
        // if(typeOfReport == 'self'){
        //   //this.toastrService.info("Reports not available");    
        //   if(this.customFlag){
        //     this.exportBtns = true;
        //   }else{
        //     this.exportBtns = false;
        //   }      
        // }else if(typeOfReport == 'predefined'){
        //   if(this.profilingFlag){
        //     this.exportBtns = true;
        //   }else{
        //     this.exportBtns = false;
        //   } 
        // }
        if (this.hcRService.allCharts.length > 0) {
            this.exportBtns = true;
        }
    };
    RunReportComponent.prototype.cancel = function () {
        var url = "charts/" + this.projectId;
        this.router.navigate([url]);
    };
    RunReportComponent.prototype.downloadAsPDF = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var charts;
            return tslib_1.__generator(this, function (_a) {
                charts = this.localChartsList;
                Highcharts.exportCharts(charts, {
                    filename: 'P2F-Roots-reports',
                    type: 'application/pdf'
                });
                return [2 /*return*/];
            });
        });
    };
    RunReportComponent.prototype.RemoveChart = function (itemScope) {
        //console.log(itemScope);
        parentScope01.removeFromList(itemScope.id, itemScope.userOptions.reportId, itemScope);
        itemScope.renderTo.remove();
        //self.renderTo.remove();
        //self.destory();
        //self.remove();    
    };
    RunReportComponent.prototype.removeFromList = function (item, reportId, currentItem) {
        var charts = this.hcRService.allCharts;
        var profilingReports;
        var customReports;
        //let newCharts = charts.filter(item1  => item1.id != item);
        var newCharts = charts.filter(function (item1) { return item1.reportId != reportId; });
        this.hcRService.allCharts = newCharts;
        this.localChartsList = newCharts;
        this.existingSelectedReports = this.existingSelectedReports.filter(function (item3) { return item3 != reportId; });
        profilingReports = newCharts;
        customReports = newCharts;
        if (this.reportTypes == 'predefined') {
            this.selectedReportsMultiSelect = this.selectedReportsMultiSelect.filter(function (item2) { return item2.id != reportId; });
            profilingReports = profilingReports.filter(function (item3) { return item3.reportTypeFrontend == currentItem.reportTypeFrontend; });
        }
        else {
            this.selectedReportsMultiSelectCustom = this.selectedReportsMultiSelectCustom.filter(function (item2) { return item2.id != reportId; });
            customReports = customReports.filter(function (item3) { return item3.reportTypeFrontend == currentItem.reportTypeFrontend; });
        }
        this.checkChartsAvailable = this.hcRService.allCharts;
        if (this.hcRService.allCharts.length == 0) {
            this.profilingFlag = false;
            this.customFlag = false;
        }
        if (profilingReports && profilingReports.length > 0) {
            this.profilingFlag = true;
        }
        else {
            this.profilingFlag = false;
        }
        if (customReports && customReports.length > 0) {
            this.customFlag = true;
        }
        else {
            this.customFlag = false;
        }
    };
    RunReportComponent.prototype.printCharts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var charts, getDiv, mywindow, x, y;
            return tslib_1.__generator(this, function (_a) {
                charts = this.localChartsList;
                getDiv = document.getElementById('test1');
                mywindow = window.open('', 'PRINT', 'height=600,width=900');
                mywindow.document.write('<html><head><title>' + document.title + '</title>');
                mywindow.document.write('</head><body >');
                mywindow.document.write(document.getElementById('test1').innerHTML);
                mywindow.document.write('</body></html>');
                x = mywindow.document.querySelectorAll(".chartBox-pdmr");
                y = [];
                Highcharts.each(charts, function (chart) {
                    chart.reflow();
                });
                //console.log(y);
                mywindow.document.close(); // necessary for IE >= 10
                mywindow.focus(); // necessary for IE >= 10*/
                mywindow.print();
                mywindow.close();
                return [2 /*return*/, true];
            });
        });
    };
    RunReportComponent.prototype.initiatePrintForAll = function () {
        // Find tht button that has the ngx-print tag & after a timeout, click it. Without a timeout the data is not set when the print fn is called
        //var idPost=document.getElementById("printReportsBtn").innerHTML;
        // if(document){
        //   let btn = document.getElementById('printReportsBtn');
        //   setTimeout(() => btn.click(), 1000);
        // }        
    };
    RunReportComponent.prototype.trackBySectionName = function (index, dataItem) {
        return dataItem.section;
    };
    RunReportComponent.prototype.trackBySubsectionName = function (index, dataItem) {
        return dataItem.name;
    };
    RunReportComponent.prototype.clearAllCharts = function () {
        //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
        this.chartElement01.nativeElement.innerHTML = '';
        this.chartElement02.nativeElement.innerHTML = '';
        this.hcRService.allCharts = [];
        this.localChartsList = [];
        //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
        this.selectedReportsMultiSelect = [];
        this.selectedReportsMultiSelectCustom = [];
        this.exportBtns = false;
        this.reportAvailableFlag = false;
        this.existingSelectedReports = [];
        // this.dashboardAddObject ={
        //   "section":0,
        //   "subsection":'',
        //   "reportID":[]
        // }
        //this.runReportForm.submitted =false;
        //this.subsectionFlag = true;
    };
    RunReportComponent.prototype.onSelectAllChart = function (ele) {
        document.getElementById('Chart').click();
    };
    return RunReportComponent;
}());
export { RunReportComponent };
