import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';


@Injectable()
export class UpdateService {
  constructor(private readonly swUpdate: SwUpdate) {
    this.swUpdate.available.subscribe(evt => {
    	this.swUpdate.activateUpdate().then(() => document.location.reload());
    });
  }
}