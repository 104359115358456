import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AppConfigService } from './app-config.service';
// import * as Highcharts from 'highcharts';
// import { Project } from '../models/project';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

@Injectable()
export class ChartService {
  allCharts = [];
  chartURL = this.appConfigService.getUrl(this.appConfigService.CHART);

  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) {}

  GetFarmers(projectId: number): Observable<any> {
    return this.http.get<any>( `${this.chartURL}/${projectId}`);
  }
  GetFarmerActualHarvestDataTimeline(projectId: number): Observable<any> {
    return this.http.get<any>( `${this.chartURL}/harvestdata/timline/${projectId}`);
  }

  createChart(el, cfg) {
   let newVal =  Highcharts.chart(el, cfg);
   newVal.id = +Math.random().toString().slice(2, 6);
   //newVal.series[0].remove(false);
   this.allCharts.push(newVal);
  } 

}
