import { Component, OnInit } from '@angular/core';
import { ForgetpasswordService } from '../../services/forgetpassword.service';
import { Router} from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html'
})
export class ForgetpasswordComponent implements OnInit {

  username: any;

  // flag set in two components
  flagEn = false;
  flagEs = false;
  flagFr = false;
  flagZh = false;
  flagMg = false;
  flagTa = false;

  constructor(
    private readonly router: Router,
    private readonly forgetpasswordService: ForgetpasswordService,
    public toastrService: ToastrService,
    private readonly commonService: CommonService,
    private readonly loc: Location) {
    const localLang = this.loc.prepareExternalUrl('').replace(/\//g,'');

	    if (localLang === 'en') {
        this.flagEn = true;
      }
      if (localLang === 'es') {
          this.flagEs = true;
      }
      if (localLang === 'fr') {
          this.flagFr = true;
      }
      if (localLang === 'zh') {
        this.flagZh = true;
      }
      if (localLang === 'ta') {
        this.flagTa = true;
      }
      if (localLang === 'mg') {
        this.flagMg = true;
      }
      if (localLang === 'en') {
        this.flagEn = true;
      }
   }

    usrname: string;
    ngOnInit() {}

  sendEmail() {
    const forgottenName = this.username;
    if (forgottenName) {
      this.forgetpasswordService.sendForgetPassEmail(forgottenName).subscribe(forgetpwddata => {
          this.commonService.showToast('warning', forgetpwddata.code, {});
          this.router.navigate(['']);
      }, (err) => {
      });
    } else {
      this.commonService.showToast('warning', "please_enter_username", {});
    }
  }
}

