<app-header></app-header>

<div class="project-header clearfix">
  <h3 i18n="@@myProjects">MY PROJECTS</h3>
  <button class="btn btn-success" type="buttion" i18n="@@addProject" i18n-title="@@addProject">Add Project</button>

  <div class="form-group form-group-sm search">
    <div class="input-group input-group-right">
      <input type="text" class="form-control" placeholder="Search projects" i18n-placeholder="@@searchProjects">
      <div class="input-group-icon">
        <button type="button" class="btn btn-link input-icon" name="button"><span class="utilicon utilicon-search"></span></button>
      </div>
    </div>
  </div>
</div>
<div class="project-list-box clearfix">
  <div class="project-list clearfix">

    <div class="container-fluid">
      <div class="peoject-img-list">
        <a href="javascript:void(0);" (click)="toggle1()" id="showDetail"> <img src="assets/images/project-img1.webp" alt="project"> 
          <span class="project-title" i18n="@@valleyOfFlowers"> Valley of Flowers</span> 
        </a>
        <div class="clearfix"></div>
        <div class="table-wrapper project-table-data" *ngIf="showDetailData1">

          <table class="table table-hover table-one-line">
            <tbody>
              <tr>
                <td> <strong i18n="@@deepRootsManager">Deeproots Manager:</strong> </td>
                <td>Andrino Chagnaud</td>
              </tr>
              <tr>
                <td> <strong i18n="@@product">Product:</strong> </td>
                <td>Ashine</td>
              </tr>
              <tr>
                <td> <strong i18n="@@typeOfProduct">Type of Product:</strong> </td>
                <td>Biomass</td>
              </tr>
              <tr>
                <td> <strong i18n="@@supplierName">Supplier Name:</strong> </td>
                <td>A Shine Ltd</td>
              </tr>
              <tr>
                <td> <strong i18n="@@country">Country:</strong> </td>
                <td>India</td>
              </tr>
              <tr>
                <td> <strong i18n="@@region">Region:</strong> </td>
                <td>Mumbai</td>
              </tr>
              <tr>
                <td> <strong i18n="@@startDate">Start Date:</strong> </td>
                <td>10/06/2019</td>
              </tr>
              <tr>
                <td> <strong i18n="@@status2">Status:</strong> </td>
                <td i18n="@@actived1">Actived</td>
              </tr>



            </tbody>
          </table>
        </div>
      </div>

      <div class="project-right-area">
        <div class="project-para">
          <p i18n="@@splPara01">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>
        <div class="services-available">
          <h3 i18n="@@servicesAvailable">Services Available:</h3>
          <div class="services-list">
            <div class="service-image">
              <img src="assets/images/geo-mapping.svg"  alt="Geo Mapping">
              <span class="service-name" i18n="@@geoMapping">Geo Mapping</span>
            </div>
            <div class="service-image">
              <img src="assets/images/id-card.svg" alt="ID Card">
              <span class="service-name" i18n="@@printIDCards">Print ID Cards</span>
            </div>
            <div class="service-image">
              <img src="assets/images/reporting.svg" alt="reposrting">
              <span class="service-name" i18n="@@reportingAndAnalytics">Reporting & Analytics</span>
            </div>
            <div class="service-image">
              <img src="assets/images/scan-card.svg" alt="scan card">
              <span class="service-name" i18n="@@scanIDCard">Scan ID Card</span>
            </div>
            <div class="service-image">
              <img src="assets/images/tracing.svg" alt="tracing">
              <span class="service-name" i18n="@@tracingAbility">Tracing Ability</span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <button class="btn view-more">  <span i18n="@@readMore">Read More</span> </button>
  </div>


  <div class="clearfix"></div>

  <div class="project-list clearfix">

    <div class="container-fluid">
      <div class="peoject-img-list">
        <a href="javascript:void(0);" (click)="toggle2()" id="showDetail"> <img src="assets/images/project-img2.webp" alt="project"> 
          <span class="project-title" i18n="@@fragranceDevelopment">
            Fragrance Development </span> </a>
        <div class="clearfix"></div>
        <div class="table-wrapper project-table-data" *ngIf="showDetailData2">

          <table class="table table-hover table-one-line">
            <tbody>
              <tr>
                <td> <strong i18n="@@deepRootsManager">Deeproots Manager:</strong> </td>
                <td>Andrino Chagnaud</td>
              </tr>
              <tr>
                <td> <strong i18n="@@product">Product:</strong> </td>
                <td>Ashine</td>
              </tr>
              <tr>
                <td> <strong i18n="@@typeOfProduct">Type of Product:</strong> </td>
                <td>Biomass</td>
              </tr>
              <tr>
                <td> <strong i18n="@@supplierName">Supplier Name:</strong> </td>
                <td>A Shine Ltd</td>
              </tr>
              <tr>
                <td> <strong i18n="@@country">Country:</strong> </td>
                <td>India</td>
              </tr>
              <tr>
                <td> <strong i18n="@@region">Region:</strong> </td>
                <td>Mumbai</td>
              </tr>
              <tr>
                <td> <strong i18n="@@startDate">Start Date:</strong> </td>
                <td>10/06/2019</td>
              </tr>
              <tr>
                <td> <strong i18n="@@status2">Status:</strong> </td>
                <td>Actived</td>
              </tr>



            </tbody>
          </table>
        </div>
      </div>

      <div class="project-right-area">
        <div class="project-para">
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when
            looking
            at its layout.</p>
        </div>
        <div class="services-available">
          <h3 i18n="@@servicesAvailable">Services Available:</h3>
          <div class="services-list">
            <div class="service-image">
              <img src="assets/images/geo-mapping.svg" alt="Geo Mapping">
              <span class="service-name" i18n="@@geoMapping">Geo Mapping</span>
            </div>
            <div class="service-image">
              <img src="assets/images/id-card.svg" alt="ID Card">
              <span class="service-name" i18n="@@printIDCards">Print ID Cards</span>
            </div>
            <div class="service-image">
              <img src="assets/images/reporting.svg" alt="reporting">
              <span class="service-name" i18n="@@reportingAndAnalytics">Reporting & Analytics</span>
            </div>
            <div class="service-image">
              <img src="assets/images/scan-card.svg" alt="scan card">
              <span class="service-name" i18n="@@scanIDCard">Scan ID Card</span>
            </div>
            <div class="service-image">
              <img src="assets/images/tracing.svg" alt="tracing">
              <span class="service-name" i18n="@@tracingAbility">Tracing Ability</span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <button class="btn view-more"> <span i18n="@@readMore">Read More</span> </button>
  </div>

  <div class="clearfix"></div>
  <div class="project-list clearfix">

    <div class="container-fluid">
      <div class="peoject-img-list">
        <a href="javascript:void(0);" (click)="toggle3()" id="showDetail"> <img src="assets/images/project-img3.webp" alt="project"> <span class="project-title"> Valley of Flowers</span> </a>
        <div class="clearfix"></div>
        <div class="table-wrapper project-table-data" *ngIf="showDetailData3">

          <table class="table table-hover table-one-line">
            <tbody>
              <tr>
                <td> <strong i18n="@@deepRootsManager">Deeproots Manager:</strong> </td>
                <td>Andrino Chagnaud</td>
              </tr>
              <tr>
                <td> <strong i18n="@@product">Product:</strong> </td>
                <td>Ashine</td>
              </tr>
              <tr>
                <td> <strong i18n="@@typeOfProduct">Type of Product:</strong> </td>
                <td>Biomass</td>
              </tr>
              <tr>
                <td> <strong i18n="@@supplierName">Supplier Name:</strong> </td>
                <td>A Shine Ltd</td>
              </tr>
              <tr>
                <td> <strong i18n="@@country">Country:</strong> </td>
                <td>India</td>
              </tr>
              <tr>
                <td> <strong i18n="@@region">Region:</strong> </td>
                <td>Mumbai</td>
              </tr>
              <tr>
                <td> <strong i18n="@@startDate">Start Date:</strong> </td>
                <td>10/06/2019</td>
              </tr>
              <tr>
                <td> <strong>Status:</strong> </td>
                <td>Actived</td>
              </tr>



            </tbody>
          </table>
        </div>
      </div>

      <div class="project-right-area">
        <div class="project-para">
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when
            looking
            at its layout.</p>
        </div>
        <div class="services-available">
          <h3 i18n="@@servicesAvailable">Services Available:</h3>
          <div class="services-list">
            <div class="service-image">
              <img src="assets/images/geo-mapping.svg" alt="geo mapping">
              <span class="service-name" i18n="@@geoMapping">Geo Mapping</span>
            </div>
            <div class="service-image">
              <img src="assets/images/id-card.svg" alt="ID Card">
              <span class="service-name" i18n="@@printIDCards">Print ID Cards</span>
            </div>
            <div class="service-image">
              <img src="assets/images/reporting.svg" alt="reporting">
              <span class="service-name" i18n="@@reportingAndAnalytics">Reporting & Analytics</span>
            </div>
            <div class="service-image">
              <img src="assets/images/scan-card.svg" alt="scan card">
              <span class="service-name" i18n="@@scanIDCard">Scan ID Card</span>
            </div>
            <div class="service-image">
              <img src="assets/images/tracing.svg" alt="tracing">
              <span class="service-name" i18n="@@tracingAbility">Tracing Ability</span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <button class="btn view-more">  <span i18n="@@readMore">Read More</span> </button>
  </div>
  <div class="clearfix"></div>

 

</div>