
import { Observable, forkJoin, throwError,of } from 'rxjs';


import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';


import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';

import { CommonService } from '../services/common.service';
import { ProjectService } from '../services/project.service';
import { FarmerService } from '../services/farmer.service';
import { StorageService } from '../services/storage.service';

interface IReturn {
  productType: any[];
  managers: any[];
  projects: any[];
  farmers: any[];
  farmermaster: any[];
  indfarmers: any[];
}

@Injectable()
export class DashboardResolver implements Resolve<IReturn> {

  userValue;

  constructor(private readonly farmerService: FarmerService,
    private readonly projectService: ProjectService,
    private readonly commonService: CommonService,
    private readonly storageService: StorageService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IReturn> {
    if (this.checkRole() < 4) {
      return  forkJoin ([
        this.commonService.getProductType().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getProjectManager().pipe(map((results) => results), catchError(e => of([]))),
       
      ]).pipe(
      map(results => ({
        productType: results[0],
        managers: results[1],

      })),
    catchError(error => {
      return throwError(error);
      }),
    );
    }
  }

  checkRole(): any {
    const localTokenInfo = this.storageService.getLocalTokenInfo();
    if (localTokenInfo) {
      this.userValue = JSON.parse(localTokenInfo);
      return this.userValue.role.id;
    }
  }

}
