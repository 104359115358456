import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { CommonService } from '../../services/common.service';
import { ProjectService } from '../../services/project.service';
import { UserService } from '../../services/user.service';
import { CampaignService } from '../../services/campaign.service';
import { TranslateService } from '@ngx-translate/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FarmerService } from '../../services/farmer.service';
import { SuppliersService } from '../../services/suppliers.service';

@Component({
  selector: 'app-campaign-management',
  templateUrl: './campaign-management.component.html',
  styleUrls: ['./campaign-management.component.css']
})
export class CampaignManagementComponent implements OnInit {
  campaigns = [];
  currentUser = this.userService.getCurrentUser();
  fromNavId;
  public typeOfAdministrationArr: any[]; 
  typeOfAdministration = '';
  previousPaginationLabel;
  nextPaginationLabel;
  searchText;
  searchTextFarmers;
  loadedCampaigns = false;
  closeCampaignModal: BsModalRef;
  closingCampaign = false;
  closeThisCampaign;
  campaignClosingRemarks;
  closeCampaignSubmitted;
  farmers = [];
  selectedFarmers = [];
  selectedFarmersCopy = [];
  loadingFarmers = false;
  manageFarmersModal: BsModalRef;
  cancelFarmersModal: BsModalRef;
  tooltipText;
  selectDeselectLabels;
  managingFarmersForCampaign;
  campaignStatuses;
  confirmUpdateModal;
  confirmFarmerModal;

  isAscending = true;
  isAscendingId = true;
  isAscendingName = true;
  isAscendingTel = true;
  isAscendingDate = true;

  isAscendingFarmer = true;
  isAscendingIdFarmer = true;
  isAscendingNameFarmer = true;
  isAscendingTelFarmer = true;
  isAscendingDateFarmer = true;
  isAscendingAgeFarmer = true;
  isAscendingSexFarmer = true;
  isAscendingVillageFarmer = true;
  isAscendingVCFarmer = true;
  sortLabel = '';

  page = 1;
  itemsPerPage = 10;
  farmersListPage = 1;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly commonService: CommonService,
    private readonly campaignService: CampaignService,
    private readonly userService: UserService,
    private readonly projectService: ProjectService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly farmerService: FarmerService,
    private readonly modalService: BsModalService,
    private readonly supplierService: SuppliersService
  ) {
    this.route.queryParamMap.subscribe(queryParams => {
      const qpObject = { ...queryParams.keys, ...queryParams };

      this.fromNavId = qpObject['params']['from'];

      this.translate.get('typeAdminTextArr').subscribe((val) => {
        this.typeOfAdministrationArr = val;
        if (this.fromNavId === '2') {
          this.typeOfAdministration = this.typeOfAdministrationArr[0];
        }
        else if (this.fromNavId === '3') {
          this.typeOfAdministration = this.typeOfAdministrationArr[1];
        }
      }, err => console.log('err', err));
    });

    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });

    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });

    this.translate.get('select_deselect_object').subscribe(result => {
      this.selectDeselectLabels = result;
    });
  }

  ngOnInit() {
    this.getCampaigns();
  }

  async getCampaigns() {
    const request = await Promise.all([
      this.campaignService.getCampaigns().toPromise(),
      this.projectService.GetProjectsBasicInfo().toPromise(),
      this.campaignService.getCampaignStatuses().toPromise()
    ]);

    if (request[0]['code'] === 'success' && request[1]['msg'] === 'success' && request[2]['msg'] === 'success') {
      const campaigns = request[0]['message'];
      const projects = request[1]['data'];
      const campaignStatuses = request[2]['data'];

      for (let i = 0; i < campaigns.length; i++) {
        campaigns[i].project = projects.find(item => item.id === campaigns[i].project_id);
        campaigns[i].projectname = campaigns[i].project.name;
        campaigns[i].ecosystem = `${campaigns[i].project.Supplier.name} - ${campaigns[i].project.Product.name}`;
        campaigns[i].producttype = campaigns[i].project.Product.product_type.name;
        campaigns[i].status = campaigns[i].status && campaigns[i].status !== '0' ? campaignStatuses.find(item => item.id === parseInt(campaigns[i].status))['name'] : '';
      }

      this.campaigns = campaigns;
      this.loadedCampaigns = true;
    }
  }

  goToAddCampaign(): void {
    if (navigator.onLine) {
      const url = 'campaign-setup';
      this.router.navigate([url], { queryParams: { from: this.fromNavId }});
    }
  }

  goToEditCampaign(campaignId) {
    if (navigator.onLine) {
      const url = `campaign-setup/${campaignId}`;
      this.router.navigate([url],{ queryParams: { from: this.fromNavId }});
    }
  }

  getGridFilters() {
    const gridColums = ['name','description','campaignstatus', 'producttype'];
    const finalObj = {};
    if (this.campaigns === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  closeCampaign(campaign, template): void {
    this.confirmUpdateModal = true;
    this.campaignClosingRemarks = "";
    this.closeThisCampaign = campaign;
    this.closeCampaignModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  cancelCloseCampaign(val): void {
    if(val.length > 0){
      this.confirmUpdateModal = false;
    } else {
      this.declineCloseCampaign();
    }
  }

  declineCloseCampaign(): void {
    this.closeThisCampaign = undefined;
    if (this.closeCampaignModal) { 
      this.closeCampaignModal.hide(); 
    }
  }
  confirmCloseCampaignNo(){
    this.confirmUpdateModal = true;
  }

  confirmCloseCampaign(): void {
    this.closeCampaignSubmitted = true;
    if (!this.campaignClosingRemarks || this.campaignClosingRemarks.length === 0) {
      return;
    }
    this.closingCampaign = true;
    this.campaignService.closeCampaign(this.closeThisCampaign.id, {closingRemarks: this.campaignClosingRemarks})
    .subscribe(result => {
      if (result['code'] === 'success') {
        this.closingCampaign = false;
        this.declineCloseCampaign();
        this.getCampaigns();
      }
    })
  }

  async manageFarmers(campaign, template) {
    this.searchTextFarmers = ""
    this.confirmFarmerModal = true;
    if (!this.loadingFarmers) {
      this.spinner.show();
      this.loadingFarmers = true;
      this.managingFarmersForCampaign = campaign;
      
      const requests = await Promise.all([
        this.farmerService.getFarmersByProjectBasicData(campaign.project_id).toPromise(),
        this.campaignService.getFarmerIdsMappedToCampaign(campaign.id).toPromise(),
      ]);
      
      this.loadingFarmers = false;
      this.spinner.hide();
      
      if (requests[0]['msg'] === 'success') {
        this.farmers = requests[0].data.filter(item => !item.is_discontinued);
        for(const item of this.farmers){
          item.village_name = item.region_village.village_name
        }
        this.sortFarmers();
      }
      if (requests[1]['code'] === 'success') {
        this.selectedFarmers = requests[1].message;
        this.selectedFarmersCopy = JSON.parse(JSON.stringify(this.selectedFarmers));
      }
  
      if (this.farmers.length === 0) {
        this.commonService.showToast('info', 'no_farmers_regd', {});
      } else {
        this.farmersListPage = 1;
        this.tooltipText = undefined;
        this.manageFarmersModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
      }
    }
  }

  getGridFiltersFarmers() {
    const gridColums = ['farmer_code', 'name', 'official_id', 'age', 'village_name','village_collectors', 'cr_date'];
    const finalObj = {};
    if (this.farmers === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchTextFarmers;
      });
    }
    return finalObj;
  }

  // Toggle a particular farmer's selection status
  toggleThisFarmerSelect(id): void {
    if (this.selectedFarmers.indexOf(id) > -1) {
      this.selectedFarmers = this.selectedFarmers.filter(item => item !== id);
    }
    else {
      this.selectedFarmers.push(id);
    }
    this.setTooltipText();
  }

  setTooltipText(): void {
    if (this.farmers && this.selectedFarmers) {
      if (this.selectedFarmers.length === this.farmers.length) {
        // Deselect farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.deselect} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.deselect} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      } else {
        // Select farmers.length farmers
        if (this.farmers.length === 1) {
          this.tooltipText = `${this.selectDeselectLabels.select} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.select} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      }
    }
  }

  cancelManageFarmers(){
    let isEqual = false;
    for(const val of this.selectedFarmers){
      if(this.selectedFarmersCopy.indexOf(val) < 0){
        isEqual = true;
      }
    }
    if(isEqual){
      this.confirmFarmerModal = false;
    } else {
      this.declineManageFarmers();
    }
  }

  declineManageFarmers(): void {
    this.selectedFarmers = [];
    this.farmers = [];
    this.managingFarmersForCampaign = undefined;
    if (this.manageFarmersModal) {
      this.manageFarmersModal.hide();
    }
  }

  confirmCloseFarmerNo(){
    this.confirmFarmerModal = true;
  }

  confirmManageFarmers(): void {
    this.campaignService.mapFarmersToCampaign(this.managingFarmersForCampaign.id, this.selectedFarmers)
    .subscribe(result => {
      console.log('result', result);
      if (result['code'] === 'success') {
        this.commonService.showToast('success', 'changes_saved', {});
        this.declineManageFarmers();
      }
    })
  }

  closeFarmerCancel(){
    if (this.cancelFarmersModal) {
      this.cancelFarmersModal.hide();
    }
  }

  confirmCancel(){
    if (this.cancelFarmersModal) {
      this.cancelFarmersModal.hide();
    }
    if (this.manageFarmersModal) {
      this.manageFarmersModal.hide();
    }
  }

  // Select / deselect all farmers in the array
  toggleAllFarmers(): void {
    // If all farmers are already selected, then deselect all farmers
    if (this.selectedFarmers.length === this.farmers.length) {
      this.selectedFarmers = [];
    }
    // If all farmers are NOT already selected, select all the farmers
    else {
      this.selectedFarmers = [];
      for (const val of this.farmers) {
        this.selectedFarmers.push(val.id);
      }
    }
    this.setTooltipText();
  }

  sortBy(sortOn: string) {

    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);

  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    if (sortBy === 'projectname') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.campaigns.sort((a, b) => {
        if (a.projectname.toLowerCase() < b.projectname.toLowerCase()) {
          return 1;
        }
        if (a.projectname.toLowerCase() > b.projectname.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'ecosystem') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.campaigns.sort((a, b) => {
        if (a.ecosystem.toLowerCase() < b.ecosystem.toLowerCase()) {
          return 1;
        }
        if (a.ecosystem.toLowerCase() > b.ecosystem.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'campaignname') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.campaigns.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'campaigndescription') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.campaigns.sort((a, b) => {
        if (a.description.toLowerCase() < b.description.toLowerCase()) {
          return 1;
        }
        if (a.description.toLowerCase() > b.description.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'status') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.campaigns.sort((a, b) => {
        if (a.status.toLowerCase() < b.status.toLowerCase()) {
          return 1;
        }
        if (a.status.toLowerCase() > b.status.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'producttype') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.campaigns.sort((a, b) => {
        if (a.producttype.toLowerCase() < b.producttype.toLowerCase()) {
          return 1;
        }
        if (a.producttype.toLowerCase() > b.producttype.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'startdate') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.campaigns.sort((a, b) => {
        if (a.start_date.toLowerCase() < b.start_date.toLowerCase()) {
          return 1;
        }
        if (a.start_date.toLowerCase() > b.start_date.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'enddate') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.campaigns.sort((a, b) => {
        if (a.end_date && b.end_date) {
          if (a.end_date.toLowerCase() < b.end_date.toLowerCase()) {
            return 1;
          }
          if (a.end_date.toLowerCase() > b.end_date.toLowerCase()) {
            return -1;
          }
        }
        return 0;
      });
    }


  }

  descendic(sortBy: string) {
    this.isAscending = true;
    if (sortBy === 'projectname') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.campaigns.sort((a, b) => {
        if (a.projectname.toLowerCase() > b.projectname.toLowerCase()) {
          return 1;
        }
        if (a.projectname.toLowerCase() < b.projectname.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'ecosystem') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.campaigns.sort((a, b) => {
        if (a.ecosystem.toLowerCase() > b.ecosystem.toLowerCase()) {
          return 1;
        }
        if (a.ecosystem.toLowerCase() < b.ecosystem.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'campaignname') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.campaigns.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'campaigndescription') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.campaigns.sort((a, b) => {
        if (a.description.toLowerCase() > b.description.toLowerCase()) {
          return 1;
        }
        if (a.description.toLowerCase() < b.description.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'status') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.campaigns.sort((a, b) => {
        if (a.status.toLowerCase() > b.status.toLowerCase()) {
          return 1;
        }
        if (a.status.toLowerCase() < b.status.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'producttype') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.campaigns.sort((a, b) => {
        if (a.producttype.toLowerCase() > b.producttype.toLowerCase()) {
          return 1;
        }
        if (a.producttype.toLowerCase() < b.producttype.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'startdate') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.campaigns.sort((a, b) => {
        if (a.start_date.toLowerCase() > b.start_date.toLowerCase()) {
          return 1;
        }
        if (a.start_date.toLowerCase() < b.start_date.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'enddate') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.campaigns.sort((a, b) => {
        if (a.end_date && b.end_date) {
          if (a.end_date.toLowerCase() > b.end_date.toLowerCase()) {
            return 1;
          }
          if (a.end_date.toLowerCase() < b.end_date.toLowerCase()) {
            return -1;
          }
        }
        return 0;
      });
    }
  }

  @HostListener('window:keyup', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      if(this.confirmFarmerModal){
        this.cancelManageFarmers();
      } else {
        this.confirmCloseFarmerNo();
      }
      if(this.confirmUpdateModal){
        this.cancelCloseCampaign(this.campaignClosingRemarks)
      } else {
        this.confirmCloseCampaignNo();
      }
    }
  }

  exportToExcel() : void {
    this.supplierService.exportData('CAMPAIGNMGMT_MDM').subscribe(result => {
      console.log('result', result);
      var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
      const blob = new Blob([result], {type: contentType});
      const linkdownload = document.createElement('a');
      linkdownload.href = URL.createObjectURL(blob);
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();
      var today1 = `${dd}-${mm}-${yyyy}`;
      this.translate.get('campaign_management').subscribe((val) => {
        linkdownload.download =`P2F-Roots_${val}_ExcelExport_${today1}.xlsx`;
        linkdownload.style.display = 'none';
        document.body.appendChild(linkdownload);
        linkdownload.click();
      }, err => console.log('err', err));
    }, err=> console.log('err', err))
  }

  farmerSortBy(sortOn: string) {
    this.isAscendingFarmer ? this.ascendicFarmer(sortOn) : this.descendicFarmer(sortOn);
  }

  ascendicFarmer(sortBy: string) {
    this.isAscendingFarmer = false;

    this.isAscendingIdFarmer = sortBy !== 'id';
    this.isAscendingNameFarmer = sortBy !== 'name';
    this.isAscendingTelFarmer = sortBy !== 'official_id';
    this.isAscendingDateFarmer = sortBy !== 'cr_date';
    this.isAscendingAgeFarmer = sortBy !== 'age';
    this.isAscendingSexFarmer = sortBy !== 'sex';
    // Sort of new fields added
    this.isAscendingVillageFarmer = sortBy !== 'village_name';
    this.isAscendingVCFarmer = sortBy === 'village_collectors'
    // Sort of new fields added
    if (sortBy === 'name') {
      this.farmers.sort((a, b) => {
        if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
          return 1;
        }
        if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
      this.farmers.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'village_name') {
      this.farmers.sort((a, b) => {
        if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
          return 1;
        }
        if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }

  descendicFarmer(sortBy: string) {
    this.isAscendingFarmer = true;

    this.isAscendingIdFarmer = sortBy === 'id';
    this.isAscendingNameFarmer = sortBy === 'name';
    this.isAscendingTelFarmer = sortBy === 'official_id';
    this.isAscendingDateFarmer = sortBy === 'cr_date';
    this.isAscendingAgeFarmer = sortBy === 'age';
    this.isAscendingSexFarmer = sortBy === 'sex';
    // Sort of new fields added
    this.isAscendingVillageFarmer = sortBy === 'village_name';
    this.isAscendingVCFarmer = sortBy === 'village_collectors'
    // Sort of new fields added
    if (sortBy === 'name') {
      this.farmers.sort((a, b) => {
        if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
          return 1;
        }
        if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
      this.farmers.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'village_name') {
      this.farmers.sort((a, b) => {
        if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
          return 1;
        }
        if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
  }

  sortFarmers(): void {
    this.farmers.sort((a, b) => {
      if (a.cr_date < b.cr_date) {
        return 1;
      }
      if (a.cr_date > b.cr_date) {
        return -1;
      }
      return 0;
    });
  }

}
