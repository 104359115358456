<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToReportingManagement(project?.id)" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item active cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)"><span i18n-title="@@projectReporting" title="Project Reporting">...</span></li>
      <li class="breadcrumb-item active" aria-current="page">
        <span>Consolidated Report</span>
      </li>
    </ol>
  </nav>
</div>

<div class="content-wrap content-wrapper background margin-btm-20 min-height-calc">
  <div class="form-design form-box pb-0">
    <form name="runReportForm" #runReportForm="ngForm" novalidate enctype="multipart/form-data">
      <div class="row">
        <div style="padding-left: 1%;" class="col-md-6 col-lg-6 col-xs-12">
          <label class="group-title inline-block" id="Chart"><span>Consolidated Report</span><span class="gray"> **</span></label>
        </div>
        <div class="col-md-6 col-lg-6 col-xs-12 text-right gray pt-3">
          <span style="font-size: 11px;">** Consolidated report is available in English only.</span>
        </div>
      </div>
      <div class="row mrbottomten" *ngIf="notActiveSurvey == 0 && notReleasedSurveyInLanguage == 0">
        <div class="col-md-12 col-lg-3 col-xs-12">
          <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': dashboardAddObject.section.length <= 0  && runReportForm.submitted }">
            <label><span i18n="@@sectionName">Section Name </span> <span class="mandatory"> *</span></label>
            <!--<select class="form-control" (change)="selectSection($event,section_name)" [(ngModel)]="dashboardAddObject.section" name="section_name"  #section_name="ngModel" [ngClass]="(dashboardAddObject.section == 0 ? 'ng-invalid':'ng-valid')" onchange="this.setAttribute('required','')" required>
              <option value="0" class="option" i18n="@@select">Select</option>
              <option *ngFor="let list of listsSectionsSubSection; trackBy: trackBySectionName" [ngValue]="list.sectionId">{{list.section}}</option>
            </select> -->            
            <ng-multiselect-dropdown
              [placeholder]="selectPlaceholder"
              [data]="listsSectionsSubSection"
              [(ngModel)]="dashboardAddObject.section"
              [settings]="dropdownSettings"
              [ngModelOptions]="{standalone: true}"
              (onSelect)="selectSection($event)"
              (onDeSelect)="selectSection($event)"
              name="section_name"  #section_name="ngModel" required
              onchange="this.setAttribute('required','')" [ngClass]="(dashboardAddObject.section.length <= 0 && runReportForm.submitted  ? 'ng-invalid':'ng-valid')"
            >
            </ng-multiselect-dropdown>
            <div class="error" *ngIf="dashboardAddObject.section.length <= 0  && runReportForm.submitted ">
              <span i18n="@@enterValidInput">Enter valid input.</span>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3 col-xs-12">
          <div class="form-group no-margin-hr">
            <label i18n="@@subSectionName">Sub section Name </label>
            <ng-multiselect-dropdown
              [placeholder]="selectPlaceholder"
              [data]="newSubSection"
              [(ngModel)]="dashboardAddObject.subsection"
              [settings]="dropdownSettings1"
              [ngModelOptions]="{standalone: true}"
              (onSelectAll)="onSelectAllChart($event)"
              #sub_section_name="ngModel" name="sub_section_name"               
            >
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xs-12 fltRgt datatable-header slidedownsearch">
          <label></label>
          <div class="form-group runreport-btns">
            <button type="button" name="button" class="runBtn btn btn-primary btn-lg mr-3" (click)="genarateReports()" [disabled]="!selectedTab">
              <span>Load Questions</span>
            </button>
            <button type="button" name="button" class="btn btn-default-gray btn-lg"  (click)="clearAll()">
              <span>Clear</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row mrbottomten" *ngIf="notActiveSurvey == 1">
        <div class="col-md-12 col-lg-6 col-xs-12 slidedownsearch pt-4">
          <label>No active survey found.</label>
        </div>
        <div class="col-md-12 col-lg-6 col-xs-12 fltRgt datatable-header slidedownsearch">
          <div class="form-group runreport-btns">
            <button type="button" name="button" class="btn btn-default-gray btn-lg"  (click)="cancel()">
              <span i18n="@@cancel">Cancel</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row mrbottomten" *ngIf="notReleasedSurveyInLanguage == 1">
        <div class="col-md-12 col-lg-6 col-xs-12 slidedownsearch pt-4">
          <label i18n="@@surveyNotReleasedCurrentLanguage">Survey is not released in current language</label>
        </div>
        <div class="col-md-12 col-lg-6 col-xs-12 fltRgt datatable-header slidedownsearch">
          <div class="form-group runreport-btns">
            <button type="button" name="button" class="btn btn-default-gray btn-lg"  (click)="cancel()">
              <span i18n="@@cancel">Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="border-top pt-4" *ngIf="questionAndType.length > 0">
      <div class="form-group fltRgt datatable-header">
        <span *ngIf="isOnline | async; else offlineIndicator2">
          <button *ngIf="questionAndType.length>0 && selectedQuestions.length > 0" i18n-title="@@exportToExcel" title="Export to Excel" (click)="exportConsolidatedReport(confirmTypeModal);$event.stopPropagation()" type="button"
            name="button" class="btn btn-success"> <span i18n="@@exportToExcel">Export to Excel</span> </button>
        </span>
        <ng-template #offlineIndicator2>
          <span>
            <button *ngIf="questionAndType.length>0 && selectedQuestions.length > 0" i18n-title="@@exportToExcel" title="Export to Excel" [disabled]="true" (click)="exportFarmers();$event.stopPropagation()" type="button"
              name="button" class="btn btn-success"> <span i18n="@@exportToExcel" >Export to Excel</span> </button>
          </span>
        </ng-template>
        <span *ngIf="isOnline | async; else offlineIndicator3">
          <button title="Reset to Default consolidation type" (click)="resetToDefault(confirmDefaultModal);$event.stopPropagation()" type="button"
            name="button" class="btn btn-success"> <span>Reset to Default</span> </button>
        </span>
        <ng-template #offlineIndicator3>
          <span>
            <button title="Reset to Default consolidation type"  [disabled]="true" type="button"
              name="button" class="btn btn-success"> <span>Reset to Default</span> </button>
          </span>
        </ng-template>
        <span *ngIf="isOnline | async;">
          <button title="Select all consolidation type" (click)="selectAllConsTypes();$event.stopPropagation()" type="button"
            name="button" class="btn btn-success"> <span>Select All Types</span> </button>
        </span>
        <input type="text" [(ngModel)]="searchText" placeholder="Search Questions" class="form-control" style="width: initial;height: 40px;display: inline-block"
          i18n-placeholder="@@search">
        <select class="form-control" [(ngModel)]="itemsPerPage" style="display: inline; width: fit-content; margin-top: 14px; margin-left: 10px;">
          <option class="option" value="10">10</option>
          <option class="option" value="20">20</option>
          <option class="option" value="50">50</option>
        </select>
      </div>
      <div class="clearfix"></div>
      <div style="overflow-x: auto">
        <!-- *ngIf="farmers.length > 0" -->
        <form (keydown)="keyDownFunction($event)">
          <table class="table table-sm table-hover table-one-line" role="grid">
            <thead>
              <tr role="row">
                <th style="width:2%" scope="col">
                  <span class="form-check" style="display: inline;">
                    <input type="checkbox" class="form-check-input" [checked]="questionAndType.length == selectedQuestions.length && questionAndType.length !=0"
                      (change)="toggleAllFarmers();$event.stopPropagation()" [tooltip]="tooltipText"
                      container="body" placement="right">
                    <label class="form-check-label"> &nbsp;</label>
                  </span>
                </th>
                <th (click)="sortBy('label')" style="width:50%" scope="col"><span>Question </span>
                  
                  <img class="sorting-arrow" alt=""  *ngIf="isAscendingName" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSwxOTcuMjg3TDE1OS4wMjgsNjkuMzgxYy0zLjYxMy0zLjYxNy03Ljg5NS01LjQyNC0xMi44NDctNS40MjRzLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0TDUuNDI0LDE5Ny4yODcgICBDMS44MDcsMjAwLjkwNCwwLDIwNS4xODYsMCwyMTAuMTM0czEuODA3LDkuMjMzLDUuNDI0LDEyLjg0N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyNSwxMi44NSw1LjQyNWgyNTUuODEzICAgYzQuOTQ5LDAsOS4yMzMtMS44MDgsMTIuODQ4LTUuNDI1YzMuNjEzLTMuNjEzLDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N1MyOTAuNTQ4LDIwMC45MDQsMjg2LjkzNSwxOTcuMjg3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg=="
                  />
                  <img class="sorting-arrow" alt=""  *ngIf="!isAscendingName" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSw2OS4zNzdjLTMuNjE0LTMuNjE3LTcuODk4LTUuNDI0LTEyLjg0OC01LjQyNEgxOC4yNzRjLTQuOTUyLDAtOS4yMzMsMS44MDctMTIuODUsNS40MjQgICBDMS44MDcsNzIuOTk4LDAsNzcuMjc5LDAsODIuMjI4YzAsNC45NDgsMS44MDcsOS4yMjksNS40MjQsMTIuODQ3bDEyNy45MDcsMTI3LjkwN2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyOCwxMi44NSw1LjQyOCAgIHM5LjIzMy0xLjgxMSwxMi44NDctNS40MjhMMjg2LjkzNSw5NS4wNzRjMy42MTMtMy42MTcsNS40MjctNy44OTgsNS40MjctMTIuODQ3QzI5Mi4zNjIsNzcuMjc5LDI5MC41NDgsNzIuOTk4LDI4Ni45MzUsNjkuMzc3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg=="
                  />
                </th>
                <th style="width:48%" scope="col">
                  <span>Select Consolidation Type </span>                
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="gradeA odd" *ngFor="let question of questionAndType | gridFilter: getGridFilters() | paginate: { itemsPerPage: itemsPerPage, currentPage: page }"
              [tooltip]="tooltipText2">
                <td class="sorting_1">
                  <span class="form-check" style="display: inline;">
                    <input type="checkbox" class="form-check-input" [checked]="selectedQuestions.indexOf(question.questionnaire_asset_id) > -1" (change)="toggleThisFarmerSelect(question.questionnaire_asset_id);$event.stopPropagation()">
                    <label class="form-check-label"> &nbsp;</label>
                  </span>
                </td>
                <td>{{question.label}}</td>
                <td>
                  <span *ngFor="let type of reportsTypeData" class="form-check inline-block">
                    <span *ngIf="type.q_type.indexOf(question.qa_subtype) > -1" class="inline-block pl-3 mt-0 mb-0">
                      <input type="checkbox" class="form-check-input cons-checkbox"
                      [checked] = "checkedObject[question.questionnaire_asset_id].indexOf(type.name) > -1" [attr.disabled]="question.default_cons.indexOf(type.name) > -1 ? true : null" (change)="typeChanged($event, type.name, question.questionnaire_asset_id)"> 
                      <span style="display:inline" class="form-check-label">&nbsp;</span>
                      <span style="font-size:0.78rem">{{type.name}}</span>
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
        <pagination-controls [previousLabel]="previousPaginationLabel" [nextLabel]="nextPaginationLabel" *ngIf="finalQuestionsArray && finalQuestionsArray.length>0"
          (pageChange)="page = $event" style="align-self: center;"></pagination-controls>
      </div>
    </div>
  </div>
</div>
<ng-template #confirmTypeModal>
  <div class="modal-body text-center">
    <h3 class="">
      Do you want to keep this consolidation saved for the next run?
    </h3>
    <div class="font-1 semi-bold dark-blue text-uppercase pb-2">
      Note: If you do not save additional type now your excel output will be one time extract for selected types.
    </div>
    <button type="button" title="Yes" i18n-title="@@yes" class="btn btn-secondary mr-3" (click)="confirmModalFn('Yes')" i18n="@@yes">Yes</button>
    <button type="button" title="No" i18n-title="@@no" class="btn btn-primary cancel-focus" autofocus appAutofocus (click)="confirmModalFn('No')" i18n="@@no">No</button>
  </div>
</ng-template>
<ng-template #confirmDefaultModal>
  <div class="modal-body text-center">
    <h3 class="">
      All additionally saved consolidation types will be removed from this survey.
    </h3>
    <button type="button" title="Yes" i18n-title="@@ok" class="btn btn-secondary mr-3" (click)="confirmDefaultFn()" i18n="@@ok">Ok</button>
    <button type="button" title="No" i18n-title="@@cancel" class="btn btn-primary cancel-focus" autofocus appAutofocus (click)="closeDefaultFn()" i18n="@@cancel">Cancel</button>
  </div>
</ng-template>
