import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '../../services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FarmerService } from '../../services/farmer.service';
import { CommonService } from '../../services/common.service';
import { TrainingService } from '../../services/training.service';
import { ProjectService } from '../../services/project.service';
var ProjectGroupMappingComponent = /** @class */ (function () {
    function ProjectGroupMappingComponent(route, router, translate, userService, spinner, farmerService, commonService, modalService, trainingService, projectService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.translate = translate;
        this.userService = userService;
        this.spinner = spinner;
        this.farmerService = farmerService;
        this.commonService = commonService;
        this.modalService = modalService;
        this.trainingService = trainingService;
        this.projectService = projectService;
        this.typeOfAdministration = '';
        this.currentUser = this.userService.getCurrentUser();
        this.searchText = '';
        this.loadingFarmers = false;
        this.farmers = [];
        this.selectedFarmers = [];
        this.selectedFarmersCopy = [];
        this.page = 1;
        this.itemsPerPage = 10;
        this.farmersListPage = 1;
        this.isAscending = true;
        this.isAscendingGroup = true;
        this.isAscendingProject = true;
        this.isAscendingType = true;
        this.isAscendingStatus = true;
        this.isAscendingFarmer = true;
        this.isAscendingIdFarmer = true;
        this.isAscendingNameFarmer = true;
        this.isAscendingTelFarmer = true;
        this.isAscendingDateFarmer = true;
        this.isAscendingAgeFarmer = true;
        this.isAscendingSexFarmer = true;
        this.isAscendingVillageFarmer = true;
        this.isAscendingVCFarmer = true;
        this.mappingAddObject = {
            "group": 0,
            "type": 0,
            "project": 0,
            "status": 0
        };
        this.disableAddButton = true;
        this.formIsEdited = false;
        this.uniqueGroupTypes = [];
        this.isEdit = false;
        this.route.paramMap.subscribe(function (params) {
            _this.id = params.get('id');
        });
        this.route.queryParamMap.subscribe(function (queryParams) {
            var qpObject = tslib_1.__assign({}, queryParams.keys, queryParams);
            _this.fromNavId = qpObject['params']['from'];
            _this.translate.get('typeAdminTextArr').subscribe(function (val) {
                _this.typeOfAdministrationArr = val;
                if (_this.fromNavId === '2') {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[0];
                }
                else if (_this.fromNavId === '3') {
                    _this.typeOfAdministration = _this.typeOfAdministrationArr[1];
                }
            }, function (err) { return console.log('err', err); });
        });
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
        this.translate.get('select_deselect_object').subscribe(function (result) {
            _this.selectDeselectLabels = result;
        });
    }
    ProjectGroupMappingComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.onLine) return [3 /*break*/, 2];
                        this.spinner.show();
                        this.ecosystem_id = localStorage.getItem('ecosystem_id');
                        this.getMasterData();
                        return [4 /*yield*/, Promise.all([
                                this.projectService.GetProjectsBasicInfo().toPromise(),
                                this.trainingService.getAllGroups().toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['msg'] === 'success') {
                            this.projects = request[0]['data'];
                        }
                        if (request[1]['msg'] === 'success') {
                            this.allGroups = request[1]['data'];
                        }
                        this.spinner.hide();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ProjectGroupMappingComponent.prototype.getMasterData = function () {
        var _this = this;
        this.trainingService.getProjectGroupMapping().subscribe(function (result) {
            if (result['msg'] === 'success') {
                _this.projectGroups = result['data'];
                for (var _i = 0, _a = _this.projectGroups; _i < _a.length; _i++) {
                    var item = _a[_i];
                    item.groupName = item.training_certification_group.name;
                    item.associatedProject = item.project.name;
                    item.groupType = item.training_certification_group.multivalue_master.name;
                    item.mappingStatus = item.status;
                }
            }
        });
    };
    ProjectGroupMappingComponent.prototype.editProjectGroupMapping = function (id, template) {
        this.spinner.show();
        this.isEdit = true;
        this.currentMappingID = id;
        var curMapping = this.projectGroups.find(function (item) { return item.id === id; });
        console.log('curMapping', curMapping);
        if (curMapping) {
            this.mappingAddObject.group = curMapping.training_certification_group.id;
            this.mappingAddObject.project = curMapping.project.id;
            this.mappingAddObject.type = curMapping.training_certification_group.multivalue_master.id;
            this.mappingAddObject.status = curMapping.mappingStatus;
        }
        this.addMappingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        this.spinner.hide();
    };
    ProjectGroupMappingComponent.prototype.addProjectGroupMapping = function (template) {
        this.addMappingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    ProjectGroupMappingComponent.prototype.saveProjectGroupMapping = function () {
        var _this = this;
        this.spinner.show();
        var requestParams = {
            "group_id": this.mappingAddObject.group,
            "project_id": this.mappingAddObject.project,
            "status": this.mappingAddObject.status
        };
        console.log('requestParams', requestParams);
        this.trainingService.saveProjectGroupMapping(requestParams, this.isEdit, this.currentMappingID).subscribe(function (result) {
            if (result['msg'] === 'MAPPING_CREATED' || result['msg'] === 'MAPPING_UPDATED') {
                if (result['msg'] === 'MAPPING_CREATED') {
                    _this.commonService.showToast('success', 'success_master_data_created', {});
                }
                else {
                    _this.commonService.showToast('success', 'success_master_data_updated', {});
                }
                _this.closePopUpYes();
                _this.getMasterData();
                _this.spinner.hide();
            }
        });
    };
    ProjectGroupMappingComponent.prototype.closePopUp = function (form) {
        if (!this.isEdit) {
            if (this.mappingAddObject.group != 0 || this.mappingAddObject.project != 0 || this.mappingAddObject.status != 0 || this.mappingAddObject.type != 0) {
                this.formIsEdited = true;
            }
            else {
                this.resetMappingObject();
                this.addMappingModal.hide();
            }
        }
        else {
            if (form.dirty) {
                this.formIsEdited = true;
            }
            else {
                this.resetMappingObject();
                this.addMappingModal.hide();
            }
        }
    };
    ProjectGroupMappingComponent.prototype.closePopUpYes = function () {
        this.resetMappingObject();
        this.addMappingModal.hide();
        this.formIsEdited = false;
    };
    ProjectGroupMappingComponent.prototype.closePopUpNo = function () {
        this.formIsEdited = false;
    };
    ProjectGroupMappingComponent.prototype.resetMappingObject = function () {
        this.mappingAddObject.group = 0;
        this.mappingAddObject.project = 0;
        this.mappingAddObject.type = 0;
        this.mappingAddObject.status = 0;
        this.disableAddButton = true;
        this.isEdit = false;
    };
    ProjectGroupMappingComponent.prototype.manageFarmers = function (project, template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, _i, _a, item;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.searchTextFarmers = "";
                        this.confirmFarmerModal = true;
                        if (!!this.loadingFarmers) return [3 /*break*/, 2];
                        this.spinner.show();
                        this.loadingFarmers = true;
                        this.managingFarmersForGroup = project;
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getFarmersByProjectBasicData(project.project_id).toPromise(),
                                this.trainingService.getFarmerIdsMappedToGroup(project.id).toPromise(),
                            ])];
                    case 1:
                        requests = _b.sent();
                        this.loadingFarmers = false;
                        this.spinner.hide();
                        if (requests[0]['msg'] === 'success') {
                            this.farmers = requests[0].data.filter(function (item) { return !item.is_discontinued; });
                            for (_i = 0, _a = this.farmers; _i < _a.length; _i++) {
                                item = _a[_i];
                                item.village_name = item.region_village.village_name;
                            }
                            this.sortFarmers();
                        }
                        if (requests[1]['code'] === 'success') {
                            this.selectedFarmers = requests[1].message;
                            this.selectedFarmersCopy = JSON.parse(JSON.stringify(this.selectedFarmers));
                        }
                        if (this.farmers.length === 0) {
                            this.commonService.showToast('info', 'no_farmers_regd', {});
                        }
                        else {
                            this.farmersListPage = 1;
                            this.tooltipText = undefined;
                            this.manageFarmersModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ProjectGroupMappingComponent.prototype.cancelManageFarmers = function () {
        var isEqual = false;
        for (var _i = 0, _a = this.selectedFarmers; _i < _a.length; _i++) {
            var val = _a[_i];
            if (this.selectedFarmersCopy.indexOf(val) < 0) {
                isEqual = true;
            }
        }
        if (isEqual) {
            this.confirmFarmerModal = false;
        }
        else {
            this.declineManageFarmers();
        }
    };
    ProjectGroupMappingComponent.prototype.declineManageFarmers = function () {
        this.selectedFarmers = [];
        this.farmers = [];
        this.managingFarmersForGroup = undefined;
        if (this.manageFarmersModal) {
            this.manageFarmersModal.hide();
        }
    };
    ProjectGroupMappingComponent.prototype.confirmManageFarmers = function () {
        var _this = this;
        this.trainingService.mapFarmersToGroup(this.managingFarmersForGroup.id, this.selectedFarmers)
            .subscribe(function (result) {
            console.log('result', result);
            if (result['code'] === 'success') {
                _this.commonService.showToast('success', 'changes_saved', {});
                _this.declineManageFarmers();
            }
        });
    };
    ProjectGroupMappingComponent.prototype.sortFarmers = function () {
        this.farmers.sort(function (a, b) {
            if (a.cr_date < b.cr_date) {
                return 1;
            }
            if (a.cr_date > b.cr_date) {
                return -1;
            }
            return 0;
        });
    };
    ProjectGroupMappingComponent.prototype.showAddButton = function (isGroup) {
        var _this = this;
        if (isGroup && this.mappingAddObject.group != 0) {
            var getGroupType = this.allGroups.find(function (item) { return item.id == _this.mappingAddObject.group; });
            this.mappingAddObject.type = getGroupType.multivalue_master.id;
        }
        if (this.mappingAddObject.group != 0 && this.mappingAddObject.project != 0 && this.mappingAddObject.status != 0) {
            this.disableAddButton = false;
        }
        else {
            this.disableAddButton = true;
        }
    };
    ProjectGroupMappingComponent.prototype.exportToExcel = function () {
        var _this = this;
        this.trainingService.exportToExcelPGM().subscribe(function (result) {
            console.log('result', result);
            var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
            var blob = new Blob([result], { type: contentType });
            var linkdownload = document.createElement('a');
            linkdownload.href = URL.createObjectURL(blob);
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            var today1 = dd + "-" + mm + "-" + yyyy;
            _this.translate.get('group_mapping').subscribe(function (val) {
                linkdownload.download = "P2F-Roots_" + val + "_ExcelExport_" + today1 + ".xlsx";
                linkdownload.style.display = 'none';
                document.body.appendChild(linkdownload);
                linkdownload.click();
            }, function (err) { return console.log('err', err); });
        }, function (err) { return console.log('err', err); });
    };
    // Select / deselect all farmers in the array
    ProjectGroupMappingComponent.prototype.toggleAllFarmers = function () {
        // If all farmers are already selected, then deselect all farmers
        if (this.selectedFarmers.length === this.farmers.length) {
            this.selectedFarmers = [];
        }
        // If all farmers are NOT already selected, select all the farmers
        else {
            this.selectedFarmers = [];
            for (var _i = 0, _a = this.farmers; _i < _a.length; _i++) {
                var val = _a[_i];
                this.selectedFarmers.push(val.id);
            }
        }
        this.setTooltipText();
    };
    ProjectGroupMappingComponent.prototype.setTooltipText = function () {
        if (this.farmers && this.selectedFarmers) {
            if (this.selectedFarmers.length === this.farmers.length) {
                // Deselect farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.deselect + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.deselect + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
            else {
                // Select farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.select + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.select + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
        }
    };
    // Toggle a particular farmer's selection status
    ProjectGroupMappingComponent.prototype.toggleThisFarmerSelect = function (id) {
        if (this.selectedFarmers.indexOf(id) > -1) {
            this.selectedFarmers = this.selectedFarmers.filter(function (item) { return item !== id; });
        }
        else {
            this.selectedFarmers.push(id);
        }
        this.setTooltipText();
    };
    ProjectGroupMappingComponent.prototype.confirmCloseFarmerNo = function () {
        this.confirmFarmerModal = true;
    };
    ProjectGroupMappingComponent.prototype.onKeydownHandler = function (event) {
        if (event.key === "Escape") {
            if (this.confirmFarmerModal) {
                this.cancelManageFarmers();
            }
            else {
                this.confirmCloseFarmerNo();
            }
            if (this.addMappingModal) {
                this.closePopUp();
            }
        }
    };
    ProjectGroupMappingComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    ProjectGroupMappingComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        if (sortBy === 'groupname') {
            this.isAscendingGroup = false;
            this.isAscendingProject = true;
            this.isAscendingType = true;
            this.isAscendingStatus = true;
            this.projectGroups.sort(function (a, b) {
                if (a.groupName.toLowerCase() < b.groupName.toLowerCase()) {
                    return 1;
                }
                if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'associatedproject') {
            this.isAscendingGroup = true;
            this.isAscendingProject = false;
            this.isAscendingType = true;
            this.isAscendingStatus = true;
            this.projectGroups.sort(function (a, b) {
                if (a.associatedProject.toLowerCase() < b.associatedProject.toLowerCase()) {
                    return 1;
                }
                if (a.associatedProject.toLowerCase() > b.associatedProject.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'grouptype') {
            this.isAscendingGroup = true;
            this.isAscendingProject = true;
            this.isAscendingType = false;
            this.isAscendingStatus = true;
            this.projectGroups.sort(function (a, b) {
                if (a.groupType.toLowerCase() < b.groupType.toLowerCase()) {
                    return 1;
                }
                if (a.groupType.toLowerCase() > b.groupType.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'mappingstatus') {
            this.isAscendingGroup = true;
            this.isAscendingProject = true;
            this.isAscendingType = true;
            this.isAscendingStatus = false;
            this.projectGroups.sort(function (a, b) {
                if (a.mappingStatus.toLowerCase() < b.mappingStatus.toLowerCase()) {
                    return 1;
                }
                if (a.mappingStatus.toLowerCase() > b.mappingStatus.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    ProjectGroupMappingComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        if (sortBy === 'groupname') {
            this.isAscendingGroup = true;
            this.isAscendingProject = false;
            this.isAscendingType = false;
            this.isAscendingStatus = false;
            this.projectGroups.sort(function (a, b) {
                if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) {
                    return 1;
                }
                if (a.groupName.toLowerCase() < b.groupName.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'associatedproject') {
            this.isAscendingGroup = false;
            this.isAscendingProject = true;
            this.isAscendingType = false;
            this.isAscendingStatus = false;
            this.projectGroups.sort(function (a, b) {
                if (a.associatedProject.toLowerCase() > b.associatedProject.toLowerCase()) {
                    return 1;
                }
                if (a.associatedProject.toLowerCase() < b.associatedProject.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'grouptype') {
            this.isAscendingGroup = false;
            this.isAscendingProject = false;
            this.isAscendingType = true;
            this.isAscendingStatus = false;
            this.projectGroups.sort(function (a, b) {
                if (a.groupType.toLowerCase() > b.groupType.toLowerCase()) {
                    return 1;
                }
                if (a.groupType.toLowerCase() < b.groupType.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'mappingstatus') {
            this.isAscendingGroup = false;
            this.isAscendingProject = false;
            this.isAscendingType = false;
            this.isAscendingStatus = true;
            this.projectGroups.sort(function (a, b) {
                if (a.mappingStatus.toLowerCase() > b.mappingStatus.toLowerCase()) {
                    return 1;
                }
                if (a.mappingStatus.toLowerCase() < b.mappingStatus.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    ProjectGroupMappingComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['groupName', 'associatedProject', 'groupType', 'mappingStatus'];
        var finalObj = {};
        if (this.projectGroups === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    ProjectGroupMappingComponent.prototype.farmerSortBy = function (sortOn) {
        this.isAscendingFarmer ? this.ascendicFarmer(sortOn) : this.descendicFarmer(sortOn);
    };
    ProjectGroupMappingComponent.prototype.ascendicFarmer = function (sortBy) {
        this.isAscendingFarmer = false;
        this.isAscendingIdFarmer = sortBy !== 'id';
        this.isAscendingNameFarmer = sortBy !== 'name';
        this.isAscendingTelFarmer = sortBy !== 'official_id';
        this.isAscendingDateFarmer = sortBy !== 'cr_date';
        this.isAscendingAgeFarmer = sortBy !== 'age';
        this.isAscendingSexFarmer = sortBy !== 'sex';
        // Sort of new fields added
        this.isAscendingVillageFarmer = sortBy !== 'village_name';
        this.isAscendingVCFarmer = sortBy === 'village_collectors';
        // Sort of new fields added
        if (sortBy === 'name') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                    return 1;
                }
                if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'village_name') {
            this.farmers.sort(function (a, b) {
                if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
                    return 1;
                }
                if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    ProjectGroupMappingComponent.prototype.descendicFarmer = function (sortBy) {
        this.isAscendingFarmer = true;
        this.isAscendingIdFarmer = sortBy === 'id';
        this.isAscendingNameFarmer = sortBy === 'name';
        this.isAscendingTelFarmer = sortBy === 'official_id';
        this.isAscendingDateFarmer = sortBy === 'cr_date';
        this.isAscendingAgeFarmer = sortBy === 'age';
        this.isAscendingSexFarmer = sortBy === 'sex';
        // Sort of new fields added
        this.isAscendingVillageFarmer = sortBy === 'village_name';
        this.isAscendingVCFarmer = sortBy === 'village_collectors';
        // Sort of new fields added
        if (sortBy === 'name') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                    return 1;
                }
                if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'village_name') {
            this.farmers.sort(function (a, b) {
                if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
                    return 1;
                }
                if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    ProjectGroupMappingComponent.prototype.getGridFiltersFarmers = function () {
        var _this = this;
        var gridColums = ['farmer_code', 'name', 'official_id', 'age', 'village_name', 'village_collectors', 'cr_date'];
        var finalObj = {};
        if (this.farmers === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchTextFarmers;
            });
        }
        return finalObj;
    };
    ProjectGroupMappingComponent.prototype.trackByGroupName = function (index, dataItem) {
        return dataItem.groupName;
    };
    ProjectGroupMappingComponent.prototype.trackByProjectName = function (index, dataItem) {
        return dataItem.groupName;
    };
    ProjectGroupMappingComponent.prototype.trackByTypeName = function (index, dataItem) {
        return dataItem.groupName;
    };
    ProjectGroupMappingComponent.prototype.trackByStatusName = function (index, dataItem) {
        return dataItem.groupName;
    };
    return ProjectGroupMappingComponent;
}());
export { ProjectGroupMappingComponent };
