<app-header></app-header>
<div class="project-header clearfix">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="getBackProjectFilter(project?.name, project?.id)" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item active cursorPointer " aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item active">
        <span i18n="@@campaignProfiling">Campaign Profiling</span>
      </li>
    </ol>
  </nav>
</div>
<div class="page-wrapper">
  <div class="content-wrap content-wrapper user-listing background">
    <div class="form-design form-box">
      <label class="group-title inline-block" i18n="@@campaignProfiling">Campaign Profiling</label>
      <div class="col-md-7 form-group fltRgt datatable-header">
        <input type="text" [(ngModel)]="searchText" placeholder="Search campaigns" i18n-placeholder="@@searchCampaigns" class="form-control" style="width: initial;height: 40px;display: inline-block">
      </div>
      <div class="clearfix"></div>
        <div *ngIf="loadedCampaigns">
          <div *ngIf="campaigns && campaigns.length > 0; else noCampaigns" class="row">
            <div class="col-lg-3 col-md-3 clearfix" *ngFor="let campaign of campaigns | gridFilter: getGridFilters()">
              <div class="dashboard-card-wrapper" (click)="goToCampaignProfiling(campaign.id); $event.stopPropagation()">
                <div class="dashboard-card-text">
                  {{ campaign.name }}
                </div>
                <div class="text-center" [attr.title]="campaign.description">
                  <span class="ellipsis" style="width:100%">{{ campaign.description }}</span>
                </div>
                <div class="dashboard-card-label">
                  <img *ngIf="campaign.closed_date" src="assets/images/campaign-closed.svg" alt="System" />
                </div>
              </div>
            </div>
          </div>
          <ng-template #noCampaigns>
            <p i18n="@@noCampaignCreated">No Campaign created</p>
          </ng-template>
        </div>
    </div>
  </div>
</div>