import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
var parentScope01;
var CollectionNetworkComponent = /** @class */ (function () {
    function CollectionNetworkComponent(route, projectService, router, activeRoute, hcRService, spinner, renderer) {
        this.route = route;
        this.projectService = projectService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.hcRService = hcRService;
        this.spinner = spinner;
        this.renderer = renderer;
        this.projects = [];
        this.projectId = 0;
        this.dashboardData = [];
        this.reportIDs = [];
        this.predefinedReports = [];
    }
    CollectionNetworkComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dashboardId = this.route.snapshot.params['id2'];
        this.projectId = this.route.snapshot.params['id'];
        this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
            _this.project = response.data;
            var projectObject = _this.project.filter(function (item) { return item.id == _this.projectId; })[0];
            _this.project = projectObject;
        });
        var requestObject = {
            //"dashboardId":this.dashboardId
            "dashboardId": this.dashboardId
        };
        this.hcRService.getDashboardDetailsById(requestObject).subscribe(function (response) {
            //console.log("get Dashboard Data ", response);
            if (response.msg == 'success') {
                _this.dashboardData = response.data;
                if (_this.dashboardData.length > 0) {
                    //console.log("this.dashboardData ", this.dashboardData);
                    var dashboardSelectedReports = [];
                    for (var i = 0; i < _this.dashboardData[0].hc_report_dashboard_dtl.length; i++) {
                        dashboardSelectedReports.push(_this.dashboardData[0].hc_report_dashboard_dtl[i]);
                        //this.dashboardEditObject.section = dashboardData[0].hc_report_dashboard_dtl[0].section;
                    }
                    //console.log("dashboardSelectedReports ", dashboardSelectedReports);
                    if (dashboardSelectedReports && dashboardSelectedReports.length > 0) {
                        for (var i = 0; i < dashboardSelectedReports.length; i++) {
                            _this.reportIDs.push(dashboardSelectedReports[i].report_id);
                        }
                        //console.log(" this.reportIDs ", this.reportIDs);
                        if (_this.reportIDs && _this.reportIDs.length > 0) {
                            _this.genarateCharts(_this.reportIDs);
                        }
                    }
                }
            }
        });
    };
    CollectionNetworkComponent.prototype.genarateCharts = function (reportIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requestObject, chartsList;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.spinner.show();
                requestObject = {};
                requestObject = {
                    "reportID": reportIds,
                    "projectId": this.projectId
                };
                chartsList = [];
                this.hcRService.getReports(requestObject).subscribe(function (response) {
                    //console.log("response ", response);
                    if (response.msg == 'success') {
                        var reportsData = response.data;
                        _this.predefinedReports = response.data;
                        //console.log("reportsData ", this.predefinedReports);
                        if (reportsData && reportsData.length > 0) {
                            var _loop_1 = function (i) {
                                if (reportsData[i].reportId == 150) {
                                    var option = reportsData[i].conf[0].conf;
                                    var series_1 = reportsData[i].series.data;
                                    option.title.style = {
                                        fontSize: "18px",
                                        fontWeight: '500'
                                    };
                                    //option.series.data = series;
                                    option.xAxis.categories = reportsData[i].series.categories;
                                    option.series.forEach(function (item, i) {
                                        item.data = series_1[i].data;
                                        if (series_1[i].name == 'avg/farmer') {
                                            item.name = 'Average per Farmer';
                                        }
                                        if (series_1[i].name == 'avg/field') {
                                            item.name = 'Average per Field';
                                        }
                                    });
                                    var contextMenuOptions = [];
                                    contextMenuOptions = [
                                        'viewFullscreen',
                                        {
                                            textKey: 'downloadPDF',
                                            onclick: function () {
                                                this.exportChart({
                                                    type: 'application/pdf'
                                                });
                                            }
                                        },
                                        {
                                            textKey: 'downloadPNG',
                                            onclick: function () {
                                                this.exportChart();
                                            }
                                        },
                                        {
                                            textKey: 'downloadXLS',
                                            onclick: function () {
                                                this.downloadXLS();
                                            }
                                        }
                                    ];
                                    option.exporting.buttons.contextButton.menuItems = contextMenuOptions;
                                    var g = document.createElement("div");
                                    var newElement1 = _this.chartElement01.nativeElement.appendChild(g);
                                    _this.renderer.addClass(newElement1, 'height100');
                                    _this.hcRService.createChart(newElement1, option);
                                }
                            };
                            for (var i = 0; i < reportsData.length; i++) {
                                _loop_1(i);
                            }
                        }
                        _this.spinner.hide();
                    }
                    else {
                        _this.spinner.hide();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    CollectionNetworkComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    CollectionNetworkComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    CollectionNetworkComponent.prototype.goToDashboards = function () {
        var url = "charts/dashboards/" + this.projectId;
        this.router.navigate([url]);
    };
    return CollectionNetworkComponent;
}());
export { CollectionNetworkComponent };
