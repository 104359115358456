import * as tslib_1 from "tslib";
import { TemplateRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, of, Subject } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { TourService } from 'ngx-tour-ngx-bootstrap';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';
import { LoginService } from '../../services/login.service';
import { CommonService } from '../../services/common.service';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { isPlatformBrowser } from '@angular/common';
import { ProjectService } from '../../services/project.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(authenticationService, router, userService, loginService, formBuilder, commonService, storageService, location, platformId, ref, toastrService, tourService, modalService, projectService, translate) {
        var _this = this;
        this.authenticationService = authenticationService;
        this.router = router;
        this.userService = userService;
        this.loginService = loginService;
        this.formBuilder = formBuilder;
        this.commonService = commonService;
        this.storageService = storageService;
        this.location = location;
        this.platformId = platformId;
        this.ref = ref;
        this.toastrService = toastrService;
        this.tourService = tourService;
        this.modalService = modalService;
        this.projectService = projectService;
        this.translate = translate;
        this.isFieldAgent = false;
        this.isManager = false;
        this.isDeeproots = false;
        this.supportType = 'technical_support';
        this.unsubscribe = new Subject();
        this.alias = '';
        this.sidebarOpen = false;
        this.showBackdrop = false;
        this.showPrompt = false;
        this.navItems = [];
        this.router.events.subscribe(function (val) {
            _this.activeNavItem = location.path();
        });
        this.online$ = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.tourService.events$.subscribe(function (event) {
        });
        this.translate.get('file_upload_component_default_txt').subscribe(function (result) {
            _this.noFileChosenDefault = result; // default text for the file input component
        });
        this.translate.get('not_available_when_offline').subscribe(function (result) {
            _this.notAvailableWhenOfflineTxt = result; // default text for the file input component
        });
    }
    HeaderComponent.prototype.onKeydownHandler = function (event, quickConnectForm) {
        this.declineModal(this.quickConnectForm);
    };
    HeaderComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, requests, navItemsData, i;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        window.scrollTo(0, 0);
                        this.currentUser = this.userService.getCurrentUser();
                        _a = this;
                        return [4 /*yield*/, idbApp.getDataCount('farmerstmp')];
                    case 1:
                        _a.indexedDBDataCount = _b.sent();
                        this.quickConnectForm = this.formBuilder.group({
                            support_type: new FormControl('technical_support', [Validators.required]),
                            message: new FormControl('', [Validators.required]),
                            project_id: new FormControl(null),
                            attachment: new FormControl('')
                        });
                        return [4 /*yield*/, Promise.all([
                                this.userService.getNavItemsForUserType().toPromise(),
                            ])];
                    case 2:
                        requests = _b.sent();
                        navItemsData = requests[0].data;
                        for (i = 0; i < navItemsData.length; i++) {
                            if (navItemsData[i]['NavigationControl']['status'] == 'Active') {
                                this.navItems.push(navItemsData[i]['NavigationControl']);
                            }
                        }
                        if (this.currentUser.role.id === 2 || this.currentUser.role.id == 3) {
                            this.ecosystemName = localStorage.getItem('ecosystem_name');
                        }
                        this.commonService.projectListObs.subscribe(function (value) {
                            _this.projectsList = value;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.handleFileInput = function (file) {
        var fileItem = file.item(0);
        var msg = this.commonService.ValidateFile(fileItem);
        if (msg.length > 0) {
            this.commonService.showToast('warning', 'generic_error', {});
            return;
        }
        this.fileToUpload = fileItem;
        var reader = new FileReader();
        reader.onload = function (event) {
        };
        reader.readAsDataURL(this.fileToUpload);
    };
    HeaderComponent.prototype.supportTypeChangeFun = function (currentVal) {
        this.supportType = currentVal;
        if (currentVal === 'technical_support') {
            this.quickConnectForm.patchValue({
                project_id: 0
            });
        }
    };
    HeaderComponent.prototype.submitSupportForm = function (form) {
        var _this = this;
        var support_type = this.quickConnectForm['value'].support_type;
        var message = this.quickConnectForm['value'].message;
        var project_id = this.quickConnectForm['value'].project_id;
        if (!support_type || support_type.length == 0 || !message || message.length == 0) {
            return;
        }
        if (project_id == 0) {
            project_id = null;
        }
        var contactSupport = {
            support_type: support_type,
            message: message,
            attachment: null,
            project_id: project_id
        };
        if (navigator.onLine) {
            if (this.fileToUpload) {
                var formData = new FormData();
                formData.append('type', 'farmerAvatar');
                formData.append('avatar', this.fileToUpload, this.fileToUpload.name);
                this.projectService.uploadImageContactSupport(formData)
                    .subscribe(function (obj) {
                    if (obj.msg === 'success') {
                        contactSupport['attachment'] = obj.data;
                        _this.commonService.contactSupport(contactSupport).subscribe(function (result) {
                            if (_this.quickConnectModal) {
                                _this.quickConnectModal.hide();
                            }
                            if (result.msg === 'success') {
                                if (_this.quickConnectModal) {
                                    _this.quickConnectModal.hide();
                                }
                                _this.quickConnectForm.setValue({
                                    support_type: 'technical_support',
                                    message: '',
                                    attachment: '',
                                    project_id: null
                                });
                                _this.fileToUpload = '';
                                _this.supportType = 'technical_support';
                                _this.quickConnectForm.markAsPristine();
                            }
                            else {
                                _this.toastrService.error(result.msgText);
                            }
                        });
                    }
                });
            }
            else {
                this.commonService.contactSupport(contactSupport).subscribe(function (result) {
                    if (_this.quickConnectModal) {
                        _this.quickConnectModal.hide();
                    }
                    if (result.msg === 'success') {
                        if (_this.quickConnectModal) {
                            _this.quickConnectModal.hide();
                        }
                        _this.quickConnectForm.setValue({
                            support_type: 'technical_support',
                            message: '',
                            attachment: '',
                            project_id: null
                        });
                        _this.toastrService.success(result.msgText);
                        _this.fileToUpload = '';
                        _this.supportType = 'technical_support';
                        _this.quickConnectForm.markAsPristine();
                    }
                    else {
                        _this.toastrService.error(result.msgText);
                    }
                });
            }
        }
    };
    HeaderComponent.prototype.startTour = function () {
        this.sidebarOpen = false;
        this.tourService.start();
    };
    HeaderComponent.prototype.logout = function () {
        if (navigator.onLine) {
            this.confirmModal = this.modalService.show(this.confirmLogout, { backdrop: true, ignoreBackdropClick: true });
        }
        else {
            this.closeModal(false);
        }
    };
    HeaderComponent.prototype.closeModal = function (isOkay) {
        this.confirmModal.hide();
        if (isOkay) {
            this.blockToken();
        }
    };
    HeaderComponent.prototype.blockToken = function () {
        var _this = this;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.commonService.blockToken({ 'token': currentUser.token }).subscribe(function (response) {
            var responseData = response;
            if (responseData.msg === 'success') {
                // If online and  PM only then remove token
                // Dive into the service functions - the TOKEN is not removed even for manager
                if (isPlatformBrowser(_this.platformId)) {
                    if (navigator.onLine) {
                        _this.authenticationService.removeToken();
                        _this.authenticationService.removeTokenHeader();
                        localStorage.removeItem('ecosystem_id');
                        localStorage.removeItem('ecosystem_name');
                    }
                }
                _this.storageService.setLocalStorage('lg', '1');
                _this.router.navigate(['/']);
            }
            else {
                _this.commonService.showToast('error', responseData.code, {});
            }
        });
    };
    HeaderComponent.prototype.goTo = function (where, id) {
        if (id === 2 || id === 3) {
            this.router.navigate(['/ecosystems']);
        }
        else {
            if (where === "dashboard" || where === "register") {
                this.router.navigate(["/" + where]);
            }
            else {
                if (navigator.onLine) {
                    this.router.navigate(["/" + where]);
                }
                else {
                    if (where === 'setup') {
                        this.commonService.showToast('warning', 'offline_project_warning', {});
                    }
                    else if (where === 'userlisting') {
                        this.commonService.showToast('warning', 'offline_user_mgmt_warning', {});
                    }
                    else if (where === 'charts') {
                        this.commonService.showToast('warning', 'offline_charts_warning', {});
                    }
                }
            }
        }
    };
    HeaderComponent.prototype.clickOnProjectHome = function () {
        this.commonService.setSearchToBlank();
    };
    HeaderComponent.prototype.goToNew = function (nav) {
        if (nav.click_action === 'router') {
            if (nav.angular_component === "/dashboard") {
                this.router.navigate([nav.angular_component]);
                this.clickOnProjectHome();
            }
            else {
                if (navigator.onLine) {
                    if (!nav.populate_dynamic_content) {
                        var url = nav.angular_component;
                        this.router.navigate([url]);
                    }
                    else {
                        var url = "/goto/" + nav.id;
                        this.router.navigate([url]);
                    }
                }
                else {
                    this.toastrService.warning(this.notAvailableWhenOfflineTxt);
                }
            }
        }
    };
    HeaderComponent.prototype.updateSyncStatusShown = function (args) {
        this.indexedDBDataCount = 0;
    };
    HeaderComponent.prototype.dataAdded = function (args) {
        this.indexedDBDataCount++;
    };
    HeaderComponent.prototype.errorCaching = function (args) {
        this.commonService.showToast('error', 'error_caching', { disableTimeout: true });
    };
    HeaderComponent.prototype.quickConnectModalFn = function (template) {
        this.showPrompt = false;
        this.quickConnectModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    HeaderComponent.prototype.declineModal = function (form) {
        if (form.dirty) {
            this.showPrompt = true;
        }
        else {
            if (this.quickConnectModal) {
                this.quickConnectModal.hide();
            }
        }
    };
    HeaderComponent.prototype.confirmModalFn = function (what) {
        if (what === 'Yes') {
            if (this.quickConnectModal) {
                this.quickConnectModal.hide();
            }
            this.quickConnectForm.setValue({
                support_type: '',
                message: '',
                attachment: '',
                project_id: null
            });
            this.supportType = 'technical_support';
            this.quickConnectForm.markAsPristine();
        }
        else {
            this.showPrompt = false;
        }
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    return HeaderComponent;
}());
export { HeaderComponent };
