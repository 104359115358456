import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingService } from '../../services/training.service';
import { ProjectService } from '../../services/project.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '../../services/user.service';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import Compressor from 'compressorjs';
var FarmerTrainingDetailsComponent = /** @class */ (function () {
    function FarmerTrainingDetailsComponent(_route, commonService, toastrService, formBuilder, modalService, projectService, trainingService, spinner, userService, router) {
        this._route = _route;
        this.commonService = commonService;
        this.toastrService = toastrService;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.projectService = projectService;
        this.trainingService = trainingService;
        this.spinner = spinner;
        this.userService = userService;
        this.router = router;
        this.isTrainingOpen = false;
        this.isTrainingPopupOpen = false;
        this.isCertificateOpen = false;
        this.isCertificatePopupOpen = false;
        this.isExpiryOpen = false;
        this.isExpiryPopupOpen = false;
        this.dateFormat = 'DD-MMM-YYYY';
        this.uploadedTrainingFiles = [];
        this.farmerTrainings = [];
        this.trainers = [];
        this.currentUser = this.userService.getCurrentUser();
        this.currentTab = [];
        this.uploadedTrainingFilesOld = [];
        this.uploadedNewTrainingFiles = [];
        this.confirmModalPopup = true;
        this.subject = new Subject();
        this.disablePopupAddButton = true;
        this.confirmAddModal = true;
        this.maxDate = new Date();
        this.minDate = new Date();
        this.popupCertiAddButton = true;
    }
    FarmerTrainingDetailsComponent.prototype.ngOnChanges = function () {
        /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'isCertificate'**************/
        //Write your code here    
        console.log('certication', this.isCertificate);
        this.farmerId = +this._route.snapshot.params['id'] || 0;
        this.projectId = +this._route.snapshot.params['projectId'];
        this.getTrainingCertifcationList();
    };
    FarmerTrainingDetailsComponent.prototype.ngOnInit = function () {
        this.minDate.setDate(this.minDate.getDate() + 1);
        this.createTrainingForm();
    };
    FarmerTrainingDetailsComponent.prototype.getTrainingCertifcationList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, userObj;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.trainingService.get_training_recordings_farmer(this.projectId, this.farmerId).toPromise(),
                            this.trainingService.get_all_trainer(this.projectId).toPromise(),
                            this.trainingService.getAllGroups(this.projectId).toPromise(),
                            this.trainingService.get_all_training_certification(this.projectId).toPromise()
                        ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['msg'] === 'success') {
                            this.trainingCertifications = request[0]['data'];
                        }
                        if (this.currentUser.role.id <= 3) {
                            if (request[1]['msg'] === 'success') {
                                this.trainers = request[1]['data'];
                            }
                        }
                        else {
                            userObj = {
                                users_id: this.currentUser.id,
                                users: {
                                    name: this.currentUser.name
                                }
                            };
                            this.trainers.push(userObj);
                        }
                        if (request[2]['msg'] === 'success') {
                            this.allGroups = request[2]['data'];
                        }
                        if (request[3]['msg'] === 'success') {
                            this.allTrainingCertifications = request[3]['data'];
                        }
                        this.projectTrainings = this.allTrainingCertifications.filter(function (item) {
                            return item.training_certification_master.training_certification_type == 'Training' && item.training_certification_master.status == 'Active';
                        });
                        this.projectCertifications = this.allTrainingCertifications.filter(function (item) {
                            return item.training_certification_master.training_certification_type == 'Certification' && item.training_certification_master.status == 'Active';
                        });
                        this.trainings = this.trainingCertifications.filter(function (item) { return item.training_certification_type == 'Training'; });
                        this.certifications = this.trainingCertifications.filter(function (item) { return item.training_certification_type == 'Certification'; });
                        if (this.isCertificate) {
                            this.currentTab = this.certifications;
                        }
                        else {
                            this.currentTab = this.trainings;
                        }
                        this.initialize(0);
                        return [2 /*return*/];
                }
            });
        });
    };
    FarmerTrainingDetailsComponent.prototype.initialize = function (index) {
        this.currentSectionIndex = index;
        this.subtabClicked(index);
    };
    FarmerTrainingDetailsComponent.prototype.subtabClicked = function (index, newGroup) {
        var _this = this;
        if (this.currentTab.length > 0) {
            this.spinner.show();
            this.currentSectionIndex = index;
            var headerID = this.currentTab[index].recording_hdr_id;
            var type = void 0;
            if (this.isCertificate) {
                type = 'certification';
            }
            else {
                type = 'training';
            }
            this.trainingService.getIndividualTrainingCertificationDetails(this.projectId, this.farmerId, headerID, type).subscribe(function (result) {
                if (result['msg'] === 'success') {
                    _this.currentSection = result['data'];
                    if (_this.isCertificate) {
                        _this.editCertificationForm();
                    }
                    else {
                        _this.editTrainingForm();
                    }
                }
            });
        }
    };
    FarmerTrainingDetailsComponent.prototype.navigateSection = function (direction) {
        var index = this.currentSectionIndex + direction;
        this.subtabClicked(index);
    };
    FarmerTrainingDetailsComponent.prototype.createTrainingForm = function () {
        this.groundForm = this.formBuilder.group({
            trainingName: new FormControl('0', [Validators.required]),
            description: new FormControl('', [Validators.maxLength(1000)]),
            trainerName: new FormControl('0'),
            trainingDate: new FormControl(null),
            group: new FormControl('0'),
            trainingAttended: new FormControl(''),
            spouse: new FormControl(''),
            children: new FormControl(''),
            certificateName: new FormControl('0', [Validators.required]),
            certificationDate: new FormControl(null),
            expiryDate: new FormControl(null),
            certified: new FormControl(null),
        });
    };
    FarmerTrainingDetailsComponent.prototype.editTrainingForm = function () {
        var obj = {
            trainingName: this.currentSection.project_training_certification_mapping_id,
            description: this.currentSection.description,
            trainerName: this.currentSection.trainer_id,
            trainingDate: new Date(this.currentSection.training_date),
            group: this.currentSection.training_recording_dtl[0].project_group_actor_mapping ?
                this.currentSection.training_recording_dtl[0].project_group_actor_mapping.project_group_mapping_id : 0,
            trainingAttended: this.currentSection.training_recording_dtl[0].training_attended,
            spouse: this.currentSection.training_recording_dtl[0].spouse_attended,
            children: this.currentSection.training_recording_dtl[0].children_attended_above_16
        };
        this.groundForm.patchValue(obj);
        this.groundForm.markAsPristine();
        this.groundForm.controls['trainingName'].disable();
        this.groundForm.controls['trainerName'].disable();
        this.groundForm.controls['trainingDate'].disable();
        this.groundForm.controls['group'].disable();
        this.uploadedTrainingFilesOld = this.currentSection.training_documents_dtl;
        this.spinner.hide();
    };
    FarmerTrainingDetailsComponent.prototype.editCertificationForm = function () {
        var obj = {
            certificateName: this.currentSection.project_training_certification_mapping_id,
            description: this.currentSection.description,
            certificationDate: new Date(this.currentSection.certification_date),
            expiryDate: this.currentSection.expiry_date ? new Date(this.currentSection.expiry_date) : null,
            group: this.currentSection.certification_recording_dtl[0].project_group_actor_mapping ?
                this.currentSection.certification_recording_dtl[0].project_group_actor_mapping.project_group_mapping_id : 0,
            certified: this.currentSection.certification_recording_dtl[0].certified
        };
        this.groundForm.patchValue(obj);
        this.groundForm.markAsPristine();
        this.groundForm.controls['certificateName'].disable();
        this.groundForm.controls['certificationDate'].disable();
        this.groundForm.controls['group'].disable();
        this.spinner.hide();
    };
    FarmerTrainingDetailsComponent.prototype.updateTraining = function () {
        var _this = this;
        this.spinner.show();
        var trainingName = this.groundForm['value'].trainingName;
        var trainerName = this.groundForm['value'].trainerName;
        var trainingDate = this.groundForm['value'].trainingDate;
        var description = this.groundForm['value'].description;
        var trainingAttended = this.groundForm['value'].trainingAttended;
        var spouse = this.groundForm['value'].spouse;
        var children = this.groundForm['value'].children;
        var farmerData = [];
        var obj = {
            "id": this.currentSection.training_recording_dtl[0].id,
            "training_recording_hdr_id": this.currentSection.id,
            "farmer_id": this.currentSection.training_recording_dtl[0].farmer_id,
            "project_group_actor_mapping_id": this.currentSection.training_recording_dtl[0].project_group_actor_mapping_id,
            "training_attended": trainingAttended ? 1 : 0,
            "spouse_attended": spouse ? 1 : 0,
            "children_attended_above_16": children ? 1 : 0,
            "status": "Active"
        };
        farmerData.push(obj);
        var documentData = this.uploadedTrainingFiles;
        var trainingToAdd = {
            "id": this.currentSection.id,
            "project_training_certification_mapping_id": trainingName,
            "trainer_id": trainerName,
            "training_date": trainingDate,
            "description": description,
            "status": "Active",
            "for_individual": this.currentSection.for_individual,
            "training_recording_dtl": farmerData,
            "training_documents_dtl": documentData
        };
        console.log('trainingToAdd', trainingToAdd);
        this.trainingService.updateIndividualTraining(this.projectId, this.farmerId, trainingToAdd).subscribe(function (result) {
            _this.commonService.showToast('success', 'training_updated_successfully', {});
            _this.subtabClicked(_this.currentSectionIndex);
            _this.uploadedTrainingFiles = [];
            _this.spinner.hide();
        }, function (error) {
            _this.spinner.hide();
        });
    };
    FarmerTrainingDetailsComponent.prototype.addNewTraining = function () {
        var _this = this;
        this.spinner.show();
        var trainingName = this.popupForm['value'].trainingName;
        var trainerName = this.popupForm['value'].trainerName;
        var trainingDate = this.popupForm['value'].trainingDate;
        var description = this.popupForm['value'].description;
        var spouse = this.popupForm['value'].spouse;
        var children = this.popupForm['value'].children;
        var farmerData = [];
        var obj = {
            "farmer_id": this.farmerId,
            "project_group_actor_mapping_id": null,
            "training_attended": 1,
            "spouse_attended": spouse ? 1 : 0,
            "children_attended_above_16": children ? 1 : 0
        };
        farmerData.push(obj);
        var documentData = this.uploadedNewTrainingFiles;
        var trainingToAdd = {
            "project_training_certification_mapping_id": trainingName,
            "trainer_id": trainerName,
            "training_date": trainingDate,
            "description": description,
            "status": "Active",
            "for_individual": 1,
            "training_recording_dtl": farmerData,
            "training_documents_dtl": documentData
        };
        console.log('trainingToAdd', trainingToAdd);
        this.trainingService.recordIndividualTraining(this.projectId, this.farmerId, trainingToAdd).subscribe(function (result) {
            _this.commonService.showToast('success', 'training_created_successfully', {});
            _this.confirmCloseAddTrainingPopup();
            _this.getTrainingCertifcationList();
            _this.uploadedNewTrainingFiles = [];
            _this.spinner.hide();
        }, function (error) {
            _this.confirmCloseAddTrainingPopup();
            _this.spinner.hide();
        });
    };
    FarmerTrainingDetailsComponent.prototype.updateCertification = function () {
        var _this = this;
        this.spinner.show();
        var certificateName = this.currentSection.project_training_certification_mapping_id;
        var certificationDate = this.groundForm['value'].certificationDate;
        var expiryDate = this.groundForm['value'].expiryDate;
        var description = this.groundForm['value'].description;
        var certified = this.groundForm['value'].certified;
        var farmerData = [];
        var obj = {
            "id": this.currentSection.certification_recording_dtl[0].id,
            "certification_recording_hdr_id": this.currentSection.id,
            "farmer_id": this.currentSection.certification_recording_dtl[0].farmer_id,
            "project_group_actor_mapping_id": this.currentSection.certification_recording_dtl[0].project_group_actor_mapping_id,
            "certified": certified ? 1 : 0
        };
        farmerData.push(obj);
        var trainingToAdd = {
            "id": this.currentSection.id,
            "project_training_certification_mapping_id": certificateName,
            "certification_date": certificationDate,
            "expiry_date": expiryDate,
            "description": description,
            "for_individual": this.currentSection.for_individual,
            "certification_recording_dtl": farmerData
        };
        console.log('trainingToAdd', trainingToAdd);
        this.trainingService.updateIndividualCertification(this.projectId, this.farmerId, trainingToAdd).subscribe(function (result) {
            _this.commonService.showToast('success', 'certification_updated_successfully', {});
            _this.subtabClicked(_this.currentSectionIndex);
            _this.spinner.hide();
        }, function (error) {
            _this.spinner.hide();
        });
    };
    FarmerTrainingDetailsComponent.prototype.addNewCertification = function () {
        var _this = this;
        this.spinner.show();
        var certificateName = this.popupForm['value'].certificateName;
        var certificationDate = this.popupForm['value'].certificationDate;
        var expiryDate = this.popupForm['value'].expiryDate;
        var description = this.popupForm['value'].description;
        var farmerData = [];
        var obj = {
            "farmer_id": this.farmerId,
            "project_group_actor_mapping_id": null,
            "certified": 1
        };
        farmerData.push(obj);
        var trainingToAdd = {
            "project_training_certification_mapping_id": certificateName,
            "certification_date": certificationDate,
            "expiry_date": expiryDate,
            "description": description,
            "for_individual": 1,
            "certification_recording_dtl": farmerData
        };
        console.log('trainingToAdd', trainingToAdd);
        this.trainingService.recordIndividualCertification(this.projectId, this.farmerId, trainingToAdd).subscribe(function (result) {
            _this.commonService.showToast('success', 'certification_created_successfully', {});
            _this.confirmCloseAddTrainingPopup();
            _this.getTrainingCertifcationList();
            _this.uploadedNewTrainingFiles = [];
            _this.spinner.hide();
        }, function (error) {
            _this.confirmCloseAddTrainingPopup();
            _this.spinner.hide();
        });
    };
    FarmerTrainingDetailsComponent.prototype.removeTrainingFile = function (fileName, template) {
        var file = {
            isOld: false,
            file: fileName
        };
        this.fileToDelete = file;
        this.fileDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    FarmerTrainingDetailsComponent.prototype.removeTrainingFileOld = function (id, template) {
        var file = {
            isOld: true,
            file: id
        };
        this.fileToDelete = file;
        this.fileDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    FarmerTrainingDetailsComponent.prototype.confirmFileDelete = function (decision) {
        var _this = this;
        if (decision == "Yes") {
            console.log(this.fileToDelete.isOld);
            if (this.fileToDelete.isOld) {
                var id_1 = this.fileToDelete.file;
                this.trainingService.deleteTrainingDocument(id_1, {}).subscribe(function (result) {
                    _this.commonService.showToast('success', 'file_deleted_successfully', {});
                    _this.uploadedTrainingFilesOld = _this.uploadedTrainingFilesOld.filter(function (item) { return item.id !== id_1; });
                    _this.fileDeleteReset();
                }, function (error) {
                    _this.commonService.showToast('error', error, {});
                    _this.fileDeleteReset();
                });
            }
            else {
                var x = this.uploadedTrainingFiles.find(function (item) { return item.image_for == _this.fileToDelete.file; });
                var index = this.uploadedTrainingFiles.indexOf(x);
                if (index !== -1) {
                    this.uploadedTrainingFiles.splice(index, 1);
                }
                this.fileDeleteReset();
            }
        }
        else {
            this.fileDeleteReset();
        }
    };
    FarmerTrainingDetailsComponent.prototype.fileDeleteReset = function () {
        this.fileDeletePopup.hide();
        this.fileToDelete = null;
    };
    FarmerTrainingDetailsComponent.prototype.downloadImage = function (imageBase64, fileName) {
        this.downloadPdf(imageBase64, fileName);
    };
    FarmerTrainingDetailsComponent.prototype.downloadPdf = function (base64String, fileName) {
        var source = base64String;
        var link = document.createElement("a");
        link.href = source;
        link.download = fileName;
        link.click();
    };
    /* file upload */
    FarmerTrainingDetailsComponent.prototype.handleNewTrainingFileInput = function (file) {
        var _this = this;
        var fileItem = file.item(0);
        var msg = this.commonService.ValidateFile(fileItem);
        if (msg && msg.length > 0) {
            this.toastrService.warning(msg);
            return;
        }
        new Compressor(fileItem, {
            quality: environment.imageCompress.quality,
            maxWidth: environment.imageCompress.maxWidth,
            maxHeight: environment.imageCompress.maxHeight,
            mimeType: environment.imageCompress.mimeType,
            convertSize: environment.imageCompress.convertSize,
            success: function (result) {
                setFarmerImageData(result);
            },
            error: function (err) {
                console.log(err.message);
            },
        });
        var setFarmerImageData = function (result) {
            _this.commonService.blobToBase64(result).then(function (imageData) {
                var obj = {
                    "image_file": imageData,
                    "image_for": fileItem.name
                };
                _this.uploadedNewTrainingFiles.push(obj);
            });
        };
    };
    FarmerTrainingDetailsComponent.prototype.removeNewTrainingFile = function (fileName) {
        var x = this.uploadedNewTrainingFiles.find(function (item) { return item.image_for == fileName; });
        var index = this.uploadedNewTrainingFiles.indexOf(x);
        if (index !== -1) {
            this.uploadedNewTrainingFiles.splice(index, 1);
        }
    };
    FarmerTrainingDetailsComponent.prototype.addTrainingCertificate = function (template) {
        this.popupForm = this.formBuilder.group({
            trainingName: new FormControl('0', [Validators.required]),
            description: new FormControl('', [Validators.maxLength(1000)]),
            trainerName: new FormControl('0', [Validators.required]),
            trainingDate: new FormControl(null, [Validators.required]),
            group: new FormControl('0', [Validators.required]),
            trainingAttended: new FormControl({ value: true, disabled: true }),
            spouse: new FormControl(''),
            children: new FormControl(''),
            certificateName: new FormControl('0', [Validators.required]),
            certificationDate: new FormControl(null),
            expiryDate: new FormControl(null),
            certified: new FormControl({ value: true, disabled: true }),
        });
        this.addTrainingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
    };
    FarmerTrainingDetailsComponent.prototype.exitClicked = function (myForm, template) {
        if (myForm.dirty) {
            this.confirmModalPopup = false;
            this.confirmPopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            var url = 'farmers/' + this.projectId;
            this.router.navigate([url]);
        }
    };
    /* file upload */
    FarmerTrainingDetailsComponent.prototype.handleTrainingFileInput = function (file) {
        var _this = this;
        // // debugger;
        var fileItem = file.item(0);
        var msg = this.commonService.ValidateFile(fileItem);
        if (msg && msg.length > 0) {
            this.toastrService.warning(msg);
            return;
        }
        console.log('file', fileItem);
        new Compressor(fileItem, {
            quality: environment.imageCompress.quality,
            maxWidth: environment.imageCompress.maxWidth,
            maxHeight: environment.imageCompress.maxHeight,
            mimeType: environment.imageCompress.mimeType,
            convertSize: environment.imageCompress.convertSize,
            success: function (result) {
                setFarmerImageData(result);
            },
            error: function (err) {
                console.log(err.message);
            },
        });
        var setFarmerImageData = function (result) {
            _this.commonService.blobToBase64(result).then(function (imageData) {
                var obj = {
                    "image_file": imageData,
                    "image_for": fileItem.name
                };
                _this.uploadedTrainingFiles.push(obj);
            });
        };
    };
    FarmerTrainingDetailsComponent.prototype.confirmExitModal = function (item) {
        if (item === 'Yes') {
            this.confirmModalPopup = true;
            if (this.confirmPopup) {
                this.confirmPopup.hide();
            }
            var url = 'farmers/' + this.projectId;
            this.router.navigate([url]);
        }
        else if (item === 'No') {
            this.confirmModalPopup = true;
            if (this.confirmPopup) {
                this.confirmPopup.hide();
            }
        }
    };
    FarmerTrainingDetailsComponent.prototype.closeAddTrainingPopup = function () {
        if (this.popupForm && this.popupForm.dirty) {
            this.confirmAddModal = false;
        }
        else {
            this.confirmCloseAddTrainingPopup();
        }
    };
    FarmerTrainingDetailsComponent.prototype.confirmAddClose = function (decision) {
        if (decision == 'Yes') {
            this.confirmCloseAddTrainingPopup();
        }
        else {
            this.confirmAddModal = true;
        }
    };
    FarmerTrainingDetailsComponent.prototype.confirmCloseAddTrainingPopup = function () {
        if (this.addTrainingModal) {
            this.addTrainingModal.hide();
            this.confirmAddModal = true;
        }
    };
    FarmerTrainingDetailsComponent.prototype.showPopupAddButton = function () {
        if (this.popupForm['value'].trainingName != 0 && this.popupForm['value'].trainerName != 0 && this.popupForm['value'].trainingDate != null) {
            this.disablePopupAddButton = false;
        }
        else {
            this.disablePopupAddButton = true;
        }
    };
    FarmerTrainingDetailsComponent.prototype.showCertiPopupAddButton = function () {
        if (this.popupForm['value'].certificateName != 0 && this.popupForm['value'].certificationDate != null) {
            this.popupCertiAddButton = false;
        }
        else {
            this.popupCertiAddButton = true;
        }
    };
    FarmerTrainingDetailsComponent.prototype.trackBytraining = function (index, dataItem) {
        return dataItem.id;
    };
    FarmerTrainingDetailsComponent.prototype.trackBytrainer = function (index, dataItem) {
        return dataItem.id;
    };
    FarmerTrainingDetailsComponent.prototype.canDeactivate = function () {
        if (this.groundForm && this.groundForm.dirty) {
            this.confirmPopup = this.modalService.show(this.confirmTemplate, { backdrop: true, ignoreBackdropClick: true });
            this.spinner.hide();
            return this.subject.asObservable();
        }
        return true;
    };
    FarmerTrainingDetailsComponent.prototype.checkTrainingAttended = function (e) {
        if (e.target.checked) {
            this.groundForm.patchValue({
                trainingAttended: true
            });
        }
    };
    return FarmerTrainingDetailsComponent;
}());
export { FarmerTrainingDetailsComponent };
