<app-header></app-header>
<div class="project-header clearfix">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#" i18n="@@projectHome" i18n-title="@@projectHome">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page" i18n="@@administration">Administration</li>
    </ol>
  </nav>
</div>


<div class="page-wrapper">
    <div class="content-wrapper background">

        <div class="myprojects">
            <h3 class="project-head" i18n="@@administration">Administration</h3>

            <div class="clearfix"></div>

            <div class="list-project" id="myList">
                <ul class="mdm-box clearfix">
                    <li class="col-lg-2 col-md-2 col-sm-6 col-xs-12" [routerLink]="['/projectmanagement']">
                        <span class="box box_mh">
                            <img src="assets/images/projct-admin.svg" alt="project admin">
                            <h3 i18n="@@projectManagement">Project Management</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing</p>
                        </span>
                    </li>
                    <li class="col-lg-2 col-md-2 col-sm-6 col-xs-12" [routerLink]="['/mdminfo']" [queryParams]="{origin: 'USERS_MDM'}">
                      <span class="box box_mh">
                          <img src="assets/images/users.svg" i18n-title="@@user" alt="Users">
                          <h3 i18n="@@user">User</h3>
                          <p>Lorem Ipsum is simply dummy text of the printing</p>
                      </span>
                  </li>
                  <li class="col-lg-2 col-md-2 col-sm-6 col-xs-12" [routerLink]="['/mdminfo']" [queryParams]="{origin: 'UNITS_MDM'}">
                    <span class="box box_mh">
                        <img src="assets/images/units.svg" i18n-title="@@units" alt="Units">
                        <h3 i18n="@@units">Units</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                    </span>
                </li>
                </ul>
            </div>

        </div>
        <div class="clearfix"></div>
        <div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
            <button (click)="scrollToTop()" class="btn btn-primary btn-icon btn-circle">
                <img height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSwxOTcuMjg3TDE1OS4wMjgsNjkuMzgxYy0zLjYxMy0zLjYxNy03Ljg5NS01LjQyNC0xMi44NDctNS40MjRzLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0TDUuNDI0LDE5Ny4yODcgICBDMS44MDcsMjAwLjkwNCwwLDIwNS4xODYsMCwyMTAuMTM0czEuODA3LDkuMjMzLDUuNDI0LDEyLjg0N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyNSwxMi44NSw1LjQyNWgyNTUuODEzICAgYzQuOTQ5LDAsOS4yMzMtMS44MDgsMTIuODQ4LTUuNDI1YzMuNjEzLTMuNjEzLDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N1MyOTAuNTQ4LDIwMC45MDQsMjg2LjkzNSwxOTcuMjg3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iI2ZmZmZmZiI+PC9wYXRoPgo8L2c+PC9nPiA8L3N2Zz4=" alt=""/>
            </button>
        </div>
    </div>
</div>
