import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
var ApiManagementService = /** @class */ (function () {
    function ApiManagementService(http, appConfigService) {
        this.http = http;
        this.appConfigService = appConfigService;
        this.API_MANAGEMENT_URL = this.appConfigService.getUrl(this.appConfigService.API_MANAGEMENT);
        this.API_URL = '/api';
        this.dynamicAPIUrl = this.appConfigService.getUrl('');
    }
    ApiManagementService.prototype.getAPIInfo = function () {
        return this.http.get(this.API_MANAGEMENT_URL + "/getAPIInfo");
    };
    ApiManagementService.prototype.getAPIErrors = function () {
        return this.http.get(this.API_MANAGEMENT_URL + "/getAPIErrors");
    };
    ApiManagementService.prototype.triggerAPI = function (api) {
        return this.http.post(this.API_MANAGEMENT_URL + "/triggerAPI", api);
    };
    ApiManagementService.prototype.retriggerAPIForError = function (errorId) {
        return this.http.get(this.API_MANAGEMENT_URL + "/retriggerAPIForError/" + errorId);
    };
    ApiManagementService.prototype.getAPIConfigData = function (urls) {
        var responseArray = [];
        for (var i = 0; i < urls.length; i++) {
            responseArray[i] = this.http.get(this.dynamicAPIUrl + "/api/" + urls[i]);
        }
        return forkJoin(responseArray);
    };
    ApiManagementService.prototype.saveTraceabilityEcosystems = function (data) {
        return this.http.post(this.API_MANAGEMENT_URL + "/saveTraceabilityEcosystems", data);
    };
    ApiManagementService.ngInjectableDef = i0.defineInjectable({ factory: function ApiManagementService_Factory() { return new ApiManagementService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: ApiManagementService, providedIn: "root" });
    return ApiManagementService;
}());
export { ApiManagementService };
