import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
var CampaignService = /** @class */ (function () {
    function CampaignService(http, appConfigService) {
        this.http = http;
        this.appConfigService = appConfigService;
        this.campaignURL = this.appConfigService.getUrl(this.appConfigService.CAMPAIGN);
    }
    CampaignService.prototype.getCampaigns = function () {
        return this.http.get(this.campaignURL + "/getCampaigns");
    };
    CampaignService.prototype.getCampaignById = function (campaignId) {
        return this.http.get(this.campaignURL + "/getCampaignById/" + campaignId);
    };
    CampaignService.prototype.saveOrUpdateCampaign = function (campaignId, data) {
        return campaignId && campaignId > 0 ? this.http.put(this.campaignURL + "/updateCampaign/" + campaignId, data)
            : this.http.post(this.campaignURL + '/saveCampaign', data);
    };
    CampaignService.prototype.closeCampaign = function (campaignId, data) {
        return this.http.put(this.campaignURL + "/closeCampaign/" + campaignId, data);
    };
    CampaignService.prototype.getFarmerIdsMappedToCampaign = function (campaignId) {
        return this.http.get(this.campaignURL + "/getFarmerIdsMappedToCampaign/" + campaignId);
    };
    CampaignService.prototype.mapFarmersToCampaign = function (campaignId, farmerIdsArray) {
        return this.http.post(this.campaignURL + "/mapFarmersToCampaign/" + campaignId, farmerIdsArray);
    };
    CampaignService.prototype.getCampaignStatuses = function () {
        return this.http.get(this.campaignURL + "/getCampaignStatuses");
    };
    CampaignService.prototype.getCampaignsForProject = function (projectId) {
        return this.http.get(this.campaignURL + "/getCampaignsByProject/" + projectId);
    };
    CampaignService.prototype.getFarmersMappedToCampaign = function (campaignId) {
        return this.http.get(this.campaignURL + "/getFarmersMappedToCampaign/" + campaignId);
    };
    CampaignService.ngInjectableDef = i0.defineInjectable({ factory: function CampaignService_Factory() { return new CampaignService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: CampaignService, providedIn: "root" });
    return CampaignService;
}());
export { CampaignService };
