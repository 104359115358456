import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray, Form } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ApiManagementService } from '../../services/api-management.service';
import { CommonService } from '../../services/common.service';


@Component({
  selector: 'app-api-management',
  templateUrl: './api-management.component.html',
  styleUrls: ['./api-management.component.css']
})
export class ApiManagementComponent implements OnInit {

  public previousPaginationLabel;
  public nextPaginationLabel;
  public triggerAPIModal;
  confirmModal = true;
  public registerFarmerForm: FormGroup;
  triggeringAPI;

  public tabs = [
    {key: 'apiConfig', title: 'API Configuration'}
    // {key: 'apiDetails', title: 'API Details'},
    // {key: 'apiErrors', title: 'API Errors'}
  ];

  public selectedTab = 'apiConfig';
  apiDetails;
  apiErrors;
  apiToTrigger;
  triggerAPIForm: FormGroup;
  apiHasNoParams;
  apiHasNoQueryParams;
  errorToRetrigger;
  errorsBeingRetriggered = [];
  apiConfigs = [
    {
      id: 1,
      name: 'Ecosystems to Trigger',
      description: 'Select Ecosystems to Trigger API calls for. Master data & farmers will be triggered in the subsequent cron job call',
      required: true,
      formDataURL: undefined,
      formFields: [
        {
          label: 'Select ecosystems to trigger',
          type: 'checkbox',
          optionsDataUrls: ['common/getallecosystems', 'apimanagement/traceabilityEcosystems'],
        }
      ],
      saveDataURL: 'apimanagement/saveConfig'
    }
  ];

  public confirmModalAPIConfig: boolean = true;
  public apiConfigModal;
  public savingAPIConfig: boolean = true;
  apiConfigForm: FormGroup;
  configToEdit;
  allEcosystems;
  selectedEcosystems;
  selectedEcosystemsCurrent;
  errorsJustTriggered = [];

  fromNavId;
  public typeOfAdministrationArr: any[]; 
  typeOfAdministration = '';


  @ViewChild('triggerAPIModal') triggerAPIModalTemplate :any;
  //DEEPIII-290
  searchText;
  constructor(
    private translate: TranslateService,
    public modalService: BsModalService,
    private fb: FormBuilder,
    private apiManagementService: ApiManagementService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });

    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });

    this.route.queryParamMap.subscribe(queryParams => {
      const qpObject = { ...queryParams.keys, ...queryParams };

      this.fromNavId = qpObject['params']['from'];

      this.translate.get('typeAdminTextArr').subscribe((val) => {
        this.typeOfAdministrationArr = val;
      }, err => console.log('err', err));

      if (this.fromNavId == 6) {
        this.typeOfAdministration = this.typeOfAdministrationArr[2];
      }

      //console.log('this.origin', this.origin);
    })
  }

  ngOnInit() {
    this.fetchData();
    this.triggerAPIForm = this.fb.group({
    });
    this.selectTab(this.tabs[0]);
  }

  // Fetch data as per the selected tab
  fetchData(): void {
    if (this.selectedTab === 'apiDetails') {
      this.apiDetails = [];
      this.apiManagementService.getAPIInfo()
      .subscribe(data => {
        // console.log('data', data);
        if (data && data.data && data.data.length > 0){

          for (let i = 0; i < data.data.length; i++) {
            data.data[i].params = JSON.parse(data.data[i].params);
          }

          this.apiDetails = data.data;
        } else {
          this.apiDetails = [];
        }
      })
    }
    else if (this.selectedTab === 'apiErrors') {
      this.apiErrors = [];
      this.apiManagementService.getAPIErrors()
      .subscribe(data => {
        // console.log('data', data);
        let apiErrors;
        if (data && data.data && data.data.length > 0){
          apiErrors = data.data;
        } else {
          apiErrors = [];
        }

        if (apiErrors.length > 0) {
          for (let i = 0; i < apiErrors.length; i++) {
            const errorLogObject = apiErrors[i].errorlog;
            // let errorLogObject = JSON.parse(errorLogString);
            
            //DEEPIII-290 Issue 1 - Initiate errorSomeText with space tp avoid null exception.
            // errorsomeText holds the values of failed farmers and their respective error code and message
            // this data will be hidden on the frontend page. Used only for proper filtering purpose
            apiErrors[i].consolidatedErrorText = ' ';

            for (let k = 0; k < errorLogObject.length; k++) {
              const errorCodeArray = errorLogObject[k].errorCode.split(",");
              const errorMsgArray = errorLogObject[k].errorMsg.split(",");
              
              //DEEPIII-290 Issue 1 - Search Tab. To enable proper Search on API ERRORS tab
              const consolidatedErrorString: string = errorLogObject[k].farmerno + ' ' + errorLogObject[k].errorCode + ' ' + errorLogObject[k].errorMsg; 
              apiErrors[i].consolidatedErrorText = apiErrors[i].consolidatedErrorText + ' ' + consolidatedErrorString;
              
              const errorsToDisplay = [];
              for (let j = 0; j < errorCodeArray.length; j++) {
                const errorObj = {
                  code: errorCodeArray[j],
                  msg: errorMsgArray[j]
                };
                errorsToDisplay.push(errorObj);
              }
              errorLogObject[k].errorsToDisplay = errorsToDisplay;
            }
            // errorLogObject.push(errorLogObject[0]);
            

        
            apiErrors[i].errorLogValue = errorLogObject;
          }
        }

        this.apiErrors = apiErrors;
        // this.apiErrors.push(this.apiErrors[0]);
      })
    } else if (this.selectedTab == 'apiConfig') {
      // console.log('API config tab opened');
      this.apiDetails = [];
      this.apiManagementService.getAPIInfo()
      .subscribe(data => {
        // console.log('data', data);
        if (data && data.data && data.data.length > 0){

          for (let i = 0; i < data.data.length; i++) {
            data.data[i].params = JSON.parse(data.data[i].params);
          }

          this.apiDetails = data.data;
        } else {
          this.apiDetails = [];
        }
      })
    }
  }

  selectTab(tab): void {
    this.selectedTab = tab.key;
    this.fetchData();
    //Reset Search Text Box on Tab Change and Tab click
    this.searchText='';
  }

  openTriggerModal(api, template): void {
    this.apiToTrigger = api;

    if (this.apiToTrigger.params && this.apiToTrigger.params.length > 0) {
      for (let i = 0; i < this.apiToTrigger.params.length; i++) {
        const validatorArr = [];
        if (this.apiToTrigger.params[i].required) {
          validatorArr.push(Validators.required);
        }
        this.triggerAPIForm.addControl('p_' + this.apiToTrigger.params[i].key, new FormControl({value: null, disabled: this.apiToTrigger.params[i].readonly ? true : false}, validatorArr));
      }
    } else this.apiHasNoParams = true;

    if (this.apiToTrigger.query_params && this.apiToTrigger.query_params.length > 0) {
      for (let i = 0; i < this.apiToTrigger.query_params.length; i++) {
        const validatorArr = [];
        if (this.apiToTrigger.query_params[i].required) {
          validatorArr.push(Validators.required);
        }
        this.triggerAPIForm.addControl('q_' + this.apiToTrigger.query_params[i].key, new FormControl({value: null, disabled: this.apiToTrigger.query_params[i].readonly ? true : false}, validatorArr));
      }
    } else this.apiHasNoQueryParams = true;

    this.triggerAPIModal = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
  }


  openAPIConfigModal(config, template): void {
    this.configToEdit = config;
    //DEEPIII-290 Set to True so that CONFIRM button is disabled when Trigger button is clicked
    this.savingAPIConfig = true;
    // console.log('this.configToEdit', this.configToEdit);

    for (let i = 0; i < this.configToEdit.formFields.length; i++) {
      const formField = this.configToEdit.formFields[i];
      if (formField.optionsDataUrls) {
        this.apiManagementService.getAPIConfigData(formField.optionsDataUrls)
        .subscribe(result => {
          // console.log('result', result);
          if (this.configToEdit.id = 1) {
            //DEEPIII-290 Issue 2  - Get Active Ecosystems
            this.allEcosystems = result[0]['data'].filter(item=>item.status=='Active');
            this.selectedEcosystems = result[1]['data'].map(item => item['ecosystem']['id']);
            this.selectedEcosystemsCurrent = Object.assign([], this.selectedEcosystems);
          }
        })
      }
    }

    this.apiConfigModal = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
  }

  declineTriggerAPIPrompt(form) : void {
    // console.log('declineTriggerAPIPrompt called');
    if (form.dirty) {
      // console.log('form is dirty');
      this.confirmModal = false;
    } else {
      // console.log('form is not dirty');
      this.declineTriggerAPI();
    }
  }

  declineTriggerAPI(): void {
    if (this.selectedTab === 'apiConfig') {
      this.selectedEcosystemsCurrent = Object.assign([], this.selectedEcosystems);
      this.apiConfigModal.hide(); 
    } else {
      this.triggerAPIModal.hide();
      setTimeout(() => this.resetAPIForm(), 0);
    }
  }

  confirmModalFn(what) : void {
    //DEEPIII-290 Issue 4 - ConfirmModal and ConfirmAPIConfig modal needs to be set to true again on YES&NO. 
    //If not then on TRIGGER click, the popup is given since the false value satiesfies still
    this.selectedTab == 'apiConfig' ? this.confirmModalAPIConfig = true : this.confirmModal = true;
    if (what == 'Yes') {
      this.declineTriggerAPI();
    }
    else {
      //DEEPIII-290 Issue 4 - moved to start of method
      // this.selectedTab == 'apiConfig' ? this.confirmModalAPIConfig = true : this.confirmModal = true;
    }
  }

  triggerAPI(): void {
    this.triggeringAPI = true;

    const paramValues = [];
    const queryParamValues = [];
    Object.keys(this.triggerAPIForm.value).forEach(function (key) {
      // console.log('key: ', key, 'value: ', this.triggerAPIForm.value[key]);
      if (key.indexOf('p_') > -1) paramValues.push({key: key, value: this.triggerAPIForm.value[key]});
      if (key.indexOf('q_') > -1) queryParamValues.push({key: key, value: this.triggerAPIForm.value[key]});
    }.bind(this));
    
    const apiToTrigger = {};
    apiToTrigger['api_code'] = this.apiToTrigger.api_code;
    apiToTrigger['id'] = this.apiToTrigger.id;
    apiToTrigger['paramValues'] = paramValues;
    apiToTrigger['queryParamValues'] = queryParamValues;

    this.apiManagementService.triggerAPI(apiToTrigger)
    .subscribe(response => {
      // console.log('response', response);
      this.declineTriggerAPI();
      this.triggeringAPI = false;
    }, error => {
      this.triggeringAPI = false;
    })
  }
  

  resetAPIForm(): void {
    if (this.apiToTrigger.params && this.apiToTrigger.params.length > 0) {
      for (let i = 0; i < this.apiToTrigger.params.length; i++) {
        this.triggerAPIForm.removeControl('p_' + this.apiToTrigger.params[i].key);
      }
    }

    if (this.apiToTrigger.query_params && this.apiToTrigger.query_params.length > 0) {
      for (let i = 0; i < this.apiToTrigger.query_params.length; i++) {
        this.triggerAPIForm.removeControl('q_' + this.apiToTrigger.query_params[i].key);
      }
    }

    this.triggerAPIForm.reset();
    this.triggerAPIForm.markAsPristine();

    this.apiToTrigger = undefined;
    this.confirmModal = true;
    // console.log('this.triggerAPIForm', this.triggerAPIForm);
  }

  retriggerError(error): void {
    // console.log('error', error);

    // Set which error to retrigger with this click action
    this.errorToRetrigger = error;

    // Add this error's id to the array of errors being currently triggered. After the response is got back, remove the id from this array
    this.errorsBeingRetriggered.push(error.id);

    this.apiManagementService.retriggerAPIForError(error.id)
    .subscribe(response => {
      // console.log('response', response);
      if (response && response.code == 'Success') {
        this.errorsJustTriggered.push(error.id);

        if (response.data == 'notAllFarmersTriggered') {
          this.commonService.showToast('warning', 'Some farmers were not triggered because of unknown farmer number', {});
        } else {
          this.commonService.showToast('success', 'All farmers triggered', {});
        }
      }
    })
  }

  saveAPIConfig(): void {
    //DEEPIII-290 - Issue 3 - Set true so that on single click button becomes disables do avoid double click by user.
    this.savingAPIConfig = true;
    if (this.configToEdit.id == 1) {
      this.apiManagementService.saveTraceabilityEcosystems(this.selectedEcosystemsCurrent)
      .subscribe(result => {
        this.selectedEcosystems = result['data'].map(item => item['ecosystem']['id']);
        this.selectedEcosystemsCurrent = Object.assign([], this.selectedEcosystems);
        this.apiConfigModal.hide();
        //DEEPIII-290 - Issue 5 - Success message on Confirm.
        this.commonService.showToast('success', 'success_master_data_updated', {});
      })
    }
  }

  toggleInSelectedEcosystems(ecosystemId): void {
    //DEEPIII-290 Set to False so that CONFIRM button is enabled when an ecosystem is checked/unchecked.
    this.savingAPIConfig = false;
    const index = this.selectedEcosystemsCurrent.indexOf(ecosystemId);
    if (index > -1) {
      this.selectedEcosystemsCurrent = this.selectedEcosystemsCurrent.filter(item => item != ecosystemId);
    } else {
      this.selectedEcosystemsCurrent.push(ecosystemId);
    }
  }
  //DEEPIII-290 - Issue 1. Search Functionality
  getGridFilters() {
    let gridColums = [];
    if(this.selectedTab === "apiDetails"){
      gridColums = ['api_name','api_code','api_description','status'];
    }
    else if(this.selectedTab === 'apiErrors'){
      gridColums = ['code','batchno','consolidatedErrorText'];
    }
    else if(this.selectedTab === 'apiConfig'){
      gridColums = ['name','description'];
    }
    const finalObj = {};
    if (this.apiDetails === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  //DEEPIII-290 - Issue 4. Popup on CANCEL
  checkAPIConfigFormChange() : void {
    //DEEPIII-290 - Issue 4. Check if selected ecosystems are same as the ones before
    if (this.selectedEcosystems.sort().toString()===this.selectedEcosystemsCurrent.sort().toString()) {
      // NO POPUP on CANCEL if previous and selected ecosystems are same i.e. no change in ecosystem
      this.declineTriggerAPI();
    } else {
      //POPUP Displayed on CANCEL since previous and selected ecosystems are different
      this.confirmModalAPIConfig = false;
    }
  }

}
