import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// Services
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { FarmerService } from '../../services/farmer.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
var ViewProfilingDatasheetComponent = /** @class */ (function () {
    function ViewProfilingDatasheetComponent(_route, dynamicQuestionnaireService, projectService, commonService, route, toastr, surveyService, userService, farmerService, router, modalService, spinner) {
        this._route = _route;
        this.dynamicQuestionnaireService = dynamicQuestionnaireService;
        this.projectService = projectService;
        this.commonService = commonService;
        this.route = route;
        this.toastr = toastr;
        this.surveyService = surveyService;
        this.userService = userService;
        this.farmerService = farmerService;
        this.router = router;
        this.modalService = modalService;
        this.spinner = spinner;
        this.showSectionForm = false; // Boolean to show / hide the section form
        this.checkedObject = {}; // Object that stores checked = true / false for checkbox questions (non-table)
        this.checkedObjectSecondaryKeys = {}; // Object that stores checked = true / false for checkbox questions (table)
        this.secondary_asset_keys = {}; // Stores the secondary_asset_keys for each table. Format {tableId: [sak1, sak2], table2: [sak3, sak4]}
        this.secondary_asset_key__options = {}; // Stores the secondary_asset_key against the checkbox option that triggered creation of that sak. Format {optionId1: sak1, optionId2: sak2}
        this.notActiveSurvey = 2;
        this.currentUser = this.userService.getCurrentUser();
        this.revisionsInSurvey = [];
        this.selectedRevision = '';
        this.tableOtherQuestionsIsDisplayed = {};
        this.unsavedAnswerCount = 0;
        this.dynamicOptionsObject = {};
        this.subject = new Subject();
        this.displayLogicKeyValues = [];
        this.tableQuestionsAnswer = {};
        this.assetTypes = [
            { type: 'tab', subTypes: [] },
            { type: 'section', subTypes: [] },
            { type: 'subsection', subTypes: [] },
            { type: 'table', subTypes: [{ key: 'question_rows', label: 'Questions in rows' }, { key: 'question_cols', label: 'Questions in columns' }] },
            { type: 'question', subTypes: [{ key: 'text', label: 'Text' }, { key: 'number', label: 'Number' }, { key: 'select', label: 'Select' }, { key: 'multiselect', label: 'Multiselect' }] },
            { type: 'option', subTypes: [] },
            { type: 'uom_question', subTypes: [{ key: '1', label: 'Length' }, { key: '2', label: 'Volume' }, { key: '3', label: 'Weight' }, { key: '4', label: 'Area' }, { key: '5', label: 'Quantity' }] },
            { type: 'other_question', subTypes: [] },
            { type: 'grand_total_question', subTypes: [] },
        ];
        this.processedUOMs = {};
        this.askUserToConfirmExit = false;
        this.farmProfitabilitySummary = { income: { source: 'NA', amount: 'NA' }, expense: { source: 'NA', amount: 'NA' }, totalIncome: 'NA', totalExpense: 'NA', totalProfit: 'NA' };
        this.farmProfitabilitySummaryArray = [];
        this.canDoFarmerMgmt = false;
        this.showHideBackButton = true;
        this.showHideNextButton = false;
        this.farmerDetailsTabIndex = 0;
        // objFarmer : any;
        this.farmerRegQuestions = {};
    }
    ViewProfilingDatasheetComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.route.paramMap.subscribe(function (params) {
                    _this.projectId = params.get('projectId');
                    _this.route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var request, request2, farmer_requests, masterData, result, qAnswersForFarmer, farmertmpobj;
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    // Get the 'raw' farmerid - this can be the farmer id (online registration) or a temporary offline id
                                    this.rawFarmerId = params.get('id');
                                    // Get the farmer id (online registration), if it exists, or set this.farmerId to 0
                                    this.farmerId = +params.get('id') || 0;
                                    this.spinner.show();
                                    if (!(this.farmerId != 0)) return [3 /*break*/, 6];
                                    if (!navigator.onLine) return [3 /*break*/, 2];
                                    return [4 /*yield*/, Promise.all([
                                            this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                            this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                            this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                            this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                                            this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
                                            this.farmerService.getFarmerBasicDataById(this.farmerId).toPromise()
                                        ])];
                                case 1:
                                    request = _a.sent();
                                    return [3 /*break*/, 5];
                                case 2: return [4 /*yield*/, Promise.all([
                                        this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                        this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                        this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                        this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                                        this.dynamicQuestionnaireService.getQAnswersForProject(this.projectId, null).toPromise(),
                                        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                                    ])];
                                case 3:
                                    request = _a.sent();
                                    return [4 /*yield*/, Promise.all([
                                            this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
                                        ])];
                                case 4:
                                    request2 = _a.sent();
                                    _a.label = 5;
                                case 5: return [3 /*break*/, 8];
                                case 6: return [4 /*yield*/, Promise.all([
                                        this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                        this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                        this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                        this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise()
                                    ])];
                                case 7:
                                    request = _a.sent();
                                    _a.label = 8;
                                case 8: return [4 /*yield*/, Promise.all([
                                        this.farmerService.getFarmRegQuestions().toPromise(),
                                        this.farmerService.GetFarmerMaster().toPromise()
                                    ])];
                                case 9:
                                    farmer_requests = _a.sent();
                                    this.farmerRegQuestions = farmer_requests[0].data;
                                    masterData = farmer_requests[1].data;
                                    this.masterDataFarmer = masterData.filter(function (t) { return t.item_tab == "farmer_reg"; });
                                    if (!(request[0]['msg'] !== 'success' || request[1]['msg'] !== 'success' || request[2]['msg'] !== 'success')) return [3 /*break*/, 10];
                                    this.commonService.showToast('error', 'generic_error', {});
                                    return [3 /*break*/, 15];
                                case 10:
                                    this.project = tslib_1.__assign({}, request[0].data[0], request[1].data[0], request[2].data[0]);
                                    this.canDoFarmerMgmtFn();
                                    this.setCurrencyCode();
                                    if (request[3]['message'] === 'notActive') {
                                        this.notActiveSurvey = 1;
                                        this.commonService.showToast('warning', 'No Active Survey Found', {});
                                    }
                                    else if (request[3]['message'] === 'notReleasedForLanguage') {
                                        this.notReleasedSurveyInLanguage = 1;
                                    }
                                    else {
                                        this.questionnaire = request[3]['message'];
                                        this.setQuestionnaireAttributes();
                                        this.notActiveSurvey = 0;
                                    }
                                    result = void 0;
                                    if (this.farmerId != 0) {
                                        // If online, get answers from getQAnswersForFarmer (for this.farmerId)
                                        if (navigator.onLine) {
                                            result = request[4];
                                        }
                                        else {
                                            // If offline, get answers from getQAnswersForFarmer (for this.farmerId) - this is coming from request2
                                            result = { code: 'success' };
                                            qAnswersForFarmer = void 0;
                                            if (request2[0] && request2[0]['message']) {
                                                // If request2[0] has a message attribute, getQAnswersForFarmer has been called for this farmer before
                                                qAnswersForFarmer = request2[0];
                                            }
                                            else {
                                                // If getQAnswersForFarmer has not been called for this farmer, get the answer values from getQAnswersForProject by filtering on farmerId
                                                result['message'] = request[4]['message'].filter(function (item) { return item.farmer_id === _this.farmerId; });
                                            }
                                            // If getQAnswersForFarmer has been called for this farmer before, compare the timestamp of the two API calls (farmer specific & project-wide data fetch)
                                            // Set the results array as the latest API data fetch
                                            if (qAnswersForFarmer) {
                                                // getQAnswersForFarmer fetch      getQAnswersForProject fetch
                                                if (qAnswersForFarmer['ts'] > request[4]['ts']) {
                                                    // Set as farmer-specific API fetch
                                                    result['message'] = qAnswersForFarmer['message'];
                                                }
                                                else {
                                                    // Set as project-wide fetch, filtered by farmerId
                                                    result['message'] = request[4]['message'].filter(function (item) { return item.farmer_id === _this.farmerId; });
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        result = [];
                                    }
                                    if (!(this.farmerId !== 0 && request[5]['msg'] === 'success')) return [3 /*break*/, 11];
                                    if (navigator.onLine) {
                                        this.farmer = request[5]['data'];
                                    }
                                    else {
                                        this.farmer = request[5]['data'].find(function (item) { return item.id === _this.farmerId; });
                                    }
                                    this.setVCMapping();
                                    return [3 /*break*/, 13];
                                case 11: return [4 /*yield*/, idbApp.getAllProjects('farmerstmp')];
                                case 12:
                                    farmertmpobj = _a.sent();
                                    this.farmer = farmertmpobj.find(function (x) { return x.tmp_id === _this.rawFarmerId; });
                                    _a.label = 13;
                                case 13:
                                    if (this.farmer && this.farmer.FarmerQuickNotes && this.farmer.FarmerQuickNotes.length > 0) {
                                        this.notes = this.farmer.FarmerQuickNotes[0];
                                    }
                                    else {
                                        this.notes = { notes: '', farmer_id: this.farmerId };
                                    }
                                    // if(navigator.onLine && request[5].code === 'success') {
                                    //   this.revisionsInSurvey = request[5].message;
                                    // }
                                    // Set quick notes
                                    //this.setFarmerQuickNotes();
                                    // Set the qAnswers array
                                    return [4 /*yield*/, this.setQAnswersAndInitialize(result)];
                                case 14:
                                    // if(navigator.onLine && request[5].code === 'success') {
                                    //   this.revisionsInSurvey = request[5].message;
                                    // }
                                    // Set quick notes
                                    //this.setFarmerQuickNotes();
                                    // Set the qAnswers array
                                    _a.sent();
                                    this.preProcessProjectUOMs();
                                    this.setAnswerToQuestions();
                                    _a.label = 15;
                                case 15:
                                    this.spinner.hide();
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                });
                return [2 /*return*/];
            });
        });
    };
    ViewProfilingDatasheetComponent.prototype.setQAnswersAndInitialize = function (result) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var indexedDBEntries, farmertmpobj, qAnswersForThisFarmerId, i, i, _loop_1, this_1, i;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.qAnswers = [];
                        indexedDBEntries = [];
                        return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 1:
                        farmertmpobj = _a.sent();
                        if (farmertmpobj && farmertmpobj.length > 0) {
                            qAnswersForThisFarmerId = farmertmpobj.filter(function (item) { return item.tmp_id === _this.rawFarmerId; });
                            if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
                                for (i = 0; i < qAnswersForThisFarmerId.length; i++) {
                                    indexedDBEntries = indexedDBEntries.concat(JSON.parse(qAnswersForThisFarmerId[i].values));
                                }
                            }
                        }
                        // Cached values
                        if (result.code === 'success' && result.message.length > 0) {
                            for (i = 0; i < result.message.length; i++) {
                                this.qAnswers.push({
                                    id: result.message[i].id.toString(),
                                    key: result.message[i].questionnaire_asset_id.toString(),
                                    sak_of_modified: result.message[i].sak_of_modified,
                                    secondary_asset_key: result.message[i].secondary_asset_key,
                                    value: result.message[i].answer.toString()
                                });
                            }
                        }
                        // IndexedDB values
                        if (indexedDBEntries.length > 0) {
                            _loop_1 = function (i) {
                                // If the indexedDB entry has an id, find the same id from qAnswers and update the value, mark as touched
                                if (indexedDBEntries[i].id) {
                                    var x = this_1.qAnswers.find(function (item) { return item.id == indexedDBEntries[i].id; });
                                    if (x) {
                                        var xIndex = this_1.qAnswers.indexOf(x);
                                        if (this_1.validIndex(xIndex)) {
                                            this_1.qAnswers[xIndex]['value'] = indexedDBEntries[i]['value'];
                                            this_1.qAnswers[xIndex]['touched'] = true;
                                            if (indexedDBEntries[i]['checked']) {
                                                this_1.qAnswers[xIndex]['checked'] = indexedDBEntries[i]['checked'];
                                            }
                                            if (indexedDBEntries[i]['unchecked']) {
                                                this_1.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['unchecked'];
                                            }
                                            if (indexedDBEntries[i]['deleted']) {
                                                this_1.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['deleted'];
                                            }
                                        }
                                    }
                                }
                                else {
                                    // Push into qAnswers
                                    var answerToInsert = {
                                        key: indexedDBEntries[i].key.toString(),
                                        sak_of_modified: indexedDBEntries[i].sak_of_modified,
                                        secondary_asset_key: indexedDBEntries[i].secondary_asset_key,
                                        value: indexedDBEntries[i].value.toString(),
                                        touched: true,
                                        checked: indexedDBEntries[i].checked
                                    };
                                    if (indexedDBEntries[i]['checked']) {
                                        answerToInsert['checked'] = indexedDBEntries[i]['checked'];
                                    }
                                    if (indexedDBEntries[i]['unchecked']) {
                                        answerToInsert['unchecked'] = indexedDBEntries[i]['unchecked'];
                                    }
                                    if (indexedDBEntries[i]['deleted']) {
                                        answerToInsert['unchecked'] = indexedDBEntries[i]['deleted'];
                                    }
                                    this_1.qAnswers.push(answerToInsert);
                                }
                            };
                            this_1 = this;
                            for (i = 0; i < indexedDBEntries.length; i++) {
                                _loop_1(i);
                            }
                            this.askUserToConfirmExit = false;
                        }
                        if (this.notActiveSurvey === 0) {
                            // Initialize the questionnaire
                            this.initialize();
                            // Set the sak_of_modified values in this.secondary_asset_key__options object (which checkbox impacted which row / column uuid in a table)
                            this.setSecondaryAssetKeyOptionValues();
                            // Set dynamic options (which crop intercropping questions)
                            this.setDynamicOptionsObject();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // Initialize the questionnaire by navigating to the 1st tab
    ViewProfilingDatasheetComponent.prototype.initialize = function () {
        this.farmerDetailsTabIndex = this.questionnaire.length + 1;
        this.currentTabIndex = this.farmerDetailsTabIndex;
        this.tabClicked(this.currentTabIndex, true);
        //this.collectDisplayLogicKeyValues();
    };
    ViewProfilingDatasheetComponent.prototype.tabClicked = function (tabIndex, isFarmerTab) {
        if (!isFarmerTab) {
            this.currentTab = this.questionnaire[tabIndex];
        }
        this.currentTabIndex = tabIndex;
    };
    ViewProfilingDatasheetComponent.prototype.setSecondaryAssetKeyOptionValues = function () {
        for (var i = 0; i < this.qAnswers.length; i++) {
            if (this.qAnswers[i].sak_of_modified != null) {
                this.secondary_asset_key__options[this.qAnswers[i].secondary_asset_key] = this.qAnswers[i].sak_of_modified;
            }
        }
    };
    ViewProfilingDatasheetComponent.prototype.setDynamicOptionsObject = function () {
        if (this.questionnaire && this.questionnaire !== 'noSurveys' && this.questionnaire.length > 0) {
            this.dynamicOptionsObject = {};
            for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                        var _loop_2 = function (k) {
                            if (this_2.questionnaire[i].sections[j].tables[k].displayFunction && this_2.questionnaire[i].sections[j].tables[k].displayFunction.indexOf("addOptionToQ") > -1) {
                                var tableModifyDetails = JSON.parse(this_2.questionnaire[i].sections[j].tables[k].displayFunction);
                                if (tableModifyDetails && tableModifyDetails.addOptionToQ) {
                                    var addOptionToQ = tableModifyDetails.addOptionToQ.split(",");
                                    var qToAddAsOption_1 = tableModifyDetails.qToAddAsOption;
                                    var existingAnswers_1 = this_2.qAnswers.filter(function (item) { return item.key == qToAddAsOption_1; });
                                    if (existingAnswers_1 && existingAnswers_1.length > 0) {
                                        for (var p = 0; p < addOptionToQ.length; p++) {
                                            var _loop_3 = function (q) {
                                                if (!existingAnswers_1[q].deleted) {
                                                    if (!this_2.dynamicOptionsObject[addOptionToQ[p]]) {
                                                        this_2.dynamicOptionsObject[addOptionToQ[p]] = [];
                                                    }
                                                    if (!this_2.dynamicOptionsObject[addOptionToQ[p]].find(function (item) { return item.sak == existingAnswers_1[q].secondary_asset_key; })) {
                                                        this_2.dynamicOptionsObject[addOptionToQ[p]].push({ q: qToAddAsOption_1, sak: existingAnswers_1[q].secondary_asset_key, label: existingAnswers_1[q].value });
                                                    }
                                                }
                                            };
                                            for (var q = 0; q < existingAnswers_1.length; q++) {
                                                _loop_3(q);
                                            }
                                        }
                                    }
                                }
                            }
                        };
                        var this_2 = this;
                        for (var k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                            _loop_2(k);
                        }
                    }
                }
            }
        }
    };
    ViewProfilingDatasheetComponent.prototype.preProcessProjectUOMs = function () {
        var _this = this;
        this.processedUOMs = {};
        if (this.project && this.project.ProjectUOM && this.project.ProjectUOM.length > 0) {
            var _loop_4 = function (i) {
                if (!this_3.processedUOMs[this_3.project.ProjectUOM[i].unit_type_id]) {
                    this_3.processedUOMs[this_3.project.ProjectUOM[i].unit_type_id] = {};
                    this_3.processedUOMs[this_3.project.ProjectUOM[i].unit_type_id]['units'] = [];
                }
                var projectDefaultUnit = this_3.project.ProjectUOM.find(function (item) { return item.is_default && item.unit_type_id === _this.project.ProjectUOM[i].unit_type_id; });
                this_3.processedUOMs[this_3.project.ProjectUOM[i].unit_type_id]['projectDefaultUnit'] = projectDefaultUnit;
                this_3.processedUOMs[this_3.project.ProjectUOM[i].unit_type_id]['units'].push({
                    id: this_3.project.ProjectUOM[i].id,
                    international_unit: this_3.project.ProjectUOM[i].international_unit,
                    international_value: this_3.project.ProjectUOM[i].international_value,
                    uom: this_3.project.ProjectUOM[i].uom,
                    conversionFactorToProjectDefault: this_3.project.ProjectUOM[i].international_value / projectDefaultUnit.international_value
                });
            };
            var this_3 = this;
            for (var i = 0; i < this.project.ProjectUOM.length; i++) {
                _loop_4(i);
            }
        }
    };
    ViewProfilingDatasheetComponent.prototype.canDoFarmerMgmtFn = function () {
        var _this = this;
        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
        }
        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
        }
        if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
            var canDoFarmerMgmt = this.usersServiceComponentMappings.filter(function (item) { return (item.services_id == 2 && item.user_type_id == _this.currentUser.role.id); });
            if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
                this.canDoFarmerMgmt = true;
            }
        }
        else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
            var canDoFarmerMgmt = this.ecosystemServicesComponentsMapping.filter(function (item) { return item.services_id == 2; });
            if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
                this.canDoFarmerMgmt = true;
            }
        }
    };
    ViewProfilingDatasheetComponent.prototype.setCurrencyCode = function () {
        if (this.project.Currency && this.project.Currency.currency_code) {
            this.currencyCode = this.project.Currency.currency_code;
        }
        else {
            this.currencyCode = "";
        }
    };
    ViewProfilingDatasheetComponent.prototype.setQuestionnaireAttributes = function () {
        if (this.questionnaire && this.questionnaire.length > 0) {
            for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (this.questionnaire[i].sections[j].displayFunction) {
                        var x = JSON.parse(this.questionnaire[i].sections[j].displayFunction);
                        if (x.type && x.type === 'income') {
                            this.questionnaire[i].sections[j]['isIncome'] = true;
                        }
                        else if (x.type && x.type === 'expense') {
                            this.questionnaire[i].sections[j]['isExpense'] = true;
                        }
                    }
                }
            }
        }
    };
    ViewProfilingDatasheetComponent.prototype.validIndex = function (index) {
        if (index != null && index > -1) {
            return true;
        }
        return false;
    };
    ViewProfilingDatasheetComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    ViewProfilingDatasheetComponent.prototype.existsInQAnswersForTableQuestion = function (question) {
        return this.qAnswers.filter(function (item) { return item.key == question.id && !item.deleted && !item.unchecked; });
    };
    // Check if a given question has answer(s) in the qAnswers array. This should be used for non-table questions only
    ViewProfilingDatasheetComponent.prototype.existsInQAnswers = function (question) {
        if (!question.is_table_question) {
            var x = void 0;
            if (question.qa_subtype === 'multiselect') {
                x = this.qAnswers.filter(function (item) { return item.key == question.id; });
            }
            else {
                x = this.qAnswers.find(function (item) { return item.key == question.id; });
            }
            return x;
        }
    };
    ViewProfilingDatasheetComponent.prototype.setOtherQuestion = function (question, secondaryAssetKey) {
        if (question.options && question.options.length > 0) {
            if (question.options.find(function (item) { return item.qa_subtype == 'other'; })) {
                if (secondaryAssetKey) {
                    var existsInQAnswersForTableQuestion = this.existsInQAnswersForTableQuestion(question.other_questions[0]);
                    if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
                        for (var i = 0; i < existsInQAnswersForTableQuestion.length; i++) {
                            if (existsInQAnswersForTableQuestion[i].secondary_asset_key == secondaryAssetKey) {
                                question.otherAnsText = existsInQAnswersForTableQuestion ? existsInQAnswersForTableQuestion[i].value : null;
                            }
                        }
                    }
                }
                else {
                    var existsInQAnswers = this.existsInQAnswers(question.other_questions[0]);
                    question.otherAnsText = existsInQAnswers ? existsInQAnswers['value'] : null;
                }
            }
        }
        return question;
    };
    ViewProfilingDatasheetComponent.prototype.setAnswerForQuestion = function (question) {
        question.answerText = null;
        var answerDetails = this.qAnswers.filter(function (a) { return a.key == question.id; });
        if (question.qa_subtype == "text" || question.qa_subtype == "number") {
            if (answerDetails && answerDetails.length > 0) {
                question.answerText = answerDetails[0].value;
            }
        }
        else if (question.qa_subtype == "select") {
            if (answerDetails && answerDetails.length > 0) {
                var optionsValue = question.options.filter(function (op) { return op.id == answerDetails[0].value; });
                if (optionsValue && optionsValue.length > 0) {
                    question.answerText = optionsValue[0].survey_q_asset_labels[0].label;
                }
            }
        }
        else if (question.qa_subtype == "multiselect") {
            question.answerText = null;
            if (answerDetails && answerDetails.length > 0) {
                var optionsIds = [];
                var multiAns = [];
                for (var _i = 0, answerDetails_1 = answerDetails; _i < answerDetails_1.length; _i++) {
                    var ans = answerDetails_1[_i];
                    optionsIds.push(ans.value);
                }
                for (var _a = 0, _b = question.options; _a < _b.length; _a++) {
                    var op = _b[_a];
                    if (optionsIds.indexOf(op.id + "") > -1 && op.qa_subtype != "other") {
                        multiAns.push(op.survey_q_asset_labels[0].label);
                    }
                    else if (optionsIds.indexOf(op.id + "") > -1 && op.qa_subtype == "other") {
                        multiAns.push(question.other_questions[0].survey_q_asset_labels[0].label);
                    }
                }
                question.answerText = multiAns.join(" , ");
            }
        }
        question = this.setOtherQuestion(question);
        return question;
    };
    ViewProfilingDatasheetComponent.prototype.setAnswerForUOMQuestion = function (uomQuestion) {
        uomQuestion.answerText = null;
        var answerDetails = this.qAnswers.filter(function (a) { return a.key == uomQuestion.id; });
        uomQuestion.options = this.project.ProjectUOM.filter(function (item) { return item.unit_type_id == uomQuestion.qa_subtype; });
        if (answerDetails && answerDetails.length > 0) {
            var optionsValue = uomQuestion.options.filter(function (op) { return op.id == answerDetails[0].value; });
            if (optionsValue && optionsValue.length > 0) {
                uomQuestion.answerText = optionsValue[0].international_unit;
            }
        }
        return uomQuestion;
    };
    ViewProfilingDatasheetComponent.prototype.setAnswerToQuestions = function () {
        // this.questionnaire
        // this.qAnswers
        for (var _i = 0, _a = this.questionnaire; _i < _a.length; _i++) { // tabs
            var currentTab = _a[_i];
            for (var _b = 0, _c = currentTab.sections; _b < _c.length; _b++) {
                var section = _c[_b];
                for (var _d = 0, _e = section.questions; _d < _e.length; _d++) {
                    var question = _e[_d];
                    question = this.setAnswerForQuestion(question);
                    if (question.include_uom_question) {
                        for (var _f = 0, _g = question.uom_questions; _f < _g.length; _f++) {
                            var uomQ = _g[_f];
                            uomQ = this.setAnswerForUOMQuestion(uomQ);
                        }
                    }
                }
                for (var _h = 0, _j = section.subsections; _h < _j.length; _h++) {
                    var subsections = _j[_h];
                    for (var _k = 0, _l = subsections.questions; _k < _l.length; _k++) {
                        var question = _l[_k];
                        question = this.setAnswerForQuestion(question);
                        if (question.include_uom_question) {
                            for (var _m = 0, _o = question.uom_questions; _m < _o.length; _m++) {
                                var uomQ = _o[_m];
                                uomQ = this.setAnswerForUOMQuestion(uomQ);
                            }
                        }
                    }
                }
                for (var _p = 0, _q = section.tables; _p < _q.length; _p++) {
                    var tables = _q[_p];
                    var _loop_5 = function (i) {
                        var existsInQAnswersForTableQuestion = this_4.existsInQAnswersForTableQuestion(tables.questions[i]);
                        this_4.secondary_asset_keys[tables.id] = existsInQAnswersForTableQuestion.map(function (item) { return item.secondary_asset_key; })
                            .filter(function (value, index, self) { return self.indexOf(value) === index; });
                        if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
                            if (tables.questions[i].qa_subtype == "multiselect") {
                                // console.log("existsInQAnswersForTableQuestion",existsInQAnswersForTableQuestion);
                            }
                            var _loop_6 = function (p) {
                                var tableKey = tables.id + tables.questions[i].id + existsInQAnswersForTableQuestion[p].secondary_asset_key;
                                this_4.tableQuestionsAnswer[tableKey] =
                                    {
                                        "value": null,
                                        "otherValue": null,
                                        "uomAnswer": null
                                    };
                                if (tables.questions[i].qa_subtype == "text" || tables.questions[i].qa_subtype == "number") {
                                    this_4.tableQuestionsAnswer[tableKey].value = existsInQAnswersForTableQuestion[p].value;
                                }
                                else if (tables.questions[i].qa_subtype == "select") {
                                    if (existsInQAnswersForTableQuestion[p]) {
                                        var optionsValue = tables.questions[i].options.filter(function (op) { return op.id == existsInQAnswersForTableQuestion[p].value; });
                                        if (optionsValue && optionsValue.length > 0) {
                                            this_4.tableQuestionsAnswer[tableKey].value = optionsValue[0].survey_q_asset_labels[0].label;
                                        }
                                    }
                                }
                                else if (tables.questions[i].qa_subtype == "multiselect") {
                                    // console.log("existsInQAnswersForTableQuestion",existsInQAnswersForTableQuestion);
                                    //   if(answerDetails && answerDetails.length>0){
                                    //     let optionsIds = [];
                                    //     let multiAns = []
                                    //     for(let ans of answerDetails){
                                    //       optionsIds.push(ans.value);
                                    //     }
                                    //     for(let op of question.options){
                                    //       if(optionsIds.indexOf(op.id + "")>-1 && op.qa_subtype != "other"){
                                    //         multiAns.push(op.survey_q_asset_labels[0].label);
                                    //       }else if(optionsIds.indexOf(op.id + "")>-1 && op.qa_subtype == "other"){
                                    //         multiAns.push(question.other_questions[0].survey_q_asset_labels[0].label);
                                    //       }
                                    //     }
                                    //     question.answerText = multiAns.join(" , ");
                                    //   }
                                }
                            };
                            for (var p = 0; p < existsInQAnswersForTableQuestion.length; p++) {
                                _loop_6(p);
                            }
                        }
                    };
                    var this_4 = this;
                    for (var i = 0; i < tables.questions.length; i++) {
                        _loop_5(i);
                    }
                }
            }
        }
    };
    ViewProfilingDatasheetComponent.prototype.setVCMapping = function () {
        if (this.farmer) {
            if (this.farmer.vc_farmer_mappings && this.farmer.vc_farmer_mappings.length > 0) {
                if (this.farmer.vc_farmer_mappings[0].status == 'Active') {
                    this.farmer.village_collector = this.farmer.vc_farmer_mappings[0].users.name;
                }
            }
        }
    };
    ViewProfilingDatasheetComponent.prototype.getMasterData = function (code, value) {
        var answer = this.masterDataFarmer.filter(function (v) {
            return v.master_id == value && v.item_code == code;
        });
        if (answer.length > 0) {
            return answer[0].name;
        }
        else {
            return null;
        }
    };
    return ViewProfilingDatasheetComponent;
}());
export { ViewProfilingDatasheetComponent };
