import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class DataConsentService {
  
  private readonly dataConsentURL = this.appConfigService.getUrl(this.appConfigService.DATACONSENT);

  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) { }

  getFarmerConsents(projectID): Observable<any> {
    return this.http.get<any>(`${this.dataConsentURL}/farmer_consents/${projectID}`);
  }

  getConsentTemplate(projectID, templateId?): Observable<any> {
    return templateId ? this.http.get<any>(`${this.dataConsentURL}/consents_template/${projectID}/${templateId}`) : 
    this.http.get<any>(`${this.dataConsentURL}/consents_template/${projectID}`);
  }

  getFarmerConsentHistory(projectID, farmerID?): Observable<any> {
    return farmerID ? this.http.get<any>(`${this.dataConsentURL}/farmer_consents_history/${projectID}/${farmerID}`) :
    this.http.get<any>(`${this.dataConsentURL}/farmer_consents_history/${projectID}`);
  }  

  saveFarmerConsent(projectId, farmerId, data): Observable<any> {
    return this.http.post<any>(`${this.dataConsentURL}/farmer_consents/${projectId}/${farmerId}`, data);
  }

  getFarmerConsentForFarmers(projectId, data): Observable<any> {
    return this.http.post<any>(`${this.dataConsentURL}/farmer_consent_signature/${projectId}`, data);
  }
  
}