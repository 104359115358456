import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Routes } from '@angular/router';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale, esLocale, enGbLocale, zhCnLocale, hiLocale, idLocale } from 'ngx-bootstrap/locale';
defineLocale('en');
defineLocale('fr', frLocale);
defineLocale('es', esLocale);
defineLocale('ta', enGbLocale);
defineLocale('mg', enGbLocale);
defineLocale('zh', zhCnLocale);
defineLocale('hi', hiLocale);
defineLocale('id', idLocale);
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
// Guards
import { AuthguardGuard } from './gaurd/authguard.guard';
import { NavigationGuard } from './gaurd/navigation.guard';
import { RememberMeGuard } from './gaurd/rememberme.guard';
// Locales
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeMg from '@angular/common/locales/mg';
import localeZh from '@angular/common/locales/zh';
import localeHi from '@angular/common/locales/hi';
import localeId from '@angular/common/locales/id';
// import localeTa from '@angular/common/locales/ta';
registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'ta');
registerLocaleData(localeMg, 'mg');
registerLocaleData(localeZh, 'zh');
registerLocaleData(localeHi, 'hi');
registerLocaleData(localeId, 'ba');
// Resolvers
import { CommonResolver } from './resolvers/common.resolver';
import { ProjectNewResolver } from './resolvers/project-new.resolver';
import { FarmerResolver } from './resolvers/farmer.resolver';
import { DashboardResolver } from './resolvers/dashboard.resolver';
import { ProfileResolver } from './resolvers/profile.resolver';
import { RegisterResolver } from './resolvers/register.resolver';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { UserComponent } from './components/user/user.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ManagerpageComponent } from './components/managerpage/managerpage.component';
import { SetupComponent } from './components/setup/setup.component';
import { ProjectinfoComponent } from './components/projectinfo/projectinfo.component';
import { UserListingComponent } from './components/user-listing/user-listing.component';
import { HistorydataComponent } from './components/historydata/historydata.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { ForgetpasswordComponent } from './components/forgetpassword/forgetpassword.component';
import { FarmerProfileUiComponent } from './farmer-profile-ui/farmer-profile-ui.component';
import { MdmComponent } from './components/mdm/mdm.component';
import { SupplierComponent } from './components/supplier/supplier.component';
import { ProductComponent } from './components/product/product.component';
import { FarmermanagementComponent } from './components/farmermanagement/farmermanagement.component';
import { RegisterFarmerComponent } from './components/register-farmer/register-farmer.component';
import { DesignProjectListComponent } from './design-project-list/design-project-list.component';
import { AdministrationComponent } from './components/administration/administration.component';
import { ProfilingComponent } from './components/profiling/profiling.component';
import { ProjectManagementComponent } from './project-management/project-management.component';
import { ResetusernameandpasswordComponent } from './components/resetusernameandpassword/resetusernameandpassword.component';
import { RouteUserComponent } from './route-user/route-user.component';
import { ResetuseridComponent } from './components/resetuserid/resetuserid.component';
import { EcosystemmanagementComponent } from './components/ecosystemmanagement/ecosystemmanagement.component';
import { GovernanceComponent } from './components/governance/governance.component';
import { EcosystemsettingsComponent } from './components/ecosystemsettings/ecosystemsettings.component';
import { EditEcosystemSettingsComponent } from './components/edit-ecosystem-settings/edit-ecosystem-settings.component';
import { FarmerdetailsComponent } from './components/farmerdetails/farmerdetails.component';
import { ApiManagementComponent } from './components/api-management/api-management.component';
import { ReportingmanagementComponent } from './components/reportingmanagement/reportingmanagement.component';
import { DashboardsListComponent } from './components/highcharts-reporting/dashboards-list/dashboards-list.component';
import { DashboardAddComponent } from './components/highcharts-reporting/dashboard-add/dashboard-add.component';
import { DashboardEditComponent } from './components/highcharts-reporting/dashboard-edit/dashboard-edit.component';
import { RunReportComponent } from './components/highcharts-reporting/run-report/run-report.component';
import { DashboardViewReportsComponent } from './components/highcharts-reporting/dashboard-view-reports/dashboard-view-reports.component';
import { IntegratedDashboardComponent } from './components/highcharts-reporting/integrated-dashboard/integrated-dashboard.component';
import { FarmsProfileComponent } from './components/highcharts-reporting/farms-profile/farms-profile.component';
import { CommunityLivelihoodComponent } from './components/highcharts-reporting/community-livelihood/community-livelihood.component';
import { GoodFarmingPracticesComponent } from './components/highcharts-reporting/good-farming-practices/good-farming-practices.component';
import { CollectionNetworkComponent } from './components/highcharts-reporting/collection-network/collection-network.component';
import { KpiIndicatorsComponent } from './components/highcharts-reporting/kpi-indicators/kpi-indicators.component';
import { SurveysComponent } from './components/surveys/surveys.component';
import { DynamicQuestionnaireComponent } from './components/dynamic-questionnaire/dynamic-questionnaire.component';
import { EditDynamicQuestionnaireComponent } from './components/edit-dynamic-questionnaire/edit-dynamic-questionnaire.component';
import { PreviewTemplateComponent } from './components/preview-template/preview-template.component';
import { Edq2Component } from './components/edq2/edq2.component';
import { ViewProfilingDatasheetComponent } from './components/view-profiling-datasheet/view-profiling-datasheet.component';
import { Vpd2Component } from './components/vpd2/vpd2.component';
import { ProjectGroupMappingComponent } from './components/project-group-mapping/project-group-mapping.component';
import { ComparedataComponent } from './components/highcharts-reporting/comparedata/comparedata.component';
import { AnswerComparisonComponent } from './components/highcharts-reporting/answer-comparison/answer-comparison.component';
import { ViewQuestionnaireComponent } from './components/view-questionnaire/view-questionnaire.component';
import { CampaignManagementComponent } from './components/campaign-management/campaign-management.component';
import { CampaignSetupComponent } from './components/campaign-setup/campaign-setup.component';
import { CampaignProfilingComponent } from './components/campaign-profiling/campaign-profiling.component';
import { CampaignProfilingFarmersComponent } from './components/campaign-profiling-farmers/campaign-profiling-farmers.component';
import { TrainingManagementComponent } from './components/training-management/training-management.component';
import { AddTrainingComponent } from './components/add-training/add-training.component';
import { ProjectTrainingMappingComponent } from './components/project-training-mapping/project-training-mapping.component';
import { EditTrainingComponent } from './components/edit-training/edit-training.component';
import { EditCertificationComponent } from './components/edit-certification/edit-certification.component';
import { VisitManagementComponent } from './components/visit-management/visit-management.component';
import { ConsolidatedReportComponent } from './components/consolidated-report/consolidated-report.component';
// network status module
//import { NgNetworkStatusModule } from 'ng-network-status';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient) {
    return new TranslateHttpLoader(httpClient);
}
var appRoutes = [
    {
        path: 'users',
        pathMatch: 'full',
        children: [
            { path: ':name', component: UserComponent },
            { path: ':name/:id', component: UserComponent }
        ]
    },
    { path: 'setup',
        resolve: { commonData: CommonResolver },
        children: [
            { path: '', component: SetupComponent, canActivate: [AuthguardGuard], canDeactivate: [NavigationGuard], },
            { path: ':id', component: SetupComponent, canActivate: [AuthguardGuard], canDeactivate: [NavigationGuard], }
        ]
    },
    { path: 'projectinfo/:id', canActivate: [AuthguardGuard], component: ProjectinfoComponent,
        resolve: { project: ProjectNewResolver } },
    { path: 'projectlist', canActivate: [AuthguardGuard], component: DesignProjectListComponent, resolve: { dashboard: DashboardResolver, commonData: CommonResolver } },
    { path: 'ecosystems', canActivate: [AuthguardGuard], component: GovernanceComponent },
    { path: 'api-management', canActivate: [AuthguardGuard], component: ApiManagementComponent },
    { path: 'register', canActivate: [AuthguardGuard], component: RegisterFarmerComponent, resolve: { project: RegisterResolver } },
    { path: 'questionnaires/:id', canActivate: [AuthguardGuard], component: SurveysComponent, resolve: { project: ProjectNewResolver } },
    { path: 'dashboard', canActivate: [AuthguardGuard], component: ManagerpageComponent,
        resolve: { commonData: CommonResolver, project: RegisterResolver } },
    { path: 'projectmanagement', canActivate: [AuthguardGuard], component: ProjectManagementComponent, resolve: { commonData: CommonResolver } },
    { path: 'ecosystemmanagement', canActivate: [AuthguardGuard], component: EcosystemmanagementComponent },
    { path: 'projectgroupmapping', canActivate: [AuthguardGuard], component: ProjectGroupMappingComponent },
    { path: 'profilingui',
        children: [
            { path: ':projectId/:id', component: FarmerProfileUiComponent, canActivate: [AuthguardGuard],
                resolve: { farmerData: FarmerResolver }, canDeactivate: [NavigationGuard] },
        ]
    },
    { path: 'administration', component: AdministrationComponent },
    { path: 'route', component: RouteUserComponent },
    { path: 'goto/:navId', component: MdmComponent },
    { path: 'mdminfo', component: SupplierComponent, resolve: { commonData: CommonResolver } },
    { path: 'farmers/:id', canActivate: [AuthguardGuard], component: FarmermanagementComponent, resolve: { project: ProjectNewResolver } },
    { path: 'profiling/:id', canActivate: [AuthguardGuard], component: ProfilingComponent, resolve: { project: ProjectNewResolver } },
    { path: 'productinfo', component: ProductComponent },
    { path: 'userlisting', component: UserListingComponent, canActivate: [AuthguardGuard],
        resolve: { commonData: CommonResolver } },
    { path: 'viewprofile/:projectId/:id', component: ViewProfilingDatasheetComponent,
        canActivate: [AuthguardGuard], resolve: { farmerData: ProfileResolver } },
    { path: 'historydata', component: HistorydataComponent, canActivate: [AuthguardGuard] },
    { path: 'forgetpassword', component: ForgetpasswordComponent },
    { path: 'resetpassword', component: ResetpasswordComponent },
    { path: 'resetusernamepassword', component: ResetusernameandpasswordComponent },
    { path: 'resetuserid', component: ResetuseridComponent },
    { path: 'ecosystem-settings', canActivate: [AuthguardGuard], component: EcosystemsettingsComponent },
    { path: 'ecosystem-settings/edit/:id', component: EditEcosystemSettingsComponent, resolve: { commonData: CommonResolver } },
    { path: 'farmerdetails/:projectId/:id', component: FarmerdetailsComponent, resolve: { farmerData: FarmerResolver }, canDeactivate: [NavigationGuard] },
    { path: 'charts/:id', component: ReportingmanagementComponent, canActivate: [AuthguardGuard] },
    { path: 'charts/dashboards/:id', component: DashboardsListComponent },
    { path: 'charts/dashboards/add/:id', component: DashboardAddComponent },
    { path: 'charts/dashboards/edit/:id2/:id', component: DashboardEditComponent },
    { path: 'charts/runreport/:id', component: RunReportComponent },
    { path: 'charts/dashboards/viewreports/:id2/:id', component: DashboardViewReportsComponent },
    { path: 'charts/dashboards/profilingsynopsis/:id2/:id', component: IntegratedDashboardComponent },
    { path: 'charts/dashboards/communitylivelihood/:id2/:id', component: CommunityLivelihoodComponent },
    //added by Kiran for vanilla dashboard
    { path: 'charts/dashboards/farmsprofile/:id2/:id', component: FarmsProfileComponent },
    { path: 'charts/dashboards/goodfarming/:id2/:id', component: GoodFarmingPracticesComponent },
    { path: 'charts/dashboards/collections/:id2/:id', component: CollectionNetworkComponent },
    { path: 'charts/kpiindicators/:id', component: KpiIndicatorsComponent },
    { path: 'charts/comparedata/:id', component: ComparedataComponent },
    { path: 'charts/answercompare/:id', component: AnswerComparisonComponent },
    //added by KRPT for consolidated report
    { path: 'charts/consolidatedreport/:id', component: ConsolidatedReportComponent },
    { path: '', component: LoginFormComponent, canActivate: [RememberMeGuard] },
    { path: 'viewquestionnaire/:id/:surveyid', component: ViewQuestionnaireComponent },
    { path: 'questionnaire',
        children: [
            { path: 'edit/:projectId/:surveyId', component: Edq2Component },
            { path: 'edit2/:projectId/:surveyId', component: EditDynamicQuestionnaireComponent },
            { path: 'respond/:projectId/:farmerId', component: DynamicQuestionnaireComponent, canActivate: [AuthguardGuard], canDeactivate: [NavigationGuard] },
            { path: 'template/:projectId/:surveyId/:templateId', component: PreviewTemplateComponent },
            { path: 'view/:projectId/:farmerId', component: Vpd2Component, canActivate: [AuthguardGuard], canDeactivate: [NavigationGuard] },
        ]
    },
    { path: 'campaignmanagement', component: CampaignManagementComponent },
    { path: 'campaign-setup',
        children: [
            { path: '', component: CampaignSetupComponent, canActivate: [AuthguardGuard], canDeactivate: [NavigationGuard], },
            { path: ':id', component: CampaignSetupComponent, canActivate: [AuthguardGuard], canDeactivate: [NavigationGuard], }
        ]
    },
    { path: 'campaign-profiling/:id', canActivate: [AuthguardGuard], component: CampaignProfilingComponent },
    { path: 'campaign-profiling-farmers/:id/:campaignId', canActivate: [AuthguardGuard], component: CampaignProfilingFarmersComponent, resolve: { project: ProjectNewResolver } },
    { path: 'training-management',
        children: [
            { path: ':id', canActivate: [AuthguardGuard], component: TrainingManagementComponent },
            { path: 'add/:type/:id', canActivate: [AuthguardGuard], component: AddTrainingComponent },
            { path: 'edit/training/:id/:typeid', canActivate: [AuthguardGuard], component: EditTrainingComponent },
            { path: 'edit/certification/:id/:typeid', canActivate: [AuthguardGuard], component: EditCertificationComponent }
        ]
    },
    { path: 'visit-management/:id', canActivate: [AuthguardGuard], component: VisitManagementComponent },
    { path: 'projecttrainingmapping', canActivate: [AuthguardGuard], component: ProjectTrainingMappingComponent },
    { path: '**', component: NotfoundComponent }
];
export function getBaseHref(platformLocation) {
    // console.log('platformLocation.getBaseHrefFromDOM()' + platformLocation.getBaseHrefFromDOM());
    return platformLocation.getBaseHrefFromDOM();
}
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, './assets/lang/', '.json');
}
var ɵ0 = (createTranslateLoader);
// export function getDaterangepickerConfig(): BsDaterangepickerConfig {
//   return Object.assign(new BsDaterangepickerConfig(), {
//     dateInputFormat: 'YYYY-MM-DD'
//   });
// }
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
