import { CompactType, GridType, DisplayGrid } from 'angular-gridster2';
import * as i0 from "@angular/core";
var DashboardAddService = /** @class */ (function () {
    function DashboardAddService() {
        this.options = {
            gridType: GridType.VerticalFixed,
            displayGrid: DisplayGrid.None,
            compactType: CompactType.None,
            enableEmptyCellClick: true,
            minCols: 3,
            maxCols: 3,
            minRows: 6,
            maxRows: 100,
            mobileBreakPoint: 640,
            minItemRows: 0,
            fixedRowHeight: 145,
            keepFixedHeightInMobile: false,
            keepFixedWidthInMobile: true,
            //emptyCellClickCallback: this.emptyCellClick.bind(this),
            pushItems: true,
            pushResizeItems: false,
            draggable: {
                enabled: true,
                stop: function (event, $element, widget) {
                }
            },
            resizable: {
                enabled: false
            }
        };
        this.layout = [];
        this.components = [];
    }
    DashboardAddService.prototype.deleteItem = function (id) {
        var item = this.layout.find(function (d) { return d.id === id; });
        this.layout.splice(this.layout.indexOf(item), 1);
        //const comp = this.components.find(c => c.id === id);
        //this.components.splice(this.components.indexOf(comp), 1);
    };
    DashboardAddService.prototype.setDropId = function (dropId) {
        this.dropId = dropId;
    };
    DashboardAddService.prototype.dropItem = function (dragId) {
        var _this = this;
        var _a;
        var components = this.components;
        var comp = components.find(function (c) { return c.id === _this.dropId; });
        var updateIdx = comp ? components.indexOf(comp) : components.length;
        var componentItem = {
            id: this.dropId,
            componentRef: dragId
        };
        this.components = Object.assign([], components, (_a = {}, _a[updateIdx] = componentItem, _a));
    };
    DashboardAddService.prototype.removeItem = function (item) {
        this.layout.splice(this.layout.indexOf(item), 1);
    };
    DashboardAddService.prototype.getComponentRef = function (id) {
        var comp = this.components.find(function (c) { return c.id === id; });
        return comp ? comp.componentRef : null;
    };
    DashboardAddService.prototype.addChart = function (chartData, callType) {
        if (callType == "edit") {
            this.layout.push({ id: chartData.reportId, cols: chartData.src.dim_col, rows: chartData.src.dim_row, y: chartData.src.y, x: chartData.src.x, maxSizeX: 1, maxsizeY: 1, resizeEnabled: false, compactEnabled: false, src: chartData.src.chartOptions, componentName: 'Chart' });
        }
        else if (callType == "view") {
            this.layout.push({ id: chartData.reportId, cols: chartData.src.dim_col, rows: chartData.src.dim_row, y: chartData.src.y, x: chartData.src.x, maxSizeX: 1, maxsizeY: 1, dragEnabled: false, resizeEnabled: false, compactEnabled: false, src: chartData.src.chartOptions, componentName: 'Chart' });
        }
        else {
            this.layout.push({ id: chartData.reportId, cols: chartData.dim_col, rows: chartData.dim_row, y: 0, x: 0, maxSizeX: 1, maxsizeY: 1, resizeEnabled: false, compactEnabled: false, src: chartData, componentName: 'Chart' });
        }
        this.options.api.optionsChanged();
    };
    DashboardAddService.prototype.addKPI = function (kpiData, callType) {
        if (callType == "edit") {
            this.layout.push({ id: kpiData.id, cols: kpiData.dim_col, rows: kpiData.dim_row, y: kpiData.y, x: kpiData.x, maxSizeX: 1, maxsizeY: 1, resizeEnabled: false, compactEnabled: false, src: kpiData, componentName: 'KPI' });
        }
        else if (callType == "view") {
            this.layout.push({ id: kpiData.id, cols: kpiData.dim_col, rows: kpiData.dim_row, y: kpiData.y, x: kpiData.x, maxSizeX: 1, maxsizeY: 1, dragEnabled: false, resizeEnabled: false, compactEnabled: false, src: kpiData, componentName: 'KPI' });
        }
        else {
            {
                this.layout.push({ id: kpiData.id, cols: kpiData.dim_col, rows: kpiData.dim_row, y: 0, x: 0, maxSizeX: 1, maxsizeY: 1, resizeEnabled: false, compactEnabled: false, src: kpiData, componentName: 'KPI' });
            }
        }
        this.options.api.optionsChanged();
        console.log(this.layout);
    };
    DashboardAddService.prototype.deleteAll = function () {
        this.layout = [];
    };
    DashboardAddService.ngInjectableDef = i0.defineInjectable({ factory: function DashboardAddService_Factory() { return new DashboardAddService(); }, token: DashboardAddService, providedIn: "root" });
    return DashboardAddService;
}());
export { DashboardAddService };
