<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToReportingManagement(project?.id)" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)">
        <span i18n-title="@@projectReporting" title="Project Reporting">...</span>
      </li>      
      <li class="breadcrumb-item active" aria-current="page"><span i18n="@@compareData">Compare Data</span></li>
    </ol>
  </nav>
</div>
<div class="content-wrap content-wrapper background margin-btm-20 pb-0" [ngStyle]="{ overflow: viewAnswerComparison ? 'hidden' : 'visible' }">
  <div class="form-design form-box pb-0" *ngIf="!viewComparison && !viewAnswerComparison">
    <div class="row">
      <div class="col-md-12 col-lg-9">
        <label class="group-title inline-block mrbtm-two">
          <span i18n="@@compareData">Compare Data</span>
        </label>
      </div>
    </div>
    <div class="">
      <span class="mr-3">
        <label class="" i18n="@@selectCompareType">Select Compare Type</label>
      </span>
      <div class="form-check form-check-inline">
        <input type="radio" id="questions" name="comparetype" class="form-check-input cursorPointer" [value]="0" [(ngModel)]="unitTrustsPnl" (click)="reportTypeChange('question')" />
        <label class="form-check-label" for="questions" i18n="@@questions">Questions</label>
      </div>
      <div class="form-check form-check-inline">
        <input type="radio" id="answers" name="comparetype"class="form-check-input cursorPointer" [value]="1" [(ngModel)]="unitTrustsPnl" (click)="reportTypeChange('answer')"/>
        <label class="form-check-label" for="answers" i18n="@@answers">Answers</label>
      </div>
    </div>
    <div class="row mrbottomten">
      <div class="col-md-12 col-lg-3 col-xs-12">
        <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': target_snapshot.value == 0 && (target_snapshot.dirty || target_snapshot.touched) }">
          <label><span i18n="@@targetSnapshot">Target Snapshot </span><span *ngIf="compareAnswers">. <span i18n="@@revNo">Rev No.</span></span><span class="mandatory"> *</span></label>
          <select id="targetSelect" class="form-control" (change)="targetSelected($event, true)" [(ngModel)]="targetSnapshotId" name="target_snapshot"  #target_snapshot="ngModel" [ngClass]="(targetSnapshotId == 0 ? 'ng-invalid':'ng-valid')" >
            <option value="0" class="option" i18n="@@select">Select</option>
            <option value="currentVersion" *ngIf="compareAnswers && activeSnapshot">{{currentVersionText}}</option>
            <ng-container *ngFor="let list of snapshotData; trackBy: trackBySourceSnapshot">
              <option [ngValue]="list.id" *ngIf="list.survey_stage_changelogs[0].change_type != 'draft' && !compareAnswers">
                <span *ngIf="!list.flex2">{{list.name}} {{list.survey_type_sequence}}</span>
                <span *ngIf="list.flex2">{{list.flex2}}</span>
              </option>
              <ng-container *ngIf="compareAnswers">
                <ng-container *ngFor="let revision of list.revisions; trackBy: trackByRevisions">
                  <option value="{{list.id}}-{{revision.id}}" *ngIf="revision?.revision_mode == 'Manual'">
                    <span *ngIf="!list.flex2">{{list.name}} {{list.survey_type_sequence}}.{{revision.revision_number}}</span>
                    <span *ngIf="list.flex2">{{list.flex2}}.{{revision.revision_number}}</span>
                  </option>
                </ng-container>
              </ng-container>
            </ng-container>
          </select>
          <div class="error" *ngIf="target_snapshot.value == 0 && (target_snapshot.dirty || target_snapshot.touched)">
            <span i18n="@@enterValidInput">Enter valid input.</span>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-3 col-xs-12">
        <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': source_snapshot.value == 0 && (source_snapshot.dirty || source_snapshot.touched) }">
          <label><span i18n="@@sourceSnapshot">Source Snapshot </span><span *ngIf="compareAnswers">. <span i18n="@@revNo">Rev No.</span></span><span class="mandatory"> *</span></label>
          <select id="sourceSelect" class="form-control" [(ngModel)]="sourceSnapshotId" name="source_snapshot"  #source_snapshot="ngModel" [ngClass]="(sourceSnapshotId == 0 ? 'ng-invalid':'ng-valid')" [disabled]="disableSource">
            <option value="0" class="option" i18n="@@select">Select</option>
            <ng-container *ngFor="let list of sourceSnapshotData; trackBy: trackBySourceSnapshot">
              <option [ngValue]="list.id" *ngIf="!compareAnswers">
                <span *ngIf="!list.flex2">{{list.name}} {{list.survey_type_sequence}}</span>
                <span *ngIf="list.flex2">{{list.flex2}}</span>
              </option>
              <ng-container *ngIf="compareAnswers">
                <ng-container *ngFor="let revision of list.revisions; trackBy: trackByRevisions">
                  <option value="{{list.id}}-{{revision.id}}" *ngIf="revision?.revision_mode == 'Manual'">
                    <span *ngIf="!list.flex2">{{list.name}} {{list.survey_type_sequence}}.{{revision.revision_number}}</span>
                    <span *ngIf="list.flex2">{{list.flex2}}.{{revision.revision_number}}</span>
                  </option>
                </ng-container>
              </ng-container>
            </ng-container>
          </select>
          <div class="error" *ngIf="source_snapshot.value == 0 && (source_snapshot.dirty || source_snapshot.touched)">
            <span i18n="@@enterValidInput">Enter valid input.</span>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-3 col-xs-12">
        <div class="form-group no-margin-hr farmer-name-nmd" *ngIf="compareAnswers">
          <label><span i18n="@@farmerName">Farmer Name </span><span class="mandatory"> *</span></label>
          <!-- <select class="form-control" [(ngModel)]="selectedFarmerId" name="farmer_selection"  #farmer_selection="ngModel" [ngClass]="(selectedFarmerId == 0 ? 'ng-invalid':'ng-valid')">
            <option value="0" class="option" i18n="@@select">Select</option>
            <option [ngValue]="farmer.id" *ngFor="let farmer of revisonFarmers; trackBy: trackByRevisionFarmers">
              <span *ngIf="farmer.supplier_id">{{farmer.supplier_id}} - </span>
              <span>{{farmer.name}}</span>
            </option>
          </select>
          <div class="error" *ngIf="farmer_selection.value == 0 && (farmer_selection.dirty || farmer_selection.touched)">
            <span i18n="@@enterValidInput">Enter valid input.</span>
          </div> -->
          <ng-multiselect-dropdown
            [placeholder]="selectPlaceholder"
            [data]="revisonFarmers"
            [(ngModel)]="selectedFarmerIdDD"
            [settings]="dropdownSettings"
            [ngModelOptions]="{standalone: true}"
            (onSelect)="setFarmer($event)"
            #farmer_selection="ngModel" name="farmer_selection" onchange="this.setAttribute('required','')" [ngClass]="(selectedFarmerIdDD.length <= 0 ? 'ng-invalid':'ng-valid')" required [disabled]="disableSource"
            >
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="col-md-12 col-lg-3 col-xs-12 fltRgt datatable-header slidedownsearch">
        <div class="mt-35px">
          <button type="button" *ngIf="!compareAnswers" name="button" class="runBtn btn btn-primary btn-lg mr-3 ml-3" (click)="compareOverview()" [disabled]="targetSnapshotId == 0 || sourceSnapshotId == 0">
            <span i18n="@@run">Run</span>
          </button>
          <button type="button" *ngIf="compareAnswers" name="button" class="runBtn btn btn-primary btn-lg mr-3 ml-3" (click)="compareOverview()" [disabled]="targetSnapshotId == 0 || sourceSnapshotId == 0 || selectedFarmerIdDD.length == 0">
            <span i18n="@@run">Run</span>
          </button>
          <button type="button" name="button" class="btn btn-default-gray btn-lg" (click)="goToReportingManagement(project?.id)">
            <span i18n="@@cancel">Cancel</span>
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix" *ngIf="showOverview">
      <table class="table table-sm table-one-line weight-400" role="grid">
        <thead>
          <tr role="row">
            <th style="width:60%"></th>
            <th style="width:20%">
              <span *ngIf="!compareAnswers">{{targetSnapshot.surveyDetails.name}} {{targetSnapshot.surveyDetails.sequence}}</span>
              <span *ngIf="compareAnswers">{{revObjectText.targetText}}</span>
              <button class="btn-link view-button ml-2" (click)="viewSnapshot('target')" i18n="@@view">View</button>
            </th>
            <th style="width:20%">
              <span *ngIf="!compareAnswers">{{sourceSnapshot.surveyDetails.name}} {{sourceSnapshot.surveyDetails.sequence}}</span>
              <span *ngIf="compareAnswers">{{revObjectText.sourceText}}</span>
              <button class="btn-link view-button ml-2" (click)="viewSnapshot('source')" i18n="@@view">View</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="bold" i18n="@@totalSection">Total Section</td>
            <td>{{targetSnapshot?.numberOfSections}}</td>
            <td>{{sourceSnapshot?.numberOfSections}}</td>
          </tr>
          <tr>
            <td class="bold" i18n="@@totalSubsection">Total Subsection</td>
            <td>{{targetSnapshot?.numberOfSubsections}}</td>
            <td>{{sourceSnapshot?.numberOfSubsections}}</td>
          </tr>
          <tr>
            <td class="bold" i18n="@@totalQuestions">Total Questions</td>
            <td>{{targetSnapshot?.numberOfQuestions}}</td>
            <td>{{sourceSnapshot?.numberOfQuestions}}</td>
          </tr>
          <tr *ngIf="compareAnswers">
            <td class="bold" i18n="@@totalAnswers">Total Answers</td>
            <td>{{targetSnapshot?.total_answers}}</td>
            <td>{{sourceSnapshot?.total_answers}}</td>
          </tr>
          <!-- <tr>
            <td class="bold" i18n="@@newQuestions">New Questions</td>
            <td>{{targetSnapshot?.totalNewQuestions}}</td>
            <td>{{sourceSnapshot?.totalNewQuestions}}</td>
          </tr> -->
          <ng-container *ngIf="!compareAnswers else questionTr">
            <tr *ngFor="let item of targetSnapshot?.sortedTab">
              <td class="bold">{{item[0]}}</td>
              <td>{{item[1].changeInPercentage}}%</td>
              <td i18n="@@NA">NA</td>
            </tr>
          </ng-container>
          <ng-template #questionTr>
            <ng-container *ngFor="let item of targetSnapshot?.sortedTab">
              <tr>
                <td class="bold"><span i18n="@@questionIn">Questions in </span>{{item[0]}}</td>
                <td>{{item[1].numberOfQuestion}}</td>
                <td>
                  <span *ngFor="let item2 of sourceSnapshot?.sortedTab">
                    <span *ngIf="item[0] == item2[0]">{{item2[1].numberOfQuestion}}</span>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="bold"><span i18n="@@questionAnsweredIn">Questions Answered in </span>{{item[0]}}</td>
                <td>{{item[1].numberOfAnswers}}</td>
                <td>
                  <span *ngFor="let item2 of sourceSnapshot?.sortedTab">
                    <span *ngIf="item[0] == item2[0]">{{item2[1].numberOfAnswers}}</span>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="bold"><span i18n="@@percChangein">% Change in </span>{{item[0]}} <span i18n="@@answers"> Answers</span></td>
                <td>{{item[1].changeInPercentage}}%</td>
                <td i18n="@@NA">NA</td>
              </tr>
            </ng-container>
          </ng-template>
          <tr *ngIf="!compareAnswers">
            <td class="bold" i18n="@@templateName">Template Name</td>
            <td>
              <span *ngIf="targetSnapshot?.templateName == 'P2F_ROOTS_TEMPLATE'" i18n="@@erootsTemplate">P2F-Roots Template</span>
              <span *ngIf="targetSnapshot?.templateName == 'BLANK_TEMPLATE'" i18n="@@blankTemplate">Blank Template</span>
              <span *ngIf="targetSnapshot?.templateName != 'P2F_ROOTS_TEMPLATE' && targetSnapshot?.templateName != 'BLANK_TEMPLATE'">{{targetSnapshot?.templateName}}</span>
            </td>
            <td>
              <span *ngIf="sourceSnapshot?.templateName == 'P2F_ROOTS_TEMPLATE'" i18n="@@erootsTemplate">P2F-Roots Template</span>
              <span *ngIf="sourceSnapshot?.templateName == 'BLANK_TEMPLATE'" i18n="@@blankTemplate">Blank Template</span>
              <span *ngIf="sourceSnapshot?.templateName != 'P2F_ROOTS_TEMPLATE' && sourceSnapshot?.templateName != 'BLANK_TEMPLATE'">{{sourceSnapshot?.templateName}}</span>
            </td>
          </tr>
          <tr *ngIf="!compareAnswers">
            <td class="bold" i18n="@@snapshotCreatedOn">Snapshot Created On</td>
            <td>{{targetSnapshot?.surveyDetails?.created_on | date:'d-MMM-y h:mm a'}}</td>
            <td>{{sourceSnapshot?.surveyDetails?.created_on | date:'d-MMM-y h:mm a'}}</td>
          </tr>
          <tr>
            <td class="bold" i18n="@@snapshotStatus">Snapshot Status</td>
            <td>
              <span *ngIf="targetSnapshot?.surveyDetails?.status == 'Active'" i18n="@@active">Active</span>
              <span *ngIf="targetSnapshot?.surveyDetails?.status == 'Closed'" i18n="@@notActive">Closed</span>
            </td>
            <td>
              <span *ngIf="sourceSnapshot?.surveyDetails?.status == 'Active'" i18n="@@active">Active</span>
              <span *ngIf="sourceSnapshot?.surveyDetails?.status == 'Closed'" i18n="@@notActive">Closed</span>
            </td>
          </tr>
          <tr>
            <td class="bold" i18n="@@snapshotActivatedOn">Snapshot Activated On</td>
            <td>{{targetSnapshot?.surveyDetails?.activated_on | date:'d-MMM-y h:mm a'}}</td>
            <td>{{sourceSnapshot?.surveyDetails?.activated_on | date:'d-MMM-y h:mm a'}}</td>
          </tr>
          <tr>
            <td class="bold" i18n="@@snapshotClosedOn">Snapshot Closed On</td>
            <td>{{targetSnapshot?.surveyDetails?.closed_on | date:'d-MMM-y h:mm a'}}</td>
            <td>{{sourceSnapshot?.surveyDetails?.closed_on | date:'d-MMM-y h:mm a'}}</td>
          </tr>
        </tbody>
        <tfoot>
          <td colspan="3" class="text-center">
            <button class="btn btn-primary mb-4" (click)="showDifferenceSnapshot()">
              <span i18n="@@compareData">Compare Data</span>
            </button>
          </td>
        </tfoot>
      </table>
    </div>    
  </div>
  <div class="form-design form-box padB0 clearfix" *ngIf="viewComparison">
    <div class="row no-gutters">
      <h3 class="col-sm-6 font-1-1 mb-2 mt-0 pl-3">
        {{targetSnapshot.surveyDetails.name}} {{targetSnapshot.surveyDetails.sequence}} (<span i18n="@@target">Target</span>)
      </h3>
      <h3 class="col-sm-3 font-1-1 mb-2 mt-0 pl-3">
        {{sourceSnapshot.surveyDetails.name}} {{sourceSnapshot.surveyDetails.sequence}} (<span i18n="@@source">Source</span>)
      </h3>      
      <div class="col-sm-3 col-md-3 col-lg-3">
        <div class="indices fltRgt text-right mt-1">
          <span class="small-indices" i18n="@@edited">Edited</span>
          <span class="small-indices" i18n="@@added">Added</span>
          <span class="small-indices" i18n="@@deleted">Deleted</span>
        </div>
      </div>
    </div>
    <div class="text-center gray font-1-1 pt-4 pb-4" *ngIf="snapshotComparisonData.length == 0" i18n="@@bothSnapshotsIdentical">Both snapshots are identical.</div>
    <div class="scrollbar-1 pr-2">
      <!--EVERY SECTION NEW TABLE-->
      <table class="table-custom-one mb-4" role="grid" *ngFor="let tab of snapshotComparisonData;">
        <thead>
          <tr role="row">
            <th style="width:50%" class="section">
              <span>{{tab?.tab_name}}</span>
              <span class="badge badge-info ml-2" style="height:auto">
                <span style="font-size: 100%">{{tab.total_changes}}&nbsp;</span>
                <span style="font-size: 100%" *ngIf="tab.total_changes > 1" i18n="@@changes">Changes</span>
                <span style="font-size: 100%" *ngIf="tab.total_changes == 1" i18n="@@changeSingle">Change</span>
              </span>
            </th>
            <th style="width:50%" class="section"><span>{{tab?.tab_name}}</span></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let section of tab.sections;">
            <td colspan="2" style="padding:0!important;border:none!important">
              <table>
                <thead>
                  <tr role="row">
                    <th style="width:50%" class="subsection"><span>{{section?.section_name}}</span></th>
                    <th style="width:50%" class="subsection"><span>{{section?.section_name}}</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let question of section.questions;" class="weight-400">
                    <td style="width:50%" [ngClass]="question.marker == 'ADDED' ? 'added' : question.marker == 'UPDATED' ? 'edited' : 'deleted-q gray'">{{question.question}}</td>
                    <td style="width:50%">{{question.oldQuestion}}</td>
                  </tr>
                  <tr *ngFor="let subsection of section.subsections;">
                    <td colspan="2" style="padding:0!important;;border:none!important">
                      <table class="weight-400">
                        <thead>
                          <tr role="row">
                            <th style="width:50%" class="qTable"><span>{{subsection?.subsection_name}}</span></th>
                            <th style="width:50%" class="qTable"><span>{{subsection?.subsection_name}}</span></th>
                          </tr>
                        </thead>  
                        <tbody>
                          <tr *ngFor="let question of subsection.questions;">
                            <td style="width:50%" [ngClass]="question.marker == 'ADDED' ? 'added' : question.marker == 'UPDATED' ? 'edited' : question.marker == 'DELETED' ? 'deleted-q gray' : ''">{{question.question}}</td>
                            <td style="width:50%">{{question.oldQuestion}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>                    
                  <tr *ngFor="let table of section.tables;">
                    <td colspan="2" style="padding:0!important;;border:none!important">
                      <table class="weight-400">
                        <thead>
                          <tr role="row">
                            <th style="width:50%" class="qTable"><span>{{table?.table_name}}</span></th>
                            <th style="width:50%" class="qTable"><span>{{table?.table_name}}</span></th>
                          </tr>
                        </thead>  
                        <tbody>
                          <tr *ngFor="let question of table.questions;">
                            <td style="width:50%" [ngClass]="question.marker == 'ADDED' ? 'added' : question.marker == 'UPDATED' ? 'edited' : 'deleted-q gray'">{{question.question}}</td>
                            <td style="width:50%">{{question.oldQuestion}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-2 pt-3 text-center pb-3">
      <button type="button" name="button" class="btn btn-primary btn-lg"  (click)="closeComparison()">
        <span i18n="@@cancel">Cancel</span>
      </button>
    </div>
  </div>
  <div class="form-design form-box padB0 clearfix" style="margin-top: 40px;;" *ngIf="viewAnswerComparison">
    <app-answer-comparison></app-answer-comparison>
  </div>
  <div class="mb-4 text-center pb-3" *ngIf="viewAnswerComparison">
    <button type="button" name="button" class="btn btn-primary btn-lg"  (click)="closeAnswerComparison()">
      <span i18n="@@cancel">Cancel</span>
    </button>
  </div>
</div>
