import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-project-list',
  templateUrl: './design-project-list.component.html',
  styleUrls: ['./design-project-list.component.css']
})
export class DesignProjectListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  showDetailData1 : any;
  showDetailData2 : any;
  showDetailData3 : any;

  toggle1() {
    this.showDetailData1 = !this.showDetailData1;
  }
  toggle2() {
    this.showDetailData2 = !this.showDetailData2;
  }
  toggle3() {
    this.showDetailData3 = !this.showDetailData3;
  }

}
