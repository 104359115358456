/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "../../services/authentication.service";
import * as i5 from "@angular/router";
import * as i6 from "../../services/user.service";
import * as i7 from "../../services/login.service";
import * as i8 from "@angular/forms";
import * as i9 from "../../services/common.service";
import * as i10 from "../../services/storage.service";
import * as i11 from "ngx-toastr";
import * as i12 from "ngx-tour-ngx-bootstrap";
import * as i13 from "ngx-bootstrap/modal/bs-modal.service";
import * as i14 from "../../services/project.service";
import * as i15 from "@ngx-translate/core";
import * as i16 from "./reportingmanagement.component";
import * as i17 from "../../services/highchart-reports.service";
var styles_ReportingmanagementComponent = [".list-project[_ngcontent-%COMP%]   ul.mdm-box[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   div.box[_ngcontent-%COMP%]   h3[_ngcontent-%COMP%] {\n      text-transform: capitalize;\n      margin: 1px 0 0 0;\n      padding: 4px 0 2px 0;\n    }\n    .content-wrapper.background[_ngcontent-%COMP%]   .myprojects[_ngcontent-%COMP%]   .list-project[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   div.box[_ngcontent-%COMP%]   p[_ngcontent-%COMP%] {\n      min-height: 25px;\n    }"];
var RenderType_ReportingmanagementComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ReportingmanagementComponent, data: {} });
export { RenderType_ReportingmanagementComponent as RenderType_ReportingmanagementComponent };
function View_ReportingmanagementComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [["class", "col-lg-2 col-md-2 col-sm-6 col-xs-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "span", [["class", "box box_mh text-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.detailProjectReport(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.image; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.subtitle; _ck(_v, 6, 0, currVal_2); }); }
function View_ReportingmanagementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "mdm-box row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReportingmanagementComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.projectReporting; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ReportingmanagementComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "mdm-box row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "text-red"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Reports not available when offline"]))], null, null); }
export function View_ReportingmanagementComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, [["window", "data-synced"], ["window", "data-added"], ["window", "error-caching"], ["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("window:data-synced" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).updateSyncStatusShown($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:data-added" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).dataAdded($event) !== false);
        ad = (pd_1 && ad);
    } if (("window:error-caching" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).errorCaching($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:keydown.escape" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onKeydownHandler($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i0.ɵdid(1, 245760, null, 0, i3.HeaderComponent, [i4.AuthenticationService, i5.Router, i6.UserService, i7.LoginService, i8.FormBuilder, i9.CommonService, i10.StorageService, i1.Location, i0.PLATFORM_ID, i0.ChangeDetectorRef, i11.ToastrService, i12.ɵa, i13.BsModalService, i14.ProjectService, i15.TranslateService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 16, "div", [["class", "project-header clearfix pos-r"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 15, "nav", [["aria-label", "breadcrumb"], ["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 14, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "li", [["class", "breadcrumb-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["aria-current", "page"], ["class", "btn-back"], ["title", "Back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getBackProjectFilter(((_co.project == null) ? null : _co.project.name), ((_co.project == null) ? null : _co.project.id)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "i", [["class", "utilicon utilicon-arrow-alt-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "li", [["class", "breadcrumb-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "span", [["class", "btn-home"], ["routerLink", "/dashboard"], ["title", "Home"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 0, "img", [["alt", "Home"], ["src", "assets/images/home.webp"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 3, "li", [["aria-current", "page"], ["class", "breadcrumb-item active cursorPointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getBackProjectFilter(((_co.project == null) ? null : _co.project.name), ((_co.project == null) ? null : _co.project.id)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 2, "span", [], [[1, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(14, null, ["", "", ""])), i0.ɵpid(0, i1.SlicePipe, []), (_l()(), i0.ɵeld(16, 0, null, null, 2, "li", [["aria-current", "page"], ["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Project Reporting"])), (_l()(), i0.ɵeld(19, 0, null, null, 6, "div", [["class", "content-wrap content-wrapper background margin-btm-20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 5, "div", [["class", "myprojects"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 4, "div", [["class", "list-project"], ["id", "myList"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ReportingmanagementComponent_1)), i0.ɵdid(23, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["offlineMsg", 2]], null, 0, null, View_ReportingmanagementComponent_3))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "/dashboard"; _ck(_v, 10, 0, currVal_0); var currVal_4 = i0.ɵunv(_v, 23, 0, i0.ɵnov(_v, 24).transform(_co.online$)); var currVal_5 = i0.ɵnov(_v, 25); _ck(_v, 23, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.project == null) ? null : _co.project.name); _ck(_v, 13, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform(((_co.project == null) ? null : _co.project.name), 0, 15)); var currVal_3 = ((((_co.project == null) ? null : _co.project.name.length) > 15) ? "..." : ""); _ck(_v, 14, 0, currVal_2, currVal_3); }); }
export function View_ReportingmanagementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-reportingmanagement", [], null, null, null, View_ReportingmanagementComponent_0, RenderType_ReportingmanagementComponent)), i0.ɵdid(1, 114688, null, 0, i16.ReportingmanagementComponent, [i5.ActivatedRoute, i14.ProjectService, i11.ToastrService, i5.Router, i15.TranslateService, i6.UserService, i17.HighchartReportsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportingmanagementComponentNgFactory = i0.ɵccf("app-reportingmanagement", i16.ReportingmanagementComponent, View_ReportingmanagementComponent_Host_0, {}, {}, []);
export { ReportingmanagementComponentNgFactory as ReportingmanagementComponentNgFactory };
