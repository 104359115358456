/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./user.component";
import * as i3 from "@angular/router";
var styles_UserComponent = [i0.styles];
var RenderType_UserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserComponent, data: {} });
export { RenderType_UserComponent as RenderType_UserComponent };
export function View_UserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Hello, ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; _ck(_v, 1, 0, currVal_0); }); }
export function View_UserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user", [], null, null, null, View_UserComponent_0, RenderType_UserComponent)), i1.ɵdid(1, 114688, null, 0, i2.UserComponent, [i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserComponentNgFactory = i1.ɵccf("app-user", i2.UserComponent, View_UserComponent_Host_0, {}, {}, []);
export { UserComponentNgFactory as UserComponentNgFactory };
