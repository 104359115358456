import { Component,
  OnInit,
  OnDestroy,
  ViewChild, ElementRef, AfterViewInit, ComponentFactoryResolver, Renderer, ChangeDetectionStrategy, Renderer2, Inject, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Project, UserTypeServiceMapping } from '../../../models/project';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { chart } from 'highcharts';
import { SuppliersService } from '../../../services/suppliers.service';
import { ChartService } from '../../../services/chart.service';
import { HighchartReportsService } from '../../../services/highchart-reports.service';

//added by Kiran for Gridster
import { GridsterConfig, GridsterItem } from 'angular-gridster2';
import { DashboardAddService, IComponent } from '../../../services/dashboard-add.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


import { of } from 'rxjs';

const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
var parentScope01;

@Component({
  selector: 'app-dashboard-view-reports',
  templateUrl: './dashboard-view-reports.component.html',
  styleUrls: ['./dashboard-view-reports.component.css']
})
export class DashboardViewReportsComponent implements OnInit {
  @ViewChild('charts01') public chartElement01: ElementRef;

  @ViewChild('charts02') public chartElement02: ElementRef;
  preferredReportsType = 'predefined';
  public setAsDefault:boolean = false;
  public reportListType:any = [];
  reportTypes ='predefined';
  projects: any = [];
  project: any;
  projectId = 0;
  dashboardId;

  public dashboardEditObject ={
    "name":'',
    "description":'',
    "section":0,
    "subsection":'',
    "reportID":[],
    "newsection":'',
    "chartDataOptions":{"id":0,"name":'',"type":'',"sortOrder":0, "x":0, "y":0,"dim_col":0,"dim_row":0,"chartOptions":{}}
  }
  newSubSection: any[];
  newReportName: any[];
  reportsListData:any[];
  reportsListDataCustom:any[];
  subsectionFlag: boolean = true;
  reportsFlag: boolean = true;
  selectedReportsMultiSelect = [];
  selectedReportsMultiSelectCustom = [];
  dropdownSettings = {};
  listsSectionsSubSection = [];
  chartsOptions=[];
  sectionDropDown= [];
  subSectionDropdown = [];
  listOfReport = [];
  exportBtns:boolean = false;
  reportAvailableFlag:boolean = false;
  dashboardData = [];
  existingSelectedReports = [];
  public customReportsListData:any = [];
  checkChartsAvailable = [];
  profilingFlag:boolean = false;
  customFlag:boolean = false;
  localChartsList = [];

  //added by Kiran for Gridster
  title = "Data Dashoard";

  currentAdIndex = -1;
  interval: any;
  data: any;
  center: any;
  zoom = [5];
  pitch: number;
  private timer: number;
  height = 300;
  kpisListData = [];
  Highcharts = Highcharts;
  dashboardIDs = []
  public kpiDataObject ={"id":0,"name":'',"type":'',"sortOrder":0, "x":0, "y":0,"dim_col":0,"dim_row":0,"kpiImage":""};
  chartWidth;

  allReportsArr = [];
  chartReports = [];

  constructor(
    private readonly route: ActivatedRoute,
    private projectService: ProjectService,
    private toastrService: ToastrService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private readonly suppService: SuppliersService,
    private translate: TranslateService,
    private changeDetectionRef: ChangeDetectorRef,
    private chartService:ChartService,
    private renderer: Renderer2,
    private hcRService:HighchartReportsService,
    //added by Kiran for Gridster
    private readonly spinner: Ng4LoadingSpinnerService,
    public DashboardAddService: DashboardAddService,

    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  async ngOnInit() {
    parentScope01 = this;
    //console.log("this.route.snapshot.params ", this.route.snapshot.params);
    this.dashboardId = this.route.snapshot.params['id2'];
    this.projectId = this.route.snapshot.params['id'];
    this.hcRService.allCharts = [];
    this.DashboardAddService.deleteAll();
    const gridsterWidth = document.getElementById("gridsterBase").offsetWidth;
    this.chartWidth = (gridsterWidth /3 )-15;

    this.projectService.getProjectBasicInfo(this.projectId).subscribe(response =>{
      this.project = response.data;
      const projectObject = this.project.filter(item => item.id == this.projectId)[0];
      this.project = projectObject;
    });

    Highcharts.getSVG = function(charts) {
      var svgArr = [],
        top = 0,
        width = 0,
        endWidth = 0;

      Highcharts.each(charts, function(chart) {
        var svg = chart.getSVG(),
          // Get width/height of SVG for export
          svgWidth = +svg.match(
            /^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/
          )[1],
          svgHeight = +svg.match(
            /^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/
          )[1];

        svg = svg.replace(
          '<svg',
          '<g transform="translate(' + width + ', ' + top + ')" '
        );

        svg = svg.replace('</svg>', '</g>');

        width += svgWidth;
        endWidth = Math.max(endWidth, width)

        if (width === 2 * svgWidth) {
          width = 0;
          top += svgHeight;
        }

        svgArr.push(svg);
      });
      top += 400;
      return '<svg height="' + top + '" width="' + endWidth +
        '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
        svgArr.join('') + '</svg>';
    }

    Highcharts.exportCharts = function(charts, options) {

      // Merge the options
      options = Highcharts.merge(Highcharts.getOptions().exporting, options);

      // Post to export server
      Highcharts.post(options.url, {
        filename: options.filename || 'chart',
        type: options.type,
        width: options.width,
        svg: Highcharts.getSVG(charts)
      });
    };

    Highcharts.setOptions({
      lang: {
        noData: 'No data to display'
      }
    });

    const requestObject = {
      "dashboardId":this.dashboardId
    }
    this.spinner.show();
    this.hcRService.getDashboardDetailsById(requestObject).subscribe(response =>{
      //console.log("get Dashboard Data ", response);
      if(response.msg == 'success'){
        this.dashboardData = response.data;
        this.dashboardEditObject.name = this.dashboardData[0].name;
        this.dashboardEditObject.description = this.dashboardData[0].description;
        if(this.dashboardData[0].is_default == 'N'){
          this.setAsDefault = false;
          this.dashboardData[0].is_default = false;
        }else{
          this.setAsDefault = true;
          this.dashboardData[0].is_default = true;
        }

        const dashboardSelectedReports = [];
        const reportArr = [];
        for(let i=0;i< this.dashboardData[0].hc_report_dashboard_dtl.length;i++){
          // const reportArr = [];
          if(this.dashboardData[0].hc_report_dashboard_dtl[i].report_type == 'Chart'){
            dashboardSelectedReports.push(this.dashboardData[0].hc_report_dashboard_dtl[i]);
            this.chartReports.push(this.dashboardData[0].hc_report_dashboard_dtl[i]);
            //this.dashboardEditObject.section = dashboardData[0].hc_report_dashboard_dtl[0].section;
          } else {
            this.kpiDataObject =
            {
              "id":this.dashboardData[0].hc_report_dashboard_dtl[i].report_id,
              "name":this.dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.hc_report_name.length > 0 ? this.dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.hc_report_name[0].name : '',
              "type":this.dashboardData[0].hc_report_dashboard_dtl[i].report_id.report_type,
              "sortOrder":this.dashboardData[0].hc_report_dashboard_dtl[i].report_id.sort_order,
              "x":this.dashboardData[0].hc_report_dashboard_dtl[i].x_axis,
              "y":this.dashboardData[0].hc_report_dashboard_dtl[i].y_axis,
              "dim_col":this.dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.dim_col,
              "dim_row":this.dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.dim_row,
              "kpiImage":this.dashboardData[0].hc_report_dashboard_dtl[i].hc_report_hdr.kpi_image
            };
            const tempObj = {
              "id":this.dashboardData[0].hc_report_dashboard_dtl[i].report_id,
              "type":"view",
              "x":this.kpiDataObject.x,
              "y":this.kpiDataObject.y
            };
            reportArr.push(tempObj);
          }

          
        }
        this.genarateKPIs(reportArr);



        //console.log("newSelectOptions ", dashboardSelectedReports)

        if(dashboardSelectedReports && dashboardSelectedReports.length > 0){
          const reportsIds = [];
          for(let i=0;i<dashboardSelectedReports.length;i++){
            //hc_report_hdr
            //let chartOptions = dashboardSelectedReports[i].hc_report_hdr.hc_report_type[0].conf;
            //console.log(" chart options ", JSON.parse(chartOptions));
            const reportId = [];
            reportId.push(dashboardSelectedReports[i].report_id);
            this.dashboardEditObject.reportID = reportId;
            this.dashboardEditObject.newsection = dashboardSelectedReports[i].hc_report_hdr.section;
            
            // this.dashboardEditObject.chartDataOptions =
            //                   {
            //                     "id":dashboardSelectedReports[i].report_id,
            //                     "name":dashboardSelectedReports[i].hc_report_hdr.hc_report_name[0].name?dashboardSelectedReports[i].hc_report_hdr.hc_report_name[0].name:"",
            //                     "type":dashboardSelectedReports[i].report_type,
            //                     "sortOrder":dashboardSelectedReports[i].sort_order,
            //                     "x":dashboardSelectedReports[i].x_axis,
            //                     "y":dashboardSelectedReports[i].y_axis,
            //                     "dim_col":dashboardSelectedReports[i].hc_report_hdr.dim_col,
            //                     "dim_row":dashboardSelectedReports[i].hc_report_hdr.dim_row,
            //                     "chartOptions":{}
            //                   };
            //console.log(" i --> ",i);
            // this.genarateCharts(this.reportTypes,'init');
            const tempObj = {
              "id":dashboardSelectedReports[i].report_id,
              "type":"init",
              "x":dashboardSelectedReports[i].x_axis,
              "y":dashboardSelectedReports[i].y_axis,
            };
            reportsIds.push(tempObj);
          }
          this.genarateCharts(reportsIds);
        }

      }

    });
    this.spinner.hide();
  }
  
   genarateKPIs(reports){
    this.spinner.show();
    if(!reports || reports.length<1){
      return false;
    }
    const reportIds = [];
   for(const report of reports){
    reportIds.push(report.id);
   }
   const requestObject = {
      "reportID":reportIds,
      "projectId":this.projectId
      //"projectId":25
    }

    var innerObj : any;
      this.hcRService.getReports(requestObject).subscribe(response =>{
        if(response.msg == 'success'){
          this.reportAvailableFlag = true;
          const reportsData = response.data;
           for(let i=0;i<reportsData.length;i++){
            if(reportsData[i].is_multi_kpi) {
              this.multiKPI(reportsData[i], reports)
             }
             else if(reportsData[i].series.length > 0){
              for(let j=0;j<reportsData[i].series.length;j++){
                for (var prop in reportsData[i].series[j]) {
                  if (reportsData[i].series[j].hasOwnProperty(prop)) {
                    //if(Number(reportsData[i].series[j][prop]) > 0){
                      innerObj = {
                        id: reportsData[i].reportId,
                        name:'',
                        dim_col: reportsData[i].dim_col,
                        dim_row: reportsData[i].dim_row,
                        value:Number(''),
                        kpi_image:''
                      };
                      innerObj.name = reportsData[i].name;
                      const num = Number(reportsData[i].series[j][prop]).toFixed();
                      if(isNaN(parseInt(num))){
                        innerObj.value = reportsData[i].series[0][prop];
                      }else{
                        innerObj.value = parseInt(num);
                      }
                      innerObj.kpi_image = reportsData[i].kpi_image;
                      const cordinates = reports.find((r)=>{ return r.id == reportsData[i].reportId;});
                      innerObj.x = (cordinates && cordinates.x)?cordinates.x:0;
                      innerObj.y = (cordinates && cordinates.y)?cordinates.y:0;
                      this.DashboardAddService.addKPI(innerObj,"view");
                  }
                }
              }
            } else {
              innerObj = {
                id: reportsData[i].reportId,
                name:'',
                dim_col: reportsData[i].dim_col,
                dim_row: reportsData[i].dim_row,
                value:'N/D',
                kpi_image:''
              };
              innerObj.name = reportsData[i].name;
              innerObj.kpi_image = reportsData[i].kpi_image;
              const cordinates = reports.find((r)=>{ return r.id == reportsData[i].reportId;});
              innerObj.x = (cordinates && cordinates.x)?cordinates.x:0;
              innerObj.y = (cordinates && cordinates.y)?cordinates.y:0;
              this.DashboardAddService.addKPI(innerObj,"view");
            }
          }
          this.calcGridsterHeight();
          this.spinner.hide();

          //console.log("this.kpisReportsList ", this.kpisReportsList);
          //this.selectedKpiReportsIDs = [];
        }
      });

  }
  multiKPI(obj, reports){
    const outerObj = {
      id:obj.reportId,
      name:obj.name,
      is_multi:true,
      series:[],
      dim_col:obj.dim_col,
      dim_row:obj.dim_row,
      x:0,
      y:0
    }
    if(obj.drilldown.series.length > 0){
      for(let j=0;j<obj.drilldown.series.length;j++){         
        const innerObj = {
            name:'',
            value:'',
            kpi_image:'',
            cols:obj.dim_col
          };
          innerObj.name = obj.drilldown.series[j].kpi_key;
          if(obj.drilldown.series[j].data[0] != null){
            innerObj.value = obj.drilldown.series[j].data[0];
          } else {
            innerObj.value = 'N/D'
          }
          innerObj.kpi_image = obj.drilldown.series[j].kpi_image;
          outerObj.series.push(innerObj);
      }
    }
    const cordinates = reports.find((r)=>{ return r.id == obj.reportId;});
    outerObj.x = (cordinates && cordinates.x)?cordinates.x:0;
    outerObj.y = (cordinates && cordinates.y)?cordinates.y:0;
    this.DashboardAddService.addKPI(outerObj,"view");
  }
  ngOnDestroy() {
    clearInterval(this.interval);
    window.clearInterval(this.timer);
  }

  get options(): GridsterConfig {
    return this.DashboardAddService.options;
  }
  get layout(): GridsterItem[] {
    return this.DashboardAddService.layout;
  }
  get components(): IComponent[] {
    return this.DashboardAddService.components;
  }

  calcGridsterHeight(){
    let kpiCount = 0;
    let chartCount = 0;
    for(const item of this.layout){
      if(item.componentName == "Chart"){
        chartCount = chartCount + item.cols;
      } else {
        kpiCount = kpiCount + item.cols;
      }
    }
    kpiCount = Math.ceil(kpiCount / 3);
    chartCount = Math.ceil(chartCount / 3);
    const totalKpiHeight = kpiCount * 160;
    const totalChartHeight = chartCount * 320;
    //const calcHeight = Math.ceil(this.layout.length / 3)*160;
    const calcHeight = totalChartHeight + totalKpiHeight;
    this.height = calcHeight;
  }

  genarateCharts(reports){
    let requestObject ={};
    // if(callType == 'init'){
    // //console.log("selectedReports ", selectedReports);
    //   requestObject = {
    //     "reportID":this.dashboardEditObject.reportID,
    //     //"reportID":this.chartReportsIds,
    //     "projectId":this.projectId
    //   };
    // }
    const reportIds = [];
    for(const report of reports){
      reportIds.push(report.id);
    }
    requestObject = {
      "reportID":reportIds,
      "projectId":this.projectId
    };

    //console.log("request params Object ", requestObject);
    const chartsList = [];
    this.spinner.show();
    this.hcRService.getReports(requestObject).subscribe(response =>{
      //console.log("response ", response);
      if(response.msg == 'success'){
        this.reportAvailableFlag = true;
        const reportsData = response.data;
        if(reportsData && reportsData.length > 0){
          for(let i=0;i<reportsData.length;i++){
            var seriesArr = [];
            let contextMenuOptions = [];
            let chartOptions = reportsData[i].conf[0].conf;
            if(typeof reportsData[i].conf[0].conf !== 'object'){
               chartOptions = JSON.parse(reportsData[i].conf[0].conf);
            }

            //added by Kiran to change chart title to report name
            if(chartOptions.hasOwnProperty('title')){
              chartOptions.title.text = reportsData[i].name;
            }
            chartOptions.dim_row = reportsData[i].dim_row;

            chartOptions.dim_col = reportsData[i].dim_col;
            //console.log("chartOptions ", chartOptions);
            if(reportsData[i].is_drilldown != 1){
              //console.log(" chartOptions.chart.zoomType ", chartOptions.chart.zoomType);
              //console.log("reportsData[i].series ",reportsData[i].series.length);
              if(reportsData[i].series && reportsData[i].series.length > 1){
                //console.log("more than 1 conf ");
                if(chartOptions.chart.zoomType == 'xy' && reportsData[i].conf[0].report_type == 'dual-axes'){
                  seriesArr = [];
                  //console.log("reportsData[i].conf[0].report_type ", reportsData[i].conf[0].report_type);
                  const category = [];
                  for(let x=0;x<reportsData[i].series.length;x++){
                    category.push(reportsData[i].series[x].key);

                    for(let y=0;y<chartOptions.series.length;y++){
                      if(chartOptions.series[y].hasOwnProperty('yAxis')){
                        //chartOptions.series[y].name='';
                        chartOptions.series[y].data.push(Number(reportsData[i].series[x].avg_expense));
                      }else{
                        //chartOptions.series[y].name='';
                        chartOptions.series[y].data.push(reportsData[i].series[x].value);
                      }
                    }

                  }

                  if(Array.isArray(chartOptions.xAxis)){
                    chartOptions.xAxis[0].categories = category;
                  }
                  if(chartOptions.hasOwnProperty('yAxis')){
                    const newString = chartOptions.yAxis[1].title.text +' ('+ reportsData[i].series[0].currency + ')';
                   chartOptions.yAxis[1].title.text = newString;
                  }
                  //chartOptions.legend.y=0;
                  //chartOptions.legend.x=60;
                  //console.log("chartOptions dual axes ", JSON.stringify(chartOptions));

                }else{
                  const hcObject = {};
                  const categories = [];
                  const objectKeys = Object.keys(reportsData[i].series[0]);
                  for(let j=0;j<reportsData[i].series.length;j++){
                    var elementItem = reportsData[i].series[j];
                    for(let k=0;k<objectKeys.length;k++){
                      if(objectKeys[k] == 'name'){
                        categories.push(reportsData[i].series[j][objectKeys[k]]);
                      }else{
                        if(hcObject[objectKeys[k]]){
                          hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                        }else{
                          hcObject[objectKeys[k]] = new Array();
                          hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                        }
                      }
                    }
                  }

                  if(Object.keys(hcObject).length !== 0){
                    for(const key in hcObject){
                      const object ={
                        name:'',
                        data:[]
                      };
                      object.name = key;
                      object.data = hcObject[key];
                      seriesArr.push(object);
                    }
                  }

                  //console.log(" seriesArr time ", seriesArr);
                  if(chartOptions.hasOwnProperty('xAxis')){
                    chartOptions.xAxis.categories = categories;
                  }
                  if(chartOptions.hasOwnProperty('yAxis')){
                    chartOptions.yAxis.min = 0;
                  }
                  //chartOptions.xAxis.categories = categories;
                  //console.log("4 ", chartOptions);
                  chartOptions.series = seriesArr;
                //console.log("chartOptions.series ",chartOptions.series);
                }

              }else{
                //console.log("reportsData[i].series ", reportsData[i].series);
                if(chartOptions.chart.zoomType == 'xy' && reportsData[i].conf[0].report_type == 'dual-axes'){
                  seriesArr = [];
                  //console.log("reportsData[i].conf[0].report_type ", reportsData[i].conf[0].report_type);
                  const category = [];
                  for(let x=0;x<reportsData[i].series.length;x++){
                    category.push(reportsData[i].series[x].key);

                    for(let y=0;y<chartOptions.series.length;y++){
                      if(chartOptions.series[y].hasOwnProperty('yAxis')){
                        //chartOptions.series[y].name='';
                        chartOptions.series[y].data.push(Number(reportsData[i].series[x].avg_expense));
                      }else{
                        //chartOptions.series[y].name='';
                        chartOptions.series[y].data.push(reportsData[i].series[x].value);
                      }
                    }

                  }

                  if(Array.isArray(chartOptions.xAxis)){
                    chartOptions.xAxis[0].categories = category;
                  }

                  if(chartOptions.hasOwnProperty('yAxis')){
                    if(reportsData[i].series.length > 0 && reportsData[i].series[0].hasOwnProperty('currency')){
                      const newString = chartOptions.yAxis[1].title.text +' ('+ reportsData[i].series[0].currency + ')';
                      chartOptions.yAxis[1].title.text = newString;
                    }
                  }
                  //chartOptions.legend.y=0;
                  //chartOptions.legend.x=60;
                  //console.log("chartOptions dual axes ", JSON.stringify(chartOptions));

                }else if(chartOptions.chart.type != 'pyramid'){
                  for(let j=0;j<reportsData[i].series.length;j++){
                    for (var prop in reportsData[i].series[j]) {
                      if (reportsData[i].series[0].hasOwnProperty(prop)) {
                        if(Number(reportsData[i].series[0][prop]) > 0){
                          var innerObj = {
                            name:'',
                            y:Number(''),
                          };
                          innerObj.name = prop;
                          innerObj.y = Number(reportsData[i].series[0][prop]);
                          seriesArr.push(innerObj)
                        }
                      }
                    }
                  }

                  // chartOptions.series = [
                  //   {
                  //     colorByPoint: true,
                  //     data: seriesArr
                  //   }
                  // ];
                 //console.log("3 ", chartOptions);

                  if(chartOptions.hasOwnProperty('series')){
                    if(chartOptions.series.length > 0){
                      if(reportsData[i].conf[0].report_type == 'waterfall'){
                        if(chartOptions.hasOwnProperty('chart')){
                          if(chartOptions.chart.hasOwnProperty('type')){
                            //console.log("waterfall")
                            const totalObject ={
                              name: 'Total',
                              isIntermediateSum: true
                            };
                            seriesArr.push(totalObject);
                            chartOptions.series[0].data = seriesArr;
                          }
                        }
                      }else{
                        chartOptions.series[0].data = seriesArr;
                      }
                    }

                  }else{
                    if(reportsData[i].name === 'Accessibility to Basics'){
                      const categories = [];
                      seriesArr = [];
                      //console.log(reportsData[i]);
                      for(let j=0;j<reportsData[i].series.length;j++){
                          var object = {
                              name:'Farmers',
                              data:[]
                            };
                          for (var prop in reportsData[i].series[j]) {
                              if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                  categories.push(prop);
                                    // object.name = key;
                                  object.data.push(Number(reportsData[i].series[0][prop]));

                              }
                          }
                          seriesArr.push(object);
                      }

                      if(chartOptions.hasOwnProperty('xAxis')){
                          chartOptions.xAxis.categories = categories;
                      }
                      if(chartOptions.hasOwnProperty('yAxis')){
                          chartOptions.yAxis.min = 0;
                        }
                      chartOptions.series = seriesArr;
                    }else{
                      chartOptions.series = [
                        {
                          colorByPoint: true,
                          data: seriesArr
                        }
                      ];
                    }

                  }

                }else{
                  for(let j=0;j<reportsData[i].series.length;j++){
                    for (const prop in reportsData[i].series[j]) {
                      if (reportsData[i].series[0].hasOwnProperty(prop)) {
                        if(Number(reportsData[i].series[0][prop]) > 0){
                          var newArray = [];
                          newArray[0] = prop;
                          newArray[1] = Number(reportsData[i].series[0][prop]);
                          seriesArr.push(newArray)
                        }
                      }
                    }
                  }
                  //console.log("seriesArr pyramid", seriesArr);
                  seriesArr.sort(function(a, b) {
                    return a[1] < b[1] ? 1 : -1;
                  });
                  //console.log("seriesArr pyramid", seriesArr);
                  // chartOptions.series = [
                  //   {
                  //     colorByPoint: true,
                  //     data: seriesArr
                  //   }
                  // ];
                  //console.log("2 ", chartOptions);
                  if(chartOptions.hasOwnProperty('series')){
                    chartOptions.series[0].data = seriesArr;
                  }else{
                    chartOptions.series = [
                      {
                        colorByPoint: true,
                        data: seriesArr
                      }
                    ];
                  }


                }


                //console.log("seriesArr ", seriesArr);
              }

            }else if(reportsData[i].is_drilldown == 1){
              //console.log("Drill down ", chartOptions);
              //console.log("reportsData[i] ",reportsData[i].drilldown);
                for(let j=0;j<reportsData[i].series.length;j++){
                  for (const prop in reportsData[i].series[j]) {
                    if (reportsData[i].series[0].hasOwnProperty(prop)) {
                      if(Number(reportsData[i].series[0][prop]) > 0){
                        const innerObj = {
                          name:'',
                          y:Number(''),
                          drilldown:''
                        };
                        //let itemVal = Number(reportsData[i].series[0][prop]).toFixed(1);
                        //console.log("Number(reportsData[i].series[0][prop]) ",itemVal);
                        innerObj.name = prop;
                        innerObj.y = Number(reportsData[i].series[0][prop]);
                        const drilldownSeriesData = reportsData[i].drilldown.series;
                        for(let x=0;x<drilldownSeriesData.length;x++){
                          if(drilldownSeriesData[x].name == prop && drilldownSeriesData[x].id == prop){
                            innerObj.drilldown = prop;
                          }
                        }

                        seriesArr.push(innerObj);
                        //console.log("seriesArr ", seriesArr);
                      }
                    }

                  }
                }
                //console.log("1 ", chartOptions);
              if(reportsData[i].conf[0].report_type != 'combination'){
                if(chartOptions.hasOwnProperty('series')){
                  chartOptions.series[0].data = seriesArr;
                }else{
                  chartOptions.series = [
                    {
                      colorByPoint: true,
                      data: seriesArr
                    }
                  ];
                }

                const drilldownSeriesData = reportsData[i].drilldown.series;
                for(let x=0;x<drilldownSeriesData.length;x++){
                  //let drillData = drilldownSeriesData[x].data;
                  const drillOptionsData =[];
                  if(drilldownSeriesData[x].has_child == 1){
                    drilldownSeriesData[x]['keys'] =['name', 'y', 'drilldown'];
                  }

                  if(drilldownSeriesData[x].hasOwnProperty('drill_report_type')){
                    if(drilldownSeriesData[x].drill_report_type != null && drilldownSeriesData[x].drill_report_type != ''){
                      drilldownSeriesData[x].type = drilldownSeriesData[x].drill_report_type;
                      //Object.assign(chartOptions.drilldown.series[x], {type: "value"})
                      //console.log("chartOptions.drilldown.series[x]. ", chartOptions.drilldown.series[x]);
                      //chartOptions.drilldown.series[x].type = drilldownSeriesData[x].drill_report_type;
                    }
                  }

                }
                if(reportsData[i].reportId == 102){
                  if(drilldownSeriesData[0].hasOwnProperty('data')){
                    for(let x=0;x<drilldownSeriesData[0].data.length;x++){
                      if(drilldownSeriesData[0].data[x][2] == 'Above 15 years'){
                        drilldownSeriesData[0].data[x][2] = '';
                      }
                    }
                  }
                }

                if(reportsData[i].reportId == 118){
                  if(drilldownSeriesData[1].hasOwnProperty('data')){
                    for(let x=0;x<drilldownSeriesData[1].data.length;x++){
                      if(drilldownSeriesData[1].data[x][2] == 'No'){
                        drilldownSeriesData[1].data[x][2] = '';
                      }
                    }
                  }
                }

              }else{
                if(chartOptions.hasOwnProperty('xAxis')){
                  chartOptions.xAxis.categories = reportsData[i].series.categories;
                }
                if(reportsData[i].series.categories.length > 0){
                  const seriesData= reportsData[i].series.series;
                  //console.log("seriesData ", seriesData);
                  for(let i=0;i<seriesData.length;i++){
                    if(seriesData[i].type == 'pie'){
                      seriesData[i].center = [20, 0];
                      seriesData[i].size = 50;
                      seriesData[i].showInLegend = false;
                      seriesData[i].dataLabels ={
                        enabled: false
                      }
                      seriesData[i].name = "Farmers";
                    }else{
                      seriesData[i].name = "Farmers";
                    }
                  }

                  chartOptions.series = reportsData[i].series.series;
                }else{
                  chartOptions.series = [];
                  //chartOptions.labels.items[0].html = '';
                  if(chartOptions.hasOwnProperty('labels')){
                    chartOptions.labels.items[0].html = '';
                  }
                }

              }

            }

            //console.log(" seriesArr ", seriesArr)
            //for(let j=0;j < reportsData[i].conf[0].length; j++){
              //console.log(" reportsData[i].conf ", reportsData[i].conf[0].conf);


              // chartOptions.chart.width = 320;
              //chartOptions.chart.height = "100%";
              //chartOptions.chart_type = chartOptions.chart.type;
              if(chartOptions.chart.hasOwnProperty('type')){
                chartOptions.chart_type = chartOptions.chart.type;
              }else{
                if(chartOptions.chart.hasOwnProperty('charttype')){
                  chartOptions.chart_type = chartOptions.chart.charttype;
                }
              }
              chartOptions.title.style= {
                fontSize: "16px",
                fontWeight: '600'
              };
              chartOptions.reportId = reportsData[i].reportId;
              if(reportsData[i].is_drilldown == 1){
                chartOptions.drilldown = reportsData[i].drilldown;
                //chartOptions.drilldown.drillUpButton = {"position":{"verticalAlign":"top"}};
                //chartOptions.drilldown.drillUpButton = {"position":{"x":-25,"y":-35}};

                //added by Kiran for drillup button
                const drillUp = {
                  relativeTo: 'spacingBox',
                  position: {
                    align: 'left',
                    y: -10,
                    x: 0
                  },
                  theme: {
                    fill: '#63775c',
                    'stroke-width': 1,
                    r: 25,
                    states: {
                      hover: {
                        fill: '#63775c'
                      },
                      select: {
                        fill: '#63775c'
                      }
                    }
                  }

                }
                chartOptions.drilldown.drillUpButton = drillUp;
                if(chartOptions.hasOwnProperty('lang')){
                  chartOptions.lang.drillUpText = '⇦';
                } else {
                  const drillText = {
                    drillUpText : '⇦'
                  }
                  chartOptions.lang = drillText;
                }

                //var defaultTitleXAxis = "Percentage Range";
                //var defaultTitleYAxis = "Avg age of children";
                var defaultTitleYAxis = "";
                //var drilldownTitleXAxis = "School Name";
                //var drilldownTitleYAxis = "Count of children";
                var drilldownTitleYAxis = "";
                if(chartOptions.hasOwnProperty('yAxis')){
                  if(Array.isArray(chartOptions.yAxis)){
                    defaultTitleYAxis = chartOptions.yAxis[0].title.text;
                  }else{
                    if(chartOptions.yAxis.hasOwnProperty('title')){
                    defaultTitleYAxis = chartOptions.yAxis.title.text;
                    }
                  }
                }
                // if(chartOptions.hasOwnProperty('yAxisDrilldownTitle')){
                //   drilldownTitleYAxis = chartOptions.yAxisDrilldownTitle.text;
                // }
                chartOptions.chart.events= {
                  drilldown: function(e) {
                    var curConfig = this.options;
                    var curTitle = curConfig.title.text;
                    if(curConfig.hasOwnProperty('drilltitle1')){
                      if(curTitle == curConfig.drilltitle1.text){
                        if(curConfig.hasOwnProperty('drilltitle2')){
                          this.setTitle({text: curConfig.drilltitle2.text}, {text: ''});
                        }
                      } else {
                        this.oldTitle = curTitle;
                        this.oldSubtitle = curConfig.subtitle.text;
                        if(curConfig.hasOwnProperty('drilltitle2')){
                          this.setTitle({text: curConfig.drilltitle1.text}, {text: 'Drilldown for details'});
                        } else {
                          this.setTitle({text: curConfig.drilltitle1.text}, {text: ''});
                        }
                      }
                    }

                    const thisChart = this.yAxis[0].chart.options;
                    if(thisChart.hasOwnProperty('yAxis')){
                      if(Array.isArray(thisChart.yAxis)){
                        if(thisChart.yAxis[0].hasOwnProperty('title')){
                          this.oldYaxisTitle = thisChart.yAxis[0].title.text;
                        }
                      }else{
                        if(thisChart.yAxis.hasOwnProperty('title')){
                          this.oldYaxisTitle = thisChart.yAxis.title.text;
                        }
                      }
                    }
                    if(thisChart.hasOwnProperty('yAxisDrilldownTitle')){
                      if(this.hasOwnProperty('yAxis')){
                        if(Array.isArray(this.yAxis)){
                          this.yAxis[0].setTitle({ text: thisChart.yAxisDrilldownTitle.text});
                        }else{
                          if(this.yAxis.hasOwnProperty('title')){
                            this.yAxis.setTitle({ text: thisChart.yAxisDrilldownTitle.text});
                          }
                        }
                      }
                    }
                  },
                  drillup: function(e) {
                    var curConfig = this.options;
                    var curTitle = curConfig.title.text;
                    if(curConfig.hasOwnProperty('drilltitle1')){
                      if(curTitle == curConfig.drilltitle1.text){
                        this.setTitle({text: this.oldTitle}, {text: this.oldSubtitle});
                      } else {
                        this.setTitle({text: curConfig.drilltitle1.text}, {text: 'Drilldown for details'});
                      }
                    }
                    if(this.hasOwnProperty('yAxis')){
                      if(Array.isArray(this.yAxis)){
                        this.yAxis[0].setTitle({ text: this.oldYaxisTitle});
                      }else{
                        if(this.yAxis.hasOwnProperty('title')){
                          this.yAxis.setTitle({ text: this.oldYaxisTitle});
                        }
                      }
                    }
                  }
                }
              }
              if(chartOptions.chart.type == 'pie'){

                if(chartOptions.hasOwnProperty("plotOptions")){
                  if(chartOptions.plotOptions.hasOwnProperty("pie")){
                    chartOptions.plotOptions.pie.dataLabels ={
                      enabled: true,
                      format: '{point.percentage:.1f} %',
                      distance: -20,
                      color:'#fafafa'
                    }
                    chartOptions.plotOptions.pie.showInLegend= true;
                  }
                }

                chartOptions.legend = {
                  enabled: true
                }
              }
              if(chartOptions.chart.type == 'pyramid'){
                if(chartOptions.hasOwnProperty("plotOptions")){
                  if(chartOptions.plotOptions.hasOwnProperty("series")){
                    chartOptions.plotOptions.series.dataLabels ={
                      enabled: true,
                      format: '{point.y:,.0f}',
                      softConnector: true,
                      distance: -25,
                      color:'#fafafa'
                    }
                    chartOptions.plotOptions.series.width = '100%';
                  }
                }
                chartOptions.title.x = 0;
              }


              if(chartOptions.chart.hasOwnProperty("charttype")){
                //console.log("chartOptions.chart.charttype ", chartOptions.chart.charttype);
                if(chartOptions.chart.charttype == 'circle'){
                  seriesArr = [];
                  for(let j=0;j<reportsData[i].series.length;j++){
                    for (const prop in reportsData[i].series[j]) {
                      if (reportsData[i].series[0].hasOwnProperty(prop)) {
                        if(reportsData[i].series[0][prop] != null){
                          //if(Number(reportsData[i].series[0][prop]) > 0){
                            const innerObj = {
                              name:'Farmers',
                              y:Number(''),
                              color: '#50C1BF'
                            };
                            //innerObj.name = prop;
                            //console.log("reportsData[i].series[0][prop] ", reportsData[i].series[0][prop]);
                            const x = reportsData[i].series[0][prop].split('/');
                            //console.log("x ", x);
                            innerObj.y = Number(x[0]);
                            seriesArr.push(innerObj)
                         // }
                        }
                      }
                    }
                  }

                  chartOptions.series[0].data = seriesArr;
                }
              }

              if(reportsData[i].conf[0].report_type == 'column_placement'){
                seriesArr = [];
                //console.log("reportsData[i] ", reportsData[i]);
                delete chartOptions.xAxis.type;
                chartOptions.xAxis.categories = reportsData[i].series.categories;

                //console.log("reportsData[i].series ", reportsData[i].series);
                //console.log("reportsData[i].series ", reportsData[i].series.series);

                const seriesData = reportsData[i].series.series;
                chartOptions.series = [];
                  //console
                  const colorsDataObject =[
                    {
                      name: '',
                      color: 'rgba(165,170,217,1)',
                      data: [],
                      pointPadding: 0.3,
                      pointPlacement: -0.2
                    }, {
                        name: '',
                        color: 'rgba(126,86,134,.9)',
                        data: [],
                        pointPadding: 0.4,
                        pointPlacement: -0.2
                    }, {
                        name: '',
                        color: 'rgba(248,161,63,1)',
                        data: [],
                        pointPadding: 0.3,
                        pointPlacement: 0.2,
                        yAxis: 1
                    }, {
                        name: '',
                        color: 'rgba(186,60,61,.9)',
                        data: [],
                        pointPadding: 0.4,
                        pointPlacement: 0.2,
                        yAxis: 1
                    }
                  ];
                //console
                for(let k=0;k<seriesData.length;k++){
                  //console.log("seriesData.name ", seriesData[k].name);
                  //console.log("seriesData.name ", seriesData[k].data);
                  // for(let j=0; j < chartOptions.series.length;j++){
                    //chartOptions.series[k].name = seriesData[k].name;
                    //chartOptions.series[k].data = seriesData[k].data;
                    colorsDataObject[k].name = seriesData[k].name;
                    colorsDataObject[k].data = seriesData[k].data;
                  // }
                }
                chartOptions.series = colorsDataObject;
                //console.log(" column placement ", chartOptions);

              }

              if(reportsData[i].conf[0].report_type == 'column-stacked'){
                seriesArr = [];
                if(chartOptions.xAxis.hasOwnProperty('type')){
                  delete chartOptions.xAxis.type;
                }
                chartOptions.xAxis.categories = reportsData[i].series.categories;
                const seriesData = reportsData[i].series.series;
                for(let k=0;k<seriesData.length;k++){
                //     chartOptions.series[k].name = seriesData[k].name;
                //     chartOptions.series[k].data = seriesData[k].data;
                  seriesData[k].data = seriesData[k].data.map(function(val, i) {
                    return val === 0 ? null : val;
                  });
                  chartOptions.series.push(seriesData[k]);
                }
                //console.log(" column placement ", chartOptions);
              }

              chartOptions.reportTypeFrontend = 'init';//currentType;
              chartOptions.chart.backgroundColor= '#fafafa';
              chartOptions.chart.spacingTop = 20;
              //chartOptions.chart.spacingBottom = 20;

                //added by Kiran for currency on zAxis
                if(reportsData[i].reportId == 93 || reportsData[i].reportId == 87 || reportsData[i].reportId == 135){
                  if(reportsData[i].hasOwnProperty('currency')){
                    chartOptions.yAxis[1].title.text = chartOptions.yAxis[1].title.text + ' (' + reportsData[i].currency + ')';
                    if(reportsData[i].reportId == 87){
                      chartOptions.yAxis[0].title.text = chartOptions.yAxis[0].title.text + ' (' + reportsData[i].currency + ')';
                    }
                  }
                }
                if(reportsData[i].reportId == 49){
                  if(reportsData[i].hasOwnProperty('currency')){
                    chartOptions.yAxis.title.text = chartOptions.yAxis.title.text + ' (' + reportsData[i].currency + ')';
                  }
                }
              //console.log("reportsData[i].conf ",reportsData[i].conf);
              if(reportsData[i].conf.length > 1){
                contextMenuOptions = [
                  'viewFullscreen',
                  // {
                  //   text: 'Change chart type ',
                  //   onclick: function(this,event){
                  //     let self = this;
                  //     parentScope01.changeChartType(self);
                  //   }
                  //   },
                    {
                    textKey: 'downloadPDF',
                    onclick: function () {
                      this.exportChart({
                        type: 'application/pdf'
                      });
                    }
                    },
                    {
                    textKey: 'downloadPNG',
                    onclick: function () {
                      this.exportChart();
                    }
                  },
                  {
                    textKey: 'downloadXLS',
                    onclick: function () {
                      this.downloadXLS();
                    }
                  }
                  // {
                  //   text: 'Remove',
                  //   onclick: function(this){
                  //   let self = this;
                  //   parentScope01.RemoveChart(self);
                  //   }
                  // }
                  // {
                  //   textKey: 'viewData',
                  //   text: 'Toggle data table',
                  //   onclick: function (this) {
                  //     if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                  //       this.dataTableDiv.style.display = 'none';
                  //     } else {
                  //       this.viewData();
                  //       this.dataTableDiv.style.display = '';
                  //     }
                  //   }
                  // }
                ];
              }else{
                if(!contextMenuOptions || !Array.isArray(contextMenuOptions)){
                  contextMenuOptions = [];
                }
                contextMenuOptions = [
                  'viewFullscreen',
                    {
                    textKey: 'downloadPDF',
                    onclick: function () {
                      this.exportChart({
                        type: 'application/pdf'
                      });
                    }
                    },
                    {
                    textKey: 'downloadPNG',
                    onclick: function () {
                      this.exportChart();
                    }
                  },
                  {
                    textKey: 'downloadXLS',
                    onclick: function () {
                      this.downloadXLS();
                    }
                  }
                  // {
                  //   text: 'Remove',
                  //   onclick: function(this){
                  //   let self = this;
                  //   parentScope01.RemoveChart(self);
                  //   }
                  // }
                  // {
                  //   textKey: 'viewData',
                  //   text: 'Toggle data table',
                  //   onclick: function (this) {
                  //     if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                  //       this.dataTableDiv.style.display = 'none';
                  //     } else {
                  //       this.viewData();
                  //       this.dataTableDiv.style.display = '';
                  //     }
                  //   }
                  // }
                ];
              }
              //console.log("contextMenuOptions ", contextMenuOptions);
              chartOptions.exporting.buttons.contextButton.menuItems = contextMenuOptions;
              chartOptions.exporting.filename = reportsData[i].name;
              //chartOptions.exporting.buttons.contextButton.className ='highcharts-button-normal';
              // chartOptions.legend = {
              //   enabled: falseS
              // };
              // chartOptions.exporting.menuItemDefinitions= {
              // 	viewFullscreen: {
              // 		onclick: function () {
              //       //this.fullscreen = new Highcharts.FullScreen(this.container);
              //       Highcharts.FullScreen.init(this.renderTo);
              //     	console.log('Test')
              // 		}
              // 	}
              // }

              //added bt KIran for export button position
              chartOptions.navigation = {
                buttonOptions: {
                  verticalAlign: 'top',
                  y: -5
                }
              }

              //console.log("chartOptions ", chartOptions);
              //console.log(" json sting ", JSON.stringify(chartOptions));
              //console.log("chartOptions.series ", chartOptions.series);
              //chartsList.push(chartOptions);
              for(let j = 0; j < this.chartReports.length; j++){
                if(reportsData[i].reportId == this.chartReports[j].report_id){
                  const newChartObj = {
                    "id":this.chartReports[j].report_id,
                    "name":this.chartReports[j].hc_report_hdr.hc_report_name[0].name,
                    "type":this.chartReports[j].report_type,
                    "sortOrder":this.chartReports[j].sort_order,
                    "x":this.chartReports[j].x_axis,
                    "y":this.chartReports[j].y_axis,
                    "dim_col":this.chartReports[j].hc_report_hdr.dim_col,
                    "dim_row":this.chartReports[j].hc_report_hdr.dim_row,
                    "chartOptions":chartOptions
                  }
                  const KPIobject = {"reportId":reportsData[i].reportId,"col":reportsData[i].dim_col,"row":reportsData[i].dim_row, "type":'Chart',"src":newChartObj};
                  
                  this.DashboardAddService.addChart(KPIobject,"view");
                  
                }
              }
              //this.dashboardEditObject.chartDataOptions.chartOptions = chartOptions;
              
              this.calcGridsterHeight();

            //}

          }

        }else{

          if(this.existingSelectedReports && this.existingSelectedReports.length == 0){
            this.chartElement01.nativeElement.innerHTML = '';
            this.hcRService.allCharts = [];
          }

        }

        if(chartsList && chartsList.length > 0){

            //this.chartElement01.nativeElement.innerHTML = '';
            //this.hcRService.allCharts = [];
            for(let i=0;i<chartsList.length;i++){
              //chartsList[i].exporting.buttons.contextButton.menuItems = contextMenuOptions;
              const e = document.createElement("div");
              const newElement = this.chartElement01.nativeElement.appendChild(e);
              // this.renderer.addClass(newElement, 'chartbox');
              // this.renderer.addClass(newElement, 'chartbox-add');
              if(chartsList[i].reportId == 88){
                this.renderer.addClass(newElement, 'col-md-4');
                this.renderer.addClass(newElement, 'col-lg-8');
              }else{
                this.renderer.addClass(newElement, 'col-md-4');
                this.renderer.addClass(newElement, 'col-lg-4');
              }

              this.renderer.addClass(newElement, 'pl-0');
              this.renderer.addClass(newElement, 'pr-0');
              const f = document.createElement("div");
              const newElement1 = newElement.appendChild(f);
              this.renderer.addClass(newElement1, 'chartBox-pdmr');
              //Added by Kiran to replace math.random to windows.crypto
              const array = new Uint32Array(1);
              window.crypto.getRandomValues(array);                
              newElement1.setAttribute('id',+array[0].toString().slice(2, 6));
              this.hcRService.createChart(newElement1, chartsList[i]); 
              this.exportBtns = true;
              //var el = document.getElementById('test');
              //var newEl = document.createElement('button');
              //el.insertAdjacentHTML('afterend', '<button id="two">two</button>');
            }

            for(let i=0;i<chartsList.length;i++){
              const e = document.createElement("div");
              const newElement = this.chartElement02.nativeElement.appendChild(e);
              this.renderer.setStyle(newElement, 'width','48%');
              this.renderer.setStyle(newElement, 'display','inline-block');
              this.renderer.setStyle(newElement, 'padding','1%');
              this.renderer.addClass(newElement, 'chartBox-pdmr');
              //Added by Kiran to replace math.random to windows.crypto
              const array = new Uint32Array(1);
              window.crypto.getRandomValues(array);                
              newElement.setAttribute('id',+array[0].toString().slice(2, 6));
              const newVal = Highcharts.chart(newElement, chartsList[i]);

              this.localChartsList.push(newVal);
            }

            // if(currentType == 'predefined'){
            //   this.profilingFlag = true;
            // }else{
            //   //this.exportBtns = true;
            //   this.customFlag = true;
            // }

          // }else{
          //   //this.chartElement02.nativeElement.innerHTML = '';
          //   //this.hcRService.allCharts = [];
          //   for(let i=0;i<chartsList.length;i++){
          //     let e = document.createElement("div");
          //     let newElement = this.chartElement02.nativeElement.appendChild(e);
          //     this.renderer.addClass(newElement, 'chartbox');
          //     this.renderer.addClass(newElement, 'chartbox-add');
          //     this.renderer.addClass(newElement, 'col-lg-6');
          //     newElement.setAttribute('id',+Math.random().toString().slice(2, 6));
          //     this.hcRService.createChart(newElement, chartsList[i]);
          //     this.exportBtns = true;
          //     this.customFlag = true;
          //   }
          // }
          this.checkChartsAvailable = this.hcRService.allCharts;
          //this.selectedReportsMultiSelect = [];
          //this.selectedReportsMultiSelectCustom = [];
          //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
        }

      }else{
      //this.existingSelectedReports = [];
      }
      this.spinner.hide();
    });

  }

  async downloadAsPDF(){
    //debugger;
    //let charts = this.hcRService.allCharts;
    const charts = this.localChartsList;
    //let charts = this.layout;
    Highcharts.exportCharts(charts,{
      type: 'application/pdf'
    });
  }

  RemoveChart(itemScope){
    parentScope01.removeFromList(itemScope.id);
    itemScope.renderTo.remove();
    //self.renderTo.remove();
    //self.destory();
    //self.remove();
  }

  removeFromList(item){
    const charts = this.hcRService.allCharts;
    const newCharts = charts.filter(item1  => item1.id != item);
    this.hcRService.allCharts = newCharts;
    this.localChartsList = newCharts;
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }
  goToReportingManagement(projectID){
    const url = 'charts/' + this.projectId;
    this.router.navigate([url]);
  }
  goToDashboards(){
    const url="charts/dashboards/"+ this.projectId;
    this.router.navigate([url]);
  }
  addNewDashboard(){
    const url="charts/dashboards/add/"+ this.projectId;
    this.router.navigate([url]);
  }

  editDashboard(currentItem){
    const url="charts/dashboards/edit/"+ currentItem.id +'/'+this.projectId;
    this.router.navigate([url]);
  }

  onResize(event) {
    const gridsterWidth = document.getElementById("gridsterBase").offsetWidth;
    this.chartWidth = (gridsterWidth /3 )-15;
  }

}
