import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
@Injectable({
    providedIn: 'root'
})
export class VisitService {
    private readonly visitURL = this.appConfigService.getUrl(this.appConfigService.VISIT);

    private readonly visitTypeURL = this.appConfigService.getUrl(this.appConfigService.VISIT_TYPES);

    constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) { }

    getAllVisits(projectId): Observable<any> {
        return this.http.get<any>(`${this.visitURL}/allvisits/${projectId}`);
    }

    getRegionVillages(projectId): Observable<any> {
        return this.http.get<any>(`${this.visitURL}/getRegionVillages/${projectId}`);
    }

    getAllVisitTypes(): Observable<any> {
      return this.http.get<any>(`${this.visitTypeURL}/visit_type_id`);
    }

    saveUpdateVisit(data, edit, projectId): Observable<any> {
        return edit ? this.http.put(`${this.visitURL}/updateVisit/${projectId}`, data) :
        this.http.post(`${this.visitURL}/addVisit/${projectId}`, data); 
    }

    visitManagementExportToExcel(projectID, type) {
        return this.http.post<Blob>(`${this.visitURL}/exportVisits/${projectID}/${type}`, {}, {responseType:'blob' as 'json'}); 
    }

    getAllVisitPurposes(): Observable<any> {
        return this.http.get<any>(`${this.visitTypeURL}/visit_purpose_id`);
    }
}