import * as tslib_1 from "tslib";
// TODO: Done: Build questionnaire from blank slate
// TODO: Done: Bug in option reordering
import { OnInit, TemplateRef, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// Services
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import { ChangeDetectorRef } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
var EditDynamicQuestionnaireComponent = /** @class */ (function () {
    function EditDynamicQuestionnaireComponent(dynamicQuestionnaireService, route, surveyService, projectService, commonService, toastr, modalService, ref, router, locale, spinner) {
        this.dynamicQuestionnaireService = dynamicQuestionnaireService;
        this.route = route;
        this.surveyService = surveyService;
        this.projectService = projectService;
        this.commonService = commonService;
        this.toastr = toastr;
        this.modalService = modalService;
        this.ref = ref;
        this.router = router;
        this.locale = locale;
        this.spinner = spinner;
        this.showSectionForm = false;
        this.reloadingQuestionnaire = true;
        this.checkedObject = {};
        this.farmerId = 1;
        this.obj = { id: "8", key: "116", value: "1" };
        this.assetTypes = [
            { type: 'tab', subTypes: [] },
            { type: 'section', subTypes: [] },
            { type: 'subsection', subTypes: [] },
            { type: 'table', subTypes: [{ key: 'question_rows', label: 'Questions in rows' }, { key: 'question_cols', label: 'Questions in columns' }] },
            { type: 'question', subTypes: [{ key: 'text', label: 'Text' }, { key: 'number', label: 'Number' }, { key: 'select', label: 'Select' }, { key: 'multiselect', label: 'Multiselect' }] },
            { type: 'option', subTypes: [] },
            { type: 'uom_question', subTypes: [{ key: '3', label: 'Weight' }, { key: '4', label: 'Area' }, { key: '5', label: 'Quantity' }] },
            { type: 'other_question', subTypes: [] },
            { type: 'grand_total_question', subTypes: [] },
        ];
        this.assetSubtypes = [];
        this.changedAssets = [];
        this.validationTypes = ['max_value', 'min_value', 'regex_pattern'];
        this.newValidationTypes = [];
        this.mtaIssues = [];
        this.questionnaireChanges = {};
        this.displayLogicTypes = [{ type_key: 'value_matches', type_label: 'Must have specified answer' }, { type_key: 'value_exists', type_label: 'Can have any answer' }];
        this.displayLogicTypeSelected = { type_key: 'value_matches', type_label: 'Must have specified answer' };
        this.uomSubtypes = [];
        this.mtaHasOtherOption = false;
        this.autocalculateFormulaArray = [];
        this.formulaOperations = [
            { name: '+', symbol: '+' },
            { name: '-', symbol: '-' },
            { name: 'x', symbol: '*' },
            { name: '/', symbol: '/' }
        ];
        this.saveTemplateSubmitted = false;
        this.remainingTemplateDescriptionLength = 300;
        this.savingTemplate = false;
        this.displayLogicKeys = [];
        this.displayLogicValues = [];
        this.userLanguages = [];
        if (this.locale && this.locale.length > 2) {
            this.locale = this.locale.substr(0, 2);
        }
    }
    EditDynamicQuestionnaireComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap.subscribe(function (params) {
            _this.route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var request;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.surveyId = params.get('surveyId');
                            this.projectId = params.get('projectId');
                            return [4 /*yield*/, Promise.all([
                                    this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                    this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                    this.surveyService.getSurvey(this.surveyId).toPromise(),
                                    this.surveyService.getSurveyQuestionnaire(this.surveyId).toPromise(),
                                    this.surveyService.getAssociatedTemplates(this.surveyId).toPromise(),
                                    this.surveyService.getSurveyLanguages().toPromise()
                                ])];
                        case 1:
                            request = _a.sent();
                            if (request[0]['msg'] != 'success' || request[1]['msg'] != 'success') {
                                this.commonService.showToast('error', 'generic_error', {});
                            }
                            else {
                                this.project = tslib_1.__assign({}, request[0].data[0], request[1].data[0]);
                            }
                            if (request[2]['message']) {
                                this.survey = request[2]['message'];
                            }
                            if (request[5]['message']) {
                                this.userLanguages = request[5]['message'];
                            }
                            if (request[3]['message']) {
                                if (request[3]['message'].length > 0) {
                                    this.questionnaire = request[3]['message'];
                                }
                                else {
                                    this.initializeBlankQuestionnaire();
                                }
                                this.initialize(0, 0);
                                this.reloadingQuestionnaire = false;
                            }
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    EditDynamicQuestionnaireComponent.prototype.tabClicked = function (tabIndex, sectionIndex) {
        delete this.currentTab;
        this.currentTab = JSON.parse(JSON.stringify(this.questionnaire[tabIndex]));
        this.currentTabIndex = tabIndex;
        this.currentSectionIndex = sectionIndex;
        this.sectionClicked(this.currentSectionIndex);
    };
    EditDynamicQuestionnaireComponent.prototype.sectionClicked = function (sectionIndex) {
        if (this.currentTab) {
            delete this.currentSection;
            if (this.currentTab.sections && this.currentTab.sections.length > 0) {
                this.currentSection = JSON.parse(JSON.stringify(this.currentTab.sections[sectionIndex]));
                this.currentSectionIndex = sectionIndex;
                this.ref.detectChanges();
                this.reloadingQuestionnaire = false;
            }
            else {
                this.ref.detectChanges();
                this.reloadingQuestionnaire = false;
            }
        }
        else {
            this.ref.detectChanges();
            this.reloadingQuestionnaire = false;
        }
    };
    EditDynamicQuestionnaireComponent.prototype.initialize = function (tabIndex, sectionIndex) {
        this.currentTabIndex = tabIndex;
        this.tabClicked(this.currentTabIndex, sectionIndex);
        this.collectDisplayLogicKeyValues();
    };
    EditDynamicQuestionnaireComponent.prototype.setForm = function () {
        this.showSectionForm = false;
        this.sectionForm = new FormGroup({});
        // For each question in the section
        for (var i = 0; i < this.currentSection.questions.length; i++) {
            // Find if the question has any answer(s) in the qAnswers array
            var existsInQAnswers = this.existsInQAnswers(this.currentSection.questions[i]);
            if (this.currentSection.questions[i].qa_subtype == 'multiselect' && existsInQAnswers && existsInQAnswers.length > 0) {
                // Set the object which determines whether a checkbox should appear as checked or unchecked (question.id + option.id combination is true / false)
                this.setCheckedObject(existsInQAnswers, this.currentSection.questions[i]);
            }
            // Create a form control for each question
            this.sectionForm.addControl(this.currentSection.questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
            // Set the question's validations
            this.setQuestionValidations(this.currentSection.questions[i]);
            // Disable user input if required
            if (this.currentSection.questions[i].disable_input) {
                this.sectionForm.controls[this.currentSection.questions[i].id].disable();
            }
            // Set the question's UOM dropdown (value of dropdown select formcontrol + the option in the dropdown) if required
            if (this.currentSection.questions[i].include_uom_question) {
                this.setUOMQuestion(this.currentSection.questions[i]);
            }
        }
        // For each subsection in the section, do the same steps as above (done for each question within each subsection)
        for (var k = 0; k < this.currentSection.subsections.length; k++) {
            for (var i = 0; i < this.currentSection.subsections[k].questions.length; i++) {
                var existsInQAnswers = this.existsInQAnswers(this.currentSection.subsections[k].questions[i]);
                if (this.currentSection.subsections[k].questions[i].qa_subtype == 'multiselect' && existsInQAnswers && existsInQAnswers.length > 0) {
                    this.setCheckedObject(existsInQAnswers, this.currentSection.subsections[k].questions[i]);
                }
                this.sectionForm.addControl(this.currentSection.subsections[k].questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
                this.setQuestionValidations(this.currentSection.subsections[k].questions[i]);
                if (this.currentSection.subsections[k].questions[i].disable_input) {
                    this.sectionForm.controls[this.currentSection.subsections[k].questions[i].id].disable();
                }
                if (this.currentSection.subsections[k].questions[i].include_uom_question) {
                    this.setUOMQuestion(this.currentSection.subsections[k].questions[i]);
                }
            }
        }
        // For each table within the section
        for (var k = 0; k < this.currentSection.tables.length; k++) {
            // For each question in the table
            for (var i = 0; i < this.currentSection.tables[k].questions.length; i++) {
                // Check if answers exist for this question
                var existsInQAnswersForTableQuestion = this.existsInQAnswersForTableQuestion(this.currentSection.tables[k].questions[i]);
                // Add secondary asset key of each unique row / column in the table to the secondary_asset_keys object
                this.secondary_asset_keys[this.currentSection.tables[k].id] = existsInQAnswersForTableQuestion.map(function (item) { return item.secondary_asset_key; });
                // Either non-blank or "" answer should exist for each question in each row / column in the table
                if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
                    // For each unique secondary asset key
                    for (var p = 0; p < existsInQAnswersForTableQuestion.length; p++) {
                        // For multiselect questions, set the checkedObjectSecondaryKeys value (to indicate whether the checkbox should appear checked or not)
                        if (this.currentSection.tables[k].questions[i].qa_subtype == 'multiselect' && existsInQAnswersForTableQuestion[p].value && existsInQAnswersForTableQuestion[p].value.length > 0) {
                            this.setCheckedObjectSecondaryKeys(existsInQAnswersForTableQuestion[p], this.currentSection.tables[k].questions[i]);
                        }
                        // Add the form control (question.id + '_' + secondary_asset_key of the row / column)
                        this.sectionForm.addControl(this.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key'], new FormControl(existsInQAnswersForTableQuestion[p]['value']));
                        // Set the question's validations, disable the input if required & add UOM questions if required
                        this.setQuestionValidations(this.currentSection.tables[k].questions[i], existsInQAnswersForTableQuestion[p]['secondary_asset_key']);
                        if (this.currentSection.tables[k].questions[i].disable_input) {
                            this.sectionForm.controls[this.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key']].disable();
                        }
                        if (this.currentSection.tables[k].questions[i].include_uom_question) {
                            this.setUOMQuestion(this.currentSection.tables[k].questions[i], existsInQAnswersForTableQuestion[p]);
                        }
                    }
                }
            }
        }
        // Based on toggle logic if applicatble, set which elements in the form should be displayed (isDisplayed attribute = true/false)
        this.setSectionFormDisplay();
        this.showSectionForm = true;
    };
    EditDynamicQuestionnaireComponent.prototype.sectionFormChangeHandler = function (args, question) {
        if (!this.qAnswers) {
            this.qAnswers = [];
        }
        // See if this object already exists in qAnswers
        var x = this.qAnswers.find(function (item) { return item.key == question.id; });
        // If object exists, find the index
        if (x) {
            var index = this.qAnswers.indexOf(x);
            // Set this object's touched parameter to true
            this.qAnswers[index]['touched'] = true;
            // If this object has an id (previously value was set), set the form control's value
            if (this.qAnswers[index].id && this.qAnswers[index].id > 0) {
                this.qAnswers[index]['value'] = this.sectionForm.value[question.id];
            }
            else {
                // If this object does NOT have an id
                // If the form control has a not-null not-blank value, set it
                if (this.sectionForm.value[question.id] && this.sectionForm.value[question.id] != '') {
                    this.qAnswers[index]['value'] = this.sectionForm.value[question.id];
                }
                else {
                    // If the form control has a null / blank value, remove the object from this.qAnswers (it has been set and unset in the same session)
                    this.qAnswers = this.qAnswers.filter(function (item) { return item.key != question.id; });
                }
            }
        }
        else {
            // If this object does not exist in qAnswers
            // If the form control has a not-null not-blank value, add the object to qAnswers with touched = true
            if (this.sectionForm.value[question.id] && this.sectionForm.value[question.id] != '') {
                this.qAnswers.push({ key: question.id.toString(), value: this.sectionForm.value[question.id].toString(), touched: true });
            }
        }
        this.setSectionFormDisplay();
    };
    EditDynamicQuestionnaireComponent.prototype.updateQAnswers = function (savedQAnswers) {
        if (!this.qAnswers) {
            this.qAnswers = [];
        }
        var _loop_1 = function (i) {
            var x = this_1.qAnswers.find(function (item) { return item.key == savedQAnswers[i].questionnaire_asset_id; });
            if (x) {
                var index = this_1.qAnswers.indexOf(x);
                this_1.qAnswers[index] = { id: savedQAnswers[i].id.toString(), key: savedQAnswers[i].questionnaire_asset_id.toString(), value: savedQAnswers[i].answer.toString() };
            }
            else {
                this_1.qAnswers.push({ id: savedQAnswers[i].id.toString(), key: savedQAnswers[i].questionnaire_asset_id.toString(), value: savedQAnswers[i].answer.toString() });
            }
        };
        var this_1 = this;
        for (var i = 0; i < savedQAnswers.length; i++) {
            _loop_1(i);
        }
    };
    EditDynamicQuestionnaireComponent.prototype.existsInQAnswers = function (question) {
        if (question.qa_subtype == 'multiselect') {
            // return this.qAnswers.filter(item => item.key == question.id);
            return [];
        }
        else {
            // return this.qAnswers.find(item => item.key == question.id);
            return {};
        }
    };
    EditDynamicQuestionnaireComponent.prototype.setCheckedObject = function (existsInQAnswers, question) {
        for (var i = 0; i < existsInQAnswers.length; i++) {
            this.checkedObject[question.id + '_' + existsInQAnswers[i]['value']] = true;
        }
    };
    EditDynamicQuestionnaireComponent.prototype.setSectionFormDisplay = function () {
        for (var i = 0; i < this.currentSection.questions.length; i++) {
            // set question display
            this.currentSection.questions[i].isDisplayed = this.checkAssetDisplay(this.currentSection.questions[i]);
        }
        for (var k = 0; k < this.currentSection.subsections.length; k++) {
            // TODO: Done: set subsection display
            this.currentSection.subsections[k].isDisplayed = this.checkAssetDisplay(this.currentSection.subsections[k]);
            for (var i = 0; i < this.currentSection.subsections[k].questions.length; i++) {
                // TODO: Done: set question display
                this.currentSection.subsections[k].questions[i].isDisplayed = this.checkAssetDisplay(this.currentSection.subsections[k].questions[i]);
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.checkAssetDisplay = function (asset) {
        if (asset.questionnaire_asset_display_logic && asset.questionnaire_asset_display_logic.length > 0) {
            var _loop_2 = function (i) {
                if (this_2.qAnswers.find(function (item) { return item.key == asset.questionnaire_asset_display_logic[i].display_if_key && item.value == asset.questionnaire_asset_display_logic[i].display_if_value; })) {
                    return { value: true };
                }
            };
            var this_2 = this;
            for (var i = 0; i < asset.questionnaire_asset_display_logic.length; i++) {
                var state_1 = _loop_2(i);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
            return false;
        }
        else {
            return true;
        }
    };
    EditDynamicQuestionnaireComponent.prototype.navigateSection = function (direction) {
        var newSectionIndex = this.currentSectionIndex + direction;
        if (newSectionIndex < 0) {
            var newTabIndex = this.currentTabIndex + direction;
            if (newTabIndex > 0) {
                this.tabClicked(newTabIndex, 0);
            }
        }
        else if (newSectionIndex > 0 && newSectionIndex < this.currentTab.sections.length) {
            this.sectionClicked(newSectionIndex);
        }
        else {
            var newTabIndex = this.currentTabIndex + direction;
            if (newTabIndex < this.questionnaire.length) {
                this.tabClicked(newTabIndex, 0);
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.exitClicked = function () {
    };
    EditDynamicQuestionnaireComponent.prototype.showModifyQuestionInput = function (question, option) {
        // this.modifyingThisQuestion = question;
        // if (option) {
        //   this.modifyingThisOption = option;
        // }
    };
    EditDynamicQuestionnaireComponent.prototype.modifyThisAsset = function (asset, template, assetType) {
        this.assetSubtypes = this.assetTypes.find(function (item) { return item.type == assetType; })['subTypes'];
        this.mta = Object.assign({}, JSON.parse(JSON.stringify(asset)));
        // this.newValidationTypes = this.validationTypes.filter(item => !this.mta.survey_q_asset_validations.find(each => each.id && each.validation_key == item));
        this.uomSubtypes = this.assetTypes.find(function (item) { return item.type == 'uom_question'; })['subTypes'];
        this.mta.isMandatory = this.mta.survey_q_asset_validations.find(function (each) { return each.validation_key == 'mandatory'; }) ? true : false;
        if (asset.qa_type == 'question' || asset.qa_type == 'subsection' || asset.qa_type == 'table') {
            this.mta.childOfCurrentSection = this.mta.parent_code === this.currentSection.code;
        }
        // this.mta.displayLogic = this.getDisplayLogic(asset);
        this.setMtaHasOtherOption();
        this.mtaIssues = [];
        this.modifyingAssetModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    EditDynamicQuestionnaireComponent.prototype.addAsset = function (assetType, relativeToAsset, relativeToAssetIndex, direction, template, isChildOfTable) {
        if (assetType != 'tab' && (!relativeToAsset.parent_id || relativeToAsset.parent_id == 0)) {
            this.toastr.warning('Please save changes first');
            return;
        }
        var assetToAdd = {
            survey_id: this.surveyId,
            code: uuidv4(),
            qa_type: assetType,
            parent_id: relativeToAsset.parent_id,
            parent_code: relativeToAsset.parent_code,
            qa_sequence: this.getRelativeSequence(relativeToAsset, direction),
            qa_subtype: this.getDefaultSubtype(assetType),
            survey_q_asset_labels: [{ language_id: this.getLanguageId(), label: '' }],
            survey_q_asset_validations: [],
            survey_q_asset_display_logic: [],
            survey_q_asset_autocalculate_formulas: [],
            is_table_question: isChildOfTable ? true : false
        };
        console.log('assetToAdd', assetToAdd);
        this.modifyThisAsset(assetToAdd, template, assetType);
        this.relativeToAssetIndex = relativeToAssetIndex;
    };
    EditDynamicQuestionnaireComponent.prototype.getDefaultSubtype = function (assetType) {
        var subtypes = this.assetTypes.find(function (item) { return item.type == assetType; })['subTypes'];
        if (subtypes && subtypes.length > 0) {
            return subtypes[0];
        }
        else {
            return null;
        }
    };
    EditDynamicQuestionnaireComponent.prototype.saveAssetChanges = function () {
        if (this.validateMTA()) {
            var mtaType = this.mta.qa_type;
            this.updateChangedAssets();
            this.updateCurrentTab();
            this.closeModifyingAssetTemplateModal();
            if (this.disableCancelOfModifyingAssetModal) {
                this.disableCancelOfModifyingAssetModal = false;
            }
            if (mtaType == 'tab') {
                // this.saveQuestionnaireChanges();
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.changeOption = function (option, action) {
        var optionToRemove = this.mta.options.find(function (item) { return item.code == option.code; });
        if (optionToRemove) {
            var index = this.mta.options.indexOf(optionToRemove);
            if (this.validIndex(index)) {
                if (this.mta.options[index].id) {
                    if (this.displayLogicValues.indexOf(this.mta.options[index].id.toString()) > -1) {
                        this.toastr.error('This option is part of a display logic condition & cannot be removed. Please modify the display logic first.');
                        return;
                    }
                    this.mta.options[index].removed_from_questionnaire = (action == 'remove' ? true : false);
                }
                else {
                    this.mta.options.splice(index, 1);
                }
                this.setMtaHasOtherOption();
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.changeUOM = function (uom, action) {
        var uomToRemove = this.mta.uom_questions.find(function (item) { return item.code == uom.code; });
        if (uomToRemove) {
            var index = this.mta.uom_questions.indexOf(uomToRemove);
            if (this.validIndex(index)) {
                if (this.mta.uom_questions[index].id) {
                    this.mta.uom_questions[index].removed_from_questionnaire = (action == 'remove' ? true : false);
                    this.mta.include_uom_question = (action == 'remove' ? false : true);
                }
                else {
                    this.mta.uom_questions.splice(index, 1);
                }
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.addOption = function (isOtherOption) {
        // isOtherOption = true would indicate that this is an option of qa_subtype 'Other' - a text field is to be displayed if this option is selected
        if (!this.mta.id) {
            this.toastr.warning('Please save changes first');
            return;
        }
        var optionToAdd = {
            survey_id: this.surveyId,
            parent_id: this.mta.id,
            parent_code: this.mta.code,
            qa_sequence: this.getSequence('option'),
            code: uuidv4(),
            qa_type: 'option',
            qa_subtype: isOtherOption ? 'other' : null,
            survey_q_asset_labels: [{ language_id: this.getLanguageId(), label: this.getOptionLabel(isOtherOption) }]
        };
        if (!this.mta.options) {
            this.mta.options = [];
        }
        this.mta.options.push(optionToAdd);
        this.addOrRemoveAsset(optionToAdd, 'add');
        this.setMtaHasOtherOption();
        if (isOtherOption) {
            this.addIsOtherOptionQuestionAsset();
        }
    };
    EditDynamicQuestionnaireComponent.prototype.getOptionLabel = function (isOtherOption) {
        if (isOtherOption) {
            // TODO: Return other in various languages
            return 'Other';
        }
        else {
            return '';
        }
    };
    EditDynamicQuestionnaireComponent.prototype.getLanguageId = function () {
        var _this = this;
        if (this.userLanguages && this.userLanguages.length > 0) {
            return this.userLanguages.find(function (item) { return item.locale == _this.locale; })['id'];
        }
        else {
            return undefined;
        }
    };
    EditDynamicQuestionnaireComponent.prototype.validIndex = function (index) {
        if (index != null && index > -1) {
            return true;
        }
        return false;
    };
    EditDynamicQuestionnaireComponent.prototype.getSequence = function (assetType) {
        var x;
        if (assetType == 'option' && this.mta.options) {
            x = Object.assign([], JSON.parse(JSON.stringify(this.mta.options)));
        }
        else if (assetType == 'question' && this.currentSection.questions) {
            x = Object.assign([], JSON.parse(JSON.stringify(this.currentSection.questions)));
        }
        else if (assetType == 'subsection' && this.currentSection.subsections) {
            x = Object.assign([], JSON.parse(JSON.stringify(this.currentSection.subsections)));
        }
        else if (assetType == 'table' && this.currentSection.tables) {
            x = Object.assign([], JSON.parse(JSON.stringify(this.currentSection.tables)));
        }
        else if (assetType == 'section' && this.currentTab.sections) {
            x = Object.assign([], JSON.parse(JSON.stringify(this.currentTab.sections)));
        }
        // If the question has options, sort the options already in this question. If no options, return 1
        // Similar logic for other asset types
        if (x && x.length > 0) {
            x.sort(function (a, b) {
                if (a.qa_sequence > b.qa_sequence) {
                    return 1;
                }
                else if (a.qa_sequence < b.qa_sequence) {
                    return -1;
                }
                else {
                    return 0;
                }
            });
            // Get the max qa_sequence value in current set of options
            var maxSequence = x[x.length - 1].qa_sequence;
            return maxSequence + 1;
        }
        else {
            return 1;
        }
    };
    EditDynamicQuestionnaireComponent.prototype.getRelativeSequence = function (relativeToAsset, direction) {
        // Find the sibling assets to relativeToAsset
        // Check if an asset with qa_sequence = relativeToAsset + direction exists
        // If it does not, return relativeToAsset + direction
        // If it does, check relativeToAsset + direction + direction.. until you find an unclaimed qa_sequence number.
        if (relativeToAsset.qa_sequence == null) {
            return 0;
        }
        else {
            var siblingAssets = [];
            if (relativeToAsset.qa_type == 'section') {
                siblingAssets = this.currentTab.sections.filter(function (item) { return item.id != relativeToAsset.id; });
            }
            if (relativeToAsset.qa_type == 'question') {
                var parentAsset = this.getParentAsset(relativeToAsset);
                siblingAssets = parentAsset.questions;
            }
            if (relativeToAsset.qa_type == 'subsection') {
                var parentAsset = this.getParentAsset(relativeToAsset);
                siblingAssets = parentAsset.subsections;
            }
            if (relativeToAsset.qa_type == 'table') {
                var parentAsset = this.getParentAsset(relativeToAsset);
                siblingAssets = parentAsset.tables;
            }
            var sequenceToCheck_1 = relativeToAsset.qa_sequence + direction;
            var sequenceIsAlreadyClaimed = siblingAssets.find(function (item) { return sequenceToCheck_1 != relativeToAsset.qa_sequence && item.qa_sequence == sequenceToCheck_1; });
            if (!sequenceIsAlreadyClaimed) {
                return relativeToAsset.qa_sequence + direction;
            }
            else {
                return sequenceIsAlreadyClaimed.qa_sequence;
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.getParentAsset = function (childAsset) {
        var parentAsset;
        if (childAsset.qa_type == 'question') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
                        parentAsset = this.questionnaire[i].sections[j];
                        break loop1;
                    }
                    else {
                        var parentSubsection = this.questionnaire[i].sections[j].subsections.find(function (item) { return item.code == childAsset.parent_code; });
                        if (parentSubsection) {
                            parentAsset = parentSubsection;
                            break loop1;
                        }
                        var parentTable = this.questionnaire[i].sections[j].tables.find(function (item) { return item.code == childAsset.parent_code; });
                        if (parentTable) {
                            parentAsset = parentTable;
                            break loop1;
                        }
                    }
                }
            }
        }
        if (childAsset.qa_type == 'subsection') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
                        parentAsset = this.questionnaire[i].sections[j];
                        break loop1;
                    }
                }
            }
        }
        if (childAsset.qa_type == 'section') {
            for (var i = 0; i < this.questionnaire.length; i++) {
                parentAsset = this.questionnaire.find(function (item) { return item.code == childAsset.parent_code; });
            }
        }
        if (childAsset.qa_type == 'option') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    var parentQuestion = this.questionnaire[i].sections[j].questions.find(function (item) { return item.code == childAsset.parent_code; });
                    if (parentQuestion) {
                        parentAsset = parentQuestion;
                        break loop1;
                    }
                    else {
                        if (this.questionnaire[i].sections[j].subsections && this.questionnaire[i].sections[j].subsections.length > 0) {
                            for (var i_1 = 0; i_1 < this.questionnaire[i_1].sections[j].subsections.length; i_1++) {
                                var parentQuestion_1 = this.questionnaire[i_1].sections[j].subsections[i_1].questions.find(function (item) { return item.code == childAsset.parent_code; });
                                if (parentQuestion_1) {
                                    parentAsset = parentQuestion_1;
                                    break loop1;
                                }
                            }
                        }
                        if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                            for (var i_2 = 0; i_2 < this.questionnaire[i_2].sections[j].tables.length; i_2++) {
                                var parentQuestion_2 = this.questionnaire[i_2].sections[j].tables[i_2].questions.find(function (item) { return item.code == childAsset.parent_code; });
                                if (parentQuestion_2) {
                                    parentAsset = parentQuestion_2;
                                    break loop1;
                                }
                            }
                        }
                    }
                }
            }
        }
        if (childAsset.qa_type == 'table') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
                        parentAsset = this.questionnaire[i].sections[j];
                        break loop1;
                    }
                }
            }
        }
        return parentAsset;
    };
    EditDynamicQuestionnaireComponent.prototype.changeQuestion = function (action, calledFromMarkChildrenDeleted) {
        // console.log('this.displayLogicKeys', this.displayLogicKeys);
        // console.log('this.mta.id', this.mta.id);
        if (this.mta.id && this.displayLogicKeys.indexOf(this.mta.id.toString()) > -1) {
            this.toastr.error('This question is part of a display logic condition & cannot be removed. Please modify the display logic first.');
            return;
        }
        if (this.mta.id && this.displayLogicValues.indexOf(this.mta.id.toString()) > -1) {
            this.toastr.error('This option is part of a display logic condition & cannot be removed. Please modify the display logic first.');
            return;
        }
        this.mta.removed_from_questionnaire = action == 'remove' ? true : false;
        this.updateChangedAssets();
        this.updateCurrentTab();
        if (!calledFromMarkChildrenDeleted) {
            if (action == 'remove')
                this.markChildrenDeleted2(this.mta);
        }
    };
    EditDynamicQuestionnaireComponent.prototype.closeModifyingAssetTemplateModal = function () {
        this.mta = undefined;
        if (this.modifyingAssetModal) {
            this.modifyingAssetModal.hide();
        }
    };
    EditDynamicQuestionnaireComponent.prototype.cancelModifyingQuestion = function () {
        this.modifyingThisQuestion = undefined;
    };
    EditDynamicQuestionnaireComponent.prototype.activateSnapshot = function (template, template2) {
        if (this.changedAssets.length > 0) {
            this.confirmForChanges = this.modalService.show(template2, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            this.closeSnapshotModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
    };
    EditDynamicQuestionnaireComponent.prototype.declineSaveChangesActivate = function () {
        this.confirmForChanges.hide();
    };
    EditDynamicQuestionnaireComponent.prototype.moveWithoutSaveChangesActivate = function () {
        this.confirmForChanges.hide();
        this.activateSurvey();
    };
    EditDynamicQuestionnaireComponent.prototype.confirmActivation = function () {
        if (this.closeSnapshotModal) {
            this.closeSnapshotModal.hide();
        }
        this.activateSurvey();
    };
    EditDynamicQuestionnaireComponent.prototype.declineCloseSnapshot = function () {
        if (this.closeSnapshotModal) {
            this.closeSnapshotModal.hide();
        }
    };
    EditDynamicQuestionnaireComponent.prototype.activateSurvey = function () {
        var _this = this;
        if (this.disableButtons || this.savingTemplate) {
            return;
        }
        else {
            this.spinner.show();
            this.disableButtons = true;
            this.ref.detectChanges();
            this.surveyService.activateSurvey(this.surveyId)
                .subscribe(function (result) {
                if (result.code == 'success') {
                    _this.toastr.success('Activated survey');
                    _this.disableButtons = false;
                    _this.ref.detectChanges();
                    _this.router.navigate(['questionnaires/' + _this.projectId]);
                    _this.spinner.hide();
                }
            }, function (error) {
                _this.spinner.hide();
                _this.disableButtons = false;
                _this.ref.detectChanges();
            });
        }
    };
    EditDynamicQuestionnaireComponent.prototype.launchSaveAsTemplateModal = function (template, template2) {
        this.savingTemplate = false;
        this.templateNameInput = undefined;
        this.templateDescriptionInput = undefined;
        this.saveTemplateSubmitted = false;
        if (this.changedAssets.length > 0) {
            this.confirmForChanges = this.modalService.show(template2, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            this.saveTemplateModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
    };
    EditDynamicQuestionnaireComponent.prototype.declineSaveChanges = function () {
        this.confirmForChanges.hide();
    };
    EditDynamicQuestionnaireComponent.prototype.moveWithoutSaveChangesTemplate = function () {
        this.confirmForChanges.hide();
        this.saveTemplateModal = this.modalService.show(this.saveTemplateModalPopup, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    EditDynamicQuestionnaireComponent.prototype.closeSaveTemplateModal = function () {
        if (this.saveTemplateModal) {
            this.saveTemplateModal.hide();
        }
        this.templateNameInput = undefined;
        this.templateDescriptionInput = undefined;
        this.saveTemplateSubmitted = false;
        this.savingTemplate = false;
        this.ref.detectChanges();
    };
    EditDynamicQuestionnaireComponent.prototype.saveTemplate = function () {
        var _this = this;
        if (this.savingTemplate || this.disableButtons) {
            return;
        }
        this.saveTemplateSubmitted = true;
        if (!this.templateNameInput || this.templateNameInput.length == 0 || !this.templateDescriptionInput || this.templateDescriptionInput.length == 0) {
            return;
        }
        var templateDetails = {
            name: this.templateNameInput,
            description: this.templateDescriptionInput
        };
        this.savingTemplate = true;
        this.ref.detectChanges();
        this.surveyService.saveAsTemplate(this.surveyId, templateDetails)
            .subscribe(function (result) {
            if (result['code'] == 'success') {
                _this.toastr.success('template_created');
            }
            _this.closeSaveTemplateModal();
        }, function (error) {
            _this.closeSaveTemplateModal();
        });
    };
    // Set the angular reactive form validators for the given question
    EditDynamicQuestionnaireComponent.prototype.setQuestionValidations = function (question, secondaryAssetKey) {
        if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) {
            var formControlName = question.id;
            if (secondaryAssetKey) {
                formControlName = formControlName + '_' + secondaryAssetKey;
            }
            var arrValidators = [];
            for (var j = 0; j < question.survey_q_asset_validations.length; j++) {
                if (question.survey_q_asset_validations[j].validation_key == 'mandatory' && question.survey_q_asset_validations[j].validation_value) {
                    arrValidators.push(Validators.required);
                }
                if (question.survey_q_asset_validations[j].validation_key == 'regex_pattern') {
                    arrValidators.push(Validators.pattern(question.survey_q_asset_validations[j].validation_value));
                }
                if (question.survey_q_asset_validations[j].validation_key == 'min_value') {
                    arrValidators.push(Validators.min(question.survey_q_asset_validations[j].validation_value));
                }
                if (question.survey_q_asset_validations[j].validation_key == 'max_value') {
                    arrValidators.push(Validators.max(question.survey_q_asset_validations[j].validation_value));
                }
            }
            this.sectionForm.controls[formControlName].setValidators(arrValidators);
            this.sectionForm.controls[formControlName].updateValueAndValidity();
        }
    };
    // Check if the uom_question has a value, & create the corresponding sectionForm control. Also add the options to be displayed in the dropdown
    EditDynamicQuestionnaireComponent.prototype.setUOMQuestion = function (question, existsInQAnswersForTableQuestionItem) {
        if (!existsInQAnswersForTableQuestionItem) {
            // If the question has uom_questions children elements
            if (question.uom_questions && question.uom_questions.length > 0) {
                var _loop_3 = function (i) {
                    // For each uom_question child element, check if it has a value in qAnswers, & create the form control accordingly
                    var existsInQAnswers = this_3.existsInQAnswers(question.uom_questions[i]);
                    this_3.sectionForm.addControl(question.uom_questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
                    // Set the options dropdown for this uom_question
                    question.uom_questions[i].options = this_3.project.ProjectUOM.filter(function (item) { return item.unit_type_id == question.uom_questions[i].qa_subtype; });
                };
                var this_3 = this;
                for (var i = 0; i < question.uom_questions.length; i++) {
                    _loop_3(i);
                }
            }
        }
        else {
            if (question.uom_questions && question.uom_questions.length > 0) {
                var _loop_4 = function (i) {
                    var valueObject = this_4.qAnswers.find(function (item) { return item.key == question.uom_questions[i].id && item.secondary_asset_key == existsInQAnswersForTableQuestionItem['secondary_asset_key']; });
                    this_4.sectionForm.addControl(question.uom_questions[i].id + '_' + existsInQAnswersForTableQuestionItem['secondary_asset_key'], valueObject != null ? new FormControl(valueObject['value']) : new FormControl(''));
                    question.uom_questions[i].options = this_4.project.ProjectUOM.filter(function (item) { return item.unit_type_id == question.uom_questions[i].qa_subtype; });
                };
                var this_4 = this;
                for (var i = 0; i < question.uom_questions.length; i++) {
                    _loop_4(i);
                }
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.existsInQAnswersForTableQuestion = function (question) {
        return this.qAnswers.filter(function (item) { return item.key === question.id; });
    };
    // Set the checkedObject object attribute of question id + secondary_asset_key + option id to true if it exists in qAnswers for a given secondary asset key
    // This is used for checkbox questions that are in a table
    EditDynamicQuestionnaireComponent.prototype.setCheckedObjectSecondaryKeys = function (existsInQAnswersForTableQuestionItem, question) {
        this.checkedObjectSecondaryKeys[question.id + '_' + existsInQAnswersForTableQuestionItem.secondary_asset_key + '_' + existsInQAnswersForTableQuestionItem['value']] = true;
    };
    EditDynamicQuestionnaireComponent.prototype.subtypeChanged = function (event) { };
    EditDynamicQuestionnaireComponent.prototype.saveQuestionnaireChanges = function () {
        var _this = this;
        this.spinner.show();
        this.surveyService.saveQuestionnaireChanges(this.surveyId, this.changedAssets)
            .subscribe(function (result) {
            if (result.code == 'success') {
                _this.updateQuestionnaireAfterSaving();
                _this.spinner.hide();
            }
            if (result.code == 'fail') {
                // TODO: Done: Caught in error handler. Let user know about errors
                _this.toastr.error('error');
                _this.spinner.hide();
            }
        });
    };
    EditDynamicQuestionnaireComponent.prototype.updateQuestionnaireAfterSaving = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([
                                this.surveyService.getSurveyQuestionnaire(this.surveyId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['message']) {
                            // delete this.questionnaire;
                            this.reloadingQuestionnaire = true;
                            this.questionnaire = undefined;
                            this.ref.detectChanges();
                            setTimeout(function () {
                                _this.questionnaire = Object.assign([], JSON.parse(JSON.stringify(request[0]['message'])));
                                if (request[0]['message'] && request[0]['message'].length > 0) {
                                    _this.isBlankQuestionnaire = false;
                                    _this.ref.detectChanges();
                                }
                                _this.initialize(_this.currentTabIndex, _this.currentSectionIndex);
                            }, 500);
                            // this.ref.detectChanges();
                            this.changedAssets = [];
                            this.spinner.hide();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // updateTabs(tabsToUpdate): void {
    //   for (let i = 0; i < tabsToUpdate.length; i++) {
    //     let existsInQuestionnaire = this.questionnaire.find(item => item.id == tabsToUpdate[i].id);
    //     if (existsInQuestionnaire) {
    //       let index = this.questionnaire.indexOf(existsInQuestionnaire);
    //       if (this.validIndex(index)) {
    //         this.questionnaire[index] = tabsToUpdate[i];
    //       }
    //     } else {
    //       this.questionnaire.push(tabsToUpdate[i]);
    //     }
    //   }
    // }
    // updateSections(sectionsToUpdate): void {
    //   for (let i = 0; i < sectionsToUpdate.length; i++) {
    //     let sectionToUpdateTab = this.questionnaire.find(item => item.parent_id == sectionsToUpdate[i].parent_id);
    //     if (sectionToUpdateTab) {
    //       let index1 = this.questionnaire.indexOf(sectionToUpdateTab);
    //       if (this.validIndex(index1)) {
    //         let sectionToUpdate = this.questionnaire[index1].sections.find(item => item.id == sectionsToUpdate[i].id);
    //         if (sectionToUpdate) {
    //           let index2 = this.questionnaire[index1].sections.indexOf(sectionToUpdate);
    //           if (this.validIndex(index2)) {
    //             this.questionnaire[index1].sections[index2] = sectionsToUpdate[i];
    //           } else {
    //             this.questionnaire[index1].sections.push(sectionsToUpdate[i]);
    //           }
    //         }
    //       } else {
    //         // console.log('622 code should not reach here');
    //       }
    //     }
    //   }
    // }
    // TODO: Not relevant. This function
    EditDynamicQuestionnaireComponent.prototype.updateSubSections = function (subsectionsToUpdate) {
    };
    // TODO: Not relevant. This function
    EditDynamicQuestionnaireComponent.prototype.updateTables = function (subsectionsToUpdate) {
    };
    // TODO: Not relevant. This function
    EditDynamicQuestionnaireComponent.prototype.updateQuestions = function (subsectionsToUpdate) {
    };
    // TODO: Not relevant. This function
    EditDynamicQuestionnaireComponent.prototype.updateOptions = function (optionsToUpdate) {
    };
    // getParentId(id, parentType) {
    // }
    EditDynamicQuestionnaireComponent.prototype.updateChangedAssets = function () {
        var _this = this;
        var mtaBeingModified = this.changedAssets.find(function (item) { return item.code == _this.mta.code; });
        if (mtaBeingModified) {
            var index = this.changedAssets.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
                this.changedAssets[index] = this.mta;
            }
        }
        else {
            this.changedAssets.push(this.mta);
        }
        // console.log('this.changedAssets', this.changedAssets);
    };
    EditDynamicQuestionnaireComponent.prototype.updateCurrentTab = function () {
        if (this.mta.qa_type == 'question') {
            this.updateCurrentTabQuestion();
        }
        else if (this.mta.qa_type == 'section') {
            this.updateCurrentTabSection();
        }
        else if (this.mta.qa_type == 'tab') {
            this.updateCurrentTabEntireTab();
        }
        else if (this.mta.qa_type == 'subsection') {
            this.updateCurrentTabSubsection();
        }
        else if (this.mta.qa_type == 'table') {
            this.updateCurrentTabTable();
        }
    };
    EditDynamicQuestionnaireComponent.prototype.updateCurrentTabQuestion = function () {
        var _this = this;
        var parentAsset = this.getParentAsset(this.mta);
        if (parentAsset.qa_type == 'section') {
            var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.find(function (item) { return item.code == _this.mta.code; });
            if (mtaBeingModified) {
                var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.indexOf(mtaBeingModified);
                if (this.validIndex(index)) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[index] = this.mta;
                }
            }
            else {
                if (this.relativeToAssetIndex != undefined) {
                    if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions) {
                        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions = [];
                    }
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.splice(this.relativeToAssetIndex + 1, 0, this.mta);
                }
                else {
                    if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions) {
                        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions = [];
                    }
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.splice(this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.length, 0, this.mta);
                }
            }
        }
        else if (parentAsset.qa_type == 'subsection') {
            var subsectionBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.find(function (item) { return item.code == _this.mta.parent_code; });
            if (subsectionBeingModified) {
                var subsectionIndex = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.indexOf(subsectionBeingModified);
                if (this.validIndex(subsectionIndex)) {
                    var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions.find(function (item) { return item.code == _this.mta.code; });
                    if (mtaBeingModified) {
                        var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions.indexOf(mtaBeingModified);
                        if (this.validIndex(index)) {
                            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions[index] = this.mta;
                        }
                    }
                    else {
                        if (this.relativeToAssetIndex != undefined) {
                            if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions) {
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions = [];
                            }
                            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions.splice(this.relativeToAssetIndex + 1, 0, this.mta);
                        }
                    }
                }
            }
        }
        else if (parentAsset.qa_type == 'table') {
            var tableBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.find(function (item) { return item.code == _this.mta.parent_code; });
            if (tableBeingModified) {
                var tableIndex = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.indexOf(tableBeingModified);
                if (this.validIndex(tableIndex)) {
                    var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions.find(function (item) { return item.code == _this.mta.code; });
                    if (mtaBeingModified) {
                        var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions.indexOf(mtaBeingModified);
                        if (this.validIndex(index)) {
                            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions[index] = this.mta;
                        }
                    }
                    else {
                        if (this.relativeToAssetIndex != undefined) {
                            if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions) {
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions = [];
                            }
                            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions.splice(this.relativeToAssetIndex + 1, 0, this.mta);
                        }
                    }
                }
            }
        }
        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined);
    };
    EditDynamicQuestionnaireComponent.prototype.updateCurrentTabSection = function () {
        var _this = this;
        var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections.find(function (item) { return item.code == _this.mta.code; });
        if (mtaBeingModified) {
            var index = this.questionnaire[this.currentTabIndex].sections.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
                this.questionnaire[this.currentTabIndex].sections[index] = this.mta;
            }
        }
        else {
            if (this.relativeToAssetIndex != undefined) {
                if (!this.questionnaire[this.currentTabIndex].sections) {
                    this.questionnaire[this.currentTabIndex].sections = [];
                }
                this.questionnaire[this.currentTabIndex].sections.splice(this.relativeToAssetIndex + 1, 0, this.mta);
            }
            else {
                if (!this.questionnaire[this.currentTabIndex].sections) {
                    this.questionnaire[this.currentTabIndex].sections = [];
                }
                this.questionnaire[this.currentTabIndex].sections.splice(this.questionnaire[this.currentTabIndex].sections.length, 0, this.mta);
            }
        }
        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined);
    };
    EditDynamicQuestionnaireComponent.prototype.updateCurrentTabSubsection = function () {
        var _this = this;
        var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.find(function (item) { return item.code == _this.mta.code; });
        if (mtaBeingModified) {
            var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[index] = this.mta;
            }
        }
        else {
            if (this.relativeToAssetIndex != undefined) {
                if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections = [];
                }
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.splice(this.relativeToAssetIndex + 1, 0, this.mta);
            }
            else {
                if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections = [];
                }
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.splice(this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.length, 0, this.mta);
            }
        }
        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined);
    };
    EditDynamicQuestionnaireComponent.prototype.updateCurrentTabTable = function () {
        var _this = this;
        var mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.find(function (item) { return item.code == _this.mta.code; });
        if (mtaBeingModified) {
            var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[index] = this.mta;
            }
        }
        else {
            if (this.relativeToAssetIndex != undefined) {
                if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables = [];
                }
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.splice(this.relativeToAssetIndex + 1, 0, this.mta);
            }
            else {
                if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables = [];
                }
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.splice(this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.length, 0, this.mta);
            }
        }
        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined);
    };
    EditDynamicQuestionnaireComponent.prototype.updateCurrentTabEntireTab = function () {
        var _this = this;
        var mtaBeingModified = this.questionnaire.find(function (item) { return item.code == _this.mta.code; });
        if (mtaBeingModified) {
            var index = this.questionnaire.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
                this.questionnaire[index] = this.mta;
            }
        }
        else {
            if (this.relativeToAssetIndex != undefined) {
                if (!this.questionnaire) {
                    this.questionnaire = [];
                }
                this.questionnaire.splice(this.relativeToAssetIndex + 1, 0, this.mta);
            }
        }
        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined);
    };
    // TODO: Not part of 1st release: Add validtion handler function
    EditDynamicQuestionnaireComponent.prototype.addValidation = function () {
        var validationToAdd = {
            questionnaire_asset_id: this.mta.id,
            validation_key: '',
            validation_value: ''
        };
        if (!this.mta.survey_q_asset_validations) {
            this.mta.survey_q_asset_validations = [];
        }
        this.mta.survey_q_asset_validations.push(validationToAdd);
    };
    EditDynamicQuestionnaireComponent.prototype.removeValidation = function (validationToRemove) {
        if (validationToRemove.id) {
            this.mta.survey_q_asset_validations = this.mta.survey_q_asset_validations.filter(function (item) { return item.id != validationToRemove.id; });
        }
        else {
            var lastValidatorIndex = this.lastMatchingObjectIndex(this.mta.survey_q_asset_validations, validationToRemove.validation_key);
            // console.log('lastValidatorIndex', lastValidatorIndex);
            this.mta.survey_q_asset_validations.splice(lastValidatorIndex, 1);
        }
    };
    EditDynamicQuestionnaireComponent.prototype.lastMatchingObjectIndex = function (array, matchingAttributeValue) {
        var index = array.length - 1;
        for (; index >= 0; index--) {
            if (array[index].validation_key == matchingAttributeValue) {
                break;
            }
        }
        return index;
    };
    EditDynamicQuestionnaireComponent.prototype.changeMandatory = function (event) {
        if (event.target.checked) {
            if (this.mta.survey_q_asset_validations.find(function (item) { return item.validation_key == 'mandatory'; })) {
            }
            else {
                var validationToAdd = {
                    questionnaire_asset_id: this.mta.id,
                    validation_key: 'mandatory',
                    validation_value: 'true'
                };
                this.mta.survey_q_asset_validations.push(validationToAdd);
            }
        }
        else {
            if (this.mta.survey_q_asset_validations.find(function (item) { return item.validation_key == 'mandatory'; })) {
                this.mta.survey_q_asset_validations = this.mta.survey_q_asset_validations.filter(function (item) { return item.validation_key != 'mandatory'; });
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.addToMTAIssues = function (issue) {
        if (!this.mtaIssues.find(function (item) { return item.code == issue.code; })) {
            this.mtaIssues.push(issue);
        }
    };
    // Valiate the MTA before committing the changes
    EditDynamicQuestionnaireComponent.prototype.validateMTA = function () {
        // If qa_subtype is select or multiselect, it must have at least 1 option
        if (this.mta.id && (this.mta.qa_subtype == 'select' || this.mta.qa_subtype == 'multiselect') && (!this.mta.options || this.mta.options.length == 0 || !this.mta.options.find(function (item) { return !item.removed_from_questionnaire; }))) {
            this.addToMTAIssues({ code: 1, text: 'Must have at least 1 option' });
            this.mtaIssues.push();
            return false;
        }
        // If qa_subtype is text or number, it must have 0 options
        if (this.mta.id && (this.mta.qa_subtype == 'text' || this.mta.qa_subtype == 'number') && (this.mta.options && this.mta.options.length > 0 && this.mta.options.find(function (item) { return !item.removed_from_questionnaire; }))) {
            this.addToMTAIssues({ code: 2, text: 'Please remove all options' });
            return false;
        }
        // If mta has uom_question the mta must have a valid qa_subtype
        if (this.mta.id && !(this.mta.qa_subtype == 'text' || this.mta.qa_subtype == 'number') && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
            this.addToMTAIssues({ code: 3, text: 'Question must be of type Number or Text' });
            return false;
        }
        // Must not have duplicated validators
        if (this.mta.id && this.mta.survey_q_asset_validations && this.mta.survey_q_asset_validations.length > 0) {
            if (this.hasObjectWithDuplicates(this.mta.survey_q_asset_validations, 'validation_key')) {
                this.addToMTAIssues({ code: 4, text: 'Only one validator of each type is permitted' });
                return false;
            }
        }
        // Must not have duplicated uom_questions (two uom_questions of the same qa_subtype)
        if (this.mta.id && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
            if (this.hasObjectWithDuplicates(this.mta.uom_questions, 'qa_subtype')) {
                this.addToMTAIssues({ code: 5, text: 'Only one unit of measurement of each type is permitted' });
                return false;
            }
        }
        // Each validator must have a not-null not-blank value
        if (this.mta.id && this.mta.survey_q_asset_validations && this.mta.survey_q_asset_validations.length > 0) {
            if (this.hasObjectWithBlankValue(this.mta.survey_q_asset_validations, 'validation_value')) {
                this.addToMTAIssues({ code: 6, text: 'Each validator must have a value' });
                return false;
            }
            if (this.hasObjectWithBlankValue(this.mta.survey_q_asset_validations, 'validation_key')) {
                this.addToMTAIssues({ code: 7, text: 'Each validator must have a key' });
                return false;
            }
        }
        // If the unit qa_subtype or label is blank, throw an error
        if (this.mta.id && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
            var x_1 = [];
            for (var i = 0; i < this.mta.uom_questions.length; i++) {
                x_1 = x_1.concat(this.mta.uom_questions[i].survey_q_asset_labels);
            }
            if (this.hasObjectWithBlankValue(x_1, 'label')) {
                this.addToMTAIssues({ code: 8, text: 'Each unit must have a label. Set default "Unit"' });
                return false;
            }
            if (this.hasObjectWithBlankValue(this.mta.uom_questions, 'qa_subtype')) {
                this.addToMTAIssues({ code: 9, text: 'Please select a unit type for each unit of measurement' });
                return false;
            }
        }
        // Units are permitted only in text & number type questions
        if (this.mta.id && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
            if (this.mta.qa_subtype != 'text' && this.mta.qa_subtype != 'number') {
                this.addToMTAIssues({ code: 10, text: 'Units are permitted for questions of type "Text" and "Number' });
                return false;
            }
        }
        // Validate that each of the labels have non-empty string as label
        if (this.hasObjectWithBlankValue(this.mta.survey_q_asset_labels, 'label')) {
            this.addToMTAIssues({ code: 11, text: 'Please enter a label' });
            return false;
        }
        // Validate that each of the options has a label
        var x = [];
        if (this.mta.options && this.mta.options.length > 0) {
            for (var i = 0; i < this.mta.options.length; i++) {
                if (this.mta.options[i].survey_q_asset_labels && this.mta.options[i].survey_q_asset_labels.length > 0) {
                    for (var j = 0; j < this.mta.options[i].survey_q_asset_labels.length; j++) {
                        x.push(this.mta.options[i].survey_q_asset_labels[j]);
                    }
                }
            }
        }
        if (this.hasObjectWithBlankValue(x, 'label')) {
            this.addToMTAIssues({ code: 12, text: 'Please enter a label for each option' });
            return false;
        }
        // Validate that both disabled & mandatory options are not selected for the question
        if (this.mta.disable_input && this.mta.isMandatory) {
            this.addToMTAIssues({ code: 13, text: 'Cannot select both "Mandatory" & "Disable input" options at once' });
            return false;
        }
        // Validate that MTA has valid language_id in labels
        for (var i = 0; i < this.mta.survey_q_asset_labels.length; i++) {
            if (!this.mta.survey_q_asset_labels[i].language_id) {
                console.log('!this.mta.survey_q_asset_labels[i].language_id', this.mta.survey_q_asset_labels[i]);
                this.addToMTAIssues({ code: 14, text: 'Please cancel and try making the change again.' });
                return false;
            }
        }
        // Validate that MTA options if any have valid language_id in labels
        if (this.mta.options && this.mta.options.length > 0) {
            for (var j = 0; j < this.mta.options.length; j++) {
                for (var i = 0; i < this.mta.options[j].survey_q_asset_labels.length; i++) {
                    if (!this.mta.options[j].survey_q_asset_labels[i].language_id) {
                        this.addToMTAIssues({ code: 15, text: 'Please cancel and try making the change again.' });
                        return false;
                    }
                }
            }
        }
        // Validate that the label is 300 characters or less
        for (var i = 0; i < this.mta.survey_q_asset_labels.length; i++) {
            if (this.mta.survey_q_asset_labels[i].label && this.mta.survey_q_asset_labels[i].label.length > 300) {
                this.addToMTAIssues({ code: 15, text: 'One or more label length exceeds limit: 300.' });
                return false;
            }
        }
        // Validate that the MTA Option label is 300 characters or less
        if (this.mta.options && this.mta.options.length > 0) {
            for (var j = 0; j < this.mta.options.length; j++) {
                for (var i = 0; i < this.mta.options[j].survey_q_asset_labels.length; i++) {
                    if (this.mta.options[j].survey_q_asset_labels[i].label && this.mta.options[j].survey_q_asset_labels[i].label.length > 300) {
                        this.addToMTAIssues({ code: 16, text: 'One or more label length exceeds limit: 300' });
                        return false;
                    }
                }
            }
        }
        if (this.mta.qa_subtype && typeof (this.mta.qa_subtype) != 'string') {
            this.addToMTAIssues({ code: 17, text: 'Please select a valid value for "Type"' });
            return false;
        }
        this.mtaIssues = [];
        return true;
    };
    EditDynamicQuestionnaireComponent.prototype.hasObjectWithDuplicates = function (array, attributeToCheck) {
        var valueArr = [];
        array.forEach(function (element) {
            if (!element.removed_from_questionnaire) {
                valueArr.push(element[attributeToCheck]);
            }
        });
        var isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx;
        });
        return isDuplicate;
    };
    EditDynamicQuestionnaireComponent.prototype.hasObjectWithBlankValue = function (array, attributeToCheck) {
        for (var i = 0; i < array.length; i++) {
            if (!array[i][attributeToCheck] || array[i][attributeToCheck] == '') {
                return true;
            }
        }
        return false;
    };
    EditDynamicQuestionnaireComponent.prototype.addOrRemoveAsset = function (asset, action) {
        this.handleDisplay(asset, action);
        this.handleChangedAssets(asset, action);
    };
    EditDynamicQuestionnaireComponent.prototype.handleDisplay = function (asset, action) {
        if (asset.qa_type == 'question') {
            this.mta.removed_from_questionnaire = (action == 'remove' ? true : false);
        }
        if (asset.qa_type == 'option') {
            if (this.displayLogicValues.indexOf(asset.id) > -1) {
                this.toastr.warning('This option is part of a display logic condition & cannot be removed');
                return;
            }
            var optionToRemove = this.mta.options.find(function (item) { return item.code == asset.code; });
            if (optionToRemove) {
                var index = this.mta.options.indexOf(optionToRemove);
                if (this.validIndex(index)) {
                    this.mta.options[index].removed_from_questionnaire = (action == 'remove' ? true : false);
                }
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.handleChangedAssets = function (asset, action) {
        var nowUTCString = new Date().toUTCString();
        if (!this.questionnaireChanges['assets']) {
            this.questionnaireChanges['assets'] = {};
        }
        if (action == 'remove') {
            if (this.isRedundant(asset, action)) {
                this.questionnaireChanges['assets']['add'] = this.questionnaireChanges['assets']['add'].filter(function (item) { return !(item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code)); });
            }
            else {
                if (!this.questionnaireChanges['assets']['remove']) {
                    this.questionnaireChanges['assets']['remove'] = [];
                }
                var element = asset.id ? { qa_type: asset.qa_type, identifier: 'id', value: asset.id, at: nowUTCString } : { qa_type: asset.qa_type, identifier: 'code', value: asset.code, at: nowUTCString };
                this.questionnaireChanges['assets']['remove'].push(element);
            }
        }
        else {
            if (this.isRedundant(asset, action)) {
                this.questionnaireChanges['assets']['remove'] = this.questionnaireChanges['assets']['remove'].filter(function (item) { return !(item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code)); });
            }
            else {
                if (!this.questionnaireChanges['assets']['add']) {
                    this.questionnaireChanges['assets']['add'] = [];
                }
                var element = asset.id ? { qa_type: asset.qa_type, identifier: 'id', value: asset.id, at: nowUTCString } : { qa_type: asset.qa_type, identifier: 'code', value: asset.code, at: nowUTCString };
                this.questionnaireChanges['assets']['add'].push(element);
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.isRedundant = function (asset, action) {
        if (action == 'remove') {
            if (this.questionnaireChanges['assets']['add']) {
                if (this.questionnaireChanges['assets']['add'].find(function (item) { return item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code); })) {
                    return true;
                }
            }
        }
        else {
            if (this.questionnaireChanges['assets']['remove']) {
                if (this.questionnaireChanges['assets']['remove'].find(function (item) { return item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code); })) {
                    return true;
                }
            }
        }
        return false;
    };
    EditDynamicQuestionnaireComponent.prototype.clickOnQuestionDiv = function (question) {
        if (question && (this.modifyAutocalculateFormulaForAsset || this.autocalculateFormulaForAssetExists)) {
            if (question.qa_subtype != 'number') {
                this.toastr.error('Select a question of type "Number"');
                return;
            }
            if ((this.modifyAutocalculateFormulaForAsset && this.modifyAutocalculateFormulaForAsset.code != question.code) || (this.autocalculateFormulaForAssetExists && this.autocalculateFormulaForAssetExists.code != question.code)) {
                this.setQuestionInModifyingAutocalcualteFormula(question);
            }
        }
        else if (this.modifyDisplayLogicForAsset) {
            if (question && !this.displayLogicDisplayIfKey && this.canSelectQuestionInDisplayLogic(question)) {
                this.displayLogicDisplayIfKey = question;
                this.displayLogicDisplayIfValue = undefined;
                this.addDisplayLogic(question.id, undefined);
            }
        }
        else {
            this.showAddButtonAfterThisQuestion = question;
        }
    };
    EditDynamicQuestionnaireComponent.prototype.canSelectQuestionInDisplayLogic = function (clickedQuestion) {
        // Dont allow selection of the question in its own display logic
        if (this.modifyDisplayLogicForAsset.code == clickedQuestion.code) {
            this.toastr.error('Cannot select the same question');
            return false;
        }
        // Dont allow selection of a question in its parent's display logic
        if (this.modifyDisplayLogicForAsset.code == clickedQuestion.parent_code) {
            this.toastr.error('Cannot select a child question');
            return false;
        }
        // Dont allow selection of a question that has been removed from the questionnaire
        if (clickedQuestion.removed_from_questionnaire) {
            this.toastr.error('Please select a question that is not remvoved from the questionnaire');
            return false;
        }
        return true;
    };
    EditDynamicQuestionnaireComponent.prototype.changeAssetSequence = function (asset, direction) {
        if (asset.qa_type == 'question') {
            this.changeQuestionSequence(asset, direction);
        }
        if (asset.qa_type == 'section') {
            this.changeSectionSequence(asset, direction);
        }
        if (asset.qa_type == 'tab') {
            this.changeTabSequence(asset, direction);
        }
        if (asset.qa_type == 'subsection') {
            this.changeSubsectionSequence(asset, direction);
        }
        if (asset.qa_type == 'table') {
            this.changeTableSequence(asset, direction);
        }
        if (asset.qa_type == 'option') {
            this.changeOptionSequence(asset, direction);
        }
    };
    EditDynamicQuestionnaireComponent.prototype.changeTabSequence = function (tab, direction) {
        var allChildrenAssets = this.questionnaire;
        var x = allChildrenAssets.find(function (item) { return item.code == tab.code; });
        if (x) {
            var indexOfTab = allChildrenAssets.indexOf(x);
            if (this.validIndex(indexOfTab)) {
                var moveTabToIndex = indexOfTab + direction;
                var destinationAsset = allChildrenAssets[moveTabToIndex];
                var newSequenceOfDestinationAsset = tab.qa_sequence;
                tab.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfTab) && this.validIndex(moveTabToIndex)) {
                    this.questionnaire[indexOfTab] = destinationAsset;
                    this.questionnaire[moveTabToIndex] = tab;
                }
                this.mta = tab;
                this.updateChangedAssets();
                this.mta = destinationAsset;
                this.updateChangedAssets();
                this.initialize(moveTabToIndex, this.currentSectionIndex);
                this.clickOnQuestionDiv(undefined);
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.changeSectionSequence = function (section, direction) {
        var parentAsset = this.getParentAsset(section);
        var allChildrenAssets = parentAsset.sections;
        var x = allChildrenAssets.find(function (item) { return item.code == section.code; });
        if (x) {
            var indexOfSection = allChildrenAssets.indexOf(x);
            if (this.validIndex(indexOfSection)) {
                var moveQuestionToIndex = indexOfSection + direction;
                var destinationAsset = parentAsset.sections[moveQuestionToIndex];
                var newSequenceOfDestinationAsset = section.qa_sequence;
                section.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfSection) && this.validIndex(moveQuestionToIndex)) {
                    this.questionnaire[this.currentTabIndex].sections[indexOfSection] = destinationAsset;
                    this.questionnaire[this.currentTabIndex].sections[moveQuestionToIndex] = section;
                }
                this.mta = section;
                this.updateChangedAssets();
                this.mta = destinationAsset;
                this.updateChangedAssets();
                this.initialize(this.currentTabIndex, moveQuestionToIndex);
                this.clickOnQuestionDiv(undefined);
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.changeQuestionSequence = function (question, direction) {
        var parentAsset = this.getParentAsset(question);
        var allChildrenAssets = parentAsset.questions;
        var questionBeingModified = allChildrenAssets.find(function (item) { return item.code == question.code; });
        if (questionBeingModified) {
            var indexOfQuestion = allChildrenAssets.indexOf(questionBeingModified);
            if (this.validIndex(indexOfQuestion)) {
                var moveQuestionToIndex = indexOfQuestion + direction;
                var destinationAsset = parentAsset.questions[moveQuestionToIndex];
                var newSequenceOfDestinationAsset = question.qa_sequence;
                question.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfQuestion) && this.validIndex(moveQuestionToIndex)) {
                    if (parentAsset.qa_type == 'section') {
                        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[indexOfQuestion] = destinationAsset;
                        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[moveQuestionToIndex] = question;
                    }
                    else if (parentAsset.qa_type == 'subsection') {
                        var parentSubsection = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.find(function (item) { return item.code == parentAsset.code; });
                        if (parentSubsection) {
                            var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.indexOf(parentSubsection);
                            if (this.validIndex(index)) {
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[index].questions[indexOfQuestion] = destinationAsset;
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[index].questions[moveQuestionToIndex] = question;
                            }
                        }
                    }
                    else if (parentAsset.qa_type == 'table') {
                        var parentSubsection = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.find(function (item) { return item.code == parentAsset.code; });
                        if (parentSubsection) {
                            var index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.indexOf(parentSubsection);
                            if (this.validIndex(index)) {
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[index].questions[indexOfQuestion] = destinationAsset;
                                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[index].questions[moveQuestionToIndex] = question;
                            }
                        }
                    }
                }
                this.mta = question;
                this.updateChangedAssets();
                this.mta = destinationAsset;
                this.updateChangedAssets();
                this.initialize(this.currentTabIndex, this.currentSectionIndex);
                this.clickOnQuestionDiv(undefined);
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.changeOptionSequence = function (option, direction) {
        var x = JSON.parse(JSON.stringify(this.mta.options));
        var allChildrenAssets = x;
        var optionBeingModified = allChildrenAssets.find(function (item) { return item.code == option.code; });
        if (optionBeingModified) {
            var indexOfOption = allChildrenAssets.indexOf(optionBeingModified);
            if (this.validIndex(indexOfOption)) {
                var moveOptionToIndex = indexOfOption + direction;
                var destinationAsset = x[moveOptionToIndex];
                var newSequenceOfDestinationAsset = option.qa_sequence;
                option.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfOption) && this.validIndex(moveOptionToIndex)) {
                    x[indexOfOption] = destinationAsset;
                    x[moveOptionToIndex] = option;
                    this.mta.options = JSON.parse(JSON.stringify(x));
                    this.initialize(this.currentTabIndex, this.currentSectionIndex);
                }
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.changeSubsectionSequence = function (subsection, direction) {
        var parentAsset = this.getParentAsset(subsection);
        var allChildrenAssets = parentAsset.subsections;
        var subsectionBeingModified = allChildrenAssets.find(function (item) { return item.code == subsection.code; });
        if (subsectionBeingModified) {
            var indexOfSubsection = allChildrenAssets.indexOf(subsectionBeingModified);
            if (this.validIndex(indexOfSubsection)) {
                var moveSubsectionToIndex = indexOfSubsection + direction;
                var destinationAsset = parentAsset.subsections[moveSubsectionToIndex];
                var newSequenceOfDestinationAsset = subsection.qa_sequence;
                subsection.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfSubsection) && this.validIndex(moveSubsectionToIndex)) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[indexOfSubsection] = destinationAsset;
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[moveSubsectionToIndex] = subsection;
                }
                this.mta = subsection;
                this.updateChangedAssets();
                this.mta = destinationAsset;
                this.updateChangedAssets();
                this.initialize(this.currentTabIndex, this.currentSectionIndex);
                this.clickOnQuestionDiv(undefined);
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.changeTableSequence = function (table, direction) {
        var parentAsset = this.getParentAsset(table);
        var allChildrenAssets = parentAsset.tables;
        var tableBeingModified = allChildrenAssets.find(function (item) { return item.code == table.code; });
        if (tableBeingModified) {
            var indexOfTable = allChildrenAssets.indexOf(tableBeingModified);
            // console.log('indexOfTable', indexOfTable);
            if (this.validIndex(indexOfTable)) {
                var moveTableToIndex = indexOfTable + direction;
                var destinationAsset = parentAsset.tables[moveTableToIndex];
                var newSequenceOfDestinationAsset = table.qa_sequence;
                table.qa_sequence = destinationAsset.qa_sequence;
                destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
                if (this.validIndex(indexOfTable) && this.validIndex(moveTableToIndex)) {
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[indexOfTable] = destinationAsset;
                    this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[moveTableToIndex] = table;
                }
                this.mta = table;
                this.updateChangedAssets();
                this.mta = destinationAsset;
                this.updateChangedAssets();
                this.initialize(this.currentTabIndex, this.currentSectionIndex);
                this.clickOnQuestionDiv(undefined);
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.modifyDisplayLogic = function (asset, template, parentSubsection, parentSection) {
        var _this = this;
        if (asset.survey_q_asset_display_logic && asset.survey_q_asset_display_logic.length > 0) {
            this.displayLogicForAssetExists = asset;
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    this.displayLogicDisplayIfKey = this.questionnaire[i].sections[j].questions.find(function (item) { return item.id == _this.displayLogicForAssetExists.survey_q_asset_display_logic[0].display_if_key; });
                    if (this.displayLogicDisplayIfKey) {
                        break loop1;
                    }
                    if (!this.displayLogicDisplayIfKey && this.questionnaire[i].sections[j].subsections && this.questionnaire[i].sections[j].subsections.length > 0) {
                        for (var k = 0; k < this.questionnaire[i].sections[j].subsections.length; k++) {
                            this.displayLogicDisplayIfKey = this.questionnaire[i].sections[j].subsections[k].questions.find(function (item) { return item.id == _this.displayLogicForAssetExists.survey_q_asset_display_logic[0].display_if_key; });
                            if (this.displayLogicDisplayIfKey) {
                                break loop1;
                            }
                        }
                    }
                    if (!this.displayLogicDisplayIfKey && this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                        for (var k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                            this.displayLogicDisplayIfKey = this.questionnaire[i].sections[j].tables[k].questions.find(function (item) { return item.id == _this.displayLogicForAssetExists.survey_q_asset_display_logic[0].display_if_key; });
                            if (this.displayLogicDisplayIfKey) {
                                break loop1;
                            }
                        }
                    }
                }
            }
            if (asset.survey_q_asset_display_logic[0].logic_type == 'value_matches') {
                this.displayLogicDisplayIfValue = this.displayLogicDisplayIfKey.options.find(function (item) { return item.id == _this.displayLogicForAssetExists.survey_q_asset_display_logic[0].display_if_value; });
            }
            else {
                this.displayLogicDisplayIfValueExists = true;
            }
        }
        else {
            this.modifyDisplayLogicForAsset = asset;
        }
    };
    EditDynamicQuestionnaireComponent.prototype.modifyAutocalculateFormula = function (asset, parentSubsection, parentSection) {
        this.modifyingAutoCalcFormulaInTab = this.currentTabIndex;
        this.modifyingAutoCalcFormulaInSection = this.currentSectionIndex;
        if (asset.survey_q_asset_autocalculate_formulas && asset.survey_q_asset_autocalculate_formulas.length > 0) {
            var formula = asset.survey_q_asset_autocalculate_formulas[0].formula;
            var formulaArray_1 = formula.split(",");
            var formulaQAssetIdArray_1 = [];
            for (var p = 0; p < formulaArray_1.length; p++) {
                // Ignore non-number elements of the formula
                if (isNaN(parseInt(formulaArray_1[p]))) { }
                else {
                    formulaQAssetIdArray_1.push(parseInt(formulaArray_1[p]));
                }
            }
            var formulaQAssetArray = [];
            for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    var x = this.questionnaire[i].sections[j].questions.filter(function (item) { return formulaQAssetIdArray_1.indexOf(item.id) > -1; });
                    formulaQAssetArray = formulaQAssetArray.concat(x);
                    for (var k = 0; k < this.questionnaire[i].sections[j].subsections.length; k++) {
                        var y = this.questionnaire[i].sections[j].subsections[k].questions.filter(function (item) { return formulaQAssetIdArray_1.indexOf(item.id) > -1; });
                        formulaQAssetArray = formulaQAssetArray.concat(y);
                    }
                    for (var k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                        var z = this.questionnaire[i].sections[j].tables[k].questions.filter(function (item) { return formulaQAssetIdArray_1.indexOf(item.id) > -1; });
                        formulaQAssetArray = formulaQAssetArray.concat(z);
                    }
                }
            }
            this.autocalculateFormulaArray = [];
            var _loop_5 = function (i) {
                if (isNaN(parseInt(formulaArray_1[i]))) {
                    this_5.autocalculateFormulaArray.push({ part: formulaArray_1[i], isAsset: false });
                }
                else {
                    var x = formulaQAssetArray.find(function (item) { return item.id == formulaArray_1[i]; });
                    if (x) {
                        this_5.autocalculateFormulaArray.push({ part: x, isAsset: true });
                    }
                    else {
                        // TODO: Handle error
                    }
                }
            };
            var this_5 = this;
            for (var i = 0; i < formulaArray_1.length; i++) {
                _loop_5(i);
            }
            this.autocalculateFormulaForAssetExists = asset;
        }
        else {
            this.modifyAutocalculateFormulaForAsset = asset;
            this.autocalculateFormulaArray = [];
            this.autocalculateFormulaArray.push({ isAsset: true, editing: true });
            this.autocalculateFormulaArray.push({ isAsset: false, part: "*" });
            this.autocalculateFormulaArray.push({ isAsset: true, editing: true });
        }
    };
    EditDynamicQuestionnaireComponent.prototype.validateAutocalculateFormulaArray = function () {
        if (!this.autocalculateFormulaArray || this.autocalculateFormulaArray.length == 0) {
            return false;
        }
        else {
            for (var i = 0; i < this.autocalculateFormulaArray.length; i++) {
                if (!this.autocalculateFormulaArray[i].part) {
                    return false;
                }
            }
            return true;
        }
    };
    EditDynamicQuestionnaireComponent.prototype.saveAutocalculateFormulaForAsset = function () {
        if (!this.validateAutocalculateFormulaArray()) {
            this.toastr.error('Please enter a valid formula');
            return;
        }
        // this.initialize(this.modifyingAutoCalcFormulaInTab, this.modifyingAutoCalcFormulaInSection);
        var newFormulaArray = [];
        for (var i = 0; i < this.autocalculateFormulaArray.length; i++) {
            if (this.autocalculateFormulaArray[i].isAsset) {
                newFormulaArray.push(this.autocalculateFormulaArray[i].part.id);
            }
            else {
                newFormulaArray.push(this.autocalculateFormulaArray[i].part);
            }
        }
        var newFormulaString = newFormulaArray.join();
        if (this.autocalculateFormulaForAssetExists) {
            this.autocalculateFormulaForAssetExists.survey_q_asset_autocalculate_formulas[0].formula = newFormulaString;
            console.log('autocalculateFormulaForAssetExists');
            this.mta = this.autocalculateFormulaForAssetExists;
        }
        else if (this.modifyAutocalculateFormulaForAsset) {
            if (!this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas || this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas.length == 0) {
                this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas = [{}];
            }
            this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas[0]['questionnaire_asset_id'] = this.modifyAutocalculateFormulaForAsset.id;
            this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas[0]['formula'] = newFormulaString;
            this.mta = this.modifyAutocalculateFormulaForAsset;
        }
        console.log('this.mta', this.mta);
        var x = JSON.stringify(this.mta);
        // this.mta.disable_input = true;
        var qaBeingModifiedId = this.mta.id;
        this.updateModifiesQasOfFormulaParts(qaBeingModifiedId);
        this.currentTabIndex = this.modifyingAutoCalcFormulaInTab;
        this.currentSectionIndex = this.modifyingAutoCalcFormulaInSection;
        this.mta = JSON.parse(x);
        this.updateCurrentTab();
        this.updateChangedAssets();
        this.resetAutocalculateFormula();
    };
    EditDynamicQuestionnaireComponent.prototype.updateModifiesQasOfFormulaParts = function (qaBeingModifiedId) {
        for (var i = 0; i < this.autocalculateFormulaArray.length; i++) {
            if (this.autocalculateFormulaArray[i].isAsset) {
                this.mta = this.autocalculateFormulaArray[i].part;
                this.mta.modifies_qas = qaBeingModifiedId;
                this.updateChangedAssets();
                this.updateCurrentTab();
            }
        }
    };
    // setDisplayLogicTab(event): void {
    //   this.displayLogicKeyTab = this.questionnaire.find(item => item.code == this.displayLogicKeyTabCode);
    // }
    // setDisplayLogicSection(event): void {
    //   console.log('display logic section', this.displayLogicKeySection);
    //   this.displayLogicKeySection = this.displayLogicKeyTab['sections'].find(item => item.code == this.displayLogicKeySectionCode);
    //   if (this.displayLogicTypeSelected.type_key == 'value_matches') {
    //     this.displayLogicKeySection['questions'] = this.displayLogicKeySection['questions'].filter(item => item.qa_subtype == 'select' || item.qa_subtype == 'multiselect');
    //   }
    // }
    // setDisplayLogicQuestion(event): void {
    //   console.log('display logic question', this.displayLogicKeyQuestion);
    //   let x = this.displayLogicKeyQuestion;
    //   this.displayLogicKeyQuestion = this.displayLogicKeySection['questions'].find(item => item.code == this.displayLogicKeyQuestionCode);
    // }
    // setDisplayLogicOptionValue(event): void {
    //   console.log('display logic option', this.displayLogicKeyOptionValue);
    //   let x = this.displayLogicKeyOptionValue;
    //   this.displayLogicKeyOptionValue = this.displayLogicKeyQuestion['options'].find(item => item.code ==  this.displayLogicKeyOptionValueCode);
    // }
    EditDynamicQuestionnaireComponent.prototype.saveDisplayLogicChanges = function () {
    };
    EditDynamicQuestionnaireComponent.prototype.canAddOptionToDisplayLogic = function (option) {
        return true;
    };
    EditDynamicQuestionnaireComponent.prototype.setOptionAsDisplayLogic = function (event, option, question) {
        if (!this.modifyDisplayLogicForAsset) {
            return;
        }
        if (!this.modifyDisplayLogicForAsset.id) {
            this.toastr.warning('Please save changes first');
            return;
        }
        if (this.canAddOptionToDisplayLogic(option)) {
            this.displayLogicDisplayIfKey = question;
            this.displayLogicDisplayIfValue = option;
            this.addDisplayLogic(question.id, option.id);
        }
    };
    EditDynamicQuestionnaireComponent.prototype.addDisplayLogic = function (ifKey, ifValue) {
        var displayLogicToAdd = {
            questionnaire_asset_id: this.modifyDisplayLogicForAsset.id,
            display_if_key: ifKey,
            display_if_value: ifValue,
            logic_type: ifValue ? 'value_matches' : 'value_exists'
        };
        if (!this.modifyDisplayLogicForAsset.survey_q_asset_display_logic || this.modifyDisplayLogicForAsset.survey_q_asset_display_logic.length == 0) {
            this.modifyDisplayLogicForAsset.survey_q_asset_display_logic = [];
        }
        this.modifyDisplayLogicForAsset.survey_q_asset_display_logic = [displayLogicToAdd];
    };
    EditDynamicQuestionnaireComponent.prototype.removeDisplayLogic = function () {
        if (this.modifyDisplayLogicForAsset) {
            this.modifyDisplayLogicForAsset.survey_q_asset_display_logic = [];
            this.mta = this.modifyDisplayLogicForAsset;
        }
        else if (this.displayLogicForAssetExists) {
            this.displayLogicForAssetExists.survey_q_asset_display_logic = [];
            this.mta = this.displayLogicForAssetExists;
        }
        this.updateChangedAssets();
        this.updateCurrentTab();
        this.resetDisplayLogic();
    };
    EditDynamicQuestionnaireComponent.prototype.saveDisplayLogic = function () {
        this.mta = this.modifyDisplayLogicForAsset;
        this.updateChangedAssets();
        this.updateCurrentTab();
        this.resetDisplayLogic();
    };
    EditDynamicQuestionnaireComponent.prototype.cancelDisplayLogicChanges = function () {
        this.resetDisplayLogic();
    };
    EditDynamicQuestionnaireComponent.prototype.resetDisplayLogic = function () {
        this.modifyDisplayLogicForAsset = undefined;
        this.displayLogicForAssetExists = undefined;
        this.displayLogicDisplayIfKey = undefined;
        this.displayLogicDisplayIfValue = undefined;
        this.displayLogicDisplayIfValueExists = undefined;
    };
    EditDynamicQuestionnaireComponent.prototype.addUOM = function () {
        if (!this.mta.id || this.mta.id == 0) {
            this.toastr.warning('Please save changes first');
            return;
        }
        if (!this.mta.uom_questions) {
            this.mta.uom_questions = [];
        }
        var uomToAdd = {
            code: uuidv4(),
            qa_type: 'uom_question',
            qa_subtype: 0,
            survey_id: this.surveyId,
            parent_id: this.mta.id,
            parent_code: this.mta.code,
            survey_q_asset_labels: [{ language_id: this.getLanguageId(), label: 'Unit' }],
            is_table_question: this.mta.is_table_question
        };
        this.mta.include_uom_question = true;
        this.mta.uom_questions.push(uomToAdd);
    };
    EditDynamicQuestionnaireComponent.prototype.removeUOM = function () {
        this.mta.include_uom_question = false;
        this.mta.uom_questions = [];
    };
    EditDynamicQuestionnaireComponent.prototype.changeParent = function (oldParentCode) {
        var _this = this;
        if (this.mta.qa_type == 'section') {
            var tabMovedFrom = this.questionnaire.find(function (item) { return item.code == oldParentCode; });
            if (tabMovedFrom) {
                var index = this.questionnaire.indexOf(tabMovedFrom);
                if (this.validIndex(index)) {
                    this.questionnaire[index].sections = this.questionnaire[index].sections.filter(function (item) { return item.code != _this.mta.code; });
                }
            }
            var tabMovedTo = this.questionnaire.find(function (item) { return item.code == _this.mta.parent_code; });
            if (tabMovedTo) {
                var index = this.questionnaire.indexOf(tabMovedTo);
                if (this.validIndex(index)) {
                    this.tabClicked(index, 0);
                    this.mta.parent_id = tabMovedTo.id;
                    this.mta.qa_sequence = this.getSequence('section');
                }
            }
        }
        else {
            // Remove the child asset from the old parent section
            var sectionMovedFrom = this.currentTab.sections.find(function (item) { return item.code == oldParentCode; });
            if (sectionMovedFrom) {
                var index = this.currentTab.sections.indexOf(sectionMovedFrom);
                if (this.validIndex(index)) {
                    if (this.mta.qa_type == 'question') {
                        this.currentTab.sections[index].questions = this.currentTab.sections[index].questions.filter(function (item) { return item.code != _this.mta.code; });
                    }
                    if (this.mta.qa_type == 'subsection') {
                        this.currentTab.sections[index].subsections = this.currentTab.sections[index].subsections.filter(function (item) { return item.code != _this.mta.code; });
                    }
                    if (this.mta.qa_type == 'table') {
                        this.currentTab.sections[index].tables = this.currentTab.sections[index].tables.filter(function (item) { return item.code != _this.mta.code; });
                    }
                }
            }
            // Find the index of the new parent section, set it as the current Section. Update the mta
            var sectionMovedTo = this.currentTab.sections.find(function (item) { return item.code == _this.mta.parent_code; });
            if (sectionMovedTo) {
                var index = this.currentTab.sections.indexOf(sectionMovedTo);
                if (this.validIndex(index)) {
                    this.sectionClicked(index);
                    this.mta.parent_id = sectionMovedTo.id;
                    this.mta.qa_sequence = this.getSequence('question');
                }
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.setMtaHasOtherOption = function () {
        if (this.mta.qa_type == 'question') {
            if (this.mta.options && this.mta.options.length > 0) {
                var x = this.mta.options.find(function (item) { return item.qa_subtype == 'other'; });
                // console.log('x', x);
                this.mta.mtaHasOtherOption = x ? true : false;
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.addIsOtherOptionQuestionAsset = function () {
        if (!this.mta.id || this.mta.id == 0) {
            this.toastr.warning('Please save changes first');
            return;
        }
        if (!this.mta.other_questions) {
            this.mta.other_questions = [];
        }
        var otherQuestionToAdd = {
            code: uuidv4(),
            qa_type: 'other_question',
            survey_id: this.surveyId,
            parent_id: this.mta.id,
            parent_code: this.mta.code,
            survey_q_asset_labels: [{ language_id: this.getLanguageId(), label: 'Other' }],
            is_table_question: this.mta.is_table_question
        };
        this.mta.other_questions.push(otherQuestionToAdd);
    };
    EditDynamicQuestionnaireComponent.prototype.discardQuestionnaireChanges = function () {
        this.changedAssets = [];
        this.mta = undefined;
        this.updateQuestionnaireAfterSaving();
    };
    // TODO: Done: Implement cancellation of autocalculate formula changes
    EditDynamicQuestionnaireComponent.prototype.cancelAutocalculateFormulaChanges = function () {
        this.resetAutocalculateFormula();
    };
    // TODO: Done: Implement removal of autocalculate formulat for given asset
    EditDynamicQuestionnaireComponent.prototype.removeAutocalculateFormula = function () {
        if (this.modifyAutocalculateFormulaForAsset) {
            this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas = [];
            // TODO: Not disabling input automatically so not relevant: Handle scenariow here input may be disabled for another reason (not auto calcualte)
            // this.modifyAutocalculateFormulaForAsset.disable_input = false;
            this.mta = this.modifyAutocalculateFormulaForAsset;
        }
        else if (this.autocalculateFormulaForAssetExists) {
            this.autocalculateFormulaForAssetExists.survey_q_asset_autocalculate_formulas = [];
            this.mta = this.autocalculateFormulaForAssetExists;
        }
        this.updateChangedAssets();
        this.updateCurrentTab();
        this.updateModifiesQasOfFormulaParts(null);
        this.resetAutocalculateFormula();
    };
    EditDynamicQuestionnaireComponent.prototype.resetAutocalculateFormula = function () {
        this.modifyAutocalculateFormulaForAsset = undefined;
        this.autocalculateFormulaForAssetExists = undefined;
        this.modifyingAutoCalcFormulaInTab = undefined;
        this.modifyingAutoCalcFormulaInSection = undefined;
        this.autocalculateFormulaArray = [];
    };
    EditDynamicQuestionnaireComponent.prototype.modifyThisFormulaPart = function (part, index) {
    };
    EditDynamicQuestionnaireComponent.prototype.removeThisFormulaPart = function (part, index) {
        // At this index in autocalculateFormulaArray set the part with attribute editing: true
        this.autocalculateFormulaArray[index] = { editing: true, isAsset: true };
        this.selectAutocalculatePartAtThisIndex(index);
    };
    EditDynamicQuestionnaireComponent.prototype.setQuestionInModifyingAutocalcualteFormula = function (question) {
        var x = this.autocalculateFormulaArray.find(function (item) { return item.selectedForSetting; });
        if (x) {
            var indexToSetQuestionAt = this.autocalculateFormulaArray.indexOf(x);
            if (this.validIndex(indexToSetQuestionAt)) {
                this.autocalculateFormulaArray[indexToSetQuestionAt] = { part: question, isAsset: true };
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.selectAutocalculatePartAtThisIndex = function (index) {
        this.autocalculateFormulaArray[index].selectedForSetting = true;
    };
    EditDynamicQuestionnaireComponent.prototype.appendNewOperator = function () {
        this.autocalculateFormulaArray.push({ isAsset: false, part: "*" });
        this.autocalculateFormulaArray.push({ isAsset: true, editing: true });
    };
    EditDynamicQuestionnaireComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    EditDynamicQuestionnaireComponent.prototype.collectDisplayLogicKeyValues = function () {
        this.displayLogicKeys = [];
        this.displayLogicValues = [];
        if (this.questionnaire && this.questionnaire.length > 0) {
            for (var i = 0; i < this.questionnaire.length; i++) {
                if (this.questionnaire[i].sections && this.questionnaire[i].sections.length > 0) {
                    for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                        for (var k = 0; k < this.questionnaire[i].sections[j].questions.length; k++) {
                            if (this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic.length > 0) {
                                for (var m = 0; m < this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic.length; m++) {
                                    this.displayLogicKeys.push(this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_key.toString());
                                    if (this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_value !== null) {
                                        this.displayLogicValues.push(this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_value.toString());
                                    }
                                }
                            }
                        }
                        for (var p = 0; p < this.questionnaire[i].sections[j].subsections.length; p++) {
                            for (var k = 0; k < this.questionnaire[i].sections[j].subsections[p].questions.length; k++) {
                                if (this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic.length > 0) {
                                    for (var m = 0; m < this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic.length; m++) {
                                        this.displayLogicKeys.push(this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_key.toString());
                                        if (this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_value !== null) {
                                            this.displayLogicValues.push(this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_value.toString());
                                        }
                                    }
                                }
                            }
                        }
                        for (var p = 0; p < this.questionnaire[i].sections[j].tables.length; p++) {
                            for (var k = 0; k < this.questionnaire[i].sections[j].tables[p].questions.length; k++) {
                                if (this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic.length > 0) {
                                    for (var m = 0; m < this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic.length; m++) {
                                        this.displayLogicKeys.push(this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_key.toString());
                                        if (this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_value !== null) {
                                            this.displayLogicValues.push(this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_value.toString());
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };
    EditDynamicQuestionnaireComponent.prototype.markChildrenDeleted = function (asset) {
        var assetTypes = [];
        this.assetTypes.forEach(function (item) {
            assetTypes.push(item.type + 's');
        });
        for (var j = 0; j < assetTypes.length; j++) {
            console.log('assetTypes j', assetTypes[j]);
            if (asset[assetTypes[j]]) {
                for (var k = 0; k < asset[assetTypes[j]].length; k++) {
                    this.mta = asset[assetTypes[j]][k];
                    this.changeQuestion('remove', true);
                    if (asset[assetTypes[j]][k][assetTypes[j]]) {
                        for (var p = 0; p < asset[assetTypes[j]][k][assetTypes[j]].length; p++) {
                            this.mta = asset[assetTypes[j]][k][assetTypes[j]][p];
                            this.changeQuestion('remove', true);
                            if (asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]]) {
                                for (var q = 0; q < asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]].length; q++) {
                                    this.mta = asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q];
                                    this.changeQuestion('remove', true);
                                    if (asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]]) {
                                        for (var r = 0; r < asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]].length; r++) {
                                            this.mta = asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]][r];
                                            this.changeQuestion('remove', true);
                                            if (asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]][r][assetTypes[j]]) {
                                                for (var s = 0; s < asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]][r][assetTypes[j]].length; s++) {
                                                    this.mta = asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]][r][assetTypes[j]][s];
                                                    this.changeQuestion('remove', true);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else {
                console.log('2046');
            }
        }
        console.log('questionnaire', this.questionnaire);
    };
    EditDynamicQuestionnaireComponent.prototype.markChildrenDeleted2 = function (asset) {
        for (var i = 0; i < this.assetTypes.length; i++) {
            var key = this.assetTypes[i].type + 's';
            if (asset[key]) {
                for (var j = 0; j < asset[key].length; j++) {
                    this.mta = asset[key][j];
                    this.changeQuestion('remove', true);
                    this.markChildrenDeleted2(asset[key][j]);
                }
            }
        }
        this.mta = undefined;
    };
    EditDynamicQuestionnaireComponent.prototype.initializeBlankQuestionnaire = function () {
        // setTimeout(() => {
        var assetToAdd = {
            survey_id: this.surveyId,
            code: uuidv4(),
            qa_type: 'tab',
            parent_id: null,
            parent_code: null,
            qa_sequence: 1,
            qa_subtype: null,
            survey_q_asset_labels: [{ language_id: this.getLanguageId(), label: 'New tab' }],
            survey_q_asset_validations: [],
            survey_q_asset_display_logic: [],
            survey_q_asset_autocalculate_formulas: [],
            is_table_question: false
        };
        this.questionnaire = [];
        this.questionnaire.push(assetToAdd);
        this.disableCancelOfModifyingAssetModal = true;
        this.isBlankQuestionnaire = true;
        this.modifyThisAsset(this.questionnaire[0], this.modifyAssetModalTemp, 'tab');
        // }, 500);
    };
    EditDynamicQuestionnaireComponent.prototype.printSurvey = function () {
        var surveyQuestionnaire = document.getElementById('surveyQuestionnaire');
        surveyQuestionnaire.classList.remove('d-none');
        surveyQuestionnaire.classList.add('d-block');
        document.documentElement.style.overflowY = 'hidden';
    };
    EditDynamicQuestionnaireComponent.prototype.closePrintSurvey = function () {
        var surveyQuestionnaire = document.getElementById('surveyQuestionnaire');
        surveyQuestionnaire.classList.remove('d-block');
        surveyQuestionnaire.classList.add('d-none');
        document.documentElement.style.overflowY = 'scroll';
    };
    return EditDynamicQuestionnaireComponent;
}());
export { EditDynamicQuestionnaireComponent };
// changeOptionSequence(option, direction): void {
//   let parentAsset = this.getParentAsset(option); // Question
//   console.log('parentAsset', parentAsset);
//   let grandParentAsset = this.getParentAsset(parentAsset); // Section, subsection, or table
//   let allChildrenAssets = parentAsset.options;
//   console.log('allChildrenAssets', allChildrenAssets);
//   let optionBeingModified = allChildrenAssets.find(item => item.code == option.code);
//   if (optionBeingModified) {
//   }
//   let indexOfOption = allChildrenAssets.indexOf(optionBeingModified);
//   console.log('indexOfOption', indexOfOption);
//   let moveOptionToIndex = indexOfOption + direction;
//   console.log('moveOptionToIndex', moveOptionToIndex);
//   let destinationAsset = parentAsset.options[moveOptionToIndex];
//   let newSequenceOfDestinationAsset = option.qa_sequence;
//   option.qa_sequence = destinationAsset.qa_sequence;
//   destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
//   if (this.validIndex(indexOfOption) && this.validIndex(moveOptionToIndex)) {
//     let q; let qIndex; // index of parentAsset question
//     let s; let sIndex; // index of grandParentAsset subsection
//     let t; let tIndex; // index of grandParentAsset table
//     let qs; let qsIndex; // index of grandParentAsset table
//     let qt; let qtIndex; // index of grandParentAsset table
//     // q = this.currentSection.questions.find(item => item.code == parentAsset.code);
//     // if (q) {
//     //   qIndex = this.currentSection.questions.indexOf(q);
//     // } else {
//     //   s = this.currentSection.subsections.find(item => item.code == grandParentAsset.code);
//     //   if (s) {
//     //     sIndex = this.currentSection.subsections.indexOf(s);
//     //     qs = this.currentSection.subsections[sIndex].questions.find(item => item.code == parentAsset.code);
//     //     if (qs) {
//     //       qsIndex = this.currentSection.subsections[sIndex].questions.indexOf(qs);
//     //     }
//     //   } else {
//     //     t = this.currentSection.tables.find(item => item.code == grandParentAsset.code);
//     //     if (t) {
//     //       tIndex = this.currentSection.tables.indexOf(t);
//     //       qt = this.currentSection.tables[tIndex].questions.find(item => item.code == parentAsset.code);
//     //       if (qt) {
//     //         qtIndex = this.currentSection.tables[tIndex].questions.indexOf(qt);
//     //       }
//     //     }
//     //   }
//     // }
//     // if (qt) {
//     //   let temp = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tIndex].questions[qtIndex].options[indexOfOption];
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tIndex].questions[qtIndex].options[indexOfOption] = destinationAsset;
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tIndex].questions[qtIndex].options[moveOptionToIndex] = option;
//     //   // this.mta = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tIndex].questions[qtIndex];
//     // } else if (qs) {
//     //   let temp = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[sIndex].questions[qsIndex].options[indexOfOption];
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[sIndex].questions[qsIndex].options[indexOfOption] = destinationAsset;
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[sIndex].questions[qsIndex].options[moveOptionToIndex] = option;
//     //   // this.mta = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tIndex].questions[qsIndex];
//     // } else {
//     //   let temp = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[qIndex].options[indexOfOption];
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[qIndex].options[indexOfOption] = destinationAsset;
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[qIndex].options[moveOptionToIndex] = option;
//     //   // this.mta = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[qIndex];
//     // }
//     this.mta.options[indexOfOption] = destinationAsset;
//     this.mta.options[moveOptionToIndex] = option;
//     this.initialize(this.currentTabIndex, this.currentSectionIndex);
//   }
// }
