import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { CommonService } from '../../services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/login.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styles: [
    `.alert_pd{
      padding-bottom: 0.6em;
      padding-top: 0.3em;
    }
    .dateIcon{
      position:relative;
    }
    .dateIcon i{
      position:absolute;
      right:0;
      bottom:0;
      font-size:1.9rem;
    }`
  ]
})
export class ProductComponent implements OnInit {
  public dateFormat:any;
  public isOpen = false;
  public productForm:FormGroup;
  addUserModal: BsModalRef;
  importUserModal: BsModalRef;
  updateUserModal: BsModalRef;
  removeUsersConfirmModal: BsModalRef;
  public f1Submitted = false;
  public productsReqData:any;
  public typeOfProductData:any;
  public listOfProducts:any;
  constructor(
    private readonly commonService: CommonService,
    private readonly  router: Router,
    private readonly _route: ActivatedRoute,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly toastrService: ToastrService,
    private readonly loginService: LoginService,
    private readonly fb: FormBuilder,
    private readonly modalService: BsModalService,
    private readonly translate: TranslateService
  ) { }

  ngOnInit() {
    this.productForm = this.fb.group({  
    });
    this.supplierFieldsData();
  }

  public inputValidator(event: any) {
    
    const pattern = RegExp(/^[a-zA-Z0-9 \r\n]+$/);   
   
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9 (,)/=<>_*"&\n!?.-]/g, "");
      
    }
  }

  supplierFieldsData(){
    this.productsReqData = {
      "status": "success",
      "mdm_form_code":"Pr_Form",
      "data": [{
          "field_id":1,
          "field_name": "Product/Crop Name",
          "field_type": "text",
          "mandatory": "Y",
          "value_options":[],
          "api_url":null,
          "sequence":"",
          "mapping_table":"tablename",
          "mapping_table_field":"column name",
          "allow_new":"N",
          "allow_new_code":null,
          "toggle_value":[],			
          "validations": [{
            "type": "minlength",
            "value": 3
          }, {
            "type": "maxlength",
            "value": 10
          }]
        },
        {
          "field_id":2,
          "field_name": "Type of Product ",
          "field_type": "dropdown",
          "mandatory": "Y",
          "value_options":[],
          "api_url":null,
          "sequence":"",
          "mapping_table":"tablename",
          "mapping_table_field":"column name",
          "allow_new":"N",
          "allow_new_code":null,
          "toggle_value":[],
          "validations": []
        },
        {
          "field_id":3,
          "field_name": "Status",
          "field_type": "dropdown",
          "mandatory": "Y",
          "value_options":[
            {"id":1,"name":"Active"},
            {"id":2,"name":"InActive"}
          ],
          "api_url":null,
          "sequence":"",
          "mapping_table":"tablename",
          "mapping_table_field":"column name",
          "allow_new":"",
          "allow_new_code":null,
          "toggle_value":[],
          "validations": []
        },
        {
          "field_id":4,
          "field_name": "Product Category",
          "field_type": "text",
          "mandatory": "N",
          "value_options":[],
          "api_url":null,
          "sequence":"",
          "mapping_table":"tablename",
          "mapping_table_field":"column name",
          "allow_new":"",
          "allow_new_code":null,
          "toggle_value":[],
          "validations": [
            {
              "type": "pattern",
              "value": 'DD-MM-YYYY'
            }
          ]
        }
      ]
    };

    this.listOfProducts ={
      "status": "success",
      "data": [
      {
        "productname":"Flowers",
        "typeofproduct":"xyz",
        "status":"InActive",
        "productcategory":"abc"
      },
      {
        "productname":"FreshOne",
        "typeofproduct":"Biomass",
        "status":"Active",
        "productcategory":"xyz"
      },
      {
        "productname":"Vanilla",
        "typeofproduct":"TestOne",
        "status":"InActive",
        "productcategory":"abc"
      },
      {
        "productname":"Jasmine",
        "typeofproduct":"Biomass",
        "status":"Active",
        "productcategory":"xyz"
      },
      {
        "productname":"FreshTwo",
        "typeofproduct":"TestTwo",
        "status":"Active",
        "productcategory":"xyz"
      }
      
    ]
    };

    for(let i=0; i< this.productsReqData.data.length;i++){

      if(this.productsReqData.data[i].field_type === 'dropdown'){
        if(this.productsReqData.data[i].value_options.length === 0){
          this.typeOfProductData ={
            "status": "success",
            "data": [
              {"id":1,"name":"ProductOne"},
              {"id":2,"name":"ProductTwo"},
              {"id":3,"name":"ProductThree"}
            ]
          };
          this.productForm.addControl(this.productsReqData.data[i].field_id, new FormControl(null, 
            this.productsReqData.data[i].mandatory === 'Y' ? Validators.required : Validators.nullValidator));
            if(this.productsReqData.data[i].validations.length === 0){

            }else{
              for(let j = 0; j<this.productsReqData.data[i].validations.length; j++){
                this.productForm.controls[this.productsReqData.data[i].field_id].setValidators([
                  this.productsReqData.data[i].validations[j].type === 'minlength' ? Validators.minLength(this.productsReqData.data[i].validations[j].value) : Validators.nullValidator,
                  this.productsReqData.data[i].validations[j].type === 'maxlength' ? Validators.maxLength(this.productsReqData.data[i].validations[j].value) : Validators.nullValidator
                ])
              }
            }
        }else{
          this.productForm.addControl(this.productsReqData.data[i].field_id, new FormControl(this.productsReqData.data[i].value_options[0].id, 
            this.productsReqData.data[i].mandatory === 'Y' ? Validators.required : Validators.nullValidator));
            if(this.productsReqData.data[i].validations.length === 0){

            }else{
              for(let j = 0; j<this.productsReqData.data[i].validations.length; j++){
                this.productForm.controls[this.productsReqData.data[i].field_id].setValidators([
                  this.productsReqData.data[i].validations[j].type === 'minlength' ? Validators.minLength(this.productsReqData.data[i].validations[j].value) : Validators.nullValidator,
                  this.productsReqData.data[i].validations[j].type === 'maxlength' ? Validators.maxLength(this.productsReqData.data[i].validations[j].value) : Validators.nullValidator
                ])
              }
            }
        }
        
      }else if(this.productsReqData.data[i].field_type === 'date'){
        this.productForm.addControl(this.productsReqData.data[i].field_id, new FormControl(null, 
          this.productsReqData.data[i].mandatory === 'Y' ? Validators.required : Validators.nullValidator
           ));        
        if(this.productsReqData.data[i].validations.length === 0){

        }else{
          for(let j = 0; j<this.productsReqData.data[i].validations.length; j++){
            this.dateFormat = this.productsReqData.data[i].validations[j].value;
            
          }
        }
      }else{
          
          if(this.productsReqData.data[i].validations.length === 0){
            
          }else{
            for(let j = 0; j<this.productsReqData.data[i].validations.length; j++){
              
              this.productForm.addControl(this.productsReqData.data[i].field_id, new FormControl(null,[
                this.productsReqData.data[i].mandatory === 'Y' ? Validators.required : Validators.nullValidator,
                this.productsReqData.data[i].validations[j].type === 'minlength' ? Validators.minLength(this.productsReqData.data[i].validations[j].value) : Validators.nullValidator,
                this.productsReqData.data[i].validations[j].type === 'maxlength' ? Validators.maxLength(5) : Validators.nullValidator
              ]))
              this.productForm.controls[this.productsReqData.data[i].field_id].updateValueAndValidity();
              
            }
          }
      }
      
    }


  }


  openModal(template: TemplateRef<any>, importUserModalBool: boolean) {
    
    if (importUserModalBool) {
      this.importUserModal = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true});
    }
    else {
      this.addUserModal = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true});
    }
  }

  submitForm(form:any, event:any){
    this.f1Submitted = true;    
     


  }

  hideAddUserModal() : void {
    if (this.addUserModal) {
      this.addUserModal.hide();
    }
    this.productForm.reset();
    this.f1Submitted = false;
  }

}
