import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { CommonService } from '../../services/common.service';
import { ProjectService } from '../../services/project.service';
import { UserService } from '../../services/user.service';
import { CampaignService } from '../../services/campaign.service';
import { TranslateService } from '@ngx-translate/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FarmerService } from '../../services/farmer.service';

@Component({
  selector: 'app-campaign-profiling',
  templateUrl: './campaign-profiling.component.html',
  styleUrls: ['./campaign-profiling.component.css']
})
export class CampaignProfilingComponent implements OnInit {

  projectId;
  campaigns = [];
  currentUser = this.userService.getCurrentUser();
  fromNavId;
  public typeOfAdministrationArr: any[]; 
  typeOfAdministration = '';
  previousPaginationLabel;
  nextPaginationLabel;
  searchText;
  loadedCampaigns = false;
  closeCampaignModal: BsModalRef;
  closingCampaign = false;
  closeThisCampaign;
  campaignClosingRemarks;
  closeCampaignSubmitted;
  farmers = [];
  selectedFarmers = [];
  selectedFarmersCopy = [];
  loadingFarmers = false;
  manageFarmersModal: BsModalRef;
  tooltipText;
  selectDeselectLabels;
  managingFarmersForCampaign;
  campaignStatuses;

  isAscending = true;
  isAscendingId = true;
  isAscendingName = true;
  isAscendingTel = true;
  isAscendingDate = true;
  sortLabel = '';

  page = 1;
  itemsPerPage = 10;
  farmersListPage = 1;
  project;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly commonService: CommonService,
    private readonly campaignService: CampaignService,
    private readonly userService: UserService,
    private readonly projectService: ProjectService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly farmerService: FarmerService,
    private readonly modalService: BsModalService
  ) {
    this.route.queryParamMap.subscribe(queryParams => {
      const qpObject = { ...queryParams.keys, ...queryParams };

      this.fromNavId = qpObject['params']['from'];

      this.translate.get('typeAdminTextArr').subscribe((val) => {
        this.typeOfAdministrationArr = val;
        if (this.fromNavId === 2) {
          this.typeOfAdministration = this.typeOfAdministrationArr[0];
        }
        else if (this.fromNavId === 3) {
          this.typeOfAdministration = this.typeOfAdministrationArr[1];
        }
      }, err => console.log('err', err));
    });

    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });

    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });

    this.translate.get('select_deselect_object').subscribe(result => {
      this.selectDeselectLabels = result;
    });
  }

  ngOnInit() {
    this.projectId = +this.route.snapshot.params['id'];
    this.getCampaigns();
  }

  async getCampaigns() {
    const request = await Promise.all([
      this.campaignService.getCampaignsForProject(this.projectId).toPromise(),
      this.projectService.getProjectBasicInfo(this.projectId).toPromise()
    ]);

    if (request[0]['code'] === 'success' && request[0]['message']) {
      this.campaigns = Array.isArray(request[0]['message']) ? request[0]['message'] : [request[0]['message']];
    }
    if (navigator.onLine) {
      console.log('online')
    } else {
      this.campaigns = this.campaigns.filter(item => item.closed_date == null);
    }

    if (request[1]['msg'] === 'success') {
      this.project = request[1]['data'][0];
    }

    this.loadedCampaigns = true;
  }

  getGridFilters() {
    const gridColums = ['name','description'];
    const finalObj = {};
    if (this.campaigns === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  goToCampaignProfiling(campaignId): void {
    this.router.navigate([`/campaign-profiling-farmers/${this.projectId}/${campaignId}`]);
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

}
