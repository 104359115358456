
import { Observable, forkJoin, of } from 'rxjs';


import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';





import { ProjectService } from '../services/project.service';
import { FarmerService } from '../services/farmer.service';
import { CommonService } from '../services/common.service';


interface IReturn {
  project: any[];
  farmers: any[];
  productType: any[];
  managers: any[];
  // projects: any[];
  // farmers: any[];
}

@Injectable()
export class RegisterResolver implements Resolve<IReturn> {

  constructor(private readonly projectService: ProjectService, private readonly farmerService: FarmerService,
    private readonly commonService: CommonService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    let services: Observable<any>[];
    if (navigator.onLine) {
     services = [];
    } else {
      services = [of([]), of([])];
    }

      return forkJoin([
        // this.projectService.GetProject(route.params['id']),
        // this.farmerService.GetFarmersByProjectId(route.params['id'])
        // this.projectService.GetProjectsDataDashboard()
        // this.farmerService.GetAllFarmers(),

        // ...services,
        // this.commonService.getProductType().pipe(map((results) => results), catchError(e => of([]))),
        // this.commonService.getProjectManager().pipe(map((results) => results), catchError(e => of([]))),
        this.farmerService.GetFarmerMaster().pipe(map((results) => results), catchError(e => of([]))),

      ]).pipe(
        map(results => ({
          master: results[0]
        }))
      );

  }

}
