import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { SurveyService } from '../../services/survey.service';
import { CompareDataService } from '../../services/compare-data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { TranslateService } from '@ngx-translate/core';
var ViewQuestionnaireComponent = /** @class */ (function () {
    function ViewQuestionnaireComponent(router, route, projectService, surveyService, compareDataService, spinner, translate, locale) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.projectService = projectService;
        this.surveyService = surveyService;
        this.compareDataService = compareDataService;
        this.spinner = spinner;
        this.translate = translate;
        this.locale = locale;
        this.projectId = 0;
        this.questionnaire = [];
        this.labelVariable = 'survey_q_asset_labels';
        this.currentLocaleLanguageIdIndex = 0;
        this.userLanguages = [];
        this.selectedSnapshot = 0;
        this.isCompare = false;
        this.showUpArrow = false;
        this.notReleasedSurveyInLanguage = false;
        this.blankQ = false;
        this.fromCampaign = false;
        this.questionnaireText = "Questionnaire";
        if (this.locale && this.locale.length > 2) {
            this.locale = this.locale.substr(0, 2);
        }
        this.route.paramMap.subscribe(function (params) {
            _this.route.queryParamMap.subscribe(function (queryParams) {
                _this.translate.get('questionnaire').subscribe(function (result) {
                    _this.questionnaireText = result;
                });
            });
        });
    }
    ViewQuestionnaireComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, _i, _a, item;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.projectId = this.route.snapshot.params['id'];
                        this.surveyId = this.route.snapshot.params['surveyid'];
                        //Added to show questionnaire of target and source snapshot in compare functionality
                        this.route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (queryParams.get('fromCompare') && queryParams.get('fromCompare') === '1') {
                                    this.isCompare = true;
                                }
                                if (queryParams.get('campaignId')) {
                                    this.fromCampaign = true;
                                    this.campaignId = queryParams.get('campaignId');
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
                            _this.project = response.data;
                            var projectObject = _this.project.filter(function (item) { return String(item.id) === String(_this.projectId); })[0];
                            _this.project = projectObject;
                        });
                        return [4 /*yield*/, Promise.all([
                                this.surveyService.getSurveyLanguages().toPromise(),
                                this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                            ])];
                    case 1:
                        requests = _b.sent();
                        if (requests[0]['message']) {
                            this.userLanguages = requests[0]['message'];
                        }
                        if (requests[1]['message']) {
                            this.snapshotData = requests[1].message;
                        }
                        if (this.fromCampaign) {
                            this.snapshotData = this.snapshotData.filter(function (item) {
                                return item.survey_type === 'campaign' && item.survey_stage_changelogs[0].change_type !== 'draft' && item.flex1 == _this.campaignId;
                            });
                        }
                        else {
                            this.snapshotData = this.snapshotData.filter(function (item) { return item.survey_type === 'snapshot' && item.survey_stage_changelogs[0].change_type !== 'draft'; });
                        }
                        if (this.snapshotData && this.snapshotData.length > 0) {
                            for (_i = 0, _a = this.snapshotData; _i < _a.length; _i++) {
                                item = _a[_i];
                                if (item.revisions.length > 1) {
                                    item.revisions.reverse();
                                }
                            }
                        }
                        this.selectedSnapshot = this.surveyId;
                        this.getSnapshotData(this.surveyId);
                        return [2 /*return*/];
                }
            });
        });
    };
    // Get the languageId of the current locale
    // getLanguageId() {
    //   if (this.userLanguages && this.userLanguages.length > 0) {
    //     const p = this.userLanguages.find(item => item.locale == this.locale);
    //     if (p) {
    //       return p['id'];
    //     } else {
    //       return undefined;
    //     }
    //   } else {
    //     return undefined;
    //   }
    // }
    ViewQuestionnaireComponent.prototype.getSnapshotData = function (snapshotId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, _i, _a, tab, _b, _c, section, totalQuestions, _d, _e, q, optionTotalLength, _f, _g, option, _h, _j, subsection, _k, _l, q, optionTotalLength, _m, _o, option, _p, _q, table, _r, _s, q, optionTotalLength, _t, _u, option, totalQuestionsHeight;
            return tslib_1.__generator(this, function (_v) {
                switch (_v.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([
                                this.surveyService.getSurveyQForProfilingService(snapshotId).toPromise()
                            ])];
                    case 1:
                        requests = _v.sent();
                        if (requests[0]['message'] !== 'notReleasedForLanguage') {
                            this.questionnaire = requests[0]['message'];
                            for (_i = 0, _a = this.questionnaire; _i < _a.length; _i++) {
                                tab = _a[_i];
                                for (_b = 0, _c = tab.sections; _b < _c.length; _b++) {
                                    section = _c[_b];
                                    totalQuestions = 0;
                                    if (section.questions.length > 0) {
                                        totalQuestions = section.questions.length;
                                        for (_d = 0, _e = section.questions; _d < _e.length; _d++) {
                                            q = _e[_d];
                                            optionTotalLength = 0;
                                            if (q.options && q.options.length > 0) {
                                                for (_f = 0, _g = q.options; _f < _g.length; _f++) {
                                                    option = _g[_f];
                                                    optionTotalLength = optionTotalLength + option.survey_q_asset_labels[0].label.length;
                                                    if (optionTotalLength > 150) {
                                                        totalQuestions = totalQuestions + 1;
                                                        optionTotalLength = 0;
                                                    }
                                                }
                                            }
                                            //console.log('optionTotalLength', optionTotalLength)
                                        }
                                    }
                                    for (_h = 0, _j = section.subsections; _h < _j.length; _h++) {
                                        subsection = _j[_h];
                                        if (subsection.questions.length > 0) {
                                            totalQuestions = totalQuestions + (subsection.questions.length + 1);
                                            for (_k = 0, _l = subsection.questions; _k < _l.length; _k++) {
                                                q = _l[_k];
                                                optionTotalLength = 0;
                                                if (q.options && q.options.length > 0) {
                                                    for (_m = 0, _o = q.options; _m < _o.length; _m++) {
                                                        option = _o[_m];
                                                        optionTotalLength = optionTotalLength + option.survey_q_asset_labels[0].label.length;
                                                        if (optionTotalLength > 150) {
                                                            totalQuestions = totalQuestions + 1;
                                                            optionTotalLength = 0;
                                                        }
                                                    }
                                                }
                                                //console.log('optionTotalLength', optionTotalLength)
                                            }
                                        }
                                    }
                                    for (_p = 0, _q = section.tables; _p < _q.length; _p++) {
                                        table = _q[_p];
                                        if (table.questions.length > 0) {
                                            totalQuestions = totalQuestions + (table.questions.length + 1);
                                            for (_r = 0, _s = table.questions; _r < _s.length; _r++) {
                                                q = _s[_r];
                                                optionTotalLength = 0;
                                                if (q.options && q.options.length > 0) {
                                                    for (_t = 0, _u = q.options; _t < _u.length; _t++) {
                                                        option = _u[_t];
                                                        optionTotalLength = optionTotalLength + option.survey_q_asset_labels[0].label.length;
                                                        if (optionTotalLength > 150) {
                                                            totalQuestions = totalQuestions + 1;
                                                            optionTotalLength = 0;
                                                        }
                                                    }
                                                }
                                                //console.log('optionTotalLength', optionTotalLength)
                                            }
                                        }
                                    }
                                    totalQuestionsHeight = totalQuestions > 55 ? '137.8cm' : totalQuestions > 44 ? '136.5cm' : totalQuestions > 33 ? '109.2cm' : totalQuestions > 22 ? '81.9cm' : totalQuestions > 11 ? '54.6cm' : '27.3cm';
                                    section.totalQuestionsHeight = totalQuestionsHeight;
                                }
                            }
                        }
                        else if (requests[0]['message'] === 'notReleasedForLanguage') {
                            this.questionnaire = [];
                            this.blankQ = true;
                            this.notReleasedSurveyInLanguage = true;
                        }
                        else {
                            this.questionnaire = [];
                            this.blankQ = true;
                        }
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    ViewQuestionnaireComponent.prototype.changeSnapshot = function () {
        window.scrollTo(0, 0);
        this.getSnapshotData(this.selectedSnapshot);
    };
    ViewQuestionnaireComponent.prototype.changeToFirstSnapshot = function () {
        window.scrollTo(0, 0);
        var firstSnapshotId = this.snapshotData[0].id;
        if (this.selectedSnapshot !== firstSnapshotId) {
            this.selectedSnapshot = firstSnapshotId;
            this.getSnapshotData(firstSnapshotId);
        }
    };
    ViewQuestionnaireComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    ViewQuestionnaireComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    ViewQuestionnaireComponent.prototype.trackBySourceSnapshot = function (index, dataItem) {
        return dataItem.section;
    };
    ViewQuestionnaireComponent.prototype.getBackCompareData = function () {
        var url = 'charts/comparedata/' + this.projectId;
        this.router.navigate([url], { queryParams: { fromQuestion: true } });
    };
    ViewQuestionnaireComponent.prototype.goToSurveyManagement = function (projectID) {
        var url = '/questionnaires/' + this.projectId;
        if (this.fromCampaign) {
            this.router.navigate([url], { queryParams: { fromCampaign: true } });
        }
        else {
            this.router.navigate([url], { queryParams: { fromProject: true } });
        }
    };
    ViewQuestionnaireComponent.prototype.onScroll = function (item) {
        if (document.getElementById('surveyQuestionnaire').scrollTop > 100) {
            this.showUpArrow = true;
        }
        else {
            this.showUpArrow = false;
        }
    };
    ViewQuestionnaireComponent.prototype.scrollToTop = function () {
        document.getElementById('surveyQuestionnaire').scrollTop = 0; // For Safari
        //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    ViewQuestionnaireComponent.prototype.downloadQuestionnaireAsPDF = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pages, pdf, _loop_1, i, fileName;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        pages = document.querySelectorAll('.tabwise');
                        _loop_1 = function (i) {
                            var page;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        page = pages[i];
                                        return [4 /*yield*/, html2canvas(page).then(function (canvas) {
                                                var imgData = canvas.toDataURL("image/jpeg", 0.8);
                                                if (i == 0) {
                                                    pdf = new jsPDF({
                                                        unit: 'px',
                                                        format: [parseInt(canvas.style.width) + 165, parseInt(canvas.style.height) + 1000]
                                                    });
                                                    pdf.addImage(imgData, 'JPEG', 10, 10);
                                                }
                                                else {
                                                    pdf.addPage([parseInt(canvas.style.width) + 165, parseInt(canvas.style.height) + 1000], "p");
                                                    pdf.addImage(imgData, 'JPEG', 10, 10);
                                                }
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < pages.length)) return [3 /*break*/, 4];
                        return [5 /*yield**/, _loop_1(i)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        fileName = "P2F-Roots_" + this.project.name + "_" + this.questionnaireText;
                        pdf.save(fileName);
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ViewQuestionnaireComponent;
}());
export { ViewQuestionnaireComponent };
