import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services/project.service';
import { SurveyService } from '../../../services/survey.service';
import { CompareDataService } from '../../../services/compare-data.service';
import { FarmerService } from '../../../services/farmer.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TranslateService } from '@ngx-translate/core';
var ComparedataComponent = /** @class */ (function () {
    function ComparedataComponent(router, route, projectService, surveyService, compareDataService, spinner, farmerService, translate) {
        this.router = router;
        this.route = route;
        this.projectService = projectService;
        this.surveyService = surveyService;
        this.compareDataService = compareDataService;
        this.spinner = spinner;
        this.farmerService = farmerService;
        this.translate = translate;
        this.projectId = 0;
        this.sourceSnapshotData = [];
        this.snapshotsListDataDropdown = [];
        this.unitTrustsPnl = 0;
        this.compareAnswers = false;
        this.disableSource = true;
        this.viewComparison = false;
        this.viewAnswerComparison = false;
        this.snapshotComparisonData = [];
        this.revisonFarmers = [];
        this.showOverview = false;
        this.selectedFarmerId = 0;
        this.revObjectText = {};
        this.activeSnapshot = false;
        this.dropdownSettings = {};
        this.selectedFarmerIdDD = [];
    }
    ComparedataComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allSnapshotsWithRevision, _i, _a, item;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.targetSnapshotId = 0;
                        this.sourceSnapshotId = 0;
                        this.projectId = this.route.snapshot.params['id'];
                        this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
                            _this.project = response.data;
                            var projectObject = _this.project.filter(function (item) { return String(item.id) === String(_this.projectId); })[0];
                            _this.project = projectObject;
                        });
                        return [4 /*yield*/, Promise.all([
                                this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                            ])];
                    case 1:
                        allSnapshotsWithRevision = _b.sent();
                        this.snapshotData = allSnapshotsWithRevision[0].message;
                        this.snapshotData = this.snapshotData.filter(function (item) { return item.survey_type === 'snapshot'; });
                        if (this.snapshotData && this.snapshotData.length > 0) {
                            for (_i = 0, _a = this.snapshotData; _i < _a.length; _i++) {
                                item = _a[_i];
                                if (item.revisions.length > 1) {
                                    item.revisions.reverse();
                                }
                            }
                        }
                        this.checkActiveSnapshot = this.snapshotData.filter(function (item) { return item.survey_stage_changelogs.find(function (item2) { return item2.change_type === 'active'; }); });
                        if (this.checkActiveSnapshot && this.checkActiveSnapshot.length > 0) {
                            this.activeSnapshot = true;
                        }
                        this.translate.get('currentVersion').subscribe(function (result) {
                            _this.currentVersionText = result;
                        });
                        this.route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var requestParams, requestParams;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                if (queryParams.get('fromQuestion') && queryParams.get('fromQuestion') === 'true') {
                                    this.compareAnswers = false;
                                    this.disableSource = false;
                                    requestParams = localStorage.getItem('compareSnapshotsQuestionRequestObject');
                                    requestParams = JSON.parse(requestParams);
                                    this.targetSnapshotId = requestParams['targetSnapshotId'];
                                    this.targetSelected(this.targetSnapshotId);
                                    this.sourceSnapshotId = requestParams['sourceSnapshotId'];
                                    this.compareOverview();
                                }
                                if (queryParams.get('fromAnswer') && queryParams.get('fromAnswer') === 'true') {
                                    this.spinner.show();
                                    this.compareAnswers = true;
                                    this.disableSource = false;
                                    this.unitTrustsPnl = 1;
                                    requestParams = localStorage.getItem('compareSnapshotsRevisionRequestObject');
                                    requestParams = JSON.parse(requestParams);
                                    this.revObjectText = JSON.parse(localStorage.getItem('compareSnapshotsRevisionTextObject'));
                                    if (this.revObjectText['targetText'] === this.currentVersionText) {
                                        this.targetSnapshotId = "currentVersion";
                                    }
                                    else {
                                        this.targetSnapshotId = requestParams['targetSnapshotId'] + "-" + requestParams['targetRevisionId'];
                                    }
                                    this.sourceSnapshotId = requestParams['sourceSnapshotId'] + "-" + requestParams['sourceRevisionId'];
                                    this.selectedFarmerId = requestParams['farmerId'];
                                    this.targetSelected(this.targetSnapshotId);
                                    this.compareDataService.getRevisionComparisonReport(this.projectId, requestParams).subscribe(function (response) {
                                        _this.targetSnapshot = _this.addStatusAndDates(response.targetSnapshot);
                                        _this.sourceSnapshot = _this.addStatusAndDates(response.sourceSnapshot);
                                        _this.showOverview = true;
                                        _this.spinner.hide();
                                    });
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        this.setTranslatedLabelObjects();
                        this.dropdownSettings = {
                            singleSelection: true,
                            idField: 'id',
                            textField: 'name',
                            selectAllText: this.selectAllText,
                            unSelectAllText: this.unSelectAllText,
                            searchPlaceholderText: this.searchText1,
                            itemsShowLimit: 3,
                            allowSearchFilter: true,
                            closeDropDownOnSelection: true
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    //added by Kiran for translation
    ComparedataComponent.prototype.setTranslatedLabelObjects = function () {
        var _this = this;
        this.translate.get('selectPlaceholder').subscribe(function (result) {
            _this.selectPlaceholder = result;
        });
        this.translate.get('selectAllText').subscribe(function (result) {
            _this.selectAllText = result;
        });
        this.translate.get('unSelectAllText').subscribe(function (result) {
            _this.unSelectAllText = result;
        });
        this.translate.get('searchText').subscribe(function (result) {
            _this.searchText1 = result;
        });
    };
    ComparedataComponent.prototype.setFarmer = function (eve) {
        this.selectedFarmerId = eve.id;
    };
    ComparedataComponent.prototype.compareOverview = function () {
        var _this = this;
        this.spinner.show();
        this.showOverview = false;
        if (!this.compareAnswers) {
            var requestObject = {
                "targetSnapshotId": this.targetSnapshotId,
                "sourceSnapshotId": this.sourceSnapshotId,
                "showDetails": 0
            };
            localStorage.setItem('compareSnapshotsQuestionRequestObject', JSON.stringify(requestObject));
            this.compareDataService.getComparisonReport(this.projectId, requestObject).subscribe(function (response) {
                _this.sourceSnapshot = _this.addStatusAndDates(response.sourceSnapshot);
                _this.targetSnapshot = _this.addStatusAndDates(response.targetSnapshot);
                _this.showOverview = true;
                _this.spinner.hide();
            });
        }
        else {
            var revTargetSelected = void 0;
            var revTargetSelectedSTring = this.targetSnapshotId.toString();
            if (revTargetSelectedSTring !== 'currentVersion') {
                revTargetSelected = this.targetSnapshotId;
                revTargetSelected = revTargetSelected.split("-");
            }
            else {
                var currentVersion = this.checkActiveSnapshot[0].id + "-0";
                revTargetSelected = currentVersion.split("-");
            }
            var revSourceSelected = void 0;
            revSourceSelected = this.sourceSnapshotId;
            revSourceSelected = revSourceSelected.split("-");
            var requestObject = {
                "targetSnapshotId": revTargetSelected[0],
                "sourceSnapshotId": revSourceSelected[0],
                "showDetails": 0,
                "farmerId": (this.selectedFarmerId > 0 ? this.selectedFarmerId : this.revisonFarmers[0].id),
                "sourceRevisionId": revSourceSelected[1],
                "targetRevisionId": revTargetSelected[1]
            };
            localStorage.setItem('compareSnapshotsRevisionRequestObject', JSON.stringify(requestObject));
            var revTarget = document.querySelector('#targetSelect');
            var revTargetText = revTarget.options[revTarget.selectedIndex].text;
            var revSource = document.querySelector('#sourceSelect');
            var revSourceText = revSource.options[revSource.selectedIndex].text;
            var revObject = {
                "targetText": revTargetText,
                "sourceText": revSourceText
            };
            this.revObjectText = revObject;
            localStorage.setItem('compareSnapshotsRevisionTextObject', JSON.stringify(revObject));
            this.compareDataService.getRevisionComparisonReport(this.projectId, requestObject).subscribe(function (response) {
                _this.targetSnapshot = _this.addStatusAndDates(response.targetSnapshot);
                _this.sourceSnapshot = _this.addStatusAndDates(response.sourceSnapshot);
                _this.showOverview = true;
                _this.spinner.hide();
            });
        }
    };
    ComparedataComponent.prototype.showDifferenceSnapshot = function () {
        var _this = this;
        if (!this.compareAnswers) {
            this.spinner.show();
            var requestObject = {
                "targetSnapshotId": this.targetSnapshot.surveyDetails.id,
                "sourceSnapshotId": this.sourceSnapshot.surveyDetails.id,
                "showDetails": 1
            };
            this.compareDataService.getComparisonReport(this.projectId, requestObject).subscribe(function (response) {
                var targetSnapshotDiff = response.target;
                var sourceSnapshotDiff = response.source;
                var targetDiffArr = [];
                if (targetSnapshotDiff && targetSnapshotDiff.length > 0) {
                    var _loop_1 = function (tab) {
                        var sectionArr = [];
                        var changesCount = 0;
                        if (tab.sections && tab.sections.length > 0) {
                            var _loop_2 = function (section) {
                                var questionArr = [];
                                var subsectionArr = [];
                                var tableArr = [];
                                if (section.questions && section.questions.length > 0) {
                                    var _loop_3 = function (question) {
                                        if (question.marker && question.marker !== "NO_CHANGE") {
                                            var newObj = {
                                                "id": question.id,
                                                "question": question.survey_q_asset_labels[0].label,
                                                "marker": question.marker,
                                                "oldQuestion": ""
                                            };
                                            if (question.marker === "UPDATED" || question.marker === "DELETED") {
                                                var sourceTab = sourceSnapshotDiff.filter(function (item) { return item.code === tab.code; });
                                                if (sourceTab) {
                                                    var sourceSection = sourceTab[0].sections.filter(function (item) { return item.code === section.code; });
                                                    if (sourceSection) {
                                                        var sourceQuestion = sourceSection[0].questions.filter(function (item) { return item.code === question.code; });
                                                        newObj.oldQuestion = sourceQuestion[0].survey_q_asset_labels[0].label;
                                                    }
                                                }
                                            }
                                            questionArr.push(newObj);
                                            changesCount++;
                                        }
                                    };
                                    for (var _i = 0, _a = section.questions; _i < _a.length; _i++) {
                                        var question = _a[_i];
                                        _loop_3(question);
                                    }
                                }
                                if (section.subsections && section.subsections.length > 0) {
                                    var _loop_4 = function (subsection) {
                                        var subsectionQuestionArr = [];
                                        if (subsection.questions && subsection.questions.length > 0) {
                                            var _loop_6 = function (question) {
                                                if (question.marker && question.marker !== "NO_CHANGE") {
                                                    var newObj = {
                                                        "id": question.id,
                                                        "question": question.survey_q_asset_labels[0].label,
                                                        "marker": question.marker,
                                                        "oldQuestion": ""
                                                    };
                                                    if (question.marker === "UPDATED" || question.marker === "DELETED") {
                                                        var sourceTab = sourceSnapshotDiff.filter(function (item) { return item.code === tab.code; });
                                                        if (sourceTab) {
                                                            var sourceSection = sourceTab[0].sections.filter(function (item) { return item.code === section.code; });
                                                            if (sourceSection) {
                                                                var sourceSubsection = sourceSection[0].subsections.filter(function (item) { return item.code === subsection.code; });
                                                                if (sourceSubsection) {
                                                                    var sourceQuestion = sourceSubsection[0].questions.filter(function (item) { return item.code === question.code; });
                                                                    newObj.oldQuestion = sourceQuestion[0].survey_q_asset_labels[0].label;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    subsectionQuestionArr.push(newObj);
                                                    changesCount++;
                                                }
                                            };
                                            for (var _i = 0, _a = subsection.questions; _i < _a.length; _i++) {
                                                var question = _a[_i];
                                                _loop_6(question);
                                            }
                                        }
                                        if (subsectionQuestionArr.length > 0) {
                                            var subsectionObj = {
                                                "subsection_name": subsection.survey_q_asset_labels[0].label,
                                                "questions": subsectionQuestionArr
                                            };
                                            subsectionArr.push(subsectionObj);
                                        }
                                    };
                                    for (var _b = 0, _c = section.subsections; _b < _c.length; _b++) {
                                        var subsection = _c[_b];
                                        _loop_4(subsection);
                                    }
                                }
                                if (section.tables && section.tables.length > 0) {
                                    var _loop_5 = function (table) {
                                        var tableQuestionArr = [];
                                        if (table.questions && table.questions.length > 0) {
                                            var _loop_7 = function (question) {
                                                if (question.marker && question.marker !== "NO_CHANGE") {
                                                    var newObj = {
                                                        "id": question.id,
                                                        "question": question.survey_q_asset_labels[0].label,
                                                        "marker": question.marker,
                                                        "oldQuestion": ""
                                                    };
                                                    if (question.marker === "UPDATED" || question.marker === "DELETED") {
                                                        var sourceTab = sourceSnapshotDiff.filter(function (item) { return item.code === tab.code; });
                                                        if (sourceTab) {
                                                            var sourceSection = sourceTab[0].sections.filter(function (item) { return item.code === section.code; });
                                                            if (sourceSection) {
                                                                var sourceTable = sourceSection[0].tables.filter(function (item) { return item.code === table.code; });
                                                                if (sourceTable) {
                                                                    var sourceQuestion = sourceTable[0].questions.filter(function (item) { return item.code === question.code; });
                                                                    newObj.oldQuestion = sourceQuestion[0].survey_q_asset_labels[0].label;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    tableQuestionArr.push(newObj);
                                                    changesCount++;
                                                }
                                            };
                                            for (var _i = 0, _a = table.questions; _i < _a.length; _i++) {
                                                var question = _a[_i];
                                                _loop_7(question);
                                            }
                                        }
                                        if (tableQuestionArr.length > 0) {
                                            var tableObj = {
                                                "table_name": table.survey_q_asset_labels[0].label,
                                                "questions": tableQuestionArr
                                            };
                                            tableArr.push(tableObj);
                                        }
                                    };
                                    for (var _d = 0, _e = section.tables; _d < _e.length; _d++) {
                                        var table = _e[_d];
                                        _loop_5(table);
                                    }
                                }
                                if (questionArr.length > 0 || subsectionArr.length > 0 || tableArr.length > 0) {
                                    var sectionObj = {
                                        "section_name": section.survey_q_asset_labels[0].label,
                                        "questions": questionArr,
                                        "subsections": subsectionArr,
                                        "tables": tableArr
                                    };
                                    sectionArr.push(sectionObj);
                                }
                            };
                            for (var _i = 0, _a = tab.sections; _i < _a.length; _i++) {
                                var section = _a[_i];
                                _loop_2(section);
                            }
                        }
                        if (sectionArr.length > 0) {
                            var tabObj = {
                                "tab_name": tab.survey_q_asset_labels[0].label,
                                "sections": sectionArr,
                                "total_changes": changesCount
                            };
                            targetDiffArr.push(tabObj);
                        }
                    };
                    for (var _i = 0, targetSnapshotDiff_1 = targetSnapshotDiff; _i < targetSnapshotDiff_1.length; _i++) {
                        var tab = targetSnapshotDiff_1[_i];
                        _loop_1(tab);
                    }
                }
                _this.viewComparison = true;
                _this.snapshotComparisonData = targetDiffArr;
                _this.spinner.hide();
                window.scroll(0, 0);
            });
        }
        else {
            this.viewAnswerComparison = true;
        }
    };
    ComparedataComponent.prototype.addStatusAndDates = function (snapshot) {
        var tempTargetSnapshot = snapshot;
        var targetStatus = "Draft";
        var targetSurveyActivatedOn;
        var targetSurveyClosedOn;
        for (var _i = 0, _a = tempTargetSnapshot.surveyDetails.survey_stage_changelogs; _i < _a.length; _i++) {
            var val = _a[_i];
            if (val.change_type === "closed") {
                targetStatus = "Closed";
                targetSurveyClosedOn = val.change_at;
            }
            if (val.change_type === "active") {
                targetStatus = "Active";
                targetSurveyActivatedOn = val.change_at;
            }
        }
        tempTargetSnapshot.surveyDetails.status = targetStatus;
        tempTargetSnapshot.surveyDetails.activated_on = targetSurveyActivatedOn;
        tempTargetSnapshot.surveyDetails.closed_on = targetSurveyClosedOn;
        var sortable = [];
        for (var tab in tempTargetSnapshot.tabkeys) {
            sortable.push([tab, tempTargetSnapshot.tabkeys[tab]]);
        }
        tempTargetSnapshot.sortedTab = sortable;
        return tempTargetSnapshot;
    };
    ComparedataComponent.prototype.closeComparison = function () {
        this.viewComparison = false;
    };
    ComparedataComponent.prototype.closeAnswerComparison = function () {
        this.viewAnswerComparison = false;
    };
    ComparedataComponent.prototype.trackBySourceSnapshot = function (index, dataItem) {
        return dataItem.section;
    };
    ComparedataComponent.prototype.trackByRevisions = function (index, dataItem) {
        return dataItem.section;
    };
    ComparedataComponent.prototype.trackByRevisionFarmers = function (index, dataItem) {
        return dataItem.section;
    };
    ComparedataComponent.prototype.reportTypeChange = function (type) {
        this.showOverview = false;
        this.targetSnapshotId = 0;
        this.sourceSnapshotId = 0;
        this.selectedFarmerId = 0;
        if (type === "answer") {
            this.compareAnswers = true;
        }
        else {
            this.compareAnswers = false;
        }
    };
    ComparedataComponent.prototype.targetSelected = function (ev, fromUI) {
        var _this = this;
        if (this.compareAnswers) {
            if (this.targetSnapshotId !== 0) {
                //this.disableSource = false;
                if (this.targetSnapshotId !== 'currentVersion') {
                    var curValue_1 = this.targetSnapshotId;
                    curValue_1 = curValue_1.split("-");
                    var targetSequenceId_1 = this.snapshotData.filter(function (item) { return parseInt(item.id) === parseInt(curValue_1[0]); })[0];
                    var targetRevisionId_1 = targetSequenceId_1.revisions.filter(function (item) { return parseInt(item.id) === parseInt(curValue_1[1]); })[0];
                    var res = this.snapshotData.reduce(function (acc, a) {
                        if (a.survey_type_sequence === targetSequenceId_1.survey_type_sequence) {
                            var ch = a.revisions && a.revisions.filter(function (b) { return b.revision_number < targetRevisionId_1.revision_number; });
                            if (ch && ch.length) {
                                acc.push(tslib_1.__assign({}, a, { revisions: ch }));
                            }
                        }
                        else if (a.survey_type_sequence < targetSequenceId_1.survey_type_sequence) {
                            acc.push(tslib_1.__assign({}, a));
                        }
                        return acc;
                    }, []);
                    this.sourceSnapshotData = res;
                    if (fromUI) {
                        if (this.sourceSnapshotId == 0) {
                            this.disableSource = false;
                        }
                        else {
                            var checkSourceSnapshot = this.sourceSnapshotData.filter(function (item) { return item.id === curValue_1[0]; });
                            if (checkSourceSnapshot.length > 0) {
                                this.disableSource = false;
                            }
                            else {
                                this.sourceSnapshotId = 0;
                            }
                        }
                    }
                    this.getFarmerForRevision(curValue_1[1], fromUI);
                }
                else {
                    this.disableSource = false;
                    this.sourceSnapshotData = this.snapshotData;
                    this.farmerService.getFarmersByProjectBasicData(this.projectId).subscribe(function (response) {
                        _this.revisonFarmers = response.data;
                        for (var _i = 0, _a = _this.revisonFarmers; _i < _a.length; _i++) {
                            var farmer = _a[_i];
                            var idName = void 0;
                            if (farmer.official_id) {
                                farmer.supplier_id = farmer.official_id;
                                idName = farmer.official_id + " - " + farmer.name;
                            }
                            else {
                                idName = farmer.name;
                            }
                            farmer.name = idName;
                            if (fromUI) {
                                _this.selectedFarmerIdDD = [];
                            }
                            else if (farmer.id == _this.selectedFarmerId) {
                                _this.selectedFarmerIdDD = [{ 'id': farmer.id, 'name': farmer.name }];
                            }
                        }
                    });
                }
            }
        }
        else if (this.targetSnapshotId > 0) {
            var targetSequenceId_2 = this.snapshotData.filter(function (item) { return item.id === _this.targetSnapshotId; })[0];
            var sourceDataset = this.snapshotData.filter(function (item) { return item.survey_type_sequence < targetSequenceId_2.survey_type_sequence; });
            this.sourceSnapshotData = sourceDataset;
            if (this.sourceSnapshotId == 0) {
                this.disableSource = false;
            }
            else {
                var checkSourceSnapshot = this.sourceSnapshotData.filter(function (item) { return item.id === _this.sourceSnapshotId; });
                if (checkSourceSnapshot.length > 0) {
                    this.disableSource = false;
                }
                else {
                    this.sourceSnapshotId = 0;
                }
            }
        }
    };
    ComparedataComponent.prototype.viewSnapshot = function (rType) {
        if (this.compareAnswers) {
            var url = "questionnaire/view/" + this.projectId + "/" + (this.selectedFarmerId > 0 ? this.selectedFarmerId : this.revisonFarmers[0].id);
            this.router.navigate([url], { queryParams: { fromCompare: 1, type: rType } });
        }
        else {
            if (rType === 'target') {
                var url = "viewquestionnaire/" + this.projectId + "/" + this.targetSnapshotId;
                this.router.navigate([url], { queryParams: { fromCompare: 1 } });
            }
            else {
                var url = "viewquestionnaire/" + this.projectId + "/" + this.sourceSnapshotId;
                this.router.navigate([url], { queryParams: { fromCompare: 1 } });
            }
        }
    };
    ComparedataComponent.prototype.getFarmerForRevision = function (revisionId, fromUI) {
        var _this = this;
        this.compareDataService.getFarmerList(this.projectId, revisionId).subscribe(function (response) {
            _this.revisonFarmers = response.farmers;
            for (var _i = 0, _a = _this.revisonFarmers; _i < _a.length; _i++) {
                var farmer = _a[_i];
                var idName = void 0;
                if (farmer.supplier_id) {
                    idName = farmer.supplier_id + " - " + farmer.name;
                }
                else {
                    idName = farmer.name;
                }
                farmer.name = idName;
                if (fromUI) {
                    _this.selectedFarmerIdDD = [];
                }
                else if (farmer.id == _this.selectedFarmerId) {
                    _this.selectedFarmerIdDD = [{ 'id': farmer.id, 'name': farmer.name }];
                }
            }
        });
    };
    ComparedataComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    ComparedataComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    return ComparedataComponent;
}());
export { ComparedataComponent };
