import { Component,
  OnInit,
  OnDestroy,
  ViewChild, ElementRef, AfterViewInit, Renderer, ChangeDetectionStrategy, Renderer2, Inject, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Project, UserTypeServiceMapping } from '../../../models/project';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { chart } from 'highcharts';
import { SuppliersService } from '../../../services/suppliers.service';
import { ChartService } from '../../../services/chart.service';
import { HighchartReportsService } from '../../../services/highchart-reports.service';

const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
require('highcharts/modules/full-screen')(Highcharts);

var parentScope01;
@Component({
  selector: 'app-run-report',
  templateUrl: './run-report.component.html',
  styleUrls: ['./run-report.component.css']
})
export class RunReportComponent implements OnInit {

  @ViewChild('charts') public chartElement01: ElementRef;

  @ViewChild('charts02') public chartElement02: ElementRef;

  preferredReportsType = 'profiling';
  public setAsDefault:boolean = false;
  public reportListType:any = [];
  public customReportsListData:any = [];
  reportTypes ='predefined';
  projects: any = [];
  project: any;
  projectId = 0;
  public tableData: any;
  public origin: any;
  tableFinalData;
  public fromNavId;
  public typeOfAdministrationArr: any[];
  typeOfAdministration = '';
  public searchText: string;
  public previousPaginationLabel;
  public nextPaginationLabel;
  public dashboardAddObject ={
    "section":0,
    "subsection":'',
    "reportID":[]
  }
  newSubSection: any[];
  newReportName: any[];
  reportsListData:any[];
  reportsListDataCustom:any[];
  subsectionFlag: boolean = true;
  reportsFlag: boolean = true;
  selectedReportsMultiSelect = [];
  selectedReportsMultiSelectCustom = [];
  dropdownSettings = {};
  listsSectionsSubSection = [];
  chartsOptions=[];
  exportBtns:boolean = false;
  reportAvailableFlag:boolean = false;
  sectionDropDown= [];
  subSectionDropdown = [];
  listOfReport = [];
  existingSelectedReports = [];
  checkChartsAvailable = [];
  profilingFlag:boolean = false;
  customFlag:boolean = false;
  selectedChartType;
  countOfFarmers;
  localChartsList = [];
  noNewReportsSelected;
  selectPlaceholder:string;
  selectAllText:string;
  unSelectAllText:string;
  searchText1:string;
  constructor(
    private readonly route: ActivatedRoute,
    private projectService: ProjectService,
    private toastrService: ToastrService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private readonly suppService: SuppliersService,
    private translate: TranslateService,
    private changeDetectionRef: ChangeDetectorRef,
    private chartService:ChartService,
    private renderer: Renderer2,
    private hcRService:HighchartReportsService
  ) {    

  }

  async ngOnInit() {
    //debugger;
    parentScope01 = this;
    this.projectId = parseInt(this.route.snapshot.params['id']);
    // if (navigator.onLine) {
    //   this.project = this.route.snapshot.data.project.project.data;
    // }else{
    //   const requests = await Promise.all([
    //     this.projectService.GetProjectsDataDashboard().toPromise()
    //   ]);
    //   this.project = requests[0].data.find(x => x.id === this.projectId);
    // }
    this.hcRService.allCharts = [];

    this.projectService.getProjectBasicInfo(this.projectId).subscribe(response =>{
      this.project = response.data;
      let projectObject = this.project.filter(item => item.id == this.projectId)[0];
      this.project = projectObject;
    });

    let requestParams = {
      projectId : this.projectId,
      type : "CHART"
    };
    this.setTranslatedLabelObjects();

    this.hcRService.getSectionSubsectionReportsSelectionListAPI(requestParams).subscribe(response =>{
      //console.log("response ", response);
      if(response.msg == 'success'){
        let sectionSubsectionReports = response.data;
        //console.log("sectionSubsectionReports ",sectionSubsectionReports);
        if(sectionSubsectionReports.profiling_reports && Object.keys(sectionSubsectionReports.profiling_reports).length !== 0){
          for (var item in sectionSubsectionReports.profiling_reports) {
            let newObject = {
              "section": '',
              "subsection": []
            };
            //console.log(" item ", item);
            this.sectionDropDown.push(item);
            newObject.section  = item;
            let subSection = sectionSubsectionReports.profiling_reports[item];
            //console.log(" subSection ", subSection);
            for(let x in subSection){
              //console.log(" subSection ", x)
              this.subSectionDropdown.push(x);
              let reportsList = subSection[x];
              //this.listOfReport = reportsList.map(item => ({id:item.id, name:item.name}));
              //console.log("this.listOfReport ",this.listOfReport);
              let subObject ={
                "name": x,
                "reports":reportsList 
              };
              newObject.subsection.push(subObject);            
  
              //console.log(" newObject ", newObject);
              
            }
            //console.log(" newObject ", newObject);
            this.listsSectionsSubSection.push(newObject);
            //console.log("this.listsSectionsSubSection ", this.listsSectionsSubSection);
          }
        }        
        if(sectionSubsectionReports.custom_reports && sectionSubsectionReports.custom_reports.length > 0){
          let customReports = sectionSubsectionReports.custom_reports;
          this.customReportsListData.push(...customReports);
        }

      }     

    });
    
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: this.selectAllText,
      unSelectAllText: this.unSelectAllText,
      searchPlaceholderText:this.searchText1,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    
    Highcharts.getSVG = function(charts) {
      var svgArr = [],
        top = 0,
        width = 0,
        endWidth = 0;
    
      Highcharts.each(charts, function(chart) {
        var svg = chart.getSVG(),
          // Get width/height of SVG for export
          svgWidth = +svg.match(
            /^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/
          )[1],
          svgHeight = +svg.match(
            /^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/
          )[1];
    
        svg = svg.replace(
          '<svg',
          '<g transform="translate(' + width + ', ' + top + ')" '
        );
    
        svg = svg.replace('</svg>', '</g>');
        //console.log(" svgWidth ", svgWidth);
        //console.log(" width before ", width);
        width += svgWidth;
        //console.log(" width After ", width);

        endWidth = Math.max(endWidth, width)
        
        //console.log("endWidth ", endWidth);

        if (width === 2 * svgWidth) {
          width = 0;
          top += svgHeight;
        }
        //console.log("top ", top);
        svgArr.push(svg);
      });
      top += 400;
      return '<svg height="' + top + '" width="' + endWidth +
        '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
        svgArr.join('') + '</svg>';
    }

    Highcharts.exportCharts = function(charts, options) {

      // Merge the options
      options = Highcharts.merge(Highcharts.getOptions().exporting, options);
    
      // Post to export server
      Highcharts.post(options.url, {
        filename: options.filename || 'chart',
        type: options.type,
        width: options.width,
        svg: Highcharts.getSVG(charts)
      });
    };

    Highcharts.setOptions({
      lang: {
        noData: 'No data to display',
      }
    });
    Highcharts.setOptions({
      lang: {
        drillUpText: '<<'
      }
    });

    if(this.projectId > 0){
      let requestObject = {
        "reportID":[50],
        "projectId":this.projectId
      }; 

      this.hcRService.getReports(requestObject).subscribe(response =>{
        //console.log("response ", response);
        if(response.msg == 'success'){
          //console.log(" response ", response);
          this.countOfFarmers = response.data[0].series[0];
        }
      });

    }

  }

  //added by Kiran for translation
  setTranslatedLabelObjects(): void {
    this.translate.get('no_new_reports_selected').subscribe(result => {
      this.noNewReportsSelected = result;
    });
    this.translate.get('selectPlaceholder').subscribe(result => {
      this.selectPlaceholder = result;
    });
    this.translate.get('selectAllText').subscribe(result => {
      this.selectAllText = result;
    });
    this.translate.get('unSelectAllText').subscribe(result => {
      this.unSelectAllText = result;
    });
    this.translate.get('searchText').subscribe(result => {
      this.searchText1 = result;
    });
  }

  changeChartType(currentItemScope){
    //debugger;
    //parentScope01.changeChartType
    //console.log("currentItemScope ", currentItemScope);
    //console.log("currentItemScope Before ", currentItemScope.userOptions.chart.type);
    if(currentItemScope.userOptions.chart.type == 'column'){
      currentItemScope.update({
        chart:{
          type: 'pie'
        }
      });
    }else if(currentItemScope.userOptions.chart.type == 'pie'){
      currentItemScope.update({
        chart:{
          type: 'column'
        }
      });
    }
    
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }
  goToReportingManagement(projectID){
    let url = 'charts/' + this.projectId;
    this.router.navigate([url]);
  }
  goToDashboards(){
    let url="charts/dashboards/"+ this.projectId;
    this.router.navigate([url]);
  }

  selectSection(ev, selectedValue) {
    this.reportsListData = [];
    this.selectedReportsMultiSelect = [];

    this.dashboardAddObject.subsection = '';

    //console.log("selectSection ",ev.target.value);
    //console.log("dashboardAddObject.sectionId ", this.dashboardAddObject.sectionId);
    let section = this.listsSectionsSubSection.filter(item => item.section == this.dashboardAddObject.section)[0];
    //console.log("section ", section);
    if(section != undefined || section  != null){
      this.newSubSection = section.subsection;
      for(let i=0;i<this.newSubSection.length;i++){
        let reports = this.newSubSection[i].reports.filter(item => item.section == this.dashboardAddObject.section);
        //console.log("reports section select ", reports);
        this.reportsListData = this.reportsListData.concat(reports);
        //console.log(" reportsItems ", this.reportsListData);
      }
    }else{
      this.newSubSection = [];
    }
    //console.log(" subsection ", section.subsection);
    this.subsectionFlag = false;
  }

  selectSubSection(ev) {    
    this.reportsListData = [];
    //this.selectedValues.subsection = ev.target.value;
    //console.log("dashboardAddObject.subSectionId ", this.dashboardAddObject.subSectionId);
    let section = this.listsSectionsSubSection.filter(item => item.section == this.dashboardAddObject.section)[0];
    //this.reportsListData
    let subsection;
    if(section != undefined || section  != null){
      subsection = section.subsection.filter(item =>  item.name == this.dashboardAddObject.subsection)[0];
    }
    //let subsection = section.subsection.filter(item =>  item.name == this.dashboardAddObject.subsection)[0];
    //console.log(" reports ", subsection);
    //this.reportsListData = subsection.reports;
    if(subsection && subsection.reports){
      this.reportsListData = subsection.reports;
      this.selectedReportsMultiSelect = [];
    }else{
      this.newSubSection = section.subsection;
      //let reportsItems:any =[];
      for(let i=0;i<this.newSubSection.length;i++){
        let reports = this.newSubSection[i].reports.filter(item => item.section == this.dashboardAddObject.section);
        //console.log("reports section select ", reports);
        this.reportsListData = this.reportsListData.concat(reports);
        //console.log(" reportsItems ", reportsItems);
      }
      this.selectedReportsMultiSelect = [];
    }
    this.reportsFlag = false;
  }

  setAsDefaultDashboard(){
    //console.log(" setAsDefault ", this.setAsDefault);
  }

  chartType(selectedChartType){
    //console.log(" selectedChartType ", selectedChartType);
  }
  
  async genarateReports(currentType){
    //console.log("dashboardAddObject ", this.dashboardAddObject);
    //console.log("selectedReportsMultiSelect ", this.selectedReportsMultiSelect);
    //this.chartElement01.nativeElement;
    //console.log("currentType ", currentType);
    //debugger;
    let selectedReportIds;
    let requestObject ={};
    let selectedReports;
    if(currentType == 'predefined'){
      selectedReports = this.selectedReportsMultiSelect.map(item => item.id);
      //console.log("selectedReports ", selectedReports);
    }else{
      selectedReports = this.selectedReportsMultiSelectCustom.map(item => item.id);
      //console.log("selectedReports ", selectedReports);     
    }

    if(this.existingSelectedReports.length > 0){
      const result = selectedReports.filter(d => this.existingSelectedReports.indexOf(d) < 0);
      //console.log(result);
      selectedReportIds = result;
      this.existingSelectedReports = this.existingSelectedReports.concat(result);
    }else{
      this.existingSelectedReports = this.existingSelectedReports.concat(selectedReports);
      selectedReportIds = selectedReports;
    }

    requestObject = {
      //"reportID":selectedReportIds,
      "reportID":selectedReportIds,
      "projectId":this.projectId
      //"projectId":25
    };    

    //console.log("request params Object ", requestObject);
    //return;
    let chartsList = [];
    let colList = [];
    
    if(selectedReportIds.length > 0){
      this.hcRService.getReports(requestObject).subscribe(response =>{
        //console.log("response ", response);
        if(response.msg == 'success'){
          this.reportAvailableFlag = true;
          let reportsData = response.data;              
          if(reportsData && reportsData.length > 0){
            for(let i=0;i<reportsData.length;i++){
              var seriesArr = [];
              let contextMenuOptions = [];
              colList.push(reportsData[i].dim_col)
              let chartOptions = reportsData[i].conf[0].conf;
              if(typeof reportsData[i].conf[0].conf !== 'object'){
                 chartOptions = JSON.parse(reportsData[i].conf[0].conf);
              }

              //added by Kiran to change chart title to report name
              if(chartOptions.hasOwnProperty('title')){
                chartOptions.title.text = reportsData[i].name;
              }
              
              //console.log("chartOptions ", chartOptions);
              if(reportsData[i].is_drilldown != 1){
                //console.log(" chartOptions.chart.zoomType ", chartOptions.chart.zoomType);
                //console.log("reportsData[i].series ",reportsData[i].series.length);
                if(reportsData[i].series && reportsData[i].series.length > 1){
                  //console.log("more than 1 conf ");
                  if(chartOptions.chart.zoomType == 'xy' && reportsData[i].conf[0].report_type == 'dual-axes'){
                    seriesArr = [];
                    //console.log("reportsData[i].conf[0].report_type ", reportsData[i].conf[0].report_type);
                    let category = [];
                    for(let x=0;x<reportsData[i].series.length;x++){
                      category.push(reportsData[i].series[x].key);

                      for(let y=0;y<chartOptions.series.length;y++){
                        if(chartOptions.series[y].hasOwnProperty('yAxis')){
                          //chartOptions.series[y].name='';
                          chartOptions.series[y].data.push(Number(reportsData[i].series[x].avg_expense));
                        }else{
                          //chartOptions.series[y].name='';
                          chartOptions.series[y].data.push(reportsData[i].series[x].value);
                        }
                      }

                    }

                    if(Array.isArray(chartOptions.xAxis)){
                      chartOptions.xAxis[0].categories = category;
                    }
                    if(chartOptions.hasOwnProperty('yAxis')){
                     let newString = chartOptions.yAxis[1].title.text +' ('+ reportsData[i].series[0].currency + ')';
                     chartOptions.yAxis[1].title.text = newString;
                    }
                    //chartOptions.legend.y=0;
                    //chartOptions.legend.x=60;
                    //console.log("chartOptions dual axes ", JSON.stringify(chartOptions));

                  }else{
                    let hcObject = {};
                    let categories = [];
                    let objectKeys = Object.keys(reportsData[i].series[0]);
                    for(let j=0;j<reportsData[i].series.length;j++){  
                      var elementItem = reportsData[i].series[j];
                      for(let k=0;k<objectKeys.length;k++){
                        if(objectKeys[k] == 'name'){
                          categories.push(reportsData[i].series[j][objectKeys[k]]);
                        }else{
                          if(hcObject[objectKeys[k]]){
                            hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                          }else{
                            hcObject[objectKeys[k]] = new Array();
                            hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                          }
                        }
                      }
                    }

                    if(Object.keys(hcObject).length !== 0){
                      for(let key in hcObject){
                        let object ={
                          name:'',
                          data:[]
                        };
                        object.name = key;
                        object.data = hcObject[key];
                        seriesArr.push(object);
                      }
                    }
                    
                    //console.log(" seriesArr time ", seriesArr);
                    if(chartOptions.hasOwnProperty('xAxis')){
                      chartOptions.xAxis.categories = categories;
                    }
                    if(chartOptions.hasOwnProperty('yAxis')){                    
                      chartOptions.yAxis.min = 0;
                    }
                    //chartOptions.xAxis.categories = categories;
                    //console.log("4 ", chartOptions);
                    chartOptions.series = seriesArr;
                  //console.log("chartOptions.series ",chartOptions.series);
                  }

                }else{
                  //console.log("reportsData[i].series ", reportsData[i].series);
                  if(chartOptions.chart.zoomType == 'xy' && reportsData[i].conf[0].report_type == 'dual-axes'){
                    seriesArr = [];
                    //console.log("reportsData[i].conf[0].report_type ", reportsData[i].conf[0].report_type);
                    let category = [];
                    for(let x=0;x<reportsData[i].series.length;x++){
                      category.push(reportsData[i].series[x].key);

                      for(let y=0;y<chartOptions.series.length;y++){
                        if(chartOptions.series[y].hasOwnProperty('yAxis')){
                          //chartOptions.series[y].name='';
                          chartOptions.series[y].data.push(Number(reportsData[i].series[x].avg_expense));
                        }else{
                          //chartOptions.series[y].name='';
                          chartOptions.series[y].data.push(reportsData[i].series[x].value);
                        }
                      }

                    }

                    if(Array.isArray(chartOptions.xAxis)){
                      chartOptions.xAxis[0].categories = category;
                    }
                   
                    if(chartOptions.hasOwnProperty('yAxis')){
                      if(reportsData[i].series.length > 0 && reportsData[i].series[0].hasOwnProperty('currency')){
                        let newString = chartOptions.yAxis[1].title.text +' ('+ reportsData[i].series[0].currency + ')';
                        chartOptions.yAxis[1].title.text = newString;
                      }
                    }

                    //chartOptions.legend.y=0;
                    //chartOptions.legend.x=60;
                    //console.log("chartOptions dual axes ", JSON.stringify(chartOptions));

                  }else if(chartOptions.chart.type != 'pyramid'){
                    for(let j=0;j<reportsData[i].series.length;j++){                  
                      for (var prop in reportsData[i].series[j]) {                    
                        if (reportsData[i].series[0].hasOwnProperty(prop)) {
                          if(Number(reportsData[i].series[0][prop]) > 0){
                            var innerObj = {
                              name:'',
                              y:Number(''),
                            };
                            innerObj.name = prop;
                            innerObj.y = Number(reportsData[i].series[0][prop]);
                            seriesArr.push(innerObj)
                          }                          
                        }
                      }                  
                    }
  
                    // chartOptions.series = [
                    //   {
                    //     colorByPoint: true,
                    //     data: seriesArr
                    //   }
                    // ];
                   //console.log("3 ", chartOptions);

                    if(chartOptions.hasOwnProperty('series')){
                      //console.log(" seriesArr ", seriesArr);
                      if(chartOptions.series.length > 0){
                        if(reportsData[i].conf[0].report_type == 'waterfall'){ 
                          if(chartOptions.hasOwnProperty('chart')){
                            if(chartOptions.chart.hasOwnProperty('type')){
                              //console.log("waterfall")
                              let totalObject ={
                                name: 'Total',
                                isIntermediateSum: true
                              };
                              seriesArr.push(totalObject);
                              chartOptions.series[0].data = seriesArr;
                            }
                          }
                        }else{
                          chartOptions.series[0].data = seriesArr;
                        }
                        
                      }
                      
                    }else{
                      if(reportsData[i].name === 'Accessibility to Basics'){
                        let categories = [];
                        seriesArr = [];
                        //console.log(reportsData[i]);
                        for(let j=0;j<reportsData[i].series.length;j++){
                            var object = {
                                name:'Farmers',
                                data:[]
                              };                  
                            for (var prop in reportsData[i].series[j]) {                    
                                if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                    categories.push(prop);                                         
                                      // object.name = key;
                                    object.data.push(Number(reportsData[i].series[0][prop]));
                                    
                                }
                            }
                            seriesArr.push(object);                  
                        }                            
                        
                        if(chartOptions.hasOwnProperty('xAxis')){
                            chartOptions.xAxis.categories = categories;
                        }
                        if(chartOptions.hasOwnProperty('yAxis')){                    
                            chartOptions.yAxis.min = 0;
                          }                                
                        chartOptions.series = seriesArr;
                      }else{
                        chartOptions.series = [
                          {
                            colorByPoint: true,
                            data: seriesArr
                          }
                        ];
                      }
                      
                    }

                  }else{
                    for(let j=0;j<reportsData[i].series.length;j++){                  
                      for (let prop in reportsData[i].series[j]) {                    
                        if (reportsData[i].series[0].hasOwnProperty(prop)) {
                          if(Number(reportsData[i].series[0][prop]) > 0){
                            var newArray = [];
                            newArray[0] = prop;
                            newArray[1] = Number(reportsData[i].series[0][prop]);
                            seriesArr.push(newArray)
                          }                          
                        }
                      }                  
                    }
                    //console.log("seriesArr pyramid", seriesArr);
                    seriesArr.sort(function(a, b) { 
                      return a[1] < b[1] ? 1 : -1;
                    });
                    //console.log("seriesArr pyramid", seriesArr);
                    // chartOptions.series = [
                    //   {
                    //     colorByPoint: true,
                    //     data: seriesArr
                    //   }
                    // ];
                    //console.log("2 ", chartOptions);
                    if(chartOptions.hasOwnProperty('series')){
                      chartOptions.series[0].data = seriesArr;
                    }else{
                      chartOptions.series = [
                        {
                          colorByPoint: true,
                          data: seriesArr
                        }
                      ];
                    }                  

                  }                  

                  //console.log("seriesArr ", seriesArr);                  
                }                
                
              }else if(reportsData[i].is_drilldown == 1){
                //console.log("Drill down ", chartOptions);
                //console.log("reportsData[i] ",reportsData[i].drilldown);
                for(let j=0;j<reportsData[i].series.length;j++){
                  for (let prop in reportsData[i].series[j]) {
                    if (reportsData[i].series[0].hasOwnProperty(prop)) {
                      if(Number(reportsData[i].series[0][prop]) > 0){
                        let innerObj = {
                          name:'',
                          y:Number(''),
                          drilldown:''
                        };
                        //let itemVal = Number(reportsData[i].series[0][prop]).toFixed(1);
                        //console.log("Number(reportsData[i].series[0][prop]) ",itemVal);
                        innerObj.name = prop;
                        innerObj.y = Number(reportsData[i].series[0][prop]);
                        let drilldownSeriesData = reportsData[i].drilldown.series;
                        for(let x=0;x<drilldownSeriesData.length;x++){
                          if(drilldownSeriesData[x].name == prop && drilldownSeriesData[x].id == prop){
                            innerObj.drilldown = prop;
                          }
                        }
                        seriesArr.push(innerObj);
                        //console.log("seriesArr ", seriesArr);
                      }
                    }
                      
                  }
                }
                //console.log("1 ", chartOptions);
                if(reportsData[i].conf[0].report_type != 'combination'){
                  if(chartOptions.hasOwnProperty('series')){
                    chartOptions.series[0].data = seriesArr;
                  }else{
                    chartOptions.series = [
                      {
                        colorByPoint: true,
                        data: seriesArr
                      }
                    ];
                  }
                  
                  let drilldownSeriesData = reportsData[i].drilldown.series;
                  
                  for(let x=0;x<drilldownSeriesData.length;x++){
                    //let drillData = drilldownSeriesData[x].data;
                    let drillOptionsData =[]; 
                    //console.log("drilldownSeriesData[x] ", drilldownSeriesData[x]);
                    if(drilldownSeriesData[x].has_child == 1){
                      drilldownSeriesData[x]['keys'] =['name', 'y', 'drilldown'];
                    }                                      

                    if(drilldownSeriesData[x].hasOwnProperty('drill_report_type')){
                      if(drilldownSeriesData[x].drill_report_type != null && drilldownSeriesData[x].drill_report_type != ''){
                        drilldownSeriesData[x].type = drilldownSeriesData[x].drill_report_type;
                        //Object.assign(chartOptions.drilldown.series[x], {type: "value"})
                        //console.log("chartOptions.drilldown.series[x]. ", chartOptions.drilldown.series[x]);
                        //chartOptions.drilldown.series[x].type = drilldownSeriesData[x].drill_report_type;
                      }
                    } 

                  }     
                  if(reportsData[i].reportId == 102){
                    if(drilldownSeriesData[0].hasOwnProperty('data')){
                      for(let x=0;x<drilldownSeriesData[0].data.length;x++){
                        if(drilldownSeriesData[0].data[x][2] == 'Above 15 years'){
                          drilldownSeriesData[0].data[x][2] = '';
                        }
                      }
                    }
                  }
                  if(reportsData[i].reportId == 118){
                    if(drilldownSeriesData[1].hasOwnProperty('data')){
                      for(let x=0;x<drilldownSeriesData[1].data.length;x++){
                        if(drilldownSeriesData[1].data[x][2] == 'No'){
                          drilldownSeriesData[1].data[x][2] = '';
                        }
                      }
                    }
                  }
                }else{
                  if(chartOptions.hasOwnProperty('xAxis')){
                    chartOptions.xAxis.categories = reportsData[i].series.categories;
                  }
                  if(reportsData[i].series.categories.length > 0){
                    let seriesData= reportsData[i].series.series;
                    //console.log("seriesData ", seriesData);
                    for(let i=0;i<seriesData.length;i++){
                      if(seriesData[i].type == 'pie'){
                        seriesData[i].center = [20, 0];
                        seriesData[i].size = 50;
                        seriesData[i].showInLegend = false;
                        seriesData[i].dataLabels ={
                          enabled: false
                        }
                        seriesData[i].name = "Farmers";
                      }else{
                        seriesData[i].name = "Farmers";
                      }
                    }

                    chartOptions.series = reportsData[i].series.series; 
                  }else{
                    chartOptions.series = [];                    
                    //chartOptions.labels.items[0].html = '';
                    if(chartOptions.hasOwnProperty('labels')){                      
                      chartOptions.labels.items[0].html = '';                                           
                    }                    
                  }                                 

                }

              }
                  
              //console.log(" seriesArr ", seriesArr)
              //for(let j=0;j < reportsData[i].conf[0].length; j++){
                //console.log(" reportsData[i].conf ", reportsData[i].conf[0].conf);              
                
                
                // chartOptions.chart.width = 320;
                //chartOptions.chart.height = "100%";
                //chartOptions.chart_type = chartOptions.chart.type;
                if(chartOptions.chart.hasOwnProperty('type')){
                  chartOptions.chart_type = chartOptions.chart.type;                 
                }else{
                  if(chartOptions.chart.hasOwnProperty('charttype')){
                    chartOptions.chart_type = chartOptions.chart.charttype;
                  }                  
                }
                chartOptions.title.style= {
                  fontSize: "16px",
                  fontWeight: '600'
                };       
                chartOptions.reportId = reportsData[i].reportId; 
                if(reportsData[i].is_drilldown == 1){
                  chartOptions.drilldown = reportsData[i].drilldown;
                  //chartOptions.drilldown.drillUpButton = {"position":{"verticalAlign":"top"}};

                  //added by Kiran for drillup button
                  let drillUp = {
                    relativeTo: 'spacingBox',
                    position: {
                      align: 'left',
                      y: -10,
                      x: 0
                    },
                    theme: {
                      fill: '#63775c',
                      'stroke-width': 1,
                      r: 25,
                      states: {
                        hover: {
                          fill: '#63775c'
                        },
                        select: {
                          fill: '#63775c'
                        }
                      }
                    }
              
                  }                  
                  chartOptions.drilldown.drillUpButton = drillUp;
                  if(chartOptions.hasOwnProperty('lang')){
                    chartOptions.lang.drillUpText = '⇦';
                  } else {
                    let drillText = {
                      drillUpText : '⇦'
                    }
                    chartOptions.lang = drillText;
                  }

                  //var defaultTitleXAxis = "Percentage Range";
                  //var defaultTitleYAxis = "Avg age of children";
                  var defaultTitleYAxis = "";
                  //var drilldownTitleXAxis = "School Name";
                  //var drilldownTitleYAxis = "Count of children";
                  var drilldownTitleYAxis = "";  
                  //var drillDownTitle = "Hello";
                  //var defaultTitle = "";
                  if(chartOptions.hasOwnProperty('yAxis')){
                    if(Array.isArray(chartOptions.yAxis)){
                      defaultTitleYAxis = chartOptions.yAxis[0].title.text;
                      //console.log("defaultTitleYAxis ", defaultTitleYAxis);
                    }else{
                      if(chartOptions.yAxis.hasOwnProperty('title')){
                        defaultTitleYAxis = chartOptions.yAxis.title.text;
                        //console.log("defaultTitleYAxis ", defaultTitleYAxis);
                      }
                    }                   
                  }

                  // if(chartOptions.hasOwnProperty('title')){
                  //   defaultTitle = chartOptions.title.text;
                  // }

                  // if(chartOptions.hasOwnProperty('yAxisDrilldownTitle')){
                  //   drilldownTitleYAxis = chartOptions.yAxisDrilldownTitle.text;
                  // }
                  // console.log('drilldownTitleYAxis', drilldownTitleYAxis)
                  chartOptions.chart.events= {
                    drilldown: function(e) {
                      // this.setTitle({text: e.seriesOptions.name}, {text: ''});
                      // console.log(e.seriesOptions.id);
                      let thisChart = this.yAxis[0].chart.options;           
                      if(thisChart.hasOwnProperty('yAxis')){
                        if(Array.isArray(thisChart.yAxis)){
                          if(thisChart.yAxis[0].hasOwnProperty('title')){
                            this.oldYaxisTitle = thisChart.yAxis[0].title.text;
                          }
                        }else{
                          if(thisChart.yAxis.hasOwnProperty('title')){
                            this.oldYaxisTitle = thisChart.yAxis.title.text;
                          }
                        }                   
                      }
                      if(thisChart.hasOwnProperty('yAxisDrilldownTitle')){
                        if(this.hasOwnProperty('yAxis')){
                          if(Array.isArray(this.yAxis)){
                            this.yAxis[0].setTitle({ text: thisChart.yAxisDrilldownTitle.text});
                          }else{
                            if(this.yAxis.hasOwnProperty('title')){
                              this.yAxis.setTitle({ text: thisChart.yAxisDrilldownTitle.text});
                            }
                          }                   
                        }
                      }

                      var curConfig = this.options;
                      var curTitle = curConfig.title.text;
                      if(curConfig.hasOwnProperty('drilltitle1')){
                        if(curTitle == curConfig.drilltitle1.text){
                          if(curConfig.hasOwnProperty('drilltitle2')){
                            this.setTitle({text: curConfig.drilltitle2.text}, {text: ''});
                            if(reportsData[i].reportId == 116){
                              this.yAxis[0].setTitle({ text: "No. of responses"});
                            }
                          }
                        } else {
                          this.oldTitle = curTitle;
                          this.oldSubtitle = curConfig.subtitle.text;
                          if(curConfig.hasOwnProperty('drilltitle2')){                        
                            this.setTitle({text: curConfig.drilltitle1.text}, {text: 'Drilldown for details'});
                          } else { 
                            this.setTitle({text: curConfig.drilltitle1.text}, {text: ''});
                          }
                          if(reportsData[i].reportId == 116){
                            this.yAxis[0].setTitle({ text: "Average age"});
                          }
                        }
                      } 

                      
                      //this.xAxis[0].setTitle({ text: drilldownTitleXAxis });
                      // this.yAxis[0].setTitle({ text: drilldownTitleYAxis });
                      // console.log("drilldownTitleYAxis ", drilldownTitleYAxis);
                      //this.setTitle({ text: drillDownTitle });
                    },
                    drillup: function(e) {
                      //console.log(this);
                      if(this.hasOwnProperty('yAxis')){
                        if(Array.isArray(this.yAxis)){
                          this.yAxis[0].setTitle({ text: this.oldYaxisTitle});
                        }else{
                          if(this.yAxis.hasOwnProperty('title')){
                            this.yAxis.setTitle({ text: this.oldYaxisTitle});
                          }
                        }                   
                      }
                      
                      var curConfig = this.options;
                      var curTitle = curConfig.title.text;
                      if(curConfig.hasOwnProperty('drilltitle1')){
                        if(curTitle == curConfig.drilltitle1.text){
                          this.setTitle({text: this.oldTitle}, {text: this.oldSubtitle});
                          if(reportsData[i].reportId == 116){
                            this.yAxis[0].setTitle({ text: "No. of children"});
                          }
                        } else {                        
                          this.setTitle({text: curConfig.drilltitle1.text}, {text: 'Drilldown for details'});
                          if(reportsData[i].reportId == 116){
                            this.yAxis[0].setTitle({ text: "Average age"});
                          }
                        }
                      } 
                        //this.xAxis[0].setTitle({ text: defaultTitleXAxis });
                        //this.yAxis[0].setTitle({ text: defaultTitleYAxis });
                        //console.log("defaultTitleYAxis ", defaultTitleYAxis);
                        //this.setTitle({ text: defaultTitle });
                    }
                  }
                }
                //console.log('chartOptions, ', chartOptions)
                if(chartOptions.chart.type == 'pie'){
                  
                  if(chartOptions.hasOwnProperty("plotOptions")){
                    if(chartOptions.plotOptions.hasOwnProperty("pie")){
                      chartOptions.plotOptions.pie.dataLabels ={
                        enabled: true,
                        format: '{point.percentage:.1f} %',
                        distance: -20,
                        color:'#fafafa'
                      }
                      chartOptions.plotOptions.pie.showInLegend= true;
                    }
                  }
                  
                  chartOptions.legend = {
                    enabled: true
                  }
                }
                if(chartOptions.chart.type == 'pyramid'){
                  if(chartOptions.hasOwnProperty("plotOptions")){
                    if(chartOptions.plotOptions.hasOwnProperty("series")){
                      chartOptions.plotOptions.series.dataLabels ={
                        enabled: true,
                        format: '{point.y:,.0f}',
                        softConnector: true,
                        distance: -25,
                        color:'#fafafa'
                      }
                      chartOptions.plotOptions.series.width = '100%';
                    }
                  }
                  chartOptions.title.x = 0;
                }     
                
                
                if(chartOptions.chart.hasOwnProperty("charttype")){
                  //console.log("chartOptions.chart.charttype ", chartOptions.chart.charttype);
                  if(chartOptions.chart.charttype == 'circle'){
                    seriesArr = [];
                    for(let j=0;j<reportsData[i].series.length;j++){ 
                      for (let prop in reportsData[i].series[j]) {  
                        if (reportsData[i].series[0].hasOwnProperty(prop)) {
                          if(reportsData[i].series[0][prop] != null){
                            //if(Number(reportsData[i].series[0][prop]) > 0){
                              let innerObj = {
                                name:'Farmers',
                                y:Number(''),
                                color: '#50C1BF'
                              };
                              //innerObj.name = prop;
                              //console.log("reportsData[i].series[0][prop] ", reportsData[i].series[0][prop]);
                              let x = reportsData[i].series[0][prop].split('/');
                              //console.log("x ", x);
                              innerObj.y = Number(x[0]);
                              seriesArr.push(innerObj)
                           // } 
                          }                                                   
                        }
                      }                  
                    }

                    chartOptions.series[0].data = seriesArr;
                  }
                }

                if(reportsData[i].conf[0].report_type == 'column_placement'){
                  seriesArr = [];
                  //console.log("reportsData[i] ", reportsData[i]);
                  delete chartOptions.xAxis.type;
                  chartOptions.xAxis.categories = reportsData[i].series.categories;
                 
                  //console.log("reportsData[i].series ", reportsData[i].series);
                  //console.log("reportsData[i].series ", reportsData[i].series.series);

                  let seriesData = reportsData[i].series.series;
                  chartOptions.series = [];
                  //console
                  let colorsDataObject =[
                    {
                      name: '',
                      color: 'rgba(165,170,217,1)',
                      data: [],
                      pointPadding: 0.3,
                      pointPlacement: -0.2
                    }, {
                        name: '',
                        color: 'rgba(126,86,134,.9)',
                        data: [],
                        pointPadding: 0.4,
                        pointPlacement: -0.2
                    }, {
                        name: '',
                        color: 'rgba(248,161,63,1)',
                        data: [],
                        pointPadding: 0.3,
                        pointPlacement: 0.2,
                        yAxis: 1
                    }, {
                        name: '',
                        color: 'rgba(186,60,61,.9)',
                        data: [],
                        pointPadding: 0.4,
                        pointPlacement: 0.2,
                        yAxis: 1
                    }
                  ];
                  for(let k=0;k<seriesData.length;k++){
                    //console.log("seriesData.name ", seriesData[k].name);
                    //console.log("seriesData.name ", seriesData[k].data);
                    // for(let j=0; j < chartOptions.series.length;j++){
                      //for(let x=0;x<colorsDataObject.length;x++){
                        colorsDataObject[k].name = seriesData[k].name;
                        colorsDataObject[k].data = seriesData[k].data;
                      //}
                      //old code 
                      //chartOptions.series[k].name = seriesData[k].name;
                      //chartOptions.series[k].data = seriesData[k].data;
                    // }
                    
                  }
                  //console.log("colorsDataObject ", colorsDataObject);

                  chartOptions.series = colorsDataObject;

                  //console.log(" column placement ", chartOptions);
                  
                }

                if(reportsData[i].conf[0].report_type == 'column-stacked'){                  
                  seriesArr = [];
                  if(chartOptions.xAxis.hasOwnProperty('type')){
                    delete chartOptions.xAxis.type;
                  }
                  chartOptions.xAxis.categories = reportsData[i].series.categories;
                  let seriesData = reportsData[i].series.series;
                  for(let k=0;k<seriesData.length;k++){
                  //     chartOptions.series[k].name = seriesData[k].name;
                  //     chartOptions.series[k].data = seriesData[k].data;  
                    seriesData[k].data = seriesData[k].data.map(function(val, i) {
                      return val === 0 ? null : val;
                    });
                    chartOptions.series.push(seriesData[k]);                  
                  }
                  //console.log(" column placement ", chartOptions);
                }
                

                chartOptions.reportTypeFrontend = currentType;
                chartOptions.chart.backgroundColor= '#fafafa';
                chartOptions.chart.spacingTop = 20;
                //chartOptions.chart.spacingBottom = 20;

                //added by Kiran for currency on zAxis
                if(reportsData[i].reportId == 93 || reportsData[i].reportId == 87 || reportsData[i].reportId == 135){
                  if(reportsData[i].hasOwnProperty('currency')){
                    chartOptions.yAxis[1].title.text = chartOptions.yAxis[1].title.text + ' (' + reportsData[i].currency + ')';
                    if(reportsData[i].reportId == 87){
                      chartOptions.yAxis[0].title.text = chartOptions.yAxis[0].title.text + ' (' + reportsData[i].currency + ')';  
                    }
                  }            
                }
                if(reportsData[i].reportId == 49){
                  if(reportsData[i].hasOwnProperty('currency')){
                    chartOptions.yAxis.title.text = chartOptions.yAxis.title.text + ' (' + reportsData[i].currency + ')';                  
                  }
                }
                // if(reportsData[i].reportId == 102){
                //   var UNDF;
                //   if(chartOptions.hasOwnProperty('tooltip')){
                //     chartOptions.tooltip = {
                //       headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                //       pointFormatter: function () {
                //         if (this.series.chart.drilldownLevels !== UNDF && this.series.chart.drilldownLevels.length > 1) {
                //             return '<span style="color:' + this.color + '">' + this.name + '</span>: <b>' + this.percentage.toFixed(1) + '%</b><br/>';
                //         } else {
                //             return '<span style="color:' + this.color + '">' + this.name + '</span>: <b>' + this.y.toFixed(1) + '%</b><br/>';
                //         }
                //       }
                //     }
                //   }
                // }
                //console.log("reportsData[i].conf ",reportsData[i].conf);
                if(reportsData[i].conf.length > 1){
                  contextMenuOptions = [
                    'viewFullscreen',
                    // {
                    //   text: 'Change chart type ',
                    //   onclick: function(this,event){
                    //     // event.preventDefault();
                    //     //console.log("this ",this);
                    //     // console.log(this.userOptions.exporting.buttons.contextButton.menuItems[0].name);
                    //     // let item = this.userOptions.exporting.buttons.contextButton.menuItems[0];
                    //     // console.log(item);
                    //     // console.log("selectedChartType ", this.selectedChartType);
                    //     // event.stopPropagation();
                    // //     let el = `<div class="dropdown-content">
                    // //     <a href="#">Link 1</a>
                    // //     <a href="#">Link 2</a>  
                    // // </div> `;
                    // //     let newElement01 = document.querySelector('.highcharts-menu div:first-child');
                    // //     let elementOne = document.createElement('div');
                    // //     elementOne.innerHTML =`<div class="dropdown-content">
                    // //     <a href="#">Link 1</a>
                    // //     <a href="#">Link 2</a>  
                    // // </div>`; 
                    // //     //this.renderer.appendChild(el);
                    // //     newElement01.appendChild(elementOne);
                    //     let self = this;
                    //     parentScope01.changeChartType(self);
                    //   }
                    //   },
                      {
                      textKey: 'downloadPDF',
                      onclick: function () {
                        this.exportChart({
                          type: 'application/pdf',
                          filename: reportsData[i].name
                        });
                      }
                      },
                      {
                      textKey: 'downloadPNG',
                      onclick: function () {
                        this.exportChart();
                      }
                    },
                    {
                      textKey: 'downloadXLS',                      
                      onclick: function () {
                        this.downloadXLS();
                      }
                    },
                    {
                      text: 'Remove',
                      onclick: function(this){
                      let self = this;
                      parentScope01.RemoveChart(self);
                      }                  
                    },
                    // {
                    //   textKey: 'viewData',
                    //   text: 'Toggle data table',
                    //   onclick: function (this) {
                    //     if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                    //       this.dataTableDiv.style.display = 'none';
                    //     } else {
                    //       this.viewData();
                    //       this.dataTableDiv.style.display = '';
                    //     }
                    //   }                  
                    // }
                  ];
                }else{
                  contextMenuOptions = [   
                    'viewFullscreen',                
                      {
                      textKey: 'downloadPDF',
                      onclick: function () {
                        this.exportChart({
                          type: 'application/pdf',
                          filename: reportsData[i].name
                        });
                      }
                      },
                      {
                      textKey: 'downloadPNG',
                      onclick: function () {
                        this.exportChart();
                      }
                    },
                    {
                      textKey: 'downloadXLS',
                      onclick: function () {
                        this.downloadXLS();
                      }
                    },
                    {
                      text: 'Remove',
                      onclick: function(this){
                      let self = this;
                      parentScope01.RemoveChart(self);
                      }                  
                    }
                    // {
                    //   textKey: 'viewData',
                    //   text: 'Toggle data table',
                    //   onclick: function (this) {
                    //     if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                    //       this.dataTableDiv.style.display = 'none';
                    //     } else {
                    //       this.viewData();
                    //       this.dataTableDiv.style.display = '';
                    //     }
                    //   }                  
                    // }
                  ];
                }
                //console.log("contextMenuOptions ", contextMenuOptions);
                chartOptions.exporting.buttons.contextButton.menuItems = contextMenuOptions;
                chartOptions.exporting.filename = reportsData[i].name;
                //chartOptions.exporting.buttons.contextButton.className ='highcharts-button-normal';
                // chartOptions.legend = {
                //   enabled: falseS
                // };
                // chartOptions.exporting.menuItemDefinitions= {
            		// 	viewFullscreen: {
                // 		onclick: function () {
                //       //this.fullscreen = new Highcharts.FullScreen(this.container);
                //       Highcharts.FullScreen.init(this.renderTo);
                //     	console.log('Test')
                // 		}
            		// 	}
                // }
                
                //added bt KIran for export button position 
                chartOptions.navigation = {
                    buttonOptions: {
                        verticalAlign: 'top',
                        y: -5
                    }
                }
                //console.log("chartOptions ", chartOptions);
                //console.log(" json sting ", JSON.stringify(chartOptions));
                //console.log("chartOptions.series ", chartOptions.series);
                chartsList.push(chartOptions);
                
              //}
    
            }

          }else{

            if(this.existingSelectedReports && this.existingSelectedReports.length == 0){
              this.chartElement01.nativeElement.innerHTML = '';
              this.hcRService.allCharts = [];
            }
            
          }
  
          if(chartsList && chartsList.length > 0){
                        
              for(let i=0;i<chartsList.length;i++){
                let e = document.createElement("div");
                let newElement = this.chartElement01.nativeElement.appendChild(e);
                if(colList[i] == 2){
                  this.renderer.addClass(newElement, 'col-md-8');
                  this.renderer.addClass(newElement, 'col-lg-8');
                }else{
                  this.renderer.addClass(newElement, 'col-md-4');
                  this.renderer.addClass(newElement, 'col-lg-4');
                }
                
                this.renderer.addClass(newElement, 'pl-0');
                this.renderer.addClass(newElement, 'pr-0');
                let f = document.createElement("div");
                let newElement1 = newElement.appendChild(f);
                this.renderer.addClass(newElement1, 'chartBox-pdmr');
                //Added by Kiran to replace math.random to windows.crypto
                let array = new Uint32Array(1);
                window.crypto.getRandomValues(array);                
                newElement1.setAttribute('id',+array[0].toString().slice(2, 6));
                this.hcRService.createChart(newElement1, chartsList[i]); 
                this.exportBtns = true;
                //var el = document.getElementById('test');
                //var newEl = document.createElement('button');
                //el.insertAdjacentHTML('afterend', '<button id="two">two</button>');
              }
              
              for(let i=0;i<chartsList.length;i++){
                //chartsList[i].exporting.buttons.contextButton.menuItems = contextMenuOptions; 
                let e = document.createElement("div");
                let newElement = this.chartElement02.nativeElement.appendChild(e);
                // this.renderer.addClass(newElement, 'chartbox');
                // this.renderer.addClass(newElement, 'chartbox-add');
                this.renderer.setStyle(newElement, 'width','48%');
                this.renderer.setStyle(newElement, 'display','inline-block');
                this.renderer.setStyle(newElement, 'padding','1%');
                //this.renderer.setStyle(newElement, 'margin','0.6%');
                //this.renderer.addClass(newElement, 'col-lg-4');
                this.renderer.addClass(newElement, 'chartBox-pdmr');
                //Added by Kiran to replace math.random to windows.crypto
                let array = new Uint32Array(1);
                window.crypto.getRandomValues(array);                
                newElement.setAttribute('id',+array[0].toString().slice(2, 6));
                //this.hcRService.createChart(newElement, chartsList[i]);
                let newVal = Highcharts.chart(newElement, chartsList[i]);
                
                this.localChartsList.push(newVal);
                //this.exportBtns = true;
                //var el = document.getElementById('test');
                //var newEl = document.createElement('button');
                //el.insertAdjacentHTML('afterend', '<button id="two">two</button>');
              }
              if(currentType == 'predefined'){                
                this.profilingFlag = true;
              }else{
                //this.exportBtns = true;
                this.customFlag = true;
              }
              
            // }else{
            //   //this.chartElement02.nativeElement.innerHTML = '';
            //   //this.hcRService.allCharts = [];
            //   for(let i=0;i<chartsList.length;i++){
            //     let e = document.createElement("div");
            //     let newElement = this.chartElement02.nativeElement.appendChild(e);
            //     this.renderer.addClass(newElement, 'chartbox');
            //     this.renderer.addClass(newElement, 'chartbox-add');
            //     this.renderer.addClass(newElement, 'col-lg-6');
            //     newElement.setAttribute('id',+Math.random().toString().slice(2, 6));       
            //     this.hcRService.createChart(newElement, chartsList[i]);
            //     this.exportBtns = true;
            //     this.customFlag = true;
            //   }
            // }
            this.checkChartsAvailable = this.hcRService.allCharts;
            //this.selectedReportsMultiSelect = [];
            //this.selectedReportsMultiSelectCustom = [];
            //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
          }
  
        }else{
        //this.existingSelectedReports = [];
        }
        
      });
    }else{
      this.toastrService.info(this.noNewReportsSelected);
    }       
    
  }  

  reportTypeChange(typeOfReport){
     //console.log(typeOfReport);     
    // if(typeOfReport == 'self'){
    //   //this.toastrService.info("Reports not available");    
    //   if(this.customFlag){
    //     this.exportBtns = true;
    //   }else{
    //     this.exportBtns = false;
    //   }      
    // }else if(typeOfReport == 'predefined'){
    //   if(this.profilingFlag){
    //     this.exportBtns = true;
    //   }else{
    //     this.exportBtns = false;
    //   } 
    // }
    if(this.hcRService.allCharts.length > 0){
      this.exportBtns = true;
    }

  }

  cancel(){
    let url="charts/"+ this.projectId;
    this.router.navigate([url]);
  }

  async downloadAsPDF(){
    //debugger;
    //let charts = this.hcRService.allCharts;
    let charts = this.localChartsList;
    Highcharts.exportCharts(charts,{
      filename:'P2F-Roots-reports',
      type: 'application/pdf'
    });   
  }

  RemoveChart(itemScope){
    //console.log(itemScope);
    parentScope01.removeFromList(itemScope.id,itemScope.userOptions.reportId,itemScope);
    itemScope.renderTo.remove();    
    //self.renderTo.remove();
    //self.destory();
    //self.remove();    
  }

  removeFromList(item,reportId, currentItem){
    let charts = this.hcRService.allCharts;
    let profilingReports;
    let customReports;
    //let newCharts = charts.filter(item1  => item1.id != item);
    let newCharts = charts.filter(item1  => item1.reportId != reportId);
    this.hcRService.allCharts = newCharts;
    this.localChartsList = newCharts;
    this.existingSelectedReports = this.existingSelectedReports.filter(item3 => item3 != reportId);
    profilingReports = newCharts;
    customReports = newCharts;
    if(this.reportTypes == 'predefined'){
      this.selectedReportsMultiSelect = this.selectedReportsMultiSelect.filter(item2 => item2.id != reportId);  
      profilingReports = profilingReports.filter(item3 => item3.reportTypeFrontend == currentItem.reportTypeFrontend);

    }else{
      this.selectedReportsMultiSelectCustom = this.selectedReportsMultiSelectCustom.filter(item2 => item2.id != reportId);
      customReports = customReports.filter(item3 => item3.reportTypeFrontend == currentItem.reportTypeFrontend);
    }
    this.checkChartsAvailable = this.hcRService.allCharts;
    if(this.hcRService.allCharts.length == 0){
      this.profilingFlag = false;
      this.customFlag = false;
    }
    if(profilingReports && profilingReports.length > 0){
      this.profilingFlag = true;
    }else{
      this.profilingFlag = false;
    }
    if(customReports && customReports.length > 0){
      this.customFlag = true;
    }else{
      this.customFlag = false;
    }
  }

  async printCharts(){
    //debugger;
    let charts = this.localChartsList;
    const getDiv = document.getElementById('test1');
    var mywindow = window.open('', 'PRINT', 'height=600,width=900');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById('test1').innerHTML);
    mywindow.document.write('</body></html>');
    var x = mywindow.document.querySelectorAll(".chartBox-pdmr");
    var y = [];
    Highcharts.each(charts, function(chart) {
      chart.reflow();
    })
    //console.log(y);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
    // console.log("this.chartElement01.nativeElement ", this.chartElement01.nativeElement);
    // let chart = this.chartElement01.nativeElement.innerHTML;
    // Highcharts.exportCharts(charts,{
    //   //this.print();
    // }); 
    //let newCharts  = Highcharts.chart(charts);
    //charts[0].print();
    //console.log("chart ", chart);
    //chart.print();
    //this.initiatePrintForAll();
  }
  initiatePrintForAll(): void {
    // Find tht button that has the ngx-print tag & after a timeout, click it. Without a timeout the data is not set when the print fn is called
    
      //var idPost=document.getElementById("printReportsBtn").innerHTML;
      // if(document){
      //   let btn = document.getElementById('printReportsBtn');
      //   setTimeout(() => btn.click(), 1000);
      // }        
    
  }

  trackBySectionName(index, dataItem) {
    return dataItem.section;
  }
  trackBySubsectionName(index, dataItem) {
    return dataItem.name;
  } 
  
  clearAllCharts(){
    //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
    this.chartElement01.nativeElement.innerHTML = '';
    this.chartElement02.nativeElement.innerHTML = '';
    this.hcRService.allCharts = [];
    this.localChartsList = [];
    //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
    this.selectedReportsMultiSelect = [];
    this.selectedReportsMultiSelectCustom = [];
    this.exportBtns = false;
    this.reportAvailableFlag = false;
    this.existingSelectedReports = [];
    // this.dashboardAddObject ={
    //   "section":0,
    //   "subsection":'',
    //   "reportID":[]
    // }
    //this.runReportForm.submitted =false;
    //this.subsectionFlag = true;
  }
  
  onSelectAllChart(ele){
    document.getElementById('Chart').click();
  }

}
