import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  surveyURL = this.appConfigService.getUrl(this.appConfigService.SURVEY);
  SURVEY_NQ = this.appConfigService.getUrl(this.appConfigService.SURVEY_NQ);

  constructor(private http: HttpClient, private appConfigService: AppConfigService) { }


  getAllSurveysForProject(projectId: number): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getAllSurveysForProject/${projectId}`);
  }

  createSurvey(projectId: number, type: string): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/createSurvey/${projectId}/${type}`);
  }

  checkSurveyQuestionnaireExists(surveyId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/checkSurveyQuestionnaireExists/${surveyId}`);
  }

  createQuestionnaireForSurvey(surveyId, templateId, body): Observable<any> {
    return this.http.post<any>(`${this.surveyURL}/createQuestionnaireForSurvey/${surveyId}/${templateId}`, body);
  }

  getSurveyQuestionnaire(surveyId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getSurveyQuestionnaire/${surveyId}`);
  }

  getSurveyQuestionnaireForProject(projectId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getSurveyQuestionnaireForProject/${projectId}`);
  }

  activateSurvey(surveyId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/activateSurvey/${surveyId}`);
  }

  getSurvey(surveyId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getSurvey/${surveyId}`);
  }

  saveAsTemplate(surveyId, templateDetails): Observable<any> {
    return this.http.post<any>(`${this.surveyURL}/saveAsTemplate`, {surveyId: surveyId, templateDetails: templateDetails});
  }

  getTemplateQuestionnaire(templateId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getTemplateQuestionnaire/${templateId}`);
  }

  getAssociatedTemplates(surveyId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getAssociatedTemplates/${surveyId}`);
  }

  saveQuestionnaireChanges(surveyId, changedAssets): Observable<any> {
    return this.http.post<any>(`${this.surveyURL}/saveQuestionnaireChanges/${surveyId}`, changedAssets);
  }

  closeSurvey(surveyId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/closeSurvey/${surveyId}`);
  }

  getEcosystemTemplates(): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getEcosystemTemplates`);
  }

  retrofitAnswerData(): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/retrofitAnswerData/25`);
  }

  savePercentageCompleted(farmerId, surveyId, denominator, numerator) : Observable<any> {
    return this.http.post<any>(`${this.surveyURL}/savePercentageCompleted`, {farmerId: farmerId, surveyId: surveyId, total_questions: denominator, answered_questions: numerator});
  }

  getRevisionsForProject(projectId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getRevisionsForProject/${projectId}`);
  }

  getTemplateMetadata(templateId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getTemplateMetadata/${templateId}`);
  }

  getSurveyLanguages(): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getSurveyLanguages`);
  }

  checkLanguageOKForRelease(surveyId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/checkLanguageOKForRelease/${surveyId}`);
  }

  getP2FTemplate(): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getP2FTemplate`);
  }

  saveP2FTemplateChanges(changedAssets): Observable<any> {
    return this.http.post<any>(`${this.surveyURL}/saveP2FTemplateChanges`, changedAssets);
  }

  getQAnswersForFarmerByRevision(farmerId, revisionId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getQAnswersForFarmerByRevision/${farmerId}/${revisionId}`);
  }

  getSurveyQForProfilingService(surveyId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getSurveyQForProfilingService/${surveyId}`);
  }

  getAllSurveysForProjectProfilingService(projectId: number): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getAllSurveysForProjectProfilingService/${projectId}`);
  }

  changeQuestionnaireForSurvey(surveyId, templateId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/changeQuestionnaireForSurvey/${surveyId}/${templateId}`);
  }

  getProfilingTabKeys(projectId, request={}): Observable<any> {
    return this.http.post<any>(`${this.SURVEY_NQ}/getProfilingTabKeys/${projectId}`, request);
  }
  exportFarmerProfilingData(projectId, request): Observable<any> {
    return this.http.post<Blob>(`${this.SURVEY_NQ}/exportFarmerProfilingData/${projectId}`, request, {responseType:'blob' as 'json'});
  }
  createCampaignSurvey(campaignId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/createCampaignSurvey/${campaignId}`);
  }

  getSurveyQuestionnaireForCampaign(campaignId): Observable<any> {
    return this.http.get<any>(`${this.surveyURL}/getSurveyQuestionnaireForCampaign/${campaignId}`);
  }

  saveGrandTotalQuestion(surveyId, changedAssets): Observable<any> {
    return this.http.post<any>(`${this.surveyURL}/createGrandTotalQn/${surveyId}`, changedAssets);
  }
 
  updateSurveyNameBySurveyId(surveyId, body): Observable<any> {
    return this.http.post<any>(`${this.surveyURL}/updateSurveyNameBySurveyId/${surveyId}`, body);
  }
  
  saveSurveyManualDoc(surveyId, file): Observable<any> {
    return this.http.post<any>(`${this.surveyURL}/uploadSurveyManualDoc/${surveyId}`, file);
  }
  
  updateSurveyManualRefWithSection(surveyId, payload): Observable<any> {
    return this.http.post<any>(`${this.surveyURL}/updateSurveyManualRefWithSection/${surveyId}`, payload);
  }
}
