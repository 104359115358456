import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { SurveyService } from '../../services/survey.service';
import { CompareDataService } from '../../services/compare-data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-view-questionnaire',
  templateUrl: './view-questionnaire.component.html',
  styleUrls: ['./view-questionnaire.component.css']
})
export class ViewQuestionnaireComponent implements OnInit {
  projectId = 0;
  project: any;
  surveyId;
  questionnaire = [];
  labelVariable = 'survey_q_asset_labels';
  currentLocaleLanguageIdIndex = 0;  
  userLanguages = [];
  snapshotData:any;
  selectedSnapshot = 0;
  isCompare = false;
  showUpArrow = false;
  notReleasedSurveyInLanguage = false;
  blankQ = false;
  fromCampaign = false;
  campaignId;
  questionnaireText = "Questionnaire";
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly projectService: ProjectService,
    private readonly surveyService: SurveyService,
    private readonly compareDataService: CompareDataService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly translate: TranslateService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {

    if (this.locale && this.locale.length > 2) {
      this.locale = this.locale.substr(0, 2);
    }

    this.route.paramMap.subscribe(params => {
      this.route.queryParamMap.subscribe(queryParams => {
        this.translate.get('questionnaire').subscribe(result => {
          this.questionnaireText = result;
        });
      })
    });
   }

  async ngOnInit() {
    this.projectId = this.route.snapshot.params['id'];
    this.surveyId = this.route.snapshot.params['surveyid'];
    //Added to show questionnaire of target and source snapshot in compare functionality
    this.route.queryParamMap.subscribe(async queryParams => {
      if (queryParams.get('fromCompare') && queryParams.get('fromCompare') === '1') {
        this.isCompare = true;
      }
      if (queryParams.get('campaignId')) {
        this.fromCampaign = true;
        this.campaignId = queryParams.get('campaignId')
      }
    })
    this.projectService.getProjectBasicInfo(this.projectId).subscribe(response =>{
      this.project = response.data;
      const projectObject = this.project.filter(item => String(item.id) === String(this.projectId))[0];
      this.project = projectObject;
    });
    const requests = await Promise.all([
      this.surveyService.getSurveyLanguages().toPromise(),
      this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
    ]);
    if (requests[0]['message']) {
      this.userLanguages = requests[0]['message'];
    }
    if (requests[1]['message']) {
      this.snapshotData = requests[1].message;
    }
    if(this.fromCampaign){
      this.snapshotData = this.snapshotData.filter(item => 
        item.survey_type === 'campaign' && item.survey_stage_changelogs[0].change_type !== 'draft' && item.flex1 == this.campaignId
      );
    } else {
      this.snapshotData = this.snapshotData.filter(item => item.survey_type === 'snapshot' && item.survey_stage_changelogs[0].change_type !== 'draft');
    }
    
    if(this.snapshotData && this.snapshotData.length > 0){
      for(const item of this.snapshotData){
        if(item.revisions.length > 1){
          item.revisions.reverse();
        }
      }
    } 
    this.selectedSnapshot = this.surveyId;
    this.getSnapshotData(this.surveyId)
  }

  // Get the languageId of the current locale
  // getLanguageId() {
  //   if (this.userLanguages && this.userLanguages.length > 0) {
  //     const p = this.userLanguages.find(item => item.locale == this.locale);
  //     if (p) {
  //       return p['id'];
  //     } else {
  //       return undefined;
  //     }
  //   } else {
  //     return undefined;
  //   }
  // }
  async getSnapshotData(snapshotId){
    this.spinner.show();
    const requests = await Promise.all([
      this.surveyService.getSurveyQForProfilingService(snapshotId).toPromise()
    ]);
    if (requests[0]['message'] !== 'notReleasedForLanguage') {
      this.questionnaire = requests[0]['message'];
      for(const tab of this.questionnaire){
        for(const section of tab.sections){
          let totalQuestions = 0;
          if(section.questions.length > 0){
            totalQuestions = section.questions.length;
            for(const q of section.questions){
              let optionTotalLength = 0;
              if(q.options && q.options.length > 0){
                for(const option of q.options){
                  optionTotalLength = optionTotalLength + option.survey_q_asset_labels[0].label.length;
                  if(optionTotalLength > 150){
                    totalQuestions = totalQuestions + 1;
                    optionTotalLength = 0;
                  }
                }
              }
              //console.log('optionTotalLength', optionTotalLength)
            }
          }
          for(const subsection of section.subsections){
            if(subsection.questions.length > 0) {
              totalQuestions = totalQuestions + (subsection.questions.length + 1);
              for(const q of subsection.questions){
                let optionTotalLength = 0;
                if(q.options && q.options.length > 0){
                  for(const option of q.options){
                    optionTotalLength = optionTotalLength + option.survey_q_asset_labels[0].label.length;
                    if(optionTotalLength > 150){
                      totalQuestions = totalQuestions + 1;
                      optionTotalLength = 0;
                    }
                  }
                }
                //console.log('optionTotalLength', optionTotalLength)
              }
            }
          }
          for(const table of section.tables){
            if(table.questions.length > 0){
              totalQuestions = totalQuestions + (table.questions.length + 1);
              for(const q of table.questions){
                let optionTotalLength = 0;
                if(q.options && q.options.length > 0){
                  for(const option of q.options){
                    optionTotalLength = optionTotalLength + option.survey_q_asset_labels[0].label.length;
                    if(optionTotalLength > 150){
                      totalQuestions = totalQuestions + 1;
                      optionTotalLength = 0;
                    }
                  }
                }
                //console.log('optionTotalLength', optionTotalLength)
              }
            }
          }
          const totalQuestionsHeight = totalQuestions > 55 ? '137.8cm' : totalQuestions > 44 ? '136.5cm' : totalQuestions > 33 ? '109.2cm' : totalQuestions > 22 ? '81.9cm' : totalQuestions > 11 ? '54.6cm' : '27.3cm';
          
          section.totalQuestionsHeight = totalQuestionsHeight;
        }
      }
    } else if(requests[0]['message'] === 'notReleasedForLanguage'){
      this.questionnaire = [];
      this.blankQ = true;
      this.notReleasedSurveyInLanguage = true;
    } else {
      this.questionnaire = [];
      this.blankQ = true;
    }
    this.spinner.hide();
  }
  changeSnapshot(){
    window.scrollTo(0, 0);
    this.getSnapshotData(this.selectedSnapshot)
  }
  changeToFirstSnapshot(){
    window.scrollTo(0, 0);
    const firstSnapshotId = this.snapshotData[0].id;
    if(this.selectedSnapshot !== firstSnapshotId){
      this.selectedSnapshot = firstSnapshotId;
      this.getSnapshotData(firstSnapshotId);
    }
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }
  goToReportingManagement(projectID){
    const url = 'charts/' + this.projectId;
    this.router.navigate([url]);
  }
  trackBySourceSnapshot(index, dataItem) {
    return dataItem.section;
  }
  getBackCompareData(){
    const url = 'charts/comparedata/' + this.projectId;
    this.router.navigate([url], { queryParams: { fromQuestion: true} });
  }
  goToSurveyManagement(projectID){
    const url = '/questionnaires/' + this.projectId;
    if(this.fromCampaign){
      this.router.navigate([url], { queryParams: { fromCampaign: true} });
    } else {
      this.router.navigate([url], { queryParams: { fromProject: true}});
    }
  }
  onScroll(item){
    if(document.getElementById('surveyQuestionnaire').scrollTop > 100){
      this.showUpArrow = true;
    } else {
      this.showUpArrow = false;
    }
  }
  scrollToTop() {
    document.getElementById('surveyQuestionnaire').scrollTop = 0; // For Safari
    //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  } 

  async downloadQuestionnaireAsPDF(){        
    
    this.spinner.show();
    const pages = document.querySelectorAll('.tabwise');
    let pdf;
    for(let i = 0; i < pages.length; i++){
      const page = pages[i] as HTMLElement;
      await html2canvas(page).then(function(canvas) {
        var imgData = canvas.toDataURL("image/jpeg", 0.8);
        if(i == 0){
          pdf = new jsPDF({
            unit: 'px',
            format: [parseInt(canvas.style.width) + 165, parseInt(canvas.style.height) + 1000]
          });
          pdf.addImage(imgData, 'JPEG', 10, 10,);
        } else {        
          pdf.addPage([parseInt(canvas.style.width) + 165, parseInt(canvas.style.height) + 1000], "p")
          pdf.addImage(imgData, 'JPEG', 10, 10,);
        }
      });
    }    
    const fileName = `P2F-Roots_${this.project.name}_${this.questionnaireText}`;
    pdf.save(fileName);
    
    this.spinner.hide();
  }
}
