import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';


@Injectable()
export class ProfileService {

  url = this.appConfigService.getUrl(this.appConfigService.PROFILE);
  farmerURL = this.appConfigService.getUrl(this.appConfigService.FARMER);

  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) { }

  GetSnapshot(projectId: number): Observable<any> {
   return this.http.get<any>(`${this.url}/${projectId}`);
  }
}
