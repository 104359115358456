  <div class="bg">
      <div class="login-p2f-logo">
        <img src="assets/images/path2farm-logo-hor-white.webp" alt="path2farm logo"/>
      </div>
      <div class="login-fir-logo login-p2froots-img">
        <img src="assets/images/fir-logo.webp" alt="firmenich logo"/>
      </div>
  </div>


  <div class="login-right">
      <div class="login-p2froots-logo text-right">
        <img class="login-p2froots-img" src="assets/images/p2f-roots-logo.webp" alt="p2f roots logo"/>
        <img class="login-p2froots-img-small" src="assets/images/p2f-roots-logo-white.webp" alt="p2f roots logo"/>
        </div>
      <div class="reset-screen login mt-0 pt-0" style="overflow:inherit;" id="login">
        <div class="login-info">
          <div class="login-info reset-pwd text-center" ng-model="RESET">
            <h3 class="login-header" i18n="@@resetPassword">
              Reset Password
            </h3>
            <div class="reset-body text-center">

              <form name="resetPassForm" (ngSubmit)="resetPassword()" #resetPassForm="ngForm" novalidate  autocomplete="off" method="POST">

                    <div class="form-group">
                        <label for="username" i18n="@@currentPassword">Current Password</label>
                  <input class="form-control-login pl-2 pr-2" i18n-placeholder="@@oldPassword" type="password" value="" name="oldpwd" required
                    maxlength="20" [(ngModel)]="oldpassword" #oldpwd="ngModel" autofocus appAutofocus>
                  <span class="separator"></span>
                </div>
                <div class="form-group">
                  <label for="username" i18n="@@newPassword">New Password</label>
                  <div class="pos-r">
                    <input class="form-control-login pl-2 pr-2" i18n-placeholder="@@newPassword" [type]="showPass ? 'text' : 'password'" value="" name="newpwd" required
                      minlength="12" [(ngModel)]="newpassword" #newpwd="ngModel" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$"
                      (focus)="passChar = !passChar" (blur)="passChar = !passChar">
                    <i class="utilicon utilicon-eye-light show-pass" title="Show Password" i18n-title="@@showPassword" *ngIf="!showPass" (click)="showPass = !showPass"></i>
                    <i class="utilicon utilicon-eye-slash-light show-pass" title="Hide Password" i18n-title="@@hidePassword" *ngIf="showPass" (click)="showPass = !showPass"></i>
                  </div>
                    
                  <div>
                    <label style="color: #004281; float: left; max-width: 175px;" *ngIf="newpassword" i18n="@@passwordStrength">Password Strength</label>
                    <ng2-password-strength-bar *ngIf="newpassword" class="fltRgt"
                    [passwordToCheck]="newpassword"
                    [barColors]="myColors">
                  </ng2-password-strength-bar>
                  </div>
                  <span class="separator"></span>
                  <div class="password-popup" *ngIf="passChar">
                    <div i18n="@@passwordMust">Password must :</div>
                    <ul>
                      <li i18n="@@betweenCharacter">be between 12 and 20 characters</li>
                      <li i18n="@@includeUppercaseCharacter">include an uppercase character</li>
                      <li i18n="@@includeLowercaseCharacter">include a lowercase character</li>
                      <li i18n="@@includeSpecialCharacter">include a special character from @$!%*?&</li>
                      <li i18n="@@includeNumber">include a number</li>
                    </ul>
                  </div>
                </div>
                <div class="form-group">
                    <label for="username" i18n="@@confirmPassword">Confirm Password</label>
                  <input class="form-control-login pl-2 pr-2" i18n-placeholder="@@confirmPassword" type="password" value="" name="cfmpwd"
                    required minlength="12" [(ngModel)]="cfmpassword" #cfmpwd="ngModel">
                  <span class="separator"></span>
                </div>
                <button type="submit" class="btn-login-blue mr-3 inline-block" id="submit" title="Reset" i18n-title="@@reset" [disabled]="resetPassForm.form.invalid" i18n="@@reset">Reset</button>
                <button class="button" class="btn-login-blank inline-block" title="Back" i18n-title="@@back" i18n="@@back" routerLink="/">Back</button>
              </form>
            </div>
            <div class="reset-msg hide">
              <span class="desc" i18n="@@passwordChangedSuccessfully">
                Your password has been changed successfully.
              </span>
              <a routerLink="./" class="btn btn-lg-btn-success btn-block login-btn" i18n="@@login">Login</a>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div class="clearfix"></div>
