import * as tslib_1 from "tslib";
import { Subject, merge, fromEvent } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of } from 'rxjs/observable/of';
import { OnInit, OnDestroy, ElementRef, AfterViewInit, Renderer2, TemplateRef } from '@angular/core';
import { mapTo } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Project, ProjectUOM } from '../../models/project';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { SuppliersService } from '../../services/suppliers.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
var SetupComponent = /** @class */ (function () {
    function SetupComponent(projectService, commonService, router, elementRef, renderer, userService, loc, _route, spinner, toastrService, locale, translate, suppliersService, modalService, localeService, deviceService) {
        var _this = this;
        this.projectService = projectService;
        this.commonService = commonService;
        this.router = router;
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.userService = userService;
        this.loc = loc;
        this._route = _route;
        this.spinner = spinner;
        this.toastrService = toastrService;
        this.locale = locale;
        this.translate = translate;
        this.suppliersService = suppliersService;
        this.modalService = modalService;
        this.localeService = localeService;
        this.deviceService = deviceService;
        this.subject = new Subject();
        this.isOpen = false;
        this.dateFormat = 'DD-MMM-YYYY';
        this.savingProject = false;
        this.projectId = 0;
        this.project = new Project();
        this.products = [];
        this.suppliers = [];
        this.projectStatus = [];
        this.countries = [];
        this.currencyArr = [];
        this.services = [];
        this.serviceComponents = [];
        this.roles = [];
        this.supplierRoles = [];
        this.productTypes = [];
        this.project_manager = [];
        this.partners = [];
        this.masterServiceComponentArray = [];
        this.rowsToShow = [];
        this.units = [];
        this.unittypes = [];
        this.Weight = [];
        this.Length = [];
        this.Volume = [];
        this.Area = [];
        this.partnerServiceMapping = [];
        this.user_type_services_components_mapping = [];
        this.supplier_project_services_components_mapping = [];
        this.currentUser = this.userService.getCurrentUser();
        this.filesToUpload = [];
        this.error = '';
        this.modalDisplay = 'none';
        this.faServices = ['e-Pay', 'Farmer Profiling', 'Geo Mapping', 'Scan ID Card', 'Sourcing & Collection', 'Traceability'];
        this.pmServices = ['Farmer Profiling', 'Print ID Cards', 'Reporting & Analytics', 'Scan ID Card'];
        this.faServicesCode = ['EPAY', 'FMPR', 'GEOM', 'SCID', 'SRCL', 'TRCY'];
        this.pmServicesCode = ['FMPR', 'PRID', 'RPAN', 'SCID'];
        this.showMainSpinner = false;
        this.unsubscribe = new Subject();
        this.partnersArray = [];
        this.servicesArray = [];
        this.componentsArray = [];
        this.checkedObj = {};
        this.allCheckedUserTypes = [];
        this.allCheckedUserTypesServices = [];
        this.allComponentsOfThisServiceSelected = [];
        this.serviceComponentsMapping = [];
        this.unitsMasterArray = [];
        this.projectUnitsMaster = [];
        this.projectUOMObj = {};
        this.mappingsForSupplier = {};
        this.hideSupplierMappingGrid = true;
        this.ecosystemRegionData = [];
        this.allClosed = false;
        this.flagEn = false;
        this.flagEs = false;
        this.flagFr = false;
        this.flagZh = false;
        this.flagTa = false;
        this.flagMg = false;
        this.typeOfAdministration = '';
        this.online$ = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('file_upload_component_default_txt').subscribe(function (result) {
            _this.noFileChosenDefault = result; // default text for the file input component
        });
        this.localeService.use(this.locale);
        var localLang = this.loc.prepareExternalUrl('').replace(/\//g, '');
        if (localLang === 'en') {
            this.flagEn = true;
        }
        else if (localLang === 'es') {
            this.flagEs = true;
        }
        else if (localLang === 'fr') {
            this.flagFr = true;
        }
        else if (localLang === 'zh') {
            this.flagZh = true;
        }
        else if (localLang === 'ta') {
            this.flagTa = true;
        }
        else if (localLang === 'mg') {
            this.flagMg = true;
        }
        else {
            this.flagEn = true;
        }
        this._route.queryParamMap.subscribe(function (queryParams) {
            var qpObject = tslib_1.__assign({}, queryParams.keys, queryParams);
            _this.fromNavId = qpObject['params']['from'];
            //console.log('this.fromNavId', this.fromNavId);
            //navigation changes added
            _this.translate.get('typeAdminTextArr').subscribe(function (val) {
                _this.typeOfAdministrationArr = val;
            }, function (err) { return console.log('err', err); });
            if (_this.fromNavId == 2) {
                _this.typeOfAdministration = _this.typeOfAdministrationArr[0];
            }
            else if (_this.fromNavId == 3) {
                _this.typeOfAdministration = _this.typeOfAdministrationArr[1];
            }
        });
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();
    }
    SetupComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var a, request, i, international_value_string;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scrollToTop();
                        if (!navigator.onLine) {
                            setTimeout(function () {
                                _this.commonService.showToast('warning', 'offline_project_warning', {});
                            });
                            this.router.navigate(['/dashboard']);
                            return [2 /*return*/];
                        }
                        setTimeout(function () { return _this.spinner.show(); }, 0);
                        a = this._route.snapshot.params['id'];
                        this.projectId = a ? +a : 0;
                        if (!(this.projectId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                this.projectService.getProjectServicesMappings(this.projectId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['msg'] !== 'success' || request[1]['msg'] !== 'success' || request[2]['msg'] !== 'success' || request[3]['msg'] !== 'success') {
                            this.commonService.showToast('error', 'generic_error', {});
                        }
                        else {
                            this.project = tslib_1.__assign({}, request[0].data[0], request[1].data[0], request[2].data[0], request[3].data[0]);
                            if (this.project.ProjectUOM) {
                                for (i = 0; i < this.project.ProjectUOM.length; i++) {
                                    international_value_string = this.project.ProjectUOM[i]['international_value'].toString();
                                    international_value_string = international_value_string.replace(/(\.[0-9]*?)0+$/, "$1");
                                    this.project.ProjectUOM[i]['international_value'] = +international_value_string;
                                    this.project.ProjectUOM[i]['name'] = this.project.ProjectUOM[i]['uom'];
                                }
                            }
                            if (this.project['start_date']) {
                                this.project['start_date'] = new Date(this.project['start_date']);
                            }
                            if (this.project['end_date']) {
                                this.project['end_date'] = new Date(this.project['end_date']);
                            }
                            this.project.status_id = this.project.status_id > 0 ? this.project.status_id : 0;
                            this.init();
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.init();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SetupComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.spinner.hide();
        var elements = this.elementRef.nativeElement.querySelectorAll('select[required]');
        setTimeout(function () {
            elements.forEach(function (e) {
                if (e.value === '0') {
                    _this.renderer.addClass(e, 'ng-invalid');
                }
            });
        }, 1000);
    };
    SetupComponent.prototype.init = function () {
        var _this = this;
        if (this.project.id > 0) {
            this.services = this._route.snapshot.data.commonData.services.data;
            this.products = this._route.snapshot.data.commonData.products.data;
            this.countries = this._route.snapshot.data.commonData.countries.data;
            this.projectStatus = this._route.snapshot.data.commonData.status.data;
            this.suppliers = this._route.snapshot.data.commonData.suppliers.data;
            this.productTypes = this._route.snapshot.data.commonData.productType.data;
            this.currencyArr = this._route.snapshot.data.commonData.currency.data;
            this.project_manager = this._route.snapshot.data.commonData.projectmanager.data;
            this.serviceComponents = this._route.snapshot.data.commonData.serviceComponents.data;
            if (this.currentUser.role.id > 2) {
                //Product and supplier autopopulated
                if (this.products[0].id > 0) {
                    this.project.product_id = this.products[0].id;
                }
                else {
                    this.project.product_id = 0;
                }
                if (this.suppliers[0].id > 0) {
                    this.project.supplier_id = this.suppliers[0].id;
                }
                else {
                    this.project.supplier_id = 0;
                }
            }
            //product type auto populated
            if (this.project.product_id) {
                if (this.project.product_id > 0) {
                    var product = this.products.filter(function (item) { return item.id == _this.project.product_id; });
                    if (product && product.length > 0) {
                        var productItem = product[0];
                        if (productItem) {
                            this.project.product_type = productItem.product_type_id;
                        }
                    }
                }
                else {
                    this.project.product_type = 0;
                }
            }
            // Get project data
            if (this.project.id > 0) {
                var _loop_1 = function (i) {
                    this_1.unitsMasterArray[i].unitsInProject = this_1.project.ProjectUOM.filter(function (x) { return x.unit_type_id == _this.unitsMasterArray[i]['unitType'].id; });
                    for (var j = 0; j < this_1.unitsMasterArray[i].unitsInProject.length; j++) {
                        this_1.unitsMasterArray[i].unitsInProject[j]['tmp_id'] = +this_1.commonService.getRandomNum().toString().slice(2, 6);
                    }
                };
                var this_1 = this;
                for (var i = 0; i < this.unitsMasterArray.length; i++) {
                    _loop_1(i);
                }
                this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
                this.setInternationalUnits();
                this.project.product_name = this.products.find(function (x) { return x.id === _this.project.product_id; }).name;
                this.getSupplierMappings(this.project.supplier_id, this.project.product_id);
                var servicesWithoutComponentsTemp = this.supplier_project_services_components_mapping.filter(function (item) { return item.components_id == null; });
                this.servicesWithoutComponents = servicesWithoutComponentsTemp.reduce(function (unique, o) {
                    if (!unique.some(function (obj) { return obj.user_type_id === o.user_type_id || obj.services_id === o.services_id; })) {
                        unique.push(o);
                    }
                    return unique;
                }, []);
            }
            else {
                this.servicesWithoutComponents = [];
                this.user_type_services_components_mapping = [];
                this.addUnitNew();
            }
        }
        else {
            this.commonService.getSuppliersProductsProjectSetup()
                .subscribe(function (result) {
                _this.suppliers = result.data.suppliers;
                _this.products = result.data.products;
                //Product and supplier autopopulated
                if (_this.currentUser.role.id > 2) {
                    if (_this.products[0].id > 0) {
                        _this.project.product_id = _this.products[0].id;
                    }
                    else {
                        _this.project.product_id = 0;
                    }
                    if (_this.suppliers[0].id > 0) {
                        _this.project.supplier_id = _this.suppliers[0].id;
                    }
                    else {
                        _this.project.supplier_id = 0;
                    }
                }
                _this.services = _this._route.snapshot.data.commonData.services.data;
                _this.countries = _this._route.snapshot.data.commonData.countries.data;
                _this.projectStatus = _this._route.snapshot.data.commonData.status.data;
                _this.productTypes = _this._route.snapshot.data.commonData.productType.data;
                _this.currencyArr = _this._route.snapshot.data.commonData.currency.data;
                _this.project_manager = _this._route.snapshot.data.commonData.projectmanager.data;
                _this.serviceComponents = _this._route.snapshot.data.commonData.serviceComponents.data;
                //product type autopopulated
                if (_this.project.product_id) {
                    if (_this.project.product_id > 0) {
                        var product = _this.products.filter(function (item) { return item.id == _this.project.product_id; });
                        if (product && product.length > 0) {
                            var productItem = product[0];
                            if (productItem) {
                                _this.project.product_type = productItem.product_type_id;
                            }
                        }
                    }
                    else {
                        _this.project.product_type = 0;
                    }
                }
                _this.project['region_id'] = 0;
                _this.project['estimated_yield_weight_uom'] = 0;
                _this.project['estimated_yield_area_uom'] = 0;
                // Get project data
                if (_this.project.id > 0) {
                    var _loop_2 = function (i) {
                        _this.unitsMasterArray[i].unitsInProject = _this.project.ProjectUOM.filter(function (x) { return x.unit_type_id == _this.unitsMasterArray[i]['unitType'].id; });
                        for (var j = 0; j < _this.unitsMasterArray[i].unitsInProject.length; j++) {
                            _this.unitsMasterArray[i].unitsInProject[j]['tmp_id'] = +_this.commonService.getRandomNum().toString().slice(2, 6);
                        }
                    };
                    for (var i = 0; i < _this.unitsMasterArray.length; i++) {
                        _loop_2(i);
                    }
                    _this.unitsMasterArrayCopy = JSON.stringify(_this.unitsMasterArray);
                    _this.project.product_name = _this.products.find(function (x) { return x.id === _this.project.product_id; }).name;
                    _this.getSupplierMappings(_this.project.supplier_id, _this.project.product_id);
                    var servicesWithoutComponentsTemp = _this.supplier_project_services_components_mapping.filter(function (item) { return item.components_id == null; });
                    _this.servicesWithoutComponents = servicesWithoutComponentsTemp.reduce(function (unique, o) {
                        if (!unique.some(function (obj) { return obj.user_type_id === o.user_type_id || obj.services_id === o.services_id; })) {
                            unique.push(o);
                        }
                        return unique;
                    }, []);
                    _this.setInternationalUnits();
                }
                else {
                    _this.servicesWithoutComponents = [];
                    _this.user_type_services_components_mapping = [];
                    _this.addUnitNew();
                }
                // Auto load the data of project
                if (_this.currentUser.role.id > 2) {
                    _this.getSupplierMappings(_this.project.supplier_id, _this.project.product_id);
                }
            });
        }
    };
    SetupComponent.prototype.checkIfDuplicateExists = function (w) {
        var _loop_3 = function (i) {
            var filteredArray = w.filter(function (item) { return item.code == w[i].code; });
            if (filteredArray && filteredArray.length > 1) {
                return { value: true };
            }
        };
        for (var i = 0; i < w.length; i++) {
            var state_1 = _loop_3(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    };
    SetupComponent.prototype.ecosystemUnitsInitialization = function (unitsData) {
        var _this = this;
        // Set up units
        this.unitsMasterArray = [];
        if (unitsData.length > 0) {
            this.units = unitsData;
            this.unitsDuplicate = JSON.stringify(this.units);
            this.unittypes = this._route.snapshot.data.commonData.unittypes.data;
            var _loop_4 = function (i) {
                this_2.unitsMasterArray.push({
                    unitType: this_2.unittypes[i],
                    unitOptions: this_2.ecosystemSettingsData.EcosystemUOM.filter(function (item) { return item.unit_type_id == _this.unittypes[i].id; }),
                });
            };
            var this_2 = this;
            for (var i = 0; i < this.unittypes.length; i++) {
                _loop_4(i);
            }
            this.addUnitNew();
        }
        else {
            this.commonService.showToast('error', 'need_update_in_ecosystem_settings', {});
        }
    };
    SetupComponent.prototype.submit = function () {
        var _this = this;
        this.spinner.show();
        this.savingProject = true;
        var servicesWithComponents = [];
        for (var i = 0; i < this.supplier_project_services_components_mapping.length; i++) {
            if (this.supplier_project_services_components_mapping[i]['components_id']) {
                servicesWithComponents.push(this.supplier_project_services_components_mapping[i]['services_id']);
            }
        }
        var _loop_5 = function (i) {
            this_3.supplier_project_services_components_mapping = this_3.supplier_project_services_components_mapping.filter(function (item) { return item.services_id !== servicesWithComponents[i] || item.components_id; });
        };
        var this_3 = this;
        for (var i = 0; i < servicesWithComponents.length; i++) {
            _loop_5(i);
        }
        this.showMainSpinner = true;
        var uomArray = [];
        var uomArray2 = [];
        for (var i = 0; i < this.unitsMasterArray.length; i++) {
            uomArray = uomArray.concat(this.unitsMasterArray[i]['unitsInProject']);
        }
        if (this.checkIfDuplicateExists(uomArray)) {
            this.commonService.showToast('error', 'duplicate_units', {});
            this.spinner.hide();
            this.savingProject = false;
            return;
        }
        for (var i = 0; i < uomArray.length; i++) {
            if (!uomArray[i]['international_value'] || uomArray[i]['international_value'] == '' || uomArray[i]['international_value'] == 0) {
                this.commonService.showToast('error', 'int_unit_value_missing', {});
                this.spinner.hide();
                this.savingProject = false;
                return;
            }
            var uom = {
                id: uomArray[i]['id'],
                project_id: this.project.id > 0 ? this.project.id : 0,
                unit_id: uomArray[i]['unit_id'],
                unit_type_id: uomArray[i]['unit_type_id'],
                code: uomArray[i]['code'],
                uom: uomArray[i]['uom'] ? uomArray[i]['uom'] : uomArray[i]['name'],
                international_value: uomArray[i]['international_value'],
                international_unit: uomArray[i]['international_unit'],
                is_default: uomArray[i]['is_default'],
                deleted: uomArray[i]['deleted'] ? (uomArray[i]['deleted'] == true ? true : false) : false
            };
            uomArray2.push(uom);
        }
        this.project.ProjectUOM = uomArray2;
        if (!this.project.ProjectUOM || this.project.ProjectUOM.length == 0) {
            this.commonService.showToast('error', 'add_units', {});
            this.savingProject = false;
            return;
        }
        var _loop_6 = function (i) {
            var unitX = this_4.project.ProjectUOM.filter(function (item) { return item.unit_type_id == _this.unitsMasterArray[i]['unitType']['id']; });
            if (!unitX || unitX.length == 0) {
                this_4.commonService.showToast('error', 'add_units', {});
                this_4.savingProject = false;
                return { value: void 0 };
            }
        };
        var this_4 = this;
        for (var i = 0; i < this.unitsMasterArray.length; i++) {
            var state_2 = _loop_6(i);
            if (typeof state_2 === "object")
                return state_2.value;
        }
        var isValid = this.supplier_project_services_components_mapping.length > 0 && this.project.currency_code && this.project.currency_code.length > 0 && this.unitsMasterArray.length > 0;
        if (isValid) {
            if (this.project['estimated_yield'] == '') {
                this.project['estimated_yield'] = null;
            }
            this.project['supplier_project_services_components_mapping'] = this.supplier_project_services_components_mapping;
            for (var i = 0; i < this.project['supplier_project_services_components_mapping'].length; i++) {
                this.project['supplier_project_services_components_mapping'][i]['project_id'] = this.project.id > 0 ? this.project.id : 0;
                this.project['supplier_project_services_components_mapping'][i]['cr_user_id'] = this.currentUser.id;
            }
            var initials = this.GetInitials(this.project.name);
            var productName = this.project.product_name;
            var supplierName = this.project.supplier_name;
            this.project.program_code = initials + productName + supplierName;
            this.project.cr_user_id = this.currentUser.id;
            if (this.filesToUpload.length > 0) {
                var formData = new FormData();
                var files = this.filesToUpload;
                formData.append('type', 'projectAvatar');
                formData.append('farmerId', this.project.id);
                formData.append('avatar', files[0], files[0].name);
                this.projectService.Upload(formData)
                    .subscribe(function (obj) {
                    if (obj.msg === 'success') {
                        _this.project.photo_path = obj.data;
                        _this.saveProject();
                    }
                    else {
                        _this.commonService.showToast('error', 'generic_error', {});
                        _this.spinner.hide();
                    }
                }, function (err) {
                    _this.spinner.hide();
                });
            }
            else {
                this.saveProject();
            }
        }
        else {
            this.commonService.showToast('error', 'select_psm', {});
            this.savingProject = false;
            this.spinner.hide();
        }
    };
    SetupComponent.prototype.scrollToTop = function () {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    SetupComponent.prototype.saveProject = function () {
        var _this = this;
        this.project.ProjectMapping = [{
                users_id: this.currentUser.id,
                access_type_id: 3,
                user_type_id: this.currentUser.role.id,
                cr_user_id: this.currentUser.id,
                project_id: this.project.id > 0 ? this.project.id : 0
            }];
        this.project.status_id = this.project.status_id > 0 ? this.project.status_id : null;
        this.project.description = this.project.description.substr(0, 999);
        this.projectService.SaveProject(this.project).subscribe(function (result) {
            _this.showMainSpinner = false;
            _this.savingProject = false;
            if (result.msg === 'success') {
                if (_this.project.id > 0) {
                    _this.commonService.showToast('success', 'project_updated_success', {});
                }
                else {
                    _this.commonService.showToast('success', 'project_saved_success', {});
                }
                var toast_1;
                setTimeout(function () {
                    toast_1 = _this.commonService.showToast('warning', 'caching_warning', { disableTimeOut: true });
                });
                _this.commonService.updateCache().subscribe(function (val) {
                    if (toast_1) {
                        toast_1.toastRef.manualClose();
                    }
                    else {
                        _this.toastrService.clear();
                    }
                    setTimeout(function () { return _this.commonService.showToast('success', 'data_cached_successfully', {}); }, 1000);
                });
                setTimeout(function () {
                    _this.spinner.hide();
                    if (result.data.id) {
                        var url = "/projectmanagement";
                        _this.router.navigate([url], { queryParams: { from: _this.fromNavId } });
                    }
                    else if (result.data.project.id) {
                        var url = "/projectmanagement";
                        _this.router.navigate([url], { queryParams: { from: _this.fromNavId } });
                    }
                }, 1000);
            }
            _this.spinner.hide();
        }, function (error) {
            _this.spinner.hide();
            _this.savingProject = false;
        }, function () { });
    };
    // Add a unit to the gieve unit type
    SetupComponent.prototype.addUnitDynamic = function (unitTypeToAddClicked) {
        var oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
        var unitToAdd;
        var unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
        // Find the unit type that is to be added
        var unitTypeToAdd = oldUnitsMasterArray.filter(function (one) { return one.unitType.id == unitTypeToAddClicked.id; });
        if (unitTypeToAdd && unitTypeToAdd.length > 0) {
            var index_1 = oldUnitsMasterArray.indexOf(unitTypeToAdd[0]);
            if (index_1 != null && index_1 > -1) {
                var _loop_7 = function (i) {
                    unitsDuplicateArray = unitsDuplicateArray.filter(function (item) { return item.unit_id !== oldUnitsMasterArray[index_1].unitsInProject[i].unit_id; });
                };
                // From the overall units array, remove the units in this unittype that have already been added
                for (var i = 0; i < oldUnitsMasterArray[index_1].unitsInProject.length; i++) {
                    _loop_7(i);
                }
                // From the remaining set of units, find the first unit of the type that is to be added
                var unitChanged = unitsDuplicateArray.filter(function (one) { return one.unit_type_id == unitTypeToAddClicked.id; });
                if (unitChanged && unitChanged.length > 0) {
                    unitToAdd = unitChanged[0];
                    unitToAdd.tmp_id = +this.commonService.getRandomNum().toString().slice(2, 6);
                    unitToAdd.is_default = false;
                    oldUnitsMasterArray[index_1]['unitsInProject'].push(unitToAdd);
                }
            }
        }
        // Configure the unitsMasterArray & copy
        this.unitsMasterArray = oldUnitsMasterArray;
        this.unitsMasterArray = [].concat(this.unitsMasterArray);
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
        this.setInternationalUnits();
    };
    SetupComponent.prototype.addUnitNew = function () {
        var _this = this;
        if (this.project.id > 0) {
            var _loop_8 = function (i) {
                this_5.unitsMasterArray[i].unitsInProject = this_5.project.ProjectUOM.filter(function (x) { return x.unit_type_id == _this.unitsMasterArray[i]['unitType'].id; });
                for (var j = 0; j < this_5.unitsMasterArray[i].unitsInProject.length; j++) {
                    this_5.unitsMasterArray[i].unitsInProject[j]['tmp_id'] = +this_5.commonService.getRandomNum().toString().slice(2, 6);
                }
            };
            var this_5 = this;
            for (var i = 0; i < this.unitsMasterArray.length; i++) {
                _loop_8(i);
            }
            this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
            this.setInternationalUnits();
        }
        else {
            for (var i = 0; i < this.unitsMasterArray.length; i++) {
                this.unitsMasterArray[i]['unitsInProject'] = [];
                var x = this.unitsMasterArray[i].unitOptions.filter(function (item) { return item.is_default == 1; })[0];
                this.unitsMasterArray[i]['unitsInProject'].push(x);
                this.unitsMasterArray[i]['unitsInProject'][0]['is_default'] = true;
                this.unitsMasterArray[i]['unitsInProject'][0]['tmp_id'] = +this.commonService.getRandomNum().toString().slice(2, 6);
            }
            this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
            this.setInternationalUnits();
        }
        if (this.project.id > 0) {
            this.projectEstYieldFieldsUpdate(this.unitsMasterArray, 'unitsInProject');
        }
        else {
            this.projectEstYieldFieldsUpdate(this.unitsMasterArray, 'unitOptions');
        }
    };
    SetupComponent.prototype.projectEstYieldFieldsUpdate = function (unitsMasterArray, filterFrom) {
        for (var i = 0; i < unitsMasterArray.length; i++) {
            if (unitsMasterArray[i].unitType.id == 4) {
                this.projectYieldAreaUnitsArray = [];
                this.projectYieldAreaUnitsArray = unitsMasterArray[i][filterFrom].filter(function (item) { return item.unit_type_id === 4; });
            }
            if (this.unitsMasterArray[i].unitType.id == 3) {
                this.projectYieldWeightUnitsArray = [];
                this.projectYieldWeightUnitsArray = unitsMasterArray[i][filterFrom].filter(function (item) { return item.unit_type_id == 3; });
            }
        }
        var weightUnitDefault;
        var areaUnitDefault;
        if (this.projectYieldWeightUnitsArray != undefined || this.projectYieldWeightUnitsArray != null) {
            weightUnitDefault = this.projectYieldWeightUnitsArray.filter(function (item) { return item.unit_type_id == 3 && item.is_default == true; })[0];
            this.project.estimated_yield_weight_uom = weightUnitDefault.tmp_id;
        }
        if (this.projectYieldAreaUnitsArray != undefined || this.projectYieldAreaUnitsArray != null) {
            areaUnitDefault = this.projectYieldAreaUnitsArray.filter(function (item) { return item.unit_type_id == 4 && item.is_default == true; })[0];
            this.project.estimated_yield_area_uom = areaUnitDefault.tmp_id;
        }
    };
    SetupComponent.prototype.projectEstYieldFieldsUpdate2 = function (unitsMasterArray, unit) {
        for (var i = 0; i < this.unitsMasterArray.length; i++) {
            if (this.unitsMasterArray[i].unitType.id == 4) {
                this.projectYieldAreaUnitsArray = [];
                if (unit.unit_type_id == 4 && unit.is_default == false) {
                    this.projectYieldAreaUnitsArray = this.unitsMasterArray[i].unitsInProject.filter(function (item) { return item.unit_type_id == 4; });
                }
                else {
                    this.projectYieldAreaUnitsArray = this.unitsMasterArray[i].unitOptions.filter(function (item) { return item.unit_type_id == 4; });
                }
            }
            if (this.unitsMasterArray[i].unitType.id == 3) {
                this.projectYieldWeightUnitsArray = [];
                if (unit.unit_type_id === 3 && unit.is_default === false) {
                    this.projectYieldWeightUnitsArray = this.unitsMasterArray[i].unitsInProject.filter(function (item) { return item.unit_type_id == 3; });
                }
                else {
                    this.projectYieldWeightUnitsArray = this.unitsMasterArray[i].unitOptions.filter(function (item) { return item.unit_type_id == 3; });
                }
            }
        }
        var weightUnitDefault;
        var areaUnitDefault;
        if (this.projectYieldWeightUnitsArray != undefined || this.projectYieldWeightUnitsArray != null) {
            weightUnitDefault = this.projectYieldWeightUnitsArray.filter(function (item) { return item.unit_type_id == 3 && item.is_default == true; })[0];
            this.project.estimated_yield_weight_uom = weightUnitDefault.tmp_id;
        }
        if (this.projectYieldAreaUnitsArray != undefined || this.projectYieldAreaUnitsArray != null) {
            areaUnitDefault = this.projectYieldAreaUnitsArray.filter(function (item) { return item.unit_type_id == 4 && item.is_default == true; })[0];
            this.project.estimated_yield_area_uom = areaUnitDefault.tmp_id;
        }
    };
    SetupComponent.prototype.projectEstYieldFieldsUpdate3 = function (unitsMasterArray, unit) {
        for (var i = 0; i < this.unitsMasterArray.length; i++) {
            if (this.unitsMasterArray[i].unitType.id == 4) {
                if (unit.unit_type_id == 4) {
                    this.projectYieldAreaUnitsArray = [];
                    this.projectYieldAreaUnitsArray = this.unitsMasterArray[i].unitsInProject.filter(function (item) { return item.unit_type_id == 4; });
                }
            }
            if (this.unitsMasterArray[i].unitType.id == 3) {
                if (unit.unit_type_id == 3) {
                    this.projectYieldWeightUnitsArray = [];
                    this.projectYieldWeightUnitsArray = this.unitsMasterArray[i].unitsInProject.filter(function (item) { return item.unit_type_id == 3; });
                }
            }
        }
        var weightUnitDefault;
        var areaUnitDefault;
        if (this.projectYieldWeightUnitsArray != undefined || this.projectYieldWeightUnitsArray != null) {
            weightUnitDefault = this.projectYieldWeightUnitsArray.filter(function (item) { return item.unit_type_id == 3 && item.is_default == true; })[0];
            this.project.estimated_yield_weight_uom = weightUnitDefault.tmp_id;
        }
        if (this.projectYieldAreaUnitsArray != undefined || this.projectYieldAreaUnitsArray != null) {
            areaUnitDefault = this.projectYieldAreaUnitsArray.filter(function (item) { return item.unit_type_id == 4 && item.is_default == true; })[0];
            this.project.estimated_yield_area_uom = areaUnitDefault.tmp_id;
        }
    };
    SetupComponent.prototype.addUnit = function (key) {
        var uom = new ProjectUOM();
        uom.temp_id = +this.commonService.getRandomNum().toString().slice(2, 6);
        uom.uom_type = key;
        if (this.locale == 'es') {
            uom.international_uom = key === 'Length' ? 'Metro' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litro' : 'Hectárea'));
        }
        else if (this.locale == 'en') {
            uom.international_uom = key === 'Length' ? 'Meter' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Ltr' : 'Hectare'));
        }
        else if (this.locale == 'fr') {
            uom.international_uom = key === 'Length' ? 'Mètre' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litre' : 'Hectare'));
        }
        else if (this.locale == 'zh') {
            uom.international_uom = key === 'Length' ? '仪表' : (key === 'Weight' ? '公斤' : (key === 'Volume' ? '升' : '公顷'));
        }
        else {
            uom.international_uom = key === 'Length' ? 'மீட்டர்' : (key === 'Weight' ? 'கிலோ' : (key === 'Volume' ? 'லிட்டர்' : 'ஹெக்டேர்'));
        }
        uom.is_default = this[key].length === 0;
        if (this[key].length < 1) {
            if (this.locale == 'es') {
                uom.uom = (key === 'Length' ? 'Metro' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litro' : 'Hectárea')));
            }
            else if (this.locale == 'en') {
                uom.uom = (key === 'Length' ? 'Meter' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Ltr' : 'Hectare')));
            }
            else if (this.locale == 'fr') {
                uom.uom = (key === 'Length' ? 'Mètre' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litre' : 'Hectare')));
            }
            else if (this.locale == 'zh') {
                uom.uom = (key === 'Length' ? '仪表' : (key === 'Weight' ? '公斤' : (key === 'Volume' ? '升' : '公顷')));
            }
            else {
                uom.uom = (key === 'Length' ? 'மீட்டர்' : (key === 'Weight' ? 'கிலோ' : (key === 'Volume' ? 'லிட்டர்' : 'ஹெக்டேர்')));
            }
            uom.international_value = 1;
        }
        uom.project_id = this.project.id > 0 ? this.project.id : 0;
        this[key].push(uom);
    };
    SetupComponent.prototype.deleteUnit = function (key, unit) {
        if (unit.id > 0) {
            this[key] = this[key].filter(function (x) { return x.id !== unit.id; });
        }
        else {
            this[key] = this[key].filter(function (x) { return x.temp_id !== unit.temp_id; });
        }
        if (unit.is_default) {
            this[key][0].is_default = true;
        }
    };
    SetupComponent.prototype.deleteUnit2 = function (unit) {
        var oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
        var unitTypeToDelete = oldUnitsMasterArray.filter(function (one) { return one.unitType.id === unit.unit_type_id; });
        if (unitTypeToDelete && unitTypeToDelete.length > 0) {
            var index = oldUnitsMasterArray.indexOf(unitTypeToDelete[0]);
            if (index != null && index > -1) {
                oldUnitsMasterArray[index]['unitsInProject'] = oldUnitsMasterArray[index]['unitsInProject'].filter(function (item) { return item.tmp_id !== unit.tmp_id; });
                if (oldUnitsMasterArray[index]['unitsInProject'].length > 0) {
                    oldUnitsMasterArray[index]['unitsInProject'][0]['is_default'] = true;
                }
            }
        }
        this.unitsMasterArray = oldUnitsMasterArray;
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
        this.projectEstYieldFieldsUpdate3(this.unitsMasterArray, unit);
        this.setInternationalUnits();
    };
    SetupComponent.prototype.changeDefaultProjectUOM = function (unit) {
        this.projectYieldAreaUnitsArray = [];
        this.projectYieldWeightUnitsArray = [];
        var oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
        var unitTypeChanged = oldUnitsMasterArray.filter(function (item) { return item.unitType.id == unit.unit_type_id; });
        if (unitTypeChanged && unitTypeChanged.length > 0) {
            var index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
            if (index != null && index > -1) {
                var unitsInProject = oldUnitsMasterArray[index]['unitsInProject'];
                for (var i = 0; i < unitsInProject.length; i++) {
                    unitsInProject[i]['is_default'] = false;
                }
                var unitChanged = unitsInProject.filter(function (item) { return item.tmp_id == unit.tmp_id; });
                if (unitChanged && unitChanged.length > 0) {
                    var index2 = unitsInProject.indexOf(unitChanged[0]);
                    unitsInProject[index2]['is_default'] = true;
                }
                oldUnitsMasterArray['unitsInProject'] = unitsInProject;
            }
        }
        this.unitsMasterArray = oldUnitsMasterArray;
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
        this.projectEstYieldFieldsUpdateOnChange();
        this.setInternationalUnits();
    };
    SetupComponent.prototype.checkChange = function (key, entry) {
        this[key] = this[key].map(function (x) {
            if (x.temp_id === entry.temp_id) {
                x.is_default = true;
            }
            else {
                x.is_default = false;
            }
            return x;
        });
    };
    SetupComponent.prototype.checkUnit = function (key, entry) {
        var _this = this;
        this[key].map(function (x) {
            if (entry.uom && entry.uom !== '' && x.uom === entry.uom && x.temp_id !== entry.temp_id) {
                entry.uom = '';
                _this.commonService.showToast('warning', 'unit_conversion_exists', {});
                return;
            }
        });
    };
    SetupComponent.prototype.fileChangeEvent = function (fileInput) {
        var fileItem = fileInput.target.files.item(0);
        this.uploadingFileName = fileItem.name;
        var file = fileInput.target.files[0];
        this.uploadingFileName = fileItem.name;
        this.project.photo_path = '';
        var msg = this.commonService.ValidateFile(file);
        if (msg.length > 0) {
            this.commonService.showToast('warning', 'generic_error', {});
            return;
        }
        this.filesToUpload = fileInput.target.files;
        this.project.photo_path = file.name;
    };
    SetupComponent.prototype.cancel = function (template) {
        if (this.projectForm.dirty) {
            this.confirmCancelModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        }
        else {
            this.confirmCancel();
        }
    };
    SetupComponent.prototype.confirmModal = function (what) {
        if (what == 'yes') {
            if (this.confirmCancelModal) {
                this.confirmCancelModal.hide();
            }
            this.confirmCancel();
        }
        else {
            if (this.confirmCancelModal) {
                this.confirmCancelModal.hide();
            }
        }
    };
    SetupComponent.prototype.confirmCancel = function () {
        var url = "/projectmanagement";
        this.router.navigate([url], { queryParams: { from: this.fromNavId } });
    };
    SetupComponent.prototype.GetInitials = function (string) {
        var names = string.split(' ');
        var initials = '';
        if (names.length === 1) {
            return names[0].substring(0, 1).toUpperCase();
        }
        names.forEach(function (name) {
            initials += name.substring(0, 1).toUpperCase();
        });
        return initials;
    };
    SetupComponent.prototype.productChanged = function () {
        var _this = this;
        if (this.project.product_name) {
            var obj = this.products.find(function (x) { return x.name.toLowerCase() === _this.project.product_name.toLowerCase(); });
            if (obj) {
                this.project.product_id = obj.id;
            }
            else {
                this.project.product_id = 0;
            }
        }
    };
    SetupComponent.prototype.trackByUnitTypeId = function (index, dataItem) {
        return dataItem.unit_type_id;
    };
    SetupComponent.prototype.trackByRegionId = function (index, dataItem) {
        return dataItem.region_id;
    };
    SetupComponent.prototype.trackById = function (index, dataItem) {
        return dataItem.id;
    };
    SetupComponent.prototype.trackByCode = function (index, dataItem) {
        return dataItem.code;
    };
    SetupComponent.prototype.trackByTempId = function (index, dataItem) {
        return dataItem.temp_id;
    };
    SetupComponent.prototype.trackByTmpId = function (index, dataItem) {
        return dataItem.tmp_id;
    };
    SetupComponent.prototype.goToProject = function () {
        var url = "projectinfo/" + this.projectId;
        this.router.navigate([url]);
    };
    SetupComponent.prototype.goToDashboard = function () {
        if (confirm('Are you sure you want to exit?')) {
            this.router.navigate(['/dashboard']);
        }
    };
    SetupComponent.prototype.trackByIndex = function (index, dataItem) {
        return index;
    };
    SetupComponent.prototype.closeModal = function (isOkay) {
        this.modalDisplay = 'none';
        this.subject.next(isOkay);
    };
    SetupComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.subject.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    SetupComponent.prototype.setCurrency = function () {
        /* This functionality has been disabled on 18/7/19
      // console.log('selected country', this.project.country_id);
      let selectedCountry = this.countries.filter(item => item.id == this.project.country_id);
      if (selectedCountry && selectedCountry.length > 0) {
        const selectedCurrency = this.currencyArr.filter(item => item.currency_code == selectedCountry[0].currency_code);
        if (selectedCurrency && selectedCurrency.length > 0) {
          this.project.currency_code = selectedCurrency[0].currency_code;
        }
        else {
          this.project.currency_code = "";
        }
      } else {
        this.project.currency_code = '';
      }
      */
    };
    SetupComponent.prototype.setProductType = function (args) {
        var _this = this;
        if (this.project.product_id) {
            if (this.project.product_id > 0) {
                var product = this.products.filter(function (item) { return item.id == _this.project.product_id; });
                if (product && product.length > 0) {
                    var productItem = product[0];
                    if (productItem) {
                        this.project.product_type = productItem.product_type_id;
                    }
                }
            }
            else {
                this.project.product_type = 0;
            }
        }
    };
    SetupComponent.prototype.serviceOpenCloseToggleAll = function () {
        for (var i = 0; i < this.masterServiceComponentArray.length; i++) {
            if (!this.masterServiceComponentArray[i]['services_id'] && this.masterServiceComponentArray[i]['noComponents'] == false) {
                this.serviceOpenCloseToggle(this.masterServiceComponentArray[i]);
            }
        }
        if (this.rowsToShow && this.rowsToShow.length === 0) {
            this.allClosed = true;
        }
        else if (!this.rowsToShow) {
            this.allClosed = true;
        }
        else {
            this.allClosed = false;
        }
    };
    SetupComponent.prototype.serviceOpenCloseToggle = function (item) {
        var clickedItem = this.masterServiceComponentArray.filter(function (one) { return one.id == item.id; });
        var clickedItemObj = clickedItem[0];
        var index = this.masterServiceComponentArray.indexOf(clickedItemObj);
        this.masterServiceComponentArray[index]['opened'] = !this.masterServiceComponentArray[index]['opened'];
        if (!this.masterServiceComponentArray[index]['opened']) {
            var childItems_1 = this.masterServiceComponentArray.filter(function (one) { return one.services_id == item.id; });
            if (childItems_1 && childItems_1.length > 0) {
                var _loop_9 = function (i) {
                    var tempRowsToShow = this_6.rowsToShow.filter(function (item) { return item !== childItems_1[i].id; });
                    this_6.rowsToShow = tempRowsToShow;
                };
                var this_6 = this;
                for (var i = 0; i < childItems_1.length; i++) {
                    _loop_9(i);
                }
            }
        }
        else {
            var childItems = this.masterServiceComponentArray.filter(function (one) { return one.services_id == item.id; });
            if (childItems && childItems.length > 0) {
                for (var i = 0; i < childItems.length; i++) {
                    this.rowsToShow.push(childItems[i].id);
                }
            }
        }
        if (this.rowsToShow && this.rowsToShow.length === 0) {
            this.allClosed = true;
        }
        else if (!this.rowsToShow) {
            this.allClosed = true;
        }
        else {
            this.allClosed = false;
        }
    };
    SetupComponent.prototype.psmChecked = function (partnerId, itemId, parentServiceId, isToggleAllCall) {
        if (parentServiceId) { // this is a component being toggled
            var isPresent = this.supplier_project_services_components_mapping.filter(function (one) { return one.components_id == itemId && one.user_type_id == partnerId; });
            if (isPresent && isPresent.length > 0) {
                // If it is a select all call, don't remove, otherwise remove
                if (!isToggleAllCall) {
                    var itemsToRemove_1 = this.supplier_project_services_components_mapping.filter(function (one) { return one.components_id == itemId && one.user_type_id == partnerId; });
                    var tempArray = this.supplier_project_services_components_mapping.filter(function (x) { return !itemsToRemove_1.includes(x); });
                    this.supplier_project_services_components_mapping = tempArray;
                }
            }
            // not present so add this component
            else {
                this.supplier_project_services_components_mapping.push({ supplier_id: this.project.supplier_id, user_type_id: partnerId, components_id: itemId, services_id: parentServiceId });
            }
        }
        else { // this is a parent service
            var isPresent = this.supplier_project_services_components_mapping.filter(function (one) { return one.services_id == itemId && one.user_type_id == partnerId; });
            if (isPresent && isPresent.length > 0) {
                if (!isToggleAllCall) {
                    var itemsToRemove_2 = this.supplier_project_services_components_mapping.filter(function (one) { return one.services_id == itemId && one.user_type_id == partnerId; });
                    var tempArray = this.supplier_project_services_components_mapping.filter(function (x) { return !itemsToRemove_2.includes(x); });
                    this.supplier_project_services_components_mapping = tempArray;
                }
            }
            else {
                this.supplier_project_services_components_mapping.push({ supplier_id: this.project.supplier_id, user_type_id: partnerId, services_id: itemId });
            }
        }
        this.checkedObj = {};
        for (var i = 0; i < this.supplier_project_services_components_mapping.length; i++) {
            if (this.supplier_project_services_components_mapping[i]['components_id']) {
                this.checkedObj['C_' + this.supplier_project_services_components_mapping[i]['components_id'] + '_' + this.supplier_project_services_components_mapping[i]['user_type_id']] = true;
            }
            else {
                this.checkedObj['S_' + this.supplier_project_services_components_mapping[i]['services_id'] + '_' + this.supplier_project_services_components_mapping[i]['user_type_id']] = true;
            }
        }
        this.setServicesWithComponentsAllChecked();
    };
    SetupComponent.prototype.toggleComponentCheckboxesFn = function (partner, service) {
        var childComponents = this.masterServiceComponentArray.filter(function (item) { return item.services_id == service.id; });
        if (this.allComponentsOfThisServiceSelected.find(function (item) { return item == service.id + partner.id; })) {
            this.allComponentsOfThisServiceSelected = this.allComponentsOfThisServiceSelected.filter(function (item) { return item !== service.id + partner.id; });
            var _loop_10 = function (i) {
                var itemsToRemove = this_7.supplier_project_services_components_mapping.filter(function (one) { return one.services_id == childComponents[i].id && one.user_type_id == partner.id; });
                var tempArray = this_7.supplier_project_services_components_mapping.filter(function (x) { return !itemsToRemove.includes(x); });
                this_7.supplier_project_services_components_mapping = tempArray;
                var index = "C_" + childComponents[i]['id'] + "_" + partner.id;
                this_7.checkedObj[index] = false;
            };
            var this_7 = this;
            for (var i = 0; i < childComponents.length; i++) {
                _loop_10(i);
            }
        }
        else {
            this.allComponentsOfThisServiceSelected.push(service.id + partner.id);
            for (var i = 0; i < childComponents.length; i++) {
                this.supplier_project_services_components_mapping.push({ user_type_id: partner.id, components_id: childComponents[i].id, services_id: childComponents[i].services_id });
                var index = "C_" + childComponents[i]['id'] + "_" + partner.id;
                this.checkedObj[index] = true;
            }
        }
    };
    SetupComponent.prototype.internationalUnitChanged = function (projectUOM) {
        var unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
        var unitChanged = unitsDuplicateArray.filter(function (one) { return one.code == projectUOM.code; });
        if (unitChanged && unitChanged.length > 0) {
            var index = unitsDuplicateArray.indexOf(unitChanged[0]);
            if (index != null && index > -1) {
                unitsDuplicateArray[index]['international_value'] = projectUOM['international_value'];
                this.unitsDuplicate = JSON.stringify(unitsDuplicateArray);
            }
        }
    };
    SetupComponent.prototype.selectUnit = function (projectUOM, projectUOMIndex) {
        var unitTypeChanged = this.unitsMasterArray.filter(function (one) { return one.unitType.id == projectUOM.unit_type_id; });
        if (unitTypeChanged && unitTypeChanged.length > 0) {
            var index = this.unitsMasterArray.indexOf(unitTypeChanged[0]);
            if (index != null && index > -1) {
                var projectUOMChanged = this.unitsMasterArray[index]['unitsInProject'].filter(function (one) { return one.code == projectUOM.code; });
                if (projectUOMChanged && projectUOMChanged.length > 0) {
                    var index2 = this.unitsMasterArray[index]['unitsInProject'].indexOf(projectUOMChanged[0]);
                    if (index2 != null && index2 > -1) {
                        var unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
                        var unitSelected = unitsDuplicateArray.filter(function (one) { return one.code == projectUOM.code; });
                        if (unitSelected && unitSelected.length > 0) {
                            var index3 = unitsDuplicateArray.indexOf(unitSelected[0]);
                            var unitToSet = unitsDuplicateArray[index3];
                            for (var i = 0; i < this.unitsMasterArray[index]['unitsInProject'].length; i++) {
                                this.unitsMasterArray[index]['unitsInProject'][i]['is_default'] = false;
                            }
                            this.unitsMasterArray[index]['unitsInProject'][index2] = unitsDuplicateArray[index3];
                            this.unitsMasterArray[index]['unitsInProject'][index2]['is_default'] = true;
                        }
                    }
                }
                var unitChanged = this.unitsMasterArray[index]['unitOptions'].filter(function (one) { return one.id == projectUOM.id; });
                if (unitChanged && unitChanged.length > 0) {
                    var index4 = this.unitsMasterArray[index]['unitOptions'].indexOf(unitChanged[0]);
                    if (index4 != null && index4 > -1) {
                        var unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
                        var unitSelected = unitsDuplicateArray.filter(function (one) { return one.id == projectUOM.id; });
                        if (unitSelected && unitSelected.length > 0) {
                            var index5 = unitsDuplicateArray.indexOf(unitSelected[0]);
                            this.unitsMasterArray[index]['unitOptions'][index4] = unitsDuplicateArray[index5];
                        }
                    }
                }
            }
        }
        this.unitsMasterArray = Object.assign([], this.unitsMasterArray);
        this.setInternationalUnits();
    };
    SetupComponent.prototype.selectUnit2 = function (projectUOM, projectUOMIndex) {
        var oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
        var unitTypeChanged = oldUnitsMasterArray.filter(function (one) { return one.unitType.id == projectUOM.unit_type_id; });
        if (unitTypeChanged && unitTypeChanged.length > 0) {
            var index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
            if (index != null && index > -1) {
                var projectUOMChanged = oldUnitsMasterArray[index]['unitsInProject'].filter(function (one) { return one.tmp_id == projectUOM.tmp_id; }); // code changed to tmp_id
                if (projectUOMChanged && projectUOMChanged.length > 0) {
                    var index2 = oldUnitsMasterArray[index]['unitsInProject'].indexOf(projectUOMChanged[0]);
                    if (index2 != null && index2 > -1) {
                        var unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
                        // this is the new unit selected
                        var unitSelected = unitsDuplicateArray.filter(function (one) { return one.code == projectUOM.code; });
                        if (unitSelected && unitSelected.length > 0) {
                            var index3 = unitsDuplicateArray.indexOf(unitSelected[0]);
                            unitsDuplicateArray[index3]['tmp_id'] = projectUOM.tmp_id;
                            for (var i = 0; i < oldUnitsMasterArray[index]['unitsInProject'].length; i++) {
                                oldUnitsMasterArray[index]['unitsInProject'][i]['is_default'] = false;
                            }
                            oldUnitsMasterArray[index]['unitsInProject'][index2] = unitsDuplicateArray[index3];
                            oldUnitsMasterArray[index]['unitsInProject'][index2]['is_default'] = true;
                        }
                    }
                }
            }
        }
        this.unitsMasterArray = oldUnitsMasterArray;
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
        this.projectEstYieldFieldsUpdate3(this.unitsMasterArray, projectUOM);
        this.setInternationalUnits();
    };
    SetupComponent.prototype.getSupplierMappings = function (supplierIdProvided, productIdProvided) {
        var _this = this;
        if (this.project.supplier_id !== 0 && this.project.product_id !== 0) {
            this.suppliersService.getMappingsForSupplier(supplierIdProvided ? supplierIdProvided : this.project.supplier_id, productIdProvided ? productIdProvided : this.project.product_id, this.project.id > 0 ? this.project.id : 0)
                .subscribe(function (result) {
                if (!result.supplier_services_components_mapping || !result.supplier_user_type_mapping) {
                    _this.hideSupplierMappingGrid = true;
                    _this.commonService.showToast('error', 'ecosystem_mappings_error', {});
                    _this.services = [];
                    _this.serviceComponents = [];
                    _this.masterServiceComponentArray = [];
                    _this.rowsToShow = [];
                    _this.supplierRoles = [];
                    _this.hideSupplierMappingGrid = true;
                    _this.supplier_project_services_components_mapping = [];
                }
                else if (result.supplier_services_components_mapping.length == 0 || result.supplier_user_type_mapping.length == 0) {
                    _this.commonService.showToast('error', 'ecosystem_mappings_invalid', {});
                    _this.services = [];
                    _this.serviceComponents = [];
                    _this.masterServiceComponentArray = [];
                    _this.rowsToShow = [];
                    _this.supplierRoles = [];
                    _this.hideSupplierMappingGrid = true;
                    _this.supplier_project_services_components_mapping = [];
                }
                else {
                    _this.hideSupplierMappingGrid = false;
                    _this.mappingsForSupplier = result;
                    _this.createServiceMappingGrid();
                    //Getting ecosystem related data
                    _this.ecosystem_id = result.supplier_services_components_mapping[0].ecosystem_id;
                    _this.suppliersService.getEcoSystemSettingsRecord(result.supplier_services_components_mapping[0].ecosystem_id).subscribe(function (response) {
                        _this.ecosystemSettingsData = response.data;
                        _this.spinner.hide();
                        for (var i = 0; i < _this.ecosystemSettingsData.EcosystemUOM.length; i++) {
                            _this.ecosystemSettingsData.EcosystemUOM[i]['id'] = 0;
                            _this.ecosystemSettingsData.EcosystemUOM[i]['name'] = _this.ecosystemSettingsData.EcosystemUOM[i]['uom'];
                        }
                        if (response.status === 'success') {
                            if (_this.ecosystemSettingsData.currency_id == null || _this.ecosystemSettingsData.currency_id === 'null') {
                                _this.ecosystemSettingsData.currency_id = 0;
                            }
                            else {
                                var populatedCurrency = _this.currencyArr.filter(function (item) { return item.id === _this.ecosystemSettingsData.currency_id; })[0];
                                _this.project.currency_code = populatedCurrency.currency_code;
                            }
                            if (_this.ecosystemSettingsData.country_id == null || _this.ecosystemSettingsData.country_id == 'null') {
                                _this.ecosystemSettingsData.country_id = 0;
                            }
                            else {
                                var populatedCountry = _this.countries.filter(function (item) { return item.id == _this.ecosystemSettingsData.country_id; })[0];
                                _this.project.country_id = populatedCountry.id;
                            }
                            if (_this.ecosystemSettingsData.ecosystem_region_master.length > 0 || _this.ecosystemSettingsData.ecosystem_region_master != null) {
                                var _loop_11 = function (region) {
                                    var regionObject = {
                                        "id": region.id,
                                        "region_name": region.region_name
                                    };
                                    //finding the duplicate regions and not pusing into array
                                    var x = _this.ecosystemRegionData.find(function (item) { return item.id == regionObject.id; });
                                    if (!x) {
                                        _this.ecosystemRegionData.push(regionObject);
                                    }
                                };
                                for (var _i = 0, _a = _this.ecosystemSettingsData.ecosystem_region_master; _i < _a.length; _i++) {
                                    var region = _a[_i];
                                    _loop_11(region);
                                }
                            }
                            if (_this.ecosystemSettingsData.EcosystemUOM.length > 0 || _this.ecosystemSettingsData.EcosystemUOM != null) {
                                var newArrayCopy = JSON.stringify(_this.ecosystemSettingsData.EcosystemUOM);
                                var ecoSystemUOM = [];
                                ecoSystemUOM = JSON.parse(newArrayCopy);
                                for (var _b = 0, ecoSystemUOM_1 = ecoSystemUOM; _b < ecoSystemUOM_1.length; _b++) {
                                    var y = ecoSystemUOM_1[_b];
                                    y.id = 0;
                                }
                                _this.ecosystemUnitsInitialization(ecoSystemUOM);
                            }
                        }
                    });
                }
            });
        }
        else {
            this.hideSupplierMappingGrid = true;
            this.supplier_project_services_components_mapping = [];
            this.masterServiceComponentArray = [];
            this.services = [];
            this.serviceComponents = [];
            this.rowsToShow = [];
            this.supplierRoles = [];
        }
    };
    SetupComponent.prototype.createServiceMappingGrid = function () {
        var _this = this;
        var servicesTemp1 = [];
        var servicesTemp2 = [];
        this.services = [];
        this.serviceComponents = [];
        this.masterServiceComponentArray = [];
        this.rowsToShow = [];
        this.supplierRoles = [];
        for (var i = 0; i < this.mappingsForSupplier['supplier_user_type_mapping'].length; i++) {
            this.supplierRoles.push(this.mappingsForSupplier['supplier_user_type_mapping'][i]['user_type']);
        }
        for (var i = 0; i < this.mappingsForSupplier['supplier_services_components_mapping'].length; i++) {
            if (this.mappingsForSupplier['supplier_services_components_mapping'][i]['components_id']) {
                this.serviceComponents.push(this.mappingsForSupplier['supplier_services_components_mapping'][i]['services_components_mapping']);
            }
            servicesTemp1.push(this.mappingsForSupplier['supplier_services_components_mapping'][i]['services']);
        }
        this.services = servicesTemp1.reduce(function (unique, o) {
            if (!unique.some(function (obj) { return obj.code === o.code; })) {
                unique.push(o);
            }
            return unique;
        }, []);
        for (var i = 0; i < this.serviceComponents.length; i++) {
            if (this.serviceComponentsMapping[this.serviceComponents[i]['services_id']]) {
                this.serviceComponentsMapping[this.serviceComponents[i]['services_id']]++;
            }
            else {
                this.serviceComponentsMapping[this.serviceComponents[i]['services_id']] = 0;
            }
        }
        var _loop_12 = function (i) {
            var componentsInService = void 0;
            this_8.masterServiceComponentArray.push(this_8.services[i]);
            componentsInService = this_8.serviceComponents.filter(function (item) { return item.services_id == _this.services[i].id; });
            if (componentsInService && componentsInService.length > 0) {
                var componentsInService2 = this_8.masterServiceComponentArray.concat(componentsInService);
                this_8.masterServiceComponentArray = componentsInService2;
                this_8.services[i]['noComponents'] = false;
            }
            else {
                this_8.services[i]['noComponents'] = true;
            }
        };
        var this_8 = this;
        for (var i = 0; i < this.services.length; i++) {
            _loop_12(i);
        }
        for (var i = 0; i < this.masterServiceComponentArray.length; i++) {
            this.masterServiceComponentArray[i]['opened'] = true;
            if (this.masterServiceComponentArray[i].services_id) {
                this.rowsToShow.push(this.masterServiceComponentArray[i].id);
            }
        }
        this.addCheckedToSPSCM();
    };
    SetupComponent.prototype.addCheckedToSPSCM = function () {
        // if this is a project being edited, add the already mapped itesm to checkedObj
        if (this.project.id > 0) {
            for (var i = 0; i < this.project['supplier_project_services_components_mapping'].length; i++) {
                if (this.project['supplier_project_services_components_mapping'][i]['components_id'] != null) {
                    this.psmChecked(this.project['supplier_project_services_components_mapping'][i]['user_type_id'], this.project['supplier_project_services_components_mapping'][i]['components_id'], this.project['supplier_project_services_components_mapping'][i]['services_id']);
                }
                else {
                    this.psmChecked(this.project['supplier_project_services_components_mapping'][i]['user_type_id'], this.project['supplier_project_services_components_mapping'][i]['services_id'], null);
                }
            }
            // Additionally if there are NEW user types or services / components mapped to this ecosystem (mapped AFTER project was created)
            // they will not be present in the supplier_project_services_components_mapping but will be present in the services & serviceCOmponents
            // Their is_default = 'Y' must be handled
            for (var i = 0; i < this.serviceComponents.length; i++) {
                for (var j = 0; j < this.supplierRoles.length; j++) {
                    if (!this.checkedObj['C_' + this.serviceComponents[i]['id'] + '_' + this.supplierRoles[j]['id']] && this.serviceComponents[i]['is_default'] == 'Y') {
                        this.psmChecked(this.supplierRoles[j].id, this.serviceComponents[i].id, this.serviceComponents[i].services_id);
                    }
                }
            }
            for (var i = 0; i < this.services.length; i++) {
                for (var j = 0; j < this.supplierRoles.length; j++) {
                    if (!this.checkedObj['S_' + this.services[i]['id'] + '_' + this.supplierRoles[j]['id']] && this.services[i]['is_default'] == 'Y') {
                        this.psmChecked(this.supplierRoles[j].id, this.services[i].id, null);
                    }
                }
            }
        }
        else {
            for (var i = 0; i < this.serviceComponents.length; i++) {
                for (var j = 0; j < this.supplierRoles.length; j++) {
                    if (this.serviceComponents[i]['is_default'] == 'Y') {
                        this.psmChecked(this.supplierRoles[j].id, this.serviceComponents[i].id, this.serviceComponents[i].services_id);
                    }
                }
            }
            for (var i = 0; i < this.services.length; i++) {
                for (var j = 0; j < this.supplierRoles.length; j++) {
                    if (this.services[i]['is_default'] == 'Y') {
                        this.psmChecked(this.supplierRoles[j].id, this.services[i].id, null);
                    }
                }
            }
        }
    };
    SetupComponent.prototype.setServicesWithComponentsAllChecked = function () {
        var _this = this;
        // Get all services with components in them
        var servicesWithComponents = this.services.filter(function (x) { return x.noComponents == false; });
        var _loop_13 = function (i) {
            var _loop_14 = function (p) {
                var componentsInService = this_9.serviceComponents.filter(function (item) { return item.services_id == servicesWithComponents[i].id; });
                var countOfComponentsInServiceForUserType = 0;
                for (var j = 0; j < componentsInService.length; j++) {
                    var index = "C_" + componentsInService[j]['id'] + "_" + this_9.supplierRoles[p]['id'];
                    if (this_9.checkedObj[index]) {
                        countOfComponentsInServiceForUserType++;
                    }
                }
                if (countOfComponentsInServiceForUserType === componentsInService.length) {
                    this_9.allCheckedUserTypesServices.push("S" + this_9.supplierRoles[p]['id'] + servicesWithComponents[i]['id']);
                }
                else {
                    this_9.allCheckedUserTypesServices = this_9.allCheckedUserTypesServices.filter(function (item) { return item !== ('S' + _this.supplierRoles[p]['id'] + servicesWithComponents[i]['id']); });
                }
            };
            for (var p = 0; p < this_9.supplierRoles.length; p++) {
                _loop_14(p);
            }
        };
        var this_9 = this;
        // For each such service
        for (var i = 0; i < servicesWithComponents.length; i++) {
            _loop_13(i);
        }
        this.setUserTypesWithComponentsAllChecked();
    };
    SetupComponent.prototype.setUserTypesWithComponentsAllChecked = function () {
        var _this = this;
        var componentsInUserType = this.serviceComponents;
        var servicesWithoutComponents = this.services.filter(function (item) { return item.noComponents == true; });
        var total = componentsInUserType.length + servicesWithoutComponents.length;
        var _loop_15 = function (i) {
            var count1 = 0;
            var count2 = 0;
            for (var j = 0; j < servicesWithoutComponents.length; j++) {
                if (this_10.checkedObj["S_" + servicesWithoutComponents[j]['id'] + "_" + this_10.supplierRoles[i]['id']]) {
                    count1++;
                }
            }
            for (var j = 0; j < componentsInUserType.length; j++) {
                if (this_10.checkedObj["C_" + componentsInUserType[j]['id'] + "_" + this_10.supplierRoles[i]['id']]) {
                    count2++;
                }
            }
            if (total == count1 + count2) {
                this_10.allCheckedUserTypes.push(this_10.supplierRoles[i]['id']);
            }
            else {
                this_10.allCheckedUserTypes = this_10.allCheckedUserTypes.filter(function (item) { return item !== _this.supplierRoles[i]['id']; });
            }
        };
        var this_10 = this;
        for (var i = 0; i < this.supplierRoles.length; i++) {
            _loop_15(i);
        }
    };
    SetupComponent.prototype.toggleAllServicesForUserType = function (partnerId) {
        // Check if this user type already has all items selected
        var isPresent = this.allCheckedUserTypes.indexOf(partnerId);
        if (isPresent != null && isPresent > -1) {
            // If all items already selected, must remove all items
            for (var i = 0; i < this.masterServiceComponentArray.length; i++) {
                if (this.masterServiceComponentArray[i].services_id && this.masterServiceComponentArray[i].is_modifiable != 'N') {
                    this.psmChecked(partnerId, this.masterServiceComponentArray[i].id, this.masterServiceComponentArray[i].services_id);
                }
                else {
                    if (this.masterServiceComponentArray[i].noComponents == true && this.masterServiceComponentArray[i].is_modifiable != 'N') {
                        this.psmChecked(partnerId, this.masterServiceComponentArray[i].id, null);
                    }
                    else if (this.masterServiceComponentArray[i].noComponents == false && this.masterServiceComponentArray[i].is_modifiable != 'N') {
                    }
                }
            }
            this.allCheckedUserTypes = this.allCheckedUserTypes.filter(function (item) { return item != partnerId; });
        }
        else {
            // If all items NOT already selected, must select all items
            this.allCheckedUserTypes.push(partnerId);
            for (var i = 0; i < this.masterServiceComponentArray.length; i++) {
                if (this.masterServiceComponentArray[i].services_id && this.masterServiceComponentArray[i].is_modifiable != 'N') {
                    this.psmChecked(partnerId, this.masterServiceComponentArray[i].id, this.masterServiceComponentArray[i].services_id, true);
                }
                else {
                    if (this.masterServiceComponentArray[i].noComponents == true && this.masterServiceComponentArray[i].is_modifiable != 'N') {
                        this.psmChecked(partnerId, this.masterServiceComponentArray[i].id, null, true);
                    }
                    else if (this.masterServiceComponentArray[i].noComponents == false && this.masterServiceComponentArray[i].is_modifiable != 'N') {
                    }
                }
            }
        }
    };
    SetupComponent.prototype.toggleServiceComponentsForUserType2 = function (partnerId, servicesId) {
        var isPresent = this.allCheckedUserTypesServices.indexOf("S" + partnerId + servicesId);
        if (isPresent != null && isPresent > -1) {
            this.allCheckedUserTypesServices = this.allCheckedUserTypesServices.filter(function (item) { return item != "S" + partnerId + servicesId; });
            var componentsToToggle = this.masterServiceComponentArray.filter(function (item) { return item.id == servicesId && item.services_id == null || item.services_id == servicesId; });
            for (var i = 0; i < componentsToToggle.length; i++) {
                if (componentsToToggle[i].services_id && componentsToToggle[i].is_modifiable != 'N') {
                    this.psmChecked(partnerId, componentsToToggle[i].id, componentsToToggle[i].services_id);
                }
                else {
                    if (componentsToToggle[i].noComponents == true && componentsToToggle[i].is_modifiable != 'N') {
                        this.psmChecked(partnerId, componentsToToggle[i].id, null, true);
                    }
                    else if (componentsToToggle[i].noComponents == false && componentsToToggle[i].is_modifiable != 'N') {
                    }
                }
            }
        }
        else {
            this.allCheckedUserTypesServices.push("S" + partnerId + servicesId);
            var componentsToToggle = this.masterServiceComponentArray.filter(function (item) { return item.id == servicesId && item.services_id == null || item.services_id == servicesId; });
            for (var i = 0; i < componentsToToggle.length; i++) {
                if (componentsToToggle[i].services_id && componentsToToggle[i].is_modifiable != 'N') {
                    this.psmChecked(partnerId, componentsToToggle[i].id, componentsToToggle[i].services_id, true);
                }
                else {
                    if (componentsToToggle[i].noComponents == true && componentsToToggle[i].is_modifiable != 'N') {
                        this.psmChecked(partnerId, componentsToToggle[i].id, null, true);
                    }
                    else if (componentsToToggle[i].noComponents == false && componentsToToggle[i].is_modifiable != 'N') {
                    }
                }
            }
        }
    };
    SetupComponent.prototype.internationalUnitValueChanged = function (unit) {
        var oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
        var unitTypeChanged = oldUnitsMasterArray.filter(function (item) { return item.unitType.id == unit.unit_type_id; });
        if (unitTypeChanged && unitTypeChanged.length > 0) {
            var index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
            if (index != null && index > -1) {
                var unitsInProject = oldUnitsMasterArray[index]['unitsInProject'];
                var unitChanged = unitsInProject.filter(function (item) { return item.tmp_id == unit.tmp_id; });
                if (unitChanged && unitChanged.length > 0) {
                    var index1 = unitsInProject.indexOf(unitChanged[0]);
                    unitsInProject[index1]['international_value'] = unit['international_value'];
                    oldUnitsMasterArray['unitsInProject'] = unitsInProject;
                    this.unitsMasterArray = Object.assign([], oldUnitsMasterArray);
                    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
                    this.setInternationalUnits();
                }
            }
        }
    };
    SetupComponent.prototype.updateUnitsMasterArrayCopy = function (args) {
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
    };
    SetupComponent.prototype.setInternationalUnits = function () {
        var _this = this;
        var _loop_16 = function (i) {
            var _loop_17 = function (j) {
                var intValue = this_11.unittypes.filter(function (item) { return item.international_unit_to_save == _this.unitsMasterArray[i]['unitsInProject'][j]['international_unit']; });
                if (intValue && intValue.length > 0) {
                    this_11.unitsMasterArray[i]['unitsInProject'][j]['international_unit'] = intValue[0]['international_unit'];
                }
            };
            for (var j = 0; j < this_11.unitsMasterArray[i]['unitsInProject'].length; j++) {
                _loop_17(j);
            }
        };
        var this_11 = this;
        for (var i = 0; i < this.unitsMasterArray.length; i++) {
            _loop_16(i);
        }
    };
    SetupComponent.prototype.projectEstYieldFieldsUpdateOnChange = function () {
        this.projectYieldWeightUnitsArray = this.unitsMasterArray.find(function (item) { return item.unitType.id == 3; }).unitsInProject;
        this.projectYieldAreaUnitsArray = this.unitsMasterArray.find(function (item) { return item.unitType.id == 4; }).unitsInProject;
        this.project.estimated_yield_weight_uom = this.projectYieldWeightUnitsArray.find(function (item) { return item.is_default == true; }).tmp_id;
        this.project.estimated_yield_area_uom = this.projectYieldAreaUnitsArray.find(function (item) { return item.is_default == true; }).tmp_id;
    };
    //Redirect to project management on click of breadcrumb
    SetupComponent.prototype.redirectToProjectManagement = function () {
        var url = "/projectmanagement";
        this.router.navigate([url], { queryParams: { from: this.fromNavId } });
    };
    return SetupComponent;
}());
export { SetupComponent };
