import { Component, AfterViewInit, ViewChild, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataConsentService } from '../../services/data-consent.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})

export class SignatureComponent implements AfterViewInit {

  @ViewChild('canvas') public canvas: ElementRef;

  @Input() public width = 400;
  @Input() public height = 160; // 400
  @Input() consentTemplate:SafeHtml;
  @Input() projectId:any;
  @Output() newItemEvent = new EventEmitter<object>();
  @Input() changeEvent:any;

  private canvasEl: HTMLCanvasElement;
    private context: CanvasRenderingContext2D;
    private paint: boolean;

    private clickX: number[] = [];
    private clickY: number[] = [];
    private clickDrag: boolean[] = [];

    agree = false;
    signature = {
      'agree': false,
      'value':''
    }
    privacyPolicyModal: BsModalRef;

    constructor(      
      private readonly modalService: BsModalService,
      private readonly dataConsentService:DataConsentService,
      private sanitizer: DomSanitizer,
      private route: ActivatedRoute
    ) {}

    ngAfterViewInit(): void {
        this.canvasEl = document.getElementById('canvas') as HTMLCanvasElement;
        this.canvasEl.width = 400;
        this.context = (<HTMLCanvasElement>this.canvasEl).getContext('2d');
        this.context.lineCap = 'round';
        this.context.lineJoin = 'round';
        this.context.strokeStyle = 'black';
        this.context.lineWidth = 1;
        if(!this.consentTemplate){
          this.getFarmerConsentTemplate();
        }

        this.redraw();
        this.createUserEvents();
      }
  
      ngOnChanges() {
        /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'changeEvent'**************/
        this.agree = false;
        this.clearCanvas();
      } 

  private createUserEvents() {
    let canvas = this.canvasEl;

    canvas.addEventListener("mousedown", this.pressEventHandler);
    canvas.addEventListener("mousemove", this.dragEventHandler);
    canvas.addEventListener("mouseup", this.releaseEventHandler);
    canvas.addEventListener("mouseout", this.cancelEventHandler);

    canvas.addEventListener("touchstart", this.pressEventHandler);
    canvas.addEventListener("touchmove", this.dragEventHandler);
    canvas.addEventListener("touchend", this.releaseEventHandler);
    canvas.addEventListener("touchcancel", this.cancelEventHandler);
  }

  public clearCanvas() {
      if(this.context){
        this.context
          .clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
      }
      this.clickX = [];
      this.clickY = [];
      this.clickDrag = [];
      if(this.context){
      this.getSignValues();
      }
  }

  private clearEventHandler = () => {
      this.clearCanvas();
  }

  private releaseEventHandler = () => {
      this.paint = false;
      this.redraw();
      this.agree = true;
      this.getSignValues();
  }

  private cancelEventHandler = () => {
      this.paint = false;
      this.getSignValues();
  }

  private pressEventHandler = (e: MouseEvent | TouchEvent) => {
      let r = this.canvasEl.getBoundingClientRect();
      let mouseX;
      if((e as TouchEvent).changedTouches) {
          mouseX = (e as TouchEvent).changedTouches[0].pageX - r.left;
      } else {
          mouseX = (e as MouseEvent).offsetX;
      }

      let mouseY;
      if((e as TouchEvent).changedTouches) {
          mouseY = (e as TouchEvent).changedTouches[0].pageY - r.top;
      } else {
          mouseY = (e as MouseEvent).offsetY;
      }

      this.paint = true;
      this.addClick(mouseX, mouseY, false);
      this.redraw();
  }

  private dragEventHandler = (e: MouseEvent | TouchEvent) => {
      let r = this.canvasEl.getBoundingClientRect();
      let mouseX;
      if((e as TouchEvent).changedTouches) {
          mouseX = (e as TouchEvent).changedTouches[0].pageX - r.left;
      } else {
          mouseX = (e as MouseEvent).offsetX;
      }

      let mouseY;
      if((e as TouchEvent).changedTouches) {
          mouseY = (e as TouchEvent).changedTouches[0].pageY - r.top;
      } else {
          mouseY = (e as MouseEvent).offsetY;
      }

      if (this.paint) {
          this.addClick(mouseX, mouseY, true);
          this.redraw();
      }

      e.preventDefault();
  }

  private redraw() {
      let clickX = this.clickX;
      let context = this.context;
      let clickDrag = this.clickDrag;
      let clickY = this.clickY;
      for (let i = 0; i < clickX.length; ++i) {
          context.beginPath();
          if (clickDrag[i] && i) {
              context.moveTo(clickX[i - 1], clickY[i - 1]);
          } else {
              context.moveTo(clickX[i] - 1, clickY[i]);
          }

          context.lineTo(clickX[i], clickY[i]);
          context.stroke();
      }
      context.closePath();
  }

  private addClick(x: number, y: number, dragging: boolean) {
      this.clickX.push(x);
      this.clickY.push(y);
      this.clickDrag.push(dragging);
  }

  getSignValues(){
    this.signature.agree = this.agree;
    const blank = this.isCanvasBlank(this.canvasEl);
    if(!blank){
      this.signature.value = this.canvasEl.toDataURL('image/webp', 0.5);
    } else {
      this.signature.value = ''
    }
    this.newItemEvent.emit(this.signature);
  }

  isCanvasBlank(canvas) {
    const context = canvas.getContext('2d');
  
    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );
  
    return !pixelBuffer.some(color => color !== 0);
  }

  viewPrivacyPolicy(template) {
    this.privacyPolicyModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
  }

  closePrivacyPolicy(){
    if(this.privacyPolicyModal){
      this.privacyPolicyModal.hide();
    }
  }

  async getFarmerConsentTemplate(){
    const requests = await Promise.all([
      this.dataConsentService.getConsentTemplate(this.projectId).toPromise()
    ]);
    if(requests[0].msg == 'success' && requests[0].data){
      this.consentTemplate = this.sanitizer.bypassSecurityTrustHtml(requests[0].data.template_content);
    }
  }

  //  //added by Kiran for escape button click event
  //  @HostListener('window:keyup', ['$event']) onKeydownHandler(event: KeyboardEvent) {
  //   if (event.key === "Escape") {
  //     this.closePrivacyPolicy()
  //   }
  // }

}
