import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gridFilter'
})
export class GridFilterPipe implements PipeTransform {
    transform(items: any, filter: any, defaultFilter: boolean): any {
        if (!filter) {
          return items;
        }
        if (!Array.isArray(items)) {
          return items;
        }
        if (filter && Array.isArray(items)) {
          const filterKeys = Object.keys(filter);
          if (defaultFilter) {
            return items.filter(item =>
                filterKeys.reduce((x, keyName) =>
                    (x && new RegExp(filter[keyName], 'gi').test(this.stripUnwantedChars(item[keyName]))) || filter[keyName] === '', true));
          } else {
            return items.filter(item => {
              return filterKeys.some((keyName) => {

                if (filter[keyName] != null && item[keyName] !== null && item[keyName]  && (item[keyName]).hasOwnProperty('name')) {
                    return new RegExp(filter[keyName].trim(), 'gi').test(this.stripUnwantedChars(item[keyName]['name']));

                } else if (filter[keyName] != null && item[keyName] !== null) {
                    return new RegExp(filter[keyName].trim(), 'gi').test(this.stripUnwantedChars(item[keyName]));
                } else {
                  return new RegExp(filter[keyName], 'gi').test(this.stripUnwantedChars(item[keyName]));
                }
              });
            });
          }
        }
      }

      // remove problematic characters before string is passed to regex check
      stripUnwantedChars(string) {
        if (string) {
          let output = string.toString().replace('(', '');
          output = output.replace(')', '');
          output = output.replace('[', '');
          output = output.replace(']', '');
          output = output.replace('{', '');
          output = output.replace('}', '');
          return output;
        }
    }
}
