
import { Directive, Input, Renderer2, ElementRef, AfterContentInit, Injector } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, NgModel } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngModel][customMax]',
  providers: [{provide: NG_VALIDATORS, useExisting: CustomMaxDirective, multi: true}]
})


export class CustomMaxDirective implements Validator, AfterContentInit {
  @Input()
  customMax: number;

  constructor(private readonly renderer: Renderer2,   private readonly elementRef: ElementRef,
    private readonly _injector: Injector) {  }

  ngAfterContentInit() {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'max', this.customMax.toString());
  }
  validate(c: FormControl): {[key: string]: any} {
    const that = this;
      const v = +c.value;
      if (v && v > this.customMax) {
        that.renderer.addClass(that.elementRef.nativeElement, 'ng-invalid');
        return {'customMax': true};
      } else {
        that.renderer.addClass(that.elementRef.nativeElement, 'ng-valid');
        if (c.value==='') { 
          that._injector.get(NgModel).update.emit(null); 
        }

        return null;
      }

  }
}
