import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
var parentScope01;
var IntegratedDashboardComponent = /** @class */ (function () {
    function IntegratedDashboardComponent(route, projectService, toastrService, router, activeRoute, translate, changeDetectionRef, renderer, hcRService) {
        this.route = route;
        this.projectService = projectService;
        this.toastrService = toastrService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.translate = translate;
        this.changeDetectionRef = changeDetectionRef;
        this.renderer = renderer;
        this.hcRService = hcRService;
        this.projects = [];
        this.projectId = 0;
        this.dashboardData = [];
        this.reportIDs = [];
        this.predefinedReports = [];
    }
    IntegratedDashboardComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requestObject;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.dashboardId = this.route.snapshot.params['id2'];
                this.projectId = this.route.snapshot.params['id'];
                this.hcRService.allCharts = [];
                this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
                    _this.project = response.data;
                    var projectObject = _this.project.filter(function (item) { return item.id == _this.projectId; })[0];
                    _this.project = projectObject;
                });
                Highcharts.getSVG = function (charts) {
                    var svgArr = [], top = 0, width = 0, endWidth = 0;
                    Highcharts.each(charts, function (chart) {
                        var svg = chart.getSVG(), 
                        // Get width/height of SVG for export
                        svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/)[1], svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/)[1];
                        svg = svg.replace('<svg', '<g transform="translate(' + width + ', ' + top + ')" ');
                        svg = svg.replace('</svg>', '</g>');
                        //console.log(" svgWidth ", svgWidth);
                        //console.log(" width before ", width);
                        width += svgWidth;
                        //console.log(" width After ", width);
                        endWidth = Math.max(endWidth, width);
                        //console.log("endWidth ", endWidth);
                        if (width === 2 * svgWidth) {
                            width = 0;
                            top += svgHeight;
                        }
                        //console.log("top ", top);
                        svgArr.push(svg);
                    });
                    top += 400;
                    return '<svg height="' + top + '" width="' + endWidth +
                        '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
                        svgArr.join('') + '</svg>';
                };
                Highcharts.exportCharts = function (charts, options) {
                    // Merge the options
                    options = Highcharts.merge(Highcharts.getOptions().exporting, options);
                    // Post to export server
                    Highcharts.post(options.url, {
                        filename: options.filename || 'chart',
                        type: options.type,
                        width: options.width,
                        svg: Highcharts.getSVG(charts)
                    });
                };
                Highcharts.setOptions({
                    lang: {
                        noData: 'No data to display'
                    }
                });
                requestObject = {
                    //"dashboardId":this.dashboardId
                    "dashboardId": this.dashboardId
                };
                this.hcRService.getDashboardDetailsById(requestObject).subscribe(function (response) {
                    //console.log("get Dashboard Data ", response);
                    if (response.msg == 'success') {
                        _this.dashboardData = response.data;
                        if (_this.dashboardData.length > 0) {
                            //console.log("this.dashboardData ", this.dashboardData);
                            var dashboardSelectedReports = [];
                            for (var i = 0; i < _this.dashboardData[0].hc_report_dashboard_dtl.length; i++) {
                                dashboardSelectedReports.push(_this.dashboardData[0].hc_report_dashboard_dtl[i]);
                                //this.dashboardEditObject.section = dashboardData[0].hc_report_dashboard_dtl[0].section;
                            }
                            if (dashboardSelectedReports && dashboardSelectedReports.length > 0) {
                                for (var i = 0; i < dashboardSelectedReports.length; i++) {
                                    _this.reportIDs.push(dashboardSelectedReports[i].report_id);
                                }
                                //console.log(" this.reportIDs ", this.reportIDs);
                                if (_this.reportIDs && _this.reportIDs.length > 0) {
                                    _this.genarateCharts(_this.reportIDs);
                                }
                            }
                        }
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    IntegratedDashboardComponent.prototype.genarateCharts = function (reportIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requestObject, chartsList, contextMenuOptions;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                requestObject = {};
                requestObject = {
                    "reportID": reportIds,
                    "projectId": this.projectId
                };
                chartsList = [];
                contextMenuOptions = [
                    // {
                    //   text: 'Change chart type',
                    //   onclick: function(this){
                    //     let self = this;
                    //     parentScope01.changeChartType(self);
                    //   }
                    //   },
                    {
                        textKey: 'downloadPDF',
                        onclick: function () {
                            this.exportChart({
                                type: 'application/pdf'
                            });
                        }
                    },
                    {
                        textKey: 'downloadPNG',
                        onclick: function () {
                            this.exportChart();
                        }
                    },
                    {
                        textKey: 'downloadXLS',
                        onclick: function () {
                            this.downloadXLS();
                        }
                    },
                ];
                this.hcRService.getReports(requestObject).subscribe(function (response) {
                    //console.log("response ", response);
                    if (response.msg == 'success') {
                        var reportsData = response.data;
                        _this.predefinedReports = response.data;
                        //console.log("reportsData ", reportsData);
                        if (reportsData && reportsData.length > 0) {
                            for (var i = 0; i < reportsData.length; i++) {
                                var seriesArr = [];
                                if (reportsData[i].conf && reportsData[i].conf.length > 0) {
                                    var chartOptions = reportsData[i].conf[0].conf;
                                    if (typeof reportsData[i].conf[0].conf !== 'object') {
                                        chartOptions = JSON.parse(reportsData[i].conf[0].conf);
                                    }
                                    if (reportsData[i].name === 'Household Income') {
                                        if (reportsData[i].series && reportsData[i].series.length > 1) {
                                            var hcObject = {};
                                            var categories = [];
                                            var objectKeys = Object.keys(reportsData[i].series[0]);
                                            for (var j = 0; j < reportsData[i].series.length; j++) {
                                                var elementItem = reportsData[i].series[j];
                                                for (var k = 0; k < objectKeys.length; k++) {
                                                    if (objectKeys[k] == 'name') {
                                                        categories.push(reportsData[i].series[j][objectKeys[k]]);
                                                    }
                                                    else {
                                                        if (hcObject[objectKeys[k]]) {
                                                            hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                                                        }
                                                        else {
                                                            hcObject[objectKeys[k]] = new Array();
                                                            hcObject[objectKeys[k]].push(Number(elementItem[objectKeys[k]]));
                                                        }
                                                    }
                                                }
                                            }
                                            if (Object.keys(hcObject).length !== 0) {
                                                for (var key in hcObject) {
                                                    var object_1 = {
                                                        name: '',
                                                        data: []
                                                    };
                                                    object_1.name = key;
                                                    object_1.data = hcObject[key];
                                                    seriesArr.push(object_1);
                                                }
                                            }
                                            //console.log(" seriesArr time ", seriesArr);
                                            if (chartOptions.hasOwnProperty('xAxis')) {
                                                chartOptions.xAxis.categories = categories;
                                            }
                                            if (chartOptions.hasOwnProperty('yAxis')) {
                                                chartOptions.yAxis.min = 0;
                                            }
                                            chartOptions.series = seriesArr;
                                        }
                                    }
                                    else if (reportsData[i].name === 'Accessibility to Basics') {
                                        var categories = [];
                                        //console.log(reportsData[i]);
                                        for (var j = 0; j < reportsData[i].series.length; j++) {
                                            var object = {
                                                name: 'Farmers',
                                                data: []
                                            };
                                            for (var prop in reportsData[i].series[j]) {
                                                if (reportsData[i].series[0].hasOwnProperty(prop)) {
                                                    categories.push(prop);
                                                    // object.name = key;
                                                    object.data.push(Number(reportsData[i].series[0][prop]));
                                                }
                                            }
                                            seriesArr.push(object);
                                        }
                                        if (chartOptions.hasOwnProperty('xAxis')) {
                                            chartOptions.xAxis.categories = categories;
                                        }
                                        if (chartOptions.hasOwnProperty('yAxis')) {
                                            chartOptions.yAxis.min = 0;
                                        }
                                        chartOptions.series = seriesArr;
                                    }
                                    chartOptions.exporting.buttons.contextButton.menuItems = contextMenuOptions;
                                    // chartOptions.chart.width = 320;
                                    chartOptions.chart.height = '50%';
                                    chartOptions.chart_type = chartOptions.chart.type;
                                    chartOptions.title.style = {
                                        fontSize: "16px",
                                        fontWeight: '600'
                                    };
                                    chartOptions.reportId = reportsData[i].reportId;
                                    if (reportsData[i].is_drilldown == 1) {
                                        chartOptions.drilldown = reportsData[i].drilldown;
                                    }
                                    //chartOptions.reportTypeFrontend = currentType;
                                    chartOptions.chart.backgroundColor = '#fafafa';
                                    chartOptions.chart.spacingTop = 20;
                                    chartOptions.chart.spacingBottom = 20;
                                    chartOptions.reportName = reportsData[i].name;
                                    //console.log("chartOptions ", chartOptions);
                                    chartsList.push(chartOptions);
                                }
                                else {
                                    //console.log("conf 0 ");
                                }
                            }
                        }
                        else {
                            _this.chartElement01.nativeElement.innerHTML = '';
                            _this.hcRService.allCharts = [];
                        }
                        if (chartsList && chartsList.length > 0) {
                            // console.log("chartsList ", chartsList);
                            for (var i = 0; i < chartsList.length; i++) {
                                //chartsList[i].exporting.buttons.contextButton.menuItems = contextMenuOptions; 
                                if (chartsList[i].reportName == 'Household Income') {
                                    var e = document.createElement("div");
                                    var newElement = _this.chartElement01.nativeElement.appendChild(e);
                                    //Added by Kiran to replace math.random to windows.crypto
                                    var array = new Uint32Array(1);
                                    window.crypto.getRandomValues(array);
                                    newElement.setAttribute('id', +array[0].toString().slice(2, 6));
                                    _this.hcRService.createChart(newElement, chartsList[i]);
                                }
                                else if (chartsList[i].reportName == 'Accessibility to Basics') {
                                    var e = document.createElement("div");
                                    var newElement = _this.chartElement02.nativeElement.appendChild(e);
                                    //Added by Kiran to replace math.random to windows.crypto
                                    var array = new Uint32Array(1);
                                    window.crypto.getRandomValues(array);
                                    newElement.setAttribute('id', +array[0].toString().slice(2, 6));
                                    _this.hcRService.createChart(newElement, chartsList[i]);
                                }
                            }
                            //console.log("this.chartService.allCharts ", this.hcRService.allCharts);
                        }
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    IntegratedDashboardComponent.prototype.downloadAsPDF = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var charts;
            return tslib_1.__generator(this, function (_a) {
                charts = this.hcRService.allCharts;
                Highcharts.exportCharts(charts, {
                    type: 'application/pdf'
                });
                return [2 /*return*/];
            });
        });
    };
    IntegratedDashboardComponent.prototype.changeChartType = function (currentItemScope) {
        //debugger;
        //parentScope01.changeChartType
        //console.log("currentItemScope ", currentItemScope);
        //console.log("currentItemScope Before ", currentItemScope.userOptions.chart.type);
        if (currentItemScope.userOptions.chart.type == 'column') {
            currentItemScope.update({
                chart: {
                    type: 'pie'
                }
            });
        }
        else if (currentItemScope.userOptions.chart.type == 'pie') {
            currentItemScope.update({
                chart: {
                    type: 'column'
                }
            });
        }
    };
    IntegratedDashboardComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    IntegratedDashboardComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    IntegratedDashboardComponent.prototype.goToDashboards = function () {
        var url = "charts/dashboards/" + this.projectId;
        this.router.navigate([url]);
    };
    return IntegratedDashboardComponent;
}());
export { IntegratedDashboardComponent };
