import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, HostListener, Renderer, Inject, LOCALE_ID } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { map, mapTo } from 'rxjs/operators';
import { Observable, fromEvent, merge, of, Subscription } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { environment } from '../../../environments/environment';

import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { UserService } from '../../services/user.service';


import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TrainingService } from '../../services/training.service';
import { DataConsentService } from '../../services/data-consent.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


import jsPDF from 'jspdf';
import 'jspdf-autotable';


declare var idbApp: any;
declare var idbAppPhoto: any;

declare var $: any;

declare var serviceWorkerVar;

@Component({
  selector: 'app-farmermanagement',
  templateUrl: './farmermanagement.component.html',
  styleUrls: ['./farmermanagement.component.css'],
  host: { '(window:partial-synced)': 'syncDone($event)' }
})
export class FarmermanagementComponent implements OnInit, AfterViewInit, OnDestroy {
  isOnline: Observable<boolean>;
  projectId = 0;
  project: any;
  farmers = [];
  productType = [];
  managers = [];

  productTypeName: string;
  managerName: string;

  isAscending = true;
  isAscendingId = true;
  isAscendingName = true;
  isAscendingTel = true;
  isAscendingDate = true;
  isAscendingAge = true;
  isAscendingSex = true;
  isAscendingVillage = true;
  isAscendingVC = true;// VC name sort added
  isAscendingFarmerNo = true; //Official farmer id added 

  sortLabel = '';

  public showSpinner = false;

  isFieldAgent = false;
  isManager = false;
  isDeeproots = false;

  farmerFilterString: any = { name: '' };

  page = 1;
  itemsPerPage = 10;
  public searchText: any;
  public previousPaginationLabel;
  public nextPaginationLabel;
  public isInIDBInThisProject = [];
  public showSyncSuccessAlert = false;
  public closeSnapshotModal: BsModalRef;
  public modifySnapshotModal: BsModalRef;

  public allSnapshots;
  public snapshot;
  public olderSnapshots = [];

  public snapshotNameTemp;
  public bsDateRange;
  public snapshotModifying;
  public oldSnapshotInfo;

  public minDate = new Date();
  public projectUndefined;

  public getSnapshotInterval;
  public noSnapshotsInProject = false;
  public fetchingSnapshotInfo = true;

  public disableCreateSnapshot = false;
  public disableCloseSnapshot = false;

  public registerFarmerModal;
  public updateFarmerModal;
  public registeringFarmerName;
  public registeringFarmerAge;
  public registeringFarmerSex;
  public registeringFarmerArea;
  public registeringFarmer = false;

  public master;
  public sex;
  public registerFarmerForm: FormGroup;
  public updateFarmerForm: FormGroup;
  public registerAnotherFarmer = false;
  fileToUpload: File = null;
  photoModel = '';

  oldSnapshotId = 0;
  arrSnapshotRevisions = [];
  snapshotRevision = [];
  public id: any;
  public projectData: any;

  public confirmModal = true;
  public confirmModalRegistration = true;

  deletingFarmer: any;
  deleteFarmerModal: BsModalRef;

  farmerUpdating;
  registerFarmerGender = 3;
  public addFarmer = false;
  public editFarmer = false;
  selectedFarmers = [];
  farmerPrinting;

  // tranlsate service
  thereNoActiveSnapshotForThisProject;

  ageSliderOptions = {
    floor: 18,
    ceil: 99
  };

  farmerAgeSliderValue = 18;
  farmerAgeSliderValueUpdate = 18;

  accessSub: Subscription;
  uamData;

  canRead = false;
  canWrite = false;
  canDelete = false;
  currentUser;
  UPM;
  usersServiceComponentMappings;
  ecosystemServicesComponentsMapping;
  canExportToExcel = false;
  canPrintIDCard = false;
  canEmailIDCard = false;
  canExportTraining = false;
  uploadingFileName;
  noFileChosenDefault; // default text for input file component
  revisionTxt;
  createSuccessTxt;
  selectedPrinterType;
  selectPrinterModal;
  missingDataValuesDuringPrint;
  selectedPrintItems;
  farmerLabel;
  tooltipText;
  tooltipText2;
  selectDeselectLabels;  
  trainingDetailsModal;
  missingTrainings = [];
  farmerMissingTrainings = [];  
  public selectTypeModal: BsModalRef;  
  confirmTypeModal = true;  
  selectedType;
  exportTypes;
  consentTemplate:SafeHtml;
  consentFarmersData;
  farmerCertifications = [];
  certificationsCompleted;
  canExportConsent = false;
  consentTemplateNotAvailable;
  farmerConsent;
  consentNotAvailable;
  noConsentFoundLanguage;
  discontinueFarmerModal: BsModalRef;
  discontinuingFarmer;
  filteredfarmers = [];
  constructor(
    private readonly route: ActivatedRoute,
    private readonly commonService: CommonService,
    private readonly router: Router,
    private readonly farmerService: FarmerService,
    private readonly projectService: ProjectService,
    private readonly translate: TranslateService,
    public toastrService: ToastrService,
    public modalService: BsModalService,
    private readonly localeService: BsLocaleService,
    private readonly formBuilder: FormBuilder,
    private readonly revisionService: RevisionService,
    private readonly renderer: Renderer,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly userService: UserService,
    private readonly trainingService: TrainingService,
    private readonly dataConsentService:DataConsentService,
    private readonly dynamicQuestionnaireService: DynamicQuestionnaireService,
    private sanitizer: DomSanitizer,
    @Inject(LOCALE_ID) private readonly locale: string) {

    if (this.locale && this.locale.length > 2) {
      this.locale = this.locale.substr(0, 2);
    }
  

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.isOnline = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });

    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });

    this.translate.get('file_upload_component_default_txt').subscribe(result => {
      this.noFileChosenDefault = result; 
    });

    this.translate.get('revision').subscribe(result => {
      this.revisionTxt = result;
    });
    this.translate.get('created_successfully').subscribe(result => {
      this.createSuccessTxt = result; 
    });

    this.translate.get('there_no_active_snapshot_for_this_project').subscribe(result => {
      this.thereNoActiveSnapshotForThisProject = result;
    });

    this.translate.get('farmer').subscribe(result => {
      this.farmerLabel = result;
    });

    this.translate.get('select_deselect_object').subscribe(result => {
      this.selectDeselectLabels = result;
    });

    this.translate.get('data_will_be_synced').subscribe(result => {
      this.tooltipText2 = result;
    });

    this.translate.get('certifications_completed').subscribe(result => {
      this.certificationsCompleted = result;
    });

    this.translate.get('consent_template_not_available').subscribe(result => {
      this.consentTemplateNotAvailable = result;
    });

    this.translate.get('consent_not_available_selected_farmers').subscribe(result => {
      this.consentNotAvailable = result;
    });

    this.translate.get('no_consent_found_this_language').subscribe(result => {
      this.noConsentFoundLanguage = result;
    });

  }
  snapshotChanged() {
    this.snapshotRevision = this.arrSnapshotRevisions.filter(x => x.snapshot_id_ref === this.oldSnapshotId);
  }

  async ngOnInit() {
    this.scrollToTop();
    this.projectId = +this.route.snapshot.params['id'];
    const code = this.commonService.checkRole();
    this.currentUser = this.userService.getCurrentUser();
    
    if (code == 'FAG') {
      this.isFieldAgent = true;
    }
    else if (code === 'PMG') {
      this.isManager = true;
    }
    else if (code === 'SYS1') {
      this.isDeeproots = true;
    }

    if (navigator.onLine) {
      this.project = this.route.snapshot.data.project.project;      

      if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
        this.UPM = this.project.ProjectMapping.find(item => item.users_id === this.currentUser.id);
      }

      if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
        this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
      }

      if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
        this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
      }
      

      const requests = await Promise.all([
        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
        this.trainingService.getFarmerTrainingIndicators(this.projectId).toPromise(),
        this.trainingService.getFarmerExportTypes().toPromise(),
        this.dataConsentService.getConsentTemplate(this.projectId).toPromise(),
        this.trainingService.getFarmerCertificationList(this.projectId).toPromise()
      ]);
      this.farmers = requests[0].data;
      if(requests[1].msg == 'success'){
        this.missingTrainings = requests[1].data;
      }
      for(const item of this.farmers){
        item.village_name = item.region_village.village_name;
        item.trainingMissed = false;
      }
      for (let [key, value] of Object.entries(this.missingTrainings)) {
        const farmer = this.farmers.find(item => item.id == key);
        if(farmer){
          farmer.trainingMissed = true;
        }
      }
      if(requests[2].msg == 'success'){
        this.exportTypes = requests[2].data;
      }
      if(requests[3].msg == 'success'&& requests[3].data){
        this.consentTemplate = this.sanitizer.bypassSecurityTrustHtml(requests[3].data.template_content);
      }
      if(requests[4].msg == 'success'){
        this.farmerCertifications = requests[4].data;
      }
      for (const [key, value] of Object.entries(this.farmerCertifications)) {
        const farmer = this.farmers.find(item => item.id == key);
        if(farmer){
          let certificationString = `${this.certificationsCompleted} : `;
          value.forEach((item, index) => {
            if(index == value.length - 1){              
              certificationString = `${certificationString}${item.shortname}`;
            } else {
              certificationString = `${certificationString}${item.shortname}, `;
            }
          });
          farmer.certificationsCompleted = certificationString;
        }
      }
      this.setOfflineData();
      this.getFarmerConsents();

    } else {

      const requests = await Promise.all([
        this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
        this.projectService.getProjectProfilingData(this.projectId).toPromise(),
        this.projectService.getProjectDashboardData(this.projectId).toPromise()
        
      ]);

      this.project = this.route.snapshot.data.project.project;
      
      if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
        this.UPM = this.project.ProjectMapping.find(item => item.users_id === this.currentUser.id);
        
      }

      if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
        this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
      }
      if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
        this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
      } 

      this.farmers = requests[1].data;
      this.setFarmerRegData();
      
      // Get any farmers saved in indexed db for this project
      this.setOfflineData();
      this.getFarmerConsents();

      // for(let i=0;i<this.farmers.length;i++){
      //   if(this.farmers[i].region_village && this.farmers[i].region_village != null){
      //     this.farmers[i]['village_name'] = this.farmers[i].region_village.village_name;
      //   }else{
      //     this.farmers[i]['village_name'] = '';
      //   }
      //   if(this.farmers[i].vc_farmer_mappings && this.farmers[i].vc_farmer_mappings.length > 0){
      //     for(let j=0;j< this.farmers[i].vc_farmer_mappings.length;j++){
      //       this.farmers[i]['vc_name'] = this.farmers[i].vc_farmer_mappings[j].users.name;
      //     }
      //   }else{
      //     this.farmers[i]['vc_name'] = '';
      //   }
      //   console.log(this.farmers[i].id, this.farmers[i]['vc_name']);
      // }


      //  this.master = requests[2].data;

      //  const snapshotData  = (await this.projectService.getAllSnapshots().toPromise()).data;
      //  // console.log('snapshotData', snapshotData);
      //   let projectSnapshots = snapshotData.filter(x => x.project == this.projectId);
      //  // console.log('projectSnapshots', projectSnapshots);
      //   if (projectSnapshots && projectSnapshots.length > 0) {
      //     this.allSnapshots = projectSnapshots[0].snapshots;
      //   } else {
      //     console.log('projectSnapshots is null or length 0');
      //     this.allSnapshots = [];
      //   }
      //   this.setSnapshots();

    }

    // For non-admin roles, define the services displayed array based on the services mapped to this project
    if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
     
      const canExportToExcel = this.usersServiceComponentMappings.filter(item => (item.services_components_mapping && item.services_components_mapping.id === 3 && item.user_type_id === this.currentUser.role.id));
      if (canExportToExcel && canExportToExcel.length > 0) {
        this.canExportToExcel = true;
      }     

      const canPrintIDCard = this.usersServiceComponentMappings.filter(item => (item.services_components_mapping && item.services_components_mapping.id === 1 && item.user_type_id === this.currentUser.role.id));
      if (canPrintIDCard && canPrintIDCard.length > 0) {
        this.canPrintIDCard = true;
      }

      const canEmailIDCard = this.usersServiceComponentMappings.filter(item => (item.services_components_mapping && item.services_components_mapping.id === 2 && item.user_type_id === this.currentUser.role.id));
      if (canEmailIDCard && canEmailIDCard.length > 0) {
        this.canEmailIDCard = true;
      }

      const canExportTraining = this.usersServiceComponentMappings.filter(item => (
        item.services_components_mapping && item.services_components_mapping.code === "FR_TRA_EXP" && item.user_type_id === this.currentUser.role.id)
      );
      if (canExportTraining && canExportTraining.length > 0) {
        this.canExportTraining = true;
      }

      const canExportConsent = this.usersServiceComponentMappings.filter(item => (
        item.services_components_mapping && item.services_components_mapping.code === "FR_CON_EXP" && item.user_type_id === this.currentUser.role.id)
      );
      if (canExportConsent && canExportConsent.length > 0) {
        this.canExportConsent = true;
      }  
      
    }

    // For admin roles, define the services displayed array based on the services mapped to the ecosystem - whether or not mapped to the project
    else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
      const canExportToExcel = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id === 3));
      if (canExportToExcel && canExportToExcel.length > 0) {
        this.canExportToExcel = true;
      }      

      const canPrintIDCard = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id === 1));
      if (canPrintIDCard && canPrintIDCard.length > 0) {
        this.canPrintIDCard = true;
      }

      const canEmailIDCard = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id === 2));
      if (canEmailIDCard && canEmailIDCard.length > 0) {
        this.canEmailIDCard = true;
      }
      const canExportTraining = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.code === "FR_TRA_EXP"));
      if (canExportTraining && canExportTraining.length > 0) {
        this.canExportTraining = true;
      }

      const canExportConsent = this.ecosystemServicesComponentsMapping.filter(item => (
        item.services_components_mapping && item.services_components_mapping.code === "FR_CON_EXP")
      );
      if (canExportConsent && canExportConsent.length > 0) {
        this.canExportConsent = true;
      }
      
    }


    this.updateFarmerForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      age: new FormControl('', [Validators.required, Validators.min(18), Validators.max(99)]),
      sex: new FormControl(3, [Validators.required]),
      area: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email]),
      telNumber: new FormControl('', [Validators.pattern("^[0-9]{5,13}$"),
      Validators.minLength(5), Validators.maxLength(13)]),
      photo_path: new FormControl('')
    });

    this.registerFarmerForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      age: new FormControl('', [Validators.required, Validators.min(18), Validators.max(99)]),
      sex: new FormControl(3, [Validators.required]),
      area: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email]),
      telNumber: new FormControl('', [Validators.pattern("^[0-9]{5,13}$"),
      Validators.minLength(5), Validators.maxLength(13)]),
      photo_path: new FormControl('')
    });


    this.setVCMapping();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
   
    if (this.addFarmer) {
      this.declineRegisterFarmerPrompt(this.registerFarmerForm);
    } else if (this.editFarmer) {
      this.declineUpdateFarmerPrompt(this.updateFarmerForm);
    }
    this.closeMissingTrainingPopup();
    this.closeTypePopup(true)
  }


  async setOfflineData() {
    // Find the farmer data in indexed db corresponding to this project & append to the farmers array;
    var farmertmpobj = await idbApp.getAllProjects('farmerstmp');
    const idbAppFarmers = farmertmpobj.filter(x => x.project_id == this.projectId && x.savetab == 'Tab0');
    if (idbAppFarmers && idbAppFarmers.length > 0) {
      idbAppFarmers.sort(function (a, b) {
        const aDate = new Date(a.cr_date);
        const bDate = new Date(b.cr_date);
        return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
      })
      for (let i = 0; i < idbAppFarmers.length; i++) {
        idbAppFarmers[i]['region_village'] = this.project.ecosystem_region_master.region_villages.find(item => item.id === idbAppFarmers[i]['village_area_id']);
      }
      this.farmers = idbAppFarmers.concat(this.farmers);
    }

    // If any farmer has Tab00 data as well (1st tab updated data), then find that farmer from indexed db
    // .. and replace that farmer object in the this.farmers array
    const idbAppFarmersUpdatedOffline = farmertmpobj.filter(x => x.project_id == this.projectId && x.savetab == 'Tab00');
    if (idbAppFarmersUpdatedOffline && idbAppFarmersUpdatedOffline.length > 0) {
      
      for (let i = 0; i < idbAppFarmersUpdatedOffline.length; i++) {
        let farmerUpdated;
        if (idbAppFarmersUpdatedOffline[i].id > 0) {
        
          farmerUpdated = this.farmers.filter(item => item.id === idbAppFarmersUpdatedOffline[i].id);
        }
        else {
         
          farmerUpdated = this.farmers.filter(item => item.tmp_id === idbAppFarmersUpdatedOffline[i].tmp_id.slice(0, -1));
        }

        if (farmerUpdated && farmerUpdated.length > 0) {
          const index = this.farmers.indexOf(farmerUpdated[0]);
         
          if (index != null && index > -1) {
            const villageCollectors = this.farmers[index].village_collectors;
            this.farmers[index] = idbAppFarmersUpdatedOffline[i];
            this.farmers[index]['region_village'] = this.project.ecosystem_region_master.region_villages.find(item => item.id == this.farmers[index]['village_area_id']);
            this.farmers[index]['village_collectors'] = villageCollectors;
          }
        }
      }
    }
      this.filteredfarmers = this.farmers;
    this.sortFarmers();
    this.setIndicatorValues();
    this.setOfflineSignData();
  }

  async setOfflineSignData(){    
    // added by KRPT for checking offline signature
    var farmersignobj = await idbAppPhoto.getAllProjects('farmerSignaturetmp');
    const faremerWithTemId = this.farmers.filter(x => x.tmp_id);
    for(let item of faremerWithTemId){
      const findSignature = farmersignobj.find(x => x.tmp_id == item.tmp_id);
      if(findSignature){
        item.consentPending = false;
        item.valid_upto = 730;
      }
    }
}

  getBackProjectFilter(projectNameVal, projectId) {
    const url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  getProjectData() {
    this.projectService.GetProject(this.id).subscribe(response => {
      this.projectData = response.data;
      
    });
  }


  async syncDone(args) {
    if (navigator.onLine) {
      window.dispatchEvent(new Event('caching-now'));
      const request = await Promise.all([
        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
        // this.dynamicQuestionnaireService.getQAnswersForProject(this.projectId, null).toPromise(),
        this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise()
      ]);
      this.farmers = request[0].data;

      await Promise.all([
        this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise()
      ])
      .then(result => {
        if (result[0]['code'] === "success") {
          for (let i = 0; i < result[0]['message'].length; i++) {
            this.loadFarmerDataIntoCache(this.projectId, result[0]['message'][i], result[0]['message'][i+1]);
          }
        }
      }).catch(e => {});

      
      this.setOfflineData();
      this.getFarmerConsents();

      window.dispatchEvent(new Event('data-synced'));
    }
  }

  goToDashboard(): void {
    this.router.navigate(['dashboard']);
  }

  goToViewFarmer(farmer): void {
    let url;
    if (farmer.id && farmer.id > 0) {
      url = `/farmerprofileinfo/${farmer.project_id}/${farmer.id}`;
    } else if (farmer.tmp_id && !navigator.onLine) {
      url = `/farmerprofileinfo/${farmer.project_id}/${farmer.tmp_id}`;
    } else if (farmer.tmp_id && navigator.onLine) {
      this.commonService.showToast('warning', 'please_wait_data_sync', {preventDuplicates: true});
      return;
    } else {
      url = '/error';
    }
    this.router.navigate([url]);
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  ngAfterViewInit() {
    if (this.project && this.project.QuesProjectSnapshotMappings && this.project.QuesProjectSnapshotMappings.length > 0) {
      // If the active snapshot is in draft stage, fetch complete QuesProjectSnapshotMappings object from server

      // If the active snapshot is not in draft stage, fetch the complete QuesProjectSnapshotMappings object from the cache...
      // ... have to wait for the managerpage ngAfterViewInit function to finish getting the response for this to work
    }
  }

  getGridFilters() {
    //new fields added for search village_name and vc_name
    const gridColums = ['farmer_code', 'name', 'official_id', 'age', 'village_name', 'cr_date'];
    const finalObj = {};
    if (this.farmers === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }


  ngOnDestroy() {
    this.showSpinner = false;
    clearInterval(this.getSnapshotInterval);
  }

  goToEditProject() {
    if (navigator.onLine) {
      const url = `setup/${this.project.id}`;
      this.router.navigate([url]);
    } else {
      this.toastrService.warning('You cannot edit a project while offline');
    }
  }

  sortBy(sortOn: string) {



    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);

  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    this.isAscendingId = sortBy !== 'id';
    this.isAscendingName = sortBy !== 'name';
    this.isAscendingTel = sortBy !== 'tel_number';
    this.isAscendingDate = sortBy !== 'cr_date';
    this.isAscendingAge = sortBy !== 'age';
    this.isAscendingFarmerNo = sortBy !== 'official_id';
    this.isAscendingSex = sortBy !== 'sex';
    // Sort of new fields added
    this.isAscendingVillage = sortBy !== 'village_name';
    this.isAscendingVC = sortBy === 'village_collectors'
    // Sort of new fields added
    if (sortBy === 'name' || sortBy === 'village_name' || sortBy === 'village_collectors' || sortBy === 'official_id') {
      this.farmers.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
      });
    } else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'tel_number' || sortBy === 'cr_date') {
      this.farmers.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }

  }

  descendic(sortBy: string) {
    this.isAscending = true;

    this.isAscendingId = sortBy === 'id';
    this.isAscendingName = sortBy === 'name';
    this.isAscendingTel = sortBy === 'tel_number';
    this.isAscendingDate = sortBy === 'cr_date';
    this.isAscendingAge = sortBy === 'age';    
    this.isAscendingFarmerNo = sortBy === 'official_id';
    this.isAscendingSex = sortBy === 'sex';
    // Sort of new fields added
    this.isAscendingVillage = sortBy === 'village_name';
    this.isAscendingVC = sortBy === 'village_collectors'
    // Sort of new fields added
    if (sortBy === 'name' || sortBy === 'village_name' || sortBy === 'village_collectors' || sortBy === 'official_id') {
      this.farmers.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
      });
    } else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'tel_number' || sortBy === 'cr_date') {
      this.farmers.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }
  }

  updateSyncStatusShown(args): void {  
    for (let i = 0; i < this.farmers.length; i++) {
      // if there is a non-zero farmer_id in the allData element, it is an update object
      this.farmers[i]['isInIDB'] = false;
    }
    let numberOfUnsyncedFarmers = this.isInIDBInThisProject.length;
    this.isInIDBInThisProject = [];
    if (numberOfUnsyncedFarmers && numberOfUnsyncedFarmers > 0) {
      numberOfUnsyncedFarmers = 0;
      this.showSyncSuccessAlert = true;
      setTimeout(() => { 
        this.showSyncSuccessAlert = false 
      }, 5000);
    }
  }

  freezeQuestionnaire(): void {
    const url = `edit-questionnaire/${this.project.id}/${this.snapshot.id}`;
    this.router.navigate([url]);
  }

  bsRangeValueChange(args): void { 
    if (args) {
    
      if (args[0]) {
        if (args[0].getTime() !== 0) {
          this.snapshotModifying['start_date'] = args[0];
        }
      }
      if (args[1]) {
        if (args[1].getTime() !== 0) {
          this.snapshotModifying['end_date'] = args[1];
        }
      }
    }
  }

  applyLocaleToDatePicker(pop: any) {   
    pop.hide();
    pop.show();
  }

  openRegisterFarmerModal(template: TemplateRef<any>): void {
   
    this.addFarmer = true;
    this.registerFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  registerFarmer(): void {
    this.registeringFarmer = true;    

    const name = this.registerFarmerForm['value'].name;
    const age = this.registerFarmerForm['value'].age;
    const sex = this.registerFarmerForm['value'].sex;
    const area = this.registerFarmerForm['value'].area;
    const telNumber = this.registerFarmerForm['value'].telNumber;
    const email = this.registerFarmerForm['value'].email;

    if (!name || name.length === 0 || !age || age.length === 0 || !sex || sex.length === 0 || !area || area.length === 0) {
      return;
    }

    const farmerToRegister = {
      name: name,
      age: age,
      sex: sex,
      village_area: area,
      tel_number: telNumber,
      email: email,
      photo_path: null,
      project_id: this.projectId
    };

    if (navigator.onLine) {
      if (this.fileToUpload) {
        const formData: any = new FormData();
        formData.append('type', 'farmerAvatar');
        formData.append('projectId', this.projectId);
        formData.append('avatar', this.fileToUpload, this.fileToUpload.name);

        this.farmerService.uploadFarmerImage(formData)
          .subscribe((obj) => {
            if (obj.msg === 'success') {
            
              farmerToRegister['photo_path'] = obj.data;
              this.farmerService.registerFarmer(farmerToRegister).subscribe(
                (result) => {
                  
                  this.registeringFarmer = false;
                  if (result.msg === 'success') {
                    this.farmers.unshift(result.data);
                    result.data.cr_date = new Date();
                  
                    if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                      this.registerFarmerModal.hide();
                    }
                    this.registerFarmerForm.setValue({
                      name: '',
                      age: '',
                      sex: 3,
                      area: '',
                      telNumber: '',
                      email: '',
                      photo_path: ''
                    });
                    this.genderChanged(3);
                    this.registerFarmerForm.markAsUntouched();
                    this.registerFarmerForm.markAsPristine();
                    this.farmerAgeSliderValue = 1
                    this.fileToUpload = undefined;
                    this.commonService.showToast('success', 'farmer_registration_successful', {});
                    this.renderer.selectRootElement('#farmerNameAdd').focus();
                    this.confirmModalRegistration = true;
                  }
                  else {
                    this.confirmModalRegistration = true;
                    if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                      this.registerFarmerModal.hide();
                    }
                  }
                },
                (error) => {
                  this.confirmModalRegistration = true;
                  if (this.registerFarmerModal) {
                    this.registerFarmerModal.hide();
                  }
                }
              );
            }
          });
      }
      else {
        this.farmerService.registerFarmer(farmerToRegister).subscribe(
          (result) => {
           
            this.registeringFarmer = false;
            if (result.msg === 'success') {
              this.farmers.unshift(result.data);
              result.data.cr_date = new Date();
             
              if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                this.registerFarmerModal.hide();
              }
              this.registerFarmerForm.setValue({
                name: '',
                age: '',
                sex: 3,
                area: '',
                telNumber: '',
                email: '',
                photo_path: ''
              });
              this.genderChanged(3);
              this.registerFarmerForm.markAsUntouched();
              this.registerFarmerForm.markAsPristine();
              this.farmerAgeSliderValue = 1
              this.fileToUpload = undefined;
              this.confirmModalRegistration = true;
            
              this.renderer.selectRootElement('#farmerNameAdd').focus();
              this.commonService.showToast('success', 'farmer_registration_successful', {});
            }
            else {
              this.confirmModalRegistration = true;
              if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                this.registerFarmerModal.hide();
              }
            }
          },
          (error) => {
            this.confirmModalRegistration = true;
            if (this.registerFarmerModal) {
              this.registerFarmerModal.hide();
            }
          }
        );
      }
    }
    else {
      const finalObj = {};
      const farmerObj = farmerToRegister;
      Object.keys(farmerObj).forEach(function (key) {
        if (typeof farmerObj[key] === 'object' && farmerObj[key] != null) {
          if (farmerObj[key].length && farmerObj[key].length > 0) {
            //finalObj[key] = {};
            finalObj[key] = farmerObj[key];
          } 
          // else {
          //   finalObj[key] = farmerObj[key];
          // }
        } else {
          finalObj[key] = farmerObj[key];
        }
      });
      const farmObj = new Array();
      finalObj['tmp_id'] = `FA${this.commonService.getRandomNum().toString().slice(0, 10)}_Tab0`;
      finalObj['savetab'] = 'Tab0';
      finalObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
      finalObj['cr_date'] = new Date().getTime();
      finalObj['upd_date'] = new Date().getTime();
      finalObj['id'] = 0;
      farmObj[farmObj.length] = finalObj;

      idbApp.addStoreInfo(farmObj, 'farmerstmp');
      this.commonService.showToast('info', 'offline_sync_later', {}); 
     
      this.genderChanged(3);
      this.registerFarmerForm.markAsPristine();
      this.farmerAgeSliderValue = 1
      this.registerSyncEvent();
      

      farmerToRegister['cr_date'] = new Date();
      farmerToRegister['tmp_id'] = finalObj['tmp_id'];
     
      if (!this.registerAnotherFarmer && this.registerFarmerModal) {
        this.registerFarmerModal.hide();
      }
      this.registerFarmerForm.setValue({
        name: '',
        age: '',
        sex: 3,
        area: '',
        telNumber: '',
        email: '',
        photo_path: ''

      });
      this.genderChanged(3);
      this.registerFarmerForm.markAsUntouched();
      this.registerFarmerForm.markAsPristine();
      this.farmerAgeSliderValue = 1
      this.registeringFarmer = false;
      this.confirmModal = true;
    }
   

  }

  registerSyncEvent() {
    navigator.serviceWorker.register('/service-worker.js', {
      scope: './'
    })
      .then(function (reg) {
      
        reg.sync.register('oneTimeSync');
      })
      .catch(function (error) {
       
      });
  }

  declineRegisterFarmerPrompt(form): void {
    if (form.dirty) {
      this.confirmModalRegistration = false;
    } else {
      this.declineRegisterFarmer();
    }
  }

  declineRegisterFarmer(): void {
    if (this.registerFarmerModal) {
      this.registerFarmerModal.hide();
    }
    this.registerFarmerForm.setValue({
      name: '',
      age: '',
      sex: 3,
      area: '',
      telNumber: '',
      email: '',
      photo_path: ''
    });
    this.genderChanged(3);
    this.registerFarmerForm.markAsUntouched();
    this.registerFarmerForm.markAsPristine();
    this.farmerAgeSliderValue = 1
    this.addFarmer = false;
    setTimeout(() => this.confirmModalRegistration = true, 1000);
  }

  genderChanged(gender): void {
    this.registerFarmerForm.patchValue({
      sex: gender
    });
    this.registerFarmerGender = gender;
  }

  genderChangedUpdateFarmer(gender): void {
    this.updateFarmerForm.patchValue({
      sex: gender
    });
  }

  declineUpdateFarmer(): void {
    if (this.updateFarmerModal) {
      this.updateFarmerModal.hide();
    }
    this.updateFarmerForm.setValue({
      name: '',
      age: '',
      sex: '',
      area: '',
      telNumber: '',
      email: '',
      photo_path: ''
    });
    this.updateFarmerForm.markAsUntouched();
    this.updateFarmerForm.markAsPristine();
    this.editFarmer = false;
    setTimeout(() => this.confirmModal = true, 1000);
  }

  async updateCache() {

  }

  /* file upload */
  handleFileInput(file: FileList) {

    const fileItem = file.item(0);
    this.uploadingFileName = fileItem.name;

   
    const msg = this.commonService.ValidateFile(fileItem);

    if (msg.length > 0) {
      // alert(msg);
      // this.toastrService.warning(msg);
      this.commonService.showToast('warning', msg, {});
      return;
    }

    this.fileToUpload = fileItem;
   

    const reader = new FileReader();
    reader.onload = (event: any) => {
     
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  // makeRevision() {
  //   this.spinner.show();
  //   this.revisionService.MakeRevision(this.projectId).subscribe(
  //     (result) => {
  //       this.spinner.hide();
  //       this.toastrService.success(`${this.revisionTxt} ${result.data} ${this.createSuccessTxt}.`);
  //     },
  //     (error) => { 
  //       this.spinner.hide(); 
  //       this.toastrService.error(error.message); 
  //     }
  //   );
  // }

  // Delete farmer btn click function
  deleteFarmer(template: TemplateRef<any>, farmer: any): void {
    this.deletingFarmer = farmer;
    this.deleteFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  // Redirect the program based on user intput on delete modal
  confirmModalDeleteFarmer(what: string): void {
    if (what === 'yes') {
      if (navigator.onLine) {
        this.confirmDeleteFarmer(this.deletingFarmer);
      } else {

        // Push offline deleted farmer data in browser's IndexedDB
        const farmerObj = this.deletingFarmer;
        const finalObj = {};

        Object.keys(farmerObj).forEach(function (key) {
          if (typeof farmerObj[key] === 'object' && farmerObj[key] != null) {
            if (farmerObj[key].length && farmerObj[key].length > 0) {
              finalObj[key] = farmerObj[key];
            }
          } else {
            finalObj[key] = farmerObj[key];
          }
        });

        const farmObj = new Array();

        if (farmerObj.id < 1) {
          const rawFarmerId = farmerObj.tmp_id;
          const index = rawFarmerId.indexOf('_');
          const rawFarmerIdEdited = rawFarmerId.substr(0, index);
          finalObj['tmp_id'] = `${rawFarmerIdEdited}_Tab00`;
        }
        else {
          finalObj['tmp_id'] = `${farmerObj.farmer_code}_Tab00`;
        }
        finalObj['savetab'] = 'Tab00';
        finalObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
        finalObj['deleted'] = 1;
        finalObj['upd_user_id'] = this.currentUser.id;
        finalObj['cr_user_id'] = this.currentUser.id;
        farmObj[farmObj.length] = finalObj;
        finalObj['cr_date'] = new Date().getTime();
        finalObj['upd_date'] = new Date().getTime();
        idbApp.addStoreInfo(farmObj, 'farmerstmp');
        this.farmers = this.farmers.filter(item => item.id !== farmObj[0].id);
        this.deleteFarmerModal.hide();
        this.commonService.showToast('info', 'offline_sync_later', {});
      }
    }
    else {
      this.deletingFarmer = undefined;
      if (this.deleteFarmerModal) {
        this.deleteFarmerModal.hide();
      }
    }
  }

  //  Call farmer delete API when user is online
  confirmDeleteFarmer(farmer: any): void {
    if (this.deleteFarmerModal) {
      this.deleteFarmerModal.hide();
    }
    this.spinner.show();
    this.farmerService.deleteFarmer(farmer.id)
      .subscribe(result => {
        this.deletingFarmer = undefined;
        if (result.msg === 'success') {
          this.spinner.hide();
          this.commonService.showToast('success', 'success_farmer_deleted', {});
          this.farmers = this.farmers.filter(item => item.id !== farmer.id);
        }
        else {
          this.commonService.showToast('error', 'error_farmer_deleted', {});
        }
      })
  }

  updateFarmerDataModal(farmer: any, template: any): void {
    
    this.farmerUpdating = farmer;
    this.updateFarmerForm.setValue({
      name: farmer.name,
      age: farmer.age,
      sex: farmer.sex,
      area: farmer.village_area,
      telNumber: farmer.tel_number,
      email: farmer.email ? farmer.email : '',
      photo_path: ''
    });
    this.farmerAgeSliderValueUpdate = farmer.age;
    this.editFarmer = true;
    this.updateFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  goToFarmerDetials(farmer): void {
    
    let url;   
    if (farmer.id && farmer.id > 0) {
      url = `/farmerdetails/${farmer.project_id}/${farmer.id}`;
    } else if (farmer.tmp_id && !navigator.onLine) {
      url = `/farmerdetails/${farmer.project_id}/${farmer.tmp_id}`;
    } else if (farmer.tmp_id && navigator.onLine) {
      this.commonService.showToast('warning', 'please_wait_data_sync', {preventDuplicates: true});
      return;
    } else {
      url = '/error';
    }
    if(farmer.trainingMissed && navigator.onLine){
      this.router.navigate([url], { queryParams: { trainingMissed: true} });
    } else {
      this.router.navigate([url]);
    }
  }

  updateFarmer(): void {
    
    const name = this.updateFarmerForm['value'].name;
    const age = this.updateFarmerForm['value'].age;
    const sex = this.updateFarmerForm['value'].sex;
    const area = this.updateFarmerForm['value'].area;
    const telNumber = this.updateFarmerForm['value'].telNumber;
    const email = this.updateFarmerForm['value'].email;

    if (!name || name.length === 0 || !age || age.length === 0 || !sex || sex.length === 0 || !area || area.length === 0) {
      return;
    }

    this.farmerUpdating.name = name;
    this.farmerUpdating.age = age;
    this.farmerUpdating.sex = sex;
    this.farmerUpdating.village_area = area;
    this.farmerUpdating.tel_number = telNumber;
    this.farmerUpdating.email = email;

    if (navigator.onLine) {
      
      if (this.fileToUpload) {
        const formData: any = new FormData();
        formData.append('type', 'farmerAvatar');
        formData.append('projectId', this.projectId);
        formData.append('avatar', this.fileToUpload, this.fileToUpload.name);

        this.farmerService.uploadFarmerImage(formData)
          .subscribe((obj) => {
            if (obj.msg === 'success') {
             
              this.farmerUpdating['photo_path'] = obj.data;
              this.farmerService.updateFarmerRegData(this.farmerUpdating)
                .subscribe(result => {
                 
                  if (result.msg === 'success') {
                    this.updateFarmerForm.markAsUntouched();
                    this.updateFarmerForm.markAsPristine();
                    if (this.updateFarmerModal) {
                      this.updateFarmerModal.hide();
                    }
                    this.commonService.showToast('success', 'success_farmer_updated', {});
                    this.renderer.selectRootElement('#farmerNameEdit').focus();
                    this.confirmModal = true
                  }
                  else {
                    this.commonService.showToast('error', 'error_farmer_updated', {});
                    this.confirmModal = true
                  }
                })
            }
          })
      }
      else {
        this.farmerService.updateFarmerRegData(this.farmerUpdating)
          .subscribe(result => {
            
            if (result.msg === 'success') {
              this.updateFarmerForm.markAsUntouched();
              this.updateFarmerForm.markAsPristine();
              if (this.updateFarmerModal) {
                this.updateFarmerModal.hide();
              }
              this.commonService.showToast('success', 'success_farmer_updated', {});
              this.renderer.selectRootElement('#farmerNameEdit').focus();
              this.confirmModal = true
            }
            else {
              this.commonService.showToast('error', 'error_farmer_updated', {});
              this.confirmModal = true
            }
          })
      }
    } else {
     
      const finalObj = {};
      const farmerObj = this.farmerUpdating;
     
      Object.keys(farmerObj).forEach(function (key) {
     
        if (typeof farmerObj[key] === 'object' && farmerObj[key] != null) {
          if (farmerObj[key].length && farmerObj[key].length > 0) {
            //finalObj[key] = {};
            finalObj[key] = farmerObj[key];
          } 
          // else {
          //   finalObj[key] = farmerObj[key];
          // }
        } else {
          finalObj[key] = farmerObj[key];
        }
      });
      const farmObj = new Array();
      
      if (farmerObj.id < 1) {
        const rawFarmerId = farmerObj.tmp_id;
        const index = rawFarmerId.indexOf('_');
        const rawFarmerIdEdited = rawFarmerId.substr(0, index);
        finalObj['tmp_id'] = `${rawFarmerIdEdited}_Tab00`;
      }
      else {
        finalObj['tmp_id'] = `${farmerObj.farmer_code}_Tab00`;
      }
      finalObj['savetab'] = 'Tab00';
      finalObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
      finalObj['project_id'] = this.projectId;
      finalObj['upd_user_id'] = this.currentUser.id;
      farmObj[farmObj.length] = finalObj;
      finalObj['cr_date'] = new Date().getTime();
      finalObj['upd_date'] = new Date().getTime();
      idbApp.addStoreInfo(farmObj, 'farmerstmp');

      this.commonService.showToast('info', 'offline_sync_later', {});
     

      this.updateFarmerForm.markAsUntouched();
      this.updateFarmerForm.markAsPristine();
      this.renderer.selectRootElement('#farmerNameEdit').focus();
      if (this.updateFarmerModal) {
        this.updateFarmerModal.hide();
      }

      this.registerSyncEvent();

      

    }
  }

  confirmModalFn(what): void {
    if (what === 'Yes') {
      this.declineUpdateFarmer();
    }
    else {
      this.confirmModal = true;
    }
  }

  confirmModalRemoveFn(what): void {
    if (what === 'Yes') {
    }
  }

  confirmModalRegistrationFn(what): void {
    if (what === 'Yes') {
      this.declineRegisterFarmer();
    }
    else {
      this.confirmModalRegistration = true;
    }
  }

  declineUpdateFarmerPrompt(form): void {
    if (form.dirty) {
      this.confirmModal = false;
    } else {
      this.declineUpdateFarmer();
    }
  }

  // Email the farmer his / her own ID card
  // emailIdCard(farmer): void {
  
  //     this.farmerService.emailIdCard([farmer.id])
  //       .subscribe(result => {
          
  //         if (result.msg === 'fail' && result.data === 'noEmailId') {
  //           this.commonService.showToast('error', 'farmer_no_email', {});
  //         }
  //         // If successful, show the success message as a toast
  //         else if (result.msg === 'success' && result.data === 'emailSent') {
  //           this.commonService.showToast('success', 'farmer_id_email_sent', {});
  //         }
  //       },
  //         error => {           
  //           this.commonService.showToast('error', 'generic_error', {});
  //         })
  //   //}
  // }

  // Email multiple farmers his / her own ID card
  emailIdCardBulk(farmerArray): void {
    // Find the farmers that have a valid email ID
    const farmersToEmail = [];
    for (let i = 0; i < farmerArray.length; i++) {
      const farmer = farmerArray[i];
      if (farmer.email && farmer.email.length > 0) {
        farmersToEmail.push(farmer.id);
      }
    }
    // If no farmers have a valid email id, warn the user
    // if (farmersToEmail.length == 0) {
    //   this.commonService.showToast('warning', 'no_farmers_have_email_ids', {});
    // }
    // // If at least one farmer has a valid email id
    // else {
      // Call the email id card service and pass the array of farmers to be emailed
      this.farmerService.emailIdCardBulk(farmersToEmail)
        .subscribe(result => {
          // If the service returns a no email id message, notify the agent
          if (result.msg == 'fail' && result.data == 'noFarmersSelected') {
            this.commonService.showToast('error', 'no_farmers_have_email_ids', {});
          }
          // If successful, show the success message as a toast
          else if (result.msg == 'success' && result.data == 'emailSentBulk') {
            this.commonService.showToast('success', 'farmer_id_email_sent_bulk', {});
          }
        },
          error => {
           
            this.commonService.showToast('error', 'generic_error', {});
          })

  }

  // Toggle a particular farmer's selection status
  toggleThisFarmerSelect(id): void {
    if (this.selectedFarmers.indexOf(id) > -1) {
      this.selectedFarmers = this.selectedFarmers.filter(item => item !== id);
    }
    else {
      this.selectedFarmers.push(id);
    }
    this.setTooltipText();
  }

  // Select / deselect all farmers in the array
  toggleAllFarmers(): void {
    // If all farmers are already selected, then deselect all farmers
    if (this.selectedFarmers.length === this.farmers.length) {
      this.selectedFarmers = [];
    }
    // If all farmers are NOT already selected, select all the farmers
    else {
      this.selectedFarmers = [];
      for (let i = 0; i < this.farmers.length; i++) {
        this.selectedFarmers.push(this.farmers[i].id);
      }
    }
    this.setTooltipText();
  }

  // Print multiple ID cards - to be tested
  printSelectedIdCards(): void {
    
    this.selectedPrintItems = this.farmers.filter(item => this.selectedFarmers.indexOf(item.id) > -1);
    this.initiatePrintForAll();

    
  
  }

  // Email ID cards to all selected farmers
  emailSelectedIdCards(): void {
    // Find the list of farmers that have been selected
    const farmersArrayToEmail = this.farmers.filter(item => this.selectedFarmers.indexOf(item.id) > -1);
    // Call the bulk email function and pass this list
    this.emailIdCardBulk(farmersArrayToEmail);
  }



  // Set the farmer data to be printed
  initiatePrint(farmer): void {
    console.clear();
    
    this.farmerPrinting = Object.assign({}, farmer);
    this.farmerPrinting.photo_path = undefined;
    if (farmer.photo_path) {
      this.farmerPrinting.photo_path = `/${this.locale}/assets/uploads/${farmer.photo_path}`;
    } else {
      if(farmer.sex === 3){
        this.farmerPrinting.photo_path = '/' + this.locale + '/assets/images/farmer.png';
      }else if(farmer.sex === 4){
        this.farmerPrinting.photo_path = '/' + this.locale + '/assets/images/female.png';
      }

    }
    // Find tht button that has the ngx-print tag & after a timeout, click it. Without a timeout the data is not set when the print fn is called
    const btn = document.getElementById('realPrintButton');
    setTimeout(() => btn.click(), 1000);
  }
  // When the slide is moved, patch the selected value in the form
  setAgeValue(event): void {
    this.registerFarmerForm.patchValue({
      age: this.farmerAgeSliderValue
    });
    this.registerFarmerForm.markAsDirty();
  }

  setAgeValueUpdate(event): void {
    this.updateFarmerForm.patchValue({
      age: this.farmerAgeSliderValueUpdate
    });
    this.updateFarmerForm.markAsDirty();
  }


  exportSelectedFarmers() {
    
    const farmersList = $.extend(true, [], this.farmers)
    
    const selTemp2 = farmersList.filter(f => this.selectedFarmers.includes(f.id));
    
    const selTemp = [];
    for (let i = 0; i < selTemp2.length; i++) {
      selTemp[i] = selTemp2[i];
      if (selTemp[i]['sex'] === 3) {
        selTemp[i]['sex'] = 'Male';
      } else if (selTemp[i]['sex'] === 4){
         selTemp[i]['sex'] = 'Female';
      }
     
    }
    


    const finalObj: any = {
      farmers: selTemp,
      projectName: this.project.name
    }
    if (this.selectedFarmers.length) {
    

      if (this.projectId != null && this.projectId !== 0) {
        this.projectService.exportFarmersProfilebyProjectId(this.projectId, finalObj)
          .subscribe(result => {
            if (result.status === 'success') {

              const linkIndex = result.fileName.indexOf('assets');
              let link = result.fileName.substring(linkIndex);
              const clickLink = `${environment.apiBaseUrl}/en/${link}`;

              var lastIndex = link.lastIndexOf("/");

              link = link.substring(0, lastIndex);
              
              const linkdownload = document.createElement('a');
              linkdownload.setAttribute('type', 'hidden');
              linkdownload.href = clickLink;
              linkdownload.download = clickLink;
              document.body.appendChild(linkdownload);
              linkdownload.click();
              linkdownload.remove();
              this.selectedFarmers = [];
              this.toastrService.success("Excel Downloaded");
            } else {
              this.toastrService.info(`Information: ${result.msgText}`);
            }


          });
      }
    } else {
      this.toastrService.info('Information: ' + "Select Farmers first");
    }
  }

  printIDCards(){
    //
  }

  printAll() {
    var doc = new jsPDF('p', 'pt');
    var text = "Farmer Management",
      xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
    doc.text(text, xOffset, 40);
    const selFarmersData = this.farmers.filter(item => this.selectedFarmers.indexOf(item.id) > -1);
    const finalSelFarmerData = [];
    selFarmersData.forEach(snap => {
      const temp = [];
      temp.push(snap.farmer_code);
      temp.push(snap.name);
      temp.push(snap.tel_number);

      const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      snap.cr_date = new Date(snap.cr_date);
      snap.cr_date = `${snap.cr_date.getDate()}-${months[snap.cr_date.getMonth()]}-${snap.cr_date.getFullYear()}`


      temp.push(snap.cr_date);
      finalSelFarmerData.push(temp);
    })
    var columns = ["Farmer Code", "Farmer Name", "Phone", "Registered Date"];
    var res = doc.autoTableHtmlToJson(document.getElementById("farmerTableAll"));

    doc.autoTable({
      head: [columns],
      body: finalSelFarmerData,
      margin: { top: 60 },
      theme: 'grid',
      bodyStyles: {
        columnWidth: 100, // 'auto', 'wrap' or a number
        textColor: 20,

      }
    });

    doc.autoPrint({ variant: 'non-conform' });
    doc.output('dataurlnewwindow')
    doc.save("Farmer Management.pdf");
    this.selectedFarmers = [];
  }

  goToFarmerDatasheet(farmer) : void {
    let url;
    if (farmer.id && farmer.id > 0) {
      url = `/farmerdatasheet/${farmer.project_id}/${farmer.id}`;
    } else if (farmer.tmp_id && !navigator.onLine) {
      url = `/farmerdatasheet/${farmer.project_id}/${farmer.tmp_id}`;
    } else if (farmer.tmp_id && navigator.onLine) {
      this.commonService.showToast('warning', 'please_wait_data_sync', {preventDuplicates: true});
      return;
    } else {
      url = '/error';
    }
    this.router.navigate([url]);
  }

  setFarmerRegData(): void {
    if (this.farmers && this.farmers.length > 0) {
      for (let i = 0; i < this.farmers.length; i++) {
        if (this.farmers[i].farmer_reg) {
          this.farmers[i] = {...this.farmers[i], ...this.farmers[i]['farmer_reg']};
        }
      }
    }
  }

  // DEEPIII-367 (on click of print id card, prompt user to select which printer they want to use)
  promptPrinterSelection(template: any): void {
    this.missingDataValuesDuringPrint = this.findMissingPrintingData();
    
    this.changeSelectedPrinter('zebra');
    this.selectPrinterAndPrint();
  }

  declineSelectPrinterPrompt(): void {
    if (this.selectPrinterModal) {
      this.selectPrinterModal.hide();
    }
    this.selectedPrinterType = null;
  }

  changeSelectedPrinter(printerType): void {
    this.selectedPrinterType = printerType;
    const selectedFarmerData = this.farmers.filter(item => this.selectedFarmers.indexOf(item.id) > -1);
  }

  selectPrinterAndPrint(): void {
    // Call the print command with the selected printer type
    this.printSelectedIdCards();

    if (this.selectPrinterModal) {
      this.selectPrinterModal.hide();
    }
    this.selectedPrinterType = null;
  }

  // Send back an array of farmers with missing data points
  findMissingPrintingData() {
    const selectedFarmerData = this.farmers.filter(item => this.selectedFarmers.indexOf(item.id) > -1);
    // Mandatory data points: ID Card No
    const mandatoryDataFields = [];
    const missingData = [];
    for (let i = 0; i < selectedFarmerData.length; i++) {
      const missingDataForCurrentFarmer = [];
      for (let j = 0; j < mandatoryDataFields.length; j++) {
        if (!selectedFarmerData[i][mandatoryDataFields[j]]) {
          missingDataForCurrentFarmer.push(mandatoryDataFields[j]);
        }
      }
      if (missingDataForCurrentFarmer.length > 0) {
        for (let i = 0; i < missingDataForCurrentFarmer.length; i++) {
          if (missingDataForCurrentFarmer[i] === 'official_id_card_no') {
            missingDataForCurrentFarmer[i] = 'ID Card No'
          }
        }
        missingData.push({farmer: selectedFarmerData[i]['name'], missingDataFields: missingDataForCurrentFarmer.join(", ")})
      }
    }
    return missingData;
  }

  initiatePrintForAll(): void {
    // Find tht button that has the ngx-print tag & after a timeout, click it. Without a timeout the data is not set when the print fn is called
    if (this.selectedPrinterType === 'epson') {
      const btn = document.getElementById('realPrintButtonEpson');
      setTimeout(() => btn.click(), 1000);
    } else if (this.selectedPrinterType === 'zebra') {
      const btn = document.getElementById('realPrintButtonZebra');
      setTimeout(() => btn.click(), 1000);
    }
  }

  sortFarmers(): void {
    this.farmers.sort((a, b) => {
      if (a.cr_date < b.cr_date) {
        return 1;
      }
      if (a.cr_date > b.cr_date) {
        return -1;
      }
      return 0;
    });
  }

  setIndicatorValues(): void {
    for(let i=0;i<this.farmers.length;i++){
      if(this.farmers[i].region_village && this.farmers[i].region_village != null){
        this.farmers[i]['village_name'] = this.farmers[i].region_village.village_name;
      }else{
        this.farmers[i]['village_name'] = '';
      }
    }
  }
  exportAllData(template){
    if(this.canExportToExcel && this.canExportTraining){
      this.selectTypeModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    } else if(this.canExportToExcel){
      this.exportFarmerData(this.selectedFarmers)
    } else if(this.canExportTraining){
      this.exportTypes = this.exportTypes.filter(item => item.value != "Farmers Details");
      this.selectTypeModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    }
  }

  submitType(){
    if(this.selectedType == 'Farmers Details'){
      this.exportFarmerData(this.selectedFarmers);
    }
    if(this.selectedType == 'Training Details'){
      this.exportTrainingData('training')
    }
    if(this.selectedType == 'Certification Details'){
      this.exportTrainingData('certification')
    }
    this.closeTypePopup();
  }

  closeTypePopup(fromUI?){
    if(fromUI){      
      if(this.selectedType){
        this.confirmTypeModal = false;
      } else if(this.selectTypeModal) {
        this.selectTypeModal.hide();
        this.selectedType = undefined;
        this.confirmTypeModal = true;
      }
    } else if(this.selectTypeModal) {
      this.confirmTypeModal = true;
      this.selectTypeModal.hide();
      this.selectedType = undefined;
    }
  }

  confirmTypeModalFn(decision){
    if(decision == "Yes"){
      this.closeTypePopup();
      this.confirmTypeModal = true;
    } else {
      this.confirmTypeModal = true;
    }
  }
  exportFarmerData(x:any): void {
    const farmerdetail = this.farmers.filter(item => this.selectedFarmers.indexOf(item.id) > -1);
    this.farmerService.exportData(this.projectId.toString(), farmerdetail)
      .subscribe(result => {
        var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
        const blob = new Blob([result], {type: contentType});
        const linkdownload = document.createElement('a');
        linkdownload.href = URL.createObjectURL(blob);
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const today1 = dd + '-' + mm + '-' + yyyy;
        linkdownload.download = 'P2F-Roots_' + this.farmerLabel + '_' + today1 + '.xlsx';
        linkdownload.style.display = 'none';
        document.body.appendChild(linkdownload);
        linkdownload.click();
        this.selectedFarmers = [];
      })
  }
  exportTrainingData(type){
    const farmerdetail = this.farmers.filter(item => this.selectedFarmers.indexOf(item.id) > -1);
    const farmerArray = [];
    for(const item of farmerdetail){
      farmerArray.push(item.id);
    }
    const obj = {
      "farmerIds":farmerArray
    }
    this.trainingService.trainingFarmerManagementExportToExcel(this.projectId.toString(), obj, type)
      .subscribe(result => {
        var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
        const blob = new Blob([result], {type: contentType});
        const linkdownload = document.createElement('a');
        linkdownload.href = URL.createObjectURL(blob);
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const today1 = dd + '-' + mm + '-' + yyyy;
        this.translate.get(`${type}s`).subscribe((val) => {          
          linkdownload.download = 'P2F-Roots_' + this.farmerLabel + ' ' + val + '_ExcelExport_' + today1 + '.xlsx';
          linkdownload.style.display = 'none';
          document.body.appendChild(linkdownload);
          linkdownload.click();
          this.selectedFarmers = [];
        }, err => console.log('err', err));
      }, error => {
        this.commonService.showToast('warning', 'no_data_found_selected_farmers', {})
        this.selectedFarmers = [];        
      })
  }

    setVCMapping(): void {
      if (this.farmers && this.farmers.length > 0) {
        for (let i = 0; i < this.farmers.length; i++) {
          if (this.farmers[i].vc_farmer_mappings && this.farmers[i].vc_farmer_mappings.length > 0) {
            let vcNames = '';
            for (let j = 0; j < this.farmers[i].vc_farmer_mappings.length; j++) {
              if (this.farmers[i].vc_farmer_mappings[j].status == 'Active') {
                vcNames = this.farmers[i].vc_farmer_mappings[j].users.name  + ', ' + vcNames;
              }
            }
            vcNames = vcNames.substring(0, vcNames.length - 2);
            this.farmers[i].village_collectors = vcNames;
          } else {
            this.farmers[i].village_collectors = '';
          }
        }
    }
  }

  async loadFarmerDataIntoCache(projectId, gte, lt) {
    if (projectId && projectId > 0) {  
      await Promise.all([
        this.dynamicQuestionnaireService.getQAnswersForProjectPaginated(projectId, gte, lt).toPromise()
      ])
      .then(result => {
        console.log('result', result);
      }).catch(e => {});
    }
  }

  setTooltipText(): void {
    if (this.farmers && this.selectedFarmers) {
      if (this.selectedFarmers.length == this.farmers.length) {
        // Deselect farmers.length farmers
        if (this.farmers.length == 1) {
          this.tooltipText = `${this.selectDeselectLabels.deselect} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.deselect} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      } else {
        // Select farmers.length farmers
        if (this.farmers.length == 1) {
          this.tooltipText = `${this.selectDeselectLabels.select} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.select} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      }
    }
  }
  showMissingTrainingDetails(id, template){
    for (let [key, value] of Object.entries(this.missingTrainings)) {
      if(key == id){
        this.farmerMissingTrainings = value;
      }
    }
    for(const item of this.farmerMissingTrainings){
      let date1: Date = new Date();
      let date2: Date = new Date(item.due_date);
      let timeInMilisec: number = date1.getTime() - date2.getTime();
      let daysBetweenDates: number = Math.floor(timeInMilisec / (1000 * 60 * 60 * 24));
      item.overdue = daysBetweenDates;
    }
    this.trainingDetailsModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }
  closeMissingTrainingPopup(){
    if(this.trainingDetailsModal){
      this.trainingDetailsModal.hide();
      this.farmerMissingTrainings = [];
    }
  }

  async exportSelectedConsents(){
    if(this.consentTemplate){
      const farmerdetail = this.farmers.filter(item => this.selectedFarmers.indexOf(item.id) > -1);
      const farmerArray = [];
      for(const item of farmerdetail){
        if(!item.consentPending){
          farmerArray.push(item.id);
        }
      }
      if(farmerArray.length > 0){
        this.spinner.show();
        const obj = {
          "farmerIds":farmerArray
        }
        const requests = await Promise.all([
          this.dataConsentService.getFarmerConsentForFarmers(this.projectId, obj).toPromise()        
        ]);
        if(requests[0].msg == 'success'){
          this.consentFarmersData = requests[0].data;
          if(this.consentFarmersData){
            for(let farmer of this.consentFarmersData){
              const farmerName = farmerdetail.find(item => item.id == farmer.farmer_id);
              if(farmerName){
                farmer.name = farmerName.name; 
              }
            }       
            const btn = document.getElementById('consentPrintButtonEpson');
            setTimeout(() => btn.click(), 1000);     
          } else {
            this.commonService.showToast('warning', 'no_consent_found_this_language', {preventDuplicates: true})
          }
        }
        this.selectedFarmers = [];
        this.spinner.hide();
      } else {
        this.selectedFarmers = [];
        this.commonService.showToast('warning', 'consent_not_available_selected_farmers', {preventDuplicates: true})
      }
    } else {
      this.commonService.showToast('warning', 'consent_template_not_available', {preventDuplicates: true})
      this.selectedFarmers = [];   
    }
  }

  //added by Kiran for famer consent
  async getFarmerConsents(){
    const requests = await Promise.all([
      this.dataConsentService.getFarmerConsents(this.projectId).toPromise()
    ]);
    if(requests[0].msg == 'success'){
      this.farmerConsent = requests[0].data;
    }
    for(const [key, value] of Object.entries(this.farmerConsent)){
      const findFarmer = this.farmers.find(item => item.id == key);
      if(findFarmer){
        findFarmer.consentPending = !value["is_consent_valid"];
        findFarmer.valid_upto = value["valid_upto"];
      }
    }
  }

  // Delete farmer btn click function
  discontinueFarmer(template: TemplateRef<any>, farmer: any): void {
    this.discontinuingFarmer = farmer;
    this.discontinueFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  // Redirect the program based on user intput on delete modal
  confirmModalDiscontinueFarmer(what: string): void {
    if (what === 'yes') {
      const discontinueAction = this.discontinuingFarmer.is_discontinued ? 0 : 1;
      
      if (navigator.onLine) {
        this.confirmDiscontinueFarmer(this.discontinuingFarmer, discontinueAction);
      } else {

        // Push offline deleted farmer data in browser's IndexedDB
        const farmerObj = this.discontinuingFarmer;
        const finalObj = {};

        Object.keys(farmerObj).forEach(function (key) {
          if (typeof farmerObj[key] === 'object' && farmerObj[key] != null) {
            if (farmerObj[key].length && farmerObj[key].length > 0) {
              finalObj[key] = farmerObj[key];
            }
          } else {
            finalObj[key] = farmerObj[key];
          }
        });

        const farmObj = new Array();

        if (farmerObj.id < 1) {
          const rawFarmerId = farmerObj.tmp_id;
          const index = rawFarmerId.indexOf('_');
          const rawFarmerIdEdited = rawFarmerId.substr(0, index);
          finalObj['tmp_id'] = `${rawFarmerIdEdited}_Tab00`;
          finalObj['cr_user_id'] = this.currentUser.id;
          finalObj['cr_date'] = new Date().getTime();
        }
        else {
          finalObj['tmp_id'] = `${farmerObj.farmer_code}_Tab00`;
        }
        finalObj['savetab'] = 'Tab00';
        finalObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
        finalObj['is_discontinued'] = discontinueAction;
        finalObj['upd_user_id'] = this.currentUser.id;
        farmObj[farmObj.length] = finalObj;
        finalObj['upd_date'] = new Date().getTime();
        idbApp.addStoreInfo(farmObj, 'farmerstmp');
        this.farmers.map((item) => {
          if (item.id == farmObj[0].id) {
            item.is_discontinued = discontinueAction;
          }
        });
        this.discontinueFarmerModal.hide();
        this.commonService.showToast('info', 'offline_sync_later', {});
      }
    }
    else {
      this.discontinuingFarmer = undefined;
      if (this.discontinueFarmerModal) {
        this.discontinueFarmerModal.hide();
      }
    }
  }
  

  //  Call farmer delete API when user is online
  confirmDiscontinueFarmer(farmer: any, discontinueAction): void {
    if (this.discontinueFarmerModal) {
      this.discontinueFarmerModal.hide();
    }
    this.spinner.show();
    this.farmerService.discontinueFarmer(this.projectId, farmer.id, discontinueAction)
      .subscribe(result => {
        this.discontinuingFarmer = undefined;
        if (result.msg === 'success') {
          this.spinner.hide();
          if(discontinueAction == 1){
            farmer.is_discontinued  = true;
            this.commonService.showToast('success', 'farmer_discontinued', {});
          } else {
            farmer.is_discontinued  = false;
            this.commonService.showToast('success', 'farmer_continued', {});
          }
        }
        else {
          this.commonService.showToast('error', 'error_farmer_deleted', {});
        }
      })
  }
  //filter farmers array based on search text
  filterFarmers(){ 
    this.farmers = this.filteredfarmers;
    if(this.searchText.length > 0){
      const filterArr = this.farmers.filter(item => {
        if((item.farmer_code && item.farmer_code.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
        (item.name && item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
        (item.cr_date && item.cr_date.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
        (item.official_id && item.official_id.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
        (item.age && item.age == this.searchText) ||
        (item.village_name && item.village_name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)){
          return item; 
        }
      })
      this.farmers = filterArr;
    }
  }
}
