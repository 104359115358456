import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from "highcharts";
import * as proj4x from 'proj4';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
const proj4 = (proj4x as any).default;

import MapModule from "highcharts/modules/map";
declare var require: any;

const mapWorld = require("@highcharts/map-collection/custom/world.geo.json");
MapModule(Highcharts);
((Highcharts as any).win as any).proj4 = proj4;

@Component({
  selector: 'app-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.css']
})

export class GovernanceComponent implements OnInit {
  erootTxt: string;

  currentUser = this.userService.getCurrentUser();
  ecosystemsToShow;
    Highcharts = Highcharts;
    chartMap: Highcharts.Options = {
      chart: {
        map: mapWorld,
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
    },
    exporting: {
      enabled: false
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    tooltip: {
      headerFormat: '',
      useHTML: true,
      style: {
        pointerEvents: 'auto',
      },
      formatter: function() {
        return this.point['markerText'];
      },
    },
    plotOptions: {
      
      series: {
        stickyTracking: false,
          point: {
              events: {
                  click: function(e){
                     const p = e.point
                     this.routeToEcosystem(p.ecosystem_id, p.markerText);
                  }.bind(this)
              },
          }
      }
  },
    series: [
      {
        // Specify points using lat/lon
        type: "mappoint",
        cursor: 'pointer',
        name: 'P2F-Roots Ecosystems',
        color: "tomato",
        data: []
      },
      {
        zIndex: -1,
        showInLegend: false,
        states: {
          hover: {
            color: "#BADA55"
          }
        },
        dataLabels: {
          enabled: false
        }
      } as Highcharts.SeriesMapOptions
    ]
  };
  ecosystemsCount = 0;
 
  constructor(
    private readonly commonService: CommonService,
    private readonly projectService: ProjectService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {
    this.translate.get('chartIdentifyName').subscribe((val) => {
      this.erootTxt = val;
      this.chartMap.series[0].name = this.erootTxt;      

    });

    if (this.locale && this.locale.length > 2) {
      this.locale = this.locale.substr(0,2);
    }
  }

  routeToEcosystem(id, name) {
    this.router.navigate(['/dashboard'], {queryParams: {ecosystemId: id, ecosystemName: name}});
  }

  ngOnInit() {
    this.projectService.getGovernanceEcosystems()
    .subscribe(response => {
      if (response && response.msg == 'success') {
        const ecosystemsArray = [];
        for (let i = 0; i < response.data.length; i++) {
          ecosystemsArray.push({
            name: response.data[i]['ecosystem']['supplier']['name'],
            lat: +response.data[i]['ecosystem']['x_coordinate'],
            lon: +response.data[i]['ecosystem']['y_coordinate'],
            
            markerText: response.data[i]['ecosystem']['marker_description'],
            ecosystem_id: response.data[i]['ecosystem']['id']
          });
          this.ecosystemsCount++;
        }
        this.chartMap.series[0]['data'] = ecosystemsArray;
        this.chartMap = Object.assign({}, this.chartMap);
      }
    })
  }

  viewAllEcosystems() : void {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { ecosystemId: 0, ecosystemName: 'All' } });
  }
}
