<app-header></app-header>
<div class="project-header clearfix">

  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="getBackProjectFilter(project?.name, project?.id)" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer">
        <a (click)="getBackProjectFilter(project?.name, project?.id)" [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page" i18n="@@surveyManagement">Survey Management </li>
    </ol>
  </nav>
</div>
<div class="page-wrapper">
  <div class="content-wrap no-search content-wrapper background">
    <div class="form-design form-box padB0 clearfix">
      <div class="accordion" id="accordionExample">
        <div class="clearfix project-info">
          <div data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" id="headingOne">
            <i class="utilicon utilicon-chevron-down"></i>
            <label class="group-title inline-block dark-blue" i18n="@@projectSurveys">Project Surveys</label>
          </div>
          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card">
              <div i18n="@@snapshot" class="card-header">
                Snapshot
              </div>
              <div class="card-body snapshot-status pb-3 pt-3" style="height:auto">
                <div class="row no-gutters">
                  <div class="col-sm-12 col-lg-4">
                    <div class="inner-card">
                      <div class="inner-card-header" i18n="@@snapshotInformation1">Snapshot Information</div>
                      <div class="inner-card-body">
                        <div *ngIf="loadingSurveyData">
                          <p i18n="@@loading">Loading...</p>
                        </div>
                          <div *ngIf="!loadingSurveyData && snapshotSurveys?.length == 0;">
                            <p i18n="@@noSnapshotCreated">No snapshot created</p>
                          </div>
                          <div class="" *ngFor="let survey of snapshotSurveys; let i = index">
                            <div *ngIf="i == 0">
                              <div class="">
                                <p class="card-title"><strong i18n="@@activeSnapshot">Active Snapshot:</strong>
                                  <span *ngIf="!survey?.flex2" i18n="@@snapshotInformation" class="pl-1">Snapshot</span><span *ngIf="!survey?.flex2" >{{survey.survey_type_sequence ? ' ' + survey.survey_type_sequence : ''}}</span>
                                  <span *ngIf="survey?.flex2" class="pl-1">{{survey?.flex2}}</span>
                                </p>
                                <p class="card-title"><strong i18n="@@created">Created:</strong> {{survey.cr_date | date: 'dd-MMM-yyyy hh:mm a'}}</p>
                                <p class="card-title" *ngIf="survey?.survey_stage_changelogs[0].change_type == 'draft'"><strong i18n="@@stageDraft">Stage:
                                  </strong>
                                  <span class="badge badge-info" i18n="@@draft">Draft</span></p>
                                <p class="card-title" *ngIf="survey?.survey_stage_changelogs[0].change_type == 'active'"><strong i18n="@@stageActive">Stage:
                                  </strong>
                                  <span class="badge badge-success" i18n="@@active">Active</span>
                                </p>
                                <p class="card-title" *ngIf="survey?.survey_stage_changelogs[0].change_type == 'closed'">
                                  <strong i18n="@@stageClosed">Stage: </strong>
                                  <span class="badge badge-danger" i18n="@@notActive"> Closed</span>
                                </p>
                                <p *ngIf="survey.lastModifiedDate" class="card-title"><strong i18n="@@questionnaireUpdatedOn">Questionnaire updated on:</strong> {{survey.lastModifiedDate  | date: 'dd-MMM-yyyy hh:mm a'}}</p>
                                <p *ngIf="survey && survey.survey_stage_changelogs && survey.survey_stage_changelogs.length > 0 && survey.survey_stage_changelogs[0].change_type === 'active'" class="card-title"><strong i18n="@@surveyActivatedOn">Survey activated on:</strong> {{survey.survey_stage_changelogs[0].change_at  | date: 'dd-MMM-yyyy hh:mm a'}}</p>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-3">
                    <div class="inner-card sd-mt-20">
                      <div class="inner-card-header" i18n="@@action">Action</div>
                      <div class="inner-card-body">                        
                        <div>
                          <button *ngIf="showSnapshotSurveyCreateButton && canCreateSnapshot" type="button" class="btn btn-primary mt-0" (click)="createSurvey('snapshot', surveyTemplates); $event.stopPropagation()" i18n="@@create">Create</button>
                        </div>
                        <div class="" *ngFor="let survey of snapshotSurveys; let i = index">
                          <div *ngIf="i == 0">
                            <div>
                              <button *ngIf="survey?.survey_stage_changelogs[0].change_type == 'draft'  && canCreateSnapshot" type="button" class="btn btn-primary mt-0" (click)="surveyActionPrimary(survey, surveyTemplates);$event.stopPropagation()">
                                <span i18n="@@manageQuestionnaire">Manage Questionnaire</span>
                              </button>
                            </div>
                            <div *ngIf="survey?.survey_stage_changelogs[0].change_type != 'closed'  && canCreateSnapshot">
                              <button *ngIf="survey?.survey_stage_changelogs[0].change_type == 'draft' && survey?.questionnaireExists" type="button" class="btn btn-primary ml-0" (click)="surveyActionSecondary(survey, surveyTemplates, confirmActivationTemplate);$event.stopPropagation()">
                                <span i18n="@@activateSurvey">Activate Survey</span>
                              </button>
                              <button *ngIf="survey?.survey_stage_changelogs[0].change_type == 'active'  && canCreateSnapshot" type="button" class="btn btn-primary ml-0 mt-0" (click)="surveyActionSecondary(survey, surveyTemplates, closeSnapshotTemplate);$event.stopPropagation()">
                                <span i18n="@@closeSurvey">Close Survey</span>
                              </button>
                            </div>
                            <div *ngIf="survey?.survey_stage_changelogs[0].change_type != 'closed'  && canCreateSnapshot">
                              <button *ngIf="survey?.survey_stage_changelogs[0].change_type == 'active'  && canCreateSnapshot" type="button" class="btn btn-primary ml-0" (click)="getSurveyDetails(survey.id)">
                                <span i18n="@@previewSurvey">Preview Survey</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" i18n="@@revision">
                Revision
              </div>
              <div class="card-body snapshot-status pb-3 pt-3" style="height:auto">
                <div class="row no-gutters">
                  <div class="col-sm-12 col-lg-6">
                    <div class="inner-card">
                      <div class="row no-gutters">
                        <div class="col-lg-6 col-sm-6 col-md-6">
                          <div class="inner-card-header" i18n="@@revisionInformation">Revision Information</div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6">
                          <div class="inner-card-header text-right" i18n="@@revisionSchedule">Revision Schedule</div>
                        </div>
                      </div>
                      <div class="inner-card-body">
                        <div *ngIf="loadingSurveyData">
                          <p i18n="@@loading">Loading...</p>
                        </div>
                        <div  *ngIf="!loadingSurveyData && snapshotSurveys?.length == 0;" i18n="@@noRevisionFound">No Revision Found</div>
                        <div class="row no-gutters">
                          <div class="col-lg-6 col-sm-6 col-md-6 border-right-light-blue">
                            <div class="" *ngFor="let survey of snapshotSurveys; let i = index">
                              <div *ngIf="i == 0">
                                <div class="">
                                  <p class="card-title">
                                    <strong i18n="@@revisionNumber">Revision Number:</strong>
                                    <span class="ml-1" *ngIf="snapshotSurveys[0].latestRevision && snapshotSurveys[0]?.survey_stage_changelogs[0].change_type == 'active'">{{survey?.survey_type_sequence}}.{{survey?.latestRevision.revision_number}}</span>
                                    <span class="ml-1" *ngIf="!snapshotSurveys[0].latestRevision || snapshotSurveys[0]?.survey_stage_changelogs[0].change_type != 'active'" i18n="@@none">None</span>
                                  </p>
                                  <p class="card-title">
                                    <strong i18n="@@updated">Updated:</strong> 
                                    <span class="ml-1" *ngIf="snapshotSurveys[0].latestRevision && snapshotSurveys[0]?.survey_stage_changelogs[0].change_type == 'active'">{{survey?.latestRevision.cr_date | date: 'dd-MMM-yyyy hh:mm a'}}</span>
                                    <span class="ml-1" *ngIf="!snapshotSurveys[0].latestRevision || snapshotSurveys[0]?.survey_stage_changelogs[0].change_type != 'active'" i18n="@@none">None</span>
                                  </p>
                                  <p class="card-title">
                                    <strong i18n="@@stageActive">Stage: </strong>
                                    <span *ngIf="snapshotSurveys[0].latestRevision && snapshotSurveys[0]?.survey_stage_changelogs[0].change_type == 'active'" class="badge badge-success" i18n="@@active">Active</span>                                    
                                    <span *ngIf="!snapshotSurveys[0].latestRevision || snapshotSurveys[0]?.survey_stage_changelogs[0].change_type != 'active'" class="badge badge-danger" i18n="@@notActive">Not Active</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6 col-md-6">
                              <div class="text-right" *ngIf="snapshotSurveys.length > 0 && schedule?.id > 0 && snapshotSurveys[0]?.survey_stage_changelogs[0].change_type === 'active'">
                                <p class="card-title">
                                  <strong i18n="@@frequency">Frequency:</strong>
                                  <span class="ml-1" *ngIf="schedule && schedule?.id > 0">{{schedule.frequencyName}}</span>
                                  <span class="ml-1" *ngIf="!schedule || schedule.id == 0">--</span>
                                </p>
                                <p class="card-title">
                                  <strong i18n="@@scheduleAt">Schedule At:</strong> 
                                  <span *ngIf="schedule && schedule?.id > 0">{{ scheduleText.scheduling_frequency==2?(scheduleText.scheduled_datetime|date:'EEEE') :(scheduleText.scheduling_frequency==4?(scheduleText.day | dateSuffix):'')}}&nbsp;&nbsp;{{(scheduleText.hrs==0?'00 Hrs GMT':'12 Hrs GMT')}}</span>
                                  <span class="ml-1" *ngIf="!schedule || schedule.id == 0">--</span>
                                </p>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-3">
                    <div class="inner-card sd-mt-20">
                      <div class="inner-card-header" i18n="@@action">Action</div>
                      <div class="inner-card-body" *ngIf="snapshotSurveys[0]?.survey_stage_changelogs[0].change_type === 'active'">
                        <div>
			                    <div>
                            <button class="btn btn-primary mt-0 mr-3 mb-2" *ngIf="snapshotSurveys[0]?.survey_stage_changelogs[0].change_type == 'active' && canScheduleRevision" (click)="scheduleRevisionModalFn(scheduleRevisionModalTemplate, false); $event.stopPropagation()">
                              <span *ngIf="schedule?.id > 0" i18n="@@reScheduleRevision">Reschedule Revision</span> <span *ngIf="schedule.id == 0" i18n="@@scheduleRevision">Schedule Revision</span>
                            </button>
			                    </div>
			                    <div>
                            <button  class="btn btn-primary mt-2" *ngIf="schedule?.id > 0 && snapshotSurveys[0]?.survey_stage_changelogs[0].change_type == 'active' && canScheduleRevision" (click)="stopScheduleRevision(); $event.stopPropagation()">
                              <span i18n="@@stopScheduledRevision">Stop Scheduled Revision</span>
                            </button>
			                    </div>
                          <ng-template #scheduleRevisionModalTemplate>
                            <div *ngIf="showPrompt">
                              <div class="modal-header">
                                <h4 class="modal-title pull-left" i18n="@@scheduleRevision">Schedule Revision</h4>
                                <button type="button" class="close pull-right" aria-label="Close" (click)="cancelModal(revisionForm)">
                                  <i class="utilicon utilicon-times-light"></i>
                                </button>
                              </div>
                              <div class="modal-body text-center add-farmer-form">
                                <div class="container-fluid">
                                  <form [formGroup]="revisionForm" (ngSubmit)=" revisionForm.valid && schedule.scheduling_frequency>0 && saveSchedule(revisionForm)" class="form-design">
                                    <div class="form-group no-margin-hr text-left">
                                      <label class="control-label"><span i18n="@@frequency">Frequency</span>&nbsp;
                                        <span class="mandatory">*</span>
                                      </label>
                                      <select required class="form-control"
                                        name="scheduling_frequency" [(ngModel)]="schedule.scheduling_frequency" formControlName="scheduling_frequency">
                                        <option *ngFor="let item of frequency;" [ngValue]="item.id">{{item.name}}</option>
                                      </select>
                                    </div>
                                    <div class="form-group no-margin-hr text-left" *ngIf="schedule.scheduling_frequency!=1">
                                      <label class="control-label"><span i18n="@@selectDay">Select Day</span>&nbsp;<span class="mandatory">*</span></label>
                                      <select *ngIf="schedule.scheduling_frequency>2" required class="form-control" name="day" [(ngModel)]="schedule.day" formControlName="day">
                                        <option  value="1">1</option>
                                        <option  value="2">2</option>
                                        <option  value="3">3</option>
                                        <option  value="4">4</option>
                                        <option  value="5">5</option>
                                        <option  value="6">6</option>
                                        <option  value="7">7</option>
                                        <option  value="8">8</option>
                                        <option  value="9">9</option>
                                        <option  value="10">10</option>
                                        <option  value="11">11</option>
                                        <option  value="12">12</option>
                                        <option  value="13">13</option>
                                        <option  value="14">14</option>
                                        <option  value="15">15</option>
                                        <option  value="16">16</option>
                                        <option  value="17">17</option>
                                        <option  value="18">18</option>
                                        <option  value="19">19</option>
                                        <option  value="20">20</option>
                                        <option  value="21">21</option>
                                        <option  value="22">22</option>
                                        <option  value="23">23</option>
                                        <option  value="24">24</option>
                                        <option  value="25">25</option>
                                        <option  value="26">26</option>
                                        <option  value="27">27</option>
                                        <option  value="28">28</option>
                                        <option  value="29">29</option>
                                        <option  value="30">30</option>
                                        <option  value="31">31</option>
                                      </select>
                                      <select *ngIf="schedule.scheduling_frequency==2" required formControlName="weekday" class="form-control" [(ngModel)]="schedule.weekday" name="weekday">
                                        <option  value="1" i18n="@@dayIsMonday">Monday</option>
                                        <option  value="2" i18n="@@dayIsTuesday">Tuesday</option>
                                        <option  value="3" i18n="@@dayIsWednesday">Wednesday</option>
                                        <option  value="4" i18n="@@dayIsThursday">Thursday</option>
                                        <option  value="5" i18n="@@dayIsFriday">Friday</option>
                                        <option  value="6" i18n="@@dayIsSaturday">Saturday</option>
                                        <option  value="7" i18n="@@dayIsSunday">Sunday</option>
                                      </select>
                                    </div>
                                    <div class="form-group no-margin-hr text-left">
                                      <label class="control-label"><span i18n="@@selectTime">Select Time</span>&nbsp;<span class="mandatory">*</span>
                                      </label>
                                      <select   class="form-control" name="hrs" [(ngModel)]="schedule.hrs" formControlName="hrs">
                                        <option  value="0">00 Hrs GMT</option>
                                        <option  value="12">12 Hrs GMT</option>
                                      </select>
                                    </div>
                                    <div class="row">
                                      <div class="col-sm-12">
                                        <div class="text-center">
                                          <button type="submit" class="btn btn-primary" i18n="@@save" [disabled]="!revisionForm.valid">Save</button>
                                          <button type="button" class="btn btn-secondary" (click)="cancelModal(revisionForm );"
                                            i18n="@@cancel">Cancel</button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="!showPrompt">
                              <div class="modal-body text-center">
                                <h3 i18n="@@areYouSureWantToClose">Are you sure you want to close?</h3>
                                <button type="button" class="btn btn-secondary mr-1" (click)="confirmModalFn('Yes')" i18n="@@yes">Yes</button>
                                <button type="button" class="btn btn-primary" (click)="confirmModalFn('No')" i18n="@@no">No</button>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-design form-box padB0 clearfix">
      <div class="accordion" id="accordionExample1">
        <div class="clearfix project-info">
          <div data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne" id="headingOne">
            <i class="utilicon utilicon-chevron-down"></i>
            <label class="group-title inline-block dark-blue" i18n="@@campaignSurveys">Campaign Surveys
            </label>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card">
              <div class="card-header" i18n="@@campaign">
                Campaigns
              </div>
              <div *ngFor="let campaign of campaigns" class="card-body snapshot-status pb-3 pt-3" style="height:auto">
                <div class="row no-gutters">
                  <div class="col-sm-12 col-lg-4">
                    <div class="inner-card">
                      <!-- <div class="inner-card-header" i18n="@@campaignInformation">Campaign Information</div> -->
                      <div class="inner-card-header">
                        <span><span i18n="@@campaignName">Campaign Name</span>: </span>
                        <span style="font-weight:400; font-size: 14px;">{{campaign.name}}</span>
                      </div>
                      <div class="inner-card-body">
                        <div *ngIf="loadingSurveyData">
                          <p i18n="@@loading">Loading...</p>
                        </div>
                          <p class="card-title"><strong i18n="@@activeSnapshot">Active Snapshot:</strong>
                            <span *ngIf="!campaign.survey?.flex2" i18n="@@snapshotInformation" class="pl-1">Snapshot</span><span *ngIf="!campaign.survey?.flex2">{{campaign.survey?.survey_type_sequence ? ' ' + campaign.survey?.survey_type_sequence : ''}}</span>
                            <span *ngIf="campaign.survey?.flex2" class="pl-1">{{campaign.survey?.flex2}}</span>
                          </p>
                          <div *ngIf="!loadingSurveyData && !campaign.survey;">
                            <p i18n="@@noCampaignSurveyCreated">No Campaign survey created</p>
                          </div>
                          <div class="" *ngIf="campaign.survey">
                            <div class="">
                              <p class="card-title"><strong i18n="@@created">Created:</strong> {{campaign.survey.cr_date | date: 'dd-MMM-yyyy hh:mm a'}}</p>
                              <p class="card-title" *ngIf="campaign.survey?.survey_stage_changelogs[0].change_type == 'draft'"><strong i18n="@@stageDraft">Stage:
                                </strong>
                                <span class="badge badge-info" i18n="@@draft">Draft</span></p>
                              <p class="card-title" *ngIf="campaign.survey?.survey_stage_changelogs[0].change_type == 'active'"><strong i18n="@@stageActive">Stage:
                                </strong>
                                <span class="badge badge-success" i18n="@@active">Active</span>
                              </p>
                              <p class="card-title" *ngIf="campaign.survey?.survey_stage_changelogs[0].change_type == 'closed'">
                                <strong i18n="@@stageClosed">Stage: </strong>
                                <span class="badge badge-danger" i18n="@@notActive">Closed</span></p>
                              <p *ngIf="campaign.survey.lastModifiedDate" class="card-title"><strong i18n="@@questionnaireUpdatedOn">Questionnaire updated on:</strong> {{campaign.survey.lastModifiedDate  | date: 'dd-MMM-yyyy hh:mm a'}}</p>
                              <p *ngIf="campaign.survey && campaign.survey.survey_stage_changelogs && campaign.survey.survey_stage_changelogs.length > 0 && campaign.survey.survey_stage_changelogs[0].change_type === 'active'" class="card-title"><strong i18n="@@surveyActivatedOn">Survey activated on:</strong> {{campaign.survey.survey_stage_changelogs[0].change_at  | date: 'dd-MMM-yyyy hh:mm a'}}</p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-3">
                    <div class="inner-card sd-mt-20">
                      <div class="inner-card-header" i18n="@@action">Action</div>
                      <div class="inner-card-body" *ngIf="!campaign.closed_date">                        
                        <div>
                          <button *ngIf="!loadingSurveyData && (!campaign.survey || campaign.survey?.survey_stage_changelogs[0].change_type == 'closed');" type="button" class="btn btn-primary mt-0" (click)="createCampaignSurvey(surveyTemplates, campaign); $event.stopPropagation()" i18n="@@create">Create</button>
                        </div>
                        <div>
                          <button *ngIf="campaign.survey?.survey_stage_changelogs[0].change_type == 'draft'  && canCreateSnapshot" type="button" class="btn btn-primary mt-0" (click)="surveyActionPrimary(campaign.survey, surveyTemplates);$event.stopPropagation()">
                            <span i18n="@@manageQuestionnaire">Manage Questionnaire</span>
                          </button>
                        </div>
                        <div *ngIf="campaign.survey?.survey_stage_changelogs[0].change_type != 'closed'  && canCreateSnapshot">
                          <button *ngIf="campaign.survey?.survey_stage_changelogs[0].change_type == 'draft' && campaign.survey?.questionnaireExists" type="button" class="btn btn-primary ml-0" (click)="surveyActionSecondary(campaign.survey, surveyTemplates, confirmActivationTemplate);$event.stopPropagation()">
                            <span i18n="@@activateSurvey">Activate Survey</span>
                          </button>
                          <button *ngIf="campaign.survey?.survey_stage_changelogs[0].change_type == 'active'  && canCreateSnapshot" type="button" class="btn btn-primary ml-0 mt-0" (click)="surveyActionSecondary(campaign.survey, surveyTemplates, closeSnapshotTemplate);$event.stopPropagation()">
                            <span i18n="@@closeSurvey">Close Survey</span>
                          </button>
                        </div>
                        <div *ngIf="campaign.survey?.survey_stage_changelogs[0].change_type != 'closed'  && canCreateSnapshot">
                          <button *ngIf="campaign.survey?.survey_stage_changelogs[0].change_type == 'active'  && canCreateSnapshot" type="button" class="btn btn-primary ml-0" (click)="getSurveyDetails(campaign.survey?.id, campaign.id)">
                            <span i18n="@@previewSurvey">Preview Survey</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-3">
                    <div class="inner-card">
                      <div class="row no-gutters">
                        <div class="col-lg-12 col-sm-12 col-md-12">
                          <div class="inner-card-header" i18n="@@revisionInformation">Revision Information</div>
                        </div>
                      </div>
                      <div class="inner-card-body">
                        <div *ngIf="loadingSurveyData">
                          <p i18n="@@loading">Loading...</p>
                        </div>
                        <div  *ngIf="!loadingSurveyData && (!campaign.survey);" i18n="@@noRevisionFound">No Revision Found</div>
                        <div class="row no-gutters" *ngIf="campaign.survey">
                          <div class="col-lg-12 col-sm-12 col-md-12">
                            <div>
                              <p class="card-title">
                                <strong i18n="@@revisionNumber">Revision Number:</strong>
                                <span class="ml-1" *ngIf="campaign.survey?.latestRevision && campaign.survey?.survey_stage_changelogs[0].change_type == 'active'">{{campaign.survey?.survey_type_sequence}}.{{campaign.survey?.latestRevision?.revision_number}}</span>
                                <span class="ml-1" *ngIf="!campaign.survey?.latestRevision || campaign.survey?.survey_stage_changelogs[0].change_type != 'active'" i18n="@@none">None</span>
                              </p>
                              <p class="card-title">
                                <strong i18n="@@updated">Updated:</strong> 
                                <span class="ml-1" *ngIf="campaign.survey?.latestRevision && campaign.survey?.survey_stage_changelogs[0].change_type == 'active'">{{campaign.survey?.latestRevision.cr_date | date: 'dd-MMM-yyyy hh:mm a'}}</span>
                                <span class="ml-1" *ngIf="!campaign.survey?.latestRevision || campaign.survey?.survey_stage_changelogs[0].change_type != 'active'" i18n="@@none">None</span>
                              </p>
                              <p class="card-title">
                                <strong i18n="@@stageActive">Stage: </strong>
                                <span *ngIf="campaign.survey?.latestRevision && campaign.survey?.survey_stage_changelogs[0].change_type == 'active'" class="badge badge-success" i18n="@@active">Active</span>                                    
                                <span *ngIf="!campaign.survey?.latestRevision || campaign.survey?.survey_stage_changelogs[0].change_type != 'active'" class="badge badge-danger" i18n="@@notActive">Not Active</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #surveyTemplates>
      <div class="modal-header" style="border-bottom:1px solid #dadde9; padding-bottom:10px">
        <h4 class="modal-title pull-left" i18n="@@selectATemplate">Select a template</h4>
        <button type="button" class="close pull-right big-popup-close" title="Close" i18n-title="@@close" aria-label="Close" (click)="closeSurveyTemplateModal(myForm); $event.stopPropagation()">
          <i class="utilicon utilicon-times-light"></i>
        </button>
      </div>
      <div class="modal-body add-farmer-form">
        <div class="container-fluid">
          <form action="" #myForm="ngForm" id="templateForm">
          <div class="popup-form-box pt-0 pb-0 pl-0">
            <div class="row no-gutters">
              <div class="col-3 pt-2"><label style="font-size: 1rem;" i18n="@@surveyName">Survey Name <span class="mandatory">*</span></label></div>
              <div class="col-9 pt-1 pb-3">
                <div>
                  <input type="text" class="form-control" [(ngModel)]="surveyName" name="surveyNameBox" #surveyNameBox="ngModel" required  (change)="checkEmptyness($event)"/>
                </div>
                <div class="error" *ngIf="surveyNameBox.value == '' && (surveyNameBox.dirty || surveyNameBox.touched)">
                  <span i18n="@@enterValidInput">Enter valid input.</span>
                </div>
              </div>
            </div>
            <div class="text-header capitalize" i18n="@@standardQuestionnaire">Standard Questionnaire</div>
            <div class="row mt-2 no-gutters">
              <div class="col-lg-4 col-md-6 pl-2 pr-2">
                <div class="card template-card" #masterTemplate (click)="selectTemplate(masterTemplate)">
                  <input type="radio" class="hide" [(ngModel)]="templateId" name="templateId" value="0" id="0" required/>
                  <div class="card-body">
                    <div class="font-1 weight-400 blue text-center flex-center content-center height-48" i18n="@@erootsTemplate">P2F-Roots Template</div>
                    <div class="gray text-center pb-2">
                      <span class="line-ellipsis padding0-10" style="-webkit-box-orient: vertical;" title="Use the questionnaire created by Naturals+ team. Great if this is your first survey" i18n-title="@@useTheQuestionnaireCreatedBy" i18n="@@useTheQuestionnaireCreatedBy">Use the questionnaire created by Naturals+ team. Great if this is your first survey</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 pl-2 pr-2">
                <div class="card template-card" #blankTemplate (click)="selectTemplate(blankTemplate)">
                  <input type="radio" class="hide" [(ngModel)]="templateId" name="templateId" value="-1" id="-1" required/>
                  <div class="card-body">
                    <div class="font-1 weight-400 blue text-center flex-center content-center height-48" i18n="@@blankTemplate">Blank Template</div>
                    <div class="gray text-center pb-2">
                      <span class="line-ellipsis padding0-10" style="-webkit-box-orient: vertical;" title="Start from a blank slate & add your own questions" i18n-title="@@startFromBlankSlate" i18n="@@startFromBlankSlate">Start from a blank slate & add your own questions</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="ecosystemTemplates?.length > 0">
              <div class="text-header mt-4 capitalize" i18n="@@ecosystemTemplates">Ecosystem Templates</div>
              <div class="row mt-2 no-gutters">
                <div class="col-lg-4 col-md-6 mb-3 pl-2 pr-2" *ngFor="let template of ecosystemTemplates">
                  <div class="card template-card" #customTemplate (click)="selectTemplate(customTemplate)">
                    <input type="radio" class="hide" [(ngModel)]="templateId" name="templateId" value="{{template.id}}" id="{{template.id}}"  required/>
                    <div class="card-body">
                      <div class="font-1 weight-400 blue text-center flex-center content-center height-48">{{template.name}}</div>
                      <div class="gray text-center pb-2 height-48">
                        <span class="line-ellipsis padding0-10" style="-webkit-box-orient: vertical;" [attr.title]="template.description">{{template.description}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </form>
          <div class="text-right">
            <button type="button" class="btn btn-primary" [disabled]="!myForm.valid || emptySurveyName" (click)="templateAction('use', templateId, myForm); $event.stopPropagation()" i18n="@@use">Use</button>
            <button type="button" class="btn btn-secondary ml-4" [disabled]="!myForm.valid" (click)="templateAction('preview', templateId, myForm); $event.stopPropagation()"  i18n="@@preview">Preview</button>
          </div>

        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #closeSnapshotTemplate>
      <div class="modal-header">
        <h4 class="modal-title pull-left" i18n="@@closeSnapshot">Close Snapshot</h4>
        <button type="button" class="close pull-right" aria-label="Close"
          (click)="declineCloseSnapshot()">
          <i class="utilicon utilicon-times-light"></i>
        </button>
      </div>
      <div class="modal-body text-center">
        <p class="text-danger" i18n="@@snapshotClosureWarning">Warning: This is irreversible.
          You will
          not be able to collect questionnaire responses until a new snapshot is activated.
        </p>
        <button type="button" class="btn btn-primary mr-1" (click)="confirmCloseSnapshot()"
          i18n="@@closeSnapshot">Close
          Snapshot</button>
        <button type="button" class="btn btn-secondary" (click)="declineCloseSnapshot()"
          i18n="@@cancel">Cancel</button>
      </div>
    </ng-template>
    <ng-template #confirmActivationTemplate>
      <div class="modal-header">
        <h4 class="modal-title pull-left" i18n="@@confirmActivation">Confirm Activation</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="declineCloseSnapshot()">
          <i class="utilicon utilicon-times-light"></i>
        </button>
      </div>
      <div class="modal-body text-center"  *ngIf="languagesReadyForRelease?.length > 0">
        <p *ngIf="languagesReadyForRelease?.length > 0" i18n="@@releasedInTheFollowingLanguages">The survey will be released in the following languages
          <br>
          <span *ngFor="let ok of languagesReadyForRelease"><b>{{ok.name}}</b> <br></span>
        </p>
        <p *ngIf="languagesNotReadyForRelease?.length > 0" i18n="@@notReleasedInTheFollowingLanguages">The survey will NOT be released in the following languages as there are missing or invalid labels
          <br>
          <span *ngFor="let notOK of languagesNotReadyForRelease"><b>{{notOK.name}}</b> <br></span>
        </p>
        <p *ngIf="!isAllConsSelected && !isCampaignActivation" class="text-warning">
          Consolidation report might appear incomplete because all consolidation types have not been defined.
        </p>
        <p class="text-danger" i18n="@@snapshotActivationWarning">Warning: This is irreversible. You will
          not be able to modify the questionnaire after activation.</p>
        <span class="form-check">
          <input type="checkbox" class="form-check-input" [checked]="saveTemplateAfterActivation === true" (change)="saveTemplateAfterActivation = !saveTemplateAfterActivation"
            container="body" placement="right">
          <label class="form-check-label" i18n="@@saveAsTemplate">Save as a template</label>
        </span>
        <div *ngIf="saveTemplateAfterActivation" style="text-align:left">
          <p class="identifier" i18n="@@questionnaireWillBeSavedAsTemplate">This questionnaire will be saved as a template. You can reuse the template to create future questionnaires.</p>
          <div id = "questionText" class="mt-3">
            <p class="identifier mb-1" i18n="@@templateName">Template Name</p>
            <input type="text"  class="form-control" style="display: inline-block;" 
            [(ngModel)]="templateNameInput" [ngModelOptions]="{standalone: true}" required [disabled]="savingTemplate" autofocus appAutofocus>
            <div class="error" *ngIf="saveTemplateSubmitted && (!templateNameInput || (templateNameInput && templateNameInput.length == 0))">
              <span i18n="@@required">Required</span>
            </div>
            <p class="identifier mt-2 mb-1" i18n="@@description">Description</p>
            <div>
              <textarea  class="form-control has-counter" style="display: inline-block;" [(ngModel)]="templateDescriptionInput" 
                [ngModelOptions]="{standalone: true}" #textareavalue required [disabled]="savingTemplate" maxlength="300">
              </textarea>
              <div class="textarea-counter">
                <span class="value">{{textareavalue?.value?.length}}</span>/300
              </div>
            </div>
            <div class="error" *ngIf="saveTemplateSubmitted && (!templateDescriptionInput || (templateDescriptionInput && templateDescriptionInput.length == 0))">
              <span i18n="@@required">Required</span>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="confirmActivation(saveTemplateModal)" i18n="@@activate">Activate</button>
        <button type="button" class="btn btn-secondary" (click)="declineCloseSnapshot()" i18n="@@cancel">Cancel</button>
      </div>
      <div class="modal-body text-center"  *ngIf="!languagesReadyForRelease || languagesReadyForRelease?.length == 0">
        <p i18n="@@surveyNotReleasedMissingLabels">The survey cannot be released as there are missing or invalid labels in all languages</p>
      </div>
    </ng-template>
    <!-- Added by Kiran for print survey -->
    <div class="form-box d-none" id="surveyQuestionnaire" #surveyQuestionnaire (scroll)="onScroll($event)">
        <a href="javascript:void(0)" (click)="printSurvey('close')" class="close-print-survey blue"><i class="utilicon utilicon-times-light"></i></a>
        <ul class="print-ul">
          <li *ngFor="let tab of questionnaire; let i = index">
            <span *ngIf="tab[labelVariable] && !tab.deleted" class="print-tab TSST-box pos-r" style="border:1px solid #ccc; background-color: #dadde9" [ngClass]="(tab.removed_from_questionnaire ? 'deleted2':'')">
              {{tab[labelVariable][currentLocaleLanguageIdIndex]?.label}}
              <span class="missing-label missing-label-text red" *ngIf="tab.missing_label">
                <i class="utilicon utilicon-exclamation-circle"></i> <span class="ml-2">Missing Label</span>
              </span>
            </span>
            <ul>
              <li *ngFor="let section of tab?.sections; let sectionIndex = index;">
                <span *ngIf="!section.deleted && section[labelVariable]" class="print-section TSST-box pos-r" [ngClass]="(section.removed_from_questionnaire ? 'deleted2':'')">
                  {{section[labelVariable][currentLocaleLanguageIdIndex]?.label}}
                  <span class="missing-label missing-label-text red" *ngIf="section.missing_label">
                    <i class="utilicon utilicon-exclamation-circle"></i> <span class="ml-2">Missing Label</span>
                  </span>
                </span>
                <ul>
                  <li *ngFor="let question of section?.questions; let questionIndex = index">
                    <div *ngIf="!question.deleted" class="form-group mb-2" #target [ngClass]="questionIndex % 2 == 0 ? 'questionDiv' : 'questionDiv2'"
                    [class.display-logic-key]="displayLogicDisplayIfKey?.code == question.code">
                      <div class="pRight-200 pos-r repeater mb-2">
                        <label class="question-label" *ngIf="question[labelVariable]" [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')">
                          <span>{{question[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                          <span *ngIf="question[displayLogicVariable]?.length > 0" class="ml-3"><i class="utilicon utilicon-eye-light"></i></span>
                          <span *ngIf="question[autocalculateFormulaVariable]?.length > 0" class="ml-3"><img src="assets/images/formula-gray.webp" alt="formula"/></span>
                        </label>
                        <span class="missing-label red" *ngIf="question.missing_label">
                          <i class="utilicon utilicon-exclamation-circle"></i> <span class="ml-2">Missing Label</span>
                        </span>
                      </div>
                      <div class="clearfix">
                        <div *ngIf="question.qa_subtype == 'select'" class="mt-2">
                          <div *ngFor="let option of question?.options" class="mr-4 inline-block">
                            <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                              <input [name]="question.code" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == question.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == question.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != question.id)" type="radio" class="mr-1" (change)="setOptionAsDisplayLogic($event, option, question); $event.stopPropagation()">
                              <span *ngIf="option[labelVariable]" [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                            </span>
                          </div>
                        </div>
                        <input  *ngIf="question.qa_subtype == 'text' || question.qa_subtype == 'number'" disabled class="form-control" (change)="sectionFormChangeHandler($event, question)" style="background-color: rgba(255, 255, 255, 0.2);">
                        <div *ngIf="question.qa_subtype == 'multiselect'" class="mt-2">
                          <div *ngFor="let option of question?.options" class="mr-4 inline-block">
                              <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                <input [name]="option.code" type="checkbox" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == question.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == question.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != question.id)"  class="mr-1"
                                (change)="setOptionAsDisplayLogic($event, option, question); $event.stopPropagation()">
                                <span *ngIf="option[labelVariable]" [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li *ngFor="let subsection of section?.subsections; let subsectionIndex = index">
                    <span *ngIf="!subsection.deleted && subsection[labelVariable]" class="print-subsection TSST-box pos-r" [ngClass]="(subsection.removed_from_questionnaire ? 'deleted':'')">
                      {{subsection[labelVariable][currentLocaleLanguageIdIndex]?.label}}
                      <i class="fa fa-list-alt ml-2" aria-hidden="true"></i>
                      <span *ngIf="subsection[displayLogicVariable]?.length > 0" class="ml-3"><i class="utilicon utilicon-eye-light"></i></span>
                      <span class="missing-label missing-label-text red" *ngIf="subsection.missing_label">
                        <i class="utilicon utilicon-exclamation-circle"></i> <span class="ml-2">Missing Label</span>
                      </span>
                    </span>                    
                    <ul>
                      <li *ngFor="let q of subsection.questions; let qIndex = index;" class="pl-2 pr-2">
                        <div *ngIf="!q.deleted" class="form-group mb-2" [ngClass]="qIndex % 2 == 0 ? 'questionDiv' : 'questionDiv2'"
                        [class.display-logic-key]="displayLogicDisplayIfKey?.code == q.code">
                          <div class="pRight-200 pos-r repeater mb-2">
                            <label class="question-label" *ngIf="q[labelVariable]" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')">
                              <span>{{q[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                              <span *ngIf="q[displayLogicVariable]?.length > 0" class="ml-3"><i class="utilicon utilicon-eye-light"></i></span>
                              <span *ngIf="q[autocalculateFormulaVariable]?.length > 0" class="ml-3"><img src="assets/images/formula-gray.webp" alt="formula"/></span>
                            </label>
                            <span class="missing-label red" *ngIf="q.missing_label">
                              <i class="utilicon utilicon-exclamation-circle"></i> <span class="ml-2">Missing Label</span>
                            </span>
                          </div>
                          <div class="clearfix">
                            <div *ngIf="q.qa_subtype == 'select'" class="mt-2">
                              <div *ngFor="let option of q?.options" class="mr-4 inline-block">
                                <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                  <input [name]="q.code" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == q.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == q.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != q.id)" type="radio" class="mr-1" (change)="setOptionAsDisplayLogic($event, option, q); $event.stopPropagation()">
                                  <span *ngIf="option[labelVariable]" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                                </span>
                              </div>
                            </div>
                            <input disabled class="form-control" *ngIf="q.qa_subtype == 'text'"  (change)="sectionFormChangeHandler($event, q)">
                            <input disabled class="form-control" *ngIf="q.qa_subtype == 'number'"  (change)="sectionFormChangeHandler($event, q)">
                            <div *ngIf="q.qa_subtype == 'multiselect'" class="mt-2">
                              <div *ngFor="let option of q?.options" class="mr-4 inline-block">
                                <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                  <input [name]="option.code" type="checkbox" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == q.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == q.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != q.id)"  class="mr-1"
                                  (change)="setOptionAsDisplayLogic($event, option, q); $event.stopPropagation()">
                                  <span *ngIf="option[labelVariable]" class="ml-2" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li *ngFor="let table of section?.tables; let tableIndex = index">
                    <span *ngIf="!table.deleted && table[labelVariable]" class="print-subsection TSST-box pos-r" [ngClass]="(table.removed_from_questionnaire ? 'deleted':'')">
                      {{table[labelVariable][currentLocaleLanguageIdIndex]?.label}} 
                      <i class="fa fa-table ml-2" aria-hidden="true"></i>
                      <span *ngIf="table[displayLogicVariable]?.length > 0" class="ml-3"><i class="utilicon utilicon-eye-light"></i></span>
                      <span class="missing-label missing-label-text red" *ngIf="table.missing_label">
                        <i class="utilicon utilicon-exclamation-circle"></i> <span class="ml-2">Missing Label</span>
                      </span>
                    </span>                    
                    <ul>
                      <li *ngFor="let q of table.questions; let qIndex = index;">
                        <div *ngIf="!q.deleted" class="form-group mb-2" [ngClass]="qIndex % 2 == 0 ? 'questionDiv' : 'questionDiv2'"
                        [class.display-logic-key]="displayLogicDisplayIfKey?.code == q.code">
                          <div class="pRight-200 pos-r repeater mb-2">
                            <label class="question-label" *ngIf="q[labelVariable]" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')">
                              <span>{{q[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                              <span *ngIf="q[displayLogicVariable]?.length > 0" class="ml-3"><i class="utilicon utilicon-eye-light"></i></span>
                              <span *ngIf="q[autocalculateFormulaVariable]?.length > 0" class="ml-3"><img src="assets/images/formula-gray.webp" alt="formula"/></span>
                            </label>
                            <span class="missing-label red" *ngIf="q.missing_label">
                              <i class="utilicon utilicon-exclamation-circle"></i> <span class="ml-2">Missing Label</span>
                            </span>
                          </div>
                          <div class="clearfix">
                            <div *ngIf="q.qa_subtype == 'select'" class="mt-2">
                              <div *ngFor="let option of q?.options" class="mr-4 inline-block">
                                <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                  <input [name]="q.code" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == q.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == q.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != q.id)" type="radio" class="mr-1" (change)="setOptionAsDisplayLogic($event, option, q); $event.stopPropagation()">
                                  <span *ngIf="option[labelVariable]" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                                </span>
                              </div>
                            </div>
                            <input disabled class="form-control" *ngIf="q.qa_subtype == 'text'"  (change)="sectionFormChangeHandler($event, q)">
                            <input disabled class="form-control" *ngIf="q.qa_subtype == 'number'"  (change)="sectionFormChangeHandler($event, q)">
                            <div *ngIf="q.qa_subtype == 'multiselect'" class="mt-2">
                              <div *ngFor="let option of q?.options" class="mr-4 inline-block">
                                <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                  <input [name]="option.code" type="checkbox" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == q.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == q.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != q.id)"  class="mr-1"
                                  (change)="setOptionAsDisplayLogic($event, option, q); $event.stopPropagation()">
                                  <span *ngIf="option[labelVariable]" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="clearfix"></div>
        <div class="scroll-to-top" [ngClass]="{'show-scrollTop': showUpArrow}">
            <button (click)="scrollToTop()" style="padding:6px 12px" class="btn btn-primary btn-icon btn-circle" id="go-to-top-button" aria-label="Go to top">                
                <img alt="Go to top" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSwxOTcuMjg3TDE1OS4wMjgsNjkuMzgxYy0zLjYxMy0zLjYxNy03Ljg5NS01LjQyNC0xMi44NDctNS40MjRzLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0TDUuNDI0LDE5Ny4yODcgICBDMS44MDcsMjAwLjkwNCwwLDIwNS4xODYsMCwyMTAuMTM0czEuODA3LDkuMjMzLDUuNDI0LDEyLjg0N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyNSwxMi44NSw1LjQyNWgyNTUuODEzICAgYzQuOTQ5LDAsOS4yMzMtMS44MDgsMTIuODQ4LTUuNDI1YzMuNjEzLTMuNjEzLDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N1MyOTAuNTQ4LDIwMC45MDQsMjg2LjkzNSwxOTcuMjg3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iI2ZmZmZmZiI+PC9wYXRoPgo8L2c+PC9nPiA8L3N2Zz4=" />
            </button>
        </div>
      </div>
      <!-- Print survey ends here -->
</div>