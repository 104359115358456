<app-header></app-header>
<div class="project-header clearfix">

  <nav aria-label="breadcrumb" class="breadcrumb pRight200">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="getBackFarmerManagment(project?.id)" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item active cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item active cursorPointer" (click)="getBackFarmerManagment(project?.id)" aria-current="page">
        <span i18n-title="@@farmerManagement" title="FARMER MANAGEMENT">...</span>
      </li>
      <li class="breadcrumb-item active" aria-current="page">{{farmer?.name}}</li>
    </ol>
  </nav>
  <ol class="breadcrumb posTopLeft" style="float:right; margin-top:4px;" *ngIf="canDoProfiling && currentTabIndex != trainingDetailsTabIndex">
    <li class="breadcrumb-item active cursorPointer" aria-current="page">
      <button class="small-button" (click)="goToFarmerProfiling(); $event.stopPropagation()">
        <span i18n="@@editProfiling" *ngIf="!campaignId">Edit Profiling</span>
        <span i18n="@@editCampaignProfiling" *ngIf="campaignId">Edit Campaign Profiling</span>
      </button>
    </li>
  </ol>
</div>
<div class="clearfix"></div>
<div class="content-wrap content-wrapper profiling-screen tab_content" style="margin-top: 145px !important">
  <div class="" *ngIf="currentTabIndex == trainingDetailsTabIndex">
    <div class="form-check form-check-inline">
      <input type="radio" id="training" name="addtype" class="form-check-input cursorPointer" [value]="0" [(ngModel)]="unitTrustsPnl" (click)="typeChanged('training')" />
      <label class="form-check-label" for="training" i18n="@@training">Training</label>
    </div>
    <div class="form-check form-check-inline">
      <input type="radio" id="certification" name="addtype"class="form-check-input cursorPointer" [value]="1" [(ngModel)]="unitTrustsPnl" (click)="typeChanged('certificate')"/>
      <label class="form-check-label" for="certification" i18n="@@certification">Certification</label>
    </div>
  </div>
  <div *ngIf="currentTabIndex == farmDetails" style="margin-top: 20px;">
    <div class="row pt-2" *ngIf="farms.length == 0 else allFarms">
      <div class="col-sm-2">
        <!-- <button type="button" style="margin:0px" class="btn btn-secondary" (click)="getGeoLocation($event)">Get Geolocation</button>
        <button type="button" style="margin:0 0 0 20px" class="btn btn-secondary" (click)="getFarmCoordinates(farmRecording)">Record Farm</button> -->
        <div class="form-group" style="margin:5px 0px;">
          <label>
            <span i18n="@@plottingType">Plotting Type </span>
            <span class="mandatory"> *</span>
          </label>
          <div>
            <select class="form-control" style="margin:5px 0px;" [(ngModel)]="farmTypeSelected" (change)="plotTypeSelection($event)">
              <option value="0" class="option" i18n="@@select">Select</option>
              <option value="1" class="option" title="Good if you have a single point of location." i18n-title="@@singlePointOfLocation" i18n="@@getGeolocation">Get Geolocation</option>
              <option value="2" class="option" title="Good if you have multiple points of location." i18n-title="@@multiplePointsOfLocation" i18n="@@recordFarm">Record Farm</option>
              <option value="3" *ngIf="online$ | async;" class="option" title="Draw farm area on the map." i18n-title="@@drawFarmAreaOnMap" i18n="@@drawFarmArea">Draw Farm Area</option>
            </select>
          </div>
        </div>        
      </div>
      <div class="col-sm-3">
        <div class="form-group" style="margin:5px 0px;">
          <label>
            <span i18n="@@farmName">Farm Name </span>
            <span class="mandatory"> *</span>
          </label>
          <input type="text" class="form-control" placeholder="Farm Name" i18n-placeholder="@@farmName" [(ngModel)]="updatedFarmName" />
        </div>
      </div>
      <div class="col-sm-2">
        <div class="form-group" style="margin:5px 0px;">
          <label>
            <span i18n="@@annualForecastKG">Annual Forecast (in KG)</span>
          </label>
          <input type="text" class="form-control" placeholder="Forecast" i18n-placeholder="@@forecast" [(ngModel)]="potential" />
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group" style="margin:5px 0px;">
          <label>
            <span i18n="@@farmImage">Farm Image</span>
            <span class="small gray" style="font-size: 12px; font-weight: unset; padding-left: 5px;" i18n="@@maxSize10MB">(max size 10MB)</span>
          </label>
          <div class="upload-btn-wrapper" #farmFileInput>
            <div>
              <!-- *ngIf="isDesktopDevice;else tabletDevice" -->
              <button class="btnr" (click)="handleFarmFileInput($event.target.files)" i18n-title="@@noFileChosen">
                <span i18n="@@chooseFiles">Choose File</span>
              </button>
              <span *ngIf="uploadingFarmFileName; else nofiles">{{uploadingFarmFileName}}</span>
              <ng-template #nofiles>
                  <span i18n="@@noFileChosen">No file Chosen</span>
              </ng-template>
              <input type="file" class="absolute-input" id="farmFileInput" (change)="handleFarmFileInput($event.target.files)"
              accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}"/>
            </div>
            <!-- <ng-template #tabletDevice>
              <label for="farmFileInput">
                <span><i class="utilicon utilicon-camera-light" style="font-size: 2.2em;"></i></span>
                <span class="upload_file" *ngIf="uploadingFarmFileName">{{uploadingFarmFileName}}</span>
                <input type="file" class="" id="farmFileInput" (change)="handleFarmFileInput($event.target.files)"
                accept="image/*" capture="camera" style="display:none" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}" />
              </label>
            </ng-template> -->
          </div>
          
        </div>
      </div>
      <div class="col-sm-2">
        <button type="button" style="margin:35px 0px 0px 0px" class="btn btn-primary fltRgt" (click)="saveCoordinates($event)" [disabled]="!loc_coordinates || !updatedFarmName" i18n="@@save">Save</button>
      </div>
    </div>
    <ng-template #allFarms>
      <div *ngFor="let farm of farms; let farmIndex = index" [style.display]="selectedFarm.id == farm?.id ? 'block' : 'none'">
        <div class="row pt-3" [id]="farm.farmname">  
          <div class="col-sm-2">
            <div class="form-group" style="margin:5px 0px;">
              <label>
                <span i18n="@@plottingType">Plotting Type </span>
                <span class="mandatory"> *</span>
              </label>
              <select class="form-control" style="margin:5px 0px;" [disabled]="farm.id > 0" [(ngModel)]="farmTypeSelected" (change)="plotTypeSelection($event, farm)">
                <option value="0" class="option" i18n="@@select">Select</option>
                <option value="1" class="option" title="Good if you have a single point of location." i18n-title="@@singlePointOfLocation" i18n="@@getGeolocation">Get Geolocation</option>
                <option value="2" class="option" title="Good if you have multiple points of location." i18n-title="@@multiplePointsOfLocation" i18n="@@recordFarm">Record Farm</option>
                <option value="3" [disabled]="!this.isOnline" class="option" title="Draw farm area on the map." i18n-title="@@drawFarmAreaOnMap" i18n="@@drawFarmArea">Draw Farm Area</option>
              </select>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group" style="margin:5px 0px;">
              <label>
                <span i18n="@@farmName">Farm Name </span>
                <span class="mandatory"> *</span>
              </label>
              <input type="text" class="form-control" placeholder="Farm Name" i18n-placeholder="@@farmName" [(ngModel)]="updatedFarmName"  [disabled]="farm.id > 0"/>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group" style="margin:5px 0px;">
              <label>
                <span i18n="@@annualForecastKG">Annual Forecast (in KG)</span>
              </label>
              <input type="text" class="form-control" placeholder="Forecast" i18n-placeholder="@@forecast" [(ngModel)]="potential" [disabled]="farm.id > 0"/>
              <!-- <a *ngIf="farm.id > 0" (click)="updateForecast(farm)" class="pl-2 cursorPointer"><img src="assets/images/update.png" style="width:20px" alt="Update" title="Update Annual Forcast" i18n-title="@@updateAnnualForcast" /></a> -->
            </div>
          </div>          
          <div class="col-sm-3">
            <div class="form-group" style="margin:5px 0px;">
              <label>
                <span i18n="@@farmImage">Farm Image</span>
                <span class="small gray" style="font-size: 12px; font-weight: unset; padding-left: 5px;" i18n="@@maxSize10MB">(max size 10MB)</span>
              </label>
              <div class="upload-btn-wrapper" #farmFileInput>
                <div>
                  <!-- *ngIf="isDesktopDevice;else tabletDevice" -->
                  <button class="btnr" (click)="handleFarmFileInput($event.target.files)" i18n-title="@@noFileChosen">
                    <span i18n="@@chooseFiles">Choose File</span>
                  </button>
                  <span *ngIf="uploadingFarmFileName && farm.id == 0; else nofiles">{{uploadingFarmFileName}}</span>
                  <ng-template #nofiles>
                      <span i18n="@@noFileChosen">No file Chosen</span>
                  </ng-template>
                  <input type="file" class="absolute-input" id="farmFileInput" (change)="handleFarmFileInput($event.target.files)"
                  accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}"  [disabled]="farm.id > 0"/>
                </div>
                <!-- <ng-template #tabletDevice>
                  <label for="farmFileInput">
                    <span><i class="utilicon utilicon-camera-light" style="font-size: 2.2em;"></i></span>
                    <span class="upload_file" *ngIf="uploadingFarmFileName">{{uploadingFarmFileName}}</span>
                    <input type="file" id="farmFileInput" (change)="handleFarmFileInput($event.target.files)"
                    accept="image/*" capture="camera" style="display:none" data-max-size="10485760" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}" />                    
                  </label>
                  <span *ngIf="farm.id > 0" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 50px; background-color: #f6f6f6;"></span>
                </ng-template> -->
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <button type="button" *ngIf="farm.id != 0" style="margin:35px 0px 0px 0px; padding-bottom: 4px;" class="btn btn-secondary fltRgt" (click)="addFarm($event)" [disabled]="noNewFarm" title="Add Farm" i18n-title="@@addFarm"><i class="utilicon utilicon-plus blue" alt="Add Farm" style="line-height: inherit;"></i></button>
            <button type="button" *ngIf="farm.id != 0 || farm.tmp_id" style="margin:35px 20px 0px 0px; padding-bottom: 4px;" class="btn btn-secondary fltRgt" (click)="deleteFarm(farm, deleteFarmTemplate)" title="Delete Farm"  i18n-title="@@deleteFarm"><i class="utilicon utilicon-trash-alt red" alt="Delete" style="line-height: inherit;" ></i></button>
            <button type="button" *ngIf="farm.id == 0" style="margin:35px 0px 0px 0px" class="btn btn-primary fltRgt" (click)="saveCoordinates($event)" [disabled]="!loc_coordinates || !updatedFarmName" i18n="@@save">Save</button>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- <ng-template #farmRecordingPopup>
      <div class="modal-header">
        <h4 class="modal-title pull-left" >Record Farm Coordinates</h4>
        <button type="button" title="Close" i18n-title="@@close" class="close pull-right" aria-label="Close"
          (click)="closeRecodingPopup()">
          <i class="utilicon utilicon-times-light"></i>
        </button>
      </div>
      <div class="modal-body add-farmer-form">
        <div class="container-fluid">
          <div class="row"  style="padding-top: 20px;">
            <div class="col-sm-6 text-center">
              <button type="button" style="margin:0px; width:100%" class="btn btn-secondary" (click)="recordFarmCoordinates('start')">START</button>
            </div>
            <div class="col-sm-6 text-center">
              <button type="button" style="margin:0px; width:100%" class="btn btn-secondary" (click)="recordFarmCoordinates('intermediate')" [disabled]="farmRecording.start == ''">INTERMEDIATE</button>
            </div>
          </div>
          <div class="row" style="padding-top: 20px;">
            <div class="col-sm-6 text-center">
              <button type="button" style="margin:0px; width:100%" class="btn btn-secondary" (click)="recordFarmCoordinates('end')" [disabled]="farmRecording.intermediate.length == 0">END</button>
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div class="row" style="padding: 10px 0px 20px;">
            <div class="col-sm-12">
              <div class="text-right pt-2">
                <button class="btn btn-primary" type="button" (click)="saveFarmCoordinates()" [disabled]="farmRecording.start == '' || farmRecording.intermediate.length == 0 || farmRecording.end == ''" title="Save" i18n-title="@@save"><span i18n="@@save">Save</span></button>
                <button class="btn btn-secondary" type="button" (click)="closeRecodingPopup()"><span i18n="@@cancel">Cancel</span></button>
              </div>
            </div>
          </div>  
          <div style="margin-bottom: 20px;">
            <div class="row no-gutters" style="border-bottom:1px solid #454545">
              <div class="col-sm-6 bold">State</div>
              <div class="col-sm-6 bold">Coordinates</div>
            </div>
            <div class="row no-gutters" *ngIf="farmRecording.start != ''" style="border-bottom:1px solid #454545; padding: 5px;">
              <div class="col-sm-6">Start</div>
              <div class="col-sm-6">{{farmRecording.start}}</div>
            </div>
            <div *ngIf="farmRecording.intermediate.length > 0">
              <div class="row no-gutters" *ngFor="let im of farmRecording.intermediate; let i =index;" style="border-bottom:1px solid #454545; padding: 5px;">
                <div class="col-sm-6">Intermediate {{i + 1}}</div>
                <div class="col-sm-6">{{im}}</div>
              </div>
            </div>
            <div class="row no-gutters" *ngIf="farmRecording.end != ''" style="border-bottom:1px solid #454545; padding: 5px;">
              <div class="col-sm-6">End</div>
              <div class="col-sm-6">{{farmRecording.end}}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template> -->
    <!-- <button type="button" class="btn btn-secondary" (click)="drawPolygon($event)">Draw Polygon</button> -->
  </div>
  <div name="projectForm" class="form-design mt-2">
    <div class="form-box">
      <div class="farmer-profile-div clearfix">
        
        <div class="farmer-tab-content" id="pills-tabContent2">
          <div class="tab-pane fade in active" id="pills-fp" role="tabpanel" aria-labelledby="pills-fp-tab" *ngIf="currentTabIndex == 0">
            <div class="clearfix"></div>
            <div class="border-middle"></div>
            <div class="farmer-tab-content card" id="pills-tabContent">
              <div>
                <div class="tab-pane fade in active" role="tabpanel" aria-labelledby="pills-personal-tab">
                  <form *ngIf="myForm" [formGroup]="myForm" (ngSubmit)="submitForm(selectedTab.tab_key,myForm,currentTabIndex)" (keydown)="keyDownFunction($event)">
                    <div class="card-body clearfix" *ngIf="currentTabIndex == 0">
                      <div class="col-lg-6 col-md-12" *ngFor="let question1 of farmerRegQuestions; let innerIndex =index;">                          
                        <div [ngSwitch]="question1?.ques_type">
                          <div class="form-group">                            
                            <div *ngSwitchCase="'text'">
                                <label for="text-result">{{question1.ques_text}}
                                  <span class="mandatory" *ngIf="question1?.mandatory_yn == 'Y'">*</span>
                                </label>
                              <input type="text" [name]="question1?.code" id={{question1?.code}} [formControlName]="question1?.code" class="form-control" (change)="checkEmptyness($event)">       
                              <div *ngIf="myForm.controls[question1?.code]?.invalid && (myForm.controls[question1?.code]?.dirty || f1Submitted)">
                                <div class="error" *ngIf="myForm.controls[question1?.code]?.hasError('required')"><span i18n="@@enterValidInput">Enter valid input.</span></div>                                
                              </div>
                            </div>
                            <div *ngSwitchCase="'file'">
                              <label for="text-result">{{question1.ques_text}}
                                <span class="mandatory" *ngIf="question1?.mandatory_yn == 'Y'">*</span>
                                <span class="desc">
                                  <span i18n="@@imgMaxSize" class="mr-1">(square images are best fit, max image size 10MB)
                                  </span>
                                  <span *ngIf="online$ | async; else offlineMsg"></span>
                                  <ng-template #offlineMsg>
                                    <!-- <span class="text-red" i18n="@@imageAvailableOnline">Available when online</span> -->
                                  </ng-template>
                                </span>
                              </label>
                              <!-- <input type="file" [name]="question1?.code" id={{question1?.code}} [formControlName]="question1?.code" class="form-control" >        -->
                              <div class="upload-btn-wrapper" #fileInput>
                              <!-- <div class="upload-btn-wrapper" *ngIf="online$ | async; else disabledInput" #fileInput> -->
                                <!-- <button class="btnr" i18n-title="@@noFileChosen" (click)="handleFileInput($event.target.files,'farmer_reg')">
                                  <span i18n="@@chooseFiles">Choose Files</span>
                                </button>
                                <span *ngIf="uploadingFileName; else nofiles">{{uploadingFileName}}</span>
                                <ng-template #nofiles>
                                  <span i18n="@@noFileChosen">No file Chosen</span>
                                </ng-template>
                                <input type="file" class="absolute-input" capture="camera" [name]="question1?.code" id="fileInput" [formControlName]="question1?.code" (change)="handleFileInput($event.target.files,'farmer_reg')"
                                  accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}" /> -->

                                  <div>
                                    <button class="btnr" (click)="handleFileInput($event.target.files,'farmer_reg')" i18n-title="@@noFileChosen">
                                      <span i18n="@@chooseFiles">Choose Files</span>
                                    </button>
                                    <span *ngIf="uploadingFileName; else nofiles">{{uploadingFileName}}</span>
                                    <ng-template #nofiles>
                                        <span i18n="@@noFileChosen">No file Chosen</span>
                                    </ng-template>
                                    <input type="file" class="absolute-input" [name]="question1?.code" id="fileInput" [formControlName]="question1?.code" (change)="handleFileInput($event.target.files,'farmer_reg')"
                                    accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}" />
                                  </div>
                                  <!-- <ng-template #tabletDevice>
                                    <label for="fileInput">
                                      <span><i class="utilicon utilicon-camera-light" style="font-size: 2.2em;"></i></span>
                                      <span class="upload_file" *ngIf="uploadingFileName">{{uploadingFileName}}</span>
                                      <input type="file" class="" [formControlName]="question1?.code" id="fileInput" (change)="handleFileInput($event.target.files,'farmer_reg')"
                                      accept="image/*" capture="camera" style="display:none" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}" />
                                    </label>
                                  </ng-template> -->
                              </div>

                              <ng-template #disabledInput>
                                <!--custom upload button-->
                                <div class="upload-btn-wrapper" #fileInput>
                                  <button class="btnr" i18n-title="@@noFileChosen" disabled>
                                    <span i18n="@@chooseFiles">Choose Files</span>
                                  </button>
                                  <span *ngIf="uploadingFileName; else nofiles">{{uploadingFileName}}</span>
                                  <ng-template #nofiles>
                                    <span i18n="@@noFileChosen">No file Chosen</span>
                                  </ng-template>
                                  <input disabled type="file" class="absolute-input" id="fileInput" [formControlName]="question1?.code" accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}"  />
                                </div>
                              </ng-template>
                            </div>
                            <div *ngSwitchCase="'number'">
                              <label for="text-result">{{question1.ques_text}}
                                <span class="mandatory" *ngIf="question1?.mandatory_yn == 'Y'">*</span>
                              </label>
                              <input type="number" [name]="question1?.code" id={{question1?.code}} [formControlName]="question1?.code" class="form-control">       
                              <div *ngIf="myForm.controls[question1?.code]?.invalid && (myForm.controls[question1?.code]?.dirty || f1Submitted)">
                                <div class="error" *ngIf="myForm.controls[question1?.code]?.hasError('required')"><span i18n="@@enterValidInput">Enter valid input.</span></div>                                
                              </div>
                            </div>
                            <div *ngSwitchCase="'select'">
                              <label for="text-result">{{question1.ques_text}}
                                <span class="mandatory" *ngIf="question1?.mandatory_yn == 'Y'">*</span>
                              </label>
                              <select [name]="question1?.code" id={{question1?.code}} class="form-control"
                              [formControlName]="question1?.code" *ngIf="question1?.code == 'sex'">
                                <option value="" class="option" [selected]="selected" i18n="@@select">Select</option>
                                <option *ngFor="let option1 of genderData" [ngValue]="option1?.master_id">
                                  {{option1?.name}}
                                </option>
                              </select>
                              <select [name]="question1?.code" id={{question1?.code}} class="form-control"
                              [formControlName]="question1?.code" *ngIf="question1?.code == 'education_level'">
                                <option value="" class="option" selected i18n="@@select">Select</option>
                                <option *ngFor="let option2 of educationLevelData" [ngValue]="option2?.master_id">
                                  {{option2?.name}}
                                </option>
                              </select>
                              <select [name]="question1?.code" id={{question1?.code}} class="form-control"
                              [formControlName]="question1?.code" *ngIf="question1?.code == 'school_level'">
                                <option value="" class="option" selected i18n="@@select">Select</option>
                                <option *ngFor="let option2 of schoolLevelData" [ngValue]="option2?.master_id">
                                  {{option2?.name}}
                                </option>
                              </select>
                              <select required id="village_area" class="form-control select2" [formControlName]="question1?.code"
                              *ngIf="question1?.code == 'village_area_id'">
                                <option value="" class="option" selected i18n="@@select">Select</option>
                                <option *ngFor="let item of villageAreaList; trackBy: trackById" [ngValue]="item.id">{{item.village_name}}</option>
                              </select>
                              <div *ngIf="myForm.controls[question1?.code]?.invalid && (myForm.controls[question1?.code]?.dirty || f1Submitted)">
                                <div class="error" *ngIf="myForm.controls[question1?.code]?.hasError('required')"><span i18n="@@enterValidInput">Enter valid input.</span></div>                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="fixed-actions form-box actions">
                      <button class="btn btn-primary" type="submit" title="Update" i18n-title="@@update" [disabled]="!myForm.valid || validFarmerName"><span i18n="@@update">Update</span></button>
                      <button class="btn btn-secondary" type="button" (click)="exitClicked(myForm,exitConfirmTemplate);$event.stopPropagation()"><span i18n="@@exit">Exit</span></button>
                    </div>
                    <div class="card-body clearfix" *ngIf="currentTabIndex == 1">
                      <p>No data available</p>
                    </div>
                    <ng-template #exitConfirmTemplate>
                      <div *ngIf="!confirmModalPopup">
                        <div class="modal-body text-center">
                          <h3 i18n="@@areYouSureWantToExit">
                            Are you sure you want to exit?
                          </h3>
                          <button type="button" class="btn btn-secondary mr-1" (click)="confirmExitModal('Yes')" i18n="@@yes" i18n-title="@@yes">Yes</button>
                          <button type="button" class="btn btn-primary cancel-focus" autofocus appAutofocus (click)="confirmExitModal('No')" i18n="@@no" i18n-title="@@no">No</button>
                        </div>
                      </div>
                    </ng-template>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" [id]="farmDetails" [ngClass]="{'active': currentTabIndex == farmDetails}" *ngIf="currentTabIndex == farmDetails">
            <!-- <div class="pt1 pb1">Agm Map</div>
            <div *ngFor="let farm of farms; let farmIndex = index" [style.display]="selectedFarm.farmname == farm?.farmname ? 'block' : 'none'">
              <agm-map [latitude]="lat" [longitude]="lng" [zoom]="18" [mapTypeId]="'satellite'" (mapReady)="changeTilt($event)">
                <agm-marker [latitude]="markerlat" [longitude]="markerlng"></agm-marker>
              </agm-map>
            </div> -->
            <div class="row">
              <div class="col-sm-12 col-md-4 col-lg-4" *ngIf="walkingOnTheEdge">
                <div class="row"  style="padding-top: 20px;">
                  <div class="col-sm-6 text-center">
                    <button type="button" style="margin:0px; width:100%" class="btn btn-secondary" (click)="recordFarmCoordinates('start')" i18n="@@START">START</button>
                  </div>
                  <div class="col-sm-6 text-center">
                    <button type="button" style="margin:0px; width:100%" class="btn btn-secondary" (click)="recordFarmCoordinates('intermediate')" [disabled]="farmRecording.start == ''" i18n="@@INTERMEDIATE">INTERMEDIATE</button>
                  </div>
                </div>
                <div class="row" style="padding-top: 20px;">
                  <div class="col-sm-6 text-center">
                    <button type="button" style="margin:0px; width:100%" class="btn btn-secondary" (click)="recordFarmCoordinates('end')" [disabled]="farmRecording.intermediate.length == 0" i18n="@@END">END</button>
                  </div>
                  <div class="col-sm-6">
                  </div>
                </div>
                <!-- <div class="row" style="padding: 10px 0px 20px;">
                  <div class="col-sm-12">
                    <div class="text-right pt-2">
                      <button class="btn btn-primary" type="button" (click)="saveFarmCoordinates()" [disabled]="farmRecording.start == '' || farmRecording.intermediate.length == 0 || farmRecording.end == ''" title="Save" i18n-title="@@save"><span i18n="@@save">Save</span></button>
                      <button class="btn btn-secondary" type="button" (click)="closeRecodingPopup()"><span i18n="@@cancel">Cancel</span></button>
                    </div>
                  </div>
                </div>   -->
                <div style="margin-bottom: 20px;" class="mt-4">
                  <div class="row no-gutters" style="border-bottom:1px solid #454545">
                    <div class="col-sm-6 bold" i18n="@@state">State</div>
                    <div class="col-sm-6 bold" i18n="@@coordinates">Coordinates</div>
                  </div>
                  <div class="row no-gutters" *ngIf="farmRecording.start != ''" style="border-bottom:1px solid #454545; padding: 5px;">
                    <div class="col-sm-6" i18n="@@start">Start</div>
                    <div class="col-sm-6">{{farmRecording.start}}</div>
                  </div>
                  <div *ngIf="farmRecording.intermediate.length > 0">
                    <div class="row no-gutters" *ngFor="let im of farmRecording.intermediate; let i =index;" style="border-bottom:1px solid #454545; padding: 5px;">
                      <div class="col-sm-6"><span i18n="@@intermediate">Intermediate </span>{{i + 1}}</div>
                      <div class="col-sm-6">{{im}}</div>
                    </div>
                  </div>
                  <div class="row no-gutters" *ngIf="farmRecording.end != ''" style="border-bottom:1px solid #454545; padding: 5px;">
                    <div class="col-sm-6" i18n="@@end">End</div>
                    <div class="col-sm-6">{{farmRecording.end}}</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" [ngClass]="walkingOnTheEdge ? 'col-md-8 col-lg-8' : 'col-md-12 col-lg-12'">
                <div #map id="map" style="width:100%;height:400px; margin-top: 10px;" [style.display]="mapDisplay ? 'block' : 'none'"></div>
              </div>
            </div>
            <div class="pt-4" *ngIf="uploadedFarmImage">
              <div><label class="font-1" i18n="@@farmImage">Farm Image</label></div>
              <div>
                <img [src]="uploadedFarmImage" alt="Farm image" />
              </div>

            </div>
          </div>
          <div class="tab-pane" [id]="trainingDetailsTabIndex" [ngClass]="{'active': currentTabIndex == trainingDetailsTabIndex}" *ngIf="currentTabIndex == trainingDetailsTabIndex"> 
            <app-farmer-training-details 
              [farmer]="farmer" 
              [isDesktopDevice]="isDesktopDevice"
              [isCertificate]="isCertificate"
            >
            </app-farmer-training-details>
          </div>
          <ul class="nav nav-pills fixed-tabs" id="pills-tab2" role="tablist">
            <li class="nav-item" *ngFor="let tab of tabsList; let formIndex = index">
              <a class="nav-link" [ngClass]="{'active': selectedTab.tab_key == tab?.tab_key && currentTabIndex != trainingDetailsTabIndex && currentTabIndex != farmDetails}" id="pills-fp-tab" data-toggle="pill"  [ngClass]="{'active': currentTabIndex == farmerDetailsTabIndex}"
                [href]="'#' + tab?.tab_key" role="tab" aria-selected="true" (click)="tabClicked(tab,formIndex)">
                {{tab?.tab_heading}}
              </a>
            </li>
            <li class="nav-item" style="width: auto;" *ngIf="canDoGeoplotting">
              <a class="nav-link" style="padding: 6px 25px" data-toggle="pill" [href]="'#' + farmDetails" id="pills-fp-tab"  [ngClass]="{'active': currentTabIndex == farmDetails}"
              (click)="tabClicked(null, farmDetails)" role="tab" aria-selected="true" i18n="@@farmDetails">Farm Details</a>
            </li>
            <li class="nav-item" *ngIf="canAddTraining && online$ | async" style="width: auto;">
              <a class="nav-link" style="padding: 6px 25px" data-toggle="pill" [href]="'#' + trainingDetailsTabIndex" id="pills-fp-tab"  [ngClass]="{'active': currentTabIndex == trainingDetailsTabIndex}"
              (click)="tabClicked(null, trainingDetailsTabIndex)" role="tab" aria-selected="true" i18n="@@trainingCertificationDetails">Training & Certification Details</a>
            </li>
          </ul>
          <ul class="nav nav-pills sub-tab-fixed" id="pills-tab" role="tablist" *ngIf="currentTabIndex == farmDetails" style="overflow: inherit; width: auto">
            <li class="nav-item" *ngIf="farms.length == 0 else savedFarms">
              <a class="nav-link" id="pills-personal-tab" class="active"
                data-toggle="pill" role="tab" aria-selected="true" (click)="sectionClicked(sectionIndex)" i18n="@@farm1">
                Farm 1
              </a>
            </li>
            <ng-template #savedFarms>
              <li class="nav-item" *ngFor="let farm of farms; let farmIndex = index">
                <a class="nav-link" id="pills-personal-tab" [ngClass]="{'active': selectedFarm.id == farm?.id}"
                  data-toggle="pill" [href]="'#' + farm.farm_name" role="tab" aria-selected="true" (click)="farmSectionClicked(farmIndex)">
                  <span i18n="@@farm">Farm </span><span>{{farmIndex + 1}}</span>
                </a>
              </li>              
            </ng-template>
          </ul>
        </div>
        <ng-template #confirmTemplate>
          <div class=" modal-body text-center ">
            <h3 i18n="@@haveAnswerWhichHavnotSaveDoYouWannaExit">
              You have answers which have not been saved. Are you sure you want to exit?
            </h3>
            <button type="button " class="btn btn-secondary mr-1 " (click)="closeModal(true)" i18n="@@yes" i18n-title="@@yes">Yes</button>
            <button type="button " class="btn btn-primary " (click)="closeModal(false)" i18n="@@no" i18n-title="@@no">No</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-template #deleteFarmTemplate>
    <div>
      <div class="modal-body text-center">
        <h3 i18n="@@wantToDeleteThisFarm">
          Are you sure you want to delete this farm?
        </h3>
        <button type="button" class="btn btn-secondary mr-1" (click)="confirmFarmDelete('Yes')" i18n="@@yes" i18n-title="@@yes">Yes</button>
        <button type="button" class="btn btn-primary cancel-focus" autofocus appAutofocus (click)="confirmFarmDelete('No')" i18n="@@no" i18n-title="@@no">No</button>
      </div>
    </div>
  </ng-template>
</div>