import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
@Injectable({
    providedIn: 'root'
})
export class TrainingService {
    private readonly trainingURL = this.appConfigService.getUrl(this.appConfigService.TRAINING);
    private readonly visitTypeURL = this.appConfigService.getUrl(this.appConfigService.VISIT_TYPES);

    constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) { }

    getProjectTrainingMapping(mappingID?): Observable<any> {
        return mappingID ? this.http.get<any>(`${this.trainingURL}/projectTCMapping/${mappingID}`) : 
        this.http.get<any>(`${this.trainingURL}/projectTCMapping`);
    }

    saveProjectTCMapping(data, edit, mappingID): Observable<any> {
        return edit ? this.http.put(`${this.trainingURL}/projectTCMapping/${mappingID}`, data) :
        this.http.post(`${this.trainingURL}/projectTCMapping`, data); 
    }
    exportToExcel() {
        return this.http.post<Blob>(`${this.trainingURL}/export/projectTCMapping`, {}, {responseType:'blob' as 'json'}); 
    }

    exportToExcelPGM() {
        return this.http.post<Blob>(`${this.trainingURL}/export/exportProjectGRMapping`, {}, {responseType:'blob' as 'json'}); 
    }

    getProjectGroupMapping(mappingID?): Observable<any> {
        return mappingID ? this.http.get<any>(`${this.trainingURL}/projectGRMapping/${mappingID}`) : 
        this.http.get<any>(`${this.trainingURL}/projectGRMapping`);
    }
    getAllGroups(projectID?): Observable<any> {
        return projectID ? this.http.get<any>(`${this.trainingURL}/getAllGroups/${projectID}`) : 
        this.http.get<any>(`${this.trainingURL}/getAllGroups`);
    }

    saveProjectGroupMapping(data, edit, mappingID): Observable<any> {
        return edit ? this.http.put(`${this.trainingURL}/projectGRMapping/${mappingID}`, data) :
        this.http.post(`${this.trainingURL}/projectGRMapping`, data); 
    }

    getFarmerIdsMappedToGroup(projectGroupMappingId): Observable<any> {
        return this.http.get<any>(`${this.trainingURL}/getFarmerIdsMappedToGroup/${projectGroupMappingId}`);
    }

    mapFarmersToGroup(projectGroupMappingId, data): Observable<any> {
        return this.http.post<any>(`${this.trainingURL}/mapFarmersToGroup/${projectGroupMappingId}`, data);
    }

    get_all_training_certification(projectID): Observable<any> {
        return this.http.get<any>(`${this.trainingURL}/get_all_training_certification/${projectID}`);
    }

    get_training_recordings(projectID): Observable<any> {
        return this.http.get<any>(`${this.trainingURL}/get_training_recordings/${projectID}`);
    }    

    get_all_trainer(projectID): Observable<any> {
        return this.http.get<any>(`${this.trainingURL}/get_all_trainer/${projectID}`);
    }    

    getGroupFarmers(projectGroupMappingID): Observable<any> {
        return this.http.get<any>(`${this.trainingURL}/getMapedFarmerDetailsToProjectGroup/${projectGroupMappingID}`);
    } 

    saveTrainingDetails(data): Observable<any> {
        return this.http.post<any>(`${this.trainingURL}/recordTraining`, data);
    }

    getTrainingDetails(hdrID): Observable<any> {
        return this.http.get<any>(`${this.trainingURL}/getTrainingDetails/${hdrID}`);
    } 

    updateTraining(data): Observable<any> {
        return this.http.put<any>(`${this.trainingURL}/updateTraining`, data);
    }
    
    deleteTrainingDocument(id, data): Observable<any> {
        return this.http.delete<any>(`${this.trainingURL}/deleteTrainingDocument/${id}`, data);
    }

    saveCertificationDetails(data): Observable<any> {
        return this.http.post<any>(`${this.trainingURL}/recordCertification`, data);
    }

    getCertificationDetails(hdrID): Observable<any> {
        return this.http.get<any>(`${this.trainingURL}/getCertificationDetails/${hdrID}`);
    } 

    updateCertification(data): Observable<any> {
        return this.http.put<any>(`${this.trainingURL}/updateCertification`, data);
    }

    trainingManagementExportToExcel(projectID, type) {
        return this.http.post<Blob>(`${this.trainingURL}/export/management_training_certification/${projectID}/${type}`, {}, {responseType:'blob' as 'json'}); 
    }
    

    getFarmerTrainingIndicators(projectID): Observable<any> {
        return this.http.get<any>(`${this.trainingURL}/getFarmerTrainingIndicators/${projectID}`);
    } 
    get_training_recordings_farmer(projectID, farmerID): Observable<any> {
        return this.http.get<any>(`${this.trainingURL}/get_training_recordings/${projectID}/${farmerID}`);
    }    

    getIndividualTrainingCertificationDetails(projectID, farmerID, headerID, type): Observable<any> {
        return this.http.get<any>(`${this.trainingURL}/getIndividualTrainingCertificationDetails/${projectID}/${farmerID}/${headerID}/${type}`);
    }    

    updateIndividualTraining(projectID, farmerID, data): Observable<any> {
        return this.http.put<any>(`${this.trainingURL}/updateIndividualTraining/${projectID}/${farmerID}`, data);
    }

    recordIndividualTraining(projectID, farmerID, data): Observable<any> {
        return this.http.post<any>(`${this.trainingURL}/recordIndividualTraining/${projectID}/${farmerID}`, data);
    }

    updateIndividualCertification(projectID, farmerID, data): Observable<any> {
        return this.http.put<any>(`${this.trainingURL}/updateIndividualCertification/${projectID}/${farmerID}`, data);
    }

    recordIndividualCertification(projectID, farmerID, data): Observable<any> {
        return this.http.post<any>(`${this.trainingURL}/recordIndividualCertification/${projectID}/${farmerID}`, data);
    }

    trainingFarmerManagementExportToExcel(projectID, farmerIDs, type) {
        return this.http.post<Blob>(`${this.trainingURL}/export/exportTtrainingCertificationRecordingsForFarmers/${projectID}/${type}`, farmerIDs, {responseType:'blob' as 'json'}); 
    }

    getFarmerExportTypes(): Observable<any> {
        return this.http.get<any>(`${this.visitTypeURL}/farmer_export_type`);
    }

    getFarmerCertificationList(projectID): Observable<any> {
        return this.http.get<any>(`${this.trainingURL}/getFarmerCertificationList/${projectID}`);
    }  
    
}
