
<div>
  <app-header></app-header>
  <div class="project-header clearfix">
  
    <nav aria-label="breadcrumb" class="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-icon">
          <span class="btn-back" title="Back" i18n-title="@@back"  (click)="backClicked(); $event.stopPropagation()" aria-current="page">
            <i class="utilicon utilicon-arrow-alt-left"></i>
          </span>
        </li>
        <li class="breadcrumb-icon">
          <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
        </li>
        <li class="breadcrumb-item cursorPointer" aria-current="page">
          <a (click)="getBackProjectFilter(project?.name, project?.id)" [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</a>
        </li>
        <li class="breadcrumb-item active cursorPointer" aria-current="page">
          <a (click)="goToProfilingTable()">...</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{farmer?.name}}</li>
      </ol>
    </nav>
    <ol class="breadcrumb posTopLeft" style="float:right; margin-top: 4px; margin-right:10px;"
    *ngIf="canDoFarmerMgmt">
      <li class="breadcrumb-item active cursorPointer" aria-current="page">
        <button class="small-button" (click)="goToFarmerManagementEdit(); $event.stopPropagation()">
          <span i18n="@@editFarmer">Edit Farmer</span>
        </button>
      </li>
    </ol>
  </div>
  <div class="clearfix"></div>
  <div class="content-wrap content-wrapper profiling-screen">
    <div *ngIf="notActiveSurvey == 1">
      <p i18n="@@pleaseActivateSurvey">Please activate survey</p>
    </div>
    <div *ngIf="notReleasedSurveyInLanguage == 1">
      <p i18n="@@surveyNotReleasedCurrentLanguage">Survey is not released in current language</p>
    </div>
    <div name="projectForm" class="form-design" *ngIf="(notReleasedSurveyInLanguage !== 1 && notActiveSurvey !== 1)">
      <div class="form-box">
        <div class="farmer-profile-div clearfix">
          <div class="farmer-tab-content" id="pills-tabContent2">
            <div class="tab-pane fade in active" id="pills-fp" role="tabpanel" aria-labelledby="pills-fp-tab"
            *ngIf="notActiveSurvey == 0">
              <!--<label class="group-title">{{currentTab?.tabHeading}}</label>-->
              <div class="clearfix"></div>
              <div class="border-middle"></div>
                <ul *ngIf="questionnaire && questionnaire != 'noSurveys'" class="nav nav-pills fixed-tabs" id="pills-tab2" role="tablist">
                  <li class="nav-item" *ngFor="let tab of questionnaire; let i = index">
                    <a class="nav-link" [class.disabled]="!cropNameQuestionAnswered || !mandatoryQuestionAnswered ? true : false" [ngClass]="{'active': currentTab?.id ==tab?.id && !showReferenceTab}" id="pills-fp-tab" data-toggle="pill"
                      [href]="'#' + tab?.tabKey" role="tab" aria-selected="true" (click)="tabClicked(i)">
                      {{tab?.survey_q_asset_labels[0]?.label}}
                    </a>
                  </li>
                  <li class="nav-item" *ngIf="surveyHasReference" [class.disabled]="!cropNameQuestionAnswered || !mandatoryQuestionAnswered ? true : false">
                    <a href="#refDoc" class="nav-link" role="tab" aria-selected="true"  id="pills-fp-tab" data-toggle="pill"
                    (click)="tabClicked(-1)" [ngClass]="{'active': showReferenceTab}" i18n="@@reference">Reference</a>
                  </li>
                </ul>
                <ul class="nav nav-pills sub-tab-fixed" id="pills-tab" role="tablist" #widgetsContent [style.display]="showReferenceTab ? 'none' : 'block'">
                  <button class="prev-btn" (click)="leftScroll()">
                    <i class="utilicon utilicon-chevron-left"> </i>
                  </button>
                  <li class="nav-item " *ngFor="let section of currentTab?.sections; let sectionIndex = index;">
                    <a class="nav-link" [class.disabled]="!cropNameQuestionAnswered || !mandatoryQuestionAnswered ? true : false" [ngClass]="{'active' : currentSection?.id == section.id}" id="pills-personal-tab"
                      data-toggle="pill" role="tab" aria-selected="true" (click)="sectionClicked(sectionIndex)">
                      {{section?.survey_q_asset_labels[0]?.label}}
                    </a>
                  </li>
                  <button class="next-btn" (click)="rightScroll()">
                    <i class="utilicon utilicon-chevron-right"> </i>
                  </button>
                </ul>
                <div *ngIf="questionnaire && questionnaire.length > 0">
                  <div class="farmer-tab-content card" id="pills-tabContent">
                    <div class="tab-pane" [id]="currentSection?.id" role="tabpanel" aria-labelledby="pills-personal-tab"  [style.display]="showReferenceTab ? 'none' : 'block'">
                      <form enctype="multipart/form-data" [formGroup]="sectionForm" (keydown)="keyDownFunction($event)" *ngIf="showSectionForm">
                        <div class="card-body clearfix">
                          <div class="com-sm-12" *ngIf="showSummaryForCurrentTab" id="tabSummary">
                            <div class="row profit-lost-grid no-gutters mt-2 mb-2">
                              <div class="col-md-12 col-lg-4 light-blue br-4-l flex-center" >
                                <div class="profit-green weight-400">
                                  <div class="font-20">{{farmProfitabilitySummary?.income?.source}}</div>
                                  <div><span class="green">{{currencyCode}} {{farmProfitabilitySummary?.income?.amount}}</span></div>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-4 text-center">
                                <div class="pt-2 pb-2 weight-400">
                                  <div class="font-20" i18n="@@farmerProfitability">PROFITABILITY</div>
                                  <div><span i18n="@@farmerTotalIncome">Total Income</span>: <span class="green">{{currencyCode}} {{farmProfitabilitySummary?.totalIncome}}</span></div>
                                  <div><span i18n="@@farmerTotalExpenses">Total Expenses</span>: <span class="red">{{currencyCode}} {{farmProfitabilitySummary?.totalExpense}}</span></div>
                                  <div><span i18n="@@farmerTotalProfitability">Total Profitability</span>: <span [ngClass]="farmProfitabilitySummary.totalProfit>=0 ? 'green' : 'red' ">{{currencyCode}} {{farmProfitabilitySummary?.totalProfit}}</span></div>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-4 light-blue br-4-r flex-center">
                                <div class="lost-red weight-400">
                                  <div class="font-20">
                                    <span>{{farmProfitabilitySummary.expense?.source}}</span>
                                  </div>
                                  <div><span class="red">{{currencyCode}} {{farmProfitabilitySummary.expense?.amount}}</span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <ng-container *ngFor="let question of currentSection?.questions">
                              <div *ngIf="question?.isDisplayed" class="col-lg-6 col-md-12 mb-3">
                                <label>
                                  <span>{{question?.survey_q_asset_labels[0]?.label}}</span>
                                  <ng-container *ngFor="let validation of question?.survey_q_asset_validations">
                                    <span class="bold red ml-2" *ngIf="validation?.validation_key=='mandatory' && validation?.validation_value=='true'">*</span>
                                  </ng-container>
                                </label>
                                <div class="row no-gutters">
                                  <div [style.display]="question.include_uom_question ? 'flex' : 'block'" class="col-lg-10 col-md-10 col-sm-10">
                                    <select class="form-control" *ngIf="question.qa_subtype == 'select'" [formControlName]="question.id" (change)="sectionFormChangeHandler($event, question)">
                                      <option value="" i18n="@@select">Select</option>
                                      <option *ngFor="let option of question?.options" [value]="option.id">{{option?.survey_q_asset_labels[0]?.label}}</option>
                                    </select>
                                    <input type="text" class="form-control" *ngIf="question.qa_subtype == 'text'" [formControlName]="question.id" (change)="sectionFormChangeHandler($event, question)" (click)="openTextarea($event)">
                                    <textarea class="hide text-area" *ngIf="question.qa_subtype == 'text'" maxlength="200" [formControlName]="question.id" (change)="sectionFormChangeHandler($event, question)" (blur)="closeTextArea($event)"></textarea>
                                    <input type="number" class="form-control" *ngIf="question.qa_subtype == 'number'" [formControlName]="question.id" (change)="sectionFormChangeHandler($event, question)">                                    
                                    <div *ngIf="question.qa_subtype == 'multiselect'" class="input-like-box">
                                      <span *ngFor="let option of question?.options" class="form-check inline-block pl-3 mt-0 mb-0">
                                        <input type="checkbox" [formControlName]="question.id" class="form-check-input" [checked] = "checkedObject[question.id + '_'  + option.id]" (change)="sectionFormChangeHandlerCheckbox($event, option, question)"> 
                                        <span style="display:inline" class="form-check-label">&nbsp;</span>
                                        <span style="font-size:0.78rem">{{option?.survey_q_asset_labels[0]?.label}}</span>
                                      </span>
                                      <span *ngIf="dynamicOptionsObject && dynamicOptionsObject[question.id]">
                                        <span *ngFor="let option of dynamicOptionsObject[question.id]" class="form-check inline-block pl-3 mt-0 mb-0">
                                          <input type="checkbox" [formControlName]="question.id" class="form-check-input" [checked] = "checkedObject[question.id + '_'  + option.q + '__' + option.sak]" (change)="sectionFormChangeHandlerCheckboxDynamicOptions($event, option, question)"> 
                                          <span style="display:inline" class="form-check-label">&nbsp;</span>
                                          <span style="font-size:0.78rem">{{option.label}}</span>
                                        </span>
                                      </span>
                                    </div>
                                    <div class="dateIcon" *ngIf="question.qa_subtype == 'date'">
                                      <input bsDatepicker readonly placement="top" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue', showClearButton: true, clearPosition: 'right' }" [isOpen]="dateOpen" class="form-control"
                                       [formControlName]="question.id" (onHidden)="sectionFormChangeHandler($event, question)">
                                      <i class="utilicon utilicon-calendar-alt"></i>
                                      <i class="utilicon utilicon-times-circle-light" style="right:26px; color:#9D2835; cursor: pointer;" (click)="clearCalendarDate($event, question)" title="Clear Date" i18n-title="@@clearDate"></i>
                                    </div>
                                    <div class="upload-btn-wrapper" *ngIf="question.qa_subtype == 'file'" >
                                      <div>
                                        <!-- *ngIf="isDesktopDevice;else tabletDevice" -->
                                        <!-- <input type="file" class="form-control" (change)="handleUploadFileInput($event.target.files, question)" style="padding-top: 5px;"> -->
                                        <button class="btnr" (click)="handleUploadFileInput($event.target.files, question)" i18n-title="@@noFileChosen">
                                          <span>Choose File</span>
                                        </button>
                                        <input type="file" class="absolute-input" (change)="handleUploadFileInput($event.target.files, question)"
                                        accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" />
                                      </div>
                                      <!-- <ng-template #tabletDevice>
                                        <label [for]="question.id" style="width:100%">
                                          <span><i class="utilicon utilicon-camera-light" style="font-size: 2.2em;"></i></span>
                                          <input type="file" [id]="question.id" (change)="handleUploadFileInput($event.target.files, question)"
                                          accept="image/*" capture="camera" style="display:none" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen"/>
                                        </label>
                                      </ng-template> -->
                                      <div *ngIf="uploadedFiles && uploadedFiles.length > 0" style="position:absolute;top: 7px;left: 110px;">
                                        <div *ngFor="let file of uploadedFiles">                                                
                                          <span *ngIf="file.id == question.id">{{file.image_for}}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <span *ngIf="question.qa_subtype == 'file'" class="small gray" style="font-size: 12px; font-weight: unset;">Allowed types: JPG, JPEG, PNG, WEBP</span>
                                    <span *ngIf="question.include_uom_question" [ngStyle]="{ width: question.uom_questions.length > 1 ? '70%' : '50%' }" class="ml-2">
                                      <span *ngFor="let uom of question.uom_questions; let i = index">
                                        <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                        <select class="form-control" [ngClass]="{'small-select' : question.uom_questions.length > 1}" [formControlName]="uom.id" (change)="sectionFormChangeHandler($event, uom)">
                                          <!-- <option value="" i18n="@@select">Select</option> -->
                                          <option *ngFor="let uom_option of uom?.options" [value]="uom_option.id">{{uom_option?.uom}}</option>
                                        </select>
                                      </span>
                                    </span>
                                    <span *ngIf="question.other_questions && question.other_questions.length > 0 && question.other_questions[0].isDisplayed" style="width:50%">
                                      <input type="text" class="form-control mt-2" [formControlName]="question?.other_questions[0]?.id" (change)="sectionFormChangeHandler($event, question.other_questions[0])">
                                    </span>
                                  </div>
                                  <div  class="col-lg-2 col-md-2 col-sm-2">
                                    <a (click)="confirmAnswer($event, question, true)" title="Confirm Answer" i18n-title="@@confirmAnswer" class="confirm-answer" [class.answer-confirmed]="question.answerConfirmed">
                                      <i class="utilicon utilicon-check-circle-light"></i>
                                    </a>
                                    <a (click)="confirmAnswer($event, question, false)" title="Answer Not Available" i18n-title="@@answerNotAvailable" class="answer-na" [class.answer-not-confirmed]="question.answerConfirmed == false">
                                      <!-- <i class="utilicon utilicon-exclamation-circle-light"></i> -->
                                      <!-- <img *ngIf="question.answerConfirmed != false" src="assets/images/blocked.png" alt="N/A" />
                                      <img *ngIf="question.answerConfirmed == false" src="assets/images/blocked-selected.png" alt="N/A" /> -->
                                      N/A
                                    </a>
                                  </div>
                                </div>
                                <div class="error" *ngIf="(sectionForm?.controls[question.id]?.dirty || sectionForm?.controls[question.id]?.touched) && sectionForm?.controls[question.id]?.errors">
                                  <span *ngIf="sectionForm?.controls[question.id]?.errors?.pattern" i18n="@@enterValidInput">Enter valid input.</span>
                                  <span *ngIf="sectionForm?.controls[question.id]?.errors?.max"><span i18n="@@exceedMaximumValue">Exceeds maximum value: </span> {{sectionForm?.controls[question.id]?.errors?.max?.max}}</span>
                                  <span *ngIf="sectionForm?.controls[question.id]?.errors?.min"><span i18n="@@haveMinimumValue">Must have minimum value: </span> {{sectionForm?.controls[question.id]?.errors?.min?.min}}</span>
                                  <span *ngIf="sectionForm?.controls[question.id]?.errors?.required" i18n="@@inputIsRequired">Input is required</span>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                          <div *ngFor="let subsection of currentSection?.subsections">
                            <div *ngIf="subsection?.isDisplayed">
                              <h6 class="mt-2">{{subsection?.survey_q_asset_labels[0]?.label}}</h6>
                              <div class="row">
                                <ng-container *ngFor="let q of subsection.questions">
                                  <div *ngIf="q?.isDisplayed" class="col-lg-6 col-md-12 mb-3">
                                    <label>
                                      <span>{{q?.survey_q_asset_labels[0]?.label}}</span>
                                      <ng-container *ngFor="let validation of q?.survey_q_asset_validations">
                                        <span class="bold red ml-2" *ngIf="validation?.validation_key=='mandatory' && validation?.validation_value=='true'">*</span>
                                      </ng-container>
                                    </label>
                                    <div class="row no-gutters">
                                      <div [style.display]="q.include_uom_question ? 'flex' : 'block'" class="col-lg-10 col-md-10 col-sm-10">
                                        <select class="form-control" *ngIf="q.qa_subtype == 'select'" [formControlName]="q.id" (change)="sectionFormChangeHandler($event, q)">
                                          <option value="" i18n="@@select">Select</option>
                                          <option *ngFor="let option of q?.options" [value]="option.id">{{option?.survey_q_asset_labels[0]?.label}}</option>
                                        </select>
                                        <input type="text" class="form-control" *ngIf="q.qa_subtype == 'text'" [formControlName]="q.id" (change)="sectionFormChangeHandler($event, q)" (click)="openTextarea($event)">
                                        <textarea class="hide text-area" *ngIf="q.qa_subtype == 'text'" maxlength="200" [formControlName]="q.id" (change)="sectionFormChangeHandler($event, q)" (blur)="closeTextArea($event)"></textarea>
                                        <input type="number" class="form-control" *ngIf="q.qa_subtype == 'number'" [formControlName]="q.id" (change)="sectionFormChangeHandler($event, q)">                                        
                                        <div *ngIf="q.qa_subtype == 'multiselect'" class="input-like-box">
                                          <span *ngFor="let option of q?.options" class="form-check inline-block pl-3 mt-0 mb-0">
                                            <input type="checkbox" [formControlName]="q.id"class="form-check-input" [checked] = "checkedObject[q.id + '_'  + option.id]" (change)="sectionFormChangeHandlerCheckbox($event, option, q)" /> 
                                            <span style="display:inline" class="form-check-label">&nbsp;</span>
                                            <span style="font-size:0.78rem">{{option?.survey_q_asset_labels[0]?.label}}</span>
                                          </span>
                                          <span *ngIf="dynamicOptionsObject && dynamicOptionsObject[q.id]">
                                            <span *ngFor="let option of dynamicOptionsObject[q.id]" class="form-check inline-block pl-3 mt-0 mb-0">
                                              <input type="checkbox" [formControlName]="q.id" class="form-check-input" [checked] = "checkedObject[q.id + '_'  + option.q + '__' + option.sak]" (change)="sectionFormChangeHandlerCheckboxDynamicOptions($event, option, q)"> 
                                              <span style="display:inline" class="form-check-label">&nbsp;</span>
                                              <span style="font-size:0.78rem">{{option.label}}</span>
                                            </span>
                                          </span>
                                        </div>
                                        <div class="dateIcon" *ngIf="q.qa_subtype == 'date'">
                                          <input bsDatepicker readonly placement="top" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue', showClearButton: true, clearPosition: 'right' }" [isOpen]="dateOpen" class="form-control"
                                           [formControlName]="q.id" (onHidden)="sectionFormChangeHandler($event, q)">
                                          <i class="utilicon utilicon-calendar-alt"></i>
                                          <i class="utilicon utilicon-times-circle-light" style="right:26px; color:#9D2835; cursor: pointer;" (click)="clearCalendarDate($event, q)" title="Clear Date" i18n-title="@@clearDate"></i>
                                        </div>
                                        <div class="upload-btn-wrapper" *ngIf="q.qa_subtype == 'file'" >
                                          <div>
                                            <!-- *ngIf="isDesktopDevice;else tabletDevice" -->
                                            <button class="btnr" (click)="handleUploadFileInput($event.target.files, q)" i18n-title="@@noFileChosen">
                                              <span>Choose File</span>
                                            </button>
                                            <input type="file" class="absolute-input" (change)="handleUploadFileInput($event.target.files, q)"
                                            accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" />
                                          </div>
                                          <!-- <ng-template #tabletDevice>
                                            <label [for]="q.id" style="width:100%">
                                              <span><i class="utilicon utilicon-camera-light" style="font-size: 2.2em;"></i></span>
                                              <input type="file" [id]="q.id" (change)="handleUploadFileInput($event.target.files, q)"
                                              accept="image/*" capture="camera" style="display:none" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen"/>
                                            </label>
                                          </ng-template> -->
                                          <div *ngIf="uploadedFiles && uploadedFiles.length > 0" style="position:absolute;top: 7px;left: 110px;">
                                            <div *ngFor="let file of uploadedFiles">                                                
                                              <span *ngIf="file.id == q.id">{{file.image_for}}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <span *ngIf="q.qa_subtype == 'file'" class="small gray" style="font-size: 12px; font-weight: unset;">Allowed types: JPG, JPEG, PNG, WEBP</span>
                                        <span *ngIf="q.include_uom_question" [ngStyle]="{ width: q.uom_questions.length > 1 ? '70%' : '50%' }" class="ml-2">
                                          <span *ngFor="let uom of q.uom_questions; let i = index">
                                            <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                            <select class="form-control" [formControlName]="uom.id" (change)="sectionFormChangeHandler($event, uom)">
                                              
                                              <option *ngFor="let uom_option of uom?.options" [value]="uom_option.id">{{uom_option?.uom}}</option>
                                            </select>
                                          </span>
                                        </span>
                                        <span *ngIf="q.other_questions && q.other_questions.length > 0 && q.other_questions[0].isDisplayed" style="width:50%">
                                          <input type="text" class="form-control mt-2" [formControlName]="q?.other_questions[0]?.id" (change)="sectionFormChangeHandler($event, q.other_questions[0])">
                                        </span>
                                      </div>                                    
                                      <div  class="col-lg-2 col-md-2 col-sm-2">
                                        <a (click)="confirmAnswer($event, q, true)" title="Confirm Answer" i18n-title="@@confirmAnswer" class="confirm-answer" [class.answer-confirmed]="q.answerConfirmed">
                                          <i class="utilicon utilicon-check-circle-light"></i>
                                        </a>
                                        <a (click)="confirmAnswer($event, q, false)" title="Answer Not Available" i18n-title="@@answerNotAvailable" class="answer-na" [class.answer-not-confirmed]="q.answerConfirmed == false">
                                          N/A
                                        </a>
                                      </div>
                                    </div>
                                    <div class="error" *ngIf="(sectionForm?.controls[q.id]?.dirty || sectionForm?.controls[q.id]?.touched) && sectionForm?.controls[q.id]?.errors">
                                      <span *ngIf="sectionForm?.controls[q.id]?.errors?.pattern" i18n="@@enterValidInput">Enter valid input.</span>
                                      <span *ngIf="sectionForm?.controls[q.id]?.errors?.max"><span i18n="@@exceedMaximumValue">Exceeds maximum value: </span>{{sectionForm?.controls[q.id]?.errors?.max?.max}}</span>
                                      <span *ngIf="sectionForm?.controls[q.id]?.errors?.min"><span i18n="@@haveMinimumValue">Must have minimum value: </span> {{sectionForm?.controls[q.id]?.errors?.min?.min}}</span>
                                      <span *ngIf="sectionForm?.controls[q.id]?.errors?.required" i18n="@@inputIsRequired">Input is required</span>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                          <div *ngFor="let table of currentSection?.tables">
                            <div *ngIf="table?.isDisplayed">
                              <h6 class="mt-2">{{table?.survey_q_asset_labels[0]?.label}}</h6>
                              <div class="row no-gutters">
                                <div class="col-lg-12 col-md-12 ">
                                  <div *ngIf="table.qa_subtype == 'question_cols'">
                                    <table class="table">
                                      <thead class="no-box-shaow">
                                        <tr>
                                          <th></th>
                                          <th *ngFor="let question of table.questions">
                                            <span>{{question.survey_q_asset_labels[0].label}}</span>
                                            <ng-container *ngFor="let validation of question?.survey_q_asset_validations">
                                              <span class="bold red ml-2" *ngIf="validation?.validation_key=='mandatory' && validation?.validation_value=='true'">*</span>
                                            </ng-container>
                                          </th>
                                          <th>Confirm</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let key of secondary_asset_keys[table.id]" [id] = "key">
                                          <td class="align-middle text-center">
                                            <button (click)="removeFromTable(table, key); $event.stopPropagation()" class="qdelete-btn table-cross pos-r"><i class="utilicon utilicon-times"></i></button>
                                          </td>
                                          <td *ngFor="let question of table.questions" [id] = "question.id">
                                            <div style="display: flex; position: relative;" *ngIf="question[key+ '_isDisplayed']">
                                              <select class="form-control" *ngIf="question.qa_subtype == 'select'" [formControlName]="question.id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, question, key)">
                                                <option value="" i18n="@@select">Select</option>
                                                <option *ngFor="let option of question?.options" [value]="option.id">{{option?.survey_q_asset_labels[0]?.label}}</option>
                                              </select>
                                              <input type="text" class="form-control" *ngIf="question.qa_subtype == 'text'" [formControlName]="question.id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, question, key)" (click)="openTextarea($event)">
                                              <textarea class="hide text-area" *ngIf="question.qa_subtype == 'text'" maxlength="200" [formControlName]="question.id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, question, key)" (blur)="closeTextArea($event)"></textarea>
                                              <input type="number" class="form-control" *ngIf="question.qa_subtype == 'number'" [formControlName]="question.id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, question, key)">
                                              <div *ngIf="question.qa_subtype == 'multiselect'" class="input-like-box">
                                                <span *ngFor="let option of question?.options" class="form-check inline-block pl-3 mt-0 mb-0">
                                                  <input type="checkbox" [formControlName]="question.id + '_' + key" class="form-check-input" [checked] = "checkedObjectSecondaryKeys[question.id + '_'  + key + '_' + option.id]" (change)="sectionFormChangeHandlerForTableCheckbox($event, table, question, option, key)"/> 
                                                  <span style="display:inline" class="form-check-label">&nbsp;</span>
                                                  <span style="font-size:0.78rem">{{option?.survey_q_asset_labels[0]?.label}}</span>
                                                </span>
                                                <span *ngIf="dynamicOptionsObject && dynamicOptionsObject[question.id]">
                                                  <span *ngFor="let option of dynamicOptionsObject[question.id]" class="form-check inline-block pl-3 mt-0 mb-0">
                                                    <input type="checkbox" [formControlName]="question.id + '_' + key" class="form-check-input" [checked] = "checkedObjectSecondaryKeys[question.id + '_' + key + '_' + option.q + '__' + option.sak]" (change)="sectionFormChangeHandlerForTableCheckboxDynamicOptions($event, table, question, option, key)"> 
                                                    <span style="display:inline" class="form-check-label">&nbsp;</span>
                                                    <span style="font-size:0.78rem">{{option.label}}</span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div class="dateIcon" *ngIf="question.qa_subtype == 'date'">
                                                <input bsDatepicker readonly placement="top" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue', showClearButton: true, clearPosition: 'right' }" [isOpen]="dateOpen" class="form-control"
                                                 [formControlName]="question.id + '_' + key" (onHidden)="sectionFormChangeHandlerForTable($event, table, question, key)">
                                                <i class="utilicon utilicon-calendar-alt"></i>
                                                <i class="utilicon utilicon-times-circle-light" style="right:26px; color:#9D2835; cursor: pointer;" (click)="clearTableCalendarDate($event, table, question, key)" title="Clear Date" i18n-title="@@clearDate"></i>
                                              </div>
                                              <div class="upload-btn-wrapper" *ngIf="question.qa_subtype == 'file'" >
                                                <div>
                                                  <!-- *ngIf="isDesktopDevice;else tabletDevice" -->
                                                  <!-- <input type="file" class="form-control" (change)="handleUploadFileInput($event.target.files, question)" style="padding-top: 5px;"> -->
                                                  <button class="btnr" (click)="handleUploadFileInput($event.target.files, question, key)" i18n-title="@@noFileChosen">
                                                    <span>Choose File</span>
                                                  </button>
                                                  <input type="file" class="absolute-input" (change)="handleUploadFileInput($event.target.files, question, key)"
                                                  accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" />
                                                </div>
                                                <!-- <ng-template #tabletDevice>
                                                  <label [for]="question.id + '_' + key">
                                                    <span><i class="utilicon utilicon-camera-light" style="font-size: 2.2em;"></i></span>
                                                    <input type="file" [id]="question.id + '_' + key" (change)="handleUploadFileInput($event.target.files, question, key)"
                                                    accept="image/*" capture="camera" style="display:none" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen"/>
                                                  </label>
                                                </ng-template> -->
                                                <div *ngIf="uploadedFiles && uploadedFiles.length > 0" style="position:absolute;top: 7px;left: 110px;">
                                                  <div *ngFor="let file of uploadedFiles">                                                
                                                    <span *ngIf="file.id == question.id && file.sak == key">{{file.image_for}}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <span *ngIf="question.include_uom_question" [ngStyle]="{ width: question.uom_questions.length > 1 ? '90%' : '90%' }" class="ml-2">
                                                <span *ngFor="let uom of question.uom_questions; let i = index">
                                                  <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                                  <select class="form-control" [formControlName]="uom.id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, uom, key)">
                                                    <!-- <option value="" i18n="@@select">Select</option> -->
                                                    <option *ngFor="let uom_option of uom?.options" [value]="uom_option.id">{{uom_option?.uom}}</option>
                                                  </select>
                                                </span>
                                              </span>
                                            </div>
                                            <div *ngIf="question.other_questions && question.other_questions.length > 0 && tableOtherQuestionsIsDisplayed[question.other_questions[0].id + '_' + key]">
                                              <input type="text" class="form-control mt-2" [formControlName]="question.other_questions[0].id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, question.other_questions[0], key)">
                                            </div>
                                            <div class="error" *ngIf="(sectionForm?.controls[question.id + '_' + key]?.dirty || sectionForm?.controls[question.id + '_' + key]?.touched) && sectionForm?.controls[question.id + '_' + key]?.errors">
                                              <span *ngIf="sectionForm?.controls[question.id + '_' + key]?.errors?.pattern" i18n="@@enterValidInput">Enter valid input.</span>
                                              <span *ngIf="sectionForm?.controls[question.id + '_' + key]?.errors?.max"><span i18n="@@exceedMaximumValue">Exceeds maximum value: </span> {{sectionForm?.controls[question.id + '_' + key]?.errors?.max?.max}}</span>
                                              <span *ngIf="sectionForm?.controls[question.id + '_' + key]?.errors?.min"><span i18n="@@haveMinimumValue">Must have minimum value: </span> {{sectionForm?.controls[question.id + '_' + key]?.errors?.min?.min}}</span>
                                              <span *ngIf="sectionForm?.controls[question.id + '_' + key]?.errors?.required" i18n="@@inputIsRequired">Input is required</span>
                                            </div>
                                            <span *ngIf="question.qa_subtype == 'file'" class="small gray" style="font-size: 12px; font-weight: unset;">Allowed types: JPG, JPEG, PNG, WEBP</span>
                                          </td>
                                          <td class="text-center">
                                            <a (click)="confirmTableAnswer($event, table.id, key, true, 1)" title="Confirm Answer" class="confirm-answer" i18n-title="@@confirmAnswer" [class.answer-confirmed]="tableAnswersSAKs[key]">
                                              <i class="utilicon utilicon-check-circle-light"></i>
                                            </a>
                                            <a (click)="confirmTableAnswer($event, table.id, key, false, 1)" title="Answer Not Available" i18n-title="@@answerNotAvailable" class="answer-na" [class.answer-not-confirmed]="tableAnswersSAKs[key] == false">
                                              N/A
                                            </a>
                                          </td>
                                        </tr>
                                        <tr *ngFor="let gtQuestion of table.grand_total_questions">
                                          <td class="bold">{{gtQuestion.survey_q_asset_labels[0].label}}</td>
                                          <td *ngFor="let question of table.questions; let i = index" [id] = "question.id">
                                            <input type="number" class="form-control" *ngIf="i == table?.questions?.length - 1 && gtQuestion.qa_subtype == 'number'" [formControlName]="gtQuestion.id" />
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td style="border:none;" colspan="2">
                                            <!-- <button class="btn btn-secondary" style="margin-left:0" (click)="addToTable(table); $event.stopPropagation()">+Add</button> -->
                                            <a href="javascript:void(0)" [ngStyle]="{'pointer-events' : !tableQuestionAnswered || !cropNameQuestionAnswered ? 'none': '', 'color' : !tableQuestionAnswered || !cropNameQuestionAnswered ? '#ccc' : '#4f598b'}" class="blue" (click)="addToTable(table); $event.stopPropagation()">                                              
                                              <span class="ml-2 font-110 bold" i18n="@@+add">+ Add</span>
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div  style="overflow-x:auto;" *ngIf="table.qa_subtype == 'question_rows'">
                                    <table class="table fixed-width-table-rows">
                                      <thead class="no-box-shaow">
                                        <tr>
                                          <th style="width:30%"></th>
                                          <th style="width:20%" class="text-center" *ngFor="let key of secondary_asset_keys[table.id]" [id] = "key">
                                            <button (click)="removeFromTable(table, key); $event.stopPropagation()" class="qdelete-btn table-cross pos-r"><i class="utilicon utilicon-times"></i></button>
                                            <!-- Confirm button added here -->
                                            <a (click)="confirmTableAnswer($event, table.id, key, true, 1)" title="Confirm Answer" class="confirm-answer ml-3" [class.answer-confirmed]="tableAnswersSAKs[key]">
                                              <i class="utilicon utilicon-check-circle-light"></i>
                                            </a>
                                            <a (click)="confirmTableAnswer($event, table.id, key, false, 1)" title="Answer Not Available" class="answer-na" [class.answer-not-confirmed]="tableAnswersSAKs[key] == false">
                                              N/A
                                            </a> 
                                          </th>
                                          <th style="width:10%" >
                                            <a href="javascript:void(0)" [ngStyle]="{'pointer-events' : !tableQuestionAnswered || !cropNameQuestionAnswered ? 'none': '', 'color' : !tableQuestionAnswered || !cropNameQuestionAnswered ? '#ccc' : '#4f598b'}" class="blue" (click)="addToTable(table); $event.stopPropagation()">                                              
                                              <span class="ml-2 font-110" i18n="@@+add">+ Add</span>
                                            </a>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let question of table.questions" [id] = "question.id">
                                          <td>
                                            <span class="bold">{{question.survey_q_asset_labels[0].label}}</span>
                                            <ng-container *ngFor="let validation of question?.survey_q_asset_validations">
                                              <span class="bold red ml-2" *ngIf="validation?.validation_key=='mandatory' && validation?.validation_value=='true'">*</span>
                                            </ng-container>
                                          </td>
                                          <td *ngFor="let key of secondary_asset_keys[table.id]"> 
                                            <div style="display: flex; position: relative;" *ngIf="question[key+ '_isDisplayed']">
                                              <select class="form-control" *ngIf="question.qa_subtype == 'select'" [formControlName]="question.id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, question, key)">
                                                <option value="" i18n="@@select">Select</option>
                                                <option *ngFor="let option of question?.options" [value]="option.id">{{option?.survey_q_asset_labels[0]?.label}}</option>
                                              </select>
                                              <input type="text" class="form-control" *ngIf="question.qa_subtype == 'text'" [formControlName]="question.id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, question, key)" (click)="openTextarea($event)">
                                              <textarea class="hide text-area" *ngIf="question.qa_subtype == 'text'" maxlength="200" [formControlName]="question.id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, question, key)" (blur)="closeTextArea($event)"></textarea>
                                              <input type="number" class="form-control" *ngIf="question.qa_subtype == 'number'" [formControlName]="question.id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, question, key)">
                                              <div *ngIf="question.qa_subtype == 'multiselect'" class="input-like-box">
                                                <span *ngFor="let option of question?.options" class="form-check inline-block pl-3 mt-0 mb-0">
                                                  <input type="checkbox" [formControlName]="question.id + '_' + key" class="form-check-input" [checked] = "checkedObjectSecondaryKeys[question.id + '_'  + key + '_' + option.id]" (change)="sectionFormChangeHandlerForTableCheckbox($event, table, question, option, key)"/> 
                                                  <span style="display:inline" class="form-check-label">&nbsp;</span>
                                                  <span style="font-size:0.78rem">{{option?.survey_q_asset_labels[0]?.label}}</span>
                                                </span>
                                                <span *ngIf="dynamicOptionsObject && dynamicOptionsObject[question.id]">
                                                  <span *ngFor="let option of dynamicOptionsObject[question.id]" class="form-check inline-block pl-3 mt-0 mb-0">
                                                    <input type="checkbox" [formControlName]="question.id + '_' + key" class="form-check-input" [checked] = "checkedObjectSecondaryKeys[question.id + '_' + key + '_' + option.q + '__' + option.sak]" (change)="sectionFormChangeHandlerForTableCheckboxDynamicOptions($event, table, question, option, key)"> 
                                                    <span style="display:inline" class="form-check-label">&nbsp;</span>
                                                    <span style="font-size:0.78rem">{{option.label}}</span>
                                                  </span>
                                                </span>
                                              </div>                                              
                                              <div class="dateIcon" *ngIf="question.qa_subtype == 'date'">
                                                <input bsDatepicker readonly placement="top" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue', showClearButton: true, clearPosition: 'right' }" [isOpen]="dateOpen" class="form-control"
                                                 [formControlName]="question.id + '_' + key" (onHidden)="sectionFormChangeHandlerForTable($event, table, question, key)">
                                                <i class="utilicon utilicon-calendar-alt"></i>
                                                <i class="utilicon utilicon-times-circle-light" style="right:26px; color:#9D2835; cursor: pointer;" (click)="clearTableCalendarDate($event, table, question, key)" title="Clear Date" i18n-title="@@clearDate"></i>
                                              </div>
                                              <div class="upload-btn-wrapper" *ngIf="question.qa_subtype == 'file'" >
                                                <div>
                                                  <!-- *ngIf="isDesktopDevice;else tabletDevice" -->
                                                  <button class="btnr" (click)="handleUploadFileInput($event.target.files, question, key)" i18n-title="@@noFileChosen">
                                                    <span>Choose File</span>
                                                  </button>
                                                  <input type="file" class="absolute-input" (change)="handleUploadFileInput($event.target.files, question, key)"
                                                  accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" />
                                                </div>
                                                <!-- <ng-template #tabletDevice>
                                                  <button class="btnr" (click)="handleUploadFileInput($event.target.files, question, key)" i18n-title="@@noFileChosen">
                                                    <span>Choose File</span>
                                                  </button>
                                                  <input type="file" class="absolute-input" (change)="handleUploadFileInput($event.target.files, question, key)"
                                                  accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" />
                                                </ng-template> -->
                                                <div *ngIf="uploadedFiles && uploadedFiles.length > 0" style="position:absolute;top: 7px;left: 110px;">
                                                  <div *ngFor="let file of uploadedFiles">                                                
                                                    <span *ngIf="file.id == question.id && file.sak == key">{{file.image_for}}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <span *ngIf="question.include_uom_question" [ngStyle]="{ width: question.uom_questions.length > 1 ? '90%' : '90%' }" class="ml-2">
                                                <span *ngFor="let uom of question.uom_questions; let i = index">
                                                  <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                                  <select class="form-control" [formControlName]="uom.id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, uom, key)">
                                                    <!-- <option value="" i18n="@@select">Select</option> -->
                                                    <option *ngFor="let uom_option of uom?.options" [value]="uom_option.id">{{uom_option?.uom}}</option>
                                                  </select>
                                                </span>
                                              </span>
                                            </div>
                                            <div *ngIf="question.other_questions && question.other_questions.length > 0 && tableOtherQuestionsIsDisplayed[question.other_questions[0].id + '_' + key]">
                                              <input type="text" class="form-control mt-2" [formControlName]="question.other_questions[0].id + '_' + key" (change)="sectionFormChangeHandlerForTable($event, table, question.other_questions[0], key)">
                                            </div>
                                            <div class="error" *ngIf="(sectionForm?.controls[question.id + '_' + key]?.dirty || sectionForm?.controls[question.id + '_' + key]?.touched) && sectionForm?.controls[question.id + '_' + key]?.errors">
                                              <span *ngIf="sectionForm?.controls[question.id + '_' + key]?.errors?.pattern" i18n="@@enterValidInput">Enter valid input.</span>
                                              <span *ngIf="sectionForm?.controls[question.id + '_' + key]?.errors?.max"><span i18n="@@exceedMaximumValue">Exceeds maximum value: </span>{{sectionForm?.controls[question.id + '_' + key]?.errors?.max?.max}}</span>
                                              <span *ngIf="sectionForm?.controls[question.id + '_' + key]?.errors?.min"><span i18n="@@haveMinimumValue">Must have minimum value: </span> {{sectionForm?.controls[question.id + '_' + key]?.errors?.min?.min}}</span>
                                              <span *ngIf="sectionForm?.controls[question.id + '_' + key]?.errors?.required" i18n="@@inputIsRequired">Input is required</span>
                                            </div>
                                            <span *ngIf="question.qa_subtype == 'file'" class="small gray" style="font-size: 12px; font-weight: unset;">Allowed types: JPG, JPEG, PNG, WEBP</span>
                                          </td>
                                          <td>&nbsp;</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="fixed-actions form-box actions">
                        <select style="margin-right: 10px" *ngIf="revisionsInSurvey.length > 0" (change)="selectRevision()" [(ngModel)]="selectedRevision" [ngModelOptions]="{standalone: true}">
                          <option value="" i18n="@@select">Select</option>
                          <option *ngFor="let revision of revisionsInSurvey" [value]="revision.id">{{revision.name}}</option>
                        </select>
                        <button [disabled]="showHideBackButton || !cropNameQuestionAnswered || !mandatoryQuestionAnswered" class="btn btn-secondary" (click)="navigateSection(-1)">
                          <span i18n="@@back">Back</span>
                        </button>
                        <button [disabled]="showHideNextButton || !cropNameQuestionAnswered || !mandatoryQuestionAnswered" class="btn btn-secondary" (click)="navigateSection(1)">
                          <span i18n="@@next">Next</span>
                        </button>
                        <button class="btn" [ngClass]="askUserToConfirmExit ? 'btn-primary' : 'btn-secondary'" [disabled]="!askUserToConfirmExit || !cropNameQuestionAnswered" (click)="saveProfilingData()">
                          <span i18n="@@save">Save</span>
                        </button>
                        <button class="btn btn-secondary" (click)="exitClicked()"> 
                            <span i18n="@@exit">Exit</span>
                        </button>
                        </div>
                    </form>
                    <ng-template #confirmTemplate>
                      <div class="modal-header">
                        <h4 class="modal-title pull-left" i18n="@@confirm">Confirm</h4>
                        <button type="button" class="close pull-right" title="Close" i18n-title="@@close" aria-label="Close" (click)="closeSurveyTemplateModal(); $event.stopPropagation()">
                          <i class="utilicon utilicon-times-light"></i>
                        </button>
                      </div>
                      <div class="modal-body add-farmer-form">
                        <div class="container-fluid text-center">
                          <p class="text-danger">
                            Warning: You have {{unsavedAnswerCount}} unsaved change{{unsavedAnswerCount > 1 ? 's' : ''}}. Answer{{unsavedAnswerCount > 1 ? 's' : ''}} will not be saved if you exit.
                          </p>
                          <div>
                            <button type="button" class="btn btn-primary" (click)="saveProfilingData(true); $event.stopPropagation()" i18n="@@saveAndExit">Save &amp; Exit</button>
                            <button type="button" class="btn btn-secondary ml-4" (click)="confirmExit(); $event.stopPropagation()" i18n="@@exit">Exit</button>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #exitConfirmModalTemplate>
                      <div class=" modal-body text-center ">
                        <h3 i18n="@@haveAnswerWhichHavnotSaveDoYouWannaExit">
                          You have answers which have not been saved. Are you sure you want to exit?
                        </h3>
                        <button type="button " class="btn btn-secondary mr-3 " (click)="closeModal(true)" i18n="@@yes" i18n-title="@@yes">Yes</button>
                        <button type="button " class="btn btn-primary " (click)="closeModal(false)" i18n="@@no" i18n-title="@@no">No</button>
                      </div>
                    
                    </ng-template>
                  </div>
                  </div>
                  <div  class="farmer-tab-content card" id="pills-tabContent"  *ngIf="surveyHasReference">
                    <div id="refDoc" [style.display]="showReferenceTab ? 'block' : 'none'">
                      <!-- <input type="text" class="form-control" (keyup)="searchPdf($event)" placeholder="Search PDF" /> -->
                      <!-- <iframe src="/{{locale}}/assets/uploads/surveyManual/{{activeSnapshot.flex4}}" type="application/pdf" width="100%" height="100%" style="overflow: auto;">
                      </iframe> -->
                      <!-- src="assets/uploads/surveyManual/{{activeSnapshot.flex4}}" -->
                      <!-- <embed [src]="'assets/uploads/surveyManual/' + activeSnapshot.flex4 | safe" 
                          style="width: 100%;height: 500px" 
                          type="application/pdf"> -->

                          <ngx-extended-pdf-viewer
     [src]="docUrl"
     useBrowserLocale="true"
     [textLayer]="true">
</ngx-extended-pdf-viewer>

                          <!-- <pdf-viewer [src]="docUrl"
                          [render-text]="true"
                          [original-size]="false"
                          style="width: 400px; height: 500px" 
              ></pdf-viewer> -->
                      <!-- <object data="http://localhost:3000/en/assets/uploads/surveyManual/ccce5a0849f0a111a0e33d4ee4beb850b4514332ceee6cadb9f4f9e355f99a9b-1697799195077.pdf" type="application/pdf" width="100%" height="500px">
        <p>Unable to display PDF file. <a href="http://localhost:3000/en/assets/uploads/surveyManual/ccce5a0849f0a111a0e33d4ee4beb850b4514332ceee6cadb9f4f9e355f99a9b-1697799195077.pdf">Download</a> instead.</p>
      </object> -->
                    </div>
                  </div>
                </div>
                <div *ngIf="questionnaire && questionnaire.length == 0">
                  <!-- <p i18n="@@issuesWithQuestionnaireContactProjectAdministrator">There may be issues with this questionnaire. Please contact Project Administrator for support.</p> -->
                  <p i18n="@@surveyNotReleasedCurrentLanguage">Survey is not released in current language</p>
                </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
<div class="msg-wizard">
  <div class="form-wizard" *ngIf="wizardShow">
    <h3 i18n="@@quickNotes">Quick Notes</h3>
    <div class="farmer-name">
      <span>{{farmer.name}}</span>
      <span>{{farmer.tel_number}}</span>
    </div>
    <form>
      <div class="form-group">
        <textarea i18n-placeholder="@@quickNotes" placeholder="Quick Notes" class="form-control" rows="7" name="notes" [(ngModel)]="notes.notes"></textarea> </div>
      <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="saveNotes()" i18n="@@saveNotes">Save Notes</button>
      </div>
    </form>
  </div>
  <div class="clearfix"></div>
  <div class="msg-wizard-action" (click)="wizardToggle()" [ngClass]="{'open':wizardShow}" i18n-title="@@quickNotes">
    <i class="utilicon utilicon-exclamation-circle-light"></i>
  </div>
</div>
<div class="msg-wizard" style="left: 99px; right: auto;" *ngIf="showSurveyManualIcon == true  && !showReferenceTab" >
  <div class="msg-wizard-action" style="float: left;" i18n-title="@@downloadManualReference" title = "View Manual Reference"
  (click)="tabClicked(-1)" [class.disabled]="!cropNameQuestionAnswered || !mandatoryQuestionAnswered ? true : false">
    <i class="utilicon utilicon-book-light"></i>
  </div>
</div>
</div>
