<div class="mt-3">
  <div *ngIf="currentTab.length == 0">
    <div *ngIf="!isCertificate">
      <div i18n="@@noTrainingFoundForFarmer">No training found for this farmer</div>
      <div class="pt-4">
        <button class="btn btn-primary" type="button" title="Add Training" i18n-title="@@addTraining" (click)="addTrainingCertificate(addTraining)"><span i18n="@@addTraining">Add Training</span></button>
        <button class="btn btn-secondary" type="button" (click)="exitClicked(groundForm,exitConfirmTemplate);$event.stopPropagation()"><span i18n="@@exit">Exit</span></button>
      </div>
    </div>
    <div *ngIf="isCertificate">
      <div i18n="@@noCertificationFoundForFarmer">No certification found for this farmer</div>
      <div class="pt-4">
        <button class="btn btn-primary" type="button" title="Update" (click)="addTrainingCertificate(addTraining)" title="Add Certification" i18n-title="@@addCertification"><span i18n="@@addCertification">Add Certification</span></button>
        <button class="btn btn-secondary" type="button" (click)="exitClicked(groundForm,exitConfirmTemplate);$event.stopPropagation()"><span i18n="@@exit">Exit</span></button>
      </div>
    </div>
  </div>
  <div class="tab-pane fade in active" role="tabpanel" aria-labelledby="pills-personal-tab" *ngIf="currentTab.length > 0">
    <form [formGroup]="groundForm">
      <div *ngIf="!isCertificate">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <label for="text-result"><span i18n="@@trainingTopic">Training Topic </span><span class="mandatory">*</span></label>
            <select class="form-control" formControlName="trainingName" name="trainingName" (change)="showAddButton()">
              <option value="0" class="option" selected i18n="@@select">Select</option>
              <option *ngFor="let item of trainings; trackBy: trackBytraining" [ngValue]="item.project_training_certification_mapping_id">{{item.training_certification_name}}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12">
            <label for="text-result" i18n="@@description">Description</label>
            <input type="text" formControlName="description" class="form-control">
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-lg-6 col-md-12">
            <label for="text-result"><span i18n="@@trainerName">Trainer Name </span><span class="mandatory">*</span></label>
            <select class="form-control" formControlName="trainerName" name="trainerName"(change)="showAddButton()">
              <option value="0" class="option" selected i18n="@@select">Select</option>
              <option *ngFor="let item of trainers; trackBy: trackBytrainer" [ngValue]="item.users_id">{{item.users.name}}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12">
            <label for="text-result"><span i18n="@@trainingDate">Training Date </span><span class="mandatory">*</span></label>
            <div class="dateIcon" (click)="isTrainingOpen = !isTrainingOpen">
              <input bsDatepicker readonly placement="top" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue' }" [isOpen]="isTrainingOpen" class="form-control"
              placeholder="DD-MM-YYYY" formControlName="trainingDate">
              <i class="utilicon utilicon-calendar-alt"></i>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-lg-6 col-md-12">
            <label for="text-result" i18n="@@group">Group</label>
            <select class="form-control mb-2" formControlName="group" name="farmerGroup" (change)="showAddButton()">
              <option value="0" class="option" selected i18n="@@select">Select</option>
              <option *ngFor="let item of allGroups; trackBy: trackBytrainer" [ngValue]="item.project_group_mapping_id">{{item.training_certification_group.name}}</option>
            </select>
            <div class="row no-gutters">
              <div class="col-lg-4 col-md-12 ellipsis">
                <span class="form-check ml-2">
                  <input type="checkbox" class="form-check-input" formControlName="trainingAttended">
                  <label class="form-check-label gray" title="Training Attended" i18n-title="@@trainingAttended" i18n="@@trainingAttended">Training Attended</label>
                </span>
              </div>
              <div class="col-lg-4 col-md-12 ellipsis">
                <span class="form-check ml-2">
                  <input type="checkbox" class="form-check-input" (change)="checkTrainingAttended($event)" formControlName="spouse">
                  <label class="form-check-label gray" title="Spouce Attended" i18n-title="@@spouseAttended" i18n="@@spouseAttended">Spouse Attended</label>
                </span>
              </div>
              <div class="col-lg-4 col-md-12 ellipsis">
                <span class="form-check ml-2">
                  <input type="checkbox" class="form-check-input" (change)="checkTrainingAttended($event)" formControlName="children">
                  <label class="form-check-label gray" title="Children Aged > 16" i18n-title="@@childrenAged16" i18n="@@childrenAged16">Children Aged > 16</label>
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <label for="text-result"><span i18n="@@trainingDetails">Training Details </span><span class="small gray" i18n="@@includingAttendance">(including attendance)</span></label>
            <div class="upload-btn-wrapper">
              <div>
                <!-- *ngIf="isDesktopDevice;else tabletDevice" -->
                <button [class.btnr]="currentSection?.for_individual == 1" [class.disabled]="currentSection?.for_individual == 0" [disabled]="currentSection?.for_individual == 0" (click)="handleTrainingFileInput($event.target.files)" i18n-title="@@noFileChosen">
                  <span i18n="@@chooseFiles">Choose Files</span>
                </button>
                <input type="file" [disabled]="currentSection?.for_individual == 0" class="absolute-input" [name]="question1?.code" id="fileInput" (change)="handleTrainingFileInput($event.target.files)"
                accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}" />
              </div>
              <!-- <ng-template #tabletDevice>
                <label for="fileInput">
                  <span><i class="utilicon utilicon-camera-light" style="font-size: 2.2em;"></i></span>
                  <input type="file" [disabled]="currentSection?.for_individual == 0" class="" id="fileInput" (change)="handleTrainingFileInput($event.target.files)"
                  accept="image/*" capture="camera" style="display:none" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}" />
                </label>
              </ng-template> -->
            </div>
            <div class="small gray" style="font-size: 12px; font-weight: unset;" i18n="@@uploadOneMoreImages">Upload one or more images | square images are best fit, max image size 10MB</div>
            <div class="mt-2">
              <table class="table">
                <tbody>
                  <tr *ngFor="let file of uploadedTrainingFilesOld" class="bold">
                    <td style="width:90%"><span>{{ file.image_for }}</span></td>
                    <td style="width: 10%;" class="text-center">
                      <span class="cursorPointer bold" (click)="downloadImage(file.image_file, file.image_for)">
                        <i class="utilicon utilicon-arrow-alt-to-bottom" style="line-height: inherit; font-size: 13px;"></i>
                      </span>
                    </td>
                    <td style="width: 10%;" class="text-center" *ngIf="currentSection?.for_individual == 1">
                      <span class="cursorPointer red" (click)="removeTrainingFileOld(file.id, deleteFileTemplate)">
                        <i class="utilicon utilicon-times" style="line-height: inherit; font-size: 13px;"></i>
                      </span>
                    </td>
                  </tr>
                  <tr *ngFor="let file of uploadedTrainingFiles" class="bold">
                    <td style="width:80%"><span>{{ file.image_for }}</span></td>
                    <td style="width: 10%;" class="text-center">
                      <span class="cursorPointer bold" (click)="downloadImage(file.image_file, file.image_for)">
                        <i class="utilicon utilicon-arrow-alt-to-bottom" style="line-height: inherit; font-size: 13px;"></i>
                      </span>
                    </td>
                    <td style="width: 10%;" class="text-center">
                      <span class="cursorPointer red" (click)="removeTrainingFile(file.image_for, deleteFileTemplate)">
                        <i class="utilicon utilicon-times" style="line-height: inherit; font-size: 13px;"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="fixed-actions form-box actions">
          <button class="btn btn-primary" type="button" title="Update" (click)="addTrainingCertificate(addTraining)"><span i18n="@@addTraining">Add Training</span></button>
          <button [disabled]="currentSectionIndex == 0" class="btn btn-secondary" (click)="navigateSection(-1)">
            <span i18n="@@back">Back</span>
          </button>
          <button [disabled]="currentSectionIndex == currentTab.length - 1" class="btn btn-secondary" (click)="navigateSection(1)">
            <span i18n="@@next">Next</span>
          </button>
          <button class="btn btn-primary" type="button" (click)="updateTraining()" [disabled]="!groundForm.valid" title="Update"><span i18n="@@update">Update</span></button>
          <button class="btn btn-secondary" type="button" (click)="exitClicked(groundForm,exitConfirmTemplate);$event.stopPropagation()"><span i18n="@@exit">Exit</span></button>
        </div>
      </div>
      <div *ngIf="isCertificate">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <label for="text-result"><span i18n="@@certificationName">Certification Name </span><span class="mandatory">*</span></label>
            <select class="form-control" formControlName="certificateName" name="certificateName">
              <option value="0" class="option" selected i18n="@@select">Select</option>
              <option *ngFor="let item of certifications; trackBy: trackBytraining" [ngValue]="item.project_training_certification_mapping_id">{{item.training_certification_name}}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12">
            <label for="text-result" i18n="@@description">Description</label>
            <input type="text" formControlName="description" class="form-control">
          </div>
        </div>      
        <div class="row pt-3">
          <div class="col-lg-6 col-md-12">
            <label for="text-result"><span i18n="@@certificationDate">Certification Date </span><span class="mandatory">*</span></label>
            <div class="dateIcon" (click)="isCertificateOpen = !isCertificateOpen">
              <input bsDatepicker readonly placement="top" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue' }" [isOpen]="isCertificateOpen" class="form-control"
              placeholder="DD-MM-YYYY" formControlName="certificationDate">
              <i class="utilicon utilicon-calendar-alt"></i>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <label for="text-result"><span i18n="@@expiryDate">Expiry Date </span></label>
            <div class="dateIcon" (click)="isExpiryOpen = !isExpiryOpen">
              <input bsDatepicker readonly placement="top" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue' }" [isOpen]="isExpiryOpen" class="form-control"
              placeholder="DD-MM-YYYY" formControlName="expiryDate">
              <i class="utilicon utilicon-calendar-alt"></i>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-lg-6 col-md-12">
            <label for="text-result" i18n="@@group">Group</label>
            <select class="form-control mb-2" formControlName="group" name="farmerGroup" (change)="showAddButton()">
              <option value="0" class="option" selected i18n="@@select">Select</option>
              <option *ngFor="let item of allGroups; trackBy: trackBytrainer" [ngValue]="item.project_group_mapping_id">{{item.training_certification_group.name}}</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-12">
            <span class="form-check pt-4 pl-2">
              <input type="checkbox" class="form-check-input" formControlName="certified">
              <label class="form-check-label gray" i18n="@@certified">Certified</label>
            </span>
          </div>
        </div> 
        <div class="fixed-actions form-box actions">
          <button class="btn btn-primary" type="button" title="Update" (click)="addTrainingCertificate(addTraining)"><span i18n="@@addCertification">Add Certification</span></button>
          <button [disabled]="currentSectionIndex == 0" class="btn btn-secondary" (click)="navigateSection(-1)">
            <span i18n="@@back">Back</span>
          </button>
          <button [disabled]="currentSectionIndex == currentTab.length - 1" class="btn btn-secondary" (click)="navigateSection(1)">
            <span i18n="@@next">Next</span>
          </button>
          <button class="btn btn-primary" type="button" (click)="updateCertification()" [disabled]="!groundForm.valid" title="Update"><span i18n="@@update">Update</span></button>
          <button class="btn btn-secondary" type="button" (click)="exitClicked(groundForm,exitConfirmTemplate);$event.stopPropagation()"><span i18n="@@exit">Exit</span></button>
        </div>
      </div>
    </form>
  </div>
  <ul class="nav nav-pills sub-tab-fixed" id="pills-tab" role="tablist" #widgetsContent style="visibility: hidden;">
    <li class="nav-item " *ngFor="let item of currentTab; let index = index;">
      <a class="nav-link" [ngClass]="{'active' : currentSection?.id == item.recording_hdr_id}" id="pills-personal-tab"
        data-toggle="pill" role="tab" aria-selected="true" (click)="subtabClicked(index)">
        {{item?.recording_hdr_id}}
      </a>
    </li>
  </ul>
</div>
<ng-template #addTraining>
  <div *ngIf="confirmAddModal">
    <div class="modal-header">
      <h4 class="modal-title pull-left" *ngIf="!isCertificate" i18n="@@addNewTraining">Add New Training</h4>
      <h4 class="modal-title pull-left" *ngIf="isCertificate" i18n="@@addNewCertificate">Add New Certificate</h4>
      <button type="button" title="Close" i18n-title="@@close" class="close pull-right" aria-label="Close"
        (click)="closeAddTrainingPopup()">
        <i class="utilicon utilicon-times-light"></i>
      </button>
    </div>
    <div class="modal-body add-farmer-form">
      <div class="container-fluid">
        <form [formGroup]="popupForm">
          <div *ngIf="!isCertificate">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <label for="text-result"><span i18n="@@trainingTopic">Training Topic </span><span class="mandatory">*</span></label>
                <select class="form-control" formControlName="trainingName" name="trainingName" (change)="showPopupAddButton()">
                  <option value="0" class="option" selected i18n="@@select">Select</option>
                  <option *ngFor="let item of projectTrainings; trackBy: trackBytraining" [ngValue]="item.project_training_certification_mapping_id">{{item.training_certification_master.name}}</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-12">
                <label for="text-result" i18n="@@description">Description</label>
                <input type="text" formControlName="description" class="form-control">
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-lg-6 col-md-12">
                <label for="text-result"><span i18n="@@trainerName">Trainer Name </span><span class="mandatory">*</span></label>
                <select class="form-control" formControlName="trainerName" name="trainerName" (change)="showPopupAddButton()">
                  <option value="0" class="option" selected i18n="@@select">Select</option>
                  <option *ngFor="let item of trainers; trackBy: trackBytrainer" [ngValue]="item.users_id">{{item.users.name}}</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-12">
                <label for="text-result"><span i18n="@@trainingDate">Training Date </span><span class="mandatory">*</span></label>
                <div class="dateIcon" (click)="isTrainingPopupOpen = !isTrainingPopupOpen">
                  <input bsDatepicker readonly placement="bottom" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue' }" [isOpen]="isTrainingPopupOpen" class="form-control"
                  placeholder="DD-MM-YYYY" formControlName="trainingDate"  [maxDate]="maxDate" (onHidden)="showPopupAddButton()">
                  <i class="utilicon utilicon-calendar-alt"></i>
                </div>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-lg-6 col-md-12">
                <div class="row no-gutters">
                  <div class="col-lg-4 col-md-12 ellipsis">
                    <span class="form-check ml-2">
                      <input type="checkbox" class="form-check-input" formControlName="trainingAttended">
                      <label class="form-check-label" title="Training Attended" i18n-title="@@trainingAttended" i18n="@@trainingAttended">Training Attended</label>
                    </span>
                  </div>
                  <div class="col-lg-4 col-md-12 ellipsis">
                    <span class="form-check ml-2">
                      <input type="checkbox" class="form-check-input" formControlName="spouse">
                      <label class="form-check-label" title="Spouse Attended" i18n-title="@@spouseAttended" i18n="@@spouseAttended">Spouse Attended</label>
                    </span>
                  </div>                  
                  <div class="col-lg-4 col-md-12 ellipsis">
                    <span class="form-check ml-2">
                      <input type="checkbox" class="form-check-input" formControlName="children">
                      <label class="form-check-label" title="Children Aged > 16" i18n-title="@@childrenAged16" i18n="@@childrenAged16">Children Aged > 16</label>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <label for="text-result"><span i18n="@@trainingDetails">Training Details </span><span class="small gray" i18n="@@includingAttendance">(including attendance)</span></label>
                <div class="upload-btn-wrapper">
                  <div>
                    <!-- *ngIf="isDesktopDevice;else tabletDevice" -->
                    <button class="btnr" (click)="handleNewTrainingFileInput($event.target.files)" i18n-title="@@noFileChosen">
                      <span i18n="@@chooseFiles">Choose Files</span>
                    </button>
                    <input type="file" class="absolute-input" [name]="question1?.code" id="fileInput" (change)="handleNewTrainingFileInput($event.target.files)"
                    accept="image/*" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}" />
                  </div>
                  <!-- <ng-template #tabletDevice>
                    <label for="fileInput">
                      <span><i class="utilicon utilicon-camera-light" style="font-size: 2.2em;"></i></span>
                      <input type="file" class="" id="fileInput" (change)="handleNewTrainingFileInput($event.target.files)"
                      accept="image/*" capture="camera" style="display:none" data-max-size="10485760" multiple="false" i18n-title="@@noFileChosen" title="{{noFileChosenDefault}}" />
                    </label>
                  </ng-template> -->
                </div>
                <div class="small gray" style="font-size: 12px; font-weight: unset;" i18n="@@uploadOneMoreImages">Upload one or more images | square images are best fit, max image size 10MB</div>
                <div class="mt-2" *ngIf="uploadedNewTrainingFiles && uploadedNewTrainingFiles.length > 0">
                  <table class="table">
                    <tbody>
                      <tr *ngFor="let file of uploadedNewTrainingFiles" class="bold">
                        <td style="width:80%">{{ file.image_for }}</td>
                        <td style="width: 20%;" class="text-center">
                          <span class="cursorPointer bold red" (click)="removeNewTrainingFile(file.image_for)">X</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="text-center pt-2">
                  <button class="btn btn-primary" type="button" (click)="addNewTraining()" [disabled]="disablePopupAddButton" title="Save" i18n-title="@@save"><span i18n="@@save">Save</span></button>
                  <button class="btn btn-secondary" type="button" (click)="closeAddTrainingPopup(popupForm)"><span i18n="@@cancel">Cancel</span></button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isCertificate">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <label for="text-result"><span i18n="@@certificationName">Certification Name </span><span class="mandatory">*</span></label>
                <select class="form-control" formControlName="certificateName" name="certificateName" (change)="showCertiPopupAddButton()">
                  <option value="0" class="option" selected i18n="@@select">Select</option>
                  <option *ngFor="let item of projectCertifications; trackBy: trackBytraining" [ngValue]="item.project_training_certification_mapping_id">{{item.training_certification_master.name}}</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-12">
                <label for="text-result" i18n="@@description">Description</label>
                <input type="text" formControlName="description" class="form-control">
              </div>
            </div>      
            <div class="row pt-3">
              <div class="col-lg-6 col-md-12">
                <label for="text-result"><span i18n="@@certificationDate">Certification Date </span><span class="mandatory">*</span></label>
                <div class="dateIcon" (click)="isCertificatePopupOpen = !isCertificatePopupOpen">
                  <input bsDatepicker readonly placement="bottom" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue' }" [isOpen]="isCertificatePopupOpen" class="form-control"
                  placeholder="DD-MM-YYYY" formControlName="certificationDate" [maxDate]="maxDate" (onHidden)="showCertiPopupAddButton()">
                  <i class="utilicon utilicon-calendar-alt"></i>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <label for="text-result"><span i18n="@@expiryDate">Expiry Date </span></label>
                <div class="dateIcon" (click)="isExpiryPopupOpen = !isExpiryPopupOpen">
                  <input bsDatepicker readonly placement="bottom" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue' }" [isOpen]="isExpiryPopupOpen" class="form-control"
                  placeholder="DD-MM-YYYY" formControlName="expiryDate" [minDate]="minDate"  (onHidden)="showCertiPopupAddButton()">
                  <i class="utilicon utilicon-calendar-alt"></i>
                </div>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-lg-6 col-md-12">                
                <span class="form-check" style="display: inline;">
                  <input type="checkbox" class="form-check-input" formControlName="certified">
                  <label class="form-check-label" i18n="@@certified">Certified</label>
                </span>
              </div>
            </div>    
            <div class="row">
              <div class="col-sm-12">
                <div class="text-center pt-2">
                  <button class="btn btn-primary" type="button" (click)="addNewCertification()" [disabled]="popupCertiAddButton" title="Save" i18n-title="@@save"><span i18n="@@save">Save</span></button>
                  <button class="btn btn-secondary" type="button" (click)="closeAddTrainingPopup(popupForm)"><span i18n="@@cancel">Cancel</span></button>
                </div>
              </div>
            </div>   
          </div>
        </form>
      </div>   
    </div>
  </div>
  <div *ngIf="!confirmAddModal">
    <div class="modal-body text-center">
      <h3 i18n="@@areYouSureWantToClose">
        ARE YOU SURE YOU WANT TO CLOSE?
      </h3>
      <button type="button" title="Yes" i18n="@@yes" i18n-title="@@yes" class="btn btn-secondary mr-1" (click)="confirmAddClose('Yes')">Yes</button>
      <button type="button" title="No" i18n="@@no" i18n-title="@@no" class="btn btn-primary cancel-focus" autofocus appAutofocus (click)="confirmAddClose('No')">No</button>
    </div>
  </div>
</ng-template>
<ng-template #exitConfirmTemplate>
  <div *ngIf="!confirmModalPopup">
    <div class="modal-body text-center">
      <h3 i18n="@@areYouSureWantToExit">
        Are you sure you want to exit?
      </h3>
      <button type="button" class="btn btn-secondary mr-1" (click)="confirmExitModal('Yes')" i18n="@@yes" i18n-title="@@yes">Yes</button>
      <button type="button" class="btn btn-primary cancel-focus" autofocus appAutofocus (click)="confirmExitModal('No')" i18n="@@no" i18n-title="@@no">No</button>
    </div>
  </div>
</ng-template>
<ng-template #deleteFileTemplate>
  <div>
    <div class="modal-body text-center">
      <h3 i18n="@@wantToDeleteRecord">
        Are you sure you want to delete this file?
      </h3>
      <button type="button" class="btn btn-secondary mr-1" (click)="confirmFileDelete('Yes')" i18n="@@yes" i18n-title="@@yes">Yes</button>
      <button type="button" class="btn btn-primary cancel-focus" autofocus appAutofocus (click)="confirmFileDelete('No')" i18n="@@no" i18n-title="@@no">No</button>
    </div>
  </div>
</ng-template>