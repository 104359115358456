import * as tslib_1 from "tslib";
import { OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { merge, of, fromEvent, Subject } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CommonService } from '../../services/common.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ProjectService } from '../../services/project.service';
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environment';
import { FarmerService } from '../../services/farmer.service';
import { SurveyService } from '../../services/survey.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import Compressor from 'compressorjs';
import { DataConsentService } from '../../services/data-consent.service';
var FarmerdetailsComponent = /** @class */ (function () {
    function FarmerdetailsComponent(_route, toastrService, commonService, formBuilder, userService, router, translate, farmerService, modalService, spinner, projectService, localeService, locale, cd, deviceService, surveyService, dataConsentService
    // private mapsAPILoader: MapsAPILoader
    ) {
        var _this = this;
        this._route = _route;
        this.toastrService = toastrService;
        this.commonService = commonService;
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.router = router;
        this.translate = translate;
        this.farmerService = farmerService;
        this.modalService = modalService;
        this.spinner = spinner;
        this.projectService = projectService;
        this.localeService = localeService;
        this.locale = locale;
        this.cd = cd;
        this.deviceService = deviceService;
        this.surveyService = surveyService;
        this.dataConsentService = dataConsentService;
        this.isOnline = navigator.onLine;
        this.genderData = [];
        this.educationLevelData = [];
        this.schoolLevelData = [];
        this.isNextClicked = true;
        this.currentTabIndex = 0;
        this.myFormStatus = false;
        this.uploadingFileNameFlag = false;
        this.currentUser = this.userService.getCurrentUser();
        this.showSpinner = false;
        this.subject = new Subject();
        this.imageUrl = 'assets/images/photo.webp';
        this.fileToUpload = null;
        this.confirmModalPopup = true;
        this.villageAreaList = [];
        this.f1Submitted = false;
        this.canDoProfiling = false;
        this.farmerUpdatedOffline = false;
        this.unitTrustsPnl = 0;
        this.isCertificate = false;
        this.trainingMissed = false;
        this.canAddTraining = false;
        this.trainingDetailsTabIndex = 1;
        this.farmDetails = 2;
        this.mapDisplay = false;
        this.farms = [];
        this.originalFarms = [];
        this.noNewFarm = false;
        this.farmRecording = {
            start: '',
            intermediate: [],
            end: ''
        };
        //below vars are for agm core
        this.lat = 51.678418;
        this.lng = 7.809007;
        this.canDoGeoplotting = false;
        this.farmFileToUpload = null;
        this.farmTypeSelected = 0;
        this.online$ = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.localeService.use(this.locale);
        this.translate.get('enter_value_object').subscribe(function (result) {
            _this.enterValueObject = result;
            // console.log('this.enterValueObject => ', this.enterValueObject);
        });
        this.translate.get('file_upload_component_default_txt').subscribe(function (result) {
            _this.noFileChosenDefault = result; // default text for the file input component
        });
        this.translate.get('farm_type_not_selected').subscribe(function (result) {
            _this.farmTypeNotSelected = result;
        });
        this.translate.get('Farm_saved_successfully').subscribe(function (result) {
            _this.farmSavedSuccessfully = result;
        });
        this.translate.get('farm_deleted_successfully').subscribe(function (result) {
            _this.farmDeletedSuccessfully = result;
        });
        this.translate.get('annual_forecast_updated_successfully').subscribe(function (result) {
            _this.annualForecastUpdatedSuccessfully = result;
        });
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();
    }
    FarmerdetailsComponent.prototype.onOnline = function (event) {
        this.isOnline = true;
    };
    FarmerdetailsComponent.prototype.onOffline = function (event) {
        this.isOnline = false;
    };
    FarmerdetailsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmerId, requests, farmertmpobj, farmerFromIDB, requests, farmerRegData, i, i, _i, _a, _b, key, value, request, currentFarmer;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        farmerId = +this._route.snapshot.params['id'] || 0;
                        this.projectId = +this._route.snapshot.params['projectId'];
                        this.rawFarmerId = this._route.snapshot.params['id'];
                        this.uploadingFileName = '';
                        this.farmerMainObject = {};
                        this.showSpinner = true;
                        this._route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (queryParams.get('campaignId') && +queryParams.get('campaignId') !== 0 && !isNaN(+queryParams.get('campaignId'))) {
                                    this.campaignId = queryParams.get('campaignId');
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        if (!navigator.onLine) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),])];
                    case 1:
                        requests = _c.sent();
                        this.farmer = requests[0].data.find(function (x) { return x.id == farmerId; });
                        this.farmerPhotoPath = this.farmer.photo_path;
                        this.master = this._route.snapshot.data.farmerData.master.data;
                        this.project = this._route.snapshot.data.farmerData.project;
                        this._route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (queryParams.get('trainingMissed') && queryParams.get('trainingMissed') === 'true') {
                                    this.trainingMissed = true;
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        this.farmerService.getFarmerManagementTabs().subscribe(function (response) {
                            _this.tabsList = response.data;
                            //console.log("this.tabsList  ", this.tabsList);
                            _this.selectedTab = _this.tabsList[0];
                            var farmerRegData = [];
                            for (var i = 0; i < _this.master.length; i++) {
                                if (_this.master[i].item_tab == _this.tabsList[0].tab_key) {
                                    farmerRegData.push(_this.master[i]);
                                }
                            }
                            //console.log("farmerRegData ", farmerRegData);
                            for (var i = 0; i < farmerRegData.length; i++) {
                                if (farmerRegData[i].item_code == 'sex') {
                                    _this.genderData.push(farmerRegData[i]);
                                }
                                else if (farmerRegData[i].item_code == 'education_level') {
                                    _this.educationLevelData.push(farmerRegData[i]);
                                }
                                else if (farmerRegData[i].item_code == 'school_level') {
                                    _this.schoolLevelData.push(farmerRegData[i]);
                                }
                            }
                            //console.log("this.genderData ", this.genderData);
                            //console.log("this.educationLevelData ", this.educationLevelData);
                            _this.getSelectedTabData();
                        });
                        this.setVillageAreaList();
                        return [3 /*break*/, 5];
                    case 2: return [4 /*yield*/, idbApp.getAllProjects('farmerstmp')];
                    case 3:
                        farmertmpobj = _c.sent();
                        farmerFromIDB = void 0;
                        if (farmertmpobj && farmerId && farmerId > 0) {
                            farmerFromIDB = farmertmpobj.find(function (x) { return x.id == farmerId; });
                        }
                        else {
                            farmerFromIDB = farmertmpobj.find(function (x) { return x.tmp_id == _this.rawFarmerId + '0'; });
                            if (!farmerFromIDB) {
                                farmerFromIDB = farmertmpobj.find(function (x) { return x.tmp_id == _this.rawFarmerId; });
                            }
                        }
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.GetFarmerMaster().toPromise(),
                                this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                                this.farmerService.getFarmerManagementTabs().toPromise(),
                                this.dataConsentService.getFarmerConsents(this.projectId).toPromise()
                                // farmer reg questions
                            ])];
                    case 4:
                        requests = _c.sent();
                        console.log('requests', requests);
                        this.master = requests[0].data;
                        this.project = this._route.snapshot.data.farmerData.project;
                        this.farmer = farmerFromIDB ? farmerFromIDB : requests[1].data.find(function (x) { return x.id == farmerId; });
                        this.farmerPhotoPath = this.farmer.photo_path;
                        this.tabsList = requests[2].data;
                        this.selectedTab = this.tabsList[0];
                        farmerRegData = [];
                        for (i = 0; i < this.master.length; i++) {
                            if (this.master[i].item_tab == this.tabsList[0].tab_key) {
                                farmerRegData.push(this.master[i]);
                            }
                        }
                        for (i = 0; i < farmerRegData.length; i++) {
                            if (farmerRegData[i].item_code == 'sex') {
                                this.genderData.push(farmerRegData[i]);
                            }
                            else if (farmerRegData[i].item_code == 'education_level') {
                                this.educationLevelData.push(farmerRegData[i]);
                            }
                            else if (farmerRegData[i].item_code == 'school_level') {
                                this.schoolLevelData.push(farmerRegData[i]);
                            }
                        }
                        //added by KRPT for adding farmer consent
                        if (requests[0].msg == 'success') {
                            this.farmerConsent = requests[3].data;
                        }
                        for (_i = 0, _a = Object.entries(this.farmerConsent); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], value = _b[1];
                            if (parseInt(key) == farmerId) {
                                this.farmer.consentPending = !value["is_consent_valid"];
                                this.farmer.valid_upto = value["valid_upto"];
                            }
                        }
                        this.getSelectedTabData();
                        this.setVillageAreaList();
                        _c.label = 5;
                    case 5:
                        this.updatedFarmName = this.farmer.name + " Farm 1";
                        this.canDoProfilingFn();
                        //geo-plotting service component check
                        this.canDoGeoplottingFn();
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getEstimatedPotentialValue(this.projectId).toPromise()
                            ])];
                    case 6:
                        request = _c.sent();
                        if (request[0]['msg'] === 'success') {
                            currentFarmer = request[0]['data'].filter(function (item) { return item.farmer_id == farmerId; });
                            if (currentFarmer && currentFarmer.length > 0) {
                                this.potential = currentFarmer[0].answer;
                                this.currentFarmerOriginalPotential = currentFarmer[0].answer;
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    FarmerdetailsComponent.prototype.canDoGeoplottingFn = function () {
        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
        }
        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
        }
        if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
            for (var _i = 0, _a = this.usersServiceComponentMappings; _i < _a.length; _i++) {
                var item = _a[_i];
                if (item.services_components_mapping) {
                    if (item.services_components_mapping.code == "FAR21" && item.user_type_id == this.currentUser.role.id) {
                        this.canDoGeoplotting = true;
                    }
                }
            }
        }
        else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
            for (var _b = 0, _c = this.ecosystemServicesComponentsMapping; _b < _c.length; _b++) {
                var item = _c[_b];
                if (item.services_components_mapping) {
                    if (item.services_components_mapping.code == "FAR21") {
                        this.canDoGeoplotting = true;
                    }
                }
            }
        }
    };
    FarmerdetailsComponent.prototype.getSelectedTabData = function () {
        if (this.selectedTab['tab_key'] == 'farmer_reg') {
            if (!this.farmerRegQuestions || this.farmerRegQuestions.length == 0) {
                this.getFarmRegQuestions();
            }
        }
        else if (this.selectedTab['tab_key'] == 'farms') {
            // function to get farms related data from mongodb
        }
        // other else if conditions
    };
    FarmerdetailsComponent.prototype.getFarmRegQuestions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.myForm = this.formBuilder.group({});
                        if (!navigator.onLine) return [3 /*break*/, 1];
                        this.farmerService.getFarmRegQuestions()
                            .subscribe(function (response) {
                            //console.log('response', response);
                            _this.farmerRegQuestions = response.data;
                            // console.log("this.farmerRegQuestions all ", this.farmerRegQuestions);
                            // for (let i = 0; i < this.farmerRegQuestions.length; i++) {
                            //   this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl('',
                            //     [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));                  
                            // }
                            // Farmer questions sort the order based on the ques_seq
                            var orderedQuestions = _this.farmerRegQuestions.sort(function (a, b) { return Number(a.ques_seq) - Number(b.ques_seq); });
                            _this.farmerRegQuestions = orderedQuestions;
                            _this.setVCMapping();
                            _this.myFormStatus = true;
                            _this.setFarmerForm();
                            _this.showSpinner = false;
                        });
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, Promise.all([
                            this.farmerService.getFarmRegQuestions().toPromise(),
                        ])];
                    case 2:
                        requests = _a.sent();
                        this.farmerRegQuestions = requests[0].data;
                        //console.log("offline this.farmerRegQuestions ", this.farmerRegQuestions);
                        this.setVCMapping();
                        this.setFarmerForm();
                        this.showSpinner = false;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FarmerdetailsComponent.prototype.scrollToTop = function () {
        // $('html, body').animate({
        //   scrollTop: $('#mainDiv').scrollTop = 0
        // }, 'slow');
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    FarmerdetailsComponent.prototype.clickBack = function () {
        this.currentTabIndex = 0;
    };
    FarmerdetailsComponent.prototype.tabClicked = function (tab, index) {
        this.currentTabIndex = index;
        if (index == 2) {
            this.mapDisplay = true;
            this.initializeMap();
        }
        else {
            this.mapDisplay = false;
        }
    };
    FarmerdetailsComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    /* file upload */
    FarmerdetailsComponent.prototype.handleFileInput = function (file, tab) {
        var _this = this;
        // // debugger;
        var fileItem = file.item(0);
        if (fileItem.name == null || fileItem.name == undefined) {
            this.uploadingFileName = '';
        }
        else {
            this.uploadingFileName = fileItem.name;
        }
        this.uploadingFileName = fileItem.name;
        if (this.uploadingFileName) {
            this.uploadingFileNameFlag = true;
        }
        else {
            this.uploadingFileNameFlag = false;
        }
        this.fileUploadMsg = '';
        var msg = this.commonService.ValidateFile(fileItem);
        if (msg && msg.length > 0) {
            // this.commonService.showToast('warning', 'generic_error', {});
            this.commonService.showToast('warning', msg, {});
            this.fileUploadMsg = msg;
            return;
        }
        //console.log(" fileItem  ",fileItem);
        // this.fileToUpload = fileItem;
        //this.farmer['photo_path'] = fileItem.name;
        // const reader = new FileReader();
        // reader.onload = (event: any) => {
        //   // this.imageUrl = event.target.result;
        //   this.farmerImageData = null;
        //   let imageData = event.target.result;
        //   imageData = new Uint8Array( imageData );
        //   imageData = new Blob( [ imageData ], { type: "image/jpeg" } );
        //   let setFarmerImageData = (result) => { 
        //     this.commonService.blobToArrayBuffer(result).then((imageData)=>{
        //       this.farmerImageData = imageData;
        //     });
        //   };
        //   new Compressor(imageData, {
        //     quality: 0.6,
        //     success(result) {
        //       setFarmerImageData(result);
        //     },
        //     error(err) {
        //       console.log(err.message);
        //     },
        //   });
        // };
        // reader.readAsArrayBuffer(this.fileToUpload);
        new Compressor(fileItem, {
            quality: environment.imageCompress.quality,
            maxWidth: environment.imageCompress.maxWidth,
            maxHeight: environment.imageCompress.maxHeight,
            mimeType: environment.imageCompress.mimeType,
            convertSize: environment.imageCompress.convertSize,
            success: function (result) {
                setFarmerImageData(result);
            },
            error: function (err) {
                console.log(err.message);
            },
        });
        var setFarmerImageData = function (result) {
            _this.fileToUpload = result;
            _this.commonService.blobToBase64(result).then(function (imageData) {
                _this.farmerImageData = imageData;
                _this.imageUrl = imageData.toString();
            });
        };
    };
    FarmerdetailsComponent.prototype.submitForm = function (tab, myForm, index) {
        var _this = this;
        //console.log("myForm ",myForm.value);    
        this.f1Submitted = true;
        if (myForm.valid) {
            this.farmerMainObject[tab] = this.farmer;
            //console.log("myForm.valid");
            if (this.farmer && (this.farmer != null || this.farmer != undefined)) {
                // Object.keys(this.farmer).forEach((key1) =>{                  
                Object.keys(myForm.value).forEach(function (key2) {
                    // if(key1 == key2){
                    _this.farmer[key2] = myForm.controls[key2].value;
                    // }
                    // });         
                });
                this.farmer.photo_path = this.farmerPhotoPath ? this.farmerPhotoPath : null;
            }
            if (this.farmer.education_level != null && this.farmer.education_level == '') {
                this.farmer.education_level = null;
            }
            this.setSchoolLevelToNull();
            if (navigator.onLine) {
                //console.log("this.farmerMainObject[tab] online", this.farmerMainObject[tab]);
                //console.log("this.farmerMainObject online",this.farmerMainObject);
                //console.log("this.farmer online",this.farmer);
                if (this.fileToUpload) {
                    //console.log("this.fileToUpload ", this.fileToUpload);
                    var formData = new FormData();
                    formData.append('type', 'farmerAvatar');
                    formData.append('projectId', this.projectId);
                    formData.append('avatar', this.fileToUpload, this.fileToUpload.name);
                    this.farmerService.uploadFarmerImage(formData)
                        .subscribe(function (obj) {
                        if (obj.msg === 'success') {
                            // console.log('obj', obj);
                            _this.farmer['photo_path'] = obj.data;
                            _this.farmerPhotoPath = _this.farmer.photo_path;
                            _this.farmerMainObject[tab] = _this.farmer;
                            _this.farmerService.SaveFarmer(_this.farmerMainObject[tab])
                                .subscribe(function (result) {
                                // console.log('result after updating farmer', result);
                                if (result.msg == 'success') {
                                    _this.f1Submitted = false;
                                    _this.commonService.showToast('success', 'success_farmer_updated', {});
                                    var url = 'farmers/' + _this.projectId;
                                    // this.router.navigate([url]);
                                }
                                else {
                                    _this.commonService.showToast('error', 'error_farmer_updated', {});
                                }
                                _this.myForm.markAsPristine();
                            });
                        }
                    });
                }
                else {
                    this.farmerMainObject[tab] = this.farmer;
                    this.farmerService.SaveFarmer(this.farmerMainObject[tab])
                        .subscribe(function (result) {
                        // console.log('result after updating farmer', result);
                        if (result.msg == 'success') {
                            _this.f1Submitted = false;
                            _this.commonService.showToast('success', 'success_farmer_updated', {});
                            var url = 'farmers/' + _this.projectId;
                            // this.router.navigate([url]);             
                        }
                        else {
                            _this.commonService.showToast('error', 'error_farmer_updated', {});
                        }
                        _this.myForm.markAsPristine();
                    });
                }
            }
            else {
                //offline
                this.farmerMainObject[tab] = this.farmer;
                var finalObj_1 = {};
                var farmerObj_1 = this.farmerMainObject[tab];
                //console.log("this.farmerMainObject ",this.farmerMainObject);
                //console.log("farmerObj ",farmerObj);
                // console.log('farmerObj initially', JSON.stringify(farmerObj));
                Object.keys(farmerObj_1).forEach(function (key) {
                    // console.log('now doing key', key);
                    // console.log('now doing farmerObj[key]', farmerObj[key]);
                    if (typeof farmerObj_1[key] === 'object' && farmerObj_1[key] != null) {
                        if (farmerObj_1[key].length && farmerObj_1[key].length > 0) {
                            // finalObj[key] = {};
                            finalObj_1[key] = farmerObj_1[key];
                        }
                        //  else {
                        //   finalObj[key] = farmerObj[key];
                        // }
                    }
                    else {
                        finalObj_1[key] = farmerObj_1[key];
                    }
                });
                var farmObj = new Array();
                // tslint:disable-next-line:max-line-length
                // finalObj['tmp_id'] = this.farmer[tab].id > 0 ? `${this.farmer[tab].farmer_code}_Tab0` : `FA${Math.random().toString().slice(2, 12)}_Tab0`;
                var index_1 = this.rawFarmerId.indexOf('_');
                var rawFarmerIdEdited = this.rawFarmerId.substr(0, index_1);
                // console.log('rawFarmerIdEdited', rawFarmerIdEdited);
                finalObj_1['tmp_id'] = this.farmer.id > 0 ? this.farmerMainObject.farmer_reg.farmer_code + "_Tab00" : rawFarmerIdEdited + "_Tab00";
                finalObj_1['savetab'] = 'Tab00';
                finalObj_1['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
                finalObj_1['project_id'] = this.projectId;
                finalObj_1['upd_user_id'] = this.currentUser.id;
                finalObj_1['consentPending'] = this.farmer.consentPending;
                finalObj_1['valid_upto'] = this.farmer.valid_upto;
                delete finalObj_1['photo_path'];
                farmObj[farmObj.length] = finalObj_1;
                // finalObj['cr_date'] = Date.now();
                idbApp.addStoreInfo(farmObj, 'farmerstmp');
                // console.log(this.farmerImageData);
                if (this.farmerImageData) {
                    var farmPhotoObj = {};
                    farmPhotoObj['tmp_id'] = this.farmer.id > 0 ? this.farmerMainObject.farmer_reg.farmer_code + "_Tab0" : rawFarmerIdEdited + "_Tab0";
                    farmPhotoObj['randnum'] = finalObj_1['randnum'];
                    farmPhotoObj['farmerImageData'] = this.farmerImageData;
                    farmPhotoObj['savetab'] = 'Tab00';
                    // farmPhotoObj['cr_date'] = Date.now();
                    farmPhotoObj['farmer_id'] = (this.farmer.id > 0) ? this.farmer.id : 0;
                    idbAppPhoto.addStoreInfo([farmPhotoObj], 'farmersPhototmp');
                }
                //console.log("after idbAPP stat ", farmObj);
                // this.commonService.showToast('info', 'offline_sync_later', {});
                // this.saveButtonClicked['farmer_reg'] = false;
                this.showSpinner = false;
                this.myForm.markAsPristine();
                this.farmerImageData = undefined;
                // this.registerSyncEvent();
                // code to update the cache
                this.updateCache();
                //console.log("after  update log");
                this.f1Submitted = false;
                var url = 'farmers/' + this.projectId;
                this.farmerUpdatedOffline = true;
                this.commonService.showToast('info', 'offline_sync_later', {});
            }
        }
        else {
            //console.log("myForm.Invalid");
        }
    };
    FarmerdetailsComponent.prototype.registerSyncEvent = function () {
        serviceWorkerVar.sync.register('oneTimeSync');
        //console.log("serviceWorkerVar register");
        // navigator.serviceWorker.register('/service-worker.js', {
        //   scope: './'
        // })
        //   .then(function (reg) {
        //     // console.log('ServiceWorker registered2', reg);
        //     reg.sync.register('oneTimeSync');
        //   })
        //   .catch(function (error) {
        //     // console.log('Failed to register ServiceWorker', error);
        //   });
    };
    FarmerdetailsComponent.prototype.updateCache = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, farmerId_1, farmer_1, keys;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateFarmerCache();
                        url = environment.apiBaseUrl + "/api/farmer/get/allFarmers";
                        if (!(this.farmer.id > 0)) return [3 /*break*/, 2];
                        farmerId_1 = this.farmer.id;
                        farmer_1 = this.farmer;
                        return [4 /*yield*/, caches.keys()];
                    case 1:
                        keys = _a.sent();
                        keys.forEach(function (key) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var cache, resp, init_1, body, respBody, index, newResp, currentUser, fp, request;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, caches.open(key)];
                                    case 1:
                                        cache = _a.sent();
                                        return [4 /*yield*/, cache.match(url)];
                                    case 2:
                                        resp = _a.sent();
                                        if (!resp) return [3 /*break*/, 4];
                                        init_1 = {
                                            status: resp.status,
                                            statusText: resp.statusText,
                                            headers: {}
                                        };
                                        resp.headers.forEach(function (v, k) {
                                            init_1.headers[k] = v;
                                        });
                                        return [4 /*yield*/, resp.json()];
                                    case 3:
                                        body = _a.sent();
                                        respBody = body;
                                        index = respBody.data.findIndex(function (x) { return x.id == farmerId_1; });
                                        respBody.data[index] = farmer_1;
                                        newResp = new Response(JSON.stringify(respBody), init_1);
                                        currentUser = JSON.parse(localStorage.getItem('currentUser'));
                                        fp = localStorage.getItem('fp');
                                        request = new Request(url);
                                        request.headers.append('Authorization', currentUser.token);
                                        request.headers.append('fp', fp);
                                        cache.put(url, newResp.clone());
                                        _a.label = 4;
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    FarmerdetailsComponent.prototype.updateFarmerCache = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmerId, farmer, url, keys;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        farmerId = this.farmerMainObject.farmer_reg.id;
                        farmer = this.farmerMainObject.farmer_reg;
                        url = environment.apiBaseUrl + "/api/farmer/getall/byproject";
                        return [4 /*yield*/, caches.keys()];
                    case 1:
                        keys = _a.sent();
                        keys.forEach(function (key) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var cache, resp, init_2, body, respBody, index, newRecord, newResp, currentUser, fp, request;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, caches.open(key)];
                                    case 1:
                                        cache = _a.sent();
                                        return [4 /*yield*/, cache.match(url)];
                                    case 2:
                                        resp = _a.sent();
                                        if (!resp) return [3 /*break*/, 4];
                                        init_2 = {
                                            status: resp.status,
                                            statusText: resp.statusText,
                                            headers: {}
                                        };
                                        resp.headers.forEach(function (v, k) {
                                            init_2.headers[k] = v;
                                        });
                                        return [4 /*yield*/, resp.json()];
                                    case 3:
                                        body = _a.sent();
                                        if (body) {
                                            respBody = body;
                                            index = respBody.data.findIndex(function (x) { return x.id == farmerId; });
                                            newRecord = respBody.data[index];
                                            // this will only update name and tel number as it is required on projectinfo page
                                            if (newRecord) {
                                                newRecord.name = farmer.name;
                                                newRecord.tel_number = farmer.tel_number;
                                                newRecord.upd_date = farmer.upd_date;
                                                newRecord.filled_questions = farmer.filled_questions;
                                                newRecord.filled_mandatory = farmer.filled_mandatory;
                                                newRecord.total_questions = farmer.total_questions;
                                                newRecord.mandatory_questions = farmer.mandatory_questions;
                                                respBody.data[index] = newRecord;
                                                newResp = new Response(JSON.stringify(respBody), init_2);
                                                currentUser = JSON.parse(localStorage.getItem('currentUser'));
                                                fp = localStorage.getItem('fp');
                                                request = new Request(url);
                                                request.headers.append('Authorization', currentUser.token);
                                                request.headers.append('fp', fp);
                                                cache.put(request, newResp.clone());
                                            }
                                        }
                                        _a.label = 4;
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    FarmerdetailsComponent.prototype.exitClicked = function (myForm, template) {
        if (myForm.dirty) {
            //console.log("if");
            this.confirmModalPopup = false;
            this.confirmPopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            var url = 'farmers/' + this.projectId;
            this.router.navigate([url]);
        }
    };
    FarmerdetailsComponent.prototype.confirmExitModal = function (item) {
        if (item === 'Yes') {
            //this.cancelQuestionEdit();
            //setTimeout(()=>{
            this.confirmModalPopup = true;
            if (this.confirmPopup)
                this.confirmPopup.hide();
            //},1000);
            if (!navigator.onLine) {
                this.commonService.showToast('info', 'offline_sync_later', {});
            }
            var url = 'farmers/' + this.projectId;
            this.router.navigate([url]);
        }
        else if (item === 'No') {
            this.confirmModalPopup = true;
            if (this.confirmPopup)
                this.confirmPopup.hide();
        }
    };
    FarmerdetailsComponent.prototype.getBackFarmerManagment = function (id) {
        var url = 'farmers/' + this.projectId;
        this.router.navigate([url]);
    };
    FarmerdetailsComponent.prototype.setVillageAreaList = function () {
        if (this.project.ecosystem_region_master) {
            this.villageAreaList = this.project.ecosystem_region_master.region_villages;
        }
    };
    FarmerdetailsComponent.prototype.setVCMapping = function () {
        if (this.farmer) {
            if (this.farmer.vc_farmer_mappings && this.farmer.vc_farmer_mappings.length > 0) {
                var vcNames = '';
                for (var i = 0; i < this.farmer.vc_farmer_mappings.length; i++) {
                    if (this.farmer.vc_farmer_mappings[i].status == 'Active') {
                        vcNames = this.farmer.vc_farmer_mappings[i].users.name + ', ' + vcNames;
                    }
                }
                vcNames = vcNames.substring(0, vcNames.length - 2);
                this.farmer.village_collector = vcNames;
            }
        }
    };
    // Create and set values for the farmer details edit form based on reg questions the farmer object
    FarmerdetailsComponent.prototype.setFarmerForm = function () {
        // console.log('this.farmer', this.farmer);
        for (var i = 0; i < this.farmerRegQuestions.length; i++) {
            if (this.farmer && (this.farmer != null || this.farmer != undefined)) {
                var key = this.farmerRegQuestions[i].code;
                // console.log('key', key);
                if (this.farmerRegQuestions[i].code === 'photo_path') {
                    this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl('', [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));
                }
                else if (this.farmerRegQuestions[i].code === 'village_collector' || this.farmerRegQuestions[i].code === 'official_id_card_no') {
                    if (this.farmer[key]) {
                        this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl({ value: this.farmer[key], disabled: true }, [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));
                    }
                    else {
                        this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl({ value: '', disabled: true }, [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));
                    }
                }
                else {
                    if (this.farmer[key]) {
                        this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl(this.farmer[key], [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));
                    }
                    else {
                        this.myForm.addControl(this.farmerRegQuestions[i].code, new FormControl('', [this.farmerRegQuestions[i].mandatory_yn === 'Y' ? Validators.required : Validators.nullValidator]));
                    }
                }
            }
            if (this.farmerRegQuestions[i].regex_pattern && this.farmerRegQuestions[i].regex_pattern !== '') {
                this.myForm.controls[this.farmerRegQuestions[i].code].setValidators(Validators.pattern(this.farmerRegQuestions[i].regex_pattern));
            }
        }
    };
    FarmerdetailsComponent.prototype.goToFarmerProfiling = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                        ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['message'] === 'notActive' || request[0]['message'] === 'notReleasedForLanguage') {
                            this.commonService.showToast('info', 'there_no_active_snapshot_for_this_project', {});
                            return [2 /*return*/];
                        }
                        if (this.farmer.id && this.farmer.id > 0) {
                            url = '/questionnaire/respond/' + this.farmer.project_id + '/' + this.farmer.id;
                        }
                        else if (this.farmer.tmp_id) {
                            if (this.farmer.tmp_id.indexOf('Tab00') > -1) {
                                url = '/questionnaire/respond/' + this.farmer.project_id + '/' + this.farmer.tmp_id.substring(0, this.farmer.tmp_id.length - 1);
                            }
                            else {
                                url = '/questionnaire/respond/' + this.farmer.project_id + '/' + this.farmer.tmp_id;
                            }
                        }
                        else {
                            url = '/error';
                        }
                        if (!this.campaignId) {
                            this.router.navigate([url]);
                        }
                        else {
                            this.router.navigate([url], { queryParams: { campaignId: this.campaignId } });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    FarmerdetailsComponent.prototype.canDoProfilingFn = function () {
        var _this = this;
        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
        }
        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
        }
        if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
            var canDoProfiling = this.usersServiceComponentMappings.filter(function (item) { return (item.services_id == 3 && item.user_type_id == _this.currentUser.role.id); });
            if (canDoProfiling && canDoProfiling.length > 0) {
                this.canDoProfiling = true;
            }
            var canAddTraining = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "FR_TRA_CER" && item.user_type_id === _this.currentUser.role.id); });
            if (canAddTraining && canAddTraining.length > 0) {
                this.canAddTraining = true;
            }
        }
        else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
            var canDoProfiling = this.ecosystemServicesComponentsMapping.filter(function (item) { return item.services_id == 3; });
            if (canDoProfiling && canDoProfiling.length > 0) {
                this.canDoProfiling = true;
            }
            var canAddTraining = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.code === "FR_TRA_CER"); });
            if (canAddTraining && canAddTraining.length > 0) {
                this.canAddTraining = true;
            }
        }
    };
    FarmerdetailsComponent.prototype.setSchoolLevelToNull = function () {
        if (this.farmer['school_level'] == '') {
            this.farmer['school_level'] = null;
        }
    };
    FarmerdetailsComponent.prototype.canDeactivate = function () {
        if (this.myForm && this.myForm.dirty) {
            this.confirmModal = this.modalService.show(this.confirmTemplate, { backdrop: true, ignoreBackdropClick: true });
            this.spinner.hide();
            return this.subject.asObservable();
        }
        return true;
    };
    FarmerdetailsComponent.prototype.closeModal = function (isOkay) {
        this.confirmModal.hide();
        this.subject.next(isOkay);
    };
    FarmerdetailsComponent.prototype.keyDownFunction = function (event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            // rest of your code
        }
    };
    FarmerdetailsComponent.prototype.typeChanged = function (type) {
        if (type == 'certificate') {
            this.isCertificate = true;
        }
        else {
            this.isCertificate = false;
        }
    };
    //check if farmername contains spaces only
    FarmerdetailsComponent.prototype.checkEmptyness = function () {
        var name = this.myForm['value'].name;
        if (!name.replace(/\s/g, '').length) {
            this.validFarmerName = true;
        }
        else {
            this.validFarmerName = false;
        }
    };
    //Geo-plotting - for getting farm cordinates
    FarmerdetailsComponent.prototype.getGeoLocation = function () {
        var _this = this;
        this.getPosition().then(function (pos) {
            var myLatLng = new google.maps.LatLng(pos.lat, pos.lng);
            var newMapProperties = {
                center: myLatLng,
                zoom: 19,
                mapTypeControl: false,
                streetViewControl: false,
                mapTypeId: google.maps.MapTypeId.HYBRID
            };
            _this.map = new google.maps.Map(_this.mapElement.nativeElement, newMapProperties);
            var marker = new google.maps.Marker({
                position: myLatLng,
                draggable: false,
            });
            // To add the marker to the map, call setMap();
            marker.setMap(_this.map);
            _this.loc_coordinates = pos.lat + "," + pos.lng;
        });
    };
    FarmerdetailsComponent.prototype.getPosition = function () {
        var opts = { enableHighAccuracy: true, maximumAge: 60000, timeout: 5000 };
        return new Promise(function (resolve, reject) {
            navigator.geolocation.watchPosition(function (resp) {
                resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
            }, function (err) {
                reject(err);
            }, opts);
        });
    };
    //initialize google map on click of tab
    FarmerdetailsComponent.prototype.initializeMap = function (saved) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, farmtmpobj, index, rawFarmerIdEdited, farmerCode_1, farmerFarms_2, _loop_1, this_1, _i, farmerFarms_1, farm, farmIndex, farmLatLng, splitLatLng, myLatLng, area;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.farmerService.getFarmCoordinates(this.projectId).toPromise()
                        ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['msg'] === 'success') {
                            this.farms = request[0]['data'].filter(function (item) { return item.farmer_code == _this.farmer.farmer_code; });
                        }
                        if (!!navigator.onLine) return [3 /*break*/, 3];
                        return [4 /*yield*/, idbAppPhoto.getAllProjects('farmCoordinatestmp')];
                    case 2:
                        farmtmpobj = _a.sent();
                        if (farmtmpobj && farmtmpobj.length > 0) {
                            index = this.rawFarmerId.indexOf('_');
                            rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
                            farmerCode_1 = this.farmer.id > 0 ? "" + this.farmer.farmer_code : "" + rawFarmerIdEdited;
                            farmerFarms_2 = [];
                            farmtmpobj.forEach((function (item) {
                                var tmpIds = item.tmp_id.split("_");
                                // console.log("tmpIds:", tmpIds);
                                if (tmpIds[0] == farmerCode_1) {
                                    farmerFarms_2.push(item);
                                }
                            }));
                            // console.log("farmerFarms:", farmerFarms);
                            // farmerFarms = farmtmpobj.filter(item => item.farmData.farmer_code == farmerCode);
                            if (farmerFarms_2 && farmerFarms_2.length > 0) {
                                _loop_1 = function (farm) {
                                    //this.originalFarms.push(farm.farmData);    
                                    //check if deleted 
                                    if (!farm.farmData.deleted) {
                                        farm.farmData['farmname'] = farm.farmData.farm_name;
                                        farm.farmData['tmp_id'] = farm.tmp_id;
                                        farm.farmData['id'] = farm.randnum;
                                        delete farm.farmData.farm_name;
                                        this_1.farms.push(farm.farmData);
                                    }
                                    else {
                                        if (farm.farmData.id) {
                                            this_1.farms = this_1.farms.filter(function (item) { return item.id != farm.farmData.id; });
                                        }
                                    }
                                };
                                this_1 = this;
                                for (_i = 0, farmerFarms_1 = farmerFarms_2; _i < farmerFarms_1.length; _i++) {
                                    farm = farmerFarms_1[_i];
                                    _loop_1(farm);
                                }
                            }
                        }
                        _a.label = 3;
                    case 3:
                        //console.log('this.farms', this.farms);
                        this.originalFarms = this.farms;
                        if (!(this.farms.length > 0)) return [3 /*break*/, 7];
                        farmIndex = saved ? this.farms.length - 1 : 0;
                        farmLatLng = this.farms[farmIndex].plot;
                        if (!(this.farms[farmIndex].area == null)) return [3 /*break*/, 4];
                        splitLatLng = farmLatLng.split(',');
                        myLatLng = new google.maps.LatLng(parseFloat(splitLatLng[0]), parseFloat(splitLatLng[1]));
                        this.setMarkerOnMap(myLatLng);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.createPolygonOnMap(farmLatLng, this.farms[farmIndex])];
                    case 5:
                        area = _a.sent();
                        _a.label = 6;
                    case 6:
                        this.selectedFarm = this.farms[farmIndex];
                        this.updatedFarmName = this.farms[farmIndex].farmname;
                        this.farmTypeSelected = this.farms[farmIndex].farm_type;
                        if (this.farms[farmIndex].fcproj != null) {
                            this.potential = this.farms[farmIndex].fcproj;
                        }
                        if (this.farms[farmIndex].farmimage != null) {
                            this.uploadedFarmImage = this.farms[farmIndex].farmimage;
                        }
                        this.noNewFarm = false;
                        return [3 /*break*/, 8];
                    case 7:
                        this.updatedFarmName = this.farmer.name + " Farm 1";
                        this.potential = this.currentFarmerOriginalPotential;
                        this.farmTypeSelected = 0;
                        this.setAreaMap();
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    FarmerdetailsComponent.prototype.setMarkerOnMap = function (coordinates) {
        var newMapProperties = {
            center: coordinates,
            zoom: 19,
            mapTypeControl: false,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.HYBRID
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, newMapProperties);
        var marker = new google.maps.Marker({
            position: coordinates,
            //title: "Field Location!",
            draggable: false,
        });
        // To add the marker to the map, call setMap();
        marker.setMap(this.map);
    };
    FarmerdetailsComponent.prototype.setAreaMap = function () {
        var _this = this;
        this.getPosition().then(function (pos) {
            var myLatLng = new google.maps.LatLng(pos.lat, pos.lng);
            var newMapProperties = {
                center: myLatLng,
                zoom: 16,
                mapTypeControl: false,
                streetViewControl: false,
                mapTypeId: google.maps.MapTypeId.HYBRID
            };
            _this.map = new google.maps.Map(_this.mapElement.nativeElement, newMapProperties);
            var marker = new google.maps.Marker({
                position: myLatLng,
                draggable: true,
            });
            // To add the marker to the map, call setMap();
            marker.setMap(null);
        });
    };
    FarmerdetailsComponent.prototype.farmSectionClicked = function (findex) {
        this.selectedFarm = this.farms[findex];
        if (this.selectedFarm.id != 0) {
            var farmLatLng = this.selectedFarm.plot;
            if (this.selectedFarm.area == null) {
                var splitLatLng = farmLatLng.split(',');
                var myLatLng = new google.maps.LatLng(parseFloat(splitLatLng[0]), parseFloat(splitLatLng[1]));
                this.setMarkerOnMap(myLatLng);
            }
            else {
                var area = this.createPolygonOnMap(farmLatLng, this.selectedFarm);
            }
        }
        else if (this.selectedFarm.farm_type == 3) {
            this.drawPolygon();
        }
        else if (this.selectedFarm.farm_type == 1) {
            this.getGeoLocation();
        }
        else {
            this.setAreaMap();
        }
        this.updatedFarmName = this.selectedFarm.farmname;
        this.potential = this.selectedFarm.fcproj;
        this.uploadedFarmImage = this.selectedFarm.farmimage;
        this.farmTypeSelected = this.selectedFarm.farm_type;
        if (this.farmTypeSelected == 2 && this.selectedFarm.id == 0) {
            this.walkingOnTheEdge = true;
        }
        else {
            this.walkingOnTheEdge = false;
        }
    };
    FarmerdetailsComponent.prototype.saveCoordinates = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var index, rawFarmerIdEdited, area, obj, formData, tempId, index_2, rawFarmerIdEdited_1, farmerCode_2, farmtmpobj, farmerFarms_3, lastFarm, lastChar, farmObj;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        //this.spinner.show();
                        if (this.farmTypeSelected == 0) {
                            this.toastrService.warning(this.farmTypeNotSelected);
                            return [2 /*return*/];
                        }
                        index = this.rawFarmerId.indexOf('_');
                        rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
                        if (!(this.drawAreaOnMap || this.walkingOnTheEdge)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.createPolygonOnMap(this.loc_coordinates)];
                    case 1:
                        area = _a.sent();
                        _a.label = 2;
                    case 2:
                        obj = {
                            ecosystem_id: this.project.ecosystem_id,
                            project_id: this.projectId,
                            farmer_code: this.farmer.id > 0 ? "" + this.farmer.farmer_code : null,
                            farm_name: this.updatedFarmName,
                            farm_type: this.farmTypeSelected,
                            plot: this.loc_coordinates,
                            fcproj: this.potential && this.potential != "" ? this.potential : null,
                            fcproj_unit: this.potential ? 'kilogram' : null,
                            area: area ? area : null
                        };
                        if (!navigator.onLine) return [3 /*break*/, 3];
                        if (this.farmFileToUpload) {
                            formData = new FormData();
                            formData.append('farmImage', this.farmFileToUpload, this.farmFileToUpload.name);
                            this.farmerService.uploadFarmImage(formData)
                                .subscribe(function (res) {
                                if (res.msg === 'success') {
                                    var farmImage = res.data;
                                    obj['farm_image'] = farmImage;
                                    _this.farmerService.saveFarmCoordinates(obj)
                                        .subscribe(function (result) {
                                        _this.toastrService.success(_this.farmSavedSuccessfully);
                                        _this.spinner.hide();
                                        _this.potential = undefined;
                                        _this.drawAreaOnMap = false;
                                        _this.walkingOnTheEdge = false;
                                        _this.farmFileToUpload = null;
                                        _this.uploadingFarmFileName = '';
                                        _this.farmImageData = undefined;
                                        _this.farmTypeSelected = 0;
                                        _this.initializeMap(true);
                                    });
                                }
                            });
                        }
                        else {
                            this.farmerService.saveFarmCoordinates(obj)
                                .subscribe(function (result) {
                                _this.toastrService.success(_this.farmSavedSuccessfully);
                                _this.spinner.hide();
                                _this.potential = undefined;
                                _this.drawAreaOnMap = false;
                                _this.walkingOnTheEdge = false;
                                _this.farmTypeSelected = 0;
                                _this.initializeMap(true);
                            });
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        //offline save coordinates      
                        obj['user_id'] = this.currentUser.id;
                        obj['farmImage'] = this.farmImageData;
                        tempId = void 0;
                        index_2 = this.rawFarmerId.indexOf('_');
                        rawFarmerIdEdited_1 = this.rawFarmerId.substr(0, index_2);
                        farmerCode_2 = this.farmer.id > 0 ? "" + this.farmer.farmer_code : "" + rawFarmerIdEdited_1;
                        return [4 /*yield*/, idbAppPhoto.getAllProjects('farmCoordinatestmp')];
                    case 4:
                        farmtmpobj = _a.sent();
                        if (farmtmpobj && farmtmpobj.length > 0) {
                            farmerFarms_3 = [];
                            farmtmpobj.forEach((function (item) {
                                var tmpIds = item.tmp_id.split("_");
                                if (tmpIds[0] == farmerCode_2) {
                                    farmerFarms_3.push(tmpIds[1]);
                                }
                            }));
                            if (farmerFarms_3 && farmerFarms_3.length > 0) {
                                lastFarm = farmerFarms_3[farmerFarms_3.length - 1];
                                lastChar = parseInt(lastFarm.substr(lastFarm.length - 1)) + 1;
                                tempId = farmerCode_2 + "_Tab0" + lastChar;
                            }
                            else {
                                tempId = farmerCode_2 + "_Tab01";
                            }
                        }
                        else {
                            tempId = farmerCode_2 + "_Tab01";
                        }
                        farmObj = {};
                        farmObj['tmp_id'] = tempId;
                        //farmObj['tmp_id'] = this.farmer.id > 0 ? `${this.farmer.farmer_code}_Tab0${this.originalFarms.length + 1}` : `${rawFarmerIdEdited}_Tab0${this.originalFarms.length + 1}`;
                        farmObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
                        farmObj['farmData'] = obj;
                        farmObj['savetab'] = 'Tab00';
                        idbAppPhoto.addStoreInfo([farmObj], 'farmCoordinatestmp');
                        this.commonService.showToast('info', 'offline_sync_later', {});
                        this.spinner.hide();
                        this.potential = undefined;
                        this.drawAreaOnMap = false;
                        this.walkingOnTheEdge = false;
                        this.farmFileToUpload = null;
                        this.uploadingFarmFileName = '';
                        this.farmImageData = undefined;
                        this.farmTypeSelected = 0;
                        this.initializeMap(true);
                        _a.label = 5;
                    case 5:
                        this.loc_coordinates = undefined;
                        this.resetFarmCordinates();
                        return [2 /*return*/];
                }
            });
        });
    };
    FarmerdetailsComponent.prototype.updateCoordinates = function (farm) {
        var _this = this;
        this.spinner.show();
        var index = this.rawFarmerId.indexOf('_');
        var rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
        var obj = {
            id: farm.id,
            ecosystem_id: farm.ecosystem_id,
            project_id: this.projectId,
            farmer_code: this.farmer.id > 0 ? "" + this.farmer.farmer_code : "" + rawFarmerIdEdited,
            farm_name: this.updatedFarmName,
            plot: this.loc_coordinates,
            fcproj: this.potential,
            fcproj_unit: this.potential ? 'kilogram' : null,
            farmImage: this.farmImageData
        };
        if (navigator.onLine) {
            this.farmerService.saveFarmCoordinates(obj)
                .subscribe(function (result) {
                _this.potential = undefined;
                _this.initializeMap();
                _this.toastrService.success('Farm updated successfully!');
                var curIndex = _this.farms.indexOf(farm);
                _this.farmSectionClicked(curIndex);
                _this.spinner.hide();
            });
        }
        else {
            //offline save coordinates
            obj['user_id'] = this.currentUser.id;
            var farmObj = {};
            farmObj['tmp_id'] = this.farmer.id > 0 ? this.farmer.farmer_code + "_Tab0" + (this.originalFarms.length + 1) : rawFarmerIdEdited + "_Tab0" + (this.originalFarms.length + 1);
            farmObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
            farmObj['farmData'] = obj;
            farmObj['savetab'] = 'Tab00';
            if (farm.tmp_id) {
                idbAppPhoto.removeDataFromStore('farmCoordinatestmp', farm.tmp_id);
            }
            idbAppPhoto.addStoreInfo([farmObj], 'farmCoordinatestmp');
            this.commonService.showToast('info', 'offline_sync_later', {});
            // obj['id'] = 0;
            // this.farms.push(obj);
            // this.farmSectionClicked(this.farms.length - 1);
            this.spinner.hide();
        }
        this.loc_coordinates = undefined;
    };
    FarmerdetailsComponent.prototype.saveFarmCoordinates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmCoordinates, _i, _a, item, area, index, rawFarmerIdEdited, obj, farmObj;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.spinner.show();
                        farmCoordinates = this.farmRecording.start;
                        for (_i = 0, _a = this.farmRecording.intermediate; _i < _a.length; _i++) {
                            item = _a[_i];
                            farmCoordinates = farmCoordinates + " " + item;
                        }
                        farmCoordinates = farmCoordinates + " " + this.farmRecording.end;
                        return [4 /*yield*/, this.createPolygonOnMap(farmCoordinates)];
                    case 1:
                        area = _b.sent();
                        index = this.rawFarmerId.indexOf('_');
                        rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
                        obj = {
                            ecosystem_id: this.project.ecosystem_id,
                            project_id: this.projectId,
                            farmer_code: this.farmer.id > 0 ? "" + this.farmer.farmer_code : "" + rawFarmerIdEdited,
                            farm_name: this.updatedFarmName,
                            plot: farmCoordinates,
                            area: area,
                            area_unit: 'hectare',
                            fcproj: this.potential,
                            fcproj_unit: this.potential ? 'kilogram' : null,
                            farmImage: this.farmImageData
                        };
                        if (navigator.onLine) {
                            this.farmerService.saveFarmCoordinates(obj)
                                .subscribe(function (result) {
                                _this.toastrService.success(_this.farmSavedSuccessfully);
                                _this.spinner.hide();
                                _this.potential = undefined;
                                _this.initializeMap();
                            });
                        }
                        else {
                            //offline save coordinates
                            obj['user_id'] = this.currentUser.id;
                            farmObj = {};
                            farmObj['tmp_id'] = this.farmer.id > 0 ? this.farmer.farmer_code + "_Tab0" + (this.originalFarms.length + 1) : rawFarmerIdEdited + "_Tab0" + (this.originalFarms.length + 1);
                            farmObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
                            farmObj['farmData'] = obj;
                            farmObj['savetab'] = 'Tab00';
                            // farmPhotoObj['cr_date'] = Date.now();
                            // farmObj['farmer_id'] = (this.farmer.id > 0) ? this.farmer.id : 0;
                            idbAppPhoto.addStoreInfo([farmObj], 'farmCoordinatestmp');
                            this.commonService.showToast('info', 'offline_sync_later', {});
                            // obj['id'] = 0;
                            // this.farms.push(obj);
                            // this.farmSectionClicked(this.farms.length - 1);
                            this.spinner.hide();
                            this.potential = undefined;
                            this.initializeMap();
                        }
                        this.closeRecodingPopup();
                        this.walkingOnTheEdge = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    FarmerdetailsComponent.prototype.deleteFarm = function (farm, template) {
        this.farmToDelete = farm;
        this.farmDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    FarmerdetailsComponent.prototype.confirmFarmDelete = function (decision) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmId, obj, farmObj, index, rawFarmerIdEdited;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (decision == "Yes") {
                    this.spinner.show();
                    farmId = this.farmToDelete.id;
                    if (navigator.onLine) {
                        this.farmerService.deleteFarm(farmId)
                            .subscribe(function (result) {
                            _this.toastrService.success(_this.farmDeletedSuccessfully);
                            _this.uploadedFarmImage = undefined;
                            _this.spinner.hide();
                            _this.initializeMap();
                            _this.farmDeleteReset();
                        });
                    }
                    else {
                        //offline farm delete         
                        // const farmtmpobj = await idbAppPhoto.getAllProjects('farmCoordinatestmp');
                        // console.log('farmtmpobj', farmtmpobj);
                        if (farmId && !this.farmToDelete.user_id) {
                            obj = {
                                id: farmId,
                                ecosystem_id: this.farmToDelete.ecosystem_id,
                                project_id: this.projectId,
                                farmer_code: this.farmToDelete.farmer_code,
                                farm_name: this.farmToDelete.farmname,
                                plot: this.farmToDelete.plot,
                                user_id: this.currentUser.id,
                                deleted: true
                            };
                            farmObj = {};
                            index = this.rawFarmerId.indexOf('_');
                            rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
                            farmObj['tmp_id'] = this.farmer.id > 0 ? this.farmer.farmer_code + "_Tab0" + (this.farms.length + 1) : rawFarmerIdEdited + "_Tab0" + (this.farms.length + 1);
                            farmObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
                            farmObj['farmData'] = obj;
                            farmObj['savetab'] = 'Tab00';
                            idbAppPhoto.addStoreInfo([farmObj], 'farmCoordinatestmp');
                        }
                        console.log('farm.tmp_id', this.farmToDelete.tmp_id);
                        if (this.farmToDelete.tmp_id) {
                            idbAppPhoto.removeDataFromStore('farmCoordinatestmp', this.farmToDelete.tmp_id);
                        }
                        this.commonService.showToast('info', 'offline_sync_later', {});
                        this.initializeMap();
                        this.spinner.hide();
                        this.farmDeleteReset();
                    }
                }
                else {
                    this.farmDeleteReset();
                }
                return [2 /*return*/];
            });
        });
    };
    FarmerdetailsComponent.prototype.farmDeleteReset = function () {
        this.farmDeletePopup.hide();
        this.farmToDelete = null;
    };
    //open popup for farm recordings
    FarmerdetailsComponent.prototype.getFarmCoordinates = function () {
        this.walkingOnTheEdge = true;
        this.setAreaMap();
        //this.addRecordingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    FarmerdetailsComponent.prototype.closeRecodingPopup = function () {
        if (this.addRecordingModal) {
            this.addRecordingModal.hide();
            this.resetFarmCordinates();
        }
    };
    FarmerdetailsComponent.prototype.addFarm = function (e) {
        var obj = {
            id: 0,
            ecosystem_id: this.project.ecosystem_id,
            farmer_code: this.farmer.farmer_code,
            farmname: this.farmer.name + " Farm " + (this.originalFarms.length + 1),
            plot: '',
            fcproj: this.farms[0].fcproj,
            farm_type: 0
        };
        this.farms.push(obj);
        this.updatedFarmName = this.farmer.name + " Farm " + this.originalFarms.length;
        this.farmSectionClicked(this.farms.length - 1);
        this.noNewFarm = true;
        this.setAreaMap();
    };
    //geo plotting type dropdown selection
    FarmerdetailsComponent.prototype.plotTypeSelection = function (e, farm) {
        this.farmTypeSelected = e.target.value;
        if (farm) {
            var index = this.farms.indexOf(farm);
            if (e.target.value != 0) {
                this.farms[index].farm_type = e.target.value;
            }
        }
        this.drawAreaOnMap = false;
        this.walkingOnTheEdge = false;
        this.resetFarmCordinates();
        this.loc_coordinates = undefined;
        if (e.target.value == 1) {
            this.getGeoLocation();
        }
        else if (e.target.value == 2) {
            this.getFarmCoordinates();
        }
        else if (e.target.value == 3) {
            this.drawPolygon();
        }
        else {
            this.loc_coordinates = undefined;
            this.setAreaMap();
        }
    };
    /*farm file upload */
    FarmerdetailsComponent.prototype.handleFarmFileInput = function (file) {
        var _this = this;
        // // debugger;
        var fileItem = file.item(0);
        if (fileItem.name == null || fileItem.name == undefined) {
            this.uploadingFarmFileName = '';
        }
        else {
            this.uploadingFarmFileName = fileItem.name;
        }
        this.uploadingFarmFileName = fileItem.name;
        // if(this.uploadingFarmFileName){
        //   this.uploadingFileNameFlag= true;
        // }else{
        //   this.uploadingFileNameFlag= false;
        // }
        //this.fileUploadMsg = '';
        var msg = this.commonService.ValidateFile(fileItem);
        if (msg && msg.length > 0) {
            this.commonService.showToast('warning', msg, {});
            //this.fileUploadMsg = msg;
            return;
        }
        new Compressor(fileItem, {
            quality: environment.imageCompress.quality,
            maxWidth: environment.imageCompress.maxWidth,
            maxHeight: environment.imageCompress.maxHeight,
            mimeType: environment.imageCompress.mimeType,
            convertSize: environment.imageCompress.convertSize,
            success: function (result) {
                setFarmImageData(result);
            },
            error: function (err) {
                console.log(err.message);
            },
        });
        var setFarmImageData = function (result) {
            _this.farmFileToUpload = result;
            _this.commonService.blobToBase64(result).then(function (imageData) {
                _this.farmImageData = imageData;
            });
        };
    };
    // //for agm
    // changeTilt(map) {
    // }  
    FarmerdetailsComponent.prototype.resetFarmCordinates = function () {
        this.loc_coordinates = undefined;
        this.farmRecording = {
            start: '',
            intermediate: [],
            end: ''
        };
    };
    //record farm coordinates
    FarmerdetailsComponent.prototype.recordFarmCoordinates = function (type) {
        var _this = this;
        this.getPosition().then(function (pos) {
            if (type == 'start') {
                _this.farmRecording.start = pos.lat + "," + pos.lng;
            }
            if (type == 'intermediate') {
                _this.farmRecording.intermediate.push(pos.lat + "," + pos.lng);
            }
            if (type == 'end') {
                _this.farmRecording.end = pos.lat + "," + pos.lng;
                var farmCoordinates = _this.farmRecording.start;
                for (var _i = 0, _a = _this.farmRecording.intermediate; _i < _a.length; _i++) {
                    var item = _a[_i];
                    farmCoordinates = farmCoordinates + " " + item;
                }
                farmCoordinates = farmCoordinates + " " + _this.farmRecording.end;
                _this.loc_coordinates = farmCoordinates;
                var area = _this.createPolygonOnMap(farmCoordinates);
            }
        });
    };
    FarmerdetailsComponent.prototype.createPolygonOnMap = function (farmCoordinates, farm) {
        var coordinates = farmCoordinates.split(' ');
        var polyCoordinates = [];
        for (var _i = 0, coordinates_1 = coordinates; _i < coordinates_1.length; _i++) {
            var item = coordinates_1[_i];
            var i = item.split(',');
            var obj = {
                lat: parseFloat(i[0]), lng: parseFloat(i[1])
            };
            polyCoordinates.push(obj);
        }
        var myLatLng = new google.maps.LatLng(polyCoordinates[0].lat, polyCoordinates[0].lng);
        var newMapProperties = {
            center: myLatLng,
            zoom: 18,
            mapTypeControl: false,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.HYBRID
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, newMapProperties);
        // Construct the polygon.
        var bermudaTriangle = new google.maps.Polygon({
            paths: polyCoordinates,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            editable: true
        });
        this.attachPolygonInfoWindow(bermudaTriangle, farm);
        bermudaTriangle.setMap(this.map);
        var area = google.maps.geometry.spherical.computeArea(bermudaTriangle.getPath());
        var areaText = "Area : " + (area / 10000).toFixed(2) + " Hectare";
        if (!farm) {
            window.alert(areaText);
        }
        return ((area / 10000).toFixed(2));
    };
    FarmerdetailsComponent.prototype.attachPolygonInfoWindow = function (polygon, farm) {
        var infoWindow = new google.maps.InfoWindow();
        var contentString;
        if (farm) {
            contentString = '<div">' + farm.farmname +
                '</div>' +
                '<div>' + 'Area: ' + farm.area + ' Hectare' + '</div>';
        }
        google.maps.event.addListener(polygon, 'mouseover', function (e) {
            infoWindow.setContent(contentString);
            var latLng = e.latLng;
            infoWindow.setPosition(latLng);
            infoWindow.open(this.map);
        });
        infoWindow.open(this.map);
    };
    //draw polygon
    FarmerdetailsComponent.prototype.drawPolygon = function () {
        var _this = this;
        var self = this;
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['polygon']
            }
        });
        this.getPosition().then(function (pos) {
            var myLatLng = new google.maps.LatLng(pos.lat, pos.lng);
            var newMapProperties = {
                center: myLatLng,
                zoom: 19,
                mapTypeControl: false,
                streetViewControl: false,
                mapTypeId: google.maps.MapTypeId.HYBRID
            };
            _this.map = new google.maps.Map(_this.mapElement.nativeElement, newMapProperties);
            _this.drawingManager.setMap(_this.map);
        });
        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', function (event) {
            // Polygon drawn
            if (event.type === google.maps.drawing.OverlayType.POLYGON) {
                //this is the coordinate, you can assign it to a variable or pass into another function.
                //console.log(`polygon cordinates : ${event.overlay.getPath().getArray()}`);
                var paths = event.overlay.getPaths();
                for (var p = 0; p < paths.getLength(); p++) {
                    google.maps.event.addListener(paths.getAt(p), 'set_at', function () {
                        if (!event.overlay.drag) {
                            self.updatePointList(event.overlay.getPath());
                        }
                    });
                    google.maps.event.addListener(paths.getAt(p), 'insert_at', function () {
                        self.updatePointList(event.overlay.getPath());
                    });
                    google.maps.event.addListener(paths.getAt(p), 'remove_at', function () {
                        self.updatePointList(event.overlay.getPath());
                    });
                }
                self.updatePointList(event.overlay.getPath());
            }
            if (event.type !== google.maps.drawing.OverlayType.MARKER) {
                // Switch back to non-drawing mode after drawing a shape.
                self.drawingManager.setDrawingMode(null);
                // To hide:
                self.drawingManager.setOptions({
                    drawingControl: false,
                });
            }
        });
    };
    FarmerdetailsComponent.prototype.updatePointList = function (path) {
        //this.pointList = [];
        var len = path.getLength();
        var pathCordinates;
        for (var i = 0; i < len; i++) {
            // this.pointList.push(
            //   path.getAt(i).toJSON()
            // );
            if (i == 0) {
                pathCordinates = path.getAt(i).toJSON().lat + "," + path.getAt(i).toJSON().lng;
            }
            else {
                pathCordinates = pathCordinates + " " + path.getAt(i).toJSON().lat + "," + path.getAt(i).toJSON().lng;
            }
        }
        this.loc_coordinates = pathCordinates;
        this.drawAreaOnMap = true;
    };
    FarmerdetailsComponent.prototype.updateForecast = function (farm) {
        var _this = this;
        if (this.potential && farm.fcproj != this.potential) {
            var obj = {
                id: farm.id,
                ecosystem_id: this.project.ecosystem_id,
                project_id: this.projectId,
                farmer_code: farm.farmer_code,
                farm_name: farm.farmname,
                farm_type: farm.farm_type,
                plot: farm.plot,
                fcproj: this.potential,
                fcproj_unit: this.potential ? 'kilogram' : null,
                area: farm.area
            };
            if (navigator.onLine) {
                this.spinner.show();
                this.farmerService.saveFarmCoordinates(obj)
                    .subscribe(function (result) {
                    _this.toastrService.success(_this.annualForecastUpdatedSuccessfully);
                    _this.spinner.hide();
                    farm.fcproj = _this.potential;
                    // this.drawAreaOnMap = false;
                    // this.walkingOnTheEdge = false;
                    // this.farmTypeSelected = 0;
                    // this.initializeMap();
                });
            }
            else {
                //offline save coordinates      
                obj['user_id'] = this.currentUser.id;
                //obj['farmImage'] = this.farmImageData;
                var farmObj = {};
                farmObj['tmp_id'] = farm.farmer_code;
                farmObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
                farmObj['farmData'] = obj;
                farmObj['savetab'] = 'Tab00';
                // farmObj['farmer_id'] = (this.farmer.id > 0) ? this.farmer.id : 0;
                idbAppPhoto.addStoreInfo([farmObj], 'farmCoordinatestmp');
                this.commonService.showToast('info', 'offline_sync_later', {});
                farm.fcproj = this.potential;
            }
        }
    };
    return FarmerdetailsComponent;
}());
export { FarmerdetailsComponent };
