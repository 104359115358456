<app-header></app-header>
<div class="project-header clearfix">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page"><span i18n="@@erootsEcosystems">P2F-Roots Ecosystems</span></li>
    </ol>
  </nav>



  <div class="form-group form-group-sm search text-right print-projectinfo" *ngIf="ecosystemsCount && ecosystemsCount > 1">
    <button (click)="viewAllEcosystems(); $event.stopPropagation()"  *ngIf="currentUser?.role?.id == 2" type="button" i18n-title="@@viewAllEcosystems" title="View All Ecosystems" class="print-btn view-btn">
      <span i18n="@@viewAllEcosystems">View All Ecosystems</span>
    </button>
  </div>

</div>
<div class="content-wrap content-wrapper background">
  <highcharts-chart
	[Highcharts]="Highcharts"
	[constructorType]="'mapChart'"
	[options]="chartMap"  class="ecosystem-location">
</highcharts-chart>

</div>
