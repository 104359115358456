import { Directive, Input, Renderer2, ElementRef, AfterContentInit, Injector } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, NgModel } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngModel][customMin]',
  providers: [{provide: NG_VALIDATORS, useExisting: CustomMinDirective, multi: true}]
})
export class CustomMinDirective implements Validator, AfterContentInit {
  @Input()
  customMin: number;

  constructor(private readonly renderer: Renderer2,   private readonly elementRef: ElementRef, private readonly _injector: Injector) {  }

  ngAfterContentInit() {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'min', this.customMin.toString());
  }

  validate(c: FormControl): {[key: string]: any} {
    const that = this;
      const v = +c.value;
      if (v &&  v < this.customMin) {
        this.renderer.addClass(this.elementRef.nativeElement, 'ng-invalid');
        return {'customMin': true};
      } else {
        this.renderer.addClass(this.elementRef.nativeElement, 'ng-valid');
        if (c.value==='') { 
          that._injector.get(NgModel).update.emit(null); 
        }
        return null;
      }
  }
}

