<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToDashboards()" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)"><span i18n-title="@@projectReporting" title="Project Reporting">...</span></li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToDashboards()"><span title="Dashboards">...</span></li>
      <li class="breadcrumb-item active" aria-current="page"><span>Farms Profile</span></li>
    </ol>
  </nav>
</div>

<div class="content-wrap content-wrapper background margin-btm-20">
  <div class="form-design form-box">
    <div class="row">
      <div class="col-md-12 col-lg-9">
        <label class="group-title inline-block mrbtm-two">
          <span>Farms Profile</span>
        </label>
      </div>
    </div>
    <div class="form-design form-box form-box-gray" >
      <div class="">
        <div class="row mt-3">
          <div class="col-md-12 col-lg-3">
            <div class="white-box">
              <div class="row flex-center pt-2 no-gutters">
                <div class="col-md-12 col-lg-5 text-center">
                  <img src="assets/online_images/vanilla.webp" alt="Vanilla" style="height:50px" />
                </div>
                <ng-container  *ngFor="let report of predefinedReports" >
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 103">
                    <span class="gray-30" *ngIf="report?.series[0]?.total_vanilla_area != null">{{report?.series[0]?.total_vanilla_area}}</span>
                    <span class="gray-30" *ngIf="report?.series[0]?.total_vanilla_area == null">0</span>
                    <span class="font-20" *ngIf="report?.series[0]?.Area_uom != null">{{report?.series[0]?.Area_uom == 'Hectare' ? ' ha' : ''}}</span>
                  </div>
                </ng-container>
                
              </div>
              <div class="mt-3 mb-2 text-center font-20 height-48 flex-center">Average Area of Vanilla Cultivation per Plot</div>
            </div>
          </div>
          <div class="col-md-12 col-lg-3">
            <div class="white-box">
              <div class="row flex-center pt-2 no-gutters">
                <div class="col-md-12 col-lg-5 text-center">
                  <img src="assets/online_images/cultivation.webp" alt="cultivation" style="height:50px"/>
                </div>
                <ng-container  *ngFor="let report of predefinedReports" >
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 104">
                    <span class="gray-30" *ngIf="report?.series[0]?.total_vanilla_area != null">{{report?.series[0]?.total_vanilla_area}}</span>
                    <span class="gray-30" *ngIf="report?.series[0]?.total_vanilla_area == null">0</span>
                    <!-- <span class="font-20" *ngIf="report?.series[0]?.Area_uom != null">{{report?.series[0]?.Area_uom == 'Hectare' ? ' ha' : ''}}</span> -->
                  </div>
                </ng-container>
              </div>
              <div class="mt-3 mb-2 text-center font-20 height-48 flex-center">Average Area of Vanilla Cultivation per Farmer</div>
            </div>
          </div>
          <div class="col-md-12 col-lg-3">
            <div class="white-box">
              <div class="row flex-center pt-2 no-gutters">
                <div class="col-md-12 col-lg-5 text-center">
                  <img src="assets/online_images/available-land.webp" alt="Available Land" style="height:50px" />
                </div>
                <ng-container  *ngFor="let report of predefinedReports" >
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 106">
                    <span class="gray-30" *ngIf="report?.series[0]?.tot_available_area != null">{{report?.series[0]?.tot_available_area}}</span>
                    <span class="gray-30" *ngIf="report?.series[0]?.tot_available_area == null">0</span>
                    <span class="font-20" *ngIf="report?.series[0]?.Area_unit != null">{{report?.series[0]?.Area_unit == 'Hectare' ? ' ha' : ''}}</span>
                  </div>
                </ng-container>
              </div>
              <div class="mt-3 mb-2 text-center font-20 height-48">
                <span>Area of Available Land<br/></span>
                <ng-container  *ngFor="let report of predefinedReports" >
                  <span *ngIf="report.reportId == 106">({{report?.series[0]?.Total_farmer != null ? report?.series[0]?.Total_farmer : 0}} Farmers)</span>                
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-3">
            <div class="white-box">
              <div class="row flex-center pt-2 no-gutters">
                <div class="col-md-12 col-lg-5 text-center">
                  <img src="assets/online_images/vine4.webp" alt="vine" style="height:50px" />
                </div>
                <ng-container  *ngFor="let report of predefinedReports" >
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 110">
                    <span class="gray-30" *ngIf="report?.series[0]?.number_of_vines != null">{{predefinedReports[7]?.series[0]?.number_of_vines | number:'1.0':'en-US'}}</span>
                    <span class="gray-30" *ngIf="report?.series[0]?.number_of_vines == null">0</span>                  
                  </div>
                </ng-container>
              </div>
              <div class="mt-3 mb-2 text-center font-20 height-48 flex-center" style="justify-content:center">Number of Vines</div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12 col-lg-3">
            <div class="white-box">
              <div class="row flex-center pt-2 no-gutters">
                <div class="col-md-12 col-lg-5 text-center">
                  <img src="assets/online_images/forest.webp" alt="Reforestated Land" style="height:50px" />
                </div>
                <ng-container *ngFor="let report of predefinedReports" >
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 108">
                    <span class="gray-30" *ngIf="report?.series[0]?.reforested_area != null">{{report?.series[0]?.reforested_area}}</span>
                    <span class="gray-30" *ngIf="report?.series[0]?.reforested_area == null">0</span>            
                    <span class="font-20" *ngIf="report?.series[0]?.Area_uom != null">{{report?.series[0]?.Area_uom == 'Hectare' ? ' ha' : ''}}</span>
                  </div>
                </ng-container>
              </div>
              <div class="mt-3 mb-2 text-center font-20 height-48 flex-center">Total Area of Reforested Land</div>
            </div>           
            <div class="white-box mt-4">
              <div class="row flex-center pt-2 no-gutters">
                <div class="col-md-12 col-lg-5 text-center">
                  <img src="assets/online_images/sustainable-farming.svg" alt="Asustainable farming" style="height:50px" />
                </div>
                <ng-container *ngFor="let report of predefinedReports" >
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 107 && report?.series.length > 0">
                      <span class="gray-30" *ngIf="report?.series[0].key == 'Yes' && report?.series[0].value != null">{{report?.series[0].value | number:'1.0':'en-US'}}</span>
                      <span class="gray-30" *ngIf="report?.series[0].key == 'Yes' && report?.series[0].value == null">0</span>
                      <span class="gray-30" *ngIf="report?.series[0].key != 'Yes'">0</span>
                  </div>
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 107 && report?.series.length == 0">
                      <span class="gray-30">0</span>
                  </div>
                </ng-container>
              </div>
              <div class="mt-3 mb-2 text-center font-20 height-48 flex-center">Farmers Practising Afforestation</div>
            </div> 
            <div class="white-box mt-4">
              <div class="row flex-center pt-2 no-gutters">
                <div class="col-md-12 col-lg-5 text-center">
                  <img src="assets/online_images/afforestation.webp" alt="afforestation" style="height:50px" />
                </div>
                <ng-container *ngFor="let report of predefinedReports" >
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 109 && report?.series.length > 0">
                    <span class="gray-30" *ngIf="report?.series[0].key == 'Yes' && report?.series[0].value != null">{{report?.series[0].value | number:'1.0':'en-US'}}</span>
                    <span class="gray-30" *ngIf="report?.series[0].key == 'Yes' && report?.series[0].value == null">0</span>
                    <span class="gray-30" *ngIf="report?.series[0].key != 'Yes'">0</span>
                  </div>
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 109 && report?.series.length == 0">
                      <span class="gray-30">0</span>
                  </div>
                </ng-container>
              </div>
              <div class="mt-3 mb-2 text-center font-20 height-48 flex-center">Farmers Part of an Afforestation Programme</div>
            </div> 
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="white-box height100">
              <!-- <div class="font-20 text-center pb-3 pt-2">Others Crops Cultivated by Farmer</div> -->
              <div #charts id="" class="highcharts-no-border"></div>
            </div>
          </div>
          <div class="col-md-12 col-lg-3">
            <div class="white-box">
              <div class="row flex-center pt-2 no-gutters">
                <div class="col-md-12 col-lg-5 text-center">
                  <img src="assets/online_images/vine2.webp" alt="Vine" style="height:50px" />
                </div>
                <ng-container *ngFor="let report of predefinedReports" >
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 111 && report?.series.length > 0">
                    <span class="gray-30" *ngIf="report?.series[0]?.ratio_of_vines != null">{{report?.series[0]?.ratio_of_vines | number:'1.0':'en-US'}}</span>
                    <span class="gray-30" *ngIf="report?.series[0]?.ratio_of_vines == null">0</span>
                  </div>
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 111 && report?.series.length == 0">
                      <span class="gray-30">0</span>
                  </div>
                </ng-container>
              </div>
              <div class="mt-3 mb-2 text-center font-20 height-48 flex-center">Ratio of Vines per Hectare</div>
            </div> 
            <div class="white-box mt-4">
              <div class="row flex-center pt-2 no-gutters">
                <div class="col-md-12 col-lg-5 text-center">
                  <img src="assets/online_images/vine3.webp" alt="vine3" style="height:50px" />
                </div>
                <ng-container *ngFor="let report of predefinedReports" >
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 112 && report?.series.length > 0">
                    <span class="gray-30" *ngIf="report?.series[0]?.Avergage_age_of_vines_in_years != null">{{report?.series[0]?.Avergage_age_of_vines_in_years}}</span>
                    <span class="gray-30" *ngIf="report?.series[0]?.Avergage_age_of_vines_in_years == null">0</span>          
                    <span class="font-20" *ngIf="report?.series[0]?.Avergage_age_of_vines_in_years != null"> Years</span>
                  </div>
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 112 && report?.series.length == 0">
                      <span class="gray-30">0</span>
                  </div>
                </ng-container>
              </div>
              <div class="mt-3 mb-2 text-center font-20 height-48 flex-center" style="justify-content:center">Average Age of Vines</div>
            </div> 
            <div class="white-box mt-4">
              <div class="row flex-center pt-2 no-gutters">
                <div class="col-md-12 col-lg-5 text-center">
                  <img src="assets/online_images/vine-production.webp" alt="vine production" style="height:50px" />
                </div>
                <ng-container *ngFor="let report of predefinedReports" >
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 114 && report?.series.length > 0">
                      <span class="gray-30" *ngIf="report?.series[0]?.Percent_vines != null">{{report?.series[0]?.Percent_vines}}</span>
                      <span class="gray-30" *ngIf="report?.series[0]?.Percent_vines == null">0</span>          
                      <span class="font-20" *ngIf="report?.series[0]?.Percent_vines != null"> %</span>
                  </div>
                  <div class="col-md-12 col-lg-7 text-center" *ngIf="report.reportId == 114 && report?.series.length == 0">
                      <span class="gray-30">0</span>
                  </div>
                </ng-container>
              </div>
              <div class="mt-3 mb-2 text-center font-20 height-48 flex-center">Percentage of Vines in Production</div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>