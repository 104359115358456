import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, Inject } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { map, mapTo } from 'rxjs/operators';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';


import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { SurveyService } from '../../services/survey.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';

declare var idbApp: any;

declare var $: any;

declare var serviceWorkerVar;

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-projectinfo',
  templateUrl: './projectinfo.component.html',
  host: { '(window:partial-synced)': 'syncDone($event)' },
  
})
export class ProjectinfoComponent implements OnInit, AfterViewInit, OnDestroy {

  isOnline: Observable<boolean>;
  projectId = 0;
  project: any;
  farmers = [];
  productType = [];
  managers = [];
  farmerCount = 0;

  isAscending = true;
  isAscendingId = true;
  isAscendingName = true;
  isAscendingTel = true;
  isAscendingDate = true;
  sortLabel = '';

  public showSpinner = false;

  isFieldAgent = false;
  isManager = false;
  isDeeproots = false;

  farmerFilterString: any = { name: '' };

  page = 1;
  itemsPerPage = 10;

  currentUser = this.userService.getCurrentUser();
  allSurveys = [];
  snapshotSurveys = [];
  campaignSurveys = [];
  public previousPaginationLabel;
  public nextPaginationLabel;
  public isInIDBInThisProject = [];
  public showSyncSuccessAlert = false;
  public closeSnapshotModal: BsModalRef;
  public modifySnapshotModal: BsModalRef;

  public allSnapshots;
  public snapshot;
  public olderSnapshots = [];

  public snapshotNameTemp;
  public bsDateRange;
  public snapshotModifying;
  public oldSnapshotInfo;

  public minDate = new Date();
  public projectUndefined;

  public getSnapshotInterval;
  public noSnapshotsInProject = false;
  public fetchingSnapshotInfo = true;

  public disableCreateSnapshot = false;
  public disableCloseSnapshot = false;

  public registerFarmerModal;
  public registeringFarmerName;
  public registeringFarmerAge;
  public registeringFarmerSex;
  public registeringFarmerArea;
  public registeringFarmer = false;

  public master;
  public sex;
  public registerFarmerForm: FormGroup;
  public registerAnotherFarmer = false;
  fileToUpload: File = null;
  photoModel = '';
  projectAdmin = '';

  oldSnapshotId = 0;
  arrSnapshotRevisions = [];
  snapshotRevision = [];
  latestRevision: any;
  public windowScrolled: boolean;

  isQuestionnare = false;
  revisionTxt;
  createSuccessTxt;
  locale = 'en';
  constructor(
    private readonly route: ActivatedRoute,
    private readonly commonService: CommonService,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly farmerService: FarmerService,
    private readonly projectService: ProjectService,
    private readonly translate: TranslateService,
    public toastrService: ToastrService,
    public modalService: BsModalService,
    private readonly localeService: BsLocaleService,
    private readonly formBuilder: FormBuilder,
    private readonly revisionService: RevisionService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly userService: UserService,
    private surveyService: SurveyService,
    private readonly loc: Location,
  ) {

    this.isOnline = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });

    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });

    this.translate.get('revision').subscribe(result => {
      this.revisionTxt = result;
    });
    this.translate.get('created_successfully').subscribe(result => {
      this.createSuccessTxt = result;
    });
  }

  snapshotChanged() {
    
    this.latestRevision = this.arrSnapshotRevisions.filter(x => x.snapshot_id_ref === this.oldSnapshotId).slice(-1).pop();
 
  }
  scrollToTop() {
    
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  async ngOnInit() {
    this.scrollToTop();
    
    this.projectId = +this.route.snapshot.params['id'];

    const code = this.commonService.checkRole();

    if (code === 'FAG') {
      this.isFieldAgent = true;
    }
    else if (code === 'PMG') {
      this.isManager = true;
    }
    else if (code === 'SYS1') {
      this.isDeeproots = true;
    }

    this.locale = this.loc.prepareExternalUrl('').replace(/\//g,'');
    
    if (navigator.onLine) {
      this.project = this.route.snapshot.data.project.project;
      this.farmerCount = this.project.farmerCount;
      this.setSurveys();
      // this.farmers = this.route.snapshot.data.project.farmers.data;
      // const request2 = await Promise.all([
      //   this.revisionService.GetSnapshotRevision(this.project['id']).toPromise()
      // ]);

      // this.arrSnapshotRevisions = request2[0].data;
      

      if (this.project.ProjectMapping) {
        const objProjectMapping: any[] = this.project.ProjectMapping; 
        this.projectAdmin = objProjectMapping.filter(x => x.user_type_id === 3).map(y => y.users.name).join();
      }

      if(this.project.Product){
        if(this.project.Product.product_type){
          if(this.locale == 'hi'){
            this.project.Product.product_type.name = this.project.Product.product_type.name_hi;
          } else if(this.locale == 'es'){
            this.project.Product.product_type.name = this.project.Product.product_type.name_es;
          } else if(this.locale == 'fr'){
            this.project.Product.product_type.name = this.project.Product.product_type.name_fr;
          } else if(this.locale == 'mg'){
            this.project.Product.product_type.name = this.project.Product.product_type.name_mg;
          } else if(this.locale == 'ta'){
            this.project.Product.product_type.name = this.project.Product.product_type.name_ta;
          } else if(this.locale == 'zh'){
            this.project.Product.product_type.name = this.project.Product.product_type.name_zh;
          } else if(this.locale == 'id'){
            this.project.Product.product_type.name = this.project.Product.product_type.name_id;
          }         
        }
      }

      // Get any farmers saved in indexed db for this project
      var farmertmpobj = (idbApp.getAllProjects('farmerstmp')).then(function (obj) {
        return obj;
      });
      farmertmpobj.then((farmertmpobj) => {
        const idbAppFarmers = farmertmpobj.find(x => x.project_id === this.projectId && x.savetab === 'Tab0');
        if (idbAppFarmers && idbAppFarmers.length > 0) {
          idbAppFarmers.sort(function (a, b) {
            const aDate = new Date(a.cr_date);
            const bDate = new Date(b.cr_date);
            return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
          })
          // this.farmers = idbAppFarmers.concat(this.farmers);
          this.farmerCount = this.farmerCount + idbAppFarmers.length;
        }
      });

      const request3 = await Promise.all([
        this.farmerService.GetFarmerMaster().toPromise()
      ]);

      this.master = request3[0].data;

      // this.allSnapshots = this.project.QuesProjectSnapshotMappings;
      // this.setSnapshots();
     
    } else {
      this.project = this.route.snapshot.data.project.project;
      
      const requests = await Promise.all([
        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
        this.farmerService.GetFarmerMaster().toPromise()
      ]);
      this.setSurveys();
      this.farmerCount = this.project.farmerCount;


      // const request2 = await Promise.all([
      //   this.revisionService.GetSnapshotRevision(this.project['id']).toPromise()
      // ]);

      // this.arrSnapshotRevisions = request2[0].data;

      if (this.project.ProjectMapping) {
        const objProjectMapping: any[] = this.project.ProjectMapping;
        this.projectAdmin = objProjectMapping.filter(x => x.user_type_id === 3).map(y => y.users.name).join();
      }



      // Get any farmers saved in indexed db for this project
      var farmertmpobj = (idbApp.getAllProjects('farmerstmp')).then(function (obj) {
        return obj;
      });
      farmertmpobj.then((farmertmpobj) => {
        const idbAppFarmers = farmertmpobj.filter(x => x.project_id === this.projectId && x.savetab === 'Tab0');

        if (idbAppFarmers && idbAppFarmers.length > 0) {
          idbAppFarmers.sort(function (a, b) {
            const aDate = new Date(a.cr_date);
            const bDate = new Date(b.cr_date);
            return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
          })
          // this.farmers = idbAppFarmers.concat(this.farmers);
          this.farmerCount = this.farmerCount + idbAppFarmers.length;
        }
      });

      this.master = requests[1].data;

      // const snapshotData = (await this.projectService.getAllSnapshots().toPromise()).data;

      // const projectSnapshots = snapshotData.filter(x => x.project === this.projectId);

      // if (projectSnapshots && projectSnapshots.length > 0) {
      //   this.allSnapshots = projectSnapshots[0].snapshots;
      // } else {
      //   this.allSnapshots = [];
      // }
      // this.setSnapshots();
    }

    this.sex = this.master.filter(x => x.item_tab === 'farmer_reg' && x.item_code === 'sex');

    const mappings = this.project.ecosystem_services_components_mapping ? this.project.ecosystem_services_components_mapping.map(x => x.services) : this.project.supplier_project_services_components_mapping.map(x => x.services);

    this.project.displayedServices = mappings.reduce((unique, o) => {
      if (!unique.some(obj => obj.code === o.code)) {
        unique.push(o);
      }
      return unique;
    }, []);

    if (this.currentUser.role.id > 3) {
      // For all other types of users, check whether they have been given access to that component during the project setup
      if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
        const canCreateSnapshot = this.project.supplier_project_services_components_mapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id === 6 && item.user_type_id == this.currentUser.role.id));
        if (canCreateSnapshot && canCreateSnapshot.length > 0) {
          this.isQuestionnare = true;
        }
      }
    }
    else {
      if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
        const canCreateSnapshot = this.project.ecosystem_services_components_mapping.filter(item => (item.components_id == 6));
        if (canCreateSnapshot && canCreateSnapshot.length > 0) {
          this.isQuestionnare = true;
        }
      }
    }

    const request4 = await Promise.all([
      this.commonService.getProductType().toPromise(),
      this.commonService.getProjectManager().toPromise(),
    ]).then(x => {
      this.productType = x[0].data;
      this.managers = x[1].data;
    }).catch(y => {})
  

    this.registerFarmerForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      age: new FormControl('', [Validators.required, Validators.min(18), Validators.max(99)]),
      sex: new FormControl('', [Validators.required]),
      area: new FormControl('', [Validators.required]),
      telNumber: new FormControl('', [Validators.min(1000000000), Validators.max(999999999999)]),
      photo_path: new FormControl('')
    });

    
  }

  setSnapshots(): void {
    const currentSnapshotArray = this.allSnapshots.filter(item => item.status === 'draft' || item.status === 'active');
    if (currentSnapshotArray && currentSnapshotArray.length > 0) {
      this.snapshot = currentSnapshotArray[0];
    } else {
      this.snapshot = undefined;
    }

    this.olderSnapshots = this.allSnapshots.filter(item => item.status === 'closed' || item.status === 'active');



    const activeSnapshot = this.allSnapshots.find(item => item.status === 'active');
    if (activeSnapshot) {
      this.oldSnapshotId = activeSnapshot.id;

    } else {
      this.oldSnapshotId = 0;
    }
    this.snapshotChanged();
  }

  syncDone(args) {
    if (navigator.onLine) {
      window.dispatchEvent(new Event('caching-now'));
      this.farmerService.getFarmersByProjectBasicData(this.projectId).subscribe((farmers) => {
        // this.farmers = farmers.data;
        window.dispatchEvent(new Event('data-synced'));
      });
    }
  }

  goToDashboard(): void {
    this.router.navigate(['dashboard']);
  }

  goToViewFarmer(farmer): void {
    let url;
    if (farmer.id && farmer.id > 0) {
      url = `/viewprofile/${farmer.project_id}/${farmer.id}`;
    } else if (farmer.tmp_id) {
      url = `/viewprofile/${farmer.project_id}/${farmer.tmp_id}`;
    } else {
      url = '/error';
    }
    this.router.navigate([url]);
  }


  ngAfterViewInit() {
    if (this.project && this.project.QuesProjectSnapshotMappings && this.project.QuesProjectSnapshotMappings.length > 0) {
      // If the active snapshot is in draft stage, fetch complete QuesProjectSnapshotMappings object from server

      // If the active snapshot is not in draft stage, fetch the complete QuesProjectSnapshotMappings object from the cache...
      // ... have to wait for the managerpage ngAfterViewInit function to finish getting the response for this to work
    }
  }

  

  ngOnDestroy() {
    this.showSpinner = false;
    clearInterval(this.getSnapshotInterval);
  }

  goToEditProject() {
    if (navigator.onLine) {
      const url = `setup/${this.project.id}`;
      this.router.navigate([url]);
    } else {
      this.toastrService.warning('You cannot edit a project while offline');
    }
  }

  sortBy(sortOn: string) {


    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);

  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    if (sortBy === 'name') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.farmers.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.farmers.sort((a, b) => {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'tel') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.farmers.sort((a, b) => {
        if (a.tel_number < b.tel_number) {
          return 1;
        }
        if (a.tel_number > b.tel_number) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'date') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.farmers.sort((a, b) => {
        if (a.cr_date < b.cr_date) {
          return 1;
        }
        if (a.cr_date > b.cr_date) {
          return -1;
        }
        return 0;
      });
    }

  }

  descendic(sortBy: string) {
    this.isAscending = true;
    if (sortBy === 'name') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.farmers.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.farmers.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'tel') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.farmers.sort((a, b) => {
        if (a.tel_number > b.tel_number) {
          return 1;
        }
        if (a.tel_number < b.tel_number) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'date') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.farmers.sort((a, b) => {
        if (a.cr_date > b.cr_date) {
          return 1;
        }
        if (a.cr_date < b.cr_date) {
          return -1;
        }
        return 0;
      });
    }
  }

  updateSyncStatusShown(args): void {
    for (let i = 0; i < this.farmers.length; i++) {
      // if there is a non-zero farmer_id in the allData element, it is an update object
      this.farmers[i]['isInIDB'] = false;
    }
    let numberOfUnsyncedFarmers = this.isInIDBInThisProject.length;
    this.isInIDBInThisProject = [];
    if (numberOfUnsyncedFarmers && numberOfUnsyncedFarmers > 0) {
      numberOfUnsyncedFarmers = 0;
      this.showSyncSuccessAlert = true;
      setTimeout(() => { 
        this.showSyncSuccessAlert = false 
      }, 5000);
    }
  }

  freezeQuestionnaire(): void {
    const url = `edit-questionnaire/${this.project.id}/${this.snapshot.id}`;
    this.router.navigate([url]);
  }

  closeSnapshot(template: TemplateRef<any>): void {
    
    this.closeSnapshotModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  cancelCloseSnapshot(): void {
    if (this.closeSnapshotModal) { 
      this.closeSnapshotModal.hide(); 
    }
  }

  // confirmCloseSnapshot(): void {
  //   this.disableCloseSnapshot = true;
  //   if (this.closeSnapshotModal) { 
  //     this.closeSnapshotModal.hide(); 
  //   }
  //   this.projectService.closeSnapshot(this.snapshot['id'])
  //     .subscribe(response => {
  //       if (response.status === 'success') {

  //         const closedSnapshotArray = this.allSnapshots.filter(item => item.id === response.data.id);
  //         if (closedSnapshotArray && closedSnapshotArray.length > 0) {
  //           const closedSnapshot = closedSnapshotArray[0];
  //           const index = this.allSnapshots.indexOf(closedSnapshot);
  //           this.allSnapshots[index] = response.data;
  //           // this.setSnapshots();
  //         }

  //         this.commonService.updateSnapshotStore({ project: this.projectId, snapshot: response.data });
  //         this.disableCloseSnapshot = false;
  //       }
  //     }, error => {
  //       this.disableCloseSnapshot = false;
  //     })
  // }

  declineCloseSnapshot(): void {
    if (this.closeSnapshotModal) { 
      this.closeSnapshotModal.hide(); 
    }
  }

  // newSnapshot(): void {
  //   this.disableCreateSnapshot = true;
  //   this.projectService.newSnapshot(this.projectId)
  //     .subscribe(result => {
  //       if (result.status === 'success') {
  //         if (this.allSnapshots && this.allSnapshots.length > 0) {
  //           this.allSnapshots.push(result.data);
  //         } else {
  //           this.allSnapshots = [];
  //           this.allSnapshots.push(result.data);
  //         }
  //         // this.setSnapshots();

  //         const snapshotToUpdate = this.snapshot;
  //         const mappingObjectJSON = JSON.parse(snapshotToUpdate.mapping_object);
  //         snapshotToUpdate.mapping_object = mappingObjectJSON;
  //         this.commonService.updateSnapshotStore({ project: this.projectId, snapshot: snapshotToUpdate });

  //         this.disableCreateSnapshot = false;
  //       }
  //     })
  // }

  modifySnapshot(template: TemplateRef<any>): void {
    this.oldSnapshotInfo = JSON.stringify({
      snapshot_name: this.snapshot.snapshot_name,
      start_date: this.snapshot.start_date,
      end_date: this.snapshot.end_date
    });
    this.snapshotModifying = this.snapshot;
    this.bsDateRange = [this.snapshotModifying['start_date'] ? new Date(this.snapshotModifying['start_date']) : null, this.snapshotModifying['end_date'] ? new Date(this.snapshotModifying['end_date']) : null]
    this.modifySnapshotModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  declineModifySnapshot(): void {
    const oldSnapshotInfo = JSON.parse(this.oldSnapshotInfo);
    this.snapshot.snapshot_name = oldSnapshotInfo.snapshot_name;
    this.snapshot.start_date = oldSnapshotInfo.start_date;
    this.snapshot.end_date = oldSnapshotInfo.end_date;

    this.snapshotModifying = this.snapshot;

    if (this.modifySnapshotModal) { 
      this.modifySnapshotModal.hide(); 
    }
  }

  confirmModifySnapshot(): void {

    this.projectService.updateSnapshot(this.snapshotModifying)
      .subscribe(response => {
        if (response.status === 'success') {

          const modifiedSnapshotArray = this.allSnapshots.filter(item => item.id === response.data.id);
          if (modifiedSnapshotArray && modifiedSnapshotArray.length > 0) {
            const modifiedSnapshot = modifiedSnapshotArray[0];
            const index = this.allSnapshots.indexOf(modifiedSnapshot);
            this.allSnapshots[index] = response.data;
            // this.setSnapshots();
          }

          if (this.modifySnapshotModal) { 
            this.modifySnapshotModal.hide(); 
          }

          this.commonService.updateSnapshotStore({ project: this.projectId, snapshot: response.data });
        }
      })
  }

  bsRangeValueChange(args): void {
    if (args) {
      if (args[0]) {
        if (args[0].getTime() !== 0) { 
          this.snapshotModifying['start_date'] = args[0]; 
        }
      }
      if (args[1]) {
        if (args[1].getTime() !== 0) { 
          this.snapshotModifying['end_date'] = args[1];
         }
      }
    }
  }

  applyLocaleToDatePicker(pop: any) {
    pop.hide();
    pop.show();
  }

  openRegisterFarmerModal(template: TemplateRef<any>): void {
    this.registerFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  registerFarmer(): void {
    
    this.registeringFarmer = true;
    if (this.snapshot.status === 'active') {

      const name = this.registerFarmerForm['value'].name;
      const age = this.registerFarmerForm['value'].age;
      const sex = this.registerFarmerForm['value'].sex;
      const area = this.registerFarmerForm['value'].area;
      const telNumber = this.registerFarmerForm['value'].telNumber;

      if (!name || name.length === 0 || !age || age.length === 0 || !sex || sex.length === 0 || !area || area.length === 0) {
        return;
      }

      const farmerToRegister = {
        name: name,
        age: age,
        sex: sex,
        village_area: area,
        tel_number: telNumber,
        photo_path: null,
        snapshot_id_ref: this.snapshot.id,
        project_id: this.projectId
      };

      if (navigator.onLine) {
        if (this.fileToUpload) {
          const formData: any = new FormData();
          formData.append('type', 'farmerAvatar');
          formData.append('projectId', this.projectId);
          formData.append('avatar', this.fileToUpload, this.fileToUpload.name);

          this.projectService.Upload(formData)
            .subscribe((obj) => {
              if (obj.msg === 'success') {
                farmerToRegister['photo_path'] = obj.data;
                this.farmerService.registerFarmer(farmerToRegister).subscribe(
                  (result) => {
                    this.registeringFarmer = false;
                    if (result.msg === 'success') {
                      result.data.cr_date = new Date();
                      this.farmers.unshift(result.data);
                      if (!this.registerAnotherFarmer && this.registerFarmerModal) { 
                        this.registerFarmerModal.hide(); 
                      }
                      this.registerFarmerForm.setValue({
                        name: '',
                        age: '',
                        sex: '',
                        area: '',
                        telNumber: '',
                        photo_path: ''
                      });
                      this.registerFarmerForm.markAsUntouched();
                      this.registerFarmerForm.markAsPristine();
                      this.fileToUpload = undefined;
                    } else {
                      if (!this.registerAnotherFarmer && this.registerFarmerModal) { 
                        this.registerFarmerModal.hide(); 
                      }
                    }
                  },
                  (error) => {
                    if (this.registerFarmerModal) { 
                      this.registerFarmerModal.hide(); 
                    }
                  }
                );
              }
            });
        } else {
          this.farmerService.registerFarmer(farmerToRegister).subscribe(
            (result) => {
              this.registeringFarmer = false;
              if (result.msg === 'success') {
                result.data.cr_date = new Date();
                this.farmers.unshift(result.data);
                if (!this.registerAnotherFarmer && this.registerFarmerModal) { 
                  this.registerFarmerModal.hide();
                 }
                this.registerFarmerForm.setValue({
                  name: '',
                  age: '',
                  sex: '',
                  area: '',
                  telNumber: '',
                  photo_path: ''
                });
                this.registerFarmerForm.markAsUntouched();
                this.registerFarmerForm.markAsPristine();
                this.fileToUpload = undefined;
                this.commonService.showToast('success', 'farmer_registration_successful', {});
              } else {
                if (!this.registerAnotherFarmer && this.registerFarmerModal) { 
                  this.registerFarmerModal.hide(); 
                }
              }
            },
            (error) => {
              if (this.registerFarmerModal) { 
                this.registerFarmerModal.hide(); 
              }
            }
          );
        }
      } else {
        const finalObj = {};
        const farmerObj = farmerToRegister;
        Object.keys(farmerObj).forEach(function (key) {
          if (typeof farmerObj[key] === 'object' && farmerObj[key] != null) {
            if (farmerObj[key].length && farmerObj[key].length > 0) {
              //finalObj[key] = {};
              finalObj[key] = farmerObj[key];
            } 
            // else {
            //   finalObj[key] = farmerObj[key];
            // }
          } else {
            finalObj[key] = farmerObj[key];
          }
        });
        const farmObj = new Array();
        finalObj['tmp_id'] = `FA${this.commonService.getRandomNum().toString().slice(0, 10)}_Tab0`;
        finalObj['savetab'] = 'Tab0';
        finalObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
        finalObj['cr_date'] = new Date().getTime();
        finalObj['id'] = 0;
        farmObj[farmObj.length] = finalObj;

        idbApp.addStoreInfo(farmObj, 'farmerstmp');
        this.commonService.showToast('info', 'offline_sync_later', {}); 
        this.showSpinner = false;
        this.registerFarmerForm.markAsPristine();
        this.registerSyncEvent();

        this.updateCache();

        farmerToRegister['cr_date'] = new Date();
        farmerToRegister['tmp_id'] = finalObj['tmp_id'];
        this.farmers.unshift(farmerToRegister);
        if (!this.registerAnotherFarmer && this.registerFarmerModal) { 
          this.registerFarmerModal.hide(); 
        }
        this.registerFarmerForm.setValue({
          name: '',
          age: '',
          sex: '',
          area: '',
          telNumber: '',
          photo_path: ''
        });
        this.registerFarmerForm.markAsUntouched();
        this.registerFarmerForm.markAsPristine();
        this.registeringFarmer = false;
      }
    }

  }

  registerSyncEvent() {
    navigator.serviceWorker.register('/service-worker.js', {
      scope: './'
    })
      .then(function (reg) {
        reg.sync.register('oneTimeSync');
      })
      .catch(function (error) {
      });
  }

  declineRegisterFarmer(): void {
    if (this.registerFarmerModal) { 
      this.registerFarmerModal.hide(); 
    }
    this.registerFarmerForm.setValue({
      name: '',
      age: '',
      sex: '',
      area: '',
      telNumber: '',
      photo_path: ''
    });
    this.registerFarmerForm.markAsUntouched();
    this.registerFarmerForm.markAsPristine();
  }

  async updateCache() {
  }

  handleFileInput(file: FileList) {

    const fileItem = file.item(0);

    const msg = this.commonService.ValidateFile(fileItem);

    if (msg.length > 0) {
      this.commonService.showToast('warning', 'generic_error', {});
      return;
    }

    this.fileToUpload = fileItem;

    const reader = new FileReader();
    reader.onload = (event: any) => {
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  // makeRevision() {
  //   this.spinner.show();
  //   this.revisionService.MakeRevision(this.projectId).subscribe(
  //     (result) => {
  //       this.arrSnapshotRevisions.push({ snapshot_id_ref: this.oldSnapshotId, revision: result.data.revision, cr_date: result.data.cr_date });
  //       this.snapshotChanged();
  //       this.spinner.hide();
  //       this.toastrService.success(`${this.revisionTxt} ${result.data.revision} ${this.createSuccessTxt}.`);
  //     },
  //     (error) => { 
  //       this.spinner.hide(); 
  //       this.toastrService.error(error.message); 
  //     }
  //   );
  // }

  getBackProjectFilter(projectNameVal, projectId) {
    let url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId} });
  }


  printProjectDatasheet() {
    
    let newSnap = {
      snapshot_name: "None",
      status: "Not Active",
      cr_date: "None"
    };
    let newRevision = {
      revision: "None",
      status: "Not Active",
      cr_date: "None"
    };

    let snapshotCreatedDateString = 'None';
    let revisionCreatedDateString = 'None';

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    if (this.snapshotSurveys != undefined && this.snapshotSurveys.length > 0) {
      const x = this.snapshotSurveys[0];
      newSnap.snapshot_name = x.name  + ' ' + x.survey_type_sequence;
      newSnap.cr_date = x.cr_date;
      const snapshotCreatedDate = new Date(newSnap.cr_date);
      snapshotCreatedDateString = `${snapshotCreatedDate.getDate()}-${months[snapshotCreatedDate.getMonth()]}-${snapshotCreatedDate.getFullYear()}`;
      newSnap.status = x.survey_stage_changelogs[0].change_type;

      if (x.latestRevision != undefined) {
        newRevision.revision = `${x.survey_type_sequence}.${x.latestRevision.revision_number}`;
        newRevision.cr_date = x.latestRevision.cr_date;
        const revisionCreatedDate = new Date(newRevision.cr_date);
        revisionCreatedDateString = `${revisionCreatedDate.getDate()}-${months[revisionCreatedDate.getMonth()]}-${revisionCreatedDate.getFullYear()}`;
        newRevision.status = x.survey_stage_changelogs[0].change_type;
      }
    }

    var doc = new jsPDF('p', 'pt');

    var text = "Project Datasheet",
      xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
    doc.text(text, xOffset, 40);


    doc.autoTable({
      head: [['Project Information']],
      margin: { top: 80 },
      theme: 'grid',
      bodyStyles: { text: { cellWidth: '200' } }

    });

    doc.autoTable({
      html: '#project-information-1',
      theme: 'grid',
      bodyStyles: {
        columnWidth: 200, // 'auto', 'wrap' or a number
        textColor: 20,
      },
    });

    doc.autoTable(
      {
        html: '#project-information-2',
        theme: 'grid',
        bodyStyles: {
          columnWidth: 200, // 'auto', 'wrap' or a number
          textColor: 20,
        },
      }
    );
    doc.autoTable({
      head: [['Snapshot Info']],
      body: [
        ['Active Snapshot : ', newSnap.snapshot_name],
        ['Created :', snapshotCreatedDateString],
        ['Stage:', newSnap.status.charAt(0).toUpperCase() + newSnap.status.slice(1)],
      ],
      margin: { top: 80 },
      theme: 'grid',
      bodyStyles: {
        columnWidth: 200, // 'auto', 'wrap' or a number
        textColor: 20,
      },
    });

    doc.autoTable({
      head: [['Revision Info']],
      body: [
        ['Revision Number : ', newRevision.revision],
        ['Created :', revisionCreatedDateString],
        ['Stage:', newRevision.status.charAt(0).toUpperCase() + newRevision.status.slice(1)],
      ],
      margin: { top: 80 },
      theme: 'grid',
      bodyStyles: {
        columnWidth: 200, // 'auto', 'wrap' or a number
        textColor: 20,
      },
    });

    doc.addPage();

    doc.autoTable({
      head: [['Mapped Services']],
      margin: { top: 80 },
      theme: 'grid',
      bodyStyles: {
        textColor: 20,
      },
    });

    const displayedServices = [];
    this.project.displayedServices.forEach(snap => {
      doc.autoTable({
        body: [[snap.name]],
        theme: 'grid',
        bodyStyles: {
          textColor: 20,

        },
      });
    })


    doc.autoTable({
      head: [['Campaign Information']],
      body: [["Campaign Information Coming Soon"]],
      margin: { top: 80 },
      theme: 'grid',
      bodyStyles: {
        columnWidth: 200, // 'auto', 'wrap' or a number
        textColor: 20,

      },
    });    

    const name = `Project Datasheet - ${this.project.name}.pdf`;
    doc.save(name);


  }

   // Get all surveys for the given project & filter them by type
   async setSurveys() {
    const requests = await Promise.all([this.surveyService.getAllSurveysForProject(this.projectId).toPromise()]);
    this.allSurveys = requests[0].message;

    this.filterSurveys();
  }

  filterSurveys(): void {
    this.campaignSurveys = this.allSurveys.filter(item => item.survey_type == 'campaign');
    this.snapshotSurveys = this.allSurveys.filter(item => item.survey_type == 'snapshot');
  }
}
