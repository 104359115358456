<app-header></app-header>

  <div class="project-header clearfix">

    <nav aria-label="breadcrumb" class="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-icon">
          <span class="btn-back" title="Back" i18n-title="@@back"  (click)="ecosystemRevertBack()" aria-current="page">
            <i class="utilicon utilicon-arrow-alt-left"></i>
          </span>
        </li>
        <li class="breadcrumb-icon">
          <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
        </li>
        <li class="breadcrumb-item"><a href="#" routerLink="/goto/{{fromNavId}}"  [attr.title]="typeOfAdministration">{{typeOfAdministration  | slice:0:15}}...</a></li>
        <li class="breadcrumb-item" aria-current="page"><a href="javascript:void(0)" (click)="ecosystemRevertBack()" i18n-title="@@ecoSystemSettings" title="Ecosystem Settings">...</a></li>
        <li class="breadcrumb-item active" aria-current="page" i18n="@@editEcoSystemSettings">Edit Ecosystem Settings</li>
      </ol>
    </nav>
  </div>


  <div class="clearfix"></div>
  <div class="content-wrap content-wrapper">
    <!--  getFormValidationErrors()-->
    <form *ngIf="ecoSystemData" name="ecosystemForm" #ecosystemForm="ngForm" class="ng-untouched ng-pristine ng-invalid form-design" novalidate (ngSubmit)="ecosystemForm.form.valid && submit(confirmRegionVillageArea)"
      enctype="multipart/form-data">

      <div class="form-box">

        <label i18n="@@ecosystemInformation" class="group-title">Ecosystem Information</label>

        <div class="row">

          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr">
              <label><span i18n="@@supplier">Supplier</span><span class="mandatory">*</span></label>
              <input type="text" name="Supplier_Name" placeholder="Enter value" i18n-placeholder="@@enterValue" class="form-control" [(ngModel)]="ecoSystemData?.supplier.name"
                #Supplier_Name="ngModel" disabled>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr">
              <label for="text-result"><span i18n="@@product">Product</span><span class="mandatory">*</span></label>
              <input type="text" name="Product_Name" placeholder="Enter value" i18n-placeholder="@@enterValue" class="form-control" [(ngModel)]="ecoSystemData?.product.name"
                #Product_Name="ngModel" maxlength="50" disabled>

          </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': currency.value == 0 && (currency.dirty || currency.touched || ecosystemForm.submitted)}">
              <label for="text-result"><span i18n="@@currency">Currency</span><span class="mandatory">*</span></label>
              <select required id="select-normal" class="form-control select2" [(ngModel)]="ecoSystemData.currency_id" name="currency" #currency="ngModel"
              [ngClass]="(ecoSystemData?.currency_id== 0 ? 'ng-invalid':'ng-valid')" onchange="this.setAttribute('required','');" (change)="setCurrency(currency.value)" #currency="ngModel">
                <option value="0" class="option" selected i18n="@@select">Select</option>
                <option *ngFor="let item of currencyArr; trackBy: trackById" [ngValue]="item.id">{{item.currency_code}} / {{item.currency_name}}</option>
              </select>
              <div class="error" *ngIf="currency.value == 0 && (currency.dirty || currency.touched || ecosystemForm.submitted)">
                <span i18n="@@enterValidInput">Enter valid input.</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
              <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': Country.value == 0 && (Country.dirty || Country.touched || ecosystemForm.submitted)}">
                <label for="text-result"><span i18n="@@country">Country</span><span class="mandatory">*</span></label>
                <select required class="form-control select2" [(ngModel)]="ecoSystemData.country_id" name="Country" [ngClass]="(ecoSystemData?.country_id==0? 'ng-invalid':'ng-valid')"
                  onchange="this.setAttribute('required','');" (change)="setCountry(Country.value)" #Country="ngModel">
                  <option value="0" class="option" selected i18n="@@select">Select</option>
                  <option *ngFor="let item of countries; trackBy: trackById" [ngValue]="item.id">{{item.name}}</option>
                </select>
                <div class="error" *ngIf="Country.value==0 && (Country.dirty || Country.touched || ecosystemForm.submitted)">
                  <span i18n="@@selectValidInput">Select valid input.</span>
                </div>
              </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': f_project_manager.value==0 && (f_project_manager.dirty || f_project_manager.touched || ecosystemForm.submitted) }">

                <label for="text-result"><span  i18n="@@drManager">DR Manager</span><span class="mandatory">*</span></label>

                <select required id="select-normal" class="form-control select2" [(ngModel)]="ecoSystemData.dr_manager_id" name="f_project_manager"
                  #f_project_manager="ngModel" [ngClass]="(ecoSystemData.dr_manager_id==0? 'ng-invalid':'ng-valid')" onchange="this.setAttribute('required','')" (change)="setManager(f_project_manager.value)">
                  <option value="0" class="option" selected i18n="@@select">Select</option>
                  <option *ngFor="let item of project_manager; trackBy: trackById" [ngValue]="item.id">{{item.name}}</option>
                </select>
                <div class="error" *ngIf="f_project_manager.value==0 && (f_project_manager.dirty || f_project_manager.touched || ecosystemForm.submitted)">
                  <span i18n="@@selectValidInput">Select valid input.</span>
                </div>
              </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': selectedLanguagesMultiSelect.length == 0 && (multiLang.dirty || multiLang.touched || ecosystemForm.submitted) }">

                <label for="text-result"><span i18n="@@languageNTranslationsmax3">Language & Translations (maximum 3)</span><span class="mandatory">*</span></label>

                <ng-multiselect-dropdown
                  [placeholder]="'Select'"
                  [data]="languagesList"
                  [(ngModel)]="selectedLanguagesMultiSelect"
                  [settings]="dropdownSettingsLanguages"
                  (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)" class="markDefaultSelect" [ngModelOptions]="{standalone: true}" #multiLang="ngModel" name="multiLang" [ngClass]="(selectedLanguagesMultiSelect.length == 0? 'ng-invalid':'ng-valid')"
                >
                </ng-multiselect-dropdown>
                <div class="error" *ngIf="selectedLanguagesMultiSelect.length == 0 && (multiLang.dirty || multiLang.touched || ecosystemForm.submitted)">
                  <span>Select max 2 translations permitted along with English as default.</span>
                </div>
              </div>
          </div>

        </div>
      </div>
      <hr class="breakline">

      <div *ngIf="unitsMasterArray && unitsMasterArray.length > 0">
        <div *ngFor="let item of unitsMasterArray" class="form-box">
          <label class="group-title" style="display: inline" *ngIf="flagEn">{{item.unitType.name}} Units*</label>
          <label class="group-title" style="display: inline" *ngIf="flagEs">Unidades de {{item.unitType.name}}*</label>
          <label class="group-title" style="display: inline" *ngIf="flagFr">Unités de {{item.unitType.name}}*</label>
          <label class="group-title" style="display: inline" *ngIf="flagCh">{{item.unitType.name}} 单位*</label>
          <label class="group-title" style="display: inline" *ngIf="flagTa">{{item.unitType.name}} அலகுகள்*</label>
          <label class="group-title" style="display: inline" *ngIf="flagMg">Units* {{item.unitType.name}}</label>
          
          <div class="clearfix"></div>
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="table-wrapper">
                  <table class="table table-one-line form-table">
                    <tbody>
                      <tr *ngFor="let projectUOM of item.unitsInProject; let i=index; trackBy:trackByIndex">
                        <td style="width:30%">
                          <div class="form-group" style="padding-bottom: 16px;">
                            <label for="text-result" i18n="@@unitName">Unit Name</label>
                            
                            <input type="text" disabled [id]="projectUOM['code']" [name]="projectUOM['code']" required placeholder="Enter value" i18n-placeholder="@@enterValue"
                            [(ngModel)]="projectUOM['name']" class="form-control width-measure">
                          </div>
                        </td>
                        <td style="width:30%">
                          <div class="form-group" [ngClass]="{ 'has-error': !projectUOM.international_value || projectUOM.international_value == '' && ecosystemForm.submitted ||
                          (projectUOM.international_value && projectUOM.international_value.toString().indexOf('.') > -1 &&
                          projectUOM.international_value.toString().substring(projectUOM.international_value.toString().indexOf('.')).length > 5) }"
                          style="padding-bottom: 10px;">
                            <label for="text-result" i18n="@@internationalUnits">International Unit</label>


                            <div class="input-group">
                                <input [id]="projectUOM.tmp_id" type="text" [name]="projectUOM.tmp_id" required placeholder="Enter value" i18n-placeholder="@@enterValue"
                                [(ngModel)]="projectUOM.international_value" class="form-control width-measure" pattern="^\d+(\.\d{1,4})?$"
                                customMin="0" step="1" customMax="999999" (change)="updateUnitsMasterArrayCopy($event)">
                              <div class="input-group-append">
                                <span class="input-group-text" id="basic-addon2">{{projectUOM.international_unit}}</span>
                              </div>
                            </div>
                            <div class="error" *ngIf="!projectUOM.international_value || projectUOM.international_value == '' && (ecosystemForm.submitted)">
                              <span i18n="@@enterValidInput">Enter valid input.</span>
                            </div>
                            <div class="error" *ngIf="projectUOM.international_value && projectUOM.international_value.toString().indexOf('.') > -1 &&
                                projectUOM.international_value.toString().substring(projectUOM.international_value.toString().indexOf('.')).length > 5">
                                <span i18n="@@upToFourDecimalsOnly">Up to 4 decimal places permitted</span>
                            </div>
                          </div>
                        </td>
                        <td  style="width:20%; text-align: center">
                          <div class="form-group" style="padding-bottom: 10px;">
                            <label for="radio_results" i18n="@@selectDefault">Set as Default</label>
                            
                            <input type="radio" [name]="projectUOM?.code" [checked]="projectUOM.is_default == true" (change)="changeDefaultProjectUOM(projectUOM,informationTemplate)"
                              class="px">

                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="breakline">
      <div class="form-box">
        <div>
          <label class="group-title" style="display: inline-block;" i18n="@@areaNVillage">Area & Villages </label>
          <button class="btn btn-secondary btn-sm fltRgt addBtn" type="button" value="addRegion" (click)="addRegion('region', regionVillageMasterArray?.length);$event.stopPropagation()"><span i18n="@@addRegion">Add Area</span>&nbsp;<i class="utilicon utilicon-plus" style="vertical-align: text-bottom;"></i></button>
        </div>
        <div class="row" *ngIf="regionVillageMasterArray && regionVillageMasterArray.length > 0">
          <div class="col-lg-12 col-md-12">
            <div class="table-wrapper">
              <table class="table table-one-line form-table">
                <tbody>
                  <tr>
                    <td style="width:50%">
                      <label for="radio_results" i18n="@@areaWithPrompt">Area (for project level mapping)</label>
                    </td>
                    <td style="width:50%">
                      <label for="radio_results" i18n="@@villageOrVillArea">Village / Area (for farmer level mapping)</label>
                    </td>
                  </tr>
                  <ng-container>
                    <tr *ngFor="let currentItem of regionVillageMasterArray; let i = index;">
                      <td  style="width:50%; vertical-align: top">
                        <div class="form-group region-card-box">
                          <div class="input-group region-card-inputbox" style="margin-bottom: 0px;">
                            <div class="input-group" *ngIf="currentItem.isregion == true" style="margin-bottom: 0px;">
                              <!-- autofocus added -->
                              <input [id]="currentItem?.tmp_id" type="text"  [name]="currentItem.tmp_id" class="form-control width-measure" required [(ngModel)]="currentItem.region_name" [ngModelOptions]="{standalone: true}" #region_{{currentItem.tmp_id}} onchange="this.setAttribute('required','');" (keyup)="regionVillageAreaValidationFun($event, 'region',currentItem)" [disabled]="currentItem?.project_mapped?.length > 0">
                            </div>
                          </div>
                          <div class="form-group region-card-delete">
                            <button type="button" class="delete-btn" style="padding: 0px 6px;" [class.disabled]="currentItem?.project_mapped?.length > 0" title="Delete Area" i18n-title="@@delete" *ngIf="currentItem.isregion == true" (click)="deleteRegion(currentItem)"  [disabled]="currentItem?.project_mapped?.length > 0"><i class="utilicon utilicon-trash-alt-light text-white"></i></button>
                          </div>
                          <div class="error" *ngIf="currentItem.region_name == '' && ecosystemForm.submitted">
                            <span i18n="@@enterValidInput">Enter valid input.</span>
                          </div>
                        </div>
                      </td>
                      <td style="background:#fff; width:50%; vertical-align: top">
                        <div class="form-group float-right">
                            <button class="btn btn-secondary btn-sm fltRgt addBtn"  type="button" value="Add" *ngIf="currentItem.isregion == true" (click)="addVillageArea('villagearea',currentItem)"><span i18n="@@add2">Add </span> <i class="utilicon utilicon-plus" style="vertical-align: text-bottom;"></i></button>
                        </div>
                        <div class="clearfix"></div>
                        <div class="row">
                          <div class="form-inline separate-card" *ngFor="let innerItem of currentItem?.region_villages;let j = index;">
                            <div class="input-group">
                              <!-- autofocus added  and method changed-->
                              <input type="text"  style="margin-top: 10px;display: initial;" required class="form-control width-measure" onchange="this.setAttribute('required','');" [(ngModel)]="innerItem.village_name" [ngModelOptions]="{standalone: true}" name="villagearea_{{innerItem.tmp_id}}" (keyup)="regionVillageAreaValidationFun($event,'villagearea',currentItem, innerItem)" *ngIf="currentItem.isregion == true && (innerItem.isregion == false || !innerItem.isregion)" [disabled]="innerItem?.hasMappedFarmer">
                            </div>

                            <div class="form-group p-4">
                              <button type="button"  class="delete-btn" style="padding: 0px 6px;" [class.disabled]="innerItem?.hasMappedFarmer" title="Delete Village/Village-Area" i18n-title="@@delete" *ngIf="currentItem.isregion == true && (innerItem.isregion == false || !innerItem.isregion)" (click)="deleteVillageArea(currentItem,innerItem)" [disabled]="innerItem?.hasMappedFarmer"><i class="utilicon utilicon-trash-alt-light text-white"></i></button>
                            </div>

                            <div class="error" *ngIf="innerItem.village_name == '' && ecosystemForm.submitted">
                              <span i18n="@@enterValidInput">Enter valid input.</span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

      <hr class="breakline">
      <div class="form-box actions">
        <button  [disabled]="savingEcosystem || !ecosystemForm.valid || selectedLanguages?.length > 3 || regionVillageCheck || projectManagerFlag || countryFalg || currencyFlag" class="btn btn-primary"  title="Update Ecosystem" i18n-title="@@updateEcosystem" type="submit" i18n="@@updateEcosystem"> Update Ecosystem </button>
        
        <button  class="btn btn-secondary"  title="Cancel" i18n-title="@@cancel" i18n="@@cancel"  type="button" (click)="cancel(confirmCancelTemplate)">
          Cancel </button>
      </div>

    </form>
    <ng-template #confirmCancelTemplate>
      <div class="modal-body text-center">
        <h3 i18n="@@discardUnsavedChanges">
          Discard unsaved changes?
        </h3>
        <button type="button" title="Yes" i18n-title="@@yes" class="btn btn-secondary mr-1" (click)="confirmModal('yes')" i18n="@@yes">Yes</button>
        <button type="button" title="No" i18n-title="@@no" class="btn btn-primary cancel-focus" autofocus appAutofocus  (click)="confirmModal('no')" i18n="@@no">No</button>
      </div>
    </ng-template>
    <ng-template #informationTemplate>
      <div class="modal-body text-center">
        <h5>
          <strong i18n="@@note" style="font-size: 18px;font-weight: 600;text-transform: capitalize;">Note: </strong>
          <span i18n="@@noteEditEconsystemSettings1" style="font-size: 15px;text-transform: initial;">
            Existing project profiling (P2F-Roots) defaults will not change, however, the same will be resent for all traceability calculations and new default will be applied to any new projects created in P2F-Roots for Profiling.
          </span>
        </h5>
        <button type="button" title="Close" i18n-title="@@Close" class="btn btn-primary" (click)="infoModalClose()" i18n="@@Close">Close</button>
      </div>
    </ng-template>
    <ng-template #confirmRegionVillageArea>
      <div class="modal-body text-center">
        <h3 style="font-size: 15px !important;text-transform: initial;" i18n="@@projectCreationNFarmerRegistrationCanNotBeDoneWithArVill">
            Project creation and Farmer Registration cannot be done without Area and Village / Area Mappings. Would you like to continue?
        </h3>
        <button type="button" title="Yes" i18n-title="@@yes" class="btn btn-secondary mr-1" (click)="confirmModal3('yes')" i18n="@@yes">Yes</button>
        <button type="button" title="No" i18n-title="@@no" class="btn btn-primary cancel-focus" autofocus appAutofocus  (click)="confirmModal3('no')" i18n="@@no">No</button>
      </div>
    </ng-template>
  </div>


  <div class="modal fade" id="myModal"  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="z-index: 9999">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          <h4 class="modal-title" id="myModalLabel" i18n="@@error">Error</h4>
        </div>
        <div class="modal-body">
          <ul>
            <li class="red" i18n="@@projectNameMissing">Project Name is missing</li>
            <li class="red" i18n="@@projectManagerMissing">Project Manager is missing</li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" i18n="@@ok"  i18n-title="@@ok">Ok</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
