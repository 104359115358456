import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { AppConfigService } from './app-config.service';

@Injectable()
export class UserService {

  userURL = this.appConfigService.getUrl(this.appConfigService.USER);
  resetUPURL = this.appConfigService.getUrl(this.appConfigService.RESETUSERNAMEPASSWORD);
  private isUserLoggedIn;
  public username;
  private isBackClicked;

  constructor(private readonly storageService: StorageService, private readonly http: HttpClient, private readonly appConfigService: AppConfigService) {
    this.isUserLoggedIn = false;
  }

  setUserLoggedIn() {
    this.isUserLoggedIn = true;
    this.username = 'admin';
  }

  getUserLoggedIn() {
    return this.isUserLoggedIn;
  }
  setBackClicked(val) {
    this.isBackClicked = val;
  }
  getBackClicked() {
    return this.isBackClicked;
  }
  getCurrentUser(): any {
    const localTokenInfo = this.storageService.getLocalTokenInfo();
    if (localTokenInfo) {
      return JSON.parse(localTokenInfo);
    }
    else {
     return;
    }
  }

  GetProjectUsers(id: number): Observable<any> {
    return this.http.get<any>(`${this.userURL}/getprojectusers/` + id);
  }

  SaveUser(user: any, action: string, uid: number, prj: number): Observable<any> {
    return this.http.post(`${this.userURL}/saveUser`, { user: user, action: action, uid: uid, prj: prj });
  }

  SaveUserSecret2fa(sercret2fa: string, action: string, uid: number): Observable<any> {
    return this.http.post(`${this.userURL}/saveSecret2fa`, { sercret2fa: sercret2fa, action: action, uid: uid });
  }
  SaveUserSecret2faFlag(uid: number): Observable<any> {
    return this.http.post(`${this.userURL}/saveSecret2faFlag`, { uid: uid });
  }

  GetUserSecret2fa(uid: number): Observable<any> {
    return this.http.get(`${this.userURL}/getSecret2fa/` + uid);
  }

  updatePassword(oldPass: string, newPass: string, confirmPass: string, uid: number): Observable<any> {
    return this.http.post(`${this.userURL}/updatePassword`, { oldPass: oldPass, newPass: newPass, confirmPass: confirmPass, uid: uid });
  }
  DeleteUser(uid: number): Observable<any> {
    return this.http.post(`${this.userURL}/deleteuser`, { uid: uid });
  }

  sendForgetPassEmail(username: string, email: string): Observable<any> {
    return this.http.post(`${this.userURL}/sendForgetPassEmail`, { userName: username, email: email });
  }
  validateUserPassword(uid: number,oldPass: string, newPass, confirmPass){

    return this.http.post(`${this.userURL}/validateUserPassword`, { uid: uid, oldPass: oldPass, newPass: newPass, confirmPass : confirmPass });
  }

  resetUsernamePassword(item){
    return this.http.post(this.resetUPURL, item);
  }

  getAllUsers(): Observable<any> {
    return this.http.get<any>(`${this.userURL}/getallusers`);
  }

  saveUPM(uid: number, pid: number, currentUserId: number, prjid: number): Observable<any> {
    return this.http.post(`${this.userURL}/saveupm`, {uid: uid, pid: pid, cid: currentUserId, prjid: prjid});
  }

  getNavItemsForUserType() {
    return this.http.get<any>(`${this.userURL}/getNavItems`);
  }
}
