import { Component, TemplateRef, OnInit, AfterViewInit, ElementRef, ViewChild, Inject, Renderer } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, merge, fromEvent } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { takeUntil, mapTo } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { Project, UserTypeServiceMapping } from '../../models/project';
import { ProjectService } from '../../services/project.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { HighchartReportsService } from '../../services/highchart-reports.service';

@Component({
  selector: 'app-reportingmanagement',
  templateUrl: './reportingmanagement.component.html',
  styles: [
    `
    .list-project ul.mdm-box li div.box h3 {
      text-transform: capitalize;
      margin: 1px 0 0 0;
      padding: 4px 0 2px 0;
    }
    .content-wrapper.background .myprojects .list-project ul li div.box p {
      min-height: 25px;
    }

    `
  ]
  //styleUrls: ['./reportingmanagement.component.css']
})
export class ReportingmanagementComponent implements OnInit {
  projects: any = [];
  project: any;
  projectId = 0;
  projectReporting: any = [];
  currentUser;
  public id: any;
  online$: Observable<boolean>;
  public dashboardLists:any = [];
  public dashboardRecord;
  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    public toastrService: ToastrService,
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    private hcRService:HighchartReportsService
  ) {

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.translate.get('report_labels').subscribe(result => {
      this.projectReporting = [
        {
          image: './assets/images/highchart-dashboard.svg',
          title: result.titles.dashboard,
          subtitle: result.subtitles.dashboard,
          route: 'dashboards'
        },
        {
          image: './assets/images/highchart-reports.svg',
          title: result.titles.charts,
          subtitle: result.subtitles.charts,
          route: 'runreport'
        },
        // {
        //   image: './assets/images/highchart-trends.svg',
        //   title: 'Trends',
        //   subtitle: 'Trends',
        //   route: ''
        // },
        {
          image: './assets/images/highchart-kpi-n-indecators.svg',
          title: result.titles.kpis,
          subtitle: result.subtitles.kpis,
          route: 'kpiindicators'
        },
        {
          image: './assets/online_images/compare-data.svg',
          title: result.titles.compareData,
          subtitle: result.subtitles.compareData,
          route: 'comparedata'
        },
        {
          image: './assets/images/highchart-trends.svg',
          title: result.titles.consolidatedReport,
          subtitle: result.subtitles.consolidatedReport,
          route: 'consolidatedreport'
        },
      ];
    });


    translate.setDefaultLang('en');

  }

  async ngOnInit() {

    this.projectId = this.route.snapshot.params['id']; 
    this.currentUser = this.userService.getCurrentUser();
    // if (navigator.onLine) {
    //   this.project = this.route.snapshot.data.project.project.data;
    // }else{
    //   const requests = await Promise.all([
    //     this.projectService.GetProjectsDataDashboard().toPromise()
    //   ]);
    //   this.project = requests[0].data.find(x => x.id === this.projectId);
    // }
    this.projectService.getProjectBasicInfo(this.projectId).subscribe(response =>{
      this.project = response.data;
      let projectObject = this.project.filter(item => item.id == this.projectId)[0];
      this.project = projectObject;
    });

    let requestParams ={
      "projectId":this.projectId
    }

    this.hcRService.getDashboardsList(requestParams).subscribe(response =>{
      //console.log("response ==> ", response);
      if(response.msg == 'success'){
        this.dashboardLists = response.data;
        for(let i=0;i<this.dashboardLists.length;i++){
          if(this.dashboardLists[i].is_default == 'Y'){
            this.dashboardLists[i].is_default = true;
          }else if(this.dashboardLists[i].is_default == 'N'){
            this.dashboardLists[i].is_default = false;
          }
        }
        this.dashboardRecord = this.dashboardLists.filter(item => item.is_default === true)[0];        
        //console.log("this.dashboardRecord ",this.dashboardRecord);
      }
      
    });


  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  detailProjectReport(x) {
    const url = x.route.toString();
    const routePath = 'charts/'+ url +'/'+ this.projectId;
    if (url.split('').length > 1) {
      if(url == 'dashboards'){
        if(this.dashboardRecord && this.dashboardRecord.is_default === true){
          let path = 'charts/'+ url +'/'+'viewreports'+'/'+this.dashboardRecord.id +'/'+ this.projectId;
          this.router.navigate([path]);
        }else{
          this.router.navigate([routePath]);
        }        
      }else{
        this.router.navigate([routePath]);
      }
      
    }
  }

}
