import { Component, OnInit, TemplateRef } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil, map, mapTo } from 'rxjs/operators';
import { Subject, Observable, fromEvent, merge, of } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';


import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';


import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';

declare var idbApp: any;

@Component({
  selector: 'app-register-farmer',
  templateUrl: './register-farmer.component.html',
  styleUrls: ['./register-farmer.component.css']
})
export class RegisterFarmerComponent implements OnInit {
  

  public projectId;
  public registerFarmerModal;
  public registeringFarmerName;
  public registeringFarmerAge;
  public registeringFarmerSex;
  public registeringFarmerArea;
  public registeringFarmer = false;
  public snapshot;
  public master;
  public sex;
  public registerFarmerForm: FormGroup;
  public registerAnotherFarmer = false;
  fileToUpload: File = null;
  photoModel = '';
  public projects;
  private readonly unsubscribe: Subject<void> = new Subject();
  isOnline: Observable<boolean>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly commonService: CommonService,
    private readonly router: Router,
    private readonly farmerService: FarmerService,
    private readonly projectService: ProjectService,
    private readonly translate: TranslateService,
    public toastrService: ToastrService,
    public modalService: BsModalService,
    private readonly localeService: BsLocaleService,
    private readonly formBuilder: FormBuilder) {
      this.isOnline = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      );
    }

  async ngOnInit() {
    this.scrollToTop();
    if (navigator.onLine) {

      this.master = this.route.snapshot.data.project.master.data;
      this.sex = this.master.filter(x => x.item_tab === 'farmer_reg' && x.item_code === 'sex');

      this.projectService.GetProjects().pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (project) => {
          if (project.msg === 'success') {
            this.projects = project.data;
            this.registerFarmerForm = this.formBuilder.group({
              name: new FormControl('', [Validators.required]),
              age: new FormControl('', [Validators.required, Validators.min(18), Validators.max(99)]),
              sex: new FormControl('', [Validators.required]),
              area: new FormControl('', [Validators.required]),
              telNumber: new FormControl(''),
              photo_path: new FormControl('')
            });
          }
        },
        (error) => console.error(error)
      );
    }
    else {
      const projects = await this.projectService.GetProjects().toPromise();
      if (projects) {
        this.projects = projects.data;
      }


      this.registerFarmerForm = this.formBuilder.group({
        name: new FormControl('', [Validators.required]),
        age: new FormControl('', [Validators.required, Validators.min(18), Validators.max(99)]),
        sex: new FormControl('', [Validators.required]),
        area: new FormControl('', [Validators.required]),
        telNumber: new FormControl('', [Validators.min(1000000000), Validators.max(999999999999)]),
        photo_path: new FormControl('')
      });

      const master = await this.farmerService.GetFarmerMaster().toPromise();
      this.master = master.data;
      this.sex = this.master.filter(x => x.item_tab === 'farmer_reg' && x.item_code === 'sex');
      
    }
  }


  openRegisterFarmerModal(template: TemplateRef<any>) : void {
    this.registerFarmerModal = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
  }

  registerFarmer() : void {
    this.registeringFarmer = true;
    

    const name = this.registerFarmerForm['value'].name;
    const age = this.registerFarmerForm['value'].age;
    const sex = this.registerFarmerForm['value'].sex;
    const area = this.registerFarmerForm['value'].area;
    const telNumber = this.registerFarmerForm['value'].telNumber;

      if (!name || name.length == 0 || !age || age.length == 0 || !sex || sex.length == 0 || !area || area.length == 0) {
        return;
      }

      const farmerToRegister = {
        name: name,
        age: age,
        sex: sex,
        village_area: area,
        tel_number: telNumber,
        photo_path: null,
        project_id: this.projectId
      };

      if (navigator.onLine) {
        if (this.fileToUpload) {
          const formData: any = new FormData();
          formData.append('type', 'farmerAvatar');
          formData.append('projectId', this.projectId);
          formData.append('avatar', this.fileToUpload, this.fileToUpload.name);

          this.projectService.Upload(formData)
          .subscribe((obj) => {
            if (obj.msg === 'success') {
              
              farmerToRegister['photo_path'] = obj.data;
              this.farmerService.registerFarmer(farmerToRegister).subscribe(
                (result) => {
                  
                  this.registeringFarmer = false;
                  if (result.msg === 'success') {
                    result.data.cr_date = new Date();
                   
                    if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                      this.registerFarmerModal.hide();
                    }
                    this.registerFarmerForm.setValue({
                      name: '',
                      age: '',
                      sex: '',
                      area: '',
                      telNumber: '',
                      photo_path: ''
                    });
                    this.registerFarmerForm.markAsUntouched();
                    this.registerFarmerForm.markAsPristine();
                    this.fileToUpload = undefined;
                    this.commonService.showToast('success', 'farmer_registration_successful', {});
                  }
                  else {
                    if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                      this.registerFarmerModal.hide();
                    }
                  }
                },
                (error) => {
                  if (this.registerFarmerModal) {
                    this.registerFarmerModal.hide();
                  }
                }
              );
            }
          });
        }
        else {
          this.farmerService.registerFarmer(farmerToRegister).subscribe(
            (result) => {
              this.registeringFarmer = false;
              if (result.msg === 'success') {
                result.data.cr_date = new Date();
                if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                  this.registerFarmerModal.hide();
                }
                this.registerFarmerForm.setValue({
                  name: '',
                  age: '',
                  sex: '',
                  area: '',
                  telNumber: '',
                  photo_path: ''
                });
                this.registerFarmerForm.markAsUntouched();
                this.registerFarmerForm.markAsPristine();
                this.fileToUpload = undefined;
              }
              else {
                if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                  this.registerFarmerModal.hide();
                }
              }
            },
            (error) => {
              if (this.registerFarmerModal) {
                this.registerFarmerModal.hide();
              }
            }
          );
        }
      }
      else {
        const finalObj = {};
        const farmerObj = farmerToRegister;
        Object.keys(farmerObj).forEach(function (key) {
          if (typeof farmerObj[key] === 'object' && farmerObj[key] != null) {
            if (farmerObj[key].length && farmerObj[key].length > 0) {
              //finalObj[key] = {};
              finalObj[key] = farmerObj[key];
            } 
            // else {
            //   finalObj[key] = farmerObj[key];
            // }
          } else {
            finalObj[key] = farmerObj[key];
          }
      });
        const farmObj = new Array();
        finalObj['tmp_id'] = `FA${this.commonService.getRandomNum().toString().slice(0, 10)}_Tab0`;
        finalObj['savetab'] = 'Tab0';
        finalObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
        finalObj['cr_date'] = new Date().getTime();
        finalObj['id'] = 0;
        farmObj[farmObj.length] = finalObj;
        
        idbApp.addStoreInfo(farmObj, 'farmerstmp');
        this.commonService.showToast('info', 'offline_sync_later', {}); 
        
        this.registerFarmerForm.markAsPristine();
        this.registerSyncEvent();

        farmerToRegister['cr_date'] = new Date();
        farmerToRegister['tmp_id'] = finalObj['tmp_id'];
        if (!this.registerAnotherFarmer && this.registerFarmerModal) {
          this.registerFarmerModal.hide();
        }
        this.registerFarmerForm.setValue({
          name: '',
          age: '',
          sex: '',
          area: '',
          telNumber: '',
          photo_path: ''
        });
        this.registerFarmerForm.markAsUntouched();
        this.registerFarmerForm.markAsPristine();
        this.registeringFarmer = false;
      }
    
  }

  registerSyncEvent() {
    navigator.serviceWorker.register('/service-worker.js', {
      scope: './'
    })
    .then(function (reg) {
      reg.sync.register('oneTimeSync');
    })
    .catch(function (error) {
    });
  }

  declineRegisterFarmer() : void {
    if (this.registerFarmerModal) {
      this.registerFarmerModal.hide();
    }
    this.registerFarmerForm.setValue({
      name: '',
      age: '',
      sex: '',
      area: '',
      telNumber: '',
      photo_path: ''
    });
    this.registerFarmerForm.markAsUntouched();
    this.registerFarmerForm.markAsPristine();
  }

  handleFileInput(file: FileList) {

    const fileItem = file.item(0);

    const msg = this.commonService.ValidateFile(fileItem);

    if (msg.length > 0) {
      
      this.commonService.showToast('warning', 'generic_error', {});
      return;
    }

    this.fileToUpload = fileItem;
    

    const reader = new FileReader();
    reader.onload = (event: any) => {
      
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  scrollToTop() {
    
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

}
