import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../services/project.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FarmerService } from '../../services/farmer.service';
import { TrainingService } from '../../services/training.service';
import Compressor from 'compressorjs';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';
var EditTrainingComponent = /** @class */ (function () {
    function EditTrainingComponent(_route, router, projectService, deviceService, formBuilder, commonService, toastrService, modalService, spinner, trainingService, farmerService, translate, userService) {
        var _this = this;
        this._route = _route;
        this.router = router;
        this.projectService = projectService;
        this.deviceService = deviceService;
        this.formBuilder = formBuilder;
        this.commonService = commonService;
        this.toastrService = toastrService;
        this.modalService = modalService;
        this.spinner = spinner;
        this.trainingService = trainingService;
        this.farmerService = farmerService;
        this.translate = translate;
        this.userService = userService;
        this.isTrainingOpen = false;
        this.isCertificateOpen = false;
        this.isExpiryOpen = false;
        this.dateFormat = 'DD-MMM-YYYY';
        this.uploadedTrainingFiles = [];
        this.uploadedTrainingFilesOld = [];
        this.trainers = [];
        this.loadingFarmers = false;
        this.selectedFarmersCopy = [];
        this.farmers = [];
        this.selectedFarmers = [];
        this.selectedTrainings = [];
        this.selectedSpouse = [];
        this.selectedChild = [];
        this.globalTrainings = [];
        this.globalSpouse = [];
        this.globalChild = [];
        this.confirmModalPopup = true;
        this.page = 1;
        this.itemsPerPage = 10;
        this.farmersListPage = 1;
        this.groupFarmers = [];
        this.disableAddButton = true;
        this.totalSelect = ['group1'];
        this.maxDate = new Date();
        this.currentTab = [];
        this.currentGroup = {
            "groupName": "",
            "details": [],
            "id": ""
        };
        this.currentGroupFarmers = [];
        this.allProjectFarmers = [];
        this.updatedFarmers = [];
        this.confirmGroupModal = true;
        this.newGroup = 0;
        this.allGroupIDs = [];
        this.isAscending = true;
        this.isAscendingCode = true;
        this.isAscendingName = true;
        this.isAscendingAge = true;
        this.isAscendingSex = true;
        this.isAscendingOfficialID = true;
        this.isAscendingFarmer = true;
        this.isAscendingIdFarmer = true;
        this.isAscendingNameFarmer = true;
        this.isAscendingTelFarmer = true;
        this.isAscendingDateFarmer = true;
        this.isAscendingAgeFarmer = true;
        this.isAscendingSexFarmer = true;
        this.isAscendingVillageFarmer = true;
        this.isAscendingVCFarmer = true;
        this.currentUser = this.userService.getCurrentUser();
        this.viewModel = [];
        this.isView = false;
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
        this.translate.get('select_deselect_object').subscribe(function (result) {
            _this.selectDeselectLabels = result;
        });
        this.translate.get('additional_farmer').subscribe(function (result) {
            _this.additionalFarmer = result;
        });
    }
    EditTrainingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.projectId = +this._route.snapshot.params['id'];
        this.type = "Training";
        this.hdrID = this._route.snapshot.params['typeid'];
        this._route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (queryParams.get('isView') && queryParams.get('isView') === 'true') {
                    this.isView = true;
                }
                return [2 /*return*/];
            });
        }); });
        this.getTrainingCertifcationList();
        this.createTrainingForm();
        this.isDesktopDevice = this.deviceService.isDesktop();
    };
    EditTrainingComponent.prototype.getTrainingCertifcationList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, userObj;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([
                                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                this.trainingService.get_all_training_certification(this.projectId).toPromise(),
                                this.trainingService.get_all_trainer(this.projectId).toPromise(),
                                this.trainingService.getAllGroups(this.projectId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        if (request[0]['msg'] === 'success') {
                            this.project = request[0]['data'][0];
                        }
                        if (request[1]['msg'] === 'success') {
                            this.trainingCertifications = request[1]['data'];
                        }
                        if (this.currentUser.role.id <= 3) {
                            if (request[2]['msg'] === 'success') {
                                this.trainers = request[2]['data'];
                            }
                        }
                        else {
                            userObj = {
                                users_id: this.currentUser.id,
                                users: {
                                    name: this.currentUser.name
                                }
                            };
                            this.trainers.push(userObj);
                        }
                        if (request[3]['msg'] === 'success') {
                            this.allGroups = request[3]['data'];
                        }
                        this.trainings = this.trainingCertifications.filter(function (item) {
                            return item.training_certification_master.training_certification_type == 'Training' && item.training_certification_master.status == 'Active';
                        });
                        this.certifications = this.trainingCertifications.filter(function (item) {
                            return item.training_certification_master.training_certification_type == 'Certification' && item.training_certification_master.status == 'Active';
                        });
                        this.editTraining();
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    EditTrainingComponent.prototype.createTrainingForm = function () {
        this.groundForm = this.formBuilder.group({
            trainingName: new FormControl('0', [Validators.required]),
            trainerName: new FormControl('0', [Validators.required]),
            description: new FormControl('', [Validators.maxLength(1000)]),
            trainingDate: new FormControl(null, [Validators.required]),
            group1: new FormControl('0', [Validators.required]),
            certificateName: new FormControl('0', [Validators.required]),
            certificationDate: new FormControl(null),
            expiryDate: new FormControl(null)
        });
    };
    EditTrainingComponent.prototype.editTraining = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, obj, trainingName, trainerName, viewObj, details, _loop_1, this_1, _i, _a, _b, key, value;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([
                                this.trainingService.getTrainingDetails(this.hdrID).toPromise()
                            ])];
                    case 1:
                        request = _c.sent();
                        if (request[0]['msg'] === 'success') {
                            this.typeDetails = request[0]['data'];
                        }
                        this.uploadedTrainingFilesOld = this.typeDetails.training_documents_dtl;
                        this.currentTab = [];
                        obj = {
                            trainingName: this.typeDetails.project_training_certification_mapping_id,
                            trainerName: this.typeDetails.trainer_id,
                            description: this.typeDetails.description,
                            trainingDate: new Date(this.typeDetails.training_date)
                        };
                        trainingName = this.trainings.find(function (item) { return item.project_training_certification_mapping_id == obj.trainingName; });
                        trainerName = this.trainers.find(function (item) { return item.users_id == obj.trainerName; });
                        viewObj = {
                            trainingName: trainingName.training_certification_master.name,
                            trainerName: trainerName.users.name,
                            description: this.typeDetails.description,
                            trainingDate: new Date(this.typeDetails.training_date)
                        };
                        this.viewModel.push(viewObj);
                        details = this.typeDetails.training_recording_dtl;
                        _loop_1 = function (key, value) {
                            var obj_1 = {};
                            if (key != "EXTERNAL") {
                                var groupName = this_1.allGroups.find(function (item) { return item.project_group_mapping_id == key; });
                                if (groupName) {
                                    obj_1 = {
                                        "groupName": groupName.training_certification_group.name,
                                        "details": value,
                                        "id": key
                                    };
                                }
                                this_1.allGroupIDs.push(parseInt(key));
                            }
                            else {
                                obj_1 = {
                                    "groupName": this_1.additionalFarmer,
                                    "details": value,
                                    "id": key
                                };
                            }
                            this_1.currentTab.push(obj_1);
                        };
                        this_1 = this;
                        for (_i = 0, _a = Object.entries(details); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], value = _b[1];
                            _loop_1(key, value);
                        }
                        this.initialize(0);
                        this.groundForm.patchValue(obj);
                        this.groundForm.markAsPristine();
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    EditTrainingComponent.prototype.initialize = function (index) {
        for (var _i = 0, _a = this.currentTab; _i < _a.length; _i++) {
            var item = _a[_i];
            for (var _b = 0, _c = item.details; _b < _c.length; _b++) {
                var i = _c[_b];
                var farmerDetails = i.farmer_reg;
                farmerDetails.project_group_actor_mapping_id = i.project_group_actor_mapping_id;
                farmerDetails.id = i.id;
                this.groupFarmers.push(farmerDetails);
            }
        }
        this.groupClicked(index);
    };
    EditTrainingComponent.prototype.groupClicked = function (index, newGroup) {
        var _this = this;
        this.spinner.show();
        this.currentGroup = this.currentTab[index];
        this.currentGroupIndex = index;
        if (this.currentGroup.id != "EXTERNAL") {
            this.trainingService.getGroupFarmers(this.currentGroup.id).subscribe(function (result) {
                if (result['msg'] === 'success') {
                    _this.currentGroupFarmers = [];
                    for (var _i = 0, _a = result['data']; _i < _a.length; _i++) {
                        var item = _a[_i];
                        var farmerExistInOtherGroup = _this.checkFarmerInOtherGroup(item);
                        if (!farmerExistInOtherGroup) {
                            _this.currentGroupFarmers.push(item);
                        }
                    }
                    if (newGroup && _this.currentGroupFarmers.length == 0) {
                        _this.currentTab.splice(index, 1);
                        _this.commonService.showToast('warning', 'no_unique_farmer_found_this_group', {});
                        _this.groupClicked(0);
                    }
                    else if (newGroup) {
                        _this.commonService.showToast('success', 'unique_farmers_added_successfully', {});
                    }
                    for (var _b = 0, _c = _this.currentGroupFarmers; _b < _c.length; _b++) {
                        var id = _c[_b];
                        if (_this.allProjectFarmers.indexOf(id) < 0) {
                            _this.allProjectFarmers.push(id);
                        }
                        if (newGroup) {
                            _this.updatedFarmers.push(id);
                        }
                    }
                    _this.spinner.hide();
                }
            });
        }
        else {
            this.currentGroupFarmers = [];
            var _loop_2 = function (item) {
                var farmerExistInOtherGroup = this_2.checkFarmerInOtherGroup(item.farmer_reg);
                if (!farmerExistInOtherGroup) {
                    var exists = this_2.currentGroupFarmers.find(function (e) { return e.farmer_id === item.farmer_reg.farmer_id; });
                    if (!exists) {
                        this_2.currentGroupFarmers.push(item.farmer_reg);
                    }
                }
            };
            var this_2 = this;
            for (var _i = 0, _a = this.currentGroup.details; _i < _a.length; _i++) {
                var item = _a[_i];
                _loop_2(item);
            }
            if (newGroup && this.currentGroupFarmers.length == 0) {
                this.currentTab.splice(index, 1);
                this.commonService.showToast('warning', 'no_unique_farmer_found_this_selection', {});
                this.groupClicked(0);
            }
            else if (newGroup) {
                this.commonService.showToast('success', 'unique_farmers_added_successfully', {});
            }
            for (var _b = 0, _c = this.currentGroupFarmers; _b < _c.length; _b++) {
                var id = _c[_b];
                if (this.allProjectFarmers.indexOf(id) < 0) {
                    this.allProjectFarmers.push(id);
                    if (newGroup) {
                        this.updatedFarmers.push(id);
                    }
                }
            }
            this.spinner.hide();
        }
        this.selectedTrainings = [];
        this.selectedSpouse = [];
        this.selectedChild = [];
        for (var _d = 0, _e = this.currentGroup.details; _d < _e.length; _d++) {
            var item = _e[_d];
            if (item.training_attended == 1) {
                if (this.selectedTrainings.indexOf(item.farmer_id) < 0) {
                    this.selectedTrainings.push(item.farmer_id);
                }
                if (this.globalTrainings.indexOf(item.farmer_id) < 0) {
                    this.globalTrainings.push(item.farmer_id);
                }
            }
            if (item.spouse_attended == 1) {
                if (this.selectedSpouse.indexOf(item.farmer_id) < 0) {
                    this.selectedSpouse.push(item.farmer_id);
                }
                if (this.globalSpouse.indexOf(item.farmer_id) < 0) {
                    this.globalSpouse.push(item.farmer_id);
                }
            }
            if (item.children_attended_above_16 == 1) {
                if (this.selectedChild.indexOf(item.farmer_id) < 0) {
                    this.selectedChild.push(item.farmer_id);
                }
                if (this.globalChild.indexOf(item.farmer_id) < 0) {
                    this.globalChild.push(item.farmer_id);
                }
            }
        }
    };
    EditTrainingComponent.prototype.checkFarmerInOtherGroup = function (farmer) {
        var farmerExists = false;
        for (var _i = 0, _a = this.currentTab; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item.id != this.currentGroup.id) {
                for (var _b = 0, _c = item.details; _b < _c.length; _b++) {
                    var i = _c[_b];
                    if (farmer.farmer_id == i.farmer_reg.farmer_id) {
                        farmerExists = true;
                    }
                }
            }
        }
        return farmerExists;
    };
    EditTrainingComponent.prototype.groupSelected = function (e) {
        var _this = this;
        this.spinner.show();
        this.showAddButton();
        if (e.target.value != 0) {
            this.trainingService.getGroupFarmers(e.target.value).subscribe(function (result) {
                if (result['msg'] === 'success') {
                    var data = result['data'];
                    _this.groupFarmers = _this.groupFarmers.concat(data);
                    _this.groupFarmers = _this.groupFarmers.filter(function (v, i, a) { return a.findIndex(function (t) { return (t.farmer_id === v.farmer_id); }) === i; });
                    _this.spinner.hide();
                }
            });
        }
    };
    EditTrainingComponent.prototype.addNewGroupDD = function () {
        var groupNo = this.totalSelect.length + 1;
        this.groundForm.addControl("group" + groupNo, new FormControl('0', Validators.required));
        this.totalSelect.push("group" + groupNo);
    };
    EditTrainingComponent.prototype.removeNewGroupDD = function () {
        var groupNo = this.totalSelect.length;
        this.groundForm.removeControl("group" + groupNo);
        this.totalSelect.pop();
    };
    EditTrainingComponent.prototype.saveUpdateTraining = function () {
        var _this = this;
        this.spinner.show();
        var trainingName = this.groundForm['value'].trainingName;
        var trainerName = this.groundForm['value'].trainerName;
        var trainingDate = this.groundForm['value'].trainingDate;
        var description = this.groundForm['value'].description;
        var farmerData = [];
        var _loop_3 = function (item) {
            var farmer = this_3.groupFarmers.find(function (item2) { return item2.farmer_id == item.farmer_id; });
            var obj = {};
            if (farmer) {
                obj = {
                    "id": farmer.id,
                    "training_recording_hdr_id": this_3.hdrID,
                    "farmer_id": farmer.farmer_id,
                    "project_group_actor_mapping_id": farmer.project_group_actor_mapping_id,
                    "training_attended": item.hasOwnProperty('training_attended') ? item.training_attended : this_3.globalTrainings.indexOf(item.farmer_id) > -1 ? 1 : 0,
                    "spouse_attended": item.hasOwnProperty('spouse_attended') ? item.spouse_attended : this_3.globalSpouse.indexOf(item.farmer_id) > -1 ? 1 : 0,
                    "children_attended_above_16": item.hasOwnProperty('child_attended') ? item.child_attended : this_3.globalChild.indexOf(item.farmer_id) > -1 ? 1 : 0
                };
            }
            else {
                obj = {
                    "farmer_id": item.farmer_id,
                    "training_recording_hdr_id": this_3.hdrID,
                    "project_group_actor_mapping_id": item.project_group_actor_mapping_id,
                    "training_attended": item.hasOwnProperty('training_attended') ? item.training_attended : this_3.globalTrainings.indexOf(item.farmer_id) > -1 ? 1 : 0,
                    "spouse_attended": item.hasOwnProperty('spouse_attended') ? item.spouse_attended : this_3.globalSpouse.indexOf(item.farmer_id) > -1 ? 1 : 0,
                    "children_attended_above_16": item.hasOwnProperty('child_attended') ? item.child_attended : this_3.globalChild.indexOf(item.farmer_id) > -1 ? 1 : 0
                };
            }
            farmerData.push(obj);
        };
        var this_3 = this;
        for (var _i = 0, _a = this.updatedFarmers; _i < _a.length; _i++) {
            var item = _a[_i];
            _loop_3(item);
        }
        var documentData = this.uploadedTrainingFiles;
        var trainingToAdd = {
            "id": this.hdrID,
            "project_training_certification_mapping_id": trainingName,
            "trainer_id": trainerName,
            "training_date": trainingDate,
            "description": description,
            "status": "Active",
            "training_recording_dtl": farmerData,
            "training_documents_dtl": documentData
        };
        this.trainingService.updateTraining(trainingToAdd).subscribe(function (result) {
            _this.commonService.showToast('success', 'training_updated_successfully', {});
            _this.editTraining();
            _this.uploadedTrainingFiles = [];
        }, function (error) {
            _this.spinner.hide();
        });
    };
    EditTrainingComponent.prototype.navigateSection = function (direction) {
        var index = this.currentGroupIndex + direction;
        this.groupClicked(index);
    };
    EditTrainingComponent.prototype.manageFarmers = function (project, template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, _i, _a, item, _b, _c, item, _d, _e, i;
            return tslib_1.__generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        this.searchTextFarmers = "";
                        this.confirmFarmerModal = true;
                        if (!!this.loadingFarmers) return [3 /*break*/, 2];
                        this.spinner.show();
                        this.loadingFarmers = true;
                        this.managingFarmersForGroup = project;
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getFarmersByProjectBasicData(project.id).toPromise(),
                            ])];
                    case 1:
                        requests = _f.sent();
                        this.loadingFarmers = false;
                        this.spinner.hide();
                        if (requests[0]['msg'] === 'success') {
                            this.farmers = requests[0].data;
                            for (_i = 0, _a = this.farmers; _i < _a.length; _i++) {
                                item = _a[_i];
                                item.village_name = item.region_village.village_name;
                            }
                            this.sortFarmers();
                        }
                        for (_b = 0, _c = this.currentTab; _b < _c.length; _b++) {
                            item = _c[_b];
                            for (_d = 0, _e = item.details; _d < _e.length; _d++) {
                                i = _e[_d];
                                this.selectedFarmersCopy.push(i.farmer_reg.farmer_id);
                            }
                        }
                        if (this.farmers.length === 0) {
                            this.commonService.showToast('info', 'no_farmers_regd', {});
                        }
                        else {
                            this.farmersListPage = 1;
                            this.tooltipText = undefined;
                            this.manageFarmersModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
                        }
                        _f.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    EditTrainingComponent.prototype.confirmManageFarmers = function () {
        var toAddInExternal = [];
        var _loop_4 = function (item) {
            var checkFarmerExist = this_4.allProjectFarmers.find(function (item2) { return item2.farmer_id == item; });
            if (!checkFarmerExist) {
                var farmer = this_4.farmers.find(function (item2) { return item2.id == item; });
                var obj = {
                    "age": farmer.age,
                    "farmer_code": farmer.farmer_code,
                    "farmer_id": farmer.id,
                    "name": farmer.name,
                    "official_id": farmer.official_id,
                    "project_group_actor_mapping_id": null,
                    "sex": farmer.sex
                };
                toAddInExternal.push({ "farmer_reg": obj });
            }
        };
        var this_4 = this;
        for (var _i = 0, _a = this.selectedFarmers; _i < _a.length; _i++) {
            var item = _a[_i];
            _loop_4(item);
        }
        var findExternal = this.currentTab.find(function (item) { return item.id == "EXTERNAL"; });
        if (findExternal) {
            for (var _b = 0, toAddInExternal_1 = toAddInExternal; _b < toAddInExternal_1.length; _b++) {
                var item = toAddInExternal_1[_b];
                findExternal.details.push(item);
            }
            this.groupClicked(this.currentTab.indexOf(findExternal), true);
        }
        else {
            var obj = {
                "groupName": this.additionalFarmer,
                "details": toAddInExternal,
                "id": "EXTERNAL"
            };
            this.currentTab.push(obj);
            this.groupClicked(this.currentTab.length - 1, true);
        }
        this.showAddButton();
        this.commonService.showToast('success', 'unique_farmers_added_successfully', {});
        this.declineManageFarmers();
    };
    EditTrainingComponent.prototype.cancelManageFarmers = function () {
        var isEqual = false;
        for (var _i = 0, _a = this.selectedFarmers; _i < _a.length; _i++) {
            var val = _a[_i];
            if (this.selectedFarmersCopy.indexOf(val) < 0) {
                isEqual = true;
            }
        }
        if (isEqual) {
            this.confirmFarmerModal = false;
        }
        else {
            this.declineManageFarmers();
        }
    };
    EditTrainingComponent.prototype.declineManageFarmers = function () {
        this.selectedFarmers = [];
        this.farmers = [];
        this.managingFarmersForGroup = undefined;
        if (this.manageFarmersModal) {
            this.manageFarmersModal.hide();
        }
    };
    EditTrainingComponent.prototype.confirmCloseFarmerNo = function () {
        this.confirmFarmerModal = true;
    };
    // Toggle a particular farmer's selection status
    EditTrainingComponent.prototype.toggleThisFarmerSelect = function (id) {
        if (this.selectedFarmers.indexOf(id) > -1) {
            this.selectedFarmers = this.selectedFarmers.filter(function (item) { return item !== id; });
        }
        else {
            this.selectedFarmers.push(id);
        }
        this.setTooltipText();
    };
    EditTrainingComponent.prototype.toggleThisTrainingSelect = function (id) {
        if (this.selectedTrainings.indexOf(id) > -1) {
            this.selectedTrainings = this.selectedTrainings.filter(function (item) { return item !== id; });
            this.globalTrainings = this.globalTrainings.filter(function (item) { return item !== id; });
            this.updateFarmer(id, "training_attended", 0);
        }
        else {
            this.selectedTrainings.push(id);
            this.globalTrainings.push(id);
            this.updateFarmer(id, "training_attended", 1);
        }
    };
    EditTrainingComponent.prototype.toggleThisSpouseSelect = function (id) {
        if (this.selectedSpouse.indexOf(id) > -1) {
            this.selectedSpouse = this.selectedSpouse.filter(function (item) { return item !== id; });
            this.globalSpouse = this.globalSpouse.filter(function (item) { return item !== id; });
            this.updateFarmer(id, "spouse_attended", 0);
        }
        else {
            this.selectedSpouse.push(id);
            this.globalSpouse.push(id);
            this.selectedTrainings.push(id);
            this.updateFarmer(id, "spouse_attended", 1);
            this.selectedTrainings.push(id);
            this.updateFarmer(id, "training_attended", 1);
        }
    };
    EditTrainingComponent.prototype.toggleThisChildSelect = function (id) {
        if (this.selectedChild.indexOf(id) > -1) {
            this.selectedChild = this.selectedChild.filter(function (item) { return item !== id; });
            this.globalChild = this.globalChild.filter(function (item) { return item !== id; });
            this.updateFarmer(id, "child_attended", 0);
        }
        else {
            this.selectedChild.push(id);
            this.globalChild.push(id);
            this.selectedTrainings.push(id);
            this.updateFarmer(id, "child_attended", 1);
            this.selectedTrainings.push(id);
            this.updateFarmer(id, "training_attended", 1);
        }
    };
    EditTrainingComponent.prototype.updateFarmer = function (id, field, val) {
        this.showAddButton();
        var farmer = this.allProjectFarmers.find(function (item) { return item.farmer_id == id; });
        if (farmer) {
            var exist = this.updatedFarmers.find(function (item) { return item.farmer_id == id; });
            if (exist) {
                exist[field] = val;
            }
            else {
                farmer[field] = val;
                this.updatedFarmers.push(farmer);
            }
        }
    };
    // Select / deselect all farmers in the array
    EditTrainingComponent.prototype.toggleAllFarmers = function () {
        // If all farmers are already selected, then deselect all farmers
        if (this.selectedFarmers.length === this.farmers.length) {
            this.selectedFarmers = [];
        }
        // If all farmers are NOT already selected, select all the farmers
        else {
            this.selectedFarmers = [];
            for (var _i = 0, _a = this.farmers; _i < _a.length; _i++) {
                var val = _a[_i];
                this.selectedFarmers.push(val.id);
            }
        }
        this.setTooltipText();
    };
    EditTrainingComponent.prototype.toggleAllFarmersTrainings = function () {
        if (this.selectedTrainings.length === this.currentGroupFarmers.length) {
            this.selectedTrainings = [];
            var _loop_5 = function (val) {
                this_5.updateFarmer(val.farmer_id, "training_attended", 0);
                this_5.globalTrainings = this_5.globalTrainings.filter(function (item) { return item !== val.farmer_id; });
            };
            var this_5 = this;
            for (var _i = 0, _a = this.currentGroupFarmers; _i < _a.length; _i++) {
                var val = _a[_i];
                _loop_5(val);
            }
        }
        else {
            this.selectedTrainings = [];
            for (var _b = 0, _c = this.currentGroupFarmers; _b < _c.length; _b++) {
                var val = _c[_b];
                if (!val.is_discontinued) {
                    this.selectedTrainings.push(val.farmer_id);
                    this.updateFarmer(val.farmer_id, "training_attended", 1);
                    if (this.globalTrainings.indexOf(val.farmer_id) < 0) {
                        this.globalTrainings.push(val.farmer_id);
                    }
                }
            }
        }
    };
    EditTrainingComponent.prototype.toggleAllSpouses = function () {
        if (this.selectedSpouse.length === this.currentGroupFarmers.length) {
            this.selectedSpouse = [];
            var _loop_6 = function (val) {
                this_6.updateFarmer(val.farmer_id, "spouse_attended", 0);
                this_6.globalSpouse = this_6.globalSpouse.filter(function (item) { return item !== val.farmer_id; });
            };
            var this_6 = this;
            for (var _i = 0, _a = this.currentGroupFarmers; _i < _a.length; _i++) {
                var val = _a[_i];
                _loop_6(val);
            }
        }
        else {
            this.selectedSpouse = [];
            for (var _b = 0, _c = this.currentGroupFarmers; _b < _c.length; _b++) {
                var val = _c[_b];
                if (!val.is_discontinued) {
                    this.selectedSpouse.push(val.farmer_id);
                    this.updateFarmer(val.farmer_id, "spouse_attended", 1);
                    if (this.globalSpouse.indexOf(val.farmer_id) < 0) {
                        this.globalSpouse.push(val.farmer_id);
                    }
                }
            }
        }
    };
    EditTrainingComponent.prototype.toggleAllChildren = function () {
        if (this.selectedChild.length === this.currentGroupFarmers.length) {
            this.selectedChild = [];
            var _loop_7 = function (val) {
                this_7.updateFarmer(val.farmer_id, "child_attended", 0);
                this_7.globalChild = this_7.globalChild.filter(function (item) { return item !== val.farmer_id; });
            };
            var this_7 = this;
            for (var _i = 0, _a = this.currentGroupFarmers; _i < _a.length; _i++) {
                var val = _a[_i];
                _loop_7(val);
            }
        }
        else {
            this.selectedChild = [];
            for (var _b = 0, _c = this.currentGroupFarmers; _b < _c.length; _b++) {
                var val = _c[_b];
                if (!val.is_discontinued) {
                    this.selectedChild.push(val.farmer_id);
                    this.updateFarmer(val.farmer_id, "child_attended", 1);
                    if (this.globalChild.indexOf(val.farmer_id) < 0) {
                        this.globalChild.push(val.farmer_id);
                    }
                }
            }
        }
    };
    EditTrainingComponent.prototype.setTooltipText = function () {
        if (this.farmers && this.selectedFarmers) {
            if (this.selectedFarmers.length === this.farmers.length) {
                // Deselect farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.deselect + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.deselect + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
            else {
                // Select farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.select + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.select + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
        }
    };
    EditTrainingComponent.prototype.addGroup = function (template) {
        this.addGroupModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    EditTrainingComponent.prototype.submitNewGroup = function () {
        var _this = this;
        var groupName = this.allGroups.find(function (item) { return item.project_group_mapping_id == _this.newGroup; });
        if (groupName) {
            var obj = {
                "groupName": groupName.training_certification_group.name,
                "details": [],
                "id": this.newGroup
            };
            var findExternal = this.currentTab.find(function (item) { return item.id == "EXTERNAL"; });
            if (findExternal) {
                this.currentTab.splice(-1, 0, obj);
            }
            else {
                this.currentTab.push(obj);
            }
            var getIndex = this.currentTab.find(function (item) { return item.id == _this.newGroup; });
            this.groupClicked(this.currentTab.indexOf(getIndex), true);
            this.allGroupIDs.push(this.newGroup);
            this.showAddButton();
            this.closeGroupPopup();
        }
    };
    EditTrainingComponent.prototype.closeTypePopup = function () {
        if (this.newGroup == 0) {
            this.closeGroupPopup();
        }
        else {
            this.confirmGroupModal = false;
        }
    };
    EditTrainingComponent.prototype.closeGroupPopup = function () {
        if (this.addGroupModal) {
            this.addGroupModal.hide();
            this.newGroup = 0;
        }
    };
    EditTrainingComponent.prototype.confirmModalFn = function (decision) {
        if (decision == "Yes") {
            this.closeGroupPopup();
            this.confirmGroupModal = true;
        }
        else {
            this.confirmGroupModal = true;
        }
    };
    EditTrainingComponent.prototype.sortFarmers = function () {
        this.farmers.sort(function (a, b) {
            if (a.cr_date < b.cr_date) {
                return 1;
            }
            if (a.cr_date > b.cr_date) {
                return -1;
            }
            return 0;
        });
    };
    EditTrainingComponent.prototype.getGridFiltersFarmers = function () {
        var _this = this;
        var gridColums = ['farmer_code', 'name', 'official_id', 'age', 'village_name', 'village_collectors', 'cr_date'];
        var finalObj = {};
        if (this.farmers === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchTextFarmers;
            });
        }
        return finalObj;
    };
    /* file upload */
    EditTrainingComponent.prototype.handleTrainingFileInput = function (file) {
        var _this = this;
        var fileItem = file.item(0);
        var msg = this.commonService.ValidateFile(fileItem);
        if (msg && msg.length > 0) {
            this.toastrService.warning(msg);
            return;
        }
        console.log('file', fileItem);
        new Compressor(fileItem, {
            quality: environment.imageCompress.quality,
            maxWidth: environment.imageCompress.maxWidth,
            maxHeight: environment.imageCompress.maxHeight,
            mimeType: environment.imageCompress.mimeType,
            convertSize: environment.imageCompress.convertSize,
            success: function (result) {
                setFarmerImageData(result);
            },
            error: function (err) {
                console.log(err.message);
            },
        });
        var setFarmerImageData = function (result) {
            _this.commonService.blobToBase64(result).then(function (imageData) {
                console.log('imageData', imageData);
                var obj = {
                    "image_file": imageData,
                    "image_for": fileItem.name
                };
                _this.uploadedTrainingFiles.push(obj);
                _this.showAddButton();
            });
        };
    };
    EditTrainingComponent.prototype.showAddButton = function () {
        if (this.groundForm['value'].trainerName != 0 && this.groundForm['value'].trainingDate != null) {
            this.disableAddButton = false;
        }
        else {
            this.disableAddButton = true;
        }
    };
    EditTrainingComponent.prototype.removeTrainingFile = function (fileName, template) {
        var file = {
            isOld: false,
            file: fileName
        };
        this.fileToDelete = file;
        this.fileDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    EditTrainingComponent.prototype.removeTrainingFileOld = function (id, template) {
        var file = {
            isOld: true,
            file: id
        };
        this.fileToDelete = file;
        this.fileDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    EditTrainingComponent.prototype.confirmFileDelete = function (decision) {
        var _this = this;
        if (decision == "Yes") {
            console.log(this.fileToDelete.isOld);
            if (this.fileToDelete.isOld) {
                var id_1 = this.fileToDelete.file;
                this.trainingService.deleteTrainingDocument(id_1, {}).subscribe(function (result) {
                    _this.commonService.showToast('success', 'file_deleted_successfully', {});
                    _this.uploadedTrainingFilesOld = _this.uploadedTrainingFilesOld.filter(function (item) { return item.id !== id_1; });
                    _this.fileDeleteReset();
                }, function (error) {
                    _this.commonService.showToast('error', error, {});
                    _this.fileDeleteReset();
                });
            }
            else {
                var x = this.uploadedTrainingFiles.find(function (item) { return item.image_for == _this.fileToDelete.file; });
                var index = this.uploadedTrainingFiles.indexOf(x);
                if (index !== -1) {
                    this.uploadedTrainingFiles.splice(index, 1);
                }
                this.fileDeleteReset();
            }
        }
        else {
            this.fileDeleteReset();
        }
    };
    EditTrainingComponent.prototype.fileDeleteReset = function () {
        this.fileDeletePopup.hide();
        this.fileToDelete = null;
    };
    EditTrainingComponent.prototype.downloadImage = function (imageBase64, fileName) {
        this.downloadPdf(imageBase64, fileName);
    };
    EditTrainingComponent.prototype.downloadPdf = function (base64String, fileName) {
        var source = base64String;
        var link = document.createElement("a");
        link.href = source;
        link.download = fileName;
        link.click();
    };
    EditTrainingComponent.prototype.exitClicked = function (myForm, template) {
        if (myForm.dirty) {
            this.confirmModalPopup = false;
            this.confirmPopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
        else {
            var url = 'training-management/' + this.projectId;
            this.router.navigate([url]);
        }
    };
    EditTrainingComponent.prototype.confirmExitModal = function (item) {
        if (item === 'Yes') {
            this.confirmModalPopup = true;
            if (this.confirmPopup) {
                this.confirmPopup.hide();
            }
            var url = 'training-management/' + this.projectId;
            this.router.navigate([url]);
        }
        else if (item === 'No') {
            this.confirmModalPopup = true;
            if (this.confirmPopup) {
                this.confirmPopup.hide();
            }
        }
    };
    EditTrainingComponent.prototype.getGridFilters = function () {
        var _this = this;
        //new fields added for search village_name and vc_name
        var gridColums = ['farmer_code', 'name', 'age', 'sex', 'official_id'];
        var finalObj = {};
        if (this.farmers === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    EditTrainingComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    EditTrainingComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        this.isAscendingCode = sortBy !== 'farmer_code';
        this.isAscendingName = sortBy !== 'name';
        this.isAscendingAge = sortBy !== 'age';
        this.isAscendingSex = sortBy !== 'sex';
        this.isAscendingOfficialID = sortBy !== 'official_id';
        // Sort of new fields added
        if (sortBy === 'farmer_code' || sortBy === 'official_id' || sortBy === 'name' || sortBy === 'age' || sortBy === 'sex') {
            this.currentGroupFarmers.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
    };
    EditTrainingComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        this.isAscendingCode = sortBy === 'farmer_code';
        this.isAscendingName = sortBy === 'name';
        this.isAscendingAge = sortBy === 'age';
        this.isAscendingSex = sortBy === 'sex';
        this.isAscendingOfficialID = sortBy === 'official_id';
        // Sort of new fields added
        if (sortBy === 'farmer_code' || sortBy === 'official_id' || sortBy === 'name' || sortBy === 'age' || sortBy === 'sex') {
            this.currentGroupFarmers.sort(function (a, b) {
                if (a && a[sortBy] != null && b && b[sortBy] != null) {
                    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                        return 1;
                    }
                    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
        }
    };
    EditTrainingComponent.prototype.farmerSortBy = function (sortOn) {
        this.isAscendingFarmer ? this.ascendicFarmer(sortOn) : this.descendicFarmer(sortOn);
    };
    EditTrainingComponent.prototype.ascendicFarmer = function (sortBy) {
        this.isAscendingFarmer = false;
        this.isAscendingIdFarmer = sortBy !== 'id';
        this.isAscendingNameFarmer = sortBy !== 'name';
        this.isAscendingTelFarmer = sortBy !== 'tel_number';
        this.isAscendingDateFarmer = sortBy !== 'cr_date';
        this.isAscendingAgeFarmer = sortBy !== 'age';
        this.isAscendingSexFarmer = sortBy !== 'sex';
        // Sort of new fields added
        this.isAscendingVillageFarmer = sortBy !== 'village_name';
        this.isAscendingVCFarmer = sortBy !== 'village_collectors';
        this.isAscendingOfficialID = sortBy !== 'official_id';
        // Sort of new fields added
        if (sortBy === 'name') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                    return 1;
                }
                if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'village_name') {
            this.farmers.sort(function (a, b) {
                if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
                    return 1;
                }
                if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    EditTrainingComponent.prototype.descendicFarmer = function (sortBy) {
        this.isAscendingFarmer = true;
        this.isAscendingIdFarmer = sortBy === 'id';
        this.isAscendingNameFarmer = sortBy === 'name';
        this.isAscendingTelFarmer = sortBy === 'tel_number';
        this.isAscendingDateFarmer = sortBy === 'cr_date';
        this.isAscendingAgeFarmer = sortBy === 'age';
        this.isAscendingSexFarmer = sortBy === 'sex';
        // Sort of new fields added
        this.isAscendingVillageFarmer = sortBy === 'village_name';
        this.isAscendingVCFarmer = sortBy === 'village_collectors';
        this.isAscendingOfficialID = sortBy === 'official_id';
        // Sort of new fields added
        if (sortBy === 'name') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
                    return 1;
                }
                if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id' || sortBy === 'age' || sortBy === 'sex' || sortBy === 'official_id' || sortBy === 'cr_date') {
            this.farmers.sort(function (a, b) {
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'village_name') {
            this.farmers.sort(function (a, b) {
                if (a.region_village.village_name.toLowerCase() > b.region_village.village_name.toLowerCase()) {
                    return 1;
                }
                if (a.region_village.village_name.toLowerCase() < b.region_village.village_name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    EditTrainingComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    EditTrainingComponent.prototype.trackBytraining = function (index, dataItem) {
        return dataItem.id;
    };
    EditTrainingComponent.prototype.trackBytrainer = function (index, dataItem) {
        return dataItem.id;
    };
    EditTrainingComponent.prototype.onKeydownHandler = function (event) {
        this.closeTypePopup();
    };
    return EditTrainingComponent;
}());
export { EditTrainingComponent };
