  <div class="bg">
      <div class="login-p2f-logo">
        <img src="assets/images/path2farm-logo-hor-white.webp" alt="path2farm logo"/>
      </div>
      <div class="login-fir-logo login-p2froots-img">
        <img src="assets/images/fir-logo.webp" alt="firmenich logo"/>
      </div>
  </div>

<div class="login-right">
  <div class="login-p2froots-logo text-right">
    <img class="login-p2froots-img" src="assets/images/p2f-roots-logo.webp" alt="p2f roots logo"/>
    <img class="login-p2froots-img-small" src="assets/images/p2f-roots-logo-white.webp" alt="p2f roots logo"/>
  </div>
        <div class="reset-screen forget" id="forgot" style="display:block;">
          <div class="login-info forgot-pwd">
            <h3 class="login-header text-center" i18n="@@forgotPassword">
              Forgot Your Password?
            </h3>
            <div class="forgot-body text-center">
              <form name="forgetForm" (ngSubmit)="sendEmail()" #forgetForm="ngForm" novalidate  autocomplete="off">
                <span class="desc">
                  <span i18n="@@enterUserIdAndSendTempNewPassInRegisterMail">Enter your User ID to help us reset your password.</span>
                </span>
                <br /><br />
                <div class="form-group">
                  <label for="username" i18n="@@enterYourUserId">Enter Your User ID</label>
                  <input class="form-control-login pl-2 pr-2" autofocus appAutofocus i18n-placeholder="@@enterYourUsername" type="text" autofocus name="username"
                    required minlength="6" maxlength="10" [(ngModel)]="username" #f_username="ngModel"  [value]="f_username.value?.toUpperCase()">
                  <span class="separator"></span>
                </div>
                <button type="submit" class="btn-login-blue mr-3 inline-block" [disabled]="forgetForm.form.invalid" title="Send" i18n-title="@@send" i18n="@@send">Send</button>
                <button class="button" class="btn-login-blank inline-block" title="Back" i18n-title="@@back" i18n="@@back" routerLink="/">Back</button>
              </form>
            </div>
          </div>
        </div>

    </div>
<div class="clearfix"></div>
