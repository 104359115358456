/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../header/header.component.ngfactory";
import * as i2 from "../header/header.component";
import * as i3 from "../../services/authentication.service";
import * as i4 from "@angular/router";
import * as i5 from "../../services/user.service";
import * as i6 from "../../services/login.service";
import * as i7 from "@angular/forms";
import * as i8 from "../../services/common.service";
import * as i9 from "../../services/storage.service";
import * as i10 from "@angular/common";
import * as i11 from "ngx-toastr";
import * as i12 from "ngx-tour-ngx-bootstrap";
import * as i13 from "ngx-bootstrap/modal/bs-modal.service";
import * as i14 from "../../services/project.service";
import * as i15 from "@ngx-translate/core";
import * as i16 from "./mdm.component";
import * as i17 from "@angular/platform-browser";
import * as i18 from "../../services/suppliers.service";
var styles_MdmComponent = [".scroll-to-top[_ngcontent-%COMP%] {\n      position: fixed;\n      bottom: 15px;\n      right: 15px;\n      opacity: 0;\n      transition: all .2s ease-in-out;\n     }\n     .show-scrollTop[_ngcontent-%COMP%] {\n      opacity: 1;\n      transition: all .2s ease-in-out;\n     }\n     .box_mh[_ngcontent-%COMP%]{\n       min-height:100px;\n     }"];
var RenderType_MdmComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_MdmComponent, data: {} });
export { RenderType_MdmComponent as RenderType_MdmComponent };
function View_MdmComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [["class", "col-lg-2 col-md-2 col-sm-6 col-xs-12"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navaigationMenu(((_v.context.$implicit == null) ? null : _v.context.$implicit.MDM.master_code)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "span", [["class", "box box_mh"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "photo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [["style", "max-height: 78px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.MDM.photo_path); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.MDM.name; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.MDM.description; _ck(_v, 6, 0, currVal_2); }); }
export function View_MdmComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, [["window", "data-synced"], ["window", "data-added"], ["window", "error-caching"], ["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("window:data-synced" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).updateSyncStatusShown($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:data-added" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).dataAdded($event) !== false);
        ad = (pd_1 && ad);
    } if (("window:error-caching" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).errorCaching($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:keydown.escape" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onKeydownHandler($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(1, 245760, null, 0, i2.HeaderComponent, [i3.AuthenticationService, i4.Router, i5.UserService, i6.LoginService, i7.FormBuilder, i8.CommonService, i9.StorageService, i10.Location, i0.PLATFORM_ID, i0.ChangeDetectorRef, i11.ToastrService, i12.ɵa, i13.BsModalService, i14.ProjectService, i15.TranslateService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "project-header clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 7, "nav", [["aria-label", "breadcrumb"], ["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "li", [["class", "breadcrumb-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [["class", "btn-home"], ["routerLink", "/dashboard"], ["title", "Home"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 0, "img", [["alt", "Home"], ["src", "assets/images/home.webp"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "li", [["aria-current", "page"], ["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵeld(11, 0, null, null, 9, "div", [["class", "page-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 8, "div", [["class", "content-wrapper background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 7, "div", [["class", "myprojects"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "h3", [["class", "project-head"]], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), (_l()(), i0.ɵeld(16, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 3, "div", [["class", "list-project"], ["id", "myList"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 2, "ul", [["class", "mdm-box clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MdmComponent_1)), i0.ɵdid(20, 278528, null, 0, i10.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "/dashboard"; _ck(_v, 7, 0, currVal_0); var currVal_3 = _co.mdmData; _ck(_v, 20, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.typeOfAdministration; _ck(_v, 10, 0, currVal_1); var currVal_2 = ((_co.nav == null) ? null : _co.nav.menu_name); _ck(_v, 15, 0, currVal_2); }); }
export function View_MdmComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-mdm", [], null, null, null, View_MdmComponent_0, RenderType_MdmComponent)), i0.ɵdid(1, 114688, null, 0, i16.MdmComponent, [i4.ActivatedRoute, i8.CommonService, i4.Router, i11.ToastrService, i15.TranslateService, i17.DOCUMENT, i18.SuppliersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MdmComponentNgFactory = i0.ɵccf("app-mdm", i16.MdmComponent, View_MdmComponent_Host_0, {}, {}, []);
export { MdmComponentNgFactory as MdmComponentNgFactory };
