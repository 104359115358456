import {Observable, forkJoin, of } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';


import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';

import { ProfileService } from '../services/profile.service';
import { ProjectService } from '../services/project.service';
import { FarmerService } from '../services/farmer.service';
import { Farmer } from '../models/farmer';

  // mapping: any[];
interface IReturn {
  snapshot: any[];
  project: any;
  farmer: any[];
  errormsg?: any;
}

@Injectable()
export class ProfileResolver implements Resolve<IReturn> {


  constructor(private readonly projectService: ProjectService, private readonly profileService: ProfileService,
    private readonly farmerService: FarmerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IReturn> {

  let services: Observable<any>[];

  if (navigator.onLine) {
    services = [this.projectService.getProjectBasicInfo(route.params['projectId'] || 0), this.farmerService.GetFarmer(route.params['id'] || 0), this.farmerService.GetFarmerMaster()];
   } else {
     services = [of([]), of([])];
   }

  return  forkJoin ([
        this.profileService.GetSnapshot(route.params['projectId'] || 0).pipe(map((results) => results), catchError(e => of([]))),
        this.projectService.getProjectBasicInfo(route.params['projectId'] || 0).pipe(map((results) => results), catchError(e => of([]))),
        this.farmerService.GetFarmer(route.params['id'] || 0).pipe(map((results) => results), catchError(e => of([]))),
        this.farmerService.GetFarmerMaster().pipe(map((results) => results), catchError(e => of([]))),
        this.projectService.getProjectProfilingData(route.params['projectId'] || 0).pipe(map((results) => results), catchError(e => of([]))),
        this.projectService.getProjectDashboardData(route.params['projectId'] || 0).pipe(map((results) => results), catchError(e => of([]))),

      ]).pipe(
      map(results => ({
        snapshot: results[0],
        project: {...results[1]['data'][0], ...results[4]['data'][0], ...results[5]['data'][0]},
        farmer: results[2],
        master: results[3]
      }))
    );
    }

}
