<ng4-loading-spinner [loadingText]="loadingTextValue"></ng4-loading-spinner>
<div>
    <app-header></app-header>
    <div class="project-header clearfix" style="padding-left: 82px;">  
      <nav aria-label="breadcrumb" class="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-icon" *ngIf="!isTemplate" >
            <span class="btn-back" title="Back" i18n-title="@@back"   routerLink="/questionnaire/edit/{{projectId}}/{{surveyId}}" aria-current="page">
              <i class="utilicon utilicon-arrow-alt-left"></i>
            </span>
          </li>
          <li class="breadcrumb-icon" *ngIf="isTemplate" >
            <span class="btn-back" title="Back" i18n-title="@@back"   routerLink="/questionnaires/{{projectId}}" aria-current="page">
              <i class="utilicon utilicon-arrow-alt-left"></i>
            </span>
          </li>
          <li class="breadcrumb-icon">
            <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
          </li>
          <li class="breadcrumb-item cursorPointer" aria-current="page">
              <a (click)="getBackProjectFilter(project?.name, project?.id)" [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</a>
          </li>
          <li class="breadcrumb-item cursorPointer" routerLink="/questionnaires/{{projectId}}" aria-current="page">
            <span i18n-title="@@surveyManagement" title="SURVEY MANAGEMENT">...</span>
          </li>
          <li *ngIf="!isTemplate" class="breadcrumb-item cursorPointer" routerLink="/questionnaire/edit/{{projectId}}/{{surveyId}}" aria-current="page">
            <span i18n-title="@@manageQuestionnaire" title="MANAGE QUESTIONNAIRE">...</span>
          </li>
          <li *ngIf="isTemplate" class="breadcrumb-item active" aria-current="page" i18n="@@previewTemplate">Preview Template</li>
          <li *ngIf="!isTemplate" class="breadcrumb-item active" aria-current="page" i18n="@@printSurvey">Preview Profiling</li>
        </ol>
      </nav>
      <div class="template-activate">
        <!-- <span class="question-selected cursorPointer mr-2" (click)="useTemplateConfirmation(useTemplateConfirmationModal);$event.stopPropagation()" i18n="@@useTemplate">Use Template</span> -->
      </div>
      <ng-template #useTemplateConfirmationModal>
        <div class="modal-header">
          <h4 class="modal-title pull-left" i18n="@@createQuestionnaire">
            Create questionnaire
          </h4>
          <button type="button" class="close pull-right" title="Close" i18n-title="@@close" aria-label="Close" (click)="closeUseTemplateConfirmationModal(); $event.stopPropagation()"
          [disabled]="savingTemplate">
            <i class="utilicon utilicon-times-light"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="card-body">
              <p class="identifier">{{template?.name}} will be used to create the questionnaire for {{survey.name}}{{survey.survey_type_sequence ? ' ' + survey.survey_type_sequence : ''}}</p>
              <div class="text-center pb-2">
                <button type="button" class="btn btn-primary" (click)="useTemplate(); $event.stopPropagation()" i18n="@@confirm">Confirm</button>
                <button type="button" class="btn btn-secondary ml-4" (click)="closeUseTemplateConfirmationModal(); $event.stopPropagation()" i18n="@@cancel">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="clearfix"></div>
    <div class="content-wrap content-wrapper profiling-screen">
      <div name="projectForm" class="form-design">
        <div class="form-box">
    
          <div class="farmer-profile-div clearfix">
    
            <div class="farmer-tab-content" id="pills-tabContent2">
              <div class="tab-pane fade in active" id="pills-fp" role="tabpanel" aria-labelledby="pills-fp-tab" *ngIf="!reloadingQuestionnaire">
                <div class="clearfix"></div>
                <div class="border-middle"></div>     
                  <ul *ngIf="questionnaire && questionnaire != 'noSurveys'" class="nav nav-pills fixed-tabs" id="pills-tab2" role="tablist">
                    <li class="nav-item" *ngFor="let tab of questionnaire; let i = index">
                      <a class="nav-link" *ngIf="!tab.removed_from_questionnaire" [ngClass]="{'active': currentTab?.id ==tab?.id}" id="pills-fp-tab" data-toggle="pill"
                        [href]="'#' + tab?.tabKey" role="tab" aria-selected="true" (click)="tabClicked(i, 0)">
                        {{tab?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}
                      </a>
                    </li>
                  </ul>
                  <ul class="nav nav-pills sub-tab-fixed" id="pills-tab" role="tablist" #widgetsContent>
                    <button class="prev-btn" (click)="leftScroll()">
                      <i class="utilicon utilicon-chevron-left"> </i>
                    </button>
                    <li class="nav-item " *ngFor="let section of currentTab?.sections; let sectionIndex = index;">
                      <a class="nav-link" *ngIf="!section.removed_from_questionnaire" [ngClass]="{'active' : currentSection?.id == section.id}" id="pills-personal-tab"
                        data-toggle="pill" role="tab" aria-selected="true" (click)="sectionClicked(sectionIndex)">
                        {{section?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}
                      </a>
                    </li>
                    <button class="next-btn" (click)="rightScroll()">
                      <i class="utilicon utilicon-chevron-right"> </i>
                    </button>
                  </ul>
                  <div id="rightPanel">
                    <div class="farmer-tab-content card" id="pills-tabContent"  style="top: 5px;">
                      <div class="tab-pane" [id]="currentSection?.id" role="tabpanel" aria-labelledby="pills-personal-tab">
                        <!-- <form enctype="multipart/form-data" [formGroup]="sectionForm" *ngIf="showSectionForm"> -->
                          <div class="clearfix" (click)="sidemenuOpen = false">
                            <div class="com-sm-12"></div>
                            <div class="row">
                              <ng-container *ngFor="let question of currentSection?.questions; let questionIndex = index">
                                <div *ngIf="!question.removed_from_questionnaire" class="col-lg-6 col-md-6">
                                  <div class="mb-3" 
                                  [class.display-logic-key]="displayLogicDisplayIfKey?.code == question.code">
                                    <label [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')">
                                      <span>{{question?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}</span>
                                      <ng-container *ngFor="let validation of question?.survey_q_asset_validations">
                                        <span class="bold red ml-2" *ngIf="validation?.validation_key=='mandatory' && validation?.validation_value=='true'">*</span>
                                      </ng-container>
                                    </label>                                    
                                    <div [style.display]="question.include_uom_question ? 'flex' : 'block'">
                                      <select [disabled]="question.disable_input" class="form-control" *ngIf="question.qa_subtype == 'select' && !isTemplate">
                                        <option value="" i18n="@@select">Select</option>
                                        <ng-container *ngFor="let option of question?.options">
                                          <option disabled  *ngIf="!option.removed_from_questionnaire && !option.deleted" [value]="option.id">
                                            {{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}
                                          </option>
                                        </ng-container>
                                      </select>
                                      <div *ngIf="question.qa_subtype == 'select' && isTemplate" class="mt-2">
                                        <div *ngFor="let option of question?.options" class="mr-4 inline-block">
                                          <span *ngIf="!option.removed_from_questionnaire">
                                            <input disabled type="radio" class="mr-1">
                                            <span [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}</span>
                                          </span>
                                        </div>
                                      </div>
                                      <input disabled *ngIf="question.qa_subtype == 'text' || question.qa_subtype == 'number'" disabled class="form-control" (change)="sectionFormChangeHandler($event, question)" style="background-color: rgba(255, 255, 255, 0.2);">
                                      <input disabled *ngIf="question.qa_subtype == 'file'" type="file" disabled class="form-control" style="padding-top: 5px;">
                                      <div class="dateIcon" *ngIf="question.qa_subtype == 'date'">
                                        <input bsDatepicker disabled placement="top" class="form-control" />
                                        <i class="utilicon utilicon-calendar-alt" style="color: #ccc;"></i>
                                      </div>
                                      <div *ngIf="question.qa_subtype == 'multiselect'" class="mt-2">
                                        <div *ngFor="let option of question?.options" class="mr-4 inline-block">
                                            <span *ngIf="!option.removed_from_questionnaire">
                                              <input type="checkbox" disabled class="mr-1">
                                              <span [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}</span>
                                            </span>
                                        </div>
                                      </div>
                                      <span *ngIf="question.include_uom_question" [ngStyle]="{ width: question.uom_questions.length > 1 ? '70%' : '50%' }" class="ml-2">
                                        <span *ngFor="let uom of question.uom_questions; let i = index">
                                          <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                          <select class="form-control" [ngClass]="{'small-select' : question.uom_questions.length > 1}">
                                            <option *ngFor="let uom_option of uom?.options" [value]="uom_option.id">{{uom_option?.uom}}</option>
                                          </select>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                            <div *ngFor="let subsection of currentSection?.subsections; let subsectionIndex = index">
                              <div *ngIf="!subsection.removed_from_questionnaire">
                                <div class="sectionDiv clearfix">
                                  <h6 [ngClass]="(subsection.removed_from_questionnaire ? 'deleted':'')">{{subsection?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}<i class="fa fa-list-alt ml-2" aria-hidden="true"></i></h6>                                  
                                </div>
                                <div class="row no-guttets">
                                  <ng-container *ngFor="let q of subsection.questions; let qIndex = index;">
                                    <div *ngIf="!q.removed_from_questionnaire" class="col-lg-6 col-md-6">
                                      <div class="mb-3"
                                      [class.display-logic-key]="displayLogicDisplayIfKey?.code == q.code">
                                        <label [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')">
                                          <span>{{q?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}</span>
                                          <ng-container *ngFor="let validation of q?.survey_q_asset_validations">
                                            <span class="bold red ml-2" *ngIf="validation?.validation_key=='mandatory' && validation?.validation_value=='true'">*</span>
                                          </ng-container>
                                        </label>
                                        <div [style.display]="q.include_uom_question ? 'flex' : 'block'">
                                          <select [disabled]="q.disable_input" class="form-control" *ngIf="q.qa_subtype == 'select' && !isTemplate">
                                            <option value="" i18n="@@select">Select</option>
                                            <ng-container *ngFor="let option of q?.options">
                                              <option disabled  *ngIf="!option.removed_from_questionnaire && !option.deleted" [value]="option.id">
                                                {{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}
                                              </option>
                                            </ng-container>
                                          </select>
                                          <div *ngIf="q.qa_subtype == 'select' && isTemplate" class="mr-2" >
                                            <div *ngFor="let option of q?.options" class="mr-4 inline-block">
                                              <span *ngIf="!option.removed_from_questionnaire">
                                                <input disabled type="radio" class="mr-1">
                                                <span [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}</span>
                                              </span>
                                            </div>
                                          </div>
                                          <input disabled class="form-control" *ngIf="q.qa_subtype == 'text'"  (change)="sectionFormChangeHandler($event, q)">
                                          <input disabled class="form-control" *ngIf="q.qa_subtype == 'number'"  (change)="sectionFormChangeHandler($event, q)">
                                          <input disabled *ngIf="q.qa_subtype == 'file'" type="file" disabled class="form-control" style="padding-top: 5px;">
                                          <div class="dateIcon" *ngIf="q.qa_subtype == 'date'">
                                            <input bsDatepicker disabled placement="top" class="form-control" />
                                            <i class="utilicon utilicon-calendar-alt" style="color: #ccc;"></i>
                                          </div>
                                          <div *ngIf="q.qa_subtype == 'multiselect'" class="mr-2">
                                            <div *ngFor="let option of q?.options" class="mr-4 inline-block">
                                              <span *ngIf="!option.removed_from_questionnaire">
                                                <input type="checkbox" disabled class="mr-1" />
                                                <span [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}</span>
                                              </span>
                                            </div>
                                          </div>
                                          <span *ngIf="q.include_uom_question" [ngStyle]="{ width: q.uom_questions.length > 1 ? '70%' : '50%' }" class="ml-2">
                                            <span *ngFor="let uom of q.uom_questions; let i = index">
                                              <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                              <select class="form-control" [ngClass]="{'small-select' : q.uom_questions.length > 1}">
                                                <option *ngFor="let uom_option of uom?.options" [value]="uom_option.id">{{uom_option?.uom}}</option>
                                              </select>
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
  
                            <div *ngFor="let table of currentSection?.tables; let tableIndex = index">
                              <div *ngIf="!table.removed_from_questionnaire">
                                <div class="sectionDiv clearfix">
                                  <h6 [ngClass]="(table.removed_from_questionnaire ? 'deleted':'')">{{table?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}} <i class="fa fa-table ml-2" aria-hidden="true"></i></h6>                                 
                                </div>
                                <div class="row no-guttets">
                                  <div class="col-lg-12 col-md-12 ">
                                    <div *ngIf="table.qa_subtype == 'question_cols'">
                                      <table class="options-table">
                                        <thead>
                                          <tr>
                                            <ng-container *ngFor="let question of table.questions">
                                              <th *ngIf="!question.removed_from_questionnaire">
                                                <span>{{question.survey_q_asset_labels[currentLocaleLanguageIdIndex].label}}</span>
                                                <ng-container *ngFor="let validation of question?.survey_q_asset_validations">
                                                  <span class="bold red ml-2" *ngIf="validation?.validation_key=='mandatory' && validation?.validation_value=='true'">*</span>
                                                </ng-container>
                                              </th>
                                            </ng-container>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <ng-container *ngFor="let question of table.questions">            
                                            <td *ngIf="!question.removed_from_questionnaire">
                                              <div [style.display]="question.include_uom_question ? 'flex' : 'block'">
                                                <select [disabled]="question.disable_input" class="form-control" *ngIf="question.qa_subtype == 'select' && !isTemplate">
                                                  <option value="" i18n="@@select">Select</option>
                                                  <ng-container *ngFor="let option of question?.options">
                                                    <option disabled  *ngIf="!option.removed_from_questionnaire && !option.deleted" [value]="option.id">
                                                      {{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}
                                                    </option>
                                                  </ng-container>
                                                </select>
                                                <div *ngIf="question.qa_subtype == 'select' && isTemplate" class="mt-2">
                                                  <div *ngFor="let option of question?.options" class="mr-4 inline-block">
                                                    <span *ngIf="!option.removed_from_questionnaire">
                                                      <input disabled type="radio" class="mr-1">
                                                      <span [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}</span>
                                                    </span>
                                                  </div>
                                                </div>
                                                <input  *ngIf="question.qa_subtype == 'text' || question.qa_subtype == 'number'" disabled class="form-control">
                                                <input disabled *ngIf="question.qa_subtype == 'file'" type="file" disabled class="form-control" style="padding-top: 5px;">
                                                <div class="dateIcon" *ngIf="question.qa_subtype == 'date'">
                                                  <input bsDatepicker disabled placement="top" class="form-control" />
                                                  <i class="utilicon utilicon-calendar-alt" style="color: #ccc;"></i>
                                                </div>
                                                <div *ngIf="question.qa_subtype == 'multiselect'" class="mt-2">
                                                  <div *ngFor="let option of question?.options" class="mr-4 inline-block">
                                                      <span *ngIf="!option.removed_from_questionnaire">
                                                        <input type="checkbox" disabled class="mr-1">
                                                        <span class="ml-2">{{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}</span>
                                                      </span>
                                                  </div>
                                                </div>
                                                <span *ngIf="question.include_uom_question" [ngStyle]="{ width: question.uom_questions.length > 1 ? '70%' : '50%' }" class="ml-2">
                                                  <span *ngFor="let uom of question.uom_questions; let i = index">
                                                    <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                                    <select class="form-control" [ngClass]="{'small-select' : question.uom_questions.length > 1}">
                                                      <option *ngFor="let uom_option of uom?.options" [value]="uom_option.id">{{uom_option?.uom}}</option>
                                                    </select>
                                                  </span>
                                                </span>
                                              </div>
                                            </td>
                                            </ng-container>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div *ngIf="table.qa_subtype == 'question_rows'">
                                      <table class="options-table">
                                        <ng-container *ngFor="let question of table.questions"> 
                                          <tr *ngIf="!question.removed_from_questionnaire">      
                                            <td style="width:50%">
                                              <span class="bold">{{question.survey_q_asset_labels[currentLocaleLanguageIdIndex].label}}</span>
                                              <ng-container *ngFor="let validation of question?.survey_q_asset_validations">
                                                <span class="bold red ml-2" *ngIf="validation?.validation_key=='mandatory' && validation?.validation_value=='true'">*</span>
                                              </ng-container>
                                            </td>      
                                            <td style="width:50%">
                                              <div [style.display]="question.include_uom_question ? 'flex' : 'block'">
                                                <select class="form-control" [disabled]="question.disable_input" *ngIf="question.qa_subtype == 'select' && !isTemplate">
                                                  <option value="" i18n="@@select">Select</option>
                                                  <ng-container *ngFor="let option of question?.options">
                                                    <option disabled  *ngIf="!option.removed_from_questionnaire && !option.deleted" [value]="option.id">
                                                      {{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}
                                                    </option>
                                                  </ng-container>
                                                </select>
                                                <div *ngIf="question.qa_subtype == 'select' && isTemplate" class="mt-2">
                                                  <div *ngFor="let option of question?.options" class="mr-4 inline-block">
                                                    <span *ngIf="!option.removed_from_questionnaire">
                                                      <input disabled type="radio" class="mr-1">
                                                      <span [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}</span>
                                                    </span>
                                                  </div>
                                                </div>
                                                <input  *ngIf="question.qa_subtype == 'text' || question.qa_subtype == 'number'" disabled class="form-control">
                                                <input disabled *ngIf="question.qa_subtype == 'file'" type="file" disabled class="form-control" style="padding-top: 5px;">
                                                <div class="dateIcon" *ngIf="question.qa_subtype == 'date'">
                                                  <input bsDatepicker disabled placement="top" class="form-control" />
                                                  <i class="utilicon utilicon-calendar-alt" style="color: #ccc;"></i>
                                                </div>
                                                <div *ngIf="question.qa_subtype == 'multiselect'" class="mt-2">
                                                  <div *ngFor="let option of question?.options" class="mr-4 inline-block">
                                                      <span *ngIf="!option.removed_from_questionnaire">
                                                        <input type="checkbox" disabled class="mr-1">
                                                        <span class="ml-2">{{option?.survey_q_asset_labels[currentLocaleLanguageIdIndex]?.label}}</span>
                                                      </span>
                                                  </div>
                                                </div>
                                                <span *ngIf="question.include_uom_question" [ngStyle]="{ width: question.uom_questions.length > 1 ? '70%' : '50%' }" class="ml-2">
                                                  <span *ngFor="let uom of question.uom_questions; let i = index">
                                                    <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                                    <select class="form-control" [ngClass]="{'small-select' : question.uom_questions.length > 1}">
                                                      <option *ngFor="let uom_option of uom?.options" [value]="uom_option.id">{{uom_option?.uom}}</option>
                                                    </select>
                                                  </span>
                                                </span>
                                              </div>
                                            </td>
                                          </tr>
                                        </ng-container>
                                      </table>
                                    </div>       
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="fixed-actions form-box actions">
                          <button [disabled]="showHideBackButton" class="btn btn-secondary" (click)="navigateSection(-1)">
                            <span i18n="@@back">Back</span>
                          </button>
                          <button [disabled]="showHideNextButton" class="btn btn-secondary" (click)="navigateSection(1)">
                            <span i18n="@@next">Next</span>
                          </button>
                          <button *ngIf="isTemplate" class="btn btn-secondary" routerLink="/questionnaires/{{projectId}}">
                            <span  i18n="@@exit">Exit</span>
                          </button>
                          <button *ngIf="!isTemplate" class="btn btn-secondary" routerLink="/questionnaire/edit/{{projectId}}/{{surveyId}}">
                            <span i18n="@@exit">Exit</span>
                          </button>
                        </div>
                      <!-- </form> -->
                    </div>
                  </div>
                </div>                  
              </div>              
            </div>
        </div>
      </div>
    </div>
  </div>  
  </div>
  