import { Component, TemplateRef, ViewChild, OnInit, Inject, HostListener, ChangeDetectorRef, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import { Observable, fromEvent, merge, of, Subject } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { TourService } from 'ngx-tour-ngx-bootstrap';

import {AuthenticationService} from '../../services/authentication.service';
import {UserService} from '../../services/user.service';
import {LoginService} from '../../services/login.service';
import { CommonService } from '../../services/common.service';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var idbApp: any;
declare var $ :any;


import { isPlatformBrowser } from '@angular/common';
import { ProjectService } from '../../services/project.service';




@Component({
  selector: 'app-header',
  styles: [`
    .nav-icon-menu {
      padding: 8px 16px;
      width: 65px;
    }

    .nav-icon-menu:after,
    .nav-icon-menu:before,
    .nav-icon-menu div {
      background-color: #333;
      border-radius: 3px;
      content: '';
      display: block;
      height: 3px;
      margin: 9px 0;
      transition: all .2s ease-in-out;
    }
    .nav-icon-menu:hover:before {
      transform: translateY(12px) rotate(135deg);
    }

    .nav-icon-menu:hover:after {
      transform: translateY(-12px) rotate(-135deg);
    }

    .nav-icon-menu:hover div {
      transform: scale(0);
    }

    .nav-icon-close {
      padding: 8px 16px;
      width: 65px;
    }

    .nav-icon-close:after,
    .nav-icon-close:before,
    .nav-icon-close div {
      background-color: #24283F;
      border-radius: 3px;
      content: '';
      display: block;
      height: 3px;
      margin: 9px 0;
      transition: all .2s ease-in-out;
    }
    .nav-icon-close:before {
      transform: translateY(12px) rotate(135deg);
    }

    .nav-icon-close:after {
      transform: translateY(-12px) rotate(-135deg);
    }

    .nav-icon-close div {
      transform: scale(0);
    }
    .nav-icon-close:hover:after,
    .nav-icon-close:hover:before,
    .nav-icon-close:hover div {
      background-color: #24283F;
      border-radius: 3px;
      content: '';
      display: block;
      height: 3px;
      margin: 8px 0;
    }
    .nav-icon-close:hover:before {
      transform: translateY(0px) rotate(0deg);
    }

    .nav-icon-close:hover:after {
      transform: translateY(0px) rotate(0deg);
    }
    .nav-icon-close:hover div {
      transform: scale(1);
    }

  `],
  templateUrl: './header.component.html',
  host: {'(window:data-synced)':'updateSyncStatusShown($event)', '(window:data-added)':'dataAdded($event)', '(window:error-caching)':'errorCaching($event)'}
  
})
export class HeaderComponent implements OnInit {

  // languages: any[] = [{'code': 'en', 'name': 'English'}, {'code': 'es', 'name': 'Español'}];
  online$: Observable<boolean>;
  public registerFarmerModal;
  public quickConnectForm: FormGroup;
  isFieldAgent = false;
  isManager = false;
  isDeeproots = false;
  supportType:any = 'technical_support';
  private readonly unsubscribe: Subject<void> = new Subject();

  currentUser: any;
  alias ='';
  public sidebarOpen = false;
  public fileToUpload: any;
  public activeNavItem : string;
  public showBackdrop = false;
  public indexedDBDataCount;
  public offlineIndicator;
  public quickConnectModal;
  confirmModal: BsModalRef;
  noFileChosenDefault;
  notAvailableWhenOfflineTxt;

  showPrompt = false;
  navItems = [];
  public projectsList:any;
  ecosystemName;
  @ViewChild('confirmLogout') confirmLogout: TemplateRef<any>;

  

  constructor(private readonly authenticationService: AuthenticationService, private readonly router: Router,
  private readonly userService: UserService, private readonly loginService: LoginService, private readonly formBuilder: FormBuilder,
  private readonly commonService: CommonService, private readonly storageService: StorageService, private readonly location: Location,
  @Inject(PLATFORM_ID) private readonly platformId: Object, private readonly ref: ChangeDetectorRef, public toastrService: ToastrService,
  private readonly tourService: TourService, public modalService: BsModalService, private readonly projectService: ProjectService,
  private readonly translate: TranslateService,
  
) {

    this.router.events.subscribe(val => {
      this.activeNavItem = location.path();
    });

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.tourService.events$.subscribe(event => {
    });
    this.translate.get('file_upload_component_default_txt').subscribe(result => {
      this.noFileChosenDefault = result; // default text for the file input component
    });

    this.translate.get('not_available_when_offline').subscribe(result => {
      this.notAvailableWhenOfflineTxt = result; // default text for the file input component
    });





  }


  @ViewChild('quickConnectModal') quickConnectModalTemplate :any;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent,quickConnectForm) {
    
    this.declineModal(this.quickConnectForm);
  }

  async ngOnInit() {
    window.scrollTo(0, 0);

    this.currentUser = this.userService.getCurrentUser();
    

    this.indexedDBDataCount = await idbApp.getDataCount('farmerstmp');

    

    this.quickConnectForm = this.formBuilder.group({
      support_type: new FormControl('technical_support', [Validators.required]),
      message: new FormControl('', [Validators.required]),
      project_id: new FormControl(null),
      attachment: new FormControl('')
    });

    const requests = await Promise.all([
      this.userService.getNavItemsForUserType().toPromise(),
    ]);
    const navItemsData =  requests[0].data;
   
    for (let i = 0; i < navItemsData.length; i++) {
      if (navItemsData[i]['NavigationControl']['status'] == 'Active') {
        this.navItems.push(navItemsData[i]['NavigationControl']);
      }
    }
    if (this.currentUser.role.id === 2 || this.currentUser.role.id == 3) {
      this.ecosystemName = localStorage.getItem('ecosystem_name');
    }

    this.commonService.projectListObs.subscribe(value => {
      this.projectsList = value;
    })
  }

  handleFileInput(file: FileList) {

    const fileItem = file.item(0);

    const msg = this.commonService.ValidateFile(fileItem);

    if (msg.length > 0) {
      this.commonService.showToast('warning', 'generic_error', {});
      return;
    }

    this.fileToUpload = fileItem;

    const reader = new FileReader();
    reader.onload = (event: any) => {
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  supportTypeChangeFun(currentVal){
    this.supportType = currentVal;
    if(currentVal === 'technical_support'){
      this.quickConnectForm.patchValue({
        project_id: 0
      });
    }
  }

  submitSupportForm(form:any){

    const support_type = this.quickConnectForm['value'].support_type;
    const message = this.quickConnectForm['value'].message;
    let project_id = this.quickConnectForm['value'].project_id;
    if (!support_type || support_type.length == 0 || !message || message.length == 0) {
      return;
    }

    if (project_id == 0) {
      project_id = null;
    }

    const contactSupport = {
      support_type: support_type,
      message: message,
      attachment: null,
      project_id: project_id
    };


    if (navigator.onLine) {
      if (this.fileToUpload) {
        const formData: any = new FormData();
        formData.append('type', 'farmerAvatar');
        formData.append('avatar', this.fileToUpload, this.fileToUpload.name);

        this.projectService.uploadImageContactSupport(formData)
        .subscribe((obj) => {
          if (obj.msg === 'success') {
            contactSupport['attachment'] = obj.data;

            this.commonService.contactSupport(contactSupport).subscribe(
              (result) => {
                if (this.quickConnectModal) {
                  this.quickConnectModal.hide();
                }
                if (result.msg === 'success') {
                  if (this.quickConnectModal) {
                    this.quickConnectModal.hide();
                  }
                  this.quickConnectForm.setValue({
                    support_type: 'technical_support',
                    message: '',
                    attachment: '',
                    project_id:null
                  });
                  this.fileToUpload = '';
                  this.supportType ='technical_support';
                  this.quickConnectForm.markAsPristine();
                }else{
                  this.toastrService.error(result.msgText)
                }

               });
            }
          });
        }else{
          this.commonService.contactSupport(contactSupport).subscribe(
            (result) => {
              if (this.quickConnectModal) {
                this.quickConnectModal.hide();
              }
              if (result.msg === 'success') {
                if (this.quickConnectModal) {
                  this.quickConnectModal.hide();
                }
                this.quickConnectForm.setValue({
                  support_type: 'technical_support',
                  message: '',
                  attachment: '',
                  project_id:null
                });
                this.toastrService.success(result.msgText)
                this.fileToUpload = '';
                this.supportType ='technical_support';
                this.quickConnectForm.markAsPristine();
              }else{
                this.toastrService.error(result.msgText)
              }
          });
        }
      }

  }


  startTour() {
    this.sidebarOpen = false;
    this.tourService.start();
  }

  logout() {
    if (navigator.onLine) {
      this.confirmModal = this.modalService.show(this.confirmLogout, { backdrop: true, ignoreBackdropClick: true });
    } else {
      this.closeModal(false);
    }
  }

  closeModal(isOkay: boolean) {
    this.confirmModal.hide();

    if (isOkay) {
      this.blockToken();
    }
  }

  blockToken() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.commonService.blockToken({'token': currentUser.token}).subscribe(response => {
      const responseData = response;
      if (responseData.msg === 'success') {
        // If online and  PM only then remove token
        // Dive into the service functions - the TOKEN is not removed even for manager
        if (isPlatformBrowser(this.platformId)) {
          if (navigator.onLine) {
            this.authenticationService.removeToken();
            this.authenticationService.removeTokenHeader();
            localStorage.removeItem('ecosystem_id');
            localStorage.removeItem('ecosystem_name');
          }
        }
        this.storageService.setLocalStorage('lg', '1');
        this.router.navigate(['/']);
        } else {
        this.commonService.showToast('error', responseData.code, {});
      }
    });
  }

  goTo(where: string, id) : void {
    if(id === 2 || id === 3){
      this.router.navigate(['/ecosystems'])
    }else{
      if (where === "dashboard" || where ===  "register") {
        this.router.navigate([`/${where}`]);
      }
      else {
        if (navigator.onLine) {
          this.router.navigate([`/${where}`]);
        } else {
          if (where === 'setup') {
            this.commonService.showToast('warning', 'offline_project_warning', {});
          } else if (where === 'userlisting') {
            this.commonService.showToast('warning', 'offline_user_mgmt_warning', {});
          } else if (where === 'charts') {
            this.commonService.showToast('warning', 'offline_charts_warning', {});
          }
        }
      }
    }
  }

  clickOnProjectHome(){
      this.commonService.setSearchToBlank();
  }

  goToNew(nav) : void {
    if (nav.click_action === 'router') {
      if (nav.angular_component === "/dashboard") {
        this.router.navigate([nav.angular_component]);
        this.clickOnProjectHome();
      }
      else {
        if (navigator.onLine) {
          if (!nav.populate_dynamic_content) {
            const url = nav.angular_component;
            this.router.navigate([url]);
          }
          else {
            const url = `/goto/${nav.id}`;
            this.router.navigate([url]);
          }
        }
        else {
          this.toastrService.warning(this.notAvailableWhenOfflineTxt);
        }
      }
    }
  }

  updateSyncStatusShown(args): void {
    this.indexedDBDataCount = 0;
  }

  dataAdded(args): void {
    this.indexedDBDataCount ++;
  }

  errorCaching(args): void {
    this.commonService.showToast('error', 'error_caching', {disableTimeout: true})
  }

  quickConnectModalFn(template: TemplateRef<any>) : void {
    this.showPrompt = false;
    this.quickConnectModal = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
    
  }

  declineModal(form) : void {
    if (form.dirty) {
      this.showPrompt = true;
    }
    else {
      if (this.quickConnectModal) {
        this.quickConnectModal.hide();
      }
    }
  }

  confirmModalFn(what) : void {

    if (what === 'Yes') {
      if (this.quickConnectModal) {
        this.quickConnectModal.hide();
      }
      this.quickConnectForm.setValue({
        support_type: '',
        message: '',
        attachment: '',
        project_id:null
      });
      this.supportType ='technical_support';
      this.quickConnectForm.markAsPristine();
    }
    else {
      this.showPrompt = false;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}


