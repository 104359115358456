import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
var CompareDataService = /** @class */ (function () {
    function CompareDataService(http, appConfigService) {
        this.http = http;
        this.appConfigService = appConfigService;
        this.compareURL = this.appConfigService.getUrl(this.appConfigService.COMPARE);
    }
    CompareDataService.prototype.getComparisonReport = function (projectId, compareData) {
        return this.http.post(this.compareURL + "/compareSnapshot/" + projectId, compareData);
    };
    CompareDataService.prototype.getRevisionComparisonReport = function (projectId, compareData) {
        return this.http.post(this.compareURL + "/compareAnswers/" + projectId, compareData);
    };
    CompareDataService.prototype.getFarmerList = function (projectId, revisionId) {
        return this.http.post(this.compareURL + "/farmersExistInRevision/" + projectId + "/" + revisionId, "");
    };
    CompareDataService.ngInjectableDef = i0.defineInjectable({ factory: function CompareDataService_Factory() { return new CompareDataService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: CompareDataService, providedIn: "root" });
    return CompareDataService;
}());
export { CompareDataService };
