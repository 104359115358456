<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToReportingManagement(project?.id)" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item active cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)"><span i18n-title="@@projectReporting" title="Project Reporting">...</span></li>
      <li class="breadcrumb-item active" aria-current="page">
        <span i18n="@@charts">Charts</span>
      </li>
    </ol>
  </nav>
</div>

<div class="content-wrap content-wrapper background margin-btm-20 min-height-calc">
  <div class="form-design form-box pb-0">
    <form name="runReportForm" #runReportForm="ngForm" novalidate enctype="multipart/form-data">
      <div class="row">
        <div style="padding-left: 1%;">
          <label class="group-title inline-block" id="Chart"><span i18n="@@runReport">Run Report</span></label>
        </div>
      </div>
      <div class="row mrbottomten" *ngIf="reportTypes == 'predefined'">
        <div class="col-md-12 col-lg-3 col-xs-12">
          <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': section_name.value == 0 && (section_name.dirty || section_name.touched || runReportForm.submitted) }">
            <label><span i18n="@@sectionName">Section Name </span> <span class="mandatory"> *</span></label>
            <select class="form-control" (change)="selectSection($event,section_name)" [(ngModel)]="dashboardAddObject.section" name="section_name"  #section_name="ngModel" [ngClass]="(dashboardAddObject.sectionId == 0 ? 'ng-invalid':'ng-valid')" onchange="this.setAttribute('required','')" required>
              <option value="0" class="option" i18n="@@select">Select</option>
              <option *ngFor="let list of listsSectionsSubSection; trackBy: trackBySectionName" [ngValue]="list.section">{{list.section}}</option>
            </select>
            <div class="error" *ngIf="section_name.value == 0 && (section_name.dirty || section_name.touched || runReportForm.submitted)">
              <span i18n="@@enterValidInput">Enter valid input.</span>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3 col-xs-12">
          <div class="form-group no-margin-hr">
            <label i18n="@@subSectionName">Sub section Name </label>
            <select class="form-control" (change)="selectSubSection($event)" [disabled]="" [(ngModel)]="dashboardAddObject.subsection" name="sub_section_name" #sub_section_name="ngModel" >
              <option value="" class="option" i18n="@@select">Select</option>
              <option *ngFor="let list of newSubSection; trackBy: trackBySubsectionName" [ngValue]="list.name">{{list.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-12 col-lg-3 col-xs-12">
          <div class="form-group no-margin-hr reportsdropdown" [ngClass]="{ 'has-error': selectedReportsMultiSelect.length <= 0 && runReportForm.submitted }">
            <label><span i18n="@@reportName">Report Name </span><span class="mandatory"> *</span></label>
            <ng-multiselect-dropdown
            [placeholder]="selectPlaceholder"
            [data]="reportsListData"
            [(ngModel)]="selectedReportsMultiSelect"
            [settings]="dropdownSettings"
            [ngModelOptions]="{standalone: true}"
            (onSelectAll)="onSelectAllChart($event)"
            #reportIDs="ngModel" name="reportIDs" onchange="this.setAttribute('required','')" [ngClass]="(selectedReportsMultiSelect.length <= 0 && runReportForm.submitted ? 'ng-invalid':'ng-valid')" required [disabled]="subsectionFlag"               
            >
            </ng-multiselect-dropdown>
            <div class="error" *ngIf="selectedReportsMultiSelect.length <= 0 && runReportForm.submitted">
              <span i18n="@@enterValidInput">Enter valid input.</span>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3 col-xs-12 fltRgt datatable-header slidedownsearch">
          <label></label>
          <div class="form-group runreport-btns">
            <button type="button" name="button" class="runBtn btn btn-primary btn-lg mr-3" (click)="genarateReports(reportTypes)" [disabled]="selectedReportsMultiSelect.length <= 0">
              <span i18n="@@run">Run</span>
            </button>
            <button type="button" name="button" class="btn btn-default-gray btn-lg"  (click)="cancel()">
              <span i18n="@@cancel">Cancel</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row mrbottomten" *ngIf="reportTypes == 'self'">
        <div class="col-md-12 col-lg-3 col-xs-12">
          <div class="form-group no-margin-hr reportsdropdown" [ngClass]="{ 'has-error': selectedReportsMultiSelectCustom.length <= 0 && runReportForm.submitted }">
            <label><span i18n="@@reportName">Report Name </span><span class="mandatory"> *</span></label>
            <ng-multiselect-dropdown
            [placeholder]="'Select'"
            [data]="customReportsListData"
            [(ngModel)]="selectedReportsMultiSelectCustom"
            [settings]="dropdownSettings"
            [ngModelOptions]="{standalone: true}"
            #reportIDs="ngModel" name="customReports" onchange="this.setAttribute('required','')" [ngClass]="(selectedReportsMultiSelectCustom.length <= 0 && runReportForm.submitted ? 'ng-invalid':'ng-valid')"                 
            >
            </ng-multiselect-dropdown>
            <div class="error" *ngIf="selectedReportsMultiSelectCustom.length <= 0 && runReportForm.submitted">
              <span i18n="@@enterValidInput">Enter valid input.</span>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3 col-xs-12 fltRgt datatable-header slidedownsearch">
          <label></label>
          <div class="form-group runreport-btns">
            <!-- <button type="button" name="button" class="btn btn-success btn-lg mr-3" (click)="genarateReports(reportTypes)" [disabled]="selectedReportsMultiSelectCustom.length <= 0"> -->
            <button type="button" name="button" class="runBtn btn btn-primary btn-lg mr-3" (click)="genarateReports(reportTypes)" [disabled]="selectedReportsMultiSelectCustom.length <= 0">
              <span i18n="@@run">Run</span>
            </button>
            <button type="button" name="button" class="btn btn-default-gray btn-lg"  (click)="cancel()">
              <span i18n="@@cancel">Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </form>
    <!--form-box-gray-->
    <div class="form-design form-box" [ngStyle]="{ display: reportAvailableFlag ? 'block' : 'none' }">
      <div class="row" *ngIf="exportBtns && checkChartsAvailable.length > 0">
        <div class="col-lg-12  form-group fltRgt datatable-header">
          <div class="countOfFarmers">
            <span *ngIf="countOfFarmers?.total_farmers != null">({{countOfFarmers?.total_farmers}} <span> <span i18n="@@farmers">Farmers</span>)</span></span>
            <span *ngIf="countOfFarmers?.total_farmers == null">(0 <span i18n="@@farmers">Farmers</span>)</span>
          </div>
          <button type="button" name="button" class="btn btn-success btn-noBg btn-lg" (click)="downloadAsPDF()">
            <span i18n="@@downloadAsPDF">Download as PDF</span>
          </button>
          <!-- ngx print -->
          <button type="button" name="button" id="printReportsBtn" class="btn btn-noBg btn-success btn-lg" (click)="printCharts()"> <!--printSectionId="test1" ngxPrint-->
            <span i18n="@@print">Print</span>
          </button>
          <button type="button" name="button" class="btn btn-success btn-lg" (click)="clearAllCharts()">
            <span i18n="@@clearAll">Clear All</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="jumbotron jumbotron-custom" style="justify-content: initial;align-items: initial;padding:0px;">
          <div #charts id="test" class="row mrlrzero"></div>
          <!-- <div>
            <div class="chart canvas-container col-md-4 col-lg-4 chartBox-pdmr" id="timeCharts" #timeCharts data-percent="timeVal">{{timeVal}}</div>
          </div> -->
          <div #charts02 id="test1" class="mrlrzero"  style="display: none;width:100%;"></div>
        </div>
      </div>
    </div>
    
  </div>
</div>