import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
var TrainingService = /** @class */ (function () {
    function TrainingService(http, appConfigService) {
        this.http = http;
        this.appConfigService = appConfigService;
        this.trainingURL = this.appConfigService.getUrl(this.appConfigService.TRAINING);
        this.visitTypeURL = this.appConfigService.getUrl(this.appConfigService.VISIT_TYPES);
    }
    TrainingService.prototype.getProjectTrainingMapping = function (mappingID) {
        return mappingID ? this.http.get(this.trainingURL + "/projectTCMapping/" + mappingID) :
            this.http.get(this.trainingURL + "/projectTCMapping");
    };
    TrainingService.prototype.saveProjectTCMapping = function (data, edit, mappingID) {
        return edit ? this.http.put(this.trainingURL + "/projectTCMapping/" + mappingID, data) :
            this.http.post(this.trainingURL + "/projectTCMapping", data);
    };
    TrainingService.prototype.exportToExcel = function () {
        return this.http.post(this.trainingURL + "/export/projectTCMapping", {}, { responseType: 'blob' });
    };
    TrainingService.prototype.exportToExcelPGM = function () {
        return this.http.post(this.trainingURL + "/export/exportProjectGRMapping", {}, { responseType: 'blob' });
    };
    TrainingService.prototype.getProjectGroupMapping = function (mappingID) {
        return mappingID ? this.http.get(this.trainingURL + "/projectGRMapping/" + mappingID) :
            this.http.get(this.trainingURL + "/projectGRMapping");
    };
    TrainingService.prototype.getAllGroups = function (projectID) {
        return projectID ? this.http.get(this.trainingURL + "/getAllGroups/" + projectID) :
            this.http.get(this.trainingURL + "/getAllGroups");
    };
    TrainingService.prototype.saveProjectGroupMapping = function (data, edit, mappingID) {
        return edit ? this.http.put(this.trainingURL + "/projectGRMapping/" + mappingID, data) :
            this.http.post(this.trainingURL + "/projectGRMapping", data);
    };
    TrainingService.prototype.getFarmerIdsMappedToGroup = function (projectGroupMappingId) {
        return this.http.get(this.trainingURL + "/getFarmerIdsMappedToGroup/" + projectGroupMappingId);
    };
    TrainingService.prototype.mapFarmersToGroup = function (projectGroupMappingId, data) {
        return this.http.post(this.trainingURL + "/mapFarmersToGroup/" + projectGroupMappingId, data);
    };
    TrainingService.prototype.get_all_training_certification = function (projectID) {
        return this.http.get(this.trainingURL + "/get_all_training_certification/" + projectID);
    };
    TrainingService.prototype.get_training_recordings = function (projectID) {
        return this.http.get(this.trainingURL + "/get_training_recordings/" + projectID);
    };
    TrainingService.prototype.get_all_trainer = function (projectID) {
        return this.http.get(this.trainingURL + "/get_all_trainer/" + projectID);
    };
    TrainingService.prototype.getGroupFarmers = function (projectGroupMappingID) {
        return this.http.get(this.trainingURL + "/getMapedFarmerDetailsToProjectGroup/" + projectGroupMappingID);
    };
    TrainingService.prototype.saveTrainingDetails = function (data) {
        return this.http.post(this.trainingURL + "/recordTraining", data);
    };
    TrainingService.prototype.getTrainingDetails = function (hdrID) {
        return this.http.get(this.trainingURL + "/getTrainingDetails/" + hdrID);
    };
    TrainingService.prototype.updateTraining = function (data) {
        return this.http.put(this.trainingURL + "/updateTraining", data);
    };
    TrainingService.prototype.deleteTrainingDocument = function (id, data) {
        return this.http.delete(this.trainingURL + "/deleteTrainingDocument/" + id, data);
    };
    TrainingService.prototype.saveCertificationDetails = function (data) {
        return this.http.post(this.trainingURL + "/recordCertification", data);
    };
    TrainingService.prototype.getCertificationDetails = function (hdrID) {
        return this.http.get(this.trainingURL + "/getCertificationDetails/" + hdrID);
    };
    TrainingService.prototype.updateCertification = function (data) {
        return this.http.put(this.trainingURL + "/updateCertification", data);
    };
    TrainingService.prototype.trainingManagementExportToExcel = function (projectID, type) {
        return this.http.post(this.trainingURL + "/export/management_training_certification/" + projectID + "/" + type, {}, { responseType: 'blob' });
    };
    TrainingService.prototype.getFarmerTrainingIndicators = function (projectID) {
        return this.http.get(this.trainingURL + "/getFarmerTrainingIndicators/" + projectID);
    };
    TrainingService.prototype.get_training_recordings_farmer = function (projectID, farmerID) {
        return this.http.get(this.trainingURL + "/get_training_recordings/" + projectID + "/" + farmerID);
    };
    TrainingService.prototype.getIndividualTrainingCertificationDetails = function (projectID, farmerID, headerID, type) {
        return this.http.get(this.trainingURL + "/getIndividualTrainingCertificationDetails/" + projectID + "/" + farmerID + "/" + headerID + "/" + type);
    };
    TrainingService.prototype.updateIndividualTraining = function (projectID, farmerID, data) {
        return this.http.put(this.trainingURL + "/updateIndividualTraining/" + projectID + "/" + farmerID, data);
    };
    TrainingService.prototype.recordIndividualTraining = function (projectID, farmerID, data) {
        return this.http.post(this.trainingURL + "/recordIndividualTraining/" + projectID + "/" + farmerID, data);
    };
    TrainingService.prototype.updateIndividualCertification = function (projectID, farmerID, data) {
        return this.http.put(this.trainingURL + "/updateIndividualCertification/" + projectID + "/" + farmerID, data);
    };
    TrainingService.prototype.recordIndividualCertification = function (projectID, farmerID, data) {
        return this.http.post(this.trainingURL + "/recordIndividualCertification/" + projectID + "/" + farmerID, data);
    };
    TrainingService.prototype.trainingFarmerManagementExportToExcel = function (projectID, farmerIDs, type) {
        return this.http.post(this.trainingURL + "/export/exportTtrainingCertificationRecordingsForFarmers/" + projectID + "/" + type, farmerIDs, { responseType: 'blob' });
    };
    TrainingService.prototype.getFarmerExportTypes = function () {
        return this.http.get(this.visitTypeURL + "/farmer_export_type");
    };
    TrainingService.prototype.getFarmerCertificationList = function (projectID) {
        return this.http.get(this.trainingURL + "/getFarmerCertificationList/" + projectID);
    };
    TrainingService.ngInjectableDef = i0.defineInjectable({ factory: function TrainingService_Factory() { return new TrainingService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: TrainingService, providedIn: "root" });
    return TrainingService;
}());
export { TrainingService };
