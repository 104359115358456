// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


export const environment = {
  production: false,
  // apiBaseUrl: "http://localhost:3000",
  apiBaseUrl: 'https://p2frootsreg.firmenich.com',
  port: '3000',
  secret_key: 'firmenich',
  snapshotRefreshInterval: 60000,
  imageCompress:{
    quality: 0.8,
    maxWidth:500,
    maxHeight:500,
    mimeType:"image/webp",
    convertSize:50000
 }
};
