<app-header></app-header>
<div class="project-header clearfix">

  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb" *ngIf="isCompare">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="getBackCompareData()" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" routerLink="/charts/{{project?.id}}">
        <span i18n-title="@@projectReporting" title="Project Reporting">...</span>
      </li>  
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackCompareData()">
        <span title="Compare Data">...</span>
      </li>  
      <li class="breadcrumb-item active" aria-current="page">
        <span>{{farmer?.name}}</span>
      </li>
    </ol>
    <ol class="breadcrumb" *ngIf="!isCompare">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToProfilingTable(); $event.stopPropagation()" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item active cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item active cursorPointer" aria-current="page">
        <a (click)="goToProfilingTable()">...</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        <span>{{farmer?.name}}</span>
      </li>
    </ol>
  </nav>
  <ol class="breadcrumb posTopLeft" style="float:right; margin-top: 4px; margin-right:10px;">
    <li class="breadcrumb-item active cursorPointer" aria-current="page" *ngIf="snapshotsListDataDropdown?.length > 0 && currentTabIndex !== farmerDetailsTabIndex && (isOnline | async)">
      <select class="small-button float-left" style="padding:3px" [(ngModel)]="selectedRevision" (change)="changeRevision()">
        <option [value]="0" i18n="@@currentVersion" *ngIf="notActiveSurvey == 0">Current version</option>
        <option *ngFor="let revision of snapshotsListDataDropdown;" [value]="revision?.revisionId">
          {{revision?.snapshot_name}}
        </option>
      </select>
      <a href="javascript:void(0)" title="Reload" id="currentState" (click)="selectedRevision = 0; changeRevision()" class="small-button ml-3"><i class="utilicon utilicon-redo reload-icon"></i></a>
    </li>
  </ol>
</div>
<div class="content-wrap content-wrapper view-profile">
  <!-- <div class="dropdown-box" *ngIf="snapshotsListDataDropdown?.length > 0 && currentTabIndex !== farmerDetailsTabIndex && (isOnline | async)">
    <div class="inner">
      <select class="form-control" [(ngModel)]="selectedRevision" (change)="changeRevision()">
        <option [ngValue]="0">Current version</option>
        <option *ngFor="let revision of snapshotsListDataDropdown;" [ngValue]="revision?.revisionId">
          {{revision?.snapshot_name}}
        </option>
      </select>&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="javascript:void(0)" id="currentState" (click)="selectedRevision = 0; changeRevision()" class="reload-button"><img src="assets/images/reload.webp" alt="reload"></a>
    </div>
  </div> -->
  <div *ngIf="notActiveSurvey == 1" style="margin-top: 50px">
    <p i18n="@@pleaseActivateSurvey">Please activate survey</p>
  </div>
  <div *ngIf="notReleasedSurveyInLanguage == 1" style="margin-top: 50px">
    <p i18n="@@surveyNotReleasedCurrentLanguage">Survey is not released in current language</p>
  </div>
  <div class="form-design" *ngIf="(notReleasedSurveyInLanguage !== 1 && notActiveSurvey !== 1)">
    <div class="form-box">
      <div class="farmer-profile-div clearfix">
        <div class="tab-control-div" id="mainDiv">
          <div class="farmer-tab-content">
            <div class="tab-pane fade in active" id="pills-fp" role="tabpanel" aria-labelledby="pills-fp-tab" *ngIf="notActiveSurvey == 0">
              <div class="clearfix"></div>
              <div class="border-middle"></div>
              <ul *ngIf="questionnaire && questionnaire != 'noSurveys'" class="nav nav-pills fixed-tabs" id="pills-tab2" role="tablist" style="overflow:auto">
                <li class="nav-item">
                  <a class="nav-link" data-toggle="pill" [href]="'#' + farmerDetailsTabIndex" id="pills-fp-tab"  [ngClass]="{'active': currentTabIndex == farmerDetailsTabIndex}"
                  (click)="tabClicked(farmerDetailsTabIndex, true)"  role="tab" aria-selected="true" i18n="@@farmerDetails">Farmer Details</a>
                </li>
                <li class="nav-item" *ngFor="let tab of questionnaire; let i = index">
                  <a class="nav-link" [ngClass]="{'active': currentTab?.id == tab?.id && currentTabIndex != farmerDetailsTabIndex}" id="pills-fp-tab" data-toggle="pill"
                    [href]="'#' + tab?.id" role="tab" aria-selected="true" (click)="tabClicked(i, false)">
                    {{tab?.survey_q_asset_labels[0]?.label}}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div class="com-sm-12" *ngIf="showSummaryForCurrentTab && !discontinuedFarmer" id="tabSummary">
          <div class="row profit-lost-grid no-gutters mt-2 mb-2">
            <div class="col-md-12 col-lg-4 light-blue br-4-l flex-center" >
              <div class="profit-green weight-400">
                  <div class="font-20" i18n="@@farmerIncome">Income</div>
                  <div><span i18n="@@totalFarmIncome">Total Farm Income</span>: <span class="green">{{currencyCode}} {{farmProfitabilitySummary?.agriIncome}}</span></div>
                  <div><span i18n="@@totalOtherIncome">Total Other Income</span>: <span class="green">{{currencyCode}} {{farmProfitabilitySummary?.otherIncome}}</span></div>
                <div></div>
              </div>
            </div>
            <div class="col-md-12 col-lg-4 text-center">
              <div class="pt-2 pb-2 weight-400">
                <div class="font-20" i18n="@@farmerProfitability">PROFITABILITY</div>
                <div><span i18n="@@farmerTotalProfitability">Total Profitability</span>: <span [ngClass]="farmProfitabilitySummary.totalProfit>=0 ? 'green' : 'red' ">{{currencyCode}} {{farmProfitabilitySummary?.totalProfit}}</span></div>
              </div>
            </div>
            <div class="col-md-12 col-lg-4 light-blue br-4-r flex-center">
              <div class="lost-red weight-400">
                <div class="font-20"><span i18n="@@farmerExpenses">Expenses</span></div>
                <div><span class="red">{{currencyCode}} {{farmProfitabilitySummary?.totalExpense}}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" [id]="farmerDetailsTabIndex" [ngClass]="{'active': currentTabIndex == farmerDetailsTabIndex}" *ngIf="currentTabIndex == farmerDetailsTabIndex"> 
          <div class="view-profile-data mt-3">
            <h3 class="view-profile-title" i18n="@@personal">Personal</h3>
            <ul class="clearfix">
              <li *ngIf="discontinuedFarmer" class="discontinued-view">
                This farmer is discontinued.
              </li>
              <li *ngFor="let question of farmerRegQuestions">
                <div *ngIf="question.code !='photo_path'" class="repeater">
                  <span class="question-area">{{question.ques_text}}</span>
                  <span class="asnwer-area" *ngIf="question.ques_type!='select'">{{farmer[question.code]}}&nbsp;</span>
                  <span class="asnwer-area" *ngIf="question.ques_type=='select' && question.code!='village_area_id'">{{getMasterData(question.code,farmer[question.code])}}&nbsp;</span>
                  <span class="asnwer-area" *ngIf="question.code=='village_area_id'">{{villageName}}&nbsp;</span>
                </div>
                <div *ngIf="question.code =='photo_path'" class="repeater">
                  <span class="question-area">{{question.ques_text}}</span>
                  <span class="asnwer-area" *ngIf="farmer[question.code]" i18n="@@present">Present &nbsp;</span>
                  <span class="asnwer-area" *ngIf="!farmer[question.code]" i18n="@@notPresent">Not Present &nbsp;</span>
                </div>
              </li>
            </ul>
            <h3 class="view-profile-title" *ngIf="isOnline | async" i18n="@@consentHistory">Consent History</h3>
            <table class="table" *ngIf="isOnline | async">
              <thead>
                <tr>
                  <th style="width:10%" i18n="@@srNo">Sr. No</th>
                  <th style="width:25%" i18n="@@consentVersion">Consent Version</th>
                  <th style="width:30%" i18n="@@consentSignDate">Consent Sign Date</th>
                  <th style="width:35%" i18n="@@privacyPolicyDocument">Privacy Policy Document</th>
                </tr>
              </thead>
              <tbody *ngIf="consentHistory else noConsentHistory">
                  <tr class="font-weight-normal">
                    <td>1</td>
                    <td>{{consentHistory.consent_version}} <span class="pl-1 green">(<span i18n="@@currentVersion">Current Version</span>)</span></td>
                    <td>{{consentHistory.consent_recorded | date: 'dd-MMM-yyyy'}}</td>
                    <td><span class="cursorPointer blue" (click)="getFarmerConsentTemplate(consentHistory.farmer_consent_template_id, privacyPolicy)" i18n="@@view">View</span></td>
                  </tr>
                  <tr class="font-weight-normal" *ngFor="let item of consentHistory.farmer_consent_history; let i = index">
                    <td>{{i + 2}}</td>
                    <td>{{item.consent_version}}</td>
                    <td>{{item.consent_recorded | date: 'dd-MMM-yyyy'}}</td>
                    <td><span class="cursorPointer blue" (click)="getFarmerConsentTemplate(item.farmer_consent_template_id, privacyPolicy)" i18n="@@view">View</span></td>
                  </tr>
              </tbody>
              <ng-template #noConsentHistory>
                <tbody>
                  <tr>
                    <td colspan="4" i18n="@@consentNotFoundFarmer">No consent found for this farmer</td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
          </div>
        </div>
        <div class="tab-pane active" [id]="currentTab?.id" *ngIf="currentTabIndex != farmerDetailsTabIndex">            
          <div class="row" *ngIf="!discontinuedFarmer else discontinueText">
            <div class="col-sm-12" *ngFor="let section of currentTab?.sections; let sectionIndex = index;" [ngClass]="{'hide': section.removed_from_snapshot }">
              <div class="view-profile-data">
                <h3 class="view-profile-title">{{section?.survey_q_asset_labels[0]?.label}}</h3>
                <ul class="clearfix">
                  <ng-container *ngFor="let question of section?.questions">
                    <li *ngIf="question?.isDisplayed" class="pos-r">
                      <span class="question-area">{{question?.survey_q_asset_labels[0]?.label}}</span>
                      <span class="asnwer-area">{{question?.answer}}&nbsp;
                        <span *ngIf="question.include_uom_question">
                          <span *ngFor="let uom of question.uom_questions; let i = index">
                            <span *ngIf="i > 0 && uom?.answer" class="mr-1 ml-1">/</span>
                            {{uom?.answer}}
                          </span>
                        </span>
                        <span *ngIf="question.other_questions && question.other_questions.length > 0">
                          <span *ngIf="question.otherAnswer">-&nbsp;</span>
                          {{question.otherAnswer}}
                        </span>
                      </span>
                      <span *ngIf="question.qa_subtype == 'file'" class="cursorPointer bold download-image" title="download image" (click)="downloadImage(question.id)">
                        <i class="utilicon utilicon-arrow-alt-to-bottom" style="line-height: inherit; font-size: 13px;"></i>
                      </span>
                    </li>
                  </ng-container>
                  <ng-container *ngFor="let subsection of section?.subsections">
                    <li *ngIf="subsection?.isDisplayed">
                      <ul class="clearfix" style="width:100%">
                        <h3 class="view-profile-title mt-0">{{subsection?.survey_q_asset_labels[0]?.label}}</h3>
                        <ng-container *ngFor="let question of subsection?.questions">
                          <li *ngIf="question?.isDisplayed" class="pos-r">
                            <span class="question-area">{{question?.survey_q_asset_labels[0]?.label}}</span>
                            <span class="asnwer-area">{{question?.answer}}&nbsp;
                              <span *ngIf="question.include_uom_question">
                                <span *ngFor="let uom of question.uom_questions; let i = index">
                                  <span *ngIf="i > 0 && uom?.answer" class="mr-1 ml-1">/</span>
                                  {{uom?.answer}}
                                </span>
                              </span>
                              <span *ngIf="question.other_questions && question.other_questions.length > 0">
                                
                                <span *ngIf="question.otherAnswer">-&nbsp;</span>
                                {{question.otherAnswer}}
                              </span>
                            </span>
                            <span *ngIf="question.qa_subtype == 'file'" class="cursorPointer bold download-image" title="download image" (click)="downloadImage(question.id)">
                              <i class="utilicon utilicon-arrow-alt-to-bottom" style="line-height: inherit; font-size: 13px;"></i>
                            </span>
                          </li>
                        </ng-container>
                      </ul>
                    </li>
                  </ng-container>
                  <ng-container *ngFor="let table of section?.tables">
                    <li *ngIf="table?.isDisplayed">
                      <ul class="clearfix" style="width:100%">
                        <h3 class="view-profile-title mt-0">{{table?.survey_q_asset_labels[0]?.label}}</h3>
                        <div *ngIf="table.qa_subtype == 'question_cols'">
                          <table class="table">
                            <thead class="no-box-shaow">
                              <tr>
                                <th *ngFor="let question of table.questions">
                                  <span>{{question.survey_q_asset_labels[0].label}}</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let key of secondary_asset_keys[table.id]" [id] = "key">
                                <td *ngFor="let question of table.questions" [id] = "question.id">
                                  <div *ngIf="question[key + '_isDisplayed']" style="display: flex" class="pos-r">
                                    <span class="asnwer-area">{{question?.answer[key]}}&nbsp;
                                      <span *ngIf="question.include_uom_question">
                                        <span *ngFor="let uom of question.uom_questions; let i = index">
                                          <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                          {{uom?.answer ? uom?.answer[key] : ""}}
                                        </span>
                                      </span>
                                      <span *ngIf="question.other_questions && question.other_questions.length > 0">
                                        <span *ngIf="question.otherAnswer && question.otherAnswer[key]">-&nbsp;</span>
                                        {{question.otherAnswer[key]}}
                                      </span>
                                    </span>
                                    <span *ngIf="question.qa_subtype == 'file'" class="cursorPointer bold download-image" title="download image" (click)="downloadImage(question.id, key)">
                                      <i class="utilicon utilicon-arrow-alt-to-bottom" style="line-height: inherit; font-size: 13px;"></i>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div *ngIf="table.qa_subtype == 'question_rows'">
                          <table class="table fixed-width-table-rows">
                            <thead class="no-box-shaow">
                              <tr>
                                <th class="text-center" *ngFor="let key of secondary_asset_keys[table.id]" [id] = "key">
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let question of table.questions" [id] = "question.id">
                                <td>
                                  <span class="bold">{{question.survey_q_asset_labels[0].label}}</span>
                                </td>
                                <td *ngFor="let key of secondary_asset_keys[table.id]">
                                  <div style="display: flex" *ngIf="question[key + '_isDisplayed']" class="pos-r">
                                    <span class="asnwer-area">{{question?.answer[key]}}&nbsp;
                                      <span *ngIf="question.include_uom_question">
                                        <span *ngFor="let uom of question.uom_questions; let i = index">
                                          <span *ngIf="i > 0" class="mr-1 ml-1">/</span>
                                          {{uom?.answer ? uom?.answer[key] : ""}}
                                        </span>
                                      </span>
                                      <span *ngIf="question.other_questions && question.other_questions.length > 0">
                                        <span *ngIf="question.otherAnswer !== undefined">
                                          <span *ngIf="question.otherAnswer && question.otherAnswer[key]">-&nbsp;</span>
                                          {{question.otherAnswer[key]}}
                                        </span>
                                      </span>
                                    </span>
                                    <span *ngIf="question.qa_subtype == 'file'" class="cursorPointer bold download-image" title="download image" (click)="downloadImage(question.id, key)">
                                      <i class="utilicon utilicon-arrow-alt-to-bottom" style="line-height: inherit; font-size: 13px;"></i>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </ul>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
          <ng-template #discontinueText>
            <div class="discontinued-view">
              This farmer is discontinued.
            </div>
          </ng-template>
        </div>
      </div>
      <div class="text-center mt-4" *ngIf="isCompare">
        <button class="btn btn-primary" (click)="getBackCompareData()">
          <span i18n="@@cancel">Cancel</span>
        </button>
      </div>
      <div class="text-center mt-4" *ngIf="!isCompare">
        <button class="btn btn-primary" (click)="goToProfilingTable()">
          <span i18n="@@cancel">Cancel</span>
        </button>
      </div>
    </div>
  </div>
  <ng-template #privacyPolicy>
    <div class="modal-header" style="display: flow-root;">
      <button type="button" class="close pull-right" aria-label="Close" (click)="closePrivacyPolicy()">
        <i class="utilicon utilicon-times-light"></i>
      </button>
    </div>
    <div class="modal-body">
      <div style="overflow-x: auto" [innerHtml]="consentTemplate"></div>
      <div class="text-center">
        <button type="button" class="btn btn-primary" (click)="closePrivacyPolicy()" i18n="@@back">Back</button>
      </div>
    </div>
  </ng-template>
</div>