import { Component,
  OnInit,
  OnDestroy,
  ViewChild, ElementRef, AfterViewInit, Renderer, ChangeDetectionStrategy, Renderer2, Inject, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Project, UserTypeServiceMapping } from '../../../models/project';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { chart } from 'highcharts';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
import $ from 'jquery';

@Component({
  selector: 'app-good-farming-practices',
  templateUrl: './good-farming-practices.component.html',
  styleUrls: ['./good-farming-practices.component.css']
})
export class GoodFarmingPracticesComponent implements OnInit {
  @ViewChild('charts2') public chartElement02: ElementRef;
  @ViewChild('charts3') public chartElement03: ElementRef;
  @ViewChild('charts4') public chartElement04: ElementRef;
  project: any;
  projectId = 0;
  dashboardId;
  dashboardData = [];
  reportIDs = [];
  predefinedReports = [];
  constructor(
    private readonly route: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private hcRService:HighchartReportsService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private renderer: Renderer2
  )  { }

  ngOnInit() {
    this.dashboardId = this.route.snapshot.params['id2'];
    this.projectId = this.route.snapshot.params['id'];

    this.projectService.getProjectBasicInfo(this.projectId).subscribe(response =>{
      this.project = response.data;
      const projectObject = this.project.filter(item => item.id == this.projectId)[0];
      this.project = projectObject;
    });

    const requestObject = {
        //"dashboardId":this.dashboardId
        "dashboardId":this.dashboardId
    }

    this.hcRService.getDashboardDetailsById(requestObject).subscribe(response =>{
        //console.log("get Dashboard Data ", response);
        if(response.msg == 'success'){
            this.dashboardData = response.data;
          if(this.dashboardData.length > 0){
              //console.log("this.dashboardData ", this.dashboardData);
              const dashboardSelectedReports = [];
            for(let i=0;i< this.dashboardData[0].hc_report_dashboard_dtl.length;i++){
                dashboardSelectedReports.push(this.dashboardData[0].hc_report_dashboard_dtl[i]);
            //this.dashboardEditObject.section = dashboardData[0].hc_report_dashboard_dtl[0].section;
            }
            //console.log("dashboardSelectedReports ", dashboardSelectedReports);
            if(dashboardSelectedReports && dashboardSelectedReports.length > 0){
              for(let i=0;i<dashboardSelectedReports.length;i++){
                this.reportIDs.push(dashboardSelectedReports[i].report_id);      
              }
              //console.log(" this.reportIDs ", this.reportIDs);
              if(this.reportIDs && this.reportIDs.length > 0){
                  this.genarateCharts(this.reportIDs);
              }
            }
          }         
  
        }
      });


    // let h = document.createElement("div");   
    // let newElement4 = this.chartElement04.nativeElement.appendChild(h); 
    // this.renderer.addClass(newElement4, 'height100');
    // Highcharts.chart(newElement4, {
    //   chart: {
    //       type: 'column',
    //       backgroundColor: 'transparent'
    //   },
    //   title: {
    //       text: ''
    //   },
    //   subtitle: {
    //       text: ''
    //   },
    //   accessibility: {
    //       announceNewData: {
    //           enabled: true
    //       }
    //   },
    //   xAxis: {
    //       type: 'category'
    //   },
    //   yAxis: {
    //       title: {
    //           text: ''
    //       }

    //   },
    //   legend: {
    //       enabled: false
    //   },
    //   plotOptions: {
    //       series: {
    //           borderWidth: 0,
    //           dataLabels: {
    //               enabled: true,
    //               format: '{point.y:.1f}%'
    //           }
    //       }
    //   },

    //   tooltip: {
    //       headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    //       pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
    //   },

    //   series: [
    //       {
    //           name: "Good Practices",
    //           colorByPoint: true,
    //           data: [
    //               {
    //                   name: "Buffer Areas",
    //                   y: 62.74
    //               },
    //               {
    //                   name: "Glyceridia Tutors",
    //                   y: 10.57
    //               },
    //               {
    //                   name: "Manual Weeding",
    //                   y: 78.23
    //               },
    //               {
    //                   name: "Looping",
    //                   y: 38.23
    //               },
    //               {
    //                   name: "Organic Crop Protection",
    //                   y: 23.58
    //               },
    //               {
    //                   name: "Organic Fertilizers",
    //                   y: 54.02
    //               },
    //               {
    //                   name: "Shading Trees",
    //                   y: 45.92
    //               }
    //           ]
    //       }
    //   ]
    // });
  }

  async genarateCharts(reportIds){
    this.spinner.show();
    let requestObject ={};
    requestObject = {
        "reportID":reportIds,        
        "projectId":this.projectId
    };
    //console.log("requestObject ", requestObject);
    const chartsList = [];    

    this.hcRService.getReports(requestObject).subscribe(response =>{
        //console.log("response ", response);
        if(response.msg == 'success'){
          const reportsData = response.data;
            this.predefinedReports = response.data;
            //console.log("reportsData ", this.predefinedReports);
            if(reportsData && reportsData.length > 0){
                for(let i=0;i<reportsData.length;i++){                    
                    if(reportsData[i].reportId == 117){
                        this.createGraph(reportsData[i], this.chartElement02);
                    }
                    if(reportsData[i].reportId == 120){
                        this.createGraph(reportsData[i], this.chartElement03);
                    }
                    if(reportsData[i].reportId == 128){
                        this.createGraph(reportsData[i], this.chartElement04);
                    }
                    if(reportsData[i].reportId == 133){
                        let perc = 0;
                        if(reportsData[i].series[0].children_motivated_farming != null && reportsData[i].series[0].total_children != null){
                            perc = reportsData[i].series[0].children_motivated_farming * 100 / reportsData[i].series[0].total_children;
                        }
                        reportsData[i].series[0].perc = perc.toFixed(0);
                    }
                }
                this.setPerValue();
            }
            this.spinner.hide();
        } else {
            this.spinner.hide();
        }
    })
}
createGraph(reportsData, ele){
  const option = reportsData.conf[0].conf;
  const series = reportsData.series;
    var seriesArr = [];
    if (Array.isArray(series) && series.length > 0){
      for (let [key, value] of Object.entries(series[0])) {
        const object ={
              name:key,
              y:Number(value)
          };
          seriesArr.push(object);
      }
    }
    option.title.text = '';
    option.series[0].data = seriesArr;

    let contextMenuOptions = [];
    contextMenuOptions = [
        'viewFullscreen',                      
        {
        textKey: 'downloadPDF',
        onclick: function () {
            this.exportChart({
            type: 'application/pdf'
            });
        }
        },
        {
        textKey: 'downloadPNG',
        onclick: function () {
            this.exportChart();
        }
        },
        {
        textKey: 'downloadXLS',                      
        onclick: function () {
            this.downloadXLS();
        }
        }
    ]

    option.exporting.buttons.contextButton.menuItems = contextMenuOptions;
    const e = document.createElement("div");   
    const newElement = ele.nativeElement.appendChild(e); 
    this.renderer.addClass(newElement, 'height100');
    this.hcRService.createChart(newElement, option);

}

  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }
  goToReportingManagement(projectID){
    const url = 'charts/' + this.projectId;
    this.router.navigate([url]);
  }
  goToDashboards(){
    const url="charts/dashboards/"+ this.projectId;
    this.router.navigate([url]);
  }
  setPerValue(){
      setTimeout(function(){        
        //const colorArr = ['#24b358', '#00cc44', '#00cc66', '#00cc99', '#00cc44', '#00cc66', '#00cc99'];
        var $ppc = $(".progress-pie-chart");
        $.each( $ppc, function( item, val ) {
            //console.log($(this).data("percent"));
            var percent = parseInt($(this).data("percent")),
            deg = (360 * percent) / 100;
            if (percent > 50) {
                $(this).addClass("gt-50");
                //$(this).css('background-color', colorArr[val])
            }
            $(this).find(".ppc-progress-fill").css("transform", "rotate(" + deg + "deg)");
            $(this).find(".ppc-percents span").html(percent + "%");
        });
      }, 3000)
  }

}
