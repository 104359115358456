<app-header></app-header>
<div class="project-header clearfix">

  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="redirectToCampaignManagement()" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <!-- Breadcrumb updated-->
      <li class="breadcrumb-item"><a href="#" routerLink="/goto/{{fromNavId}}" [attr.title]="typeOfAdministration">{{typeOfAdministration | slice:0:15}}...</a></li>
      <li class="breadcrumb-item"><a (click)="redirectToCampaignManagement()" i18n-title="@@campaignManagement" title="Campaign Management">...</a></li>
      <li *ngIf="campaign?.id > 0; else addCampaign" class="breadcrumb-item active" aria-current="page">{{campaign?.name}}</li>
      <ng-template #addCampaign><li *ngIf="campaignId == 0" class="breadcrumb-item active" aria-current="page" i18n='@@addCampaign'>Add Campaign</li></ng-template>
    </ol>
  </nav>
</div>


<div class="clearfix"></div>
<div class="content-wrap content-wrapper">
  <form [formGroup]="campaignForm" (ngSubmit)="saveUpdateCampaign()" class="form-design" *ngIf="projects?.length > 0">
    <div class="form-box">
      <label class="group-title" i18n="@@campaignInformation">Campaign Information</label>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="form-group no-margin-hr">
            <label for="text-result"><span i18n="@@projectName">Project Name </span><span class="mandatory">*</span></label>
            <select class="form-control" formControlName="projectId" name="ProjectName" required [attr.disabled]="campaign?.id > 0 ? '' : null">
              <option value="0" class="option" selected i18n="@@select">Select</option>
              <option *ngFor="let item of projects; trackBy: trackById" [ngValue]="item.id">{{item.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="form-group" [ngClass]="{ 'has-error': (campaignForm?.controls?.name?.dirty || campaignForm?.controls?.name?.touched) && campaignForm?.controls?.name?.errors}">
            <label for="text-result"><span i18n="@@campaignNameorCode">Campaign Name or Code</span><span class="mandatory">*</span></label>
            <input type="text" placeholder="Enter value" i18n-placeholder="@@enterValue" class="form-control" 
            formControlName="name" maxlength="200" required>
            <div class="error" *ngIf="(campaignForm?.controls?.name?.dirty || campaignForm?.controls?.name?.touched) && campaignForm?.controls?.name?.errors">
              <span i18n="@@enterValidInput">Enter valid input.</span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="form-group no-margin-hr">
            <label for="text-result"><span i18n="@@campaignObjectives">Campaign Objectives</span></label>
            <textarea class="form-control has-counter" #textareavalue1 formControlName="objectives"
            name="Objectives" placeholder="Enter text" i18n-placeholder="@@enterText" [attr.minLength]="10" [attr.maxLength]="1000"></textarea>
            <div class="textarea-counter float-right">
              <span class="value">{{textareavalue1?.value?.length}}</span>/1000
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="form-group no-margin-hr" [ngClass]="{ 'has-error': (campaignForm?.controls?.description?.dirty || campaignForm?.controls?.description?.touched) && campaignForm?.controls?.description?.errors}">
            <label for="text-result"><span i18n="@@description">Description</span> <span class="mandatory">*</span></label>
            <textarea class="form-control has-counter" #textareavalue2 formControlName="description"
            name="Description" placeholder="Enter text" i18n-placeholder="@@enterText" [attr.minLength]="10" [attr.maxLength]="1000" required></textarea>
            <div class="textarea-counter float-right">
              <span class="value">{{textareavalue2?.value?.length}}</span>/1000
            </div>
            <div class="error" *ngIf="(campaignForm?.controls?.description?.dirty || campaignForm?.controls?.description?.touched) && campaignForm?.controls?.description?.errors">
              <span i18n="@@enterValidInput">Enter valid input.</span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="form-group no-margin-hr">
            <label for="text-result"><span i18n="@@startDate">Start Date </span><span class="mandatory">*</span></label>
              <div class="form-group">
                <div class="dateIcon" (click)="isOpen = !isOpen">
                  <input bsDatepicker readonly placement="top" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue' }" [isOpen]="isOpen" class="form-control"
                  formControlName="startDate"  placeholder="DD-MM-YYYY" required>
                  <i class="utilicon utilicon-calendar-alt"></i>
                </div>
              </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group no-margin-hr">
                <label for="text-result" i18n="@@campaignStatus">Campaign Status</label>
                <select class="form-control" formControlName="status" name="status">
                  <option value="0" class="option" selected i18n="@@select">Select</option>
                  <option *ngFor="let item of campaignStatuses; trackBy: trackById" [ngValue]="item.id">{{item.name}}</option>
                </select>
              </div>
            </div>            
            <div class="col-lg-6 col-md-6">
              <div class="form-group no-margin-hr">
                <label for="text-result"><span i18n="@@campaignType">Campaign Type</span></label>
                <div class="form-group pl-4 pt-2">
                  <input type="checkbox" id="iterative" class="form-check-input cursorPointer" formControlName="campaignType" title="Select this option if you want to carry forward your data from previous survey as suggestions to the next survey" i18n-title="@@campaignSurveySuggestionOption" (click)="checkCampaignType($event, confirmTypePopup)" />
                  <label class="form-check-label" for="iterative" title="Select this option if you want to carry forward your data from previous survey as suggestions to the next survey" i18n-title="@@campaignSurveySuggestionOption" i18n="@@iterative">Iterative</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="form-group no-margin-hr">
            <label for="text-result"><span i18n="@@campaignResults">Campaign results</span></label>
            <textarea class="form-control has-counter" #textareavalue3 formControlName="results"
            name="Campaign results" placeholder="Enter text" i18n-placeholder="@@enterText" [attr.minLength]="10" [attr.maxLength]="1000"></textarea>
            <div class="textarea-counter float-right">
              <span class="value">{{textareavalue3?.value?.length}}</span>/1000
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="form-group no-margin-hr">
            <label for="text-result" i18n="@@endDate">End Date</label>
              <div class="form-group">
                <div class="dateIcon" (click)="isOpenEndDate = !isOpenEndDate">
                  <input bsDatepicker readonly placement="top" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue' }" [isOpen]="isOpenEndDate" class="form-control"
                  formControlName="endDate"  placeholder="DD-MM-YYYY"
                  [minDate]="campaignForm.value?.startDate">
                  <i class="utilicon utilicon-calendar-alt"></i>
                </div>
              </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="form-group no-margin-hr">
            <label for="text-result"><span i18n="@@closingRemarks">Closing remarks</span></label>
            <textarea class="form-control has-counter" #textareavalue4 formControlName="closingRemarks" disabled
            name="Closing remarks" placeholder="Enter text" i18n-placeholder="@@enterText" [attr.minLength]="10" [attr.maxLength]="1000"></textarea>
          </div>
        </div>
      </div>
    </div>

    <hr class="breakline">
    <div class="form-box actions">
      <button [disabled]="savingCampaign || !campaignForm.valid" *ngIf="campaign?.id > 0; else saveCampaignButton" class="btn btn-primary" i18n-title="@@updateCampaign" title="Update Campaign" type="submit" i18n="@@updateCampaign"> Update Campaign </button>
      <ng-template #saveCampaignButton>
        <button [disabled]="savingCampaign || !campaignForm.valid" class="btn btn-primary" i18n-title="@@saveCampaign" title="Save Campaigh" type="submit" i18n="@@saveCampaign"> Save Campaign </button>
      </ng-template>
      <button class="btn btn-secondary"  title="Cancel" i18n-title="@@cancel" i18n="@@cancel"  type="button" (click)="cancel(confirmCancelTemplate)">
        Cancel
      </button>
    </div>
  </form>
  <div *ngIf="projects?.length === 0">
    <p i18n="@@thereAreNoProjects">There are no projects</p>
  </div>
  <ng-template #confirmCancelTemplate>
    <div class="modal-body text-center">
      <h3 i18n="@@discardUnsavedChanges">
        Discard unsaved changes?
      </h3>
      <button type="button" title="Yes" i18n-title="@@yes" class="btn btn-secondary mr-1" (click)="confirmModal('yes')" i18n="@@yes">Yes</button>
      <button type="button" title="No" i18n-title="@@no" class="btn btn-primary cancel-focus" autofocus appAutofocus  (click)="confirmModal('no')" i18n="@@no">No</button>
    </div>
  </ng-template>
  <ng-template #confirmTypePopup>
    <div class="modal-body text-center">
      <h3 i18n="@@removingIteratingCampaignWarning">
        Removing iterative campaign type will stop suggesting answers from next survey.
      </h3>
      <button type="button" title="Ok" i18n-title="@@ok" class="btn btn-primary cancel-focus" autofocus appAutofocus  (click)="confirmTypeModal()" i18n="@@ok">Ok</button>
    </div>
  </ng-template>
</div>