import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingService } from '../../services/training.service';
import { ProjectService } from '../../services/project.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '../../services/user.service';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import Compressor from 'compressorjs';

@Component({
  selector: 'app-farmer-training-details',
  templateUrl: './farmer-training-details.component.html',
  styleUrls: ['./farmer-training-details.component.css']
})
export class FarmerTrainingDetailsComponent implements OnInit {
  @Input() farmer:any;
  @Input() isDesktopDevice: any;
  @Input() isCertificate:any;
  public isTrainingOpen = false;
  public isTrainingPopupOpen = false;  
  public isCertificateOpen = false;
  public isCertificatePopupOpen = false;
  public isExpiryOpen = false;
  public isExpiryPopupOpen = false;
  public dateFormat = 'DD-MMM-YYYY';
  uploadedTrainingFiles = [];
  groundForm:FormGroup;
  popupForm:FormGroup;
  trainings;
  farmerTrainings = [];
  currentTraining;
  addTrainingModal;
  projectId;
  trainers = [];
  trainingCertifications;
  allTrainingCertifications
  certifications;  
  projectTrainings;
  projectCertifications;
  allGroups;
  currentUser = this.userService.getCurrentUser();
  farmerId;
  currentTab = [];
  currentSection;
  currentSectionIndex;
  uploadedTrainingFilesOld = [];
  uploadedNewTrainingFiles = [];
  public confirmModalPopup = true;
  public confirmPopup:BsModalRef;
  private readonly subject = new Subject<boolean>();
  @ViewChild('exitConfirmTemplate') confirmTemplate: TemplateRef<any>;
  disablePopupAddButton = true;
  confirmAddModal = true;
  maxDate = new Date();  
  minDate = new Date();
  popupCertiAddButton = true;
  fileToDelete;
  public fileDeletePopup: BsModalRef;

  constructor(private readonly _route: ActivatedRoute,
    private readonly commonService: CommonService, 
    public toastrService: ToastrService,    
    private readonly formBuilder: FormBuilder,
    private readonly modalService: BsModalService,
    private readonly projectService: ProjectService,
    private readonly trainingService: TrainingService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly userService: UserService,
    private readonly router: Router,
  ) { }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'isCertificate'**************/
    //Write your code here    
    console.log('certication', this.isCertificate);
    this.farmerId = +this._route.snapshot.params['id'] || 0;
    this.projectId = +this._route.snapshot.params['projectId'];
    this.getTrainingCertifcationList();   
  } 

  ngOnInit() {
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.createTrainingForm();
  }

  async getTrainingCertifcationList(){
    const request = await Promise.all([
      this.trainingService.get_training_recordings_farmer(this.projectId, this.farmerId).toPromise(),
      this.trainingService.get_all_trainer(this.projectId).toPromise(),
      this.trainingService.getAllGroups(this.projectId).toPromise(),      
      this.trainingService.get_all_training_certification(this.projectId).toPromise()
    ]);
    if (request[0]['msg'] === 'success'){
      this.trainingCertifications = request[0]['data'];
    }
    if(this.currentUser.role.id <= 3){
      if (request[1]['msg'] === 'success'){
        this.trainers = request[1]['data'];
      }
    } else {
      const userObj = {
        users_id: this.currentUser.id,
        users : {
          name: this.currentUser.name
        }
      }
      this.trainers.push(userObj);
    }
    if (request[2]['msg'] === 'success'){
      this.allGroups = request[2]['data'];
    }
    if (request[3]['msg'] === 'success'){
      this.allTrainingCertifications = request[3]['data'];
    }
    this.projectTrainings = this.allTrainingCertifications.filter(item => 
      item.training_certification_master.training_certification_type == 'Training' && item.training_certification_master.status == 'Active');
    this.projectCertifications = this.allTrainingCertifications.filter(item => 
      item.training_certification_master.training_certification_type == 'Certification' && item.training_certification_master.status == 'Active');
    
    this.trainings = this.trainingCertifications.filter(item => item.training_certification_type == 'Training');
    this.certifications = this.trainingCertifications.filter(item => item.training_certification_type == 'Certification');
    if(this.isCertificate){
      this.currentTab = this.certifications;
    } else {
      this.currentTab = this.trainings;
    }
    this.initialize(0)
  }

  initialize(index){
    this.currentSectionIndex = index;
    this.subtabClicked(index);
  }

  subtabClicked(index, newGroup?){   
    if(this.currentTab.length > 0){ 
    this.spinner.show();
      this.currentSectionIndex = index;
      const headerID = this.currentTab[index].recording_hdr_id;
      let type;
      if(this.isCertificate){
        type = 'certification'
      } else {
        type = 'training'
      }
      this.trainingService.getIndividualTrainingCertificationDetails(this.projectId, this.farmerId, headerID, type).subscribe(result => {
        if (result['msg'] === 'success') {
          this.currentSection = result['data'];
          if(this.isCertificate){
            this.editCertificationForm();
          } else {
            this.editTrainingForm();
          }
        }
      });
    }
  }

  navigateSection(direction){
    const index = this.currentSectionIndex + direction;
    this.subtabClicked(index);
  }

  createTrainingForm(){
    this.groundForm = this.formBuilder.group({
      trainingName: new FormControl('0', [Validators.required]),
      description: new FormControl('', [Validators.maxLength(1000)]),
      trainerName: new FormControl('0'),
      trainingDate: new FormControl(null),
      group: new FormControl('0'),
      trainingAttended:new FormControl(''),
      spouse: new FormControl(''),
      children: new FormControl(''),
      certificateName : new FormControl('0', [Validators.required]),
      certificationDate : new FormControl(null),
      expiryDate : new FormControl(null),
      certified: new FormControl(null),
    });
  }
  
  editTrainingForm() {
    const obj = {
      trainingName: this.currentSection.project_training_certification_mapping_id,
      description: this.currentSection.description,
      trainerName: this.currentSection.trainer_id,
      trainingDate: new Date(this.currentSection.training_date),
      group: this.currentSection.training_recording_dtl[0].project_group_actor_mapping ? 
      this.currentSection.training_recording_dtl[0].project_group_actor_mapping.project_group_mapping_id : 0,
      trainingAttended: this.currentSection.training_recording_dtl[0].training_attended,
      spouse: this.currentSection.training_recording_dtl[0].spouse_attended,
      children: this.currentSection.training_recording_dtl[0].children_attended_above_16
    };
    this.groundForm.patchValue(obj);
    this.groundForm.markAsPristine();
    this.groundForm.controls['trainingName'].disable();
    this.groundForm.controls['trainerName'].disable();
    this.groundForm.controls['trainingDate'].disable();
    this.groundForm.controls['group'].disable();
    this.uploadedTrainingFilesOld = this.currentSection.training_documents_dtl

    this.spinner.hide();
  }

  editCertificationForm() {
    const obj = {
      certificateName: this.currentSection.project_training_certification_mapping_id,
      description: this.currentSection.description,
      certificationDate: new Date(this.currentSection.certification_date),
      expiryDate: this.currentSection.expiry_date ? new Date(this.currentSection.expiry_date) : null,
      group: this.currentSection.certification_recording_dtl[0].project_group_actor_mapping ? 
      this.currentSection.certification_recording_dtl[0].project_group_actor_mapping.project_group_mapping_id : 0,
      certified: this.currentSection.certification_recording_dtl[0].certified
    };
    this.groundForm.patchValue(obj);
    this.groundForm.markAsPristine();
    this.groundForm.controls['certificateName'].disable();
    this.groundForm.controls['certificationDate'].disable();
    this.groundForm.controls['group'].disable();
    this.spinner.hide();
  }

  updateTraining(){
    this.spinner.show();
    const trainingName = this.groundForm['value'].trainingName;
    const trainerName = this.groundForm['value'].trainerName;
    const trainingDate = this.groundForm['value'].trainingDate;
    const description = this.groundForm['value'].description;
    const trainingAttended = this.groundForm['value'].trainingAttended;
    const spouse = this.groundForm['value'].spouse;
    const children = this.groundForm['value'].children;
    const farmerData = [];
    const obj = {
      "id": this.currentSection.training_recording_dtl[0].id,
      "training_recording_hdr_id":  this.currentSection.id,
      "farmer_id": this.currentSection.training_recording_dtl[0].farmer_id,
      "project_group_actor_mapping_id": this.currentSection.training_recording_dtl[0].project_group_actor_mapping_id,
      "training_attended": trainingAttended ? 1 : 0,
      "spouse_attended": spouse ? 1 : 0,
      "children_attended_above_16": children ? 1 : 0,
      "status": "Active"
    }
    farmerData.push(obj);
    const documentData = this.uploadedTrainingFiles;
    const trainingToAdd = {
      "id": this.currentSection.id,
      "project_training_certification_mapping_id": trainingName,
      "trainer_id": trainerName,
      "training_date": trainingDate,
      "description": description,
      "status": "Active",
      "for_individual":this.currentSection.for_individual,
      "training_recording_dtl": farmerData,
      "training_documents_dtl": documentData
    }
    console.log('trainingToAdd', trainingToAdd);
    this.trainingService.updateIndividualTraining(this.projectId, this.farmerId, trainingToAdd).subscribe(
      (result) => {
        this.commonService.showToast('success', 'training_updated_successfully', {});
        this.subtabClicked(this.currentSectionIndex);
        this.uploadedTrainingFiles = [];
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  addNewTraining(){
    this.spinner.show();
    const trainingName = this.popupForm['value'].trainingName;
    const trainerName = this.popupForm['value'].trainerName;
    const trainingDate = this.popupForm['value'].trainingDate;
    const description = this.popupForm['value'].description;
    const spouse = this.popupForm['value'].spouse;
    const children = this.popupForm['value'].children;
    const farmerData = [];
    const obj = {
      "farmer_id": this.farmerId,
      "project_group_actor_mapping_id": null,
      "training_attended": 1,
      "spouse_attended": spouse ? 1 : 0,
      "children_attended_above_16": children ? 1 : 0
    }
    farmerData.push(obj);
    const documentData = this.uploadedNewTrainingFiles;
    const trainingToAdd = {
      "project_training_certification_mapping_id": trainingName,
      "trainer_id": trainerName,
      "training_date": trainingDate,
      "description": description,
      "status": "Active",
      "for_individual":1,
      "training_recording_dtl": farmerData,
      "training_documents_dtl": documentData
    }
    console.log('trainingToAdd', trainingToAdd);
    this.trainingService.recordIndividualTraining(this.projectId, this.farmerId, trainingToAdd).subscribe(
      (result) => {
        this.commonService.showToast('success', 'training_created_successfully', {});
        this.confirmCloseAddTrainingPopup();
        this.getTrainingCertifcationList();
        this.uploadedNewTrainingFiles = [];
        this.spinner.hide();
      },
      (error) => {
        this.confirmCloseAddTrainingPopup();
        this.spinner.hide();
      }
    );
  }

  updateCertification(){
    this.spinner.show();
    const certificateName = this.currentSection.project_training_certification_mapping_id;
    const certificationDate = this.groundForm['value'].certificationDate;
    const expiryDate = this.groundForm['value'].expiryDate;
    const description = this.groundForm['value'].description;
    const certified = this.groundForm['value'].certified;
    const farmerData = [];
    const obj = {
      "id": this.currentSection.certification_recording_dtl[0].id,
      "certification_recording_hdr_id":  this.currentSection.id,
      "farmer_id": this.currentSection.certification_recording_dtl[0].farmer_id,
      "project_group_actor_mapping_id": this.currentSection.certification_recording_dtl[0].project_group_actor_mapping_id,
      "certified": certified ? 1 : 0
    }
    farmerData.push(obj);
    const trainingToAdd = {
      "id": this.currentSection.id,
      "project_training_certification_mapping_id": certificateName,
      "certification_date": certificationDate,
      "expiry_date": expiryDate,
      "description": description,
      "for_individual":this.currentSection.for_individual,
      "certification_recording_dtl": farmerData
    }
    console.log('trainingToAdd', trainingToAdd);
    this.trainingService.updateIndividualCertification(this.projectId, this.farmerId, trainingToAdd).subscribe(
      (result) => {
        this.commonService.showToast('success', 'certification_updated_successfully', {});
        this.subtabClicked(this.currentSectionIndex);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  addNewCertification(){
    this.spinner.show();
    const certificateName = this.popupForm['value'].certificateName;
    const certificationDate = this.popupForm['value'].certificationDate;
    const expiryDate = this.popupForm['value'].expiryDate;
    const description = this.popupForm['value'].description;
    const farmerData = [];
    const obj = {
      "farmer_id": this.farmerId,
      "project_group_actor_mapping_id": null,
      "certified": 1
    }
    farmerData.push(obj);
    const trainingToAdd = {
      "project_training_certification_mapping_id": certificateName,
      "certification_date": certificationDate,
      "expiry_date": expiryDate,
      "description": description,
      "for_individual":1,
      "certification_recording_dtl": farmerData
    }
    console.log('trainingToAdd', trainingToAdd);
    this.trainingService.recordIndividualCertification(this.projectId, this.farmerId, trainingToAdd).subscribe(
      (result) => {
        this.commonService.showToast('success', 'certification_created_successfully', {});
        this.confirmCloseAddTrainingPopup();
        this.getTrainingCertifcationList();
        this.uploadedNewTrainingFiles = [];
        this.spinner.hide();
      },
      (error) => {
        this.confirmCloseAddTrainingPopup();
        this.spinner.hide();
      }
    );
  }

  removeTrainingFile(fileName, template){
    const file ={ 
      isOld : false,
      file : fileName
    }
    this.fileToDelete = file;
    this.fileDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false});
  }

  removeTrainingFileOld(id, template){
    const file ={ 
      isOld : true,
      file : id
    }
    this.fileToDelete = file;
    this.fileDeletePopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  confirmFileDelete(decision){
    if(decision == "Yes"){
      console.log(this.fileToDelete.isOld)
      if(this.fileToDelete.isOld){
        const id = this.fileToDelete.file
        this.trainingService.deleteTrainingDocument(id, {}).subscribe(
          (result) => {
            this.commonService.showToast('success', 'file_deleted_successfully', {});
            this.uploadedTrainingFilesOld = this.uploadedTrainingFilesOld.filter(item => item.id !== id);
            this.fileDeleteReset();
          },
          (error) => {        
            this.commonService.showToast('error', error, {});
            this.fileDeleteReset();
          }
        );
      } else {
        const x = this.uploadedTrainingFiles.find(item => item.image_for == this.fileToDelete.file);
        const index = this.uploadedTrainingFiles.indexOf(x);
        if (index !== -1) {
          this.uploadedTrainingFiles.splice(index, 1);    
        } 
        this.fileDeleteReset();
      }
    } else {
      this.fileDeleteReset();
    }
  }
  fileDeleteReset(){
    this.fileDeletePopup.hide();
    this.fileToDelete = null;
  }

  downloadImage(imageBase64, fileName){
    this.downloadPdf(imageBase64,fileName);
  }

  downloadPdf(base64String, fileName) {
    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = fileName;
    link.click();
  }

   /* file upload */
   handleNewTrainingFileInput(file: FileList) {
    const fileItem = file.item(0);
    
    const msg = this.commonService.ValidateFile(fileItem);

    if (msg && msg.length > 0) {
      this.toastrService.warning(msg);
      return;
    }
    new Compressor(fileItem, {
      quality: environment.imageCompress.quality,
      maxWidth:environment.imageCompress.maxWidth,
      maxHeight:environment.imageCompress.maxHeight,
      mimeType:environment.imageCompress.mimeType,
      convertSize:environment.imageCompress.convertSize,
      success(result) {
        setFarmerImageData(result);
      },
      error(err) {
        console.log(err.message);
      },
    });

    const setFarmerImageData = (result) => {
      this.commonService.blobToBase64(result).then((imageData)=>{
        const obj = {
          "image_file": imageData,
          "image_for" : fileItem.name
        }
        this.uploadedNewTrainingFiles.push(obj);
      });
    };


  }
  removeNewTrainingFile(fileName){
    const x = this.uploadedNewTrainingFiles.find(item => item.image_for == fileName);
    const index = this.uploadedNewTrainingFiles.indexOf(x);
    if (index !== -1) {
      this.uploadedNewTrainingFiles.splice(index, 1);    
    }
  }

  addTrainingCertificate(template){
    this.popupForm = this.formBuilder.group({
      trainingName: new FormControl('0', [Validators.required]),
      description: new FormControl('', [Validators.maxLength(1000)]),
      trainerName: new FormControl('0', [Validators.required]),
      trainingDate: new FormControl(null, [Validators.required]),
      group: new FormControl('0', [Validators.required]),
      trainingAttended: new FormControl({value: true, disabled: true}),
      spouse: new FormControl(''),
      children: new FormControl(''),
      certificateName : new FormControl('0', [Validators.required]),
      certificationDate : new FormControl(null),
      expiryDate : new FormControl(null),
      certified: new FormControl({value: true, disabled: true}),
    });
    this.addTrainingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
  }
  exitClicked(myForm: FormGroup, template: TemplateRef<any>){
    if(myForm.dirty){
      this.confirmModalPopup = false;
      this.confirmPopup = this.modalService.show(template, {backdrop: true, ignoreBackdropClick: true, keyboard: false});
    }else{
      const url = 'farmers/' + this.projectId;
      this.router.navigate([url]);
    }
  } 

  /* file upload */
  handleTrainingFileInput(file: FileList) {
    // // debugger;
    const fileItem = file.item(0);
    
    const msg = this.commonService.ValidateFile(fileItem);

    if (msg && msg.length > 0) {
      this.toastrService.warning(msg);
      return;
    }
    console.log('file', fileItem)
    new Compressor(fileItem, {
      quality: environment.imageCompress.quality,
      maxWidth:environment.imageCompress.maxWidth,
      maxHeight:environment.imageCompress.maxHeight,
      mimeType:environment.imageCompress.mimeType,
      convertSize:environment.imageCompress.convertSize,
      success(result) {
        setFarmerImageData(result);
      },
      error(err) {
        console.log(err.message);
      },
    });

    const setFarmerImageData = (result) => {
      this.commonService.blobToBase64(result).then((imageData)=>{
        const obj = {
          "image_file": imageData,
          "image_for" : fileItem.name
        }
        this.uploadedTrainingFiles.push(obj);
      });
    };


  }

  confirmExitModal(item){
    if(item === 'Yes'){
        this.confirmModalPopup = true;
        if (this.confirmPopup) {
          this.confirmPopup.hide();
        }
        const url = 'farmers/' + this.projectId;
        this.router.navigate([url]);
    }else if(item === 'No'){
      this.confirmModalPopup = true;
      if (this.confirmPopup) {
        this.confirmPopup.hide();
      }
    }
  }

  closeAddTrainingPopup(){
    if (this.popupForm && this.popupForm.dirty) {
      this.confirmAddModal = false;
    } else {
      this.confirmCloseAddTrainingPopup();
    }
  }

  confirmAddClose(decision){
    if(decision == 'Yes'){
      this.confirmCloseAddTrainingPopup();
    } else {
      this.confirmAddModal = true;
    }
  }

  confirmCloseAddTrainingPopup(){
    if(this.addTrainingModal){
      this.addTrainingModal.hide();
      this.confirmAddModal = true;
    }
  }

  showPopupAddButton(){
    if(this.popupForm['value'].trainingName != 0 && this.popupForm['value'].trainerName != 0 && this.popupForm['value'].trainingDate != null){
      this.disablePopupAddButton = false;
    } else {
      this.disablePopupAddButton = true;
    }
  }

  showCertiPopupAddButton(){
    if(this.popupForm['value'].certificateName != 0 && this.popupForm['value'].certificationDate != null){
      this.popupCertiAddButton = false;
    } else {
      this.popupCertiAddButton = true;
    }
  }

  trackBytraining(index, dataItem) {
    return dataItem.id;
  }
  trackBytrainer(index, dataItem) {
    return dataItem.id;
  }

  canDeactivate(): Observable<boolean> | boolean {    
    if (this.groundForm && this.groundForm.dirty) {
      this.confirmPopup = this.modalService.show(this.confirmTemplate, { backdrop: true, ignoreBackdropClick: true });
      this.spinner.hide();
      return this.subject.asObservable();
    }
    return true;
  }

  checkTrainingAttended(e){
    if(e.target.checked){
      this.groundForm.patchValue({
        trainingAttended: true
      });
    }
  }

}
