import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, TemplateRef, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { mapTo } from 'rxjs/operators';
import { fromEvent, merge, of } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { SurveyService } from '../../services/survey.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Location } from '@angular/common';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserService } from '../../services/user.service';
var ProjectinfoComponent = /** @class */ (function () {
    function ProjectinfoComponent(route, commonService, router, document, farmerService, projectService, translate, toastrService, modalService, localeService, formBuilder, revisionService, spinner, userService, surveyService, loc) {
        var _this = this;
        this.route = route;
        this.commonService = commonService;
        this.router = router;
        this.document = document;
        this.farmerService = farmerService;
        this.projectService = projectService;
        this.translate = translate;
        this.toastrService = toastrService;
        this.modalService = modalService;
        this.localeService = localeService;
        this.formBuilder = formBuilder;
        this.revisionService = revisionService;
        this.spinner = spinner;
        this.userService = userService;
        this.surveyService = surveyService;
        this.loc = loc;
        this.projectId = 0;
        this.farmers = [];
        this.productType = [];
        this.managers = [];
        this.farmerCount = 0;
        this.isAscending = true;
        this.isAscendingId = true;
        this.isAscendingName = true;
        this.isAscendingTel = true;
        this.isAscendingDate = true;
        this.sortLabel = '';
        this.showSpinner = false;
        this.isFieldAgent = false;
        this.isManager = false;
        this.isDeeproots = false;
        this.farmerFilterString = { name: '' };
        this.page = 1;
        this.itemsPerPage = 10;
        this.currentUser = this.userService.getCurrentUser();
        this.allSurveys = [];
        this.snapshotSurveys = [];
        this.campaignSurveys = [];
        this.isInIDBInThisProject = [];
        this.showSyncSuccessAlert = false;
        this.olderSnapshots = [];
        this.minDate = new Date();
        this.noSnapshotsInProject = false;
        this.fetchingSnapshotInfo = true;
        this.disableCreateSnapshot = false;
        this.disableCloseSnapshot = false;
        this.registeringFarmer = false;
        this.registerAnotherFarmer = false;
        this.fileToUpload = null;
        this.photoModel = '';
        this.projectAdmin = '';
        this.oldSnapshotId = 0;
        this.arrSnapshotRevisions = [];
        this.snapshotRevision = [];
        this.isQuestionnare = false;
        this.locale = 'en';
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
        this.translate.get('revision').subscribe(function (result) {
            _this.revisionTxt = result;
        });
        this.translate.get('created_successfully').subscribe(function (result) {
            _this.createSuccessTxt = result;
        });
    }
    ProjectinfoComponent.prototype.snapshotChanged = function () {
        var _this = this;
        this.latestRevision = this.arrSnapshotRevisions.filter(function (x) { return x.snapshot_id_ref === _this.oldSnapshotId; }).slice(-1).pop();
    };
    ProjectinfoComponent.prototype.scrollToTop = function () {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    ProjectinfoComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var code, objProjectMapping, farmertmpobj, request3, requests, objProjectMapping, farmertmpobj, mappings, canCreateSnapshot, canCreateSnapshot, request4;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scrollToTop();
                        this.projectId = +this.route.snapshot.params['id'];
                        code = this.commonService.checkRole();
                        if (code === 'FAG') {
                            this.isFieldAgent = true;
                        }
                        else if (code === 'PMG') {
                            this.isManager = true;
                        }
                        else if (code === 'SYS1') {
                            this.isDeeproots = true;
                        }
                        this.locale = this.loc.prepareExternalUrl('').replace(/\//g, '');
                        if (!navigator.onLine) return [3 /*break*/, 2];
                        this.project = this.route.snapshot.data.project.project;
                        this.farmerCount = this.project.farmerCount;
                        this.setSurveys();
                        // this.farmers = this.route.snapshot.data.project.farmers.data;
                        // const request2 = await Promise.all([
                        //   this.revisionService.GetSnapshotRevision(this.project['id']).toPromise()
                        // ]);
                        // this.arrSnapshotRevisions = request2[0].data;
                        if (this.project.ProjectMapping) {
                            objProjectMapping = this.project.ProjectMapping;
                            this.projectAdmin = objProjectMapping.filter(function (x) { return x.user_type_id === 3; }).map(function (y) { return y.users.name; }).join();
                        }
                        if (this.project.Product) {
                            if (this.project.Product.product_type) {
                                if (this.locale == 'hi') {
                                    this.project.Product.product_type.name = this.project.Product.product_type.name_hi;
                                }
                                else if (this.locale == 'es') {
                                    this.project.Product.product_type.name = this.project.Product.product_type.name_es;
                                }
                                else if (this.locale == 'fr') {
                                    this.project.Product.product_type.name = this.project.Product.product_type.name_fr;
                                }
                                else if (this.locale == 'mg') {
                                    this.project.Product.product_type.name = this.project.Product.product_type.name_mg;
                                }
                                else if (this.locale == 'ta') {
                                    this.project.Product.product_type.name = this.project.Product.product_type.name_ta;
                                }
                                else if (this.locale == 'zh') {
                                    this.project.Product.product_type.name = this.project.Product.product_type.name_zh;
                                }
                                else if (this.locale == 'id') {
                                    this.project.Product.product_type.name = this.project.Product.product_type.name_id;
                                }
                            }
                        }
                        farmertmpobj = (idbApp.getAllProjects('farmerstmp')).then(function (obj) {
                            return obj;
                        });
                        farmertmpobj.then(function (farmertmpobj) {
                            var idbAppFarmers = farmertmpobj.find(function (x) { return x.project_id === _this.projectId && x.savetab === 'Tab0'; });
                            if (idbAppFarmers && idbAppFarmers.length > 0) {
                                idbAppFarmers.sort(function (a, b) {
                                    var aDate = new Date(a.cr_date);
                                    var bDate = new Date(b.cr_date);
                                    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
                                });
                                // this.farmers = idbAppFarmers.concat(this.farmers);
                                _this.farmerCount = _this.farmerCount + idbAppFarmers.length;
                            }
                        });
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.GetFarmerMaster().toPromise()
                            ])];
                    case 1:
                        request3 = _a.sent();
                        this.master = request3[0].data;
                        return [3 /*break*/, 4];
                    case 2:
                        this.project = this.route.snapshot.data.project.project;
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                                this.farmerService.GetFarmerMaster().toPromise()
                            ])];
                    case 3:
                        requests = _a.sent();
                        this.setSurveys();
                        this.farmerCount = this.project.farmerCount;
                        // const request2 = await Promise.all([
                        //   this.revisionService.GetSnapshotRevision(this.project['id']).toPromise()
                        // ]);
                        // this.arrSnapshotRevisions = request2[0].data;
                        if (this.project.ProjectMapping) {
                            objProjectMapping = this.project.ProjectMapping;
                            this.projectAdmin = objProjectMapping.filter(function (x) { return x.user_type_id === 3; }).map(function (y) { return y.users.name; }).join();
                        }
                        farmertmpobj = (idbApp.getAllProjects('farmerstmp')).then(function (obj) {
                            return obj;
                        });
                        farmertmpobj.then(function (farmertmpobj) {
                            var idbAppFarmers = farmertmpobj.filter(function (x) { return x.project_id === _this.projectId && x.savetab === 'Tab0'; });
                            if (idbAppFarmers && idbAppFarmers.length > 0) {
                                idbAppFarmers.sort(function (a, b) {
                                    var aDate = new Date(a.cr_date);
                                    var bDate = new Date(b.cr_date);
                                    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
                                });
                                // this.farmers = idbAppFarmers.concat(this.farmers);
                                _this.farmerCount = _this.farmerCount + idbAppFarmers.length;
                            }
                        });
                        this.master = requests[1].data;
                        _a.label = 4;
                    case 4:
                        this.sex = this.master.filter(function (x) { return x.item_tab === 'farmer_reg' && x.item_code === 'sex'; });
                        mappings = this.project.ecosystem_services_components_mapping ? this.project.ecosystem_services_components_mapping.map(function (x) { return x.services; }) : this.project.supplier_project_services_components_mapping.map(function (x) { return x.services; });
                        this.project.displayedServices = mappings.reduce(function (unique, o) {
                            if (!unique.some(function (obj) { return obj.code === o.code; })) {
                                unique.push(o);
                            }
                            return unique;
                        }, []);
                        if (this.currentUser.role.id > 3) {
                            // For all other types of users, check whether they have been given access to that component during the project setup
                            if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
                                canCreateSnapshot = this.project.supplier_project_services_components_mapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id === 6 && item.user_type_id == _this.currentUser.role.id); });
                                if (canCreateSnapshot && canCreateSnapshot.length > 0) {
                                    this.isQuestionnare = true;
                                }
                            }
                        }
                        else {
                            if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
                                canCreateSnapshot = this.project.ecosystem_services_components_mapping.filter(function (item) { return (item.components_id == 6); });
                                if (canCreateSnapshot && canCreateSnapshot.length > 0) {
                                    this.isQuestionnare = true;
                                }
                            }
                        }
                        return [4 /*yield*/, Promise.all([
                                this.commonService.getProductType().toPromise(),
                                this.commonService.getProjectManager().toPromise(),
                            ]).then(function (x) {
                                _this.productType = x[0].data;
                                _this.managers = x[1].data;
                            }).catch(function (y) { })];
                    case 5:
                        request4 = _a.sent();
                        this.registerFarmerForm = this.formBuilder.group({
                            name: new FormControl('', [Validators.required]),
                            age: new FormControl('', [Validators.required, Validators.min(18), Validators.max(99)]),
                            sex: new FormControl('', [Validators.required]),
                            area: new FormControl('', [Validators.required]),
                            telNumber: new FormControl('', [Validators.min(1000000000), Validators.max(999999999999)]),
                            photo_path: new FormControl('')
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectinfoComponent.prototype.setSnapshots = function () {
        var currentSnapshotArray = this.allSnapshots.filter(function (item) { return item.status === 'draft' || item.status === 'active'; });
        if (currentSnapshotArray && currentSnapshotArray.length > 0) {
            this.snapshot = currentSnapshotArray[0];
        }
        else {
            this.snapshot = undefined;
        }
        this.olderSnapshots = this.allSnapshots.filter(function (item) { return item.status === 'closed' || item.status === 'active'; });
        var activeSnapshot = this.allSnapshots.find(function (item) { return item.status === 'active'; });
        if (activeSnapshot) {
            this.oldSnapshotId = activeSnapshot.id;
        }
        else {
            this.oldSnapshotId = 0;
        }
        this.snapshotChanged();
    };
    ProjectinfoComponent.prototype.syncDone = function (args) {
        if (navigator.onLine) {
            window.dispatchEvent(new Event('caching-now'));
            this.farmerService.getFarmersByProjectBasicData(this.projectId).subscribe(function (farmers) {
                // this.farmers = farmers.data;
                window.dispatchEvent(new Event('data-synced'));
            });
        }
    };
    ProjectinfoComponent.prototype.goToDashboard = function () {
        this.router.navigate(['dashboard']);
    };
    ProjectinfoComponent.prototype.goToViewFarmer = function (farmer) {
        var url;
        if (farmer.id && farmer.id > 0) {
            url = "/viewprofile/" + farmer.project_id + "/" + farmer.id;
        }
        else if (farmer.tmp_id) {
            url = "/viewprofile/" + farmer.project_id + "/" + farmer.tmp_id;
        }
        else {
            url = '/error';
        }
        this.router.navigate([url]);
    };
    ProjectinfoComponent.prototype.ngAfterViewInit = function () {
        if (this.project && this.project.QuesProjectSnapshotMappings && this.project.QuesProjectSnapshotMappings.length > 0) {
            // If the active snapshot is in draft stage, fetch complete QuesProjectSnapshotMappings object from server
            // If the active snapshot is not in draft stage, fetch the complete QuesProjectSnapshotMappings object from the cache...
            // ... have to wait for the managerpage ngAfterViewInit function to finish getting the response for this to work
        }
    };
    ProjectinfoComponent.prototype.ngOnDestroy = function () {
        this.showSpinner = false;
        clearInterval(this.getSnapshotInterval);
    };
    ProjectinfoComponent.prototype.goToEditProject = function () {
        if (navigator.onLine) {
            var url = "setup/" + this.project.id;
            this.router.navigate([url]);
        }
        else {
            this.toastrService.warning('You cannot edit a project while offline');
        }
    };
    ProjectinfoComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    ProjectinfoComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        if (sortBy === 'name') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.farmers.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.farmers.sort(function (a, b) {
                if (a.id < b.id) {
                    return 1;
                }
                if (a.id > b.id) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'tel') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.farmers.sort(function (a, b) {
                if (a.tel_number < b.tel_number) {
                    return 1;
                }
                if (a.tel_number > b.tel_number) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'date') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.farmers.sort(function (a, b) {
                if (a.cr_date < b.cr_date) {
                    return 1;
                }
                if (a.cr_date > b.cr_date) {
                    return -1;
                }
                return 0;
            });
        }
    };
    ProjectinfoComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        if (sortBy === 'name') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.farmers.sort(function (a, b) {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.farmers.sort(function (a, b) {
                if (a.id > b.id) {
                    return 1;
                }
                if (a.id < b.id) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'tel') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.farmers.sort(function (a, b) {
                if (a.tel_number > b.tel_number) {
                    return 1;
                }
                if (a.tel_number < b.tel_number) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'date') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.farmers.sort(function (a, b) {
                if (a.cr_date > b.cr_date) {
                    return 1;
                }
                if (a.cr_date < b.cr_date) {
                    return -1;
                }
                return 0;
            });
        }
    };
    ProjectinfoComponent.prototype.updateSyncStatusShown = function (args) {
        var _this = this;
        for (var i = 0; i < this.farmers.length; i++) {
            // if there is a non-zero farmer_id in the allData element, it is an update object
            this.farmers[i]['isInIDB'] = false;
        }
        var numberOfUnsyncedFarmers = this.isInIDBInThisProject.length;
        this.isInIDBInThisProject = [];
        if (numberOfUnsyncedFarmers && numberOfUnsyncedFarmers > 0) {
            numberOfUnsyncedFarmers = 0;
            this.showSyncSuccessAlert = true;
            setTimeout(function () {
                _this.showSyncSuccessAlert = false;
            }, 5000);
        }
    };
    ProjectinfoComponent.prototype.freezeQuestionnaire = function () {
        var url = "edit-questionnaire/" + this.project.id + "/" + this.snapshot.id;
        this.router.navigate([url]);
    };
    ProjectinfoComponent.prototype.closeSnapshot = function (template) {
        this.closeSnapshotModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    ProjectinfoComponent.prototype.cancelCloseSnapshot = function () {
        if (this.closeSnapshotModal) {
            this.closeSnapshotModal.hide();
        }
    };
    // confirmCloseSnapshot(): void {
    //   this.disableCloseSnapshot = true;
    //   if (this.closeSnapshotModal) { 
    //     this.closeSnapshotModal.hide(); 
    //   }
    //   this.projectService.closeSnapshot(this.snapshot['id'])
    //     .subscribe(response => {
    //       if (response.status === 'success') {
    //         const closedSnapshotArray = this.allSnapshots.filter(item => item.id === response.data.id);
    //         if (closedSnapshotArray && closedSnapshotArray.length > 0) {
    //           const closedSnapshot = closedSnapshotArray[0];
    //           const index = this.allSnapshots.indexOf(closedSnapshot);
    //           this.allSnapshots[index] = response.data;
    //           // this.setSnapshots();
    //         }
    //         this.commonService.updateSnapshotStore({ project: this.projectId, snapshot: response.data });
    //         this.disableCloseSnapshot = false;
    //       }
    //     }, error => {
    //       this.disableCloseSnapshot = false;
    //     })
    // }
    ProjectinfoComponent.prototype.declineCloseSnapshot = function () {
        if (this.closeSnapshotModal) {
            this.closeSnapshotModal.hide();
        }
    };
    // newSnapshot(): void {
    //   this.disableCreateSnapshot = true;
    //   this.projectService.newSnapshot(this.projectId)
    //     .subscribe(result => {
    //       if (result.status === 'success') {
    //         if (this.allSnapshots && this.allSnapshots.length > 0) {
    //           this.allSnapshots.push(result.data);
    //         } else {
    //           this.allSnapshots = [];
    //           this.allSnapshots.push(result.data);
    //         }
    //         // this.setSnapshots();
    //         const snapshotToUpdate = this.snapshot;
    //         const mappingObjectJSON = JSON.parse(snapshotToUpdate.mapping_object);
    //         snapshotToUpdate.mapping_object = mappingObjectJSON;
    //         this.commonService.updateSnapshotStore({ project: this.projectId, snapshot: snapshotToUpdate });
    //         this.disableCreateSnapshot = false;
    //       }
    //     })
    // }
    ProjectinfoComponent.prototype.modifySnapshot = function (template) {
        this.oldSnapshotInfo = JSON.stringify({
            snapshot_name: this.snapshot.snapshot_name,
            start_date: this.snapshot.start_date,
            end_date: this.snapshot.end_date
        });
        this.snapshotModifying = this.snapshot;
        this.bsDateRange = [this.snapshotModifying['start_date'] ? new Date(this.snapshotModifying['start_date']) : null, this.snapshotModifying['end_date'] ? new Date(this.snapshotModifying['end_date']) : null];
        this.modifySnapshotModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    ProjectinfoComponent.prototype.declineModifySnapshot = function () {
        var oldSnapshotInfo = JSON.parse(this.oldSnapshotInfo);
        this.snapshot.snapshot_name = oldSnapshotInfo.snapshot_name;
        this.snapshot.start_date = oldSnapshotInfo.start_date;
        this.snapshot.end_date = oldSnapshotInfo.end_date;
        this.snapshotModifying = this.snapshot;
        if (this.modifySnapshotModal) {
            this.modifySnapshotModal.hide();
        }
    };
    ProjectinfoComponent.prototype.confirmModifySnapshot = function () {
        var _this = this;
        this.projectService.updateSnapshot(this.snapshotModifying)
            .subscribe(function (response) {
            if (response.status === 'success') {
                var modifiedSnapshotArray = _this.allSnapshots.filter(function (item) { return item.id === response.data.id; });
                if (modifiedSnapshotArray && modifiedSnapshotArray.length > 0) {
                    var modifiedSnapshot = modifiedSnapshotArray[0];
                    var index = _this.allSnapshots.indexOf(modifiedSnapshot);
                    _this.allSnapshots[index] = response.data;
                    // this.setSnapshots();
                }
                if (_this.modifySnapshotModal) {
                    _this.modifySnapshotModal.hide();
                }
                _this.commonService.updateSnapshotStore({ project: _this.projectId, snapshot: response.data });
            }
        });
    };
    ProjectinfoComponent.prototype.bsRangeValueChange = function (args) {
        if (args) {
            if (args[0]) {
                if (args[0].getTime() !== 0) {
                    this.snapshotModifying['start_date'] = args[0];
                }
            }
            if (args[1]) {
                if (args[1].getTime() !== 0) {
                    this.snapshotModifying['end_date'] = args[1];
                }
            }
        }
    };
    ProjectinfoComponent.prototype.applyLocaleToDatePicker = function (pop) {
        pop.hide();
        pop.show();
    };
    ProjectinfoComponent.prototype.openRegisterFarmerModal = function (template) {
        this.registerFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    ProjectinfoComponent.prototype.registerFarmer = function () {
        var _this = this;
        this.registeringFarmer = true;
        if (this.snapshot.status === 'active') {
            var name_1 = this.registerFarmerForm['value'].name;
            var age = this.registerFarmerForm['value'].age;
            var sex = this.registerFarmerForm['value'].sex;
            var area = this.registerFarmerForm['value'].area;
            var telNumber = this.registerFarmerForm['value'].telNumber;
            if (!name_1 || name_1.length === 0 || !age || age.length === 0 || !sex || sex.length === 0 || !area || area.length === 0) {
                return;
            }
            var farmerToRegister_1 = {
                name: name_1,
                age: age,
                sex: sex,
                village_area: area,
                tel_number: telNumber,
                photo_path: null,
                snapshot_id_ref: this.snapshot.id,
                project_id: this.projectId
            };
            if (navigator.onLine) {
                if (this.fileToUpload) {
                    var formData = new FormData();
                    formData.append('type', 'farmerAvatar');
                    formData.append('projectId', this.projectId);
                    formData.append('avatar', this.fileToUpload, this.fileToUpload.name);
                    this.projectService.Upload(formData)
                        .subscribe(function (obj) {
                        if (obj.msg === 'success') {
                            farmerToRegister_1['photo_path'] = obj.data;
                            _this.farmerService.registerFarmer(farmerToRegister_1).subscribe(function (result) {
                                _this.registeringFarmer = false;
                                if (result.msg === 'success') {
                                    result.data.cr_date = new Date();
                                    _this.farmers.unshift(result.data);
                                    if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                                        _this.registerFarmerModal.hide();
                                    }
                                    _this.registerFarmerForm.setValue({
                                        name: '',
                                        age: '',
                                        sex: '',
                                        area: '',
                                        telNumber: '',
                                        photo_path: ''
                                    });
                                    _this.registerFarmerForm.markAsUntouched();
                                    _this.registerFarmerForm.markAsPristine();
                                    _this.fileToUpload = undefined;
                                }
                                else {
                                    if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                                        _this.registerFarmerModal.hide();
                                    }
                                }
                            }, function (error) {
                                if (_this.registerFarmerModal) {
                                    _this.registerFarmerModal.hide();
                                }
                            });
                        }
                    });
                }
                else {
                    this.farmerService.registerFarmer(farmerToRegister_1).subscribe(function (result) {
                        _this.registeringFarmer = false;
                        if (result.msg === 'success') {
                            result.data.cr_date = new Date();
                            _this.farmers.unshift(result.data);
                            if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                                _this.registerFarmerModal.hide();
                            }
                            _this.registerFarmerForm.setValue({
                                name: '',
                                age: '',
                                sex: '',
                                area: '',
                                telNumber: '',
                                photo_path: ''
                            });
                            _this.registerFarmerForm.markAsUntouched();
                            _this.registerFarmerForm.markAsPristine();
                            _this.fileToUpload = undefined;
                            _this.commonService.showToast('success', 'farmer_registration_successful', {});
                        }
                        else {
                            if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                                _this.registerFarmerModal.hide();
                            }
                        }
                    }, function (error) {
                        if (_this.registerFarmerModal) {
                            _this.registerFarmerModal.hide();
                        }
                    });
                }
            }
            else {
                var finalObj_1 = {};
                var farmerObj_1 = farmerToRegister_1;
                Object.keys(farmerObj_1).forEach(function (key) {
                    if (typeof farmerObj_1[key] === 'object' && farmerObj_1[key] != null) {
                        if (farmerObj_1[key].length && farmerObj_1[key].length > 0) {
                            //finalObj[key] = {};
                            finalObj_1[key] = farmerObj_1[key];
                        }
                        // else {
                        //   finalObj[key] = farmerObj[key];
                        // }
                    }
                    else {
                        finalObj_1[key] = farmerObj_1[key];
                    }
                });
                var farmObj = new Array();
                finalObj_1['tmp_id'] = "FA" + this.commonService.getRandomNum().toString().slice(0, 10) + "_Tab0";
                finalObj_1['savetab'] = 'Tab0';
                finalObj_1['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
                finalObj_1['cr_date'] = new Date().getTime();
                finalObj_1['id'] = 0;
                farmObj[farmObj.length] = finalObj_1;
                idbApp.addStoreInfo(farmObj, 'farmerstmp');
                this.commonService.showToast('info', 'offline_sync_later', {});
                this.showSpinner = false;
                this.registerFarmerForm.markAsPristine();
                this.registerSyncEvent();
                this.updateCache();
                farmerToRegister_1['cr_date'] = new Date();
                farmerToRegister_1['tmp_id'] = finalObj_1['tmp_id'];
                this.farmers.unshift(farmerToRegister_1);
                if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                    this.registerFarmerModal.hide();
                }
                this.registerFarmerForm.setValue({
                    name: '',
                    age: '',
                    sex: '',
                    area: '',
                    telNumber: '',
                    photo_path: ''
                });
                this.registerFarmerForm.markAsUntouched();
                this.registerFarmerForm.markAsPristine();
                this.registeringFarmer = false;
            }
        }
    };
    ProjectinfoComponent.prototype.registerSyncEvent = function () {
        navigator.serviceWorker.register('/service-worker.js', {
            scope: './'
        })
            .then(function (reg) {
            reg.sync.register('oneTimeSync');
        })
            .catch(function (error) {
        });
    };
    ProjectinfoComponent.prototype.declineRegisterFarmer = function () {
        if (this.registerFarmerModal) {
            this.registerFarmerModal.hide();
        }
        this.registerFarmerForm.setValue({
            name: '',
            age: '',
            sex: '',
            area: '',
            telNumber: '',
            photo_path: ''
        });
        this.registerFarmerForm.markAsUntouched();
        this.registerFarmerForm.markAsPristine();
    };
    ProjectinfoComponent.prototype.updateCache = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    ProjectinfoComponent.prototype.handleFileInput = function (file) {
        var fileItem = file.item(0);
        var msg = this.commonService.ValidateFile(fileItem);
        if (msg.length > 0) {
            this.commonService.showToast('warning', 'generic_error', {});
            return;
        }
        this.fileToUpload = fileItem;
        var reader = new FileReader();
        reader.onload = function (event) {
        };
        reader.readAsDataURL(this.fileToUpload);
    };
    // makeRevision() {
    //   this.spinner.show();
    //   this.revisionService.MakeRevision(this.projectId).subscribe(
    //     (result) => {
    //       this.arrSnapshotRevisions.push({ snapshot_id_ref: this.oldSnapshotId, revision: result.data.revision, cr_date: result.data.cr_date });
    //       this.snapshotChanged();
    //       this.spinner.hide();
    //       this.toastrService.success(`${this.revisionTxt} ${result.data.revision} ${this.createSuccessTxt}.`);
    //     },
    //     (error) => { 
    //       this.spinner.hide(); 
    //       this.toastrService.error(error.message); 
    //     }
    //   );
    // }
    ProjectinfoComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    ProjectinfoComponent.prototype.printProjectDatasheet = function () {
        var newSnap = {
            snapshot_name: "None",
            status: "Not Active",
            cr_date: "None"
        };
        var newRevision = {
            revision: "None",
            status: "Not Active",
            cr_date: "None"
        };
        var snapshotCreatedDateString = 'None';
        var revisionCreatedDateString = 'None';
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if (this.snapshotSurveys != undefined && this.snapshotSurveys.length > 0) {
            var x = this.snapshotSurveys[0];
            newSnap.snapshot_name = x.name + ' ' + x.survey_type_sequence;
            newSnap.cr_date = x.cr_date;
            var snapshotCreatedDate = new Date(newSnap.cr_date);
            snapshotCreatedDateString = snapshotCreatedDate.getDate() + "-" + months[snapshotCreatedDate.getMonth()] + "-" + snapshotCreatedDate.getFullYear();
            newSnap.status = x.survey_stage_changelogs[0].change_type;
            if (x.latestRevision != undefined) {
                newRevision.revision = x.survey_type_sequence + "." + x.latestRevision.revision_number;
                newRevision.cr_date = x.latestRevision.cr_date;
                var revisionCreatedDate = new Date(newRevision.cr_date);
                revisionCreatedDateString = revisionCreatedDate.getDate() + "-" + months[revisionCreatedDate.getMonth()] + "-" + revisionCreatedDate.getFullYear();
                newRevision.status = x.survey_stage_changelogs[0].change_type;
            }
        }
        var doc = new jsPDF('p', 'pt');
        var text = "Project Datasheet", xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
        doc.text(text, xOffset, 40);
        doc.autoTable({
            head: [['Project Information']],
            margin: { top: 80 },
            theme: 'grid',
            bodyStyles: { text: { cellWidth: '200' } }
        });
        doc.autoTable({
            html: '#project-information-1',
            theme: 'grid',
            bodyStyles: {
                columnWidth: 200,
                textColor: 20,
            },
        });
        doc.autoTable({
            html: '#project-information-2',
            theme: 'grid',
            bodyStyles: {
                columnWidth: 200,
                textColor: 20,
            },
        });
        doc.autoTable({
            head: [['Snapshot Info']],
            body: [
                ['Active Snapshot : ', newSnap.snapshot_name],
                ['Created :', snapshotCreatedDateString],
                ['Stage:', newSnap.status.charAt(0).toUpperCase() + newSnap.status.slice(1)],
            ],
            margin: { top: 80 },
            theme: 'grid',
            bodyStyles: {
                columnWidth: 200,
                textColor: 20,
            },
        });
        doc.autoTable({
            head: [['Revision Info']],
            body: [
                ['Revision Number : ', newRevision.revision],
                ['Created :', revisionCreatedDateString],
                ['Stage:', newRevision.status.charAt(0).toUpperCase() + newRevision.status.slice(1)],
            ],
            margin: { top: 80 },
            theme: 'grid',
            bodyStyles: {
                columnWidth: 200,
                textColor: 20,
            },
        });
        doc.addPage();
        doc.autoTable({
            head: [['Mapped Services']],
            margin: { top: 80 },
            theme: 'grid',
            bodyStyles: {
                textColor: 20,
            },
        });
        var displayedServices = [];
        this.project.displayedServices.forEach(function (snap) {
            doc.autoTable({
                body: [[snap.name]],
                theme: 'grid',
                bodyStyles: {
                    textColor: 20,
                },
            });
        });
        doc.autoTable({
            head: [['Campaign Information']],
            body: [["Campaign Information Coming Soon"]],
            margin: { top: 80 },
            theme: 'grid',
            bodyStyles: {
                columnWidth: 200,
                textColor: 20,
            },
        });
        var name = "Project Datasheet - " + this.project.name + ".pdf";
        doc.save(name);
    };
    // Get all surveys for the given project & filter them by type
    ProjectinfoComponent.prototype.setSurveys = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([this.surveyService.getAllSurveysForProject(this.projectId).toPromise()])];
                    case 1:
                        requests = _a.sent();
                        this.allSurveys = requests[0].message;
                        this.filterSurveys();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectinfoComponent.prototype.filterSurveys = function () {
        this.campaignSurveys = this.allSurveys.filter(function (item) { return item.survey_type == 'campaign'; });
        this.snapshotSurveys = this.allSurveys.filter(function (item) { return item.survey_type == 'snapshot'; });
    };
    return ProjectinfoComponent;
}());
export { ProjectinfoComponent };
