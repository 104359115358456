<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToDashboards()" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span>{{project?.name}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)"><span i18n="@@projectReporting">Project Reporting</span></li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToDashboards()"><span>Dashboards</span></li>
      <li class="breadcrumb-item active" aria-current="page"><span>Profiling Synopsis</span></li>
    </ol>
  </nav>
</div>

<div class="content-wrap content-wrapper background margin-btm-20">
  <div class="form-design form-box">
    <div class="row">
      <div class="col-md-12 col-lg-9">
        <label class="group-title inline-block mrbtm-two">
          <span>Profiling Synopsis 
            <span *ngIf="predefinedReports[8]?.name == 'Farmer Count'">
              <span *ngIf="predefinedReports[8]?.series[0]?.total_farmers != null">({{predefinedReports[8]?.series[0]?.total_farmers}} <span> Farmers)</span></span>
              <span *ngIf="predefinedReports[8]?.series[0]?.total_farmers == null">(0 Farmers)</span>
            </span>
          </span>
        </label>
      </div>
      <!-- <div class="col-md-12 col-lg-3 form-group fltRgt slidedownsearch">
        <select class="form-control" name="section_name" required>
          <option value="0" class="option" i18n="@@select">Vanilla</option>
          <option value="1">Rose</option>
          <option value="2">Tulip</option>
        </select>
      </div> -->
    </div>
    <div class="form-design form-box form-box-gray" [ngStyle]="{ display: predefinedReports?.length > 0 ? 'inherit' : 'none' }">
      <div class="mt-4">
        <div class="row">
          <div class="col-md-12 col-lg-3" *ngIf="predefinedReports?.length > 0">
            <div class="white-box" *ngIf="predefinedReports[4]?.name == 'Farmer - Gender Distribution'">
              <div class="row height-83 flex-center">
                <div class="col-md-12 col-lg-6 pdleft-two">
                  <div class="male-farmer">
                    <span class="fnt-size-common-number" *ngIf="predefinedReports[4]?.series[0]?.Male != null">{{predefinedReports[4]?.series[0]?.Male | number: '1.0-0' }}</span>
                    <span class="fnt-size-common-number" *ngIf="predefinedReports[4]?.series[0]?.Male == null">0</span>
                    <span class="small">%</span>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6 pdleft-two">
                    <div class="female-farmer">
                      <span class="fnt-size-common-number" *ngIf="predefinedReports[4]?.series[0]?.Female != null">{{predefinedReports[4]?.series[0]?.Female | number: '1.0-0' }}</span>
                      <span class="fnt-size-common-number" *ngIf="predefinedReports[4]?.series[0]?.Female == null">0</span>
                      <span class="small">%</span>
                    </div>
                  </div>
              </div>
              <div class="mt-3 mb-2 align-center font-20 height-48 flex-center">Farming as Profession</div>
            </div>            
            <div class="white-box mt-4" *ngIf="predefinedReports[0]?.name == 'Total Area of Surveyed Crop'">
              <div class="row height-83 flex-center">
                <div class="col-md-12 col-lg-6 align-center">
                  <img src="assets/online_images/area-crop.svg" alt="Area Crop" />
                </div>
                <div class="col-md-12 col-lg-4 font-30 flex-center">
                  <span class="fnt-size-common-number" *ngIf="predefinedReports[0]?.series[0]['Total Area of Surveyed Crop'] != null">{{predefinedReports[0]?.series[0]['Total Area of Surveyed Crop'] | number: '1.1-1'}}</span>
                  <span class="fnt-size-common-number" *ngIf="predefinedReports[0]?.series[0]['Total Area of Surveyed Crop'] == null">0.0</span>
                </div>
                <!-- <div class="col-md-12 col-lg-2 flex-center">                    
                  <span class="down-red">26%</span>
                </div> -->
              </div>
              <div class="mt-3 mb-2 align-center font-20 height-48 flex-center">Total Area of Surveyed Crop (in Hect)</div>
            </div>
            <div class="white-box mt-4" *ngIf="predefinedReports[2]?.name == 'Surveyed Crop as Main Crop'">
              <div class="row height-83 flex-center">
                <div class="col-md-12 col-lg-6 align-center">
                  <img src="assets/online_images/crop.svg" alt="Area Crop" />
                </div>
                <div class="col-md-12 col-lg-4 font-30 flex-center">
                  <span class="fnt-size-common-number" *ngIf="predefinedReports[2]?.series[0]['Surveyed Crop as Main Crop'] != null">{{predefinedReports[2]?.series[0]['Surveyed Crop as Main Crop'] | number: '1.1-1'}}</span>
                  <span class="fnt-size-common-number" *ngIf="predefinedReports[2]?.series[0]['Surveyed Crop as Main Crop'] == null">0.0</span>
                  <span class="small">%</span>
                </div>
                <!-- <div class="col-md-12 col-lg-2 flex-center">                    
                  <span class="down-red">34%</span>
                </div> -->
              </div>
              <div class="mt-3 mb-2 align-center font-20 height-48 flex-center">Surveyed Crop as Main Crop</div>
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="white-box height100">
              <div #charts id="container1" class="border-bottom"></div>
              <div #charts2 id="container2" class="mt-3"></div>
            </div>
          </div>
          <div class="col-md-12 col-lg-3">
            <div class="white-box" *ngIf="predefinedReports[1]?.name == 'Estimated Area in Production'">
              <div class="row height-83 flex-center pdtop-14">
                <div class="col-md-12 col-lg-6 align-center">
                  <img src="assets/online_images/estimated-area.svg" alt="Area Crop" />
                </div>
                <div class="col-md-12 col-lg-4 font-30 flex-center">
                  <span class="fnt-size-common-number" *ngIf="predefinedReports[1]?.series[0]['Estimated Area in Production'] != null">{{predefinedReports[1]?.series[0]['Estimated Area in Production'] | number: '1.1-1'}}</span>
                  <span class="fnt-size-common-number" *ngIf="predefinedReports[1]?.series[0]['Estimated Area in Production'] == null">0.0</span>
                </div>
                <!-- <div class="col-md-12 col-lg-2 flex-center">                    
                  <span class="down-red">22%</span>
                </div> -->
              </div>
              <div class="mt-3 mb-2 align-center font-20 height-48 flex-center">Estimated Area in Production (in Hect)</div>
            </div>
            <div class="white-box mt-4" *ngIf="predefinedReports[3]?.name == 'With Surveyed Crop > 3 years'">
              <div class="row height-83 flex-center">
                <div class="col-md-12 col-lg-6 align-center">
                  <img src="assets/online_images/surveyed-crop.svg" alt="Area Crop" />
                </div>
                <div class="col-md-12 col-lg-4 font-30 flex-center">
                  <span class="fnt-size-common-number pdleft-zero" *ngIf="predefinedReports[3]?.series[0]['With Surveyed Crop for more than 3 years'] != null">{{predefinedReports[3]?.series[0]['With Surveyed Crop for more than 3 years'] | number: '1.1-1'}}</span>
                  <span class="fnt-size-common-number pdleft-zero" *ngIf="predefinedReports[3]?.series[0]['With Surveyed Crop for more than 3 years'] == null">0.0</span>
                  <span class="small">%</span>
                </div>
                <!-- <div class="col-md-12 col-lg-2 flex-center">                    
                  <span class="up-green">41%</span>
                </div> -->
              </div>
              <div class="mt-3 mb-2 align-center font-20 height-48 flex-center">With Surveyed Crop for more than 3 years</div>
            </div>
            <div class="white-box mt-4" *ngIf="predefinedReports[5]?.name == 'Farmers Practising Sustainable Farming'">
              <div class="row height-83 flex-center">
                <div class="col-md-12 col-lg-6 align-center">
                  <img src="assets/online_images/sustainable-farming.svg" alt="Area Crop" />
                </div>
                <div class="col-md-12 col-lg-4 font-30 flex-center">
                  <span class="fnt-size-common-number" *ngIf="predefinedReports[5]?.series[0]['Farmers Practising Sustainable Farming'] != null">{{predefinedReports[5]?.series[0]['Farmers Practising Sustainable Farming'] | number: '1.1-1'}}</span>
                  <span class="fnt-size-common-number" *ngIf="predefinedReports[5]?.series[0]['Farmers Practising Sustainable Farming'] == null">0.0</span>
                  <span class="small">%</span>
                </div>
                <!-- <div class="col-md-12 col-lg-2 flex-center">                    
                  <span class="down-red">37%</span>
                </div> -->
              </div>
              <div class="mt-3 mb-2 align-center font-20 height-48 flex-center">Sustainable farming Practices</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>