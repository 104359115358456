import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CommonService } from '../../../services/common.service';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
var parentScope01;
var KpiIndicatorsComponent = /** @class */ (function () {
    function KpiIndicatorsComponent(route, projectService, toastrService, router, activeRoute, translate, changeDetectionRef, renderer, commonService, hcRService, spinner) {
        this.route = route;
        this.projectService = projectService;
        this.toastrService = toastrService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.translate = translate;
        this.changeDetectionRef = changeDetectionRef;
        this.renderer = renderer;
        this.commonService = commonService;
        this.hcRService = hcRService;
        this.spinner = spinner;
        this.projects = [];
        this.projectId = 0;
        this.dashboardData = [];
        this.reportIDs = [];
        this.predefinedReports = [];
        this.kpisListData = [];
        this.kpisObject = {
            dropDownVal: ''
        };
        this.kpisReportsList = [];
        this.selectedKpiReportsIDs = [];
        this.selectedKPIsMultiSelect = [];
        this.dropdownSettings = {};
        this.sectionDropDown = [];
        this.subSectionDropdown = [];
        this.listsSectionsSubSection = [];
        this.dashboardAddObject = {
            "name": '',
            "description": '',
            "section": 0,
            "subsection": '',
            "reportNames": ''
        };
        this.subsectionFlag = true;
        this.existingSelectedKPIs = [];
        this.listsKPISection = [];
        this.kpiData = [];
        this.kpiReports = [];
        this.KPIAddObject = {
            "name": '',
            "description": '',
            "section": 0,
            "reportNames": ''
        };
    }
    KpiIndicatorsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.projectId = this.route.snapshot.params['id'];
        this.hcRService.allCharts = [];
        this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
            _this.project = response.data;
            var projectObject = _this.project.filter(function (item) { return item.id == _this.projectId; })[0];
            _this.project = projectObject;
        });
        var requestObject = {
            projectId: this.projectId,
            type: "KPI"
        };
        this.setTranslatedLabelObjects();
        // this.hcRService.getKPIsReports(requestObject).subscribe(response =>{
        this.hcRService.getSectionSubsectionReportsSelectionListAPI(requestObject).subscribe(function (response) {
            var _a;
            if (response.msg == 'success') {
                var listKPI = response.data;
                for (var item in listKPI.kpiSections) {
                    var newObject = {
                        "section": '',
                        "reports": []
                    };
                    _this.listsKPISection.push(item);
                    console.log("listsKPISection", _this.listsKPISection);
                    newObject.section = item;
                    var reports = listKPI.kpiSections[item];
                    for (var x in reports) {
                        newObject.reports.push(reports[x]);
                    }
                    _this.kpiData.push(newObject);
                }
                if (listKPI.custom_reports && listKPI.custom_reports.length > 0) {
                    var customReports = listKPI.custom_reports;
                    (_a = _this.kpiReports).push.apply(_a, customReports);
                }
            }
        });
        //multi-select drop down settings
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: this.selectAllText,
            unSelectAllText: this.unSelectAllText,
            searchPlaceholderText: this.searchText,
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
        if (this.projectId > 0) {
            var requestObject_1 = {
                "reportID": [50],
                "projectId": this.projectId
            };
            this.hcRService.getReports(requestObject_1).subscribe(function (response) {
                if (response.msg == 'success') {
                    _this.countOfFarmers = response.data[0].series[0];
                }
            });
        }
    };
    //added by Kiran for translation
    KpiIndicatorsComponent.prototype.setTranslatedLabelObjects = function () {
        var _this = this;
        this.translate.get('no_new_reports_selected').subscribe(function (result) {
            _this.noNewReportsSelected = result;
        });
        this.translate.get('selectPlaceholder').subscribe(function (result) {
            _this.selectPlaceholder = result;
        });
        this.translate.get('selectAllText').subscribe(function (result) {
            _this.selectAllText = result;
        });
        this.translate.get('unSelectAllText').subscribe(function (result) {
            _this.unSelectAllText = result;
        });
        this.translate.get('searchText').subscribe(function (result) {
            _this.searchText = result;
        });
    };
    KpiIndicatorsComponent.prototype.trackBySectionName = function (index, dataItem) {
        return dataItem.section;
    };
    KpiIndicatorsComponent.prototype.selectKPIs = function (event, currentVal) {
        //console.log("event ", event);
        //console.log("currentVal ",currentVal);
        // let newItem = {
        //   "kpiname":"Farmers count",
        //   "imageurl":"",
        //   "value":59
        // };
        // this.kpisReportsList.push(newItem);
        this.selectedKpiReportsIDs.push(currentVal.value);
        //console.log(" this.kpiReportsIDs ", this.selectedKpiReportsIDs);
    };
    KpiIndicatorsComponent.prototype.selectKPISection = function (ev, selectedValue) {
        var _this = this;
        this.kpisListData = [];
        this.selectedKPIsMultiSelect = [];
        var section = this.kpiData.filter(function (item) { return item.section == _this.KPIAddObject.section; })[0];
        if (section != undefined || section != null) {
            this.kpisListData = section.reports;
            if (this.kpisListData.length == 0) {
                this.subsectionFlag = true;
            }
            else {
                this.subsectionFlag = false;
            }
        }
    };
    KpiIndicatorsComponent.prototype.selectSection = function (ev, selectedValue) {
        this.subsectionFlag = false;
    };
    KpiIndicatorsComponent.prototype.trackByName = function (index, dataItem) {
        return dataItem.section;
    };
    KpiIndicatorsComponent.prototype.genarateKPIs = function () {
        var _this = this;
        var selectedKPIIds;
        var selectedKpis = this.selectedKPIsMultiSelect.map(function (item) { return item.id; });
        if (this.existingSelectedKPIs.length > 0) {
            var result = selectedKpis.filter(function (d) { return _this.existingSelectedKPIs.indexOf(d) < 0; });
            selectedKPIIds = result;
            this.existingSelectedKPIs = this.existingSelectedKPIs.concat(result);
        }
        else {
            this.existingSelectedKPIs = this.existingSelectedKPIs.concat(selectedKpis);
            selectedKPIIds = selectedKpis;
        }
        var requestObject = {
            "reportID": selectedKPIIds,
            "projectId": this.projectId
        };
        if (selectedKPIIds.length > 0) {
            this.spinner.show();
            this.hcRService.getReports(requestObject).subscribe(function (response) {
                if (response.msg == 'success') {
                    var reportsData = response.data;
                    for (var i = 0; i < reportsData.length; i++) {
                        if (reportsData[i].is_multi_kpi) {
                            _this.multiKPI(reportsData[i]);
                        }
                        else if (reportsData[i].series.length > 0) {
                            for (var j = 0; j < reportsData[i].series.length; j++) {
                                for (var prop in reportsData[i].series[j]) {
                                    if (reportsData[i].series[j].hasOwnProperty(prop)) {
                                        var innerObj = {
                                            name: '',
                                            value: Number(''),
                                            kpi_image: '',
                                            cols: reportsData[i].dim_col
                                        };
                                        innerObj.name = reportsData[i].name;
                                        var num = Number(reportsData[i].series[j][prop]).toFixed();
                                        if (isNaN(parseInt(num))) {
                                            innerObj.value = reportsData[i].series[0][prop];
                                        }
                                        else {
                                            innerObj.value = parseInt(num);
                                        }
                                        innerObj.kpi_image = reportsData[i].kpi_image;
                                        _this.kpisReportsList.push(innerObj);
                                    }
                                }
                            }
                        }
                        else {
                            var innerObj = {
                                name: '',
                                value: 'N/D',
                                kpi_image: '',
                                cols: reportsData[i].dim_col
                            };
                            innerObj.name = reportsData[i].name;
                            innerObj.kpi_image = reportsData[i].kpi_image;
                            _this.kpisReportsList.push(innerObj);
                        }
                    }
                    _this.spinner.hide();
                }
            });
        }
        else {
            this.commonService.showToast('info', 'no_new_kpi_selected', {});
            //this.toastrService.info(this.noNewReportsSelected);
        }
    };
    KpiIndicatorsComponent.prototype.multiKPI = function (obj) {
        var outerObj = {
            name: obj.name,
            is_multi: true,
            series: [],
            cols: obj.dim_col
        };
        if (obj.drilldown.series.length > 0) {
            for (var j = 0; j < obj.drilldown.series.length; j++) {
                var innerObj = {
                    name: '',
                    value: '',
                    kpi_image: ''
                };
                innerObj.name = obj.drilldown.series[j].kpi_key;
                if (obj.drilldown.series[j].data[0] != null) {
                    innerObj.value = obj.drilldown.series[j].data[0];
                }
                else {
                    innerObj.value = 'N/D';
                }
                innerObj.kpi_image = obj.drilldown.series[j].kpi_image;
                outerObj.series.push(innerObj);
            }
        }
        this.kpisReportsList.push(outerObj);
    };
    KpiIndicatorsComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    KpiIndicatorsComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    KpiIndicatorsComponent.prototype.goToDashboards = function () {
        var url = "charts/dashboards/" + this.projectId;
        this.router.navigate([url]);
    };
    KpiIndicatorsComponent.prototype.cancel = function () {
        var url = "charts/" + this.projectId;
        this.router.navigate([url]);
    };
    KpiIndicatorsComponent.prototype.printCharts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mywindow;
            return tslib_1.__generator(this, function (_a) {
                mywindow = window.open('', 'PRINT', 'height=600,width=900');
                mywindow.document.write('<html><head><title>' + document.title + '</title>');
                mywindow.document.write('</head><body >');
                mywindow.document.write(document.getElementById('KPIbox').innerHTML);
                mywindow.document.write('</body></html>');
                mywindow.document.close(); // necessary for IE >= 10
                mywindow.focus(); // necessary for IE >= 10*/
                mywindow.print();
                mywindow.close();
                return [2 /*return*/, true];
            });
        });
    };
    KpiIndicatorsComponent.prototype.clearAllCharts = function () {
        //console.log("this.hcRService.allCharts ",this.hcRService.allCharts);
        this.kpisReportsList = [];
        this.selectedKPIsMultiSelect = [];
        this.existingSelectedKPIs = [];
    };
    KpiIndicatorsComponent.prototype.onSelectAllChart = function (ele) {
        document.getElementById('KPI').click();
    };
    return KpiIndicatorsComponent;
}());
export { KpiIndicatorsComponent };
