<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToDashboards()" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)"><span i18n-title="@@projectReporting" title="Project Reporting">...</span></li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToDashboards()"><span title="Dashboards">...</span></li>
      <li class="breadcrumb-item active" aria-current="page"><span>good farming and curing practices</span></li>
    </ol>
  </nav>
</div>

<div class="content-wrap content-wrapper background margin-btm-20">
  <div class="form-design form-box">
    <div class="row">
      <div class="col-md-12 col-lg-9">
        <label class="group-title inline-block mrbtm-two">
          <span>Good Farming and Curing Practices</span>
        </label>
      </div>
    </div>
    <div class="form-design form-box form-box-gray" >
      <div class="border-rb">
        <div class="row mt-2 no-gutters">
          <div class="col-md-6 col-lg-3 pos-r">
            <img src="assets/online_images/children-interest.webp" alt="Vanilla Children Interest" />
            <!-- <div class="white-border-box"></div> -->
          </div>
          <div class="col-md-6 col-lg-3 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">Children Interest in Agriculture</div>
            <div class="gf-kpi-box-1">
              <ng-container  *ngFor="let report of predefinedReports" >
                <div class="row height100 flex-center no-gutters" *ngIf="report.reportId == 133">
                  <div class="col-md-12 col-lg-12 text-center">
                    <div class="">
                      <span class="blue-big">{{report?.series[0]?.children_motivated_farming != null ? report?.series[0]?.children_motivated_farming : '0'}}</span>
                      <span class="font-20"> ({{report?.series[0]?.perc}}%)</span>
                    </div>
                    <div>
                      <span class="gray font-20">Out of {{report?.series[0]?.total_children != null ? report?.series[0]?.total_children : '0'}}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/mahadera.webp" alt="Vanilla Training in Villages" />
          </div> -->
          <div class="col-md-12 col-lg-6 padding10 pos-r white-bg border-lt">
            <ng-container *ngFor="let report of predefinedReports" >
              <div class="font-20 blue-border-5" *ngIf="report.reportId == 134">
                <span class="blue-big">{{report?.series[0]?.receive_training != null ? report?.series[0]?.receive_training : '0'}} </span>
                <span>farmers out of {{report?.series[0]?.total_farmer != null ? report?.series[0]?.total_farmer : '0'}} attended training in villages</span> 
              </div>
            </ng-container>
            <div class="gf-kpi-box-1">
                <div #charts2 class="highcharts-no-border height100"></div>
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-md-6 col-lg-6 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">Issues Faced for Vanilla Cultivation</div>
            <div class="gf-kpi-box-1">
              <div #charts3 class="highcharts-no-border height100"></div>
            </div>
          </div>
          <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/vanilla-years.webp" alt="Vanilla Years" />
          </div>
          <div class="col-md-12 col-lg-3 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">
              <span>Average Years of Cultivating Vanilla : </span>
              <ng-container  *ngFor="let report of predefinedReports" >
                  <span class="blue-big" *ngIf="report.reportId == 138">{{report.series[0].avg_cultivating != null ? report.series[0].avg_cultivating : '0'}}</span>
                </ng-container>
            </div>
            <div class="gf-kpi-box">
              <ng-container  *ngFor="let report of predefinedReports" >
                <div class="row height100 flex-center no-gutters" *ngIf="report.reportId == 119">
                  <div class="col-md-12 col-lg-12">
                    <div class="bullet-dots" *ngFor="let item of report?.series[0] | keyvalue">{{item.key}} - <span class="blue">{{item.value}}</span></div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/vanilla-issues.webp" alt="Vanilla Issues" />
          </div> -->
        </div>
        <div class="row no-gutters">
          <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/vanilla-practices.webp" alt="Vanilla Practices" />
          </div>
          <div class="col-md-12 col-lg-9 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">Good Vanilla Cultivation Practices</div>
            <div class="gf-kpi-box-1">
              <div #charts4 class="highcharts-no-border height100"></div>
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-md-12 col-lg-3 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">Buffer Areas</div>
            <ng-container *ngFor="let report of predefinedReports" >
              <div class="gf-kpi-box-2 flex-center" *ngIf="report.reportId == 121">
                <div class="progress-pie-chart" [attr.data-percent]="report?.series[0]?.buffer_area != null ? report?.series[0]?.buffer_area : '0'">
                  <div class="ppc-progress">
                    <div class="ppc-progress-fill"></div>
                  </div>
                  <div class="ppc-percents">
                    <div class="pcc-percents-wrapper">
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- <div #charts class="highcharts-no-border height100"></div> -->
            <!-- <div class="pl-2">
                <img src="assets/online_images/buffer-area.webp" alt="buffer area" title="buffer area" style="width:20px" />
                <span class="ml-2">Buffer Areas - </span>
                <span class="blue">24%</span>
            </div>-->
          </div>
          <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/buffer_area.webp" alt="buffer area" />
          </div>
          <div class="col-md-12 col-lg-3 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">Glyceridia Tutors</div>
            <ng-container  *ngFor="let report of predefinedReports" >
              <div class="gf-kpi-box-2 flex-center" *ngIf="report.reportId == 122">
                <div class="progress-pie-chart" [attr.data-percent]="report?.series[0]?.Glyciridia != null ? report?.series[0]?.Glyciridia : '0'">
                  <div class="ppc-progress">
                    <div class="ppc-progress-fill"></div>
                  </div>
                  <div class="ppc-percents">
                    <div class="pcc-percents-wrapper">
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/glyceridia.webp" alt="glyceridia" />
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/manual_weeding.webp" alt="manual weeding" />
          </div>
          <div class="col-md-12 col-lg-3 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">Manual Weeding</div>
            <ng-container  *ngFor="let report of predefinedReports" >
              <div class="gf-kpi-box-2 flex-center" *ngIf="report.reportId == 123">
                <div class="progress-pie-chart" [attr.data-percent]="report?.series[0]?.Manual_weeding != null ? report?.series[0]?.Manual_weeding : '0'">
                  <div class="ppc-progress">
                    <div class="ppc-progress-fill"></div>
                  </div>
                  <div class="ppc-percents">
                    <div class="pcc-percents-wrapper">
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/looping.webp" alt="looping" />
          </div>
          <div class="col-md-12 col-lg-3 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">Looping</div>
            <ng-container  *ngFor="let report of predefinedReports" >
              <div class="gf-kpi-box-2 flex-center" *ngIf="report.reportId == 124">
                <div class="progress-pie-chart" [attr.data-percent]="report?.series[0]?.Looping != null ? report?.series[0]?.Looping : '0'">
                  <div class="ppc-progress">
                    <div class="ppc-progress-fill"></div>
                  </div>
                  <div class="ppc-percents">
                    <div class="pcc-percents-wrapper">
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-md-12 col-lg-3 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">Organic Crop Protection</div>
            <ng-container  *ngFor="let report of predefinedReports" >
              <div class="gf-kpi-box-2 flex-center" *ngIf="report.reportId == 125">
                <div class="progress-pie-chart" [attr.data-percent]="report?.series[0]?.Organic_crop_protection != null ? report?.series[0]?.Organic_crop_protection : '0'">
                  <div class="ppc-progress">
                    <div class="ppc-progress-fill"></div>
                  </div>
                  <div class="ppc-percents">
                    <div class="pcc-percents-wrapper">
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/vanilla-practices-2.webp" alt="Vanilla Practices" />
          </div>
          <div class="col-md-12 col-lg-3 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">Organic Fertilizers</div>
            <ng-container  *ngFor="let report of predefinedReports" >
              <div class="gf-kpi-box-2 flex-center" *ngIf="report.reportId == 126">
                <div class="progress-pie-chart" [attr.data-percent]="report?.series[0]?.Organic_fertilizer != null ? report?.series[0]?.Organic_fertilizer : '0'">
                  <div class="ppc-progress">
                    <div class="ppc-progress-fill"></div>
                  </div>
                  <div class="ppc-percents">
                    <div class="pcc-percents-wrapper">
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/organic_fertilizers.webp" alt="organic fertilizers" />
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/shading_tree.webp" alt="shading tree" />
          </div>
          <div class="col-md-12 col-lg-3 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">Shading Trees</div>
            <ng-container  *ngFor="let report of predefinedReports" >
              <div class="gf-kpi-box-2 flex-center" *ngIf="report.reportId == 127">
                <div class="progress-pie-chart" [attr.data-percent]="report?.series[0]?.Shaded_trees_over_vanilla != null ? report?.series[0]?.Shaded_trees_over_vanilla : '0'">
                  <div class="ppc-progress">
                    <div class="ppc-progress-fill"></div>
                  </div>
                  <div class="ppc-percents">
                    <div class="pcc-percents-wrapper">
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-md-12 col-lg-3 pos-r">
            <img src="assets/online_images/mahadera.webp" alt="Post Harvest Practices" />
          </div>
          <div class="col-md-12 col-lg-3 padding10 pos-r white-bg border-lt">
            <div class="font-20 blue-border-5">Post Harvest Practices</div>
            <div class="gf-kpi-box-1">
              <div class="row height100 flex-center no-gutters">
                <div class="col-md-12 col-lg-12" >
                  <ng-container *ngFor="let report of predefinedReports" >
                    <div *ngIf="report.reportId == 129 || report.reportId == 130 || report.reportId == 131 || report.reportId == 132" >
                      <div *ngIf="report.reportId != 131">
                        <ng-container *ngFor="let item of report?.series[0] | keyvalue">
                          <div class="bullet-dots" *ngIf="item.key == 'Yes'">{{report?.name | titlecase}} - <span class="blue">{{item.value != null ? item.value : '0'}}</span></div>
                        </ng-container>
                      </div>
                      <div *ngIf="report.reportId == 131">
                          <div class="bullet-dots">
                            <div class="font-1">{{report?.name | titlecase}}</div>
                            <ng-container *ngFor="let item of report?.series[0] | keyvalue">
                              <div class="font-1 mt-2 ml-3" *ngIf="item.key != 'No Response'">{{item.key | titlecase}} - <span class="blue">{{item.value != null ? item.value : '0'}}</span></div>
                            </ng-container>
                          </div>
                      </div>
                    </div>
                  </ng-container>
                </div>              
              </div>
                  <!-- <div class="bullet-dots">Vaccum Packing - <span class="blue">83%</span></div>
                  <div class="bullet-dots">Permetrine Avoindance - <span class="blue">42%</span></div>
                  <div class="bullet-dots">Drying Practice - <span class="blue">79%</span></div>
                  <div class="bullet-dots">Washing Hands - <span class="blue">89%</span></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
