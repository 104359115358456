import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
var SurveyService = /** @class */ (function () {
    function SurveyService(http, appConfigService) {
        this.http = http;
        this.appConfigService = appConfigService;
        this.surveyURL = this.appConfigService.getUrl(this.appConfigService.SURVEY);
        this.SURVEY_NQ = this.appConfigService.getUrl(this.appConfigService.SURVEY_NQ);
    }
    SurveyService.prototype.getAllSurveysForProject = function (projectId) {
        return this.http.get(this.surveyURL + "/getAllSurveysForProject/" + projectId);
    };
    SurveyService.prototype.createSurvey = function (projectId, type) {
        return this.http.get(this.surveyURL + "/createSurvey/" + projectId + "/" + type);
    };
    SurveyService.prototype.checkSurveyQuestionnaireExists = function (surveyId) {
        return this.http.get(this.surveyURL + "/checkSurveyQuestionnaireExists/" + surveyId);
    };
    SurveyService.prototype.createQuestionnaireForSurvey = function (surveyId, templateId, body) {
        return this.http.post(this.surveyURL + "/createQuestionnaireForSurvey/" + surveyId + "/" + templateId, body);
    };
    SurveyService.prototype.getSurveyQuestionnaire = function (surveyId) {
        return this.http.get(this.surveyURL + "/getSurveyQuestionnaire/" + surveyId);
    };
    SurveyService.prototype.getSurveyQuestionnaireForProject = function (projectId) {
        return this.http.get(this.surveyURL + "/getSurveyQuestionnaireForProject/" + projectId);
    };
    SurveyService.prototype.activateSurvey = function (surveyId) {
        return this.http.get(this.surveyURL + "/activateSurvey/" + surveyId);
    };
    SurveyService.prototype.getSurvey = function (surveyId) {
        return this.http.get(this.surveyURL + "/getSurvey/" + surveyId);
    };
    SurveyService.prototype.saveAsTemplate = function (surveyId, templateDetails) {
        return this.http.post(this.surveyURL + "/saveAsTemplate", { surveyId: surveyId, templateDetails: templateDetails });
    };
    SurveyService.prototype.getTemplateQuestionnaire = function (templateId) {
        return this.http.get(this.surveyURL + "/getTemplateQuestionnaire/" + templateId);
    };
    SurveyService.prototype.getAssociatedTemplates = function (surveyId) {
        return this.http.get(this.surveyURL + "/getAssociatedTemplates/" + surveyId);
    };
    SurveyService.prototype.saveQuestionnaireChanges = function (surveyId, changedAssets) {
        return this.http.post(this.surveyURL + "/saveQuestionnaireChanges/" + surveyId, changedAssets);
    };
    SurveyService.prototype.closeSurvey = function (surveyId) {
        return this.http.get(this.surveyURL + "/closeSurvey/" + surveyId);
    };
    SurveyService.prototype.getEcosystemTemplates = function () {
        return this.http.get(this.surveyURL + "/getEcosystemTemplates");
    };
    SurveyService.prototype.retrofitAnswerData = function () {
        return this.http.get(this.surveyURL + "/retrofitAnswerData/25");
    };
    SurveyService.prototype.savePercentageCompleted = function (farmerId, surveyId, denominator, numerator) {
        return this.http.post(this.surveyURL + "/savePercentageCompleted", { farmerId: farmerId, surveyId: surveyId, total_questions: denominator, answered_questions: numerator });
    };
    SurveyService.prototype.getRevisionsForProject = function (projectId) {
        return this.http.get(this.surveyURL + "/getRevisionsForProject/" + projectId);
    };
    SurveyService.prototype.getTemplateMetadata = function (templateId) {
        return this.http.get(this.surveyURL + "/getTemplateMetadata/" + templateId);
    };
    SurveyService.prototype.getSurveyLanguages = function () {
        return this.http.get(this.surveyURL + "/getSurveyLanguages");
    };
    SurveyService.prototype.checkLanguageOKForRelease = function (surveyId) {
        return this.http.get(this.surveyURL + "/checkLanguageOKForRelease/" + surveyId);
    };
    SurveyService.prototype.getP2FTemplate = function () {
        return this.http.get(this.surveyURL + "/getP2FTemplate");
    };
    SurveyService.prototype.saveP2FTemplateChanges = function (changedAssets) {
        return this.http.post(this.surveyURL + "/saveP2FTemplateChanges", changedAssets);
    };
    SurveyService.prototype.getQAnswersForFarmerByRevision = function (farmerId, revisionId) {
        return this.http.get(this.surveyURL + "/getQAnswersForFarmerByRevision/" + farmerId + "/" + revisionId);
    };
    SurveyService.prototype.getSurveyQForProfilingService = function (surveyId) {
        return this.http.get(this.surveyURL + "/getSurveyQForProfilingService/" + surveyId);
    };
    SurveyService.prototype.getAllSurveysForProjectProfilingService = function (projectId) {
        return this.http.get(this.surveyURL + "/getAllSurveysForProjectProfilingService/" + projectId);
    };
    SurveyService.prototype.changeQuestionnaireForSurvey = function (surveyId, templateId) {
        return this.http.get(this.surveyURL + "/changeQuestionnaireForSurvey/" + surveyId + "/" + templateId);
    };
    SurveyService.prototype.getProfilingTabKeys = function (projectId, request) {
        if (request === void 0) { request = {}; }
        return this.http.post(this.SURVEY_NQ + "/getProfilingTabKeys/" + projectId, request);
    };
    SurveyService.prototype.exportFarmerProfilingData = function (projectId, request) {
        return this.http.post(this.SURVEY_NQ + "/exportFarmerProfilingData/" + projectId, request, { responseType: 'blob' });
    };
    SurveyService.prototype.createCampaignSurvey = function (campaignId) {
        return this.http.get(this.surveyURL + "/createCampaignSurvey/" + campaignId);
    };
    SurveyService.prototype.getSurveyQuestionnaireForCampaign = function (campaignId) {
        return this.http.get(this.surveyURL + "/getSurveyQuestionnaireForCampaign/" + campaignId);
    };
    SurveyService.prototype.saveGrandTotalQuestion = function (surveyId, changedAssets) {
        return this.http.post(this.surveyURL + "/createGrandTotalQn/" + surveyId, changedAssets);
    };
    SurveyService.prototype.updateSurveyNameBySurveyId = function (surveyId, body) {
        return this.http.post(this.surveyURL + "/updateSurveyNameBySurveyId/" + surveyId, body);
    };
    SurveyService.prototype.saveSurveyManualDoc = function (surveyId, file) {
        return this.http.post(this.surveyURL + "/uploadSurveyManualDoc/" + surveyId, file);
    };
    SurveyService.prototype.updateSurveyManualRefWithSection = function (surveyId, payload) {
        return this.http.post(this.surveyURL + "/updateSurveyManualRefWithSection/" + surveyId, payload);
    };
    SurveyService.ngInjectableDef = i0.defineInjectable({ factory: function SurveyService_Factory() { return new SurveyService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: SurveyService, providedIn: "root" });
    return SurveyService;
}());
export { SurveyService };
