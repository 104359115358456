import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-config.service";
var ConsReportService = /** @class */ (function () {
    function ConsReportService(http, appConfigService) {
        this.http = http;
        this.appConfigService = appConfigService;
        this.CONSREPORT = this.appConfigService.getUrl(this.appConfigService.CONSREPORT);
    }
    ConsReportService.prototype.exportAllSurveyData = function (projectId, request) {
        return this.http.post(this.CONSREPORT + "/exportAllSurveyData/" + projectId, request);
    };
    ConsReportService.prototype.getConsolidationTypeData = function (projectId, request) {
        return this.http.post(this.CONSREPORT + "/getConsolidationTypeData/" + projectId, request);
    };
    ConsReportService.prototype.getProjectConsolidatedReport = function (projectId, request) {
        return this.http.post(this.CONSREPORT + "/getProjectConsolidatedReport/" + projectId, request);
    };
    ConsReportService.prototype.saveConsolidationTypeData = function (projectId, request) {
        return this.http.post(this.CONSREPORT + "/saveConsolidationTypeData/" + projectId, request);
    };
    ConsReportService.prototype.resetDefaultConsolidationType = function (projectId, SurveyId) {
        return this.http.get(this.CONSREPORT + "/resetDefaultConsolidationType/" + projectId + "/" + SurveyId);
    };
    ConsReportService.prototype.getAllConsolidationTypes = function (projectId, request) {
        return this.http.post(this.CONSREPORT + "/getAllConsolidationTypes/" + projectId, request);
    };
    ConsReportService.prototype.getEcosystemConsolidationTypeData = function (ecosystemId) {
        return this.http.get(this.CONSREPORT + "/getEcosystemConsolidationTypeData/" + ecosystemId);
    };
    ConsReportService.prototype.getProjectQAsubtype = function (ecosystemId) {
        return this.http.get(this.CONSREPORT + "/getProjectQAsubtype/" + ecosystemId);
    };
    ConsReportService.prototype.getCampaignReport = function (projectId, campaignId, request) {
        return this.http.post(this.CONSREPORT + "/getCampaignReport/" + projectId + "/" + campaignId, request);
    };
    ConsReportService.ngInjectableDef = i0.defineInjectable({ factory: function ConsReportService_Factory() { return new ConsReportService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: ConsReportService, providedIn: "root" });
    return ConsReportService;
}());
export { ConsReportService };
