import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, EventEmitter } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataConsentService } from '../../services/data-consent.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
var SignatureComponent = /** @class */ (function () {
    function SignatureComponent(modalService, dataConsentService, sanitizer, route) {
        var _this = this;
        this.modalService = modalService;
        this.dataConsentService = dataConsentService;
        this.sanitizer = sanitizer;
        this.route = route;
        this.width = 400;
        this.height = 160; // 400
        this.newItemEvent = new EventEmitter();
        this.clickX = [];
        this.clickY = [];
        this.clickDrag = [];
        this.agree = false;
        this.signature = {
            'agree': false,
            'value': ''
        };
        this.clearEventHandler = function () {
            _this.clearCanvas();
        };
        this.releaseEventHandler = function () {
            _this.paint = false;
            _this.redraw();
            _this.agree = true;
            _this.getSignValues();
        };
        this.cancelEventHandler = function () {
            _this.paint = false;
            _this.getSignValues();
        };
        this.pressEventHandler = function (e) {
            var r = _this.canvasEl.getBoundingClientRect();
            var mouseX;
            if (e.changedTouches) {
                mouseX = e.changedTouches[0].pageX - r.left;
            }
            else {
                mouseX = e.offsetX;
            }
            var mouseY;
            if (e.changedTouches) {
                mouseY = e.changedTouches[0].pageY - r.top;
            }
            else {
                mouseY = e.offsetY;
            }
            _this.paint = true;
            _this.addClick(mouseX, mouseY, false);
            _this.redraw();
        };
        this.dragEventHandler = function (e) {
            var r = _this.canvasEl.getBoundingClientRect();
            var mouseX;
            if (e.changedTouches) {
                mouseX = e.changedTouches[0].pageX - r.left;
            }
            else {
                mouseX = e.offsetX;
            }
            var mouseY;
            if (e.changedTouches) {
                mouseY = e.changedTouches[0].pageY - r.top;
            }
            else {
                mouseY = e.offsetY;
            }
            if (_this.paint) {
                _this.addClick(mouseX, mouseY, true);
                _this.redraw();
            }
            e.preventDefault();
        };
    }
    SignatureComponent.prototype.ngAfterViewInit = function () {
        this.canvasEl = document.getElementById('canvas');
        this.canvasEl.width = 400;
        this.context = this.canvasEl.getContext('2d');
        this.context.lineCap = 'round';
        this.context.lineJoin = 'round';
        this.context.strokeStyle = 'black';
        this.context.lineWidth = 1;
        if (!this.consentTemplate) {
            this.getFarmerConsentTemplate();
        }
        this.redraw();
        this.createUserEvents();
    };
    SignatureComponent.prototype.ngOnChanges = function () {
        /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'changeEvent'**************/
        this.agree = false;
        this.clearCanvas();
    };
    SignatureComponent.prototype.createUserEvents = function () {
        var canvas = this.canvasEl;
        canvas.addEventListener("mousedown", this.pressEventHandler);
        canvas.addEventListener("mousemove", this.dragEventHandler);
        canvas.addEventListener("mouseup", this.releaseEventHandler);
        canvas.addEventListener("mouseout", this.cancelEventHandler);
        canvas.addEventListener("touchstart", this.pressEventHandler);
        canvas.addEventListener("touchmove", this.dragEventHandler);
        canvas.addEventListener("touchend", this.releaseEventHandler);
        canvas.addEventListener("touchcancel", this.cancelEventHandler);
    };
    SignatureComponent.prototype.clearCanvas = function () {
        if (this.context) {
            this.context
                .clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
        }
        this.clickX = [];
        this.clickY = [];
        this.clickDrag = [];
        if (this.context) {
            this.getSignValues();
        }
    };
    SignatureComponent.prototype.redraw = function () {
        var clickX = this.clickX;
        var context = this.context;
        var clickDrag = this.clickDrag;
        var clickY = this.clickY;
        for (var i = 0; i < clickX.length; ++i) {
            context.beginPath();
            if (clickDrag[i] && i) {
                context.moveTo(clickX[i - 1], clickY[i - 1]);
            }
            else {
                context.moveTo(clickX[i] - 1, clickY[i]);
            }
            context.lineTo(clickX[i], clickY[i]);
            context.stroke();
        }
        context.closePath();
    };
    SignatureComponent.prototype.addClick = function (x, y, dragging) {
        this.clickX.push(x);
        this.clickY.push(y);
        this.clickDrag.push(dragging);
    };
    SignatureComponent.prototype.getSignValues = function () {
        this.signature.agree = this.agree;
        var blank = this.isCanvasBlank(this.canvasEl);
        if (!blank) {
            this.signature.value = this.canvasEl.toDataURL('image/webp', 0.5);
        }
        else {
            this.signature.value = '';
        }
        this.newItemEvent.emit(this.signature);
    };
    SignatureComponent.prototype.isCanvasBlank = function (canvas) {
        var context = canvas.getContext('2d');
        var pixelBuffer = new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);
        return !pixelBuffer.some(function (color) { return color !== 0; });
    };
    SignatureComponent.prototype.viewPrivacyPolicy = function (template) {
        this.privacyPolicyModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
    };
    SignatureComponent.prototype.closePrivacyPolicy = function () {
        if (this.privacyPolicyModal) {
            this.privacyPolicyModal.hide();
        }
    };
    SignatureComponent.prototype.getFarmerConsentTemplate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.dataConsentService.getConsentTemplate(this.projectId).toPromise()
                        ])];
                    case 1:
                        requests = _a.sent();
                        if (requests[0].msg == 'success' && requests[0].data) {
                            this.consentTemplate = this.sanitizer.bypassSecurityTrustHtml(requests[0].data.template_content);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return SignatureComponent;
}());
export { SignatureComponent };
