<app-header></app-header>
<div class="project-header clearfix">

  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb" *ngIf="isCompare">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="getBackCompareData()" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)">
        <span i18n-title="@@projectReporting" title="Project Reporting">...</span>
      </li>  
      <li class="breadcrumb-item cursorPointer" aria-current="page"  (click)="getBackCompareData()">
        <span title="Compare Data">...</span>
      </li>  
      <li class="breadcrumb-item active" aria-current="page" i18n="@@viewQuestionnaire">View Questionnaire</li>
    </ol>
    <ol class="breadcrumb" *ngIf="!isCompare">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToSurveyManagement(project?.id)" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer">
        <a (click)="getBackProjectFilter(project?.name, project?.id)" [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</a>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToSurveyManagement(project?.id)">
        <span i18n-title="@@surveyManagement" title="Survey Management">...</span>
      </li>  
      <li class="breadcrumb-item active" aria-current="page" i18n="@@viewQuestionnaire">View Questionnaire</li>
    </ol>
  </nav>
  <ol class="breadcrumb posTopLeft" style="float:right; margin-top: 4px; margin-right:10px;">
    <li class="breadcrumb-item active cursorPointer" aria-current="page">
      <select class="small-button float-left" style="padding:3px" [(ngModel)]="selectedSnapshot" (change)="changeSnapshot()" name="target_snapshot"  #target_snapshot="ngModel">
        
        <option *ngFor="let list of snapshotData; trackBy: trackBySourceSnapshot" [value]="list.id">
          <span *ngIf="!list.flex2">{{list.name}} {{list.survey_type_sequence}}</span>
          <span *ngIf="list.flex2">{{list.flex2}}</span>
        </option>
      </select>
      <a href="javascript:void(0)" title="Reload" id="currentState" (click)="changeToFirstSnapshot()" class="small-button ml-3" style="padding: 3px 10px 0px; line-height: 21px;"><i class="utilicon utilicon-redo reload-icon"></i></a>
      <!-- <a href="javascript:void(0)"  title="Download as PDF" i18n-title="@@downloadAsPDF" (click)="downloadPDF()" class="small-button ml-3" style="color: #d54b39; font-size: 15px;"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a> -->
      <button printSectionId="forPrint" ngxPrint style="display: inline-block; border-radius: 4px; margin-left:15px; background-color: #fff; color: #3a6ea0; padding: 4px 10px 0px;border: none;line-height: 21px;" title="Print" i18n-title="@@print"><i class="utilicon utilicon-print" aria-hidden="true"></i></button>
    </li>
  </ol>
</div>
<div class="page-wrapper">
  <div class="content-wrap no-search content-wrapper background">
    <div class="form-design form-box padB0 clearfix">
      <div class="" id="surveyQuestionnaire" #surveyQuestionnaire (scroll)="onScroll($event)">
        <div *ngIf="blankQ">
          <p i18n="@@surveyNotReleasedCurrentLanguage">Survey is not released in current language</p>
        </div>
        <ul class="print-ul">
          <li *ngFor="let tab of questionnaire; let i = index" class="tabwise">
            <span *ngIf="tab[labelVariable] && !tab.deleted" class="print-tab TSST-box pos-r" style="border:1px solid #ccc; background-color: #dadde9" [ngClass]="(tab.removed_from_questionnaire ? 'deleted2':'')">
              {{tab[labelVariable][currentLocaleLanguageIdIndex]?.label}}
            </span>
            <ul>
              <li *ngFor="let section of tab?.sections; let sectionIndex = index;">
                <span *ngIf="!section.deleted && section[labelVariable]" class="print-section TSST-box pos-r" [ngClass]="(section.removed_from_questionnaire ? 'deleted2':'')">
                  {{section[labelVariable][currentLocaleLanguageIdIndex]?.label}}
                </span>
                <ul>
                  <li *ngFor="let question of section?.questions; let questionIndex = index">
                    <div *ngIf="!question.deleted" class="form-group mb-2" #target [ngClass]="questionIndex % 2 == 0 ? 'questionDiv' : 'questionDiv2'"
                    [class.display-logic-key]="displayLogicDisplayIfKey?.code == question.code">
                      <div class="pRight-200 pos-r repeater mb-2">
                        <label class="question-label" *ngIf="question[labelVariable]" [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')">
                          <span>{{question[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                          <span *ngIf="question[displayLogicVariable]?.length > 0" class="ml-3"><i class="utilicon utilicon-eye-light"></i></span>
                          <span *ngIf="question[autocalculateFormulaVariable]?.length > 0" class="ml-3"><img src="assets/images/formula-gray.webp" alt="formula"/></span>
                        </label>
                      </div>
                      <div class="clearfix">
                        <div *ngIf="question.qa_subtype == 'select'" class="mt-2">
                          <div *ngFor="let option of question?.options" class="mr-4 inline-block">
                            <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                              <input [name]="question.code" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == question.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == question.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != question.id)" type="radio" class="mr-1" (change)="setOptionAsDisplayLogic($event, option, question); $event.stopPropagation()">
                              <span *ngIf="option[labelVariable]" [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                            </span>
                          </div>
                        </div>
                        <input  *ngIf="question.qa_subtype == 'text' || question.qa_subtype == 'number'" disabled class="form-control" (change)="sectionFormChangeHandler($event, question)" style="background-color: rgba(255, 255, 255, 0.2);">
                        <input disabled *ngIf="question.qa_subtype == 'file'" type="file" disabled class="form-control" style="padding-top: 5px;background-color: rgba(255, 255, 255, 0.2);">
                        <div class="dateIcon" *ngIf="question.qa_subtype == 'date'">
                          <input bsDatepicker disabled placement="top" class="form-control" style="background-color: rgba(255, 255, 255, 0.2);"/>
                          <i class="utilicon utilicon-calendar-alt" style="color: #ccc;"></i>
                        </div>
                        <div *ngIf="question.qa_subtype == 'multiselect'" class="mt-2">
                          <div *ngFor="let option of question?.options" class="mr-4 inline-block">
                              <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                <input [name]="option.code" type="checkbox" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == question.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == question.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != question.id)"  class="mr-1"
                                (change)="setOptionAsDisplayLogic($event, option, question); $event.stopPropagation()">
                                <span *ngIf="option[labelVariable]" [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li *ngFor="let subsection of section?.subsections; let subsectionIndex = index">
                    <span *ngIf="!subsection.deleted && subsection[labelVariable]" class="print-subsection TSST-box pos-r" [ngClass]="(subsection.removed_from_questionnaire ? 'deleted':'')">
                      {{subsection[labelVariable][currentLocaleLanguageIdIndex]?.label}}
                      <i class="fa fa-list-alt ml-2" aria-hidden="true"></i>
                      <span *ngIf="subsection[displayLogicVariable]?.length > 0" class="ml-3"><i class="utilicon utilicon-eye-light"></i></span>
                      
                    </span>                    
                    <ul>
                      <li *ngFor="let q of subsection.questions; let qIndex = index;" class="pl-2 pr-2">
                        <div *ngIf="!q.deleted" class="form-group mb-2" [ngClass]="qIndex % 2 == 0 ? 'questionDiv' : 'questionDiv2'"
                        [class.display-logic-key]="displayLogicDisplayIfKey?.code == q.code">
                          <div class="pRight-200 pos-r repeater mb-2">
                            <label class="question-label" *ngIf="q[labelVariable]" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')">
                              <span>{{q[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                              <span *ngIf="q[displayLogicVariable]?.length > 0" class="ml-3"><i class="utilicon utilicon-eye-light"></i></span>
                              <span *ngIf="q[autocalculateFormulaVariable]?.length > 0" class="ml-3"><img src="assets/images/formula-gray.webp" alt="formula"/></span>
                            </label>
                          </div>
                          <div class="clearfix">
                            <div *ngIf="q.qa_subtype == 'select'" class="mt-2">
                              <div *ngFor="let option of q?.options" class="mr-4 inline-block">
                                <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                  <input [name]="q.code" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == q.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == q.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != q.id)" type="radio" class="mr-1" (change)="setOptionAsDisplayLogic($event, option, q); $event.stopPropagation()">
                                  <span *ngIf="option[labelVariable]" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                                </span>
                              </div>
                            </div>
                            <input disabled class="form-control" *ngIf="q.qa_subtype == 'text'" style="background-color: rgba(255, 255, 255, 0.2);">
                            <input disabled class="form-control" *ngIf="q.qa_subtype == 'number'" style="background-color: rgba(255, 255, 255, 0.2);">
                            <input disabled *ngIf="q.qa_subtype == 'file'" type="file" disabled class="form-control" style="padding-top: 5px;background-color: rgba(255, 255, 255, 0.2);">
                            <div class="dateIcon" *ngIf="q.qa_subtype == 'date'">
                              <input bsDatepicker disabled placement="top" class="form-control" style="background-color: rgba(255, 255, 255, 0.2);"/>
                              <i class="utilicon utilicon-calendar-alt" style="color: #ccc;"></i>
                            </div>
                            <div *ngIf="q.qa_subtype == 'multiselect'" class="mt-2">
                              <div *ngFor="let option of q?.options" class="mr-4 inline-block">
                                <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                  <input [name]="option.code" type="checkbox" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == q.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == q.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != q.id)"  class="mr-1"
                                  (change)="setOptionAsDisplayLogic($event, option, q); $event.stopPropagation()">
                                  <span *ngIf="option[labelVariable]" class="ml-2" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li *ngFor="let table of section?.tables; let tableIndex = index">
                    <span *ngIf="!table.deleted && table[labelVariable]" class="print-subsection TSST-box pos-r" [ngClass]="(table.removed_from_questionnaire ? 'deleted':'')">
                      {{table[labelVariable][currentLocaleLanguageIdIndex]?.label}} 
                      <i class="fa fa-table ml-2" aria-hidden="true"></i>
                      <span *ngIf="table[displayLogicVariable]?.length > 0" class="ml-3"><i class="utilicon utilicon-eye-light"></i></span>
                    </span>                    
                    <ul>
                      <li *ngFor="let q of table.questions; let qIndex = index;">
                        <div *ngIf="!q.deleted" class="form-group mb-2" [ngClass]="qIndex % 2 == 0 ? 'questionDiv' : 'questionDiv2'"
                        [class.display-logic-key]="displayLogicDisplayIfKey?.code == q.code">
                          <div class="pRight-200 pos-r repeater mb-2">
                            <label class="question-label" *ngIf="q[labelVariable]" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')">
                              <span>{{q[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                              <span *ngIf="q[displayLogicVariable]?.length > 0" class="ml-3"><i class="utilicon utilicon-eye-light"></i></span>
                              <span *ngIf="q[autocalculateFormulaVariable]?.length > 0" class="ml-3"><img src="assets/images/formula-gray.webp" alt="formula"/></span>
                            </label>
                          </div>
                          <div class="clearfix">
                            <div *ngIf="q.qa_subtype == 'select'" class="mt-2">
                              <div *ngFor="let option of q?.options" class="mr-4 inline-block">
                                <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                  <input [name]="q.code" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == q.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == q.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != q.id)" type="radio" class="mr-1" (change)="setOptionAsDisplayLogic($event, option, q); $event.stopPropagation()">
                                  <span *ngIf="option[labelVariable]" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                                </span>
                              </div>
                            </div>
                            <input disabled class="form-control" *ngIf="q.qa_subtype == 'text'" style="background-color: rgba(255, 255, 255, 0.2);">
                            <input disabled class="form-control" *ngIf="q.qa_subtype == 'number'" style="background-color: rgba(255, 255, 255, 0.2);">
                            <input disabled *ngIf="q.qa_subtype == 'file'" type="file" disabled class="form-control" style="padding-top: 5px;background-color: rgba(255, 255, 255, 0.2);">
                            <div class="dateIcon" *ngIf="q.qa_subtype == 'date'">
                              <input bsDatepicker disabled placement="top" class="form-control" style="background-color: rgba(255, 255, 255, 0.2);"/>
                              <i class="utilicon utilicon-calendar-alt" style="color: #ccc;"></i>
                            </div>
                            <div *ngIf="q.qa_subtype == 'multiselect'" class="mt-2">
                              <div *ngFor="let option of q?.options" class="mr-4 inline-block">
                                <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                  <input [name]="option.code" type="checkbox" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == q.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == q.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != q.id)"  class="mr-1"
                                  (change)="setOptionAsDisplayLogic($event, option, q); $event.stopPropagation()">
                                  <span *ngIf="option[labelVariable]" [ngClass]="(q.removed_from_questionnaire ? 'deleted':'')" class="ml-2" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                                </span>
                              </div>
                            </div>                            
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="clearfix"></div>
        <div class="scroll-to-top" [ngClass]="{'show-scrollTop': showUpArrow}">
            <button (click)="scrollToTop()" style="padding:6px 12px" class="btn btn-primary btn-icon btn-circle" id="go-to-top-button" aria-label="Go to top">                
                <img alt="Go to top" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSwxOTcuMjg3TDE1OS4wMjgsNjkuMzgxYy0zLjYxMy0zLjYxNy03Ljg5NS01LjQyNC0xMi44NDctNS40MjRzLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0TDUuNDI0LDE5Ny4yODcgICBDMS44MDcsMjAwLjkwNCwwLDIwNS4xODYsMCwyMTAuMTM0czEuODA3LDkuMjMzLDUuNDI0LDEyLjg0N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyNSwxMi44NSw1LjQyNWgyNTUuODEzICAgYzQuOTQ5LDAsOS4yMzMtMS44MDgsMTIuODQ4LTUuNDI1YzMuNjEzLTMuNjEzLDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N1MyOTAuNTQ4LDIwMC45MDQsMjg2LjkzNSwxOTcuMjg3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iI2ZmZmZmZiI+PC9wYXRoPgo8L2c+PC9nPiA8L3N2Zz4=" />
            </button>
        </div>

        <!-- added for print  -->
        <div id="forPrint" #forPrint  style="display: none;">
          <div style="font-family: 'DM Sans', Verdana;">
            <div *ngFor="let tab of questionnaire; let i = index">
              <span *ngIf="tab[labelVariable] && !tab.deleted" style="border:1px solid #ccc; background-color: #dadde9; border-radius: 4px; display:block; padding: 5px 10px; font-weight: 500; margin-bottom: 10px; font-size:20px" [ngClass]="(tab.removed_from_questionnaire ? 'deleted2':'')">
                {{tab[labelVariable][currentLocaleLanguageIdIndex]?.label}}
              </span>
              <div *ngFor="let section of tab?.sections; let sectionIndex = index;" style="margin-bottom:0.0cm; margin-left:20px;" [ngStyle]="{'height': section.totalQuestionsHeight}">
                <span *ngIf="!section.deleted && section[labelVariable]" style="border:1px solid #ccc; background-color: #EBEBEB; border-radius: 4px; display:block; padding: 5px 10px; font-weight: 500; margin-bottom: 10px; font-size:18px"  [ngClass]="(section.removed_from_questionnaire ? 'deleted2':'')">
                  {{section[labelVariable][currentLocaleLanguageIdIndex]?.label}}
                </span>
                <div *ngFor="let question of section?.questions; let questionIndex = index" style="margin-left: 20px;">
                  <div *ngIf="!question.deleted" style="padding: 10px; border:1px solid #EBEBEB; background-color:#F7FCF3; border-radius: 4px; margin-bottom:10px; display: block;" [ngStyle]="{'background-color': (questionIndex % 2 == 0) ? '#F7FCF3' : '#FAF9FC'}"
                  [class.display-logic-key]="displayLogicDisplayIfKey?.code == question.code">
                    <div style="display: block; margin-bottom: 8px;">
                      <label *ngIf="question[labelVariable]" [ngStyle]="{'color': (question.removed_from_questionnaire) ? '#dfdbdb' : ''}">
                        <span style="font-size: 16px; font-weight: 500;">{{question[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                        <span style="font-size: 16px; font-weight: 500; margin-left: 15px;" *ngIf="question[displayLogicVariable]?.length > 0" ><img style="width:20px; margin-top: 6px;" src="/{{locale}}/assets/online_images/view.png" alt="view"/></span>
                        <span style="font-size: 16px; font-weight: 500; margin-left: 15px;" *ngIf="question[autocalculateFormulaVariable]?.length > 0" ><img style="width:10px; margin-top: 6px;" src="/{{locale}}/assets/images/formula-gray.webp" alt="formula"/></span>
                      </label>
                    </div>
                    <div class="clearfix">
                      <div *ngIf="question.qa_subtype == 'select'">
                        <div *ngFor="let option of question?.options" style="margin-right: 20px; display: inline-block;">
                          <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                            <input [name]="question.code" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == question.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == question.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != question.id)" type="radio" class="mr-1" (change)="setOptionAsDisplayLogic($event, option, question); $event.stopPropagation()">
                            <span *ngIf="option[labelVariable]" [ngStyle]="{'color': (question.removed_from_questionnaire) ? '#dfdbdb' : ''}" style="margin-left: 10px;" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                          </span>
                        </div>
                      </div>
                      <input *ngIf="question.qa_subtype == 'text' || question.qa_subtype == 'number'" disabled class="form-control" (change)="sectionFormChangeHandler($event, question)" style="background-color: rgba(255, 255, 255, 0.2); width:100%; display:block; border: 1px solid #cdcdcd; border-radius: 3px; padding: 8px 12px;">
                      <input *ngIf="question.qa_subtype == 'file'" type="file" disabled class="form-control" style="background-color: rgba(255, 255, 255, 0.2); width:100%; display:block; border: 1px solid #cdcdcd; border-radius: 3px; padding: 8px 12px;">
                      <div class="dateIcon" *ngIf="question.qa_subtype == 'date'">
                        <input bsDatepicker disabled placement="top" class="form-control" style="background-color: rgba(255, 255, 255, 0.2); width:100%; display:block; border: 1px solid #cdcdcd; border-radius: 3px; padding: 8px 12px;"/>
                        <i class="utilicon utilicon-calendar-alt" style="color: #ccc;"></i>
                      </div>
                      <div *ngIf="question.qa_subtype == 'multiselect'">
                        <div *ngFor="let option of question?.options" style="margin-right: 20px; display: inline-block;">
                            <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                              <input [name]="option.code" type="checkbox" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == question.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == question.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != question.id)"  class="mr-1"
                              (change)="setOptionAsDisplayLogic($event, option, question); $event.stopPropagation()">
                              <span *ngIf="option[labelVariable]" [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')" style="margin-left: 10px;" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- subsections -->
                <div *ngFor="let subsection of section?.subsections; let subsectionIndex = index"  style="margin-left: 20px;">
                  <span *ngIf="!subsection.deleted && subsection[labelVariable]" style="border:1px solid #ccc; background-color: #fdfdfd; border-radius: 4px; display:block; padding: 5px 10px; font-weight: 500; margin-bottom: 10px; font-size:16px" [ngStyle]="{'color': (subsection.removed_from_questionnaire) ? '#dfdbdb' : ''}">
                    {{subsection[labelVariable][currentLocaleLanguageIdIndex]?.label}}
                    <img src="/{{locale}}/assets/online_images/subsection.png" alt="Table" style="width:22px; margin-left:10px">                    
                    <span *ngIf="subsection[displayLogicVariable]?.length > 0" style="margin-left: 15px;"><img style="width:20px; margin-top: 6px;" src="/{{locale}}/assets/online_images/view.png" alt="view"/></span>
                  </span> 
                  <div *ngFor="let question of subsection?.questions; let questionIndex = index" style="margin-left: 20px;">
                    <div *ngIf="!question.deleted" style="padding: 10px; border:1px solid #EBEBEB; background-color:#F7FCF3; border-radius: 4px; margin-bottom:10px; display: block;" [ngStyle]="{'background-color': (questionIndex % 2 == 0) ? '#F7FCF3' : '#FAF9FC'}"
                    [class.display-logic-key]="displayLogicDisplayIfKey?.code == question.code">
                      <div style="display: block; margin-bottom: 8px;">
                        <label *ngIf="question[labelVariable]" [ngStyle]="{'color': (question.removed_from_questionnaire) ? '#dfdbdb' : ''}">
                          <span style="font-size: 16px; font-weight: 500;">{{question[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                          <span style="font-size: 16px; font-weight: 500; margin-left: 15px;" *ngIf="question[displayLogicVariable]?.length > 0" ><img style="width:20px; margin-top: 6px;" src="/{{locale}}/assets/online_images/view.png" alt="view"/></span>
                          <span style="font-size: 16px; font-weight: 500; margin-left: 15px;" *ngIf="question[autocalculateFormulaVariable]?.length > 0" ><img style="width:10px; margin-top: 6px;" src="/{{locale}}/assets/images/formula-gray.webp" alt="formula"/></span>
                        </label>
                      </div>
                      <div class="clearfix">
                        <div *ngIf="question.qa_subtype == 'select'">
                          <div *ngFor="let option of question?.options" style="margin-right: 20px; display: inline-block;">
                            <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                              <input [name]="question.code" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == question.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == question.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != question.id)" type="radio" class="mr-1" (change)="setOptionAsDisplayLogic($event, option, question); $event.stopPropagation()">
                              <span *ngIf="option[labelVariable]" [ngStyle]="{'color': (question.removed_from_questionnaire) ? '#dfdbdb' : ''}" style="margin-left: 10px;" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                            </span>
                          </div>
                        </div>
                        <input *ngIf="question.qa_subtype == 'text' || question.qa_subtype == 'number'" disabled class="form-control" (change)="sectionFormChangeHandler($event, question)" style="background-color: rgba(255, 255, 255, 0.2); width:100%; display:block; border: 1px solid #cdcdcd; border-radius: 3px; padding: 8px 12px;">
                        <input *ngIf="question.qa_subtype == 'file'" type="file" disabled class="form-control" style="background-color: rgba(255, 255, 255, 0.2); width:100%; display:block; border: 1px solid #cdcdcd; border-radius: 3px; padding: 8px 12px;">
                        <div class="dateIcon" *ngIf="question.qa_subtype == 'date'">
                          <input bsDatepicker disabled placement="top" class="form-control" style="background-color: rgba(255, 255, 255, 0.2); width:100%; display:block; border: 1px solid #cdcdcd; border-radius: 3px; padding: 8px 12px;"/>
                          <i class="utilicon utilicon-calendar-alt" style="color: #ccc;"></i>
                        </div>
                        <div *ngIf="question.qa_subtype == 'multiselect'">
                          <div *ngFor="let option of question?.options" style="margin-right: 20px; display: inline-block;">
                              <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                <input [name]="option.code" type="checkbox" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == question.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == question.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != question.id)"  class="mr-1"
                                (change)="setOptionAsDisplayLogic($event, option, question); $event.stopPropagation()">
                                <span *ngIf="option[labelVariable]" [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')" style="margin-left: 10px;" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- tables -->
                <div *ngFor="let table of section?.tables; let tableIndex = index" style="margin-left: 20px;">
                  <span *ngIf="!table.deleted && table[labelVariable]" style="border:1px solid #ccc; background-color: #fdfdfd; border-radius: 4px; display:block; padding: 5px 10px; font-weight: 500; margin-bottom: 10px; font-size:16px" [ngStyle]="{'color': (table.removed_from_questionnaire) ? '#dfdbdb' : ''}">
                    {{table[labelVariable][currentLocaleLanguageIdIndex]?.label}}
                    <img src="/{{locale}}/assets/online_images/table.png" alt="Table" style="height:18px; margin-left:10px">                    
                    <span *ngIf="table[displayLogicVariable]?.length > 0" style="margin-left: 15px;"><img style="width:20px; margin-top: 6px;" src="/{{locale}}/assets/online_images/view.png" alt="view"/></span>
                  </span> 
                  <div *ngFor="let question of table?.questions; let questionIndex = index" style="margin-left: 20px;">
                    <div *ngIf="!question.deleted" style="padding: 10px; border:1px solid #EBEBEB; background-color:#F7FCF3; border-radius: 4px; margin-bottom:10px; display: block;" [ngStyle]="{'background-color': (questionIndex % 2 == 0) ? '#F7FCF3' : '#FAF9FC'}"
                    [class.display-logic-key]="displayLogicDisplayIfKey?.code == question.code">
                      <div style="display: block; margin-bottom: 8px;">
                        <label *ngIf="question[labelVariable]" [ngStyle]="{'color': (question.removed_from_questionnaire) ? '#dfdbdb' : ''}">
                          <span style="font-size: 16px; font-weight: 500;">{{question[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                          <span style="font-size: 16px; font-weight: 500; margin-left: 15px;" *ngIf="question[displayLogicVariable]?.length > 0" ><img style="width:20px; margin-top: 6px;" src="/{{locale}}/assets/online_images/view.png" alt="view"/></span>
                          <span style="font-size: 16px; font-weight: 500; margin-left: 15px;" *ngIf="question[autocalculateFormulaVariable]?.length > 0" ><img style="width:10px; margin-top: 6px;" src="/{{locale}}/assets/images/formula-gray.webp" alt="formula"/></span>
                        </label>
                      </div>
                      <div class="clearfix">
                        <div *ngIf="question.qa_subtype == 'select'">
                          <div *ngFor="let option of question?.options" style="margin-right: 20px; display: inline-block;">
                            <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                              <input [name]="question.code" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == question.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == question.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != question.id)" type="radio" class="mr-1" (change)="setOptionAsDisplayLogic($event, option, question); $event.stopPropagation()">
                              <span *ngIf="option[labelVariable]" [ngStyle]="{'color': (question.removed_from_questionnaire) ? '#dfdbdb' : ''}" style="margin-left: 10px;" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                            </span>
                          </div>
                        </div>
                        <input *ngIf="question.qa_subtype == 'text' || question.qa_subtype == 'number'" disabled class="form-control" (change)="sectionFormChangeHandler($event, question)" style="background-color: rgba(255, 255, 255, 0.2); width:100%; display:block; border: 1px solid #cdcdcd; border-radius: 3px; padding: 8px 12px;">
                        <input *ngIf="question.qa_subtype == 'file'" type="file" disabled class="form-control" style="background-color: rgba(255, 255, 255, 0.2); width:100%; display:block; border: 1px solid #cdcdcd; border-radius: 3px; padding: 8px 12px;">
                        <div class="dateIcon" *ngIf="question.qa_subtype == 'date'">
                          <input bsDatepicker disabled placement="top" class="form-control" style="background-color: rgba(255, 255, 255, 0.2); width:100%; display:block; border: 1px solid #cdcdcd; border-radius: 3px; padding: 8px 12px;"/>
                          <i class="utilicon utilicon-calendar-alt" style="color: #ccc;"></i>
                        </div>
                        <div *ngIf="question.qa_subtype == 'multiselect'">
                          <div *ngFor="let option of question?.options" style="margin-right: 20px; display: inline-block;">
                              <span *ngIf="!option.removed_from_questionnaire && !option.deleted">
                                <input [name]="option.code" type="checkbox" [disabled]="!modifyDisplayLogicForAsset || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.id == question.id) || (modifyDisplayLogicForAsset && modifyDisplayLogicForAsset?.code == question.parent_code) || (displayLogicDisplayIfKey && displayLogicDisplayIfKey?.id != question.id)"  class="mr-1"
                                (change)="setOptionAsDisplayLogic($event, option, question); $event.stopPropagation()">
                                <span *ngIf="option[labelVariable]" [ngClass]="(question.removed_from_questionnaire ? 'deleted':'')" style="margin-left: 10px;" [class.display-logic-key]="displayLogicDisplayIfValue?.code == option.code">{{option[labelVariable][currentLocaleLanguageIdIndex]?.label}}</span>
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
