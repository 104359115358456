import { Component,
  OnInit,
  OnDestroy,
  ViewChild, ElementRef, AfterViewInit, Renderer, ChangeDetectionStrategy, Renderer2, Inject, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Project, UserTypeServiceMapping } from '../../../models/project';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { chart } from 'highcharts';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
var parentScope01;

@Component({
  selector: 'app-farms-profile',
  templateUrl: './farms-profile.component.html',
  styleUrls: ['./farms-profile.component.css']
})
export class FarmsProfileComponent implements OnInit {
  @ViewChild('charts') public chartElement01: ElementRef;
  projects: any = [];
  project: any;
  projectId = 0;
  dashboardId;
  dashboardData = [];
  reportIDs = [];
  predefinedReports = [];
  constructor(
    private readonly route: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private hcRService:HighchartReportsService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private renderer: Renderer2,
  )  { }

  ngOnInit() {
    this.dashboardId = this.route.snapshot.params['id2'];
    this.projectId = this.route.snapshot.params['id'];

    this.projectService.getProjectBasicInfo(this.projectId).subscribe(response =>{
      this.project = response.data;
      const projectObject = this.project.filter(item => item.id == this.projectId)[0];
      this.project = projectObject;
    });

    const requestObject = {
        //"dashboardId":this.dashboardId
        "dashboardId":this.dashboardId
    }

    this.hcRService.getDashboardDetailsById(requestObject).subscribe(response =>{
        //console.log("get Dashboard Data ", response);
        if(response.msg == 'success'){
            this.dashboardData = response.data;
          if(this.dashboardData.length > 0){
              //console.log("this.dashboardData ", this.dashboardData);
            const dashboardSelectedReports = [];
            for(let i=0;i< this.dashboardData[0].hc_report_dashboard_dtl.length;i++){
                dashboardSelectedReports.push(this.dashboardData[0].hc_report_dashboard_dtl[i]);
            //this.dashboardEditObject.section = dashboardData[0].hc_report_dashboard_dtl[0].section;
            }
            //console.log("dashboardSelectedReports ", dashboardSelectedReports);
            if(dashboardSelectedReports && dashboardSelectedReports.length > 0){
              for(let i=0;i<dashboardSelectedReports.length;i++){
                this.reportIDs.push(dashboardSelectedReports[i].report_id);      
              }
              //console.log(" this.reportIDs ", this.reportIDs);
              if(this.reportIDs && this.reportIDs.length > 0){
                  this.genarateCharts(this.reportIDs);
              }
            }
          }         
  
        }
      });
  }

  async genarateCharts(reportIds){
    this.spinner.show();
    let requestObject ={};
    requestObject = {
        "reportID":reportIds,        
        "projectId":this.projectId
    };
    //console.log("requestObject ", requestObject);
    const chartsList = [];    

    this.hcRService.getReports(requestObject).subscribe(response =>{
        //console.log("response ", response);
        if(response.msg == 'success'){
          const reportsData = response.data;
            this.predefinedReports = response.data;
            //console.log("reportsData ", this.predefinedReports);
            if(reportsData && reportsData.length > 0){
                for(let i=0;i<reportsData.length;i++){
                    if(reportsData[i].reportId == 115){
                      const option = reportsData[i].conf[0].conf;
                        option.yAxis.visible = false;
                        option.xAxis.visible = false;
                        const series = reportsData[i].series;
                        // series.forEach((item, i) => {
                        //     item.marker = {
                        //         fillColor: {
                        //             radialGradient: { cx: 0.4, cy: 0.3, r: 0.7 },
                        //             stops: [
                        //                 [0, 'rgba(255,255,255,0.5)'],
                        //                 [1, Highcharts.color(Highcharts.getOptions().colors[i]).setOpacity(0.5).get('rgba')]
                        //             ]
                        //         }
                        //     }
                        // });
                        
                        let contextMenuOptions = [];
                        contextMenuOptions = [
                          'viewFullscreen',                      
                            {
                            textKey: 'downloadPDF',
                            onclick: function () {
                              this.exportChart({
                                type: 'application/pdf'
                              });
                            }
                            },
                            {
                            textKey: 'downloadPNG',
                            onclick: function () {
                              this.exportChart();
                            }
                          },
                          {
                            textKey: 'downloadXLS',                      
                            onclick: function () {
                              this.downloadXLS();
                            }
                          }
                          // {
                          //   textKey: 'viewData',
                          //   text: 'Toggle data table',
                          //   onclick: function (this) {
                          //     if (this.dataTableDiv && this.dataTableDiv.style.display !== 'none') {
                          //       this.dataTableDiv.style.display = 'none';
                          //     } else {
                          //       this.viewData();
                          //       this.dataTableDiv.style.display = '';
                          //     }
                          //   }                  
                          // }
                        ];
                        option.series = series;
                        //option.title.text = '';
                        //option.chart.height = '100%';
                        // option.exporting = {
                        //   "buttons":{
                        //     "contextButton":{
                        //       "menuItems":contextMenuOptions
                        //     }
                        //   }
                        // }
                        option.exporting.buttons.contextButton.menuItems = contextMenuOptions;
                        //console.log(option);
                        const g = document.createElement("div");
                        const newElement1 = this.chartElement01.nativeElement.appendChild(g); 
                        this.renderer.addClass(newElement1, 'height470');
                        this.hcRService.createChart(newElement1, option);
                    }
                }
            }
            this.spinner.hide();
        } else {
            this.spinner.hide();
        }
    })
  }


  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }
  goToReportingManagement(projectID){
    const url = 'charts/' + this.projectId;
    this.router.navigate([url]);
  }
  goToDashboards(){
    const url="charts/dashboards/"+ this.projectId;
    this.router.navigate([url]);
  }

}
