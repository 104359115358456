import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuestionMapping } from '../models/questionnaire';
import { AppConfigService } from './app-config.service';

@Injectable()
export class QuestionnaireService {

  url = this.appConfigService.getUrl(this.appConfigService.QUESTIONS);

  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) { }

  GetQuestions(projectId: number, header: string): Observable<any> {
   return this.http.get<any>(`${this.url}/${projectId}/${header}`);
  }
  SaveQuestions(mapping: QuestionMapping[]): Observable<any> {
    return this.http.post<any>(this.url, mapping);
  }
  GetQuestionMapping(projectId: number): Observable<any> {
    return this.http.get<any>(`${this.url}/${projectId}`);
  }
  GetAllQuestionMapping(): Observable<any> {
    return this.http.get<any>(`${this.url}/getall/questions`);
  }

  getQuestionnaireForProject(projectId) {
    return this.http.get<any>(`${this.url}/getQuestionnaireForProject/${projectId}`);
  }
}
