import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services/project.service';
import { SurveyService } from '../../../services/survey.service';
import { CompareDataService } from '../../../services/compare-data.service';
import { FarmerService } from '../../../services/farmer.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-comparedata',
  templateUrl: './comparedata.component.html',
  styleUrls: ['./comparedata.component.css']
})
export class ComparedataComponent implements OnInit {
  projectId = 0;
  project: any;
  snapshotData: any;
  sourceSnapshotData = [];
  snapshotsListDataDropdown = [];
  unitTrustsPnl = 0;
  sourceSnapshot;
  targetSnapshot;
  targetSnapshotId;
  sourceSnapshotId;
  compareAnswers = false;
  disableSource = true;
  viewComparison = false;
  viewAnswerComparison = false;
  snapshotComparisonData = [];
  revisonFarmers = [];
  showOverview = false;
  selectedFarmerId = 0;
  revObjectText = {};
  activeSnapshot = false;
  checkActiveSnapshot;
  currentVersionText;
  dropdownSettings = {};
  selectPlaceholder:string;
  selectAllText:string;
  unSelectAllText:string;
  searchText1:string;
  selectedFarmerIdDD = []
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly projectService: ProjectService,
    private readonly surveyService: SurveyService,
    private readonly compareDataService: CompareDataService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly farmerService: FarmerService,
    private readonly translate: TranslateService,
  ) { }

  async ngOnInit() {
    this.targetSnapshotId = 0;
    this.sourceSnapshotId = 0;
    this.projectId = this.route.snapshot.params['id'];
    this.projectService.getProjectBasicInfo(this.projectId).subscribe(response =>{
      this.project = response.data;
      const projectObject = this.project.filter(item => String(item.id) === String(this.projectId))[0];
      this.project = projectObject;
    });
    const allSnapshotsWithRevision = await Promise.all([
      this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
    ]);
    this.snapshotData = allSnapshotsWithRevision[0].message;
    this.snapshotData = this.snapshotData.filter(item => item.survey_type === 'snapshot');
    if(this.snapshotData && this.snapshotData.length > 0){
      for(const item of this.snapshotData){
        if(item.revisions.length > 1){
          item.revisions.reverse();
        }
      }
    }
    this.checkActiveSnapshot = this.snapshotData.filter(item => item.survey_stage_changelogs.find(item2 => item2.change_type === 'active'));
    
    if(this.checkActiveSnapshot && this.checkActiveSnapshot.length > 0){
      this.activeSnapshot = true;
    }

    this.translate.get('currentVersion').subscribe(result => {
      this.currentVersionText = result;
    });
      
    this.route.queryParamMap.subscribe(async queryParams => {
      if (queryParams.get('fromQuestion') && queryParams.get('fromQuestion') === 'true') {
        this.compareAnswers = false;
        this.disableSource = false;
        let requestParams = localStorage.getItem('compareSnapshotsQuestionRequestObject');
        requestParams = JSON.parse(requestParams);
        this.targetSnapshotId = requestParams['targetSnapshotId'];
        this.targetSelected(this.targetSnapshotId);
        this.sourceSnapshotId = requestParams['sourceSnapshotId'];
        this.compareOverview();
      }
      if (queryParams.get('fromAnswer') && queryParams.get('fromAnswer') === 'true') {
        this.spinner.show();
        this.compareAnswers = true;
        this.disableSource = false;
        this.unitTrustsPnl = 1;
        let requestParams = localStorage.getItem('compareSnapshotsRevisionRequestObject');
        requestParams = JSON.parse(requestParams);
        this.revObjectText = JSON.parse(localStorage.getItem('compareSnapshotsRevisionTextObject'));
        if(this.revObjectText['targetText'] === this.currentVersionText){
          this.targetSnapshotId = "currentVersion"
        } else {
          this.targetSnapshotId = `${requestParams['targetSnapshotId']}-${requestParams['targetRevisionId']}`;
        }
        this.sourceSnapshotId = `${requestParams['sourceSnapshotId']}-${requestParams['sourceRevisionId']}`;
        this.selectedFarmerId = requestParams['farmerId'];
        this.targetSelected(this.targetSnapshotId);
        this.compareDataService.getRevisionComparisonReport(this.projectId, requestParams).subscribe(response =>{ 
          
          this.targetSnapshot = this.addStatusAndDates(response.targetSnapshot);
          this.sourceSnapshot = this.addStatusAndDates(response.sourceSnapshot);
          this.showOverview = true;
          
          this.spinner.hide();
        });
      }
    })
    this.setTranslatedLabelObjects();
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: this.selectAllText,
      unSelectAllText: this.unSelectAllText,
      searchPlaceholderText:this.searchText1,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }
  
  //added by Kiran for translation
  setTranslatedLabelObjects(): void {
    this.translate.get('selectPlaceholder').subscribe(result => {
      this.selectPlaceholder = result;
    });
    this.translate.get('selectAllText').subscribe(result => {
      this.selectAllText = result;
    });
    this.translate.get('unSelectAllText').subscribe(result => {
      this.unSelectAllText = result;
    });
    this.translate.get('searchText').subscribe(result => {
      this.searchText1 = result;
    });
  }

  setFarmer(eve){
    this.selectedFarmerId = eve.id;
  }

  compareOverview(){
    this.spinner.show();
    this.showOverview = false;
    if(!this.compareAnswers){
      const requestObject = {
        "targetSnapshotId":this.targetSnapshotId,
        "sourceSnapshotId":this.sourceSnapshotId,
        "showDetails":0
      }; 
      localStorage.setItem('compareSnapshotsQuestionRequestObject', JSON.stringify(requestObject));

      this.compareDataService.getComparisonReport(this.projectId, requestObject).subscribe(response =>{ 
        this.sourceSnapshot = this.addStatusAndDates(response.sourceSnapshot);
        this.targetSnapshot = this.addStatusAndDates(response.targetSnapshot);
        this.showOverview = true;
        this.spinner.hide();
      });
    } else {
      let revTargetSelected;
      const revTargetSelectedSTring = this.targetSnapshotId.toString();
      if(revTargetSelectedSTring !== 'currentVersion'){
        revTargetSelected = this.targetSnapshotId;
        revTargetSelected = revTargetSelected.split("-");
      } else {
        const currentVersion = `${this.checkActiveSnapshot[0].id}-0`;        
        revTargetSelected = currentVersion.split("-");
      }
      let revSourceSelected;
      revSourceSelected = this.sourceSnapshotId;
      revSourceSelected = revSourceSelected.split("-");
      const requestObject = {
        "targetSnapshotId":revTargetSelected[0],
        "sourceSnapshotId":revSourceSelected[0],
        "showDetails":0,
        "farmerId":(this.selectedFarmerId > 0 ? this.selectedFarmerId : this.revisonFarmers[0].id),
        "sourceRevisionId":revSourceSelected[1],
        "targetRevisionId":revTargetSelected[1]
      }; 
      localStorage.setItem('compareSnapshotsRevisionRequestObject', JSON.stringify(requestObject));
      const revTarget = document.querySelector('#targetSelect') as HTMLSelectElement;
      const revTargetText = revTarget.options[revTarget.selectedIndex].text;
      const revSource = document.querySelector('#sourceSelect') as HTMLSelectElement;
      const revSourceText = revSource.options[revSource.selectedIndex].text;
      const revObject = {
        "targetText":revTargetText,
        "sourceText":revSourceText
      }
      this.revObjectText = revObject;
      localStorage.setItem('compareSnapshotsRevisionTextObject', JSON.stringify(revObject));
      this.compareDataService.getRevisionComparisonReport(this.projectId, requestObject).subscribe(response =>{ 
        this.targetSnapshot = this.addStatusAndDates(response.targetSnapshot);
        this.sourceSnapshot = this.addStatusAndDates(response.sourceSnapshot);
        this.showOverview = true;
        this.spinner.hide();
      });
    }
  }

  showDifferenceSnapshot(){
    if(!this.compareAnswers){
      this.spinner.show();
      const requestObject = {
        "targetSnapshotId":this.targetSnapshot.surveyDetails.id,
        "sourceSnapshotId":this.sourceSnapshot.surveyDetails.id,
        "showDetails":1
      };

      this.compareDataService.getComparisonReport(this.projectId, requestObject).subscribe(response =>{ 
        const targetSnapshotDiff = response.target;
        const sourceSnapshotDiff = response.source;      
        const targetDiffArr = [];
        if (targetSnapshotDiff && targetSnapshotDiff.length > 0) {
          for (const tab of targetSnapshotDiff) {
            const sectionArr = [];
            let changesCount = 0;
            if (tab.sections && tab.sections.length > 0) {
              for (const section of tab.sections) {
                const questionArr = [];
                const subsectionArr = [];
                const tableArr = [];
                if (section.questions && section.questions.length > 0) {
                  for (const question of section.questions) {
                    if(question.marker && question.marker !== "NO_CHANGE"){
                      const newObj = {
                        "id":question.id,
                        "question":question.survey_q_asset_labels[0].label,
                        "marker":question.marker,
                        "oldQuestion":""
                      }
                      if(question.marker === "UPDATED" || question.marker === "DELETED"){
                        const sourceTab = sourceSnapshotDiff.filter(item => item.code === tab.code);
                        if(sourceTab){
                          const sourceSection = sourceTab[0].sections.filter(item => item.code === section.code);
                          if(sourceSection){
                            const sourceQuestion = sourceSection[0].questions.filter(item => item.code === question.code);
                            newObj.oldQuestion = sourceQuestion[0].survey_q_asset_labels[0].label;
                          }
                        }
                      }
                      questionArr.push(newObj);
                      changesCount++;
                    }
                  }
                }
                if (section.subsections && section.subsections.length > 0) {
                  for (const subsection of section.subsections) {
                    const subsectionQuestionArr = [];
                    if (subsection.questions && subsection.questions.length > 0) {
                      for (const question of subsection.questions) {
                        if(question.marker && question.marker !== "NO_CHANGE"){
                          const newObj = {
                            "id":question.id,
                            "question":question.survey_q_asset_labels[0].label,
                            "marker":question.marker,
                            "oldQuestion":""
                          }
                          if(question.marker === "UPDATED" || question.marker === "DELETED"){
                            const sourceTab = sourceSnapshotDiff.filter(item => item.code === tab.code);
                            if(sourceTab){
                              const sourceSection = sourceTab[0].sections.filter(item => item.code === section.code);
                              if(sourceSection){
                                const sourceSubsection = sourceSection[0].subsections.filter(item => item.code === subsection.code);
                                if(sourceSubsection){
                                  const sourceQuestion = sourceSubsection[0].questions.filter(item => item.code === question.code);
                                  newObj.oldQuestion = sourceQuestion[0].survey_q_asset_labels[0].label;
                                }
                              }
                            }
                          }
                          subsectionQuestionArr.push(newObj)
                          changesCount++;
                        }
                      }
                    }
                    if(subsectionQuestionArr.length > 0){
                      const subsectionObj = {
                        "subsection_name":subsection.survey_q_asset_labels[0].label,
                        "questions":subsectionQuestionArr
                      }
                      subsectionArr.push(subsectionObj)
                    }
                  }
                }
                if (section.tables && section.tables.length > 0) {
                  for (const table of section.tables) {                  
                    const tableQuestionArr = [];
                    if (table.questions && table.questions.length > 0) {
                      for (const question of table.questions) {
                        if(question.marker && question.marker !== "NO_CHANGE"){
                          const newObj = {
                            "id":question.id,
                            "question":question.survey_q_asset_labels[0].label,
                            "marker":question.marker,
                            "oldQuestion":""
                          }                        
                          if(question.marker === "UPDATED" || question.marker === "DELETED"){
                            const sourceTab = sourceSnapshotDiff.filter(item => item.code === tab.code);
                            if(sourceTab){
                              const sourceSection = sourceTab[0].sections.filter(item => item.code === section.code);
                              if(sourceSection){
                                const sourceTable = sourceSection[0].tables.filter(item => item.code === table.code);
                                if(sourceTable){
                                  const sourceQuestion = sourceTable[0].questions.filter(item => item.code === question.code);
                                  
                                  newObj.oldQuestion = sourceQuestion[0].survey_q_asset_labels[0].label;
                                }
                              }
                            }
                          }
                          tableQuestionArr.push(newObj);
                          changesCount++;
                        }
                      }
                    }                  
                    if(tableQuestionArr.length > 0){
                      const tableObj = {
                        "table_name":table.survey_q_asset_labels[0].label,
                        "questions":tableQuestionArr
                      }
                      tableArr.push(tableObj)
                    }
                  }
                }
                if(questionArr.length > 0 || subsectionArr.length > 0 || tableArr.length > 0){
                  const sectionObj = {
                    "section_name":section.survey_q_asset_labels[0].label,
                    "questions":questionArr,
                    "subsections":subsectionArr,
                    "tables":tableArr
                  }
                  sectionArr.push(sectionObj)
                }
              }
            }
            if(sectionArr.length > 0){
              const tabObj = {
                "tab_name":tab.survey_q_asset_labels[0].label,
                "sections":sectionArr,
                "total_changes":changesCount
              }
              targetDiffArr.push(tabObj)
            }
          }
        }
        this.viewComparison = true;
        this.snapshotComparisonData = targetDiffArr;
        
        this.spinner.hide();    
        window.scroll(0,0);
      });
    } else {
      this.viewAnswerComparison = true;
    }
  }
  addStatusAndDates(snapshot){
    const tempTargetSnapshot = snapshot;
    let targetStatus = "Draft";
    let targetSurveyActivatedOn;
    let targetSurveyClosedOn;
    for (var val of tempTargetSnapshot.surveyDetails.survey_stage_changelogs) {
      if(val.change_type === "closed"){
        targetStatus = "Closed";
        targetSurveyClosedOn = val.change_at;
      }
      if(val.change_type === "active"){
        targetStatus = "Active";
        targetSurveyActivatedOn = val.change_at;
      }
    }
    tempTargetSnapshot.surveyDetails.status = targetStatus;
    tempTargetSnapshot.surveyDetails.activated_on = targetSurveyActivatedOn;
    tempTargetSnapshot.surveyDetails.closed_on = targetSurveyClosedOn;
    var sortable = [];
    for (var tab in tempTargetSnapshot.tabkeys) {
        sortable.push([tab, tempTargetSnapshot.tabkeys[tab]]);
    }
    tempTargetSnapshot.sortedTab = sortable;
    return tempTargetSnapshot;
  }

  closeComparison(){
    this.viewComparison = false;
  }
  closeAnswerComparison(){
    this.viewAnswerComparison = false;
  }

  trackBySourceSnapshot(index, dataItem) {
    return dataItem.section;
  }
  trackByRevisions(index, dataItem) {
    return dataItem.section;
  }
  trackByRevisionFarmers(index, dataItem) {
    return dataItem.section;
  }
  reportTypeChange(type){    
    this.showOverview = false;
    this.targetSnapshotId = 0;
    this.sourceSnapshotId = 0;
    this.selectedFarmerId = 0;
    if(type === "answer"){
      this.compareAnswers = true;
    } else {
      this.compareAnswers = false;
    }
  }

  targetSelected(ev, fromUI?){
    if(this.compareAnswers){
      if(this.targetSnapshotId !== 0){
        //this.disableSource = false;
        if(this.targetSnapshotId !== 'currentVersion'){
          let curValue = this.targetSnapshotId;
          curValue = curValue.split("-");
          
          const targetSequenceId = this.snapshotData.filter(item => parseInt(item.id) === parseInt(curValue[0]))[0];
          
          const targetRevisionId = targetSequenceId.revisions.filter(item => parseInt(item.id) === parseInt(curValue[1]))[0];
          
          const res = this.snapshotData.reduce((acc, a) => {
            if(a.survey_type_sequence === targetSequenceId.survey_type_sequence){
              const ch = a.revisions && a.revisions.filter(b => b.revision_number < targetRevisionId.revision_number);
              
              if(ch && ch.length) {
                acc.push({...a, revisions: ch})
              }
            } else if(a.survey_type_sequence < targetSequenceId.survey_type_sequence) {
              acc.push({ ...a});
            }
            return acc;
          }, []);        
          this.sourceSnapshotData = res;
          if(fromUI){
            if(this.sourceSnapshotId == 0){
              this.disableSource = false;
            } else {
              const checkSourceSnapshot = this.sourceSnapshotData.filter(item => item.id === curValue[0]); 
              if(checkSourceSnapshot.length > 0){
                this.disableSource = false;
              } else {
                this.sourceSnapshotId = 0;
              }
            }
          }
          this.getFarmerForRevision(curValue[1], fromUI);
        } else {
          this.disableSource = false;
          this.sourceSnapshotData = this.snapshotData;
          this.farmerService.getFarmersByProjectBasicData(this.projectId).subscribe(response =>{ 
            this.revisonFarmers = response.data;
            for(let farmer of this.revisonFarmers){
              let idName;
              if(farmer.official_id){
                farmer.supplier_id = farmer.official_id;
                idName = `${farmer.official_id} - ${farmer.name}`;
              } else {
                idName = farmer.name;
              }
              farmer.name = idName;
              if(fromUI){
                this.selectedFarmerIdDD = [];
              } else if(farmer.id == this.selectedFarmerId) {
                this.selectedFarmerIdDD = [{'id':farmer.id, 'name':farmer.name}];
              }
            }
          });
        }
      }      
    } else if(this.targetSnapshotId > 0){
      const targetSequenceId = this.snapshotData.filter(item => item.id === this.targetSnapshotId)[0];
      const sourceDataset = this.snapshotData.filter(item => item.survey_type_sequence < targetSequenceId.survey_type_sequence);
      this.sourceSnapshotData = sourceDataset;
      if(this.sourceSnapshotId == 0){
        this.disableSource = false;
      } else {
        const checkSourceSnapshot = this.sourceSnapshotData.filter(item => item.id === this.sourceSnapshotId); 
        if(checkSourceSnapshot.length > 0){
          this.disableSource = false;
        } else {
          this.sourceSnapshotId = 0;
        }
      }
    }
  }
  viewSnapshot(rType){
    if(this.compareAnswers){
      const url = `questionnaire/view/${this.projectId}/${this.selectedFarmerId > 0 ? this.selectedFarmerId : this.revisonFarmers[0].id}`;
      this.router.navigate([url], {queryParams: {fromCompare : 1, type : rType}});
    } else {
      if(rType === 'target'){
        const url = `viewquestionnaire/${this.projectId}/${this.targetSnapshotId}`;
        this.router.navigate([url], {queryParams: {fromCompare : 1}});
      } else {
        const url = `viewquestionnaire/${this.projectId}/${this.sourceSnapshotId}`;
        this.router.navigate([url], {queryParams: {fromCompare : 1}});
      }
    }
  }

  getFarmerForRevision(revisionId, fromUI){
    this.compareDataService.getFarmerList(this.projectId, revisionId).subscribe(response =>{ 
      this.revisonFarmers = response.farmers;
      for(const farmer of this.revisonFarmers){
        let idName;
        if(farmer.supplier_id){
          idName = `${farmer.supplier_id} - ${farmer.name}`;
        } else {
          idName = farmer.name;
        }
        farmer.name = idName;
        if(fromUI){
          this.selectedFarmerIdDD = [];
        } else if(farmer.id == this.selectedFarmerId) {
          this.selectedFarmerIdDD = [{'id':farmer.id, 'name':farmer.name}];
        }
      }
    });
  }

  goToReportingManagement(projectID){
    const url = 'charts/' + this.projectId;
    this.router.navigate([url]);
  }
  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

}
