import { TemplateRef, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DatePipe } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { Users, MultiValuedModels } from '../../models/users';
import { FarmerService } from '../../services/farmer.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/login.service';
var UserListingComponent = /** @class */ (function () {
    function UserListingComponent(datePipe, commonService, farmerService, router, _route, userService, projectService, spinner, toastrService, loginService, fb, modalService, translate) {
        var _this = this;
        this.datePipe = datePipe;
        this.commonService = commonService;
        this.farmerService = farmerService;
        this.router = router;
        this._route = _route;
        this.userService = userService;
        this.projectService = projectService;
        this.spinner = spinner;
        this.toastrService = toastrService;
        this.loginService = loginService;
        this.fb = fb;
        this.modalService = modalService;
        this.translate = translate;
        this.page = 1;
        this.importPage = 1;
        this.itemsPerPage = 10;
        this.itemsPerPageImport = 10;
        this.f1Submitted = false;
        this.f2Submitted = false;
        this.newUser = false;
        this.project = [];
        this.user = new Users();
        this.countries = [];
        this.roles = [];
        this.restrictedRoles = [];
        this.master = [];
        this.multiValuedModels = new MultiValuedModels();
        this.currentUser = this.userService.getCurrentUser();
        this.users = [];
        this.isDeepRoots = false;
        this.isProjectManager = false;
        this.importUser = false;
        this.importUsers = [];
        this.checkboxClicked = false;
        this.userFilter = { name: '' };
        this.userImportFilter = { name: '' };
        this.unsubscribe = new Subject();
        this.usersToRemove = [];
        this.removeAllUsers = false;
        this.user.country_id = 0;
        this.user.user_type_id = 0;
        this.user.name = '';
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
    }
    UserListingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.scrollToTop();
        if (!navigator.onLine) {
            setTimeout(function () {
                _this.commonService.showToast('warning', 'not_available_offline', {});
            }, 0);
            this.router.navigate(['/dashboard']);
            return;
        }
        this.loadMasterData();
        this.countries = this._route.snapshot.data.commonData.countries.data;
        this.isDeepRoots = this.commonService.isDeeproots();
        this.isProjectManager = this.commonService.isManager();
        this.roles = this._route.snapshot.data.commonData.roles.data;
        this.restrictedRoles = this.roles.filter(function (item) { return item.id !== 1; });
        this.loadAllProject();
        this.userForm2 = new FormGroup({
            'firstName': new FormControl('', [Validators.required]),
            'middleName': new FormControl(''),
            'lastName': new FormControl(''),
            'alias': new FormControl(''),
            'country': new FormControl('', [Validators.required]),
            'role': new FormControl('', [Validators.required]),
            'country_dial_in': new FormControl('', [Validators.required]),
            'mobile': new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]),
            'email': new FormControl('', [Validators.required, Validators.email]),
            'language': new FormControl('', [Validators.required])
        });
        this.userUpdateForm2 = new FormGroup({
            'firstName': new FormControl('', [Validators.required]),
            'middleName': new FormControl(''),
            'lastName': new FormControl(''),
            'alias': new FormControl(''),
            'country': new FormControl('', [Validators.required]),
            'role': new FormControl('', [Validators.required]),
            'country_dial_in': new FormControl('', [Validators.required]),
            'mobile': new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]),
            'email': new FormControl('', [Validators.required, Validators.email]),
            'language': new FormControl('', [Validators.required]),
            'deleted': new FormControl('', [Validators.required]),
        });
    };
    Object.defineProperty(UserListingComponent.prototype, "f1", {
        // convenience getter for easy access to form fields
        get: function () { return this.userForm2.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserListingComponent.prototype, "f2", {
        // convenience getter for easy access to form fields
        get: function () { return this.userUpdateForm2.controls; },
        enumerable: true,
        configurable: true
    });
    UserListingComponent.prototype.loadMasterData = function () {
        var _this = this;
        this.farmerService.GetFarmerMaster().pipe(takeUntil(this.unsubscribe))
            .subscribe(function (master) {
            if (master.msg === 'success') {
                _this.master = master.data;
            }
        }, function (err) { });
    };
    UserListingComponent.prototype.loadAllProject = function () {
        var _this = this;
        this.projectService.GetProjects().pipe(takeUntil(this.unsubscribe))
            .subscribe(function (project) {
            if (project.msg === 'success') {
                _this.project = project.data;
            }
        }, function (err) { });
    };
    UserListingComponent.prototype.ngAfterViewInit = function () {
        $('#dataTables-user').DataTable({
            data: this.project,
            columns: [
                { title: 'User Name' },
                { title: 'User Role' },
                { title: 'Firstname' },
                { title: 'Lastname' },
                { title: 'Mobile number' },
                { title: 'Email' },
                { title: 'Status' },
                { title: 'Created Date' },
                { title: 'Actions' }
            ],
            columnDefs: [
                {
                    className: 'sorting_1',
                    targets: [0]
                },
                {
                    className: 'center',
                    targets: [3, 4, 5, 6]
                },
                {
                    targets: [8],
                    visible: this.isDeepRoots
                }
            ]
        });
        $('#importUser').DataTable({
            data: this.allUsers,
            order: [[1, 'asc']],
            columns: [
                { title: 'Select' },
                { title: 'Name' },
                { title: 'Role' },
            ],
            columnDefs: [
                {
                    className: 'text-center',
                    targets: 0
                }
            ]
        });
    };
    UserListingComponent.prototype.searchuserprj = function () {
        var _this = this;
        var selprj = this.elProject.nativeElement.value;
        $('#dataTables-user').dataTable().fnClearTable();
        if (selprj == 0) {
            $('#dataTables-user').dataTable().fnClearTable();
            this.commonService.showToast('warning', 'select_project', {});
            return false;
        }
        this.removeAllUsers = false;
        this.usersToRemove = [];
        this.userService.GetProjectUsers(selprj).pipe(takeUntil(this.unsubscribe))
            .subscribe(function (project) {
            if (project.msg === 'success') {
                _this.users = project.data;
                _this.page = 1;
                for (var i = 0; i < _this.users.length; i++) {
                    _this.users[i].user_type_id = _this.users[i]['UserProgramMapping'][0]['user_type_id'];
                }
            }
        }, function (err) {
            $('#dataTables-user').dataTable().fnClearTable();
        });
    };
    UserListingComponent.prototype.getStatus = function (iStatus) {
        switch (iStatus) {
            case 1: {
                return 'Inactive';
                // break;
            }
            case 0: {
                return 'Active';
                // break;
            }
            default:
                break;
        }
    };
    UserListingComponent.prototype.getUserTypeRole = function (iRole) {
        switch (iRole) {
            case 1: {
                return 'Field Agent';
            }
            case 4: {
                return 'Project Manager';
            }
            case 8: {
                return 'Deep Roots';
            }
            default:
                break;
        }
    };
    UserListingComponent.prototype.populateRoles = function (event, selroleid, selrolename, language, country, prknowledge, partner) {
        this.user.country_id = country;
        this.user.user_type_id = partner;
        this.newUser = false;
        var finalObj = [];
        if (selroleid.length > 0) {
            for (var i = 0; i < selroleid.length; i++) {
                finalObj[finalObj.length] = { 'id': selroleid[i], 'name': selrolename[i] };
            }
        }
    };
    UserListingComponent.prototype.selectevent = function (event, id, user_type_id) {
        this.importUsers.push({ uid: id, pid: user_type_id });
    };
    UserListingComponent.prototype.deselectevent = function (event, id, user_type_id) {
        var index = this.importUsers.indexOf({ uid: id, user_type_id: user_type_id });
        this.importUsers.splice(index, 1);
    };
    UserListingComponent.prototype.resetsrc = function () {
        this.elProject.nativeElement.value = 0;
        this.users = [];
        this.removeAllUsers = false;
        this.usersToRemove = [];
        $('#dataTables-user').dataTable().fnClearTable();
    };
    UserListingComponent.prototype.deleteUser = function () {
        var _this = this;
        var uid = $('#deluserid').val();
        this.spinner.show();
        this.userService.DeleteUser(uid).subscribe(function (result) {
            if (result.msg === 'success') {
                _this.spinner.hide();
                _this.commonService.showToast('success', 'generic_success', {});
                setTimeout(function () {
                    _this.searchuserprj();
                }, 1000);
                $('#userCreation').modal('hide');
            }
            else {
                _this.commonService.showToast('error', 'generic_error', {});
                _this.spinner.hide();
            }
        }, function (err) {
            _this.spinner.hide();
        }, function () { });
    };
    UserListingComponent.prototype.validateProjSel = function () {
        $('#userForm').trigger('reset');
        $('h4#myModalLabel').html('Add User');
        $('button#usrcreatebtn').html('Add');
        $('#uaction').val('add');
        var selprj = this.elProject.nativeElement.value;
        if (selprj === '0') {
            this.commonService.showToast('warning', 'select_project', {});
            return false;
        }
        this.newUser = true;
        $('#userCreation').modal('show');
    };
    // IMPORTANT: THIS FUNCTION IS NOT BEING USED (03/APRIL/2019)
    UserListingComponent.prototype.submit = function () {
        var _this = this;
        var uid = $('#uid').val();
        var errors = [];
        var errorKeys = [];
        if (this.user.language === '') {
            errors[errors.length] = (errors.length + 1) + ". Please enter language";
        }
        if (this.user.name === '') {
            errors[errors.length] = (errors.length + 1) + ". Please enter user firstname";
        }
        if (!this.user.mobile || this.user.mobile === '') {
            errors[errors.length] = (errors.length + 1) + ". Please enter user mobile";
        }
        if (this.user.email === '') {
            errors[errors.length] = (errors.length + 1) + ". Please enter user email";
        }
        var emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!emailRegex.test(String(this.user.email).toLowerCase())) {
            errors[errors.length] = (errors.length + 1) + ". Please enter a valid email address";
        }
        if (this.user.user_type_id <= 0) {
            errors[errors.length] = (errors.length + 1) + ". Please select a User Type";
        }
        var selprj = this.elProject.nativeElement.value;
        if (selprj === 0) {
            errors[errors.length] = (errors.length + 1) + ". Please select a project first";
        }
        var valDropDowns = ['country_id', 'UserProgramMapping'];
        var valDropDownsErrorMsg = ['country', 'role'];
        for (var x = 0; x < valDropDowns.length; x++) {
            if (this.user[valDropDowns[x]] === '0' || this.user[valDropDowns[x]] === 0 || this.user[valDropDowns[x]] === null) {
                errors[errors.length] = (errors.length + 1) + ". Please select a " + valDropDownsErrorMsg[x];
            }
            if (valDropDowns[x] === 'country_id') {
                if ($('#' + valDropDownsErrorMsg[x]).val() === null) {
                    errors[errors.length] = (errors.length + 1) + ". Please select a " + valDropDownsErrorMsg[x];
                }
            }
        }
        if (errors.length > 0) {
            this.toastrService.error(errors.join('<br/>'));
            return false;
        }
        /** Updating user status */
        if (!this.newUser) {
            this.user.deleted = this.deleted.nativeElement.value;
        }
        else {
            this.user.deleted = 0;
        }
        var uaction = $('#uaction').val();
        if (uaction === 'update') {
            this.user.upd_user_id = this.currentUser.id;
            this.user.upd_date = new Date();
        }
        else {
            this.user.cr_user_id = this.currentUser.id;
        }
        var prj = selprj;
        this.addUserProgramMapping(this.user.user_type_id, prj, uid);
        this.spinner.show();
        //this.user.mobile =  this.user.country_code + '-' + this.user.mobile
        this.userService.SaveUser(this.user, uaction, uid, prj).subscribe(function (result) {
            if (result.msg === 'success') {
                _this.spinner.hide();
                _this.commonService.showToast('success', 'generic_success', {});
                setTimeout(function () {
                    _this.searchuserprj();
                }, 1000);
                $('#userCreation').modal('hide');
            }
            else {
                _this.commonService.showToast('error', 'generic_error', {});
                _this.spinner.hide();
            }
        }, function (err) {
        }, function () { });
    };
    UserListingComponent.prototype.trackById = function (index, dataItem) {
        return dataItem.master_id;
    };
    // Set the this.user.country_code value based on the country selected by the user
    UserListingComponent.prototype.setCountryCode = function (action) {
        if (action == 'add') {
            var countryCodeSelected_1 = this.userForm2.value.country;
            var countrySelected = this.countries.filter(function (item) { return item.id === countryCodeSelected_1; })[0];
            if (countrySelected) {
                this.userForm2.patchValue({
                    country_dial_in: countrySelected.country_dial_in
                });
            }
        }
        else {
            var countryCodeSelected_2 = this.userUpdateForm2.value.country;
            var countrySelected = this.countries.filter(function (item) { return item.id == countryCodeSelected_2; })[0];
            if (countrySelected) {
                this.userUpdateForm2.patchValue({
                    country_dial_in: countrySelected.country_dial_in
                });
            }
        }
    };
    UserListingComponent.prototype.addUserProgramMapping = function (partnerId, selprj, uid) {
        this.user.UserProgramMapping.length = 0;
        var userMapping = { id: 0,
            users_id: uid,
            user_type_id: partnerId,
            access_type_id: 1,
            cr_user_id: this.currentUser.id,
            project_id: selprj
        };
        this.user.UserProgramMapping.push(userMapping);
    };
    UserListingComponent.prototype.addUserProgramMapping2 = function (partnerId, selprj, uid) {
        this.userToAdd.UserProgramMapping.length = 0;
        var userMapping = { id: 0,
            users_id: uid,
            user_type_id: partnerId,
            access_type_id: 1,
            cr_user_id: this.currentUser.id,
            project_id: selprj
        };
        this.userToAdd.UserProgramMapping.push(userMapping);
    };
    UserListingComponent.prototype.addUserProgramMappingInUpdate = function (partnerId, selprj, uid) {
        this.userToUpdate.UserProgramMapping.length = 0;
        var userMapping = { id: 0,
            users_id: uid,
            user_type_id: partnerId,
            access_type_id: 1,
            cr_user_id: this.currentUser.id,
            project_id: selprj
        };
        this.userToUpdate.UserProgramMapping.push(userMapping);
    };
    UserListingComponent.prototype.onOpenImportForm = function () {
        var _this = this;
        var selprj = this.elProject.nativeElement.value;
        this.importUser = true;
        this.userService.getAllUsers().pipe(takeUntil(this.unsubscribe))
            .subscribe(function (users) {
            if (users.msg === 'success') {
                _this.allUsers = users.data;
            }
        });
    };
    UserListingComponent.prototype.onImportUser = function () {
        var _this = this;
        $('#userImportForm').trigger('reset');
        var selprj = this.elProject.nativeElement.value;
        if (this.importUsers.length === 0) {
            this.commonService.showToast('error', 'select_user_error', {});
            return false;
        }
        this.importUsers.forEach(function (users) {
            _this.userService.saveUPM(users.uid, users.pid, _this.currentUser.id, selprj).pipe(takeUntil(_this.unsubscribe))
                .subscribe(function (result) {
                var user = _this.allUsers.find(function (x) { return x.id === users.uid; });
                if (result.msg === 'success') {
                    _this.commonService.showToast('success', 'user_import_success', {});
                }
                else {
                    _this.commonService.showToast('info', 'user_already_exists', {});
                }
                _this.hideImportModal();
            });
            setTimeout(function () {
                _this.searchuserprj();
            }, 1000);
        });
        $('#userImport').modal('hide');
        this.importUsers.length = 0;
    };
    UserListingComponent.prototype.onSelectUser = function (user_id, user_type_id) {
        this.importUsers.push({ uid: user_id, pid: user_type_id });
    };
    UserListingComponent.prototype.getUserProjects = function (upmapping) {
        var projectNames = [];
        for (var i = 0; i < upmapping.length; i++) {
            var pId = upmapping[0].project_id;
            this.projectService.GetProjectById(pId).pipe(takeUntil(this.unsubscribe))
                .subscribe(function (project) {
                if (project.msg === 'success') {
                    projectNames.push(project.data.name);
                }
            });
        }
        return projectNames;
    };
    UserListingComponent.prototype.editThisUser = function (user, template) {
        this.userUpdateForm2.patchValue({
            'firstName': user.name,
            'middleName': user.middle_name,
            'lastName': user.last_name,
            'alias': user.alias,
            'country': user.country_id,
            'role': user.user_type_id,
            'country_dial_in': user.country_dial_in,
            'mobile': user.mobile,
            'email': user.email,
            'language': user.language,
            'deleted': user.deleted
        });
        if (!user.country_dial_in) {
            var countryCodeSelected_3 = this.userUpdateForm2.value.country;
            var countrySelected = this.countries.filter(function (item) { return item.id == countryCodeSelected_3; })[0];
            if (countrySelected) {
                this.userUpdateForm2.patchValue({
                    country_dial_in: countrySelected.country_dial_in
                });
            }
        }
        this.userToUpdate = user;
        this.updateUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    };
    UserListingComponent.prototype.deactivateThisUser = function () {
        var _this = this;
        var user = this.userSelectedForDeac;
        var uid = user.id;
        this.userService.DeleteUser(uid).subscribe(function (result) {
            if (result.msg === 'success') {
                _this.userSelectedForDeac = undefined;
                if (_this.deacUserConfirmModal) {
                    _this.deacUserConfirmModal.hide();
                }
                _this.commonService.showToast('success', 'generic_success', {});
                setTimeout(function () {
                    _this.searchuserprj();
                }, 1000);
            }
            else {
                _this.commonService.showToast('error', 'generic_error', {});
            }
        }, function (err) {
        }, function () { });
    };
    UserListingComponent.prototype.openModal = function (template, importUserModalBool) {
        var selprj = this.elProject.nativeElement.value;
        var errors = [];
        var errorKeys = [];
        if (selprj == 0) {
            errorKeys.push('errors.selectProject');
        }
        if (errorKeys.length > 0) {
            var errorMsgs = [];
            for (var i = 0; i < errorKeys.length; i++) {
                errorMsgs[i] = (i + 1) + ". " + this.translate.instant(errorKeys[i]);
            }
            this.toastrService.error(errorMsgs.join('<br/>'));
            return false;
        }
        if (importUserModalBool) {
            this.importUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        }
        else {
            this.addUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        }
    };
    UserListingComponent.prototype.openModalConfirmRemoval = function (template) {
        if (this.users.length > 1) {
            this.removeUsersConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        }
    };
    UserListingComponent.prototype.openModalConfirmDeac = function (template, user) {
        this.userSelectedForDeac = user;
        this.deacUserConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    };
    UserListingComponent.prototype.submitForm = function (form) {
        var _this = this;
        this.f1Submitted = true;
        var errors = [];
        var errorKeys = [];
        var selprj = this.elProject.nativeElement.value;
        if (selprj == 0) {
            errors[errors.length] = (errors.length + 1) + ". Please select a project first";
            errorKeys.push('errors.selectProject');
        }
        if (!form.value.firstName || form.value.firstName.length === 0) {
            errors[errors.length] = (errors.length + 1) + ". Please add a first name";
            errorKeys.push('errors.firstName');
        }
        if (!form.value.country) {
            errors[errors.length] = (errors.length + 1) + ". Please select a country";
            errorKeys.push('errors.country');
        }
        if (!form.value.country_dial_in) {
            errors[errors.length] = (errors.length + 1) + ". Please select a country dial code";
            errorKeys.push('errors.countryDialCode');
        }
        if (!form.value.mobile || form.value.mobile.length === 0) {
            errors[errors.length] = (errors.length + 1) + ". Please add a 10 digit mobile number";
            errorKeys.push('errors.mobileNumberLength');
        }
        if (!form.value.email || form.value.email.length == 0) {
            errors[errors.length] = (errors.length + 1) + ". Please add an email address";
            errorKeys.push('errors.emailAddressExists');
        }
        if (!form.value.language || form.value.language.length == 0) {
            errors[errors.length] = (errors.length + 1) + ". Please add a language";
            errorKeys.push('errors.language');
        }
        var mobileRegex = new RegExp(/^[0-9]*$/);
        if (!mobileRegex.test(form.value.mobile)) {
            errors[errors.length] = (errors.length + 1) + ". Please add a valid mobile number";
            errorKeys.push('errors.mobileNumberValidity');
        }
        var emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!emailRegex.test(String(form.value.email).toLowerCase())) {
            errors[errors.length] = (errors.length + 1) + ". Please add a valid email address";
            errorKeys.push('errors.emailValidity');
        }
        if (errorKeys.length > 0) {
            var errorMsgs = [];
            for (var i = 0; i < errorKeys.length; i++) {
                errorMsgs[i] = (i + 1) + ". " + this.translate.instant(errorKeys[i]);
            }
            this.toastrService.error(errorMsgs.join('<br/>'));
            return false;
        }
        this.userToAdd = {
            name: form.value.firstName,
            middle_name: form.value.middleName,
            last_name: form.value.lastName,
            alias: form.value.alias,
            country_id: form.value.country,
            user_type_id: form.value.role,
            country_dial_in: form.value.country_dial_in,
            mobile: form.value.mobile,
            email: form.value.email,
            language: form.value.language,
            deleted: 0,
            cr_user_id: this.currentUser.id,
            UserProgramMapping: []
        };
        var prj = selprj;
        var uid = +'';
        this.addUserProgramMapping2(this.userToAdd.user_type_id, prj, uid);
        this.userService.SaveUser(this.userToAdd, 'add', uid, prj).subscribe(function (result) {
            if (result.msg === 'success') {
                _this.commonService.showToast('success', 'generic_success', {});
                setTimeout(function () {
                    if (_this.addUserModal) {
                        _this.addUserModal.hide();
                    }
                    _this.userForm2.patchValue({
                        'firstName': '',
                        'middleName': '',
                        'lastName': '',
                        'alias': '',
                        'country': '',
                        'role': '',
                        'country_dial_in': '',
                        'mobile': '',
                        'email': '',
                        'language': ''
                    });
                    _this.f1Submitted = false;
                    _this.searchuserprj();
                }, 1000);
            }
            else {
                _this.commonService.showToast('error', 'generic_error', {});
            }
        }, function (err) {
        }, function () { });
    };
    UserListingComponent.prototype.updateUser = function (form) {
        var _this = this;
        this.f2Submitted = true;
        var errors = [];
        var errorKeys = [];
        var selprj = this.elProject.nativeElement.value;
        if (selprj == 0) {
            errors[errors.length] = (errors.length + 1) + ". Please select a project first";
            errorKeys.push('errors.selectProject');
        }
        if (!form.value.firstName || form.value.firstName.length == 0) {
            errors[errors.length] = (errors.length + 1) + ". Please add a first name";
            errorKeys.push('errors.firstName');
        }
        if (!form.value.country) {
            errors[errors.length] = (errors.length + 1) + ". Please select a country";
            errorKeys.push('errors.country');
        }
        if (!form.value.country_dial_in) {
            errors[errors.length] = (errors.length + 1) + ". Please select a country dial code";
            errorKeys.push('errors.countryDialCode');
        }
        if (!form.value.mobile || form.value.mobile.length == 0) {
            errors[errors.length] = (errors.length + 1) + ". Please add a 10 digit mobile number";
            errorKeys.push('errors.mobileNumberLength');
        }
        if (!form.value.email || form.value.email.length == 0) {
            errors[errors.length] = (errors.length + 1) + ". Please add an email address";
            errorKeys.push('errors.emailAddressExists');
        }
        if (!form.value.language || form.value.language.length === 0) {
            errors[errors.length] = (errors.length + 1) + ". Please add a language";
            errorKeys.push('errors.language');
        }
        var mobileRegex = new RegExp(/^[0-9]*$/);
        if (!mobileRegex.test(form.value.mobile)) {
            errors[errors.length] = (errors.length + 1) + ". Please add a valid mobile number";
            errorKeys.push('errors.mobileNumberValidity');
        }
        var emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!emailRegex.test(form.value.email.toLowerCase())) {
            errors[errors.length] = (errors.length + 1) + ". Please add a valid email address";
            errorKeys.push('errors.emailValidity');
        }
        if (errorKeys.length > 0) {
            var errorMsgs = [];
            for (var i = 0; i < errorKeys.length; i++) {
                errorMsgs[i] = (i + 1) + ". " + this.translate.instant(errorKeys[i]);
            }
            this.toastrService.error(errorMsgs.join('<br/>'));
            return false;
        }
        this.userToUpdateNewValue = {
            name: form.value.firstName,
            middle_name: form.value.middleName,
            last_name: form.value.lastName,
            alias: form.value.alias,
            country_id: form.value.country,
            user_type_id: this.userToUpdate.user_type_id,
            country_dial_in: form.value.country_dial_in,
            mobile: form.value.mobile,
            email: form.value.email,
            language: form.value.language,
            deleted: form.value.deleted,
            cr_user_id: this.currentUser.id,
            UserProgramMapping: this.userToUpdate.UserProgramMapping
        };
        var prj = selprj;
        this.addUserProgramMappingInUpdate(form.value.role, prj, this.userToUpdate.id);
        this.userService.SaveUser(this.userToUpdateNewValue, 'update', this.userToUpdate.id, prj).subscribe(function (result) {
            if (result.msg === 'success') {
                _this.commonService.showToast('success', 'generic_success', {});
                setTimeout(function () {
                    _this.userToUpdate = undefined;
                    _this.userToUpdateNewValue = undefined;
                    _this.userUpdateForm2.patchValue({
                        'firstName': '',
                        'middleName': '',
                        'lastName': '',
                        'alias': '',
                        'country': '',
                        'role': '',
                        'country_dial_in': '',
                        'mobile': '',
                        'email': '',
                        'language': '',
                        'deleted': ''
                    });
                    _this.f2Submitted = false;
                    _this.searchuserprj();
                    if (_this.updateUserModal) {
                        _this.updateUserModal.hide();
                    }
                }, 1000);
            }
            else {
                _this.commonService.showToast('error', 'generic_error', {});
            }
        }, function (err) {
        }, function () { });
    };
    UserListingComponent.prototype.hideAddUserModal = function () {
        if (this.addUserModal) {
            this.addUserModal.hide();
        }
        this.userForm2.reset();
        this.f1Submitted = false;
    };
    UserListingComponent.prototype.hideImportModal = function () {
        if (this.importUserModal) {
            this.importUserModal.hide();
        }
        this.importUsers = [];
        this.userImportFilter.name = '';
    };
    UserListingComponent.prototype.hideUpdateModal = function () {
        if (this.updateUserModal) {
            this.updateUserModal.hide();
        }
        this.userUpdateForm2.reset();
        this.f2Submitted = false;
    };
    UserListingComponent.prototype.toggleUserRemoval = function (user) {
        var userSelectedArray = this.users.filter(function (item) { return item.id === user.id; });
        if (userSelectedArray) {
            var userSelected = userSelectedArray[0];
            var index = this.users.indexOf(userSelected);
            if (index > -1) {
                this.users[index]['selectedForRemoval'] = !this.users[index]['selectedForRemoval'];
                if (this.users[index]['selectedForRemoval']) {
                    this.usersToRemove.push(this.users[index]['id']);
                }
                else {
                    this.usersToRemove = this.usersToRemove.filter(function (item) { return item !== user.id; });
                }
            }
        }
    };
    UserListingComponent.prototype.toggleUserImport = function (user) {
        var userSelectedArray = this.allUsers.filter(function (item) { return item.id == user.id; });
        if (userSelectedArray) {
            var userSelected = userSelectedArray[0];
            var index = this.allUsers.indexOf(userSelected);
            if (index > -1) {
                this.allUsers[index]['selectedForImport'] = !this.allUsers[index]['selectedForImport'];
                if (this.allUsers[index]['selectedForImport']) {
                    this.importUsers.push({ uid: user.id, pid: user.user_type_id });
                }
                else {
                    this.importUsers = this.importUsers.filter(function (item) { return item.uid !== user.id; });
                }
            }
        }
    };
    UserListingComponent.prototype.removeSelectedUsers = function () {
        var _this = this;
        var selprj = this.elProject.nativeElement.value;
        if (selprj !== 0) {
            this.projectService.removeUsersFromProject(selprj, this.usersToRemove)
                .subscribe(function (response) {
                if (response.msg === 'success') {
                    var currentUsersArray = _this.users;
                    currentUsersArray = currentUsersArray.filter(function (el) { return !_this.usersToRemove.includes(el.id); });
                    _this.users = currentUsersArray;
                    _this.usersToRemove = [];
                    if (_this.removeUsersConfirmModal) {
                        _this.removeUsersConfirmModal.hide();
                    }
                    _this.commonService.showToast('success', 'users_removed_success', {});
                }
                else {
                    _this.commonService.showToast('error', 'error_removing_users', {});
                }
            });
        }
    };
    UserListingComponent.prototype.declineRemoval = function () {
        this.usersToRemove = [];
        this.removeAllUsers = false;
        this.users.map(function (item) {
            if (item.selectedForRemoval === true) {
                item.selectedForRemoval = undefined;
            }
        });
        if (this.removeUsersConfirmModal) {
            this.removeUsersConfirmModal.hide();
        }
    };
    UserListingComponent.prototype.declineDeactivation = function () {
        this.userSelectedForDeac = undefined;
        if (this.deacUserConfirmModal) {
            this.deacUserConfirmModal.hide();
        }
    };
    UserListingComponent.prototype.removeAllUsersFromProject = function () {
        this.removeAllUsers = !this.removeAllUsers;
        if (this.removeAllUsers) {
            for (var i = 0; i < this.users.length; i++) {
                this.users[i]['selectedForRemoval'] = true;
                this.usersToRemove.push(this.users[i].id);
            }
        }
        else {
            this.usersToRemove = [];
            for (var i = 0; i < this.users.length; i++) {
                this.users[i]['selectedForRemoval'] = undefined;
            }
        }
    };
    UserListingComponent.prototype.selectImportUsersRole = function (user, event) {
        var userToggled = this.importUsers.filter(function (item) { return item.uid === user.id; });
        if (userToggled && userToggled.length > 0) {
            userToggled = userToggled[0];
            var text_1 = event.target.selectedOptions[0].text;
            var roleSelectedId = this.roles.filter(function (item) { return item.name === text_1; })[0]['id'];
            var index = this.importUsers.indexOf(userToggled);
            if (index > -1) {
                this.importUsers[index]['pid'] = roleSelectedId;
            }
            else {
                this.commonService.showToast('error', 'reload_page', {});
            }
        }
    };
    UserListingComponent.prototype.scrollToTop = function () {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    return UserListingComponent;
}());
export { UserListingComponent };
