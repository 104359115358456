import { Observable, forkJoin, throwError, of } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {throwError as observableThrowError } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';

import { CommonService } from '../services/common.service';
import { LoginService } from '../services/login.service';
import { StorageService } from '../services/storage.service';

interface IReturn {
  roles: any[];
  products: any[];
  services: any[];
  countries: any[];
  status: any[];
  suppliers: any[];
  languages: any[];
  productType: any[];
  currency: any[];
  projectmanager: any[];
  rolesToMapToEcosystem: any[];
 // uom: any[];
}

@Injectable()
export class CommonResolver implements Resolve<IReturn> {

  userValue;

  constructor(private readonly loginService: LoginService, private readonly commonService: CommonService, private readonly storageService: StorageService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IReturn> {
 
    if (this.checkRole() < 4) {
      return  forkJoin ([
        // this.commonService.getRoles().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getProducts().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getServices().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getCountries().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getStatus().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getSuppliers().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getProductType().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getCurrencies().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getProjectManager().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getServiceComponents().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getUnits().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getUnitTypes().pipe(map((results) => results), catchError(e => of([]))),
        this.commonService.getRolesToMapToEcosystem().pipe(map((results) => results), catchError(e => of([]))),
      ]).pipe(
      map(results => ({
        // roles: results[0],
        products : results[0],
        services: results[1],
        countries: results[2],
        status: results[3],
        suppliers: results[4],
        productType: results[5],
        currency: results[6],
        projectmanager: results[7],
        serviceComponents: results[8],
        units: results[9],
        unittypes: results[10],
        rolesToMapToEcosystem: results[11]
      })),
     catchError(error => {
      return throwError(error);
      }),
    );
    }
  }

  checkRole(): any {
    const localTokenInfo = this.storageService.getLocalTokenInfo();
    if (localTokenInfo) {
      this.userValue = JSON.parse(localTokenInfo);
      return this.userValue.role.id;
    }
  }

}
