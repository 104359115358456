import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
// import * as Highcharts from 'highcharts';
// import { Project } from '../models/project';
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class HighchartReportsService {

  allCharts = [];
  highChartURL = this.appConfigService.getUrl(this.appConfigService.HIGHCHART);

  constructor(private http: HttpClient, private appConfigService: AppConfigService) {}

  createChart(el, cfg) {
    let newVal =  Highcharts.chart(el, cfg);
    let array = new Uint32Array(1);
    window.crypto.getRandomValues(array);                
    newVal.id = +array[0].toString().slice(2, 6);
    newVal.reportId = cfg.reportId;
    newVal.reportTypeFrontend = cfg.reportTypeFrontend;
    //newVal.series[0].remove(false);
    //console.log(" newVal.reportId ", newVal);
    this.allCharts.push(newVal);
  }

  getReports(currentData): Observable<any> {
    return this.http.post<any>(`${this.highChartURL}/getReportDataSetsWithUIDetails`, currentData);
  }

  getSectionSubsectionReportsSelectionListAPI(currentData): Observable<any> {
    return this.http.post<any>(`${this.highChartURL}/getSelectionParamsForDashboard`, currentData);
  }
  
  getDashboardsList(currentData): Observable<any> {
    return this.http.post<any>(`${this.highChartURL}/getDashboardListByUser`,currentData);
  }

  createDashboard(currentData):Observable<any>{
    return this.http.post<any>(`${this.highChartURL}/createNewDashboard`, currentData);
  }

  updateDashboard(currentData):Observable<any>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.put<any>(`${this.highChartURL}/updateDashboard`, currentData, options);
  }

  deleteDashboard(currentData):Observable<any>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: currentData,
    };
    
    return this.http.delete<any>(`${this.highChartURL}/deleteDashboard`, options);
  }

  getDashboardDetailsById(currentData):Observable<any>{
    return this.http.post<any>(`${this.highChartURL}/getDashBoardDetailsById`, currentData);
  }
  setDashboardAsDefault(currentData):Observable<any>{
    return this.http.post<any>(`${this.highChartURL}/setDashboardDefault`, currentData);
  }

  getKPIsReports(currentData):Observable<any>{
    return this.http.post<any>(`${this.highChartURL}/getKPIReports`, currentData);
  }

}
