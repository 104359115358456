import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
var RouteUserComponent = /** @class */ (function () {
    function RouteUserComponent(userService, router) {
        this.userService = userService;
        this.router = router;
        this.html = '<p>Hello</p>';
    }
    RouteUserComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, navItemsData, defaultNavItem;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.userService.getNavItemsForUserType().toPromise(),
                        ])];
                    case 1:
                        requests = _a.sent();
                        navItemsData = requests[0].data;
                        console.log('navItemsData', navItemsData);
                        defaultNavItem = navItemsData.filter(function (item) { return item.is_default == true; })[0];
                        console.log('defaultNavItem', defaultNavItem);
                        if (defaultNavItem) {
                            this.goToNew(defaultNavItem);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    RouteUserComponent.prototype.goToNew = function (navItem) {
        if (navItem.user_type_id == 2 || navItem.user_type_id == 3) {
            this.router.navigate(['/ecosystems']);
        }
        else {
            var nav = navItem['NavigationControl'];
            if (nav.click_action == 'router') {
                if (nav.angular_component === "/dashboard") {
                    this.router.navigate([nav.angular_component]);
                }
                else {
                    if (!nav.populate_dynamic_content) {
                        var url = nav.angular_component;
                        this.router.navigate([url]);
                    }
                    else {
                        var url = '/goto/' + nav.id;
                        this.router.navigate([url]);
                    }
                }
            }
        }
    };
    return RouteUserComponent;
}());
export { RouteUserComponent };
