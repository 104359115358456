<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToDashboards()" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)"><span i18n-title="@@projectReporting" title="Project Reporting">...</span></li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToDashboards()"><span title="Dashboards">...</span></li>
      <li class="breadcrumb-item active" aria-current="page"><span i18n='@@editDashboard'>Edit Dashboard</span></li>
    </ol>
  </nav>
</div>

<div class="content-wrap content-wrapper background margin-btm-20 min-height-calc" (window:resize)="onResize($event)">
  <div class="form-design form-box pb-0">
    <form name="editDashboardForm" #editDashboardForm="ngForm" novalidate (ngSubmit)="editDashboard(editDashboardForm)"
        enctype="multipart/form-data">
      <div class="row">
        <div style="padding-left: 1%;">
          <label class="group-title inline-block"><span i18n='@@editDashboard'>Edit Dashboard</span></label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-3 col-xs-12">
          <div class="form-group" [ngClass]="{ 'has-error': dashboardName.invalid && (dashboardName.dirty || dashboardName.touched || editDashboardForm.submitted)}">
            <label for="text-light"><span i18n="@@dashboardName">Dashboard Name </span><span class="mandatory"> *</span></label>
            <input type="text" class="form-control input-light" name="dashboardName" [(ngModel)]="dashboardEditObject.name" #dashboardName="ngModel" required/>
            <div class="error" *ngIf=" dashboardName.invalid && (dashboardName.dirty || dashboardName.touched || editDashboardForm.submitted)">
              <span i18n="@@enterValidInput">Enter valid input.</span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xs-12">
          <div class="form-group" [ngClass]="{ 'has-error': dashboardDescription.invalid && (dashboardDescription.dirty || dashboardDescription.touched || editDashboardForm.submitted)}">
            <label><span i18n="@@dashboardDescription">Dashboard Description </span><span class="mandatory"> *</span></label>
            <input type="text" name="dashboardDescription" class="form-control" #dashboardDescription="ngModel" [(ngModel)]="dashboardEditObject.description" required />
            <div class="error" *ngIf="dashboardDescription.invalid && (dashboardDescription.dirty || dashboardDescription.touched || editDashboardForm.submitted)">
              <span i18n="@@enterValidInput">Enter valid input.</span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-2 col-xs-12 d-flex align-items-center">
          <div class="form-check w-100 setAsDefault-option">
            <input type="checkbox" class="form-check-input" (change)="setAsDefaultDashboard()" name="setAsDefault" id="setAsDefault" [(ngModel)]="setAsDefault">
            <label class="form-check-label" for="checkbox_setasdefault" i18n="@@setAsDefault">Set as default</label>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xs-12 text-left datatable-header slidedownsearch">
          <label></label>
          <div class="form-group deletebtn text-right">
            <button type="button" name="button" class="btn btn-success btn-lg"  (click)="deleteDashboard()">
              <span i18n="@@delete">Delete</span>
            </button>
              <button type="button" name="button" class="btn btn-success mr-3" (click)="showOptionPopup()">
                <span  i18n="@@addDashboardElements">Add Dashboard Elements</span>
              </button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="layout.length > 0">
        <div class="col-lg-12 form-group fltRgt datatable-header" style="padding-right:10px">
          <div class="countOfFarmers">
            <span *ngIf="countOfFarmers?.total_farmers != null">({{countOfFarmers?.total_farmers}} <span> <span i18n="@@farmers">Farmers</span>)</span></span>
            <span *ngIf="countOfFarmers?.total_farmers == null">(0 <span i18n="@@farmers">Farmers</span>)</span>
          </div>
          <button type="button" name="button" class="btn btn-success btn-lg ml-3" (click)="clearAllCharts()">
            <span i18n="@@clearAll">Clear All</span>
          </button>
        </div>
      </div>
      <div class="">
        <!-- <div class="row mt-4 mb-4">
          <div class="col-lg-6 col-xs-6">
            <div class="form-check form-check-inline">
              <input id="predefined" type="radio" class="form-check-input cursorPointer" value="predefined" name="reportTypes"  [(ngModel)]="reportTypes" (click)="reportTypeChange('predefined')">
                <label class="form-check-label" for="predefined"><span class="cp">Profilling Reports </span></label>
            </div>

            <div class="form-check form-check-inline">
              <input id="self" type="radio" class="form-check-input cursorPointer" value="self"  name="reportTypes" [(ngModel)]="reportTypes" (click)="reportTypeChange('self')">
                <label class="form-check-label" for="self"><span class="cp">Custom Reports</span></label>
            </div>
          </div>
        </div> -->
        <!-- <app-profilling-reports *ngIf="reports_radio == 'profiling'"></app-profilling-reports>
        <app-custom-reports *ngIf="reports_radio == 'custom'"></app-custom-reports> -->

        <div class="reports-sec">

          <div [style.height.px]="height" class="grid-content">
            <gridster [options]="options" id="gridsterBase" style="background-color:#fff; border: 1px solid #cdcdcd;">
              <gridster-item *ngFor="let item of layout"
                [item]="item" style="border:1px solid #cdcdcd; border-radius:4px;"

              >
              <div class="delete-dashboard">
                <a title="Remove widget" (mousedown)="DashboardAddService.removeItem(item)"
                (touchstart)="DashboardAddService.removeItem(item)"><i class="utilicon utilicon-trash-alt-light"></i></a>
              </div>
              <div>
                <div *ngIf="item.componentName == 'KPI'">
                  <div *ngIf="item.src.is_multi else singleKPI">
                    <div class="padding15-20">
                      <div class="row no-gutters">
                        <div class="col pos-r" *ngFor="let item2 of item.src.series; let i = index;">
                          <span *ngIf="i < item.src.series.length - 1" class="right-separator"></span>
                          <div class="align-center content-center" style="padding-top:13px">
                            <div class="inline-block text-center">
                              <img [src]="item2?.kpi_image != '' && item2?.kpi_image != null ? item2?.kpi_image : 'assets/online_images/default.svg'" alt="{{item2?.name}}"  style="max-height:48px; float:left"/>
                            </div>
                            <div class="inline-block text-center pl-3">
                              <span class="fnt-size-common-number">{{item2?.value == 'Less than 30 minutes' ? '	&lt; 30 minutes' : item2?.value == 'More than 1 hour' ? '&gt; 1 hour' : item2?.value}}</span><br/>
                              <span class="weight-400">{{item2?.name}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="align-center font-20 pb-2 pt-2 flex-center content-center">{{item.src?.name}}</div>
                    </div>
                  </div>
                  <ng-template #singleKPI>
                    <div class="padding15-20">
                      <div class="row flex-center no-gutters">
                        <div class="col-md-12 col-lg-4 align-center content-center">
                            <img [src]="item.src?.kpi_image != '' && item.src?.kpi_image != null ? item.src?.kpi_image : 'assets/online_images/default.svg'" alt="{{item.src?.name}}" style="max-height:67px" />
                        </div>
                        <div class="col-md-12 col-lg-8">
                          <div class="row no-gutters">
                            <div class="col-md-12 col-lg-12 flex-center content-center pb-3 pt-3">
                              <span class="fnt-size-common-number">{{item.src?.value == 'Less than 30 minutes' ? '	&lt; 30 minutes' : item.src?.value == 'More than 1 hour' ? '&gt; 1 hour' : item.src?.value}}</span>
                            </div>
                          </div>
                          <div class="row no-gutters">
                            <div class="col-md-12 col-lg-12 flex-center content-center pr-2 pl-2">
                              <div class="align-center font-20 flex-center height-48">{{item.src?.name}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div *ngIf="item.componentName == 'Chart'" class="highcharts-no-border" style="width:100%; height:300px">
                  <highcharts-chart
                    [ngStyle]="{'width':item.cols > 1 ? (chartWidth * item.cols) + 10 +'px' : chartWidth * item.cols +'px','height':'300px','display':'block'}"
                    [Highcharts]="Highcharts"
                    [constructorType]="'chart'"
                    [options]="item.src"
                    style="width: 100%; height: 300px; display: block;"
                  ></highcharts-chart>

                </div>

               </div>
               <!-- <app-live-update-feature *ngIf="item.map" style='height: 100%; width: 100%;display:block; object-fit: cover'></app-live-update-feature> -->
              </gridster-item>

            </gridster>
        </div>
        <div *ngIf="layout.length > 0" class="pb-2">
          <span class="gray weight-400" style="font-size: 0.7rem;">* N/D = No Data</span>
        </div>


        </div>

      </div>

      <div class="form-group text-center">
        <button type="submit" name="button" class="btn btn-primary btn-lg m-2" [disabled]="editDashboardForm.invalid"><span i18n="@@update">Update</span></button>
        <button type="button" name="button" class="btn btn-default-gray btn-lg m-2" (click)="cancel(editDashboardForm)"><span i18n="@@cancel">Cancel</span></button>
      </div>

    </form>
    <ng-template #confirmTemplate>
      <div class=" modal-body text-center ">
        <h3 i18n="@@haveAnswerWhichHavnotSaveDoYouWannaExit">
          Your changes have not been saved. Are you sure you want to exit?
        </h3>
        <button type="button " class="btn btn-secondary mr-1 " (click)="closeModal(true)" i18n="@@yes" i18n-title="@@yes">Yes</button>
        <button type="button " class="btn btn-primary " (click)="closeModal(false)" i18n="@@no" i18n-title="@@no">No</button>
      </div>
    </ng-template>
    <ng-template #selectOptions>
      <div class="modal-header">
        <h4 class="modal-title pull-left" i18n="@@seelectElementsForDashboard">Select Elements for Dashboard</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closePopUp()">
        <i class="utilicon utilicon-times-light"></i>
        </button>
      </div>
      <div class=" modal-body text-center ">
          <form name="addElementsForm" #addElementsForm="ngForm" novalidate (ngSubmit)="loadDashboard(addElementsForm)"
          enctype="multipart/form-data">
            <div>
              <!-- <label for="selectKPI">Select KPI</label>
              <select class="form-control" name="selectKPI" id="selectKPI" ngModel>
                <option value="0">Select</option>
                <option value="1">One</option>
              </select> -->
              <div class="row mt-4 mb-4">
                <div class="col-lg-12 col-xs-12">
                  <div class="form-check form-check-inline">
                    <input id="KPI" type="radio" class="form-check-input cursorPointer" value="KPI" name="reportType"  [(ngModel)]="reportTypes" (click)="reportTypeChange('KPI')">
                    <label class="form-check-label" for="KPI"><span class="cp" i18n="@@kpis">KPI</span></label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="Chart" type="radio" class="form-check-input cursorPointer" value="Chart" name="reportType" [(ngModel)]="reportTypes" (click)="reportTypeChange('Chart')">
                      <label class="form-check-label" for="Chart"><span class="cp" i18n="@@charts">Chart</span></label>
                  </div>
                </div>
              </div>
              <div class="row mrzero mr-tp-1" *ngIf="reportTypes == 'Chart'">
                <div class="col-md-4 col-lg-4 col-xs-12">
                  <div class="form-group no-margin-hr text-left" [ngClass]="{ 'has-error': section_name.value == 0 && (section_name.dirty || section_name.touched) }">
                    <label><span i18n="@@sectionName">Section Name </span> <span class="mandatory"> *</span></label>
                    <select class="form-control" (change)="selectSection($event,section_name)" [(ngModel)]="dashboardEditObject.section" name="section_name"  #section_name="ngModel" [ngClass]="(dashboardEditObject.section == 0 ? 'ng-invalid':'ng-valid')">
                      <option value="0" class="option" i18n="@@select">Select</option>
                      <option *ngFor="let list of listsSectionsSubSection; trackBy: trackBySectionName" [ngValue]="list.section">{{list.section}}</option>
                    </select>
                    <div class="error" *ngIf="section_name.value == 0 && (section_name.dirty || section_name.touched)">
                      <span i18n="@@enterValidInput">Enter valid input.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xs-12">
                  <div class="form-group no-margin-hr text-left">
                    <label i18n="@@subSectionName">Sub section Name </label>
                    <select class="form-control" (change)="selectSubSection($event)" [disabled]="subsectionFlag" [(ngModel)]="dashboardEditObject.subsection" name="sub_section_name" #sub_section_name="ngModel" >
                      <option value="" class="option" i18n="@@select">Select</option>
                      <option *ngFor="let list of newSubSection; trackBy: trackBySubsectionName" [ngValue]="list.name">{{list.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xs-12">
                  <div class="form-group no-margin-hr text-left reportsdropdown">
                    <label><span i18n="@@reportName">Report Name </span><span class="mandatory"> *</span></label>
                    <ng-multiselect-dropdown
                    [placeholder]="selectPlaceholder"
                    [data]="reportsListData"
                    [(ngModel)]="selectedReportsMultiSelect"
                    [settings]="dropdownSettings"
                    [ngModelOptions]="{standalone: true}"
                    (onSelectAll)="onSelectAllChart($event)"
                    #reportNames="ngModel" name="reportNames" onchange="this.setAttribute('required','')" [ngClass]="(selectedReportsMultiSelect.length <= 0 ? 'ng-invalid':'ng-valid')" [disabled]="subsectionFlag"
                    >
                    </ng-multiselect-dropdown>

                  </div>
                </div>
                <div class="col-md-12 col-lg-12 col-xs-12 form-group mt-4">
                  <button type="button" name="button" class="btn btn-primary mt-0 btn-lg mr-2" (click)="genarateCharts(reportTypes)" [disabled]="disableChartsButton">
                    <span i18n="@@add">Add</span>
                  </button>

                  <button type="button" name="button" class="btn btn-secondary mt-0 btn-lg " (click)="closePopUp()">
                    <span i18n="@@close">Close</span>
                  </button>
                </div>
              </div>
              <div class="row mrzero mr-tp-1" *ngIf="reportTypes == 'KPI'">
                <div class="col-md-4 col-lg-4 col-xs-12">
                  <div class="form-group no-margin-hr text-left" [ngClass]="{ 'has-error': section_name.value == 0 && (section_name.dirty || section_name.touched) }">
                    <label><span i18n="@@sectionName">Section Name </span> <span class="mandatory"> *</span></label>
                    <select class="form-control" (change)="selectKPISection($event,section_name)" [(ngModel)]="KPIAddObject.section" name="section_name"  #section_name="ngModel" [ngClass]="(KPIAddObject.section == 0 ? 'ng-invalid':'ng-valid')">
                      <option value="0" class="option" i18n="@@select">Select</option>
                      <option *ngFor="let list of listsKPISection; trackBy: trackBySectionName" [ngValue]="list">{{list}}</option>
                    </select>
                    <div class="error" *ngIf="section_name.value == 0 && (section_name.dirty || section_name.touched)">
                      <span i18n="@@enterValidInput">Enter valid input.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xs-12">
                  <div class="form-group no-margin-hr text-left">
                    <label style="color:#c1c1c1" i18n="@@subSectionName">Sub section Name </label>
                    <select class="form-control" disabled>
                      <option value="0" class="option" i18n="@@select">Select</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xs-12">
                  <div class="form-group no-margin-hr text-left reportsdropdown">
                    <label><span i18n="@@kpiName">KPI Name </span><span class="mandatory"> *</span></label>
                    <ng-multiselect-dropdown
                    [placeholder]="selectPlaceholder"
                    [data]="kpisListData"
                    [(ngModel)]="selectedKPIsMultiSelect"
                    [settings]="dropdownSettings"
                    [ngModelOptions]="{standalone: true}"
                    (onSelectAll)="onSelectAllKPI($event)"
                    #reportNames="ngModel" name="reportNames" onchange="this.setAttribute('required','')" [ngClass]="(selectedKPIsMultiSelect.length <= 0 ? 'ng-invalid':'ng-valid')" [disabled]="kpiFlag"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 col-xs-12 form-group mt-4">
                  <button type="submit" name="button" class="btn btn-primary btn-lg mt-0 mr-2" [disabled]="addElementsForm.invalid || disableKPIButton"><span i18n="@@add">Add</span></button>
                  <button type="button" name="button" class="btn btn-secondary btn-lg mt-0" (click)="closePopUp()">
                    <span i18n="@@close">Close</span>
                  </button>
                </div>

              </div>
            </div>
          </form>
      </div>
    </ng-template>
  </div>
</div>
