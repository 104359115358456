// TODO: Done: Build questionnaire from blank slate
// TODO: Done: Bug in option reordering
import { Component, OnInit, TemplateRef, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// Services
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import {ChangeDetectorRef} from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-preview-template',
  templateUrl: './preview-template.component.html',
  styleUrls: ['./preview-template.component.css']
})
export class PreviewTemplateComponent implements OnInit {

  questionnaire;
  currentTab;
  currentTabIndex;
  currentSection;
  currentSectionIndex;
  reloadingQuestionnaire = true;
  template;
  templateId;
  surveyId;
  survey;
  project;
  projectId;
  useTemplateConfirmModal;
  userLanguages = [];
  isTemplate = false;
  showHideBackButton = true;
  showHideNextButton = false;
  currentLocaleLanguageIdIndex;
  loadingTextValue = 'Please wait';
  constructor(
    private dynamicQuestionnaireService: DynamicQuestionnaireService,
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private projectService: ProjectService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private readonly spinner: Ng4LoadingSpinnerService,
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly translate: TranslateService,
  ) {
      if (this.locale && this.locale.length > 2) {
        this.locale = this.locale.substr(0, 2);
      }
      this.translate.get('please_wait').subscribe(result => {
        this.loadingTextValue = result;
      });
   }

  ngOnInit() {
    this.spinner.show();
    this.route.paramMap.subscribe(params => {
      this.route.queryParamMap.subscribe(async queryParams => {
        this.projectId = params.get('projectId');
        this.surveyId = params.get('surveyId');
        this.templateId = params.get('templateId');
        if(this.templateId >= 0){
          this.isTemplate = true;
          const request = await Promise.all([
            this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
            this.surveyService.getTemplateQuestionnaire(this.templateId).toPromise(),
            this.surveyService.getTemplateMetadata(this.templateId).toPromise(),
            this.surveyService.getSurvey(this.surveyId).toPromise(),
            this.projectService.getProjectProfilingData(this.projectId).toPromise(),
            this.surveyService.getSurveyLanguages().toPromise()
          ]);

          if (request[0]['msg'] != 'success' || request[4]['msg'] != 'success') {
            this.commonService.showToast('error', 'generic_error', {});
          } else {
            this.project = {...request[0].data[0], ...request[4].data[0]};
          }
          
          if (request[5]['message']) {
            this.userLanguages = request[5]['message'];
          }

          if (request[1]['message']) {
            if (request[1]['message'].length > 0) {
              this.questionnaire = request[1]['message'];
            }
            this.initialize(0, 0);
            this.reloadingQuestionnaire = false;
            const x = this.questionnaire[0]['survey_q_asset_labels'].find(item => item.language_id == this.getLanguageId());
            // currentLocaleLanguageIdIndex stores the index of the current locale's language ID in the questionnaire's asset's survey_q_asst_labels array
            // currentLocaleLanguageIdIndex is used in the html file to show / add the survey_q_asset_label for the current locale
            this.currentLocaleLanguageIdIndex = this.questionnaire[0]['survey_q_asset_labels'].indexOf(x);
            this.spinner.hide();
          }

          if (request[2]['message']) {
            this.template = request[2]['message'];
          }

          if (request[3]['message']) {
            this.survey = request[3]['message'];
          }
        } else {
          this.isTemplate = false;
          const request = await Promise.all([
            this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
            this.projectService.getProjectProfilingData(this.projectId).toPromise(),
            this.surveyService.getSurvey(this.surveyId).toPromise(),
            this.surveyService.getSurveyQuestionnaire(this.surveyId).toPromise(),
            this.surveyService.getAssociatedTemplates(this.surveyId).toPromise(),
            this.surveyService.getSurveyLanguages().toPromise()
          ]);
          if (request[0]['msg'] != 'success' || request[1]['msg'] != 'success') {
            this.commonService.showToast('error', 'generic_error', {});
          } else {
            this.project = {...request[0].data[0], ...request[1].data[0]};
          }
  
          if (request[2]['message']) {
            this.survey = request[2]['message'];
          }
  
          if (request[5]['message']) {
            this.userLanguages = request[5]['message'];
          }
  
          if (request[3]['message']) {
            if (request[3]['message'].length > 0) {
              this.questionnaire = request[3]['message'];
              this.spinner.hide();
            }
            this.initialize(0, 0);
            this.reloadingQuestionnaire = false;
            const x = this.questionnaire[0]['survey_q_asset_labels'].find(item => item.language_id == this.getLanguageId());
            // currentLocaleLanguageIdIndex stores the index of the current locale's language ID in the questionnaire's asset's survey_q_asst_labels array
            // currentLocaleLanguageIdIndex is used in the html file to show / add the survey_q_asset_label for the current locale
            this.currentLocaleLanguageIdIndex = this.questionnaire[0]['survey_q_asset_labels'].indexOf(x);
          }
        }
      })
    })
  }

  tabClicked(tabIndex, sectionIndex) : void {
    delete this.currentTab;
    this.currentTab = JSON.parse(JSON.stringify(this.questionnaire[tabIndex]));
    this.currentTab.sections = this.currentTab.sections.filter(item => !item.removed_from_questionnaire);
    this.currentTabIndex = tabIndex;
    this.currentSectionIndex = sectionIndex;
    this.sectionClicked(this.currentSectionIndex);
    setTimeout(() => {
      document.querySelector('ul.sub-tab-fixed').scrollLeft -= 1500;
    }, 300);
  }

  // To select last section on click of prev button
  tabClickedSectionLast(tabIndex) : void {
    this.currentTab = this.questionnaire[tabIndex];
    this.currentTab.sections = this.currentTab.sections.filter(item => !item.removed_from_questionnaire);
    this.currentTabIndex = tabIndex;
    this.currentSectionIndex = this.currentTab.sections.length - 1;
    this.sectionClicked(this.currentSectionIndex);
    setTimeout(() => {
      document.querySelector('ul.sub-tab-fixed').scrollLeft += 1500;
    }, 300);
  }

  sectionClicked(sectionIndex) : void {
    if (this.currentTab) {
      delete this.currentSection;
      if (this.currentTab.sections && this.currentTab.sections.length > 0) {
        this.currentSection = JSON.parse(JSON.stringify(this.currentTab.sections[sectionIndex]));
        this.currentSectionIndex = sectionIndex;
        this.addUOM(this.currentSection);
        this.ref.detectChanges();
        this.reloadingQuestionnaire = false;
      } else {
        this.ref.detectChanges();
        this.reloadingQuestionnaire = false;
      }
    } else {
      this.ref.detectChanges();
      this.reloadingQuestionnaire = false;
    }
    this.disableButton(sectionIndex)
  }

  initialize(tabIndex, sectionIndex): void {
    this.filterSnapshotObject();
    this.currentTabIndex = tabIndex;
    this.tabClicked(this.currentTabIndex, sectionIndex);
  }
  filterSnapshotObject(){
    this.questionnaire = this.questionnaire.filter(item => !item.removed_from_questionnaire);
  }
  addUOM(section){
    let questions = section.questions;
    let subsections = section.subsections;
    let tables = section.tables;
    if(questions && questions.length > 0){
      for (let i = 0; i < questions.length; i++) {
        if (questions[i].uom_questions && questions[i].uom_questions.length > 0) {
          for (let j = 0; j < questions[i].uom_questions.length; j++) {
            // Set the options dropdown for this uom_question
            questions[i].uom_questions[j].options = this.project.ProjectUOM.filter(item => item.unit_type_id == questions[i].uom_questions[j].qa_subtype);            
          }
        }
      }
    }  
    if(subsections && subsections.length > 0){
      for (let i = 0; i < subsections.length; i++) {
        let subquestions = subsections[i].questions;
        if(subquestions && subquestions.length > 0){
          for (let i = 0; i < subquestions.length; i++) {
            if (subquestions[i].uom_questions && subquestions[i].uom_questions.length > 0) {
              for (let j = 0; j < subquestions[i].uom_questions.length; j++) {
                subquestions[i].uom_questions[j].options = this.project.ProjectUOM.filter(item => item.unit_type_id == subquestions[i].uom_questions[j].qa_subtype);            
              }
            }
          }
        }
      }
    }
    if(tables && tables.length > 0){
      for (let i = 0; i < tables.length; i++) {
        let subquestions = tables[i].questions;
        if(subquestions && subquestions.length > 0){
          for (let i = 0; i < subquestions.length; i++) {
            if (subquestions[i].uom_questions && subquestions[i].uom_questions.length > 0) {
              for (let j = 0; j < subquestions[i].uom_questions.length; j++) {
                subquestions[i].uom_questions[j].options = this.project.ProjectUOM.filter(item => item.unit_type_id == subquestions[i].uom_questions[j].qa_subtype);            
              }
            }
          }
        }
      }
    }
  }
  // Get the languageId of the current locale
  getLanguageId() {
    if (this.userLanguages && this.userLanguages.length > 0) {
      return this.userLanguages.find(item => item.locale == this.locale)['id'];
    } else {
      return undefined;
    }
  }

  disableButton(newSectionIndex){
    if (newSectionIndex <= 0 && this.currentTabIndex <= 0){
      this.showHideBackButton = true;
    } else {
      this.showHideBackButton = false;
    }
    if(newSectionIndex == this.currentTab.sections.length - 1 && this.currentTabIndex == this.questionnaire.length - 1 ){
      this.showHideNextButton = true;
    } else {
      this.showHideNextButton = false;
    }
  }

  navigateSection(direction): void {
    // Find the section index to navigate to
    const newSectionIndex = this.currentSectionIndex + direction;
    const curActiveSection = <HTMLElement> document.querySelector('ul.sub-tab-fixed li a.active');
    this.disableButton(newSectionIndex);
    // If < 0, that indicates you need to move to the previous tab if the tab's index > 0
    if (newSectionIndex < 0) {
      const newTabIndex = this.currentTabIndex + direction;
      if (newTabIndex >= 0) {
        this.tabClickedSectionLast(newTabIndex);
      }
    }
    // If > 0 and less than the number of sections in the current tab, move to the clicked section's index
    else if (newSectionIndex >= 0 && newSectionIndex < this.currentTab.sections.length) {
      this.sectionClicked(newSectionIndex);
      if(direction > 0) {
        const nextActiveSection = <HTMLElement> curActiveSection.parentElement.nextElementSibling;
        document.querySelector('ul.sub-tab-fixed').scrollLeft = nextActiveSection.offsetLeft;
      } else {
        const nextActiveSection = <HTMLElement> curActiveSection.parentElement.previousElementSibling;
        document.querySelector('ul.sub-tab-fixed').scrollLeft = nextActiveSection.offsetLeft;
      }
    }
    // In other conditions, navigate to the next tab (e.g. you click next in the last section of any tab, except the last tab)
    else {
      const newTabIndex = this.currentTabIndex + direction;
      if (newTabIndex < this.questionnaire.length && newTabIndex > 0) {
        this.tabClicked(newTabIndex, 0);
      }
    }
  }

  useTemplateConfirmation(template): void {
    this.useTemplateConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  closeUseTemplateConfirmationModal(): void {
    if (this.useTemplateConfirmModal) {
      this.useTemplateConfirmModal.hide();
    }
  }
  async useTemplate() {
    this.spinner.show();
    const requests = await Promise.all([this.surveyService.createQuestionnaireForSurvey(this.surveyId, this.templateId, {}).toPromise()]);
    if (requests[0].code == 'success') {
      this.closeUseTemplateConfirmationModal();
      this.toastr.success('Questionnaire created in languages: ' + requests[0].message);
      this.spinner.hide();
      this.router.navigate(['questionnaire/edit/' + this.projectId + '/' + this.surveyId]);  
    } else {
      this.spinner.hide();
    }
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  //Missing functions added by Kiran
  leftScroll() {
    document.querySelector('ul.sub-tab-fixed').scrollLeft -= 500;
  }

  rightScroll() {
    document.querySelector('ul.sub-tab-fixed').scrollLeft += 500;
  }

}
