import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class AppConfigService {

  constructor() { }

  PROJECT = '/api/project';
  USER = '/api/user';
  PRODUCTS = '/api/common/products';
  SERVICES = '/api/common/services';
  SERVICECOMPONENTS = '/api/common/serviceComponents'
  COUNTRIES = '/api/common/countries';
  CURRENCY = '/api/common/currencies';
  STATUS = '/api/common/status';
  SUPPLIERS = '/api/common/supplier';
  SUPPLIERS_PRODUCTS_PROJECT_SETUP = '/api/common/suppliersProductsProjectSetup';
  PRODUCTTYPE = '/api/common/producttype';
  MANAGER = '/api/common/manager';
  QUESTIONS = '/api/questions';
  FARMER = '/api/farmer';
  LANGUAGES = '/api/common/languages';
  CHART = '/api/chart';
  TWOFACTORAUTH = '/twofactorauth';
  FORGETPASSWORD = '/forgetpassword';
  EMAIL = '/api/common/email';
  ROLES = '/api/common/roles';
  ROLES_MAP_ECOSYSTEM = '/api/common/rolesToMapToEcosystem';
  PROFILE = '/api/profile';
  BLANK = '/api/common/blank';
  REVISION = '/api/revision';
  Supplier = '/api/mdmSupp';
  MDMDATA = '/api/mdmData';
  MDMMASTER = '/api/mdmMaster';
  MDM = '/api/mdm';
  UNITS = '/api/common/units';
  UNITTYPES = '/api/common/unittypes';
  RESETUSERNAMEPASSWORD = '/api/common/updateCreds';
  CONTACTSUPPORT = '/api/contactsupport';
  ECOSYSTEMSETTINGS= '/api/mdmData/ecosystem_settings';
  ECOSYSTEMRECORD ='/api/mdmData/getecosystem_settings_record';
  API_MANAGEMENT = '/api/apimanagement';
  HIGHCHART = '/api/hcreport';
  SURVEY = '/api/survey';
  SURVEY_NQ = '/api/nq';
  COMPARE = '/api/nq';
  CAMPAIGN = '/api/campaign';
  TRAINING = '/api/trainingcertification';  
  TRAINING_CERTIFICATION = '/api/common/training_cert_name';
  VISIT = '/api/visit';
  VISIT_TYPES = '/api/common/getMultivalueMasters';
  DATACONSENT = '/api/data-consent';
  CONSREPORT = '/api/consReport';

  getUrl(name): string {
    return environment.apiBaseUrl + name;
  }

}
