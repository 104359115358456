<div style="height: calc(100vh - 0px); background-color: #30365a;" *ngIf="SWInstallation != 'activated'; else loginPage">
  <div class="text-center" style="padding-top: 20px;">
    <img src="assets/images/path2farm-logo-hor-white.webp" style="width:150px" alt="path2farm logo"/>
  </div>
  <div style="margin:auto; width:50%; margin-top:calc(50vh - 205px)">
    <div class="text-center">
      <img src="assets/images/Gear-loader.gif" alt="Loader"/>
    </div>
    <div class="text-center pt-4">
      <span class="semi-bold font-1-1 white">Initializing P2F-Roots application</span>
    </div>
  </div>
</div>
<ng-template #loginPage>
  <div class="login-right">
    <div class="login-p2froots-logo text-right">
      <img class="login-p2froots-img" src="assets/images/p2f-roots-logo.webp" alt="p2f roots logo"/>
      <img class="login-p2froots-img-small" src="assets/images/p2f-roots-logo-white.webp" alt="p2f roots logo"/>
    </div>
    <div class="login-p2froots-logo text-right mt-0 mb-0">
      <div class="language-changes" style="width: 182px">
        <!-- <span style="color:#2b2b2b;display: inline-block;margin-right: 8px;"> <i class="fa fa-globe" aria-hidden="true"></i> </span> -->
        <!-- <span class="country-flag" [ngClass]="flagClass + '-flag'" ></span>
        <select required class="form-control language-select new-lang-select" [(ngModel)]="selectedLocale" (change)="updateLocale()"
        aria-label = "Select locale">
          <option *ngFor="let locale of availableLocales" [ngValue]="locale.code">{{locale.label}}</option>
        </select> -->
        <div class="dropdown">
          <ng-container *ngFor="let locale of availableLocales">
            <a *ngIf="flagClass == locale.code" class="language-dropdown-button dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width:162px">
              <span class="country-flag" [ngClass]="locale.code + '-flag'" ></span>
              <span class="country-text" style="width:117px">{{locale.label}}</span>
            </a>
          </ng-container>
          <div class="dropdown-menu language-dropdown-menu" aria-labelledby="dropdownMenuLink" style="width:182px">
            <a *ngFor="let locale of availableLocales" (click)="setLanguage(locale.code)" class="dropdown-item" href="javascript:void(0)">
              <span class="country-flag" [ngClass]="locale.code + '-flag'" ></span>
              <span>{{locale.label}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"> </div>
    <div class="login-screen login" id="login">
      <div class="login-info login-form">
        <!-- <h3 class="login-header text-center" i18n="welcome|Welcome text@@welcomeText">
          Welcome1
        </h3>
        <p i18n="@@enterCredentialsToGetStarted">Please enter your credentials to get started in P2F-Roots</p> -->
        <div class="sever-errors" *ngIf="loginErrorMsg.length > 0">
          <div class="error">{{loginErrorMsg}}</div>
        </div>
        <form name="loginForm" (ngSubmit)="f.form.valid && doLogin()" #f="ngForm" autocomplete="off" class="bing">
          <div class="form-group">
            <label for="username" i18n="@@userId">User ID</label>
            <input class="form-control-login" type="text" name="username" #username="ngModel" [(ngModel)]="model.username" id="username"
              required minlength="6" maxlength="10" [value]="username.value?.toUpperCase()" i18n-placeholder="@@usernamePlaceholder"
              autofocus (focus)="onFocusCountry($event)" (blur)="onBlurCountry($event)">
            <span class="utilicon username"></span>
          </div>
          <div class="form-group">
            <label for="pwd" i18n="@@password">Password</label>
            <div class="pos-r">
              <input class="form-control-login" id="pwd" [type]="showPass ? 'text' : 'password'" value="" name="pwd" #pwd="ngModel" [(ngModel)]="model.password"
                required maxlength="20" i18n-placeholder="@@password" (focus)="onFocusCountry($event)" (blur)="onBlurCountry($event)">
              <span class="fa password"></span>
              <i class="utilicon utilicon-eye-light show-pass" title="Show Password" i18n-title="@@showPassword" *ngIf="!showPass" (click)="showPass = !showPass"></i>
              <i class="utilicon utilicon-eye-slash-light show-pass" title="Hide Password" i18n-title="@@hidePassword" *ngIf="showPass" (click)="showPass = !showPass"></i>
            </div>
          </div>
          <div class="clearfix"></div>        
          <span class="fltLft text-danger" name="error" *ngIf="!isValid"><strong i18n="@@invalidCredentialsMsg">Login to P2F-Roots is not allowed when offline.</strong></span>
          <button aria-label = "Login" type="submit" class="btn-login-blue" title="Login" i18n-title="@@login" id="submit" [ngClass]="{ 'disabled': !username.valid || !pwd.valid }" 
            i18n="@@login">
            LOGIN
          </button>
          <div class="reset-username">
          <a class="btn btn-link pl-0" id="forgotPwd" routerLink="/resetuserid" (click)="setCookies()" i18n="@@forgotUserId" i18n-title="@@forgotUserId">
            Forgot User ID?
          </a>
          <a class="btn btn-link pr-0" id="forgotPwd" routerLink="/forgetpassword" (click)="setCookies()" i18n="forgotPwd|forgotPwd@@forgotPwdLink">
            Forgot Password?
          </a>
          </div>
          <div class="text-left alert alert-warning mt-2" *ngIf="capslockOn" i18n="@@capsLockIsOn">Caps Lock is on</div>
        </form>
      </div>
    </div>

  </div>

  <div class="bg">
    <!-- <div class="welcome-deeproots">
      <h2><span i18n="@@welcomeTo" class="spl-bg-fontsize">Welcome to </span> <img src="assets/images/eroots-logo.svg" alt="eroots logo"> </h2>
      <div *ngIf="flagEn" class="dp-initiative"> <span i18n="@@aWord">A</span> Naturals+ <span i18n="@@initiative">Initiative</span> </div>
      <div *ngIf="flagEs" class="dp-initiative"> <span i18n="@@aWord">A</span> Naturals+ <span i18n="@@initiative">Initiative</span> </div>
      <div *ngIf="flagFr" class="dp-initiative"> <span i18n="@@aWord">A</span> <span i18n="@@initiative">Initiative</span> Naturals+ </div>
      <div *ngIf="flagZh" class="dp-initiative"> <span i18n="@@aWord">A</span> Naturals+ <span i18n="@@initiative">Initiative</span> </div>
      <div *ngIf="flagMg" class="dp-initiative"> <span i18n="@@aWord">A</span> Naturals+ <span i18n="@@initiative">Initiative</span> </div>
      <div *ngIf="flagTa" class="dp-initiative"> <span i18n="@@aWord">A</span> Naturals+ <span i18n="@@initiative">Initiative</span> </div>
    </div> -->
    <div class="login-p2f-logo">
      <img src="assets/images/path2farm-logo-hor-white.webp" alt="path2farm logo"/>
    </div>
    <div class="login-fir-logo login-p2froots-img">
      <img src="assets/images/fir-logo.webp" alt="firmenich logo"/>
    </div>
  </div>

    <div class="clearfix"></div>

    
  <div class="cookie-banner" id="cookieBanner" *ngIf="checkConsent">
    <span>This website intends to use cookies to improve the site and your experience. By continuing to browse the site you are agreeing to accept our use of cookies. If you require further information and/or do not wish to have cookies placed when using the site, visit the </span><a target="_blank" rel="noopener" href="https://p2froots.firmenich.com/en/assets/media/Firmenich_Cookies_Policy_p2froots.firmenich.com.pdf">Cookie policy page.</a>
    <a class="cookie-banner-close cursorPointer" (click)="dismiss()"><i class="utilicon utilicon-times-light"></i></a>
  </div>
</ng-template>

