import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Project } from '../models/project';
import { AppConfigService } from './app-config.service';



@Injectable()
export class ProjectService {

  projectURL = this.appConfigService.getUrl(this.appConfigService.PROJECT);

  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) { }

  GetProject(id: number): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getProjectById/${id}`);
  }

  GetProjectsDataDashboard(): Observable<any> {
    return this.http.get<any>(this.projectURL + '/getUsersProjects');
  }

  GetProjects(): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getUsersProjects`);
  }

  getEcosystems(): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getUsersEcosystems`);
  }

  GetAllProjects(): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getallprojects`);
  }

  SaveProject(project: Project): Observable<any> {
    return project.id > 0 ? this.http.put(`${this.projectURL}/updateProject/${project.id}`, project) : this.http.post(this.projectURL + '/saveProject', project);
  }

  Upload(formData: any): Observable<any> {
    return this.http.post<any>(`${this.projectURL}/uploadProjectImage`, formData);
  }

  // THIS IS THE SAME ENDPOINT AS GetProject. - scrub this when bandwidth is available
  GetProjectById(id: number): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getProjectById/${id}`);
  }

  removeUsersFromProject(id: number, userArray: any): Observable<any> {
    return this.http.post<any>(`${this.projectURL}/removeUsers/${id}`, userArray);
  }

  getSnapshot(id: number): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/projectWithSnapshot/${id}`);
  }

  updateSnapshot(snapshot): Observable<any> {
    return this.http.put(`${this.projectURL}/updateSnapshot`, { snapshot: snapshot });
  }

  exportFarmersbyProjectId(id: number, selFarmers): Observable<any> {
    return this.http.post<any>(`${this.projectURL}/exportFarmersByProjectId/${id}`, selFarmers);
  }

  exportFarmersProfilebyProjectId(id, selFarmers): Observable<any> {
    return this.http.post<any>(`${this.projectURL}/exportFarmersProfilebyProjectId/${id}`, selFarmers);
  }


  projectInfoPdf(project) {
    return this.http.post<any>(`${this.projectURL}/projectInfoPdf`, project);
  }

  getGovernanceEcosystems() {
    return this.http.get<any>(`${this.projectURL}/getGovernanceEcosystems`);
  }

  uploadImageContactSupport(formData: any): Observable<any> {
    return this.http.post<any>(`${this.projectURL}/uploadContactSupportImage`, formData);
  }

  GetProjectsBasicInfo(): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getUsersProjectsBasicInfo`);
  }

  GetProjectsDashboardData(): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getUsersProjectsDashboardData`);
  }

  getProjectBasicInfo(id): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getProjectBasicInfo/${id}`);
  }

  getProjectDashboardData(id): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getProjectDashboardData/${id}`);
  }

  getUsersProjectsProfilingData(): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getUsersProjectsProfilingData`);
  }

  getUsersProjectIds(): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getUsersProjectsIds`);
  }


  getProjectProfilingData(id): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getProjectProfilingData/${id}`);
  }

  getProjectServicesMappings(id): Observable<any> {
    return this.http.get<any>(`${this.projectURL}/getProjectServicesMappings/${id}`);
  }

}
