import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuppliersService } from '../../../services/suppliers.service';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
import { CommonService } from '../../../services/common.service';
var DashboardsListComponent = /** @class */ (function () {
    function DashboardsListComponent(route, projectService, toastrService, router, activeRoute, suppService, translate, hcRService, commonService) {
        var _this = this;
        this.route = route;
        this.projectService = projectService;
        this.toastrService = toastrService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.suppService = suppService;
        this.translate = translate;
        this.hcRService = hcRService;
        this.commonService = commonService;
        this.listViewDashboardStyle = true;
        this.projects = [];
        this.projectId = 0;
        this.typeOfAdministration = '';
        this.dashboardLists = [];
        translate.setDefaultLang('en');
        // this.activeRoute.paramMap.subscribe(params => {
        //   this.activeRoute.queryParamMap.subscribe(queryParams => {
        //     const qpObject = { ...queryParams.keys, ...queryParams };
        //     // console.log('qpObject', qpObject);
        //     this.origin = qpObject['params']['origin'];
        //     this.fromNavId = qpObject['params']['from'];
        //     this.translate.get('typeAdminTextArr').subscribe((val) => {
        //       this.typeOfAdministrationArr = val;
        //     }, err => console.log('err', err));
        //     if (this.fromNavId === 2) {
        //       this.typeOfAdministration = this.typeOfAdministrationArr[0];
        //     } else if (this.fromNavId === 3) {
        //       this.typeOfAdministration = this.typeOfAdministrationArr[1];
        //     } else if (this.fromNavId === 6) {
        //       this.typeOfAdministration = this.typeOfAdministrationArr[2];
        //     }
        //   });
        // });
        // this.dashboardLists = [
        //   {
        //     id: 1,
        //     name: 'Dashboard new title ABC',
        //     description: 'demo text available',
        //     type: 'predefined',
        //     isdefault: true,
        //     last_updated: '20/05/2020'
        //   }, {
        //     id: 2,
        //     name: 'Dashboard XTR title Dre',
        //     description: 'demo text available line 2',
        //     type: 'self',
        //     isdefault: false,
        //     last_updated: '20/05/2020'
        //   }, {
        //     id: 3,
        //     name: 'Accessibility',
        //     description: 'demo text available as accessibility',
        //     type: 'self',
        //     isdefault: false,
        //     last_updated: '20/05/2020'
        //   }
        // ];
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
    }
    DashboardsListComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requestParams;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.projectId = this.route.snapshot.params['id'];
                // if (navigator.onLine) {
                //   this.project = this.route.snapshot.data.project.project.data;
                // }else{
                //   const requests = await Promise.all([
                //     this.projectService.GetProjectsDataDashboard().toPromise()
                //   ]);
                //   this.project = requests[0].data.find(x => x.id === this.projectId);
                // }
                this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
                    _this.project = response.data;
                    var projectObject = _this.project.filter(function (item) { return item.id == _this.projectId; })[0];
                    _this.project = projectObject;
                });
                requestParams = {
                    "projectId": this.projectId
                };
                this.hcRService.getDashboardsList(requestParams).subscribe(function (response) {
                    if (response.msg == 'success') {
                        _this.dashboardLists = response.data;
                        for (var i = 0; i < _this.dashboardLists.length; i++) {
                            if (_this.dashboardLists[i].is_default == 'Y') {
                                _this.dashboardLists[i].is_default = true;
                            }
                            else if (_this.dashboardLists[i].is_default == 'N') {
                                _this.dashboardLists[i].is_default = false;
                            }
                        }
                        //console.log("this.dashboardLists ", this.dashboardLists);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    DashboardsListComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    DashboardsListComponent.prototype.getGridFilters = function () {
        var _this = this;
        //new fields added for search village_name and vc_name
        var gridColums = ['id', 'name', 'description', 'dashboard_type', 'upd_date'];
        var finalObj = {};
        if (this.dashboardLists === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    DashboardsListComponent.prototype.setADasboardAsDeafult = function (itemID) {
        var _this = this;
        var requestParams = {
            "dashboardId": itemID,
            "projectId": this.projectId,
        };
        this.hcRService.setDashboardAsDefault(requestParams).subscribe(function (response) {
            if (response.msg == 'success') {
                _this.commonService.showToast('success', 'dashboard_set_default', {});
                var requestParams_1 = {
                    "projectId": _this.projectId
                };
                _this.hcRService.getDashboardsList(requestParams_1).subscribe(function (response) {
                    //console.log("response ==> ", response);
                    if (response.msg == 'success') {
                        _this.dashboardLists = response.data;
                        for (var i = 0; i < _this.dashboardLists.length; i++) {
                            if (_this.dashboardLists[i].is_default == 'Y') {
                                _this.dashboardLists[i].is_default = true;
                            }
                            else if (_this.dashboardLists[i].is_default == 'N') {
                                _this.dashboardLists[i].is_default = false;
                            }
                        }
                    }
                });
            }
        });
    };
    // listView() {
    //   this.listViewDashboardStyle = true;
    // }
    // cardView() {
    //   this.listViewDashboardStyle = false;
    // }
    DashboardsListComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    DashboardsListComponent.prototype.addNewDashboard = function () {
        var url = "charts/dashboards/add/" + this.projectId;
        this.router.navigate([url]);
    };
    DashboardsListComponent.prototype.editDashboard = function (currentItem) {
        var url = "charts/dashboards/edit/" + currentItem.id + '/' + this.projectId;
        this.router.navigate([url]);
    };
    DashboardsListComponent.prototype.dashboardViewReports = function (currentItem) {
        if (currentItem.dashboard_type == 'predefined') {
            var url = "charts/dashboards/" + currentItem.route + '/' + currentItem.id + '/' + this.projectId;
            this.router.navigate([url]);
        }
        else if (currentItem.dashboard_type == 'self') {
            var url = "charts/dashboards/viewreports/" + currentItem.id + '/' + this.projectId;
            this.router.navigate([url]);
        }
    };
    return DashboardsListComponent;
}());
export { DashboardsListComponent };
