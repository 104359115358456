import { Component, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// Services
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { FarmerService } from '../../services/farmer.service';
import { v4 as uuidv4 } from 'uuid';
import { BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import { merge, of, fromEvent } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
declare var idbApp: any;
declare var serviceWorkerVar;
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { DataConsentService } from '../../services/data-consent.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-vpd2',
  templateUrl: './vpd2.component.html',
  styleUrls: ['./vpd2.component.css']
})
export class Vpd2Component implements OnInit {
  questionnaire; // Array of tabs
  currentTab; // Currently active tab
  currentTabIndex; // Index of currently active tab
  currentSection; // Currently active section
  currentSectionIndex; // Index of currently active section
  sectionForm: FormGroup; // Formgroup for currently active section
  showSectionForm = false; // Boolean to show / hide the section form
  qAnswers; // Array to contain all the answers in this questionnaire for this farmer
  checkedObject = {}; // Object that stores checked = true / false for checkbox questions (non-table)
  checkedObjectSecondaryKeys = {};  // Object that stores checked = true / false for checkbox questions (table)
  farmerId;  // Currently editing farmer's id
  secondary_asset_keys = {}; // Stores the secondary_asset_keys for each table. Format {tableId: [sak1, sak2], table2: [sak3, sak4]}
  secondary_asset_key__options = {}; // Stores the secondary_asset_key against the checkbox option that triggered creation of that sak. Format {optionId1: sak1, optionId2: sak2}
  project; // Project object for currently editing farmer
  notActiveSurvey = 2;
  notReleasedSurveyInLanguage;
  rawFarmerId;
  wizardShow;
  notes: any;
  currentUser = this.userService.getCurrentUser();
  projectId;
  farmer;
  revisionsInSurvey = [];
  selectedRevision = 0;
  tableOtherQuestionsIsDisplayed = {};
  saveChangesConfirmModal;
  unsavedAnswerCount = 0;
  dynamicOptionsObject = {};
  private readonly subject = new Subject<boolean>();
  displayLogicKeyValues = [];
  assetTypes = [
    {type: 'tab', subTypes: []},
    {type: 'section', subTypes: []},
    {type: 'subsection', subTypes: []},
    {type: 'table', subTypes: [{key: 'question_rows', label: 'Questions in rows'}, {key: 'question_cols', label: 'Questions in columns'}]},
    {type: 'question', subTypes: [{key: 'text', label: 'Text'}, {key: 'number', label: 'Number'}, {key: 'select', label: 'Select'}, {key: 'multiselect', label: 'Multiselect'}]},
    {type: 'option', subTypes: []},
    {type: 'uom_question', subTypes: [{key: '1', label: 'Length'},{key: '2', label: 'Volume'},{key: '3', label: 'Weight'},{key: '4', label: 'Area'}, {key: '5', label: 'Quantity'}]},
    {type: 'other_question', subTypes: []},
    {type: 'grand_total_question', subTypes: []},
  ];
  processedUOMs = {};
  defaultCropSak;
  askUserToConfirmExit = false;
  farmProfitabilitySummary = {income: {source: 'NA', amount: 'NA'}, expense: {source: 'NA', amount: 'NA'}, totalIncome: 'NA', totalExpense: 'NA', totalProfit: 'NA', agriIncome: 'NA', otherIncome: 'NA'};
  farmProfitabilitySummaryArray = [];
  usersServiceComponentMappings;
  ecosystemServicesComponentsMapping;
  canDoFarmerMgmt = false;
  thereNoActiveSnapshotForThisProject;
  showSummaryForCurrentTab;
  currencyCode;
  showHideBackButton = true;
  showHideNextButton = false;
  farmerDetailsTabIndex = 0;
  farmerRegQuestions = {};
  masterDataFarmer :any;
  snapshotsListDataDropdown;
  isOnline: Observable<boolean>;
  villageName = '';
  isCompare = false;
  revisionType = ''
  campaignId;
  breadcrumbTitle;

  consentHistory;
  consentTemplate:SafeHtml;
  privacyPolicyModal: BsModalRef;
  discontinuedFarmer = false;
  farmerDiscontinueStatus = false;
  discontinuedFarmerMapping;
  constructor(
    private readonly dynamicQuestionnaireService: DynamicQuestionnaireService,
    private readonly projectService: ProjectService,
    private readonly commonService: CommonService,
    private readonly route: ActivatedRoute,
    private readonly toastr: ToastrService,
    private readonly surveyService: SurveyService,
    private readonly userService: UserService,
    private readonly farmerService: FarmerService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly modalService: BsModalService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly dataConsentService:DataConsentService,
    private sanitizer: DomSanitizer
  ) { }

  /**
   * Init functions, API calls and many functions to process the API and IndexedDB data (in online & offline mode)
   * is the same as dynamic-questionnaire.component.ts - refer to that component for details
   */
  async ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.projectId = params.get('projectId');
      this.route.queryParamMap.subscribe(async queryParams => {
        // Get the 'raw' farmerid - this can be the farmer id (online registration) or a temporary offline id
        this.rawFarmerId = params.get('farmerId');
        // Get the farmer id (online registration), if it exists, or set this.farmerId to 0
        this.farmerId = +params.get('farmerId') || 0;
        if (queryParams.get('fromCompare') && queryParams.get('fromCompare') === '1') {
          this.isCompare = true;
        }
        if (queryParams.get('type')) {
          this.revisionType = queryParams.get('type');
        }
        // Get project info, questionnaire, & answers
        let request;
        let request2;
        this.isOnline  = merge(
          of(navigator.onLine),
          fromEvent(window, 'online').pipe(mapTo(true)),
          fromEvent(window, 'offline').pipe(mapTo(false))
        );
        this.spinner.show();
        if (queryParams.get('campaignId') && +queryParams.get('campaignId') !== 0 && !isNaN(+queryParams.get('campaignId'))) {
          this.campaignId = queryParams.get('campaignId');
          request = await this.campaignQuestionnaireFlow();
        } else {
          if (this.farmerId != 0) {
            if (navigator.onLine) {
              request = await Promise.all([
                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
                this.farmerService.getFarmerBasicDataById(this.farmerId).toPromise(),
                this.surveyService.getAllSurveysForProject(this.projectId).toPromise(),
                this.dataConsentService.getFarmerConsentHistory(this.projectId, this.farmerId).toPromise(),
                this.farmerService.getDiscontinuedFarmerSurveyMapping(this.projectId).toPromise(),
              ]);
            } else {
              request = await Promise.all([
                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise(),
                this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
              ]);
              request2 = await Promise.all([
                this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
              ]);
            }
          } else {
            request = await Promise.all([
              this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
              this.projectService.getProjectProfilingData(this.projectId).toPromise(),
              this.projectService.getProjectDashboardData(this.projectId).toPromise(),
              this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise()
            ]);
          }
        }
        
        const farmer_requests = await Promise.all([
          this.farmerService.getFarmRegQuestions().toPromise(),
          this.farmerService.GetFarmerMaster().toPromise()
        ]);
        this.farmerRegQuestions = farmer_requests[0].data;
        const masterData = farmer_requests[1].data;
        this.masterDataFarmer = masterData.filter((t)=>{return t.item_tab=="farmer_reg";});


        if (request[0]['msg'] !== 'success' || request[1]['msg'] !== 'success' || request[2]['msg'] !== 'success') {
          this.commonService.showToast('error', 'generic_error', {});
        } else {
          this.project = {...request[0].data[0], ...request[1].data[0], ...request[2].data[0]};
          this.canDoFarmerMgmtFn();
          this.setCurrencyCode();
          
          if (request[3]['message'] === 'notActive') {
            this.notActiveSurvey = 1;
            this.commonService.showToast('warning', 'No Active Survey Found', {});
          } else if (request[3]['message'] === 'notReleasedForLanguage') {
            this.notReleasedSurveyInLanguage = 1;
          } else {
            this.questionnaire = request[3]['message'];
            this.setQuestionnaireAttributes();
            this.notActiveSurvey = 0;
          }
          
          let result; let request3;
          if (this.farmerId != 0) {
            // If online, get answers from getQAnswersForFarmer (for this.farmerId)
            if (navigator.onLine) {
              result = request[4];
              //If online and farmer id then set farmer consent history
              if(request[7] && request[7].msg == 'success'){
                  this.consentHistory = request[7].data[0];
              }
              if(this.consentHistory && this.consentHistory.farmer_consent_history.length > 1){
                this.consentHistory.farmer_consent_history.reverse();
              }
            } else {
              // If offline, get answers from getQAnswersForFarmer (for this.farmerId) - this is coming from request2
              result = {code: 'success'};
              let qAnswersForFarmer;
              if (request2 && request2[0] && request2[0]['message']) {
                // If request2[0] has a message attribute, getQAnswersForFarmer has been called for this farmer before
                qAnswersForFarmer = request2[0];
              } else {
                const paginationLimitsArray = request[4].message;
                // console.log('paginationLimitsArray', paginationLimitsArray);
                const paginationLimits = this.getPaginationLimits(paginationLimitsArray, this.farmerId)
                // console.log('paginationLimits', paginationLimits);

                if (this.campaignId) {
                  request3 = await Promise.all([
                    this.dynamicQuestionnaireService.getQAnswersForCampaignPaginated(this.campaignId, paginationLimits[0], paginationLimits[1]).toPromise()
                  ]);
                } else {
                  //added by KRPT for getting active survey ID
                  const surveyReq = await Promise.all([
                    this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                  ]);
                  let activeSurveyId;
                  if (surveyReq[0]['code'] === 'success' && surveyReq[0]['message'].length > 0) {
                    let activeSnapshot;
                    const snapshotSurveys = surveyReq[0]['message'].filter(item => item.survey_type == 'snapshot');
                    if (snapshotSurveys && snapshotSurveys.length > 0) {
                      activeSnapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
                      if(activeSnapshot) {
                        activeSurveyId = activeSnapshot.id;
                      }
                    }
                  }
                  const getQIds = await Promise.all([
                    this.dynamicQuestionnaireService.getSurveyQAssetIdsDashboard(activeSurveyId).toPromise()
                  ])
                  if (getQIds[0]['code'] === 'success'){                      
                    request3 = await Promise.all([
                      this.dynamicQuestionnaireService.getQAnswersForProjectPaginatedDashboard(this.projectId, paginationLimits[0], paginationLimits[1], getQIds[0]['message']).toPromise()
                    ]);
                  }
                }

                

                // If getQAnswersForFarmer has not been called for this farmer, get the answer values from getQAnswersForProject by filtering on farmerId
                result['message'] = request3[0]['msg'] !== 'blank' ? request3[0]['message'].filter(item => item.farmer_id === this.farmerId) : [];

              }
              // If getQAnswersForFarmer has been called for this farmer before, compare the timestamp of the two API calls (farmer specific & project-wide data fetch)
              // Set the results array as the latest API data fetch
              // if (qAnswersForFarmer) {
              //       // getQAnswersForFarmer fetch      getQAnswersForProject fetch
              //   if (qAnswersForFarmer['ts'] > request3[0]['ts']) {
              //     // Set as farmer-specific API fetch
              //     console.log("Inside if...");
              //     result['message'] = qAnswersForFarmer['message'];
              //   } else {
              //     // Set as project-wide fetch, filtered by farmerId
              //     console.log("request3[0]['message']:", request3[0]['message']);
              //     result['message'] = request3[0]['message'].filter(item => item.farmer_id === this.farmerId);
              //   }
              // }
              if (qAnswersForFarmer && request3) {
                // getQAnswersForFarmer fetch      getQAnswersForProject fetch
                if (qAnswersForFarmer['ts'] > request3[0]['ts']) {
                  // Set as farmer-specific API fetch
                  result['message'] = qAnswersForFarmer['message'];
                } else {
                  // Set as project-wide fetch, filtered by farmerId
                  result['message'] = request3[0]['message'].filter(item => item.farmer_id === this.farmerId);
                }
              } else if (qAnswersForFarmer && !request3) {
                result['message'] = qAnswersForFarmer['message'];
              } else if (!qAnswersForFarmer && request3) {
                result['message'] = request3[0]['message'].filter(item => item.farmer_id === this.farmerId);
              }
            }
          } else {
            result = [];
          }
          
          if (this.farmerId !== 0 && request[5]['msg'] === 'success') {
            if (navigator.onLine) {
              this.farmer = request[5]['data'];
            } else {
              this.farmer = request[5]['data'].find(item => item.id === this.farmerId);

              let farmertmpobj = await idbApp.getAllProjects('farmerstmp');
              const tempFarmer = farmertmpobj.find(x => x.id === this.farmerId);
              if(tempFarmer){
                this.farmer = tempFarmer;
              }
            }
            this.setVCMapping();
          } else {
            let farmertmpobj = await idbApp.getAllProjects('farmerstmp');
            const rawFarmerKey = this.rawFarmerId.toString()+"0";
            const tempFarmer = farmertmpobj.find(x => x.tmp_id === rawFarmerKey);
            if(tempFarmer){
              this.farmer = tempFarmer;
            }else{
              this.farmer = farmertmpobj.find(x => x.tmp_id === this.rawFarmerId);
            }
          }

          if (this.farmer.FarmerQuickNotes && this.farmer.FarmerQuickNotes.length > 0) {
            this.notes = this.farmer.FarmerQuickNotes[0];  
          } else {
            this.notes = { notes: '', farmer_id: this.farmerId };
          }

          // Set quick notes
          this.setFarmerQuickNotes();
          this.setVillageName();

          // Set the qAnswers array
          console.log("result:",result);
          this.setQAnswersAndInitialize(result);
          // this.preProcessProjectUOMs();
        }
        this.spinner.hide();

        if(navigator.onLine && this.farmerId !== 0 && request[6] && request[6]['code'] === 'success'){
          this.setSurveysList(request[6].message);
        }
        if(this.isCompare){
          this.notActiveSurvey = 0;
          let requestVal = localStorage.getItem('compareSnapshotsRevisionRequestObject');
          requestVal = JSON.parse(requestVal);
          if(this.revisionType === 'target'){
            this.selectedRevision = parseInt(requestVal['targetRevisionId']);
          } else {
            this.selectedRevision = parseInt(requestVal['sourceRevisionId']);
          }
          if(this.selectedRevision !== 0){
            this.changeRevision();
          }
        }
        //check if farmer is disabled
        const isDisabled = this.farmer.is_discontinued;
        if(isDisabled){
          this.discontinuedFarmer = true;
          this.farmerDiscontinueStatus = true;
        }
        //discontinued farmer survey mapping
        if(navigator.onLine && request[8] && request[8].msg === 'success') {
          this.discontinuedFarmerMapping = request[8].data.filter(item => item.farmer_id == this.farmer.id);
        } 
      })
    });

  }

  async setQAnswersAndInitialize(result, oldRevisionData?) {
    this.qAnswers = [];
    let indexedDBEntries = [];
    if (!oldRevisionData) {
      // Find this rawFarmerId's indexedDB entries if any
      const farmertmpobj = await idbApp.getAllProjects('dq_profiling');
      if (farmertmpobj && farmertmpobj.length > 0) {
        let qAnswersForThisFarmerId = farmertmpobj.filter(item => item.tmp_id === this.rawFarmerId);
        if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
          for (let i = 0; i < qAnswersForThisFarmerId.length; i++) {
            indexedDBEntries = indexedDBEntries.concat(JSON.parse(qAnswersForThisFarmerId[i].values));
          }
        }
      } 
    }
    // Cached values
    if (result.code === 'success' && result.message.length > 0) {
      for (let i = 0; i < result.message.length; i++) {
          this.qAnswers.push({
            id: result.message[i].id.toString(),
            key: result.message[i].questionnaire_asset_id.toString(),
            sak_of_modified: result.message[i].sak_of_modified,
            secondary_asset_key: result.message[i].secondary_asset_key,
            value: result.message[i].answer.toString(),
            flex3: result.message[i].flex3,
            deleted: result.message[i].deleted,
            imgBase64:result.message[i].imgBase64
          });
      }
    }
    
    if (!oldRevisionData) {
      // IndexedDB values
      if (indexedDBEntries.length > 0) {
        for (let i = 0; i < indexedDBEntries.length; i++) {
          // If the indexedDB entry has an id, find the same id from qAnswers and update the value, mark as touched
          if (indexedDBEntries[i].id) {
            const x = this.qAnswers.find(item => item.id == indexedDBEntries[i].id);
            if (x) {
              const xIndex = this.qAnswers.indexOf(x);
              if (this.validIndex(xIndex)) {
                this.qAnswers[xIndex]['value'] = indexedDBEntries[i]['value'];
                this.qAnswers[xIndex]['touched'] = true;
                if (indexedDBEntries[i]['checked']) {this.qAnswers[xIndex]['checked'] = indexedDBEntries[i]['checked'];}
                if (indexedDBEntries[i]['unchecked']) {this.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['unchecked'];}
                if (indexedDBEntries[i]['deleted']) {
                  this.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['deleted'];
                  this.qAnswers[xIndex]['deleted'] = indexedDBEntries[i]['deleted']
                }
                this.qAnswers[xIndex]['flex3'] = indexedDBEntries[i]['flex3'];
              }
            }
          } else {
            // Push into qAnswers
            const answerToInsert = {
              key: indexedDBEntries[i].key.toString(),
              sak_of_modified: indexedDBEntries[i].sak_of_modified,
              secondary_asset_key: indexedDBEntries[i].secondary_asset_key,
              value: indexedDBEntries[i].value.toString(),
              touched: true,
              checked: indexedDBEntries[i].checked,
              flex3: indexedDBEntries[i].flex3,
              deleted: indexedDBEntries[i].deleted
            };

            if (indexedDBEntries[i]['checked']) {
              answerToInsert['checked'] = indexedDBEntries[i]['checked'];
            }
            if (indexedDBEntries[i]['unchecked']) {
              answerToInsert['unchecked'] = indexedDBEntries[i]['unchecked'];
            }
            if (indexedDBEntries[i]['deleted']) {
              answerToInsert['unchecked'] = indexedDBEntries[i]['deleted'];
            }
            this.qAnswers.push(answerToInsert);
          }
        }
        this.askUserToConfirmExit = false;
      }
    }
    
    this.qAnswers = this.qAnswers.filter(item => item.flex3 === "A" && !item.deleted);
    
    if (this.notActiveSurvey === 0) {
      if (this.questionnaire && this.questionnaire.length > 0) {
        // Initialize the questionnaire
        this.initialize();
        // Set the sak_of_modified values in this.secondary_asset_key__options object (which checkbox impacted which row / column uuid in a table)
        this.setSecondaryAssetKeyOptionValues();
        // Set dynamic options (which crop intercropping questions)
        this.setDynamicOptionsObject();
        if (this.questionnaire && this.questionnaire.length > 0) {
          this.setAnswerValues();
          this.setDisplay();
        }
      }
    }

  }

  // Naviate to 1st section inside the clicked tab
  tabClicked(tabIndex, isFarmerTab?) : void {
    if (!isFarmerTab) {
      this.currentTab = this.questionnaire[tabIndex];
    }
    this.currentTabIndex = tabIndex;
    
    this.showSummaryForCurrentTabFn();
    this.runTabSummaryCalculate();
    this.scrollToTop();
  }

  // Initialize the questionnaire by navigating to the 1st tab
  initialize(): void {
    this.farmerDetailsTabIndex = this.questionnaire.length + 1;
    this.currentTabIndex = this.farmerDetailsTabIndex;
    this.tabClicked(this.currentTabIndex, true);
  }

  canDoFarmerMgmtFn(): void {
    if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
      this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
    }
  
    if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
      this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
    }
  
    if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
      let canDoFarmerMgmt = this.usersServiceComponentMappings.filter(item => (item.services_id == 2 && item.user_type_id == this.currentUser.role.id));
      if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
        this.canDoFarmerMgmt = true;
      }
    }
    else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
      let canDoFarmerMgmt = this.ecosystemServicesComponentsMapping.filter(item => item.services_id == 2);
      if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
        this.canDoFarmerMgmt = true;
      }
    }
  }
  
  showNoActiveSnapshotMessage(): void {
    this.canDoFarmerMgmtFn();
    setTimeout(() => this.toastr.info(this.thereNoActiveSnapshotForThisProject), 0);
  }
  
  showSummaryForCurrentTabFn(): void {
    if (this.currentTabIndex != this.farmerDetailsTabIndex && this.currentTab && this.currentTab.displayFunction && this.currentTab.displayFunction.indexOf('showSummary') > -1) {
      this.showSummaryForCurrentTab = true;
    } else {
      this.showSummaryForCurrentTab = false;
    }
  }
  
  runTabSummaryCalculate(): void {
    if (this.showSummaryForCurrentTab) {
      this.createProfitabilitySummaryObject();
      if (this.currentSection && this.farmProfitabilitySummaryArray && this.farmProfitabilitySummaryArray.length > 0) {
        const x = this.farmProfitabilitySummaryArray.find(item => item.sectionId == this.currentSection.id);
        const indexOfCurrentSection = this.farmProfitabilitySummaryArray.indexOf(x);
        const sections = JSON.parse(JSON.stringify(this.farmProfitabilitySummaryArray));
        if (indexOfCurrentSection > 0) {
          sections.splice(indexOfCurrentSection);
          sections.reverse();
        }
  
        for (let i = 0; i < this.farmProfitabilitySummaryArray.length; i++) {
          if (this.currentSection.id == this.farmProfitabilitySummaryArray[i].sectionId) {
            if (this.currentSection.isIncome) {
              this.farmProfitabilitySummary.income = {
                source: this.farmProfitabilitySummaryArray[i].label,
                amount: this.farmProfitabilitySummaryArray[i].amount
              }
              const expenseSection = sections.find(item => item.isExpense);
              if (expenseSection) {
                this.farmProfitabilitySummary.expense = {
                  source: expenseSection.label,
                  amount: expenseSection.amount
                }
              }
            } else if (this.currentSection.isExpense) {
              this.farmProfitabilitySummary.expense = {
                source: this.farmProfitabilitySummaryArray[i].label,
                amount: this.farmProfitabilitySummaryArray[i].amount
              }
  
              const incomeSection = sections.find(item => item.isIncome);
              if (incomeSection) {
                this.farmProfitabilitySummary.income = {
                  source: incomeSection.label,
                  amount: incomeSection.amount
                }
              }
            }
          }
        }
      }
    }
  }
  
  createProfitabilitySummaryObject(): void {
    this.farmProfitabilitySummaryArray = [];
    const sections = this.currentTab.sections;
    let totalIncome = 0;
    let totalExpense = 0;
    let agriIncome = 0;
    let otherIncome = 0;
    if (sections && sections.length > 0) {
      for (let i = 0; i < sections.length; i++) {
        if (sections[i].isIncome || sections[i].isExpense) {
          const amount = this.getSummaryAmount(sections[i]);
          const amountToSet = amount ? amount : 0;
          const obj = {
            index: i,
            sectionId: sections[i].id,
            label: sections[i]['survey_q_asset_labels'][0].label,
            amount: amountToSet,
            isIncome: sections[i]['isIncome'],
            isExpense: sections[i]['isExpense'],
            isAgriIncome: sections[i]['isAgriIncome'],
            isOtherIncome: sections[i]['isOtherIncome']
          };
          this.farmProfitabilitySummaryArray.push(obj);
          if (obj.isIncome) {totalIncome = totalIncome + parseFloat(amountToSet);}
          if (obj.isExpense) {totalExpense = totalExpense + parseFloat(amountToSet);}
          if (obj.isAgriIncome) {agriIncome = agriIncome + parseFloat(amountToSet);}
          if (obj.isOtherIncome) {otherIncome = otherIncome + parseFloat(amountToSet);}
        }
        this.farmProfitabilitySummary.totalIncome = totalIncome.toString();
        this.farmProfitabilitySummary.totalExpense = totalExpense.toString();
        this.farmProfitabilitySummary.totalProfit = (totalIncome - totalExpense).toString();
        this.farmProfitabilitySummary.agriIncome = agriIncome.toString();
        this.farmProfitabilitySummary.otherIncome = otherIncome.toString();
      }
    }
  }
  
  resetFarmProfitabilitySummary(): void {
    this.showSummaryForCurrentTab = false;
    this.farmProfitabilitySummary = {income: {source: 'NA', amount: 'NA'}, expense: {source: 'NA', amount: 'NA'}, totalIncome: 'NA', totalExpense: 'NA', totalProfit: 'NA', agriIncome: 'NA', otherIncome: 'NA'}; 
    this.farmProfitabilitySummaryArray = [];
  }
  
  getSummaryAmount(section) {
    let answerToReturn;
    if (section.displayFunction) {
      const x = JSON.parse(section.displayFunction);
      if (x) {
        if (x['answerQAsset']) {
          const y = this.qAnswers.find(item => item.key == x['answerQAsset'] && !item.deleted);
          if (y) {answerToReturn = y.value;}
        }
  
        // This is required for the workers expenditure section
        // Grand total of the daily workers expenses must be added to 12 * number of workers * avg monthly wate
        if (x['multiplyFollowing']) {
          if (x['key1'] && x['key2']) {
            const y1 = this.qAnswers.find(item => item.key == x['key1'] && !item.deleted);
            const y2 = this.qAnswers.find(item => item.key == x['key2'] && !item.deleted);
            if (y1 && y2) {
              const t = x['multiplyFollowing'] * y1.value * y2.value;
              answerToReturn = answerToReturn ? parseInt(answerToReturn) + t : t;
            }
          }
        }
      }
    }
    return answerToReturn;
  }
  
  setQuestionnaireAttributes(): void {
    if (this.questionnaire && this.questionnaire.length > 0) {
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (this.questionnaire[i].sections[j].displayFunction) {
            const x = JSON.parse(this.questionnaire[i].sections[j].displayFunction);
            if (x.type && x.type === 'income') {
              this.questionnaire[i].sections[j]['isIncome'] = true;
            } else if (x.type && x.type === 'expense') {
              this.questionnaire[i].sections[j]['isExpense'] = true;
            }

            if (x.subtype && x.subtype === 'agri') {
              this.questionnaire[i].sections[j]['isAgriIncome'] = true;
            } else if (x.subtype && x.subtype === 'other') {
              this.questionnaire[i].sections[j]['isOtherIncome'] = true;
            }
          }
        }
      }
    }
  }
  
  setCurrencyCode(): void {
    if(this.project.Currency && this.project.Currency.currency_code){
      this.currencyCode = this.project.Currency.currency_code;
    } else{
      this.currencyCode = "";
    }
  }

  async setFarmerQuickNotes() {
    let rawFarmerIdQuickNotes;
    const x = this.rawFarmerId.substring(0,2);
    if (x == 'FA') {
      // the farmer was registered offline
      const whichTab = this.rawFarmerId.substring(this.rawFarmerId.length - 2);
      if (whichTab == '00') {
        rawFarmerIdQuickNotes = `${this.rawFarmerId.slice(0, -5)}QN`;
      } else {
        rawFarmerIdQuickNotes = `${this.rawFarmerId.slice(0, -4)}QN`;
      }
    } else {
      // // farmer was registered online
      // const p = 10 - this.rawFarmerId.length;
      // let m = '';
      // for (let i = 0; i < p; i++) {
      //   m = `${m}0`;
      // }
      rawFarmerIdQuickNotes = `${this.rawFarmerId}_QN`;
      // console.log('rawFarmerIdQuickNotes', rawFarmerIdQuickNotes);
    }
    // Find if the farmer has quick notes in it
    const quickNotesFromDB = this.farmer.FarmerQuickNotes;
  
    let quickNotesFromIndexedDB;
    if (!navigator.onLine) {
      const farmertmpobj = await idbApp.getAllProjects('farmerstmp');
  
      quickNotesFromIndexedDB = farmertmpobj.filter(x => x.tmp_id === rawFarmerIdQuickNotes);
    }
    // console.log('quickNotesFromIndexedDB', quickNotesFromIndexedDB);
  
    if (!this.farmer.FarmerQuickNotes) {
      this.farmer.FarmerQuickNotes = [];
    }
    
    if (quickNotesFromDB) {
      this.farmer.FarmerQuickNotes = quickNotesFromDB;
    }
  
    if (quickNotesFromIndexedDB && quickNotesFromIndexedDB.length > 0) {
      this.farmer.FarmerQuickNotes = quickNotesFromIndexedDB;
    }
  
    if (this.farmer.FarmerQuickNotes && this.farmer.FarmerQuickNotes.length > 0) {
      this.notes = this.farmer.FarmerQuickNotes[0];
    }
  }

  // preProcessProjectUOMs() {
  //   this.processedUOMs = {};
  //   if (this.project && this.project.ProjectUOM && this.project.ProjectUOM.length > 0) {
  //     for (let i = 0; i < this.project.ProjectUOM.length; i++) {
  //       if (!this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]) {
  //         this.processedUOMs[this.project.ProjectUOM[i].unit_type_id] = {};
  //         this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['units'] = [];
  //       }
  //       const projectDefaultUnit = this.project.ProjectUOM.find(item => item.is_default && item.unit_type_id === this.project.ProjectUOM[i].unit_type_id);
  //       this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['projectDefaultUnit'] = projectDefaultUnit;
  //       this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['units'].push({
  //         id: this.project.ProjectUOM[i].id,
  //         international_unit: this.project.ProjectUOM[i].international_unit,
  //         international_value: this.project.ProjectUOM[i].international_value,
  //         uom: this.project.ProjectUOM[i].uom,
  //         conversionFactorToProjectDefault: this.project.ProjectUOM[i].international_value / projectDefaultUnit.international_value
  //       });
  //     }
  //   }
  // }
  validIndex(index): boolean {
    if (index != null && index > -1) {
      return true;
    } return false;
  }

  // Set the values in this.secondary_asset_key__options given a qAnswers array
  setSecondaryAssetKeyOptionValues() {
    for (let i = 0; i < this.qAnswers.length; i++) {
      if (this.qAnswers[i].sak_of_modified != null) {
        this.secondary_asset_key__options[this.qAnswers[i].secondary_asset_key] = this.qAnswers[i].sak_of_modified;
      }
    }
  }

  // Create the set of dynamic options for the two questions
  // crop intercropping with surveyed crop
  // crop intercropping with surveyed crop (field level)
  setDynamicOptionsObject(): void {
    if (this.questionnaire && this.questionnaire !== 'noSurveys' && this.questionnaire.length > 0) {
      this.dynamicOptionsObject = {};
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
            for (let k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
              if (this.questionnaire[i].sections[j].tables[k].displayFunction && this.questionnaire[i].sections[j].tables[k].displayFunction.indexOf("addOptionToQ") > -1) {
                const tableModifyDetails = JSON.parse(this.questionnaire[i].sections[j].tables[k].displayFunction);
                if (tableModifyDetails && tableModifyDetails.addOptionToQ) {
                  const addOptionToQ = tableModifyDetails.addOptionToQ.split(",");
                  const qToAddAsOption = tableModifyDetails.qToAddAsOption;
                  let existingAnswers = this.qAnswers.filter(item => item.key == qToAddAsOption);
                  if (existingAnswers && existingAnswers.length > 0) {
                    for (let p = 0; p < addOptionToQ.length; p++) {
                      for (let q = 0; q < existingAnswers.length; q++) {
                        if (!existingAnswers[q].deleted) {
                          if (!this.dynamicOptionsObject[addOptionToQ[p]]) {this.dynamicOptionsObject[addOptionToQ[p]] = [];}
                          if (!this.dynamicOptionsObject[addOptionToQ[p]].find(item => item.sak == existingAnswers[q].secondary_asset_key)) {
                            this.dynamicOptionsObject[addOptionToQ[p]].push({q: qToAddAsOption, sak: existingAnswers[q].secondary_asset_key, label: existingAnswers[q].value});
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Set answer values for each section, subsection, table
   */
  setAnswerValues(): void {
    for (let tab of this.questionnaire) {
      for (let section of tab.sections) {
        for (let question of section.questions){
          question = this.setAnswerForQuestionNonTable(question);
        }

        for (let subsection of section.subsections) {
          for (let qs of subsection.questions) {
            qs = this.setAnswerForQuestionNonTable(qs);
          }
        }

        for (let table of section.tables) {
          this.secondary_asset_keys[table.id] = this.getSecondaryAssetKeysForTable(table);
          for (let qt of table.questions) {
            this.setAnswerForQuestionTable(qt, this.secondary_asset_keys[table.id]);
          }
          if (table.displayFunction && table.displayFunction.indexOf('addDefaultCropEntry') > -1) {
            this.sortDefaultCropToTop(table);
          }
        }
      }
    }
  }

  /**
   * For NON-TABLE questions only
   * Set the answer value of the question, depending on qa_type
   * Set the answer values of the child other_question, uom_questions
   * @param question 
   */
  setAnswerForQuestionNonTable(question) {
    let temp;
    let answer;
    let otherAnswer;
    let uomAnswer;
    if (question.qa_subtype === 'text') {
      temp = this.qAnswers.find(item => item.key == question.id);
      if (temp) {answer = temp.value;}
    } else if (question.qa_subtype === 'number') {
      temp = this.qAnswers.find(item => item.key == question.id);
      if (temp) {answer = temp.value;}
    } else if (question.qa_subtype === 'select') {
      const x = this.qAnswers.find(item => item.key == question.id);
      if (x) {
        const y = question.options.find(item => item.id == x.value);
        if (y) {
          answer = y.survey_q_asset_labels[0].label;
        }
      } 
    } else if (question.qa_subtype === 'date') {
      temp = this.qAnswers.find(item => item.key == question.id);
      if (temp) {answer = temp.value;}
    } else if (question.qa_subtype === 'file') {
      temp = this.qAnswers.find(item => item.key == question.id);
      if (temp) {answer = temp.value;}
    } else { // multiselect
      const x = this.qAnswers.filter(item => item.key == question.id);
      if (x && x.length > 0) {
        if (!question.has_dynamic_options) {
          const y = question.options.filter(item => x.find(each => each.value == item.id));
          if (y && y.length > 0) {
            const z = y.map(item => item.survey_q_asset_labels[0].label);
            answer = z.join(", ");
          }
        } else {
          const doo = this.dynamicOptionsObject[question.id];
          if (doo) {
            const y = doo.filter(item => x.find(each => each.value == item.q + '__' + item.sak));
            if (y && y.length > 0) {
              const z = y.map(item => item.label);
              answer = z.join(", ");
            } 
          }
        }
      }
    }

    if (answer) {
      question.answer = answer; 
    }

    if (question.other_questions && question.other_questions.length > 0) {
      otherAnswer = this.qAnswers.find(item => item.key == question.other_questions[0].id);
      if (otherAnswer) {
        question.otherAnswer = otherAnswer.value;
      }
    }

    if (question.uom_questions && question.uom_questions.length > 0) {
      for (let uomQ of question.uom_questions) {
        temp = this.qAnswers.find(item => item.key == uomQ.id);
        if (temp) {
          const y = this.project.ProjectUOM.find(item => item.id == temp.value);
          if (y && question.answer) {
            uomQ.answer = y.uom;
          }
        }
      }
    }
    return question;
  }


  /**
   * For TABLE questions
   * Create an answer object with key = secondary_asset_key and the value = the answer
   * @param question 
   * @param secondaryAssetKeys 
   */
  setAnswerForQuestionTable(question, secondaryAssetKeys) {
    let answer = {};
    let uomQAnswer = {};
    let otherQAnswer = {};
    for (let sak of secondaryAssetKeys) {
      let temp;
      let otherAnswer;
      if (question.qa_subtype === 'text') {
        temp = this.qAnswers.find(item => item.key == question.id && item.secondary_asset_key == sak);
        if (temp) {answer[sak] = temp.value;}
      } else if (question.qa_subtype === 'number') {
        temp = this.qAnswers.find(item => item.key == question.id && item.secondary_asset_key == sak);
        if (temp) {answer[sak] = temp.value;}
      } else if (question.qa_subtype === 'select') {
        const x = this.qAnswers.find(item => item.key == question.id && item.secondary_asset_key == sak);
        if (x) {
          const y = question.options.find(item => item.id == x.value);
          if (y) {
            answer[sak] = y.survey_q_asset_labels[0].label;
          }
        } 
      } else if (question.qa_subtype === 'date') {
        temp = this.qAnswers.find(item => item.key == question.id && item.secondary_asset_key == sak);
        if (temp) {answer[sak] = temp.value;}
      } else if (question.qa_subtype === 'file') {
        temp = this.qAnswers.find(item => item.key == question.id && item.secondary_asset_key == sak);
        if (temp) {answer[sak] = temp.value;}
      } else { // multiselect
        const x = this.qAnswers.filter(item => item.key == question.id && item.secondary_asset_key == sak);
        if (x && x.length > 0) {
          if (!question.has_dynamic_options) {
            const y = question.options.filter(item => x.find(each => each.value == item.id));
            if (y && y.length > 0) {
              const z = y.map(item => item.survey_q_asset_labels[0].label);
              answer[sak] = z.join(", ");
            }
          } else {
            const doo = this.dynamicOptionsObject[question.id];
            if (doo) {
              const y = doo.filter(item => x.find(each => each.value == item.q + '__' + item.sak));
              if (y && y.length > 0) {
                const z = y.map(item => item.label);
                answer[sak] = z.join(", ");
              } 
            }
          }
        }
      }

      if (question.other_questions && question.other_questions.length > 0) {
        otherAnswer = this.qAnswers.find(item => item.key == question.other_questions[0].id && item.secondary_asset_key == sak);
        if (otherAnswer) {
          otherQAnswer[sak] = otherAnswer.value;
          question.otherAnswer = otherQAnswer;
        }
      }

      if (question.uom_questions && question.uom_questions.length > 0) {
        for (let uomQ of question.uom_questions) {
          temp = this.qAnswers.find(item => item.key == uomQ.id && item.secondary_asset_key == sak);
          if (temp) {
            const y = this.project.ProjectUOM.find(item => item.id == temp.value);
            if (y) {
              uomQAnswer[sak] = y.uom;
            }
          }
          uomQ.answer = uomQAnswer;
        }
      }
    }
    question.answer = answer;
    return question;
  }

  /** Not used. This can be deleted */
  existsInQAnswersForTableQuestion(question) {
    return this.qAnswers.filter(item => item.key == question.id && !item.deleted && !item.unchecked);
  }

  /**
   * For questions in this table, get all the unique values of secondary_asset_keys from the answers array
   * i.e. get the identifiers of each row / column in the table
   * @param table 
   */
  getSecondaryAssetKeysForTable(table) {
    let sakArray = [];
    if (table.questions && table.questions.length > 0) {
      const qIds = table.questions.map(item => item.id.toString());
      const answersForQIds = this.qAnswers.filter(item => qIds.indexOf(item.key) > -1);
      if (answersForQIds && answersForQIds.length > 0) {
        const answersForQIdsSaks = answersForQIds.map(item => item.secondary_asset_key);
        if (answersForQIdsSaks && answersForQIdsSaks.length > 0) {
          sakArray = this.getUniqueValues(answersForQIdsSaks);
        }
      }
    }
    return sakArray;
  }

  /**
   * Get unique elements from the given array
   * @param array 
   */
  getUniqueValues(array) {
    let uniqueArray = [];
    for (let item of array) {
      if (uniqueArray.indexOf(item) < 0) {
        uniqueArray.push(item);
      }
    }
    return uniqueArray;
  }

  /**
   * For the given farmer, set the name of the village collector if it exists
   */
  setVCMapping(): void {
    if (this.farmer) {
      if (this.farmer.vc_farmer_mappings && this.farmer.vc_farmer_mappings.length > 0) {
        let vcName = '';
        for (const val of this.farmer.vc_farmer_mappings) {
          if(val.status === 'Active'){
            if(vcName.length === 0){
              vcName = `${vcName}${val.users.name}`;
            } else {
              vcName = `${vcName}, ${val.users.name}`;
            }
          }
        }
        // if (this.farmer.vc_farmer_mappings[0].status == 'Active') {
          this.farmer.village_collector = vcName;
        // }
      }
    }
  }

  /**
   * Return the value of multivalue_master array for the given code
   * @param code 
   * @param value 
   */
  getMasterData(code,value){
    let answer = this.masterDataFarmer.filter((v) => {
      return v.master_id == value && v.item_code == code;
    });
    if (answer.length > 0) {
      return answer[0].name;
    } else {
      return null;
    }
  }

  /**
   * For each question & question's children, check whether it should be displayed or not, depending on defined
   * display logic and other answers
   */
  setDisplay(): void {
    for (let x = 0; x < this.questionnaire.length; x++) {
      for (let y = 0; y < this.questionnaire[x].sections.length; y++) {
        for (let i = 0; i < this.questionnaire[x].sections[y].questions.length; i++) {
          this.questionnaire[x].sections[y].questions[i].isDisplayed = this.checkAssetDisplay(this.questionnaire[x].sections[y].questions[i]);
    
          if (this.questionnaire[x].sections[y].questions[i].other_questions && this.questionnaire[x].sections[y].questions[i].other_questions.length > 0) {
            this.questionnaire[x].sections[y].questions[i].other_questions[0].isDisplayed = this.checkOtherAnswerQuestionDisplay(this.questionnaire[x].sections[y].questions[i]);
          }
        }
        for (let k = 0; k < this.questionnaire[x].sections[y].subsections.length; k++) {
          this.questionnaire[x].sections[y].subsections[k].isDisplayed = this.checkAssetDisplay(this.questionnaire[x].sections[y].subsections[k]);
          for (let i = 0; i < this.questionnaire[x].sections[y].subsections[k].questions.length; i++) {
            this.questionnaire[x].sections[y].subsections[k].questions[i].isDisplayed = this.checkAssetDisplay(this.questionnaire[x].sections[y].subsections[k].questions[i]);
            if (this.questionnaire[x].sections[y].subsections[k].questions[i].other_questions && this.questionnaire[x].sections[y].subsections[k].questions[i].other_questions.length > 0) {
              this.questionnaire[x].sections[y].subsections[k].questions[i].other_questions[0].isDisplayed = this.checkOtherAnswerQuestionDisplay(this.questionnaire[x].sections[y].subsections[k].questions[i]);
            }
          }
        }
        for (let k = 0; k < this.questionnaire[x].sections[y].tables.length; k++) {
          this.questionnaire[x].sections[y].tables[k].isDisplayed = this.checkAssetDisplay(this.questionnaire[x].sections[y].tables[k]);
          for (let i = 0; i < this.questionnaire[x].sections[y].tables[k].questions.length; i++) {
            if (this.questionnaire[x].sections[y].tables[k].questions[i].other_questions && this.questionnaire[x].sections[y].tables[k].questions[i].other_questions.length > 0) {
              if (!this.tableOtherQuestionsIsDisplayed) {
                this.tableOtherQuestionsIsDisplayed = {}
              }
              for (let p = 0; p < this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id].length; p++) {
                this.tableOtherQuestionsIsDisplayed[this.questionnaire[x].sections[y].tables[k].questions[i].other_questions[0].id + '_' + this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id][p]] 
                = this.checkOtherAnswerQuestionDisplay(this.questionnaire[x].sections[y].tables[k].questions[i], this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id][p]);
              }
            }
          }
          // TODO: Add logic for questions within the table
          for (let i = 0; i < this.questionnaire[x].sections[y].tables[k].questions.length; i++) {
            if (this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id] && this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id].length > 0) {
              for (let j = 0; j < this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id].length; j++) {
                this.questionnaire[x].sections[y].tables[k].questions[i][this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id][j] + '_isDisplayed'] = 
                this.checkAssetDisplay(this.questionnaire[x].sections[y].tables[k].questions[i], this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id][j]);
              }
            }
          }
        }
      }
    }
  }

  /**
    * Check for simple toggle logic in deciding whether to display / hide an asset
    * By default, display the asset if there is no toggle logic defined for it
   * @param asset 
   * @param secondary_asset_key 
   */
  checkAssetDisplay(asset, secondary_asset_key?): boolean {
    // console.log('checkAssetDisplay secondary_asset_key', secondary_asset_key);
    // If the asset has any defined toggle logic
    if (asset.survey_q_asset_display_logic && asset.survey_q_asset_display_logic.length > 0) {
      // if (!secondary_asset_key) {
        // For any of the asset's display logic conditions
        for (let i = 0; i < asset.survey_q_asset_display_logic.length; i++) {
          // If the condition is of type "value_matches" - i.e. the asset on which this asset's display depends must have a value that exactly matches the condition
          if (asset.survey_q_asset_display_logic[i].logic_type === 'value_matches') {
            if (this.qAnswers.find(item => !item.unchecked && item.key == asset.survey_q_asset_display_logic[i].display_if_key && item.value == asset.survey_q_asset_display_logic[i].display_if_value && item.secondary_asset_key == secondary_asset_key)) {
              // Return true if such a value_matches condition is met
              // This exits the function immediately. If ANY condition for display is met, the asset is displayed
              return true;
            }
          }
          // If the condition is of type "value_matches" - i.e. the asset on which this asset's display depends must have any not-null, & not-blank, & non-zero value
          if (asset.survey_q_asset_display_logic[i].logic_type === 'value_exists') {
            if (this.qAnswers.find(item => !item.unchecked && item.key == asset.survey_q_asset_display_logic[i].display_if_key && item.value && item.value != '' && item.value != 0 && item.secondary_asset_key == secondary_asset_key)) {
              return true;
            }
          }
        }
        // If after looping through all display logic conditions the function has not returned true already, then return false
        return false;
      // } else {

      // }
    } else {
      // Return true in case no display logic is defined for this asset (visible by default)
      return true;
    }
  }

  /**
   * Check whether the "other" question should be disabled
   * @param question 
   * @param secondary_asset_key 
   */
  checkOtherAnswerQuestionDisplay(question, secondary_asset_key?) {
    if (question.other_questions && question.other_questions.length > 0 && question.options && question.options.length > 0) {
      const otherOption = question.options.find(item => item.qa_subtype == 'other');
      if (otherOption) {
        if (this.qAnswers.find(item => !item.unchecked && item.key == question.id && item.value == otherOption.id && item.secondary_asset_key == secondary_asset_key)) {
          return true;
        }
        return false;
      }
    }
  }

  /**
   * Populate the revisions dropdown based on the project's surveys & revisions
   * @param surveys 
   */
  setSurveysList(surveys): void {
    this.snapshotsListDataDropdown = [];
    if (surveys && surveys.length > 0) {
      const snapshotSurveys = surveys.filter(item => this.campaignId ? item.survey_type === 'campaign' && parseInt(item.flex1) === parseInt(this.campaignId)
      : item.survey_type === 'snapshot');
      for (let survey of snapshotSurveys) {
        if (survey.revisions && survey.revisions.length > 0) {
          survey.revisions = survey.revisions.reverse();
          for (let revision of survey.revisions) {
            const temp = {
              surveyId: survey.id,
              revisionId: revision.id,
              snapshot_name: survey.name + " " + survey.survey_type_sequence.toString() + "." + revision.revision_number
            };
            this.snapshotsListDataDropdown.push(temp);
          }
        }
      }
    }
  }

  /**
   * Change the revision (called when current revision is changed from the dropdown)
   */
  async changeRevision() {
    if (this.farmerId != 0) {
      this.spinner.show();
      if (this.selectedRevision != 0) {
        const survey = this.snapshotsListDataDropdown.find(item => item.revisionId == this.selectedRevision).surveyId;
        const discontinued = this.discontinuedFarmerMapping ? this.discontinuedFarmerMapping.find(item => item.survey_id == survey) : undefined;
        if(discontinued){
          this.discontinuedFarmer = true;
          this.spinner.hide();
          return;
        } else {
          this.discontinuedFarmer = false;
        }
        const request = await Promise.all([
          this.surveyService.getQAnswersForFarmerByRevision(this.farmerId, this.selectedRevision).toPromise(),
          this.surveyService.getSurveyQForProfilingService(survey).toPromise(),
        ]);

        if (request[0].code === 'success' && request[1].code === 'success') {
          if (request[0].message && request[0].message.length > 0) {
            if (request[1]['message'] !== 'notReleasedForLanguage') {
              this.questionnaire = request[1]['message'];
            } else {
              this.questionnaire = [];
            }
            this.setQuestionnaireAttributes();
            this.setQAnswersAndInitialize(request[0], true);
          } else {
            // added following 3 lines to show empty data
            if (request[1]['message'] !== 'notReleasedForLanguage') {
              this.questionnaire = request[1]['message'];
            } else {
              this.questionnaire = [];
            }
            this.setQuestionnaireAttributes();
            this.setQAnswersAndInitialize([], true);
            this.commonService.showToast('info', 'no_farmer_data_in_revision', {});
          }
        }
      } else {
        let request;
        this.discontinuedFarmer = this.farmerDiscontinueStatus;
        if (this.campaignId) {
          request = await this.campaignQuestionnaireFlowRevision();
        } else {
          request = await Promise.all([
            this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
            this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
          ]);
        }

        if (request[0].code === 'success' && request[1].code === 'success') {
          this.questionnaire = request[1]['message'];
          this.setQuestionnaireAttributes();
          
          this.setQAnswersAndInitialize(request[0], true);
        }
      }
      window.scroll(0,0);
      this.spinner.hide();
    }
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }
  getBackCompareData(){
    const url = 'charts/comparedata/' + this.projectId;
    this.router.navigate([url], { queryParams: { fromAnswer: true} });
  }

  /**
   * Set the village name of the current farmer
   */
  setVillageName(): void {
    let villageAreaList;
    if (this.project.ecosystem_region_master) {
      villageAreaList = this.project.ecosystem_region_master.region_villages;
    }
    if (villageAreaList && villageAreaList.length > 0) {
      const p = villageAreaList.find(item => item.id == this.farmer.village_area_id);
      if (p) {
        this.villageName = p.village_name;
      }
    }
  }

  scrollToTop() {
    $('html, body').animate({
      scrollTop: $('#mainDiv').scrollTop = 0
    }, 'slow');
  }

/**
 * Sort the default crop (the ecosystem crop) to the top of the agri income table
 * @param tableId - Number
*/
  sortDefaultCropToTop(table) : void {
    const tableId = table.id;
    const cropName = this.project.Product.name;
    const displayFunction = JSON.parse(table.displayFunction);
    const x = this.qAnswers.find(item => item.key == displayFunction.setQId && item.value == cropName);
    if (x) {
      // Set the value of this.defaultCropSak;
      this.defaultCropSak = x.secondary_asset_key;

      if (this.secondary_asset_keys && this.secondary_asset_keys[tableId] && this.secondary_asset_keys[tableId].length > 0) {
        const x = this.secondary_asset_keys[tableId].indexOf(this.defaultCropSak);
        if (x) {
          if (x === 0) {
            // do nothing
            return;
          } else {
            let temp = JSON.parse(JSON.stringify(this.secondary_asset_keys[tableId]));
            let p = temp[0];
            temp[0] = this.defaultCropSak;
            temp[x] = p;
            this.secondary_asset_keys[tableId] = JSON.parse(JSON.stringify(temp));
          }
        }
      }
    }
  }

  async campaignQuestionnaireFlow() {
    let r;
    if (navigator.onLine) {
      r = await Promise.all([
        this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
        this.projectService.getProjectProfilingData(this.projectId).toPromise(),
        this.projectService.getProjectDashboardData(this.projectId).toPromise(),
        this.surveyService.getSurveyQuestionnaireForCampaign(this.campaignId).toPromise(),
        this.dynamicQuestionnaireService.getQAnswersForFarmerForCampaign(this.campaignId, this.farmerId).toPromise(),
        this.farmerService.getFarmerBasicDataById(this.farmerId).toPromise(),
        this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
      ]);
    } else {
      r = await Promise.all([
        this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
        this.projectService.getProjectProfilingData(this.projectId).toPromise(),
        this.projectService.getProjectDashboardData(this.projectId).toPromise(),
        this.surveyService.getSurveyQuestionnaireForCampaign(this.campaignId).toPromise(),
        this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise(),
        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
      ]);
    }
    return r;
  }
  
  setBreadcrumbText(): void {
    this.translate.get('breadcrumbTitle').subscribe(result => {
      if (this.campaignId) {
        this.breadcrumbTitle = result['campaign'];
      }
      this.breadcrumbTitle = result['project'];
    });
  }
  
  goToProfilingTable(): void {
    if (this.campaignId) {
      this.router.navigate([`/campaign-profiling-farmers/${this.projectId}/${this.campaignId}`]);
    } else {
      this.router.navigate([`/profiling/${this.projectId}`]);
    }
  }

  async campaignQuestionnaireFlowRevision() {
    let r;
    if (navigator.onLine) {
      r = await Promise.all([
        this.dynamicQuestionnaireService.getQAnswersForFarmerForCampaign(this.campaignId, this.farmerId).toPromise(),
        this.surveyService.getSurveyQuestionnaireForCampaign(this.campaignId).toPromise(),
      ]);
    }
    return r;
  }
  
/**
 * Find which limits range the given farmerId falls in
 * E.g. if limits = [130, 150, 170] and farmerId = 165
 * limitsToReturn = [150, 170] would be returned
 * @param limits 
 * @param farmerId 
*/
  getPaginationLimits(limits, farmerId) {
    // console.log('limits', limits);
    // console.log('farmerId', farmerId);
    let limitsToReturn = [];
  
    for (let i = 0; i < limits.length; i++) {
      if (limits[i] <= farmerId && limits[i + 1] && limits[i + 1] > farmerId) {
        limitsToReturn[0] = limits[i];
        limitsToReturn[1] = limits[i+1];
        return limitsToReturn;
      } else if (!limits[i + 1]) {
        limitsToReturn[0] = limits[i];
        limitsToReturn[1] = undefined;
        return limitsToReturn;
      }
    }
  }

  //get farmer consent tempalte
  async getFarmerConsentTemplate(id, template){
    const requests = await Promise.all([
      this.dataConsentService.getConsentTemplate(this.projectId, id).toPromise()
    ]);
    if(requests[0].msg == 'success' && requests[0].data){
      this.consentTemplate = this.sanitizer.bypassSecurityTrustHtml(requests[0].data.template_content);
      this.privacyPolicyModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
    }

  }
  closePrivacyPolicy(){
    if(this.privacyPolicyModal){
      this.privacyPolicyModal.hide();
    }
  }

   @HostListener('window:keyup', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.closePrivacyPolicy();
    }
  }

  //download uploaded image
  downloadImage(qid, sak?){
    let answer;
    if(!sak){
      answer = this.qAnswers.find(item => item.key == qid);
    } else {
      answer = this.qAnswers.find(item => item.key == qid && item.secondary_asset_key == sak);
    }
    if(answer && answer.imgBase64){
      this.downloadFile(answer.imgBase64, answer.value);
    }
  }

  downloadFile(base64String, fileName) {
    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = fileName;
    link.click();
  }
}
