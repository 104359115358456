<app-header></app-header>
<div class="project-header clearfix pos-r">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-icon">
        <span class="btn-back" title="Back" i18n-title="@@back"  (click)="goToDashboards()" aria-current="page">
          <i class="utilicon utilicon-arrow-alt-left"></i>
        </span>
      </li>
      <li class="breadcrumb-icon">
        <span routerLink="/dashboard" class="btn-home" i18n-title="@@projectHome" title="Home"><img src="assets/images/home.webp" alt="Home" /></span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="getBackProjectFilter(project?.name, project?.id)">
        <span [attr.title]="project?.name">{{project?.name  | slice:0:15}}{{project?.name.length > 15 ? '...':''}}</span>
      </li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToReportingManagement(project?.id)"><span i18n-title="@@projectReporting" title="Project Reporting">...</span></li>
      <li class="breadcrumb-item cursorPointer" aria-current="page" (click)="goToDashboards()" *ngIf="!setAsDefault"><span title="Dashboards">...</span></li>
      <li class="breadcrumb-item active" aria-current="page"><span i18n="@@viewDashboardReports">View Dashboard Reports</span></li>
    </ol>
  </nav>
</div>

<div class="content-wrap content-wrapper background margin-btm-20" (window:resize)="onResize($event)">
  <div class="form-design form-box pb-0" [ngStyle]="{ display: reportAvailableFlag ? 'inherit' : 'none' }">
    <div class="reports-sec">
      <div class="row" *ngIf="dashboardData.length > 0">
        <div class="col-lg-12 form-group" style="margin-bottom: 0px;">
          <div class="dashboard-name" *ngIf="dashboardData.length > 0">{{dashboardData[0]?.name}}</div>
          <!-- <button type="button" name="button" class="btn btn-success btn-lg fltRgt datatable-header" (click)="downloadAsPDF()">
            <span i18n="@@downloadAsPDF">Download as PDF</span>
          </button> -->
          <button type="button" name="button" class="btn btn-success btn-lg fltRgt datatable-header mrlrfour" *ngIf="setAsDefault" (click)="goToDashboards()">
            <span i18n="@@viewAllDashboards">View All Dashboards</span>
          </button>
          <button type="button" name="button" class="btn btn-success btn-lg fltRgt datatable-header mrlrfour" *ngIf="setAsDefault" (click)="editDashboard(dashboardData[0])">
            <span i18n="@@edit">Edit</span>
          </button>
          <!-- <button type="button" name="button" class="btn btn-success btn-lg fltRgt datatable-header mrlrfour" *ngIf="setAsDefault" (click)="addNewDashboard()">
            <span>Add</span>
          </button> -->

          <div class="row mrlrzero">
            <div class="dashboard-desc" *ngIf="dashboardData.length > 0">{{dashboardData[0]?.description}}</div>
          </div>
          <!-- <button type="button" name="button" class="btn btn-success btn-lg fltRgt datatable-header" *ngIf="setAsDefault" (click)="downloadAsPDF()">
            <span>Download as PDF</span>
          </button>           -->
        </div>
      </div>
      <div [style.height.px]="height">
        <gridster [options]="options" id="gridsterBase" style="background-color:#fff; border: 1px solid #cdcdcd;">
          <gridster-item *ngFor="let item of layout"
            [item]="item" style="border:1px solid #cdcdcd; border-radius:4px;"

          >

          <div class="box-header-btns pull-right">
            <a title="Remove widget" (mousedown)="DashboardAddService.removeItem($event, item)"
            (touchstart)="DashboardAddService.removeItem($event, item)"><i class="glyphicon glyphicon-trash"></i></a>
          </div>
          <div>
            <div *ngIf="item.componentName == 'KPI'">
              <div *ngIf="item.src.is_multi else singleKPI">
                <div class="padding15-20">
                  <div class="row no-gutters">
                    <div class="col pos-r" *ngFor="let item2 of item.src.series; let i = index;">
                      <span *ngIf="i < item.src.series.length - 1" class="right-separator"></span>
                      <div class="align-center content-center" style="padding-top:13px">
                        <div class="inline-block text-center">
                          <img [src]="item2?.kpi_image != '' && item2?.kpi_image != null ? item2?.kpi_image : 'assets/online_images/default.svg'" alt="{{item2?.name}}"  style="max-height:48px; float:left"/>
                        </div>
                        <div class="inline-block text-center pl-3">
                          <span class="fnt-size-common-number">{{item2?.value == 'Less than 30 minutes' ? '	&lt; 30 minutes' : item2?.value == 'More than 1 hour' ? '&gt; 1 hour' : item2?.value}}</span><br/>
                          <span class="weight-400">{{item2?.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="align-center font-20 pb-2 pt-2 flex-center content-center">{{item.src?.name}}</div>
                </div>
              </div>
              <ng-template #singleKPI>
                <div class="padding15-20">
                  <div class="row flex-center no-gutters">
                    <div class="col-md-12 col-lg-4 align-center content-center">
                        <img [src]="item.src?.kpi_image != '' && item.src?.kpi_image != null ? item.src?.kpi_image : 'assets/online_images/default.svg'" alt="{{item.src?.name}}" style="max-height:67px" />
                    </div>
                    <div class="col-md-12 col-lg-8">
                      <div class="row no-gutters">
                        <div class="col-md-12 col-lg-12 flex-center content-center pb-3 pt-3">
                          <span class="fnt-size-common-number">{{item.src?.value == 'Less than 30 minutes' ? '	&lt; 30 minutes' : item.src?.value == 'More than 1 hour' ? '&gt; 1 hour' : item.src?.value}}</span>                          
                        </div>
                      </div>                  
                      <div class="row no-gutters">
                        <div class="col-md-12 col-lg-12 flex-center content-center pr-2 pl-2">
                          <div class="align-center font-20 flex-center height-48">{{item.src?.name}}</div>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
              </ng-template>
            </div>
            <div *ngIf="item.componentName == 'Chart'" class="highcharts-no-border" style="width:100%; height:300px">
              <highcharts-chart
                [ngStyle]="{'width':item.cols > 1 ? (chartWidth * item.cols) + 10 +'px' : chartWidth * item.cols +'px','height':'300px','display':'block'}"
                [Highcharts]="Highcharts"
                [constructorType]="'chart'"
                [options]="item.src"
                style="width: 100%; height: 300px; display: block;"
              ></highcharts-chart>

            </div>

           </div>
           <!-- <app-live-update-feature *ngIf="item.map" style='height: 100%; width: 100%;display:block; object-fit: cover'></app-live-update-feature> -->
          </gridster-item>



        </gridster>
    </div>
    <div *ngIf="layout.length > 0" class="pb-2">
      <span class="gray weight-400" style="font-size: 0.7rem;">* N/D = No Data</span>
    </div>
    </div>

  </div>
</div>
