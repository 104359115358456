<div class="row" [class.hide]="!consentTemplate">
  <div class="col-sm-12 text-left">
    <span class="form-check">
      <input type="checkbox" class="form-check-input"
        container="body" placement="right" [(ngModel)]="agree" (change)="getSignValues()">
      <label class="form-check-label mb-0" i18n="@@IAgreeTo">I agree to the</label>
      <label class="cursorPointer pl-1 mb-0" style="color: #007bff;" (click)="viewPrivacyPolicy(privacyPolicy)" i18n="@@privacyPolicy">Privacy Policy</label>
    </span>
  </div>
  <div class="col-sm-12 text-left posRel">
    <canvas #canvas id="canvas" name="signature"></canvas>
    <div class="pos-to-right">
      <span (click)="clearCanvas()">
        <i class="utilicon utilicon-times-circle-light"></i>
      </span>
    </div>
  </div>
</div>
<div *ngIf="!consentTemplate" class="text-left">
  <span class="font-small text-warning font-weight-normal" i18n="@@consentTemplateNotAvailable">Consent template is not available for this project</span>
</div>
<ng-template #privacyPolicy>
  <div class="modal-header" style="display: flow-root;">
    <button type="button" class="close pull-right" aria-label="Close" (click)="closePrivacyPolicy()">
      <i class="utilicon utilicon-times-light"></i>
    </button>
  </div>
  <div class="modal-body">
    <div style="overflow-x: auto" [innerHtml]="consentTemplate"></div>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="closePrivacyPolicy()" i18n="@@back">Back</button>
    </div>
  </div>
</ng-template>