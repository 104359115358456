import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DynamicQuestionnaireService = /** @class */ (function () {
    function DynamicQuestionnaireService(http) {
        this.http = http;
    }
    DynamicQuestionnaireService.prototype.getQAnswersForFarmer = function (projectId, farmerId) {
        return this.http.get(this.getUrl('/api/nq/getQAnswersForFarmer' + '/' + projectId + '/' + farmerId));
    };
    DynamicQuestionnaireService.prototype.saveProfilingData = function (qAnswers, farmerId, timestamp, stats) {
        return this.http.post(this.getUrl('/api/nq/saveProfilingData' + '/' + farmerId), { addedAt: timestamp, values: qAnswers, stats: stats });
    };
    DynamicQuestionnaireService.prototype.updateQuestionnaireAssetLabel = function (qAssetId, languageId, label) {
        return this.http.post(this.getUrl('/api/nq/updateQuestionnaireAssetLabel'), { id: qAssetId, languageId: languageId, label: label });
    };
    DynamicQuestionnaireService.prototype.saveProfilingDataOffline = function (answerValueObject) {
        return this.http.post(this.getUrl('/api/nq/saveProfilingDataOffline'), answerValueObject);
    };
    DynamicQuestionnaireService.prototype.saveProfilingStatsDataOffline = function (statsValueObject) {
        return this.http.post(this.getUrl('/api/nq/saveProfilingStatsDataOffline'), statsValueObject);
    };
    DynamicQuestionnaireService.prototype.getQAnswersForRevision = function (projectId, farmerId, revisionId) {
        return this.http.get(this.getUrl('/api/nq/getQAnswersForRevision' + '/' + projectId + '/' + farmerId + '/' + revisionId));
    };
    DynamicQuestionnaireService.prototype.getAssetDetails = function (assetId) {
        return this.http.get(this.getUrl('/api/nq/getAssetDetails' + '/' + assetId));
    };
    DynamicQuestionnaireService.prototype.getQAnswersForProject = function (projectId, nextMinFarmerId) {
        return this.http.get(this.getUrl('/api/nq/getQAnswersForProject' + '/' + projectId + '?minFarmerId=' + nextMinFarmerId));
    };
    DynamicQuestionnaireService.prototype.getUrl = function (name) {
        var url = environment.apiBaseUrl + name;
        //console.log('prjects' + url);
        return url;
    };
    DynamicQuestionnaireService.prototype.makeRevision = function (surveyId) {
        return this.http.get(this.getUrl('/api/nq/makeRevision' + '/' + surveyId));
    };
    DynamicQuestionnaireService.prototype.getAnswerStatsForProject = function (projectId) {
        return this.http.get(this.getUrl('/api/nq/getAnswerStatsForProject' + '/' + projectId));
    };
    DynamicQuestionnaireService.prototype.getPaginationLimits = function (projectId) {
        return this.http.get(this.getUrl('/api/nq/getPaginationLimits' + '/' + projectId));
    };
    DynamicQuestionnaireService.prototype.getQAnswersForProjectPaginated = function (projectId, gte, lt) {
        return this.http.get(this.getUrl('/api/nq/getQAnswersForProject' + '/' + projectId + '?gte=' + gte + '&lt=' + lt));
    };
    DynamicQuestionnaireService.prototype.getAnswerStatsForCampaign = function (campaignId) {
        return this.http.get(environment.apiBaseUrl + "/api/nq/getAnswerStatsForCampaign/" + campaignId);
    };
    DynamicQuestionnaireService.prototype.getQAnswersForFarmerForCampaign = function (campaignId, farmerId) {
        return this.http.get(this.getUrl("/api/nq/getQAnswersForFarmerForCampaign/" + campaignId + "/" + farmerId));
    };
    DynamicQuestionnaireService.prototype.saveProfilingDataForCampaign = function (qAnswers, farmerId, campaignId, timestamp, stats) {
        return this.http.post(this.getUrl("/api/nq/saveProfilingDataForCampaign/" + farmerId + "/" + campaignId), { addedAt: timestamp, values: qAnswers, stats: stats });
    };
    DynamicQuestionnaireService.prototype.getQAnswersForCampaignPaginated = function (campaignId, gte, lt) {
        return this.http.get(this.getUrl("/api/nq/getQAnswersForCampaign/" + campaignId + "?gte=" + gte + "&lt=" + lt));
    };
    //added by KRPT for performance issue
    DynamicQuestionnaireService.prototype.getSurveyQAssetIdsDashboard = function (surveyId) {
        return this.http.get(this.getUrl("/api/nq/getSurveyQAssetIdsDashboard/" + surveyId));
    };
    DynamicQuestionnaireService.prototype.getQAnswersForProjectPaginatedDashboard = function (projectId, gte, lt, surveyQIds) {
        var params = new HttpParams();
        params = params.append('surveyQIds', JSON.stringify(surveyQIds));
        return this.http.get(this.getUrl('/api/nq/getQAnswersForProjectDashboard' + '/' + projectId + '/' + '?gte=' + gte + '&lt=' + lt), { params: params });
    };
    DynamicQuestionnaireService.ngInjectableDef = i0.defineInjectable({ factory: function DynamicQuestionnaireService_Factory() { return new DynamicQuestionnaireService(i0.inject(i1.HttpClient)); }, token: DynamicQuestionnaireService, providedIn: "root" });
    return DynamicQuestionnaireService;
}());
export { DynamicQuestionnaireService };
