<app-header></app-header>
<div class="project-header clearfix">
  <nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#" i18n="@@projectHome" i18n-title="@@projectHome">Home</a></li>
      <li class="breadcrumb-item"><a href="#" routerLink="/administration" i18n="@@administration" i18n-title="@@administration">Administration</a></li>
      <li class="breadcrumb-item active" aria-current="page" i18n="@@ecosystemManagement">Ecosystem Management</li>
    </ol>
  </nav>
</div>


<div class="page-wrapper">
  <div class="content-wrap content-wrapper user-listing background">
    <div class="form-design form-box">
      <label class="group-title inline-block" i18n="@@ecosystemManagement">Ecosystem Management</label>

      <div class="col-md-7 form-group fltRgt datatable-header">
        <input type="text" [(ngModel)]="searchText" placeholder="Search Ecosystems" class="form-control"
          style="width: initial;height: 40px;display: inline-block" i18n-placeholder="@@searchEcosystems">
        <select class="form-control" [(ngModel)]="itemsPerPage" style="display: inline;width: fit-content;margin-top: 14px;margin-left: 10px;">
          <option class="option" value="10">10</option>
          <option class="option" value="20">20</option>
          <option class="option" value="50">50</option>
        </select>
      </div>
      <div class="clearfix"></div>
      <div style="overflow-x: auto">
        <table class="table table-sm table-hover table-one-line" role="grid" *ngIf="ecosystems?.length > 0">
          <thead>
            <tr role="row">
              <th (click)="sortBy('suppliername')"  style="width:21%"><span i18n="@@supplier">Supplier </span>
                <img class="sorting-arrow" alt="" *ngIf="isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSwxOTcuMjg3TDE1OS4wMjgsNjkuMzgxYy0zLjYxMy0zLjYxNy03Ljg5NS01LjQyNC0xMi44NDctNS40MjRzLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0TDUuNDI0LDE5Ny4yODcgICBDMS44MDcsMjAwLjkwNCwwLDIwNS4xODYsMCwyMTAuMTM0czEuODA3LDkuMjMzLDUuNDI0LDEyLjg0N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyNSwxMi44NSw1LjQyNWgyNTUuODEzICAgYzQuOTQ5LDAsOS4yMzMtMS44MDgsMTIuODQ4LTUuNDI1YzMuNjEzLTMuNjEzLDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N1MyOTAuNTQ4LDIwMC45MDQsMjg2LjkzNSwxOTcuMjg3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
                <img class="sorting-arrow" alt="" *ngIf="!isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSw2OS4zNzdjLTMuNjE0LTMuNjE3LTcuODk4LTUuNDI0LTEyLjg0OC01LjQyNEgxOC4yNzRjLTQuOTUyLDAtOS4yMzMsMS44MDctMTIuODUsNS40MjQgICBDMS44MDcsNzIuOTk4LDAsNzcuMjc5LDAsODIuMjI4YzAsNC45NDgsMS44MDcsOS4yMjksNS40MjQsMTIuODQ3bDEyNy45MDcsMTI3LjkwN2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyOCwxMi44NSw1LjQyOCAgIHM5LjIzMy0xLjgxMSwxMi44NDctNS40MjhMMjg2LjkzNSw5NS4wNzRjMy42MTMtMy42MTcsNS40MjctNy44OTgsNS40MjctMTIuODQ3QzI5Mi4zNjIsNzcuMjc5LDI5MC41NDgsNzIuOTk4LDI4Ni45MzUsNjkuMzc3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
              </th>
              <th (click)="sortBy('productname')"  style="width:21%"><span i18n="@@product">Product </span>
                  <img class="sorting-arrow"alt="" *ngIf="isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSwxOTcuMjg3TDE1OS4wMjgsNjkuMzgxYy0zLjYxMy0zLjYxNy03Ljg5NS01LjQyNC0xMi44NDctNS40MjRzLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0TDUuNDI0LDE5Ny4yODcgICBDMS44MDcsMjAwLjkwNCwwLDIwNS4xODYsMCwyMTAuMTM0czEuODA3LDkuMjMzLDUuNDI0LDEyLjg0N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyNSwxMi44NSw1LjQyNWgyNTUuODEzICAgYzQuOTQ5LDAsOS4yMzMtMS44MDgsMTIuODQ4LTUuNDI1YzMuNjEzLTMuNjEzLDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N1MyOTAuNTQ4LDIwMC45MDQsMjg2LjkzNSwxOTcuMjg3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
                  
                  <img class="sorting-arrow"alt="" *ngIf="!isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSw2OS4zNzdjLTMuNjE0LTMuNjE3LTcuODk4LTUuNDI0LTEyLjg0OC01LjQyNEgxOC4yNzRjLTQuOTUyLDAtOS4yMzMsMS44MDctMTIuODUsNS40MjQgICBDMS44MDcsNzIuOTk4LDAsNzcuMjc5LDAsODIuMjI4YzAsNC45NDgsMS44MDcsOS4yMjksNS40MjQsMTIuODQ3bDEyNy45MDcsMTI3LjkwN2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyOCwxMi44NSw1LjQyOCAgIHM5LjIzMy0xLjgxMSwxMi44NDctNS40MjhMMjg2LjkzNSw5NS4wNzRjMy42MTMtMy42MTcsNS40MjctNy44OTgsNS40MjctMTIuODQ3QzI5Mi4zNjIsNzcuMjc5LDI5MC41NDgsNzIuOTk4LDI4Ni45MzUsNjkuMzc3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
                </th>
                <th (click)="sortBy('xcoordinate')"  style="width:21%"><span i18n="@@projectStatus">X Coordinate </span>
                   
                    <img class="sorting-arrow" alt="" *ngIf="isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSwxOTcuMjg3TDE1OS4wMjgsNjkuMzgxYy0zLjYxMy0zLjYxNy03Ljg5NS01LjQyNC0xMi44NDctNS40MjRzLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0TDUuNDI0LDE5Ny4yODcgICBDMS44MDcsMjAwLjkwNCwwLDIwNS4xODYsMCwyMTAuMTM0czEuODA3LDkuMjMzLDUuNDI0LDEyLjg0N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyNSwxMi44NSw1LjQyNWgyNTUuODEzICAgYzQuOTQ5LDAsOS4yMzMtMS44MDgsMTIuODQ4LTUuNDI1YzMuNjEzLTMuNjEzLDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N1MyOTAuNTQ4LDIwMC45MDQsMjg2LjkzNSwxOTcuMjg3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
                    
                    <img class="sorting-arrow" alt="" *ngIf="!isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSw2OS4zNzdjLTMuNjE0LTMuNjE3LTcuODk4LTUuNDI0LTEyLjg0OC01LjQyNEgxOC4yNzRjLTQuOTUyLDAtOS4yMzMsMS44MDctMTIuODUsNS40MjQgICBDMS44MDcsNzIuOTk4LDAsNzcuMjc5LDAsODIuMjI4YzAsNC45NDgsMS44MDcsOS4yMjksNS40MjQsMTIuODQ3bDEyNy45MDcsMTI3LjkwN2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyOCwxMi44NSw1LjQyOCAgIHM5LjIzMy0xLjgxMSwxMi44NDctNS40MjhMMjg2LjkzNSw5NS4wNzRjMy42MTMtMy42MTcsNS40MjctNy44OTgsNS40MjctMTIuODQ3QzI5Mi4zNjIsNzcuMjc5LDI5MC41NDgsNzIuOTk4LDI4Ni45MzUsNjkuMzc3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
                  </th>
                  <th (click)="sortBy('ycoordinate')"  style="width:21%"><span i18n="@@typeOfProducts">Y Coordinate </span>
                      
                      <img class="sorting-arrow" alt="" *ngIf="isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSwxOTcuMjg3TDE1OS4wMjgsNjkuMzgxYy0zLjYxMy0zLjYxNy03Ljg5NS01LjQyNC0xMi44NDctNS40MjRzLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0TDUuNDI0LDE5Ny4yODcgICBDMS44MDcsMjAwLjkwNCwwLDIwNS4xODYsMCwyMTAuMTM0czEuODA3LDkuMjMzLDUuNDI0LDEyLjg0N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyNSwxMi44NSw1LjQyNWgyNTUuODEzICAgYzQuOTQ5LDAsOS4yMzMtMS44MDgsMTIuODQ4LTUuNDI1YzMuNjEzLTMuNjEzLDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N1MyOTAuNTQ4LDIwMC45MDQsMjg2LjkzNSwxOTcuMjg3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
                      
                      <img class="sorting-arrow" alt="" *ngIf="!isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSw2OS4zNzdjLTMuNjE0LTMuNjE3LTcuODk4LTUuNDI0LTEyLjg0OC01LjQyNEgxOC4yNzRjLTQuOTUyLDAtOS4yMzMsMS44MDctMTIuODUsNS40MjQgICBDMS44MDcsNzIuOTk4LDAsNzcuMjc5LDAsODIuMjI4YzAsNC45NDgsMS44MDcsOS4yMjksNS40MjQsMTIuODQ3bDEyNy45MDcsMTI3LjkwN2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyOCwxMi44NSw1LjQyOCAgIHM5LjIzMy0xLjgxMSwxMi44NDctNS40MjhMMjg2LjkzNSw5NS4wNzRjMy42MTMtMy42MTcsNS40MjctNy44OTgsNS40MjctMTIuODQ3QzI5Mi4zNjIsNzcuMjc5LDI5MC41NDgsNzIuOTk4LDI4Ni45MzUsNjkuMzc3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
                    </th>
                    <th (click)="sortBy('marker_description')"  style="width:21%"><span i18n="@@supplier">Marker Description </span>
                        
                        <img class="sorting-arrow" alt="" *ngIf="isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSwxOTcuMjg3TDE1OS4wMjgsNjkuMzgxYy0zLjYxMy0zLjYxNy03Ljg5NS01LjQyNC0xMi44NDctNS40MjRzLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0TDUuNDI0LDE5Ny4yODcgICBDMS44MDcsMjAwLjkwNCwwLDIwNS4xODYsMCwyMTAuMTM0czEuODA3LDkuMjMzLDUuNDI0LDEyLjg0N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyNSwxMi44NSw1LjQyNWgyNTUuODEzICAgYzQuOTQ5LDAsOS4yMzMtMS44MDgsMTIuODQ4LTUuNDI1YzMuNjEzLTMuNjEzLDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N1MyOTAuNTQ4LDIwMC45MDQsMjg2LjkzNSwxOTcuMjg3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
                        
                        <img class="sorting-arrow" alt="" *ngIf="!isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSw2OS4zNzdjLTMuNjE0LTMuNjE3LTcuODk4LTUuNDI0LTEyLjg0OC01LjQyNEgxOC4yNzRjLTQuOTUyLDAtOS4yMzMsMS44MDctMTIuODUsNS40MjQgICBDMS44MDcsNzIuOTk4LDAsNzcuMjc5LDAsODIuMjI4YzAsNC45NDgsMS44MDcsOS4yMjksNS40MjQsMTIuODQ3bDEyNy45MDcsMTI3LjkwN2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyOCwxMi44NSw1LjQyOCAgIHM5LjIzMy0xLjgxMSwxMi44NDctNS40MjhMMjg2LjkzNSw5NS4wNzRjMy42MTMtMy42MTcsNS40MjctNy44OTgsNS40MjctMTIuODQ3QzI5Mi4zNjIsNzcuMjc5LDI5MC41NDgsNzIuOTk4LDI4Ni45MzUsNjkuMzc3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
                      </th>
              <th (click)="sortBy('status')"  style="width:21%"><span i18n="@@status2">Status </span>
               
                <img class="sorting-arrow" alt="" *ngIf="isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSwxOTcuMjg3TDE1OS4wMjgsNjkuMzgxYy0zLjYxMy0zLjYxNy03Ljg5NS01LjQyNC0xMi44NDctNS40MjRzLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0TDUuNDI0LDE5Ny4yODcgICBDMS44MDcsMjAwLjkwNCwwLDIwNS4xODYsMCwyMTAuMTM0czEuODA3LDkuMjMzLDUuNDI0LDEyLjg0N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyNSwxMi44NSw1LjQyNWgyNTUuODEzICAgYzQuOTQ5LDAsOS4yMzMtMS44MDgsMTIuODQ4LTUuNDI1YzMuNjEzLTMuNjEzLDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N1MyOTAuNTQ4LDIwMC45MDQsMjg2LjkzNSwxOTcuMjg3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
                
                <img class="sorting-arrow" alt="" *ngIf="!isAscendingId" height="10px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+Cgk8cGF0aCBkPSJNMjg2LjkzNSw2OS4zNzdjLTMuNjE0LTMuNjE3LTcuODk4LTUuNDI0LTEyLjg0OC01LjQyNEgxOC4yNzRjLTQuOTUyLDAtOS4yMzMsMS44MDctMTIuODUsNS40MjQgICBDMS44MDcsNzIuOTk4LDAsNzcuMjc5LDAsODIuMjI4YzAsNC45NDgsMS44MDcsOS4yMjksNS40MjQsMTIuODQ3bDEyNy45MDcsMTI3LjkwN2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyOCwxMi44NSw1LjQyOCAgIHM5LjIzMy0xLjgxMSwxMi44NDctNS40MjhMMjg2LjkzNSw5NS4wNzRjMy42MTMtMy42MTcsNS40MjctNy44OTgsNS40MjctMTIuODQ3QzI5Mi4zNjIsNzcuMjc5LDI5MC41NDgsNzIuOTk4LDI4Ni45MzUsNjkuMzc3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" />
              </th>
              <th i18n="@@actions" style="width:10%" i18n="@@actions">Actions </th>
            </tr>
          </thead>
          <tbody>
            <tr class="gradeA odd" *ngFor="let item of ecosystems | gridFilter: getGridFilters() | paginate: { itemsPerPage: itemsPerPage, currentPage: page} ; let i = index">
              <td class="wrap-text">{{item?.suppliername}}</td>
              <td clsss="wrap-text">{{item?.productname}}</td>
              <td>{{item?.xcoordinate}}</td>
              <td>{{item?.ycoordinate}}</td>
              <td>{{item?.marker_description}}</td>
              <td>{{item?.status}}</td>
              <td>
                <a title="Add User" (click)="addEcoUserModal(addUserTemplate,item?.ecosystem?.id, false); $event.stopPropagation()"
                  class="edit-btn" style="display: inline-flex; color:white" i18n="@@addUser" i18n-title="@@addUser">
                  Add User
                </a>
              </td>
            </tr>
            <tr *ngIf="ecosystems?.length <=0 " style="width:100% " i18n="@@noRecordsAvailable">
              No records available
            </tr>
          </tbody>
        </table>
        <pagination-controls [previousLabel]="previousPaginationLabel " [nextLabel]="nextPaginationLabel " *ngIf="ecosystems
                && ecosystems?.length> 0" (pageChange)="page = $event" style="align-self: center;">
        </pagination-controls>
      </div>
    </div>

    <ng-template #addUserTemplate>
        <form [formGroup]="ecoSystemUserForm" (ngSubmit)="addUserToEcosystemSubmit(ecoSystemUserForm,$event)" autocomplete="off" autofocus="on" *ngIf="confirmModal">
          <div class="modal-header">
            <button type="button" class="close" i18n-title="@@close" (click)="hideAddUserModal(ecoSystemUserForm); $event.stopPropagation()">
              <i class="utilicon utilicon-times-light"></i>
            </button>
            <h4 class="modal-title" id="myModalLabel">
              <span i18n="@@addUserToEcosystem">ADD USER TO ECOSYSTEM</span>
            </h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12" *ngFor="let userData of ecoSystemData?.data; let i = index;">
                <div *ngIf="userData?.is_visible === 'Y'">
                <div [ngSwitch]="userData?.field_type">
                  <div *ngSwitchCase="'text'">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{userData?.field_label}}</span>
                        <span class="mandatory" *ngIf="userData?.mandatory === 'Y'"> *</span>
                      </label>
                      <span *ngIf="userData?.sequence === 1; else doNotAutofocus">
                        <input type="text" autofocus appAutofocus name="suppliername" class="form-control" [attr.disabled]="userData?.readonly != 'N' ? true : null"
                          [formControlName]="userData?.id" placeholder="{{userData?.field_label}}" [attr.minLength]="userData?.validations?.minlength" [attr.maxLength]="userData?.validations?.maxlength">
                      </span>
                      <ng-template #doNotAutofocus>
                        <input type="text" name="suppliername" class="form-control" [attr.disabled]="userData?.readonly != 'N' ? true : null"
                          [formControlName]="userData?.id" placeholder="{{userData?.field_label}}" [attr.maxLength]="userData?.validations?.maxlength">
                      </ng-template>
                      <div *ngIf="ecoSystemUserForm.controls[userData.id].invalid && (ecoSystemUserForm.controls[userData.id].dirty || f1Submitted)">
                        <div class="error" *ngIf="ecoSystemUserForm.controls[userData.id].hasError('required')">
                          {{userData?.field_label}} <span i18n="@@isRequiredInt">is required</span></div>
                        <div class="error" *ngIf="ecoSystemUserForm.controls[userData.id].hasError('minlength')"><span i18n="@@pleaseEnterAtleast">Please enter atleast</span> {{userData.validations.minlength}} <span i18n="@@cherectersLong">characters long</span></div>
                        <div class="error" *ngIf="ecoSystemUserForm.controls[userData.id].hasError('maxlength')"><span i18n="@@pleaseEnterLessThanThe">Please enter less than the</span> {{userData.validations.maxlength}} <span i18n="@@cherectersLong">characters long</span></div>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="'textarea'">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{userData?.field_label}}</span>
                        <span class="mandatory" *ngIf="userData?.mandatory === 'Y'"> *</span>
                      </label>
                      <textarea id="textarea-result" class="form-control has-counter" #textareavalue [attr.disabled]="userData?.readonly != 'N' ? true : null"
                        [formControlName]="userData?.id" placeholder="{{userData?.field_label}}" [attr.minLength]="userData?.validations?.minlength" [attr.maxLength]="userData?.validations?.maxlength"></textarea>
                      <div class="textarea-counter float-right">
                        <span class="value">{{textareavalue?.value?.length}}</span>/{{userData?.validations?.maxlength}}
                      </div>
                      <div *ngIf="ecoSystemUserForm.controls[userData.id].invalid && (ecoSystemUserForm.controls[userData.id].dirty || f1Submitted)">
                        <div class="error" *ngIf="ecoSystemUserForm.controls[userData.id].errors.required">
                          {{userData?.field_label}} <span i18n="@@isRequiredInt">is required</span></div>
                        <div class="error" *ngIf="ecoSystemUserForm.controls[userData.id].hasError('minlength')"><span i18n="@@pleaseEnterAtleast">Please enter atleast</span>  {{userData.validations.minlength}} <span i18n="@@cherectersLong">characters long</span></div>
                        <div class="error" *ngIf="ecoSystemUserForm.controls[userData.id].hasError('maxlength')"><span i18n="@@pleaseEnterLessThanThe">Please enter less than the</span> {{userData.validations.maxlength}} <span i18n="@@cherectersLong">characters long</span></div>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="'dropdown'">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{userData?.field_label}}</span>
                        <span class="mandatory" *ngIf="userData?.mandatory === 'Y'"> *</span>
                      </label>
                      <div *ngIf="userData?.value_options === null">
                        <span *ngIf="userData?.sequence === 1; else doNotAutofocus">
                          <select class="form-control" autofocus appAutofocus [attr.disabled]="userData?.readonly != 'N' ? true : null" (change)="setUserType(userData?.form_code, userData?.mapping_table_field)"
                            [name]="userData?.id" [formControlName]="userData?.id">
                            <option *ngFor="let item of countryData[userData?.id]?.data" [value]="item.id" selected="selected" [attr.disabled]="item?.status == 'Inactive' ? true : null"
                              [ngStyle]="{'background-color': item?.status == 'Inactive' ? '#c1c1c1' : null, 'color': item?.status == 'Inactive' ? '#D80431' : null, 'cursor': item?.status == 'Inactive' ? 'not-allowed' : null}">
                              {{item.name}}
                            </option>
                          </select>
                        </span>
                        <ng-template #doNotAutofocus>
                          <select class="form-control" [attr.disabled]="userData?.readonly != 'N' ? true : null" (change)="setUserType(userData?.form_code, userData?.mapping_table_field)"
                            [name]="userData?.id" [formControlName]="userData?.id">
                            <option *ngFor="let item of countryData[userData?.id]?.data" [value]="item.id" selected="selected" [attr.disabled]="item?.status == 'Inactive' ? true : null"
                              [ngStyle]="{'background-color': item?.status == 'Inactive' ? '#c1c1c1' : null,'color': item?.status == 'Inactive' ? '#D80431' : null, 'cursor': item?.status == 'Inactive' ? 'not-allowed' : null}">
                              {{item.name}}
                            </option>
                          </select>
                        </ng-template>
                      </div>
                      <div *ngIf="userData?.value_options != null">
                        <span *ngIf="userData?.sequence === 1; else doNotAutofocus">
                          <select class="form-control" autofocus appAutofocus [name]="userData?.id" [attr.disabled]="userData?.readonly != 'N' ? true : null"
                            [formControlName]="userData?.id">
                            <option *ngFor="let item of userData?.valueOptions" [value]="item.id" [selected]="selected">
                              {{item.name}}
                            </option>
                          </select>
                        </span>
                        <ng-template #doNotAutofocus>
                          <select class="form-control" [name]="userData?.id" [formControlName]="userData?.id" [attr.disabled]="userData?.readonly != 'N' ? true : null">
                            <option *ngFor="let item of userData?.valueOptions" [value]="item.id" [selected]="selected">
                              {{item.name}}
                            </option>
                          </select>
                        </ng-template>
                      </div>
                      <div *ngIf="ecoSystemUserForm.controls[userData.id].invalid && (ecoSystemUserForm.controls[userData.id].dirty || f1Submitted)">
                        <div class="error" *ngIf="ecoSystemUserForm?.controls[userData.id].errors.required">
                          {{userData?.field_label}} <span i18n="@@isRequiredInt">is required</span></div>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="'autoselect'">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{userData?.field_label}}</span>
                        <span class="mandatory" *ngIf="userData?.mandatory === 'Y'"> *</span>
                      </label>
                      <div *ngIf="userData?.value_options === null">
                        <span *ngIf="userData?.sequence === 1; else doNotAutofocus">
                          <input [formControlName]="userData?.id" [typeahead]="autoSelectData[userData?.id]?.data" typeaheadOptionField="name"
                            [typeaheadOptionsLimit]="9999" [typeaheadMinLength]="0" placeholder="Please select from the list or type" i18n-placeholder="@@selectFromListOrType"
                            class="form-control" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="10" (typeaheadOnSelect)="onSelect($event)">
                        </span>
                        <ng-template #doNotAutofocus>
                          <input [formControlName]="userData?.id" [typeahead]="autoSelectData[userData?.id]?.data" typeaheadOptionField="name"
                            [typeaheadOptionsLimit]="9999" [typeaheadMinLength]="0" placeholder="Please select from the list or type"  i18n-placeholder="@@selectFromListOrType"
                            class="form-control" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="10" (typeaheadOnSelect)="onSelect($event)">
                        </ng-template>
                      </div>
                      <div *ngIf="userData?.value_options != null">
                        <span *ngIf="userData?.sequence === 1; else doNotAutofocus">
                          <select class="form-control" autofocus appAutofocus [name]="userData?.id" [formControlName]="userData?.id" [attr.disabled]="userData?.readonly != 'N' ? true : null">
                            <option *ngFor="let item of userData?.valueOptions" [value]="item.id">
                              {{item.name}}
                            </option>
                          </select>
                        </span>
                        <ng-template #doNotAutofocus>
                          <select class="form-control" [name]="userData?.id" [formControlName]="userData?.id" [attr.disabled]="userData?.readonly != 'N' ? true : null">
                            <option *ngFor="let item of userData?.valueOptions" [value]="item.id">
                              {{item.name}}
                            </option>
                          </select>
                        </ng-template>
                      </div>
                      <div *ngIf="ecoSystemUserForm.controls[userData.id].invalid && (ecoSystemUserForm.controls[userData.id].dirty || f1Submitted)">
                        <div class="error" *ngIf="ecoSystemUserForm?.controls[userData.id].errors.required">
                          {{userData?.field_label}} <span i18n="@@isRequiredInt">is required</span></div>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="'multiselect'">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{userData?.field_label}}</span>
                        <span class="mandatory" *ngIf="userData?.mandatory === 'Y'"> *</span>
                      </label>
                      <div *ngIf="userData?.value_options === null">
                        <span *ngIf="userData?.sequence === 1; else doNotAutofocus">
                          <ng-multiselect-dropdown [placeholder]="selectPlaceholder" [data]="multiSelectData[userData?.id]?.data" [formControlName]="userData?.id"
                            [settings]="dropdownSettings" (onSelect)="onMultiSelect($event,userData?.id)" (onSelectAll)="onSelectAll($event,userData?.id)"
                            (onDeSelect)="onDeSelect($event,userData?.id)" (onDeSelectAll)="onDeSelectAll($event,userData?.id)"
                            name="receive_service_benefit">
                          </ng-multiselect-dropdown>
                        </span>
                        <ng-template #doNotAutofocus>
                          <ng-multiselect-dropdown [placeholder]="selectPlaceholder" [data]="multiSelectData[userData?.id]?.data" [formControlName]="userData?.id"
                            [settings]="dropdownSettings" (onSelect)="onMultiSelect($event,userData?.id)" (onSelectAll)="onSelectAll($event,userData?.id)"
                            (onDeSelect)="onDeSelect($event,userData?.id)" (onDeSelectAll)="onDeSelectAll($event,userData?.id)"
                            name="receive_service_benefit">
                          </ng-multiselect-dropdown>
                        </ng-template>
                      </div>
                      <div *ngIf="ecoSystemUserForm.controls[userData.id].invalid && (ecoSystemUserForm.controls[userData.id].dirty || f1Submitted)">
                        <div class="error" *ngIf="ecoSystemUserForm?.controls[userData.id].errors.required">
                          {{userData?.field_label}} <span i18n="@@isRequiredInt">is required</span></div>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="'date'">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{userData?.field_label}}</span>
                        <span class="mandatory" *ngIf="userData?.mandatory === 'Y'"> *</span>
                      </label>
                      <div class="dateIcon" (click)="isOpen = !isOpen">
                        <input name="doe" bsDatepicker placement="top" [bsConfig]="{ dateInputFormat: dateFormat, showWeekNumbers: false, containerClass: 'theme-dark-blue' }"
                          [isOpen]="isOpen" class="form-control" [formControlName]="userData?.id" placeholder="DD-MM-YYYY"
                          readonly [attr.disabled]="userData?.readonly != 'N' ? true : null" [isDisabled]="userData?.readonly != 'N' ? true : false">
                        <i class="utilicon utilicon-calendar-alt"></i>
                      </div>
                      <div *ngIf="ecoSystemUserForm.controls[userData.id].invalid && (ecoSystemUserForm.controls[userData.id].dirty || f1Submitted)">
                        <div class="error" *ngIf="ecoSystemUserForm?.controls[userData.id].errors.required">
                          {{userData?.field_name}} <span i18n="@@isRequiredInt">is required</span></div>
                        
                      </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="'radio'">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{userData?.field_label}}</span>
                        <span class="mandatory" *ngIf="userData?.mandatory === 'Y'"> *</span>
                      </label>`
                      <div class="form-check" *ngFor="let radioOption of userData?.value_options">
                        <input type="radio" class="form-check-input" id="{{userData?.id + radioOption?.value}}" [value]="radioOption?.value"
                          [formControlName]="surveyQuestionData.id" [attr.disabled]="userData?.readonly != 'N' ? true : null">
                        <label class="form-check-label" for="{{userData?.id + radioOption?.value}}" tabindex="0">{{radioOption?.description}}</label>
                      </div>
                      <div *ngIf="ecoSystemUserForm.controls[userData.id].invalid && (ecoSystemUserForm.controls[userData.id].dirty || f1Submitted)">
                        <div class="error" *ngIf="ecoSystemUserForm?.controls[userData.id].errors.required">
                          {{userData?.field_label}} <span i18n="@@isRequiredInt">is required</span></div>
                      </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="'checkbox'">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{userData?.field_name}}</span>
                        <span class="mandatory" *ngIf="userData?.mandatory === 'Y'"> *</span>
                      </label>`
                      <div class="form-check" *ngFor="let radioOption of userData?.value_options">
                        <input type="checkbox" class="form-check-input" id="{{userData?.id + radioOption?.value}}" [value]="radioOption?.value"
                          [formControlName]="surveyQuestionData.id" [attr.disabled]="userData?.readonly != 'N' ? true : null">
                        <label class="form-check-label" for="{{userData?.id + radioOption?.value}}" tabindex="0">{{radioOption?.description}}</label>
                      </div>
                      <div *ngIf="ecoSystemUserForm.controls[userData.id].invalid && (ecoSystemUserForm.controls[userData.id].dirty || f1Submitted)">
                        <div class="error" *ngIf="ecoSystemUserForm?.controls[userData.id].errors.required">
                          {{userData?.field_name}} <span i18n="@@isRequiredInt">is required</span></div>
                      </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="'email'">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{userData?.field_label}}</span>
                        <span class="mandatory" *ngIf="userData?.mandatory === 'Y'"> *</span>
                      </label>
                      <input type="email" name="suppliername" class="form-control" [formControlName]="userData?.id" placeholder="{{userData?.field_label}}"  [attr.minLength]="userData?.validations?.minlength" [attr.maxLength]="userData?.validations?.maxlength"
                        [attr.disabled]="userData?.readonly != 'N' ? true : null">
                      <div *ngIf="ecoSystemUserForm.controls[userData.id].invalid && (ecoSystemUserForm.controls[userData.id].dirty || f1Submitted)">
                        <div class="error" *ngIf="ecoSystemUserForm.controls[userData.id].hasError('required')">
                          {{userData?.field_label}} <span i18n="@@isRequiredInt">is required</span></div>
                        <div class="error" *ngIf="ecoSystemUserForm.controls[userData.id].hasError('email')" i18n="@@enterValidEmailId">Please enter the valid email
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="'number'">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{userData?.field_label}}</span>
                        <span class="mandatory" *ngIf="userData?.mandatory === 'Y'"> *</span>
                      </label>
                      <input type="text" name="suppliername" class="form-control" [formControlName]="userData?.id" placeholder="{{userData?.field_label}}"
                        [attr.disabled]="userData?.readonly != 'N' ? true : null" minlength="5" [attr.maxLength]="userData?.validations?.maxlength">
                      <div *ngIf="ecoSystemUserForm.controls[userData.id].invalid && (ecoSystemUserForm.controls[userData.id].dirty || f1Submitted)">
                        <div class="error" *ngIf="ecoSystemUserForm.controls[userData.id].hasError('required')">
                          {{userData?.field_label}} <span i18n="@@isRequiredInt">is required</span></div>
                        <div class="error" *ngIf="ecoSystemUserForm.controls[userData.id].hasError('pattern')"><span i18n="@@enterNumberOnly">Please enter numbers</span> (min 5 to {{userData?.validations?.maxlength}})</div>
                      </div>
                    </div>
                  </div>
                  <div class="partner-services clearfix" *ngIf="this.origin === 'ECOSYSTEM_SERVICES_COMPONENTS_MAPPING_MDM'">
                    <div class=" table-wrapper ng-star-inserted">
                      <!--Enrollement Start-->
                      <table class="table table-hover table-one-line">
                        <tbody>
                          <tr>
                              <td>
                                <strong i18n="@@servicesAndComponents">
                                  <i class="utilicon"></i>
                                  Services &amp; Components
                                </strong>
                              </td>
                              <td>
                                <span class="form-check">
                                  <span>
                                    <input type="checkbox" class="form-check-input" container="body" (change)="psmCheckedAll($event)">
                                    <label class="form-check-label"> &nbsp;</label>
                                  </span>
                                </span>
                              </td>
                            </tr>
                          <tr *ngFor="let item of masterServiceComponentArray" [class.noComponents]="item.noComponents" [class.comp-open]="!item.services_id && item.opened"
                            [class.comp-closed]="!item.services_id && !item.opened">
                            <td *ngIf="!item.services_id || rowsToShow.indexOf(item.id) > -1">
                              <strong (click)="serviceOpenCloseToggle(item)" [class.parent-mapping]="!item.services_id" [class.child-mapping]="item.services_id">
                                <i class="utilicon"></i>
                                {{item.name}}
                              </strong>
                            </td>
                            <td *ngIf="!item.services_id || rowsToShow.indexOf(item.id) > -1">
                              <span class="form-check" *ngIf="item.displaycheck; else isParentService">
                                <span>
                                  <input type="checkbox" class="form-check-input" [checked]="psmCheckedArray.indexOf(item.uniqueid) > -1" container="body" placement="right" (change)="psmChecked($event, item)">
                                  <label class="form-check-label"> &nbsp;</label>
                                </span>
                              </span>
                              <ng-template #isParentService>
                                  <span class="form-check">
                                    <span>
                                      <input type="checkbox" class="form-check-input" container="body" [checked]="psmCheckedArray.indexOf(item.uniqueid) > -1" (change)="psmCheckedParentService($event, item)">
                                      <label class="form-check-label"> &nbsp;</label>
                                    </span>
                                  </span>
                                </ng-template>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!--Enrollment End-->
                    </div>
                  </div>
                  <div class="partner-services clearfix" *ngIf="this.origin === 'ECOSYSTEM_USER_TYPE_MAPPING_MDM'">
                    <div class=" table-wrapper ng-star-inserted">
                      <!--Enrollement Start-->
                      <table class="table table-hover table-one-line">
                        <tbody>
                          <tr>
                              <td>
                                <strong i18n="@@userTypes">
                                  <i class="utilicon"></i>
                                  User Types
                                </strong>
                              </td>
                              <td>
                                <span class="form-check">
                                  <span>
                                    <input type="checkbox" class="form-check-input" container="body" (change)="userTypeCheckedAll($event)">
                                    <label class="form-check-label"> &nbsp;</label>
                                  </span>
                                </span>
                              </td>
                            </tr>
                          <tr *ngFor="let item of userTypes">
                            <td>{{item.name}}</td>
                            <td>
                              <span class="form-check">
                                <input type="checkbox" class="form-check-input" container="body" placement="right" [checked]="userTypeCheckedForAdd.indexOf(item.uniqueid) > -1" (change)="userTypeChecked($event, item)">
                                <label class="form-check-label"> &nbsp;</label>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!--Enrollment End-->
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="submit" [disabled]="!ecoSystemUserForm.valid" id="usrcreatebtn" class="btn btn-primary" i18n="@@add" i18n-title="@@add">Add</button>
            <button type="button" (click)="hideAddUserModal(ecoSystemUserForm); $event.stopPropagation()" class="btn btn-secondary" data-dismiss="modal"
              i18n="@@cancel" i18n-title="@@cancel">Cancel</button>
          </div>
        </form>
        <div *ngIf="!confirmModal">
          <div class="modal-body text-center">
            <h3 i18n="@@areSureWantToClose">
              Are you sure, do you want to close?
            </h3>
            <button type="button" class="btn btn-secondary mr-1" (click)="confirmModalYes('add')" i18n="@@yes" i18n-title="@@yes">Yes</button>
            <button type="button" class="btn btn-primary cancel-focus" autofocus appAutofocus (click)="confirmModalNo('add')" i18n="@@no" i18n-title="@@no">No</button>
          </div>
        </div>

      </ng-template>

  </div>
</div>

