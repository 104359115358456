import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HighchartReportsService } from '../../../services/highchart-reports.service';
var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/cylinder')(Highcharts);
require('highcharts/modules/funnel3d')(Highcharts);
require('highcharts/modules/pyramid3d')(Highcharts);
import $ from 'jquery';
var GoodFarmingPracticesComponent = /** @class */ (function () {
    function GoodFarmingPracticesComponent(route, projectService, router, activeRoute, hcRService, spinner, renderer) {
        this.route = route;
        this.projectService = projectService;
        this.router = router;
        this.activeRoute = activeRoute;
        this.hcRService = hcRService;
        this.spinner = spinner;
        this.renderer = renderer;
        this.projectId = 0;
        this.dashboardData = [];
        this.reportIDs = [];
        this.predefinedReports = [];
    }
    GoodFarmingPracticesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dashboardId = this.route.snapshot.params['id2'];
        this.projectId = this.route.snapshot.params['id'];
        this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
            _this.project = response.data;
            var projectObject = _this.project.filter(function (item) { return item.id == _this.projectId; })[0];
            _this.project = projectObject;
        });
        var requestObject = {
            //"dashboardId":this.dashboardId
            "dashboardId": this.dashboardId
        };
        this.hcRService.getDashboardDetailsById(requestObject).subscribe(function (response) {
            //console.log("get Dashboard Data ", response);
            if (response.msg == 'success') {
                _this.dashboardData = response.data;
                if (_this.dashboardData.length > 0) {
                    //console.log("this.dashboardData ", this.dashboardData);
                    var dashboardSelectedReports = [];
                    for (var i = 0; i < _this.dashboardData[0].hc_report_dashboard_dtl.length; i++) {
                        dashboardSelectedReports.push(_this.dashboardData[0].hc_report_dashboard_dtl[i]);
                        //this.dashboardEditObject.section = dashboardData[0].hc_report_dashboard_dtl[0].section;
                    }
                    //console.log("dashboardSelectedReports ", dashboardSelectedReports);
                    if (dashboardSelectedReports && dashboardSelectedReports.length > 0) {
                        for (var i = 0; i < dashboardSelectedReports.length; i++) {
                            _this.reportIDs.push(dashboardSelectedReports[i].report_id);
                        }
                        //console.log(" this.reportIDs ", this.reportIDs);
                        if (_this.reportIDs && _this.reportIDs.length > 0) {
                            _this.genarateCharts(_this.reportIDs);
                        }
                    }
                }
            }
        });
        // let h = document.createElement("div");   
        // let newElement4 = this.chartElement04.nativeElement.appendChild(h); 
        // this.renderer.addClass(newElement4, 'height100');
        // Highcharts.chart(newElement4, {
        //   chart: {
        //       type: 'column',
        //       backgroundColor: 'transparent'
        //   },
        //   title: {
        //       text: ''
        //   },
        //   subtitle: {
        //       text: ''
        //   },
        //   accessibility: {
        //       announceNewData: {
        //           enabled: true
        //       }
        //   },
        //   xAxis: {
        //       type: 'category'
        //   },
        //   yAxis: {
        //       title: {
        //           text: ''
        //       }
        //   },
        //   legend: {
        //       enabled: false
        //   },
        //   plotOptions: {
        //       series: {
        //           borderWidth: 0,
        //           dataLabels: {
        //               enabled: true,
        //               format: '{point.y:.1f}%'
        //           }
        //       }
        //   },
        //   tooltip: {
        //       headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        //       pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        //   },
        //   series: [
        //       {
        //           name: "Good Practices",
        //           colorByPoint: true,
        //           data: [
        //               {
        //                   name: "Buffer Areas",
        //                   y: 62.74
        //               },
        //               {
        //                   name: "Glyceridia Tutors",
        //                   y: 10.57
        //               },
        //               {
        //                   name: "Manual Weeding",
        //                   y: 78.23
        //               },
        //               {
        //                   name: "Looping",
        //                   y: 38.23
        //               },
        //               {
        //                   name: "Organic Crop Protection",
        //                   y: 23.58
        //               },
        //               {
        //                   name: "Organic Fertilizers",
        //                   y: 54.02
        //               },
        //               {
        //                   name: "Shading Trees",
        //                   y: 45.92
        //               }
        //           ]
        //       }
        //   ]
        // });
    };
    GoodFarmingPracticesComponent.prototype.genarateCharts = function (reportIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requestObject, chartsList;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.spinner.show();
                requestObject = {};
                requestObject = {
                    "reportID": reportIds,
                    "projectId": this.projectId
                };
                chartsList = [];
                this.hcRService.getReports(requestObject).subscribe(function (response) {
                    //console.log("response ", response);
                    if (response.msg == 'success') {
                        var reportsData = response.data;
                        _this.predefinedReports = response.data;
                        //console.log("reportsData ", this.predefinedReports);
                        if (reportsData && reportsData.length > 0) {
                            for (var i = 0; i < reportsData.length; i++) {
                                if (reportsData[i].reportId == 117) {
                                    _this.createGraph(reportsData[i], _this.chartElement02);
                                }
                                if (reportsData[i].reportId == 120) {
                                    _this.createGraph(reportsData[i], _this.chartElement03);
                                }
                                if (reportsData[i].reportId == 128) {
                                    _this.createGraph(reportsData[i], _this.chartElement04);
                                }
                                if (reportsData[i].reportId == 133) {
                                    var perc = 0;
                                    if (reportsData[i].series[0].children_motivated_farming != null && reportsData[i].series[0].total_children != null) {
                                        perc = reportsData[i].series[0].children_motivated_farming * 100 / reportsData[i].series[0].total_children;
                                    }
                                    reportsData[i].series[0].perc = perc.toFixed(0);
                                }
                            }
                            _this.setPerValue();
                        }
                        _this.spinner.hide();
                    }
                    else {
                        _this.spinner.hide();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    GoodFarmingPracticesComponent.prototype.createGraph = function (reportsData, ele) {
        var option = reportsData.conf[0].conf;
        var series = reportsData.series;
        var seriesArr = [];
        if (Array.isArray(series) && series.length > 0) {
            for (var _i = 0, _a = Object.entries(series[0]); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                var object = {
                    name: key,
                    y: Number(value)
                };
                seriesArr.push(object);
            }
        }
        option.title.text = '';
        option.series[0].data = seriesArr;
        var contextMenuOptions = [];
        contextMenuOptions = [
            'viewFullscreen',
            {
                textKey: 'downloadPDF',
                onclick: function () {
                    this.exportChart({
                        type: 'application/pdf'
                    });
                }
            },
            {
                textKey: 'downloadPNG',
                onclick: function () {
                    this.exportChart();
                }
            },
            {
                textKey: 'downloadXLS',
                onclick: function () {
                    this.downloadXLS();
                }
            }
        ];
        option.exporting.buttons.contextButton.menuItems = contextMenuOptions;
        var e = document.createElement("div");
        var newElement = ele.nativeElement.appendChild(e);
        this.renderer.addClass(newElement, 'height100');
        this.hcRService.createChart(newElement, option);
    };
    GoodFarmingPracticesComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    GoodFarmingPracticesComponent.prototype.goToReportingManagement = function (projectID) {
        var url = 'charts/' + this.projectId;
        this.router.navigate([url]);
    };
    GoodFarmingPracticesComponent.prototype.goToDashboards = function () {
        var url = "charts/dashboards/" + this.projectId;
        this.router.navigate([url]);
    };
    GoodFarmingPracticesComponent.prototype.setPerValue = function () {
        setTimeout(function () {
            //const colorArr = ['#24b358', '#00cc44', '#00cc66', '#00cc99', '#00cc44', '#00cc66', '#00cc99'];
            var $ppc = $(".progress-pie-chart");
            $.each($ppc, function (item, val) {
                //console.log($(this).data("percent"));
                var percent = parseInt($(this).data("percent")), deg = (360 * percent) / 100;
                if (percent > 50) {
                    $(this).addClass("gt-50");
                    //$(this).css('background-color', colorArr[val])
                }
                $(this).find(".ppc-progress-fill").css("transform", "rotate(" + deg + "deg)");
                $(this).find(".ppc-percents span").html(percent + "%");
            });
        }, 3000);
    };
    return GoodFarmingPracticesComponent;
}());
export { GoodFarmingPracticesComponent };
