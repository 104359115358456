
import { Observable, forkJoin, of } from 'rxjs';


import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';

import { ProjectService } from '../services/project.service';
import { CommonService } from '../services/common.service';
import { FarmerService } from '../services/farmer.service';
import { StorageService } from '../services/storage.service';

interface IReturn {
  project: any[];
  productType: any[];
  managers: any[];
}

@Injectable()
export class ProjectNewResolver implements Resolve<any> {

  userValue;

  constructor(private readonly projectService: ProjectService,
    private readonly commonService: CommonService, private readonly storageService: StorageService, private readonly farmerService: FarmerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let projectId = route.params['id'];
    let services: Observable<any>[];
    if (navigator.onLine) {

    } else {
      services = [of([]), of([])];
    }


      return forkJoin([
        this.projectService.getProjectBasicInfo(projectId).pipe(map((results) => results), catchError(e => of([]))),
        this.projectService.getProjectDashboardData(projectId).pipe(map((results) => results), catchError(e => of([]))),
        this.projectService.getProjectProfilingData(projectId).pipe(map((results) => results), catchError(e => of([]))),
      ]).pipe(
        map(results => ({
          project: {...results[0]['data'][0], ...results[1]['data'][0], ...results[2]['data'][0]},
          farmers: results[3]
        }))
      );
    
  }

  checkRole(): any {
    const localTokenInfo = this.storageService.getLocalTokenInfo();
    if (localTokenInfo) {
      this.userValue = JSON.parse(localTokenInfo);
      return this.userValue.role.id;
    }
  }

}
