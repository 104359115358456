import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { merge, fromEvent } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { ProjectService } from '../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { HighchartReportsService } from '../../services/highchart-reports.service';
var ReportingmanagementComponent = /** @class */ (function () {
    function ReportingmanagementComponent(route, projectService, toastrService, router, translate, userService, hcRService) {
        var _this = this;
        this.route = route;
        this.projectService = projectService;
        this.toastrService = toastrService;
        this.router = router;
        this.translate = translate;
        this.userService = userService;
        this.hcRService = hcRService;
        this.projects = [];
        this.projectId = 0;
        this.projectReporting = [];
        this.dashboardLists = [];
        this.online$ = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.route.paramMap.subscribe(function (params) {
            _this.id = params.get('id');
        });
        this.translate.get('report_labels').subscribe(function (result) {
            _this.projectReporting = [
                {
                    image: './assets/images/highchart-dashboard.svg',
                    title: result.titles.dashboard,
                    subtitle: result.subtitles.dashboard,
                    route: 'dashboards'
                },
                {
                    image: './assets/images/highchart-reports.svg',
                    title: result.titles.charts,
                    subtitle: result.subtitles.charts,
                    route: 'runreport'
                },
                // {
                //   image: './assets/images/highchart-trends.svg',
                //   title: 'Trends',
                //   subtitle: 'Trends',
                //   route: ''
                // },
                {
                    image: './assets/images/highchart-kpi-n-indecators.svg',
                    title: result.titles.kpis,
                    subtitle: result.subtitles.kpis,
                    route: 'kpiindicators'
                },
                {
                    image: './assets/online_images/compare-data.svg',
                    title: result.titles.compareData,
                    subtitle: result.subtitles.compareData,
                    route: 'comparedata'
                },
                {
                    image: './assets/images/highchart-trends.svg',
                    title: result.titles.consolidatedReport,
                    subtitle: result.subtitles.consolidatedReport,
                    route: 'consolidatedreport'
                },
            ];
        });
        translate.setDefaultLang('en');
    }
    ReportingmanagementComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requestParams;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.projectId = this.route.snapshot.params['id'];
                this.currentUser = this.userService.getCurrentUser();
                // if (navigator.onLine) {
                //   this.project = this.route.snapshot.data.project.project.data;
                // }else{
                //   const requests = await Promise.all([
                //     this.projectService.GetProjectsDataDashboard().toPromise()
                //   ]);
                //   this.project = requests[0].data.find(x => x.id === this.projectId);
                // }
                this.projectService.getProjectBasicInfo(this.projectId).subscribe(function (response) {
                    _this.project = response.data;
                    var projectObject = _this.project.filter(function (item) { return item.id == _this.projectId; })[0];
                    _this.project = projectObject;
                });
                requestParams = {
                    "projectId": this.projectId
                };
                this.hcRService.getDashboardsList(requestParams).subscribe(function (response) {
                    //console.log("response ==> ", response);
                    if (response.msg == 'success') {
                        _this.dashboardLists = response.data;
                        for (var i = 0; i < _this.dashboardLists.length; i++) {
                            if (_this.dashboardLists[i].is_default == 'Y') {
                                _this.dashboardLists[i].is_default = true;
                            }
                            else if (_this.dashboardLists[i].is_default == 'N') {
                                _this.dashboardLists[i].is_default = false;
                            }
                        }
                        _this.dashboardRecord = _this.dashboardLists.filter(function (item) { return item.is_default === true; })[0];
                        //console.log("this.dashboardRecord ",this.dashboardRecord);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    ReportingmanagementComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = '/dashboard';
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    ReportingmanagementComponent.prototype.detailProjectReport = function (x) {
        var url = x.route.toString();
        var routePath = 'charts/' + url + '/' + this.projectId;
        if (url.split('').length > 1) {
            if (url == 'dashboards') {
                if (this.dashboardRecord && this.dashboardRecord.is_default === true) {
                    var path = 'charts/' + url + '/' + 'viewreports' + '/' + this.dashboardRecord.id + '/' + this.projectId;
                    this.router.navigate([path]);
                }
                else {
                    this.router.navigate([routePath]);
                }
            }
            else {
                this.router.navigate([routePath]);
            }
        }
    };
    return ReportingmanagementComponent;
}());
export { ReportingmanagementComponent };
