/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./campaign-profiling.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../common/pipes/grid-filter.pipe";
import * as i4 from "../header/header.component.ngfactory";
import * as i5 from "../header/header.component";
import * as i6 from "../../services/authentication.service";
import * as i7 from "@angular/router";
import * as i8 from "../../services/user.service";
import * as i9 from "../../services/login.service";
import * as i10 from "@angular/forms";
import * as i11 from "../../services/common.service";
import * as i12 from "../../services/storage.service";
import * as i13 from "ngx-toastr";
import * as i14 from "ngx-tour-ngx-bootstrap";
import * as i15 from "ngx-bootstrap/modal/bs-modal.service";
import * as i16 from "../../services/project.service";
import * as i17 from "@ngx-translate/core";
import * as i18 from "./campaign-profiling.component";
import * as i19 from "../../services/campaign.service";
import * as i20 from "ng4-loading-spinner";
import * as i21 from "../../services/farmer.service";
var styles_CampaignProfilingComponent = [i0.styles];
var RenderType_CampaignProfilingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CampaignProfilingComponent, data: {} });
export { RenderType_CampaignProfilingComponent as RenderType_CampaignProfilingComponent };
function View_CampaignProfilingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "System"], ["src", "assets/images/campaign-closed.svg"]], null, null, null, null, null))], null, null); }
function View_CampaignProfilingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "col-lg-3 col-md-3 clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "dashboard-card-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.goToCampaignProfiling(_v.context.$implicit.id);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "dashboard-card-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "text-center"]], [[1, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "ellipsis"], ["style", "width:100%"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "dashboard-card-label"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CampaignProfilingComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = _v.context.$implicit.closed_date; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.description; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.description; _ck(_v, 6, 0, currVal_2); }); }
function View_CampaignProfilingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CampaignProfilingComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _co.campaigns, _co.getGridFilters())); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CampaignProfilingComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Campaign created"]))], null, null); }
function View_CampaignProfilingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CampaignProfilingComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noCampaigns", 2]], null, 0, null, View_CampaignProfilingComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.campaigns && (_co.campaigns.length > 0)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CampaignProfilingComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.GridFilterPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, [["window", "data-synced"], ["window", "data-added"], ["window", "error-caching"], ["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("window:data-synced" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).updateSyncStatusShown($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:data-added" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).dataAdded($event) !== false);
        ad = (pd_1 && ad);
    } if (("window:error-caching" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).errorCaching($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:keydown.escape" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).onKeydownHandler($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i1.ɵdid(2, 245760, null, 0, i5.HeaderComponent, [i6.AuthenticationService, i7.Router, i8.UserService, i9.LoginService, i10.FormBuilder, i11.CommonService, i12.StorageService, i2.Location, i1.PLATFORM_ID, i1.ChangeDetectorRef, i13.ToastrService, i14.ɵa, i15.BsModalService, i16.ProjectService, i17.TranslateService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 16, "div", [["class", "project-header clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 15, "nav", [["aria-label", "breadcrumb"], ["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 14, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "li", [["class", "breadcrumb-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-current", "page"], ["class", "btn-back"], ["title", "Back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getBackProjectFilter(((_co.project == null) ? null : _co.project.name), ((_co.project == null) ? null : _co.project.id)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "utilicon utilicon-arrow-alt-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "li", [["class", "breadcrumb-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["class", "btn-home"], ["routerLink", "/dashboard"], ["title", "Home"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["alt", "Home"], ["src", "assets/images/home.webp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "li", [["aria-current", "page"], ["class", "breadcrumb-item active cursorPointer "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getBackProjectFilter(((_co.project == null) ? null : _co.project.name), ((_co.project == null) ? null : _co.project.id)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [], [[1, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", "", ""])), i1.ɵpid(0, i2.SlicePipe, []), (_l()(), i1.ɵeld(17, 0, null, null, 2, "li", [["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Campaign Profiling"])), (_l()(), i1.ɵeld(20, 0, null, null, 14, "div", [["class", "page-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 13, "div", [["class", "content-wrap content-wrapper user-listing background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 12, "div", [["class", "form-design form-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "label", [["class", "group-title inline-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Campaign Profiling"])), (_l()(), i1.ɵeld(25, 0, null, null, 6, "div", [["class", "col-md-7 form-group fltRgt datatable-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 5, "input", [["class", "form-control"], ["placeholder", "Search Campaigns"], ["style", "width: initial;height: 40px;display: inline-block"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 27)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 27).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 27)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 27)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchText = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(27, 16384, null, 0, i10.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i10.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i10.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i10.DefaultValueAccessor]), i1.ɵdid(29, 671744, null, 0, i10.NgModel, [[8, null], [8, null], [8, null], [6, i10.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i10.NgControl, null, [i10.NgModel]), i1.ɵdid(31, 16384, null, 0, i10.NgControlStatus, [[4, i10.NgControl]], null, null), (_l()(), i1.ɵeld(32, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CampaignProfilingComponent_1)), i1.ɵdid(34, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = "/dashboard"; _ck(_v, 11, 0, currVal_0); var currVal_11 = _co.searchText; _ck(_v, 29, 0, currVal_11); var currVal_12 = _co.loadedCampaigns; _ck(_v, 34, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.project == null) ? null : _co.project.name); _ck(_v, 14, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform(((_co.project == null) ? null : _co.project.name), 0, 15)); var currVal_3 = ((((_co.project == null) ? null : _co.project.name.length) > 15) ? "..." : ""); _ck(_v, 15, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 31).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 31).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 31).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 31).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 31).ngClassValid; var currVal_9 = i1.ɵnov(_v, 31).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 31).ngClassPending; _ck(_v, 26, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_CampaignProfilingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-campaign-profiling", [], null, null, null, View_CampaignProfilingComponent_0, RenderType_CampaignProfilingComponent)), i1.ɵdid(1, 114688, null, 0, i18.CampaignProfilingComponent, [i7.ActivatedRoute, i11.CommonService, i19.CampaignService, i8.UserService, i16.ProjectService, i7.Router, i17.TranslateService, i20.Ng4LoadingSpinnerService, i21.FarmerService, i15.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CampaignProfilingComponentNgFactory = i1.ɵccf("app-campaign-profiling", i18.CampaignProfilingComponent, View_CampaignProfilingComponent_Host_0, {}, {}, []);
export { CampaignProfilingComponentNgFactory as CampaignProfilingComponentNgFactory };
