// TODO: Done: Build questionnaire from blank slate
// TODO: Done: Bug in option reordering
import { Component, OnInit, TemplateRef, ViewChild, LOCALE_ID, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// Services
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import {ChangeDetectorRef} from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-edit-dynamic-questionnaire',
  templateUrl: './edit-dynamic-questionnaire.component.html',
  styleUrls: ['./edit-dynamic-questionnaire.component.css']
})
export class EditDynamicQuestionnaireComponent implements OnInit {
  @ViewChild('saveTemplateModal') public saveTemplateModalPopup: ElementRef;
  questionnaire;
  currentTab;
  currentTabIndex;
  currentSection;
  currentSectionIndex;
  sectionForm: FormGroup;
  showSectionForm = false;
  reloadingQuestionnaire = true;
  qAnswers;
  checkedObject = {};
  farmerId = 1;
  obj = {id: "8", key: "116", value: "1"};
  modifyingThisQuestion;
  surveyId;
  survey;
  project;
  projectId;
  checkedObjectSecondaryKeys;
  secondary_asset_keys;
  modifyingThisOption;
  mta;
  modifyingAssetModal:BsModalRef;
  displayLogicModal:BsModalRef;
  assetTypes = [
    {type: 'tab', subTypes: []},
    {type: 'section', subTypes: []},
    {type: 'subsection', subTypes: []},
    {type: 'table', subTypes: [{key: 'question_rows', label: 'Questions in rows'}, {key: 'question_cols', label: 'Questions in columns'}]},
    {type: 'question', subTypes: [{key: 'text', label: 'Text'}, {key: 'number', label: 'Number'}, {key: 'select', label: 'Select'}, {key: 'multiselect', label: 'Multiselect'}]},
    {type: 'option', subTypes: []},
    {type: 'uom_question', subTypes: [{key: '3', label: 'Weight'},{key: '4', label: 'Area'}, {key: '5', label: 'Quantity'}]},
    {type: 'other_question', subTypes: []},
    {type: 'grand_total_question', subTypes: []},
  ];
  assetSubtypes = [];
  changedAssets = [];
  validationTypes = ['max_value','min_value', 'regex_pattern'];
  newValidationTypes = [];
  mtaIssues = [];
  questionnaireChanges = {};
  showAddButtonAfterThisQuestion;
  relativeToAssetIndex;
  modifyDisplayLogicForAsset;
  displayLogicDisplayIfKey;
  displayLogicDisplayIfValue;
  displayLogicDisplayIfValueExists;
  displayLogicTypes = [{type_key: 'value_matches', type_label: 'Must have specified answer'}, {type_key: 'value_exists', type_label: 'Can have any answer'}];
  displayLogicTypeSelected = {type_key: 'value_matches', type_label: 'Must have specified answer'};
  displayLogicForAssetExists;
  uomSubtypes = [];
  mtaHasOtherOption = false;
  modifyAutocalculateFormulaForAsset;
  autocalculateFormulaForAssetExists;
  autocalculateFormulaArray = [];
  formulaOperations = [
    {name: '+', symbol: '+'},
    {name: '-', symbol: '-'},
    {name: 'x', symbol: '*'},
    {name: '/', symbol: '/'}
  ];
  saveTemplateModal;
  templateNameInput;
  templateDescriptionInput;
  saveTemplateSubmitted = false;
  remainingTemplateDescriptionLength = 300;
  savingTemplate = false;
  displayLogicKeys = [];
  displayLogicValues = [];
  @ViewChild('modifyAssetModalTemplate') modifyAssetModalTemp: TemplateRef<void>;
  disableCancelOfModifyingAssetModal;
  disableButtons;
  isBlankQuestionnaire;
  userLanguages = [];
  public closeSnapshotModal: BsModalRef;
  modifyingAutoCalcFormulaInTab;
  modifyingAutoCalcFormulaInSection;
  public confirmForChanges: BsModalRef;

  constructor(
    private dynamicQuestionnaireService: DynamicQuestionnaireService,
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private projectService: ProjectService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private ref: ChangeDetectorRef,
    private router: Router,
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly spinner: Ng4LoadingSpinnerService
  ) {
    if (this.locale && this.locale.length > 2) {
      this.locale = this.locale.substr(0, 2);
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.route.queryParamMap.subscribe(async queryParams => {
        this.surveyId = params.get('surveyId');
        this.projectId = params.get('projectId');
        const request = await Promise.all([
          this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
          this.projectService.getProjectProfilingData(this.projectId).toPromise(),
          this.surveyService.getSurvey(this.surveyId).toPromise(),
          this.surveyService.getSurveyQuestionnaire(this.surveyId).toPromise(),
          this.surveyService.getAssociatedTemplates(this.surveyId).toPromise(),
          this.surveyService.getSurveyLanguages().toPromise()
        ]);
        if (request[0]['msg'] != 'success' || request[1]['msg'] != 'success') {
          this.commonService.showToast('error', 'generic_error', {});
        } else {
          this.project = {...request[0].data[0], ...request[1].data[0]};
        }

        if (request[2]['message']) {
          this.survey = request[2]['message'];
        }

        if (request[5]['message']) {
          this.userLanguages = request[5]['message'];
        }

        if (request[3]['message']) {
          if (request[3]['message'].length > 0) {
            this.questionnaire = request[3]['message'];
          } else {
            this.initializeBlankQuestionnaire();
          }
          this.initialize(0, 0);
          this.reloadingQuestionnaire = false;
        }
      })
    })
  }

  tabClicked(tabIndex, sectionIndex) : void {
    delete this.currentTab;
    this.currentTab = JSON.parse(JSON.stringify(this.questionnaire[tabIndex]));
    this.currentTabIndex = tabIndex;
    this.currentSectionIndex = sectionIndex;
    this.sectionClicked(this.currentSectionIndex);
  }

  sectionClicked(sectionIndex) : void {
    if (this.currentTab) {
      delete this.currentSection;
      if (this.currentTab.sections && this.currentTab.sections.length > 0) {
        this.currentSection = JSON.parse(JSON.stringify(this.currentTab.sections[sectionIndex]));
        this.currentSectionIndex = sectionIndex;
        this.ref.detectChanges();
        this.reloadingQuestionnaire = false;
      } else {
        this.ref.detectChanges();
        this.reloadingQuestionnaire = false;
      }
    } else {
      this.ref.detectChanges();
      this.reloadingQuestionnaire = false;
    }
  }

  initialize(tabIndex, sectionIndex): void {
    this.currentTabIndex = tabIndex;
    this.tabClicked(this.currentTabIndex, sectionIndex);
    this.collectDisplayLogicKeyValues();
  }



  setForm(): void {
    this.showSectionForm = false;
    this.sectionForm = new FormGroup({});
    
    // For each question in the section
    for (let i = 0; i < this.currentSection.questions.length; i++) {
      // Find if the question has any answer(s) in the qAnswers array
      let existsInQAnswers = this.existsInQAnswers(this.currentSection.questions[i]);
      if (this.currentSection.questions[i].qa_subtype == 'multiselect' && existsInQAnswers && existsInQAnswers.length > 0) {
        // Set the object which determines whether a checkbox should appear as checked or unchecked (question.id + option.id combination is true / false)
        this.setCheckedObject(existsInQAnswers, this.currentSection.questions[i]);
      }
      // Create a form control for each question
      this.sectionForm.addControl(this.currentSection.questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
      // Set the question's validations
      this.setQuestionValidations(this.currentSection.questions[i]);
      // Disable user input if required
      if (this.currentSection.questions[i].disable_input) {this.sectionForm.controls[this.currentSection.questions[i].id].disable();}
      // Set the question's UOM dropdown (value of dropdown select formcontrol + the option in the dropdown) if required
      if (this.currentSection.questions[i].include_uom_question) {this.setUOMQuestion(this.currentSection.questions[i]);}
    }

    // For each subsection in the section, do the same steps as above (done for each question within each subsection)
    for (let k = 0; k < this.currentSection.subsections.length; k++) {
      for (let i = 0; i < this.currentSection.subsections[k].questions.length; i++) {
        let existsInQAnswers = this.existsInQAnswers(this.currentSection.subsections[k].questions[i]);
        if (this.currentSection.subsections[k].questions[i].qa_subtype == 'multiselect' && existsInQAnswers && existsInQAnswers.length > 0) {
          this.setCheckedObject(existsInQAnswers, this.currentSection.subsections[k].questions[i]);
        }
        this.sectionForm.addControl(this.currentSection.subsections[k].questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
        this.setQuestionValidations(this.currentSection.subsections[k].questions[i]);
        if (this.currentSection.subsections[k].questions[i].disable_input) {this.sectionForm.controls[this.currentSection.subsections[k].questions[i].id].disable();}
        if (this.currentSection.subsections[k].questions[i].include_uom_question) {this.setUOMQuestion(this.currentSection.subsections[k].questions[i]);}
      }
    }

    // For each table within the section
    for (let k = 0; k < this.currentSection.tables.length; k++) {
      // For each question in the table
      for (let i = 0; i < this.currentSection.tables[k].questions.length; i++) {
        // Check if answers exist for this question
        let existsInQAnswersForTableQuestion = this.existsInQAnswersForTableQuestion(this.currentSection.tables[k].questions[i]);
        // Add secondary asset key of each unique row / column in the table to the secondary_asset_keys object
        this.secondary_asset_keys[this.currentSection.tables[k].id] = existsInQAnswersForTableQuestion.map(item => item.secondary_asset_key);
        // Either non-blank or "" answer should exist for each question in each row / column in the table
        if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
          // For each unique secondary asset key
          for (let p = 0; p < existsInQAnswersForTableQuestion.length; p++) {
            // For multiselect questions, set the checkedObjectSecondaryKeys value (to indicate whether the checkbox should appear checked or not)
            if (this.currentSection.tables[k].questions[i].qa_subtype == 'multiselect' && existsInQAnswersForTableQuestion[p].value && existsInQAnswersForTableQuestion[p].value.length > 0) {
              this.setCheckedObjectSecondaryKeys(existsInQAnswersForTableQuestion[p], this.currentSection.tables[k].questions[i]);
            }
            // Add the form control (question.id + '_' + secondary_asset_key of the row / column)
            this.sectionForm.addControl(this.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key'] , new FormControl(existsInQAnswersForTableQuestion[p]['value']));
            // Set the question's validations, disable the input if required & add UOM questions if required
            this.setQuestionValidations(this.currentSection.tables[k].questions[i], existsInQAnswersForTableQuestion[p]['secondary_asset_key']);
            if (this.currentSection.tables[k].questions[i].disable_input) {this.sectionForm.controls[this.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key']].disable();}
            if (this.currentSection.tables[k].questions[i].include_uom_question) {this.setUOMQuestion(this.currentSection.tables[k].questions[i], existsInQAnswersForTableQuestion[p]);}
          }
        }
      }
    }
    // Based on toggle logic if applicatble, set which elements in the form should be displayed (isDisplayed attribute = true/false)
    this.setSectionFormDisplay();
    this.showSectionForm = true;
  }

  sectionFormChangeHandler(args, question): void {
    if (!this.qAnswers) {this.qAnswers = [];}
    // See if this object already exists in qAnswers
    let x = this.qAnswers.find(item => item.key == question.id);
    // If object exists, find the index
    if (x) {
      let index = this.qAnswers.indexOf(x);
      // Set this object's touched parameter to true
      this.qAnswers[index]['touched'] = true;
      // If this object has an id (previously value was set), set the form control's value
      if (this.qAnswers[index].id && this.qAnswers[index].id > 0) {
        this.qAnswers[index]['value'] = this.sectionForm.value[question.id];
      } else {
        // If this object does NOT have an id
        // If the form control has a not-null not-blank value, set it
        if (this.sectionForm.value[question.id] && this.sectionForm.value[question.id] != '') {
          this.qAnswers[index]['value'] = this.sectionForm.value[question.id];
        } else {
          // If the form control has a null / blank value, remove the object from this.qAnswers (it has been set and unset in the same session)
          this.qAnswers = this.qAnswers.filter(item => item.key != question.id);
        }
      }
    } else {
      // If this object does not exist in qAnswers
      // If the form control has a not-null not-blank value, add the object to qAnswers with touched = true
      if (this.sectionForm.value[question.id] && this.sectionForm.value[question.id] != '') {
        this.qAnswers.push({key: question.id.toString(), value: this.sectionForm.value[question.id].toString(), touched: true});
      }
    }
    this.setSectionFormDisplay();
  }

  updateQAnswers(savedQAnswers): void {
    if (!this.qAnswers) {this.qAnswers = [];}
    for (let i = 0; i < savedQAnswers.length; i++) {
       let x = this.qAnswers.find(item => item.key == savedQAnswers[i].questionnaire_asset_id);
       if (x) {
         let index = this.qAnswers.indexOf(x);
         this.qAnswers[index] = {id: savedQAnswers[i].id.toString(), key: savedQAnswers[i].questionnaire_asset_id.toString(), value: savedQAnswers[i].answer.toString()}
       } else {
        this.qAnswers.push({id: savedQAnswers[i].id.toString(), key: savedQAnswers[i].questionnaire_asset_id.toString(), value: savedQAnswers[i].answer.toString()});
       }
    }
  }

  existsInQAnswers(question) : any {
    if (question.qa_subtype == 'multiselect') {
      // return this.qAnswers.filter(item => item.key == question.id);
      return [];
    } else {
      // return this.qAnswers.find(item => item.key == question.id);
      return {};
    }
  }


  setCheckedObject(existsInQAnswers, question) : void {
    for (let i = 0; i < existsInQAnswers.length; i++) {
      this.checkedObject[question.id + '_' + existsInQAnswers[i]['value']] = true;
    }
  }

  setSectionFormDisplay(): void {
    for (let i = 0; i < this.currentSection.questions.length; i++) {
      // set question display
      this.currentSection.questions[i].isDisplayed = this.checkAssetDisplay(this.currentSection.questions[i]);
    }

    for (let k = 0; k < this.currentSection.subsections.length; k++) {
      // TODO: Done: set subsection display
      this.currentSection.subsections[k].isDisplayed = this.checkAssetDisplay(this.currentSection.subsections[k]);
      for (let i = 0; i < this.currentSection.subsections[k].questions.length; i++) {
        // TODO: Done: set question display
        this.currentSection.subsections[k].questions[i].isDisplayed = this.checkAssetDisplay(this.currentSection.subsections[k].questions[i]);
      }
    }
  }

  checkAssetDisplay(asset): boolean {
    if (asset.questionnaire_asset_display_logic && asset.questionnaire_asset_display_logic.length > 0) {
      for (let i = 0; i < asset.questionnaire_asset_display_logic.length; i++) {
        if (this.qAnswers.find(item => item.key == asset.questionnaire_asset_display_logic[i].display_if_key && item.value == asset.questionnaire_asset_display_logic[i].display_if_value)) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }

  navigateSection(direction): void {
    const newSectionIndex = this.currentSectionIndex + direction;
    if (newSectionIndex < 0) {
      const newTabIndex = this.currentTabIndex + direction;
      if (newTabIndex > 0) {
        this.tabClicked(newTabIndex, 0);
      }
    }
    else if (newSectionIndex > 0 && newSectionIndex < this.currentTab.sections.length) {
      this.sectionClicked(newSectionIndex);
    } else {
      const newTabIndex = this.currentTabIndex + direction;
      if (newTabIndex < this.questionnaire.length) {
        this.tabClicked(newTabIndex, 0);
      }
    }
  }
  exitClicked(): void {

  }

  showModifyQuestionInput(question, option?): void {
    // this.modifyingThisQuestion = question;
    // if (option) {
    //   this.modifyingThisOption = option;
    // }
  }

  modifyThisAsset(asset, template, assetType): void {
    this.assetSubtypes = this.assetTypes.find(item => item.type == assetType)['subTypes'];
    this.mta = Object.assign({}, JSON.parse(JSON.stringify(asset)));
    // this.newValidationTypes = this.validationTypes.filter(item => !this.mta.survey_q_asset_validations.find(each => each.id && each.validation_key == item));
    this.uomSubtypes = this.assetTypes.find(item => item.type == 'uom_question')['subTypes'];
    this.mta.isMandatory = this.mta.survey_q_asset_validations.find(each => each.validation_key == 'mandatory') ? true : false;
    if (asset.qa_type == 'question' || asset.qa_type == 'subsection' || asset.qa_type == 'table') {this.mta.childOfCurrentSection = this.mta.parent_code === this.currentSection.code;}
    // this.mta.displayLogic = this.getDisplayLogic(asset);
    this.setMtaHasOtherOption();
    this.mtaIssues = [];
    this.modifyingAssetModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  addAsset(assetType, relativeToAsset, relativeToAssetIndex, direction, template, isChildOfTable?): void {
    if (assetType != 'tab' && (!relativeToAsset.parent_id || relativeToAsset.parent_id == 0)) {
      this.toastr.warning('Please save changes first');
      return;
    }
    let assetToAdd = {
      survey_id: this.surveyId,
      code: uuidv4(),
      qa_type: assetType,
      parent_id: relativeToAsset.parent_id,
      parent_code: relativeToAsset.parent_code,
      qa_sequence: this.getRelativeSequence(relativeToAsset, direction),
      qa_subtype: this.getDefaultSubtype(assetType),
      survey_q_asset_labels: [{language_id: this.getLanguageId(), label: ''}],
      survey_q_asset_validations: [],
      survey_q_asset_display_logic: [],
      survey_q_asset_autocalculate_formulas: [],
      is_table_question: isChildOfTable ? true : false
    };
    console.log('assetToAdd', assetToAdd);
    this.modifyThisAsset(assetToAdd, template, assetType);
    this.relativeToAssetIndex = relativeToAssetIndex;
  }

  getDefaultSubtype(assetType) {
    let subtypes = this.assetTypes.find(item => item.type == assetType)['subTypes'];
    if (subtypes && subtypes.length > 0) {
      return subtypes[0];
    } else {
      return null;
    }
  }

  saveAssetChanges(): void {
    if (this.validateMTA()) {
      const mtaType = this.mta.qa_type;
      this.updateChangedAssets();
      this.updateCurrentTab();
      this.closeModifyingAssetTemplateModal();
      if (this.disableCancelOfModifyingAssetModal) {this.disableCancelOfModifyingAssetModal = false;}
      if (mtaType == 'tab') {
        // this.saveQuestionnaireChanges();
      } 
    }
  }

  changeOption(option, action): void {
    let optionToRemove = this.mta.options.find(item => item.code == option.code);
    if (optionToRemove) {
      let index = this.mta.options.indexOf(optionToRemove);
      if (this.validIndex(index)) {
        if (this.mta.options[index].id) {
          if (this.displayLogicValues.indexOf(this.mta.options[index].id.toString()) > -1) {
            this.toastr.error('This option is part of a display logic condition & cannot be removed. Please modify the display logic first.');
            return;
          }
          this.mta.options[index].removed_from_questionnaire = (action == 'remove' ? true : false);
        } else {
          this.mta.options.splice(index, 1);
        }
        this.setMtaHasOtherOption();
      }
    }
  }

  changeUOM(uom, action): void {
    let uomToRemove = this.mta.uom_questions.find(item => item.code == uom.code);
    if (uomToRemove) {
      let index = this.mta.uom_questions.indexOf(uomToRemove);
      if (this.validIndex(index)) {
        if (this.mta.uom_questions[index].id) {
          this.mta.uom_questions[index].removed_from_questionnaire = (action == 'remove' ? true : false);
          this.mta.include_uom_question = (action == 'remove' ? false : true);
        } else {
          this.mta.uom_questions.splice(index, 1);
        }
      }
    }
  }

  addOption(isOtherOption?): void {
    // isOtherOption = true would indicate that this is an option of qa_subtype 'Other' - a text field is to be displayed if this option is selected
    if (!this.mta.id) {
      this.toastr.warning('Please save changes first');
      return;  
    }
    let optionToAdd = {
      survey_id: this.surveyId,
      parent_id: this.mta.id,
      parent_code: this.mta.code,
      qa_sequence: this.getSequence('option'),
      code: uuidv4(),
      qa_type: 'option',
      qa_subtype: isOtherOption ? 'other' : null,
      survey_q_asset_labels: [{language_id: this.getLanguageId(), label: this.getOptionLabel(isOtherOption)}]
    };
    if (!this.mta.options) {this.mta.options = [];}
    this.mta.options.push(optionToAdd);
    this.addOrRemoveAsset(optionToAdd, 'add');
    this.setMtaHasOtherOption();
    if (isOtherOption) {
      this.addIsOtherOptionQuestionAsset();
    }
  }

  getOptionLabel(isOtherOption) {
    if (isOtherOption) {
      // TODO: Return other in various languages
      return 'Other';
    } else {
      return '';
    }
  }

  getLanguageId() {
    if (this.userLanguages && this.userLanguages.length > 0) {
      return this.userLanguages.find(item => item.locale == this.locale)['id'];
    } else {
      return undefined;
    }
  }

  validIndex(index): boolean {
    if (index != null && index > -1) {
      return true;
    } return false;
  }

  getSequence(assetType) {
    let x;
    if (assetType == 'option' && this.mta.options) {
      x = Object.assign([], JSON.parse(JSON.stringify(this.mta.options)));
    } else if (assetType == 'question' && this.currentSection.questions) {
      x = Object.assign([], JSON.parse(JSON.stringify(this.currentSection.questions)));
    } else if (assetType == 'subsection' && this.currentSection.subsections) {
      x = Object.assign([], JSON.parse(JSON.stringify(this.currentSection.subsections)));
    } else if (assetType == 'table' && this.currentSection.tables) {
      x = Object.assign([], JSON.parse(JSON.stringify(this.currentSection.tables)));
    } else if (assetType == 'section' && this.currentTab.sections) {
      x = Object.assign([], JSON.parse(JSON.stringify(this.currentTab.sections)));
    }
    // If the question has options, sort the options already in this question. If no options, return 1
    // Similar logic for other asset types
    if (x && x.length > 0) {
      x.sort((a,b) => {
        if (a.qa_sequence > b.qa_sequence) {return 1;}
        else if (a.qa_sequence < b.qa_sequence) {return -1;}
        else {return 0;}
      });
      // Get the max qa_sequence value in current set of options
      let maxSequence = x[x.length - 1].qa_sequence;
      return maxSequence + 1;
    } else {
      return 1;
    }
  }

  getRelativeSequence(relativeToAsset, direction) {
    // Find the sibling assets to relativeToAsset
    // Check if an asset with qa_sequence = relativeToAsset + direction exists
    // If it does not, return relativeToAsset + direction
    // If it does, check relativeToAsset + direction + direction.. until you find an unclaimed qa_sequence number.

    if (relativeToAsset.qa_sequence == null) {
      return 0;
    } else {
      let siblingAssets = [];

      if (relativeToAsset.qa_type == 'section') {
        siblingAssets = this.currentTab.sections.filter(item => item.id != relativeToAsset.id);
      }

      if (relativeToAsset.qa_type == 'question') {
        let parentAsset = this.getParentAsset(relativeToAsset);
        siblingAssets = parentAsset.questions;
      }

      if (relativeToAsset.qa_type == 'subsection') {
        let parentAsset = this.getParentAsset(relativeToAsset);
        siblingAssets = parentAsset.subsections;
      }

      if (relativeToAsset.qa_type == 'table') {
        let parentAsset = this.getParentAsset(relativeToAsset);
        siblingAssets = parentAsset.tables;
      }

        let sequenceToCheck = relativeToAsset.qa_sequence + direction;
        let sequenceIsAlreadyClaimed = siblingAssets.find(item => sequenceToCheck != relativeToAsset.qa_sequence && item.qa_sequence == sequenceToCheck);
        if (!sequenceIsAlreadyClaimed) {
          return relativeToAsset.qa_sequence + direction;
        } else {
          return sequenceIsAlreadyClaimed.qa_sequence;
        }
    }
  }

  getParentAsset(childAsset) {
    let parentAsset;
    if (childAsset.qa_type == 'question') {
      loop1:
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
            parentAsset = this.questionnaire[i].sections[j];
            break loop1;
          } else {
            let parentSubsection = this.questionnaire[i].sections[j].subsections.find(item => item.code == childAsset.parent_code);
            if (parentSubsection) {
              parentAsset = parentSubsection;
              break loop1;
            }
            let parentTable = this.questionnaire[i].sections[j].tables.find(item => item.code == childAsset.parent_code);
            if (parentTable) {
              parentAsset = parentTable;
              break loop1;
            }
          }
        }
      }
    }

    if (childAsset.qa_type == 'subsection') {
      loop1:
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
            parentAsset = this.questionnaire[i].sections[j];
            break loop1;
          }
        }
      }
    }
    
    if (childAsset.qa_type == 'section') {
      for (let i = 0; i < this.questionnaire.length; i++) {
        parentAsset = this.questionnaire.find(item => item.code == childAsset.parent_code);
      }
    }

    if (childAsset.qa_type == 'option') {
      loop1:
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          let parentQuestion = this.questionnaire[i].sections[j].questions.find(item => item.code == childAsset.parent_code);
          if (parentQuestion) {
            parentAsset = parentQuestion;
            break loop1;
          } else {
            if (this.questionnaire[i].sections[j].subsections && this.questionnaire[i].sections[j].subsections.length > 0) {
              for (let i = 0; i < this.questionnaire[i].sections[j].subsections.length; i++) {
                let parentQuestion = this.questionnaire[i].sections[j].subsections[i].questions.find(item => item.code == childAsset.parent_code);
                if (parentQuestion) {
                  parentAsset = parentQuestion;
                  break loop1;
                }
              }
            }

            if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
              for (let i = 0; i < this.questionnaire[i].sections[j].tables.length; i++) {
                let parentQuestion = this.questionnaire[i].sections[j].tables[i].questions.find(item => item.code == childAsset.parent_code);
                if (parentQuestion) {
                  parentAsset = parentQuestion;
                  break loop1;
                }
              }
            }
          }
        }
      }
    }

    if (childAsset.qa_type == 'table') {
      loop1:
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
            parentAsset = this.questionnaire[i].sections[j];
            break loop1;
          }
        }
      }
    }

    return parentAsset;
  }

  changeQuestion(action, calledFromMarkChildrenDeleted?): void {
    // console.log('this.displayLogicKeys', this.displayLogicKeys);
    // console.log('this.mta.id', this.mta.id);
    if (this.mta.id && this.displayLogicKeys.indexOf(this.mta.id.toString()) > -1) {
      this.toastr.error('This question is part of a display logic condition & cannot be removed. Please modify the display logic first.');
      return;
    }

    if (this.mta.id && this.displayLogicValues.indexOf(this.mta.id.toString()) > -1) {
      this.toastr.error('This option is part of a display logic condition & cannot be removed. Please modify the display logic first.');
      return;
    }
    this.mta.removed_from_questionnaire = action == 'remove' ? true : false;
    this.updateChangedAssets();
    this.updateCurrentTab();
    if(!calledFromMarkChildrenDeleted) {if (action == 'remove') this.markChildrenDeleted2(this.mta);}
  }

  closeModifyingAssetTemplateModal(): void {
    this.mta = undefined;
    if (this.modifyingAssetModal) {this.modifyingAssetModal.hide()}
  }

  cancelModifyingQuestion(): void {
    this.modifyingThisQuestion = undefined;
  }

  activateSnapshot(template: TemplateRef<any>, template2: TemplateRef<any> ): void {
    if(this.changedAssets.length > 0){
      this.confirmForChanges = this.modalService.show(template2, { backdrop: true, ignoreBackdropClick: true, keyboard: false });  
    } else {
      this.closeSnapshotModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    }
  }
  declineSaveChangesActivate():void {
    this.confirmForChanges.hide();
  }
  moveWithoutSaveChangesActivate():void {
    this.confirmForChanges.hide();
    this.activateSurvey();
  }

  confirmActivation() : void {
    if (this.closeSnapshotModal) { 
      this.closeSnapshotModal.hide();
    }
    this.activateSurvey();
  }

  declineCloseSnapshot(): void {
    if (this.closeSnapshotModal) { 
      this.closeSnapshotModal.hide();
    }
  }

  activateSurvey(): void {
    if (this.disableButtons || this.savingTemplate) {
      return;
    } else {
      this.spinner.show();
      this.disableButtons = true;
      this.ref.detectChanges();
      this.surveyService.activateSurvey(this.surveyId)
        .subscribe(result => {
          if (result.code == 'success') {
            this.toastr.success('Activated survey');
            this.disableButtons = false;
            this.ref.detectChanges();
            this.router.navigate(['questionnaires/' + this.projectId]);
            this.spinner.hide();
          }
        }, error => {
          this.spinner.hide();
          this.disableButtons = false;
          this.ref.detectChanges();
        })
    }
  }

  launchSaveAsTemplateModal(template, template2): void {
    this.savingTemplate = false;
    this.templateNameInput = undefined;
    this.templateDescriptionInput = undefined;
    this.saveTemplateSubmitted = false;
    if(this.changedAssets.length > 0){
      this.confirmForChanges = this.modalService.show(template2, { backdrop: true, ignoreBackdropClick: true, keyboard: false });  
    } else {
      this.saveTemplateModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    }
  }
  declineSaveChanges():void {
    this.confirmForChanges.hide();
  }
  moveWithoutSaveChangesTemplate():void {
    this.confirmForChanges.hide();
    this.saveTemplateModal = this.modalService.show(this.saveTemplateModalPopup, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }

  closeSaveTemplateModal(): void {
    if (this.saveTemplateModal) {
      this.saveTemplateModal.hide()
    }
    this.templateNameInput = undefined;
    this.templateDescriptionInput = undefined;
    this.saveTemplateSubmitted = false;
    this.savingTemplate = false;
    this.ref.detectChanges();
  }

  saveTemplate(): void {
    if (this.savingTemplate || this.disableButtons) {
      return;
    }
    this.saveTemplateSubmitted = true;
    if (!this.templateNameInput || this.templateNameInput.length == 0 || !this.templateDescriptionInput || this.templateDescriptionInput.length == 0) {
      return;
    }
    const templateDetails = {
      name: this.templateNameInput,
      description: this.templateDescriptionInput
    };

    this.savingTemplate = true;
    this.ref.detectChanges();
    this.surveyService.saveAsTemplate(this.surveyId, templateDetails)
    .subscribe(result => {
      if (result['code'] == 'success') {
        this.toastr.success('template_created');
      }
      this.closeSaveTemplateModal();
    }, error => {
      this.closeSaveTemplateModal();
    })
  }

  // Set the angular reactive form validators for the given question
  setQuestionValidations(question, secondaryAssetKey?): void {
    if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) {
      let formControlName = question.id;
      if (secondaryAssetKey) {
        formControlName = formControlName + '_' + secondaryAssetKey;
      }
      let arrValidators = [];
      for (let j = 0; j < question.survey_q_asset_validations.length; j++) {
        if (question.survey_q_asset_validations[j].validation_key == 'mandatory' && question.survey_q_asset_validations[j].validation_value) {
          arrValidators.push(Validators.required);
        }
        if (question.survey_q_asset_validations[j].validation_key == 'regex_pattern') {
          arrValidators.push(Validators.pattern(question.survey_q_asset_validations[j].validation_value));
        }
        if (question.survey_q_asset_validations[j].validation_key == 'min_value') {
          arrValidators.push(Validators.min(question.survey_q_asset_validations[j].validation_value));
        }
        if (question.survey_q_asset_validations[j].validation_key == 'max_value') {
          arrValidators.push(Validators.max(question.survey_q_asset_validations[j].validation_value));
        }
      }
      this.sectionForm.controls[formControlName].setValidators(arrValidators);
      this.sectionForm.controls[formControlName].updateValueAndValidity();
    }
  }

  // Check if the uom_question has a value, & create the corresponding sectionForm control. Also add the options to be displayed in the dropdown
  setUOMQuestion(question, existsInQAnswersForTableQuestionItem?): void {
    if (!existsInQAnswersForTableQuestionItem) {
      // If the question has uom_questions children elements
      if (question.uom_questions && question.uom_questions.length > 0) {
        for (let i = 0; i < question.uom_questions.length; i++) {
          // For each uom_question child element, check if it has a value in qAnswers, & create the form control accordingly
          let existsInQAnswers = this.existsInQAnswers(question.uom_questions[i]);
          this.sectionForm.addControl(question.uom_questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
          // Set the options dropdown for this uom_question
          question.uom_questions[i].options = this.project.ProjectUOM.filter(item => item.unit_type_id == question.uom_questions[i].qa_subtype);
        }
      }
    } else {
      if (question.uom_questions && question.uom_questions.length > 0) {
        for (let i = 0; i < question.uom_questions.length; i++) {
          let valueObject = this.qAnswers.find(item => item.key == question.uom_questions[i].id && item.secondary_asset_key == existsInQAnswersForTableQuestionItem['secondary_asset_key']);
          this.sectionForm.addControl(question.uom_questions[i].id + '_' + existsInQAnswersForTableQuestionItem['secondary_asset_key'], valueObject != null ? new FormControl(valueObject['value']): new FormControl(''));
          question.uom_questions[i].options = this.project.ProjectUOM.filter(item => item.unit_type_id == question.uom_questions[i].qa_subtype);
        }
      }
    }
  }

  existsInQAnswersForTableQuestion(question) {
    return this.qAnswers.filter(item => item.key === question.id);
  }

  // Set the checkedObject object attribute of question id + secondary_asset_key + option id to true if it exists in qAnswers for a given secondary asset key
  // This is used for checkbox questions that are in a table
  setCheckedObjectSecondaryKeys(existsInQAnswersForTableQuestionItem, question) : void {
    this.checkedObjectSecondaryKeys[question.id + '_' + existsInQAnswersForTableQuestionItem.secondary_asset_key + '_' + existsInQAnswersForTableQuestionItem['value']] = true;
  }


  subtypeChanged(event): void {}

  saveQuestionnaireChanges(): void {
    this.spinner.show();
    this.surveyService.saveQuestionnaireChanges(this.surveyId, this.changedAssets)
    .subscribe(result => {
      if (result.code == 'success') {
        this.updateQuestionnaireAfterSaving();
        this.spinner.hide();
      }
      if (result.code == 'fail') {
        // TODO: Done: Caught in error handler. Let user know about errors
        this.toastr.error('error');
        this.spinner.hide();
      }
    })
  }

  async updateQuestionnaireAfterSaving() {
    this.spinner.show();
    const request = await Promise.all([
      this.surveyService.getSurveyQuestionnaire(this.surveyId).toPromise()
    ]);
    if (request[0]['message']) {
      // delete this.questionnaire;
      this.reloadingQuestionnaire = true;
      this.questionnaire = undefined;
      this.ref.detectChanges();
      setTimeout(() => {
        this.questionnaire = Object.assign([], JSON.parse(JSON.stringify(request[0]['message'])));
        if (request[0]['message'] && request[0]['message'].length > 0) {
          this.isBlankQuestionnaire = false;
          this.ref.detectChanges();
        }
        this.initialize(this.currentTabIndex, this.currentSectionIndex);
      }, 500);
      // this.ref.detectChanges();
      this.changedAssets = [];
      this.spinner.hide();
    }
  }

  // updateTabs(tabsToUpdate): void {
  //   for (let i = 0; i < tabsToUpdate.length; i++) {
  //     let existsInQuestionnaire = this.questionnaire.find(item => item.id == tabsToUpdate[i].id);
  //     if (existsInQuestionnaire) {
  //       let index = this.questionnaire.indexOf(existsInQuestionnaire);
  //       if (this.validIndex(index)) {
  //         this.questionnaire[index] = tabsToUpdate[i];
  //       }
  //     } else {
  //       this.questionnaire.push(tabsToUpdate[i]);
  //     }
  //   }
  // }

  // updateSections(sectionsToUpdate): void {
  //   for (let i = 0; i < sectionsToUpdate.length; i++) {
  //     let sectionToUpdateTab = this.questionnaire.find(item => item.parent_id == sectionsToUpdate[i].parent_id);
  //     if (sectionToUpdateTab) {
  //       let index1 = this.questionnaire.indexOf(sectionToUpdateTab);
  //       if (this.validIndex(index1)) {
  //         let sectionToUpdate = this.questionnaire[index1].sections.find(item => item.id == sectionsToUpdate[i].id);
  //         if (sectionToUpdate) {
  //           let index2 = this.questionnaire[index1].sections.indexOf(sectionToUpdate);
  //           if (this.validIndex(index2)) {
  //             this.questionnaire[index1].sections[index2] = sectionsToUpdate[i];
  //           } else {
  //             this.questionnaire[index1].sections.push(sectionsToUpdate[i]);
  //           }
  //         }
  //       } else {
  //         // console.log('622 code should not reach here');
  //       }
  //     }
  //   }
  // }

  // TODO: Not relevant. This function
  updateSubSections(subsectionsToUpdate): void {
  }

  // TODO: Not relevant. This function
  updateTables(subsectionsToUpdate): void {
  }

  // TODO: Not relevant. This function
  updateQuestions(subsectionsToUpdate): void {
  }

  // TODO: Not relevant. This function
  updateOptions(optionsToUpdate): void {
  }

  // getParentId(id, parentType) {
  // }

  updateChangedAssets(): void {
    let mtaBeingModified = this.changedAssets.find(item => item.code == this.mta.code);
    if (mtaBeingModified) {
      let index = this.changedAssets.indexOf(mtaBeingModified);
      if (this.validIndex(index)) {
        this.changedAssets[index] = this.mta;
      }
    } else {
      this.changedAssets.push(this.mta);
    }
    // console.log('this.changedAssets', this.changedAssets);
  }

  updateCurrentTab(): void {
    if (this.mta.qa_type == 'question') {
      this.updateCurrentTabQuestion();
    } else if (this.mta.qa_type == 'section') {
      this.updateCurrentTabSection();
    }
    else if (this.mta.qa_type == 'tab') {
      this.updateCurrentTabEntireTab();
    }
    else if (this.mta.qa_type == 'subsection') {
      this.updateCurrentTabSubsection();
    }
    else if (this.mta.qa_type == 'table') {
      this.updateCurrentTabTable();
    }
  }

  updateCurrentTabQuestion(): void {
    let parentAsset = this.getParentAsset(this.mta);
    if (parentAsset.qa_type == 'section') {
      let mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.find(item => item.code == this.mta.code);
      if (mtaBeingModified) {
        let index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.indexOf(mtaBeingModified);
        if (this.validIndex(index)) {
          this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[index] = this.mta;
        }
      } else {
        if (this.relativeToAssetIndex != undefined) {
          if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions) {this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions = [];}
          this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.splice(this.relativeToAssetIndex + 1, 0, this.mta);
        } else {
          if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions) {this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions = [];}
          this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.splice(this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions.length, 0, this.mta);
        }
      }
    } else if (parentAsset.qa_type == 'subsection') {
      let subsectionBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.find(item => item.code == this.mta.parent_code);
      if (subsectionBeingModified) {
        let subsectionIndex = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.indexOf(subsectionBeingModified);
        if (this.validIndex(subsectionIndex)) {
          let mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions.find(item => item.code == this.mta.code);
          if (mtaBeingModified) {
            let index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
              this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions[index] = this.mta;
            }
          } else {
            if (this.relativeToAssetIndex != undefined) {
              if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions) {this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions = [];}
              this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[subsectionIndex].questions.splice(this.relativeToAssetIndex + 1, 0, this.mta);
            }
          }
        }
      }
    } else if (parentAsset.qa_type == 'table') {
      let tableBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.find(item => item.code == this.mta.parent_code);
      if (tableBeingModified) {
        let tableIndex = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.indexOf(tableBeingModified);
        if (this.validIndex(tableIndex)) {
          let mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions.find(item => item.code == this.mta.code);
          if (mtaBeingModified) {
            let index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions.indexOf(mtaBeingModified);
            if (this.validIndex(index)) {
              this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions[index] = this.mta;
            }
          } else {
            if (this.relativeToAssetIndex != undefined) {
              if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions) {this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions = [];}
              this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tableIndex].questions.splice(this.relativeToAssetIndex + 1, 0, this.mta);
            }
          }
        }
      }
    }
    this.initialize(this.currentTabIndex, this.currentSectionIndex);
    this.clickOnQuestionDiv(undefined);
  }

  updateCurrentTabSection(): void {
    let mtaBeingModified = this.questionnaire[this.currentTabIndex].sections.find(item => item.code == this.mta.code);
    if (mtaBeingModified) {
      let index = this.questionnaire[this.currentTabIndex].sections.indexOf(mtaBeingModified);
      if (this.validIndex(index)) {
        this.questionnaire[this.currentTabIndex].sections[index] = this.mta;
      }
    } else {
      if (this.relativeToAssetIndex != undefined) {
        if (!this.questionnaire[this.currentTabIndex].sections) {this.questionnaire[this.currentTabIndex].sections = [];}
        this.questionnaire[this.currentTabIndex].sections.splice(this.relativeToAssetIndex + 1, 0, this.mta);
      } else {
        if (!this.questionnaire[this.currentTabIndex].sections) {this.questionnaire[this.currentTabIndex].sections = [];}
        this.questionnaire[this.currentTabIndex].sections.splice(this.questionnaire[this.currentTabIndex].sections.length, 0, this.mta);
      }
    }
    this.initialize(this.currentTabIndex, this.currentSectionIndex);
    this.clickOnQuestionDiv(undefined);
  }

  updateCurrentTabSubsection(): void {
    let mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.find(item => item.code == this.mta.code);
    if (mtaBeingModified) {
      let index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.indexOf(mtaBeingModified);
      if (this.validIndex(index)) {
        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[index] = this.mta;
      }
    } else {
      if (this.relativeToAssetIndex != undefined) {
        if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections) {this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections = [];}
        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.splice(this.relativeToAssetIndex + 1, 0, this.mta);
      } else {
        if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections) {this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections = [];}
        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.splice(this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.length, 0, this.mta);
      }
    }
    this.initialize(this.currentTabIndex, this.currentSectionIndex);
    this.clickOnQuestionDiv(undefined);
  }

  updateCurrentTabTable(): void {
    let mtaBeingModified = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.find(item => item.code == this.mta.code);
    if (mtaBeingModified) {
      let index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.indexOf(mtaBeingModified);
      if (this.validIndex(index)) {
        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[index] = this.mta;
      }
    } else {
      if (this.relativeToAssetIndex != undefined) {
        if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables) {this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables = [];}
        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.splice(this.relativeToAssetIndex + 1, 0, this.mta);
      } else {
        if (!this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables) {this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables = [];}
        this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.splice(this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.length, 0, this.mta);
      }
    }
    this.initialize(this.currentTabIndex, this.currentSectionIndex);
    this.clickOnQuestionDiv(undefined);
  } 

  updateCurrentTabEntireTab(): void {
    let mtaBeingModified = this.questionnaire.find(item => item.code == this.mta.code);
    if (mtaBeingModified) {
      let index = this.questionnaire.indexOf(mtaBeingModified);
      if (this.validIndex(index)) {
        this.questionnaire[index] = this.mta;
      }
    } else {
      if (this.relativeToAssetIndex != undefined) {
        if (!this.questionnaire) {this.questionnaire = [];}
        this.questionnaire.splice(this.relativeToAssetIndex + 1, 0, this.mta);
      }
    }
    this.initialize(this.currentTabIndex, this.currentSectionIndex);
    this.clickOnQuestionDiv(undefined);
  }

  // TODO: Not part of 1st release: Add validtion handler function
  addValidation(): void {
    let validationToAdd = {
      questionnaire_asset_id: this.mta.id,
      validation_key: '',
      validation_value: ''
    };
    if (!this.mta.survey_q_asset_validations) {this.mta.survey_q_asset_validations = [];}
    this.mta.survey_q_asset_validations.push(validationToAdd);
  }

  removeValidation(validationToRemove): void {
    if (validationToRemove.id) {
      this.mta.survey_q_asset_validations = this.mta.survey_q_asset_validations.filter(item => item.id != validationToRemove.id);
    } else {
      let lastValidatorIndex = this.lastMatchingObjectIndex(this.mta.survey_q_asset_validations, validationToRemove.validation_key);
      // console.log('lastValidatorIndex', lastValidatorIndex);
      this.mta.survey_q_asset_validations.splice(lastValidatorIndex, 1);
    }
  }

  lastMatchingObjectIndex(array, matchingAttributeValue) {
    let index = array.length - 1;
    for ( ; index >= 0; index--) {
        if (array[index].validation_key == matchingAttributeValue) {
          break;
        }
    }
    return index;
  }

  changeMandatory(event): void {
    if (event.target.checked) {
      if (this.mta.survey_q_asset_validations.find(item => item.validation_key == 'mandatory')) {
      } else {
        let validationToAdd = {
          questionnaire_asset_id: this.mta.id,
          validation_key: 'mandatory',
          validation_value: 'true'
        };
        this.mta.survey_q_asset_validations.push(validationToAdd);
      }
    } else {
      if (this.mta.survey_q_asset_validations.find(item => item.validation_key == 'mandatory')) {
        this.mta.survey_q_asset_validations = this.mta.survey_q_asset_validations.filter(item => item.validation_key != 'mandatory');
      }
    }
  }

  addToMTAIssues(issue): void {
    if (!this.mtaIssues.find(item => item.code == issue.code)) {
      this.mtaIssues.push(issue);
    }
  }

  // Valiate the MTA before committing the changes
  validateMTA(): boolean {
    // If qa_subtype is select or multiselect, it must have at least 1 option
    if (this.mta.id && (this.mta.qa_subtype == 'select' || this.mta.qa_subtype == 'multiselect') && (!this.mta.options || this.mta.options.length == 0 || !this.mta.options.find(item => !item.removed_from_questionnaire))) {
      this.addToMTAIssues({code: 1, text: 'Must have at least 1 option'});
      this.mtaIssues.push();
      return false;
    }

    // If qa_subtype is text or number, it must have 0 options
    if (this.mta.id && (this.mta.qa_subtype == 'text' || this.mta.qa_subtype == 'number') && (this.mta.options && this.mta.options.length > 0 && this.mta.options.find(item => !item.removed_from_questionnaire))) {
      this.addToMTAIssues({code: 2, text: 'Please remove all options'});
      return false;
    }

    // If mta has uom_question the mta must have a valid qa_subtype
    if (this.mta.id && !(this.mta.qa_subtype == 'text' || this.mta.qa_subtype == 'number') && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
      this.addToMTAIssues({code: 3, text: 'Question must be of type Number or Text'});
      return false;
    }

    // Must not have duplicated validators
    if (this.mta.id && this.mta.survey_q_asset_validations && this.mta.survey_q_asset_validations.length > 0) {
      if (this.hasObjectWithDuplicates(this.mta.survey_q_asset_validations, 'validation_key')) {
        this.addToMTAIssues({code: 4, text: 'Only one validator of each type is permitted'});
        return false;
      }
    }

    // Must not have duplicated uom_questions (two uom_questions of the same qa_subtype)
    if (this.mta.id && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
      if (this.hasObjectWithDuplicates(this.mta.uom_questions, 'qa_subtype')) {
        this.addToMTAIssues({code: 5, text: 'Only one unit of measurement of each type is permitted'});
        return false;
      }
    }

    // Each validator must have a not-null not-blank value
    if (this.mta.id && this.mta.survey_q_asset_validations && this.mta.survey_q_asset_validations.length > 0) {
      if (this.hasObjectWithBlankValue(this.mta.survey_q_asset_validations, 'validation_value')) {
        this.addToMTAIssues({code: 6, text: 'Each validator must have a value'});
        return false;
      }

      if (this.hasObjectWithBlankValue(this.mta.survey_q_asset_validations, 'validation_key')) {
        this.addToMTAIssues({code: 7, text: 'Each validator must have a key'});
        return false;
      }
    }

    // If the unit qa_subtype or label is blank, throw an error
    if (this.mta.id && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
      let x = [];
      for (let i = 0; i < this.mta.uom_questions.length; i++) {
        x = x.concat(this.mta.uom_questions[i].survey_q_asset_labels);
      }
      if (this.hasObjectWithBlankValue(x, 'label')) {
        this.addToMTAIssues({code: 8, text: 'Each unit must have a label. Set default "Unit"'});
        return false;
      }

      if (this.hasObjectWithBlankValue(this.mta.uom_questions, 'qa_subtype')) {
        this.addToMTAIssues({code: 9, text: 'Please select a unit type for each unit of measurement'});
        return false;
      }
    }

    // Units are permitted only in text & number type questions
    if (this.mta.id && this.mta.uom_questions && this.mta.uom_questions.length > 0) {
      if (this.mta.qa_subtype != 'text' && this.mta.qa_subtype != 'number') {
        this.addToMTAIssues({code: 10, text: 'Units are permitted for questions of type "Text" and "Number'});
        return false;
      }
    }

    // Validate that each of the labels have non-empty string as label
    if (this.hasObjectWithBlankValue(this.mta.survey_q_asset_labels, 'label')) {
      this.addToMTAIssues({code: 11, text: 'Please enter a label'});
        return false;
    }

    // Validate that each of the options has a label
    let x = [];
    if (this.mta.options && this.mta.options.length > 0) {
      for (let i = 0; i < this.mta.options.length; i++) {
        if (this.mta.options[i].survey_q_asset_labels && this.mta.options[i].survey_q_asset_labels.length > 0) {
          for (let j = 0; j < this.mta.options[i].survey_q_asset_labels.length; j++) {
            x.push(this.mta.options[i].survey_q_asset_labels[j]);
          }
        }
      }
    }
    if (this.hasObjectWithBlankValue(x, 'label')) {
      this.addToMTAIssues({code: 12, text: 'Please enter a label for each option'});
        return false;
    }

    // Validate that both disabled & mandatory options are not selected for the question
    if (this.mta.disable_input && this.mta.isMandatory) {
      this.addToMTAIssues({code: 13, text: 'Cannot select both "Mandatory" & "Disable input" options at once'});
        return false;
    }

    // Validate that MTA has valid language_id in labels
    for (let i = 0; i < this.mta.survey_q_asset_labels.length; i++) {
      if (!this.mta.survey_q_asset_labels[i].language_id) {
        console.log('!this.mta.survey_q_asset_labels[i].language_id', this.mta.survey_q_asset_labels[i]);
        this.addToMTAIssues({code: 14, text: 'Please cancel and try making the change again.'});
        return false;
      }
    }

    // Validate that MTA options if any have valid language_id in labels
    if (this.mta.options && this.mta.options.length > 0) {
      for (let j = 0; j < this.mta.options.length; j++) {
        for (let i = 0; i < this.mta.options[j].survey_q_asset_labels.length; i++) {
          if (!this.mta.options[j].survey_q_asset_labels[i].language_id) {
            this.addToMTAIssues({code: 15, text: 'Please cancel and try making the change again.'});
            return false;
          }
        }
      } 
    }

    // Validate that the label is 300 characters or less
    for (let i = 0; i < this.mta.survey_q_asset_labels.length; i++) {
      if (this.mta.survey_q_asset_labels[i].label && this.mta.survey_q_asset_labels[i].label.length > 300) {
        this.addToMTAIssues({code: 15, text: 'One or more label length exceeds limit: 300.'});
        return false;
      }
    }

    // Validate that the MTA Option label is 300 characters or less
    if (this.mta.options && this.mta.options.length > 0) {
      for (let j = 0; j < this.mta.options.length; j++) {
        for (let i = 0; i < this.mta.options[j].survey_q_asset_labels.length; i++) {
          if (this.mta.options[j].survey_q_asset_labels[i].label && this.mta.options[j].survey_q_asset_labels[i].label.length > 300) {
            this.addToMTAIssues({code: 16, text: 'One or more label length exceeds limit: 300'});
            return false;
          }
        }
      } 
    }

    if (this.mta.qa_subtype && typeof(this.mta.qa_subtype) != 'string') {
      this.addToMTAIssues({code: 17, text: 'Please select a valid value for "Type"'});
      return false;
    }

    

    this.mtaIssues = [];
    return true;
  }

  hasObjectWithDuplicates(array, attributeToCheck) {
    let valueArr = [];
    array.forEach(element => {
      if (!element.removed_from_questionnaire) {
        valueArr.push(element[attributeToCheck]);
      }
    });
    let isDuplicate = valueArr.some(function(item, idx){ 
      return valueArr.indexOf(item) != idx 
    });
    return isDuplicate;
  }

  hasObjectWithBlankValue(array, attributeToCheck) {
    for (let i = 0; i < array.length; i++) {
      if (!array[i][attributeToCheck] || array[i][attributeToCheck] == '') {
        return true;
      }
    }
    return false;
  }

  addOrRemoveAsset(asset, action) {
    this.handleDisplay(asset, action);
    this.handleChangedAssets(asset, action);
  }

  handleDisplay(asset, action) {
    if (asset.qa_type == 'question') {
      this.mta.removed_from_questionnaire = (action == 'remove' ? true : false);
    }
    if (asset.qa_type == 'option') {
      if (this.displayLogicValues.indexOf(asset.id) > -1) {
        this.toastr.warning('This option is part of a display logic condition & cannot be removed');
        return;
      }
      let optionToRemove = this.mta.options.find(item => item.code == asset.code);
      if (optionToRemove) {
        let index = this.mta.options.indexOf(optionToRemove);
        if (this.validIndex(index)) {
          this.mta.options[index].removed_from_questionnaire = (action == 'remove' ? true : false);
        }
      }
    }
  }

  handleChangedAssets(asset, action) {
    const nowUTCString =  new Date().toUTCString();
    if (!this.questionnaireChanges['assets']) {this.questionnaireChanges['assets'] = {};}

    if (action == 'remove') {
      if (this.isRedundant(asset, action)) {
        this.questionnaireChanges['assets']['add'] = this.questionnaireChanges['assets']['add'].filter(item => !(item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code)));
      } else {
        if (!this.questionnaireChanges['assets']['remove']) {this.questionnaireChanges['assets']['remove'] = [];}
        let element = asset.id ? {qa_type: asset.qa_type, identifier: 'id', value: asset.id, at: nowUTCString} :  {qa_type: asset.qa_type, identifier: 'code', value: asset.code, at: nowUTCString};
        this.questionnaireChanges['assets']['remove'].push(element);
      }
    } else {
      if (this.isRedundant(asset, action)) {
        this.questionnaireChanges['assets']['remove'] = this.questionnaireChanges['assets']['remove'].filter(item => !(item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code)));
      } else {
        if (!this.questionnaireChanges['assets']['add']) {this.questionnaireChanges['assets']['add'] = [];}
        let element = asset.id ? {qa_type: asset.qa_type, identifier: 'id', value: asset.id, at: nowUTCString} :  {qa_type: asset.qa_type, identifier: 'code', value: asset.code, at: nowUTCString};
        this.questionnaireChanges['assets']['add'].push(element);
      }
    }
  }

  isRedundant(asset, action) {
    if (action == 'remove') {
      if (this.questionnaireChanges['assets']['add']) {
        if (this.questionnaireChanges['assets']['add'].find(item => item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code))) {
          return true;
        }
      }
    } else {
      if (this.questionnaireChanges['assets']['remove']) {
        if (this.questionnaireChanges['assets']['remove'].find(item => item.qa_type == asset.qa_type && item.value == (asset.id ? asset.id : asset.code))) {
          return true;
        }
      }
    }
    return false;
  }

  clickOnQuestionDiv(question): void {
    if (question && (this.modifyAutocalculateFormulaForAsset || this.autocalculateFormulaForAssetExists)) {
      if (question.qa_subtype != 'number') {
        this.toastr.error('Select a question of type "Number"');
        return;
      }
      if ((this.modifyAutocalculateFormulaForAsset && this.modifyAutocalculateFormulaForAsset.code != question.code) || (this.autocalculateFormulaForAssetExists && this.autocalculateFormulaForAssetExists.code != question.code)) {
        this.setQuestionInModifyingAutocalcualteFormula(question);
      }
    } else if (this.modifyDisplayLogicForAsset) {
      if (question && !this.displayLogicDisplayIfKey && this.canSelectQuestionInDisplayLogic(question)) {
        this.displayLogicDisplayIfKey = question;
        this.displayLogicDisplayIfValue = undefined;
        this.addDisplayLogic(question.id, undefined);
      }
    } else {
      this.showAddButtonAfterThisQuestion = question;
    }
  }

  canSelectQuestionInDisplayLogic(clickedQuestion) {
    // Dont allow selection of the question in its own display logic
    if (this.modifyDisplayLogicForAsset.code == clickedQuestion.code) {
      this.toastr.error('Cannot select the same question');
      return false;
    }

    // Dont allow selection of a question in its parent's display logic
    if (this.modifyDisplayLogicForAsset.code == clickedQuestion.parent_code) {
      this.toastr.error('Cannot select a child question');
      return false;
    }

    // Dont allow selection of a question that has been removed from the questionnaire
    if (clickedQuestion.removed_from_questionnaire) {
      this.toastr.error('Please select a question that is not remvoved from the questionnaire');
      return false;
    }
    return true;
  }

  changeAssetSequence(asset, direction): void {
    if (asset.qa_type == 'question') {
      this.changeQuestionSequence(asset, direction);
    }

    if (asset.qa_type == 'section') {
      this.changeSectionSequence(asset, direction);
    }

    if (asset.qa_type == 'tab') {
      this.changeTabSequence(asset, direction);
    }

    if (asset.qa_type == 'subsection') {
      this.changeSubsectionSequence(asset, direction);
    }

    if (asset.qa_type == 'table') {
      this.changeTableSequence(asset, direction);
    }

    if (asset.qa_type == 'option') {
      this.changeOptionSequence(asset, direction);
    }
  }

  changeTabSequence(tab, direction): void {
    let allChildrenAssets = this.questionnaire;
    let x = allChildrenAssets.find(item => item.code == tab.code);
    if (x) {
      let indexOfTab = allChildrenAssets.indexOf(x);
      if (this.validIndex(indexOfTab)) {
        let moveTabToIndex = indexOfTab + direction;
        let destinationAsset = allChildrenAssets[moveTabToIndex];
        let newSequenceOfDestinationAsset = tab.qa_sequence;
        tab.qa_sequence = destinationAsset.qa_sequence;
        destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
        if (this.validIndex(indexOfTab) && this.validIndex(moveTabToIndex)) {
          this.questionnaire[indexOfTab] = destinationAsset;
          this.questionnaire[moveTabToIndex] = tab;
        }
        this.mta = tab;
        this.updateChangedAssets();

        this.mta = destinationAsset;
        this.updateChangedAssets();

        this.initialize(moveTabToIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined);
      }
    }
  }

  changeSectionSequence(section, direction): void {
    let parentAsset = this.getParentAsset(section);
    const allChildrenAssets = parentAsset.sections;
    let x = allChildrenAssets.find(item => item.code == section.code);
    if (x) {
      let indexOfSection = allChildrenAssets.indexOf(x);
      if (this.validIndex(indexOfSection)) {
        let moveQuestionToIndex = indexOfSection + direction;
        let destinationAsset = parentAsset.sections[moveQuestionToIndex];
        let newSequenceOfDestinationAsset = section.qa_sequence;
        section.qa_sequence = destinationAsset.qa_sequence;
        destinationAsset.qa_sequence = newSequenceOfDestinationAsset;
        if (this.validIndex(indexOfSection) && this.validIndex(moveQuestionToIndex)) {
          this.questionnaire[this.currentTabIndex].sections[indexOfSection] = destinationAsset;
          this.questionnaire[this.currentTabIndex].sections[moveQuestionToIndex] = section;
        }
        this.mta = section;
        this.updateChangedAssets();

        this.mta = destinationAsset;
        this.updateChangedAssets();

        this.initialize(this.currentTabIndex, moveQuestionToIndex);
        this.clickOnQuestionDiv(undefined);
      }
    }
  }

  changeQuestionSequence(question, direction): void {
    let parentAsset = this.getParentAsset(question);

    const allChildrenAssets = parentAsset.questions;

    let questionBeingModified = allChildrenAssets.find(item => item.code == question.code);
    if (questionBeingModified) {
      let indexOfQuestion = allChildrenAssets.indexOf(questionBeingModified);
      if (this.validIndex(indexOfQuestion)) {
        let moveQuestionToIndex = indexOfQuestion + direction;

        let destinationAsset = parentAsset.questions[moveQuestionToIndex];

        let newSequenceOfDestinationAsset = question.qa_sequence;
        question.qa_sequence = destinationAsset.qa_sequence;
        destinationAsset.qa_sequence = newSequenceOfDestinationAsset;


        if (this.validIndex(indexOfQuestion) && this.validIndex(moveQuestionToIndex)) {
          if (parentAsset.qa_type == 'section') {
            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[indexOfQuestion] = destinationAsset;
            this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[moveQuestionToIndex] = question;
          } else if (parentAsset.qa_type == 'subsection') {
            let parentSubsection = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.find(item => item.code == parentAsset.code);
            if (parentSubsection) {
              let index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections.indexOf(parentSubsection);
              if (this.validIndex(index)) {
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[index].questions[indexOfQuestion] = destinationAsset;
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[index].questions[moveQuestionToIndex] = question;
              }
            }
          } else if (parentAsset.qa_type == 'table') {
            let parentSubsection = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.find(item => item.code == parentAsset.code);
            if (parentSubsection) {
              let index = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables.indexOf(parentSubsection);
              if (this.validIndex(index)) {
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[index].questions[indexOfQuestion] = destinationAsset;
                this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[index].questions[moveQuestionToIndex] = question;
              }
            }
          } 
        }

        this.mta = question;
        this.updateChangedAssets();

        this.mta = destinationAsset;
        this.updateChangedAssets();


        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined);
      }
    }
  }

  changeOptionSequence(option, direction): void {
    let x = JSON.parse(JSON.stringify(this.mta.options));
    const allChildrenAssets = x;
    let optionBeingModified = allChildrenAssets.find(item => item.code == option.code);
    if (optionBeingModified) {
      let indexOfOption = allChildrenAssets.indexOf(optionBeingModified);
      if (this.validIndex(indexOfOption)) {
        let moveOptionToIndex = indexOfOption + direction;

        let destinationAsset = x[moveOptionToIndex];

        let newSequenceOfDestinationAsset = option.qa_sequence;
        option.qa_sequence = destinationAsset.qa_sequence;
        destinationAsset.qa_sequence = newSequenceOfDestinationAsset;

        if (this.validIndex(indexOfOption) && this.validIndex(moveOptionToIndex)) {
          x[indexOfOption] = destinationAsset;
          x[moveOptionToIndex] = option;
          this.mta.options = JSON.parse(JSON.stringify(x));
          this.initialize(this.currentTabIndex, this.currentSectionIndex);
        }
      }
    }
  }

  changeSubsectionSequence(subsection, direction): void {
    let parentAsset = this.getParentAsset(subsection);

    const allChildrenAssets = parentAsset.subsections;

    let subsectionBeingModified = allChildrenAssets.find(item => item.code == subsection.code);
    if (subsectionBeingModified) {
      let indexOfSubsection = allChildrenAssets.indexOf(subsectionBeingModified);
      if (this.validIndex(indexOfSubsection)) {
        let moveSubsectionToIndex = indexOfSubsection + direction;
        let destinationAsset = parentAsset.subsections[moveSubsectionToIndex];

        let newSequenceOfDestinationAsset = subsection.qa_sequence;
        subsection.qa_sequence = destinationAsset.qa_sequence;
        destinationAsset.qa_sequence = newSequenceOfDestinationAsset;


        if (this.validIndex(indexOfSubsection) && this.validIndex(moveSubsectionToIndex)) {
          this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[indexOfSubsection] = destinationAsset;
          this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[moveSubsectionToIndex] = subsection;
        }

        this.mta = subsection;
        this.updateChangedAssets();

        this.mta = destinationAsset;
        this.updateChangedAssets();


        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined);
      }
    }
  }

  changeTableSequence(table, direction): void {
    let parentAsset = this.getParentAsset(table);

    const allChildrenAssets = parentAsset.tables;

    let tableBeingModified = allChildrenAssets.find(item => item.code == table.code);
    if (tableBeingModified) {
      let indexOfTable = allChildrenAssets.indexOf(tableBeingModified);
      // console.log('indexOfTable', indexOfTable);
      if (this.validIndex(indexOfTable)) {
        let moveTableToIndex = indexOfTable + direction;
        let destinationAsset = parentAsset.tables[moveTableToIndex];

        let newSequenceOfDestinationAsset = table.qa_sequence;
        table.qa_sequence = destinationAsset.qa_sequence;
        destinationAsset.qa_sequence = newSequenceOfDestinationAsset;


        if (this.validIndex(indexOfTable) && this.validIndex(moveTableToIndex)) {
          this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[indexOfTable] = destinationAsset;
          this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[moveTableToIndex] = table;
        }

        this.mta = table;
        this.updateChangedAssets();

        this.mta = destinationAsset;
        this.updateChangedAssets();

        this.initialize(this.currentTabIndex, this.currentSectionIndex);
        this.clickOnQuestionDiv(undefined);
      }
    }
  }

  modifyDisplayLogic(asset, template, parentSubsection, parentSection): void {
    if (asset.survey_q_asset_display_logic && asset.survey_q_asset_display_logic.length > 0) {
      this.displayLogicForAssetExists = asset;

      loop1:
      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          this.displayLogicDisplayIfKey = this.questionnaire[i].sections[j].questions.find(item => item.id == this.displayLogicForAssetExists.survey_q_asset_display_logic[0].display_if_key);
          if (this.displayLogicDisplayIfKey) {
            break loop1;
          }
          if (!this.displayLogicDisplayIfKey && this.questionnaire[i].sections[j].subsections && this.questionnaire[i].sections[j].subsections.length > 0) {
            for (let k = 0; k < this.questionnaire[i].sections[j].subsections.length; k++) {
              this.displayLogicDisplayIfKey = this.questionnaire[i].sections[j].subsections[k].questions.find(item => item.id == this.displayLogicForAssetExists.survey_q_asset_display_logic[0].display_if_key);
              if (this.displayLogicDisplayIfKey) {break loop1;}
            }
          }
          if (!this.displayLogicDisplayIfKey && this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
            for (let k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
              this.displayLogicDisplayIfKey = this.questionnaire[i].sections[j].tables[k].questions.find(item => item.id == this.displayLogicForAssetExists.survey_q_asset_display_logic[0].display_if_key);
              if (this.displayLogicDisplayIfKey) {break loop1;}
            }
          }
        }
      }

      if (asset.survey_q_asset_display_logic[0].logic_type == 'value_matches') {
        this.displayLogicDisplayIfValue = this.displayLogicDisplayIfKey.options.find(item => item.id == this.displayLogicForAssetExists.survey_q_asset_display_logic[0].display_if_value);
      } else {
        this.displayLogicDisplayIfValueExists = true;
      }
    } else {
      this.modifyDisplayLogicForAsset = asset;
    }
  }

  modifyAutocalculateFormula(asset, parentSubsection, parentSection): void {
    this.modifyingAutoCalcFormulaInTab = this.currentTabIndex;
    this.modifyingAutoCalcFormulaInSection = this.currentSectionIndex;
    if (asset.survey_q_asset_autocalculate_formulas && asset.survey_q_asset_autocalculate_formulas.length > 0) {
      const formula = asset.survey_q_asset_autocalculate_formulas[0].formula;
      const formulaArray = formula.split(",");

      const formulaQAssetIdArray = [];
      for (let p = 0; p < formulaArray.length; p++) {
        // Ignore non-number elements of the formula
        if (isNaN(parseInt(formulaArray[p]))) {}
        else {
          formulaQAssetIdArray.push(parseInt(formulaArray[p]));
        }
      }

      let formulaQAssetArray = [];

      for (let i = 0; i < this.questionnaire.length; i++) {
        for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
          let x = this.questionnaire[i].sections[j].questions.filter(item => formulaQAssetIdArray.indexOf(item.id) > -1);
          formulaQAssetArray = formulaQAssetArray.concat(x);
        
          for (let k = 0; k < this.questionnaire[i].sections[j].subsections.length; k++) {
            let y = this.questionnaire[i].sections[j].subsections[k].questions.filter(item => formulaQAssetIdArray.indexOf(item.id) > -1);
            formulaQAssetArray = formulaQAssetArray.concat(y);
          }

          for (let k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
            let z = this.questionnaire[i].sections[j].tables[k].questions.filter(item => formulaQAssetIdArray.indexOf(item.id) > -1);
            formulaQAssetArray = formulaQAssetArray.concat(z);
          }
        }
      }
      this.autocalculateFormulaArray = [];
      for (let i = 0; i < formulaArray.length; i++) {
        if (isNaN(parseInt(formulaArray[i]))) {this.autocalculateFormulaArray.push({part: formulaArray[i], isAsset: false})}
        else {
          let x = formulaQAssetArray.find(item => item.id == formulaArray[i]);
          if (x) {
            this.autocalculateFormulaArray.push({part: x, isAsset: true});
          } else {
            // TODO: Handle error
          }
        }
      }
      this.autocalculateFormulaForAssetExists = asset;
    } else {
      this.modifyAutocalculateFormulaForAsset = asset;
      this.autocalculateFormulaArray = [];
      this.autocalculateFormulaArray.push({isAsset: true, editing: true});
      this.autocalculateFormulaArray.push({isAsset: false, part: "*"});
      this.autocalculateFormulaArray.push({isAsset: true, editing: true});
    }
  }

  validateAutocalculateFormulaArray() {
    if (!this.autocalculateFormulaArray || this.autocalculateFormulaArray.length == 0) {
      return false;
    } else {
      for (let i = 0; i < this.autocalculateFormulaArray.length; i++) {
        if (!this.autocalculateFormulaArray[i].part) {
          return false;
        }
      }
      return true;
    }
  }

  saveAutocalculateFormulaForAsset(): void {
    if (!this.validateAutocalculateFormulaArray()) {
      this.toastr.error('Please enter a valid formula');
      return;
    }

    // this.initialize(this.modifyingAutoCalcFormulaInTab, this.modifyingAutoCalcFormulaInSection);
    let newFormulaArray = [];
    for (let i = 0; i < this.autocalculateFormulaArray.length; i++) {
      if (this.autocalculateFormulaArray[i].isAsset) {
        newFormulaArray.push(this.autocalculateFormulaArray[i].part.id);
      } else {
        newFormulaArray.push(this.autocalculateFormulaArray[i].part);
      }
    }
    const newFormulaString = newFormulaArray.join();

    if (this.autocalculateFormulaForAssetExists) {
      this.autocalculateFormulaForAssetExists.survey_q_asset_autocalculate_formulas[0].formula = newFormulaString;
      console.log('autocalculateFormulaForAssetExists');
      this.mta = this.autocalculateFormulaForAssetExists;
    } else if (this.modifyAutocalculateFormulaForAsset) {
      if (!this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas || this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas.length == 0) {this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas = [{}];}
      this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas[0]['questionnaire_asset_id'] = this.modifyAutocalculateFormulaForAsset.id;
      this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas[0]['formula'] = newFormulaString;
      this.mta = this.modifyAutocalculateFormulaForAsset;
    }
    console.log('this.mta', this.mta);
    let x = JSON.stringify(this.mta);
    // this.mta.disable_input = true;
    let qaBeingModifiedId = this.mta.id;
    this.updateModifiesQasOfFormulaParts(qaBeingModifiedId);
    
    this.currentTabIndex = this.modifyingAutoCalcFormulaInTab;
    this.currentSectionIndex = this.modifyingAutoCalcFormulaInSection;
    this.mta = JSON.parse(x);
    this.updateCurrentTab();
    this.updateChangedAssets();
    this.resetAutocalculateFormula();
  }

  updateModifiesQasOfFormulaParts(qaBeingModifiedId): void {
    for (let i = 0; i < this.autocalculateFormulaArray.length; i++) {
      if (this.autocalculateFormulaArray[i].isAsset) {
        this.mta = this.autocalculateFormulaArray[i].part;
        this.mta.modifies_qas = qaBeingModifiedId;
        this.updateChangedAssets();
        this.updateCurrentTab();
      }
    }
  }

  // setDisplayLogicTab(event): void {
  //   this.displayLogicKeyTab = this.questionnaire.find(item => item.code == this.displayLogicKeyTabCode);
  // }

  // setDisplayLogicSection(event): void {
  //   console.log('display logic section', this.displayLogicKeySection);
  //   this.displayLogicKeySection = this.displayLogicKeyTab['sections'].find(item => item.code == this.displayLogicKeySectionCode);
  //   if (this.displayLogicTypeSelected.type_key == 'value_matches') {
  //     this.displayLogicKeySection['questions'] = this.displayLogicKeySection['questions'].filter(item => item.qa_subtype == 'select' || item.qa_subtype == 'multiselect');
  //   }
  // }

  // setDisplayLogicQuestion(event): void {
  //   console.log('display logic question', this.displayLogicKeyQuestion);
  //   let x = this.displayLogicKeyQuestion;
  //   this.displayLogicKeyQuestion = this.displayLogicKeySection['questions'].find(item => item.code == this.displayLogicKeyQuestionCode);
  // }

  // setDisplayLogicOptionValue(event): void {
  //   console.log('display logic option', this.displayLogicKeyOptionValue);
  //   let x = this.displayLogicKeyOptionValue;
  //   this.displayLogicKeyOptionValue = this.displayLogicKeyQuestion['options'].find(item => item.code ==  this.displayLogicKeyOptionValueCode);
  // }

  saveDisplayLogicChanges(): void {
    
  }

  canAddOptionToDisplayLogic(option) {
    return true;
  }

  setOptionAsDisplayLogic(event, option, question): void {
    if (!this.modifyDisplayLogicForAsset) {return;}
    if (!this.modifyDisplayLogicForAsset.id) {this.toastr.warning('Please save changes first'); return;}
    if (this.canAddOptionToDisplayLogic(option)) {
      this.displayLogicDisplayIfKey = question;
      this.displayLogicDisplayIfValue = option;
      
      this.addDisplayLogic(question.id, option.id);
    }
  }

  addDisplayLogic(ifKey, ifValue): void {
    let displayLogicToAdd = {
      questionnaire_asset_id: this.modifyDisplayLogicForAsset.id,
      display_if_key: ifKey,
      display_if_value: ifValue,
      logic_type: ifValue ? 'value_matches' : 'value_exists'
    };

    if (!this.modifyDisplayLogicForAsset.survey_q_asset_display_logic || this.modifyDisplayLogicForAsset.survey_q_asset_display_logic.length == 0) {
      this.modifyDisplayLogicForAsset.survey_q_asset_display_logic = [];
    }
    this.modifyDisplayLogicForAsset.survey_q_asset_display_logic = [displayLogicToAdd];
  }

  removeDisplayLogic(): void {
    if (this.modifyDisplayLogicForAsset) {
      this.modifyDisplayLogicForAsset.survey_q_asset_display_logic = [];
      this.mta = this.modifyDisplayLogicForAsset;
    } else if (this.displayLogicForAssetExists) {
      this.displayLogicForAssetExists.survey_q_asset_display_logic = [];
      this.mta = this.displayLogicForAssetExists;
    }
    this.updateChangedAssets();
    this.updateCurrentTab();
    this.resetDisplayLogic();
  }

  saveDisplayLogic(): void {
    this.mta = this.modifyDisplayLogicForAsset;
    this.updateChangedAssets();
    this.updateCurrentTab();
    this.resetDisplayLogic();
  }

  cancelDisplayLogicChanges(): void {
    this.resetDisplayLogic();
  }

  resetDisplayLogic(): void {
    this.modifyDisplayLogicForAsset = undefined;
    this.displayLogicForAssetExists = undefined;
    this.displayLogicDisplayIfKey = undefined;
    this.displayLogicDisplayIfValue = undefined;
    this.displayLogicDisplayIfValueExists = undefined;
  }

  addUOM(): void {
    if (!this.mta.id || this.mta.id == 0) {
      this.toastr.warning('Please save changes first');
      return;  
    }
    if (!this.mta.uom_questions) {this.mta.uom_questions = [];}
    let uomToAdd = {
      code: uuidv4(),
      qa_type: 'uom_question',
      qa_subtype: 0,
      survey_id: this.surveyId,
      parent_id: this.mta.id,
      parent_code: this.mta.code,
      survey_q_asset_labels: [{language_id: this.getLanguageId(), label: 'Unit'}],
      is_table_question: this.mta.is_table_question
    }
    this.mta.include_uom_question = true;
    this.mta.uom_questions.push(uomToAdd);
  }

  removeUOM(): void {
    this.mta.include_uom_question = false;
    this.mta.uom_questions = [];
  }

  changeParent(oldParentCode): void {
    if (this.mta.qa_type == 'section') {
      const tabMovedFrom = this.questionnaire.find(item => item.code == oldParentCode);
      if (tabMovedFrom) {
        const index = this.questionnaire.indexOf(tabMovedFrom);
        if (this.validIndex(index)) {
          this.questionnaire[index].sections = this.questionnaire[index].sections.filter(item => item.code != this.mta.code);
        }
      }

      const tabMovedTo = this.questionnaire.find(item => item.code == this.mta.parent_code);
      if (tabMovedTo) {
        const index = this.questionnaire.indexOf(tabMovedTo);
        if (this.validIndex(index)) {
          this.tabClicked(index, 0);
          this.mta.parent_id = tabMovedTo.id;
          this.mta.qa_sequence = this.getSequence('section');
        }
      }
    } else {
      // Remove the child asset from the old parent section
      const sectionMovedFrom = this.currentTab.sections.find(item => item.code == oldParentCode);
      if (sectionMovedFrom) {
        const index = this.currentTab.sections.indexOf(sectionMovedFrom);
        if (this.validIndex(index)) {
          if (this.mta.qa_type == 'question') {
            this.currentTab.sections[index].questions = this.currentTab.sections[index].questions.filter(item => item.code != this.mta.code);
          }
          if (this.mta.qa_type == 'subsection') {
            this.currentTab.sections[index].subsections = this.currentTab.sections[index].subsections.filter(item => item.code != this.mta.code);
          }
          if (this.mta.qa_type == 'table') {
            this.currentTab.sections[index].tables = this.currentTab.sections[index].tables.filter(item => item.code != this.mta.code);
          }
        }
      }
       // Find the index of the new parent section, set it as the current Section. Update the mta
      const sectionMovedTo = this.currentTab.sections.find(item => item.code == this.mta.parent_code);
      if (sectionMovedTo) {
        const index = this.currentTab.sections.indexOf(sectionMovedTo);
        if (this.validIndex(index)) {
          this.sectionClicked(index);
          this.mta.parent_id = sectionMovedTo.id;
          this.mta.qa_sequence = this.getSequence('question');
        }
      }
    }
  }

  setMtaHasOtherOption() {
    if (this.mta.qa_type == 'question') {
      if (this.mta.options && this.mta.options.length > 0) {
        let x = this.mta.options.find(item => item.qa_subtype == 'other');
        // console.log('x', x);
        this.mta.mtaHasOtherOption = x ? true : false;
      }
    }
  }

  addIsOtherOptionQuestionAsset(): void {
    if (!this.mta.id || this.mta.id == 0) {
      this.toastr.warning('Please save changes first');
      return;  
    }
    if (!this.mta.other_questions) {this.mta.other_questions = [];}
    let otherQuestionToAdd = {
      code: uuidv4(),
      qa_type: 'other_question',
      survey_id: this.surveyId,
      parent_id: this.mta.id,
      parent_code: this.mta.code,
      survey_q_asset_labels: [{language_id: this.getLanguageId(), label: 'Other'}],
      is_table_question: this.mta.is_table_question
    };
    this.mta.other_questions.push(otherQuestionToAdd);
  }

  discardQuestionnaireChanges(): void {
    this.changedAssets = [];
    this.mta = undefined;
    this.updateQuestionnaireAfterSaving();
  }

  // TODO: Done: Implement cancellation of autocalculate formula changes
  cancelAutocalculateFormulaChanges(): void {
    this.resetAutocalculateFormula();  
  }

    // TODO: Done: Implement removal of autocalculate formulat for given asset
  removeAutocalculateFormula(): void {
    if (this.modifyAutocalculateFormulaForAsset) {
      this.modifyAutocalculateFormulaForAsset.survey_q_asset_autocalculate_formulas = [];
      // TODO: Not disabling input automatically so not relevant: Handle scenariow here input may be disabled for another reason (not auto calcualte)
      // this.modifyAutocalculateFormulaForAsset.disable_input = false;
      this.mta = this.modifyAutocalculateFormulaForAsset;
    } else if (this.autocalculateFormulaForAssetExists) {
      this.autocalculateFormulaForAssetExists.survey_q_asset_autocalculate_formulas = [];
      this.mta = this.autocalculateFormulaForAssetExists;
    }
    this.updateChangedAssets();
    this.updateCurrentTab();
    this.updateModifiesQasOfFormulaParts(null);
    this.resetAutocalculateFormula();
  }

  resetAutocalculateFormula(): void {
    this.modifyAutocalculateFormulaForAsset = undefined;
    this.autocalculateFormulaForAssetExists = undefined;
    this.modifyingAutoCalcFormulaInTab = undefined;
    this.modifyingAutoCalcFormulaInSection = undefined;
    this.autocalculateFormulaArray = [];
  }

  modifyThisFormulaPart(part, index): void {

  }

  removeThisFormulaPart(part, index): void {
    // At this index in autocalculateFormulaArray set the part with attribute editing: true
    this.autocalculateFormulaArray[index] = {editing: true, isAsset: true};
    this.selectAutocalculatePartAtThisIndex(index);
  }

  setQuestionInModifyingAutocalcualteFormula(question): void {
    let x = this.autocalculateFormulaArray.find(item => item.selectedForSetting);
    if (x) {
      let indexToSetQuestionAt = this.autocalculateFormulaArray.indexOf(x);
      if (this.validIndex(indexToSetQuestionAt)) {
        this.autocalculateFormulaArray[indexToSetQuestionAt] = {part: question, isAsset: true};
      }
    }
  }

  selectAutocalculatePartAtThisIndex(index): void {
    this.autocalculateFormulaArray[index].selectedForSetting = true;
  }

  appendNewOperator(): void {
    this.autocalculateFormulaArray.push({isAsset: false, part: "*"});
    this.autocalculateFormulaArray.push({isAsset: true, editing: true});
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  collectDisplayLogicKeyValues(): void {
    this.displayLogicKeys = []; this.displayLogicValues = [];
    if (this.questionnaire && this.questionnaire.length > 0) {
      for (let i = 0; i < this.questionnaire.length; i++) {
        if (this.questionnaire[i].sections && this.questionnaire[i].sections.length > 0) {
          for (let j = 0; j < this.questionnaire[i].sections.length; j++) {
            for (let k = 0; k < this.questionnaire[i].sections[j].questions.length; k++) {
              if (this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic.length > 0) {
                for (let m = 0; m < this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic.length; m++) {
                  this.displayLogicKeys.push(this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_key.toString());
                  if (this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_value !== null) {
                    this.displayLogicValues.push(this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_value.toString());
                  }
                }
              }
            }
            for (let p = 0; p < this.questionnaire[i].sections[j].subsections.length; p++) {
              for (let k = 0; k < this.questionnaire[i].sections[j].subsections[p].questions.length; k++) {
                if (this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic.length > 0) {
                  for (let m = 0; m < this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic.length; m++) {
                    this.displayLogicKeys.push(this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_key.toString());
                    if (this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_value !== null) {
                      this.displayLogicValues.push(this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_value.toString());
                    }
                  }
                }
              } 
            }
            for (let p = 0; p < this.questionnaire[i].sections[j].tables.length; p++) {
              for (let k = 0; k < this.questionnaire[i].sections[j].tables[p].questions.length; k++) {
                if (this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic.length > 0) {
                  for (let m = 0; m < this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic.length; m++) {
                    this.displayLogicKeys.push(this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_key.toString());
                    if (this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_value !== null) {
                      this.displayLogicValues.push(this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_value.toString());
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  markChildrenDeleted(asset){
    let assetTypes = [];
    this.assetTypes.forEach(item => {
      assetTypes.push(item.type + 's');
    });
    for (let j = 0; j < assetTypes.length; j++) {
      console.log('assetTypes j', assetTypes[j]);
      if (asset[assetTypes[j]]) {
        for (let k = 0; k < asset[assetTypes[j]].length; k++) {
          this.mta = asset[assetTypes[j]][k];
          this.changeQuestion('remove', true);
          if (asset[assetTypes[j]][k][assetTypes[j]]) {
            for (let p = 0; p < asset[assetTypes[j]][k][assetTypes[j]].length; p++) {
              this.mta = asset[assetTypes[j]][k][assetTypes[j]][p];
              this.changeQuestion('remove', true);
              if (asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]]) {
                for (let q = 0; q < asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]].length; q++) {
                  this.mta = asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q];
                  this.changeQuestion('remove', true);
                  if (asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]]) {
                    for (let r = 0; r < asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]].length; r++) {
                      this.mta = asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]][r];
                      this.changeQuestion('remove', true);
                      if (asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]][r][assetTypes[j]]) {
                        for (let s = 0; s < asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]][r][assetTypes[j]].length; s++) {
                          this.mta = asset[assetTypes[j]][k][assetTypes[j]][p][assetTypes[j]][q][assetTypes[j]][r][assetTypes[j]][s];
                          this.changeQuestion('remove', true);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        console.log('2046');
      }
    }
    console.log('questionnaire', this.questionnaire);
  }


  markChildrenDeleted2(asset): void {
    for (let i = 0; i < this.assetTypes.length; i++) {
      let key = this.assetTypes[i].type + 's';
      if (asset[key]) {
        for (let j = 0; j < asset[key].length; j++) {
          this.mta = asset[key][j];
          this.changeQuestion('remove', true);
          this.markChildrenDeleted2(asset[key][j]);
        }
      }
    }
    this.mta = undefined;
  }
  initializeBlankQuestionnaire(): void {
    // setTimeout(() => {
      const assetToAdd = {
        survey_id: this.surveyId,
        code: uuidv4(),
        qa_type: 'tab',
        parent_id: null,
        parent_code: null,
        qa_sequence: 1,
        qa_subtype: null,
        survey_q_asset_labels: [{language_id: this.getLanguageId(), label: 'New tab'}],
        survey_q_asset_validations: [],
        survey_q_asset_display_logic: [],
        survey_q_asset_autocalculate_formulas: [],
        is_table_question: false
      };
      this.questionnaire = [];
      this.questionnaire.push(assetToAdd);
      this.disableCancelOfModifyingAssetModal = true;
      this.isBlankQuestionnaire = true;
      this.modifyThisAsset(this.questionnaire[0],this.modifyAssetModalTemp,'tab')
    // }, 500);
  }
  printSurvey(){
    let surveyQuestionnaire = document.getElementById('surveyQuestionnaire');
    surveyQuestionnaire.classList.remove('d-none');
    surveyQuestionnaire.classList.add('d-block');
    document.documentElement.style.overflowY = 'hidden';
  }
  closePrintSurvey(){
    let surveyQuestionnaire = document.getElementById('surveyQuestionnaire');
    surveyQuestionnaire.classList.remove('d-block');
    surveyQuestionnaire.classList.add('d-none');
    document.documentElement.style.overflowY = 'scroll';    
  }


}


// changeOptionSequence(option, direction): void {
//   let parentAsset = this.getParentAsset(option); // Question
//   console.log('parentAsset', parentAsset);
//   let grandParentAsset = this.getParentAsset(parentAsset); // Section, subsection, or table

//   let allChildrenAssets = parentAsset.options;
//   console.log('allChildrenAssets', allChildrenAssets);

//   let optionBeingModified = allChildrenAssets.find(item => item.code == option.code);
//   if (optionBeingModified) {
    
//   }

//   let indexOfOption = allChildrenAssets.indexOf(optionBeingModified);
//   console.log('indexOfOption', indexOfOption);

//   let moveOptionToIndex = indexOfOption + direction;
//   console.log('moveOptionToIndex', moveOptionToIndex);

//   let destinationAsset = parentAsset.options[moveOptionToIndex];

//   let newSequenceOfDestinationAsset = option.qa_sequence;
//   option.qa_sequence = destinationAsset.qa_sequence;
//   destinationAsset.qa_sequence = newSequenceOfDestinationAsset;


//   if (this.validIndex(indexOfOption) && this.validIndex(moveOptionToIndex)) {

//     let q; let qIndex; // index of parentAsset question
//     let s; let sIndex; // index of grandParentAsset subsection
//     let t; let tIndex; // index of grandParentAsset table
//     let qs; let qsIndex; // index of grandParentAsset table
//     let qt; let qtIndex; // index of grandParentAsset table
    
//     // q = this.currentSection.questions.find(item => item.code == parentAsset.code);
//     // if (q) {
//     //   qIndex = this.currentSection.questions.indexOf(q);
//     // } else {
//     //   s = this.currentSection.subsections.find(item => item.code == grandParentAsset.code);
//     //   if (s) {
//     //     sIndex = this.currentSection.subsections.indexOf(s);
//     //     qs = this.currentSection.subsections[sIndex].questions.find(item => item.code == parentAsset.code);
//     //     if (qs) {
//     //       qsIndex = this.currentSection.subsections[sIndex].questions.indexOf(qs);
//     //     }
//     //   } else {
//     //     t = this.currentSection.tables.find(item => item.code == grandParentAsset.code);
//     //     if (t) {
//     //       tIndex = this.currentSection.tables.indexOf(t);
//     //       qt = this.currentSection.tables[tIndex].questions.find(item => item.code == parentAsset.code);
//     //       if (qt) {
//     //         qtIndex = this.currentSection.tables[tIndex].questions.indexOf(qt);
//     //       }
//     //     }
//     //   }
//     // }

//     // if (qt) {
//     //   let temp = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tIndex].questions[qtIndex].options[indexOfOption];
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tIndex].questions[qtIndex].options[indexOfOption] = destinationAsset;
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tIndex].questions[qtIndex].options[moveOptionToIndex] = option;
//     //   // this.mta = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tIndex].questions[qtIndex];
//     // } else if (qs) {
//     //   let temp = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[sIndex].questions[qsIndex].options[indexOfOption];
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[sIndex].questions[qsIndex].options[indexOfOption] = destinationAsset;
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].subsections[sIndex].questions[qsIndex].options[moveOptionToIndex] = option;
//     //   // this.mta = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].tables[tIndex].questions[qsIndex];
//     // } else {
//     //   let temp = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[qIndex].options[indexOfOption];
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[qIndex].options[indexOfOption] = destinationAsset;
//     //   this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[qIndex].options[moveOptionToIndex] = option;
//     //   // this.mta = this.questionnaire[this.currentTabIndex].sections[this.currentSectionIndex].questions[qIndex];
//     // }

//     this.mta.options[indexOfOption] = destinationAsset;
//     this.mta.options[moveOptionToIndex] = option;

//     this.initialize(this.currentTabIndex, this.currentSectionIndex);
//   }
// }