import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services/project.service';
import { SurveyService } from '../../../services/survey.service';
import { TranslateService } from '@ngx-translate/core';
import { CompareDataService } from '../../../services/compare-data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FarmerService } from '../../../services/farmer.service';
import $ from 'jquery';

@Component({
  selector: 'app-answer-comparison',
  templateUrl: './answer-comparison.component.html',
  styleUrls: ['./answer-comparison.component.css']
})
export class AnswerComparisonComponent implements OnInit {
  projectId = 0;
  project: any;
  snapshotData: any;
  farmers;
  farmerDetails;
  allSnapshotQuestions;
  farmerRegQuestions;
  requestParams = {};
  farmer;
  farmerId;
  masterDataFarmer;
  questionnaire;  
  sourceQuestionnaire;
  currentTab; // Currently active tab
  currentTabIndex; // Index of currently active tab  
  farmerDetailsTabIndex = 0;
  sourceAnswer = [];
  targetAnswer = [];
  revisionComparisonData = [];
  revTextParams = {}
  secondary_asset_keys = {};
  selectedFarmerId = [];
  currentTabExist = true;
  defaultCropSak;
  dropdownSettings = {};
  selectPlaceholder:string;
  selectAllText:string;
  unSelectAllText:string;
  searchText1:string;
  dropdownList = [];
  farmerDiscontinued = false;
  constructor(    
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly projectService: ProjectService,
    private readonly surveyService: SurveyService,
    private readonly compareDataService: CompareDataService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly farmerService: FarmerService,
    private readonly translate: TranslateService,
  ) {
    const requestVal = localStorage.getItem('compareSnapshotsRevisionRequestObject');
    this.requestParams = JSON.parse(requestVal);
    this.requestParams["showDetails"] = 1; 
    const requestText = localStorage.getItem('compareSnapshotsRevisionTextObject');
    this.revTextParams = JSON.parse(requestText);
   }

  async ngOnInit() {
    this.spinner.show();
    //this.selectedFarmerId = this.requestParams['farmerId'];
    this.projectId = this.route.snapshot.params['id'];
    this.farmerId = this.requestParams['farmerId'];
    const request = await Promise.all([
      this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
      this.projectService.getProjectProfilingData(this.projectId).toPromise()
    ]);
    this.project = {...request[0].data[0], ...request[1].data[0]};
    const revisionId = this.requestParams['targetRevisionId'];
    this.compareDataService.getFarmerList(this.projectId, revisionId).subscribe(response =>{
      this.farmers = response.farmers;
      for(const x of this.farmers){
        let idName;
        if(x.supplier_id){
          idName = `${x.supplier_id} - ${x.name}`;
        } else {
          idName = x.name;
        }
        x.name = idName;
        if(x.id == this.farmerId){
          this.selectedFarmerId = [{'id':x.id, 'name':idName}]
        }
        //this.dropdownList.push({'id':x.id, 'name':idName});
      }
    });
    this.setTranslatedLabelObjects();
    this.getAllFarmerData(this.farmerId);

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: this.selectAllText,
      unSelectAllText: this.unSelectAllText,
      searchPlaceholderText:this.searchText1,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
  }
  //added by Kiran for translation
  setTranslatedLabelObjects(): void {
    this.translate.get('selectPlaceholder').subscribe(result => {
      this.selectPlaceholder = result;
    });
    this.translate.get('selectAllText').subscribe(result => {
      this.selectAllText = result;
    });
    this.translate.get('unSelectAllText').subscribe(result => {
      this.unSelectAllText = result;
    });
    this.translate.get('searchText').subscribe(result => {
      this.searchText1 = result;
    });
  }
  setFarmer(eve){
    this.requestParams['farmerId'] = eve.id;
    this.getAllFarmerData(eve.id)
  }
  async getAllFarmerData(farmer){    
    this.spinner.show();
    const requests = await Promise.all([
      this.farmerService.getFarmRegQuestions().toPromise(),
      this.farmerService.getFarmerBasicDataById(farmer).toPromise(),      
      this.farmerService.GetFarmerMaster().toPromise(),
      this.surveyService.getSurveyQForProfilingService(this.requestParams['targetSnapshotId']).toPromise(),
      this.compareDataService.getRevisionComparisonReport(this.projectId, this.requestParams).toPromise(),
      this.surveyService.getSurveyQForProfilingService(this.requestParams['sourceSnapshotId']).toPromise()
    ]);
    this.farmerRegQuestions = requests[0].data;
    this.farmer = requests[1].data;
    const masterData = requests[2].data;
    this.masterDataFarmer = masterData.filter((t)=>{
      return t.item_tab==="farmer_reg";
    });
    if (requests[3]['message'] !== 'notReleasedForLanguage') {
      this.questionnaire = requests[3]['message'];
    } else {
      this.questionnaire = [];
    }
    if (requests[5]['message'] !== 'notReleasedForLanguage') {
      this.sourceQuestionnaire = requests[5]['message'];
    } else {
      this.sourceQuestionnaire = [];
    }
    this.sourceAnswer = requests[4].source;
    this.targetAnswer = requests[4].target;
    this.farmerDiscontinued = requests[4].discontinedFarmersFlag;
    this.setQuestionAnswers();
    this.initialize();    
    this.spinner.hide();
  }
  setQuestionAnswers(){
    const targetDiffArr = [];
    if (this.questionnaire && this.questionnaire.length > 0) {
      for (const tab of this.questionnaire) {
        const sectionArr = [];
        const changesCount = 0;
        if (tab.sections && tab.sections.length > 0) {
          for (const section of tab.sections) {
            const questionArr = [];
            const subsectionArr = [];
            const tableArr = [];
            if (section.questions && section.questions.length > 0) {    
              for (let question of section.questions) {  
                question = this.setAnswerForQuestionNonTableTarget(question);
                question = this.setAnswerForQuestionNonTableSource(question);
                const questionAnswers = this.getAnswersForQuestion(question);
                if(questionAnswers){
                  questionArr.push(questionAnswers);
                }
              }
            }
            if (section.subsections && section.subsections.length > 0) {
              for (const subsection of section.subsections) {
                const subsectionQuestionArr = [];
                if (subsection.questions && subsection.questions.length > 0) {
                  for (let question of subsection.questions) {
                    question = this.setAnswerForQuestionNonTableTarget(question);
                    question = this.setAnswerForQuestionNonTableSource(question);
                    const questionAnswers = this.getAnswersForQuestion(question);
                    if(questionAnswers){
                      subsectionQuestionArr.push(questionAnswers);
                    }
                  }
                }
                if(subsectionQuestionArr.length > 0){
                  const subsectionObj = {
                    "subsection_name":subsection.survey_q_asset_labels[0].label,
                    "questions":subsectionQuestionArr
                  }
                  subsectionArr.push(subsectionObj)
                }
              }
            }
            if (section.tables && section.tables.length > 0) {
              for (const table of section.tables) {                  
                const tableQuestionArr = [];
                this.secondary_asset_keys[table.id] = this.getSecondaryAssetKeysForTable(table);
                if (table.questions && table.questions.length > 0) {
                  for (let question of table.questions) {                  
                    question = this.setAnswerForQuestionTableTarget(question, this.secondary_asset_keys[table.id]);
                    question = this.setAnswerForQuestionTableSource(question, this.secondary_asset_keys[table.id], table.code, table.id);  
                    const questionAnswers = this.getAnswersForQuestion(question);
                    if(questionAnswers){
                      tableQuestionArr.push(questionAnswers);
                    }
                  }
                }                  
                if(tableQuestionArr.length > 0){
                  const tableObj = {
                    "table_name":table.survey_q_asset_labels[0].label,
                    "questions":tableQuestionArr,
                    "id":table.id
                  }
                  tableArr.push(tableObj)
                }
                if (table.displayFunction && table.displayFunction.indexOf('addDefaultCropEntry') > -1) {
                  this.sortDefaultCropToTop(table);
                }
              }
            }
            if(questionArr.length > 0 || subsectionArr.length > 0 || tableArr.length > 0){
              const sectionObj = {
                "section_name":section.survey_q_asset_labels[0].label,
                "questions":questionArr,
                "subsections":subsectionArr,
                "tables":tableArr
              }
              sectionArr.push(sectionObj)
            }
          }
        }
        if(sectionArr.length > 0){
          const tabObj = {
            "tab_name":tab.survey_q_asset_labels[0].label,
            "sections":sectionArr,
            "total_changes":changesCount,
            "id":tab.id
          }
          targetDiffArr.push(tabObj)
        }
      }
    } 
    this.revisionComparisonData = targetDiffArr;
    console.log('targetDiffArr', targetDiffArr)
  }
  setAnswerForQuestionNonTableTarget(question) {
    let temp;
    let answer;
    let otherAnswer;
    let uomAnswer;
    if (question.qa_subtype === 'text') {
      temp = this.targetAnswer.find(item => item['survey_q_asset.code'] == question.code);
      if (temp) {
        answer = temp.answer;
      }
    } else if (question.qa_subtype === 'number') {
      temp = this.targetAnswer.find(item => item['survey_q_asset.code'] == question.code);
      if (temp) {
        answer = temp.answer;
      }
    } else if (question.qa_subtype === 'select') {
      const x = this.targetAnswer.find(item => item['survey_q_asset.code'] == question.code);
      if (x) {
        answer = x.answer;
      } 
    } else { // multiselect
      const x = this.targetAnswer.filter(item => item['survey_q_asset.code'] == question.code);
      if (x && x.length > 0) {
        if (!question.has_dynamic_options) {
          const z = x.map(item => item.answer);
          answer = z.join(", ");
        } 
        // else {
        //   const doo = this.dynamicOptionsObject[question.id];
        //   if (doo) {
        //     const y = doo.filter(item => x.find(each => each.value == item.q + '__' + item.sak));
        //     if (y && y.length > 0) {
        //       const z = y.map(item => item.label);
        //       answer = z.join(", ");
        //     } 
        //   }
        // }
      }
    }

    if (answer) {
      question.targetAnswer = answer; 
    }

    if (question.other_questions && question.other_questions.length > 0) {
      otherAnswer = this.targetAnswer.find(item => item['survey_q_asset.code'] == question.other_questions[0].code);      
      if (otherAnswer) {
        question.otherAnswer = otherAnswer.answer;
      }
    }

    if (question.uom_questions && question.uom_questions.length > 0) {
      for (const uomQ of question.uom_questions) {
        temp = this.targetAnswer.find(item => item['survey_q_asset.code'] == uomQ.code);
        if (temp) {
          const y = this.project.ProjectUOM.find(item => item.id == temp.answer);
          if (y) {
            uomQ.answer = y.uom;
          }
        }
      }
    }
    return question;
  }
  setAnswerForQuestionTableTarget(question, secondaryAssetKeys) {
    const answer = {};
    const uomQAnswer = {};
    const otherQAnswer = {};
    for (const sak of secondaryAssetKeys) {
      let temp;
      let otherAnswer;
      if (question.qa_subtype === 'text') {
        temp = this.targetAnswer.find(item => item['survey_q_asset.code'] == question.code && item.secondary_asset_key == sak);
        if (temp) {
          answer[sak] = temp.answer;
        }
      } else if (question.qa_subtype === 'number') {
        temp = this.targetAnswer.find(item => item['survey_q_asset.code'] == question.code && item.secondary_asset_key == sak);
        if (temp) {
          answer[sak] = temp.answer;
        }
      } else if (question.qa_subtype === 'select') {
        const x = this.targetAnswer.find(item => item['survey_q_asset.code'] == question.code && item.secondary_asset_key == sak);
        if (x) {
          answer[sak] = x.answer;
        } 
      } else { // multiselect
        const x = this.targetAnswer.filter(item => item['survey_q_asset.code'] == question.code && item.secondary_asset_key == sak);
        if (x && x.length > 0) {
          if (!question.has_dynamic_options) {
            const z = x.map(item => item.answer);
            answer[sak] = z.join(", ");
          } 
          // else {
          //   const doo = this.dynamicOptionsObject[question.id];
          //   if (doo) {
          //     const y = doo.filter(item => x.find(each => each.value == item.q + '__' + item.sak));
          //     if (y && y.length > 0) {
          //       const z = y.map(item => item.label);
          //       answer[sak] = z.join(", ");
          //     } 
          //   }
          // }
        }
      }

      if (question.other_questions && question.other_questions.length > 0) {
        otherAnswer = this.targetAnswer.find(item => item['survey_q_asset.code'] == question.other_questions[0].code && item.secondary_asset_key == sak);
        if (otherAnswer) {
          otherQAnswer[sak] = otherAnswer.answer;
          answer[sak] = `${answer[sak]}-${otherAnswer.answer}`;
          
          if(answer[sak]){
            if (answer[sak] === "-") {
              answer[sak] = "";
            } else if (answer[sak].length > 1 && answer[sak][answer[sak].length - 1] === "-") {
              answer[sak] = answer[sak].substring(0, answer[sak].length - 1);
            }
          }
        }
      }

      if (question.uom_questions && question.uom_questions.length > 0) {
        for (const uomQ of question.uom_questions) {
          temp = this.targetAnswer.find(item => item['survey_q_asset.code'] == uomQ.code && item.secondary_asset_key == sak);
          if (temp) {
            const y = this.project.ProjectUOM.find(item => item.id == temp.answer);
            if (y) {
              uomQAnswer[sak] = y.uom;
            }
          }
          uomQ.answer = uomQAnswer;
        }
      }
    }
    question.targetAnswer = answer;
    return question;
  }
  
  setAnswerForQuestionNonTableSource(question){
    let sourceQuestion = this.getSourceQuestion(question.code);
    if(!sourceQuestion){
      sourceQuestion = {}
    }
    let temp;
    let answer;
    let otherAnswer;
    let uomAnswer;
    if (sourceQuestion.qa_subtype === 'text') {
      temp = this.sourceAnswer.find(item => item['survey_q_asset.code'] == sourceQuestion.code);
      if (temp) {
        answer = temp.answer;
      }
    } else if (sourceQuestion.qa_subtype === 'number') {
      temp = this.sourceAnswer.find(item => item['survey_q_asset.code'] == sourceQuestion.code);
      if (temp) {
        answer = temp.answer;
      }
    } else if (sourceQuestion.qa_subtype === 'select') {
      const x = this.sourceAnswer.find(item => item['survey_q_asset.code'] == sourceQuestion.code);
      if (x) {        
        answer = x.answer;
      } 
    } else { // multiselect
      const x = this.sourceAnswer.filter(item => item['survey_q_asset.code'] == sourceQuestion.code);
      if (x && x.length > 0) {
        if (!sourceQuestion.has_dynamic_options) {
          const z = x.map(item => item.answer);
          answer = z.join(", ");
        } 
        // else {
        //   const doo = this.dynamicOptionsObject[question.id];
        //   if (doo) {
        //     const y = doo.filter(item => x.find(each => each.value == item.q + '__' + item.sak));
        //     if (y && y.length > 0) {
        //       const z = y.map(item => item.label);
        //       answer = z.join(", ");
        //     } 
        //   }
        // }
      }
    }

    if (answer) {
      question.sourceAnswer = answer; 
    }

    if (sourceQuestion.other_questions && sourceQuestion.other_questions.length > 0) {
      otherAnswer = this.sourceAnswer.find(item => item['survey_q_asset.code'] == sourceQuestion.other_questions[0].code);
      if (otherAnswer) {
        question.otherAnswerSource = otherAnswer.answer;
      }
    }

    if (sourceQuestion.uom_questions && sourceQuestion.uom_questions.length > 0) {
      for (const uomQ of sourceQuestion.uom_questions) {
        temp = this.sourceAnswer.find(item => item['survey_q_asset.code'] == uomQ.code);
        if (temp) {
          const y = this.project.ProjectUOM.find(item => item.id == temp.answer);
          if (y) {
            const checkQ = question.uom_questions.find(item => item.code == uomQ.code)
            if(checkQ){
              checkQ.answerSource = y.uom;
            }
          }
        }
      }
    }
    return question;
  }
  setAnswerForQuestionTableSource(question, secondaryAssetKeys, tableCode, tableId) {
    let sourceQuestion = this.getSourceQuestion(question.code);
    if(!sourceQuestion){
      sourceQuestion = {}
    }
    let sourceTable = this.getSourceTable(tableCode);
    if(!sourceTable){
      sourceTable = {}
    }
    const sakSource = {}
    sakSource[sourceTable.id] = this.getSecondaryAssetKeysForTableSource(sourceTable);
    const sakSourceArr = sakSource[sourceTable.id];
    const answer = {};
    const uomQAnswer = {};
    const otherQAnswer = {};
    var unionArr = secondaryAssetKeys.concat(sakSourceArr.filter((item) => secondaryAssetKeys.indexOf(item) < 0))
    
    // if(secondaryAssetKeys.length == 0 && sakSourceArr.length > 0){
    //   secondaryAssetKeys = sakSourceArr;
    //   this.secondary_asset_keys[tableId] = sakSourceArr;
    // }
    secondaryAssetKeys = unionArr;
    this.secondary_asset_keys[tableId] = unionArr;
    for (let i=0; i < secondaryAssetKeys.length; i++) {
      let temp;
      let otherAnswer;
      if (sourceQuestion.qa_subtype === 'text') {
        temp = this.sourceAnswer.find(item => item['survey_q_asset.code'] == sourceQuestion.code && item.secondary_asset_key == secondaryAssetKeys[i]);
        if (temp) {
          answer[ secondaryAssetKeys[i]] = temp.answer;
        }
      } else if (sourceQuestion.qa_subtype === 'number') {
        temp = this.sourceAnswer.find(item => item['survey_q_asset.code'] == sourceQuestion.code && item.secondary_asset_key ==  secondaryAssetKeys[i]);
        if (temp) {
          answer[ secondaryAssetKeys[i]] = temp.answer;
        }
      } else if (sourceQuestion.qa_subtype === 'select') {
        const x = this.sourceAnswer.find(item => item['survey_q_asset.code'] == sourceQuestion.code && item.secondary_asset_key ==  secondaryAssetKeys[i]);
        if (x) {          
          answer[secondaryAssetKeys[i]] = x.answer;
        } 
      } else { // multiselect
        const x = this.sourceAnswer.filter(item => item['survey_q_asset.code'] == sourceQuestion.code && item.secondary_asset_key ==  secondaryAssetKeys[i]);
        if (x && x.length > 0) {
          if (!sourceQuestion.has_dynamic_options) {
            const z = x.map(item => item.answer);
            answer[secondaryAssetKeys[i]] = z.join(", ");
          } 
          // else {
          //   const doo = this.dynamicOptionsObject[question.id];
          //   if (doo) {
          //     const y = doo.filter(item => x.find(each => each.value == item.q + '__' + item.sak));
          //     if (y && y.length > 0) {
          //       const z = y.map(item => item.label);
          //       answer[sak] = z.join(", ");
          //     } 
          //   }
          // }
        }
      }

      if (sourceQuestion.other_questions && sourceQuestion.other_questions.length > 0) {
        otherAnswer = this.sourceAnswer.find(item => item['survey_q_asset.code'] == sourceQuestion.other_questions[0].code && item.secondary_asset_key == secondaryAssetKeys[i]);
        
        if (otherAnswer) {
          otherQAnswer[secondaryAssetKeys[i]] = otherAnswer.answer;
          answer[secondaryAssetKeys[i]] = `${answer[secondaryAssetKeys[i]]}-${otherAnswer.answer}`;
        }
      }

      if (sourceQuestion.uom_questions && sourceQuestion.uom_questions.length > 0) {
        for (const uomQ of sourceQuestion.uom_questions) {
          temp = this.sourceAnswer.find(item => item['survey_q_asset.code'] == uomQ.code && item.secondary_asset_key ==  secondaryAssetKeys[i]);
          if (temp) {
            const y = this.project.ProjectUOM.find(item => item.id == temp.answer);
            if (y) {
              uomQAnswer[secondaryAssetKeys[i]] = y.uom;
            }
          }
          uomQ.answerSource = uomQAnswer;
        }
      }
    }
    question.sourceAnswer = answer;
    return question;
  }
  getAnswersForQuestion(question){
    let isUpdate = false;
    for(const SA of this.sourceAnswer){
      if(question.code === SA["survey_q_asset.code"]){ 
        if(SA.marker === "DELETED"){ 
          isUpdate = true;
          question.marker = SA.marker; 
        }
      }
    }
    for(const TA of this.targetAnswer){
      if(question.code === TA["survey_q_asset.code"]){
        if(TA.marker !== "NO_CHANGE"){ 
          isUpdate = true;
          question.marker = TA.marker;        
        }
      }
    }
    if(question.targetAnswer === question.sourceAnswer && question.otherAnswer === question.otherAnswerSource){
      isUpdate = false;
    }
    if(isUpdate){                  
      return question;
    }
  }
  /**
 * Sort the default crop (the ecosystem crop) to the top of the agri income table
 * @param tableId - Number
*/
  sortDefaultCropToTop(table) : void {
    const tableId = table.id;
    const cropName = this.project.Product.name;
    const displayFunction = JSON.parse(table.displayFunction);
    const x = this.targetAnswer.find(item => item.questionnaire_asset_id == displayFunction.setQId && item.answer == cropName);
    if (x) {
      // Set the value of this.defaultCropSak;
      this.defaultCropSak = x.secondary_asset_key;

      if (this.secondary_asset_keys && this.secondary_asset_keys[tableId] && this.secondary_asset_keys[tableId].length > 0) {
        const x = this.secondary_asset_keys[tableId].indexOf(this.defaultCropSak);
        if (x) {
          if (x === 0) {
            // do nothing
            return;
          } else {
            let temp = JSON.parse(JSON.stringify(this.secondary_asset_keys[tableId]));
            let p = temp[0];
            temp[0] = this.defaultCropSak;
            temp[x] = p;
            this.secondary_asset_keys[tableId] = JSON.parse(JSON.stringify(temp));
          }
        }
      }
    }
  }

  getSourceQuestion(code){
    let sourceQ;
    if (this.sourceQuestionnaire && this.sourceQuestionnaire.length > 0) {
      for (const tab of this.sourceQuestionnaire) {
        if (tab.sections && tab.sections.length > 0) {
          for (const section of tab.sections) {
            if (section.questions && section.questions.length > 0) {    
              for (const question of section.questions) {  
                if(question.code === code){
                  sourceQ = question;
                }
              }
            }
            if (section.subsections && section.subsections.length > 0) {
              for (const subsection of section.subsections) {
                if (subsection.questions && subsection.questions.length > 0) {
                  for (const question of subsection.questions) {
                    if(question.code === code){
                      sourceQ = question;
                    }
                  }
                }
              }
            }
            if (section.tables && section.tables.length > 0) {
              for (const table of section.tables) {                  
                if (table.questions && table.questions.length > 0) {
                  for (const question of table.questions) {                  
                    if(question.code === code){
                      sourceQ = question;
                    }
                  }
                } 
              }
            }
          }
        }
      }
    } 
    return sourceQ;
  }

  getSourceTable(code){
    let sourceT;
    if (this.sourceQuestionnaire && this.sourceQuestionnaire.length > 0) {
      for (const tab of this.sourceQuestionnaire) {
        if (tab.sections && tab.sections.length > 0) {
          for (const section of tab.sections) {
            if (section.tables && section.tables.length > 0) {
              for (const table of section.tables) {                  
                if(table.code === code){
                  sourceT = table;
                }
              }
            }
          }
        }
      }
    } 
    return sourceT;
  }

  getSecondaryAssetKeysForTable(table) {
    let sakArray = [];
    if (table.questions && table.questions.length > 0) {
      const qIds = table.questions.map(item => item.id.toString());
      const answersForQIds = this.targetAnswer.filter(item => qIds.indexOf(item["questionnaire_asset_id"].toString()) > -1);
      if (answersForQIds && answersForQIds.length > 0) {
        const answersForQIdsSaks = answersForQIds.map(item => item.secondary_asset_key);
        if (answersForQIdsSaks && answersForQIdsSaks.length > 0) {
          sakArray = this.getUniqueValues(answersForQIdsSaks);
        }
      }
    }
    return sakArray;
  }

  getSecondaryAssetKeysForTableSource(table) {
    let sakArray = [];
    if (table.questions && table.questions.length > 0) {
      const qIds = table.questions.map(item => item.id.toString());
      const answersForQIds = this.sourceAnswer.filter(item => qIds.indexOf(item["questionnaire_asset_id"].toString()) > -1);
      if (answersForQIds && answersForQIds.length > 0) {
        const answersForQIdsSaks = answersForQIds.map(item => item.secondary_asset_key);
        if (answersForQIdsSaks && answersForQIdsSaks.length > 0) {
          sakArray = this.getUniqueValues(answersForQIdsSaks);
        }
      }
    }
    return sakArray;
  }

  getUniqueValues(array) {
    const uniqueArray = [];
    for (const item of array) {
      if (uniqueArray.indexOf(item) < 0) {
        uniqueArray.push(item);
      }
    }
    return uniqueArray;
  }
   // Initialize the questionnaire by navigating to the 1st tab
  initialize(): void {
    this.farmerDetailsTabIndex = this.questionnaire.length + 1;
    this.currentTabIndex = this.farmerDetailsTabIndex;
    this.tabClicked(0, false);
  }
  tabClicked(tabIndex, isFarmerTab?) : void {
    if (!isFarmerTab) {
      this.currentTab = this.questionnaire[tabIndex];
      const checkTabExist = this.revisionComparisonData.find(item => item.id === this.currentTab.id);
      if(checkTabExist){
        this.currentTabExist = true;
      } else {
        this.currentTabExist = false;
      }
    }
    this.currentTabIndex = tabIndex;
    this.scrollToTop();
  }
  getMasterData(code,value){
    const answer = this.masterDataFarmer.filter((v) => {
      return v.master_id === value && v.item_code === code;
    });
    if (answer.length > 0) {
      return answer[0].name;
    } else {
      return null;
    }
  }
  scrollToTop() {
    const toTop = $('#mainDiv').scrollTop = 0;
    $('html, body').animate({
      scrollTop: toTop
    }, 'slow');
  }
  goToReportingManagement(projectID){
    const url = 'charts/' + this.projectId;
    this.router.navigate([url]);
  }
  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

}
