// TODO: Done: Delete template selection component if not required
// TODO: Done: Select option to create questionnaire from blank slate
import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { SurveyService } from '../../services/survey.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { RevisionService } from '../../services/revision.service';
import { UserService } from '../../services/user.service';
import { ChangeDetectorRef } from '@angular/core';
import { CampaignService } from '../../services/campaign.service';
var SurveysComponent = /** @class */ (function () {
    function SurveysComponent(route, router, surveyService, modalService, toastr, spinner, userService, translate, revisionService, formBuilder, commonService, ref, campaignService, locale) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.surveyService = surveyService;
        this.modalService = modalService;
        this.toastr = toastr;
        this.spinner = spinner;
        this.userService = userService;
        this.translate = translate;
        this.revisionService = revisionService;
        this.formBuilder = formBuilder;
        this.commonService = commonService;
        this.ref = ref;
        this.campaignService = campaignService;
        this.locale = locale;
        this.allSurveys = [];
        this.snapshotSurveys = [];
        this.campaignSurveys = [];
        this.ecosystemTemplates = [];
        this.canCreateSnapshot = false;
        this.canScheduleRevision = false;
        this.currentUser = this.userService.getCurrentUser();
        this.showPrompt = true;
        this.frequency = [];
        this.saveTemplateSubmitted = false;
        this.savingTemplate = false;
        this.questionnaire = []; // Array of tabs
        this.labelVariable = 'survey_q_asset_labels';
        this.displayLogicVariable = 'survey_q_asset_display_logic';
        this.autocalculateFormulaVariable = 'survey_q_asset_autocalculate_formulas';
        this.userLanguages = [];
        this.showUpArrow = false;
        this.campaigns = [];
        this.fromCampaign = false;
        this.fromProject = false;
        this.isAllConsSelected = false;
        this.isCampaignActivation = false;
        this.emptySurveyName = false;
        if (this.locale && this.locale.length > 2) {
            this.locale = this.locale.substr(0, 2);
        }
        this.translate.get('schedule_saved_successfully').subscribe(function (result) {
            _this.scheduleSavedSuccessfully = result;
        });
        this.translate.get('schedule_stopped_successfully').subscribe(function (result) {
            _this.scheduleStopSuccessfully = result;
        });
    }
    SurveysComponent.prototype.onKeydownHandler = function (event) {
        this.cancelModal(this.revisionForm);
        this.declineCloseSnapshot();
        if (this.surveyTemplatesModal) {
            this.surveyTemplatesModal.hide();
        }
        this.closeSaveTemplateModal();
        this.printSurvey('close');
    };
    SurveysComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.projectId = +this.route.snapshot.params['id'];
                this.project = this.route.snapshot.data.project.project;
                this.loadingSurveyData = true;
                this.route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        if (queryParams.get('fromCampaign') && queryParams.get('fromCampaign') === 'true') {
                            this.fromCampaign = true;
                        }
                        if (queryParams.get('fromProject') && queryParams.get('fromProject') === 'true') {
                            this.fromProject = true;
                        }
                        if (queryParams.get('isAllConsSelected') && queryParams.get('isAllConsSelected') === 'true') {
                            this.isAllConsSelected = true;
                        }
                        return [2 /*return*/];
                    });
                }); });
                if (this.fromCampaign) {
                    document.getElementById('collapseOne').classList.remove('show');
                    document.getElementById('collapseTwo').classList.add('show');
                }
                if (this.fromProject) {
                    document.getElementById('collapseTwo').classList.remove('show');
                    document.getElementById('collapseOne').classList.add('show');
                }
                this.setSurveys();
                this.setSnapshotPermissions();
                this.setRevisionData();
                return [2 /*return*/];
            });
        });
    };
    // Get the languageId of the current locale
    SurveysComponent.prototype.getLanguageId = function () {
        var _this = this;
        if (this.userLanguages && this.userLanguages.length > 0) {
            var p = this.userLanguages.find(function (item) { return item.locale == _this.locale; });
            if (p) {
                return p['id'];
            }
            else {
                return undefined;
            }
        }
        else {
            return undefined;
        }
    };
    SurveysComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    // Get all surveys for the given project & filter them by type
    SurveysComponent.prototype.setSurveys = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([this.surveyService.getAllSurveysForProject(this.projectId).toPromise()])];
                    case 1:
                        requests = _a.sent();
                        this.allSurveys = requests[0].message;
                        this.filterSurveys();
                        this.getCampaigns();
                        this.loadingSurveyData = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    // Create survey for the given project, of the specified type (snapshot / campaign)
    SurveysComponent.prototype.createSurvey = function (type, template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([this.surveyService.createSurvey(this.projectId, type).toPromise()])];
                    case 1:
                        requests = _a.sent();
                        if (requests[0].code == 'success') {
                            if (requests[0].message['cr_date']['val'] === 'CURRENT_TIMESTAMP') {
                                requests[0].message['cr_date'] = new Date();
                            }
                            this.allSurveys.unshift(requests[0].message);
                            this.filterSurveys();
                            this.spinner.hide();
                            this.surveyActionPrimary(requests[0].message, template);
                        }
                        else {
                            this.spinner.hide();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SurveysComponent.prototype.filterSurveys = function () {
        this.campaignSurveys = this.allSurveys.filter(function (item) { return item.survey_type == 'campaign'; });
        this.snapshotSurveys = this.allSurveys.filter(function (item) { return item.survey_type == 'snapshot'; });
        this.showCreateButtons();
    };
    // Take the primary button's action = here it is calling manageQuestionnaire function
    SurveysComponent.prototype.surveyActionPrimary = function (survey, template) {
        this.surveyIdClicked = survey.id;
        if (survey.survey_stage_changelogs[0].change_type == 'draft') {
            this.manageQuestionnaire(survey, template);
        }
    };
    // Take the secondary button's action = here it is activate / close survey
    SurveysComponent.prototype.surveyActionSecondary = function (survey, template, template1) {
        var _this = this;
        if (survey.survey_type == 'campaign') {
            this.isCampaignActivation = true;
        }
        else {
            this.isCampaignActivation = false;
        }
        this.surveyIdClicked = survey.id;
        if (survey.survey_stage_changelogs[0].change_type == 'draft') {
            this.spinner.show();
            // Check which languages are OK to be released - a language is OK if there are no invalid and no missing labels for any asset
            this.surveyService.checkLanguageOKForRelease(this.surveyIdClicked)
                .subscribe(function (result) {
                _this.spinner.hide();
                if (result.code == 'success') {
                    _this.languagesReadyForRelease = result['message']['ok'];
                    _this.languagesNotReadyForRelease = result['message']['notOK'];
                    _this.saveTemplateAfterActivation = false;
                    _this.closeSnapshotModal = _this.modalService.show(template1, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
                }
            });
        }
        if (survey.survey_stage_changelogs[0].change_type == 'active') {
            //this.closeSurvey(this.surveyIdClicked);
            this.closeSnapshotModal = this.modalService.show(template1, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
        }
    };
    /**
     * Check whether the template details entered by user are valid. If valid, then call activateSurvey function
     * @param template
     */
    SurveysComponent.prototype.confirmActivation = function (template) {
        if (this.saveTemplateAfterActivation) {
            if (this.savingTemplate || this.disableButtons) {
                return;
            }
            this.saveTemplateSubmitted = true;
            if (!this.templateNameInput || this.templateNameInput.length == 0 || !this.templateDescriptionInput || this.templateDescriptionInput.length == 0) {
                return;
            }
        }
        if (this.closeSnapshotModal) {
            this.closeSnapshotModal.hide();
        }
        this.activateSurvey(this.surveyIdClicked, template);
    };
    SurveysComponent.prototype.confirmCloseSnapshot = function () {
        if (this.closeSnapshotModal) {
            this.closeSnapshotModal.hide();
        }
        this.closeSurvey(this.surveyIdClicked);
    };
    SurveysComponent.prototype.declineCloseSnapshot = function () {
        if (this.closeSnapshotModal) {
            this.closeSnapshotModal.hide();
        }
        this.closeSaveTemplateModal();
    };
    // Move survey to stage = active. Allow responses to the survey
    SurveysComponent.prototype.activateSurvey = function (surveyId, template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([this.surveyService.activateSurvey(surveyId).toPromise()])];
                    case 1:
                        requests = _a.sent();
                        if (requests[0].code == 'success') {
                            if (this.saveTemplateAfterActivation) {
                                this.saveTemplate();
                            }
                            this.commonService.showToast('success', 'survey_activated', {});
                            this.setSurveys();
                            if (!this.saveTemplateAfterActivation) {
                                this.spinner.hide();
                            }
                        }
                        else {
                            this.spinner.hide();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // Move survey to stage = closed. Do not allow further responses to survey
    SurveysComponent.prototype.closeSurvey = function (surveyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([this.surveyService.closeSurvey(surveyId).toPromise()])];
                    case 1:
                        requests = _a.sent();
                        if (requests[0].code == 'success') {
                            this.commonService.showToast('success', 'survey_closed', {});
                            this.setSurveys();
                            this.spinner.hide();
                        }
                        else {
                            this.spinner.hide();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // Called on clicking Manage questionnaire button. If questionnaire exists, redirect to the questionnaire mgmt page
    // Else open the modal with templates list in it for selection
    SurveysComponent.prototype.manageQuestionnaire = function (survey, template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, templates, x;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('survey', survey);
                        if (!survey.questionnaireExists) return [3 /*break*/, 1];
                        this.router.navigate(['questionnaire/edit/' + this.projectId + '/' + survey.id]);
                        return [3 /*break*/, 5];
                    case 1:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([this.surveyService.checkSurveyQuestionnaireExists(survey.id).toPromise()])];
                    case 2:
                        requests = _a.sent();
                        if (!(requests[0].code == 'success')) return [3 /*break*/, 5];
                        if (!(requests[0].message == 'noQuestionnaire')) return [3 /*break*/, 4];
                        return [4 /*yield*/, Promise.all([this.surveyService.getEcosystemTemplates().toPromise()])];
                    case 3:
                        templates = _a.sent();
                        this.spinner.hide();
                        x = templates[0].message;
                        if (x && x.length > 0) {
                            this.ecosystemTemplates = x.filter(function (item) { return item.template_type === survey.survey_type; });
                        }
                        this.surveyTemplatesModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-md-firmenich' });
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        this.router.navigate(['questionnaire/edit/' + this.projectId + '/' + survey.id]);
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SurveysComponent.prototype.closeSurveyTemplateModal = function (myForm) {
        // Hide the modal
        if (this.surveyTemplatesModal) {
            this.surveyTemplatesModal.hide();
        }
        myForm.reset();
        this.templateId = undefined;
    };
    // Take required action (use / preview) for the clicked template
    // If templateId == 0, it indicates that the global P2F-Roots template is clicked
    SurveysComponent.prototype.templateAction = function (actionType, templateId, myForm) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var obj, requests, requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(actionType == 'use')) return [3 /*break*/, 5];
                        obj = {
                            surveyName: this.surveyName
                        };
                        if (!(templateId != -1)) return [3 /*break*/, 2];
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([this.surveyService.createQuestionnaireForSurvey(this.surveyIdClicked, templateId, obj).toPromise()])];
                    case 1:
                        requests = _a.sent();
                        if (requests[0].code == 'success') {
                            this.closeSurveyTemplateModal(myForm);
                            this.commonService.showToast('success', 'success_questionnaire_created', {}, null, { "languages": requests[0].message });
                            this.setSurveys();
                            this.spinner.hide();
                        }
                        else {
                            this.spinner.hide();
                        }
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, Promise.all([this.surveyService.updateSurveyNameBySurveyId(this.surveyIdClicked, obj).toPromise()])];
                    case 3:
                        requests = _a.sent();
                        if (requests[0].code == 'success') {
                            this.closeSurveyTemplateModal(myForm);
                            this.router.navigate(['questionnaire/edit/' + this.projectId + '/' + this.surveyIdClicked]);
                        }
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        if (actionType == 'preview') {
                            if (templateId != -1) {
                                this.closeSurveyTemplateModal(myForm);
                                this.router.navigate(['questionnaire/template/' + this.projectId + '/' + this.surveyIdClicked + '/' + templateId]);
                            }
                            else {
                                this.commonService.showToast('success', 'blank_template_cannot_previewed', {});
                            }
                        }
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    // Show the buttons to create the survey if either no survey exists, or the stage of the latest survey = closed
    SurveysComponent.prototype.showCreateButtons = function () {
        if (this.snapshotSurveys.length == 0 || this.snapshotSurveys[0].survey_stage_changelogs[0].change_type == 'closed') {
            this.showSnapshotSurveyCreateButton = true;
        }
        else {
            this.showSnapshotSurveyCreateButton = false;
        }
        if (this.campaignSurveys.length == 0 || this.campaignSurveys[0].survey_stage_changelogs[0].change_type == 'closed') {
            this.showCampaignSurveyCreateButton = true;
        }
        else {
            this.showCampaignSurveyCreateButton = false;
        }
    };
    // Depdnding on the user's service component mappings, show / hide buttons to create snapshot & schedule revision
    SurveysComponent.prototype.setSnapshotPermissions = function () {
        var _this = this;
        if (this.currentUser.role.id > 3) {
            // For all other types of users, check whether they have been given access to that component during the project setup
            if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
                var canCreateSnapshot = this.project.supplier_project_services_components_mapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 6 && item.user_type_id == _this.currentUser.role.id); });
                if (canCreateSnapshot && canCreateSnapshot.length > 0) {
                    this.canCreateSnapshot = true;
                }
                // For all other types of users, check whether they have been given access to that component during the project setup
                var canScheduleRevision = this.project.supplier_project_services_components_mapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 7 && item.user_type_id == _this.currentUser.role.id); });
                if (canScheduleRevision && canScheduleRevision.length > 0) {
                    this.canScheduleRevision = true;
                }
            }
        }
        else {
            if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
                var canCreateSnapshot = this.project.ecosystem_services_components_mapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 6); });
                if (canCreateSnapshot && canCreateSnapshot.length > 0) {
                    this.canCreateSnapshot = true;
                }
                var canScheduleRevision = this.project.ecosystem_services_components_mapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id == 7); });
                if (canScheduleRevision && canScheduleRevision.length > 0) {
                    this.canScheduleRevision = true;
                }
            }
        }
    };
    // Show schedule revision modal
    SurveysComponent.prototype.scheduleRevisionModalFn = function (template) {
        this.schedule = Object.assign({}, this.scheduleText);
        this.scheduleRevisionModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    SurveysComponent.prototype.declineModal = function (form) {
        if (form.dirty) {
            this.showPrompt = false;
        }
        else {
            if (this.scheduleRevisionModal) {
                this.schedule = Object.assign({}, this.scheduleText);
                //this.schedule = this.scheduleText;
                this.scheduleRevisionModal.hide();
            }
        }
    };
    SurveysComponent.prototype.cancelModal = function (form) {
        //console.log("form ", form);
        if (form.dirty) {
            //console.log("some thing chnages");
            this.showPrompt = false;
        }
        else {
            this.confirmModalFn('Yes');
        }
    };
    SurveysComponent.prototype.confirmModalFn = function (what) {
        var _this = this;
        if (what === 'Yes') {
            if (this.scheduleRevisionModal) {
                this.schedule = Object.assign({}, this.scheduleText);
                //console.log("this.schedule ", this.schedule);
                //this.schedule = this.scheduleText;
                this.scheduleRevisionModal.hide();
                setTimeout(function () { return _this.showPrompt = true; }, 1000);
                this.revisionForm.markAsPristine();
            }
        }
        else {
            setTimeout(function () { return _this.showPrompt = true; }, 1000);
            //this.showPrompt = true;
        }
    };
    /**
     * Save the schedule revision as per user inputs
     * @param form
     */
    SurveysComponent.prototype.saveSchedule = function (form) {
        var _this = this;
        //  debugger;
        //console.log("submit form ::: ", form.value);
        //console.log("this.schedule ::: ", this.schedule);
        if (this.schedule.id > 0) {
            this.schedule.upd_user_id = this.currentUser.id;
            this.schedule.upd_date = Date.now();
            this.schedule.revision_status = 'Not Started';
        }
        else {
            this.schedule.cr_user_id = this.currentUser.id;
            this.schedule.cr_date = Date.now();
        }
        var dt = new Date();
        //const hrs = dt.getHours();
        dt.setHours(this.schedule.hrs, 0, 0);
        this.schedule.scheduled_datetime = dt;
        if (this.schedule.scheduling_frequency == 2) {
            dt.setDate(dt.getDate() + ((7 - dt.getDay()) % 7) + (+this.schedule.weekday));
            this.schedule.scheduled_datetime = dt;
        }
        if (this.schedule.scheduling_frequency == 4) {
            dt.setDate(this.schedule.day);
            this.schedule.scheduled_datetime = dt;
        }
        this.schedule['scheduled_datetime_utc'] = this.schedule.scheduled_datetime.getTime();
        var year = this.schedule.scheduled_datetime.getFullYear();
        var month = this.schedule.scheduled_datetime.getMonth();
        var date = this.schedule.scheduled_datetime.getDate();
        this.schedule['year'] = year;
        this.schedule['month'] = month;
        this.schedule['date'] = date;
        this.revisionService.SaveRevisionSchedule(this.schedule).subscribe(function (res) {
            if (res.msg == 'success') {
                if (res.data.deleted && res.data.deleted !== '0') {
                    _this.schedule = { id: 0, project_id: _this.projectId, scheduling_frequency: 1, scheduled_datetime: new Date(), weekday: 1, day: 1, hrs: 0 };
                    _this.scheduleText = Object.assign({}, _this.schedule);
                    _this.toastr.success(_this.scheduleStopSuccessfully);
                }
                else {
                    _this.schedule = res.data;
                    _this.schedule.frequencyName = _this.frequency.find(function (x) { return x.id == _this.schedule.scheduling_frequency; }).name;
                    var dt_1 = new Date(_this.schedule.scheduled_datetime);
                    _this.schedule.weekday = dt_1.getDay();
                    _this.schedule.day = dt_1.getDate();
                    _this.schedule.hrs = dt_1.getHours();
                    _this.toastr.success(_this.scheduleSavedSuccessfully);
                    _this.scheduleRevisionModal.hide();
                    _this.scheduleText = Object.assign({}, _this.schedule);
                }
            }
        });
    };
    /**
     * Set the schedule frequency configs
     * If a schedule is already created for a project, set the value
     */
    SurveysComponent.prototype.setRevisionData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests, frequencyData, shceduleData, SchDate;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.schedule = { id: 0, project_id: this.projectId, scheduling_frequency: 1, scheduled_datetime: new Date(), weekday: 1, day: 1, hrs: 0 };
                        this.scheduleText = Object.assign({}, this.schedule);
                        this.revisionForm = this.formBuilder.group({
                            scheduling_frequency: new FormControl(this.schedule.scheduling_frequency, [Validators.required]),
                            day: new FormControl(this.schedule.day, [Validators.required]),
                            weekday: new FormControl(this.schedule.weekday, [Validators.required]),
                            hrs: new FormControl(this.schedule.hrs, [Validators.required])
                        });
                        return [4 /*yield*/, Promise.all([
                                this.commonService.getScheduleFrequency().toPromise(),
                                this.revisionService.GetRevisionSchedule(this.projectId).toPromise()
                            ])];
                    case 1:
                        requests = _a.sent();
                        frequencyData = requests[0];
                        shceduleData = requests[1];
                        if (frequencyData.msg == 'success') {
                            this.frequency = frequencyData.data;
                        }
                        if (shceduleData.msg == 'success' && shceduleData.data) {
                            this.schedule = shceduleData.data;
                            SchDate = new Date(this.schedule.scheduled_datetime);
                            this.schedule.weekday = SchDate.getDay();
                            this.schedule.day = SchDate.getDate();
                            this.schedule.hrs = SchDate.getHours();
                            this.schedule.frequencyName = this.frequency.find(function (x) { return x.id == _this.schedule.scheduling_frequency; }).name;
                            this.scheduleText = Object.assign({}, this.schedule);
                            // console.log('schedule', this.schedule)
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SurveysComponent.prototype.closeSaveTemplateModal = function () {
        if (this.saveTemplateModal) {
            this.saveTemplateModal.hide();
        }
        this.saveTemplateAfterActivation = false;
        this.templateNameInput = undefined;
        this.templateDescriptionInput = undefined;
        this.saveTemplateSubmitted = false;
        this.savingTemplate = false;
        this.ref.detectChanges();
    };
    // Function to save template from the given survey ID, with the added templateDetails
    SurveysComponent.prototype.saveTemplate = function () {
        var _this = this;
        this.commonService.showToast('warning', 'saving_template', {});
        var templateDetails = {
            name: this.templateNameInput,
            description: this.templateDescriptionInput
        };
        this.savingTemplate = true;
        this.ref.detectChanges();
        this.surveyService.saveAsTemplate(this.surveyIdClicked, templateDetails)
            .subscribe(function (result) {
            if (result['code'] == 'success') {
                _this.commonService.showToast('success', 'template_created', {});
            }
            _this.spinner.hide();
            _this.closeSaveTemplateModal();
        }, function (error) {
            _this.spinner.hide();
            _this.closeSaveTemplateModal();
        });
    };
    //added by Kiran for template selection
    SurveysComponent.prototype.selectTemplate = function (el) {
        var radioBut = el.getElementsByTagName('input')[0];
        radioBut.click();
        if (radioBut.checked) {
            var allRadioButton = document.querySelectorAll('.template-card');
            for (var i = 0; i < allRadioButton.length; i++) {
                allRadioButton[i].classList.remove('template-card-selected');
            }
            el.classList.add('template-card-selected');
        }
        else {
            el.classList.remove('template-card-selected');
        }
    };
    SurveysComponent.prototype.stopScheduleRevision = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.schedule.deleted = true;
                this.saveSchedule(null);
                return [2 /*return*/];
            });
        });
    };
    SurveysComponent.prototype.getSurveyDetails = function (id, campaignId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, url;
            return tslib_1.__generator(this, function (_a) {
                if (campaignId) {
                    url = "viewquestionnaire/" + this.projectId + "/" + id;
                    this.router.navigate([url], { queryParams: { campaignId: campaignId } });
                }
                else {
                    url = "viewquestionnaire/" + this.projectId + "/" + id;
                    this.router.navigate([url]);
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * Preview survey function
     * @param action
     */
    SurveysComponent.prototype.printSurvey = function (action) {
        var surveyQuestionnaire = document.getElementById('surveyQuestionnaire');
        if (action === 'close') {
            surveyQuestionnaire.classList.remove('d-block');
            surveyQuestionnaire.classList.add('d-none');
            document.documentElement.style.overflowY = 'scroll';
        }
        else {
            surveyQuestionnaire.classList.remove('d-none');
            surveyQuestionnaire.classList.add('d-block');
            document.documentElement.style.overflowY = 'hidden';
        }
    };
    SurveysComponent.prototype.onScroll = function (item) {
        if (document.getElementById('surveyQuestionnaire').scrollTop > 100) {
            this.showUpArrow = true;
        }
        else {
            this.showUpArrow = false;
        }
    };
    SurveysComponent.prototype.scrollToTop = function () {
        document.getElementById('surveyQuestionnaire').scrollTop = 0; // For Safari
        //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    SurveysComponent.prototype.getCampaigns = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, campaignArr, _loop_1, this_1, _i, campaignArr_1, val, activeCampaigns, closedCampaigns, withSurvey, withoutSurvey, status_1, allSurveys;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.campaignService.getCampaignsForProject(this.projectId).toPromise()
                        ])];
                    case 1:
                        request = _a.sent();
                        console.log('request', request);
                        if (request[0]['code'] === 'success' && request[0]['message']) {
                            campaignArr = Array.isArray(request[0]['message']) ? request[0]['message'] : [request[0]['message']];
                            if (campaignArr.length > 0) {
                                _loop_1 = function (val) {
                                    val.survey = this_1.campaignSurveys.find(function (item) { return parseInt(item.flex1) === val.id; });
                                };
                                this_1 = this;
                                for (_i = 0, campaignArr_1 = campaignArr; _i < campaignArr_1.length; _i++) {
                                    val = campaignArr_1[_i];
                                    _loop_1(val);
                                }
                            }
                            activeCampaigns = campaignArr.filter(function (item) { return item.closed_date == null; });
                            closedCampaigns = campaignArr.filter(function (item) { return item.closed_date != null; });
                            withSurvey = activeCampaigns.filter(function (item) { return item.survey; });
                            withoutSurvey = activeCampaigns.filter(function (item) { return !item.survey; });
                            status_1 = {
                                'draft': 1,
                                'active': 2,
                                'closed': 3
                            };
                            //withSurvey.sort((a, b) => status[a.survey.survey_stage_changelogs[0].change_type] - status[b.survey.survey_stage_changelogs[0].change_type]);        
                            withSurvey.sort(function (a, b) {
                                if (a.survey.survey_stage_changelogs[0].change_type === b.survey.survey_stage_changelogs[0].change_type) {
                                    // Price is only important when cities are the same
                                    if (a.survey.survey_stage_changelogs[0].change_at < b.survey.survey_stage_changelogs[0].change_at) {
                                        return 1;
                                    }
                                    if (a.survey.survey_stage_changelogs[0].change_at > b.survey.survey_stage_changelogs[0].change_at) {
                                        return -1;
                                    }
                                    return 0;
                                }
                                return status_1[a.survey.survey_stage_changelogs[0].change_type] - status_1[b.survey.survey_stage_changelogs[0].change_type];
                            });
                            withoutSurvey.sort(function (a, b) {
                                if (a.cr_date < b.cr_date) {
                                    return 1;
                                }
                                if (a.cr_date > b.cr_date) {
                                    return -1;
                                }
                            });
                            allSurveys = withoutSurvey.concat(withSurvey);
                            closedCampaigns.sort(function (a, b) {
                                if (a.closed_date < b.closed_date) {
                                    return 1;
                                }
                                if (a.closed_date > b.closed_date) {
                                    return -1;
                                }
                            });
                            this.campaigns = allSurveys.concat(closedCampaigns);
                            // this.campaigns = Array.isArray(request[0]['message']) ? request[0]['message'] : [request[0]['message']];
                            // this.campaigns.sort((a, b) => {
                            //   if (a.closed_date < b.closed_date) {
                            //     return 1;
                            //   }
                            //   if (a.closed_date > b.closed_date) {
                            //     return -1;
                            //   }
                            //   return 0;
                            // });
                            // this.campaigns = this.campaigns.reverse();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SurveysComponent.prototype.mapCampaignSurveysToCampaigns = function () {
        if (this.campaigns.length > 0) {
            var _loop_2 = function (val) {
                val.survey = this_2.campaignSurveys.find(function (item) { return parseInt(item.flex1) === val.id; });
            };
            var this_2 = this;
            for (var _i = 0, _a = this.campaigns; _i < _a.length; _i++) {
                var val = _a[_i];
                _loop_2(val);
            }
        }
    };
    // Create campaign survey for the given project, of the specified type (snapshot / campaign)
    SurveysComponent.prototype.createCampaignSurvey = function (template, c) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([this.surveyService.createCampaignSurvey(c.id).toPromise()])];
                    case 1:
                        requests = _a.sent();
                        if (requests[0].code === 'success') {
                            if (requests[0].message['cr_date']['val'] === 'CURRENT_TIMESTAMP') {
                                requests[0].message['cr_date'] = new Date();
                            }
                            this.allSurveys.unshift(requests[0].message);
                            this.filterSurveys();
                            this.getCampaigns();
                            this.spinner.hide();
                            this.surveyActionPrimary(requests[0].message, template);
                        }
                        else {
                            this.spinner.hide();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    //check if farmername contains spaces only
    SurveysComponent.prototype.checkEmptyness = function (e) {
        var name = e.target.value;
        if (!name.replace(/\s/g, '').length) {
            this.emptySurveyName = true;
        }
        else {
            this.emptySurveyName = false;
        }
    };
    return SurveysComponent;
}());
export { SurveysComponent };
