import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map, share } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { AppConfigService } from '../services/app-config.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export  class  LoginService {
  public  user:  any;

  constructor(private readonly http:  HttpClient, private readonly authenticationService: AuthenticationService,
         private readonly appConfigService: AppConfigService,
         @Inject(LOCALE_ID) private locale: string) {
          if (this.locale && this.locale.length > 2) {
            this.locale = this.locale.substr(0,2);
          }
         }

  private readonly loginUrl  = `${environment.apiBaseUrl}/login`;

    // Login service start her
  login(username: string, password: string, role: number, fp: string) {
    if (this.locale == 'en-US'){
      this.locale = 'en';
    }
    const headers = new HttpHeaders({
        'locale': this.locale
    });
    return this.http.post<any>( this.loginUrl, { username: username, password: password, role: role, fp: fp }, {headers}).pipe(
      map(data => {
        // login successful if there's a jwt token in the response
        return data;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    this.authenticationService.removeToken();
  }

  getVersion() {
    return this.http.get(this.loginUrl + '/getAppVersion').pipe(share());
  }

}
