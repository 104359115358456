import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
// import { Project } from '../models/project';



@Injectable()
export class RevisionService {

  revisionURL = this.appConfigService.getUrl(this.appConfigService.REVISION);

  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) {}

  MakeRevision(projectId: number): Observable<any> {
    return this.http.post( `${this.revisionURL}/byproject`, {project_id: projectId});
  }

  GetRevisionSchedule(projectId: any): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.revisionURL}/getschedule/${projectId}`);
  }
  SaveRevisionSchedule(scheduleObj: any): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return scheduleObj.id > 0 ? this.http.put(`${this.revisionURL}/updateSchedule/${scheduleObj.id}`, scheduleObj) : this.http.post(this.revisionURL + '/saveSchedule', scheduleObj);
  }

}
