import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';


import {AuthenticationService} from '../services/authentication.service';
import {StorageService} from '../services/storage.service';

@Injectable()
export class RememberMeGuard implements CanActivate {

  returnUrl = 'dashboard';

  constructor( private readonly router: Router, private readonly route: ActivatedRoute,
    private readonly authenticationService: AuthenticationService, private readonly storageService: StorageService) {}





  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
   
    const tokenObj = this.storageService.getLocalTokenInfo();

    if (tokenObj) {
      const userObj = JSON.parse(tokenObj);

      if (userObj) {
        

          const logout = this.storageService.getLocalStorage('lg');

          if ((userObj.rememberme || userObj.rememberme === true) && logout !== '1') {
            this.router.navigate([this.returnUrl]);
          }

          if (!navigator.onLine && (!logout || logout === '0')) {
            this.router.navigate([this.returnUrl]);
          }

      
      }

    }

    return true;

   }
}
