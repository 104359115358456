
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import { share, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppConfigService } from './app-config.service';
import { FarmerService } from './farmer.service';
import { ProjectService } from './project.service';
import { StorageService } from './storage.service';



@Injectable()
export  class  CommonService {
    public roles:  any;
    public allSnapshots;
    private readonly searchClearData = new BehaviorSubject<any>('default');
    currentSearchClearStatus = this.searchClearData.asObservable();

    private readonly ecosystemIdForAppScope = new BehaviorSubject<any>(0);
    ecosystemIdForAppScopeObs = this.ecosystemIdForAppScope.asObservable();

    private readonly projectList = new BehaviorSubject<any>([]);
    projectListObs = this.projectList.asObservable();

    constructor(private readonly http:  HttpClient, private readonly appConfigService: AppConfigService,
      private readonly storageService: StorageService, @Inject(LOCALE_ID) private readonly locale: string,
    private readonly toastr:ToastrService, private readonly translate: TranslateService, private readonly projectService: ProjectService,
   private readonly farmerService:FarmerService) {
    }

    userValue: any ;
    uamDataInService = [];

  private subject = new Subject<any>();



  updateCache() : any {
    if (navigator.onLine)
    {
      // if the snapshots were fetched more than environment.snapshotRefreshInterval, or they have not been fetched at all - then fetch them
      // else don't fetch the snapshots (10mb response)
      if (this.checkRole() != 'SYS1' && (!localStorage.getItem('snapshotsFetchedAt') || new Date().getTime() - +localStorage.getItem('snapshotsFetchedAt') > environment.snapshotRefreshInterval)) {
        return forkJoin(
          //this.farmerService.GetFarmerMaster(),
          this.blankAPICall(),
          this.farmerService.getFarmerManagementTabs(),
          this.farmerService.getFarmRegQuestions()
      );
      }
      else {
        return forkJoin(
          //this.farmerService.GetFarmerMaster(),
          this.blankAPICall(),
          this.blankAPICall(),
          this.farmerService.getFarmerManagementTabs(),
          this.farmerService.getFarmRegQuestions()
        );
      }
    } else {
      return;
    }

  }


  isFieldAgent(): boolean {

    this.userValue = JSON.parse(this.storageService.getLocalTokenInfo());
    return this.userValue.rcode === 'FAG';
  }
  isManager(): boolean {

    this.userValue = JSON.parse(this.storageService.getLocalTokenInfo());
    return this.userValue.rcode === 'PMG';
  }

  isDeeproots(): boolean {

    this.userValue = JSON.parse(this.storageService.getLocalTokenInfo());
    return this.userValue.rcode === 'SYS1';
  }

  checkRole(): any {
    let localTokenInfo = this.storageService.getLocalTokenInfo();
    if (localTokenInfo) {
      this.userValue = JSON.parse(localTokenInfo);
      return this.userValue.rcode;
    }
  }

  getLocale(): string {
    return this.locale;
  }


  isFarmer(): boolean {

    this.userValue = JSON.parse(this.storageService.getLocalTokenInfo());
    return this.userValue.rcode === 'FAR';
  }
  isServiceAdmin(): boolean {

    this.userValue = JSON.parse(this.storageService.getLocalTokenInfo());
    return this.userValue.rcode === 'SAD';
  }

  isSupplier(): boolean {

    this.userValue = JSON.parse(this.storageService.getLocalTokenInfo());
    return this.userValue.rcode === 'SUP';
  }
  isCollector(): boolean {

    this.userValue = JSON.parse(this.storageService.getLocalTokenInfo());
    return this.userValue.rcode === 'COL';
  }


  ValidateFile(file: File): string {
    // debugger;
    const allowedExtensions = ['jpg', 'jpeg', 'png','webp'];
    let fileExtension = file.name.split('.').pop();
    let msg = null;
    if(fileExtension){
      fileExtension = fileExtension.toString().toLowerCase();
    }else{
      msg = 'invalid_extension';
    }
    
    if (!msg && allowedExtensions.indexOf(fileExtension.toLowerCase()) === -1) {
      msg = 'invalid_extension';
    }
    if (!msg && file.size > 10485760) {
      msg = 'exceeds_size_limit';
    }

      return msg;
  }

  getProducts(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.PRODUCTS)).pipe(share());
  }

  getServices(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.SERVICES)).pipe(share());
  }

  getUnits(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.UNITS)).pipe(share());
  }

  getUnitTypes(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.UNITTYPES)).pipe(share());
  }

  getServiceComponents(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.SERVICECOMPONENTS)).pipe(share());
  }

  getCountries(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.COUNTRIES)).pipe(share());
  }
  getCurrencies(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.CURRENCY)).pipe(share());
  }

  getLanguages(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.LANGUAGES)).pipe(share());
  }

  getStatus(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.STATUS)).pipe(share());
  }

  getSuppliers(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.SUPPLIERS)).pipe(share());
  }

  getSuppliersProductsProjectSetup(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.SUPPLIERS_PRODUCTS_PROJECT_SETUP)).pipe(share());
  }

  getProjectKeyValue(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.PROJECT) + '/pair/').pipe(share());
  }

  getProductType(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.PRODUCTTYPE)).pipe(share());
  }
  getProjectManager(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.MANAGER)).pipe(share());
  }

  getRoles(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.ROLES)).pipe(share());
  }

  getRolesToMapToEcosystem(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.ROLES_MAP_ECOSYSTEM)).pipe(share());
  }

  blankAPICall(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.BLANK)).pipe(share());
  }

  getTrainingCertifications(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.TRAINING_CERTIFICATION)).pipe(share());
  }

  updateSnapshotStore(item) {
    let projectId = item.project;
    let snapshot = item.snapshot;

    // If this.allsnapshots exists
    if (this.allSnapshots) {
      // Check if this project already has snapshots
      let projectObjArray = this.allSnapshots.filter(item => item.project == projectId);
      // If this project has snapshots
      if (projectObjArray && projectObjArray.length > 0) {
        let projectObj = projectObjArray[0];
        let index1 = this.allSnapshots.indexOf(projectObj);
        // Check if this is a new snapshot or updated snapshot
        // If updated snapshot, swap it in place of the older version
        let snapshotObjArray = projectObj.snapshots.filter(item => item.id == snapshot.id);
        if (snapshotObjArray && snapshotObjArray.length > 0) {
          let snapshotObj = snapshotObjArray[0];
          let index = projectObj.snapshots.indexOf(snapshotObj);
          if (index && index > -1) projectObj.snapshots[index] = snapshot;
        }
        // If new snapshot, add it to this project's snapshot array
        else {
          projectObj.snapshots.push(snapshot);
        }
        // Update this.allSnapshots
        if (index1 && index1 > -1) this.allSnapshots[index1] = projectObj;
      }
      else {
        // This is the first snapshot of a project
        // Add the object to this.allSnapshots
        let item1 = {project: projectId, snapshots: [snapshot]};
        this.allSnapshots.push(item1);
      }
    }
    else {
      this.allSnapshots = [];
      let item1 = {project: projectId, snapshots: [snapshot]};
      this.allSnapshots.push(item1);
    }

  }

  

  insertValues(text, values) {
    Object.keys(values).forEach(key => {
      let newText = text.replace('$'+ key, values[key]);
      text = newText;
    });
    return text;
  }

  showToast(type: string, key: string, options?: any, pageReloadToast? : any, variableValues?:any): any {
    this.translate.get(key).subscribe(result => {
      if (variableValues) {
        let newResult = this.insertValues(result, variableValues);
        result  = newResult;
      }

      if (pageReloadToast) {
        this.toastr.warning('', result, options)
        .onTap
        .pipe(take(1))
        .subscribe(() => this.reloadPageOnClick());
      }

      if (type == 'warning') {
        this.toastr.warning('', result, options);
      }
      else if (type == 'success') {
        this.toastr.success('', result, options);
      }
      else if (type == 'info') {
        this.toastr.info('', result, options);
      }
      else if (type == 'error') {
        this.toastr.error('', result, options);
      }
    });
  }

  showCustomToast(type: string, key: string, appendStr: string): any {
    this.translate.get(key).subscribe(result => {
      result  = result+appendStr;

      if (type == 'warning') {
        this.toastr.warning('', result, {});
      }
      else if (type == 'success') {
        this.toastr.success('', result, {});
      }
      else if (type == 'info') {
        this.toastr.info('', result, {});
      }
      else if (type == 'error') {
        this.toastr.error('', result, {});
      }
    });
  }

  showToastLogin(type: string, key: string, options?: any,errorText?:any): any {
    let errorMsg:any;
    this.translate.get(key).subscribe(result => {
      errorMsg = result;
    });
    return errorMsg;
  }


  setSearchToBlank() {
    this.searchClearData.next('blank');
  }

  setEcosystemScope(ecosystemId) {
    this.ecosystemIdForAppScope.next(ecosystemId);
  }
  
  setProjectList(projectList) {
    this.projectList.next(projectList);
  }

  reloadPageOnClick() : void {
    location.reload();
  }

  getScheduleFrequency(): Observable<any> {
    return this.http.get(this.appConfigService.getUrl(this.appConfigService.REVISION)).pipe(share());
  }

  contactSupport(currentData): Observable<any> {
    return this.http.post<any>(this.appConfigService.getUrl(this.appConfigService.CONTACTSUPPORT), currentData);
  }

  blockToken(tokenData: any): Observable<any> {
    return this.http.post<any>(`${this.appConfigService.getUrl(this.appConfigService.USER)}/blockToken`, tokenData);
  }
  
    //added by kitan to replace math.random function
  getRandomNum(): any{
      let numArray = new Uint32Array(1);
      window.crypto.getRandomValues(numArray);
      return numArray[0];  
  }
  /**
   * Converting Blob image to array Buffer
   * @param blob 
   */
  blobToArrayBuffer(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        resolve(reader.result);
      });
      reader.addEventListener('error', reject);
      reader.readAsArrayBuffer(blob);
    });
  }

  /**
   * Converting Blob image to base64String
   * @param blob 
   */
  blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        resolve(reader.result);
      });
      reader.addEventListener('error', reject);
      reader.readAsDataURL(blob);
    });
  }

  private readonly userlogUrl = `${environment.apiBaseUrl}/api/common/userlog`;
  updateUserLog(deviceInfo, userId, randomNumForLog, totalProjects, projectsLoaded, currentPerc, ecosystemId, projectId): Observable<any> {
    return this.http.post<any>(this.userlogUrl, {deviceInfo, userId, randomNumForLog, totalProjects, projectsLoaded, currentPerc,ecosystemId,projectId});
  }
}
