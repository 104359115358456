import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProjectService } from '../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SurveyService } from '../../services/survey.service';
import { CommonService } from '../../services/common.service';
import { ConsReportService } from '../../services/consolidated-reports.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TranslateService } from '@ngx-translate/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-consolidated-report',
  templateUrl: './consolidated-report.component.html',
  styleUrls: ['./consolidated-report.component.css']
})
export class ConsolidatedReportComponent implements OnInit {
  isOnline: Observable<boolean>;
  projectId = 0;
  project: any;
  listsSectionsSubSection = [];
  questionnaire;
  notActiveSurvey = 2;
  notReleasedSurveyInLanguage;

  public dashboardAddObject ={
    "section":[],
    "subsection":[]
  }

  selectedTab = false;
  newSubSection = [];
  finalQuestionsArray = [];
  questionAndType = [];
  page: number = 1;
  itemsPerPage: number = 10;
  searchText = '';
  selectedQuestions = [];
  tooltipText;
  tooltipText2;
  selectDeselectLabels;
  isAscending = true;
  isAscendingName = true;
  selectPlaceholder:string;
  reportsTypeData;
  sectionForm: FormGroup; // Formgroup for currently active section
  
  selectedReportsMultiSelect = [];
  dropdownSettings = {};
  dropdownSettings1 = {};
  checkedObject = {};
  additionalTypeSelected = [];
  confirmTypeSave;
  confirmDefaultReset;
  surveyId;

  constructor(
    private readonly route: ActivatedRoute,
    private projectService: ProjectService,
    private toastrService: ToastrService,
    private readonly surveyService: SurveyService,
    private readonly commonService: CommonService,
    private readonly consReportService: ConsReportService,
    private translate: TranslateService,
    private readonly spinner: Ng4LoadingSpinnerService,
    public modalService: BsModalService,
    private router: Router,) {
      this.isOnline = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      );
      this.translate.get('select_deselect_object').subscribe(result => {
        this.selectDeselectLabels = result;
      });
      this.translate.get('selectPlaceholder').subscribe(result => {
        this.selectPlaceholder = result;
      });
     }

  async ngOnInit() {
    this.projectId = parseInt(this.route.snapshot.params['id']);
    // this.projectService.getProjectBasicInfo(this.projectId).subscribe(response =>{
    //   this.project = response.data;
    //   let projectObject = this.project.filter(item => item.id == this.projectId)[0];
    //   this.project = projectObject;
    // });
    const request = await Promise.all([
      this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
      this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise()
    ]);
    this.project = request[0].data;
    let projectObject = this.project.filter(item => item.id == this.projectId)[0];
    this.project = projectObject;
    if (request[1]['message'] === 'notActive') {
      this.notActiveSurvey = 1;
      this.commonService.showToast('warning', 'No Active Survey Found', {});
    } else if (request[1]['message'] === 'notReleasedForLanguage' || request[1]['message'].length == 0) {
      this.notReleasedSurveyInLanguage = 1;
    } else {
      // Set the questionnaire object
      this.questionnaire = request[1]['message'];
      this.notActiveSurvey = 0;
      this.notReleasedSurveyInLanguage = 0;
      this.surveyId = request[1]['message'][0].survey_id;
    }
    this.setSectionSubsection();
    this.reportsTypeData = [{
      "name": "Percent",
      "id" : 1,
      "q_type":"multiselect, select, text"
    },{
      "name": "Average",
      "id" : 2,
      "q_type":"number"
    },{
      "name": "Sum",
      "id" : 3,
      "q_type":"number"
    },{
      "name": "Min",
      "id" : 4,
      "q_type":"number"
    },{
      "name": "Max",
      "id" : 5,
      "q_type":"number"
    },{
      "name": "Median",
      "id" : 6,
      "q_type":"number"
    },{
      "name": "Count",
      "id" : 7,
      "q_type":"multiselect, select, text"
    },{
      "name": "Total Interviewees",
      "id" : 8,
      "q_type":"multiselect, select, text"
    },{
      "name": "Total Answers",
      "id" : 9,
      "q_type":"multiselect, select, text, number"
    }];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'sectionId',
      textField: 'section',
      allowSearchFilter: false,
      enableCheckAll:false,
      itemsShowLimit: 3
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true,
      enableCheckAll:true,
      itemsShowLimit: 3
    };
  }

  setSectionSubsection(){
    for(const item of this.questionnaire){
      let newObject = {
        "section": '',
        "sectionId": '',
        "subsection": []
      };
      newObject.section  = item.survey_q_asset_labels[0].label;
      newObject.sectionId  = item.id;
      let subSection = item.sections;
      for(let x of subSection){
        let subObject ={
          "name": x.survey_q_asset_labels[0].label,
          "id":x.id
        };
        newObject.subsection.push(subObject);
      }
      this.listsSectionsSubSection.push(newObject);
    }

  }

  selectSection(item) {
    if(this.dashboardAddObject.section.length > 0){
      this.selectedTab = true;
    } else {      
      this.selectedTab = false;
    }
    this.dashboardAddObject.subsection = [];
    this.newSubSection = [];
    for(const sec of this.dashboardAddObject.section){
      let section = this.listsSectionsSubSection.filter(item => item.sectionId == sec.sectionId)[0];
      if(section != undefined || section  != null){
        this.newSubSection.push(...section.subsection);
      }
    }
  }

  async genarateReports(){
    this.spinner.show();
    let questionnaireObject = [];
    for(const sec of this.dashboardAddObject.section){
      let qObject = this.questionnaire.filter(item => item.id == sec.sectionId);
      if(qObject){
        questionnaireObject.push(qObject[0]);
      }
    }
    
    const questionArray = [];
    if(this.dashboardAddObject.subsection.length > 0){      
      let subsectionArr = [];
      for(const SS of this.dashboardAddObject.subsection){   
        for(const sec of questionnaireObject){
          let qObject = sec.sections.filter(item => item.id == SS.id);
          if(qObject && qObject.length > 0){
            subsectionArr.push(qObject[0]);
          }
        }
      }
      for(const subSec of subsectionArr) {
        const subsection = subSec.subsections;
        if(subsection && subsection.length > 0){
          for(const item of subsection){
            const questions = item.questions;
            for(const q of questions){
              let obj ={
                "id":q.id,
                "name":q.survey_q_asset_labels[0].label,
                "original_questionnaire_item_code":q.original_questionnaire_item_code,
                "qa_subtype":q.qa_subtype
              }
              this.finalQuestionsArray.push(obj);
              questionArray.push(q.id);
            }
          }
        }
        const table = subSec.tables;
        if(table && table.length > 0){
          for(const item of table){
            const questions = item.questions;
            for(const q of questions){
              let obj ={
                "id":q.id,
                "name":q.survey_q_asset_labels[0].label,
                "original_questionnaire_item_code":q.original_questionnaire_item_code,
                "qa_subtype":q.qa_subtype
              }
              this.finalQuestionsArray.push(obj);
              questionArray.push(q.id);
            }
          }
        }
        const secQuestions = subSec.questions;
        for(const q of secQuestions){
          let obj ={
            "id":q.id,
            "name":q.survey_q_asset_labels[0].label,
            "original_questionnaire_item_code":q.original_questionnaire_item_code,
            "qa_subtype":q.qa_subtype
          }
          this.finalQuestionsArray.push(obj);
          questionArray.push(q.id);
        }
      }
    } else {
      for(const i of questionnaireObject){
        const sections = i.sections;
        for(const section of sections){
          const subsection = section.subsections;
          if(subsection && subsection.length > 0){
            for(const item of subsection){
              const questions = item.questions;
              for(const q of questions){
                let obj ={
                  "id":q.id,
                  "name":q.survey_q_asset_labels[0].label,
                  "original_questionnaire_item_code":q.original_questionnaire_item_code,
                  "qa_subtype":q.qa_subtype
                }
                this.finalQuestionsArray.push(obj);
                questionArray.push(q.id);
              }
            }
          }
          const table = section.tables;
          if(table && table.length > 0){
            for(const item of table){
              const questions = item.questions;
              for(const q of questions){
                let obj ={
                  "id":q.id,
                  "name":q.survey_q_asset_labels[0].label,
                  "original_questionnaire_item_code":q.original_questionnaire_item_code,
                  "qa_subtype":q.qa_subtype
                }
                this.finalQuestionsArray.push(obj);
                questionArray.push(q.id);
              }
            }
          }
          const secQuestions = section.questions;
          for(const q of secQuestions){
            let obj ={
              "id":q.id,
              "name":q.survey_q_asset_labels[0].label,
              "original_questionnaire_item_code":q.original_questionnaire_item_code,
              "qa_subtype":q.qa_subtype
            }
            this.finalQuestionsArray.push(obj);
            questionArray.push(q.id);
          }
        }
      }
    }
    const newRequest = {
      "questions":questionArray
    };
    const request = await Promise.all([
      this.consReportService.getConsolidationTypeData(this.projectId, newRequest).toPromise()
    ])
    if(request[0].code == "success"){
      // let isNew = false;
      // for(const q of request[0].message){
      //   const findQ = this.questionAndType.find(item => item.questionnaire_asset_id == q.questionnaire_asset_id);
      //   if(!findQ){
      //     isNew = true;
      //   }
      // }
      // if(isNew){
      this.questionAndType = request[0].message;
      this.toastrService.success("Unique questions loaded successfully, click on any question to extract it into excel");
      // } else {
      //   this.toastrService.warning("No unique questions found to be loaded, click on any question to extract it into excel");
      // }
      this.setForm();
    }
    this.spinner.hide();
  }

  setForm(): void {
    this.checkedObject = {};
    for(const item of this.questionAndType){
        this.checkedObject[item.questionnaire_asset_id] = item.cons_type;
      const findQ = this.finalQuestionsArray.find(item1 => item1.id == item.questionnaire_asset_id);
      if(findQ){
        item.qa_subtype = findQ.qa_subtype;
      }
    }

  }

  exportConsolidatedReport(template){
    if(this.additionalTypeSelected.length > 0){
      this.confirmTypeSave = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    } else {
      this.confirmModalFn('No');
    }
  }

  async confirmModalFn(decision){
    this.spinner.show();
    let typesToSave = [];
    if(decision == "Yes"){
      for(const item of this.additionalTypeSelected){
        const findQuestion = this.selectedQuestions.find(item1 => item1 == item.qid );
        if(findQuestion){
          const findItem = this.questionAndType.find(item2 => item2.questionnaire_asset_id == item.qid);
          if(findItem){
            const newObj ={
              "project_id":this.projectId,
              "questionnaire_asset_id":item.qid,
              "survey_id":findItem.survey_id,
              "code":findItem.code,
              "cons_type":item.type,
              "label":findItem.label,
              "deleted": item.deleted,
              "is_def_cons":0
            }
            typesToSave.push(newObj)
          }
        }
      }
    }
    const typeSelectedQuestions = [];
    for(const qid of this.selectedQuestions){
      const findNR = this.questionAndType.find(item => item.questionnaire_asset_id == qid && item.not_required == 1);
      
      for(const i in this.checkedObject){
        if(i == qid){
          if(this.checkedObject[i].length > 0 ){
            let obj = {
              'qid': qid,
              'type': this.checkedObject[i],
              'not_required': findNR ? 1 : 0
            }
            typeSelectedQuestions.push(obj)
          }
        }
      }
    }
    if(typeSelectedQuestions.length > 0){
      const newRequest = {
        "questions":typeSelectedQuestions
      };
      const typeRequest = {
        "types" : typesToSave
      }
      const requests = await Promise.all([
        this.consReportService.getProjectConsolidatedReport(this.projectId, newRequest).toPromise(),
        decision == 'Yes' ? this.consReportService.saveConsolidationTypeData(this.projectId, typeRequest).toPromise() : ''
      ])      
      // for(const i of requests[0].message){
      //   const survey3 = i[Object.keys(i)[7]];
      //   const survey2 = i[Object.keys(i)[6]];
      //   const survey1 = i[Object.keys(i)[5]];
      //   const variance1 = survey1 - survey2;
      //   i["Variance Survey 1 to 2"] = variance1.toFixed(1);
      //   const variance2 = survey2 - survey3;
      //   i["Variance Survey 2 to 3"] = variance2.toFixed(1);
      // }
      var wb = XLSX.utils.book_new();
      var wsBooks = XLSX.utils.json_to_sheet(requests[0].message);
      var wscols = [
        {wpx: 80}, // "pixels
        {wpx: 120},
        {wpx: 120},
        {wpx: 300},
        {wpx: 120},
        {wpx: 120},
        {wpx: 150},
        {wpx: 200},
        {wpx: 200},
        {wpx: 120}
      ];      
      wsBooks['!cols'] = wscols;
      const sheetName = "Consolidation Data"
      XLSX.utils.book_append_sheet(wb, wsBooks, sheetName); 
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      let linkdownload = document.createElement('a');
      linkdownload.href = URL.createObjectURL(blob);
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      const today1 = dd + '-' + mm + '-' + yyyy;
      linkdownload.download = `P2F-Roots_Consolidated_Report_Project_${this.projectId}_${today1}.xlsx`;
      linkdownload.style.display = 'none';
      document.body.appendChild(linkdownload);
      linkdownload.click();
      linkdownload.remove();
      this.selectedQuestions = [];
      this.additionalTypeSelected = [];
      this.toastrService.success("Excel Downloaded");
      this.resetQuestionnaire();
      this.spinner.hide();
      
    } 
    else {
      this.selectedQuestions = [];
      this.commonService.showToast('warning', 'Please select consolidation type for at least one question.', {preventDuplicates: true});
      this.spinner.hide();
    }
    if(this.confirmTypeSave){
      this.closeTypePopup();
    }
  }

  typeChanged(ele, val, qid){
    if (ele.target.checked) {
      for(const i in this.checkedObject){
        if(i == qid){
          let typeArr = this.checkedObject[i].split(', ');
          typeArr.push(val);
          typeArr = typeArr.filter(function (el) {
            return el != "";
          });
          this.checkedObject[i] = typeArr.join(', ');
        }
      }
      const additonalTypeObject ={
        "qid":qid,
        "type":val,
        "deleted":0
      }
      this.additionalTypeSelected.push(additonalTypeObject);
    } else {
      for(const i in this.checkedObject){
        if(i == qid){
          let typeArr = this.checkedObject[i].split(', ');
          for(let j = 0; j < typeArr.length; j++){
            if(val == typeArr[j]){
              typeArr.splice(j, 1);
            }
          }
          typeArr = typeArr.filter(function (el) {
            return el != "";
          });
          this.checkedObject[i] = typeArr.join(', ');
        }
      }
      let findType;
      for(let j = 0; j < this.additionalTypeSelected.length; j++){
        if(this.additionalTypeSelected[j].qid == qid && this.additionalTypeSelected[j].type == val){
          findType = this.additionalTypeSelected[j];
          this.additionalTypeSelected.splice(j, 1)
        }
      }
      if(!findType){
        const additonalTypeObject ={
          "qid":qid,
          "type":val,
          "deleted":1
        }
        this.additionalTypeSelected.push(additonalTypeObject);
      }
    }
  }

  resetToDefault(template){
    //resetDefaultConsolidationType
    this.confirmDefaultReset = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    
  }

  clearAll(){
    this.dashboardAddObject.section = [];
    this.dashboardAddObject.subsection = [];
    this.questionAndType = [];
    this.selectedQuestions = [];
    this.additionalTypeSelected = [];
    this.newSubSection = [];
  }

  selectAllConsTypes(){
    for(const qid of this.questionAndType){
      for(let type of this.reportsTypeData){
        if(type.q_type.indexOf(qid.qa_subtype) > -1){
          for(const i in this.checkedObject){
            if(i == qid.questionnaire_asset_id){
              let typeArr = this.checkedObject[i].split(', ');
              typeArr.push(type.name);
              typeArr = typeArr.filter(function (el) {
                return el != "";
              });
              this.checkedObject[i] = typeArr.join(', ');
            }
          }
          const additonalTypeObject ={
            "qid":qid.questionnaire_asset_id,
            "type":type.name,
            "deleted":0
          }
          this.additionalTypeSelected.push(additonalTypeObject);
        }
      }      
    }
  }

  confirmDefaultFn(){
    this.spinner.show();
    this.consReportService.resetDefaultConsolidationType(this.projectId, this.surveyId)
    .subscribe(result => {
      if (result.code == 'success') {
        this.toastrService.success(`Consolidation types set to default successfully!`);
      }
      this.resetQuestionnaire();
      this.closeDefaultFn();
      this.spinner.hide();
    })
  }

  async resetQuestionnaire(){
    const qids = this.questionAndType.map(item => item.questionnaire_asset_id);
    const newRequest = {
      "questions":qids
    };
    const request = await Promise.all([
      this.consReportService.getConsolidationTypeData(this.projectId, newRequest).toPromise()
    ])
    if(request[0].code == "success"){
      this.questionAndType = [];
      for(const q of request[0].message){
        const findQ = this.questionAndType.find(item => item.questionnaire_asset_id == q.questionnaire_asset_id);
        if(!findQ){
          this.questionAndType.push(q);
        }
      }
      this.setForm();
      this.additionalTypeSelected = [];
    }
  }

  closeDefaultFn(){
    this.confirmDefaultReset.hide();
  }

  closeTypePopup(){
    this.confirmTypeSave.hide();
  }

  getGridFilters() {
    const gridColums = ['label'];
    const finalObj = {};
    if (this.questionAndType === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  // Select / deselect all questions in the array
  toggleAllFarmers(): void {
    // If all questions are already selected, then deselect all farmers
    if (this.selectedQuestions.length == this.questionAndType.length) {
      this.selectedQuestions = [];
    }
    // If all questions are NOT already selected, select all the questions
    else {
      this.selectedQuestions = [];
      for (let i = 0; i < this.questionAndType.length; i++) {
        this.selectedQuestions.push(this.questionAndType[i].questionnaire_asset_id);
      }
    }
    this.setTooltipText();
  }

  // Toggle a particular question's selection status
  toggleThisFarmerSelect(id): void {
    if (this.selectedQuestions.indexOf(id) > -1) {
      this.selectedQuestions = this.selectedQuestions.filter(item => item != id);
    }
    else this.selectedQuestions.push(id);
    this.setTooltipText();
  }

  setTooltipText(): void {
    if (this.questionAndType && this.selectedQuestions) {
      if (this.selectedQuestions.length == this.questionAndType.length) {
        // Deselect farmers.length farmers
        if (this.questionAndType.length == 1) {
          this.tooltipText = `${this.selectDeselectLabels.deselect} 1 question`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.deselect} ${this.questionAndType.length} question`;
        }
      } else {
        // Select farmers.length farmers
        if (this.questionAndType.length == 1) {
          this.tooltipText = `${this.selectDeselectLabels.select} 1 question`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.select} ${this.questionAndType.length} question`;
        }
      }
    }
  }

  sortBy(sortOn: string) {
    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    if (sortBy === 'label') {
      this.isAscendingName = false;
      this.questionAndType.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return 1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } 

  }

  descendic(sortBy: string) {
    this.isAscending = true;
    if (sortBy === 'label') {
      this.isAscendingName = true;
      this.questionAndType.sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } 
  }

  getBackProjectFilter(projectNameVal, projectId) {
    const url = '/dashboard';
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }
  goToReportingManagement(projectID){
    let url = 'charts/' + this.projectId;
    this.router.navigate([url]);
  }
  goToDashboards(){
    let url="charts/dashboards/"+ this.projectId;
    this.router.navigate([url]);
  }

  trackBySectionName(index, dataItem) {
    return dataItem.section;
  }
  trackBySubsectionName(index, dataItem) {
    return dataItem.name;
  } 

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  cancel(){
    let url="charts/"+ this.projectId;
    this.router.navigate([url]);
  }

}
