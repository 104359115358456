import { Injectable } from '@angular/core';
import { CompactType,GridsterConfig, GridsterItem,GridType,DisplayGrid } from 'angular-gridster2';
//import { UUID } from 'angular2-uuid';
// import {DialogComponent} from '../dialog/dialog.component';

export interface IComponent {
  id: string;
  componentRef: string;
}
export interface DialogData {
  animal: string[];
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardAddService {
  animal: string[];
  name: string;
  dialogRef: any;
  result: any;

  public options: GridsterConfig = {
    gridType: GridType.VerticalFixed,
    displayGrid: DisplayGrid.None,
    compactType: CompactType.None,
    enableEmptyCellClick: true,
    minCols:3,
    maxCols: 3,
    minRows: 6,
    maxRows: 100,
    mobileBreakPoint: 640,
    minItemRows: 0,
    fixedRowHeight: 145,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: true,
    //emptyCellClickCallback: this.emptyCellClick.bind(this),
    pushItems: true,
    pushResizeItems: false,


    draggable: {
      enabled: true,
      stop: function(event, $element, widget) {
      }
    },
    resizable: {
      enabled: false
    }

  };
  public layout: GridsterItem[] = [];

  public components: IComponent[] = [];

  dropId: string;

  constructor() { }

  deleteItem(id: string): void {
    const item = this.layout.find(d => d.id === id);
    this.layout.splice(this.layout.indexOf(item), 1);
    //const comp = this.components.find(c => c.id === id);
    //this.components.splice(this.components.indexOf(comp), 1);
  }

  setDropId(dropId: string): void {
    this.dropId = dropId;
  }

  dropItem(dragId: string): void {
    const { components } = this;
    const comp: IComponent = components.find(c => c.id === this.dropId);

    const updateIdx: number = comp ? components.indexOf(comp) : components.length;
    const componentItem: IComponent = {
      id: this.dropId,
      componentRef: dragId
    };
    this.components = Object.assign([], components, { [updateIdx]: componentItem });
  }

  removeItem(item): void {

    this.layout.splice(this.layout.indexOf(item), 1);
  }

  getComponentRef(id: string): string {
    const comp = this.components.find(c => c.id === id);
    return comp ? comp.componentRef : null;
  }


  addChart(chartData,callType): void {
    if(callType == "edit")
    {
      this.layout.push(
        {id: chartData.reportId,cols: chartData.src.dim_col, rows: chartData.src.dim_row,y: chartData.src.y, x: chartData.src.x, maxSizeX: 1,maxsizeY: 1,resizeEnabled:false, compactEnabled:false,src:chartData.src.chartOptions, componentName: 'Chart'},

      );
    }
    else if(callType == "view")
    {
      this.layout.push(
        {id: chartData.reportId,cols: chartData.src.dim_col, rows: chartData.src.dim_row,y: chartData.src.y, x: chartData.src.x, maxSizeX: 1,maxsizeY: 1,dragEnabled:false,resizeEnabled:false, compactEnabled:false,src:chartData.src.chartOptions, componentName: 'Chart'},

      );
    }
    else
    {
      this.layout.push(
        {id: chartData.reportId,cols: chartData.dim_col, rows: chartData.dim_row,y: 0, x: 0, maxSizeX: 1,maxsizeY: 1,resizeEnabled:false,compactEnabled:false,src:chartData, componentName: 'Chart'},
      );
    }
    this.options.api.optionsChanged();
  }

  addKPI(kpiData,callType): void {

    if(callType == "edit")
    {
      this.layout.push(
        {id: kpiData.id,cols: kpiData.dim_col, rows: kpiData.dim_row, y: kpiData.y, x: kpiData.x,maxSizeX: 1,maxsizeY: 1,resizeEnabled:false,compactEnabled:false, src:kpiData, componentName: 'KPI'}

       );

    }
    else if(callType == "view")
    {
      this.layout.push(
        {id: kpiData.id,cols: kpiData.dim_col, rows: kpiData.dim_row, y: kpiData.y, x: kpiData.x,maxSizeX: 1,maxsizeY: 1,dragEnabled:false,resizeEnabled:false,compactEnabled:false, src:kpiData, componentName: 'KPI'}

       );
    }
    else
    {
      {
        this.layout.push(
          {id: kpiData.id,cols: kpiData.dim_col, rows: kpiData.dim_row, y: 0, x: 0,maxSizeX: 1,maxsizeY: 1,resizeEnabled:false,compactEnabled:false, src:kpiData, componentName: 'KPI'}

         );
      }
    }

   this.options.api.optionsChanged();
   console.log(this.layout);

  }



  deleteAll(): void {

    this.layout =[];
  }


}
