import { Component, OnInit } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '../../services/user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { TrainingService } from '../../services/training.service';

@Component({
  selector: 'app-project-training-mapping',
  templateUrl: './project-training-mapping.component.html',
  styleUrls: ['./project-training-mapping.component.css']
})
export class ProjectTrainingMappingComponent implements OnInit {
  isOnline: Observable<boolean>;
  projectTrainings:any;
  projects;
  public id:any;
  public previousPaginationLabel;
  public nextPaginationLabel;
  fromNavId;
  public typeOfAdministrationArr: any[]; 
  typeOfAdministration = '';
  currentUser = this.userService.getCurrentUser();
  searchText = '';
  searchTextFarmers;
  confirmFarmerModal;
  loadingFarmers = false;
  farmers = [];
  page = 1;
  itemsPerPage = 10;
  farmersListPage = 1;
  tooltipText;
  isAscending = true;
  isAscendingProject = true;
  isAscendingType = true;
  isAscendingName = true;
  addMappingModal: BsModalRef;
  public mappingAddObject ={
    "topic":0,
    "description":'',
    "project":0,
    'mandatory':'',
    'duedate':null,
    'frequency':0,
    "certificate":0,
    "certifiedby":0
  }
  unitTrustsPnl = 0;
  isCertificate = false;
  disableAddButton = true;
  formIsEdited = false;  
  public isTrainingOpen = false;
  public dateFormat = 'DD-MMM-YYYY';
  isMandatory = true;
  trainingCertifications;
  trainings;
  certifications;
  isEdit = false;
  currentMappingID;
  ecosystem_id;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly userService: UserService, 
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly trainingService: TrainingService,
    private readonly commonService: CommonService,
    private readonly projectService: ProjectService,
    public modalService: BsModalService,
  ) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.route.queryParamMap.subscribe(queryParams => {
      const qpObject = { ...queryParams.keys, ...queryParams };

      this.fromNavId = qpObject['params']['from'];

      this.translate.get('typeAdminTextArr').subscribe((val) => {
        this.typeOfAdministrationArr = val;
        if (this.fromNavId === '2') {
          this.typeOfAdministration = this.typeOfAdministrationArr[0];
        }
        else if (this.fromNavId === '3') {
          this.typeOfAdministration = this.typeOfAdministrationArr[1];
        }
      }, err => console.log('err', err));

    })

  this.isOnline = merge(
    of(navigator.onLine),
    fromEvent(window, 'online').pipe(mapTo(true)),
    fromEvent(window, 'offline').pipe(mapTo(false))
  );

  this.translate.get('previousPaginationLabel').subscribe(result => {
    this.previousPaginationLabel = result;
  });

  this.translate.get('nextPaginationLabel').subscribe(result => {
    this.nextPaginationLabel = result;
  });
   }

  async ngOnInit() {
    if (navigator.onLine) {
      this.spinner.show();
      this.ecosystem_id = localStorage.getItem('ecosystem_id');
      this.getTrainingMapping();
      const request = await Promise.all([
        this.projectService.GetProjectsBasicInfo().toPromise(),
        this.commonService.getTrainingCertifications().toPromise()
      ]);
      if (request[0]['msg'] === 'success'){
        this.projects = request[0]['data'];
      }
      if (request[1]['msg'] === 'success'){
        this.trainingCertifications = request[1]['data'];
      }
      this.trainings = this.trainingCertifications.filter(item => item.training_certification_type == 'Training' && item.status == 'Active');
      this.certifications = this.trainingCertifications.filter(item => item.training_certification_type == 'Certification' && item.status == 'Active');
      this.spinner.hide();
    }
  }

  getTrainingMapping(){    
    this.trainingService.getProjectTrainingMapping().subscribe(result => {      
      this.projectTrainings = result['data'];
    })
  }

  saveProjectTrainingMapping(){    
    this.spinner.show();
    let requestParams;
    let finalDueDate;
    if(this.mappingAddObject.frequency != 0){
      const dueDate = new Date();
      dueDate.setMonth(dueDate.getMonth() + this.mappingAddObject.frequency);
      finalDueDate = dueDate.getFullYear() + '-' 
      + ('0' + (dueDate.getMonth()+1)).slice(-2) + '-'
      + ('0' + dueDate.getDate()).slice(-2);
    }
    
    if(!this.isCertificate){
      requestParams = {
        "training_certification_id": this.mappingAddObject.topic,
        "project_id": this.mappingAddObject.project,
        "description": this.mappingAddObject.description,
        "is_training":1,
        "mandatory":this.mappingAddObject.mandatory ? 1 : 0,
        "due_date":finalDueDate,
        "frequency":this.mappingAddObject.frequency != 0 ? this.mappingAddObject.frequency : null
      }
    } else {
      requestParams = {
        "training_certification_id": this.mappingAddObject.certificate,
        "project_id": this.mappingAddObject.project,
        "description": this.mappingAddObject.description,
        "is_training":0,
        "certified_by":this.mappingAddObject.certifiedby
      }
    }
    console.log('requestParams', requestParams)
    this.trainingService.saveProjectTCMapping(requestParams, this.isEdit, this.currentMappingID).subscribe(result => {
      if (result['msg'] === 'MAPPING_CREATED' || result['msg'] === 'MAPPING_UPDATED') {
        if(result['msg'] === 'MAPPING_CREATED'){
          this.commonService.showToast('success', 'success_master_data_created', {});
        } else {
          this.commonService.showToast('success', 'success_master_data_updated', {});
        }
        this.closePopUpYes();
        this.getTrainingMapping();          
        this.spinner.hide();
      }
    })
  }

  async editProjectTrainingMapping(id, template){    
    this.spinner.show();
    this.isEdit = true;
    this.currentMappingID = id;
    const request = await Promise.all([
      this.trainingService.getProjectTrainingMapping(id).toPromise()
    ]);
    const curMapping = request[0]['data'];
    if(curMapping.is_training == 0){
      this.isCertificate = true;
      this.unitTrustsPnl = 1;
    } else {
      this.isCertificate = false;
      this.unitTrustsPnl = 0;
    }
    if(curMapping.mandatory == 1){
      this.isMandatory = false;
    }
    if(curMapping && !this.isCertificate){
      this.mappingAddObject.topic = curMapping.training_certification_master.id;
      this.mappingAddObject.project = curMapping.project_id;
      this.mappingAddObject.description = curMapping.description;
      this.mappingAddObject.mandatory = curMapping.mandatory;
      this.mappingAddObject.frequency = curMapping.frequency;
    }
    if(curMapping && this.isCertificate){
      this.mappingAddObject.certificate = curMapping.training_certification_master.id;
      this.mappingAddObject.project = curMapping.project_id;
      this.mappingAddObject.description = curMapping.description;
      this.mappingAddObject.certifiedby = curMapping.certified_by;
    }
    this.addMappingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    
    this.spinner.hide();
  }

  addProjectGroupMapping(template){
    this.isCertificate = false;
    this.addMappingModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }
  closePopUp(form: any){
    if(form.dirty){
      this.formIsEdited = true;
    } else {
      this.resetMappingObject()
      this.addMappingModal.hide();
    }
  }
  closePopUpYes(){
    this.resetMappingObject()
    this.addMappingModal.hide();
    this.formIsEdited = false;
  }
  closePopUpNo(){
    this.formIsEdited = false;
  }

  resetMappingObject(){    
    this.mappingAddObject.topic = 0;
    this.mappingAddObject.project = 0;
    this.mappingAddObject.description = '';
    this.mappingAddObject.mandatory = '';
    this.mappingAddObject.frequency = 0;
    this.mappingAddObject.certificate = 0;
    this.mappingAddObject.certifiedby = 0;
    this.disableAddButton = true;
    this.unitTrustsPnl = 0;
    this.isEdit = false;
    this.isMandatory = true;
  }

  showAddButton(){
    if(!this.isCertificate){
      if(this.mappingAddObject.topic != 0 && this.mappingAddObject.project != 0){
        if(!this.isMandatory){
          if(this.mappingAddObject.frequency == 0){
            this.disableAddButton = true;
          } else {
            this.disableAddButton = false;
          }
        } else {
          this.disableAddButton = false;
        }
      } else {
        this.disableAddButton = true; 
      }
    } else {
      if(this.mappingAddObject.certificate != 0 && this.mappingAddObject.project != 0){
        this.disableAddButton = false;
      } else {
        this.disableAddButton = true; 
      }
    }
  }

  toggleDueDate(eve){
    if(eve.target.checked){
      this.isMandatory = false;
      this.showAddButton();
    } else {
      this.isMandatory = true;
      this.mappingAddObject.frequency = 0;
      this.showAddButton();
    }
  }

  typeChanged(type){
    if(type == 'certificate'){
      this.isCertificate = true;
    } else {
      this.isCertificate = false;
    }
    this.resetMappingObject();
    this.showAddButton();
  }

  exportToExcel() : void {
    this.trainingService.exportToExcel().subscribe(result => {
      console.log('result', result);
      var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
      const blob = new Blob([result], {type: contentType});
      const linkdownload = document.createElement('a');
      linkdownload.href = URL.createObjectURL(blob);
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();
      var today1 = `${dd}-${mm}-${yyyy}`;
      this.translate.get('training_mapping').subscribe((val) => {
        linkdownload.download =`P2F-Roots_${val}_ExcelExport_${today1}.xlsx`;
        linkdownload.style.display = 'none';
        document.body.appendChild(linkdownload);
        linkdownload.click();
      }, err => console.log('err', err));
    }, err=> console.log('err', err))
  }

  sortBy(sortOn: string) {

    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);

  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    this.isAscendingType = sortBy !== 'master_type';
    this.isAscendingName = sortBy !== 'master_name';
    this.isAscendingProject = sortBy !== 'project_name';
    // Sort of new fields added
    if (sortBy === 'master_type' || sortBy === 'master_name' || sortBy === 'project_name') {
      this.projectTrainings.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    }

  }

  descendic(sortBy: string) {
    this.isAscending = true;

    this.isAscendingType = sortBy === 'master_type';
    this.isAscendingName = sortBy === 'master_name';
    this.isAscendingProject = sortBy === 'project_name';
    // Sort of new fields added
    if (sortBy === 'master_type' || sortBy === 'master_name' || sortBy === 'project_name') {
      this.projectTrainings.sort((a, b) => {
        if(a && a[sortBy] != null && b && b[sortBy] != null){
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
            return 1;
          }
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return 0;
      });
    }
  }

  getGridFilters() {
    const gridColums = ['master_type','master_name','project_name'];
    
    const finalObj = {};
    if (this.projectTrainings === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  
  trackByGroupName(index, dataItem) {
    return dataItem.groupName;
  }
  trackByProjectName(index, dataItem) {
    return dataItem.groupName;
  }
  trackByTypeName(index, dataItem) {
    return dataItem.groupName;
  }
  trackByStatusName(index, dataItem) {
    return dataItem.groupName;
  }

}
