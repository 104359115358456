
import {Observable, forkJoin, of } from 'rxjs';


import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';


import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';





import { FarmerService } from '../services/farmer.service';
import { ProjectService } from '../services/project.service';
import { Farmer } from '../models/farmer';

  // mapping: any[];
interface IReturn {
  master: any[];
  project: any;
  errormsg?: any;
}

@Injectable()
export class FarmerResolver implements Resolve<IReturn> {


  constructor(private readonly projectService: ProjectService, private readonly farmerService: FarmerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IReturn> {


  let services: Observable<any>[];

  if (navigator.onLine) {
    services = [this.projectService.GetProjectById(route.params['projectId'] || 0), this.farmerService.GetFarmer(route.params['id'] || 0)];
   } else {
     services = [of([]), of([])];
   }

  return  forkJoin ([
    this.farmerService.GetFarmerMaster().pipe(map((results) => results), catchError(e => of([]))),
    this.projectService.getProjectBasicInfo(route.params['projectId'] || 0).pipe(map((results) => results), catchError(e => of([]))),
    this.projectService.getProjectDashboardData(route.params['projectId'] || 0).pipe(map((results) => results), catchError(e => of([])))
  ]).pipe(
    map(results => ({
      master: results[0],
      project: {...results[1]['data'][0], ...results[2]['data'][0]}
    })
  ))
}
}
