import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare var idbApp;

@Injectable()
export class StorageService {

    // Set data from storage

    constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {}

    setSessionTokenInfo(data: string): void {
      if (isPlatformBrowser(this.platformId)) {
          sessionStorage['currentUser'] = data;
      }
    }

    setSessionUserDetails(data: string): void {
      if (isPlatformBrowser(this.platformId) && sessionStorage['UserDetails'] != data) {
          sessionStorage['UserDetails'] = data;
      }
    }
    setLocalTokenInfo(data: string): void {
      if (isPlatformBrowser(this.platformId)) {
        localStorage['currentUser'] = data;
      }
    }

    setIDBCurrentUser(data: string): void {
      if (isPlatformBrowser(this.platformId)) {
        const obj = {
          data: data
        };
        obj['currentUser_key'] = 'currentUser_key';
        idbApp.addStoreInfo([obj], 'currentUser');
      }
    }

    setLocalUserDetails(data: string): void {
      if (isPlatformBrowser(this.platformId) && localStorage['UserDetails'] != data) {
        localStorage['UserDetails'] = data;
      }
    }

    setLocalStorage(key: string , val: string): void {
      if (isPlatformBrowser(this.platformId)) {
        localStorage[key] = val;
      }
    }
    // Get data from storage
    getLocalStorage(key: string ): string {
      if (isPlatformBrowser(this.platformId)) {
        return localStorage[key] ? localStorage[key] : null;
      }
    }

    getSessionTokenInfo(): string {
      if (isPlatformBrowser(this.platformId)) {
        return sessionStorage['currentUser'] ? sessionStorage['currentUser'] : null;
      }
    }

    getSessionUserDetails(): string {
      if (isPlatformBrowser(this.platformId)) {
        return sessionStorage['UserDetails'] ? sessionStorage['UserDetails'] : null;
      }
    }
    getLocalTokenInfo(): any {
      if (isPlatformBrowser(this.platformId)) {
        if (localStorage['currentUser'] !== 'undefined' &&  localStorage['currentUser'] !== undefined) {
          return localStorage['currentUser'] ? localStorage['currentUser'] : null;
        } else {
          return null;
        }
      }
    }

    getLocalUserDetails(): string {
      if (isPlatformBrowser(this.platformId)) {
        return localStorage['UserDetails'] ? localStorage['UserDetails'] : null;
      }
    }
}
