import * as tslib_1 from "tslib";
// TODO: Done: Build questionnaire from blank slate
// TODO: Done: Bug in option reordering
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// Services
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ChangeDetectorRef } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TranslateService } from '@ngx-translate/core';
var PreviewTemplateComponent = /** @class */ (function () {
    function PreviewTemplateComponent(dynamicQuestionnaireService, route, surveyService, projectService, commonService, toastr, modalService, ref, router, spinner, locale, translate) {
        var _this = this;
        this.dynamicQuestionnaireService = dynamicQuestionnaireService;
        this.route = route;
        this.surveyService = surveyService;
        this.projectService = projectService;
        this.commonService = commonService;
        this.toastr = toastr;
        this.modalService = modalService;
        this.ref = ref;
        this.router = router;
        this.spinner = spinner;
        this.locale = locale;
        this.translate = translate;
        this.reloadingQuestionnaire = true;
        this.userLanguages = [];
        this.isTemplate = false;
        this.showHideBackButton = true;
        this.showHideNextButton = false;
        this.loadingTextValue = 'Please wait';
        if (this.locale && this.locale.length > 2) {
            this.locale = this.locale.substr(0, 2);
        }
        this.translate.get('please_wait').subscribe(function (result) {
            _this.loadingTextValue = result;
        });
    }
    PreviewTemplateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.spinner.show();
        this.route.paramMap.subscribe(function (params) {
            _this.route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var request, x, request, x;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.projectId = params.get('projectId');
                            this.surveyId = params.get('surveyId');
                            this.templateId = params.get('templateId');
                            if (!(this.templateId >= 0)) return [3 /*break*/, 2];
                            this.isTemplate = true;
                            return [4 /*yield*/, Promise.all([
                                    this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                    this.surveyService.getTemplateQuestionnaire(this.templateId).toPromise(),
                                    this.surveyService.getTemplateMetadata(this.templateId).toPromise(),
                                    this.surveyService.getSurvey(this.surveyId).toPromise(),
                                    this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                    this.surveyService.getSurveyLanguages().toPromise()
                                ])];
                        case 1:
                            request = _a.sent();
                            if (request[0]['msg'] != 'success' || request[4]['msg'] != 'success') {
                                this.commonService.showToast('error', 'generic_error', {});
                            }
                            else {
                                this.project = tslib_1.__assign({}, request[0].data[0], request[4].data[0]);
                            }
                            if (request[5]['message']) {
                                this.userLanguages = request[5]['message'];
                            }
                            if (request[1]['message']) {
                                if (request[1]['message'].length > 0) {
                                    this.questionnaire = request[1]['message'];
                                }
                                this.initialize(0, 0);
                                this.reloadingQuestionnaire = false;
                                x = this.questionnaire[0]['survey_q_asset_labels'].find(function (item) { return item.language_id == _this.getLanguageId(); });
                                // currentLocaleLanguageIdIndex stores the index of the current locale's language ID in the questionnaire's asset's survey_q_asst_labels array
                                // currentLocaleLanguageIdIndex is used in the html file to show / add the survey_q_asset_label for the current locale
                                this.currentLocaleLanguageIdIndex = this.questionnaire[0]['survey_q_asset_labels'].indexOf(x);
                                this.spinner.hide();
                            }
                            if (request[2]['message']) {
                                this.template = request[2]['message'];
                            }
                            if (request[3]['message']) {
                                this.survey = request[3]['message'];
                            }
                            return [3 /*break*/, 4];
                        case 2:
                            this.isTemplate = false;
                            return [4 /*yield*/, Promise.all([
                                    this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                    this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                    this.surveyService.getSurvey(this.surveyId).toPromise(),
                                    this.surveyService.getSurveyQuestionnaire(this.surveyId).toPromise(),
                                    this.surveyService.getAssociatedTemplates(this.surveyId).toPromise(),
                                    this.surveyService.getSurveyLanguages().toPromise()
                                ])];
                        case 3:
                            request = _a.sent();
                            if (request[0]['msg'] != 'success' || request[1]['msg'] != 'success') {
                                this.commonService.showToast('error', 'generic_error', {});
                            }
                            else {
                                this.project = tslib_1.__assign({}, request[0].data[0], request[1].data[0]);
                            }
                            if (request[2]['message']) {
                                this.survey = request[2]['message'];
                            }
                            if (request[5]['message']) {
                                this.userLanguages = request[5]['message'];
                            }
                            if (request[3]['message']) {
                                if (request[3]['message'].length > 0) {
                                    this.questionnaire = request[3]['message'];
                                    this.spinner.hide();
                                }
                                this.initialize(0, 0);
                                this.reloadingQuestionnaire = false;
                                x = this.questionnaire[0]['survey_q_asset_labels'].find(function (item) { return item.language_id == _this.getLanguageId(); });
                                // currentLocaleLanguageIdIndex stores the index of the current locale's language ID in the questionnaire's asset's survey_q_asst_labels array
                                // currentLocaleLanguageIdIndex is used in the html file to show / add the survey_q_asset_label for the current locale
                                this.currentLocaleLanguageIdIndex = this.questionnaire[0]['survey_q_asset_labels'].indexOf(x);
                            }
                            _a.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    PreviewTemplateComponent.prototype.tabClicked = function (tabIndex, sectionIndex) {
        delete this.currentTab;
        this.currentTab = JSON.parse(JSON.stringify(this.questionnaire[tabIndex]));
        this.currentTab.sections = this.currentTab.sections.filter(function (item) { return !item.removed_from_questionnaire; });
        this.currentTabIndex = tabIndex;
        this.currentSectionIndex = sectionIndex;
        this.sectionClicked(this.currentSectionIndex);
        setTimeout(function () {
            document.querySelector('ul.sub-tab-fixed').scrollLeft -= 1500;
        }, 300);
    };
    // To select last section on click of prev button
    PreviewTemplateComponent.prototype.tabClickedSectionLast = function (tabIndex) {
        this.currentTab = this.questionnaire[tabIndex];
        this.currentTab.sections = this.currentTab.sections.filter(function (item) { return !item.removed_from_questionnaire; });
        this.currentTabIndex = tabIndex;
        this.currentSectionIndex = this.currentTab.sections.length - 1;
        this.sectionClicked(this.currentSectionIndex);
        setTimeout(function () {
            document.querySelector('ul.sub-tab-fixed').scrollLeft += 1500;
        }, 300);
    };
    PreviewTemplateComponent.prototype.sectionClicked = function (sectionIndex) {
        if (this.currentTab) {
            delete this.currentSection;
            if (this.currentTab.sections && this.currentTab.sections.length > 0) {
                this.currentSection = JSON.parse(JSON.stringify(this.currentTab.sections[sectionIndex]));
                this.currentSectionIndex = sectionIndex;
                this.addUOM(this.currentSection);
                this.ref.detectChanges();
                this.reloadingQuestionnaire = false;
            }
            else {
                this.ref.detectChanges();
                this.reloadingQuestionnaire = false;
            }
        }
        else {
            this.ref.detectChanges();
            this.reloadingQuestionnaire = false;
        }
        this.disableButton(sectionIndex);
    };
    PreviewTemplateComponent.prototype.initialize = function (tabIndex, sectionIndex) {
        this.filterSnapshotObject();
        this.currentTabIndex = tabIndex;
        this.tabClicked(this.currentTabIndex, sectionIndex);
    };
    PreviewTemplateComponent.prototype.filterSnapshotObject = function () {
        this.questionnaire = this.questionnaire.filter(function (item) { return !item.removed_from_questionnaire; });
    };
    PreviewTemplateComponent.prototype.addUOM = function (section) {
        var questions = section.questions;
        var subsections = section.subsections;
        var tables = section.tables;
        if (questions && questions.length > 0) {
            var _loop_1 = function (i) {
                if (questions[i].uom_questions && questions[i].uom_questions.length > 0) {
                    var _loop_4 = function (j) {
                        // Set the options dropdown for this uom_question
                        questions[i].uom_questions[j].options = this_1.project.ProjectUOM.filter(function (item) { return item.unit_type_id == questions[i].uom_questions[j].qa_subtype; });
                    };
                    for (var j = 0; j < questions[i].uom_questions.length; j++) {
                        _loop_4(j);
                    }
                }
            };
            var this_1 = this;
            for (var i = 0; i < questions.length; i++) {
                _loop_1(i);
            }
        }
        if (subsections && subsections.length > 0) {
            var _loop_2 = function (i) {
                var subquestions = subsections[i].questions;
                if (subquestions && subquestions.length > 0) {
                    var _loop_5 = function (i_1) {
                        if (subquestions[i_1].uom_questions && subquestions[i_1].uom_questions.length > 0) {
                            var _loop_6 = function (j) {
                                subquestions[i_1].uom_questions[j].options = this_2.project.ProjectUOM.filter(function (item) { return item.unit_type_id == subquestions[i_1].uom_questions[j].qa_subtype; });
                            };
                            for (var j = 0; j < subquestions[i_1].uom_questions.length; j++) {
                                _loop_6(j);
                            }
                        }
                    };
                    for (var i_1 = 0; i_1 < subquestions.length; i_1++) {
                        _loop_5(i_1);
                    }
                }
            };
            var this_2 = this;
            for (var i = 0; i < subsections.length; i++) {
                _loop_2(i);
            }
        }
        if (tables && tables.length > 0) {
            var _loop_3 = function (i) {
                var subquestions = tables[i].questions;
                if (subquestions && subquestions.length > 0) {
                    var _loop_7 = function (i_2) {
                        if (subquestions[i_2].uom_questions && subquestions[i_2].uom_questions.length > 0) {
                            var _loop_8 = function (j) {
                                subquestions[i_2].uom_questions[j].options = this_3.project.ProjectUOM.filter(function (item) { return item.unit_type_id == subquestions[i_2].uom_questions[j].qa_subtype; });
                            };
                            for (var j = 0; j < subquestions[i_2].uom_questions.length; j++) {
                                _loop_8(j);
                            }
                        }
                    };
                    for (var i_2 = 0; i_2 < subquestions.length; i_2++) {
                        _loop_7(i_2);
                    }
                }
            };
            var this_3 = this;
            for (var i = 0; i < tables.length; i++) {
                _loop_3(i);
            }
        }
    };
    // Get the languageId of the current locale
    PreviewTemplateComponent.prototype.getLanguageId = function () {
        var _this = this;
        if (this.userLanguages && this.userLanguages.length > 0) {
            return this.userLanguages.find(function (item) { return item.locale == _this.locale; })['id'];
        }
        else {
            return undefined;
        }
    };
    PreviewTemplateComponent.prototype.disableButton = function (newSectionIndex) {
        if (newSectionIndex <= 0 && this.currentTabIndex <= 0) {
            this.showHideBackButton = true;
        }
        else {
            this.showHideBackButton = false;
        }
        if (newSectionIndex == this.currentTab.sections.length - 1 && this.currentTabIndex == this.questionnaire.length - 1) {
            this.showHideNextButton = true;
        }
        else {
            this.showHideNextButton = false;
        }
    };
    PreviewTemplateComponent.prototype.navigateSection = function (direction) {
        // Find the section index to navigate to
        var newSectionIndex = this.currentSectionIndex + direction;
        var curActiveSection = document.querySelector('ul.sub-tab-fixed li a.active');
        this.disableButton(newSectionIndex);
        // If < 0, that indicates you need to move to the previous tab if the tab's index > 0
        if (newSectionIndex < 0) {
            var newTabIndex = this.currentTabIndex + direction;
            if (newTabIndex >= 0) {
                this.tabClickedSectionLast(newTabIndex);
            }
        }
        // If > 0 and less than the number of sections in the current tab, move to the clicked section's index
        else if (newSectionIndex >= 0 && newSectionIndex < this.currentTab.sections.length) {
            this.sectionClicked(newSectionIndex);
            if (direction > 0) {
                var nextActiveSection = curActiveSection.parentElement.nextElementSibling;
                document.querySelector('ul.sub-tab-fixed').scrollLeft = nextActiveSection.offsetLeft;
            }
            else {
                var nextActiveSection = curActiveSection.parentElement.previousElementSibling;
                document.querySelector('ul.sub-tab-fixed').scrollLeft = nextActiveSection.offsetLeft;
            }
        }
        // In other conditions, navigate to the next tab (e.g. you click next in the last section of any tab, except the last tab)
        else {
            var newTabIndex = this.currentTabIndex + direction;
            if (newTabIndex < this.questionnaire.length && newTabIndex > 0) {
                this.tabClicked(newTabIndex, 0);
            }
        }
    };
    PreviewTemplateComponent.prototype.useTemplateConfirmation = function (template) {
        this.useTemplateConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    PreviewTemplateComponent.prototype.closeUseTemplateConfirmationModal = function () {
        if (this.useTemplateConfirmModal) {
            this.useTemplateConfirmModal.hide();
        }
    };
    PreviewTemplateComponent.prototype.useTemplate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, Promise.all([this.surveyService.createQuestionnaireForSurvey(this.surveyId, this.templateId, {}).toPromise()])];
                    case 1:
                        requests = _a.sent();
                        if (requests[0].code == 'success') {
                            this.closeUseTemplateConfirmationModal();
                            this.toastr.success('Questionnaire created in languages: ' + requests[0].message);
                            this.spinner.hide();
                            this.router.navigate(['questionnaire/edit/' + this.projectId + '/' + this.surveyId]);
                        }
                        else {
                            this.spinner.hide();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PreviewTemplateComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    //Missing functions added by Kiran
    PreviewTemplateComponent.prototype.leftScroll = function () {
        document.querySelector('ul.sub-tab-fixed').scrollLeft -= 500;
    };
    PreviewTemplateComponent.prototype.rightScroll = function () {
        document.querySelector('ul.sub-tab-fixed').scrollLeft += 500;
    };
    return PreviewTemplateComponent;
}());
export { PreviewTemplateComponent };
