import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { mapTo } from 'rxjs/operators';
import { fromEvent, merge, of } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SuppliersService } from '../../services/suppliers.service';
var EcosystemmanagementComponent = /** @class */ (function () {
    function EcosystemmanagementComponent(route, commonService, router, farmerService, projectService, translate, toastrService, modalService, localeService, formBuilder, revisionService, suppService, spinner, locale) {
        var _this = this;
        this.route = route;
        this.commonService = commonService;
        this.router = router;
        this.farmerService = farmerService;
        this.projectService = projectService;
        this.translate = translate;
        this.toastrService = toastrService;
        this.modalService = modalService;
        this.localeService = localeService;
        this.formBuilder = formBuilder;
        this.revisionService = revisionService;
        this.suppService = suppService;
        this.spinner = spinner;
        this.locale = locale;
        this.projectId = 0;
        this.farmers = [];
        this.productType = [];
        this.managers = [];
        this.confirmModal = true;
        this.isAscending = true;
        this.isAscendingId = true;
        this.isAscendingName = true;
        this.isAscendingTel = true;
        this.isAscendingDate = true;
        this.sortLabel = '';
        this.showSpinner = false;
        this.isFieldAgent = false;
        this.isManager = false;
        this.isDeeproots = false;
        this.farmerFilterString = { name: '' };
        this.page = 1;
        this.itemsPerPage = 10;
        this.isInIDBInThisProject = [];
        this.showSyncSuccessAlert = false;
        this.olderSnapshots = [];
        this.minDate = new Date();
        this.noSnapshotsInProject = false;
        this.fetchingSnapshotInfo = true;
        this.disableCreateSnapshot = false;
        this.disableCloseSnapshot = false;
        this.registeringFarmer = false;
        this.registerAnotherFarmer = false;
        this.fileToUpload = null;
        this.photoModel = '';
        this.oldSnapshotId = 0;
        this.arrSnapshotRevisions = [];
        this.snapshotRevision = [];
        this.searchText = '';
        this.countryData = {};
        this.autoSelectData = {};
        this.multiSelectData = {};
        this.addForm = false;
        this.f1Submitted = false;
        this.confirmUpdateModal = true;
        this.route.paramMap.subscribe(function (params) {
            _this.id = params.get('id');
        });
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
        this.localeService.use(this.locale);
    }
    EcosystemmanagementComponent.prototype.snapshotChanged = function () {
        var _this = this;
        this.snapshotRevision = this.arrSnapshotRevisions.filter(function (x) { return x.snapshot_id_ref === _this.oldSnapshotId; });
    };
    EcosystemmanagementComponent.prototype.onKeydownHandler = function (event) {
        if (this.addForm) {
            this.hideAddUserModal(this.ecoSystemUserForm);
        }
    };
    EcosystemmanagementComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var code, requests;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scrollToTop();
                        this.ecoSystemUserForm = this.formBuilder.group({});
                        this.projectId = +this.route.snapshot.params['id'];
                        code = this.commonService.checkRole();
                        if (code == 'FAG') {
                            this.isFieldAgent = true;
                        }
                        else if (code === 'PMG') {
                            this.isManager = true;
                        }
                        else if (code === 'DRO') {
                            this.isDeeproots = true;
                        }
                        if (!navigator.onLine) return [3 /*break*/, 1];
                        this.spinner.show();
                        this.projectService.getEcosystems()
                            .subscribe(function (ecosystems) {
                            if (ecosystems.msg === 'success') {
                                var finalObj = [];
                                for (var i = 0; i < ecosystems.data.length; i++) {
                                    var newObject = {};
                                    newObject[i] = {
                                        'suppliername': ecosystems.data[i].ecosystem.supplier.name,
                                        'productname': ecosystems.data[i].ecosystem.product.name,
                                        'xcoordinate': ecosystems.data[i].ecosystem.x_coordinate,
                                        'ycoordinate': ecosystems.data[i].ecosystem.y_coordinate,
                                        'marker_description': ecosystems.data[i].ecosystem.marker_description,
                                        'status': ecosystems.data[i].ecosystem.status
                                    };
                                    finalObj.push(newObject[i]);
                                }
                                _this.ecosystems = finalObj;
                                _this.spinner.hide();
                                _this.getAddUserData();
                            }
                        }, function (err) {
                            _this.spinner.hide();
                        });
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, Promise.all([
                            this.projectService.GetProjectsDataDashboard().toPromise()
                        ])];
                    case 2:
                        requests = _a.sent();
                        this.project = requests[0].data.find(function (x) { return x.id === _this.projectId; });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EcosystemmanagementComponent.prototype.getProjectData = function () {
        var _this = this;
        this.projectService.GetProject(this.id).subscribe(function (response) {
            _this.projectData = response.data;
        });
    };
    EcosystemmanagementComponent.prototype.goToDashboard = function () {
        this.router.navigate(['dashboard']);
    };
    EcosystemmanagementComponent.prototype.scrollToTop = function () {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    };
    EcosystemmanagementComponent.prototype.ngOnDestroy = function () {
        this.showSpinner = false;
        clearInterval(this.getSnapshotInterval);
    };
    EcosystemmanagementComponent.prototype.goToEditProject = function () {
        if (navigator.onLine) {
            var url = "setup/" + this.project.id;
            this.router.navigate([url]);
        }
        else {
            this.toastrService.warning('You cannot edit a project while offline');
        }
    };
    EcosystemmanagementComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    EcosystemmanagementComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        if (sortBy === 'suppliername') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.ecosystems.sort(function (a, b) {
                if (a.suppliername.toLowerCase() < b.suppliername.toLowerCase()) {
                    return 1;
                }
                if (a.suppliername.toLowerCase() > b.suppliername.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'productname') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.ecosystems.sort(function (a, b) {
                if (a.productname.toLowerCase() < b.productname.toLowerCase()) {
                    return 1;
                }
                if (a.productname.toLowerCase() > b.productname.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'xcoordinate') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.ecosystems.sort(function (a, b) {
                if (a.xcoordinate < b.xcoordinate) {
                    return 1;
                }
                if (a.xcoordinate > b.xcoordinate) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'ycoordinate') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.ecosystems.sort(function (a, b) {
                if (a.ycoordinate < b.ycoordinate) {
                    return 1;
                }
                if (a.ycoordinate > b.ycoordinate) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'marker_description') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.ecosystems.sort(function (a, b) {
                if (a.marker_description.toLowerCase() < b.marker_description.toLowerCase()) {
                    return 1;
                }
                if (a.marker_description.toLowerCase() > b.marker_description.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'status') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.ecosystems.sort(function (a, b) {
                if (a.status.toLowerCase() < b.status.toLowerCase()) {
                    return 1;
                }
                if (a.status.toLowerCase() > b.status.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    EcosystemmanagementComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        if (sortBy === 'suppliername') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.ecosystems.sort(function (a, b) {
                if (a.suppliername.toLowerCase() > b.suppliername.toLowerCase()) {
                    return 1;
                }
                if (a.suppliername.toLowerCase() < b.suppliername.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'productname') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.ecosystems.sort(function (a, b) {
                if (a.productname.toLowerCase() > b.productname.toLowerCase()) {
                    return 1;
                }
                if (a.productname.toLowerCase() < b.productname.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'xcoordinate') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.ecosystems.sort(function (a, b) {
                if (a.xcoordinate > b.xcoordinate) {
                    return 1;
                }
                if (a.xcoordinate < b.xcoordinate) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'ycoordinate') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.ecosystems.sort(function (a, b) {
                if (a.ycoordinate > b.ycoordinate) {
                    return 1;
                }
                if (a.ycoordinate < b.ycoordinate) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'marker_description') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.ecosystems.sort(function (a, b) {
                if (a.marker_description.toLowerCase() > b.marker_description.toLowerCase()) {
                    return 1;
                }
                if (a.marker_description.toLowerCase() < b.marker_description.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'status') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.ecosystems.sort(function (a, b) {
                if (a.status.toLowerCase() > b.status.toLowerCase()) {
                    return 1;
                }
                if (a.status.toLowerCase() < b.status.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    EcosystemmanagementComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['suppliername', 'productname', 'xcoordinate', 'ycoordinate', 'marker_description', 'status'];
        var finalObj = {};
        if (this.ecosystems === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    EcosystemmanagementComponent.prototype.sortByItem = function (item) {
        if (item.isAscending) {
            this.asc(item);
        }
        else {
            this.desc(item);
        }
        this.ecosystems.forEach(function (element) {
            element.isAscending = true;
        });
        item.isAscending = !item.isAscending;
    };
    EcosystemmanagementComponent.prototype.asc = function (item) {
        this.ecosystems.sort(function (a, b) {
            var finala = '';
            var finalb = '';
            if (item.field_type === 'dropdown' && item.api_url !== null) {
                finala = a[item.ref_modal_name]['name'];
                finalb = b[item.ref_modal_name]['name'];
            }
            else {
                finala = a[item.mapping_table_field];
                finalb = b[item.mapping_table_field];
            }
            if (finala === null) {
                finala = '';
            }
            if (finalb === null) {
                finalb = '';
            }
            if (finala.toLowerCase() > finalb.toLowerCase()) {
                return 1;
            }
            if (finala.toLowerCase() < finalb.toLowerCase()) {
                return -1;
            }
            return 0;
        });
    };
    EcosystemmanagementComponent.prototype.desc = function (item) {
        this.ecosystems.sort(function (a, b) {
            var finala = '';
            var finalb = '';
            if (item.field_type === 'dropdown' && item.api_url !== null) {
                finala = a[item.ref_modal_name]['name'];
                finalb = b[item.ref_modal_name]['name'];
            }
            else {
                finala = a[item.mapping_table_field];
                finalb = b[item.mapping_table_field];
            }
            if (finala === null) {
                finala = '';
            }
            if (finalb === null) {
                finalb = '';
            }
            if (finala.toLowerCase() < finalb.toLowerCase()) {
                return 1;
            }
            if (finala.toLowerCase() > finalb.toLowerCase()) {
                return -1;
            }
            return 0;
        });
    };
    EcosystemmanagementComponent.prototype.addUserToEcosystemSubmit = function (form) {
        var newObject = {
            ecosystem_id: this.addingUserToEcosystemId,
            data: form.value,
        };
    };
    EcosystemmanagementComponent.prototype.addEcoUserModal = function (template, currentItem, importUserModalBool) {
        this.addingUserToEcosystemId = currentItem;
        if (importUserModalBool) {
            this.importUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        }
        else {
            this.confirmModal = true;
            this.addUserModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
            this.addForm = true;
        }
    };
    EcosystemmanagementComponent.prototype.confirmModalYes = function (valItem) {
        if (valItem === 'add') {
            if (this.addUserModal) {
                this.addUserModal.hide();
            }
            this.ecoSystemUserForm.reset();
            this.f1Submitted = false;
        }
    };
    EcosystemmanagementComponent.prototype.confirmModalNo = function (valItem) {
        if (valItem === 'add') {
            this.confirmModal = true;
        }
    };
    EcosystemmanagementComponent.prototype.getAddUserData = function () {
        var _this = this;
        this.suppService.getMaster('USERS_MDM').subscribe(function (response) {
            _this.ecoSystemData = response;
            var validationsArray;
            var _loop_1 = function (i) {
                _this.ecoSystemData.data[i].validations = {};
                var validatorArr = [];
                if (_this.ecoSystemData.data[i].mandatory === 'Y') {
                    validatorArr.push(Validators.required);
                }
                if (_this.ecoSystemData.data[i].MDMValidation != null) {
                    validationsArray = JSON.parse(_this.ecoSystemData.data[i].MDMValidation.validation_obj);
                    for (var j = 0; j < validationsArray.length; j++) {
                        if (validationsArray[j].type === 'min') {
                            _this.ecoSystemData.data[i].validations['minlength'] = validationsArray[j].value;
                            validatorArr.push(Validators.minLength(validationsArray[j].value));
                        }
                        if (validationsArray[j].type === 'max') {
                            _this.ecoSystemData.data[i].validations['maxlength'] = validationsArray[j].value;
                            validatorArr.push(Validators.maxLength(validationsArray[j].value));
                        }
                        if (validationsArray[j].type === 'email') {
                            validatorArr.push(Validators.email);
                        }
                        if (validationsArray[j].type === 'number') {
                            validatorArr.push(Validators.pattern('^[0-9]{5,13}$'));
                        }
                    }
                }
                if (_this.ecoSystemData.data[i].field_type === 'dropdown') {
                    if (_this.ecoSystemData.data[i].value_options === null) {
                        _this.countryData[_this.ecoSystemData.data[i].id] = {};
                        _this.suppService.getAPIURLMethod(_this.ecoSystemData.data[i].api_url).subscribe(function (response) {
                            _this.countryData[_this.ecoSystemData.data[i].id] = response;
                        });
                        _this.ecoSystemUserForm.addControl(_this.ecoSystemData.data[i].id, new FormControl({
                            disabled: _this.ecoSystemData.data[i].readonly === 'Y' ? true : false
                        }, validatorArr));
                    }
                    else {
                        _this.valueOptions = JSON.parse(_this.ecoSystemData.data[i].value_options);
                        _this.ecoSystemData.data[i]['valueOptions'] = _this.valueOptions;
                        _this.ecoSystemUserForm.addControl(_this.ecoSystemData.data[i].id, new FormControl({
                            value: _this.valueOptions[0].id,
                            disabled: _this.ecoSystemData.data[i].readonly === 'Y' ? true : false
                        }, validatorArr));
                    }
                }
                else if (_this.ecoSystemData.data[i].field_type === 'autoselect') {
                    if (_this.ecoSystemData.data[i].value_options === null) {
                        _this.autoSelectData[_this.ecoSystemData.data[i].id] = {};
                        _this.suppService.getAPIURLMethod(_this.ecoSystemData.data[i].api_url).subscribe(function (response) {
                            _this.autoSelectData[_this.ecoSystemData.data[i].id] = response;
                        });
                        _this.ecoSystemUserForm.addControl(_this.ecoSystemData.data[i].id, new FormControl({
                            disabled: _this.ecoSystemData.data[i].readonly === 'Y' ? true : false
                        }, validatorArr));
                    }
                }
                else if (_this.ecoSystemData.data[i].field_type === 'multiselect') {
                    if (_this.ecoSystemData.data[i].value_options === null) {
                        _this.multiSelectData[_this.ecoSystemData.data[i].id] = {};
                        _this.suppService.getAPIURLMethod(_this.ecoSystemData.data[i].api_url).subscribe(function (response) {
                            _this.multiSelectData[_this.ecoSystemData.data[i].id] = response;
                        });
                        _this.ecoSystemUserForm.addControl(_this.ecoSystemData.data[i].id, new FormControl({
                            disabled: _this.ecoSystemData.data[i].readonly === 'Y' ? true : false
                        }, validatorArr));
                    }
                }
                else if (_this.ecoSystemData.data[i].field_type === 'date') {
                    _this.ecoSystemUserForm.addControl(_this.ecoSystemData.data[i].id, new FormControl({
                        value: null,
                        disabled: _this.ecoSystemData.data[i].readonly === 'Y' ? true : false
                    }, validatorArr));
                    _this.dateFormat = 'DD-MMM-YYYY';
                }
                else {
                    _this.ecoSystemUserForm.addControl(_this.ecoSystemData.data[i].id, new FormControl({
                        value: null,
                        disabled: _this.ecoSystemData.data[i].readonly === 'Y' ? true : false
                    }, validatorArr));
                }
            };
            for (var i = 0; i < _this.ecoSystemData.data.length; i++) {
                _loop_1(i);
            }
        });
    };
    EcosystemmanagementComponent.prototype.setUserType = function (form_code, mapping_table_field) {
        if (form_code === 'USERPROGRAMMAPPING_MDM' && mapping_table_field === 'users_id') {
            var mid = this.ecoSystemData.data.find(function (sdata) { return sdata.mapping_table_field === mapping_table_field; });
            var midval_1 = this.ecoSystemUserForm.get(('' + mid.id)).value;
            var seldata = this.countryData[mid.id].data.find(function (sdata) { return sdata.id === +midval_1; });
            var fmid = this.ecoSystemData.data.find(function (sdata) { return sdata.mapping_table_field === 'user_type_id'; });
            fmid = '' + fmid.id;
            this.ecoSystemUserForm.controls[fmid].setValue(seldata.user_type_id);
        }
        if (form_code === 'UNITS_MDM' && mapping_table_field === 'unit_type_id') {
            var mid = this.ecoSystemData.data.find(function (sdata) { return sdata.mapping_table_field === mapping_table_field; });
            var midval_2 = this.ecoSystemUserForm.get(('' + mid.id)).value;
            var seldata = this.countryData[mid.id].data.find(function (sdata) { return sdata.id === +midval_2; });
            var fmid = this.ecoSystemData.data.find(function (sdata) { return sdata.mapping_table_field === 'international_unit'; });
            fmid = '' + fmid.id;
            this.ecoSystemUserForm.controls[fmid].setValue(seldata.international_unit);
        }
        if (form_code === 'ECOSYSTEM_USERS_MAPPING_MDM' && mapping_table_field === 'users_id') {
            var mid = this.ecoSystemData.data.find(function (sdata) { return sdata.mapping_table_field === mapping_table_field; });
            var midval_3 = this.ecoSystemUserForm.get(('' + mid.id)).value;
            var seldata = this.countryData[mid.id].data.find(function (sdata) { return sdata.id === +midval_3; });
            var utid = this.ecoSystemData.data.find(function (sdata) { return sdata.mapping_table_field === 'user_type_id'; });
            utid = '' + utid.id;
            var ucid = this.ecoSystemData.data.find(function (sdata) { return sdata.mapping_table_field === 'user_category_id'; });
            ucid = '' + ucid.id;
            var atid = this.ecoSystemData.data.find(function (sdata) { return sdata.mapping_table_field === 'access_type_id'; });
            atid = '' + atid.id;
            this.ecoSystemUserForm.controls[utid].setValue(seldata.user_type_id);
            this.ecoSystemUserForm.controls[ucid].setValue(seldata.user_category_id);
            var atdata = this.countryData[atid].data.find(function (sdata) { return sdata.name === 'Full'; });
            this.ecoSystemUserForm.controls[atid].setValue(atdata.id);
        }
    };
    EcosystemmanagementComponent.prototype.hideAddUserModal = function (form) {
        if (form.dirty) {
            this.confirmModal = false;
        }
        else {
            if (this.addUserModal) {
                this.addUserModal.hide();
                this.addForm = false;
            }
            this.ecoSystemUserForm.reset();
        }
        this.f1Submitted = false;
    };
    return EcosystemmanagementComponent;
}());
export { EcosystemmanagementComponent };
