import { Component, HostListener, OnInit, OnDestroy, TemplateRef, AfterViewInit, Injectable, LOCALE_ID, Inject} from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl, Validator, NgForm, ValidationErrors, FormArray } from '@angular/forms';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { takeUntil, map, mapTo } from 'rxjs/operators';
import { Subject, Observable, fromEvent, merge, of, Subscription } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { environment } from '../../../environments/environment';

import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { UserService } from '../../services/user.service';
import { Project } from '../../models/project';

import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataConsentService } from '../../services/data-consent.service';
import { ConsReportService } from '../../services/consolidated-reports.service';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';

import { HttpClient } from '@angular/common/http';

declare var idbApp: any;
declare var idbAppPhoto: any;

declare var $: any;

declare var serviceWorkerVar;

@Component({
  selector: 'app-profiling',
  templateUrl: './profiling.component.html',
  styleUrls: ['./profiling.component.css'],
  host: { '(window:partial-synced)': 'syncDone($event)' }
})
export class ProfilingComponent implements OnInit, AfterViewInit, OnDestroy {
  isOnline: Observable<boolean>;
  projectId = 0;
  project: any;
  farmers = [];
  productType = [];
  managers = [];
  productTypeName: string;
  managerName: string;

  isAscending = true;
  isAscendingId = true;
  isAscendingName = true;
  isAscendingTel = true;
  isAscendingDate = true;
  isAscendingFarmerNo = true; //Official farmer id added 
  sortLabel = '';

  public showSpinner = false;

  isFieldAgent = false;
  isManager = false;
  isDeeproots = false;

  farmerFilterString: any = { name: '' };

  page: number = 1;
  itemsPerPage: number = 10;
  searchText = '';

  public previousPaginationLabel;
  public nextPaginationLabel;
  public isInIDBInThisProject = [];
  public showSyncSuccessAlert = false;
  public closeSnapshotModal: BsModalRef;
  public modifySnapshotModal: BsModalRef;

  public allSnapshots;
  public snapshot;
  public olderSnapshots = [];
  public allSurveys;
  public snapshotNameTemp;
  public bsDateRange;
  public snapshotModifying;
  public oldSnapshotInfo;

  public minDate = new Date();
  public projectUndefined;

  public getSnapshotInterval;
  public noSnapshotsInProject = false;
  public fetchingSnapshotInfo = true;

  public disableCreateSnapshot = false;
  public disableCloseSnapshot = false;

  public registerFarmerModal;
  public registeringFarmerName;
  public registeringFarmerAge;
  public registeringFarmerSex;
  public registeringFarmerArea;
  public registeringFarmer = false;

  public quickNoteModal;
  public quickNoteData:any;
  
  public master;
  public sex;
  public registerFarmerForm: FormGroup;
  public registerAnotherFarmer = false;
  fileToUpload: File = null;
  photoModel = '';

  oldSnapshotId = 0;
  arrSnapshotRevisions = [];
  snapshotRevision = [];
  public id: any;
  public projectData: any;
  public UPM;
  usersServiceComponentMappings;
  ecosystemServicesComponentsMapping;
  canExportToExcel = false;
  canMakeRevision = false
  currentUser;

  selectedFarmers = [];
  allTabNames = [];
  selectedTabCode;
  public selectTabName;
  public addConsentPopup;
  consentFarmerName;
  consentFarmerNo;

  // tranlsate service
  thereNoActiveSnapshotForThisProject;
  revisionTxt;
  createSuccessTxt;
  surveyStats;
  tooltipText;
  tooltipText2;
  selectDeselectLabels;
  confirmTEDModal = true;
  confirmConsentModal = true;
  farmerProfiling;
  signValues;
  selectedConsentCode = true;

  //added by KRPT fp Farmer consent
  farmerConsent;
  farmerId;
  farmer;
  consentTemplate:SafeHtml;
  consentTemplateId;
  canRecordConsent;
  consentTemplateNotAvailable;
  consentAddedSuccssfully;
  entireSurvey;

  removingFarmer: any;
  removeFarmerModal: BsModalRef;
  farmerNA;
  qAnswers; // Array to contain all the answers in this questionnaire for this farmer
  currentSurveyId;
  farmerMarkedUnavailableSuccessfully;
  farmerMarkedAvailableSuccessfully;
  showSurveyManualIcon = false;
  noChangeRevisionTxt;
  filteredfarmers = [];
  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private readonly farmerService: FarmerService,
    private readonly projectService: ProjectService,
    private userService: UserService,
    private translate: TranslateService,
    public toastrService: ToastrService,
    public modalService: BsModalService,
    private localeService: BsLocaleService,
    private formBuilder: FormBuilder,
    private revisionService: RevisionService,
    private dynamicQuestionnaireService: DynamicQuestionnaireService,
    private readonly surveyService: SurveyService,
    private readonly consReportService: ConsReportService,
    private readonly spinner: Ng4LoadingSpinnerService,
    private readonly dataConsentService:DataConsentService,
    private sanitizer: DomSanitizer,
    @Inject(LOCALE_ID) private readonly locale: string, 
    private http: HttpClient) {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.isOnline = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.translate.get('previousPaginationLabel').subscribe(result => {
      this.previousPaginationLabel = result;
    });

    this.translate.get('nextPaginationLabel').subscribe(result => {
      this.nextPaginationLabel = result;
    });

    this.translate.get('there_no_active_snapshot_for_this_project').subscribe(result => {
      this.thereNoActiveSnapshotForThisProject = result;
    });

    this.translate.get('revision').subscribe(result => {
      this.revisionTxt = result;
    });
    this.translate.get('created_successfully').subscribe(result => {
      this.createSuccessTxt = result;
    });
    this.translate.get('select_deselect_object').subscribe(result => {
      this.selectDeselectLabels = result;
    });
    this.translate.get('data_will_be_synced').subscribe(result => {
      this.tooltipText2 = result;
    });
    this.translate.get('farmer_profiling').subscribe(result => {
      this.farmerProfiling = result;
    });
    this.translate.get('consent_template_not_available').subscribe(result => {
      this.consentTemplateNotAvailable = result;
    });
    this.translate.get('consent_added_succssfully').subscribe(result => {
      this.consentAddedSuccssfully = result;
    });
    this.translate.get('entire_survey').subscribe(result => {
      this.entireSurvey = result;
    });
    this.translate.get('marked_unavailable').subscribe(result => {
      this.farmerMarkedUnavailableSuccessfully = result;
    });
    this.translate.get('marked_available').subscribe(result => {
      this.farmerMarkedAvailableSuccessfully = result;
    });
    this.localeService.use(this.locale);
    this.translate.get('no_revision_txt').subscribe(result => {
      this.noChangeRevisionTxt = result;
    });
  }
  snapshotChanged() {
    this.snapshotRevision = this.arrSnapshotRevisions.filter(x => x.snapshot_id_ref === this.oldSnapshotId);
  }

  async ngOnInit() {
    this.scrollToTop();
    this.projectId = +this.route.snapshot.params['id'];
    const code = this.commonService.checkRole();
    this.currentUser = this.userService.getCurrentUser();

    if (code == 'FAG') { this.isFieldAgent = true; }
    else if (code === 'PMG') this.isManager = true;
    else if (code === 'SYS1') { this.isDeeproots = true; }

    if (navigator.onLine) {
      this.project = this.route.snapshot.data.project.project;
      // console.log('this.project', this.project);
      if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
        this.UPM = this.project.ProjectMapping.find(item => item.users_id == this.currentUser.id);
        // this.UPM.access_type_id = 3;
      }

      if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
        this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
      }

      if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
        this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
      }

      // console.log('this.project', this.project);

      if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
        let canExportToExcel = this.usersServiceComponentMappings.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 4 && item.user_type_id == this.currentUser.role.id));
        if (canExportToExcel && canExportToExcel.length > 0) {
          this.canExportToExcel = true;
        }
      // console.log('canExportToExcel farmer profiling component', this.canExportToExcel);

        let canMakeRevision = this.usersServiceComponentMappings.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 5 && item.user_type_id == this.currentUser.role.id));
        if (canMakeRevision && canMakeRevision.length > 0) {
          this.canMakeRevision = true;
        }

        let canRecordConsent = this.usersServiceComponentMappings.filter(item => (
          item.services_components_mapping && item.services_components_mapping.code === "FR_CON_REC" && item.user_type_id == this.currentUser.role.id)
        );
        if (canRecordConsent && canRecordConsent.length > 0) {
          this.canRecordConsent = true;
        }
      }
      else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
        let canExportToExcel = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 4));
        if (canExportToExcel && canExportToExcel.length > 0) {
          this.canExportToExcel = true;
        }
      // console.log('canExportToExcel farmer profiling component', this.canExportToExcel);

        let canMakeRevision = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 5));
        if (canMakeRevision && canMakeRevision.length > 0) {
          this.canMakeRevision = true;
        }

        let canRecordConsent = this.ecosystemServicesComponentsMapping.filter(item => (
          item.services_components_mapping && item.services_components_mapping.code === "FR_CON_REC")
        );
        if (canRecordConsent && canRecordConsent.length > 0) {
          this.canRecordConsent = true;
        }
      }
    //  console.log('canMakeRevision farmer profiling component', this.canMakeRevision);

      const requests = await Promise.all([
        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
        this.surveyService.getAllSurveysForProject(this.projectId).toPromise(),
        this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise(),
        this.farmerService.getFarmerAvailibility(this.projectId).toPromise()
      ]);
      this.farmers = requests[0].data;
      this.allSurveys = requests[1].message;
      // this.surveyStats = requests[2].message;
      // console.log('this.surveyStats', this.surveyStats);
      // Get any farmers saved in indexed db for this project
      this.getFarmerConsents();
      this.setOfflineData();
      this.setSurveyStats(requests[2].message);
      // this.master = this.route.snapshot.data.project.master.data;

      // this.allSnapshots = this.project.QuesProjectSnapshotMappings;
      this.setSurveys();
      if(requests[3].msg == "success"){
        this.farmerNA = requests[3].code;
      }
      this.setFarmerNA();
      // this.snapshot = this.route.snapshot.data.project.snapshot.data;
    } else {

      const requests = await Promise.all([
        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
        this.surveyService.getAllSurveysForProject(this.projectId).toPromise(),
        this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise(),
        this.farmerService.getFarmerAvailibility(this.projectId).toPromise()
      ]);
      // this.project = requests[0].data.find(x => x.id == this.projectId);
      this.project = this.route.snapshot.data.project.project;
      if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
        this.UPM = this.project.ProjectMapping.find(item => item.users_id == this.currentUser.id);
        // this.UPM.access_type_id = 3;
      }

      if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
        this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
      }
      if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
        this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
      }

      if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
        let canExportToExcel = this.usersServiceComponentMappings.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 4 && item.user_type_id == this.currentUser.role.id));
        if (canExportToExcel && canExportToExcel.length > 0) {
          this.canExportToExcel = true;
        }
      // console.log('canExportToExcel farmer profiling component', this.canExportToExcel);

        let canMakeRevision = this.usersServiceComponentMappings.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 5 && item.user_type_id == this.currentUser.role.id));
        if (canMakeRevision && canMakeRevision.length > 0) {
          this.canMakeRevision = true;
        }

        let canRecordConsent = this.usersServiceComponentMappings.filter(item => (item.services_components_mapping && item.services_components_mapping.code === "FR_CON_REC" && item.user_type_id == this.currentUser.role.id));
        if (canRecordConsent && canRecordConsent.length > 0) {
          this.canRecordConsent = true;
        }
      }
      else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
        let canExportToExcel = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 4));
        if (canExportToExcel && canExportToExcel.length > 0) {
          this.canExportToExcel = true;
        }
      // console.log('canExportToExcel farmer profiling component', this.canExportToExcel);

        let canMakeRevision = this.ecosystemServicesComponentsMapping.filter(item => (item.services_components_mapping && item.services_components_mapping.id == 5));
        if (canMakeRevision && canMakeRevision.length > 0) {
          this.canMakeRevision = true;
        }
        
        let canRecordConsent = this.ecosystemServicesComponentsMapping.filter(item => (
          item.services_components_mapping && item.services_components_mapping.code === "FR_CON_REC")
        );
        if (canRecordConsent && canRecordConsent.length > 0) {
          this.canRecordConsent = true;
        }
      }

      this.farmers = requests[0].data;
      this.allSurveys = requests[1].message;
      this.setFarmerRegData();
      // const snapshotData = requests[1].data;
      // let projectSnapshots = snapshotData.filter(x => x.project == this.projectId);

      // if (projectSnapshots && projectSnapshots.length > 0) {
      //   this.allSnapshots = projectSnapshots[0].snapshots;
      // } else {
      //   // console.log('projectSnapshots is null or length 0');
      //   this.allSnapshots = [];
      // }


     // debugger;;
      // Get any farmers saved in indexed db for this project
      this.getFarmerConsents();
      this.setOfflineData();
      this.setSurveyStats(requests[2].message);
      this.setSurveys();   
      if(requests[3].msg == "success"){
        this.farmerNA = requests[3].code;
      }
      this.setFarmerNA();   
    }
    //debugger;
    //  this.master = requests[2].data;

    /* const snapshotData = (await this.projectService.getAllSnapshots().toPromise()).data;
    // console.log('snapshotData', snapshotData);
    let projectSnapshots = snapshotData.filter(x => x.project == this.projectId);
    // console.log('projectSnapshots', projectSnapshots);
    if (projectSnapshots && projectSnapshots.length > 0) {
      this.allSnapshots = projectSnapshots[0].snapshots;
    } else {
      console.log('projectSnapshots is null or length 0');
      this.allSnapshots = [];
    } */
    /* this.setSnapshots(); */

  }

  async setOfflineData() {
    // Find the farmer data in indexed db corresponding to this project & append to the farmers array;
    var farmertmpobj = await idbApp.getAllProjects('farmerstmp');
    let idbAppFarmers = farmertmpobj.filter(x => x.project_id == this.projectId && x.savetab == 'Tab0');
    if (idbAppFarmers && idbAppFarmers.length > 0) {
      idbAppFarmers.sort(function (a, b) {
        let aDate = new Date(a.cr_date);
        let bDate = new Date(b.cr_date);
        return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
      })
      this.farmers = idbAppFarmers.concat(this.farmers);
    }

    // If any farmer has Tab00 data as well (1st tab updated data), then find that farmer from indexed db
    // .. and replace that farmer object in the this.farmers array
    let idbAppFarmersUpdatedOffline = farmertmpobj.filter(x => x.project_id == this.projectId && x.savetab == 'Tab00');
    if (idbAppFarmersUpdatedOffline && idbAppFarmersUpdatedOffline.length > 0) {
      for (let i = 0; i < idbAppFarmersUpdatedOffline.length; i++) {
        let farmerUpdated;
        if (idbAppFarmersUpdatedOffline[i].id > 0) {
          // console.log('id is greater than 0');
          farmerUpdated = this.farmers.filter(item => item.id == idbAppFarmersUpdatedOffline[i].id);
        }
        else {
          // console.log('id is equal to 0');
          farmerUpdated = this.farmers.filter(item => item.tmp_id == idbAppFarmersUpdatedOffline[i].tmp_id.slice(0, -1));
        }
        if (farmerUpdated && farmerUpdated.length > 0) {
          let index = this.farmers.indexOf(farmerUpdated[0]);
          if (index !=null && index > -1) {
            this.farmers[index] = idbAppFarmersUpdatedOffline[i];
          }
        }
      }
    }    
    // this.setFilledPercentageForOfflineProfiledFarmers();
    this.filteredfarmers = this.farmers;
    this.sortFarmers();
    this.setQuickNotesData();
    this.setOfflineSignData();
  }

  async setOfflineSignData(){    
      // added by KRPT for checking offline signature
      var farmersignobj = await idbAppPhoto.getAllProjects('farmerSignaturetmp');
      const faremerWithTemId = this.farmers.filter(x => x.tmp_id);
      for(let item of faremerWithTemId){
        const findSignature = farmersignobj.find(x => x.tmp_id == item.tmp_id);
        if(findSignature){
          item.consentPending = false;
          item.valid_upto = 730;
        }
      }
  }


  goToDashboard(): void {
    this.router.navigate(['dashboard']);
  }

  goToFarmer(farmer): void {
    let url;
    if (this.snapshot) {
      if (farmer.id && farmer.id > 0) {
        url = '/questionnaire/respond/' + farmer.project_id + '/' + farmer.id;
      } else if (farmer.tmp_id && !navigator.onLine) {
        if (farmer.tmp_id.indexOf('Tab00') > -1) {
          url = '/questionnaire/respond/' + farmer.project_id + '/' + farmer.tmp_id.substring(0, farmer.tmp_id.length - 1);
        } else {
          url = '/questionnaire/respond/' + farmer.project_id + '/' + farmer.tmp_id;
        }
      } else if (farmer.tmp_id && navigator.onLine) {
        this.commonService.showToast('warning', 'please_wait_data_sync', {preventDuplicates: true});
        return;
      } else {
        url = '/error';
      }
      this.router.navigate([url]);
    } else {
      this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
    }

  }

  goToViewFarmer(farmer): void {
    let url;
    // if (this.snapshot) {
      if (farmer.id && farmer.id > 0) {
        url = '/viewprofile/' + farmer.project_id + '/' + farmer.id;
      } else if (farmer.tmp_id) {
        url = '/viewprofile/' + farmer.project_id + '/' + farmer.tmp_id;
      } else {
        url = '/error';
      }
      
      this.router.navigate([url]);
    // } else {
    //   this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
    // }
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  ngAfterViewInit() {
    if (this.project && this.project.QuesProjectSnapshotMappings && this.project.QuesProjectSnapshotMappings.length > 0) {
      // If the active snapshot is in draft stage, fetch complete QuesProjectSnapshotMappings object from server

      // If the active snapshot is not in draft stage, fetch the complete QuesProjectSnapshotMappings object from the cache...
      // ... have to wait for the managerpage ngAfterViewInit function to finish getting the response for this to work
    }
  }


  ngOnDestroy() {
    this.showSpinner = false;
    clearInterval(this.getSnapshotInterval);
  }

  sortBy(sortOn: string) {

    // console.log(sortOn);

    this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);

  }

  ascendic(sortBy: string) {
    this.isAscending = false;

    if (sortBy === 'name') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.isAscendingFarmerNo = true;
      this.farmers.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.isAscendingFarmerNo = true;
      this.farmers.sort((a, b) => {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'tel') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.isAscendingFarmerNo = true;
      this.farmers.sort((a, b) => {
        if (a.tel_number < b.tel_number) {
          return 1;
        }
        if (a.tel_number > b.tel_number) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'date') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.isAscendingFarmerNo = true;
      this.farmers.sort((a, b) => {
        if (a.lastProfiledAt === b.lastProfiledAt) {
            return 0;
        }
    
        // nulls sort after anything else
        if (a.lastProfiledAt === null) {
            return 1;
        }
        if (b.lastProfiledAt === null) {
            return -1;
        }
        if (a.lastProfiledAt < b.lastProfiledAt) {
          return 1;
        }
        if (a.lastProfiledAt > b.lastProfiledAt) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'official_id') {
      this.isAscendingId = true;
      this.isAscendingName = true;
      this.isAscendingTel = true;
      this.isAscendingDate = true;
      this.isAscendingFarmerNo = false;
      this.farmers.sort((a, b) => {
        if (a.official_id === null) {
            return 1;
        }
        if (b.official_id === null) {
            return -1;
        }
        if(a && a.official_id != null && b && b.official_id != null){
          if (a.official_id < b.official_id) {
            return 1;
          }
          if (a.official_id > b.official_id) {
            return -1;
          }
          return 0;
        }
      });
    }

  }

  descendic(sortBy: string) {
    this.isAscending = true;
    if (sortBy === 'name') {
      this.isAscendingId = false;
      this.isAscendingName = true;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.isAscendingFarmerNo = false;
      this.farmers.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'id') {
      this.isAscendingId = true;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.isAscendingFarmerNo = false;
      this.farmers.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'tel') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = true;
      this.isAscendingDate = false;
      this.isAscendingFarmerNo = false;
      this.farmers.sort((a, b) => {
        if (a.tel_number > b.tel_number) {
          return 1;
        }
        if (a.tel_number < b.tel_number) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'date') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = true;
      this.isAscendingFarmerNo = false;
      this.farmers.sort((a, b) => {
        if (a.lastProfiledAt === b.lastProfiledAt) {
          return 0;
      }
  
      // nulls sort after anything else
      if (a.lastProfiledAt === null) {
          return -1;
      }
      if (b.lastProfiledAt === null) {
          return 1;
      }
        if (a.lastProfiledAt > b.lastProfiledAt) {
          return 1;
        }
        if (a.lastProfiledAt < b.lastProfiledAt) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy === 'official_id') {
      this.isAscendingId = false;
      this.isAscendingName = false;
      this.isAscendingTel = false;
      this.isAscendingDate = false;
      this.isAscendingFarmerNo = true;
      this.farmers.sort((a, b) => {        
        if (a.official_id === null) {
          return 1;
        }
        if (b.official_id === null) {
            return -1;
        }
        if(a && a.official_id != null && b && b.official_id != null){
          if (a.official_id > b.official_id) {
            return 1;
          }
          if (a.official_id < b.official_id) {
            return -1;
          }
          return 0;
        }
      });
    }
  }

  updateSyncStatusShown(args): void {
    // console.log('args', args);
    for (let i = 0; i < this.farmers.length; i++) {
      // if there is a non-zero farmer_id in the allData element, it is an update object
      this.farmers[i]['isInIDB'] = false;
    }
    let numberOfUnsyncedFarmers = this.isInIDBInThisProject.length;
    this.isInIDBInThisProject = [];
    if (numberOfUnsyncedFarmers && numberOfUnsyncedFarmers > 0) {
      numberOfUnsyncedFarmers = 0;
      this.showSyncSuccessAlert = true;
      setTimeout(() => { this.showSyncSuccessAlert = false; }, 5000);
    }
  }

  getGridFilters() {
    const gridColums = ['farmer_code', 'name', 'official_id', 'lastProfiledAt'];
    const finalObj = {};
    if (this.farmers === undefined) {
      return {};
    } else {
      gridColums.forEach(element => {
        finalObj[element] = this.searchText;
      });
    }
    return finalObj;
  }

  getBackProjectFilter(projectNameVal, projectId) {
    let url = "/dashboard";
    this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
  }

  setSnapshots(): void {
    //debugger;
    let currentSnapshotArray = this.allSnapshots.filter(item => item.status === 'active');
    if (currentSnapshotArray && currentSnapshotArray.length > 0) {
      this.snapshot = currentSnapshotArray[0];
    } else {
      this.snapshot = undefined;
    }
  }

  setSurveys(): void {
    if (this.allSurveys && this.allSurveys.length > 0) {
      const snapshotSurveys = this.allSurveys.filter(item => item.survey_type == 'snapshot');
      if (snapshotSurveys && snapshotSurveys.length > 0) {
        this.snapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
      } else {
        this.snapshot = undefined;  
      }
    } else {
      this.snapshot = undefined;
    }

    if(this.snapshot){
      if(this.snapshot.flex4){
        this.showSurveyManualIcon = true;
      } 
    }
  }

  async syncDone(args) {
    if (navigator.onLine) {
      window.dispatchEvent(new Event('caching-now'));
      const requests = await Promise.all([
        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
        // this.dynamicQuestionnaireService.getQAnswersForProject(this.projectId, null).toPromise(),
        this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise(),
        this.farmerService.getFarmerAvailibility(this.projectId).toPromise()
      ]);
      this.farmers = requests[0].data;
      this.sortFarmers();
      this.setSurveyStats(requests[1].message);
      if(requests[2].msg == "success"){
        this.farmerNA = requests[2].code;
      }
      this.setFarmerNA();   

      await Promise.all([
        this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise()
      ])
      .then(result => {
        if (result[0]['code'] === "success") {
          for (let i = 0; i < result[0]['message'].length; i++) {
            this.loadFarmerDataIntoCache(this.projectId, result[0]['message'][i], result[0]['message'][i+1]);
          }
        }
      }).catch(e => {});

      this.getFarmerConsents();


      window.dispatchEvent(new Event('data-synced'));
    }
  }

  exportFarmers(template: TemplateRef<any>) {
    if (this.snapshot) {
      this.spinner.show();
      this.selectedTabCode = undefined;
      if (this.selectedFarmers.length) {
        if (this.projectId != null && this.projectId != 0) {
          this.surveyService.getProfilingTabKeys(this.projectId, {})
            .subscribe(result => {
              if (result.code == 'success') {
                this.allTabNames = result.message;
                this.selectTabName = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
                this.spinner.hide();
              } else {
                this.toastrService.info('Information: ' + result.msgText);
                this.spinner.hide();
              }
            });
        }
      } else {
        this.toastrService.info('Information: ' + "Select Farmers first");
      }
    } else {
      this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
    }

  }

  //download excel for selected tab and farmers
  async submitTabName(){
    this.closeTabNamePopup();
    this.spinner.show();
    let selTemp = this.farmers.filter(f => this.selectedFarmers.includes(f.id) && (f.percentageCompleted || f.is_discontinued));
    selTemp = selTemp.map((f)=>{
      return f.id
    });
    const exportExcelRequest = {
      "tabCode":this.selectedTabCode,
      "farmers":selTemp
    };
    if(this.selectedTabCode != 'entireSurvey') {
      const selectedTabName = this.allTabNames.find(item => item.code == this.selectedTabCode);      
      this.surveyService.exportFarmerProfilingData(this.projectId, exportExcelRequest)
      .subscribe(exportResult => {
        var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
        const blob = new Blob([exportResult], {type: contentType});
        let linkdownload = document.createElement('a');
        linkdownload.href = URL.createObjectURL(blob);
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const today1 = dd + '-' + mm + '-' + yyyy;
        linkdownload.download = 'P2F-Roots_' + this.farmerProfiling + '_' + selectedTabName.survey_q_asset_labels[0].label + '_' + today1 + '.xlsx';
        linkdownload.style.display = 'none';
        document.body.appendChild(linkdownload);
        linkdownload.click();
        linkdownload.remove();
        this.selectedFarmers = [];
        this.toastrService.success("Excel Downloaded");
        this.spinner.hide();
      });
    } else {
      // this code is with XLSX
      let chunk;
      let splittedArrayLength = 500; //1000
      let splittedArray = [];
      while (selTemp.length > 0) {
        chunk = selTemp.splice(0,splittedArrayLength);
        splittedArray.push(chunk);
      }
      var wb = XLSX.utils.book_new();
      for(let i = 0; i < splittedArray.length; i++) {
        const newRequest = {
          "tabCode":this.selectedTabCode,
          "farmers":splittedArray[i]
        };
        const requests = await Promise.all([
          this.consReportService.exportAllSurveyData(this.projectId, newRequest).toPromise()
        ])
        
        var wsBooks = XLSX.utils.json_to_sheet(requests[0].message);
        var wscols = [
          {wpx: 80}, // "pixels
          {wpx: 80},
          {wpx: 120},
          {wpx: 300},
          {wpx: 100},
          {wpx: 80},
          {wpx: 80},
          {wpx: 200},
          {wpx: 200},
          {wpx: 200}
        ];
        
        wsBooks['!cols'] = wscols;
        const sheetName = `Farmer Sheet ${i+1}`
        XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);  
      }
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      let linkdownload = document.createElement('a');
      linkdownload.href = URL.createObjectURL(blob);
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      const today1 = dd + '-' + mm + '-' + yyyy;
      linkdownload.download = 'P2F-Roots_' + this.farmerProfiling + '_' + this.entireSurvey + '_Project_' + this.projectId +'_'  + today1 + '.xlsx';
      linkdownload.style.display = 'none';
      document.body.appendChild(linkdownload);
      linkdownload.click();
      linkdownload.remove();
      this.selectedFarmers = [];
      this.toastrService.success("Excel Downloaded");
      this.spinner.hide();
      // for(const type of this.allTabNames){
      //   const exportExcelRequest1 = {
      //     "tabCode":type.code,
      //     "farmers":selTemp
      //   };
      //   //const selectedTabName = this.allTabNames.find(item => item.code == this.selectedTabCode);      
      //   this.surveyService.exportConsolidatedSurveyData(this.projectId, exportExcelRequest1)
      //   .subscribe(exportResult => {
      //     var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
      //     const blob = new Blob([exportResult], {type: contentType});
      //     let linkdownload = document.createElement('a');
      //     linkdownload.href = URL.createObjectURL(blob);
      //     const today = new Date();
      //     const dd = String(today.getDate()).padStart(2, '0');
      //     const mm = String(today.getMonth() + 1).padStart(2, '0');
      //     const yyyy = today.getFullYear();
      //     const today1 = dd + '-' + mm + '-' + yyyy;
      //     linkdownload.download = 'P2F-Roots_' + this.farmerProfiling + '_' + type.survey_q_asset_labels[0].label + '_' + today1 + '.xlsx';
      //     linkdownload.style.display = 'none';
      //     document.body.appendChild(linkdownload);
      //     linkdownload.click();
      //     linkdownload.remove();
      //     this.selectedFarmers = [];
      //     this.toastrService.success("Excel Downloaded");
      //     this.spinner.hide();
      //   });
      // }
    }
  }
  
  // exportExcel(excelData) {

  //   //Title, Header & Data
  //   const today = new Date();
  //   const dd = String(today.getDate()).padStart(2, '0');
  //   const mm = String(today.getMonth() + 1).padStart(2, '0');
  //   const yyyy = today.getFullYear();
  //   const today1 = dd + '-' + mm + '-' + yyyy;
  //   const title = 'P2F-Roots_' + this.farmerProfiling + '_Entire_Survey_' + today1 + '.xlsx';
  //   const header = Object.keys(excelData[0])
  //   const data = excelData;
  //   console.log('data', data)

  //   //Create a workbook with a worksheet
  //   let workbook: ExcelProper.Workbook = new Excel.Workbook();
  //   let worksheet = workbook.addWorksheet('Farmer Data');

  //   worksheet.columns = [
  //     { header: 'Question Id', key: 'Question Id', width : 16 },
  //     { header: 'Tab Name', key: 'Tab Name', width : 16 },
  //     { header: 'Section', key: 'Section', width : 24 },
  //     { header: 'Question Label', key: 'Question Label', width : 60 },
  //     { header: 'Farmer Name', key: 'Farmer Name', width : 20 },
  //     { header: 'Farmer Code', key: 'Farmer Code', width : 16 },
  //     { header: 'Official Id', key: 'Official Id', width : 16 },
  //     { header: 'Survey 1 (14-Dec-2021)', key: 'Survey 1 (14-Dec-2021)', width : 40 },
  //     { header: 'Survey 2 (2-Nov-2021 to 14-Dec-2021)', key: 'Survey 2 (2-Nov-2021 to 14-Dec-2021)', width : 40 },
  //     { header: 'Survey 3 (12-Jun-2021 to 2-Nov-2021)', key: 'Survey 3 (12-Jun-2021 to 2-Nov-2021)', width : 40 }
  //   ];
  //   //Blank Row 
  //   for (const row of data){
  //     worksheet.addRow(row);
  //   }
  //   worksheet.getRow(1).font = { bold: true, color: {argb: "ffffff"} };
  //   worksheet.getRow(1).fill = {
  //     type: 'pattern',
  //     pattern: 'solid',
  //     fgColor: { argb: '0000FF' },
  //   };
  //   worksheet.views = [
  //     { state: 'frozen', xSplit: 0, ySplit: 1, activeCell: 'B2' }
  //   ];  

  //   //Generate & Save Excel File
  //   workbook.xlsx.writeBuffer().then((data) => {
  //     let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     fs.saveAs(blob, title + '.xlsx');
  //   })

  // }
  // Toggle a particular farmer's selection status
  toggleThisFarmerSelect(id): void {
    if (this.selectedFarmers.indexOf(id) > -1) {
      this.selectedFarmers = this.selectedFarmers.filter(item => item != id);
    }
    else this.selectedFarmers.push(id);
    // console.log("single", this.selectedFarmers)
    this.setTooltipText();
  }

  // Select / deselect all farmers in the array
  toggleAllFarmers(): void {
    // If all farmers are already selected, then deselect all farmers
    if (this.selectedFarmers.length == this.farmers.length) {
      this.selectedFarmers = [];
    }
    // If all farmers are NOT already selected, select all the farmers
    else {
      this.selectedFarmers = [];
      for (let i = 0; i < this.farmers.length; i++) {
        this.selectedFarmers.push(this.farmers[i].id);
      }
    }
    this.setTooltipText();
  }

  // Set the profiling % for farmers that were profiled in offline mode
  async setFilledPercentageForOfflineProfiledFarmers() {
    // Get everything in indexeddb
    var farmertmpobj = await idbApp.getAllProjects('farmerstmp');
    // Get all the entries for this project, except for the registration entries
    let idbAppFarmersUpdatedOffline = farmertmpobj.filter(x => x.project_id == this.projectId && x.savetab != 'Tab0' && x.savetab != 'Tab00'); 
    if (idbAppFarmersUpdatedOffline && idbAppFarmersUpdatedOffline.length > 0) {
      // For each of these entries, find the entry updated in this.farmers & update the value of filled_questions & total _questions
      // As looping through idb in sequence, the last entry for any particular farmer will supply the final value displayed on screen
      for (let i = 0; i < idbAppFarmersUpdatedOffline.length; i++) {
        let farmerUpdated;
        if (idbAppFarmersUpdatedOffline[i].id && idbAppFarmersUpdatedOffline[i].id > 0) {
          farmerUpdated = this.farmers.filter(item => item.id == idbAppFarmersUpdatedOffline[i].id);
        }
        else {
          let tempId = idbAppFarmersUpdatedOffline[i].tmp_id.slice(0, -1);
          tempId = tempId + '0'; let tempId00 = tempId + '0';
          farmerUpdated = this.farmers.filter(item => item.tmp_id == tempId || item.tmp_id == tempId00);
        }
        if (farmerUpdated && farmerUpdated.length > 0) {
          let index = this.farmers.indexOf(farmerUpdated[0]);
          if (index !=null && index > -1) {
            this.farmers[index]['filled_questions'] = idbAppFarmersUpdatedOffline[i]['filled_questions'];
            this.farmers[index]['total_questions'] = idbAppFarmersUpdatedOffline[i]['total_questions'];
          }
        }
      }
    }    
  }
  setFarmerRegData(): void {
    if (this.farmers && this.farmers.length > 0) {
      for (let i = 0; i < this.farmers.length; i++) {
        if (this.farmers[i].farmer_reg) {
          this.farmers[i] = {...this.farmers[i], ...this.farmers[i]['farmer_reg']};
        }
      }
    }
  }

  sortFarmers(): void {
    this.farmers.sort((a, b) => {
      if (a.cr_date < b.cr_date) {
        return 1;
      }
      if (a.cr_date > b.cr_date) {
        return -1;
      }
      return 0;
    });
  }
  profileCompletionCalculation(){
    
  }

  quickNoteView(currentItem, template: TemplateRef<any>){
    this.quickNoteData = currentItem;
    //console.log("quickNoteData ",this.quickNoteData[0].notes);
    this.quickNoteModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  }
  closePrompt():void{
    if (this.quickNoteModal) {
      this.quickNoteModal.hide();
    }
  }
  closeTabNamePopup(fromUI?):void{
    if(fromUI){      
      if(this.selectedTabCode){
        this.confirmTEDModal = false;
      } else if(this.selectTabName) {
        this.selectTabName.hide();
        this.confirmTEDModal = true;
      }
    } else if(this.selectTabName) {
      this.confirmTEDModal = true;
      this.selectTabName.hide();
    }
  }
  confirmModalFn(decision){
    if(decision == "Yes"){
      this.selectTabName.hide();
      this.confirmTEDModal = true;
    } else {
      this.confirmTEDModal = true;
    }
  }

  //added by Kiran for escape button click event
  @HostListener('window:keyup', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if (this.quickNoteModal) {
        this.quickNoteModal.hide();
      }
      this.closeTabNamePopup(true)
      this.closeConsentPopup()
    }
  }

  makeRevisionDynamicQuestionnaire(): void {
    if (this.snapshot) {
      this.dynamicQuestionnaireService.makeRevision(this.snapshot.id)
      .subscribe(result => {
        console.log('result', result);
        if (result.code == 'success') {
          if (result.message == "no_change") {
            this.toastrService.warning(this.noChangeRevisionTxt);
          } else {
            this.toastrService.success(`${this.revisionTxt} ${result.message.revision_number} ${this.createSuccessTxt}`);
          }
        }
      })
    }
  }

  async setSurveyStats(stats) {
    if (stats != 'survey_not_active') {
      let surveyStatsOnline = stats;
      this.surveyStats = stats;
      let surveyStatsFromIDB = await idbApp.getAllProjects('dq_profiling');
      for (let i = 0; i < this.farmers.length; i++) {
        if (this.farmers[i].id && this.farmers[i].id != 0) {
          let statsForFarmer = surveyStatsOnline.find(item => item.farmer_id == this.farmers[i].id);
          if (!statsForFarmer) {
            this.farmers[i]['percentageCompleted'] = 0;
            this.farmers[i]['lastProfiledAt'] = null;
          } else {
            this.farmers[i]['percentageCompleted'] = Math.round(parseInt(statsForFarmer.answered_questions) / parseInt(statsForFarmer.total_questions)*100);
            this.farmers[i]['lastProfiledAt'] = statsForFarmer.upd_date ? statsForFarmer.upd_date : statsForFarmer.cr_date;
          }
          const statsForFarmerFromIDB = surveyStatsFromIDB.find(item => item.tmp_id == this.farmers[i].id && !item.campaignId);
          if (statsForFarmerFromIDB) {
            const x = JSON.parse(statsForFarmerFromIDB.stats);
            if (x) {
              this.farmers[i]['percentageCompleted'] = Math.round(parseInt(x[2]) / parseInt(x[1])*100);
              this.farmers[i]['lastProfiledAt'] = new Date(statsForFarmerFromIDB.updatedAt);
            }
          }
        } else {
          let tmp_id = this.farmers[i].tmp_id;
          if (tmp_id.indexOf('_Tab00') > -1) {tmp_id = tmp_id.substring(0, tmp_id.length - 1);}
          const statsForFarmerFromIDB = surveyStatsFromIDB.find(item => item.tmp_id == tmp_id && !item.campaignId);
          if (statsForFarmerFromIDB) {
            const x = JSON.parse(statsForFarmerFromIDB.stats);
            if (x) {
              this.farmers[i]['percentageCompleted'] = Math.round(parseInt(x[2]) / parseInt(x[1])*100);
              this.farmers[i]['lastProfiledAt'] = new Date(statsForFarmerFromIDB.updatedAt);
            }
          }
        }
      }
    } else {
      // TODO: Handle scenario where survey not active yet
    }
  }

  async setQuickNotesData() {
    if (this.farmers && this.farmers.length > 0) {
      for (let f of this.farmers) {
        if (!f['FarmerQuickNotes']) {
          f['FarmerQuickNotes'] = [];
        }
      }
      var farmertmpobj = await idbApp.getAllProjects('farmerstmp');
      const idbAppFarmersQuickNotesOffline = farmertmpobj.filter(x => x.project_id == this.projectId && x.savetab === 'QN');
      if (idbAppFarmersQuickNotesOffline && idbAppFarmersQuickNotesOffline.length > 0) {
        for (let i = 0; i < idbAppFarmersQuickNotesOffline.length; i++) {
          let farmerUpdated;
          if (idbAppFarmersQuickNotesOffline[i].farmer_id > 0) {
            farmerUpdated =  this.farmers.filter(item => item.id == idbAppFarmersQuickNotesOffline[i].farmer_id);
          }
          else {
            const p = idbAppFarmersQuickNotesOffline[i].tmp_id.slice(0, -2);
            farmerUpdated = this.farmers.filter(item => item.tmp_id === `${p}Tab0`);
          }
          if (farmerUpdated && farmerUpdated.length > 0) {
            const index = this.farmers.indexOf(farmerUpdated[0]);
            if (index != null && index > -1) {
              this.farmers[index]['FarmerQuickNotes'] = [idbAppFarmersQuickNotesOffline[i]];
            }
          }
        }
      }
    }
  }

  goToViewFarmerDQ(farmer): void {
    let url2;
    if (this.snapshot) {
      if (farmer.id && farmer.id > 0) {
        url2 = '/questionnaire/view/' + farmer.project_id + '/' + farmer.id;
      } else if (farmer.tmp_id && !navigator.onLine) {
        if (farmer.tmp_id.indexOf('Tab00') > -1) {
          url2 = '/questionnaire/view/' + farmer.project_id + '/' + farmer.tmp_id.substring(0, farmer.tmp_id.length - 1);
        } else {
          url2 = '/questionnaire/view/' + farmer.project_id + '/' + farmer.tmp_id;
        }
      } else if (farmer.tmp_id && navigator.onLine) {
        this.commonService.showToast('warning', 'please_wait_data_sync', {preventDuplicates: true});
        return;
      } else {
        url2 = '/error';
      }
      this.router.navigate([url2]);
    } else {
      this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
    }
  }

  async loadFarmerDataIntoCache(projectId, gte, lt) {
    if (projectId && projectId > 0) {  
      await Promise.all([
        this.dynamicQuestionnaireService.getQAnswersForProjectPaginated(projectId, gte, lt).toPromise()
      ])
      .then(result => {
        console.log('result', result);
      }).catch(e => {});
    }
  }

  setTooltipText(): void {
    if (this.farmers && this.selectedFarmers) {
      if (this.selectedFarmers.length == this.farmers.length) {
        // Deselect farmers.length farmers
        if (this.farmers.length == 1) {
          this.tooltipText = `${this.selectDeselectLabels.deselect} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.deselect} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      } else {
        // Select farmers.length farmers
        if (this.farmers.length == 1) {
          this.tooltipText = `${this.selectDeselectLabels.select} 1 ${this.selectDeselectLabels.farmer}`;  
        } else {
          this.tooltipText = `${this.selectDeselectLabels.select} ${this.farmers.length} ${this.selectDeselectLabels.farmers}`;
        }
      }
    }
  }

  //added by Kiran for famer consent
  async getFarmerConsents(){
    const requests = await Promise.all([
      this.dataConsentService.getFarmerConsents(this.projectId).toPromise(),
      this.dataConsentService.getConsentTemplate(this.projectId).toPromise()
    ]);
    if(requests[0].msg == 'success'){
      this.farmerConsent = requests[0].data;
    }
    if(requests[1].msg == 'success' && requests[1].data){
      this.consentTemplateId = requests[1].data.id;
      this.consentTemplate = this.sanitizer.bypassSecurityTrustHtml(requests[1].data.template_content);
    }
    for(const [key, value] of Object.entries(this.farmerConsent)){
      const findFarmer = this.farmers.find(item => item.id == key);
      if(findFarmer){
        findFarmer.consentPending = !value["is_consent_valid"];
        findFarmer.valid_upto = value["valid_upto"];
      }
    }
  }

  openConsentPopup(farmer, template){
    if(this.consentTemplate) {
      this.consentFarmerName = farmer.name;
      this.farmerId = farmer.id;
      this.consentFarmerNo = farmer.official_id;
      this.farmer = farmer;
      this.addConsentPopup = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    } else {
      this.commonService.showToast('warning', 'consent_template_not_available', {preventDuplicates: true})
    }
  }

  closeConsentPopup():void{
    if(!this.selectedConsentCode){
      this.confirmConsentModal = false;
    } else if(this.addConsentPopup) {
      this.addConsentPopup.hide();
      this.confirmConsentModal = true;
    }
  }
  confirmConsentModalFn(decision){
    if(decision == "Yes"){
      this.addConsentPopup.hide();
      this.confirmConsentModal = true;
    } else {
      this.confirmConsentModal = true;
      this.selectedConsentCode = true;
    }
  }

  getConsentData(data) {
    if(data.agree && data.value != ""){
      this.signValues = data;
      this.selectedConsentCode = false
    } else {
      this.selectedConsentCode = true;
    }
  }

  submitConsent(){
    if (navigator.onLine) {
      this.spinner.show();
      const data = {
        "consent_sign":this.signValues.value
      }
      this.dataConsentService.saveFarmerConsent(this.projectId, this.farmerId, data).subscribe(
        (result) => {
          this.selectedConsentCode = true;
          this.closeConsentPopup();
          this.commonService.showToast('success', 'consent_added_succssfully', {preventDuplicates: true});
          const findFarmer = this.farmers.find(item => item.id == this.farmerId);
          if(findFarmer){
            findFarmer.consentPending = false;
            findFarmer.valid_upto = 365;
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );          
    } else {
      // added for signature offline storage
      if(this.signValues.agree && this.signValues.value != ""){
        //add farmer in farmerstmp
        const finalObj = {};
        const farmerObj = this.farmer;
        Object.keys(farmerObj).forEach(function (key) {
          if (typeof farmerObj[key] === 'object' && farmerObj[key] != null) {
            if (farmerObj[key].length && farmerObj[key].length > 0) {
              finalObj[key] = farmerObj[key];
            }
          } else {
            finalObj[key] = farmerObj[key];
          }
        });
        const farmObj = new Array();
        finalObj['tmp_id'] = this.farmer.id > 0 ? `${this.farmer.farmer_code}_Tab00` : `${this.farmer.tmp_id}`;
        finalObj['savetab'] = this.farmer.id > 0 ? 'Tab00' : "Tab0";
        finalObj['randnum'] = this.farmer.id > 0 ? this.commonService.getRandomNum().toString().slice(0, 10) : this.farmer.randnum;
        finalObj['project_id'] = this.projectId;
        finalObj['upd_user_id'] = this.currentUser.id;
        finalObj['consentPending'] = false;
        finalObj['valid_upto'] = 730;
        farmObj[farmObj.length] = finalObj;
        idbApp.addStoreInfo(farmObj, 'farmerstmp');

        const farmSignatureObj = {};
        const err_msg = {
          "farmer_consent_template_id":this.consentTemplateId,
          "project_id":this.projectId,
          "user_id":this.currentUser.id
        }
        if(this.farmerId == 0) {
          farmSignatureObj['tmp_id']  = this.farmer.tmp_id;
          farmSignatureObj['randnum'] = this.farmer.randnum;
          farmSignatureObj['farmerImageData'] = this.signValues.value;
          farmSignatureObj['savetab'] = 'Tab0';
          farmSignatureObj['cr_date'] = Date.now();
          farmSignatureObj['farmer_id'] = 0;
          farmSignatureObj['photo_type'] = "signature";
        } else {
          farmSignatureObj['tmp_id']  = `${this.farmer.farmer_code}_Tab00`;
          farmSignatureObj['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
          farmSignatureObj['farmerImageData'] = this.signValues.value;
          farmSignatureObj['savetab'] = 'Tab00';
          farmSignatureObj['cr_date'] = Date.now();
          farmSignatureObj['farmer_id'] = this.farmerId;
          farmSignatureObj['photo_type'] = "signature";
        }
        farmSignatureObj['error_msg'] = err_msg;
        idbAppPhoto.addStoreInfo([farmSignatureObj], 'farmerSignaturetmp');
      }
      this.selectedConsentCode = true;
      this.closeConsentPopup();
      this.commonService.showToast('info', 'offline_sync_later', {}); 
      const findFarmer = this.farmers.find(item => item.id == this.farmerId);
      if(findFarmer){
        findFarmer.consentPending = false;
        findFarmer.valid_upto = 365;
      }
    }
  }
// remove farmer btn click function
removeFarmer(template: TemplateRef<any>, farmer: any): void {
  if (this.snapshot) {
    this.removingFarmer = farmer;
    this.removeFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  } else {
    this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
  }
}

async confirmModalDeleteFarmer(what: string) {
  if (what === 'yes') {    
    if (navigator.onLine) {
      this.confirmedFarmerNotAvailable(this.removingFarmer);
    } else {
      //check if farmer already available in indexdb
      const farmertmpobj = await idbApp.getAllProjects('dq_profiling');
      let farmerInIndexDB;
      if (farmertmpobj && farmertmpobj.length > 0) {
        for(const obj of farmertmpobj){
          let fid = this.removingFarmer.tmp_id ? this.removingFarmer.tmp_id : this.removingFarmer.id;
          if(obj.tmp_id == fid){
            farmerInIndexDB = true;
            const answerStats = obj.stats;
            let val = JSON.parse(obj.values);
            //val = val.slice(1, -1);
            if(val && val.length > 0){
              for(const i of val){
                i['flex2'] = 'NA'
              }
            }
            idbApp.removeDataFromStore('dq_profiling', obj.unique_id);
            const uniqueId = uuidv4();
            const x = {unique_id: uniqueId, tmp_id: fid, 
              updatedAt: new Date().getTime(), values: JSON.stringify(val), 
              stats: answerStats, campaignId: undefined};
            idbApp.addStoreInfo([x], 'dq_profiling');
            let findFarmer;
            if(this.removingFarmer.tmp_id){
              findFarmer = this.farmers.find(item => item.tmp_id == this.removingFarmer.tmp_id);
            } else {                
              findFarmer = this.farmers.find(item => item.id == this.removingFarmer.id);
            }
            if(findFarmer){
              findFarmer["notAvailable"] = true;
            }
            this.removingFarmer = undefined;
            if (this.removeFarmerModal) {
              this.removeFarmerModal.hide();
            }
            this.toastrService.success(this.farmerMarkedUnavailableSuccessfully);
          }
        }
      }  
      //if farmer not in indexdb
      if(!farmerInIndexDB){
        await this.addNewEntrytoNQIDB("NA");
      }
    }
  }
  else {
    this.removingFarmer = undefined;
    if (this.removeFarmerModal) {
      this.removeFarmerModal.hide();
    }
  }
}

async addNewEntrytoNQIDB(status) {
  const request = await Promise.all([
    this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise()
  ]);
  const request2 = await Promise.all([
    this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.removingFarmer.id).toPromise(),
  ]);
  let result; let request3; let qids;
  // If offline, get answers from getQAnswersForFarmer (for this.farmerId) - this is coming from request2
  result = {code: 'success'};
  let qAnswersForFarmer;
  if (request2 && request2[0] && request2[0]['message']) {
    // If request2[0] has a message attribute, getQAnswersForFarmer has been called for this farmer before
    qAnswersForFarmer = request2[0];
  } 
    const paginationLimitsArray = request[0].message;
    // console.log('paginationLimitsArray', paginationLimitsArray);
    const paginationLimits = this.getPaginationLimits(paginationLimitsArray, this.removingFarmer.id)
    // console.log('paginationLimits', paginationLimits);
     //added by KRPT for getting active survey ID
      const surveyReq = await Promise.all([
        this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
      ]);
      let activeSurveyId;
      if (surveyReq[0]['code'] === 'success' && surveyReq[0]['message'].length > 0) {
        let activeSnapshot;
        const snapshotSurveys = surveyReq[0]['message'].filter(item => item.survey_type == 'snapshot');
        if (snapshotSurveys && snapshotSurveys.length > 0) {
          activeSnapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
          
          if(activeSnapshot) {
            activeSurveyId = activeSnapshot.id;
            this.currentSurveyId = activeSnapshot.id;
          }
        }
      }
      if(!activeSurveyId){
        this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
        this.removingFarmer = undefined;
        if (this.removeFarmerModal) {
          this.removeFarmerModal.hide();
        }
        return;
      }
      const getQIds = await Promise.all([
        this.dynamicQuestionnaireService.getSurveyQAssetIdsDashboard(activeSurveyId).toPromise()
      ])
      if (getQIds[0]['code'] === 'success'){                      
        request3 = await Promise.all([
          this.dynamicQuestionnaireService.getQAnswersForProjectPaginatedDashboard(this.projectId, paginationLimits[0], paginationLimits[1], getQIds[0]['message']).toPromise()
        ]);
        qids = getQIds[0]['message'];
      }

    // If getQAnswersForFarmer has not been called for this farmer, get the answer values from getQAnswersForProject by filtering on farmerId
    result['message'] = request3[0]['msg'] !== 'blank' ? request3[0]['message'].filter(item => item.farmer_id === this.removingFarmer.id) : [];
  
  // If getQAnswersForFarmer has been called for this farmer before, compare the timestamp of the two API calls (farmer specific & project-wide data fetch)
  // Set the results array as the latest API data fetch
  if (qAnswersForFarmer ) {
        // getQAnswersForFarmer fetch      getQAnswersForProject fetch
    if(request3){
      if (qAnswersForFarmer['ts'] > request3[0]['ts']) {
        // Set as farmer-specific API fetch
        result['message'] = qAnswersForFarmer['message'];
      } else {
        // Set as project-wide fetch, filtered by farmerId
        result['message'] = request3[0]['message'].filter(item => item.farmer_id === this.removingFarmer.id);
      }
    } else {
      result['message'] = qAnswersForFarmer['message'];
    }
  }

  // Set the qAnswers array
  this.setQAnswersAndInitialize(result, qids, status);
}

/**
   * Set the qAnswers array using the result array - answers returned by the API, and the indexedDB entries
   * @param result = answers array
   */
 async setQAnswersAndInitialize(result, qids, status) {
  this.qAnswers = [];
  let indexedDBEntries = [];
  // Find this rawFarmerId's indexedDB entries if any
  const farmertmpobj = await idbApp.getAllProjects('dq_profiling');
  if (farmertmpobj && farmertmpobj.length > 0) {
    let qAnswersForThisFarmerId = [];
    qAnswersForThisFarmerId = farmertmpobj.filter((item)=>{
      return (String(item.tmp_id) === String(this.removingFarmer.id) && item.campaignId == undefined);
    });
    if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
      for (let i = 0; i < qAnswersForThisFarmerId.length; i++) {
        indexedDBEntries = indexedDBEntries.concat(JSON.parse(qAnswersForThisFarmerId[i].values));
      }
    }
  }
  // Cached values
  if (result.code === 'success' && result.message.length > 0) {
    for (let i = 0; i < result.message.length; i++) {
      this.qAnswers.push({
        id: result.message[i].id.toString(), 
        key: result.message[i].questionnaire_asset_id.toString(), 
        sak_of_modified: result.message[i].sak_of_modified, 
        secondary_asset_key: result.message[i].secondary_asset_key, 
        value: result.message[i].answer.toString(),
        touched: true,
        flex2: status
      });
    }
  }
  // IndexedDB values
  if (indexedDBEntries.length > 0) {
    for (let i = 0; i < indexedDBEntries.length; i++) {
      // If the indexedDB entry has an id, find the same id from qAnswers and update the value, mark as touched
      if (indexedDBEntries[i].id) {
        const x = this.qAnswers.find(item => item.id == indexedDBEntries[i].id);
        if (x) {
          const xIndex = this.qAnswers.indexOf(x);
          if (this.validIndex(xIndex)) {
            this.qAnswers[xIndex]['value'] = indexedDBEntries[i]['value'];
            this.qAnswers[xIndex]['touched'] = true;
            this.qAnswers[xIndex]['flex2'] = status;
            if (indexedDBEntries[i]['checked']) {this.qAnswers[xIndex]['checked'] = indexedDBEntries[i]['checked'];}
            if (indexedDBEntries[i]['unchecked']) {this.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['unchecked'];}
            if (indexedDBEntries[i]['deleted']) {this.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['deleted'];}
          }
        }
      } else {
        // Push into qAnswers
        const answerToInsert = {
          key: indexedDBEntries[i].key.toString(),
          sak_of_modified: indexedDBEntries[i].sak_of_modified,
          secondary_asset_key: indexedDBEntries[i].secondary_asset_key,
          value: indexedDBEntries[i].value.toString(),
          touched: true,
          checked: indexedDBEntries[i].checked,
          flex2: status
        };

        if (indexedDBEntries[i]['checked']) {
          answerToInsert['checked'] = indexedDBEntries[i]['checked'];
        }
        if (indexedDBEntries[i]['unchecked']) {
          answerToInsert['unchecked'] = indexedDBEntries[i]['unchecked'];
        }
        if (indexedDBEntries[i]['deleted']) {
          answerToInsert['unchecked'] = indexedDBEntries[i]['deleted'];
        }
        this.qAnswers.push(answerToInsert);
      }
    }
    //this.askUserToConfirmExit = false;
  }
  if (this.qAnswers.length == 0) {
    this.qAnswers.push({
      key: qids[0], 
      value: '',
      touched: true,
      flex2: status
    });
  }
  let qAnswersToSubmit = this.qAnswers.filter(item => item.touched);
  this.addEntyToIndexDB(qAnswersToSubmit, status);
}

addEntyToIndexDB(qAnswersToSubmit, status): void {
    const y1 = qAnswersToSubmit.filter(a => a.checked || a.unchecked);
    const y2 = qAnswersToSubmit.filter(a => (!a.secondary_asset_key && !a.checked && !a.unchecked && a.value != null && a.value != '' && !a.deleted) 
    || (a.secondary_asset_key && !a.checked && !a.unchecked && a.value != null && !a.deleted));
    const na = qAnswersToSubmit.filter(a => a.value == '' && (a.flex2 == 'NA' || a.flex2 == 'A'));
    let y11 = [];
    let y21 = [];
    if (y1 && y1.length > 0) y11 = y1;
    if (y2 && y2.length > 0) y21 = y2;
    let y = y11.concat(y21);
    y = y.concat(na);
    let statsForFarmer = this.surveyStats.find(item => item.farmer_id == this.removingFarmer.id);
    let stats;
    if (!statsForFarmer) {
      stats = [this.currentSurveyId, 0, 0];
    } else {
      stats = [this.currentSurveyId, parseInt(statsForFarmer.total_questions), parseInt(statsForFarmer.answered_questions)];
    }
    if (y && y.length > 0) {
      const uniqueId = uuidv4();
      const x = {unique_id: uniqueId, tmp_id: this.removingFarmer.tmp_id ? this.removingFarmer.tmp_id : this.removingFarmer.id, 
        updatedAt: new Date().getTime(), values: JSON.stringify(y), 
        stats: JSON.stringify(stats), campaignId: undefined};
      idbApp.addStoreInfo([x], 'dq_profiling');
    }
    let findFarmer;
    if(this.removingFarmer.tmp_id){
      findFarmer = this.farmers.find(item => item.tmp_id == this.removingFarmer.tmp_id);
    } else {
      findFarmer = this.farmers.find(item => item.id == this.removingFarmer.id);
    }
    if(findFarmer){
      findFarmer["notAvailable"] = status == "NA" ? true : false;
    }
    this.removingFarmer = undefined;
    if (this.removeFarmerModal) {
      this.removeFarmerModal.hide();
    }
    let toastMessage = status == "NA" ? this.farmerMarkedUnavailableSuccessfully : this.farmerMarkedAvailableSuccessfully
    //this.commonService.showToast('success', toastMessage, {});
    this.toastrService.success(toastMessage);
  }

/**
   * Checks whether the given index is valid (not null, > -1)
   * @param index 
   */
 validIndex(index): boolean {
  if (index != null && index > -1) {
    return true;
  } return false;
}


/**
 * Find which limits range the given farmerId falls in
 * E.g. if limits = [130, 150, 170] and farmerId = 165
 * limitsToReturn = [150, 170] would be returned
 * @param limits 
 * @param farmerId 
 */
 getPaginationLimits(limits, farmerId) {
  const limitsToReturn = [];

  for (let i = 0; i < limits.length; i++) {
    if (limits[i] <= farmerId && limits[i + 1] && limits[i + 1] > farmerId) {
      limitsToReturn[0] = limits[i];
      limitsToReturn[1] = limits[i+1];
      return limitsToReturn;
    } else if (!limits[i + 1]) {
      limitsToReturn[0] = limits[i];
      limitsToReturn[1] = undefined;
      return limitsToReturn;
    }
  }
}
// add farmer btn click function
addFarmer(template: TemplateRef<any>, farmer: any): void {
  if(this.snapshot){  
    this.removingFarmer = farmer;
    this.removeFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
  } else {
    this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
  }
}

async confirmModalAddFarmer(what: string) {
  if (what === 'yes') {
    if (navigator.onLine) {
      this.confirmedFarmerAvailable(this.removingFarmer);
    } else {
      //check if farmer already available in indexdb
      const farmertmpobj = await idbApp.getAllProjects('dq_profiling');
      let farmerInIndexDB;
      if (farmertmpobj && farmertmpobj.length > 0) {
        for(const obj of farmertmpobj){
          let fid = this.removingFarmer.tmp_id ? this.removingFarmer.tmp_id : this.removingFarmer.id;
          console.log('fid', fid);
          if(obj.tmp_id == fid){
            farmerInIndexDB = true;
            const answerStats = obj.stats;
            let val = JSON.parse(obj.values);
            if(val && val.length > 0){
              for(const i of val){
                i['flex2'] = 'A'
              }
              idbApp.removeDataFromStore('dq_profiling', obj.unique_id);
              const uniqueId = uuidv4();
              const x = {unique_id: uniqueId, tmp_id: fid, 
                updatedAt: new Date().getTime(), values: JSON.stringify(val), 
                stats: answerStats, campaignId: undefined};
              idbApp.addStoreInfo([x], 'dq_profiling');
              let findFarmer;
              if(this.removingFarmer.tmp_id){
                findFarmer = this.farmers.find(item => item.tmp_id == this.removingFarmer.tmp_id);
              } else {                
                findFarmer = this.farmers.find(item => item.id == this.removingFarmer.id);
              }
              if(findFarmer){
                findFarmer["notAvailable"] = false;
              }
              this.removingFarmer = undefined;
              if (this.removeFarmerModal) {
                this.removeFarmerModal.hide();
              }
              
              this.toastrService.success(this.farmerMarkedAvailableSuccessfully);
            }
          }
        }
      }  
      //if farmer not in indexdb    
      if(!farmerInIndexDB){  
        await this.addNewEntrytoNQIDB("A");        
      }
    }
  }
  else {
    this.removingFarmer = undefined;
    if (this.removeFarmerModal) {
      this.removeFarmerModal.hide();
    }
  }
}
confirmedFarmerNotAvailable(farmer){
  if (this.removeFarmerModal) {
    this.removeFarmerModal.hide();
  }
  this.spinner.show();
  this.farmerService.farmerAvailableNotAvailable(farmer.id, false, this.projectId)
    .subscribe(result => {
      if (result.msg === 'success') {
        this.spinner.hide();
        //this.commonService.showToast('success', 'farmerMarkedUnavailableSuccessfully', {});
        this.toastrService.success(this.farmerMarkedUnavailableSuccessfully);
        const findFarmer = this.farmers.find(item => item.id == this.removingFarmer.id);
        if(findFarmer){
          findFarmer["notAvailable"] = true;
        }
      }
      else {
        this.commonService.showToast('error', 'error_farmer_deleted', {});
      }
      this.removingFarmer = undefined;
    })
}

confirmedFarmerAvailable(farmer){
  if (this.removeFarmerModal) {
    this.removeFarmerModal.hide();
  }
  this.spinner.show();
  this.farmerService.farmerAvailableNotAvailable(farmer.id, true, this.projectId)
    .subscribe(result => {
      if (result.msg === 'success') {
        this.spinner.hide();
        this.toastrService.success(this.farmerMarkedAvailableSuccessfully);
        const findFarmer = this.farmers.find(item => item.id == this.removingFarmer.id);
        if(findFarmer){
          findFarmer["notAvailable"] = undefined;
        }
      }
      else {
        this.commonService.showToast('error', 'error_farmer_deleted', {});
      }
      this.removingFarmer = undefined;
    })
}

//for making farmer unavailable if 
async setFarmerNA(){
  for(const fid of this.farmerNA){
    const findFarmer = this.farmers.find(item => item.id == fid);
    if(findFarmer){
      findFarmer.notAvailable = true;
    }
  }
  if (!navigator.onLine) {
    const farmertmpobj = await idbApp.getAllProjects('dq_profiling');
    let farmerNAarr = [];
    if (farmertmpobj && farmertmpobj.length > 0) {
      for(const obj of farmertmpobj){
        let val = JSON.parse(obj.values);
        if(val && val.length > 0){
          for(const i of val){
            if(i.flex2 == 'NA' || i.flex2 == 'A'){
              farmerNAarr.push({
                farmer_id: obj.tmp_id,
                availability: i.flex2
              });
            }
          }
        }
      }
    }
    if(farmerNAarr.length > 0){
      for(const farmer of farmerNAarr){
        const findFarmer = this.farmers.find(item => item.id == farmer.farmer_id);
        if(findFarmer){
          findFarmer.notAvailable = farmer.availability == "NA" ? true : false;
        } else {
          const findTmpFarmer = this.farmers.find(item => item.tmp_id == farmer.farmer_id);
          if(findTmpFarmer){
            findTmpFarmer.notAvailable = farmer.availability == "NA" ? true : false;
          }
        }
      }
    }
  }
}

  openSurveyManual() {
    const docUrl = `${environment.apiBaseUrl}/${this.locale.substr(0, 2)}/assets/uploads/surveyManual/${this.snapshot.flex4}`;
    const docExtension = docUrl.split(/[#?]/)[0].split('.').pop().trim();
    this.http
      .get(docUrl, {
        responseType: 'blob',
      })
      .subscribe(response => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.style.display = 'none';
        link.download = `Survey_manual.${docExtension}`;
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
        link.remove();
      });
  }
  //filter farmers array based on search text
  filterFarmers(){ 
    this.farmers = this.filteredfarmers;
    if(this.searchText.length > 0){
      const filterArr = this.farmers.filter(item => {
        if((item.farmer_code && item.farmer_code.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
        (item.name && item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
        (item.lastProfiledAt && item.lastProfiledAt.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) ||
        (item.official_id && item.official_id.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)){
          return item; 
        }
      })
      this.farmers = filterArr;
    }
  }
}


