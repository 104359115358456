import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelper } from 'angular2-jwt';
import { StorageService } from '../services/storage.service';

declare var idbApp;

@Injectable()
export class AuthenticationService {

    constructor (private readonly storageService: StorageService) {}
    tokenInfo;


    setTokenInfo (data: object) {
        this.tokenInfo = data;
        if (this.tokenInfo) {
          this.storageService.setSessionTokenInfo(JSON.stringify(this.tokenInfo));
          this.storageService.setLocalTokenInfo(JSON.stringify(this.tokenInfo));
          this.storageService.setIDBCurrentUser(JSON.stringify(this.tokenInfo));
        }

        if (this.tokenInfo === null) {
          localStorage.removeItem('snapshotsFetchedAt');
          localStorage.removeItem('lg');
          localStorage.removeItem('user');
          localStorage.removeItem('currentUser');
          localStorage.removeItem('id_token');
          localStorage.removeItem('access_token');
        }
    }
    setRememberedTokenInfo(data: string) {
        this.tokenInfo = data;
    }
    getHeader (): any {
        let requestOption: any;
        if (this.tokenInfo) {
            return {
               headers : new HttpHeaders({
               authorization : 'Bearer ' + this.tokenInfo.accessToken,
               contentType : 'application/x-www-form-urlencoded;charset=utf-8'
            })
        };
    }
    }

    // Since this.tokenInfo is being set to null here the this.setTokenInfo(null) function does not actually set the token to null
    removeToken() {
      this.tokenInfo = null;
      this.setTokenInfo(null);
      idbApp.removeDataFromStore('currentUser', 'currentUser_key');
    }

    removeTokenHeader() {
      let requestOption: any;
      if (this.tokenInfo) {
        idbApp.removeDataFromStore('currentUser', 'currentUser_key');
        return {
          headers: new HttpHeaders({
            authorization: null,
            contentType: 'application/x-www-form-urlencoded;charset=utf-8'
          })
        };
      }
    }
    public isAuthenticated(): boolean {

      const jwtHelper: JwtHelper = new JwtHelper();
      const tokenObj = this.storageService.getLocalTokenInfo();

      if (tokenObj) {
        const token = JSON.parse(tokenObj).token;
          // Check whether the token is expired and return
      // true or false
      return !jwtHelper.isTokenExpired(token);
      }
      return false;

    }
    public isTwoFA(): boolean {

      const tokenObj = this.storageService.getLocalTokenInfo();
      if (tokenObj ) {
        const token = JSON.parse(tokenObj);
          // Check whether the 2fa done or not
      // true or false
      return token.twofa === 1;
      }
      return false;

    }
}
