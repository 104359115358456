import { Subject, Subscription, merge, fromEvent } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { Component,
  OnInit,
  ViewChild, ElementRef, Renderer2, Inject, TemplateRef, LOCALE_ID } from '@angular/core';
import { mapTo } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import {Project, ProjectUOM} from '../../models/project';

import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService} from '../../services/user.service';

import { ToastrService } from 'ngx-toastr';
import { SuppliersService } from '../../services/suppliers.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-ecosystem-settings',
  templateUrl: './edit-ecosystem-settings.component.html',
  styleUrls: ['./edit-ecosystem-settings.component.css']
})
export class EditEcosystemSettingsComponent implements OnInit {

  @ViewChild ('Country') country;
  @ViewChild ('Supplier') supplier ;
  // @ViewChild('fileInput') fileInputVariable: any;
  @ViewChild('ecosystemForm') ecosystemForm;
  @ViewChild('productType') product_type;
  @ViewChild('f_project_manager') projectManager;

  private readonly subject = new Subject<boolean>();
  online$: Observable<boolean>;

  public isOpen = false;
  public dateFormat = 'DD-MMM-YYYY';
  public savingEcosystem = false

  projectId = 0;
  project = new Project();
  products = [];
  suppliers = [];
  projectStatus = [];
  countries = [];
  currencyArr = [];
  services = [];
  serviceComponents = [];
  roles = [];
  supplierRoles = [];
  productTypes = [];
  project_manager = [];
  partners = [];
  masterServiceComponentArray = [];
  rowsToShow = [];
  units = [];
  unitsDuplicate;
  unittypes = [];

  Weight: ProjectUOM[] = [];
  Length: ProjectUOM[] = [];
  Volume: ProjectUOM[] = [];
  Area: ProjectUOM[] = [];
  partnerServiceMapping: any = [];
  user_type_services_components_mapping: any = [];
  supplier_project_services_components_mapping = [];

  currentUser = this.userService.getCurrentUser();
  filesToUpload: Array<File> = [];

  uploadingFileName;

  subscription: Subscription;
  error = '';
  modalDisplay = 'none' ;

  faServices = ['e-Pay', 'Farmer Profiling', 'Geo Mapping', 'Scan ID Card', 'Sourcing & Collection', 'Traceability'];
  pmServices = ['Farmer Profiling', 'Print ID Cards', 'Reporting & Analytics', 'Scan ID Card'];

  faServicesCode = ['EPAY', 'FMPR', 'GEOM', 'SCID', 'SRCL', 'TRCY'];
  pmServicesCode = ['FMPR', 'PRID', 'RPAN', 'SCID'];

  product_name: string;

  public showMainSpinner = false;

  public intUnitValue;

  private readonly unsubscribe: Subject<void> = new Subject();

  public partnersArray = [];
  public servicesArray = [];
  public componentsArray = [];

  public checkedObj = {};
  public allCheckedUserTypes = [];
  public allCheckedUserTypesServices = [];

  public allComponentsOfThisServiceSelected = [];
  public serviceComponentsMapping = [];

  public unitsMasterArray = [];
  unitsMasterArrayCopy;
  public projectUnitsMaster = [];
  public ecosystemUOMChanged;
  public projectUOMObj = {};

  public mappingsForSupplier = {};
  public hideSupplierMappingGrid = true;
  public userTypeMappingsCount;
  public servicesWithoutComponents;

  public confirmCancelModal : BsModalRef;
  public infoModal:BsModalRef;
  public allClosed = false;
  public ecosystemID:any;
  noFileChosenDefault; // default text for input file component
  ecoSystemData;
  languagesList;
  selectedLanguages:any=[];
  regionVillageMasterArray:any =[];
  fromNavId;
  public typeOfAdministrationArr: any[]; 
  typeOfAdministration = '';
  regionVillageMasterCopy:any;
  finalRequestObject:any;
  languageIDsArray:any = [];
  checkedNumber = 1;
  limitNumber = 2;
  addRegionStingsArray:any = [];
  addVillageAreaStringsArray:any = [];
  regionVillageValidationFlag=true;
  regionVillageCheck=false;
  currencyFlag=false;
  countryFalg=false;
  projectManagerFlag=false;
  unitsSetDefaultFlag=false;
  public confirmRegionVillageAreaModel:BsModalRef;//popup added
  public confirmModal2 = true;//popup added
  selectedLanguagesMultiSelect:any=[];

  flagEn = false;
  flagEs = false;
  flagFr = false;
  flagCh = false;
  flagTa = false;
  flagMg = false;

  constructor(private readonly projectService: ProjectService, private readonly commonService:  CommonService,
  private readonly router: Router, private readonly elementRef: ElementRef,
  private readonly renderer: Renderer2, private readonly userService: UserService, private readonly loc: Location,
  private readonly _route: ActivatedRoute, private readonly spinner: Ng4LoadingSpinnerService,
  private readonly toastrService: ToastrService, @Inject(LOCALE_ID) private readonly locale: string, private readonly translate: TranslateService,
  private readonly suppliersService: SuppliersService, private readonly modalService: BsModalService, private readonly localeService: BsLocaleService) {

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.translate.get('file_upload_component_default_txt').subscribe(result => {
      this.noFileChosenDefault = result; // default text for the file input component
    });

    this.localeService.use(this.locale);

    const localLang = this.loc.prepareExternalUrl('').replace(/\//g,'');

    if(localLang === 'en') {
      this.flagEn = true;
    } else if (localLang === 'es') {
        this.flagEs = true;
    } else if (localLang === 'fr') {
        this.flagFr = true;
    } else if (localLang === 'zh') {
      this.flagCh = true;
    } else if (localLang === 'ta') {
      this.flagTa = true;
    } else if (localLang === 'mg') {
      this.flagMg = true;
    } else {
      this.flagEn = true;
    }
    this._route.queryParamMap.subscribe(queryParams => {
      const qpObject = { ...queryParams.keys, ...queryParams };

      this.fromNavId = qpObject['params']['from'];

      this.translate.get('typeAdminTextArr').subscribe((val) => {
        this.typeOfAdministrationArr = val;
      }, err => console.log('err', err));

      if (this.fromNavId == 2) {
        this.typeOfAdministration = this.typeOfAdministrationArr[0];
      }
      else if (this.fromNavId == 3) {
        this.typeOfAdministration = this.typeOfAdministrationArr[1];
      }

    })
    

}
dropdownSettingsLanguages = {
  singleSelection: false,
  idField: 'id',
  textField: 'name',
  selectAllText: 'Select All',
  unSelectAllText: 'Un-Select All',
  allowSearchFilter: false,
  "limitSelection": 3
};

  async ngOnInit() {

    this.scrollToTop();
    this.ecosystemID = +this._route.snapshot.params['id'];
    this.countries = this._route.snapshot.data.commonData.countries.data;
    this.currencyArr = this._route.snapshot.data.commonData.currency.data;
    this.project_manager = this._route.snapshot.data.commonData.projectmanager.data;
    
    if (!navigator.onLine) {
      setTimeout(() => { 
        this.commonService.showToast('warning', 'offline_project_warning', {}); 
      });
      this.router.navigate(['/dashboard']);
      return;
    }
    this.spinner.show();
    this.projectId = +this._route.snapshot.params['id'];
    // Set up units
    this.units = this._route.snapshot.data.commonData.units.data;

    if(this.ecosystemID > 0){
      this.commonService.getLanguages().subscribe(response =>{
        this.languagesList = response.data;
        for(const lang of this.languagesList){
          this.languageIDsArray.push(lang.id);
        }

        this.suppliersService.getEcoSystemSettingsRecord(this.ecosystemID).subscribe(response =>{
          
          if (response.status === 'success') {
            this.ecoSystemData=response.data;
            if(this.ecoSystemData.currency_id == null || this.ecoSystemData.currency_id == 'null'){
              this.ecoSystemData.currency_id = 0;
            }
            if(this.ecoSystemData.country_id == null || this.ecoSystemData.country_id == 'null'){
              this.ecoSystemData.country_id = 0;
            }
            if(this.ecoSystemData.dr_manager_id == null || this.ecoSystemData.dr_manager_id == 'null'){
              this.ecoSystemData.dr_manager_id = 0;
            }
            if(!this.ecoSystemData.ecosystem_languages_mapping || this.ecoSystemData.ecosystem_languages_mapping.length == 0){
              this.ecoSystemData.ecosystem_languages_mapping.push(1);
              this.selectedLanguages = this.ecoSystemData.ecosystem_languages_mapping;              
            }else{
              this.selectedLanguages = this.ecoSystemData.ecosystem_languages_mapping.map(item => item.user_lang_id);
            }
            this.selectedLanguagesMultiSelect =  this.languagesList.filter(item => this.selectedLanguages.indexOf(item.id) > -1);
            
            if(this.ecoSystemData.ecosystem_region_master && this.ecoSystemData.ecosystem_region_master.length > 0){
              for (let i=0; i < this.ecoSystemData.ecosystem_region_master.length; i++) {
                this.ecoSystemData.ecosystem_region_master[i]['tmp_id'] = +this.commonService.getRandomNum().toString().slice(2, 6);
                this.ecoSystemData.ecosystem_region_master[i]['isregion'] = true;
                for (let j=0; j < this.ecoSystemData.ecosystem_region_master[i].region_villages.length; j++) {
                  this.ecoSystemData.ecosystem_region_master[i].region_villages[j]['tmp_id'] = +this.commonService.getRandomNum().toString().slice(2, 6);
                  this.ecoSystemData.ecosystem_region_master[i].region_villages[j]['isvilagearea'] = true;
                }
              }
              this.regionVillageMasterArray = this.ecoSystemData.ecosystem_region_master;
              this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
            } else {
              this.regionVillageMasterArray = [];
              this.regionVillageMasterCopy = '';
            }
            if(this.regionVillageMasterArray.length > 0){
              for(let i=0;i< this.regionVillageMasterArray.length;i++){              
                const currentItem = this.ecoSystemData.ecosystem_region_master.filter(item => item.id == this.regionVillageMasterArray[i].id)[0];
                this.regionVillageMasterArray[i].project_mapped = currentItem.Project;
              }
              this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
            }            

            for (let i = 0; i < this.units.length; i++) {
              this.units[i]['international_value'] = 1;
            }
            this.unitsDuplicate = JSON.stringify(this.units);
            this.unittypes = this._route.snapshot.data.commonData.unittypes.data;
            for (let i = 0; i < this.unittypes.length; i++) {
              this.unitsMasterArray.push({
                unitType: this.unittypes[i],
                unitOptions: this.units.filter(item => item.unit_type_id == this.unittypes[i].id),
              });
            }

            if(this.ecoSystemData.EcosystemUOM.length > 0){
              for (let i = 0; i < this.unitsMasterArray.length; i++) {
                this.unitsMasterArray[i].unitsInProject = this.ecoSystemData.EcosystemUOM.filter(x => x.unit_type_id == this.unitsMasterArray[i]['unitType'].id);
                for (let j = 0; j < this.unitsMasterArray[i].unitsInProject.length; j++) {
                  this.unitsMasterArray[i].unitsInProject[j]['tmp_id']  = +this.commonService.getRandomNum().toString().slice(2, 6);
                  this.unitsMasterArray[i].unitsInProject[j]['name'] = this.unitsMasterArray[i].unitsInProject[j]['uom'];//assign to name
                }
              }
      
              this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
              this.setInternationalUnits();
            }else{
              this.addUnitNew();
            }
          }else {
            this.commonService.showToast('error', 'generic_error', {});
          }
          this.spinner.hide();
        },(err) => {
          this.spinner.hide();
          this.commonService.showToast('error', 'generic_error', {});
        });
      });
      
    }
  }

  ngAfterViewInit(): void {
    this.spinner.hide();
    const elements: any[] = this.elementRef.nativeElement.querySelectorAll('select[required]');

    setTimeout(() => {
      elements.forEach(e => {
        if (e.value === '0') {
          this.renderer.addClass(e, 'ng-invalid');
        }
      });
    }, 1000);

  }


  checkIfDuplicateExists(w){
    for (let i = 0; i < w.length; i++) {
      const filteredArray = w.filter(item => item.code == w[i].code);
      if (filteredArray && filteredArray.length > 1) {
        return true;
      }
    }
  }

  selectedLanguagesArray(currentId,event,item){
    if(event.target.checked){      
      this.selectedLanguages.push(currentId);
      this.checkedNumber++;
    }else{      
      this.selectedLanguages = this.selectedLanguages.filter(item => item !== currentId);
      this.checkedNumber--;
    }
  }

  disableLanguages(val){
    if(this.selectedLanguages.indexOf(val) > -1){
      return false;
    }else{
      return true;
    }
  }

  submit(template: TemplateRef<any>) { //popup added
    
    let uomArray = []; 
    const uomArray2 = [];
    for (let i = 0; i < this.unitsMasterArray.length; i++) {
      uomArray = uomArray.concat(this.unitsMasterArray[i]['unitsInProject']);
    }

    if (this.checkIfDuplicateExists(uomArray)) {
      this.commonService.showToast('error', 'duplicate_units', {});
      this.spinner.hide();
      this.savingEcosystem = false;
      return;
    }

    for (let i = 0; i < uomArray.length; i++) {
      if (!uomArray[i]['international_value'] || uomArray[i]['international_value'] == '' || uomArray[i]['international_value'] == 0) {
        this.commonService.showToast('error', 'int_unit_value_missing', {});
        this.spinner.hide();
        this.savingEcosystem = false;
        return;
      }

      const uom = {
        id : uomArray[i]['id'],
        //project_id : this.project.id > 0 ? this.project.id : 0,
        ecosystem_id:this.ecoSystemData.id,
        unit_id: uomArray[i]['unit_id'],
        unit_type_id: uomArray[i]['unit_type_id'],
        code: uomArray[i]['code'],
        uom: uomArray[i]['uom'] ? uomArray[i]['uom'] : uomArray[i]['name'],
        international_value: uomArray[i]['international_value'],
        international_unit: uomArray[i]['international_unit'],
        is_default: uomArray[i]['is_default'],
        deleted: uomArray[i]['deleted'] ? (uomArray[i]['deleted'] == true ? true: false) : false
      };
      uomArray2.push(uom);
    }

    this.ecoSystemData.EcosystemUOM = uomArray2;

    if (!this.ecoSystemData.EcosystemUOM || this.ecoSystemData.EcosystemUOM.length == 0) {
      this.commonService.showToast('error', 'add_units', {});
      this.savingEcosystem = false;
      return;
    }

    for (let i = 0; i < this.unitsMasterArray.length; i++) {
      const unitX = this.ecoSystemData.EcosystemUOM.filter(item => item.unit_type_id == this.unitsMasterArray[i]['unitType']['id']);
      if (!unitX || unitX.length == 0) {
        this.commonService.showToast('error', 'add_units', {});
        this.savingEcosystem = false;
        return;
      }
    }

    //popup added
    if(this.regionVillageMasterArray.length > 0){
      let regionFieldEmpty = true;
      for(let i=0;i<this.regionVillageMasterArray.length; i++){
        const villageArea = this.regionVillageMasterArray[i].region_villages;
        this.regionVillageMasterArray[i].region_name = this.regionVillageMasterArray[i].region_name.trim();
        if(this.regionVillageMasterArray[i].region_name == ''){
          regionFieldEmpty = false;
          this.savingEcosystem = false;
          break;
        }else{
          for(let j=0;j<villageArea.length;j++){
            villageArea[j].village_name = villageArea[j].village_name.trim();            
            if(villageArea[j].village_name == ''){
              regionFieldEmpty = false;
              this.savingEcosystem = false;
              break;
            }
          }
        }
      }
      
      if(regionFieldEmpty){
        this.saveEcosystem();
      }      
      
    }else{
      this.savingEcosystem = false;
      this.confirmRegionVillageAreaModel = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    }
  }

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  saveEcosystem() {
    this.savingEcosystem = true;
    const ecosystemId = this.ecoSystemData.id;
    const productId = this.ecoSystemData.product.id; 
    const supplierId = this.ecoSystemData.supplier.id;
    const isValid = (this.ecosystemForm.controls['currency'].value && this.ecosystemForm.controls['currency'].value > 0) && (this.ecosystemForm.controls['Country'].value && this.ecosystemForm.controls['Country'].value > 0) && (this.ecosystemForm.controls['f_project_manager'].value && this.ecosystemForm.controls['f_project_manager'].value > 0) && this.regionVillageValidationFlag && this.selectedLanguagesMultiSelect.length > 0; 
    
    if (isValid) {
      const requestObject = {};

      requestObject['ecosystemId'] = ecosystemId;
      requestObject['supplierId'] = supplierId;
      requestObject['productId'] = productId;
      requestObject['currencyId'] = this.ecosystemForm.controls['currency'].value;
      requestObject['countryId'] = this.ecosystemForm.controls['Country'].value;
      requestObject['drmanager'] = this.ecosystemForm.controls['f_project_manager'].value;    
      requestObject['ecosystemUOM'] = this.ecoSystemData.EcosystemUOM;
      for(const region of this.regionVillageMasterArray){
        region['country_id'] = this.ecosystemForm.controls['Country'].value;
      }
      requestObject['region_village_area'] = this.regionVillageMasterArray; 
      this.selectedLanguages = this.selectedLanguagesMultiSelect.map(item => item.id);
      console.log('this.selectedLanguages', this.selectedLanguages);
      const languagesMappingObject = {
        "ecosystem_id":ecosystemId,
        "user_lang_id":this.selectedLanguages
      }   
      requestObject['display_languages'] = languagesMappingObject;
      
      this.finalRequestObject =requestObject;
      this.suppliersService.updateEcosystemSettings(this.finalRequestObject).subscribe(
        (result) => {
          this.savingEcosystem = false;
          if (result.msg === 'success') {
            this.commonService.showToast('success', 'success_master_data_updated', {});
            const url="/ecosystem-settings";
            this.router.navigate([url],{ queryParams: { from: this.fromNavId } });
          } 
          this.spinner.hide();
        },
        (error) => {this.spinner.hide();
          this.savingEcosystem = false;
        },
        () => {/*console.log('')*/}
      );


    } else {
      this.commonService.showToast('error', 'mandatory_fields', {});
      this.savingEcosystem = false;
      this.spinner.hide();
    }   
    
  }

  // Add a unit to the gieve unit type
  addUnitDynamic(unitTypeToAddClicked: any,template: TemplateRef<any>) {
    this.infoModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });

    const oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
    let unitToAdd;
    let unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
    // Find the unit type that is to be added
    const unitTypeToAdd = oldUnitsMasterArray.filter(one => one.unitType.id === unitTypeToAddClicked.id);
    
    if (unitTypeToAdd && unitTypeToAdd.length > 0) {
      const index = oldUnitsMasterArray.indexOf(unitTypeToAdd[0]);
      if (index != null && index > -1) {
        // From the overall units array, remove the units in this unittype that have already been added
        for (let i = 0; i < oldUnitsMasterArray[index].unitsInProject.length; i++) {
          unitsDuplicateArray = unitsDuplicateArray.filter(item => item.unit_id !== oldUnitsMasterArray[index].unitsInProject[i].unit_id);
        }
        // From the remaining set of units, find the first unit of the type that is to be added
        const unitChanged = unitsDuplicateArray.filter(one => one.unit_type_id === unitTypeToAddClicked.id);
        
        if (unitChanged && unitChanged.length > 0) {
          unitToAdd = unitChanged[0];
          
          unitToAdd.tmp_id = +this.commonService.getRandomNum().toString().slice(2, 6);
          unitToAdd.is_default = false;
          oldUnitsMasterArray[index]['unitsInProject'].push(unitToAdd);
        }
      }
    }
    // Configure the unitsMasterArray & copy
    this.unitsMasterArray = oldUnitsMasterArray;
    this.unitsMasterArray = [].concat(this.unitsMasterArray);
    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
    this.setInternationalUnits();
  }

  addUnitNew() {
    for (let i = 0; i < this.unitsMasterArray.length; i++) {
      this.unitsMasterArray[i]['unitsInProject'] = [];
      this.unitsMasterArray[i]['unitsInProject'].push(this.unitsMasterArray[i].unitOptions[0]);
      this.unitsMasterArray[i]['unitsInProject'][0]['is_default'] = true;
      this.unitsMasterArray[i]['unitsInProject'][0]['tmp_id'] = +this.commonService.getRandomNum().toString().slice(2, 6);
    }
    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
    this.setInternationalUnits();
  }

  addUnit(key: string) {
    const uom = new ProjectUOM();
    uom.temp_id = +this.commonService.getRandomNum().toString().slice(2, 6);

    uom.uom_type = key;
    if (this.locale == 'es') {
      uom.international_uom = key === 'Length' ? 'Metro' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litro' : 'Hectárea'));
    } else if (this.locale == 'en') {
      uom.international_uom = key === 'Length' ? 'Meter' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Ltr' : 'Hectare'));
    } else if (this.locale == 'fr') {
      uom.international_uom = key === 'Length' ? 'Mètre' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litre' : 'Hectare'));
    } else if (this.locale == 'ch') {
      uom.international_uom = key === 'Length' ? '仪表' : (key === 'Weight' ? '公斤' : (key === 'Volume' ? '升' : '公顷'));
    } else {
      uom.international_uom = key === 'Length' ? 'மீட்டர்' : (key === 'Weight' ? 'கிலோ' : (key === 'Volume' ? 'லிட்டர்' : 'ஹெக்டேர்'));
    }
    uom.is_default = this[key].length === 0;

    if (this[key].length < 1) {
      if (this.locale == 'es') {
        uom.uom = (key === 'Length' ? 'Metro' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litro' : 'Hectárea')));
      } else if (this.locale == 'en') {
        uom.uom = (key === 'Length' ? 'Meter' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Ltr' : 'Hectare')));
      } else if (this.locale == 'fr') {
        uom.uom = (key === 'Length' ? 'Mètre' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litre' : 'Hectare')));
      } else if (this.locale == 'ch') {
        uom.uom = (key === 'Length' ? '仪表' : (key === 'Weight' ? '公斤' : (key === 'Volume' ? '升' : '公顷')));
      } else {
        uom.uom = (key === 'Length' ? 'மீட்டர்' : (key === 'Weight' ? 'கிலோ' : (key === 'Volume' ? 'லிட்டர்' : 'ஹெக்டேர்')));
      }
      uom.international_value = 1;
    }
    uom.project_id = this.project.id > 0 ? this.project.id : 0;
    this[key].push(uom);
  }
  deleteUnit(key: string, unit: ProjectUOM) {

    if (unit.id > 0) {
      this[key] = this[key].filter(x => x.id !== unit.id);
    } else {
      this[key] = this[key].filter(x => x.temp_id !== unit.temp_id);
    }


    if (unit.is_default) {
      this[key][0].is_default = true;
    }

  }

  deleteUnit2(unit: any,template:TemplateRef<any>) {
    this.infoModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    this.unitsSetDefaultFlag=false;
    const oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
    const unitTypeToDelete = oldUnitsMasterArray.filter(one => one.unitType.id === unit.unit_type_id);
    if (unitTypeToDelete && unitTypeToDelete.length > 0) {
      const index = oldUnitsMasterArray.indexOf(unitTypeToDelete[0]);
      if (index != null && index > -1) {
        oldUnitsMasterArray[index]['unitsInProject'] = oldUnitsMasterArray[index]['unitsInProject'].filter(item => item.tmp_id !== unit.tmp_id);

        if (oldUnitsMasterArray[index]['unitsInProject'].length > 0) {
          oldUnitsMasterArray[index]['unitsInProject'][0]['is_default'] = true;
        }
      }
    }
    this.unitsMasterArray = oldUnitsMasterArray;
    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
    this.setInternationalUnits();
  }

  changeDefaultProjectUOM(unit: any,template:TemplateRef<any>) {
    this.infoModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    if(unit.is_default == false){
      this.unitsSetDefaultFlag= true;
    }else{
      this.unitsSetDefaultFlag= false;
    }
    
    const oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
    const unitTypeChanged = oldUnitsMasterArray.filter(item => item.unitType.id == unit.unit_type_id);
    if (unitTypeChanged && unitTypeChanged.length > 0) {
      const index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
      if (index != null && index > -1) {
        const unitsInProject = oldUnitsMasterArray[index]['unitsInProject'];
        for (let i = 0; i < unitsInProject.length; i++) {
          unitsInProject[i]['is_default'] = false;
        }
        const unitChanged = unitsInProject.filter(item => item.tmp_id == unit.tmp_id);
        if (unitChanged && unitChanged.length > 0) {
          const index2 = unitsInProject.indexOf(unitChanged[0]);
          unitsInProject[index2]['is_default'] = true;
        }
        oldUnitsMasterArray['unitsInProject'] = unitsInProject;
      }
    }
    this.unitsMasterArray = oldUnitsMasterArray;
    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
    this.setInternationalUnits();
  }

  checkChange(key: string, entry: ProjectUOM) {

    this[key] = this[key].map(x => {
      if (x.temp_id === entry.temp_id) {
        x.is_default = true;
      } else {
        x.is_default = false;
      }
      return x;
    });
  }

  checkUnit(key: string, entry: ProjectUOM) {

    this[key].map(x => {
      if (entry.uom && entry.uom !== '' && x.uom === entry.uom && x.temp_id !== entry.temp_id) {
        entry.uom = '';
        this.commonService.showToast('warning', 'unit_conversion_exists', {});
        // return;
      }
    });
  }

  cancel(template: TemplateRef<any>) {
    if (this.ecosystemForm.dirty) {
      this.confirmCancelModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
    }
    else {
      const url="ecosystem-settings";
      this.router.navigate([url],{ queryParams: { from: 3 } });
    }
  }

  confirmModal(what: string) {
    if (what == 'yes') {
      if (this.confirmCancelModal) {
        this.confirmCancelModal.hide();
      }
      this.confirmCancel();
    }
    else {
      if (this.confirmCancelModal) {
        this.confirmCancelModal.hide();
      }
    }
  }

  confirmCancel() : void {
    const url="ecosystem-settings";
    this.router.navigate([url],{ queryParams: { from: 3 } });
  }

  GetInitials(string) {
    const names = string.split(' ');
    let  initials = '';

    if (names.length === 1) {
      return names[0].substring(0, 1).toUpperCase();
    }

    names.forEach((name) => {
      initials += name.substring(0, 1).toUpperCase();
    });
    return initials;
  }

  productChanged() {

    if (this.project.product_name) {
      const obj = this.products.find(x => x.name.toLowerCase() === this.project.product_name.toLowerCase());
      if (obj) {
        this.project.product_id = obj.id;
      } else {
        this.project.product_id = 0;
      }
    }
  }

  trackById(index, dataItem) {
    return dataItem.id;
  }
  trackByCode(index, dataItem) {
    return dataItem.code;
  }
  trackByTempId(index, dataItem) {

     return dataItem.temp_id;
  }

  goToProject(): void {
    const url = `projectinfo/${this.projectId}`;
    this.router.navigate([url]);
  }

  goToDashboard(): void {
    if (confirm('Are you sure you want to exit?')) {
      this.router.navigate(['/dashboard']);
    }
  }

  trackByIndex(index, dataItem) {

    return index;
 }

 closeModal(isOkay: boolean) {

    this.modalDisplay = 'none';
    this.subject.next(isOkay);

 }

  ngOnDestroy() {

    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.subject.complete();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  setManager(item) : void {
    if(item > 0){
      this.projectManagerFlag = false;
    }else{
      this.projectManagerFlag = true;
    }
  }

  setCountry(item) : void {
    if(item > 0){
      this.countryFalg = false;
    }else{
      this.countryFalg = true;
    }
  }

  setCurrency(item) : void {
    if(item > 0){
      this.currencyFlag = false;
    }else{
      this.currencyFlag = true;
    }
  }

  setProductType(args) : void {
    if (this.project.product_id) {
      if (this.project.product_id > 0) {
        const product = this.products.filter(item => item.id == this.project.product_id);
        if (product && product.length > 0) {
          const productItem = product[0];
          if (productItem) {
            this.project.product_type = productItem.product_type_id;
          }
        }
      }
      else {
        this.project.product_type = 0;
      }
    }
  }

  internationalUnitChanged(projectUOM) : void {

    const unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
    const unitChanged = unitsDuplicateArray.filter(one => one.code == projectUOM.code);
    if (unitChanged && unitChanged.length > 0) {
      const index = unitsDuplicateArray.indexOf(unitChanged[0]);
      if (index != null  && index > -1) {
        unitsDuplicateArray[index]['international_value'] = projectUOM['international_value'];
        this.unitsDuplicate = JSON.stringify(unitsDuplicateArray);
      }
    }
  }

  selectUnit(projectUOM, projectUOMIndex) : void {

    const unitTypeChanged = this.unitsMasterArray.filter(one => one.unitType.id == projectUOM.unit_type_id);
    
    if (unitTypeChanged && unitTypeChanged.length > 0) {
      const index = this.unitsMasterArray.indexOf(unitTypeChanged[0]);
      
      if (index != null && index > -1) {
        const ecosystemUOMChanged = this.unitsMasterArray[index]['unitsInProject'].filter(one => one.code == projectUOM.code);
        
        if (ecosystemUOMChanged && ecosystemUOMChanged.length > 0) {
          const index2 = this.unitsMasterArray[index]['unitsInProject'].indexOf(ecosystemUOMChanged[0]);
          if (index2 != null  && index2 > -1){
            const unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
            const unitSelected = unitsDuplicateArray.filter(one => one.code == projectUOM.code);
            if (unitSelected && unitSelected.length > 0) {
              const index3 = unitsDuplicateArray.indexOf(unitSelected[0]);
              let unitToSet;
              unitToSet = unitsDuplicateArray[index3];

              for (let i = 0; i < this.unitsMasterArray[index]['unitsInProject'].length; i++) {
                this.unitsMasterArray[index]['unitsInProject'][i]['is_default'] = false;
              }
              this.unitsMasterArray[index]['unitsInProject'][index2] = unitsDuplicateArray[index3];
              this.unitsMasterArray[index]['unitsInProject'][index2]['is_default'] = true;
            }
          }
        }

        const unitChanged = this.unitsMasterArray[index]['unitOptions'].filter(one => one.id == projectUOM.id);
        
        if (unitChanged && unitChanged.length > 0) {
          const index4 = this.unitsMasterArray[index]['unitOptions'].indexOf(unitChanged[0]);
          if (index4 != null  && index4 > -1){
            const unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
            const unitSelected = unitsDuplicateArray.filter(one => one.id == projectUOM.id);
            if (unitSelected && unitSelected.length > 0) {
              const index5 = unitsDuplicateArray.indexOf(unitSelected[0]);
              this.unitsMasterArray[index]['unitOptions'][index4] = unitsDuplicateArray[index5];
            }
          }
        }
      }
    }
    this.unitsMasterArray = Object.assign([], this.unitsMasterArray);
    this.setInternationalUnits();
  }

  selectUnit2(projectUOM, projectUOMIndex, template:TemplateRef<any>): void {
    // Find the unit type & unitsInProject items that have been changed
    this.infoModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });

    const oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
    const unitTypeChanged = oldUnitsMasterArray.filter(one => one.unitType.id == projectUOM.unit_type_id);
    if (unitTypeChanged && unitTypeChanged.length > 0) {
      const index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
      if (index != null && index > -1) {
        const ecosystemUOMChanged = oldUnitsMasterArray[index]['unitsInProject'].filter(one => one.tmp_id == projectUOM.tmp_id); // code changed to tmp_id
        
        if (ecosystemUOMChanged && ecosystemUOMChanged.length > 0) {
          const index2 = oldUnitsMasterArray[index]['unitsInProject'].indexOf(ecosystemUOMChanged[0]);
          if (index2 != null  && index2 > -1){
            const unitsDuplicateArray = JSON.parse(this.unitsDuplicate);

            // this is the new unit selected
            const unitSelected = unitsDuplicateArray.filter(one => one.code == projectUOM.code);
            if (unitSelected && unitSelected.length > 0) {
              const index3 = unitsDuplicateArray.indexOf(unitSelected[0]);
              unitsDuplicateArray[index3]['tmp_id'] = projectUOM.tmp_id;

              for (let i = 0; i < oldUnitsMasterArray[index]['unitsInProject'].length; i++) {
                oldUnitsMasterArray[index]['unitsInProject'][i]['is_default'] = false;
              }
              oldUnitsMasterArray[index]['unitsInProject'][index2] = unitsDuplicateArray[index3];
              oldUnitsMasterArray[index]['unitsInProject'][index2]['is_default'] = true;
            }
          }
        }

      }
    }
    this.unitsMasterArray = oldUnitsMasterArray;
    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
    this.setInternationalUnits();

  }
 

  internationalUnitValueChanged(unit) : void {
    const oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
    const unitTypeChanged = oldUnitsMasterArray.filter(item => item.unitType.id == unit.unit_type_id);
    if (unitTypeChanged && unitTypeChanged.length > 0) {
      const index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
      if (index != null && index > -1) {
        const unitsInProject = oldUnitsMasterArray[index]['unitsInProject'];

        const unitChanged = unitsInProject.filter(item => item.tmp_id == unit.tmp_id);
        if (unitChanged && unitChanged.length > 0) {
          const index1 = unitsInProject.indexOf(unitChanged[0]);
          unitsInProject[index1]['international_value'] = unit['international_value'];

          oldUnitsMasterArray['unitsInProject'] = unitsInProject;
          this.unitsMasterArray = Object.assign([], oldUnitsMasterArray);
          this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
          this.setInternationalUnits();
        }
      }
    }
  }

  updateUnitsMasterArrayCopy(args): void {
    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
  }

  setInternationalUnits(): void {
    for (let i = 0; i < this.unitsMasterArray.length; i++) {
      for (let j = 0; j < this.unitsMasterArray[i]['unitsInProject'].length; j++) {
        const intValue = this.unittypes.filter(item => item.international_unit_to_save == this.unitsMasterArray[i]['unitsInProject'][j]['international_unit']);
        
        if (intValue && intValue.length > 0) {
          this.unitsMasterArray[i]['unitsInProject'][j]['international_unit'] = intValue[0]['international_unit'];
        }
      }
    }
  }

  addRegion(currentVal,id){
    let regionMasterArray = [];
    if(!this.regionVillageMasterCopy ||  this.regionVillageMasterCopy.length == 0){
      regionMasterArray = [];
    } 
       
    const regionObject={
        "region_name":"",
        "isregion":true,
        "isvilagearea":false,
        "region_villages":[],
        "status":'',
        "id": 0
      };
      regionObject['tmp_id'] = +this.commonService.getRandomNum().toString().slice(2, 6);
      regionMasterArray.push(regionObject);
      this.regionVillageMasterArray.push(...regionMasterArray);
      this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
      this.regionVillageUpdateButtonDisable(regionObject, 'add');
  }
  addVillageArea(villageArea,regionVal){   
    const villageAreaObject ={
          "village_name":'',
          "type":"villagearea",
          "isvilagearea":true,
          "isregion":false,
          "actions":"yes",
          "farmer_reg":[],      
          "region_id": regionVal.id,
          "id": 0
      };
      villageAreaObject['tmp_id'] = +this.commonService.getRandomNum().toString().slice(2, 6);
      const newObject = Object.assign({}, villageAreaObject);
      const oldRegionVillageMasterArray = JSON.parse(this.regionVillageMasterCopy); 
      const newArray:any = oldRegionVillageMasterArray;
      for(const x of newArray){        
        if(x.tmp_id === regionVal.tmp_id){                  
          x.region_villages.push(newObject);
        }
      }
      this.regionVillageMasterArray = newArray;
      this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
      this.regionVillageUpdateButtonDisable(newObject, 'add');
  }

  deleteRegion(regionVal){
    const oldRegionVillageMasterArray = JSON.parse(this.regionVillageMasterCopy);    
    const regionArray = oldRegionVillageMasterArray.filter(item => item.tmp_id !== regionVal.tmp_id);
    let x;
    for(const item of regionArray){
      if(item.region_villages && item.region_villages.length > 0){
       
      }else{
        x = true;        
      }
    }
    if(x){
      this.regionVillageCheck = true;
    }
    this.regionVillageCheck = false;
    this.regionVillageMasterArray  = regionArray;
    this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
    this.regionVillageValidationFlag = true;
  }
  deleteVillageArea(regionVal,villageAreaVal){    
    const oldRegionVillageMasterArray = JSON.parse(this.regionVillageMasterCopy);
    const villageAreaToDelete = oldRegionVillageMasterArray.filter(item => item.tmp_id == regionVal.tmp_id);
    if (villageAreaToDelete && villageAreaToDelete.length > 0) {
      const index = oldRegionVillageMasterArray.indexOf(villageAreaToDelete[0]);
      if (index != null && index > -1) {
        const villageAreaArray = oldRegionVillageMasterArray[index]['region_villages'];
        const villageAreaChanged = villageAreaArray.filter(item => item.tmp_id !== villageAreaVal.tmp_id);
        
        oldRegionVillageMasterArray[index]['region_villages'] = villageAreaChanged; 
        this.regionVillageMasterArray = oldRegionVillageMasterArray;
        this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
        this.regionVillageValidationFlag = true;        
        let x;
        for(const item of villageAreaArray){
          if(item.village_name !==  '' || item.village_name.length > 0){
            
          }else{
            x = true;        
          }
        }
        if(x){
          this.regionVillageCheck = true;
        }
      }
      this.addVillageAreaStringsArray = this.addVillageAreaStringsArray.filter(item => item.tmp_id !== villageAreaVal.tmp_id);
      this.regionVillageUpdateButtonDisable(villageAreaToDelete[0],'delete');
    }
  }

  updateRegionMasterArrayCopy(event:any,valType,item){
    console.log("item ", item);
    console.log("event ", event);
    let villageAreaArray=[];
    if(item.village_name.length > 0){
      villageAreaArray = item.village_name;
    }
     if(valType == 'region'){
      if(this.addRegionStingsArray.length > 0){
        for(const stringItem of this.addRegionStingsArray){
          if(stringItem.toLowerCase() == event.target.value.trim().toLowerCase()){
            event.target.value = '';
          }else{
            if(event.target.value !== '' || event.target.value.length > 0){
              this.addRegionStingsArray.push(event.target.value.trim());
              this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
              break;
            }else{
              this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
            }
           
          }
        }
      }else{
        this.addRegionStingsArray.push(event.target.value.trim());
        this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
      }
     }else{
       
       if(villageAreaArray.length > 0){    
          if(this.addVillageAreaStringsArray.length > 0){
            for(const stringItem of this.addVillageAreaStringsArray){
              const villageArray = villageAreaArray.filter(item => item.village_name.toLowerCase() == event.target.value.toLowerCase() && item.tmp_id  != stringItem.tmp_id)[0];
              
              if(villageArray !=  undefined){                
                if(stringItem.tmp_id == villageArray.tmp_id){
                  if(event.target.value !== '' || event.target.value.length > 0){
                    if(villageArray.village_name.toLowerCase() === event.target.value.toLowerCase()){
                      stringItem.value = event.target.value.trim();
                      this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
                    }else{
                      this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
                      stringItem.value = event.target.value.trim();
                    }
                  }else{
                    stringItem.value = event.target.value.trim();
                  }            
                }else{
                  if(stringItem.value.toLowerCase() == villageArray.village_name.toLowerCase()){
                    villageArray.village_name = event.target.value.trim();
                    for(let i=0;i < this.regionVillageMasterArray.length;i++){
                      for(let j=0;j < this.regionVillageMasterArray[i].village_name.length; j++){
                        if(this.regionVillageMasterArray[i].village_name[j].tmp_id == villageArray.tmp_id){
                          this.regionVillageMasterArray[i].village_name[j].village_name = event.target.value.trim();
                          
                        }
                      }
                    }
                    event.target.value = '';  
                    this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);  
                    this.regionVillageCheck = true;      
                  }else{
                    const newObject = {
                      'tmp_id':villageArray.tmp_id,
                      'value':event.target.value.trim()                
                    };
                    this.addVillageAreaStringsArray.push(newObject);
                    //break;
                }
                }
              }
            }
          }else{
            if(event.target.value !== '' || event.target.value !== undefined){
              const villageArray = villageAreaArray.filter(item => item.village_name.toLowerCase() == event.target.value.toLowerCase())[0];
              const newObject = {
                'tmp_id':villageArray.tmp_id,
                'value':event.target.value.trim()                
              };
              this.addVillageAreaStringsArray.push(newObject);
              this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
            }else{
              const villageArray = villageAreaArray.filter(item => item.village_name === event.target.value)[0];
              const newObject = {
                'tmp_id':villageArray.tmp_id,
                'value':event.target.value.trim()                
              };
              this.addVillageAreaStringsArray.push(newObject);
              this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
            }
          }
        }
      }
     }
      
  
  ecosystemRevertBack(){
    const url="/ecosystem-settings";
    this.router.navigate([url],{ queryParams: { from: this.fromNavId } });
  }
  regionVillageAreaValidationFun(event:any,itemType,currentItem, innerItem?){
    if (currentItem.isregion) {
      const x = JSON.parse(this.regionVillageMasterCopy);
      for (let i = 0; i < x.length; i++) {
        if (x[i].tmp_id == currentItem.tmp_id) {
          x[i] = currentItem;
        }
      }
      this.regionVillageMasterArray = x;
      this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
    } 

    if(event.target.value !== '' || event.target.value.length > 0){      
      this.regionVillageValidationFlag = true;      
    }else{      
      this.regionVillageValidationFlag = false;
    }    
    this.regionVillageUpdateButtonDisable(currentItem,'add');
  }

   regionVillageUpdateButtonDisableOld(currentItem,fromType){
    console.log("currentItem ", currentItem);    
    if(this.regionVillageMasterArray.length  > 0){
      if(currentItem.name != '' || currentItem.name.length > 0){
        if(currentItem.village_name && currentItem.village_name.length > 0){
          for(let i=0;i< currentItem.village_name.length; i++){
            if(currentItem.village_name[i].village_name == '' || currentItem.village_name[i].village_name == undefined){
              
              this.regionVillageCheck = true;
              //break;
            }else{
              this.regionVillageCheck = false;
            }
          }
        }else{
          this.regionVillageCheck = true;
        }
      }else{
        this.regionVillageCheck = true;
      }
    }else{
      this.regionVillageCheck = false;
    }
  }
  infoModalClose(){
    if (this.infoModal) {
      this.infoModal.hide();
    }
  }



  regionVillageUpdateButtonDisable(currentItem,fromType){
    if(this.regionVillageMasterArray.length  > 0){
      for (let j = 0; j < this.regionVillageMasterArray.length; j++) {
        const currentItem = this.regionVillageMasterArray[j];
        if(currentItem.region_name != '' || currentItem.region_name.length > 0){
          if(currentItem.region_villages && currentItem.region_villages.length > 0){
            for(let i=0;i< currentItem.region_villages.length; i++){
              if(currentItem.region_villages[i].village_name == '' || currentItem.region_villages[i].village_name == undefined){
                this.regionVillageCheck = true;
                break;
              }else{
                this.regionVillageCheck = false;
              }
            }
          }else{
            this.regionVillageCheck = true;
          }
        }else{
          this.regionVillageCheck = true;
        }
      }
    }else{
      this.regionVillageValidationFlag = false;
    }
  }

  //popup added
  confirmModal3(what: string) {
    if (what == 'yes') {
      if (this.confirmRegionVillageAreaModel) {
        this.confirmRegionVillageAreaModel.hide();
      }
      this.saveEcosystem();
    }
    else {
      if (this.confirmRegionVillageAreaModel) {
        this.confirmRegionVillageAreaModel.hide();
      }
    }
  }
  onMultiSelect(item: any) {
  }

  onSelectAll(items: any) {

  }
  onItemSelect(item: any) {
  }

  onDeSelect(item: any) {
  }

  onDeSelectAll(items: any) {
  }

  addItem(items: any) {
  }

  removeItem(items: any, key: string, yesNo: boolean) {

  }

}
