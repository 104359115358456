import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CompareDataService {

  compareURL = this.appConfigService.getUrl(this.appConfigService.COMPARE);

  constructor(private readonly http: HttpClient, private readonly appConfigService: AppConfigService) { }

  getComparisonReport(projectId:number, compareData): Observable<any> {
    return this.http.post<any>(`${this.compareURL}/compareSnapshot/${projectId}`, compareData);
  }
  getRevisionComparisonReport(projectId:number, compareData): Observable<any> {
    return this.http.post<any>(`${this.compareURL}/compareAnswers/${projectId}`, compareData);
  }
  getFarmerList(projectId:number, revisionId:number): Observable<any> {
    return this.http.post<any>(`${this.compareURL}/farmersExistInRevision/${projectId}/${revisionId}`, "");
  }
}
