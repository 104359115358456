import * as tslib_1 from "tslib";
import { Subject, merge, fromEvent } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of } from 'rxjs/observable/of';
import { OnInit, ElementRef, Renderer2, TemplateRef } from '@angular/core';
import { mapTo } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Project, ProjectUOM } from '../../models/project';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { SuppliersService } from '../../services/suppliers.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
var EditEcosystemSettingsComponent = /** @class */ (function () {
    function EditEcosystemSettingsComponent(projectService, commonService, router, elementRef, renderer, userService, loc, _route, spinner, toastrService, locale, translate, suppliersService, modalService, localeService) {
        var _this = this;
        this.projectService = projectService;
        this.commonService = commonService;
        this.router = router;
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.userService = userService;
        this.loc = loc;
        this._route = _route;
        this.spinner = spinner;
        this.toastrService = toastrService;
        this.locale = locale;
        this.translate = translate;
        this.suppliersService = suppliersService;
        this.modalService = modalService;
        this.localeService = localeService;
        this.subject = new Subject();
        this.isOpen = false;
        this.dateFormat = 'DD-MMM-YYYY';
        this.savingEcosystem = false;
        this.projectId = 0;
        this.project = new Project();
        this.products = [];
        this.suppliers = [];
        this.projectStatus = [];
        this.countries = [];
        this.currencyArr = [];
        this.services = [];
        this.serviceComponents = [];
        this.roles = [];
        this.supplierRoles = [];
        this.productTypes = [];
        this.project_manager = [];
        this.partners = [];
        this.masterServiceComponentArray = [];
        this.rowsToShow = [];
        this.units = [];
        this.unittypes = [];
        this.Weight = [];
        this.Length = [];
        this.Volume = [];
        this.Area = [];
        this.partnerServiceMapping = [];
        this.user_type_services_components_mapping = [];
        this.supplier_project_services_components_mapping = [];
        this.currentUser = this.userService.getCurrentUser();
        this.filesToUpload = [];
        this.error = '';
        this.modalDisplay = 'none';
        this.faServices = ['e-Pay', 'Farmer Profiling', 'Geo Mapping', 'Scan ID Card', 'Sourcing & Collection', 'Traceability'];
        this.pmServices = ['Farmer Profiling', 'Print ID Cards', 'Reporting & Analytics', 'Scan ID Card'];
        this.faServicesCode = ['EPAY', 'FMPR', 'GEOM', 'SCID', 'SRCL', 'TRCY'];
        this.pmServicesCode = ['FMPR', 'PRID', 'RPAN', 'SCID'];
        this.showMainSpinner = false;
        this.unsubscribe = new Subject();
        this.partnersArray = [];
        this.servicesArray = [];
        this.componentsArray = [];
        this.checkedObj = {};
        this.allCheckedUserTypes = [];
        this.allCheckedUserTypesServices = [];
        this.allComponentsOfThisServiceSelected = [];
        this.serviceComponentsMapping = [];
        this.unitsMasterArray = [];
        this.projectUnitsMaster = [];
        this.projectUOMObj = {};
        this.mappingsForSupplier = {};
        this.hideSupplierMappingGrid = true;
        this.allClosed = false;
        this.selectedLanguages = [];
        this.regionVillageMasterArray = [];
        this.typeOfAdministration = '';
        this.languageIDsArray = [];
        this.checkedNumber = 1;
        this.limitNumber = 2;
        this.addRegionStingsArray = [];
        this.addVillageAreaStringsArray = [];
        this.regionVillageValidationFlag = true;
        this.regionVillageCheck = false;
        this.currencyFlag = false;
        this.countryFalg = false;
        this.projectManagerFlag = false;
        this.unitsSetDefaultFlag = false;
        this.confirmModal2 = true; //popup added
        this.selectedLanguagesMultiSelect = [];
        this.flagEn = false;
        this.flagEs = false;
        this.flagFr = false;
        this.flagCh = false;
        this.flagTa = false;
        this.flagMg = false;
        this.dropdownSettingsLanguages = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'Un-Select All',
            allowSearchFilter: false,
            "limitSelection": 3
        };
        this.online$ = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('file_upload_component_default_txt').subscribe(function (result) {
            _this.noFileChosenDefault = result; // default text for the file input component
        });
        this.localeService.use(this.locale);
        var localLang = this.loc.prepareExternalUrl('').replace(/\//g, '');
        if (localLang === 'en') {
            this.flagEn = true;
        }
        else if (localLang === 'es') {
            this.flagEs = true;
        }
        else if (localLang === 'fr') {
            this.flagFr = true;
        }
        else if (localLang === 'zh') {
            this.flagCh = true;
        }
        else if (localLang === 'ta') {
            this.flagTa = true;
        }
        else if (localLang === 'mg') {
            this.flagMg = true;
        }
        else {
            this.flagEn = true;
        }
        this._route.queryParamMap.subscribe(function (queryParams) {
            var qpObject = tslib_1.__assign({}, queryParams.keys, queryParams);
            _this.fromNavId = qpObject['params']['from'];
            _this.translate.get('typeAdminTextArr').subscribe(function (val) {
                _this.typeOfAdministrationArr = val;
            }, function (err) { return console.log('err', err); });
            if (_this.fromNavId == 2) {
                _this.typeOfAdministration = _this.typeOfAdministrationArr[0];
            }
            else if (_this.fromNavId == 3) {
                _this.typeOfAdministration = _this.typeOfAdministrationArr[1];
            }
        });
    }
    EditEcosystemSettingsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.scrollToTop();
                this.ecosystemID = +this._route.snapshot.params['id'];
                this.countries = this._route.snapshot.data.commonData.countries.data;
                this.currencyArr = this._route.snapshot.data.commonData.currency.data;
                this.project_manager = this._route.snapshot.data.commonData.projectmanager.data;
                if (!navigator.onLine) {
                    setTimeout(function () {
                        _this.commonService.showToast('warning', 'offline_project_warning', {});
                    });
                    this.router.navigate(['/dashboard']);
                    return [2 /*return*/];
                }
                this.spinner.show();
                this.projectId = +this._route.snapshot.params['id'];
                // Set up units
                this.units = this._route.snapshot.data.commonData.units.data;
                if (this.ecosystemID > 0) {
                    this.commonService.getLanguages().subscribe(function (response) {
                        _this.languagesList = response.data;
                        for (var _i = 0, _a = _this.languagesList; _i < _a.length; _i++) {
                            var lang = _a[_i];
                            _this.languageIDsArray.push(lang.id);
                        }
                        _this.suppliersService.getEcoSystemSettingsRecord(_this.ecosystemID).subscribe(function (response) {
                            if (response.status === 'success') {
                                _this.ecoSystemData = response.data;
                                if (_this.ecoSystemData.currency_id == null || _this.ecoSystemData.currency_id == 'null') {
                                    _this.ecoSystemData.currency_id = 0;
                                }
                                if (_this.ecoSystemData.country_id == null || _this.ecoSystemData.country_id == 'null') {
                                    _this.ecoSystemData.country_id = 0;
                                }
                                if (_this.ecoSystemData.dr_manager_id == null || _this.ecoSystemData.dr_manager_id == 'null') {
                                    _this.ecoSystemData.dr_manager_id = 0;
                                }
                                if (!_this.ecoSystemData.ecosystem_languages_mapping || _this.ecoSystemData.ecosystem_languages_mapping.length == 0) {
                                    _this.ecoSystemData.ecosystem_languages_mapping.push(1);
                                    _this.selectedLanguages = _this.ecoSystemData.ecosystem_languages_mapping;
                                }
                                else {
                                    _this.selectedLanguages = _this.ecoSystemData.ecosystem_languages_mapping.map(function (item) { return item.user_lang_id; });
                                }
                                _this.selectedLanguagesMultiSelect = _this.languagesList.filter(function (item) { return _this.selectedLanguages.indexOf(item.id) > -1; });
                                if (_this.ecoSystemData.ecosystem_region_master && _this.ecoSystemData.ecosystem_region_master.length > 0) {
                                    for (var i = 0; i < _this.ecoSystemData.ecosystem_region_master.length; i++) {
                                        _this.ecoSystemData.ecosystem_region_master[i]['tmp_id'] = +_this.commonService.getRandomNum().toString().slice(2, 6);
                                        _this.ecoSystemData.ecosystem_region_master[i]['isregion'] = true;
                                        for (var j = 0; j < _this.ecoSystemData.ecosystem_region_master[i].region_villages.length; j++) {
                                            _this.ecoSystemData.ecosystem_region_master[i].region_villages[j]['tmp_id'] = +_this.commonService.getRandomNum().toString().slice(2, 6);
                                            _this.ecoSystemData.ecosystem_region_master[i].region_villages[j]['isvilagearea'] = true;
                                        }
                                    }
                                    _this.regionVillageMasterArray = _this.ecoSystemData.ecosystem_region_master;
                                    _this.regionVillageMasterCopy = JSON.stringify(_this.regionVillageMasterArray);
                                }
                                else {
                                    _this.regionVillageMasterArray = [];
                                    _this.regionVillageMasterCopy = '';
                                }
                                if (_this.regionVillageMasterArray.length > 0) {
                                    var _loop_1 = function (i) {
                                        var currentItem = _this.ecoSystemData.ecosystem_region_master.filter(function (item) { return item.id == _this.regionVillageMasterArray[i].id; })[0];
                                        _this.regionVillageMasterArray[i].project_mapped = currentItem.Project;
                                    };
                                    for (var i = 0; i < _this.regionVillageMasterArray.length; i++) {
                                        _loop_1(i);
                                    }
                                    _this.regionVillageMasterCopy = JSON.stringify(_this.regionVillageMasterArray);
                                }
                                for (var i = 0; i < _this.units.length; i++) {
                                    _this.units[i]['international_value'] = 1;
                                }
                                _this.unitsDuplicate = JSON.stringify(_this.units);
                                _this.unittypes = _this._route.snapshot.data.commonData.unittypes.data;
                                var _loop_2 = function (i) {
                                    _this.unitsMasterArray.push({
                                        unitType: _this.unittypes[i],
                                        unitOptions: _this.units.filter(function (item) { return item.unit_type_id == _this.unittypes[i].id; }),
                                    });
                                };
                                for (var i = 0; i < _this.unittypes.length; i++) {
                                    _loop_2(i);
                                }
                                if (_this.ecoSystemData.EcosystemUOM.length > 0) {
                                    var _loop_3 = function (i) {
                                        _this.unitsMasterArray[i].unitsInProject = _this.ecoSystemData.EcosystemUOM.filter(function (x) { return x.unit_type_id == _this.unitsMasterArray[i]['unitType'].id; });
                                        for (var j = 0; j < _this.unitsMasterArray[i].unitsInProject.length; j++) {
                                            _this.unitsMasterArray[i].unitsInProject[j]['tmp_id'] = +_this.commonService.getRandomNum().toString().slice(2, 6);
                                            _this.unitsMasterArray[i].unitsInProject[j]['name'] = _this.unitsMasterArray[i].unitsInProject[j]['uom']; //assign to name
                                        }
                                    };
                                    for (var i = 0; i < _this.unitsMasterArray.length; i++) {
                                        _loop_3(i);
                                    }
                                    _this.unitsMasterArrayCopy = JSON.stringify(_this.unitsMasterArray);
                                    _this.setInternationalUnits();
                                }
                                else {
                                    _this.addUnitNew();
                                }
                            }
                            else {
                                _this.commonService.showToast('error', 'generic_error', {});
                            }
                            _this.spinner.hide();
                        }, function (err) {
                            _this.spinner.hide();
                            _this.commonService.showToast('error', 'generic_error', {});
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    EditEcosystemSettingsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.spinner.hide();
        var elements = this.elementRef.nativeElement.querySelectorAll('select[required]');
        setTimeout(function () {
            elements.forEach(function (e) {
                if (e.value === '0') {
                    _this.renderer.addClass(e, 'ng-invalid');
                }
            });
        }, 1000);
    };
    EditEcosystemSettingsComponent.prototype.checkIfDuplicateExists = function (w) {
        var _loop_4 = function (i) {
            var filteredArray = w.filter(function (item) { return item.code == w[i].code; });
            if (filteredArray && filteredArray.length > 1) {
                return { value: true };
            }
        };
        for (var i = 0; i < w.length; i++) {
            var state_1 = _loop_4(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    };
    EditEcosystemSettingsComponent.prototype.selectedLanguagesArray = function (currentId, event, item) {
        if (event.target.checked) {
            this.selectedLanguages.push(currentId);
            this.checkedNumber++;
        }
        else {
            this.selectedLanguages = this.selectedLanguages.filter(function (item) { return item !== currentId; });
            this.checkedNumber--;
        }
    };
    EditEcosystemSettingsComponent.prototype.disableLanguages = function (val) {
        if (this.selectedLanguages.indexOf(val) > -1) {
            return false;
        }
        else {
            return true;
        }
    };
    EditEcosystemSettingsComponent.prototype.submit = function (template) {
        var _this = this;
        var uomArray = [];
        var uomArray2 = [];
        for (var i = 0; i < this.unitsMasterArray.length; i++) {
            uomArray = uomArray.concat(this.unitsMasterArray[i]['unitsInProject']);
        }
        if (this.checkIfDuplicateExists(uomArray)) {
            this.commonService.showToast('error', 'duplicate_units', {});
            this.spinner.hide();
            this.savingEcosystem = false;
            return;
        }
        for (var i = 0; i < uomArray.length; i++) {
            if (!uomArray[i]['international_value'] || uomArray[i]['international_value'] == '' || uomArray[i]['international_value'] == 0) {
                this.commonService.showToast('error', 'int_unit_value_missing', {});
                this.spinner.hide();
                this.savingEcosystem = false;
                return;
            }
            var uom = {
                id: uomArray[i]['id'],
                //project_id : this.project.id > 0 ? this.project.id : 0,
                ecosystem_id: this.ecoSystemData.id,
                unit_id: uomArray[i]['unit_id'],
                unit_type_id: uomArray[i]['unit_type_id'],
                code: uomArray[i]['code'],
                uom: uomArray[i]['uom'] ? uomArray[i]['uom'] : uomArray[i]['name'],
                international_value: uomArray[i]['international_value'],
                international_unit: uomArray[i]['international_unit'],
                is_default: uomArray[i]['is_default'],
                deleted: uomArray[i]['deleted'] ? (uomArray[i]['deleted'] == true ? true : false) : false
            };
            uomArray2.push(uom);
        }
        this.ecoSystemData.EcosystemUOM = uomArray2;
        if (!this.ecoSystemData.EcosystemUOM || this.ecoSystemData.EcosystemUOM.length == 0) {
            this.commonService.showToast('error', 'add_units', {});
            this.savingEcosystem = false;
            return;
        }
        var _loop_5 = function (i) {
            var unitX = this_1.ecoSystemData.EcosystemUOM.filter(function (item) { return item.unit_type_id == _this.unitsMasterArray[i]['unitType']['id']; });
            if (!unitX || unitX.length == 0) {
                this_1.commonService.showToast('error', 'add_units', {});
                this_1.savingEcosystem = false;
                return { value: void 0 };
            }
        };
        var this_1 = this;
        for (var i = 0; i < this.unitsMasterArray.length; i++) {
            var state_2 = _loop_5(i);
            if (typeof state_2 === "object")
                return state_2.value;
        }
        //popup added
        if (this.regionVillageMasterArray.length > 0) {
            var regionFieldEmpty = true;
            for (var i = 0; i < this.regionVillageMasterArray.length; i++) {
                var villageArea = this.regionVillageMasterArray[i].region_villages;
                this.regionVillageMasterArray[i].region_name = this.regionVillageMasterArray[i].region_name.trim();
                if (this.regionVillageMasterArray[i].region_name == '') {
                    regionFieldEmpty = false;
                    this.savingEcosystem = false;
                    break;
                }
                else {
                    for (var j = 0; j < villageArea.length; j++) {
                        villageArea[j].village_name = villageArea[j].village_name.trim();
                        if (villageArea[j].village_name == '') {
                            regionFieldEmpty = false;
                            this.savingEcosystem = false;
                            break;
                        }
                    }
                }
            }
            if (regionFieldEmpty) {
                this.saveEcosystem();
            }
        }
        else {
            this.savingEcosystem = false;
            this.confirmRegionVillageAreaModel = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        }
    };
    EditEcosystemSettingsComponent.prototype.scrollToTop = function () {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    EditEcosystemSettingsComponent.prototype.saveEcosystem = function () {
        var _this = this;
        this.savingEcosystem = true;
        var ecosystemId = this.ecoSystemData.id;
        var productId = this.ecoSystemData.product.id;
        var supplierId = this.ecoSystemData.supplier.id;
        var isValid = (this.ecosystemForm.controls['currency'].value && this.ecosystemForm.controls['currency'].value > 0) && (this.ecosystemForm.controls['Country'].value && this.ecosystemForm.controls['Country'].value > 0) && (this.ecosystemForm.controls['f_project_manager'].value && this.ecosystemForm.controls['f_project_manager'].value > 0) && this.regionVillageValidationFlag && this.selectedLanguagesMultiSelect.length > 0;
        if (isValid) {
            var requestObject = {};
            requestObject['ecosystemId'] = ecosystemId;
            requestObject['supplierId'] = supplierId;
            requestObject['productId'] = productId;
            requestObject['currencyId'] = this.ecosystemForm.controls['currency'].value;
            requestObject['countryId'] = this.ecosystemForm.controls['Country'].value;
            requestObject['drmanager'] = this.ecosystemForm.controls['f_project_manager'].value;
            requestObject['ecosystemUOM'] = this.ecoSystemData.EcosystemUOM;
            for (var _i = 0, _a = this.regionVillageMasterArray; _i < _a.length; _i++) {
                var region = _a[_i];
                region['country_id'] = this.ecosystemForm.controls['Country'].value;
            }
            requestObject['region_village_area'] = this.regionVillageMasterArray;
            this.selectedLanguages = this.selectedLanguagesMultiSelect.map(function (item) { return item.id; });
            console.log('this.selectedLanguages', this.selectedLanguages);
            var languagesMappingObject = {
                "ecosystem_id": ecosystemId,
                "user_lang_id": this.selectedLanguages
            };
            requestObject['display_languages'] = languagesMappingObject;
            this.finalRequestObject = requestObject;
            this.suppliersService.updateEcosystemSettings(this.finalRequestObject).subscribe(function (result) {
                _this.savingEcosystem = false;
                if (result.msg === 'success') {
                    _this.commonService.showToast('success', 'success_master_data_updated', {});
                    var url = "/ecosystem-settings";
                    _this.router.navigate([url], { queryParams: { from: _this.fromNavId } });
                }
                _this.spinner.hide();
            }, function (error) {
                _this.spinner.hide();
                _this.savingEcosystem = false;
            }, function () { });
        }
        else {
            this.commonService.showToast('error', 'mandatory_fields', {});
            this.savingEcosystem = false;
            this.spinner.hide();
        }
    };
    // Add a unit to the gieve unit type
    EditEcosystemSettingsComponent.prototype.addUnitDynamic = function (unitTypeToAddClicked, template) {
        this.infoModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        var oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
        var unitToAdd;
        var unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
        // Find the unit type that is to be added
        var unitTypeToAdd = oldUnitsMasterArray.filter(function (one) { return one.unitType.id === unitTypeToAddClicked.id; });
        if (unitTypeToAdd && unitTypeToAdd.length > 0) {
            var index_1 = oldUnitsMasterArray.indexOf(unitTypeToAdd[0]);
            if (index_1 != null && index_1 > -1) {
                var _loop_6 = function (i) {
                    unitsDuplicateArray = unitsDuplicateArray.filter(function (item) { return item.unit_id !== oldUnitsMasterArray[index_1].unitsInProject[i].unit_id; });
                };
                // From the overall units array, remove the units in this unittype that have already been added
                for (var i = 0; i < oldUnitsMasterArray[index_1].unitsInProject.length; i++) {
                    _loop_6(i);
                }
                // From the remaining set of units, find the first unit of the type that is to be added
                var unitChanged = unitsDuplicateArray.filter(function (one) { return one.unit_type_id === unitTypeToAddClicked.id; });
                if (unitChanged && unitChanged.length > 0) {
                    unitToAdd = unitChanged[0];
                    unitToAdd.tmp_id = +this.commonService.getRandomNum().toString().slice(2, 6);
                    unitToAdd.is_default = false;
                    oldUnitsMasterArray[index_1]['unitsInProject'].push(unitToAdd);
                }
            }
        }
        // Configure the unitsMasterArray & copy
        this.unitsMasterArray = oldUnitsMasterArray;
        this.unitsMasterArray = [].concat(this.unitsMasterArray);
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
        this.setInternationalUnits();
    };
    EditEcosystemSettingsComponent.prototype.addUnitNew = function () {
        for (var i = 0; i < this.unitsMasterArray.length; i++) {
            this.unitsMasterArray[i]['unitsInProject'] = [];
            this.unitsMasterArray[i]['unitsInProject'].push(this.unitsMasterArray[i].unitOptions[0]);
            this.unitsMasterArray[i]['unitsInProject'][0]['is_default'] = true;
            this.unitsMasterArray[i]['unitsInProject'][0]['tmp_id'] = +this.commonService.getRandomNum().toString().slice(2, 6);
        }
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
        this.setInternationalUnits();
    };
    EditEcosystemSettingsComponent.prototype.addUnit = function (key) {
        var uom = new ProjectUOM();
        uom.temp_id = +this.commonService.getRandomNum().toString().slice(2, 6);
        uom.uom_type = key;
        if (this.locale == 'es') {
            uom.international_uom = key === 'Length' ? 'Metro' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litro' : 'Hectárea'));
        }
        else if (this.locale == 'en') {
            uom.international_uom = key === 'Length' ? 'Meter' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Ltr' : 'Hectare'));
        }
        else if (this.locale == 'fr') {
            uom.international_uom = key === 'Length' ? 'Mètre' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litre' : 'Hectare'));
        }
        else if (this.locale == 'ch') {
            uom.international_uom = key === 'Length' ? '仪表' : (key === 'Weight' ? '公斤' : (key === 'Volume' ? '升' : '公顷'));
        }
        else {
            uom.international_uom = key === 'Length' ? 'மீட்டர்' : (key === 'Weight' ? 'கிலோ' : (key === 'Volume' ? 'லிட்டர்' : 'ஹெக்டேர்'));
        }
        uom.is_default = this[key].length === 0;
        if (this[key].length < 1) {
            if (this.locale == 'es') {
                uom.uom = (key === 'Length' ? 'Metro' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litro' : 'Hectárea')));
            }
            else if (this.locale == 'en') {
                uom.uom = (key === 'Length' ? 'Meter' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Ltr' : 'Hectare')));
            }
            else if (this.locale == 'fr') {
                uom.uom = (key === 'Length' ? 'Mètre' : (key === 'Weight' ? 'Kg' : (key === 'Volume' ? 'Litre' : 'Hectare')));
            }
            else if (this.locale == 'ch') {
                uom.uom = (key === 'Length' ? '仪表' : (key === 'Weight' ? '公斤' : (key === 'Volume' ? '升' : '公顷')));
            }
            else {
                uom.uom = (key === 'Length' ? 'மீட்டர்' : (key === 'Weight' ? 'கிலோ' : (key === 'Volume' ? 'லிட்டர்' : 'ஹெக்டேர்')));
            }
            uom.international_value = 1;
        }
        uom.project_id = this.project.id > 0 ? this.project.id : 0;
        this[key].push(uom);
    };
    EditEcosystemSettingsComponent.prototype.deleteUnit = function (key, unit) {
        if (unit.id > 0) {
            this[key] = this[key].filter(function (x) { return x.id !== unit.id; });
        }
        else {
            this[key] = this[key].filter(function (x) { return x.temp_id !== unit.temp_id; });
        }
        if (unit.is_default) {
            this[key][0].is_default = true;
        }
    };
    EditEcosystemSettingsComponent.prototype.deleteUnit2 = function (unit, template) {
        this.infoModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        this.unitsSetDefaultFlag = false;
        var oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
        var unitTypeToDelete = oldUnitsMasterArray.filter(function (one) { return one.unitType.id === unit.unit_type_id; });
        if (unitTypeToDelete && unitTypeToDelete.length > 0) {
            var index = oldUnitsMasterArray.indexOf(unitTypeToDelete[0]);
            if (index != null && index > -1) {
                oldUnitsMasterArray[index]['unitsInProject'] = oldUnitsMasterArray[index]['unitsInProject'].filter(function (item) { return item.tmp_id !== unit.tmp_id; });
                if (oldUnitsMasterArray[index]['unitsInProject'].length > 0) {
                    oldUnitsMasterArray[index]['unitsInProject'][0]['is_default'] = true;
                }
            }
        }
        this.unitsMasterArray = oldUnitsMasterArray;
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
        this.setInternationalUnits();
    };
    EditEcosystemSettingsComponent.prototype.changeDefaultProjectUOM = function (unit, template) {
        this.infoModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        if (unit.is_default == false) {
            this.unitsSetDefaultFlag = true;
        }
        else {
            this.unitsSetDefaultFlag = false;
        }
        var oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
        var unitTypeChanged = oldUnitsMasterArray.filter(function (item) { return item.unitType.id == unit.unit_type_id; });
        if (unitTypeChanged && unitTypeChanged.length > 0) {
            var index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
            if (index != null && index > -1) {
                var unitsInProject = oldUnitsMasterArray[index]['unitsInProject'];
                for (var i = 0; i < unitsInProject.length; i++) {
                    unitsInProject[i]['is_default'] = false;
                }
                var unitChanged = unitsInProject.filter(function (item) { return item.tmp_id == unit.tmp_id; });
                if (unitChanged && unitChanged.length > 0) {
                    var index2 = unitsInProject.indexOf(unitChanged[0]);
                    unitsInProject[index2]['is_default'] = true;
                }
                oldUnitsMasterArray['unitsInProject'] = unitsInProject;
            }
        }
        this.unitsMasterArray = oldUnitsMasterArray;
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
        this.setInternationalUnits();
    };
    EditEcosystemSettingsComponent.prototype.checkChange = function (key, entry) {
        this[key] = this[key].map(function (x) {
            if (x.temp_id === entry.temp_id) {
                x.is_default = true;
            }
            else {
                x.is_default = false;
            }
            return x;
        });
    };
    EditEcosystemSettingsComponent.prototype.checkUnit = function (key, entry) {
        var _this = this;
        this[key].map(function (x) {
            if (entry.uom && entry.uom !== '' && x.uom === entry.uom && x.temp_id !== entry.temp_id) {
                entry.uom = '';
                _this.commonService.showToast('warning', 'unit_conversion_exists', {});
                // return;
            }
        });
    };
    EditEcosystemSettingsComponent.prototype.cancel = function (template) {
        if (this.ecosystemForm.dirty) {
            this.confirmCancelModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        }
        else {
            var url = "ecosystem-settings";
            this.router.navigate([url], { queryParams: { from: 3 } });
        }
    };
    EditEcosystemSettingsComponent.prototype.confirmModal = function (what) {
        if (what == 'yes') {
            if (this.confirmCancelModal) {
                this.confirmCancelModal.hide();
            }
            this.confirmCancel();
        }
        else {
            if (this.confirmCancelModal) {
                this.confirmCancelModal.hide();
            }
        }
    };
    EditEcosystemSettingsComponent.prototype.confirmCancel = function () {
        var url = "ecosystem-settings";
        this.router.navigate([url], { queryParams: { from: 3 } });
    };
    EditEcosystemSettingsComponent.prototype.GetInitials = function (string) {
        var names = string.split(' ');
        var initials = '';
        if (names.length === 1) {
            return names[0].substring(0, 1).toUpperCase();
        }
        names.forEach(function (name) {
            initials += name.substring(0, 1).toUpperCase();
        });
        return initials;
    };
    EditEcosystemSettingsComponent.prototype.productChanged = function () {
        var _this = this;
        if (this.project.product_name) {
            var obj = this.products.find(function (x) { return x.name.toLowerCase() === _this.project.product_name.toLowerCase(); });
            if (obj) {
                this.project.product_id = obj.id;
            }
            else {
                this.project.product_id = 0;
            }
        }
    };
    EditEcosystemSettingsComponent.prototype.trackById = function (index, dataItem) {
        return dataItem.id;
    };
    EditEcosystemSettingsComponent.prototype.trackByCode = function (index, dataItem) {
        return dataItem.code;
    };
    EditEcosystemSettingsComponent.prototype.trackByTempId = function (index, dataItem) {
        return dataItem.temp_id;
    };
    EditEcosystemSettingsComponent.prototype.goToProject = function () {
        var url = "projectinfo/" + this.projectId;
        this.router.navigate([url]);
    };
    EditEcosystemSettingsComponent.prototype.goToDashboard = function () {
        if (confirm('Are you sure you want to exit?')) {
            this.router.navigate(['/dashboard']);
        }
    };
    EditEcosystemSettingsComponent.prototype.trackByIndex = function (index, dataItem) {
        return index;
    };
    EditEcosystemSettingsComponent.prototype.closeModal = function (isOkay) {
        this.modalDisplay = 'none';
        this.subject.next(isOkay);
    };
    EditEcosystemSettingsComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.subject.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    EditEcosystemSettingsComponent.prototype.setManager = function (item) {
        if (item > 0) {
            this.projectManagerFlag = false;
        }
        else {
            this.projectManagerFlag = true;
        }
    };
    EditEcosystemSettingsComponent.prototype.setCountry = function (item) {
        if (item > 0) {
            this.countryFalg = false;
        }
        else {
            this.countryFalg = true;
        }
    };
    EditEcosystemSettingsComponent.prototype.setCurrency = function (item) {
        if (item > 0) {
            this.currencyFlag = false;
        }
        else {
            this.currencyFlag = true;
        }
    };
    EditEcosystemSettingsComponent.prototype.setProductType = function (args) {
        var _this = this;
        if (this.project.product_id) {
            if (this.project.product_id > 0) {
                var product = this.products.filter(function (item) { return item.id == _this.project.product_id; });
                if (product && product.length > 0) {
                    var productItem = product[0];
                    if (productItem) {
                        this.project.product_type = productItem.product_type_id;
                    }
                }
            }
            else {
                this.project.product_type = 0;
            }
        }
    };
    EditEcosystemSettingsComponent.prototype.internationalUnitChanged = function (projectUOM) {
        var unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
        var unitChanged = unitsDuplicateArray.filter(function (one) { return one.code == projectUOM.code; });
        if (unitChanged && unitChanged.length > 0) {
            var index = unitsDuplicateArray.indexOf(unitChanged[0]);
            if (index != null && index > -1) {
                unitsDuplicateArray[index]['international_value'] = projectUOM['international_value'];
                this.unitsDuplicate = JSON.stringify(unitsDuplicateArray);
            }
        }
    };
    EditEcosystemSettingsComponent.prototype.selectUnit = function (projectUOM, projectUOMIndex) {
        var unitTypeChanged = this.unitsMasterArray.filter(function (one) { return one.unitType.id == projectUOM.unit_type_id; });
        if (unitTypeChanged && unitTypeChanged.length > 0) {
            var index = this.unitsMasterArray.indexOf(unitTypeChanged[0]);
            if (index != null && index > -1) {
                var ecosystemUOMChanged = this.unitsMasterArray[index]['unitsInProject'].filter(function (one) { return one.code == projectUOM.code; });
                if (ecosystemUOMChanged && ecosystemUOMChanged.length > 0) {
                    var index2 = this.unitsMasterArray[index]['unitsInProject'].indexOf(ecosystemUOMChanged[0]);
                    if (index2 != null && index2 > -1) {
                        var unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
                        var unitSelected = unitsDuplicateArray.filter(function (one) { return one.code == projectUOM.code; });
                        if (unitSelected && unitSelected.length > 0) {
                            var index3 = unitsDuplicateArray.indexOf(unitSelected[0]);
                            var unitToSet = void 0;
                            unitToSet = unitsDuplicateArray[index3];
                            for (var i = 0; i < this.unitsMasterArray[index]['unitsInProject'].length; i++) {
                                this.unitsMasterArray[index]['unitsInProject'][i]['is_default'] = false;
                            }
                            this.unitsMasterArray[index]['unitsInProject'][index2] = unitsDuplicateArray[index3];
                            this.unitsMasterArray[index]['unitsInProject'][index2]['is_default'] = true;
                        }
                    }
                }
                var unitChanged = this.unitsMasterArray[index]['unitOptions'].filter(function (one) { return one.id == projectUOM.id; });
                if (unitChanged && unitChanged.length > 0) {
                    var index4 = this.unitsMasterArray[index]['unitOptions'].indexOf(unitChanged[0]);
                    if (index4 != null && index4 > -1) {
                        var unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
                        var unitSelected = unitsDuplicateArray.filter(function (one) { return one.id == projectUOM.id; });
                        if (unitSelected && unitSelected.length > 0) {
                            var index5 = unitsDuplicateArray.indexOf(unitSelected[0]);
                            this.unitsMasterArray[index]['unitOptions'][index4] = unitsDuplicateArray[index5];
                        }
                    }
                }
            }
        }
        this.unitsMasterArray = Object.assign([], this.unitsMasterArray);
        this.setInternationalUnits();
    };
    EditEcosystemSettingsComponent.prototype.selectUnit2 = function (projectUOM, projectUOMIndex, template) {
        // Find the unit type & unitsInProject items that have been changed
        this.infoModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true });
        var oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
        var unitTypeChanged = oldUnitsMasterArray.filter(function (one) { return one.unitType.id == projectUOM.unit_type_id; });
        if (unitTypeChanged && unitTypeChanged.length > 0) {
            var index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
            if (index != null && index > -1) {
                var ecosystemUOMChanged = oldUnitsMasterArray[index]['unitsInProject'].filter(function (one) { return one.tmp_id == projectUOM.tmp_id; }); // code changed to tmp_id
                if (ecosystemUOMChanged && ecosystemUOMChanged.length > 0) {
                    var index2 = oldUnitsMasterArray[index]['unitsInProject'].indexOf(ecosystemUOMChanged[0]);
                    if (index2 != null && index2 > -1) {
                        var unitsDuplicateArray = JSON.parse(this.unitsDuplicate);
                        // this is the new unit selected
                        var unitSelected = unitsDuplicateArray.filter(function (one) { return one.code == projectUOM.code; });
                        if (unitSelected && unitSelected.length > 0) {
                            var index3 = unitsDuplicateArray.indexOf(unitSelected[0]);
                            unitsDuplicateArray[index3]['tmp_id'] = projectUOM.tmp_id;
                            for (var i = 0; i < oldUnitsMasterArray[index]['unitsInProject'].length; i++) {
                                oldUnitsMasterArray[index]['unitsInProject'][i]['is_default'] = false;
                            }
                            oldUnitsMasterArray[index]['unitsInProject'][index2] = unitsDuplicateArray[index3];
                            oldUnitsMasterArray[index]['unitsInProject'][index2]['is_default'] = true;
                        }
                    }
                }
            }
        }
        this.unitsMasterArray = oldUnitsMasterArray;
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
        this.setInternationalUnits();
    };
    EditEcosystemSettingsComponent.prototype.internationalUnitValueChanged = function (unit) {
        var oldUnitsMasterArray = JSON.parse(this.unitsMasterArrayCopy);
        var unitTypeChanged = oldUnitsMasterArray.filter(function (item) { return item.unitType.id == unit.unit_type_id; });
        if (unitTypeChanged && unitTypeChanged.length > 0) {
            var index = oldUnitsMasterArray.indexOf(unitTypeChanged[0]);
            if (index != null && index > -1) {
                var unitsInProject = oldUnitsMasterArray[index]['unitsInProject'];
                var unitChanged = unitsInProject.filter(function (item) { return item.tmp_id == unit.tmp_id; });
                if (unitChanged && unitChanged.length > 0) {
                    var index1 = unitsInProject.indexOf(unitChanged[0]);
                    unitsInProject[index1]['international_value'] = unit['international_value'];
                    oldUnitsMasterArray['unitsInProject'] = unitsInProject;
                    this.unitsMasterArray = Object.assign([], oldUnitsMasterArray);
                    this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
                    this.setInternationalUnits();
                }
            }
        }
    };
    EditEcosystemSettingsComponent.prototype.updateUnitsMasterArrayCopy = function (args) {
        this.unitsMasterArrayCopy = JSON.stringify(this.unitsMasterArray);
    };
    EditEcosystemSettingsComponent.prototype.setInternationalUnits = function () {
        var _this = this;
        var _loop_7 = function (i) {
            var _loop_8 = function (j) {
                var intValue = this_2.unittypes.filter(function (item) { return item.international_unit_to_save == _this.unitsMasterArray[i]['unitsInProject'][j]['international_unit']; });
                if (intValue && intValue.length > 0) {
                    this_2.unitsMasterArray[i]['unitsInProject'][j]['international_unit'] = intValue[0]['international_unit'];
                }
            };
            for (var j = 0; j < this_2.unitsMasterArray[i]['unitsInProject'].length; j++) {
                _loop_8(j);
            }
        };
        var this_2 = this;
        for (var i = 0; i < this.unitsMasterArray.length; i++) {
            _loop_7(i);
        }
    };
    EditEcosystemSettingsComponent.prototype.addRegion = function (currentVal, id) {
        var _a;
        var regionMasterArray = [];
        if (!this.regionVillageMasterCopy || this.regionVillageMasterCopy.length == 0) {
            regionMasterArray = [];
        }
        var regionObject = {
            "region_name": "",
            "isregion": true,
            "isvilagearea": false,
            "region_villages": [],
            "status": '',
            "id": 0
        };
        regionObject['tmp_id'] = +this.commonService.getRandomNum().toString().slice(2, 6);
        regionMasterArray.push(regionObject);
        (_a = this.regionVillageMasterArray).push.apply(_a, regionMasterArray);
        this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
        this.regionVillageUpdateButtonDisable(regionObject, 'add');
    };
    EditEcosystemSettingsComponent.prototype.addVillageArea = function (villageArea, regionVal) {
        var villageAreaObject = {
            "village_name": '',
            "type": "villagearea",
            "isvilagearea": true,
            "isregion": false,
            "actions": "yes",
            "farmer_reg": [],
            "region_id": regionVal.id,
            "id": 0
        };
        villageAreaObject['tmp_id'] = +this.commonService.getRandomNum().toString().slice(2, 6);
        var newObject = Object.assign({}, villageAreaObject);
        var oldRegionVillageMasterArray = JSON.parse(this.regionVillageMasterCopy);
        var newArray = oldRegionVillageMasterArray;
        for (var _i = 0, newArray_1 = newArray; _i < newArray_1.length; _i++) {
            var x = newArray_1[_i];
            if (x.tmp_id === regionVal.tmp_id) {
                x.region_villages.push(newObject);
            }
        }
        this.regionVillageMasterArray = newArray;
        this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
        this.regionVillageUpdateButtonDisable(newObject, 'add');
    };
    EditEcosystemSettingsComponent.prototype.deleteRegion = function (regionVal) {
        var oldRegionVillageMasterArray = JSON.parse(this.regionVillageMasterCopy);
        var regionArray = oldRegionVillageMasterArray.filter(function (item) { return item.tmp_id !== regionVal.tmp_id; });
        var x;
        for (var _i = 0, regionArray_1 = regionArray; _i < regionArray_1.length; _i++) {
            var item = regionArray_1[_i];
            if (item.region_villages && item.region_villages.length > 0) {
            }
            else {
                x = true;
            }
        }
        if (x) {
            this.regionVillageCheck = true;
        }
        this.regionVillageCheck = false;
        this.regionVillageMasterArray = regionArray;
        this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
        this.regionVillageValidationFlag = true;
    };
    EditEcosystemSettingsComponent.prototype.deleteVillageArea = function (regionVal, villageAreaVal) {
        var oldRegionVillageMasterArray = JSON.parse(this.regionVillageMasterCopy);
        var villageAreaToDelete = oldRegionVillageMasterArray.filter(function (item) { return item.tmp_id == regionVal.tmp_id; });
        if (villageAreaToDelete && villageAreaToDelete.length > 0) {
            var index = oldRegionVillageMasterArray.indexOf(villageAreaToDelete[0]);
            if (index != null && index > -1) {
                var villageAreaArray = oldRegionVillageMasterArray[index]['region_villages'];
                var villageAreaChanged = villageAreaArray.filter(function (item) { return item.tmp_id !== villageAreaVal.tmp_id; });
                oldRegionVillageMasterArray[index]['region_villages'] = villageAreaChanged;
                this.regionVillageMasterArray = oldRegionVillageMasterArray;
                this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
                this.regionVillageValidationFlag = true;
                var x = void 0;
                for (var _i = 0, villageAreaArray_1 = villageAreaArray; _i < villageAreaArray_1.length; _i++) {
                    var item = villageAreaArray_1[_i];
                    if (item.village_name !== '' || item.village_name.length > 0) {
                    }
                    else {
                        x = true;
                    }
                }
                if (x) {
                    this.regionVillageCheck = true;
                }
            }
            this.addVillageAreaStringsArray = this.addVillageAreaStringsArray.filter(function (item) { return item.tmp_id !== villageAreaVal.tmp_id; });
            this.regionVillageUpdateButtonDisable(villageAreaToDelete[0], 'delete');
        }
    };
    EditEcosystemSettingsComponent.prototype.updateRegionMasterArrayCopy = function (event, valType, item) {
        console.log("item ", item);
        console.log("event ", event);
        var villageAreaArray = [];
        if (item.village_name.length > 0) {
            villageAreaArray = item.village_name;
        }
        if (valType == 'region') {
            if (this.addRegionStingsArray.length > 0) {
                for (var _i = 0, _a = this.addRegionStingsArray; _i < _a.length; _i++) {
                    var stringItem = _a[_i];
                    if (stringItem.toLowerCase() == event.target.value.trim().toLowerCase()) {
                        event.target.value = '';
                    }
                    else {
                        if (event.target.value !== '' || event.target.value.length > 0) {
                            this.addRegionStingsArray.push(event.target.value.trim());
                            this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
                            break;
                        }
                        else {
                            this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
                        }
                    }
                }
            }
            else {
                this.addRegionStingsArray.push(event.target.value.trim());
                this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
            }
        }
        else {
            if (villageAreaArray.length > 0) {
                if (this.addVillageAreaStringsArray.length > 0) {
                    var _loop_9 = function (stringItem) {
                        var villageArray = villageAreaArray.filter(function (item) { return item.village_name.toLowerCase() == event.target.value.toLowerCase() && item.tmp_id != stringItem.tmp_id; })[0];
                        if (villageArray != undefined) {
                            if (stringItem.tmp_id == villageArray.tmp_id) {
                                if (event.target.value !== '' || event.target.value.length > 0) {
                                    if (villageArray.village_name.toLowerCase() === event.target.value.toLowerCase()) {
                                        stringItem.value = event.target.value.trim();
                                        this_3.regionVillageMasterCopy = JSON.stringify(this_3.regionVillageMasterArray);
                                    }
                                    else {
                                        this_3.regionVillageMasterCopy = JSON.stringify(this_3.regionVillageMasterArray);
                                        stringItem.value = event.target.value.trim();
                                    }
                                }
                                else {
                                    stringItem.value = event.target.value.trim();
                                }
                            }
                            else {
                                if (stringItem.value.toLowerCase() == villageArray.village_name.toLowerCase()) {
                                    villageArray.village_name = event.target.value.trim();
                                    for (var i = 0; i < this_3.regionVillageMasterArray.length; i++) {
                                        for (var j = 0; j < this_3.regionVillageMasterArray[i].village_name.length; j++) {
                                            if (this_3.regionVillageMasterArray[i].village_name[j].tmp_id == villageArray.tmp_id) {
                                                this_3.regionVillageMasterArray[i].village_name[j].village_name = event.target.value.trim();
                                            }
                                        }
                                    }
                                    event.target.value = '';
                                    this_3.regionVillageMasterCopy = JSON.stringify(this_3.regionVillageMasterArray);
                                    this_3.regionVillageCheck = true;
                                }
                                else {
                                    var newObject = {
                                        'tmp_id': villageArray.tmp_id,
                                        'value': event.target.value.trim()
                                    };
                                    this_3.addVillageAreaStringsArray.push(newObject);
                                    //break;
                                }
                            }
                        }
                    };
                    var this_3 = this;
                    for (var _b = 0, _c = this.addVillageAreaStringsArray; _b < _c.length; _b++) {
                        var stringItem = _c[_b];
                        _loop_9(stringItem);
                    }
                }
                else {
                    if (event.target.value !== '' || event.target.value !== undefined) {
                        var villageArray = villageAreaArray.filter(function (item) { return item.village_name.toLowerCase() == event.target.value.toLowerCase(); })[0];
                        var newObject = {
                            'tmp_id': villageArray.tmp_id,
                            'value': event.target.value.trim()
                        };
                        this.addVillageAreaStringsArray.push(newObject);
                        this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
                    }
                    else {
                        var villageArray = villageAreaArray.filter(function (item) { return item.village_name === event.target.value; })[0];
                        var newObject = {
                            'tmp_id': villageArray.tmp_id,
                            'value': event.target.value.trim()
                        };
                        this.addVillageAreaStringsArray.push(newObject);
                        this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
                    }
                }
            }
        }
    };
    EditEcosystemSettingsComponent.prototype.ecosystemRevertBack = function () {
        var url = "/ecosystem-settings";
        this.router.navigate([url], { queryParams: { from: this.fromNavId } });
    };
    EditEcosystemSettingsComponent.prototype.regionVillageAreaValidationFun = function (event, itemType, currentItem, innerItem) {
        if (currentItem.isregion) {
            var x = JSON.parse(this.regionVillageMasterCopy);
            for (var i = 0; i < x.length; i++) {
                if (x[i].tmp_id == currentItem.tmp_id) {
                    x[i] = currentItem;
                }
            }
            this.regionVillageMasterArray = x;
            this.regionVillageMasterCopy = JSON.stringify(this.regionVillageMasterArray);
        }
        if (event.target.value !== '' || event.target.value.length > 0) {
            this.regionVillageValidationFlag = true;
        }
        else {
            this.regionVillageValidationFlag = false;
        }
        this.regionVillageUpdateButtonDisable(currentItem, 'add');
    };
    EditEcosystemSettingsComponent.prototype.regionVillageUpdateButtonDisableOld = function (currentItem, fromType) {
        console.log("currentItem ", currentItem);
        if (this.regionVillageMasterArray.length > 0) {
            if (currentItem.name != '' || currentItem.name.length > 0) {
                if (currentItem.village_name && currentItem.village_name.length > 0) {
                    for (var i = 0; i < currentItem.village_name.length; i++) {
                        if (currentItem.village_name[i].village_name == '' || currentItem.village_name[i].village_name == undefined) {
                            this.regionVillageCheck = true;
                            //break;
                        }
                        else {
                            this.regionVillageCheck = false;
                        }
                    }
                }
                else {
                    this.regionVillageCheck = true;
                }
            }
            else {
                this.regionVillageCheck = true;
            }
        }
        else {
            this.regionVillageCheck = false;
        }
    };
    EditEcosystemSettingsComponent.prototype.infoModalClose = function () {
        if (this.infoModal) {
            this.infoModal.hide();
        }
    };
    EditEcosystemSettingsComponent.prototype.regionVillageUpdateButtonDisable = function (currentItem, fromType) {
        if (this.regionVillageMasterArray.length > 0) {
            for (var j = 0; j < this.regionVillageMasterArray.length; j++) {
                var currentItem_1 = this.regionVillageMasterArray[j];
                if (currentItem_1.region_name != '' || currentItem_1.region_name.length > 0) {
                    if (currentItem_1.region_villages && currentItem_1.region_villages.length > 0) {
                        for (var i = 0; i < currentItem_1.region_villages.length; i++) {
                            if (currentItem_1.region_villages[i].village_name == '' || currentItem_1.region_villages[i].village_name == undefined) {
                                this.regionVillageCheck = true;
                                break;
                            }
                            else {
                                this.regionVillageCheck = false;
                            }
                        }
                    }
                    else {
                        this.regionVillageCheck = true;
                    }
                }
                else {
                    this.regionVillageCheck = true;
                }
            }
        }
        else {
            this.regionVillageValidationFlag = false;
        }
    };
    //popup added
    EditEcosystemSettingsComponent.prototype.confirmModal3 = function (what) {
        if (what == 'yes') {
            if (this.confirmRegionVillageAreaModel) {
                this.confirmRegionVillageAreaModel.hide();
            }
            this.saveEcosystem();
        }
        else {
            if (this.confirmRegionVillageAreaModel) {
                this.confirmRegionVillageAreaModel.hide();
            }
        }
    };
    EditEcosystemSettingsComponent.prototype.onMultiSelect = function (item) {
    };
    EditEcosystemSettingsComponent.prototype.onSelectAll = function (items) {
    };
    EditEcosystemSettingsComponent.prototype.onItemSelect = function (item) {
    };
    EditEcosystemSettingsComponent.prototype.onDeSelect = function (item) {
    };
    EditEcosystemSettingsComponent.prototype.onDeSelectAll = function (items) {
    };
    EditEcosystemSettingsComponent.prototype.addItem = function (items) {
    };
    EditEcosystemSettingsComponent.prototype.removeItem = function (items, key, yesNo) {
    };
    return EditEcosystemSettingsComponent;
}());
export { EditEcosystemSettingsComponent };
