import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { mapTo } from 'rxjs/operators';
import { fromEvent, merge, of } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { FarmerService } from '../../services/farmer.service';
import { SuppliersService } from '../../services/suppliers.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
var EcosystemsettingsComponent = /** @class */ (function () {
    function EcosystemsettingsComponent(route, commonService, router, farmerService, projectService, translate, toastrService, modalService, localeService, formBuilder, revisionService, spinner, userService, mdmService) {
        var _this = this;
        this.route = route;
        this.commonService = commonService;
        this.router = router;
        this.farmerService = farmerService;
        this.projectService = projectService;
        this.translate = translate;
        this.toastrService = toastrService;
        this.modalService = modalService;
        this.localeService = localeService;
        this.formBuilder = formBuilder;
        this.revisionService = revisionService;
        this.spinner = spinner;
        this.userService = userService;
        this.mdmService = mdmService;
        this.projectId = 0;
        this.farmers = [];
        this.productType = [];
        this.managers = [];
        this.isAscending = true;
        this.isAscendingId = true;
        this.isAscendingName = true;
        this.isAscendingTel = true;
        this.isAscendingDate = true;
        this.sortLabel = '';
        this.showSpinner = false;
        this.isFieldAgent = false;
        this.isManager = false;
        this.isDeeproots = false;
        this.farmerFilterString = { name: '' };
        this.page = 1;
        this.itemsPerPage = 10;
        this.isInIDBInThisProject = [];
        this.showSyncSuccessAlert = false;
        this.olderSnapshots = [];
        this.minDate = new Date();
        this.noSnapshotsInProject = false;
        this.fetchingSnapshotInfo = true;
        this.disableCreateSnapshot = false;
        this.disableCloseSnapshot = false;
        this.registeringFarmer = false;
        this.registerAnotherFarmer = false;
        this.fileToUpload = null;
        this.photoModel = '';
        this.oldSnapshotId = 0;
        this.arrSnapshotRevisions = [];
        this.snapshotRevision = [];
        this.loadedEcosystems = false;
        this.searchText = '';
        this.currentUser = this.userService.getCurrentUser();
        this.typeOfAdministration = '';
        this.route.paramMap.subscribe(function (params) {
            _this.id = params.get('id');
        });
        this.route.queryParamMap.subscribe(function (queryParams) {
            var qpObject = tslib_1.__assign({}, queryParams.keys, queryParams);
            _this.fromNavId = qpObject['params']['from'];
            _this.translate.get('typeAdminTextArr').subscribe(function (val) {
                _this.typeOfAdministrationArr = val;
            }, function (err) { return console.log('err', err); });
            if (_this.fromNavId == 2) {
                _this.typeOfAdministration = _this.typeOfAdministrationArr[0];
            }
            else if (_this.fromNavId == 3) {
                _this.typeOfAdministration = _this.typeOfAdministrationArr[1];
            }
        });
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
    }
    EcosystemsettingsComponent.prototype.snapshotChanged = function () {
        var _this = this;
        this.snapshotRevision = this.arrSnapshotRevisions.filter(function (x) { return x.snapshot_id_ref === _this.oldSnapshotId; });
    };
    EcosystemsettingsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mdmCode, code, requests;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scrollToTop();
                        mdmCode = "ECOSYSTEM_MDM";
                        this.ecosystemId = localStorage.getItem('ecosystem_id');
                        this.projectId = +this.route.snapshot.params['id'];
                        code = this.commonService.checkRole();
                        if (code == 'FAG') {
                            this.isFieldAgent = true;
                        }
                        else if (code === 'PMG') {
                            this.isManager = true;
                        }
                        else if (code === 'SYS1') {
                            this.isDeeproots = true;
                        }
                        if (!navigator.onLine) return [3 /*break*/, 1];
                        this.spinner.show();
                        this.mdmService.getEcoSystemSettingsData(mdmCode, this.ecosystemId).subscribe(function (response) {
                            if (response.status == 'success') {
                                _this.ecoSystemSettingsData = response.data;
                                _this.loadedEcosystems = true;
                                _this.spinner.hide();
                            }
                            else {
                                _this.ecoSystemSettingsData = [];
                                _this.spinner.hide();
                            }
                        });
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, Promise.all([
                            this.projectService.GetProjectsDataDashboard().toPromise()
                        ])];
                    case 2:
                        requests = _a.sent();
                        this.project = requests[0].data.find(function (x) { return x.id === _this.projectId; });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EcosystemsettingsComponent.prototype.getProjectData = function () {
        var _this = this;
        this.projectService.GetProject(this.id).subscribe(function (response) {
            _this.projectData = response.data;
        });
    };
    EcosystemsettingsComponent.prototype.goToDashboard = function () {
        this.router.navigate(['dashboard']);
    };
    EcosystemsettingsComponent.prototype.scrollToTop = function () {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    };
    EcosystemsettingsComponent.prototype.ngOnDestroy = function () {
        this.showSpinner = false;
        clearInterval(this.getSnapshotInterval);
    };
    EcosystemsettingsComponent.prototype.goToEditProject = function () {
        if (navigator.onLine) {
            var url = "setup/" + this.project.id;
            this.router.navigate([url]);
        }
        else {
            this.toastrService.warning('You cannot edit a project while offline');
        }
    };
    EcosystemsettingsComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    EcosystemsettingsComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        if (sortBy === 'ecosystem_name') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.ecoSystemSettingsData.sort(function (a, b) {
                if (a.projectname.toLowerCase() < b.projectname.toLowerCase()) {
                    return 1;
                }
                if (a.projectname.toLowerCase() > b.projectname.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'marker_description') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.ecoSystemSettingsData.sort(function (a, b) {
                if (a.description.toLowerCase() < b.description.toLowerCase()) {
                    return 1;
                }
                if (a.description.toLowerCase() > b.description.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'status') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.ecoSystemSettingsData.sort(function (a, b) {
                if (a.projectstatus.toLowerCase() < b.projectstatus.toLowerCase()) {
                    return 1;
                }
                if (a.projectstatus.toLowerCase() > b.projectstatus.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'upd_date') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.ecoSystemSettingsData.sort(function (a, b) {
                if (a.suppliername.toLowerCase() < b.suppliername.toLowerCase()) {
                    return 1;
                }
                if (a.suppliername.toLowerCase() > b.suppliername.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    EcosystemsettingsComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        if (sortBy === 'ecosystem_name') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.ecoSystemSettingsData.sort(function (a, b) {
                if (a.projectname.toLowerCase() > b.projectname.toLowerCase()) {
                    return 1;
                }
                if (a.projectname.toLowerCase() < b.projectname.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'marker_description') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.ecoSystemSettingsData.sort(function (a, b) {
                if (a.description.toLowerCase() > b.description.toLowerCase()) {
                    return 1;
                }
                if (a.description.toLowerCase() < b.description.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'status') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.ecoSystemSettingsData.sort(function (a, b) {
                if (a.projectstatus.toLowerCase() > b.projectstatus.toLowerCase()) {
                    return 1;
                }
                if (a.projectstatus.toLowerCase() < b.projectstatus.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'upd_date') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.ecoSystemSettingsData.sort(function (a, b) {
                if (a.suppliername.toLowerCase() > b.suppliername.toLowerCase()) {
                    return 1;
                }
                if (a.suppliername.toLowerCase() < b.suppliername.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
    };
    EcosystemsettingsComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['ecosystem_name', 'marker_description', 'status', 'upd_date'];
        var finalObj = {};
        if (this.ecoSystemSettingsData === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    EcosystemsettingsComponent.prototype.goToEditEcosystemSettings = function (id) {
        var url = "/ecosystem-settings/edit/" + id;
        this.router.navigate([url], { queryParams: { from: this.fromNavId } });
    };
    EcosystemsettingsComponent.prototype.exportToExcel = function () {
        var _this = this;
        this.mdmService.exportData('ECOSYSTEM_SETTINGS_MDM', this.ecoSystemSettingsData.length == 1 ? this.ecoSystemSettingsData[0].id : undefined).subscribe(function (result) {
            console.log('result', result);
            var contentType = "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheets";
            var blob = new Blob([result], { type: contentType });
            var linkdownload = document.createElement('a');
            linkdownload.href = URL.createObjectURL(blob);
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            var today1 = dd + '-' + mm + '-' + yyyy;
            _this.translate.get('ecosystem_settings').subscribe(function (val) {
                linkdownload.download = 'P2F-Roots_' + val + '_ExcelExport_' + today1 + '.xlsx';
                linkdownload.style.display = 'none';
                document.body.appendChild(linkdownload);
                linkdownload.click();
            }, function (err) { return console.log('err', err); });
        }, function (err) { return console.log('err', err); });
    };
    return EcosystemsettingsComponent;
}());
export { EcosystemsettingsComponent };
