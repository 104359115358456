import * as tslib_1 from "tslib";
import { OnInit, TemplateRef, AfterViewInit, OnDestroy, ElementRef, Renderer } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { UserService } from '../../services/user.service';
import { RevisionService } from '../../services/revision.service';
import { SurveyService } from '../../services/survey.service';
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { CampaignService } from '../../services/campaign.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataConsentService } from '../../services/data-consent.service';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import Compressor from 'compressorjs';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ConsReportService } from '../../services/consolidated-reports.service';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
var ManagerpageComponent = /** @class */ (function () {
    function ManagerpageComponent(route, projectService, commonService, farmerService, userService, revisionService, router, toastr, formBuilder, modalService, activeRoute, spinner, el, renderer, translate, loc, document, deviceService, surveyService, dynamicQuestionnaireService, campaignService, dataConsentService, sanitizer, consReportService, http) {
        var _this = this;
        this.route = route;
        this.projectService = projectService;
        this.commonService = commonService;
        this.farmerService = farmerService;
        this.userService = userService;
        this.revisionService = revisionService;
        this.router = router;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.activeRoute = activeRoute;
        this.spinner = spinner;
        this.el = el;
        this.renderer = renderer;
        this.translate = translate;
        this.loc = loc;
        this.document = document;
        this.deviceService = deviceService;
        this.surveyService = surveyService;
        this.dynamicQuestionnaireService = dynamicQuestionnaireService;
        this.campaignService = campaignService;
        this.dataConsentService = dataConsentService;
        this.sanitizer = sanitizer;
        this.consReportService = consReportService;
        this.http = http;
        this.registeringFarmer = false;
        this.registerAnotherFarmer = false;
        this.fileToUpload = null;
        this.photoModel = '';
        this.projects = [];
        this.isFieldAgent = true;
        this.isManager = false;
        this.isDeeproots = false;
        this.productType = [];
        this.managers = [];
        this.showDetailData1 = false;
        this.showingProjectTables = [];
        this.projectFilter = { name: '' };
        this.confirmModal = true;
        this.unsubscribe = new Subject();
        this.order = 'project.name';
        this.projectNames = [];
        this.isAscending = true;
        this.sortLabel = '';
        // navArrowLeftIcons:boolean = false;
        // navArrowRightIcons:boolean = true;
        this.noProjectsMsg = false;
        this.registerFarmerGender = 3;
        // @ViewChild('namedsf', { read: ElementRef })firstNameTextbox: ElementRef;
        this.ageSliderOptions = {
            floor: 18,
            ceil: 99
        };
        this.farmerAgeSliderValue = 18;
        this.currentUser = this.userService.getCurrentUser();
        this.loadedProjects = false;
        // flag set
        this.flagEn = false;
        this.flagEs = false;
        this.flagFr = false;
        this.flagZh = false;
        this.flagTa = false;
        this.flagMg = false;
        this.flagHi = false;
        this.flagId = false;
        this.villageAreaList = [];
        this.educationLevelData = [];
        this.signEmpty = false;
        this.changeEvent = true;
        this.consentTemplateAvailable = false;
        this.percCompletion = 0;
        this.totalProjects = 0;
        this.projectsLoaded = 0;
        this.currentPerc = 0;
        this.ecosystemId = null;
        this.online$ = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.currentSearchClearStatus = this.commonService.currentSearchClearStatus.subscribe(function (message) {
            // console.log('message', message);
            if (message == 'blank') {
                _this.removeSearchFilter();
            }
        });
        this.translate.get('file_upload_component_default_txt').subscribe(function (result) {
            _this.noFileChosenDefault = result; // default text for the file input component
        });
        this.translate.get('not_available_when_offline').subscribe(function (result) {
            _this.notAvailableWhenOfflineTxt = result; // default text for the file input component
        });
        this.translate.get('icon_title_male').subscribe(function (result) {
            _this.titleValPosMale = result;
        });
        this.translate.get('icon_title_female').subscribe(function (result) {
            _this.titleValPosFemale = result;
        });
        var localLang = this.loc.prepareExternalUrl('').replace(/\//g, '');
        if (localLang === 'en') {
            this.flagEn = true;
        }
        else if (localLang === 'es') {
            this.flagEs = true;
        }
        else if (localLang === 'fr') {
            this.flagFr = true;
        }
        else if (localLang === 'zh') {
            this.flagZh = true;
        }
        else if (localLang === 'ta') {
            this.flagTa = true;
        }
        else if (localLang === 'mg') {
            this.flagMg = true;
        }
        else if (localLang === 'hi') {
            this.flagHi = true;
        }
        else if (localLang === 'id') {
            this.flagId = true;
        }
        else {
            this.flagEn = true;
        }
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();
    }
    ;
    ManagerpageComponent.prototype.onKeydownHandler = function (event, registerFarmerForm) {
        this.declineRegisterFarmerPrompt(this.registerFarmerForm);
    };
    ManagerpageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activeRoute.queryParamMap.subscribe(function (queryParams) {
            var qpObject = tslib_1.__assign({}, queryParams.keys, queryParams);
            _this.pId = qpObject['params']['pId'];
            var filterValue = qpObject['params']['filter'];
            if (filterValue && filterValue.length > 0) {
                _this.searchText = filterValue;
            }
            _this.ecosystemScopeId = localStorage.getItem('ecosystem_id');
            var ecosystemId = qpObject['params']['ecosystemId'];
            var ecosystemName = qpObject['params']['ecosystemName'];
            if (ecosystemId && ecosystemId.length > 0) {
                _this.ecosystemScopeId = ecosystemId;
                localStorage.setItem('ecosystem_id', ecosystemId);
                localStorage.setItem('ecosystem_name', ecosystemName);
            }
        });
        if (this.currentUser.role.id === 3) {
            if (!localStorage.getItem('ecosystem_id') || localStorage.getItem('ecosystem_id') === '0') {
                this.commonService.showToast('warning', 'select_ecosystem', {});
                this.router.navigate(['/ecosystems']);
            }
        }
        if (this.currentUser.role.id === 2) {
            if (!localStorage.getItem('ecosystem_id')) {
                this.commonService.showToast('warning', 'select_ecosystem_or_all', {});
                this.router.navigate(['/ecosystems']);
            }
        }
        var code = this.commonService.checkRole();
        if (code == 'FAG') {
            this.isFieldAgent = true;
        }
        else if (code == 'PMG') {
            this.isManager = true;
        }
        else if (code == 'SYS1') {
            this.isDeeproots = true;
        }
        this.productType = this.route.snapshot.data.dashboard ? this.route.snapshot.data.dashboard.productType.data : undefined;
        this.managers = this.route.snapshot.data.dashboard ? this.route.snapshot.data.dashboard.managers.data : undefined;
        this.master = this.route.snapshot.data.project.master.data;
        this.sex = this.master.filter(function (x) { return x.item_tab === 'farmer_reg' && x.item_code === 'sex'; });
        var farmerQuestions = [];
        for (var i = 0; i < this.master.length; i++) {
            if (this.master[i].item_tab == 'farmer_reg') {
                farmerQuestions.push(this.master[i]);
            }
        }
        for (var i = 0; i < farmerQuestions.length; i++) {
            if (farmerQuestions[i].item_code == 'education_level') {
                this.educationLevelData.push(farmerQuestions[i]);
            }
        }
        this.displayProjects();
        this.sortLabel = 'A-Z';
        this.deviceInfoObj = {
            browser: {
                name: this.deviceInfo.browser,
                version: this.deviceInfo.browser_version
            },
            os: {
                name: this.deviceInfo.os,
                version: this.deviceInfo.os_version
            }
        };
    };
    ManagerpageComponent.prototype.toggle1 = function (id) {
        if (this.showingProjectTables.indexOf(id) > -1) {
            this.showingProjectTables = this.showingProjectTables.filter(function (item) { return item != id; });
        }
        else {
            this.showingProjectTables.push(id);
        }
    };
    ManagerpageComponent.prototype.filterChange = function () {
        this.router.navigate([], {
            relativeTo: this.activeRoute,
            queryParams: {
                filter: this.searchText.trim()
            },
            replaceUrl: true,
        });
    };
    ManagerpageComponent.prototype.removeSearchFilter = function () {
        this.searchText = '';
        this.pId = undefined;
        this.displayProjects();
    };
    ManagerpageComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['name'];
        var finalObj = {};
        if (this.projects === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    ManagerpageComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var toast;
        if (navigator.onLine) {
            setTimeout(function () {
                toast = _this.commonService.showToast('warning', 'caching_warning', { disableTimeOut: true });
            });
            this.commonService.updateCache().subscribe(function (val) {
                // const snapshots = val[4];
                // if (snapshots.status == 'success') {
                //   this.commonService.allSnapshots = val[4].data;
                //   localStorage.setItem('snapshotsFetchedAt', new Date().getTime().toString());
                // }
                if (toast) {
                    toast.toastRef.manualClose();
                }
                else {
                    _this.toastr.clear();
                }
                _this.showCacheMessage2 = true;
                _this.showDataCachedMessage();
            }, function (error) {
                _this.spinner.hide();
                if (toast) {
                    toast.toastRef.manualClose();
                }
                else {
                    _this.toastr.clear();
                }
                setTimeout(function () { return _this.commonService.showToast('error', 'generic_error', {}); }, 1000);
            });
        }
    };
    ManagerpageComponent.prototype.sort = function () {
        this.isAscending ? this.ascendic() : this.descendic();
    };
    ManagerpageComponent.prototype.ascendic = function () {
        this.isAscending = false;
        this.sortLabel = 'Z-A';
        this.projects.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return 1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return -1;
            }
            return 0;
        });
    };
    ManagerpageComponent.prototype.descendic = function () {
        this.isAscending = true;
        this.sortLabel = 'A-Z';
        this.projects.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            return 0;
        });
    };
    ManagerpageComponent.prototype.goTo = function (where) {
        if (navigator.onLine) {
            this.router.navigate(["/" + where]);
        }
        else {
            if (where === 'setup') {
                this.commonService.showToast('warning', 'offline_project_warning', {});
            }
        }
    };
    ManagerpageComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.currentSearchClearStatus.unsubscribe();
    };
    ManagerpageComponent.prototype.openRegisterFarmerModal = function (template, id) {
        this.projectIdRegisteringFarmer = id;
        this.registerFarmerModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    ManagerpageComponent.prototype.registerFarmer = function () {
        var _this = this;
        this.registeringFarmer = true;
        var name = this.registerFarmerForm['value'].name;
        var age = this.registerFarmerForm['value'].age;
        var sex = this.registerFarmerForm['value'].sex;
        var area = this.registerFarmerForm['value'].area;
        var telNumber = this.registerFarmerForm['value'].telNumber;
        var email = this.registerFarmerForm['value'].email;
        var levelOfEducation = this.registerFarmerForm['value'].levelOfEducation;
        var motherTongue = this.registerFarmerForm['value'].motherTongue;
        var fatherHusbandName = this.registerFarmerForm['value'].fatherHusbandName;
        if (!name || name.length == 0 || !age || age.length == 0 || !sex || sex.length == 0 || !area || area.length == 0 || !motherTongue || motherTongue.length == 0) {
            return;
        }
        if (levelOfEducation != null && levelOfEducation == 0) {
            levelOfEducation = null;
        }
        var farmerToRegister = {
            name: name,
            age: age,
            sex: sex,
            village_area_id: area,
            mother_tongue: motherTongue,
            education_level: levelOfEducation,
            tel_number: telNumber,
            email: email,
            photo_path: null,
            project_id: this.projectIdRegisteringFarmer,
            father_husband_name: fatherHusbandName
        };
        if (navigator.onLine) {
            if (this.fileToUpload) {
                var formData = new FormData();
                formData.append('type', 'farmerAvatar');
                formData.append('projectId', this.projectIdRegisteringFarmer);
                formData.append('avatar', this.fileToUpload, this.fileToUpload.name);
                this.farmerService.uploadFarmerImage(formData)
                    .subscribe(function (obj) {
                    if (obj.msg === 'success') {
                        farmerToRegister['photo_path'] = obj.data;
                        _this.farmerService.registerFarmer(farmerToRegister).subscribe(function (result) {
                            _this.registeringFarmer = false;
                            if (result.msg === 'success') {
                                //If consent given by farmer (added by KRPT) 
                                if (_this.signValues && _this.signValues.agree && _this.signValues.value != "") {
                                    _this.saveSignValue(_this.projectIdRegisteringFarmer, result.data.id);
                                }
                                result.data.cr_date = new Date();
                                if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                                    _this.registerFarmerModal.hide();
                                }
                                _this.registerFarmerForm.setValue({
                                    name: '',
                                    age: 18,
                                    sex: 3,
                                    area: 0,
                                    motherTongue: '',
                                    levelOfEducation: 0,
                                    telNumber: '',
                                    email: '',
                                    photo_path: '',
                                    fatherHusbandName: ''
                                });
                                _this.genderChanged(3);
                                _this.registerFarmerForm.markAsUntouched();
                                _this.registerFarmerForm.markAsPristine();
                                _this.farmerAgeSliderValue = 18;
                                _this.fileToUpload = undefined;
                                _this.commonService.showToast('success', 'farmer_registration_successful', {});
                                _this.renderer.selectRootElement('#nameVal').focus();
                                _this.confirmModal = true;
                                //for signature to be reset
                                _this.changeEvent = !_this.changeEvent;
                            }
                            else {
                                _this.confirmModal = true;
                                if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                                    _this.registerFarmerModal.hide();
                                }
                            }
                        }, function (error) {
                            _this.confirmModal = true;
                            if (_this.registerFarmerModal) {
                                _this.registerFarmerModal.hide();
                            }
                        });
                    }
                });
            }
            else {
                this.farmerService.registerFarmer(farmerToRegister).subscribe(function (result) {
                    _this.registeringFarmer = false;
                    if (result.msg === 'success') {
                        //If consent given by farmer (added by KRPT) 
                        if (_this.signValues && _this.signValues.agree && _this.signValues.value != "") {
                            _this.saveSignValue(_this.projectIdRegisteringFarmer, result.data.id);
                        }
                        result.data.cr_date = new Date();
                        if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                            _this.registerFarmerModal.hide();
                        }
                        _this.registerFarmerForm.setValue({
                            name: '',
                            age: 18,
                            sex: 3,
                            area: 0,
                            motherTongue: '',
                            levelOfEducation: 0,
                            telNumber: '',
                            email: '',
                            photo_path: '',
                            fatherHusbandName: ''
                        });
                        _this.genderChanged(3);
                        _this.registerFarmerForm.markAsUntouched();
                        _this.registerFarmerForm.markAsPristine();
                        _this.farmerAgeSliderValue = 18;
                        _this.fileToUpload = undefined;
                        _this.confirmModal = true;
                        _this.renderer.selectRootElement('#nameVal').focus();
                        //for signature to be reset
                        _this.changeEvent = !_this.changeEvent;
                    }
                    else {
                        _this.confirmModal = true;
                        if (!_this.registerAnotherFarmer && _this.registerFarmerModal) {
                            _this.registerFarmerModal.hide();
                        }
                    }
                }, function (error) {
                    _this.confirmModal = true;
                    if (_this.registerFarmerModal) {
                        _this.registerFarmerModal.hide();
                    }
                });
            }
        }
        else {
            var finalObj_1 = {};
            var farmerObj_1 = farmerToRegister;
            Object.keys(farmerObj_1).forEach(function (key) {
                if (typeof farmerObj_1[key] === 'object' && farmerObj_1[key] != null) {
                    if (farmerObj_1[key].length && farmerObj_1[key].length > 0) {
                        //finalObj[key] = {};
                        finalObj_1[key] = farmerObj_1[key];
                    }
                    // else {
                    //   finalObj[key] = farmerObj[key];
                    // }
                }
                else {
                    finalObj_1[key] = farmerObj_1[key];
                }
            });
            var farmObj = new Array();
            finalObj_1['tmp_id'] = "FA" + this.commonService.getRandomNum().toString().slice(0, 10) + "_Tab0";
            finalObj_1['savetab'] = 'Tab0';
            finalObj_1['randnum'] = this.commonService.getRandomNum().toString().slice(0, 10);
            finalObj_1['cr_date'] = new Date().getTime();
            finalObj_1['upd_date'] = new Date().getTime();
            finalObj_1['id'] = 0;
            finalObj_1['total_questions'] = null;
            finalObj_1['mandatory_questions'] = null;
            finalObj_1['filled_questions'] = null;
            finalObj_1['filled_mandatory'] = null;
            // added for signature offline storage
            if (this.signValues && this.signValues.agree && this.signValues.value != "") {
                finalObj_1['consentPending'] = false;
                finalObj_1['valid_upto'] = 730;
            }
            else {
                finalObj_1['consentPending'] = true;
                finalObj_1['valid_upto'] = null;
            }
            farmObj[farmObj.length] = finalObj_1;
            idbApp.addStoreInfo(farmObj, 'farmerstmp');
            if (this.farmerImageData) {
                var farmPhotoObj = {};
                farmPhotoObj['tmp_id'] = finalObj_1['tmp_id'];
                farmPhotoObj['randnum'] = finalObj_1['randnum'];
                farmPhotoObj['farmerImageData'] = this.farmerImageData;
                farmPhotoObj['savetab'] = 'Tab0';
                farmPhotoObj['cr_date'] = Date.now();
                farmPhotoObj['farmer_id'] = 0;
                idbAppPhoto.addStoreInfo([farmPhotoObj], 'farmersPhototmp');
            }
            // added for signature offline storage
            if (this.signValues && this.signValues.agree && this.signValues.value != "") {
                var farmSignatureObj = {};
                var curProject = this.projects.find(function (item) { return item.id == _this.projectIdRegisteringFarmer; });
                var err_msg = {
                    "farmer_consent_template_id": curProject.consentTemplateId,
                    "project_id": this.projectIdRegisteringFarmer,
                    "user_id": this.currentUser.id
                };
                farmSignatureObj['tmp_id'] = finalObj_1['tmp_id'];
                farmSignatureObj['randnum'] = finalObj_1['randnum'];
                farmSignatureObj['farmerImageData'] = this.signValues.value;
                farmSignatureObj['savetab'] = 'Tab0';
                farmSignatureObj['cr_date'] = Date.now();
                farmSignatureObj['farmer_id'] = 0;
                farmSignatureObj['photo_type'] = "signature";
                farmSignatureObj['error_msg'] = err_msg;
                idbAppPhoto.addStoreInfo([farmSignatureObj], 'farmerSignaturetmp');
            }
            this.commonService.showToast('info', 'offline_sync_later', {}); /* this.toastrService.info('You are offline. Data will be synced once you are back online.'); */
            this.signEmpty = false;
            if (this.signValues) {
                this.signValues.agree = false;
                this.signValues.value = "";
            }
            //for signature to be reset
            this.changeEvent = !this.changeEvent;
            this.genderChanged(3);
            this.registerFarmerForm.markAsPristine();
            this.farmerAgeSliderValue = 18;
            this.farmerImageData = undefined;
            this.registerSyncEvent();
            farmerToRegister['cr_date'] = new Date();
            farmerToRegister['tmp_id'] = finalObj_1['tmp_id'];
            if (!this.registerAnotherFarmer && this.registerFarmerModal) {
                this.registerFarmerModal.hide();
            }
            this.registerFarmerForm.setValue({
                name: '',
                age: 18,
                sex: 3,
                area: 0,
                motherTongue: '',
                levelOfEducation: 0,
                telNumber: '',
                email: '',
                photo_path: '',
                fatherHusbandName: ''
            });
            this.registerFarmerForm.markAsUntouched();
            this.registerFarmerForm.markAsPristine();
            this.renderer.selectRootElement('#nameVal').focus();
            this.farmerAgeSliderValue = 18;
            this.registeringFarmer = false;
            this.confirmModal = true;
        }
        this.uploadingFileName = undefined;
    };
    ManagerpageComponent.prototype.saveSignValue = function (projectId, farmerId) {
        var _this = this;
        this.spinner.show();
        var data = {
            "consent_sign": this.signValues.value
        };
        this.dataConsentService.saveFarmerConsent(projectId, farmerId, data).subscribe(function (result) {
            _this.spinner.hide();
            _this.signEmpty = false;
            _this.signValues.agree = false;
            _this.signValues.value = "";
        }, function (error) {
            _this.spinner.hide();
            _this.signEmpty = false;
            _this.signValues.agree = false;
            _this.signValues.value = "";
        });
    };
    ManagerpageComponent.prototype.registerSyncEvent = function () {
        navigator.serviceWorker.register('/service-worker.js', {
            scope: './'
        })
            .then(function (reg) {
            reg.sync.register('oneTimeSync');
        })
            .catch(function (error) {
        });
    };
    ManagerpageComponent.prototype.declineRegisterFarmerPrompt = function (form) {
        if (form.dirty) {
            this.confirmModal = false;
        }
        else {
            this.declineRegisterFarmer();
        }
    };
    ManagerpageComponent.prototype.declineRegisterFarmer = function () {
        var _this = this;
        if (this.registerFarmerModal) {
            this.registerFarmerModal.hide();
        }
        this.registerFarmerForm.setValue({
            name: '',
            age: 18,
            sex: 3,
            area: 0,
            motherTongue: '',
            levelOfEducation: 0,
            telNumber: '',
            email: '',
            photo_path: '',
            fatherHusbandName: ''
        });
        this.registerFarmerForm.markAsUntouched();
        this.registerFarmerForm.markAsPristine();
        this.farmerAgeSliderValue = 18;
        this.uploadingFileName = undefined;
        setTimeout(function () { return _this.confirmModal = true; }, 1000);
    };
    ManagerpageComponent.prototype.handleFileInput = function (file) {
        var _this = this;
        var fileItem = file.item(0);
        this.uploadingFileName = fileItem.name;
        var msg = this.commonService.ValidateFile(fileItem);
        if (msg && msg.length > 0) {
            this.commonService.showToast('warning', msg, {});
            return;
        }
        // this.fileToUpload = fileItem;
        // this.farmer.photo_path = fileItem.name;
        // const reader = new FileReader();
        // reader.onload = (event: any) => {
        //   // this.imageUrl = event.target.result;
        //   this.farmerImageData = null;
        //   let imageData = event.target.result;
        //   imageData = new Uint8Array(imageData);
        //   imageData = new Blob( [ imageData ], { type: "image/jpeg" } );
        //   let setFarmerImageData = (result) => { 
        //     this.commonService.blobToBase64(result).then((imageData)=>{
        //       this.farmerImageData = imageData;
        //     });
        //   };
        //   new Compressor(imageData, {
        //     quality: 0.6,
        //     success(result) {
        //       setFarmerImageData(result);
        //     },
        //     error(err) {
        //       console.log(err.message);
        //     },
        //   });
        // };
        // // reader.readAsDataURL(this.fileToUpload);
        // reader.readAsArrayBuffer(this.fileToUpload);
        new Compressor(fileItem, {
            quality: environment.imageCompress.quality,
            maxWidth: environment.imageCompress.maxWidth,
            maxHeight: environment.imageCompress.maxHeight,
            mimeType: environment.imageCompress.mimeType,
            convertSize: environment.imageCompress.convertSize,
            success: function (result) {
                setFarmerImageData(result);
            },
            error: function (err) {
                console.log(err.message);
            },
        });
        var setFarmerImageData = function (result) {
            _this.fileToUpload = result;
            _this.commonService.blobToBase64(result).then(function (imageData) {
                _this.farmerImageData = imageData;
            });
        };
    };
    ManagerpageComponent.prototype.genderChanged = function (gender) {
        this.registerFarmerForm.patchValue({
            sex: gender
        });
        this.registerFarmerGender = gender;
    };
    ManagerpageComponent.prototype.handleServiceClick = function (service, project) {
        if (service.click_action == 'modal') {
            this.setVillageAreaList(project);
            this.openRegisterFarmerModal(this[service.click_action_value + "Template"], project.id);
        }
        else if (service.click_action == 'router') {
            if (navigator.onLine) {
                var url = "/" + service.click_action_value + "/" + project.id;
                this.router.navigate([url]);
            }
            else {
                if (service.id === 5 || service.id === 4 || service.id === 7 || service.id === 8) {
                    this.toastr.warning(this.notAvailableWhenOfflineTxt);
                }
                else {
                    var url = "/" + service.click_action_value + "/" + project.id;
                    this.router.navigate([url]);
                }
            }
        }
    };
    ManagerpageComponent.prototype.confirmModalFn = function (what) {
        if (what == 'Yes') {
            this.declineRegisterFarmer();
        }
        else {
            this.confirmModal = true;
        }
    };
    // When the slide is moved, patch the selected value in the form
    ManagerpageComponent.prototype.setAgeValue = function (event) {
        this.registerFarmerForm.patchValue({
            age: this.farmerAgeSliderValue
        });
        this.registerFarmerForm.markAsDirty();
    };
    ManagerpageComponent.prototype.scrollRight = function (id) {
        var x = document.getElementById(id);
        x.scrollTo({ left: (x.scrollLeft + 150), behavior: 'smooth' });
    };
    ManagerpageComponent.prototype.scrollLeft = function (id) {
        var x = document.getElementById(id);
        x.scrollTo({ left: (x.scrollLeft - 150), behavior: 'smooth' });
    };
    ManagerpageComponent.prototype.setVillageAreaList = function (project) {
        if (project.ecosystem_region_master) {
            this.villageAreaList = project.ecosystem_region_master.region_villages;
        }
    };
    ManagerpageComponent.prototype.loadDetailedProjectData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, request, projectBasicInfo, projectDashboardData, projectProfilingData, projectConsentTemplateData, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.startTime = performance.now();
                        if (!(this.projects && this.projects.length > 0)) return [3 /*break*/, 7];
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < this.projects.length)) return [3 /*break*/, 7];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, Promise.all([
                                this.projectService.getProjectBasicInfo(this.projects[i].id).toPromise(),
                                this.projectService.getProjectDashboardData(this.projects[i].id).toPromise(),
                                this.projectService.getProjectProfilingData(this.projects[i].id).toPromise(),
                                this.farmerService.getFarmCoordinates(this.projects[i].id).toPromise(),
                                this.farmerService.getEstimatedPotentialValue(this.projects[i].id).toPromise(),
                                //this.farmerService.getFarmersByProjectBasicData(this.projects[i].id).toPromise(),
                                //this.dataConsentService.getFarmerConsents(this.projects[i].id).toPromise(),
                                !this.consentTemplateAvailable ? this.dataConsentService.getConsentTemplate(this.projects[i].id).toPromise() : ''
                            ])];
                    case 3:
                        request = _a.sent();
                        if (request[0]['msg'] != 'success'
                            || request[1]['msg'] != 'success'
                            || request[2]['msg'] != 'success'
                            || request[3]['msg'] != 'success'
                            || request[4]['msg'] != 'success') {
                            this.commonService.showToast('error', 'generic_error', {});
                        }
                        else {
                            projectBasicInfo = request[0].data[0];
                            projectDashboardData = request[1].data[0];
                            projectProfilingData = request[2].data[0];
                            projectConsentTemplateData = request[5].data;
                            if (projectBasicInfo) {
                                this.projects[i]['description'] = projectBasicInfo['description'];
                                this.projects[i]['start_date'] = projectBasicInfo['start_date'];
                                this.projects[i]['Product'] = projectBasicInfo['Product'];
                                this.projects[i]['Supplier'] = projectBasicInfo['Supplier'];
                                this.projects[i]['MultiValueMaster'] = projectBasicInfo['MultiValueMaster'];
                                this.projects[i]['ProjectStatus'] = projectBasicInfo['ProjectStatus'];
                                this.projects[i]['Country'] = projectBasicInfo['Country'];
                                this.projects[i]['ecosystem'] = projectBasicInfo['ecosystem'];
                            }
                            if (projectDashboardData) {
                                this.projects[i]['ecosystem_services_components_mapping'] = projectDashboardData['ecosystem_services_components_mapping'];
                                this.projects[i]['ecosystem_region_master'] = projectDashboardData['ecosystem_region_master'];
                                this.projects[i]['farmerCount'] = projectDashboardData['farmerCount'];
                                this.projects[i]['ProjectMapping'] = projectDashboardData['ProjectMapping'];
                                this.projects[i]['supplier_project_services_components_mapping'] = projectDashboardData['supplier_project_services_components_mapping'];
                            }
                            if (projectProfilingData) {
                                this.projects[i]['QuesProjectSnapshotMapping'] = projectProfilingData['QuesProjectSnapshotMapping'];
                                this.projects[i]['ProjectUOM'] = projectProfilingData['ProjectUOM'];
                            }
                            if (projectConsentTemplateData) {
                                this.consentTemplateAvailable = true;
                                this.consentTemplateData = projectConsentTemplateData;
                            }
                            this.projects[i]['consentTemplateId'] = this.consentTemplateData.id;
                            this.projects[i]['consentTemplate'] = this.sanitizer.bypassSecurityTrustHtml(this.consentTemplateData.template_content);
                        }
                        this.setProjectServices(i);
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        // console.log('858', err);
                        this.setProjectServices(i);
                        return [3 /*break*/, 5];
                    case 5:
                        //check if campaign service is assigned to this user
                        // let isCampaignService = false;
                        // if(this.projects[i].displayedServices){
                        //   for(const service of this.projects[i].displayedServices){
                        //     if(service.id == 6){
                        //       isCampaignService = true;
                        //     }
                        //   }
                        // }
                        // const request2 = await Promise.all([
                        //   this.surveyService.getSurveyQuestionnaireForProject(this.projects[i].id).toPromise(),
                        //   this.surveyService.getAllSurveysForProject(this.projects[i].id).toPromise(),
                        //   this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projects[i].id).toPromise(),
                        //   isCampaignService ? this.campaignService.getCampaignsForProject(this.projects[i].id).toPromise() : ''
                        // ])
                        // .then(async x => {
                        //   //added by KRPT for getting active survey ID
                        //   let activeSurveyId;
                        //   if (x[1]['code'] === 'success' && x[1]['message'].length > 0) {
                        //     let activeSnapshot;
                        //     const snapshotSurveys = x[1]['message'].filter(item => item.survey_type == 'snapshot');
                        //     if (snapshotSurveys && snapshotSurveys.length > 0) {
                        //       activeSnapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
                        //       if(activeSnapshot) {
                        //         activeSurveyId = activeSnapshot.id;
                        //       }
                        //     }
                        //   }
                        //   if (x[3]['code'] === 'success') {
                        //     let request3;
                        //     for (let k = 0; k < x[3]['message'].length; k++) {
                        //       if(x[3]['message'][k].closed_date == null){
                        //         request3 = await Promise.all([
                        //           this.surveyService.getSurveyQuestionnaireForCampaign(x[3]['message'][k].id).toPromise(),
                        //           this.campaignService.getFarmersMappedToCampaign(x[3]['message'][k].id).toPromise(),
                        //           this.dynamicQuestionnaireService.getAnswerStatsForCampaign(x[3]['message'][k].id).toPromise()
                        //         ]);
                        //       } else {
                        //         continue;
                        //       }
                        //     }
                        //     await this.loadPaginationLimits(this.projects[i].id, x[3]['message'], activeSurveyId);
                        //   } else {
                        //     await this.loadPaginationLimits(this.projects[i].id, null, activeSurveyId);
                        //   }
                        //   this.projects[i]['loadedDisplayedServices'] = true;
                        // })
                        // .catch(err => {
                        //   this.loadPaginationLimits(this.projects[i].id, null);
                        // });
                        this.projects[i]['loadedDisplayedServices'] = true;
                        _a.label = 6;
                    case 6:
                        i++;
                        return [3 /*break*/, 1];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ManagerpageComponent.prototype.setDashboardData = function () {
        this.commonService.setProjectList(this.projects);
        this.registerFarmerForm = this.formBuilder.group({
            name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
            age: new FormControl(18, [Validators.required, Validators.min(18), Validators.max(99)]),
            sex: new FormControl(3, [Validators.required]),
            area: new FormControl(0, [Validators.required, Validators.min(1)]),
            motherTongue: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            levelOfEducation: new FormControl(0),
            email: new FormControl('', [Validators.email]),
            telNumber: new FormControl('', [Validators.pattern("^[0-9]{5,13}$"),
                Validators.minLength(5), Validators.maxLength(13)]),
            photo_path: new FormControl(''),
            fatherHusbandName: new FormControl('', [Validators.maxLength(50)])
        });
        if (this.projects && this.projects.length == 0) {
            this.noProjectsMsg = true;
        }
        // make the non-critical API calls to fetch and cache this data (moved these out of dashboard.resolver.ts)
        if (this.currentUser.role.id < 4) {
            this.commonService.getProjectManager().subscribe(function (result) { });
        }
    };
    ManagerpageComponent.prototype.setProjectServices = function (i) {
        var _this = this;
        // console.log('setProjectServices called for', this.projects[i]);
        this.loadedProjects = true;
        var productItem;
        var managerItem;
        productItem = this.productType ? this.productType.filter(function (item) { return item.id == _this.projects[i].product_type; })[0] : undefined;
        if (productItem) {
            this.projects[i]['product_type_name'] = productItem.name;
        }
        else {
            this.projects[i]['product_type_name'] = "NA";
        }
        managerItem = this.managers ? this.managers.filter(function (item) { return item.id == _this.projects[i].project_manager; })[0] : undefined;
        if (managerItem) {
            this.projects[i]['manager_name'] = managerItem.name;
        }
        else {
            if (this.projects[i]['MultiValueMaster'] != null || this.projects[i]['MultiValueMaster'] != undefined) {
                this.projects[i]['manager_name'] = this.projects[i]['MultiValueMaster']['name'];
            }
            else {
                this.projects[i]['manager_name'] = "NA";
            }
        }
        // For non-admin roles, define the services displayed array based on the services mapped to this project
        if ((this.currentUser.role.id > 3) && this.projects[i]['supplier_project_services_components_mapping'] && this.projects[i]['supplier_project_services_components_mapping'].length > 0) {
            var displayedServicesTemp = [];
            for (var j = 0; j < this.projects[i]['supplier_project_services_components_mapping'].length; j++) {
                displayedServicesTemp.push(this.projects[i]['supplier_project_services_components_mapping'][j]['services']);
                if (this.projects[i]['supplier_project_services_components_mapping'][j]['services_components_mapping']) {
                    displayedServicesTemp.push(this.projects[i]['supplier_project_services_components_mapping'][j]['services_components_mapping']['services']);
                }
            }
            var displayedServicesUnique = displayedServicesTemp.reduce(function (unique, o) {
                if (!unique.some(function (obj) { return obj.code === o.code; })) {
                    unique.push(o);
                }
                return unique;
            }, []);
            displayedServicesUnique.sort(function (a, b) {
                if (a.sequence < b.sequence) {
                    return -1;
                }
                if (a.sequence > b.sequence) {
                    return 1;
                }
                return 0;
            });
            // console.log('displayedServicesUnique', displayedServicesUnique);
            //this.projects[i]['loadedDisplayedServices'] = true;
            this.projects[i]['displayedServices'] = displayedServicesUnique;
        }
        // For admin roles, define the services displayed array based on the services mapped to the ecosystem - whether or not mapped to the project
        else if ((this.currentUser.role.id < 4) && this.projects[i]['ecosystem_services_components_mapping'] && this.projects[i]['ecosystem_services_components_mapping'].length > 0) {
            var displayedServicesTemp = [];
            for (var j = 0; j < this.projects[i]['ecosystem_services_components_mapping'].length; j++) {
                displayedServicesTemp.push(this.projects[i]['ecosystem_services_components_mapping'][j]['services']);
                if (this.projects[i]['ecosystem_services_components_mapping'][j]['services_components_mapping']) {
                    displayedServicesTemp.push(this.projects[i]['ecosystem_services_components_mapping'][j]['services_components_mapping']['services']);
                }
            }
            var displayedServicesUnique = displayedServicesTemp.reduce(function (unique, o) {
                if (!unique.some(function (obj) { return obj.code === o.code; })) {
                    unique.push(o);
                }
                return unique;
            }, []);
            displayedServicesUnique.sort(function (a, b) {
                if (a.sequence < b.sequence) {
                    return -1;
                }
                if (a.sequence > b.sequence) {
                    return 1;
                }
                return 0;
            });
            this.projects[i]['displayedServices'] = displayedServicesUnique;
            //this.projects[i]['loadedDisplayedServices'] = true;
        }
        else if ((this.currentUser.role.id > 3) && (!this.projects[i]['supplier_project_services_components_mapping'] || this.projects[i]['supplier_project_services_components_mapping'].length == 0)) {
            this.projects[i]['displayedServices'] = null;
            //this.projects[i]['loadedDisplayedServices'] = true;
        }
    };
    ManagerpageComponent.prototype.showDataCachedMessage = function () {
        var _this = this;
        if (this.showCacheMessage1 && this.showCacheMessage2) {
            this.endTime = performance.now();
            this.toastr.clear();
            setTimeout(function () { return _this.commonService.showToast('success', 'data_cached_successfully', { preventDuplicates: true }); }, 100);
            this.spinner.hide();
        }
    };
    ManagerpageComponent.prototype.syncDone = function (args) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.onLine) return [3 /*break*/, 6];
                        window.dispatchEvent(new Event('caching-now'));
                        if (!(this.projects && this.projects.length > 0)) return [3 /*break*/, 6];
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < this.projects.length)) return [3 /*break*/, 5];
                        return [4 /*yield*/, Promise.all([
                                this.farmerService.getFarmersByProjectBasicData(this.projects[i].id).toPromise(),
                                // this.dynamicQuestionnaireService.getQAnswersForProject(this.projects[i].id, null).toPromise(),
                                this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projects[i].id).toPromise()
                            ])];
                    case 2:
                        request = _a.sent();
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getPaginationLimits(this.projects[i].id).toPromise()
                            ])
                                .then(function (result) {
                                if (result[0]['code'] === "success") {
                                    for (var i_1 = 0; i_1 < result[0]['message'].length; i_1++) {
                                        _this.loadFarmerDataIntoCache(_this.projects[i_1].id, result[0]['message'][i_1], result[0]['message'][i_1 + 1]);
                                    }
                                }
                            }).catch(function (e) { })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 1];
                    case 5:
                        window.dispatchEvent(new Event('data-synced'));
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ManagerpageComponent.prototype.displayProjects = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.pId) return [3 /*break*/, 1];
                        this.projectService.getUsersProjectIds().pipe(takeUntil(this.unsubscribe))
                            .subscribe(function (project) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(project.msg === 'success')) return [3 /*break*/, 2];
                                        // the following userType based filters are required for offline mode in case multiple ecosystem projects are stored in the cache
                                        // if system admin, all projects to be displayed
                                        if (this.currentUser.role.id == 1) {
                                            this.projects = project.data;
                                        }
                                        // if P2F-Roots admin, all projects to be displayed in View All mode, otherwise only show projects in selected ecosystem
                                        else if (this.currentUser.role.id == 2) {
                                            if (this.ecosystemScopeId == 0)
                                                this.projects = project.data;
                                            else
                                                this.projects = project.data.filter(function (item) { return item.ecosystem_id == _this.ecosystemScopeId; });
                                        }
                                        // if project admin, show projects in the selected ecosystem only
                                        else if (this.currentUser.role.id == 3) {
                                            this.projects = project.data.filter(function (item) { return item.ecosystem_id == _this.ecosystemScopeId; });
                                        }
                                        // in all other cases, show all projects
                                        else {
                                            this.projects = project.data;
                                        }
                                        return [4 /*yield*/, this.loadDetailedProjectData()];
                                    case 1:
                                        _a.sent();
                                        this.loadedProjects = true;
                                        this.cacheProjectsData();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        if (project.success == false) {
                                            this.commonService.showToast('error', 'relogin', {});
                                            setTimeout(function () { _this.toastr.clear(); }, 5000);
                                        }
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            console.error(error);
                        });
                        return [3 /*break*/, 3];
                    case 1:
                        this.projects = [{ id: this.pId, name: this.searchText, ecosystem_id: this.ecosystemScopeId }];
                        return [4 /*yield*/, this.loadDetailedProjectData()];
                    case 2:
                        _a.sent();
                        this.loadedProjects = true;
                        this.cacheProjectsData();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ManagerpageComponent.prototype.cacheProjectsData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var count, _loop_1, this_1, _i, _a, item;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        count = 0;
                        this.totalProjects = this.projects.length;
                        if (!(this.projects && this.projects.length > 0)) return [3 /*break*/, 5];
                        this.projectsLoaded = 0;
                        this.randomNumForLog = Math.floor(100000 + Math.random() * 900000);
                        _loop_1 = function (item) {
                            var isCampaignService, _i, _a, service, request;
                            return tslib_1.__generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        //check if campaign service is assigned to this user
                                        count++;
                                        isCampaignService = false;
                                        if (item.displayedServices) {
                                            for (_i = 0, _a = item.displayedServices; _i < _a.length; _i++) {
                                                service = _a[_i];
                                                if (service.id == 6) {
                                                    isCampaignService = true;
                                                }
                                            }
                                        }
                                        return [4 /*yield*/, Promise.all([
                                                this_1.farmerService.getFarmersByProjectBasicData(item.id).toPromise(),
                                                this_1.dataConsentService.getFarmerConsents(item.id).toPromise(),
                                                this_1.surveyService.getSurveyQuestionnaireForProject(item.id).toPromise(),
                                                this_1.surveyService.getAllSurveysForProject(item.id).toPromise(),
                                                this_1.dynamicQuestionnaireService.getAnswerStatsForProject(item.id).toPromise(),
                                                isCampaignService ? this_1.campaignService.getCampaignsForProject(item.id).toPromise() : ''
                                            ])
                                                .then(function (x) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                                var activeSurveyId, activeSnapshot, snapshotSurveys, docUrl, campaignSurveys, i, activeCampaign, docUrl, request3, _loop_2, this_2, k;
                                                return tslib_1.__generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            if (x[3]['code'] === 'success' && x[3]['message'].length > 0) {
                                                                activeSnapshot = void 0;
                                                                snapshotSurveys = x[3]['message'].filter(function (item) { return item.survey_type == 'snapshot'; });
                                                                if (snapshotSurveys && snapshotSurveys.length > 0) {
                                                                    activeSnapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
                                                                    if (activeSnapshot) {
                                                                        activeSurveyId = activeSnapshot.id;
                                                                        if (activeSnapshot.flex4) {
                                                                            docUrl = environment.apiBaseUrl + "/" + this.loc.prepareExternalUrl('').replace(/\//g, '').substr(0, 2) + "/assets/uploads/surveyManual/" + activeSnapshot.flex4;
                                                                            console.log("docUrl:", docUrl);
                                                                            this.http
                                                                                .get(docUrl, {
                                                                                responseType: 'blob',
                                                                            })
                                                                                .subscribe(function (response) { });
                                                                        }
                                                                    }
                                                                }
                                                                campaignSurveys = x[3]['message'].filter(function (item) { return item.survey_type == 'campaign'; });
                                                                if (campaignSurveys && campaignSurveys.length > 0) {
                                                                    for (i = 0, activeCampaign = null; i < campaignSurveys.length; i++, activeCampaign = null) {
                                                                        activeCampaign = campaignSurveys[i]['survey_stage_changelogs'][0]['change_type'] == 'active' ? campaignSurveys[i] : undefined;
                                                                        if (activeCampaign) {
                                                                            if (activeCampaign.flex4) {
                                                                                docUrl = environment.apiBaseUrl + "/" + this.loc.prepareExternalUrl('').replace(/\//g, '').substr(0, 2) + "/assets/uploads/surveyManual/" + activeCampaign.flex4;
                                                                                this.http
                                                                                    .get(docUrl, {
                                                                                    responseType: 'blob',
                                                                                })
                                                                                    .subscribe(function (response) { });
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            if (!(x[5]['code'] === 'success')) return [3 /*break*/, 6];
                                                            request3 = void 0;
                                                            _loop_2 = function (k) {
                                                                var campaignSurveys, thisCampaignSurveys, activeSurvey;
                                                                return tslib_1.__generator(this, function (_a) {
                                                                    switch (_a.label) {
                                                                        case 0:
                                                                            if (!(x[5]['message'][k].closed_date == null)) return [3 /*break*/, 2];
                                                                            return [4 /*yield*/, Promise.all([
                                                                                    this_2.surveyService.getSurveyQuestionnaireForCampaign(x[5]['message'][k].id).toPromise(),
                                                                                    this_2.campaignService.getFarmersMappedToCampaign(x[5]['message'][k].id).toPromise(),
                                                                                    this_2.dynamicQuestionnaireService.getAnswerStatsForCampaign(x[5]['message'][k].id).toPromise()
                                                                                ])];
                                                                        case 1:
                                                                            request3 = _a.sent();
                                                                            return [3 /*break*/, 3];
                                                                        case 2: return [2 /*return*/, "continue"];
                                                                        case 3:
                                                                            campaignSurveys = x[3]['message'].filter(function (item) { return item.survey_type == 'campaign'; });
                                                                            thisCampaignSurveys = campaignSurveys.filter(function (item) { return item.flex1 == x[5]['message'][k].id; });
                                                                            if (thisCampaignSurveys && thisCampaignSurveys.length > 0) {
                                                                                activeSurvey = thisCampaignSurveys.find(function (item) { return item.survey_stage_changelogs[0].change_type == 'active'; });
                                                                                if (activeSurvey) {
                                                                                    x[5]['message'][k]['activeSurvey'] = true;
                                                                                }
                                                                            }
                                                                            return [2 /*return*/];
                                                                    }
                                                                });
                                                            };
                                                            this_2 = this;
                                                            k = 0;
                                                            _a.label = 1;
                                                        case 1:
                                                            if (!(k < x[5]['message'].length)) return [3 /*break*/, 4];
                                                            return [5 /*yield**/, _loop_2(k)];
                                                        case 2:
                                                            _a.sent();
                                                            _a.label = 3;
                                                        case 3:
                                                            k++;
                                                            return [3 /*break*/, 1];
                                                        case 4: return [4 /*yield*/, this.loadPaginationLimits(item.id, x[5]['message'], activeSurveyId)];
                                                        case 5:
                                                            _a.sent();
                                                            return [3 /*break*/, 8];
                                                        case 6: return [4 /*yield*/, this.loadPaginationLimits(item.id, null, activeSurveyId)];
                                                        case 7:
                                                            _a.sent();
                                                            _a.label = 8;
                                                        case 8:
                                                            this.ecosystemId = localStorage.getItem('ecosystem_id') ? localStorage.getItem('ecosystem_id') : "NA";
                                                            this.projectsLoaded = this.projectsLoaded + 1;
                                                            this.currentPerc = parseFloat(((this.projectsLoaded / this.totalProjects) * 100).toFixed(2));
                                                            // Push user log info to backend. The params such as device info, user info and time taken to cache data in secs are passing to backend
                                                            this.commonService.updateUserLog(this.deviceInfoObj, this.currentUser.id, this.randomNumForLog, this.totalProjects, this.projectsLoaded, this.currentPerc, this.ecosystemId, parseInt(item.id)).subscribe(function (message) {
                                                                console.table(message);
                                                            });
                                                            item['readyForOffline'] = true;
                                                            //added by KRPT to calculated percentage completion
                                                            this.percCompletion = (count * 100) / this.projects.length;
                                                            if (!Number.isInteger(this.percCompletion)) {
                                                                this.percCompletion = parseFloat(this.percCompletion.toFixed(1));
                                                            }
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); })
                                                .catch(function (err) {
                                                _this.loadPaginationLimits(item.id, null);
                                            })];
                                    case 1:
                                        request = _b.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, _a = this.projects;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        item = _a[_i];
                        return [5 /*yield**/, _loop_1(item)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.showCacheMessage1 = true;
                        setTimeout(function () { return _this.showDataCachedMessage(); }, 100); //5000
                        this.setDashboardData();
                        return [3 /*break*/, 6];
                    case 5:
                        this.showCacheMessage1 = true;
                        this.showCacheMessage2 = true;
                        this.showDataCachedMessage();
                        this.setDashboardData();
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ManagerpageComponent.prototype.loadFarmerDataIntoCache = function (projectId, gte, lt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(projectId && projectId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getQAnswersForProjectPaginated(projectId, gte, lt).toPromise()
                            ])
                                .then(function (result) {
                                // console.log('result', result);
                            }).catch(function (e) { })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ManagerpageComponent.prototype.loadFarmerDataIntoCacheDashboard = function (projectId, gte, lt, surveyQIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(projectId && projectId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getQAnswersForProjectPaginatedDashboard(projectId, gte, lt, surveyQIds).toPromise()
                            ])
                                .then(function (result) {
                                // console.log('result', result);
                            }).catch(function (e) { })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ManagerpageComponent.prototype.keyDownFunction = function (event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            // rest of your code
        }
    };
    ManagerpageComponent.prototype.loadPaginationLimits = function (projectId, campaigns, activeSurveyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(projectId && projectId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getPaginationLimits(projectId).toPromise(),
                                activeSurveyId ? this.dynamicQuestionnaireService.getSurveyQAssetIdsDashboard(activeSurveyId).toPromise() : '',
                                this.farmerService.getFarmerAvailibility(projectId).toPromise()
                            ])
                                .then(function (result) {
                                var surveyQIds = [];
                                if (result[1]['code'] === 'success') {
                                    surveyQIds = result[1]['message'];
                                }
                                if (result[0]['code'] === "success") {
                                    for (var i = 0; i < result[0]['message'].length; i++) {
                                        _this.loadFarmerDataIntoCacheDashboard(projectId, result[0]['message'][i], result[0]['message'][i + 1], surveyQIds);
                                        //this.loadFarmerDataIntoCache(projectId, result[0]['message'][i], result[0]['message'][i+1]);
                                    }
                                    if (campaigns && campaigns.length > 0) {
                                        for (var i = 0; i < campaigns.length; i++) {
                                            // this.loadFarmerCampaignDataIntoCache(campaigns[i].id, result[0]['message'][i], result[0]['message'][i+1]);
                                            for (var ij = 0; ij < result[0]['message'].length; ij++) {
                                                if ((campaigns[i].closed_date === null || campaigns[i].closed_date === "" || !campaigns[i].closed_date) && campaigns[i].activeSurvey) {
                                                    _this.loadFarmerCampaignDataIntoCache(campaigns[i].id, result[0]['message'][ij], result[0]['message'][ij + 1]);
                                                }
                                            }
                                        }
                                    }
                                }
                            }).catch(function (e) { })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ManagerpageComponent.prototype.loadFarmerCampaignDataIntoCache = function (campaignId, gte, lt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(campaignId && campaignId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getQAnswersForCampaignPaginated(campaignId, gte, lt).toPromise()
                            ])
                                .then(function (result) {
                            }).catch(function (e) { })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ManagerpageComponent.prototype.getConsentData = function (data) {
        if (data.agree && data.value != "") {
            this.signValues = data;
            this.signEmpty = false;
        }
        if ((data.agree && data.value == "") || (!data.agree && data.value != "")) {
            this.signEmpty = true;
        }
        if (!data.agree && data.value == "") {
            this.signEmpty = false;
        }
    };
    //for ecosystem consolidation navigation
    ManagerpageComponent.prototype.navigateEcosytemConsolidation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allecoData, request, wb, _loop_3, this_3, wsBooks, wscols, wsBooks, i, fileType, excelBuffer, blob, linkdownload, today, dd, mm, yyyy, today1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // const url = `/consolidatedreportecosystem/${this.ecosystemScopeId}`;
                        // this.router.navigate([url]);
                        this.spinner.show();
                        if (!(this.projects && this.projects.length > 0)) return [3 /*break*/, 6];
                        allecoData = void 0;
                        return [4 /*yield*/, Promise.all([
                                this.consReportService.getEcosystemConsolidationTypeData(this.ecosystemScopeId).toPromise()
                            ])];
                    case 1:
                        request = _a.sent();
                        if (request[0].code == "success") {
                            allecoData = request[0].message;
                        }
                        wb = XLSX.utils.book_new();
                        _loop_3 = function (i) {
                            var projData, typeSelectedQuestions, _i, projData_1, item, obj, newRequest, requests, finalReportArr, _a, _b, item2, obj, sheetName, sheetName;
                            return tslib_1.__generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        projData = allecoData.filter(function (item) { return item.project_id == _this.projects[i].id; });
                                        if (!(projData && projData.length > 0)) return [3 /*break*/, 3];
                                        typeSelectedQuestions = [];
                                        for (_i = 0, projData_1 = projData; _i < projData_1.length; _i++) {
                                            item = projData_1[_i];
                                            obj = {
                                                'qid': item.questionnaire_asset_id,
                                                'type': item.cons_type,
                                                'not_required': item.not_required
                                            };
                                            typeSelectedQuestions.push(obj);
                                        }
                                        if (!(typeSelectedQuestions.length > 0)) return [3 /*break*/, 2];
                                        newRequest = {
                                            "questions": typeSelectedQuestions
                                        };
                                        return [4 /*yield*/, Promise.all([
                                                this_3.consReportService.getProjectConsolidatedReport(this_3.projects[i].id, newRequest).toPromise()
                                            ])];
                                    case 1:
                                        requests = _c.sent();
                                        finalReportArr = [];
                                        for (_a = 0, _b = requests[0].message; _a < _b.length; _a++) {
                                            item2 = _b[_a];
                                            obj = Object.assign({ "Project Name": this_3.projects[i].name }, item2);
                                            //console.log('obj', obj);
                                            finalReportArr.push(obj);
                                        }
                                        //console.log('requests[0].message', requests[0].message);
                                        wsBooks = XLSX.utils.json_to_sheet(finalReportArr);
                                        wscols = [
                                            { wpx: 150 },
                                            { wpx: 80 },
                                            { wpx: 120 },
                                            { wpx: 120 },
                                            { wpx: 300 },
                                            { wpx: 120 },
                                            { wpx: 120 },
                                            { wpx: 150 },
                                            { wpx: 200 },
                                            { wpx: 200 },
                                            { wpx: 120 }
                                        ];
                                        wsBooks['!cols'] = wscols;
                                        sheetName = "Project_" + this_3.projects[i].id;
                                        XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);
                                        _c.label = 2;
                                    case 2: return [3 /*break*/, 4];
                                    case 3:
                                        wsBooks = XLSX.utils.json_to_sheet([]);
                                        sheetName = "Project_" + this_3.projects[i].id;
                                        XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);
                                        _c.label = 4;
                                    case 4: return [2 /*return*/];
                                }
                            });
                        };
                        this_3 = this;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.projects.length)) return [3 /*break*/, 5];
                        return [5 /*yield**/, _loop_3(i)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5:
                        fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                        excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        blob = new Blob([excelBuffer], { type: fileType });
                        linkdownload = document.createElement('a');
                        linkdownload.href = URL.createObjectURL(blob);
                        today = new Date();
                        dd = String(today.getDate()).padStart(2, '0');
                        mm = String(today.getMonth() + 1).padStart(2, '0');
                        yyyy = today.getFullYear();
                        today1 = dd + '-' + mm + '-' + yyyy;
                        linkdownload.download = 'P2F-Roots_Consolidated_Report_Ecosystem_' + this.ecosystemScopeId + '_' + today1 + '.xlsx';
                        //linkdownload.download = `P2F-Roots_Consolidated_Report_${this.projects[0].Supplier.name}-${this.projects[0].Product.name}.xlsx`;
                        linkdownload.style.display = 'none';
                        document.body.appendChild(linkdownload);
                        linkdownload.click();
                        linkdownload.remove();
                        this.toastr.success("Excel Downloaded");
                        this.spinner.hide();
                        return [3 /*break*/, 7];
                    case 6:
                        this.toastr.success("Project not found");
                        this.spinner.hide();
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    //check if farmername contains spaces only
    ManagerpageComponent.prototype.checkEmptyness = function (e) {
        var name = this.registerFarmerForm['value'].name;
        if (!name.replace(/\s/g, '').length) {
            this.registeringFarmer = true;
        }
        else {
            this.registeringFarmer = false;
        }
    };
    ManagerpageComponent.prototype.showOverview = function (e) {
        document.querySelector('.peoject-img-list').classList.add('show');
    };
    return ManagerpageComponent;
}());
export { ManagerpageComponent };
