import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { mapTo } from 'rxjs/operators';
import { fromEvent, merge, of } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { FarmerService } from '../../services/farmer.service';
import { UserService } from '../../services/user.service';
import { CampaignService } from '../../services/campaign.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RevisionService } from '../../services/revision.service';
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as XLSX from 'xlsx';
import { ConsReportService } from '../../services/consolidated-reports.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
var CampaignProfilingFarmersComponent = /** @class */ (function () {
    function CampaignProfilingFarmersComponent(route, commonService, router, farmerService, projectService, userService, translate, toastrService, modalService, localeService, formBuilder, revisionService, dynamicQuestionnaireService, surveyService, campaignService, spinner, consReportService, locale, http) {
        var _this = this;
        this.route = route;
        this.commonService = commonService;
        this.router = router;
        this.farmerService = farmerService;
        this.projectService = projectService;
        this.userService = userService;
        this.translate = translate;
        this.toastrService = toastrService;
        this.modalService = modalService;
        this.localeService = localeService;
        this.formBuilder = formBuilder;
        this.revisionService = revisionService;
        this.dynamicQuestionnaireService = dynamicQuestionnaireService;
        this.surveyService = surveyService;
        this.campaignService = campaignService;
        this.spinner = spinner;
        this.consReportService = consReportService;
        this.locale = locale;
        this.http = http;
        this.projectId = 0;
        this.farmers = [];
        this.productType = [];
        this.managers = [];
        this.isAscending = true;
        this.isAscendingId = true;
        this.isAscendingName = true;
        this.isAscendingTel = true;
        this.isAscendingDate = true;
        this.sortLabel = '';
        this.showSpinner = false;
        this.isFieldAgent = false;
        this.isManager = false;
        this.isDeeproots = false;
        this.farmerFilterString = { name: '' };
        this.page = 1;
        this.itemsPerPage = 10;
        this.searchText = '';
        this.isInIDBInThisProject = [];
        this.showSyncSuccessAlert = false;
        this.olderSnapshots = [];
        this.minDate = new Date();
        this.noSnapshotsInProject = false;
        this.fetchingSnapshotInfo = true;
        this.disableCreateSnapshot = false;
        this.disableCloseSnapshot = false;
        this.registeringFarmer = false;
        this.registerAnotherFarmer = false;
        this.fileToUpload = null;
        this.photoModel = '';
        this.oldSnapshotId = 0;
        this.arrSnapshotRevisions = [];
        this.snapshotRevision = [];
        this.canExportToExcel = false;
        this.canMakeRevision = false;
        this.selectedFarmers = [];
        this.allTabNames = [];
        this.confirmTEDModal = true;
        this.showSurveyManualIcon = false;
        this.filteredfarmers = [];
        this.route.paramMap.subscribe(function (params) {
            _this.id = params.get('id');
        });
        this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
        this.translate.get('previousPaginationLabel').subscribe(function (result) {
            _this.previousPaginationLabel = result;
        });
        this.translate.get('nextPaginationLabel').subscribe(function (result) {
            _this.nextPaginationLabel = result;
        });
        this.translate.get('there_no_active_snapshot_for_this_project').subscribe(function (result) {
            _this.thereNoActiveSnapshotForThisProject = result;
        });
        this.translate.get('revision').subscribe(function (result) {
            _this.revisionTxt = result;
        });
        this.translate.get('created_successfully').subscribe(function (result) {
            _this.createSuccessTxt = result;
        });
        this.translate.get('select_deselect_object').subscribe(function (result) {
            _this.selectDeselectLabels = result;
        });
        this.translate.get('data_will_be_synced').subscribe(function (result) {
            _this.tooltipText2 = result;
        });
        this.translate.get('entire_survey').subscribe(function (result) {
            _this.entireSurvey = result;
        });
        this.translate.get('campaign_profiling').subscribe(function (result) {
            _this.campaignProfiling = result;
        });
        this.localeService.use(this.locale);
        this.translate.get('no_revision_txt').subscribe(function (result) {
            _this.noChangeRevisionTxt = result;
        });
    }
    CampaignProfilingFarmersComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var canExportToExcel, canMakeRevision, canExportToExcel, canMakeRevision, requests;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scrollToTop();
                        this.projectId = +this.route.snapshot.params['id'];
                        this.campaignId = +this.route.snapshot.params['campaignId'];
                        this.currentUser = this.userService.getCurrentUser();
                        this.project = this.route.snapshot.data.project.project;
                        if (this.project && this.project.ProjectMapping && this.project.ProjectMapping.length > 0) {
                            this.UPM = this.project.ProjectMapping.find(function (item) { return item.users_id === _this.currentUser.id; });
                        }
                        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
                            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
                        }
                        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
                            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
                        }
                        if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
                            canExportToExcel = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id === 10 && item.user_type_id === _this.currentUser.role.id); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                            canMakeRevision = this.usersServiceComponentMappings.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id === 11 && item.user_type_id === _this.currentUser.role.id); });
                            if (canMakeRevision && canMakeRevision.length > 0) {
                                this.canMakeRevision = true;
                            }
                        }
                        else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
                            canExportToExcel = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id === 10); });
                            if (canExportToExcel && canExportToExcel.length > 0) {
                                this.canExportToExcel = true;
                            }
                            canMakeRevision = this.ecosystemServicesComponentsMapping.filter(function (item) { return (item.services_components_mapping && item.services_components_mapping.id === 11); });
                            if (canMakeRevision && canMakeRevision.length > 0) {
                                this.canMakeRevision = true;
                            }
                        }
                        return [4 /*yield*/, Promise.all([
                                this.campaignService.getFarmersMappedToCampaign(this.campaignId).toPromise(),
                                this.surveyService.getAllSurveysForProject(this.projectId).toPromise(),
                                this.dynamicQuestionnaireService.getAnswerStatsForCampaign(this.campaignId).toPromise()
                            ])];
                    case 1:
                        requests = _a.sent();
                        this.farmers = requests[0].data.filter(function (item) { return !item.is_discontinued; });
                        this.allSurveys = requests[1].message;
                        this.filteredfarmers = this.farmers;
                        this.setSurveyStats(requests[2].message);
                        this.setSurveys();
                        return [2 /*return*/];
                }
            });
        });
    };
    CampaignProfilingFarmersComponent.prototype.scrollToTop = function () {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 8));
            }
        })();
    };
    CampaignProfilingFarmersComponent.prototype.setSurveyStats = function (stats) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var surveyStatsOnline, surveyStatsFromIDB, _loop_1, this_1, i;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(stats !== 'survey_not_active')) return [3 /*break*/, 2];
                        surveyStatsOnline = stats;
                        return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 1:
                        surveyStatsFromIDB = _a.sent();
                        _loop_1 = function (i) {
                            if (this_1.farmers[i].id && this_1.farmers[i].id !== 0) {
                                var statsForFarmer = surveyStatsOnline.find(function (item) { return item.farmer_id === _this.farmers[i].id; });
                                if (!statsForFarmer) {
                                    this_1.farmers[i]['percentageCompleted'] = 0;
                                    this_1.farmers[i]['lastProfiledAt'] = null;
                                }
                                else {
                                    this_1.farmers[i]['percentageCompleted'] = Math.round(parseInt(statsForFarmer.answered_questions) / parseInt(statsForFarmer.total_questions) * 100);
                                    this_1.farmers[i]['lastProfiledAt'] = statsForFarmer.upd_date ? statsForFarmer.upd_date : statsForFarmer.cr_date;
                                }
                                var statsForFarmerFromIDB = surveyStatsFromIDB.find(function (item) { return parseInt(item.tmp_id) === parseInt(_this.farmers[i].id) && parseInt(item.campaignId) === parseInt(_this.campaignId); });
                                if (statsForFarmerFromIDB) {
                                    var x = JSON.parse(statsForFarmerFromIDB.stats);
                                    if (x) {
                                        this_1.farmers[i]['percentageCompleted'] = Math.round(parseInt(x[2]) / parseInt(x[1]) * 100);
                                        this_1.farmers[i]['lastProfiledAt'] = new Date(statsForFarmerFromIDB.updatedAt);
                                    }
                                }
                            }
                            else {
                                var tmp_id_1 = this_1.farmers[i].tmp_id;
                                if (tmp_id_1.indexOf('_Tab00') > -1) {
                                    tmp_id_1 = tmp_id_1.substring(0, tmp_id_1.length - 1);
                                }
                                var statsForFarmerFromIDB = surveyStatsFromIDB.find(function (item) { return parseInt(item.tmp_id) === parseInt(tmp_id_1) && parseInt(item.campaignId) === parseInt(_this.campaignId); });
                                if (statsForFarmerFromIDB) {
                                    var x = JSON.parse(statsForFarmerFromIDB.stats);
                                    if (x) {
                                        this_1.farmers[i]['percentageCompleted'] = Math.round(parseInt(x[2]) / parseInt(x[1]) * 100);
                                        this_1.farmers[i]['lastProfiledAt'] = new Date(statsForFarmerFromIDB.updatedAt);
                                    }
                                }
                            }
                        };
                        this_1 = this;
                        for (i = 0; i < this.farmers.length; i++) {
                            _loop_1(i);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    CampaignProfilingFarmersComponent.prototype.setSurveys = function () {
        var _this = this;
        if (this.allSurveys && this.allSurveys.length > 0) {
            var campaignSurveys = this.allSurveys.filter(function (item) { return item.survey_type === 'campaign' && parseInt(item.flex1) === parseInt(_this.campaignId); });
            if (campaignSurveys && campaignSurveys.length > 0) {
                this.snapshot = campaignSurveys[0]['survey_stage_changelogs'][0]['change_type'] === 'active' ? campaignSurveys[0] : undefined;
            }
            else {
                this.snapshot = undefined;
            }
        }
        else {
            this.snapshot = undefined;
        }
        if (this.snapshot) {
            if (this.snapshot.flex4) {
                this.showSurveyManualIcon = true;
            }
        }
    };
    CampaignProfilingFarmersComponent.prototype.getGridFilters = function () {
        var _this = this;
        var gridColums = ['farmer_code', 'name', 'lastProfiledAt'];
        var finalObj = {};
        if (this.farmers === undefined) {
            return {};
        }
        else {
            gridColums.forEach(function (element) {
                finalObj[element] = _this.searchText;
            });
        }
        return finalObj;
    };
    CampaignProfilingFarmersComponent.prototype.goToFarmer = function (farmer) {
        var url;
        if (this.snapshot) {
            if (farmer.id && farmer.id > 0) {
                url = "/questionnaire/respond/" + farmer.project_id + "/" + farmer.id;
            }
            else if (farmer.tmp_id && !navigator.onLine) {
                if (farmer.tmp_id.indexOf('Tab00') > -1) {
                    url = "/questionnaire/respond/" + farmer.project_id + "/" + farmer.tmp_id.substring(0, farmer.tmp_id.length - 1);
                }
                else {
                    url = "/questionnaire/respond/" + farmer.project_id + "/" + farmer.tmp_id;
                }
            }
            else if (farmer.tmp_id && navigator.onLine) {
                this.commonService.showToast('warning', 'please_wait_data_sync', { preventDuplicates: true });
                return;
            }
            else {
                url = '/error';
            }
            this.router.navigate([url], { queryParams: { campaignId: this.campaignId } });
        }
        else {
            this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
        }
    };
    CampaignProfilingFarmersComponent.prototype.goToViewFarmerDQ = function (farmer) {
        var url2;
        if (this.snapshot) {
            if (farmer.id && farmer.id > 0) {
                url2 = "/questionnaire/view/" + farmer.project_id + "/" + farmer.id;
            }
            else if (farmer.tmp_id && !navigator.onLine) {
                if (farmer.tmp_id.indexOf('Tab00') > -1) {
                    url2 = "/questionnaire/view/" + farmer.project_id + "/" + farmer.tmp_id.substring(0, farmer.tmp_id.length - 1);
                }
                else {
                    url2 = "/questionnaire/view/" + farmer.project_id + "/" + farmer.tmp_id;
                }
            }
            else if (farmer.tmp_id && navigator.onLine) {
                this.commonService.showToast('warning', 'please_wait_data_sync', { preventDuplicates: true });
                return;
            }
            else {
                url2 = '/error';
            }
            this.router.navigate([url2], { queryParams: { campaignId: this.campaignId } });
        }
        else {
            this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
        }
    };
    CampaignProfilingFarmersComponent.prototype.makeRevisionDynamicQuestionnaire = function () {
        var _this = this;
        if (this.snapshot) {
            this.dynamicQuestionnaireService.makeRevision(this.snapshot.id)
                .subscribe(function (result) {
                if (result.code == 'success') {
                    if (result.message == "no_change") {
                        _this.toastrService.warning(_this.noChangeRevisionTxt);
                    }
                    else {
                        _this.toastrService.success(_this.revisionTxt + " " + result.message.revision_number + " " + _this.createSuccessTxt);
                    }
                }
            });
        }
    };
    CampaignProfilingFarmersComponent.prototype.quickNoteView = function (currentItem, template) {
        this.quickNoteData = currentItem;
        //console.log("quickNoteData ",this.quickNoteData[0].notes);
        this.quickNoteModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    };
    CampaignProfilingFarmersComponent.prototype.closePrompt = function () {
        if (this.quickNoteModal)
            this.quickNoteModal.hide();
    };
    //added by Kiran for escape button click event
    CampaignProfilingFarmersComponent.prototype.onKeydownHandler = function (event) {
        if (event.key === "Escape") {
            if (this.quickNoteModal) {
                this.quickNoteModal.hide();
            }
        }
    };
    CampaignProfilingFarmersComponent.prototype.syncDone = function (args) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.onLine) return [3 /*break*/, 3];
                        window.dispatchEvent(new Event('caching-now'));
                        return [4 /*yield*/, Promise.all([
                                // this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                                // this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise()
                                this.campaignService.getFarmersMappedToCampaign(this.campaignId).toPromise(),
                                this.dynamicQuestionnaireService.getAnswerStatsForCampaign(this.campaignId).toPromise()
                            ])];
                    case 1:
                        requests = _a.sent();
                        this.farmers = requests[0].data;
                        //this.sortFarmers();
                        this.setSurveyStats(requests[1].message);
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise()
                            ])
                                .then(function (result) {
                                if (result[0]['code'] === "success") {
                                    for (var i = 0; i < result[0]['message'].length; i++) {
                                        //this.loadFarmerDataIntoCache(this.projectId, result[0]['message'][i], result[0]['message'][i+1]);
                                        _this.loadFarmerCampaignDataIntoCache(_this.campaignId, result[0]['message'][i], result[0]['message'][i + 1]);
                                    }
                                }
                            }).catch(function (e) { })];
                    case 2:
                        _a.sent();
                        window.dispatchEvent(new Event('data-synced'));
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CampaignProfilingFarmersComponent.prototype.sortFarmers = function () {
        this.farmers.sort(function (a, b) {
            if (a.cr_date < b.cr_date) {
                return 1;
            }
            if (a.cr_date > b.cr_date) {
                return -1;
            }
            return 0;
        });
    };
    CampaignProfilingFarmersComponent.prototype.loadFarmerDataIntoCache = function (projectId, gte, lt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(projectId && projectId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getQAnswersForProjectPaginated(projectId, gte, lt).toPromise()
                            ])
                                .then(function (result) {
                                console.log('result', result);
                            }).catch(function (e) { })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    CampaignProfilingFarmersComponent.prototype.loadFarmerCampaignDataIntoCache = function (campaignId, gte, lt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(campaignId && campaignId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getQAnswersForCampaignPaginated(campaignId, gte, lt).toPromise()
                            ])
                                .then(function (result) {
                            }).catch(function (e) { })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    // Toggle a particular farmer's selection status
    CampaignProfilingFarmersComponent.prototype.toggleThisFarmerSelect = function (id) {
        if (this.selectedFarmers.indexOf(id) > -1) {
            this.selectedFarmers = this.selectedFarmers.filter(function (item) { return item !== id; });
        }
        else {
            this.selectedFarmers.push(id);
        }
        // console.log("single", this.selectedFarmers)
        this.setTooltipText();
    };
    // Select / deselect all farmers in the array
    CampaignProfilingFarmersComponent.prototype.toggleAllFarmers = function () {
        // If all farmers are already selected, then deselect all farmers
        if (this.selectedFarmers.length === this.farmers.length) {
            this.selectedFarmers = [];
        }
        // If all farmers are NOT already selected, select all the farmers
        else {
            this.selectedFarmers = [];
            for (var _i = 0, _a = this.farmers; _i < _a.length; _i++) {
                var val = _a[_i];
                this.selectedFarmers.push(val.id);
            }
        }
        this.setTooltipText();
    };
    CampaignProfilingFarmersComponent.prototype.setTooltipText = function () {
        if (this.farmers && this.selectedFarmers) {
            if (this.selectedFarmers.length === this.farmers.length) {
                // Deselect farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.deselect + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.deselect + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
            else {
                // Select farmers.length farmers
                if (this.farmers.length === 1) {
                    this.tooltipText = this.selectDeselectLabels.select + " 1 " + this.selectDeselectLabels.farmer;
                }
                else {
                    this.tooltipText = this.selectDeselectLabels.select + " " + this.farmers.length + " " + this.selectDeselectLabels.farmers;
                }
            }
        }
    };
    CampaignProfilingFarmersComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    CampaignProfilingFarmersComponent.prototype.sortBy = function (sortOn) {
        this.isAscending ? this.ascendic(sortOn) : this.descendic(sortOn);
    };
    CampaignProfilingFarmersComponent.prototype.ascendic = function (sortBy) {
        this.isAscending = false;
        if (sortBy === 'name') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.farmers.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = true;
            this.farmers.sort(function (a, b) {
                if (a.id < b.id) {
                    return 1;
                }
                if (a.id > b.id) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'tel') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.farmers.sort(function (a, b) {
                if (a.tel_number < b.tel_number) {
                    return 1;
                }
                if (a.tel_number > b.tel_number) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'date') {
            this.isAscendingId = true;
            this.isAscendingName = true;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.farmers.sort(function (a, b) {
                if (a.lastProfiledAt === b.lastProfiledAt) {
                    return 0;
                }
                // nulls sort after anything else
                if (a.lastProfiledAt === null) {
                    return 1;
                }
                if (b.lastProfiledAt === null) {
                    return -1;
                }
                if (a.lastProfiledAt < b.lastProfiledAt) {
                    return 1;
                }
                if (a.lastProfiledAt > b.lastProfiledAt) {
                    return -1;
                }
                return 0;
            });
        }
    };
    CampaignProfilingFarmersComponent.prototype.descendic = function (sortBy) {
        this.isAscending = true;
        if (sortBy === 'name') {
            this.isAscendingId = false;
            this.isAscendingName = true;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.farmers.sort(function (a, b) {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'id') {
            this.isAscendingId = true;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = false;
            this.farmers.sort(function (a, b) {
                if (a.id > b.id) {
                    return 1;
                }
                if (a.id < b.id) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'tel') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = true;
            this.isAscendingDate = false;
            this.farmers.sort(function (a, b) {
                if (a.tel_number > b.tel_number) {
                    return 1;
                }
                if (a.tel_number < b.tel_number) {
                    return -1;
                }
                return 0;
            });
        }
        else if (sortBy === 'date') {
            this.isAscendingId = false;
            this.isAscendingName = false;
            this.isAscendingTel = false;
            this.isAscendingDate = true;
            this.farmers.sort(function (a, b) {
                if (a.lastProfiledAt === b.lastProfiledAt) {
                    return 0;
                }
                // nulls sort after anything else
                if (a.lastProfiledAt === null) {
                    return -1;
                }
                if (b.lastProfiledAt === null) {
                    return 1;
                }
                if (a.lastProfiledAt > b.lastProfiledAt) {
                    return 1;
                }
                if (a.lastProfiledAt < b.lastProfiledAt) {
                    return -1;
                }
                return 0;
            });
        }
    };
    CampaignProfilingFarmersComponent.prototype.exportFarmers = function (template) {
        if (this.snapshot) {
            this.spinner.show();
            this.selectedTabCode = undefined;
            if (this.selectedFarmers.length) {
                if (this.projectId != null && this.projectId != 0) {
                    this.submitTabName();
                }
            }
            else {
                this.toastrService.info('Information: ' + "Select Farmers first");
            }
        }
        else {
            this.toastrService.info(this.thereNoActiveSnapshotForThisProject);
        }
    };
    //download excel for campaign profiling
    CampaignProfilingFarmersComponent.prototype.submitTabName = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var selTemp, exportExcelRequest, chunk, splittedArrayLength, splittedArray, wb, i, newRequest, requests, wsBooks, wscols, sheetName, fileType, excelBuffer, blob, linkdownload, today, dd, mm, yyyy, today1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        selTemp = this.farmers.filter(function (f) { return _this.selectedFarmers.includes(f.id) && f.percentageCompleted; });
                        selTemp = selTemp.map(function (f) {
                            return f.id;
                        });
                        exportExcelRequest = {
                            "tabCode": this.selectedTabCode,
                            "farmers": selTemp
                        };
                        splittedArrayLength = 500;
                        splittedArray = [];
                        while (selTemp.length > 0) {
                            chunk = selTemp.splice(0, splittedArrayLength);
                            splittedArray.push(chunk);
                        }
                        wb = XLSX.utils.book_new();
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < splittedArray.length)) return [3 /*break*/, 4];
                        newRequest = {
                            "tabCode": this.selectedTabCode,
                            "farmers": splittedArray[i]
                        };
                        return [4 /*yield*/, Promise.all([
                                this.consReportService.getCampaignReport(this.projectId, this.campaignId, newRequest).toPromise()
                            ])];
                    case 2:
                        requests = _a.sent();
                        wsBooks = XLSX.utils.json_to_sheet(requests[0].message);
                        wscols = [
                            { wpx: 80 },
                            { wpx: 80 },
                            { wpx: 120 },
                            { wpx: 300 },
                            { wpx: 100 },
                            { wpx: 80 },
                            { wpx: 80 },
                            { wpx: 200 },
                            { wpx: 200 },
                            { wpx: 200 }
                        ];
                        wsBooks['!cols'] = wscols;
                        sheetName = "Farmer Sheet " + (i + 1);
                        XLSX.utils.book_append_sheet(wb, wsBooks, sheetName);
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                        excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        blob = new Blob([excelBuffer], { type: fileType });
                        linkdownload = document.createElement('a');
                        linkdownload.href = URL.createObjectURL(blob);
                        today = new Date();
                        dd = String(today.getDate()).padStart(2, '0');
                        mm = String(today.getMonth() + 1).padStart(2, '0');
                        yyyy = today.getFullYear();
                        today1 = dd + '-' + mm + '-' + yyyy;
                        linkdownload.download = 'P2F-Roots_' + this.campaignProfiling + '_' + this.entireSurvey + '_Project_' + this.projectId + '_' + today1 + '.xlsx';
                        linkdownload.style.display = 'none';
                        document.body.appendChild(linkdownload);
                        linkdownload.click();
                        linkdownload.remove();
                        this.selectedFarmers = [];
                        this.toastrService.success("Excel Downloaded");
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    CampaignProfilingFarmersComponent.prototype.openSurveyManual = function () {
        var docUrl = environment.apiBaseUrl + "/" + this.locale.substr(0, 2) + "/assets/uploads/surveyManual/" + this.snapshot.flex4;
        var docExtension = docUrl.split(/[#?]/)[0].split('.').pop().trim();
        this.http
            .get(docUrl, {
            responseType: 'blob',
        })
            .subscribe(function (response) {
            var blob = new Blob([response], { type: 'application/octet-stream' });
            var downloadUrl = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadUrl;
            link.style.display = 'none';
            link.download = "Survey_manual." + docExtension;
            link.click();
            window.URL.revokeObjectURL(downloadUrl);
            link.remove();
        });
    };
    //filter farmers array based on search text
    CampaignProfilingFarmersComponent.prototype.filterFarmers = function () {
        var _this = this;
        this.farmers = this.filteredfarmers;
        if (this.searchText.length > 0) {
            var filterArr = this.farmers.filter(function (item) {
                if ((item.farmer_code && item.farmer_code.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1) ||
                    (item.name && item.name.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1) ||
                    (item.lastProfiledAt && item.lastProfiledAt.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1) ||
                    (item.official_id && item.official_id.toLowerCase().indexOf(_this.searchText.toLowerCase()) > -1)) {
                    return item;
                }
            });
            this.farmers = filterArr;
        }
    };
    return CampaignProfilingFarmersComponent;
}());
export { CampaignProfilingFarmersComponent };
